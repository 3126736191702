import _differenceBy from 'lodash/differenceBy';
import _isEmpty from 'lodash/isEmpty';
import _concat from 'lodash/concat';
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _head from 'lodash/head';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { fetchEntityRecords } from '../../../../../actions/recordManagement.actions';
import { fetchActions, fetchApplicableActionsForRecords } from '../../../../../actions/actionBuilder.actions';
import { searchViewConfigurations } from '../../../../../actions/viewBuilderPage.actions';
import { fetchEntities, fetchEntityDefByName } from '../../../../../actions/entityManagement.actions';

import { getResolvedRichTextEditorFieldRecords } from '../../../../../helpers/richTextField.helpers';
import { getPayloadForEntityRecords, getPayloadForViewDefinitionSearch, getPayloadForActions } from './entityRecordDetailPage.helpers';
import { triggerOnActionModalInit } from '../../../../../connectors/withActionExecutionModal';
import { getCompactedFiltersPayload } from '../../../../../helpers/general.helpers';
import { getComplexAndRelationshipEntitiesFromEntityDef, getResolvedEntityDefinitions } from '../../../../../helpers/entityManagement.helpers';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../constants/actionBuilder.constants';
import ACTION_TYPES from '../constants/entityRecordDetailPage.constants';
import { FORM_MODES, VIEW_CONFIG_TYPES, VIEW_TYPES } from '../../../../../constants/viewBuilder.constants';
import ENTITY_DEFINITION_FIELDS from '../../../../../constants/entityDefinitionFields';

const getEntityRecord = async ({ entityRecordId, detailViewConfig, entityName }) => {
  const entityRecordPayload = getPayloadForEntityRecords([entityRecordId], detailViewConfig);

  const entityRecords = await fetchEntityRecords(entityName, entityRecordPayload);
  const entityRecord = _head(tget(entityRecords, 'hits', [{}]));

  return entityRecord;
};
const getPayloadForEntity = (entities) => {
  let payload = {};
  if (!_isEmpty(entities)) {
    payload = {
      filters: getCompactedFiltersPayload([
        {
          field: ENTITY_DEFINITION_FIELDS.NAME,
          values: entities,
          filterType: OPERATORS.IN,
        },
      ]),
    };
  }
  return payload;
};

const handleInitData = async ({ getState, setState }) => {
  const { match = EMPTY_OBJECT } = getState();

  const entityName = _get(match, 'params.entityName');
  const entityRecordId = _get(match, 'params.entityRecordId');
  const viewDefinitionPayload = getPayloadForViewDefinitionSearch([entityName], [VIEW_TYPES.DETAIL_VIEW], [VIEW_CONFIG_TYPES.STANDARD]);
  const actionPayload = getPayloadForActions([entityName]);

  const [entity, detailViewConfigResponse, allActions] = await Promise.all([
    fetchEntityDefByName(entityName),
    searchViewConfigurations(viewDefinitionPayload),
    fetchActions(actionPayload),
  ]);
  const detailViewConfig = _head(tget(detailViewConfigResponse, 'hits', [{}]));
  const { complexEntities, relationshipEntities } = getComplexAndRelationshipEntitiesFromEntityDef(entity);
  const entityPayload = getPayloadForEntity([...complexEntities, ...relationshipEntities]);

  const entityResponse = await fetchEntities(entityPayload, true);
  const entityDefsByName = _keyBy(tget(entityResponse, 'hits', EMPTY_ARRAY), ENTITY_DEFINITION_FIELDS.NAME);
  const masterEntityDef = getResolvedEntityDefinitions(entity, entityDefsByName);

  const allActionsList = tget(allActions, 'hits', EMPTY_ARRAY);
  const actionOptions = _map(allActionsList, (action) => ({
    id: _get(action, ACTION_DEFINITION_FIELD_IDS.ID, EMPTY_STRING),
    name: _get(action, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME, EMPTY_STRING),
    errorMessage: _get(action, 'errorMessage', __('This record is not qualifying the entry condition for this action.')),
  }));
  let entityRecord;

  if (_isEmpty(entityRecord)) {
    entityRecord = await getEntityRecord({ entityRecordId, detailViewConfig, entityName });
  }
  entityRecord = await getResolvedRichTextEditorFieldRecords(masterEntityDef, entityRecord);
  entityRecord = getArraySafeValue(entityRecord);

  setState({
    formMode: FORM_MODES.VIEW,
    entity: masterEntityDef,
    entityRecord,
    detailViewConfig,
    actionOptions,
    actionDefinitions: _keyBy(allActionsList, 'id'),
    isLoading: false,
  });
};

const handleActionClick = async ({ getState, params = EMPTY_OBJECT }) => {
  const { actionDefinitions, entity, match, entityRecord, actionModalContextId } = getState();

  const actionId = _get(params, 'key');
  const recordId = _get(match, 'params.entityRecordId');
  const actionDefinition = _get(actionDefinitions, actionId, EMPTY_OBJECT);

  triggerOnActionModalInit(actionModalContextId, { recordId, actionId, actionDef: actionDefinition, recordData: entityRecord, entityDef: entity });
};

const handleGoBack = ({ getState }) => {
  const { history, match } = getState();
  const entityName = _get(match, 'params.entityName');
  history.push(`/entities/${entityName}`);
};

const handleFetchActions = async ({ getState, setState }) => {
  const { match, actionOptions } = getState();

  setState({ isActionLoading: true });
  const entityName = _get(match, 'params.entityName');
  const entityRecordId = _get(match, 'params.entityRecordId');

  const actions = await fetchApplicableActionsForRecords(entityName, { recordIds: [entityRecordId] });
  const actionsList = _get(actions, entityRecordId, []);
  const disabledActions = _differenceBy(actionOptions, actionsList, ACTION_DEFINITION_FIELD_IDS.ID);
  const newActionOptions = _concat(
    _map(actionsList, (action) => ({
      id: _get(action, ACTION_DEFINITION_FIELD_IDS.ID, EMPTY_STRING),
      name: _get(action, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME, EMPTY_STRING),
    })),
    _map(disabledActions, (action) => ({
      id: _get(action, ACTION_DEFINITION_FIELD_IDS.ID, EMPTY_STRING),
      name: _get(action, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME, EMPTY_STRING),
      disabled: true,
      errorMessage: _get(action, 'errorMessage', __('This record is not qualifying the entry condition for this action.')),
    })),
  );

  setState({ isActionLoading: false, actionOptions: newActionOptions });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT_FORM]: handleInitData,
  [ACTION_TYPES.ACTION_CLICK]: handleActionClick,
  [ACTION_TYPES.GO_BACK]: handleGoBack,
  [ACTION_TYPES.FETCH_ACTIONS]: handleFetchActions,
};

export default ACTION_HANDLERS;
