import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

// Constants
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../constants/actionBuilder.constants';
import { ACTION_BUILDER_ACTION_TYPES, USER_INPUT_TYPES } from '../constants/actionBuilderForm.general';

// Styles
import styles from '../actionBuilder.module.scss';

const actionTypeCheck = {
  [ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME]: ({ actionType }) =>
    actionType !== ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL &&
    actionType !== ACTION_BUILDER_ACTION_TYPES.SEND_EMAILS &&
    actionType !== ACTION_BUILDER_ACTION_TYPES.DELETE_RECORD &&
    actionType !== ACTION_BUILDER_ACTION_TYPES.WORKFLOW_TRIGGER,

  [ACTION_DEFINITION_FIELD_IDS.WORKFLOW_ID]: ({ actionType }) => actionType === ACTION_BUILDER_ACTION_TYPES.WORKFLOW_TRIGGER,
};

const actionInfoSection = (actionType) => ({
  subHeader: {
    label: __('Action Info'),
  },
  rows: [
    {
      columns: [ACTION_DEFINITION_FIELD_IDS.ACTION_DISPLAY_NAME, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME],
    },
    {
      columns: [
        ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE,
        ...filterRows([ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME], { actionType }, actionTypeCheck),
        ...filterRows([ACTION_DEFINITION_FIELD_IDS.WORKFLOW_ID], { actionType }, actionTypeCheck),
      ],
    },
  ],
});

const VALIDATION_RULE_OVERRIDE_SECTION = {
  className: styles.fullWidthSection,
  subHeader: {
    label: __('Validation Rule Override'),
  },
  rows: [{ columns: [ACTION_DEFINITION_FIELD_IDS.VALIDATION_RULE_OVERRIDE] }],
};

const rowCheck = {
  [ACTION_DEFINITION_FIELD_IDS.USER_INPUT]: ({ actionType }) =>
    actionType === ACTION_BUILDER_ACTION_TYPES.CREATE_RECORD || actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD,
  [ACTION_DEFINITION_FIELD_IDS.USER_INPUT_TYPE]: ({ userInput }) => userInput,
  [ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME]: ({ userInput, userInputType }) => userInput && userInputType === USER_INPUT_TYPES.FORM,
  [ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS]: ({ userInput, userInputType }) => userInput && userInputType === USER_INPUT_TYPES.FIELD,

  [ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS]: ({ actionType, shouldUpdateSameRecord }) =>
    (actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD && !shouldUpdateSameRecord) ||
    actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORDS,
  [ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS]: ({ actionType, shouldUpdateSameRecord }) =>
    (actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD && !shouldUpdateSameRecord) ||
    actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORDS,
  [ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS]: ({ isSortRequired }) => isSortRequired,
  [ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT]: ({ actionType }) => actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORDS,

  [ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD]: ({ isTargetSameAsParameter, actionType }) =>
    isTargetSameAsParameter && actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD,
  [ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION]: ({ actionType }) =>
    actionType === ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL || actionType === ACTION_BUILDER_ACTION_TYPES.SEND_EMAILS,
};

const getSections = (entityName, actionData) => {
  // TODO: Both places below, use getArraySafeValue
  const actionType = tget(actionData, `${ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE}.0`);
  const targetEntity = tget(actionData, `${ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME}.0`);
  const isTargetSameAsParameter = targetEntity === entityName;

  if (
    _isEmpty(actionType) ||
    (actionType !== ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL && actionType !== ACTION_BUILDER_ACTION_TYPES.SEND_EMAILS && _isEmpty(targetEntity))
  ) {
    return [
      actionInfoSection(actionType),
      {
        className: styles.fullWidthSection,
        subHeader: {
          label: __('Entry Condition'),
        },
        rows: [{ columns: [ACTION_DEFINITION_FIELD_IDS.ENTRY_CONDITION] }],
      },
    ];
  }

  const isSortRequired = tget(actionData, ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS, false);
  const userInput = tget(actionData, ACTION_DEFINITION_FIELD_IDS.USER_INPUT, false);
  const userInputType = tget(actionData, ACTION_DEFINITION_FIELD_IDS.USER_INPUT_TYPE);
  const shouldUpdateSameRecord = tget(actionData, ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD, false);

  const sections = [
    actionInfoSection(actionType),
    {
      className: styles.fullWidthSection,
      subHeader: {
        label: __('Entry Condition'),
      },
      rows: [{ columns: [ACTION_DEFINITION_FIELD_IDS.ENTRY_CONDITION] }],
    },
    {
      rows: [
        {
          columns:
            actionType !== ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL && actionType !== ACTION_BUILDER_ACTION_TYPES.SEND_EMAILS
              ? [ACTION_DEFINITION_FIELD_IDS.ERROR_MESSAGE_ON_ENTRY_CONDITION]
              : [],
        },
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD], { actionType, isTargetSameAsParameter }, rowCheck),
        },
      ],
    },
    {
      className: styles.fullWidthSection,
      rows: [
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS], { actionType, shouldUpdateSameRecord }, rowCheck),
        },
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS], { actionType, shouldUpdateSameRecord }, rowCheck),
        },
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS], { isSortRequired }, rowCheck),
        },
      ],
    },
    {
      rows: [
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT], { actionType }, rowCheck),
        },
      ],
    },
    {
      className: styles.fullWidthSection,
      subHeader: {
        label: __('Action Definition'),
      },
      rows: [
        {
          columns: [ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION],
        },
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION], { actionType }, rowCheck),
        },
      ],
    },
    {
      rows: [
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.USER_INPUT], { actionType }, rowCheck),
        },
        {
          className: styles.halfColumn,
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME], { userInput, userInputType }, rowCheck),
        },
        {
          className: styles.halfColumn,
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS], { userInput, userInputType }, rowCheck),
        },
      ],
    },
  ];

  if (
    (actionType === ACTION_BUILDER_ACTION_TYPES.CREATE_RECORD || actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD) &&
    !_isEmpty(targetEntity)
  ) {
    return [...sections, VALIDATION_RULE_OVERRIDE_SECTION];
  }

  return sections;
};

export default getSections;
