import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import ApiService from './apiService/apiService';

const DATA_IMPORT_BASE_URL = '/core/u/imports';

const getImportTemplateById = (id) => ApiService.get(`${DATA_IMPORT_BASE_URL}/templates/${id}`);
const updateImportTemplateById = (id, payload = EMPTY_OBJECT) => ApiService.put(`${DATA_IMPORT_BASE_URL}/templates/${id}`, payload);
const createImportTask = (payload = EMPTY_OBJECT) => ApiService.post(`${DATA_IMPORT_BASE_URL}/task`, payload);
const startImportById = (id) => ApiService.post(`${DATA_IMPORT_BASE_URL}/task/${id}/start`);
const searchImports = (payload = EMPTY_OBJECT) => ApiService.post(`${DATA_IMPORT_BASE_URL}/search`, payload);
const searchBatchTasks = (payload = EMPTY_OBJECT) => ApiService.post(`${DATA_IMPORT_BASE_URL}/batch/search`, payload);
const getImportTaskById = (id) => ApiService.get(`${DATA_IMPORT_BASE_URL}/task/${id}`);
const getProgressForImportTaskById = (id) => ApiService.get(`${DATA_IMPORT_BASE_URL}/task/${id}/progress`);

export default {
  getImportTemplateById,
  updateImportTemplateById,
  createImportTask,
  startImportById,
  searchBatchTasks,
  getImportTaskById,
  getProgressForImportTaskById,
  searchImports,
};
