import { tget } from '@tekion/tekion-base/utils/general';

import { NOTIFICATION_FIELD_IDS } from '../../../../../constants/notification.constants';

const getCustomEntityNotificationFields = (notificationEvent) => ({
  id: tget(notificationEvent, NOTIFICATION_FIELD_IDS.NOTIFICATION_ID),
  subject: tget(notificationEvent, NOTIFICATION_FIELD_IDS.SUBJECT),
  body: tget(notificationEvent, NOTIFICATION_FIELD_IDS.BODY),
  applications: tget(notificationEvent, NOTIFICATION_FIELD_IDS.APP_NAMES),
  entityName: tget(notificationEvent, NOTIFICATION_FIELD_IDS.ENTITY_NAME),
  recordId: tget(notificationEvent, NOTIFICATION_FIELD_IDS.RECORD_ID),
  status: tget(notificationEvent, NOTIFICATION_FIELD_IDS.STATUS),
});

const getDefaultNotificationFields = (notificationEvent) => ({
  id: tget(notificationEvent, NOTIFICATION_FIELD_IDS.NOTIFICATION_ID),
  subject: tget(notificationEvent, NOTIFICATION_FIELD_IDS.SUBJECT),
  body: tget(notificationEvent, NOTIFICATION_FIELD_IDS.BODY),
});

export { getCustomEntityNotificationFields, getDefaultNotificationFields };
