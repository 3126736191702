import React, { useMemo } from 'react';
import _some from 'lodash/some';
import _identity from 'lodash/identity';

// context
import { UploadingMediaContextProvider } from './context/UploadingMediaContext';

// hooks
import useUploadingMediaStatuses from './hooks/useUploadingMediaStatuses';

/**
 * This HOC holds the state of all the media that are currently getting uploaded across plate RTEs
 *
 */
function withUploadingMediaStatus(WrappedComponent) {
  function WithUploadingMediaStatus(props) {
    const { setMediaUploadingStatus, uploadingMediaStatuses } = useUploadingMediaStatuses();
    const isMediaUploading = useMemo(() => _some(uploadingMediaStatuses, _identity), [uploadingMediaStatuses]);

    return (
      <UploadingMediaContextProvider value={setMediaUploadingStatus}>
        <WrappedComponent {...props} isMediaUploading={isMediaUploading} />
      </UploadingMediaContextProvider>
    );
  }
  return WithUploadingMediaStatus;
}

export default withUploadingMediaStatus;
