exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".workflowBuilder_container__47WFQCB9wi{flex-grow:1;overflow:auto;min-height:2em;min-width:100%;transition:width .3s cubic-bezier(0.7, 0.3, 0.1, 1)}[dir] .workflowBuilder_container__47WFQCB9wi{background:#edeef0}.workflowBuilder_headerContainer__q2XB7ghByL{justify-content:space-between !important;align-items:flex-end !important}.workflowBuilder_headerContainer__q2XB7ghByL .workflowBuilder_entityNameContainer__2YfV3jajLq{font-size:1.8rem}.workflowBuilder_headerContainer__q2XB7ghByL .workflowBuilder_entityNameContainer__2YfV3jajLq .workflowBuilder_entityLabel__xpvvbHMpDj{font-family:\"Proxima-Nova-Semibold\"}", ""]);

// Exports
exports.locals = {
	"container": "workflowBuilder_container__47WFQCB9wi",
	"headerContainer": "workflowBuilder_headerContainer__q2XB7ghByL",
	"entityNameContainer": "workflowBuilder_entityNameContainer__2YfV3jajLq",
	"entityLabel": "workflowBuilder_entityLabel__xpvvbHMpDj"
};