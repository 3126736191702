import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';

import { tget } from '@tekion/tekion-base/utils/general';

// Components
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Modal from '@tekion/tekion-components/molecules/Modal';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

// Constants
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import { FIELD_IDS, SECTIONS } from './conditionModal.constants';
import { TASK_DEF_IDS } from '../../../constants/workflow.constants';
import { CONDITION_BUILDER_TYPES } from '../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';

// Helpers
import getFields from './helpers/conditionModal.getFields';
import {
  convertCheckFieldNodeUserDataForApi,
  convertCheckFieldNodeUserDataForForm,
  getUsedVariables,
  getChildLabels,
} from './helpers/conditionModal.helpers';
import { EVENT_TYPES_FOR_RECORD_CHANGE_OPERATORS } from '../../../constants/workflowBuilder.constants';

const CONDITION_MODAL_CONTEXT_ID = 'CONDITION_MODAL_CONTEXT_ID';

const ConditionModal = ({
  isLoading,
  visible,
  renderContentOnly,
  targetType,
  mode,
  eventType,
  stepDetails,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  onChangeConditionModal,
  onCancel,
  onSubmit,
}) => {
  const [value, setValue] = useState({ type: CONDITION_BUILDER_TYPES.CRITERIA, expression: '1', criteriaList: [{}] });
  const [errors, setErrors] = useState();
  const taskDefId = useMemo(() => tget(stepDetails, 'taskDefId', TASK_DEF_IDS.CREATE_RECORD), [stepDetails]);

  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload = EMPTY_OBJECT } = action;
    switch (type) {
      case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
        const { value: updatedValue } = payload;
        onChangeConditionModal(updatedValue);
        setValue(updatedValue);
        break;
      }
      case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
        const { errors: newErrors } = payload;
        setErrors(newErrors);
        break;
      }
      case FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT: {
        const childLabels = getChildLabels(stepDetails, value);
        const usedVariables = getUsedVariables(value);

        let apiFormValues = { ...value };
        if (taskDefId === TASK_DEF_IDS.SWITCH) {
          apiFormValues = { ...convertCheckFieldNodeUserDataForApi(apiFormValues) };
        }

        onSubmit({ [FIELD_IDS.CONDITION]: apiFormValues }, childLabels, [], usedVariables);
        setValue({});
        setErrors({});
        break;
      }
      default:
    }
  };

  const handleCancel = useCallback(() => {
    setValue({});
    setErrors({});
    onCancel();
  }, [onCancel]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(CONDITION_MODAL_CONTEXT_ID);
  }, []);

  const conditionType = targetType || tget(stepDetails, 'uiMetadata.conditionType', EMPTY_STRING);

  const enableRecordChangeComparatorOperators = _includes(EVENT_TYPES_FOR_RECORD_CHANGE_OPERATORS, eventType);
  const fields = getFields(conditionType, enableRecordChangeComparatorOperators, conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName);

  const renderContent = () => (
    <FormWithSubmission
      className="p-t-12"
      isFetching={isLoading}
      contextId={CONDITION_MODAL_CONTEXT_ID}
      sections={SECTIONS}
      fields={fields}
      values={{ [FIELD_IDS.CONDITION]: value }}
      errors={errors}
      onAction={handleAction}
    />
  );

  useEffect(() => {
    if (mode === 'EDIT') {
      const userData = { ...tget(stepDetails, `userData.data.${FIELD_IDS.CONDITION}`) };

      if (!_isEmpty(userData)) {
        let newFormValues = { ...userData };

        if (taskDefId === TASK_DEF_IDS.SWITCH) {
          newFormValues = { ...convertCheckFieldNodeUserDataForForm(newFormValues) };
        }

        setValue(newFormValues);
      }
    }
  }, [stepDetails, mode, taskDefId]);

  return (
    <PropertyControlledComponent controllerProperty={!renderContentOnly} fallback={renderContent()}>
      <Modal
        destroyOnClose
        visible={visible}
        title={__('Set your condition')}
        content={renderContent()}
        width={Modal.SIZES.XL}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    </PropertyControlledComponent>
  );
};

ConditionModal.propTypes = {
  isLoading: PropTypes.bool,
  visible: PropTypes.bool,
  renderContentOnly: PropTypes.bool,
  eventType: PropTypes.string.isRequired,
  targetType: PropTypes.string,
  mode: PropTypes.string,
  stepDetails: PropTypes.object.isRequired,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  onChangeConditionModal: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

ConditionModal.defaultProps = {
  isLoading: false,
  visible: false,
  renderContentOnly: false,
  targetType: EMPTY_STRING,
  mode: EMPTY_STRING,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  onChangeConditionModal: _noop,
  onCancel: _noop,
  onSubmit: _noop,
};

export default ConditionModal;
