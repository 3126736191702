import _get from 'lodash/get';
import _map from 'lodash/map';
import _merge from 'lodash/merge';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import _isNaN from 'lodash/isNaN';
import _drop from 'lodash/drop';
import _nth from 'lodash/nth';
import _reduce from 'lodash/reduce';
import _noop from 'lodash/noop';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { CHART_TYPES, AXIS_TYPES } from '@tekion/tekion-components/molecules/tekionCharts/tekionCharts.constants';
import DEFAULT_OPTIONS from '@tekion/tekion-components/molecules/tekionCharts/tekionCharts.defaultOptions';

import { GRAPH_COLORS, UNIT_CONVERTER_LABEL } from '../constants/chartWidget.constants';

const unitConverterFunc = {
  us: (num) => num / 1000,
};

const unitConvter = (num, unit) => {
  if (_isEmpty(unit)) return num;
  return unitConverterFunc[unit](num);
};

const getAxisUnit = (axis) => {
  const unit = UNIT_CONVERTER_LABEL[tget(axis, 'unit', 'NA')];
  const unitToRenderer = _isEmpty(unit) ? '' : `(${unit})`;
  return unitToRenderer;
};

const getAxisConfig = (axisArr = EMPTY_ARRAY, overridesByAxisKey = EMPTY_OBJECT) =>
  _map(axisArr, (axis, index) => ({
    title: { text: `${_get(axis, 'displayName', '')} ${getAxisUnit(axis)}` },
    crosshair: true,
    ..._get(overridesByAxisKey, axis?.name, EMPTY_OBJECT),
    opposite: index % 2 === 1,
  }));

const getXAxisCategories = (dataDistribution = EMPTY_ARRAY) =>
  _reduce(
    dataDistribution,
    (result, data) => {
      const pair = _get(data, 'data', EMPTY_ARRAY);
      const x = _nth(pair);
      const xNumber = _toNumber(x);
      if (_isNaN(xNumber)) {
        result.push(x);
      }
      return result;
    },
    [],
  );

const getSeriesConfig = (axisArr = EMPTY_ARRAY, dataDistribution = EMPTY_ARRAY) =>
  _map(axisArr, (axis, index) => {
    const unit = tget(axis, 'unit', '');
    const unitLabel = !_isEmpty(unit) ? UNIT_CONVERTER_LABEL[unit] : '';
    return {
      name: _get(axis, 'displayName', ''),
      id: _get(axis, 'name', ''),
      yAxis: index,
      data: _map(dataDistribution, (data) => {
        const pair = _get(data, 'data', EMPTY_ARRAY);
        const x = _nth(pair);
        const y = _nth(pair, index + 1);
        const xNumber = _toNumber(x);
        const xx = _isNaN(xNumber) ? x : xNumber;
        return [xx, unitConvter(_toNumber(y), unit)];
      }),
      tooltip: {
        valueSuffix: unitLabel,
        valueDecimals: 2,
      },
    };
  });

const getDefaultGenericChartOptions = (axis, dataDistribution, handleChartAreaSelection, chartType = CHART_TYPES.LINE) => {
  const xAxis = _nth(axis);
  const yAxisArr = _drop(axis);
  const categories = getXAxisCategories(dataDistribution);
  const options = {
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },
    colors: GRAPH_COLORS,
    chart: {
      type: chartType,
      zoomType: 'x',
      panning: true,
      panKey: 'shift',
      events: {
        selection: !_isEmpty(categories) ? _noop : handleChartAreaSelection,
      },
    },
    xAxis: getAxisConfig([xAxis]),
    yAxis: getAxisConfig(yAxisArr),
    tooltip: {
      shared: true,
    },
    series: getSeriesConfig(yAxisArr, dataDistribution),
  };
  if (!_isEmpty(categories)) {
    options.xAxis[0].categories = categories;
  } else {
    options.xAxis[0].type = AXIS_TYPES.DATE_TIME;
  }
  return _merge({}, DEFAULT_OPTIONS, options);
};

export { getDefaultGenericChartOptions };
