import React from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';

import { tget } from '@tekion/tekion-base/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import AsyncPaginatedSelect from '../../../../../../../organisms/asyncPaginatedSelect/AsyncPaginatedSelect';

import { getOptionsForProfile, serviceHandlerForGetAllProfilesByWorkspaceId } from './profileSelect.helpers';
import ACTION_TYPES from '../../constants/tenantUserForm.actionTypes';

import styles from './profileSelect.module.scss';

const ProfileSelect = ({ column, index, value, tdProps }) => {
  const { onAction, selectedWorkspaces } = tget(tdProps, 'rest', EMPTY_OBJECT);
  const { key } = column;

  const selectedWorkspace = tget(selectedWorkspaces, index);
  const getPayload = () => selectedWorkspace;
  const serviceHandler = !_isEmpty(selectedWorkspace) ? serviceHandlerForGetAllProfilesByWorkspaceId : _noop;

  const handleActionForProfile = (action) => {
    const { type, payload } = action;
    if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
      onAction({
        type: ACTION_TYPES.ON_USER_WORKSPACE_UPDATE,
        payload: {
          index,
          key: tget(payload, 'id'),
          value: tget(payload, 'value'),
        },
      });
    }
  };

  return (
    <AsyncPaginatedSelect
      id={key}
      isMulti={false}
      value={value}
      fieldClassName={styles.profileSelect}
      serviceHandler={serviceHandler}
      getOptions={getOptionsForProfile}
      onAction={handleActionForProfile}
      getPayload={getPayload}
      isDisabled={_isEmpty(selectedWorkspace)}
    />
  );
};

ProfileSelect.propTypes = {
  column: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string]),
  tdProps: PropTypes.shape({
    rest: PropTypes.shape({
      onAction: PropTypes.func.isRequired,
      selectedWorkspaces: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }).isRequired,
};

ProfileSelect.defaultProps = {
  value: EMPTY_ARRAY,
};

export default ProfileSelect;
