const FIELDS_FORM_FIELD_IDS = {
  GENERAL_DETAILS_FORM: 'generalDetails',
  DEPENDENCY_CONFIG_FORM: 'dependencyConfig',
  VIEW_DETAILS_FORM: 'viewDetails',
  SELECT_OPTIONS_FORM: 'selectOptions',
  RELATIONSHIP_DETAILS_FORM: 'relationshipDetails',
  CHILD_AGGREGATE_SUMMARY_FORM: 'childAggregateSummary',
  COMPLEX_FIELD_DETAILS_FORM: 'complexFieldDetails',
  PROPERTIES_FORM: 'properties',
  TEXT_PROPERTIES_FORM: 'textProperties',
  LOOKUP_DETAILS_FORM: 'lookupDetails',
  MEDIA_PROPERTIES_FORM: 'mediaProperties',
};

export default FIELDS_FORM_FIELD_IDS;
