import produce from 'immer';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { tget } from '@tekion/tekion-base/utils/general';

import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import PAGE_IDS from '../../../../../constants/PageIds.constants';

// Actions
import { createSetting } from '../../../../../../../actions/approvalManagement.actions';

const handleFormInit = () => {};

const handleFieldChange = ({ params = EMPTY_OBJECT, setState }) => {
  const { id, value } = params;

  setState(
    produce((draft) => {
      _set(draft, `formValues.${id}`, value);
    }),
  );
};

const handleErrors = ({ params = EMPTY_OBJECT, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const handleSubmit = async ({ getState, setState }) => {
  const { match, formValues, history } = getState();
  const { settingName } = tget(match, 'params', EMPTY_OBJECT);

  setState({ isSubmitting: true });

  if (_isEmpty(settingName)) {
    const response = await createSetting(formValues);
    if (!_isEmpty(response)) {
      const { name } = response;
      setTimeout(() => {
        history.push(`${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_SETTING_DETAIL}/${name}`);
      }, ES_REFETCH_DELAY);
    } else {
      setState({ isSubmitting: false });
    }
  }
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleFormInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
};

export default ACTION_HANDLERS;
