import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cx from 'classnames';

import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import Modal from '@tekion/tekion-components/molecules/Modal';

import WithSize from '../../../../../connectors/withSize';

import ACTION_HANDLERS from './helpers/dataImportForm.actionHandlers';
import getFields from './helpers/dataImportForm.fields';
import getSections from './helpers/dataImportForm.sections';
import { getFieldMappingCount } from './helpers/dataImportForm.helpers';

import { DATA_IMPORT_FORM_CONTEXT_ID, FIELD_IDS, PRIMARY_BUTTON_LABELS } from './constants/dataImportForm.constants';
import ACTION_TYPES from './constants/dataImportForm.actionTypes';

import styles from './dataImportForm.module.scss';

const DataImportForm = ({
  isSaveLoading,
  isLoadingFieldMapping,
  isColumnDefinitionsEdited,
  isConfirmationModalVisible,
  contentHeight,
  templateId,
  columnDefinitions,
  dataImportFormValue,
  errors,
  entityFieldOptions,
  onAction,
}) => {
  const operationType = tget(dataImportFormValue, FIELD_IDS.OPERATION_TYPE);
  const mediaId = tget(dataImportFormValue, FIELD_IDS.MEDIA_ID);

  const handleRedirect = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_REDIRECTION,
    });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(DATA_IMPORT_FORM_CONTEXT_ID);
  }, []);

  const handleStartImportTask = useCallback(() => {
    onAction({
      type: ACTION_TYPES.START_IMPORT_TASK,
    });
  }, [onAction]);

  const handleCancelConfirmationModal = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CANCEL_CONFIRMATION_MODAL,
    });
  }, [onAction]);

  const fields = useMemo(
    () => getFields(isLoadingFieldMapping, operationType, entityFieldOptions, columnDefinitions),
    [isLoadingFieldMapping, operationType, entityFieldOptions, columnDefinitions],
  );

  const sections = useMemo(() => getSections(templateId), [templateId]);

  const primaryButtonLabel = useMemo(() => {
    if (_isEmpty(templateId)) {
      return PRIMARY_BUTTON_LABELS.GET_TEMPLATE;
    } else if (isColumnDefinitionsEdited) {
      return PRIMARY_BUTTON_LABELS.SAVE_TEMPLATE;
    } else {
      return PRIMARY_BUTTON_LABELS.IMPORT;
    }
  }, [isColumnDefinitionsEdited, templateId]);

  const okButtonProps = useMemo(
    () => ({
      className: styles.importButton,
    }),
    [],
  );

  const renderModalContent = useCallback(() => {
    const { mappedFieldsCount = 0, unmappedFieldsCount = 0 } = getFieldMappingCount(columnDefinitions);

    return (
      <div className={styles.modalContentContainer}>
        <span>
          {__('Mapped Fields ')}- {mappedFieldsCount}
        </span>
        <span>
          {__('Unmapped Fields ')}- {unmappedFieldsCount}
        </span>
      </div>
    );
  }, [columnDefinitions]);

  return (
    <Page className="full-height full-width">
      <Page.Header hasBack goBackHandler={handleRedirect}>
        <Heading className="full-width">{__('Create Import')}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          contextId={DATA_IMPORT_FORM_CONTEXT_ID}
          fields={fields}
          sections={sections}
          values={dataImportFormValue}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>
      <Page.Footer>
        <Modal
          visible={isConfirmationModalVisible}
          destroyOnClose
          width={Modal.SIZES.SM}
          title={__('Confirmation')}
          submitBtnText={__('Start Import')}
          okButtonProps={okButtonProps}
          onCancel={handleCancelConfirmationModal}
          onSubmit={handleStartImportTask}
        >
          {renderModalContent()}
        </Modal>
        <SaveComponent
          primaryActionLoading={isSaveLoading}
          isPrimaryDisabled={_isEmpty(mediaId)}
          primaryButtonLabel={primaryButtonLabel}
          primaryClassName={cx({
            [styles.getTemplate]: primaryButtonLabel === PRIMARY_BUTTON_LABELS.GET_TEMPLATE,
            [styles.importButton]: primaryButtonLabel === PRIMARY_BUTTON_LABELS.IMPORT,
          })}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleRedirect}
        />
      </Page.Footer>
    </Page>
  );
};

DataImportForm.propTypes = {
  isSaveLoading: PropTypes.bool,
  isLoadingFieldMapping: PropTypes.bool,
  isColumnDefinitionsEdited: PropTypes.bool,
  isConfirmationModalVisible: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  templateId: PropTypes.string,
  dataImportFormValue: PropTypes.object,
  columnDefinitions: PropTypes.array,
  errors: PropTypes.object,
  entityFieldOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

DataImportForm.defaultProps = {
  isSaveLoading: false,
  isLoadingFieldMapping: false,
  isColumnDefinitionsEdited: false,
  isConfirmationModalVisible: false,
  templateId: EMPTY_STRING,
  dataImportFormValue: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  columnDefinitions: EMPTY_ARRAY,
  entityFieldOptions: EMPTY_ARRAY,
};

export default compose(WithSize({ hasPageFooter: 1, hasPageHeader: 1 }), withActions({}, ACTION_HANDLERS))(DataImportForm);
