const APPROVED_ICON = 'icon-tick-filled';
const REJECTED_ICON = 'icon-close-filled';

const getStatusIconToDisplay = (hasUserApproved, hasUserRejected) => {
  if (hasUserApproved) return APPROVED_ICON;
  if (hasUserRejected) return REJECTED_ICON;
  return '';
};

const getStatusTextToDisplay = (hasUserApproved, hasUserRejected) => {
  if (hasUserApproved) return __('Approved');
  if (hasUserRejected) return __('Rejected');
  return '';
};

export { getStatusIconToDisplay, getStatusTextToDisplay };
