import produce from 'immer';

import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _castArray from 'lodash/castArray';
import _forEach from 'lodash/forEach';
import _remove from 'lodash/remove';
import _includes from 'lodash/includes';
import _unset from 'lodash/unset';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { ASSIGNED_VARIABLE_FIELD_IDS, VARIABLE_TYPES } from '../constants/workflow.assignVariableConstants';

const getWorkflowVariablesContext = (variablesInfo) => {
  const variablesContext = {};
  _forEach(variablesInfo, (variableInfo, variableName) => {
    const type = tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE, '');
    if (type === VARIABLE_TYPES.ENTITY_RECORD || type === VARIABLE_TYPES.ENTITY_RECORD_LIST) {
      const entityName = tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME, '');
      _set(variablesContext, `${variableName}.${ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE}`, type);
      _set(variablesContext, `${variableName}.${ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME}`, entityName);
    } else {
      const fieldType = tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_FIELD_TYPE, 'TEXT');
      const dataType = tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_DATA_TYPE, 'TEXT');
      const multiValued = tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.MULTIVALUED, false);
      const fieldDefinitionMinimal = {
        [ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_DATA_TYPE]: dataType,
        [ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_FIELD_TYPE]: fieldType,
        [ASSIGNED_VARIABLE_FIELD_IDS.MULTI_VALUED]: multiValued,
      };
      _set(variablesContext, `${variableName}.${ASSIGNED_VARIABLE_FIELD_IDS.FIELD_DEFINITION_MINIMAL}`, fieldDefinitionMinimal);
      _set(variablesContext, `${variableName}.${ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE}`, type);
    }
  });

  return variablesContext;
};

const getUpdatedWorkflowWithVariableInfo = (workflow, currentNodeId, assignedVariableInfo, usedVariableInfo) =>
  produce(workflow, (draft) => {
    if (_isEmpty(currentNodeId)) {
      return;
    }

    if (!_isEmpty(usedVariableInfo)) {
      _forEach(usedVariableInfo, (variable) => {
        const usedAtNodeIds = [...tget(draft, `uiMetadata.variablesInfo.${variable}.${ASSIGNED_VARIABLE_FIELD_IDS.USED_AT_NODE_IDS}`, [])];
        usedAtNodeIds.push(currentNodeId);
        _set(draft, `uiMetadata.variablesInfo.${variable}.${ASSIGNED_VARIABLE_FIELD_IDS.USED_AT_NODE_IDS}`, usedAtNodeIds);
      });
    }

    if (!_isEmpty(assignedVariableInfo)) {
      const assignedVariable = tget(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_NAME);

      if (!_isEmpty(tget(draft, `uiMetadata.variablesInfo.${assignedVariable}`))) {
        const updatedAssignedAtNodeIds = [
          ...tget(draft, `uiMetadata.variablesInfo.${assignedVariable}.${ASSIGNED_VARIABLE_FIELD_IDS.ASSIGNED_AT_NODE_IDS}`, EMPTY_ARRAY),
        ];
        updatedAssignedAtNodeIds.push(currentNodeId);
        _set(draft, `uiMetadata.variablesInfo.${assignedVariable}.${ASSIGNED_VARIABLE_FIELD_IDS.ASSIGNED_AT_NODE_IDS}`, updatedAssignedAtNodeIds);
      } else {
        const updatedAssignedVariableInfo = {
          ...assignedVariableInfo,
          [ASSIGNED_VARIABLE_FIELD_IDS.DECLARED_AT_NODE_ID]: currentNodeId,
          [ASSIGNED_VARIABLE_FIELD_IDS.ASSIGNED_AT_NODE_IDS]: _castArray(currentNodeId),
        };
        _set(draft, `uiMetadata.variablesInfo.${assignedVariable}`, updatedAssignedVariableInfo);
      }
    }

    const updatedVariablesInfo = { ...tget(draft, 'uiMetadata.variablesInfo') };

    _forEach(updatedVariablesInfo, (variable) => {
      const variableName = tget(variable, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_NAME);
      const modifiedUsedVariables = [...tget(variable, ASSIGNED_VARIABLE_FIELD_IDS.USED_AT_NODE_IDS, [])];
      const modifiedAssignedVariables = [...tget(variable, ASSIGNED_VARIABLE_FIELD_IDS.ASSIGNED_AT_NODE_IDS, [])];
      let declaredAtNode = tget(variable, ASSIGNED_VARIABLE_FIELD_IDS.DECLARED_AT_NODE_ID, '');

      if (_includes(modifiedUsedVariables, currentNodeId) && !_includes(usedVariableInfo, variableName)) {
        _remove(modifiedUsedVariables, (value) => value === currentNodeId);
      }
      if (
        _includes(modifiedAssignedVariables, currentNodeId) &&
        tget(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_NAME) !== variableName
      ) {
        _remove(modifiedAssignedVariables, (value) => value === currentNodeId);
      }
      if (declaredAtNode === currentNodeId && tget(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_NAME) !== variableName) {
        declaredAtNode = getArraySafeValue(modifiedAssignedVariables) || '';
        _set(updatedVariablesInfo, `${variableName}.${ASSIGNED_VARIABLE_FIELD_IDS.DECLARED_AT_NODE_ID}`, declaredAtNode);
      }
      if (_isEmpty(declaredAtNode)) {
        _unset(updatedVariablesInfo, variableName);
      } else {
        _set(updatedVariablesInfo, `${variableName}.${ASSIGNED_VARIABLE_FIELD_IDS.USED_AT_NODE_IDS}`, modifiedUsedVariables);
        _set(updatedVariablesInfo, `${variableName}.${ASSIGNED_VARIABLE_FIELD_IDS.ASSIGNED_AT_NODE_IDS}`, modifiedAssignedVariables);
      }
    });

    _set(draft, 'uiMetadata.variablesInfo', updatedVariablesInfo);
    _set(draft, 'variables', getWorkflowVariablesContext(updatedVariablesInfo));
  });

export { getWorkflowVariablesContext };
export default getUpdatedWorkflowWithVariableInfo;
