import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';

import { tget } from '@tekion/tekion-base/utils/general';
import Heading from '@tekion/tekion-components/atoms/Heading/Heading';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { getApprovalStages, getStatusName } from './helpers/approvalStagesStatus.helpers';

import { APPROVAL_CENTRE_FIELD_IDS, APPROVAL_STATUS_TYPES } from '../../../../../../../constants/approvalCentre.constants';

import styles from './approvalStagesStatus.module.scss';

const ApprovalStagesStatus = ({ className, approvalProcess, approvalTasks }) => {
  const stages = useMemo(() => getApprovalStages(approvalProcess, approvalTasks), [approvalProcess, approvalTasks]);

  const renderIndex = useCallback(
    (status, stageIndex) => (
      <div
        className={cx({
          [styles.completedContainer]: status === APPROVAL_STATUS_TYPES.COMPLETED && _size(stages) > stageIndex + 1,
          [styles.indexContainer]: status !== APPROVAL_STATUS_TYPES.COMPLETED && _size(stages) > stageIndex + 1,
          [styles.lastIndexContainer]: _size(stages) === stageIndex + 1,
        })}
      >
        <div
          className={cx(styles.index, {
            [styles.pending]: status === APPROVAL_STATUS_TYPES.PENDING,
            [styles.completed]: status === APPROVAL_STATUS_TYPES.COMPLETED,
            [styles.rejected]: status === APPROVAL_STATUS_TYPES.REJECTED,
            [styles.expired]: status === APPROVAL_STATUS_TYPES.CANCELLED || status === APPROVAL_STATUS_TYPES.EXPIRED || _isEmpty(status),
          })}
        >
          {__(`${stageIndex + 1}`)}
        </div>
      </div>
    ),
    [stages],
  );

  const renderStatusLabel = useCallback(
    (status, statusLabel) => (
      <div
        className={cx(styles.statusLabel, {
          [styles.pendingColor]: status === APPROVAL_STATUS_TYPES.PENDING,
          [styles.completedColor]: status === APPROVAL_STATUS_TYPES.COMPLETED,
          [styles.rejectedColor]: status === APPROVAL_STATUS_TYPES.REJECTED,
          [styles.expiredColor]: status === APPROVAL_STATUS_TYPES.CANCELLED || status === APPROVAL_STATUS_TYPES.EXPIRED || _isEmpty(status),
        })}
      >
        {statusLabel}
      </div>
    ),
    [],
  );
  return (
    <div className={cx(styles.mainContainer, className)}>
      <Heading className="p-t-16 p-l-16" size={3}>
        {__('Approval Stages')}
      </Heading>
      <div className={styles.container}>
        {_map(stages, (stage, stageIndex) => {
          const status = tget(stage, APPROVAL_CENTRE_FIELD_IDS.STATUS);
          const statusLabel = getStatusName(status);

          return (
            <div className={styles.stageContainer}>
              {renderIndex(status, stageIndex)}
              {renderStatusLabel(status, statusLabel)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ApprovalStagesStatus.propTypes = {
  className: PropTypes.string,
  approvalProcess: PropTypes.object,
  approvalTasks: PropTypes.array,
};

ApprovalStagesStatus.defaultProps = {
  className: EMPTY_STRING,
  approvalProcess: EMPTY_OBJECT,
  approvalTasks: EMPTY_ARRAY,
};

export default ApprovalStagesStatus;
