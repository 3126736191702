// Fields
import FIELD_IDS from '../constants/tenantUserForm.fieldIds';
import { FORM_MODES } from '../../../../../../constants/general.constants';

import styles from '../tenantUserForm.module.scss';

const getUserManagementSections = (formMode) => [
  {
    className: styles.userFormSections,
    header: {
      className: 'p-t-12',
      label: __('User details'),
    },

    rows: [
      {
        className: 'p-t-8',
        columns: [FIELD_IDS.NAME, FIELD_IDS.DISPLAY_NAME],
      },
      {
        columns: [FIELD_IDS.EMAIL, FIELD_IDS.PHONE],
      },
    ],
  },
  {
    className: styles.userFormSections,
    subHeader:
      formMode === FORM_MODES.EDIT
        ? {
            label: __('Status'),
            className: styles.userStatusFormContainer,
          }
        : [],
    rows: [
      {
        columns: formMode === FORM_MODES.EDIT ? [FIELD_IDS.ACTIVE] : [],
      },
    ],
  },
  {
    className: styles.userFormSections,
    rows: [
      {
        columns: [FIELD_IDS.PROFILE_PICTURE],
      },
    ],
  },
];

export { getUserManagementSections };
