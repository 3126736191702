import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { APPROVAL_VALIDITY_TYPES } from '../../../../../../../constants/approvalCentre.constants';
import { CONDITION_BUILDER_FIELD_IDS } from '../../../../../../../organisms/conditionBuilder';
import FIELD_IDS from './approvalProcessForm.fieldIds';

const CONTEXT_ID = 'APPROVAL_PROCESS';

const INITIAL_STATE = {
  formValues: {
    [FIELD_IDS.INACTIVE]: false,
    [FIELD_IDS.VALIDITY_TYPE]: APPROVAL_VALIDITY_TYPES.RELATIVE,
    [FIELD_IDS.CONDITION]: {
      [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: [EMPTY_OBJECT],
      [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: '1',
    },
  },
};

export { CONTEXT_ID, INITIAL_STATE };
