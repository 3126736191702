import React from 'react';

function createDefaultElementPlugin() {
  return {
    renderElement: () => (props) => {
      // eslint-disable-next-line react/prop-types
      const { attributes, children } = props;
      return <p {...attributes}>{children}</p>;
    },
  };
}

export default createDefaultElementPlugin;
