import _isNil from 'lodash/isNil';

// Readers
import envReader from '../../readers/env.reader';
import LocalStorageReader from '../../readers/localStorage.reader';

const getDefaultHeaders = () => {
  const userInfo = LocalStorageReader.userInfo();
  const { platform } = envReader;

  if (_isNil(userInfo)) {
    return {
      clientId: 'web',
      platform,
    };
  }

  const { userId, workspaceId, tenantId } = userInfo;
  return {
    'tekion-api-token': LocalStorageReader.apiToken(),
    clientId: 'web',
    userId,
    workspaceId,
    tenantId,
    platform,
  };
};

export { getDefaultHeaders };
