import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _unset from 'lodash/unset';
import _isNil from 'lodash/isNil';
import _set from 'lodash/set';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { tget } from '@tekion/tekion-base/utils/general';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import {
  createApprovalRequestUsingProcess,
  fetchMatchingApprovalProcess,
  getApprovalRequestById,
  updateApprovalRequestUsingProcess,
} from '../../../../../../actions/approvalCentre.actions';

import { getPayloadForApprovalMatchingProcess } from './approvalRequestForm.helpers';

import { CUSTOM_ENTITY_ACTION_TYPES, CUSTOM_ENTITY_CATEGORY } from '../../../../../../constants/approvalCentre.constants';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import PAGE_IDS from '../../../../constants/pageIds.constants';
import { ACTION_TYPES, CONTEXT_IDS } from '../constants/approvalRequestForm.constants';
import FIELD_IDS from '../constants/approvalRequestForm.fieldIds';

const handleMatchingProcessFetch = async ({ payload, setState }) => {
  const name = tget(payload, FIELD_IDS.NAME);
  const data = tget(payload, FIELD_IDS.DATA);

  // API has name & data as mandatory fields
  if (!_isEmpty(name) && !_isNil(data)) {
    setState({ isMatchingApprovalProcessLoading: true, isMatchingApprovalProcessLoaded: false });

    const matchingApprovalProcess = await fetchMatchingApprovalProcess(payload);
    setState({ isMatchingApprovalProcessLoading: false, matchingApprovalProcess, isMatchingApprovalProcessLoaded: true });

    return matchingApprovalProcess;
  }

  return EMPTY_OBJECT;
};

const handleInit = async ({ getState, setState }) => {
  const { match } = getState();

  const approvalId = tget(match, 'params.approvalId');
  if (!_isEmpty(approvalId)) {
    setState({ isRequestLoading: true, formMode: FORM_MODES.EDIT });

    const approvalData = await getApprovalRequestById(approvalId);

    setState({ isRequestLoading: false, formValues: approvalData, existingRequestData: approvalData });

    handleMatchingProcessFetch({ payload: approvalData, setState });
  } else {
    setState({ formMode: FORM_MODES.CREATE });
  }
};

const handleIdentifierFieldChange = async ({ params, getState, setState }) => {
  const { id, value } = params;
  const { formValues } = getState();

  if (id === FIELD_IDS.GROUP) {
    const updatedValues = { ...formValues, [id]: value };

    _unset(updatedValues, FIELD_IDS.TYPE);
    _unset(updatedValues, FIELD_IDS.DATA);

    setState({
      formValues: updatedValues,
      isMatchingApprovalProcessLoaded: false,
      matchingApprovalProcess: {},
    });
  } else if (id === FIELD_IDS.CATEGORY) {
    const updatedValues = { ...formValues, [id]: value };

    _unset(updatedValues, FIELD_IDS.TYPE);
    _unset(updatedValues, FIELD_IDS.DATA);

    setState({
      formValues: updatedValues,
      isMatchingApprovalProcessLoaded: false,
      matchingApprovalProcess: {},
    });
  } else if (id === FIELD_IDS.TYPE) {
    const updatedValues = { ...formValues, [id]: value };

    _unset(updatedValues, FIELD_IDS.DATA);

    setState({
      formValues: updatedValues,
      isMatchingApprovalProcessLoaded: false,
      matchingApprovalProcess: {},
    });
  } else {
    setState({ formValues: { ...formValues, [id]: value } });
  }
};

const handleSettingFieldChange = ({ setState, params = EMPTY_OBJECT }) => {
  // settingFormValues will be used to fetch matching approval process on blur.
  // Need to maintain it in state as ViewViewer (FormViewRenderer) does not send them on blur.

  const { updatedValues } = params;
  setState({ settingFormValues: updatedValues });
};

const handleIdentifierFieldBlur = async ({ getState, setState }) => {
  // formValues: Identifier form's values, source of truth
  // settingFormValues: formValues for setting form, maintained only for process fetching. Not used for submit.
  // recordFormValues: formValues for record form, maintained only for process fetching. Not used for submit.

  // Either of recordFormValues and settingFormValues will be used, depending on the categroy.

  const { formValues, settingFormValues, recordFormValues } = getState();

  const group = getArraySafeValue(tget(formValues, FIELD_IDS.GROUP, EMPTY_ARRAY));
  const category = getArraySafeValue(tget(formValues, FIELD_IDS.CATEGORY, EMPTY_ARRAY));

  if (!_isEmpty(group) && !_isEmpty(category)) {
    if (category === CUSTOM_ENTITY_CATEGORY) {
      if (!_isEmpty(recordFormValues)) {
        const payload = getPayloadForApprovalMatchingProcess(recordFormValues, formValues);
        handleMatchingProcessFetch({ payload, setState });
      }
    } else {
      const payload = {
        ...formValues,
        [FIELD_IDS.DATA]: settingFormValues,
      };
      handleMatchingProcessFetch({ payload, setState });
    }
  }
};

const handleSettingFormBlur = async ({ getState, setState }) => {
  const { formValues, settingFormValues } = getState();

  const payload = {
    ...formValues,
    [FIELD_IDS.DATA]: settingFormValues,
  };

  handleMatchingProcessFetch({ payload, setState });
};

const handleRecordFormFieldBlur = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  // recordFormValues will be used to fetch matching approval process on blur from identifier form.
  // Need to maintain it in state to be able to use in identifier blur.

  const { formValues } = getState();
  setState({ recordFormValues: params });
  const payload = getPayloadForApprovalMatchingProcess(params, formValues);
  handleMatchingProcessFetch({ payload, setState });
};

const handleSubmitClick = ({ getState, setState }) => {
  const { formValues } = getState();
  const categoryValue = getArraySafeValue(tget(formValues, FIELD_IDS.CATEGORY, EMPTY_ARRAY));

  setState(
    {
      isIdentifierReadyToSubmit: false,
      isDataReadyToSubmit: false,
      dataFormValues: EMPTY_OBJECT,
    },
    () => {
      triggerSubmit(CONTEXT_IDS.IDENTIFIER);

      if (categoryValue === CUSTOM_ENTITY_CATEGORY) {
        triggerSubmit(CONTEXT_IDS.ENTITY_RECORD);
      } else {
        triggerSubmit(CONTEXT_IDS.DATA);
      }
    },
  );
};

const handleErrors = ({ params, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const submitApprovalRequest = async ({
  formValues,
  dataFormValues,
  matchingApprovalProcess,
  formMode,
  history,
  setState,
  isMountedInsideApplication,
  appRoute,
}) => {
  // NOTE: dataFormValues can point to values coming from either setting fields form or record form. Depending on whose submit got called.
  const payload = _cloneDeep(formValues);

  const category = getArraySafeValue(tget(formValues, FIELD_IDS.CATEGORY, EMPTY_ARRAY));

  if (category === CUSTOM_ENTITY_CATEGORY) {
    const id = tget(dataFormValues, 'id');
    if (!_isEmpty(id)) {
      _set(payload, [FIELD_IDS.DATA, FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.ID], id);
      _set(payload, [FIELD_IDS.DATA, FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.ACTION_TYPE], CUSTOM_ENTITY_ACTION_TYPES.UPDATE_CUSTOM_ENTITY);
    } else {
      _set(payload, [FIELD_IDS.DATA, FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.ACTION_TYPE], CUSTOM_ENTITY_ACTION_TYPES.CREATE_CUSTOM_ENTITY);
    }

    _set(payload, [FIELD_IDS.DATA, FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.CUSTOM_ENTITY_REQUEST], dataFormValues);
  } else {
    _set(payload, FIELD_IDS.DATA, dataFormValues);
  }

  let _matchingApprovalProcess = matchingApprovalProcess;
  if (_isEmpty(_matchingApprovalProcess)) {
    _matchingApprovalProcess = await handleMatchingProcessFetch({ payload, setState });
  }

  setState({ isSubmitting: true });

  let response;

  if (formMode === FORM_MODES.CREATE) {
    response = await createApprovalRequestUsingProcess(_matchingApprovalProcess, payload);
  } else {
    const approvalId = tget(formValues, 'id');
    response = await updateApprovalRequestUsingProcess(approvalId, _matchingApprovalProcess, payload);
  }

  if (!_isEmpty(response)) {
    setTimeout(() => {
      let prefixPathName = '';

      if (isMountedInsideApplication) {
        prefixPathName = appRoute;
      }

      setState({ isSubmitting: false }, () =>
        history.push(`${prefixPathName}/${PAGE_IDS.APPROVAL_CENTRE}/${PAGE_IDS.MANAGEMENT}/${PAGE_IDS.APPROVAL_REQUESTS}`),
      );
    }, ES_REFETCH_DELAY);
  } else {
    setState({ isSubmitting: false });
  }
};

const handleIdentifierFormSubmit = async ({ setState, getState }) => {
  const { history, isMountedInsideApplication = false, appRoute } = getState();

  setState((curState) => {
    const { isDataReadyToSubmit, matchingApprovalProcess, formMode } = curState;

    if (isDataReadyToSubmit) {
      const { formValues, dataFormValues } = curState;

      submitApprovalRequest({
        formValues,
        matchingApprovalProcess,
        formMode,
        history,
        dataFormValues,
        isMountedInsideApplication,
        appRoute,
        setState,
      });
    }

    return {
      ...curState,
      isIdentifierReadyToSubmit: true,
    };
  });
};

const handleSettingFormSubmit = async ({ getState, setState }) => {
  const { history, isMountedInsideApplication = false, appRoute } = getState();
  setState((curState) => {
    const { isIdentifierReadyToSubmit, formMode, formValues, settingFormValues, matchingApprovalProcess } = curState;

    if (isIdentifierReadyToSubmit) {
      submitApprovalRequest({
        formValues,
        dataFormValues: settingFormValues,
        matchingApprovalProcess,
        formMode,
        history,
        setState,
        isMountedInsideApplication,
        appRoute,
      });
    }

    return {
      ...curState,
      isDataReadyToSubmit: true,
      dataFormValues: settingFormValues,
    };
  });
};

const handleRecordFormSubmit = ({ getState, setState, params }) => {
  const { history, isMountedInsideApplication = false, appRoute } = getState();
  setState((curState) => {
    const { isIdentifierReadyToSubmit, formValues, matchingApprovalProcess, formMode } = curState;

    if (isIdentifierReadyToSubmit) {
      submitApprovalRequest({
        formValues,
        dataFormValues: params,
        matchingApprovalProcess,
        formMode,
        history,
        setState,
        isMountedInsideApplication,
        appRoute,
      });
    }

    return {
      ...curState,
      isDataReadyToSubmit: true,
      dataFormValues: params,
    };
  });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleIdentifierFieldChange,
  [ACTION_TYPES.ON_SETTING_FIELD_CHANGE]: handleSettingFieldChange,
  [FORM_ACTION_TYPES.ON_FIELD_BLUR]: handleIdentifierFieldBlur,
  [ACTION_TYPES.ON_SETTING_FORM_BLUR]: handleSettingFormBlur,
  [ACTION_TYPES.ON_RECORD_FORM_FIELD_BLUR]: handleRecordFormFieldBlur,
  [ACTION_TYPES.ON_SUBMIT_CLICK]: handleSubmitClick,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleIdentifierFormSubmit,
  [ACTION_TYPES.ON_SETTING_FORM_SUBMIT]: handleSettingFormSubmit,
  [ACTION_TYPES.ON_RECORD_FORM_SUBMIT]: handleRecordFormSubmit,
};

export default ACTION_HANDLERS;
