import React, { useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';

// Tekion-components
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Modal from '@tekion/tekion-components/molecules/Modal';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';

// Tekion-connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Connectors
import withSize from '../../../../../../connectors/withSize';

// Helpers
import { createTableProps } from './helpers/approvalGroupList.helpers';
import COLUMN_CONFIG from './helpers/approvalGroupList.columns';
import ACTION_HANDLERS from './helpers/approvalGroupList.actionHandlers';

// Constants
import { SUB_HEADER_PROPS, TABLE_MANAGER_PROPS } from './constants/approvalGroupList.constants';
import ACTION_TYPES from './constants/approvalGroupList.actionTypes';
import COLUMN_IDS from './constants/approvalGroupList.columnIds';

const ApprovalGroupList = ({
  isLoading,
  isGroupCreationInProgress,
  isGroupAddModalVisible,
  contentHeight,
  currentPage,
  pageSize,
  searchText,
  nextPageToken,
  values,
  errors,
  data,
  onAction,
}) => {
  const tableProps = useMemo(() => createTableProps(isLoading, currentPage, pageSize, onAction), [isLoading, currentPage, pageSize, onAction]);

  const handleSubmit = () => {
    onAction({ type: ACTION_TYPES.SUBMIT_GROUP_CREATION });
  };

  const handleCancel = () => {
    onAction({ type: ACTION_TYPES.CANCEL_GROUP_CREATION });
  };

  const groupNameField = (
    <TextInput id={COLUMN_IDS.GROUP} label={__('Group')} value={values?.[COLUMN_IDS.GROUP]} error={errors?.[COLUMN_IDS.GROUP]} onAction={onAction} />
  );

  return (
    <Page style={{ height: contentHeight }}>
      <TableManager
        tokenPagination
        nextPageToken={nextPageToken}
        searchText={searchText}
        subHeaderProps={SUB_HEADER_PROPS}
        tableManagerProps={TABLE_MANAGER_PROPS}
        tableProps={tableProps}
        columns={COLUMN_CONFIG}
        data={data}
        onAction={onAction}
      />
      <Modal
        destroyOnClose
        visible={isGroupAddModalVisible}
        loading={isGroupCreationInProgress}
        title={__('Create group')}
        submitBtnText={__('Create')}
        content={groupNameField}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};

ApprovalGroupList.propTypes = {
  isLoading: PropTypes.bool,
  isGroupCreationInProgress: PropTypes.bool,
  isGroupAddModalVisible: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  searchText: PropTypes.string,
  nextPageToken: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  data: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ApprovalGroupList.defaultProps = {
  isLoading: false,
  isGroupCreationInProgress: false,
  isGroupAddModalVisible: false,
  currentPage: DEFAULT_PAGE_INFO.start,
  pageSize: DEFAULT_PAGE_SIZE,
  searchText: EMPTY_STRING,
  nextPageToken: EMPTY_STRING,
  values: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  data: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: false }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ApprovalGroupList);
