import { defaultMemoize } from 'reselect';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';

import { FIELD_IDS as ROLES_FIELD_IDS } from '../../../../../../../constants/roles.constants';

const getRoleOptions = defaultMemoize((data, roleOptions) => {
  if (_isEmpty(data)) return;

  const roleData = _get(data, 'role');
  const label = _get(roleData, ROLES_FIELD_IDS.DISPLAY_NAME);
  const value = _get(roleData, ROLES_FIELD_IDS.ROLE_NAME);

  roleOptions.push({ label, value });

  const childRoles = _get(data, 'childRoles');

  _forEach(childRoles, (child) => {
    getRoleOptions(child, roleOptions);
  });
});

export { getRoleOptions };
