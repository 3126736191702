import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import withEventSubmissionHOC from '@tekion/tekion-components/pages/formPage/withEventSubmission';
import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import { getFormFields, getFormSections } from './helpers/addChartWidget.formConfig';
import { ADD_WIDGET_FORM_FIELDS } from './constants/addWidget.general.constant';

const Form = withEventSubmissionHOC(FormBuilder);

const AddChartWidget = (props) => {
  const { widgetName, contextId, formValues, errors, possibleXAxis, possibleYAxis, onAction } = props;

  const entityName = _get(formValues, `${ADD_WIDGET_FORM_FIELDS.ENTITY_NAME}.0`, '');
  const sections = useMemo(() => getFormSections(entityName), [entityName]);
  const fields = useMemo(
    () => getFormFields({ possibleXAxis, possibleYAxis, formValues, widgetName }),
    [possibleXAxis, possibleYAxis, formValues, widgetName],
  );

  return <Form fields={fields} values={formValues} onAction={onAction} sections={sections} errors={errors} contextId={contextId} />;
};

AddChartWidget.propTypes = {
  widgetName: PropTypes.string,
  contextId: PropTypes.string,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  possibleXAxis: PropTypes.array,
  possibleYAxis: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

AddChartWidget.defaultProps = {
  widgetName: EMPTY_STRING,
  contextId: '',
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  possibleXAxis: EMPTY_ARRAY,
  possibleYAxis: EMPTY_ARRAY,
};

export default AddChartWidget;
