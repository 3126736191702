import _forEach from 'lodash/forEach';
import _set from 'lodash/set';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _uniq from 'lodash/uniq';

// Constants
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

// Utils
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';
import { isSingleChildConditionType } from '@tekion/tekion-rule-engine/src/utils/core/index';

import { checkValueIsVariable, getVariableName } from '../../../../utils/workflowVariables.utils';

import DATA_TYPES from '../../../../../../../../../constants/fieldDefinition.dataTypes';
import { SWITCH_DEFAULT_CASE } from '../../../../constants/workflowBuilder.constants';
import { CONDITION_TYPES } from '../../../../constants/workflow.constants';
import { CHILD_LABELS } from '../conditionModal.constants';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import { CONDITION_BUILDER_TYPES } from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import { CONDITION_FIELD_IDS } from '../../../../../../../../../organisms/conditionBuilder';

const getChildLabels = (stepDetails, value) => {
  const conditionType = tget(stepDetails, 'uiMetadata.conditionType', '');
  const criteria = getArraySafeValue(tget(value, 'criteriaList', [{}]));
  const optionValues = tget(criteria, 'values', EMPTY_ARRAY);
  let childLabels = EMPTY_ARRAY;

  if (conditionType === CONDITION_TYPES.SWITCH) {
    childLabels = [...optionValues, SWITCH_DEFAULT_CASE.id];
  } else if (conditionType === CONDITION_TYPES.IF_ELSE) {
    childLabels = [CHILD_LABELS.TRUE, CHILD_LABELS.FALSE];
  } else if (isSingleChildConditionType(conditionType)) {
    childLabels = [CHILD_LABELS.TRUE];
  }

  return childLabels;
};

const getFilteredFieldDefinition = (conditionBuilderFieldDefinitionObject) => {
  let updatedConditionBuilderFieldDefinitionObject = {};
  _forEach(conditionBuilderFieldDefinitionObject, (fieldDef, entity) => {
    const updatedEntityFieldDef = _reduce(
      fieldDef,
      (result, value, key) => {
        const dataType = _get(value, 'dataType');
        if (dataType === DATA_TYPES.TEXT || dataType === DATA_TYPES.COMPLEX) {
          _set(result, key, value);
        }
        return result;
      },
      {},
    );
    updatedConditionBuilderFieldDefinitionObject = { ...updatedConditionBuilderFieldDefinitionObject, [entity]: updatedEntityFieldDef };
  });
  return updatedConditionBuilderFieldDefinitionObject;
};

const convertCheckFieldNodeUserDataForApi = (userData) => {
  const criteriaList = tget(userData, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST);
  return _head(criteriaList);
};

const convertCheckFieldNodeUserDataForForm = (apiUserData) => ({
  type: CONDITION_BUILDER_TYPES.CRITERIA,
  expression: '1',
  criteriaList: [{ ...apiUserData }],
});

const getUsedVariables = (formValues) => {
  const usedVariables = [];

  const criteriaList = tget(formValues, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST);
  _forEach(criteriaList, (criteria) => {
    const fieldName = tget(criteria, CONDITION_FIELD_IDS.FIELD, '');
    const fieldValue = tget(criteria, CONDITION_FIELD_IDS.VALUES, '');
    if (checkValueIsVariable(fieldName)) {
      usedVariables.push(getVariableName(fieldName));
    }
    if (checkValueIsVariable(fieldValue)) {
      usedVariables.push(getVariableName(fieldValue));
    }
  });

  return _uniq(usedVariables);
};

export { getChildLabels, getFilteredFieldDefinition, convertCheckFieldNodeUserDataForApi, convertCheckFieldNodeUserDataForForm, getUsedVariables };
