import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _size from 'lodash/size';

import Heading from '@tekion/tekion-components/atoms/Heading/Heading';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import LoadingSpinner from '@tekion/tekion-components/molecules/loadingSpinner';

import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';
import { EMPTY_OBJECT, EMPTY_STRING, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';

import NavigationItem from '../../../../../molecules/NavigationFlow/components/navigationItem/NavigationItem';
import ImportedFileCardView from './components/importedFileCardView/ImportedFileCardView';

import WithSize from '../../../../../connectors/withSize';

import COLUMN_CONFIG from './helpers/dataImportDetailPage.columns';
import ACTION_HANDLERS from './helpers/dataImportDetailPage.actionHandlers';
import { createTableProps, getSubHeaderProps } from './helpers/dataImportDetailPage.helpers';

import ACTION_TYPES from './constants/dataImportDetailPage.actionTypes';
import { DATA_IMPORT_NAVIGATION_DATA, TABLE_MANAGER_PROPS } from './constants/dataImportDetailPage.constants';

import mediaReader from '../../../../../readers/media.reader';

import styles from './dataImportDetailPage.module.scss';

const DataImportDetailPage = ({
  isLoading,
  isImportTaskLoading,
  currentPage,
  contentHeight,
  pageSize,
  nextPageToken,
  history,
  uploadedFileData,
  importedFileCardViewData,
  batchData,
  onAction,
}) => {
  const tableProps = useMemo(() => createTableProps(isLoading, currentPage, pageSize), [currentPage, isLoading, pageSize]);
  const subHeaderProps = useMemo(() => getSubHeaderProps(_size(batchData)), [batchData]);

  const handleNavigationItemClick = useCallback(
    (goTo) => {
      if (!_isEmpty(goTo)) {
        history.push(goTo);
      }
    },
    [history],
  );

  const headerNavigationData = useMemo(
    () => [...DATA_IMPORT_NAVIGATION_DATA, { label: __(mediaReader.fileName(uploadedFileData)), key: '2' }],
    [uploadedFileData],
  );

  const headerNavigation = useMemo(
    () =>
      _map(headerNavigationData, (data, index) => (
        <NavigationItem
          key={_get(data, 'key', EMPTY_STRING)}
          itemNumber={index}
          totalItems={_size(headerNavigationData)}
          data={data}
          onNavigationItemClick={handleNavigationItemClick}
        />
      )),
    [headerNavigationData, handleNavigationItemClick],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction]);

  if (isImportTaskLoading) {
    return <LoadingSpinner className={styles.loadingContainer} size={30} />;
  }

  return (
    <Page>
      <Page.Header>
        <Heading className={styles.headerNavigationContainer} size={2}>
          {headerNavigation}
        </Heading>
      </Page.Header>
      <Page.Body className={styles.pageBody} style={{ height: contentHeight }}>
        <ImportedFileCardView uploadedFileData={uploadedFileData} importedFileCardViewData={importedFileCardViewData} />
        <TableManager
          tokenPagination
          loading={isLoading}
          nextPageToken={nextPageToken}
          containerClassName={styles.tableContainer}
          subHeaderProps={subHeaderProps}
          tableManagerProps={TABLE_MANAGER_PROPS}
          tableProps={tableProps}
          columns={COLUMN_CONFIG}
          data={batchData}
          onAction={onAction}
        />
      </Page.Body>
    </Page>
  );
};

DataImportDetailPage.propTypes = {
  isLoading: PropTypes.bool,
  isImportTaskLoading: PropTypes.bool,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  contentHeight: PropTypes.number.isRequired,
  nextPageToken: PropTypes.string,
  history: PropTypes.object.isRequired,
  uploadedFileData: PropTypes.object,
  importedFileCardViewData: PropTypes.object,
  batchData: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

DataImportDetailPage.defaultProps = {
  isLoading: false,
  isImportTaskLoading: false,
  currentPage: DEFAULT_PAGE_INFO.start,
  pageSize: DEFAULT_PAGE_SIZE,
  nextPageToken: EMPTY_STRING,
  uploadedFileData: EMPTY_OBJECT,
  importedFileCardViewData: EMPTY_OBJECT,
  batchData: EMPTY_ARRAY,
};

export default compose(WithSize({ hasPageFooter: 0, hasPageHeader: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(DataImportDetailPage);
