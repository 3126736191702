import compose from 'recompose/compose';
import { defaultMemoize } from 'reselect';
import _property from 'lodash/property';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _nthArg from 'lodash/nthArg';

import { tget } from '@tekion/tekion-base/utils/general';
import { NO_DATA } from '@tekion/tekion-base/app.constants';
import ColumnConfig from '@tekion/tekion-components/organisms/inputTable/builders/ColumnConfig';
import CellConfig from '@tekion/tekion-components/organisms/inputTable/builders/CellConfig';
import SelectInputCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/SelectInputCellRenderer';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';

import { CONTROLLING_OPTION_TABLE_COLUMN_IDS } from '../constants/controllingOptionConfigTable.general';

import styles from '../controllingOption.module.scss';

const getFieldOptions = (options, selectedOptions) =>
  _map(options, (field) => ({
    label: tget(field, 'label', NO_DATA),
    value: _get(field, 'value', ''),
    isDisabled: _includes(selectedOptions, field.value),
  }));

const mapOptionProps = defaultMemoize((props, options, selectedOptions, isOptionDisabled) => {
  const { error } = props;

  return {
    options: getFieldOptions(options, selectedOptions),
    isDisabled: isOptionDisabled,
    className: styles.customSelect,
    error,
  };
});

const mapValueProps = defaultMemoize((props, valuesOptions, isValueDisabled) => {
  const { error } = props;

  return {
    options: valuesOptions,
    mode: 'multiple',
    selectContainerClassName: styles.multiSelect,
    disabled: isValueDisabled,
    className: styles.customSelect,
    error,
  };
});

const getOptionCell = (options, selectedOptions, isOptionDisabled) =>
  new CellConfig()
    .setComponent(SelectInputCellRenderer)
    .setMapCellPropsToComponentProps((props) => mapOptionProps(props, options, selectedOptions, isOptionDisabled));

const getValueCell = (valuesOptions, isValueDisabled) =>
  new CellConfig().setComponent(SelectInput).setMapCellPropsToComponentProps((props) => mapValueProps(props, valuesOptions, isValueDisabled));

const COLUMN_WIDTHS = {
  [CONTROLLING_OPTION_TABLE_COLUMN_IDS.OPTION]: '30rem',
  [CONTROLLING_OPTION_TABLE_COLUMN_IDS.VALUES]: '30rem',
};

const valueAccessor = _property(CONTROLLING_OPTION_TABLE_COLUMN_IDS.VALUES);
const optionAccessor = _property(CONTROLLING_OPTION_TABLE_COLUMN_IDS.OPTION);

const onOptionChange = compose(_property('value'), _nthArg(1));
const onValueChange = (data, value) => [...value];

const getOptionColumnConfig = (options, selectedOptions, isOptionDisabled) =>
  new ColumnConfig(CONTROLLING_OPTION_TABLE_COLUMN_IDS.OPTION)
    .setHeader(__('Option'))
    .setGetValueFromOnChange(onOptionChange)
    .setAccessor(optionAccessor)
    .addCellConfig(getOptionCell(options, selectedOptions, isOptionDisabled))
    .setWidth(COLUMN_WIDTHS[CONTROLLING_OPTION_TABLE_COLUMN_IDS.OPTION]);

const getValueColumnConfig = (valuesOptions, isValueDisabled) =>
  new ColumnConfig(CONTROLLING_OPTION_TABLE_COLUMN_IDS.VALUES)
    .setHeader(__('Controlling Field Values'))
    .setGetValueFromOnChange(onValueChange)
    .setAccessor(valueAccessor)
    .addCellConfig(getValueCell(valuesOptions, isValueDisabled))
    .setWidth(COLUMN_WIDTHS[CONTROLLING_OPTION_TABLE_COLUMN_IDS.VALUES]);

const getColumns = (options, selectedOptions, valuesOptions, isOptionDisabled, isValueDisabled) => {
  const optionColumnConfig = getOptionColumnConfig(options, selectedOptions, isOptionDisabled);
  const valueColumnConfig = getValueColumnConfig(valuesOptions, isValueDisabled);

  return [optionColumnConfig, valueColumnConfig];
};

export { getColumns };
