import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { WORKFLOW_BUILDER_MODE } from '../../../../../../../constants/general.constants';
import ROUTES from '../../../../../constants/routes';

const handleFieldChange = ({ setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  setState((state) => {
    const { values } = state;
    return {
      ...state,
      values: {
        ...values,
        [id]: value,
      },
    };
  });
};

const handleValidationSuccess = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;
  setState({ errors });
};

const handleSaveSubmittedData = ({ getState }) => {
  const { history, values, workflowMode } = getState();

  let pathname = '';
  if (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS) {
    pathname = ROUTES.PROCESS_CREATE_ROUTE;
  } else {
    pathname = ROUTES.RULE_CREATE_ROUTE;
  }

  history.push({
    pathname,
    state: { initialWorkflowDetails: values },
  });
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidationSuccess,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSaveSubmittedData,
};

export default ACTION_HANDLERS;
