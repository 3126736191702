import React from 'react';
import PropTypes from 'prop-types';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';

import { getFields, getSections } from '../helpers/delayTaskModal.formHelpers';
import DELAY_TASK_MODAL_FIELD_IDS from '../constants/delayTaskModal.fieldIds';

const TabContent = ({
  isConditionFetching,
  tab,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  tabError,
  eventTypes,
  workflowOptions,
  onAction,
}) => {
  const id = tget(tab, DELAY_TASK_MODAL_FIELD_IDS.ID);
  const values = tget(tab, 'values');

  const fields = getFields(eventTypes, conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, workflowOptions);
  const sections = getSections(tab, isConditionFetching);

  return <FormWithSubmission contextId={id} fields={fields} sections={sections} values={values} errors={tabError} onAction={onAction} />;
};

TabContent.propTypes = {
  isConditionFetching: PropTypes.bool,
  tab: PropTypes.object.isRequired,
  conditionBuilderFieldDefinitionObject: PropTypes.object.isRequired,
  mapOfVariableToEntityName: PropTypes.object.isRequired,
  tabError: PropTypes.object,
  eventTypes: PropTypes.array.isRequired,
  workflowOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

TabContent.defaultProps = {
  isConditionFetching: false,
  tabError: EMPTY_OBJECT,
  workflowOptions: EMPTY_ARRAY,
};

export default TabContent;
