import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { fetchLoginData } from '../../actions/loginData.actions';
import { isAuthenticated, redirectToLogin } from './WithAuth.helpers';
import { updateUserDataFromLoginData } from '../../utils/user.utils';

const LoggedInWrapper = (props) => {
  const { location, component: Component } = props;
  const [isAuthenticatedState, setAuthenticatedState] = useState(false);
  const [isLoginDataFetched, setIsLogInDataFetched] = useState(false);

  const fetchAndStoreLoginData = useCallback(async () => {
    const loginData = await fetchLoginData();
    updateUserDataFromLoginData(loginData);
    setIsLogInDataFetched(true);
  }, []);

  useEffect(() => {
    if (!isAuthenticated()) {
      const [pathname, search] = [_get(location, 'pathname', ''), _get(location, 'search', '')];

      redirectToLogin(false, `?redirectTo=${pathname}${search}`, true);
    } else {
      setAuthenticatedState(true);
    }
  }, [location]);

  useEffect(() => {
    if (isAuthenticated()) {
      fetchAndStoreLoginData();
    }
  }, [fetchAndStoreLoginData]);

  return isAuthenticatedState && isLoginDataFetched ? <Component {...props} /> : null;
};

LoggedInWrapper.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
};

LoggedInWrapper.defaultProps = {};

export default LoggedInWrapper;
