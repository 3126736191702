import React, { useCallback, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

// Tekion-base
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Tekion-components
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import Button from '@tekion/tekion-components/atoms/Button';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

// Tekion-connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Connectors
import WithSize from '../../../../../connectors/withSize';

// Components
import SubscriptionFormModal from './components/subscriptionFormModal/SubscriptionFormModal';

// Helpers
import { getSections, getFields } from './helpers/subscriptionConfigForm.formConfig';
import ACTION_HANDLERS from './helpers/subscriptionConfigForm.actionHandlers';

// Constants
import { FORM_MODES } from '../../../../../constants/general.constants';
import { SUBSCRIPTION_CONFIG_FORM_CONTEXT_ID } from './constants/subscriptionConfigForm.general';
import ACTION_TYPES from './constants/subscriptionConfigForm.actionTypes';

import styles from './subscriptionConfigForm.module.scss';

const SubscriptionConfigForm = ({
  isSubmitting,
  isFetchingSubscriptionConfig,
  isSubscriptionConfigVerified,
  isSubscriptionModalVisible,
  isSubscriptionConfigInActive,
  isEntityFieldsLoading,
  contentHeight,
  selectedSubscriptionIndex,
  formMode,
  formValues,
  errors,
  selectedSubscriptionValues,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  onAction,
}) => {
  const headerTitle = formMode === FORM_MODES.CREATE ? __('Create Subscription Configuration') : __('Update Subscription Configuration');

  const primaryButtonLabel = useMemo(() => {
    if (isSubscriptionConfigVerified) {
      return formMode === FORM_MODES.CREATE ? __('Create') : __('Update');
    } else {
      return __('Verify');
    }
  }, [formMode, isSubscriptionConfigVerified]);

  const fields = useMemo(() => getFields(formMode), [formMode]);

  const sections = useMemo(() => getSections(formMode), [formMode]);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(SUBSCRIPTION_CONFIG_FORM_CONTEXT_ID);
  }, []);

  const handleRedirectToLogs = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_REDIRECT_TO_LOGS });
  }, [onAction]);

  return (
    <Page>
      <Page.Header hasBack>
        <div className={styles.configHeaderContainer}>
          <Heading>{headerTitle}</Heading>
          <PropertyControlledComponent controllerProperty={isSubscriptionConfigVerified}>
            <div className={styles.configVerifiedInfo}>
              <FontIcon>icon-checked-outlined</FontIcon>
              <span>{__('Verified')}</span>
            </div>
          </PropertyControlledComponent>
        </div>
        <PropertyControlledComponent controllerProperty={!isSubscriptionConfigInActive && formMode === FORM_MODES.EDIT}>
          <Button view={Button.VIEW.PRIMARY} onClick={handleRedirectToLogs}>
            {__('Logs')}
          </Button>
        </PropertyControlledComponent>
      </Page.Header>
      <Page.Body style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          className="p-t-12"
          isFetching={isFetchingSubscriptionConfig}
          contextId={SUBSCRIPTION_CONFIG_FORM_CONTEXT_ID}
          sections={sections}
          fields={fields}
          values={formValues}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id={SUBSCRIPTION_CONFIG_FORM_CONTEXT_ID}
          primaryActionLoading={isSubmitting}
          primaryButtonLabel={primaryButtonLabel}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
      <SubscriptionFormModal
        isSubscriptionModalVisible={isSubscriptionModalVisible}
        isEntityFieldsLoading={isEntityFieldsLoading}
        selectedSubscriptionIndex={selectedSubscriptionIndex}
        conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
        mapOfVariableToEntityName={mapOfVariableToEntityName}
        selectedSubscriptionValues={selectedSubscriptionValues}
        onAction={onAction}
      />
    </Page>
  );
};

SubscriptionConfigForm.propTypes = {
  isSubmitting: PropTypes.bool,
  isSubscriptionConfigVerified: PropTypes.bool,
  isFetchingSubscriptionConfig: PropTypes.bool,
  isSubscriptionModalVisible: PropTypes.bool,
  isEntityFieldsLoading: PropTypes.bool,
  isSubscriptionConfigInActive: PropTypes.bool,
  selectedSubscriptionIndex: PropTypes.number,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  selectedSubscriptionValues: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

SubscriptionConfigForm.defaultProps = {
  isSubmitting: false,
  isSubscriptionConfigVerified: false,
  isFetchingSubscriptionConfig: false,
  isSubscriptionModalVisible: false,
  isEntityFieldsLoading: false,
  isSubscriptionConfigInActive: false,
  selectedSubscriptionIndex: -1,
  formMode: FORM_MODES.CREATE,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  selectedSubscriptionValues: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
};

export default compose(WithSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions({}, ACTION_HANDLERS))(SubscriptionConfigForm);
