import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { CONDITION_TYPES } from '../constants/workflow.constants';
import { SWITCH_DEFAULT_CASE } from '../constants/workflowBuilder.constants';

const generatePathOptions = (paths = EMPTY_ARRAY, valueLabelMap = EMPTY_OBJECT, targetType = EMPTY_STRING) => {
  const mutablePaths = [...paths];
  const mutableValueLabelMap = { ...valueLabelMap };

  if (targetType === CONDITION_TYPES.SWITCH) {
    mutablePaths.push(SWITCH_DEFAULT_CASE.id);
  }

  mutableValueLabelMap.default = SWITCH_DEFAULT_CASE.displayName;

  return _map(mutablePaths, (value) => {
    const label = tget(mutableValueLabelMap, value, value);
    return { label, value };
  });
};

export default generatePathOptions;
