import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _valuesIn from 'lodash/valuesIn';
import _get from 'lodash/get';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import withDateAsFilter from '@tekion/tekion-components/organisms/filterSection/containers/withDateAsFilter';
import DateTimeRangeWithoutPreset from '@tekion/tekion-components/organisms/filterSection/molecules/DateTimeRange/DateTimeRange';
import Label from '@tekion/tekion-components/atoms/Label/Label';

import WidgetContainer from '../../../reporting/molecules/widgetContainer/WidgetContainer';
import ACTION_HANDLERS from './helpers/reportingWidgetRenderer.actionHandlers';
import { ACTION_TYPES, TIME_RANGE } from './reportingWidgetRenderer.constants';

import styles from './reportingWidgetRenderer.module.scss';

const DateTimeFilter = withDateAsFilter(DateTimeRangeWithoutPreset);

const ReportingWidgetRenderer = ({ widgetName, timeRange, componentConfig, onAction }) => {
  const rangeValues = useMemo(() => _valuesIn(timeRange), [timeRange]);
  const timeTriggerPresent = _get(componentConfig, 'properties.timeTriggerPresent', true);

  const handleDateTimeFilterChange = useCallback(
    (value) => {
      onAction({ type: ACTION_TYPES.ON_FIELD_CHANGE, payload: { id: TIME_RANGE, value } });
    },
    [onAction],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT });
  }, [onAction]);

  return (
    <div className={styles.reportingContainer}>
      {timeTriggerPresent && (
        <div className={styles.customFilterContainer}>
          <Label className="m-t-4">{__('Time Trigger')}</Label>
          <DateTimeFilter
            id={TIME_RANGE}
            showTime
            required={false}
            onChange={handleDateTimeFilterChange}
            values={rangeValues}
            className={styles.dateFilter}
          />
        </div>
      )}
      <div className={styles.widgetContainer}>
        <WidgetContainer isEditable={false} widgetName={widgetName} timeRange={timeRange} triggerRangeChange={handleDateTimeFilterChange} />
      </div>
    </div>
  );
};

ReportingWidgetRenderer.propTypes = {
  widgetName: PropTypes.string,
  timeRange: PropTypes.object,
  componentConfig: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ReportingWidgetRenderer.defaultProps = {
  widgetName: EMPTY_STRING,
  timeRange: EMPTY_OBJECT,
  componentConfig: EMPTY_OBJECT,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(ReportingWidgetRenderer);
