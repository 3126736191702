import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Button from '@tekion/tekion-components/atoms/Button';
import Content from '@tekion/tekion-components/atoms/Content';
import withActions from '@tekion/tekion-components/connectors/withActions';
import FilterSection from '@tekion/tekion-components/organisms/filterSection';
import Loader from '@tekion/tekion-components/molecules/loader';

import withSize from '../../../../../connectors/withSize';

import SubscriptionConfigLogDetails from './components/subscriptionConfigLogDetails/SubscriptionConfigLogDetails';

import { createFilterProps } from './helpers/subscriptionConfigLogs.helpers';
import ACTION_HANDLERS from './helpers/subscriptionConfigLogs.actionHandlers';

import { ACTION_TYPES } from './constants/subscriptionConfigLogs.general';

import styles from './subscriptionConfigLogs.module.scss';

const SubscriptionConfigLogs = ({
  isLoading,
  isLogStatusChanging,
  isLogEnabled,
  isInitialFetchLoading,
  contentHeight,
  subscriptionConfigDisplayName,
  subscriptionConfigLogs,
  selectedFilters,
  onAction,
}) => {
  const filterProps = useMemo(() => createFilterProps(onAction), [onAction]);

  const handleChangeLogStatus = useCallback(() => onAction({ type: ACTION_TYPES.ON_CHANGE_CONFIG_LOG_STATUS }), [onAction]);

  const handleFilterApply = useCallback(
    (filterSelected) => {
      onAction({
        type: ACTION_TYPES.ON_SET_FILTERS,
        payload: { value: filterSelected },
      });
    },
    [onAction],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_FETCH_LOGS, payload: { isInitialFetchLoading: true } });
  }, [onAction]);

  if (isInitialFetchLoading) {
    return <Loader />;
  }

  return (
    <Page style={{ height: contentHeight }}>
      <Page.Header hasBack>
        <Heading className={styles.logsHeader}>{__(`${subscriptionConfigDisplayName} Logs`)}</Heading>
        {isLogEnabled && (
          <Button loading={isLogStatusChanging} view={Button.VIEW.PRIMARY} onClick={handleChangeLogStatus}>
            {__('Disable')}
          </Button>
        )}
      </Page.Header>
      <PropertyControlledComponent
        controllerProperty={isLogEnabled}
        fallback={
          !isLoading && (
            <div className={styles.logDisabledContainer}>
              <Content colorVariant={Content.COLOR_VARIANTS.GREY} className={styles.contentText}>
                {__('Logs Disabled')}
              </Content>
              <Button loading={isLogStatusChanging} className={styles.enable} view={Button.VIEW.PRIMARY} onClick={handleChangeLogStatus}>
                {__('Enable')}
              </Button>
            </div>
          )
        }
      >
        <div className={styles.logTimeFilterSection}>
          <FilterSection {...filterProps} selectedFilters={selectedFilters} onFilterApply={handleFilterApply} />
        </div>
        <PropertyControlledComponent controllerProperty={!isLoading} fallback={<Loader />}>
          {!_isEmpty(subscriptionConfigLogs) ? (
            _map(subscriptionConfigLogs, (logDetails, index) => <SubscriptionConfigLogDetails key={index} logDetails={logDetails} />)
          ) : (
            <Content colorVariant={Content.COLOR_VARIANTS.GREY} className={styles.noLogContentText}>
              {__('No Logs Available')}
            </Content>
          )}
        </PropertyControlledComponent>
      </PropertyControlledComponent>
    </Page>
  );
};

SubscriptionConfigLogs.propTypes = {
  isLoading: PropTypes.bool,
  isLogEnabled: PropTypes.bool,
  isLogStatusChanging: PropTypes.bool,
  isInitialFetchLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  subscriptionConfigDisplayName: PropTypes.string,
  subscriptionConfigLogs: PropTypes.array,
  selectedFilters: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

SubscriptionConfigLogs.defaultProps = {
  isLoading: false,
  isLogEnabled: false,
  isLogStatusChanging: false,
  isInitialFetchLoading: false,
  subscriptionConfigDisplayName: EMPTY_STRING,
  subscriptionConfigLogs: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: true }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(SubscriptionConfigLogs);
