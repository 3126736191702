const EVENT_ACTIONS = {
  NAVIGATE: 'NAVIGATE_TO_PAGE',
  TRIGGER_ACTION_BUILDER_ACTION: 'EXECUTE_AN_ACTION',
  TOGGLE_COMMENT_SECTION: 'TOGGLE_COMMENT_SECTION',
  COPY_PAGE_LINK_TO_CLIPBOARD: 'COPY_PAGE_LINK_TO_CLIPBOARD',
  SHOW_AUDIT_LOG_DRAWER: 'SHOW_AUDIT_LOG_DRAWER',
};

const EVENT_NAMES = {
  CLICK: 'CLICK',
  OPTIONS_LOAD: 'OPTION_LOAD',
  LOAD: 'LOAD',
  UNLOAD: 'UNLOAD',
  CHANGE: 'CHANGE',
  BEFORE_SUBMIT: 'BEFORE_SUBMIT',
  ON_ROW_CLICK: 'ON_ROW_CLICK',
  AFTER_SUBMIT: 'AFTER_SUBMIT',
  CELL_CLICK: 'CELL_CLICK',
  EXPANDABLE_CLICK: 'EXPANDABLE_CLICK',
  HOVER: 'HOVER',
  FOCUS: 'FOCUS',
  BLUR: 'BLUR',
  DBLCLICK: 'DBLCLICK',
  INPUT: 'INPUT',
  KEYPRESS: 'KEYPRESS',
  BEFORE_DATA_LOAD: 'BEFORE_DATA_LOAD',
  AFTER_DATA_LOAD: 'AFTER_DATA_LOAD',
  RECORD_CREATED: 'RECORD_CREATED',
  RECORD_UPDATED: 'RECORD_UPDATED',
  // TODO Add below ENUM to Backend
  RECORD_DELETED: 'RECORD_DELETED',
};

const EVENT_NAME_TO_LABEL_MAP = {
  [EVENT_NAMES.CLICK]: __('On Click'),
  [EVENT_NAMES.OPTIONS_LOAD]: __('On Options load'),
  [EVENT_NAMES.LOAD]: __('On Load'),
  [EVENT_NAMES.UNLOAD]: __('On Unload'),
  [EVENT_NAMES.CHANGE]: __('On Change'),
  [EVENT_NAMES.BEFORE_SUBMIT]: __('Before Submit'),
  [EVENT_NAMES.ON_ROW_CLICK]: __('On Row Click'),
  [EVENT_NAMES.AFTER_SUBMIT]: __('After Submit'),
  [EVENT_NAMES.CELL_CLICK]: __('On Cell Click'),
  [EVENT_NAMES.EXPANDABLE_CLICK]: __('On Expandable Click'),
  [EVENT_NAMES.HOVER]: __('On Hover'),
  [EVENT_NAMES.FOCUS]: __('On Focus'),
  [EVENT_NAMES.BLUR]: __('On Blur'),
  [EVENT_NAMES.DBLCLICK]: __('On Double Click'),
  [EVENT_NAMES.INPUT]: __('On Input'),
  [EVENT_NAMES.KEYPRESS]: __('On KeyPress'),
  [EVENT_NAMES.BEFORE_DATA_LOAD]: __('Before Data Load'),
  [EVENT_NAMES.AFTER_DATA_LOAD]: __('After Data Load'),
  [EVENT_NAMES.RECORD_CREATED]: __('Record Created'),
};

const EVENT_ACTION_NAME_TO_LABEL = {
  [EVENT_ACTIONS.NAVIGATE]: __('Navigate To Page'),
  [EVENT_ACTIONS.TRIGGER_ACTION_BUILDER_ACTION]: __('Execute An Action'),
  [EVENT_ACTIONS.TOGGLE_COMMENT_SECTION]: __('Toggle Comment Section'),
  [EVENT_ACTIONS.COPY_PAGE_LINK_TO_CLIPBOARD]: __('Copy Page Link to Clipboard'),
  [EVENT_ACTIONS.SHOW_AUDIT_LOG_DRAWER]: __('Show Audit'),
};

const EVENT_ACTIONS_TYPES = { SCRIPT: 'SCRIPT', PRE_DEFINED: 'PRE_DEFINED' };

export { EVENT_ACTIONS, EVENT_NAMES, EVENT_ACTIONS_TYPES, EVENT_NAME_TO_LABEL_MAP, EVENT_ACTION_NAME_TO_LABEL };
