import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _map from 'lodash/map';
import _castArray from 'lodash/castArray';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';
import FILTER_FIELD_KEYS, { INVALID_FILTER_VALUES } from '../../constants/filter.constants';

const addFilterObjectToFilterPayload = (filterPayload, filterObject) => {
  if (!_isEmpty(filterObject)) {
    filterPayload.push(filterObject);
  }
};

const getFilterObject = (id, filterType, values, allowEmptyValuesInFilter) => {
  const validFilterValues = _filter(_castArray(values), (value) => !_includes(INVALID_FILTER_VALUES, value));

  if (!allowEmptyValuesInFilter && _isEmpty(validFilterValues)) {
    return EMPTY_OBJECT;
  }
  return {
    [FILTER_FIELD_KEYS.FIELD]: id,
    [FILTER_FIELD_KEYS.FILTER_TYPE]: filterType,
    [FILTER_FIELD_KEYS.VALUES]: validFilterValues,
  };
};

const getOrTypeFilterObject = (filters) => ({
  [FILTER_FIELD_KEYS.FILTER_TYPE]: OPERATORS.BOOL,
  [FILTER_FIELD_KEYS.SHOULD_FILTERS]: filters,
});

const convertToFilterPayload = (filters) =>
  _map(filters, (filter) => ({
    [FILTER_FIELD_KEYS.FIELD]: tget(filter, 'type'),
    [FILTER_FIELD_KEYS.FILTER_TYPE]: tget(filter, 'operator'),
    [FILTER_FIELD_KEYS.VALUES]: tget(filter, 'values', EMPTY_ARRAY),
  }));

export { getFilterObject, getOrTypeFilterObject, convertToFilterPayload, addFilterObjectToFilterPayload };
