exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir=ltr] .notificationGroup_groupContainer__NdeNQM9w7e{padding-right:2.4rem}[dir=rtl] .notificationGroup_groupContainer__NdeNQM9w7e{padding-left:2.4rem}.notificationGroup_groupContainer__NdeNQM9w7e:hover .notificationGroup_clearIcon__nW1kSpcMBm{visibility:visible}[dir] .notificationGroup_groupContainer__NdeNQM9w7e:hover .notificationGroup_cardContainer__jvwjM7Tcsb{background-color:#ebf4ff}.notificationGroup_groupHeader__9M64HGHdGv{font-family:\"Proxima-Nova-Semibold\"}[dir] .notificationGroup_groupHeader__9M64HGHdGv{padding-top:1.2rem}[dir=ltr] .notificationGroup_groupHeader__9M64HGHdGv{margin-left:2.4rem}[dir=rtl] .notificationGroup_groupHeader__9M64HGHdGv{margin-right:2.4rem}.notificationGroup_unreadCountToolTip__47EsPrhhg5{font-size:1.2rem}[dir] .notificationGroup_unreadCountToolTip__47EsPrhhg5{padding:0 1rem}.notificationGroup_clearIcon__nW1kSpcMBm{visibility:hidden}[dir] .notificationGroup_clearIcon__nW1kSpcMBm{padding-top:.4rem}[dir=ltr] .notificationGroup_clearIcon__nW1kSpcMBm{padding-right:.8rem}[dir=rtl] .notificationGroup_clearIcon__nW1kSpcMBm{padding-left:.8rem}", ""]);

// Exports
exports.locals = {
	"groupContainer": "notificationGroup_groupContainer__NdeNQM9w7e",
	"clearIcon": "notificationGroup_clearIcon__nW1kSpcMBm",
	"cardContainer": "notificationGroup_cardContainer__jvwjM7Tcsb",
	"groupHeader": "notificationGroup_groupHeader__9M64HGHdGv",
	"unreadCountToolTip": "notificationGroup_unreadCountToolTip__47EsPrhhg5"
};