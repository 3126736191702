import _property from 'lodash/property';
import _trim from 'lodash/trim';
import _isEmpty from 'lodash/isEmpty';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

export const USER_ID_FIELD_NAME = 'userId';
export const CURRENTY_USER_ROLE_ID = 'roleId';
export const USER_EMAIL_FIELD_NAME = 'email';
export const USER_JOB_TITLE_FIELD_NAME = 'jobTitle';
export const DISPLAY_NAME_FIELD_NAME = 'displayName';
export const USER_FIRST_NAME_FIELD_NAME = 'firstName';
export const USER_LAST_NAME_FIELD_NAME = 'lastName';
export const USER_TENANT_ID = 'tenantId';
export const USER_WORKSPACE_ID = 'workspaceId';
export const USER_HOMEWORKSPACE_ID = 'homeWorkspaceId';
export const FIRST_TIME_PWD_RESET = 'firstTimePWDReset';
export const USER_WORKSPACE_DETAILS = 'userWorkspaceDetails';
export const WORKSPACE_PROFILE_NAME = 'profileName';

const id = _property(USER_ID_FIELD_NAME);
const userName = _property(DISPLAY_NAME_FIELD_NAME);
const tenantId = _property(USER_TENANT_ID);
const firstName = _property(USER_FIRST_NAME_FIELD_NAME);
const lastName = _property(USER_LAST_NAME_FIELD_NAME);
const userID = _property(USER_ID_FIELD_NAME);
const email = _property(USER_EMAIL_FIELD_NAME);
const displayName = _property(DISPLAY_NAME_FIELD_NAME);
const firstTimePWDReset = _property(FIRST_TIME_PWD_RESET);
const roleId = _property(CURRENTY_USER_ROLE_ID);
const profileName = _property(WORKSPACE_PROFILE_NAME);
const workspaceId = _property(USER_WORKSPACE_ID);
const homeWorkspaceId = _property(USER_HOMEWORKSPACE_ID);
const userWorkspaceDetails = _property(USER_WORKSPACE_DETAILS);

const name = (user = EMPTY_OBJECT) => {
  if (user.name) return user.name;
  if (user.displayName) return user.displayName;
  if (user.displayValue) return user.displayValue;
  const userFirstName = user.firstName || user.fname || '';
  const userLastName = user.lastName || user.lname || '';
  const userEmail = user.email || '';
  if (_isEmpty(userFirstName) && _isEmpty(userLastName)) {
    return userEmail;
  }
  return _trim(`${userFirstName} ${userLastName}`);
};

const READER = {
  id,
  email,
  name,
  displayName,
  tenantId,
  roleId,
  firstTimePWDReset,
  userName,
  firstName,
  lastName,
  userID,
  workspaceId,
  homeWorkspaceId,
  userWorkspaceDetails,
  profileName,
};

export default READER;
