import _map from 'lodash/map';
import _find from 'lodash/find';
import _isArray from 'lodash/isArray';
import _get from 'lodash/get';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { getFormattedDateTime } from '../../../../../../utils';
import CONDITION_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import DATA_TYPES from '../../../../../../constants/fieldDefinition.dataTypes';

const getCriteriaList = (criteriaList, entity) => {
  const newCriteriaList = _map(criteriaList, (item) => {
    const fieldDefinitions = _get(entity, 'fieldDefinitions', EMPTY_ARRAY);
    const fieldDef = _find(fieldDefinitions, (field) => _get(field, 'name') === _get(item, CONDITION_FIELD_IDS.FIELD));
    let value = _get(item, CONDITION_FIELD_IDS.VALUES);
    if (_get(fieldDef, 'dataType') === DATA_TYPES.DATE_TIME) {
      value = getFormattedDateTime(DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_US_WITH_TIME)(getArraySafeValue(value));
    }
    if (_isArray(value)) {
      value = value.toString();
    }
    return {
      ...item,
      [CONDITION_FIELD_IDS.VALUES]: value,
    };
  });
  return newCriteriaList;
};

export { getCriteriaList };
