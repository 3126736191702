import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import _size from 'lodash/size';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import withActions from '@tekion/tekion-components/connectors/withActions';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Button from '@tekion/tekion-components/atoms/Button';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import Checkbox from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import CustomActionsConfiguratorModal from './components/CustomActionsVisualConfiguratorModal';

import ACTION_HANDLERS from './helpers/customActionsVisualConfigurator.actionHandlers';

import { COMPONENT_CONFIG_KEYS } from '../../../../constants/visualBuilder.general.constants';
import ACTION_TYPES from './constants/customActionsVisualConfigurator.actionTypes';
import { ACTIONS_CONFIGURATOR_FORM_CONTEXT_ID, FIELD_IDS } from './constants/customActionsVisualConfigurator.constants';
import { CUSTOM_ACTIONS_PROPERTIES } from '../../../../constants/visualBuilder.properties.constants';

import styles from './customActionsVisualConfigurator.module.scss';

const CustomActionsVisualConfigurator = ({ isModalVisible, selectedWidgetConfig, entityName, formValues, errors, onAction }) => {
  const handleSubmit = useCallback(() => {
    triggerSubmit(ACTIONS_CONFIGURATOR_FORM_CONTEXT_ID);
  }, []);

  const handleCancelModal = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CANCEL_MODAL,
    });
  }, [onAction]);

  const handleConfigureClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CLICK_CONFIGURE_ACTIONS,
    });
  }, [onAction]);

  const countOfSelectedCustomActions = useMemo(() => _size(tget(formValues, [FIELD_IDS.ACTIONS_TO_SHOW], EMPTY_ARRAY)), [formValues]);

  const selectedWidgetConfigProperties = useMemo(() => tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, {}), [selectedWidgetConfig]);

  const isActionsShown = useMemo(
    () => tget(selectedWidgetConfigProperties, CUSTOM_ACTIONS_PROPERTIES.SHOW_ACTIONS, false),
    [selectedWidgetConfigProperties],
  );

  const renderActionConfiguratorModal = useCallback(
    () => (
      <Modal
        visible={isModalVisible}
        width={SIZES.MD}
        bodyStyle={{ padding: 0 }}
        title={__('Configure Actions')}
        submitBtnText={__('Submit')}
        onSubmit={handleSubmit}
        onCancel={handleCancelModal}
      >
        <CustomActionsConfiguratorModal entityName={entityName} formValues={formValues} errors={errors} onAction={onAction} />
      </Modal>
    ),
    [entityName, errors, formValues, handleCancelModal, handleSubmit, isModalVisible, onAction],
  );

  const renderConfigureEntityName = useCallback(() => {
    if (entityName) {
      return (
        <Button view={Button.VIEW.TERTIARY} className={styles.configureButton} onClick={handleConfigureClick}>
          {__('Configure')}
        </Button>
      );
    } else {
      return <div>{__('Please configure entity name')}</div>;
    }
  }, [entityName, handleConfigureClick]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.ON_SELECTED_WIDGET_PROPERTIES_CHANGE,
      payload: { selectedWidgetConfigProperties },
    });
  }, [onAction, selectedWidgetConfigProperties]);

  return (
    <div className={styles.customActionsComponent}>
      <Checkbox
        id={CUSTOM_ACTIONS_PROPERTIES.SHOW_ACTIONS}
        label={__('Show Actions')}
        containerClassName="d-flex"
        className={styles.showActionsCheckboxLabel}
        value={isActionsShown}
        onAction={onAction}
      />
      {isActionsShown && (
        <>
          <div className={styles.actionsComponent}>
            <div className={styles.addedCustomActionsContainer}>
              <div className={styles.addedCustomActionSection}>
                {__('Added Actions ')}({countOfSelectedCustomActions})
              </div>
            </div>
            {entityName && renderConfigureEntityName()}
          </div>
          {!entityName && renderConfigureEntityName()}
          {renderActionConfiguratorModal()}
        </>
      )}
    </div>
  );
};

CustomActionsVisualConfigurator.propTypes = {
  isModalVisible: PropTypes.bool,
  selectedWidgetConfig: PropTypes.object,
  entityName: PropTypes.object,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

CustomActionsVisualConfigurator.defaultProps = {
  isModalVisible: false,
  selectedWidgetConfig: EMPTY_OBJECT,
  entityName: undefined,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(CustomActionsVisualConfigurator);
