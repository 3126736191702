const WORKFLOW_FIELD_IDS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  ENTITY: 'entityName', // This to be confirmed with BE once API contracts are ready.
  EVENT_TYPE: 'eventType',
  DESCRIPTION: 'description',
};

const PROCESS_FIELD_IDS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  ENTITY: 'entityName', // This to be confirmed with BE once API contracts are ready.
  EVENT_TYPE: 'eventType',
  DESCRIPTION: 'description',
  ASSIGNED_VARIABLES_INFO: 'assignedVariablesInfo',
};

const RULE_FIELD_IDS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  ENTITY: 'entityName', // This to be confirmed with BE once API contracts are ready.
  EVENT_TYPE: 'eventType',
  DESCRIPTION: 'description',
  PRIORITY: 'priority',
};

export { RULE_FIELD_IDS, PROCESS_FIELD_IDS };

export default WORKFLOW_FIELD_IDS;
