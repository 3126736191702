import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

// Components
import TableManager from '@tekion/tekion-components/organisms/TableManager';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';
import withSize from '../../../../connectors/withSize';

// Table Helpers
import TABLE_MANAGER_PROPS from './helpers/projectList.tableManagerProps';
import SUB_HEADER_PROPS from './helpers/projectList.subHeaderProps';
import PROJECT_LIST_COLUMNS from './helpers/projectList.columns';
import { getTableProps } from './helpers/projectList.helpers';
import HEADER_PROPS from './helpers/projectList.headerProps';
import ACTION_HANDLERS from './helpers/projectList.actionHandlers';

// Constants
import ACTION_TYPES from './constants/projectList.actionTypes';
import { TABLE_CONSTANTS, INITIAL_STATE_PROJECT_LIST_PROPS } from './constants/projectList.general.constants';

const ProjectList = ({
  isLoaded,
  loading,
  contentHeight,
  pageSize,
  currentPage,
  searchText,
  nextPageToken,
  totalNumberOfEntries,
  projects,
  onAction,
}) => {
  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({
        type: actionType,
        payload,
      });
    },
    [onAction],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_FETCH_PROJECT_DATA });
  }, [onAction]);

  return (
    <div style={{ height: contentHeight }}>
      <TableManager
        isLoaded={isLoaded}
        tokenPagination
        columns={PROJECT_LIST_COLUMNS}
        tableManagerProps={TABLE_MANAGER_PROPS}
        subHeaderProps={SUB_HEADER_PROPS}
        headerProps={HEADER_PROPS}
        pageSize={pageSize}
        searchText={searchText}
        nextPageToken={nextPageToken}
        data={projects}
        tableProps={getTableProps(handleRowAction, currentPage, loading, pageSize, totalNumberOfEntries)}
        onAction={onAction}
      />
    </div>
  );
};

ProjectList.propTypes = {
  loading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  searchText: PropTypes.string,
  nextPageToken: PropTypes.string,
  totalNumberOfEntries: PropTypes.number,
  projects: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ProjectList.defaultProps = {
  loading: true,
  isLoaded: true,
  pageSize: DEFAULT_PAGE_SIZE,
  currentPage: DEFAULT_PAGE_INFO.start,
  searchText: EMPTY_STRING,
  nextPageToken: undefined,
  totalNumberOfEntries: TABLE_CONSTANTS.TOTAL_NUMBER_OF_ENTRIES,
  projects: EMPTY_ARRAY,
};

export default compose(
  withSize({ hasPageFooter: 0, hasPageHeader: 0 }),
  withActions(INITIAL_STATE_PROJECT_LIST_PROPS, ACTION_HANDLERS),
  React.memo,
)(ProjectList);
