import { CHART_TYPES } from '@tekion/tekion-components/molecules/tekionCharts/tekionCharts.constants';
import s3Assets from '../../../assests/s3Assests';

const WIDGET_TYPES = {
  DATA_TABLE: 'DATA_TABLE',
  CHART: 'CHART',
};

const CHART_WIDGET_TYPES = {
  LINE_CHART: 'LINE_CHART',
  BAR_CHART: 'BAR_CHART',
  MULTI_BAR_CHART: 'MULTI_BAR_CHART',
};

const CHART_TYPE_MAPPER = {
  [CHART_WIDGET_TYPES.LINE_CHART]: 'spline',
  [CHART_WIDGET_TYPES.BAR_CHART]: CHART_TYPES.COLUMN,
  [CHART_TYPES.BAR]: CHART_TYPES.BAR,
  [CHART_TYPES.PIE]: CHART_TYPES.PIE,
  areaspline: 'areaspline',
  area: 'area',
};

const CHART_OPTIONS = [
  {
    label: __('line'),
    value: 'spline',
    src: s3Assets.MULTI_LINE,
  },
  {
    label: __('column'),
    value: CHART_TYPES.COLUMN,
    src: s3Assets.COLUMN,
  },
  {
    label: __('pie'),
    value: CHART_TYPES.PIE,
    src: s3Assets.DONUT,
  },
  {
    label: __('bar'),
    value: CHART_TYPES.BAR,
    src: s3Assets.BAR,
  },
  {
    label: __('area'),
    value: 'areaspline',
    src: s3Assets.AREA,
  },
];

export { WIDGET_TYPES, CHART_TYPE_MAPPER, CHART_OPTIONS, CHART_WIDGET_TYPES };
