import { defaultMemoize } from 'reselect';
import _isEmpty from 'lodash/isEmpty';

import _get from 'lodash/get';
import _map from 'lodash/map';

import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import Button from '@tekion/tekion-components/atoms/Button';
import Heading from '@tekion/tekion-components/atoms/Heading';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { getFormattedDateTime, DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';

import { TEXT_STARTS_WITH } from '../../../../../constants/filter.constants';
import { ROW_ACTIONS } from '../constants/projectApplicationList.columns';
import ACTION_TYPES from '../constants/projectApplicationList.actionTypes';
import { ROWS } from '../constants/projectApplicationList.constants';

const getTableProps = defaultMemoize((currentPage, totalCount, loading, rows, handleRowAction) => ({
  currentPage: currentPage + 1,
  loading,
  resultsPerPage: rows,
  rowHeight: 40,
  totalNumberOfEntries: totalCount,
  minRows: 0,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  selection: EMPTY_ARRAY,
  pageSize: rows,
  getRowActions: () => ROW_ACTIONS,
  onRowActionClick: handleRowAction,
}));

const getSubHeaderProps = defaultMemoize((countOfApplications) => ({
  subHeaderLeftActions: [
    {
      renderer: Heading,
      renderOptions: {
        children: __(`Applications (${countOfApplications})`),
        size: 2,
      },
    },
  ],
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        view: Button.VIEW.PRIMARY,
        label: __('Add Applications'),
      },
      action: ACTION_TYPES.ADD_APPLICATION,
    },
  ],
}));

const getApplications = defaultMemoize((data) => {
  const applicationList = _map(data, (application) => {
    const key = _get(application, 'name', EMPTY_STRING);
    const title = _get(application, 'displayName', EMPTY_STRING);
    const description = _get(application, 'description', EMPTY_STRING);
    const name = _get(application, 'displayName', EMPTY_STRING);
    return { key, title, description, name };
  });
  return applicationList;
});

const getProjectCreatedTime = defaultMemoize((epochTime) => getFormattedDateTime(epochTime, DATE_TIME_FORMATS.NATIVE_DATE_FORMAT));

const getPayload = (currentSearchText, pageToken) => {
  let payload = {
    rows: ROWS,
    searchText: currentSearchText,
    nextPageToken: pageToken,
  };

  if (!_isEmpty(currentSearchText)) {
    payload = {
      ...payload,
      filters: [
        {
          field: 'displayName',
          values: [currentSearchText],
          filterType: TEXT_STARTS_WITH,
        },
      ],
    };
  }
  return payload;
};

const isScrollAtBottom = (event) => {
  if (event) {
    const element = event.target;
    return element && element.scrollHeight - element.scrollTop === element.clientHeight;
  }
  return false;
};

export { getTableProps, getSubHeaderProps, getApplications, getProjectCreatedTime, getPayload, isScrollAtBottom };
