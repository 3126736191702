import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// constants
import MEDIA from '@tekion/tekion-components/molecules/Gallery/Media.reader';
import { BROWSER_ASSET_TYPE } from '@tekion/tekion-base/helpers/media.helper';

// components
import MediaPreviewGallery from '../../../../../mediaPreviewGallery';

function ImagePreview(props) {
  const { imageSrc, onClose } = props;
  const mediaList = useMemo(() => [{ url: imageSrc, [MEDIA.MEDIA_KEY_TYPE]: BROWSER_ASSET_TYPE.image }], [imageSrc]);

  return (
    <MediaPreviewGallery
      showCarouselArrows={false}
      showCountDisplay={false}
      isSecureMedia={false}
      mediaList={mediaList}
      closeMediaPreview={onClose}
    />
  );
}

ImagePreview.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImagePreview;
