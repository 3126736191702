import produce from 'immer';

import _set from 'lodash/set';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _head from 'lodash/head';
import _filter from 'lodash/filter';
import _remove from 'lodash/remove';
import _castArray from 'lodash/castArray';
import _map from 'lodash/map';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { createRecordTypeOverride, fetchRecordTypeOverride, updateRecordTypeOverride } from '../../../../../actions/recordType.actions';
import { fetchEntityDefByName } from '../../../../../actions/entityManagement.actions';
import { getDisplayName, getPayload, getValuesFromPayload } from './recordTypeOverride.helpers';
import { ACTION_TYPES } from '../constants/recordTypeOverride.constants';
import { STUDIO_ROUTE } from '../../../../../constants/routes';
import PAGE_IDS from '../../../constants/PageIds.constants';
import { FIELD_IDS } from '../components/addOverrideFieldModal/constants/addOverrideFieldModal.constants';
import FIELD_TYPES from '../../../../../constants/fieldDefinition.fieldTypes';

import entityReader from '../../../../../readers/entity.reader';
import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';

const handleOnMount = async ({ setState, getState }) => {
  const { history, match } = getState();
  const { entityName, recordTypeName } = _get(match, 'params', {});

  setState({ isLoading: true });

  const recordTypeOverride = await fetchRecordTypeOverride(entityName, recordTypeName);
  const recordTypeOverrideName = _get(recordTypeOverride, 'name', '');
  let entity = _get(history, 'location.state.entity', {});
  let recordTypeDetails = EMPTY_OBJECT;

  if (_isEmpty(entity)) {
    entity = await fetchEntityDefByName(entityName);
  }

  const { overrideFieldModalData, overrideFieldTableData, selectedFields } = getValuesFromPayload(recordTypeOverride, entity);
  const derivationFields = _get(entity, 'recordTypeDefinition.recordTypeDerivationConfig.derivationFields', EMPTY_ARRAY);
  entity = { ...entity, derivationFields };

  if (!_isEmpty(recordTypeName)) {
    recordTypeDetails = _get(history, 'location.state.recordTypeDetails', {});
    if (_isEmpty(recordTypeDetails)) {
      recordTypeDetails = _find(
        _get(entity, 'recordTypeDefinition.recordTypes', EMPTY_ARRAY),
        (recordType) => _get(recordType, 'name') === recordTypeName,
      );
    }
  }

  setState({ entity, recordTypeDetails, recordTypeOverrideName, overrideFieldModalData, overrideFieldTableData, selectedFields, derivationFields });

  const fieldName = _get(history, 'location.state.fieldName', '');
  if (!_isEmpty(fieldName)) {
    setState({ isModalVisible: true, isLoading: false, fieldOverrideFormValues: { [FIELD_IDS.FIELD_NAME]: _castArray(fieldName) } });
  } else {
    setState({ isLoading: false });
  }
};

const handleFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { entity } = getState();
  const fieldDefinitions = entityReader.fieldDefinitions(entity);

  setState(
    produce((draft) => {
      if (id === FIELD_IDS.FIELD_NAME) {
        const fieldDef = _find(fieldDefinitions, (item) => _get(item, 'name') === getArraySafeValue(value));
        if (fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.SELECT) {
          const options = _map(_get(fieldDef, 'optionConfig.options', EMPTY_ARRAY), (option) => ({
            label: _get(option, 'displayName', EMPTY_STRING),
            value: _get(option, 'value', EMPTY_STRING),
          }));
          _set(draft, `fieldOverrideFormValues.${FIELD_IDS.OPTIONS}`, options);
        }

        _set(draft, `fieldOverrideFormValues.${FIELD_IDS.DISPLAY_NAME}`, _get(fieldDef, 'displayName', EMPTY_STRING));
      }
      _set(draft, `fieldOverrideFormValues.${id}`, value);
    }),
  );
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;

  setState({ errors });
};

const handleSave = async ({ setState, getState }) => {
  const { history, match, recordTypeOverrideName, overrideFieldModalData, recordTypeDetails } = getState();
  const { entityName } = _get(match, 'params', {});
  let response = EMPTY_OBJECT;
  const payload = getPayload(overrideFieldModalData, recordTypeDetails);

  setState({ isSavingDetails: true });

  if (_isEmpty(recordTypeOverrideName)) {
    response = await createRecordTypeOverride(entityName, payload);
  } else {
    response = await updateRecordTypeOverride(entityName, recordTypeOverrideName, payload);
  }

  if (!_isEmpty(response)) {
    setTimeout(() => {
      history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.RECORD_TYPE}`);
    }, ES_REFETCH_DELAY);
  } else {
    setState({ isSavingDetails: false });
  }
};

const handleModalOpen = ({ setState }) => {
  setState({ isModalVisible: true });
};

const handleModalClose = ({ setState }) => {
  setState({ isModalVisible: false, fieldOverrideFormValues: EMPTY_OBJECT });
};

const handleRemoveRow = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { overrideFieldModalData = EMPTY_ARRAY, overrideFieldTableData = EMPTY_ARRAY, selectedFields = EMPTY_ARRAY } = getState();
  const { value } = params;
  _remove(overrideFieldTableData, (item) => _get(item, 'name') === value.name);
  _remove(overrideFieldModalData, (item) => _head(_get(item, FIELD_IDS.FIELD_NAME)) === value.id);
  _remove(selectedFields, (item) => item === value.id);

  setState({
    overrideFieldTableData: [...overrideFieldTableData],
    overrideFieldModalData: [...overrideFieldModalData],
    selectedFields: [...selectedFields],
  });
};

const handleEditClick = ({ getState }) => {
  const { history = EMPTY_OBJECT, match = EMPTY_OBJECT, entity, recordTypeDetails } = getState();
  const { entityName, recordTypeName } = _get(match, 'params', {});

  if (!_isEmpty(entityName) && !_isEmpty(recordTypeName))
    history.push({
      pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.RECORD_TYPE_EDIT}/${recordTypeName}`,
      state: { entity, recordTypeValues: recordTypeDetails },
    });
};

const handleSubmit = ({ getState, setState }) => {
  const {
    fieldOverrideFormValues,
    overrideFieldModalData = EMPTY_ARRAY,
    overrideFieldTableData = EMPTY_ARRAY,
    entity,
    selectedFields = EMPTY_ARRAY,
  } = getState();
  const fieldName = _head(_get(fieldOverrideFormValues, FIELD_IDS.FIELD_NAME));
  const fieldDisplayName = getDisplayName(entity, fieldName);

  let newOverrideFieldTableData = _filter(overrideFieldTableData, (item) => !(_get(item, 'id') === fieldName));
  let newOverrideFieldModalData = _filter(overrideFieldModalData, (item) => !(_head(_get(item, FIELD_IDS.FIELD_NAME)) === fieldName));
  let newSelectedFields = _filter(selectedFields, (item) => !(item === fieldName));

  newOverrideFieldModalData = [...newOverrideFieldModalData, fieldOverrideFormValues];
  newOverrideFieldTableData = [...newOverrideFieldTableData, { name: fieldDisplayName, id: fieldName }];
  newSelectedFields = [...newSelectedFields, fieldName];

  setState({
    isModalVisible: false,
    overrideFieldModalData: newOverrideFieldModalData,
    overrideFieldTableData: newOverrideFieldTableData,
    fieldOverrideFormValues: EMPTY_OBJECT,
    selectedFields: newSelectedFields,
  });
};

const handleEdit = ({ setState, params = EMPTY_OBJECT, getState }) => {
  const { overrideFieldModalData } = getState();
  const { value } = params;

  const newFormValues = _find(overrideFieldModalData, (item) => _head(_get(item, FIELD_IDS.FIELD_NAME)) === value.id) || {};

  setState({ isModalVisible: true, fieldOverrideFormValues: newFormValues });
};

const handleOptionTableOnChange = ({ setState, params, getState }) => {
  const { id, value } = params;
  const { fieldOverrideFormValues } = getState();

  const row = _get(value, 'row');
  const fieldValue = _get(value, 'value');
  const tableData = _get(fieldOverrideFormValues, FIELD_IDS.OPTIONS, EMPTY_ARRAY);
  tableData[row] = { ...tableData[row], [id]: fieldValue };

  setState({ fieldOverrideFormValues: { ...fieldOverrideFormValues, [FIELD_IDS.OPTIONS]: [...tableData] } });
};

const handleOptionTableRemoveRow = ({ getState, setState, params }) => {
  const { fieldOverrideFormValues } = getState();
  const { row } = params;
  const tableData = _get(fieldOverrideFormValues, FIELD_IDS.OPTIONS, EMPTY_ARRAY);
  _remove(tableData, (val, index) => index === row);
  setState({
    fieldOverrideFormValues: { ...fieldOverrideFormValues, [FIELD_IDS.OPTIONS]: [...tableData] },
  });
};

const handleOptionTableAddRow = ({ setState, getState }) => {
  const { fieldOverrideFormValues } = getState();
  const tableData = _get(fieldOverrideFormValues, FIELD_IDS.OPTIONS, EMPTY_ARRAY);

  setState({ fieldOverrideFormValues: { ...fieldOverrideFormValues, [FIELD_IDS.OPTIONS]: [...tableData, {}] } });
};

const handleCancel = ({ getState }) => {
  const { match, history } = getState();
  const entityName = _get(match, 'params.entityName');
  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.RECORD_TYPE}`);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_MOUNT]: handleOnMount,
  [ACTION_TYPES.ON_CHANGE]: handleFieldChange,
  [ACTION_TYPES.EDIT]: handleEdit,
  [ACTION_TYPES.ON_CANCEL]: handleCancel,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_EDIT_CLICK]: handleEditClick,
  [ACTION_TYPES.ON_SAVE]: handleSave,
  [ACTION_TYPES.MODAL_OPEN]: handleModalOpen,
  [ACTION_TYPES.MODAL_CLOSE]: handleModalClose,
  [ACTION_TYPES.DELETE]: handleRemoveRow,
  [ACTION_TYPES.OPTION_TABLE_ON_CHANGE]: handleOptionTableOnChange,
  [ACTION_TYPES.OPTION_TABLE_REMOVE_ROW]: handleOptionTableRemoveRow,
  [ACTION_TYPES.OPTION_TABLE_ADD_ROW]: handleOptionTableAddRow,
};

export default ACTION_HANDLERS;
