import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _valuesIn from 'lodash/valuesIn';
import { defaultMemoize } from 'reselect';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { tget } from '@tekion/tekion-base/utils/general';
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox/Checkbox';
import { EMPTY_ARRAY, EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';

import { mergeRenderOptions } from '../../../helpers/fieldsForm.general.helpers';

import VIEW_DETAILS_FORM_FIELD_IDS from '../constants/viewDetailsForm.fieldIds';
import VIEW_DETAILS_FORM_LABELS from '../constants/viewDetailsForm.labels';

const ADD_TO_LIST_VIEW_FIELD = {
  id: VIEW_DETAILS_FORM_FIELD_IDS.LIST_VIEW_ENABLED,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: __('Add to standard list view'),
  },
};

const ADD_TO_DETAIL_VIEW_FIELD = {
  id: VIEW_DETAILS_FORM_FIELD_IDS.DETAILED_VIEW_ENABLED,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: __('Add to standard detail view'),
  },
};

const VIEW_DETAILS_FORM_FIELDS = {
  [VIEW_DETAILS_FORM_FIELD_IDS.LIST_VIEW_ENABLED]: ADD_TO_LIST_VIEW_FIELD,
  [VIEW_DETAILS_FORM_FIELD_IDS.DETAILED_VIEW_ENABLED]: ADD_TO_DETAIL_VIEW_FIELD,
};

const getFieldLabel = defaultMemoize((fieldId) => tget(VIEW_DETAILS_FORM_LABELS, fieldId, NO_DATA));

const getFields = (renderOptions) => {
  const fieldsRenderOptions = {};
  const mergedRenderOptions = mergeRenderOptions(fieldsRenderOptions, _valuesIn(VIEW_DETAILS_FORM_FIELD_IDS), renderOptions, getFieldLabel);

  return _reduce(
    _valuesIn(VIEW_DETAILS_FORM_FIELD_IDS),
    (fields, fieldId) => ({
      ...fields,
      [fieldId]: addToRenderOptions(_get(VIEW_DETAILS_FORM_FIELDS, fieldId), _get(mergedRenderOptions, fieldId, EMPTY_ARRAY)),
    }),
    EMPTY_OBJECT,
  );
};

export default getFields;
