import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import entityViewDefinitionServices from '../services/entityViewDefinition.services';

const fetchEntityViewConfigurationByName = async (entityName, viewName) => {
  try {
    const responseData = await entityViewDefinitionServices.fetchEntityViewConfigurationByName(entityName, viewName);
    const viewConfiguration = getDataFromResponse(responseData);

    return viewConfiguration;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch view, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const searchEntityViewConfigurations = async (payload = EMPTY_OBJECT) => {
  try {
    const receivedData = await entityViewDefinitionServices.searchEntityViewConfigurations(payload);

    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch entity views, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const saveEntityViewConfiguration = async (entityName, entityViewConfiguration) => {
  try {
    const responseData = await entityViewDefinitionServices.saveEntityViewConfiguration(entityName, entityViewConfiguration);
    const savedViewConfiguration = getDataFromResponse(responseData);

    return savedViewConfiguration;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to save entityView, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateEntityViewConfiguration = async (entityName, entityViewName, entityViewConfiguration) => {
  try {
    const responseData = await entityViewDefinitionServices.updateEntityViewConfiguration(entityName, entityViewName, entityViewConfiguration);
    const savedEntityViewConfiguration = getDataFromResponse(responseData);

    return savedEntityViewConfiguration;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update entity view, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const deleteEntityViewConfiguration = async (entityName, viewName) => {
  try {
    const responseData = await entityViewDefinitionServices.deleteEntityViewConfiguration(entityName, viewName);
    const status = tget(responseData, 'data.status');

    return status;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Delete view, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export {
  fetchEntityViewConfigurationByName,
  saveEntityViewConfiguration,
  searchEntityViewConfigurations,
  updateEntityViewConfiguration,
  deleteEntityViewConfiguration,
};
