const ACTION_TYPES = {
  ON_EDIT_CLICK: 'ON_EDIT_CLICK',
  ON_CANCEL_CLICK: 'ON_CANCEL_CLICK',
  ON_CANCEL_CANCEL_APPROVAL_REQUEST_MODAL_CLICK: 'ON_CANCEL_CANCEL_APPROVAL_REQUEST_MODAL_CLICK',
  ON_CANCEL_CONFIRM_APPROVAL_REQUEST_MODAL_CLICK: 'ON_CANCEL_CONFIRM_APPROVAL_REQUEST_MODAL_CLICK',
};

const TABLE_MANAGER_PROPS = {
  showFilter: true,
  showHeader: false,
  showSearch: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const EDIT_ROW_ACTION = {
  id: ACTION_TYPES.ON_EDIT_CLICK,
  name: __('Edit Request'),
};

const CANCEL_ROW_ACTION = {
  id: ACTION_TYPES.ON_CANCEL_CLICK,
  name: __('Cancel Request'),
};

export { ACTION_TYPES, TABLE_MANAGER_PROPS, EDIT_ROW_ACTION, CANCEL_ROW_ACTION };
