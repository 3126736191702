import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { PAGE_TYPES, PAGE_CONFIG_IDS } from '../../../../../../../../../constants/visualBuilder';

const getValueOptionForPage = (pageConfig) => ({
  label: __(`${tget(pageConfig, 'displayName', EMPTY_STRING)}`) || __(`${tget(pageConfig, 'name', EMPTY_STRING)}`),
  value: tget(pageConfig, 'name', EMPTY_STRING),
});

const getOptionsForPageField = (data) => _map(data, getValueOptionForPage);

const getPayloadForPageField = ({ nextPageToken, searchText }) => ({
  rows: 20,
  filters: _compact([
    {
      field: PAGE_CONFIG_IDS.PAGE_TYPE,
      values: [PAGE_TYPES.HOME_PAGE],
      filterType: OPERATORS.IN,
    },
    !_isEmpty(searchText)
      ? {
          field: PAGE_CONFIG_IDS.TITLE,
          values: [searchText],
          filterType: OPERATORS.TEXT_STARTS_WITH,
        }
      : null,
  ]),
  nextPageToken,
});

export { getOptionsForPageField, getPayloadForPageField };
