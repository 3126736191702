import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { RECORD_TYPES } from '../../../../../constants/general.constants';

const INITIAL_STATES_PROJECT_FORM_PROPS = {
  values: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

const CONTEXT_ID = 'RECORD_TYPE_DETAIL_FORM';

const RECORD_TYPES_OPTIONS = [
  {
    label: __('Standard'),
    value: RECORD_TYPES.STANDARD,
  },
  {
    label: __('Derived'),
    value: RECORD_TYPES.DERIVED,
  },
];

export { CONTEXT_ID, INITIAL_STATES_PROJECT_FORM_PROPS, RECORD_TYPES_OPTIONS };
