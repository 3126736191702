import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LoginPage from './Login/LoginPage';
import { isAuthenticated, getRedirectUrl, redirectToLastRoute } from './WithAuth.helpers';

const LogInWrapper = (props) => {
  const { history } = props;
  const redirectUrl = getRedirectUrl(history);
  const [isAuthenticatedState, setAuthenticatedState] = useState(false);

  useEffect(() => {
    if (isAuthenticated()) {
      const _redirectUrl = getRedirectUrl(history);

      setAuthenticatedState(true);
      redirectToLastRoute(_redirectUrl);
    } else {
      setAuthenticatedState(false);
    }
  }, [history]);

  return isAuthenticatedState ? null : <LoginPage {...props} reDirectUrl={redirectUrl} />;
};

LogInWrapper.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

LogInWrapper.defaultProps = {};

export default LogInWrapper;
