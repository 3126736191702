import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

import { NO_DATA, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { ROWS } from '../constants/customActionsConfigurator.constants';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../constants/actionBuilder.constants';
import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../constants/viewBuilder.constants';

const getPayload =
  (entityName) =>
  ({ nextPageToken = EMPTY_STRING, searchText = EMPTY_STRING }) => {
    const filters = [
      {
        field: ACTION_DEFINITION_FIELD_IDS.ACTION_LEVEL,
        values: ['ENTITY'],
        filterType: OPERATORS.IN,
      },
      {
        field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
        values: _castArray(entityName),
        filterType: OPERATORS.IN,
      },
      {
        field: ACTION_DEFINITION_FIELD_IDS.STATUS,
        values: ['ACTIVE'],
        filterType: OPERATORS.IN,
      },
    ];

    if (!_isEmpty(searchText)) {
      filters.push({
        field: ACTION_DEFINITION_FIELD_IDS.ACTION_NAME,
        values: [searchText],
        filterType: OPERATORS.TEXT_STARTS_WITH,
      });
    }

    return {
      rows: ROWS,
      nextPageToken,
      filters,
    };
  };

const getValueOptionForAction = (actionData) => ({
  label: tget(actionData, ACTION_DEFINITION_FIELD_IDS.ACTION_DISPLAY_NAME, NO_DATA),
  value: tget(actionData, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME),
});

const getActionNameOptions = (data) => _map(data, getValueOptionForAction);

const getCustomActionsInfoMessage = (showActions, actionsToShow) => {
  if (!showActions) {
    return __('Enable Show-Actions to get Row Actions in List View.');
  } else if (showActions && _isEmpty(actionsToShow)) {
    return __(
      // eslint-disable-next-line max-len
      'Since Show-Actions is true but no Actions are selected here, so all the Actions defined for this Entity will be populated as Row Actions for this view.',
    );
  } else {
    return __('The Actions selected here will be available as row actions for this view.');
  }
};

export { getPayload, getActionNameOptions, getCustomActionsInfoMessage };
