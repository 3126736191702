import ApiService from './apiService/apiService';

const LOGIN_CONFIGURATIONS_BASE_URL = 'core/u/login/configurations';

const fetchLoginConfigurations = () => ApiService.get(`${LOGIN_CONFIGURATIONS_BASE_URL}`);
const addLoginConfigurations = (payload) => ApiService.post(`${LOGIN_CONFIGURATIONS_BASE_URL}/add`, payload);
const updateLoginConfigurations = (payload) => ApiService.put(`${LOGIN_CONFIGURATIONS_BASE_URL}`, payload);
const removeLoginConfigurations = (providerType) => ApiService.post(`${LOGIN_CONFIGURATIONS_BASE_URL}/remove/${providerType}`);

export default {
  fetchLoginConfigurations,
  addLoginConfigurations,
  updateLoginConfigurations,
  removeLoginConfigurations,
};
