import { withProps } from 'recompose';

import _map from 'lodash/map';
import _castArray from 'lodash/castArray';

import { hasNumberRule, isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';
import Checkbox from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';

import EventHandlerConfigurator from '../eventHandlerConfigurator';
import CustomStylesConfigurator from '../customStylesConfigurator';

import { FIELD_IDS } from './gridViewAttributePropertiesForm.constants';
import { VIEW_BUILDER_DATE_TIME_FORMATS } from '../../../../constants/viewBuilder.constants';

const mapPropsForCheckbox = ({ value, defaultValue, ...restProps }) => ({
  ...restProps,
  value: value === null || value === undefined ? defaultValue : value,
});

const mapPropsForDateTimeFormat = ({ value, ...restProps }) => ({ ...restProps, value: _castArray(value) });

const KEY_SPAN_FIELD = {
  id: FIELD_IDS.KEY_SPAN,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Key Column Span'),
    defaultValue: 8,
    min: 0,
    max: 24,
    validators: [isRequiredRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const VALUE_SPAN_FIELD = {
  id: FIELD_IDS.VALUE_SPAN,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Value Column Span'),
    defaultValue: 15,
    min: 0,
    max: 24,
    validators: [isRequiredRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const IS_KEY_VISIBLE_FIELD = {
  id: FIELD_IDS.IS_KEY_VISIBLE,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    defaultChecked: true,
    checkboxLabel: __('Show key'),
  },
};

const IS_AVATAR_VIEW_FIELD = {
  id: FIELD_IDS.IS_AVATAR_VIEW,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: false,
    checkboxLabel: __('Avatar View Enabled'),
  },
};

const IS_RTE_TRUNCATED_TYPE_FIELD = {
  id: FIELD_IDS.IS_RTE_TRUNCATED_TYPE,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: false,
    checkboxLabel: __('RTE Truncated'),
  },
};

const DATE_TIME_FORMAT_FIELD = {
  id: FIELD_IDS.DATE_TIME_FORMAT,
  renderer: withProps(mapPropsForDateTimeFormat)(Select),
  renderOptions: {
    label: __('Date Time Format'),
    isMulti: false,
    closeMenuOnSelect: true,
    defaultValue: [],
    options: _map(VIEW_BUILDER_DATE_TIME_FORMATS, (option) => ({ label: option, value: option })),
  },
};

const EVENT_HANDLER_FIELD = {
  id: FIELD_IDS.EVENT_HANDLERS,
  renderer: EventHandlerConfigurator,
};

const CUSTOM_STYLE_FIELD = {
  id: FIELD_IDS.CUSTOM_STYLES,
  renderer: CustomStylesConfigurator,
};

const FORM_FIELDS = {
  [FIELD_IDS.KEY_SPAN]: KEY_SPAN_FIELD,
  [FIELD_IDS.VALUE_SPAN]: VALUE_SPAN_FIELD,
  [FIELD_IDS.IS_KEY_VISIBLE]: IS_KEY_VISIBLE_FIELD,
  [FIELD_IDS.IS_AVATAR_VIEW]: IS_AVATAR_VIEW_FIELD,
  [FIELD_IDS.IS_RTE_TRUNCATED_TYPE]: IS_RTE_TRUNCATED_TYPE_FIELD,
  [FIELD_IDS.DATE_TIME_FORMAT]: DATE_TIME_FORMAT_FIELD,
  [FIELD_IDS.EVENT_HANDLERS]: EVENT_HANDLER_FIELD,
  [FIELD_IDS.CUSTOM_STYLES]: CUSTOM_STYLE_FIELD,
};

const getFields = (componentType, properties, entity) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.EVENT_HANDLERS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.EVENT_HANDLERS], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
  [FIELD_IDS.CUSTOM_STYLES]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CUSTOM_STYLES], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
});

export { getFields };
