import { compose, withProps } from 'recompose';

import withSize from '../../../../connectors/withSize';

import ApprovalManagement from './approvalManagement';
import ApprovalRequestForm from './approvalRequestForm';
import ApprovalRequestDetailsPage from './approvalRequestDetailsPage';

import PAGE_IDS from '../../constants/pageIds.constants';

const ApprovalManagementWithWrappedWithSize = compose(
  withProps({ mountedPrefixPath: `/${PAGE_IDS.APPLICATIONS}` }),
  withSize({ hasPageHeader: false, hasPageFooter: false }),
)(ApprovalManagement);

const ApprovalRequestFormWithWrappedWithSize = compose(
  withProps({ mountedPrefixPath: `/${PAGE_IDS.APPLICATIONS}` }),
  withSize({ hasPageHeader: true, hasPageFooter: true }),
)(ApprovalRequestForm);

const ApprovalRequestDetailsPageWithWrappedWithSize = compose(
  withProps({ mountedPrefixPath: `/${PAGE_IDS.APPLICATIONS}` }),
  withSize({ hasPageHeader: false, hasPageFooter: false }),
)(ApprovalRequestDetailsPage);

export {
  ApprovalManagement,
  ApprovalManagementWithWrappedWithSize,
  ApprovalRequestForm,
  ApprovalRequestFormWithWrappedWithSize,
  ApprovalRequestDetailsPage,
  ApprovalRequestDetailsPageWithWrappedWithSize,
};
