import React from 'react';
import _noop from 'lodash/noop';

const VisualBuilderContext = React.createContext({
  selectedWidgetComponentId: undefined,
  onSelect: _noop,
  onDelete: _noop,
  onDrop: _noop,
});

export default VisualBuilderContext;
