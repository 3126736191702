import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _nth from 'lodash/nth';
import _has from 'lodash/has';
import _omit from 'lodash/omit';
import _keyBy from 'lodash/keyBy';
import _castArray from 'lodash/castArray';
import _includes from 'lodash/includes';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchEntityRecordById } from '../../../../../actions/recordManagement.actions';
import { getApplication } from '../../../../../actions/applicationManagement.actions';
import { fetchPageConfigurationById, searchPageConfigurations } from '../../../../../actions/visualBuilder.actions';
import localStorageReader from '../../../../../readers/localStorage.reader';
import { getSearchParamsFromUrl } from '../../../../../utils/visualBuilder.utils';

import {
  getAppConfigWithFlattenProperties,
  getApplicationData,
  getPayloadForPageConfig,
  getUrl,
  getPageNamesFromApplicationConfig,
  getPayloadForPageConfigFetch,
} from './applicationRenderer.helpers';

import { ACTION_TYPES, APPROVAL_TAB_ID, PROPERTY_IDS } from '../constants/applicationRenderer.constants';
import { STANDARD_ENTITY_NAME } from '../../../../../constants/general.constants';
import { EVENT_META_DATA_FIELD_IDS } from '../../../../../constants/event.fieldIds';
import { APPROVAL_METADATA_PROPERTY_IDS } from '../../../../../constants/approvalCentre.constants';
import { APPLICATION_CONTEXT_KEYS, APPLICATION_SEARCH_PARAM_IDS } from '../../../../../constants/applicationRenderer.constants';
import PAGE_IDS from '../../../constants/pageIds.constants';

const getDataFromUrl = async ({ setState, getState }) => {
  const { history, pageConfigByName = {} } = getState();

  const searchParams = getSearchParamsFromUrl(history);
  const pageName = _get(searchParams, APPLICATION_SEARCH_PARAM_IDS.PAGE_NAME);
  let newPageConfigByName;

  // let newPageConfigs = [];
  if (!_has(pageConfigByName, pageName)) {
    const pageConfigPayload = getPayloadForPageConfig(_castArray(pageName));
    const { hits: pageConfigs } = await searchPageConfigurations(pageConfigPayload, true);
    newPageConfigByName = _keyBy(pageConfigs, 'name');
    setState({
      pageConfigByName: { ...pageConfigByName, [pageName]: tget(newPageConfigByName, pageName, {}) },
    });
  }
};

const handleTabClick = async ({ setState, getState, params = {} }) => {
  const { tabsData, history, tabIdToOrderMapper, match } = getState();
  const { nextTabId } = params;

  if (nextTabId === APPROVAL_TAB_ID) {
    setState({ selectedTabId: APPROVAL_TAB_ID });
    history.push(`${match.url}/${PAGE_IDS.APPROVAL_CENTRE}/${PAGE_IDS.MANAGEMENT}/${PAGE_IDS.APPROVALS}`);
  } else {
    // const currentUrl = history.location.search;

    // const updatedTabsData = getUpdatedTabsData(tabsData, tabIdToOrderMapper, currentTabId, { lastUrl: currentUrl });
    // disabling tab persistance
    const nextTabData = _nth(tabsData, tabIdToOrderMapper[nextTabId]);
    const newUrl = _get(nextTabData, 'lastUrl');

    history.push(`${match.url}${newUrl}`);

    setState({ tabsData, selectedTabId: nextTabId });

    await getDataFromUrl({ setState, getState });
  }
};

const handleInit = async ({ setState, getState }) => {
  setState({ loading: true });
  const { history, match, currentLoggedInUserId = '', location } = getState();
  const applicationName = _get(match, 'params.appName', EMPTY_OBJECT);
  const devPlatformUser = localStorageReader.userInfo();

  const existingSearchParamsValue = getSearchParamsFromUrl(history);
  const tabIdAlreadyPresentInSearchParams = _get(existingSearchParamsValue, APPLICATION_SEARCH_PARAM_IDS.TAB_ID);
  const pageNameAlreadyPresentInSearchParams = _get(existingSearchParamsValue, APPLICATION_SEARCH_PARAM_IDS.PAGE_NAME);
  const pageRecordIdAlreadyPresentInSearchParams = _get(existingSearchParamsValue, 'pageRecordId');

  const userIdToFetch = !_isEmpty(currentLoggedInUserId) ? currentLoggedInUserId : tget(devPlatformUser, 'userId');

  const promises = [fetchEntityRecordById(STANDARD_ENTITY_NAME.USER, userIdToFetch), getApplication(applicationName)];

  const [userResponse, applicationResponse] = await Promise.all(promises);
  const applicationData = getAppConfigWithFlattenProperties(applicationResponse);

  const pageNamesInApplicationResponse = [];

  getPageNamesFromApplicationConfig(_get(applicationData, 'components', EMPTY_ARRAY), pageNamesInApplicationResponse);
  const payload = getPayloadForPageConfigFetch(pageNamesInApplicationResponse);

  const { hits: pageConfigFromApplicationData } = await searchPageConfigurations(payload, true);

  const pageConfigByName = _keyBy(pageConfigFromApplicationData, 'name');

  const {
    tabsData = EMPTY_ARRAY,
    isNavigationRequired = true,
    noNavigationData = EMPTY_OBJECT,
    tabIdToOrderMapper,
    navigationType,
  } = getApplicationData(applicationData);

  const isOnApprovalRoute = _includes(location?.pathname, PAGE_IDS.APPROVAL_CENTRE);

  let selectedTabId;
  let url;
  let selectPageName;

  const approvalProperties = tget(applicationData, ['properties', PROPERTY_IDS.APPROVAL_METADATA], EMPTY_OBJECT);
  const isApprovalTabEnabled = tget(approvalProperties, APPROVAL_METADATA_PROPERTY_IDS.IS_ENABLED, false);

  if (isNavigationRequired) {
    if (isOnApprovalRoute && isApprovalTabEnabled) {
      selectedTabId = APPROVAL_TAB_ID;
      selectPageName = EMPTY_STRING;
    } else {
      selectedTabId = !_isEmpty(tabIdAlreadyPresentInSearchParams)
        ? tabIdAlreadyPresentInSearchParams
        : _get(getArraySafeValue(tabsData), 'id', EMPTY_STRING);

      selectPageName = !_isEmpty(pageNameAlreadyPresentInSearchParams)
        ? pageNameAlreadyPresentInSearchParams
        : _get(getArraySafeValue(tabsData), 'pageName', EMPTY_STRING);
      url = getUrl({ tabId: selectedTabId, pageName: selectPageName, recordId: pageRecordIdAlreadyPresentInSearchParams });
    }
  } else {
    selectPageName = !_isEmpty(pageNameAlreadyPresentInSearchParams) ? pageNameAlreadyPresentInSearchParams : _get(noNavigationData, 'pageName');
    url = getUrl({ pageName: selectPageName, recordId: pageRecordIdAlreadyPresentInSearchParams });
  }

  if (!isOnApprovalRoute || !isApprovalTabEnabled) {
    history.push(`${match.url}${url}`);
  }

  const applicationRendererContextValue = {
    [APPLICATION_CONTEXT_KEYS.CURRENT_USER]: {
      ..._omit(userResponse, ['entity']),
      ...tget(userResponse, 'entity'),
    },
  };

  setState(
    {
      isNavigationRequired,
      tabIdToOrderMapper,
      navigationType,
      tabsData,
      applicationData,
      pageConfigByName,
      selectedTabId,
      applicationRendererContextValue,
    },
    async () => {
      await getDataFromUrl({
        getState,
        setState,
      });
    },
  );
};

const handleNavigateToPage = async ({ setState, getState, params = {} }) => {
  const { history } = getState();

  const entityRecord = tget(params, 'entityRecord', EMPTY_OBJECT);
  const navigateToPrevPage = tget(params, EVENT_META_DATA_FIELD_IDS.NAVIGATE_TO_PREV_PAGE, false);
  const recordId = _get(entityRecord, 'id');
  const currentSearchParams = _get(history, 'location.search');

  let pageName = tget(params, EVENT_META_DATA_FIELD_IDS.PAGE_NAME_TO_NAVIGATE);

  if (_isEmpty(pageName)) {
    const pageId = tget(params, EVENT_META_DATA_FIELD_IDS.PAGE_ID_TO_NAVIGATE);
    const pageConfig = await fetchPageConfigurationById(pageId);
    pageName = tget(pageConfig, 'name');
  }

  if (navigateToPrevPage) {
    history.goBack();
  } else {
    const newUrl = getUrl({ recordId, pageName, url: currentSearchParams });

    history.push(newUrl);
    await getDataFromUrl({ setState, getState });
  }
};

const handleSetApplicationVariables = ({ getState, setState, params }) => {
  const { variableName, value } = params;
  const { applicationRendererContextValue = {} } = getState();

  const previousApplicationVariables = tget(applicationRendererContextValue, APPLICATION_CONTEXT_KEYS.APPLICATION_VARIABLES, {});

  setState({
    applicationRendererContextValue: {
      ...applicationRendererContextValue,
      [APPLICATION_CONTEXT_KEYS.APPLICATION_VARIABLES]: { ...previousApplicationVariables, [variableName]: value },
    },
  });
};

const handleSetEntityDef = ({ getState, setState, params }) => {
  const { entityDefsByName = {} } = params;

  const { applicationRendererContextValue = {} } = getState();

  const previousEntitiesDef = tget(applicationRendererContextValue, APPLICATION_CONTEXT_KEYS.ENTITIES_DEF, {});

  setState({
    applicationRendererContextValue: {
      ...applicationRendererContextValue,
      [APPLICATION_CONTEXT_KEYS.ENTITIES_DEF]: { ...previousEntitiesDef, ...entityDefsByName },
    },
  });
};

const handleSetEntityViewConfig = ({ getState, setState, params }) => {
  const { entityViewConfigsToUpdate = {} } = params;

  const { applicationRendererContextValue = {} } = getState();

  const previousEntityViewConfigByNameForEntities = tget(
    applicationRendererContextValue,
    APPLICATION_CONTEXT_KEYS.ENTITY_VIEW_CONFIG_BY_NAME_FOR_ENTITIES,
    {},
  );

  const entityName = tget(entityViewConfigsToUpdate, 'entityName');
  const newEntityViewConfigByName = tget(entityViewConfigsToUpdate, 'newEntityViewConfigByName');

  const previousEntityViewConfigByNameForEntity = tget(previousEntityViewConfigByNameForEntities, entityName);

  setState({
    applicationRendererContextValue: {
      ...applicationRendererContextValue,
      [APPLICATION_CONTEXT_KEYS.ENTITY_VIEW_CONFIG_BY_NAME_FOR_ENTITIES]: {
        ...previousEntityViewConfigByNameForEntities,
        [entityName]: { ...previousEntityViewConfigByNameForEntity, ...newEntityViewConfigByName },
      },
    },
  });
};

const handleSetRecordTypeViewConfig = ({ getState, setState, params }) => {
  const { recordTypeViewConfigsToUpdate = {} } = params;

  const { applicationRendererContextValue = {} } = getState();

  const previousRecordTypeViewConfigByNameForEntities = tget(
    applicationRendererContextValue,
    APPLICATION_CONTEXT_KEYS.RECORD_TYPE_VIEW_CONFIG_BY_NAME_FOR_ENTITIES,
    {},
  );

  const entityName = tget(recordTypeViewConfigsToUpdate, 'entityName');
  const newViewConfigsByName = tget(recordTypeViewConfigsToUpdate, 'newViewConfigsByName', {});

  const previousRecordTypeViewConfigByNameForEntity = tget(previousRecordTypeViewConfigByNameForEntities, entityName);

  setState({
    applicationRendererContextValue: {
      ...applicationRendererContextValue,
      [APPLICATION_CONTEXT_KEYS.RECORD_TYPE_VIEW_CONFIG_BY_NAME_FOR_ENTITIES]: {
        ...previousRecordTypeViewConfigByNameForEntities,
        [entityName]: {
          ...previousRecordTypeViewConfigByNameForEntity,
          ...newViewConfigsByName,
        },
      },
    },
  });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT_DATA]: handleInit,
  [ACTION_TYPES.ON_TAB_CLICK]: handleTabClick,
  [ACTION_TYPES.ON_NAVIGATE_TO_PAGE]: handleNavigateToPage,
  [ACTION_TYPES.SET_APPLICATION_VARIABLES]: handleSetApplicationVariables,
  [ACTION_TYPES.SET_ENTITY_DEF]: handleSetEntityDef,
  [ACTION_TYPES.SET_ENTITY_VIEW_CONFIG]: handleSetEntityViewConfig,
  [ACTION_TYPES.SET_RECORD_TYPE_VIEW_CONFIG]: handleSetRecordTypeViewConfig,
};

export default ACTION_HANDLERS;
