import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Button from '@tekion/tekion-components/atoms/Button';

import ACTION_TYPES from '../../../../constants/createDashboard.actionTypes';

const ListItemAdd = (props) => {
  const { onAction, widgetName } = props;

  const onInfoButtonClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.LIST_WIDGET_ITEM_ADD,
      payload: { name: widgetName },
    });
  }, [onAction, widgetName]);

  return <Button onClick={onInfoButtonClick} view={Button.VIEW.ICON} icon="icon-add" />;
};

ListItemAdd.propTypes = {
  widgetName: PropTypes.string,
  onAction: PropTypes.func.isRequired,
};

ListItemAdd.defaultProps = {
  widgetName: EMPTY_STRING,
};

export default React.memo(ListItemAdd);
