import React from 'react';
import _noop from 'lodash/noop';

const ViewBuilderContext = React.createContext({
  selectedViewComponentId: undefined,
  onSelect: _noop,
  onDelete: _noop,
  onDrop: _noop,
});

export default ViewBuilderContext;
