import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './cellViewRowPropertiesForm.module.scss';

const FIELD_IDS = {
  COLUMN_COUNT: ALL_VIEW_PROPERTY_KEYS.COLUMN_COUNT,
  CUSTOM_STYLES: ALL_VIEW_PROPERTY_KEYS.CUSTOM_STYLES,
};

const SECTIONS = [
  {
    rows: [
      {
        columns: [FIELD_IDS.COLUMN_COUNT],
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.CUSTOM_STYLES],
      },
    ],
  },
];

const CUSTOM_ON_CHANGE_FIELD_IDS = [FIELD_IDS.COLUMN_COUNT];

export { SECTIONS, FIELD_IDS, CUSTOM_ON_CHANGE_FIELD_IDS };
