import { VIEW_TYPES } from '../../../../../../organisms/viewBuilder/constants/viewBuilder.constants';
import { ENTITY_VIEW_FORM_FIELD_IDS } from '../components/entityViewForm/constants/entityViewForm.general.constants';

const CONTEXT_ID = 'CREATE_ENTITY_VIEW_FORM';

const ACTION_TYPES = {
  INITIALIZE: 'INITIALIZE',
  ON_ENTITY_FORM_CHANGE: 'ON_ENTITY_FORM_CHANGE',
  ON_REMOVE_ROW: 'ON_REMOVE_ROW',
  ON_ADD_ROW: 'ON_ADD_ROW',
  ON_REDIRECTION: 'ON_REDIRECTION',
  ON_ASYNC_LOADED_OPTIONS: 'ON_ASYNC_LOADED_OPTIONS',
};

const FIELD_IDS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  VIEW_TYPE: 'viewType',
  RECORD_TYPE_SELECTION_VIEW_NAME: 'recordTypeSelectionViewName',
  VIEW_RECORD_TYPE_META_DATA_LIST: 'viewRecordTypeMetadataList',
  LIST_VIEW_NAME: 'listViewName',
};

const SELECT_INPUT_FIELDS = [FIELD_IDS.VIEW_TYPE, ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME, ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME];

const VIEW_TYPE_OPTIONS = [
  {
    label: __('Detail View'),
    value: VIEW_TYPES.DETAIL_VIEW,
  },
  {
    label: __('List View'),
    value: VIEW_TYPES.LIST_VIEW,
  },
  {
    label: __('Form View'),
    value: VIEW_TYPES.FORM_VIEW,
  },
  {
    label: __('Cell View'),
    value: VIEW_TYPES.CELL_VIEW,
  },
  {
    label: __('Grid View'),
    value: VIEW_TYPES.GRID_VIEW,
  },
];

export { FIELD_IDS, CONTEXT_ID, ACTION_TYPES, VIEW_TYPE_OPTIONS, SELECT_INPUT_FIELDS };
