import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import readers from '../readers/exportListPage.readers';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const COLUMN_IDS = {
  DOWNLOAD_DATA: 'downloadData',
  FILE_NAME: 'name',
  STATUS: 'status',
  CREATED_TIME: 'createdTime',
  DOWNLOAD_TYPE: 'downloadType',
  ENTITY_NAME: 'entityName',
  VIEW_NAME: 'viewName',
  VIEW_TYPE: 'viewType',
};

const COLUMN_NAMES = {
  [COLUMN_IDS.FILE_NAME]: __('File Name'),
  [COLUMN_IDS.STATUS]: __('Status'),
  [COLUMN_IDS.CREATED_TIME]: __('Initiated'),
  [COLUMN_IDS.DOWNLOAD_DATA]: __('Download'),
  [COLUMN_IDS.DOWNLOAD_TYPE]: __('Format'),
  [COLUMN_IDS.ENTITY_NAME]: __('Entity Name'),
  [COLUMN_IDS.VIEW_NAME]: __('View Name'),
  [COLUMN_IDS.VIEW_TYPE]: __('View Type'),
};

const COLUMNS = [
  {
    Header: COLUMN_NAMES[COLUMN_IDS.FILE_NAME],
    accessor: COLUMN_IDS.FILE_NAME,
    key: COLUMN_IDS.FILE_NAME,
    id: COLUMN_IDS.FILE_NAME,
    Cell: TextRenderer,
  },
  {
    Header: COLUMN_NAMES[COLUMN_IDS.STATUS],
    accessor: COLUMN_IDS.STATUS,
    key: COLUMN_IDS.STATUS,
    id: COLUMN_IDS.STATUS,
    Cell: StatusCellRenderer,
  },
  {
    Header: COLUMN_NAMES[COLUMN_IDS.CREATED_TIME],
    accessor: readers.createdTimeReader,
    key: COLUMN_IDS.CREATED_TIME,
    id: COLUMN_IDS.CREATED_TIME,
    Cell: TextRenderer,
  },
  {
    Header: COLUMN_NAMES[COLUMN_IDS.DOWNLOAD_TYPE],
    accessor: readers.downloadTypeReader,
    key: COLUMN_IDS.DOWNLOAD_TYPE,
    id: COLUMN_IDS.DOWNLOAD_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: COLUMN_NAMES[COLUMN_IDS.ENTITY_NAME],
    accessor: readers.exportTemplateEntityNameReader,
    key: COLUMN_IDS.ENTITY_NAME,
    id: COLUMN_IDS.ENTITY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: COLUMN_NAMES[COLUMN_IDS.VIEW_TYPE],
    accessor: readers.viewTypeReader,
    key: COLUMN_IDS.VIEW_TYPE,
    id: COLUMN_IDS.VIEW_TYPE,
    Cell: TextRenderer,
  },
];

export { COLUMNS, COLUMN_IDS, COLUMN_NAMES };
