exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".applicationRenderer_pageViewer__9ztKKFbTBX{height:100%;overflow-y:scroll}.applicationRenderer_tabHeader__4j3kZNmqTf{display:flex;justify-content:flex-start;min-width:10rem;align-items:center;font-size:1.4rem !important}[dir] .applicationRenderer_tabHeader__4j3kZNmqTf{background-color:#f4f5f6;border-radius:99px;margin-top:1rem}[dir=ltr] .applicationRenderer_tabHeader__4j3kZNmqTf{padding:0 1rem 0 0}[dir=rtl] .applicationRenderer_tabHeader__4j3kZNmqTf{padding:0 0 0 1rem}[dir] .applicationRenderer_tabHeader__4j3kZNmqTf .applicationRenderer_iconClassName__jMaj9Squrv{margin:0 .5rem}[dir] .applicationRenderer_tabHeader__4j3kZNmqTf .applicationRenderer_displayName__2vKrzZQkbn{margin:0 .5rem}.applicationRenderer_tabs__bWFjsUQfTJ{height:100%;width:100%}.applicationRenderer_noNavigationContainer__itxQvxjoEZ{width:100%;height:100%}", ""]);

// Exports
exports.locals = {
	"pageViewer": "applicationRenderer_pageViewer__9ztKKFbTBX",
	"tabHeader": "applicationRenderer_tabHeader__4j3kZNmqTf",
	"iconClassName": "applicationRenderer_iconClassName__jMaj9Squrv",
	"displayName": "applicationRenderer_displayName__2vKrzZQkbn",
	"tabs": "applicationRenderer_tabs__bWFjsUQfTJ",
	"noNavigationContainer": "applicationRenderer_noNavigationContainer__itxQvxjoEZ"
};