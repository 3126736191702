exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".entityCardView_container__phLTGvmnpi{display:flex;flex-wrap:wrap}[dir] .entityCardView_container__phLTGvmnpi{margin:2.4rem 2.4rem 0;border:1px solid #dad3d3}.entityCardView_item__5DFqEsx3eT{display:flex;flex-direction:row;flex:0 1 24%;height:20px}[dir] .entityCardView_item__5DFqEsx3eT{margin-bottom:1%;padding-top:.8rem}[dir=ltr] .entityCardView_item__5DFqEsx3eT{padding-left:.8rem}[dir=rtl] .entityCardView_item__5DFqEsx3eT{padding-right:.8rem}[dir=ltr] .entityCardView_subItem__6ve2Uqb3tb{margin-right:.8rem}[dir=rtl] .entityCardView_subItem__6ve2Uqb3tb{margin-left:.8rem}.entityCardView_iconItem__9y3FRXHRtp{display:flex;flex-direction:row-reverse;flex:0 1 24%;height:20px}[dir] .entityCardView_iconItem__9y3FRXHRtp{margin-bottom:1%;padding-top:.8rem}[dir=ltr] .entityCardView_iconItem__9y3FRXHRtp{padding-right:.8rem}[dir=rtl] .entityCardView_iconItem__9y3FRXHRtp{padding-left:.8rem}", ""]);

// Exports
exports.locals = {
	"container": "entityCardView_container__phLTGvmnpi",
	"item": "entityCardView_item__5DFqEsx3eT",
	"subItem": "entityCardView_subItem__6ve2Uqb3tb",
	"iconItem": "entityCardView_iconItem__9y3FRXHRtp"
};