import ROUTES from '../../../../constants/routes';

const TABS = {
  ENTITY_SETTINGS: 'entity-settings',
  PROJECT_SETTINGS: 'project-settings',
  USERS: 'users',
  USER_GROUPS: 'user-groups',
};

const PERMISSION_SET_NAVIGATION_DEFAULT_DATA = [{ label: __('Permission sets'), goTo: ROUTES.PERMISSION_SET_LIST_ROUTE, key: '1' }];

export { TABS, PERMISSION_SET_NAVIGATION_DEFAULT_DATA };
