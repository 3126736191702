const ACTION_TYPES = {
  ON_INIT: 'ON_INIT',
  ON_CHANGE_APPROVER_TYPE: 'ON_CHANGE_APPROVER_TYPE',
  ON_ADD_STAGE_CLICK: 'ON_ADD_STAGE_CLICK',
  ON_CANCEL_MODAL_CLICK: 'ON_CANCEL_MODAL_CLICK',
  ON_MODAL_SUBMIT: 'ON_MODAL_SUBMIT',
  ON_CHANGE_APPROVER_SEARCH_TEXT: 'ON_CHANGE_APPROVER_SEARCH_TEXT',
  ON_SCROLL_END_DROPDOWN: 'ON_SCROLL_END_DROPDOWN',
  ON_DELETE_STAGE_CLICK: 'ON_DELETE_STAGE_CLICK',
  ON_CLICK_EDIT_STAGE: 'ON_CLICK_EDIT_STAGE',
};

export default ACTION_TYPES;
