import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { NO_DATA } from '@tekion/tekion-base/app.constants';

import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

import styles from './filterCard.module.scss';

const FilterCard = ({ id, className, iconName, iconClassName, name, count, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <div className={`${className} ${styles.filterContainer}`} onClick={handleClick}>
      <div className={styles.iconNameContainer}>
        <FontIcon className={iconClassName}>{iconName}</FontIcon>
        <div className={styles.nameClass}>{name}</div>
      </div>
      <div className={styles.countClassName}>{count}</div>
    </div>
  );
};

FilterCard.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconName: PropTypes.string,
  iconClassName: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  onClick: PropTypes.func,
};

FilterCard.defaultProps = {
  className: '',
  iconName: '',
  iconClassName: '',
  name: NO_DATA,
  count: NO_DATA,
  onClick: _noop,
};

export default FilterCard;
