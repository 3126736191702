import _get from 'lodash/get';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { PROPERTIES_FIELD_IDS } from './propertiesForm.constants';
import { COMPONENT_TYPES } from '../../constants/viewBuilder.constants';
import { ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './propertiesForm.module.scss';

const SECTION_FOR_SECTION_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.SECTION_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_SECTION_COLUMN_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.SECTION_COLUMN_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_DETAIL_VIEW_ATTRIBUTE_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.DETAIL_VIEW_ATTRIBUTE_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_LIST_VIEW_RENDERER_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.LIST_VIEW_RENDERER_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_GRID_VIEW_RENDERER_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.GRID_VIEW_RENDERER_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_LIST_VIEW_COLUMN_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.LIST_VIEW_COLUMN_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_FORM_VIEW_COLUMN_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.FORM_VIEW_COLUMN_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_FORM_VIEW_SECTION_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.FORM_VIEW_SECTION_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_FORM_VIEW_ROW_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.FORM_VIEW_ROW_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_CELL_VIEW_ROW_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.CELL_VIEW_ROW_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_CELL_VIEW_COLUMN_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.CELL_VIEW_COLUMN_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_IMAGE_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.IMAGE_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_BUTTON_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.BUTTON_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_CELL_VIEW_ATTRIBUTE_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.CELL_VIEW_ATTRIBUTE_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_GRID_VIEW_ATTRIBUTE_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.GRID_VIEW_ATTRIBUTE_PROPERTIES_FORM],
      },
    ],
  },
];

const SECTION_FOR_FORM_VIEW_RENDERER_COMPONENT_TYPE = [
  {
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [PROPERTIES_FIELD_IDS.FORM_VIEW_RENDERER_PROPERTIES_FORM],
      },
    ],
  },
];

const getSectionsAccordingToComponentType = (selectedViewComponent) => {
  const componentType = _get(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.TYPE);

  switch (componentType) {
    case COMPONENT_TYPES.SECTION:
      return SECTION_FOR_SECTION_COMPONENT_TYPE;
    case COMPONENT_TYPES.SECTION_COLUMN:
      return SECTION_FOR_SECTION_COLUMN_COMPONENT_TYPE;
    case COMPONENT_TYPES.DETAIL_VIEW_ATTRIBUTE:
      return SECTION_FOR_DETAIL_VIEW_ATTRIBUTE_COMPONENT_TYPE;
    case COMPONENT_TYPES.LIST_VIEW_RENDERER:
      return SECTION_FOR_LIST_VIEW_RENDERER_COMPONENT_TYPE;
    case COMPONENT_TYPES.LIST_VIEW_COLUMN:
      return SECTION_FOR_LIST_VIEW_COLUMN_COMPONENT_TYPE;
    case COMPONENT_TYPES.GRID_VIEW:
      return SECTION_FOR_GRID_VIEW_RENDERER_COMPONENT_TYPE;
    case COMPONENT_TYPES.FORM_VIEW_RENDERER:
      return SECTION_FOR_FORM_VIEW_RENDERER_COMPONENT_TYPE;
    case COMPONENT_TYPES.FORM_VIEW_COLUMN:
      return SECTION_FOR_FORM_VIEW_COLUMN_COMPONENT_TYPE;
    case COMPONENT_TYPES.FORM_VIEW_ROW:
      return SECTION_FOR_FORM_VIEW_ROW_COMPONENT_TYPE;
    case COMPONENT_TYPES.FORM_VIEW_SECTION:
      return SECTION_FOR_FORM_VIEW_SECTION_COMPONENT_TYPE;
    case COMPONENT_TYPES.ROW:
      return SECTION_FOR_CELL_VIEW_ROW_COMPONENT_TYPE;
    case COMPONENT_TYPES.COLUMN:
      return SECTION_FOR_CELL_VIEW_COLUMN_COMPONENT_TYPE;
    case COMPONENT_TYPES.IMAGE:
      return SECTION_FOR_IMAGE_COMPONENT_TYPE;
    case COMPONENT_TYPES.CELL_VIEW_ATTRIBUTE:
      return SECTION_FOR_CELL_VIEW_ATTRIBUTE_COMPONENT_TYPE;
    case COMPONENT_TYPES.GRID_VIEW_ATTRIBUTE:
      return SECTION_FOR_GRID_VIEW_ATTRIBUTE_COMPONENT_TYPE;
    case COMPONENT_TYPES.BUTTON:
      return SECTION_FOR_BUTTON_COMPONENT_TYPE;
    default:
      return [];
  }
};

const getSections = (selectedViewComponent) => {
  const properties = _get(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);

  const propSection = {
    header: {
      size: 4,
      label: __(`${_get(properties, ALL_VIEW_PROPERTY_KEYS.TITLE, EMPTY_STRING)} Properties`),
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainerForProperties,
    rows: [],
  };

  const sections = getSectionsAccordingToComponentType(selectedViewComponent) || [];
  return [propSection, ...sections];
};

export { getSections };
