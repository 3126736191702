import _map from 'lodash/map';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { SEND_EMAILS_ADDRESSEE_TYPE } from './sendEmailsActionDefinitionSection.constants';
import { ACTION_BUILDER_ACTION_TYPES } from '../../constants/actionBuilderForm.general';
import FIELD_TYPES from '../../../../../../../constants/fieldDefinition.fieldTypes';

import fieldDefinitionReader from '../../../../../../../readers/fieldDefinition.reader';

const getSourceFieldOptions = (fieldDefinitions) =>
  _reduce(
    fieldDefinitions,
    (ans, fieldDef) => {
      const fieldType = fieldDefinitionReader.fieldType(fieldDef);
      if (fieldType === FIELD_TYPES.RELATIONSHIP) {
        ans.push({
          label: fieldDefinitionReader.displayName(fieldDef),
          value: fieldDefinitionReader.name(fieldDef),
          lookupField: _get(fieldDef, 'lookupField.entityType'),
        });
      }
      return ans;
    },
    [],
  );

const getDerivedFieldOptions = (sourceField, sourceEntityName, conditionBuilderFieldDefinitionObject) => {
  const sourceFieldDefs = conditionBuilderFieldDefinitionObject[sourceEntityName];
  const sourceFieldDef = _get(sourceFieldDefs, sourceField, EMPTY_OBJECT);

  const relationshipEntityName = _get(sourceFieldDef, 'lookupField.entityType');
  const fieldDefinitions = _get(conditionBuilderFieldDefinitionObject, relationshipEntityName, EMPTY_OBJECT);

  const options = _map(fieldDefinitions, (fieldDef) => ({
    label: fieldDefinitionReader.displayName(fieldDef),
    value: fieldDefinitionReader.name(fieldDef),
  }));
  return options;
};

const getTemplateValueFieldProps = (actionType, addresseeType) => {
  if (actionType === ACTION_BUILDER_ACTION_TYPES.SEND_EMAILS && addresseeType === SEND_EMAILS_ADDRESSEE_TYPE.DERIVED) {
    return { isRelationshipRecursive: false, isComplexRecursive: false };
  }
  return EMPTY_OBJECT;
};

export { getSourceFieldOptions, getDerivedFieldOptions, getTemplateValueFieldProps };
