import React from 'react';

import { NO_DATA } from '@tekion/tekion-base/app.constants';

import { TOOLBAR_GROUPS } from '../../constants/richTextEditor.plugins';
import { DEFAULT_SHORTCUTS_INFO } from './constants/shortcutsInfoDialog.constants';

import styles from './shortcutsInfoDialog.module.scss';

const DEFAULT_SHORTCUTS_INFO_WITH_JSX = [
  ...DEFAULT_SHORTCUTS_INFO,
  {
    preview: (
      <span>
        Superscript<sup>1</sup>
      </span>
    ),
    markdown: 'Superscript^1',
    shortcut: NO_DATA,
  },
  {
    preview: (
      <span>
        Subscript<sub>1</sub>
      </span>
    ),
    markdown: 'Subscript~1',
    shortcut: NO_DATA,
  },
];

const MARKS_SHORTCUTS_INFO = [
  {
    preview: <b>Bold</b>,
    markdown: '**Bold**',
    shortcut: 'cmd+b',
  },
  {
    preview: <i>Italic</i>,
    markdown: '*Italic*',
    shortcut: 'cmd+i',
  },
  {
    preview: <u>Underline</u>,
    markdown: '__Underline__',
    shortcut: 'cmd+u',
  },
  {
    preview: <s>Strikethrough</s>,
    markdown: '~~Strikethrough~~',
    shortcut: 'cmd+shift+s',
  },
];

const INSERTION_SHORTCUTS_INFO = [
  {
    preview: <span className={styles.link}>Link</span>,
    markdown: NO_DATA,
    shortcut: 'cmd+k',
  },
];

const SHORTCUTS_INFOS = {
  [TOOLBAR_GROUPS.MARKS]: MARKS_SHORTCUTS_INFO,
  [TOOLBAR_GROUPS.INSERTION]: INSERTION_SHORTCUTS_INFO,
};

export { DEFAULT_SHORTCUTS_INFO_WITH_JSX, SHORTCUTS_INFOS };
