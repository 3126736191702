import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import Tabs from '@tekion/tekion-components/molecules/Tabs';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import ComponentPanels from '../componentPanels/ComponentPanels';
import TabBarRenderer from './TabBarRenderer';
import PropertiesForm from '../propertiesForm';

import { getComponentPanels, getEntityPanels } from './configurator.helper';
import { getUpdatedViewConfigForUpdatedSelectedViewComponent } from '../../helpers/viewBuilder.helper';

import { DEFAULT_TAB_KEY, VIEW_TYPES } from '../../constants/viewBuilder.constants';
import { CONFIGURATOR_PANEL_DISPLAY_NAMES, CONFIGURATOR_PANEL_IDS } from './configuratorForm.constant';
import { VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './configurator.module.scss';

const Configurator = ({
  viewType,
  componentDisplayName,
  selectedViewComponent,
  viewConfiguration,
  entity,
  errors,
  usedEntityFieldNames,
  handleComponentConfiguratorAction,
}) => {
  const [activeTabKey, setActiveTabKey] = useState(DEFAULT_TAB_KEY);

  const isEntityTabVisible = viewType !== VIEW_TYPES.GRID_VIEW;
  const isComponentTabVisible = viewType !== VIEW_TYPES.LIST_VIEW && viewType !== VIEW_TYPES.GRID_VIEW;
  const properties = _get(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, {});
  const entityPanels = getEntityPanels(viewType, entity, usedEntityFieldNames);
  const componentPanels = getComponentPanels(viewType);

  const handlePropertiesFormAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          // Properties form on change will pass the updated selected view component

          const updatedViewConfig = getUpdatedViewConfigForUpdatedSelectedViewComponent(viewConfiguration, payload);
          handleComponentConfiguratorAction({ type, payload: { newViewConfiguration: updatedViewConfig } });
          break;
        }

        case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
          const _errors = _get(payload, 'errors', EMPTY_OBJECT);
          handleComponentConfiguratorAction({ type, payload: { errors: _errors } });
          break;
        }

        default: {
          break;
        }
      }
    },
    [handleComponentConfiguratorAction, viewConfiguration],
  );

  const onTabChange = useCallback((newActiveTabKey) => {
    setActiveTabKey(newActiveTabKey);
  }, []);

  const renderTabBar = useCallback((props) => <TabBarRenderer {...props} viewType={viewType} />, [viewType]);

  const renderTabs = useCallback(() => {
    const tabPanels = [];

    // Properties tab
    tabPanels.push(
      <Tabs.TabPane
        key={CONFIGURATOR_PANEL_IDS.PROPERTIES}
        tab={CONFIGURATOR_PANEL_DISPLAY_NAMES[CONFIGURATOR_PANEL_IDS.PROPERTIES]}
        className={styles.tabPane}
        isActive={activeTabKey === CONFIGURATOR_PANEL_IDS.PROPERTIES}
      >
        {!_isEmpty(properties) ? (
          <PropertiesForm
            selectedViewComponent={selectedViewComponent}
            properties={properties}
            entity={entity}
            errors={errors}
            onAction={handlePropertiesFormAction}
          />
        ) : (
          <>
            No <strong>{__(componentDisplayName)}</strong> properties to configure.
          </>
        )}
      </Tabs.TabPane>,
    );

    if (isComponentTabVisible) {
      tabPanels.push(
        <Tabs.TabPane
          key={CONFIGURATOR_PANEL_IDS.COMPONENTS}
          tab={CONFIGURATOR_PANEL_DISPLAY_NAMES[CONFIGURATOR_PANEL_IDS.COMPONENTS]}
          className={styles.tabPane}
          isActive={activeTabKey === CONFIGURATOR_PANEL_IDS.COMPONENTS}
        >
          <ComponentPanels panels={componentPanels} />
        </Tabs.TabPane>,
      );
    }

    if (isEntityTabVisible) {
      // Entity tab
      tabPanels.push(
        <Tabs.TabPane
          key={CONFIGURATOR_PANEL_IDS.ENTITY}
          tab={CONFIGURATOR_PANEL_DISPLAY_NAMES[CONFIGURATOR_PANEL_IDS.ENTITY]}
          className={styles.tabPane}
          isActive={activeTabKey === CONFIGURATOR_PANEL_IDS.ENTITY}
        >
          <ComponentPanels panels={entityPanels} usedEntityFieldNames={usedEntityFieldNames} />
        </Tabs.TabPane>,
      );
    }

    return tabPanels;
  }, [
    activeTabKey,
    properties,
    selectedViewComponent,
    entity,
    errors,
    handlePropertiesFormAction,
    componentDisplayName,
    isComponentTabVisible,
    isEntityTabVisible,
    componentPanels,
    entityPanels,
    usedEntityFieldNames,
  ]);

  return (
    <Tabs className={styles.componentConfigurator} activeKey={activeTabKey} animated={false} renderTabBar={renderTabBar} onChange={onTabChange}>
      {renderTabs()}
    </Tabs>
  );
};

Configurator.propTypes = {
  componentDisplayName: PropTypes.string.isRequired,
  viewType: PropTypes.string.isRequired,
  selectedViewComponent: PropTypes.object.isRequired,
  entity: PropTypes.object,
  viewConfiguration: PropTypes.object,
  errors: PropTypes.object,
  usedEntityFieldNames: PropTypes.arrayOf(PropTypes.string),
  handleComponentConfiguratorAction: PropTypes.func.isRequired,
};

Configurator.defaultProps = {
  entity: undefined,
  viewConfiguration: undefined,
  errors: EMPTY_OBJECT,
  usedEntityFieldNames: [],
};

export default React.memo(Configurator);
