import _property from 'lodash/property';

const commentReader = {
  name: _property('name'),
  displayName: _property('displayName'),
  description: _property('description'),
  createdTime: _property('createdTime'),
  createdBy: _property('createdBy'),
  content: _property('content'),
  email: _property('email'),
  deleted: _property('deleted'),
  id: _property('id'),
  inReplyTo: _property('inReplyTo'),
  modifiedTime: _property('modifiedTime'),
  platform: _property('platform'),
  projectName: _property('projectName'),
  taggedUsers: _property('taggedUsers'),
  showReplyInput: _property('showReplyInput'),
  showRepliesEnabled: _property('showRepliesEnabled'),
  replies: _property('replies'),
  nextPageToken: _property('nextPageToken'),
  commentMode: _property('commentMode'),
};

export default commentReader;
