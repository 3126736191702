import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _get from 'lodash/get';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import HelperText from '@tekion/tekion-components/atoms/HelperText';
import Input from '@tekion/tekion-components/molecules/Input';
import FORM_BUILDER_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import styles from './inputRange.module.scss';

const getClassNames = (props) => {
  if (props.error) return `error ${_get(props, 'fieldClassName', '')}`;
  return _get(props, 'fieldClassName', '');
};

const InputRange = (props) => {
  const { required, fromLabel, toLabel, fromKey, toKey, id, label, helperTextClassName, value = EMPTY_OBJECT, error, onAction, ...rest } = props;

  const onValueChange = useCallback(
    (e) => {
      onAction({
        type: FORM_BUILDER_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: {
          id,
          value: { ...value, [e.target.name]: e.target.value },
        },
      });
    },
    [id, value, onAction],
  );

  return (
    <div className={classNames(getClassNames(props))}>
      <div className="d-flex flex-row align-items-center justify-content-center">
        <Input
          {...rest}
          label={label}
          name={fromKey}
          onChange={onValueChange}
          value={_get(value, fromKey)}
          error={_get(error, 'from')}
          required={required}
          addonBefore={__('From')}
        />
        <HelperText className={classNames('d-flex align-items-center justify-content-center', styles.helperText, helperTextClassName)} />
        <Input
          {...rest}
          className={styles.inputField}
          name={toKey}
          onChange={onValueChange}
          value={_get(value, toKey)}
          error={_get(error, 'to')}
          required={required}
          addonBefore={__('To')}
        />
      </div>
    </div>
  );
};

InputRange.propTypes = {
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  fromLabel: PropTypes.string,
  toLabel: PropTypes.string,
  fromKey: PropTypes.string,
  toKey: PropTypes.string,
  label: PropTypes.string,
  helperTextClassName: PropTypes.string,
  value: PropTypes.object,
  error: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

InputRange.defaultProps = {
  required: false,
  fromLabel: __('From'),
  toLabel: __('To'),
  fromKey: 'from',
  toKey: 'to',
  label: EMPTY_STRING,
  helperTextClassName: EMPTY_STRING,
  value: EMPTY_OBJECT,
  error: EMPTY_OBJECT,
};

export default InputRange;
