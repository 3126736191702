import _join from 'lodash/join';
import _get from 'lodash/get';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

const workspaceUserReader = {
  NAME: (user = EMPTY_OBJECT) =>
    _get(user, 'name') || _join([_get(user, 'entity.firstname', EMPTY_STRING), _get(user, 'entity.lastname', EMPTY_STRING)], ' '),
  id: (user = EMPTY_OBJECT) => _get(user, 'id'),
  fullName: (user = EMPTY_OBJECT) => _join([_get(user, 'entity.firstname', EMPTY_STRING), _get(user, 'entity.lastname', EMPTY_STRING)], ' '),
  firstName: (user = EMPTY_OBJECT) => _get(user, 'entity.firstname'),
  lastName: (user = EMPTY_OBJECT) => _get(user, 'entity.lastname'),
  phone: (user = EMPTY_OBJECT) => _get(user, 'entity.phone'),
  countryCode: (user = EMPTY_OBJECT) => _get(user, 'entity.countrycode'),
  email: (user = EMPTY_OBJECT) => _get(user, 'entity.email'),
  profile: (user = EMPTY_OBJECT) => _get(user, 'entity.profile'),
  roleId: (user = EMPTY_OBJECT) => _get(user, 'entity.roleid'),
  roleName: (user = EMPTY_OBJECT) => _get(user, 'entity.rolename'),
  displayName: (user = EMPTY_OBJECT) => _get(user, 'entity.displayname'),
  active: (user = EMPTY_OBJECT) => _get(user, 'entity.active', false),
  lockUser: (user = EMPTY_OBJECT) => _get(user, 'entity.lockuser', false),
  profilePicture: (user = EMPTY_OBJECT) => _get(user, 'entity.profilepicture'),
  password: (user = EMPTY_OBJECT) => _get(user, 'entity.password', EMPTY_STRING),
  profileName: (user = EMPTY_OBJECT) => _get(user, 'entity.profilename'),
};

export default workspaceUserReader;
