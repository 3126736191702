import ButtonPropertiesConfigure from '../components/buttonPropertiesConfigure/ButtonPropertiesConfigure';
import CustomStylesConfigure from '../components/customStylesConfigure/CustomStylesConfigure';
import EntityAndViewConfigure from '../components/entityAndViewConfigure/entityAndViewConfigure';
import EventHandlerConfigurator from '../components/eventHandlerConfigurator/EventHandlerConfigurator';
import GridPropertiesConfigure from '../components/gridPropertiesConfigure/GridPropertiesConfigure';
import TabPropertiesConfigure from '../components/tabPropertiesConfigure/TabPropertiesConfigurator';
import TextPropertiesConfigure from '../components/textPropertiesConfigure/TextPropertiesConfigure';
import WidgetNameConfigure from '../components/widgetNameConfigure/WidgetNameConfigure';
import PreAppliedFiltersConfigurator from '../components/preAppliedFiltersConfigurator/PreAppliedFiltersConfigurator';
import CustomActionsVisualConfigurator from '../components/customActionsVisualConfigurator';

const PROPERTIES_CONFIGURATOR = {
  WIDGET_NAME_CONFIGURATOR: 'WIDGET_NAME_CONFIGURATOR',
  BUTTON_PROPERTIES_CONFIGURATOR: 'BUTTON_PROPERTIES_CONFIGURATOR',
  EVENT_HANDLER_CONFIGURATOR: 'EVENT_HANDLER_CONFIGURATOR',
  CUSTOM_STYLE_CONFIGURATOR: 'CUSTOM_STYLE_CONFIGURATOR',
  ENTITY_AND_VIEW_CONFIGURATOR: 'ENTITY_AND_VIEW_CONFIGURATOR',
  TAB_PROPERTIES_CONFIGURATOR: 'TAB_PROPERTIES_CONFIGURATOR',
  GRID_PROPERTIES_CONFIGURATOR: 'GRID_PROPERTIES_CONFIGURATOR',
  TEXT_PROPERTIES_CONFIGURATOR: 'TEXT_PROPERTIES_CONFIGURATOR',
  PRE_APPLIED_FILTERS_CONFIGURATOR: 'PRE_APPLIED_FILTERS_CONFIGURATOR',
  CUSTOM_ACTIONS_CONFIGURATOR: 'CUSTOM_ACTIONS_CONFIGURATOR',
};

const CONFIGURATOR_BY_PROPERTIES = {
  [PROPERTIES_CONFIGURATOR.WIDGET_NAME_CONFIGURATOR]: WidgetNameConfigure,
  [PROPERTIES_CONFIGURATOR.BUTTON_PROPERTIES_CONFIGURATOR]: ButtonPropertiesConfigure,
  [PROPERTIES_CONFIGURATOR.CUSTOM_STYLE_CONFIGURATOR]: CustomStylesConfigure,
  [PROPERTIES_CONFIGURATOR.ENTITY_AND_VIEW_CONFIGURATOR]: EntityAndViewConfigure,
  [PROPERTIES_CONFIGURATOR.EVENT_HANDLER_CONFIGURATOR]: EventHandlerConfigurator,
  [PROPERTIES_CONFIGURATOR.GRID_PROPERTIES_CONFIGURATOR]: GridPropertiesConfigure,
  [PROPERTIES_CONFIGURATOR.TEXT_PROPERTIES_CONFIGURATOR]: TextPropertiesConfigure,
  [PROPERTIES_CONFIGURATOR.TAB_PROPERTIES_CONFIGURATOR]: TabPropertiesConfigure,
  [PROPERTIES_CONFIGURATOR.PRE_APPLIED_FILTERS_CONFIGURATOR]: PreAppliedFiltersConfigurator,
  [PROPERTIES_CONFIGURATOR.CUSTOM_ACTIONS_CONFIGURATOR]: CustomActionsVisualConfigurator,
};

const PROPERTIES_PANEL_HEADER_NAME = {
  [PROPERTIES_CONFIGURATOR.WIDGET_NAME_CONFIGURATOR]: __('Widget Name'),
  [PROPERTIES_CONFIGURATOR.BUTTON_PROPERTIES_CONFIGURATOR]: __('Button Properties'),
  [PROPERTIES_CONFIGURATOR.ENTITY_AND_VIEW_CONFIGURATOR]: __('Entity and View'),
  [PROPERTIES_CONFIGURATOR.GRID_PROPERTIES_CONFIGURATOR]: __('Grid Properties'),
  [PROPERTIES_CONFIGURATOR.EVENT_HANDLER_CONFIGURATOR]: __('Event Handlers'),
  [PROPERTIES_CONFIGURATOR.TAB_PROPERTIES_CONFIGURATOR]: __('Tab Properties'),
  [PROPERTIES_CONFIGURATOR.CUSTOM_STYLE_CONFIGURATOR]: __('Custom Styles'),
  [PROPERTIES_CONFIGURATOR.TEXT_PROPERTIES_CONFIGURATOR]: __('Text Properties'),
  [PROPERTIES_CONFIGURATOR.PRE_APPLIED_FILTERS_CONFIGURATOR]: __('Pre Applied Filters'),
  [PROPERTIES_CONFIGURATOR.CUSTOM_ACTIONS_CONFIGURATOR]: __('Custom Actions'),
};

export { PROPERTIES_PANEL_HEADER_NAME, CONFIGURATOR_BY_PROPERTIES, PROPERTIES_CONFIGURATOR };
