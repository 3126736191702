import { defaultMemoize } from 'reselect';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import withKeyPressInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput/withKeyPressInput';
import { FIELD_NAMES } from './resetPasswordPage.constants';

import styles from '../AuthenticationForm/authentication.module.scss';

const FORM_SECTION = defaultMemoize(() => [
  {
    rows: [
      {
        columns: [FIELD_NAMES.USERNAME],
      },
    ],
  },
]);

const FORM_CONFIG = defaultMemoize(() => ({
  [FIELD_NAMES.USERNAME]: {
    renderer: withKeyPressInput,
    renderOptions: {
      label: __('Username'),
      required: true,
      validators: [isRequiredRule],
      className: styles.textInputPadding,
    },
  },
}));

export { FORM_CONFIG, FORM_SECTION };
