import _isArray from 'lodash/isArray';
import _reduce from 'lodash/reduce';
import _filter from 'lodash/filter';
import _pick from 'lodash/pick';
import _head from 'lodash/head';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _get from 'lodash/get';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { DELAY_TASK_TAB_IDS } from '../constants/delayTaskModal.tabs';
import DELAY_TASK_MODAL_FIELD_IDS from '../constants/delayTaskModal.fieldIds';
import { TASK_VALUES } from '../constants/delayTaskModal.constants';

import { convertTimeInputToMs } from '../../../../utils/convertTimeUnits';

const generateUserData = (tabs) => {
  const tabsByType = _reduce(
    tabs,
    (draft, tab) => {
      const { type } = tab;

      if (_isArray(draft[type])) {
        draft[type].push(tab);
      } else {
        draft[type] = [tab];
      }

      return draft;
    },
    {},
  );

  const userData = _reduce(
    tabsByType,
    (draft, tabsForType, type) => {
      switch (type) {
        case DELAY_TASK_TAB_IDS.TIME_DELAY: {
          const { values } = _head(tabsForType);
          const delayTimeValue = tget(values, DELAY_TASK_MODAL_FIELD_IDS.EXECUTION_DELAY);
          const value = _get(delayTimeValue, 'value', 0);
          let delayTimeInMilliSeconds = 0;
          if (value) {
            delayTimeInMilliSeconds = convertTimeInputToMs(value, _get(delayTimeValue, 'unit'));
          }
          _set(draft, DELAY_TASK_MODAL_FIELD_IDS.EXECUTION_DELAY, delayTimeInMilliSeconds);
          _set(draft, 'delayTime', { unit: _get(delayTimeValue, 'unit'), time: value });
          break;
        }
        case DELAY_TASK_TAB_IDS.ADD_DELAY_EXIT_EVENT: {
          const delayExitEvents = _map(tabsForType, (tab) => {
            const { id, values } = tab;
            return {
              [DELAY_TASK_MODAL_FIELD_IDS.ID]: id,
              [DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME]: tget(values, DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME),
              [DELAY_TASK_MODAL_FIELD_IDS.EVENT_TYPE]: tget(values, DELAY_TASK_MODAL_FIELD_IDS.EVENT_TYPE),
              [DELAY_TASK_MODAL_FIELD_IDS.ENTITY_NAME]: getArraySafeValue(tget(values, DELAY_TASK_MODAL_FIELD_IDS.ENTITY_NAME)),
              userData: {
                data: _pick(values, [DELAY_TASK_MODAL_FIELD_IDS.CONDITION]),
              },
            };
          });

          _set(draft, DELAY_TASK_MODAL_FIELD_IDS.EXPECTED_EVENT_TYPE, delayExitEvents);

          break;
        }
        case DELAY_TASK_TAB_IDS.ADD_WORKFLOW_EXIT_EVENT: {
          const workflowExitEvents = _map(tabsForType, (tab) => {
            const { id, values } = tab;
            return {
              [DELAY_TASK_MODAL_FIELD_IDS.ID]: id,
              [DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME]: tget(values, DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME),
              [DELAY_TASK_MODAL_FIELD_IDS.EVENT_TYPE]: tget(values, DELAY_TASK_MODAL_FIELD_IDS.EVENT_TYPE),
              [DELAY_TASK_MODAL_FIELD_IDS.ENTITY_NAME]: getArraySafeValue(tget(values, DELAY_TASK_MODAL_FIELD_IDS.ENTITY_NAME)),
              [DELAY_TASK_MODAL_FIELD_IDS.WORKFLOW]: tget(values, DELAY_TASK_MODAL_FIELD_IDS.WORKFLOW, null),
              userData: {
                data: _pick(values, [DELAY_TASK_MODAL_FIELD_IDS.CONDITION]),
              },
            };
          });

          _set(draft, DELAY_TASK_MODAL_FIELD_IDS.EXIT_EVENT_TYPE, workflowExitEvents);

          break;
        }
        default:
      }

      return draft;
    },
    {},
  );

  return userData;
};

const getChildLabels = (tabs) => {
  const delayExitEvents = _filter(tabs, { type: DELAY_TASK_TAB_IDS.ADD_DELAY_EXIT_EVENT });
  const workflowExitEvents = _filter(tabs, { type: DELAY_TASK_TAB_IDS.ADD_WORKFLOW_EXIT_EVENT });

  let eventNames = _map(delayExitEvents, (event) => {
    const { values } = event;
    const eventName = tget(values, DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME);

    return eventName;
  });

  eventNames = [
    ...eventNames,
    ..._map(workflowExitEvents, (event) => {
      const { values } = event;
      const eventName = tget(values, DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME);

      return eventName;
    }),
  ];

  return [TASK_VALUES.TIME_DELAY, ...eventNames];
};

/*
[
    {
        "id": "TIME_DELAY",
        "displayName": "Time delay",
        "type": "TIME_DELAY",
        "values": {
            "executionDelay": "12"
        },
        "errors": {}
    },
    {
        "id": "ADD_DELAY_EXIT_EVENT",
        "displayName": "Add delay exit event"
    },
    {
        "id": "1669892730087",
        "displayName": "Some name",
        "type": "ADD_DELAY_EXIT_EVENT",
        "values": {
            "displayName": "Some name",
            "eventType": "RECORD_CREATED",
            "entityName": [
                "mass_communication__u"
            ],
            "condtition": {
                "criteriaList": [
                    {
                        "fieldName": "generate_mass_communication__u",
                        "operatorType": "IN",
                        "values": [
                            true
                        ]
                    }
                ],
                "expression": "1"
            }
        },
        "errors": {}
    },
    {
        "id": "ADD_WORKFLOW_EXIT_EVENT",
        "displayName": "Add workflow exit event"
    },
    {
        "id": "1669892743978",
        "displayName": "Some other name",
        "type": "ADD_WORKFLOW_EXIT_EVENT",
        "values": {
            "displayName": "Some other name",
            "eventType": "RECORD_CREATED",
            "entityName": [
                "mass_communication__u"
            ],
            "condtition": {
                "criteriaList": [
                    {
                        "fieldName": "generate_mass_communication__u",
                        "operatorType": "IN",
                        "values": [
                            false
                        ]
                    }
                ],
                "expression": "1"
            }
        },
        "errors": {}
    }
]
*/

export { generateUserData, getChildLabels };
