import _times from 'lodash/times';
import { ELEMENT_DEFAULT } from '@udecode/plate-common';
import { ELEMENT_TR, ELEMENT_TD, ELEMENT_TH } from '@udecode/plate-table';
import { getPlatePluginType } from '@udecode/plate-core';

// utils
import { uuid } from '@tekion/tekion-base/utils/general';

export const createEmptyCellNode = ({ editor, isHeader, rowId, columnId }) => {
  const pluginKey = isHeader ? ELEMENT_TH : ELEMENT_TD;
  return {
    type: getPlatePluginType(editor, pluginKey),
    rowId,
    columnId,
    children: [
      {
        type: getPlatePluginType(editor, ELEMENT_DEFAULT),
        children: [{ text: '' }],
      },
    ],
  };
};

export const createEmptyRowNode = ({ editor, columnCount, columnIds, isHeader }) => {
  const rowId = uuid();
  const cells = _times(columnCount, (index) => createEmptyCellNode({ editor, isHeader, rowId, columnId: columnIds[index] }));
  return {
    type: getPlatePluginType(editor, ELEMENT_TR),
    children: cells,
    rowId,
  };
};
