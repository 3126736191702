/* eslint-disable import/order */
import { useContext, useEffect, useState } from 'react';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import _curry from 'lodash/curry';

// utils
import { uuid } from '@tekion/tekion-base/utils/general';

// hooks
import useEditor from './useEditor';

// constants
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

// context
import { UploadingMediaContext } from '../containers/withUploadingMediaStatus';

// eslint-disable-next-line no-unused-vars
const handleMediaUploadFailure = _curry((editor, element, error) => {
  const mediaPath = ReactEditor.findPath(editor, element);
  Transforms.removeNodes(editor, { at: mediaPath });
});

const handleMediaUploadSuccess =
  ({ editor, element, setUploaded }) =>
  (hasUploadingFailed) => {
    if (hasUploadingFailed) {
      handleMediaUploadFailure(editor, element, undefined);
    } else {
      setUploaded(false);
    }
  };

const setMediaAsUploaded = (setMediaUploadingStatus, uploadingMediaIdentifier) => () => {
  setMediaUploadingStatus(uploadingMediaIdentifier, false);
};

const uploadMedia = ({ shouldUpload, addMedia, selectedFile, setUploaded, queryOptions, setMediaUploadingStatus, editor, element }) => {
  if (shouldUpload) {
    const uploadingMediaIdentifier = uuid();
    setMediaUploadingStatus(uploadingMediaIdentifier, true);
    addMedia([selectedFile], queryOptions)
      .then(handleMediaUploadSuccess({ editor, element, setUploaded }))
      .catch(handleMediaUploadFailure(editor, element))
      .finally(setMediaAsUploaded(setMediaUploadingStatus, uploadingMediaIdentifier));
  }
};

function useUploadMedia({ mediaId, addMedia, selectedFile, queryOptions, element }) {
  const shouldUpload = !mediaId;
  const [, setUploaded] = useState(shouldUpload);
  const setMediaUploadingStatus = useContext(UploadingMediaContext);
  const editor = useEditor();

  useEffect(() => {
    uploadMedia({
      shouldUpload,
      setUploaded,
      selectedFile,
      addMedia,
      queryOptions,
      setMediaUploadingStatus,
      editor,
      element,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, EMPTY_ARRAY);

  return {
    shouldUpload,
  };
}

export default useUploadMedia;
