import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import cx from 'classnames';

import _includes from 'lodash/includes';

import { tget } from '@tekion/tekion-base/utils/general';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import ViewBuilderContext from '../../ViewBuilder.context';

import { getDndButtonDirection, shouldApplyPadding } from './viewComponent.helpers';

import { NON_DRAGGABLE_COMPONENTS } from '../../constants/viewBuilder.constants';
import { VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';
import { DND_ICON_DIRECTION } from './viewComponent.const';

import styles from './viewComponent.module.scss';

const ViewComponent = ({ index, style, className, isDeleteEnabled, isEditEnabled, componentConfig, children }) => {
  const { selectedViewComponentId, onSelect, onDelete, onEdit } = useContext(ViewBuilderContext);

  const type = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.TYPE);
  const applyPadding = useMemo(() => shouldApplyPadding(style), [style]);
  const dndButtonDirection = useMemo(() => getDndButtonDirection(type), [type]);

  let dndClassName = null;
  if (dndButtonDirection === DND_ICON_DIRECTION.TOP) dndClassName = styles.dndIconHorizontal;
  else if (dndButtonDirection === DND_ICON_DIRECTION.LEFT) dndClassName = styles.dndIconVertical;
  else dndClassName = styles.dndIconBlock;

  const sectionId = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);

  const [, dragRef] = useDrag({
    type,
    item: {
      index,
      ...componentConfig,
    },
    collect: () => ({}),
  });

  const handleSelect = useCallback(
    (event) => {
      event.stopPropagation();

      onSelect(sectionId);
    },
    [sectionId, onSelect],
  );

  const handleDelete = useCallback(
    (event) => {
      event.stopPropagation();

      onDelete(sectionId);
    },
    [sectionId, onDelete],
  );

  const handleEdit = useCallback(
    (event) => {
      event.stopPropagation();

      onEdit(sectionId);
    },
    [sectionId, onEdit],
  );

  const getRef = useCallback(() => (_includes(NON_DRAGGABLE_COMPONENTS, type) ? {} : { ref: dragRef }), [dragRef, type]);

  return (
    <div
      style={style}
      {...getRef()}
      className={cx(styles.viewComponent, dndClassName, className, {
        [styles.padding]: applyPadding,
        [styles.selected]: selectedViewComponentId === componentConfig.sectionId,
      })}
      onClick={handleSelect}
    >
      <FontIcon size={SIZES.S} className={styles.btnDnd}>
        icon-drag-and-drop
      </FontIcon>
      {children}
      {isEditEnabled && (
        <FontIcon className={styles.btnEdit} size={SIZES.S} onClick={handleEdit}>
          icon-edit
        </FontIcon>
      )}
      {isDeleteEnabled && (
        <FontIcon className={styles.btnCross} size={SIZES.S} onClick={handleDelete}>
          icon-trash
        </FontIcon>
      )}
    </div>
  );
};

ViewComponent.propTypes = {
  isDeleteEnabled: PropTypes.bool,
  isEditEnabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  componentConfig: PropTypes.object.isRequired,
  children: PropTypes.any,
};

ViewComponent.defaultProps = {
  isDeleteEnabled: true,
  isEditEnabled: false,
  className: '',
  style: {},
  children: undefined,
};

export default React.memo(ViewComponent);
