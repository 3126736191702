import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import TemplateServices from '../services/template.services';

const createTemplate = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await TemplateServices.createTemplate(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create template, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getTemplateByName = async (templateName) => {
  try {
    const response = await TemplateServices.getTemplateByName(templateName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch template, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateTemplate = async (templateName, payload = EMPTY_OBJECT) => {
  try {
    const response = await TemplateServices.updateTemplate(templateName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update template, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const deleteTemplate = async (templateName) => {
  try {
    const response = await TemplateServices.deleteTemplate(templateName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to delete template, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const searchTemplate = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await TemplateServices.searchTemplate(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to search template, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export { createTemplate, getTemplateByName, updateTemplate, deleteTemplate, searchTemplate };
