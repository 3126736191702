import { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';

const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showHeader: false,
  showSearch: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const PERMISSION_USER_STATUS = {
  ACTIVE: true,
  INACTIVE: false,
};

const PERMISSION_USER_STATUS_LABEL_MAP = {
  [PERMISSION_USER_STATUS.ACTIVE]: __('Active'),
  [PERMISSION_USER_STATUS.INACTIVE]: __('Inactive'),
};

const PERMISSION_USER_STATUS_COLOR_MAP = {
  [PERMISSION_USER_STATUS.ACTIVE]: COLOR_MAP.GREEN,
  [PERMISSION_USER_STATUS.INACTIVE]: COLOR_MAP.RED,
};

export { TABLE_MANAGER_PROPS, PERMISSION_USER_STATUS_COLOR_MAP, PERMISSION_USER_STATUS_LABEL_MAP };
