import ROUTES from '../../pages/devPlatform/constants/routes';
import PAGE_IDS from '../../pages/devPlatform/constants/PageIds.constants';

const FIELDS_NAVIGATION_DEFAULT_DATA = [{ label: __('Entity'), goTo: ROUTES.ENTITY_LIST_ROUTE, key: '1' }];

const VALIDATION_RULE_NAVIGATION_DEFAULT_DATA = [...FIELDS_NAVIGATION_DEFAULT_DATA];

const VIEW_BUILDER_NAVIGATION_DEFAULT_DATA = [...FIELDS_NAVIGATION_DEFAULT_DATA];

const ACTION_BUILDER_NAVIGATION_DEFAULT_DATA = [...FIELDS_NAVIGATION_DEFAULT_DATA];
const RECORD_TYPE_NAVIGATION_DEFAULT_DATA = [...FIELDS_NAVIGATION_DEFAULT_DATA];

const VISIBLE_PATHS = [PAGE_IDS.FIELDS, PAGE_IDS.VIEW_BUILDER, PAGE_IDS.VALIDATION_RULE_BUILDER, PAGE_IDS.ACTION_BUILDER, PAGE_IDS.RECORD_TYPE];

export {
  FIELDS_NAVIGATION_DEFAULT_DATA,
  VALIDATION_RULE_NAVIGATION_DEFAULT_DATA,
  VIEW_BUILDER_NAVIGATION_DEFAULT_DATA,
  ACTION_BUILDER_NAVIGATION_DEFAULT_DATA,
  RECORD_TYPE_NAVIGATION_DEFAULT_DATA,
  VISIBLE_PATHS,
};
