import React, { useMemo } from 'react';
import compose from 'recompose/compose';
import renameProps from 'recompose/renameProps';
import PropTypes from 'prop-types';

import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import RELATIONSHIP_DETAILS_FORM_ACTION_HANDLERS from './helpers/relationshipDetailsForm.actionHandlers';
import getFields from './helpers/relationshipDetailsForm.fields';

import RELATIONSHIP_DETAILS_FORM_SECTIONS from './constants/relationshipDetailsForm.sections';
import FIELDS_FORM_FIELD_IDS from '../../constants/fieldsForm.fieldIds';

const RelationshipDetailsForm = ({
  isInitialStateLoaded,
  isDisabled,
  isGroupingAllowed,
  entity,
  values,
  error,
  renderOptions,
  lookupDisplayFieldOptions,
  lookupFieldOptions,
  onAction,
}) => {
  const fields = useMemo(
    () => getFields(entity, values, isGroupingAllowed, lookupDisplayFieldOptions, lookupFieldOptions, renderOptions),
    [entity, values, isGroupingAllowed, lookupDisplayFieldOptions, lookupFieldOptions, renderOptions],
  );

  return (
    <FormWithSubmission
      className="full-width"
      isFetching={!isInitialStateLoaded}
      isDisabled={isDisabled}
      contextId={FIELDS_FORM_FIELD_IDS.RELATIONSHIP_DETAILS_FORM}
      errors={error}
      values={values}
      fields={fields}
      sections={RELATIONSHIP_DETAILS_FORM_SECTIONS}
      onAction={onAction}
    />
  );
};

RelationshipDetailsForm.propTypes = {
  isInitialStateLoaded: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isGroupingAllowed: PropTypes.bool,
  entity: PropTypes.object,
  values: PropTypes.object,
  error: PropTypes.object,
  renderOptions: PropTypes.object,
  lookupDisplayFieldOptions: PropTypes.array,
  lookupFieldOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

RelationshipDetailsForm.defaultProps = {
  isInitialStateLoaded: false,
  isDisabled: false,
  isGroupingAllowed: true,
  entity: EMPTY_OBJECT,
  values: EMPTY_OBJECT,
  error: undefined,
  renderOptions: EMPTY_OBJECT,
  lookupDisplayFieldOptions: EMPTY_ARRAY,
  lookupFieldOptions: EMPTY_ARRAY,
};

export default compose(
  renameProps({ onAction: 'fieldsFormOnAction', value: 'fieldsFormRelationshipDetailsValue' }),
  withActions(EMPTY_OBJECT, RELATIONSHIP_DETAILS_FORM_ACTION_HANDLERS),
)(RelationshipDetailsForm);
