import { useEffect, useState } from 'react';

function useLinkSelectionPopupVisibility(selectedLinkNode) {
  const [showPopover, setShowPopover] = useState();

  useEffect(() => {
    if (!selectedLinkNode) {
      setShowPopover(false);
    }
  }, [selectedLinkNode]);

  return {
    showPopover,
    setShowPopover,
  };
}

export default useLinkSelectionPopupVisibility;
