import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _replace from 'lodash/replace';
import _split from 'lodash/split';
import _includes from 'lodash/includes';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import styles from '../../asyncMentions.module.scss';

const CommentContentRenderer = ({ content }) => {
  const getContentForCommentInPreviewMode = useCallback(() => {
    try {
      // First filtering out the entire string by replacing the mentioned display name with @displayname
      // All the mentioned display name are i this format : @(abc xyz)[ded-4cfv-cvf-cwf-4], where "abc xyz" is the name of the person and
      // "ded-4cfv-cvf-cwf-4" is the id of the person. So in the below function replacing the substring "@(abc xyz)[ded-4cfv-cvf-cwf-4]"
      // with @abc-xyz, everywhere for all the mentions occurred in the content.

      const filteredContent = _replace(content, /@\[((.*?))\]<@(.*?)>/g, (mentionString) => {
        const regexExpression = /\[(.*?)\]/g;
        const display = regexExpression.exec(mentionString);
        if (display) {
          return ` ${_replace(`@${display[1]}`, /\s+/g, '-')}`;
        }
        return '';
      });

      // Now, since we have to pass the mentioned names into the html element so that we can apply text color to them,
      // so the logic to achieve this is by first splitting the entire content using space separator into an array of string
      // Now we can one by one check if any string in array has "@", if true then we will pass this string into "span" with some styling
      // if false, then we will use the string only. Also we have to manually add spaces after each string as they were removed.

      const contentArray = _split(filteredContent, ' ');

      return _map(contentArray, (displayString) => {
        if (_includes(displayString, '@')) {
          return <span className={styles.mentionedText}>{`${_replace(displayString, /-/g, ' ')} `}</span>;
        }
        return `${displayString} `;
      });
    } catch (error) {
      return content;
    }
  }, [content]);

  return <div className={styles.commentTextInPreviewMode}>{getContentForCommentInPreviewMode(content)}</div>;
};

CommentContentRenderer.propTypes = {
  content: PropTypes.string,
};

CommentContentRenderer.defaultProps = {
  content: EMPTY_STRING,
};

export default CommentContentRenderer;
