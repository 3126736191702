import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Content from '@tekion/tekion-components/atoms/Content';

import ApplicationPageContent from '../applicationPageContent/ApplicationPageContent';
import { TAB_IDS } from '../../constants/applicationBuilder.constants';

import styles from './applicationTabContent.module.scss';

const ApplicationTabContent = ({ tabInfo, applicationContext }) => {
  const renderWithoutTabInfo = useCallback(
    () => (
      <>
        <Content colorVariant={Content.COLOR_VARIANTS.GREY} className={styles.contentText}>
          {__('No Page Added')}
        </Content>
        <Content colorVariant={Content.COLOR_VARIANTS.GREY} className={styles.contentText}>
          {__('Add Page from right side properties')}
        </Content>
      </>
    ),
    [],
  );

  const renderWithTabInfo = useCallback(
    () => (
      <div className="full-width full-height">
        <ApplicationPageContent tabContent={tabInfo} applicationContext={applicationContext} />
      </div>
    ),
    [tabInfo, applicationContext],
  );

  return _isEmpty(_get(tabInfo, 'pageName')) && !(_get(tabInfo, 'id') === TAB_IDS.APPROVAL) ? renderWithoutTabInfo() : renderWithTabInfo();
};

ApplicationTabContent.propTypes = {
  tabInfo: PropTypes.object,
  applicationContext: PropTypes.object,
};

ApplicationTabContent.defaultProps = {
  tabInfo: EMPTY_OBJECT,
  applicationContext: EMPTY_OBJECT,
};
export default ApplicationTabContent;
