import React from 'react';

import getDateWithSuffix from '@tekion/tekion-base/utils/getDateWithSuffix';
import { phoneNumberFormatter } from '@tekion/tekion-base/utils/phoneUtils';
import { IMAGE_TYPES } from '@tekion/tekion-base/constants/mediaMimeTypes';
import Label from '@tekion/tekion-components/atoms/Label';

import ImageThumbnails from '../../mediaUploader/components/ImageThumbnails/ImageThumbnails';
import ATTRIBUTE_TYPES from '../constants/auditLogsDrawer.attributeTypes';
import styles from '../auditLogsDrawer.module.scss';

const getBooleanFormatter = (value) => {
  if (value) {
    return value === 'true' ? __('Enabled') : __('Disabled');
  }
  return value;
};

const getDayFormatter = (day) => (day ? getDateWithSuffix(day) : day);

const getFormatterForImageAttribute = (imageUrl) => {
  const mediaList = [{ contentType: IMAGE_TYPES.ANY_IMAGE, url: imageUrl }];

  return <ImageThumbnails viewOnly isGalleryControlsEnabled={false} displayCount={1} className={styles.imageThumbnail} mediaList={mediaList} />;
};

const getFormatterForHyperlink = (url) => (
  <Label highlight>
    <a href={url} target="_blank" rel="noreferrer">
      {__('Open link')}
    </a>
  </Label>
);

const AUDIT_LOGS_DRAWER_FORMATTERS = {
  [ATTRIBUTE_TYPES.BOOLEAN]: getBooleanFormatter,
  [ATTRIBUTE_TYPES.DAY]: getDayFormatter,
  [ATTRIBUTE_TYPES.PHONE_NUMBER]: phoneNumberFormatter,
  [ATTRIBUTE_TYPES.IMAGE]: getFormatterForImageAttribute,
  [ATTRIBUTE_TYPES.HYPERLINK]: getFormatterForHyperlink,
};

export default AUDIT_LOGS_DRAWER_FORMATTERS;
