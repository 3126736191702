exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".approvalSettingCard_container__urfpPb63jL{display:flex;flex-wrap:wrap}[dir] .approvalSettingCard_container__urfpPb63jL{margin:2.4rem 2.4rem 0;border:.1rem solid #dad3d3}.approvalSettingCard_item__sqKdQ52wBx{display:flex;flex-direction:row;flex:0 1 24%;height:2rem}[dir] .approvalSettingCard_item__sqKdQ52wBx{margin-bottom:1%;padding-top:.8rem}[dir=ltr] .approvalSettingCard_item__sqKdQ52wBx{padding-left:.8rem}[dir=rtl] .approvalSettingCard_item__sqKdQ52wBx{padding-right:.8rem}[dir=ltr] .approvalSettingCard_subItem__5Yn4ss8aeU{margin-right:.8rem}[dir=rtl] .approvalSettingCard_subItem__5Yn4ss8aeU{margin-left:.8rem}.approvalSettingCard_iconItem__pQd6P93vqG{display:flex;flex-direction:row-reverse;flex:0 1 24%;height:2rem}[dir] .approvalSettingCard_iconItem__pQd6P93vqG{margin-bottom:1%;padding-top:.8rem}[dir=ltr] .approvalSettingCard_iconItem__pQd6P93vqG{padding-right:.8rem}[dir=rtl] .approvalSettingCard_iconItem__pQd6P93vqG{padding-left:.8rem}", ""]);

// Exports
exports.locals = {
	"container": "approvalSettingCard_container__urfpPb63jL",
	"item": "approvalSettingCard_item__sqKdQ52wBx",
	"subItem": "approvalSettingCard_subItem__5Yn4ss8aeU",
	"iconItem": "approvalSettingCard_iconItem__pQd6P93vqG"
};