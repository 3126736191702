import React, { useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';

// Constants
import { DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { TABLE_MANAGER_PROPS, HEADER_PROPS } from './constants/entityList.general';
import withSize from '../../../../connectors/withSize';

// Helpers
import { COLUMN_CONFIG } from './constants/entityList.columns';
import ACTION_HANDLERS from './entityList.actionHandlers';
import { createTableProps } from './helpers/entityList.helpers';
import { TABLE_CONSTANTS } from '../../../../constants/general.constants';

const EntityList = ({ loading, searchText, entities, totalNumberOfEntries, nextPageToken, pageSize, currentPage, contentHeight, onAction }) => {
  const tableProps = useMemo(
    () => createTableProps(onAction, loading, currentPage, pageSize, totalNumberOfEntries),
    [onAction, loading, currentPage, pageSize, totalNumberOfEntries],
  );

  return (
    <Page style={{ height: contentHeight }}>
      <TableManager
        headerProps={HEADER_PROPS}
        tableProps={tableProps}
        tableManagerProps={TABLE_MANAGER_PROPS}
        columns={COLUMN_CONFIG}
        data={entities}
        onAction={onAction}
        pageSize={pageSize}
        currentPage={currentPage}
        searchText={searchText}
        tokenPagination
        nextPageToken={nextPageToken}
      />
    </Page>
  );
};

EntityList.propTypes = {
  entities: PropTypes.array,
  searchText: PropTypes.string,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  contentHeight: PropTypes.number.isRequired,
  nextPageToken: PropTypes.string,
  totalNumberOfEntries: PropTypes.number,
  loading: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
};

EntityList.defaultProps = {
  searchText: EMPTY_STRING,
  entities: EMPTY_ARRAY,
  pageSize: DEFAULT_PAGE_INFO.rows,
  currentPage: DEFAULT_PAGE_INFO.start,
  nextPageToken: EMPTY_STRING,
  totalNumberOfEntries: TABLE_CONSTANTS.TOTAL_NUMBER_OF_ENTRIES,
  loading: false,
};

export default compose(withSize({ hasPageHeader: 0, hasPageFooter: 0 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(EntityList);
