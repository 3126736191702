import { useSelected, useFocused } from 'slate-react';

function useElementSelection() {
  const selected = useSelected();
  const focused = useFocused();
  const isElementSelected = focused && selected;

  return { isElementSelected };
}

export default useElementSelection;
