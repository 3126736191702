import _keyBy from 'lodash/keyBy';

const ALL_ACCESS_ACCESS_FILTER = {
  id: 'ALL',
  name: __('All'),
  iconName: 'icon-teams',
};

const READ_ACCESS_TYPE_FILTER = {
  id: 'READ',
  name: __('Read'),
  iconName: 'icon-team',
};

const READ_WRITE_ACCESS_TYPE_FILTER = {
  id: 'READ_WRITE',
  name: __('Read Write'),
  iconName: 'icon-team',
};

const ACCESS_DENIED_ACCESS_TYPE_FILTER = {
  id: 'ACCESS_DENIED',
  name: __('Access Denied'),
  iconName: 'icon-team',
};

const ACCESS_TYPE_FILTERS = [ALL_ACCESS_ACCESS_FILTER, READ_ACCESS_TYPE_FILTER, READ_WRITE_ACCESS_TYPE_FILTER, ACCESS_DENIED_ACCESS_TYPE_FILTER];

const ACCESS_TYPE_FILTER_BY_ID = _keyBy(ACCESS_TYPE_FILTERS, 'id');

export {
  ACCESS_TYPE_FILTERS,
  ACCESS_TYPE_FILTER_BY_ID,
  ALL_ACCESS_ACCESS_FILTER,
  READ_ACCESS_TYPE_FILTER,
  READ_WRITE_ACCESS_TYPE_FILTER,
  ACCESS_DENIED_ACCESS_TYPE_FILTER,
};
