import _unset from 'lodash/unset';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { modifyPageConfigFromApi } from '../helpers/visualBuilder.helpers';

import visualBuilder from '../services/visualBuilderBuilder.services';

const fetchPageConfigurationById = async (pageId, includeWidgets = true) => {
  try {
    const responseData = await visualBuilder.fetchPageConfigurationById(pageId);
    let pageConfiguration = getDataFromResponse(responseData);
    if (includeWidgets) {
      [pageConfiguration] = await modifyPageConfigFromApi(pageConfiguration);
    }
    return pageConfiguration;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch page, please try again later.')));
    return false;
  }
};

const fetchPageConfigurationByName = async (pageName, includeWidgets = true) => {
  try {
    const responseData = await visualBuilder.fetchPageConfigurationByName(pageName);
    let pageConfiguration = getDataFromResponse(responseData);
    if (includeWidgets) {
      [pageConfiguration] = await modifyPageConfigFromApi(pageConfiguration);
    }

    return pageConfiguration;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch page, please try again later.')));
    return false;
  }
};

const searchPageConfigurations = async (payload = EMPTY_OBJECT, includeWidgets = false) => {
  try {
    const receivedData = await visualBuilder.searchPageConfigurations(payload);
    let { hits: pageConfigs } = getDataFromResponse(receivedData);
    if (includeWidgets) {
      const promises = _map(pageConfigs, modifyPageConfigFromApi);
      const pageConfigsWithWidgets = await Promise.all(promises);
      pageConfigs = _map(pageConfigsWithWidgets, ([pageConfig]) => pageConfig);
    }
    return { hits: pageConfigs };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch pages, please try again later.')));
    return false;
  }
};

const savePageConfiguration = async (pageConfiguration) => {
  try {
    const responseData = await visualBuilder.savePageConfiguration(pageConfiguration);
    const savedPageConfiguration = getDataFromResponse(responseData);

    return savedPageConfiguration;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to save page, please try again later.')));
    return false;
  }
};

const updatePageConfiguration = async (pageId, pageConfiguration) => {
  try {
    const responseData = await visualBuilder.updatePageConfiguration(pageId, pageConfiguration);
    const savedPageConfiguration = getDataFromResponse(responseData);

    return savedPageConfiguration;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update page, please try again later.')));
    return false;
  }
};

const searchPageConfigurationsCMS = async (payload) => {
  try {
    const response = await visualBuilder.searchPagesCMS(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch pages, please try again later')));
    return EMPTY_OBJECT;
  }
};

const saveWidgetConfiguration = async (payload) => {
  try {
    const responseData = await visualBuilder.saveWidgetConfiguration(payload);
    const widgetConfiguration = getDataFromResponse(responseData);
    const widget = tget(widgetConfiguration, 'hits', EMPTY_ARRAY);
    return widget;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to add widgets, please try again later.')));
    return false;
  }
};

const updateWidgetConfiguration = async (widgetName, payload) => {
  try {
    const responseData = await visualBuilder.updateWidgetConfiguration(widgetName, payload);
    const widgetConfiguration = getDataFromResponse(responseData);
    const widget = tget(widgetConfiguration, 'hits', EMPTY_ARRAY);
    return widget;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update widgets, please try again later.')));
    return false;
  }
};

const deleteWidgetConfiguration = async (widgetName) => {
  try {
    const responseData = await visualBuilder.deleteWidgetConfiguration(widgetName);

    return responseData;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to delete widget, please try again later.')));
    return false;
  }
};

const searchWidgetConfiguration = async (payload) => {
  try {
    const responseData = await visualBuilder.searchWidgetConfiguration(payload);
    const widgetConfiguration = getDataFromResponse(responseData);
    const widgets = tget(widgetConfiguration, 'hits', EMPTY_ARRAY);
    return widgets;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch widgets, please try again later.')));
    return false;
  }
};

const savePageAndWidgetConfiguration = async (pageConfiguration) => {
  const widgets = tget(pageConfiguration, 'widgets', EMPTY_ARRAY);
  _unset(pageConfiguration, 'widgets');

  const widgetPromises = [];

  _forEach(widgets, (widget) => {
    widgetPromises.push(saveWidgetConfiguration(widget));
  });

  await Promise.all(widgetPromises);

  _unset(pageConfiguration, 'containers');

  const response = await savePageConfiguration(pageConfiguration);
  return response;
};

export {
  fetchPageConfigurationById,
  fetchPageConfigurationByName,
  searchPageConfigurations,
  savePageConfiguration,
  updatePageConfiguration,
  saveWidgetConfiguration,
  searchWidgetConfiguration,
  updateWidgetConfiguration,
  savePageAndWidgetConfiguration,
  deleteWidgetConfiguration,
  searchPageConfigurationsCMS,
};
