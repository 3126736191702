import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import RadioRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio/Radio';
import SwitchRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch/Switch';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import { NAVIGATION_TYPE_OPTIONS } from './navigationComponent.constants';
import { FIELD_IDS } from '../../constants/navigationProperties.constants';

import styles from './navigationComponent.module.scss';

const NavigationComponent = ({ navigationPropertiesValues, error, ...restProps }) => {
  const disabled = useMemo(() => !_get(navigationPropertiesValues, FIELD_IDS.NAVIGATION, false), [navigationPropertiesValues]);

  return (
    <div className={styles.navigationContainer}>
      <div className={styles.navigationHeader}>
        <div>{__('Navigation')}</div>
        <SwitchRenderer
          {...restProps}
          id={FIELD_IDS.NAVIGATION}
          fieldClassName={styles.navigationSwitch}
          value={_get(navigationPropertiesValues, FIELD_IDS.NAVIGATION, false)}
        />
      </div>
      <RadioRenderer
        {...restProps}
        required={!disabled}
        disabled={disabled}
        id={FIELD_IDS.NAVIGATION_TYPE}
        value={_get(navigationPropertiesValues, FIELD_IDS.NAVIGATION_TYPE)}
        label={__('Navigation Type')}
        validators={[isRequiredRule]}
        radios={NAVIGATION_TYPE_OPTIONS}
      />
      <SwitchRenderer
        {...restProps}
        disabled={disabled}
        id={FIELD_IDS.APPROVAL_INTEGRATION}
        label={__('Approval Integration')}
        value={_get(navigationPropertiesValues, FIELD_IDS.APPROVAL_INTEGRATION)}
      />
    </div>
  );
};

NavigationComponent.propTypes = {
  navigationPropertiesValues: PropTypes.string.isRequired,
  error: PropTypes.string,
};

NavigationComponent.defaultProps = {
  error: EMPTY_STRING,
};

export default React.memo(NavigationComponent);
