import _get from 'lodash/get';

import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import IconRenderer from '../../../../../../../atoms/IconRenderer';
import { NAVIGATION_TYPES } from '../constants/appList.general.constants';

const IconCellRenderer = makeCellRenderer(IconRenderer);

const COLUMNS_IDS = {
  ICON: 'icon',
  API_NAME: 'apiName',
  DISPLAY_NAME: 'displayName',
  NAME: 'name',
  DESCRIPTION: 'description',
  NAVIGATION_TYPE: 'navigationType',
};

const getNavigationType = (rowInfo) => {
  const navigationType = _get(rowInfo, 'navigationInfo.navigationType');
  const modifyLabel = _get(NAVIGATION_TYPES, navigationType);
  return _get(modifyLabel, 'label');
};

const COLUMN_CONFIG = [
  {
    Header: __('Icon'),
    accessor: COLUMNS_IDS.ICON,
    id: COLUMNS_IDS.ICON,
    Cell: IconCellRenderer,
  },
  {
    Header: __('Display Name'),
    accessor: COLUMNS_IDS.DISPLAY_NAME,
    id: COLUMNS_IDS.DISPLAY_NAME,
    Cell: TextRenderer,
    sortable: true,
  },
  {
    Header: __('Name'),
    accessor: COLUMNS_IDS.NAME,
    id: COLUMNS_IDS.NAME,
    Cell: TextRenderer,
    sortable: true,
  },
  {
    Header: __('Description'),
    accessor: COLUMNS_IDS.DESCRIPTION,
    id: COLUMNS_IDS.DESCRIPTION,
    Cell: TextRenderer,
  },
  {
    Header: __('Navigation Type'),
    accessor: getNavigationType,
    id: COLUMNS_IDS.NAVIGATION_TYPE,
    Cell: TextRenderer,
  },
];

export { COLUMN_CONFIG, COLUMNS_IDS };
