import { defaultMemoize } from 'reselect';

import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import _set from 'lodash/set';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _forEach from 'lodash/forEach';
import _includes from 'lodash/includes';
import _map from 'lodash/map';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';
import { NO_DATA, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import AsyncPaginatedSelect from '../../../../../../organisms/asyncPaginatedSelect/AsyncPaginatedSelect';

import { searchViewConfigurations } from '../../../../../../actions/viewBuilderPage.actions';

import { FORM_MODES } from '../../../../../../constants/general.constants';
import { VIEW_FORM_FIELDS } from '../constants/createEntityViewForm.fields';
import { FIELD_IDS, SELECT_INPUT_FIELDS, ACTION_TYPES } from '../constants/createEntityView.constants';
import { ENTITY_VIEW_FORM_FIELD_IDS } from '../components/entityViewForm/constants/entityViewForm.general.constants';
import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../../constants/viewBuilder.constants';

import entityReader from '../../../../../../readers/entity.reader';

const ROWS = 20;

const getListViewNamePayload = defaultMemoize((entityName, viewType) => ({ nextPageToken, searchText }) => {
  const filters = [
    { field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME, filterType: OPERATORS.IN, values: [entityName] },
    { field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE, filterType: OPERATORS.IN, values: [viewType] },
  ];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: VIEW_CONFIGURATION_FIELD_IDS.NAME,
      values: [searchText],
      filterType: OPERATORS.TEXT_STARTS_WITH,
    });
  }

  return {
    rows: ROWS,
    nextPageToken,
    filters,
  };
});

const handleSearchViewConfigurations = (id, onAction) => async (payload) => {
  const response = await searchViewConfigurations(payload);
  const value = tget(response, 'hits', EMPTY_ARRAY);
  onAction({ type: ACTION_TYPES.ON_ASYNC_LOADED_OPTIONS, payload: { id, value } });
  return response;
};

const getListViewOptions = (viewConfigs) =>
  _map(viewConfigs, (viewConfig) => ({
    label: tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.DISPLAY_NAME, tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME, NO_DATA)),
    value: tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME),
  }));

const getFormFields = defaultMemoize((formValues, entity, viewType, formMode, recordTypeOptions, onAction) => {
  const viewRecordTypeMetadataList = _get(formValues, [FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST], []);
  const entityName = entityReader.name(entity);

  return {
    ...VIEW_FORM_FIELDS,
    [FIELD_IDS.NAME]: addToRenderOptions(VIEW_FORM_FIELDS[FIELD_IDS.NAME], [
      { path: 'disabled', value: formMode !== FORM_MODES.CREATE },
      { path: 'required', value: formMode === FORM_MODES.CREATE },
    ]),
    [FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST]: addToRenderOptions(VIEW_FORM_FIELDS[FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST], [
      { path: 'entityName', value: entityName },
      { path: 'viewType', value: viewType },
      { path: 'fieldKey', value: viewType },
      { path: 'recordTypeOptions', value: recordTypeOptions },
      { path: 'viewRecordTypeMetadataList', value: viewRecordTypeMetadataList },
    ]),
    [FIELD_IDS.LIST_VIEW_NAME]: {
      renderer: AsyncPaginatedSelect,
      renderOptions: {
        required: true,
        label: __('View'),
        serviceHandler: handleSearchViewConfigurations(FIELD_IDS.LIST_VIEW_NAME, onAction),
        getOptions: getListViewOptions,
        getPayload: getListViewNamePayload(entityName, viewType),
      },
    },
  };
});

const validateViewRecordTypeMetadataList = (viewRecordTypeMetadataList) => {
  if (_size(viewRecordTypeMetadataList) === 0) {
    return { isValid: false, message: __('At least One Record Type to View Mapping is required') };
  }

  let isValid = true;

  _forEach(viewRecordTypeMetadataList, (viewRecordTypeMetadata) => {
    if (
      _isEmpty(viewRecordTypeMetadata) ||
      _isEmpty(_get(viewRecordTypeMetadata, ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME)) ||
      _isEmpty(_get(viewRecordTypeMetadata, ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME))
    ) {
      isValid = false;
    }
  });

  return isValid ? { isValid } : { isValid, message: __('All Fields for Record Type to Views Mapping are required') };
};

const getFlattenedFormValues = (formValues) => {
  _forEach(formValues, (value, id) => {
    let newValue = value;
    if (_includes(SELECT_INPUT_FIELDS, id)) {
      newValue = getArraySafeValue(value);
    } else if (_isObject(value)) {
      newValue = getFlattenedFormValues(value);
    }
    _set(formValues, [id], newValue);
  });

  return formValues;
};

const getFormattedFormValues = (formValues) => {
  _forEach(formValues, (value, id) => {
    let newValue = value;
    if (_includes(SELECT_INPUT_FIELDS, id)) {
      newValue = _castArray(value);
    } else if (_isObject(value)) {
      newValue = getFormattedFormValues(value);
    }
    _set(formValues, [id], newValue);
  });

  return formValues;
};

const getPayload = ({ entityName, viewType }) => {
  let payload = {};

  if (!_isEmpty(viewType)) {
    payload = {
      ...payload,
      filters: [
        {
          field: 'entityName',
          values: [entityName],
          filterType: OPERATORS.IN,
        },
        {
          field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
          values: [viewType],
          filterType: OPERATORS.IN,
        },
      ],
    };
  }

  return payload;
};

export { getFormFields, getPayload, getFlattenedFormValues, getFormattedFormValues, validateViewRecordTypeMetadataList };
