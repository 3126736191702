import { defaultMemoize } from 'reselect';

import _get from 'lodash/get';
import _head from 'lodash/head';
import _reject from 'lodash/reject';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _isUndefined from 'lodash/isUndefined';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import FIELD_IDS from '../constants/fieldsForm.fieldIds';
import { FORM_FIELDS } from '../constants/fieldsForm.fields';
import { nameValidator } from '../../../../../utils/formValidators';
import {
  DATA_TYPES,
  FIELD_TYPES,
  FIELD_TYPE_OPTIONS,
  FIELD_TYPE_OPTIONS_NUMBER,
  DATA_TYPE_OPTIONS,
  DATA_TYPE_OF_TARGET_OPTION,
  SORT_OPTIONS,
  ROLL_UP_FUNCTIONS,
  FIELD_TYPE_OPTIONS_TEXT,
} from '../constants/fieldsForm.constants';
import { getPropertyFieldsWithConstraints } from './fieldsForm.general.helpers';
import {
  getChildEntities,
  getComplexEntityFilters,
  getControllingFieldOptions,
  getLookupEntityFilters,
  getValuesOptions,
} from './fieldsForm.helpers';
import ConditionBuilder from '../../../../../organisms/conditionBuilder/ConditionBuilder';
import CONDITION_BUILDER_MODES from '../../../../../organisms/conditionBuilder/constants/conditionBuilder.modes';
import { CONDITION_BUILDER_TYPES } from '../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import { NAMESPACES, FORM_MODES } from '../../../../../constants/general.constants';

const getConditionFields = defaultMemoize(
  (mapOfVariableToEntityNameForEntryConditionChild, conditionBuilderFieldDefinitionObjectChild, entity = EMPTY_OBJECT) => {
    const { mapOfVariableToEntityNameForEntryConditionParent = EMPTY_OBJECT, conditionBuilderFieldDefinitionObjectParent = EMPTY_OBJECT } = entity;
    return {
      [FIELD_IDS.CHILD_FILTER_CONDITION]: {
        id: FIELD_IDS.CHILD_FILTER_CONDITION,
        renderer: ConditionBuilder,
        renderOptions: {
          validators: [isRequiredRule],
          required: true,
          containerClassname: fieldLayoutStyles.fieldC,
          mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
          conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
          childProps: {
            [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
              label: '',
              childProps: {
                [CONDITION_FIELD_IDS.FIELD]: {
                  label: __('Field'),
                  mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryConditionChild,
                  conditionBuilderFieldDefinitionObject: conditionBuilderFieldDefinitionObjectChild,
                  isVariablePrefixNeeded: false,
                  variableNameForPrefixNotNeeded: NAMESPACES.CURRENT_RECORD,
                },
                [CONDITION_FIELD_IDS.OPERATOR]: {
                  label: __('Operator'),
                  mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryConditionChild,
                  conditionBuilderFieldDefinitionObject: conditionBuilderFieldDefinitionObjectChild,
                  isVariablePrefixNeeded: false,
                  variableNameForPrefixNotNeeded: NAMESPACES.CURRENT_RECORD,
                },
                [CONDITION_FIELD_IDS.VALUES]: {
                  label: __('Value'),
                  mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryConditionChild,
                  conditionBuilderFieldDefinitionObject: conditionBuilderFieldDefinitionObjectChild,
                  isVariablePrefixNeeded: false,
                  variableNameForPrefixNotNeeded: NAMESPACES.CURRENT_RECORD,
                },
              },
            },
          },
        },
      },

      [FIELD_IDS.UPDATE_CONDITION]: {
        id: FIELD_IDS.UPDATE_CONDITION,
        renderer: ConditionBuilder,
        renderOptions: {
          validators: [isRequiredRule],
          required: true,
          containerClassname: fieldLayoutStyles.fieldC,
          mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
          conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
          childProps: {
            [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
              label: '',
              childProps: {
                [CONDITION_FIELD_IDS.FIELD]: {
                  label: __('Field'),
                  mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryConditionParent,
                  conditionBuilderFieldDefinitionObject: conditionBuilderFieldDefinitionObjectParent,
                  isVariablePrefixNeeded: false,
                  variableNameForPrefixNotNeeded: '$record',
                },
                [CONDITION_FIELD_IDS.OPERATOR]: {
                  label: __('Operator'),
                  mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryConditionParent,
                  conditionBuilderFieldDefinitionObject: conditionBuilderFieldDefinitionObjectParent,
                  isVariablePrefixNeeded: false,
                  variableNameForPrefixNotNeeded: '$record',
                },
                [CONDITION_FIELD_IDS.VALUES]: {
                  label: __('Value'),
                  mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryConditionParent,
                  conditionBuilderFieldDefinitionObject: conditionBuilderFieldDefinitionObjectParent,
                  isVariablePrefixNeeded: false,
                  variableNameForPrefixNotNeeded: '$record',
                },
              },
            },
          },
        },
      },
    };
  },
);

const getFormFields = defaultMemoize(
  ({ options, formValues, formMode, entity, modalData, isModalVisible, selectedFields, childEntityDefinitions, selectedOptions, regexOptions }) => {
    const dataType = getArraySafeValue(_get(formValues, FIELD_IDS.DATA_TYPE));
    const fieldType = getArraySafeValue(_get(formValues, FIELD_IDS.FIELD_TYPE));
    let childEntitiesOptions = [];
    let childRelationFieldOptions = [];
    let childFieldDefinitions = [];
    let mapOfVariableToEntityNameForEntryConditionChild = [];
    let conditionBuilderFieldDefinitionObjectChild = [];
    let disableFieldChild = true;

    const detailsFields = getPropertyFieldsWithConstraints({
      formValues,
      formMode,
      entity,
      dataType,
    });

    let disableDataTypeField = false;
    let dataTypeOptions = DATA_TYPE_OPTIONS;
    let fieldTypeOptions = FIELD_TYPE_OPTIONS;

    if (dataType === DATA_TYPES.TEXT) {
      fieldTypeOptions = _reject(fieldTypeOptions, (option) => _get(option, 'value', '') === FIELD_TYPES.RANGE);
      fieldTypeOptions = [...fieldTypeOptions, ...FIELD_TYPE_OPTIONS_TEXT];
    } else if (dataType === DATA_TYPES.DATE) {
      fieldTypeOptions = _reject(fieldTypeOptions, (option) => {
        const value = _get(option, 'value', '');
        if (value === FIELD_TYPES.SELECT || value === FIELD_TYPES.RELATIONSHIP) return true;
        return false;
      });
    } else if (dataType === DATA_TYPES.DATE_TIME) {
      fieldTypeOptions = _reject(fieldTypeOptions, (option) => {
        const value = _get(option, 'value', '');
        if (value === FIELD_TYPES.SELECT || value === FIELD_TYPES.RELATIONSHIP || value === FIELD_TYPES.RANGE) return true;
        return false;
      });
    } else if (dataType === DATA_TYPES.NUMBER) {
      const childEntity = getChildEntities(entity);
      childEntitiesOptions = _get(childEntity, 'childEntitiesOptions');
      childRelationFieldOptions = _get(childEntity, 'childRelationFieldOptionsByEntity');
      const entityName = _head(_get(formValues, FIELD_IDS.CHILD_ENTITY_NAME));
      if (!_isUndefined(entityName)) {
        childFieldDefinitions = _get(childEntityDefinitions, `${entityName}.childFieldDefinitions`);
        mapOfVariableToEntityNameForEntryConditionChild = _get(
          childEntityDefinitions,
          `${entityName}.mapOfVariableToEntityNameForEntryConditionChild`,
          EMPTY_OBJECT,
        );
        conditionBuilderFieldDefinitionObjectChild = _get(
          childEntityDefinitions,
          `${entityName}.conditionBuilderFieldDefinitionObjectChild`,
          EMPTY_OBJECT,
        );
        if (!_isEmpty(_get(formValues, FIELD_IDS.ROLL_UP_FUNCTION))) {
          disableFieldChild = false;
          if (_head(_get(formValues, FIELD_IDS.ROLL_UP_FUNCTION)) === ROLL_UP_FUNCTIONS.COUNT) {
            childFieldDefinitions = _filter(childFieldDefinitions, (field) => _get(field, 'name') === 'id');
          }
        }
      }
      fieldTypeOptions = _reject(fieldTypeOptions, (option) => {
        const value = _get(option, 'value', '');
        if (value === FIELD_TYPES.SELECT) return true;
        return false;
      });
      fieldTypeOptions = [...fieldTypeOptions, ...FIELD_TYPE_OPTIONS_NUMBER];
    } else if (dataType === DATA_TYPES.BOOLEAN) {
      fieldTypeOptions = _reject(fieldTypeOptions, (option) => {
        const value = _get(option, 'value', '');
        if (value === FIELD_TYPES.SELECT || value === FIELD_TYPES.RANGE || value === FIELD_TYPES.RELATIONSHIP) return true;
        return false;
      });
    } else if (dataType === DATA_TYPES.COMPLEX) {
      fieldTypeOptions = _filter(fieldTypeOptions, (option) => {
        const value = _get(option, 'value', '');
        if (value === FIELD_TYPES.TEXT) return true;
        return false;
      });
    }
    if (fieldType === FIELD_TYPES.RELATIONSHIP) {
      dataTypeOptions = [...dataTypeOptions, ...DATA_TYPE_OF_TARGET_OPTION];
      disableDataTypeField = true;
    }

    const tableData = _get(formValues, FIELD_IDS.LIST_INPUT_TABLE);
    let disableFieldLookup = true;
    let lookupFieldOptions = [];
    let lookupDisplayFieldOptions = [];
    const isSortDisabled = !_get(formValues, FIELD_IDS.SORTABLE, false) || formMode === FORM_MODES.EDIT;
    if (fieldType === FIELD_TYPES.RELATIONSHIP) {
      const entityName = _head(_get(formValues, FIELD_IDS.LOOKUP_ENTITY));
      if (!_isUndefined(entityName)) {
        disableFieldLookup = false;
        lookupFieldOptions = _get(options, `lookupFieldOptions.${entityName}`);
        lookupDisplayFieldOptions = _get(options, `lookupDisplayFieldOptions.${entityName}`);
      }
    }

    return {
      ...FORM_FIELDS,
      ...detailsFields,
      ...getConditionFields(mapOfVariableToEntityNameForEntryConditionChild, conditionBuilderFieldDefinitionObjectChild, entity),
      [FIELD_IDS.NAME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.NAME], [
        { path: 'disabled', value: formMode === FORM_MODES.EDIT },
        { path: 'required', value: formMode === FORM_MODES.CREATE },
        { path: 'validators', value: formMode === FORM_MODES.EDIT ? EMPTY_ARRAY : [nameValidator] },
      ]),
      [FIELD_IDS.FIELD_TYPE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.FIELD_TYPE], [
        { path: 'options', value: fieldTypeOptions },
        { path: 'isDisabled', value: formMode === FORM_MODES.EDIT },
      ]),
      [FIELD_IDS.DATA_TYPE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.DATA_TYPE], [
        { path: 'options', value: dataTypeOptions },
        { path: 'isDisabled', value: disableDataTypeField || formMode === FORM_MODES.EDIT },
      ]),
      [FIELD_IDS.MULTI_VALUED_ENABLED]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.MULTI_VALUED_ENABLED], [
        { path: 'disabled', value: formMode === FORM_MODES.EDIT },
      ]),
      [FIELD_IDS.DEPENDENCY_CONFIG]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.DEPENDENCY_CONFIG], [
        { path: 'fieldDefinitions', value: _get(entity, 'fieldDefinitions', EMPTY_ARRAY) },
        { path: 'selectedFields', value: selectedFields },
      ]),
      [FIELD_IDS.LIST_INPUT_TABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.LIST_INPUT_TABLE], [
        { path: 'data', value: tableData },
        { path: 'formMode', value: formMode },
        { path: 'modalData', value: modalData },
        { path: 'isModalVisible', value: isModalVisible },
        {
          path: 'isControllingFunctionEnabled',
          value: _get(formValues, FIELD_IDS.CONTROLLING_FUNCTION, false),
        },
        {
          path: 'controllingOptions',
          value: getValuesOptions(entity, _head(_get(formValues, FIELD_IDS.CONTROLLING_FIELD_NAME))),
        },
      ]),

      [FIELD_IDS.LOOKUP_ENTITY]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.LOOKUP_ENTITY], [
        { path: 'isDisabled', value: formMode === FORM_MODES.EDIT },
        { path: 'formMode', value: formMode },
        { path: 'filters', value: getLookupEntityFilters() },
      ]),
      [FIELD_IDS.SELECT_TEMPLATE_REGEX]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.SELECT_TEMPLATE_REGEX], [{ path: 'options', value: regexOptions }]),
      [FIELD_IDS.LOOKUP_FIELD]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.LOOKUP_FIELD], [
        { path: 'options', value: lookupFieldOptions },
        { path: 'isDisabled', value: disableFieldLookup || formMode === FORM_MODES.EDIT },
      ]),
      [FIELD_IDS.LOOKUP_DISPLAY_FIELD]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.LOOKUP_DISPLAY_FIELD], [
        { path: 'options', value: lookupDisplayFieldOptions },
        { path: 'isDisabled', value: disableFieldLookup },
      ]),
      [FIELD_IDS.SORT_DIRECTION]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.SORT_DIRECTION], [
        { path: 'options', value: SORT_OPTIONS },
        { path: 'isDisabled', value: isSortDisabled },
      ]),
      [FIELD_IDS.COMPLEX_FIELD_ENTITY]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.COMPLEX_FIELD_ENTITY], [
        { path: 'isDisabled', value: formMode === FORM_MODES.EDIT },
        { path: 'filters', value: getComplexEntityFilters(_get(entity, 'name', EMPTY_STRING)) },
        { path: 'formMode', value: formMode },
      ]),
      [FIELD_IDS.CHILD_ENTITY_NAME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CHILD_ENTITY_NAME], [{ path: 'options', value: childEntitiesOptions }]),
      [FIELD_IDS.CHILD_RELATION_FIELD]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CHILD_RELATION_FIELD], [
        { path: 'options', value: childRelationFieldOptions[_get(formValues, FIELD_IDS.CHILD_ENTITY_NAME, EMPTY_STRING)] },
        { path: 'isDisabled', value: disableFieldChild },
      ]),
      [FIELD_IDS.CHILD_FIELD]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CHILD_FIELD], [
        {
          path: 'options',
          value: _map(childFieldDefinitions, (item) => ({
            label: _get(item, 'displayName'),
            value: _get(item, 'name'),
          })),
        },
        { path: 'isDisabled', value: disableFieldChild },
      ]),

      [FIELD_IDS.CONTROLLING_FIELD_NAME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CONTROLLING_FIELD_NAME], [
        {
          path: 'options',
          value: getControllingFieldOptions(entity, formValues),
        },
      ]),

      [FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS], [
        {
          path: 'options',
          value: _get(formValues, FIELD_IDS.LIST_INPUT_TABLE),
        },
        {
          path: 'selectedOptions',
          value: selectedOptions,
        },
        {
          path: 'valuesOptions',
          value: getValuesOptions(entity, _head(_get(formValues, FIELD_IDS.CONTROLLING_FIELD_NAME))),
        },

        { path: 'isOptionDisabled', value: _isEmpty(_get(_get(formValues, FIELD_IDS.LIST_INPUT_TABLE), '[0].value')) },
        { path: 'isValueDisabled', value: _isEmpty(_get(formValues, FIELD_IDS.CONTROLLING_FIELD_NAME)) },
      ]),
    };
  },
);

export { getFormFields };
