import { useSlate } from 'slate-react';

function useEditor() {
  // TODO: replace usage of useEditor directly with useSlate and avoid redundant hook
  const editor = useSlate();

  return editor;
}

export default useEditor;
