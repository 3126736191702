import React from 'react';
import PropTypes from 'prop-types';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';

import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import DatePicker from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/datePicker';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import Error from '@tekion/tekion-components/organisms/FormBuilder/components/error';

import SelectInputFieldWrapper from '../../../../../../../../../selectInputFieldWrapper/SelectInputFieldWrapper';

import useSetDurationFieldRenderer from './hooks/useDurationFieldRenderer';

import { DATE_PICKER_ID, DURATION_TYPE_ID, DURATION_OPTIONS } from './constants/durationFieldRenderer.general.constants';

import styles from './durationFieldRenderer.module.scss';

const SelectInputField = SelectInputFieldWrapper(SelectInput);

const DurationFieldRenderer = ({ id, value, error, required, fieldClassName, onAction }) => {
  const { durationTypeValue, disabledDate, datePickerValue, isDatePickerDisabled, handleAction } = useSetDurationFieldRenderer(id, value, onAction);
  return (
    <div className={fieldClassName}>
      <FieldLabel required={required} label="Set Duration" />
      <div className={styles.durationField}>
        <SelectInputField
          className={styles.durationTypeField}
          id={DURATION_TYPE_ID}
          value={durationTypeValue}
          options={DURATION_OPTIONS}
          onAction={handleAction}
        />
        <DatePicker
          id={DATE_PICKER_ID}
          showTime
          disabled={isDatePickerDisabled}
          disabledDate={disabledDate}
          value={datePickerValue}
          format={DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_US_WITH_24_HOUR_TIME}
          onAction={handleAction}
        />
        <Error error={error} />
      </div>
    </div>
  );
};

DurationFieldRenderer.propTypes = {
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  value: PropTypes.object,
  fieldClassName: PropTypes.string.isRequired,
  error: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

DurationFieldRenderer.defaultProps = {
  required: false,
  error: EMPTY_OBJECT,
  value: EMPTY_OBJECT,
};

export default DurationFieldRenderer;
