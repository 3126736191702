import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _get from 'lodash/get';

// Constants
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

// Utils
import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';

const NumberField = ({ id, onAction, ...restProps }) => {
  const ref = useRef();
  const handleOnBlur = useCallback(() => {
    onAction({
      type: FORM_ACTION_TYPES.ON_FIELD_BLUR,
      payload: {
        id,
        value: _get(ref.current.lastValue),
      },
    });
  }, [id, onAction]);

  return <NumberInputField id={id} ref={ref} onAction={onAction} onBlur={handleOnBlur} {...restProps} />;
};

NumberField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onAction: PropTypes.func,
};

NumberField.defaultProps = {
  value: undefined,
  onAction: _noop,
};

export default NumberField;
