import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _get from 'lodash/get';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import styles from './chartWidget.module.scss';

const ChartOptionsRenderer = (props) => {
  const { data, innerProps, isFocused } = props;
  const imgSrc = useMemo(() => _get(data, 'src'), [data]);

  return (
    <div {...innerProps} className={cx(styles.option, { [styles.focused]: isFocused })}>
      <span>{_get(data, 'label', '')}</span>
      <img src={imgSrc} alt={__('visual-icon')} style={{ width: '2.8rem' }} />
    </div>
  );
};

ChartOptionsRenderer.propTypes = {
  data: PropTypes.object,
  innerProps: PropTypes.object,
  isFocused: PropTypes.bool,
};

ChartOptionsRenderer.defaultProps = {
  data: EMPTY_OBJECT,
  innerProps: EMPTY_OBJECT,
  isFocused: false,
};

export default ChartOptionsRenderer;
