const ACTION_TYPES = {
  ON_OPEN_MODAL: 'ON_OPEN_MODAL',
  ON_CLOSE_MODAL: 'ON_CLOSE_MODAL',
  ON_MODAL_INIT: 'ON_MODAL_INIT',
  ON_MODAL_SUBMIT: 'ON_MODAL_SUBMIT',
  ON_MODAL_FOOTER_RIGHT_SECTION_CLICK: 'ON_MODAL_FOOTER_RIGHT_SECTION_CLICK',
  ON_EMAIL_MODAL_CONTENT_CHANGE: 'ON_EMAIL_MODAL_CONTENT_CHANGE',
};

const ACTION_EXECUTION_MODAL_TYPES = {
  USER_INPUT: 'USER_INPUT',
  BULK_EMAIL_USER_INPUT: 'BULK_EMAIL_USER_INPUT',
};

const ACTION_EXECUTION_MODAL_EVENT_TYPES = {
  ON_MODAL_INIT: 'ON_MODAL_INIT',
};

export { ACTION_TYPES, ACTION_EXECUTION_MODAL_TYPES, ACTION_EXECUTION_MODAL_EVENT_TYPES };
