import { defaultMemoize } from 'reselect';

import _get from 'lodash/get';
import _values from 'lodash/values';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _includes from 'lodash/includes';

import { entityFieldPermissionsIds } from '../constants/entityFieldPermissionsConfigure.general.constants';
import permissionSetReader from '../../../../../../readers/permissionSet.reader';

const getModifiedColumnHeaderCheckbox = defaultMemoize((columnHeaderCheckbox, fieldPermissions) => {
  const modifiedColumnHeaderCheckbox = { ...columnHeaderCheckbox };
  _forEach(fieldPermissions, (permission) => {
    const fieldActionTypes = _get(permission, 'actionTypes');
    _forEach(entityFieldPermissionsIds, (id) => {
      if (!_includes(fieldActionTypes, id)) {
        modifiedColumnHeaderCheckbox[id] = false;
      }
    });
  });
  return modifiedColumnHeaderCheckbox;
});

const getDisabledFieldPermissionIds = defaultMemoize((entityActionTypes) => {
  let disabledFieldPermissionIds = [];
  _forEach(entityFieldPermissionsIds, (id) => {
    if (!_includes(entityActionTypes, id)) {
      disabledFieldPermissionIds = [...disabledFieldPermissionIds, id];
    }
  });
  return disabledFieldPermissionIds;
});

const getPayload = defaultMemoize((entityFieldPermissions, permissionData) => {
  let entityAccess = [];
  let entityFieldsAccesses = [];

  _map(entityFieldPermissions, (value, key) => {
    const fieldAccessMap = _values(_get(value, 'fieldAccessMap'));
    entityAccess = [...entityAccess, { ..._get(value, 'entityAccess'), recordTypeName: key }];
    entityFieldsAccesses = [...entityFieldsAccesses, { entityName: key, recordTypeName: key, fieldAccesses: fieldAccessMap }];
  });

  const modifiedEntityFieldPermission = {
    entityPermission: { entityAccess },
    fieldPermission: { entityFieldsAccesses },
    name: permissionSetReader.name(permissionData),
    displayName: permissionSetReader.displayName(permissionData),
    description: permissionSetReader.description(permissionData),
  };

  return modifiedEntityFieldPermission;
});
export { getModifiedColumnHeaderCheckbox, getPayload, getDisabledFieldPermissionIds };
