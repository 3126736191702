import TEXT_PROPERTIES_FORM_FIELD_IDS from './textPropertiesForm.fieldIds';

import styles from '../../../fieldsForm.module.scss';

const TEXT_PROPERTIES_FORM_SECTIONS = [
  {
    header: {
      label: __('Text Length Constraints'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [TEXT_PROPERTIES_FORM_FIELD_IDS.MIN_TEXT_LENGTH, TEXT_PROPERTIES_FORM_FIELD_IDS.MAX_TEXT_LENGTH],
      },
    ],
  },
  {
    header: {
      label: __('Text Pattern Rule'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [TEXT_PROPERTIES_FORM_FIELD_IDS.SELECT_TEMPLATE_REGEX],
      },
      {
        columns: [TEXT_PROPERTIES_FORM_FIELD_IDS.PATTERN],
      },
      {
        columns: [TEXT_PROPERTIES_FORM_FIELD_IDS.TEST_REGEX_INPUT_STRING],
      },
    ],
  },
];

export default TEXT_PROPERTIES_FORM_SECTIONS;
