import { isEmailRule, isRequiredRule, isPhoneNumberFormatRule } from '@tekion/tekion-base/utils/formValidators';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { EMPTY_ARRAY } from '@tekion/tekion-base/utils/constant';

import TextInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput/TextInput';
import PhoneNumberField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/PhoneNumberField';
import Switch from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch';

import PersonNameInput from '../../../../../../molecules/personNameInput';
import MediaUploaderFormRenderer from '../../../../../../organisms/mediaUploader/MediaUploaderFormRenderer';

import FIELD_IDS from '../constants/tenantUserForm.fieldIds';

import { isPersonNameRequiredRule } from '../../../../../../utils/formValidators';
import { FORM_MODES } from '../../../../../../constants/general.constants';

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: PersonNameInput,
  renderOptions: {
    required: true,
    label: __('Name'),
    firstNameKey: FIELD_IDS.FIRST_NAME,
    lastNameKey: FIELD_IDS.LAST_NAME,
    validators: [isPersonNameRequiredRule(FIELD_IDS.FIRST_NAME, FIELD_IDS.LAST_NAME)],
  },
};

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInputField,
  renderOptions: {
    label: __('Display name'),
    placeholder: __('Enter display name'),
  },
};

const EMAIL_FIELD = {
  id: FIELD_IDS.EMAIL,
  renderer: TextInputField,
  renderOptions: {
    label: __('E-mail'),
    placeholder: __('example@tekion.com'),
  },
};

const PHONE_NUMBER_FIELD = {
  id: FIELD_IDS.PHONE,
  renderer: PhoneNumberField,
  renderOptions: {
    label: __('Phone number'),
    placeholder: __('(___) ___-____'),
    validators: [isPhoneNumberFormatRule, isRequiredRule],
    required: true,
  },
};

const IS_ACTIVE_FIELD = {
  id: FIELD_IDS.ACTIVE,
  renderer: Switch,
  renderOptions: {
    label: __('Lock Account'),
  },
};

const PROFILE_PICTURE_FIELD = {
  id: FIELD_IDS.PROFILE_PICTURE,
  renderer: MediaUploaderFormRenderer,
  renderOptions: {
    label: __('Profile Picture'),
    accept: '.jpg,.png,.jpeg',
  },
};

const getUserFormFields = (mode) => ({
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.PHONE]: PHONE_NUMBER_FIELD,
  [FIELD_IDS.ACTIVE]: IS_ACTIVE_FIELD,
  [FIELD_IDS.PROFILE_PICTURE]: PROFILE_PICTURE_FIELD,
  [FIELD_IDS.EMAIL]: addToRenderOptions(EMAIL_FIELD, [
    { path: 'disabled', value: mode !== FORM_MODES.CREATE },
    { path: 'required', value: mode === FORM_MODES.CREATE },
    { path: 'validators', value: mode === FORM_MODES.CREATE ? [isRequiredRule, isEmailRule] : EMPTY_ARRAY },
  ]),
});

export { getUserFormFields };
