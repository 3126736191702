import _size from 'lodash/size';
import _split from 'lodash/split';
import _get from 'lodash/get';

import { getNodeDeserializer } from '@udecode/plate-common';
import { getPlatePluginOptions, getRenderElement, getPlatePluginTypes } from '@udecode/plate-core';
import { ELEMENT_IMAGE } from '@udecode/plate-image';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

const getImageDeserialize = () => (editor) => {
  const options = getPlatePluginOptions(editor, ELEMENT_IMAGE);
  return {
    element: getNodeDeserializer({
      type: options.type,
      getNode: (el) => {
        let mediaId = el.getAttribute('id') || EMPTY_STRING;
        const widthArray = _split(el.getAttribute('style'), ': ');
        if (mediaId && mediaId.startsWith('mediaid{')) {
          mediaId = mediaId.substring(8, _size(mediaId) - 1);
        }
        let width = _get(widthArray, '1', 250);
        if (_size(width) > 2) {
          width = Number(width.substring(0, _size(width) - 3));
        }
        return {
          type: options.type,
          data: { url: el.getAttribute('src'), mediaId, width },
          width,
        };
      },
      rules: [
        {
          nodeNames: 'IMG',
        },
      ],
      ...options.deserialize,
    }),
  };
};

const createImagePlugin = () => ({
  pluginKeys: ELEMENT_IMAGE,
  renderElement: getRenderElement(ELEMENT_IMAGE),
  deserialize: getImageDeserialize(),
  voidTypes: getPlatePluginTypes(ELEMENT_IMAGE),
});

export default createImagePlugin;
