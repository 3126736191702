import _property from 'lodash/property';

const mediaReader = {
  mediaId: _property('mediaId'),
  url: _property('normal.url'),
  mimeType: _property('normal.mimeType'),
  fileName: _property('normal.filename'),
};

export default mediaReader;
