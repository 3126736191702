const MEASURE_UNIT = {
  US: 'us',
  NA: 'NA',
};

const GRAPH_COLORS = ['#2FEDD3', '#FD6764', '#BBA8F6', '#648DE6'];

const SPLINE = 'spline';

const UNIT_CONVERTER_LABEL = {
  [MEASURE_UNIT.US]: 'ms',
  [MEASURE_UNIT.NA]: '',
};

export { GRAPH_COLORS, SPLINE, UNIT_CONVERTER_LABEL };
