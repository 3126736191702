exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .tableCellActionMenu_cellActionItem__rWDPeqTnPB{padding:.6rem 1.2rem}[dir] .tableCellActionMenu_cellActionItem__rWDPeqTnPB:hover{background-color:#ebf4ff}.tableCellActionMenu_cellActionContainer__eMkR8HEfgr{width:18rem}.tableCellActionMenu_deleteTableButton__26S9UsWh3V{color:#f52908}.tableCellActionMenu_separator__8mzdEGsTGx{height:.1rem}[dir] .tableCellActionMenu_separator__8mzdEGsTGx{background-color:#d4d5d6;margin:.4rem 1.2rem}", ""]);

// Exports
exports.locals = {
	"cellActionItem": "tableCellActionMenu_cellActionItem__rWDPeqTnPB",
	"cellActionContainer": "tableCellActionMenu_cellActionContainer__eMkR8HEfgr",
	"deleteTableButton": "tableCellActionMenu_deleteTableButton__26S9UsWh3V",
	"separator": "tableCellActionMenu_separator__8mzdEGsTGx"
};