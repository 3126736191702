import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/comments';

const createComment = (payload) => ApiService.post(BASE_URL, payload);

const searchComments = (payload) => ApiService.post(`${BASE_URL}/search`, payload);

const getCommentCount = (assetType, assetId) => ApiService.get(`${BASE_URL}/${assetType}/${assetId}/count`);

const getCommentById = (commentId) => ApiService.get(`${BASE_URL}/${commentId}`);

const updateCommentById = (commentId, payload) => ApiService.put(`${BASE_URL}/${commentId}`, payload);

const deleteCommentById = (commentId) => ApiService.delete(`${BASE_URL}/${commentId}`);

export default {
  createComment,
  searchComments,
  getCommentCount,
  getCommentById,
  updateCommentById,
  deleteCommentById,
};
