import { FIELD_IDS } from '../constants/globalSelectListForm.general.constants';

const SECTIONS = [
  {
    header: {
      className: 'p-t-12',
      label: __('Global Select details'),
    },
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
      },
      {
        columns: [FIELD_IDS.DESCRIPTION],
      },
      {
        columns: [FIELD_IDS.OPTIONS],
      },
    ],
  },
];

export default SECTIONS;
