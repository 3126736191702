const ACTION_TYPES = {
  ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE: 'ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE',
  ON_CLICK_CONFIGURE_ACTIONS: 'ON_CLICK_CONFIGURE_ACTIONS',
  ON_CANCEL_MODAL: 'ON_CANCEL_MODAL',
  ON_ASYNC_LOADED_OPTIONS: 'ON_ASYNC_LOADED_OPTIONS',
  ON_ACTIONS_TAB_CHANGE: 'ON_ACTIONS_TAB_CHANGE',
  ON_CUSTOM_ACTION_ADD_CLICK: 'ON_CUSTOM_ACTION_ADD_CLICK',
  ON_CUSTOM_ACTION_LEFT_PANEL_CLICK: 'ON_CUSTOM_ACTION_LEFT_PANEL_CLICK',
  ON_SUBMIT: 'ON_SUBMIT',
  ON_CUSTOM_ACTION_LEFT_PANEL_DELETE_CLICK: 'ON_CUSTOM_ACTION_LEFT_PANEL_DELETE_CLICK',
  ON_SHOW_ACTIONS_CHANGE: 'ON_SHOW_ACTIONS_CHANGE',
};

export default ACTION_TYPES;
