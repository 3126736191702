import ACTION_TYPES from './workspaceUserList.actionTypes';

const REMOVE_ACTION = {
  id: ACTION_TYPES.REMOVE,
  name: __('Remove'),
};

const EDIT_ACTION = {
  id: ACTION_TYPES.EDIT,
  name: __('Edit'),
};

export { REMOVE_ACTION, EDIT_ACTION };
