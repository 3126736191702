import React, { useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _split from 'lodash/split';
import _includes from 'lodash/includes';

// Components
import SideBar from '../../molecules/sideBar';
import NavigationFlow from '../../molecules/NavigationFlow/NavigationFlow';

// Pages
import FieldsForm from './pages/fieldsForm/FieldsForm';
import NewFieldsForm from './pages/fields/fieldsForm';
import NewFieldList from './pages/fields/fieldList';
import EntityList from './pages/entityList/EntityList';
import EntityForm from './pages/entityForm/EntityForm';
import FieldList from './pages/fieldList/FieldList';
import UserGroupList from './pages/userGroup/userGroupList';
import CreateUserGroup from './pages/userGroup/createUserGroup/CreateUserGroup';
import WorkspaceList from './pages/workspaceList';
import TenantUserList from './pages/tenantUser/tenantUserList/TenantUserList';
import TenantUserForm from './pages/tenantUser/tenantUserForm/TenantUserForm';
import WorkspaceUserList from './pages/workspaceUser/workspaceUserList/WorkspaceUserList';
import WorkspaceUserForm from './pages/workspaceUser/workspaceUserForm/WorkspaceUserForm';
import ProjectList from './pages/projectList/ProjectList';
import ProjectApplicationList from './pages/projectApplicationList';
import ProjectForm from './pages/projectForm/ProjectForm';
import StudioHomePage from './pages/studioHomePage/StudioHomePage';
import ActionBuilderList from './pages/actionBuilder/actionBuilderList/ActionBuilderList';
import ActionBuilderForm from './pages/actionBuilder/actionBuilderForm';
import ValidationRuleBuilderList from './pages/validationRuleBuilder/validationRuleBuilderList';
import ValidationRuleBuilderForm from './pages/validationRuleBuilder/validationRuleBuilderForm';
import ViewBuilderPage from './pages/viewBuilderPage';
import ViewBuilderList from './pages/viewBuilderList/ViewBuilderList';
import PermissionSetList from './pages/permissionSet/permissionSetList/PermissionSetList';
import PermissionSetForm from './pages/permissionSet/permissionSetForm/PermissionSetForm';
import PermissionSetConfigure from './pages/permissionSet/permissionSetConfigure/PermissionSetConfigure';
import EntityFieldPermissionsConfigure from './pages/permissionSet/entityFieldPermissionsConfigure';
import Reportee from './pages/reportee/Reportee';
import EntityRecordGroupForm from './pages/entityRecordGroup/entityRecordGroupForm';
import RecordGroupRuleList from './pages/recordGroupRuleList';
import EntityRecordGroupList from './pages/entityRecordGroup/entityRecordGroupList';
import WWDPermissionList from './pages/wwdPermissionList';
import CreateDashboard from './pages/createDashboard/CreateDashboard';
import DashboardList from './pages/dashboardList/DashboardList';
import TemplateBuilderForm from './pages/templateBuilder/templateBuilderForm';
import TemplateBuilderList from './pages/templateBuilder/templateBuilderList';
import RecordTypeList from './pages/recordTypeList/RecordTypeList';
import RecordTypeForm from './pages/recordTypeForm/RecordTypeForm';
import RecordTypeOverride from './pages/recordTypeOverride/RecordTypeOverride';
import RecordTypeDetailsForm from './pages/recordTypeDetailsForm/RecordTypeDetailsForm';
import JobScheduleList from './pages/jobScheduleList';
import JobScheduleForm from './pages/jobScheduleForm';
import VisualBuilderPage from './pages/visualBuilderPage';
import VisualBuilderList from './pages/visualBuilderList/VisualBuilderList';
import ExportList from './pages/exportList';
import ExportForm from './pages/exportForm';
import MetadataComponentList from './pages/metadataComponentList';
import ImportForm from './pages/importForm';

import {
  ApprovalGroupList,
  ApprovalCategoryList,
  ApprovalSettingsList,
  ApprovalSettingForm,
  ApprovalSettingDetails,
  ApprovalSettingFieldForm,
  ApprovalProcessList,
  ApprovalProcessForm,
} from './pages/approvalStudio';
import AuthProviderList from './pages/serverCredentialsConfigure/authProvider/authProviderList';
import AuthProviderForm from './pages/serverCredentialsConfigure/authProvider/authProviderForm';
import ExternalCredentialList from './pages/serverCredentialsConfigure/externalCredential/externalCredentialList';
import ExternalCredentialForm from './pages/serverCredentialsConfigure/externalCredential/externalCredentialForm';
import ServerCredentialList from './pages/serverCredentialsConfigure/serverCredential/serverCredentialList';
import ServerCredentialForm from './pages/serverCredentialsConfigure/serverCredential/serverCredentialForm';
import { GlobalSelectForm, GlobalSelectList } from './pages/globalSelect';
import { SubscriptionConfigList, SubscriptionConfigForm, SubscriptionConfigLogs } from './pages/subscriptionConfig';
import { WorkflowBuilder, WorkflowBuilderList } from './pages/workflowManagement';
import LoginConfigurationsDashboard from './pages/loginConfigurationsDashboard';
import DataImportForm from './pages/dataImportStudio/dataImportForm/DataImportForm';
import DataImportList from './pages/dataImportStudio/dataImportList/DataImportList';
import DataImportDetailPage from './pages/dataImportStudio/dataImportDetailPage/DataImportDetailPage';
import { ApplicationBuilder, ApplicationFormPage, ApplicationList } from './pages/applications';

import { STUDIO_ROUTE, TENANT_UNIVERSE_BASE_ROUTE } from '../../constants/routes';
import ROUTES from './constants/routes';

import localStorageReader from '../../readers/localStorage.reader';
import userLocalStorageReader from '../../readers/loginResponse.reader';

// Styles
import styles from './devPlatform.module.scss';

const DevPlatform = (props) => {
  const { history } = props;
  const pathName = _get(history, 'location.pathname');
  const sanitizedPathName = useMemo(() => _split(pathName, '/'), [pathName]);

  const userInfo = localStorageReader.userInfo();
  const currentWorkspaceId = userLocalStorageReader.workspaceId(userInfo);

  if (pathName === TENANT_UNIVERSE_BASE_ROUTE) {
    return <Redirect to={ROUTES.WORKSPACE_LIST_ROUTE} />;
  }

  if (_includes(currentWorkspaceId, 'universe') && !_includes(pathName, 'universe')) {
    return <Redirect to={TENANT_UNIVERSE_BASE_ROUTE} />;
  }

  return (
    <div className={`full-height full-width ${styles.container}`}>
      <SideBar history={history} sanitizedPathName={sanitizedPathName} />
      <div className="full-height full-width">
        <NavigationFlow history={history} sanitizedPathName={sanitizedPathName} />
        <Switch>
          <Route exact path={ROUTES.ENTITY_CREATE_ROUTE} component={EntityForm} />
          <Route exact path={ROUTES.ENTITY_LIST_ROUTE} component={EntityList} />
          <Route exact path={ROUTES.ENTITY_EDIT_ROUTE} component={EntityForm} />

          <Route exact path={ROUTES.FIELD_CREATE_ROUTE} component={FieldsForm} />
          <Route exact path={ROUTES.FIELD_LIST_ROUTE} component={FieldList} />
          <Route exact path={ROUTES.FIELD_LIST_ROUTE_V2} component={NewFieldList} />
          <Route exact path={ROUTES.FIELD_EDIT_ROUTE} component={FieldsForm} />

          <Route exact path={ROUTES.FIELD_CREATE_ROUTE_V2} component={NewFieldsForm} />
          <Route exact path={ROUTES.FIELD_EDIT_ROUTE_V2} component={NewFieldsForm} />

          <Route exact path={ROUTES.WORKSPACE_LIST_ROUTE} component={WorkspaceList} />
          <Route exact path={ROUTES.USER_GROUP_LIST_ROUTE} component={UserGroupList} />
          <Route exact path={ROUTES.USER_GROUP_CREATE_ROUTE} component={CreateUserGroup} />
          <Route exact path={ROUTES.USER_GROUP_EDIT_ROUTE} component={CreateUserGroup} />

          <Route exact path={ROUTES.TENANT_UNIVERSE_USER_GROUP_LIST_ROUTE} component={UserGroupList} />
          <Route exact path={ROUTES.TENANT_UNIVERSE_USER_GROUP_CREATE_ROUTE} component={CreateUserGroup} />
          <Route exact path={ROUTES.TENANT_UNIVERSE_USER_GROUP_EDIT_ROUTE} component={CreateUserGroup} />

          <Route exact path={ROUTES.TENANT_UNIVERSE_USER_LIST_ROUTE} component={TenantUserList} />
          <Route exact path={ROUTES.TENANT_UNIVERSE_USER_CREATE_ROUTE} component={TenantUserForm} />
          <Route exact path={ROUTES.TENANT_UNIVERSE_USER_EDIT_ROUTE} component={TenantUserForm} />

          <Route exact path={ROUTES.LOGIN_CONFIGURATIONS_ROUTE} component={LoginConfigurationsDashboard} />

          <Route exact path={ROUTES.USER_LIST_ROUTE} component={WorkspaceUserList} />
          <Route exact path={ROUTES.USER_CREATE_ROUTE} component={WorkspaceUserForm} />
          <Route exact path={ROUTES.USER_EDIT_ROUTE} component={WorkspaceUserForm} />

          <Route exact path={ROUTES.APPLICATION_LIST_ROUTE} component={ApplicationList} />
          <Route exact path={ROUTES.APPLICATION_CREATE_ROUTE} component={ApplicationFormPage} />
          <Route exact path={ROUTES.APPLICATION_EDIT_ROUTE} component={ApplicationFormPage} />
          <Route exact path={ROUTES.CONFIGURE_APPLICATION_ROUTE_CREATE} component={ApplicationBuilder} />
          <Route exact path={ROUTES.CONFIGURE_APPLICATION_ROUTE_EDIT} component={ApplicationBuilder} />

          <Route exact path={ROUTES.VIEW_BUILDER_LIST_TAB_ROUTE} component={ViewBuilderList} />
          <Route path={ROUTES.VIEW_BUILDER_CREATE_ROUTE} render={ViewBuilderPage} />
          <Route path={ROUTES.VIEW_BUILDER_EDIT_ROUTE} component={ViewBuilderPage} />

          <Route exact path={ROUTES.VALIDATION_RULE_BUILDER_LIST_ROUTE} component={ValidationRuleBuilderList} />
          <Route exact path={ROUTES.VALIDATION_RULE_BUILDER_CREATE_ROUTE} component={ValidationRuleBuilderForm} />
          <Route exact path={ROUTES.VALIDATION_RULE_BUILDER_EDIT_ROUTE} component={ValidationRuleBuilderForm} />

          <Route exact path={ROUTES.ACTION_BUILDER_LIST_ROUTE} component={ActionBuilderList} />
          <Route exact path={ROUTES.ACTION_BUILDER_CREATE_ROUTE} component={ActionBuilderForm} />
          <Route exact path={ROUTES.ACTION_BUILDER_EDIT_ROUTE} component={ActionBuilderForm} />

          <Route exact path={ROUTES.PROJECT_LIST_ROUTE} component={ProjectList} />
          <Route exact path={ROUTES.PROJECT_APPLICATION_LIST_ROUTE} component={ProjectApplicationList} />
          <Route exact path={ROUTES.PROJECT_CREATE_ROUTE} component={ProjectForm} />
          <Route exact path={ROUTES.PROJECT_EDIT_ROUTE} component={ProjectForm} />

          <Route exact path={ROUTES.PERMISSION_SET_LIST_ROUTE} component={PermissionSetList} />
          <Route exact path={ROUTES.PERMISSION_SET_CONFIGURE_ROUTE} component={PermissionSetConfigure} />
          <Route exact path={ROUTES.PERMISSION_SET_CREATE_ROUTE} component={PermissionSetForm} />
          <Route exact path={ROUTES.PERMISSION_SET_EDIT_ROUTE} component={PermissionSetForm} />
          <Route exact path={ROUTES.ENTITY_FIELD_PERMISSION_CONFIGURE_ROUTE} component={EntityFieldPermissionsConfigure} />

          <Route path={ROUTES.REPORTEE_ROUTE} component={Reportee} />
          <Route exact path={ROUTES.WWD_PERMISSION_LIST_ROUTE} component={WWDPermissionList} />
          <Route exact path={ROUTES.DASHBOARD_LIST_ROUTE} component={DashboardList} />
          <Route exact path={ROUTES.DASHBOARD_CREATE_ROUTE} component={CreateDashboard} />
          <Route exact path={ROUTES.DASHBOARD_EDIT_ROUTE} component={CreateDashboard} />

          <Route exact path={ROUTES.ENTITY_RECORD_GROUP_LIST} component={EntityRecordGroupList} />
          <Route exact path={ROUTES.ENTITY_RECORD_GROUP_CREATE_ROUTE} component={EntityRecordGroupForm} />
          <Route exact path={ROUTES.ENTITY_RECORD_GROUP_EDIT_ROUTE} component={EntityRecordGroupForm} />
          <Route exact path={ROUTES.RECORD_SHARING_RULE_LIST_ROUTE} component={RecordGroupRuleList} />

          <Route exact path={ROUTES.JOB_SCHEDULE_LIST_ROUTE} component={JobScheduleList} />
          <Route exact path={ROUTES.JOB_SCHEDULE_CREATE_ROUTE} component={JobScheduleForm} />
          <Route exact path={ROUTES.JOB_SCHEDULE_EDIT_ROUTE} component={JobScheduleForm} />

          <Route exact path={ROUTES.TEMPLATE_CREATE_ROUTE} component={TemplateBuilderForm} />
          <Route exact path={ROUTES.TEMPLATE_EDIT_ROUTE} component={TemplateBuilderForm} />
          <Route exact path={ROUTES.TEMPLATE_BUILDER_LIST_ROUTE} component={TemplateBuilderList} />

          <Route exact path={ROUTES.RECORD_TYPE_LIST} component={RecordTypeList} />
          <Route exact path={ROUTES.RECORD_TYPE_CREATE} component={RecordTypeForm} />
          <Route exact path={ROUTES.RECORD_TYPE_EDIT} component={RecordTypeForm} />
          <Route exact path={ROUTES.RECORD_TYPE_DETAILS} component={RecordTypeOverride} />
          <Route exact path={ROUTES.RECORD_TYPE_DETAILS_EDIT} component={RecordTypeDetailsForm} />

          <Route exact path={ROUTES.RULE_CREATE_FORM_ROUTE} component={WorkflowBuilder} />
          <Route exact path={ROUTES.RULE_CREATE_ROUTE} component={WorkflowBuilder} />
          <Route exact path={ROUTES.RULE_EDIT_ROUTE} component={WorkflowBuilder} />
          <Route exact path={ROUTES.PROCESS_CREATE_ROUTE} component={WorkflowBuilder} />
          <Route exact path={ROUTES.PROCESS_EDIT_ROUTE} component={WorkflowBuilder} />
          <Route exact path={ROUTES.PROCESS_CREATE_FORM_ROUTE} component={WorkflowBuilder} />
          <Route exact path={ROUTES.WORKFLOW_LIST} component={WorkflowBuilderList} />

          <Route path={ROUTES.VISUAL_BUILDER_CREATE_ROUTE} component={VisualBuilderPage} />
          <Route path={ROUTES.VISUAL_BUILDER_EDIT_ROUTE} component={VisualBuilderPage} />
          <Route exact path={ROUTES.VISUAL_BUILDER_LIST_ROUTE} component={VisualBuilderList} />

          <Route path={ROUTES.APPROVAL_GROUPS_ROUTE} component={ApprovalGroupList} />
          <Route path={ROUTES.APPROVAL_CATEGORIES_ROUTE} component={ApprovalCategoryList} />
          <Route path={ROUTES.APPROVAL_SETTINGS_ROUTE} component={ApprovalSettingsList} />
          <Route path={ROUTES.APPROVAL_SETTING_CREATE_ROUTE} component={ApprovalSettingForm} />
          <Route path={ROUTES.APPROVAL_SETTING_DETAILS_ROUTE} component={ApprovalSettingDetails} />
          <Route path={ROUTES.APPROVAL_SETTING_ADD_FIELD_ROUTE} component={ApprovalSettingFieldForm} />
          <Route path={ROUTES.APPROVAL_SETTING_EDIT_FIELD_ROUTE} component={ApprovalSettingFieldForm} />
          <Route path={ROUTES.APPROVAL_PROCESSES_ROUTE} component={ApprovalProcessList} />
          <Route path={ROUTES.APPROVAL_PROCESS_CREATE_ROUTE} component={ApprovalProcessForm} />
          <Route path={ROUTES.APPROVAL_PROCESS_EDIT_ROUTE} component={ApprovalProcessForm} />
          <Route exact path={ROUTES.EXPORT_LIST_ROUTE} component={ExportList} />
          <Route exact path={ROUTES.EXPORT_CREATE_ROUTE} component={ExportForm} />
          <Route exact path={ROUTES.EXPORT_EDIT_ROUTE} component={ExportForm} />
          <Route exact path={ROUTES.COMPONENT_CREATE_ROUTE} component={MetadataComponentList} />
          <Route exact path={ROUTES.IMPORT_CREATE_ROUTE} component={ImportForm} />
          <Route exact path={ROUTES.SUBSCRIPTION_CONFIG_CREATE_ROUTE} component={SubscriptionConfigForm} />
          <Route exact path={ROUTES.SUBSCRIPTION_CONFIG_EDIT_ROUTE} component={SubscriptionConfigForm} />
          <Route exact path={ROUTES.SUBSCRIPTION_CONFIG_LOGS} component={SubscriptionConfigLogs} />
          <Route exact path={ROUTES.SUBSCRIPTION_CONFIG_LIST_ROUTE} component={SubscriptionConfigList} />

          <Route path={ROUTES.AUTH_PROVIDER_CREATE_ROUTE} component={AuthProviderForm} />
          <Route path={ROUTES.AUTH_PROVIDER_EDIT_ROUTE} component={AuthProviderForm} />
          <Route exact path={ROUTES.AUTH_PROVIDER_LIST_ROUTE} component={AuthProviderList} />

          <Route path={ROUTES.EXTERNAL_CREDENTIAL_CREATE_ROUTE} component={ExternalCredentialForm} />
          <Route path={ROUTES.EXTERNAL_CREDENTIAL_EDIT_ROUTE} component={ExternalCredentialForm} />
          <Route exact path={ROUTES.EXTERNAL_CREDENTIAL_LIST_ROUTE} component={ExternalCredentialList} />

          <Route path={ROUTES.SERVER_CREDENTIAL_CREATE_ROUTE} component={ServerCredentialForm} />
          <Route path={ROUTES.SERVER_CREDENTIAL_EDIT_ROUTE} component={ServerCredentialForm} />
          <Route exact path={ROUTES.SERVER_CREDENTIAL_LIST_ROUTE} component={ServerCredentialList} />

          <Route path={ROUTES.GLOBAL_SELECT_LIST_CREATE_ROUTE} component={GlobalSelectForm} />
          <Route path={ROUTES.GLOBAL_SELECT_LIST_EDIT_ROUTE} component={GlobalSelectForm} />
          <Route exact path={ROUTES.GLOBAL_SELECT_LIST_ROUTE} component={GlobalSelectList} />

          <Route exact path={ROUTES.IMPORT_DATA_LIST_ROUTE} component={DataImportList} />
          <Route exact path={ROUTES.IMPORT_DATA_CREATE_ROUTE} component={DataImportForm} />
          <Route exact path={ROUTES.IMPORT_DATA_DETAIL_PAGE_ROUTE} component={DataImportDetailPage} />

          <Route path={STUDIO_ROUTE} component={StudioHomePage} />
        </Switch>
      </div>
    </div>
  );
};

DevPlatform.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default DevPlatform;
