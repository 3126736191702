// Components
import Button from '@tekion/tekion-components/atoms/Button';

// Constants
import { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import { ACTION_TYPES } from './fieldList.actionTypes';

// Styles
import styles from '../fieldList.module.scss';

const SUB_HEADER_PROPS = {
  className: styles.subHeader,
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        view: Button.VIEW.PRIMARY,
        label: __('Add Field'),
      },
      action: ACTION_TYPES.REDIRECT_TO_FIELD_CREATION,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showHeader: false,
  showSearch: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const FIELD_STATUS = {
  CHECKED: true,
  UNCHECKED: false,
};

const FIELD_STATUS_COLOR_MAP = {
  [FIELD_STATUS.UNCHECKED]: COLOR_MAP.RED,
  [FIELD_STATUS.CHECKED]: COLOR_MAP.GREEN,
};

const FIELD_STATUS_LABEL_MAP = {
  [FIELD_STATUS.UNCHECKED]: __('No'),
  [FIELD_STATUS.CHECKED]: __('Yes'),
};

export { SUB_HEADER_PROPS, TABLE_MANAGER_PROPS, FIELD_STATUS_COLOR_MAP, FIELD_STATUS_LABEL_MAP };
