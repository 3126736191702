const CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS = {
  CHILD_ENTITY_NAME: 'entityName',
  AGGREGATE_FIELD: 'fieldToAggregate',
  ROLL_UP_FUNCTION: 'function',
  CHILD_RELATIONSHIP: 'relationshipField',
  FILTER_CRITERIA_FOR_CHILD: 'filterCriteriaForChild',
  CHILD_FILTER_CONDITION: 'childFilterCondition',
  FILTER_CRITERIA_FOR_PARENT: 'filterCriteriaForParent',
  UPDATE_CONDITION: 'updateCondition',
};

export default CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS;
