import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { tget, uuid } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { TOASTER_TYPE, toaster } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { createApplication, getApplication, updateApplication } from '../../../../../../../actions/applicationManagement.actions';
import { fetchEntityRecordById } from '../../../../../../../actions/recordManagement.actions';
import {
  getErrors,
  getAppConfigWithFlattenProperties,
  getNavigationComponentId,
  getAppConfigWithUpdatedProperties,
  getApplicationWithUpdatedComponentId,
} from './applicationBuilder.helpers';

import { DIALOG_TEXT, TITLE_TEXT } from '../constants/applicationBuilder.constants';
import ACTION_TYPES from '../constants/applicationBuilder.actionTypes';
import { FORM_MODES, STANDARD_ENTITY_NAME } from '../../../../../../../constants/general.constants';
import { APPLICATION_CONTEXT_KEYS, COMPONENTS_TYPES } from '../../../../../../../constants/applicationRenderer.constants';
import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import ROUTES from '../../../../../constants/routes';
import PAGE_NAMES from '../../../../../constants/PageIds.constants';

import localStorageReader from '../../../../../../../readers/localStorage.reader';

const handleOnMount = async ({ getState, setState }) => {
  const { match = EMPTY_OBJECT, history, currentLoggedInUserId = EMPTY_STRING } = getState();
  setState({ loading: true });
  const { applicationName } = _get(match, 'params', EMPTY_OBJECT);

  const devPlatformUser = localStorageReader.userInfo();
  let applicationData = _get(history, 'location.state', EMPTY_OBJECT);

  const userIdToFetch = !_isEmpty(currentLoggedInUserId) ? currentLoggedInUserId : tget(devPlatformUser, 'userId', EMPTY_STRING);
  const userResponse = await fetchEntityRecordById(STANDARD_ENTITY_NAME.USER, userIdToFetch);

  if (_isEmpty(applicationName)) {
    if (_isEmpty(applicationData)) {
      history.push(ROUTES.ACTION_BUILDER_CREATE_ROUTE);
    } else {
      const componentId = uuid();
      setState({
        formMode: FORM_MODES.CREATE,
        applicationConfig: {
          ...applicationData,
          components: [{ type: COMPONENTS_TYPES.NO_NAVIGATION, pageName: null, children: [], properties: [], componentId }],
        },
        selectedComponentId: componentId,
      });
    }
  } else {
    if (_isEmpty(applicationData)) {
      applicationData = await getApplication(applicationName);
    }
    applicationData = getAppConfigWithFlattenProperties(applicationData);
    applicationData = getApplicationWithUpdatedComponentId(applicationData);
    const selectedComponentId = getNavigationComponentId(applicationData);

    setState({
      formMode: FORM_MODES.EDIT,
      applicationConfig: applicationData,
      selectedComponentId,
    });
  }

  setState({
    applicationBuilderContextValue: { [APPLICATION_CONTEXT_KEYS.CURRENT_USER]: userResponse },
    loading: false,
  });
};

const handleOnSave = async ({ getState, setState }) => {
  const { applicationConfig, formMode, match, history } = getState();
  const errorObject = getErrors(applicationConfig);
  let isValid = true;
  _forEach(errorObject, (value) => {
    if (!_isEmpty(value)) {
      isValid = false;
    }
  });

  if (isValid) {
    setState({ isSavingDetails: true });
    const payload = getAppConfigWithUpdatedProperties(applicationConfig);

    if (formMode === FORM_MODES.CREATE) {
      const response = await createApplication(payload);
      if (!_isEmpty(response)) {
        // Open entity mappings modal after creation of application, after user saves / closes, take them to list page.
        setState({ isEntityMappingsModalVisible: true });
        toaster(TOASTER_TYPE.SUCCESS, __('Application created successfully.'));
      }
    } else {
      const { applicationName } = _get(match, 'params', EMPTY_OBJECT);
      await updateApplication(applicationName, payload);
      setState({ isSavingDetails: false });

      setTimeout(() => {
        history.push(ROUTES.APPLICATION_LIST_ROUTE);
      }, ES_REFETCH_DELAY);
    }
  } else {
    setState({ errorObject });
  }
};

const handleOnCancel = ({ setState }) => {
  setState({
    isCancelDialogVisible: true,
    dialogText: DIALOG_TEXT.CANCEL_TEXT,
    titleText: TITLE_TEXT.CANCEL_TITLE_TEXT,
  });
};

const handleOnCancelModalRequest = ({ setState }) => {
  setState({ isCancelDialogVisible: false });
};

const handleOnConfirmModalRequest = ({ getState, setState }) => {
  setState({ isCancelDialogVisible: false });
  const { history } = getState();
  history.goBack();
};

const handleOpenPreviewModal = ({ setState }) => {
  setState({ showPreviewModal: true });
};

const handleClosePreviewModal = ({ setState }) => {
  setState({ showPreviewModal: false });
};

const handleSetApplicationVariables = ({ getState, setState, params }) => {
  const { variableName, value } = params;
  const { applicationBuilderContextValue = {} } = getState();

  const previousApplicationVariables = tget(applicationBuilderContextValue, APPLICATION_CONTEXT_KEYS.APPLICATION_VARIABLES, {});

  setState({
    applicationBuilderContextValue: {
      ...applicationBuilderContextValue,
      [APPLICATION_CONTEXT_KEYS.APPLICATION_VARIABLES]: { ...previousApplicationVariables, [variableName]: value },
    },
  });
};

const handleSetApplicationConfig = ({ setState, params }) => {
  const applicationConfig = _get(params, 'applicationConfig');
  const errorObject = getErrors(applicationConfig);

  setState({ applicationConfig, errorObject });
};

const handleSetSelectedComponent = ({ setState, params }) => {
  const selectedComponentId = _get(params, 'selectedComponentId');
  setState({ selectedComponentId });
};

const handleEditClick = ({ getState }) => {
  const { match, history, applicationConfig } = getState();
  const updatedApplicationConfig = getAppConfigWithUpdatedProperties(applicationConfig);
  const { applicationName } = _get(match, 'params', EMPTY_OBJECT);
  if (_isEmpty(applicationName)) {
    history.push({ pathname: ROUTES.APPLICATION_CREATE_ROUTE, state: updatedApplicationConfig });
  } else {
    history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_NAMES.APPLICATION_EDIT}/${applicationName}`, state: updatedApplicationConfig });
  }
};

const handleEntityMappingsClick = ({ setState }) => {
  setState({ isEntityMappingsModalVisible: true });
};

const handleEntityMappingsClose = ({ getState, setState }) => {
  const { history, isSavingDetails, formMode } = getState();

  if (formMode === FORM_MODES.CREATE && isSavingDetails) {
    // this condition is true when the modal had opened automatically post creation.
    // hence we are pushing to list view if condition is true.
    setTimeout(() => {
      setState({ isEntityMappingsModalVisible: false });
      history.push(ROUTES.APPLICATION_LIST_ROUTE);
    }, ES_REFETCH_DELAY);
  } else {
    setState({ isEntityMappingsModalVisible: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT_DATA]: handleOnMount,
  [ACTION_TYPES.ON_EDIT_CLICK]: handleEditClick,
  [ACTION_TYPES.ON_SAVE]: handleOnSave,
  [ACTION_TYPES.ON_CANCEL]: handleOnCancel,
  [ACTION_TYPES.ON_CANCEL_REQUEST]: handleOnCancelModalRequest,
  [ACTION_TYPES.ON_CONFIRM_REQUEST]: handleOnConfirmModalRequest,
  [ACTION_TYPES.OPEN_PREVIEW_MODAL]: handleOpenPreviewModal,
  [ACTION_TYPES.CLOSE_PREVIEW_MODAL]: handleClosePreviewModal,
  [ACTION_TYPES.SET_APPLICATION_VARIABLES]: handleSetApplicationVariables,
  [ACTION_TYPES.SET_APPLICATION_CONFIG]: handleSetApplicationConfig,
  [ACTION_TYPES.SET_SELECTED_COMPONENT]: handleSetSelectedComponent,
  [ACTION_TYPES.ON_ENTITY_MAPPINGS_CLICK]: handleEntityMappingsClick,
  [ACTION_TYPES.ON_CLOSE_ENTITY_MAPPINS_MODAL]: handleEntityMappingsClose,
};

export default ACTION_HANDLERS;
