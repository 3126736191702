import { useCallback, useState } from 'react';

// constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

const handleMediaUploadStatusChange = ({ mediaIdentifier, uploadingMediaStatuses, setUploadingMediaStatuses, value }) => {
  setUploadingMediaStatuses({
    ...uploadingMediaStatuses,
    [mediaIdentifier]: value,
  });
};

function useUploadingMediaStatuses() {
  /**
   * uploadingMediaStatuses keep track of each media that is getting uploaded.
   * It is an object in which the keys represent unique media identifier and the value is true or false (true representing media upload in progress)
   */
  const [uploadingMediaStatuses, setUploadingMediaStatuses] = useState(EMPTY_OBJECT);
  const setMediaUploadingStatus = useCallback(
    (mediaIdentifier, value) => {
      handleMediaUploadStatusChange({ mediaIdentifier, uploadingMediaStatuses, setUploadingMediaStatuses, value });
    },
    [uploadingMediaStatuses],
  );

  return {
    uploadingMediaStatuses,
    setMediaUploadingStatus,
  };
}

export default useUploadingMediaStatuses;
