import React, { useCallback, useEffect, useMemo } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';
import Spinner from '@tekion/tekion-components/molecules/SpinnerComponent';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';

import WithSize from '../../../../../connectors/withSize';

import getFields from './helpers/globalSelectListForm.fields';
import SECTIONS from './helpers/globalSelectListForm.sections';

import ACTION_HANDLERS from './helpers/globalSelectListForm.actionHandlers';
import ACTION_TYPES from './constants/globalSelectListForm.actionTypes';
import { FORM_MODES } from '../../../../../constants/viewBuilder.constants';
import { INITIAL_STATE, GLOBAL_SELECT_LIST_FORM_CONTEXT_ID } from './constants/globalSelectListForm.general.constants';

const GlobalSelectListForm = ({
  isDataLoading,
  isSaveLoading,
  showCancelModal,
  globalSelectName,
  formMode,
  contentHeight,
  formValues,
  errors,
  onAction,
}) => {
  const formHeaderValue = formMode === FORM_MODES.CREATE ? __('Create new Global Select') : __(`Edit Global Select - ${globalSelectName}`);
  const fields = useMemo(() => getFields(formMode), [formMode]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(GLOBAL_SELECT_LIST_FORM_CONTEXT_ID);
  }, []);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const handleConfirmationDialogSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CONFIRM_MODAL_REQUEST });
  }, [onAction]);

  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL_MODAL_REQUEST });
  }, [onAction]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction]);

  return (
    <Page className="full-height full-width">
      <Page.Header hasBack>
        <Heading className="full-width">{formHeaderValue}</Heading>
      </Page.Header>

      <Page.Body style={{ height: contentHeight }}>
        <PropertyControlledComponent
          controllerProperty={!isDataLoading}
          fallback={
            <div className="full-width full-height flex-center">
              <Spinner size="medium" />
            </div>
          }
        >
          <FormWithSubmission
            contextId={GLOBAL_SELECT_LIST_FORM_CONTEXT_ID}
            fields={fields}
            sections={SECTIONS}
            values={formValues}
            errors={errors}
            onAction={onAction}
          />
        </PropertyControlledComponent>
      </Page.Body>
      <PropertyControlledComponent controllerProperty={showCancelModal}>
        <ConfirmationDialog
          isVisible={showCancelModal}
          title={__('Cancel Changes')}
          content={__('Are you sure you want to cancel all the changes made by you? They will be lost if you click yes.')}
          onSubmit={handleConfirmationDialogSubmit}
          onCancel={handleConfirmationDialogCancel}
        />
      </PropertyControlledComponent>

      <Page.Footer>
        <SaveComponent
          primaryActionLoading={isSaveLoading}
          primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Create') : __('Update')}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

GlobalSelectListForm.propTypes = {
  isDataLoading: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  showCancelModal: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  globalSelectName: PropTypes.string,
  formMode: PropTypes.string,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

GlobalSelectListForm.defaultProps = {
  isDataLoading: false,
  isSaveLoading: false,
  showCancelModal: false,
  globalSelectName: EMPTY_STRING,
  formMode: EMPTY_STRING,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default compose(WithSize({ hasPageFooter: 1, hasPageHeader: 1 }), withActions(INITIAL_STATE, ACTION_HANDLERS))(GlobalSelectListForm);
