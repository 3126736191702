const ROW_ACTION_PROPS = {
  visibleActionCount: 1,
};

const OPTIONS_TABLE_ACTION_TYPES = {
  REMOVE_ROW: 'REMOVE_OPTION',
  EDIT_MODE_ADD_ROW: 'EDIT_MODE_ADD_OPTION',
  EDIT_ROW: 'EDIT_OPTION',
};

const OPTION_MODAL_ACTION_TYPES = {
  ON_SUBMIT: 'ON_SUBMIT',
  ON_CLOSE: 'ON_CLOSE',
};

const REMOVE_ACTION = {
  id: OPTIONS_TABLE_ACTION_TYPES.REMOVE_ROW,
  name: __('Remove Row'),
  icon: 'icon-trash',
};
const EDIT_ACTION = {
  id: OPTIONS_TABLE_ACTION_TYPES.EDIT_ROW,
  name: __('Edit'),
  icon: 'icon-edit',
};

const COLUMN_IDS = {
  LABEL: 'displayName',
  VALUE: 'value',
};

const ROW_OPERATION = {
  EDIT: 'EDIT',
  ADD: 'ADD',
  DELETE: 'DELETE',
};

const MODAL_INPUT_FIELD = {
  LABEL: 'displayName',
  VALUE: 'value',
  CONTROLLING_OPTIONS: 'controllingOptions',
};

const MAX_LENGTH = 256;

export {
  ROW_ACTION_PROPS,
  REMOVE_ACTION,
  EDIT_ACTION,
  OPTIONS_TABLE_ACTION_TYPES,
  OPTION_MODAL_ACTION_TYPES,
  COLUMN_IDS,
  ROW_OPERATION,
  MODAL_INPUT_FIELD,
  MAX_LENGTH,
};
