import { FIELD_IDS } from './navigationProperties.constants';

const SECTIONS = [
  {
    rows: [
      {
        columns: [FIELD_IDS.PAGE_NAME],
      },
      {
        columns: [FIELD_IDS.NAVIGATION_PROPERTIES],
      },
    ],
  },
];

export { SECTIONS };
