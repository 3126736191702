import { useCallback, useState } from 'react';

function useHoveredColumnId() {
  const [hoveredColumnId, setHoveredColumnId] = useState();

  const resetHoveredColumnId = useCallback(() => setHoveredColumnId(), []);

  return {
    hoveredColumnId,
    setHoveredColumnId,
    resetHoveredColumnId,
  };
}

export default useHoveredColumnId;
