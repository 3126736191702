import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

// Tekion Components
import Select from '@tekion/tekion-components/molecules/Select';
import IconAsBtn from '@tekion/tekion-components/atoms/iconAsBtn';
import Popover, { POPOVER_TRIGGER, POPOVER_PLACEMENT } from '@tekion/tekion-components/molecules/popover';

// Constants
import { TAB_CONTENT_FILTER_OPTIONS } from '../../constants/notificationDrawer.constants';

import styles from './notificationSubHeader.module.scss';

const NotificationSubHeader = ({ isActionDisabled, filterType, onMarkAllAsRead, onClearAll, onFilterChange }) => (
  <div className={styles.container}>
    <Select className={styles.select} value={filterType} options={TAB_CONTENT_FILTER_OPTIONS} onChange={onFilterChange} />
    <div className="flex-center">
      <Popover
        placement={POPOVER_PLACEMENT.TOP}
        trigger={POPOVER_TRIGGER.HOVER}
        content={__('Mark all as Read')}
        overlayClassName={styles.groupActionToolTip}
      >
        <IconAsBtn containerClassName="m-r-16" disabled={isActionDisabled} onClick={onMarkAllAsRead}>
          icon-double-tick
        </IconAsBtn>
      </Popover>
      <Popover
        placement={POPOVER_PLACEMENT.TOP}
        trigger={POPOVER_TRIGGER.HOVER}
        content={__('Clear All')}
        overlayClassName={styles.groupActionToolTip}
      >
        <IconAsBtn disabled={isActionDisabled} onClick={onClearAll}>
          icon-clear-all
        </IconAsBtn>
      </Popover>
    </div>
  </div>
);

NotificationSubHeader.propTypes = {
  isActionDisabled: PropTypes.bool,
  filterType: PropTypes.string.isRequired,
  onMarkAllAsRead: PropTypes.func,
  onClearAll: PropTypes.func,
  onFilterChange: PropTypes.func,
};

NotificationSubHeader.defaultProps = {
  isActionDisabled: true,
  onMarkAllAsRead: _noop,
  onClearAll: _noop,
  onFilterChange: _noop,
};

export default NotificationSubHeader;
