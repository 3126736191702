import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

import { getAuthenticatorType } from './mfaRenderer.helpers';
import { MFA_ICONS, MFA_HEADINGS, MFA_MESSAGES } from '../../LoginPage.constants';

import styles from './mfaRenderer.module.scss';

const MFARenderer = ({ mfaAuthTypes }) => {
  const authenticatorType = useMemo(() => getAuthenticatorType(mfaAuthTypes), [mfaAuthTypes]);
  const authenticatorHeading = MFA_HEADINGS[authenticatorType];

  const authenticatorMessage = MFA_MESSAGES[authenticatorType];

  const icon = MFA_ICONS[authenticatorType];

  return (
    <div className={styles.container}>
      <FontIcon className={styles.icon}>{icon}</FontIcon>
      <div className={styles.heading}>{authenticatorHeading}</div>
      <div className={styles.message}>{authenticatorMessage}</div>
    </div>
  );
};

MFARenderer.propTypes = {
  mfaAuthTypes: PropTypes.array,
};

MFARenderer.defaultProps = {
  mfaAuthTypes: EMPTY_ARRAY,
};

export default MFARenderer;
