import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _head from 'lodash/head';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

import styles from './formSectionRowColumn.module.scss';

const FormSectionRowColumn = (props) => {
  const { sectionId, className, componentConfig, fieldDefinitionsByName } = props;

  const properties = _get(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);
  const fieldName = _head(_get(properties, ALL_VIEW_PROPERTY_KEYS.FIELD_NAMES, EMPTY_ARRAY));

  const fieldDefinition = _get(fieldDefinitionsByName, fieldName);

  const displayName = _get(properties, ALL_VIEW_PROPERTY_KEYS.TITLE);

  return (
    <div key={sectionId} className={cx(styles.formSectionRowColumn, className)}>
      <div className={styles.key}>{displayName}</div>
      {fieldDefinitionReader.displayName(fieldDefinition)}
    </div>
  );
};

FormSectionRowColumn.propTypes = {
  sectionId: PropTypes.string.isRequired,
  className: PropTypes.string,
  componentConfig: PropTypes.object.isRequired,
  fieldDefinitionsByName: PropTypes.object.isRequired,
};

FormSectionRowColumn.defaultProps = {
  className: '',
};

export default FormSectionRowColumn;
