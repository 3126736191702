import { uuid } from '@tekion/tekion-base/utils/general';

import { COMPONENTS_TYPES } from '../../../../../../../../../../../constants/applicationRenderer.constants';
import { PROPERTIES_FIELD_IDS } from '../../../../../constants/applicationBuilder.constants';

const FIELD_IDS = {
  NAVIGATION: PROPERTIES_FIELD_IDS.IS_NAVIGATION_REQUIRED,
  NAVIGATION_TYPE: PROPERTIES_FIELD_IDS.NAVIGATION_TYPE,
  APPROVAL_INTEGRATION: PROPERTIES_FIELD_IDS.APPROVAL_INTEGRATION,
  NAVIGATION_PROPERTIES: PROPERTIES_FIELD_IDS.NAVIGATION_PROPERTIES,
  PAGE_NAME: PROPERTIES_FIELD_IDS.PAGE_NAME,
  APPROVAL_META_DATA: PROPERTIES_FIELD_IDS.APPROVAL_META_DATA,
};

const NAVIGATION_PROPERTIES_CONTEXT_ID = 'NAVIGATION_PROPERTIES_CONTEXT_ID';

const NAVIGATION_TYPE_INITIAL_CONFIG = {
  pageName: null,
  type: COMPONENTS_TYPES.NAVIGATION,
  properties: { [FIELD_IDS.NAVIGATION_TYPE]: 'top' },
  children: [
    {
      componentId: uuid(),
      type: COMPONENTS_TYPES.TAB,
      children: null,
      properties: {
        [PROPERTIES_FIELD_IDS.TAB_DISPLAY_NAME]: 'Untitled',

        [PROPERTIES_FIELD_IDS.TAB_NAME]: 'untitled',
      },
    },
  ],
};

const APPROVAL_INTEGRATION_INITIAL_CONFIG = {
  isEnabled: true,
  enableCreation: false,
  groupsEnabled: ['DEFAULT'],
  categoriesEnabled: ['CUSTOM_ENTITY'],
  filtersToShow: ['status'],
  pageName: null,
  id: 'APPROVAL_CENTRE',
  tabDisplayName: 'Approvals',
};

export { FIELD_IDS, NAVIGATION_PROPERTIES_CONTEXT_ID, NAVIGATION_TYPE_INITIAL_CONFIG, APPROVAL_INTEGRATION_INITIAL_CONFIG };
