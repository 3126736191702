import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import Heading from '@tekion/tekion-components/atoms/Heading';

import { getDerivedTitle } from './sectionTitle.helper';

import { VIEW_CONFIGURATION_FIELD_IDS, ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './sectionTitle.module.scss';

const SectionTitle = ({ isPreviewMode, className, componentConfig, customStyles, entityRecord }) => {
  const properties = tget(componentConfig, VIEW_CONFIGURATION_FIELD_IDS.PROPERTIES);
  const title = tget(properties, ALL_VIEW_PROPERTY_KEYS.TITLE);
  const headerSize = tget(properties, ALL_VIEW_PROPERTY_KEYS.SECTION_TITLE_SIZE, 3);

  const titleToShow = getDerivedTitle(title, entityRecord);

  const onSectionTitleChange = useCallback(
    (event) => {
      _set(componentConfig, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.TITLE}`, event.target.innerText);
    },
    [componentConfig],
  );

  if (_isEmpty(title) && isPreviewMode) {
    return null;
  }

  return (
    <Heading size={headerSize} style={customStyles} className={cx(styles.sectionTitle, className)}>
      {isPreviewMode ? (
        titleToShow
      ) : (
        <div contentEditable suppressContentEditableWarning onInput={onSectionTitleChange}>
          {title}
        </div>
      )}
    </Heading>
  );
};

SectionTitle.propTypes = {
  isPreviewMode: PropTypes.bool,
  className: PropTypes.string,
  customStyles: PropTypes.object,
  entityRecord: PropTypes.object,
  componentConfig: PropTypes.object.isRequired,
};

SectionTitle.defaultProps = {
  isPreviewMode: false,
  className: EMPTY_STRING,
  customStyles: EMPTY_OBJECT,
  entityRecord: EMPTY_OBJECT,
};

export default React.memo(SectionTitle);
