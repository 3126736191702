import _get from 'lodash/get';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _keyBy from 'lodash/keyBy';
import _forEach from 'lodash/forEach';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';
import _split from 'lodash/split';
import _head from 'lodash/head';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';
import { isConditionRequiredRule } from '../../../../../organisms/conditionBuilder/helpers/conditionBuilder.general.helpers';
import { RECORD_TYPES } from '../../../../../constants/general.constants';
import { FIELD_IDS } from './recordTypeDetailForm.fields';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import CONDITION_BUILDER_MODES from '../../../../../organisms/conditionBuilder/constants/conditionBuilder.modes';

const getValues = (entity, fieldDefinitionByName) => {
  const type = _get(entity, 'recordTypeDefinition.type', RECORD_TYPES.STANDARD);
  const derivationFields = _get(entity, 'recordTypeDefinition.recordTypeDerivationConfig.derivationFields', EMPTY_ARRAY);
  const derivationFieldDefinitions = _map(derivationFields, (item) => _get(fieldDefinitionByName, item));

  let allTabs = _map(_get(entity, 'recordTypeDefinition.recordTypes', EMPTY_ARRAY), (recordType) => ({
    ..._pick(recordType, ['name']),
    ...tget(recordType, 'derivationCondition', {
      [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: [{}],
      [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: '1',
    }),
  }));

  allTabs = _map(allTabs, (item) => ({
    ...item,
    [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: _map(_get(item, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST), (criteria) => {
      if (_isEmpty(criteria)) {
        return criteria;
      } else
        return {
          ...criteria,
          [CONDITION_FIELD_IDS.FIELD]: _castArray(_get(criteria, CONDITION_FIELD_IDS.FIELD, [''])),
        };
    }),
  }));

  const selectedTabId = _get(getArraySafeValue(allTabs, EMPTY_OBJECT), 'name');
  const allTabsByName = _keyBy(allTabs, 'name');

  _forEach(allTabsByName, (value, key) => {
    allTabsByName[key] = _omit(value, ['name']);
  });

  return {
    formValues: { [FIELD_IDS.RECORD_TYPE]: type, derivationFields, allTabsByName },
    selectedTabId,
    derivationFieldDefinitions,
  };
};

const getErrorObject = (allTabsByName) => {
  const conditionErrorObject = {};

  _forEach(allTabsByName, (value, key) => {
    const error = isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)(null, value);
    conditionErrorObject[key] = { ...error, ..._get(error, 'message') };
  });

  return conditionErrorObject;
};

const getFieldOptions = (fieldDefinitions) =>
  _map(fieldDefinitions, (field) => ({ label: __(_get(field, 'displayName')), value: _get(field, 'name') }));

const getPayloadFromValues = (formValues) => {
  const recordTypeToConditionMap = {};
  _forEach(_get(formValues, 'allTabsByName'), (value, key) => {
    recordTypeToConditionMap[key] = {
      ...value,
      type: 'CRITERIA',
      [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: _map(tget(value, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY), (criteria) => ({
        ...criteria,
        [CONDITION_FIELD_IDS.FIELD]: getArraySafeValue(_get(criteria, CONDITION_FIELD_IDS.FIELD)),
      })),
    };
  });
  const payload = { ..._pick(formValues, [FIELD_IDS.RECORD_TYPE, FIELD_IDS.DERIVATION_FIELDS]), recordTypeToConditionMap };

  return payload;
};

const getFieldNameFromFieldValue = (fieldValue) => {
  const fieldArray = _split(fieldValue, '.');

  return _head(fieldArray);
};

export { getValues, getFieldOptions, getPayloadFromValues, getErrorObject, getFieldNameFromFieldValue };
