import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _get from 'lodash/get';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { getMasterEntityDefFromCache } from '../../../../actions/applicationRenderer.actions';
import { getMasterEntityRecord } from '../../../../actions/entityManagement.actions';

import { WIDGET_TYPES } from '../../../../constants/visualBuilder';
import { COMPONENT_CONFIG_KEYS } from '../../constants/visualBuilder.general.constants';
import { ACTION_DEFINITION_FIELD_IDS, ACTION_LEVEL_TYPE } from '../../../../constants/actionBuilder.constants';

import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';
import entityReader from '../../../../readers/entity.reader';

const getEntityRecordAndDefFromWidgetType = async ({
  pageEntity,
  pageEntityRecord,
  componentConfig,
  detailViewEntityName,
  cachedEntitiesDef,
  handleSetEntityDef,
  isEntityRecordFetched,
}) => {
  const widgetType = _get(componentConfig, COMPONENT_CONFIG_KEYS.WIDGET_TYPE);

  let entityRecord = pageEntityRecord;
  let entityDef = pageEntity;

  switch (widgetType) {
    case WIDGET_TYPES.CHILD_WIDGET: {
      entityDef = await getMasterEntityDefFromCache({
        cacheValue: cachedEntitiesDef,
        setCacheHandler: handleSetEntityDef,
        entityName: detailViewEntityName,
      });
      const fieldDefs = entityReader.fieldDefinitions(entityDef);

      const relativeField = _get(componentConfig, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${COMPONENT_CONFIG_KEYS.RELATIVE_FIELD_NAME}`);
      const relatedFieldDef = _find(fieldDefs, { name: relativeField });
      const relatedFieldLookupField = fieldDefinitionReader.lookupField(relatedFieldDef);

      let recordValueToFilter;
      if (_has(pageEntityRecord, relatedFieldLookupField)) {
        recordValueToFilter = tget(pageEntityRecord, relatedFieldLookupField, EMPTY_STRING);
      } else if (_has(pageEntityRecord, `entity.${relatedFieldLookupField}`)) {
        recordValueToFilter = tget(pageEntityRecord, ['entity', relatedFieldLookupField], EMPTY_STRING);
      }

      if (!_isEmpty(recordValueToFilter) && !isEntityRecordFetched) {
        entityRecord = await getMasterEntityRecord(recordValueToFilter, relativeField, entityDef);
      } else if (_isEmpty(recordValueToFilter)) {
        entityRecord = null;
      }
      break;
    }

    case WIDGET_TYPES.PARENT_WIDGET: {
      const relativeField = _get(componentConfig, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${COMPONENT_CONFIG_KEYS.RELATIVE_FIELD_NAME}`);

      const fieldDefs = entityReader.fieldDefinitions(entityDef);
      const relatedFieldDef = _find(fieldDefs, { name: relativeField });
      const relatedFieldLookupEntityName = fieldDefinitionReader.lookupFieldEntityType(relatedFieldDef);
      const relatedFieldLookupField = fieldDefinitionReader.lookupField(relatedFieldDef);

      entityDef = await getMasterEntityDefFromCache({
        cacheValue: cachedEntitiesDef,
        setCacheHandler: handleSetEntityDef,
        entityName: relatedFieldLookupEntityName,
      });

      let recordValueToFilter;
      if (_has(pageEntityRecord, relativeField)) {
        recordValueToFilter = tget(pageEntityRecord, relativeField, EMPTY_STRING);
      } else if (_has(pageEntityRecord, `entity.${relativeField}`)) {
        recordValueToFilter = tget(pageEntityRecord, ['entity', relativeField], EMPTY_STRING);
      }

      if (!_isEmpty(recordValueToFilter) && !isEntityRecordFetched) {
        entityRecord = await getMasterEntityRecord(recordValueToFilter, relatedFieldLookupField, entityDef);
      } else if (_isEmpty(recordValueToFilter)) {
        // setting null so that we can know that record does not exist
        entityRecord = null;
      }
      break;
    }

    default: {
      break;
    }
  }

  return { entityRecord, entityDef };
};

const getPayloadForFetchActions = ({ entityName, actionNames }) => {
  let filters = [
    {
      field: ACTION_DEFINITION_FIELD_IDS.ACTION_LEVEL,
      values: [ACTION_LEVEL_TYPE.ENTITY],
      filterType: OPERATORS.IN,
    },
    {
      field: ACTION_DEFINITION_FIELD_IDS.ENTITY_NAME,
      values: [entityName],
      filterType: OPERATORS.IN,
    },
    {
      field: 'status',
      values: ['ACTIVE'],
      filterType: OPERATORS.IN,
    },
  ];

  if (!_isEmpty(actionNames)) {
    filters = [
      ...filters,
      {
        field: ACTION_DEFINITION_FIELD_IDS.ACTION_NAME,
        values: actionNames,
        filterType: OPERATORS.IN,
      },
    ];
  }

  const payload = {
    filters,
  };

  return payload;
};

export { getEntityRecordAndDefFromWidgetType, getPayloadForFetchActions };
