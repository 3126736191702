import { insertNodes, unwrapNodes } from '@udecode/plate-common';
import { getPlatePluginType } from '@udecode/plate-core';
import { ELEMENT_LINK } from '@udecode/plate-link';

// constants
import { Transforms } from 'slate';
import ACTION_TYPES from '../constants/linkInsertionForm.actionTypes';
import FIELD_IDS from '../constants/linkInsertionForm.fieldIds';

const handleEditExistingLink = ({ selectedLinkNode, editor, linkNodeType, url, title, shouldOpenInNewTab, hideLinkDialog }) => {
  const [, selectedLinkNodePath] = selectedLinkNode;
  Transforms.select(editor, selectedLinkNodePath);
  unwrapNodes(editor, { at: editor.selection, match: { type: linkNodeType } });
  insertNodes(editor, { url, type: linkNodeType, shouldOpenInNewTab, children: [{ text: title }] }, { at: editor.selection });
  hideLinkDialog(editor);
};

const handleAddNewLink = ({ editor, linkNodeType, url, title, shouldOpenInNewTab, hideLinkDialog }) => {
  unwrapNodes(editor, { at: editor.selection, match: { type: linkNodeType } });
  insertNodes(editor, { url, type: linkNodeType, shouldOpenInNewTab, children: [{ text: title }] }, { at: editor.selection });
  hideLinkDialog(editor);
};

const handleLinkCreation = ({ params, getState }) => {
  const { formValues, editor } = params;
  const { selectedLinkNode, hideLinkDialog } = getState();
  const linkNodeType = getPlatePluginType(editor, ELEMENT_LINK);
  const { [FIELD_IDS.URL]: url, [FIELD_IDS.TITLE]: title, [FIELD_IDS.SHOULD_OPEN_IN_NEW_TAB]: shouldOpenInNewTab } = formValues;
  if (selectedLinkNode) {
    return handleEditExistingLink({
      selectedLinkNode,
      editor,
      linkNodeType,
      shouldOpenInNewTab,
      title,
      url,
      hideLinkDialog,
    });
  }
  return handleAddNewLink({ editor, linkNodeType, url, title, shouldOpenInNewTab, hideLinkDialog });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE_LINK]: handleLinkCreation,
};

export default ACTION_HANDLERS;
