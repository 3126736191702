import NullComponent from '@tekion/tekion-components/atoms/nullComponent';

import Container from '../atoms/container/Container';
import DetailViewWidgetRenderer from '../widgets/detailViewWidgetRenderer';
import FormViewWidgetRenderer from '../widgets/formViewWidgetRenderer/FormViewWidgetRenderer';
import GridViewWidgetRenderer from '../widgets/gridViewWidgetRenderer';
import ButtonWidgetRenderer from '../widgets/buttonWidgetRenderer';
import TabWidgetRenderer from '../widgets/tabWidgetRenderer';
import ReportingWidgetRenderer from '../widgets/reportingWidgetRenderer/ReportingWidgetRenderer';
import TextWidgetRenderer from '../widgets/textWidgetRenderer/TextWidgetRenderer';
import TabPaneContainer from '../atoms/tabPaneContainer/TabPaneContainer';

import { WIDGET_TYPES } from './visualBuilder.general.constants';
import { VIEW_TYPES } from '../../../constants/viewBuilder.constants';

const mapWidgetByViewType = (viewType) => {
  switch (viewType) {
    case VIEW_TYPES.DETAIL_VIEW:
      return DetailViewWidgetRenderer;
    case VIEW_TYPES.GRID_VIEW:
      return GridViewWidgetRenderer;
    case VIEW_TYPES.LIST_VIEW:
      return GridViewWidgetRenderer;
    case VIEW_TYPES.FORM_VIEW:
      return FormViewWidgetRenderer;
    default:
      return NullComponent;
  }
};

const mapWidgetByType = (widgetType) => {
  switch (widgetType) {
    case WIDGET_TYPES.CONTAINER:
      return Container;
    case WIDGET_TYPES.BUTTON_WIDGET:
      return ButtonWidgetRenderer;
    case WIDGET_TYPES.TAB_WIDGET:
      return TabWidgetRenderer;
    case WIDGET_TYPES.REPORTING_WIDGET:
      return ReportingWidgetRenderer;
    case WIDGET_TYPES.TEXT_WIDGET:
      return TextWidgetRenderer;
    case WIDGET_TYPES.TAB_PANE:
      return TabPaneContainer;
    default:
      return NullComponent;
  }
};

const WIDGET_DISPLAY_NAMES = {
  [WIDGET_TYPES.PARENT_WIDGET]: __('Parent Widget'),
  [WIDGET_TYPES.SIMILAR_WIDGET]: __('Similar Widget'),
  [WIDGET_TYPES.STANDARD_WIDGET]: __('Standard Widget'),
  [WIDGET_TYPES.CHILD_WIDGET]: __('Child Widget'),
  [WIDGET_TYPES.BUTTON_WIDGET]: __('Button Widget'),
  [WIDGET_TYPES.TEXT_WIDGET]: __('Text Widget'),
  [WIDGET_TYPES.TAB_WIDGET]: __('Tab Widget'),
  [WIDGET_TYPES.REPORTING_WIDGET]: __('Reporting Widget'),
};

export { mapWidgetByType, mapWidgetByViewType, WIDGET_DISPLAY_NAMES };
