import _forEach from 'lodash/forEach';
import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { APPROVAL_CENTRE_FIELD_IDS } from '../../../constants/approvalCentre.constants';
import ENTITY_DEFINITION_FIELDS from '../../../constants/entityDefinitionFields';

import entityReader from '../../../readers/entity.reader';

const getEntitiesDiplayNameFetchPayload = (data) => {
  const entityNames = _compact(_map(data, (approvalItem) => tget(approvalItem, APPROVAL_CENTRE_FIELD_IDS.TYPE)));

  if (_isEmpty(entityNames)) {
    return false;
  }

  return {
    filters: [
      {
        field: ENTITY_DEFINITION_FIELDS.NAME,
        values: entityNames,
        filterType: OPERATORS.IN,
      },
    ],
  };
};

const getEntitiesDisplayNamesByName = (entitiesResponse) => {
  const entitiesList = tget(entitiesResponse, 'hits', EMPTY_ARRAY);
  return _reduce(
    entitiesList,
    (acc, entity) => {
      const name = entityReader.name(entity);
      const displayName = entityReader.displayName(entity);

      return {
        ...acc,
        [name]: displayName,
      };
    },
    {},
  );
};

const getEntitiesApprovalRequestsPayload = (data) => {
  const entityNames = [];
  const approvalRequestIds = [];

  _forEach(data, (approvalTask) => {
    entityNames.push(tget(approvalTask, APPROVAL_CENTRE_FIELD_IDS.TYPE));
    approvalRequestIds.push(tget(approvalTask, APPROVAL_CENTRE_FIELD_IDS.APPROVAL_ID));
  });

  const entitiesPayload = _isEmpty(_compact(entityNames))
    ? false
    : {
        filters: [
          {
            field: ENTITY_DEFINITION_FIELDS.NAME,
            values: _compact(entityNames),
            filterType: OPERATORS.IN,
          },
        ],
      };

  const approvalRequestsPayload = _isEmpty(_compact(approvalRequestIds))
    ? false
    : {
        filters: [
          {
            field: APPROVAL_CENTRE_FIELD_IDS.ID,
            values: _compact(approvalRequestIds),
            filterType: OPERATORS.IN,
          },
        ],
      };

  return { entitiesPayload, approvalRequestsPayload };
};

export { getEntitiesDiplayNameFetchPayload, getEntitiesDisplayNamesByName, getEntitiesApprovalRequestsPayload };
