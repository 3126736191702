import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _castArray from 'lodash/castArray';
import _values from 'lodash/values';
import _noop from 'lodash/noop';

import { Collapse } from 'antd';

import { NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import Heading from '@tekion/tekion-components/atoms/Heading';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import Button from '@tekion/tekion-components/atoms/Button';

import DraggableWidget from '../../atoms/dragable/DraggableWidget';

import { getMapOfWidgetsToWidgetPanel } from './helpers/widgetPanel.helpers';
import { PAGE_TYPES } from '../../../../constants/visualBuilder';
import { WIDGETS_PANEL_HEADER_NAME, WIDGET_PANELS, PANEL_ITEMS_NAME } from './constants/widgetPanel.constants';

import styles from './widgetsPanel.module.scss';

const WidgetsPanel = ({ pageType, onClickAddContainer }) => {
  const [activeKeys, setActiveKeys] = useState([]);

  const onPanelChange = useCallback((key) => setActiveKeys(_castArray(key)), []);

  return (
    <>
      <div className={styles.addContainerButton}>
        <Button view={Button.VIEW.PRIMARY} onClick={onClickAddContainer}>
          {__('Add Container')}
        </Button>
      </div>
      <Collapse activeKey={activeKeys} onChange={onPanelChange} ghost className={styles.collapseStyle}>
        {_map(_values(WIDGET_PANELS), (widgetPanel, index) => (
          <Collapse.Panel
            header={
              <Heading size={3} className={styles.listGroupHeader}>
                {WIDGETS_PANEL_HEADER_NAME[widgetPanel] || NO_DATA}
              </Heading>
            }
            key={index}
          >
            {_map(tget(getMapOfWidgetsToWidgetPanel(pageType), widgetPanel, []), (panelItem, panelItemIndex) => (
              <DraggableWidget key={index + panelItemIndex} index={index + panelItemIndex} type={panelItem}>
                <div className={styles.widgets}>
                  <FontIcon size={SIZES.S} className={styles.btnDnd}>
                    icon-drag-and-drop
                  </FontIcon>
                  {tget(PANEL_ITEMS_NAME, [panelItem], NO_DATA)}
                </div>
              </DraggableWidget>
            ))}
          </Collapse.Panel>
        ))}
      </Collapse>
    </>
  );
};

WidgetsPanel.propTypes = {
  pageType: PropTypes.string,
  onClickAddContainer: PropTypes.func,
};

WidgetsPanel.defaultProps = {
  pageType: PAGE_TYPES.HOME_PAGE,
  onClickAddContainer: _noop,
};

export default WidgetsPanel;
