import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

const INITIAL_STATES_PROJECT_FORM_PROPS = {
  values: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

const PROJECT_FORM_CONTEXT_ID = 'WORKSPACE_PROJECT_MANAGEMENT_FORM';

const STATUS = {
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
};

export { STATUS, PROJECT_FORM_CONTEXT_ID, INITIAL_STATES_PROJECT_FORM_PROPS };
