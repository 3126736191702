import { useMemo } from 'react';
import createEditorOptions from './helpers/useEditorOptions.createOptions';

function useEditorOptions(readOnly) {
  const options = useMemo(() => createEditorOptions(readOnly), [readOnly]);

  return options;
}

export default useEditorOptions;
