import { compose, withProps } from 'recompose';
import { defaultMemoize } from 'reselect';
import _get from 'lodash/get';

import Button from '@tekion/tekion-components/atoms/Button';
import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import {
  CUSTOM_ACTIONS,
  COLUMN_ACCESSORS,
  STATUS_TO_LABEL,
  STATUS_TO_COLOR,
  ACTION_TYPES_OPTIONS,
  STATUS_OPTIONS,
  STATUSES,
} from './actionBuilderList.constants';

const StatusCellRenderer = compose(
  makeCellRenderer,
  withProps({
    labelMap: STATUS_TO_LABEL,
    colorMap: STATUS_TO_COLOR,
  }),
)(StatusRenderer);

const getColumns = defaultMemoize(() => [
  {
    Header: __('Display Name'),
    accessor: COLUMN_ACCESSORS.ACTION_DISPLAY_NAME,
    id: COLUMN_ACCESSORS.ACTION_DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Name'),
    accessor: COLUMN_ACCESSORS.ACTION_NAME,
    id: COLUMN_ACCESSORS.ACTION_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Action Type'),
    accessor: COLUMN_ACCESSORS.ACTION_TYPE,
    id: COLUMN_ACCESSORS.ACTION_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Status'),
    accessor: COLUMN_ACCESSORS.STATUS,
    id: COLUMN_ACCESSORS.STATUS,
    Cell: StatusCellRenderer,
  },
  {
    Header: __('Modified By'),
    accessor: COLUMN_ACCESSORS.MODIFIED_BY,
    id: COLUMN_ACCESSORS.MODIFIED_BY,
    Cell: TextRenderer,
  },
]);

const getSubHeaderProps = defaultMemoize(() => ({
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        view: Button.VIEW.PRIMARY,
        label: __('Create New'),
      },
      action: CUSTOM_ACTIONS.CREATE_NEW,
    },
  ],
}));

const TABLE_MANAGER_PROPS = {
  showSearch: true,
  showFilter: true,
  showHeader: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const EDIT_ACTION = {
  id: 'EDIT',
  name: __('Edit'),
};

const ACTIVATE_ACTION = {
  id: 'ACTIVATE',
  name: __('Activate Action'),
};

const DEACTIVATE_ACTION = {
  id: 'DEACTIVATE',
  name: __('Deactivate Action'),
};

const getRow = (rowData) => {
  const active = _get(rowData, 'status', '');
  const ACTIONS = active === STATUSES.ACTIVE ? [EDIT_ACTION, DEACTIVATE_ACTION] : [EDIT_ACTION, ACTIVATE_ACTION];
  return ACTIONS;
};

const getTableProps = defaultMemoize((handleRowAction, currentPage, loading, rows, totalNumberOfEntries) => ({
  currentPage: currentPage + 1,
  loading,
  pageSize: rows,
  resultsPerPage: rows,
  minRows: 0,
  showPagination: true,
  rows,
  totalNumberOfEntries,
  selection: [],
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  onRowActionClick: handleRowAction,
  getRowActions: getRow,
}));

const getFilterProps = defaultMemoize(() => ({
  filterTypes: [
    {
      id: COLUMN_ACCESSORS.ACTION_TYPE,
      name: __('Action Type'),
      type: FILTER_TYPES.SINGLE_SELECT,
      additional: {
        options: ACTION_TYPES_OPTIONS,
      },
    },
    {
      id: COLUMN_ACCESSORS.STATUS,
      name: __('Status'),
      type: FILTER_TYPES.SINGLE_SELECT,
      additional: {
        options: STATUS_OPTIONS,
      },
    },
  ],
  defaultFilterTypes: [COLUMN_ACCESSORS.ACTION_TYPE, COLUMN_ACCESSORS.STATUS],
  showDefaultFilter: false,
}));

const tableData = {
  getColumns,
  TABLE_MANAGER_PROPS,
  getTableProps,
  getSubHeaderProps,
  getFilterProps,
};

export default tableData;
