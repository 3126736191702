import _find from 'lodash/find';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _slice from 'lodash/slice';
import _pick from 'lodash/pick';
import _valuesIn from 'lodash/valuesIn';
import { defaultMemoize } from 'reselect';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';

import TEXT_PROPERTIES_FORM_FIELD_IDS from '../constants/textPropertiesForm.fieldIds';

const getRegexOptions = defaultMemoize((regexps) =>
  _map(regexps, (regex) => ({
    label: tget(regex, 'displayName', NO_DATA),
    value: _get(regex, 'id'),
    ...regex,
  })),
);

const getRegexValue = defaultMemoize((regexOptions, value) => {
  const regexValue = _find(regexOptions, (regex) => getArraySafeValue(value) === _get(regex, 'id')) || EMPTY_OBJECT;

  return tget(regexValue, 'regex', EMPTY_STRING);
});

const getTextPropertiesFormValues = (fieldsFormTextPropertiesValues) =>
  _pick(fieldsFormTextPropertiesValues, _slice(_valuesIn(TEXT_PROPERTIES_FORM_FIELD_IDS), 0, 3));

const getFieldsFormTextPropertiesValue = (values) =>
  _pick(values, [
    TEXT_PROPERTIES_FORM_FIELD_IDS.MIN_TEXT_LENGTH,
    TEXT_PROPERTIES_FORM_FIELD_IDS.MAX_TEXT_LENGTH,
    TEXT_PROPERTIES_FORM_FIELD_IDS.PATTERN,
  ]);

const minMaxRule = (fieldId, value, allValues) => {
  const minTextLength = _get(allValues, TEXT_PROPERTIES_FORM_FIELD_IDS.MIN_TEXT_LENGTH);
  const maxTextLength = _get(allValues, TEXT_PROPERTIES_FORM_FIELD_IDS.MAX_TEXT_LENGTH);

  if (minTextLength > maxTextLength) {
    return { isValid: false, message: __('Min text length cannot be more than max text length.') };
  }

  return { isValid: true };
};

export { getRegexOptions, getRegexValue, getTextPropertiesFormValues, getFieldsFormTextPropertiesValue, minMaxRule };
