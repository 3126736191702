exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".mfaRenderer_container__kZnThDpWH8{width:100%;display:flex;align-items:center;justify-content:center;flex-flow:column nowrap;gap:1.2rem}[dir] .mfaRenderer_container__kZnThDpWH8{margin:3.6rem 0}[dir] .mfaRenderer_icon__vTDCkv62XW{padding:.8rem;border-radius:50%;background-color:#d4d5d6}.mfaRenderer_heading__uZazsrJFZN{font-family:\"Proxima-Nova-Semibold\";font-size:1.6rem}.mfaRenderer_message__ayrgm7Js34{max-width:50%;font-size:1.4rem}[dir] .mfaRenderer_message__ayrgm7Js34{text-align:center}", ""]);

// Exports
exports.locals = {
	"container": "mfaRenderer_container__kZnThDpWH8",
	"icon": "mfaRenderer_icon__vTDCkv62XW",
	"heading": "mfaRenderer_heading__uZazsrJFZN",
	"message": "mfaRenderer_message__ayrgm7Js34"
};