import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';
import _reduce from 'lodash/reduce';
import _unset from 'lodash/unset';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS } from '../constants/entityAndViewConfigure.fieldIds';
import { VIEW_CONFIGURATION_FIELD_IDS, VIEW_TYPES } from '../../../../../../../constants/viewBuilder.constants';
import { COMPONENT_CONFIG_KEYS, WIDGET_TYPES } from '../../../../../constants/visualBuilder.general.constants';
import { VIEW_TYPE_OPTIONS_LABEL } from '../constants/entityAndViewConfigure.general.constants';
import FIELD_TYPES from '../../../../../../../constants/fieldDefinition.fieldTypes';
import { PAGE_TYPES } from '../../../../../../../constants/visualBuilder';
import entityReader from '../../../../../../../readers/entity.reader';
import fieldDefinitionReader from '../../../../../../../readers/fieldDefinition.reader';

const getValueOption = (viewConfig) => ({
  label: __(`${tget(viewConfig, 'displayName', EMPTY_STRING)}`) || __(`${tget(viewConfig, 'name', EMPTY_STRING)}`),
  value: tget(viewConfig, 'name', EMPTY_STRING),
});

const getOptions = (data) => _map(data, getValueOption);

const getPayload =
  (entityName, viewType) =>
  ({ nextPageToken, searchText }) => {
    const filters = [
      {
        field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
        values: _castArray(entityName),
        filterType: OPERATORS.IN,
      },
      {
        field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
        values: _castArray(viewType),
        filterType: OPERATORS.IN,
      },
    ];

    if (!_isEmpty(searchText)) {
      filters.push({
        field: 'displayName',
        values: [searchText],
        filterType: OPERATORS.TEXT_STARTS_WITH,
      });
    }

    return {
      rows: 20,
      nextPageToken,
      filters,
    };
  };

const getFormFormattedSelectedWidgetProperties = (selectedWidgetProperties, widgetType) => {
  const entityName = tget(selectedWidgetProperties, ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.ENTITY_NAME);
  const viewName = tget(selectedWidgetProperties, ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW);
  const viewType = tget(selectedWidgetProperties, ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW_TYPE);

  let relativeFieldName;
  if (widgetType !== WIDGET_TYPES.SIMILAR_WIDGET) {
    relativeFieldName = tget(selectedWidgetProperties, ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.RELATIVE_FIELD_NAME);
  } else {
    // In similar widget case enum is fieldToSearchOn
    relativeFieldName = tget(selectedWidgetProperties, COMPONENT_CONFIG_KEYS.FIELD_TO_SEARCH_ON);
  }

  let updatedWidgetProperties = { ...selectedWidgetProperties };

  if (!_isEmpty(viewName)) {
    updatedWidgetProperties = { ...updatedWidgetProperties, [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW]: _castArray(viewName) };
  }

  if (!_isEmpty(entityName)) {
    updatedWidgetProperties = { ...updatedWidgetProperties, [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.ENTITY_NAME]: _castArray(entityName) };
  }

  if (!_isEmpty(viewType)) {
    updatedWidgetProperties = { ...updatedWidgetProperties, [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW_TYPE]: _castArray(viewType) };
  }

  if (!_isEmpty(relativeFieldName)) {
    updatedWidgetProperties = {
      ...updatedWidgetProperties,
      [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.RELATIVE_FIELD_NAME]: _castArray(relativeFieldName),
    };
  }

  return updatedWidgetProperties;
};

const getConfigFormattedSelectedWidgetProperties = (value, id, selectedWidgetProperties, widgetType) => {
  let updatedWidgetProperties = { ...selectedWidgetProperties, [id]: value };
  if (id !== ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW) {
    _unset(updatedWidgetProperties, ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW);
  }

  const entityName = tget(updatedWidgetProperties, ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.ENTITY_NAME);
  const entityViewName = tget(updatedWidgetProperties, ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW);
  const viewType = tget(updatedWidgetProperties, ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW_TYPE);
  const relativeFieldName = tget(updatedWidgetProperties, ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.RELATIVE_FIELD_NAME);

  if (!_isEmpty(entityViewName)) {
    updatedWidgetProperties = { ...updatedWidgetProperties, [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW]: getArraySafeValue(entityViewName) };
  }

  if (!_isEmpty(entityName)) {
    updatedWidgetProperties = { ...updatedWidgetProperties, [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.ENTITY_NAME]: getArraySafeValue(entityName) };
  }

  if (!_isEmpty(viewType)) {
    updatedWidgetProperties = { ...updatedWidgetProperties, [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW_TYPE]: getArraySafeValue(viewType) };
  }

  if (!_isEmpty(relativeFieldName)) {
    if (widgetType !== WIDGET_TYPES.SIMILAR_WIDGET) {
      updatedWidgetProperties = {
        ...updatedWidgetProperties,
        [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.RELATIVE_FIELD_NAME]: getArraySafeValue(relativeFieldName),
      };
    } else {
      // In similar widget case enum is fieldToSearchON
      updatedWidgetProperties = {
        ...updatedWidgetProperties,
        [COMPONENT_CONFIG_KEYS.FIELD_TO_SEARCH_ON]: getArraySafeValue(relativeFieldName),
      };
    }
  }

  return updatedWidgetProperties;
};

const getViewTypeOptions = (widgetType, pageType) => {
  if (pageType === PAGE_TYPES.HOME_PAGE) {
    return [
      { label: VIEW_TYPE_OPTIONS_LABEL[VIEW_TYPES.LIST_VIEW], value: VIEW_TYPES.LIST_VIEW },
      { label: VIEW_TYPE_OPTIONS_LABEL[VIEW_TYPES.GRID_VIEW], value: VIEW_TYPES.GRID_VIEW },
    ];
  }

  if (widgetType === WIDGET_TYPES.SIMILAR_WIDGET) {
    return [
      { label: VIEW_TYPE_OPTIONS_LABEL[VIEW_TYPES.LIST_VIEW], value: VIEW_TYPES.LIST_VIEW },
      { label: VIEW_TYPE_OPTIONS_LABEL[VIEW_TYPES.GRID_VIEW], value: VIEW_TYPES.GRID_VIEW },
    ];
  }

  if (widgetType === WIDGET_TYPES.PARENT_WIDGET) {
    return [
      { label: VIEW_TYPE_OPTIONS_LABEL[VIEW_TYPES.LIST_VIEW], value: VIEW_TYPES.LIST_VIEW },
      { label: VIEW_TYPE_OPTIONS_LABEL[VIEW_TYPES.DETAIL_VIEW], value: VIEW_TYPES.DETAIL_VIEW },
      { label: VIEW_TYPE_OPTIONS_LABEL[VIEW_TYPES.GRID_VIEW], value: VIEW_TYPES.GRID_VIEW },
    ];
  }

  return [
    { label: VIEW_TYPE_OPTIONS_LABEL[VIEW_TYPES.LIST_VIEW], value: VIEW_TYPES.LIST_VIEW },
    { label: VIEW_TYPE_OPTIONS_LABEL[VIEW_TYPES.DETAIL_VIEW], value: VIEW_TYPES.DETAIL_VIEW },
    { label: VIEW_TYPE_OPTIONS_LABEL[VIEW_TYPES.GRID_VIEW], value: VIEW_TYPES.GRID_VIEW },
    { label: VIEW_TYPE_OPTIONS_LABEL[VIEW_TYPES.FORM_VIEW], value: VIEW_TYPES.FORM_VIEW },
  ];
};

const getFilteredEntityForRelatedWidget = (entityDef) => {
  let entityNames = [];
  const entityRelationMetadataList = entityReader.entityRelationMetadataList(entityDef);
  entityNames = _map(entityRelationMetadataList, 'relatedEntityName');
  if (_isEmpty(entityNames)) {
    entityNames.push('');
  }
  return entityNames;
};

const getFilteredEntityForChildWidget = (entityDef) => {
  let entityNames = [];
  const fieldDefinitions = entityReader.fieldDefinitions(entityDef);
  entityNames = _reduce(
    fieldDefinitions,
    (result, fieldDef) => {
      if (fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.RELATIONSHIP) {
        result.push(fieldDefinitionReader.lookupFieldEntityType(fieldDef));
      }

      return result;
    },
    [],
  );
  if (_isEmpty(entityNames)) {
    entityNames.push('');
  }
  return entityNames;
};

const getFilteredEntityForSelectedWidgetType = (widgetType, entityDef) => {
  let entityNames = [];

  switch (widgetType) {
    case WIDGET_TYPES.CHILD_WIDGET:
      entityNames = getFilteredEntityForRelatedWidget(entityDef);
      break;
    case WIDGET_TYPES.PARENT_WIDGET:
      entityNames = getFilteredEntityForChildWidget(entityDef);
      break;
    default: {
      break;
    }
  }

  return entityNames;
};

const getFilteredRelativeFieldForRelatedWidget = (entityDef, entityName) => {
  let relativeFieldsNameOptions = [];
  const entityRelationMetadataList = entityReader.entityRelationMetadataList(entityDef);

  relativeFieldsNameOptions = _reduce(
    entityRelationMetadataList,
    (result, entityRelationMetadata) => {
      if (tget(entityRelationMetadata, 'relatedEntityName') === entityName) {
        result.push({ label: tget(entityRelationMetadata, 'relatedField'), value: tget(entityRelationMetadata, 'relatedField') });
      }

      return result;
    },
    [],
  );
  return relativeFieldsNameOptions;
};

const getFilteredRelativeFieldForChildWidget = (entityDef, entityName) => {
  let relativeFieldsNameOptions = [];
  const fieldDefinitions = entityReader.fieldDefinitions(entityDef);
  relativeFieldsNameOptions = _reduce(
    fieldDefinitions,
    (result, fieldDef) => {
      if (fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.RELATIONSHIP) {
        if (fieldDefinitionReader.lookupFieldEntityType(fieldDef) === entityName) {
          result.push({ label: fieldDefinitionReader.displayName(fieldDef), value: fieldDefinitionReader.name(fieldDef) });
        }
      }

      return result;
    },
    [],
  );
  return relativeFieldsNameOptions;
};

const getFilteredRelativeFieldForSimilarWidget = (entityDef) => {
  let relativeFieldsNameOptions = [];
  const fieldDefinitions = entityReader.fieldDefinitions(entityDef);
  relativeFieldsNameOptions = _map(fieldDefinitions, (fieldDef) => ({
    label: fieldDefinitionReader.displayName(fieldDef),
    value: fieldDefinitionReader.name(fieldDef),
  }));
  return relativeFieldsNameOptions;
};

const getFilteredRelativeFieldForSelectedEntity = (entityName, widgetType, entityDef) => {
  let relativeFieldsNameOptions = [];

  switch (widgetType) {
    case WIDGET_TYPES.CHILD_WIDGET:
      relativeFieldsNameOptions = getFilteredRelativeFieldForRelatedWidget(entityDef, entityName);
      break;
    case WIDGET_TYPES.PARENT_WIDGET:
      relativeFieldsNameOptions = getFilteredRelativeFieldForChildWidget(entityDef, entityName);
      break;

    case WIDGET_TYPES.SIMILAR_WIDGET:
      relativeFieldsNameOptions = getFilteredRelativeFieldForSimilarWidget(entityDef);
      break;

    default: {
      break;
    }
  }

  return relativeFieldsNameOptions;
};

export {
  getOptions,
  getPayload,
  getFormFormattedSelectedWidgetProperties,
  getConfigFormattedSelectedWidgetProperties,
  getViewTypeOptions,
  getFilteredEntityForSelectedWidgetType,
  getFilteredRelativeFieldForSelectedEntity,
};
