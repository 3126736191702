import React, { useCallback } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent';

import withSize from '../../../../../connectors/withSize';
import tableProps from './constants/actionBuilder.data';
import ACTION_HANDLERS from './helpers/actionBuilderList.actionHandlers';
import { CURRENT_PAGE, CUSTOM_ACTIONS, INITIAL_STATE, RESULTS_PER_PAGE } from './constants/actionBuilderList.constants';

const { getColumns, TABLE_MANAGER_PROPS, getTableProps, getSubHeaderProps, getFilterProps } = tableProps;

const ActionBuilderList = ({
  loading,
  showModal,
  contentHeight,
  searchText,
  modalContent,
  currentPage,
  pageSize,
  nextPageToken,
  actionBuilderList,
  selectedFilters,
  onAction,
}) => {
  const handleRowAction = useCallback(
    (actionType, value) => {
      onAction({
        type: actionType,
        payload: {
          value,
        },
      });
    },
    [onAction],
  );

  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({
      type: CUSTOM_ACTIONS.CLOSE_MODAL_REQUEST_DIALOG,
    });
  }, [onAction]);

  const handleConfirmationDialogSubmit = useCallback(() => {
    onAction({ type: CUSTOM_ACTIONS.CONFIRM_MODAL_REQUEST });
  }, [onAction]);

  const subHeaderProps = getSubHeaderProps();
  const filterProps = getFilterProps();

  return (
    <Page>
      <Page.Body style={{ height: contentHeight }}>
        <TableManager
          isLoaded
          disableHeight={false}
          columns={getColumns()}
          loading={loading}
          data={actionBuilderList}
          subHeaderProps={subHeaderProps}
          tableProps={getTableProps(handleRowAction, currentPage, loading, pageSize, actionBuilderList.length)}
          tableManagerProps={TABLE_MANAGER_PROPS}
          onAction={onAction}
          searchText={searchText}
          searchPlaceholder={__('Enter Name')}
          filterProps={filterProps}
          selectedFilters={selectedFilters}
          tokenPagination
          nextPageToken={nextPageToken}
        />
        <PropertyControlledComponent controllerProperty={showModal}>
          <ConfirmationDialog
            isVisible={showModal}
            onSubmit={handleConfirmationDialogSubmit}
            onCancel={handleConfirmationDialogCancel}
            content={__(`${modalContent}`)}
          />
        </PropertyControlledComponent>
      </Page.Body>
    </Page>
  );
};

ActionBuilderList.propTypes = {
  loading: PropTypes.bool,
  showModal: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  modalContent: PropTypes.string,
  nextPageToken: PropTypes.string,
  searchText: PropTypes.string,
  actionBuilderList: PropTypes.array,
  selectedFilters: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ActionBuilderList.defaultProps = {
  loading: false,
  showModal: false,
  currentPage: CURRENT_PAGE,
  pageSize: RESULTS_PER_PAGE,
  modalContent: EMPTY_STRING,
  nextPageToken: EMPTY_STRING,
  searchText: EMPTY_STRING,
  actionBuilderList: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
};

export default compose(withActions(INITIAL_STATE, ACTION_HANDLERS), withSize({ hasPageHeader: true }))(ActionBuilderList);
