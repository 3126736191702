import _map from 'lodash/map';
import _castArray from 'lodash/castArray';

import { defaultMemoize } from 'reselect';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import entityReader from '../../../../readers/entity.reader';

const ROW_COUNT = 20;

const getHasMore = (nextPageToken) => nextPageToken !== null;

const getPayload = defaultMemoize((nextPageToken, searchText = '', filters = []) => ({
  filters: [...filters, { field: 'displayName', filterType: OPERATORS.TEXT_STARTS_WITH, values: _castArray(searchText) }],
  rows: ROW_COUNT,
  nextPageToken,
}));

const getTargetEntityOptions = defaultMemoize((entities) =>
  _map(entities, (entity) => ({
    label: entityReader.displayName(entity),
    value: entityReader.name(entity),
  })),
);

const getDefaultFilters = defaultMemoize(() => [
  {
    field: 'complexField',
    values: [false],
    filterType: OPERATORS.IN,
  },
]);

const entityOption = (val, displayName) => ({ value: val, label: displayName });

export { getHasMore, getPayload, getTargetEntityOptions, getDefaultFilters, entityOption };
