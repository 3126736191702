import queryString from 'query-string';
import produce from 'immer';

import _get from 'lodash/get';
import _head from 'lodash/head';
import _split from 'lodash/split';
import _some from 'lodash/some';
import _includes from 'lodash/includes';
import _set from 'lodash/set';

import { hasUpperCaseChar, hasLowerCaseChar, hasNumberRule } from '@tekion/tekion-base/utils/formValidators';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';

import { getValueFromLocalStorage } from '../../../../utils/localStorage';
import { FIELD_NAMES, PASSWORD_POLICY_DEFAULTS, UNLOCK_ACCOUNT_PARAMS_VALUE } from './updatePasswordPage.constants';
import LS_CACHE_KEYS from '../../../../constants/localStorage.cacheKeys';
import { LOGIN_PAGE_READER } from '../LoginPage/LoginPage.constants';
import { betweenLengthRule } from '../../../../utils/formValidators';

const getUpdatedPayload = (newPassword) => {
  try {
    const token = getValueFromLocalStorage(LS_CACHE_KEYS.TOKEN);
    const email = LOGIN_PAGE_READER.email(JSON.parse(getValueFromLocalStorage(LS_CACHE_KEYS.USER)));
    return {
      email,
      token,
      newPassword,
    };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Something went wrong. Please try again later')));
  }
  return {};
};

const isUnlockAccountPage = (history) => {
  const search = _get(history, 'location.search', '');
  const locationSearchObject = queryString.parse(search);
  const { unlockAccount = '' } = locationSearchObject;
  return unlockAccount === UNLOCK_ACCOUNT_PARAMS_VALUE;
};

const getValidatePayload = (history) => {
  const search = _get(history, 'location.search', '');
  const locationSearchObject = queryString.parse(search);
  const { token = '' } = locationSearchObject;
  return {
    token,
    unlockAccount: isUnlockAccountPage(history),
  };
};

const isEqualRule = (value, newPassword) => {
  const areEqual = value === newPassword;
  if (areEqual) return undefined;
  return __('Passwords does not match');
};

const hasSpecialCharacters = (fieldId, valueToTest) => {
  const re = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const isValid = re.test(valueToTest);
  return isValid ? { isValid } : { isValid, message: __('Should contain atleast one special character') };
};

const getEmailSubstrings = (email) => {
  const userInfoInEmail = _head(_split(email, '@')) || EMPTY_STRING;
  const emailSubStrings = [];
  for (let i = 0; i < userInfoInEmail.length - 2; i += 1)
    for (let length = 3; length <= userInfoInEmail.length && length <= userInfoInEmail.length - i; length += 1) {
      emailSubStrings.push(userInfoInEmail.substr(i, length));
    }
  return emailSubStrings;
};

const hasUserInfo = (fieldId, valueToTest, emailSubstrings) => {
  const isValid = !_some(emailSubstrings, (emailPart) => _includes(valueToTest, emailPart));
  return isValid ? { isValid } : { isValid, message: __('Should not contain user info') };
};

const validateForm = (values, prevErrors = EMPTY_OBJECT) => {
  const newPassword = _get(values, `${FIELD_NAMES.NEW_PASSWORD}`, '');
  const confirmNewPassword = _get(values, `${FIELD_NAMES.CONFIRM_NEW_PASSWORD}`, '');

  return produce(prevErrors, (draft) => {
    if (!newPassword && !confirmNewPassword) return;
    _set(draft, FIELD_NAMES.CONFIRM_NEW_PASSWORD, isEqualRule(confirmNewPassword, newPassword));
    _set(draft, FIELD_NAMES.UPPERCASE_CHAR, hasUpperCaseChar('', newPassword).message);
    _set(draft, FIELD_NAMES.LOWERCASE_CHAR, hasLowerCaseChar('', newPassword).message);
    _set(draft, FIELD_NAMES.NUMBER, hasNumberRule('', newPassword).message);
    _set(draft, FIELD_NAMES.SYMBOL, hasSpecialCharacters('', newPassword).message);
    _set(
      draft,
      FIELD_NAMES.CHAR_COUNT,
      betweenLengthRule(PASSWORD_POLICY_DEFAULTS.MIN_LENGTH, PASSWORD_POLICY_DEFAULTS.MAX_LENGTH, newPassword).message,
    );
  });
};

export {
  validateForm,
  hasNumberRule,
  hasUserInfo,
  getEmailSubstrings,
  hasSpecialCharacters,
  getValidatePayload,
  getUpdatedPayload,
  isEqualRule,
  isUnlockAccountPage,
};
