import SingleSelect from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SingleSelect';
import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';
import Checkbox from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';

import { isRequiredRule, isNumericRule, isPositiveNumber } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import AsyncPaginatedSelect from '../../../../../../organisms/asyncPaginatedSelect/AsyncPaginatedSelect';
import MediaUploaderFormRenderer from '../../../../../../organisms/mediaUploader/MediaUploaderFormRenderer';
import FieldMappingInputTable from '../components/fieldMappingInputTable/FieldMappingInputTable';

import { getAsyncSelectPayload, getOptions, handleFetchEntities } from './dataImportForm.helpers';

import { FIELD_IDS, OPERATION_TYPES, OPERATION_TYPE_OPTIONS, REPORTS_NAME_OPTIONS } from '../constants/dataImportForm.constants';

import styles from '../dataImportForm.module.scss';

const OPERATION_TYPE_FIELD = {
  id: FIELD_IDS.OPERATION_TYPE,
  renderer: SingleSelect,
  renderOptions: {
    id: FIELD_IDS.PROVIDER_TYPE,
    required: true,
    label: __('Action Type'),
    options: OPERATION_TYPE_OPTIONS,
    validators: [isRequiredRule],
  },
};

const ENTITY_SELECT_FIELD = {
  id: FIELD_IDS.ENTITY_NAME,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    isMulti: false,
    label: __('Select Entity'),
    serviceHandler: handleFetchEntities,
    getOptions,
    getPayload: getAsyncSelectPayload,
  },
};

const MATCH_BY_FIELD = {
  id: FIELD_IDS.FIELD_TO_MATCH,
  renderer: SingleSelect,
  renderOptions: {
    id: FIELD_IDS.FIELD_TO_MATCH,
    required: true,
    label: __('Match By'),
    validators: [isRequiredRule],
  },
};

const MEDIA_ID_FIELD = {
  id: FIELD_IDS.MEDIA_ID,
  renderer: MediaUploaderFormRenderer,
  renderOptions: {
    label: __('File (CSV)'),
    validFormats: '.xls,.xlsx,.csv',
    className: styles.mediaUploaderFormRenderer,
  },
};

const REPORTS_NAME_FIELD = {
  id: FIELD_IDS.REPORTS_NAME,
  renderer: SingleSelect,
  renderOptions: {
    id: FIELD_IDS.REPORTS_NAME,
    required: true,
    label: __('Reports Name'),
    options: REPORTS_NAME_OPTIONS,
    validators: [isRequiredRule],
  },
};

const BATCH_SIZE_FIELD = {
  id: FIELD_IDS.BATCH_SIZE,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Batch Size'),
    placeholder: __('Enter batch size '),
    required: true,
    validators: [isRequiredRule, isNumericRule, isPositiveNumber],
  },
};

const SEQUENTIAL_FIELD = {
  id: FIELD_IDS.SEQUENTIAL,
  renderer: Checkbox,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Sequential'),
  },
};

const UNIQUE_DATA_FIELD = {
  id: FIELD_IDS.UNIQUE_DATA,
  renderer: Checkbox,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Unique Data'),
  },
};

const FIELD_MAPPINGS_INPUT_TABLE_FIELD = {
  id: FIELD_IDS.FIELD_MAPPING,
  renderer: FieldMappingInputTable,
};

const DATA_IMPORT_FORM_FIELDS = {
  [FIELD_IDS.OPERATION_TYPE]: OPERATION_TYPE_FIELD,
  [FIELD_IDS.FIELD_TO_MATCH]: MATCH_BY_FIELD,
  [FIELD_IDS.ENTITY_NAME]: ENTITY_SELECT_FIELD,
  [FIELD_IDS.MEDIA_ID]: MEDIA_ID_FIELD,
  [FIELD_IDS.REPORTS_NAME]: REPORTS_NAME_FIELD,
  [FIELD_IDS.BATCH_SIZE]: BATCH_SIZE_FIELD,
  [FIELD_IDS.SEQUENTIAL]: SEQUENTIAL_FIELD,
  [FIELD_IDS.UNIQUE_DATA]: UNIQUE_DATA_FIELD,
  [FIELD_IDS.FIELD_MAPPING]: FIELD_MAPPINGS_INPUT_TABLE_FIELD,
};

const getFields = (isLoadingFieldMapping, operationType, entityFieldOptions, columnDefinitions) => ({
  ...DATA_IMPORT_FORM_FIELDS,
  [FIELD_IDS.FIELD_TO_MATCH]: addToRenderOptions(DATA_IMPORT_FORM_FIELDS[FIELD_IDS.FIELD_TO_MATCH], [
    { path: 'options', value: entityFieldOptions },
    {
      path: 'label',
      value: operationType === OPERATION_TYPES.CREATE ? __('Unique Field') : __('Match By'),
    },
  ]),
  [FIELD_IDS.FIELD_MAPPING]: addToRenderOptions(DATA_IMPORT_FORM_FIELDS[FIELD_IDS.FIELD_MAPPING], [
    { path: 'isLoadingFieldMapping', value: isLoadingFieldMapping },
    { path: 'fieldNameOptions', value: entityFieldOptions },
    { path: 'columnDefinitions', value: columnDefinitions },
  ]),
});

export default getFields;
