import React from 'react';
import PropTypes from 'prop-types';

import { List } from 'antd';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import Button from '@tekion/tekion-components/atoms/Button';
import Icon from '@tekion/tekion-components/atoms/FontIcon/Icon';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Dropdown from '@tekion/tekion-components/molecules/DropDown';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import ListItemAdd from './components/listItemAdd/ListItemAdd';

import { WIDGET_TYPES } from '../../../../../../organisms/reporting/molecules/widgetContainer/constants/widgetContainer.general.constants';

import styles from './rightPanel.module.scss';

const RightPanel = (props) => {
  const { data, loading, onAction, addWidgetDropdownMenu } = props;

  const renderWidgetItem = (item) => (
    <List.Item actions={[<ListItemAdd onAction={onAction} widgetName={item.name} />]}>
      <List.Item.Meta
        className="align-items-center"
        avatar={<FontIcon size={SIZES.XL}>{item.widgetType === WIDGET_TYPES.DATA_TABLE ? 'icon-table' : 'icon-pie-chart'}</FontIcon>}
        title={item.title}
        description={item.widgetType}
      />
    </List.Item>
  );

  return (
    <div className={styles.container}>
      <Page className="full-height full-width">
        <Page.Header contentClassName={styles.content} className={styles.headerContainer}>
          <Heading size={3}>{__('Widgets')}</Heading>
          <div className={styles.createWidgetContainer}>
            <Dropdown overlay={addWidgetDropdownMenu}>
              <Button view={Button.VIEW.PRIMARY} className={styles.createWidgetButton}>
                <Icon size={Icon.SIZES.MD} className>
                  icon-add2
                </Icon>
                <span>{__('New Widget')}</span>
              </Button>
            </Dropdown>
          </div>
        </Page.Header>
        <Page.Body className="full-width">
          <List itemLayout="horizontal" loading={loading} dataSource={data} renderItem={(item) => renderWidgetItem(item)} />
        </Page.Body>
      </Page>
    </div>
  );
};

RightPanel.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  addWidgetDropdownMenu: PropTypes.node,
  onAction: PropTypes.func.isRequired,
};

RightPanel.defaultProps = {
  loading: false,
  data: EMPTY_ARRAY,
  addWidgetDropdownMenu: null,
};

export default React.memo(RightPanel);
