import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Loader from '@tekion/tekion-components/molecules/loader';
import Content from '@tekion/tekion-components/atoms/Content';
import { tget } from '@tekion/tekion-base/utils/general';

import PageViewer from '../../../../../../../../../../organisms/visualBuilder/organisms/pageViewer/PageViewer';

import styles from './applicationPreviewTabContent.module.scss';

const ApplicationPreviewTabContent = ({ contentHeight, contentWidth, tabInfo, style, history, applicationContext }) => {
  const pageConfiguration = tget(tabInfo, 'pageConfiguration', EMPTY_OBJECT);

  const renderWithoutTabInfo = useCallback(
    () => (
      <div className={styles.tabContent} style={style}>
        <Content colorVariant={Content.COLOR_VARIANTS.GREY} className={styles.contentText}>
          {__('No Page added')}
        </Content>
      </div>
    ),
    [style],
  );

  const renderWithTabInfo = useCallback(() => {
    if (_isEmpty(pageConfiguration)) return <Loader />;
    else
      return (
        <div className={styles.viewViewer} style={style}>
          <PageViewer
            isPreviewMode
            contentWidth={contentWidth}
            contentHeight={contentHeight}
            history={history}
            pageConfiguration={pageConfiguration}
            applicationContext={applicationContext}
          />
        </div>
      );
  }, [pageConfiguration, style, applicationContext, contentWidth, contentHeight, history]);

  return _isEmpty(_get(tabInfo, 'pageName')) ? renderWithoutTabInfo() : renderWithTabInfo();
};

ApplicationPreviewTabContent.propTypes = {
  contentHeight: PropTypes.number.isRequired,
  contentWidth: PropTypes.number.isRequired,
  tabInfo: PropTypes.object,
  history: PropTypes.object,
  style: PropTypes.object,
  applicationContext: PropTypes.object,
};

ApplicationPreviewTabContent.defaultProps = {
  tabInfo: EMPTY_OBJECT,
  history: EMPTY_OBJECT,
  style: EMPTY_OBJECT,
  applicationContext: EMPTY_OBJECT,
};
export default React.memo(ApplicationPreviewTabContent);
