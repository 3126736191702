import _reduce from 'lodash/reduce';
import _set from 'lodash/set';

import ALL_SYSTEM_DEFINED_FIELDS from '../../../constants/systemDefinedFields';
import { CUSTOM_STYLE_IDS } from '../../../constants/customStyles.constants';

const VIEW_TYPES = {
  DETAIL_VIEW: 'DETAIL_VIEW',
  LIST_VIEW: 'LIST_VIEW',
  FORM_VIEW: 'FORM_VIEW',
  GRID_VIEW: 'GRID_VIEW',
  CUSTOM_VIEW: 'CUSTOM_VIEW',
  CELL_VIEW: 'CELL_VIEW',
  RIGHT_PANE_VIEW: 'RIGHT_PANE_VIEW',
  RECORD_TYPE_SELECTION_VIEW: 'RECORD_TYPE_SELECTION_VIEW',
};

const COMPONENT_TYPES = {
  COMPONENT: 'COMPONENT',
  VIEW_COMPONENT: 'VIEW_COMPONENT',
  SECTION: 'SECTION',
  COMMENT: 'COMMENT',
  SECTION_COLUMN: 'SECTION_COLUMN',
  DETAIL_VIEW_ATTRIBUTE: 'DETAIL_VIEW_ATTRIBUTE',
  RIGHT_PANE_VIEW_ATTRIBUTE: 'RIGHT_PANE_VIEW_ATTRIBUTE',
  LIST_VIEW_RENDERER: 'LIST_VIEW_RENDERER',
  LIST_VIEW_COLUMN: 'LIST_COLUMN',
  FORM_VIEW_RENDERER: 'FORM_VIEW_RENDERER',
  FORM_VIEW_SECTION: 'FORM_SECTION',
  FORM_VIEW_ROW: 'FORM_ROW',
  FORM_VIEW_COLUMN: 'FORM_COLUMN',
  GRID_VIEW_ATTRIBUTE: 'GRID_VIEW_ATTRIBUTE',
  GRID_VIEW: 'GRID_VIEW_RENDERER',
  ROW: 'ROW',
  COLUMN: 'COLUMN',
  CELL_VIEW_ATTRIBUTE: 'CELL_VIEW_ATTRIBUTE',
  IMAGE: 'IMAGE',
  BUTTON: 'BUTTON',
};

const SYSTEM_DEFINED_FIELDS = [
  ALL_SYSTEM_DEFINED_FIELDS.ID,
  ALL_SYSTEM_DEFINED_FIELDS.CREATED_TIME,
  ALL_SYSTEM_DEFINED_FIELDS.MODIFIED_TIME,
  ALL_SYSTEM_DEFINED_FIELDS.CREATED_BY,
  ALL_SYSTEM_DEFINED_FIELDS.LAST_MODIFIED_BY,
];

const VIEW_SUMMARY_FIELD_IDS = {
  VIEW_NAME: 'name',
  VIEW_DESCRIPTION: 'description',
  VIEW_TYPE: 'viewType',
  COLUMN_TABLE: 'columns',
  FILTERS: 'FILTERS',
  COLUMN_FORM_LAYOUT_FIELD: 'COLUMN_FORM_LAYOUT_FIELD',
  COLUMN_FORM_FIELDS: 'COLUMN_FORM_FIELDS',
  LIST_VIEW_ROW_ACTION_CLICK: 'LIST_VIEW_ROW_ACTION_CLICK',
  APPLICABLE_RECORD_TYPES: 'applicableRecordTypes',
};

const ICONS = [
  'icon-phone-filled',
  'icon-email-filled',
  'icon-profile',
  'icon-caret-up',
  'icon-caret-down',
  'icon-clock-in',
  'icon-mark-bad',
  'icon-mark-good',
  'icon-bookmark',
  'icon-bookmark-filled',
  'icon-comment',
  'icon-share',
  'icon-redirect',
];

const NON_DRAGGABLE_COMPONENTS = [
  COMPONENT_TYPES.SECTION_COLUMN,
  COMPONENT_TYPES.FORM_VIEW_RENDERER,
  COMPONENT_TYPES.LIST_VIEW_RENDERER,
  COMPONENT_TYPES.COLUMN,
];

const DEFAULT_WIDTH = 100;
const DEFAULT_COLUMN_SPAN_VALUE = 14;
const DEFAULT_TAB_KEY = 'properties';

const TEXT_INPUT_TYPES = {
  TEXT_AREA: 'TEXT_AREA',
  TEXT_INPUT: 'TEXT_INPUT',
};

const BOOL_INPUT_TYPES = {
  CHECKBOX: 'CHECKBOX',
  SWITCH: 'SWITCH',
};

const RICH_TEXT_EDITOR_STYLES = {
  borderBottom: '0.1rem solid gray',
  padding: '1.2rem 0',
  minHeight: 'fit-content',
  position: 'sticky',
  top: '0',
  zIndex: '2',
};

const VIEW_CONTEXT_KEYS = {
  VIEW_CONTEXT: 'viewContext',
  WIDGET_CONTEXT: 'widgetContext',
  APPLICATION_CONTEXT: 'applicationContext',
};

const COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP = _reduce(
  COMPONENT_TYPES,
  (result, value) => {
    switch (value) {
      case COMPONENT_TYPES.SECTION: {
        _set(result, [COMPONENT_TYPES.SECTION], [CUSTOM_STYLE_IDS.CONTAINER, CUSTOM_STYLE_IDS.TITLE]);
        break;
      }

      case COMPONENT_TYPES.CELL_VIEW_ATTRIBUTE: {
        _set(result, [COMPONENT_TYPES.CELL_VIEW_ATTRIBUTE], [CUSTOM_STYLE_IDS.CONTENT, CUSTOM_STYLE_IDS.CONTAINER]);
        break;
      }

      case COMPONENT_TYPES.DETAIL_VIEW_ATTRIBUTE: {
        _set(result, [COMPONENT_TYPES.DETAIL_VIEW_ATTRIBUTE], [CUSTOM_STYLE_IDS.CONTENT, CUSTOM_STYLE_IDS.ROW]);
        break;
      }

      case COMPONENT_TYPES.GRID_VIEW: {
        _set(result, [COMPONENT_TYPES.GRID_VIEW], [CUSTOM_STYLE_IDS.CARD_STYLE]);
        break;
      }

      case COMPONENT_TYPES.LIST_VIEW_RENDERER: {
        _set(result, [COMPONENT_TYPES.LIST_VIEW_RENDERER], [CUSTOM_STYLE_IDS.ROW]);
        break;
      }

      default: {
        _set(result, [value], [CUSTOM_STYLE_IDS.CONTAINER]);
      }
    }

    return result;
  },
  {},
);

const MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE = {
  [COMPONENT_TYPES.COLUMN]: [COMPONENT_TYPES.ROW, COMPONENT_TYPES.CELL_VIEW_ATTRIBUTE, COMPONENT_TYPES.IMAGE, COMPONENT_TYPES.BUTTON],
  [COMPONENT_TYPES.FORM_VIEW_SECTION]: [COMPONENT_TYPES.FORM_VIEW_ROW],
  [COMPONENT_TYPES.FORM_VIEW_ROW]: [COMPONENT_TYPES.FORM_VIEW_COLUMN],
  [COMPONENT_TYPES.SECTION_COLUMN]: [
    COMPONENT_TYPES.SECTION,
    COMPONENT_TYPES.DETAIL_VIEW_ATTRIBUTE,
    COMPONENT_TYPES.FORM_VIEW_RENDERER,
    COMPONENT_TYPES.COMMENT,
    COMPONENT_TYPES.BUTTON,
  ],
};

export {
  VIEW_TYPES,
  COMPONENT_TYPES,
  SYSTEM_DEFINED_FIELDS,
  ICONS,
  NON_DRAGGABLE_COMPONENTS,
  DEFAULT_WIDTH,
  DEFAULT_COLUMN_SPAN_VALUE,
  DEFAULT_TAB_KEY,
  VIEW_SUMMARY_FIELD_IDS,
  TEXT_INPUT_TYPES,
  RICH_TEXT_EDITOR_STYLES,
  VIEW_CONTEXT_KEYS,
  COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP,
  BOOL_INPUT_TYPES,
  MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE,
};
