import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _forEach from 'lodash/forEach';
import _isNil from 'lodash/isNil';
import _castArray from 'lodash/castArray';
import _valuesIn from 'lodash/valuesIn';
import _cloneDeep from 'lodash/cloneDeep';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { isErrorEmpty } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getFieldsFormPropertiesValues } from './propertiesForm.helpers';

import FIELDS_FORM_FIELD_IDS from '../../../constants/fieldsForm.fieldIds';
import PROPERTIES_FORM_FIELD_IDS from '../constants/propertiesForm.fieldIds';
import ACTION_TYPES from '../../../constants/fieldsForm.actionTypes';
import { FIELD_FORM_CONTEXT_ID } from '../../../constants/fieldsForm.constants';

const handleFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { values: oldValues = EMPTY_OBJECT, fieldsFormOnAction } = getState();
  const values = { ...oldValues, [id]: value };

  setState({ values }, () =>
    fieldsFormOnAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM, value: getFieldsFormPropertiesValues(values) },
    }),
  );
};

const handleRenderOptionsChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { renderOptions = EMPTY_OBJECT } = params;
  const { fieldsFormPropertiesValue = {}, fieldsFormOnAction } = getState();

  const sortDirection = _get(fieldsFormPropertiesValue, PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION);

  const values = {
    ...fieldsFormPropertiesValue,
    ...(!_isEmpty(sortDirection) && { [PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION]: _castArray(sortDirection) }),
  };

  let changed = false;
  _forEach(_valuesIn(PROPERTIES_FORM_FIELD_IDS), (fieldId) => {
    const value = _get(renderOptions, [fieldId, 'value']);

    if (!_isNil(value) && value !== _get(values, fieldId)) {
      if (_get(fieldsFormPropertiesValue, fieldId) !== value) {
        changed = true;
      }
      _set(values, fieldId, value);
      _set(fieldsFormPropertiesValue, fieldId, value);
    }
  });

  if (changed) {
    setState({ values }, () =>
      fieldsFormOnAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: { id: FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM, value: getFieldsFormPropertiesValues(fieldsFormPropertiesValue) },
      }),
    );
  }
};

const handleValidationSuccess = ({ getState, params = EMPTY_OBJECT }) => {
  const { fieldsFormOnAction, values } = getState();
  const { errors } = params;
  const sortable = _get(values, PROPERTIES_FORM_FIELD_IDS.SORTABLE, false);

  if (!sortable) {
    _unset(errors, PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION);
  }

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: {
      id: FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM,
      errors,
    },
  });
};

const handleSubmit = ({ getState, params = EMPTY_OBJECT }) => {
  const { additional = {} } = params;
  const { formErrors } = additional;
  const { fieldsFormOnAction } = getState();

  fieldsFormOnAction({ type: FORM_ACTION_TYPES.VALIDATION_SUCCESS, payload: { id: FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM, errors: undefined } });

  _set(formErrors, FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM, false);

  if (isErrorEmpty(formErrors)) {
    triggerSubmit(FIELD_FORM_CONTEXT_ID, _cloneDeep(additional));
  }
};

const PROPERTIES_FORM_ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [ACTION_TYPES.ON_RENDER_OPTIONS_CHANGE]: handleRenderOptionsChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidationSuccess,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
};

export default PROPERTIES_FORM_ACTION_HANDLERS;
