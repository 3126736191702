import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

// Validators
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { removeNonDigits } from '@tekion/tekion-base/formatters/number';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

// Components
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import EntityAsyncSelect from '../../../../../../../molecules/EntityAsyncSelect/EntityAsyncSelect';
import ConditionBuilder from '../../../../../../../../../organisms/conditionBuilder/ConditionBuilder';
import NumberWithSelect from '../../../customFormFieldRenderer/numberWithSelect';

// Helpers
import { isConditionRequiredRule } from '../../../../../../../../../organisms/conditionBuilder/helpers/conditionBuilder.general.helpers';
import { getTimeUnitOptions } from './delayTaskModal.helpers';

// Constants
import { DELAY_TASK_TAB_IDS } from '../constants/delayTaskModal.tabs';
import CONDITION_BUILDER_MODES from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.modes';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import DELAY_TASK_MODAL_FIELD_IDS from '../constants/delayTaskModal.fieldIds';
import { CONDITION_BUILDER_TYPES } from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';

import styles from '../delayTaskModal.module.scss';

const DELAY_TIME_FIELD = {
  id: DELAY_TASK_MODAL_FIELD_IDS.EXECUTION_DELAY,
  renderer: NumberWithSelect,
  renderOptions: {
    label: __('Delay Time By'),
    addonAfterOptions: getTimeUnitOptions([
      { displayName: 'Minute', id: 'MINUTES' },

      { displayName: 'Hour', id: 'HOURS' },

      { displayName: 'Day', id: 'DAYS' },
    ]),
    required: true,
    validators: [isRequiredRule],
    highlightedLabel: true,
    descriptionClassName: 'mb-3',
    fieldClassName: styles.minWidth100,
    parser: removeNonDigits,
    containerClassName: 'd-flex',
  },
};

const EVENT_NAME_FIELD = {
  id: DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Event name'),
    validators: [isRequiredRule],
  },
};

const EVENT_TYPE_FIELD = {
  id: DELAY_TASK_MODAL_FIELD_IDS.EVENT_TYPE,
  renderer: Select,
  renderOptions: {
    required: true,
    label: __('Event type'),
    validators: [isRequiredRule],
  },
};

const ENTITY_NAME_FIELD = {
  id: DELAY_TASK_MODAL_FIELD_IDS.ENTITY_NAME,
  renderer: EntityAsyncSelect,
  renderOptions: {
    required: true,
    label: __('Entity'),
    validators: [isRequiredRule],
  },
};
const WORKFLOW_FIELD = {
  id: DELAY_TASK_MODAL_FIELD_IDS.WORKFLOW,
  renderer: Select,
  renderOptions: {
    label: __('Call Workflow'),
    validators: [isRequiredRule],
  },
};

const FORM_FIELDS = {
  [DELAY_TASK_MODAL_FIELD_IDS.EXECUTION_DELAY]: DELAY_TIME_FIELD,
  [DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME]: EVENT_NAME_FIELD,
  [DELAY_TASK_MODAL_FIELD_IDS.EVENT_TYPE]: EVENT_TYPE_FIELD,
  [DELAY_TASK_MODAL_FIELD_IDS.ENTITY_NAME]: ENTITY_NAME_FIELD,
  [DELAY_TASK_MODAL_FIELD_IDS.WORKFLOW]: WORKFLOW_FIELD,
};

// Todo: Upgrade condition builder usage
const getFields = (eventTypes, conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, workflowOptions) => ({
  ...FORM_FIELDS,
  [DELAY_TASK_MODAL_FIELD_IDS.EVENT_TYPE]: addToRenderOptions(EVENT_TYPE_FIELD, {
    path: 'options',
    value: eventTypes,
  }),
  [DELAY_TASK_MODAL_FIELD_IDS.WORKFLOW]: addToRenderOptions(WORKFLOW_FIELD, {
    path: 'options',
    value: workflowOptions,
  }),
  [DELAY_TASK_MODAL_FIELD_IDS.CONDITION]: {
    id: DELAY_TASK_MODAL_FIELD_IDS.CONDITION,
    renderer: ConditionBuilder,
    renderOptions: {
      mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
      conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
      validators: [isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)],
      required: true,
      childProps: {
        [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
          childProps: {
            [CONDITION_FIELD_IDS.FIELD]: {
              label: __('Field'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
            },
            [CONDITION_FIELD_IDS.OPERATOR]: {
              label: __('Operator'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
            },
            [CONDITION_FIELD_IDS.VALUES]: {
              label: __('Value'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
            },
          },
        },
      },
    },
  },
});

const rowCheck = {
  [DELAY_TASK_MODAL_FIELD_IDS.CONDITION]: ({ entityName, isConditionFetching }) => !_isEmpty(entityName) && !isConditionFetching,
};

const TIME_DELAY_TAB_SECTIONS = [
  {
    className: styles.sectionContainer,
    header: {
      label: __('Set time delay'),
    },
    subHeader: {
      label: __('Define a specific time to delay the workflow till then'),
    },
    rows: [DELAY_TASK_MODAL_FIELD_IDS.EXECUTION_DELAY],
  },
];

const DELAY_EXIT_EVENT_TAB_SECTIONS = (entityName, isConditionFetching) => [
  {
    className: styles.sectionContainer,
    rows: [
      {
        columns: [DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME],
      },
      {
        columns: [DELAY_TASK_MODAL_FIELD_IDS.EVENT_TYPE, DELAY_TASK_MODAL_FIELD_IDS.ENTITY_NAME],
      },
      {
        columns: filterRows([DELAY_TASK_MODAL_FIELD_IDS.CONDITION], { entityName, isConditionFetching }, rowCheck),
      },
    ],
  },
];

const WORKFLOW_EXIT_EVENT_TAB_SECTIONS = (entityName, isConditionFetching) => [
  {
    className: styles.sectionContainer,
    rows: [
      {
        columns: [DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME],
      },
      {
        columns: [DELAY_TASK_MODAL_FIELD_IDS.EVENT_TYPE, DELAY_TASK_MODAL_FIELD_IDS.ENTITY_NAME],
      },
      {
        columns: [DELAY_TASK_MODAL_FIELD_IDS.WORKFLOW],
      },
      {
        columns: filterRows([DELAY_TASK_MODAL_FIELD_IDS.CONDITION], { entityName, isConditionFetching }, rowCheck),
      },
    ],
  },
];

const getSections = (tab, isConditionFetching) => {
  const { type, values } = tab;
  const entityName = getArraySafeValue(tget(values, DELAY_TASK_MODAL_FIELD_IDS.ENTITY_NAME, ['']));
  switch (type) {
    case DELAY_TASK_TAB_IDS.TIME_DELAY:
      return TIME_DELAY_TAB_SECTIONS;
    case DELAY_TASK_TAB_IDS.ADD_DELAY_EXIT_EVENT:
      return DELAY_EXIT_EVENT_TAB_SECTIONS(entityName, isConditionFetching);
    case DELAY_TASK_TAB_IDS.ADD_WORKFLOW_EXIT_EVENT:
      return WORKFLOW_EXIT_EVENT_TAB_SECTIONS(entityName, isConditionFetching);
    default:
      return EMPTY_ARRAY;
  }
};

export { getFields, getSections };
