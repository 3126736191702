import _set from 'lodash/set';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _reduce from 'lodash/reduce';
import _size from 'lodash/size';

import { tget } from '@tekion/tekion-base/utils/general';

const getValueToLabelMap = (options) =>
  _reduce(
    options,
    (prevValueToLabelMap, option) => {
      const newValueToLabelMap = { ...prevValueToLabelMap };
      const label = tget(option, 'label');
      const value = tget(option, 'value');

      _set(newValueToLabelMap, [value], label);

      return newValueToLabelMap;
    },
    {},
  );

const getUpdatedOptionsWithSortOverrideIndex = (options, values) => {
  if (_size(options) === _size(values)) {
    return options;
  }

  let index = 0;

  return _map(options, (option) => {
    const value = tget(option, 'value');

    if (_includes(values, value)) {
      return { ...option, sortOverrideIndex: index++ };
    }

    return option;
  });
};

export { getValueToLabelMap, getUpdatedOptionsWithSortOverrideIndex };
