const TABLE_MANAGER_PROPS = {
  showFilter: true,
  showHeader: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const USER_RESOLVER_COLUMN_NAME = {
  CREATED_BY: 'createdBy',
  LAST_MODIFIED_BY: 'lastModifiedBy',
};

export { TABLE_MANAGER_PROPS, USER_RESOLVER_COLUMN_NAME };
