import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';

import { STATUS } from '@tekion/tekion-base/constants/statuses';
import { tget } from '@tekion/tekion-base/utils/general';

import { APPROVAL_CENTRE_FIELD_IDS, APPROVAL_STATUS_LABELS, APPROVAL_STATUS_TYPES } from '../../../../../../../../constants/approvalCentre.constants';

const getStatusName = (status) => {
  let label = APPROVAL_STATUS_LABELS[STATUS.IN_ACTIVE];
  switch (status) {
    case APPROVAL_STATUS_TYPES.PENDING:
      label = APPROVAL_STATUS_LABELS[STATUS.PENDING];
      break;
    case APPROVAL_STATUS_TYPES.CANCELLED:
      label = APPROVAL_STATUS_LABELS[STATUS.CANCELLED];
      break;
    case APPROVAL_STATUS_TYPES.EXPIRED:
      label = APPROVAL_STATUS_LABELS[STATUS.EXPIRED];
      break;
    case APPROVAL_STATUS_TYPES.COMPLETED:
      label = APPROVAL_STATUS_LABELS[STATUS.COMPLETED];
      break;
    case APPROVAL_STATUS_TYPES.REJECTED:
      label = APPROVAL_STATUS_LABELS[STATUS.REJECTED];
      break;
    default:
      break;
  }
  return label;
};

const getApprovalStages = (approvalProcess, approvalTasks) => {
  const processStages = tget(approvalProcess, APPROVAL_CENTRE_FIELD_IDS.STAGES, []);
  const stagesWithTaskStatus = [];
  _forEach(processStages, (stage, index) => {
    const taskForStage = _find(approvalTasks, { [APPROVAL_CENTRE_FIELD_IDS.APPROVAL_LEVEL]: index });

    if (!_isEmpty(taskForStage)) {
      stagesWithTaskStatus.push({
        ...stage,
        [APPROVAL_CENTRE_FIELD_IDS.STATUS]: tget(taskForStage, APPROVAL_CENTRE_FIELD_IDS.STATUS),
      });
    }
  });

  return stagesWithTaskStatus;
};

export { getStatusName, getApprovalStages };
