import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _includes from 'lodash/includes';
import _last from 'lodash/last';
import _nth from 'lodash/nth';
import _isEmpty from 'lodash/isEmpty';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Tekion-components
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';

// Components
import NavigationItem from './components/navigationItem';

// Constants
import PAGE_IDS from '../../pages/devPlatform/constants/PageIds.constants';
import { STUDIO_ROUTE } from '../../constants/routes';
import {
  FIELDS_NAVIGATION_DEFAULT_DATA,
  VALIDATION_RULE_NAVIGATION_DEFAULT_DATA,
  VIEW_BUILDER_NAVIGATION_DEFAULT_DATA,
  ACTION_BUILDER_NAVIGATION_DEFAULT_DATA,
  VISIBLE_PATHS,
  RECORD_TYPE_NAVIGATION_DEFAULT_DATA,
} from './navigationFlow.constants';

// Styles
import styles from './navigationFlow.module.scss';

const NavigationFlow = (props) => {
  const { history, sanitizedPathName } = props;

  const isNavigationShown = useMemo(() => _includes(VISIBLE_PATHS, _last(sanitizedPathName)), [sanitizedPathName]);

  const getNavigationData = useCallback(() => {
    if (isNavigationShown) {
      const last = _last(sanitizedPathName);
      if (last === PAGE_IDS.FIELDS) {
        const entityName = _nth(sanitizedPathName, 3);
        const fieldsNavigationData = [...FIELDS_NAVIGATION_DEFAULT_DATA];
        fieldsNavigationData.push({ label: __(entityName) });
        return fieldsNavigationData;
      }
      if (last === PAGE_IDS.VALIDATION_RULE_BUILDER) {
        const entityName = _nth(sanitizedPathName, 3);
        const validationRuleBuilderData = [...VALIDATION_RULE_NAVIGATION_DEFAULT_DATA];
        validationRuleBuilderData.push({
          label: __(entityName),
          goTo: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELDS}`,
          key: '2',
        });
        validationRuleBuilderData.push({ label: __('Validation Rule Builder'), key: '3' });
        return validationRuleBuilderData;
      }
      if (last === PAGE_IDS.VIEW_BUILDER) {
        const entityName = _nth(sanitizedPathName, 3);
        const viewBuilderData = [...VIEW_BUILDER_NAVIGATION_DEFAULT_DATA];
        viewBuilderData.push({ label: __(entityName), goTo: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELDS}`, key: '2' });
        viewBuilderData.push({ label: __('View Builder'), key: '3' });
        return viewBuilderData;
      }
      if (last === PAGE_IDS.ACTION_BUILDER) {
        const entityName = _nth(sanitizedPathName, 3);
        const actionBuilderData = [...ACTION_BUILDER_NAVIGATION_DEFAULT_DATA];
        actionBuilderData.push({ label: __(entityName), goTo: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELDS}`, key: '2' });
        actionBuilderData.push({ label: __('Action Builder'), key: '3' });
        return actionBuilderData;
      }
      if (last === PAGE_IDS.RECORD_TYPE) {
        const entityName = _nth(sanitizedPathName, 3);
        const recordTypeData = [...RECORD_TYPE_NAVIGATION_DEFAULT_DATA];
        recordTypeData.push({ label: __(entityName), goTo: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELDS}`, key: '2' });
        recordTypeData.push({ label: __('Record Type'), key: '3' });
        return recordTypeData;
      }
    }
    return EMPTY_ARRAY;
  }, [sanitizedPathName, isNavigationShown]);

  const handleNavigationItemClick = useCallback(
    (goTo) => {
      if (!_isEmpty(goTo)) {
        history.push(goTo);
      }
    },
    [history],
  );

  const navigationData = useMemo(() => getNavigationData(), [getNavigationData]);

  const createNavigationFlow = useCallback(
    () =>
      _map(navigationData, (data, index) => (
        <NavigationItem
          key={_get(data, 'key', EMPTY_STRING)}
          itemNumber={index}
          totalItems={_size(navigationData)}
          data={data}
          onNavigationItemClick={handleNavigationItemClick}
        />
      )),
    [navigationData, handleNavigationItemClick],
  );

  return (
    <PropertyControlledComponent controllerProperty={isNavigationShown}>
      <Page.Header>
        <Heading className={styles.container} size={2}>
          {createNavigationFlow()}
        </Heading>
      </Page.Header>
    </PropertyControlledComponent>
  );
};

NavigationFlow.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  sanitizedPathName: PropTypes.array,
};

NavigationFlow.defaultProps = {
  sanitizedPathName: EMPTY_ARRAY,
};

export default NavigationFlow;
