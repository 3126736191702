import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _slice from 'lodash/slice';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import Tag from '@tekion/tekion-components/atoms/Tag';

import styles from './tagsWithLimit.module.scss';

// TODO: Wrap + tag with popover so remaining values can be shown

const TagsWithLimit = ({ label, maxLimit, values }) => (
  <div>
    <FieldLabel label={label} />
    <div className={styles.flewRow}>
      {_size(values) <= maxLimit ? (
        _map(values, (value) => <Tag key={value}>{value}</Tag>)
      ) : (
        <div className={styles.flexRow}>
          {_map(_slice(values, 0, maxLimit), (value) => (
            <Tag key={value}>{value}</Tag>
          ))}
          <Tag className={styles.plusStyle}>+{_size(values) - maxLimit}</Tag>
        </div>
      )}
    </div>
  </div>
);

TagsWithLimit.propTypes = {
  label: PropTypes.string,
  maxLimit: PropTypes.number,
  values: PropTypes.array,
};

TagsWithLimit.defaultProps = {
  label: EMPTY_STRING,
  maxLimit: 0,
  values: EMPTY_ARRAY,
};

export default TagsWithLimit;
