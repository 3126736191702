import RELATIONSHIP_DETAILS_FORM_FIELD_IDS from './relationshipDetailsForm.fieldIds';

import styles from '../../../fieldsForm.module.scss';

const RELATIONSHIP_DETAILS_FORM_SECTIONS = [
  {
    header: {
      label: __('Relationship details'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [
          RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY,
          RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP,
          RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_DISPLAY,
        ],
      },
      {
        columns: [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.GROUPS_ALLOWED],
      },
    ],
  },
];

export default RELATIONSHIP_DETAILS_FORM_SECTIONS;
