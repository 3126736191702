import _isFunction from 'lodash/isFunction';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _find from 'lodash/find';
import _set from 'lodash/set';
import _forEach from 'lodash/forEach';
import _concat from 'lodash/concat';

import { tget } from '@tekion/tekion-base/utils/general';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import generateVariableMapForNode from '../../../../../utils/generateVariableMapForNode';
import { loadFieldDefinitions } from '../../../../../pages/workflowBuilder/workflowBuilder.actionHandlers/helperHandlers';
import DELAY_TASK_ACTION_TYPES from '../../constants/delayTaskModal.actionTypes';
import DELAY_TASK_MODAL_FIELD_IDS from '../../constants/delayTaskModal.fieldIds';
import { CURRENT_ENTITY_NAMESPACE } from '../../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import { ASSIGNED_VARIABLE_FIELD_IDS } from '../../../../../constants/workflow.assignVariableConstants';

const handleFieldChange = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { selectedTabId, tabsList, variablesByNodeId, variablesInfo, stepDetails } = getState();
  const { id, value } = params;

  const tab = _find(tabsList, { id: selectedTabId });
  const tabIndex = _findIndex(tabsList, { id: selectedTabId });
  const { values } = tab;

  const updatedTab = {
    ...tab,
    values: {
      ...values,
      [id]: value,
    },
  };

  if (id === DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME) {
    _set(updatedTab, DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME, value);
  }

  if (id === DELAY_TASK_MODAL_FIELD_IDS.ENTITY_NAME) {
    _set(updatedTab, `values.${DELAY_TASK_MODAL_FIELD_IDS.CONDITION}`, []);
  }

  const updatedTabList = _isNil(tabsList) ? [] : [...tabsList];
  updatedTabList.splice(tabIndex, 1, updatedTab);

  setState({ tabsList: updatedTabList });

  if (id === DELAY_TASK_MODAL_FIELD_IDS.ENTITY_NAME) {
    setState({ isConditionFetching: true });
    const selectedEntityName = getArraySafeValue(value);
    const mapOfVariableToEntityNameForCondition = { [CURRENT_ENTITY_NAMESPACE]: selectedEntityName };

    const selectedEntityVariablesInfo = {};
    _forEach(variablesInfo, (info, variableName) => {
      if (selectedEntityName === tget(info, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME)) {
        selectedEntityVariablesInfo[variableName] = info;
      }
    });
    const variableMap = generateVariableMapForNode(
      selectedEntityVariablesInfo,
      mapOfVariableToEntityNameForCondition,
      _concat(tget(variablesByNodeId, `${tget(stepDetails, 'nodeConfig.parent')}`), tget(variablesByNodeId, `${tget(stepDetails, 'nodeId')}`)),
    );
    const conditionBuilderFieldDefinitionObject = await loadFieldDefinitions({
      setState,
      getState,
      params: { variableMap },
    });

    setState({
      conditionBuilderFieldDefinitionObject,
      mapOfVariableToEntityName: variableMap,
      isConditionFetching: false,
    });
  }
};

const handleErrors = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { selectedTabId, tabsList, pendingAction, tabErrorList } = getState();
  const { errors } = params;

  const tabIndex = _findIndex(tabsList, { id: selectedTabId });

  const updatedTabErrorList = _isNil(tabErrorList) ? [] : [...tabErrorList];
  updatedTabErrorList.splice(tabIndex, 1, {
    ...errors,
  });

  if (pendingAction?.showToaster) {
    toaster(TOASTER_TYPE.ERROR, __('Please correct form errors.'));
    setState({ pendingAction: EMPTY_OBJECT });
  }

  setState({ tabErrorList: updatedTabErrorList });
};

const handleFormBlur = ({ getState, setState, params }) => {
  const { selectedTabId } = getState();
  setState({ pendingAction: { ...params, showToaster: true } }, () => triggerSubmit(selectedTabId));
};

const handleFormSubmit = ({ getState, setState }) => {
  const { pendingAction = EMPTY_OBJECT } = getState();
  const { type, payload = EMPTY_OBJECT, onAction } = pendingAction;

  if (_isFunction(onAction) && !_isEmpty(type)) {
    onAction({ type, payload });
  }

  setState({ pendingAction: EMPTY_OBJECT });
};

const FORM_ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [DELAY_TASK_ACTION_TYPES.ON_FORM_BLUR]: handleFormBlur,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleFormSubmit,
};

export default FORM_ACTION_HANDLERS;
