exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".deleteModal_infoContainer__tzq4WwxEA8{display:flex;align-items:center}[dir] .deleteModal_infoContainer__tzq4WwxEA8{padding:0 2rem 2rem}.deleteModal_infoContainer__tzq4WwxEA8 .deleteModal_infoIconContainer__nzvLtFHD8g{color:#ffb23c}[dir=ltr] .deleteModal_infoContainer__tzq4WwxEA8 .deleteModal_infoIconContainer__nzvLtFHD8g{padding-right:1rem}[dir=rtl] .deleteModal_infoContainer__tzq4WwxEA8 .deleteModal_infoIconContainer__nzvLtFHD8g{padding-left:1rem}.deleteModal_infoContainer__tzq4WwxEA8 .deleteModal_infoContentContainer__fZCieE8G2g{font-family:\"Proxima-Nova-Regular\";font-size:1.7rem}[dir] .deleteModal_selectLabelContainer__qNw7u6SFUF{padding:0 2rem 1rem}.deleteModal_reporteeSelectContainer__8veXx835Ue{display:flex;align-items:center}[dir] .deleteModal_reporteeSelectContainer__8veXx835Ue{padding:0 2rem 2rem}.deleteModal_reporteeSelectContainer__8veXx835Ue .deleteModal_reporteeSelect__mzHKSuRGT6{width:35rem}[dir] .deleteModal_reporteeSelectContainer__8veXx835Ue .deleteModal_spanBox__fJ6aRkSYWC{padding:0 2rem}.deleteModal_formHeaderContainer__6t6GH74A7A{display:flex;align-items:center;justify-content:space-between}[dir] .deleteModal_formHeaderContainer__6t6GH74A7A{padding:0 2rem 1rem}[dir] .deleteModal_roleFormContainer__w9y7GWSp1u{padding-top:2rem;border-top:.1rem solid #edeef0}", ""]);

// Exports
exports.locals = {
	"infoContainer": "deleteModal_infoContainer__tzq4WwxEA8",
	"infoIconContainer": "deleteModal_infoIconContainer__nzvLtFHD8g",
	"infoContentContainer": "deleteModal_infoContentContainer__fZCieE8G2g",
	"selectLabelContainer": "deleteModal_selectLabelContainer__qNw7u6SFUF",
	"reporteeSelectContainer": "deleteModal_reporteeSelectContainer__8veXx835Ue",
	"reporteeSelect": "deleteModal_reporteeSelect__mzHKSuRGT6",
	"spanBox": "deleteModal_spanBox__fJ6aRkSYWC",
	"formHeaderContainer": "deleteModal_formHeaderContainer__6t6GH74A7A",
	"roleFormContainer": "deleteModal_roleFormContainer__w9y7GWSp1u"
};