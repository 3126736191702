import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { toMoment } from '@tekion/tekion-base/utils/dateUtils';
import Button from '@tekion/tekion-components/atoms/Button';
import { getDuration } from '../../otpInputRenderer.helpers';

import { LOGIN_ACTION_TYPES } from '../../../../LoginPage.constants';

import HCaptchaRenderer from '../../../../../atoms/hCaptchaRenderer/hCaptchaRenderer';

const ResendOtpButton = ({ expiryTime, onAction }) => {
  const [currentTime, setCurrentTime] = useState(+toMoment());
  const intervalRef = useRef();

  const buttonProps = useMemo(
    () => (expiryTime > currentTime ? { disabled: true, label: __(`Resend in ${getDuration(currentTime, expiryTime)}`) } : { label: __('Resend') }),
    [currentTime, expiryTime],
  );

  useEffect(() => {
    setCurrentTime(+toMoment());
    const interval = setInterval(() => setCurrentTime(+toMoment()), 1000);
    intervalRef.current = interval;
  }, [expiryTime]);

  return (
    <div>
      <HCaptchaRenderer
        highlightOnHover={false}
        view={Button.VIEW.TERTIARY}
        actionType={LOGIN_ACTION_TYPES.RESEND_OTP}
        onAction={onAction}
        {...buttonProps}
      />
    </div>
  );
};

ResendOtpButton.propTypes = {
  expiryTime: PropTypes.number,
  onAction: PropTypes.func.isRequired,
};

ResendOtpButton.defaultProps = {
  expiryTime: 60,
};

export default ResendOtpButton;
