import _map from 'lodash/map';

// Tekion-base
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

// Tekion-components
import TextInputRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import RadioRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';

// Constants
import SwitchRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch/Switch';

import RichTextEditor from '../../../../../../organisms/richTextEditor';
import { ruleNameValidator } from '../../../../../../utils/formValidators';
import { RULE_STATUS_RADIO_OPTIONS, RULE_STATUS_RADIO_LABEL_MAP } from './validationRuleBuilderForm.general';
import { FIELD_IDS } from '../../../../../../constants/validationRuleBuilder.constants';

import styles from '../validationRuleBuilder.module.scss';

const createRadio = (option) => ({
  label: RULE_STATUS_RADIO_LABEL_MAP[option],
  value: option,
});

const createRuleStatusRadios = () => _map(RULE_STATUS_RADIO_OPTIONS, createRadio);

const RULE_NAME_FIELD = {
  id: FIELD_IDS.RULE_NAME,
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Rule Name'),
    required: true,
    validators: [ruleNameValidator],
  },
};

const RULE_STATUS_FIELD = {
  id: FIELD_IDS.RULE_STATUS,
  renderer: RadioRenderer,
  renderOptions: {
    label: __('Rule Status'),
    required: true,
    validators: [isRequiredRule],
    radios: createRuleStatusRadios(),
    labelClassName: 'm-b-8',
  },
};

const ERROR_MESSAGE_FIELD = {
  id: FIELD_IDS.ERROR_MESSAGE,
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Error Message'),
    required: true,
    validators: [isRequiredRule],
  },
};

const APPLICABLE_RECORD_TYPES_FIELD = {
  id: FIELD_IDS.APPLICABLE_RECORD_TYPES,
  renderer: SelectInput,
  renderOptions: {
    label: __('Select Record Types'),
    required: true,
    validators: [isRequiredRule],
    placeholder: __('Select record type'),
    key: 'select-bar',
    mode: 'multiple',
    className: styles.customSelect,
  },
};

const ENABLE_RICH_TEXT_EDITOR_FIELD = {
  id: FIELD_IDS.ENABLE_RICH_TEXT_EDITOR,
  renderer: SwitchRenderer,
  renderOptions: {
    label: __('Enable Rich Text Editor'),
    defaultValue: false,
    infoBadgeClassName: styles.infoIcon,
  },
};

const CUSTOM_CODE_FIELD = {
  id: FIELD_IDS.CUSTOM_CODE,
  renderer: RichTextEditor,
  renderOptions: {
    editorId: 'editorid',
    customCodeEnabled: true,
    className: `full-width overflow-auto ${styles.richTextEditorWrapper}`,
    toolbarStyles: {
      borderBottom: '0.1rem solid gray',
      padding: '1.2rem 0',
      minHeight: 'fit-content',
      position: 'sticky',
      top: '0',
      zIndex: '1',
    },
  },
};

export { RULE_NAME_FIELD, RULE_STATUS_FIELD, ERROR_MESSAGE_FIELD, APPLICABLE_RECORD_TYPES_FIELD, ENABLE_RICH_TEXT_EDITOR_FIELD, CUSTOM_CODE_FIELD };
