const TOTAL_GRID_COLUMNS = 24;

const DRAGGABLE_CLASS_NAME = '_RGL_draggable';

const DRAGGABLE_CANCEL_CLASS_NAME = 'RGL-draggable-cancel';

const DEFAULT_GRID_GUTTER = 24;

const ROW_HEIGHT = 20;

const BREAKPOINTS = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };

export { TOTAL_GRID_COLUMNS, DRAGGABLE_CANCEL_CLASS_NAME, DRAGGABLE_CLASS_NAME, DEFAULT_GRID_GUTTER, ROW_HEIGHT, BREAKPOINTS };
