import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

// Constants
import { DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';
import { tget } from '@tekion/tekion-base/utils/general';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { getPayload } from './templateBuilderList.helpers';
import { deleteTemplate, searchTemplate } from '../../../../../../actions/template.actions';

import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import ROUTES from '../../../../constants/routes';
import { ACTION_TYPES, COLUMN_IDS } from '../constants/templateBuilderList.contants';
import { FIELD_IDS as TEMPLATE_BUILDER_FIELD_IDS } from '../../../../../../constants/templateBuilder.constants';

const handleInitData = async ({ setState, params, getState }) => {
  const { pageSize = DEFAULT_PAGE_INFO.rows, searchText } = getState();
  const currentPageToken = _get(params, 'nextPageToken');

  setState({ loading: true });

  const payload = getPayload({
    searchText,
    searchField: COLUMN_IDS.DISPLAY_NAME,
    pageSize,
    nextPageToken: currentPageToken,
  });
  const response = await searchTemplate(payload);
  const templates = tget(response, 'hits', EMPTY_ARRAY);

  setState({
    templates,
    totalNumberOfEntries: _get(response, 'count', 0),
    loading: false,
    nextPageToken: _get(response, 'nextPageToken'),
  });
};

const handleRedirectToCreate = ({ getState }) => {
  const { history } = getState();
  history.push(ROUTES.TEMPLATE_CREATE_ROUTE);
};

const handleRowClick = ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const template = _get(params, 'value.original', EMPTY_OBJECT);
  const templateName = _get(template, TEMPLATE_BUILDER_FIELD_IDS.NAME);

  if (!_isEmpty(templateName)) {
    const path = `${STUDIO_ROUTE}/${PAGE_IDS.TEMPLATE_EDIT}/${templateName}`;
    history.push({ pathname: path, state: { templateInfo: template } });
  }
};

const handleEditTemplate = ({ getState, params }) => {
  const { history } = getState();
  const templateName = _get(params, TEMPLATE_BUILDER_FIELD_IDS.NAME);

  if (!_isEmpty(templateName)) {
    const path = `${STUDIO_ROUTE}/${PAGE_IDS.TEMPLATE_EDIT}/${templateName}`;
    history.push({ pathname: path, state: { templateInfo: params } });
  }
};

const handleSearchApply = async ({ setState, params, getState }) => {
  setState(
    {
      searchText: _get(params, 'value'),
      currentPage: 0,
    },
    () => {
      handleInitData({ getState, setState });
    },
  );
};

const handlePageUpdate = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');
  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }

  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleInitData({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const handleDeleteTemplateRowActionClick = ({ setState, params }) => {
  const templateDisplayName = _get(params, TEMPLATE_BUILDER_FIELD_IDS.DISPLAY_NAME);

  setState({
    isConfirmationModalVisible: true,
    templateDataToPerformAction: params,
    confirmationModalTitle: __('Delete {{templateDisplayName}} template', { templateDisplayName }),
  });
};

const handleDeleteTemplateConfirmationCancelClick = ({ setState }) => {
  setState({ isConfirmationModalVisible: false, templateDataToPerformAction: {} });
};

const handleDeleteTemplateConfirmationDoneClick = async ({ getState, setState }) => {
  const { templateDataToPerformAction } = getState();

  const templateName = _get(templateDataToPerformAction, TEMPLATE_BUILDER_FIELD_IDS.NAME);

  if (!_isEmpty(templateName)) {
    setState({ isTemplateDeleting: true });

    await deleteTemplate(templateName);

    setState({ isTemplateDeleting: false, isConfirmationModalVisible: false });

    handleInitData({ setState, getState });
  }
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInitData,
  [ACTION_TYPES.REDIRECT_TO_TEMPLATE_CREATION]: handleRedirectToCreate,
  [ACTION_TYPES.EDIT_TEMPLATE_DETAIL]: handleEditTemplate,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_SEARCH_TERM_UPDATE]: handleSearchApply,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleRowClick,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleInitData,
  [ACTION_TYPES.DELETE_TEMPLATE_ROW_ACTION_CLICK]: handleDeleteTemplateRowActionClick,
  [ACTION_TYPES.DELETE_TEMPLATE_CONFIRMATION_CANCEL_CLICK]: handleDeleteTemplateConfirmationCancelClick,
  [ACTION_TYPES.DELETE_TEMPLATE_CONFIRMATION_DONE_CLICK]: handleDeleteTemplateConfirmationDoneClick,
};

export default ACTION_HANDLERS;
