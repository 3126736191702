import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// Components
import NotificationGroupList from '../notificationGroupList';
import NotificationCardList from '../../molecules/notificationCardList';

// Constants
import { TAB_CONTENT_FILTER_TYPES } from '../../constants/notificationDrawer.constants';

const NotificationTab = ({ showUnreadOnly }) => {
  const [filterType, setFilterType] = useState(TAB_CONTENT_FILTER_TYPES.BY_GROUP);

  const handleFilterChange = useCallback((_filterType) => {
    setFilterType(_filterType);
  }, []);

  if (filterType === TAB_CONTENT_FILTER_TYPES.BY_GROUP) {
    return (
      <div className="p-t-24 full-height">
        <NotificationGroupList showUnreadOnly={showUnreadOnly} filterType={filterType} onFilterChange={handleFilterChange} />
      </div>
    );
  }

  if (filterType === TAB_CONTENT_FILTER_TYPES.MOST_RECENT) {
    return (
      <div className="p-t-24 full-height">
        <NotificationCardList showUnreadOnly={showUnreadOnly} isGroupExpanded={false} filterType={filterType} onFilterChange={handleFilterChange} />
      </div>
    );
  }

  return null;
};

NotificationTab.propTypes = {
  showUnreadOnly: PropTypes.bool,
};

NotificationTab.defaultProps = {
  showUnreadOnly: false,
};

export default React.memo(NotificationTab);
