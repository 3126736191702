import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { isErrorEmpty } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { fetchEntityDefByName } from '../../../../../../../../actions/entityManagement.actions';
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../../../../actions/conditionBuilder.actions';

import { getChildAggregateSummaryFormValues, getFieldsFormChildAggregateSummaryValues } from './childAggregateSummaryForm.general.helpers';

import CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS from '../constants/childAggregateSummaryForm.fieldIds';
import FIELDS_FORM_FIELD_IDS from '../../../constants/fieldsForm.fieldIds';
import { FILTER_CONDITION_OPTIONS } from '../constants/childAggregateSummaryForm.general.constants';
import { FIELD_FORM_CONTEXT_ID } from '../../../constants/fieldsForm.constants';
import { NAMESPACES } from '../../../../../../../../constants/general.constants';
import ACTION_TYPES from '../../../constants/fieldsForm.actionTypes';

import entityDefinitionReader from '../../../../../../../../readers/entity.reader';

const handleFormInit = async ({ getState, setState }) => {
  const { fieldsFormChildAggregateSummaryValues = EMPTY_OBJECT, cachedEntityDefs, childEntityDefinitions = {}, fieldsFormOnAction } = getState();
  const childAggregateFieldValues = getChildAggregateSummaryFormValues(fieldsFormChildAggregateSummaryValues);
  const childEntityName = _get(fieldsFormChildAggregateSummaryValues, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_ENTITY_NAME);

  if (!_isEmpty(childEntityName)) {
    let entityDef = _get(cachedEntityDefs, childEntityName);
    if (_isEmpty(entityDef)) {
      entityDef = await fetchEntityDefByName(childEntityName);
      fieldsFormOnAction({ type: ACTION_TYPES.ON_ENTITY_DEF_CHANGE, payload: { entityName: childEntityName, entityDef } });
    }
    const childFieldDefinitions = entityDefinitionReader.fieldDefinitions(entityDef);
    const mapOfVariableToEntityNameForEntryConditionChild = { [NAMESPACES.CURRENT_RECORD]: childEntityName };
    const conditionBuilderFieldDefinitionObjectChild = await fetchFieldDefinitionsForConditionBuilder(
      mapOfVariableToEntityNameForEntryConditionChild,
    );

    _set(childEntityDefinitions, childEntityName, {
      childFieldDefinitions,
      mapOfVariableToEntityNameForEntryConditionChild,
      conditionBuilderFieldDefinitionObjectChild,
    });
  }

  setState({ values: childAggregateFieldValues, childEntityDefinitions });
};

const handleFieldChange = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { values: oldValues = EMPTY_OBJECT, childEntityDefinitions = {}, cachedEntityDefs, fieldsFormOnAction } = getState();

  const values = { ...oldValues, [id]: value };

  if (id === CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_ENTITY_NAME) {
    const childEntityName = getArraySafeValue(value);
    let entityDef = _get(cachedEntityDefs, childEntityName);

    if (_isEmpty(entityDef)) {
      entityDef = await fetchEntityDefByName(childEntityName);
      fieldsFormOnAction({ type: ACTION_TYPES.ON_ENTITY_DEF_CHANGE, payload: { entityName: childEntityName, entityDef } });
    }

    const childFieldDefinitions = entityDefinitionReader.fieldDefinitions(entityDef);
    const mapOfVariableToEntityNameForEntryConditionChild = { [NAMESPACES.CURRENT_RECORD]: childEntityName };
    const conditionBuilderFieldDefinitionObjectChild = await fetchFieldDefinitionsForConditionBuilder(
      mapOfVariableToEntityNameForEntryConditionChild,
    );

    _set(childEntityDefinitions, childEntityName, {
      childFieldDefinitions,
      mapOfVariableToEntityNameForEntryConditionChild,
      conditionBuilderFieldDefinitionObjectChild,
    });

    _unset(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_RELATIONSHIP);
    _unset(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.AGGREGATE_FIELD);
    _unset(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.ROLL_UP_FUNCTION);
    _unset(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_FILTER_CONDITION);
    _unset(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.UPDATE_CONDITION);
  }

  if (id === CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.ROLL_UP_FUNCTION) {
    _unset(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.AGGREGATE_FIELD);
  }

  setState({ values, childEntityDefinitions }, () =>
    fieldsFormOnAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: FIELDS_FORM_FIELD_IDS.CHILD_AGGREGATE_SUMMARY_FORM, value: getFieldsFormChildAggregateSummaryValues(values) },
    }),
  );
};

const handleValidationSuccess = ({ getState, params = EMPTY_OBJECT }) => {
  const { errors } = params;
  const { fieldsFormOnAction, values = EMPTY_OBJECT } = getState();
  const childFilterCriteria = _get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.FILTER_CRITERIA_FOR_CHILD);
  const parentFilterCriteria = _get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.FILTER_CRITERIA_FOR_PARENT);
  const filteredErrors = {
    ...errors,
    ...(childFilterCriteria === FILTER_CONDITION_OPTIONS.ALL_RECORDS && {
      [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_FILTER_CONDITION]: undefined,
    }),
    ...(parentFilterCriteria === FILTER_CONDITION_OPTIONS.ALL_RECORDS && {
      [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.UPDATE_CONDITION]: undefined,
    }),
  };

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: { id: FIELDS_FORM_FIELD_IDS.CHILD_AGGREGATE_SUMMARY_FORM, errors: filteredErrors },
  });
};

const handleSubmit = ({ getState, params = EMPTY_OBJECT }) => {
  const { additional = {} } = params;
  const { fieldsFormOnAction } = getState();
  const { formErrors } = additional;

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: { id: FIELDS_FORM_FIELD_IDS.CHILD_AGGREGATE_SUMMARY_FORM, errors: undefined },
  });

  _set(formErrors, FIELDS_FORM_FIELD_IDS.CHILD_AGGREGATE_SUMMARY_FORM, false);

  if (isErrorEmpty(formErrors)) {
    triggerSubmit(FIELD_FORM_CONTEXT_ID, _cloneDeep(additional));
  }
};

const CHILD_AGGREGATE_SUMMARY_FORM_ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleFormInit,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidationSuccess,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
};

export default CHILD_AGGREGATE_SUMMARY_FORM_ACTION_HANDLERS;
