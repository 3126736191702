import { tget } from '@tekion/tekion-base/utils/general';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import TextInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput/TextInput';
import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import EntityAsyncSelect from '../../../../../../molecules/EntityAsyncSelect/EntityAsyncSelect';
import ResourceField from '../../../../../../../../organisms/conditionBuilder/atoms/resourceField/ResourceField';

import {
  DATA_TYPE_OPTIONS,
  FIELD_IDS,
  FIELD_RENDERERS,
  RECORD_COUNT_OPTIONS,
  RENDERER_OPTIONS_BASED_FIELD_RENDERER,
  VALUE_TYPES,
  VALUE_TYPE_OPTIONS,
  VARIABLE_NODE_TYPES,
} from './variableModal.constants';

import { TASK_DEF_IDS } from '../../../constants/workflow.constants';
import DATA_TYPES from '../../../../../../../../constants/fieldDefinition.dataTypes';

import styles from './variableNodeModal.module.scss';

const rowCheck = {
  [FIELD_IDS.ENTITY_NAME]: ({ variableNodeType }) => variableNodeType === VARIABLE_NODE_TYPES.DEFINED,
  [FIELD_IDS.RECORD_COUNT]: ({ variableNodeType }) => variableNodeType === VARIABLE_NODE_TYPES.DEFINED,
  [FIELD_IDS.VALUE_TYPE]: ({ variableNodeType }) => variableNodeType === TASK_DEF_IDS.SET_VARIABLE,

  [FIELD_IDS.STATIC_VALUE]: ({ variableNodeType, formValues }) =>
    variableNodeType === TASK_DEF_IDS.SET_VARIABLE && tget(formValues, FIELD_IDS.VALUE_TYPE) === VALUE_TYPES.STATIC,
  [FIELD_IDS.DATA_TYPE]: ({ variableNodeType, formValues }) =>
    variableNodeType === TASK_DEF_IDS.SET_VARIABLE && tget(formValues, FIELD_IDS.VALUE_TYPE) === VALUE_TYPES.STATIC,
  [FIELD_IDS.DERIVED_FIELD]: ({ variableNodeType, formValues }) =>
    variableNodeType === TASK_DEF_IDS.SET_VARIABLE && tget(formValues, FIELD_IDS.VALUE_TYPE) === VALUE_TYPES.DERIVED,
};

const getFormSections = (formValues, variableNodeType) => [
  {
    className: styles.section,
    rows: [
      {
        columns: [FIELD_IDS.VARIABLE_NAME],
      },
      {
        columns: filterRows([FIELD_IDS.ENTITY_NAME], { formValues, variableNodeType }, rowCheck),
      },
    ],
  },
  {
    className: styles.section,
    rows: [
      {
        columns: filterRows([FIELD_IDS.RECORD_COUNT], { variableNodeType }, rowCheck),
      },
      {
        columns: filterRows([FIELD_IDS.VALUE_TYPE], { variableNodeType }, rowCheck),
      },
    ],
  },
  {
    className: styles.section,
    rows: [
      {
        columns: filterRows([FIELD_IDS.DATA_TYPE], { variableNodeType, formValues }, rowCheck),
      },
      {
        columns: filterRows([FIELD_IDS.STATIC_VALUE], { variableNodeType, formValues }, rowCheck),
      },
      {
        columns: filterRows([FIELD_IDS.DERIVED_FIELD], { variableNodeType, formValues }, rowCheck),
      },
    ],
  },
];

const getFieldConfig = (conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, formValues) => {
  const dataType = tget(formValues, FIELD_IDS.DATA_TYPE, DATA_TYPES.TEXT);
  return {
    [FIELD_IDS.VARIABLE_NAME]: {
      id: FIELD_IDS.VARIABLE_NAME,
      renderer: TextInputField,
      renderOptions: {
        label: __('Variable Name'),
        size: 6,
        required: true,
        validators: [isRequiredRule],
        placeholder: __('Enter variable name'),
      },
    },
    [FIELD_IDS.ENTITY_NAME]: {
      id: FIELD_IDS.ENTITY_NAME,
      renderer: EntityAsyncSelect,
      renderOptions: {
        required: true,
        label: __('Entity'),
        validators: [isRequiredRule],
      },
    },
    [FIELD_IDS.RECORD_COUNT]: {
      id: FIELD_IDS.RECORD_COUNT,
      renderer: Radio,
      renderOptions: {
        required: true,
        label: __('Records'),
        validators: [isRequiredRule],
        radios: RECORD_COUNT_OPTIONS,
      },
    },
    [FIELD_IDS.VALUE_TYPE]: {
      id: FIELD_IDS.VALUE_TYPE,
      renderer: Radio,
      renderOptions: {
        required: true,
        label: __('Value'),
        validators: [isRequiredRule],
        radios: VALUE_TYPE_OPTIONS,
      },
    },
    [FIELD_IDS.STATIC_VALUE]: {
      id: FIELD_IDS.STATIC_VALUE,
      renderer: FIELD_RENDERERS[dataType],
      renderOptions: {
        label: __('Value'),
        size: 6,
        required: true,
        validators: [isRequiredRule],
        placeholder: __('Enter Value'),
        ...RENDERER_OPTIONS_BASED_FIELD_RENDERER[dataType],
      },
    },
    [FIELD_IDS.DATA_TYPE]: {
      id: FIELD_IDS.DATA_TYPE,
      renderer: SelectInput,
      renderOptions: {
        label: __('Data Type'),
        size: 6,
        required: true,
        options: DATA_TYPE_OPTIONS,
        validators: [isRequiredRule],
        placeholder: __('Select'),
      },
    },
    [FIELD_IDS.DERIVED_FIELD]: {
      id: FIELD_IDS.DERIVED_FIELD,
      renderer: ResourceField,
      renderOptions: {
        required: true,
        label: __('Field'),
        validators: [isRequiredRule],
        conditionBuilderFieldDefinitionObject,
        mapOfVariableToEntityName,
      },
    },
  };
};

export { getFieldConfig, getFormSections };
