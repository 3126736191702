import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import styles from '../permissionSetList.module.scss';

export const COLUMN_IDS = {
  PERMISSION_NAME: 'name',
  PERMISSION_DISPLAY_NAME: 'displayName',
  PERMISSION_DESCRIPTION: 'description',
};

const PERMISSION_SET_LIST_COLUMNS = [
  {
    id: COLUMN_IDS.PERMISSION_NAME,
    Header: __('Permission Name'),
    accessor: COLUMN_IDS.PERMISSION_NAME,
    searchable: true,
    Cell: TextRenderer,
    className: styles.permissionSetListColumn,
  },
  {
    id: COLUMN_IDS.PERMISSION_DISPLAY_NAME,
    Header: __('Display Name'),
    accessor: COLUMN_IDS.PERMISSION_DISPLAY_NAME,
    searchable: true,
    Cell: TextRenderer,
    className: styles.permissionSetListColumn,
  },
  {
    id: COLUMN_IDS.PERMISSION_DESCRIPTION,
    Header: __('Description'),
    accessor: COLUMN_IDS.PERMISSION_DESCRIPTION,
    searchable: true,
    Cell: TextRenderer,
    className: styles.permissionSetListColumn,
  },
];

export default PERMISSION_SET_LIST_COLUMNS;
