import React, { useCallback, useContext, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _noop from 'lodash/noop';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import SectionTitle from '../sectionTitle/SectionTitle';
import ViewComponent from '../viewComponent/ViewComponent';
import ComponentRendererContainer from '../componentRendererContainer';
import ViewBuilderContext from '../../ViewBuilder.context';

import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';

import ViewRendererContext from '../../../../context/viewRenderer.context';
import variableApiFactory from '../../../../factories/variableApi.Factory';

import {
  DEFAULT_WIDTH,
  VIEW_TYPES,
  COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP,
  COMPONENT_TYPES,
  VIEW_CONTEXT_KEYS,
} from '../../constants/viewBuilder.constants';
import { CUSTOM_STYLE_IDS } from '../../../../constants/customStyles.constants';
import { ALL_VIEW_PROPERTY_KEYS, RENDERER_PROP_KEYS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './section.module.scss';

const SUPPORTED_CUSTOM_STYLE_SECTION = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[COMPONENT_TYPES.SECTION];

const Section = (props) => {
  const { isPreviewMode, isSelected = false, viewType, className, componentConfig, entityRecord, ...restProps } = props;

  const { selectedViewComponentId, onSelect } = useContext(ViewBuilderContext);
  const viewContextValue = useContext(ViewRendererContext);

  const sectionId = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
  const isDropEnabled = viewType !== VIEW_TYPES.LIST_VIEW;
  const children = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.CHILDREN, EMPTY_ARRAY);
  const properties = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);

  const viewVariables = useMemo(() => tget(viewContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.viewVariables`, EMPTY_STRING), [viewContextValue]);
  const handleSetViewVariables = useMemo(
    () => tget(viewContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.handleSetViewVariables`, _noop),
    [viewContextValue],
  );
  const variablesApi = useMemo(() => variableApiFactory(viewVariables, handleSetViewVariables), [viewVariables, handleSetViewVariables]);

  const customStyles = tget(properties, RENDERER_PROP_KEYS.CUSTOM_STYLES, EMPTY_OBJECT);

  const resolvedCustomStyles = useMemo(
    () => getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_SECTION, customStyles, entityRecord, variablesApi),
    [entityRecord, variablesApi, customStyles],
  );

  const handleSelect = useCallback(
    (event) => {
      event.stopPropagation();

      onSelect(sectionId);
    },
    [sectionId, onSelect],
  );

  const renderSectionInPreviewMode = () => (
    <div
      key={sectionId}
      style={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTAINER, EMPTY_OBJECT)}
      className={cx(styles.section, styles.previewMode)}
    >
      <SectionTitle
        isPreviewMode
        componentConfig={componentConfig}
        entityRecord={entityRecord}
        customStyles={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.TITLE, EMPTY_OBJECT)}
      />
      <div className={styles.sectionRow}>
        {_map(children, (childComponentConfig, index) => {
          const childSectionId = tget(childComponentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
          const sectionColumnWidth = _get(
            childComponentConfig,
            `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.WIDTH}`,
            DEFAULT_WIDTH,
          );

          return (
            <ComponentRendererContainer
              key={childSectionId}
              isPreviewMode={isPreviewMode}
              isDeleteEnabled={false}
              columnIndex={index}
              componentConfig={childComponentConfig}
              style={{ width: `${sectionColumnWidth}%` }}
              entityRecord={entityRecord}
              {...restProps}
            />
          );
        })}
      </div>
    </div>
  );

  const renderSectionInBuilderMode = () => (
    <div
      key={sectionId}
      style={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTAINER, EMPTY_OBJECT)}
      className={cx(styles.section, styles.builderMode, className, {
        [styles.selected]: isSelected || selectedViewComponentId === sectionId,
      })}
      onClick={handleSelect}
    >
      <SectionTitle
        componentConfig={componentConfig}
        entityRecord={entityRecord}
        customStyles={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.TITLE, EMPTY_OBJECT)}
        className={styles.sectionTitle}
      />
      <div className={styles.sectionRow}>
        {_map(children, (childComponentConfig, index) => {
          const childSectionId = tget(childComponentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
          const sectionColumnWidth = _get(
            childComponentConfig,
            `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.WIDTH}`,
            DEFAULT_WIDTH,
          );

          return (
            <ViewComponent
              style={{ width: `${sectionColumnWidth}%` }}
              className={styles.sectionColumn}
              key={childSectionId}
              index={index}
              componentConfig={childComponentConfig}
              isDeleteEnabled={index !== 0}
              isDropEnabled={isDropEnabled}
            >
              <ComponentRendererContainer
                isPreviewMode={isPreviewMode}
                isDropEnabled={isDropEnabled}
                columnIndex={index}
                componentConfig={childComponentConfig}
                entityRecord={entityRecord}
                {...restProps}
              />
            </ViewComponent>
          );
        })}
      </div>
    </div>
  );

  return isPreviewMode ? renderSectionInPreviewMode() : renderSectionInBuilderMode();
};

Section.propTypes = {
  isPreviewMode: PropTypes.bool,
  isDeleteEnabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  className: PropTypes.string,
  componentConfig: PropTypes.object.isRequired,
};

Section.defaultProps = {
  isSelected: false,
  isDeleteEnabled: true,
  isPreviewMode: false,
  className: '',
};

export default React.memo(Section);
