import { defaultMemoize } from 'reselect';

import _isEmpty from 'lodash/isEmpty';

import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';

import { TEXT_STARTS_WITH } from '../../../../../../constants/filter.constants';
import { EDIT_ACTION } from './tenantUserList.rowActions';

const getTableProps = defaultMemoize((handleRowAction, currentPage, loading, rows, totalNumberOfEntries) => ({
  currentPage: currentPage + 1,
  loading,
  showPagination: true,
  pageSize: rows,
  resultsPerPage: rows,
  totalNumberOfEntries,
  minRows: 0,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  rows,
  onRowActionClick: handleRowAction,
  getRowActions: () => [EDIT_ACTION],
}));

const getPayload = defaultMemoize(({ searchText, searchField, pageSize, nextPageToken }) => {
  let payload = { rows: pageSize };

  if (!_isEmpty(searchText)) {
    payload = {
      ...payload,
      filters: [
        {
          field: searchField,
          values: [searchText],
          filterType: TEXT_STARTS_WITH,
        },
      ],
    };
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      ...payload,
      nextPageToken,
    };
  }
  return payload;
});

export { getTableProps, getPayload };
