import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import _unset from 'lodash/unset';
import _set from 'lodash/set';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { getMomentValueOf, toMoment } from '@tekion/tekion-base/utils/dateUtils';

import { APPROVAL_VALIDITY_TYPES } from '../../../../../../../constants/approvalCentre.constants';
import FIELD_IDS from '../constants/approvalProcessForm.fieldIds';
import { CONDITION_BUILDER_FIELD_IDS } from '../../../../../../../organisms/conditionBuilder';

const VALIDITY_FIELDS = [
  FIELD_IDS.VALIDITY_TYPE,
  FIELD_IDS.VALIDITY_EPOCH,
  FIELD_IDS.VALIDITY_DAYS,
  FIELD_IDS.VALIDITY_HOURS,
  FIELD_IDS.VALIDITY_MINUTES,
];

const convertResponseToValues = (data) => {
  const validity = tget(data, FIELD_IDS.VALIDITY, {});

  const {
    [FIELD_IDS.VALIDITY_TYPE]: validityType,
    [FIELD_IDS.VALIDITY_EPOCH]: epoch,
    [FIELD_IDS.VALIDITY_DAYS]: days,
    [FIELD_IDS.VALIDITY_HOURS]: hours,
    [FIELD_IDS.VALIDITY_MINUTES]: minutes,
  } = validity;

  return {
    ..._omit(data, FIELD_IDS.VALIDITY),
    validityType,
    epoch: toMoment(epoch),
    days,
    hours,
    minutes,
  };
};

const getValidityForPayload = (validityValues) => {
  const validityType = tget(validityValues, FIELD_IDS.VALIDITY_TYPE);
  const epoch = tget(validityValues, FIELD_IDS.VALIDITY_EPOCH);

  if (validityType === APPROVAL_VALIDITY_TYPES.EXACT) {
    return {
      [FIELD_IDS.VALIDITY_TYPE]: validityType,
      [FIELD_IDS.VALIDITY_EPOCH]: getMomentValueOf(epoch),
    };
  } else if (validityType === APPROVAL_VALIDITY_TYPES.RELATIVE) {
    return _pick(validityValues, [FIELD_IDS.VALIDITY_TYPE, FIELD_IDS.VALIDITY_DAYS, FIELD_IDS.VALIDITY_HOURS, FIELD_IDS.VALIDITY_MINUTES]);
  }

  return validityValues;
};

const convertValuesToPayload = (formValues) => {
  const payload = _omit(formValues, VALIDITY_FIELDS);

  const validity = getValidityForPayload(_pick(formValues, VALIDITY_FIELDS));
  _set(payload, FIELD_IDS.VALIDITY, validity);

  const group = getArraySafeValue(tget(formValues, FIELD_IDS.GROUP));
  const category = getArraySafeValue(tget(formValues, FIELD_IDS.CATEGORY));
  const type = getArraySafeValue(tget(formValues, FIELD_IDS.TYPE));

  _set(payload, FIELD_IDS.GROUP, group);
  _set(payload, FIELD_IDS.CATEGORY, category);
  _set(payload, FIELD_IDS.TYPE, type);

  const condition = tget(formValues, FIELD_IDS.CONDITION);
  const criteriaList = tget(condition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST);
  if (_isEmpty(criteriaList)) {
    _unset(payload, FIELD_IDS.CONDITION);
  }

  return payload;
};

export { convertResponseToValues, convertValuesToPayload };
