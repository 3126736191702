import React from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import Heading from '@tekion/tekion-components/atoms/Heading';
import BaseTable from '@tekion/tekion-components/molecules/table/BaseTable';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

import AccessTypeFilter from './molecules/accessTypeFilter';

import useManageRecordAccessTable from './hooks/useManageRecordAccessTable';

import { SHARE_AND_MANAGE_RECORD_MODAL_MODE } from '../../../../constants/shareAndManageRecordModal.constants';

import { ACCESS_DENIED_WARNING_MESSAGE } from './manageRecordAccessTable.constants';

import styles from './manageRecordAccessTable.module.scss';

const ManageRecordAccessTable = ({
  isDataLoading,
  mode,
  wwdAccessTypesOptions,
  shareAndManageRecordUserDetails,
  onUpdateShareAndManageRecordUserDetails,
  onRemoveShareAndManageRecordUserDetails,
}) => {
  const { isSelectedAccessTypeFilterNotOfAccessDenied, selectedAccessTypeFilter, columns, filteredData, getRowClassName, handleSetAccessTypeFilter } =
    useManageRecordAccessTable({
      wwdAccessTypesOptions,
      shareAndManageRecordUserDetails,
      onUpdateShareAndManageRecordUserDetails,
      onRemoveShareAndManageRecordUserDetails,
    });

  return (
    <div>
      <Heading size={4} className={styles.manageRecordHeading}>
        {mode === SHARE_AND_MANAGE_RECORD_MODAL_MODE.MANAGE_RECORD ? __('Users With Access') : __('Added users')}
      </Heading>
      <AccessTypeFilter
        selectedFilter={selectedAccessTypeFilter}
        shareAndManageRecordUserDetails={shareAndManageRecordUserDetails}
        onFilterChange={handleSetAccessTypeFilter}
      />
      {!isSelectedAccessTypeFilterNotOfAccessDenied && (
        <div className={styles.accessDeniedContainer}>
          <FontIcon className={styles.iconClass}>icon-info</FontIcon>
          <div className={styles.textClass}>{ACCESS_DENIED_WARNING_MESSAGE}</div>
        </div>
      )}
      <BaseTable
        className={styles.container}
        loading={isDataLoading}
        showRowHoverColor={false}
        getRowClassName={getRowClassName}
        rowHeight={45}
        data={filteredData}
        columns={columns}
      />
    </div>
  );
};

ManageRecordAccessTable.propTypes = {
  isDataLoading: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  wwdAccessTypesOptions: PropTypes.array,
  shareAndManageRecordUserDetails: PropTypes.array,
  onUpdateShareAndManageRecordUserDetails: PropTypes.func,
  onRemoveShareAndManageRecordUserDetails: PropTypes.func,
};

ManageRecordAccessTable.defaultProps = {
  isDataLoading: false,
  wwdAccessTypesOptions: EMPTY_ARRAY,
  shareAndManageRecordUserDetails: EMPTY_ARRAY,
  onUpdateShareAndManageRecordUserDetails: _noop,
  onRemoveShareAndManageRecordUserDetails: _noop,
};

export default ManageRecordAccessTable;
