import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import workspaceServices from '../services/workspace.services';

const fetchWorkspaces = async () => {
  try {
    const response = await workspaceServices.getWorkspaceList();
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch workspaces, please try again later.')));
    return EMPTY_ARRAY;
  }
};

export { fetchWorkspaces };
