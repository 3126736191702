import { defaultMemoize } from 'reselect';
import _valuesIn from 'lodash/valuesIn';
import { COLUMN_IDS } from '../constants/configTable.constants';
import WorkspaceSelect from '../../workspaceSelect';
import ProfileSelect from '../../profileSelect/ProfileSelect';
import WorkspaceIcon from '../../workspaceIcon';
import DeleteIcon from '../../deleteIcon';
import RoleSelect from '../../roleSelect';

// all the cell are normal function here. so hooks will not work inside below cell components
const USER_WORKSPACE_STATIC_COLUMN_DATA = {
  [COLUMN_IDS.HOME_WORKSPACE_CHECKBOX]: {
    Header: __('Home'),
    minWidth: 25,
    sortable: false,
    key: COLUMN_IDS.HOME_WORKSPACE_CHECKBOX,
    accessor: COLUMN_IDS.HOME_WORKSPACE_CHECKBOX,
    Cell: WorkspaceIcon,
  },
  [COLUMN_IDS.WORKSPACE]: {
    Header: __('Workspace'),
    sortable: false,
    minWidth: 120,
    key: COLUMN_IDS.WORKSPACE,
    accessor: COLUMN_IDS.WORKSPACE,
    Cell: WorkspaceSelect,
  },
  [COLUMN_IDS.PROFILE_NAME]: {
    Header: __('Profile Name'),
    sortable: false,
    minWidth: 120,
    key: COLUMN_IDS.PROFILE_NAME,
    accessor: COLUMN_IDS.PROFILE_NAME,
    Cell: ProfileSelect,
  },
  [COLUMN_IDS.ROLE]: {
    Header: __('Role'),
    sortable: false,
    minWidth: 120,
    key: COLUMN_IDS.ROLE,
    accessor: COLUMN_IDS.ROLE,
    Cell: RoleSelect,
  },
  [COLUMN_IDS.ACTION]: {
    Header: __('Action'),
    sortable: false,
    resizable: false,
    fixed: 'right',
    minWidth: 25,
    key: COLUMN_IDS.ACTION,
    accessor: COLUMN_IDS.ACTION,
    Cell: DeleteIcon,
  },
};

const getColumns = defaultMemoize(() => _valuesIn(USER_WORKSPACE_STATIC_COLUMN_DATA));

export { getColumns };
