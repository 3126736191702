const VALIDATION_OVERRIDE_RULE_FIELD_IDS = {
  ERROR_MESSAGE: 'errorMessage',
  IS_MESSAGE_OVERRIDEN: 'IS_MESSAGE_OVERRIDEN',
  FORM_VIEW_NAME: 'viewName',
  SELECTED_FIELDS: 'manualInput',
  USER_INPUT: 'validationUserInput',
  USER_INPUT_TYPE: 'USER_INPUT_TYPE',
  RULE_NAME: 'ruleName',
};

export default VALIDATION_OVERRIDE_RULE_FIELD_IDS;
