import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import TextInputRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import TextAreaRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';

const CLONE_VIEW_MODAL_FORM_IDS = {
  NAME: 'title',
  DESCRIPTION: 'description',
};

const CLONE_VIEW_MODAL_FORM_SECTIONS = [
  { rows: [{ columns: [CLONE_VIEW_MODAL_FORM_IDS.NAME] }, { columns: [CLONE_VIEW_MODAL_FORM_IDS.DESCRIPTION] }] },
];

const NAME_FIELD = {
  id: CLONE_VIEW_MODAL_FORM_IDS.NAME,
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const DESCRIPTION_FIELD = {
  id: CLONE_VIEW_MODAL_FORM_IDS.DESCRIPTION,
  renderer: TextAreaRenderer,
  renderOptions: {
    label: __('Description'),
  },
};

const CLONE_VIEW_MODAL_FORM_FIELDS = {
  [CLONE_VIEW_MODAL_FORM_IDS.NAME]: NAME_FIELD,
  [CLONE_VIEW_MODAL_FORM_IDS.DESCRIPTION]: DESCRIPTION_FIELD,
};

export { CLONE_VIEW_MODAL_FORM_FIELDS, CLONE_VIEW_MODAL_FORM_SECTIONS, CLONE_VIEW_MODAL_FORM_IDS };
