import React, { useMemo } from 'react';
import compose from 'recompose/compose';
import renameProps from 'recompose/renameProps';
import PropTypes from 'prop-types';

import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import DEPENDENCY_CONFIG_FORM_ACTION_HANDLERS from './helpers/dependencyConfigForm.actionHandlers';
import getFields from './helpers/dependencyConfigForm.fields';

import DEPENDENCY_CONFIG_FORM_SECTIONS from './constants/dependencyConfigForm.sections';
import FIELDS_FORM_FIELD_IDS from '../../constants/fieldsForm.fieldIds';

const DependencyConfigForm = ({ entity, error, values, onAction }) => {
  const fields = useMemo(() => getFields(entity), [entity]);

  return (
    <FormWithSubmission
      className="full-width"
      contextId={FIELDS_FORM_FIELD_IDS.DEPENDENCY_CONFIG_FORM}
      errors={error}
      values={values}
      fields={fields}
      sections={DEPENDENCY_CONFIG_FORM_SECTIONS}
      onAction={onAction}
    />
  );
};

DependencyConfigForm.propTypes = {
  entity: PropTypes.object,
  error: PropTypes.object,
  values: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

DependencyConfigForm.defaultProps = {
  entity: EMPTY_OBJECT,
  error: undefined,
  values: EMPTY_OBJECT,
};

export default compose(
  renameProps({ onAction: 'fieldsFormOnAction', value: 'fieldsFormDependencyConfigValues' }),
  withActions(EMPTY_OBJECT, DEPENDENCY_CONFIG_FORM_ACTION_HANDLERS),
)(DependencyConfigForm);
