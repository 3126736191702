import React from 'react';
import { defaultMemoize } from 'reselect';

import _property from 'lodash/property';
import _get from 'lodash/get';
import _includes from 'lodash/includes';

// Components
import ColumnConfig from '@tekion/tekion-components/organisms/inputTable/builders/ColumnConfig';
import CellConfig from '@tekion/tekion-components/organisms/inputTable/builders/CellConfig';
import TextDisplayCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/TextDisplayCellRenderer';
import CheckBoxRenderer from '@tekion/tekion-components/molecules/CellRenderers/CheckboxRenderer';

import { COLUMN_IDS, PERMISSION_TYPE } from '../constants/entityFieldPermissionTable.constants';
import EntityFieldPermissionTableColumnHeader from '../../entityFieldPermissionTableColumnHeader/EntityFieldPermissionTableColumnHeader';

const mapValueProp = (data, id, permissionTableType) => {
  const value = _get(data, `rowData.${id}`, '');
  return {
    permissionTableType,
    value,
  };
};

const mapFlagprop = (data, id) => {
  const actionTypes = _get(data, 'rowData.actionTypes');
  return {
    checked: _includes(actionTypes, id),
  };
};

const getNameCell = defaultMemoize((permissionTableType) => {
  if (permissionTableType === PERMISSION_TYPE.ENTITY) {
    return new CellConfig()
      .setComponent(TextDisplayCellRenderer)
      .setMapCellPropsToComponentProps((props) => mapValueProp(props, COLUMN_IDS.ENTITY_NAME, permissionTableType));
  }
  return new CellConfig()
    .setComponent(TextDisplayCellRenderer)
    .setMapCellPropsToComponentProps((props) => mapValueProp(props, COLUMN_IDS.FIELD_NAME));
});

const getReadCell = defaultMemoize((disabled) =>
  new CellConfig()
    .setComponent(
      defaultMemoize(({ rowData, onChange, checked }) => (
        <CheckBoxRenderer onClick={onChange} original={rowData} checked={checked} disabled={disabled} />
      )),
    )
    .setMapCellPropsToComponentProps((props) => mapFlagprop(props, COLUMN_IDS.READ)),
);

const getCreateCell = defaultMemoize((disabled) =>
  new CellConfig()
    .setComponent(
      defaultMemoize(({ rowData, onChange, checked }) => (
        <CheckBoxRenderer onClick={onChange} original={rowData} checked={checked} disabled={disabled} />
      )),
    )
    .setMapCellPropsToComponentProps((props) => mapFlagprop(props, COLUMN_IDS.CREATE)),
);

const getEditCell = defaultMemoize((disabled) =>
  new CellConfig()
    .setComponent(
      defaultMemoize(({ rowData, onChange, checked }) => (
        <CheckBoxRenderer onClick={onChange} original={rowData} checked={checked} disabled={disabled} />
      )),
    )
    .setMapCellPropsToComponentProps((props) => mapFlagprop(props, COLUMN_IDS.UPDATE)),
);

const getDeleteCell = defaultMemoize((disabled) =>
  new CellConfig()
    .setComponent(
      defaultMemoize(({ rowData, onChange, checked }) => (
        <CheckBoxRenderer onClick={onChange} original={rowData} checked={checked} disabled={disabled} />
      )),
    )
    .setMapCellPropsToComponentProps((props) => mapFlagprop(props, COLUMN_IDS.DELETE)),
);

const entityNameAccessor = _property(COLUMN_IDS.ENTITY_NAME);
const fieldNameAccessor = _property(COLUMN_IDS.FIELD_NAME);
const getReadAccessor = (rowInfo) => {
  const actionTypes = _get(rowInfo, 'actionTypes');
  return _includes(actionTypes, COLUMN_IDS.READ);
};
const getDeleteAccessor = (rowInfo) => {
  const actionTypes = _get(rowInfo, 'actionTypes');
  return _includes(actionTypes, COLUMN_IDS.DELETE);
};
const getCreateAccessor = (rowInfo) => {
  const actionTypes = _get(rowInfo, 'actionTypes');
  return _includes(actionTypes, COLUMN_IDS.CREATE);
};
const getEditAccessor = (rowInfo) => {
  const actionTypes = _get(rowInfo, 'actionTypes');
  return _includes(actionTypes, COLUMN_IDS.UPDATE);
};

const onValueChange = (data, e) => _get(e, 'value') || e;

const getNameColumnConfig = defaultMemoize((permissionTableType) => {
  if (permissionTableType === PERMISSION_TYPE.ENTITY) {
    return new ColumnConfig(COLUMN_IDS.ENTITY_NAME)
      .setHeader(__('Entity Name'))
      .setAccessor(entityNameAccessor)
      .addCellConfig(getNameCell(permissionTableType));
  }
  return new ColumnConfig(COLUMN_IDS.FIELD_NAME)
    .setHeader(__('Field Name'))
    .setAccessor(fieldNameAccessor)
    .addCellConfig(getNameCell(permissionTableType));
});

const getReadColumnConfig = defaultMemoize((onAction, data, permissionTableType, columnHeaderCheckbox, disabledFieldPermissionIds) => {
  if (permissionTableType === PERMISSION_TYPE.FIELD) {
    let disabled = false;
    if (_includes(disabledFieldPermissionIds, COLUMN_IDS.READ)) {
      disabled = true;
    }
    return new ColumnConfig(COLUMN_IDS.READ)
      .setHeader(
        <EntityFieldPermissionTableColumnHeader
          onAction={onAction}
          data={data}
          id={COLUMN_IDS.READ}
          name="Read"
          checked={!disabled && columnHeaderCheckbox[COLUMN_IDS.READ]}
          disabled={disabled}
        />,
      )
      .setAccessor(getReadAccessor)
      .addCellConfig(getReadCell(disabled))
      .setGetValueFromOnChange(onValueChange);
  }
  return new ColumnConfig(COLUMN_IDS.READ)
    .setHeader(__('Read'))
    .setAccessor(getReadAccessor)
    .addCellConfig(getReadCell())
    .setGetValueFromOnChange(onValueChange);
});

const getCreateColumnConfig = defaultMemoize((onAction, data, permissionTableType, columnHeaderCheckbox, disabledFieldPermissionIds) => {
  if (permissionTableType === PERMISSION_TYPE.FIELD) {
    let disabled = false;
    if (_includes(disabledFieldPermissionIds, COLUMN_IDS.CREATE)) {
      disabled = true;
    }
    return new ColumnConfig(COLUMN_IDS.CREATE)
      .setHeader(
        <EntityFieldPermissionTableColumnHeader
          onAction={onAction}
          data={data}
          id={COLUMN_IDS.CREATE}
          name="Create"
          checked={!disabled && columnHeaderCheckbox[COLUMN_IDS.CREATE]}
          disabled={disabled}
        />,
      )
      .setAccessor(getCreateAccessor)
      .addCellConfig(getCreateCell(disabled))
      .setGetValueFromOnChange(onValueChange);
  }
  return new ColumnConfig(COLUMN_IDS.CREATE)
    .setHeader(__('Create'))
    .setAccessor(getCreateAccessor)
    .addCellConfig(getCreateCell())
    .setGetValueFromOnChange(onValueChange);
});

const getEditColumnConfig = defaultMemoize((onAction, data, permissionTableType, columnHeaderCheckbox, disabledFieldPermissionIds) => {
  if (permissionTableType === PERMISSION_TYPE.FIELD) {
    let disabled = false;
    if (_includes(disabledFieldPermissionIds, COLUMN_IDS.UPDATE)) {
      disabled = true;
    }
    return new ColumnConfig(COLUMN_IDS.UPDATE)
      .setHeader(
        <EntityFieldPermissionTableColumnHeader
          onAction={onAction}
          data={data}
          id={COLUMN_IDS.UPDATE}
          name="Edit"
          checked={!disabled && columnHeaderCheckbox[COLUMN_IDS.UPDATE]}
          disabled={disabled}
        />,
      )
      .setAccessor(getEditAccessor)
      .addCellConfig(getEditCell(disabled))
      .setGetValueFromOnChange(onValueChange);
  }
  return new ColumnConfig(COLUMN_IDS.UPDATE)
    .setHeader(__('Edit'))
    .setAccessor(getEditAccessor)
    .addCellConfig(getEditCell())
    .setGetValueFromOnChange(onValueChange);
});

const getDeleteColumnConfig = defaultMemoize((onAction, data, permissionTableType, columnHeaderCheckbox, disabledFieldPermissionIds) => {
  if (permissionTableType === PERMISSION_TYPE.FIELD) {
    let disabled = false;
    if (_includes(disabledFieldPermissionIds, COLUMN_IDS.DELETE)) {
      disabled = true;
    }
    return new ColumnConfig(COLUMN_IDS.DELETE)
      .setHeader(
        <EntityFieldPermissionTableColumnHeader
          onAction={onAction}
          data={data}
          id={COLUMN_IDS.DELETE}
          name="Delete"
          checked={!disabled && columnHeaderCheckbox[COLUMN_IDS.DELETE]}
          disabled={disabled}
        />,
      )
      .setAccessor(getDeleteAccessor)
      .addCellConfig(getDeleteCell(disabled))
      .setGetValueFromOnChange(onValueChange);
  }
  return new ColumnConfig(COLUMN_IDS.DELETE)
    .setHeader(__('Delete'))
    .setAccessor(getDeleteAccessor)
    .addCellConfig(getDeleteCell())
    .setGetValueFromOnChange(onValueChange);
});

const getColumns = defaultMemoize((permissionTableType, onAction, data, columnHeaderCheckbox, disabledFieldPermissionIds) => [
  getNameColumnConfig(permissionTableType),
  getReadColumnConfig(onAction, data, permissionTableType, columnHeaderCheckbox, disabledFieldPermissionIds),
  getCreateColumnConfig(onAction, data, permissionTableType, columnHeaderCheckbox, disabledFieldPermissionIds),
  getEditColumnConfig(onAction, data, permissionTableType, columnHeaderCheckbox, disabledFieldPermissionIds),
  getDeleteColumnConfig(onAction, data, permissionTableType, columnHeaderCheckbox, disabledFieldPermissionIds),
]);

export { getColumns };
