// Validators
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

// Renderers
import TextInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';

import EntityViewForm from '../components/entityViewForm';

import { ruleNameValidator, entityViewNameValidator } from '../../../../../../utils/formValidators';

import { entityViewRequiredRule } from '../components/entityViewForm/helpers/entityViewForm.helpers';

// Constants
import { FIELD_IDS, VIEW_TYPE_OPTIONS } from './createEntityView.constants';

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInputField,
  renderOptions: {
    label: __('Name'),
    required: true,
    validators: [isRequiredRule, ruleNameValidator, entityViewNameValidator],
  },
};

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInputField,
  renderOptions: {
    label: __('Display Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const VIEW_TYPE_FIELD = {
  id: FIELD_IDS.VIEW_TYPE,
  renderer: SelectInput,
  renderOptions: {
    label: __('Type'),
    required: true,
    options: VIEW_TYPE_OPTIONS,
  },
};

const VIEW_RECORD_TYPE_META_DATA_INPUT_FORM = {
  id: FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST,
  renderer: EntityViewForm,
  renderOptions: {
    required: true,
    validators: [entityViewRequiredRule],
  },
};

const VIEW_FORM_FIELDS = {
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.VIEW_TYPE]: VIEW_TYPE_FIELD,
  [FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST]: VIEW_RECORD_TYPE_META_DATA_INPUT_FORM,
};

export { VIEW_FORM_FIELDS };
