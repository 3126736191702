import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _noop from 'lodash/noop';

// Tekion-base
import { EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

// Tekion-components
import Card from '@tekion/tekion-components/molecules/Card';
import Avatar from '@tekion/tekion-components/atoms/Avatar';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

// Styles
import styles from './workSpaceCard.module.scss';

const WorkSpaceCard = (props) => {
  const { isHomeWorkspace, cardData, onCardClick } = props;

  const handleCardClick = useCallback(() => {
    onCardClick(_get(cardData, 'workspaceId', EMPTY_STRING));
  }, [onCardClick, cardData]);

  const getWorkspaceDisplayName = useCallback((workspace) => tget(workspace, 'workspaceName', NO_DATA), []);

  const AvatarIcon = useMemo(() => <Avatar size={30} />, []);

  const Title = useMemo(
    () => (
      <div className={styles.homeIcon}>
        <div className={styles.displayName}>{__(getWorkspaceDisplayName(cardData))}</div>
        {isHomeWorkspace && <FontIcon>icon-home1</FontIcon>}
      </div>
    ),
    [isHomeWorkspace, cardData, getWorkspaceDisplayName],
  );

  return (
    <Card hoverable className={styles.card} onClick={handleCardClick}>
      <Card.Meta avatar={AvatarIcon} title={Title} description={_get(cardData, 'description', EMPTY_STRING)} />
    </Card>
  );
};

WorkSpaceCard.propTypes = {
  isHomeWorkspace: PropTypes.bool,
  cardData: PropTypes.object,
  onCardClick: PropTypes.func,
};

WorkSpaceCard.defaultProps = {
  isHomeWorkspace: false,
  cardData: EMPTY_OBJECT,
  onCardClick: _noop,
};

export default WorkSpaceCard;
