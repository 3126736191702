import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

// Components
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import TextArea from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';
import AsyncPaginatedSelect from '../../../../../../organisms/asyncPaginatedSelect';
import EntityAsyncSelect from '../../../../../devPlatform/molecules/EntityAsyncSelect';

// Actions
import { fetchCategories, fetchGroups } from '../../../../../../actions/approvalManagement.actions';

// Helpers
import {
  generateCategoryOptionsFromHits,
  generateGroupOptionsFromHits,
  getAsyncSelectPayload,
} from '../../../../../../helpers/approval.groupCategoryHelpers';

// Constants
import FIELD_IDS from '../constants/approvalRequestForm.fieldIds';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import { CUSTOM_ENTITY_CATEGORY } from '../../../../../../constants/approvalCentre.constants';

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    size: 6,
    label: __('Name'),
    placeholder: __('Enter display name'),
    validators: [isRequiredRule],
  },
};

const GROUP_FIELD = {
  id: FIELD_IDS.GROUP,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    required: true,
    label: __('Group'),
    size: 6,
    getPayload: getAsyncSelectPayload(),
    getOptions: generateGroupOptionsFromHits(),
    validators: [isRequiredRule],
    serviceHandler: fetchGroups,
  },
};

const CATEGORY_FIELD = {
  id: FIELD_IDS.CATEGORY,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    required: true,
    label: __('Category'),
    size: 6,
    getPayload: getAsyncSelectPayload(),
    getOptions: generateCategoryOptionsFromHits([CUSTOM_ENTITY_CATEGORY]),
    validators: [isRequiredRule],
    serviceHandler: fetchCategories,
  },
};

const ENTITY_FIELD = {
  id: FIELD_IDS.TYPE,
  renderer: EntityAsyncSelect,
  renderOptions: {
    label: __('Entity'),
    placeholder: __('Choose entity'),
    size: 6,
    required: true,
    validators: [isRequiredRule],
  },
};

const COMMENT_FIELD = {
  id: FIELD_IDS.COMMENT,
  renderer: TextArea,
  renderOptions: {
    required: true,
    rows: 3,
    label: __('Comment'),
    placeholder: __('Add a comment'),
    validators: [isRequiredRule],
  },
};

const IDENTIFIER_CREATE_MODE_FIELDS = {
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.GROUP]: GROUP_FIELD,
  [FIELD_IDS.CATEGORY]: CATEGORY_FIELD,
  [FIELD_IDS.TYPE]: ENTITY_FIELD,
  [FIELD_IDS.COMMENT]: COMMENT_FIELD,
};

const IDENTIFIER_EDIT_MODE_FORM_FIELDS = {
  [FIELD_IDS.NAME]: addToRenderOptions(NAME_FIELD, { path: 'disabled', value: true }),
  [FIELD_IDS.GROUP]: addToRenderOptions(GROUP_FIELD, [
    { path: 'disabled', value: true },
    { path: 'isDisabled', value: true },
  ]),
  [FIELD_IDS.CATEGORY]: addToRenderOptions(CATEGORY_FIELD, [
    { path: 'disabled', value: true },
    { path: 'isDisabled', value: true },
    { path: 'getOptions', value: generateCategoryOptionsFromHits() },
  ]),
  [FIELD_IDS.TYPE]: addToRenderOptions(ENTITY_FIELD, [
    { path: 'disabled', value: true },
    { path: 'isDisabled', value: true },
  ]),
  [FIELD_IDS.COMMENT]: addToRenderOptions(COMMENT_FIELD, { path: 'placeholder', value: __('Add reason for editing request') }),
};

const getSettingIdentifierFields = (formMode) => {
  if (formMode === FORM_MODES.CREATE) {
    return IDENTIFIER_CREATE_MODE_FIELDS;
  }
  return IDENTIFIER_EDIT_MODE_FORM_FIELDS;
};

export default getSettingIdentifierFields;
