import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FIELD_IDS } from './metadataComponentForm.field';

import { COMPONENT_TYPE_OPTIONS_VALUES } from '../constants/metadataComponentForm.general.constants';

const rowCheck = {
  [FIELD_IDS.NAME]: ({ componentType, entityName }) =>
    componentType && entityName && componentType !== COMPONENT_TYPE_OPTIONS_VALUES.ENTITY_DEFINITION,
};

const componentFormSections = (componentType, entityName) => [
  {
    header: {
      className: 'p-t-12',
      label: __('Component details'),
    },
    rows: [
      {
        columns: [FIELD_IDS.COMPONENT_TYPE],
      },
      {
        columns: [FIELD_IDS.ENTITY_NAME],
      },
      {
        columns: filterRows([FIELD_IDS.NAME], { componentType, entityName }, rowCheck),
      },
    ],
  },
];

export default componentFormSections;
