import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import { COLUMN_IDS } from './fieldListTable.constant';

const COLUMNS = [
  {
    Header: __('Name'),
    accessor: COLUMN_IDS.NAME,
    id: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
];

const TABLE_MANAGER_PROPS = {
  showSearch: false,
  showFilter: false,
  showHeader: false,
  showSubHeader: false,
  showRefreshIcon: false,
};

const EDIT_ACTION = {
  id: 'EDIT',
  name: __('Edit'),
};

const DELETE_ACTION = {
  id: 'DELETE',
  name: __('Delete'),
};

const getTableProps = (handleRowAction) => ({
  minRows: 0,
  showPagination: false,
  showActions: true,
  onRowActionClick: handleRowAction,
  getRowActions: () => [EDIT_ACTION, DELETE_ACTION],
});

export { COLUMNS, TABLE_MANAGER_PROPS, getTableProps };
