import _get from 'lodash/get';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { TOASTER_TYPE, toaster } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { isErrorEmpty } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { addSelectOption, updateSelectOption } from '../../../../../../../../actions/entityManagement.actions';

import { getFieldsFormSelectOptionsValues, getSaveListOptionPayload, getSelectOptionsFormValues } from './selectOptionsForm.general.helpers';
import { getInitialState } from '../../../helpers/fieldsForm.general.helpers';

import {
  COLUMN_IDS,
  MODAL_INPUT_FIELD,
  OPTIONS_TABLE_ACTION_TYPES,
  OPTION_MODAL_ACTION_TYPES,
  ROW_OPERATION,
} from '../components/listInputTable/constants/listInputTable.constants';
import { CONTROLLING_OPTION_TABLE_COLUMN_IDS } from '../components/controllingOptionConfigTable/constants/controllingOptionConfigTable.general';
import SELECT_OPTIONS_FORM_FIELD_IDS from '../constants/selectOptionsForm.fieldsIds';
import FIELDS_FORM_FIELD_IDS from '../../../constants/fieldsForm.fieldIds';
import { FIELD_FORM_CONTEXT_ID } from '../../../constants/fieldsForm.constants';

import entityReader from '../../../../../../../../readers/entity.reader';

const handleInit = ({ getState, setState }) => {
  const { fieldsFormSelectOptionsValues = EMPTY_OBJECT } = getState();

  setState({ values: getSelectOptionsFormValues(fieldsFormSelectOptionsValues) });
};

const handleFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { values: oldValues = EMPTY_OBJECT, fieldsFormOnAction } = getState();
  const { id, value } = params;
  const values = { ...oldValues, [id]: value };

  if (id === SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD) {
    _unset(values, SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS);
  }

  setState({ values }, () =>
    fieldsFormOnAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM, value: getFieldsFormSelectOptionsValues(values) },
    }),
  );
};

const handleEditRow = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { values, fieldsFormSelectOptionsValues } = getState();
  const { nestingPath } = params;
  const [row] = nestingPath;
  const modalStateControllingField = _find(
    _get(values, SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS, []),
    (controlledOption) =>
      getArraySafeValue(_get(controlledOption, CONTROLLING_OPTION_TABLE_COLUMN_IDS.OPTION)) ===
      _get(fieldsFormSelectOptionsValues, [SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS, row, COLUMN_IDS.VALUE]),
  );

  setState({
    isModalVisible: true,
    modalData: {
      ..._get(fieldsFormSelectOptionsValues, [SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS, row]),
      operation: ROW_OPERATION.EDIT,
      ...(!_isEmpty(modalStateControllingField) && {
        [MODAL_INPUT_FIELD.CONTROLLING_OPTIONS]: _get(modalStateControllingField, CONTROLLING_OPTION_TABLE_COLUMN_IDS.VALUES),
      }),
      nestingPath,
    },
  });
};

const handleEditModeAddRow = ({ setState }) => {
  setState({
    isModalVisible: true,
    modalData: {
      operation: ROW_OPERATION.ADD,
    },
  });
};

const handleOptionModalSubmit = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { values, entity, fieldName, fieldsFormOnAction } = getState();
  const entityName = entityReader.name(entity);
  const { rowData } = params;
  const rowOperation = _get(rowData, 'operation', ROW_OPERATION.ADD);
  const { value, payload } = getSaveListOptionPayload(rowData);

  let response;

  if (rowOperation === ROW_OPERATION.ADD) {
    response = await addSelectOption(entityName, fieldName, payload);
  } else {
    response = await updateSelectOption(entityName, fieldName, value, payload);
  }

  const { response: fieldData, error } = response;

  if (_isEmpty(error)) {
    if (rowOperation === ROW_OPERATION.ADD) {
      toaster(TOASTER_TYPE.SUCCESS, __('Option added successfully!'));
    } else {
      toaster(TOASTER_TYPE.SUCCESS, __('Option updated successfully!'));
    }
    const newFieldsFormSelectOptionsValues = _get(getInitialState(fieldData), FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM);
    const optionsConfig = _get(getSelectOptionsFormValues(newFieldsFormSelectOptionsValues), SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS);

    _set(values, SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS, optionsConfig);

    setState(
      {
        isModalVisible: false,
        modalData: {},
        values,
      },
      () =>
        fieldsFormOnAction({
          type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
          payload: { id: FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM, value: getFieldsFormSelectOptionsValues(values) },
        }),
    );
  } else {
    toaster(TOASTER_TYPE.ERROR, error);
  }
};

const handleOptionModalClose = ({ setState }) => {
  setState({ isModalVisible: false });
};

const handleSubmit = ({ getState, params = EMPTY_OBJECT }) => {
  const { additional = {} } = params;
  const { formErrors } = additional;
  const { fieldsFormOnAction } = getState();

  fieldsFormOnAction({ type: FORM_ACTION_TYPES.VALIDATION_SUCCESS, payload: { id: FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM, errors: undefined } });

  _set(formErrors, FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM, false);

  if (isErrorEmpty(formErrors)) {
    triggerSubmit(FIELD_FORM_CONTEXT_ID, _cloneDeep(additional));
  }
};

const handleValidationSuccess = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { fieldsFormOnAction, values } = getState();
  const { validationTriggeredOnSubmit = false, errors = EMPTY_OBJECT } = params;
  const isControllingFieldEnabled = _get(values, SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD_ENABLED, false);

  const newErrors = {
    ...errors,
    ...(!isControllingFieldEnabled && {
      [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS]: undefined,
      [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD]: undefined,
    }),
  };

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: {
      id: FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM,
      errors: isErrorEmpty(newErrors) ? undefined : newErrors,
    },
  });

  if (isErrorEmpty(newErrors) && validationTriggeredOnSubmit) {
    handleSubmit({ getState, setState, params });
  }
};

const SELECT_OPTIONS_FORM_ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidationSuccess,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [OPTIONS_TABLE_ACTION_TYPES.EDIT_ROW]: handleEditRow,
  [OPTIONS_TABLE_ACTION_TYPES.EDIT_MODE_ADD_ROW]: handleEditModeAddRow,
  [OPTION_MODAL_ACTION_TYPES.ON_SUBMIT]: handleOptionModalSubmit,
  [OPTION_MODAL_ACTION_TYPES.ON_CLOSE]: handleOptionModalClose,
};

export default SELECT_OPTIONS_FORM_ACTION_HANDLERS;
