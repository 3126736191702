import { compose } from 'recompose';
import _capitalize from 'lodash/capitalize';
import _property from 'lodash/property';

import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

// Constants
import { NO_DATA } from '@tekion/tekion-base/app.constants';
import COLUMN_IDS from './fieldsList.columnIds';
import { FIELD_STATUS_COLOR_MAP, FIELD_STATUS_LABEL_MAP } from './fieldsList.constants';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const getStatusProps = () => ({
  colorMap: FIELD_STATUS_COLOR_MAP,
  labelMap: FIELD_STATUS_LABEL_MAP,
});

const capitalizeValue = (value) => _capitalize(value) || NO_DATA;

const COLUMN_CONFIG = [
  {
    Header: __('Display name'),
    accessor: COLUMN_IDS.DISPLAY_NAME,
    id: COLUMN_IDS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Name'),
    accessor: COLUMN_IDS.NAME,
    id: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Field type'),
    accessor: compose(capitalizeValue, _property(COLUMN_IDS.FIELD_TYPE)),
    id: COLUMN_IDS.FIELD_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Data type'),
    accessor: compose(capitalizeValue, _property(COLUMN_IDS.DATA_TYPE)),
    id: COLUMN_IDS.DATA_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Mandatory'),
    accessor: COLUMN_IDS.MANDATORY,
    id: COLUMN_IDS.MANDATORY,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
];

export default COLUMN_CONFIG;
