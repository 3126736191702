import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';

import IconAsBtn from '@tekion/tekion-components/atoms/iconAsBtn';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import NotificationTimeStamp from '../notificationTimeStamp';
import communicationsActions from '../../../../../../../../../../actions/communications.actions';

import { getCustomEntityNotificationFields } from '../../../../../../helpers/notification.eventHelpers';
import { PUSH_NOTIFICATION_EVENT_TYPES, NOTIFICATION_FIELD_IDS } from '../../../../../../../../../../constants/notification.constants';

import styles from './notificationCard.module.scss';

const NotificationCard = ({ cardContainerClassName, notification, showNotificationHeader, removeNotification }) => {
  const subject = tget(notification, NOTIFICATION_FIELD_IDS.SUBJECT);
  const body = tget(notification, NOTIFICATION_FIELD_IDS.BODY);
  const eventType = tget(notification, NOTIFICATION_FIELD_IDS.EVENT_TYPE);
  const createdTime = tget(notification, NOTIFICATION_FIELD_IDS.CREATED_TIME);
  const groupKey = tget(notification, NOTIFICATION_FIELD_IDS.GROUP_KEY, __('Notification group'));
  const notificationId = tget(notification, NOTIFICATION_FIELD_IDS.NOTIFICATION_ID);

  const handleClick = async () => {
    switch (eventType) {
      case PUSH_NOTIFICATION_EVENT_TYPES.CUSTOM_ENTITY: {
        const { id, applications, entityName, recordId, status } = getCustomEntityNotificationFields(notification);
        const application = getArraySafeValue(applications);
        // Need to await as we need to wait for the API call to complete before redirecting. otherwise call gets cancelled
        await communicationsActions.markNotificationAsRead(id, status);

        const notificationRoute = `notification?application=${application}&entity=${entityName}&recordId=${recordId}`;
        window.location.href = notificationRoute;
        break;
      }
      default:
    }
  };

  const handleClearNotificationClick = async () => {
    const response = await communicationsActions.clearNotifications([notificationId]);

    if (!_isEmpty(response)) {
      removeNotification(notificationId);
    }
  };

  return (
    <div className={`${styles.card} ${cardContainerClassName}`} onClick={handleClick}>
      {showNotificationHeader && (
        <div className={styles.header}>
          <div className={styles.groupKey}>{groupKey}</div>
          <IconAsBtn id="CLEAR_BUTTON" className={styles.clearIcon} size={SIZES.S} onClick={handleClearNotificationClick}>
            icon-cross
          </IconAsBtn>
        </div>
      )}
      <div className={styles.subHeader}>
        <div className={styles.subject}>{subject}</div>
        <NotificationTimeStamp notificationTimeInEpoch={createdTime} />
      </div>
      <div className={styles.body}>{body}</div>
    </div>
  );
};

NotificationCard.propTypes = {
  showNotificationHeader: PropTypes.bool,
  cardContainerClassName: PropTypes.string,
  notification: PropTypes.object.isRequired,
  removeNotification: PropTypes.func,
};

NotificationCard.defaultProps = {
  showNotificationHeader: false,
  cardContainerClassName: EMPTY_STRING,
  removeNotification: _noop,
};

export default NotificationCard;
