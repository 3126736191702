/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

// Containers
import withFormPageState from '@tekion/tekion-components/connectors/withFormPageState';
import withActions from '@tekion/tekion-components/connectors/withActions';

// constants
import { FORM_CONTEXT_ID } from './constants/linkInsertionForm.general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ACTION_HANDLERS from './helpers/linkInsertionForm.actionHandlers';

// components
import { FormWithSubmission } from '@tekion/tekion-components/pages/formPage';

// helpers
import { getSections, getFields } from './helpers/linkInsertionForm.config';

// hooks
import useFormActions from './hooks/useFormActions';
import useFormInitialValues from './hooks/useFormInitialValues';
import useEditor from '../../../../hooks/useEditor';

const FormWithState = withFormPageState()(FormWithSubmission);

function LinkInsertionForm(props) {
  const { onAction, selectedLinkNode } = props;

  const editor = useEditor();

  const { handleLinkCreation } = useFormActions(onAction, editor);

  const initialValues = useFormInitialValues(editor, selectedLinkNode);

  return (
    <FormWithState
      initialValues={initialValues}
      getFields={getFields}
      getSections={getSections}
      contextId={FORM_CONTEXT_ID}
      onSubmit={handleLinkCreation}
    />
  );
}

LinkInsertionForm.propTypes = {
  onAction: PropTypes.func,
  selectedLinkNode: PropTypes.array,
};

LinkInsertionForm.defaultProps = {
  onAction: _noop,
  selectedLinkNode: undefined,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(LinkInsertionForm);
