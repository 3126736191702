import { tget } from '@tekion/tekion-base/utils/general';
import standardFieldOptionMapper from '@tekion/tekion-base/utils/optionMappers/standardFieldMapper';

import { WWD_FIELD_IDS } from '../../../../../../../constants/wwdPermissions.constants';
import { PUBLIC_READONLY } from '../../../../../../../constants/wwdAccessTypes.constants';
import { READ_WRITE, RECORD_SHARING_ACCESS_TYPES } from '../../../../../../../constants/recordSharingAccessTypes.constants';

const getRecordSharingAccessTypeOptions = (wwdDataOfEntity) => {
  const wwdEntityAccessType = tget(wwdDataOfEntity, WWD_FIELD_IDS.ACCESS_TYPE);
  switch (wwdEntityAccessType) {
    case PUBLIC_READONLY.id: {
      return standardFieldOptionMapper('', [READ_WRITE]);
    }
    default: {
      return standardFieldOptionMapper('', RECORD_SHARING_ACCESS_TYPES);
    }
  }
};

export default getRecordSharingAccessTypeOptions;
