import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _castArray from 'lodash/castArray';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _isNil from 'lodash/isNil';
import _includes from 'lodash/includes';
import _unset from 'lodash/unset';

import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';

import { fetchEntityRecords } from '../../../../../../../../actions/recordManagement.actions';

import FIELD_TYPES from '../../../../../../../../constants/fieldDefinition.fieldTypes';
import SYSTEM_DEFINED_FIELDS from '../../../../../../../../constants/systemDefinedFields';
import DATA_TYPES from '../../../../../../../../constants/fieldDefinition.dataTypes';
import {
  APPROVAL_CENTRE_FIELD_IDS,
  APPROVAL_EXECUTION_DETAIL_FIELD_IDS,
  APPROVAL_EXECUTION_STATUS_TYPES,
  APPROVAL_STATUS_TYPES,
} from '../../../../../../../../constants/approvalCentre.constants';

import fieldDefinitionReader from '../../../../../../../../readers/fieldDefinition.reader';

const compareNewRequestToPrevEntityRecordValue = async (prevEntityRecord, requestEntityRecord, fieldDefByName) => {
  _forEach(fieldDefByName, (fieldDef, fieldName) => {
    const isFieldSystemDefined = _includes(SYSTEM_DEFINED_FIELDS, fieldName);
    let prevRecordFieldValue = isFieldSystemDefined ? tget(prevEntityRecord, fieldName) : tget(prevEntityRecord, `entity.${fieldName}`);

    const newRequestedRecordFieldValue = isFieldSystemDefined
      ? tget(requestEntityRecord, fieldName)
      : tget(requestEntityRecord, `entity.${fieldName}`);

    const fieldType = fieldDefinitionReader.fieldType(fieldDef);

    if (!_isNil(newRequestedRecordFieldValue)) {
      if (fieldType === FIELD_TYPES.RELATIONSHIP) {
        const lookupByField = fieldDefinitionReader.lookupField(fieldDef);
        prevRecordFieldValue = isFieldSystemDefined
          ? tget(prevEntityRecord, fieldName)
          : tget(prevEntityRecord, ['entity', `${fieldName}.${lookupByField}`]);
      }

      if (_isEqual(prevRecordFieldValue, newRequestedRecordFieldValue)) {
        if (isFieldSystemDefined) {
          _unset(requestEntityRecord, fieldName, newRequestedRecordFieldValue);
        } else {
          _unset(requestEntityRecord, `entity.${fieldName}`, newRequestedRecordFieldValue);
        }
      }
    }
  });
};

const resolveRelationshipFieldsForRequestedData = async (relationshipFields, fieldDefByName, resolvedRequestedData) => {
  const promises = [];
  _forEach(relationshipFields, (recordFieldInfo) => {
    const recordField = tget(recordFieldInfo, 'recordField', '');
    const recordFieldValue = tget(recordFieldInfo, 'recordFieldValue', '');
    const fieldDef = tget(fieldDefByName, recordField);
    const lookupByField = fieldDefinitionReader.lookupField(fieldDef);
    const lookupFieldEntityType = fieldDefinitionReader.lookupFieldEntityType(fieldDef);

    const payload = { filters: [{ field: lookupByField, filterType: OPERATORS.IN, values: _castArray(recordFieldValue) }] };
    promises.push(fetchEntityRecords(lookupFieldEntityType, payload));
  });

  const lookupEntityRecordsResponses = await Promise.all(promises);

  _forEach(lookupEntityRecordsResponses, (lookupEntityRecordsResponse, index) => {
    const recordField = tget(relationshipFields, `${index}.recordField`, '');
    const recordFieldValue = tget(relationshipFields, `${index}.recordFieldValue`, '');
    const isFieldSystemDefined = _includes(SYSTEM_DEFINED_FIELDS, recordField);

    const fieldDef = tget(fieldDefByName, recordField);
    const lookupDisplayField = fieldDefinitionReader.lookupFieldDisplayField(fieldDef);
    const lookupEntityRecords = _keyBy(tget(lookupEntityRecordsResponse, 'hits'), 'id');
    const isMultiValued = fieldDefinitionReader.multiValued(fieldDef);
    const isLookupFieldSystemDefined = _includes(SYSTEM_DEFINED_FIELDS, lookupDisplayField);

    let resolvedRecordFieldValue = '';

    if (isMultiValued) {
      resolvedRecordFieldValue = _map(recordFieldValue, (val) =>
        isLookupFieldSystemDefined
          ? tget(lookupEntityRecords, `${val}.${lookupDisplayField}`)
          : tget(lookupEntityRecords, `${val}.entity.${lookupDisplayField}`),
      );
    } else {
      resolvedRecordFieldValue = isLookupFieldSystemDefined
        ? tget(lookupEntityRecords, `${recordFieldValue}.${lookupDisplayField}`)
        : tget(lookupEntityRecords, `${recordFieldValue}.entity.${lookupDisplayField}`);
    }

    if (isFieldSystemDefined) {
      _set(resolvedRequestedData, recordField, resolvedRecordFieldValue);
    } else {
      _set(resolvedRequestedData, `entity.${recordField}`, resolvedRecordFieldValue);
    }
  });
};

const resolveNestedRelationshipFields = async (complexFieldName, complexFieldValue, fieldDefByName, resolvedRequestedComplexField) => {
  const relationshipFields = [];
  _forEach(complexFieldValue, (fieldValue, fieldName) => {
    const fieldDef = tget(fieldDefByName, fieldName);
    const fieldType = fieldDefinitionReader.fieldType(fieldDef);
    if (fieldType === FIELD_TYPES.RELATIONSHIP) {
      relationshipFields.push({ recordField: fieldName, fieldValue });
    } else {
      _set(resolvedRequestedComplexField, fieldName, fieldValue);
    }
  });

  if (!_isEmpty(relationshipFields)) {
    await resolveRelationshipFieldsForRequestedData(relationshipFields, fieldDefByName, resolvedRequestedComplexField);
  }

  return { [complexFieldName]: resolvedRequestedComplexField };
};

const resolveComplexFields = async (complexFields, fieldDefByName, resolvedRequestedData) => {
  const resolvedComplexFieldPromises = [];
  _forEach(complexFields, (complexFieldInfo) => {
    const complexFieldName = tget(complexFieldInfo, 'recordField', '');
    const complexFieldValue = tget(complexFieldInfo, 'recordFieldValue', '');
    const complexFieldDef = tget(fieldDefByName, complexFieldName);
    const complexEntityFieldDefinitionsByName = _keyBy(fieldDefinitionReader.complexEntityFieldDefinitions(complexFieldDef), 'name');
    const resolvedRequestedComplexField = {};
    resolvedComplexFieldPromises.push(
      resolveNestedRelationshipFields(complexFieldName, complexFieldValue, complexEntityFieldDefinitionsByName, resolvedRequestedComplexField),
    );
  });

  const resolvedComplexFieldsResponses = await Promise.all(resolvedComplexFieldPromises);

  _forEach(resolvedComplexFieldsResponses, (resolvedComplexFieldInfo) => {
    _forEach(resolvedComplexFieldInfo, (resolvedValue, field) => {
      _set(resolvedRequestedData, `entity.${field}`, resolvedValue);
    });
  });
};

const resolvedRequestedEntityRecord = async (requestEntityRecord, fieldDefByName, resolvedRequestedData) => {
  const relationshipFields = [];
  const complexFields = [];

  _forEach(fieldDefByName, (fieldDef, fieldName) => {
    const fieldType = fieldDefinitionReader.fieldType(fieldDef);
    const dataType = fieldDefinitionReader.dataType(fieldDef);
    const isFieldSystemDefined = _includes(SYSTEM_DEFINED_FIELDS, fieldName);

    const recordFieldValue = isFieldSystemDefined ? tget(requestEntityRecord, fieldName) : tget(requestEntityRecord, `entity.${fieldName}`);

    if (!_isNil(recordFieldValue)) {
      if (dataType === DATA_TYPES.COMPLEX) {
        complexFields.push({ recordField: fieldName, recordFieldValue });
      } else if (fieldType === FIELD_TYPES.RELATIONSHIP) {
        relationshipFields.push({ recordField: fieldName, recordFieldValue });
      } else if (isFieldSystemDefined) {
        _set(resolvedRequestedData, fieldName, recordFieldValue);
      } else {
        _set(resolvedRequestedData, `entity.${fieldName}`, recordFieldValue);
      }
    }
  });

  if (!_isEmpty(relationshipFields)) {
    await resolveRelationshipFieldsForRequestedData(relationshipFields, fieldDefByName, resolvedRequestedData);
  }

  if (!_isEmpty(complexFields)) {
    await resolveComplexFields(complexFields, fieldDefByName, resolvedRequestedData);
  }
};

const getEntityRecordId = (approvalRequest) => {
  let entityRecordId = '';
  const status = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.STATUS, NO_DATA);
  if (status === APPROVAL_STATUS_TYPES.COMPLETED) {
    const approvalExecutionDetail = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.APPROVAL_EXECUTION_DETAIL, EMPTY_OBJECT);
    const executionStatus = tget(
      approvalExecutionDetail,
      APPROVAL_EXECUTION_DETAIL_FIELD_IDS.EXECUTION_STATUS,
      APPROVAL_EXECUTION_STATUS_TYPES.FAILURE,
    );

    if (executionStatus === APPROVAL_EXECUTION_STATUS_TYPES.SUCCESS) {
      const executionResponse = tget(approvalExecutionDetail, APPROVAL_EXECUTION_DETAIL_FIELD_IDS.EXECUTION_RESPONSE, EMPTY_OBJECT);
      entityRecordId = tget(executionResponse, APPROVAL_CENTRE_FIELD_IDS.ID, EMPTY_STRING);
      return entityRecordId;
    }
  }

  entityRecordId = tget(approvalRequest, `${APPROVAL_CENTRE_FIELD_IDS.DATA}.${APPROVAL_CENTRE_FIELD_IDS.ID}`, EMPTY_STRING);

  return entityRecordId;
};

export { resolvedRequestedEntityRecord, compareNewRequestToPrevEntityRecordValue, getEntityRecordId };
