import _pick from 'lodash/pick';
import _flatten from 'lodash/flatten';
import _values from 'lodash/values';
import _reduce from 'lodash/reduce';
import _concat from 'lodash/concat';

// Plate
import { createReactPlugin, createHistoryPlugin } from '@udecode/plate-core';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';

import { createBoldPlugin, createItalicPlugin, createUnderlinePlugin, createStrikethroughPlugin } from '@udecode/plate-basic-marks';
import { createFontBackgroundColorPlugin, createFontColorPlugin, createFontSizePlugin } from '@udecode/plate-font';
import { createBasicElementPlugins } from '@udecode/plate-basic-elements';
import { ELEMENT_TD } from '@udecode/plate-table';
import { createSoftBreakPlugin, createExitBreakPlugin } from '@udecode/plate-break';
import { createDeserializeHTMLPlugin } from '@udecode/plate-html-serializer';
import { createTrailingBlockPlugin } from '@udecode/plate-trailing-block';
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote';
import { ELEMENT_CODE_BLOCK } from '@udecode/plate-code-block';
import { createListPlugin } from '@udecode/plate-list';
import { createLinkPlugin } from '@udecode/plate-link';

import { createAutoformatPlugin } from '@udecode/plate-autoformat';
import { autoformatRules } from './config/autoformat/autoformatRules';

// custom plugins
import createImagePlugin from './image';
import createVideoPlugin from './video';
import createVoidSelectionPlugin from './voidSelection';
import createDefaultElementPlugin from './defaultElementPlugin';
import createTableCellNormalizerPlugin from './tableCellNormalizer';
import createTablePlugin from './table';
// import createTemplateVariablePlugin from './templateVariable';

import { PLUGINS_KEYS, PLUGINS_FOR_TOOLBAR_GROUPS, PLUGINS_ORDER } from '../constants/richTextEditor.plugins';
import createAlignmentPlugin from './alignment';

const BASIC_PLUGINS = {
  [PLUGINS_KEYS.REACT]: createReactPlugin(), // withReact
  [PLUGINS_KEYS.HISTORY]: createHistoryPlugin(), // withHistory
  [PLUGINS_KEYS.BASIC]: [...createBasicElementPlugins()], // creates blockquote, code block, paragraph and heading plugins
  [PLUGINS_KEYS.VOID_SELECTION]: createVoidSelectionPlugin(),
  // helpers
  [PLUGINS_KEYS.SOFT_BREAK]: createSoftBreakPlugin({
    rules: [
      { hotkey: 'shift+enter' },
      {
        hotkey: 'enter',
        query: {
          allow: [ELEMENT_CODE_BLOCK, ELEMENT_BLOCKQUOTE, ELEMENT_TD],
        },
      },
    ],
  }),
  [PLUGINS_KEYS.EXIT_BREAK]: createExitBreakPlugin({
    rules: [
      {
        hotkey: 'mod+enter',
      },
      {
        hotkey: 'mod+shift+enter',
        before: true,
      },
    ],
  }),
  [PLUGINS_KEYS.TRAILING_BLOCK]: createTrailingBlockPlugin({
    type: ELEMENT_PARAGRAPH,
  }),
  [PLUGINS_KEYS.AUTO_FORMAT]: createAutoformatPlugin({
    rules: autoformatRules,
  }),
  [PLUGINS_KEYS.DEFAULT_ELEMENT]: createDefaultElementPlugin(),
  [PLUGINS_KEYS.BACKGROUND_COLOR]: createFontBackgroundColorPlugin(),
};

const CUSTOM_PLUGINS = {
  // marks
  [PLUGINS_KEYS.BOLD]: createBoldPlugin(), // bold mark
  [PLUGINS_KEYS.ITALIC]: createItalicPlugin(), // italic mark
  [PLUGINS_KEYS.UNDERLINE]: createUnderlinePlugin(), // underline mark
  [PLUGINS_KEYS.STRIKE]: createStrikethroughPlugin(), // strikethrough mark
  [PLUGINS_KEYS.ALIGN]: createAlignmentPlugin(),
  // media
  [PLUGINS_KEYS.IMAGE]: createImagePlugin(),
  [PLUGINS_KEYS.VIDEO]: createVideoPlugin(),

  [PLUGINS_KEYS.FONT_COLOR]: createFontColorPlugin(),
  // createFontBackgroundColorPlugin(),
  fontSize: createFontSizePlugin(),
  list: createListPlugin(),
  link: createLinkPlugin(),

  // table
  [PLUGINS_KEYS.TABLE]: createTablePlugin(),
  [PLUGINS_KEYS.TABLE_CELL]: createTableCellNormalizerPlugin(),
};

const getPlugins = (toolbarGroups) => {
  const pluginsForToolbarGroups = _flatten(_values(_pick(PLUGINS_FOR_TOOLBAR_GROUPS, toolbarGroups)));
  const pluginsWithKeys = { ...BASIC_PLUGINS, ..._pick(CUSTOM_PLUGINS, pluginsForToolbarGroups) };
  const sortedPlugins = _reduce(
    PLUGINS_ORDER,
    (acc, value) => {
      if (pluginsWithKeys[value]) {
        return _concat(acc, pluginsWithKeys[value]);
      }
      return acc;
    },
    [],
  );

  return _concat(sortedPlugins, createDeserializeHTMLPlugin({ plugins: sortedPlugins }));
};

export { BASIC_PLUGINS, getPlugins };
