import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

export const COLUMN_IDS = {
  PROJECT_NAME: 'name',
  PROJECT_DISPLAY_NAME: 'displayName',
};

const PROJECT_LIST_COLUMNS = [
  {
    id: COLUMN_IDS.PROJECT_NAME,
    Header: __('Project Name'),
    accessor: COLUMN_IDS.PROJECT_NAME,
    searchable: true,
    Cell: TextRenderer,
  },
  {
    id: COLUMN_IDS.PROJECT_DISPLAY_NAME,
    Header: __('Display Name'),
    accessor: COLUMN_IDS.PROJECT_DISPLAY_NAME,
    searchable: true,
    Cell: TextRenderer,
  },
];

export default PROJECT_LIST_COLUMNS;
