import React, { useCallback, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _size from 'lodash/size';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Components
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';
import withSize from '../../../../../../../connectors/withSize';

// Helpers
import { createTableProps, createFilterProps, getTableColumnsFromWorkflowMode } from './helpers/workflowList.helpers';
import ACTION_HANDLERS from './workflowList.actionHandlers';

// Constants
import { SUB_HEADER_PROPS, TABLE_MANAGER_PROPS } from './constants/workflowList.general';
import ACTION_TYPES from './constants/workflowList.actionTypes';

const WorkflowList = ({
  isLoading,
  isConfirmDialogVisible,
  contentHeight,
  workflowMode,
  modalTitle,
  modalContent,
  searchText,
  nextPageToken,
  data,
  selectedFilters,
  onAction,
}) => {
  const columns = getTableColumnsFromWorkflowMode(workflowMode);
  const tableProps = useMemo(() => createTableProps(isLoading, _size(data), onAction), [isLoading, data, onAction]);
  const filterProps = useMemo(() => createFilterProps(onAction), [onAction]);

  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CANCEL_CONFIRMATION_MODAL,
    });
  }, [onAction]);

  const handleConfirmationDialogSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_SUBMIT_CONFIRMATION_MODAL });
  }, [onAction]);

  return (
    <Page style={{ height: contentHeight }}>
      <TableManager
        tokenPagination
        nextPageToken={nextPageToken}
        searchText={searchText}
        subHeaderProps={SUB_HEADER_PROPS}
        tableManagerProps={TABLE_MANAGER_PROPS}
        tableProps={tableProps}
        columns={columns}
        data={data}
        filterProps={filterProps}
        selectedFilters={selectedFilters}
        onAction={onAction}
      />
      <ConfirmationDialog
        isVisible={isConfirmDialogVisible}
        title={modalTitle}
        content={modalContent}
        onSubmit={handleConfirmationDialogSubmit}
        onCancel={handleConfirmationDialogCancel}
      />
    </Page>
  );
};

WorkflowList.propTypes = {
  isLoading: PropTypes.bool,
  isConfirmDialogVisible: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  workflowMode: PropTypes.string.isRequired,
  searchText: PropTypes.string,
  nextPageToken: PropTypes.string,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.string,
  data: PropTypes.array,
  selectedFilters: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

WorkflowList.defaultProps = {
  isLoading: false,
  isConfirmDialogVisible: false,
  searchText: EMPTY_STRING,
  nextPageToken: EMPTY_STRING,
  modalTitle: EMPTY_STRING,
  modalContent: EMPTY_STRING,
  data: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: true }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(WorkflowList);
