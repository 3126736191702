import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import _debounce from 'lodash/debounce';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';

// Tekion-base
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Tekion-components
import AppLogo from '@tekion/tekion-components/atoms/AppLogo';

// Components
import UserAvatar from './components/UserAvatar';
import SideNav from './components/SideNav';
import WorkspaceModal from '../../molecules/WorkSpaceModal';
import Notifications from './components/notificationV2/Notifications';

// Hooks
import usePusher from '../../hooks/usePusher';

// Helpers
import { getScaleAdjustedSize, setDisplayScaleAutomatically } from './helpers/appSkeleton.general';
import { setValueInLocalStorage } from '../../utils/localStorage';

// Constants
import { BASE_ROUTE, STUDIO_ROUTE, TENANT_UNIVERSE_PAGE_ID, TENANT_UNIVERSE_BASE_ROUTE } from '../../constants/routes';
import { SIDEBAR_WIDTH, HEADER_HEIGHT, MENU_OPTIONS, TENANT_UNIVERSE_MENU_OPTION, SIDE_NAVIGATION_TREE_WIDTH } from './constants/appSkeleton.general';
import LS_CACHE_KEYS from '../../constants/localStorage.cacheKeys';

import localStorageReader from '../../readers/localStorage.reader';
import userReader, { USER_WORKSPACE_ID } from '../../readers/loginResponse.reader';

// Context
import { WorkSpaceSizeContext } from '../../context/workSpaceSize.context';

// Styles
import styles from './appSkeleton.module.scss';

const AppSkeleton = ({ children, history }) => {
  const [workSpaceSize, setWorkSpaceSize] = useState({});
  const [isWorkSpaceModalVisible, setWorkSpaceModalVisibility] = useState(false);
  const [workSpaceData, setWorkSpaceData] = useState([]);

  usePusher();

  const onBodyResize = useCallback(() => {
    const { height, width } = document.body.getBoundingClientRect();
    setDisplayScaleAutomatically(width);

    const pathName = history.location.pathname;
    let sideBarWidth = SIDEBAR_WIDTH;

    if (_includes(pathName, STUDIO_ROUTE)) {
      sideBarWidth += SIDE_NAVIGATION_TREE_WIDTH;
    }

    const contentHeight = height - getScaleAdjustedSize(HEADER_HEIGHT);
    const contentWidth = width - getScaleAdjustedSize(sideBarWidth);
    setWorkSpaceSize({ height, width, contentHeight, contentWidth });
  }, [history.location.pathname]);

  const onBodyResizeDebounced = useMemo(() => _debounce(onBodyResize, 300), [onBodyResize]);

  useEffect(() => {
    const userInfo = localStorageReader.userInfo();
    if (!_isEmpty(userInfo)) {
      const userWorkspaceDetails = userReader.userWorkspaceDetails(userInfo);
      setWorkSpaceData(userWorkspaceDetails);
    }
  }, []);

  useEffect(() => {
    // Invoking this function when user routes to different screen as content width might change.
    // For eg:- When from studio side user goes to experience there side navigation tree won't be present
    onBodyResize();

    window.addEventListener('resize', onBodyResizeDebounced);

    return () => {
      window.removeEventListener('resize', onBodyResizeDebounced);
    };
  }, [onBodyResize, onBodyResizeDebounced]);

  const getUniverseWorkspaceData = useCallback(() => {
    const universeWorkspaceData = _filter(workSpaceData, (workspace) => _includes(_get(workspace, 'workspaceId', EMPTY_STRING), 'universe'));
    return universeWorkspaceData;
  }, [workSpaceData]);

  const handleUserProfileItemClick = useCallback(
    (path) => {
      if (path === TENANT_UNIVERSE_BASE_ROUTE) {
        const universeWorkspaceData = _head(getUniverseWorkspaceData());
        const workspaceId = _get(universeWorkspaceData, 'workspaceId', EMPTY_STRING);
        const userInfo = localStorageReader.userInfo();
        if (!_isEmpty(userInfo)) {
          const updatedUserInfo = { ...userInfo, [USER_WORKSPACE_ID]: workspaceId };
          setValueInLocalStorage(LS_CACHE_KEYS.USER, JSON.stringify(updatedUserInfo));
          window.location.href = TENANT_UNIVERSE_BASE_ROUTE;
        }
      } else {
        history.push(path);
      }
    },
    [history, getUniverseWorkspaceData],
  );

  const handleWorkSpaceModalVisibility = useCallback(() => {
    setWorkSpaceModalVisibility((value) => !value);
  }, []);

  const handleWorkSpaceItemClick = useCallback((workSpaceId) => {
    const userInfo = localStorageReader.userInfo();
    if (!_isEmpty(userInfo)) {
      const updatedUserInfo = { ...userInfo, [USER_WORKSPACE_ID]: workSpaceId };
      setValueInLocalStorage(LS_CACHE_KEYS.USER, JSON.stringify(updatedUserInfo));
      const currentPathname = window.location.pathname;
      if (_includes(currentPathname, TENANT_UNIVERSE_PAGE_ID) || !_includes(currentPathname, STUDIO_ROUTE)) {
        window.location.href = `${BASE_ROUTE}`;
      } else {
        window.location.href = `${STUDIO_ROUTE}`;
      }
    }
  }, []);
  // TODO:  To find a better way of above implementation

  const isTenantUniverseAccessibleToUser = useMemo(
    () => _reduce(workSpaceData, (ans, data) => ans || _includes(_get(data, 'workspaceId', EMPTY_STRING), 'universe'), false),
    [workSpaceData],
  );

  const userMenuOptions = useMemo(() => {
    if (isTenantUniverseAccessibleToUser) {
      return [TENANT_UNIVERSE_MENU_OPTION, ...MENU_OPTIONS];
    }
    return MENU_OPTIONS;
  }, [isTenantUniverseAccessibleToUser]);

  return (
    <div className={styles.container}>
      <div className={styles.topHeader}>
        <AppLogo useLink={false} href="/" />
        <div className="flex">
          <WorkSpaceSizeContext.Provider value={workSpaceSize}>
            <Notifications />
          </WorkSpaceSizeContext.Provider>
          <UserAvatar
            menuOptions={userMenuOptions}
            workSpaceOptions={workSpaceData}
            onMenuItemClick={handleUserProfileItemClick}
            onSubMenuWorkSpaceItemClick={handleWorkSpaceItemClick}
            onWorkSpaceModalVisibilityChange={handleWorkSpaceModalVisibility}
          />
          <WorkspaceModal
            allWorkSpaces={workSpaceData}
            isVisible={isWorkSpaceModalVisible}
            handleWorkSpaceItemClick={handleWorkSpaceItemClick}
            handleWorkSpaceModalVisibility={handleWorkSpaceModalVisibility}
          />
        </div>
      </div>
      <div className={styles.appWrapper}>
        <SideNav history={history} />
        <WorkSpaceSizeContext.Provider value={workSpaceSize}>
          <div className="full-height full-width">{children}</div>
        </WorkSpaceSizeContext.Provider>
      </div>
    </div>
  );
};

AppSkeleton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

AppSkeleton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(AppSkeleton);
