import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { isErrorEmpty } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { validateForm } from './updatePasswordPage.helpers';

const validateErrors = ({ params, getState, setState }) => {
  const { values } = getState();
  const { errors = EMPTY_OBJECT } = params;

  setState({
    errors: validateForm(values, errors),
  });
};

const handleSubmit = ({ getState, setState }) => {
  const { onSubmit, values } = getState();
  const errors = validateForm(values, EMPTY_OBJECT);
  if (!isErrorEmpty(errors)) {
    setState({
      errors,
    });
    return;
  }
  onSubmit(values);
};

const ACTION_HANDLER = {
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: validateErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
};

export default ACTION_HANDLER;
