import { defaultMemoize } from 'reselect';

import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import addOptionsToAdditional from '@tekion/tekion-base/utils/addOptionsToAdditional';
import Button from '@tekion/tekion-components/atoms/Button';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { pascalCase } from '../../../../../helpers/general.helpers';

import { TEXT_STARTS_WITH } from '../../../../../constants/filter.constants';
import { DEFAULT_FILTERS, DEFAULT_FILTER_GROUP, VIEW_BUILDER_LIST_TAB_IDS, VIEW_TYPES, VIEW_TYPE_FILTER } from '../constants/viewBuilderList.general';
import { ROW_ACTIONS } from './viewBuilderList.columns';
import { ENTITY_VIEW_LIST_COLUMN_IDS, RECORD_TYPE_LIST_COLUMN_IDS } from '../constants/viewBuilderList.columnIds';
import ACTION_TYPES from '../constants/viewBuilderList.actionTypes';
import { VIEW_TYPES as VIEW_BUILDER_VIEW_TYPES } from '../../../../../constants/viewBuilder.constants';
// import FilterBuilder from '../../../../../builders/filterBuilder';

const createTableProps = defaultMemoize((isLoading, totalNumberOfEntries, rows, currentPage, selectedTabId, onAction) => ({
  loading: isLoading,
  currentPage: currentPage + 1,
  showPagination: true,
  pageSize: rows,
  resultsPerPage: rows,
  totalNumberOfEntries,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  rows,
  rowHeight: 35,
  minRows: 0,
  showActions: true,
  getRowActions: () => ROW_ACTIONS[selectedTabId],
  onRowActionClick: (type, payload) => onAction({ type, payload }),
}));

const getTableSubHeaderProps = (tabName) => ({
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __(`Create ${pascalCase(tabName)}`),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_VIEW_BUILDER,
    },
  ],
});

const getPayloadForUserIds = defaultMemoize((ids) => ({
  filters: [
    {
      field: 'id',
      values: ids,
      filterType: 'IN',
    },
  ],
}));

const getOptions = defaultMemoize((options) =>
  _map(options, (option) => ({
    value: option.value,
    label: option.label,
  })),
);

const getFilterProps = defaultMemoize((selectedFilters, selectedFilterGroup) => {
  const filterTypes = [addOptionsToAdditional(VIEW_TYPE_FILTER, getOptions(VIEW_TYPES))];

  return {
    filterTypes,
    selectedFilters,
    appliedFilterGroup: selectedFilterGroup || DEFAULT_FILTER_GROUP,
    defaultFilterTypes: DEFAULT_FILTERS,
  };
});

const getModifiedFilters = (selectedFilters) => {
  const modifiedFilters = _reduce(
    selectedFilters,
    (result, filter) => {
      if (!_isEmpty(_get(filter, 'values'))) {
        result.push({
          values: _get(filter, 'values', EMPTY_ARRAY),
          field: _get(filter, 'type', EMPTY_STRING),
          filterType: _get(filter, 'operator', EMPTY_STRING),
        });
      }
      return result;
    },
    [],
  );
  return modifiedFilters;
};

const getPayload = defaultMemoize((entityName, searchText, searchField, pageSize, selectedFilters, nextPageToken) => {
  let payload = { rows: pageSize };

  // const filtersOfBuilder = new FilterBuilder()
  //   .addEntityNameFilter({ values: [entityName] })
  //   .addDeletedFilter({ values: [false] })
  //   .addViewTypeFilter({ values: [VIEW_BUILDER_VIEW_TYPES.RIGHT_PANE_VIEW], operator: OPERATORS.NIN })
  //   .addFilterSectionFilters(selectedFilters)
  //   .getFilterPayload();

  let filters = [
    {
      field: 'entityName',
      values: [entityName],
      filterType: OPERATORS.IN,
    },
    {
      field: 'deleted',
      values: [false],
      filterType: OPERATORS.IN,
    },
    {
      field: RECORD_TYPE_LIST_COLUMN_IDS.VIEW_TYPE,
      values: [VIEW_BUILDER_VIEW_TYPES.RIGHT_PANE_VIEW],
      filterType: OPERATORS.NIN,
    },
  ];

  if (!_isEmpty(selectedFilters)) {
    filters = [...filters, ...getModifiedFilters(selectedFilters)];
  }

  if (!_isEmpty(searchText)) {
    filters = [
      {
        field: searchField,
        values: [searchText],
        filterType: TEXT_STARTS_WITH,
      },
      ...filters,
    ];
  }

  if (!_isEmpty(filters)) {
    payload = {
      filters,
      ...payload,
    };
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      nextPageToken,
      ...payload,
    };
  }
  return payload;
});

const getSearchableColumns = (selectedTabId) => {
  if (selectedTabId === VIEW_BUILDER_LIST_TAB_IDS.RECORD_TYPE_VIEWS) {
    return [
      {
        label: __('Display Name'),
        value: RECORD_TYPE_LIST_COLUMN_IDS.VIEW_NAME,
      },
    ];
  } else if (selectedTabId === VIEW_BUILDER_LIST_TAB_IDS.ENTITY_VIEWS) {
    return [
      {
        label: __('Name'),
        value: RECORD_TYPE_LIST_COLUMN_IDS.VIEW_NAME,
      },
      {
        label: __('Display Name'),
        value: ENTITY_VIEW_LIST_COLUMN_IDS.DISPLAY_NAME,
      },
    ];
  } else {
    return [];
  }
};

export { createTableProps, getTableSubHeaderProps, getPayloadForUserIds, getFilterProps, getPayload, getSearchableColumns };
