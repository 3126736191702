import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import Tabs from '@tekion/tekion-components/molecules/Tabs';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import CustomRowActionsForm from '../customRowActionsForm';

import getFields from '../../helpers/customActionsConfigurator.fields';

import SECTION_FOR_CUSTOM_ACTIONS from '../../helpers/customActionsConfigurator.sections';
import {
  ACTIONS_CONFIGURATOR_FORM_CONTEXT_ID,
  CUSTOM_ROW_ACTION_CONFIGURATOR_FORM_CONTEXT_ID,
} from '../../constants/customActionsConfigurator.constants';
import { TAB_IDS } from './customActionsConfiguratorModal.constants';
import ACTION_TYPES from '../../constants/customActionsConfigurator.actionTypes';

import styles from '../../customActionsConfigurator.module.scss';

const CustomActionsConfiguratorModal = ({
  showActionsValue,
  customRowActionsLeftPanelSelectedIndex,
  selectedTabId,
  entity,
  formValues,
  errors,
  onAction,
}) => {
  const actionsData = tget(formValues, TAB_IDS.ACTION_BUILDER_ACTIONS_TAB, EMPTY_OBJECT);
  const customRowActionsData = tget(formValues, TAB_IDS.CUSTOM_ACTIONS_TAB, EMPTY_ARRAY);

  const actionError = tget(errors, TAB_IDS.ACTION_BUILDER_ACTIONS_TAB, EMPTY_OBJECT);
  const customActionError = tget(errors, TAB_IDS.CUSTOM_ACTIONS_TAB, EMPTY_OBJECT);

  const fields = useMemo(() => getFields(entity, actionsData, showActionsValue), [actionsData, entity, showActionsValue]);

  const handleTabChange = useCallback(
    (newTabId) => {
      if (newTabId === TAB_IDS.ACTION_BUILDER_ACTIONS_TAB && _isEmpty(customRowActionsData)) {
        onAction({
          type: FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT,
          payload: { additional: { actionType: ACTION_TYPES.ON_ACTIONS_TAB_CHANGE, value: newTabId } },
        });
        return;
      }

      const contextId =
        selectedTabId === TAB_IDS.ACTION_BUILDER_ACTIONS_TAB ? ACTIONS_CONFIGURATOR_FORM_CONTEXT_ID : CUSTOM_ROW_ACTION_CONFIGURATOR_FORM_CONTEXT_ID;
      triggerSubmit(contextId, { actionType: ACTION_TYPES.ON_ACTIONS_TAB_CHANGE, value: newTabId });
    },
    [customRowActionsData, selectedTabId, onAction],
  );

  const handleAction = useCallback(
    (fieldId) =>
      (action = EMPTY_OBJECT) => {
        const { type, payload = EMPTY_OBJECT } = action;

        switch (type) {
          case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
            let newValues = {};
            const { id, value } = payload;

            if (fieldId === TAB_IDS.ACTION_BUILDER_ACTIONS_TAB) {
              newValues = { ...actionsData };
            } else {
              newValues = [...customRowActionsData];
            }

            _set(newValues, id, value);

            onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id: fieldId, value: newValues } });
            break;
          }

          case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
            const { errors: _errors } = payload;

            onAction({ type: FORM_ACTION_TYPES.VALIDATION_SUCCESS, payload: { id: fieldId, value: _errors } });
            break;
          }

          default: {
            onAction(action);
            break;
          }
        }
      },
    [actionsData, customRowActionsData, onAction],
  );

  // Reason for TabPane force render is inside handleSubmit we are trigger submit for both the forms and as it works in async manner
  // if other is not present it won't update config and modal will remain open.
  return (
    <div className={styles.customActionsModal}>
      <Tabs activeKey={selectedTabId} onChange={handleTabChange}>
        <Tabs.TabPane forceRender key={TAB_IDS.ACTION_BUILDER_ACTIONS_TAB} tab={__('Action Builder Actions')}>
          <FormWithSubmission
            className="full-width p-t-12"
            contextId={ACTIONS_CONFIGURATOR_FORM_CONTEXT_ID}
            fields={fields}
            sections={SECTION_FOR_CUSTOM_ACTIONS}
            values={actionsData}
            errors={actionError}
            onAction={handleAction(TAB_IDS.ACTION_BUILDER_ACTIONS_TAB)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane forceRender key={TAB_IDS.CUSTOM_ACTIONS_TAB} tab={__('Custom Row Actions')}>
          <CustomRowActionsForm
            selectedLeftPanelIndex={customRowActionsLeftPanelSelectedIndex}
            entity={entity}
            values={customRowActionsData}
            errors={customActionError}
            onAction={handleAction(TAB_IDS.CUSTOM_ACTIONS_TAB)}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

CustomActionsConfiguratorModal.propTypes = {
  showActionsValue: PropTypes.bool,
  customRowActionsLeftPanelSelectedIndex: PropTypes.number,
  selectedTabId: PropTypes.string,
  entity: PropTypes.object,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

CustomActionsConfiguratorModal.defaultProps = {
  showActionsValue: false,
  customRowActionsLeftPanelSelectedIndex: 0,
  selectedTabId: TAB_IDS.ACTION_BUILDER_ACTIONS_TAB,
  entity: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  formValues: EMPTY_OBJECT,
};

export default CustomActionsConfiguratorModal;
