import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import { EDIT_DASHBOARD_COMPONENT_FIELDS } from '../constants/editDashboardComponent.general.constants';

const DASHBOARD_NAME_FIELD = {
  id: EDIT_DASHBOARD_COMPONENT_FIELDS.DASHBOARD_NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Dashboard Name'),
    validators: [isRequiredRule],
    required: true,
  },
};

const DISPLAY_NAME_FIELD = {
  id: EDIT_DASHBOARD_COMPONENT_FIELDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Display Name'),
    validators: [isRequiredRule],
    required: true,
  },
};

export { DASHBOARD_NAME_FIELD, DISPLAY_NAME_FIELD };
