const FIELD_IDS = {
  TO: 'to',
  FROM: 'from',
  CC: 'cc',
  BCC: 'bcc',
  TEMPLATES: 'templates',
  PREVIEW: 'preview',
  ADDRESSEE_VALUE: 'addresseeValue',
  MAP_OF_VARIABLES: 'fieldValueMap',
  EDITOR_ID: 'editorId',
  VARIABLE_TEXT: 'variableText',
};

const RICH_TEXT_EDITOR_FIELDS = {
  JSON_BODY: 'jsonBody',
  BODY: 'body',
};

const TOOLBAR_STYLES = {
  borderBottom: '0.1rem solid gray',
  padding: '1.2rem 0',
  minHeight: 'fit-content',
  position: 'sticky',
  top: '0',
  zIndex: '1',
};

const ACTION_TYPES = {
  INIT: 'INIT',
  ON_SELECTED_TEMPLATE_CHANGE: 'ON_SELECTED_TEMPLATE_CHANGE',
};

const EMAIL_TEMPLATE_OPERATOR_OPTIONS = [{ label: 'Is', value: 'is' }];

const EMAIL_TEMPLATE_OPERATOR_VALUE = ['is'];

const EMAIL_USER_INPUT_ID = 'emailUserInput';

const TOOLBAR_GROUPS = {
  FONT_SIZE: 'FONT_SIZE',
  LIST: 'LIST',
  MARKS: 'MARKS',
  FONT_COLOR: 'FONT_COLOR',
  ALIGNMENT: 'ALIGNMENT',
  INSERTION: 'INSERTION',
};

const VARIABLE_ERROR_MESSAGE = __('This field is mandatory');

export {
  FIELD_IDS,
  ACTION_TYPES,
  EMAIL_TEMPLATE_OPERATOR_OPTIONS,
  EMAIL_TEMPLATE_OPERATOR_VALUE,
  EMAIL_USER_INPUT_ID,
  TOOLBAR_GROUPS,
  TOOLBAR_STYLES,
  RICH_TEXT_EDITOR_FIELDS,
  VARIABLE_ERROR_MESSAGE,
};
