import _get from 'lodash/get';
import _head from 'lodash/head';
import _find from 'lodash/find';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import SectionPropertiesForm from '../../molecules/sectionPropertiesForm';
import SectionColumnPropertiesForm from '../../molecules/sectionColumnPropertiesForm';
import DetailViewAttributePropertiesForm from '../../molecules/detailViewAttributePropertiesForm';
import ListViewRendererPropertiesForm from '../../molecules/listViewRendererPropertiesForm';
import ListViewColumnPropertiesForm from '../../molecules/listViewColumnPropertiesForm';
import GridViewRendererPropertiesForm from '../../molecules/gridViewRendererPropertiesForm';
import FormViewRendererPropertiesForm from '../../molecules/formViewRendererPropertiesForm';
import FormViewColumnPropertiesForm from '../../molecules/formViewColumnPropertiesForm';
import FormViewRowPropertiesForm from '../../molecules/formViewRowPropertiesForm';
import FormViewSectionPropertiesForm from '../../molecules/formViewSectionPropertiesForm';
import CellViewRowPropertiesForm from '../../molecules/cellViewRowPropertiesForm';
import CellViewColumnPropertiesForm from '../../molecules/cellViewColumnPropertiesForm';
import ImagePropertiesForm from '../../molecules/imagePropertiesForm';
import CellViewAttributePropertiesForm from '../../molecules/cellViewAttributePropertiesForm';
import GridViewAttributePropertiesForm from '../../molecules/gridViewAttributePropertiesForm';
import ButtonPropertiesForm from '../../molecules/buttonPropertiesForm';

import { getSimpleFieldNameFromColumn } from '../../../../utils';

import { PROPERTIES_FIELD_IDS } from './propertiesForm.constants';

import entityReader from '../../../../readers/entity.reader';

const PROPERTIES_FORM_FIELDS = {
  [PROPERTIES_FIELD_IDS.SECTION_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.SECTION_PROPERTIES_FORM,
    renderer: SectionPropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.SECTION_COLUMN_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.SECTION_COLUMN_PROPERTIES_FORM,
    renderer: SectionColumnPropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.DETAIL_VIEW_ATTRIBUTE_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.DETAIL_VIEW_ATTRIBUTE_PROPERTIES_FORM,
    renderer: DetailViewAttributePropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.LIST_VIEW_RENDERER_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.LIST_VIEW_RENDERER_PROPERTIES_FORM,
    renderer: ListViewRendererPropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.LIST_VIEW_COLUMN_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.LIST_VIEW_COLUMN_PROPERTIES_FORM,
    renderer: ListViewColumnPropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.GRID_VIEW_RENDERER_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.GRID_VIEW_RENDERER_PROPERTIES_FORM,
    renderer: GridViewRendererPropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.FORM_VIEW_RENDERER_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.FORM_VIEW_RENDERER_PROPERTIES_FORM,
    renderer: FormViewRendererPropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.FORM_VIEW_COLUMN_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.FORM_VIEW_COLUMN_PROPERTIES_FORM,
    renderer: FormViewColumnPropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.FORM_VIEW_ROW_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.FORM_VIEW_ROW_PROPERTIES_FORM,
    renderer: FormViewRowPropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.FORM_VIEW_SECTION_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.FORM_VIEW_SECTION_PROPERTIES_FORM,
    renderer: FormViewSectionPropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.CELL_VIEW_ROW_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.CELL_VIEW_ROW_PROPERTIES_FORM,
    renderer: CellViewRowPropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.CELL_VIEW_COLUMN_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.CELL_VIEW_COLUMN_PROPERTIES_FORM,
    renderer: CellViewColumnPropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.IMAGE_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.IMAGE_PROPERTIES_FORM,
    renderer: ImagePropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.CELL_VIEW_ATTRIBUTE_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.CELL_VIEW_ATTRIBUTE_PROPERTIES_FORM,
    renderer: CellViewAttributePropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.GRID_VIEW_ATTRIBUTE_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.GRID_VIEW_ATTRIBUTE_PROPERTIES_FORM,
    renderer: GridViewAttributePropertiesForm,
  },
  [PROPERTIES_FIELD_IDS.BUTTON_PROPERTIES_FORM]: {
    id: PROPERTIES_FIELD_IDS.BUTTON_PROPERTIES_FORM,
    renderer: ButtonPropertiesForm,
  },
};

const getFields = ({ entity, properties, errors, selectedViewComponent }) => {
  const selectedViewComponentFieldName = getSimpleFieldNameFromColumn(_head(_get(properties, 'fieldNames', [''])));
  const fieldDefinitionForSelectedFieldName = _find(entityReader.fieldDefinitions(entity), { name: selectedViewComponentFieldName });

  return {
    ...PROPERTIES_FORM_FIELDS,
    [PROPERTIES_FIELD_IDS.SECTION_PROPERTIES_FORM]: addToRenderOptions(PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.SECTION_PROPERTIES_FORM], [
      { path: 'entity', value: entity },
      { path: 'selectedViewComponent', value: selectedViewComponent },
    ]),
    [PROPERTIES_FIELD_IDS.SECTION_COLUMN_PROPERTIES_FORM]: addToRenderOptions(
      PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.SECTION_COLUMN_PROPERTIES_FORM],
      [
        { path: 'entity', value: entity },
        { path: 'selectedViewComponent', value: selectedViewComponent },
      ],
    ),
    [PROPERTIES_FIELD_IDS.DETAIL_VIEW_ATTRIBUTE_PROPERTIES_FORM]: addToRenderOptions(
      PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.DETAIL_VIEW_ATTRIBUTE_PROPERTIES_FORM],
      [
        { path: 'selectedViewComponent', value: selectedViewComponent },
        { path: 'entity', value: entity },
        { path: 'fieldDefinitionForSelectedFieldName', value: fieldDefinitionForSelectedFieldName },
      ],
    ),
    [PROPERTIES_FIELD_IDS.LIST_VIEW_RENDERER_PROPERTIES_FORM]: addToRenderOptions(
      PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.LIST_VIEW_RENDERER_PROPERTIES_FORM],
      [
        { path: 'selectedViewComponent', value: selectedViewComponent },
        { path: 'entity', value: entity },
        { path: 'errors', value: errors },
      ],
    ),
    [PROPERTIES_FIELD_IDS.LIST_VIEW_COLUMN_PROPERTIES_FORM]: addToRenderOptions(
      PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.LIST_VIEW_COLUMN_PROPERTIES_FORM],
      [
        { path: 'selectedViewComponent', value: selectedViewComponent },
        { path: 'entity', value: entity },
        { path: 'fieldDefinitionForSelectedFieldName', value: fieldDefinitionForSelectedFieldName },
      ],
    ),
    [PROPERTIES_FIELD_IDS.GRID_VIEW_RENDERER_PROPERTIES_FORM]: addToRenderOptions(
      PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.GRID_VIEW_RENDERER_PROPERTIES_FORM],
      [
        { path: 'selectedViewComponent', value: selectedViewComponent },
        { path: 'entity', value: entity },
      ],
    ),
    [PROPERTIES_FIELD_IDS.FORM_VIEW_RENDERER_PROPERTIES_FORM]: addToRenderOptions(
      PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.FORM_VIEW_RENDERER_PROPERTIES_FORM],
      [
        { path: 'selectedViewComponent', value: selectedViewComponent },
        { path: 'entity', value: entity },
      ],
    ),
    [PROPERTIES_FIELD_IDS.FORM_VIEW_COLUMN_PROPERTIES_FORM]: addToRenderOptions(
      PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.FORM_VIEW_COLUMN_PROPERTIES_FORM],
      [
        { path: 'selectedViewComponent', value: selectedViewComponent },
        { path: 'entity', value: entity },
        { path: 'fieldDefinitionForSelectedFieldName', value: fieldDefinitionForSelectedFieldName },
        { path: 'errors', value: errors },
      ],
    ),
    [PROPERTIES_FIELD_IDS.FORM_VIEW_ROW_PROPERTIES_FORM]: addToRenderOptions(
      PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.FORM_VIEW_ROW_PROPERTIES_FORM],
      [
        { path: 'selectedViewComponent', value: selectedViewComponent },
        { path: 'entity', value: entity },
      ],
    ),
    [PROPERTIES_FIELD_IDS.FORM_VIEW_SECTION_PROPERTIES_FORM]: addToRenderOptions(
      PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.FORM_VIEW_SECTION_PROPERTIES_FORM],
      [
        { path: 'selectedViewComponent', value: selectedViewComponent },
        { path: 'entity', value: entity },
      ],
    ),
    [PROPERTIES_FIELD_IDS.CELL_VIEW_ROW_PROPERTIES_FORM]: addToRenderOptions(
      PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.CELL_VIEW_ROW_PROPERTIES_FORM],
      [
        { path: 'selectedViewComponent', value: selectedViewComponent },
        { path: 'entity', value: entity },
      ],
    ),
    [PROPERTIES_FIELD_IDS.CELL_VIEW_COLUMN_PROPERTIES_FORM]: addToRenderOptions(
      PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.CELL_VIEW_COLUMN_PROPERTIES_FORM],
      [
        { path: 'selectedViewComponent', value: selectedViewComponent },
        { path: 'entity', value: entity },
      ],
    ),
    [PROPERTIES_FIELD_IDS.IMAGE_PROPERTIES_FORM]: addToRenderOptions(PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.IMAGE_PROPERTIES_FORM], [
      { path: 'selectedViewComponent', value: selectedViewComponent },
      { path: 'entity', value: entity },
    ]),
    [PROPERTIES_FIELD_IDS.CELL_VIEW_ATTRIBUTE_PROPERTIES_FORM]: addToRenderOptions(
      PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.CELL_VIEW_ATTRIBUTE_PROPERTIES_FORM],
      [
        { path: 'selectedViewComponent', value: selectedViewComponent },
        { path: 'entity', value: entity },
        { path: 'fieldDefinitionForSelectedFieldName', value: fieldDefinitionForSelectedFieldName },
      ],
    ),
    [PROPERTIES_FIELD_IDS.GRID_VIEW_ATTRIBUTE_PROPERTIES_FORM]: addToRenderOptions(
      PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.GRID_VIEW_ATTRIBUTE_PROPERTIES_FORM],
      [
        { path: 'selectedViewComponent', value: selectedViewComponent },
        { path: 'entity', value: entity },
        { path: 'fieldDefinitionForSelectedFieldName', value: fieldDefinitionForSelectedFieldName },
      ],
    ),
    [PROPERTIES_FIELD_IDS.BUTTON_PROPERTIES_FORM]: addToRenderOptions(PROPERTIES_FORM_FIELDS[PROPERTIES_FIELD_IDS.BUTTON_PROPERTIES_FORM], [
      { path: 'selectedViewComponent', value: selectedViewComponent },
      { path: 'entity', value: entity },
    ]),
  };
};

export { getFields };
