import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _get from 'lodash/get';

// Tekion-base
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

// Actions
import { cancelApprovalRequest, fetchAllApprovalRequests } from '../../../../actions/approvalCentre.actions';
import { fetchEntities } from '../../../../actions/entityManagement.actions';

// Helpers
import { getEntitiesDiplayNameFetchPayload, getEntitiesDisplayNamesByName } from '../../helper/approvalManagement.helpers';
import { getPayload } from './approvalRequestsList.helpers';

// Constants
import { ACTION_TYPES } from '../constants/approvalRequestsList.constants';
import COLUMN_IDS from '../constants/approvalRequestsList.columnIds';
import PAGE_IDS from '../../../../pages/devPlatform/constants/PageIds.constants';

const handleLoadEntityNames = async ({ data, setState }) => {
  const entitiesDisplayNamesFetchPayload = getEntitiesDiplayNameFetchPayload(data);

  if (entitiesDisplayNamesFetchPayload) {
    const entitiesResponse = await fetchEntities(entitiesDisplayNamesFetchPayload);
    const entitiesDisplayNamesByName = getEntitiesDisplayNamesByName(entitiesResponse);

    const dataWithEntityNames = _map(data, (approvalRequest) => {
      const entityName = tget(approvalRequest, COLUMN_IDS.ENTITY);

      return {
        ...approvalRequest,
        [COLUMN_IDS.ENTITY]: tget(entitiesDisplayNamesByName, entityName, NO_DATA),
      };
    });

    setState({ data: dataWithEntityNames });
  }
};

const fetchData = async ({ getState, setState }) => {
  const {
    currentPage = DEFAULT_PAGE_INFO.start,
    selectedFilters = EMPTY_ARRAY,
    pageSize = DEFAULT_PAGE_SIZE,
    currentPageToken = EMPTY_STRING,
    searchText = EMPTY_STRING,
    applicationProperties = EMPTY_OBJECT,
    currentLoggedInUserData,
  } = getState();

  const payload = getPayload({
    applicationProperties,
    currentPage,
    currentPageToken,
    pageSize,
    searchText,
    selectedFilters,
    currentLoggedInUserData,
  });

  setState({ isLoading: true });

  const response = await fetchAllApprovalRequests(payload);
  const data = tget(response, 'data', EMPTY_ARRAY);
  const nextPageToken = tget(response, 'nextPageToken', EMPTY_STRING);

  handleLoadEntityNames({ data, setState });

  setState({ isLoading: false, data, nextPageToken, pageSize, currentPage });
};

const handleSearch = ({ params, getState, setState }) => {
  const { value } = params;

  setState({ searchText: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => fetchData({ getState, setState }));
};

const handlePageUpdate = ({ params, getState, setState }) => {
  const { page, resultsPerPage } = _get(params, 'value', EMPTY_OBJECT);
  const { currentPage, pageSize, nextPageToken, previousPageTokens = [], currentPageToken } = getState();

  let newPageNumber = page;
  let newTokenNumber = null;
  let newPrevPageTokens = [...previousPageTokens];

  if (resultsPerPage !== pageSize) {
    newPageNumber = 1;
    newPrevPageTokens = [];
  } else if (page > currentPage + 1) {
    newTokenNumber = nextPageToken;
    newPrevPageTokens.push(currentPageToken);
  } else if (page !== 1) {
    newTokenNumber = newPrevPageTokens.pop();
  } else {
    newPrevPageTokens = [];
  }

  setState(
    { currentPage: newPageNumber - 1, pageSize: resultsPerPage, currentPageToken: newTokenNumber, previousPageTokens: newPrevPageTokens },
    () => fetchData({ getState, setState }),
  );
};

const handleFilterChange = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ selectedFilters: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => fetchData({ getState, setState }));
};

const handleApprovalClick = ({ params = EMPTY_OBJECT, getState }) => {
  const approvalRequestToShow = _get(params, 'value.original');
  const approvalRequestId = _get(approvalRequestToShow, 'id', '');
  const { history, match } = getState();
  history.push(`${match.url}/${approvalRequestId}`);
};

const handleEditClick = ({ getState, params }) => {
  const approvalRequest = params;
  const { isMountedInsideApplication = false, appRoute, history } = getState();

  let prefixPathName = '';

  if (isMountedInsideApplication) {
    prefixPathName = appRoute;
  }

  const approvalRequestId = tget(approvalRequest, 'id');

  const pathName = `${prefixPathName}/${PAGE_IDS.APPROVAL_CENTRE}/${PAGE_IDS.EDIT_REQUEST}/${approvalRequestId}`;

  history.push(pathName);
};

const handleCancelRequestRowActionClick = ({ setState, params = {} }) => {
  setState({ isConfirmModalVisible: true, approvalRequest: params });
};

const handleCancelCancelApprovalRequestClick = ({ setState }) => {
  setState({ isConfirmModalVisible: false });
};

const handleCancelConfirmClick = async ({ getState, setState }) => {
  const { approvalRequest } = getState();

  setState({ isCancellingRequest: true });

  const approvalId = tget(approvalRequest, 'id');
  if (!_isEmpty(approvalId)) {
    await cancelApprovalRequest(approvalId);
  }

  setState({ isCancellingRequest: false, isConfirmModalVisible: false }, () => {
    setTimeout(() => {
      fetchData({ getState, setState });
    }, ES_REFETCH_DELAY);
  });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleFilterChange,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleApprovalClick,
  [ACTION_TYPES.ON_EDIT_CLICK]: handleEditClick,
  [ACTION_TYPES.ON_CANCEL_CLICK]: handleCancelRequestRowActionClick,
  [ACTION_TYPES.ON_CANCEL_CANCEL_APPROVAL_REQUEST_MODAL_CLICK]: handleCancelCancelApprovalRequestClick,
  [ACTION_TYPES.ON_CANCEL_CONFIRM_APPROVAL_REQUEST_MODAL_CLICK]: handleCancelConfirmClick,
};

export default ACTION_HANDLERS;
