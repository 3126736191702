import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import Loader from '@tekion/tekion-components/molecules/loader';
import Modal from '@tekion/tekion-components/molecules/Modal';
import EntityMappingsList from './components/entityMappingsList';

import {
  getEntityMappingsForApplication,
  createUpdateEntityMappingsForApplication,
} from '../../../../../../actions/entityApplicationMapping.actions';

import { FORM_MODES } from '../../../../../../constants/viewBuilder.constants';
import { APPLICATION_CONFIG_FIELD_IDS } from '../../constants/applications.constants';
import { INITIAL_MAPPINGS, MODE_LABELS } from './constants/entityMappings.constants';

import { getTabOptions, validateAndSanitizeMappings } from './helpers/entityMappings.helpers';

const EntityMappings = ({ applicationConfiguration, preText, onClose }) => {
  const [{ isLoading, isErrored, isFetched }, setLoadingState] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [formMode, setFormMode] = useState(FORM_MODES.CREATE);
  const [mappings, setMappings] = useState(INITIAL_MAPPINGS);
  const [errors, setErrors] = useState(EMPTY_ARRAY);

  const tabOptions = useMemo(() => getTabOptions(applicationConfiguration), [applicationConfiguration]);
  const appName = tget(
    applicationConfiguration,
    APPLICATION_CONFIG_FIELD_IDS.NAME,
    tget(applicationConfiguration, APPLICATION_CONFIG_FIELD_IDS.API_NAME),
  );

  const loadCurrentMappings = async () => {
    setLoadingState({ isLoading: true });

    const { data: currentMappings, isErrored: _isErrored } = await getEntityMappingsForApplication(appName);

    if (!_isEmpty(currentMappings)) {
      setFormMode(FORM_MODES.EDIT);
      setMappings(currentMappings);
    }
    setLoadingState({ isLoading: false, isErrored: _isErrored, isFetched: true });
  };

  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload } = action;

    switch (type) {
      case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
        const { value } = payload;
        setMappings(value);
        break;
      }
      case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
        const { errors: _errors } = payload;
        setErrors(_errors);
        break;
      }
      default: {
        /* empty */
      }
    }
  };

  const handleCancel = () => onClose();
  const handleSubmit = async () => {
    const { payload, errors: _errors, hasErrors } = validateAndSanitizeMappings(appName, tabOptions, mappings);

    if (!hasErrors) {
      setIsSaving(true);
      const { isErrored: _isErrored } = await createUpdateEntityMappingsForApplication(MODE_LABELS[formMode], appName, payload);

      if (!_isErrored) {
        onClose();
      } else {
        setIsSaving(false);
      }
    } else {
      setErrors(_errors);
      toaster(TOASTER_TYPE.ERROR, __('Please fill in all values for all mappings.'));
    }
  };

  useEffect(() => {
    loadCurrentMappings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderEntityMappingsList = () => {
    if (isLoading || !isFetched) {
      return <Loader id="ENTITY_MAPPINGS" />;
    }
    return (
      <>
        {preText}
        <EntityMappingsList value={mappings} error={errors} tabOptions={tabOptions} onAction={handleAction} />
      </>
    );
  };

  if (isErrored) {
    return (
      <Modal visible showFooter={false} width={Modal.SIZES.L} title={__('Configure entity mappings')} onCancel={handleCancel}>
        {preText}
      </Modal>
    );
  }

  return (
    <Modal visible loading={isSaving} width={Modal.SIZES.L} title={__('Configure entity mappings')} onCancel={handleCancel} onSubmit={handleSubmit}>
      {renderEntityMappingsList()}
    </Modal>
  );
};

EntityMappings.propTypes = {
  applicationConfiguration: PropTypes.object.isRequired,
  preText: PropTypes.node,
  onClose: PropTypes.func,
};

EntityMappings.defaultProps = {
  preText: null,
  onClose: _noop,
};

export default EntityMappings;
