import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _noop from 'lodash/noop';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import {
  findParentComponentById,
  findComponentById,
  getFieldNamesFromChildren,
  getFirstFieldName,
  findParentChildRelationship,
  removeViewComponent,
  insertViewComponent,
  reIndexChildComponents,
  insertComponent,
  getUpdatedViewConfigForUpdatedSelectedViewComponent,
} from './viewBuilder.helper';
import { registerChangeToHistoryManager } from '../../../atoms/historyControlManager/HistoryControlManager';

import ACTION_TYPES from '../constants/viewBuilder.actionTypes';
import { COMPONENT_TYPES } from '../constants/viewBuilder.constants';
import { VIEW_CONFIGURATION_GENERAL_KEYS, ALL_VIEW_PROPERTY_KEYS } from '../../../constants/viewBuilder.constants';

const handleOnInit = ({ getState, setState }) => {
  const { initialViewConfiguration } = getState();

  const initialUsedEntityFieldNames = getFieldNamesFromChildren(_get(initialViewConfiguration, 'section'));

  const viewContainerComponentId = _get(initialViewConfiguration, 'section.sectionId');

  setState({
    selectedViewComponentId: viewContainerComponentId,
    viewConfiguration: initialViewConfiguration,
    usedEntityFieldNames: initialUsedEntityFieldNames,
  });
};

const handleViewComponentSelect = ({ setState, params = EMPTY_OBJECT }) => {
  const newSelectedComponentId = tget(params, 'componentId');

  setState({ selectedViewComponentId: newSelectedComponentId });
};

const handleViewComponentDelete = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const componentId = tget(params, 'componentId');

  const { viewConfiguration = {}, usedEntityFieldNames = [] } = getState();

  const parentComponent = _cloneDeep(findParentComponentById(viewConfiguration, componentId));

  if (parentComponent && !(parentComponent.type === COMPONENT_TYPES.SECTION && parentComponent.children.length <= 1)) {
    const viewComponent = findComponentById(viewConfiguration, componentId);

    let deletedFieldNames = getFieldNamesFromChildren(viewComponent);
    deletedFieldNames = _map(deletedFieldNames, (deletedFieldName) => getFirstFieldName(getArraySafeValue(deletedFieldName)));

    const newUsedEntityFieldNames = _filter(usedEntityFieldNames, (usedEntityFieldName) => {
      const firstFieldName = getFirstFieldName(usedEntityFieldName);
      return !_includes(deletedFieldNames, firstFieldName);
    });

    parentComponent.children = parentComponent.children.filter((childComponent) => childComponent.sectionId !== componentId);

    const columnCountProperty = _get(parentComponent, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.COLUMN_COUNT}`);
    if (columnCountProperty) {
      _set(parentComponent, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.COLUMN_COUNT}`, parentComponent.children.length);
    }

    const newViewConfig = getUpdatedViewConfigForUpdatedSelectedViewComponent(viewConfiguration, parentComponent);

    registerChangeToHistoryManager(newViewConfig);

    setState({
      selectedViewComponentId: parentComponent.sectionId,
      viewConfiguration: newViewConfig,
      usedEntityFieldNames: newUsedEntityFieldNames,
    });
  }
};

const handleComponentDrop = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const dropZoneComponentId = tget(params, 'dropZoneComponentId');
  const dropZonePositionIndex = tget(params, 'dropZonePositionIndex');
  const droppedComponent = tget(params, 'droppedComponent');

  const { viewConfiguration = {}, viewType, usedEntityFieldNames = [] } = getState();

  const newViewConfiguration = _cloneDeep(viewConfiguration);
  const droppedComponentParentComponent = findParentComponentById(newViewConfiguration, droppedComponent.sectionId);
  const parentViewComponent = findComponentById(newViewConfiguration, dropZoneComponentId);
  const isComponentDroppedInItsChild = findParentChildRelationship(droppedComponent, parentViewComponent);

  if (isComponentDroppedInItsChild) return;

  if (droppedComponentParentComponent) {
    const viewComponent = removeViewComponent(droppedComponentParentComponent, droppedComponent);
    let viewComponentIndex = dropZonePositionIndex;
    if (viewComponentIndex !== 0) {
      if (dropZonePositionIndex > droppedComponent.index) {
        viewComponentIndex = dropZonePositionIndex - 1;
      }
    }

    insertViewComponent(parentViewComponent, viewComponent, viewComponentIndex);
    reIndexChildComponents(parentViewComponent);
    reIndexChildComponents(droppedComponentParentComponent);
  } else {
    insertComponent(parentViewComponent, droppedComponent, dropZonePositionIndex, viewType);
    reIndexChildComponents(parentViewComponent);
    const droppedComponentFieldName = _get(droppedComponent, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.fieldNames[0]`);
    if (droppedComponentFieldName) {
      setState({ usedEntityFieldNames: [...usedEntityFieldNames, droppedComponentFieldName] });
    }
  }

  registerChangeToHistoryManager(newViewConfiguration);

  setState({ viewConfiguration: newViewConfiguration });
};

const handleComponentEditClick = ({ setState, params = EMPTY_OBJECT }) => {
  const viewComponentIdToEdit = tget(params, 'viewComponentIdToEdit');

  setState({ showCompoundConfigModal: true, selectedViewComponentId: viewComponentIdToEdit });
};

const handleViewConfigurationPreview = async ({ getState, setState }) => {
  const { viewConfiguration = EMPTY_OBJECT, onViewConfigurationPreview = _noop } = getState();

  setState({ showPreviewModal: true, isPreviewLoading: true });

  await onViewConfigurationPreview(viewConfiguration);

  setState({ isPreviewLoading: false });
};

const handlePreviewModalClose = ({ setState }) => {
  setState({ showPreviewModal: false });
};

const handleComponentConfiguratorAction = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const action = tget(params, 'action');

  const { viewConfiguration = EMPTY_OBJECT, errors = {} } = getState();

  const { type, payload = EMPTY_OBJECT } = action;
  switch (type) {
    case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
      const newViewConfiguration = _get(payload, 'newViewConfiguration', viewConfiguration);
      registerChangeToHistoryManager(newViewConfiguration);
      setState({ viewConfiguration: newViewConfiguration });
      break;
    }
    case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
      const newErrors = _get(payload, 'errors', errors);
      setState({ errors: newErrors });
      break;
    }
    default: {
      break;
    }
  }
};

const handelComponentConfigModalSave = ({ setState, params = EMPTY_OBJECT }) => {
  const updatedViewConfig = tget(params, 'updatedViewConfig');

  registerChangeToHistoryManager(updatedViewConfig);

  setState({ viewConfiguration: updatedViewConfig, showCompoundConfigModal: false });
};

const handelComponentConfigModalCancel = ({ setState }) => {
  setState({ showCompoundConfigModal: false });
};

const handleInitialViewConfigurationChange = ({ setState, params = EMPTY_OBJECT }) => {
  const initialViewConfiguration = tget(params, 'initialViewConfiguration');

  const initialUsedEntityFieldNames = getFieldNamesFromChildren(_get(initialViewConfiguration, 'section'));

  setState({ viewConfiguration: initialViewConfiguration, usedEntityFieldNames: initialUsedEntityFieldNames });
};

const handleTreeNodeClick = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const data = tget(params, 'data');
  const selectedTreeNodeKeys = tget(params, 'selectedTreeNodeKeys');

  const { selectedViewComponentId } = getState();

  const currentNodeKey = tget(data, 'node.props.eventKey');
  if (selectedViewComponentId === currentNodeKey) {
    return;
  }

  setState({ selectedViewComponentId: getArraySafeValue(selectedTreeNodeKeys) });
};

const handleUndoRedo = ({ setState, params = EMPTY_OBJECT }) => {
  const configuration = tget(params, 'configuration');

  const usedFieldNames = getFieldNamesFromChildren(_get(configuration, 'section'));

  setState({ viewConfiguration: configuration, usedEntityFieldNames: usedFieldNames });
};

const handleViewTreeStructureClick = ({ getState, setState }) => {
  const { showTreeStructure = false } = getState();

  setState({ showTreeStructure: !showTreeStructure });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleOnInit,
  [ACTION_TYPES.ON_COMPONENT_SELECT]: handleViewComponentSelect,
  [ACTION_TYPES.ON_COMPONENT_DELETE]: handleViewComponentDelete,
  [ACTION_TYPES.ON_COMPONENT_DROP]: handleComponentDrop,
  [ACTION_TYPES.ON_COMPONENT_EDIT_CLICK]: handleComponentEditClick,
  [ACTION_TYPES.ON_PREVIEW_CLICK]: handleViewConfigurationPreview,
  [ACTION_TYPES.ON_PREVIEW_MODAL_CLOSE]: handlePreviewModalClose,
  [ACTION_TYPES.ON_CONFIGURATOR_ACTION]: handleComponentConfiguratorAction,
  [ACTION_TYPES.ON_COMPOUND_CONFIG_MODAL_SAVE]: handelComponentConfigModalSave,
  [ACTION_TYPES.ON_COMPOUND_CONFIG_MODAL_CANCEL]: handelComponentConfigModalCancel,
  [ACTION_TYPES.ON_INITIAL_VIEW_CONFIGURATION_CHANGE]: handleInitialViewConfigurationChange,
  [ACTION_TYPES.ON_TREE_NODE_CLICK]: handleTreeNodeClick,
  [ACTION_TYPES.ON_UNDO_REDO]: handleUndoRedo,
  [ACTION_TYPES.ON_VIEW_TREE_STRUCTURE_CLICK]: handleViewTreeStructureClick,
};

export default ACTION_HANDLERS;
