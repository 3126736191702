import _reduce from 'lodash/reduce';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';

import DATA_TYPES from '../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../constants/fieldDefinition.fieldTypes';
import { CURRENT_ENTITY_NAMESPACE } from '../organisms/conditionBuilder/constants/conditionBuilder.general';
import fieldDefinitionReader from '../readers/fieldDefinition.reader';

const getStaticToFieldSelectProps = (fieldDefinitions, selectedValues) => {
  const options = _reduce(
    fieldDefinitions,
    (result, fieldDef) => {
      const fieldType = fieldDefinitionReader.fieldType(fieldDef);
      const dataType = fieldDefinitionReader.dataType(fieldDef);
      if (dataType !== DATA_TYPES.COMPLEX && fieldType !== FIELD_TYPES.RELATIONSHIP) {
        result.push({
          label: `${CURRENT_ENTITY_NAMESPACE}.${fieldDefinitionReader.displayName(fieldDef)}`,
          value: `${CURRENT_ENTITY_NAMESPACE}.${fieldDefinitionReader.name(fieldDef)}`,
        });
      }

      return result;
    },
    [],
  );

  _forEach(selectedValues, (selectedValue) => {
    if (!_find(options, { value: selectedValue })) {
      options.push({ label: selectedValue, value: selectedValue });
    }
  });

  return options;
};

export { getStaticToFieldSelectProps };
