import { insertNodes, unwrapNodes } from '@udecode/plate-common';
import { getPlatePluginType } from '@udecode/plate-core';

// constants
import { Transforms } from 'slate';
import { ELEMENT_TEMPLATE_VARIABLE } from '../../../../../plugins/templateVariable';
import ACTION_TYPES from '../constants/templateVariableForm.actionTypes';
import FIELD_IDS from '../constants/templateVariableForm.fieldIds';

const handleEditExistingLink = ({ selectedLinkNode, editor, linkNodeType, variableName, hideTemplateVariableDialog }) => {
  const [, selectedLinkNodePath] = selectedLinkNode;
  Transforms.select(editor, selectedLinkNodePath);
  unwrapNodes(editor, { at: editor.selection, match: { type: linkNodeType } });
  insertNodes(editor, { variableName, type: linkNodeType, children: [{ text: variableName }] }, { at: editor.selection });
  hideTemplateVariableDialog();
};

const handleAddNewLink = ({ editor, linkNodeType, variableName, hideTemplateVariableDialog }) => {
  unwrapNodes(editor, { at: editor.selection, match: { type: linkNodeType } });
  insertNodes(editor, { type: linkNodeType, children: [{ text: `$\{${variableName}}` }] }, { at: editor.selection });
  hideTemplateVariableDialog();
};

const handleTemplateVariableCreation = ({ params, getState }) => {
  const { formValues, editor } = params;
  const { selectedLinkNode, hideTemplateVariableDialog } = getState();
  const linkNodeType = getPlatePluginType(editor, ELEMENT_TEMPLATE_VARIABLE);
  const { [FIELD_IDS.VARIABLE_NAME]: variableName } = formValues;
  if (selectedLinkNode) {
    return handleEditExistingLink({
      selectedLinkNode,
      editor,
      linkNodeType,
      variableName,
      hideTemplateVariableDialog,
    });
  }
  return handleAddNewLink({ editor, linkNodeType, variableName, hideTemplateVariableDialog });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE_TEMPLATE_VARIABLE]: handleTemplateVariableCreation,
};

export default ACTION_HANDLERS;
