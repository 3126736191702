import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { getMomentValueOf, toMomentWithParserFormat, DATE_TIME_FORMATS, toMoment } from '@tekion/tekion-base/utils/dateUtils';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { VIEW_TYPES } from '../../../../../organisms/viewBuilder/constants/viewBuilder.constants';
import { DEFAULT_PAYLOAD_PICK_VALUE, SCHEDULE_TYPES, TASK_TYPES } from '../constants/jobScheduleForm.general';
import FIELD_IDS from '../constants/jobScheduleForm.fieldIds';
import { FIELD_IDS as CRON_EXPRESSION_FIELD_IDS } from '../components/cronExpression/cronExpression.constants';

const getPayloadForSearchViews = (entityNames) => ({
  filters: [
    { field: 'entityName', values: entityNames, filterType: 'IN' },
    { field: 'viewType', values: [VIEW_TYPES.LIST_VIEW], filterType: 'IN' },
  ],
});

const generatePayloadFromFormValues = (formValues) => {
  let payload = _pick(formValues, DEFAULT_PAYLOAD_PICK_VALUE);
  const scheduleType = getArraySafeValue(_get(formValues, FIELD_IDS.SCHEDULE_TYPE));
  const taskType = getArraySafeValue(_get(formValues, FIELD_IDS.TASK_TYPE));

  const startTime = getMomentValueOf(_get(formValues, FIELD_IDS.START_TIME));
  const endTime = getMomentValueOf(_get(formValues, FIELD_IDS.END_TIME));
  const timeZone = getArraySafeValue(_get(formValues, FIELD_IDS.TIME_ZONE));
  let scheduleData = {
    [FIELD_IDS.SCHEDULE_TYPE]: scheduleType,
    [FIELD_IDS.START_TIME]: startTime,
    [FIELD_IDS.END_TIME]: endTime,
    [FIELD_IDS.TIME_ZONE]: timeZone,
  };
  if (scheduleType === SCHEDULE_TYPES.CRON) {
    const cronExpressionValue = _get(formValues, FIELD_IDS.CRON_EXPRESSION, EMPTY_OBJECT);
    scheduleData = { ...scheduleData, ...cronExpressionValue };
  } else if (scheduleType === SCHEDULE_TYPES.SIMPLE) {
    const forever = _get(formValues, FIELD_IDS.SIMPLE_FOREVER);
    const repeatCount = _get(formValues, FIELD_IDS.SIMPLE_REPEAT_COUNT);
    const triggerTime = _get(formValues, FIELD_IDS.SIMPLE_TRIGGER_TIME);
    const hours = triggerTime.hours();
    const minutes = triggerTime.minutes();
    const seconds = triggerTime.seconds();
    scheduleData = { ...scheduleData, [FIELD_IDS.SIMPLE_FOREVER]: forever, [FIELD_IDS.SIMPLE_REPEAT_COUNT]: repeatCount, hours, minutes, seconds };
  }

  let taskInfo = { [FIELD_IDS.TASK_TYPE]: taskType };
  if (taskType === TASK_TYPES.EXPORT) {
    const entityName = getArraySafeValue(_get(formValues, FIELD_IDS.ENTITY_NAME));
    const selectedViewName = getArraySafeValue(_get(formValues, FIELD_IDS.VIEW_NAME));
    const fileFormat = getArraySafeValue(_get(formValues, FIELD_IDS.FILE_FORMAT));
    taskInfo = { ...taskInfo, [FIELD_IDS.ENTITY_NAME]: entityName, [FIELD_IDS.VIEW_NAME]: selectedViewName, [FIELD_IDS.FILE_FORMAT]: fileFormat };
  }

  payload = { ...payload, scheduleData, taskInfo };

  return payload;
};

const generateFormValuesFromApiResponse = (apiResponse) => {
  const scheduleInfo = _get(apiResponse, 'scheduleData', EMPTY_OBJECT);
  const taskInfo = _get(apiResponse, 'taskInfo', EMPTY_OBJECT);
  const scheduleType = _get(scheduleInfo, FIELD_IDS.SCHEDULE_TYPE);
  const taskType = _get(taskInfo, FIELD_IDS.TASK_TYPE);

  let formValues = _pick(apiResponse, DEFAULT_PAYLOAD_PICK_VALUE);
  const startTime = toMoment(_get(scheduleInfo, FIELD_IDS.START_TIME));
  const endTime = toMoment(_get(scheduleInfo, FIELD_IDS.END_TIME));
  const timeZone = _get(scheduleInfo, FIELD_IDS.TIME_ZONE);
  formValues = {
    ...formValues,
    [FIELD_IDS.SCHEDULE_TYPE]: _castArray(scheduleType),
    [FIELD_IDS.START_TIME]: startTime,
    [FIELD_IDS.END_TIME]: endTime,
    [FIELD_IDS.TIME_ZONE]: _castArray(timeZone),
    [FIELD_IDS.TASK_TYPE]: _castArray(taskType),
  };

  if (scheduleType === SCHEDULE_TYPES.CRON) {
    const cronExpression = _pick(scheduleInfo, [
      CRON_EXPRESSION_FIELD_IDS.SECONDS,
      CRON_EXPRESSION_FIELD_IDS.MINUTES,
      CRON_EXPRESSION_FIELD_IDS.HOURS,
      CRON_EXPRESSION_FIELD_IDS.DAY_OF_MONTH,
      CRON_EXPRESSION_FIELD_IDS.MONTH,
      CRON_EXPRESSION_FIELD_IDS.DAY_OF_WEEK,
      CRON_EXPRESSION_FIELD_IDS.YEAR,
    ]);
    formValues = { ...formValues, [FIELD_IDS.CRON_EXPRESSION]: cronExpression };
  } else if (scheduleType === SCHEDULE_TYPES.SIMPLE) {
    const forever = _get(scheduleInfo, FIELD_IDS.SIMPLE_FOREVER);
    const repeatCount = _get(scheduleInfo, FIELD_IDS.SIMPLE_REPEAT_COUNT);
    const hours = _get(scheduleInfo, 'hours');
    const minutes = _get(scheduleInfo, 'minutes');
    const seconds = _get(scheduleInfo, 'seconds');
    const momentObject = toMomentWithParserFormat(`${hours}:${minutes}:${seconds}`, DATE_TIME_FORMATS.TWENTY_FOUR_HOUR_MIN_SEC_FORMAT_WITHOUT_SUFFIX);
    formValues = {
      ...formValues,
      [FIELD_IDS.SIMPLE_REPEAT_COUNT]: repeatCount,
      [FIELD_IDS.SIMPLE_FOREVER]: forever,
      [FIELD_IDS.SIMPLE_TRIGGER_TIME]: momentObject,
    };
  }

  if (taskType === TASK_TYPES.EXPORT) {
    const entityName = _castArray(_get(taskInfo, FIELD_IDS.ENTITY_NAME));
    const selectedViewName = _castArray(_get(taskInfo, FIELD_IDS.VIEW_NAME));
    const fileFormat = _castArray(_get(taskInfo, FIELD_IDS.FILE_FORMAT));
    formValues = { ...formValues, [FIELD_IDS.ENTITY_NAME]: entityName, [FIELD_IDS.VIEW_NAME]: selectedViewName, [FIELD_IDS.FILE_FORMAT]: fileFormat };
  }

  return formValues;
};

export { getPayloadForSearchViews, generatePayloadFromFormValues, generateFormValuesFromApiResponse };
