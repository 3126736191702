// Constants
import { EMPTY_ARRAY } from 'tbase/app.constants';

export const DEFAULT_PAGE_SIZE = 10;

export const INITIAL_STATE = {
  pageToken: undefined,
  isFetchingAuditLogs: true,
  activities: EMPTY_ARRAY,
};
