import React from 'react';

// Cell Renderers
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import Icon from '@tekion/tekion-components/atoms/FontIcon';
import CheckboxCellRenderer from './renderers/CheckboxCellRenderer';
import InputCellRenderer from './renderers/InputCellRenderer';

// Constants
import { COLUMN_IDS } from './configTable.constants';
import { VIEW_TYPES } from '../../../../../../../organisms/viewBuilder/constants/viewBuilder.constants';

const DraggerIcon = () => <Icon size="S">icon-drag-and-drop</Icon>;
const DraggerIconCellRenderer = makeCellRenderer(DraggerIcon);

const COLUMN_CONFIG_BY_KEY = {
  [COLUMN_IDS.NAME]: {
    Header: __('Name'),
    id: COLUMN_IDS.NAME,
    accessor: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
  [COLUMN_IDS.DISPLAY_NAME]: {
    Header: __('Column Header'),
    id: COLUMN_IDS.DISPLAY_NAME,
    accessor: COLUMN_IDS.DISPLAY_NAME,
    Cell: InputCellRenderer,
  },
  [COLUMN_IDS.SEARCHABLE]: {
    Header: __('Searchable'),
    id: COLUMN_IDS.SEARCHABLE,
    accessor: COLUMN_IDS.SEARCHABLE,
    Cell: CheckboxCellRenderer,
  },
  [COLUMN_IDS.SORTABLE]: {
    Header: __('Sortable'),
    id: COLUMN_IDS.SORTABLE,
    accessor: COLUMN_IDS.SORTABLE,
    Cell: CheckboxCellRenderer,
  },
  [COLUMN_IDS.FILTERABLE]: {
    Header: __('Filterable'),
    id: COLUMN_IDS.FILTERABLE,
    accessor: COLUMN_IDS.FILTERABLE,
    Cell: CheckboxCellRenderer,
  },
  [COLUMN_IDS.DEFAULT_FILTER]: {
    Header: __('Default Filter'),
    id: COLUMN_IDS.DEFAULT_FILTER,
    accessor: COLUMN_IDS.DEFAULT_FILTER,
    Cell: CheckboxCellRenderer,
  },
  [COLUMN_IDS.MANDATORY]: {
    Header: __('Mandatory Field'),
    id: COLUMN_IDS.MANDATORY,
    accessor: COLUMN_IDS.MANDATORY,
    Cell: CheckboxCellRenderer,
  },
  [COLUMN_IDS.EDITABLE]: {
    Header: __('Editable Field'),
    id: COLUMN_IDS.EDITABLE,
    accessor: COLUMN_IDS.EDITABLE,
    Cell: CheckboxCellRenderer,
  },
  [COLUMN_IDS.DISABLED]: {
    Header: __('Disable Field'),
    id: COLUMN_IDS.DISABLED,
    accessor: COLUMN_IDS.DISABLED,
    Cell: CheckboxCellRenderer,
  },
};

const DRAGGABLE_COLUMN = {
  id: COLUMN_IDS.DRAG_ICON,
  resizable: false,
  Cell: DraggerIconCellRenderer,
  width: 40,
};

const getColumns = (viewType) => {
  switch (viewType) {
    case VIEW_TYPES.DETAIL_VIEW:
    case VIEW_TYPES.FORM_VIEW:
      return [
        DRAGGABLE_COLUMN,
        COLUMN_CONFIG_BY_KEY[COLUMN_IDS.DISPLAY_NAME],
        COLUMN_CONFIG_BY_KEY[COLUMN_IDS.NAME],
        COLUMN_CONFIG_BY_KEY[COLUMN_IDS.MANDATORY],
        COLUMN_CONFIG_BY_KEY[COLUMN_IDS.EDITABLE],
        COLUMN_CONFIG_BY_KEY[COLUMN_IDS.DISABLED],
      ];

    case VIEW_TYPES.LIST_VIEW:
    case VIEW_TYPES.GRID_VIEW:
      return [DRAGGABLE_COLUMN, COLUMN_CONFIG_BY_KEY[COLUMN_IDS.DISPLAY_NAME], COLUMN_CONFIG_BY_KEY[COLUMN_IDS.NAME]];

    default:
      return [];
  }
};

export { getColumns };
