import ACTION_TYPES from './approvalProcessList.actionTypes';

const EDIT_APPROVAL_SETTING = {
  id: ACTION_TYPES.EDIT_APPROVAL_PROCESS,
  name: __('Edit'),
};

const ACTIVATE_APPROVAL_PROCESS = {
  id: ACTION_TYPES.ACTIVATE_APPROVAL_PROCESS,
  name: __('Activate Process'),
};

const DEACTIVATE_APPROVAL_PROCESS = {
  id: ACTION_TYPES.DEACTIVATE_APPROVAL_PROCESS,
  name: __('Deactivate Process'),
};

const ROW_ACTIONS = [EDIT_APPROVAL_SETTING];

export { ACTIVATE_APPROVAL_PROCESS, DEACTIVATE_APPROVAL_PROCESS };

export default ROW_ACTIONS;
