import _get from 'lodash/get';
import _property from 'lodash/property';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

const id = _property('id');
const name = _property('name');
const displayName = _property('displayName');
const widgetItems = (params) => _get(params, 'widgetItems', EMPTY_ARRAY);
const itemLayout = (params) => _get(params, 'itemLayout', EMPTY_ARRAY);
const widgetLayouts = (params) => _get(params, 'widgetLayouts', EMPTY_ARRAY);
const dashboardType = _property('dashboardType');
const createdBy = _property('createdBy');
const updatedBy = _property('updatedBy');
const createdTime = _property('createdTime');
const updatedTime = _property('updatedTime');
const filters = (params) => _get(params, 'filters', EMPTY_ARRAY);

export default {
  id,
  name,
  widgetItems,
  itemLayout,
  dashboardType,
  createdBy,
  updatedBy,
  createdTime,
  updatedTime,
  filters,
  displayName,
  widgetLayouts,
};
