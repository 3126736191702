import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';

import withSize from '../../../../../connectors/withSize';

import { getTableProps } from './helpers/globalSelectList.helpers';
import ACTION_HANDLERS from './helpers/globalSelectList.actionHandlers';
import { HEADER_PROPS, INITIAL_STATE, TABLE_MANAGER_PROPS } from './constants/globalSelectList.general.constants';
import { EXPORT_LIST_COLUMNS, SUB_HEADER_PROPS } from './constants/globalSelectList.constants';

const GlobalSelectList = ({ loading, contentHeight, pageSize, currentPage, searchText, nextPageToken, globalSelectList, onAction }) => {
  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({ type: actionType, payload });
    },
    [onAction],
  );

  return (
    <div style={{ height: contentHeight }}>
      <TableManager
        tokenPagination
        pageSize={pageSize}
        searchText={searchText}
        nextPageToken={nextPageToken}
        tableManagerProps={TABLE_MANAGER_PROPS}
        subHeaderProps={SUB_HEADER_PROPS}
        headerProps={HEADER_PROPS}
        columns={EXPORT_LIST_COLUMNS}
        data={globalSelectList}
        tableProps={getTableProps(handleRowAction, currentPage, loading, pageSize)}
        onAction={onAction}
      />
    </div>
  );
};

GlobalSelectList.propTypes = {
  loading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  searchText: PropTypes.string,
  nextPageToken: PropTypes.string,
  globalSelectList: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

GlobalSelectList.defaultProps = {
  loading: false,
  pageSize: DEFAULT_PAGE_SIZE,
  currentPage: DEFAULT_PAGE_INFO.start,
  searchText: EMPTY_STRING,
  nextPageToken: undefined,
  globalSelectList: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageFooter: 0, hasPageHeader: 0 }), withActions(INITIAL_STATE, ACTION_HANDLERS))(GlobalSelectList);
