// styles
import COLORS from '@tekion/tekion-styles-next/scss/variables.scss';

export const getDropDownAttributes = (showDropdown) => {
  const dropDownIcon = showDropdown ? 'icon-chevron-up' : 'icon-chevron-down';
  const dropdownIconColor = showDropdown ? COLORS.denim : undefined;
  return {
    dropDownIcon,
    dropdownIconColor,
  };
};
