import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import apiService from './apiService/apiService';
import { clearLoginData } from '../connectors/WithAuth/WithAuth.helpers';

import envReader from '../readers/env.reader';

const PATHS = {
  LOGIN: '/public/login/authenticate',
  LOGOUT: '/u/logout',
  FETCH_CONFIGURATIONS: '/public/login/configurations/fetch',
  AUTHENTICATE_MFA: '/public/login/authenticate/mfa',
  RESEND_OTP: '/public/login/resend-otp',
};

const apiUrl = envReader.service;

const loginBasePath = `${apiUrl}/api/core`;
const loginUrl = `${loginBasePath}${PATHS.LOGIN}`;
const logoutUrl = `${loginBasePath}${PATHS.LOGOUT}`;
const fetchConfigurationsUrl = `${loginBasePath}${PATHS.FETCH_CONFIGURATIONS}`;
const verifyMFAUrl = `${loginBasePath}${PATHS.AUTHENTICATE_MFA}`;
const resendOtpUrl = `${loginBasePath}${PATHS.RESEND_OTP}`;

const logIn = (payload) => apiService.post(loginUrl, payload).then(({ data }) => data);

const fetchConfigurations = (payload) => apiService.post(fetchConfigurationsUrl, payload).then(({ data }) => data);

const verifyMFA = (payload) => apiService.post(verifyMFAUrl, payload).then(({ data }) => data);
const resendOTP = (payload) => apiService.post(resendOtpUrl, payload).then(({ data }) => data);

const logOut = ({ shouldRedirect = true } = EMPTY_OBJECT) =>
  apiService
    .post(logoutUrl)
    .then(() => clearLoginData(shouldRedirect))
    .catch(() => clearLoginData(shouldRedirect));

export { logIn, logOut, fetchConfigurations, verifyMFA, resendOTP };
