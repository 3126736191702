import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import { WIDGET_TYPES } from '../../../../../constants/visualBuilder.general.constants';
import { ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS } from '../constants/entityAndViewConfigure.fieldIds';

const rowCheck = {
  [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.RELATIVE_FIELD_NAME]: ({ widgetType }) => widgetType !== WIDGET_TYPES.STANDARD_WIDGET,
};

const getEntityAndViewConfigureSections = (widgetType) => [
  {
    rows: [
      {
        columns: [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.ENTITY_NAME],
      },
      {
        columns: [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW_TYPE],
      },
      {
        columns: filterRows([ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.RELATIVE_FIELD_NAME], { widgetType }, rowCheck),
      },
      {
        columns: [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW],
      },
    ],
  },
];

export { getEntityAndViewConfigureSections };
