import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';
import Spinner from '@tekion/tekion-components/molecules/SpinnerComponent';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import withSize from '../../../../../connectors/withSize';
import UserCredModal from './userCredModal/UserCredModal';

// Constants
import { FORM_MODES } from '../../../../../constants/general.constants';
import ACTION_TYPES from './constants/workspaceUserForm.actionTypes';
import { HEADER_PROPS, USER_FORM_CONTEXT_ID } from './constants/workspaceUserForm.general.constants';

// Helpers
import { getUserFormFields } from './formHelpers/workspaceUserForm.fields';
import { USER_MANAGEMENT_SECTIONS } from './formHelpers/workspaceUserForm.sections';
import ACTION_HANDLERS from './formHelpers/workspaceUserForm.actionHandlers';

import styles from './workspaceUserForm.module.scss';

const WorkspaceUserForm = ({
  isDataLoading,
  isSaveLoading,
  isConfirmModalVisible,
  isUserPresentInOtherWorkspace,
  contentHeight,
  formMode,
  values,
  errors,
  roleOptions,
  onAction,
}) => {
  const handleSubmit = useCallback(() => {
    triggerSubmit(USER_FORM_CONTEXT_ID);
  }, []);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_REDIRECTION });
  }, [onAction]);

  const goBackHandler = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_REDIRECTION,
    });
  }, [onAction]);

  const formHeader = useMemo(() => {
    if (formMode === FORM_MODES.CREATE) return __('Create New User');
    return __('Edit User');
  }, [formMode]);

  const fields = useMemo(
    () => getUserFormFields(formMode, isUserPresentInOtherWorkspace, roleOptions),
    [formMode, isUserPresentInOtherWorkspace, roleOptions],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INITIALIZE_USER_FORM_VALUES });
  }, [onAction]);

  return (
    <Page className="full-height full-width">
      <Page.Header {...HEADER_PROPS} goBackHandler={goBackHandler}>
        <Heading className="full-width">{formHeader}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight }} className={styles.pageBody}>
        {isDataLoading && (
          <div className={styles.spinnerWrap}>
            <Spinner size="medium" />
          </div>
        )}
        {!isDataLoading && (
          <>
            <FormWithSubmission
              contextId={USER_FORM_CONTEXT_ID}
              fields={fields}
              sections={USER_MANAGEMENT_SECTIONS}
              values={values}
              errors={errors}
              onAction={onAction}
            />
            <UserCredModal isConfirmModalVisible={isConfirmModalVisible} goBackHandler={goBackHandler} />
          </>
        )}
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          primaryButtonLabel={__('Save')}
          primaryActionLoading={isSaveLoading}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

WorkspaceUserForm.propTypes = {
  isDataLoading: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  isConfirmModalVisible: PropTypes.bool,
  isUserPresentInOtherWorkspace: PropTypes.bool,
  formMode: PropTypes.string,
  contentHeight: PropTypes.number.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  roleOptions: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

WorkspaceUserForm.defaultProps = {
  isDataLoading: false,
  isSaveLoading: false,
  isConfirmModalVisible: false,
  isUserPresentInOtherWorkspace: false,
  formMode: EMPTY_STRING,
  values: EMPTY_OBJECT,
  roleOptions: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default compose(withSize({ hasPageFooter: 1, hasPageHeader: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(WorkspaceUserForm);
