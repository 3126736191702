const SHARE_RECORD_CONFIG = {
  ID: 'SHARE_RECORD',
  LABEL: __('Share'),
};

const MANAGE_ACCESS_RECORD_CONFIG = {
  ID: 'MANAGE_ACCESS_RECORD',
  LABEL: __('Manage Access'),
};

export { SHARE_RECORD_CONFIG, MANAGE_ACCESS_RECORD_CONFIG };
