import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _has from 'lodash/has';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';

import Editor from '@tekion/tekion-components/molecules/htmlPreviewEditor/Editor/Editor';
import Switch from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import Avatar from '@tekion/tekion-components/atoms/Avatar';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Loader from '@tekion/tekion-components/molecules/loader';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import { SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS, SEND_EMAILS_ADDRESSEE_TYPE } from '../../../../../../../../constants/actionBuilder.constants';

import styles from './emailPreviewRenderer.module.scss';

const EmailPreviewRenderer = ({
  isEmailResolving,
  switchValue,
  switchId,
  addresseeId,
  sendEmailsType,
  derivedEntityLookupFieldName,
  resolvedEmailSubject,
  resolvedEmailBody,
  emailTemplateDef,
  selectedActionDef,
  derivedEntityRecordDataById,
  fromValue,
  toValue,
  addresseeValue,
  onAction,
}) => {
  const addresseeOptions = useMemo(
    () => _map(derivedEntityRecordDataById, (data) => ({ label: __(tget(data, derivedEntityLookupFieldName, NO_DATA)), value: tget(data, 'id') })),
    [derivedEntityRecordDataById, derivedEntityLookupFieldName],
  );

  const emailToValue = useMemo(() => {
    let value = EMPTY_STRING;

    if (sendEmailsType === SEND_EMAILS_ADDRESSEE_TYPE.STATIC) {
      value = getArraySafeValue(toValue);
    } else if (sendEmailsType === SEND_EMAILS_ADDRESSEE_TYPE.DERIVED) {
      const recordData = _get(derivedEntityRecordDataById, getArraySafeValue(addresseeValue), EMPTY_OBJECT);
      const derivedEntityField = _get(selectedActionDef, `emailAddresseeConfig.${SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY_FIELD}`);
      if (_has(recordData, derivedEntityField)) {
        value = recordData[derivedEntityField];
      } else {
        value = _get(recordData, `entity.${derivedEntityField}`, EMPTY_STRING);
      }
    }

    return value;
  }, [sendEmailsType, derivedEntityRecordDataById, selectedActionDef, addresseeValue, toValue]);

  if (isEmailResolving) {
    return <Loader />;
  }

  return (
    <div>
      <div className={cx(styles.flexRow, styles.subContainer)}>
        <Switch
          id={switchId}
          value={switchValue}
          checkedChildren={__('Resolved Template')}
          unCheckedChildren={__('Unresolved Template')}
          fieldClassName={fieldLayoutStyles.fieldC}
          onAction={onAction}
        />
        <PropertyControlledComponent controllerProperty={sendEmailsType === SEND_EMAILS_ADDRESSEE_TYPE.DERIVED}>
          <SelectInput
            id={addresseeId}
            label={__('Addressee')}
            fieldClassName={fieldLayoutStyles.fieldC}
            value={addresseeValue}
            options={addresseeOptions}
            onAction={onAction}
          />
        </PropertyControlledComponent>
      </div>
      <PropertyControlledComponent controllerProperty={_isEmpty(emailTemplateDef)}>
        <div className={styles.emailNotPresent}>{__('Please select a template!')}</div>
      </PropertyControlledComponent>
      <PropertyControlledComponent controllerProperty={!_isEmpty(emailTemplateDef) && switchValue}>
        <div className={styles.container}>
          <Heading size={3}>{__(resolvedEmailSubject)}</Heading>
          <hr />
          <Avatar size={30} name={__(getArraySafeValue(fromValue))} secondaryName={__(`To: ${emailToValue}`)} />
          <Editor disabled html={resolvedEmailBody} />
        </div>
      </PropertyControlledComponent>
      <PropertyControlledComponent controllerProperty={!_isEmpty(emailTemplateDef) && !switchValue}>
        <div className={styles.emailTemplateContainer}>
          <div>Subject: {_get(emailTemplateDef, 'subject', NO_DATA)}</div>
          <Editor disabled html={_get(emailTemplateDef, 'body', EMPTY_STRING)} />
        </div>
      </PropertyControlledComponent>
    </div>
  );
};

EmailPreviewRenderer.propTypes = {
  isEmailResolving: PropTypes.bool,
  switchValue: PropTypes.bool,
  switchId: PropTypes.string.isRequired,
  sendEmailsType: PropTypes.string.isRequired,
  resolvedEmailSubject: PropTypes.string,
  addresseeId: PropTypes.string,
  resolvedEmailBody: PropTypes.string,
  derivedEntityLookupFieldName: PropTypes.string,
  emailTemplateDef: PropTypes.object,
  derivedEntityRecordDataById: PropTypes.object,
  selectedActionDef: PropTypes.object,
  fromValue: PropTypes.array,
  toValue: PropTypes.array,
  addresseeValue: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

EmailPreviewRenderer.defaultProps = {
  isEmailResolving: false,
  switchValue: true,
  resolvedEmailSubject: EMPTY_STRING,
  resolvedEmailBody: EMPTY_STRING,
  derivedEntityLookupFieldName: EMPTY_STRING,
  addresseeId: EMPTY_STRING,
  emailTemplateDef: EMPTY_OBJECT,
  derivedEntityRecordDataById: EMPTY_OBJECT,
  selectedActionDef: EMPTY_OBJECT,
  fromValue: EMPTY_ARRAY,
  toValue: EMPTY_ARRAY,
  addresseeValue: EMPTY_ARRAY,
};

export default EmailPreviewRenderer;
