import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { compose } from 'recompose';
import { Route, Switch, withRouter } from 'react-router-dom';

import Modal from '@tekion/tekion-components/molecules/Modal';
import withActions from '@tekion/tekion-components/connectors/withActions';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import ViewBuilder from '../../../../organisms/viewBuilder/ViewBuilder';
import CreateView from './createView/CreateView';
import CreateEntityView from './createEntityView';

import ACTION_HANDLERS from './viewBuilderPage.actionHandlers';
import {
  getEmptyViewConfiguration,
  getDetailViewConfiguration,
  getListViewConfiguration,
  getFormViewConfiguration,
  getCellViewConfiguration,
  getGridViewConfiguration,
} from '../../../../organisms/viewBuilder/helpers/viewTypeConfiguration.helper';
import { FIELD_IDS } from './createView/constants/createView.constants';
import { ACTION_TYPES } from './viewBuilderPage.constants';
import { VIEW_TYPES } from '../../../../organisms/viewBuilder/constants/viewBuilder.constants';
import { VIEW_BUILDER_LIST_TAB_IDS } from '../../../../constants/viewBuilder.constants';
import { STUDIO_ROUTE } from '../../../../constants/routes';
import PAGE_IDS from '../../constants/PageIds.constants';
import ROUTES from '../../constants/routes';

import styles from './viewBuilderPage.module.scss';

const ViewBuilderPage = ({
  isLoading,
  isEditMode,
  isCancelModalVisible,
  entity,
  currentRecordTypeEntity,
  entityRecord,
  viewSummary,
  initialViewConfiguration,
  viewConfigsByName,
  match,
  history,
  cachedCreateViewFormState,
  onAction,
}) => {
  const entityName = _get(match, 'params.entityName');
  const viewName = _get(match, 'params.viewName');
  const viewType = _get(viewSummary, `${FIELD_IDS.VIEW_TYPE}[0]`) || _get(initialViewConfiguration, 'viewType');

  const handleViewSummarySubmit = useCallback(
    (newViewSummary, updatedEntityDefAccordingToSelectedRecordTypes, newCachedCreateViewFormState) => {
      onAction({
        type: ACTION_TYPES.SAVE_VIEW_SUMMARY,
        payload: {
          viewSummary: newViewSummary,
          updatedEntityDefAccordingToSelectedRecordTypes,
          cachedCreateViewFormState: newCachedCreateViewFormState,
        },
      });

      history.push(`${match.url}/configure`);
    },
    [history, match.url, onAction],
  );

  const handleViewConfigurationSave = useCallback(
    (viewConfiguration) => {
      onAction({
        type: ACTION_TYPES.SAVE_VIEW_CONFIGURATION,
        payload: {
          viewConfiguration,
        },
      });
    },
    [onAction],
  );

  const handleViewConfigurationCancel = useCallback(() => {
    onAction({
      type: ACTION_TYPES.CANCEL_VIEW_CONFIGURATION,
    });
  }, [onAction]);

  const handleViewConfigurationSaved = useCallback(() => {
    history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VIEW_BUILDER}/${VIEW_BUILDER_LIST_TAB_IDS.RECORD_TYPE_VIEW}`);
  }, [history, entityName]);

  const handleViewConfigurationCanceled = useCallback(() => {
    onAction({
      type: ACTION_TYPES.SAVE_VIEW_CONFIGURATION_MODAL_CLOSE,
    });
  }, [onAction]);

  const handleViewConfigurationPreview = useCallback(
    (payload) => {
      onAction({
        type: ACTION_TYPES.PREVIEW_VIEW_CONFIG,
        payload,
      });
    },
    [onAction],
  );

  const viewConfiguration = useMemo(() => {
    switch (viewType) {
      case VIEW_TYPES.DETAIL_VIEW:
        return initialViewConfiguration || getDetailViewConfiguration(viewSummary, currentRecordTypeEntity);

      case VIEW_TYPES.LIST_VIEW:
        return initialViewConfiguration || getListViewConfiguration(viewSummary, currentRecordTypeEntity);

      case VIEW_TYPES.FORM_VIEW:
        return initialViewConfiguration || getFormViewConfiguration(viewSummary, currentRecordTypeEntity);

      case VIEW_TYPES.CELL_VIEW:
        return initialViewConfiguration || getCellViewConfiguration(viewSummary, currentRecordTypeEntity);

      case VIEW_TYPES.GRID_VIEW:
        return initialViewConfiguration || getGridViewConfiguration(viewSummary, currentRecordTypeEntity);

      case VIEW_TYPES.RECORD_TYPE_SELECTION_VIEW:
        return initialViewConfiguration;

      default:
        return getEmptyViewConfiguration();
    }
  }, [viewType, viewSummary, initialViewConfiguration, currentRecordTypeEntity]);

  const renderEntityView = () => <CreateEntityView isLoading={isLoading} history={history} match={match} entity={entity} />;

  const renderRecordTypeView = () => (
    <CreateView
      history={history}
      match={match}
      isLoading={isLoading}
      entity={entity}
      initialViewConfiguration={viewConfiguration}
      cachedCreateViewFormState={cachedCreateViewFormState}
      onViewSummarySubmit={handleViewSummarySubmit}
    />
  );

  const renderView = () => {
    const { tabId = VIEW_BUILDER_LIST_TAB_IDS.RECORD_TYPE_VIEW } = tget(match, 'params');

    if (tabId === VIEW_BUILDER_LIST_TAB_IDS.ENTITY_VIEW) {
      return renderEntityView();
    }
    return renderRecordTypeView();
  };

  const renderCancelModal = useCallback(() => {
    if (isCancelModalVisible) {
      return (
        <div className={styles.cancelModal}>
          {__('Are you sure, you want to exit?')}
          <br />
          {__('If you exit any unsaved changes for ')}
          <strong>{viewName}</strong>
          {__(' will be lost.')}
        </div>
      );
    }
    return null;
  }, [isCancelModalVisible, viewName]);

  const renderConfigure = () => {
    if (!viewSummary && !viewName) {
      history.replace(
        `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VIEW_BUILDER_CREATE}/${VIEW_BUILDER_LIST_TAB_IDS.RECORD_TYPE_VIEW}`,
      );
    }

    return (
      <div className={styles.viewBuilder}>
        <ViewBuilder
          isEditMode={isEditMode}
          viewType={viewType}
          entity={currentRecordTypeEntity}
          entityRecord={entityRecord}
          initialViewConfiguration={viewConfiguration}
          history={history}
          viewConfigsByName={viewConfigsByName}
          onViewConfigurationSave={handleViewConfigurationSave}
          onViewConfigurationPreview={handleViewConfigurationPreview}
          onViewConfigurationCancel={handleViewConfigurationCancel}
        />

        <Modal
          visible={isCancelModalVisible}
          destroyOnClose
          width={Modal.SIZES.SM}
          title={__('Exit View Configurator')}
          submitBtnText={__('Yes')}
          onCancel={handleViewConfigurationCanceled}
          onSubmit={handleViewConfigurationSaved}
        >
          {renderCancelModal()}
        </Modal>
      </div>
    );
  };

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.INIT,
    });
  }, [onAction, entityName]);

  return (
    <Switch>
      <Route path={`${match.path}/configure`} render={renderConfigure} />
      <Route path={`${ROUTES.VIEW_BUILDER_CREATE_ROUTE}`} render={renderView} />
      <Route path={`${ROUTES.VIEW_BUILDER_EDIT_ROUTE}`} render={renderView} />
    </Switch>
  );
};

ViewBuilderPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isCancelModalVisible: PropTypes.bool,
  entity: PropTypes.object,
  currentRecordTypeEntity: PropTypes.object,
  entityRecord: PropTypes.array,
  viewSummary: PropTypes.object,
  viewConfigsByName: PropTypes.object,
  initialViewConfiguration: PropTypes.object,
  cachedCreateViewFormState: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ViewBuilderPage.defaultProps = {
  isLoading: true,
  isEditMode: false,
  isCancelModalVisible: false,
  entity: undefined,
  currentRecordTypeEntity: undefined,
  entityRecord: undefined,
  viewSummary: undefined,
  viewConfigsByName: undefined,
  initialViewConfiguration: undefined,
  cachedCreateViewFormState: EMPTY_OBJECT,
};

export default compose(withRouter, withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ViewBuilderPage);
