import produce from 'immer';

import _set from 'lodash/set';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _castArray from 'lodash/castArray';
import _head from 'lodash/head';
import _snakeCase from 'lodash/snakeCase';

// Utils
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

// Constants
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { getViewSummary, getTableConfig, getRecordTypeOptions } from './createView.general.helpers';
import { getUpdatedEntityDefFromRecordTypeValues } from '../../../../../../helpers/entityManagement.helpers';

import { ACTION_TYPES, FIELD_IDS } from '../constants/createView.constants';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import { ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../../constants/viewBuilder.constants';

import entityReader from '../../../../../../readers/entity.reader';

const handleInit = ({ setState, params = EMPTY_OBJECT }) => {
  const { initialViewConfiguration, entity, cachedCreateViewFormState } = params;
  const selectedColumnIds = tget(cachedCreateViewFormState, 'selectedColumnIds', EMPTY_ARRAY);

  const viewName = _get(initialViewConfiguration, VIEW_CONFIGURATION_FIELD_IDS.NAME);

  let formValues = {};
  let applicableRecordTypes = [];
  const formMode = viewName ? FORM_MODES.EDIT : FORM_MODES.CREATE;

  // viewName can be present in both Edit_Mode and in Create_Mode when user fills the form routes to next page and redirects back here
  if (viewName) {
    applicableRecordTypes = _map(tget(initialViewConfiguration, 'applicableRecordTypes'), 'recordTypeName');

    formValues = {
      [FIELD_IDS.VIEW_DISPLAY_NAME]: tget(initialViewConfiguration, `${VIEW_CONFIGURATION_FIELD_IDS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.TITLE}`),
      [FIELD_IDS.VIEW_NAME]: tget(initialViewConfiguration, VIEW_CONFIGURATION_FIELD_IDS.NAME),
      [FIELD_IDS.VIEW_TYPE]: _castArray(tget(initialViewConfiguration, VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE)),
      [FIELD_IDS.VIEW_DESCRIPTION]: tget(
        initialViewConfiguration,
        `${VIEW_CONFIGURATION_FIELD_IDS.PROPERTIES}.${VIEW_CONFIGURATION_FIELD_IDS.DESCRIPTION}`,
      ),
      [FIELD_IDS.APPLICABLE_RECORD_TYPES]: applicableRecordTypes,
    };
  } else {
    applicableRecordTypes = [entityReader.defaultRecordType(entity)];

    formValues = {
      [FIELD_IDS.APPLICABLE_RECORD_TYPES]: applicableRecordTypes,
    };
  }

  const updatedEntityDef = getUpdatedEntityDefFromRecordTypeValues(applicableRecordTypes, entity, tget(formValues, FIELD_IDS.VIEW_TYPE));
  const recordTypeOptions = getRecordTypeOptions(entity);

  if (formMode === FORM_MODES.CREATE && viewName) {
    formValues = {
      ...formValues,
      [FIELD_IDS.COLUMN_TABLE]: getTableConfig(updatedEntityDef),
    };
  }

  setState({ formMode, formValues, recordTypeOptions, updatedEntityDefAccordingToSelectedRecordTypes: updatedEntityDef, selectedColumnIds });
};

const handleFieldOnChange = ({ setState, getState, params }) => {
  const { formValues = {}, entity, disableAutoFillForName = false, formMode } = getState();
  const { id, value } = params;

  let newFormValues = {
    ...formValues,
    [id]: value,
  };

  let recordTypeValues;

  if (id === FIELD_IDS.APPLICABLE_RECORD_TYPES) {
    recordTypeValues = value;
  } else if (id === FIELD_IDS.VIEW_TYPE) {
    recordTypeValues = _castArray(_head(tget(formValues, FIELD_IDS.APPLICABLE_RECORD_TYPES)) || []);
  } else {
    recordTypeValues = tget(formValues, FIELD_IDS.APPLICABLE_RECORD_TYPES, []);
  }

  const updatedEntityDef = getUpdatedEntityDefFromRecordTypeValues(recordTypeValues, entity, tget(newFormValues, FIELD_IDS.VIEW_TYPE));

  if (id === FIELD_IDS.VIEW_DISPLAY_NAME && formMode === FORM_MODES.CREATE && !disableAutoFillForName) {
    newFormValues = {
      ...newFormValues,
      [FIELD_IDS.VIEW_NAME]: _snakeCase(value),
    };
  } else if (id === FIELD_IDS.VIEW_NAME) {
    setState({ disableAutoFillForName: true });
  } else if (id === FIELD_IDS.VIEW_TYPE) {
    newFormValues = {
      ...newFormValues,
      [FIELD_IDS.COLUMN_TABLE]: getTableConfig(updatedEntityDef),
      [FIELD_IDS.APPLICABLE_RECORD_TYPES]: _castArray(_head(tget(formValues, FIELD_IDS.APPLICABLE_RECORD_TYPES)) || []),
    };
  } else if (id === FIELD_IDS.APPLICABLE_RECORD_TYPES) {
    newFormValues = {
      ...newFormValues,
      [FIELD_IDS.COLUMN_TABLE]: getTableConfig(updatedEntityDef),
    };
  }

  setState({
    formValues: newFormValues,
    updatedEntityDefAccordingToSelectedRecordTypes: updatedEntityDef,
  });
};

const handleTableOnChange = ({ setState, getState, params }) => {
  const { id, value, columnId, rowIndex } = params;
  const { formValues } = getState();

  const fieldColumns = _get(formValues, FIELD_IDS.COLUMN_TABLE, []);
  const tableValues = produce(fieldColumns, (draft) => _set(draft, `[${rowIndex}].${columnId}`, value));

  setState({
    formValues: {
      ...formValues,

      [id]: tableValues,
    },
  });
};

const handleRowSelect = async ({ setState, params = EMPTY_OBJECT }) => {
  const { selectedColumnIds } = params;

  setState({ selectedColumnIds });
};

const handleValidation = ({ setState, params }) => {
  const { errors } = params;

  setState({ errors });
};

const handleRedirection = ({ getState }) => {
  const { history } = getState();
  history.goBack();
};

const handleSubmit = async ({ setState, getState }) => {
  try {
    const { formValues, selectedColumnIds, onViewSummarySubmit, updatedEntityDefAccordingToSelectedRecordTypes = EMPTY_OBJECT } = getState();
    const viewSummary = getViewSummary(formValues, selectedColumnIds);

    const cachedCreateViewFormState = { selectedColumnIds };

    onViewSummarySubmit(viewSummary, updatedEntityDefAccordingToSelectedRecordTypes, cachedCreateViewFormState);
  } catch (error) {
    setState({ formErrors: error });
    toaster(TOASTER_TYPE.ERROR, error);
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INITIALIZE]: handleInit,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidation,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldOnChange,
  [ACTION_TYPES.ON_TABLE_CHANGE]: handleTableOnChange,
  [ACTION_TYPES.ROW_SELECT]: handleRowSelect,
  [ACTION_TYPES.ON_CANCEL]: handleRedirection,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
};

export default ACTION_HANDLERS;
