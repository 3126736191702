import Button from '@tekion/tekion-components/atoms/Button';
import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';
import { STATUS } from '@tekion/tekion-base/constants/status.constants';

import ACTION_TYPES from './dataImportList.actionTypes';

const COLUMN_IDS = {
  ID: 'id',
  NAME: 'name',
  ENTITY_NAME: 'entityName',
  OPERATION_TYPE: 'operationType',
  FILE_NAME: 'fileName',
  TASK_STATUS: 'taskStatus',
  MEDIA_ID: 'mediaId',
  ADDITIONAL_DATA: 'additionalData',
  ENTITY_DISPLAY_NAME: 'entityDisplayName',
};

const INITIAL_STATE_FOR_DATA_IMPORT_LIST = {
  currentPage: 0,
  pageSize: 50,
};

const HEADER_PROPS = {
  label: __('Data Import'),
};

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Import New'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.ON_CLICK_IMPORT_NEW,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showHeader: true,
  showSearch: false,
  showSubHeader: true,
  showRefreshIcon: true,
  showFilter: true,
};

const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

const OPERATION_TYPE_FILTER = {
  id: COLUMN_IDS.OPERATION_TYPE,
  key: COLUMN_IDS.OPERATION_TYPE,
  type: FILTER_TYPES.SINGLE_SELECT,
  name: __('Action Type'),
};

const STATUS_FILTER = {
  id: COLUMN_IDS.TASK_STATUS,
  key: COLUMN_IDS.TASK_STATUS,
  type: FILTER_TYPES.SINGLE_SELECT,
  name: __('Status'),
};

const DEFAULT_FILTERS = [COLUMN_IDS.OPERATION_TYPE, COLUMN_IDS.TASK_STATUS];

const OPERATION_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  UPSERT: 'UPSERT',
  CREATE_IF_ABSENT: 'CREATE_IF_ABSENT',
};

const OPERATION_TYPE_OPTIONS = [
  { label: __('Create'), value: OPERATION_TYPES.CREATE },
  { label: __('Update'), value: OPERATION_TYPES.UPDATE },
  { label: __('Upsert'), value: OPERATION_TYPES.UPSERT },
  { label: __('Create If Absent'), value: OPERATION_TYPES.CREATE_IF_ABSENT },
];

const STATUS_OPTIONS = [
  { label: 'Pending', value: STATUS.PENDING },
  { label: 'Completed', value: STATUS.COMPLETED },
  { label: 'In Progress', value: STATUS.IN_PROGRESS },
  { label: 'Failed', value: STATUS.FAILED },
];

export {
  COLUMN_IDS,
  HEADER_PROPS,
  SUB_HEADER_PROPS,
  TABLE_MANAGER_PROPS,
  TABLE_CONSTANTS,
  DEFAULT_FILTERS,
  OPERATION_TYPE_FILTER,
  STATUS_FILTER,
  STATUS_OPTIONS,
  OPERATION_TYPE_OPTIONS,
  INITIAL_STATE_FOR_DATA_IMPORT_LIST,
};
