import _property from 'lodash/property';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { getTextInputValueOnChange } from '@tekion/tekion-components/organisms/inputTable/utils/onChangeValueAccessors';
import CellConfig from '@tekion/tekion-components/organisms/inputTable/builders/CellConfig';
import ColumnConfig from '@tekion/tekion-components/organisms/inputTable/builders/ColumnConfig';
import TextInputCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/TextInputCellRenderer';

import ValueField from '../../../../../../../../../../organisms/conditionBuilder/atoms/valueField/ValueField';
import makeInputCellRendererForFieldRenderer from '../../../../../../../../../../connectors/makeInputCellRendererForFieldRenderer';

import { REQUEST_DETAILS_FIELD_IDS } from '../../calloutModal.constants';

import styles from './calloutRequestDetailsRenderer.module.scss';

const ValueFieldInputCellRenderer = makeInputCellRendererForFieldRenderer(ValueField);

const getRequestNameColumn = () =>
  new ColumnConfig(REQUEST_DETAILS_FIELD_IDS.REQUEST_KEY)
    .setAccessor(_property(REQUEST_DETAILS_FIELD_IDS.REQUEST_KEY))
    .setCellClassName(styles.nameCell)
    .addCellConfig(new CellConfig().setComponent(TextInputCellRenderer))
    .setHeader(__('Name'))
    .setGetValueFromOnChange(getTextInputValueOnChange);

const onValueChange = (data, e) => _get(e, 'value', '');

const mapCellPropsToComponentProps = (conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName) => (props) => {
  const isDisabled = _isEmpty(tget(props, `rowData.${REQUEST_DETAILS_FIELD_IDS.REQUEST_KEY}`));
  return {
    ...props,
    id: REQUEST_DETAILS_FIELD_IDS.REQUEST_VALUE,
    conditionBuilderFieldDefinitionObject,
    mapOfVariableToEntityName,
    showFieldSwitcher: true,
    isDisabled,
  };
};

const getRequestValueColumn = (conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName) =>
  new ColumnConfig(REQUEST_DETAILS_FIELD_IDS.REQUEST_VALUE)
    .setAccessor(_property(REQUEST_DETAILS_FIELD_IDS.REQUEST_VALUE))
    .addCellConfig(
      new CellConfig()
        .setComponent(ValueFieldInputCellRenderer)
        .setMapCellPropsToComponentProps(mapCellPropsToComponentProps(conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName)),
    )
    .setHeader(__('Value'))
    .setGetValueFromOnChange(onValueChange);

const getColumns = (conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName) => [
  getRequestNameColumn(),
  getRequestValueColumn(conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName),
];

export default getColumns;
