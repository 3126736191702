import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { TAB_PROPERTIES_CONFIGURE_FIELD_IDS } from '../constants/tabPropertiesConfigurator.fieldIds';
import { TAB_TEMPLATE_TYPE } from '../../../../../constants/visualBuilder.properties.constants';

const getFormFormattedSelectedWidgetProperties = (selectedWidgetProperties) => {
  const widgetPosition = tget(selectedWidgetProperties, TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_POSITION);
  const widgetSize = tget(selectedWidgetProperties, TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_SIZE);
  const tabTemplateType = tget(selectedWidgetProperties, TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_TEMPLATE_TYPE, TAB_TEMPLATE_TYPE.DEFAULT);

  let newSelectedWidgetProperties = { ...selectedWidgetProperties };

  if (!_isEmpty(widgetPosition)) {
    newSelectedWidgetProperties = {
      ...newSelectedWidgetProperties,
      [TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_POSITION]: _castArray(widgetPosition),
    };
  }

  if (!_isEmpty(widgetSize)) {
    newSelectedWidgetProperties = {
      ...newSelectedWidgetProperties,
      [TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_SIZE]: _castArray(widgetSize),
    };
  }

  if (!_isEmpty(tabTemplateType)) {
    newSelectedWidgetProperties = {
      ...newSelectedWidgetProperties,
      [TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_TEMPLATE_TYPE]: _castArray(tabTemplateType),
    };
  }

  return newSelectedWidgetProperties;
};

const getConfigFormattedSelectedWidgetProperties = (value, id, selectedWidgetProperties) => {
  let newSelectedWidgetProperties = { ...selectedWidgetProperties, [id]: value };
  const widgetPosition = tget(newSelectedWidgetProperties, TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_POSITION);
  const widgetSize = tget(newSelectedWidgetProperties, TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_SIZE);
  const tabTemplateType = tget(newSelectedWidgetProperties, TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_TEMPLATE_TYPE);

  if (!_isEmpty(widgetSize)) {
    newSelectedWidgetProperties = {
      ...newSelectedWidgetProperties,
      [TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_SIZE]: getArraySafeValue(widgetSize),
    };
  }

  if (!_isEmpty(widgetPosition)) {
    newSelectedWidgetProperties = {
      ...newSelectedWidgetProperties,
      [TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_POSITION]: getArraySafeValue(widgetPosition),
    };
  }

  if (!_isEmpty(tabTemplateType)) {
    newSelectedWidgetProperties = {
      ...newSelectedWidgetProperties,
      [TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_TEMPLATE_TYPE]: getArraySafeValue(tabTemplateType),
    };
  }

  return newSelectedWidgetProperties;
};

export { getFormFormattedSelectedWidgetProperties, getConfigFormattedSelectedWidgetProperties };
