import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _filter from 'lodash/filter';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { TABS } from '../../constants/permissionSetConfigure.constants';

import entityReader from '../../../../../../../readers/entity.reader';
import workspaceUserReader from '../../../../../../../readers/workSpaceUser.reader';

const ROWS = 30;

export const getHasMore = (nextPageToken) => !_isEmpty(nextPageToken);

export const getLoadingMessage = () => __('Searching...');

export const getOptions = (data, existingData, tabType) => {
  let dropDownOptions = [];
  switch (tabType) {
    case TABS.PROJECT_SETTINGS:
      {
        const filteredProjectData = _filter(data, (projectData) => !_includes(existingData, _get(projectData, 'id', EMPTY_STRING)));
        dropDownOptions = _map(filteredProjectData, (project) => ({
          label: _get(project, 'displayName', EMPTY_STRING) || _get(project, 'name'),
          value: _get(project, 'id', EMPTY_STRING),
        }));
      }
      break;
    case TABS.USERS:
      {
        const filteredUsersData = _filter(data, (userData) => !_includes(existingData, _get(userData, 'id', EMPTY_STRING)));
        dropDownOptions = _map(filteredUsersData, (user) => ({
          label: `${workspaceUserReader.firstName(user)} ${workspaceUserReader.lastName(user)}`,
          value: workspaceUserReader.id(user),
        }));
      }
      break;
    case TABS.USER_GROUPS:
      {
        const filteredUserGroupData = _filter(data, (userGroup) => !_includes(existingData, _get(userGroup, 'id', EMPTY_STRING)));
        dropDownOptions = _map(filteredUserGroupData, (userGroup) => ({
          label: _get(userGroup, 'displayName', EMPTY_STRING),
          value: _get(userGroup, 'name', EMPTY_STRING),
        }));
      }
      break;
    case TABS.ENTITY_SETTINGS:
      {
        const presentEntities = _map(existingData, 'entityAccess.scope');
        const filteredEntityGroupData = _filter(data, (entity) => !_includes(presentEntities, entityReader.name(entity)));
        dropDownOptions = _map(filteredEntityGroupData, (entity) => ({
          label: _get(entity, 'displayName', EMPTY_STRING) || _get(entity, 'name'),
          value: _get(entity, 'name', EMPTY_STRING),
        }));
      }
      break;

    default:
      dropDownOptions = EMPTY_ARRAY;
  }
  return dropDownOptions;
};

export const getPayload = (nextPageToken, searchText, tabType) => {
  let payload = { rows: ROWS };

  if (!_isEmpty(searchText)) {
    if (tabType === TABS.USERS) {
      payload = {
        filters: [
          {
            field: 'firstName',
            values: [searchText],
            filterType: 'TEXT_STARTS_WITH',
          },
        ],
        ...payload,
      };
    } else {
      payload = {
        filters: [
          {
            field: 'displayName',
            values: [searchText],
            filterType: 'TEXT_STARTS_WITH',
          },
        ],
        ...payload,
      };
    }
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      nextPageToken,
      ...payload,
    };
  }

  return payload;
};
