import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _size from 'lodash/size';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import styles from './recordSharingRuleUsersDetailColumnRenderer.module.scss';

const RecordSharingRuleUsersDetailColumnRenderer = ({ data, getAllDataById }) => {
  const dataSize = _size(data);

  return dataSize < 3 ? (
    _map(data, (id) => {
      const info = _get(getAllDataById, id);
      return <div className={styles.infoContainer}>{_get(info, 'name')}</div>;
    })
  ) : (
    <>
      <div className={styles.infoContainer}>{tget(tget(getAllDataById, data[0]), 'name')}</div>
      <div className={styles.infoContainer}>{tget(tget(getAllDataById, data[1]), 'name')}</div>
      <div className={styles.infoContainer}>{`+${dataSize - 2} more`}</div>
    </>
  );
};

RecordSharingRuleUsersDetailColumnRenderer.propTypes = {
  data: PropTypes.array,
  getAllDataById: PropTypes.object,
};

RecordSharingRuleUsersDetailColumnRenderer.defaultProps = {
  data: EMPTY_ARRAY,
  getAllDataById: EMPTY_OBJECT,
};

export default makeCellRenderer(RecordSharingRuleUsersDetailColumnRenderer);
