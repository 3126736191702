exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".rightPanel_container__s7vsSi5cwX{display:flex;flex-flow:column nowrap;flex:1 1 25%;overflow:auto}[dir] .rightPanel_container__s7vsSi5cwX{padding:.8rem 2rem;background-color:#fff}.rightPanel_headerContainer__bvAEJWdFKL{height:5rem}[dir] .rightPanel_headerContainer__bvAEJWdFKL{padding:1rem;margin-bottom:1rem}.rightPanel_headerContainer__bvAEJWdFKL .rightPanel_content__cw15uewvsZ{display:flex;justify-content:space-between;align-items:center}.rightPanel_createWidgetContainer__wdJnH5rRPM{display:flex;justify-content:space-between;align-items:center;flex-flow:row nowrap}.rightPanel_createWidgetButton__fJqEW1voxP{display:flex;justify-content:space-between;align-items:center;flex-flow:row nowrap}", ""]);

// Exports
exports.locals = {
	"container": "rightPanel_container__s7vsSi5cwX",
	"headerContainer": "rightPanel_headerContainer__bvAEJWdFKL",
	"content": "rightPanel_content__cw15uewvsZ",
	"createWidgetContainer": "rightPanel_createWidgetContainer__wdJnH5rRPM",
	"createWidgetButton": "rightPanel_createWidgetButton__fJqEW1voxP"
};