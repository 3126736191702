import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import loginConfigurationsServices from '../services/loginConfigurations.services';

const fetchLoginConfigurations = async () => {
  try {
    const response = await loginConfigurationsServices.fetchLoginConfigurations();
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch login configurations, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const addLoginConfigurations = async (payload) => {
  try {
    const response = await loginConfigurationsServices.addLoginConfigurations(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to add login configurations, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const updateLoginConfigurations = async (payload) => {
  try {
    const response = await loginConfigurationsServices.updateLoginConfigurations(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update login configurations, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const removeLoginConfigurations = async (providerType) => {
  try {
    const response = await loginConfigurationsServices.removeLoginConfigurations(providerType);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to remove login configurations, please try again later.')));
    return EMPTY_ARRAY;
  }
};

export { fetchLoginConfigurations, addLoginConfigurations, updateLoginConfigurations, removeLoginConfigurations };
