/* eslint-disable */
import { ELEMENT_IMAGE } from '@udecode/plate-image';

import { Text } from 'slate';
import _map from 'lodash/map';
import _join from 'lodash/join';
import _isArray from 'lodash/isArray';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
import _isString from 'lodash/isString';
import _escape from 'lodash/escape';

import { pipeOverrideProps } from '@udecode/plate-core';
import { createElementWithSlate } from '@udecode/plate-common';
import { renderToStaticMarkup } from 'react-dom/server';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

const stripClassNames = (html, { preserveClassNames = ['slate-'] }) => {
  const allClasses = html.split(/(class="[^"]*")/g);
  let filteredHtml = '';
  allClasses.forEach((item, index) => {
    if (index % 2 === 0) {
      filteredHtml += item;
      return filteredHtml;
    }

    const preserveRegExp = new RegExp(preserveClassNames.map((cn) => `${cn}[^"\\s]*`).join('|'), 'g');
    const slateClassNames = item.match(preserveRegExp);

    if (slateClassNames) {
      filteredHtml += `class="${slateClassNames.join(' ')}"`;
    }
  });
  return filteredHtml;
};

const getNode = (editor, { plugins, elementProps, slateProps, preserveClassNames }) => {
  // If no type provided we wrap children with div tag

  if (elementProps.element.type === ELEMENT_IMAGE) {
    const url = tget(elementProps, 'element.data.url', EMPTY_STRING);
    const mediaId = tget(elementProps, 'element.data.mediaId', EMPTY_STRING);
    let width = tget(elementProps, 'element.width', 250);

    if (_isString(width)) {
      width = 250;
    }

    return `<img style="width: ${width}px" src="${url}" id="mediaid{${mediaId}}"></img>`;
  }

  if (!elementProps.element.type) {
    return `<div>${elementProps.children}</div>`;
  }

  let html;
  const overriders = plugins.flatMap((plugin) => {
    var _plugin$overrideProps, _plugin$overrideProps2;

    return (_plugin$overrideProps =
      (_plugin$overrideProps2 = plugin.overrideProps) === null || _plugin$overrideProps2 === void 0
        ? void 0
        : _plugin$overrideProps2.call(plugin, editor)) !== null && _plugin$overrideProps !== void 0
      ? _plugin$overrideProps
      : [];
  });
  elementProps = pipeOverrideProps(elementProps, overriders); // Search for matching plugin based on element type

  plugins.some((plugin) => {
    var _plugin$serialize,
      _plugin$deserialize,
      _plugin$deserialize$c,
      _plugin$serialize$ele,
      _plugin$serialize2,
      _plugin$serialize2$el,
      _plugin$renderElement;

    if (!((_plugin$serialize = plugin.serialize) !== null && _plugin$serialize !== void 0 && _plugin$serialize.element) && !plugin.renderElement)
      return false;

    if (
      !(
        (_plugin$deserialize = plugin.deserialize) !== null &&
        _plugin$deserialize !== void 0 &&
        (_plugin$deserialize$c = _plugin$deserialize.call(plugin, editor).element) !== null &&
        _plugin$deserialize$c !== void 0 &&
        _plugin$deserialize$c.some((item) => item.type === String(elementProps.element.type))
      )
    ) {
      html = `<div>${elementProps.children}</div>`;
      return false;
    } // Render element using picked plugins renderElement function and ReactDOM

    html = renderToStaticMarkup(
      createElementWithSlate({
        ...slateProps,
        children:
          (_plugin$serialize$ele =
            (_plugin$serialize2 = plugin.serialize) === null || _plugin$serialize2 === void 0
              ? void 0
              : (_plugin$serialize2$el = _plugin$serialize2.element) === null || _plugin$serialize2$el === void 0
              ? void 0
              : _plugin$serialize2$el.call(_plugin$serialize2, elementProps)) !== null && _plugin$serialize$ele !== void 0
            ? _plugin$serialize$ele
            : (_plugin$renderElement = plugin.renderElement) === null || _plugin$renderElement === void 0
            ? void 0
            : _plugin$renderElement.call(plugin, editor)(elementProps),
      }),
    );
    // html = stripClassNames(html, {
    //   preserveClassNames,
    // });
    return true;
  });
  return html;
};

const getLeaf = (editor, { plugins, leafProps, slateProps, preserveClassNames }) => {
  const { children } = leafProps;
  return plugins.reduce((result, plugin) => {
    var _plugin$serialize3,
      _plugin$serialize$lea,
      _plugin$serialize4,
      _plugin$serialize4$le,
      _plugin$renderLeaf,
      _plugin$serialize$lea2,
      _plugin$serialize5,
      _plugin$serialize5$le,
      _plugin$renderLeaf2;

    if (!((_plugin$serialize3 = plugin.serialize) !== null && _plugin$serialize3 !== void 0 && _plugin$serialize3.leaf) && !plugin.renderLeaf)
      return result;
    if (
      ((_plugin$serialize$lea =
        (_plugin$serialize4 = plugin.serialize) === null || _plugin$serialize4 === void 0
          ? void 0
          : (_plugin$serialize4$le = _plugin$serialize4.leaf) === null || _plugin$serialize4$le === void 0
          ? void 0
          : _plugin$serialize4$le.call(_plugin$serialize4, leafProps)) !== null && _plugin$serialize$lea !== void 0
        ? _plugin$serialize$lea
        : (_plugin$renderLeaf = plugin.renderLeaf) === null || _plugin$renderLeaf === void 0
        ? void 0
        : _plugin$renderLeaf.call(plugin, editor)(leafProps)) === children
    )
      return result;
    const overriders = plugins.flatMap((p) => {
      var _p$overrideProps, _p$overrideProps2;

      return (_p$overrideProps =
        (_p$overrideProps2 = p.overrideProps) === null || _p$overrideProps2 === void 0 ? void 0 : _p$overrideProps2.call(p, editor)) !== null &&
        _p$overrideProps !== void 0
        ? _p$overrideProps
        : [];
    });
    leafProps = { ...pipeOverrideProps(leafProps, overriders), children: encodeURIComponent(result) };
    let html = decodeURIComponent(
      renderToStaticMarkup(
        createElementWithSlate({
          ...slateProps,
          children:
            (_plugin$serialize$lea2 =
              (_plugin$serialize5 = plugin.serialize) === null || _plugin$serialize5 === void 0
                ? void 0
                : (_plugin$serialize5$le = _plugin$serialize5.leaf) === null || _plugin$serialize5$le === void 0
                ? void 0
                : _plugin$serialize5$le.call(_plugin$serialize5, leafProps)) !== null && _plugin$serialize$lea2 !== void 0
              ? _plugin$serialize$lea2
              : (_plugin$renderLeaf2 = plugin.renderLeaf) === null || _plugin$renderLeaf2 === void 0
              ? void 0
              : _plugin$renderLeaf2.call(plugin, editor)(leafProps),
        }),
      ),
    );
    html = stripClassNames(html, {
      preserveClassNames,
    });
    return html;
  }, children);
};

const isEncoded = (str = '') => {
  try {
    return str !== decodeURIComponent(str);
  } catch (error) {
    return false;
  }
};

// Remove extra whitespace generated by ReactDOMServer
const trimWhitespace = (rawHtml) => rawHtml.replace(/(\r\n|\n|\r|\t)/gm, ''); // Remove redundant data attributes

const stripSlateDataAttributes = (rawHtml) =>
  rawHtml.replace(/( data-slate)(-node|-type|-leaf)="[^"]+"/gm, '').replace(/( data-testid)="[^"]+"/gm, '');
/**
 * Remove all class names that do not start with one of preserveClassNames (`slate-` by default)
 */

const serializeHTMLFromNodes = (editor, { plugins, nodes, slateProps, stripDataAttributes = true, preserveClassNames, stripWhitespace = true }) => {
  let result = nodes
    .map((node) => {
      if (Text.isText(node)) {
        return getLeaf(editor, {
          plugins,
          leafProps: {
            leaf: node,
            text: node,
            children: _escape(node.text),
            attributes: {
              'data-slate-leaf': true,
            },
          },
          slateProps,
          preserveClassNames,
        });
      }

      return getNode(editor, {
        plugins,
        elementProps: {
          element: node,
          children: encodeURIComponent(
            serializeHTMLFromNodes(editor, {
              plugins,
              nodes: node.children,
              preserveClassNames,
              stripWhitespace,
            }),
          ),
          attributes: {
            'data-slate-node': 'element',
            ref: null,
          },
        },
        slateProps,
        preserveClassNames,
      });
    })
    .join('');

  if (isEncoded(result)) {
    result = decodeURIComponent(result);
  }

  if (stripWhitespace) {
    result = trimWhitespace(result);
  }

  if (stripDataAttributes) {
    result = stripSlateDataAttributes(result);
  }

  return result;
};

export { serializeHTMLFromNodes };
