import React, { useCallback, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';

import AssignModal from '../assignModal';
import withSize from '../../../../../../../connectors/withSize';
import ACTION_HANDLERS from './helpers/userGroupsSetting.actionHandlers';
import { getAllRecordGroups } from '../../../../../../../actions/recordGroup.actions';
import { createTableProps, getSubHeaderProps } from './helpers/userGroupsSetting.helpers';

import { COLUMN_CONFIG } from './helpers/userGroupsSetting.columns';
import { TABLE_MANAGER_PROPS } from './constants/userGroupsSetting.general';
import { TABS } from '../../constants/permissionSetConfigure.constants';

const UserGroupsSettings = ({
  contentHeight,
  isLoading,
  isAssignUserGroupModalVisible,
  isAssignUserGroupModalDataSubmitting,
  totalCount,
  userGroupsDropdownOptions,
  selectedUserGroupsModalData,
  userGroupsSettingData,
  userGroupNames,
  onAction,
}) => {
  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({
        type: actionType,
        payload,
      });
    },
    [onAction],
  );

  const tableProps = useMemo(() => createTableProps(isLoading, handleRowAction), [isLoading, handleRowAction]);

  return (
    <Page>
      <Page.Body style={{ height: contentHeight - 110 }}>
        <TableManager
          subHeaderProps={getSubHeaderProps(totalCount)}
          tableManagerProps={TABLE_MANAGER_PROPS}
          columns={COLUMN_CONFIG}
          tableProps={tableProps}
          data={userGroupsSettingData}
          onAction={onAction}
        />
        <AssignModal
          isAssignModalVisible={isAssignUserGroupModalVisible}
          isMulti
          isAssignModalSubmitting={isAssignUserGroupModalDataSubmitting}
          modalTitle={__('Assign Group')}
          selectDropdownOptions={userGroupsDropdownOptions}
          selectedDropdownData={selectedUserGroupsModalData}
          getOptionsApi={getAllRecordGroups}
          tabType={TABS.USER_GROUPS}
          existingData={userGroupNames}
          onAction={onAction}
        />
      </Page.Body>
    </Page>
  );
};

UserGroupsSettings.propTypes = {
  contentHeight: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isAssignUserGroupModalVisible: PropTypes.bool,
  isAssignUserGroupModalDataSubmitting: PropTypes.bool,
  totalCount: PropTypes.number,
  userGroupNames: PropTypes.array,
  userGroupsDropdownOptions: PropTypes.array,
  selectedUserGroupsModalData: PropTypes.array,
  userGroupsSettingData: PropTypes.array,
  onAction: PropTypes.func,
};

UserGroupsSettings.defaultProps = {
  isLoading: false,
  isAssignUserGroupModalVisible: false,
  isAssignUserGroupModalDataSubmitting: false,
  totalCount: 0,
  userGroupNames: EMPTY_ARRAY,
  userGroupsDropdownOptions: EMPTY_ARRAY,
  selectedUserGroupsModalData: EMPTY_ARRAY,
  userGroupsSettingData: EMPTY_ARRAY,
  onAction: _noop,
};

export default compose(withSize({ hasPageHeader: true }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(UserGroupsSettings);
