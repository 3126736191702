import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { getComponentFromComponentType } from '../../helpers/componentType.helpers';

import { VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

const ComponentRendererContainer = ({ isPreviewMode, componentConfig, ...restProps }) => {
  const componentType = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.TYPE);

  const ComponentRenderer = useMemo(() => getComponentFromComponentType(componentType, isPreviewMode), [componentType, isPreviewMode]);

  return <ComponentRenderer isPreviewMode={isPreviewMode} componentConfig={componentConfig} {...restProps} />;
};

ComponentRendererContainer.propTypes = {
  isPreviewMode: PropTypes.bool,
  componentConfig: PropTypes.object,
};

ComponentRendererContainer.defaultProps = {
  isPreviewMode: false,
  componentConfig: EMPTY_OBJECT,
};

export default ComponentRendererContainer;
