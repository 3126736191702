import { ASSET_TYPES } from '../../../../../../../../../constants/general.constants';

const APPROVAL_STAGE_INPUT_FORM_CONTEXT_ID = 'APPROVAL_STAGE_INPUT_FORM_CONTEXT_ID';

const STAGE_APPROVER_TYPES = {
  ALL: 'ALL',
  USER: ASSET_TYPES.USER,
  USER_GROUP: ASSET_TYPES.USER_GROUP,
  ROLE: ASSET_TYPES.ROLE,
};

const STAGE_APPROVER_OPTIONS = [
  { label: __('All'), value: STAGE_APPROVER_TYPES.ALL },
  { label: __('Users'), value: STAGE_APPROVER_TYPES.USER },
  { label: __('User Groups'), value: STAGE_APPROVER_TYPES.USER_GROUP },
  { label: __('Roles'), value: STAGE_APPROVER_TYPES.ROLE },
];

const ROWS = 7;

export { APPROVAL_STAGE_INPUT_FORM_CONTEXT_ID, STAGE_APPROVER_TYPES, STAGE_APPROVER_OPTIONS, ROWS };
