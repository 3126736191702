import _keyBy from 'lodash/keyBy';

const SHARE_AND_MANAGE_RECORD_MODAL_MODE = {
  SHARE_RECORD: 'SHARE_RECORD',
  MANAGE_RECORD: 'MANAGE_RECORD',
};

const SHARE_RECORD_MODAL_MODE_DATA = {
  id: SHARE_AND_MANAGE_RECORD_MODAL_MODE.SHARE_RECORD,
  modalTitle: __('Share Record'),
  submitButtonLabel: __('Share'),
};

const MANAGE_RECORD_MODAL_MODE_DATA = {
  id: SHARE_AND_MANAGE_RECORD_MODAL_MODE.MANAGE_RECORD,
  modalTitle: __('Manage Access'),
  submitButtonLabel: __('Update'),
};

const MODAL_MODE_DATA = [SHARE_RECORD_MODAL_MODE_DATA, MANAGE_RECORD_MODAL_MODE_DATA];

const MODAL_MODE_DATA_BY_ID = _keyBy(MODAL_MODE_DATA, 'id');

export { SHARE_AND_MANAGE_RECORD_MODAL_MODE, MODAL_MODE_DATA_BY_ID };
