import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _split from 'lodash/split';
import _includes from 'lodash/includes';
import _values from 'lodash/values';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import Loader from '@tekion/tekion-components/molecules/loader';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import withActions from '@tekion/tekion-components/connectors/withActions';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import ValueField from '../../../../../../organisms/conditionBuilder/atoms/valueField';
import EmailPreviewRenderer from './molecules/emailPreviewRenderer';
import TagsWithLimit from '../../../../../../atoms/tagsWithLimit';
import RichTextEditor from '../../../../../../organisms/richTextEditor';

import ACTION_HANDLERS from './sendEmailModalContentRenderer.actionHandlers';

import { getTemplateVariables } from './sendEmailModalContentRenderer.helpers';
import { getStaticToFieldSelectProps } from '../../../../../../helpers/actionBuilder.helpers';

import {
  ACTION_DEFINITION_FIELD_IDS,
  SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS,
  SEND_EMAILS_ADDRESSEE_TYPE,
  ACTION_DEFINITION_ACTION_TYPES,
} from '../../../../../../constants/actionBuilder.constants';
import {
  FIELD_IDS,
  ACTION_TYPES,
  EMAIL_TEMPLATE_OPERATOR_OPTIONS,
  EMAIL_TEMPLATE_OPERATOR_VALUE,
  TOOLBAR_GROUPS,
  TOOLBAR_STYLES,
} from './sendEmailModalContentRenderer.constants';

import styles from './sendEmailModalContentRenderer.module.scss';

const MAX_TO_LIMIT = 3;

const SendEmailModalContentRenderer = ({
  isDataLoading,
  isEmailPreviewEnabled,
  isEmailResolving,
  derivedEntityLookupFieldName,
  selectedTemplateResolvedSubject,
  selectedTemplateResolvedBody,
  values,
  emailTemplateDefStore,
  selectedActionDef,
  mapOfVariableToEntityNameForStatic,
  mapOfVariableToEntityNameForDerived,
  conditionBuilderFieldDefinitionObject,
  derivedEntityRecordDataById,
  errors,
  emailTemplateOptions,
  onAction,
}) => {
  const fromValue = values[FIELD_IDS.FROM];
  const toValue = values[FIELD_IDS.TO];
  const editorValue = values[FIELD_IDS.EDITOR_ID];
  const selectedTemplate = values[FIELD_IDS.TEMPLATES];
  const mapOfVariableValues = _get(values, FIELD_IDS.MAP_OF_VARIABLES, EMPTY_OBJECT);
  const previewState = values[FIELD_IDS.PREVIEW];
  const addresseeValue = values[FIELD_IDS.ADDRESSEE_VALUE];
  const selectedTemplateId = getArraySafeValue(selectedTemplate);
  const selectedTemplateDef = emailTemplateDefStore[selectedTemplateId];
  const selectedActionType = _get(selectedActionDef, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE);
  const sendEmailsType = _get(selectedActionDef, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.ADDRESSEE_TYPE);
  const sourceEntityName = _get(selectedActionDef, 'entityName');

  const { selectedTemplateEmptyVariables, selectedTemplateDefinedVariables } = useMemo(
    () => getTemplateVariables(selectedTemplateDef, selectedTemplateId, selectedActionDef),
    [selectedTemplateDef, selectedTemplateId, selectedActionDef],
  );

  const toOptions = useMemo(
    () => getStaticToFieldSelectProps(conditionBuilderFieldDefinitionObject[sourceEntityName], toValue),
    [toValue, sourceEntityName, conditionBuilderFieldDefinitionObject],
  );

  const handleAction = useCallback(
    (action) => {
      const { type, payload } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          let { id: fieldId } = payload;
          const { value: fieldValue } = payload;
          let updatedValue = {};

          if (_includes(fieldId, '^')) {
            const [id, variableName] = _split(fieldId, '^', 2);
            fieldId = id;
            updatedValue = { ...mapOfVariableValues, [variableName]: fieldValue };
          } else {
            updatedValue = fieldValue;
          }

          onAction({
            type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
            payload: { id: fieldId, value: updatedValue },
          });
          break;
        }

        default: {
          onAction(action);
          break;
        }
      }
    },
    [mapOfVariableValues, onAction],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT });
  }, [onAction]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_SELECTED_TEMPLATE_CHANGE, payload: { selectedTemplateDef } });
  }, [isEmailPreviewEnabled, selectedTemplateDef, onAction]);

  const renderRichTextEditor = useMemo(
    () => (
      <PropertyControlledComponent controllerProperty={!_isEmpty(selectedTemplateDef)}>
        <RichTextEditor
          editorId={selectedTemplateId}
          id={FIELD_IDS.EDITOR_ID}
          value={editorValue}
          toolbarGroups={_values(TOOLBAR_GROUPS)}
          toolbarStyles={TOOLBAR_STYLES}
          className={styles.richTextEditorWrapper}
          onAction={onAction}
        />
      </PropertyControlledComponent>
    ),
    [editorValue, onAction, selectedTemplateDef, selectedTemplateId],
  );

  if (isDataLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.flexRow}>
      <div style={isEmailPreviewEnabled ? { width: '50%' } : { width: '100%' }}>
        <TextInput id={FIELD_IDS.FROM} disabled label={__('From')} fieldClassName={fieldLayoutStyles.fieldC} value={fromValue} onAction={onAction} />
        <div className={fieldLayoutStyles.fieldC}>
          {sendEmailsType === SEND_EMAILS_ADDRESSEE_TYPE.DERIVED ? (
            <TagsWithLimit label={__('To')} values={toValue} maxLimit={MAX_TO_LIMIT} />
          ) : (
            <Select
              required
              mode="tags"
              id={FIELD_IDS.TO}
              label={__('To')}
              fieldClassName={fieldLayoutStyles.fieldC}
              className={styles.customSelect}
              value={toValue}
              options={toOptions}
              onAction={handleAction}
            />
          )}
        </div>
        <Select
          id={FIELD_IDS.TEMPLATES}
          required
          validators={[isRequiredRule]}
          label={__('Email Template')}
          fieldClassName={fieldLayoutStyles.fieldC}
          value={selectedTemplateId}
          errors={errors}
          options={emailTemplateOptions}
          onAction={onAction}
        />
        <div className={styles.templateVariableContainer}>
          {_map(selectedTemplateEmptyVariables, (variable, subIndex) => (
            <div className={styles.flexRow} key={subIndex}>
              <TextInput disabled label={__('Field')} value={variable} fieldClassName={fieldLayoutStyles.fieldC} />
              <SelectInput
                isDisabled
                label={__('Operator')}
                options={EMAIL_TEMPLATE_OPERATOR_OPTIONS}
                value={EMAIL_TEMPLATE_OPERATOR_VALUE}
                fieldClassName={fieldLayoutStyles.fieldC}
              />
              <TextInput
                required
                label={__('Value')}
                id={`${FIELD_IDS.MAP_OF_VARIABLES}^${variable}`}
                validators={[isRequiredRule]}
                value={mapOfVariableValues[variable]}
                error={_get(errors, variable, EMPTY_STRING)}
                fieldClassName={fieldLayoutStyles.fieldC}
                onAction={handleAction}
              />
            </div>
          ))}
          {_map(selectedTemplateDefinedVariables, (value, key) => (
            <div className={styles.flexRow} key={key}>
              <TextInput disabled label={__('Field')} value={key} fieldClassName={fieldLayoutStyles.fieldC} />
              <SelectInput
                isDisabled
                label={__('Operator')}
                options={EMAIL_TEMPLATE_OPERATOR_OPTIONS}
                value={EMAIL_TEMPLATE_OPERATOR_VALUE}
                fieldClassName={fieldLayoutStyles.fieldC}
              />
              {selectedActionType === ACTION_DEFINITION_ACTION_TYPES.SEND_EMAILS && sendEmailsType === SEND_EMAILS_ADDRESSEE_TYPE.DERIVED ? (
                <ValueField
                  required
                  isDisabled
                  showFieldSwitcher
                  isRelationshipRecursive={false}
                  isComplexRecursive={false}
                  label={__('Value')}
                  fieldClassName={fieldLayoutStyles.fieldC}
                  value={value}
                  mapOfVariableToEntityName={mapOfVariableToEntityNameForDerived}
                  conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
                  onAction={handleAction}
                />
              ) : (
                <ValueField
                  required
                  isDisabled
                  showFieldSwitcher
                  isRelationshipRecursive={false}
                  isComplexRecursive={false}
                  label={__('Value')}
                  fieldClassName={fieldLayoutStyles.fieldC}
                  value={value}
                  mapOfVariableToEntityName={mapOfVariableToEntityNameForStatic}
                  conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
                  onAction={handleAction}
                />
              )}
            </div>
          ))}
        </div>
        {renderRichTextEditor}
      </div>
      {isEmailPreviewEnabled && (
        <div className={styles.emailPreviewContainer}>
          <EmailPreviewRenderer
            isEmailResolving={isEmailResolving}
            switchId={FIELD_IDS.PREVIEW}
            addresseeId={FIELD_IDS.ADDRESSEE_VALUE}
            switchValue={previewState}
            derivedEntityLookupFieldName={derivedEntityLookupFieldName}
            sendEmailsType={sendEmailsType}
            emailTemplateDef={selectedTemplateDef}
            selectedActionDef={selectedActionDef}
            resolvedEmailSubject={selectedTemplateResolvedSubject}
            resolvedEmailBody={selectedTemplateResolvedBody}
            derivedEntityRecordDataById={derivedEntityRecordDataById}
            fromValue={fromValue}
            toValue={toValue}
            addresseeValue={addresseeValue}
            onAction={onAction}
          />
        </div>
      )}
    </div>
  );
};

SendEmailModalContentRenderer.propTypes = {
  isDataLoading: PropTypes.bool,
  isEmailPreviewEnabled: PropTypes.bool,
  isEmailResolving: PropTypes.bool,
  selectedTemplateResolvedSubject: PropTypes.string,
  selectedTemplateResolvedBody: PropTypes.string,
  derivedEntityLookupFieldName: PropTypes.string,
  values: PropTypes.object,
  emailTemplateDefStore: PropTypes.object,
  selectedActionDef: PropTypes.object,
  mapOfVariableToEntityNameForStatic: PropTypes.object,
  mapOfVariableToEntityNameForDerived: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  derivedEntityRecordDataById: PropTypes.object,
  errors: PropTypes.object,
  emailTemplateOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

SendEmailModalContentRenderer.defaultProps = {
  isDataLoading: false,
  isEmailPreviewEnabled: false,
  isEmailResolving: false,
  selectedTemplateResolvedSubject: EMPTY_STRING,
  selectedTemplateResolvedBody: EMPTY_STRING,
  derivedEntityLookupFieldName: EMPTY_STRING,
  values: EMPTY_OBJECT,
  emailTemplateDefStore: EMPTY_OBJECT,
  selectedActionDef: EMPTY_OBJECT,
  mapOfVariableToEntityNameForStatic: EMPTY_OBJECT,
  mapOfVariableToEntityNameForDerived: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  derivedEntityRecordDataById: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  emailTemplateOptions: EMPTY_ARRAY,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(SendEmailModalContentRenderer);
