import ACTION_TYPES from '../../../constants/searchOptionsConfigurator.actionTypes';

const ROW_ACTION_PROPS = {
  visibleActionCount: 1,
};

const REMOVE_ACTION = {
  id: ACTION_TYPES.TABLE_REMOVE_ROW,
  name: __('Remove row'),
  icon: 'icon-trash',
};

const EDIT_ACTION = {
  id: 'EDIT_ROW',
  name: __('Edit'),
  icon: 'icon-edit',
};

const TABLE_ACTION_TYPES = {
  ON_CHANGE: 'ON_CHANGE',
  TABLE_ACTION_CLICK: 'TABLE_ACTION_CLICK',
};

const COLUMN_IDS = {
  FIELD: 'field',
  BOOST: 'boost',
};

export { ROW_ACTION_PROPS, REMOVE_ACTION, EDIT_ACTION, TABLE_ACTION_TYPES, COLUMN_IDS };
