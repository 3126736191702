import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';

import Button from '@tekion/tekion-components/atoms/Button';
import Modal from '@tekion/tekion-components/molecules/Modal';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import withActions from '@tekion/tekion-components/connectors/withActions';

import ConfigTab from './components/configTab/ConfigTab';
import { TAB_PROPERTIES_CONFIGURE_FIELDS } from './helpers/tabPropertiesConfigurator.fields';
import { TAB_PROPERTIES_CONFIGURE_SECTIONS } from './helpers/tabPropertiesConfigurator.sections';
import ACTION_HANDLERS from './helpers/tabPropertiesConfigurator.actionHandlers';

import ACTION_TYPES from './constants/tabPropertiesConfigurator.actionTypes';
import { TAB_PROPERTIES_CONFIGURE_FIELD_IDS } from './constants/tabPropertiesConfigurator.fieldIds';

import styles from './tabPropertiesConfigurator.module.scss';

const TabPropertiesConfigure = ({
  isTabConfigureModalVisible,
  formFormattedSelectedWidgetProperties,
  selectedWidgetConfig,
  tabContentDetails,
  tabContentChildren,
  onAction,
}) => {
  const handleSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_SAVE_MODAL });
  }, [onAction]);

  const handleCancelModal = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CANCEL_MODAL,
    });
  }, [onAction]);

  const handleConfigureClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CLICK_CONFIGURE_TAB,
    });
  }, [onAction]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE,
      payload: { selectedWidgetConfig },
    });
  }, [onAction, selectedWidgetConfig]);

  return (
    <>
      <FormBuilder
        sections={TAB_PROPERTIES_CONFIGURE_SECTIONS}
        fields={TAB_PROPERTIES_CONFIGURE_FIELDS}
        values={formFormattedSelectedWidgetProperties}
        onAction={onAction}
      />
      <div className={styles.tabConfigureContainer}>
        <Button disable view={Button.VIEW.TERTIARY} className={styles.tabConfigureButton} onClick={handleConfigureClick}>
          {__('Configure Tabs')}
        </Button>
      </div>

      <Modal
        visible={isTabConfigureModalVisible}
        width={SIZES.S}
        bodyStyle={{ padding: 0 }}
        title={__('Configure Tabs')}
        submitBtnText={__('Submit')}
        onSubmit={handleSubmit}
        onCancel={handleCancelModal}
      >
        <ConfigTab
          id={TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_NAMES}
          tabContentDetails={tabContentDetails}
          tabContentChildren={tabContentChildren}
          onAction={onAction}
        />
      </Modal>
    </>
  );
};

TabPropertiesConfigure.propTypes = {
  isTabConfigureModalVisible: PropTypes.bool,
  selectedWidgetConfig: PropTypes.object,
  formFormattedSelectedWidgetProperties: PropTypes.object,
  tabContentDetails: PropTypes.array,
  tabContentChildren: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

TabPropertiesConfigure.defaultProps = {
  isTabConfigureModalVisible: false,
  formFormattedSelectedWidgetProperties: PropTypes.object,
  tabContentDetails: [{}],
  tabContentChildren: EMPTY_ARRAY,
  selectedWidgetConfig: undefined,
};

export default withActions({}, ACTION_HANDLERS)(TabPropertiesConfigure);
