import _get from 'lodash/get';

import { getNthChildOfFieldName, getSimpleFieldNameFromColumn } from '../../../../utils';

import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

const getFieldDefProperties = (fieldName, fieldDefinitionByName) => {
  const firstFieldName = getSimpleFieldNameFromColumn(fieldName);
  const fieldDefForFirstFieldName = _get(fieldDefinitionByName, firstFieldName);
  const isFieldComplex = fieldDefinitionReader.dataType(fieldDefForFirstFieldName) === DATA_TYPES.COMPLEX;
  const isRelationshipField = fieldDefinitionReader.fieldType(fieldDefForFirstFieldName) === FIELD_TYPES.RELATIONSHIP;
  let isMultiValued = fieldDefinitionReader.multiValued(fieldDefForFirstFieldName);
  let isRichTextEditorField = fieldDefinitionReader.fieldType(fieldDefForFirstFieldName) === FIELD_TYPES.RICH_TEXT_EDITOR;
  let isMediaField = fieldDefinitionReader.fieldType(fieldDefForFirstFieldName) === FIELD_TYPES.MEDIA;

  if (isFieldComplex) {
    const complexEntityFieldDefinitions = fieldDefinitionReader.complexEntityFieldDefinitions(fieldDefForFirstFieldName);
    const secondFieldName = getNthChildOfFieldName(fieldName, 1);
    const fieldDefForSecondFieldName = _get(complexEntityFieldDefinitions, secondFieldName);
    isMultiValued = fieldDefinitionReader.multiValued(fieldDefForSecondFieldName);
    isRichTextEditorField = fieldDefinitionReader.fieldType(fieldDefForSecondFieldName) === FIELD_TYPES.RICH_TEXT_EDITOR;
    isMediaField = fieldDefinitionReader.fieldType(fieldDefForSecondFieldName) === FIELD_TYPES.MEDIA;
  }

  if (isRelationshipField) {
    const relationshipEntityFieldDefinitions = fieldDefinitionReader.relationshipEntityFieldDefinitions(fieldDefForFirstFieldName);
    const secondFieldName = getNthChildOfFieldName(fieldName, 1);
    const fieldDefForSecondFieldName = _get(relationshipEntityFieldDefinitions, secondFieldName);
    isMultiValued = fieldDefinitionReader.multiValued(fieldDefForSecondFieldName);
    isRichTextEditorField = fieldDefinitionReader.fieldType(fieldDefForSecondFieldName) === FIELD_TYPES.RICH_TEXT_EDITOR;
    isMediaField = fieldDefinitionReader.fieldType(fieldDefForSecondFieldName) === FIELD_TYPES.MEDIA;
  }

  return { isFieldComplex, isMultiValued, isRichTextEditorField, isMediaField, isRelationshipField };
};

export { getFieldDefProperties };
