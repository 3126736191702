import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import _map from 'lodash/map';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import Collapse from '@tekion/tekion-components/molecules/Collapse';
import Heading from '@tekion/tekion-components/atoms/Heading';

import Stage from './components/stage/Stage';

import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../../../../constants/approvalCentre.constants';
import { REQUEST_DETAILS_PAGE_PANEL_KEYS } from '../../constants/approvalRequestDetailsPage.general.constants';

import styles from './approvalStagesDetailView.module.scss';

const StagesDetailView = ({ className, approvalProcess, approvalTasks }) => {
  const stagesData = useMemo(() => {
    const stages = tget(approvalProcess, APPROVAL_CENTRE_FIELD_IDS.STAGES, []);
    const stagesWithTaskDetails = _map(stages, (stage, index) => {
      const taskForStage = _find(approvalTasks, { [APPROVAL_CENTRE_FIELD_IDS.APPROVAL_LEVEL]: index });

      return {
        ...stage,
        [APPROVAL_CENTRE_FIELD_IDS.TASK]: taskForStage,
      };
    });

    return stagesWithTaskDetails;
  }, [approvalProcess, approvalTasks]);

  return (
    <div className={className}>
      <Collapse
        collapseHeaderClassName={styles.panelHeader}
        bodyClassName={styles.collapseBody}
        defaultActiveKey={[REQUEST_DETAILS_PAGE_PANEL_KEYS.STAGE_ACTIVITY]}
      >
        <Collapse.Panel
          panelKey={REQUEST_DETAILS_PAGE_PANEL_KEYS.STAGE_ACTIVITY}
          header={
            <Heading className="p-l-4" size={3}>
              {__('Stage Activity')}
            </Heading>
          }
        >
          {_map(stagesData, (stage, stageIndex) => (
            <Stage key={stageIndex} index={stageIndex} stage={stage} />
          ))}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

StagesDetailView.propTypes = {
  className: PropTypes.string,
  approvalProcess: PropTypes.object.isRequired,
  approvalTasks: PropTypes.array,
};

StagesDetailView.defaultProps = {
  className: EMPTY_STRING,
  approvalTasks: EMPTY_ARRAY,
};

export default StagesDetailView;
