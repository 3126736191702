const VARIABLE_TYPES = {
  ENTITY_RECORD: 'RECORD',
  ENTITY_RECORD_LIST: 'LIST_OF_RECORDS',
  PRIMITIVE: 'PRIMITIVE',
};

const ASSIGNED_VARIABLE_FIELD_IDS = {
  VARIABLE_NAME: 'variableName',
  ENTITY_NAME: 'entityName',
  VARIABLE_DATA_TYPE: 'dataType',
  DECLARED_AT_NODE_ID: 'declaredAtNodeId',
  USED_AT_NODE_IDS: 'usedAtNodeIds',
  ASSIGNED_AT_NODE_IDS: 'assignedAtNodeIds',
  VARIABLE_TYPE: 'type',
  VARIABLE_FIELD_TYPE: 'fieldType',
  MULTI_VALUED: 'multiValued',
  FIELD_DEFINITION_MINIMAL: 'fieldDefinitionMinimal',
};

export { VARIABLE_TYPES, ASSIGNED_VARIABLE_FIELD_IDS };
