import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import Heading from '@tekion/tekion-components/atoms/Heading';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Button from '@tekion/tekion-components/atoms/Button';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';

import TagsRenderer from '../../atoms/tagsRenderer/TagsRenderer';
import EventHandlerModal from './components/eventHandlerModal/EventHandlerModal';

import ACTION_HANDLERS from './helpers/eventHandlerConfigurator.actionHandlers';
import { getSelectedEventHandlers } from './helpers/eventHandlerConfigurator.helper';

import ACTION_TYPES from './constants/eventHandlerConfigurator.actionTypes';
import { EVENT_CONFIGURATOR_FORM_CONTEXT_ID } from './constants/eventHandlerConfigurator.constants';

import styles from './eventHandler.module.scss';

const EventHandlerConfigurator = ({
  isModalVisible,
  viewType,
  componentType,
  currentFieldTitle,
  selectedEventType,
  selectedModuleIndex,
  allFormValues,
  entity,
  selectedViewComponentProperties,
  errors,
  onAction,
}) => {
  const handleSubmit = useCallback(() => {
    if (!_isEmpty(allFormValues)) {
      triggerSubmit(EVENT_CONFIGURATOR_FORM_CONTEXT_ID, { actionType: ACTION_TYPES.ON_SAVE_MODAL });
    } else {
      onAction({
        type: ACTION_TYPES.ON_SAVE_MODAL,
      });
    }
  }, [allFormValues, onAction]);

  const handleCancelModal = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CANCEL_MODAL,
    });
  }, [onAction]);

  const handleConfigureClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CLICK_CONFIGURE_EVENT,
    });
  }, [onAction]);

  const { selectedEventHandlerTitles } = useMemo(() => getSelectedEventHandlers(allFormValues), [allFormValues]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE,
      payload: { selectedViewComponentProperties },
    });
  }, [onAction, selectedViewComponentProperties]);

  return (
    <div className={styles.eventHandlerComponent}>
      <Heading size={5} className={styles.eventComponentHeading}>
        {__('Event Handlers')}
      </Heading>
      <div className={styles.eventComponent}>
        <div className={styles.addedEventContainer}>
          <div className={styles.addedEventSection}>
            {__('Added Events ')}({_size(selectedEventHandlerTitles)})
            <PropertyControlledComponent controllerProperty={_size(selectedEventHandlerTitles) > 0}>
              <TagsRenderer fieldValue={selectedEventHandlerTitles} maxLimit={2} tagClassName={styles.tag} tagContentClassName={styles.tagContent} />
            </PropertyControlledComponent>
          </div>
        </div>
        <Button disable view={Button.VIEW.TERTIARY} className={styles.configureButton} onClick={handleConfigureClick}>
          {__('Configure')}
        </Button>
      </div>
      <Modal
        visible={isModalVisible}
        width={SIZES.XL}
        bodyStyle={{ padding: 0 }}
        title={__(`Event Handlers for ${currentFieldTitle}`)}
        submitBtnText={__('Submit')}
        onSubmit={handleSubmit}
        onCancel={handleCancelModal}
      >
        <EventHandlerModal
          viewType={viewType}
          componentType={componentType}
          selectedEventType={selectedEventType}
          selectedModuleIndex={selectedModuleIndex}
          entity={entity}
          allFormValues={allFormValues}
          errors={errors}
          onAction={onAction}
        />
      </Modal>
    </div>
  );
};

EventHandlerConfigurator.propTypes = {
  isModalVisible: PropTypes.bool,
  currentFieldTitle: PropTypes.string,
  viewType: PropTypes.string,
  componentType: PropTypes.string,
  selectedEventType: PropTypes.string,
  selectedModuleIndex: PropTypes.number,
  selectedViewComponentProperties: PropTypes.object,
  entity: PropTypes.object,
  allFormValues: PropTypes.array,
  errors: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

EventHandlerConfigurator.defaultProps = {
  isModalVisible: false,
  selectedModuleIndex: undefined,
  currentFieldTitle: EMPTY_STRING,
  viewType: undefined,
  componentType: undefined,
  selectedEventType: undefined,
  selectedViewComponentProperties: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  allFormValues: EMPTY_ARRAY,
  errors: EMPTY_ARRAY,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(EventHandlerConfigurator);
