import ApiService from './apiService/apiService';

const BASE_URL = '/core/u';

const fetchRecordAccess = (payload) => ApiService.post(`${BASE_URL}/cas/record-access/fetch`, payload);

const fetchEntityAccessForUsers = (entityName, recordTypeId, userIds) =>
  ApiService.post(`${BASE_URL}/governance/permissions/entity-name/${entityName}/record-type/${recordTypeId}/fetch`, userIds);

const shareRecord = (payload) => ApiService.post(`${BASE_URL}/governance/record-sharing-rules/bulk`, payload);

const fetchSharingDataForRecord = (entityName, recordId) => ApiService.get(`${BASE_URL}/governance/record-sharing-rules/${entityName}`, { recordId });

const deleteSharedRecordForUser = (entityName, recordId, userId) =>
  ApiService.delete(`${BASE_URL}/governance/record-sharing-rules/${entityName}`, {}, { recordId, userId });

export default { fetchRecordAccess, fetchEntityAccessForUsers, shareRecord, fetchSharingDataForRecord, deleteSharedRecordForUser };
