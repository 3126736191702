import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _keyBy from 'lodash/keyBy';

import TASK_TYPES from '@tekion/tekion-rule-engine/src/constants/ruleEngineWorkflow.taskTypes';
import { getNewNode, getNodeConfig } from '@tekion/tekion-rule-engine';

const mapData = (ruleData) => {
  if (_isEmpty(_get(ruleData, 'nodeList'))) return ruleData;
  const eventId = _get(ruleData, 'eventType', '');
  // we store event ID in eventType for a given rule.
  // a root trigger is also a step/node which gone through a set of validations before
  // adding or removing step below it along with other nodes [actions/workflows/conditions]
  // thus keeping id in entityDefId for triggers.
  const label = _get(ruleData, 'uiMetadata.eventLabel', eventId);
  const triggerData = getNewNode({
    taskType: TASK_TYPES.EVENT,
    nextNodeId: ruleData.rootNodeId,
    label,
    uiMetadata: {
      taskType: TASK_TYPES.EVENT,
      entityDefId: eventId,
    },
  });
  const rootNode = _get(triggerData, 'nodeId');
  const nodeList = _reduce(_get(ruleData, 'nodeList', {}), (acc, current) => ({ ...acc, [current.nodeId]: current }), {
    [rootNode]: triggerData,
  });

  const modifiedNodeList = getNodeConfig(nodeList, rootNode, null);

  return {
    ...ruleData,
    nodeList: _keyBy(modifiedNodeList.nodeList, 'nodeId'),
    emptyChildCount: modifiedNodeList.emptyChild,
    rootNodeId: rootNode,
  };
};

export default mapData;
