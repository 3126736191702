exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".approvalRequestDetails_row__ff7zRzqSg2{display:flex;justify-content:flex-start;align-items:center}[dir] .approvalRequestDetails_container__koq2orbCmQ{margin:1.6rem 1.6rem 0;border-bottom:.1rem solid #d4d5d6}.approvalRequestDetails_panelHeader__3jbCaWPLGG{align-items:center;height:5.4rem}[dir] .approvalRequestDetails_panelHeader__3jbCaWPLGG{background-color:#fff}[dir] .approvalRequestDetails_collapseBody__8kxC2qXcfC{padding-top:0 !important;border:none !important}", ""]);

// Exports
exports.locals = {
	"row": "approvalRequestDetails_row__ff7zRzqSg2",
	"container": "approvalRequestDetails_container__koq2orbCmQ",
	"panelHeader": "approvalRequestDetails_panelHeader__3jbCaWPLGG",
	"collapseBody": "approvalRequestDetails_collapseBody__8kxC2qXcfC"
};