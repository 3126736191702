import React, { useCallback, useEffect, useMemo } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading/Heading';
import Spinner from '@tekion/tekion-components/molecules/SpinnerComponent';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';
import withSize from '../../../../connectors/withSize';

import ACTION_HANDLERS from './helpers/exportForm.actionHandlers';
import getFields from './helpers/exportForm.fields';
import exportFormSections from './helpers/exportForm.sections';

import { BUNDLE_STATE } from '../exportList/constants/exportList.general.constants';
import { INITIAL_STATE, EXPORT_FORM_CONTEXT_ID } from './constants/exportForm.general.constants';
import { FORM_MODES } from '../../../../constants/general.constants';
import ACTION_TYPES from './constants/exportForm.actionTypes';

import styles from './exportForm.module.scss';

const ExportForm = ({ isDataLoading, isSaveLoading, contentHeight, bundleState, exportData, errors, match, onAction }) => {
  const bundleId = tget(match, 'params.bundleId', '');
  const formMode = _isEmpty(bundleId) ? FORM_MODES.CREATE : FORM_MODES.EDIT;
  const getFormHeader = formMode === FORM_MODES.CREATE ? __('Create New Bundle') : __('Edit Bundle');
  const fields = useMemo(() => getFields(formMode, bundleState), [formMode, bundleState]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(EXPORT_FORM_CONTEXT_ID);
  }, []);

  const handleRedirect = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_REDIRECTION,
    });
  }, [onAction]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_MOUNT });
  }, [onAction]);

  if (isDataLoading) {
    return (
      <div className={styles.spinnerWrap}>
        <Spinner size="medium" />
      </div>
    );
  }

  return (
    <Page>
      <Page.Header hasBack goBackHandler={handleRedirect}>
        <Heading className="full-width">{getFormHeader}</Heading>
      </Page.Header>

      <Page.Body style={{ height: contentHeight }}>
        <FormWithSubmission
          contextId={EXPORT_FORM_CONTEXT_ID}
          fields={fields}
          sections={exportFormSections()}
          errors={errors}
          values={exportData}
          onAction={onAction}
        />
      </Page.Body>

      <Page.Footer>
        <PropertyControlledComponent controllerProperty={bundleState !== BUNDLE_STATE.PUBLISHED}>
          <SaveComponent
            primaryActionLoading={isSaveLoading}
            primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Create') : __('Update')}
            onPrimaryAction={handleSubmit}
            onSecondaryAction={handleRedirect}
          />
        </PropertyControlledComponent>
      </Page.Footer>
    </Page>
  );
};

ExportForm.propTypes = {
  isDataLoading: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  bundleState: PropTypes.string,
  exportData: PropTypes.object,
  errors: PropTypes.object,
  match: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
};

ExportForm.defaultProps = {
  isDataLoading: false,
  isSaveLoading: false,
  bundleState: EMPTY_STRING,
  exportData: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default compose(withSize({ hasPageFooter: 1, hasPageHeader: 1 }), withActions(INITIAL_STATE, ACTION_HANDLERS))(ExportForm);
