import { compose } from 'recompose';

import _property from 'lodash/property';
import _get from 'lodash/get';
import _nthArg from 'lodash/nthArg';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';

// Components
import ColumnConfig from '@tekion/tekion-components/organisms/inputTable/builders/ColumnConfig';
import CellConfig from '@tekion/tekion-components/organisms/inputTable/builders/CellConfig';
import SelectInputCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/SelectInputCellRenderer';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

// Constants
import { COLUMN_IDS, SORT_ORDER_FIELD_OPTIONS } from '../constants/sortFieldsTable.general';

const COLUMN_WIDTHS = {
  [COLUMN_IDS.SORT_FIELD]: '26rem',
  [COLUMN_IDS.SORT_ORDER]: '26rem',
};

const sortFieldAccessor = _property(COLUMN_IDS.SORT_FIELD);
const sortOrderAccessor = _property(COLUMN_IDS.SORT_ORDER);

const onOptionChange = compose(_property('value'), _nthArg(1));

const getSortFieldOptions = (options, selectedOptions = EMPTY_ARRAY) =>
  _map(options, (field) => ({
    label: _get(field, 'label', ''),
    value: _get(field, 'value', ''),
    isDisabled: _includes(selectedOptions, _get(field, 'value')),
  }));

const mapSortFieldProps = (props, options, selectedOptions, isOptionDisabled) => {
  const value = _get(props, `rowData.${COLUMN_IDS.SORT_FIELD}`);
  return {
    options: getSortFieldOptions(options, selectedOptions),
    isDisabled: isOptionDisabled,
    error: _isEmpty(value) && !isOptionDisabled ? __('Please Select Sort Field') : '',
  };
};

const mapSortOrderProps = () => ({
  options: SORT_ORDER_FIELD_OPTIONS,
});

const getSortFieldCell = (options, selectedOptions, isOptionDisabled) =>
  new CellConfig()
    .setComponent(SelectInputCellRenderer)
    .setMapCellPropsToComponentProps((props) => mapSortFieldProps(props, options, selectedOptions, isOptionDisabled));

const getSortOrderCell = () =>
  new CellConfig().setComponent(SelectInputCellRenderer).setMapCellPropsToComponentProps((props) => mapSortOrderProps(props));

const getSortFieldColumnConfig = (sortableFieldOptions = EMPTY_ARRAY, selectedOptions = EMPTY_ARRAY, isOptionDisabled = false) =>
  new ColumnConfig(COLUMN_IDS.SORT_FIELD)
    .setHeader(__('Field'))
    .setAccessor(sortFieldAccessor)
    .addCellConfig(getSortFieldCell(sortableFieldOptions, selectedOptions, isOptionDisabled))
    .setGetValueFromOnChange(onOptionChange)
    .setWidth(COLUMN_WIDTHS[COLUMN_IDS.SORT_FIELD]);

const getSortOrderColumnConfig = () =>
  new ColumnConfig(COLUMN_IDS.SORT_ORDER)
    .setHeader(__('Order'))
    .setAccessor(sortOrderAccessor)
    .addCellConfig(getSortOrderCell())
    .setGetValueFromOnChange(onOptionChange)
    .setWidth(COLUMN_WIDTHS[COLUMN_IDS.SORT_ORDER]);

const getColumns = (sortableFieldOptions, selectedSortOptions) => {
  const SORT_FIELD_COLUMN_CONFIG = getSortFieldColumnConfig(sortableFieldOptions, selectedSortOptions);
  const SORT_ORDER_COLUMN_CONFIG = getSortOrderColumnConfig();

  return [SORT_FIELD_COLUMN_CONFIG, SORT_ORDER_COLUMN_CONFIG];
};

export { getSortFieldColumnConfig, getSortOrderColumnConfig, getColumns };
