import _map from 'lodash/map';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _last from 'lodash/last';
import _split from 'lodash/split';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _compact from 'lodash/compact';

import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { tget } from '@tekion/tekion-base/utils/general';
import RelationshipField from '../../../../atoms/relationshipField/RelationshipField';

import withAsyncSelectFilterWrapper from '../../../../connectors/withAsyncSelectFilterWrapper/withAsyncSelectFilterWrapper';
import { getOptions } from './listViewRenderer.helpers';
import { getSimpleFieldNameFromColumn, getNthChildOfFieldName } from '../../../../utils';

import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';
import { EXISTENCE_CHECK_FIELDS } from './listViewRenderer.constants';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';

import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

import styles from './listViewRenderer.module.scss';

const getFilterForSelect = (filter, options) => ({
  ...filter,
  type: FILTER_TYPES.MULTI_SELECT,
  additional: {
    options: _map(options, (option) => ({
      label: _get(option, 'displayName'),
      value: _get(option, 'value'),
      additional: {
        ...option,
      },
    })),
  },
});

const getFilterForSimpleColumn = (fieldName, displayName, fieldDef) => {
  const fieldType = fieldDefinitionReader.fieldType(fieldDef);
  const dataType = fieldDefinitionReader.dataType(fieldDef);
  const options = fieldDefinitionReader.options(fieldDef);

  const filter = {
    id: fieldName,
    name: displayName,
    type: FILTER_TYPES.STRING,
  };

  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      switch (dataType) {
        case DATA_TYPES.DATE:
        case DATA_TYPES.DATE_TIME:
          return { ...filter, type: FILTER_TYPES.DATE };
        case DATA_TYPES.NUMBER:
          return { ...filter, type: FILTER_TYPES.NUMBER };
        case DATA_TYPES.COMPLEX: {
          const complexField = getNthChildOfFieldName(fieldName, 1);
          const complexEntityFieldDefinitions = fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef) || {};
          const complexFieldDef = _get(complexEntityFieldDefinitions, [complexField], {});
          const complexFieldFilter = getFilterForSimpleColumn(complexField, displayName, complexFieldDef);
          return { ...complexFieldFilter, id: fieldName };
        }
        case DATA_TYPES.TEXT:
          return {
            ...filter,
            additional: {
              operators: [
                { name: OPERATORS.IN, id: OPERATORS.IN },
                { name: OPERATORS.NIN, id: OPERATORS.NIN },
              ],
            },
          };
        default:
          return filter;
      }

    case FIELD_TYPES.CHILD_AGGREGATE_SUMMARY:
      switch (dataType) {
        case DATA_TYPES.NUMBER:
          return { ...filter, type: FILTER_TYPES.NUMBER };
        default:
          return filter;
      }

    case FIELD_TYPES.SELECT:
      return getFilterForSelect(filter, options);

    case FIELD_TYPES.RELATIONSHIP: {
      const lookUpField = _last(_split(fieldName, '.'));
      const lookupFieldName = fieldDefinitionReader.lookupField(fieldDef);

      const relationshipEntityFieldDefinitions = fieldDefinitionReader.relationshipEntityFieldDefinitions(fieldDef) || {};
      const lookUpFieldDef = _get(relationshipEntityFieldDefinitions, [lookUpField], {});
      const lookUpFilter = getFilterForSimpleColumn(lookUpField, displayName, lookUpFieldDef);
      const entityType = fieldDefinitionReader.lookupFieldEntityType(fieldDef);

      return {
        ...lookUpFilter,
        id: fieldName,
        type: FILTER_TYPES.CUSTOM,
        key: fieldName,
        additional: {
          valueKey: 'value',
          component: withAsyncSelectFilterWrapper(RelationshipField),
          operators: [
            { name: OPERATORS.IN, id: OPERATORS.IN },
            { name: OPERATORS.NIN, id: OPERATORS.NIN },
          ],
          renderProps: {
            required: true,
            isMulti: true,
            id: fieldName,
            lookUpEntityName: entityType,
            lookUpDisplayName: lookUpField,
            lookUpFieldName: lookupFieldName,
            fieldClassName: styles.asyncPaginatedSelect,
            getOptions: getOptions(lookUpFieldDef),
          },
        },
      };
    }
    default:
      return filter;
  }
};

const getFilters = (filterMetadata, fieldDefinitionByName) =>
  _map(filterMetadata, (filterConfig) => {
    const fieldName = _get(filterConfig, 'fieldName');
    const fieldDef = _get(fieldDefinitionByName, getSimpleFieldNameFromColumn(fieldName), {});
    const fieldType = fieldDefinitionReader.fieldType(fieldDef);
    const dataType = fieldDefinitionReader.dataType(fieldDef);
    let displayName = fieldDefinitionReader.displayName(fieldDef);

    if (fieldType === FIELD_TYPES.RELATIONSHIP || dataType === DATA_TYPES.COMPLEX) {
      const relatedEntityField = getNthChildOfFieldName(fieldName, 1);
      let entityFieldDefinitions = {};

      if (fieldType === FIELD_TYPES.RELATIONSHIP) {
        entityFieldDefinitions = fieldDefinitionReader.relationshipEntityFieldDefinitions(fieldDef) || {};
      } else {
        entityFieldDefinitions = fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef) || {};
      }

      const relatedEntityFieldDef = _get(entityFieldDefinitions, [relatedEntityField], {});
      const relatedFieldDisplayName = fieldDefinitionReader.displayName(relatedEntityFieldDef);
      displayName = `${displayName} - ${relatedFieldDisplayName}`;
    }

    const filter = getFilterForSimpleColumn(fieldName, displayName, fieldDef);
    _set(filter, ['additional', 'checkFieldExistence'], true); // This will enable EXISTS and NOT_EXISTS operators in filter

    return filter;
  });

const configFilterOperatorsToTableManagerFilterOperators = (filters) =>
  _map(filters, (filter) => {
    let filterType = _get(filter, 'filterType');

    if (filterType === OPERATORS.IN) {
      filterType = OPERATORS.CONTAINS;
    } else if (filterType === OPERATORS.NIN) {
      filterType = OPERATORS.NOT_CONTAINS;
    }

    return {
      ...filter,
      filterType,
    };
  });

const tableManagerFilterOperatorsToConfigFilterOperators = (filters) =>
  _map(filters, (filter) => {
    let filterType = _get(filter, 'filterType');

    if (filterType === OPERATORS.CONTAINS) {
      filterType = OPERATORS.IN;
    } else if (filterType === OPERATORS.NOT_CONTAINS) {
      filterType = OPERATORS.NIN;
    }

    return {
      ...filter,
      filterType,
    };
  });

const convertTableManagerFiltersToConfigFilters = (selectedFilters) =>
  _compact(
    _map(selectedFilters, (filter) => {
      const field = _get(filter, 'type');
      const filterType = _get(filter, 'operator');
      const values = _get(filter, 'values');

      if (_isEmpty(values) && !_includes(EXISTENCE_CHECK_FIELDS, filterType)) {
        return null;
      }

      return {
        field,
        filterType,
        values,
      };
    }),
  );

const getUpfrontFilterFields = (filterMetadata) =>
  _reduce(
    filterMetadata,
    (prevFilterFields, filterData) => {
      if (_get(filterData, 'upfront')) {
        prevFilterFields.push(_get(filterData, 'fieldName'));
      }
      return prevFilterFields;
    },
    [],
  );

const getSelectedFilterCount = (selectedFilters) => {
  const count = _reduce(
    selectedFilters,
    (totalCount, filter) => {
      const operator = _get(filter, 'operator');
      const values = _get(filter, 'values');

      return !_includes(EXISTENCE_CHECK_FIELDS, operator) && (_isEmpty(values) || !operator) ? totalCount : totalCount + 1;
    },
    0,
  );

  return count;
};

const createFilterProps = ({ fieldDefinitionByName, selectedFilters, filterMetadata }) => {
  const filters = getFilters(filterMetadata, fieldDefinitionByName);
  const defaultFilterTypes = getUpfrontFilterFields(filterMetadata);

  return {
    filterTypes: filters,
    defaultFilterTypes,
    shouldHideFilterGroup: true,
    selectedFilters,
    showReset: false,
    showClear: true,
    // showSortTrigger: false,
    showResultsCount: true,
    filterPopoverClass: styles.previewFilterOverrideStyle,
    getSelectedFilterCount,
  };
};

const getSortableColumns = (properties, fieldDefinitionByName) => {
  const sortableFields = tget(properties, ALL_VIEW_PROPERTY_KEYS.SORTABLE_FIELDS);

  const sortableColumns = _map(sortableFields, (fieldName) => {
    const fieldDef = _get(fieldDefinitionByName, [getSimpleFieldNameFromColumn(fieldName)], {});
    let displayName = fieldDefinitionReader.displayName(fieldDef);
    const dataType = fieldDefinitionReader.dataType(fieldDef);

    if (dataType === DATA_TYPES.COMPLEX) {
      const complexEntityFieldDefinitions = fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef);
      const complexFieldDisplayName = _get(complexEntityFieldDefinitions, [getNthChildOfFieldName(fieldName, 1), 'displayName'], '');
      displayName = `${displayName} - ${complexFieldDisplayName}`;
    }

    return { key: fieldName, displayName, Header: displayName, sortable: true };
  });

  return sortableColumns || [];
};

export {
  createFilterProps,
  convertTableManagerFiltersToConfigFilters,
  configFilterOperatorsToTableManagerFilterOperators,
  tableManagerFilterOperatorsToConfigFilterOperators,
  getSortableColumns,
};
