import _includes from 'lodash/includes';

import { tget } from '@tekion/tekion-base/utils/general';
import { NO_DATA } from '@tekion/tekion-base/app.constants';

// Tekion-components
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import COLUMN_IDS from '../constants/approvalRequestsList.columnIds';
import {
  APPROVAL_STATUS_LABELS,
  APPROVAL_STATUS_COLOR_MAPS,
  APPROVAL_CENTRE_FIELD_IDS,
  CUSTOM_ENTITY_ACTION_TYPES,
  CUSTOM_ENTITY_ACTION_TYPE_LABEL,
} from '../../../../constants/approvalCentre.constants';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const getStatusProps = () => ({
  labelMap: APPROVAL_STATUS_LABELS,
  colorMap: APPROVAL_STATUS_COLOR_MAPS,
});

const actionTypeAccessor = (data) => {
  const actionType = tget(data, ['data', APPROVAL_CENTRE_FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.ACTION_TYPE]);

  if (_includes([CUSTOM_ENTITY_ACTION_TYPES.CREATE_CUSTOM_ENTITY, CUSTOM_ENTITY_ACTION_TYPES.UPDATE_CUSTOM_ENTITY], actionType)) {
    return CUSTOM_ENTITY_ACTION_TYPE_LABEL[actionType];
  }

  return NO_DATA;
};

const entityAccessor = (data) => tget(data, COLUMN_IDS.ENTITY, NO_DATA);

const COLUMNS = {
  NAME: {
    Header: __('Name'),
    id: COLUMN_IDS.NAME,
    accessor: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
  GROUP: {
    Header: __('Group'),
    id: COLUMN_IDS.GROUP,
    accessor: COLUMN_IDS.GROUP,
    Cell: TextRenderer,
  },
  CATEGORY: {
    Header: __('Category'),
    id: COLUMN_IDS.CATEGORY,
    accessor: COLUMN_IDS.CATEGORY,
    Cell: TextRenderer,
  },
  ENTITY: {
    Header: __('Entity'),
    id: COLUMN_IDS.ENTITY,
    accessor: entityAccessor,
    Cell: TextRenderer,
  },
  CUSTOM_ENTITY_ACTION_TYPE: {
    Header: __('Action type'),
    id: COLUMN_IDS.CUSTOM_ENTITY_ACTION_TYPE,
    accessor: actionTypeAccessor,
    Cell: TextRenderer,
  },
  STATUS: {
    Header: __('Task status'),
    id: COLUMN_IDS.STATUS,
    accessor: COLUMN_IDS.STATUS,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  VALIDITY: {
    Header: __('Task valid till'),
    id: COLUMN_IDS.VALIDITY,
    accessor: COLUMN_IDS.VALIDITY,
    Cell: TextRenderer,
  },
  CREATED_TIME: {
    Header: __('Task created at'),
    id: COLUMN_IDS.CREATED_TIME,
    accessor: COLUMN_IDS.CREATED_TIME,
    Cell: TextRenderer,
  },
  LAST_MODIFIED_TIME: {
    Header: __('Last modified at'),
    id: COLUMN_IDS.LAST_MODIFIED_TIME,
    accessor: COLUMN_IDS.LAST_MODIFIED_TIME,
    Cell: TextRenderer,
  },
};

const GENERAL_COLUMNS = [
  COLUMNS.NAME,
  COLUMNS.GROUP,
  COLUMNS.CATEGORY,
  COLUMNS.ENTITY,
  COLUMNS.STATUS,
  COLUMNS.VALIDITY,
  COLUMNS.CREATED_TIME,
  COLUMNS.LAST_MODIFIED_TIME,
];

const APPLICATION_SPECIFIC_COLUMNS = [
  COLUMNS.NAME,
  COLUMNS.GROUP,
  COLUMNS.CATEGORY,
  COLUMNS.ENTITY,
  COLUMNS.CUSTOM_ENTITY_ACTION_TYPE,
  COLUMNS.STATUS,
  COLUMNS.VALIDITY,
  COLUMNS.CREATED_TIME,
  COLUMNS.LAST_MODIFIED_TIME,
];

const getColumns = (isMountedInsideApplication) => {
  if (isMountedInsideApplication) {
    return APPLICATION_SPECIFIC_COLUMNS;
  }

  return GENERAL_COLUMNS;
};

export default getColumns;
