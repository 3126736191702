import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _nth from 'lodash/nth';
import _castArray from 'lodash/castArray';
import _isArray from 'lodash/isArray';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { fetchDashboard, getAllDashboards } from '../../../../../actions/reporting.actions';
import { getDefaultTimeRange, getWidgetItems } from './dashboard.helpers';
import { transformResponseBodyToGridLayout } from '../../../../../organisms/reporting/helpers/reporting.general.helpers';
import ACTION_TYPES from '../constants/dashboard.actionTypes';

const handleInit = async ({ setState, getState }) => {
  const { history, timeRange = getDefaultTimeRange(), match } = getState();
  const dashboardName = _get(match, 'params.dashboardName');
  let dashboard = _get(history, 'location.state.dashboard');
  let dashboardList = _get(history, 'location.state.dashboardList');
  const promises = [];

  setState({ loading: true });

  if (_isEmpty(dashboard)) {
    promises.push(fetchDashboard(dashboardName));
  } else {
    promises.push(Promise.resolve(dashboard));
  }

  if (_isEmpty(dashboardList)) {
    promises.push(getAllDashboards());
  } else {
    promises.push(Promise.resolve(dashboardList));
  }
  [dashboard, dashboardList] = await Promise.all(promises);
  if (!_isArray(dashboardList)) {
    dashboardList = tget(dashboardList, 'hits', EMPTY_ARRAY);
  }

  dashboardList = _map(dashboardList, (item) => ({
    ...item,
    label: _get(item, 'displayName'),
    value: _get(item, 'name'),
  }));

  const selectedDashboard = _castArray(dashboardName);

  const widgetLayouts = _get(dashboard, 'widgetLayouts', EMPTY_ARRAY);
  const layouts = transformResponseBodyToGridLayout(widgetLayouts);
  const layoutsByName = _keyBy(layouts, 'i');
  const widgetItems = getWidgetItems(widgetLayouts);

  setState({
    dashboard: { ...dashboard },
    loading: false,
    layoutsByName,
    timeRange,
    widgetItems,
    dashboardList,
    selectedDashboard,
  });
};

const handleApplyDashboardFilter = ({ setState, params }) => {
  const { value } = params;

  setState({
    timeRange: {
      startTime: _nth(value),
      endTime: _nth(value, 1),
    },
  });
};

const handleChangeDashboard = ({ getState, setState, params }) => {
  const { history } = getState();
  const value = _get(params, 'value');
  setState({ selectedDashboard: _castArray(value) });
  history.replace(`/reporting/dashboards/${value}`);
};
const handleGoBack = ({ getState }) => {
  const { history } = getState();
  history.push('/reporting');
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: handleInit,
  [ACTION_TYPES.GO_BACK]: handleGoBack,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleApplyDashboardFilter,
  [ACTION_TYPES.CHANGE_DASHBOARD]: handleChangeDashboard,
};

export default ACTION_HANDLERS;
