import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import List from '@tekion/tekion-components/molecules/List';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import ROLE_READERS from '../../../../../../../../readers/role.reader';
import ACTION_TYPES from '../../constants/roleConfigure.actionTypes';
import { getModifiedTime } from '../../helpers/roleConfigure.helpers';

import styles from './roleConfigureCardView.module.scss';

const RoleConfigureCardView = ({ roleCardData, onAction }) => {
  const handleEditClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CLICK_EDIT,
    });
  }, [onAction]);

  const permissionCreatedTime = useMemo(() => getModifiedTime(ROLE_READERS.createdTime(roleCardData)), [roleCardData]);
  const permissionModifiedTime = useMemo(() => getModifiedTime(ROLE_READERS.modifiedTime(roleCardData)), [roleCardData]);

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Display Name')}</div>
        <List.Item.Meta description={ROLE_READERS.name(roleCardData) || NO_DATA} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Created On')}</div>
        <List.Item.Meta description={permissionCreatedTime || NO_DATA} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Modified On')}</div>
        <List.Item.Meta description={permissionModifiedTime || NO_DATA} />
      </div>

      <FontIcon size={SIZES.MD_S} onClick={handleEditClick} className="cursor-pointer m-r-32">
        icon-edit
      </FontIcon>
    </div>
  );
};

RoleConfigureCardView.propTypes = {
  roleCardData: PropTypes.object,
  onAction: PropTypes.func,
};

RoleConfigureCardView.defaultProps = {
  roleCardData: EMPTY_OBJECT,
  onAction: _noop,
};

export default React.memo(RoleConfigureCardView);
