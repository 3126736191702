import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { tget } from '@tekion/tekion-base/utils/general';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

// Components
import Modal from '@tekion/tekion-components/molecules/Modal';
import Loader from '@tekion/tekion-components/molecules/loader';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

// Helpers
import getFields from './helpers/subscriptionFormModal.fields';
import getSections from './helpers/subscriptionFormModal.sections';

import { CONTEXT_ID } from './constants/subscriptionFormModal.constants';
import ACTION_TYPES from '../../constants/subscriptionConfigForm.actionTypes';
import SUBSCRIPTION_FIELD_IDS from './constants/subscriptionFormModal.fieldIds';

import styles from './subscriptionFormModal.module.scss';

const SubscriptionFormModal = ({
  isEntityFieldsLoading,
  isSubscriptionModalVisible,
  selectedSubscriptionIndex,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  selectedSubscriptionValues,
  onAction,
}) => {
  const [formValues, setFormValues] = useState();
  const [errors, setErrors] = useState();

  const entityName = tget(formValues, SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_ENTITY_NAME);
  const sections = useMemo(() => getSections(entityName, isEntityFieldsLoading), [entityName, isEntityFieldsLoading]);
  const fields = useMemo(
    () => getFields(entityName, conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName),
    [entityName, conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName],
  );

  const handleCancel = useCallback(() => {
    setFormValues({});
    setErrors({});
    onAction({ type: ACTION_TYPES.ON_CANCEL_MODAL_SUBSCRIPTION_FORM });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    setErrors({});
    triggerSubmit(CONTEXT_ID);
  }, []);

  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload = EMPTY_OBJECT } = action;
    switch (type) {
      case FORM_ACTION_TYPES.ON_FORM_INIT: {
        const selectedSubscriptionEntityName = tget(selectedSubscriptionValues, SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_ENTITY_NAME);
        onAction({ type: ACTION_TYPES.LOAD_CONDITION_FIELDS, payload: { entityName: selectedSubscriptionEntityName } });
        setFormValues(selectedSubscriptionValues);
        break;
      }
      case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
        const { value: updatedValue, id } = payload;

        if (id === SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_ENTITY_NAME) {
          setFormValues((prev) => ({ ...prev, [SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_CONDITION]: {} }));
          onAction({ type: ACTION_TYPES.LOAD_CONDITION_FIELDS, payload: { entityName: updatedValue } });
        }

        setFormValues((prev) => ({ ...prev, [id]: updatedValue }));
        break;
      }
      case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
        const { errors: newErrors } = payload;
        setErrors(newErrors);
        break;
      }

      case FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT: {
        onAction({
          type: ACTION_TYPES.ON_SUBMIT_SUBSCRIPTION_MODAL,
          payload: { subscriptionData: formValues, subscriptionIndex: selectedSubscriptionIndex },
        });
        setFormValues({});
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <Modal
      destroyOnClose
      visible={isSubscriptionModalVisible}
      className={styles.modalBodyContainer}
      title={__('Subscription')}
      width={Modal.SIZES.L}
      submitBtnText={__('Done')}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    >
      <FormWithSubmission
        className="p-t-16"
        contextId={CONTEXT_ID}
        sections={sections}
        fields={fields}
        values={formValues}
        errors={errors}
        onAction={handleAction}
      />
      {isEntityFieldsLoading && <Loader id="ENTITY_FIELDS_LOADING" />}
    </Modal>
  );
};

SubscriptionFormModal.propTypes = {
  isEntityFieldsLoading: PropTypes.bool,
  isSubscriptionModalVisible: PropTypes.bool,
  selectedSubscriptionIndex: PropTypes.number,
  mapOfVariableToEntityName: PropTypes.object.isRequired,
  selectedSubscriptionValues: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
};

SubscriptionFormModal.defaultProps = {
  isEntityFieldsLoading: false,
  isSubscriptionModalVisible: false,
  selectedSubscriptionIndex: -1,
  selectedSubscriptionValues: EMPTY_OBJECT,
};

export default React.memo(SubscriptionFormModal);
