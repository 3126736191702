import React, { useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';

import AssignModal from '../assignModal';
import withSize from '../../../../../../../connectors/withSize';

import { getWorkspaceProjectList } from '../../../../../../../actions/workspaceProjectManagement.actions';
import ACTION_HANDLERS from './helpers/projectSettings.actionHandlers';
import { createTableProps, getSubHeaderProps } from './helpers/projectSettings.helpers';

import { COLUMN_CONFIG } from './helpers/projectSettings.columns';
import { TABLE_MANAGER_PROPS } from './constants/projectSettings.general';
import { TABS } from '../../constants/permissionSetConfigure.constants';

const ProjectSettings = ({
  contentHeight,
  isLoading,
  isAssignProjectModalVisible,
  isAssignProjectModalDataSubmitting,
  totalCount,
  projectDropdownOptions,
  selectedProjectsModalData,
  projectSettingsData,
  projectNames,
  onAction,
}) => {
  const tableProps = useMemo(() => createTableProps(isLoading, onAction), [isLoading, onAction]);
  return (
    <Page>
      <Page.Body style={{ height: contentHeight - 110 }}>
        <TableManager
          subHeaderProps={getSubHeaderProps(totalCount)}
          tableManagerProps={TABLE_MANAGER_PROPS}
          columns={COLUMN_CONFIG}
          tableProps={tableProps}
          data={projectSettingsData}
          onAction={onAction}
        />
        <AssignModal
          isAssignModalVisible={isAssignProjectModalVisible}
          isMulti
          isAssignModalSubmitting={isAssignProjectModalDataSubmitting}
          modalTitle={__('Add Projects')}
          getOptionsApi={getWorkspaceProjectList}
          selectDropdownOptions={projectDropdownOptions}
          selectedDropdownData={selectedProjectsModalData}
          tabType={TABS.PROJECT_SETTINGS}
          existingData={projectNames}
          onAction={onAction}
        />
      </Page.Body>
    </Page>
  );
};

ProjectSettings.propTypes = {
  contentHeight: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isAssignProjectModalVisible: PropTypes.bool,
  isAssignProjectModalDataSubmitting: PropTypes.bool,
  totalCount: PropTypes.number,
  projectNames: PropTypes.array,
  projectDropdownOptions: PropTypes.array,
  selectedProjectsModalData: PropTypes.array,
  projectSettingsData: PropTypes.array,
  onAction: PropTypes.func,
};

ProjectSettings.defaultProps = {
  isLoading: false,
  isAssignProjectModalVisible: false,
  isAssignProjectModalDataSubmitting: false,
  totalCount: 0,
  projectNames: EMPTY_ARRAY,
  projectDropdownOptions: EMPTY_ARRAY,
  selectedProjectsModalData: EMPTY_ARRAY,
  projectSettingsData: EMPTY_ARRAY,
  onAction: _noop,
};

export default compose(withSize({ hasPageHeader: true }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ProjectSettings);
