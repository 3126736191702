import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

const CURRENT_PAGE = 0;
const RESULTS_PER_PAGE = 50;

const COLUMN_ACCESSORS = {
  DASHBOARD_NAME: 'name',
  DISPLAY_NAME: 'displayName',
};
const CUSTOM_ACTIONS = {
  CREATE_NEW_DASHBOARD: 'CREATE_NEW_DASHBOARD',
  EDIT: 'EDIT',
};

const INITIAL_STATE = {
  searchField: COLUMN_ACCESSORS.DASHBOARD_NAME,
  dashboards: EMPTY_ARRAY,
  currentPage: CURRENT_PAGE,
  pageSize: RESULTS_PER_PAGE,
  resultsPerPage: RESULTS_PER_PAGE,
};

export { CURRENT_PAGE, RESULTS_PER_PAGE, COLUMN_ACCESSORS, CUSTOM_ACTIONS, INITIAL_STATE };
