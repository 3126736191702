import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _has from 'lodash/has';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import Button from '@tekion/tekion-components/atoms/Button';

import Records from '../../../../actions/customCodeApis/customCode.RecordApi';
import Actions from '../../../../actions/customCodeApis/customCode.ActionApi';
import Comments from '../../../../actions/customCodeApis/customCode.CommentApi';

import ViewRendererContext from '../../../../context/viewRenderer.context';

import variableApiFactory from '../../../../factories/variableApi.Factory';

import { convertEventHandlersFromArrayToObjectByEventName, executeEventFromEventViewConfigData } from '../../../../utils/eventHandlers';
import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';

import Media from '../../../../actions/customCodeApis/customCode.MediaApi';
import {
  MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT,
  EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS,
} from '../../../../constants/eventHandlers.constants';
import { EVENT_NAMES } from '../../../../constants/eventActions.constants';
import { BUTTON_CONFIGURATOR_IDS, BUTTON_RADIO_VALUES } from '../../molecules/buttonConfiguratorRenderer/buttonConfiguratorRenderer.constants';
import { COMPONENT_TYPES, COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP, VIEW_CONTEXT_KEYS } from '../../constants/viewBuilder.constants';
import { CUSTOM_STYLE_IDS } from '../../../../constants/customStyles.constants';
import { ALL_VIEW_PROPERTY_KEYS, RENDERER_PROP_KEYS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

const SUPPORTED_CUSTOM_STYLE_SECTION = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[COMPONENT_TYPES.BUTTON];

const ButtonRenderer = ({ isPreviewMode, componentConfig, entityRecord }) => {
  const [buttonProps, setButtonProps] = useState({});

  const viewContextValue = useContext(ViewRendererContext);
  const properties = useMemo(() => tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT), [componentConfig]);

  const widgetName = tget(viewContextValue, `${VIEW_CONTEXT_KEYS.WIDGET_CONTEXT}.widgetName`, EMPTY_STRING);
  const viewVariables = useMemo(() => tget(viewContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.viewVariables`, EMPTY_STRING), [viewContextValue]);
  const handleSetViewVariables = useMemo(
    () => tget(viewContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.handleSetViewVariables`, _noop),
    [viewContextValue],
  );
  const variablesApi = useMemo(() => variableApiFactory(viewVariables, handleSetViewVariables), [viewVariables, handleSetViewVariables]);

  const buttonView = useMemo(
    () =>
      properties[ALL_VIEW_PROPERTY_KEYS.BUTTON] === BUTTON_RADIO_VALUES.ICON
        ? Button.VIEW.ICON
        : tget(properties, BUTTON_CONFIGURATOR_IDS.BUTTON_TEXT_TYPE, Button.VIEW.PRIMARY),
    [properties],
  );

  const customStyles = useMemo(() => tget(properties, RENDERER_PROP_KEYS.CUSTOM_STYLES, {}), [properties]);
  const resolvedCustomStyles = useMemo(
    () => getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_SECTION, customStyles, entityRecord, variablesApi),
    [customStyles, variablesApi, entityRecord],
  );

  const currentLoggedInUser = useMemo(() => tget(viewContextValue, `${VIEW_CONTEXT_KEYS.APPLICATION_CONTEXT}.currentUser`), [viewContextValue]);

  const handleButtonClick = useCallback(() => {
    const buttonEventHandler = tget(properties, BUTTON_CONFIGURATOR_IDS.EVENT_HANDLERS, EMPTY_ARRAY);
    const modifiedEventHandlers = convertEventHandlersFromArrayToObjectByEventName(buttonEventHandler);
    const clickEventHandler = tget(modifiedEventHandlers, EVENT_NAMES.CLICK, EMPTY_OBJECT);

    const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$RECORD]: entityRecord,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$CURRENT_USER]: currentLoggedInUser,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RECORDS]: Records,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.VARIABLES]: variablesApi,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.ACTIONS]: Actions,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.COMMENTS]: Comments,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.MEDIA]: Media,
    };

    executeEventFromEventViewConfigData(clickEventHandler, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE, entityRecord, widgetName);
  }, [properties, entityRecord, currentLoggedInUser, variablesApi, widgetName]);

  useEffect(() => {
    const buttonOnLoad = async () => {
      let directButtonProps = {};

      if (buttonView === Button.VIEW.ICON) {
        directButtonProps = {
          ...directButtonProps,
          icon: tget(properties, BUTTON_CONFIGURATOR_IDS.BUTTON_ICON, EMPTY_STRING),
        };
      } else {
        directButtonProps = {
          ...directButtonProps,
          label: __(`${tget(properties, BUTTON_CONFIGURATOR_IDS.BUTTON_TEXT, EMPTY_STRING)}`),
        };
      }

      setButtonProps(directButtonProps);

      const buttonEventHandler = tget(properties, BUTTON_CONFIGURATOR_IDS.EVENT_HANDLERS, EMPTY_ARRAY);
      const modifiedEventHandlers = convertEventHandlersFromArrayToObjectByEventName(buttonEventHandler);

      if (_has(modifiedEventHandlers, EVENT_NAMES.LOAD)) {
        const onLoadEventData = tget(modifiedEventHandlers, EVENT_NAMES.LOAD, EMPTY_OBJECT);

        const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
          [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$RECORD]: entityRecord,
          [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$CURRENT_USER]: currentLoggedInUser,
          [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RECORDS]: Records,
          [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.VARIABLES]: variablesApi,
          [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.ACTIONS]: Actions,
          [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.COMMENTS]: Comments,
        };

        const scriptReturnValue = await executeEventFromEventViewConfigData(
          onLoadEventData,
          MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE,
          entityRecord,
          widgetName,
        );
        const isReturnValuePresentFromScript = tget(scriptReturnValue, EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS.IS_RETURN_VALUE_PRESENT, false);

        if (isReturnValuePresentFromScript) {
          // Expected that scriptReturnValue should be object that will be passed for button props
          const scriptButtonProps = tget(scriptReturnValue, EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS.RETURN_VALUE, {});
          setButtonProps((prevButtonProps) => ({ ...prevButtonProps, ...scriptButtonProps }));
        }
      }
    };

    buttonOnLoad();
  }, [buttonView, entityRecord, variablesApi, currentLoggedInUser, widgetName, properties]);

  return (
    <Button
      view={buttonView}
      {...buttonProps}
      style={{ ...tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTAINER, EMPTY_OBJECT) }}
      onClick={isPreviewMode ? handleButtonClick : _noop}
    />
  );
};

ButtonRenderer.propTypes = {
  isPreviewMode: PropTypes.bool,
  componentConfig: PropTypes.object,
  entityRecord: PropTypes.object,
};

ButtonRenderer.defaultProps = {
  isPreviewMode: false,
  componentConfig: EMPTY_OBJECT,
  entityRecord: EMPTY_OBJECT,
};

export default ButtonRenderer;
