import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';

import ShareAndManageRecordReader from '../../../../../readers/shareAndManageRecord.reader';
import { ShareAndManageRecordUserDetailSetters } from '../shareAndManageRecordUserData';

const getUserIdsFromManageAccessesData = (manageAccessesData) => _map(manageAccessesData, (data) => ShareAndManageRecordReader.userId(data));

// For combining users data, manage access data and entity access sharing data into single object
const combineAllUserManageAndEntityAccessData = (usersData, manageAccessesData, entityAccessUsersData) => {
  const usersDataById = _keyBy(usersData, 'id');

  const combinedData = _map(manageAccessesData, (data) => {
    const userId = ShareAndManageRecordReader.userId(data);

    const userObject = _get(usersDataById, userId);
    const entityAccessUserData = _get(entityAccessUsersData, userId);

    return ShareAndManageRecordUserDetailSetters.createShareAndManageAccessRecordUserData({
      userObject,
      manageAccessObject: data,
      entityAccessObject: entityAccessUserData,
    });
  });

  return combinedData;
};

export { getUserIdsFromManageAccessesData, combineAllUserManageAndEntityAccessData };
