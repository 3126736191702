const FIELDS = {
  FIELD: __('Field'),
  OPERATOR: __('Operator'),
  VALUE: __('Value'),
};

const FIELD_IDS = {
  FILTERABLE_FIELDS: 'filterableFields',
  FILTERABLE: 'filterable',
  UPFRONT_FILTER_CHECKBOX: 'upfrontFilterCheckbox',
  UPFRONT_FILTERS: 'upfrontFilters',
  PRE_APPLIED_FILTERS: 'preAppliedFilters',
  PRE_APPLIED_FILTER_CHECKBOX: 'preAppliedFilterCheckbox',
  FILTER_METADATA: 'filterMetadata',
  FILTER_LIST: 'filterList',
  FIELD_NAME: 'fieldName',
  FIELD: 'field',
  FILTER_TYPE: 'filterType',
  VALUES: 'values',
  DELETE: 'delete',
  UPFRONT: 'upfront',
  DISABLED: 'disabled',
};

const FIELD_ERRORS = {
  [FIELD_IDS.FIELD]: __('Field is required'),
  [FIELD_IDS.FILTER_TYPE]: __('Operator is required'),
  [FIELD_IDS.VALUES]: __('Value is required'),
};

export { FIELDS, FIELD_IDS, FIELD_ERRORS };
