import { COLUMN_IDS } from '../../constants/wwdPermissionList.constants';

const CONTEXT_ID = 'WWD_PERMISSION_EDIT_MODAL';

const ACCESS_TYPE_OPTIONS = [
  { label: __('Private'), value: COLUMN_IDS.PRIVATE_READ_WRITE },
  { label: __('Public Read - Write'), value: COLUMN_IDS.PUBLIC_READ_WRITE },
  { label: __('Public Read'), value: COLUMN_IDS.PUBLIC_READ },
];

export { CONTEXT_ID, ACCESS_TYPE_OPTIONS };
