import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import _find from 'lodash/find';

import { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { createAssignment, getAssignments, removeAssignment } from '../../../../../../../../actions/permissionSetManagement.action';
import globalLookupItemsResolver from '../../../../../../../../actions/globalLookupItemsResolver';

import { createSearchPayload, createAssignmentPayload } from './usersSetting.helpers';

import { ACTION_TYPES as ASSIGN_MODAL_ACTION_TYPES } from '../../assignModal/assignModal.constants';
import ACTION_TYPES from '../constants/usersSetting.actionTypes';
import { LOOKUP_ITEM_TO_RESOLVE, LOOKUP_FIELD_TO_RESOLVE } from '../../../../../../../../constants/globalLookupItemsResolver.constants';
import { FIELD_IDS as PERMISSION_SET_FIELD_IDS } from '../../../../../../../../constants/permissionSet.constants';

import workspaceUserReader from '../../../../../../../../readers/workSpaceUser.reader';

const fetchData = async ({ getState, setState }) => {
  const { match } = getState();
  const permissionName = _get(match, 'params.permissionName', EMPTY_STRING);

  setState({ isLoading: true });
  const payload = createSearchPayload(permissionName);
  const response = await getAssignments(payload);
  const assignmentsData = tget(response, 'hits', EMPTY_ARRAY);
  let usersData = [];
  let userIds = [];

  if (!_isEmpty(assignmentsData)) {
    userIds = _map(assignmentsData, (data) => _get(data, 'principalIdentifier', EMPTY_STRING));
    const userResponse = await globalLookupItemsResolver({ [LOOKUP_ITEM_TO_RESOLVE.USER]: { [LOOKUP_FIELD_TO_RESOLVE.ID]: userIds } }, false);
    const users = _map(tget(userResponse, LOOKUP_ITEM_TO_RESOLVE.USER, EMPTY_OBJECT));

    usersData = _map(users, (data) => {
      const firstName = workspaceUserReader.firstName(data);
      const lastName = workspaceUserReader.lastName(data);
      return { userName: `${firstName} ${lastName}`, status: workspaceUserReader.active(data), id: workspaceUserReader.id(data) };
    });
  }

  setState({ assignmentsData, usersSettingData: usersData, isLoading: false, userIds, totalCount: _size(usersData) });
};

const handleAssignUserClick = ({ setState }) => {
  setState({ isAssignUserModalVisible: true });
};

const handleAssignUserModalClose = ({ setState }) => {
  setState({ isAssignUserModalVisible: false });
};

const handleUserDropdownChange = ({ params, setState }) => {
  const { value = EMPTY_ARRAY } = params;
  setState({ selectedUsersModalData: value });
};

const handleUserDropdownDone = async ({ getState, setState }) => {
  const { match, selectedUsersModalData } = getState();
  const permissionName = _get(match, 'params.permissionName', EMPTY_STRING);
  const userGroupIdsFromValue = _map(selectedUsersModalData, 'value');
  if (!_isEmpty(userGroupIdsFromValue)) {
    setState({ isAssignUserModalDataSubmitting: true });
    const payload = createAssignmentPayload(userGroupIdsFromValue, permissionName);
    const response = await createAssignment(payload);
    if (!_isEmpty(response)) {
      setState({ selectedUsersModalData: [] });
    }
  }
  setState({ isAssignUserModalVisible: false, isAssignUserModalDataSubmitting: false }, () => fetchData({ getState, setState }));
};

const handleRemoveAssignedUser = async ({ setState, getState, params }) => {
  const { assignmentsData = [] } = getState();
  const principalId = _get(params, 'id', EMPTY_STRING);
  const userObject = _find(assignmentsData, ['principalIdentifier', principalId]);
  const recordName = _get(userObject, PERMISSION_SET_FIELD_IDS.NAME, EMPTY_STRING);

  await removeAssignment(recordName);

  fetchData({ getState, setState });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: fetchData,
  [ACTION_TYPES.ASSIGN_USER_CLICK]: handleAssignUserClick,
  [ASSIGN_MODAL_ACTION_TYPES.ASSIGN_MODAL_CLOSE]: handleAssignUserModalClose,
  [ASSIGN_MODAL_ACTION_TYPES.ASSIGN_DROPDOWN_ON_CHANGE]: handleUserDropdownChange,
  [ASSIGN_MODAL_ACTION_TYPES.ASSIGN_DROPDOWN_DONE]: handleUserDropdownDone,
  [ACTION_TYPES.REMOVE_USER_ACTION]: handleRemoveAssignedUser,
};

export default ACTION_HANDLERS;
