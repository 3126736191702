import produce from 'immer';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _noop from 'lodash/noop';
import _set from 'lodash/set';
import _head from 'lodash/head';
import _compact from 'lodash/compact';
import _castArray from 'lodash/castArray';
import _snakeCase from 'lodash/snakeCase';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { fetchMetaDataForWidget } from './addWidget.helpers';
import { createPayloadForTableWidget, createPayloadForChartWidget, getValuesFromWidget, getMeasurements } from './addWidget.general.helpers';
import { fetchWidget, createWidget, updateWidget, getMetaDataByFieldName } from '../../../../../../../actions/reporting.actions';
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../../../actions/conditionBuilder.actions';
import { fetchEntityDefByName } from '../../../../../../../actions/entityManagement.actions';

import { ACTION_TYPES, ADD_WIDGET_FORM_FIELDS, COLUMN_IDS, METRIC_TYPES } from '../constants/addWidget.general.constant';
import { WIDGET_TYPES } from '../../../../../../../organisms/reporting/molecules/widgetContainer/constants/widgetContainer.general.constants';
import { COMPONENT_CONFIG_KEYS } from '../../../../../../../organisms/visualBuilder/constants/visualBuilder.general.constants';

const getWidget = async ({ setState, params = EMPTY_OBJECT }) => {
  const widgetName = _get(params, COMPONENT_CONFIG_KEYS.WIDGET_NAME, '');

  setState({ loading: true });
  if (!_isEmpty(widgetName)) {
    const widget = await fetchWidget(widgetName);
    const entityName = _get(widget, ADD_WIDGET_FORM_FIELDS.ENTITY_NAME);
    const widgetType = _get(widget, ADD_WIDGET_FORM_FIELDS.WIDGET_TYPE);
    const entityDef = await fetchEntityDefByName(entityName);
    const resource = {};
    _set(resource, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS, _get(entityDef, 'fieldDefinitions', EMPTY_ARRAY));
    if (widgetType === WIDGET_TYPES.DATA_TABLE) {
      const mapOfVariableToEntityName = { $record: entityName };
      const conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityName, {}, false, false);
      _set(resource, 'mapOfVariableToEntityName', mapOfVariableToEntityName);
      _set(resource, 'conditionBuilderFieldDefinitionObject', conditionBuilderFieldDefinitionObject);
      const measurements = await fetchMetaDataForWidget(_castArray(entityName));
      _set(resource, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS, measurements);
    } else {
      const dimensions = getArraySafeValue(_get(widget, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS));
      const fieldName = _get(dimensions, 'field');
      if (entityName && fieldName) {
        let metrics = await getMetaDataByFieldName(entityName, fieldName);
        metrics = getMeasurements(_get(getArraySafeValue(metrics), ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS, EMPTY_ARRAY));
        _set(resource, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS, metrics);
      }
    }

    const { values, possibleXAxis, possibleYAxis, selectedDimensions, selectedMeasurements } = getValuesFromWidget(widget, resource);
    setState({
      widget,
      widgetName,
      formValues: values,
      possibleXAxis,
      possibleYAxis,
      selectedDimensions,
      selectedMeasurements,
      widgetMetaData: resource,
      loading: false,
    });
  } else {
    setState({ loading: false });
  }
};

const handleOnReportResourceName = async ({ getState, setState }) => {
  const { formValues, metricsByResource, widgetType } = getState();
  const entityName = _get(formValues, `${ADD_WIDGET_FORM_FIELDS.ENTITY_NAME}.0`, '');
  const memoValue = _get(metricsByResource, entityName);
  let metrics = memoValue;
  let updatedMetrics = metricsByResource;
  const mapOfVariableToEntityName = { $record: entityName };

  if (_isEmpty(memoValue)) {
    const conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityName, {}, false, false);
    metrics = {};
    const entityDef = await fetchEntityDefByName(entityName);
    _set(metrics, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS, _get(entityDef, 'fieldDefinitions', EMPTY_ARRAY));
    _set(metrics, 'mapOfVariableToEntityName', mapOfVariableToEntityName);
    _set(metrics, 'conditionBuilderFieldDefinitionObject', conditionBuilderFieldDefinitionObject);
  }
  if (widgetType === WIDGET_TYPES.DATA_TABLE && _isEmpty(_get(metrics, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS))) {
    const measurements = await fetchMetaDataForWidget(_castArray(entityName));
    _set(metrics, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS, measurements);
    updatedMetrics = { ...metricsByResource, [entityName]: metrics };
  }

  const possibleXAxis = _map(_get(metrics, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS, EMPTY_ARRAY), (obj) => ({
    ...obj,
    metricType: METRIC_TYPES.DIMENSION,
    id: _get(obj, 'name'),
    label: __(_get(obj, 'displayName')),
    value: _get(obj, 'name'),
  }));

  const possibleYAxis = _map(_get(metrics, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS, EMPTY_ARRAY), (obj) => ({
    ...obj,
    metricType: METRIC_TYPES.MEASUREMENTS,
    id: _get(obj, 'name'),
    label: __(_get(obj, 'displayName')),
    value: _get(obj, 'name'),
  }));

  setState({
    widgetMetaData: metrics,
    possibleXAxis,
    possibleYAxis,
    formValues: {
      ...formValues,
      [ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS]: EMPTY_ARRAY,
      [ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS]: EMPTY_ARRAY,
    },
    metricsByResource: updatedMetrics,
  });
};

const handleFetchMeasurements = async ({ getState, setState }) => {
  const { formValues } = getState();
  const entityName = _get(formValues, ADD_WIDGET_FORM_FIELDS.ENTITY_NAME);
  const fieldName = _get(formValues, ADD_WIDGET_FORM_FIELDS.X_AXIS);
  let possibleYAxis = [];

  if (entityName && fieldName) {
    const metrics = await getMetaDataByFieldName(entityName, fieldName);
    let measurements = _get(getArraySafeValue(metrics), ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS, EMPTY_ARRAY);
    measurements = _map(measurements, (item) => ({ ...item, entity: entityName }));
    possibleYAxis = getMeasurements(measurements);
    possibleYAxis = _map(possibleYAxis, (obj) => ({
      ...obj,
      metricType: METRIC_TYPES.MEASUREMENTS,
      id: _get(obj, 'name'),
      label: __(_get(obj, 'displayName')),
      value: _get(obj, 'name'),
    }));
  }

  setState({ possibleYAxis });
};

const FIELD_ID_VS_ON_CHANGE = {
  [ADD_WIDGET_FORM_FIELDS.ENTITY_NAME]: handleOnReportResourceName,
  [ADD_WIDGET_FORM_FIELDS.X_AXIS]: handleFetchMeasurements,
};

const handleOnFieldChange = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { formValues, fetchInitialFilterValues, disableAutoFillForName } = getState();
  const newValues = {
    ...formValues,
    [id]: value,
  };

  if (id === ADD_WIDGET_FORM_FIELDS.DISPLAY_NAME && !disableAutoFillForName) {
    _set(newValues, ADD_WIDGET_FORM_FIELDS.WIDGET_NAME, _snakeCase(value));
  } else if (id === ADD_WIDGET_FORM_FIELDS.WIDGET_NAME) {
    setState({ disableAutoFillForName: true });
  }

  if (fetchInitialFilterValues) {
    setState({ fetchInitialFilterValues: false });
  }
  setState({ formValues: newValues }, () => {
    const func = FIELD_ID_VS_ON_CHANGE[id] || _noop;
    func({ getState, setState });
  });
};

const handleColumnConfigChange = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { formValues, selectedDimensions, selectedMeasurements } = getState();
  const { id, value, columnId, rowIndex, displayName = EMPTY_STRING } = params;

  const tableData = _get(formValues, id, []);
  let updatedSelectedDimension = selectedDimensions;
  let updatedSelectedMeasurement = selectedMeasurements;

  let newTableData = produce(tableData, (draft) => _set(draft, `[${rowIndex}].${columnId}`, value));

  if (columnId === COLUMN_IDS.SELECT_FIELD) {
    newTableData = produce(newTableData, (draft) => _set(draft, `[${rowIndex}].${COLUMN_IDS.COLUMN_DISPLAY_NAME}`, displayName));
    const selectedField = _compact(_map(newTableData, (data) => _head(_get(data, COLUMN_IDS.SELECT_FIELD, EMPTY_ARRAY))));

    if (id === ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS) {
      updatedSelectedDimension = selectedField;
    }
    if (id === ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS) {
      updatedSelectedMeasurement = selectedField;
    }
  }

  setState({
    formValues: {
      ...formValues,
      [id]: [...newTableData],
    },
    selectedDimensions: updatedSelectedDimension,
    selectedMeasurements: updatedSelectedMeasurement,
  });
};

const ON_SUBMIT_VS_WIDGET_TYPE = {
  [WIDGET_TYPES.DATA_TABLE]: createPayloadForTableWidget,
  [WIDGET_TYPES.CHART]: createPayloadForChartWidget,
};

const handleSubmit = async ({ setState, getState }) => {
  const {
    formValues,
    widgetType = WIDGET_TYPES.DATA_TABLE,
    widgetName,
    selectedColumns = EMPTY_ARRAY,
    possibleXAxis,
    possibleYAxis,
    handleCancel,
  } = getState();
  setState({ isSubmitting: true });

  const handler = ON_SUBMIT_VS_WIDGET_TYPE[widgetType] || _noop;
  const payload = handler({ formValues, widgetType, widgetName, possibleXAxis, possibleYAxis, selectedColumns });
  if (_isEmpty(payload)) {
    setState({ isSubmitting: false });
    return;
  }
  if (_isEmpty(widgetName)) {
    const response = await createWidget(payload);
    const { name } = response;
    setState({ isSubmitting: false }, () => handleCancel(EMPTY_STRING, { name }));
  } else {
    const response = await updateWidget(widgetName, payload);
    const { name } = response;
    setState({ isSubmitting: false }, () => handleCancel(EMPTY_STRING, { name, isWidgetUpdated: true }));
  }
};

const closeModal = ({ setState }) => {
  setState({ modalVisible: false });
};

const handleFormErrors = ({ params = EMPTY_OBJECT, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const handleAddNewRow = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { id } = params;
  const { formValues } = getState();
  const data = _get(formValues, [id], EMPTY_ARRAY);

  setState({
    formValues: {
      ...formValues,
      [id]: [...data, {}],
    },
  });
};

const handleDeleteRow = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { formValues } = getState();
  const { index = 0, id } = params;
  const data = _get(formValues, [id], EMPTY_ARRAY);
  data.splice(index, 1);
  let updatedSelectedDimension;
  let updatedSelectedMeasurement;

  const selectedField = _compact(_map(data, (dataItem) => _head(_get(dataItem, COLUMN_IDS.SELECT_FIELD, EMPTY_ARRAY))));

  if (id === ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS) {
    updatedSelectedDimension = selectedField;
  }

  if (id === ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS) {
    updatedSelectedMeasurement = selectedField;
  }

  setState({
    formValues: {
      ...formValues,
      [id]: [...data],
    },
    selectedDimensions: updatedSelectedDimension,
    selectedMeasurements: updatedSelectedMeasurement,
  });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.FETCH_WIDGET_BY_NAME]: getWidget,
  [ACTION_TYPES.ON_COLUMN_CONFIG_CHANGE]: handleColumnConfigChange,
  [ACTION_TYPES.SAVE_OR_UPDATE_WIDGET]: handleSubmit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleFormErrors,
  [ACTION_TYPES.CLOSE_MODAL]: closeModal,
  [ACTION_TYPES.ON_CLICK_ADD_NEW]: handleAddNewRow,
  [ACTION_TYPES.ON_DELETE]: handleDeleteRow,
};

export default ACTION_HANDLERS;
