import React from 'react';
import _get from 'lodash/get';

import PropTypes from 'prop-types';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

const FontStylingCoomponent = (props) => {
  const { children, leaf, attributes } = props;
  let styleProperties = {};
  const fontSize = _get(leaf, 'fontSize');
  const color = _get(leaf, 'color');

  if (fontSize) {
    styleProperties = { ...styleProperties, fontSize };
  }

  if (color) {
    styleProperties = { ...styleProperties, color };
  }

  return (
    <span {...attributes} style={styleProperties}>
      {children}
    </span>
  );
};

FontStylingCoomponent.propTypes = {
  children: PropTypes.node,
  leaf: PropTypes.object,
  attributes: PropTypes.object,
};

FontStylingCoomponent.defaultProps = {
  children: null,
  leaf: EMPTY_OBJECT,
  attributes: EMPTY_OBJECT,
};
export default React.memo(FontStylingCoomponent);
