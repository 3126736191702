import { defaultMemoize } from 'reselect';

import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import addOptionsToAdditional from '@tekion/tekion-base/utils/addOptionsToAdditional';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { TEXT_STARTS_WITH } from '../../../../../../../constants/filter.constants';
import { NAVIGATION_TYPE_FILTER, DEFAULT_FILTERS, DEFAULT_FILTER_GROUP } from '../constants/appList.filter';
import { NAVIGATION_TYPES } from '../constants/appList.general.constants';
import { EDIT_ACTION, ENTITY_MAPPINGS } from '../constants/appList.rowActions';
import { COLUMNS_IDS } from './appList.columns';

const getFlattenProperties = (properties) =>
  _reduce(
    properties,
    (result, property) => {
      const type = _get(property, 'type');
      const value = _get(property, 'value');
      if (!_isEmpty(type)) {
        return { ...result, [type]: value };
      }
      return result;
    },
    {},
  );

const getComponentWithFlattenProperties = (component) => {
  const flattenProperties = getFlattenProperties(tget(component, 'properties', EMPTY_ARRAY));
  const children = tget(component, 'children', EMPTY_ARRAY);
  let updatedChildren = [];
  if (!_isEmpty(children)) {
    updatedChildren = _map(children, (child) => getComponentWithFlattenProperties(child));
  }

  return { ...component, properties: flattenProperties, children: updatedChildren };
};

const getOptions = defaultMemoize((options) =>
  _map(options, (option) => ({
    value: option.value,
    label: option.label,
  })),
);

const getFilterProps = defaultMemoize((selectedFilters, selectedFilterGroup) => {
  const filterTypes = [addOptionsToAdditional(NAVIGATION_TYPE_FILTER, getOptions(NAVIGATION_TYPES))];

  return {
    filterTypes,
    selectedFilters,
    appliedFilterGroup: selectedFilterGroup || DEFAULT_FILTER_GROUP,
    defaultFilterTypes: DEFAULT_FILTERS,
  };
});

const getTableProps = defaultMemoize((handleRowAction, currentPage, loading, rows, totalNumberOfEntries) => ({
  currentPage: currentPage + 1,
  loading,
  showPagination: true,
  pageSize: rows,
  showActions: true,
  resultsPerPage: rows,
  totalNumberOfEntries,
  totalNumberOfPages: Math.round(totalNumberOfEntries / rows),
  minRows: 0,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  rows,
  onRowActionClick: handleRowAction,
  getRowActions: () => [EDIT_ACTION, ENTITY_MAPPINGS],
}));

const getModifiedFilters = defaultMemoize((selectedFilters) => {
  const modifiedFilters = _reduce(
    selectedFilters,
    (result, filter) => {
      if (!_isEmpty(_get(filter, 'values'))) {
        result.push({
          values: _get(filter, 'values', EMPTY_ARRAY),
          field: _get(filter, 'type', EMPTY_STRING),
          filterType: _get(filter, 'operator', EMPTY_STRING),
        });
      }
      return result;
    },
    [],
  );
  return modifiedFilters;
});

const getPayload = defaultMemoize((searchText, selectedFilters, pageSize, nextPageToken) => {
  let payload = { rows: pageSize };
  let filters = EMPTY_ARRAY;

  if (!_isEmpty(selectedFilters)) {
    filters = [...getModifiedFilters(selectedFilters)];
  }

  if (!_isEmpty(searchText)) {
    filters = [
      {
        field: COLUMNS_IDS.NAME,
        values: [searchText],
        filterType: TEXT_STARTS_WITH,
      },
      ...filters,
    ];
  }

  if (!_isEmpty(filters)) {
    payload = {
      filters,
      ...payload,
    };
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      nextPageToken,
      ...payload,
    };
  }
  return payload;
});

export { getOptions, getTableProps, getFilterProps, getModifiedFilters, getPayload };
