const PAGE_IDS = {
  PROJECTS: 'projects',
  PROJECT_APPLICATIONS: 'project-applications',
  PROJECT_CREATE: 'create-project',
  PROJECT_EDIT: 'edit-project',
  WORKSPACES: 'workspaces',
  USERS: 'users',
  USER_CREATE: 'create-user',
  USER_EDIT: 'edit-user',
  USER_GROUPS: 'user-groups',
  USER_GROUP_CREATE: 'create-user-group',
  USER_GROUP_EDIT: 'edit-user-group',
  APPLICATIONS: 'applications',
  APPLICATION_CREATE: 'create-application',
  APPLICATION_EDIT: 'edit-application',
  CONFIGURE_APPLICATION: 'configure-application',
  ENTITIES: 'entities',
  ENTITY_CREATE: 'create-entity',
  ENTITY_EDIT: 'edit-entity',
  FIELDS: 'fields',
  FIELD_CREATE: 'create-field',
  FIELD_EDIT: 'edit-field',
  VALIDATION_RULE_BUILDER: 'validation-rules',
  VALIDATION_RULE_BUILDER_CREATE: 'create-validation-rule',
  VALIDATION_RULE_BUILDER_EDIT: 'edit-validation-rule',
  VIEW_BUILDER: 'view-builder',
  VIEW_BUILDER_CREATE: 'create-view-builder',
  VIEW_BUILDER_EDIT: 'edit-view-builder',
  ACTION_BUILDER: 'actions',
  ACTION_BUILDER_CREATE: 'create-action',
  ACTION_BUILDER_EDIT: 'edit-action',
  PERMISSION_SET: 'permission-set',
  PERMISSION_SET_CREATE: 'permission-set-create',
  PERMISSION_SET_EDIT: 'permission-set-edit',
  PERMISSION_SET_CONFIGURE: 'permission-set-configure',
  REPORTEE: 'roles',
  REPORTEE_CREATE: 'roles-create',
  REPORTEE_EDIT: 'roles-edit',
  ROLE_CONFIGURE: 'role-configure',
  ENTITY_RECORD_GROUP_LIST: 'record-groups',
  ENTITY_RECORD_GROUP_CREATE: 'create-record-group',
  ENTITY_RECORD_GROUP_EDIT: 'edit-record-group',
  RECORD_SHARING_RULES: 'record-sharing-rules',
  WWD_PERMISSIONS: 'wwd-permissions',
  REPORTING: 'reporting',
  DASHBOARDS: 'dashboards',
  DASHBOARD_CREATE: 'create-dashboard',
  DASHBOARD_EDIT: 'edit-dashboard',
  TEMPLATE_CREATE: 'create-template',
  TEMPLATE_EDIT: 'edit-template',
  TEMPLATE_BUILDER: 'templates',
  RECORD_TYPE: 'record-type',
  RECORD_TYPE_DETAILS: 'record-type-details',
  RECORD_TYPE_CREATE: 'create-record-type',
  RECORD_TYPE_EDIT: 'edit-record-type',
  JOB_CREATE: 'create-job',
  JOB_EDIT: 'edit-job',
  JOBS: 'jobs',
  VISUAL_BUILDER: 'visual-builder',
  VISUAL_BUILDER_CREATE: 'create-visual-builder',
  VISUAL_BUILDER_EDIT: 'edit-visual-builder',
  RECORD_TYPE_VIEW: 'record-type-view',
  ENTITY_VIEW: 'entity-view',
  EDIT_ENTITY_VIEW: 'edit-entity-view',
  WORKFLOW_BUILDER: 'workflow-builder',
  RULE_CREATE: 'create-rule',
  RULE_EDIT: 'edit-rule',
  PROCESS_CREATE: 'create-process',
  PROCESS_EDIT: 'edit-process',
  APPROVAL_STUDIO: 'approval-studio',
  APPROVAL_GROUPS: 'groups',
  APPROVAL_CATEGORIES: 'categories',
  APPROVAL_SETTING: 'setting',
  APPROVAL_SETTINGS: 'settings',
  APPROVAL_SETTING_CREATE: 'create-settings',
  APPROVAL_SETTING_DETAIL: 'setting-details',
  APPROVAL_SETTING_ADD_FIELD: 'add-field',
  APPROVAL_SETTING_EDIT_FIELD: 'edit-field',
  APPROVAL_PROCESSES: 'processes',
  APPROVAL_PROCESS_CREATE: 'create-process',
  APPROVAL_PROCESS_EDIT: 'edit-process',
  EXPORTS: 'exports',
  EXPORT_CREATE: 'create-export',
  EXPORT_EDIT: 'edit-export',
  ADD_METADATA_COMPONENT: 'add-metadata-component',
  IMPORT_CREATE: 'create-import',
  SERVER_CREDENTIAL_CONFIGURE: 'server-credential-hub',
  AUTH_PROVIDERS_LIST: 'auth-providers',
  AUTH_PROVIDER_CREATE: 'create-auth-provider',
  AUTH_PROVIDER_EDIT: 'edit-auth-provider',
  EXTERNAL_CREDENTIALS_LIST: 'external-credentials',
  EXTERNAL_CREDENTIAL_CREATE: 'create-external-credential',
  EXTERNAL_CREDENTIAL_EDIT: 'edit-external-credential',
  SERVER_CREDENTIALS_LIST: 'server-credentials',
  SERVER_CREDENTIAL_CREATE: 'create-server-credential',
  SERVER_CREDENTIAL_EDIT: 'edit-server-credential',
  SUBSCRIPTION_CONFIGS: 'subscriptions-configs',
  SUBSCRIPTION_CONFIG_CREATE: 'create-subscription',
  SUBSCRIPTION_CONFIG_EDIT: 'edit-subscription',
  SUBSCRIPTION_CONFIG_LOGS: 'subscription-logs',
  LOGIN_CONFIGURATIONS: 'login-configurations',
  GLOBAL_SELECT_LIST: 'global-selects',
  CREATE_GLOBAL_SELECT_LIST: 'create-global-select',
  EDIT_GLOBAL_SELECT_LIST: 'edit-global-select',
  IMPORT_DATA_STUDIO: 'import-data-studio',
  DATA_IMPORTS: 'data-imports',
  CREATE_IMPORT: 'create-import',
};

export default PAGE_IDS;
