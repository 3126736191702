import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Lodash
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _curry from 'lodash/curry';
import _isNil from 'lodash/isNil';

// Constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import Content from '@tekion/tekion-components/atoms/Content/Content';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';

import { ATTRIBUTE_DISPLAY_MARGIN, AUDIT_TYPES } from './changeLog.constants';
import changeReader from '../../readers/Change';

import styles from './changeLog.module.scss';

class ChangeLog extends PureComponent {
  // eslint-disable-next-line class-methods-use-this
  renderAttributeDisplay = (attributeDisplay, depth = 0) => {
    const style = { marginLeft: ATTRIBUTE_DISPLAY_MARGIN * depth };
    return (
      <Content highlight className={styles.attributeDisplay} style={style}>
        {__(attributeDisplay)}
      </Content>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  renderValue = (value, auditType) => {
    if (_isEmpty(value)) {
      return <div className={styles.emptyLabel}>{__('None')}</div>;
    }
    const suffix = auditType === AUDIT_TYPES.CREATE ? ` - ${__('Created')} ` : ` - ${__('Updated')} `;
    return (
      <div className={styles.displayValue}>
        {value}
        <span className={styles.suffixClassName}>{auditType !== AUDIT_TYPES.UPDATE && suffix}</span>
      </div>
    );
  };

  renderValues = (depth, change, index = 0) => {
    const oldValues = changeReader.oldValues(change);
    const newValues = changeReader.newValues(change);
    const attributeDisplay = changeReader.attributeDisplay(change);
    const auditType = changeReader.auditType(change);
    const style = { marginLeft: ATTRIBUTE_DISPLAY_MARGIN * depth };
    return (
      <>
        {this.renderAttributeDisplay(attributeDisplay, depth)}
        <div className={classNames('d-flex align-items-center')} style={style} key={index}>
          {auditType !== AUDIT_TYPES.CREATE && this.renderValue(oldValues, auditType)}
          {auditType === AUDIT_TYPES.UPDATE && (
            <FontIcon size={SIZES.S} className={styles.arrow}>
              icon-arrow_right
            </FontIcon>
          )}
          {auditType !== AUDIT_TYPES.DELETE && this.renderValue(newValues, auditType)}
        </div>
      </>
    );
  };

  renderList = (change, depth = 0, index = 0) => {
    const complexObjectDisplayName = changeReader.complexObjectDisplayName(change);
    const nestedAuditChanges = changeReader.nestedAuditChanges(change);
    const attributeDisplayStr = `${index + 1}. `;
    const style = { marginLeft: ATTRIBUTE_DISPLAY_MARGIN * depth };
    const complexObjectDisplayNameStyle = { marginLeft: ATTRIBUTE_DISPLAY_MARGIN * (depth + 1) };
    return (
      <>
        <Content highlight style={style} className={`absolute ${styles.attributeDisplay} ${styles.listIndex}`} key={index}>
          {attributeDisplayStr}
        </Content>
        <PropertyControlledComponent controllerProperty={!_isNil(complexObjectDisplayName)}>
          <Content highlight className={styles.attributeDisplay} style={complexObjectDisplayNameStyle}>
            {`${__('For')} ${__(complexObjectDisplayName)} :`}
          </Content>
        </PropertyControlledComponent>
        {_map(nestedAuditChanges, this.renderChange(depth + 1))}
      </>
    );
  };

  renderNestedAuditChange = (depth, attribute) => (change, index) => {
    const nestedAttribute = changeReader.attribute(change);
    if (nestedAttribute === attribute) {
      return this.renderList(change, depth, index);
    }
    return this.renderChange(depth + 1, change);
  };

  renderChange = _curry((depth, change, index = 0) => {
    const attribute = changeReader.attribute(change);
    const attributeDisplay = changeReader.attributeDisplay(change);
    const nestedAuditChanges = changeReader.nestedAuditChanges(change);
    if (nestedAuditChanges) {
      return (
        <>
          {this.renderAttributeDisplay(attributeDisplay, depth)}
          {_map(nestedAuditChanges, this.renderNestedAuditChange(depth + 1, attribute))}
        </>
      );
    }
    return this.renderValues(depth, change, index);
  });

  render() {
    const { change } = this.props;
    return <div className={`${styles.changeLogContainer} relative`}>{this.renderChange(0, change)}</div>;
  }
}
ChangeLog.propTypes = {
  change: PropTypes.object,
};

ChangeLog.defaultProps = {
  change: EMPTY_OBJECT,
};

export default ChangeLog;
