import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import IconWithBadge from '@tekion/tekion-components/molecules/IconWithBadge';
import { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import styles from './notificationBell.module.scss';

const NotificationBell = ({ unSeenCount, onClick }) => {
  const handleBellClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <div onClick={handleBellClick} className="flex">
      <IconWithBadge
        isVisible
        className={`cursor-pointer ${styles.bell}`}
        iconColor="white"
        iconName="icon-bell"
        iconSize={SIZES.L}
        action="GLOBAL_NOTIFICATION_INFO"
        badgeContent={unSeenCount}
      />
    </div>
  );
};

NotificationBell.propTypes = {
  unSeenCount: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

NotificationBell.defaultProps = {
  onClick: _noop,
};

export default React.memo(NotificationBell);
