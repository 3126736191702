import cx from 'classnames';

import _map from 'lodash/map';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';
import { isMultiChildConditionType } from '@tekion/tekion-rule-engine/src/utils/core/index';

import { FIELD_IDS } from '../constants/conditionChangeToModal.fields';
import { CONDITION_TYPES } from '../../../../constants/workflow.constants';
import { LABEL_BY_TARGET, MAX_TAG_COUNT, PATH_LABEL } from '../constants/conditionChangeToModal.general';

import style from '../conditionChangeToModal.module.scss';

const getFormSections = (targetType, sourceType) => {
  const rows = [{ columns: [FIELD_IDS.YES_PATH] }];

  if (targetType === CONDITION_TYPES.IF_ELSE) {
    rows.push({ columns: [FIELD_IDS.NO_PATH] });
  } else if (targetType === CONDITION_TYPES.SWITCH && sourceType === CONDITION_TYPES.IF_ELSE) {
    rows[0].columns.push(FIELD_IDS.NO_PATH);
  }

  const sections = [
    {
      header: {
        label: LABEL_BY_TARGET[targetType],
        size: 5,
      },
      rows,
    },
  ];

  return sections;
};

const getFormFields = ({ targetType, sourceType, values, pathOptions } = EMPTY_OBJECT) => ({
  [FIELD_IDS.YES_PATH]: {
    id: FIELD_IDS.YES_PATH,
    renderer: Select,
    renderOptions: {
      selectContainerClassName: cx({ [style.maxWidth50]: sourceType === CONDITION_TYPES.IF }),
      label: isMultiChildConditionType(targetType) ? PATH_LABEL.YES : PATH_LABEL.DEFAULT,
      options: _map(pathOptions, (path) => ({ ...path, disabled: values[FIELD_IDS.NO_PATH] === path.value })),
      validators: [isRequiredRule],
      maxTagCount: MAX_TAG_COUNT,
    },
  },
  [FIELD_IDS.NO_PATH]: {
    id: FIELD_IDS.NO_PATH,
    renderer: Select,
    renderOptions: {
      label: PATH_LABEL.NO,
      options: _map(pathOptions, (path) => ({ ...path, disabled: values[FIELD_IDS.YES_PATH] === path.value })),
      validators: [isRequiredRule],
      maxTagCount: MAX_TAG_COUNT,
    },
  },
});

export { getFormFields, getFormSections };
