import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import withActions from '@tekion/tekion-components/connectors/withActions';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Loader from '@tekion/tekion-components/molecules/loader';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import AddTableWidget from './AddTableWidget';
import AddChartWidget from './AddChartWidget';

import ACTION_HANDLERS from './helpers/addWidget.actionHandlers';
import { ACTION_TYPES } from './constants/addWidget.general.constant';
import { WIDGET_TYPES } from '../../../../../../organisms/reporting/molecules/widgetContainer/constants/widgetContainer.general.constants';

const AddWidget = (props) => {
  const { widgetName, onAction, widgetType, loading, isSubmitting, modalVisible, handleCancel } = props;

  const handleSubmit = useCallback(() => {
    triggerSubmit(widgetType);
  }, [widgetType]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.FETCH_WIDGET_BY_NAME, payload: { widgetName } });
  }, [onAction, widgetName]);

  return (
    <Modal
      title={__('Add/Edit Widget')}
      visible={modalVisible}
      onCancel={handleCancel}
      width={Modal.SIZES.XL}
      destroyOnClose
      onSubmit={handleSubmit}
      loading={isSubmitting}
    >
      <PropertyControlledComponent controllerProperty={!loading} fallback={<Loader className="full-width full-height align-center" />}>
        <PropertyControlledComponent controllerProperty={widgetType === WIDGET_TYPES.DATA_TABLE}>
          <AddTableWidget {...props} contextId={widgetType} />
        </PropertyControlledComponent>
        <PropertyControlledComponent controllerProperty={widgetType === WIDGET_TYPES.CHART}>
          <AddChartWidget {...props} contextId={widgetType} />
        </PropertyControlledComponent>
      </PropertyControlledComponent>
    </Modal>
  );
};

AddWidget.propTypes = {
  widgetType: PropTypes.string,
  loading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  widgetName: PropTypes.string,
  modalVisible: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
};

AddWidget.defaultProps = {
  widgetType: WIDGET_TYPES.DATA_TABLE,
  loading: false,
  isSubmitting: false,
  modalVisible: true,
  widgetName: '',
  handleCancel: _noop,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(AddWidget);
