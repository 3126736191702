import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/cms';

const createApplication = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/applications`, payload);
const updateApplication = (name, payload = EMPTY_OBJECT) => ApiService.put(`${BASE_URL}/applications/name/${name}`, payload);
const getApplicationDetails = (name) => ApiService.get(`${BASE_URL}/applications/name/${name}`);

const getApplicationList = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/applications/search`, payload);

export default {
  createApplication,
  updateApplication,
  getApplicationDetails,
  getApplicationList,
};
