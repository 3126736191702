import ApiService from './apiService/apiService';

const NOTIFICATION_BASE_URL = '/core/u/communications';

const fetchLatestCommunicationForAllGroups = (payload) => ApiService.put(`${NOTIFICATION_BASE_URL}/groups/fetch`, payload);

const updateStatus = (payload) => ApiService.put(`${NOTIFICATION_BASE_URL}/status`, payload);

const fetchUnreadCount = (payload) => ApiService.post(`${NOTIFICATION_BASE_URL}/unread-count`, payload);

const fetchCommunications = (payload) => ApiService.post(`${NOTIFICATION_BASE_URL}/search`, payload);

export default {
  fetchLatestCommunicationForAllGroups,
  updateStatus,
  fetchUnreadCount,
  fetchCommunications,
};
