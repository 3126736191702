// Tekion-components
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import COLUMN_IDS from '../constants/subscriptionConfigList.columnIds';
import ACTION_TYPES from '../constants/subscriptionConfigList.actionTypes';
import { SUBSCRIPTION_CONFIG_STATUS_LABEL_MAP, SUBSCRIPTION_CONFIG_STATUS_COLOR_MAP } from '../constants/subscriptionConfigList.general';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const getStatusProps = () => ({
  labelMap: SUBSCRIPTION_CONFIG_STATUS_LABEL_MAP,
  colorMap: SUBSCRIPTION_CONFIG_STATUS_COLOR_MAP,
});

const COLUMN_CONFIG = [
  {
    Header: __('Display Name'),
    id: COLUMN_IDS.SUBSCRIPTION_CONFIG_DISPLAY_NAME,
    accessor: COLUMN_IDS.SUBSCRIPTION_CONFIG_DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Path'),
    id: COLUMN_IDS.URL,
    accessor: COLUMN_IDS.URL,
    Cell: TextRenderer,
  },
  {
    Header: __('Server Credential Name'),
    id: COLUMN_IDS.SERVER_CREDENTIAL_NAME,
    accessor: COLUMN_IDS.SERVER_CREDENTIAL_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Status'),
    id: COLUMN_IDS.STATUS,
    accessor: COLUMN_IDS.STATUS,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
];

const EDIT_SUBSCRIPTION_CONFIG = {
  id: ACTION_TYPES.ON_EDIT_SUBSCRIPTION_CONFIG,
  name: __('Edit Config'),
};

const REDIRECT_TO_SUBSCRIPTION_CONFIG_LOGS = {
  id: ACTION_TYPES.ON_REDIRECT_TO_SUBSCRIPTION_LOGS,
  name: __('Logs'),
};

const ACTIVE_SUBSCRIPTION_CONFIG = {
  id: ACTION_TYPES.ON_ACTIVE_SUBSCRIPTION_CONFIG,
  name: __('Active'),
};

const INACTIVE_SUBSCRIPTION_CONFIG = {
  id: ACTION_TYPES.ON_INACTIVE_SUBSCRIPTION_CONFIG,
  name: __('Deactivate'),
};

const DEFAULT_ROW_ACTIONS = [EDIT_SUBSCRIPTION_CONFIG];

export { COLUMN_CONFIG, DEFAULT_ROW_ACTIONS, ACTIVE_SUBSCRIPTION_CONFIG, INACTIVE_SUBSCRIPTION_CONFIG, REDIRECT_TO_SUBSCRIPTION_CONFIG_LOGS };
