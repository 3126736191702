const COLUMN_ACCESSORS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  DESCRIPTION: 'description',
};

const CUSTOM_ACTIONS = {
  ADD_NEW_GROUP: 'ADD_NEW_GROUP',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  FETCH_DATA: 'FETCH_DATA',
  CLOSE_DELETE_REQUEST_DIALOG: 'CLOSE_DELETE_REQUEST_DIALOG',
  CONFIRM_DELETE_REQUEST: 'CONFIRM_DELETE_REQUEST',
};

const SEARCHABLE_OPTIONS = [
  {
    label: __('Name'),
    value: COLUMN_ACCESSORS.NAME,
  },
  {
    label: __('Display Name'),
    value: COLUMN_ACCESSORS.DISPLAY_NAME,
  },
];
const CURRENT_PAGE = 0;
const RESULTS_PER_PAGE = 50;

const INITIAL_STATE = {
  currentPage: CURRENT_PAGE,
  pageSize: RESULTS_PER_PAGE,
  resultsPerPage: RESULTS_PER_PAGE,
  searchField: COLUMN_ACCESSORS.NAME,
  isTenantUniverse: false,
  showDeleteModal: false,
};

export { CUSTOM_ACTIONS, SEARCHABLE_OPTIONS, INITIAL_STATE, CURRENT_PAGE, RESULTS_PER_PAGE, COLUMN_ACCESSORS };
