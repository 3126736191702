import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { createSearchPayload, getRolesDataByName } from './roleConfigure.helpers';

import ACTION_TYPES from '../constants/roleConfigure.actionTypes';
import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import PAGE_IDS from '../../../../../constants/PageIds.constants';

import { getWorkspaceUserList } from '../../../../../../../actions/workspaceUserManagement.actions';
import { assignUsersByRoleName, getAllRoles, getRoleByName } from '../../../../../../../actions/rolesHierarchyManagement.actions';
import workspaceUserReader from '../../../../../../../readers/workSpaceUser.reader';

const fetchData = async ({ getState, setState, params }) => {
  const { match, pageSize } = getState();
  const roleName = _get(match, 'params.roleName', EMPTY_STRING);

  setState({ isLoading: true });

  const currentPageToken = _get(params, 'nextPageToken');
  const payload = createSearchPayload(roleName, currentPageToken, pageSize);
  const response = await getWorkspaceUserList(payload);
  let usersData = tget(response, 'hits', EMPTY_ARRAY);

  let userIds = [];

  usersData = _map(usersData, (data) => {
    const firstName = workspaceUserReader.firstName(data);
    const lastName = workspaceUserReader.lastName(data);
    const displayName = workspaceUserReader.displayName(data);
    const email = workspaceUserReader.email(data);
    const id = workspaceUserReader.id(data);
    userIds = [...userIds, id];

    return { userName: `${firstName} ${lastName}`, status: workspaceUserReader.active(data), displayName, email };
  });

  setState({
    usersSettingData: usersData,
    isLoading: false,
    userIds,
    totalCount: _get(response, 'count'),
    nextPageToken: _get(response, 'nextPageToken'),
  });
};

const initData = async ({ getState, setState }) => {
  const { match, location } = getState();
  const roleName = _get(match, 'params.roleName', EMPTY_STRING);

  await fetchData({ getState, setState });

  setState({ isLoading: true });

  let selectedRoleData = _get(location, 'state.selectedRoleData', {});

  if (_isEmpty(selectedRoleData)) {
    selectedRoleData = await getRoleByName(roleName);
  }

  const rolesDataByName = _get(location, 'state.rolesDataByName', {});

  if (_isEmpty(rolesDataByName)) {
    const rolesTreeData = await getAllRoles();
    if (!_isEmpty(rolesTreeData)) {
      getRolesDataByName(rolesTreeData, rolesDataByName);
    }
  }

  setState({ isLoading: false, selectedRoleData, rolesDataByName });
};

const handlePageSizeUpdate = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');

  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }
  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }
  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      fetchData({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const handleEditRole = ({ getState }) => {
  const { history, match } = getState();
  const roleName = _get(match, 'params.roleName', EMPTY_STRING);

  const path = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTEE}/${PAGE_IDS.REPORTEE_EDIT}/${roleName}`;

  history.push(path);
};

const handleModalClick = ({ setState }) => {
  setState({ isAssignUserModalVisible: true });
};

const handleModalSave = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { match } = getState();
  const { users } = params;

  const roleName = _get(match, 'params.roleName', EMPTY_STRING);
  const userIds = _map(users, (user) => _get(user, 'id', EMPTY_STRING));
  if (!_isEmpty(userIds)) {
    setState({ isAssignUserModalDataSubmitting: true });
    await assignUsersByRoleName({ roleName, userIds });
  }

  setTimeout(() => {
    setState({ isAssignUserModalVisible: false, usersModalData: {}, isAssignUserModalDataSubmitting: false }, () => {
      fetchData({ getState, setState });
    });
  }, ES_REFETCH_DELAY);
};

const handleModalClose = ({ setState }) => {
  setState({ isAssignUserModalVisible: false });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: initData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageSizeUpdate,
  [ACTION_TYPES.ON_CLICK_EDIT]: handleEditRole,
  [ACTION_TYPES.ON_CLICK_ASSIGN_USERS]: handleModalClick,
  [ACTION_TYPES.ON_MODAL_SAVE]: handleModalSave,
  [ACTION_TYPES.ON_MODAL_CLOSE]: handleModalClose,
};

export default ACTION_HANDLERS;
