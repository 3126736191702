const VALIDATION_OVERRIDE_FIELD_IDS = {
  VALIDATION_OVERRIDES: 'validationOverrides',
  USER_INPUT: 'validationUserInput',
  USER_INPUT_TYPE: 'USER_INPUT_TYPE',
  SELECTED_FIELDS: 'manualInput',
};

const USER_INPUT_TYPES = {
  FORM: 'FORM',
  FIELD: 'FIELD',
};

const USER_INPUT_TYPE_OPTIONS = [
  {
    label: __('Form'),
    value: USER_INPUT_TYPES.FORM,
  },
  {
    label: __('Select Fields'),
    value: USER_INPUT_TYPES.FIELD,
  },
];

export { VALIDATION_OVERRIDE_FIELD_IDS, USER_INPUT_TYPE_OPTIONS, USER_INPUT_TYPES };
