import { getMomentValueOf } from '@tekion/tekion-base/utils/dateUtils';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { DATE_PICKER_ID } from '../../constants/durationFieldRenderer.general.constants';

const triggerDurationFieldRendererActions = ({ action, value, id, onAction }) => {
  const { type, payload } = action;
  switch (type) {
    case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
      const { id: _id, value: _value } = payload;

      let newValue = _value;

      if (_id === DATE_PICKER_ID) {
        newValue = getMomentValueOf(_value);
      }

      onAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: { id, value: { ...value, [_id]: newValue } },
      });

      break;
    }
    default: {
      onAction({ type, payload });
    }
  }
};

export { triggerDurationFieldRendererActions };
