import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = '/core/u';
const PERMISSION_SET_BASE_URL = 'core/u/governance/permissions';
const SEARCH_URL = 'core/u/governance/permissions/search';
const ASSIGNMENT_BASE_URL = `${BASE_URL}/governance/assignment`;

const getPermissionByName = (permissionName) => ApiService.get(`${PERMISSION_SET_BASE_URL}/name/${permissionName}`);

const fetchPermissionList = (payload = EMPTY_OBJECT) => ApiService.post(SEARCH_URL, payload);

const editPermissionByName = (permissionName, payload = EMPTY_OBJECT) => ApiService.put(`${PERMISSION_SET_BASE_URL}/name/${permissionName}`, payload);

const deletePermissionByName = (permissionName) => ApiService.delete(`${PERMISSION_SET_BASE_URL}/name/${permissionName}`);

const createPermissionSet = (payload) => ApiService.post(PERMISSION_SET_BASE_URL, payload);

const createAssignment = (payload) => ApiService.post(`${ASSIGNMENT_BASE_URL}/bulk`, payload);

const getAssignments = (payload) => ApiService.post(`${ASSIGNMENT_BASE_URL}/search`, payload);

const removeAssignment = (assignmentName) => ApiService.delete(`${ASSIGNMENT_BASE_URL}/name/${assignmentName}`);

export default {
  fetchPermissionList,
  getPermissionByName,
  editPermissionByName,
  createPermissionSet,
  createAssignment,
  getAssignments,
  removeAssignment,
  deletePermissionByName,
};
