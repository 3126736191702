import React from 'react';

// components
import Skeleton from '@tekion/tekion-components/molecules/skeleton';

import { AVATAR_PROPS, PARAGRAPH_PROPS, WIDTH_LARGE, WIDTH_MEDIUM, WIDTH_SMALL } from './constants/notificationCardSkeleton.general';

// styles
import styles from './notificationCardSkeleton.module.scss';

const NotificationCardSkeleton = () => (
  <div className={styles.wrapperContainer}>
    <div className={styles.unreadDotContainer}>
      <Skeleton className={styles.unreadDot} active avatar={AVATAR_PROPS} paragraph={false} title={false} />
    </div>
    <div className={styles.section}>
      <div className={`full-width ${styles.header}`}>
        <Skeleton active title={WIDTH_MEDIUM} paragraph={false} avatar={false} />
        <div className={styles.rightActions}>
          <Skeleton active avatar={AVATAR_PROPS} paragraph={false} title={false} />
          <Skeleton active avatar={AVATAR_PROPS} paragraph={false} title={false} />
        </div>
      </div>

      <div className={`full-width ${styles.content}`}>
        <div className={styles.icon}>
          <Skeleton active avatar={AVATAR_PROPS} paragraph={false} title={false} />
        </div>
        <div className={`full-width ${styles.contentArea}`}>
          <div className={`full-width ${styles.contentTitle}`}>
            <div className={styles.subHeading}>
              <Skeleton active title={WIDTH_LARGE} paragraph={false} avatar={false} />
            </div>
            <div className={styles.time}>
              <Skeleton active title={WIDTH_SMALL} paragraph={false} avatar={false} />
            </div>
          </div>
          <div className={`full-width m-t-1.6 ${styles.contentParagraph}`}>
            <Skeleton active paragraph={PARAGRAPH_PROPS} title={false} avatar={false} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NotificationCardSkeleton;
