import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _map from 'lodash/map';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import Button from '@tekion/tekion-components/atoms/Button';
import Module from '@tekion/tekion-components/molecules/leftPanelItem';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';

import { getCustomStyleConfigurationFormValue } from '../helpers/customStylesConfigurator.helper';
import getFields from '../helpers/customStylesConfigurator.fields';
import getCustomStyleFormSection from '../helpers/customStylesConfigurator.sections';
import { pascalCase } from '../../../../../helpers/general.helpers';

import ACTION_TYPES from '../constants/customStylesConfigurator.actionTypes';
import { CUSTOM_STYLES_CONFIGURATOR_FORM_CONTEXT_ID } from '../constants/customStylesConfigurator.constants';
import { COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP } from '../../../constants/viewBuilder.constants';

import customStylesReader from '../../../../../readers/customStyles.reader';

import styles from '../customStylesConfigurator.module.scss';

const CustomStylesModal = ({ selectedModuleIndex, componentType, allFormValues, errors, onAction }) => {
  const handleTabChange = useCallback((moduleIndex) => {
    triggerSubmit(CUSTOM_STYLES_CONFIGURATOR_FORM_CONTEXT_ID, { actionType: ACTION_TYPES.ON_CHANGE_MODULE, payload: { moduleIndex } });
  }, []);

  const handleAddCustomStyleClick = useCallback(() => {
    if (!_isEmpty(allFormValues)) {
      triggerSubmit(CUSTOM_STYLES_CONFIGURATOR_FORM_CONTEXT_ID, { actionType: ACTION_TYPES.ON_CLICK_ADD_CUSTOM_STYLE });
    } else {
      onAction({ type: ACTION_TYPES.ON_CLICK_ADD_CUSTOM_STYLE });
      onAction({ type: ACTION_TYPES.ON_CHANGE_MODULE, payload: { moduleIndex: 0 } });
    }
  }, [allFormValues, onAction]);

  const handleDeleteClick = useCallback(
    (moduleIndex) => (event) => {
      onAction({
        type: ACTION_TYPES.DELETE_ROW,
        payload: { moduleIndex },
      });
      event.stopPropagation();
    },
    [onAction],
  );

  const customStylesConfigurationFormValue = useMemo(
    () => getCustomStyleConfigurationFormValue(allFormValues, selectedModuleIndex),
    [allFormValues, selectedModuleIndex],
  );

  const fields = useMemo(
    () => getFields(customStylesConfigurationFormValue, allFormValues, componentType, errors),
    [customStylesConfigurationFormValue, allFormValues, componentType, errors],
  );

  const sections = useMemo(() => getCustomStyleFormSection(customStylesConfigurationFormValue), [customStylesConfigurationFormValue]);

  const renderModuleItem = useCallback(
    (customStyleType, moduleIndex) => (
      <div
        className={cx(styles.moduleItem, {
          [styles.selectedBackgroundColor]: selectedModuleIndex === moduleIndex,
        })}
      >
        <div className={styles.moduleSection}>
          <div className={styles.moduleItemTitle}>{__(!_isEmpty(customStyleType) ? pascalCase(customStyleType) : 'Untitled')}</div>
          <div>
            <FontIcon className={styles.moduleItemDeleteIcon} onClick={handleDeleteClick(moduleIndex)}>
              icon-trashcan
            </FontIcon>
          </div>
        </div>
      </div>
    ),
    [handleDeleteClick, selectedModuleIndex],
  );

  const renderActionModule = useCallback(
    () =>
      _map(allFormValues, (customStyleConfig, moduleIndex) => {
        const customStyleType = customStylesReader.customStyleType(customStyleConfig);
        return (
          <Module
            key={moduleIndex}
            moduleId={moduleIndex}
            title={renderModuleItem(customStyleType, moduleIndex)}
            selected={selectedModuleIndex === moduleIndex}
            onModuleSelect={handleTabChange}
          />
        );
      }),
    [allFormValues, handleTabChange, renderModuleItem, selectedModuleIndex],
  );

  return (
    <div className={styles.customStyleHandlerModal}>
      <div className={styles.customStyleSideBar}>
        <Button
          disabled={_size(allFormValues) === _size(COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[componentType])}
          view={Button.VIEW.TERTIARY}
          className={styles.addCustomStyleButton}
          onClick={handleAddCustomStyleClick}
        >
          <FontIcon className={styles.addIcon}>icon-add-circle</FontIcon>
          {__('Add Custom Style')}
        </Button>
        {renderActionModule()}
      </div>
      <div className={styles.rightPanel}>
        <PropertyControlledComponent controllerProperty={!_isEmpty(allFormValues)}>
          <FormWithSubmission
            className="full-width p-t-12"
            contextId={CUSTOM_STYLES_CONFIGURATOR_FORM_CONTEXT_ID}
            fields={fields}
            sections={sections}
            values={customStylesConfigurationFormValue}
            errors={errors}
            onAction={onAction}
          />
        </PropertyControlledComponent>
      </div>
    </div>
  );
};

CustomStylesModal.propTypes = {
  selectedModuleIndex: PropTypes.number,
  componentType: PropTypes.string,
  allFormValues: PropTypes.array,
  errors: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

CustomStylesModal.defaultProps = {
  selectedModuleIndex: undefined,
  componentType: undefined,
  errors: EMPTY_ARRAY,
  allFormValues: EMPTY_ARRAY,
};

export default CustomStylesModal;
