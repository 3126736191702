import { FIELD_IDS as EVENT_HANDLER_MODAL_FIELD_ID } from '../components/eventHandlerModal/eventHandlerModal.constants';
import { FIELD_IDS as EVENT_HANDLER_FIELD_IDS } from '../../../../../../../molecules/eventHandler/eventHandler.constants';
import { EVENT_ACTIONS_TYPES } from '../../../../../../../constants/eventActions.constants';

const FIELD_IDS = {
  EVENT_HANDLERS: 'eventHandlers',
};

const INITIAL_EVENT_HANDLER_CONFIGURATION = {
  [EVENT_HANDLER_MODAL_FIELD_ID.EVENT_HANDLER]: { [EVENT_HANDLER_FIELD_IDS.SUB_EVENT_TYPE]: EVENT_ACTIONS_TYPES.PRE_DEFINED },
};

const EVENT_CONFIGURATOR_FORM_CONTEXT_ID = 'EVENT_CONFIGURATOR_FORM';

export { FIELD_IDS, EVENT_CONFIGURATOR_FORM_CONTEXT_ID, INITIAL_EVENT_HANDLER_CONFIGURATION };
