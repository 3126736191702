import COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS from './complexFieldDetailsForm.fieldIds';

import styles from '../../../fieldsForm.module.scss';

const COMPLEX_FIELD_DETAILS_FORM_SECTIONS = [
  {
    header: {
      label: __('Complex Field Details'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS.ENTITY_NAME],
      },
    ],
  },
];

export default COMPLEX_FIELD_DETAILS_FORM_SECTIONS;
