import React, { useEffect } from 'react';
import { compose } from 'recompose';

import { Switch, Route, withRouter } from 'react-router-dom';
import { isAuthenticated, redirectToLogin } from './WithAuth.helpers';
import LogoutPage from './Login/LogoutPage';
import LoggedInWrapper from './LoggedInWrapper';
import LogInWrapper from './LogInWrapper';
import { LOGIN_PATHNAME, LOGOUT_PATHNAME, DEFAULT_ROUTE, FORGOT_PASSWORD_PATHNAME, UPDATE_PASSWORD_PATHNAME } from './WithAuth.constants';
import ResetPasswordPage from './Login/resetPasswordPage/ResetPasswordPage';
import UpdatePasswordPage from './Login/updatePasswordPage/UpdatePasswordPage';

function withAuthHoc(ComposedComponent) {
  const WithAuth = (props) => {
    const renderLogoutComponent = () => <LogoutPage {...props} />;
    const renderLoginComponent = () => <LogInWrapper {...props} />;
    const renderLoggedInComponent = () => <LoggedInWrapper component={ComposedComponent} {...props} />;
    const renderResetPasswordComponent = () => <ResetPasswordPage {...props} />;
    const renderUpdatePasswordComponent = () => <UpdatePasswordPage {...props} />;

    useEffect(() => {
      const isUserAuthenticated = isAuthenticated();

      if (!isUserAuthenticated) {
        redirectToLogin();
      }
    }, []);

    return (
      <Switch>
        <Route path={LOGIN_PATHNAME} render={renderLoginComponent} />
        <Route path={LOGOUT_PATHNAME} render={renderLogoutComponent} />
        <Route path={UPDATE_PASSWORD_PATHNAME} render={renderUpdatePasswordComponent} />
        <Route path={FORGOT_PASSWORD_PATHNAME} render={renderResetPasswordComponent} />

        <Route path={DEFAULT_ROUTE} render={renderLoggedInComponent} />
      </Switch>
    );
  };

  WithAuth.propTypes = {};

  return compose(withRouter)(WithAuth);
}

export default withAuthHoc;
