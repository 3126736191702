import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _flatten from 'lodash/flatten';
import _keyBy from 'lodash/keyBy';
import _uniq from 'lodash/uniq';
import _castArray from 'lodash/castArray';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { getPreSignedUrls } from '../actions/media.actions';
import { getSimpleFieldNameFromColumn } from '../utils';
import { getHtmlMediaIds, getResolvedMediaTemplate } from '../utils/template.utils';

import FIELD_TYPES from '../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../constants/fieldDefinition.dataTypes';
import fieldDefinitionReader from '../readers/fieldDefinition.reader';

const getAllMediaIdsFromValues = (fieldDefinitionsByName, formValues, mediaIds = EMPTY_ARRAY) => {
  _forEach(formValues, (value, key) => {
    const fieldDef = _get(fieldDefinitionsByName, getSimpleFieldNameFromColumn(key), EMPTY_OBJECT);
    if (fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.RICH_TEXT_EDITOR) {
      const newMediaIds = getHtmlMediaIds(value);
      mediaIds.push(newMediaIds);
    }
    if (fieldDefinitionReader.dataType(fieldDef) === DATA_TYPES.COMPLEX) {
      const newFieldDefinitionsByName = fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef);
      getAllMediaIdsFromValues(newFieldDefinitionsByName, value, mediaIds);
    }
  });
  return mediaIds;
};

const getModifiedRichTextEditorFieldValues = (fieldDefinitionsByName, formValues, mediaDataById) => {
  let newFormValues = { ...formValues };
  _forEach(formValues, (value, key) => {
    const fieldDef = _get(fieldDefinitionsByName, getSimpleFieldNameFromColumn(key), EMPTY_OBJECT);
    if (fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.RICH_TEXT_EDITOR) {
      newFormValues = { ...newFormValues, [key]: getResolvedMediaTemplate(value, mediaDataById) };
    }
    if (fieldDefinitionReader.dataType(fieldDef) === DATA_TYPES.COMPLEX) {
      const newFieldDefinitionsByName = fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef);
      newFormValues = { ...newFormValues, [key]: getModifiedRichTextEditorFieldValues(newFieldDefinitionsByName, value, mediaDataById) };
    }
  });

  return newFormValues;
};

const getResolvedRichTextEditorFieldRecords = async (entityDefinition, records) => {
  let mediaIds = [];
  _map(_castArray(records), (record) => {
    const entity = _get(record, 'entity', EMPTY_OBJECT);
    const recordWithoutEntity = { ..._omit(record, ['entity']) };
    getAllMediaIdsFromValues(_keyBy(_get(entityDefinition, 'fieldDefinitions', EMPTY_ARRAY), 'name'), entity, mediaIds);
    getAllMediaIdsFromValues(_keyBy(_get(entityDefinition, 'fieldDefinitions', EMPTY_ARRAY), 'name'), recordWithoutEntity, mediaIds);
    const newMediaIds = _flatten(mediaIds);
    mediaIds = newMediaIds;
  });

  if (_isEmpty(mediaIds)) return _castArray(records);

  const mediaData = await getPreSignedUrls(_uniq(mediaIds));
  const mediaDataById = _keyBy(mediaData, 'mediaId');

  return _map(_castArray(records), (record) => {
    let newRecord = { ...record };
    let entity = _get(record, 'entity', EMPTY_OBJECT);
    let recordWithoutEntity = { ..._omit(record, ['entity']) };

    entity = getModifiedRichTextEditorFieldValues(_keyBy(_get(entityDefinition, 'fieldDefinitions', EMPTY_ARRAY), 'name'), entity, mediaDataById);
    recordWithoutEntity = getModifiedRichTextEditorFieldValues(
      _keyBy(_get(entityDefinition, 'fieldDefinitions', EMPTY_ARRAY), 'name'),
      recordWithoutEntity,
      mediaDataById,
    );
    newRecord = { ...recordWithoutEntity, entity };
    return newRecord;
  });
};

export { getResolvedRichTextEditorFieldRecords };
