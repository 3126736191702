exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".rightPaneViewAttributeRenderer_rightPaneViewAttributeRenderer__jmErRRxaW9{min-width:10rem}[dir] .rightPaneViewAttributeRenderer_rightPaneViewAttributeRenderer__jmErRRxaW9{padding:.1rem}[dir=ltr] .rightPaneViewAttributeRenderer_rightPaneViewAttributeRenderer__jmErRRxaW9{margin:0 0 1rem .5rem}[dir=rtl] .rightPaneViewAttributeRenderer_rightPaneViewAttributeRenderer__jmErRRxaW9{margin:0 .5rem 1rem 0}.rightPaneViewAttributeRenderer_key__ugTEsfgaQh{display:flex}.rightPaneViewAttributeRenderer_label__bMGwJQKVq9{display:flex;align-items:center}[dir] .rightPaneViewAttributeRenderer_label__bMGwJQKVq9{margin-bottom:.05rem}.rightPaneViewAttributeRenderer_value__8UmefoPVh1{font-size:small;font-family:\"Proxima-Nova-Bold\"}", ""]);

// Exports
exports.locals = {
	"rightPaneViewAttributeRenderer": "rightPaneViewAttributeRenderer_rightPaneViewAttributeRenderer__jmErRRxaW9",
	"key": "rightPaneViewAttributeRenderer_key__ugTEsfgaQh",
	"label": "rightPaneViewAttributeRenderer_label__bMGwJQKVq9",
	"value": "rightPaneViewAttributeRenderer_value__8UmefoPVh1"
};