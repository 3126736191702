import { RULE_FIELD_IDS, PROCESS_FIELD_IDS } from './workflow.fieldIds';

const WORKFLOW_SECTIONS = [
  {
    rows: [
      {
        columns: [PROCESS_FIELD_IDS.DISPLAY_NAME],
      },
      {
        columns: [PROCESS_FIELD_IDS.ENTITY, PROCESS_FIELD_IDS.EVENT_TYPE],
      },
      {
        columns: [PROCESS_FIELD_IDS.DESCRIPTION],
      },
    ],
  },
];

const RULE_SECTIONS = [
  {
    rows: [
      {
        columns: [RULE_FIELD_IDS.DISPLAY_NAME],
      },
      {
        columns: [RULE_FIELD_IDS.ENTITY, RULE_FIELD_IDS.EVENT_TYPE],
      },
      {
        columns: [RULE_FIELD_IDS.PRIORITY],
      },
      {
        columns: [RULE_FIELD_IDS.DESCRIPTION],
      },
    ],
  },
];

export { WORKFLOW_SECTIONS, RULE_SECTIONS };
