import React from 'react';

import _get from 'lodash/get';
import _head from 'lodash/head';
import _toNumber from 'lodash/toNumber';
import _capitalize from 'lodash/capitalize';
import _replace from 'lodash/replace';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { FORM_MODES } from '../../../../../constants/general.constants';
import InfoBadge from '../../../../../atoms/infoBadge';
import { FORM_FIELDS } from '../constants/fieldsForm.fields';
import FIELD_IDS from '../constants/fieldsForm.fieldIds';
import { HELP_TEXT, HELP_TEXT_ID, DATA_TYPES, FIELD_TYPES } from '../constants/fieldsForm.constants';
import entityReader from '../../../../../readers/entity.reader';

const getDisabledField = (fieldId) => addToRenderOptions(FORM_FIELDS[fieldId], [{ path: 'disabled', value: true }]);

const getInfoBadge = (fieldId, path = '', helpText = '') => {
  const label = _get(FORM_FIELDS[fieldId], `renderOptions.${path}`, '');
  return <InfoBadge label={label} helpText={helpText} />;
};

const getPropertyFieldsWithConstraints = ({ formValues, formMode, entity, dataType }) => {
  switch (formMode) {
    case FORM_MODES.CREATE: {
      let detailsFormFields = {};

      const uniqueFieldValue = _get(formValues, FIELD_IDS.UNIQUE_CONSTRAINT, false);
      const editableFieldValue = _get(formValues, FIELD_IDS.EDITABLE, false);
      const sortableFieldValue = _get(formValues, FIELD_IDS.SORTABLE, false);
      const textMaxLength = _toNumber(_get(formValues, FIELD_IDS.TEXT_MAX_LENGTH, ''));
      const isEntitySearchEnable = entityReader.searchEnabled(entity);
      const allowLookup = _get(formValues, FIELD_IDS.ALLOW_LOOKUP_USING_FIELD, false);
      const fieldType = _head(_get(formValues, FIELD_IDS.FIELD_TYPE, EMPTY_STRING));
      const multiValued = _get(formValues, FIELD_IDS.MULTI_VALUED_ENABLED, false);

      if (fieldType === FIELD_TYPES.RELATIONSHIP) {
        detailsFormFields = {
          ...detailsFormFields,
          [FIELD_IDS.SEARCHABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.SEARCHABLE], [
            { path: 'disabled', value: getArraySafeValue(_get(formValues, FIELD_IDS.LOOKUP_ENTITY)) === _get(entity, 'name') },
          ]),
        };
      }

      if (dataType === DATA_TYPES.NUMBER) {
        detailsFormFields = {
          ...detailsFormFields,
          [FIELD_IDS.SEARCHABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.SEARCHABLE], [
            {
              path: 'checkboxLabel',
              value: getInfoBadge(FIELD_IDS.SEARCHABLE, 'checkboxLabel', HELP_TEXT[HELP_TEXT_ID.NUMBER_SEARCH_DISABLED]),
            },
            { path: 'disabled', value: true },
          ]),
        };
      }

      if (dataType === DATA_TYPES.BOOLEAN || dataType === DATA_TYPES.COMPLEX) {
        detailsFormFields = {
          ...detailsFormFields,
          [FIELD_IDS.UNIQUE_CONSTRAINT]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.UNIQUE_CONSTRAINT], [
            {
              path: 'checkboxLabel',
              value: getInfoBadge(
                FIELD_IDS.UNIQUE_CONSTRAINT,
                'checkboxLabel',
                _capitalize(_replace(dataType, '_', ' ')) + HELP_TEXT[HELP_TEXT_ID.UNIQUE_DISABLED],
              ),
            },
            { path: 'disabled', value: true },
          ]),
        };
      }
      if (
        fieldType === FIELD_TYPES.LIST ||
        fieldType === FIELD_TYPES.SELECT ||
        fieldType === FIELD_TYPES.RANGE ||
        fieldType === FIELD_TYPES.RICH_TEXT_EDITOR ||
        multiValued
      ) {
        detailsFormFields = {
          ...detailsFormFields,
          [FIELD_IDS.UNIQUE_CONSTRAINT]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.UNIQUE_CONSTRAINT], [
            {
              path: 'checkboxLabel',
              value: getInfoBadge(
                FIELD_IDS.UNIQUE_CONSTRAINT,
                'checkboxLabel',
                _capitalize(_replace(fieldType, '_', ' ')) + HELP_TEXT[HELP_TEXT_ID.UNIQUE_DISABLED],
              ),
            },
            { path: 'disabled', value: true },
          ]),
        };
      }
      if (fieldType === FIELD_TYPES.RICH_TEXT_EDITOR) {
        detailsFormFields = {
          ...detailsFormFields,
          [FIELD_IDS.FILTERABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.FILTERABLE], [
            { path: 'disabled', value: true },
            {
              path: 'checkboxLabel',
              value: getInfoBadge(FIELD_IDS.FILTERABLE, 'checkboxLabel', HELP_TEXT[HELP_TEXT_ID.RICH_TEXT_EDITOR_FIELD_FILTERABLE]),
            },
          ]),
          [FIELD_IDS.SORTABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.SORTABLE], [
            { path: 'disabled', value: true },
            {
              path: 'checkboxLabel',
              value: getInfoBadge(FIELD_IDS.SORTABLE, 'checkboxLabel', HELP_TEXT[HELP_TEXT_ID.RICH_TEXT_EDITOR_FIELD_SORTABLE]),
            },
          ]),
        };
      }
      if (uniqueFieldValue) {
        detailsFormFields = {
          ...detailsFormFields,
          [FIELD_IDS.MANDATORY]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.MANDATORY], [
            { path: 'disabled', value: true },
            {
              path: 'checkboxLabel',
              value: getInfoBadge(FIELD_IDS.MANDATORY, 'checkboxLabel', HELP_TEXT[HELP_TEXT_ID.UNIQUE_MANDATORY]),
            },
          ]),
        };
      }
      if (editableFieldValue) {
        detailsFormFields = {
          ...detailsFormFields,
          [FIELD_IDS.CREATABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CREATABLE], [
            { path: 'disabled', value: true },
            {
              path: 'checkboxLabel',
              value: getInfoBadge(FIELD_IDS.CREATABLE, 'checkboxLabel', HELP_TEXT[HELP_TEXT_ID.CREATABLE_EDITABLE]),
            },
          ]),
        };
      }
      if (sortableFieldValue) {
        detailsFormFields = {
          ...detailsFormFields,
          [FIELD_IDS.FILTERABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.FILTERABLE], [
            { path: 'disabled', value: true },
            {
              path: 'checkboxLabel',
              value: getInfoBadge(FIELD_IDS.FILTERABLE, 'checkboxLabel', HELP_TEXT[HELP_TEXT_ID.SORTABLE_FILTERABLE]),
            },
          ]),
        };
      }
      if (textMaxLength > 256) {
        detailsFormFields = {
          ...detailsFormFields,
          [FIELD_IDS.FILTERABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.FILTERABLE], [
            {
              path: 'checkboxLabel',
              value: getInfoBadge(FIELD_IDS.FILTERABLE, 'checkboxLabel', HELP_TEXT[HELP_TEXT_ID.TEXT_MAX_LENGTH_FILTERABLE]),
            },
            { path: 'disabled', value: true },
          ]),
          [FIELD_IDS.SORTABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.SORTABLE], [
            {
              path: 'checkboxLabel',
              value: getInfoBadge(FIELD_IDS.SORTABLE, 'checkboxLabel', HELP_TEXT[HELP_TEXT_ID.TEXT_MAX_LENGTH_SORTABLE]),
            },
            { path: 'disabled', value: true },
          ]),
          [FIELD_IDS.ALLOW_LOOKUP_USING_FIELD]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.ALLOW_LOOKUP_USING_FIELD], [
            {
              path: 'checkboxLabel',
              value: getInfoBadge(FIELD_IDS.ALLOW_LOOKUP_USING_FIELD, 'checkboxLabel', HELP_TEXT[HELP_TEXT_ID.ALLOW_LOOKUP_DISABLED]),
            },
            { path: 'disabled', value: true },
          ]),
        };
      }
      if (!isEntitySearchEnable) {
        detailsFormFields = {
          ...detailsFormFields,
          [FIELD_IDS.SEARCHABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.SEARCHABLE], [
            {
              path: 'checkboxLabel',
              value: getInfoBadge(FIELD_IDS.SEARCHABLE, 'checkboxLabel', HELP_TEXT[HELP_TEXT_ID.SEARCH_DISABLED]),
            },
            { path: 'disabled', value: true },
          ]),
        };
      }
      if (allowLookup) {
        detailsFormFields = {
          ...detailsFormFields,
          [FIELD_IDS.UNIQUE_CONSTRAINT]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.UNIQUE_CONSTRAINT], [
            { path: 'disabled', value: true },
            {
              path: 'checkboxLabel',
              value: getInfoBadge(FIELD_IDS.UNIQUE_CONSTRAINT, 'checkboxLabel', HELP_TEXT[HELP_TEXT_ID.ALLOW_LOOKUP_ENABLED]),
            },
          ]),
          [FIELD_IDS.EXPORTABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.EXPORTABLE], [
            { path: 'disabled', value: true },
            {
              path: 'checkboxLabel',
              value: getInfoBadge(FIELD_IDS.EXPORTABLE, 'checkboxLabel', HELP_TEXT[HELP_TEXT_ID.ALLOW_LOOKUP_ENABLED]),
            },
          ]),
          [FIELD_IDS.FILTERABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.FILTERABLE], [
            { path: 'disabled', value: true },
            {
              path: 'checkboxLabel',
              value: getInfoBadge(FIELD_IDS.FILTERABLE, 'checkboxLabel', HELP_TEXT[HELP_TEXT_ID.ALLOW_LOOKUP_ENABLED]),
            },
          ]),
        };
      }
      return detailsFormFields;
    }

    case FORM_MODES.EDIT: {
      return {
        [FIELD_IDS.DETAILED_VIEW_ENABLED]: getDisabledField(FIELD_IDS.DETAILED_VIEW_ENABLED),
        [FIELD_IDS.LIST_VIEW_ENABLED]: getDisabledField(FIELD_IDS.LIST_VIEW_ENABLED),
        [FIELD_IDS.EDITABLE]: getDisabledField(FIELD_IDS.EDITABLE),
        [FIELD_IDS.SEARCHABLE]: getDisabledField(FIELD_IDS.SEARCHABLE),
        [FIELD_IDS.FILTERABLE]: getDisabledField(FIELD_IDS.FILTERABLE),
        [FIELD_IDS.SORTABLE]: getDisabledField(FIELD_IDS.SORTABLE),
        [FIELD_IDS.DISABLED]: getDisabledField(FIELD_IDS.DISABLED),
        [FIELD_IDS.LIST_ADD_ROW_BUTTON]: getDisabledField(FIELD_IDS.LIST_ADD_ROW_BUTTON),
        [FIELD_IDS.ALLOW_LOOKUP_USING_FIELD]: getDisabledField(FIELD_IDS.ALLOW_LOOKUP_USING_FIELD),
        [FIELD_IDS.UNIQUE_CONSTRAINT]: getDisabledField(FIELD_IDS.UNIQUE_CONSTRAINT),
      };
    }
    default:
      return EMPTY_OBJECT;
  }
};

export { getPropertyFieldsWithConstraints };
