import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import MultiSelectField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/MultiSelectField';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import { tget } from '@tekion/tekion-base/utils/general';

import SortFieldsTable from '../components/sortFieldsTable/SortFieldsTable';

import { FIELD_IDS } from '../constants/sortOptionsConfigurator.constants';

import styles from '../sortOptionsConfigurator.module.scss';

const SORTABLE_FIELDS = {
  id: FIELD_IDS.SORTABLE_FIELDS,
  renderer: MultiSelectField,
  renderOptions: {
    label: __('Sortable Fields'),
  },
};

const SORT_DETAILS_ERROR_FIELD = {
  id: FIELD_IDS.ERROR_MESSAGE,
  renderer: FieldLabel,
  renderOptions: {
    labelClassName: styles.codePanelErrorLabel,
    fieldLabelClassName: styles.codePanelErrorFieldLabel,
  },
};

const DEFAULT_SORT_DETAILS_FIELD = {
  id: FIELD_IDS.DEFAULT_SORT_DETAILS,
  renderer: SortFieldsTable,
  renderOptions: {
    label: __('Default Sort Details'),
  },
};

const SORT_OPTIONS_FORM_FIELDS = {
  [FIELD_IDS.SORTABLE_FIELDS]: SORTABLE_FIELDS,
  [FIELD_IDS.ERROR_MESSAGE]: SORT_DETAILS_ERROR_FIELD,
  [FIELD_IDS.DEFAULT_SORT_DETAILS]: DEFAULT_SORT_DETAILS_FIELD,
};

const getFields = (sortableFieldOptions, selectedSortableFieldOptions, defaultSortDetails, error) => ({
  ...SORT_OPTIONS_FORM_FIELDS,
  [FIELD_IDS.SORTABLE_FIELDS]: addToRenderOptions(SORT_OPTIONS_FORM_FIELDS[FIELD_IDS.SORTABLE_FIELDS], [
    { path: 'options', value: sortableFieldOptions },
  ]),
  [FIELD_IDS.ERROR_MESSAGE]: addToRenderOptions(SORT_OPTIONS_FORM_FIELDS[FIELD_IDS.ERROR_MESSAGE], [
    { path: 'label', value: __(tget(error, [FIELD_IDS.DEFAULT_SORT_DETAILS])) },
  ]),
  [FIELD_IDS.DEFAULT_SORT_DETAILS]: addToRenderOptions(SORT_OPTIONS_FORM_FIELDS[FIELD_IDS.DEFAULT_SORT_DETAILS], [
    { path: 'data', value: defaultSortDetails },
    { path: 'sortableFieldOptions', value: selectedSortableFieldOptions },
  ]),
});

export default getFields;
