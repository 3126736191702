import _get from 'lodash/get';
import _set from 'lodash/set';
import _omitBy from 'lodash/omitBy';
import _isEmpty from 'lodash/isEmpty';
import _pickBy from 'lodash/pickBy';
import _unset from 'lodash/unset';
import _pick from 'lodash/pick';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _cloneDeep from 'lodash/cloneDeep';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';

import { isErrorEmpty } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { createField, updateField, fetchEntityDefByName } from '../../../../../../actions/entityManagement.actions';

import { getPayloadForSection, getInitialState } from './fieldsForm.general.helpers';

import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import FIELDS_FORM_FIELD_IDS from '../constants/fieldsForm.fieldIds';
import ACTION_TYPES from '../constants/fieldsForm.actionTypes';
import PROPERTIES_FORM_FIELD_IDS from '../components/propertiesForm/constants/propertiesForm.fieldIds';
import { FIELD_FORM_CONTEXT_ID, SUBMIT_ADDITIONAL_VALUE } from '../constants/fieldsForm.constants';
import SELECT_OPTIONS_FORM_FIELD_IDS from '../components/selectOptionsForm/constants/selectOptionsForm.fieldsIds';
import entityReader from '../../../../../../readers/entity.reader';
import fieldDefinitionReader from '../../../../../../readers/fieldDefinition.reader';

const handleInitForm = async ({ setState, getState }) => {
  setState({ isFetching: true });

  const { cachedEntityDefs = EMPTY_OBJECT, history = EMPTY_OBJECT, match = EMPTY_OBJECT } = getState();
  const { entityName, fieldName } = _get(match, 'params', EMPTY_OBJECT);
  let fieldData = _get(history, 'location.state.fieldData', EMPTY_OBJECT);

  let entity = _get(history, 'location.state.entity');

  if (_isEmpty(entity)) {
    entity = await fetchEntityDefByName(entityName);
    fieldData = _find(entityReader.fieldDefinitions(entity), (fieldDef) => fieldDefinitionReader.name(fieldDef) === fieldName);
  }

  const formMode = _isEmpty(fieldName) ? FORM_MODES.CREATE : FORM_MODES.EDIT;

  if (formMode === FORM_MODES.EDIT) {
    const initialState = getInitialState(fieldData);
    const formValues = _cloneDeep(initialState);

    setState({
      isFormDisabled: _get(fieldData, 'name') !== 'name' && _get(fieldData, 'fieldDefType') === 'SYSTEM',
      isFetching: false,
      initialState,
      formValues,
      formMode,
      cachedEntityDefs: {
        ...cachedEntityDefs,
        [entityName]: entity,
      },
    });
  } else {
    const initialState = {
      [FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM]: {
        [PROPERTIES_FORM_FIELD_IDS.EXPORTABLE]: true,
        [PROPERTIES_FORM_FIELD_IDS.IMPORTABLE]: true,
        [PROPERTIES_FORM_FIELD_IDS.EDITABLE]: true,
        [PROPERTIES_FORM_FIELD_IDS.CREATABLE]: true,
      },
      [FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM]: {
        [SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS]: [{}],
      },
    };
    const formValues = _cloneDeep(initialState);

    setState({
      isFormDisabled: false,
      isFetching: false,
      isSavingDetails: false,
      isSavingDetailsCreateNew: false,
      initialState,
      errors: undefined,
      formMode,
      cachedEntityDefs: {
        ...cachedEntityDefs,
        [entityName]: entity,
      },
      formValues,
    });
  }
};

const handleSubmit = async ({ getState, setState, params }) => {
  const { formIds, formErrors } = _get(params, 'additional');
  const filteredErrors = _pickBy(formErrors, (value) => !!value);

  if (!_isEmpty(filteredErrors)) {
    return;
  }

  const actionType = tget(params, 'additional.actionType', SUBMIT_ADDITIONAL_VALUE.SAVE);

  if (actionType === SUBMIT_ADDITIONAL_VALUE.SAVE) {
    setState({ isSavingDetails: true });
  }

  if (actionType === SUBMIT_ADDITIONAL_VALUE.SAVE_AND_CREATE_NEW) {
    setState({ isSavingDetailsCreateNew: true });
  }

  const { history, match, formMode, formValues } = getState();

  let payload = {};

  _forEach(formIds, (formId) => {
    const sectionPayload = getPayloadForSection(formId)(formValues);

    payload = {
      ...payload,
      ...sectionPayload,
    };
  });

  const entityName = _get(match, 'params.entityName');

  if (formMode === FORM_MODES.EDIT) {
    const fieldName = _get(match, 'params.fieldName');
    const response = await updateField(entityName, fieldName, payload);

    if (!_isEmpty(response)) {
      const url = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELDS}`;

      setTimeout(() => {
        history.push(url);
      }, ES_REFETCH_DELAY);
    } else {
      setState({ isSavingDetails: false });
    }
  } else {
    const response = await createField(entityName, payload);

    if (!_isEmpty(response)) {
      if (actionType === SUBMIT_ADDITIONAL_VALUE.SAVE) {
        const url = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELDS}`;

        setTimeout(() => {
          history.push(url);
        }, ES_REFETCH_DELAY);
      }

      if (actionType === SUBMIT_ADDITIONAL_VALUE.SAVE_AND_CREATE_NEW) {
        _unset(history, 'location.state.entity');
      }

      handleInitForm({ getState, setState });
    } else {
      if (actionType === SUBMIT_ADDITIONAL_VALUE.SAVE) {
        setState({ isSavingDetails: false });
      }

      if (actionType === SUBMIT_ADDITIONAL_VALUE.SAVE_AND_CREATE_NEW) {
        setState({ isSavingDetailsCreateNew: false });
      }
    }
  }
};

const handleOnCancel = ({ setState, getState }) => {
  const { history, match } = getState();
  const entityName = _get(match, 'params.entityName');

  setState({ formValues: {} });
  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELDS}`);
};

const handleFieldChange = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { formValues = EMPTY_OBJECT } = getState();

  setState({ formValues: { ...formValues, [id]: value } });
};

const handleValidationSuccess = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { errors: oldErrors = EMPTY_OBJECT } = getState();
  const { errors, id, validationTriggeredOnSubmit = false } = params;

  const newErrors = { ...oldErrors };

  if (id !== FIELD_FORM_CONTEXT_ID && id !== undefined) {
    _set(newErrors, id, errors);
  }

  const formIds = _get(params, 'additional.formIds');

  let filteredErrors = newErrors;

  if (!_isEmpty(formIds)) {
    filteredErrors = _omitBy(_pick(newErrors, formIds), (value) => _isEmpty(value));
  }

  const hasError = !_isEmpty(filteredErrors) && !isErrorEmpty(filteredErrors);

  setState({ errors: hasError ? filteredErrors : undefined }, () => {
    if (validationTriggeredOnSubmit && !hasError) {
      handleSubmit({ setState, getState, params });
    }
  });
};

const handleEntityDefChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { entityName, entityDef } = params;
  const { cachedEntityDefs = EMPTY_OBJECT } = getState();
  setState({ cachedEntityDefs: { ...cachedEntityDefs, [entityName]: entityDef } });
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleInitForm,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidationSuccess,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_ENTITY_DEF_CHANGE]: handleEntityDefChange,
  [ACTION_TYPES.ON_CANCEL]: handleOnCancel,
};

export default ACTION_HANDLERS;
