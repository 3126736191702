import ApiService from './apiService/apiService';

const TEMPLATE_BASE_URL = '/core/u/template/templates';

const createTemplate = (payload) => ApiService.post(TEMPLATE_BASE_URL, payload);

const getTemplateByName = (templateName) => ApiService.get(`${TEMPLATE_BASE_URL}/name/${templateName}`);

const updateTemplate = (templateName, payload) => ApiService.put(`${TEMPLATE_BASE_URL}/name/${templateName}`, payload);

const deleteTemplate = (templateName) => ApiService.delete(`${TEMPLATE_BASE_URL}/name/${templateName}`);

const searchTemplate = (payload) => ApiService.post(`${TEMPLATE_BASE_URL}/search`, payload);

export default { createTemplate, getTemplateByName, updateTemplate, deleteTemplate, searchTemplate };
