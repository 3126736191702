import _size from 'lodash/size';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { AUTHENTICATOR_TYPES } from '../../LoginPage.constants';

const getAuthenticatorType = (mfaAuthTypes) => {
  if (_size(mfaAuthTypes) === 2) {
    return AUTHENTICATOR_TYPES.SMS_AND_EMAIL;
  }

  return getArraySafeValue(mfaAuthTypes);
};

export { getAuthenticatorType };
