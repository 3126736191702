import _isNil from 'lodash/isNil';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { toMoment, getFormattedDateTime, DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';

const convertTimeStamp = (time) => {
  if (_isNil(time)) return EMPTY_STRING;
  const exportMoment = toMoment(time);
  const formattedTime = getFormattedDateTime(exportMoment, DATE_TIME_FORMATS.DATE_ALPHA_MONTH_WITH_TIME);
  return formattedTime;
};

export { convertTimeStamp };
