import React from 'react';
import PropTypes from 'prop-types';
import { useSelected } from 'slate-react';
import { getAbove } from '@udecode/plate-common';
import { ELEMENT_TR } from '@udecode/plate-table';
import { getPlatePluginType } from '@udecode/plate-core';

// constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// styles
import COLORS from '@tekion/tekion-styles-next/scss/variables.scss';

// components
import TableCellResizer from './molecules/tableCellResizer';
import TableCellPopover from './organisms/tableCellPopover';

// hooks
import useEditor from '../../../hooks/useEditor';

const renderCellContent = ({ children, element, showTableCellPopover, enableTableResizing, cellBackgroundColor }) => (
  <>
    <div style={{ padding: '0.4rem 3.6rem 0.4rem 0.4rem', position: 'relative' }}>{children}</div>
    {showTableCellPopover && <TableCellPopover element={element} cellBackgroundColor={cellBackgroundColor} />}
    {enableTableResizing && <TableCellResizer element={element} />}
  </>
);

function TableCell(props) {
  const { attributes, children, element, nodeProps, isHeader } = props;
  const { enableTableResizing, colSpan, rowSpan } = nodeProps;
  const selected = useSelected();
  const editor = useEditor();
  const currentRowItem = getAbove(editor, {
    match: { type: getPlatePluginType(editor, ELEMENT_TR) },
  });
  const { backgroundColor } = element;
  // currentRowItem check is to check if only one cell is selected. TODO: find other approach through selection
  const showTableCellPopover = !!currentRowItem && selected;

  if (isHeader) {
    const thBackgroundColor = backgroundColor || COLORS.glitter;
    return (
      <th
        {...attributes}
        style={{
          backgroundColor: thBackgroundColor,
          verticalAlign: 'top',
          border: '0.1rem solid black',
          backgroundClip: 'padding-box',
          position: 'relative',
        }}
        colSpan={colSpan}
        rowSpan={rowSpan}
      >
        {renderCellContent({
          children,
          element,
          showTableCellPopover,
          enableTableResizing,
          cellBackgroundColor: thBackgroundColor,
        })}
      </th>
    );
  }

  const tdBackgroundColor = backgroundColor || COLORS.white;
  return (
    <td
      {...attributes}
      style={{
        backgroundColor: tdBackgroundColor,
        verticalAlign: 'top',
        border: '0.1rem solid black',
        backgroundClip: 'padding-box',
        position: 'relative',
      }}
      colSpan={colSpan}
      rowSpan={rowSpan}
    >
      {renderCellContent({
        children,
        element,
        showTableCellPopover,
        enableTableResizing,
        cellBackgroundColor: tdBackgroundColor,
      })}
    </td>
  );
}

TableCell.propTypes = {
  attributes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  element: PropTypes.object.isRequired,
  nodeProps: PropTypes.object,
  isHeader: PropTypes.bool,
};

TableCell.defaultProps = {
  nodeProps: EMPTY_OBJECT,
  isHeader: false,
};

export default React.memo(TableCell);
