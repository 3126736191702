import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

const ACTION_TYPES = {
  ON_INIT: 'ON_INIT',
  ON_SETTING_FIELD_CHANGE: 'ON_SETTING_FIELD_CHANGE',
  ON_SETTING_FORM_BLUR: 'ON_SETTING_FORM_BLUR',
  ON_RECORD_FORM_FIELD_BLUR: 'ON_RECORD_FORM_FIELD_BLUR',
  ON_SUBMIT_CLICK: 'ON_SUBMIT_CLICK',
  ON_SETTING_FORM_SUBMIT: 'ON_SETTING_FORM_SUBMIT',
  ON_RECORD_FORM_SUBMIT: 'ON_RECORD_FORM_SUBMIT',
};

const CONTEXT_IDS = {
  IDENTIFIER: 'IDENTIFIER_FORM',
  DATA: 'DATA_FORM',
  ENTITY_RECORD: 'ENTITY_RECORD_FORM',
};

const INITIAL_STATE = {
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export { ACTION_TYPES, CONTEXT_IDS, INITIAL_STATE };
