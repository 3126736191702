import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import _includes from 'lodash/includes';

import { tget } from '@tekion/tekion-base/utils/general';

// Pages
import WorkflowBuilder from './pages/workflowBuilder/WorkflowBuilder';
import WorkflowBuilderForm from './pages/workflowBuilderForm/WorkflowBuilderForm';

import { fetchProcessTaskDefinitions, fetchProcessGlobalMetadata } from '../../../../../actions/processBuilder.actions';
import { fetchRuleGlobalMetadata, fetchRuleTaskDefinitions } from '../../../../../actions/ruleBuilder.actions';
import getEventTypesFromMetadata from './utils/getEventTypesFromMetadata';

// Constants
import ROUTES from '../../../constants/routes';
import { WORKFLOW_BUILDER_MODE } from '../../../../../constants/general.constants';
import PAGE_IDS from '../../../constants/PageIds.constants';

const WorkflowBuilderEntryPage = ({ match }) => {
  const [isGlobalMetadataLoading, setIsGlobalMetadataLoading] = useState(true);
  const [isStepsLoading, setIsStepsLoading] = useState(true);
  const [globalMetadata, setGlobalMetadata] = useState([]);
  const [steps, setSteps] = useState([]);

  const workflowMode = useMemo(
    () =>
      _includes(tget(match, 'path'), PAGE_IDS.RULE_CREATE) || _includes(tget(match, 'path'), PAGE_IDS.RULE_EDIT)
        ? WORKFLOW_BUILDER_MODE.RULE
        : WORKFLOW_BUILDER_MODE.PROCESS,
    [match],
  );

  const loadGlobalMetadata = useCallback(async () => {
    setIsGlobalMetadataLoading(true);
    let _globalMetadata = {};

    if (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS) {
      _globalMetadata = await fetchProcessGlobalMetadata();
    } else if (workflowMode === WORKFLOW_BUILDER_MODE.RULE) {
      _globalMetadata = await fetchRuleGlobalMetadata();
    }

    setGlobalMetadata(_globalMetadata);
    setIsGlobalMetadataLoading(false);
  }, [workflowMode]);

  const loadSteps = useCallback(async () => {
    setIsStepsLoading(true);
    let _steps = {};
    if (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS) {
      _steps = await fetchProcessTaskDefinitions();
    } else if (workflowMode === WORKFLOW_BUILDER_MODE.RULE) {
      _steps = await fetchRuleTaskDefinitions();
    }

    setSteps(_steps);
    setIsStepsLoading(false);
  }, [workflowMode]);

  const eventTypes = useMemo(() => getEventTypesFromMetadata(globalMetadata), [globalMetadata]);

  useEffect(() => {
    // setIsGlobalMetadataLoading(true);
    // const _globalMetadata = _map(EVENT_TYPES, (eventType) => ({
    //   ...eventType,
    //   label: eventType.displayName,
    //   value: eventType.id,
    // }));
    // setGlobalMetadata(_globalMetadata);
    // setIsGlobalMetadataLoading(false);
    loadGlobalMetadata();
    loadSteps();
  }, [loadGlobalMetadata, loadSteps]);

  return (
    <Switch>
      <Route
        path={ROUTES.RULE_CREATE_FORM_ROUTE}
        render={() => <WorkflowBuilderForm isEventTypesLoading={isGlobalMetadataLoading} workflowMode={workflowMode} eventTypes={eventTypes} />}
      />
      <Route
        path={ROUTES.PROCESS_CREATE_FORM_ROUTE}
        render={() => <WorkflowBuilderForm isEventTypesLoading={isGlobalMetadataLoading} workflowMode={workflowMode} eventTypes={eventTypes} />}
      />
      <Route
        path={ROUTES.RULE_CREATE_ROUTE}
        render={() => (
          <WorkflowBuilder
            isEventTypesLoading={isGlobalMetadataLoading}
            isStepsLoading={isStepsLoading}
            workflowMode={workflowMode}
            eventTypes={eventTypes}
            steps={steps}
          />
        )}
      />
      <Route
        path={ROUTES.PROCESS_CREATE_ROUTE}
        render={() => (
          <WorkflowBuilder
            isEventTypesLoading={isGlobalMetadataLoading}
            isStepsLoading={isStepsLoading}
            workflowMode={workflowMode}
            eventTypes={eventTypes}
            steps={steps}
          />
        )}
      />
      <Route
        path={ROUTES.RULE_EDIT_ROUTE}
        render={() => (
          <WorkflowBuilder
            isEventTypesLoading={isGlobalMetadataLoading}
            isStepsLoading={isStepsLoading}
            workflowMode={workflowMode}
            eventTypes={eventTypes}
            steps={steps}
          />
        )}
      />
      <Route
        path={ROUTES.PROCESS_EDIT_ROUTE}
        render={() => (
          <WorkflowBuilder
            isEventTypesLoading={isGlobalMetadataLoading}
            isStepsLoading={isStepsLoading}
            workflowMode={workflowMode}
            eventTypes={eventTypes}
            steps={steps}
          />
        )}
      />
    </Switch>
  );
};

WorkflowBuilderEntryPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default WorkflowBuilderEntryPage;
