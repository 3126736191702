import _get from 'lodash/get';
import _compact from 'lodash/compact';
import _head from 'lodash/head';
import _size from 'lodash/size';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _castArray from 'lodash/castArray';
import _parseInt from 'lodash/parseInt';
import _set from 'lodash/set';
import _has from 'lodash/has';
import _cloneDeep from 'lodash/cloneDeep';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { getFilterMetadataWithFilterableFieldsOfMainEntity, getFilters } from './filterForm.config';
import { countOfEmptyFieldsInPreAppliedFilter } from './filterForm.utils';

import { FIELD_IDS, FIELD_ERRORS } from '../constants/filterForm.constants';
import ACTION_TYPES from '../constants/filterForm.actionTypes';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../../constants/viewBuilder.constants';

import filterReader from '../../../../../readers/filter.reader';

const handleSelectedViewComponentPropertiesChange = ({ getState, setState, params }) => {
  const { entity, onAction: configuratorOnAction } = getState();
  const { selectedViewComponentProperties } = params;
  const fieldDefinitions = tget(entity, 'fieldDefinitions', EMPTY_ARRAY);
  const fieldTypeOptions = getFilters(fieldDefinitions);
  const preAppliedFilters = _get(selectedViewComponentProperties, FIELD_IDS.PRE_APPLIED_FILTERS, EMPTY_ARRAY);
  let filterMetadata = _get(selectedViewComponentProperties, FIELD_IDS.FILTER_METADATA, EMPTY_ARRAY);
  const fieldsConfig = {};
  let filterableFieldTypeOptions = [];
  let isUpfrontFiltersEnabled = false;
  let isPreDefineFiltersEnabled = false;

  if (_isEmpty(filterMetadata)) {
    // If filterMetadata is Empty, then putting all the filterable fields of main entity in it as selected fields and saving into ViewConfig

    filterMetadata = getFilterMetadataWithFilterableFieldsOfMainEntity(fieldDefinitions);

    const filterFormValues = {
      [FIELD_IDS.FILTER_METADATA]: filterMetadata,
    };

    configuratorOnAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: ALL_VIEW_PROPERTY_KEYS.FILTER_COMPONENT, value: filterFormValues },
    });
  }

  // checking if there are filterable fields and upfront fields present in viewConfig
  if (!_isEmpty(filterMetadata)) {
    _map(filterMetadata, (filterData) => {
      const isUpfront = filterReader.upfront(filterData);
      if (isUpfront) {
        isUpfrontFiltersEnabled = true;
      }
      _set(fieldsConfig, [filterReader.fieldName(filterData), FIELD_IDS.UPFRONT], isUpfront);
    });

    filterableFieldTypeOptions = _filter(fieldTypeOptions, (option) => _has(fieldsConfig, [_get(option, 'value')]));
  }

  /// checking if there are preAppliedfilters present in viewConfig and then setting those filter fieldNames as disabled:true in fieldTypeOptions
  if (!_isEmpty(preAppliedFilters)) {
    isPreDefineFiltersEnabled = true;
    _map(preAppliedFilters, (filterData) => {
      _set(fieldsConfig, [filterReader.field(filterData), FIELD_IDS.DISABLED], true);
    });
  }

  setState({
    isUpfrontFiltersEnabled,
    isPreDefineFiltersEnabled,
    fieldsConfig,
    fieldTypeOptions,
    filterableFieldTypeOptions,
    preAppliedFilters,
    filterMetadata,
    errors: [],
  });
};

const handleAddRow = ({ getState, setState }) => {
  const { isPreDefineFiltersEnabled, preAppliedFilters = EMPTY_ARRAY, fieldsConfig = EMPTY_OBJECT } = getState();

  if (!isPreDefineFiltersEnabled || _size(preAppliedFilters) === _size(fieldsConfig)) {
    return;
  }

  setState({
    preAppliedFilters: [...preAppliedFilters, {}],
  });
};

const handleDeleteRow = ({ getState, setState, params }) => {
  const { index } = params;
  const { preAppliedFilters = EMPTY_ARRAY, fieldsConfig = EMPTY_OBJECT } = getState();
  const newFieldsConfig = _cloneDeep(fieldsConfig);

  const fieldName = _get(preAppliedFilters, [index, FIELD_IDS.FIELD], '');
  if (!_isEmpty(fieldName)) {
    _set(newFieldsConfig, [fieldName, FIELD_IDS.DISABLED], false);
  }
  const filterList = _filter(preAppliedFilters, (filterRow, rowIndex) => _parseInt(rowIndex) !== _parseInt(index));

  setState({
    fieldsConfig: newFieldsConfig,
    preAppliedFilters: [...filterList],
  });
};

const handleOnChange = ({ getState, setState, params }) => {
  const {
    fieldsConfig = EMPTY_OBJECT,
    fieldTypeOptions = EMPTY_ARRAY,
    preAppliedFilters = EMPTY_ARRAY,
    filterMetadata = EMPTY_ARRAY,
    errors = EMPTY_ARRAY,
  } = getState();

  const { id, value } = params;
  const index = tget(params, 'index');
  let newFieldsConfig = _cloneDeep(fieldsConfig);

  if (id === FIELD_IDS.FILTERABLE_FIELDS) {
    newFieldsConfig = {};

    const filterableFields = _reduce(
      value,
      (prevFilterableFields, fieldName) => {
        if (!_isEmpty(fieldName)) {
          const isUpfront = _get(fieldsConfig, [fieldName, FIELD_IDS.UPFRONT], false);
          _set(newFieldsConfig, [fieldName, FIELD_IDS.UPFRONT], isUpfront);
          prevFilterableFields.push({ [FIELD_IDS.FIELD_NAME]: fieldName, [FIELD_IDS.UPFRONT]: isUpfront });
        }
        return prevFilterableFields;
      },
      [],
    );

    const filterableFieldTypeOptions = _filter(fieldTypeOptions, (option) => _has(newFieldsConfig, [_get(option, 'value')]));

    const newPreAppliedFilters = _filter(preAppliedFilters, (filter) => {
      if (_has(newFieldsConfig, [filterReader.field(filter)])) {
        _set(newFieldsConfig, [filterReader.field(filter), FIELD_IDS.DISABLED], true);
        return true;
      } else if (_isEmpty(filterReader.field(filter))) {
        return true;
      }
      return false;
    });

    setState({
      filterMetadata: filterableFields,
      preAppliedFilters: [...newPreAppliedFilters],
      filterableFieldTypeOptions,
    });
  } else if (id === FIELD_IDS.UPFRONT_FILTER_CHECKBOX) {
    setState({ isUpfrontFiltersEnabled: value });
  } else if (id === FIELD_IDS.PRE_APPLIED_FILTER_CHECKBOX) {
    setState({ isPreDefineFiltersEnabled: value }, () => {
      if (countOfEmptyFieldsInPreAppliedFilter(preAppliedFilters) < 1) {
        handleAddRow({ getState, setState });
      }
    });
  } else if (id === FIELD_IDS.UPFRONT_FILTERS) {
    let newFilterMetadata = _cloneDeep(filterMetadata);
    newFilterMetadata = _map(newFilterMetadata, (filterConfig) => {
      const field = filterReader.fieldName(filterConfig);
      if (_find(value, (fieldName) => fieldName === field)) {
        _set(newFieldsConfig, [field, FIELD_IDS.UPFRONT], true);
        return {
          ...filterConfig,
          [FIELD_IDS.UPFRONT]: true,
        };
      }
      _set(newFieldsConfig, [field, FIELD_IDS.UPFRONT], false);
      return {
        ...filterConfig,
        [FIELD_IDS.UPFRONT]: false,
      };
    });

    setState({
      filterMetadata: newFilterMetadata,
    });
  } else if (id === FIELD_IDS.FIELD || id === FIELD_IDS.FILTER_TYPE || id === FIELD_IDS.VALUES) {
    const newErrors = [...errors];

    const filterList = _cloneDeep(preAppliedFilters);
    const prevFilterRowData = _get(filterList, `${index}`, {});
    const prevErrorRowData = _get(errors, `${index}`, {});
    newErrors[index] = { ...prevErrorRowData, [id]: null };

    if (id === FIELD_IDS.FIELD) {
      // setting previous fieldname as disabled
      const prevFieldName = filterReader.field(prevFilterRowData);
      if (!_isEmpty(prevFieldName)) {
        _set(newFieldsConfig, [prevFieldName, FIELD_IDS.DISABLED], false);
      }
      filterList[index] = { [id]: value };
      _set(newFieldsConfig, [value, FIELD_IDS.DISABLED], true);
    } else if (id === FIELD_IDS.FILTER_TYPE) {
      filterList[index] = { ...prevFilterRowData, [id]: value };
    } else if (id === FIELD_IDS.VALUES) {
      let newValue = value;

      if (_has(value, 'value')) {
        newValue = tget(value, 'value');
      }

      filterList[index] = { ...prevFilterRowData, [id]: _compact(_castArray(newValue)) };
    }
    setState({
      preAppliedFilters: filterList,
      errors: [...newErrors],
    });
  }
  setState({ fieldsConfig: newFieldsConfig });
};

const getPrevState = ({ getState }) => {
  const {
    isUpfrontFiltersEnabled,
    isPreDefineFiltersEnabled,
    preAppliedFilters = EMPTY_ARRAY,
    filterMetadata = EMPTY_ARRAY,
    fieldTypeOptions = EMPTY_ARRAY,
    filterableFieldTypeOptions = EMPTY_ARRAY,
    fieldsConfig = EMPTY_OBJECT,
    errors = EMPTY_ARRAY,
  } = getState();

  return {
    isUpfrontFiltersEnabled,
    isPreDefineFiltersEnabled,
    fieldsConfig: { ...fieldsConfig },
    preAppliedFilters: [...preAppliedFilters],
    filterMetadata: [...filterMetadata],
    fieldTypeOptions: [...fieldTypeOptions],
    filterableFieldTypeOptions: [...filterableFieldTypeOptions],
    errors: [...errors],
  };
};

const handleAddFilterModal = ({ getState, setState }) => {
  const prevState = getPrevState({ getState });

  setState({ isModalVisible: true, prevState: { ...prevState } }, () => handleAddRow({ getState, setState }));
};

const validateFilterForm = (preAppliedFilters) => {
  let hasErrors = false;
  const errors = _reduce(
    preAppliedFilters,
    (prevErrors, filter, index) => {
      const fieldName = _get(filter, FIELD_IDS.FIELD);
      const operatorType = _get(filter, FIELD_IDS.FILTER_TYPE);
      const value = _get(filter, FIELD_IDS.VALUES);
      const newErrors = [...prevErrors];

      if (_isEmpty(fieldName) || _isEmpty(operatorType) || _isEmpty(value) || _head(value) === '') {
        hasErrors = true;
        newErrors[index] = {
          [FIELD_IDS.FIELD]: _isEmpty(fieldName) ? FIELD_ERRORS[FIELD_IDS.FIELD] : null,
          [FIELD_IDS.FILTER_TYPE]: _isEmpty(operatorType) ? FIELD_ERRORS[FIELD_IDS.FILTER_TYPE] : null,
          [FIELD_IDS.VALUES]: _isEmpty(value) || _head(value) === '' ? FIELD_ERRORS[FIELD_IDS.VALUES] : null,
        };
      }
      return newErrors;
    },
    [],
  );
  return { hasErrors, errors };
};

const handleSaveModal = ({ getState, setState }) => {
  const {
    isUpfrontFiltersEnabled,
    isPreDefineFiltersEnabled,
    preAppliedFilters = EMPTY_ARRAY,
    filterMetadata = EMPTY_ARRAY,
    onAction: configuratorOnAction,
  } = getState();

  const { hasErrors, errors } = isPreDefineFiltersEnabled ? validateFilterForm(preAppliedFilters) : { hasErrors: false, errors: EMPTY_ARRAY };
  if (!hasErrors) {
    const preAppliedFiltersPayload = isPreDefineFiltersEnabled ? preAppliedFilters : [];
    const upfrontFiltersPayload = isUpfrontFiltersEnabled
      ? filterMetadata
      : _map(filterMetadata, (data) => ({ ...data, [FIELD_IDS.UPFRONT]: false }));

    const filterFormValues = {
      [FIELD_IDS.PRE_APPLIED_FILTERS]: preAppliedFiltersPayload,
      [FIELD_IDS.FILTER_METADATA]: upfrontFiltersPayload,
    };

    configuratorOnAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: ALL_VIEW_PROPERTY_KEYS.FILTER_COMPONENT, value: filterFormValues },
    });
    setState({ isModalVisible: false, prevState: {} });
  }
  setState({ errors });
};

const handleCancelModal = ({ getState, setState }) => {
  const { prevState = EMPTY_OBJECT } = getState();
  setState({
    ...prevState,
    prevState: {},
    isModalVisible: false,
  });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE]: handleSelectedViewComponentPropertiesChange,
  [ACTION_TYPES.ADD_ROW]: handleAddRow,
  [ACTION_TYPES.DELETE_ROW]: handleDeleteRow,
  [ACTION_TYPES.ON_CLICK_ADD_FILTER]: handleAddFilterModal,
  [ACTION_TYPES.ON_SAVE_MODAL]: handleSaveModal,
  [ACTION_TYPES.ON_CANCEL_MODAL]: handleCancelModal,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnChange,
};

export default ACTION_HANDLERS;
