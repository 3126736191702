import React, { useEffect, useRef } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

// hooks
import useVideoStreaming from './hooks/useVideoStreaming';
import useSignedVideoUrl from './hooks/useSignedVideoUrl/useSignedVideoUrl';

// containers
import withMediaResizing from '../../containers/withMediaResizing';
import withMediaStyling from '../../containers/withMediaStyling';
import withMediaDeletion from '../../containers/withMediaDeletion';

const preventDefaultHandler = (e) => e.preventDefault();

const CONTROLS_LIST_ATTRIBUTES = {
  NO_DOWNLOAD: 'nodownload',
};

function Video(props) {
  const { element, enableVideoControls } = props;
  const { data } = element;
  const { mediaId } = data;
  const videoRef = useRef();
  const { isVideoFragmented, signedVideoUrl } = useSignedVideoUrl(mediaId);
  useVideoStreaming({ videoRef, src: signedVideoUrl });

  useEffect(() => {
    if (enableVideoControls || !videoRef.current) return;
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  }, [enableVideoControls]);

  const showError = !isVideoFragmented || isVideoFragmented === 'false';

  return (
    <div className="full-height full-width">
      {showError ? (
        <div>{__('Video is not available')}</div>
      ) : (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          preload="true"
          className="full-width full-height"
          ref={videoRef}
          controlsList={CONTROLS_LIST_ATTRIBUTES.NO_DOWNLOAD}
          onContextMenu={preventDefaultHandler}
          controls={enableVideoControls}
        />
      )}
    </div>
  );
}

Video.propTypes = {
  element: PropTypes.shape({
    data: PropTypes.shape({
      mediaId: PropTypes.string.isRequired,
    }).isRequired,
    type: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  }).isRequired,
  enableVideoControls: PropTypes.bool,
};

Video.defaultProps = {
  enableVideoControls: false,
};

export default compose(withMediaStyling, withMediaResizing, withMediaDeletion, React.memo)(Video);
