import _reduce from 'lodash/reduce';
import _set from 'lodash/set';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { generateFunction } from '../factories/functionFactory';

import { CUSTOM_STYLE_TYPES, CUSTOM_STYLES_FIELD_IDS, CUSTOM_STYLE_SCRIPT_FALLBACK } from '../constants/customStyles.constants';

const getResolvedCustomStylesFromViewConfigCustomStyles = (supportedCustomStyleSection, customStyles, entityRecord, variablesApi) =>
  _reduce(
    supportedCustomStyleSection,
    (result, supportedStyleSection) => {
      const customStyleOfSupportedSectionValue = tget(customStyles, supportedStyleSection, EMPTY_OBJECT);
      const customStyleType = tget(customStyleOfSupportedSectionValue, CUSTOM_STYLES_FIELD_IDS.TYPE, CUSTOM_STYLE_TYPES.STYLES_OBJECT);
      const fallbackForCustomStyleValue = customStyleType === CUSTOM_STYLE_TYPES.STYLES_OBJECT ? {} : CUSTOM_STYLE_SCRIPT_FALLBACK;
      const customStyleValue = tget(customStyleOfSupportedSectionValue, CUSTOM_STYLES_FIELD_IDS.VALUE, fallbackForCustomStyleValue);

      if (customStyleType === CUSTOM_STYLE_TYPES.STYLES_OBJECT) {
        _set(result, supportedStyleSection, customStyleValue);
      } else {
        const customStyleScriptFunctionToExec = generateFunction(['$record', 'Variables'], customStyleValue);
        const conditionalStyles = customStyleScriptFunctionToExec(entityRecord, variablesApi);
        _set(result, supportedStyleSection, conditionalStyles);
      }

      return result;
    },
    {},
  );

export { getResolvedCustomStylesFromViewConfigCustomStyles };
