import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import cx from 'classnames';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import withActions from '@tekion/tekion-components/connectors/withActions';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import Loader from '@tekion/tekion-components/molecules/loader';

import withSize from '../../../../../../connectors/withSize';
import EntityMappings from '../../organisms/entityMappings';
import ApplicationTabContent from './components/applicationTabContent';
import Header from './components/header/Header';
import NavigationNeededComponent from './components/navigationNeededComponent/NavigationNeededComponent';
import Footer from './components/footer/Footer';
import PropertyPanel from './organisms/propertyPanel/PropertyPanel';
import ApplicationPreviewPage from './components/applicationPreviewPage';
import ApplicationHeading from './components/applicationHeading/ApplicationHeading';

import { getComponentFromApplicationConfig, getNavigationComponentId } from './helpers/applicationBuilder.helpers';

import ACTION_HANDLERS from './helpers/applicationBuilder.actionHandlers';
import ACTION_TYPES from './constants/applicationBuilder.actionTypes';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import { VIEWS } from './constants/applicationBuilder.constants';
import { APPLICATION_CONTEXT_KEYS, COMPONENTS_TYPES } from '../../../../../../constants/applicationRenderer.constants';

import styles from './applicationBuilder.module.scss';

const ApplicationBuilder = ({
  loading,
  isSavingDetails,
  isCancelDialogVisible,
  isEntityMappingsModalVisible,
  showPreviewModal,
  contentHeight,
  dialogText,
  titleText,
  formMode,
  selectedComponentId,
  applicationConfig,
  history,
  errorObject,
  applicationBuilderContextValue,
  onAction,
}) => {
  const applicationContext = useMemo(
    () => ({
      ...applicationBuilderContextValue,
      [APPLICATION_CONTEXT_KEYS.SET_APPLICATION_VARIABLES]: (variableName, value) => {
        onAction({ type: ACTION_TYPES.SET_APPLICATION_VARIABLES, payload: { variableName, value } });
      },
    }),
    [applicationBuilderContextValue, onAction],
  );

  const bodyStyle = useMemo(() => ({ height: contentHeight }), [contentHeight]);

  const entityModalPretextContent = useMemo(() => {
    if (isSavingDetails && formMode === FORM_MODES.CREATE) {
      return (
        <div className="flex p-b-12">
          <FontIcon size={FontIcon.SIZES.M} color="#28811f" className="p-t-4">
            icon-checked-outlined
          </FontIcon>
          <div className={styles.entityModalPretext}>
            {__("Your application has been saved successfully. Canceling this action won't affect your saved application")}
          </div>
        </div>
      );
    }
    return null;
  }, [isSavingDetails, formMode]);

  const triggerFormSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_SAVE });
  }, [onAction]);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const handleConfirmationDialogSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CONFIRM_REQUEST });
  }, [onAction]);

  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL_REQUEST });
  }, [onAction]);

  const handleShowPreview = useCallback(() => {
    onAction({ type: ACTION_TYPES.OPEN_PREVIEW_MODAL });
  }, [onAction]);

  const handleClosePreview = useCallback(() => {
    onAction({ type: ACTION_TYPES.CLOSE_PREVIEW_MODAL });
  }, [onAction]);

  const handleClickComponent = useCallback(
    (event, selectedId) => {
      event?.stopPropagation();
      onAction({ type: ACTION_TYPES.SET_SELECTED_COMPONENT, payload: { selectedComponentId: selectedId } });
    },
    [onAction],
  );

  const navigationPropertiesId = useMemo(() => getNavigationComponentId(applicationConfig), [applicationConfig]);

  const handleEntityMappingsModalClose = () => onAction({ type: ACTION_TYPES.ON_CLOSE_ENTITY_MAPPINS_MODAL });

  const renderBody = useCallback(() => {
    const isNavigationRequired = !_isEmpty(getComponentFromApplicationConfig(COMPONENTS_TYPES.NAVIGATION, applicationConfig));
    if (!isNavigationRequired) {
      const components = getComponentFromApplicationConfig(COMPONENTS_TYPES.NO_NAVIGATION, applicationConfig);
      const newTabInfo = { pageName: _get(components, 'pageName') };
      return (
        <div className={styles.tabContent}>
          <ApplicationTabContent tabInfo={newTabInfo} applicationContext={applicationContext} />
        </div>
      );
    } else
      return (
        <NavigationNeededComponent
          isNavigationRequired={isNavigationRequired}
          contentHeight={contentHeight}
          selectedComponentId={selectedComponentId}
          applicationContext={applicationContext}
          applicationConfig={applicationConfig}
          errorObject={errorObject}
          applicationBuilderOnAction={onAction}
        />
      );
  }, [applicationConfig, contentHeight, selectedComponentId, applicationContext, errorObject, onAction]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT_DATA });
  }, [onAction]);

  if (loading) return <Loader />;

  return (
    <Page>
      <Page.Header contentClassName="flex justify-content-between">
        <ApplicationHeading formMode={formMode} applicationConfig={applicationConfig} history={history} onAction={onAction} />
      </Page.Header>
      <Page.Body className={styles.bodyContainer} style={{ height: contentHeight }}>
        <div className={styles.applicationContainer}>
          <Header />
          <div
            className={cx('full-height full-width', {
              [styles.selected]: selectedComponentId === navigationPropertiesId,
            })}
            onClick={(event) => handleClickComponent(event, navigationPropertiesId)}
          >
            {renderBody()}
          </div>
          <Footer />
        </div>
        <div className={styles.propertyContainer}>
          <PropertyPanel
            loading={loading}
            selectedComponentId={selectedComponentId}
            applicationConfig={applicationConfig}
            errorObject={errorObject}
            applicationBuilderOnAction={onAction}
          />
        </div>

        {isCancelDialogVisible && (
          <ConfirmationDialog
            isVisible={isCancelDialogVisible}
            title={titleText}
            content={dialogText}
            onSubmit={handleConfirmationDialogSubmit}
            onCancel={handleConfirmationDialogCancel}
          />
        )}
        {showPreviewModal && (
          <Modal
            hideCancel
            hideCloseIcon
            hideHeader
            visible={showPreviewModal}
            zIndex={50}
            width="100%"
            bodyStyle={bodyStyle}
            submitBtnText={__('Close Preview')}
            onCancel={handleClosePreview}
            onSubmit={handleClosePreview}
          >
            <ApplicationPreviewPage history={history} applicationConfig={applicationConfig} applicationContext={applicationContext} />
          </Modal>
        )}
        {isEntityMappingsModalVisible && (
          <EntityMappings preText={entityModalPretextContent} applicationConfiguration={applicationConfig} onClose={handleEntityMappingsModalClose} />
        )}
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          showAdditionalButton
          additionalActionLoading={isSavingDetails}
          primaryButtonLabel={__('Preview')}
          primaryActionView={VIEWS.SECONDARY}
          additionalActionView={VIEWS.PRIMARY}
          additionalButtonLabel={formMode === FORM_MODES.CREATE ? __('Create') : __('Update')}
          onAdditionalAction={triggerFormSubmit}
          onSecondaryAction={handleCancel}
          onPrimaryAction={handleShowPreview}
        />
      </Page.Footer>
    </Page>
  );
};

ApplicationBuilder.propTypes = {
  loading: PropTypes.bool,
  isCancelDialogVisible: PropTypes.bool,
  isSavingDetails: PropTypes.bool,
  showPreviewModal: PropTypes.bool,
  isEntityMappingsModalVisible: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  dialogText: PropTypes.string,
  titleText: PropTypes.string,
  formMode: PropTypes.string,
  selectedComponentId: PropTypes.string,
  applicationBuilderContextValue: PropTypes.object,
  applicationConfig: PropTypes.object,
  history: PropTypes.object,
  errorObject: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ApplicationBuilder.defaultProps = {
  loading: true,
  isCancelDialogVisible: false,
  isSavingDetails: false,
  showPreviewModal: false,
  isEntityMappingsModalVisible: false,
  dialogText: EMPTY_STRING,
  titleText: EMPTY_STRING,
  formMode: FORM_MODES.CREATE,
  selectedComponentId: EMPTY_STRING,
  applicationBuilderContextValue: EMPTY_OBJECT,
  applicationConfig: EMPTY_OBJECT,
  history: EMPTY_OBJECT,
  errorObject: EMPTY_OBJECT,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ApplicationBuilder);
