import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _keysIn from 'lodash/keysIn';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _omitBy from 'lodash/omitBy';
import _find from 'lodash/find';
import _isArray from 'lodash/isArray';
import _isNil from 'lodash/isNil';
import _head from 'lodash/head';
import _last from 'lodash/last';
import _split from 'lodash/split';
import _size from 'lodash/size';
import _reduce from 'lodash/reduce';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import DATA_TYPES from '../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../constants/fieldDefinition.fieldTypes';
import { VIEW_TYPES } from '../constants/viewBuilder.constants';
import SYSTEM_DEFINED_FIELDS from '../constants/systemDefinedFields';

import fieldDefinitionReader from '../readers/fieldDefinition.reader';
import entityReader from '../readers/entity.reader';

const getComplexAndRelationshipEntitiesFromEntityDef = (entityDef) => {
  const fieldDefinitions = entityReader.fieldDefinitions(entityDef);
  const complexEntities = [];
  const relationshipEntities = [];

  _forEach(fieldDefinitions, (field) => {
    const dataType = fieldDefinitionReader.dataType(field);
    const fieldType = fieldDefinitionReader.fieldType(field);
    if (dataType === DATA_TYPES.COMPLEX) {
      complexEntities.push(fieldDefinitionReader.complexFieldDefinitionEntityName(field));
    }
    if (fieldType === FIELD_TYPES.RELATIONSHIP) {
      relationshipEntities.push(fieldDefinitionReader.lookupFieldEntityType(field));
    }
  });

  return { complexEntities: _uniq(complexEntities), relationshipEntities: _uniq(relationshipEntities) };
};

const getIncludeFieldsFromEntityDef = (masterEntityDef) => {
  const fieldDefinitions = entityReader.fieldDefinitions(masterEntityDef);

  let includeFields = [];
  _forEach(fieldDefinitions, (field) => {
    const fieldType = fieldDefinitionReader.fieldType(field);
    const fieldName = fieldDefinitionReader.name(field);

    if (fieldType === FIELD_TYPES.RELATIONSHIP) {
      const relationshipEntityFieldDefinitions = fieldDefinitionReader.relationshipEntityFieldDefinitions(field);
      const relatedFields = _keysIn(relationshipEntityFieldDefinitions);
      includeFields = [...includeFields, ..._map(relatedFields, (relatedField) => `${fieldName}.${relatedField}`)];
    } else {
      includeFields.push(fieldName);
    }
  });

  return includeFields;
};

// add complex and relationship entity def into main entity Def
const getResolvedEntityDefinitions = (entity, entityDefsByName) => {
  const fieldDefinitions = entityReader.fieldDefinitions(entity);

  const updatedFieldDefinitions = _map(fieldDefinitions, (field) => {
    const dataType = fieldDefinitionReader.dataType(field);
    const fieldType = fieldDefinitionReader.fieldType(field);

    if (dataType === DATA_TYPES.COMPLEX) {
      const complexEntity = fieldDefinitionReader.complexFieldDefinitionEntityName(field);
      if (_has(entityDefsByName, complexEntity)) {
        const complexEntityFieldDefinitions = entityReader.fieldDefinitions(_get(entityDefsByName, complexEntity));

        return {
          ...field,
          complexEntityFieldDefinitions: {
            ..._keyBy(complexEntityFieldDefinitions, 'name'),
          },
        };
      } else {
        return field;
      }
    }

    if (fieldType === FIELD_TYPES.RELATIONSHIP) {
      const lookUpEntity = fieldDefinitionReader.lookupFieldEntityType(field);
      if (_has(entityDefsByName, lookUpEntity)) {
        const relationshipEntityFieldDefinitions = entityReader.fieldDefinitions(_get(entityDefsByName, lookUpEntity));

        return {
          ...field,
          relationshipEntityFieldDefinitions: {
            ..._keyBy(relationshipEntityFieldDefinitions, 'name'),
          },
        };
      } else {
        return field;
      }
    }

    return field;
  });

  const masterEntityDef = { ...entity, fieldDefinitions: updatedFieldDefinitions };
  return masterEntityDef;
};

const getRecordTypeOverriddenEntityDef = (currentRecordType, entityDef) => {
  const isRecordTypeEnabled = entityReader.recordTypeEnabled(entityDef);
  const defaultRecordType = entityReader.defaultRecordType(entityDef);
  if (!isRecordTypeEnabled || defaultRecordType === currentRecordType) {
    return entityDef;
  }

  const recordTypeOverrides = entityReader.recordTypeOverrides(entityDef);
  const recordTypeDefs = entityReader.recordTypeDefinitionRecordTypes(entityDef);

  const currentRecordTypeDef = _find(recordTypeDefs, (recordTypeDef) => tget(recordTypeDef, 'name') === currentRecordType);
  // one recordTypeName can be present in only one override object
  const currentOverride = getArraySafeValue(
    _filter(recordTypeOverrides, (override) => {
      const recordTypes = _map(tget(override, 'recordTypeInfos', EMPTY_ARRAY), (recordTypeInfo) => tget(recordTypeInfo, 'recordTypeName'));
      return _includes(recordTypes, currentRecordType);
    }),
  );

  const fieldOverridesByFieldName = _keyBy(tget(currentOverride, 'fieldOverrides', EMPTY_ARRAY), 'name');
  const fieldDefinitions = entityReader.fieldDefinitions(entityDef);
  let updatedFieldDefs = _map(fieldDefinitions, (fieldDef) => {
    const fieldName = fieldDefinitionReader.name(fieldDef);
    const overrides = tget(fieldOverridesByFieldName, fieldName, EMPTY_OBJECT);
    const filteredOverrides = _omitBy(overrides, _isNil);
    return {
      ...fieldDef,
      ...filteredOverrides,
    };
  });

  const availableFields = _get(currentRecordTypeDef, 'availableFields');
  // explicitly checking null as empty array means no available fields and null means add all the fields
  if (!_isNil(availableFields) && _isArray(availableFields)) {
    updatedFieldDefs = _filter(updatedFieldDefs, (field) => _includes(availableFields, fieldDefinitionReader.name(field)));
  }

  return { ...entityDef, fieldDefinitions: updatedFieldDefs };
};

const getValueOfFieldFromFieldDefinitionOfFieldName = (fieldName, fieldDefinitions = EMPTY_ARRAY, field = undefined) => {
  const fieldDefinitionByName = _keyBy(fieldDefinitions, 'name');
  const firstFieldName = _head(_split(fieldName, '.'));
  const fieldDef = tget(fieldDefinitionByName, [firstFieldName], EMPTY_OBJECT);
  const fieldType = tget(fieldDef, ['fieldType'], EMPTY_STRING);
  const dataType = tget(fieldDef, ['dataType'], EMPTY_STRING);

  if (_has(fieldName, '.')) {
    const lastFieldName = _last(_split(fieldName, '.'));
    let entityFieldDefinitions;

    if (fieldType === FIELD_TYPES.RELATIONSHIP) {
      entityFieldDefinitions = tget(fieldDef, ['relationshipEntityFieldDefinitions'], EMPTY_ARRAY);
    } else if (dataType === DATA_TYPES.COMPLEX) {
      entityFieldDefinitions = tget(fieldDef, ['complexEntityFieldDefinitions'], EMPTY_OBJECT);
    }

    const entityFieldDef = tget(entityFieldDefinitions, [lastFieldName], EMPTY_OBJECT);

    return tget(entityFieldDef, [field], entityFieldDef);
  }
  return tget(fieldDef, [field], fieldDef);
};

const getFilteredFieldDefinitionsBasedOnViewTypes = (entity, viewType) => {
  const fieldDefs = entityReader.fieldDefinitions(entity);
  const derivationFields = entityReader.derivedRecordTypeFields(entity) || EMPTY_ARRAY;

  if (viewType === VIEW_TYPES.RECORD_TYPE_SELECTION_VIEW) {
    // Picking only derivation and recordType fields from fieldDefs when ViewType is RECORD_TYPE_SELECTION_VIEW
    return _filter(fieldDefs, (fieldDef) =>
      _includes([...derivationFields, SYSTEM_DEFINED_FIELDS.RECORD_TYPE_NAME], fieldDefinitionReader.name(fieldDef)),
    );
  } else if (viewType === VIEW_TYPES.FORM_VIEW) {
    const systemFieldsToFilterInFormView = _filter(
      _map(SYSTEM_DEFINED_FIELDS),
      (field) => !_includes([SYSTEM_DEFINED_FIELDS.NAME, SYSTEM_DEFINED_FIELDS.OWNER_ID], field),
    );

    // filtering only derivation and recordType fields from fieldDefs when ViewType is FORM_VIEW
    return _filter(
      fieldDefs,
      (fieldDef) => !_includes([...derivationFields, ...systemFieldsToFilterInFormView], fieldDefinitionReader.name(fieldDef)),
    );
  }

  return fieldDefs;
};

const getUpdatedEntityDefFromRecordTypeValues = (selectedRecordTypeNames, entityDef, viewTypeValue = EMPTY_STRING) => {
  const fieldDefs = entityReader.fieldDefinitions(entityDef);
  const viewType = getArraySafeValue(viewTypeValue);
  const updatedFieldDefinitions = getFilteredFieldDefinitionsBasedOnViewTypes(entityDef, viewType);
  const updatedEntityDef = { ...entityDef, fieldDefinitions: updatedFieldDefinitions };

  if (_size(selectedRecordTypeNames) === 0) {
    return updatedEntityDef;
  }

  if (_size(selectedRecordTypeNames) === 1) {
    return getRecordTypeOverriddenEntityDef(_head(selectedRecordTypeNames), updatedEntityDef);
  }

  const recordTypeDefs = entityReader.recordTypeDefinitionRecordTypes(updatedEntityDef);
  const recordTypeDefByName = _keyBy(recordTypeDefs, 'name');

  const combinedFieldNamesFromRecordTypes = _uniq(
    _reduce(
      selectedRecordTypeNames,
      (result, recordTypeName) => {
        const currentRecordTypeDef = tget(recordTypeDefByName, recordTypeName, EMPTY_OBJECT);
        const recordTypeAvailableFields = tget(currentRecordTypeDef, 'availableFields', EMPTY_ARRAY);

        result.push(...recordTypeAvailableFields);

        return result;
      },
      [],
    ),
  );

  const combinedFieldDefsFromCombinedFieldNames = _filter(fieldDefs, (fieldDef) =>
    _includes(combinedFieldNamesFromRecordTypes, fieldDefinitionReader.name(fieldDef)),
  );

  return { ...updatedEntityDef, fieldDefinitions: combinedFieldDefsFromCombinedFieldNames };
};

export {
  getComplexAndRelationshipEntitiesFromEntityDef,
  getIncludeFieldsFromEntityDef,
  getResolvedEntityDefinitions,
  getRecordTypeOverriddenEntityDef,
  getValueOfFieldFromFieldDefinitionOfFieldName,
  getUpdatedEntityDefFromRecordTypeValues,
  getFilteredFieldDefinitionsBasedOnViewTypes,
};
