import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import Modal from '@tekion/tekion-components/molecules/Modal';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import Button from '@tekion/tekion-components/atoms/Button';
import TextArea from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';

import {
  getBeautifyFormatJsonString,
  getConfigFormattedSelectedWidgetProperties,
  getFormFormattedSelectedWidgetProperties,
} from './helpers/customStylesConfigure.helpers';
import { CUSTOM_STYLES_CONFIGURE_SECTIONS } from './helpers/customStylesConfigure.sections';
import { CUSTOM_STYLES_CONFIGURE_FIELDS } from './helpers/customStylesConfigure.fields';

import { ACTION_TYPES as VISUAL_BUILDER_ACTION_TYPES, COMPONENT_CONFIG_KEYS } from '../../../../constants/visualBuilder.general.constants';
import { CUSTOM_STYLES_CONFIGURE_FIELD_IDS } from './constants/customStylesConfigure.fieldIds';

import styles from './customStylesConfigure.module.scss';

const CustomStylesConfigure = ({ selectedWidgetConfig, onChangeWidgetConfigurationAction }) => {
  const widgetName = useMemo(() => tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, ''), [selectedWidgetConfig]);
  const selectedWidgetProperties = useMemo(() => tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, {}), [selectedWidgetConfig]);
  const formFormattedSelectedWidgetProperties = getFormFormattedSelectedWidgetProperties(selectedWidgetProperties);
  const [isStylesConfigureModalVisible, setStylesConfigureModalVisible] = useState(false);
  const [customStylesFormValues, setCustomStylesFormValues] = useState(formFormattedSelectedWidgetProperties);

  const handleConfigureClick = useCallback(() => {
    setCustomStylesFormValues(formFormattedSelectedWidgetProperties);
    setStylesConfigureModalVisible(true);
  }, [formFormattedSelectedWidgetProperties]);

  const handleBeautifyClick = useCallback(() => {
    const beautifiedJsonString = getBeautifyFormatJsonString(customStylesFormValues[CUSTOM_STYLES_CONFIGURE_FIELD_IDS.CUSTOM_STYLES]);
    setCustomStylesFormValues({ ...customStylesFormValues, [CUSTOM_STYLES_CONFIGURE_FIELD_IDS.CUSTOM_STYLES]: beautifiedJsonString });
  }, [customStylesFormValues]);

  const handleSubmit = useCallback(() => {
    const configFormattedSelectedWidgetProperties = getConfigFormattedSelectedWidgetProperties(
      CUSTOM_STYLES_CONFIGURE_FIELD_IDS.CUSTOM_STYLES,
      customStylesFormValues,
    );

    if (configFormattedSelectedWidgetProperties) {
      onChangeWidgetConfigurationAction({
        type: VISUAL_BUILDER_ACTION_TYPES.UPDATE_WIDGET_CONFIGURATION,
        payload: {
          value: { widgetConfiguration: { ...selectedWidgetConfig, properties: { ...configFormattedSelectedWidgetProperties } }, widgetName },
        },
      });

      setStylesConfigureModalVisible(false);
    }
  }, [onChangeWidgetConfigurationAction, customStylesFormValues, selectedWidgetConfig, widgetName]);

  const handleCancelModal = useCallback(() => {
    setCustomStylesFormValues({});
    setStylesConfigureModalVisible(false);
  }, []);

  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload = EMPTY_OBJECT } = action;
    switch (type) {
      case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
        const { id, value } = payload;
        setCustomStylesFormValues({ ...selectedWidgetProperties, [id]: value });
        break;
      }

      // case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
      //   const { errors: newErrors } = payload;
      //   break;
      // }
      default:
        break;
    }
  };
  return (
    <>
      <TextArea
        disabled
        rows={8}
        containerClassName={styles.textAreaContainer}
        value={formFormattedSelectedWidgetProperties[CUSTOM_STYLES_CONFIGURE_FIELD_IDS.CUSTOM_STYLES]}
        onAction={handleAction}
      />

      <div className={styles.stylesConfigureContainer}>
        <Button disable view={Button.VIEW.TERTIARY} className={styles.stylesConfigureButton} onClick={handleConfigureClick}>
          {__('Set Styles')}
        </Button>
      </div>

      <Modal
        visible={isStylesConfigureModalVisible}
        width={SIZES.S}
        bodyStyle={{ padding: 0 }}
        title={__('Configure Custom Styles')}
        submitBtnText={__('Submit')}
        onSubmit={handleSubmit}
        onCancel={handleCancelModal}
      >
        <FormBuilder
          sections={CUSTOM_STYLES_CONFIGURE_SECTIONS}
          fields={CUSTOM_STYLES_CONFIGURE_FIELDS}
          values={customStylesFormValues}
          onAction={handleAction}
        />
        <Button disable view={Button.VIEW.TERTIARY} className={styles.beautifyButton} onClick={handleBeautifyClick}>
          {__('Beautify')}
        </Button>
      </Modal>
    </>
  );
};

CustomStylesConfigure.propTypes = {
  selectedWidgetConfig: PropTypes.object,
  onChangeWidgetConfigurationAction: PropTypes.func,
};

CustomStylesConfigure.defaultProps = {
  selectedWidgetConfig: undefined,
  onChangeWidgetConfigurationAction: _noop,
};

export default CustomStylesConfigure;
