import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Heading from '@tekion/tekion-components/atoms/Heading';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { FormWithSubmission } from '@tekion/tekion-components/pages/formPage';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

import { FORM_SECTIONS, getFieldConfig } from './repeatNodeModal.fields';
import { CONTEXT_ID } from './repeatNodeModal.constants';
import { MODAL_MODES } from '../../../constants/workflowBuilder.constants';

const RepeatNode = ({ repeatWithNoLoop, mode, stepDetails, onCancel, onSubmit }) => {
  const [values, setValue] = useState({});
  const [errors, setErrors] = useState({});

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { value: updatedValue, id } = payload;
          setValue({ ...values, [id]: updatedValue });
          break;
        }
        case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
          const { errors: newErrors } = payload;
          setErrors(newErrors);
          break;
        }
        default:
      }
    },
    [values],
  );

  const handleSubmit = useCallback(() => {
    setValue({});
    setErrors({});
    onSubmit(values, stepDetails);
  }, [values, onSubmit, stepDetails]);

  const handleCancel = useCallback(() => {
    setValue({});
    setErrors({});
    onCancel();
  }, [onCancel]);

  const getTimeOfTheDayContent = useMemo(
    () => (
      <>
        <Heading className="p-b-12" size={3}>
          {__('Repeat steps by')}
        </Heading>
        <FormWithSubmission
          className="p-t-12"
          contextId={CONTEXT_ID}
          sections={FORM_SECTIONS}
          fields={getFieldConfig}
          values={values}
          errors={errors}
          onAction={handleAction}
        />
      </>
    ),
    [errors, values, handleAction],
  );

  useEffect(() => {
    if (repeatWithNoLoop) {
      onSubmit({});
    }
  }, [onSubmit, repeatWithNoLoop, values]);

  useEffect(() => {
    if (mode === MODAL_MODES.EDIT) {
      const userData = tget(stepDetails, 'userData.data');
      if (!_isEmpty(userData)) {
        setValue((_value) => ({ ..._value, ...userData }));
      }
    }
  }, [stepDetails, mode]);

  return (
    <PropertyControlledComponent controllerProperty={!repeatWithNoLoop}>
      <Modal
        visible
        destroyOnClose
        title={__('Add Repeat')}
        submitBtnText={__('Add')}
        width={Modal.SIZES.SM}
        content={getTimeOfTheDayContent}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    </PropertyControlledComponent>
  );
};

RepeatNode.propTypes = {
  repeatWithNoLoop: PropTypes.bool,
  mode: PropTypes.string,
  stepDetails: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

RepeatNode.defaultProps = {
  repeatWithNoLoop: false,
  mode: EMPTY_STRING,
  stepDetails: EMPTY_OBJECT,
  onCancel: _noop,
  onSubmit: _noop,
};

export default RepeatNode;
