import React, { useCallback, useEffect, useMemo } from 'react';
import { Transfer } from 'antd';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _debounce from 'lodash/debounce';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _toLower from 'lodash/toLower';

import Button from '@tekion/tekion-components/atoms/Button';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import LoadingSpinner from '@tekion/tekion-components/molecules/loadingSpinner';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import withActions from '@tekion/tekion-components/connectors/withActions';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import Modal from '@tekion/tekion-components/molecules/Modal';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import Heading from '@tekion/tekion-components/atoms/Heading';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';
import NavigationItem from '../../../../molecules/NavigationFlow/components/navigationItem/NavigationItem';
import ProjectApplicationsCardView from './components/projectApplicationsCardView';

import { COLUMN_CONFIG } from './constants/projectApplicationList.columns';
import { getTableProps, getSubHeaderProps, getApplications, isScrollAtBottom } from './helpers/projectApplicationList.helpers';
import withSize from '../../../../connectors/withSize';
import ACTION_HANDLERS from './helpers/projectApplicationList.actionHandlers';

import ACTION_TYPES from './constants/projectApplicationList.actionTypes';
import { TABLE_MANAGER_PROPS, PROJECT_NAVIGATION_DEFAULT_DATA, MODAL_DIRECTION } from './constants/projectApplicationList.constants';
import PROJECT_READERS from '../../../../readers/entity.reader';

import styles from './projectApplicationList.module.scss';

const ProjectApplicationList = ({
  loading,
  isSaveLoading,
  isApplicationLoading,
  isApplicationModalVisible,
  pageSize,
  currentPage,
  totalCount,
  contentHeight,
  selectedApplications,
  applicationList,
  projectData,
  projectApplications,
  history,
  onAction,
}) => {
  const projectName = PROJECT_READERS.name(projectData);

  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({
        type: actionType,
        payload,
      });
    },
    [onAction],
  );

  const handleCancelModal = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CANCEL_MODAL,
    });
  }, [onAction]);

  const handleSaveModal = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_SAVE_MODAL,
    });
  }, [onAction]);

  const renderTransferListItem = useCallback((item) => <span key={item.key}>{item.title}</span>, []);

  const handleSearch = useMemo(
    () =>
      _debounce((direction, value) => {
        if (direction === MODAL_DIRECTION.LEFT) {
          const searchText = value;
          onAction({
            type: ACTION_TYPES.ON_SEARCH,
            payload: { direction, searchText },
          });
        }
      }, 1000),
    [onAction],
  );

  const handleTransfer = useCallback(
    (newSelectedApplications) => {
      onAction({
        type: ACTION_TYPES.ON_TRANSFER_ITEM,
        payload: { newSelectedApplications },
      });
    },
    [onAction],
  );

  const tableProps = useMemo(
    () => getTableProps(currentPage, totalCount, loading, pageSize, handleRowAction),
    [handleRowAction, currentPage, pageSize, loading, totalCount],
  );

  const handleScroll = (direction, event) => {
    if (isScrollAtBottom(event)) {
      onAction({
        type: ACTION_TYPES.ON_SCROLL,
        payload: { direction },
      });
    }
  };

  const handleFilterOption = useCallback((inputValue, option) => {
    const title = _toLower(_get(option, 'title', EMPTY_STRING));
    const searchText = _toLower(inputValue);
    return _includes(title, searchText);
  }, []);

  const showLoader = useMemo(() => <LoadingSpinner className={styles.applicationLoader} />, []);

  const headerNavigationData = useMemo(
    () => [...PROJECT_NAVIGATION_DEFAULT_DATA, { label: __(_get(projectData, 'displayName', NO_DATA)), key: '2' }],
    [projectData],
  );

  const handleNavigationItemClick = useCallback(
    (goTo) => {
      if (!_isEmpty(goTo)) {
        history.push(goTo);
      }
    },
    [history],
  );

  const headerNavigation = useMemo(
    () =>
      _map(headerNavigationData, (data, index) => (
        <NavigationItem
          key={_get(data, 'key', EMPTY_STRING)}
          itemNumber={index}
          totalItems={_size(headerNavigationData)}
          data={data}
          onNavigationItemClick={handleNavigationItemClick}
        />
      )),
    [headerNavigationData, handleNavigationItemClick],
  );

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.INIT_DATA,
    });
  }, [onAction]);

  return (
    <Page>
      <Page.Header>
        <Heading className={styles.headerNavigationContainer} size={2}>
          {headerNavigation}
        </Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight - 24 }}>
        <ProjectApplicationsCardView projectData={projectData} onAction={onAction} />
        <div className={styles.tableContainer} style={{ height: contentHeight - 110 }}>
          <TableManager
            loading
            columns={COLUMN_CONFIG}
            tableManagerProps={TABLE_MANAGER_PROPS}
            tableProps={tableProps}
            data={projectApplications}
            subHeaderProps={getSubHeaderProps(totalCount)}
            onAction={onAction}
          />
        </div>
        <Modal
          visible={isApplicationModalVisible}
          width={SIZES.L}
          title={projectName}
          submitBtnText={__('Done')}
          okButtonProps={{ view: Button.VIEW.PRIMARY, className: styles.submitButton }}
          onSubmit={handleSaveModal}
          onCancel={handleCancelModal}
          loading={isSaveLoading}
        >
          <PropertyControlledComponent controllerProperty={!isApplicationLoading} fallback={showLoader}>
            <Transfer
              showSearch
              titles={[__('All Applications'), __('Selected')]}
              searchPlaceholder={__('Quick Search')}
              targetKeys={selectedApplications}
              dataSource={getApplications(applicationList)}
              render={renderTransferListItem}
              onSearch={handleSearch}
              style={{ fontWeight: 'bolder' }}
              listStyle={{
                width: 370,
                height: 400,
              }}
              operationStyle={{ width: 40, margin: 20 }}
              onChange={handleTransfer}
              onScroll={handleScroll}
              filterOption={handleFilterOption}
              scroll={{ x: 400 }}
            />
          </PropertyControlledComponent>
        </Modal>
      </Page.Body>
    </Page>
  );
};
ProjectApplicationList.propTypes = {
  loading: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  isApplicationLoading: PropTypes.bool,
  isApplicationModalVisible: PropTypes.bool,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  contentHeight: PropTypes.number.isRequired,
  selectedApplications: PropTypes.array,
  applicationList: PropTypes.array,
  projectApplications: PropTypes.array,
  projectData: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onAction: PropTypes.func.isRequired,
};

ProjectApplicationList.defaultProps = {
  isApplicationLoading: false,
  isApplicationModalVisible: false,
  isSaveLoading: false,
  loading: false,
  selectedApplications: EMPTY_ARRAY,
  projectData: EMPTY_OBJECT,
  projectApplications: EMPTY_ARRAY,
  applicationList: EMPTY_ARRAY,
  pageSize: DEFAULT_PAGE_INFO.rows,
  currentPage: DEFAULT_PAGE_INFO.start,
  totalCount: 0,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 0 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ProjectApplicationList);
