import { STATUS } from '@tekion/tekion-base/constants/status.constants';
import ROUTES from '../../../constants/routes';

const TABLE_MANAGER_PROPS = {
  showFilter: true,
  showHeader: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const EXPORTS_LIST_PAGE_DEFAULT_PROPS = {
  currentPage: 0,
  pageSize: 50,
};

const EXPORTS_LIST_PAGE_FIELD_IDS = {
  CREATED_TIME_FIELD: 'createdTime',
  STATUS_FIELD: 'status',
};

const EXPORT_STATUSES = {
  FAILED: STATUS.FAILED,
  COMPLETED: STATUS.COMPLETED,
  IN_PROGRESS: STATUS.IN_PROGRESS,
  CANCELLED: STATUS.CANCELLED,
  NEW: STATUS.NEW,
};

const VIEW_TYPE_MAPPER = {
  LIST_VIEW: 'List View',
  DETAIL_VIEW: 'Detail View',
};

const EXPORT_HEADER_NAVIGATION_DEFAULT_DATA = [
  { label: __('Home'), goTo: ROUTES.HOME_PAGE_ROUTE, key: 'home' },
  { label: __('My Downloads'), key: 'my-downloads' },
];

export {
  TABLE_MANAGER_PROPS,
  EXPORTS_LIST_PAGE_DEFAULT_PROPS,
  EXPORTS_LIST_PAGE_FIELD_IDS,
  EXPORT_STATUSES,
  VIEW_TYPE_MAPPER,
  EXPORT_HEADER_NAVIGATION_DEFAULT_DATA,
};
