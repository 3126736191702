import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Modal from '@tekion/tekion-components/molecules/Modal';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Heading from '@tekion/tekion-components/atoms/Heading';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getAssignedAndUsedVariableInfo, getUserData } from './variableNodeModal.helpers';
import { getFormSections, getFieldConfig } from './variableNodeModal.fields';
import { CONTEXT_ID } from './variableModal.constants';
import { MODAL_MODES } from '../../../constants/workflowBuilder.constants';

const VariableNodeModal = ({
  mode,
  stepDetails,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  variablesInfo,
  onCancel,
  onSubmit,
}) => {
  const [values, setValue] = useState();
  const [errors, setErrors] = useState();
  const fields = useMemo(
    () => getFieldConfig(conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, values),
    [conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, values],
  );
  const sections = useMemo(() => getFormSections(values, tget(stepDetails, 'taskDefId')), [values, stepDetails]);

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { value: updatedValue, id } = payload;
          setValue({ ...values, [id]: updatedValue });
          break;
        }
        case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
          const { errors: newErrors } = payload;
          setErrors(newErrors);
          break;
        }
        default:
      }
    },
    [values],
  );

  const variableNodeContent = useMemo(
    () => (
      <>
        <Heading className="p-b-12" size={3}>
          {__('Variable Info')}
        </Heading>
        <FormWithSubmission sections={sections} fields={fields} values={values} errors={errors} contextId={CONTEXT_ID} onAction={handleAction} />
      </>
    ),
    [values, errors, handleAction, sections, fields],
  );

  const handleSubmit = useCallback(() => {
    setValue({});
    setErrors({});
    const userData = getUserData(values);
    const { assignedVariableInfo, usedVariableInfo } = getAssignedAndUsedVariableInfo(values, variablesInfo);

    onSubmit(userData, [], assignedVariableInfo, usedVariableInfo);
  }, [values, onSubmit, variablesInfo]);

  const handleCancel = useCallback(() => {
    setValue({});
    setErrors({});
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    if (mode === MODAL_MODES.EDIT) {
      const userData = tget(stepDetails, 'userData.data');
      if (!_isEmpty(userData)) {
        setValue((_value) => ({ ..._value, ...userData }));
      }
    }
  }, [stepDetails, mode]);

  return (
    <Modal
      visible
      destroyOnClose
      title={__('Set Variable')}
      submitBtnText={__('Add')}
      width={Modal.SIZES.L}
      content={variableNodeContent}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

VariableNodeModal.propTypes = {
  mode: PropTypes.string,
  stepDetails: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  variablesInfo: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

VariableNodeModal.defaultProps = {
  mode: EMPTY_STRING,
  stepDetails: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  variablesInfo: EMPTY_OBJECT,
  onSubmit: _noop,
  onCancel: _noop,
};

export default VariableNodeModal;
