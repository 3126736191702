import { useCallback, useMemo } from 'react';

import _find from 'lodash/find';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { generateOptions } from './useCustomActions.helpers';
import { convertEventHandlersFromArrayToObjectByEventName, executeEventFromEventViewConfigData } from '../../../../../../utils/eventHandlers';

import { VIEW_CONFIGURATION_PROPERTIES_ID } from '../../../../../../constants/viewBuilder.constants';
import { EVENT_NAMES } from '../../../../../../constants/eventActions.constants';
import { MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT } from '../../../../../../constants/eventHandlers.constants';
import { OPTION_KEYS as MENU_OPTION_KEYS } from '../../../../../../atoms/menu';

const useCustomActions = ({ customActionsData, entityRecord }) => {
  const onOptionClick = useCallback(
    ({ [MENU_OPTION_KEYS.ID]: name }) => {
      const _customActionDef = _find(customActionsData, { name });

      const eventHandlers = tget(_customActionDef, VIEW_CONFIGURATION_PROPERTIES_ID.EVENT_HANDLERS, EMPTY_ARRAY);
      const eventHandlersMapByEventName = convertEventHandlersFromArrayToObjectByEventName(eventHandlers);

      const onClickEventData = tget(eventHandlersMapByEventName, EVENT_NAMES.CLICK, EMPTY_OBJECT);

      const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$RECORD]: entityRecord,
      };

      executeEventFromEventViewConfigData(onClickEventData, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE, entityRecord);
    },
    [customActionsData, entityRecord],
  );

  const customActionOptions = useMemo(
    () => generateOptions(customActionsData, entityRecord, onOptionClick),
    [customActionsData, entityRecord, onOptionClick],
  );

  return { customActionOptions };
};

export default useCustomActions;
