import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';

import { DEFAULT_FILTERS_TYPES, SUBSCRIPTION_CONFIG_LOG_CREATED_TIME_FILTER } from '../constants/subscriptionConfigLogs.filterTypes';

const createFilterProps = (onAction) => ({
  filterTypes: [SUBSCRIPTION_CONFIG_LOG_CREATED_TIME_FILTER],
  onAction,
  defaultFilterTypes: DEFAULT_FILTERS_TYPES,
});

const getPayloadForConfigLog = (selectedFilters) => {
  const logTimeFilter = getArraySafeValue(selectedFilters);
  const logTimeValues = tget(logTimeFilter, 'values', []);
  return { startTime: tget(logTimeValues, '0', Date.now()), endTime: tget(logTimeValues, '1', Date.now()) };
};

export { createFilterProps, getPayloadForConfigLog };
