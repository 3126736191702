import React from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import AntCheckBox from 'tcomponents/atoms/checkbox';

const Options = (props) => {
  const { label, isSelected } = props;
  return (
    <div>
      <components.Option {...props}>
        <AntCheckBox label={label} value={isSelected} onChange={_noop} />
      </components.Option>
    </div>
  );
};

Options.propTypes = {
  label: PropTypes.string,
  isSelected: PropTypes.bool,
};

Options.defaultProps = {
  label: __(''),
  isSelected: false,
};

export default React.memo(Options);
