import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _get from 'lodash/get';
import _slice from 'lodash/slice';
import _head from 'lodash/head';
import _last from 'lodash/last';
import _toNumber from 'lodash/toNumber';
import _isNil from 'lodash/isNil';
import _map from 'lodash/map';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { DATE_TIME_FORMATS, getFormattedDateTime, toMoment, toMomentWithParserFormat } from '@tekion/tekion-base/utils/dateUtils';
import DATA_TYPES from '../../../../../constants/fieldDefinition.dataTypes';

const RangeFieldWrapper = (Component, rangeType) => {
  const WrappedComponent = React.memo(({ value, onAction, ...restProps }) => {
    const toMomentValue = useCallback((dateValue) => {
      if (_isNil(dateValue)) return undefined;
      return toMomentWithParserFormat(dateValue, DATE_TIME_FORMATS.ISO_8601_DATE) || toMoment(dateValue);
    }, []);

    const handleAction = useCallback(
      (action = EMPTY_OBJECT) => {
        const type = _get(action, 'type');
        const payload = _get(action, 'payload', EMPTY_OBJECT);
        const _value = _get(action, 'payload.value', EMPTY_ARRAY);
        if (rangeType === DATA_TYPES.DATE) {
          if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
            onAction({
              type,
              payload: {
                ...payload,
                value: _map(_slice(_value, 0, 2), (dateValue) => getFormattedDateTime(dateValue, DATE_TIME_FORMATS.ISO_8601_DATE)),
              },
            });
          } else {
            onAction(action);
          }
        }
        if (rangeType === DATA_TYPES.NUMBER) {
          if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
            onAction({
              type,
              payload: {
                ...payload,
                value: [_toNumber(_get(_value, 'from')) || null, _toNumber(_get(_value, 'to')) || null],
              },
            });
          } else {
            onAction(action);
          }
        }
      },
      [onAction],
    );

    if (DATA_TYPES.DATE === rangeType) {
      const momentValue = _map(value, toMomentValue);
      return <Component value={momentValue} onAction={handleAction} {...restProps} />;
    }
    return <Component value={{ from: _head(value), to: _last(value) }} onAction={handleAction} {...restProps} />;
  });

  WrappedComponent.propTypes = {
    value: PropTypes.oneOf([PropTypes.array, PropTypes.object]),
    onAction: PropTypes.func,
  };

  WrappedComponent.defaultProps = {
    value: undefined,
    onAction: _noop,
  };

  return WrappedComponent;
};

export default RangeFieldWrapper;
