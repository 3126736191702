import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { getNavigationProperties, setNavigationPropertiesInApplicationConfig } from './navigationProperties.helpers';
import ACTION_TYPES from '../constants/navigationProperties.actionTypes';
import APPLICATION_BUILDER_ACTION_TYPES from '../../../../../constants/applicationBuilder.actionTypes';
import { FIELD_IDS } from '../constants/navigationProperties.constants';

const handleInit = ({ getState, setState }) => {
  const { selectedComponent, applicationConfig } = getState();

  const formValues = getNavigationProperties(selectedComponent, applicationConfig);
  setState({ formValues });
};

const handleUpdatedApplicationConfig = ({ getState, params }) => {
  const id = tget(params, 'id');
  const { formValues, applicationBuilderOnAction, applicationConfig } = getState();
  const { updatedApplicationConfig } = setNavigationPropertiesInApplicationConfig(applicationConfig, formValues, id);

  applicationBuilderOnAction({
    type: APPLICATION_BUILDER_ACTION_TYPES.SET_APPLICATION_CONFIG,
    payload: { applicationConfig: updatedApplicationConfig },
  });
};

const handleOnChange = ({ getState, setState, params }) => {
  const { formValues } = getState();
  const { id, value } = params;
  if ((id === FIELD_IDS.APPROVAL_INTEGRATION && value === false) || (id === FIELD_IDS.NAVIGATION && value === false)) {
    setState({ isRequestDialogVisible: true, confirmationModalId: id });
  } else {
    let newFormValues = { ...formValues };
    if (id === FIELD_IDS.NAVIGATION) {
      newFormValues = { ...newFormValues, [FIELD_IDS.NAVIGATION_TYPE]: 'top', [FIELD_IDS.PAGE_NAME]: [] };
    }
    newFormValues = { ...newFormValues, [id]: value };
    setState(
      {
        formValues: newFormValues,
      },
      () => handleUpdatedApplicationConfig({ getState, params: { id } }),
    );
  }
};

const handleOnCancelModalRequest = ({ setState }) => {
  setState({ isRequestDialogVisible: false });
};

const handleOnConfirmModalRequest = ({ getState, setState }) => {
  const { confirmationModalId, formValues } = getState();
  setState({ isRequestDialogVisible: false });
  let newFormValues = { ...formValues };
  if (confirmationModalId === FIELD_IDS.NAVIGATION) {
    newFormValues = {};
  }
  newFormValues = { ...newFormValues, [confirmationModalId]: false };

  setState(
    { formValues: newFormValues },

    () => handleUpdatedApplicationConfig({ getState, params: { id: confirmationModalId } }),
  );
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnChange,
  [ACTION_TYPES.ON_CANCEL_REQUEST]: handleOnCancelModalRequest,
  [ACTION_TYPES.ON_CONFIRM_REQUEST]: handleOnConfirmModalRequest,
};

export default ACTION_HANDLERS;
