exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".createView_body__9c2KvAp1jp{overflow-y:scroll}.createView_form__i76AvdJ7eP{max-width:130rem;height:calc(100% - 5rem)}.createView_formSection__rGHAwvLSEg{max-width:130rem}.createView_formSectionHeader__eieN7BiBdK{font-size:1.8rem;max-width:100rem}.createView_footerContainer__gLw9fqbYXG{height:6.4rem}.createView_halfColumn__eqmG5dL6Z9{width:calc((100% - 2.4rem)/2)}.createView_header__jeJ2QLsxWk{justify-content:space-between}", ""]);

// Exports
exports.locals = {
	"body": "createView_body__9c2KvAp1jp",
	"form": "createView_form__i76AvdJ7eP",
	"formSection": "createView_formSection__rGHAwvLSEg",
	"formSectionHeader": "createView_formSectionHeader__eieN7BiBdK",
	"footerContainer": "createView_footerContainer__gLw9fqbYXG",
	"halfColumn": "createView_halfColumn__eqmG5dL6Z9",
	"header": "createView_header__jeJ2QLsxWk"
};