import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _values from 'lodash/values';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _size from 'lodash/size';
import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _map from 'lodash/map';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { editPermissionByName, getPermissionByName } from '../../../../../../../../actions/permissionSetManagement.action';
import { fetchEntityDefByName } from '../../../../../../../../actions/entityManagement.actions';
import globalLookupItemsResolver from '../../../../../../../../actions/globalLookupItemsResolver';

import { getPayload } from '../../../../entityFieldPermissionsConfigure/formHelpers/entityFieldPermissionsConfigure.helpers';

import ACTION_TYPES from '../constants/entitySettings.actionTypes';
import { STUDIO_ROUTE } from '../../../../../../../../constants/routes';
import PAGE_IDS from '../../../../../../constants/PageIds.constants';
import { ACTION_TYPES as ADD_ENTITY_MODAL_ACTION_TYPES } from '../../assignModal/assignModal.constants';
import { TABS } from '../../../constants/permissionSetConfigure.constants';
import { COLUMN_IDS, FIELD_IDS } from '../constants/entitySettings.constants';
import { LOOKUP_ITEM_TO_RESOLVE, LOOKUP_FIELD_TO_RESOLVE } from '../../../../../../../../constants/globalLookupItemsResolver.constants';

const handleModifyPermissionData = async ({ getState, setState }) => {
  const { permissionData } = getState();
  const entityFieldPermissions = {};
  let entityAccess = _get(permissionData, 'entityPermission.entityAccess', []);
  let fieldAccess = _get(permissionData, 'fieldPermission.entityFieldsAccesses', []);

  entityAccess = _keyBy(entityAccess, COLUMN_IDS.ENTITY_NAME);
  fieldAccess = _keyBy(fieldAccess, 'entityName');

  _forEach(entityAccess, (access, entity) => {
    let fieldAccessMap = _get(fieldAccess, `${entity}.fieldAccesses`, []);
    fieldAccessMap = _keyBy(fieldAccessMap, 'fieldName');
    _set(entityFieldPermissions, `${entity}.entityAccess`, access);
    _set(entityFieldPermissions, `${entity}.fieldAccessMap`, fieldAccessMap);
  });

  const modifiedEntityFieldPermissions = _values(entityFieldPermissions);
  const entityNames = _map(modifiedEntityFieldPermissions, (modifiedEntityFieldPermission) =>
    tget(modifiedEntityFieldPermission, [FIELD_IDS.ENTITY_ACCESS, COLUMN_IDS.ENTITY_NAME]),
  );
  const response = await globalLookupItemsResolver({ [LOOKUP_ITEM_TO_RESOLVE.ENTITY]: { [LOOKUP_FIELD_TO_RESOLVE.NAME]: entityNames } }, true);
  const resolvedEntityNamesToDisplayNameMap = tget(response, LOOKUP_ITEM_TO_RESOLVE.ENTITY, EMPTY_OBJECT);

  setState({
    loading: false,
    totalCount: _size(modifiedEntityFieldPermissions),
    entityFieldPermissions: modifiedEntityFieldPermissions,
    filteredPermissionData: modifiedEntityFieldPermissions,
    trieEntityFieldPermissions: entityFieldPermissions,
    resolvedEntityNamesToDisplayNameMap,
  });
};

const handleFetchPermissionData = async ({ getState, setState }) => {
  const { match } = getState();
  setState({ loading: true });
  const permissionName = _get(match, 'params.permissionName');
  let permissionData = {};
  permissionData = await getPermissionByName(permissionName);
  setState({ permissionData }, () => {
    handleModifyPermissionData({ getState, setState });
  });
};

const rowClickHandler = ({ getState, params }) => {
  const { history, match, trieEntityFieldPermissions, permissionData } = getState();
  const entityPermissionData = _get(params, 'value.original');
  const entityName = _get(entityPermissionData, 'entityAccess.scope');
  const permissionName = _get(match, 'params.permissionName');
  history.push({
    pathname: `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_CONFIGURE}/${permissionName}/${TABS.ENTITY_SETTINGS}/${entityName}`,
    state: { entityPermissionData, trieEntityFieldPermissions, permissionData },
  });
};

const handleAddEntityClick = ({ setState }) => {
  setState({ isAddEntityModalVisible: true });
};

const handleEntityModalClose = ({ setState }) => {
  setState({ isAddEntityModalVisible: false, selectedEntityModalData: '' });
};

const handleAddEntityDropdownOnChange = ({ params, setState }) => {
  const { value = EMPTY_ARRAY } = params;
  setState({ selectedEntityModalData: value });
};
const handleEntityModalDone = async ({ getState, setState }) => {
  const { selectedEntityModalData, permissionData, match, trieEntityFieldPermissions } = getState();
  const permissionName = _get(match, 'params.permissionName');
  const entityIdsFromValue = _get(selectedEntityModalData, 'value');

  if (!_isEmpty(entityIdsFromValue)) {
    setState({ isAddEntityModalDataSubmitting: true });
    const entityDefinition = await fetchEntityDefByName(entityIdsFromValue);
    const fieldDefinitions = _get(entityDefinition, 'fieldDefinitions');
    const entityAccess = { scope: entityIdsFromValue, scopeType: 'ENTITY', actionTypes: [] };
    const fieldAccessMap = {};
    _forEach(fieldDefinitions, (fieldDef) => {
      const fieldName = _get(fieldDef, 'name');
      fieldAccessMap[fieldName] = { fieldName, actionTypes: [], recordTypeName: entityIdsFromValue };
    });
    trieEntityFieldPermissions[entityIdsFromValue] = {
      entityAccess,
      fieldAccessMap,
    };
    const modifiedEntityFieldPermissions = getPayload(trieEntityFieldPermissions, permissionData);
    await editPermissionByName(permissionName, modifiedEntityFieldPermissions);
  }

  setState({ isAddEntityModalVisible: false, selectedEntityModalData: '', isAddEntityModalDataSubmitting: false }, () => {
    handleFetchPermissionData({ getState, setState });
  });
};

const handleSearchApply = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { entityFieldPermissions } = getState();
  const searchText = _get(params, 'value');
  const filteredPermissionData = _filter(entityFieldPermissions, (permission) => {
    const entityName = _get(permission, 'entityAccess.scope');
    return _includes(entityName, searchText);
  });
  setState({ searchText, filteredPermissionData });
};

const handleRefreshListView = ({ setState, getState }) => {
  setState(() => {
    handleFetchPermissionData({ getState, setState });
  });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT_DATA]: handleModifyPermissionData,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: rowClickHandler,
  [ACTION_TYPES.ADD_ENTITY_PERMISSION]: handleAddEntityClick,
  [ADD_ENTITY_MODAL_ACTION_TYPES.ASSIGN_MODAL_CLOSE]: handleEntityModalClose,
  [ADD_ENTITY_MODAL_ACTION_TYPES.ASSIGN_DROPDOWN_ON_CHANGE]: handleAddEntityDropdownOnChange,
  [ADD_ENTITY_MODAL_ACTION_TYPES.ASSIGN_DROPDOWN_DONE]: handleEntityModalDone,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchApply,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleRefreshListView,
};

export default ACTION_HANDLERS;
