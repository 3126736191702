import _property from 'lodash/property';
import _get from 'lodash/get';
import { compose } from 'recompose';
import _reduce from 'lodash/reduce';
import _valuesIn from 'lodash/valuesIn';
import _keyBy from 'lodash/keyBy';
import _set from 'lodash/set';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';

import {
  getSimpleFieldNameFromColumn,
  getValueAsTemplate,
  joinByCommas,
  toDisplayNumberRange,
  getFormattedDateTime,
  toDisplayDateRange,
  isSystemDefinedField,
} from '../../../utils';

import FIELD_TYPES from '../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../constants/fieldDefinition.dataTypes';
import { ALL_VIEW_PROPERTY_KEYS, RENDERER_PROP_KEYS } from '../../../constants/viewBuilder.constants';

import fieldDefinitionReader from '../../../readers/fieldDefinition.reader';

const BOOL_DISPLAY_VALUES = {
  TRUE: 'True',
  FALSE: 'False',
};

const relationshipAccessor = (viewFieldName, isMultiValued) => (row) => {
  const value = _get(row, ['entity', viewFieldName], NO_DATA);
  if (!isMultiValued) {
    return value;
  } else {
    return joinByCommas(value);
  }
};

const complexAccessor = (viewFieldName) => (row) => {
  let complexValues = _get(row, `entity.${viewFieldName}`);
  if (_isObject(complexValues)) {
    complexValues = joinByCommas(_valuesIn(complexValues));
  }
  return complexValues;
};

const getDisplayNameForSingleSelect = (optionsByValue) => (value) => _get(optionsByValue, `${value}.displayName`, NO_DATA);

const getDisplayNameForMultiSelect = (optionsByValue) => (value) =>
  _reduce(value, (result, optionValue) => [...result, _get(optionsByValue, `${optionValue}.displayName`, NO_DATA)], []);

const booleanAccessor = (value) => {
  if (value == null) return NO_DATA;
  if (value) return BOOL_DISPLAY_VALUES.TRUE;
  else return BOOL_DISPLAY_VALUES.FALSE;
};

const getValueForSimpleField = (viewFieldName, fieldDefinition, dateTimeFormat) => {
  const fieldType = fieldDefinitionReader.fieldType(fieldDefinition);
  const dataType = fieldDefinitionReader.dataType(fieldDefinition);

  const optionsByValue = _keyBy(tget(fieldDefinition, 'optionConfig.options', []), 'value');
  const isMultiValued = fieldDefinitionReader.multiValued(fieldDefinition);

  let accessor;
  if (isSystemDefinedField(viewFieldName)) {
    accessor = _property(viewFieldName);
  } else {
    accessor = _property(`entity.${viewFieldName}`);
  }

  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      switch (dataType) {
        case DATA_TYPES.TEXT:
        case DATA_TYPES.NUMBER:
          return accessor;
        case DATA_TYPES.DATE:
          return compose(getFormattedDateTime(_isEmpty(dateTimeFormat) ? DATE_TIME_FORMATS.ABBREVIATED_DATE_ALPHA_MONTH : dateTimeFormat), accessor);
        case DATA_TYPES.DATE_TIME:
          return compose(getFormattedDateTime(dateTimeFormat), accessor);
        case DATA_TYPES.BOOLEAN:
          return compose(booleanAccessor, accessor);
        case DATA_TYPES.COMPLEX:
          return complexAccessor(viewFieldName);
        default:
          return accessor;
      }
    case FIELD_TYPES.MEDIA:
      return accessor;

    case FIELD_TYPES.LIST:
      return compose(joinByCommas, accessor);

    case FIELD_TYPES.RANGE:
      switch (dataType) {
        case DATA_TYPES.NUMBER:
          return compose(toDisplayNumberRange, accessor);
        case DATA_TYPES.DATE:
          return compose(toDisplayDateRange(), accessor);
        default:
          return accessor;
      }

    case FIELD_TYPES.SELECT:
      if (!isMultiValued) return compose(getDisplayNameForSingleSelect(optionsByValue), accessor);
      else return compose(joinByCommas, getDisplayNameForMultiSelect(optionsByValue), accessor);

    case FIELD_TYPES.RELATIONSHIP:
      return relationshipAccessor(viewFieldName, isMultiValued);

    default:
      return accessor;
  }
};

// When we don't get field value from entityRecord this function can be used to return fallback for fieldValue depending upon FieldType & DataType
const getFallbackValueForSimpleField = (fieldDefinition) => {
  const fieldType = fieldDefinitionReader.fieldType(fieldDefinition);
  const dataType = fieldDefinitionReader.dataType(fieldDefinition);

  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      switch (dataType) {
        case DATA_TYPES.TEXT:
          return NO_DATA;
        case DATA_TYPES.NUMBER:
          return 0;
        case DATA_TYPES.DATE:
        case DATA_TYPES.DATE_TIME:
          return NO_DATA;
        case DATA_TYPES.BOOLEAN:
          return NO_DATA;
        case DATA_TYPES.COMPLEX:
          return NO_DATA;
        default:
          return NO_DATA;
      }
    case FIELD_TYPES.MEDIA:
      return NO_DATA;

    case FIELD_TYPES.LIST:
      return [];

    case FIELD_TYPES.RANGE:
      return NO_DATA;

    case FIELD_TYPES.SELECT:
      return NO_DATA;

    case FIELD_TYPES.RELATIONSHIP:
      return NO_DATA;

    case FIELD_TYPES.CHILD_AGGREGATE_SUMMARY:
      return 0;

    default:
      return NO_DATA;
  }
};

const entityRecordAccessor = (properties, fieldDefinitionByName) => {
  // This is fieldNames array  which can have more that one field according to cellType.
  const viewFieldNames = _get(properties, ALL_VIEW_PROPERTY_KEYS.FIELD_NAMES);
  const template = _get(properties, ALL_VIEW_PROPERTY_KEYS.TEMPLATE, '');
  const dateTimeFormat = tget(properties, RENDERER_PROP_KEYS.DATE_TIME_FORMAT);

  const newAccessor = (entityRecord) => {
    const values = _reduce(
      viewFieldNames,
      (result, viewFieldName) => {
        const simpleFieldName = getSimpleFieldNameFromColumn(viewFieldName);
        const fieldDef = _get(fieldDefinitionByName, `${simpleFieldName}`);
        let fieldValue = getValueForSimpleField(viewFieldName, fieldDef, dateTimeFormat)(entityRecord);
        if (_isNil(fieldValue)) {
          fieldValue = getFallbackValueForSimpleField(fieldDef);
        }
        // getting value for each field
        _set(result, viewFieldName, fieldValue);
        return result;
      },
      {},
    );

    return getValueAsTemplate(template, values);
  };

  return newAccessor;
};

export { entityRecordAccessor };
