import _map from 'lodash/map';

const getDefaultTimeRange = () => ({
  startTime: Date.now() - 86400000,
  endTime: Date.now(),
});

const getWidgetItems = (widgetLayouts) => _map(widgetLayouts, (item) => ({ widgetName: item.widgetName }));

export { getDefaultTimeRange, getWidgetItems };
