import { defaultMemoize } from 'reselect';
import _property from 'lodash/property';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

// Validators
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

// Components
import ColumnConfig from '@tekion/tekion-components/organisms/inputTable/builders/ColumnConfig';
import CellConfig from '@tekion/tekion-components/organisms/inputTable/builders/CellConfig';
import TextInputCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/TextInputCellRenderer';

// Constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { COLUMN_IDS } from '../constants/listInputTable.general.constants';

const mapLabelProps = defaultMemoize((data = EMPTY_OBJECT) => {
  const value = _get(data, `rowData.${COLUMN_IDS.LABEL}`, '');
  return {
    value,
    placeholder: __('Enter Label'),
    error: _isEmpty(value) ? __('Please enter label') : '',
    validators: [isRequiredRule],
    required: true,
  };
});

const mapValueProps = defaultMemoize((data = EMPTY_OBJECT) => {
  const value = _get(data, `rowData.${COLUMN_IDS.VALUE}`, '');
  return {
    value,
    placeholder: __('Enter Value'),
    error: _isEmpty(value) ? __('Please enter value') : '',
    validators: [isRequiredRule],
    required: true,
  };
});

const LABEL_CELL = new CellConfig().setComponent(TextInputCellRenderer).setMapCellPropsToComponentProps((props) => mapLabelProps(props));

const VALUE_CELL = new CellConfig().setComponent(TextInputCellRenderer).setMapCellPropsToComponentProps((props) => mapValueProps(props));

const COLUMN_WIDTHS = {
  [COLUMN_IDS.LABEL]: '30rem',
  [COLUMN_IDS.VALUE]: '30rem',
};

const valueAccessor = _property(COLUMN_IDS.VALUE);
const labelAccessor = _property(COLUMN_IDS.LABEL);

const onValueChange = (data, e) => ({
  row: _get(data, 'rowIndex'),
  value: _get(e, 'target.value', ''),
});

const LABEL_COLUMN_CONFIG = new ColumnConfig(COLUMN_IDS.LABEL)
  .setHeader(__('Label'))
  .setGetValueFromOnChange(onValueChange)
  .setAccessor(labelAccessor)
  .addCellConfig(LABEL_CELL)
  .setWidth(COLUMN_WIDTHS[COLUMN_IDS.LABEL]);

const VALUE_COLUMN_CONFIG = new ColumnConfig(COLUMN_IDS.VALUE)
  .setHeader(__('Value'))
  .setGetValueFromOnChange(onValueChange)
  .setAccessor(valueAccessor)
  .addCellConfig(VALUE_CELL)
  .setWidth(COLUMN_WIDTHS[COLUMN_IDS.VALUE]);

export { LABEL_COLUMN_CONFIG, VALUE_COLUMN_CONFIG };
