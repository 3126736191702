import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _includes from 'lodash/includes';
import _valuesIn from 'lodash/valuesIn';
import { compose } from 'recompose';

import { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import Heading from '@tekion/tekion-components/atoms/Heading';
import Button from '@tekion/tekion-components/atoms/Button';
import Loader from '@tekion/tekion-components/molecules/loader';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import withActions from '@tekion/tekion-components/connectors/withActions';

import TableWidget from '../tableWidget';
import ChartWidget from '../chartWidget';
import { getColumnsFromWidget, getFiltersToRender } from './helpers/widgetContainer.helpers';

import ACTION_HANDLERS from './helpers/widgetContainer.actionHandlers';
import ACTION_TYPES from './constants/widgetContainer.actionTypes';
import { WIDGET_TYPES, CHART_WIDGET_TYPES, CHART_TYPE_MAPPER } from './constants/widgetContainer.general.constants';
import widgetReader from '../../../../readers/reportingWidget.reader';

import styles from './widgetContainer.module.scss';

const WidgetContainer = ({
  loading,
  isEditable,
  isWidgetUpdated,
  widget,
  widgetName,
  widgetLayout,
  timeRange,
  triggerRangeChange,
  entityFieldDefinitions,
  entityDisplayName,
  style,
  handleWidgetEdit,
  handleWidgetRemove,
  onAction,
  ...rest
}) => {
  const widgetType = useMemo(() => widgetReader.widgetType(widget), [widget]);

  const handleEdit = useCallback(
    () =>
      handleWidgetEdit({
        widgetName,
        widgetType: widgetType === WIDGET_TYPES.DATA_TABLE ? WIDGET_TYPES.DATA_TABLE : WIDGET_TYPES.CHART,
      }),
    [handleWidgetEdit, widgetName, widgetType],
  );

  const handleRemove = useCallback(
    () =>
      handleWidgetRemove({
        widgetName,
      }),
    [handleWidgetRemove, widgetName],
  );

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.FETCH_WIDGET,
    });
  }, [onAction]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.EDIT_WIDGET,
      payload: { isWidgetUpdated },
    });
  }, [isWidgetUpdated, onAction]);

  return (
    <div className={`full-width full-height ${styles.widget}`} key={widgetName} {...rest}>
      <PropertyControlledComponent controllerProperty={!loading} fallback={<Loader className="full-width full-height align-center" />}>
        <div className="flex flex-column full-height">
          <div className={`${styles.widgetHeader}`}>
            <Heading size={3}>{`${widgetReader.name(widget)} (${entityDisplayName})` || __('Widget')}</Heading>
            {isEditable && (
              <div>
                <Button onClick={() => handleEdit()} view="icon" icon="icon-edit" className={styles.widgetButton} />
                <Button onClick={() => handleRemove()} view="icon" icon="icon-close" className={styles.widgetButton} />
              </div>
            )}
          </div>
          <div className={`full-height ${styles.widgetBody}`}>
            <PropertyControlledComponent controllerProperty={widgetType === WIDGET_TYPES.DATA_TABLE}>
              <TableWidget
                requestPayload={getColumnsFromWidget(widget)}
                entityName={widgetReader.entityName(widget)}
                widget={widget}
                entityFieldDefinitions={entityFieldDefinitions}
                filtersToRender={getFiltersToRender(widget)}
                timeRange={timeRange}
                {...rest}
              />
            </PropertyControlledComponent>
            <PropertyControlledComponent controllerProperty={_includes(_valuesIn(CHART_WIDGET_TYPES), widgetType)}>
              <ChartWidget
                isEditable={isEditable}
                requestPayload={getColumnsFromWidget(widget)}
                entityName={widgetReader.entityName(widget)}
                widget={widget}
                entityFieldDefinitions={entityFieldDefinitions}
                chartType={CHART_TYPE_MAPPER[widgetType]}
                widgetLayout={widgetLayout}
                filtersToRender={getFiltersToRender(widget)}
                timeRange={timeRange}
                triggerRangeChange={triggerRangeChange}
                className="full-height full-width"
                {...rest}
              />
            </PropertyControlledComponent>
          </div>
        </div>
      </PropertyControlledComponent>
    </div>
  );
};

WidgetContainer.propTypes = {
  isWidgetUpdated: PropTypes.bool,
  loading: PropTypes.bool,
  isEditable: PropTypes.bool,
  widget: PropTypes.object,
  style: PropTypes.object,
  widgetName: PropTypes.string,
  entityDisplayName: PropTypes.string,
  widgetLayout: PropTypes.object,
  entityFieldDefinitions: PropTypes.array,
  handleWidgetEdit: PropTypes.func,
  handleWidgetRemove: PropTypes.func,
  timeRange: PropTypes.object,
  triggerRangeChange: PropTypes.func,
  onAction: PropTypes.func.isRequired,
};

WidgetContainer.defaultProps = {
  loading: false,
  isEditable: false,
  isWidgetUpdated: false,
  entityDisplayName: EMPTY_STRING,
  widget: EMPTY_OBJECT,
  style: EMPTY_OBJECT,
  widgetName: null,
  widgetLayout: EMPTY_OBJECT,
  entityFieldDefinitions: EMPTY_ARRAY,
  triggerRangeChange: _noop,
  timeRange: {},
  handleWidgetEdit: _noop,
  handleWidgetRemove: _noop,
};

export default compose(React.memo, withActions(EMPTY_OBJECT, ACTION_HANDLERS))(WidgetContainer);
