import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { isErrorEmpty } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getFieldsFormViewDetailsValue, getViewDetailsFormValues } from './viewDetailsForm.helpers';

import FIELDS_FORM_FIELD_IDS from '../../../constants/fieldsForm.fieldIds';
import { FIELD_FORM_CONTEXT_ID } from '../../../constants/fieldsForm.constants';

const handleFormInit = ({ getState, setState }) => {
  const { fieldsFormViewDetailsValue = EMPTY_OBJECT } = getState();
  setState({ values: getViewDetailsFormValues(fieldsFormViewDetailsValue) });
};

const handleFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { values: oldValues = EMPTY_OBJECT, fieldsFormOnAction } = getState();
  const values = { ...oldValues, [id]: value };

  setState({ values }, () =>
    fieldsFormOnAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: FIELDS_FORM_FIELD_IDS.VIEW_DETAILS_FORM, value: getFieldsFormViewDetailsValue(values) },
    }),
  );
};

const handleSubmit = ({ getState, params = EMPTY_OBJECT }) => {
  const { additional = {} } = params;
  const { formErrors } = additional;
  const { fieldsFormOnAction } = getState();

  fieldsFormOnAction({ type: FORM_ACTION_TYPES.VALIDATION_SUCCESS, payload: { id: FIELDS_FORM_FIELD_IDS.VIEW_DETAILS_FORM, errors: undefined } });
  _set(formErrors, FIELDS_FORM_FIELD_IDS.VIEW_DETAILS_FORM, false);

  if (isErrorEmpty(formErrors)) {
    triggerSubmit(FIELD_FORM_CONTEXT_ID, _cloneDeep(additional));
  }
};

const VIEW_DETAILS_FORM_ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleFormInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
};

export default VIEW_DETAILS_FORM_ACTION_HANDLERS;
