import _map from 'lodash/map';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

const ROWS = 20;

const getValueOption = (project) => ({
  ...project,
  label: tget(project, 'displayName', EMPTY_STRING) || tget(project, 'name', EMPTY_STRING),
  value: tget(project, 'name', EMPTY_STRING),
});
const getOptions = (data) => _map(data, getValueOption);

const getPayload = (searchText, nextPageToken = EMPTY_STRING) => ({
  rows: ROWS,
  nextPageToken,
  searchText,
});

export { getValueOption, getOptions, getPayload };
