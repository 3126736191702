import VIEW_DETAILS_FORM_FIELD_IDS from './viewDetailsForm.fieldIds';

import styles from '../../../fieldsForm.module.scss';

const VIEW_DETAILS_FORM_SECTIONS = [
  {
    header: {
      label: __('View Details'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [VIEW_DETAILS_FORM_FIELD_IDS.LIST_VIEW_ENABLED, VIEW_DETAILS_FORM_FIELD_IDS.DETAILED_VIEW_ENABLED],
      },
    ],
  },
];

export default VIEW_DETAILS_FORM_SECTIONS;
