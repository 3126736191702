import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import { WIDGET_NAME_CONFIGURE_FIELD_IDS } from '../constants/widgetNameConfigure.fieldIds';

const WIDGET_NAME_FIELD = {
  id: WIDGET_NAME_CONFIGURE_FIELD_IDS.WIDGET_NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Widget Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Widget Name'),
  },
};

const WIDGET_TYPE_FIELD = {
  id: WIDGET_NAME_CONFIGURE_FIELD_IDS.WIDGET_TYPE,
  renderer: TextInput,
  renderOptions: {
    label: __('Widget Type'),
    validators: [isRequiredRule],
    disabled: true,
  },
};

const WIDGET_NAME_CONFIGURE_FIELDS = {
  [WIDGET_NAME_CONFIGURE_FIELD_IDS.WIDGET_NAME]: WIDGET_NAME_FIELD,
  [WIDGET_NAME_CONFIGURE_FIELD_IDS.WIDGET_TYPE]: WIDGET_TYPE_FIELD,
};

export { WIDGET_NAME_CONFIGURE_FIELDS };
