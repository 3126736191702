import React from 'react';

// Hooks
import useFontSize from './hooks/useFontSize';

// Components
import FontSizeInput from './molecules/fontSizeInput';

const FontSizeToolBarButton = (props) => {
  const { fontSize, setFontSize, applyFontSize } = useFontSize();

  return <FontSizeInput {...props} value={fontSize} onChange={setFontSize} onSelect={applyFontSize} />;
};

export default FontSizeToolBarButton;
