// Form Validators
import { isRequiredRule, isNumericRule } from '@tekion/tekion-base/utils/formValidators';

// Components
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import Button from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/button';
import SwitchRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch/Switch';
import ListInputTable from '../components/ListInputTable';
import ControllingOptionConfigTable from '../components/controllingOptionConfigTable/ControllingOptionConfigTable';
import DependencyConfigTable from '../components/dependencyConfigTable/DependencyConfigTable';

import FIELD_IDS from './approvalSettingFieldForm.fieldIds';
import { DATA_TYPE_OPTIONS } from './approvalSettingFieldForm.constants';

import { isRegexRule } from '../../../../../../../utils/formValidators';

// Styles
import styles from '../approvalSettingFieldForm.module.scss';

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Name'),
    placeholder: __('This will be autofilled'),
  },
};

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Display Name'),
    placeholder: __('Enter field display name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const FIELD_TYPE_FIELD = {
  id: FIELD_IDS.FIELD_TYPE,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Field Type'),
    label: __('Field Type'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const DATA_TYPE_FIELD = {
  id: FIELD_IDS.DATA_TYPE,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Field Data Type'),
    label: __('Field Data Type'),
    options: DATA_TYPE_OPTIONS,
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const TEXT_MIN_LENGTH_FIELD = {
  id: FIELD_IDS.TEXT_MIN_LENGTH,
  renderer: TextInput,
  renderOptions: {
    label: __('Min Text Length'),
    placeholder: __('Enter Min length of text '),
    required: true,
    validators: [isRequiredRule, isNumericRule],
  },
};

const TEXT_MAX_LENGTH_FIELD = {
  id: FIELD_IDS.TEXT_MAX_LENGTH,
  renderer: TextInput,
  renderOptions: {
    label: __('Max Text Length'),
    placeholder: __('Enter Max length of text'),
    required: true,
    validators: [isRequiredRule, isNumericRule],
  },
};

const TEXT_REGEX_FIELD = {
  id: FIELD_IDS.TEXT_REGEX,
  renderer: TextInput,
  renderOptions: {
    placeholder: __('Regex'),
    label: __('Pattern'),
    validators: [isRegexRule],
  },
};

const LIST_INPUT_TABLE_FIELD = {
  id: FIELD_IDS.LIST_INPUT_TABLE,
  renderer: ListInputTable,
  renderOptions: {
    required: true,
    validators: [isRequiredRule],
  },
};

const LIST_ADD_ROW_BUTTON_FIELD = {
  id: FIELD_IDS.LIST_ADD_ROW_BUTTON,
  renderer: Button,
  renderOptions: {
    label: __('Add Option'),
    view: Button.VIEW.TERTIARY,
    className: styles.addRowButton,
  },
};

const MANDATORY_FIELD = {
  id: FIELD_IDS.MANDATORY,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Is Field Mandatory?'),
    defaultValue: false,
  },
};

const HELP_TEXT_FIELD = {
  id: FIELD_IDS.HELP_TEXT,
  renderer: TextInput,
  renderOptions: {
    label: __('Help Text'),
    placeholder: __('Enter Help Text'),
  },
};

const DEPENDENCY_CONFIG_TABLE_FIELD = {
  id: FIELD_IDS.DEPENDENCY_CONFIG,
  renderer: DependencyConfigTable,
  renderOptions: {},
};

const CONTROLLING_FUNCTION_ENABLED_FIELD = {
  id: FIELD_IDS.CONTROLLING_FUNCTION,
  renderer: SwitchRenderer,
  renderOptions: {
    label: __('Controlling Function'),
    defaultValue: false,
  },
};

const CONTROLLING_FIELD_NAME_FIELD = {
  id: FIELD_IDS.CONTROLLING_FIELD_NAME,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Controlling Field'),
    label: __('Controlling field'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const CONTROLLING_OPTIONS_CONFIGS_FIELD = {
  id: FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS,
  renderer: ControllingOptionConfigTable,
  renderOptions: {
    required: true,
    validators: [isRequiredRule],
  },
};

const MULTI_VALUED_ENABLED_FIELD = {
  id: FIELD_IDS.MULTI_VALUED_ENABLED,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: __('Multi Valued'),
    defaultValue: false,
  },
};

export const FORM_FIELDS = {
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.FIELD_TYPE]: FIELD_TYPE_FIELD,
  [FIELD_IDS.DATA_TYPE]: DATA_TYPE_FIELD,
  [FIELD_IDS.TEXT_MIN_LENGTH]: TEXT_MIN_LENGTH_FIELD,
  [FIELD_IDS.TEXT_MAX_LENGTH]: TEXT_MAX_LENGTH_FIELD,
  [FIELD_IDS.TEXT_REGEX]: TEXT_REGEX_FIELD,
  [FIELD_IDS.LIST_INPUT_TABLE]: LIST_INPUT_TABLE_FIELD,
  [FIELD_IDS.LIST_ADD_ROW_BUTTON]: LIST_ADD_ROW_BUTTON_FIELD,
  [FIELD_IDS.MANDATORY]: MANDATORY_FIELD,
  [FIELD_IDS.HELP_TEXT]: HELP_TEXT_FIELD,
  [FIELD_IDS.DEPENDENCY_CONFIG]: DEPENDENCY_CONFIG_TABLE_FIELD,
  [FIELD_IDS.CONTROLLING_FUNCTION]: CONTROLLING_FUNCTION_ENABLED_FIELD,
  [FIELD_IDS.CONTROLLING_FIELD_NAME]: CONTROLLING_FIELD_NAME_FIELD,
  [FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS]: CONTROLLING_OPTIONS_CONFIGS_FIELD,
  [FIELD_IDS.MULTI_VALUED_ENABLED]: MULTI_VALUED_ENABLED_FIELD,
};
