exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container_widgetContainer__fXNyy9oXmj{display:flex;flex-direction:column;overflow:auto}.container_widgetContainer__fXNyy9oXmj.container_builderMode__dst5HsnmRX{height:100%;width:100%}.container_widgetContainer__fXNyy9oXmj.container_previewMode__ciJFAyDZT6{width:100%}[dir] .container_widgetContainer__fXNyy9oXmj.container_previewMode__ciJFAyDZT6{margin-bottom:0}[dir] .container_widgetContainer__fXNyy9oXmj.container_previewMode__ciJFAyDZT6:last-child{margin-bottom:0}.container_widgetContainer__fXNyy9oXmj.container_horizontalGrid__2D3vbXXCp6{flex-direction:row}.container_widgetContainer__fXNyy9oXmj.container_verticalGrid__iMiLT4uHwQ{flex-direction:column}.container_widgetContainer__fXNyy9oXmj .container_editIconContainer__n1LXPX8hMx{display:flex;justify-content:flex-end;align-items:center}[dir] .container_widgetContainer__fXNyy9oXmj .container_editIconContainer__n1LXPX8hMx{margin:.5rem}[dir] .container_widgetContainer__fXNyy9oXmj .container_editIconContainer__n1LXPX8hMx .container_icon__verj2LGTxH{cursor:pointer}.container_widgetContainer__fXNyy9oXmj .container_verticalDropZone__g5zjKRZVyX{max-height:4rem}.container_widgetContainer__fXNyy9oXmj .container_horizontalDropZone__6khoQGyVN7{max-width:4rem}.container_scrollEnabled__cr9ncpPs7i{height:100%}", ""]);

// Exports
exports.locals = {
	"widgetContainer": "container_widgetContainer__fXNyy9oXmj",
	"builderMode": "container_builderMode__dst5HsnmRX",
	"previewMode": "container_previewMode__ciJFAyDZT6",
	"horizontalGrid": "container_horizontalGrid__2D3vbXXCp6",
	"verticalGrid": "container_verticalGrid__iMiLT4uHwQ",
	"editIconContainer": "container_editIconContainer__n1LXPX8hMx",
	"icon": "container_icon__verj2LGTxH",
	"verticalDropZone": "container_verticalDropZone__g5zjKRZVyX",
	"horizontalDropZone": "container_horizontalDropZone__6khoQGyVN7",
	"scrollEnabled": "container_scrollEnabled__cr9ncpPs7i"
};