const ACTION_DEFINITION_FIELD_IDS = {
  ACTION_NAME: 'name',
  ACTION_DISPLAY_NAME: 'displayName',
  ACTION_TYPE: 'actionType',
  ACTION_LEVEL: 'actionLevel',
  TARGET_ENTITY_NAME: 'targetEntityName',
  ENTRY_CONDITION: 'entryCondition',
  ERROR_MESSAGE_ON_ENTRY_CONDITION: 'errorMessage',
  ACTION_DEFINITION: 'prefilledData',
  ID: 'id',
  WORKFLOW_ID: 'workflowId',
  // Below fieldIds in constant format to differentiate this as a field that BE isnt taking in payload
  FILTER_CONDITIONS: 'FILTER_CONDITIONS',
  USER_INPUT: 'OUTER_USER_INPUT',
  USER_INPUT_TYPE: 'USER_INPUT_TYPE',
  SHOULD_SORT_RECORDS: 'SHOULD_SORT_RECORDS',
  VALIDATION_RULE_OVERRIDE: 'validationOverrideInfo',
  SORT_CONDITIONS: 'recordsToUpdate.sortList',
  FORM_VIEW_NAME: 'viewName',
  SELECTED_FIELDS: 'manualInput',
  RECORDS_LIMIT: 'recordsToUpdate.limit',
  LIMIT: 'limit',
  UPDATE_SAME_RECORD: 'updateSameRecord',
  SEND_EMAILS_ACTION_DEFINITION: 'sendEmailsActionDefinition',
  EMAIL_TEMPLATE: 'emailTemplate',
  CONDITION: 'condition',
  RECORDS_TO_UPDATE: 'recordsToUpdate',
  RECORD_TO_UPDATE: 'recordToUpdate',
  SEARCH_REQUEST: 'searchRequest',
  SHOULD_ASSIGN_VARIABLE: 'SHOULD_ASSIGN_VARIABLE',
  ASSIGNED_VARIABLE: 'assignedVariable',
  STATUS: 'status',
  SORT_LIST: 'sortList',
  ENTITY_NAME: 'entityName',
};

const ACTION_DEFINITION_ACTION_TYPES = {
  CREATE_RECORD: 'CREATE_RECORD',
  UPDATE_RECORD: 'UPDATE_RECORD',
  UPDATE_RECORDS: 'UPDATE_RECORDS',
  SEND_EMAIL: 'EMAIL',
  SEND_EMAILS: 'BULK_EMAIL',
  GET_RECORDS: 'GET_RECORDS',
  DELETE_RECORD: 'DELETE_RECORD',
};

const USER_INPUT_TYPES = {
  FORM: 'FORM',
  FIELD: 'FIELD',
};

const SEND_EMAILS_ADDRESSEE_TYPE = {
  STATIC: 'STATIC',
  DERIVED: 'DERIVED',
};

const SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS = {
  FROM: 'fromEmailAddress',
  TO: 'toEmailAddress',
  CC: 'cc',
  BCC: 'bcc',
  EMAIL_TEMPLATES: 'templates',
  ADDRESSEE_TYPE: 'addresseeType',
  DERIVED_ENTITY: 'entityName',
  SOURCE_FIELD: 'addresseeLookupFields',
  DERIVED_ENTITY_FIELD: 'emailField',
  SENDER_DISPLAY_NAME: 'senderDisplayName',
};

const VALIDATION_OVERRIDE_FIELD_IDS = {
  VALIDATION_OVERRIDES: 'validationOverrides',
  USER_INPUT: 'validationUserInput',
  USER_INPUT_TYPE: 'USER_INPUT_TYPE',
  FORM_VIEW_NAME: 'viewName',
  SELECTED_FIELDS: 'manualInput',
};

const VALIDATION_OVERRIDE_RULE_FIELD_IDS = {
  ERROR_MESSAGE: 'errorMessage',
  IS_MESSAGE_OVERRIDEN: 'IS_MESSAGE_OVERRIDEN',
  FORM_VIEW_NAME: 'viewName',
  SELECTED_FIELDS: 'manualInput',
  USER_INPUT: 'validationUserInput',
  USER_INPUT_TYPE: 'USER_INPUT_TYPE',
};

const ACTION_LEVEL_TYPE = {
  ENTITY: 'ENTITY',
};

export {
  ACTION_DEFINITION_FIELD_IDS,
  ACTION_DEFINITION_ACTION_TYPES,
  USER_INPUT_TYPES,
  SEND_EMAILS_ADDRESSEE_TYPE,
  SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS,
  VALIDATION_OVERRIDE_FIELD_IDS,
  VALIDATION_OVERRIDE_RULE_FIELD_IDS,
  ACTION_LEVEL_TYPE,
};
