import _map from 'lodash/map';
import _get from 'lodash/get';
import _join from 'lodash/join';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import _includes from 'lodash/includes';

import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import { EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { tget } from '@tekion/tekion-base/utils/general';
import CellViewViewer from './cellViewViewer/cellViewViewer';
import KebabRowActionCellRenderer from './kebabRowActionCellRenderer';

import TagsRenderer from '../../atoms/tagsRenderer';
import MediaCellRenderer from '../../atoms/MediaCellRenderer';
import RichTextEditorTableRenderer from '../../atoms/RichTextEditorTableRenderer/RichTextEditorTableRenderer';

import { checkIsFieldMultiValued, checkForRichTextEditorField, checkForMediaTypeField } from '../../helpers/viewBuilder.helper';
import { entityRecordAccessor } from '../../helpers/entityRecordAccessor';
import { getCellViewNameForCompoundColumnWithCellView } from './listViewRenderer.helpers';
import { getUuid } from '../../../../utils';

import { ALL_VIEW_PROPERTY_KEYS, CELL_TYPES } from '../../../../constants/viewBuilder.constants';

const getDisplayName = (column) => _get(column, 'displayName') || __('Column');

const getIdForColumn = (column) => {
  // if there is only one field in compound, id may collide with normal field
  const fieldNames = _get(column, 'fieldNames', []);
  const cellType = _get(column, 'cellType', EMPTY_STRING);

  // This is for cell view when fieldNames is empty
  if (_isEmpty(fieldNames)) {
    return getUuid();
  }

  if (cellType === CELL_TYPES.COMPOUND && _size(fieldNames) === 1) {
    return `${getArraySafeValue(fieldNames)}_`;
  }

  return _join(_get(column, 'fieldNames', []), NO_DATA);
};

const generateColumns = ({
  entity,
  configColumns,
  fieldDefinitionByName,
  showActions,
  cellViewConfigurationsByName,
  customRowActionsData,
  properties,
  actionBuilderPropBuilder,
  actionModalContextId,
}) => {
  const sortableColumnIds = tget(properties, ALL_VIEW_PROPERTY_KEYS.SORTABLE_FIELDS);

  const columns = _map(configColumns, (column) => {
    const columnProperties = column;
    const columnId = getIdForColumn(column);

    let columnProperty = {
      id: columnId,
      key: columnId,
      name: columnId,
      Header: getDisplayName(column),
      displayName: getDisplayName(column),
      accessor: entityRecordAccessor(columnProperties, fieldDefinitionByName),
      Cell: TextRenderer,
      sortable: _includes(sortableColumnIds, columnId),
    };

    const cellViewName = getCellViewNameForCompoundColumnWithCellView(column);

    if (!_isEmpty(cellViewName)) {
      columnProperty = {
        ...columnProperty,
        Cell: CellViewViewer,
        getProps: () => ({
          viewConfiguration: _get(cellViewConfigurationsByName, [cellViewName]),
          entity,
        }),
      };
    }

    const isFieldMultiValued = checkIsFieldMultiValued(column, fieldDefinitionByName);
    const isRichTextEditorField = checkForRichTextEditorField(column, fieldDefinitionByName);
    const { isMediaField, fieldDef } = checkForMediaTypeField(column, fieldDefinitionByName);

    if (isRichTextEditorField) {
      columnProperty = {
        ...columnProperty,
        Cell: RichTextEditorTableRenderer,
      };
    }

    if (isMediaField) {
      columnProperty = {
        ...columnProperty,
        Cell: MediaCellRenderer,
        getProps: () => ({
          multiple: isFieldMultiValued,
          fieldDef,
        }),
      };
    }

    if (isFieldMultiValued && !isMediaField) {
      columnProperty = {
        ...columnProperty,
        Cell: TagsRenderer,
        getProps: () => ({
          maxLimit: 1,
        }),
        minWidth: 180,
      };
    }

    return columnProperty;
  });

  if (showActions) {
    columns.push({
      id: 'rowAction',
      Cell: KebabRowActionCellRenderer,
      width: 52,
      getProps: () => ({
        entityDef: entity,
        customRowActionsData,
        actionBuilderProps: actionBuilderPropBuilder,
        actionModalContextId,
      }),
    });
  }
  return columns;
};

export { generateColumns };
