const ACTION_TYPES = {
  ON_INIT: 'ON_INIT',
  ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE: 'ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE',
  ON_CLICK_CONFIGURE_SORT_OPTIONS: 'ON_CLICK_CONFIGURE_SORT_OPTIONS',
  ON_SAVE_MODAL: 'ON_SAVE_MODAL',
  ON_CANCEL_MODAL: 'ON_CANCEL_MODAL',
  TABLE_ADD_ROW: 'TABLE_ADD_ROW',
  TABLE_REMOVE_ROW: 'TABLE_REMOVE_ROW',
  TABLE_ON_CHANGE: 'TABLE_ON_CHANGE',
};

export default ACTION_TYPES;
