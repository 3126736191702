exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".derivationConditionTab_tabContent__iVUFMrqn53{display:flex;flex-direction:column;justify-content:center;overflow-y:scroll}[dir=ltr] .derivationConditionTab_tabContent__iVUFMrqn53{margin:.8rem .8rem 1rem -1.6rem}[dir=rtl] .derivationConditionTab_tabContent__iVUFMrqn53{margin:.8rem -1.6rem 1rem .8rem}.derivationConditionTab_tabs__u1mbXb6HMY{font-size:1.6rem}[dir=ltr] .derivationConditionTab_tabs__u1mbXb6HMY{margin-right:2rem}[dir=rtl] .derivationConditionTab_tabs__u1mbXb6HMY{margin-left:2rem}.derivationConditionTab_tabContainer__qRtJN856Qx{width:15rem;display:flex;justify-content:space-between;align-items:center}.derivationConditionTab_errorInfoIcon__2SqmJQsNX3{color:red}[dir=ltr] .derivationConditionTab_errorInfoIcon__2SqmJQsNX3{margin-right:2rem}[dir=rtl] .derivationConditionTab_errorInfoIcon__2SqmJQsNX3{margin-left:2rem}[dir] .derivationConditionTab_derivationConditionContainer__mMknmo2qMN{background-color:#f4f5f6;padding:1rem 4rem;border-bottom:.002px solid gray;border-top:.002px solid gray}.derivationConditionTab_errorText__ncfor8hcku{color:red;font-size:small}", ""]);

// Exports
exports.locals = {
	"tabContent": "derivationConditionTab_tabContent__iVUFMrqn53",
	"tabs": "derivationConditionTab_tabs__u1mbXb6HMY",
	"tabContainer": "derivationConditionTab_tabContainer__qRtJN856Qx",
	"errorInfoIcon": "derivationConditionTab_errorInfoIcon__2SqmJQsNX3",
	"derivationConditionContainer": "derivationConditionTab_derivationConditionContainer__mMknmo2qMN",
	"errorText": "derivationConditionTab_errorText__ncfor8hcku"
};