import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import _get from 'lodash/get';
import _noop from 'lodash/noop';

import { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from 'tbase/app.constants';
import { NativeSelect as Select } from 'tcomponents/molecules/advancedSelect';
import withAsyncSelect from 'tcomponents/molecules/advancedSelect/containers/withAsyncSelect';
import withMultiSelect from '@tekion/tekion-components/molecules/multiSelect/withMultiSelect';
import { tget } from '@tekion/tekion-base/utils/general';

import Option from './Options';

import { getLoadingMessage, getHasMore, getPayload, getOptions } from './customAsyncSelect.helper';
import styles from './asyncMultiSelect.module.scss';

const AsyncMultiSelect = compose(withMultiSelect, withAsyncSelect)(Select);
const AsyncSingleSelect = compose(withAsyncSelect)(Select);

const CustomAsyncSelect = ({ value, className, isMulti, onChange, getOptionsApi, tabType, existingData, ...restProps }) => {
  const [nextPageToken, setNextPageToken] = useState(EMPTY_STRING);
  const [searchText, setSearchText] = useState(EMPTY_STRING);

  const handleLoadOptions = useCallback(
    async (inputString = EMPTY_STRING) => {
      let payload = EMPTY_OBJECT;

      if (inputString !== searchText) {
        payload = getPayload(EMPTY_STRING, inputString, tabType);
        setNextPageToken(EMPTY_STRING);
        setSearchText(inputString);
      } else {
        payload = getPayload(nextPageToken, inputString, tabType);
      }
      const data = await getOptionsApi(payload);
      const hits = tget(data, 'hits', EMPTY_ARRAY);
      const currentNextPageToken = _get(data, 'nextPageToken');
      const newOptions = getOptions(hits, existingData, tabType);
      const hasMore = getHasMore(currentNextPageToken);
      setNextPageToken(currentNextPageToken);
      return { options: newOptions, hasMore };
    },
    [nextPageToken, searchText, existingData, tabType, getOptionsApi],
  );

  return isMulti ? (
    <AsyncMultiSelect
      {...restProps}
      components={{ Option }}
      refreshOptions
      onChange={onChange}
      openMenuOnFocus
      loadOptions={handleLoadOptions}
      value={value}
      loadingMessage={getLoadingMessage}
      newSelect
    />
  ) : (
    <AsyncSingleSelect
      {...restProps}
      refreshOptions
      onChange={onChange}
      openMenuOnFocus
      loadOptions={handleLoadOptions}
      value={value}
      loadingMessage={getLoadingMessage}
    />
  );
};
CustomAsyncSelect.propTypes = {
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  className: PropTypes.string,
  tabType: PropTypes.string,
  history: PropTypes.object,
  existingData: PropTypes.array,
  onChange: PropTypes.func,
  getOptionsApi: PropTypes.func,
};

CustomAsyncSelect.defaultProps = {
  isMulti: false,
  placeholder: __('Type Here'),
  value: EMPTY_ARRAY,
  className: styles.select,
  tabType: EMPTY_STRING,
  history: EMPTY_OBJECT,
  existingData: EMPTY_ARRAY,
  onChange: _noop,
  getOptionsApi: _noop,
};

export default withRouter(CustomAsyncSelect);
