import COLUMN_IDS from './entityRecordGroupList.columnIds';

const DEFAULT_RECORD_GROUP_LIST_PROPS = {
  currentPage: 0,
  pageSize: 50,
  searchField: COLUMN_IDS.RULE_NAME,
};

const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

const RECORD_GROUP_STATUS = {
  ACTIVE: true,
  INACTIVE: false,
};

export { DEFAULT_RECORD_GROUP_LIST_PROPS, TABLE_CONSTANTS, RECORD_GROUP_STATUS };
