import React, { useCallback, useEffect, useMemo } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import WithSize from '../../../../../../connectors/withSize';

import getServerCredentialFormFields from './helpers/serverCredentialForm.fields';
import SERVER_CREDENTIAL_FORM_SECTION from './helpers/serverCredentialForm.sections';
import ACTION_HANDLERS from './helpers/serverCredentialForm.actionHandlers';

import { FORM_MODES } from '../../../../../../constants/general.constants';
import ACTION_TYPES from './constants/serverCredentialForm.actionTypes';
import { SERVER_CREDENTIAL_FORM_CONTEXT_ID } from './constants/serverCredentialForm.constants';

const ServerCredentialForm = ({ isDataLoading, isSaveLoading, contentHeight, formMode, serverCredentialFormValue, errors, onAction }) => {
  const formHeading = useMemo(() => (formMode === FORM_MODES.CREATE ? __('Create Server Credential') : __('Edit Server Credential')), [formMode]);
  const fields = useMemo(() => getServerCredentialFormFields(formMode), [formMode]);

  const handleRedirect = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_REDIRECTION,
    });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(SERVER_CREDENTIAL_FORM_CONTEXT_ID);
  }, []);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction]);

  return (
    <Page className="full-height full-width">
      <Page.Header hasBack goBackHandler={handleRedirect}>
        <Heading className="full-width">{formHeading}</Heading>
      </Page.Header>

      <Page.Body style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          isFetching={isDataLoading}
          contextId={SERVER_CREDENTIAL_FORM_CONTEXT_ID}
          fields={fields}
          sections={SERVER_CREDENTIAL_FORM_SECTION}
          values={serverCredentialFormValue}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>

      <Page.Footer>
        <SaveComponent
          primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Create') : __('Update')}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleRedirect}
          primaryActionLoading={isSaveLoading}
        />
      </Page.Footer>
    </Page>
  );
};

ServerCredentialForm.propTypes = {
  isDataLoading: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  serverCredentialFormValue: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ServerCredentialForm.defaultProps = {
  isDataLoading: false,
  isSaveLoading: false,
  formMode: FORM_MODES.CREATE,
  serverCredentialFormValue: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default compose(WithSize({ hasPageFooter: 1, hasPageHeader: 1 }), withActions({}, ACTION_HANDLERS))(ServerCredentialForm);
