import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';

import getFields from '../helpers/customActionsVisualConfigurator.fields';
import { SECTIONS } from '../constants/customActionsVisualConfigurator.sections';
import { ACTIONS_CONFIGURATOR_FORM_CONTEXT_ID } from '../constants/customActionsVisualConfigurator.constants';

import styles from '../customActionsVisualConfigurator.module.scss';

const CustomActionsVisualConfiguratorModal = ({ entityName, formValues, errors, onAction }) => {
  const fields = useMemo(() => getFields(entityName), [entityName]);

  return (
    <div className={styles.customActionsModal}>
      <FormWithSubmission
        className="full-width p-t-12"
        contextId={ACTIONS_CONFIGURATOR_FORM_CONTEXT_ID}
        fields={fields}
        sections={SECTIONS}
        values={formValues}
        errors={errors}
        onAction={onAction}
      />
    </div>
  );
};

CustomActionsVisualConfiguratorModal.propTypes = {
  entityName: PropTypes.string,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

CustomActionsVisualConfiguratorModal.defaultProps = {
  entityName: EMPTY_STRING,
  errors: EMPTY_OBJECT,
  formValues: EMPTY_OBJECT,
};

export default CustomActionsVisualConfiguratorModal;
