import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import { tget } from '@tekion/tekion-base/utils/general';

// Input Field
import Input from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';

// Constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

// Styles\
import styles from './personNameInput.module.scss';

const PersonNameInput = ({ id, firstNameKey, lastNameKey, fieldClassName, label, value, error, required, onAction, ...restProps }) => {
  const handleAction = useCallback(
    (action) => {
      const { type, payload = EMPTY_OBJECT } = action;
      if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
        const { id: fieldId, value: fieldValue } = payload;
        onAction({
          type,
          payload: {
            id,
            value: {
              ...value,
              [fieldId]: fieldValue,
            },
          },
        });
      } else {
        onAction(action);
      }
    },
    [id, value, onAction],
  );

  return (
    <div className={styles.container}>
      <Input
        required={required}
        id={firstNameKey}
        label={label}
        fieldClassName={`${fieldClassName} ${styles.firstNameField}`}
        value={tget(value, firstNameKey, '')}
        error={tget(error, firstNameKey, '')}
        placeholder={__('First name')}
        onAction={handleAction}
        {...restProps}
      />
      <Input
        id={lastNameKey}
        label=""
        fieldClassName={`${fieldClassName} ${styles.lastNameField}`}
        value={tget(value, lastNameKey, '')}
        error={tget(error, lastNameKey, '')}
        placeholder={__('Last name')}
        onAction={handleAction}
        {...restProps}
      />
    </div>
  );
};

PersonNameInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  firstNameKey: PropTypes.string.isRequired,
  lastNameKey: PropTypes.string.isRequired,
  fieldClassName: PropTypes.string,
  value: PropTypes.object,
  error: PropTypes.object,
  onAction: PropTypes.func,
};

PersonNameInput.defaultProps = {
  label: '',
  required: false,
  fieldClassName: '',
  value: EMPTY_OBJECT,
  error: EMPTY_OBJECT,
  onAction: _noop,
};

export default PersonNameInput;
