import produce from 'immer';
import { handleActions } from 'redux-actions';

import { LIST_VIEW_REDUCER_ACTION_TYPES } from '../constants/reducers.actionTypes';

const INITIAL_STATE = {};

const handleSetViewContextInReduxState = produce((state, { payload }) => ({
  ...state,
  ...payload,
}));

const listViewReducer = handleActions(
  {
    [LIST_VIEW_REDUCER_ACTION_TYPES.SET_LIST_VIEW_FILTERS_CONTEXT_IN_REDUX]: handleSetViewContextInReduxState,
  },
  INITIAL_STATE,
);

export default listViewReducer;
