exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .templateRenderer_container__6AiRS8poAj{background-color:#fcfcfc;padding:1.6rem;margin-bottom:1.6rem;border:.1rem dashed #969aa3;border-radius:.5rem}.templateRenderer_flexRow__39cqL8eKMk{display:flex;justify-content:flex-start;align-items:flex-start}[dir] .templateRenderer_valueContainer__rmB49x2qgC{margin:0 2.4rem}.templateRenderer_templateSelectContainer__a7fVZQNrv1{width:60%}", ""]);

// Exports
exports.locals = {
	"container": "templateRenderer_container__6AiRS8poAj",
	"flexRow": "templateRenderer_flexRow__39cqL8eKMk",
	"valueContainer": "templateRenderer_valueContainer__rmB49x2qgC",
	"templateSelectContainer": "templateRenderer_templateSelectContainer__a7fVZQNrv1"
};