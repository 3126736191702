import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import Modal from '@tekion/tekion-components/molecules/Modal';
import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { FORM_FIELDS, FORM_SECTIONS } from './helpers/editDashboardComponent.formConfig';

const EditDashboardModal = (props) => {
  const { isSubmitting, modalVisible, handleCancel, handleSubmit, formValues, onAction } = props;

  return (
    <Modal
      title={__('Create/Edit Dashboard')}
      visible={modalVisible}
      onCancel={handleCancel}
      width={Modal.SIZES.M}
      destroyOnClose
      onSubmit={handleSubmit}
      loading={isSubmitting}
    >
      <FormBuilder fields={FORM_FIELDS} values={formValues} onAction={onAction} sections={FORM_SECTIONS} />
    </Modal>
  );
};

EditDashboardModal.propTypes = {
  isSubmitting: PropTypes.bool,
  modalVisible: PropTypes.bool,
  formValues: PropTypes.object,
  onAction: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
};

EditDashboardModal.defaultProps = {
  isSubmitting: false,
  modalVisible: true,
  formValues: EMPTY_OBJECT,
  handleCancel: _noop,
  handleSubmit: _noop,
};

export default EditDashboardModal;
