exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".fieldAndChangedValuePair_container__iqBkpb5VMw{display:flex;align-items:center}[dir=ltr] .fieldAndChangedValuePair_container__iqBkpb5VMw{padding:0 3.2rem 1.6rem 0}[dir=rtl] .fieldAndChangedValuePair_container__iqBkpb5VMw{padding:0 0 1.6rem 3.2rem}.fieldAndChangedValuePair_label__rkrjKeBprU{font-size:1.2rem;font-family:\"Proxima-Nova-Light\";line-height:1.4rem}.fieldAndChangedValuePair_value__n6hobeQmx7{display:flex;font-size:1.4rem;font-family:\"Proxima-Nova-Medium\";align-items:center}[dir=ltr] .fieldAndChangedValuePair_value__n6hobeQmx7{margin-left:2rem}[dir=rtl] .fieldAndChangedValuePair_value__n6hobeQmx7{margin-right:2rem}[dir] .fieldAndChangedValuePair_value__n6hobeQmx7 .fieldAndChangedValuePair_prevValue__ZX5BnrXbf8{background-color:#feeae6}[dir=ltr] .fieldAndChangedValuePair_value__n6hobeQmx7 .fieldAndChangedValuePair_prevValue__ZX5BnrXbf8{margin-right:1.2rem}[dir=rtl] .fieldAndChangedValuePair_value__n6hobeQmx7 .fieldAndChangedValuePair_prevValue__ZX5BnrXbf8{margin-left:1.2rem}[dir] .fieldAndChangedValuePair_value__n6hobeQmx7 .fieldAndChangedValuePair_newValue__wTN4w7We1L{background-color:#effaee}[dir=ltr] .fieldAndChangedValuePair_value__n6hobeQmx7 .fieldAndChangedValuePair_newValue__wTN4w7We1L{margin-left:1.2rem}[dir=rtl] .fieldAndChangedValuePair_value__n6hobeQmx7 .fieldAndChangedValuePair_newValue__wTN4w7We1L{margin-right:1.2rem}.fieldAndChangedValuePair_value__n6hobeQmx7 .fieldAndChangedValuePair_arrowIcon__3iqwk4HX5s{color:#969aa3}", ""]);

// Exports
exports.locals = {
	"container": "fieldAndChangedValuePair_container__iqBkpb5VMw",
	"label": "fieldAndChangedValuePair_label__rkrjKeBprU",
	"value": "fieldAndChangedValuePair_value__n6hobeQmx7",
	"prevValue": "fieldAndChangedValuePair_prevValue__ZX5BnrXbf8",
	"newValue": "fieldAndChangedValuePair_newValue__wTN4w7We1L",
	"arrowIcon": "fieldAndChangedValuePair_arrowIcon__3iqwk4HX5s"
};