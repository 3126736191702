import LS_CACHE_KEYS from '../constants/localStorage.cacheKeys';
import { getValueFromLocalStorage } from '../utils/localStorage';

const getApiToken = () => getValueFromLocalStorage(LS_CACHE_KEYS.TOKEN);

const getUserInfo = () => {
  const userInfo = getValueFromLocalStorage(LS_CACHE_KEYS.USER);

  return userInfo ? JSON.parse(userInfo) : userInfo;
};
const getProjectInfo = () => JSON.parse(getValueFromLocalStorage(LS_CACHE_KEYS.PROJECT_CONTEXT));

const getViewContexts = () => {
  const viewContext = getValueFromLocalStorage(LS_CACHE_KEYS.VIEW_CONTEXTS);

  return viewContext ? JSON.parse(viewContext) : viewContext;
};

const localStorageReader = {
  apiToken: getApiToken,
  userInfo: getUserInfo,
  projectContext: getProjectInfo,
  viewContexts: getViewContexts,
};

export default localStorageReader;
