const RECORD_GROUP_RULE_LIST_COLUMN_IDS = {
  RULE_NAME: 'name',
  RECORD_GROUP_ID: 'recordGroupId',
  RECORD_GROUP_NAME: 'recordGroupName',
  USERS: 'users',
  USER_GROUPS: 'userGroups',
  PERMISSION: 'accessType',
  READ_ONLY: 'READ_ONLY',
  READ_WRITE: 'READ_WRITE',
};

export default RECORD_GROUP_RULE_LIST_COLUMN_IDS;
