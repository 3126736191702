import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import styles from '../applicationRenderer.module.scss';

const ApplicationTabHeader = ({ iconToRender, title }) => (
  <div className={styles.tabHeader}>
    <PropertyControlledComponent controllerProperty={!_isEmpty(iconToRender)}>
      <FontIcon size={FontIcon.SIZES.L} className={styles.iconClassName}>
        {iconToRender}
      </FontIcon>
    </PropertyControlledComponent>
    <span className={styles.displayName}> {__('{{title}}', { title })}</span>
  </div>
);

ApplicationTabHeader.propTypes = {
  iconToRender: PropTypes.string,
  title: PropTypes.string.isRequired,
};

ApplicationTabHeader.defaultProps = {
  iconToRender: EMPTY_STRING,
};

export default ApplicationTabHeader;
