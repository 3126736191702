import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { getCompactedFiltersPayload } from '../../../../../helpers/general.helpers';
import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../constants/viewBuilder.constants';

const getPayloadForViewDefinitionSearch = (entityNames, viewTypes, viewConfigTypes) => {
  const filters = [
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      values: entityNames,
      filterType: OPERATORS.IN,
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
      values: viewTypes,
      filterType: OPERATORS.IN,
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_CONFIG_TYPE,
      values: viewConfigTypes,
      filterType: OPERATORS.IN,
    },
  ];

  return { filters: getCompactedFiltersPayload(filters) };
};

export { getPayloadForViewDefinitionSearch };
