import { defaultMemoize } from 'reselect';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

// Components
import TextArea from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import RadioRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio/Radio';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import DerivationCondition from '../components/derivationCondition/DerivationCondition';
import { nameValidator } from '../../../../../utils/formValidators';
import { isConditionRequiredRule } from '../../../../../organisms/conditionBuilder/helpers/conditionBuilder.general.helpers';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import { FIELD_IDS, STATUS_OPTIONS } from '../constants/recordType.constants';
import { FORM_MODES } from '../../../../../constants/general.constants';
import CONDITION_BUILDER_MODES from '../../../../../organisms/conditionBuilder/constants/conditionBuilder.modes';

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Display Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Display Name'),
  },
};

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Name'),
    placeholder: __('This will be autofilled'),
  },
};

const DESCRIPTION_FIELD = {
  id: FIELD_IDS.DESCRIPTION,
  renderer: TextArea,
  renderOptions: {
    rows: 3,
    label: __('Description'),
  },
};

const STATUS_FIELD = {
  id: FIELD_IDS.STATUS,
  renderer: RadioRenderer,
  renderOptions: {
    label: __('Status'),
    required: true,
    validators: [isRequiredRule],
    radios: STATUS_OPTIONS,
    labelClassName: 'm-b-8',
  },
};

const FORM_FIELDS = {
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DESCRIPTION]: DESCRIPTION_FIELD,
  [FIELD_IDS.STATUS]: STATUS_FIELD,
};

const getConditionField = defaultMemoize((mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject) => ({
  [FIELD_IDS.DERIVATION_CONDITION]: {
    id: FIELD_IDS.DERIVATION_CONDITION,
    renderer: DerivationCondition,
    renderOptions: {
      validators: [isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)],
      childProps: {
        [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
          childProps: {
            [CONDITION_FIELD_IDS.FIELD]: {
              label: __('Field'),
              mapOfVariableToEntityName,
              conditionBuilderFieldDefinitionObject,
              isVariablePrefixNeeded: false,
              variableNameForPrefixNotNeeded: '$record',
            },
            [CONDITION_FIELD_IDS.OPERATOR]: {
              label: __('Operator'),
              mapOfVariableToEntityName,
              conditionBuilderFieldDefinitionObject,
              isVariablePrefixNeeded: false,
              variableNameForPrefixNotNeeded: '$record',
            },
            [CONDITION_FIELD_IDS.VALUES]: {
              label: __('Value'),
              mapOfVariableToEntityName,
              conditionBuilderFieldDefinitionObject,
              isVariablePrefixNeeded: false,
              variableNameForPrefixNotNeeded: '$record',
            },
          },
        },
      },
    },
  },
}));

const getFields = (mode, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.NAME]: addToRenderOptions(NAME_FIELD, [
    { path: 'disabled', value: mode !== FORM_MODES.CREATE },
    { path: 'required', value: mode === FORM_MODES.CREATE },
    { path: 'validators', value: mode === FORM_MODES.CREATE ? [nameValidator] : EMPTY_ARRAY },
  ]),
  ...getConditionField(mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject),
});

export { getFields };
