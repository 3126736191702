import React, { useContext } from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import ViewBuilderContext from '../../ViewBuilder.context';

import styles from '../../organisms/viewViewer/viewViewer.module.scss';

function DropZoneContainer({ id, index, className, onHoverClassName, acceptedComponentTypes }) {
  const { onDrop } = useContext(ViewBuilderContext);
  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: acceptedComponentTypes,
    drop: (...args) => onDrop(id, index, ...args),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={dropRef}
      className={cx(className, {
        [styles.dropZoneHover]: canDrop && isOver,
        [onHoverClassName]: canDrop && isOver,
      })}
    />
  );
}

DropZoneContainer.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
  onHoverClassName: PropTypes.string,
  acceptedComponentTypes: PropTypes.arrayOf(PropTypes.string),
};

DropZoneContainer.defaultProps = {
  className: EMPTY_STRING,
  onHoverClassName: EMPTY_STRING,
  acceptedComponentTypes: [],
};

export default DropZoneContainer;
