import React, { useMemo, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';
// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

// Components
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import Loader from '@tekion/tekion-components/molecules/loader';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import withSize from '../../../../../connectors/withSize';
import { ACTION_TYPES, CONTEXT_ID, FIELD_IDS, GROUP_TYPES } from './constants/createUserGroup.constants';
import ACTION_HANDLERS from './helpers/createUserGroup.actionHandlers';
import { getSections, getFields } from './helpers/createUserGroup.helpers';
import UserListTable from './components/userListTable';
import { FORM_MODES } from '../../../../../constants/general.constants';
import { INITIAL_STATE } from './constants/createUserGroup.config';
// Styles
import styles from './createUserGroup.module.scss';

const CreateUserGroup = ({
  history,
  values,
  isConditionFieldsLoading,
  fieldDefinitions,
  errors,
  onAction,
  formMode,
  contentHeight,
  loading,
  isSaving,
  isTenantUniverse,
  tableData,
  isModalVisible,
  modalData,
  mapOfVariableToEntityName,
  conditionBuilderFieldDefinitionObject,
  systemResources,
}) => {
  const [showCancelModal, setShowCancelModal] = useState(false);

  const handleSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, []);

  const handleCancel = useCallback(() => {
    setShowCancelModal(true);
  }, []);

  const handleConfirmationDialogSubmit = useCallback(() => {
    setShowCancelModal(false);
    history.goBack();
  }, [history]);

  const handleConfirmationDialogCancel = useCallback(() => {
    setShowCancelModal(false);
  }, []);

  const formSections = useMemo(() => getSections(values, isTenantUniverse), [values, isTenantUniverse]);
  const formFields = useMemo(
    () => getFields(isTenantUniverse, systemResources, fieldDefinitions, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject),
    [fieldDefinitions, isTenantUniverse, systemResources, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject],
  );

  const isTableVisible = isTenantUniverse || _get(values, FIELD_IDS.GROUP_TYPE, '') === GROUP_TYPES.STATIC;
  const heading = formMode === FORM_MODES.CREATE ? __('Create User Group') : __('Edit User Group');

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.INIT,
    });
  }, [onAction]);

  if (loading || _isEmpty(formMode) || isConditionFieldsLoading) {
    return <Loader id={CONTEXT_ID} />;
  }

  return (
    <Page>
      <Page.Header hasBack>
        <Heading>{heading}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight }} className={styles.body}>
        <FormWithSubmission
          contextId={CONTEXT_ID}
          className={`full-width p-3 ${styles.form}`}
          sections={formSections}
          fields={formFields}
          errors={errors}
          values={values}
          onAction={onAction}
        />
        <PropertyControlledComponent controllerProperty={isTableVisible}>
          <div className={styles.inputTable}>
            <UserListTable isModalVisible={isModalVisible} data={tableData} modalData={modalData} history={history} onAction={onAction} />
          </div>
        </PropertyControlledComponent>
        <PropertyControlledComponent controllerProperty={showCancelModal}>
          <ConfirmationDialog
            isVisible={showCancelModal}
            title={__('Cancel Changes')}
            content={__('Are you sure you want to cancel all the changes made by you? All data will be lost if you click yes.')}
            onSubmit={handleConfirmationDialogSubmit}
            onCancel={handleConfirmationDialogCancel}
          />
        </PropertyControlledComponent>
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          primaryActionLoading={isSaving}
          primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Create') : __('Update')}
          onSecondaryAction={handleCancel}
          onPrimaryAction={handleSubmit}
        />
      </Page.Footer>
    </Page>
  );
};

CreateUserGroup.propTypes = {
  contentHeight: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  isSaving: PropTypes.bool,
  isTenantUniverse: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  isConditionFieldsLoading: PropTypes.bool,
  history: PropTypes.object.isRequired,
  errors: PropTypes.object,
  values: PropTypes.object,
  formMode: PropTypes.string,
  modalData: PropTypes.object,
  tableData: PropTypes.array,
  fieldDefinitions: PropTypes.array,
  systemResources: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

CreateUserGroup.defaultProps = {
  loading: false,
  isSaving: false,
  isTenantUniverse: false,
  isModalVisible: false,
  isConditionFieldsLoading: false,
  modalData: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  values: EMPTY_OBJECT,
  formMode: '',
  tableData: EMPTY_ARRAY,
  fieldDefinitions: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  systemResources: EMPTY_ARRAY,
};

export default compose(withActions(INITIAL_STATE, ACTION_HANDLERS), withSize({ hasPageFooter: true, hasPageHeader: true }))(CreateUserGroup);
