import { COMPONENT_TYPE_OPTIONS_VALUES } from '../../metadataComponentForm/constants/metadataComponentForm.general.constants';

import ROUTES from '../../../constants/routes';

const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

const INITIAL_STATE_COMPONENT_LIST_PROPS = {
  currentPage: 0,
  pageSize: 50,
};

const TABLE_MANAGER_PROPS = {
  showHeader: true,
  showSearch: true,
  showFilter: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const HEADER_PROPS = {
  label: __('Component List'),
};

const INCLUDED_BY_FILTER_OPTIONS = {
  USER: { label: __('User'), value: 'USER' },
};

const BUNDLE_STATE = {
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
};

const COMPONENT_TYPE_FILTER_OPTIONS = {
  ENTITY_DEFINITIONS: { label: __('Entity Definition'), value: COMPONENT_TYPE_OPTIONS_VALUES.ENTITY_DEFINITION },
  VIEW_DEFINITIONS: { label: __('View Definition'), value: COMPONENT_TYPE_OPTIONS_VALUES.VIEW_DEFINITION },
  ACTION_DEFINITIONS: { label: __('Action Definition'), value: COMPONENT_TYPE_OPTIONS_VALUES.ACTION_DEFINITION },
  ENTITY_VIEW_DEFINITIONS: { label: __('Entity View Definition'), value: COMPONENT_TYPE_OPTIONS_VALUES.ENTITY_VIEW_DEFINITION },
  FIELD_DEFINITIONS: { label: __('Field Definition'), value: COMPONENT_TYPE_OPTIONS_VALUES.FIELD_DEFINITION },
  VALIDATION_RULE: { label: __('Validation Rule'), value: COMPONENT_TYPE_OPTIONS_VALUES.VALIDATION_RULE },
};

const BUNDLE_NAVIGATION_DEFAULT_DATA = [{ label: __('Bundles'), goTo: ROUTES.EXPORT_LIST_ROUTE, key: '1' }];

export {
  TABLE_CONSTANTS,
  INITIAL_STATE_COMPONENT_LIST_PROPS,
  TABLE_MANAGER_PROPS,
  HEADER_PROPS,
  INCLUDED_BY_FILTER_OPTIONS,
  COMPONENT_TYPE_FILTER_OPTIONS,
  BUNDLE_NAVIGATION_DEFAULT_DATA,
  BUNDLE_STATE,
};
