import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _isEmpty from 'lodash/isEmpty';

import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import HelperText from '@tekion/tekion-components/atoms/HelperText';
import { ICON_TYPES, ICON_COLOR, PASSWORD_STATUS } from '../updatePasswordPage.constants';

import styles from './passwordValidation.module.scss';

const PasswordValidationRenderer = ({ label, error, newPassword }) => {
  const isValidationSuccess = newPassword && _isEmpty(error);
  const shouldShowErrors = !_isEmpty(newPassword);
  const validationStatus = isValidationSuccess ? PASSWORD_STATUS.PASS : PASSWORD_STATUS.FAIL;
  const status = shouldShowErrors ? validationStatus : PASSWORD_STATUS.INITIAL;
  const textType = shouldShowErrors ? 'error' : 'assistive';

  return (
    <div className={styles.container}>
      <FontIcon color={ICON_COLOR[status]} className={styles.icon} size={SIZES.S}>
        {ICON_TYPES[status]}
      </FontIcon>
      <HelperText type={textType} className={cx({ [styles[status]]: shouldShowErrors })}>
        {label}
      </HelperText>
    </div>
  );
};

PasswordValidationRenderer.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  newPassword: PropTypes.string.isRequired,
};

export default PasswordValidationRenderer;
