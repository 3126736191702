import ROUTES from '../../../../constants/routes';
import { COLUMN_IDS } from '../components/entityFieldPermissionTable/constants/entityFieldPermissionTable.constants';

const DEFAULT_PERMISSION_FORM_PROPS = {
  columnHeaderCheckbox: { [COLUMN_IDS.DELETE]: true, [COLUMN_IDS.CREATE]: true, [COLUMN_IDS.UPDATE]: true, [COLUMN_IDS.READ]: true },
};
const entityFieldPermissionsIds = [COLUMN_IDS.CREATE, COLUMN_IDS.DELETE, COLUMN_IDS.READ, COLUMN_IDS.UPDATE];

const PERMISSION_SET_NAVIGATION_DEFAULT_DATA = [{ label: __('Permission sets'), goTo: ROUTES.PERMISSION_SET_LIST_ROUTE, key: '1' }];

export { DEFAULT_PERMISSION_FORM_PROPS, entityFieldPermissionsIds, PERMISSION_SET_NAVIGATION_DEFAULT_DATA };
