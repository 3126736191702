import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

const INITIAL_STATE = {
  values: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

const EXPORT_FORM_CONTEXT_ID = 'METADATA_EXPORT_FORM';

const STATUS = {
  FAILED: 'failed',
  SUCCESS: 'success',
};

export { STATUS, EXPORT_FORM_CONTEXT_ID, INITIAL_STATE };
