import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';

import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { generateFunction } from '../../../../../../factories/functionFactory';
import { MenuOptionBuilder } from '../../../../../../atoms/menu';

import { VIEW_CONFIGURATION_GENERAL_KEYS, ALL_VIEW_PROPERTY_KEYS } from '../../../../../../constants/viewBuilder.constants';
import { MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT } from '../../../../../../constants/eventHandlers.constants';

const checkIsOptionEnabled = (script, entityRecord) => {
  let enable = true;

  if (!_isEmpty(script)) {
    try {
      const scriptFuncToExecute = generateFunction([MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$RECORD], script);
      enable = scriptFuncToExecute(entityRecord);
    } catch (error) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage(error));
    }
  }

  return enable;
};

const generateOptions = (customRowActionsData, entityRecord, onOptionClick) => {
  const MenuOptionObject = new MenuOptionBuilder();

  _forEach(customRowActionsData, (customRowAction) => {
    const customRowActionRendererProps = tget(customRowAction, VIEW_CONFIGURATION_GENERAL_KEYS.RENDERER_PROPS, EMPTY_ARRAY);

    const actionEnableScriptRendererPropValue = _find(customRowActionRendererProps, { type: ALL_VIEW_PROPERTY_KEYS.ENABLE_ACTION_SCRIPT });
    const actionEnableScript = tget(actionEnableScriptRendererPropValue, 'value');

    const enable = checkIsOptionEnabled(actionEnableScript, entityRecord);

    const displayName = tget(customRowAction, 'displayName', '');
    const id = tget(customRowAction, 'name');

    const option = { label: displayName, id, onClick: onOptionClick };

    if (!enable) {
      const disableMessage = __('Record could not qualify for this action');

      _set(option, 'disabled', true);
      _set(option, 'disableMessage', disableMessage);
    }

    MenuOptionObject.addOption({
      label: option.label,
      id: option.id,
      disabled: option.disabled,
      disableMessage: option.disableMessage,
      onClick: option.onClick,
    });
  });

  return MenuOptionObject.getOptions();
};

export { generateOptions };
