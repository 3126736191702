import { defaultMemoize } from 'reselect';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _forEach from 'lodash/forEach';

import { EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { DATE_TIME_FORMATS, getFormattedDateTime } from '@tekion/tekion-base/utils/dateUtils';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { ACTION_LEVEL, ENTITY, ENTITY_NAME, STATUSES, TEXT_STARTS_WITH } from '../constants/actionBuilderList.constants';

const getModifiedBy = (users, id) => {
  const user = _head(_filter(users, (item) => item.id === id));
  return _get(user, 'entity.displayName', NO_DATA);
};

const getTime = (createdTime = EMPTY_STRING) => getFormattedDateTime(createdTime, DATE_TIME_FORMATS.DATE_MONTH_YEAR);

const getActionBuilderList = (data, users) => {
  const actionBuilderListData = _map(data, (item) => {
    const userName = getModifiedBy(users, _get(item, 'lastModifiedBy', ''));
    const status = _get(item, 'status');

    if (_isEmpty(status)) {
      return {
        ...item,
        modifiedBy: `${userName}, ${getTime(item.modifiedTime)} `,
        status: STATUSES.INACTIVE,
      };
    } else
      return {
        ...item,
        modifiedBy: `${userName}, ${getTime(item.modifiedTime)} `,
      };
  });

  return actionBuilderListData;
};

const getPayloadForModifiedByIds = (Ids) => [
  {
    field: 'id',
    values: Ids,
    filterType: OPERATORS.IN,
  },
];

const getRawData = defaultMemoize(({ searchText, searchField, selectedFilters, pageSize, currentPage, nextPageToken, entityName }) => {
  let payload = { rows: pageSize, start: currentPage * pageSize };
  const filters = [
    {
      field: ACTION_LEVEL,
      values: [ENTITY],
      filterType: OPERATORS.IN,
    },
    {
      field: ENTITY_NAME,
      values: [entityName],
      filterType: OPERATORS.IN,
    },
  ];

  if (!_isEmpty(selectedFilters)) {
    _forEach(selectedFilters, (item) => {
      const searchItem = _get(item, 'values', '');
      const searchId = _get(item, 'type', '');
      if (!_isEmpty(searchItem)) {
        const newFilter = {
          field: searchId,
          values: searchItem,
          filterType: OPERATORS.IN,
        };
        filters.push(newFilter);
      }
    });
  }

  if (!_isEmpty(searchText)) {
    filters.push({
      field: searchField,
      values: [searchText],
      filterType: TEXT_STARTS_WITH,
    });
  }

  if (!_isEmpty(filters)) {
    payload = {
      filters,
      ...payload,
    };
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      nextPageToken,
      ...payload,
    };
  }
  return payload;
});

export { getActionBuilderList, getPayloadForModifiedByIds, getRawData };
