import { defaultMemoize } from 'reselect';

import _isEmpty from 'lodash/isEmpty';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';

import { EDIT_ACTION } from '../constants/globalSelectList.rowActions';
import { COLUMN_IDS } from '../constants/globalSelectList.constants';

const getRowActions = () => [EDIT_ACTION];

const getTableProps = defaultMemoize((handleRowAction, currentPage, loading, rows) => ({
  currentPage: currentPage + 1,
  loading,
  showPagination: true,
  pageSize: rows,
  showActions: true,
  resultsPerPage: rows,
  minRows: 0,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  rows,
  onRowActionClick: handleRowAction,
  getRowActions,
}));

const getPayload = defaultMemoize((pageSize, nextPageToken, searchText) => {
  let payload = { rows: pageSize };
  let filters = [];

  if (!_isEmpty(searchText)) {
    filters = [
      {
        field: COLUMN_IDS.DISPLAY_NAME,
        values: [searchText],
        filterType: OPERATORS.TEXT_STARTS_WITH,
      },
      ...filters,
    ];
  }

  if (!_isEmpty(filters)) {
    payload = {
      filters,
      ...payload,
    };
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      nextPageToken,
      ...payload,
    };
  }

  return payload;
});

export { getTableProps, getPayload };
