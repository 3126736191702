exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .metadataComponentList_tableContainer__3cxBDt6NyU{border:.1rem solid #d4d5d6;margin:2.4rem}[dir] .metadataComponentList_subHeader__uKr7zFNGx1{padding:1rem 2.4rem 2.4rem}[dir] .metadataComponentList_metadataCardHeading__pUSuQBmNfz{margin-top:1rem}.metadataComponentList_headerNavigationContainer__iAYsYk9Xy2{display:flex;flex-flow:row;font-family:\"Proxima-Nova-Regular\"}", ""]);

// Exports
exports.locals = {
	"tableContainer": "metadataComponentList_tableContainer__3cxBDt6NyU",
	"subHeader": "metadataComponentList_subHeader__uKr7zFNGx1",
	"metadataCardHeading": "metadataComponentList_metadataCardHeading__pUSuQBmNfz",
	"headerNavigationContainer": "metadataComponentList_headerNavigationContainer__iAYsYk9Xy2"
};