import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import styles from './actionRenderer.module.scss';

const ActionRenderer = ({ index, onDeleteClick }) => {
  const handleClick = useCallback(() => {
    onDeleteClick(index);
  }, [index, onDeleteClick]);

  return (
    <FontIcon className={styles.container} onClick={handleClick}>
      icon-failed
    </FontIcon>
  );
};

ActionRenderer.propTypes = {
  index: PropTypes.number.isRequired,
  onDeleteClick: PropTypes.func,
};

ActionRenderer.defaultProps = {
  onDeleteClick: _noop,
};

export default makeCellRenderer(ActionRenderer);
