import { defaultMemoize } from 'reselect';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import {
  ENTITY_RECORD_GROUP_FIELD,
  PERMISSION_FIELD,
  RECORD_GROUP_RULE_DESCRIPTION_FIELD,
  RECORD_GROUP_RULE_DISPLAY_NAME_FIELD,
  RECORD_GROUP_RULE_NAME_FIELD,
  RULE_ASSIGNMENT_FIELD,
} from '../constants/recordSharingRuleAssignModal.fields';
import { FIELD_IDS } from '../../../../../../../constants/recordSharingRule.constants';

import styles from '../recordSharingRuleAssignModal.module.scss';

const SECTION = [
  {
    className: styles.sectionContainer,
    rows: [{ columns: [FIELD_IDS.RULE_DISPLAY_NAME, FIELD_IDS.RECORD_GROUP_RULE_NAME] }],
  },
  {
    className: styles.sectionContainer,
    rows: [{ columns: [FIELD_IDS.RULE_DESCRIPTION] }],
  },
  {
    className: styles.sectionContainer,
    rows: [{ columns: [FIELD_IDS.ENTITY_RECORD_GROUP, FIELD_IDS.PERMISSION] }],
  },
  {
    className: styles.sectionContainer,
    rows: [{ columns: [FIELD_IDS.USERS] }],
  },
];

const FORM_FIELDS = {
  [FIELD_IDS.PERMISSION]: PERMISSION_FIELD,
  [FIELD_IDS.RECORD_GROUP_RULE_NAME]: RECORD_GROUP_RULE_NAME_FIELD,
  [FIELD_IDS.RULE_DESCRIPTION]: RECORD_GROUP_RULE_DESCRIPTION_FIELD,
  [FIELD_IDS.RULE_DISPLAY_NAME]: RECORD_GROUP_RULE_DISPLAY_NAME_FIELD,
};

const getFields = defaultMemoize(
  (
    userSearchText,
    userGroupSearchText,
    assignedUserList,
    assignedUserGroupList,
    filteredAssignedUserList,
    filteredAssignedUserGroupList,
    entityName,
  ) => ({
    ...FORM_FIELDS,
    [FIELD_IDS.ENTITY_RECORD_GROUP]: addToRenderOptions(ENTITY_RECORD_GROUP_FIELD, [{ path: 'entityName', value: entityName }]),
    [FIELD_IDS.USERS]: addToRenderOptions(RULE_ASSIGNMENT_FIELD, [
      { path: 'userSearchText', value: userSearchText },
      { path: 'userGroupSearchText', value: userGroupSearchText },
      { path: 'assignedUserList', value: assignedUserList },
      { path: 'assignedUserGroupList', value: assignedUserGroupList },
      { path: 'filteredAssignedUserList', value: filteredAssignedUserList },
      { path: 'filteredAssignedUserGroupList', value: filteredAssignedUserGroupList },
    ]),
  }),
);

export { SECTION, FORM_FIELDS, getFields };
