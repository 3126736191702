exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".subscriptionConfigLogs_logsHeader__rSmFmQiftx{display:flex;flex-flow:row;width:100%;height:6.4rem;align-items:center}.subscriptionConfigLogs_contentText__3dvrh1fX49{font-weight:bold;font-size:2rem}[dir] .subscriptionConfigLogs_contentText__3dvrh1fX49{margin:2rem}.subscriptionConfigLogs_logDisabledContainer__kAB3xMqm6W{display:flex;height:100%;align-items:center;justify-content:center;flex-direction:column}[dir] .subscriptionConfigLogs_logTimeFilterSection__kiTsQbX1FD{margin:2rem}.subscriptionConfigLogs_noLogContentText__ioggijioT3{display:flex;justify-content:center;font-size:1.5rem}", ""]);

// Exports
exports.locals = {
	"logsHeader": "subscriptionConfigLogs_logsHeader__rSmFmQiftx",
	"contentText": "subscriptionConfigLogs_contentText__3dvrh1fX49",
	"logDisabledContainer": "subscriptionConfigLogs_logDisabledContainer__kAB3xMqm6W",
	"logTimeFilterSection": "subscriptionConfigLogs_logTimeFilterSection__kiTsQbX1FD",
	"noLogContentText": "subscriptionConfigLogs_noLogContentText__ioggijioT3"
};