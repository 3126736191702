const WORKFLOW = 'workflowId';
const ON_LOAD = 'ON_LOAD';

const ACTION_TYPES = {
  ON_LOAD,
};

const CONTEXT_ID = 'SWITCH_WORKFLOW_FORM';
const FIELD_IDS = {
  WORKFLOW,
};

export { FIELD_IDS, CONTEXT_ID, ACTION_TYPES };
