import React from 'react';
import PropTypes from 'prop-types';
import { ToolbarButton } from '@udecode/plate-toolbar';

// components
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

// hooks
import useFileSelection from './hooks/useFileSelection';
import useFileInput from './hooks/useFileInput';

// constants
import { FILE_INPUT_STYLE } from './constants/mediaUploader.general';

function MediaUploader(props) {
  const { icon, id: nodeType, accept } = props;

  const { onClick, inputRef } = useFileInput();
  const { onFileSelection } = useFileSelection(nodeType);

  return (
    <div>
      <input type="file" accept={accept} ref={inputRef} style={FILE_INPUT_STYLE} onChange={onFileSelection} />
      <ToolbarButton icon={<FontIcon size={SIZES.MD_S}>{icon}</FontIcon>} onMouseDown={onClick} />
    </div>
  );
}

MediaUploader.propTypes = {
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
};

export default React.memo(MediaUploader);
