import produce from 'immer';

import _get from 'lodash/get';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _size from 'lodash/size';
import _castArray from 'lodash/castArray';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { toaster } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { tget } from '@tekion/tekion-base/utils/general';

import {
  addComponentToBundle,
  deleteComponentFromBundle,
  fetchComponentsOfBundle,
  getBundleById,
  publishBundleById,
} from '../../../../../actions/metadataManagement.actions';
import globalLookupItemsResolver from '../../../../../actions/globalLookupItemsResolver';

import { getComponentData, getPayload } from './metadataComponentList.helpers';
import { getPayloadForApi } from '../../metadataComponentForm/helpers/metadataComponentForm.helpers';
import PAGE_IDS from '../../../constants/PageIds.constants';
import { FIELD_IDS } from '../../metadataComponentForm/helpers/metadataComponentForm.field';
import ACTION_TYPES from '../constants/metadataComponentList.actionTypes';
import { TABLE_CONSTANTS } from '../constants/metadataComponentList.general.constants';
import { DEFAULT_FILTER_GROUP } from '../constants/metadataComponentList.filters';
import { STUDIO_ROUTE } from '../../../../../constants/routes';
import { COLUMN_IDS } from '../constants/metadataComponentList.table.constants';
import { LOOKUP_ITEM_TO_RESOLVE, LOOKUP_FIELD_TO_RESOLVE } from '../../../../../constants/globalLookupItemsResolver.constants';

const handleInit = async ({ getState, setState }) => {
  const { selectedFilters, pageToken, pageSize, match } = getState();
  const bundleId = _get(match, 'params.bundleId', EMPTY_STRING);
  const payload = getPayload(pageSize, selectedFilters, pageToken);

  setState({ loading: true });

  const componentData = await fetchComponentsOfBundle(bundleId, payload);

  let tableData = getComponentData(componentData);
  const entityNames = _map(tableData, COLUMN_IDS.ENTITY_NAME);
  const entityResponse = await globalLookupItemsResolver({ [LOOKUP_ITEM_TO_RESOLVE.ENTITY]: { [LOOKUP_FIELD_TO_RESOLVE.NAME]: entityNames } }, true);
  const entityNameToEntityDisplayNameMap = tget(entityResponse, LOOKUP_ITEM_TO_RESOLVE.ENTITY, EMPTY_OBJECT);

  tableData = _map(tableData, (rowData) => {
    const entityName = tget(rowData, COLUMN_IDS.ENTITY_NAME);

    return {
      ...rowData,
      [COLUMN_IDS.ENTITY_DISPLAY_NAME]: tget(entityNameToEntityDisplayNameMap, entityName, entityName),
    };
  });

  setState({
    components: tableData,
    loading: false,
    totalNumberOfEntries: _size(tableData),
    nextPageToken: _get(componentData, 'nextPageToken'),
  });
};

const handleBundleData = async ({ getState, setState }) => {
  const { match, history } = getState();
  const bundleId = _get(match, 'params.bundleId', EMPTY_STRING);
  const state = _get(history, 'location.state', EMPTY_OBJECT);
  let bundle = _get(state, 'bundleInfo', EMPTY_OBJECT);
  if (_isEmpty(bundle) && !_isEmpty(bundleId)) {
    bundle = await getBundleById(bundleId);
  }
  setState({ bundle });
};

const handleTableDataFetch = ({ setState, getState }) => {
  setState(
    {
      currentPage: TABLE_CONSTANTS.CURRENT_PAGE,
    },
    () => {
      handleInit({ getState, setState });
    },
  );
};

const handleSetFilter = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const filters = _get(params, 'value', EMPTY_ARRAY);
  const selectedFilterGroup = _get(params, 'selectedFilterGroup', DEFAULT_FILTER_GROUP);
  setState(
    {
      selectedFilters: filters,
      currentPage: TABLE_CONSTANTS.CURRENT_PAGE,
      selectedFilterGroup,
    },
    () => {
      handleInit({ getState, setState });
    },
  );
};

const handlePageSizeUpdate = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');

  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }
  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleInit({ getState, setState });
    },
  );
};

const handleFormErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;

  setState({ formErrors: errors });
};

const handleFormFieldChange = ({ setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  setState(
    produce((draft) => {
      if (id === FIELD_IDS.COMPONENT_TYPE) {
        _set(draft, `componentData.${id}`, value);
        _unset(draft, `componentData.${FIELD_IDS.NAME}`);
      } else {
        _set(draft, `componentData.${id}`, value);
      }
    }),
  );
};

const handleComponentConfigModalClose = ({ getState, setState }) => {
  const { isComponentAddModalOpen } = getState();
  setState({ isComponentAddModalOpen: !isComponentAddModalOpen });
};

const handleFormSubmit = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { componentData, match } = getState();
  const payload = _get(params, 'additional.payload', EMPTY_OBJECT);
  const shouldModalRemainOpen = _get(payload, 'shouldModalRemainOpen', false);
  const bundleId = _get(match, 'params.bundleId', EMPTY_STRING);
  const payloadForApi = getPayloadForApi(componentData);

  await addComponentToBundle(bundleId, payloadForApi);

  setState({ componentData: {}, currentPage: TABLE_CONSTANTS.CURRENT_PAGE }, () => {
    if (!shouldModalRemainOpen) {
      handleComponentConfigModalClose({ getState, setState });
      handleTableDataFetch({ getState, setState });
    }
  });
};

const handleBundlePublish = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const bundle = tget(params, 'bundle', EMPTY_OBJECT);
  const bundleId = tget(bundle, 'id', EMPTY_STRING);

  await publishBundleById(bundleId);

  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.EXPORTS}`;

  await handleInit({ getState, setState });

  history.push({ pathname });
};

const handleBundleSaveAsDraft = ({ getState }) => {
  const { history } = getState();
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.EXPORTS}`;

  history.push({ pathname });

  toaster('success', __('Bundle successfully saved as draft'));
};

const handleDeleteComponentFromBundle = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const bundleId = _get(params, 'bundleId', EMPTY_STRING);
  const componentId = _get(params, 'id', EMPTY_STRING);

  setState({ loading: true });

  await deleteComponentFromBundle(bundleId, componentId);
  setState({ loading: false }, () => {
    handleInit({ getState, setState });
  });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInit,
  [ACTION_TYPES.FETCH_BUNDLE_DATA]: handleBundleData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleTableDataFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleSetFilter,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageSizeUpdate,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleFormErrors,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFormFieldChange,
  [ACTION_TYPES.COMPONENT_CONFIG_MODAL]: handleComponentConfigModalClose,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleFormSubmit,
  [ACTION_TYPES.PUBLISH_BUNDLE]: handleBundlePublish,
  [ACTION_TYPES.SAVE_BUNDLE_AS_DRAFT]: handleBundleSaveAsDraft,
  [ACTION_TYPES.DELETE]: handleDeleteComponentFromBundle,
};

export default ACTION_HANDLERS;
