import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _get from 'lodash/get';

import { filterSections } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import GENERAL_DETAILS_FORM_FIELD_IDS from '../components/generalDetailsForm/constants/generalDetailsForm.fieldIds';
import FIELDS_FORM_FIELD_IDS from '../constants/fieldsForm.fieldIds';
import DATA_TYPES from '../../../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../../../constants/fieldDefinition.fieldTypes';

import styles from '../fieldsForm.module.scss';

const GENERAL_DETAILS_FORM_SECTION = {
  className: styles.sectionContainer,
  rows: [
    {
      columns: [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM],
    },
  ],
};

const DEPENDENCY_CONFIG_FORM_SECTION = {
  className: styles.sectionContainer,
  rows: [
    {
      columns: [FIELDS_FORM_FIELD_IDS.DEPENDENCY_CONFIG_FORM],
    },
  ],
};

const VIEW_DETAILS_FORM_SECTION = {
  className: styles.sectionContainer,
  rows: [
    {
      columns: [FIELDS_FORM_FIELD_IDS.VIEW_DETAILS_FORM],
    },
  ],
};

const SELECT_OPTIONS_FORM_SECTION = {
  className: styles.sectionContainer,
  rows: [
    {
      columns: [FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM],
    },
  ],
};

const TEXT_PROPERTIES_FORM_SECTION = {
  className: styles.sectionContainer,
  rows: [
    {
      columns: [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM],
    },
  ],
};

const RELATIONSHIP_DETAILS_FORM_SECTION = {
  className: styles.sectionContainer,
  rows: [
    {
      columns: [FIELDS_FORM_FIELD_IDS.RELATIONSHIP_DETAILS_FORM],
    },
  ],
};

const CHILD_AGGREGATE_SUMMARY_FORM_SECTION = {
  className: styles.sectionContainer,
  rows: [
    {
      columns: [FIELDS_FORM_FIELD_IDS.CHILD_AGGREGATE_SUMMARY_FORM],
    },
  ],
};

const COMPLEX_FIELD_DETAILS_FORM_SECTION = {
  className: styles.sectionContainer,
  rows: [
    {
      columns: [FIELDS_FORM_FIELD_IDS.COMPLEX_FIELD_DETAILS_FORM],
    },
  ],
};

const PROPERTIES_FORM_SECTION = {
  className: styles.sectionContainer,
  rows: [
    {
      columns: [FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM],
    },
  ],
};

const LOOKUP_DETAILS_FORM_SECTION = {
  className: styles.sectionContainer,
  rows: [
    {
      columns: [FIELDS_FORM_FIELD_IDS.LOOKUP_DETAILS_FORM],
    },
  ],
};

const MEDIA_PROPERTIES_FORM_SECTION = {
  className: styles.sectionContainer,
  rows: [
    {
      columns: [FIELDS_FORM_FIELD_IDS.MEDIA_PROPERTIES_FORM],
    },
  ],
};

const getSections = (values) => {
  const dataType = _get(values, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE);
  const fieldType = _get(values, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE);
  return filterSections([
    GENERAL_DETAILS_FORM_SECTION,
    {
      ...MEDIA_PROPERTIES_FORM_SECTION,
      visible: fieldType === FIELD_TYPES.MEDIA,
    },
    DEPENDENCY_CONFIG_FORM_SECTION,
    VIEW_DETAILS_FORM_SECTION,
    {
      ...TEXT_PROPERTIES_FORM_SECTION,
      visible: dataType === DATA_TYPES.TEXT && fieldType === FIELD_TYPES.TEXT,
    },
    {
      ...LOOKUP_DETAILS_FORM_SECTION,
      visible: _includes([DATA_TYPES.TEXT, DATA_TYPES.NUMBER], dataType) && fieldType === FIELD_TYPES.TEXT,
    },
    {
      ...SELECT_OPTIONS_FORM_SECTION,
      visible: dataType === DATA_TYPES.TEXT && fieldType === FIELD_TYPES.SELECT,
    },
    {
      ...RELATIONSHIP_DETAILS_FORM_SECTION,
      visible: fieldType === FIELD_TYPES.RELATIONSHIP,
    },
    {
      ...CHILD_AGGREGATE_SUMMARY_FORM_SECTION,
      visible: dataType === DATA_TYPES.NUMBER && fieldType === FIELD_TYPES.CHILD_AGGREGATE_SUMMARY,
    },
    {
      ...COMPLEX_FIELD_DETAILS_FORM_SECTION,
      visible: dataType === DATA_TYPES.COMPLEX && fieldType === FIELD_TYPES.TEXT,
    },
    {
      ...PROPERTIES_FORM_SECTION,
      visible: !_isEmpty(fieldType) && (!_isEmpty(dataType) || fieldType === FIELD_TYPES.RELATIONSHIP),
    },
  ]);
};
export default getSections;
