import _find from 'lodash/find';

import LOG_IN_DATA from '../../../constants/logInData.constants';
import localStorageReader from '../../../readers/localStorage.reader';
import loginResponseReader from '../../../readers/loginResponse.reader';

const getUserProfileNameForCurrentWorkspace = () => {
  const loginData = localStorageReader.userInfo();
  const currentWorkspaceId = loginResponseReader.workspaceId(loginData);
  const allWorkspaces = loginResponseReader.userWorkspaceDetails(loginData);

  const currentWorkspace = _find(allWorkspaces, { [LOG_IN_DATA.WORKSPACE_ID]: currentWorkspaceId });
  const profileNameForCurrentWorkspace = loginResponseReader.profileName(currentWorkspace);

  return profileNameForCurrentWorkspace;
};

export { getUserProfileNameForCurrentWorkspace };
