import _get from 'lodash/get';
import _map from 'lodash/map';
import _has from 'lodash/has';
import _castArray from 'lodash/castArray';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';

import { VIEW_TYPES } from '../../constants/viewBuilder.constants';
import { FIELD_IDS, ROWS } from './selectViewField.constants';
import { ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_FIELD_IDS } from '../../../../constants/viewBuilder.constants';

const getValuesFromProperties = (selectedViewComponentProperties) => {
  let isCardViewEnabled = false;
  let selectedViewName = EMPTY_STRING;

  if (selectedViewComponentProperties) {
    isCardViewEnabled = _has(selectedViewComponentProperties, ALL_VIEW_PROPERTY_KEYS.CARD_VIEW_NAME);
    selectedViewName = _get(selectedViewComponentProperties, ALL_VIEW_PROPERTY_KEYS.CARD_VIEW_NAME, EMPTY_STRING);
  }

  return { isCardViewEnabled, selectedViewName };
};

const getUpdatedValue = (payload) => {
  const { id, value } = payload;

  let selectedViewValue = '';

  if (id === FIELD_IDS.VIEW_NAME || (id === FIELD_IDS.IS_CARD_VIEW_ENABLED && value)) {
    selectedViewValue = id === FIELD_IDS.IS_CARD_VIEW_ENABLED ? '' : value;
  }

  return selectedViewValue;
};

const getValueOption = (viewConfig) => ({
  label: tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.DISPLAY_NAME, _get(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME)),
  value: tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME, EMPTY_STRING),
});

const getOptions = (data) => _map(data, getValueOption);

const getPayload =
  (entityName) =>
  ({ nextPageToken, searchText }) => ({
    rows: ROWS,
    nextPageToken,
    searchText,
    filters: [
      {
        field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
        values: _castArray(entityName),
        filterType: OPERATORS.IN,
      },
      {
        field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
        values: [VIEW_TYPES.DETAIL_VIEW], // as of now adding detail view as card
        filterType: OPERATORS.IN,
      },
    ],
  });

const selectCardViewFieldValidator = (fieldId, valueToTest) => {
  let isValid = true;
  const message = {};

  if (!_isEmpty(valueToTest)) {
    const valueObject = _find(valueToTest, ['type', fieldId]);

    if (_isEmpty(_get(valueObject, 'value'))) {
      isValid = false;
      message[fieldId] = __('Please select view name');
    }
  }

  return isValid ? { isValid } : { isValid, message };
};

export { getValuesFromProperties, getUpdatedValue, getOptions, getPayload, selectCardViewFieldValidator };
