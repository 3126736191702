exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".formSection_formSection__o15FZ2gMeQ{position:relative;display:flex;flex-direction:column;width:100%}[dir] .formSection_formSection__o15FZ2gMeQ .formSection_formSectionTitle__xqqPYevirB{cursor:pointer;margin-bottom:1rem;background:#fff}[dir] .formSection_formSection__o15FZ2gMeQ .formSection_formSectionTitle__xqqPYevirB>div{padding:1rem}.formSection_formSection__o15FZ2gMeQ .formSection_dropZoneGutter__uvfLDA42Hq{height:2rem}[dir] .formSection_formSection__o15FZ2gMeQ .formSection_dropZoneGutter__uvfLDA42Hq{background:#fff}.formSection_formSection__o15FZ2gMeQ .formSection_dropZoneGutter__uvfLDA42Hq:last-child{flex-grow:1}.formSection_formSection__o15FZ2gMeQ .formSection_hiddenDropZone__vDt68Y56U3{height:500%;top:-250%;width:100%}[dir=ltr] .formSection_formSection__o15FZ2gMeQ .formSection_onHoverDropZoneGutter__bVvza2jQAM{background:repeating-linear-gradient(-60deg, #ffffff, #ffe0ec 3%) !important}[dir=rtl] .formSection_formSection__o15FZ2gMeQ .formSection_onHoverDropZoneGutter__bVvza2jQAM{background:repeating-linear-gradient(60deg, #ffffff, #ffe0ec 3%) !important}.formSection_formSection__o15FZ2gMeQ .formSection_dropZoneContainer__ojwCh4ZPFg{flex-grow:1;min-height:5rem}[dir] .formSection_formSection__o15FZ2gMeQ .formSection_dropZoneContainer__ojwCh4ZPFg{background:#fff}", ""]);

// Exports
exports.locals = {
	"formSection": "formSection_formSection__o15FZ2gMeQ",
	"formSectionTitle": "formSection_formSectionTitle__xqqPYevirB",
	"dropZoneGutter": "formSection_dropZoneGutter__uvfLDA42Hq",
	"hiddenDropZone": "formSection_hiddenDropZone__vDt68Y56U3",
	"onHoverDropZoneGutter": "formSection_onHoverDropZoneGutter__bVvza2jQAM",
	"dropZoneContainer": "formSection_dropZoneContainer__ojwCh4ZPFg"
};