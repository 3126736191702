const USER_INPUT_TYPES = {
  FORM: 'FORM',
  FIELD: 'FIELD',
};
const USER_INPUT_TYPE_OPTIONS = [
  {
    label: __('Form'),
    value: USER_INPUT_TYPES.FORM,
  },
  {
    label: __('Select Fields'),
    value: USER_INPUT_TYPES.FIELD,
  },
];
export { USER_INPUT_TYPE_OPTIONS, USER_INPUT_TYPES };
