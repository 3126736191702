import _get from 'lodash/get';

import TextInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import TextAreaField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import EntityAsyncSelect from '../../../../molecules/EntityAsyncSelect/EntityAsyncSelect';

import { FORM_MODES } from '../../../../../../constants/general.constants';
import { PAGE_TYPES } from '../../../../../../constants/visualBuilder';
import { FIELD_IDS, PAGE_TYPE_OPTIONS } from './visualBuilderMetadataPage.constants';

import styles from '../visualBuilderMetadataPage.module.scss';

const TITLE_FIELD = {
  id: FIELD_IDS.PAGE_TITLE,
  renderer: TextInputField,
  renderOptions: {
    label: __('Display Name'),
    placeHolder: __('Enter display name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const NAME_FIELD = {
  id: FIELD_IDS.PAGE_NAME,
  renderer: TextInputField,
  renderOptions: {
    label: __('Name'),
    placeHolder: __('Enter name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const PAGE_TYPE_FIELD = {
  id: FIELD_IDS.PAGE_TYPE,
  renderer: SelectInput,
  renderOptions: {
    label: __('Page Type'),
    required: true,
    options: PAGE_TYPE_OPTIONS,
    validators: [isRequiredRule],
  },
};

const DESCRIPTION_FIELD = {
  id: FIELD_IDS.PAGE_DESCRIPTION,
  renderer: TextAreaField,
  renderOptions: {
    label: __('Description'),
    placeholder: __('Type Here'),
    rows: 8,
  },
};

const PAGE_ENTITY_NAME_FIELD = {
  id: FIELD_IDS.PAGE_ENTITY_NAME,
  renderer: EntityAsyncSelect,
  renderOptions: {
    label: __('Entity Name'),
    placeholder: __('Select Entity'),
    required: true,
    validators: [isRequiredRule],
  },
};

const getPageFormFields = (formMode) => ({
  [FIELD_IDS.PAGE_TITLE]: TITLE_FIELD,
  [FIELD_IDS.PAGE_TYPE]: addToRenderOptions(PAGE_TYPE_FIELD, [{ path: 'isDisabled', value: formMode === FORM_MODES.EDIT }]),
  [FIELD_IDS.PAGE_DESCRIPTION]: DESCRIPTION_FIELD,
  [FIELD_IDS.PAGE_ENTITY_NAME]: addToRenderOptions(PAGE_ENTITY_NAME_FIELD, [{ path: 'isDisabled', value: formMode === FORM_MODES.EDIT }]),
  [FIELD_IDS.PAGE_NAME]: addToRenderOptions(NAME_FIELD, [{ path: 'disabled', value: formMode === FORM_MODES.EDIT }]),
});

const rowCheck = {
  [FIELD_IDS.PAGE_ENTITY_NAME]: ({ formValues }) => getArraySafeValue(_get(formValues, [FIELD_IDS.PAGE_TYPE])) === PAGE_TYPES.RECORD_DETAIL_PAGE,
};

const getPageFormSections = (formValues) => [
  {
    className: styles.formSection,
    header: {
      label: __('Page Details'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [FIELD_IDS.PAGE_TITLE, FIELD_IDS.PAGE_NAME],
        className: styles.halfColumn,
      },
      {
        columns: [FIELD_IDS.PAGE_TYPE],
        className: styles.halfColumn,
      },
      {
        columns: filterRows([FIELD_IDS.PAGE_ENTITY_NAME], { formValues }, rowCheck),
        className: styles.halfColumn,
      },
      {
        columns: [FIELD_IDS.PAGE_DESCRIPTION],
        className: styles.halfColumn,
      },
    ],
  },
];

export { getPageFormFields, getPageFormSections };
