import _identity from 'lodash/identity';

import { tget } from '@tekion/tekion-base/utils/general';
import { NO_DATA } from '@tekion/tekion-base/app.constants';

const CREATABLE_ASYNC_PAGINATED_RESOURCE_TYPE = 'CREATABLE_ASYNC_PAGINATED_RESOURCE_TYPE';

const CREATABLE_ASYNC_SELECT_ACTION_TYPES = {
  ON_CREATE_NEW_OPTION: 'ON_CREATE_NEW_OPTION',
};

const RESOURCE_PARAMS = {
  getOptionLabel: (option) => tget(option, 'label', NO_DATA),
  getOptionValue: (option) => tget(option, 'value'),
  getOptionData: _identity,
};

const SELECT_STYLE = {
  menu: (base) => ({
    ...base,
    zIndex: 20,
  }),
};

export { CREATABLE_ASYNC_PAGINATED_RESOURCE_TYPE, RESOURCE_PARAMS, SELECT_STYLE, CREATABLE_ASYNC_SELECT_ACTION_TYPES };
