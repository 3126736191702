import React, { useCallback, useMemo } from 'react';
import ReactSelect from 'react-select';
import { compose } from 'recompose';
import cx from 'classnames';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _noop from 'lodash/noop';
import _castArray from 'lodash/castArray';

import withFetchAsyncResource from '@tekion/tekion-components/molecules/advancedSelect/containers/withAsyncResource/withFetchAsyncResource';
import WithAsyncSelectHOC from '@tekion/tekion-components/molecules/advancedSelect/containers/withAsyncSelect';

import Error from '@tekion/tekion-components/organisms/FormBuilder/components/error';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import { VirtualizedMultiAsync } from '@tekion/tekion-components/molecules/virtualizedMultiSelect';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import ClearIndicator from './ClearIndicator';

import { RESOURCE_TYPE } from './selectField.const';

import styles from './selectField.module.scss';

const AsyncSelect = compose(withFetchAsyncResource, WithAsyncSelectHOC)(ReactSelect);

const SelectField = (props) => {
  const { isMulti, id, label, fieldClassName, error, customStyles, value, options, onAction, ...restProps } = props;

  const Component = useMemo(() => (isMulti ? VirtualizedMultiAsync : AsyncSelect), [isMulti]);

  const handleLoadOptions = useCallback(async () => ({ resources: [], hasMore: false }), []);

  const onChange = useCallback(
    async (payload) => {
      const valueToSet = isMulti ? _get(payload, 'value', []) : getArraySafeValue(_get(payload, 'value', []));

      onAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: {
          id,
          value: valueToSet,
        },
      });
    },
    [isMulti, onAction, id],
  );

  const renderClearIcon = useCallback(() => <ClearIndicator id={id} values={value} onAction={onAction} />, [id, onAction, value]);

  return (
    <div className={cx(fieldLayoutStyles.fieldC, fieldClassName)}>
      <FieldLabel style={customStyles?.label} {...props} />
      <div
        className={cx(styles.field, {
          [styles.multiSelect]: isMulti,
        })}
      >
        <Component
          {...restProps}
          openMenuOnFocus
          isClearable={!isMulti}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={false}
          resourceType={RESOURCE_TYPE}
          menuPosition="fixed"
          styles={{
            menu: (base) => ({
              ...base,
              zIndex: 20,
            }),
          }}
          staticOptions={options}
          value={_castArray(value)}
          lookUpSearchApi={handleLoadOptions}
          onChange={onChange}
          suffix={renderClearIcon}
        />
      </div>
      <Error error={error} />
    </div>
  );
};

SelectField.propTypes = {
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  fieldClassName: PropTypes.string,
  error: PropTypes.string,
  customStyles: PropTypes.object,
  options: PropTypes.array,
  value: PropTypes.array,
  onAction: PropTypes.func,
};

SelectField.defaultProps = {
  isMulti: false,
  placeholder: __('Type Here'),
  id: EMPTY_STRING,
  label: EMPTY_STRING,
  fieldClassName: EMPTY_STRING,
  error: EMPTY_STRING,
  customStyles: EMPTY_OBJECT,
  value: EMPTY_ARRAY,
  options: EMPTY_ARRAY,
  onAction: _noop,
};

export default SelectField;
