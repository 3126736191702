// Components
import Button from '@tekion/tekion-components/atoms/Button';
import Heading from '@tekion/tekion-components/atoms/Heading';

// Constants
import { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import ACTION_TYPES from './fieldsList.actionTypes';

// Styles
import styles from '../fieldsList.module.scss';

const SUB_HEADER_PROPS = {
  className: styles.subHeader,
  subHeaderLeftActions: [
    {
      renderer: Heading,
      renderOptions: {
        size: 2,
        children: __('Fields'),
      },
    },
  ],
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        view: Button.VIEW.PRIMARY,
        label: __('Add Field'),
      },
      action: ACTION_TYPES.CREATE_FIELD_CLICK,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showHeader: false,
  showSearch: false,
  showSubHeader: true,
  showRefreshIcon: false,
};

const FIELD_STATUS = {
  CHECKED: true,
  UNCHECKED: false,
};

const FIELD_STATUS_COLOR_MAP = {
  [FIELD_STATUS.UNCHECKED]: COLOR_MAP.RED,
  [FIELD_STATUS.CHECKED]: COLOR_MAP.GREEN,
};

const FIELD_STATUS_LABEL_MAP = {
  [FIELD_STATUS.UNCHECKED]: __('No'),
  [FIELD_STATUS.CHECKED]: __('Yes'),
};

export { SUB_HEADER_PROPS, TABLE_MANAGER_PROPS, FIELD_STATUS, FIELD_STATUS_COLOR_MAP, FIELD_STATUS_LABEL_MAP };
