import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _split from 'lodash/split';
import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _reduce from 'lodash/reduce';
import _includes from 'lodash/includes';
import _uniq from 'lodash/uniq';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Components
import Label from '@tekion/tekion-components/atoms/Label';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getFieldOptionsAndValuesForValidationRule } from './validationRulesRenderer.helpers';

import VALIDATION_OVERRIDE_RULE_FIELD_IDS from './validationRulesRenderer.fieldIds';
import { USER_INPUT_TYPES, USER_INPUT_TYPE_OPTIONS } from './validationRulesRenderer.constants';
import {
  RULE_STATUS_LABEL_MAP,
  RULE_STATUS_COLOR_MAP,
} from '../../../../../validationRuleBuilder/validationRuleBuilderList/constants/validationRuleBuilderList.general';
import { FIELD_IDS } from '../../../../../../../../constants/validationRuleBuilder.constants';

// Styles
import styles from './validationRulesRenderer.module.scss';

const ValidationRulesRenderer = ({
  id,
  outerUserInput,
  actionType,
  value,
  errors,
  targetEntityFieldsByName,
  validationRules,
  formViewOptions,
  onAction,
}) => {
  const validationRulesByName = useMemo(() => _keyBy(validationRules, FIELD_IDS.RULE_NAME), [validationRules]);

  const handleChange = useCallback(
    (action) => {
      const { type, payload = EMPTY_OBJECT } = action;
      if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
        const { id: originalFieldId, value: fieldValue } = payload;

        const [fieldId, ruleName] = _split(originalFieldId, '-', 2);

        // Check for if user is trying to remove fields that are present in that validation rule
        if (fieldId === VALIDATION_OVERRIDE_RULE_FIELD_IDS.SELECTED_FIELDS) {
          const validationRule = _get(validationRulesByName, ruleName, EMPTY_OBJECT);
          const { defaultFieldValues } = getFieldOptionsAndValuesForValidationRule(actionType, targetEntityFieldsByName, validationRule);
          const hasUserRemovedDefaultField = _reduce(defaultFieldValues, (ans, defaultField) => ans || !_includes(fieldValue, defaultField), false);
          if (hasUserRemovedDefaultField) {
            return;
          }
        }

        // If user checks true for selected field by default the field defined in that validation rule will be added
        if (fieldId === VALIDATION_OVERRIDE_RULE_FIELD_IDS.USER_INPUT_TYPE && fieldValue === USER_INPUT_TYPES.FIELD) {
          const validationRule = _get(validationRulesByName, ruleName, EMPTY_OBJECT);
          const { defaultFieldValues } = getFieldOptionsAndValuesForValidationRule(actionType, targetEntityFieldsByName, validationRule);
          const selectedFieldValues = _get(value, `${ruleName}.${VALIDATION_OVERRIDE_RULE_FIELD_IDS.SELECTED_FIELDS}`, EMPTY_ARRAY);
          onAction({
            type,
            payload: {
              id,
              value: {
                ...value,
                [ruleName]: {
                  ...tget(value, ruleName, {}),
                  [fieldId]: fieldValue,
                  [VALIDATION_OVERRIDE_RULE_FIELD_IDS.SELECTED_FIELDS]: _uniq([...selectedFieldValues, ...defaultFieldValues]),
                },
              },
            },
          });
          return;
        }

        onAction({
          type,
          payload: {
            id,
            value: {
              ...value,
              [ruleName]: {
                ...tget(value, ruleName, {}),
                [fieldId]: fieldValue,
              },
            },
          },
        });
      } else {
        onAction(action);
      }
    },
    [id, actionType, targetEntityFieldsByName, validationRulesByName, value, onAction],
  );

  const renderUserInput = useCallback(
    (rule, ruleName, isMessageOverriden) => {
      const userInputType = _get(value, `${ruleName}.${VALIDATION_OVERRIDE_RULE_FIELD_IDS.USER_INPUT_TYPE}`);
      const userInput = _get(value, `${ruleName}.${VALIDATION_OVERRIDE_RULE_FIELD_IDS.USER_INPUT}`);
      const { fieldOptions } = getFieldOptionsAndValuesForValidationRule(actionType, targetEntityFieldsByName, rule);

      return (
        <div className={styles.inputContainer}>
          <CheckboxRenderer
            id={`${VALIDATION_OVERRIDE_RULE_FIELD_IDS.USER_INPUT}-${ruleName}`}
            fieldClassName={fieldLayoutStyles.fieldC}
            checkboxLabel={__('User Input')}
            disabled={!isMessageOverriden}
            value={userInput}
            error={errors?.[`${ruleName}.${VALIDATION_OVERRIDE_RULE_FIELD_IDS.USER_INPUT}`]}
            onAction={handleChange}
          />
          {userInput && (
            <Radio
              id={`${VALIDATION_OVERRIDE_RULE_FIELD_IDS.USER_INPUT_TYPE}-${ruleName}`}
              fieldClassName={fieldLayoutStyles.fieldC}
              disabled={!isMessageOverriden}
              value={userInputType}
              radios={USER_INPUT_TYPE_OPTIONS}
              onAction={handleChange}
            />
          )}
          {userInput && userInputType === USER_INPUT_TYPES.FIELD && (
            <Select
              id={`${VALIDATION_OVERRIDE_RULE_FIELD_IDS.SELECTED_FIELDS}-${ruleName}`}
              isMulti
              isDisabled={!isMessageOverriden}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              placeholder={__('Select Fields')}
              fieldClassName={fieldLayoutStyles.fieldC}
              options={fieldOptions}
              value={_get(value, `${ruleName}.${VALIDATION_OVERRIDE_RULE_FIELD_IDS.SELECTED_FIELDS}`, [])}
              error={errors?.[`${ruleName}.${VALIDATION_OVERRIDE_RULE_FIELD_IDS.SELECTED_FIELDS}`]}
              onAction={handleChange}
            />
          )}
          {userInput && userInputType === USER_INPUT_TYPES.FORM && (
            <Select
              id={`${VALIDATION_OVERRIDE_RULE_FIELD_IDS.FORM_VIEW_NAME}-${ruleName}`}
              fieldClassName={fieldLayoutStyles.fieldC}
              isDisabled={!isMessageOverriden}
              placeholder={__('Select Form')}
              options={formViewOptions}
              value={_get(value, `${ruleName}.${VALIDATION_OVERRIDE_RULE_FIELD_IDS.FORM_VIEW_NAME}`, [])}
              error={errors?.[`${ruleName}.${VALIDATION_OVERRIDE_RULE_FIELD_IDS.FORM_VIEW_NAME}`]}
              onAction={handleChange}
            />
          )}
        </div>
      );
    },
    [actionType, targetEntityFieldsByName, formViewOptions, value, errors, handleChange],
  );

  const validationRenderItems = useCallback(
    () =>
      _map(validationRules, (rule, index) => {
        const ruleName = tget(rule, 'name');
        const errorMessage = tget(rule, 'errorMessage');
        const isMessageOverriden = tget(value, `${ruleName}.${VALIDATION_OVERRIDE_RULE_FIELD_IDS.IS_MESSAGE_OVERRIDEN}`, false);
        const isActive = _get(rule, 'active', false);

        return (
          <div className={styles.container} key={index}>
            <div className={styles.columnContainer}>
              <CheckboxRenderer
                fieldClassName={styles.checkBox}
                id={`${VALIDATION_OVERRIDE_RULE_FIELD_IDS.IS_MESSAGE_OVERRIDEN}-${ruleName}`}
                value={tget(value, `${ruleName}.${VALIDATION_OVERRIDE_RULE_FIELD_IDS.IS_MESSAGE_OVERRIDEN}`)}
                onAction={handleChange}
                checkboxLabel={ruleName}
              />
              <TextInput
                fieldClassName={styles.textInput}
                id={`${VALIDATION_OVERRIDE_RULE_FIELD_IDS.ERROR_MESSAGE}-${ruleName}`}
                value={tget(value, `${ruleName}.errorMessage`)}
                onAction={handleChange}
                disabled={!isMessageOverriden}
                placeholder={errorMessage}
              />
              <StatusRenderer
                containerClassName={styles.statusLabel}
                labelMap={RULE_STATUS_LABEL_MAP}
                colorMap={RULE_STATUS_COLOR_MAP}
                data={isActive}
              />
            </div>
            <PropertyControlledComponent controllerProperty={!outerUserInput}>
              {renderUserInput(rule, ruleName, isMessageOverriden)}
            </PropertyControlledComponent>
          </div>
        );
      }),
    [outerUserInput, validationRules, value, renderUserInput, handleChange],
  );

  return (
    <div className={styles.mainContainer}>
      <div className={styles.labelContainer}>
        <Label className={styles.firstLabel}>{__('Validation Rules')}</Label>
        <Label className={styles.secondLabel}>{__('Error Messages')}</Label>
      </div>
      <div className={styles.innerContainer}>{validationRenderItems()}</div>
    </div>
  );
};

ValidationRulesRenderer.propTypes = {
  id: PropTypes.string.isRequired,
  outerUserInput: PropTypes.bool,
  actionType: PropTypes.string,
  value: PropTypes.object,
  errors: PropTypes.object,
  targetEntityFieldsByName: PropTypes.object,
  validationRules: PropTypes.array,
  formViewOptions: PropTypes.array,
  onAction: PropTypes.func,
};

ValidationRulesRenderer.defaultProps = {
  outerUserInput: false,
  actionType: EMPTY_STRING,
  value: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  targetEntityFieldsByName: EMPTY_OBJECT,
  validationRules: EMPTY_ARRAY,
  formViewOptions: EMPTY_ARRAY,
  onAction: _noop,
};

export default ValidationRulesRenderer;
