import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { tget } from '@tekion/tekion-base/utils/general';
import Icon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import ACTION_TYPES from '../../constants/tenantUserForm.actionTypes';

import styles from './workspaceIcon.module.scss';

const WorkspaceIcon = ({ column, index, tdProps }) => {
  const { onAction, homeWorkspaceIndex } = tget(tdProps, 'rest', EMPTY_OBJECT);
  const { key } = column;
  const handleUserWorkspaceChange = () => {
    onAction({
      type: ACTION_TYPES.ON_USER_WORKSPACE_UPDATE,
      payload: {
        index,
        key,
        value: true,
      },
    });
  };

  return (
    <Icon
      size={SIZES.MD}
      className={cx(styles.iconContainer, { [styles.selectedIcon]: homeWorkspaceIndex === index })}
      onClick={handleUserWorkspaceChange}
    >
      icon-home1
    </Icon>
  );
};

WorkspaceIcon.propTypes = {
  column: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  tdProps: PropTypes.shape({
    rest: PropTypes.shape({
      onAction: PropTypes.func.isRequired,
      homeWorkspaceIndex: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
  }).isRequired,
};
export default WorkspaceIcon;
