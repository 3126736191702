// Lodash
import _property from 'lodash/property';

const universalAssetId = _property('universalAssetId');
const auditId = _property('auditId');
const assetType = _property('assetType');
const assetid = _property('assetid');
const dealerId = _property('dealerId');
const tenantId = _property('tenantId');
const updateTypes = _property('updateTypes');
const changes = _property('changes');
const createdTime = _property('createdTime');
const userId = _property('userId');
const userName = _property('userName');
const originalUserId = _property('originalUserId');
const originalUserName = _property('originalUserName');

const READERS = {
  universalAssetId,
  auditId,
  assetType,
  assetid,
  dealerId,
  tenantId,
  updateTypes,
  changes,
  createdTime,
  userId,
  userName,
  originalUserId,
  originalUserName,
};

export default READERS;
