import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { getMasterEntityDefFromCache } from '../../../../actions/applicationRenderer.actions';
import { getMasterEntityRecord } from '../../../../actions/entityManagement.actions';

import { getSearchParamsFromUrl } from '../../../../utils/visualBuilder.utils';
import { getLayouts } from '../../helpers/visualBuilder.helper';

import { ACTION_TYPES } from './pageViewer.constants';
import { PAGE_TYPES } from '../../../../constants/visualBuilder';
import { APPLICATION_CONTEXT_KEYS, APPLICATION_SEARCH_PARAM_IDS } from '../../../../constants/applicationRenderer.constants';
import { ROW_HEIGHT } from '../visualBuilderGridLayout/visualBuilderGridLayout.constants';
import { COMPONENT_CONFIG_KEYS } from '../../constants/visualBuilder.general.constants';
import RECORD_FIELDS from '../../../../constants/recordFields';

const handleInit = async ({ getState, setState, params = {} }) => {
  const { history, isPreviewMode, pageEntityRecord = {}, applicationContext } = getState();
  const { pageConfiguration } = params;

  const layoutById = _keyBy(getLayouts(pageConfiguration), 'i');

  const pageType = _get(pageConfiguration, 'pageType');
  setState({ isLoading: true });

  const cachedEntitiesDef = tget(applicationContext, APPLICATION_CONTEXT_KEYS.ENTITIES_DEF, EMPTY_OBJECT);
  const handleSetEntityDef = tget(applicationContext, APPLICATION_CONTEXT_KEYS.SET_ENTITY_DEF, _noop);

  if (isPreviewMode) {
    if (pageType === PAGE_TYPES.RECORD_DETAIL_PAGE) {
      const entityName = _get(pageConfiguration, COMPONENT_CONFIG_KEYS.ENTITY_NAME);
      let recordId;
      if (history) {
        const searchParams = getSearchParamsFromUrl(history);
        recordId = _get(searchParams, APPLICATION_SEARCH_PARAM_IDS.PAGE_RECORD_ID);
      }

      const masterEntityDef = await getMasterEntityDefFromCache({
        cacheValue: cachedEntitiesDef,
        setCacheHandler: handleSetEntityDef,
        entityName,
      });

      let entityRecord = pageEntityRecord;
      if (!_isEmpty(recordId)) {
        entityRecord = await getMasterEntityRecord(recordId, RECORD_FIELDS.ID, masterEntityDef);
      }

      setState({ pageEntity: masterEntityDef, pageEntityRecord: entityRecord });
    } else {
      setState({ pageEntity: {}, pageEntityRecord: {} });
    }
  }
  setState({ previewLayoutsById: layoutById, isLoading: false });
};

const handleLayoutChange = ({ params, getState, setState }) => {
  const { previewLayoutsById, isPreviewMode } = getState();
  const { value, widgetName } = params;

  const newHeight = Math.ceil(value / ROW_HEIGHT);
  const widgetData = _get(previewLayoutsById, widgetName);
  if (_get(widgetData, 'h') === newHeight || !isPreviewMode) {
    return;
  }
  const newLayouts = { ...previewLayoutsById, [widgetName]: { ..._get(previewLayoutsById, widgetName), h: newHeight } };

  setState({ previewLayoutsById: newLayouts });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: handleInit,
  [ACTION_TYPES.LAYOUT_CHANGE]: handleLayoutChange,
};

export default ACTION_HANDLERS;
