import { CUSTOM_STYLES_CONFIGURE_FIELD_IDS } from '../constants/customStylesConfigure.fieldIds';

const CUSTOM_STYLES_CONFIGURE_SECTIONS = [
  {
    rows: [
      {
        columns: [CUSTOM_STYLES_CONFIGURE_FIELD_IDS.CUSTOM_STYLES],
      },
    ],
  },
];

export { CUSTOM_STYLES_CONFIGURE_SECTIONS };
