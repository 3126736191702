import COLUMN_IDS from './workspaceUserList.columnIds';

const DEFAULT_USER_LIST_PROPS = {
  currentPage: 0,
  pageSize: 50,
  searchField: COLUMN_IDS.FIRST_NAME,
};

const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

const DIALOG_TEXT = {
  REMOVE_TEXT: (userName) => __(`Are you sure you want to remove ${userName} from current workspace?`),
};

const SEARCHABLE_OPTIONS = [
  {
    label: __('Name'),
    value: COLUMN_IDS.FIRST_NAME,
  },
  {
    label: __('Email'),
    value: COLUMN_IDS.EMAIL,
  },
];

const USER_STATUS = {
  ACTIVE: true,
  INACTIVE: false,
};

export { DEFAULT_USER_LIST_PROPS, TABLE_CONSTANTS, DIALOG_TEXT, SEARCHABLE_OPTIONS, USER_STATUS };
