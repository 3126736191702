import React, { useContext } from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import ViewBuilderContext from '../../ViewBuilder.context';

import styles from '../../organisms/viewViewer/viewViewer.module.scss';

const DropZone = ({ id, index, className, onHoverClassName, hiddenDropZoneClassName, acceptedComponentTypes }) => {
  const { onDrop } = useContext(ViewBuilderContext);

  const [{ canDrop, isOver, draggedItem }, dropRef] = useDrop({
    accept: acceptedComponentTypes,
    drop: (...args) => onDrop(id, index, ...args),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      draggedItem: monitor.getItem(),
    }),
  });

  let enableDropZone = false;
  if (!_isEmpty(draggedItem) && _includes(acceptedComponentTypes, tget(draggedItem, 'type', ''))) {
    enableDropZone = true;
  }

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={cx(className, {
          [styles.dropZoneHover]: canDrop && isOver,
          [onHoverClassName]: canDrop && isOver,
        })}
      />
      <div ref={dropRef} style={{ pointerEvents: enableDropZone ? 'all' : 'none' }} className={cx(styles.hiddenDropZone, hiddenDropZoneClassName)} />
    </div>
  );
};

DropZone.propTypes = {
  index: PropTypes.number.isRequired,
  hiddenDropZoneClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  onHoverClassName: PropTypes.string,
  acceptedComponentTypes: PropTypes.arrayOf(PropTypes.string),
};

DropZone.defaultProps = {
  className: EMPTY_STRING,
  hiddenDropZoneClassName: EMPTY_STRING,
  onHoverClassName: EMPTY_STRING,
  acceptedComponentTypes: EMPTY_ARRAY,
};

export default DropZone;
