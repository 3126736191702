import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// lodash
import _map from 'lodash/map';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import _size from 'lodash/size';
import _toLower from 'lodash/toLower';
import _isFunction from 'lodash/isFunction';
import _includes from 'lodash/includes';
import _toString from 'lodash/toString';
import _isNil from 'lodash/isNil';

// components
import { EMPTY_STRING, EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import IconAsBtn from '@tekion/tekion-components/atoms/iconAsBtn';
import Icon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Heading from '@tekion/tekion-components/atoms/Heading';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Checkbox from '@tekion/tekion-components/atoms/checkbox';
import NullComponent from '@tekion/tekion-components/atoms/nullComponent';

// util
import {
  isBlankTaskType,
  isEventTaskType,
  isLeafEvaluator,
  graphManager,
  stepsManager,
  dataManager,
  useStepsContext,
} from '@tekion/tekion-rule-engine';

// styles
import boxStyles from './nodeBox.module.scss';

const NodeBox = (props) => {
  const { nodeData, isCardExpanded, handleExpand, isEditable, conterntParser, isPreview, notificationPanel: NotificationPanel } = props;
  // local state
  const [content, setContent] = useState([]);

  const { multiSelectData } = useStepsContext();
  const { selectableIdList = EMPTY_ARRAY, selectedIdList = EMPTY_ARRAY, auxiliaryCardDataList = EMPTY_ARRAY } = multiSelectData;

  const {
    nodesByConditionValue = EMPTY_OBJECT,
    uiMetadata = EMPTY_OBJECT,
    nodeConfig = EMPTY_OBJECT,
    label = EMPTY_STRING,
    nodeId = EMPTY_STRING,
    userData = EMPTY_OBJECT,
    description = EMPTY_STRING,
    taskDefId = EMPTY_STRING,
  } = nodeData || EMPTY_OBJECT;

  const {
    optionsByConditionTypes = EMPTY_OBJECT,
    taskType = EMPTY_STRING,
    taskCategory = taskType,
    showLabel = true,
    entityDefId = EMPTY_STRING,
    icon: iconFromMetadata = EMPTY_STRING,
    isDraggable = true,
    fieldOptions = EMPTY_ARRAY,
    fields = EMPTY_OBJECT,
  } = uiMetadata;
  const parentTaskType = _get(nodeConfig, 'parentTaskType', EMPTY_STRING);
  const isRuleEngine = dataManager.isEngineTypeRuleEngine();
  const isFirstWorkflowConditionNode = !isRuleEngine && isEventTaskType(parentTaskType);
  const isNodeDraggable = isDraggable && !isFirstWorkflowConditionNode;

  const isTrigger = useMemo(() => isEventTaskType(taskType), [taskType]);
  const isBlank = useMemo(() => isBlankTaskType(taskType), [taskType]);
  const isMultiSelectable = useMemo(() => _includes(selectableIdList, nodeId), [selectableIdList, nodeId]);
  const isMultiSelectedAuxiliary = useMemo(() => _includes(_map(auxiliaryCardDataList, 'nodeId'), nodeId), [auxiliaryCardDataList, nodeId]);
  const isMultiSelected = useMemo(() => _includes(selectedIdList, nodeId), [selectedIdList, nodeId]);
  const containerClassName = useMemo(() => {
    const cursorType = !isTrigger && !isBlank && isNodeDraggable ? boxStyles.cursorMove : boxStyles.defaultCursor;
    return cx(
      boxStyles.ruleCard,
      { [boxStyles.multiSelectBorder]: isMultiSelected || isMultiSelectedAuxiliary },
      boxStyles[_toLower(taskCategory)],
      boxStyles[_toLower(taskDefId)], // apply this style when taskDef is loopExit and loopContinue
      cursorType,
      'rootNode',
    );
  }, [isTrigger, isNodeDraggable, taskCategory, isBlank, isMultiSelected, isMultiSelectedAuxiliary, taskDefId]);
  const isExpandable = isEditable && !isTrigger && _size(content) > 1;
  const isLabelVisible = !isEditable || showLabel;
  const icon = useMemo(() => (isTrigger ? 'icon-trigger' : iconFromMetadata), [iconFromMetadata, isTrigger]);

  const iconContainer = useMemo(() => <Icon className={boxStyles.ruleCardContentLeftIcon}>{icon}</Icon>, [icon]);
  const labelContainer = useMemo(() => {
    const translatedLabel = _get(dataManager.taskByTaskDefId, `${taskDefId}.displayName`, label);
    return (
      <Heading size={4}>
        <PropertyControlledComponent controllerProperty={isLabelVisible}>{translatedLabel}</PropertyControlledComponent>
      </Heading>
    );
  }, [isLabelVisible, label, taskDefId]);

  useEffect(() => {
    const data = _get(userData, 'data', EMPTY_OBJECT);
    const parsedStepContent = _isFunction(conterntParser)
      ? conterntParser(entityDefId, data, fields, fieldOptions, optionsByConditionTypes)
      : EMPTY_ARRAY;

    setContent(parsedStepContent);
  }, [conterntParser, entityDefId, fieldOptions, fields, optionsByConditionTypes, userData]);

  useEffect(() => {
    graphManager.updateGraphManager();
  }, [isCardExpanded, label, nodesByConditionValue]);

  const expandIcon = useMemo(() => {
    if (!isExpandable) return null;
    return (
      <IconAsBtn size={SIZES.S} containerClassName="m-auto" onClick={handleExpand} className={boxStyles.expandIcon}>
        {isCardExpanded ? 'icon-collapse' : 'icon-expand'}
      </IconAsBtn>
    );
  }, [handleExpand, isCardExpanded, isExpandable]);

  const checkboxOnChange = useCallback(() => {
    stepsManager.toggleMultiSelect(nodeId, isLeafEvaluator(nodeData));
  }, [nodeId, nodeData]);

  const cardDetails = useMemo(
    () =>
      _map(content, (list, index) => {
        if (!(isCardExpanded || index < 1)) return null;
        const itemKey = `${list.key}_${index}_${nodeId}`;
        return (
          <li className={cx(boxStyles.cardListItem, { [boxStyles.conditionDetailsList]: !_isNil(list.index) })} key={itemKey}>
            {!_isNil(list.index) && <span className={boxStyles.listNumber}>{list.index}. </span>}
            {list.key && <span className={boxStyles.listKey}>{!_isNil(list.value) ? `${list.key} : ` : list.key}</span>}
            {_toString(list.value)}
          </li>
        );
      }),
    [nodeId, content, isCardExpanded],
  );

  return (
    <div className={containerClassName}>
      <div className={boxStyles.ruleCardContentLeft}>{iconContainer}</div>
      <div className={boxStyles.ruleCardContentRight}>
        <PropertyControlledComponent controllerProperty={isLabelVisible}>{labelContainer}</PropertyControlledComponent>
        <PropertyControlledComponent controllerProperty={isEditable && !isPreview}>
          <ul className={cx(isTrigger ? 'd-none' : boxStyles.cardBody)}>{cardDetails}</ul>
        </PropertyControlledComponent>
        <PropertyControlledComponent controllerProperty={isCardExpanded}>
          <p>{description}</p>
        </PropertyControlledComponent>
      </div>
      <PropertyControlledComponent controllerProperty={!isPreview}>
        <div className={boxStyles.ruleCardContentUtility}>
          <div className={boxStyles.parentIconsContainer}>
            <NotificationPanel cardData={nodeData} />
            {expandIcon}
          </div>
          <PropertyControlledComponent controllerProperty={isMultiSelectable || isMultiSelectedAuxiliary}>
            <Checkbox
              className={boxStyles.multiSelectCheckbox}
              value={isMultiSelected || isMultiSelectedAuxiliary}
              onChange={isMultiSelectable ? checkboxOnChange : _noop}
            />
          </PropertyControlledComponent>
        </div>
      </PropertyControlledComponent>
    </div>
  );
};

NodeBox.propTypes = {
  isCardExpanded: PropTypes.bool,
  isEditable: PropTypes.bool,
  isPreview: PropTypes.bool,
  nodeData: PropTypes.object.isRequired,
  notificationPanel: PropTypes.element,
  handleExpand: PropTypes.func,
  conterntParser: PropTypes.func,
};

NodeBox.defaultProps = {
  isCardExpanded: false,
  isEditable: true,
  isPreview: false,
  notificationPanel: NullComponent,
  handleExpand: _noop,
  conterntParser: _noop,
};

export default NodeBox;
