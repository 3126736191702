import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import ReporteeForm from './pages/reporteeForm';
import ReporteeList from './pages/reporteeList';
import RoleConfigure from './pages/roleConfigure';

const Reportee = () => (
  <Switch>
    <Route exact path={ROUTES.REPORTEE_CREATE_ROUTE} component={ReporteeForm} />
    <Route exact path={ROUTES.REPORTEE_EDIT_ROUTE} component={ReporteeForm} />
    <Route exact path={ROUTES.ROLE_CONFIGURE_ROUTE} component={RoleConfigure} />
    <Route path={ROUTES.REPORTEE_ROUTE} component={ReporteeList} />
  </Switch>
);

export default Reportee;
