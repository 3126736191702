import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';

import { EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';

import TagsRenderer from '../../atoms/tagsRenderer';

import { checkForRichTextEditorField, checkIsFieldMultiValued, getPlainTextFromHtml } from '../../helpers/viewBuilder.helper';
import { entityRecordAccessor } from '../../helpers/entityRecordAccessor';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';
import entityReader from '../../../../readers/entity.reader';

import styles from './rightPaneViewAttributeRenderer.module.scss';

const RightPaneViewAttributeRenderer = ({ className, entity, entityRecord, componentConfig }) => {
  const { sectionId, properties = {} } = componentConfig;

  const fieldDefinitionByName = _keyBy(entityReader.fieldDefinitions(entity), 'name');
  const accessor = entityRecordAccessor(properties, fieldDefinitionByName);
  let fieldValue = accessor(entityRecord) || NO_DATA;
  const isMultiValued = checkIsFieldMultiValued(properties, fieldDefinitionByName);
  const isRichTextEditorField = checkForRichTextEditorField(properties, fieldDefinitionByName);
  if (isRichTextEditorField) {
    fieldValue = getPlainTextFromHtml(fieldValue);
  }
  const displayName = _get(properties, ALL_VIEW_PROPERTY_KEYS.TITLE);

  return (
    <div className={cx(styles.rightPaneViewAttributeRenderer, className)} key={sectionId}>
      <div className={styles.key}>
        <div className={styles.label}>{__(displayName)}</div>
      </div>
      {isMultiValued ? <TagsRenderer fieldValue={fieldValue} maxLimit={3} /> : <div className={styles.value}>{__(fieldValue)}</div>}
    </div>
  );
};

RightPaneViewAttributeRenderer.propTypes = {
  entity: PropTypes.object,
  entityRecord: PropTypes.object,
  componentConfig: PropTypes.object.isRequired,
  className: PropTypes.string,
};

RightPaneViewAttributeRenderer.defaultProps = {
  entity: undefined,
  entityRecord: undefined,
  className: EMPTY_STRING,
};

export default RightPaneViewAttributeRenderer;
