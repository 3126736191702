import produce from 'immer';
import _snakeCase from 'lodash/snakeCase';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _remove from 'lodash/remove';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _size from 'lodash/size';
import _union from 'lodash/union';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { fetchEntityDefByName } from '../../../../../actions/entityManagement.actions';
import { createRecordType, updateRecordType } from '../../../../../actions/recordType.actions';
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../actions/conditionBuilder.actions';
import { getDerivationConditionValues, getPayloadCondition } from './recordType.helpers';
import { ACTION_TYPES, FIELD_IDS } from '../constants/recordType.constants';
import { STUDIO_ROUTE } from '../../../../../constants/routes';
import { FORM_MODES, RECORD_TYPES } from '../../../../../constants/general.constants';
import PAGE_IDS from '../../../constants/PageIds.constants';
import { CONDITION_BUILDER_FIELD_IDS } from '../../../../../organisms/conditionBuilder';
import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';
import entityDefinitionReader from '../../../../../readers/entity.reader';

const handleOnMount = async ({ setState, getState }) => {
  const { history = EMPTY_OBJECT, match = EMPTY_OBJECT } = getState();
  const { entityName, recordTypeName } = _get(match, 'params', {});

  setState({ isLoading: true });

  let formMode = FORM_MODES.CREATE;
  let formValues = { [FIELD_IDS.STATUS]: true };
  let fieldModalData = EMPTY_ARRAY;
  let fieldTableData = EMPTY_ARRAY;
  let entity = _get(history, 'location.state.entity', {});
  let recordTypeValues = EMPTY_OBJECT;

  if (_isEmpty(entity)) {
    entity = await fetchEntityDefByName(entityName);
  }

  const derivationFields = _get(entity, 'recordTypeDefinition.recordTypeDerivationConfig.derivationFields', EMPTY_ARRAY);
  const mapOfVariableToEntityName = { $record: entityName };
  const conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityName);

  const fieldDefs = _get(conditionBuilderFieldDefinitionObject, entityName, EMPTY_OBJECT);
  let filteredFieldDefs = _filter(fieldDefs, (fieldDef) => _includes(derivationFields, fieldDefinitionReader.name(fieldDef)));
  filteredFieldDefs = _keyBy(filteredFieldDefs, 'name');
  const fieldDefinitions = entityDefinitionReader.fieldDefinitions(entity);
  _set(conditionBuilderFieldDefinitionObject, entityName, filteredFieldDefs);

  if (!_isEmpty(recordTypeName)) {
    formMode = FORM_MODES.EDIT;
    recordTypeValues = _get(history, 'location.state.recordTypeValues', {});
    if (_isEmpty(recordTypeValues)) {
      recordTypeValues = _find(
        _get(entity, 'recordTypeDefinition.recordTypes', EMPTY_ARRAY),
        (recordType) => _get(recordType, 'name') === recordTypeName,
      );
    }

    formValues = { ...recordTypeValues, [FIELD_IDS.DERIVATION_CONDITION]: getDerivationConditionValues(recordTypeValues) };
    fieldModalData = _get(formValues, FIELD_IDS.AVAILABLE_FIELDS, EMPTY_ARRAY);
  }

  fieldModalData = _union(fieldModalData, derivationFields);
  fieldTableData = _map(fieldModalData, (item) => ({ ..._find(fieldDefinitions, (field) => field.name === item) }));

  setState({
    isLoading: false,
    entity,
    formMode,
    formValues,
    fieldModalData,
    fieldTableData,
    derivationFields,
    mapOfVariableToEntityName,
    conditionBuilderFieldDefinitionObject,
  });
};

const handleFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { formMode, disableAutoFillForName = false } = getState();

  setState(
    produce((draft) => {
      _set(draft, `formValues.${id}`, value);

      if (formMode === FORM_MODES.CREATE && id === FIELD_IDS.DISPLAY_NAME && !disableAutoFillForName) {
        _set(draft, `formValues.${FIELD_IDS.NAME}`, _snakeCase(value));
      } else if (id === FIELD_IDS.NAME) setState({ disableAutoFillForName: true });
    }),
  );
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;

  setState({ errors });
};

const handleSubmit = async ({ setState, getState }) => {
  const { history, fieldModalData, formValues, errors, formMode, entity, match } = getState();
  const { entityName } = _get(match, 'params', {});

  const submitAction = formMode === FORM_MODES.CREATE ? createRecordType : updateRecordType;

  setState({ isSavingDetails: true });

  let allFieldsAvailable = false;
  if (_size(_get(entity, 'fieldDefinitions', EMPTY_ARRAY)) === _size(fieldModalData)) {
    allFieldsAvailable = true;
  }

  const derivationCondition = _get(formValues, FIELD_IDS.DERIVATION_CONDITION, EMPTY_OBJECT);

  if (_get(entity, 'recordTypeDefinition.type') === RECORD_TYPES.DERIVED) {
    if (
      _isEmpty(_get(derivationCondition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST)) &&
      (_get(errors, `${FIELD_IDS.DERIVATION_CONDITION}.isValid`) || _isEmpty(_get(errors, `${FIELD_IDS.DERIVATION_CONDITION}`)))
    ) {
      const newErrors = {
        ...errors,
        [FIELD_IDS.DERIVATION_CONDITION]: { isValid: false, errorMessage: __('There should be atleast one condition') },
      };
      setState({ errors: newErrors, isSavingDetails: false });
      return;
    }
  }

  const response = await submitAction(entityName, {
    ...formValues,
    availableFields: fieldModalData,
    [FIELD_IDS.DERIVATION_CONDITION]: getPayloadCondition(formValues),
    allFieldsAvailable,
  });

  if (!_isEmpty(response)) {
    setTimeout(() => {
      const recordTypeName = _get(formValues, FIELD_IDS.NAME);
      const recordTypeDetails = response;

      history.push({
        pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.RECORD_TYPE}/${recordTypeName}`,
        state: { entity, recordTypeDetails },
      });
    }, ES_REFETCH_DELAY);
  } else {
    setState({ isSavingDetails: false });
  }
};

const handleModalOpen = ({ setState }) => {
  setState({ isModalVisible: true });
};

const handleModalSave = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { fields } = params;
  const { entity } = getState();
  const fieldDefinitions = entityDefinitionReader.fieldDefinitions(entity);
  const selectedFields = _map(fields, (item) => ({ ..._find(fieldDefinitions, (field) => field.name === item) }));

  setState({ isModalVisible: false, fieldModalData: fields, fieldTableData: selectedFields });
};

const handleModalClose = ({ setState }) => {
  setState({ isModalVisible: false });
};

const handleRemoveRow = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { fieldTableData, fieldModalData } = getState();
  const { value } = params;
  _remove(fieldTableData, (item) => _get(item, 'name') === value.name);
  _remove(fieldModalData, (item) => item === value.name);

  setState({ fieldTableData: [...fieldTableData], fieldModalData: [...fieldModalData] });
};

const handleOverride = ({ params = EMPTY_OBJECT, getState }) => {
  const { value } = params;
  const { history, match, fieldModalData, entity, formValues } = getState();
  const { entityName, recordTypeName } = _get(match, 'params', {});
  const fieldName = _get(value, 'name');
  history.push({
    pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.RECORD_TYPE}/${recordTypeName}`,
    state: {
      entity,
      recordTypeDetails: {
        ...formValues,
        availableFields: fieldModalData,
        [FIELD_IDS.DERIVATION_CONDITION]: getPayloadCondition(_get(formValues, FIELD_IDS.DERIVATION_CONDITION)),
      },
      fieldName,
    },
  });
};

const handleRedirection = ({ getState }) => {
  const { match, history } = getState();
  const entityName = _get(match, 'params.entityName');

  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.RECORD_TYPE}`);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_MOUNT]: handleOnMount,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.OVERRIDE]: handleOverride,
  [ACTION_TYPES.MODAL_OPEN]: handleModalOpen,
  [ACTION_TYPES.MODAL_SAVE]: handleModalSave,
  [ACTION_TYPES.MODAL_CLOSE]: handleModalClose,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
  [ACTION_TYPES.DELETE]: handleRemoveRow,
};

export default ACTION_HANDLERS;
