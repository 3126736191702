import { useEffect, useState } from 'react';
import { getVideoManifestUrl } from './helpers/useSignedVideoUrl.fetchUrl';

const handleUrlFetchSuccess =
  (setVideoDetails) =>
  ({ signedMediaUrl, isVideoFragmented }) => {
    setVideoDetails({
      signedVideoUrl: signedMediaUrl,
      isVideoFragmented,
    });
  };

function useSignedVideoUrl(mediaId) {
  const [videoDetails, setVideoDetails] = useState({ signedVideoUrl: '', isVideoFragmented: 'false' });
  useEffect(() => {
    getVideoManifestUrl(mediaId).then(handleUrlFetchSuccess(setVideoDetails));
  }, [mediaId]);

  return videoDetails;
}

export default useSignedVideoUrl;
