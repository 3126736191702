import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import commentServices from '../services/comments.services';

const createComment = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await commentServices.createComment(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Create Comment, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const searchComments = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await commentServices.searchComments(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Search Comments, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getCommentCount = async (assetType = EMPTY_STRING, assetId = EMPTY_STRING) => {
  try {
    const encodedAssetType = encodeURIComponent(assetType);

    const response = await commentServices.getCommentCount(encodedAssetType, assetId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Get Comment Count, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getCommentById = async (commentId = EMPTY_STRING) => {
  try {
    const response = await commentServices.getCommentById(commentId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Fetch Comment, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateCommentById = async (commentId = EMPTY_STRING, payload = EMPTY_OBJECT) => {
  try {
    const response = await commentServices.updateCommentById(commentId, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Update Comment, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const deleteCommentById = async (commentId = EMPTY_STRING) => {
  try {
    const response = await commentServices.deleteCommentById(commentId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Delete Comment, please try again later.')));
    return false;
  }
};

export { createComment, searchComments, getCommentCount, getCommentById, updateCommentById, deleteCommentById };
