import React from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import AsyncPaginatedSelect from '../../asyncPaginatedSelect';

import { getWorkspaceUserList } from '../../../actions/workspaceUserManagement.actions';

import { getPayload, getPayloadForInitialFetch, getWorkspaceUserOptions } from './workspaceUserAsyncSelect.helpers';

// filters in props should be created from filter builder
const WorkspaceUserAsyncSelect = ({ filters, ...restProps }) => (
  <AsyncPaginatedSelect
    serviceHandler={getWorkspaceUserList}
    getOptions={getWorkspaceUserOptions}
    getPayload={getPayload(filters)}
    getPayloadForInitialFetch={getPayloadForInitialFetch(filters)}
    {...restProps}
  />
);

WorkspaceUserAsyncSelect.propTypes = {
  filters: PropTypes.array,
};

WorkspaceUserAsyncSelect.defaultProps = {
  filters: EMPTY_ARRAY,
};

export default WorkspaceUserAsyncSelect;
