import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _isNil from 'lodash/isNil';
import _filter from 'lodash/filter';
import _castArray from 'lodash/castArray';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { createRole, editRoleByName, getAllRoles, getRoleByName } from '../../../../../../../actions/rolesHierarchyManagement.actions';
import { getRoleOptions } from './reporteeForm.helpers';

import { FIELD_IDS } from '../../../molecules/roleForm/constants/roleForm.fields';
import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import { FORM_MODES } from '../../../../../../../constants/general.constants';
import PAGE_IDS from '../../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/reporteeForm.actionTypes';

const setInitialReporteeFormValues = async ({ getState, setState }) => {
  const { location, match } = getState();
  const roleName = _get(match, 'params.roleName');

  setState({ isDataLoading: true });

  let roleOptions = _get(location, 'state.roleOptions');
  if (_isNil(roleOptions)) {
    roleOptions = [];
    const rolesTreeData = await getAllRoles();
    if (!_isEmpty(rolesTreeData)) {
      getRoleOptions(rolesTreeData, roleOptions);
    }
  }

  if (_isEmpty(roleName)) {
    setState({
      formMode: FORM_MODES.CREATE,
      roleOptions,
      isDataLoading: false,
      roleData: { [FIELD_IDS.REPORTS_TO_ROLE]: _castArray(_get(location, 'state.selectedRoleKey')) },
    });
    return;
  }

  roleOptions = _filter(roleOptions, (role) => _get(role, 'value') !== roleName);
  let roleData = _get(location, 'state.roleInfo');

  if (_isEmpty(roleData)) {
    roleData = await getRoleByName(roleName);
  }

  const reportsToRole = _get(roleData, FIELD_IDS.REPORTS_TO_ROLE);

  if (!_isEmpty(reportsToRole)) {
    _set(roleData, FIELD_IDS.REPORTS_TO_ROLE, _castArray(reportsToRole));
  }
  setState({ roleData, isDataLoading: false, formMode: FORM_MODES.EDIT, roleOptions });
};

const handleFieldChange = ({ setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  setState((state) => ({
    roleData: {
      ...state?.roleData,
      [id]: value,
    },
  }));
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;
  setState({ errors });
};

const handleRedirection = ({ getState }) => {
  const { history } = getState();
  const path = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTEE}`;
  history.push(path);
};

const handleSubmit = async ({ getState, setState }) => {
  const { history, roleData, match } = getState();

  const payload = { ...roleData };
  _set(payload, FIELD_IDS.REPORTS_TO_ROLE, getArraySafeValue(_get(payload, FIELD_IDS.REPORTS_TO_ROLE)));

  setState({ isSaveLoading: true });

  const roleName = _get(match, 'params.roleName');
  let responseData = EMPTY_OBJECT;
  if (_isEmpty(roleName)) {
    responseData = await createRole(payload);
  } else {
    responseData = await editRoleByName(roleName, payload);
  }

  if (!_isNil(responseData)) {
    const { name } = responseData;
    setTimeout(() => {
      history.push({
        pathname: `${STUDIO_ROUTE}/${PAGE_IDS.REPORTEE}/${PAGE_IDS.ROLE_CONFIGURE}/${name}`,
        state: { selectedRoleData: responseData },
      });
    }, ES_REFETCH_DELAY);
  } else {
    setState({ isSaveLoading: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INITIALIZE_REPORTEE_FORM_VALUES]: setInitialReporteeFormValues,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
};

export default ACTION_HANDLERS;
