import React, { useCallback, useEffect } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Heading from '@tekion/tekion-components/atoms/Heading';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Button from '@tekion/tekion-components/atoms/Button';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Loader from '@tekion/tekion-components/molecules/loader';

import WithSize from '../../../../connectors/withSize';
import ViewViewer from '../../../../organisms/viewBuilder/organisms/viewViewer/ViewViewer';
import EntityRecordRightPane from '../entityRecordRightPane/EntityRecordRightPane';
import ApplicationRendererEventEmitter from '../../../../eventEmitters/applicationRendererEventEmitter';

import ACTION_HANDLERS from './helpers/entityRecordListPage.actionHandlers';

import { ACTION_TYPES } from './constants/entityRecordListPage.constants';
import { EVENT_ACTIONS } from '../../../../constants/eventActions.constants';

import styles from './entityRecordListPage.module.scss';
import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../constants/viewBuilder.constants';

const EntityRecordListPage = ({ entity, isDrawerVisible, onAction, contentHeight, entityRecordId, listViewConfig, match, history }) => {
  const entityDisplayName = _get(entity, 'displayName', EMPTY_STRING);

  const handleListRowClick = useCallback(
    (payload) => {
      onAction({ type: ACTION_TYPES.ON_OPEN_SIDE_PANE, payload });
    },
    [onAction],
  );
  const handleDrawerClose = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CLOSE_SIDE_PANE });
  }, [onAction]);

  const handleGoBack = useCallback(() => {
    onAction({ type: ACTION_TYPES.GO_BACK });
  }, [onAction]);

  const handleClickCreate = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CLICK_CREATE });
  }, [onAction]);

  useEffect(() => {
    ApplicationRendererEventEmitter.on(EVENT_ACTIONS.NAVIGATE, handleListRowClick);
    onAction({ type: ACTION_TYPES.ON_INIT });
    return () => {
      ApplicationRendererEventEmitter.removeAllListeners(EVENT_ACTIONS.NAVIGATE);
    };
  }, [onAction, handleListRowClick]);

  if (_isEmpty(entity)) return <Loader />;

  return (
    <Page>
      <Page.Header hasBack goBackHandler={handleGoBack}>
        <div className={styles.header}>
          <Heading>{entityDisplayName}</Heading>
          <div>
            <Button label={__('Create New')} view={Button.VIEW.PRIMARY} onClick={handleClickCreate} />
          </div>
        </div>
      </Page.Header>
      <Page.Body className={styles.body} style={{ height: contentHeight }}>
        <div className={styles.viewViewer}>
          <ViewViewer
            isPreviewMode
            viewConfiguration={listViewConfig}
            entity={entity}
            onRowClick={handleListRowClick}
            viewConfigName={_get(listViewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME)}
            history={history}
            isFilterPersist={false}
            match={match}
          />
        </div>
        <PropertyControlledComponent controllerProperty={isDrawerVisible}>
          <EntityRecordRightPane isDrawerVisible entityRecordId={entityRecordId} match={match} history={history} onDrawerClose={handleDrawerClose} />
        </PropertyControlledComponent>
      </Page.Body>
    </Page>
  );
};

EntityRecordListPage.propTypes = {
  isDrawerVisible: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  listViewConfig: PropTypes.object,
  entityRecordId: PropTypes.string,
  entity: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
};

EntityRecordListPage.defaultProps = {
  isDrawerVisible: false,
  listViewConfig: EMPTY_OBJECT,
  entity: undefined,
  entityRecordId: undefined,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS), WithSize({ hasPageFooter: 0, hasPageHeader: 1 }))(EntityRecordListPage);
