import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const EXTERNAL_CREDENTIAL_BASE_URL = '/core/u/scs/external/credentials';

// APIs for External Credentials

const fetchExternalCredentialByName = (name) => ApiService.get(`${EXTERNAL_CREDENTIAL_BASE_URL}/${name}`);
const createExternalCredential = (payload = EMPTY_OBJECT) => ApiService.post(`${EXTERNAL_CREDENTIAL_BASE_URL}`, payload);
const updateExternalCredentialByName = (name, payload = EMPTY_OBJECT) => ApiService.put(`${EXTERNAL_CREDENTIAL_BASE_URL}/${name}`, payload);
const searchExternalCredentials = (payload = EMPTY_OBJECT) => ApiService.post(`${EXTERNAL_CREDENTIAL_BASE_URL}/search`, payload);
const fetchExternalCredentialByNameWithReveal = (name) => ApiService.get(`${EXTERNAL_CREDENTIAL_BASE_URL}/${name}/reveal`);

export default {
  fetchExternalCredentialByName,
  createExternalCredential,
  updateExternalCredentialByName,
  searchExternalCredentials,
  fetchExternalCredentialByNameWithReveal,
};
