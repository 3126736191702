import { LOGOUT_ROUTE, BASE_ROUTE, STUDIO_ROUTE, TENANT_UNIVERSE_BASE_ROUTE } from '../../../constants/routes';

export const HEADER_HEIGHT = 64;
export const SIDEBAR_WIDTH = 64;
export const SIDE_NAVIGATION_TREE_WIDTH = 240;

export const MENU_OPTIONS = [{ label: __('Logout'), value: LOGOUT_ROUTE }];

export const TENANT_UNIVERSE_MENU_OPTION = { label: __('Tenant Universe'), value: TENANT_UNIVERSE_BASE_ROUTE };

export const SIDENAV_EXPERIENCE_DATA = { name: __('Home'), goTo: BASE_ROUTE };
export const SIDENAV_STUDIO_DATA = { name: __('Studio'), goTo: STUDIO_ROUTE };

export const USER_AVATAR_WORKSPACE_SEE_ALL = 'workspace-see-all';
