import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { isErrorEmpty } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { getDependencyConfigFormValues, getFieldsFormDependencyConfigValues } from './dependencyConfigForm.general.helpers';

import FIELDS_FORM_FIELD_IDS from '../../../constants/fieldsForm.fieldIds';
import { FIELD_FORM_CONTEXT_ID } from '../../../constants/fieldsForm.constants';

const handleInit = ({ getState, setState }) => {
  const { fieldsFormDependencyConfigValues = EMPTY_OBJECT } = getState();

  setState({ values: getDependencyConfigFormValues(fieldsFormDependencyConfigValues) });
};

const handleSubmit = ({ getState, params = EMPTY_OBJECT }) => {
  const { additional = {} } = params;
  const { fieldsFormOnAction } = getState();
  const { formErrors } = additional;

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: { id: FIELDS_FORM_FIELD_IDS.DEPENDENCY_CONFIG_FORM, errors: undefined },
  });

  _set(formErrors, FIELDS_FORM_FIELD_IDS.DEPENDENCY_CONFIG_FORM, false);

  if (isErrorEmpty(formErrors)) {
    triggerSubmit(FIELD_FORM_CONTEXT_ID, _cloneDeep(additional));
  }
};

const handleValidationSuccess = ({ getState, params = EMPTY_OBJECT }) => {
  const { fieldsFormOnAction } = getState();
  const { errors } = params;

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: {
      id: FIELDS_FORM_FIELD_IDS.DEPENDENCY_CONFIG_FORM,
      errors: isErrorEmpty(errors) ? undefined : errors,
    },
  });
};

const handleFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { values: oldValues = EMPTY_OBJECT, fieldsFormOnAction } = getState();
  const values = { ...oldValues, [id]: value };

  setState({ values }, () =>
    fieldsFormOnAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: FIELDS_FORM_FIELD_IDS.DEPENDENCY_CONFIG_FORM, value: getFieldsFormDependencyConfigValues(values) },
    }),
  );
};

const DEPENDENCY_CONFIG_FORM_ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidationSuccess,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
};

export default DEPENDENCY_CONFIG_FORM_ACTION_HANDLERS;
