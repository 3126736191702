import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/cms';

const savePageConfiguration = (payload) => ApiService.post(`${BASE_URL}/pages`, payload);
const updatePageConfiguration = (pageName, payload) => ApiService.put(`${BASE_URL}/pages/name/${pageName}`, payload);
const searchPageConfigurations = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/pages/search`, payload);
const fetchPageConfigurationById = (pageId) => ApiService.get(`${BASE_URL}/pages/${pageId}`);
const searchPagesCMS = (payload) => ApiService.post('core/u/cms/pages/search', payload);
const fetchPageConfigurationByName = (pageName) => ApiService.get(`${BASE_URL}/pages/name/${pageName}`);
const saveWidgetConfiguration = (payload) => ApiService.post(`${BASE_URL}/widgets`, payload);
const updateWidgetConfiguration = (widgetName, payload) => ApiService.put(`${BASE_URL}/widgets/${widgetName}`, payload);
const searchWidgetConfiguration = (payload) => ApiService.post(`${BASE_URL}/widgets/search`, payload);
const deleteWidgetConfiguration = (widgetName) => ApiService.delete(`${BASE_URL}/widgets/${widgetName}`);

export default {
  savePageConfiguration,
  searchPageConfigurations,
  updatePageConfiguration,
  fetchPageConfigurationById,
  searchPagesCMS,
  fetchPageConfigurationByName,
  searchWidgetConfiguration,
  saveWidgetConfiguration,
  updateWidgetConfiguration,
  deleteWidgetConfiguration,
};
