import React from 'react';
import PropTypes from 'prop-types';

// Constants
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

// Components
import Error from '@tekion/tekion-components/organisms/FormBuilder/components/error';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import TextInputWithTag from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInputWithTag';

const TextInputWithTags = (props) => {
  const { disabled } = props;
  return (
    <div className={fieldLayoutStyles.fieldC}>
      <FieldLabel {...props} />
      <TextInputWithTag areTagsDeletable={!disabled} {...props} />
      <Error {...props} />
    </div>
  );
};

TextInputWithTags.propTypes = {
  disabled: PropTypes.bool,
};

TextInputWithTags.defaultProps = {
  disabled: false,
};

export default TextInputWithTags;
