import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { defaultMemoize } from 'reselect';

import { tget } from '@tekion/tekion-base/utils/general';
import { NO_DATA, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { searchTemplate } from '../../../actions/template.actions';

import { DEFAULT_TEMPLATE_SELECT_DROPDOWN_ROWS, ACTION_TYPES, TEMPLATE_RENDERER_FIELD_IDS } from './templateRenderer.constant';
import { FIELD_IDS as TEMPLATE_BUILDER_FIELD_IDS } from '../../../constants/templateBuilder.constants';

const getTemplatePayload = defaultMemoize((templateType) => ({ nextPageToken, searchText }) => {
  const filters = [{ field: TEMPLATE_RENDERER_FIELD_IDS.TEMPLATE_TYPE, filterType: 'IN', values: [templateType] }];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: TEMPLATE_BUILDER_FIELD_IDS.DISPLAY_NAME,
      values: [searchText],
      filterType: OPERATORS.TEXT_STARTS_WITH,
    });
  }

  return {
    rows: DEFAULT_TEMPLATE_SELECT_DROPDOWN_ROWS,
    nextPageToken,
    filters,
  };
});

const getTemplateOptions = defaultMemoize((hits) => {
  const templateOptions = _map(hits, (data) => ({
    label: tget(data, TEMPLATE_BUILDER_FIELD_IDS.DISPLAY_NAME, NO_DATA),
    value: _get(data, TEMPLATE_BUILDER_FIELD_IDS.NAME),
  }));
  return templateOptions;
});

const handleSearchTemplate = (onAction) => async (payload) => {
  const response = await searchTemplate(payload);
  const value = tget(response, 'hits', EMPTY_ARRAY);
  onAction({ type: ACTION_TYPES.ON_ASYNC_LOADED_OPTIONS, payload: { value } });
  return response;
};

export { getTemplatePayload, getTemplateOptions, handleSearchTemplate };
