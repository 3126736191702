import { defaultMemoize } from 'reselect';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';

import Heading from '@tekion/tekion-components/atoms/Heading';
import Button from '@tekion/tekion-components/atoms/Button';
import { getFormattedDateTime, DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ACTION_TYPES from '../constants/roleConfigure.actionTypes';
import { FIELD_IDS as ROLES_FIELD_IDS } from '../../../../../../../constants/roles.constants';

const createTableProps = defaultMemoize((isLoading, rows, currentPage) => ({
  currentPage: currentPage + 1,
  loading: isLoading,
  rowHeight: 35,
  minRows: 0,
  pageSize: rows,
  rows,
  showPagination: true,
}));

const createSearchPayload = defaultMemoize((roleId, nextPageToken, pageSize) => {
  let payload = {
    rows: pageSize,
    filters: [
      {
        field: 'roleId',
        filterType: 'IN',
        values: [roleId],
      },
    ],
  };
  if (!_isEmpty(nextPageToken)) {
    payload = { ...payload, nextPageToken };
  }
  return payload;
});

const getSubHeaderProps = defaultMemoize((count) => ({
  subHeaderLeftActions: [
    {
      renderer: Heading,
      renderOptions: {
        children: __(`Users (${count})`),
        size: 2,
      },
    },
  ],
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Assign User'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.ON_CLICK_ASSIGN_USERS,
    },
  ],
}));

const getModifiedTime = defaultMemoize((epochTime) => getFormattedDateTime(epochTime, DATE_TIME_FORMATS.NATIVE_DATE_FORMAT));

const getRolesDataByName = defaultMemoize((data, rolesDataByName) => {
  const roleData = _get(data, 'role', EMPTY_OBJECT);
  const name = _get(roleData, ROLES_FIELD_IDS.ROLE_NAME);
  const children = _get(data, 'childRoles');

  _set(rolesDataByName, name, roleData);

  _forEach(children, (child) => getRolesDataByName(child, rolesDataByName));
});

export { createTableProps, createSearchPayload, getSubHeaderProps, getModifiedTime, getRolesDataByName };
