import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _map from 'lodash/map';
import _noop from 'lodash/noop';
import _get from 'lodash/get';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DragDropContext, Droppable } from '@tekion/tekion-components/organisms/dragAndDrop';
import Tabs from '@tekion/tekion-components/molecules/Tabs';
import withActions from '@tekion/tekion-components/connectors/withActions';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';

import EntityTabHeader from './EntityTabHeader';
import ApplicationTabContent from '../applicationTabContent/ApplicationTabContent';

import { getApplicationData } from '../../helpers/applicationBuilder.helpers';
import { DRAG_DROP_TABS } from './constants/navigationNeededComponent.constants';
import ACTION_TYPES from '../../constants/applicationBuilder.actionTypes';
import { DIRECTION, NAVIGATION_TYPES } from '../../constants/applicationBuilder.constants';
import { COMPONENTS_TYPES } from '../../../../../../../../constants/applicationRenderer.constants';
import ACTION_HANDLERS from './helpers/navigationNeededComponent.actionHandlers';

import styles from '../../applicationBuilder.module.scss';

const TAB_BAR_STYLE = { backgroundColor: '#f4f5f6' };

const NavigationNeededComponent = ({
  isDeleteDialogVisible,
  contentHeight,
  selectedTabId,
  selectedComponentId,
  titleText,
  dialogText,
  selectedComponentType,
  applicationContext,
  applicationConfig,
  errorObject,
  onAction,
}) => {
  const { allTabs, navigationType } = useMemo(() => getApplicationData(applicationConfig), [applicationConfig]);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL_DELETE_REQUEST });
  }, [onAction]);

  const handleConfirmationDelete = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CONFIRM_DELETE_REQUEST });
  }, [onAction]);

  const onDragEnd = useCallback(
    (result) => {
      onAction({ type: ACTION_TYPES.ON_DRAG_END, payload: { result } });
    },
    [onAction],
  );

  const handleTabClick = useCallback(
    (tab) => {
      onAction({
        type: ACTION_TYPES.ON_TAB_CLICK,
        payload: { tab },
      });
    },
    [onAction],
  );

  const navigationSpecificTabsStyles = useMemo(() => {
    if (navigationType === NAVIGATION_TYPES.LEFT) {
      return { direction: DIRECTION.VERTICAL, style: { height: contentHeight - 110 } };
    }

    return { direction: DIRECTION.HORIZONTAL, style: { height: contentHeight - 120 } };
  }, [contentHeight, navigationType]);

  const navigationSpecificTabsContentStyles = useMemo(() => {
    if (navigationType === NAVIGATION_TYPES.LEFT) {
      return { style: { height: contentHeight - 110 } };
    }

    return { style: { height: contentHeight - 172 } };
  }, [navigationType, contentHeight]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT_NAVIGATION_COMPONENT });
  }, [onAction, selectedComponentId]);

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={DRAG_DROP_TABS} direction={navigationSpecificTabsStyles.direction}>
          {(provided) => (
            <div
              className={cx(styles.navigationComponentContainer, {
                [styles.selectedTab]: selectedComponentType !== COMPONENTS_TYPES.TAB,
              })}
              {...provided.droppableProps}
              ref={provided.innerRef}
              onClick={(event) => {
                event?.stopPropagation();
              }}
            >
              <Tabs
                animated={false}
                tabPosition={navigationType}
                style={navigationSpecificTabsStyles.style}
                activeKey={selectedTabId}
                tabBarStyle={TAB_BAR_STYLE}
                onTabClick={handleTabClick}
              >
                {_map(allTabs, (tabInfo, index) => (
                  <Tabs.TabPane
                    tab={
                      <EntityTabHeader
                        index={index}
                        tabInfo={tabInfo}
                        error={_get(errorObject, `${tabInfo.id}`)}
                        selectedTabId={selectedTabId}
                        onAction={onAction}
                      />
                    }
                    key={tabInfo.id}
                  >
                    <div className={styles.tabContent} style={navigationSpecificTabsContentStyles.style}>
                      <ApplicationTabContent tabInfo={tabInfo} applicationContext={applicationContext} />
                    </div>
                  </Tabs.TabPane>
                ))}
              </Tabs>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div
        onClick={(event) => {
          event?.stopPropagation();
        }}
      >
        <PropertyControlledComponent controllerProperty={isDeleteDialogVisible}>
          <ConfirmationDialog
            isVisible={isDeleteDialogVisible}
            title={titleText}
            content={dialogText}
            onSubmit={handleConfirmationDelete}
            onCancel={handleCancel}
          />
        </PropertyControlledComponent>
      </div>
    </>
  );
};

NavigationNeededComponent.propTypes = {
  isDeleteDialogVisible: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  navigationType: PropTypes.string,
  selectedComponentId: PropTypes.string,
  selectedTabId: PropTypes.string,
  titleText: PropTypes.string,
  dialogText: PropTypes.string,
  selectedComponentType: PropTypes.string,
  applicationConfig: PropTypes.object,
  applicationContext: PropTypes.object,
  errorObject: PropTypes.object,
  onAction: PropTypes.func.isRequired,
  handleClickComponent: PropTypes.func,
};

NavigationNeededComponent.defaultProps = {
  isDeleteDialogVisible: false,
  navigationType: EMPTY_STRING,
  selectedComponentId: EMPTY_STRING,
  selectedTabId: EMPTY_STRING,
  titleText: EMPTY_STRING,
  dialogText: EMPTY_STRING,
  selectedComponentType: EMPTY_STRING,
  applicationConfig: EMPTY_OBJECT,
  applicationContext: EMPTY_OBJECT,
  errorObject: EMPTY_OBJECT,
  handleClickComponent: _noop,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(NavigationNeededComponent);
