import React, { useEffect, useCallback, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _includes from 'lodash/includes';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';

import Heading from '@tekion/tekion-components/atoms/Heading';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Button from '@tekion/tekion-components/atoms/Button';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import FontIcon, { SIZES as ICON_SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Tooltip from '@tekion/tekion-components/atoms/tooltip';
import Menu from '@tekion/tekion-components/molecules/Menu';
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import { isFilterOfSelectType } from '@tekion/tekion-components/organisms/filterSection/organisms/defaultFilterToolbar/defaultFilterToolbar.helpers';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

import FilterList from './components/FilterList';
import MultiSelectInputWithTags from '../../../../atoms/multiSelectInputWithTags';

import ACTION_HANDLERS from './helpers/filterForm.actionHandlers';
import { getInfoIconSelectedFields } from './helpers/filterForm.utils';

import ACTION_TYPES from './constants/filterForm.actionTypes';
import { FIELD_IDS } from './constants/filterForm.constants';

import filterReader from '../../../../readers/filter.reader';

import styles from './filterForm.module.scss';

const FilterForm = ({
  isModalVisible,
  isUpfrontFiltersEnabled,
  isPreDefineFiltersEnabled,
  entity,
  selectedViewComponentProperties,
  fieldsConfig,
  errors,
  fieldTypeOptions,
  filterableFieldTypeOptions,
  preAppliedFilters,
  filterMetadata,
  onAction,
}) => {
  const isComplexField = _get(entity, 'complexField', false);

  const handleSaveModal = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_SAVE_MODAL,
    });
  }, [onAction]);

  const handleCancelModal = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CANCEL_MODAL,
    });
  }, [onAction]);

  const handleAddClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CLICK_ADD_FILTER,
    });
  }, [onAction]);

  const selectedFilterableFields = useMemo(() => _map(filterableFieldTypeOptions, 'value'), [filterableFieldTypeOptions]);

  const upfrontFieldOptions = useMemo(
    () => _filter(filterableFieldTypeOptions, (option) => isFilterOfSelectType(option) && _includes(selectedFilterableFields, _get(option, 'value'))),
    [filterableFieldTypeOptions, selectedFilterableFields],
  );

  const upfrontFields = useMemo(
    () =>
      _reduce(
        filterMetadata,
        (prevFields, currFilterConfig) => {
          const fieldName = filterReader.fieldName(currFilterConfig);
          const isUpfront = _get(currFilterConfig, FIELD_IDS.UPFRONT, false);
          if (isUpfront) {
            prevFields.push(fieldName);
          }
          return prevFields;
        },
        [],
      ),
    [filterMetadata],
  );

  const { selectedPreAppliedFields, selectedUpfrontFields } = useMemo(
    () => getInfoIconSelectedFields(filterableFieldTypeOptions, fieldsConfig),
    [fieldsConfig, filterableFieldTypeOptions],
  );

  const renderSelectedFields = useCallback((selectedFields) => {
    if (_size(selectedFields) > 0) {
      return (
        <Menu className={styles.menuPopup}>
          {_map(selectedFields, ({ label }, index) => (
            <Menu.Item className={styles.filtersMenuItem} key={index}>
              {index + 1}.{__(` ${label}`)}
            </Menu.Item>
          ))}
        </Menu>
      );
    }

    return <span className={styles.fallbackText}>{__('No Fields')}</span>;
  }, []);

  const renderInfoIconToolTip = useCallback(
    (selectedFields) => (
      <Tooltip placement="bottom" overlayClassName={styles.tooltipOverlay} title={renderSelectedFields(selectedFields)}>
        <FontIcon className={styles.infoIcon} size={ICON_SIZES.MD_S}>
          icon-info
        </FontIcon>
      </Tooltip>
    ),
    [renderSelectedFields],
  );

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE,
      payload: { selectedViewComponentProperties },
    });
  }, [onAction, selectedViewComponentProperties]);

  if (isComplexField) {
    return null;
  }

  return (
    <div className="full-width">
      <div className={styles.filterComponent}>
        <div className={styles.addFilter}>
          <Heading size={5} className={styles.filterComponentHeading}>
            {__('Filter Properties')}
          </Heading>
        </div>
        <Button disable view={Button.VIEW.TERTIARY} className={styles.addButton} onClick={handleAddClick}>
          {__('Configure')}
        </Button>
      </div>
      <div className={styles.addedFiltersInfo}>
        <span>
          {__('Filterable Fields ')}({_size(filterableFieldTypeOptions)})
        </span>
        {renderInfoIconToolTip(filterableFieldTypeOptions)}
      </div>
      <div className={styles.addedFiltersInfo}>
        <span>
          {__('Upfront Fields ')}({_size(selectedUpfrontFields)})
        </span>
        {renderInfoIconToolTip(selectedUpfrontFields)}
      </div>
      <div className={styles.addedFiltersInfo}>
        <span>
          {__('Pre-Defined Filters ')}({_size(selectedPreAppliedFields)})
        </span>
        {renderInfoIconToolTip(selectedPreAppliedFields)}
      </div>
      <Modal
        visible={isModalVisible}
        width={SIZES.XL}
        title={__('Filters Properties for Table')}
        submitBtnText={__('Save')}
        onSubmit={handleSaveModal}
        onCancel={handleCancelModal}
      >
        <div className={styles.filterModalComponent}>
          <div className={styles.selectInputWithTagsContainer}>
            <div className={styles.selectInputLeftContainer}>
              <div className={styles.heading}>{__('Filterable Field(s)')}</div>
              <MultiSelectInputWithTags
                isMulti
                isSearchable
                isSelectValueDeletable
                menuOpenOnblur
                closeMenuOnSelect={false}
                id={FIELD_IDS.FILTERABLE_FIELDS}
                fieldClassName={styles.selectInputWithTags}
                values={selectedFilterableFields}
                options={fieldTypeOptions}
                noOptionsMessage={() => __('No Available Filterable Fields')}
                onAction={onAction}
              />
            </div>
          </div>
          <div className={styles.checkboxComponent}>
            <CheckboxRenderer
              checked={isUpfrontFiltersEnabled}
              id={FIELD_IDS.UPFRONT_FILTER_CHECKBOX}
              fieldClassName={styles.checkBoxRenderer}
              checkboxLabel={__('Upfront Filter(s)')}
              onAction={onAction}
            />
            <CheckboxRenderer
              checked={isPreDefineFiltersEnabled}
              id={FIELD_IDS.PRE_APPLIED_FILTER_CHECKBOX}
              fieldClassName={styles.checkBoxRenderer}
              checkboxLabel={__('Pre-define Filter(s)')}
              onAction={onAction}
            />
          </div>
          <PropertyControlledComponent controllerProperty={isUpfrontFiltersEnabled}>
            <div className={styles.upfrontFilterComponent}>
              <div className={styles.heading}>{__('Upfront Filter(s)')}</div>
              <MultiSelectInputWithTags
                isMulti
                isSearchable
                menuOpenOnblur
                id={FIELD_IDS.UPFRONT_FILTERS}
                fieldClassName={styles.selectInputWithTags}
                values={upfrontFields}
                options={upfrontFieldOptions}
                noOptionsMessage={() => __('No Available Upfront Supported Fields')}
                onAction={onAction}
              />
            </div>
          </PropertyControlledComponent>
          <PropertyControlledComponent controllerProperty={isPreDefineFiltersEnabled}>
            <div className={styles.preAppliedFilterComponent}>
              <div className={styles.heading}>{__('Pre-Defined Filter(s)')}</div>
              <FilterList
                id={FIELD_IDS.FILTER_LIST}
                fieldsConfig={fieldsConfig}
                preAppliedFilters={preAppliedFilters}
                errors={errors}
                filterableFieldTypeOptions={filterableFieldTypeOptions}
                onAction={onAction}
              />
            </div>
          </PropertyControlledComponent>
        </div>
      </Modal>
    </div>
  );
};

FilterForm.propTypes = {
  isUpfrontFiltersEnabled: PropTypes.bool,
  isPreDefineFiltersEnabled: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  entity: PropTypes.object.isRequired,
  selectedViewComponentProperties: PropTypes.object.isRequired,
  errors: PropTypes.array,
  fieldsConfig: PropTypes.object,
  preAppliedFilters: PropTypes.array,
  fieldTypeOptions: PropTypes.array,
  filterableFieldTypeOptions: PropTypes.array,
  filterMetadata: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

FilterForm.defaultProps = {
  isUpfrontFiltersEnabled: false,
  isPreDefineFiltersEnabled: false,
  isModalVisible: false,
  fieldsConfig: EMPTY_OBJECT,
  errors: EMPTY_ARRAY,
  preAppliedFilters: EMPTY_ARRAY,
  fieldTypeOptions: EMPTY_ARRAY,
  filterableFieldTypeOptions: EMPTY_ARRAY,
  filterMetadata: EMPTY_ARRAY,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS))(FilterForm);
