const ACTION_TYPES = {
  CREATE_USER: 'CREATE_USER',
  ON_FETCH_USERS_DATA: 'ON_FETCH_USERS_DATA',
  ON_CLOSE_REQUEST_DIALOG: 'ON_CLOSE_REQUEST_DIALOG',
  ON_CONFIRM_REQUEST: 'ON_CONFIRM_REQUEST',
  REMOVE: 'REMOVE',
  EDIT: 'EDIT',
};

export default ACTION_TYPES;
