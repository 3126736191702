// Tekion-components
import Button from '@tekion/tekion-components/atoms/Button';
import { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';

// Constants
import ACTION_TYPES from './validationRuleBuilderList.actionTypes';

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create New'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_VALIDATION_RULE,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showFilter: true,
  showHeader: false,
  showSearch: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const RULE_STATUS = {
  ACTIVE: true,
  INACTIVE: false,
};

const RULE_STATUS_COLOR_MAP = {
  [RULE_STATUS.ACTIVE]: COLOR_MAP.GREEN,
  [RULE_STATUS.INACTIVE]: COLOR_MAP.RED,
};

const RULE_STATUS_LABEL_MAP = {
  [RULE_STATUS.ACTIVE]: __('Active'),
  [RULE_STATUS.INACTIVE]: __('Inactive'),
};

const PAGE_SIZE_OPTIONS = [20, 50, 100, 150];

const DEFAULT_PAGE_SIZE = 50;

const TEXT_STARTS_WITH = 'TEXT_STARTS_WITH';

export {
  SUB_HEADER_PROPS,
  TABLE_MANAGER_PROPS,
  RULE_STATUS,
  RULE_STATUS_COLOR_MAP,
  RULE_STATUS_LABEL_MAP,
  PAGE_SIZE_OPTIONS,
  DEFAULT_PAGE_SIZE,
  TEXT_STARTS_WITH,
};
