import React from 'react';
import PropTypes from 'prop-types';

import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import ActionRenderer from '../../../../actionRenderer';

const KebabRowActionCellRenderer = ({ actionModalContextId, original, entityDef, actionBuilderProps, customRowActionsData }) => (
  <ActionRenderer
    actionModalContextId={actionModalContextId}
    entityDef={entityDef}
    entityRecord={original}
    actionBuilderProps={actionBuilderProps}
    customActionsData={customRowActionsData}
  />
);

KebabRowActionCellRenderer.propTypes = {
  actionModalContextId: PropTypes.string.isRequired,
  original: PropTypes.object.isRequired,
  entityDef: PropTypes.object.isRequired,
  actionBuilderProps: PropTypes.object.isRequired,
  customRowActionsData: PropTypes.array.isRequired,
};

export default makeCellRenderer(KebabRowActionCellRenderer);
