/* eslint-disable max-len */
import { STUDIO_ROUTE, TENANT_UNIVERSE_BASE_ROUTE } from '../../../constants/routes';
import PAGE_IDS from './PageIds.constants';

const PROJECT_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.PROJECTS}`;
const PROJECT_APPLICATION_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.PROJECT_APPLICATIONS}/:projectName`;
const PROJECT_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.PROJECT_CREATE}`;
const PROJECT_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.PROJECT_EDIT}/:projectName`;

const WORKSPACE_LIST_ROUTE = `${TENANT_UNIVERSE_BASE_ROUTE}/${PAGE_IDS.WORKSPACES}`;

const USER_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.USERS}`;
const USER_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.USER_CREATE}`;
const USER_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.USER_EDIT}/:userId`;

const TENANT_UNIVERSE_USER_LIST_ROUTE = `${TENANT_UNIVERSE_BASE_ROUTE}/${PAGE_IDS.USERS}`;
const TENANT_UNIVERSE_USER_CREATE_ROUTE = `${TENANT_UNIVERSE_BASE_ROUTE}/${PAGE_IDS.USER_CREATE}`;
const TENANT_UNIVERSE_USER_EDIT_ROUTE = `${TENANT_UNIVERSE_BASE_ROUTE}/${PAGE_IDS.USER_EDIT}/:userId`;

const USER_GROUP_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.USER_GROUPS}`;
const USER_GROUP_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.USER_GROUP_CREATE}`;
const USER_GROUP_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.USER_GROUP_EDIT}/:groupName`;

const TENANT_UNIVERSE_USER_GROUP_LIST_ROUTE = `${TENANT_UNIVERSE_BASE_ROUTE}/${PAGE_IDS.USER_GROUPS}`;
const TENANT_UNIVERSE_USER_GROUP_CREATE_ROUTE = `${TENANT_UNIVERSE_BASE_ROUTE}/${PAGE_IDS.USER_GROUP_CREATE}`;
const TENANT_UNIVERSE_USER_GROUP_EDIT_ROUTE = `${TENANT_UNIVERSE_BASE_ROUTE}/${PAGE_IDS.USER_GROUP_EDIT}/:groupName`;

const APPLICATION_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.APPLICATIONS}`;
const APPLICATION_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.APPLICATION_CREATE}`;
const APPLICATION_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.APPLICATION_EDIT}/:applicationName`;
const CONFIGURE_APPLICATION_ROUTE_CREATE = `${STUDIO_ROUTE}/${PAGE_IDS.APPLICATIONS}/${PAGE_IDS.CONFIGURE_APPLICATION}`;
const CONFIGURE_APPLICATION_ROUTE_EDIT = `${STUDIO_ROUTE}/${PAGE_IDS.APPLICATIONS}/:applicationName/${PAGE_IDS.CONFIGURE_APPLICATION}`;

const ENTITY_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}`;
const ENTITY_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITY_CREATE}`;
const ENTITY_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITY_EDIT}/:entityName`;

const FIELD_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.FIELDS}`;
const FIELD_LIST_ROUTE_V2 = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.FIELDS}/v2`;
const FIELD_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.FIELD_CREATE}`;
const FIELD_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.FIELD_EDIT}/:fieldName`;
const FIELD_CREATE_ROUTE_V2 = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.FIELD_CREATE}/v2`;
const FIELD_EDIT_ROUTE_V2 = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.FIELD_EDIT}/:fieldName/v2`;

const VALIDATION_RULE_BUILDER_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.VALIDATION_RULE_BUILDER}`;
const VALIDATION_RULE_BUILDER_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.VALIDATION_RULE_BUILDER_CREATE}`;
const VALIDATION_RULE_BUILDER_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.VALIDATION_RULE_BUILDER_EDIT}/:ruleName`;

const VIEW_BUILDER_LIST_TAB_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.VIEW_BUILDER}/:tabId`;
const VIEW_BUILDER_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.VIEW_BUILDER_CREATE}/:tabId`;
const VIEW_BUILDER_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.VIEW_BUILDER_EDIT}/:tabId/:viewName`;

const ACTION_BUILDER_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.ACTION_BUILDER}`;
const ACTION_BUILDER_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.ACTION_BUILDER_CREATE}`;
const ACTION_BUILDER_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.ACTION_BUILDER_EDIT}/:actionName`;

const PERMISSION_SET_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET}`;
const PERMISSION_SET_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_CREATE}`;
const PERMISSION_SET_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_EDIT}/:permissionName`;
const PERMISSION_SET_CONFIGURE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_CONFIGURE}/:permissionName/:tabName`;
const ENTITY_FIELD_PERMISSION_CONFIGURE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_CONFIGURE}/:permissionName/:tabName/:entityName`;

const REPORTEE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTEE}`;
const REPORTEE_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTEE}/${PAGE_IDS.REPORTEE_CREATE}`;
const REPORTEE_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTEE}/${PAGE_IDS.REPORTEE_EDIT}/:roleName`;
const ROLE_CONFIGURE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTEE}/${PAGE_IDS.ROLE_CONFIGURE}/:roleName`;
const DASHBOARD_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTING}/${PAGE_IDS.DASHBOARDS}`;
const DASHBOARD_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTING}/${PAGE_IDS.DASHBOARD_CREATE}`;
const DASHBOARD_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTING}/${PAGE_IDS.DASHBOARD_EDIT}/:dashboardName`;

const ENTITY_RECORD_GROUP_LIST = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITY_RECORD_GROUP_LIST}`;
const ENTITY_RECORD_GROUP_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITY_RECORD_GROUP_CREATE}`;
const ENTITY_RECORD_GROUP_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITY_RECORD_GROUP_EDIT}/:recordGroupName`;
const RECORD_SHARING_RULE_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.RECORD_SHARING_RULES}`;
const WWD_PERMISSION_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.WWD_PERMISSIONS}`;

const RECORD_TYPE_LIST = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.RECORD_TYPE}`;
const RECORD_TYPE_CREATE = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.RECORD_TYPE_CREATE}`;
const RECORD_TYPE_EDIT = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.RECORD_TYPE_EDIT}/:recordTypeName`;
const RECORD_TYPE_DETAILS = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.RECORD_TYPE}/:recordTypeName`;
const RECORD_TYPE_DETAILS_EDIT = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/:entityName/${PAGE_IDS.RECORD_TYPE_DETAILS}`;

const TEMPLATE_BUILDER_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.TEMPLATE_BUILDER}`;
const TEMPLATE_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.TEMPLATE_CREATE}`;
const TEMPLATE_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.TEMPLATE_EDIT}/:templateName`;

const WORKFLOW_LIST = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/:tabId?`;
const RULE_CREATE_FORM_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${PAGE_IDS.RULE_CREATE}/initiate`;
const RULE_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${PAGE_IDS.RULE_CREATE}`;
const RULE_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${PAGE_IDS.RULE_EDIT}/:workflowId`;
const PROCESS_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/processes`;
const PROCESS_CREATE_FORM_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${PAGE_IDS.PROCESS_CREATE}/initiate`;
const PROCESS_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${PAGE_IDS.PROCESS_EDIT}/:workflowId`;
const PROCESS_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${PAGE_IDS.PROCESS_CREATE}`;

const JOB_SCHEDULE_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.JOBS}`;
const JOB_SCHEDULE_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.JOB_CREATE}`;
const JOB_SCHEDULE_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.JOB_EDIT}/:jobScheduleId`;

const VISUAL_BUILDER_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.VISUAL_BUILDER}`;
const VISUAL_BUILDER_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.VISUAL_BUILDER_CREATE}`;
const VISUAL_BUILDER_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.VISUAL_BUILDER_EDIT}/:pageName`;

const APPROVAL_GROUPS_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_GROUPS}`;
const APPROVAL_CATEGORIES_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_CATEGORIES}`;
const APPROVAL_SETTINGS_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_SETTINGS}`;
const APPROVAL_SETTING_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_SETTING_CREATE}`;
const APPROVAL_SETTING_DETAILS_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_SETTING_DETAIL}/:settingName`;
const APPROVAL_SETTING_ADD_FIELD_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_SETTING}/:settingName/${PAGE_IDS.APPROVAL_SETTING_ADD_FIELD}`;
const APPROVAL_SETTING_EDIT_FIELD_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_SETTING}/:settingName/${PAGE_IDS.APPROVAL_SETTING_EDIT_FIELD}/:fieldName`;
const APPROVAL_PROCESSES_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_PROCESSES}`;
const APPROVAL_PROCESS_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_PROCESS_CREATE}`;
const APPROVAL_PROCESS_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_PROCESS_EDIT}/:processName`;

const EXPORT_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.EXPORTS}`;
const EXPORT_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.EXPORT_CREATE}`;
const EXPORT_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.EXPORT_EDIT}/:bundleId`;
const COMPONENT_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.EXPORTS}/${PAGE_IDS.ADD_METADATA_COMPONENT}/:bundleId`;
const IMPORT_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.IMPORT_CREATE}`;
const SUBSCRIPTION_CONFIG_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.SUBSCRIPTION_CONFIGS}`;
const SUBSCRIPTION_CONFIG_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.SUBSCRIPTION_CONFIG_CREATE}`;
const SUBSCRIPTION_CONFIG_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.SUBSCRIPTION_CONFIG_EDIT}/:subscriptionConfigName`;
const SUBSCRIPTION_CONFIG_LOGS = `${STUDIO_ROUTE}/${PAGE_IDS.SUBSCRIPTION_CONFIG_LOGS}/:subscriptionConfigName`;

const SERVER_CREDENTIAL_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.SERVER_CREDENTIALS_LIST}`;
const SERVER_CREDENTIAL_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.SERVER_CREDENTIAL_CREATE}`;
const SERVER_CREDENTIAL_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.SERVER_CREDENTIAL_EDIT}/:serverCredentialName`;

const EXTERNAL_CREDENTIAL_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.EXTERNAL_CREDENTIALS_LIST}`;
const EXTERNAL_CREDENTIAL_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.EXTERNAL_CREDENTIAL_CREATE}`;
const EXTERNAL_CREDENTIAL_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.EXTERNAL_CREDENTIAL_EDIT}/:externalCredentialName`;

const AUTH_PROVIDER_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.AUTH_PROVIDERS_LIST}`;
const AUTH_PROVIDER_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.AUTH_PROVIDER_CREATE}`;
const AUTH_PROVIDER_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.AUTH_PROVIDER_EDIT}/:authProviderName`;
const LOGIN_CONFIGURATIONS_ROUTE = `${TENANT_UNIVERSE_BASE_ROUTE}/${PAGE_IDS.LOGIN_CONFIGURATIONS}`;

const GLOBAL_SELECT_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.GLOBAL_SELECT_LIST}`;
const GLOBAL_SELECT_LIST_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.CREATE_GLOBAL_SELECT_LIST}`;
const GLOBAL_SELECT_LIST_EDIT_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.EDIT_GLOBAL_SELECT_LIST}/:globalSelectName`;

const IMPORT_DATA_LIST_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.IMPORT_DATA_STUDIO}/${PAGE_IDS.DATA_IMPORTS}`;
const IMPORT_DATA_CREATE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.IMPORT_DATA_STUDIO}/${PAGE_IDS.CREATE_IMPORT}`;
const IMPORT_DATA_DETAIL_PAGE_ROUTE = `${STUDIO_ROUTE}/${PAGE_IDS.IMPORT_DATA_STUDIO}/${PAGE_IDS.DATA_IMPORTS}/:importTaskId`;

const ROUTES = {
  REPORTEE_ROUTE,
  REPORTEE_CREATE_ROUTE,
  REPORTEE_EDIT_ROUTE,
  ROLE_CONFIGURE_ROUTE,
  PROJECT_LIST_ROUTE,
  PROJECT_CREATE_ROUTE,
  PROJECT_EDIT_ROUTE,
  PROJECT_APPLICATION_LIST_ROUTE,
  WORKSPACE_LIST_ROUTE,
  USER_LIST_ROUTE,
  USER_CREATE_ROUTE,
  USER_EDIT_ROUTE,
  TENANT_UNIVERSE_USER_LIST_ROUTE,
  TENANT_UNIVERSE_USER_CREATE_ROUTE,
  TENANT_UNIVERSE_USER_EDIT_ROUTE,
  USER_GROUP_LIST_ROUTE,
  USER_GROUP_CREATE_ROUTE,
  USER_GROUP_EDIT_ROUTE,
  TENANT_UNIVERSE_USER_GROUP_LIST_ROUTE,
  TENANT_UNIVERSE_USER_GROUP_CREATE_ROUTE,
  TENANT_UNIVERSE_USER_GROUP_EDIT_ROUTE,
  APPLICATION_LIST_ROUTE,
  APPLICATION_CREATE_ROUTE,
  APPLICATION_EDIT_ROUTE,
  CONFIGURE_APPLICATION_ROUTE_CREATE,
  CONFIGURE_APPLICATION_ROUTE_EDIT,
  ENTITY_LIST_ROUTE,
  ENTITY_CREATE_ROUTE,
  ENTITY_EDIT_ROUTE,
  FIELD_LIST_ROUTE,
  FIELD_LIST_ROUTE_V2,
  FIELD_CREATE_ROUTE,
  FIELD_CREATE_ROUTE_V2,
  FIELD_EDIT_ROUTE_V2,
  FIELD_EDIT_ROUTE,
  VALIDATION_RULE_BUILDER_LIST_ROUTE,
  VALIDATION_RULE_BUILDER_CREATE_ROUTE,
  VALIDATION_RULE_BUILDER_EDIT_ROUTE,
  VIEW_BUILDER_LIST_TAB_ROUTE,
  VIEW_BUILDER_CREATE_ROUTE,
  VIEW_BUILDER_EDIT_ROUTE,
  ACTION_BUILDER_LIST_ROUTE,
  ACTION_BUILDER_CREATE_ROUTE,
  ACTION_BUILDER_EDIT_ROUTE,
  PERMISSION_SET_LIST_ROUTE,
  PERMISSION_SET_CREATE_ROUTE,
  PERMISSION_SET_EDIT_ROUTE,
  PERMISSION_SET_CONFIGURE_ROUTE,
  ENTITY_FIELD_PERMISSION_CONFIGURE_ROUTE,
  ENTITY_RECORD_GROUP_LIST,
  ENTITY_RECORD_GROUP_CREATE_ROUTE,
  ENTITY_RECORD_GROUP_EDIT_ROUTE,
  RECORD_SHARING_RULE_LIST_ROUTE,
  WWD_PERMISSION_LIST_ROUTE,
  DASHBOARD_LIST_ROUTE,
  DASHBOARD_CREATE_ROUTE,
  DASHBOARD_EDIT_ROUTE,
  TEMPLATE_BUILDER_LIST_ROUTE,
  TEMPLATE_CREATE_ROUTE,
  TEMPLATE_EDIT_ROUTE,
  RECORD_TYPE_LIST,
  RECORD_TYPE_CREATE,
  RECORD_TYPE_EDIT,
  RECORD_TYPE_DETAILS,
  RECORD_TYPE_DETAILS_EDIT,
  JOB_SCHEDULE_LIST_ROUTE,
  JOB_SCHEDULE_CREATE_ROUTE,
  JOB_SCHEDULE_EDIT_ROUTE,
  VISUAL_BUILDER_LIST_ROUTE,
  VISUAL_BUILDER_CREATE_ROUTE,
  VISUAL_BUILDER_EDIT_ROUTE,
  WORKFLOW_LIST,
  RULE_CREATE_FORM_ROUTE,
  PROCESS_CREATE_FORM_ROUTE,
  PROCESS_CREATE_ROUTE,
  RULE_CREATE_ROUTE,
  RULE_EDIT_ROUTE,
  PROCESS_EDIT_ROUTE,
  PROCESS_LIST_ROUTE,
  APPROVAL_GROUPS_ROUTE,
  APPROVAL_CATEGORIES_ROUTE,
  APPROVAL_SETTINGS_ROUTE,
  APPROVAL_SETTING_CREATE_ROUTE,
  APPROVAL_SETTING_DETAILS_ROUTE,
  APPROVAL_SETTING_ADD_FIELD_ROUTE,
  APPROVAL_SETTING_EDIT_FIELD_ROUTE,
  APPROVAL_PROCESSES_ROUTE,
  APPROVAL_PROCESS_CREATE_ROUTE,
  APPROVAL_PROCESS_EDIT_ROUTE,
  EXPORT_LIST_ROUTE,
  EXPORT_CREATE_ROUTE,
  EXPORT_EDIT_ROUTE,
  COMPONENT_CREATE_ROUTE,
  IMPORT_CREATE_ROUTE,
  SERVER_CREDENTIAL_LIST_ROUTE,
  SERVER_CREDENTIAL_CREATE_ROUTE,
  SERVER_CREDENTIAL_EDIT_ROUTE,
  EXTERNAL_CREDENTIAL_LIST_ROUTE,
  EXTERNAL_CREDENTIAL_CREATE_ROUTE,
  EXTERNAL_CREDENTIAL_EDIT_ROUTE,
  AUTH_PROVIDER_LIST_ROUTE,
  AUTH_PROVIDER_CREATE_ROUTE,
  AUTH_PROVIDER_EDIT_ROUTE,
  SUBSCRIPTION_CONFIG_LIST_ROUTE,
  SUBSCRIPTION_CONFIG_CREATE_ROUTE,
  SUBSCRIPTION_CONFIG_EDIT_ROUTE,
  SUBSCRIPTION_CONFIG_LOGS,
  LOGIN_CONFIGURATIONS_ROUTE,
  GLOBAL_SELECT_LIST_ROUTE,
  GLOBAL_SELECT_LIST_CREATE_ROUTE,
  GLOBAL_SELECT_LIST_EDIT_ROUTE,
  IMPORT_DATA_LIST_ROUTE,
  IMPORT_DATA_CREATE_ROUTE,
  IMPORT_DATA_DETAIL_PAGE_ROUTE,
};

export default ROUTES;
