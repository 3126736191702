import produce from 'immer';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _snakeCase from 'lodash/snakeCase';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';

import { editProjectData, getProjectByName, saveProjectData } from '../../../../../actions/workspaceProjectManagement.actions';
import { FIELD_IDS } from './projectForm.fields';

import { STUDIO_ROUTE } from '../../../../../constants/routes';
import { FORM_MODES } from '../../../../../constants/general.constants';
import PAGE_IDS from '../../../constants/PageIds.constants';
import { STATUS } from '../constants/projectForm.general.constants';
import ACTION_TYPES from '../constants/projectForm.actionTypes';

const setInitialProjectFormValues = async ({ getState, setState }) => {
  const { location, match } = getState();
  const projectName = _get(match, 'params.projectName');

  if (_isEmpty(projectName)) {
    setState({ formMode: FORM_MODES.CREATE });
    return;
  }

  setState({ isDataLoading: true });
  let projectData = _get(location, 'state.projectInfo');

  if (_isEmpty(projectData)) {
    projectData = await getProjectByName(projectName);
  }
  setState({ projectData, isDataLoading: false, formMode: FORM_MODES.EDIT });
};

const handleFieldChange = ({ getState, setState, params }) => {
  const { formMode, disableAutoFillForName = false } = getState();
  const { id, value } = params;

  setState(
    produce((draft) => {
      _set(draft, `projectData.${id}`, value);
      if (formMode === FORM_MODES.CREATE && id === FIELD_IDS.DISPLAY_NAME && !disableAutoFillForName) {
        _set(draft, `projectData.${FIELD_IDS.NAME}`, _snakeCase(value));
      } else if (id === FIELD_IDS.NAME) {
        setState({ disableAutoFillForName: true });
      }
    }),
  );
};

const handleErrors = ({ setState, params }) => {
  const { errors } = params;

  setState({ errors });
};

const handleRedirection = ({ getState }) => {
  const { history } = getState();

  const path = `${STUDIO_ROUTE}/${PAGE_IDS.PROJECTS}`;
  history.push({ pathname: path });
};

const handleSubmit = async ({ getState, setState }) => {
  const { history, projectData, match } = getState();

  setState({ isSaveLoading: true });
  const projectName = _get(match, 'params.projectName', '');
  let responseData = EMPTY_OBJECT;
  let responseStatus = EMPTY_STRING;

  if (_isEmpty(projectName)) {
    const { data, status } = await saveProjectData(projectData);
    responseData = data;
    responseStatus = status;
  } else {
    const { data, status } = await editProjectData(projectName, projectData);
    responseData = data;
    responseStatus = status;
  }
  if (responseStatus !== STATUS.FAILED) {
    const { name } = responseData;
    setTimeout(() => {
      history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_IDS.PROJECT_APPLICATIONS}/${name}`, state: { projectData: responseData } });
    }, ES_REFETCH_DELAY);
  } else {
    setState({ isSaveLoading: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INITIALIZE_PROJECT_FORM_VALUES]: setInitialProjectFormValues,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
};

export default ACTION_HANDLERS;
