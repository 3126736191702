import React from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import ViewViewer from '../../../organisms/viewViewer/ViewViewer';

import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../constants/viewBuilder.constants';

const ChildListViewRenderer = ({ childRelationshipContext, viewConfiguration, entity }) => {
  if (_isEmpty(viewConfiguration) || _isEmpty(_get(viewConfiguration, VIEW_CONFIGURATION_FIELD_IDS.NAME))) {
    return null;
  }

  return (
    <ViewViewer
      isPreviewMode
      isChildTable
      isFilterPersist={false}
      childRelationshipContext={childRelationshipContext}
      viewConfiguration={viewConfiguration}
      viewConfigName={_get(viewConfiguration, VIEW_CONFIGURATION_FIELD_IDS.NAME)}
      entity={entity}
    />
  );
};
ChildListViewRenderer.propTypes = {
  childRelationshipContext: PropTypes.object,
  viewConfiguration: PropTypes.object,
  entity: PropTypes.object,
};

ChildListViewRenderer.defaultProps = {
  childRelationshipContext: undefined,
  viewConfiguration: undefined,
  entity: undefined,
};

export default ChildListViewRenderer;
