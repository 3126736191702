// Utils
import { DATE_TIME_FORMATS, getDateTimeFormatValue } from '@tekion/tekion-base/utils/dateUtils';

const RELATIVE_DATE_FORMAT = {
  sameDay: getDateTimeFormatValue(DATE_TIME_FORMATS.DATE_ALPHA_MONTH_WITH_TIME),
  lastDay: getDateTimeFormatValue(DATE_TIME_FORMATS.DATE_ALPHA_MONTH_WITH_TIME),
  lastWeek: getDateTimeFormatValue(DATE_TIME_FORMATS.DATE_ALPHA_MONTH_WITH_TIME),
  nextWeek: getDateTimeFormatValue(DATE_TIME_FORMATS.DATE_ALPHA_MONTH_WITH_TIME),
  sameElse: getDateTimeFormatValue(DATE_TIME_FORMATS.DATE_ALPHA_MONTH_WITH_TIME),
};

const ACTIVITY_TYPES = {
  CREATED: 'Created',
  UPDATE: 'Update',
};

const SYTEM_USER = 'SYSTEM_USER';

export { RELATIVE_DATE_FORMAT, ACTIVITY_TYPES, SYTEM_USER };
