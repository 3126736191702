import { FIELD_IDS } from '../constants/approvalSettingForm.constants';

import styles from '../../../approvalStudio.module.scss';

const SECTIONS = [
  {
    className: styles.section,
    rows: [{ columns: [FIELD_IDS.DISPLAY_NAME] }, { columns: [FIELD_IDS.GROUP, FIELD_IDS.CATEGORY] }],
  },
];

export default SECTIONS;
