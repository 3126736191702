import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import { FIELD_IDS } from '../../../../../../constants/entityRecordGroup.constants';

const RULE_STATUS_RADIO_OPTIONS = {
  ACTIVE: true,
  INACTIVE: false,
};

const RULE_STATUS_RADIO_LABEL_MAP = {
  [RULE_STATUS_RADIO_OPTIONS.ACTIVE]: __('Active'),
  [RULE_STATUS_RADIO_OPTIONS.INACTIVE]: __('Inactive'),
};

const ENTITY_RECORD_GROUP_FORM_CONTEXT_ID = 'ENTITY_RECORD_GROUP_FORM_CONTEXT_ID';

const INITIAL_STATE = {
  formValues: {
    [FIELD_IDS.CONDITION]: {
      [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: [EMPTY_OBJECT],
      [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: '1',
    },
  },
};

export { RULE_STATUS_RADIO_OPTIONS, RULE_STATUS_RADIO_LABEL_MAP, ENTITY_RECORD_GROUP_FORM_CONTEXT_ID, INITIAL_STATE };
