import { FIELD_IDS as EVENT_FIELD_IDS } from '../../../../../constants/event.fieldIds';
import { EVENT_ACTIONS, EVENT_ACTIONS_TYPES, EVENT_NAMES } from '../../../../../constants/eventActions.constants';

const ACTION_TYPES = {
  ON_INIT: 'INIT_DATA',
  ON_OPEN_SIDE_PANE: 'ON_OPEN_SIDE_PANE',
  ON_CLOSE_SIDE_PANE: 'ON_CLOSE_SIDE_PANE',
  ON_CLICK_CREATE: 'ON_CLICK_CREATE',
  GO_BACK: 'GO_BACK',
};

const DEFAULT_EVENT_HANDLER_FOR_RIGHT_PANE_VIEW = {
  [EVENT_FIELD_IDS.EVENT_TYPE]: EVENT_NAMES.ON_ROW_CLICK,
  [EVENT_FIELD_IDS.PROPERTIES]: {
    [EVENT_FIELD_IDS.SUB_EVENT_TYPE]: EVENT_ACTIONS_TYPES.PRE_DEFINED,
    [EVENT_FIELD_IDS.ACTION_TO_EXECUTE]: EVENT_ACTIONS.NAVIGATE,
  },
};

export { ACTION_TYPES, DEFAULT_EVENT_HANDLER_FOR_RIGHT_PANE_VIEW };
