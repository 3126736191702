import { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';

const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showHeader: false,
  showSearch: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const PERMISSION_USER_GROUP_STATUS = {
  ACTIVE: true,
  INACTIVE: false,
};

const PERMISSION_USER_GROUP_STATUS_LABEL_MAP = {
  [PERMISSION_USER_GROUP_STATUS.ACTIVE]: __('Active'),
  [PERMISSION_USER_GROUP_STATUS.INACTIVE]: __('Inactive'),
};

const PERMISSION_USER_GROUP_STATUS_COLOR_MAP = {
  [PERMISSION_USER_GROUP_STATUS.ACTIVE]: COLOR_MAP.GREEN,
  [PERMISSION_USER_GROUP_STATUS.INACTIVE]: COLOR_MAP.RED,
};

const USER_GROUP_FIELD_IDS = {
  DISPLAY_NAME: 'displayName',
  DELETED: 'deleted',
  ID: 'id',
  NAME: 'name',
};

export { USER_GROUP_FIELD_IDS, TABLE_MANAGER_PROPS, PERMISSION_USER_GROUP_STATUS_LABEL_MAP, PERMISSION_USER_GROUP_STATUS_COLOR_MAP };
