import { TAB_TYPE as TEKION_TAB_TYPE } from '@tekion/tekion-components/molecules/Tabs/tabs.constants';
import { ALL_WIDGET_PROPERTY_KEY } from '../../../constants/visualBuilder';

const GENERAL_PROPERTIES = {
  CUSTOM_STYLES: 'customStyles',
  EVENT_HANDLERS: 'eventHandlers',
  WIDGET_NAME: 'widgetDisplayName',
};

const PARENT_WIDGET_PROPERTIES = {
  CHILD_ENTITY_NAME: ALL_WIDGET_PROPERTY_KEY.CHILD_ENTITY_NAME,
};

const TEXT_WIDGET_PROPERTIES = {
  SIZE: ALL_WIDGET_PROPERTY_KEY.SIZE,
  LABEL: ALL_WIDGET_PROPERTY_KEY.LABEL,
  ON_CLICK_ENABLED: ALL_WIDGET_PROPERTY_KEY.ON_CLICK_ENABLED,
};

const TAB_WIDGET_PROPERTIES = {
  TAB_POSITION: ALL_WIDGET_PROPERTY_KEY.TAB_POSITION,
  TAB_SIZE: ALL_WIDGET_PROPERTY_KEY.TAB_SIZE,
  TAB_NAMES: ALL_WIDGET_PROPERTY_KEY.TAB_METADATA_LIST,
  TAB_TEMPLATE_NAME: 'TAB_TEMPLATE_NAME',
};

const BUTTON_WIDGET_PROPERTIES = {
  BUTTON_TEXT: ALL_WIDGET_PROPERTY_KEY.BUTTON_TEXT,
  BUTTON_VIEW_TYPE: ALL_WIDGET_PROPERTY_KEY.BUTTON_TYPE,
};

const SIMILAR_WIDGET_PROPERTIES = {
  ENTITY_NAME: ALL_WIDGET_PROPERTY_KEY.ENTITY_NAME,
};

const CONTAINER_PROPERTIES = {
  WIDGET_POSITION: ALL_WIDGET_PROPERTY_KEY.WIDGET_POSITION,
  SCROLL_ENABLED: ALL_WIDGET_PROPERTY_KEY.SCROLL_ENABLED,
};

const CUSTOM_ACTIONS_PROPERTIES = {
  SHOW_ACTIONS: 'showActions',
  ACTION_IDS: 'actionIds',
  ACTION_NAMES: 'actionNames',
};

const TAB_TEMPLATE_TYPE = {
  DEFAULT: TEKION_TAB_TYPE.LINE_VARIANT_GENERAL,
  CIRCULAR: 'CIRCULAR',
  CARD: TEKION_TAB_TYPE.CARD_VARIANT_GENERAL,
  CARD_GROW: TEKION_TAB_TYPE.CARD_VARIANT_GROW,
};

const TAB_TEMPLATE_TYPE_TO_DISPLAY_NAME = {
  [TAB_TEMPLATE_TYPE.DEFAULT]: __('Default'),
  [TAB_TEMPLATE_TYPE.CIRCULAR]: __('Circular'),
  [TAB_TEMPLATE_TYPE.CARD]: __('Card Default'),
  [TAB_TEMPLATE_TYPE.CARD_GROW]: __('Card Grow'),
};

const TAB_TEMPLATE_TYPE_TO_CLASSNAME = {
  [TAB_TEMPLATE_TYPE.DEFAULT]: 'defaultTabClass',
  [TAB_TEMPLATE_TYPE.CIRCULAR]: 'circularTabClass',
  [TAB_TEMPLATE_TYPE.CARD]: 'cardTabClass',
  [TAB_TEMPLATE_TYPE.CARD_GROW]: 'cardGrowTabClass',
};

export {
  GENERAL_PROPERTIES,
  TEXT_WIDGET_PROPERTIES,
  TAB_WIDGET_PROPERTIES,
  BUTTON_WIDGET_PROPERTIES,
  SIMILAR_WIDGET_PROPERTIES,
  CONTAINER_PROPERTIES,
  CUSTOM_ACTIONS_PROPERTIES,
  TAB_TEMPLATE_TYPE,
  TAB_TEMPLATE_TYPE_TO_DISPLAY_NAME,
  TAB_TEMPLATE_TYPE_TO_CLASSNAME,
  PARENT_WIDGET_PROPERTIES,
};
