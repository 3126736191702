import _get from 'lodash/get';
import _map from 'lodash/map';
import _slice from 'lodash/slice';
import _uniqBy from 'lodash/uniqBy';
import _castArray from 'lodash/castArray';
import _startCase from 'lodash/startCase';
import _camelCase from 'lodash/camelCase';
import _isEmpty from 'lodash/isEmpty';
import { defaultMemoize } from 'reselect';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS from '../constants/childAggregateSummaryForm.fieldIds';
import { FILTER_CONDITION_OPTIONS } from '../constants/childAggregateSummaryForm.general.constants';

import entityReader from '../../../../../../../../readers/entity.reader';

const pascalCase = (str) => _startCase(_camelCase(str)).replace(/ /g, '');

const getChildEntities = defaultMemoize((entityDef) => {
  const entityMetaList = entityReader.entityRelationMetadataList(entityDef, 'entityRelationMetadataList', EMPTY_ARRAY);
  const childEntitiesOptions = _uniqBy(
    _map(entityMetaList, (item) => ({
      label: _slice(pascalCase(_get(item, 'relatedEntityName')), 0, -1),
      value: _get(item, 'relatedEntityName'),
    })),
    'value',
  );
  const childRelationFieldOptionsByEntity = {};

  _map(entityMetaList, (item) => {
    const entityName = _get(item, 'relatedEntityName');

    if (!_get(childRelationFieldOptionsByEntity, entityName)) {
      childRelationFieldOptionsByEntity[entityName] = [];
    }

    childRelationFieldOptionsByEntity[entityName].push({
      label: _slice(pascalCase(_get(item, 'relatedField')), 0, -1),
      value: _get(item, 'relatedField'),
    });
  });

  return {
    childEntitiesOptions,
    childRelationFieldOptionsByEntity,
  };
});

const getChildAggregateSummaryFormValues = defaultMemoize((fieldsFormChildAggregateSummaryValues) => {
  const childEntityName = _get(fieldsFormChildAggregateSummaryValues, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_ENTITY_NAME);
  const rollUpFunction = _get(fieldsFormChildAggregateSummaryValues, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.ROLL_UP_FUNCTION);
  const childRelationshipField = _get(fieldsFormChildAggregateSummaryValues, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_RELATIONSHIP);
  const aggregateField = _get(fieldsFormChildAggregateSummaryValues, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.AGGREGATE_FIELD);
  const childFilterCondition = _get(fieldsFormChildAggregateSummaryValues, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_FILTER_CONDITION);
  const updateCondition = _get(fieldsFormChildAggregateSummaryValues, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.UPDATE_CONDITION);

  return {
    ...fieldsFormChildAggregateSummaryValues,
    ...(!_isEmpty(childEntityName) && { [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_ENTITY_NAME]: _castArray(childEntityName) }),
    ...(!_isEmpty(rollUpFunction) && {
      [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.ROLL_UP_FUNCTION]: _castArray(rollUpFunction),
    }),
    ...(!_isEmpty(childRelationshipField) && { [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_RELATIONSHIP]: _castArray(childRelationshipField) }),
    ...(!_isEmpty(aggregateField) && {
      [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.AGGREGATE_FIELD]: _castArray(aggregateField),
    }),
    [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.FILTER_CRITERIA_FOR_CHILD]: _isEmpty(childFilterCondition)
      ? FILTER_CONDITION_OPTIONS.ALL_RECORDS
      : FILTER_CONDITION_OPTIONS.ONLY_CERTAIN_RECORDS,
    [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.FILTER_CRITERIA_FOR_PARENT]: _isEmpty(updateCondition)
      ? FILTER_CONDITION_OPTIONS.ALL_RECORDS
      : FILTER_CONDITION_OPTIONS.ONLY_CERTAIN_RECORDS,
    ...(!_isEmpty(childFilterCondition) && { [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_FILTER_CONDITION]: childFilterCondition }),
    ...(!_isEmpty(updateCondition) && { [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.UPDATE_CONDITION]: updateCondition }),
  };
});

const getFieldsFormChildAggregateSummaryValues = defaultMemoize((values) => {
  const childEntityName = _get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_ENTITY_NAME);
  const rollUpFunction = _get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.ROLL_UP_FUNCTION);
  const childRelationshipField = _get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_RELATIONSHIP);
  const aggregateField = _get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.AGGREGATE_FIELD);
  const childFilterCondition = _get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_FILTER_CONDITION);
  const updateCondition = _get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.UPDATE_CONDITION);

  return {
    ...values,
    ...(!_isEmpty(childEntityName) && { [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_ENTITY_NAME]: getArraySafeValue(childEntityName) }),
    ...(!_isEmpty(rollUpFunction) && {
      [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.ROLL_UP_FUNCTION]: getArraySafeValue(rollUpFunction),
    }),
    ...(!_isEmpty(childRelationshipField) && {
      [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_RELATIONSHIP]: getArraySafeValue(childRelationshipField),
    }),
    ...(!_isEmpty(aggregateField) && {
      [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.AGGREGATE_FIELD]: getArraySafeValue(aggregateField),
    }),
    ...(!_isEmpty(childFilterCondition) && { [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_FILTER_CONDITION]: childFilterCondition }),
    ...(!_isEmpty(updateCondition) && { [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.UPDATE_CONDITION]: updateCondition }),
  };
});

export { getChildEntities, getFieldsFormChildAggregateSummaryValues, getChildAggregateSummaryFormValues };
