const MARGIN_TOP = '6.4rem';

const DRAWER_STYLE = { marginTop: MARGIN_TOP, height: `calc(100% - ${MARGIN_TOP})` };

const DRAWER_BODY_STYLE = { padding: 0, height: '100%' };

const ACTION_TYPES = {
  INIT_FORM: 'INIT_FORM',
  ON_CLOSE_SIDE_PANE: 'ON_CLOSE_SIDE_PANE',
  ON_CLICK_EDIT: 'ON_CLICK_EDIT',
  ON_CLICK_VIEW: 'ON_CLICK_VIEW',
};
export { ACTION_TYPES, DRAWER_BODY_STYLE, DRAWER_STYLE };
