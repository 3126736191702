import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _flattenDeep from 'lodash/flattenDeep';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { getCompactedFiltersPayload } from '../../../../../helpers/general.helpers';
import { COMPONENT_TYPES } from '../../../../../organisms/viewBuilder/constants/viewBuilder.constants';
import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../constants/viewBuilder.constants';

const getPayloadForViewDefinitionSearch = (entityNames, viewTypes, viewConfigTypes) => {
  const filters = [
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      values: entityNames,
      filterType: OPERATORS.IN,
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
      values: viewTypes,
      filterType: OPERATORS.IN,
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_CONFIG_TYPE,
      values: viewConfigTypes,
      filterType: OPERATORS.IN,
    },
  ];

  return { filters: getCompactedFiltersPayload(filters) };
};

const getPayloadForActions = (entityNames) => {
  const filters = [
    {
      field: 'actionLevel',
      values: ['ENTITY'],
      filterType: OPERATORS.IN,
    },
    {
      field: 'entityName',
      values: entityNames,
      filterType: OPERATORS.IN,
    },
  ];
  const sortList = [
    {
      field: 'name',
      order: 'ASC',
    },
  ];

  return { filters: getCompactedFiltersPayload(filters), sortList, rows: '5000' };
};

const getFieldNamesFromConfig = (section, fieldNames) => {
  const type = _get(section, 'type', '');
  if (type === COMPONENT_TYPES.DETAIL_VIEW_ATTRIBUTE) {
    fieldNames.push(_get(section, 'properties.fieldNames', []));
  } else {
    _forEach(_get(section, 'children'), (child) => {
      getFieldNamesFromConfig(child, fieldNames);
    });
  }
};

const getPayloadForEntityRecords = (entityRecordIds, detailViewConfig) => {
  const filters = [
    {
      field: 'id',
      values: entityRecordIds,
      filterType: OPERATORS.IN,
    },
  ];
  const fieldNames = [];
  getFieldNamesFromConfig(detailViewConfig.section, fieldNames);

  return { filters: getCompactedFiltersPayload(filters), includeFields: _flattenDeep(fieldNames) };
};

const getUserSearchPayload = (createdByUserId, modifiedByUserId) => ({
  filters: [
    {
      field: 'id',
      values: [createdByUserId, modifiedByUserId],
      filterType: OPERATORS.IN,
    },
  ],
});

export { getPayloadForViewDefinitionSearch, getPayloadForEntityRecords, getPayloadForActions, getUserSearchPayload };
