import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _get from 'lodash/get';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import { Row } from '@tekion/tekion-components/molecules/Grid';
import { IMAGE_RADIO_BUTTON_VALUES } from '../../organisms/configurator/configuratorForm.constant';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';
import { DEFAULT_WIDTH } from '../../constants/viewBuilder.constants';

import styles from './imageRenderer.module.scss';

const ImageRenderer = ({ className, componentConfig }) => {
  const { sectionId, properties = {} } = componentConfig;
  const width = _get(properties, ALL_VIEW_PROPERTY_KEYS.WIDTH, DEFAULT_WIDTH);

  const imageRadioButtonValue = _get(properties, ALL_VIEW_PROPERTY_KEYS.IMAGE_RADIO_BUTTON);

  if (imageRadioButtonValue === IMAGE_RADIO_BUTTON_VALUES.IMAGE) {
    const pathValue = _get(properties, ALL_VIEW_PROPERTY_KEYS.IMAGE_PATH);

    return (
      <Row key={sectionId} style={{ width: `${width}%` }} className={cx(styles.imageRenderer, className)}>
        <img src={pathValue} alt={pathValue} />
      </Row>
    );
  } else if (imageRadioButtonValue === IMAGE_RADIO_BUTTON_VALUES.ICON) {
    const iconNameValue = _get(properties, ALL_VIEW_PROPERTY_KEYS.IMAGE_PATH);

    return (
      <Row key={sectionId} style={{ width: `${width}%` }} className={cx(styles.imageRenderer, className)}>
        <FontIcon size={SIZES.MD_S} className={styles.icon}>
          {iconNameValue}
        </FontIcon>
      </Row>
    );
  }

  return null;
};

ImageRenderer.propTypes = {
  className: PropTypes.string,
  componentConfig: PropTypes.object.isRequired,
};

ImageRenderer.defaultProps = {
  className: '',
};

export default ImageRenderer;
