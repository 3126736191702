exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir=ltr] .customActionsVisualConfigurator_customActionsComponent__i1L3GZJQED{margin:1rem 0 0 2rem}[dir=rtl] .customActionsVisualConfigurator_customActionsComponent__i1L3GZJQED{margin:1rem 2rem 0 0}[dir] .customActionsVisualConfigurator_customActionComponentHeading__8AnLFF1ycK{margin-bottom:1.6rem}[dir] .customActionsVisualConfigurator_heading__sDEeFMgrUp{margin-bottom:1.6rem}.customActionsVisualConfigurator_addedCustomActionsContainer__5BzVPxiXv1{display:flex;flex-direction:column;color:#444f5c;font-size:1.4rem}.customActionsVisualConfigurator_addedCustomActionSection__vu7ExdJU9A{display:flex;flex-direction:column;width:100%;font-weight:600}.customActionsVisualConfigurator_configureButton__cjtcJH81Xm{display:flex;justify-content:center;height:2.2rem;font-weight:bolder;font-size:1.56rem}[dir] .customActionsVisualConfigurator_configureButton__cjtcJH81Xm{padding:.2rem .8rem 0 !important}.customActionsVisualConfigurator_actionsComponent__u63WE5SAdU{display:flex;justify-content:space-between}[dir] .customActionsVisualConfigurator_submitButton__4ak9FzS9Ua:hover{background-color:#ededed}.customActionsVisualConfigurator_customActionsModal__3mW1czNSBX{display:flex}.customActionsVisualConfigurator_showActionsCheckboxLabel__q2DQdr5UGc{align-self:center}[dir=ltr] .customActionsVisualConfigurator_showActionsCheckboxLabel__q2DQdr5UGc{margin:0 0 1rem 1rem}[dir=rtl] .customActionsVisualConfigurator_showActionsCheckboxLabel__q2DQdr5UGc{margin:0 1rem 1rem 0}", ""]);

// Exports
exports.locals = {
	"customActionsComponent": "customActionsVisualConfigurator_customActionsComponent__i1L3GZJQED",
	"customActionComponentHeading": "customActionsVisualConfigurator_customActionComponentHeading__8AnLFF1ycK",
	"heading": "customActionsVisualConfigurator_heading__sDEeFMgrUp",
	"addedCustomActionsContainer": "customActionsVisualConfigurator_addedCustomActionsContainer__5BzVPxiXv1",
	"addedCustomActionSection": "customActionsVisualConfigurator_addedCustomActionSection__vu7ExdJU9A",
	"configureButton": "customActionsVisualConfigurator_configureButton__cjtcJH81Xm",
	"actionsComponent": "customActionsVisualConfigurator_actionsComponent__u63WE5SAdU",
	"submitButton": "customActionsVisualConfigurator_submitButton__4ak9FzS9Ua",
	"customActionsModal": "customActionsVisualConfigurator_customActionsModal__3mW1czNSBX",
	"showActionsCheckboxLabel": "customActionsVisualConfigurator_showActionsCheckboxLabel__q2DQdr5UGc"
};