import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

import TASK_TYPES from '@tekion/tekion-rule-engine/src/constants/ruleEngineWorkflow.taskTypes';
import { tget } from '@tekion/tekion-base/utils/general';

const assignTrigger = (workflow, triggerId, triggersList, triggerDetails = null, eventLabel = null, triggerDataForUiMetadata = null) => {
  const { rootNodeId } = workflow;

  const trigger = _find(triggersList, { id: triggerId });
  const eventDisplayName = tget(trigger, 'displayName', eventLabel);

  if (_isEmpty(trigger)) {
    return undefined;
  }

  return {
    ...workflow,
    eventType: trigger.id,
    isModified: true,
    workflowEventDetails: triggerDetails,
    uiMetadata: {
      ...workflow.uiMetadata,
      eventLabel: eventDisplayName,
      triggerData: triggerDataForUiMetadata,
    },
    nodeList: {
      ...workflow.nodeList,
      [rootNodeId]: {
        ...workflow.nodeList[rootNodeId],
        label: eventDisplayName,
        uiMetadata: {
          ...workflow.nodeList[rootNodeId].uiMetadata,
          taskType: TASK_TYPES.EVENT,
          icon: 'icon-trigger',
          entityDefId: trigger.id,
          allowTasksBelow: false,
        },
        dealerId: null,
      },
    },
  };
};

export default assignTrigger;
