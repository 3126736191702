import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _join from 'lodash/join';
import _split from 'lodash/split';
import _has from 'lodash/has';
import _uniq from 'lodash/uniq';
import _castArray from 'lodash/castArray';
import _compact from 'lodash/compact';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

import FileReader from '@tekion/tekion-base/utils/File.reader';
import { DUPLICATE_FILE_UPLOAD_MESSAGE, MB_TO_BYTES } from './mediaUploader.constants';

const fileSize = (file) => file && FileReader.getFileSize(file);

const getSanitizedFileName = (fileName) => _join(_split(fileName, ' '), '_');

const getUpdatedMediaFiles = (selectedFiles, updatedMedia) =>
  _map(selectedFiles, (file) => {
    if (_get(file, 'name') === _get(updatedMedia, 'name')) {
      return updatedMedia;
    }
    return file;
  });

const getUpdatedMediaListWithUrl = (selectedFiles, mediaListWithUrl) => {
  const newMediaList = [];
  if (_isEmpty(selectedFiles)) {
    return mediaListWithUrl;
  } else {
    _forEach(selectedFiles, (file) => {
      const newFile = _find(mediaListWithUrl, (media) => _get(media, 'mediaId') === _get(file, 'mediaId'));
      if (_isEmpty(newFile)) {
        newMediaList.push(file);
      } else {
        newMediaList.push(newFile);
      }
    });
    return newMediaList;
  }
};

const isFileSizeAllowed = (mediaToUpload, maxFileSize, minFileSize) => {
  let errorMessage = '';

  _forEach(mediaToUpload, (media) => {
    const file = _get(media, 'file');
    if (maxFileSize && fileSize(file) > maxFileSize) {
      errorMessage = __(`Media size should be less than ${maxFileSize / MB_TO_BYTES}MB`);
    }
    if (minFileSize && fileSize(file) < minFileSize) {
      errorMessage = __(`Media size should be greater than ${minFileSize}MB`);
    }
  });

  return errorMessage;
};

const getUpdatedFileName = (file, fileNameCount) =>
  `${FileReader.getFileNameWithoutExtension(file.name)}_Copy-${fileNameCount}.${FileReader.getFileExtension(file.name)}`;

const getMediaIdsToBeResolved = (mediaList, mediaIdVsSignedUrlMapper) => {
  const mediaIds = [];
  _forEach(mediaList, (media) => {
    const mediaId = _get(media, 'mediaId');
    if (!_has(mediaIdVsSignedUrlMapper, mediaId)) mediaIds.push(mediaId);
  });

  return _uniq(mediaIds);
};

const getMediaList = (value) => _map(_compact(_castArray(value)), (item) => ({ mediaId: item }));

const getFileNames = (errorObject) => {
  let fileName = '';
  _forEach(errorObject, (item, index) => {
    if (index === _size(errorObject) - 1) {
      fileName += `${_get(item, 'fileName')}`;
    } else {
      fileName += `${_get(item, 'fileName')} ,`;
    }
  });
  return fileName;
};

const getToasterMessage = (fileNames) => __(`The file ( ${fileNames} ) ${DUPLICATE_FILE_UPLOAD_MESSAGE} `);

const getNewFileCount = (prevFileCount, mediaListWithUrl) => {
  if (_isEmpty(prevFileCount)) {
    let newFileCount;
    _forEach(mediaListWithUrl, (media) => {
      const fileName = _get(media, 'name', '') || '';
      if (_has(newFileCount, fileName)) {
        newFileCount[fileName] += 1;
      } else {
        newFileCount = { ...newFileCount, [fileName]: 1 };
      }
    });
    return newFileCount;
  } else return { ...prevFileCount };
};

export {
  getUpdatedMediaFiles,
  isFileSizeAllowed,
  getUpdatedFileName,
  getSanitizedFileName,
  getMediaIdsToBeResolved,
  getMediaList,
  getFileNames,
  getToasterMessage,
  getNewFileCount,
  getUpdatedMediaListWithUrl,
};
