import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { tget } from '@tekion/tekion-base/utils/general';

import subscriptionConfigServices from '../services/subscriptionConfig.services';

const fetchSubscriptionConfigs = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await subscriptionConfigServices.fetchSubscriptionsConfigs(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch subscription configurations, please try again later!')));
    return EMPTY_ARRAY;
  }
};

const updateSubscriptionConfigByName = async (subscriptionConfigName, payload = EMPTY_OBJECT) => {
  try {
    const response = await subscriptionConfigServices.updateSubscriptionConfigByName(subscriptionConfigName, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Subscription configurations updated successfully'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update subscription configurations, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const createSubscriptionConfig = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await subscriptionConfigServices.createSubscriptionConfig(payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Subscription configurations created successfully'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create subscription configurations, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const getSubscriptionConfigByName = async (subscriptionConfigName) => {
  try {
    const response = await subscriptionConfigServices.getSubscriptionConfigByName(subscriptionConfigName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get subscription configuration, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const fetchSubscriptionConfigLogs = async (subscriptionConfigName) => {
  try {
    const response = await subscriptionConfigServices.fetchSubscriptionConfigLogs(subscriptionConfigName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get subscription configuration logs, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const searchSubscriptionConfigLogsByTime = async (subscriptionConfigName, payload) => {
  try {
    const response = await subscriptionConfigServices.searchSubscriptionConfigLogsByTime(subscriptionConfigName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get subscription configuration logs, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const getSubscriptionConfigLogsStatus = async (subscriptionConfigName) => {
  try {
    const response = await subscriptionConfigServices.getSubscriptionConfigLogsStatus(subscriptionConfigName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get subscription configuration logs status, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const enableSubscriptionConfigLog = async (subscriptionConfigName) => {
  try {
    const response = await subscriptionConfigServices.enableSubscriptionConfigLog(subscriptionConfigName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to enable subscription configuration logs, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const disableSubscriptionConfigLog = async (subscriptionConfigName) => {
  try {
    const response = await subscriptionConfigServices.disableSubscriptionConfigLog(subscriptionConfigName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to disable subscription configuration logs, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const verifySubscriptionConfigUrl = async (payload) => {
  try {
    const response = await subscriptionConfigServices.verifySubscriptionConfigUrl(payload);
    const valid = tget(getDataFromResponse(response), 'valid');
    if (!valid) {
      toaster(TOASTER_TYPE.ERROR, __('Configuration is not correct, please fill correct information!'));
    }
    return valid;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Configuration is not correct, please fill correct information!')));
    return false;
  }
};

const activeSubscriptionConfig = async (subscriptionConfigName, payload) => {
  try {
    const response = await subscriptionConfigServices.activeSubscriptionConfig(subscriptionConfigName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to active subscription configuration, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const inactiveSubscriptionConfig = async (subscriptionConfigName, payload) => {
  try {
    const response = await subscriptionConfigServices.inactiveSubscriptionConfig(subscriptionConfigName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to inactive subscription configuration, please try again later!')));
    return EMPTY_OBJECT;
  }
};

export {
  fetchSubscriptionConfigs,
  getSubscriptionConfigByName,
  verifySubscriptionConfigUrl,
  createSubscriptionConfig,
  updateSubscriptionConfigByName,
  fetchSubscriptionConfigLogs,
  getSubscriptionConfigLogsStatus,
  disableSubscriptionConfigLog,
  enableSubscriptionConfigLog,
  searchSubscriptionConfigLogsByTime,
  activeSubscriptionConfig,
  inactiveSubscriptionConfig,
};
