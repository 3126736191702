import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _set from 'lodash/set';

// Constants
import { EMPTY_STRING, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import { TEXT_STARTS_WITH } from '../../../../../../../constants/filter.constants';
import { DEFAULT_FILTERS_TYPES, RULE_STATUS_FILTER } from '../constants/approvalProcessList.filterTypes';
import ROW_ACTIONS, { ACTIVATE_APPROVAL_PROCESS, DEACTIVATE_APPROVAL_PROCESS } from '../constants/approvalProcessList.rowActions';
import COLUMN_IDS from '../constants/approvalProcessList.columnIds';

const getRowActionClick = (onAction) => (type, payload) => onAction({ type, payload });

const getRowActions = (rowData) => {
  const inactive = _get(rowData, COLUMN_IDS.INACTIVE);
  const ACTIONS = inactive ? [...ROW_ACTIONS, ACTIVATE_APPROVAL_PROCESS] : [...ROW_ACTIONS, DEACTIVATE_APPROVAL_PROCESS];
  return ACTIONS;
};

const createTableProps = (isLoading, currentPage, pageSize, onAction) => ({
  loading: isLoading,
  currentPage: currentPage + 1,
  rowHeight: 35,
  minRows: 0,
  showPagination: true,
  pageSize,
  resultsPerPage: pageSize,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  onRowActionClick: getRowActionClick(onAction),
  getRowActions,
});

const createFilterProps = (onAction) => ({
  filterTypes: [RULE_STATUS_FILTER],
  onAction,
  defaultFilterTypes: DEFAULT_FILTERS_TYPES,
  showResultsCount: false,
});

const createFilterArrayForApiPayload = (selectedFilters) => {
  const filters = _map(
    _filter(selectedFilters, (filter) => !_isEmpty(_get(filter, 'values'))),
    (filter) => ({
      field: _get(filter, 'type', EMPTY_STRING),
      values: _get(filter, 'values', EMPTY_ARRAY),
      filterType: _get(filter, 'operator', EMPTY_STRING),
    }),
  );

  return filters;
};

const getPayload = ({ currentPage, currentPageToken, pageSize, searchText, selectedFilters }) => {
  const payload = { rows: pageSize, start: currentPage * pageSize, nextPageToken: currentPageToken };
  let filters = [];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: COLUMN_IDS.DISPLAY_NAME,
      values: [searchText],
      filterType: TEXT_STARTS_WITH,
    });
  }

  if (!_isEmpty(selectedFilters)) {
    filters = [...filters, ...createFilterArrayForApiPayload(selectedFilters)];
  }

  if (!_isEmpty(filters)) {
    _set(payload, 'filters', filters);
  }

  return payload;
};

export { createTableProps, createFilterProps, getPayload };
