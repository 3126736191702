const ACTION_TYPES = {
  ON_CONFIGURE_CLICK: 'ON_CONFIGURE_CLICK',
  ON_SAVE_MODAL: 'ON_SAVE_MODAL',
  ON_DELETE_ROW: 'ON_DELETE_ROW',
  ON_ADD_ROW: 'ON_ADD_ROW',
  ON_CANCEL_MODAL: 'ON_CANCEL_MODAL',
  ON_CHANGE_MODULE: 'ON_CHANGE_MODULE',
  ON_CHANGE: 'ON_CHANGE',
  ON_MODAL_FIELD_CHANGE: 'ON_MODAL_FIELD_CHANGE',
  SET_TAB_VALUES: 'SET_TAB_VALUES',
  ON_INIT: 'ON_INIT',
};

export default ACTION_TYPES;
