exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .sectionTitle_sectionTitle__u8ba7rjZLn{margin-top:1rem;margin-bottom:1rem}[dir] .sectionTitle_sectionTitleSize1__mYUqWLNkLb{margin-top:2rem;margin-bottom:1.6rem}[dir] .sectionTitle_sectionTitleSize2__qXsGN5upzR{margin-top:1.8rem;margin-bottom:1.4rem}[dir] .sectionTitle_sectionTitleSize3__bTLGA4aXe2{margin-top:1.6rem;margin-bottom:1.2rem}[dir] .sectionTitle_sectionTitleSize4__2YhE2TVBe1{margin-top:1.4rem;margin-bottom:1rem}[dir] .sectionTitle_sectionTitleSize5__uo8uEcc93q{margin-top:1.4rem;margin-bottom:1rem}[dir] .sectionTitle_sectionTitleSize6__61MYb2hMrm{margin-top:1.4rem;margin-bottom:1rem}", ""]);

// Exports
exports.locals = {
	"sectionTitle": "sectionTitle_sectionTitle__u8ba7rjZLn",
	"sectionTitleSize1": "sectionTitle_sectionTitleSize1__mYUqWLNkLb",
	"sectionTitleSize2": "sectionTitle_sectionTitleSize2__qXsGN5upzR",
	"sectionTitleSize3": "sectionTitle_sectionTitleSize3__bTLGA4aXe2",
	"sectionTitleSize4": "sectionTitle_sectionTitleSize4__2YhE2TVBe1",
	"sectionTitleSize5": "sectionTitle_sectionTitleSize5__uo8uEcc93q",
	"sectionTitleSize6": "sectionTitle_sectionTitleSize6__61MYb2hMrm"
};