import React from 'react';
import useEditor from '../hooks/useEditor';

function withEditor(WrappedComponent) {
  function WithEditor(props) {
    const editor = useEditor();
    return <WrappedComponent {...props} editor={editor} />;
  }

  return WithEditor;
}

export default withEditor;
