exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".importedFileCardView_container__vmtxDkXZGe{display:grid;width:98%;min-height:12rem;grid-template-columns:1.6fr 1fr 1fr 1fr}[dir] .importedFileCardView_container__vmtxDkXZGe{margin:.8rem;border:1px solid #dad3d3}.importedFileCardView_item__rPR9MUbWHC{width:100%;display:flex;flex-direction:row;flex:0 1 24%;height:20px}[dir] .importedFileCardView_item__rPR9MUbWHC{margin-bottom:1%;padding-top:.8rem}[dir=ltr] .importedFileCardView_item__rPR9MUbWHC{padding-left:.8rem}[dir=rtl] .importedFileCardView_item__rPR9MUbWHC{padding-right:.8rem}.importedFileCardView_subItem__xaCTBLVd8v{color:#a4a4a4}[dir=ltr] .importedFileCardView_subItem__xaCTBLVd8v{margin-right:.8rem}[dir=rtl] .importedFileCardView_subItem__xaCTBLVd8v{margin-left:.8rem}.importedFileCardView_subItemDescription__w46T95Pq5m{color:#000}[dir=ltr] .importedFileCardView_subItemDescription__w46T95Pq5m{margin-left:.4rem}[dir=rtl] .importedFileCardView_subItemDescription__w46T95Pq5m{margin-right:.4rem}.importedFileCardView_iconItem__bx7CVF6NYk{display:flex;flex-direction:row-reverse;flex:0 1 24%;height:20px}[dir] .importedFileCardView_iconItem__bx7CVF6NYk{margin-bottom:1%;padding-top:.8rem}[dir=ltr] .importedFileCardView_iconItem__bx7CVF6NYk{padding-right:.8rem}[dir=rtl] .importedFileCardView_iconItem__bx7CVF6NYk{padding-left:.8rem}", ""]);

// Exports
exports.locals = {
	"container": "importedFileCardView_container__vmtxDkXZGe",
	"item": "importedFileCardView_item__rPR9MUbWHC",
	"subItem": "importedFileCardView_subItem__xaCTBLVd8v",
	"subItemDescription": "importedFileCardView_subItemDescription__w46T95Pq5m",
	"iconItem": "importedFileCardView_iconItem__bx7CVF6NYk"
};