exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".menu_menuContainer__eKy1TYFjdT{width:16rem}[dir] .menu_menuContainer__eKy1TYFjdT{border-radius:.4rem}.menu_menuItem__9bDZnU7c8b{display:flex;flex-direction:row;justify-content:space-between}.menu_menuItem__9bDZnU7c8b .menu_label__ikk25UaF3h{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.menu_loader__5qWhhtZenH{display:flex;justify-content:center;align-items:center}", ""]);

// Exports
exports.locals = {
	"menuContainer": "menu_menuContainer__eKy1TYFjdT",
	"menuItem": "menu_menuItem__9bDZnU7c8b",
	"label": "menu_label__ikk25UaF3h",
	"loader": "menu_loader__5qWhhtZenH"
};