import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _reduce from 'lodash/reduce';
import _castArray from 'lodash/castArray';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FIELD_IDS } from '../constants/authProviderForm.constants';
import { FORM_MODES } from '../../../../../../../constants/general.constants';

import styles from '../authProviderForm.module.scss';

const BASIC_DETAILS_SECTION = [
  {
    header: {
      label: __('General Details'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
      },
      {
        columns: [FIELD_IDS.PROVIDER_TYPE, FIELD_IDS.URL_SUFFIX],
      },
    ],
  },
];

const getAuthDetailSection = ({ rowCheck, isAuthDetailSectionVisible, isProviderTypeChanged, formMode, renderAuthProviderDetailSectionHeader }) => {
  let section = {
    header: {
      label: renderAuthProviderDetailSectionHeader(),
      className: styles.formSectionHeader,
    },
    rows: [],
  };

  if (isAuthDetailSectionVisible || formMode === FORM_MODES.CREATE || isProviderTypeChanged) {
    section = {
      ...section,
      rows: [
        {
          columns: filterRows([FIELD_IDS.KEY, FIELD_IDS.SECRET], {}, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.TOKEN_END_POINT_URL, FIELD_IDS.AUTHORIZE_END_POINT_URL], {}, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.DEFAULT_SCOPE, FIELD_IDS.TEAM_ID, FIELD_IDS.SERVICE_ID], {}, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.KEY_ID, FIELD_IDS.KEY_MEDIA_ID, FIELD_IDS.TOKEN_ISSUER], {}, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.DEFAULT_SCOPES, FIELD_IDS.REGISTER_AS], {}, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.USER_INFO_END_POINT_URL, FIELD_IDS.EXECUTE_REGISTRATION_AS], {}, rowCheck),
        },
        {
          columns: [FIELD_IDS.CUSTOM_ERROR_URL, FIELD_IDS.CUSTOM_LOGOUT_URL],
        },
        {
          columns: [FIELD_IDS.REGISTRATION_HANDLER, FIELD_IDS.PORTAL, FIELD_IDS.ICON_URL],
        },
        {
          columns: filterRows([FIELD_IDS.SEND_ACCESS_TOKEN_IN_HEADER, FIELD_IDS.SEND_CLIENT_CREDS_IN_HEADER], {}, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE], {}, rowCheck),
        },
      ],
    };
  }

  return _castArray(section);
};

const getRowCheck = (fieldsBasedOnProviderType) =>
  _reduce(
    FIELD_IDS,
    (prevRowCheck, fieldId) => {
      if (!_includes(fieldsBasedOnProviderType, fieldId)) {
        return { ...prevRowCheck, [fieldId]: () => false };
      }
      return prevRowCheck;
    },
    {},
  );

const getAuthProviderFormSection = ({
  isAuthDetailSectionVisible,
  isProviderTypeChanged,
  formMode,
  providerType,
  fieldsBasedOnProviderType,
  renderAuthProviderDetailSectionHeader,
}) => {
  const rowCheck = getRowCheck(fieldsBasedOnProviderType);

  if (_isEmpty(providerType)) {
    return BASIC_DETAILS_SECTION;
  } else {
    return [
      ...BASIC_DETAILS_SECTION,
      ...getAuthDetailSection({ rowCheck, isAuthDetailSectionVisible, isProviderTypeChanged, formMode, renderAuthProviderDetailSectionHeader }),
    ];
  }
};

export default getAuthProviderFormSection;
