import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import _get from 'lodash/get';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import { NativeSelect } from '@tekion/tekion-components/molecules/advancedSelect';
import withAsyncSelect from '@tekion/tekion-components/molecules/advancedSelect/containers/withAsyncSelect';
import withMultiSelect from '@tekion/tekion-components/molecules/multiSelect/withMultiSelect';
import { tget } from '@tekion/tekion-base/utils/general';

import Option from './Options';
import { getHasMore, getLoadingMessage, getPayload, getRecordGroupOptions } from './recordGroupAsyncSelect.helper';
import { getAllRecordGroups } from '../../../../actions/recordGroup.actions';

const AsyncMultiSelect = compose(withMultiSelect, withAsyncSelect)(NativeSelect);
const AsyncSelect = withAsyncSelect(Select);

const RecordGroupAsyncSelect = ({ isMulti, value, entityName, onChange, ...restProps }) => {
  const [nextPageToken, setNextPageToken] = useState(EMPTY_STRING);
  const [searchText, setSearchText] = useState(EMPTY_STRING);

  const handleLoadOptions = useCallback(
    async (inputString = EMPTY_STRING) => {
      let payload;
      if (inputString !== searchText) {
        payload = getPayload(EMPTY_STRING, inputString, entityName);
        setNextPageToken(EMPTY_STRING);
        setSearchText(inputString);
      } else {
        payload = getPayload(nextPageToken, inputString, entityName);
      }
      const data = await getAllRecordGroups(payload);
      const hits = tget(data, 'hits', EMPTY_ARRAY);
      const currentNextPageToken = _get(data, 'nextPageToken');
      const newOptions = getRecordGroupOptions(hits);
      const hasMore = getHasMore(currentNextPageToken);
      setNextPageToken(currentNextPageToken);
      return { options: newOptions, hasMore };
    },
    [entityName, nextPageToken, searchText],
  );

  return isMulti ? (
    <AsyncMultiSelect
      {...restProps}
      components={{ Option }}
      refreshOptions
      onChange={onChange}
      openMenuOnFocus
      loadOptions={handleLoadOptions}
      value={value}
      loadingMessage={getLoadingMessage}
      newSelect
    />
  ) : (
    <AsyncSelect
      {...restProps}
      refreshOptions
      openMenuOnFocus
      loadOptions={handleLoadOptions}
      value={value}
      loadingMessage={__('Searching...')}
      newSelect
    />
  );
};
RecordGroupAsyncSelect.propTypes = {
  isMulti: PropTypes.bool,
  value: PropTypes.string,
  entityName: PropTypes.string,
  onChange: PropTypes.func,
};

RecordGroupAsyncSelect.defaultProps = {
  isMulti: false,
  value: EMPTY_STRING,
  entityName: EMPTY_STRING,
  onChange: _noop,
};

export default RecordGroupAsyncSelect;
