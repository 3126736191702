import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _concat from 'lodash/concat';
import _uniq from 'lodash/uniq';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { checkValueIsVariable, getVariableName } from '../../../../utils/workflowVariables.utils';

import { ASSIGNED_VARIABLE_FIELD_IDS } from '../../../../constants/workflow.assignVariableConstants';
import {
  ACTION_DEFINITION_ACTION_TYPES,
  ACTION_DEFINITION_FIELD_IDS,
  SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS,
} from '../../../../../../../../../constants/actionBuilder.constants';
import { CONDITION_BUILDER_FIELD_IDS, CONDITION_FIELD_IDS } from '../../../../../../../../../organisms/conditionBuilder';
import { ACTION_BUILDER_ACTION_TYPES } from '../../../../../../actionBuilder/actionBuilderForm/constants/actionBuilderForm.general';

const getVariableOptions = (variablesInfo, targetEntityName, scopedVariables) => {
  const variableOptions = [];
  _forEach(variablesInfo, (variableInfo, variableName) => {
    if (_includes(scopedVariables, variableName) && tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME) === targetEntityName) {
      variableOptions.push({
        label: variableName,
        value: variableName,
      });
    }
  });
  return variableOptions;
};

const getFilterConditionUsedVariables = (userData) => {
  const usedVariables = [];
  const condition = tget(userData, ACTION_DEFINITION_FIELD_IDS.CONDITION);
  const criteriaList = tget(condition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST);
  _forEach(criteriaList, (criteria) => {
    const value = getArraySafeValue(tget(criteria, CONDITION_FIELD_IDS.VALUES, []));
    if (checkValueIsVariable(value)) {
      const variableName = getVariableName(value);
      usedVariables.push(variableName);
    }
  });

  return usedVariables;
};

const getSendEmailUsedVariables = (userData) => {
  const usedVariables = [];
  const fromValue = tget(userData, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.FROM);
  const toValues = getArraySafeValue(tget(userData, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.TO, []));
  const templates = tget(userData, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES, {});
  const cc = getArraySafeValue(tget(userData, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.CC));
  const bcc = getArraySafeValue(tget(userData, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.BCC));

  if (checkValueIsVariable(fromValue)) {
    const variableName = getVariableName(fromValue);
    usedVariables.push(variableName);
  }

  if (checkValueIsVariable(toValues)) {
    const variableName = getVariableName(toValues);
    usedVariables.push(variableName);
  }

  if (checkValueIsVariable(cc)) {
    const variableName = getVariableName(cc);
    usedVariables.push(variableName);
  }

  if (checkValueIsVariable(bcc)) {
    const variableName = getVariableName(bcc);
    usedVariables.push(variableName);
  }

  _forEach(templates, (templateVariableInfo) => {
    _forEach(templateVariableInfo, (value) => {
      if (checkValueIsVariable(value)) {
        const variableName = getVariableName(value);
        usedVariables.push(variableName);
      }
    });
  });

  return usedVariables;
};

const getActionDefinitionUsedVariables = (userData) => {
  const usedVariables = [];

  _forEach(userData, (prefilledData) => {
    if (checkValueIsVariable(prefilledData)) {
      const variableName = getVariableName(prefilledData);
      usedVariables.push(variableName);
    }
  });

  return usedVariables;
};

const getUsedVariables = (formValues) => {
  let usedVariables = [];

  const actionType = tget(formValues, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE);
  const actionDefinition = tget(formValues, ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION, EMPTY_OBJECT);
  const searchRequest = tget(formValues, ACTION_DEFINITION_FIELD_IDS.SEARCH_REQUEST, EMPTY_OBJECT);
  const recordToUpdate = tget(formValues, ACTION_DEFINITION_FIELD_IDS.RECORD_TO_UPDATE, EMPTY_OBJECT);

  // Search Request
  if (!_isEmpty(searchRequest)) {
    const searchRequestUsedVariables = getFilterConditionUsedVariables(searchRequest);
    usedVariables = _concat(usedVariables, searchRequestUsedVariables);
  }

  // Record To Update
  if (!_isEmpty(recordToUpdate)) {
    const recordToUpdateUsedVariables = getFilterConditionUsedVariables(recordToUpdate);
    usedVariables = _concat(usedVariables, recordToUpdateUsedVariables);
  }

  // Action Definition
  if (!_isEmpty(actionDefinition)) {
    const actionDefinitionUsedVariables = getActionDefinitionUsedVariables(actionDefinition);

    usedVariables = _concat(usedVariables, actionDefinitionUsedVariables);
  }

  // Send Email
  if (actionType === ACTION_DEFINITION_ACTION_TYPES.SEND_EMAIL) {
    const sendEmailUsedVariables = getSendEmailUsedVariables(formValues);
    usedVariables = _concat(usedVariables, sendEmailUsedVariables);
  }

  return _uniq(usedVariables);
};

const isActionDefinitionRequired = (actionType) =>
  actionType === ACTION_BUILDER_ACTION_TYPES.CREATE_RECORD ||
  actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD ||
  actionType === ACTION_BUILDER_ACTION_TYPES.UPSERT_RECORD;

const isUpsertOrUpdateActionType = (actionType) =>
  actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD || actionType === ACTION_BUILDER_ACTION_TYPES.UPSERT_RECORD;

export { getVariableOptions, getUsedVariables, isActionDefinitionRequired, isUpsertOrUpdateActionType };
