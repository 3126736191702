import React, { useCallback, useMemo } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';

import withSize from '../../../../../connectors/withSize';

// Constants
import { FORM_MODES } from '../../../../../constants/general.constants';
import ACTION_TYPES from './constants/permissionSetForm.actionTypes';
import { PERMISSION_SET_FORM_CONTEXT_ID } from './constants/permissionSetForm.general.constants';

// Helpers
import getFields from './helpers/permissionSetForm.fields';
import PERMISSION_SET_FORM_SECTIONS from './helpers/permissionSetForm.sections';
import ACTION_HANDLERS from './helpers/permissionSetForm.actionHandlers';

const PermissionSetForm = ({ isDataLoading, isSubmitting, contentHeight, formMode, permissionData, errors, onAction }) => {
  const handleSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_SUBMIT });
  }, [onAction]);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const pageHeading = formMode === FORM_MODES.CREATE ? __('Create New Permission') : __('Edit Permission');
  const fields = useMemo(() => getFields(formMode), [formMode]);

  return (
    <Page className="full-height full-width">
      <Page.Header hasBack>
        <Heading className="full-width">{pageHeading}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight }}>
        <FormWithSubmission
          className="p-t-12"
          contextId={PERMISSION_SET_FORM_CONTEXT_ID}
          isFetching={isDataLoading}
          fields={fields}
          sections={PERMISSION_SET_FORM_SECTIONS}
          values={permissionData}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          primaryActionLoading={isSubmitting}
          primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Create') : __('Update')}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

PermissionSetForm.propTypes = {
  isDataLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  contentHeight: PropTypes.number,
  formMode: PropTypes.string,
  permissionData: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func,
};

PermissionSetForm.defaultProps = {
  isDataLoading: false,
  isSubmitting: false,
  contentHeight: 0,
  formMode: FORM_MODES.CREATE,
  permissionData: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  onAction: _noop,
};

export default compose(withSize({ hasPageFooter: 1, hasPageHeader: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(PermissionSetForm);
