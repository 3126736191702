const FIELD_IDS = {
  SORTABLE_FIELDS: 'sortableFields',
  DEFAULT_SORT_DETAILS: 'defaultSortDetails',
  FIELD: 'field',
  ORDER: 'order',
  ERROR_MESSAGE: 'errorMessage',
};

const ORDER_FIELD_OPTION_IDS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const SORT_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID = 'SORT_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID';

export { FIELD_IDS, SORT_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID, ORDER_FIELD_OPTION_IDS };
