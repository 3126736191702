import _property from 'lodash/property';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

const hits = (response) => tget(response, 'hits', EMPTY_ARRAY);
const count = _property('count');
const groups = _property('groups');
const nextPageToken = _property('nextPageToken');
const projections = _property('projections');

const SEARCH_RESPONSE_READER = {
  hits,
  count,
  groups,
  nextPageToken,
  projections,
};

export default SEARCH_RESPONSE_READER;
