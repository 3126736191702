import _castArray from 'lodash/castArray';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _omit from 'lodash/omit';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { getClassName, getInitialFunction } from './validationRuleBuilderForm.helpers';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import { FIELD_IDS } from '../../../../../../constants/validationRuleBuilder.constants';
import { CONDITION_BUILDER_TYPES } from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';

const generatePayloadFromFormValues = (formValues) => {
  const condition = tget(formValues, FIELD_IDS.CONDITION, EMPTY_OBJECT);
  const criteriaList = tget(condition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY);
  const newFormValues = {
    ..._omit(formValues, [FIELD_IDS.ENABLE_RICH_TEXT_EDITOR, FIELD_IDS.CUSTOM_CODE]),
    [FIELD_IDS.APPLICABLE_RECORD_TYPES]: _map(_get(formValues, FIELD_IDS.APPLICABLE_RECORD_TYPES, EMPTY_ARRAY), (item) => ({ recordTypeName: item })),
  };

  if (_get(formValues, FIELD_IDS.ENABLE_RICH_TEXT_EDITOR, false)) {
    return {
      ...newFormValues,
      [FIELD_IDS.CONDITION]: {
        type: CONDITION_BUILDER_TYPES.SCRIPT,
        script: getClassName(_get(formValues, FIELD_IDS.RULE_NAME)),
      },
    };
  } else {
    return {
      ...newFormValues,
      [FIELD_IDS.CONDITION]: {
        ...condition,
        [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: _map(criteriaList, (criteria) => {
          const resourceFieldValue = tget(criteria, CONDITION_FIELD_IDS.FIELD, EMPTY_ARRAY);
          const valueFieldValue = tget(criteria, CONDITION_FIELD_IDS.VALUES, EMPTY_ARRAY);
          return {
            ...criteria,
            [CONDITION_FIELD_IDS.FIELD]: getArraySafeValue(resourceFieldValue),
            [CONDITION_FIELD_IDS.VALUES]: _castArray(valueFieldValue),
          };
        }),
      },
    };
  }
};

const generateFormFieldsFromData = (data, customCodeData, metaData) => {
  const condition = tget(data, FIELD_IDS.CONDITION, EMPTY_OBJECT);
  const type = tget(condition, CONDITION_BUILDER_FIELD_IDS.TYPE, CONDITION_BUILDER_TYPES.CRITERIA);
  let newData = { ...data };
  if (type === CONDITION_BUILDER_TYPES.SCRIPT) {
    let customCodeValue = tget(customCodeData, 'rawCode', EMPTY_OBJECT);
    try {
      customCodeValue = JSON.parse(customCodeValue);
    } catch (error) {
      customCodeValue = [getInitialFunction(metaData)];
    }
    newData = {
      ...newData,
      [FIELD_IDS.APPLICABLE_RECORD_TYPES]: _map(_get(data, FIELD_IDS.APPLICABLE_RECORD_TYPES, EMPTY_ARRAY), 'recordTypeName'),
      [FIELD_IDS.ENABLE_RICH_TEXT_EDITOR]: true,
      [FIELD_IDS.CUSTOM_CODE]: customCodeValue,
    };
  } else {
    const criteriaList = tget(condition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY);
    newData = {
      ...newData,
      [FIELD_IDS.APPLICABLE_RECORD_TYPES]: _map(_get(data, FIELD_IDS.APPLICABLE_RECORD_TYPES, EMPTY_ARRAY), 'recordTypeName'),
      [FIELD_IDS.CONDITION]: {
        ...condition,
        [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: _map(criteriaList, (criteria) => {
          const resourceFieldValue = tget(criteria, CONDITION_FIELD_IDS.FIELD, EMPTY_ARRAY);
          return {
            ...criteria,
            [CONDITION_FIELD_IDS.FIELD]: _castArray(resourceFieldValue),
          };
        }),
      },
    };
  }

  return {
    ...newData,
  };
};

export { generatePayloadFromFormValues, generateFormFieldsFromData };
