import React from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ApprovalManagement, ApprovalRequestDetailsPage, ApprovalRequestForm } from '../../approvalCentre';

import PAGE_IDS from '../../../constants/pageIds.constants';

const ApprovalPagesRenderer = ({ match, ...rest }) => (
  <Switch>
    <Route
      exact
      path={`${match.url}/${PAGE_IDS.MANAGEMENT}/:approvalTabId`}
      render={() => <ApprovalManagement isMountedInsideApplication {...rest} />}
    />
    <Route
      exact
      path={`${match.url}/${PAGE_IDS.MANAGEMENT}/:approvalTabId?/:approvalId?`}
      render={() => <ApprovalRequestDetailsPage isMountedInsideApplication {...rest} />}
    />
    <Route
      exact
      path={`${match.url}/${PAGE_IDS.EDIT_REQUEST}/:approvalId`}
      render={() => <ApprovalRequestForm isMountedInsideApplication {...rest} />}
    />
  </Switch>
);

ApprovalPagesRenderer.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(ApprovalPagesRenderer);
