class ActionBuilderPropsBuilder {
  constructor() {
    this.isActionDataFetchRequired = false;
    this.actionNamesToShow = [];
    this.actionDefs = [];
  }

  setActionNamesToShow(actionNames) {
    this.actionNamesToShow = actionNames;
    this.isActionDataFetchRequired = true;
  }

  getActionNamesToShow() {
    return this.actionNamesToShow;
  }

  setActionDefs(actionDefs) {
    this.actionDefs = actionDefs;
    this.isActionDataFetchRequired = false;
  }

  getActionDefs() {
    return this.actionDefs;
  }

  setDataFetchRequired() {
    this.isActionDataFetchRequired = true;
  }

  getDataFetchRequired() {
    return this.isActionDataFetchRequired;
  }
}

export default ActionBuilderPropsBuilder;
