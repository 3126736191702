import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';

import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SearchInput from '@tekion/tekion-components/molecules/searchInput';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Loader from '@tekion/tekion-components/molecules/loader';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import TileButton from '../../../../../../atoms/tileButton/TileButton';
import ProjectSelect from '../asyncProjectSelect';

import ACTION_HANDLERS from './helpers/applicationTab.actionHandlers';

import withSize from '../../../../../../connectors/withSize';
import { ACTION_TYPES } from './constants/applicationTab.constants';

import styles from './applicationTab.module.scss';

const ApplicationTab = ({ loading, contentHeight, searchText, applicationList, selectedProject, onAction }) => {
  const handleProjectSelect = useCallback(
    (currentSelectedProject) => {
      onAction({
        type: ACTION_TYPES.ON_PROJECT_CHANGE,
        payload: { selectedProject: currentSelectedProject },
      });
    },
    [onAction],
  );

  const handleSearch = useCallback(
    (searchTerm) => {
      onAction({
        type: ACTION_TYPES.ON_SEARCH_CHANGE,
        payload: { searchTerm },
      });
    },
    [onAction],
  );

  const getAppButtonUrl = useCallback((application) => {
    const appName = _get(application, 'name');

    const url = `applications/${appName}`;

    return url;
  }, []);

  const renderApplicationList = useCallback(
    () =>
      _map(applicationList, (application, index) => {
        const displayName = _get(application, 'displayName');
        const logoUrl = _get(application, 'logoUrl');
        const url = getAppButtonUrl(application);
        return (
          <Link to={{ pathname: url, state: { application } }}>
            <TileButton key={index} displayName={displayName} application={application} logoUrl={logoUrl} />
          </Link>
        );
      }),
    [applicationList, getAppButtonUrl],
  );

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.INIT,
    });
  }, [onAction]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Page className={styles.page}>
      <Page.Body style={{ height: contentHeight }}>
        <div className={styles.subHeader}>
          <div className={styles.leftSubHeader}>
            <Heading className={styles.projectHeading}>{__('Projects')}</Heading>
            <ProjectSelect key="select-bar" placeholder={__('Select Projects')} value={selectedProject} onChange={handleProjectSelect} />
          </div>
          <SearchInput textInputClass={styles.search} inputContainerClassName={styles.searchContainer} value={searchText} onChange={handleSearch} />
        </div>
        <Heading className={styles.appHeading}>{__(`Apps (${_size(applicationList)})`)}</Heading>
        <div className={styles.tileListContainer}>{renderApplicationList()}</div>
      </Page.Body>
    </Page>
  );
};

ApplicationTab.propTypes = {
  searchText: PropTypes.string,
  applicationList: PropTypes.object,
  selectedProject: PropTypes.object,
  contentHeight: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
};

ApplicationTab.defaultProps = {
  searchText: EMPTY_STRING,
  applicationList: EMPTY_OBJECT,
  selectedProject: EMPTY_OBJECT,
  loading: false,
};

export default compose(withSize({ hasFooter: 0, hasHeader: 0 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ApplicationTab);
