import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _noop from 'lodash/noop';
import _toUpper from 'lodash/toUpper';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Button from '@tekion/tekion-components/atoms/Button';
import NullComponent from '@tekion/tekion-components/atoms/nullComponent';

import { checkIfNoTekionProvider, checkIfNoProviderOtherThanTekion } from '../../LoginPage.helpers';
import { PROVIDER_IMAGES, LOGIN_ACTION_TYPES, LOGIN_PROVIDERS, PROVIDERS_DISPLAY_NAME } from '../../LoginPage.constants';

import styles from './ssoProvidersRenderer.module.scss';

const SSOProvidersRenderer = ({ providerTypeMap, onAction }) => {
  const handleClickProvider = useCallback(
    (providerType) => () => {
      onAction({
        type: LOGIN_ACTION_TYPES.REDIRECT_TO_SSO,
        payload: {
          providerType,
        },
      });
    },
    [onAction],
  );

  const renderHeading = useCallback(() => {
    const noTekionProvider = checkIfNoTekionProvider(providerTypeMap);
    const heading = noTekionProvider ? __('Sign In with SSO') : __('Or sign In with SSO');
    const headingClassName = noTekionProvider ? styles.heading2 : styles.heading;

    return <div className={headingClassName}>{heading}</div>;
  }, [providerTypeMap]);

  const getInitialsFromName = useCallback((name) => {
    const initials = name ? name.match(/\b\w/g) || [] : [];

    return _toUpper((initials.shift() || '') + (initials.pop() || ''));
  }, []);

  const renderLogo = useCallback(
    (providerType) => {
      const logoImage = PROVIDER_IMAGES[providerType];

      if (_isEmpty(logoImage)) {
        const initials = getInitialsFromName(PROVIDERS_DISPLAY_NAME[providerType]);
        return <div className={styles.logoPlaceHolder}>{initials}</div>;
      }

      return <img src={logoImage} alt={logoImage} />;
    },
    [getInitialsFromName],
  );

  const renderProviders = useCallback(() => {
    const providers = _compact(
      _map(providerTypeMap, (provider, providerType) => {
        if (providerType === LOGIN_PROVIDERS.TEKION) {
          return NullComponent;
        }

        return (
          <Button key={providerType} onClick={handleClickProvider(providerType)} className={styles.providerButton}>
            <div className={styles.logo}>{renderLogo(providerType)}</div>
            <div className={styles.providerName}>{PROVIDERS_DISPLAY_NAME[providerType]}</div>
          </Button>
        );
      }),
    );

    return <div className={styles.providersContainer}>{providers}</div>;
  }, [handleClickProvider, renderLogo, providerTypeMap]);

  if (_isEmpty(providerTypeMap) || checkIfNoProviderOtherThanTekion(providerTypeMap)) {
    return null;
  }

  return (
    <div className={styles.container}>
      {renderHeading()}
      {renderProviders()}
    </div>
  );
};

SSOProvidersRenderer.propTypes = {
  providerTypeMap: PropTypes.object,
  onAction: PropTypes.func,
};

SSOProvidersRenderer.defaultProps = {
  providerTypeMap: EMPTY_OBJECT,
  onAction: _noop,
};

export default SSOProvidersRenderer;
