import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import AuthProviderServices from '../services/authProvider.services';

const fetchAuthProviderByName = async (name) => {
  try {
    const response = await AuthProviderServices.fetchAuthProviderByName(name);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch auth provider, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createAuthProvider = async (payload) => {
  try {
    const response = await AuthProviderServices.createAuthProvider(payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Auth Provider created successfully.'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create auth provider, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateAuthProviderByName = async (name, payload) => {
  try {
    const response = await AuthProviderServices.updateAuthProviderByName(name, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Auth Provider updated successfully.'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update auth provider, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const searchAuthProviders = async (payload) => {
  try {
    const response = await AuthProviderServices.searchAuthProviders(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to search auth provider, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const fetchAuthProviderByNameWithReveal = async (name) => {
  try {
    const response = await AuthProviderServices.fetchAuthProviderByNameWithReveal(name);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch auth provider, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export { fetchAuthProviderByName, createAuthProvider, updateAuthProviderByName, searchAuthProviders, fetchAuthProviderByNameWithReveal };
