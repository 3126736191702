import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';

const FIELD_IDS = {
  EVENT_HANDLERS: ALL_VIEW_PROPERTY_KEYS.EVENT_HANDLERS,
};

const SECTIONS = [
  {
    rows: [{ columns: [FIELD_IDS.EVENT_HANDLERS] }],
  },
];

export { FIELD_IDS, SECTIONS };
