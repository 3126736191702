import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';

import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import exportServices from '../services/export.services';

const fetchAllExportsData = async (params) => {
  try {
    const apiResponse = await exportServices.fetchAllExportsData(params);
    return getDataFromResponse(apiResponse);
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err));
    return EMPTY_OBJECT;
  }
};

const downloadData = async (exportTaskId = '') => {
  try {
    const response = await exportServices.getFileDetailsForDownload(exportTaskId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error));
    return EMPTY_OBJECT;
  }
};

const cancelExport = async (taskId = '') => {
  try {
    const response = await exportServices.cancelTaskWithSelectedId(taskId);
    toaster(TOASTER_TYPE.SUCCESS, __('Task Successfully Cancelled'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error));
    return EMPTY_OBJECT;
  }
};

const retriggerExport = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await exportServices.sendDataForExport(payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Task Successfully Retrigger'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error));
    return EMPTY_OBJECT;
  }
};

export { fetchAllExportsData, downloadData, cancelExport, retriggerExport };
