import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getSections } from './cellViewAttributePropertiesForm.sections';
import { getFields } from './cellViewAttributePropertiesForm.fields';
import {
  getCellViewAttributePropertiesFromProperties,
  getUpdatedSelectedViewComponentFromOnChangeValue,
} from './cellViewAttributePropertiesForm.helpers';

import { VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

const CellViewAttributePropertiesForm = ({ selectedViewComponent, entity, fieldDefinitionForSelectedFieldName, onAction }) => {
  const properties = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);
  const componentType = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.TYPE);
  const dataType = fieldDefinitionReader.dataType(fieldDefinitionForSelectedFieldName);
  const fieldType = fieldDefinitionReader.fieldType(fieldDefinitionForSelectedFieldName);

  const sections = useMemo(() => getSections(dataType, fieldType), [dataType, fieldType]);
  const fields = useMemo(() => getFields(entity, properties, componentType), [entity, properties, componentType]);
  const cellViewAttributeProperties = useMemo(() => getCellViewAttributePropertiesFromProperties(properties), [properties]);

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const updatedSelectedViewComponent = getUpdatedSelectedViewComponentFromOnChangeValue(selectedViewComponent, payload);
          onAction({ type, payload: updatedSelectedViewComponent });
          break;
        }

        default: {
          onAction(action);
        }
      }
    },
    [selectedViewComponent, onAction],
  );

  return <FormBuilder className="full-width" sections={sections} fields={fields} values={cellViewAttributeProperties} onAction={handleAction} />;
};

CellViewAttributePropertiesForm.propTypes = {
  selectedViewComponent: PropTypes.object,
  entity: PropTypes.object,
  fieldDefinitionForSelectedFieldName: PropTypes.object,
  onAction: PropTypes.func,
};

CellViewAttributePropertiesForm.defaultProps = {
  selectedViewComponent: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  fieldDefinitionForSelectedFieldName: EMPTY_OBJECT,
  onAction: _noop,
};

export default CellViewAttributePropertiesForm;
