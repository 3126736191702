import React from 'react';
import PropTypes from 'prop-types';
import _isArray from 'lodash/isArray';
import _map from 'lodash/map';

// Tekion components
import Button from '@tekion/tekion-components/atoms/Button';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import ACTION_TYPES from './sortConditionList.actionTypes';

// Components
import SortCondition from './sortCondition';

// Styles
import styles from './sortConditionList.module.scss';

const SortConditionList = ({ id, containerClassname, value, error, fieldOptions, onAction }) => {
  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload = EMPTY_OBJECT } = action;
    switch (type) {
      case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
        const { index, condition } = payload;
        const updatedValue = [...value];
        updatedValue[index] = condition;
        onAction({
          type,
          payload: {
            id,
            value: updatedValue,
          },
        });
        break;
      }
      case ACTION_TYPES.DELETE_ROW: {
        const { index } = payload;
        const updatedValue = [...value];
        updatedValue.splice(index, 1);
        const _payload = {
          id,
          value: updatedValue,
        };
        onAction({
          type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
          payload: _payload,
        });
        break;
      }
      default:
        onAction(action);
    }
  };

  const onAddClick = () => {
    onAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: {
        id,
        value: _isArray(value) ? [...value, EMPTY_OBJECT] : [EMPTY_OBJECT],
      },
    });
  };

  return (
    <div className={`${styles.mainContainer} ${containerClassname}`}>
      {_map(value, (condition, index) => (
        <SortCondition
          id={id}
          key={index}
          index={index}
          condition={condition}
          errors={error?.[index]}
          fieldOptions={fieldOptions}
          value={value}
          onAction={handleAction}
        />
      ))}
      <Button view={Button.VIEW.TERTIARY} onClick={onAddClick}>
        {__('+Add')}
      </Button>
    </div>
  );
};

SortConditionList.propTypes = {
  id: PropTypes.string.isRequired,
  containerClassname: PropTypes.string,
  value: PropTypes.array,
  error: PropTypes.array,
  fieldOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

SortConditionList.defaultProps = {
  containerClassname: EMPTY_STRING,
  value: EMPTY_ARRAY,
  error: EMPTY_ARRAY,
  fieldOptions: EMPTY_ARRAY,
};

export default SortConditionList;
