import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _size from 'lodash/size';

// Components
import Button from '@tekion/tekion-components/atoms/Button';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import Content from '@tekion/tekion-components/atoms/Content';

import AddOverrideFieldModal from '../addOverrideFieldModal/AddOverrideFieldModal';
import WithSize from '../../../../../../connectors/withSize';
import { getAvailableFieldDefinitions } from '../../helpers/recordTypeOverride.helpers';

import { RECORD_TYPES } from '../../../../../../constants/general.constants';
import { COLUMNS, getTableProps, TABLE_MANAGER_PROPS } from './constants/feildListTable.data';
import { ACTION_TYPES } from '../../constants/recordTypeOverride.constants';

// Styles
import styles from '../../recordTypeOverride.module.scss';

const OverrideFieldListTable = (props) => {
  const {
    isModalVisible,
    contentHeight,
    overrideFieldTableData,
    overrideFieldModalData,
    entity,
    fieldOverrideFormValues,
    recordTypeDetails,
    errors,
    selectedFields,
    derivationFields,
    onAction,
  } = props;

  const handleRowAction = useCallback(
    (actionType, value) => {
      onAction({
        type: actionType,
        payload: {
          value,
        },
      });
    },
    [onAction],
  );

  const recordTypeSpecificStyle = useMemo(() => {
    if (_get(entity, 'recordTypeDefinition.type') === RECORD_TYPES.STANDARD) {
      return 150;
    }
    return 220;
  }, [entity]);

  const tableProps = useMemo(() => getTableProps(handleRowAction), [handleRowAction]);

  const handleClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.MODAL_OPEN,
    });
  }, [onAction]);

  const renderWithoutTabInfo = useCallback(
    () => (
      <div className={styles.contentContainer} style={{ height: contentHeight - recordTypeSpecificStyle - 100 }}>
        <Content colorVariant={Content.COLOR_VARIANTS.GREY} className={styles.contentText}>
          {__('No Override were added')}
        </Content>
        <Button onClick={handleClick} view={Button.VIEW.PRIMARY}>
          {__('Add')}
        </Button>
      </div>
    ),
    [contentHeight, handleClick, recordTypeSpecificStyle],
  );

  return (
    <div className={styles.overrideContainer} style={{ height: contentHeight - recordTypeSpecificStyle }}>
      <div className={styles.buttonContainer}>
        <div className={styles.overrideFieldsHeading}>{__(`Override Fields ( ${_size(overrideFieldModalData)} )`)}</div>
        <Button onClick={handleClick} view={Button.VIEW.PRIMARY}>
          {__('Add ')}
        </Button>
      </div>

      <PropertyControlledComponent controllerProperty={isModalVisible}>
        <AddOverrideFieldModal
          isModalVisible={isModalVisible}
          availableFields={getAvailableFieldDefinitions(entity, recordTypeDetails, selectedFields, derivationFields)}
          errors={errors}
          fieldOverrideFormValues={fieldOverrideFormValues}
          onAction={onAction}
        />
      </PropertyControlledComponent>

      <PropertyControlledComponent controllerProperty={_size(overrideFieldModalData)} fallback={renderWithoutTabInfo()}>
        <TableManager
          isLoaded
          loading
          disableHeight
          columns={COLUMNS}
          data={overrideFieldTableData}
          tableProps={tableProps}
          tableManagerProps={TABLE_MANAGER_PROPS}
          onAction={onAction}
        />
      </PropertyControlledComponent>
    </div>
  );
};

OverrideFieldListTable.propTypes = {
  contentHeight: PropTypes.number.isRequired,
  isModalVisible: PropTypes.bool,
  entity: PropTypes.object,
  errors: PropTypes.object,
  fieldOverrideFormValues: PropTypes.object,
  recordTypeDetails: PropTypes.object,
  overrideFieldTableData: PropTypes.array,
  overrideFieldModalData: PropTypes.array,
  derivationFields: PropTypes.array,
  selectedFields: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

OverrideFieldListTable.defaultProps = {
  isModalVisible: false,
  entity: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  fieldOverrideFormValues: EMPTY_OBJECT,
  recordTypeDetails: EMPTY_OBJECT,
  overrideFieldTableData: EMPTY_ARRAY,
  overrideFieldModalData: EMPTY_ARRAY,
  selectedFields: EMPTY_ARRAY,
  derivationFields: EMPTY_ARRAY,
};

export default React.memo(WithSize({ hasPageHeader: true, hasPageFooter: true })(OverrideFieldListTable));
