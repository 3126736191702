import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import CheckBoxField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import AsyncPaginatedCreatableSelect from '../../../../../../../molecules/asyncPaginatedCreatableSelect/AsyncPaginatedCreatableSelect';

import { searchExternalCredentials } from '../../../../../../../actions/externalCredential.actions';
import { getExternalCredentialOptions, getPayloadForExternalCredential } from './serverCredentialForm.helpers';

import { FIELD_IDS } from '../constants/serverCredentialForm.constants';
import { FORM_MODES } from '../../../../../../../constants/general.constants';

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Name'),
    validators: [isRequiredRule],
  },
};

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Display Name'),
    validators: [isRequiredRule],
  },
};

const URL_FIELD = {
  id: FIELD_IDS.URL,
  renderer: TextInput,
  renderOptions: {
    label: __('URL'),
  },
};

const EXTERNAL_CREDENTIAL_NAME_FIELD = {
  id: FIELD_IDS.EXTERNAL_CREDENTIAL_NAME,
  renderer: AsyncPaginatedCreatableSelect,
  renderOptions: {
    isMulti: false,
    label: __('External Credential ID'),
    id: FIELD_IDS.EXTERNAL_CREDENTIAL_NAME,
    createOptionPosition: 'first',
    getFormattedCreateLabel: () => __('Create External Credential'),
    checkIsValidNewOption: () => true,
    serviceHandler: searchExternalCredentials,
    getOptions: getExternalCredentialOptions,
    getPayload: getPayloadForExternalCredential,
  },
};

const CLIENT_CERTIFICATE_FIELD = {
  id: FIELD_IDS.CLIENT_CERTIFICATE,
  renderer: TextInput,
  renderOptions: {
    label: __('Client Certificate ID'),
  },
};

const GENERATE_AUTH_HEADERS_FIELD = {
  id: FIELD_IDS.GENERATE_AUTH_HEADERS,
  renderer: CheckBoxField,
  renderOptions: {
    checkboxLabel: __('Generate Auth Headers'),
    defaultValue: false,
  },
};

const ALLOW_FORMULA_IN_HEADERS_FIELD = {
  id: FIELD_IDS.ALLOW_FORMULA_IN_HEADERS,
  renderer: CheckBoxField,
  renderOptions: {
    checkboxLabel: __('Allow Formula in headers'),
    defaultValue: false,
  },
};

const ALLOW_FORMULA_IN_BODY_FIELD = {
  id: FIELD_IDS.ALLOW_FORMULA_IN_BODY,
  renderer: CheckBoxField,
  renderOptions: {
    checkboxLabel: __('Allow Formula in body'),
    defaultValue: false,
  },
};

const OPEN_API_SPECIFICATION_ID_FIELD = {
  id: FIELD_IDS.OPEN_API_SPECIFICATION_ID,
  renderer: TextInput,
  renderOptions: {
    label: __('Open Api Specification Id'),
  },
};

const SERVER_CREDENTIAL_FORM_FIELDS = {
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.URL]: URL_FIELD,
  [FIELD_IDS.EXTERNAL_CREDENTIAL_NAME]: EXTERNAL_CREDENTIAL_NAME_FIELD,
  [FIELD_IDS.CLIENT_CERTIFICATE]: CLIENT_CERTIFICATE_FIELD,
  [FIELD_IDS.GENERATE_AUTH_HEADERS]: GENERATE_AUTH_HEADERS_FIELD,
  [FIELD_IDS.ALLOW_FORMULA_IN_HEADERS]: ALLOW_FORMULA_IN_HEADERS_FIELD,
  [FIELD_IDS.ALLOW_FORMULA_IN_BODY]: ALLOW_FORMULA_IN_BODY_FIELD,
  [FIELD_IDS.OPEN_API_SPECIFICATION_ID]: OPEN_API_SPECIFICATION_ID_FIELD,
};

const getServerCredentialFormFields = (formMode) => ({
  ...SERVER_CREDENTIAL_FORM_FIELDS,
  [FIELD_IDS.NAME]: addToRenderOptions(SERVER_CREDENTIAL_FORM_FIELDS[FIELD_IDS.NAME], [{ path: 'disabled', value: formMode === FORM_MODES.EDIT }]),
});

export default getServerCredentialFormFields;
