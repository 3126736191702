import _isEmpty from 'lodash/isEmpty';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FIELD_IDS } from './createEntityView.constants';
import { VIEW_TYPES } from '../../../../../../constants/viewBuilder.constants';

import styles from '../createEntityView.module.scss';

const rowCheck = {
  [FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST]: ({ viewType }) =>
    !_isEmpty(viewType) && viewType !== VIEW_TYPES.LIST_VIEW && viewType !== VIEW_TYPES.GRID_VIEW,
  [FIELD_IDS.LIST_VIEW_NAME]: ({ viewType }) => viewType === VIEW_TYPES.LIST_VIEW || viewType === VIEW_TYPES.GRID_VIEW,
};

const getFormSections = (viewType) => [
  {
    className: styles.formSection,
    header: {
      label: __('View Details'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
        className: styles.halfColumn,
      },
      {
        columns: [FIELD_IDS.VIEW_TYPE],
        className: styles.halfColumn,
      },
      {
        columns: filterRows([FIELD_IDS.LIST_VIEW_NAME], { viewType }, rowCheck),
        className: styles.halfColumn,
      },
    ],
  },
  {
    className: styles.formSection,
    rows: [
      {
        columns: filterRows([FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST], { viewType }, rowCheck),
      },
    ],
  },
];

export { getFormSections };
