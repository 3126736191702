import _get from 'lodash/get';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FIELD_IDS } from './imagePropertiesForm.constants';
import { IMAGE_RADIO_BUTTON_VALUES } from '../../organisms/propertiesForm/propertiesForm.constants';

import styles from './imagePropertiesForm.module.scss';

const rowCheck = {
  [FIELD_IDS.IMAGE_PATH]: ({ values }) => _get(values, FIELD_IDS.IMAGE_RADIO_BUTTON) === IMAGE_RADIO_BUTTON_VALUES.IMAGE,
  [FIELD_IDS.GRID]: ({ values }) => _get(values, FIELD_IDS.IMAGE_RADIO_BUTTON) === IMAGE_RADIO_BUTTON_VALUES.ICON,
};

const getSections = (values) => [
  {
    rows: [
      {
        columns: [FIELD_IDS.IMAGE_RADIO_BUTTON],
      },
      {
        columns: filterRows([FIELD_IDS.IMAGE_PATH, FIELD_IDS.GRID], { values }, rowCheck),
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.CUSTOM_STYLES],
      },
    ],
  },
];

export { getSections };
