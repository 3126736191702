import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Constants
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import { activateProcess, deactivateProcess, fetchProcesses } from '../../../../../../../actions/processBuilder.actions';
import { activateRule, deactivateRule, fetchRules } from '../../../../../../../actions/ruleBuilder.actions';

import ACTION_TYPES from './constants/workflowList.actionTypes';
import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import PAGE_IDS from '../../../../../constants/PageIds.constants';
import { WORKFLOW_BUILDER_MODE } from '../../../../../../../constants/general.constants';
import { WORKFLOW_LIST_COLUMN_IDS, RULE_LIST_COLUMN_IDS } from './constants/workflowList.columnIds';

const fetchData = async ({ getState, setState }) => {
  const { workflowMode = WORKFLOW_BUILDER_MODE.PROCESS } = getState();

  setState({ isLoading: true });
  let data = [];

  if (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS) {
    data = await fetchProcesses();
  } else {
    data = await fetchRules();
  }

  setState({ data, isLoading: false });
};

const handleCreateWorkflow = ({ getState }) => {
  const { workflowMode = WORKFLOW_BUILDER_MODE.PROCESS, history } = getState();

  let pathname = '';

  if (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS) {
    pathname = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${PAGE_IDS.PROCESS_CREATE}/initiate`;
  } else {
    pathname = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${PAGE_IDS.RULE_CREATE}/initiate`;
  }

  history.push(pathname);
};

const handleEditWorkflow = ({ params = EMPTY_OBJECT, getState }) => {
  const { id } = params;
  const { history, workflowMode } = getState();

  if (!_isEmpty(id)) {
    let pathname = '';
    if (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS) {
      pathname = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${PAGE_IDS.PROCESS_EDIT}/${id}`;
    } else {
      pathname = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${PAGE_IDS.RULE_EDIT}/${id}`;
    }
    history.push(pathname);
  }
};

const handleWorkflowClick = ({ getState, params = EMPTY_OBJECT }) => {
  const { history, workflowMode } = getState();
  const id = _get(params, 'value.original.id');

  if (!_isEmpty(id)) {
    let pathname = '';
    if (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS) {
      pathname = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${PAGE_IDS.PROCESS_EDIT}/${id}`;
    } else {
      pathname = `${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${PAGE_IDS.RULE_EDIT}/${id}`;
    }

    history.push(pathname);
  }
};

const handleActivateWorkflow = ({ setState, params = EMPTY_OBJECT, getState }) => {
  const { workflowMode } = getState();
  const workflowName =
    workflowMode === WORKFLOW_BUILDER_MODE.PROCESS
      ? tget(params, WORKFLOW_LIST_COLUMN_IDS.WORKFLOW_NAME, EMPTY_STRING)
      : tget(params, RULE_LIST_COLUMN_IDS.WORKFLOW_NAME, EMPTY_STRING);

  const modalTitle =
    workflowMode === WORKFLOW_BUILDER_MODE.PROCESS
      ? __('Activate process - {{workflowName}}', { workflowName })
      : __('Activate rule - {{workflowName}}', { workflowName });

  const modalContent =
    workflowMode === WORKFLOW_BUILDER_MODE.PROCESS
      ? __('Are you sure you want to activate this process.')
      : __('Are you sure you want to activate this rule.');

  setState({
    isConfirmDialogVisible: true,
    actionInProgress: ACTION_TYPES.ACTIVE_WORKFLOW,
    modalTitle,
    modalContent,
    workflowUnderAction: params,
  });
};

const handleInactivateWorkflow = ({ setState, params = EMPTY_OBJECT, getState }) => {
  const { workflowMode } = getState();
  const workflowName =
    workflowMode === WORKFLOW_BUILDER_MODE.PROCESS
      ? tget(params, WORKFLOW_LIST_COLUMN_IDS.WORKFLOW_NAME, EMPTY_STRING)
      : tget(params, RULE_LIST_COLUMN_IDS.WORKFLOW_NAME, EMPTY_STRING);

  const modalTitle =
    workflowMode === WORKFLOW_BUILDER_MODE.PROCESS
      ? __('Deactivate process - {{workflowName}}', { workflowName })
      : __('Deactivate rule - {{workflowName}}', { workflowName });

  const modalContent =
    workflowMode === WORKFLOW_BUILDER_MODE.PROCESS
      ? __('Are you sure you want to deactivate this process.')
      : __('Are you sure you want to deactivate this rule.');

  setState({
    isConfirmDialogVisible: true,
    actionInProgress: ACTION_TYPES.INACTIVE_WORKFLOW,
    modalTitle,
    modalContent,
    workflowUnderAction: params,
  });
};

const handleCancelConfirmationModal = ({ setState }) => {
  setState({ isConfirmDialogVisible: false, actionInProgress: '', workflowUnderAction: {}, modalContent: '', modalTitle: '' });
};

const handleSubmitConfirmationModal = async ({ getState, setState, params }) => {
  const { workflowUnderAction, actionInProgress } = getState();
  const id = _get(workflowUnderAction, 'id', '');
  const { workflowMode } = getState();

  setState({
    isConfirmDialogVisible: false,
    isLoading: true,
    actionInProgress: '',
    modalContent: '',
    modalTitle: '',
    workflowUnderAction: {},
  });

  switch (actionInProgress) {
    case ACTION_TYPES.ACTIVE_WORKFLOW: {
      setState({ isLoading: true });
      let response = {};
      if (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS) {
        response = await activateProcess(id, { ...params, active: true });
      } else if (workflowMode === WORKFLOW_BUILDER_MODE.RULE) {
        response = await activateRule(id, { ...params, active: true });
      }
      setState({ isLoading: false });
      if (!_isEmpty(response)) {
        fetchData({ getState, setState });
      }

      break;
    }
    case ACTION_TYPES.INACTIVE_WORKFLOW: {
      let response = {};
      if (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS) {
        response = await deactivateProcess(id, { ...params, active: false });
      } else if (workflowMode === WORKFLOW_BUILDER_MODE.RULE) {
        response = await deactivateRule(id, { ...params, active: false });
      }
      setState({ isLoading: false });
      if (!_isEmpty(response)) {
        fetchData({ getState, setState });
      }
      break;
    }
    default:
  }
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: fetchData,
  [ACTION_TYPES.CREATE_WORKFLOW]: handleCreateWorkflow,
  [ACTION_TYPES.EDIT_WORKFLOW]: handleEditWorkflow,
  [ACTION_TYPES.ACTIVE_WORKFLOW]: handleActivateWorkflow,
  [ACTION_TYPES.INACTIVE_WORKFLOW]: handleInactivateWorkflow,
  [ACTION_TYPES.ON_CANCEL_CONFIRMATION_MODAL]: handleCancelConfirmationModal,
  [ACTION_TYPES.ON_SUBMIT_CONFIRMATION_MODAL]: handleSubmitConfirmationModal,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleWorkflowClick,
};

export default ACTION_HANDLERS;
