import { useCallback, useState } from 'react';

function useDropdownVisibility() {
  const [showDropdown, setShowDropdown] = useState(false);

  const onCloseDropdown = useCallback(() => setShowDropdown(false), []);

  return {
    onCloseDropdown,
    showDropdown,
    setShowDropdown,
  };
}

export default useDropdownVisibility;
