import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _without from 'lodash/without';

import MultiSelect from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/MultiSelectField';
import Tag from '@tekion/tekion-components/atoms/Tag';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getUpdatedOptionsWithSortOverrideIndex, getValueToLabelMap } from './multiSelectInputWithTags.helper';

const MultiSelectInputWithTags = ({ areTagsEnabled, id, values, options, onAction, ...rest }) => {
  const valueToLabelMap = useMemo(() => getValueToLabelMap(options), [options]);

  const handleRemoveTag = useCallback(
    (value) => () => {
      onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id, value: _without(values, value) } });
    },
    [id, values, onAction],
  );

  const renderTags = useCallback(
    () =>
      _map(values, (value) => (
        <Tag {...rest} deletable key={value} onDelete={handleRemoveTag(value)}>
          {__(valueToLabelMap[value])}
        </Tag>
      )),
    [rest, valueToLabelMap, values, handleRemoveTag],
  );

  const optionsWithSortOverrideIndex = useMemo(() => getUpdatedOptionsWithSortOverrideIndex(options, values), [options, values]);

  return (
    <>
      <MultiSelect
        showSelectedValueLabels={false}
        id={id}
        value={values}
        shouldSortOptions
        options={optionsWithSortOverrideIndex}
        onAction={onAction}
        {...rest}
      />
      <PropertyControlledComponent controllerProperty={areTagsEnabled}>{renderTags()}</PropertyControlledComponent>
    </>
  );
};

MultiSelectInputWithTags.propTypes = {
  areTagsEnabled: PropTypes.bool,
  id: PropTypes.string,
  values: PropTypes.array,
  options: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

MultiSelectInputWithTags.defaultProps = {
  areTagsEnabled: true,
  id: undefined,
  options: EMPTY_ARRAY,
  values: EMPTY_ARRAY,
};

export default MultiSelectInputWithTags;
