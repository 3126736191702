import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import Modal from '@tekion/tekion-components/molecules/Modal';

import { SECTION, getFields } from './helpers/recordSharingRuleAssignModal.formConfig';
import { RECORD_RULE_SHARING_FORM_CONTEXT_ID } from './constants/recordSharingRuleAssignModal.general';
import ACTION_TYPES from '../../constants/recordSharingRuleList.actionTypes';

const RecordSharingRuleAssignModal = ({
  isAssignRecordSharingRuleModalVisible,
  isAssignModalSubmitting,
  userSearchText,
  userGroupSearchText,
  entityName,
  modalTitle,
  errors,
  recordSharingRuleFormValues,
  assignedUserList,
  assignedUserGroupList,
  filteredAssignedUserList,
  filteredAssignedUserGroupList,
  onAction,
}) => {
  const handleOnCancelRequest = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_ASSIGN_MODAL_CANCEL_REQUEST });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(RECORD_RULE_SHARING_FORM_CONTEXT_ID);
  }, []);

  const fields = useMemo(
    () =>
      getFields(
        userSearchText,
        userGroupSearchText,
        assignedUserList,
        assignedUserGroupList,
        filteredAssignedUserList,
        filteredAssignedUserGroupList,
        entityName,
      ),
    [
      userSearchText,
      userGroupSearchText,
      assignedUserList,
      assignedUserGroupList,
      filteredAssignedUserList,
      filteredAssignedUserGroupList,
      entityName,
    ],
  );

  return (
    <Modal
      visible={isAssignRecordSharingRuleModalVisible}
      loading={isAssignModalSubmitting}
      width={Modal.SIZES.L}
      title={modalTitle}
      submitBtnText={__('Done')}
      onCancel={handleOnCancelRequest}
      onSubmit={handleSubmit}
    >
      <FormWithSubmission
        contextId={RECORD_RULE_SHARING_FORM_CONTEXT_ID}
        sections={SECTION}
        fields={fields}
        values={recordSharingRuleFormValues}
        errors={errors}
        onAction={onAction}
      />
    </Modal>
  );
};

RecordSharingRuleAssignModal.propTypes = {
  isAssignRecordSharingRuleModalVisible: PropTypes.bool,
  isAssignModalSubmitting: PropTypes.bool,
  userSearchText: PropTypes.string,
  userGroupSearchText: PropTypes.string,
  entityName: PropTypes.string,
  modalTitle: PropTypes.string,
  errors: PropTypes.object,
  recordSharingRuleFormValues: PropTypes.object,
  assignedUserList: PropTypes.array,
  assignedUserGroupList: PropTypes.array,
  filteredAssignedUserList: PropTypes.array,
  filteredAssignedUserGroupList: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

RecordSharingRuleAssignModal.defaultProps = {
  isAssignRecordSharingRuleModalVisible: false,
  isAssignModalSubmitting: false,
  userSearchText: EMPTY_STRING,
  userGroupSearchText: EMPTY_STRING,
  entityName: EMPTY_STRING,
  modalTitle: EMPTY_STRING,
  errors: EMPTY_OBJECT,
  recordSharingRuleFormValues: EMPTY_OBJECT,
  assignedUserList: EMPTY_ARRAY,
  assignedUserGroupList: EMPTY_ARRAY,
  filteredAssignedUserList: EMPTY_ARRAY,
  filteredAssignedUserGroupList: EMPTY_ARRAY,
};

export default React.memo(RecordSharingRuleAssignModal);
