import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components/molecules/loader';

import ApplicationRendererContext from '../../../../context/applicationRenderer.context';
import GeneralEventEmitter from '../../../../eventEmitters/generalEventEmitter';
import VisualBuilderGridLayout from '../visualBuilderGridLayout/VisualBuilderGridLayout';
import ACTION_HANDLERS from './pageViewer.actionHandlers';
import { ACTION_TYPES, INITIAL_STATE } from './pageViewer.constants';
import { GENERAL_EVENT_NAMES, ADDITIONAL_PAYLOAD_IDS_BY_GENERAL_EVENT_NAME } from '../../../../eventEmitters/constants/generalEventEmitter.constants';
import { ACTION_DEFINITION_ACTION_TYPES } from '../../../../constants/actionBuilder.constants';
import entityReader from '../../../../readers/entity.reader';

import styles from './pageViewer.module.scss';

const PageViewer = ({
  isPreviewMode,
  isLoading,
  contentWidth,
  contentHeight,
  pageEntity,
  pageEntityRecord,
  pageConfiguration,
  applicationContext,
  previewLayoutsById,
  history,
  visualBuilderOnAction,
  onAction,
  ...restProps
}) => {
  const searchParams = useMemo(() => history.location.search, [history.location.search]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.INIT,
      payload: { pageConfiguration },
    });
  }, [onAction, pageConfiguration, searchParams]);

  useEffect(() => {
    const ACTION_BUILDER_EVENT_FUNCTIONS = {
      UPDATE_RECORD: (payload) => {
        const ADDITIONAL_PAYLOAD_IDS_FOR_ACTION_EVENT = ADDITIONAL_PAYLOAD_IDS_BY_GENERAL_EVENT_NAME[GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT];

        const entityNameFromEvent = tget(payload, ADDITIONAL_PAYLOAD_IDS_FOR_ACTION_EVENT.ENTITY_NAME);
        const entityRecordId = tget(payload, ADDITIONAL_PAYLOAD_IDS_FOR_ACTION_EVENT.ENTITY_RECORD_ID);

        const pageEntityName = entityReader.name(pageEntity);
        const pageEntityRecordId = tget(pageEntityRecord, 'id');

        // Currently we assume that when update page entity record event comes we want to refresh
        if (pageEntityName === entityNameFromEvent && entityRecordId === pageEntityRecordId) {
          onAction({ type: ACTION_TYPES.INIT, payload: { pageConfiguration } });
        }
      },
    };

    // Registering General events

    // Registering Action Builder Events
    GeneralEventEmitter.on(
      `${GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT}:${ACTION_DEFINITION_ACTION_TYPES.UPDATE_RECORD}`,
      ACTION_BUILDER_EVENT_FUNCTIONS.UPDATE_RECORD,
    );

    return () => {
      GeneralEventEmitter.removeListener(
        `${GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT}:${ACTION_DEFINITION_ACTION_TYPES.UPDATE_RECORD}`,
        ACTION_BUILDER_EVENT_FUNCTIONS.UPDATE_RECORD,
      );
    };
  }, [pageEntity, pageEntityRecord, pageConfiguration, onAction]);

  // Avoiding mounting of children to reduce unnecessary re-rending and API calls
  if (isLoading) {
    return <Loader />;
  }

  return (
    <ApplicationRendererContext.Provider value={applicationContext}>
      <div className={styles.pageViewer}>
        <div style={{ height: contentHeight }}>
          <VisualBuilderGridLayout
            isPreviewMode={isPreviewMode}
            width={contentWidth}
            pageConfiguration={pageConfiguration}
            pageEntityRecord={pageEntityRecord}
            pageEntity={pageEntity}
            previewLayoutsById={previewLayoutsById}
            visualBuilderOnAction={visualBuilderOnAction}
            pageViewerOnAction={onAction}
            {...restProps}
          />
        </div>
      </div>
    </ApplicationRendererContext.Provider>
  );
};

PageViewer.propTypes = {
  isLoading: PropTypes.bool,
  isPreviewMode: PropTypes.bool,
  selectedViewComponentId: PropTypes.bool,
  contentWidth: PropTypes.number,
  contentHeight: PropTypes.number,
  pageConfiguration: PropTypes.object,
  pageEntity: PropTypes.object,
  entityDef: PropTypes.object,
  entityRecord: PropTypes.object,
  applicationContext: PropTypes.object,
  pageEntityRecord: PropTypes.object,
  previewLayoutsById: PropTypes.object,
  history: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
  visualBuilderOnAction: PropTypes.func,
};

PageViewer.defaultProps = {
  isLoading: false,
  isPreviewMode: true,
  selectedViewComponentId: undefined,
  contentWidth: 0,
  contentHeight: 0,
  pageConfiguration: undefined,
  pageEntityRecord: undefined,
  pageEntity: undefined,
  applicationContext: EMPTY_OBJECT,
  entityDef: EMPTY_OBJECT,
  entityRecord: EMPTY_OBJECT,
  previewLayoutsById: EMPTY_OBJECT,
  visualBuilderOnAction: _noop,
};

export default withActions(INITIAL_STATE, ACTION_HANDLERS)(PageViewer);
