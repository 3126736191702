import { defaultMemoize } from 'reselect';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';
import _size from 'lodash/size';
import _parseInt from 'lodash/parseInt';
import _forEach from 'lodash/forEach';
import _set from 'lodash/set';
import _split from 'lodash/split';

import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { TABLE_MANAGER_DEFAULT_PROPS } from '@tekion/tekion-components/organisms/TableManager';
import { DATE_TIME_FORMATS, getFormattedDateTime } from '@tekion/tekion-base/utils/dateUtils';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import DATA_TYPES from '../../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../../constants/fieldDefinition.fieldTypes';
import { COLUMN_TYPES } from '../../../constants/reporting.general.constants';
import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';

const getColumnAccessor = (columnName) => (rowData) => _get(rowData, columnName);

const getTransformedColumns = (columns) =>
  _map(columns, (col) => {
    const columnName = _get(col, 'name');
    const columnDisplayName = _get(col, 'displayName');

    return {
      ...col,
      Header: columnDisplayName,
      accessor: getColumnAccessor(columnName),
      key: columnName,
      id: columnName,
      Cell: TextRenderer,
    };
  });

const getTime = (time) => getFormattedDateTime(time, DATE_TIME_FORMATS.DATE_MONTH_YEAR);

const getSelectedFieldDef = (splitResourceValue, index, fieldDefs, conditionBuilderFieldDefinitionObject) => {
  if (index >= _size(splitResourceValue)) {
    return {};
  }

  let selectedFieldDef = _get(fieldDefs, splitResourceValue[index], EMPTY_OBJECT);
  const dataType = fieldDefinitionReader.dataType(selectedFieldDef);
  const fieldType = fieldDefinitionReader.fieldType(selectedFieldDef);
  if (dataType === DATA_TYPES.COMPLEX && index + 1 !== _size(splitResourceValue)) {
    const complexEntityName = fieldDefinitionReader.complexFieldDefinitionEntityName(selectedFieldDef);
    const complexEntityFieldDefs = _get(conditionBuilderFieldDefinitionObject, complexEntityName, EMPTY_OBJECT);
    selectedFieldDef = getSelectedFieldDef(splitResourceValue, index + 1, complexEntityFieldDefs, conditionBuilderFieldDefinitionObject);
  } else if (fieldType === FIELD_TYPES.RELATIONSHIP && index + 1 !== _size(splitResourceValue)) {
    const relationshipEntityName = fieldDefinitionReader.lookupFieldEntityType(selectedFieldDef);
    const relationshipEntityFieldDefs = _get(conditionBuilderFieldDefinitionObject, relationshipEntityName, EMPTY_OBJECT);
    selectedFieldDef = getSelectedFieldDef(splitResourceValue, index + 1, relationshipEntityFieldDefs, conditionBuilderFieldDefinitionObject);
  }

  return selectedFieldDef;
};

const getFieldDef = (value, mapOfVariableToEntityName = EMPTY_OBJECT, conditionBuilderFieldDefinitionObject = EMPTY_OBJECT) => {
  const splitValue = _split(value, '.');
  const variableName = '$record';
  const entityName = mapOfVariableToEntityName[variableName];
  const fieldDefs = _get(conditionBuilderFieldDefinitionObject, entityName, EMPTY_OBJECT);

  const selectedFieldDef = getSelectedFieldDef(splitValue, 0, fieldDefs, conditionBuilderFieldDefinitionObject);
  return selectedFieldDef;
};

const getTransformedData = (rows, columns, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject) =>
  _map(rows, (row) =>
    _reduce(
      columns,
      (acc, value, idx) => {
        let data = _get(row, `data.${idx}`);
        if (_get(value, 'type') === COLUMN_TYPES.DIMENSION) {
          const fieldValue = _get(value, 'reportingDimension.field');
          const fieldDef = getFieldDef(fieldValue, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject);
          if (_get(fieldDef, 'dataType') === DATA_TYPES.DATE_TIME || _get(fieldDef, 'dataType') === DATA_TYPES.DATE) {
            data = getTime(_parseInt(data));
          }
        }
        return {
          ...acc,
          [value?.name]: data,
        };
      },
      EMPTY_OBJECT,
    ),
  );

const getTableProps = defaultMemoize(({ loading, bucketSize, currentPage, data, ...rest }) => ({
  totalNumberOfEntries: _size(data),
  loading,
  minRows: 0,
  showPagination: true,
  rowHeight: 50,
  pageSize: bucketSize,
  currentPage: currentPage + 1,
  ..._pick(rest, TABLE_MANAGER_DEFAULT_PROPS),
}));

const createFiltersObject = (selectedFilters) => {
  const filtersModified = _map(selectedFilters, (filter) => ({
    ...filter,
    field: _has(filter, 'field') ? _get(filter, 'field') : _get(filter, 'type'),
  }));

  const filters = [];

  _forEach(filtersModified, (filter) => {
    if (!_isEmpty(_get(filter, 'values'))) {
      if (_get(filter, 'operator') === OPERATORS.CONTAINS) {
        _set(filter, 'operator', 'TEXT_CONTAINS');
      }
      filters.push(_pick(filter, ['field', 'operator', 'values']));
    }
  });

  return filters;
};

export { getTransformedColumns, getTransformedData, getTableProps, createFiltersObject, getFieldDef };
