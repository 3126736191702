import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getFields } from './cellViewRowPropertiesForm.fields';
import { getCellViewRowPropertiesFromProperties, getUpdatedSelectedViewComponentFromOnChangeValue } from './cellViewRowPropertiesForm.helpers';

import { SECTIONS } from './cellViewRowPropertiesForm.constants';
import { VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

const CellViewRowPropertiesForm = ({ selectedViewComponent, entity, onAction }) => {
  const componentType = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.TYPE);
  const properties = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);

  const fields = useMemo(() => getFields(entity, properties, componentType), [componentType, entity, properties]);
  const cellViewRowProperties = useMemo(() => getCellViewRowPropertiesFromProperties(properties), [properties]);

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const updatedSelectedViewComponent = getUpdatedSelectedViewComponentFromOnChangeValue(selectedViewComponent, payload);
          onAction({ type, payload: updatedSelectedViewComponent });
          break;
        }

        default: {
          onAction(action);
        }
      }
    },
    [selectedViewComponent, onAction],
  );

  return <FormBuilder className="full-width" sections={SECTIONS} fields={fields} values={cellViewRowProperties} onAction={handleAction} />;
};

CellViewRowPropertiesForm.propTypes = {
  selectedViewComponent: PropTypes.object,
  entity: PropTypes.object,
  onAction: PropTypes.func,
};

CellViewRowPropertiesForm.defaultProps = {
  selectedViewComponent: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  onAction: _noop,
};

export default CellViewRowPropertiesForm;
