import { useCallback } from 'react';
import ACTION_TYPES from '../constants/linkInsertionForm.actionTypes';

function useFormActions(onAction, editor) {
  const handleLinkCreation = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.CREATE_LINK,
        payload: {
          editor,
          formValues,
        },
      });
    },
    [onAction, editor],
  );

  return {
    handleLinkCreation,
  };
}

export default useFormActions;
