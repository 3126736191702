import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox/Checkbox';

import MEDIA_PROPERTIES_FORM_FIELD_IDS from '../constants/mediaPropertiesForm.fieldIds';

const PREVIEW_ENABLED_FIELD = {
  id: MEDIA_PROPERTIES_FORM_FIELD_IDS.PREVIEW_ENABLED,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: __('Is Preview Enabled?'),
    defaultValue: false,
  },
};

const FILE_ACCESS_AUDIT_ENABLED_FIELD = {
  id: MEDIA_PROPERTIES_FORM_FIELD_IDS.FILE_ACCESS_AUDIT_ENABLED,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: __('Is Media Audit Enabled?'),
    defaultValue: false,
  },
};

const MEDIA_PROPERTIES_FORM_FIELDS = {
  [MEDIA_PROPERTIES_FORM_FIELD_IDS.PREVIEW_ENABLED]: PREVIEW_ENABLED_FIELD,
  [MEDIA_PROPERTIES_FORM_FIELD_IDS.FILE_ACCESS_AUDIT_ENABLED]: FILE_ACCESS_AUDIT_ENABLED_FIELD,
};

export default MEDIA_PROPERTIES_FORM_FIELDS;
