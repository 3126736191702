const FIELD_IDS = {
  FIELD_NAME: 'name',
  DISPLAY_NAME: 'displayName',
  ATTRIBUTE: 'attribute',
  OPTIONS: 'options',
};

const ACTION_TYPES = {
  ON_MOUNT: 'ON_MOUNT',
  ON_CANCEL: 'ON_CANCEL',
  ON_EDIT_CLICK: 'ON_EDIT_CLICK',
  MODAL_SAVE: 'MODAL_SAVE',
  MODAL_CLOSE: 'MODAL_CLOSE',
  ON_SAVE: 'ON_SAVE',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  MODAL_OPEN: 'MODAL_OPEN',
  ON_CHANGE: 'ON_CHANGE',
  OPTION_TABLE_ON_CHANGE: 'OPTION_TABLE_ON_CHANGE',
  OPTION_TABLE_REMOVE_ROW: 'OPTION_TABLE_REMOVE_ROW',
  OPTION_TABLE_ADD_ROW: 'OPTION_TABLE_ADD_ROW',
};

const FIELD_ATTRIBUTES = [FIELD_IDS.DISPLAY_NAME];

const CONTEXT_ID = 'RECORD_TYPE_FORM';

const STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

const DESCRIPTION_MAX_LENGTH = 30;

export { ACTION_TYPES, CONTEXT_ID, FIELD_ATTRIBUTES, FIELD_IDS, STATUS, DESCRIPTION_MAX_LENGTH };
