import React, { useMemo, useCallback } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';

import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import Heading from '@tekion/tekion-components/atoms/Heading';
import withActions from '@tekion/tekion-components/connectors/withActions';

import withSize from '../../../../../../connectors/withSize';
import RoleConfigureCardView from './components/roleConfigureCardView';
import AddUserRoleModal from './components/addUserRoleModal';
import NavigationItem from '../../../../../../molecules/NavigationFlow/components/navigationItem';
import ACTION_HANDLERS from './helpers/roleConfigure.actionHandlers';
import { createTableProps, getSubHeaderProps } from './helpers/roleConfigure.helpers';
import { COLUMN_CONFIG } from './helpers/roleConfigure.columns';
import { TABLE_MANAGER_PROPS, ROLES_NAVIGATION_DEFAULT_DATA, INITIAL_STATES_ASSIGN_USER_ROLE } from './constants/roleConfigure.general';

import styles from './roleConfigure.module.scss';

const RoleConfigure = ({
  isLoading,
  isAssignUserModalVisible,
  isAssignUserModalDataSubmitting,
  contentHeight,
  totalCount,
  pageSize,
  currentPage,
  nextPageToken,
  history,
  usersModalData,
  selectedRoleData,
  usersSettingData,
  userIds,
  rolesDataById,
  onAction,
}) => {
  const tableProps = useMemo(() => createTableProps(isLoading, pageSize, currentPage), [isLoading, pageSize, currentPage]);

  const headerNavigationData = useMemo(
    () => [...ROLES_NAVIGATION_DEFAULT_DATA, { label: __(_get(selectedRoleData, 'name', NO_DATA)), key: 'roleUserConfigure' }],
    [selectedRoleData],
  );

  const handleNavigationItemClick = useCallback(
    (goTo) => {
      if (!_isEmpty(goTo)) {
        history.push(goTo);
      }
    },
    [history],
  );

  const headerNavigation = useMemo(
    () =>
      _map(headerNavigationData, (data, index) => (
        <NavigationItem
          key={_get(data, 'key', EMPTY_STRING)}
          itemNumber={index}
          totalItems={_size(headerNavigationData)}
          data={data}
          onNavigationItemClick={handleNavigationItemClick}
        />
      )),
    [headerNavigationData, handleNavigationItemClick],
  );

  return (
    <Page>
      <Page.Header>
        <Heading className={styles.headerNavigationContainer} size={2}>
          {headerNavigation}
        </Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight }}>
        <RoleConfigureCardView roleCardData={selectedRoleData} onAction={onAction} />
        <div className={styles.tableConainer} style={{ height: contentHeight - 40 }}>
          <TableManager
            tokenPagination
            subHeaderProps={getSubHeaderProps(totalCount)}
            tableManagerProps={TABLE_MANAGER_PROPS}
            columns={COLUMN_CONFIG}
            tableProps={tableProps}
            pageSize={pageSize}
            currentPage={currentPage}
            data={usersSettingData}
            nextPageToken={nextPageToken}
            onAction={onAction}
          />
        </div>
        <AddUserRoleModal
          visible={isAssignUserModalVisible}
          modalData={usersModalData}
          onAction={onAction}
          existingUserIds={userIds}
          isAssignModalSubmitting={isAssignUserModalDataSubmitting}
          rolesDataById={rolesDataById}
        />
      </Page.Body>
    </Page>
  );
};

RoleConfigure.propTypes = {
  isLoading: PropTypes.bool,
  isAssignUserModalVisible: PropTypes.bool,
  isAssignUserModalDataSubmitting: PropTypes.bool,
  contentHeight: PropTypes.number,
  totalCount: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  nextPageToken: PropTypes.string,
  history: PropTypes.object.isRequired,
  usersModalData: PropTypes.object,
  selectedRoleData: PropTypes.object,
  usersSettingData: PropTypes.array,
  userIds: PropTypes.array,
  rolesDataById: PropTypes.array,
  onAction: PropTypes.func,
};

RoleConfigure.defaultProps = {
  isLoading: false,
  isAssignUserModalVisible: false,
  isAssignUserModalDataSubmitting: false,
  contentHeight: 0,
  totalCount: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  currentPage: DEFAULT_PAGE_INFO.start,
  nextPageToken: EMPTY_STRING,
  usersModalData: EMPTY_OBJECT,
  selectedRoleData: EMPTY_OBJECT,
  usersSettingData: EMPTY_ARRAY,
  userIds: EMPTY_ARRAY,
  rolesDataById: EMPTY_ARRAY,
  onAction: _noop,
};

export default compose(
  withSize({ hasPageHeader: 1, hasPageFooter: 1 }),
  withActions(INITIAL_STATES_ASSIGN_USER_ROLE, ACTION_HANDLERS),
)(RoleConfigure);
