import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

// Components
import TextArea from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import MediaUploaderFormRenderer from '../../../../../../organisms/mediaUploader/MediaUploaderFormRenderer';

// Helpers
import { nameValidator } from '../../../../../../utils/formValidators';

// Constants
import { FIELD_IDS } from './applicationFormPage.constants';
import { FORM_MODES } from '../../../../../../constants/general.constants';

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Display Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Display Name'),
  },
};

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Name'),
    placeholder: __('This will be autofilled'),
  },
};

const DESCRIPTION_FIELD = {
  id: FIELD_IDS.DESCRIPTION,
  renderer: TextArea,
  renderOptions: {
    rows: 3,
    label: __('Description'),
  },
};

const MEDIA_ID_FIELD = {
  id: FIELD_IDS.MEDIA_ID,
  renderer: MediaUploaderFormRenderer,
  renderOptions: {
    label: __('Application Icon'),
    accept: '.jpg,.png,.jpeg',
  },
};

const CREATE_EDIT_MODE_FIELDS = {
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DESCRIPTION]: DESCRIPTION_FIELD,
  [FIELD_IDS.MEDIA_ID]: MEDIA_ID_FIELD,
};

const getFields = (mode) => ({
  ...CREATE_EDIT_MODE_FIELDS,
  [FIELD_IDS.NAME]: addToRenderOptions(NAME_FIELD, [
    { path: 'disabled', value: mode !== FORM_MODES.CREATE },
    { path: 'required', value: mode === FORM_MODES.CREATE },
    { path: 'validators', value: mode === FORM_MODES.CREATE ? [nameValidator] : EMPTY_ARRAY },
  ]),
});

export default getFields;
