import React from 'react';
import PropTypes from 'prop-types';

import _size from 'lodash/size';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import BaseTable from '@tekion/tekion-components/molecules/table/BaseTable';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import Collapse from '@tekion/tekion-components/molecules/Collapse';

import { getCriteriaList } from './derivationConditionTable.helpers';
import { COLUMNS } from './derivationCondition.helpers';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';

import styles from '../../recordTypeOverride.module.scss';

const DerivationConditionTable = ({ condition, entity }) => {
  let criteriaList = _get(condition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY);
  criteriaList = getCriteriaList(criteriaList, entity);
  const expression = _get(condition, CONDITION_BUILDER_FIELD_IDS.EXPRESSION);

  return (
    <Collapse bodyClassName={styles.collapse} className={styles.collapseStyle}>
      <Collapse.Panel
        key="conditionTable"
        panelKey="conditionTable"
        header={<div className={styles.applicableFieldsHeader}>{__('Derivation Condition')}</div>}
      >
        <div>
          <div className={styles.expressionHeading}>{__('Expression')}</div>
          <TextInput className={styles.conditionExpression} value={expression} />
          <BaseTable showPagination={false} columns={COLUMNS} data={criteriaList} pageSize={_size(criteriaList)} sortable={false} resizable={false} />
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

DerivationConditionTable.propTypes = {
  condition: PropTypes.object,
  entity: PropTypes.object,
};

DerivationConditionTable.defaultProps = {
  condition: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
};

export default React.memo(DerivationConditionTable);
