import _pick from 'lodash/pick';
import _valuesIn from 'lodash/valuesIn';

import MEDIA_PROPERTIES_FORM_FIELD_IDS from '../constants/mediaPropertiesForm.fieldIds';

const getMediaPropertiesFormValues = (fieldsFormMediaPropertiesValues) =>
  _pick(fieldsFormMediaPropertiesValues, _valuesIn(MEDIA_PROPERTIES_FORM_FIELD_IDS));

const getFieldsFormMediaPropertiesValue = (values) =>
  _pick(values, [MEDIA_PROPERTIES_FORM_FIELD_IDS.PREVIEW_ENABLED, MEDIA_PROPERTIES_FORM_FIELD_IDS.FILE_ACCESS_AUDIT_ENABLED]);

export { getMediaPropertiesFormValues, getFieldsFormMediaPropertiesValue };
