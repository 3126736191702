import { defaultMemoize } from 'reselect';

import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import _isArray from 'lodash/isArray';
import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _flattenDeep from 'lodash/flattenDeep';
import _keysIn from 'lodash/keysIn';
import _find from 'lodash/find';
import _uniq from 'lodash/uniq';
import _isNil from 'lodash/isNil';
import _includes from 'lodash/includes';
import _has from 'lodash/has';
import _castArray from 'lodash/castArray';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { DATE_TIME_FORMATS, getFormattedDateTime } from '@tekion/tekion-base/utils/dateUtils';
import DownloadButton from './DownloadButton';

import { getSimpleFieldNameFromColumn, joinByCommas } from '../../../../utils';
import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';
import { tableManagerFilterOperatorsToConfigFilterOperators } from './listViewRenderer.filterConfig';

import { CUSTOM_STYLE_IDS } from '../../../../constants/customStyles.constants';
import {
  TABLE_MANAGER_PROPS,
  LIST_VIEW_RENDERER_DEFAULT_PROPS,
  ENTITY,
  ACTION_TYPES,
  LIST_VIEW_CONFIG_PROPERTY_KEYS,
  VIEW_RECORD_ACTION_TYPES,
  ON_CLICK_ACTION_TYPES,
  EXISTENCE_CHECK_FIELDS,
  LIST_VIEW_PAYLOAD_KEYS,
} from './listViewRenderer.constants';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../constants/actionBuilder.constants';
import {
  ALL_VIEW_PROPERTY_KEYS,
  CELL_TYPES,
  VIEW_CONFIGURATION_FIELD_IDS,
  VIEW_CONFIGURATION_GENERAL_KEYS,
} from '../../../../constants/viewBuilder.constants';
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';

import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

import styles from './listViewRenderer.module.scss';

const createTableProps = ({ loading, totalCount, currentPage, pageSize, configTableManagerProps, currentExpandedRows, getTrProps, onAction }) => ({
  currentPage: currentPage + 1,
  resultPerPages: pageSize != null ? pageSize : configTableManagerProps.resultPerPages,
  pageSize: pageSize != null ? pageSize : configTableManagerProps.resultPerPages,
  loading,
  minRows: configTableManagerProps.minRows,
  totalNumberOfEntries: totalCount,
  showPagination: configTableManagerProps.showPagination,
  fixedPagination: configTableManagerProps.fixedPagination,
  showActions: configTableManagerProps.showActions,
  rowHeight: configTableManagerProps.rowHeight,
  pageSizeOptions: configTableManagerProps.pageSizeOptions,
  onRowActionClick: configTableManagerProps.onRowActionClick,
  getRowActions: configTableManagerProps.getRowActions,
  onExpanderClick: (cellInfo) => {
    onAction({ type: ACTION_TYPES.TABLE_ON_EXPAND, payload: { cellInfo } });
  },
  expanded: currentExpandedRows,
  overrideDefaultExpanderClick: true,
  getTrProps,
});

const isFilterValueValid = (values) => {
  const headValue = getArraySafeValue(values);

  if (!_isArray(values) || (_size(values) > 0 && _isNil(headValue))) return false;

  return true;
};

const createFiltersObject = (selectedFilters, fieldDefinitionsByName) => {
  let filters = _reduce(
    selectedFilters,
    (prevFilters, currFilter) => {
      const filterType = _get(currFilter, 'filterType');
      if (isFilterValueValid(_get(currFilter, 'values')) || _includes(EXISTENCE_CHECK_FIELDS, filterType)) {
        let filterConfig = {};
        if (_includes(EXISTENCE_CHECK_FIELDS, filterType)) {
          filterConfig = _pick(currFilter, ['field', 'filterType']);
        } else {
          filterConfig = _pick(currFilter, ['field', 'filterType', 'values']);
        }
        const field = getSimpleFieldNameFromColumn(_get(filterConfig, 'field'));
        const fieldDefinition = tget(fieldDefinitionsByName, field, {});
        const fieldType = fieldDefinitionReader.fieldType(fieldDefinition);
        if (fieldType === FIELD_TYPES.RELATIONSHIP) {
          filterConfig = { ...filterConfig, field };
        }
        prevFilters.push(filterConfig);
      }
      return prevFilters;
    },
    [],
  );

  filters = tableManagerFilterOperatorsToConfigFilterOperators(filters);

  return filters;
};

const getFieldsForPayload = (configColumns) => _flattenDeep(_map(configColumns, 'fieldNames'));

const getPayloadForSearch = (searchText, searchMetadata, similarViewDetails) => {
  const defaultSearchText = tget(similarViewDetails, 'searchText', EMPTY_STRING);
  const defaultSearchFields = tget(similarViewDetails, 'searchFields', []);

  // Default search and normal search are exclusive in nature so if default search details is present ideally we won't allow other search
  if (!_isEmpty(defaultSearchText) && !_isEmpty(defaultSearchFields)) {
    const apiPayloadSearchFieldsMetadata = _map(defaultSearchFields, (defaultSearchField) => ({ field: defaultSearchField })) || [];

    return {
      searchTextMetadata: apiPayloadSearchFieldsMetadata,
      searchText: defaultSearchText,
    };
  }

  if (_isEmpty(searchText)) return EMPTY_OBJECT;

  return {
    searchTextMetadata: searchMetadata,
    searchText,
  };
};

const getMergedSortDetails = (sortDetails, defaultSortDetails) =>
  _reduce(
    defaultSortDetails,
    (prevMergedSortDetails, value, key) => {
      if (!_has(sortDetails, key)) {
        return { ...prevMergedSortDetails, [key]: value };
      }
      return prevMergedSortDetails;
    },
    { ...sortDetails },
  );

const getPayloadForSort = (sortDetails = EMPTY_OBJECT, defaultSortDetails = EMPTY_OBJECT) => {
  const mergedSortDetails = getMergedSortDetails(sortDetails, defaultSortDetails);

  if (_isEmpty(mergedSortDetails)) return EMPTY_ARRAY;
  return {
    sortList: _map(mergedSortDetails, (value, key) => ({ field: key, order: value })),
  };
};

const getPayloadForNextPageToken = (nextPageToken) => (_isEmpty(nextPageToken) ? EMPTY_OBJECT : { nextPageToken });

const payloadConstructor = ({
  selectedFilters = EMPTY_ARRAY,
  searchText = '',
  searchMetadata = EMPTY_ARRAY,
  sortDetails = EMPTY_OBJECT,
  pageSize = 0,
  configColumns = EMPTY_ARRAY,
  currentPageToken = '',
  defaultSortDetails = EMPTY_OBJECT,
  preIncludeFields = EMPTY_ARRAY,
  fieldDefinitionsByName = EMPTY_OBJECT,
  similarViewDetails = EMPTY_OBJECT,
}) => {
  const filters = createFiltersObject(selectedFilters, fieldDefinitionsByName);
  const payloadForSearch = getPayloadForSearch(searchText, searchMetadata, similarViewDetails);
  const payloadForNextPageToken = getPayloadForNextPageToken(currentPageToken);
  let payloadForSort = {};

  if (!_isNil(_get(payloadForSearch, 'searchText'))) {
    payloadForSort = getPayloadForSort(sortDetails, EMPTY_OBJECT);
  } else {
    payloadForSort = getPayloadForSort(sortDetails, defaultSortDetails);
  }

  return {
    filters,
    rows: pageSize,
    includeFields: _uniq([...getFieldsForPayload(configColumns), ...preIncludeFields]),
    ...payloadForSearch,
    ...payloadForSort,
    ...payloadForNextPageToken,
  };
};

const getTableProps = (properties) => {
  const configProps = _pick(properties, _keysIn(LIST_VIEW_RENDERER_DEFAULT_PROPS));
  const tableProps = {
    ...LIST_VIEW_RENDERER_DEFAULT_PROPS,
    ...configProps,
    showActions: false,
  };

  return tableProps;
};

const getTableManagerProps = (properties) => {
  const configProps = _pick(properties, _keysIn(TABLE_MANAGER_PROPS));
  const tableManagerProps = {
    ...TABLE_MANAGER_PROPS,
    ...configProps,
  };

  return tableManagerProps;
};

const getTableColumnsFromChildren = (children) =>
  _map(children, (child) => ({
    ..._get(child, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES),
    displayName: _get(child, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.TITLE}`),
  }));

const getPropsFromConfig = (properties, children) => {
  const configColumns = getTableColumnsFromChildren(children);
  const configTableProps = getTableProps(properties);
  const configTableManagerProps = getTableManagerProps(properties);
  const downloadOptions = tget(properties, LIST_VIEW_CONFIG_PROPERTY_KEYS.DOWNLOAD_OPTIONS, []);
  const filterMetadata = tget(properties, LIST_VIEW_CONFIG_PROPERTY_KEYS.FILTER_META_DATA, []);

  return {
    configColumns,
    configTableProps,
    configTableManagerProps,
    downloadOptions,
    filterMetadata,
  };
};

const getUpdatedViewContexts = (applicationId, viewContext, viewConfigName, selectedFilters, sortDetails, searchText, searchField) => {
  const updatedViewContext = viewContext || {};

  if (!_isEmpty(selectedFilters)) {
    _set(updatedViewContext, `${applicationId}.${viewConfigName}.selectedFilters`, selectedFilters);
  } else {
    _unset(updatedViewContext, `${applicationId}.${viewConfigName}.selectedFilters`);
  }

  if (!_isEmpty(sortDetails)) {
    _set(updatedViewContext, `${applicationId}.${viewConfigName}.sortDetails`, sortDetails);
  } else {
    _unset(updatedViewContext, `${applicationId}.${viewConfigName}.sortDetails`);
  }

  if (!_isEmpty(searchText)) {
    _set(updatedViewContext, `${applicationId}.${viewConfigName}.searchText`, searchText);
  } else {
    _unset(updatedViewContext, `${applicationId}.${viewConfigName}.searchText`);
  }

  if (!_isEmpty(searchField)) {
    _set(updatedViewContext, `${applicationId}.${viewConfigName}.searchField`, searchField);
  } else {
    _unset(updatedViewContext, `${applicationId}.${viewConfigName}.searchField`);
  }
  return updatedViewContext;
};

const getPayloadForExport = ({ viewName, entityName, fileFormat, payloadForDataFilter }) => {
  const sortList = tget(payloadForDataFilter, LIST_VIEW_PAYLOAD_KEYS.SORT_LIST, EMPTY_ARRAY);
  return {
    viewName,
    entityName,
    fileFormat,
    ..._pick(payloadForDataFilter, [LIST_VIEW_PAYLOAD_KEYS.FILTERS, LIST_VIEW_PAYLOAD_KEYS.SEARCH_TEXT, LIST_VIEW_PAYLOAD_KEYS.SEARCH_TEXT_METADATA]),
    [LIST_VIEW_PAYLOAD_KEYS.SORT_DETAILS]: sortList,
  };
};

const getTableHeaderProps = (displayName) => ({
  label: displayName,
  hasBack: false,
});

const getTableSubHeaderProps = (downloadOptions) => {
  let subHeaderProps = {
    className: styles.subHeader,
  };

  if (!_isEmpty(downloadOptions)) {
    subHeaderProps = {
      ...subHeaderProps,
      subHeaderRightActions: [
        {
          renderer: DownloadButton,
          renderOptions: {
            downloadOptions,
          },

          action: ACTION_TYPES.ON_DOWNLOAD,
        },
      ],
    };
  }

  return subHeaderProps;
};

const getPayloadForActions = defaultMemoize(({ entityName, actionsToShow }) => {
  let filters = [
    {
      field: ACTION_DEFINITION_FIELD_IDS.ACTION_LEVEL,
      values: [ENTITY],
      filterType: OPERATORS.IN,
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      values: [entityName],
      filterType: OPERATORS.IN,
    },
    {
      field: 'status',
      values: ['ACTIVE'],
      filterType: OPERATORS.IN,
    },
  ];

  if (!_isEmpty(actionsToShow)) {
    filters = [
      ...filters,
      {
        field: ACTION_DEFINITION_FIELD_IDS.ACTION_NAME,
        values: actionsToShow,
        filterType: OPERATORS.IN,
      },
    ];
  }

  const payload = {
    filters,
  };

  return payload;
});

const getViewRecordActionConfigForExpandableRows = defaultMemoize((viewRecordActionConfigs) =>
  _find(viewRecordActionConfigs, (config) => {
    const viewRecordActionType = _get(config, LIST_VIEW_CONFIG_PROPERTY_KEYS.VIEW_RECORD_ACTION_TYPE, '');
    const onClickActionType = _get(config, LIST_VIEW_CONFIG_PROPERTY_KEYS.ON_CLICK_ACTION_TYPE, '');

    return viewRecordActionType === VIEW_RECORD_ACTION_TYPES.CELL_CLICK && onClickActionType === ON_CLICK_ACTION_TYPES.EXPAND;
  }),
);

const getParentChildRelationshipFilter = (childRelationshipField, childEntity, rowData) => {
  const relationshipFieldDefinition = _find(
    _get(childEntity, 'fieldDefinitions', []),
    (fieldDef) => _get(fieldDef, 'name') === childRelationshipField,
  );
  const lookupField = _get(relationshipFieldDefinition, 'lookupField.field');
  const parentRecordRelationshipValue = _get(rowData, `original.${lookupField}`);
  const childRelationshipContext = {
    field: `${childRelationshipField}.${lookupField}`,
    filterType: 'IN',
    values: [parentRecordRelationshipValue],
  };
  return childRelationshipContext;
};

const getCellViewNameForCompoundColumnWithCellView = (columnConfigProperties) => {
  const cellType = tget(columnConfigProperties, ALL_VIEW_PROPERTY_KEYS.CELL_TYPE);
  const cellViewName = tget(columnConfigProperties, ALL_VIEW_PROPERTY_KEYS.CELL_VIEW_NAME);

  return cellType === CELL_TYPES.COMPOUND ? cellViewName : null;
};

const getCardViewNameFromGridViewRenderer = (properties) => tget(properties, ALL_VIEW_PROPERTY_KEYS.CARD_VIEW_NAME);

const getCellViewNamesFromColumnConfig = (columnConfigs) => {
  const cellViewNames = _reduce(
    columnConfigs,
    (prevCellViewNames, columnConfig) => {
      const cellViewName = getCellViewNameForCompoundColumnWithCellView(tget(columnConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES));
      if (!_isEmpty(cellViewName)) {
        prevCellViewNames.push(cellViewName);
      }
      return prevCellViewNames;
    },
    [],
  );
  return _uniq(cellViewNames);
};

const getPayloadForCellView = (cellViewNames, entityName) => ({
  filters: [
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.NAME,
      values: cellViewNames,
      filterType: OPERATORS.IN,
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      filterType: OPERATORS.IN,
      values: _castArray(entityName),
    },
  ],
});

const extractAllFieldsFromViewConfiguration = (viewConfiguration, includeFields) => {
  if (_isEmpty(viewConfiguration)) {
    return;
  }

  const properties = tget(viewConfiguration, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);
  const fieldNames = tget(properties, ALL_VIEW_PROPERTY_KEYS.FIELD_NAMES, EMPTY_ARRAY);

  includeFields.push(...fieldNames);

  const children = tget(viewConfiguration, 'children', EMPTY_ARRAY);

  _forEach(children, (child) => extractAllFieldsFromViewConfiguration(child, includeFields));
};

const getLabelForFilterOptionsBasedOnDataType = (value, lookUpFieldDef) => {
  const lookupFieldDataType = fieldDefinitionReader.dataType(lookUpFieldDef);
  const isMultiValued = fieldDefinitionReader.multiValued(lookUpFieldDef);

  if (lookupFieldDataType === DATA_TYPES.DATE || lookupFieldDataType === DATA_TYPES.DATE_TIME) {
    return getFormattedDateTime(value, DATE_TIME_FORMATS.DATE_ORDINAL_MONTH_YEAR_WITH_TIME);
  } else if (isMultiValued && _isArray(value)) {
    return joinByCommas(value);
  }

  return value;
};

const getOptions = (lookUpFieldDef) => (data, lookUpDisplayName, lookUpFieldName) => {
  const options = _map(data, (userConfig) => {
    const label = tget(userConfig, `entity.${lookUpDisplayName}`, tget(userConfig, lookUpDisplayName, tget(userConfig, 'name', EMPTY_STRING)));
    const value = tget(userConfig, `entity.${lookUpFieldName}`, tget(userConfig, lookUpFieldName, EMPTY_STRING));

    return { label: __(getLabelForFilterOptionsBasedOnDataType(label, lookUpFieldDef)), value };
  });
  return options;
};

/* const getPayloadForAsyncSelect = (nextPageToken, searchText) => ({
  rows: ASYNC_SELECT_FILTER_ROWS,
  nextPageToken,
  searchText,
});

const fetchEntityRecordsForAsyncSelect = (entityType) => async (payload) => {
  const response = await fetchEntityRecords(entityType, payload);
  return response;
}; */

const generateCustomStyleForRow = (rowInfo, customStyles, variablesApi) => {
  const resolvedCustomStyles = getResolvedCustomStylesFromViewConfigCustomStyles(
    [CUSTOM_STYLE_IDS.ROW],
    customStyles,
    rowInfo.original,
    variablesApi,
  );

  return {
    style: tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.ROW, {}),
  };
};

export {
  getPropsFromConfig,
  payloadConstructor,
  createTableProps,
  getUpdatedViewContexts,
  getPayloadForExport,
  getTableHeaderProps,
  getPayloadForActions,
  getTableColumnsFromChildren,
  getViewRecordActionConfigForExpandableRows,
  getParentChildRelationshipFilter,
  getTableSubHeaderProps,
  getCellViewNamesFromColumnConfig,
  getPayloadForCellView,
  getCellViewNameForCompoundColumnWithCellView,
  getCardViewNameFromGridViewRenderer,
  extractAllFieldsFromViewConfiguration,
  getOptions,
  generateCustomStyleForRow,
};
