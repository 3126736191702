import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _valuesIn from 'lodash/valuesIn';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isLessThanRule, isNumericRule, isPositiveNumber, isRequiredRule, nonNegativeRule } from '@tekion/tekion-base/utils/formValidators';
import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { mergeRenderOptions } from '../../../helpers/fieldsForm.general.helpers';
import { isRegexRule } from '../../../../../../../../utils/formValidators';

import TEXT_PROPERTIES_FORM_FIELD_IDS from '../constants/textPropertiesForm.fieldIds';
import { minMaxRule } from './textPropertiesForm.helpers';

const MIN_TEXT_LENGTH_FIELD = {
  id: TEXT_PROPERTIES_FORM_FIELD_IDS.MIN_TEXT_LENGTH,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Min Text Length'),
    placeholder: __('Enter Min length of text '),
    required: true,
    validators: [isRequiredRule, isNumericRule, isPositiveNumber, minMaxRule],
  },
};

const MAX_TEXT_LENGTH_FIELD = {
  id: TEXT_PROPERTIES_FORM_FIELD_IDS.MAX_TEXT_LENGTH,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Max Text Length'),
    placeholder: __('Enter Max length of text'),
    required: true,
    validators: [isRequiredRule, isNumericRule, isLessThanRule(257), nonNegativeRule],
  },
};

const PATTERN_FIELD = {
  id: TEXT_PROPERTIES_FORM_FIELD_IDS.PATTERN,
  renderer: TextInput,
  renderOptions: {
    placeholder: __('Write Regex without / at start and end'),
    label: __('Pattern'),
    validators: [isRegexRule],
    helpText: __('Write Regex without / at start and end '),
  },
};

const TEST_REGEX_INPUT_STRING_FIELD = {
  id: TEXT_PROPERTIES_FORM_FIELD_IDS.TEST_REGEX_INPUT_STRING,
  renderer: TextInput,
  renderOptions: {
    label: __('String to test your regex'),
    placeholder: __('Enter string'),
  },
};

const SELECT_TEMPLATE_REGEX_FIELD = {
  id: TEXT_PROPERTIES_FORM_FIELD_IDS.SELECT_TEMPLATE_REGEX,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select template for regex'),
    label: __('Template for regex'),
  },
};

const TEXT_PROPERTIES_FORM_FIELDS = {
  [TEXT_PROPERTIES_FORM_FIELD_IDS.MIN_TEXT_LENGTH]: MIN_TEXT_LENGTH_FIELD,
  [TEXT_PROPERTIES_FORM_FIELD_IDS.MAX_TEXT_LENGTH]: MAX_TEXT_LENGTH_FIELD,
  [TEXT_PROPERTIES_FORM_FIELD_IDS.PATTERN]: PATTERN_FIELD,
  [TEXT_PROPERTIES_FORM_FIELD_IDS.SELECT_TEMPLATE_REGEX]: SELECT_TEMPLATE_REGEX_FIELD,
  [TEXT_PROPERTIES_FORM_FIELD_IDS.TEST_REGEX_INPUT_STRING]: TEST_REGEX_INPUT_STRING_FIELD,
};

const getFields = (regexpOptions, renderOptions) => {
  const fieldsRenderOptions = {
    [TEXT_PROPERTIES_FORM_FIELD_IDS.SELECT_TEMPLATE_REGEX]: [
      {
        path: 'options',
        value: regexpOptions,
      },
    ],
  };
  const mergedRenderOptions = mergeRenderOptions(fieldsRenderOptions, _valuesIn(TEXT_PROPERTIES_FORM_FIELD_IDS), renderOptions);

  return _reduce(
    _valuesIn(TEXT_PROPERTIES_FORM_FIELD_IDS),
    (fields, fieldId) => ({
      ...fields,
      [fieldId]: addToRenderOptions(_get(TEXT_PROPERTIES_FORM_FIELDS, fieldId), _get(mergedRenderOptions, fieldId, EMPTY_ARRAY)),
    }),
    EMPTY_OBJECT,
  );
};

export default getFields;
