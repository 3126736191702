import _filter from 'lodash/filter';
import _includes from 'lodash/includes';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { getSelectedEventHandlers, generateEventOptions } from '../../helpers/eventHandlerConfigurator.helper';

import { FIELD_IDS } from './eventHandlerModal.constants';
import { EVENT_NAMES_MAP_BASED_ON_VIEW_TYPE_AND_COMPONENT_TYPE } from '../../helpers/eventHandlerConfigurator.eventMapper';

import eventConfiguratorReader from '../../../../../../readers/eventConfigurator.reader';

const getEventTypeOptionsFromValues = (values, allFormValues, viewType, componentType) => {
  const eventValue = tget(values, FIELD_IDS.EVENT_HANDLER, EMPTY_OBJECT);
  const eventType = eventConfiguratorReader.eventType(eventValue);
  const { selectedEventHandlerEventTypes } = getSelectedEventHandlers(allFormValues);

  const supportedEventNames = tget(EVENT_NAMES_MAP_BASED_ON_VIEW_TYPE_AND_COMPONENT_TYPE, [viewType, componentType], []);
  const options = generateEventOptions(supportedEventNames);
  const filteredEventTypeOptions = _filter(options, ({ value }) => eventType === value || !_includes(selectedEventHandlerEventTypes, value));

  return filteredEventTypeOptions;
};

export { getEventTypeOptionsFromValues };
