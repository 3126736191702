const OPERATORS = {
  IN: {
    label: __('In'),
    value: 'IN',
  },
  NOT_IN: {
    label: __('Not in'),
    value: 'NIN',
  },
  STARTS_WITH: {
    label: __('Starts with'),
    value: 'STARTS_WITH',
  },
  ENDS_WITH: {
    label: __('Ends with'),
    value: 'ENDS_WITH',
  },
  EQUALS: {
    label: __('Equals'),
    value: 'IN',
  },
  DOES_NOT_EQUAL: {
    label: __('Not equal to'),
    value: 'DOES_NOT_EQUAL',
  },
  LESS_THAN: {
    label: __('Less than'),
    value: 'LESS_THAN',
  },
  GREATER_THAN: {
    label: __('Greater than'),
    value: 'GREATER_THAN',
  },
  LESS_THAN_EQUAL_TO: {
    label: __('Less than or equal to'),
    value: 'LESS_THAN_EQUAL_TO',
  },
  GREATER_THAN_EQUAL_TO: {
    label: __('Greater than or equal to'),
    value: 'GREATER_THAN_EQUAL_TO',
  },
  IS_NULL: {
    label: __('Is null'),
    value: 'IS_NULL',
  },
  CHANGED: {
    label: __('Has changed'),
    value: 'HAS_CHANGED',
  },
  CHANGED_FROM: {
    label: __('Changed from'),
    value: 'CHANGED_FROM',
  },
  CHANGED_TO: {
    label: __('Changed to'),
    value: 'CHANGED_TO',
  },
};

const OPERATORS_WITH_VALUE_NULL = [OPERATORS.IS_NULL.value, OPERATORS.CHANGED.value];

const RECORD_CHANGE_OPERATORS = [OPERATORS.CHANGED, OPERATORS.CHANGED_FROM, OPERATORS.CHANGED_TO];

export { OPERATORS, OPERATORS_WITH_VALUE_NULL, RECORD_CHANGE_OPERATORS };
