const LS_CACHE_KEYS = {
  USER: 'dev_platform_user',
  TOKEN: 'dev_platform_token',
  COOKIES: 'dev_platform_cookies',
  PROJECT_CONTEXT: 'project_context',
  VIEW_CONTEXTS: 'view_contexts',
  ENV_PREFIX: 'envPrefix',
};

export default LS_CACHE_KEYS;
