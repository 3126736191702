exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".loginPage_forgotButtonField__tNy2xbc93D{display:flex;justify-content:flex-end}.loginPage_dontChallangeField__jiPy9rWrUZ{display:flex;align-items:center}[dir] .loginPage_labelClassName__789b2wYpib{margin-top:.5rem}[dir=ltr] .loginPage_labelClassName__789b2wYpib{margin-left:1.2rem}[dir=rtl] .loginPage_labelClassName__789b2wYpib{margin-right:1.2rem}.loginPage_backIcon__97nicM7j9i{display:inline-block}[dir=ltr] .loginPage_backIcon__97nicM7j9i{margin-right:.4rem}[dir=rtl] .loginPage_backIcon__97nicM7j9i{margin-left:.4rem}", ""]);

// Exports
exports.locals = {
	"forgotButtonField": "loginPage_forgotButtonField__tNy2xbc93D",
	"dontChallangeField": "loginPage_dontChallangeField__jiPy9rWrUZ",
	"labelClassName": "loginPage_labelClassName__789b2wYpib",
	"backIcon": "loginPage_backIcon__97nicM7j9i"
};