import { hasNumberRule, isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';

import CustomStylesConfigurator from '../customStylesConfigurator/CustomStylesConfigurator';

import { FIELD_IDS } from './cellViewRowPropertiesForm.constants';

const COLUMN_COUNT_FIELD = {
  id: FIELD_IDS.COLUMN_COUNT,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Number of Columns'),
    defaultValue: 1,
    min: 1,
    placeholder: __('Enter the number of columns here'),
    validators: [isRequiredRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const CUSTOM_STYLE_FIELD = {
  id: FIELD_IDS.CUSTOM_STYLES,
  renderer: CustomStylesConfigurator,
};

const FORM_FIELDS = {
  [FIELD_IDS.COLUMN_COUNT]: COLUMN_COUNT_FIELD,
  [FIELD_IDS.CUSTOM_STYLES]: CUSTOM_STYLE_FIELD,
};

const getFields = (entity, properties, componentType) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.CUSTOM_STYLES]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CUSTOM_STYLES], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
});

export { getFields };
