import { defaultMemoize } from 'reselect';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';

const getTableProps = defaultMemoize((rows, loading, currentPage, totalCount) => ({
  currentPage: currentPage + 1,
  loading,
  resultsPerPage: rows,
  rowHeight: 40,
  totalNumberOfEntries: totalCount,
  minRows: 0,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  selection: EMPTY_ARRAY,
  pageSize: rows,
}));

export { getTableProps };
