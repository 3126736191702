import React, { useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';

// Tekion-components
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Modal from '@tekion/tekion-components/molecules/Modal';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';

// Tekion-connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Connectors
import withSize from '../../../../../../connectors/withSize';

// Helpers
import { createTableProps } from './helpers/approvalCategoryList.helpers';
import COLUMN_CONFIG from './helpers/approvalCategoryList.columns';
import ACTION_HANDLERS from './helpers/approvalCategoryList.actionHandlers';

// Constants
import { SUB_HEADER_PROPS, TABLE_MANAGER_PROPS } from './constants/approvalCategoryList.constants';
import ACTION_TYPES from './constants/approvalCategoryList.actionTypes';
import COLUMN_IDS from './constants/approvalCategoryList.columnIds';

const ApprovalCategoryList = ({
  isLoading,
  isCategoryCreationInProgress,
  isCategoryAddModalVisible,
  contentHeight,
  currentPage,
  pageSize,
  searchText,
  nextPageToken,
  data,
  values,
  errors,
  onAction,
}) => {
  const tableProps = useMemo(() => createTableProps(isLoading, currentPage, pageSize, onAction), [isLoading, currentPage, pageSize, onAction]);

  const handleSubmit = () => {
    onAction({ type: ACTION_TYPES.SUBMIT_CATEGORY_CREATION });
  };

  const handleCancel = () => {
    onAction({ type: ACTION_TYPES.CANCEL_CATEGORY_CREATION });
  };

  const categoryNameField = (
    <TextInput
      id={COLUMN_IDS.CATEGORY}
      label={__('Category')}
      value={values?.[COLUMN_IDS.CATEGORY]}
      error={errors?.[COLUMN_IDS.CATEGORY]}
      onAction={onAction}
    />
  );

  return (
    <Page style={{ height: contentHeight }}>
      <TableManager
        tokenPagination
        nextPageToken={nextPageToken}
        searchText={searchText}
        subHeaderProps={SUB_HEADER_PROPS}
        tableManagerProps={TABLE_MANAGER_PROPS}
        columns={COLUMN_CONFIG}
        tableProps={tableProps}
        data={data}
        onAction={onAction}
      />
      <Modal
        destroyOnClose
        visible={isCategoryAddModalVisible}
        loading={isCategoryCreationInProgress}
        title={__('Create category')}
        submitBtnText={__('Create')}
        content={categoryNameField}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};

ApprovalCategoryList.propTypes = {
  isLoading: PropTypes.bool,
  isCategoryCreationInProgress: PropTypes.bool,
  isCategoryAddModalVisible: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  searchText: PropTypes.string,
  nextPageToken: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  data: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ApprovalCategoryList.defaultProps = {
  isLoading: false,
  isCategoryCreationInProgress: false,
  isCategoryAddModalVisible: false,
  currentPage: DEFAULT_PAGE_INFO.start,
  pageSize: DEFAULT_PAGE_SIZE,
  searchText: EMPTY_STRING,
  nextPageToken: EMPTY_STRING,
  values: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  data: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: false }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ApprovalCategoryList);
