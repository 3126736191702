// Form Validators
import { isRequiredRule, isNumericRule } from '@tekion/tekion-base/utils/formValidators';

// Components
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import Button from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/button';
import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import SwitchRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch/Switch';

import ListInputTable from '../components/ListInputTable';
import ControllingOptionConfigTable from '../components/controllingOptionConfigTable/ControllingOptionConfigTable';
import EntityAsyncSelect from '../../../molecules/EntityAsyncSelect/EntityAsyncSelect';
import DependencyConfigTable from '../components/dependencyConfigTable/DependencyConfigTable';
import { isRegexRule } from '../helpers/fieldsForm.helpers';
import FIELD_IDS from './fieldsForm.fieldIds';
import { FILTER_CRITERIA_FOR_CHILD_OPTIONS, FILTER_CRITERIA_FOR_PARENT_OPTIONS, ROLL_UP_OPTIONS } from './fieldsForm.constants';

// Styles
import styles from '../fieldsForm.module.scss';

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Name'),
    placeholder: __('This will be autofilled'),
  },
};

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Display Name'),
    placeholder: __('Enter field display name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const FIELD_TYPE_FIELD = {
  id: FIELD_IDS.FIELD_TYPE,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Field Type'),
    label: __('Field Type'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const DATA_TYPE_FIELD = {
  id: FIELD_IDS.DATA_TYPE,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Field Data Type'),
    label: __('Field Data Type'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const PAGE_LAYOUT_FIELD = {
  id: FIELD_IDS.PAGE_LAYOUT,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Page Layout'),
    label: __('Page Layout'),
    size: 10,
  },
};

const LIST_VIEW_ENABLED_FIELD = {
  id: FIELD_IDS.LIST_VIEW_ENABLED,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Add to standard list view'),
    defaultValue: false,
  },
};

const DETAILED_VIEW_ENABLED_FIELD = {
  id: FIELD_IDS.DETAILED_VIEW_ENABLED,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Add to standard detail view'),
    defaultValue: false,
  },
};

const TEXT_MIN_LENGTH_FIELD = {
  id: FIELD_IDS.TEXT_MIN_LENGTH,
  renderer: TextInput,
  renderOptions: {
    label: __('Min Text Length'),
    placeholder: __('Enter Min length of text '),
    required: true,
    validators: [isRequiredRule, isNumericRule],
  },
};

const TEXT_MAX_LENGTH_FIELD = {
  id: FIELD_IDS.TEXT_MAX_LENGTH,
  renderer: TextInput,
  renderOptions: {
    label: __('Max Text Length'),
    placeholder: __('Enter Max length of text'),
    required: true,
    validators: [isRequiredRule, isNumericRule],
  },
};

const TEXT_REGEX_FIELD = {
  id: FIELD_IDS.TEXT_REGEX,
  renderer: TextInput,
  renderOptions: {
    placeholder: __('Write Regex without / at start and end'),
    label: __('Pattern'),
    validators: [isRegexRule],
    helpText: __('Write Regex without / at start and end '),
  },
};

const LIST_INPUT_TABLE_FIELD = {
  id: FIELD_IDS.LIST_INPUT_TABLE,
  renderer: ListInputTable,
  renderOptions: {
    required: true,
    validators: [isRequiredRule],
  },
};

const LIST_ADD_ROW_BUTTON_FIELD = {
  id: FIELD_IDS.LIST_ADD_ROW_BUTTON,
  renderer: Button,
  renderOptions: {
    label: __('Add Option'),
    view: Button.VIEW.TERTIARY,
    className: styles.addRowButton,
  },
};

const COMPLEX_FIELD_ENTITY_FIELD = {
  id: FIELD_IDS.COMPLEX_FIELD_ENTITY,
  renderer: EntityAsyncSelect,
  renderOptions: {
    placeholder: __('Select Entity'),
    label: __('Entity'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
    noOptionsMessage: () => __('No other entity present, please add an entity to create a complex field.'),
  },
};

const ALLOW_LOOKUP_USING_FIELD = {
  id: FIELD_IDS.ALLOW_LOOKUP_USING_FIELD,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Allow Lookup using field?'),
  },
};
const LOOKUP_FIELD = {
  id: FIELD_IDS.LOOKUP_FIELD,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Lookup field'),
    label: __('Lookup field'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};
const LOOKUP_ENTITY_FIELD = {
  id: FIELD_IDS.LOOKUP_ENTITY,
  renderer: EntityAsyncSelect,
  renderOptions: {
    placeholder: __('Select Lookup Entity'),
    label: __('Lookup Entity'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};
const LOOKUP_DISPLAY_FIELD = {
  id: FIELD_IDS.LOOKUP_DISPLAY_FIELD,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select display field'),
    label: __('Lookup display field'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const CREATABLE_FIELD = {
  id: FIELD_IDS.CREATABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Is Field Creatable?'),
    defaultValue: false,
  },
};

const EDITABLE_FIELD = {
  id: FIELD_IDS.EDITABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Is Field Editable?'),
    defaultValue: false,
  },
};

const SEARCHABLE_FIELD = {
  id: FIELD_IDS.SEARCHABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Is Field Searchable?'),
    defaultValue: false,
  },
};

const FILTERABLE_FIELD = {
  id: FIELD_IDS.FILTERABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Is Field Filterable?'),
    defaultValue: false,
  },
};

const SORTABLE_FIELD = {
  id: FIELD_IDS.SORTABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Is Field Sortable?'),
    defaultValue: false,
  },
};

const SORT_DIRECTION_FIELD = {
  id: FIELD_IDS.SORT_DIRECTION,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Sort Direction'),
    label: __('Sort Direction'),
    size: 6,
  },
};

const IMPORTABLE_FIELD = {
  id: FIELD_IDS.IMPORTABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Is Field Importable?'),
    defaultValue: false,
  },
};

const EXPORTABLE_FIELD = {
  id: FIELD_IDS.EXPORTABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Is Field Exportable?'),
    defaultValue: false,
  },
};

const MANDATORY_FIELD = {
  id: FIELD_IDS.MANDATORY,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Is Field Mandatory?'),
    defaultValue: false,
  },
};

const DISABLED_FIELD = {
  id: FIELD_IDS.DISABLED,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Is Field Disabled?'),
    defaultValue: false,
  },
};

const UNIQUE_CONSTRAINT_FIELD = {
  id: FIELD_IDS.UNIQUE_CONSTRAINT,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Is Unique field'),
    defaultValue: false,
  },
};

const AUDIT_ENABLED_FIELD = {
  id: FIELD_IDS.AUDIT_ENABLED,
  renderer: CheckboxRenderer,
  renderOptions: {
    label: __(''),
    checkboxLabel: __('Enable audit'),
    defaultValue: false,
  },
};

const HELP_TEXT_FIELD = {
  id: FIELD_IDS.HELP_TEXT,
  renderer: TextInput,
  renderOptions: {
    label: __('Help Text'),
    placeholder: __('Enter Help Text'),
  },
};

const DEPENDENCY_CONFIG_TABLE_FIELD = {
  id: FIELD_IDS.DEPENDENCY_CONFIG,
  renderer: DependencyConfigTable,
  renderOptions: {},
};

const GROUPS_ALLOWED = {
  id: FIELD_IDS.GROUPS_ALLOWED,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: __('Groups Allowed'),
  },
};

const CHILD_ENTITY_NAME_FIELD = {
  id: FIELD_IDS.CHILD_ENTITY_NAME,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select child entity'),
    label: __('Child Entity'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const CHILD_FIELD_NAME_FILED = {
  id: FIELD_IDS.CHILD_FIELD,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select field'),
    label: __('Field To Aggregate'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const CHILD_RELATION_FIELD = {
  id: FIELD_IDS.CHILD_RELATION_FIELD,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Interlinking field'),
    label: __('Interlinking Field'),
    size: 5,
    required: true,
    validators: [isRequiredRule],
  },
};

const ROLL_UP_FUNCTION_FIELD = {
  id: FIELD_IDS.ROLL_UP_FUNCTION,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select function'),
    label: __('Child Aggregate Function'),
    size: 5,
    required: true,
    validators: [isRequiredRule],
    options: ROLL_UP_OPTIONS,
  },
};

const FILTER_CRITERIA_FOR_CHILD_FIELD = {
  id: FIELD_IDS.FILTER_CRITERIA_FOR_CHILD,
  renderer: Radio,
  renderOptions: {
    validators: [isRequiredRule],
    required: true,
    radios: FILTER_CRITERIA_FOR_CHILD_OPTIONS,
  },
};

const FILTER_CRITERIA_FOR_PARENT_FIELD = {
  id: FIELD_IDS.FILTER_CRITERIA_FOR_PARENT,
  renderer: Radio,
  renderOptions: {
    validators: [isRequiredRule],
    required: true,
    radios: FILTER_CRITERIA_FOR_PARENT_OPTIONS,
  },
};

const CONTROLLING_FUNCTION_ENABLED_FIELD = {
  id: FIELD_IDS.CONTROLLING_FUNCTION,
  renderer: SwitchRenderer,
  renderOptions: {
    label: __('Controlling Field Settings'),
    defaultValue: false,
    helpText: __('Are the options controlled by the selected option of another field.'),
    infoBadgeClassName: styles.controllingSettingsInfoIcon,
  },
};

const CONTROLLING_FIELD_NAME_FIELD = {
  id: FIELD_IDS.CONTROLLING_FIELD_NAME,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Controlling Field'),
    label: __('Controlling field'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const CONTROLLING_OPTIONS_CONFIGS_FIELD = {
  id: FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS,
  renderer: ControllingOptionConfigTable,
  renderOptions: {
    required: true,
    validators: [isRequiredRule],
  },
};

const MULTI_VALUED_ENABLED_FIELD = {
  id: FIELD_IDS.MULTI_VALUED_ENABLED,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: __('Multi Valued'),
    defaultValue: false,
  },
};

const TEST_REGEX_INPUT_STRING_FIELD = {
  id: FIELD_IDS.TEST_REGEX_INPUT_STRING,
  renderer: TextInput,
  renderOptions: {
    label: __('String to test your regex'),
    placeholder: __('Enter string'),
  },
};

const SELECT_TEMPLATE_REGEX_FIELD = {
  id: FIELD_IDS.SELECT_TEMPLATE_REGEX,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select template for regex'),
    label: __('Template for regex'),
  },
};

const PLACEHOLDER_FIELD = {
  id: FIELD_IDS.PLACEHOLDER,
  renderer: TextInput,
  renderOptions: {
    label: __('Placeholder'),
    placeholder: __('Enter placeholder'),
  },
};

export const FORM_FIELDS = {
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.FIELD_TYPE]: FIELD_TYPE_FIELD,
  [FIELD_IDS.DATA_TYPE]: DATA_TYPE_FIELD,
  [FIELD_IDS.PAGE_LAYOUT]: PAGE_LAYOUT_FIELD,
  [FIELD_IDS.LIST_VIEW_ENABLED]: LIST_VIEW_ENABLED_FIELD,
  [FIELD_IDS.DETAILED_VIEW_ENABLED]: DETAILED_VIEW_ENABLED_FIELD,
  [FIELD_IDS.TEXT_MIN_LENGTH]: TEXT_MIN_LENGTH_FIELD,
  [FIELD_IDS.TEXT_MAX_LENGTH]: TEXT_MAX_LENGTH_FIELD,
  [FIELD_IDS.TEXT_REGEX]: TEXT_REGEX_FIELD,
  [FIELD_IDS.COMPLEX_FIELD_ENTITY]: COMPLEX_FIELD_ENTITY_FIELD,
  [FIELD_IDS.LIST_INPUT_TABLE]: LIST_INPUT_TABLE_FIELD,
  [FIELD_IDS.LIST_ADD_ROW_BUTTON]: LIST_ADD_ROW_BUTTON_FIELD,
  [FIELD_IDS.ALLOW_LOOKUP_USING_FIELD]: ALLOW_LOOKUP_USING_FIELD,
  [FIELD_IDS.LOOKUP_ENTITY]: LOOKUP_ENTITY_FIELD,
  [FIELD_IDS.LOOKUP_FIELD]: LOOKUP_FIELD,
  [FIELD_IDS.LOOKUP_DISPLAY_FIELD]: LOOKUP_DISPLAY_FIELD,
  [FIELD_IDS.CREATABLE]: CREATABLE_FIELD,
  [FIELD_IDS.EDITABLE]: EDITABLE_FIELD,
  [FIELD_IDS.SEARCHABLE]: SEARCHABLE_FIELD,
  [FIELD_IDS.FILTERABLE]: FILTERABLE_FIELD,
  [FIELD_IDS.SORTABLE]: SORTABLE_FIELD,
  [FIELD_IDS.SORT_DIRECTION]: SORT_DIRECTION_FIELD,
  [FIELD_IDS.IMPORTABLE]: IMPORTABLE_FIELD,
  [FIELD_IDS.EXPORTABLE]: EXPORTABLE_FIELD,
  [FIELD_IDS.MANDATORY]: MANDATORY_FIELD,
  [FIELD_IDS.DISABLED]: DISABLED_FIELD,
  [FIELD_IDS.UNIQUE_CONSTRAINT]: UNIQUE_CONSTRAINT_FIELD,
  [FIELD_IDS.AUDIT_ENABLED]: AUDIT_ENABLED_FIELD,
  // [FIELD_IDS.MULTI_LINGUAL_INPUT_ENABLED]: MULTI_LINGUAL_INPUT_ENABLED_FIELD,
  // [FIELD_IDS.MULTI_LINGUAL_KEY]: MULTI_LINGUAL_KEY_FIELD,
  [FIELD_IDS.HELP_TEXT]: HELP_TEXT_FIELD,
  [FIELD_IDS.DEPENDENCY_CONFIG]: DEPENDENCY_CONFIG_TABLE_FIELD,
  [FIELD_IDS.GROUPS_ALLOWED]: GROUPS_ALLOWED,
  [FIELD_IDS.CHILD_ENTITY_NAME]: CHILD_ENTITY_NAME_FIELD,
  [FIELD_IDS.CHILD_FIELD]: CHILD_FIELD_NAME_FILED,
  [FIELD_IDS.ROLL_UP_FUNCTION]: ROLL_UP_FUNCTION_FIELD,
  [FIELD_IDS.CHILD_RELATION_FIELD]: CHILD_RELATION_FIELD,
  [FIELD_IDS.FILTER_CRITERIA_FOR_CHILD]: FILTER_CRITERIA_FOR_CHILD_FIELD,
  [FIELD_IDS.FILTER_CRITERIA_FOR_PARENT]: FILTER_CRITERIA_FOR_PARENT_FIELD,
  [FIELD_IDS.CONTROLLING_FUNCTION]: CONTROLLING_FUNCTION_ENABLED_FIELD,
  [FIELD_IDS.CONTROLLING_FIELD_NAME]: CONTROLLING_FIELD_NAME_FIELD,
  [FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS]: CONTROLLING_OPTIONS_CONFIGS_FIELD,
  [FIELD_IDS.MULTI_VALUED_ENABLED]: MULTI_VALUED_ENABLED_FIELD,
  [FIELD_IDS.SELECT_TEMPLATE_REGEX]: SELECT_TEMPLATE_REGEX_FIELD,
  [FIELD_IDS.TEST_REGEX_INPUT_STRING]: TEST_REGEX_INPUT_STRING_FIELD,
  [FIELD_IDS.PLACEHOLDER]: PLACEHOLDER_FIELD,
};
