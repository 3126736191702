import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import _size from 'lodash/size';
import _slice from 'lodash/slice';
import _filter from 'lodash/filter';
import _head from 'lodash/head';
import _includes from 'lodash/includes';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

// Tekion-components
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import Avatar from '@tekion/tekion-components/atoms/Avatar';
import Menu from '@tekion/tekion-components/molecules/Menu';
import DropDown, { DROPDOWN_TRIGGER } from '@tekion/tekion-components/molecules/DropDown';

// Readers
import USER_READER from '../../../../readers/loginResponse.reader';
import LOCAL_STORAGE_READER from '../../../../readers/localStorage.reader';

// Constants
import { USER_AVATAR_WORKSPACE_SEE_ALL } from '../../constants/appSkeleton.general';

// Styles
import styles from './userAvatar.module.scss';

const UserAvatar = (props) => {
  const { menuOptions, workSpaceOptions, onMenuItemClick, onSubMenuWorkSpaceItemClick, onWorkSpaceModalVisibilityChange } = props;

  const homeWorkspaceId = useMemo(() => USER_READER.homeWorkspaceId(LOCAL_STORAGE_READER.userInfo()), []);

  const homeWorkspaceData = useMemo(
    () => _head(_filter(workSpaceOptions, (workspace) => _get(workspace, 'workspaceId') === homeWorkspaceId)) || EMPTY_OBJECT,
    [workSpaceOptions, homeWorkspaceId],
  );

  const subMenuWorkSpaceOptions = useMemo(
    () =>
      _slice(
        _filter(
          workSpaceOptions,
          (workSpace) => _get(workSpace, 'workspaceId') !== homeWorkspaceId && !_includes(_get(workSpace, 'workspaceId', EMPTY_STRING), 'universe'),
        ),
        0,
        4,
      ),
    [homeWorkspaceId, workSpaceOptions],
  );

  const onClick = useCallback(
    (item) => {
      const keyPathSize = _size(_get(item, 'keyPath', EMPTY_ARRAY));
      const key = _get(item, 'key', EMPTY_STRING);
      if (keyPathSize >= 2) {
        if (key === USER_AVATAR_WORKSPACE_SEE_ALL) {
          onWorkSpaceModalVisibilityChange();
        } else {
          onSubMenuWorkSpaceItemClick(key);
        }
      } else {
        onMenuItemClick(key);
      }
    },
    [onMenuItemClick, onSubMenuWorkSpaceItemClick, onWorkSpaceModalVisibilityChange],
  );

  const getWorkspaceDisplayName = useCallback((workspace) => tget(workspace, 'workspaceName', NO_DATA), []);

  const getWorkspaceOptions = useCallback(
    () =>
      _map(subMenuWorkSpaceOptions, (item) => (
        <Menu.Item key={_get(item, 'workspaceId', EMPTY_STRING)}>{__(getWorkspaceDisplayName(item))}</Menu.Item>
      )),
    [subMenuWorkSpaceOptions, getWorkspaceDisplayName],
  );

  const getMenuOptions = useCallback(
    () => _map(menuOptions, (item) => <Menu.Item key={_get(item, 'value', EMPTY_STRING)}>{__(_get(item, 'label', NO_DATA))}</Menu.Item>),
    [menuOptions],
  );

  const renderPopoverContent = useCallback(
    () => (
      <Menu onClick={onClick} className={styles.menu}>
        {getMenuOptions()}
        <Menu.SubMenu title="All workspaces">
          {!_includes(homeWorkspaceId, 'universe') && (
            <Menu.Item key={homeWorkspaceId} className={styles.homeWorkSpace}>
              <div>{__(getWorkspaceDisplayName(homeWorkspaceData))}</div>
              <FontIcon style={{ marginLeft: '1rem' }}> icon-home1</FontIcon>
            </Menu.Item>
          )}
          {getWorkspaceOptions()}
          {_size(workSpaceOptions) > 4 && <Menu.Item key={USER_AVATAR_WORKSPACE_SEE_ALL}>See all</Menu.Item>}
        </Menu.SubMenu>
      </Menu>
    ),
    [onClick, getMenuOptions, homeWorkspaceId, getWorkspaceDisplayName, homeWorkspaceData, getWorkspaceOptions, workSpaceOptions],
  );

  const userName = useMemo(() => USER_READER.name(LOCAL_STORAGE_READER.userInfo()), []);

  const currentWorkspaceName = useMemo(() => {
    const workspaceId = USER_READER.workspaceId(LOCAL_STORAGE_READER.userInfo());
    const currentWorkspaceData = _head(_filter(workSpaceOptions, (workspace) => workspaceId === _get(workspace, 'workspaceId', EMPTY_STRING)));
    return getWorkspaceDisplayName(currentWorkspaceData);
  }, [workSpaceOptions, getWorkspaceDisplayName]);

  return (
    <DropDown overlay={renderPopoverContent()} trigger={[DROPDOWN_TRIGGER.CLICK]}>
      <div className={styles.container}>
        <Avatar size={30} name={__(userName)} secondaryName={__(currentWorkspaceName)} />
        <FontIcon className={styles.downIcon}>icon-chevron-down</FontIcon>
      </div>
    </DropDown>
  );
};

UserAvatar.propTypes = {
  menuOptions: PropTypes.array,
  workSpaceOptions: PropTypes.array,
  onMenuItemClick: PropTypes.func.isRequired,
  onSubMenuWorkSpaceItemClick: PropTypes.func,
  onWorkSpaceModalVisibilityChange: PropTypes.func,
};

UserAvatar.defaultProps = {
  menuOptions: EMPTY_ARRAY,
  workSpaceOptions: EMPTY_ARRAY,
  onSubMenuWorkSpaceItemClick: _noop,
  onWorkSpaceModalVisibilityChange: _noop,
};

export default UserAvatar;
