import _get from 'lodash/get';
import _set from 'lodash/set';
import _find from 'lodash/find';
import _reduce from 'lodash/reduce';
import _castArray from 'lodash/castArray';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import { defaultMemoize } from 'reselect';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, NO_DATA } from '@tekion/tekion-base/app.constants';

import { CONTROLLING_OPTION_TABLE_COLUMN_IDS } from '../components/controllingOptionConfigTable/constants/controllingOptionConfigTable.general';
import { COLUMN_IDS, MODAL_INPUT_FIELD } from '../components/listInputTable/constants/listInputTable.constants';
import SELECT_OPTIONS_FORM_FIELD_IDS from '../constants/selectOptionsForm.fieldsIds';
import FIELD_TYPES from '../../../../../../../../constants/fieldDefinition.fieldTypes';

import fieldDefinitionReader from '../../../../../../../../readers/fieldDefinition.reader';

const getControllingFieldOptions = defaultMemoize((entity) =>
  _map(
    _filter(_get(entity, 'fieldDefinitions', EMPTY_ARRAY), (item) => _get(item, 'fieldType') === FIELD_TYPES.SELECT),
    (fieldDefinition) => ({
      label: fieldDefinitionReader.displayName(fieldDefinition),
      value: fieldDefinitionReader.name(fieldDefinition),
    }),
  ),
);

const getValuesOptions = defaultMemoize((entity, fieldName) => {
  const fieldDef = _find(
    _get(entity, 'fieldDefinitions', EMPTY_ARRAY),
    (field) => fieldDefinitionReader.name(field) === getArraySafeValue(fieldName),
  );

  const valueOptions = _get(fieldDef, 'optionConfig.options', []);

  return _map(valueOptions, (item) => ({
    label: tget(item, COLUMN_IDS.LABEL, NO_DATA),
    value: _get(item, COLUMN_IDS.VALUE),
  }));
});

const getSelectOptionsFormValues = (fieldsFormSelectOptionsValues) => {
  const options = tget(fieldsFormSelectOptionsValues, SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS);
  const controllingField = _get(fieldsFormSelectOptionsValues, SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD);
  const controlledOptions = _get(fieldsFormSelectOptionsValues, SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS);

  return {
    [SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS]: options,
    [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD_ENABLED]: !_isEmpty(controllingField),
    ...(!_isEmpty(controllingField) && { [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD]: _castArray(controllingField) }),
    ...(!_isEmpty(controlledOptions) && {
      [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS]: _map(controlledOptions, (option) =>
        _reduce(CONTROLLING_OPTION_TABLE_COLUMN_IDS, (acc, columnId) => _set(acc, columnId, _castArray(_get(option, columnId, ''))), {}),
      ),
    }),
  };
};

const getFieldsFormSelectOptionsValues = (values) => {
  const options = tget(values, SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS);
  const controllingField = _get(values, SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD);
  const controlledOptions = _get(values, SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS);
  const isControllingFieldEnabled = tget(values, SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD_ENABLED, false);

  return {
    [SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS]: options,
    ...(isControllingFieldEnabled && {
      [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS]: _map(controlledOptions, (option) => ({
        [CONTROLLING_OPTION_TABLE_COLUMN_IDS.OPTION]: getArraySafeValue(_get(option, CONTROLLING_OPTION_TABLE_COLUMN_IDS.OPTION)),
        [CONTROLLING_OPTION_TABLE_COLUMN_IDS.VALUES]: _get(option, CONTROLLING_OPTION_TABLE_COLUMN_IDS.VALUES),
      })),
    }),
    ...(isControllingFieldEnabled &&
      !_isEmpty(controllingField) && { [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD]: getArraySafeValue(controllingField) }),
  };
};

const getSaveListOptionPayload = defaultMemoize((rowData) => {
  const value = _get(rowData, MODAL_INPUT_FIELD.VALUE, '');
  const payload = {
    fieldOption: {
      [MODAL_INPUT_FIELD.LABEL]: tget(rowData, MODAL_INPUT_FIELD.LABEL, ''),
      [MODAL_INPUT_FIELD.VALUE]: value,
    },
    [MODAL_INPUT_FIELD.CONTROLLING_OPTIONS]: _get(rowData, MODAL_INPUT_FIELD.CONTROLLING_OPTIONS),
  };

  return { value, payload };
});

export { getControllingFieldOptions, getValuesOptions, getSelectOptionsFormValues, getFieldsFormSelectOptionsValues, getSaveListOptionPayload };
