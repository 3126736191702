// Constants
import WORKFLOW_BUILDER_MODAL_TYPES from './workflowBuilder.modalTypes';
import { TASK_DEF_IDS } from './workflow.constants';

const TASK_DEF_ID_TO_MODAL_TYPE = {
  [TASK_DEF_IDS.IF]: WORKFLOW_BUILDER_MODAL_TYPES.CONDITION,
  [TASK_DEF_IDS.IF_ELSE]: WORKFLOW_BUILDER_MODAL_TYPES.CONDITION,
  [TASK_DEF_IDS.CREATE_RECORD]: WORKFLOW_BUILDER_MODAL_TYPES.ACTION,
  [TASK_DEF_IDS.UPDATE_RECORD]: WORKFLOW_BUILDER_MODAL_TYPES.ACTION,
  [TASK_DEF_IDS.GET_RECORDS]: WORKFLOW_BUILDER_MODAL_TYPES.ACTION,
  [TASK_DEF_IDS.UPSERT_RECORD]: WORKFLOW_BUILDER_MODAL_TYPES.ACTION,
  [TASK_DEF_IDS.DELAY_TASK]: WORKFLOW_BUILDER_MODAL_TYPES.DELAY_TASK,
  [TASK_DEF_IDS.SWITCH]: WORKFLOW_BUILDER_MODAL_TYPES.CHECK_FIELD,
  [TASK_DEF_IDS.REPEAT_NODE]: WORKFLOW_BUILDER_MODAL_TYPES.REPEAT_NODE,
  [TASK_DEF_IDS.GOTO]: WORKFLOW_BUILDER_MODAL_TYPES.REPEAT_NODE,
  [TASK_DEF_IDS.TIME_OF_THE_DAY]: WORKFLOW_BUILDER_MODAL_TYPES.TIME_OF_THE_DAY,
  [TASK_DEF_IDS.SWITCH_WORKFLOW]: WORKFLOW_BUILDER_MODAL_TYPES.SWITCH_WORKFLOW,
  [TASK_DEF_IDS.SET_VARIABLE]: WORKFLOW_BUILDER_MODAL_TYPES.SET_VARIABLE,
  [TASK_DEF_IDS.SEND_EMAIL]: WORKFLOW_BUILDER_MODAL_TYPES.ACTION,
  [TASK_DEF_IDS.PUSH_NOTIFICATION]: WORKFLOW_BUILDER_MODAL_TYPES.PUSH_NOTIFICATION,
  [TASK_DEF_IDS.CALLOUT]: WORKFLOW_BUILDER_MODAL_TYPES.CALLOUT,
  [TASK_DEF_IDS.FETCH_AND_ITERATE]: WORKFLOW_BUILDER_MODAL_TYPES.FETCH_AND_ITERATE,
  [TASK_DEF_IDS.FOR_LOOP]: WORKFLOW_BUILDER_MODAL_TYPES.FOR_LOOP,
};

export default TASK_DEF_ID_TO_MODAL_TYPE;
