import _get from 'lodash/get';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _has from 'lodash/has';
import _find from 'lodash/find';
import _unset from 'lodash/unset';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _forEach from 'lodash/forEach';
import _isNil from 'lodash/isNil';
import _tail from 'lodash/last';
import _split from 'lodash/split';
import _isEqual from 'lodash/isEqual';
import _toLower from 'lodash/toLower';
import _includes from 'lodash/includes';
import _remove from 'lodash/remove';
import _cloneDeep from 'lodash/cloneDeep';

import { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget, uuid } from '@tekion/tekion-base/utils/general';

import BlankWidgetContainer from '../atoms/blankWidgetContainer';

import { pascalCase } from '../../../helpers/general.helpers';

import { mapWidgetByType, mapWidgetByViewType } from '../constants/visualBuilder.widgetMapper';
import { PAGE_CONFIG_IDS, VIEW_OVERRIDE_CONFIG_IDS } from '../../../constants/visualBuilder';
import {
  COMPONENT_CONFIG_KEYS,
  INITIAL_PAGE_CONFIGURATION,
  PAGE_CONFIGURATION_FIELD_IDS,
  WIDGET_DEFAULT_PROPERTIES_VALUE,
  WIDGET_POSITIONS,
  WIDGET_TYPES,
} from '../constants/visualBuilder.general.constants';
import {
  BUTTON_WIDGET_PROPERTIES,
  CUSTOM_ACTIONS_PROPERTIES,
  GENERAL_PROPERTIES,
  PARENT_WIDGET_PROPERTIES,
  SIMILAR_WIDGET_PROPERTIES,
  TAB_WIDGET_PROPERTIES,
  TEXT_WIDGET_PROPERTIES,
} from '../constants/visualBuilder.properties.constants';

import entityReader from '../../../readers/entity.reader';

import {
  deleteWidgetConfiguration,
  saveWidgetConfiguration,
  updatePageConfiguration,
  updateWidgetConfiguration,
} from '../../../actions/visualBuilder.actions';

const getWidgetReference = ({ componentType, viewType }) => {
  if (
    componentType === WIDGET_TYPES.PARENT_WIDGET ||
    componentType === WIDGET_TYPES.CHILD_WIDGET ||
    componentType === WIDGET_TYPES.SIMILAR_WIDGET ||
    componentType === WIDGET_TYPES.STANDARD_WIDGET
  ) {
    if (_isEmpty(viewType)) {
      return BlankWidgetContainer;
    } else {
      return mapWidgetByViewType(viewType);
    }
  }

  return mapWidgetByType(componentType);
};

const getUpdatedWidgetConfiguration = (widgetName, widgets, value) => {
  _forEach(widgets, (widget, index) => {
    if (tget(widget, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING) === widgetName) {
      _set(widgets, index, value);
    } else {
      getUpdatedWidgetConfiguration(widgetName, tget(widget, COMPONENT_CONFIG_KEYS.CHILDREN, EMPTY_ARRAY), value);
    }
  });
};

const getUpdatedPageConfiguration = (widgetName, pageConfiguration, value) => {
  if (widgetName === PAGE_CONFIGURATION_FIELD_IDS.CONTAINERS) {
    _set(pageConfiguration, widgetName, value);
  } else {
    const containers = tget(pageConfiguration, PAGE_CONFIGURATION_FIELD_IDS.CONTAINERS, EMPTY_ARRAY);
    getUpdatedWidgetConfiguration(widgetName, containers, value);
  }
  return pageConfiguration;
};

const addNewContainerToConfiguration = (pageConfiguration) => {
  let containers = tget(pageConfiguration, PAGE_CONFIGURATION_FIELD_IDS.CONTAINERS, EMPTY_ARRAY);
  containers = [
    ...containers,
    {
      widgetName: `${_toLower(WIDGET_TYPES.CONTAINER)}_${_tail(_split(uuid(), '-'))}`,
      widgetType: WIDGET_TYPES.CONTAINER,
      properties: {
        widgetPosition: WIDGET_POSITIONS.VERTICAL,
        scrollEnabled: false,
        width: '20',
        layout: {
          row: 0,
          column: 0,
          width: 20,
          height: 20,
        },
      },
      children: [],
    },
  ];

  return { ...pageConfiguration, containers };
};

const getLayouts = (pageConfiguration) => {
  const containers = tget(pageConfiguration, PAGE_CONFIGURATION_FIELD_IDS.CONTAINERS, EMPTY_ARRAY);
  return _map(containers, (container) => ({
    i: tget(container, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING),
    w: Number(tget(container, 'properties.layout.width', 2)),
    h: Number(tget(container, 'properties.layout.height', 2)),
    x: Number(tget(container, 'properties.layout.row', 0)),
    y: Number(tget(container, 'properties.layout.column', 0)),
  }));
};

const getFlattenProperties = (properties) =>
  _reduce(
    properties,
    (result, property) => {
      const type = _get(property, 'type');
      const value = _get(property, 'value');
      if (!_isEmpty(type)) {
        return { ...result, [type]: value };
      }
      return result;
    },
    {},
  );

const getComponentWithFlattenProperties = (component) => {
  const flattenProperties = getFlattenProperties(tget(component, 'properties', EMPTY_ARRAY));
  const children = tget(component, COMPONENT_CONFIG_KEYS.CHILDREN, EMPTY_ARRAY);
  let updatedChildren = [];
  if (!_isEmpty(children)) {
    updatedChildren = _map(children, (child) => getComponentWithFlattenProperties(child));
  }

  return { ...component, properties: flattenProperties, children: updatedChildren };
};

const getPageConfigWithFlattenProperties = (pageConfig) => {
  const containers = tget(pageConfig, PAGE_CONFIGURATION_FIELD_IDS.CONTAINERS, EMPTY_ARRAY);
  const updatedContainers = _map(containers, (container) => getComponentWithFlattenProperties(container));

  return { ...pageConfig, properties: getFlattenProperties(tget(pageConfig, 'properties')), containers: updatedContainers };
};

const getPropertiesOfApiFormat = (properties) =>
  _reduce(properties, (result, propertyValue, propertyType) => [...result, { type: propertyType, value: propertyValue }], []);

// const mapComponentToApiFormat = (component) => {
//   const apiFormatProperties = getPropertiesOfApiFormat(tget(component, 'properties', EMPTY_ARRAY));
//   const children = tget(component, COMPONENT_CONFIG_KEYS.CHILDREN, EMPTY_ARRAY);
//   let updatedChildren = [];
//   if (!_isEmpty(children)) {
//     updatedChildren = _map(children, (child) => mapComponentToApiFormat(child));
//   }

//   return { ...component, properties: apiFormatProperties, children: updatedChildren };
// };

const getPageConfigurationOnDrop = (widgetName, widgets, value, pageConfiguration, widgetIndex) => {
  _forEach(widgets, (widget) => {
    if (tget(widget, COMPONENT_CONFIG_KEYS.WIDGET_NAME, '') === widgetName) {
      const children = tget(widget, COMPONENT_CONFIG_KEYS.CHILDREN, []);
      children.splice(widgetIndex, 0, value);
      _set(widget, 'children', [...children]);
    } else {
      getPageConfigurationOnDrop(widgetName, tget(widget, COMPONENT_CONFIG_KEYS.CHILDREN, []), value, pageConfiguration, widgetIndex);
    }
  });
  return pageConfiguration;
};

const getPageConfigurationOnDeleteWidget = (widgetName, widgets, pageConfiguration, widgetIndex) => {
  _forEach(widgets, (widget, index) => {
    if (tget(widget, COMPONENT_CONFIG_KEYS.WIDGET_NAME, '') === widgetName) {
      widgets.splice(index, 1);
    } else {
      getPageConfigurationOnDeleteWidget(widgetName, tget(widget, COMPONENT_CONFIG_KEYS.CHILDREN, []), pageConfiguration, widgetIndex);
    }
  });
  return pageConfiguration;
};

const getDefaultPropertiesForGivenWidgetType = (widgetType, pageEntity, defaultNumber) => {
  const properties = {};
  if (!_isNil(defaultNumber)) _set(properties, GENERAL_PROPERTIES.WIDGET_NAME, `${pascalCase(widgetType)} - ${defaultNumber}`);

  switch (widgetType) {
    case WIDGET_TYPES.BUTTON_WIDGET: {
      _set(properties, BUTTON_WIDGET_PROPERTIES.BUTTON_VIEW_TYPE, WIDGET_DEFAULT_PROPERTIES_VALUE.BUTTON_VIEW_TYPE);
      _set(properties, BUTTON_WIDGET_PROPERTIES.BUTTON_TEXT, __('Please add Text'));
      break;
    }

    case WIDGET_TYPES.TAB_WIDGET: {
      _set(properties, TAB_WIDGET_PROPERTIES.TAB_POSITION, WIDGET_DEFAULT_PROPERTIES_VALUE.TAB_POSITION);
      _set(properties, TAB_WIDGET_PROPERTIES.TAB_SIZE, WIDGET_DEFAULT_PROPERTIES_VALUE.TAB_SIZE);
      _set(properties, TAB_WIDGET_PROPERTIES.TAB_NAMES, [{ name: __('Tab Name') }]);
      break;
    }

    case WIDGET_TYPES.TEXT_WIDGET: {
      _set(properties, TEXT_WIDGET_PROPERTIES.LABEL, __('Please add Text'));
      _set(properties, TEXT_WIDGET_PROPERTIES.SIZE, 2);
      break;
    }

    case WIDGET_TYPES.SIMILAR_WIDGET: {
      _set(properties, SIMILAR_WIDGET_PROPERTIES.ENTITY_NAME, entityReader.name(pageEntity));
      break;
    }

    case WIDGET_TYPES.PARENT_WIDGET: {
      _set(properties, PARENT_WIDGET_PROPERTIES.CHILD_ENTITY_NAME, entityReader.name(pageEntity));
      break;
    }

    default: {
      break;
    }
  }

  return properties;
};

const modifyCustomStylesObject = (customStyles) =>
  _reduce(
    customStyles,
    (result, value, key) => {
      result.push({ type: key, value });
      return result;
    },
    [],
  );

const modifyPageConfiguration = (containers, newPageConfiguration, children) => {
  _forEach(containers, (container) => {
    const widgetType = _get(container, COMPONENT_CONFIG_KEYS.WIDGET_TYPE, EMPTY_STRING);
    const widgetName = _get(container, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING);
    const properties = _get(container, COMPONENT_CONFIG_KEYS.PROPERTIES, {});

    const widgetDisplayName = _get(properties, GENERAL_PROPERTIES.WIDGET_NAME, widgetType);
    _unset(properties, GENERAL_PROPERTIES.WIDGET_NAME);
    const layoutDetails = _get(properties, PAGE_CONFIG_IDS.WIDGET_LAYOUT, {});
    const eventHandlersDetails = _get(properties, PAGE_CONFIG_IDS.PAGE_EVENT_HANDLERS, EMPTY_ARRAY);
    const customStylesDetails = _get(properties, COMPONENT_CONFIG_KEYS.CUSTOM_STYLES, []);

    const widgets = _get(newPageConfiguration, PAGE_CONFIG_IDS.WIDGETS, []);

    if (!_isEmpty(customStylesDetails)) {
      const modifiedCustomStylesDetails = modifyCustomStylesObject(customStylesDetails);
      const customStyles = _get(newPageConfiguration, COMPONENT_CONFIG_KEYS.CUSTOM_STYLES, []);

      customStyles.push({
        [PAGE_CONFIG_IDS.WIDGET_NAME]: widgetName,
        [PAGE_CONFIG_IDS.WIDGET_CUSTOM_STYLE]: modifiedCustomStylesDetails,
      });
    }

    if (widgetType === WIDGET_TYPES.CONTAINER) {
      const layouts = _get(newPageConfiguration, PAGE_CONFIG_IDS.PAGE_LAYOUTS, []);
      if (!_isEmpty(layoutDetails)) {
        layouts.push({ widgetName, ...layoutDetails });
      }
    } else {
      const eventHandlers = _get(newPageConfiguration, PAGE_CONFIG_IDS.PAGE_EVENT_HANDLERS, []);

      if (!_isEmpty(eventHandlersDetails)) {
        eventHandlers.push({
          [PAGE_CONFIG_IDS.WIDGET_NAME]: widgetName,
          [PAGE_CONFIG_IDS.PAGE_EVENT_HANDLERS]: eventHandlersDetails,
        });
      }
    }

    if (_has(properties, CUSTOM_ACTIONS_PROPERTIES.SHOW_ACTIONS)) {
      const showActions = _get(properties, CUSTOM_ACTIONS_PROPERTIES.SHOW_ACTIONS);
      const actionNames = _get(properties, CUSTOM_ACTIONS_PROPERTIES.ACTION_NAMES, []);
      const viewOverrideConfig = _get(properties, VIEW_OVERRIDE_CONFIG_IDS.VIEW_OVERRIDE_CONFIG, {});

      const newViewOverrideConfig = {
        ...viewOverrideConfig,
        [VIEW_OVERRIDE_CONFIG_IDS.SHOW_ACTIONS]: showActions,
        [VIEW_OVERRIDE_CONFIG_IDS.ACTION_NAMES]: actionNames,
      };
      _set(properties, VIEW_OVERRIDE_CONFIG_IDS.VIEW_OVERRIDE_CONFIG, newViewOverrideConfig);
      _unset(properties, CUSTOM_ACTIONS_PROPERTIES.SHOW_ACTIONS);
      _unset(properties, CUSTOM_ACTIONS_PROPERTIES.ACTION_IDS);
    }

    if (_has(properties, COMPONENT_CONFIG_KEYS.PRE_APPLIED_FILTERS)) {
      const preAppliedFilters = _get(properties, COMPONENT_CONFIG_KEYS.PRE_APPLIED_FILTERS, []);
      const viewOverrideConfig = _get(properties, VIEW_OVERRIDE_CONFIG_IDS.VIEW_OVERRIDE_CONFIG, {});

      const newViewOverrideConfig = { ...viewOverrideConfig, [VIEW_OVERRIDE_CONFIG_IDS.PRE_APPLIED_FILTERS]: preAppliedFilters };
      _set(properties, VIEW_OVERRIDE_CONFIG_IDS.VIEW_OVERRIDE_CONFIG, newViewOverrideConfig);
      _unset(properties, COMPONENT_CONFIG_KEYS.PRE_APPLIED_FILTERS);
    }

    _unset(properties, PAGE_CONFIG_IDS.PAGE_EVENT_HANDLERS);
    _unset(properties, COMPONENT_CONFIG_KEYS.CUSTOM_STYLES);
    _unset(properties, PAGE_CONFIG_IDS.PAGE_DISPLAY_NAME);
    _unset(properties, PAGE_CONFIG_IDS.WIDGET_LAYOUT);

    widgets.push({
      [PAGE_CONFIG_IDS.PAGE_NAME]: widgetName,
      [PAGE_CONFIG_IDS.PAGE_DISPLAY_NAME]: widgetDisplayName,
      [COMPONENT_CONFIG_KEYS.PROPERTIES]: { ...properties, widgetType },
    });

    const child = [];
    children.push({ [PAGE_CONFIG_IDS.WIDGET_NAME]: widgetName, [PAGE_CONFIG_IDS.WIDGET_CHILDREN]: child });

    const childContainers = _get(container, COMPONENT_CONFIG_KEYS.CHILDREN, EMPTY_ARRAY);
    modifyPageConfiguration(childContainers, newPageConfiguration, child);
  });
};

const modifyPageConfigurationToApiFormat = (pageConfiguration) => {
  const containers = _get(pageConfiguration, PAGE_CONFIG_IDS.PAGE_CONTAINERS, EMPTY_ARRAY);
  const oldConfigContainers = tget(pageConfiguration, 'oldConfigContainers', []);
  _unset(pageConfiguration, 'oldConfigContainers');

  const children = [];
  const newPageConfiguration = { ...pageConfiguration, ..._cloneDeep(INITIAL_PAGE_CONFIGURATION) };

  modifyPageConfiguration(containers, newPageConfiguration, children);

  _set(newPageConfiguration, PAGE_CONFIG_IDS.PAGE_WIDGET_CONTAINERS, children);

  const properties = tget(newPageConfiguration, COMPONENT_CONFIG_KEYS.PROPERTIES, EMPTY_OBJECT);
  _set(newPageConfiguration, COMPONENT_CONFIG_KEYS.PROPERTIES, getPropertiesOfApiFormat(properties));
  _set(newPageConfiguration, PAGE_CONFIG_IDS.PAGE_CONTAINERS, oldConfigContainers);

  return newPageConfiguration;
};

const updatePageAndWidgetConfiguration = async (pageConfiguration, oldWidgetConfiguration) => {
  const modifiedPageConfiguration = modifyPageConfigurationToApiFormat(pageConfiguration);

  const pageName = tget(modifiedPageConfiguration, 'name');
  const newWidgets = tget(modifiedPageConfiguration, 'widgets', EMPTY_ARRAY);
  const newWidgetName = _reduce(
    newWidgets,
    (result, newWidget) => {
      const name = tget(newWidget, 'name', EMPTY_STRING);
      result.push(name);
      return result;
    },
    [],
  );

  const widgetsToBeDeleted = _remove(oldWidgetConfiguration, (widget) => !_includes(newWidgetName, tget(widget, 'name', EMPTY_STRING)));

  const widgetPromises = [];
  _forEach(newWidgets, (newWidget) => {
    const widgetName = tget(newWidget, 'name');
    const response = _find(oldWidgetConfiguration, (oldWidgets) => _get(oldWidgets, 'name') === _get(newWidget, 'name'));

    if (response) {
      const propertiesOfOldWidget = _get(response, 'properties');
      const propertiesOfNewWidget = _get(newWidget, 'properties');
      const widgetType = tget(propertiesOfNewWidget, 'widgetType');
      _unset(propertiesOfNewWidget, 'widgetType');

      if (!_isEqual(propertiesOfNewWidget, propertiesOfOldWidget)) {
        _set(propertiesOfNewWidget, 'widgetType', widgetType);
        widgetPromises.push(updateWidgetConfiguration(widgetName, newWidget));
      }

      _set(propertiesOfNewWidget, 'widgetType', widgetType);
    } else {
      widgetPromises.push(saveWidgetConfiguration(newWidget));
    }
  });

  _forEach(widgetsToBeDeleted, (widget) => {
    const widgetName = tget(widget, 'name');
    widgetPromises.push(deleteWidgetConfiguration(widgetName));
  });

  await Promise.all(widgetPromises);

  _unset(modifiedPageConfiguration, 'widgets');
  // _unset(modifiedPageConfiguration, 'containers');

  const response = await updatePageConfiguration(pageName, modifiedPageConfiguration);

  return response;
};

export {
  getFlattenProperties,
  getWidgetReference,
  getUpdatedPageConfiguration,
  addNewContainerToConfiguration,
  getLayouts,
  getPageConfigWithFlattenProperties,
  getPageConfigurationOnDrop,
  getDefaultPropertiesForGivenWidgetType,
  getPageConfigurationOnDeleteWidget,
  updatePageAndWidgetConfiguration,
  modifyPageConfigurationToApiFormat,
};
