const ACTION_TYPES = {
  ON_REDIRECTION: 'ON_REDIRECTION',
  ON_INIT: 'ON_INIT',
  ON_TAB_CLICK: 'ON_TAB_CLICK',
  ON_DESELECT: 'ON_DESELECT',
  ON_CLEAR: 'ON_CLEAR',
  ON_CANCEL: 'ON_CANCEL',
  CONFIRM_MODAL_REQUEST: 'CONFIRM_MODAL_REQUEST',
  ON_SUBMIT: 'ON_SUBMIT',
  ON_TYPE_CHANGE_CANCEL: 'ON_TYPE_CHANGE_CANCEL',
  ON_TYPE_CHANGE_SUCCESS: 'ON_TYPE_CHANGE_SUCCESS',
};

export default ACTION_TYPES;
