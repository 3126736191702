import { tget } from '@tekion/tekion-base/utils/general';
import { filterSections } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import SELECT_OPTIONS_FORM_FIELD_IDS from '../constants/selectOptionsForm.fieldsIds';

import styles from '../../../fieldsForm.module.scss';

const OPTIONS_SECTION = {
  header: {
    label: __('Options to select from'),
    className: styles.formSectionHeader,
  },
  rows: [
    {
      columns: [SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS],
    },
    {
      columns: [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD_ENABLED],
    },
  ],
};

const CONTROLLING_FIELD_SECTION = {
  rows: [
    {
      columns: [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD],
    },
  ],
};

const CONTROLLED_OPTIONS_SECTION = {
  header: {
    label: __('Controlled Options'),
    className: styles.formSectionHeader,
  },
  rows: [
    {
      columns: [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS],
    },
  ],
};

const getSections = (values) => {
  const isControllingFieldEnabled = tget(values, SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD_ENABLED, false);
  return filterSections([
    OPTIONS_SECTION,
    { ...CONTROLLING_FIELD_SECTION, visible: isControllingFieldEnabled },
    {
      ...CONTROLLED_OPTIONS_SECTION,
      visible: isControllingFieldEnabled,
    },
  ]);
};
export default getSections;
