import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { CURRENT_PAGE, CUSTOM_ACTIONS } from '../constants/dashboardList.constant';

import { getPayload } from './dashboardList.helpers';
import { STUDIO_ROUTE } from '../../../../../constants/routes';
import PAGE_IDS from '../../../constants/PageIds.constants';
import { getAllDashboards } from '../../../../../actions/reporting.actions';

const fetchAllDashboards = async ({ params, getState, setState }) => {
  const { pageSize, searchField, searchText } = getState();
  setState({ loading: true });
  const currentPageToken = _get(params, 'nextPageToken');
  const dashboardsResponse = await getAllDashboards(
    getPayload({
      searchText,
      searchField,
      pageSize,
      nextPageToken: currentPageToken,
    }),
  );
  const dashboards = tget(dashboardsResponse, 'hits', EMPTY_ARRAY);

  setState({
    dashboards,
    isLoaded: true,
    loading: false,
    nextPageToken: _get(dashboardsResponse, 'nextPageToken', ''),
  });
};

const handleRefresh = ({ getState, setState }) => {
  setState({ currentPage: CURRENT_PAGE }, () => {
    fetchAllDashboards({ params: { nextPageToken: EMPTY_STRING }, getState, setState });
  });
};
const handleSearchApply = ({ params, getState, setState }) => {
  setState({ searchText: _get(params, 'value', ''), currentPage: CURRENT_PAGE }, () => {
    fetchAllDashboards({ params: { nextPageToken: EMPTY_STRING }, getState, setState });
  });
};

const handleCreateNewDashboard = ({ getState }) => {
  const { history } = getState();
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTING}/${PAGE_IDS.DASHBOARD_CREATE}`;
  history.push(pathname);
};

const handlePageUpdate = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value', EMPTY_OBJECT);
  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }

  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      fetchAllDashboards({ params: { nextPageToken: currentPageToken }, getState, setState });
    },
  );
};

const handleEditRequest = ({ params, getState }) => {
  const { history } = getState();
  const name = _get(params, 'value.name', '');
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTING}/${PAGE_IDS.DASHBOARD_EDIT}/${name}`;
  if (!_isEmpty(name)) {
    history.push(pathname);
  }
};

const handleRowClick = ({ params, getState }) => {
  const { history } = getState();
  const name = _get(params, 'value.original.name', '');
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTING}/${PAGE_IDS.DASHBOARD_EDIT}/${name}`;
  if (!_isEmpty(name)) {
    history.push(pathname);
  }
};

const ACTION_HANDLERS = {
  [CUSTOM_ACTIONS.CREATE_NEW_DASHBOARD]: handleCreateNewDashboard,
  [CUSTOM_ACTIONS.EDIT]: handleEditRequest,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleRefresh,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchApply,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleRowClick,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: fetchAllDashboards,
};
export default ACTION_HANDLERS;
