import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { SingleSelect } from '@tekion/tekion-components/molecules/advancedSelect';

import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import { ShareAndManageRecordUserDetailSetters } from '../../../../../../../../hooks/useShareAndManageRecordModal/shareAndManageRecordUserData';

import { getOverrideStyles } from './accessTypeRenderer.helpers';
import { hasUserAccessDenied } from '../../helpers/manageRecord.general';

import styles from './accessTypeRenderer.module.scss';

const AccessTypeRenderer = ({ isSelectedAccessTypeFilterNotOfAccessDenied, index, data, original, wwdAccessTypesOptions, onValueChange }) => {
  const handleChange = useCallback(
    ({ value: newValue }) => {
      const updatedValue = ShareAndManageRecordUserDetailSetters.setRecordAccessType(original, newValue);

      onValueChange(index, updatedValue);
    },
    [index, onValueChange, original],
  );

  const isAccessDenied = useMemo(() => hasUserAccessDenied(original), [original]);

  return (
    <SingleSelect
      className={styles.accessTypeRenderer}
      styleOverrides={getOverrideStyles(isAccessDenied, isSelectedAccessTypeFilterNotOfAccessDenied)}
      value={data}
      options={wwdAccessTypesOptions}
      onChange={handleChange}
    />
  );
};

AccessTypeRenderer.propTypes = {
  isSelectedAccessTypeFilterNotOfAccessDenied: PropTypes.bool,
  index: PropTypes.number.isRequired,
  data: PropTypes.string,
  original: PropTypes.object.isRequired,
  wwdAccessTypesOptions: PropTypes.array,
  onValueChange: PropTypes.func,
};

AccessTypeRenderer.defaultProps = {
  isSelectedAccessTypeFilterNotOfAccessDenied: false,
  data: '',
  wwdAccessTypesOptions: EMPTY_ARRAY,
  onValueChange: _noop,
};

export default makeCellRenderer(AccessTypeRenderer);
