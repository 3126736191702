import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

// Constants
import COLUMN_IDS from '../constants/validationRuleBuilderList.columnIds';
import { DEFAULT_ROW_ACTIONS, ACTIVE_VALIDATION_RULE, INACTIVE_VALIDATION_RULE } from './validationRuleBuilderList.columns';
import { RULE_STATUS_FILTER, DEFAULT_FILTERS_TYPES } from '../constants/validationRuleBuilderList.filterTypes';
import { PAGE_SIZE_OPTIONS, TEXT_STARTS_WITH } from '../constants/validationRuleBuilderList.general';

const createTableProps = (isLoading, currentPage, pageSize, onAction) => ({
  loading: isLoading,
  currentPage: currentPage + 1,
  rowHeight: 35,
  minRows: 0,
  showPagination: true,
  pageSize,
  resultsPerPage: pageSize,
  pageSizeOptions: PAGE_SIZE_OPTIONS,
  onRowActionClick: (type, payload) => onAction({ type, payload }),
  showActions: true,
  getRowActions: (rowData) => {
    const active = _get(rowData, COLUMN_IDS.STATUS);
    const ACTIONS = active ? [...DEFAULT_ROW_ACTIONS, INACTIVE_VALIDATION_RULE] : [...DEFAULT_ROW_ACTIONS, ACTIVE_VALIDATION_RULE];
    return ACTIONS;
  },
});

const createFilterProps = (onAction) => ({
  filterTypes: [RULE_STATUS_FILTER],
  onAction,
  defaultFilterTypes: DEFAULT_FILTERS_TYPES,
  showResultsCount: false,
});

const createFilterArrayForApiPayload = (selectedFilters) => {
  const filters = _map(
    _filter(selectedFilters, (filter) => !_isEmpty(_get(filter, 'values'))),
    (filter) => ({
      field: _get(filter, 'type', EMPTY_STRING),
      values: _get(filter, 'values', EMPTY_ARRAY),
      filterType: _get(filter, 'operator', EMPTY_STRING),
    }),
  );

  return filters;
};

const getPayload = ({ currentPage, currentPageToken, pageSize, selectedFilters, searchText }) => {
  const payload = { rows: pageSize, start: currentPage * pageSize, nextPageToken: currentPageToken };
  let filters = [];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: COLUMN_IDS.RULE_NAME,
      values: [searchText],
      filterType: TEXT_STARTS_WITH,
    });
  }

  if (!_isEmpty(selectedFilters)) {
    filters = [...filters, ...createFilterArrayForApiPayload(selectedFilters)];
  }

  if (!_isEmpty(filters)) {
    _set(payload, 'filters', filters);
  }
  return payload;
};

const getPayloadForUserIds = (ids) => ({
  filters: [
    {
      field: 'id',
      values: ids,
      filterType: OPERATORS.IN,
    },
  ],
});

export { createTableProps, createFilterProps, getPayload, getPayloadForUserIds };
