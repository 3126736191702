import Button from '@tekion/tekion-components/atoms/Button';

import ACTION_TYPES from './authProviderList.actionTypes';

const HEADER_PROPS = {
  label: __('Auth Provider'),
};

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create Auth Provider'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_AUTH_PROVIDER,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showHeader: true,
  showSearch: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const EDIT_AUTH_PROVIDER = {
  id: ACTION_TYPES.EDIT_AUTH_PROVIDER,
  name: __('Edit'),
};

const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

const INITIAL_STATE_AUTH_PROVIDER_LIST = {
  currentPage: 0,
  pageSize: 50,
};

const ROW_ACTIONS = [EDIT_AUTH_PROVIDER];

export { HEADER_PROPS, SUB_HEADER_PROPS, TABLE_MANAGER_PROPS, ROW_ACTIONS, TABLE_CONSTANTS, INITIAL_STATE_AUTH_PROVIDER_LIST };
