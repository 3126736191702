import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import globalSelectListManagementServices from '../services/globalSelect.services';

const getAllGlobalSelectList = async (payload = EMPTY_OBJECT) => {
  try {
    const receivedData = await globalSelectListManagementServices.fetchAllGlobalSelects(payload);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch global selects, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createGlobalSelectList = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await globalSelectListManagementServices.createGlobalSelect(payload);
    const responseData = getDataFromResponse(response);
    return responseData;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, __('Failed to create global select, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateGlobalSelectList = async (name = EMPTY_STRING, payload = EMPTY_OBJECT) => {
  try {
    const response = await globalSelectListManagementServices.updateGlobalSelect(name, payload);
    const responseData = getDataFromResponse(response);
    return responseData;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, __('Failed to update global select "{{name}}", please try again later.', { name })));
    return EMPTY_OBJECT;
  }
};

const updateGlobalListOptions = async (name = EMPTY_STRING, payload = EMPTY_OBJECT) => {
  try {
    const response = await globalSelectListManagementServices.updateGlobalSelectOptions(name, payload);
    const responseData = getDataFromResponse(response);
    return responseData;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, __('Failed to update options of global select "{{name}}", please try again later.', { name })));
    return EMPTY_OBJECT;
  }
};

const getGlobalSelectByName = async (name = EMPTY_STRING) => {
  try {
    const response = await globalSelectListManagementServices.getGlobalSelectByName(name);
    const responseData = getDataFromResponse(response);
    return responseData;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, __('Failed to get global select "{{name}}", please try again later.', { name })));
    return EMPTY_OBJECT;
  }
};

export { getAllGlobalSelectList, getGlobalSelectByName, createGlobalSelectList, updateGlobalListOptions, updateGlobalSelectList };
