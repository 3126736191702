exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .relationship_field__svYpjvvkxn.relationship_multiSelect__sTPe6Pz8vH{border:.1rem solid #969aa3}.relationship_clearIndicator__wmrkVGia7L{display:flex}[dir=ltr] .relationship_clearIndicator__wmrkVGia7L{margin-left:auto}[dir=rtl] .relationship_clearIndicator__wmrkVGia7L{margin-right:auto}[dir] .relationship_select__k92b3h1GBY{background-color:#fff}", ""]);

// Exports
exports.locals = {
	"field": "relationship_field__svYpjvvkxn",
	"multiSelect": "relationship_multiSelect__sTPe6Pz8vH",
	"clearIndicator": "relationship_clearIndicator__wmrkVGia7L",
	"select": "relationship_select__k92b3h1GBY"
};