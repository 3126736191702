import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';
import _set from 'lodash/set';
import _omit from 'lodash/omit';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { isActionDefinitionRequired, isUpsertOrUpdateActionType } from './recordActionModal.helpers';

import CONDITION_BUILDER_FIELD_IDS from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import { ACTION_DEFINITION_FIELD_IDS, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS } from '../../../../../../../../../constants/actionBuilder.constants';
import { ACTION_BUILDER_ACTION_TYPES } from '../../../../../../actionBuilder/actionBuilderForm/constants/actionBuilderForm.general';
import { VARIABLE_TYPES, ASSIGNED_VARIABLE_FIELD_IDS } from '../../../../constants/workflow.assignVariableConstants';
import { GET_RECORD_FIELD_IDS } from '../constants/recordActionModal.fieldIds';
import { TEMPLATE_RENDERER_FIELD_IDS } from '../../../../../../../../../organisms/templateSection/templateRenderer/templateRenderer.constant';
import { ASSIGNED_VARIABLE_DATA_TYPES } from '../../../../constants/workflow.nodeVariableConstants';

const setActionDefinitionPayload = (userData, actionType, actionDefinition) => {
  if (
    isActionDefinitionRequired(actionType) &&
    !_isEmpty(actionDefinition) &&
    !_isEmpty(actionDefinition[CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST])
  ) {
    const prefilledData = _reduce(
      actionDefinition[CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST],
      (_prefilledData, criteria) => {
        const field = tget(criteria, CONDITION_FIELD_IDS.FIELD);
        const value = tget(criteria, CONDITION_FIELD_IDS.VALUES);

        return {
          ..._prefilledData,
          [field]: value,
        };
      },
      {},
    );
    _set(userData, ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION, prefilledData);
  }
};

const setRecordToUpdatePayload = (userData, actionType, filterConditions, sortConditions) => {
  if (isUpsertOrUpdateActionType(actionType)) {
    const updateDetails = {};
    const criteriaList = tget(filterConditions, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY);
    const expression = tget(filterConditions, CONDITION_BUILDER_FIELD_IDS.EXPRESSION);

    if (!_isEmpty(criteriaList) && !_isEmpty(expression)) {
      _set(updateDetails, `${ACTION_DEFINITION_FIELD_IDS.CONDITION}.${CONDITION_BUILDER_FIELD_IDS.EXPRESSION}`, expression);
      _set(updateDetails, `${ACTION_DEFINITION_FIELD_IDS.CONDITION}.${CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST}`, criteriaList);
      _set(updateDetails, `${ACTION_DEFINITION_FIELD_IDS.CONDITION}.type`, 'CRITERIA');
    }

    const filteredSortConditions = _filter(sortConditions, (condition) => !_isEmpty(condition));
    if (!_isEmpty(filteredSortConditions)) {
      _set(updateDetails, ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS, filteredSortConditions);
    }

    _set(userData, ACTION_DEFINITION_FIELD_IDS.RECORD_TO_UPDATE, updateDetails);
  }
};

const setSearchRequestPayload = (userData, actionType, filterConditions, sortConditions, recordsLimit) => {
  if (actionType === ACTION_BUILDER_ACTION_TYPES.GET_RECORDS) {
    const searchRequest = {};
    const criteriaList = tget(filterConditions, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY);
    const expression = tget(filterConditions, CONDITION_BUILDER_FIELD_IDS.EXPRESSION);

    if (!_isEmpty(criteriaList) && !_isEmpty(expression)) {
      _set(searchRequest, `${ACTION_DEFINITION_FIELD_IDS.CONDITION}.${CONDITION_BUILDER_FIELD_IDS.EXPRESSION}`, expression);
      _set(searchRequest, `${ACTION_DEFINITION_FIELD_IDS.CONDITION}.${CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST}`, criteriaList);
      _set(searchRequest, `${ACTION_DEFINITION_FIELD_IDS.CONDITION}.type`, 'CRITERIA');
    }

    const filteredSortConditions = _filter(sortConditions, (condition) => !_isEmpty(condition));
    if (!_isEmpty(filteredSortConditions)) {
      _set(searchRequest, ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS, filteredSortConditions);
    }

    _set(searchRequest, ACTION_DEFINITION_FIELD_IDS.LIMIT, _isNil(recordsLimit) ? 1 : recordsLimit);

    _set(userData, ACTION_DEFINITION_FIELD_IDS.SEARCH_REQUEST, searchRequest);
  }
};

const setActionDefinitionForSendEmails = (userData, sendEmailsActionDefinition) => {
  const selectedTemplates = _filter(
    tget(sendEmailsActionDefinition, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES, EMPTY_ARRAY),
    (value) => !_isEmpty(value),
  );

  const selectedTemplatesAccordingToPayload = _reduce(
    selectedTemplates,
    (ans, selectedTemplate) => {
      const templateName = tget(selectedTemplate, TEMPLATE_RENDERER_FIELD_IDS.SELECTED_TEMPLATE);
      const variableValueMap = tget(selectedTemplate, TEMPLATE_RENDERER_FIELD_IDS.MAP_OF_VARIABLES, EMPTY_OBJECT);
      _set(ans, templateName, variableValueMap);
      return ans;
    },
    {},
  );

  _set(sendEmailsActionDefinition, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES, selectedTemplatesAccordingToPayload);
  _set(userData, ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION, { ...sendEmailsActionDefinition });
};

const setAssignedVariable = (userData, shouldAssignVariable, assignedVariable) => {
  if (shouldAssignVariable) {
    _set(userData, ACTION_DEFINITION_FIELD_IDS.ASSIGNED_VARIABLE, getArraySafeValue(assignedVariable));
  }
};

const generateUserData = (formValues, entityName) => {
  let userData = _pick(formValues, [ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME]);
  const actionType = tget(formValues, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE);
  const targetEntityName = tget(formValues, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME);
  const actionDefinition = tget(formValues, ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION);
  const filterConditions = tget(formValues, ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS, EMPTY_OBJECT);
  const shouldSortRecords = tget(formValues, ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS, false);
  const sortConditions = tget(formValues, ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS, [null]);
  const updateSameRecord = tget(formValues, ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD, null);
  const recordsLimit = tget(formValues, ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT, null);
  const shouldAssignVariable = tget(formValues, ACTION_DEFINITION_FIELD_IDS.SHOULD_ASSIGN_VARIABLE, false);
  const assignedVariable = tget(formValues, ACTION_DEFINITION_FIELD_IDS.ASSIGNED_VARIABLE, '');

  let recordCount;

  if (actionType === ACTION_BUILDER_ACTION_TYPES.GET_RECORDS) {
    recordCount = tget(formValues, GET_RECORD_FIELD_IDS.RECORD_COUNT_FIELD_ID, ASSIGNED_VARIABLE_DATA_TYPES.MULTIPLE);
  } else if (actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORDS && shouldAssignVariable) {
    recordCount = ASSIGNED_VARIABLE_DATA_TYPES.MULTIPLE;
  } else if (shouldAssignVariable) {
    recordCount = ASSIGNED_VARIABLE_DATA_TYPES.SINGLE;
  }

  _set(userData, ACTION_DEFINITION_FIELD_IDS.ACTION_LEVEL, 'ENTITY');
  _set(userData, 'entityName', entityName);
  setActionDefinitionPayload(userData, actionType, actionDefinition);
  setRecordToUpdatePayload(userData, actionType, filterConditions, shouldSortRecords ? sortConditions : null);
  setSearchRequestPayload(userData, actionType, filterConditions, shouldSortRecords ? sortConditions : null, recordsLimit);

  const sendEmailsActionDefinition = tget(formValues, ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION);
  if (!_isEmpty(sendEmailsActionDefinition)) {
    setActionDefinitionForSendEmails(userData, sendEmailsActionDefinition);
    userData = {
      ..._omit(userData, ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION),
      ...tget(userData, ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION),
    };
  } else {
    _set(userData, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME, getArraySafeValue(targetEntityName));
    _set(userData, ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD, updateSameRecord);
    _set(userData, GET_RECORD_FIELD_IDS.RECORD_COUNT_FIELD_ID, recordCount);
  }
  setAssignedVariable(userData, shouldAssignVariable, assignedVariable);

  return userData;
};

const getAssignedVariableInfo = (formValues) => {
  const assignedVariableInfo = {};
  /**
   * Below being the variable structure to be stored at uiMetadata of workflow
   * customer: {
    entityName: "customer__u",
    variableDataType: "CUSTOM_ENTITY",
    declaredAtNodeId: "fbgjgs",
    usedAtNodeIds: ["riosatu", "xzcmnbmz"]
  },
   */

  const actionType = tget(formValues, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE);
  const targetEntityName = getArraySafeValue(tget(formValues, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME));
  const shouldAssignVariable = tget(formValues, ACTION_DEFINITION_FIELD_IDS.SHOULD_ASSIGN_VARIABLE, false);
  const assignedVariable = getArraySafeValue(tget(formValues, ACTION_DEFINITION_FIELD_IDS.ASSIGNED_VARIABLE));
  const recordCounts = tget(formValues, GET_RECORD_FIELD_IDS.RECORD_COUNT_FIELD_ID);

  if (shouldAssignVariable) {
    _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_NAME, assignedVariable);
    _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME, targetEntityName);

    if (isActionDefinitionRequired(actionType)) {
      _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE, VARIABLE_TYPES.ENTITY_RECORD);
    } else if (actionType === ACTION_BUILDER_ACTION_TYPES.GET_RECORDS) {
      if (recordCounts === ASSIGNED_VARIABLE_DATA_TYPES.MULTIPLE) {
        _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE, VARIABLE_TYPES.ENTITY_RECORD_LIST);
      } else {
        _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE, VARIABLE_TYPES.ENTITY_RECORD);
      }
    }
  }

  return assignedVariableInfo;
};

export { generateUserData, getAssignedVariableInfo };
