import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import TextInputRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import TextAreaField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';

import EntityAsyncSelect from '../../../../molecules/EntityAsyncSelect/EntityAsyncSelect';

import { FIELD_IDS } from '../../../../../../constants/entityRecordGroup.constants';

const RECORD_GROUP_NAME_FIELD = {
  id: FIELD_IDS.RECORD_GROUP_NAME,
  renderer: TextInputRenderer,
  renderOptions: {
    required: true,
    label: __('Record Group Name'),
    validators: [isRequiredRule],
  },
};

const ENTITY_FIELD = {
  id: FIELD_IDS.ENTITY,
  renderer: EntityAsyncSelect,
  renderOptions: {
    required: true,
    label: __('Entity'),
    validators: [isRequiredRule],
  },
};

const RECORD_GROUP_DESCRIPTION_FIELD = {
  id: FIELD_IDS.DESCRIPTION,
  renderer: TextAreaField,
  renderOptions: {
    label: __('Description'),
  },
};

export { RECORD_GROUP_NAME_FIELD, ENTITY_FIELD, RECORD_GROUP_DESCRIPTION_FIELD };
