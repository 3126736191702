import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import { TEXT_PROPERTIES_CONFIGURE_FIELD_IDS } from '../constants/textPropertiesConfigure.fieldIds';

const TEXT_LABEL_FIELD = {
  id: TEXT_PROPERTIES_CONFIGURE_FIELD_IDS.TEXT_LABEL,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Label'),
    validators: [isRequiredRule],
    placeholder: __('Enter Label'),
  },
};

const TEXT_SIZE_FIELD = {
  id: TEXT_PROPERTIES_CONFIGURE_FIELD_IDS.TEXT_SIZE,
  renderer: SelectInput,
  renderOptions: {
    required: true,
    label: __('Size'),
    validators: [isRequiredRule],
    placeholder: __('Select size'),
    options: [
      { label: __('1'), value: 1 },
      { label: __('2'), value: 2 },
      { label: __('3'), value: 3 },
      { label: __('4'), value: 4 },
      { label: __('5'), value: 5 },
      { label: __('6'), value: 6 },
    ],
  },
};

const TEXT_PROPERTIES_CONFIGURE_FIELDS = {
  [TEXT_PROPERTIES_CONFIGURE_FIELD_IDS.TEXT_LABEL]: TEXT_LABEL_FIELD,
  [TEXT_PROPERTIES_CONFIGURE_FIELD_IDS.TEXT_SIZE]: TEXT_SIZE_FIELD,
};

export default TEXT_PROPERTIES_CONFIGURE_FIELDS;
