import { FIELD_IDS } from '../constants/sortOptionsConfigurator.constants';

const getSortOptionsFormSection = () => [
  {
    rows: [
      {
        columns: [FIELD_IDS.SORTABLE_FIELDS],
      },
      {
        columns: [FIELD_IDS.ERROR_MESSAGE],
      },
      {
        columns: [FIELD_IDS.DEFAULT_SORT_DETAILS],
      },
    ],
  },
];

export default getSortOptionsFormSection;
