import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _isNil from 'lodash/isNil';
import _set from 'lodash/set';
import _has from 'lodash/has';
import _castArray from 'lodash/castArray';
import _toNumber from 'lodash/toNumber';

import { tget } from '@tekion/tekion-base/utils/general';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { getSimpleFieldNameFromColumn } from '../../../../../utils';

import DATA_TYPES from '../../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../../constants/fieldDefinition.fieldTypes';
import { FIELD_IDS } from '../constants/searchOptionsConfigurator.constants';

import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';

const getAddedSearchOptions = (allFormValues) =>
  _reduce(
    allFormValues,
    (prevAddedOptions, searchOption) => {
      const displayName = tget(searchOption, [FIELD_IDS.DISPLAY_NAME]);
      if (displayName) {
        return [...prevAddedOptions, displayName];
      }
      return prevAddedOptions;
    },
    [],
  );

const filterFormValueForDeletedTab = (allFormValues, moduleIndex) => {
  const newAllFormValues = _filter(allFormValues, (_, index) => moduleIndex !== index);
  return _cloneDeep(newAllFormValues);
};

const checkDeletedModuleIsSelectedAndLastModule = (moduleIndex, selectedModuleIndex, newAllFormValues) =>
  moduleIndex === selectedModuleIndex && moduleIndex === _size(newAllFormValues) - 1 && moduleIndex >= 1;

const getAllSearchableFields = (fieldDefinitions, searchableFieldOptionsByRelationshipField) =>
  _reduce(
    fieldDefinitions,
    (prevSearchableFields, fieldDef) => {
      const isColumnSearchable = fieldDefinitionReader.searchable(fieldDef);
      if (!isColumnSearchable) return prevSearchableFields;

      const fieldName = fieldDefinitionReader.name(fieldDef);
      const displayName = fieldDefinitionReader.displayName(fieldDef);
      const fieldType = fieldDefinitionReader.fieldType(fieldDef);
      const dataType = fieldDefinitionReader.dataType(fieldDef);

      if (fieldType === FIELD_TYPES.RELATIONSHIP || dataType === DATA_TYPES.COMPLEX) {
        let entityFieldDefinitions = {};

        if (fieldType === FIELD_TYPES.RELATIONSHIP) {
          entityFieldDefinitions = fieldDefinitionReader.relationshipEntityFieldDefinitions(fieldDef);
        } else {
          entityFieldDefinitions = fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef);
        }

        let searchableFields = getAllSearchableFields(entityFieldDefinitions, searchableFieldOptionsByRelationshipField);
        searchableFields = _map(searchableFields, ({ label, value }) => ({
          label: !_isEmpty(label) ? `${displayName} - ${label}` : displayName,
          value: `${fieldName}.${value}`,
        }));

        if (fieldType === FIELD_TYPES.RELATIONSHIP) {
          const prevSearchableFieldOptions = tget(searchableFieldOptionsByRelationshipField, [fieldName], EMPTY_ARRAY);
          _set(searchableFieldOptionsByRelationshipField, [fieldName], [...prevSearchableFieldOptions, ...searchableFields]);
        }

        return [...prevSearchableFields, ...searchableFields];
      }

      prevSearchableFields.push({
        label: displayName,
        value: fieldName,
      });
      return prevSearchableFields;
    },
    [],
  );

const areSearchOptionsValid = (searchFields) => {
  let isValid = true;

  _forEach(searchFields, (searchField) => {
    const field = tget(searchField, [FIELD_IDS.FIELD]);

    if (_isEmpty(field)) {
      isValid = false;
    }
  });

  if (!isValid) {
    toaster(TOASTER_TYPE.ERROR, __('All Search Fields are Required'));
  } else if (_isEmpty(searchFields)) {
    isValid = false;
    toaster(TOASTER_TYPE.ERROR, __('At Least One Search Field is Required'));
  }

  return { isValid };
};

const getFormFormattedSearchOptions = (allFormValues) =>
  _reduce(
    allFormValues,
    (prevAllFormValues, searchOption) => {
      const searchFields = tget(searchOption, [FIELD_IDS.SEARCH_METADATA], EMPTY_ARRAY);

      const newSearchFields = _map(searchFields, (searchField) => {
        const field = _castArray(tget(searchField, FIELD_IDS.FIELD));
        const boost = _castArray(tget(searchField, FIELD_IDS.BOOST));

        return {
          [FIELD_IDS.FIELD]: field,
          [FIELD_IDS.BOOST]: _toNumber(boost),
        };
      });

      return [...prevAllFormValues, { ...searchOption, [FIELD_IDS.SEARCH_METADATA]: newSearchFields }];
    },
    [],
  );

const getRendererPropFormattedSearchOptions = (allFormValues) =>
  _reduce(
    allFormValues,
    (prevAllFormValues, searchOption) => {
      const searchFields = tget(searchOption, [FIELD_IDS.SEARCH_METADATA], EMPTY_ARRAY);

      const newSearchFields = _map(searchFields, (searchField) => {
        const field = getArraySafeValue(tget(searchField, FIELD_IDS.FIELD));
        const boost = getArraySafeValue(tget(searchField, FIELD_IDS.BOOST));

        return {
          [FIELD_IDS.FIELD]: field,
          [FIELD_IDS.BOOST]: !_isNil(boost) ? _toNumber(boost) : 1,
        };
      });

      return [...prevAllFormValues, { ...searchOption, [FIELD_IDS.SEARCH_METADATA]: newSearchFields }];
    },
    [],
  );

const getRelationshipField = (selectedModuleIndex, allFormValues, searchableFieldOptionsByRelationshipField) => {
  const formValue = tget(allFormValues, [selectedModuleIndex], EMPTY_OBJECT);
  const field = tget(formValue, [FIELD_IDS.SEARCH_METADATA, 0, FIELD_IDS.FIELD]);

  if (field) {
    const simpleField = getSimpleFieldNameFromColumn(field);
    if (_has(searchableFieldOptionsByRelationshipField, simpleField)) {
      return simpleField;
    }
  }
  return undefined;
};

export {
  getAddedSearchOptions,
  filterFormValueForDeletedTab,
  checkDeletedModuleIsSelectedAndLastModule,
  getAllSearchableFields,
  areSearchOptionsValid,
  getFormFormattedSearchOptions,
  getRendererPropFormattedSearchOptions,
  getRelationshipField,
};
