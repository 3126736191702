import _isEmpty from 'lodash/isEmpty';
import _concat from 'lodash/concat';
import _isNil from 'lodash/isNil';
import _head from 'lodash/head';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _pickBy from 'lodash/pickBy';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _castArray from 'lodash/castArray';
import _includes from 'lodash/includes';
import _omit from 'lodash/omit';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';

// Utils
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

// Constants
import { EMPTY_OBJECT, EMPTY_STRING, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import FIELD_IDS from '../constants/fieldsForm.fieldIds';
import { COLUMN_IDS, MODAL_INPUT_FIELD } from '../components/ListInputTable/constants/listInputTable.constants';
import {
  CONSTRAINTS_TYPES,
  FIELD_DETAILS_FIELD_IDS,
  DATA_TYPES,
  FIELD_TYPES,
  DETAILS_FIELD_IDS,
  COLUMNS,
  FILTER_CONDITION_OPTIONS,
} from '../constants/fieldsForm.constants';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import { CONDITION_BUILDER_TYPES } from '../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';

//  -----------------  Get Payload form Value ---------------------- //

const getTextConstraintPayload = (propertyValues) => {
  const payload = {
    constraints: [
      {
        type: CONSTRAINTS_TYPES.STRING_LENGTH,
        minLength: _get(propertyValues, FIELD_IDS.TEXT_MIN_LENGTH, -1),
        maxLength: _get(propertyValues, FIELD_IDS.TEXT_MAX_LENGTH, -1),
      },
    ],
    allowLookupUsingField: _get(propertyValues, FIELD_IDS.ALLOW_LOOKUP_USING_FIELD, false),
  };

  const regexPattern = _get(propertyValues, FIELD_IDS.TEXT_REGEX, EMPTY_STRING);
  if (!_isEmpty(regexPattern)) {
    _set(
      payload,
      'constraints',
      _concat(payload.constraints, [
        {
          type: CONSTRAINTS_TYPES.REGEX,
          pattern: regexPattern,
        },
      ]),
    );
  }
  return payload;
};

const getNumberConstraintPayload = (propertyValues) => {
  const payload = {
    allowLookupUsingField: _get(propertyValues, FIELD_IDS.ALLOW_LOOKUP_USING_FIELD, false),
  };
  return payload;
};

const getRangeConstraintPayload = (propertyValues) => {
  const payload = {
    allowLookupUsingField: _get(propertyValues, FIELD_IDS.ALLOW_LOOKUP_USING_FIELD, false),
  };
  return payload;
};

const getRelationshipPayload = (propertyValues) => ({
  lookupField: {
    entityType: _head(_get(propertyValues, FIELD_IDS.LOOKUP_ENTITY, EMPTY_ARRAY)),
    field: _head(_get(propertyValues, FIELD_IDS.LOOKUP_FIELD, EMPTY_ARRAY)),
    displayField: _head(_get(propertyValues, FIELD_IDS.LOOKUP_DISPLAY_FIELD, EMPTY_ARRAY)),
    groupsAllowed: _get(propertyValues, FIELD_IDS.GROUPS_ALLOWED, false),
  },
});

const getDropDownPayload = (propertyValues) => {
  const listOptions = _get(propertyValues, FIELD_IDS.LIST_INPUT_TABLE, EMPTY_ARRAY);
  let optionConfig = {
    options: _map(listOptions, (option) => ({
      displayName: _get(option, 'label', ''),
      value: _get(option, 'value', ''),
    })),
  };
  if (_get(propertyValues, FIELD_IDS.CONTROLLING_FUNCTION, false)) {
    let controllingOptionsConfigs = _map(_get(propertyValues, FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS, EMPTY_ARRAY), (item) => ({
      controlledOption: getArraySafeValue(_get(item, COLUMNS.OPTION)),
      controllingOptions: _get(item, COLUMNS.VALUES),
    }));
    controllingOptionsConfigs = _filter(controllingOptionsConfigs, (item) => !_isEmpty(_get(item, 'controllingOptions')));
    optionConfig = {
      ...optionConfig,
      controllingFieldName: getArraySafeValue(_get(propertyValues, FIELD_IDS.CONTROLLING_FIELD_NAME)),
      controllingOptionsConfigs,
    };
  }
  return { optionConfig };
};

const getComplexDataTypePayload = (propertyValues) => ({
  complexFieldDefinition: {
    entityName: _head(_get(propertyValues, FIELD_IDS.COMPLEX_FIELD_ENTITY, EMPTY_ARRAY)),
  },
});

const getDateConstraintPayload = (propertyValues) => {
  const payload = {
    allowLookupUsingField: _get(propertyValues, FIELD_IDS.ALLOW_LOOKUP_USING_FIELD, false),
  };
  return payload;
};

const getConditionPayload = (condition) => {
  if (_isEmpty(condition)) {
    return {};
  }
  const criteriaList = _get(condition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY);

  if (_isEmpty(criteriaList)) {
    return {};
  }
  return {
    type: 'CRITERIA',
    ...condition,
    [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: _map(criteriaList, (criteria) => {
      const resourceFieldValue = _get(criteria, CONDITION_FIELD_IDS.FIELD, EMPTY_ARRAY);
      return {
        ...criteria,
        [CONDITION_FIELD_IDS.FIELD]: getArraySafeValue(resourceFieldValue),
      };
    }),
    [CONDITION_BUILDER_FIELD_IDS.TYPE]: CONDITION_BUILDER_TYPES.CRITERIA,
  };
};

const getDataTypeValue = (formValues, options) => {
  const fieldType = _head(_get(formValues, FIELD_IDS.FIELD_TYPE, EMPTY_ARRAY));
  let dataTypeValue = formValues[FIELD_IDS.DATA_TYPE];
  if (fieldType === FIELD_TYPES.RELATIONSHIP && !_isNil(options)) {
    const selectedEntity = _head(_get(formValues, FIELD_IDS.LOOKUP_ENTITY, EMPTY_ARRAY));
    const selectedField = _head(_get(formValues, FIELD_IDS.LOOKUP_FIELD, EMPTY_ARRAY));
    const lookupFieldOptions = _get(options, `lookupFieldOptions.${selectedEntity}`, EMPTY_ARRAY);
    dataTypeValue = _get(_find(lookupFieldOptions, ['value', selectedField]), 'dataType', '');
  }
  return { [FIELD_IDS.DATA_TYPE]: getArraySafeValue(dataTypeValue) };
};

const getFormDetailsValue = (formValues) => {
  let values = _pickBy(formValues, (value, key) => {
    if (_includes(FIELD_DETAILS_FIELD_IDS, key) && value) return true;
    return false;
  });
  if (_isEmpty(getArraySafeValue(_get(formValues, FIELD_IDS.SORT_DIRECTION, [''])))) {
    values = _omit(values, FIELD_IDS.SORT_DIRECTION);
  }
  return values;
};

const getChildrenConfig = (formValues) => {
  const childFilterCondition = getConditionPayload(_get(formValues, FIELD_IDS.CHILD_FILTER_CONDITION));
  const updateCondition = getConditionPayload(_get(formValues, FIELD_IDS.UPDATE_CONDITION));

  let aggregateSummaryFieldInfo = {
    entityName: getArraySafeValue(_get(formValues, FIELD_IDS.CHILD_ENTITY_NAME)),
    fieldToAggregate: getArraySafeValue(_get(formValues, FIELD_IDS.CHILD_FIELD), []),
    function: getArraySafeValue(_get(formValues, FIELD_IDS.ROLL_UP_FUNCTION)),
    relationshipField: getArraySafeValue(_get(formValues, FIELD_IDS.CHILD_RELATION_FIELD)),
  };
  if (!_isEmpty(updateCondition)) {
    aggregateSummaryFieldInfo = { ...aggregateSummaryFieldInfo, updateCondition };
  }
  if (!_isEmpty(childFilterCondition)) {
    aggregateSummaryFieldInfo = { ...aggregateSummaryFieldInfo, childFilterCondition };
  }
  return aggregateSummaryFieldInfo;
};

const getPropertyPayload = (formValues) => {
  const fieldType = getArraySafeValue(_get(formValues, FIELD_IDS.FIELD_TYPE, EMPTY_ARRAY));
  const dataType = _head(_get(formValues, FIELD_IDS.DATA_TYPE, EMPTY_ARRAY));
  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      if (dataType === DATA_TYPES.TEXT) return getTextConstraintPayload(formValues);
      if (dataType === DATA_TYPES.NUMBER) return getNumberConstraintPayload(formValues);
      if (dataType === DATA_TYPES.COMPLEX) return getComplexDataTypePayload(formValues);
      if (dataType === DATA_TYPES.DATE) return getDateConstraintPayload(formValues);
      if (dataType === DATA_TYPES.DATE_TIME) return getDateConstraintPayload(formValues);
      return EMPTY_OBJECT;
    case FIELD_TYPES.LIST:
      if (dataType === DATA_TYPES.TEXT) return getTextConstraintPayload(formValues);
      if (dataType === DATA_TYPES.NUMBER) return getNumberConstraintPayload(formValues);
      if (dataType === DATA_TYPES.DATE) return getDateConstraintPayload(formValues);
      if (dataType === DATA_TYPES.DATE_TIME) return getDateConstraintPayload(formValues);
      return EMPTY_OBJECT;
    case FIELD_TYPES.RANGE:
      if (dataType === DATA_TYPES.NUMBER) return getRangeConstraintPayload(formValues);
      return EMPTY_OBJECT;
    case FIELD_TYPES.RELATIONSHIP:
      return getRelationshipPayload(formValues);
    case FIELD_TYPES.SELECT:
      return getDropDownPayload(formValues);
    default:
      return EMPTY_OBJECT;
  }
};

const getPayloadFromValues = (formValues, options) => {
  const propertyPayload = getPropertyPayload(formValues);
  const fieldType = _head(_get(formValues, FIELD_IDS.FIELD_TYPE, EMPTY_ARRAY));
  let aggregateSummaryFieldInfo = {};

  const formDetailsValues = getFormDetailsValue(formValues);
  const dataType = getDataTypeValue(formValues, options);

  let payload = {
    ...formValues,
    ...propertyPayload,
    ...formDetailsValues,
    ...dataType,
    [FIELD_IDS.FIELD_TYPE]: getArraySafeValue(formValues[FIELD_IDS.FIELD_TYPE]),
    [FIELD_IDS.DEPENDENCY_CONFIG]: _map(_get(formValues, FIELD_IDS.DEPENDENCY_CONFIG, EMPTY_ARRAY), (item) => ({
      fieldName: _head(_get(item, COLUMNS.FIELD_NAME, [''])),
      dependencyType: _head(_get(item, COLUMNS.DEPENDENCY_TYPE, [''])),
    })),
  };
  payload = _omit(payload, [FIELD_IDS.GROUPS_ALLOWED]);
  const sortDirection = getArraySafeValue(formValues[FIELD_IDS.SORT_DIRECTION]);
  if (!_isEmpty(sortDirection)) {
    _set(payload, FIELD_IDS.SORT_DIRECTION, sortDirection);
  }
  if (fieldType === FIELD_TYPES.CHILD_AGGREGATE_SUMMARY) {
    aggregateSummaryFieldInfo = getChildrenConfig(formValues);
    payload = { ...payload, aggregateSummaryFieldInfo };
    payload = _omit(payload, [
      FIELD_IDS.CHILD_ENTITY_NAME,
      FIELD_IDS.CHILD_FIELD,
      FIELD_IDS.CHILD_FILTER_CONDITION,
      FIELD_IDS.FILTER_CRITERIA_FOR_CHILD,
      FIELD_IDS.ROLL_UP_FUNCTION,
      FIELD_IDS.FILTER_CRITERIA_FOR_PARENT,
      FIELD_IDS.UPDATE_CONDITION,
      FIELD_IDS.CHILD_RELATION_FIELD,
    ]);
  }
  if (fieldType === FIELD_TYPES.SELECT) {
    payload = _omit(payload, [
      FIELD_IDS.LIST_INPUT_TABLE,
      FIELD_IDS.CONTROLLING_FIELD_NAME,
      FIELD_IDS.CONTROLLING_FUNCTION,
      FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS,
    ]);
  }
  return payload;
};

// --------------  Get Values from Payload ------------------- //

const getTextValuesFromPayload = (fieldData) => {
  const constraints = _get(fieldData, 'constraints', EMPTY_ARRAY);
  const lengthContraints = _find(constraints, (obj) => _get(obj, 'type', '') === CONSTRAINTS_TYPES.STRING_LENGTH);
  const regexConstraints = _find(constraints, (obj) => _get(obj, 'type', '') === CONSTRAINTS_TYPES.REGEX);
  const textValues = {};
  const minLength = _get(lengthContraints, 'minLength', '');
  const maxLength = _get(lengthContraints, 'maxLength', '');
  const regex = _get(regexConstraints, 'pattern', '');
  if (!_isNil(minLength)) _set(textValues, FIELD_IDS.TEXT_MIN_LENGTH, minLength);
  if (!_isNil(maxLength)) _set(textValues, FIELD_IDS.TEXT_MAX_LENGTH, maxLength);
  if (!_isEmpty(regex)) _set(textValues, FIELD_IDS.TEXT_REGEX, regex);
  return textValues;
};

const getComplexValuesFromPayload = (fieldData) => {
  const complexFieldDefinition = _get(fieldData, 'complexFieldDefinition', EMPTY_ARRAY);
  const complexValues = {};
  const entityName = _get(complexFieldDefinition, 'entityName', '');

  if (!_isEmpty(entityName)) _set(complexValues, FIELD_IDS.COMPLEX_FIELD_ENTITY, _castArray(entityName));
  return complexValues;
};

const getRelationshipValuesFromPayload = (fieldData) => {
  const lookUpField = _get(fieldData, 'lookupField', EMPTY_ARRAY);

  const lookUpValues = {};
  const entityType = _castArray(_get(lookUpField, 'entityType', ''));
  const field = _castArray(_get(lookUpField, 'field', ''));
  const displayField = _castArray(_get(lookUpField, 'displayField', ''));
  const groupsAllowed = _get(lookUpField, 'groupsAllowed', false);

  if (!_isEmpty(entityType)) _set(lookUpValues, FIELD_IDS.LOOKUP_ENTITY, entityType);
  if (!_isEmpty(field)) _set(lookUpValues, FIELD_IDS.LOOKUP_FIELD, field);
  if (!_isEmpty(displayField)) _set(lookUpValues, FIELD_IDS.LOOKUP_DISPLAY_FIELD, displayField);
  _set(lookUpValues, FIELD_IDS.GROUPS_ALLOWED, groupsAllowed);

  return lookUpValues;
};

const getDropDownValuesFromPayload = (propertyValues) => {
  const optionConfigValues = _get(propertyValues, 'optionConfig', EMPTY_ARRAY);
  const listOptions = _get(optionConfigValues, 'options', EMPTY_ARRAY);
  let values = {
    [FIELD_IDS.LIST_INPUT_TABLE]: _map(listOptions, (option) => ({
      label: _get(option, 'displayName', ''),
      value: _get(option, 'value', ''),
    })),
  };
  if (_get(optionConfigValues, FIELD_IDS.CONTROLLING_FIELD_NAME)) {
    values = {
      ...values,
      [FIELD_IDS.CONTROLLING_FUNCTION]: true,
      [FIELD_IDS.CONTROLLING_FIELD_NAME]: _castArray(_get(optionConfigValues, FIELD_IDS.CONTROLLING_FIELD_NAME)),
      [FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS]: _map(_get(optionConfigValues, FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS), (item) => ({
        [COLUMNS.OPTION]: _castArray(_get(item, 'controlledOption')),
        [COLUMNS.VALUES]: _get(item, 'controllingOptions'),
      })),
    };
  }
  return values;
};

const getPropertyValues = (fieldData) => {
  const fieldType = _get(fieldData, FIELD_IDS.FIELD_TYPE, '');
  const dataType = _get(fieldData, FIELD_IDS.DATA_TYPE, '');
  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      if (dataType === DATA_TYPES.TEXT) return getTextValuesFromPayload(fieldData);
      if (dataType === DATA_TYPES.COMPLEX) return getComplexValuesFromPayload(fieldData);
      return EMPTY_OBJECT;
    case FIELD_TYPES.LIST:
      if (dataType === DATA_TYPES.TEXT) return getTextValuesFromPayload(fieldData);
      return EMPTY_OBJECT;
    case FIELD_TYPES.RELATIONSHIP:
      return getRelationshipValuesFromPayload(fieldData);
    case FIELD_TYPES.SELECT:
      return getDropDownValuesFromPayload(fieldData);
    default:
      return EMPTY_OBJECT;
  }
};

const getConditionFromPayload = (condition) => ({
  ...condition,
  [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: _map(_get(condition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY), (criteria) => {
    const resourceFieldValue = _get(criteria, CONDITION_FIELD_IDS.FIELD, EMPTY_ARRAY);
    return {
      ...criteria,
      [CONDITION_FIELD_IDS.FIELD]: _castArray(resourceFieldValue),
    };
  }),
});

const getChildAggregateValuesFromPayload = (fieldData) => {
  let aggregateSummaryFieldInfo = _get(fieldData, 'aggregateSummaryFieldInfo', EMPTY_OBJECT);
  const values = {};
  values[FIELD_IDS.CHILD_FILTER_CONDITION] = getConditionFromPayload(_get(aggregateSummaryFieldInfo, FIELD_IDS.CHILD_FILTER_CONDITION));
  values[FIELD_IDS.UPDATE_CONDITION] = getConditionFromPayload(_get(aggregateSummaryFieldInfo, FIELD_IDS.UPDATE_CONDITION));
  aggregateSummaryFieldInfo = _omit(aggregateSummaryFieldInfo, [FIELD_IDS.CHILD_FILTER_CONDITION, FIELD_IDS.UPDATE_CONDITION]);
  _forEach(aggregateSummaryFieldInfo, (value, key) => {
    _set(values, key, _castArray(value));
  });

  values[FIELD_IDS.FILTER_CRITERIA_FOR_CHILD] = FILTER_CONDITION_OPTIONS.ONLY_CERTAIN_RECORDS;
  values[FIELD_IDS.FILTER_CRITERIA_FOR_PARENT] = FILTER_CONDITION_OPTIONS.ONLY_CERTAIN_RECORDS;

  if (_isEmpty(_get(values[FIELD_IDS.CHILD_FILTER_CONDITION], CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST))) {
    values[FIELD_IDS.FILTER_CRITERIA_FOR_CHILD] = FILTER_CONDITION_OPTIONS.ALL_RECORDS;
  }
  if (_isEmpty(_get(values[FIELD_IDS.UPDATE_CONDITION], CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST))) {
    values[FIELD_IDS.FILTER_CRITERIA_FOR_PARENT] = FILTER_CONDITION_OPTIONS.ALL_RECORDS;
  }
  return values;
};

const getPropertyValuesFromPayload = (fieldData) => {
  const values = getPropertyValues(fieldData);
  const fieldDetailsValues = _pick(fieldData, FIELD_DETAILS_FIELD_IDS);
  const allowLookupUsingField = _pick(fieldData, 'allowLookupUsingField');
  const propertyValues = {
    ...values,
    ...fieldDetailsValues,
    ...allowLookupUsingField,
  };
  if (_get(fieldData, 'sortable', false)) {
    propertyValues[FIELD_IDS.SORT_DIRECTION] = [_get(fieldData, 'defaultSortDirection', 'ASC')];
  }
  return propertyValues;
};

const getValuesFromPayload = (fieldData) => _pick(fieldData, DETAILS_FIELD_IDS);

const getSaveListOptionPayload = (rowData) => {
  const value = _get(rowData, COLUMN_IDS.VALUE, '');
  const payload = {
    fieldOption: {
      displayName: _get(rowData, COLUMN_IDS.LABEL, ''),
      value,
    },
    [MODAL_INPUT_FIELD.CONTROLLING_OPTIONS]: _get(rowData, MODAL_INPUT_FIELD.CONTROLLING_OPTIONS),
  };
  return { value, payload };
};

export {
  getPayloadFromValues,
  getDropDownValuesFromPayload,
  getPropertyValuesFromPayload,
  getValuesFromPayload,
  getSaveListOptionPayload,
  getChildAggregateValuesFromPayload,
};
