import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _isNil from 'lodash/isNil';
import _pullAt from 'lodash/pullAt';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import InputTable from '@tekion/tekion-components/organisms/inputTable/containers/withRowActions/Table';
import Button from '@tekion/tekion-components/atoms/Button';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/molecules/tableInputField/constants/TableInputField.actionTypes';

// Helpers
import { getActionsForRow } from './helpers/dependencyConfigTable.general';
import { getColumns } from './helpers/dependencyConfigTable.columns';

// Constants
import { FORM_MODES } from '../../../../../../../../../constants/general.constants';
import { ROW_ACTION_PROPS, DEPENDENCY_CONFIG_TABLE_ACTION_TYPES } from './constants/dependencyConfigTable.general';
import DEPENDENCY_CONFIG_FORM_FIELD_IDS from '../../constants/dependencyConfigForm.fieldIds';

// Styles
import styles from '../../../../fieldsForm.module.scss';

const DependencyConfigTable = ({ id, formMode, value, error, entity, onAction }) => {
  const fieldDefinitions = _get(entity, 'fieldDefinitions', EMPTY_ARRAY);
  const selectedFields = _map(value, ({ fieldName }) => getArraySafeValue(fieldName));
  const handleAction = useCallback(
    (action) => {
      const actionType = _get(action, 'type', EMPTY_STRING);

      if (actionType === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
        const index = _get(action, 'payload.nestingPath.[0]');
        const fieldId = _get(action, 'payload.id');
        const fieldValue = _get(action, 'payload.value');
        _set(value, [index, fieldId], fieldValue);
        onAction({
          type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
          payload: {
            id: DEPENDENCY_CONFIG_FORM_FIELD_IDS.DEPENDENCY_CONFIG_TABLE,
            value,
          },
        });
      } else if (actionType === TABLE_ACTION_TYPES.TABLE_ACTION_CLICK) {
        const { payload = EMPTY_OBJECT } = action;
        const tableActionType = _get(payload, 'actionType', EMPTY_STRING);

        if (tableActionType === DEPENDENCY_CONFIG_TABLE_ACTION_TYPES.REMOVE_ROW) {
          const { nestingPath } = payload;
          _pullAt(value, nestingPath);
          onAction({
            type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
            payload: {
              id: DEPENDENCY_CONFIG_FORM_FIELD_IDS.DEPENDENCY_CONFIG_TABLE,
              value,
            },
          });
        }
      }
    },
    [onAction, value],
  );

  const columns = useMemo(() => getColumns(fieldDefinitions, selectedFields), [fieldDefinitions, selectedFields]);

  const additional = useMemo(() => ({ formMode }), [formMode]);

  const handleAddRow = useCallback(() => {
    onAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: DEPENDENCY_CONFIG_FORM_FIELD_IDS.DEPENDENCY_CONFIG_TABLE, value: [...(_isNil(value) ? [] : value), {}] },
    });
  }, [value, onAction]);

  return (
    <div>
      <InputTable
        id={id}
        className={styles.inputTable}
        rowActionProps={ROW_ACTION_PROPS}
        additional={additional}
        error={error}
        columns={columns}
        value={value}
        onAction={handleAction}
        getActionsForRow={getActionsForRow}
        onClick={handleAction}
      />
      <Button className={styles.addButton} view={Button.VIEW.TERTIARY} onClick={handleAddRow}>
        {__('Add Dependency')}
      </Button>
    </div>
  );
};

DependencyConfigTable.propTypes = {
  formMode: PropTypes.string,
  id: PropTypes.string,
  entity: PropTypes.object,
  value: PropTypes.array,
  error: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

DependencyConfigTable.defaultProps = {
  formMode: FORM_MODES.CREATE,
  id: EMPTY_STRING,
  entity: EMPTY_OBJECT,
  value: EMPTY_ARRAY,
  error: undefined,
};

export default React.memo(DependencyConfigTable);
