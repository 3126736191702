import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import PAGE_IDS from '../../../../constants/pageIds.constants';
import { COMMENT_FIELDS } from '../../../../../../organisms/commentRenderer/constants/commentRenderer.constants';
import { APPROVAL_ASSET_TYPE } from '../../../../../../constants/approvalCentre.constants';

const getCommentsPayload = (approvalId, nextPageToken) => ({
  filters: [
    {
      field: COMMENT_FIELDS.DELETED,
      filterType: OPERATORS.IN,
      values: [false],
    },
    {
      field: COMMENT_FIELDS.ASSET_TYPE,
      filterType: OPERATORS.IN,
      values: [APPROVAL_ASSET_TYPE],
    },
    {
      field: COMMENT_FIELDS.ASSET_ID,
      filterType: OPERATORS.IN,
      values: [approvalId],
    },
    {
      field: COMMENT_FIELDS.IN_REPLY_TO,
      filterType: OPERATORS.IN,
      values: [null],
    },
  ],
  rows: 20,
  countRequired: true,
  nextPageToken,
});

const getApprovalRequestTopNavigationDefaultData = (isMountedInsideApplication, appRoute, approvalTabId) => {
  let goTo;

  if (isMountedInsideApplication) {
    goTo = `${appRoute}/${PAGE_IDS.APPROVAL_CENTRE}/${PAGE_IDS.MANAGEMENT}/${approvalTabId}`;
  } else {
    goTo = `/${PAGE_IDS.APPROVAL_CENTRE}/${PAGE_IDS.MANAGEMENT}/${approvalTabId}`;
  }

  return [{ label: __('Approval Centre'), goTo, key: '1' }];
};

const getPayloadForUserIds = (ids) => ({
  filters: [
    {
      field: 'id',
      values: ids,
      filterType: OPERATORS.IN,
    },
  ],
});

const getPayloadForCreateComment = (approvalId, commentContent) => ({
  assetType: APPROVAL_ASSET_TYPE,
  assetId: approvalId,
  inReplyTo: null,
  content: commentContent,
});

export { getCommentsPayload, getApprovalRequestTopNavigationDefaultData, getPayloadForUserIds, getPayloadForCreateComment };
