import { useCallback } from 'react';
import { getPlatePluginType } from '@udecode/plate-core';
import { insertNodes } from '@udecode/plate-common';
import useEditor from '../../../../hooks/useEditor';

const handleMediaNodeCreation = ({ e, editor, nodeType }) => {
  const {
    files: [mediaFile],
  } = e.target;
  const text = { text: '' };
  const image = {
    type: getPlatePluginType(editor, nodeType),
    data: {
      selectedFile: mediaFile,
    },
    children: [text],
  };
  insertNodes(editor, image);
};

function useFileSelection(nodeType) {
  const editor = useEditor();

  const onFileSelection = useCallback(
    (e) =>
      handleMediaNodeCreation({
        e,
        editor,
        nodeType,
      }),
    [editor, nodeType],
  );

  return {
    onFileSelection,
  };
}

export default useFileSelection;
