exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir=ltr] .recordType_collapse__twPKuQXrJU{border-left:none}[dir=rtl] .recordType_collapse__twPKuQXrJU{border-right:none}[dir] .recordType_collapseStyle__noPxS4ZWkQ{margin-bottom:2rem}.recordType_applicableFieldsHeader__mkfGuuNrpm{font-size:1.6rem;font-weight:bold}[dir] .recordType_applicableFieldsHeader__mkfGuuNrpm{padding:1rem 2rem}.recordType_addFieldHeader__51FfLapqCU{display:flex;justify-content:space-between;align-items:center}[dir] .recordType_addFieldHeader__51FfLapqCU{margin-bottom:1rem}.recordType_condition__kLFJEgfCPq{max-width:100%}[dir] .recordType_condition__kLFJEgfCPq{padding:0}.recordType_conditionBuilder__qeFYfQ8aho{width:50%}.recordType_errorText__sNpuscctTh{color:red;font-size:small}[dir=ltr] .recordType_errorText__sNpuscctTh{margin-left:1rem}[dir=rtl] .recordType_errorText__sNpuscctTh{margin-right:1rem}", ""]);

// Exports
exports.locals = {
	"collapse": "recordType_collapse__twPKuQXrJU",
	"collapseStyle": "recordType_collapseStyle__noPxS4ZWkQ",
	"applicableFieldsHeader": "recordType_applicableFieldsHeader__mkfGuuNrpm",
	"addFieldHeader": "recordType_addFieldHeader__51FfLapqCU",
	"condition": "recordType_condition__kLFJEgfCPq",
	"conditionBuilder": "recordType_conditionBuilder__qeFYfQ8aho",
	"errorText": "recordType_errorText__sNpuscctTh"
};