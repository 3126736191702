// Components
import Button from '@tekion/tekion-components/atoms/Button';

// Constants
import ACTION_TYPES from '../constants/tenantUserList.actionTypes';

const HEADER_PROPS = {
  label: 'Tenant Universe User List',
};

const TABLE_MANAGER_PROPS = {
  showHeader: true,
  showSearch: false,
  showFilter: false,
  showSubHeader: true,
  showRefreshIcon: true,
  showSearchByField: true,
};

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Add new user'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_USER,
    },
  ],
};

export { HEADER_PROPS, SUB_HEADER_PROPS, TABLE_MANAGER_PROPS };
