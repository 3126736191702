import React from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { getFieldTags } from './tagGenerator.helper';

import styles from './tagGenerator.module.scss';

const TagGenerator = (props) => {
  const { fields } = props;

  const { newFields, remainingFields } = getFieldTags(fields);

  return (
    <div className={styles.fieldTagContainer}>
      {_map(newFields, (field, index) => (
        <div key={index} className={styles.fieldTag}>
          {__(`${field}`)}
        </div>
      ))}
      {remainingFields && <div className={styles.fieldTag}>{__(`${remainingFields}`)}</div>}
    </div>
  );
};

TagGenerator.propTypes = {
  fields: PropTypes.array,
};

TagGenerator.defaultProps = {
  fields: EMPTY_ARRAY,
};

export default TagGenerator;
