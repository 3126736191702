import _property from 'lodash/property';

import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import COLUMN_IDS from '../constants/externalCredentialList.columnIds';

const COLUMN_CONFIG = [
  {
    Header: __('Display Name'),
    id: COLUMN_IDS.DISPLAY_NAME,
    accessor: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Name'),
    id: COLUMN_IDS.NAME,
    accessor: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Auth Type'),
    id: COLUMN_IDS.TYPE,
    accessor: _property([COLUMN_IDS.AUTH_PROTOCOL, COLUMN_IDS.TYPE]),
    Cell: TextRenderer,
  },
];

export default COLUMN_CONFIG;
