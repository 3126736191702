import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';
import RadioInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import DatePickerField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/datePicker';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import StageApproversSelectField from '../components/stageApproversSelectField/StageApproversSelectField';

import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../../../../../../constants/approvalCentre.constants';
import { VALIDITY_TYPE_OPTIONS } from '../../../constants/approvalProcessForm.valueConstants';

const STAGE_APPROVERS_FIELD = {
  id: APPROVAL_CENTRE_FIELD_IDS.APPROVERS,
  renderer: StageApproversSelectField,
  renderOptions: {
    id: APPROVAL_CENTRE_FIELD_IDS.APPROVERS,
    validators: [isRequiredRule],
  },
};

const MINIMUM_APPROVALS_FIELD = {
  id: APPROVAL_CENTRE_FIELD_IDS.MIN_APPROVALS,
  renderer: NumberInputField,
  renderOptions: {
    required: true,
    label: __('Minimum Approvals'),
    min: 1,
    placeholder: __('Enter Here'),
    validators: [isRequiredRule],
  },
};

const VALIDITY_TYPE_FIELD = {
  id: APPROVAL_CENTRE_FIELD_IDS.VALIDITY_TYPE,
  renderer: RadioInputField,
  renderOptions: {
    required: true,
    label: __('Stage Validity'),
    size: 6,
    radios: VALIDITY_TYPE_OPTIONS,
    validators: [isRequiredRule],
  },
};

const DAYS_INPUT_FIELD = {
  id: APPROVAL_CENTRE_FIELD_IDS.VALIDITY_DAYS,
  renderer: NumberInputField,
  renderOptions: {
    required: true,
    label: __('Days'),
    min: 1,
    placeholder: __('Enter Here'),
    validators: [isRequiredRule],
  },
};

const HOURS_INPUT_FIELD = {
  id: APPROVAL_CENTRE_FIELD_IDS.VALIDITY_HOURS,
  renderer: NumberInputField,
  renderOptions: {
    required: true,
    label: __('Hours'),
    min: 1,
    placeholder: __('Enter Here'),
    validators: [isRequiredRule],
  },
};

const MINUTES_INPUT_FIELD = {
  id: APPROVAL_CENTRE_FIELD_IDS.VALIDITY_MINUTES,
  renderer: NumberInputField,
  renderOptions: {
    required: true,
    label: __('Minutes'),
    min: 1,
    placeholder: __('Enter Here'),
    validators: [isRequiredRule],
  },
};

const DATE_PICKER_FIELD = {
  id: APPROVAL_CENTRE_FIELD_IDS.VALIDITY_EPOCH,
  renderer: DatePickerField,
  renderOptions: {
    required: true,
    dateFormat: DATE_TIME_FORMATS.DATE_MONTH_YEAR_WITH_TIME_IN_24HR_FORMAT,
    validators: [isRequiredRule],
  },
};

const APPROVAL_STAGE_INPUT_FIELDS = {
  [APPROVAL_CENTRE_FIELD_IDS.APPROVERS]: STAGE_APPROVERS_FIELD,
  [APPROVAL_CENTRE_FIELD_IDS.MIN_APPROVALS]: MINIMUM_APPROVALS_FIELD,
  [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_TYPE]: VALIDITY_TYPE_FIELD,
  [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_DAYS]: DAYS_INPUT_FIELD,
  [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_HOURS]: HOURS_INPUT_FIELD,
  [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_MINUTES]: MINUTES_INPUT_FIELD,
  [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_EPOCH]: DATE_PICKER_FIELD,
};

const getFields = ({ selectedApproverType, selectedApproverOptions, approverOptions, isFetchingApprovers }) => ({
  ...APPROVAL_STAGE_INPUT_FIELDS,
  [APPROVAL_CENTRE_FIELD_IDS.APPROVERS]: addToRenderOptions(APPROVAL_STAGE_INPUT_FIELDS[APPROVAL_CENTRE_FIELD_IDS.APPROVERS], [
    {
      path: 'selectedApproverType',
      value: selectedApproverType,
    },
    {
      path: 'selectedApproverOptions',
      value: selectedApproverOptions,
    },
    {
      path: 'approverOptions',
      value: approverOptions,
    },
    {
      path: 'isFetchingApprovers',
      value: isFetchingApprovers,
    },
  ]),
});

export default getFields;
