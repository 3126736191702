import produce from 'immer';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _snakeCase from 'lodash/snakeCase';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import {
  createExternalCredential,
  fetchExternalCredentialByName,
  fetchExternalCredentialByNameWithReveal,
  updateExternalCredentialByName,
} from '../../../../../../../actions/externalCredential.actions';

import { getPayloadForExternalCredentials } from './externalCredentialForm.helpers';
import { getFlattenedObject } from '../../../../../../../helpers/general.helpers';

import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import { FORM_MODES } from '../../../../../../../constants/general.constants';
import PAGE_IDS from '../../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/externalCredentialForm.actionTypes';
import { FIELD_IDS } from '../constants/externalCredentialForm.constants';
// eslint-disable-next-line max-len
import { CREATABLE_ASYNC_SELECT_ACTION_TYPES } from '../../../../../../../molecules/asyncPaginatedCreatableSelect/asyncPaginatedCreatableSelect.constants';

const handleInit = async ({ getState, setState }) => {
  const { history, match } = getState();
  const externalCredentialName = _get(match, 'params.externalCredentialName');
  const isRedirectedBackFromAuthProvider = tget(history, 'location.state.isRedirectedBackFromAuthProvider', false);

  if (isRedirectedBackFromAuthProvider) {
    const externalCredentialFormValue = tget(history, 'location.state.externalCredentialFormValue', EMPTY_OBJECT);
    const authProviderDetails = tget(history, 'location.state.authProviderDetails', EMPTY_OBJECT);

    const newExternalCredentialFormValue = {
      ...externalCredentialFormValue,
      [FIELD_IDS.AUTH_PROVIDER_NAME]: tget(authProviderDetails, ['value']),
    };

    setState({ formMode: FORM_MODES.CREATE, externalCredentialFormValue: newExternalCredentialFormValue, authProviderDetails });
  } else if (_isEmpty(externalCredentialName)) {
    setState({ formMode: FORM_MODES.CREATE });
  } else {
    setState({ isDataLoading: true, formMode: FORM_MODES.EDIT });

    const externalCredentialFormValue = await fetchExternalCredentialByName(externalCredentialName);

    const flattenedExternalCredentialFormValue = getFlattenedObject(externalCredentialFormValue);
    const selectedExternalCredentialType = tget(flattenedExternalCredentialFormValue, [FIELD_IDS.TYPE]);

    setState({ externalCredentialFormValue: flattenedExternalCredentialFormValue, isDataLoading: false, selectedExternalCredentialType });
  }
};

const handleFieldChange = ({ getState, setState, params }) => {
  const { formMode, selectedExternalCredentialType = EMPTY_STRING, disableAutoFillForName = false } = getState();
  const { id, value } = params;

  setState(
    produce((draft) => {
      _set(draft, `externalCredentialFormValue.${id}`, value);

      if (formMode === FORM_MODES.CREATE && id === FIELD_IDS.DISPLAY_NAME && !disableAutoFillForName) {
        _set(draft, `externalCredentialFormValue.${FIELD_IDS.NAME}`, _snakeCase(value));
      } else if (id === FIELD_IDS.NAME) {
        _set(draft, 'disableAutoFillForName', true);
      } else if (id === FIELD_IDS.TYPE && formMode === FORM_MODES.EDIT) {
        _set(draft, 'isExternalCredentialTypeChanged', selectedExternalCredentialType !== value);
      }
    }),
  );
};

const handleErrors = ({ setState, params }) => {
  const { errors } = params;

  setState({ errors });
};

const handleRedirection = ({ getState }) => {
  const { history, externalCredentialFormValue } = getState();
  const historyState = tget(history, 'location.state', EMPTY_OBJECT);
  const isRedirectedFromServerCredential = tget(historyState, 'isRedirectedFromServerCredential', false);

  if (isRedirectedFromServerCredential) {
    const label = _get(externalCredentialFormValue, [FIELD_IDS.DISPLAY_NAME]);
    const value = _get(externalCredentialFormValue, [FIELD_IDS.NAME]);
    const path = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.SERVER_CREDENTIAL_CREATE}`;

    history.push({
      pathname: path,
      state: { ...historyState, isRedirectedBackExternalCredential: true, externalCredentialDetails: { label, value } },
    });
  } else {
    const path = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.EXTERNAL_CREDENTIALS_LIST}`;
    history.push({ pathname: path });
  }
};

const handleSubmit = async ({ getState, setState }) => {
  const { externalCredentialFormValue, formMode } = getState();
  const externalCredentialName = _get(externalCredentialFormValue, [FIELD_IDS.NAME]);

  const payloadForExternalCredentials = getPayloadForExternalCredentials(externalCredentialFormValue);
  let response = {};

  setState({ isSaveLoading: true });

  if (formMode === FORM_MODES.CREATE) {
    response = await createExternalCredential(payloadForExternalCredentials);
  } else if (formMode === FORM_MODES.EDIT) {
    response = await updateExternalCredentialByName(externalCredentialName, payloadForExternalCredentials);
  }

  if (externalCredentialName === _get(response, [FIELD_IDS.NAME])) {
    handleRedirection({ getState });
  }

  setState({ isSaveLoading: false });
};

const handleOnReveal = async ({ getState, setState }) => {
  const { externalCredentialFormValue, isExternalCredentialSectionVisible = false } = getState();
  const externalCredentialName = _get(externalCredentialFormValue, [FIELD_IDS.NAME]);

  if (!isExternalCredentialSectionVisible) {
    setState({ isExternalCredentialsLoading: true });
    const response = await fetchExternalCredentialByNameWithReveal(externalCredentialName);

    const flattenedExternalCredentialFormValue = getFlattenedObject(response);

    setState({
      externalCredentialFormValue: flattenedExternalCredentialFormValue,
      isExternalCredentialsLoading: false,
      isExternalCredentialSectionVisible: true,
    });
  } else {
    setState({
      isExternalCredentialSectionVisible: false,
    });
  }
};

const handleCreateAuthProvider = ({ getState }) => {
  const { history, externalCredentialFormValue } = getState();
  const historyState = tget(history, 'location.state', EMPTY_OBJECT);

  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.AUTH_PROVIDER_CREATE}`;

  history.push({ pathname, state: { ...historyState, externalCredentialFormValue, isRedirectedFromExternalCredential: true } });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
  [ACTION_TYPES.ON_REVEAL_CLICK]: handleOnReveal,
  [CREATABLE_ASYNC_SELECT_ACTION_TYPES.ON_CREATE_NEW_OPTION]: handleCreateAuthProvider,
};

export default ACTION_HANDLERS;
