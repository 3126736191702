/* eslint-disable import/order */
import { useCallback } from 'react';
import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';

// Helpers
import { setMarks } from '@udecode/plate-common';

// Utils
import { getEditorSafeSelection } from '../../../../../utils/toolbar.selection';

const handleColorSelection = ({ onCloseDropdown, editor, fontColorMarkType, color }) => {
  const editorSelection = getEditorSafeSelection(editor);
  Transforms.select(editor, editorSelection);
  setMarks(editor, { [fontColorMarkType]: color });
  ReactEditor.focus(editor);
  onCloseDropdown();
};

function useOnColorSelection(onCloseDropdown, editor, fontColorMarkType) {
  const onColorSelection = useCallback(
    (color) => {
      handleColorSelection({ onCloseDropdown, editor, fontColorMarkType, color });
    },
    [onCloseDropdown, editor, fontColorMarkType],
  );

  return onColorSelection;
}

export default useOnColorSelection;
