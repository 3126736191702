import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { COMPONENT_CONFIG_KEYS, PAGE_CONFIGURATION_FIELD_IDS } from '../../constants/visualBuilder.general.constants';

const getUpdatedPageContainers = (pageConfiguration, updatedLayout) => {
  let containers = tget(pageConfiguration, PAGE_CONFIGURATION_FIELD_IDS.CONTAINERS, EMPTY_ARRAY);
  const layoutById = _keyBy(updatedLayout, 'i');
  containers = _map(containers, (container) => {
    let layout = tget(layoutById, tget(container, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING), EMPTY_OBJECT);
    layout = {
      width: tget(layout, 'w', 2),
      height: tget(layout, 'h', 2),
      row: tget(layout, 'x', 0),
      column: tget(layout, 'y', 0),
    };
    const properties = { ...tget(container, 'properties', EMPTY_OBJECT), layout };
    const newContainer = { ...container, properties };
    return newContainer;
  });

  return containers;
};

export { getUpdatedPageContainers };
