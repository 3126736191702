import React, { useCallback, useEffect, useMemo } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';
import Button from '@tekion/tekion-components/atoms/Button';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import withActions from '@tekion/tekion-components/connectors/withActions';

import WithSize from '../../../../../../connectors/withSize';

import getAuthProviderFields from './helpers/authProviderForm.fields';
import getAuthProviderFormSection from './helpers/authProviderForm.sections';
import { getFieldsBasedOnProviderType } from './helpers/authProviderForm.helpers';
import ACTION_HANDLERS from './helpers/authProviderForm.actionHandlers';

import { FORM_MODES } from '../../../../../../constants/general.constants';
import ACTION_TYPES from './constants/authProviderForm.actionTypes';
import { AUTH_PROVIDER_FORM_CONTEXT_ID, FIELD_IDS } from './constants/authProviderForm.constants';

import styles from './authProviderForm.module.scss';

const AuthProviderForm = ({
  isDataLoading,
  isSaveLoading,
  isAuthDetailLoading,
  isAuthDetailSectionVisible,
  isProviderTypeChanged,
  contentHeight,
  formMode,
  authFormValue,
  errors,
  onAction,
}) => {
  const formHeading = formMode === FORM_MODES.CREATE ? __('Create Auth Provider') : __('Edit Auth Provider');
  const providerType = tget(authFormValue, FIELD_IDS.PROVIDER_TYPE, EMPTY_STRING);
  const fields = useMemo(() => getAuthProviderFields(formMode), [formMode]);
  const fieldsBasedOnProviderType = useMemo(() => getFieldsBasedOnProviderType(providerType), [providerType]);

  const handleRevealClick = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_REVEAL_CLICK });
  }, [onAction]);

  const handleRedirect = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_REDIRECTION,
    });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(AUTH_PROVIDER_FORM_CONTEXT_ID);
  }, []);

  const renderAuthDetailsShowButton = useCallback(
    () => (
      <Button loading={isAuthDetailLoading} view={Button.VIEW.TERTIARY} className={styles.showButton} onClick={handleRevealClick}>
        {isAuthDetailSectionVisible ? __('Hide Auth Configuration Details') : __('Show Auth Configuration Details')}
        <FontIcon className="inline m-l-8" size={SIZES.MD_S}>
          {isAuthDetailSectionVisible ? 'icon-eye' : 'icon-eye-outline'}
        </FontIcon>
      </Button>
    ),
    [isAuthDetailLoading, handleRevealClick, isAuthDetailSectionVisible],
  );

  const renderAuthProviderDetailSectionHeader = useCallback(
    () => (
      <PropertyControlledComponent
        controllerProperty={formMode === FORM_MODES.CREATE || isProviderTypeChanged}
        fallback={renderAuthDetailsShowButton()}
      >
        {__('Auth Configuration Details')}
      </PropertyControlledComponent>
    ),
    [isProviderTypeChanged, formMode, renderAuthDetailsShowButton],
  );

  const section = useMemo(
    () =>
      getAuthProviderFormSection({
        isAuthDetailSectionVisible,
        isProviderTypeChanged,
        formMode,
        providerType,
        fieldsBasedOnProviderType,
        renderAuthProviderDetailSectionHeader,
      }),
    [isAuthDetailSectionVisible, isProviderTypeChanged, formMode, providerType, fieldsBasedOnProviderType, renderAuthProviderDetailSectionHeader],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction]);

  return (
    <Page className="full-height full-width">
      <Page.Header hasBack goBackHandler={handleRedirect}>
        <Heading className="full-width">{formHeading}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          isFetching={isDataLoading}
          contextId={AUTH_PROVIDER_FORM_CONTEXT_ID}
          fields={fields}
          sections={section}
          values={authFormValue}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          primaryActionLoading={isSaveLoading}
          primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Create') : __('Update')}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleRedirect}
        />
      </Page.Footer>
    </Page>
  );
};

AuthProviderForm.propTypes = {
  isDataLoading: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  isAuthDetailSectionVisible: PropTypes.bool,
  isAuthDetailLoading: PropTypes.bool,
  isProviderTypeChanged: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  authFormValue: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

AuthProviderForm.defaultProps = {
  isDataLoading: false,
  isSaveLoading: false,
  isAuthDetailSectionVisible: false,
  isAuthDetailLoading: false,
  isProviderTypeChanged: false,
  formMode: FORM_MODES.CREATE,
  authFormValue: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default compose(WithSize({ hasPageFooter: 1, hasPageHeader: 1 }), withActions({}, ACTION_HANDLERS))(AuthProviderForm);
