import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _map from 'lodash/map';
import _reject from 'lodash/reject';
import _noop from 'lodash/noop';

import _isObject from 'lodash/isObject';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import Tag from '@tekion/tekion-components/atoms/Tag';

import styles from './asyncTag.module.scss';

const AsyncTag = (props) => {
  const { areTagsDeletable, tagClassName, value, onChange, tagsWrapperClassName } = props;

  const handleRemoveTag = useCallback(
    (val) => {
      onChange(_reject(value, ['value', val]), {});
    },
    [value, onChange],
  );

  const renderTag = useCallback(
    (selectedValue) => {
      if (!_isObject(selectedValue) || !selectedValue) return null;
      return (
        <Tag className={cx(styles.tag, tagClassName)} data={selectedValue.value} deletable={areTagsDeletable} onDelete={handleRemoveTag}>
          {selectedValue.label}
        </Tag>
      );
    },
    [areTagsDeletable, tagClassName, handleRemoveTag],
  );

  return <div className={cx(styles.tags, tagsWrapperClassName)}>{_map(value, renderTag)}</div>;
};
AsyncTag.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  tagsWrapperClassName: PropTypes.string,
  areTagsDeletable: PropTypes.bool,
  tagClassName: PropTypes.string,
};
AsyncTag.defaultProps = {
  value: EMPTY_ARRAY,
  onChange: _noop,
  tagsWrapperClassName: '',
  tagClassName: '',
  areTagsDeletable: true,
};
export default AsyncTag;
