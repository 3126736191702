const COLUMN_IDS = {
  ID: 'id',
  DISPLAY_NAME: 'displayName',
  NAME: 'name',
  URL: 'url',
  EXTERNAL_CREDENTIAL_NAME: 'externalCredentialName',
  CLIENT_CERTIFICATE: 'clientCertificate',
  GENERATE_AUTH_HEADERS: 'generateAuthHeaders',
  ALLOW_FORMULA_IN_HEADERS: 'allowFormulaInHeaders',
  ALLOW_FORMULA_IN_BODY: 'allowFormulaInBody',
  OPEN_API_SPECIFICATION_ID: 'openApiSpecificationId',
};

export default COLUMN_IDS;
