import React from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import Collapse from '@tekion/tekion-components/molecules/Collapse';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';

import ConditionBuilder from '../../../../../../organisms/conditionBuilder/ConditionBuilder';

import CONDITION_BUILDER_MODES from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.modes';
import { CONDITION_BUILDER_TYPES } from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import { FIELD_IDS } from '../../constants/recordType.constants';

import styles from '../../recordType.module.scss';

const DerivationCondition = ({ childProps, error, value, onAction }) => (
  <div className="full-width">
    <Collapse bodyClassName={styles.collapse} className={styles.collapseStyle} defaultActiveKey={FIELD_IDS.DERIVATION_CONDITION}>
      <Collapse.Panel
        key={FIELD_IDS.DERIVATION_CONDITION}
        panelKey={FIELD_IDS.DERIVATION_CONDITION}
        header={<div className={styles.applicableFieldsHeader}>{__('Derivation Condition')}</div>}
      >
        <div className={styles.conditionBuilder}>
          <ConditionBuilder
            required
            validators={[isRequiredRule]}
            value={value}
            id={FIELD_IDS.DERIVATION_CONDITION}
            mode={CONDITION_BUILDER_MODES.CONDITION_MODE}
            conditionBuilderType={CONDITION_BUILDER_TYPES.CRITERIA}
            childProps={childProps}
            error={error}
            containerClassname={fieldLayoutStyles.fieldC}
            onAction={onAction}
          />
          <PropertyControlledComponent controllerProperty={!_isEmpty(_get(error, 'errorMessage'))}>
            <span className={styles.errorText}>{_get(error, 'errorMessage', EMPTY_STRING)}</span>
          </PropertyControlledComponent>
        </div>
      </Collapse.Panel>
    </Collapse>
  </div>
);

DerivationCondition.propTypes = {
  childProps: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default DerivationCondition;
