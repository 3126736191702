import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { KEY_CODES } from '@tekion/tekion-components/organisms/FormBuilder/constants/inputKeys';

const onInputKeyPress = ({ params = EMPTY_OBJECT, getState }) => {
  if (params.value === KEY_CODES.ENTER) {
    const { contextId } = getState();
    triggerSubmit(contextId);
  }
};

const ACTION_HANDLER = {
  [FORM_ACTION_TYPES.ON_KEY_PRESS]: onInputKeyPress,
};

export default ACTION_HANDLER;
