import _map from 'lodash/map';

import SORT_ORDER_KEY from '@tekion/tekion-base/constants/sortOrder';

import { createRadioOption } from '../helpers/fieldsForm.helpers';
import FIELD_IDS from './fieldsForm.fieldIds';

const FIELD_FORM_CONTEXT_ID = 'fieldDetailsForm';

const FIELD_TYPES = {
  TEXT: 'TEXT',
  LIST: 'LIST',
  RANGE: 'RANGE',
  RELATIONSHIP: 'RELATIONSHIP',
  SELECT: 'SELECT',
  SINGLE_SELECT: 'SINGLE_SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
  CHILD_AGGREGATE_SUMMARY: 'AGGREGATE_SUMMARY',
  RICH_TEXT_EDITOR: 'RICH_TEXT',
  MEDIA: 'MEDIA',
};

const FIELD_TYPES_LABELS = {
  [FIELD_TYPES.TEXT]: __('Input'),
  [FIELD_TYPES.LIST]: __('List'),
  [FIELD_TYPES.RANGE]: __('Range'),
  [FIELD_TYPES.RELATIONSHIP]: __('Relationship'),
  [FIELD_TYPES.SELECT]: __('Select'),
  [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: __('Child Aggregate Summary'),
  [FIELD_TYPES.RICH_TEXT_EDITOR]: __('Text Editor'),
  [FIELD_TYPES.MEDIA]: __('Media'),
};

const DATA_TYPES = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  BOOLEAN: 'BOOLEAN',
  COMPLEX: 'COMPLEX',
  DATE_TIME: 'DATE_TIME',
};

const DATA_TYPES_LABELS = {
  [DATA_TYPES.TEXT]: __('Text'),
  [DATA_TYPES.NUMBER]: __('Number'),
  [DATA_TYPES.DATE]: __('Date'),
  [DATA_TYPES.BOOLEAN]: __('Boolean'),
  [DATA_TYPES.COMPLEX]: __('Complex'),
  [DATA_TYPES.DATE_TIME]: __('Date Time'),
};

const FIELD_TYPE_OPTIONS = [
  { label: FIELD_TYPES_LABELS[FIELD_TYPES.TEXT], value: FIELD_TYPES.TEXT },
  { label: FIELD_TYPES_LABELS[FIELD_TYPES.RANGE], value: FIELD_TYPES.RANGE },
  { label: FIELD_TYPES_LABELS[FIELD_TYPES.SELECT], value: FIELD_TYPES.SELECT },
  { label: FIELD_TYPES_LABELS[FIELD_TYPES.RELATIONSHIP], value: FIELD_TYPES.RELATIONSHIP },
];

const FIELD_TYPE_OPTIONS_TEXT = [
  { label: FIELD_TYPES_LABELS[FIELD_TYPES.RICH_TEXT_EDITOR], value: FIELD_TYPES.RICH_TEXT_EDITOR },
  { label: FIELD_TYPES_LABELS[FIELD_TYPES.MEDIA], value: FIELD_TYPES.MEDIA },
];

const FIELD_TYPE_OPTIONS_NUMBER = [{ label: FIELD_TYPES_LABELS[FIELD_TYPES.CHILD_AGGREGATE_SUMMARY], value: FIELD_TYPES.CHILD_AGGREGATE_SUMMARY }];

const DATA_TYPE_OPTIONS = [
  { label: DATA_TYPES_LABELS[DATA_TYPES.TEXT], value: DATA_TYPES.TEXT },
  { label: DATA_TYPES_LABELS[DATA_TYPES.NUMBER], value: DATA_TYPES.NUMBER },
  { label: DATA_TYPES_LABELS[DATA_TYPES.DATE], value: DATA_TYPES.DATE },
  { label: DATA_TYPES_LABELS[DATA_TYPES.DATE_TIME], value: DATA_TYPES.DATE_TIME },
  { label: DATA_TYPES_LABELS[DATA_TYPES.BOOLEAN], value: DATA_TYPES.BOOLEAN },
  { label: DATA_TYPES_LABELS[DATA_TYPES.COMPLEX], value: DATA_TYPES.COMPLEX },
];

const DETAILS_FIELD_IDS = [
  FIELD_IDS.NAME,
  FIELD_IDS.DISPLAY_NAME,
  FIELD_IDS.FIELD_TYPE,
  FIELD_IDS.DATA_TYPE,
  FIELD_IDS.PAGE_LAYOUT,
  FIELD_IDS.LIST_VIEW_ENABLED,
  FIELD_IDS.DETAILED_VIEW_ENABLED,
];

const ERROR_IDS = {
  INPUT_TABLE_EMPTY_VALUE: 'inputTableEmptyValue',
  INPUT_TABLE_STRING_LENGTH: 'inputTableStringLength',
  DEPENDENCY_TABLE_EMPTY_VALUE: 'dependencyTableEmptyValue',
  CONTROLLING_OPTIONS_TABLE_EMPTY_VALUE: 'controllingOptionTableEmptyValue',
};

const ERROR_MESSAGES = {
  [ERROR_IDS.INPUT_TABLE_EMPTY_VALUE]: __('Please enter both Label and Value for dropdown options in the table.'),
  [ERROR_IDS.DEPENDENCY_TABLE_EMPTY_VALUE]: __('Please select both field and dependencyType from DependencyConfig Table or delete the row.'),
  [ERROR_IDS.CONTROLLING_OPTIONS_TABLE_EMPTY_VALUE]: __('Please select both controllingField and ControllingOptions in Controlling Options Table.'),
  [ERROR_IDS.INPUT_TABLE_STRING_LENGTH]: __('Length of Value cannot be more than 256 in the Table'),
};

const DATA_TYPE_OF_TARGET_VALUE = 'NA';
const DATA_TYPE_OF_TARGET_LABEL = __('Data Type of Target Field');
const DATA_TYPE_OF_TARGET_OPTION = [{ label: DATA_TYPE_OF_TARGET_LABEL, value: DATA_TYPE_OF_TARGET_VALUE }];

const SECTION_IDS = {
  NUMBER_SECTION: 'NUMBER_SECTION',
  TEXT_SECTION: 'TEXT_SECTION',
  RANGE_SECTION: 'RANGE_SECTION',
  LIST_INPUT_SECTION: 'LIST_INPUT_SECTION',
  LOOKUP_FIELD_CHECKBOX_SECTION: 'LOOKUP_FIELD_CHECKBOX_SECTION',
  RELATIONSHIP_SECTION: 'RELATIONSHIP_SECTION',
  FIELD_DETAILS_SECTION: 'FIELD_DETAILS_SECTION',
  COMPLEX_SECTION: 'COMPLEX_SECTION',
};

const SORT_OPTIONS = [
  { label: __(SORT_ORDER_KEY.ASC), value: SORT_ORDER_KEY.ASC },
  { label: __(SORT_ORDER_KEY.DESC), value: SORT_ORDER_KEY.DESC },
];

const DEFAULT_SORT_DIRECTION = ['ASC'];

const SUBMIT_ADDITIONAL_VALUE = {
  SAVE: 'SAVE',
  SAVE_AND_CREATE_NEW: 'SAVE_AND_CREATE_NEW',
};

const HELP_TEXT_ID = {
  TEXT_MAX_LENGTH_FILTERABLE: 'textMaxConstraintsFilterable',
  TEXT_MAX_LENGTH_SORTABLE: 'textMaxConstraintsSortable',
  UNIQUE_MANDATORY: 'uniqueMandatory',
  CREATABLE_EDITABLE: 'creatableEditable',
  SORTABLE_FILTERABLE: 'sortableFilterable',
  NUMBER_OFFSET: 'numberOffset',
  SEARCH_DISABLED: 'searchDisabled',
  ALLOW_LOOKUP_ENABLED: 'allowLookUpEnabled',
  ALLOW_LOOKUP_DISABLED: 'allowLookUpDisabled',
  UNIQUE_DISABLED: 'uniqueDisabled',
  NUMBER_SEARCH_DISABLED: 'numberSearchDisabled',
  RICH_TEXT_EDITOR_FIELD_FILTERABLE: 'richTextEditorFieldFilterable',
  RICH_TEXT_EDITOR_FIELD_SORTABLE: 'richTextEditorFieldSortable',
};

const HELP_TEXT = {
  [HELP_TEXT_ID.TEXT_MAX_LENGTH_FILTERABLE]: __('Text fields with length more than 256 cannot be filterable'),
  [HELP_TEXT_ID.TEXT_MAX_LENGTH_SORTABLE]: __('Text fields with length more than 256 cannot be sortable'),
  [HELP_TEXT_ID.UNIQUE_MANDATORY]: __('Unique fields need to be mandatory.'),
  [HELP_TEXT_ID.CREATABLE_EDITABLE]: __('Editable fields should be creatable.'),
  [HELP_TEXT_ID.SORTABLE_FILTERABLE]: __('Sortable fields need to be filterable.'),
  [HELP_TEXT_ID.NUMBER_OFFSET]: __('Auto Increment is selected '),
  [HELP_TEXT_ID.SEARCH_DISABLED]: __('Field cannot be searchable if the entity is not searchable.'),
  [HELP_TEXT_ID.ALLOW_LOOKUP_ENABLED]: __('Allow Look Up is Selected'),
  [HELP_TEXT_ID.ALLOW_LOOKUP_DISABLED]: __('Length of Text Field is more than 256'),
  [HELP_TEXT_ID.UNIQUE_DISABLED]: __(' fields cannot be unique'),
  [HELP_TEXT_ID.NUMBER_SEARCH_DISABLED]: __('Number field cannot be searchable.'),
  [HELP_TEXT_ID.RICH_TEXT_EDITOR_FIELD_FILTERABLE]: __('Rich Text Editor field cannot be filterable.'),
  [HELP_TEXT_ID.RICH_TEXT_EDITOR_FIELD_SORTABLE]: __('Rich Text Editor field cannot be sortable.'),
};

const FIELD_DETAILS_FIELD_IDS = [
  FIELD_IDS.CREATABLE,
  FIELD_IDS.EDITABLE,
  FIELD_IDS.SEARCHABLE,
  FIELD_IDS.FILTERABLE,
  FIELD_IDS.SORTABLE,
  FIELD_IDS.SORT_DIRECTION,
  FIELD_IDS.IMPORTABLE,
  FIELD_IDS.EXPORTABLE,
  FIELD_IDS.MANDATORY,
  FIELD_IDS.DISABLED,
  FIELD_IDS.UNIQUE_CONSTRAINT,
];

const CONSTRAINTS_TYPES = {
  STRING_LENGTH: 'STRING_LENGTH',
  REGEX: 'REGEX',
  NUMBER_RANGE: 'NUMBER_RANGE',
  RANGE_CONSTRAINT: 'RANGE',
  DATE_RANGE: 'DATE_RANGE',
};

const COLUMNS = {
  FIELD_NAME: 'fieldName',
  DEPENDENCY_TYPE: 'dependencyType',
  OPTION: 'option',
  VALUES: 'values',
};

const FILTER_CONDITION_OPTIONS = {
  ALL_RECORDS: 'ALL_RECORDS',
  ONLY_CERTAIN_RECORDS: 'ONLY_CERTAIN_RECORDS',
};

const FILTER_CRITERIA_FOR_CHILD_OPTIONS = [
  {
    label: __('Include all records'),
    value: FILTER_CONDITION_OPTIONS.ALL_RECORDS,
  },
  {
    label: __('Include only certain records '),
    value: FILTER_CONDITION_OPTIONS.ONLY_CERTAIN_RECORDS,
  },
];

const FILTER_CRITERIA_FOR_PARENT_OPTIONS = [
  {
    label: __('Apply to all records'),
    value: FILTER_CONDITION_OPTIONS.ALL_RECORDS,
  },
  {
    label: __('Apply to only certain records '),
    value: FILTER_CONDITION_OPTIONS.ONLY_CERTAIN_RECORDS,
  },
];

const ROLL_UP_FUNCTIONS = {
  SUM: 'SUM',
  AVG: 'AVG',
  MIN: 'MIN',
  MAX: 'MAX',
  COUNT: 'COUNT',
};

const ROLL_UP_OPTIONS = _map(ROLL_UP_FUNCTIONS, createRadioOption);

export {
  FIELD_TYPES,
  FIELD_FORM_CONTEXT_ID,
  FIELD_TYPES_LABELS,
  DATA_TYPES,
  DATA_TYPES_LABELS,
  FIELD_TYPE_OPTIONS,
  DATA_TYPE_OPTIONS,
  DETAILS_FIELD_IDS,
  ERROR_IDS,
  ERROR_MESSAGES,
  DATA_TYPE_OF_TARGET_VALUE,
  SECTION_IDS,
  SORT_OPTIONS,
  DEFAULT_SORT_DIRECTION,
  HELP_TEXT_ID,
  HELP_TEXT,
  FIELD_DETAILS_FIELD_IDS,
  CONSTRAINTS_TYPES,
  DATA_TYPE_OF_TARGET_OPTION,
  COLUMNS,
  FIELD_TYPE_OPTIONS_NUMBER,
  FILTER_CRITERIA_FOR_CHILD_OPTIONS,
  FILTER_CRITERIA_FOR_PARENT_OPTIONS,
  FILTER_CONDITION_OPTIONS,
  ROLL_UP_FUNCTIONS,
  ROLL_UP_OPTIONS,
  FIELD_TYPE_OPTIONS_TEXT,
  SUBMIT_ADDITIONAL_VALUE,
};
