import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { fetchWidget } from '../../../../../actions/reporting.actions';
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../actions/conditionBuilder.actions';
import { fetchEntityDefByName } from '../../../../../actions/entityManagement.actions';
import ACTION_TYPES from '../constants/widgetContainer.actionTypes';
import widgetReader from '../../../../../readers/reportingWidget.reader';

const handleFetchWidget = async ({ getState, setState }) => {
  const { widgetName, conditionBuilderObjectByEntity = {} } = getState();

  setState({ loading: true });

  let widget = await fetchWidget(widgetName);
  const entityName = widgetReader.entityName(widget);
  let conditionBuilderFieldDefinitionObject = _get(conditionBuilderObjectByEntity, entityName);
  if (_isEmpty(_get(conditionBuilderObjectByEntity, entityName))) {
    const mapOfVariableToEntityName = { $record: entityName };
    conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityName, {}, false, false);
    conditionBuilderObjectByEntity[entityName] = conditionBuilderFieldDefinitionObject;
  }

  widget = { ...widget, conditionBuilderFieldDefinitionObject };

  const entityDef = await fetchEntityDefByName(entityName);
  const entityFieldDefinitions = _get(entityDef, 'fieldDefinitions', EMPTY_ARRAY);
  const entityDisplayName = _get(entityDef, 'displayName');

  setState({ loading: false, widget, entityFieldDefinitions, entityDisplayName });
};

const handleEditWidget = async ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { isWidgetUpdated } = params;
  if (isWidgetUpdated) {
    await handleFetchWidget({ getState, setState });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.FETCH_WIDGET]: handleFetchWidget,
  [ACTION_TYPES.EDIT_WIDGET]: handleEditWidget,
};

export default ACTION_HANDLERS;
