// Tekion-components
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import COLUMN_IDS from './jobScheduleList.columnIds';
import ACTION_TYPES from './jobScheduleList.actionTypes';
import { JOB_SCHEDULE_STATUS_LABEL_MAP, JOB_SCHEDULE_STATUS_COLOR_MAP } from './jobScheduleList.general';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const getStatusProps = () => ({
  labelMap: JOB_SCHEDULE_STATUS_LABEL_MAP,
  colorMap: JOB_SCHEDULE_STATUS_COLOR_MAP,
});

const COLUMN_CONFIG = [
  {
    Header: __('Job Schedule Name'),
    id: COLUMN_IDS.JOB_SCHEDULE_NAME,
    accessor: COLUMN_IDS.JOB_SCHEDULE_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Description'),
    id: COLUMN_IDS.DESCRIPTION,
    accessor: COLUMN_IDS.DESCRIPTION,
    Cell: TextRenderer,
  },
  {
    Header: __('Schedule Type'),
    id: COLUMN_IDS.SCHEDULE_TYPE,
    accessor: COLUMN_IDS.SCHEDULE_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Task Type'),
    id: COLUMN_IDS.TASK_TYPE,
    accessor: COLUMN_IDS.TASK_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Status'),
    id: COLUMN_IDS.STATUS,
    accessor: COLUMN_IDS.STATUS,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
];

const EDIT_VALIDATION_RULE = {
  id: ACTION_TYPES.EDIT_JOB_SCHEDULE,
  name: __('Edit Job Schedule'),
};

const ROW_ACTIONS = [EDIT_VALIDATION_RULE];

export { COLUMN_CONFIG, ROW_ACTIONS };
