import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Tabs from '@tekion/tekion-components/molecules/Tabs';
import Loader from '@tekion/tekion-components/molecules/loader';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import Ellipsis from '@tekion/tekion-components/atoms/Ellipsis';

import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import Icon from '@tekion/tekion-components/atoms/FontIcon';

import WithSize from '../../../../../../connectors/withSize';
import ConditionBuilder from '../../../../../../organisms/conditionBuilder/ConditionBuilder';
import { FIELD_IDS } from '../../helpers/recordTypeDetailForm.fields';
import CONDITION_BUILDER_MODES from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.modes';
import { CONDITION_BUILDER_TYPES } from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import ACTION_TYPES from '../../constants/recordTypeDetailsForm.actionTypes';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';

import styles from './derivationConditionTab.module.scss';

const TAB_BAR_STYLE = { backgroundColor: '#f4f5f6', borderRight: '0.002px solid gray' };

const DerivationConditionTabSection = ({
  isLoading,
  contentHeight,
  allTabsByName,
  selectedTabId,
  conditionErrorObject,
  formValues,
  mapOfVariableToEntityName,
  conditionBuilderFieldDefinitionObject,
  onAction,
}) => {
  const handleTabClick = useCallback(
    (tab) => {
      onAction({
        type: ACTION_TYPES.ON_TAB_CLICK,
        payload: { tab },
      });
    },
    [onAction],
  );

  if (isLoading) return <Loader />;

  return (
    <div className="full-width full-height" style={{ height: contentHeight - 100 }}>
      <div className={styles.derivationConditionContainer}>{__('Derive all Conditions for Record Types.')}</div>
      <Tabs
        tabPosition="left"
        style={{ height: contentHeight - 100 }}
        activeKey={selectedTabId}
        onTabClick={handleTabClick}
        animated={false}
        tabBarStyle={TAB_BAR_STYLE}
      >
        {_map(allTabsByName, (value, tabKey) => (
          <Tabs.TabPane
            tab={
              <div className={styles.tabContainer}>
                <Ellipsis length={15} tooltip className={styles.tabs}>
                  {tabKey}
                </Ellipsis>
                {!_get(conditionErrorObject[tabKey], 'isValid', true) && <Icon className={styles.errorInfoIcon}>icon-info</Icon>}
              </div>
            }
            key={tabKey}
          >
            <div className={styles.tabContent}>
              <ConditionBuilder
                required
                validators={[isRequiredRule]}
                containerClassname={fieldLayoutStyles.fieldC}
                mode={CONDITION_BUILDER_MODES.CONDITION_MODE}
                conditionBuilderType={CONDITION_BUILDER_TYPES.CRITERIA}
                error={conditionErrorObject[tabKey]}
                childProps={{
                  [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
                    label: '',
                    childProps: {
                      [CONDITION_FIELD_IDS.FIELD]: {
                        label: __('Field'),
                        mapOfVariableToEntityName,
                        conditionBuilderFieldDefinitionObject,
                        isVariablePrefixNeeded: false,
                        variableNameForPrefixNotNeeded: '$record',
                        isDisabled: _isEmpty(_get(formValues, FIELD_IDS.DERIVATION_FIELDS)),
                      },
                      [CONDITION_FIELD_IDS.OPERATOR]: {
                        label: __('Operator'),
                        mapOfVariableToEntityName,
                        conditionBuilderFieldDefinitionObject,
                        isVariablePrefixNeeded: false,
                        variableNameForPrefixNotNeeded: '$record',
                      },
                      [CONDITION_FIELD_IDS.VALUES]: {
                        label: __('Value'),
                        mapOfVariableToEntityName,
                        conditionBuilderFieldDefinitionObject,
                        isVariablePrefixNeeded: false,
                        variableNameForPrefixNotNeeded: '$record',
                      },
                    },
                  },
                }}
                value={value}
                id={FIELD_IDS.DERIVATION_ALL_CONDITIONS}
                onAction={onAction}
              />
              <PropertyControlledComponent controllerProperty={!_isEmpty(_get(conditionErrorObject, [tabKey, 'errorMessage']))}>
                <span key={tabKey} className={styles.errorText}>
                  {_get(conditionErrorObject, [tabKey, 'errorMessage'], EMPTY_STRING)}
                </span>
              </PropertyControlledComponent>
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};
DerivationConditionTabSection.propTypes = {
  isLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  selectedTabId: PropTypes.string,
  allTabsByName: PropTypes.object,
  conditionErrorObject: PropTypes.object,
  formValues: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

DerivationConditionTabSection.defaultProps = {
  isLoading: true,
  selectedTabId: EMPTY_STRING,
  conditionErrorObject: EMPTY_OBJECT,
  formValues: EMPTY_OBJECT,
  allTabsByName: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
};

export default WithSize({ hasPageFooter: true, hasPageHeader: true, hasTabSection: true })(DerivationConditionTabSection);
