import ACTION_TYPES from '../constants/workflowList.actionTypes';

const EDIT_WORKFLOW = {
  id: ACTION_TYPES.EDIT_WORKFLOW,
  name: __('Edit'),
};

const ACTIVATE_WORKFLOW = {
  id: ACTION_TYPES.ACTIVE_WORKFLOW,
  name: __('Activate'),
};

const DEACTIVATE_WORKFLOW = {
  id: ACTION_TYPES.INACTIVE_WORKFLOW,
  name: __('Deactivate'),
};

const DEFAULT_ROW_ACTIONS = [EDIT_WORKFLOW];

export { DEACTIVATE_WORKFLOW, ACTIVATE_WORKFLOW };

export default DEFAULT_ROW_ACTIONS;
