import ApiService from '../../../../services/apiService/apiService';

const PATHS = {
  UPDATE_PASSWORD: 'core/public/login/password/set',
};

const updatePassword = (payload) => ApiService.post(PATHS.UPDATE_PASSWORD, payload).then(({ data }) => data);

export default {
  updatePassword,
};
