import _includes from 'lodash/includes';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/workflowBuilderList.actionTypes';
import { TAB_IDS } from '../constants/workflowBuilderList.constants';

const handleInit = ({ setState, getState }) => {
  const { match, history } = getState();
  const tabId = tget(match, 'params.tabId', EMPTY_STRING);

  if (!_includes(TAB_IDS, tabId)) {
    history.replace(`${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${TAB_IDS.WORKFLOWS}`);
  } else {
    setState({ tabId });
  }
};

const handleTabChange = ({ params = EMPTY_OBJECT, getState }) => {
  const { history } = getState();
  const newTabId = tget(params, 'newTabId', EMPTY_STRING);
  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${newTabId}`);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [ACTION_TYPES.ON_TAB_CHANGE]: handleTabChange,
};

export default ACTION_HANDLERS;
