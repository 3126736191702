exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".notificationPanel_msgContainer__jnQCG98z4b{width:16rem;max-height:5.2rem}[dir] .notificationPanel_msgContainer__jnQCG98z4b{padding:.2rem 1.6rem;text-align:center}.notificationPanel_multiLangIcon__paHWmT2Yt9{position:relative}[dir=ltr] .notificationPanel_multiLangIcon__paHWmT2Yt9{padding-right:.6rem !important}[dir=rtl] .notificationPanel_multiLangIcon__paHWmT2Yt9{padding-left:.6rem !important}.notificationPanel_warning__oXH9iRqkNP{position:absolute;top:-0.4rem;color:#f52908;z-index:1}[dir=ltr] .notificationPanel_warning__oXH9iRqkNP{right:-0.1rem}[dir=rtl] .notificationPanel_warning__oXH9iRqkNP{left:-0.1rem}.notificationPanel_errorMessageContainer__5QvrHgSYeV{width:27.5rem;max-height:20rem;overflow:auto}[dir] .notificationPanel_errorMessageContainer__5QvrHgSYeV{padding:.4rem 0}[dir] .notificationPanel_errorMessageContainer__5QvrHgSYeV>div{padding:.2rem 1.6rem;text-align:center}.notificationPanel_errorIcon__kJsHLxqwUE{transition:opacity .1s;color:#ff0027}.notificationPanel_errorIcon__kJsHLxqwUE:hover{color:#ad0039}", ""]);

// Exports
exports.locals = {
	"msgContainer": "notificationPanel_msgContainer__jnQCG98z4b",
	"multiLangIcon": "notificationPanel_multiLangIcon__paHWmT2Yt9",
	"warning": "notificationPanel_warning__oXH9iRqkNP",
	"errorMessageContainer": "notificationPanel_errorMessageContainer__5QvrHgSYeV",
	"errorIcon": "notificationPanel_errorIcon__kJsHLxqwUE"
};