import _get from 'lodash/get';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _last from 'lodash/last';
import _reduce from 'lodash/reduce';
import _includes from 'lodash/includes';
import _head from 'lodash/head';
import _size from 'lodash/size';
import _castArray from 'lodash/castArray';
import _join from 'lodash/join';
import _forEach from 'lodash/forEach';
import _replace from 'lodash/replace';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';

import { searchViewConfigurations } from '../../../../actions/viewBuilderPage.actions';

import { getFieldNamesFromChildren, getFirstFieldName, getUpdatedViewConfigForUpdatedSelectedViewComponent } from '../../helpers/viewBuilder.helper';

import {
  ALL_VIEW_PROPERTY_KEYS,
  CELL_TYPES,
  VIEW_CONFIGURATION_FIELD_IDS,
  VIEW_CONFIGURATION_GENERAL_KEYS,
  VIEW_TYPES,
} from '../../../../constants/viewBuilder.constants';
import { ACTION_TYPES, FIELD_IDS, FORM_VIEW_TYPE_FIELD_IDS, MODAL_TYPE, ROWS } from './compoundConfigModal.constants';

const convertTemplateValueFromEntityFieldToIndex = (template, selectedFields) => {
  const mapOfEntityFieldToItsIndex = _reduce(
    selectedFields,
    (ans, fieldName, index) => {
      _set(ans, [fieldName], index);
      return ans;
    },
    {},
  );

  try {
    const templateRegex = /\${(.*?)}/gm;
    const regexIterator = Array.from(template.matchAll(templateRegex));
    let finalValue = template;

    _forEach(regexIterator, (regexMatch) => {
      const stringMatch = _head(regexMatch);
      const stringValue = _last(regexMatch);
      const indexNumber = `{${_get(mapOfEntityFieldToItsIndex, stringValue, 0) + 1}}`;
      finalValue = _replace(finalValue, stringMatch, indexNumber);
    });

    return finalValue;
  } catch {
    return _reduce(mapOfEntityFieldToItsIndex, (ans, indexValue) => `${ans}, {${indexValue}}`, '');
  }
};

const convertTemplateValueFromIndexToEntityField = (template, selectedFields) => {
  try {
    const templateRegex = /{(.*?)}/gm;
    const regexIterator = Array.from(template.matchAll(templateRegex));
    let finalValue = template;

    _forEach(regexIterator, (regexMatch) => {
      const stringMatch = _head(regexMatch);
      const stringValue = _last(regexMatch);
      const fieldName = `\${${_get(selectedFields, stringValue - 1)}}`;
      finalValue = _replace(finalValue, stringMatch, fieldName);
    });

    return finalValue;
  } catch {
    return _reduce(selectedFields, (ans, field) => `${ans}, \${${field}}`, '');
  }
};

const getUpdatedComponent = (selectedViewComponent, values, viewType, modalType) => {
  const properties = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES);
  let newProperties = {
    ..._omit(properties, ALL_VIEW_PROPERTY_KEYS.CELL_VIEW_NAME),
    cellType: CELL_TYPES.COMPOUND,
    fieldNames: _get(values, FIELD_IDS.ENTITY_FIELDS),
    template: _get(values, FIELD_IDS.TEMPLATE),
    title: _get(values, FIELD_IDS.DISPLAY_NAME),
  };

  if (modalType === MODAL_TYPE.RELATIONSHIP_FIELD_COMPOUND && viewType === VIEW_TYPES.FORM_VIEW) {
    newProperties = {
      ...newProperties,
      lookupFieldNames: _get(values, FIELD_IDS.ENTITY_FIELDS),
      fieldNames: _castArray(getFirstFieldName(getArraySafeValue(_get(values, FIELD_IDS.ENTITY_FIELDS)))),
    };
  }

  const updatedComponentValue = {
    ...selectedViewComponent,
    properties: {
      ...newProperties,
      cellType:
        _size(_get(newProperties, FIELD_IDS.ENTITY_FIELDS)) > 1 || modalType === MODAL_TYPE.NORMAL_FIELD_COMPOUND
          ? CELL_TYPES.COMPOUND
          : CELL_TYPES.SIMPLE,
    },
  };

  return updatedComponentValue;
};

const getUpdatedComponentForFormView = (selectedViewComponent, values) => {
  const newProperties = {
    ..._get(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, {}),
    cellType: CELL_TYPES.SIMPLE,
    [ALL_VIEW_PROPERTY_KEYS.COMPLEX_VIEW_NAME]: getArraySafeValue(_get(values, FORM_VIEW_TYPE_FIELD_IDS.SELECTED_VIEW_NAME)),
    [ALL_VIEW_PROPERTY_KEYS.COMPLEX_VIEW_ENTITY_NAME]: _get(values, ALL_VIEW_PROPERTY_KEYS.COMPLEX_VIEW_ENTITY_NAME),
  };

  return { ...selectedViewComponent, properties: newProperties };
};

const getUpdatedComponentForNormalCompoundFieldWithCellView = (selectedViewComponent, values, fieldNames) => {
  const newProperties = {
    ..._get(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, {}),
    cellType: CELL_TYPES.COMPOUND,
    fieldNames,
    template: '',
    title: _get(values, FIELD_IDS.DISPLAY_NAME),
    [ALL_VIEW_PROPERTY_KEYS.CELL_VIEW_NAME]: _get(values, [FIELD_IDS.CELL_VIEW_NAME]),
  };

  return { ...selectedViewComponent, properties: newProperties };
};

const getUpdatedViewConfiguration = (isCellViewEnabled, viewConfig, selectedViewComponent, values, modalType, selectedCellViewConfiguration) => {
  const viewType = _get(viewConfig, 'viewType');

  let updatedComponent = {};
  if (modalType === MODAL_TYPE.COMPLEX_FIELD_VIEW_SELECT) {
    updatedComponent = getUpdatedComponentForFormView(selectedViewComponent, values);
  } else if (isCellViewEnabled && modalType === MODAL_TYPE.NORMAL_FIELD_COMPOUND) {
    const fieldNames = getFieldNamesFromChildren(_get(selectedCellViewConfiguration, 'section', EMPTY_OBJECT));
    updatedComponent = getUpdatedComponentForNormalCompoundFieldWithCellView(selectedViewComponent, values, fieldNames);
  } else {
    const templateValue = _get(values, FIELD_IDS.TEMPLATE, EMPTY_STRING);
    const selectedFields = _get(values, FIELD_IDS.ENTITY_FIELDS, EMPTY_ARRAY);
    const convertedTemplateValue = convertTemplateValueFromIndexToEntityField(templateValue, selectedFields);
    const updatedValues = { ...values, [FIELD_IDS.TEMPLATE]: convertedTemplateValue };
    updatedComponent = getUpdatedComponent(selectedViewComponent, updatedValues, viewType, modalType);
  }

  const newViewConfig = getUpdatedViewConfigForUpdatedSelectedViewComponent(viewConfig, updatedComponent);

  return newViewConfig;
};

//----------------------------------------------------------------------------------------------------------------------------

const getFieldOptions = (data, selectedFields = EMPTY_ARRAY) =>
  _map(data, (field) => ({
    label: _get(field, 'displayName'),
    value: _get(field, 'name'),
    isDisabled: _includes(selectedFields, _get(field, 'name')),
  }));

const getValueOfComplexOrRelationshipEntityFromComponentNameAndField = (fieldName, field) => _join([fieldName, _get(field, 'name')], '.');

const getOptionsForComplexAndRelationship = (entityDefinition, fieldName, selectedFields) =>
  _map(_get(entityDefinition, 'fieldDefinitions'), (field) => {
    const optionValue = getValueOfComplexOrRelationshipEntityFromComponentNameAndField(fieldName, field);

    return {
      label: _get(field, 'displayName'),
      value: optionValue,
      isDisabled: _includes(selectedFields, optionValue),
    };
  });

const searchFormViewPayload = (entityNames) => ({
  filters: [
    { field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME, values: entityNames, filterType: OPERATORS.IN },
    { field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE, values: [VIEW_TYPES.FORM_VIEW], filterType: OPERATORS.IN },
  ],
});

const modalValueErrorCheck = (isCellViewEnabled, values) => {
  let errors = {};
  let hasErrors = false;

  if (!isCellViewEnabled && _has(values, FIELD_IDS.DISPLAY_NAME) && _isEmpty(values[FIELD_IDS.DISPLAY_NAME])) {
    errors = { ...errors, [FIELD_IDS.DISPLAY_NAME]: __('This field is mandatory') };
    hasErrors = true;
  }

  if (!isCellViewEnabled && _has(values, FIELD_IDS.TEMPLATE) && _isEmpty(values[FIELD_IDS.TEMPLATE])) {
    errors = { ...errors, [FIELD_IDS.TEMPLATE]: __('This field is mandatory') };
    hasErrors = true;
  }

  if (isCellViewEnabled && _isEmpty(values[FIELD_IDS.CELL_VIEW_NAME])) {
    errors = { ...errors, [FIELD_IDS.CELL_VIEW_NAME]: __('This field is mandatory') };
    hasErrors = true;
  }

  return { hasErrors, errors };
};

//---------------------------------------------------------------------------------------------------------------------

const getValueOption = (viewConfig) => ({
  label: tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.DISPLAY_NAME, tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME, EMPTY_STRING)),
  value: tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME),
});

const getOptionsForCellViewName = (data) => _map(data, getValueOption);

const getPayloadForCellViewName =
  (entityName) =>
  ({ nextPageToken, searchText }) => {
    const filters = [
      {
        field: FIELD_IDS.ENTITY_NAME,
        values: _castArray(entityName),
        filterType: OPERATORS.IN,
      },
      {
        field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
        values: [VIEW_TYPES.CELL_VIEW],
        filterType: OPERATORS.IN,
      },
    ];

    if (!_isEmpty(searchText)) {
      filters.push({
        field: VIEW_CONFIGURATION_FIELD_IDS.NAME,
        values: [searchText],
        filterType: OPERATORS.TEXT_STARTS_WITH,
      });
    }

    return {
      rows: ROWS,
      nextPageToken,
      filters,
    };
  };

const handleSearchViewConfigurations = (onAction) => async (payload) => {
  const response = await searchViewConfigurations(payload);
  const value = tget(response, 'hits', EMPTY_ARRAY);
  onAction({ type: ACTION_TYPES.ON_ASYNC_LOADED_OPTIONS, payload: { value } });
  return response;
};

export {
  getUpdatedViewConfiguration,
  getFieldOptions,
  getOptionsForComplexAndRelationship,
  convertTemplateValueFromEntityFieldToIndex,
  searchFormViewPayload,
  modalValueErrorCheck,
  getOptionsForCellViewName,
  getPayloadForCellViewName,
  handleSearchViewConfigurations,
};
