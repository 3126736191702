const ACTION_TYPES = {
  LOAD_CONDITION_FIELDS: 'LOAD_CONDITION_FIELDS',
  ON_CANCEL: 'ON_CANCEL',
  ON_REDIRECT_TO_LOGS: 'ON_REDIRECT_TO_LOGS',
  ON_CANCEL_MODAL_SUBSCRIPTION_FORM: 'ON_CANCEL_MODAL_SUBSCRIPTION_FORM',
  ON_SUBMIT_SUBSCRIPTION_MODAL: 'ON_SUBMIT_SUBSCRIPTION_MODAL',
  ON_REMOVE_SUBSCRIPTION: 'ON_REMOVE_SUBSCRIPTION',
  ON_EDIT_SUBSCRIPTION: 'ON_EDIT_SUBSCRIPTION',
  ON_CLICK_ADD_NEW_SUBSCRIPTION: 'ON_CLICK_ADD_NEW_SUBSCRIPTION',
};

export default ACTION_TYPES;
