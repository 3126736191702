import React from 'react';
import PropTypes from 'prop-types';
import InfoBadge from '@tekion/tekion-components/molecules/infoBadge';
import styles from './infoBadge.module.scss';

const InfoBadgeComp = ({ label, helpText }) => (
  <div className={styles.infoBadge}>
    {label}
    <InfoBadge helpText={helpText} />
  </div>
);

InfoBadgeComp.propTypes = {
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired,
};

export default InfoBadgeComp;
