import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _map from 'lodash/map';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import DropZone from '../dropZone/DropZone';
import DropZoneContainer from '../dropZone/DropZoneContainer';
import FormSectionRowColumn from '../formSectionRowColumn/FormSectionRowColumn';
import ViewComponent from '../viewComponent/ViewComponent';

import { isComponentEditable } from '../../helpers/viewBuilder.helper';

import { COMPONENT_TYPES, MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE } from '../../constants/viewBuilder.constants';
import { VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './formSectionRow.module.scss';

const FormSectionRow = ({ className, rowIndex, componentConfig, ...restProps }) => {
  const sectionId = _get(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
  const columns = _get(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.CHILDREN, EMPTY_ARRAY);

  const entity = _get(restProps, 'entity');

  return (
    <div key={sectionId} className={cx(styles.formSectionRow)}>
      {_map(columns, (column, index) => {
        const isEditEnabled = isComponentEditable(column, entity);

        return (
          <React.Fragment key={tget(column, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID)}>
            <DropZone
              id={sectionId}
              index={index}
              className={styles.dropZoneGutter}
              onHoverClassName={styles.onHoverDropZoneGutter}
              hiddenDropZoneClassName={styles.hiddenDropZone}
              acceptedComponentTypes={MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE[COMPONENT_TYPES.FORM_VIEW_ROW]}
            />
            <ViewComponent index={index} componentConfig={column} isEditEnabled={isEditEnabled}>
              <FormSectionRowColumn componentConfig={column} {...restProps} />
            </ViewComponent>
          </React.Fragment>
        );
      })}

      <DropZoneContainer
        id={sectionId}
        index={columns.length}
        className={styles.dropZoneContainer}
        onHoverClassName={styles.onHoverDropZoneGutter}
        acceptedComponentTypes={MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE[COMPONENT_TYPES.FORM_VIEW_ROW]}
      />
    </div>
  );
};

FormSectionRow.propTypes = {
  className: PropTypes.string,
  rowIndex: PropTypes.number,
  componentConfig: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
};

FormSectionRow.defaultProps = {
  className: '',
  rowIndex: 0,
};

export default React.memo(FormSectionRow);
