const FIELD_IDS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  FIELD_TYPE: 'fieldType',
  DATA_TYPE: 'dataType',
  MANDATORY: 'mandatory',
  OPTIONS: 'options',
  TEXT_MIN_LENGTH: 'textMinLength',
  TEXT_MAX_LENGTH: 'textMaxLength',
  TEXT_REGEX: 'textRegex',
  LIST_INPUT_TABLE: 'listInputTable',
  LIST_ADD_ROW_BUTTON: 'listAddRowButton',
  HELP_TEXT: 'helpText',
  DEPENDENCY_CONFIG: 'dependencyConfigs',
  CONTROLLING_FUNCTION: 'controllingFunction',
  CONTROLLING_FIELD_NAME: 'controllingFieldName',
  CONTROLLING_OPTIONS_CONFIGS: 'controllingOptionsConfigs',
  MULTI_VALUED_ENABLED: 'multiValued',
  LOOKUP_ENTITY: 'entityType',
  LOOKUP_FIELD: 'field',
};

export default FIELD_IDS;
