import _get from 'lodash/get';
import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import _keyBy from 'lodash/keyBy';
import _set from 'lodash/set';
import _findIndex from 'lodash/findIndex';
import _has from 'lodash/has';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../actions/conditionBuilder.actions';
import { fetchReport } from '../../../../../actions/reporting.actions';
import { fetchEntityRecords } from '../../../../../actions/recordManagement.actions';
import { createFiltersObject, getFieldDef } from '../../tableWidget/helpers/tableWidget.helpers';
import ACTION_TYPES from '../constants/chartWidget.actionTypes';
import FIELD_TYPES from '../../../../../constants/fieldDefinition.fieldTypes';
import { STANDARD_ENTITY_NAME } from '../../../../../constants/general.constants';
import { COLUMN_TYPES, RELATIONSHIP_FIELD_COLUMN_DATA } from '../../../constants/reporting.general.constants';
import userReader from '../../../../../readers/loginResponse.reader';
import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';

const resolveData = async (column, rows, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject) => {
  const ids = [];
  let columnData;

  const index = _findIndex(column, (item) => {
    if (_get(item, 'type') === COLUMN_TYPES.DIMENSION) {
      const fieldValue = _get(item, 'reportingDimension.field');
      const fieldDef = getFieldDef(fieldValue, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject);
      if (fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.RELATIONSHIP) {
        const field = fieldDefinitionReader.lookupField(fieldDef) || 'id';
        const entityName = fieldDefinitionReader.lookupFieldEntityType(fieldDef);
        columnData = { [RELATIONSHIP_FIELD_COLUMN_DATA.DISPLAY_FIELD]: fieldDefinitionReader.lookupFieldDisplayField(fieldDef), field, entityName };
        return true;
      }
      return false;
    } else return false;
  });

  if (index !== -1) {
    _forEach(rows, (item) => {
      if (!_isEmpty(_get(item, `data.${index}`))) ids.push(_get(item, `data.${index}`));
    });
  }

  if (_isEmpty(ids)) {
    return rows;
  }

  const payload = {
    filters: [
      {
        field: _get(columnData, RELATIONSHIP_FIELD_COLUMN_DATA.FIELD),
        values: _uniq(ids),
        filterType: OPERATORS.IN,
      },
    ],
  };

  let dataByIds = {};
  const entity = _get(columnData, RELATIONSHIP_FIELD_COLUMN_DATA.ENTITY_NAME);
  if (entity) {
    const response = await fetchEntityRecords(entity, payload);
    const data = tget(response, 'hits', EMPTY_ARRAY);
    dataByIds = _keyBy(data, _get(columnData, RELATIONSHIP_FIELD_COLUMN_DATA.FIELD));

    const updatedData = _map(rows, (item) => {
      const record = _get(dataByIds, _get(item, `data.${index}`));
      const newData = { ...item };
      if (entity === STANDARD_ENTITY_NAME.USER) {
        _set(newData, `data.${index}`, record ? userReader.name(record) : __('User deleted'));
      } else if (_has(record, _get(columnData, RELATIONSHIP_FIELD_COLUMN_DATA.DISPLAY_FIELD))) {
        _set(newData, `data.${index}`, _get(record, _get(columnData, RELATIONSHIP_FIELD_COLUMN_DATA.DISPLAY_FIELD), null));
      } else {
        _set(newData, `data.${index}`, _get(record, `entity.${_get(columnData, RELATIONSHIP_FIELD_COLUMN_DATA.DISPLAY_FIELD)}`, null));
      }

      return newData;
    });

    return updatedData;
  }

  return rows;
};

const fetchChartData = async ({ getState, setState }) => {
  const { requestPayload, widget, selectedFilters = [], timeRange = EMPTY_OBJECT, entityName } = getState();
  setState({ chartLoading: true });
  const filters = createFiltersObject(selectedFilters);
  if (!_isEmpty(timeRange)) {
    filters.push({ field: 'createdTime', operator: OPERATORS.BTW, values: [_get(timeRange, 'startTime'), _get(timeRange, 'endTime')] });
  }
  const mapOfVariableToEntityName = { $record: entityName };
  let conditionBuilderFieldDefinitionObject = _get(widget, 'conditionBuilderFieldDefinitionObject', EMPTY_OBJECT);
  if (_isEmpty(conditionBuilderFieldDefinitionObject)) {
    conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityName, {}, false, false);
  }

  const payload = {
    columns: requestPayload,
    entityName,
    filters,
  };

  const { columns, rows } = await fetchReport(payload);
  const newRows = await resolveData(columns, rows, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject);

  setState({
    axisData: columns,
    graphData: newRows,
    chartLoading: false,
  });
};

const handleOnChange = ({ params = EMPTY_OBJECT, setState }) => {
  const { value } = params;
  setState({ chartType: getArraySafeValue(value) });
};

const handleApplyFilters = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { filters } = params;
  setState({ selectedFilters: filters }, () => fetchChartData({ getState, setState }));
};

const handleSelectChartArea = ({ params = EMPTY_OBJECT, getState }) => {
  const { event } = params;
  const { triggerRangeChange = _noop } = getState();
  const values = [_get(event, 'xAxis.0.min'), _get(event, 'xAxis.0.max')];
  triggerRangeChange(values);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: fetchChartData,
  [ACTION_TYPES.APPLY_FILTERS]: handleApplyFilters,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnChange,
  [ACTION_TYPES.ON_SELECT_CHART_AREA]: handleSelectChartArea,
};
export default ACTION_HANDLERS;
