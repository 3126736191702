import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import Button from '@tekion/tekion-components/atoms/Button';

import ACTION_TYPES from './globalSelectList.actionTypes';

const COLUMN_IDS = {
  DISPLAY_NAME: 'displayName',
  NAME: 'name',
  DESCRIPTION: 'description',
};

const EXPORT_LIST_COLUMNS = [
  {
    id: COLUMN_IDS.NAME,
    Header: __('Name'),
    accessor: COLUMN_IDS.NAME,
    searchable: true,
    Cell: TextRenderer,
  },
  {
    id: COLUMN_IDS.DISPLAY_NAME,
    Header: __('Display name'),
    accessor: COLUMN_IDS.DISPLAY_NAME,
    searchable: true,
    Cell: TextRenderer,
  },

  {
    id: COLUMN_IDS.DESCRIPTION,
    Header: __('Description'),
    accessor: COLUMN_IDS.DESCRIPTION,
    Cell: TextRenderer,
  },
];

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_GLOBAL_SELECT,
    },
  ],
};

export { COLUMN_IDS, EXPORT_LIST_COLUMNS, SUB_HEADER_PROPS };
