import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _get from 'lodash/get';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import Button from '@tekion/tekion-components/atoms/Button';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import TemplateRenderer from './templateRenderer';
import { TEMPLATE_RENDERER_FIELD_IDS } from './templateRenderer/templateRenderer.constant';

import styles from './templateSection.module.scss';

const TemplateSection = ({
  isMultipleTemplateSelectAllowed,
  id,
  value,
  templateDefinitions,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  onAction,
  ...restProps
}) => {
  const handleAdd = useCallback(() => {
    onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id, value: [...value, {}] } });
  }, [id, value, onAction]);

  const handleAction = useCallback(
    (action) => {
      const { type, payload } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { index: indexNumber, value: fieldValue } = payload;
          const updatedValue = [...value];
          updatedValue[indexNumber] = fieldValue;
          onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id, value: updatedValue } });
          break;
        }

        default: {
          onAction(action);
          break;
        }
      }
    },
    [id, value, onAction],
  );

  return (
    <div>
      {_map(value, (data, index) => {
        const selectedTemplate = _get(data, TEMPLATE_RENDERER_FIELD_IDS.SELECTED_TEMPLATE, EMPTY_STRING);

        const selectedTemplateDefinition = _get(templateDefinitions, selectedTemplate, EMPTY_OBJECT);

        return (
          <TemplateRenderer
            {...restProps}
            key={index}
            index={index}
            value={data}
            templateDefinition={selectedTemplateDefinition}
            conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
            mapOfVariableToEntityName={mapOfVariableToEntityName}
            onAction={handleAction}
          />
        );
      })}
      {isMultipleTemplateSelectAllowed && (
        <div className={styles.addTemplateButton}>
          <Button view={Button.VIEW.TERTIARY} onClick={handleAdd}>
            {__('+Add Another Template')}
          </Button>
        </div>
      )}
    </div>
  );
};

TemplateSection.propTypes = {
  isMultipleTemplateSelectAllowed: PropTypes.bool,
  id: PropTypes.string.isRequired,
  sendEmailsType: PropTypes.string,
  templateDefinitions: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  value: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

TemplateSection.defaultProps = {
  isMultipleTemplateSelectAllowed: true,
  sendEmailsType: EMPTY_STRING,
  templateDefinitions: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  value: [{}],
};

export default TemplateSection;
