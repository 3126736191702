import _property from 'lodash/property';

// TODO: Make Dev-platform's pages use this reader
const eventConfiguratorReader = {
  title: _property('title'),
  codePanel: _property('codePanel'),
  eventHandlers: _property('eventHandlers'),
  eventType: _property('eventType'),
  properties: _property('properties'),
  subEventType: _property('subEventType'),
  script: _property('script'),
  metadata: _property('metadata'),
  type: _property('type'),
  actionToExecute: _property('actionToExecute'),
  pageIdToNavigate: _property('pageIdToNavigate'),
  actionIdToExecute: _property('actionIdToExecute'),
  navigateToPrevPage: _property('navigateToPrevPage'),
  generatorType: _property('generatorType'),
  viewType: _property('viewType'),
  widgetType: _property('widgetType'),
  jsonScript: _property('jsonScript'),
  editorId: _property('editorId'),
};

export default eventConfiguratorReader;
