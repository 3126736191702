const CALLOUT_FIELD_IDS = {
  ACTION_TYPE: 'actionType',
  SERVER_CREDENTIAL_NAME: 'serverCredentialName',
  HTTP_METHOD: 'httpMethod',
  PATH: 'path',
  HEADERS: 'headers',
  REQUEST_BODY: 'requestBody',
  REQUEST_PARAMS: 'requestParams',
  CONNECT_TIME_OUT: 'connectTimeout',
  READ_TIME_OUT: 'readTimeout',
  FIELD_MAPPING_ID: 'fieldMappingId',
  VARIABLE_TYPE: 'variableType',
  ASSIGNED_VARIABLE: 'assignedVariable',
  ENTITY_NAME: 'entityName',
  RESPONSE_EXPECTED: 'responseExpected',
  CALLOUT_ACTION_NAME: 'name',
};

export default CALLOUT_FIELD_IDS;
