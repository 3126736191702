import React, { useCallback } from 'react';

// components
import TableControl from '@tekion/tekion-components/molecules/richTextEditor/styleControls/TableControl';

// constants
import { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

// helpers
import insertTable from '../../../helpers/table/richTextEditor.insertTable';

// hooks
import useEditor from '../../../hooks/useEditor';

// styles
import styles from './tableToolbarButton.module.scss';

const TABLE_CONTROL = { icon: 'icon-table2', iconSize: SIZES.MD_S };

function TableToolbarButton() {
  const editor = useEditor();
  const handleInsertTable = useCallback(
    ({ size }) => {
      insertTable(editor, size);
    },
    [editor],
  );
  return (
    <TableControl onToggle={handleInsertTable} control={TABLE_CONTROL} styleMenuClassName={styles.tablePopover} btnClassName={styles.tableBtn} />
  );
}

export default React.memo(TableToolbarButton);
