import React, { useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import withActions from '@tekion/tekion-components/connectors/withActions';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';
import { DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import withSize from '../../../../../connectors/withSize';

import { COLUMN_CONFIG } from './helpers/templateBuilderList.columns';
import ACTION_HANDLERS from './helpers/templateBuilderList.actionHandlers';
import { createTableProps } from './helpers/templateBuilderList.helpers';

import { TABLE_MANAGER_PROPS, HEADER_PROPS, DELETE_TEMPLATE_CONTENT, ACTION_TYPES } from './constants/templateBuilderList.contants';
import { TABLE_CONSTANTS } from '../../../../../constants/general.constants';

const TemplateBuilderList = ({
  loading,
  isConfirmationModalVisible,
  isTemplateDeleting,
  contentHeight,
  confirmationModalTitle,
  templates,
  searchText,
  pageSize,
  currentPage,
  nextPageToken,
  totalNumberOfEntries,
  onAction,
}) => {
  const tableProps = useMemo(
    () => createTableProps(onAction, loading, currentPage, pageSize, totalNumberOfEntries),
    [onAction, loading, currentPage, pageSize, totalNumberOfEntries],
  );

  const handleConfirmationCancelClick = () => {
    onAction({ type: ACTION_TYPES.DELETE_TEMPLATE_CONFIRMATION_CANCEL_CLICK });
  };

  const handleConfirmationDoneClick = () => {
    onAction({ type: ACTION_TYPES.DELETE_TEMPLATE_CONFIRMATION_DONE_CLICK });
  };

  return (
    <Page style={{ height: contentHeight }}>
      <TableManager
        headerProps={HEADER_PROPS}
        tableProps={tableProps}
        tableManagerProps={TABLE_MANAGER_PROPS}
        data={templates}
        columns={COLUMN_CONFIG}
        pageSize={pageSize}
        currentPage={currentPage}
        searchText={searchText}
        nextPageToken={nextPageToken}
        tokenPagination
        onAction={onAction}
      />
      <ConfirmationDialog
        isVisible={isConfirmationModalVisible}
        loading={isTemplateDeleting}
        title={confirmationModalTitle}
        content={DELETE_TEMPLATE_CONTENT}
        onCancel={handleConfirmationCancelClick}
        onSubmit={handleConfirmationDoneClick}
      />
    </Page>
  );
};

TemplateBuilderList.propTypes = {
  loading: PropTypes.bool,
  isConfirmationModalVisible: PropTypes.bool,
  isTemplateDeleting: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  confirmationModalTitle: PropTypes.string,
  templates: PropTypes.array,
  searchText: PropTypes.string,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  nextPageToken: PropTypes.string,
  totalNumberOfEntries: PropTypes.number,
  onAction: PropTypes.func,
};

TemplateBuilderList.defaultProps = {
  loading: false,
  isConfirmationModalVisible: false,
  isTemplateDeleting: false,
  confirmationModalTitle: EMPTY_STRING,
  templates: EMPTY_ARRAY,
  searchText: EMPTY_STRING,
  pageSize: DEFAULT_PAGE_INFO.rows,
  currentPage: DEFAULT_PAGE_INFO.start,
  nextPageToken: EMPTY_STRING,
  totalNumberOfEntries: TABLE_CONSTANTS.TOTAL_NUMBER_OF_ENTRIES,
  onAction: _noop,
};

export default compose(withSize({ hasPageHeader: 0, hasPageFooter: 0 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(TemplateBuilderList);
