import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _snakeCase from 'lodash/snakeCase';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Modal from '@tekion/tekion-components/molecules/Modal';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { TOASTER_TYPE, toaster } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { MODAL_INPUT_FIELD, OPTION_MODAL_ACTION_TYPES, ROW_OPERATION } from '../constants/listInputTable.constants';

import styles from './optionModal.module.scss';

const OptionModal = ({ visible, modalData, onAction }) => {
  const oldLabel = _get(modalData, MODAL_INPUT_FIELD.LABEL, EMPTY_STRING);
  const oldValue = _get(modalData, MODAL_INPUT_FIELD.VALUE, EMPTY_STRING);
  const operation = _get(modalData, 'operation', ROW_OPERATION.ADD);

  const [modalState, setModalState] = useState({
    [MODAL_INPUT_FIELD.LABEL]: '',
    [MODAL_INPUT_FIELD.VALUE]: '',
    isDisabledAutoFillForValue: operation === ROW_OPERATION.EDIT,
  });

  const handleChange = useCallback(
    (action) => {
      if (_get(action, 'type') === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
        const id = _get(action, 'payload.id', '');
        const newValue = _get(action, 'payload.value', '');
        const { isDisabledAutoFillForValue } = modalState;

        if (id === MODAL_INPUT_FIELD.LABEL && operation === ROW_OPERATION.ADD && !isDisabledAutoFillForValue) {
          setModalState({ ...modalState, [id]: newValue, [MODAL_INPUT_FIELD.VALUE]: _snakeCase(newValue) });
        } else if (id === MODAL_INPUT_FIELD.VALUE) {
          setModalState({ ...modalState, [id]: newValue, isDisabledAutoFillForValue: true });
        } else {
          setModalState({ ...modalState, [id]: newValue });
        }
      }
    },
    [modalState, operation],
  );

  const handleModalSubmit = useCallback(() => {
    const hasError = _isEmpty(_get(modalState, MODAL_INPUT_FIELD.LABEL)) || _isEmpty(_get(modalState, MODAL_INPUT_FIELD.VALUE));
    if (hasError) {
      toaster(TOASTER_TYPE.ERROR, __('Please fill all the mandatory fields'));
      return;
    }
    const payload = {
      rowData: {
        ...modalState,
        operation,
        nestingPath: _get(modalData, 'nestingPath', EMPTY_ARRAY),
      },
    };

    onAction({
      type: OPTION_MODAL_ACTION_TYPES.ON_SUBMIT,
      payload,
    });
  }, [modalData, modalState, operation, onAction]);

  const handleClose = useCallback(() => {
    onAction({
      type: OPTION_MODAL_ACTION_TYPES.ON_CLOSE,
    });
  }, [onAction]);

  useEffect(() => {
    setModalState({
      [MODAL_INPUT_FIELD.LABEL]: oldLabel,
      [MODAL_INPUT_FIELD.VALUE]: oldValue,
    });
  }, [oldLabel, oldValue]);
  return (
    <Modal visible={visible} destroyOnClose width={Modal.SIZES.MD} title={__('Option update')} onCancel={handleClose} onSubmit={handleModalSubmit}>
      <div className={styles.modalBody}>
        <TextInput
          id={MODAL_INPUT_FIELD.LABEL}
          required
          label={__('Label')}
          fieldClassName={styles.input}
          value={_get(modalState, MODAL_INPUT_FIELD.LABEL, '')}
          onAction={handleChange}
        />
        <TextInput
          id={MODAL_INPUT_FIELD.VALUE}
          required
          disabled={operation === ROW_OPERATION.EDIT}
          fieldClassName={styles.input}
          label={__('Value')}
          value={_get(modalState, MODAL_INPUT_FIELD.VALUE, '')}
          onAction={handleChange}
        />
      </div>
    </Modal>
  );
};

OptionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  modalData: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

OptionModal.defaultProps = {
  modalData: EMPTY_OBJECT,
};

export default React.memo(OptionModal);
