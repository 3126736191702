import { FIELD_IDS as EVENT_HANDLER_FIELD_IDS } from '../../../../../molecules/eventHandler/eventHandler.constants';
import { EVENT_NAMES } from '../../../../../constants/eventActions.constants';
import { FIELD_IDS as CUSTOM_ROW_ACTION_FIELD_IDS } from '../components/customRowActionsForm/customRowActionsForm.constants';

const FIELD_IDS = {
  SHOW_ACTIONS: 'showActions',
  ACTIONS_TO_SHOW: 'actionsToShow',
  CUSTOM_ACTIONS_INFO: 'customActionsInfo',
};

const ROWS = 5000;

const ACTIONS_CONFIGURATOR_FORM_CONTEXT_ID = 'ACTIONS_CONFIGURATOR_FORM';

const CUSTOM_ROW_ACTION_CONFIGURATOR_FORM_CONTEXT_ID = 'CUSTOM_ROW_ACTION_CONFIGURATOR_FORM_CONTEXT_ID';

const CUSTOM_ROW_ACTION_INITIAL_EVENT_DATA = {
  [EVENT_HANDLER_FIELD_IDS.EVENT_TYPE]: EVENT_NAMES.CLICK,
};

const CUSTOM_ROW_ACTION_ENABLE_SCRIPT_INITIAL_VALUE = 'return true;';

const CUSTOM_ROW_ACTION_INITIAL_DATA = {
  [CUSTOM_ROW_ACTION_FIELD_IDS.EVENT_HANDLERS]: CUSTOM_ROW_ACTION_INITIAL_EVENT_DATA,
  [CUSTOM_ROW_ACTION_FIELD_IDS.RENDERER_PROPS]: [
    {
      type: CUSTOM_ROW_ACTION_FIELD_IDS.ENABLE_ACTION_SCRIPT,
      value: CUSTOM_ROW_ACTION_ENABLE_SCRIPT_INITIAL_VALUE,
    },
  ],
};

export {
  FIELD_IDS,
  ROWS,
  ACTIONS_CONFIGURATOR_FORM_CONTEXT_ID,
  CUSTOM_ROW_ACTION_CONFIGURATOR_FORM_CONTEXT_ID,
  CUSTOM_ROW_ACTION_INITIAL_EVENT_DATA,
  CUSTOM_ROW_ACTION_ENABLE_SCRIPT_INITIAL_VALUE,
  CUSTOM_ROW_ACTION_INITIAL_DATA,
};
