import _includes from 'lodash/includes';
import _isNil from 'lodash/isNil';
import _mapValues from 'lodash/mapValues';
import _join from 'lodash/join';
import _keys from 'lodash/keys';
import _values from 'lodash/values';
import _pick from 'lodash/pick';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { DATA_TYPES_WITHOUT_LOOKUP_ENABLED, DISABLED_FIELD_PROPERTIES } from '../constants/fieldsForm.constants';
import FIELDS_FORM_FIELD_IDS from '../constants/fieldsForm.fieldIds';
import VIEW_DETAILS_FORM_FIELD_IDS from '../components/viewDetailsForm/constants/viewDetailsForm.fieldIds';
import TEXT_PROPERTIES_FORM_FIELD_IDS from '../components/textPropertiesForm/constants/textPropertiesForm.fieldIds';
import LOOKUP_DETAILS_FORM_FIELD_IDS from '../components/lookupDetailsForm/constants/lookupDetailsForm.fieldIds';
import PROPERTIES_FORM_FIELD_IDS from '../components/propertiesForm/constants/propertiesForm.fieldIds';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import GENERAL_DETAILS_FORM_FIELD_IDS from '../components/generalDetailsForm/constants/generalDetailsForm.fieldIds';
import SELECT_OPTIONS_FORM_FIELD_IDS from '../components/selectOptionsForm/constants/selectOptionsForm.fieldsIds';
import COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS from '../components/complexFieldDetailsForm/constants/complexFieldDetailsForm.fieldIds';
import RELATIONSHIP_DETAILS_FORM_FIELD_IDS from '../components/relationshipDetailsForm/constants/relationshipDetailsForm.fieldIds';
import FIELD_TYPES from '../../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../../constants/fieldDefinition.dataTypes';

const disableCheck = {
  [GENERAL_DETAILS_FORM_FIELD_IDS.NAME]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
  },
  [GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
    [FIELD_TYPES.RELATIONSHIP]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.RELATIONSHIP,
  },
  [GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
  },
  [GENERAL_DETAILS_FORM_FIELD_IDS.MULTI_VALUED]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
    [FIELD_TYPES.RICH_TEXT_EDITOR]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.RICH_TEXT_EDITOR,
    [DATA_TYPES.BOOLEAN]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]) === DATA_TYPES.BOOLEAN,
    [FIELD_TYPES.RANGE]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.RANGE,
    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) ===
      FIELD_TYPES.CHILD_AGGREGATE_SUMMARY,
    [LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]: ({ formValues }) =>
      !!_get(formValues, [FIELDS_FORM_FIELD_IDS.LOOKUP_DETAILS_FORM, LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]) &&
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.TEXT &&
      _includes(
        DATA_TYPES_WITHOUT_LOOKUP_ENABLED,
        _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]),
      ),
    [PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT]: ({ formValues }) =>
      !!_get(formValues, [FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM, PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT]),
  },
  [LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
    [GENERAL_DETAILS_FORM_FIELD_IDS.MULTI_VALUED]: ({ formValues }) =>
      !!_get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.MULTI_VALUED]),
    [DATA_TYPES.BOOLEAN]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]) === DATA_TYPES.BOOLEAN,
    [DATA_TYPES.COMPLEX]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]) === DATA_TYPES.COMPLEX,
    [DATA_TYPES.DATE]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]) === DATA_TYPES.DATE,
    [DATA_TYPES.DATE_TIME]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]) === DATA_TYPES.DATE_TIME,
    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) ===
      FIELD_TYPES.CHILD_AGGREGATE_SUMMARY,
    [FIELD_TYPES.MEDIA]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.MEDIA,
    [FIELD_TYPES.RANGE]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.RANGE,
    [FIELD_TYPES.RICH_TEXT_EDITOR]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.RICH_TEXT_EDITOR,
    [FIELD_TYPES.RELATIONSHIP]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.RELATIONSHIP,

    [FIELD_TYPES.SELECT]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.SELECT,
    [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM]: ({ formValues }) =>
      Math.max(
        tget(formValues, [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM, TEXT_PROPERTIES_FORM_FIELD_IDS.MIN_TEXT_LENGTH], 0),
        tget(formValues, [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM, TEXT_PROPERTIES_FORM_FIELD_IDS.MAX_TEXT_LENGTH], 0),
      ) > 256,
  },
  [SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
  },
  [COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS.ENTITY_NAME]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
  },
  [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
  },
  [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
  },
  [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.GROUPS_ALLOWED]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
  },
  [VIEW_DETAILS_FORM_FIELD_IDS.LIST_VIEW_ENABLED]: {
    [FORM_MODES.EDIT]: ({ formMode, initialState }) =>
      formMode === FORM_MODES.EDIT && !!_get(initialState, [FIELDS_FORM_FIELD_IDS.VIEW_DETAILS_FORM, VIEW_DETAILS_FORM_FIELD_IDS.LIST_VIEW_ENABLED]),
  },
  [VIEW_DETAILS_FORM_FIELD_IDS.DETAILED_VIEW_ENABLED]: {
    [FORM_MODES.EDIT]: ({ formMode, initialState }) =>
      formMode === FORM_MODES.EDIT &&
      !!_get(initialState, [FIELDS_FORM_FIELD_IDS.VIEW_DETAILS_FORM, VIEW_DETAILS_FORM_FIELD_IDS.DETAILED_VIEW_ENABLED]),
  },
  [TEXT_PROPERTIES_FORM_FIELD_IDS.MIN_TEXT_LENGTH]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
  },
  [TEXT_PROPERTIES_FORM_FIELD_IDS.MAX_TEXT_LENGTH]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
  },
  [TEXT_PROPERTIES_FORM_FIELD_IDS.PATTERN]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
  },
  [PROPERTIES_FORM_FIELD_IDS.EDITABLE]: {
    [FORM_MODES.EDIT]: ({ formMode, initialState }) =>
      formMode === FORM_MODES.EDIT && !!_get(initialState, [FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM, PROPERTIES_FORM_FIELD_IDS.EDITABLE]),

    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) ===
      FIELD_TYPES.CHILD_AGGREGATE_SUMMARY,
  },
  [PROPERTIES_FORM_FIELD_IDS.IMPORTABLE]: {
    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) ===
      FIELD_TYPES.CHILD_AGGREGATE_SUMMARY,
  },
  [PROPERTIES_FORM_FIELD_IDS.SORTABLE]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
    [FIELD_TYPES.MEDIA]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.MEDIA,
    [FIELD_TYPES.RICH_TEXT_EDITOR]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.RICH_TEXT_EDITOR,
    [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM]: ({ formValues }) =>
      Math.max(
        tget(formValues, [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM, TEXT_PROPERTIES_FORM_FIELD_IDS.MIN_TEXT_LENGTH], 0),
        tget(formValues, [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM, TEXT_PROPERTIES_FORM_FIELD_IDS.MAX_TEXT_LENGTH], 0),
      ) > 256,
  },
  [PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
  },
  [PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
    // && !!_get(formValues, [FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM, PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT]),
    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) ===
      FIELD_TYPES.CHILD_AGGREGATE_SUMMARY,
    [FIELD_TYPES.SELECT]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.SELECT,
    [FIELD_TYPES.RANGE]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.RANGE,
    [FIELD_TYPES.RICH_TEXT_EDITOR]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.RICH_TEXT_EDITOR,
    [FIELD_TYPES.MEDIA]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.MEDIA,
    [GENERAL_DETAILS_FORM_FIELD_IDS.MULTI_VALUED]: ({ formValues }) =>
      !!_get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.MULTI_VALUED]),
    [LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]: ({ formValues }) =>
      !!_get(formValues, [FIELDS_FORM_FIELD_IDS.LOOKUP_DETAILS_FORM, LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]) &&
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.TEXT &&
      _includes(
        DATA_TYPES_WITHOUT_LOOKUP_ENABLED,
        _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]),
      ),
  },
  [PROPERTIES_FORM_FIELD_IDS.MANDATORY]: {
    [PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT]: ({ formValues }) =>
      !!_get(formValues, [FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM, PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT]),
    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) ===
      FIELD_TYPES.CHILD_AGGREGATE_SUMMARY,
  },
  [PROPERTIES_FORM_FIELD_IDS.EXPORTABLE]: {
    [LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]: ({ formValues }) =>
      !!_get(formValues, [FIELDS_FORM_FIELD_IDS.LOOKUP_DETAILS_FORM, LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]) &&
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.TEXT &&
      _includes(
        DATA_TYPES_WITHOUT_LOOKUP_ENABLED,
        _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]),
      ),
  },
  [PROPERTIES_FORM_FIELD_IDS.FILTERABLE]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
    [LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]: ({ formValues }) =>
      !!_get(formValues, [FIELDS_FORM_FIELD_IDS.LOOKUP_DETAILS_FORM, LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]) &&
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.TEXT &&
      _includes(
        DATA_TYPES_WITHOUT_LOOKUP_ENABLED,
        _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]),
      ),
    [FIELD_TYPES.MEDIA]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.MEDIA,
    [FIELD_TYPES.RICH_TEXT_EDITOR]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.RICH_TEXT_EDITOR,
    [PROPERTIES_FORM_FIELD_IDS.SORTABLE]: ({ formValues }) =>
      !!_get(formValues, [FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM, PROPERTIES_FORM_FIELD_IDS.SORTABLE]),
    [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM]: ({ formValues }) =>
      Math.max(
        tget(formValues, [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM, TEXT_PROPERTIES_FORM_FIELD_IDS.MIN_TEXT_LENGTH], 0),
        tget(formValues, [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM, TEXT_PROPERTIES_FORM_FIELD_IDS.MAX_TEXT_LENGTH], 0),
      ) > 256,
  },
  [PROPERTIES_FORM_FIELD_IDS.CREATABLE]: {
    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) ===
      FIELD_TYPES.CHILD_AGGREGATE_SUMMARY,
    [PROPERTIES_FORM_FIELD_IDS.EDITABLE]: ({ formValues }) =>
      !!_get(formValues, [FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM, PROPERTIES_FORM_FIELD_IDS.EDITABLE]),
  },
  [PROPERTIES_FORM_FIELD_IDS.SEARCHABLE]: {
    [FORM_MODES.EDIT]: ({ formMode }) => formMode === FORM_MODES.EDIT,
    [DATA_TYPES.NUMBER]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]) === DATA_TYPES.NUMBER,
    [FIELD_TYPES.RANGE]: ({ formValues }) =>
      _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]) === FIELD_TYPES.RANGE,
  },
};

const getSingleValuedRenderedOptions = (renderOptionsPathsForCurrentField, fieldId) => {
  const filteredRenderOptionsPathsForCurrentField = _pick(_get(DISABLED_FIELD_PROPERTIES, fieldId), renderOptionsPathsForCurrentField);
  const singleValuedRenderOptionsForCurrentField = _reduce(
    _values(filteredRenderOptionsPathsForCurrentField),
    (acc, { isDisabled, disableKey = 'disabled', helpText, value }) => ({
      helpText: helpText && acc.helpText ? _join([helpText, acc.helpText], '. ') : helpText || (acc.helpText ? acc.helpText : undefined),
      value: !_isNil(value) ? value : acc.value,
      disableKey: disableKey || (acc.disableKey ?? ''),
      isDisabled: isDisabled ?? true,
    }),
    {},
  );

  return singleValuedRenderOptionsForCurrentField;
};

const getAllRenderOptionsPathsForField = (formMode, formValues, initialState) => (renderOptions, fieldId) => {
  const currentFieldDisableCheck = tget(disableCheck, fieldId, EMPTY_OBJECT);
  const allRenderOptionsPathsForCurrentField = filterRows(
    _keys(currentFieldDisableCheck),
    { formMode, formValues, initialState },
    currentFieldDisableCheck,
  );

  return {
    ...renderOptions,
    [fieldId]: allRenderOptionsPathsForCurrentField,
  };
};

const getRenderOptions = (formMode, formValues, initialState, fieldIdsList) => {
  const renderOptionsPathsForAllFields = _reduce(fieldIdsList, getAllRenderOptionsPathsForField(formMode, formValues, initialState), {});
  const renderOptionsForAllFields = _mapValues(renderOptionsPathsForAllFields, getSingleValuedRenderedOptions);

  return _reduce(
    fieldIdsList,
    (renderOptions, fieldId) => {
      const { isDisabled = true, disableKey, helpText, value } = renderOptionsForAllFields[fieldId];

      if (disableKey) {
        return {
          ...renderOptions,
          [fieldId]: {
            [disableKey]: isDisabled,
            helpText,
            value,
          },
        };
      }

      return renderOptions;
    },
    {},
  );
};

export { getRenderOptions };
