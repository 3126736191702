import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _find from 'lodash/find';
import _head from 'lodash/head';
import _map from 'lodash/map';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import FIELD_TYPES from '../../../../../../../constants/fieldDefinition.fieldTypes';
import FIELD_IDS from '../constants/approvalSettingFieldForm.fieldIds';
import { COLUMN_IDS, MAX_LENGTH } from '../components/ListInputTable/constants/listInputTable.constants';
import { COLUMNS, ERROR_IDS, ERROR_MESSAGES } from '../constants/approvalSettingFieldForm.constants';

import fieldDefinitionReader from '../../../../../../../readers/fieldDefinition.reader';

const validateInputTable = (values) => {
  let isEmptyValue = false;
  let isValidLength = false;
  _forEach(values, (val) => {
    const label = _get(val, COLUMN_IDS.LABEL, '');
    const value = _get(val, COLUMN_IDS.VALUE, '');
    isEmptyValue = _isEmpty(label) || _isEmpty(value);
    isValidLength = _size(value) <= MAX_LENGTH || _size(value) <= MAX_LENGTH;
  });
  const valid = !isEmptyValue && isValidLength;
  let error = '';
  if (isEmptyValue) error = ERROR_MESSAGES[ERROR_IDS.INPUT_TABLE_EMPTY_VALUE];
  if (!isValidLength) error = ERROR_MESSAGES[ERROR_IDS.INPUT_TABLE_STRING_LENGTH];
  return { valid, error };
};

const validateConfigTable = (values) => {
  let isEmptyValue = false;
  _forEach(values, (val) => {
    const dependencyType = _head(_get(val, COLUMNS.DEPENDENCY_TYPE, ['']));
    const fieldName = _head(_get(val, COLUMNS.FIELD_NAME, ['']));
    isEmptyValue = _isEmpty(fieldName) || _isEmpty(dependencyType);
  });
  const valid = !isEmptyValue;
  let error = '';
  if (isEmptyValue) error = ERROR_MESSAGES[ERROR_IDS.DEPENDENCY_TABLE_EMPTY_VALUE];

  return { valid, error };
};

const validateControllingOption = (values) => {
  let isEmptyValue = false;
  _forEach(values, (val) => {
    const controllingField = _head(_get(val, COLUMNS.OPTION, ['']));
    const controllingOptions = _head(_get(val, COLUMNS.VALUES, ['']));
    isEmptyValue = _isEmpty(controllingField) || _isEmpty(controllingOptions);
  });
  const valid = !isEmptyValue;
  let error = '';
  if (isEmptyValue) error = ERROR_MESSAGES[ERROR_IDS.CONTROLLING_OPTIONS_TABLE_EMPTY_VALUE];

  return { valid, error };
};

const getControllingFieldOptions = (entity, formValues) => {
  let fieldDefinitions = _get(entity, 'fieldDefinitions', EMPTY_ARRAY);
  fieldDefinitions = _filter(fieldDefinitions, (item) => _get(item, 'fieldType') === FIELD_TYPES.SELECT);
  fieldDefinitions = _filter(fieldDefinitions, (item) => getArraySafeValue(_get(formValues, FIELD_IDS.NAME, EMPTY_STRING)) !== _get(item, 'name'));

  return _map(fieldDefinitions, (item) => ({
    label: __(_get(item, 'displayName')),
    value: _get(item, 'name'),
  }));
};

const getValuesOptions = (entity, fieldName) => {
  const fieldDef = _find(_get(entity, 'fieldDefinitions', EMPTY_ARRAY), (field) => {
    const name = fieldDefinitionReader.name(field);
    return name === fieldName;
  });

  const valueOptions = _get(fieldDef, 'optionConfig.options', EMPTY_ARRAY);

  return _map(valueOptions, (item) => ({
    label: _get(item, 'displayName'),
    value: _get(item, 'value'),
  }));
};

export { validateInputTable, getControllingFieldOptions, getValuesOptions, validateConfigTable, validateControllingOption };
