const ROW_ACTION_PROPS = {
  visibleActionCount: 1,
};

const TABLE_ACTIONS = {
  REMOVE_ROW: 'REMOVE_ROW',
};

const REMOVE_ACTION = {
  id: TABLE_ACTIONS.REMOVE_ROW,
  name: __('Delete Row'),
  icon: 'icon-trash',
};

const EMPTY_ROW = { label: '', value: '' };

const COLUMN_IDS = {
  LABEL: 'label',
  VALUE: 'value',
};

const MAX_LENGTH = 256;

export { ROW_ACTION_PROPS, REMOVE_ACTION, TABLE_ACTIONS, EMPTY_ROW, COLUMN_IDS, MAX_LENGTH };
