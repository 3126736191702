import _join from 'lodash/join';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';

// Services
import processBuilderServices from '../services/processBuilder.services';

const fetchProcesses = async () => {
  try {
    const response = await processBuilderServices.fetchProcesses();
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch processes, please try again later!')));
    return EMPTY_ARRAY;
  }
};

const createProcess = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await processBuilderServices.createProcess(payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Process created successfully!'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create the process, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateProcess = async (workflowId, payload = EMPTY_OBJECT) => {
  try {
    const response = await processBuilderServices.updateProcess(workflowId, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Workflow updated successfully!'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update the process, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getProcessById = async (workflowId) => {
  try {
    const response = await processBuilderServices.getProcessById(workflowId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get the process, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchProcessTaskDefinitions = async (eventTypes) => {
  const _eventTypes = _join(eventTypes, ',');
  try {
    const response = await processBuilderServices.fetchProcessTaskDefinitions(_eventTypes);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch task definitions, please try again later!')));
    return EMPTY_ARRAY;
  }
};

const fetchProcessGlobalMetadata = async () => {
  try {
    const response = await processBuilderServices.fetchProcessGlobalMetadata();
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch event types, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const getValidatedData = async (validationType, payload) => {
  try {
    const response = await processBuilderServices.validateProcess(validationType, payload);
    return getDataFromResponse(response);
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, __('Failed to validate process')));
    return EMPTY_OBJECT;
  }
};

const activateProcess = async (workflowId, payload = EMPTY_OBJECT) => {
  try {
    const response = await processBuilderServices.activateProcess(workflowId, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Process activate successfully!'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to activate the process, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const deactivateProcess = async (workflowId, payload = EMPTY_OBJECT) => {
  try {
    const response = await processBuilderServices.deactivateProcess(workflowId, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Process deactivate successfully!'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to deactivate the process, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export {
  fetchProcesses,
  createProcess,
  updateProcess,
  getProcessById,
  fetchProcessGlobalMetadata,
  fetchProcessTaskDefinitions,
  getValidatedData,
  activateProcess,
  deactivateProcess,
};
