import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import Icon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Attachment from '@tekion/tekion-components/molecules/attachment/attachment';

import styles from './picSelector.module.scss';

const PicSelector = (props) => {
  const { disabled, multiple, getInputFiles, validFormats } = props;

  const [, setIsOpen] = useState(false);
  // TODO : we are not removing this as it is causing issue when someone upload two files back to back
  // pls check
  const uploadHandler = useCallback(
    (files) => {
      setIsOpen((prevState) => !prevState);
      getInputFiles(files);
    },
    [getInputFiles],
  );

  return (
    <div
      className={cx({
        'cursor-pointer': !disabled,
        'is-disabled': disabled,
      })}
    >
      <Attachment multiple={multiple} onSelection={uploadHandler} disabled={disabled} validFormats={validFormats}>
        <div className={styles.uploadButton}>
          <Icon size={SIZES.MD} className={styles.uploadIcon}>
            icon-upload
          </Icon>
          <div className={styles.uploadLabel}>{__('Upload')}</div>
        </div>
      </Attachment>
    </div>
  );
};

PicSelector.propTypes = {
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  validFormats: PropTypes.string,
  getInputFiles: PropTypes.func.isRequired,
};

PicSelector.defaultProps = {
  multiple: false,
  disabled: false,
  validFormats: undefined,
};

export default PicSelector;
