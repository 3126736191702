import ApiService from './apiService/apiService';

const CORE_BASE_URL = 'core/u';
const BASE_URL = `${CORE_BASE_URL}/actions`;

const fetchActions = (payload) => ApiService.post(`${BASE_URL}/search`, payload);

const fetchAction = (actionName) => ApiService.get(`${BASE_URL}/name/${actionName}`);

const createAction = (payload) => ApiService.post(`${BASE_URL}`, payload);

const updateAction = (actionName, payload) => ApiService.put(`${BASE_URL}/name/${actionName}`, payload);

const executeAction = (actionName, payload) => ApiService.post(`${BASE_URL}/name/${actionName}/execute`, payload);

const fetchApplicableActionsForRecords = (entityName, payload) =>
  ApiService.post(`${CORE_BASE_URL}/entities/${entityName}/records/actions/fetch`, payload);

const fetchRecordForUpdateActionType = (actionName, recordId) => ApiService.get(`${BASE_URL}/name/${actionName}/records/${recordId}`);

export default {
  fetchActions,
  fetchAction,
  createAction,
  updateAction,
  executeAction,
  fetchApplicableActionsForRecords,
  fetchRecordForUpdateActionType,
};
