import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _cloneDeep from 'lodash/cloneDeep';
import _size from 'lodash/size';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { getApplicationConfigWithUpdatedComponent } from '../../../../../helpers/applicationBuilder.helpers';
import { COMPONENT_KEYS, PROPERTIES_FIELD_IDS, TAB_IDS } from '../../../../../constants/applicationBuilder.constants';
import { ENTITY_INFO_FIELD_IDS, FIELD_IDS } from '../constants/tabProperties.constants';
import { COMPONENTS_TYPES } from '../../../../../../../../../../../constants/applicationRenderer.constants';

const filterFormValueForDeletedTab = (allFormValues, moduleIndex) => {
  const newAllFormValues = _filter(allFormValues, (_, index) => moduleIndex !== index);
  return _cloneDeep(newAllFormValues);
};

const getErrors = (values) => {
  const entitiesInfo = _get(values, FIELD_IDS.ENTITIES_RENDERING_INFO, EMPTY_ARRAY);
  let isValid = true;
  const entityErrorObject = _map(entitiesInfo, (entityInfo) => {
    isValid =
      isValid &&
      !_isEmpty(_get(entityInfo, ENTITY_INFO_FIELD_IDS.ENTITY_NAME)) &&
      !_isEmpty(_get(entityInfo, ENTITY_INFO_FIELD_IDS.FORM_VIEW_NAME)) &&
      !_isEmpty(_get(entityInfo, ENTITY_INFO_FIELD_IDS.DETAIL_VIEW_NAME));

    return {
      [ENTITY_INFO_FIELD_IDS.ENTITY_NAME]: _isEmpty(_get(entityInfo, ENTITY_INFO_FIELD_IDS.ENTITY_NAME)) ? __('This field is mandatory') : undefined,
      [ENTITY_INFO_FIELD_IDS.FORM_VIEW_NAME]: _isEmpty(_get(entityInfo, ENTITY_INFO_FIELD_IDS.FORM_VIEW_NAME))
        ? __('This field is mandatory')
        : undefined,
      [ENTITY_INFO_FIELD_IDS.DETAIL_VIEW_NAME]: _isEmpty(_get(entityInfo, ENTITY_INFO_FIELD_IDS.DETAIL_VIEW_NAME))
        ? __('This field is mandatory')
        : undefined,
    };
  });

  return { isValid, entityErrorObject };
};

const checkDeletedModuleIsSelectedAndLastModule = (moduleIndex, selectedModuleIndex, newAllFormValues) =>
  moduleIndex === selectedModuleIndex && moduleIndex === _size(newAllFormValues) - 1 && moduleIndex >= 1;

const getTabPropertiesValues = (applicationConfig, selectedComponentId, selectedComponent) => {
  if (selectedComponentId === TAB_IDS.APPROVAL) {
    return {
      ...tget(applicationConfig, `${COMPONENT_KEYS.PROPERTIES}.${PROPERTIES_FIELD_IDS.APPROVAL_META_DATA}`, EMPTY_OBJECT),
      [FIELD_IDS.TAB_DISPLAY_NAME]: tget(
        applicationConfig,
        `${COMPONENT_KEYS.PROPERTIES}.${PROPERTIES_FIELD_IDS.APPROVAL_META_DATA}.${PROPERTIES_FIELD_IDS.DISPLAY_NAME}`,
      ),
    };
  } else {
    return {
      ...tget(selectedComponent, COMPONENT_KEYS.PROPERTIES, EMPTY_OBJECT),
      [PROPERTIES_FIELD_IDS.PAGE_NAME]: tget(selectedComponent, PROPERTIES_FIELD_IDS.PAGE_NAME),
    };
  }
};

const setTabPropertiesInApplicationConfig = (applicationConfig, tabProperties, selectedTabId, selectedEntities) => {
  if (selectedTabId === TAB_IDS.APPROVAL) {
    let properties = tget(applicationConfig, COMPONENT_KEYS.PROPERTIES, EMPTY_OBJECT);
    let value = tabProperties;
    value = { ...tabProperties, displayName: tget(tabProperties, FIELD_IDS.TAB_DISPLAY_NAME, 'Approvals'), entitiesEnabled: selectedEntities };
    properties = { approvalMetadata: value };
    return { ...applicationConfig, properties };
  } else {
    let components = _find(
      tget(applicationConfig, 'components', EMPTY_ARRAY),
      (component) => tget(component, COMPONENT_KEYS.TYPE) === COMPONENTS_TYPES.NAVIGATION,
    );
    const children = tget(components, COMPONENT_KEYS.CHILDREN, EMPTY_ARRAY);

    _forEach(children, (value) => {
      if (tget(value, COMPONENT_KEYS.COMPONENT_ID) === selectedTabId) {
        const propertiesToAdd = {
          [FIELD_IDS.TAB_DISPLAY_NAME]: tget(tabProperties, FIELD_IDS.TAB_DISPLAY_NAME),
          [FIELD_IDS.TAB_NAME]: _snakeCase(tget(tabProperties, FIELD_IDS.TAB_DISPLAY_NAME)),
          [FIELD_IDS.TAB_ICON]: tget(tabProperties, FIELD_IDS.TAB_ICON),
        };

        _set(value, COMPONENT_KEYS.PROPERTIES, propertiesToAdd);
        _set(value, FIELD_IDS.PAGE_NAME, tget(tabProperties, FIELD_IDS.PAGE_NAME));
      }
    });

    components = { ...components, children };
    return getApplicationConfigWithUpdatedComponent(applicationConfig, COMPONENTS_TYPES.NAVIGATION, components);
  }
};

export {
  filterFormValueForDeletedTab,
  getTabPropertiesValues,
  checkDeletedModuleIsSelectedAndLastModule,
  getErrors,
  setTabPropertiesInApplicationConfig,
};
