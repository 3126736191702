import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import { FIELD_IDS } from './entityForm.constants';
import { FORM_MODES } from '../../../../constants/general.constants';

const rowCheck = {
  [FIELD_IDS.ACCESS_TYPE]: ({ formMode }) => formMode === FORM_MODES.CREATE,
  [FIELD_IDS.GRANT_ACCESS_USING_HIERARCHIES]: ({ formMode }) => formMode === FORM_MODES.CREATE,
};

export const getSections = (formMode) => [
  {
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
      },
      {
        columns: [FIELD_IDS.DESCRIPTION],
      },
      {
        columns: [FIELD_IDS.COMPLEX_FIELD],
      },
      {
        columns: [FIELD_IDS.RECORD_TYPE_ENABLED],
      },
      {
        columns: [FIELD_IDS.ENTITY_TYPE],
      },
      {
        columns: filterRows([FIELD_IDS.ACCESS_TYPE, FIELD_IDS.GRANT_ACCESS_USING_HIERARCHIES], { formMode }, rowCheck),
      },
      {
        columns: [FIELD_IDS.LIST_VIEW_ENABLED, FIELD_IDS.DETAIL_VIEW_ENABLED],
      },
      {
        columns: [FIELD_IDS.CREATE_ENABLED, FIELD_IDS.EDIT_ENABLED],
      },
      {
        columns: [FIELD_IDS.SEARCH_ENABLED, FIELD_IDS.GROUPS_ENABLED],
      },
      {
        columns: [FIELD_IDS.COMMENTS_ALLOWED, FIELD_IDS.AUDIT_ENABLED],
      },
      {
        columns: [FIELD_IDS.APPROVAL_AUTOMATION_ENABLED],
      },
    ],
  },
];
