const FIELD_IDS = {
  DISPLAY_NAME: 'displayName',
  NAME: 'name',
  DESCRIPTION: 'description',
};

const PERMISSIONS_ACCESS_TYPES = {
  READ: 'READ',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

const PERMISSION_ACCESS_IDS = {
  ACTION_TYPES: 'actionTypes',
};

export { FIELD_IDS, PERMISSIONS_ACCESS_TYPES, PERMISSION_ACCESS_IDS };
