/* eslint-disable consistent-return */
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { jsx } from 'slate-hyperscript';

import { mergeDeepToNodes, normalizeDescendantsToDocumentFragment } from '@udecode/plate-common';
import { deserializeHTMLToBreak, deserializeHTMLToFragment, deserializeHTMLToText, htmlStringToDOMNode } from '@udecode/plate-html-serializer';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { isElement } from '@udecode/plate-core';

const deserializeHTMLToMarks = (editor, { plugins, element, children }) => {
  let leaf = {};
  plugins.forEach(({ deserialize: pluginDeserializers }) => {
    const leafDeserializers = pluginDeserializers === null || pluginDeserializers === undefined ? undefined : pluginDeserializers(editor).leaf;
    if (!leafDeserializers) return;
    leafDeserializers.forEach((deserializer) => {
      const leafPart = deserializer.deserialize(element);
      if (!leafPart) return;
      leaf = { ...leaf, ...leafPart };
    });
  });
  if (children)
    return children.reduce((arr, child) => {
      if (!child) return arr;

      if (isElement(child)) {
        if (Object.keys(leaf).length) {
          mergeDeepToNodes({
            node: child,
            source: leaf,
            query: {
              filter: ([n]) => Text.isText(n),
            },
          });
        }

        arr.push(child);
      } else {
        arr.push(jsx('text', leaf, child));
      }

      return arr;
    }, []);
  return [leaf];
};

const deserializeHTMLToElement = (editor, { plugins, element, children }) => {
  let slateElement;
  let withoutChildren;
  plugins.some(({ deserialize: pluginDeserializers }) => {
    if (pluginDeserializers === undefined) {
      return;
    }

    const elementDeserializers = pluginDeserializers(editor).element;

    if (!elementDeserializers) return;

    return elementDeserializers.some((deserializer) => {
      const deserialized = deserializer.deserialize(element);

      if (!deserialized) {
        return;
      }
      slateElement = deserialized;

      if (slateElement) {
        while (element.hasChildNodes()) {
          element.removeChild(_get(element, 'firstChild'));
        }
        const slateElementStyles = deserializeHTMLToMarks(editor, {
          plugins,
          element,
        });

        return { ...slateElement, ...tget(slateElementStyles, '0', EMPTY_OBJECT) };
      }
      withoutChildren = deserializer.withoutChildren;
      return true;
    });
  });

  if (slateElement) {
    let descendants = children;

    if (!descendants.length || withoutChildren) {
      descendants = [
        {
          text: '',
        },
      ];
    }

    return jsx('element', slateElement, descendants);
  }
};

const deserializeHTMLNode = (editor, plugins) => (node) => {
  const _node$childNodes$ = _get(node, 'childNodes.0');

  const textNode = deserializeHTMLToText(node);

  if (textNode) return textNode;

  if (_get(node, 'nodeType') !== Node.ELEMENT_NODE) return null;
  const htmlElement = node;

  const breakLine = deserializeHTMLToBreak(node);

  if (breakLine) return breakLine;
  const { nodeName } = node;

  let parent = node;

  if (nodeName === 'PRE' && (_node$childNodes$ === null || _get(_node$childNodes$, 'nodeName') === 'CODE')) {
    [parent] = _get(node, 'childNodes');
  }

  const children = _map(Array.from(_get(parent, 'childNodes')), deserializeHTMLNode(editor, plugins)).flat();

  const fragment = deserializeHTMLToFragment({
    element: htmlElement,
    children,
  });

  if (fragment) return fragment;

  const element = deserializeHTMLToElement(editor, {
    plugins,
    element: htmlElement,
    children,
  });

  if (element) {
    if (nodeName === 'PRE' && (_node$childNodes$ === null || _get(_node$childNodes$, 'nodeName') === 'CODE')) {
      const classList = _get(parent, 'classList');
      if (!_isEmpty(classList)) {
        [element.lang] = classList;
      }
    }

    while (htmlElement.hasChildNodes()) {
      htmlElement.removeChild(_get(htmlElement, 'firstChild'));
    }

    const htmlElementStyles = deserializeHTMLToMarks(editor, {
      plugins,
      element: htmlElement,
    });

    return { ...element, ...tget(htmlElementStyles, '0', EMPTY_OBJECT) };
  }

  const styles = deserializeHTMLToMarks(editor, {
    plugins,
    element: htmlElement,
    children,
  });

  return styles;
};

const deserializeHTMLElement = (editor, { plugins, element }) => deserializeHTMLNode(editor, plugins)(element);

const deserializeHTMLToDocumentFragment = (editor, { plugins, element, stripWhitespace = true }) => {
  let domNode = element;
  if (typeof element === 'string') {
    domNode = htmlStringToDOMNode(domNode, stripWhitespace);
  }

  const fragment = deserializeHTMLElement(editor, {
    plugins,
    element: domNode,
  });

  return normalizeDescendantsToDocumentFragment(editor, {
    descendants: fragment,
  });
};

export default deserializeHTMLToDocumentFragment;
