import React, { useCallback, useMemo, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { sanitizeHtml } from '@tekion/tekion-components/helpers/templateBuilder/domPurify';
import _noop from 'lodash/noop';
import _values from 'lodash/values';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';

import { Plate, withPlate, pipe } from '@udecode/plate-core';

import { createEditor } from 'slate';
import { withReact } from 'slate-react';
import { EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import ACTION_TYPES from 'tcomponents/organisms/FormBuilder/constants/actionTypes';

// context
import { Provider as AntDPopupProvider } from '@tekion/tekion-components/molecules/AntdPopupContainerContext';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';

// helpers
import components from './components';

// hooks
import useEditableProps from './hooks/useEditableProps';
import useEditorOptions from './hooks/useEditorOptions';

// components
import ErrorBoundary from '../../atoms/errorBoundary';
import LinkDialog from './organisms/linkDialog';
import ShortcutsInfoDialog from './organisms/shortcutsInfoDialog';
import { Toolbar } from './toolbar';

// import TemplateVariableDialog from './organisms/templateVariableDialog';
import { serializeHTMLFromNodes } from './helpers/richTextEditor.serializer';
import deserializeHTMLToDocumentFragment from './helpers/richTextEditor.deserializer';
import getPlainTextFromRTEJSON from './helpers/richTextEditor.getPlainText';
import getPlugins from './plugins';
import { TOOLBAR_GROUPS } from './constants/richTextEditor.plugins';

import 'prismjs/themes/prism.css';
// styles
import styles from './richTextEditor.module.scss';

const serializeEditor = withReact(createEditor());

const RichTextEditor = (props) => {
  const {
    readOnly,
    required,
    customCodeEnabled,
    label = '',
    className,
    value,
    editorId,
    initialValue,
    toolbarStyles,
    toolbarGroups,
    editorStyles,
    id,
    onAction,
  } = props;

  const editorContainerRef = useRef();

  const antDPopupContextValue = useMemo(() => ({ scrollParentRef: editorContainerRef }), [editorContainerRef]);

  const editableProps = useEditableProps(readOnly, editorStyles);

  const options = useEditorOptions(readOnly);

  const plugins = useMemo(() => getPlugins(toolbarGroups), [toolbarGroups]);

  const editor = useMemo(() => pipe(createEditor(), withPlate({ id: editorId, plugins, components })), [editorId, plugins]);

  let deserializedHTMLValue = '';

  if (_isString(value)) {
    if (!_isEmpty(value) && value !== NO_DATA) {
      try {
        deserializedHTMLValue = JSON.parse(value);
      } catch (error) {
        const newHtmlValue = sanitizeHtml(value);
        deserializedHTMLValue = deserializeHTMLToDocumentFragment(editor, { plugins, element: newHtmlValue });
      }
    } else {
      deserializedHTMLValue = undefined;
    }
  }

  const onChange = useCallback(
    (editorContent) => {
      const plainText = getPlainTextFromRTEJSON(editorContent);
      const htmlContent = serializeHTMLFromNodes(editor, {
        plugins,
        nodes: editorContent,
        slateProps: { editor: serializeEditor },
      });

      onAction({
        type: ACTION_TYPES.ON_FIELD_CHANGE,
        payload: {
          id,
          value: { plainText, editorContent, htmlContent },
        },
      });
    },
    [editor, id, onAction, plugins],
  );

  return (
    <>
      <PropertyControlledComponent controllerProperty={!_isEmpty(label)}>
        <FieldLabel required={required} label={label} labelClassName={styles.fieldLabel} />
      </PropertyControlledComponent>

      <ErrorBoundary>
        <AntDPopupProvider value={antDPopupContextValue}>
          <div
            className={cx(styles.containerClass, { [styles.editorContainer]: !readOnly && !customCodeEnabled }, className)}
            ref={editorContainerRef}
          >
            <Plate
              id={editorId}
              value={_isEmpty(deserializedHTMLValue) ? value : deserializedHTMLValue}
              initialValue={initialValue}
              editableProps={editableProps}
              onChange={onChange}
              plugins={plugins}
              components={components}
              options={options}
            >
              {!(readOnly || customCodeEnabled) && <Toolbar toolbarStyles={toolbarStyles} toolbarGroups={toolbarGroups} />}
              {!readOnly && <LinkDialog />}
              <PropertyControlledComponent controllerProperty={!readOnly}>
                <ShortcutsInfoDialog toolbarGroups={toolbarGroups} />
              </PropertyControlledComponent>
              {/* {!readOnly && <TemplateVariableDialog />} */}
            </Plate>
          </div>
        </AntDPopupProvider>
      </ErrorBoundary>
    </>
  );
};

RichTextEditor.propTypes = {
  // editorId is mandatory if multiple RTE are rendered
  required: PropTypes.bool,
  customCodeEnabled: PropTypes.bool,
  label: PropTypes.string,
  editorId: PropTypes.string,
  id: PropTypes.string,
  initialValue: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  toolbarStyles: PropTypes.object,
  editorStyles: PropTypes.object,
  toolbarGroups: PropTypes.array,
  onAction: PropTypes.func,
};

RichTextEditor.defaultProps = {
  required: false,
  customCodeEnabled: false,
  label: EMPTY_STRING,
  editorId: undefined,
  id: undefined,
  initialValue: [
    {
      type: 'p',
      children: [{ text: '' }],
    },
  ],
  value: undefined,
  readOnly: false,
  className: undefined,
  toolbarStyles: EMPTY_OBJECT,
  editorStyles: EMPTY_OBJECT,
  toolbarGroups: _values(TOOLBAR_GROUPS),
  onAction: _noop,
};

export default RichTextEditor;
