import React from 'react';
import _map from 'lodash/map';
import { BalloonToolbar as PlateBalloonToolbar } from '@udecode/plate-toolbar';

// constants
import { MARK_OPTIONS_CONFIG } from './constants/toolbar.config';
import MarkToolbarButton from './molecules/markToolbarButton';

const POPPER_OPTIONS = {
  placement: 'top',
};

function BalloonToolbar() {
  return (
    <PlateBalloonToolbar popperOptions={POPPER_OPTIONS} theme="dark" arrow={false}>
      {_map(MARK_OPTIONS_CONFIG, (config) => {
        const { renderProps, id } = config;
        const { mark, icon } = renderProps;
        return <MarkToolbarButton key={id} mark={mark} icon={icon} />;
      })}
    </PlateBalloonToolbar>
  );
}

export default BalloonToolbar;
