import React from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _map from 'lodash/map';

import List from '@tekion/tekion-components/molecules/List';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import { EMPTY_ARRAY, EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

import DerivationConditionTable from '../derivationConditionTable/DerivationConditionTable';
import TagGenerator from '../../../../molecules/tagGenerator/TagGenerator';
import { getDescription, getDisplayName } from '../../helpers/recordTypeOverride.helpers';
import { RECORD_TYPES } from '../../../../../../constants/general.constants';
import { STATUS } from '../../constants/recordTypeOverride.constants';
import entityReader from '../../../../../../readers/entity.reader';
import recordTypeReader from '../../../../../../readers/recordType.reader';

import styles from './recordtypeCardView.module.scss';

const RecordTypeCardView = ({ recordTypeDetails, entity, handleEditClick }) => {
  const status = recordTypeReader.status(recordTypeDetails) ? STATUS.ACTIVE : STATUS.INACTIVE;
  const fields = _map(_get(recordTypeDetails, 'availableFields', EMPTY_ARRAY), (item) => getDisplayName(entity, item));
  const description = getDescription(recordTypeReader.description(recordTypeDetails));

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Name')}</div>
        <List.Item.Meta description={__(recordTypeReader.name(recordTypeDetails)) || NO_DATA} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('DisplayName')}</div>
        <List.Item.Meta description={__(recordTypeReader.displayName(recordTypeDetails)) || NO_DATA} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Description')}</div>
        <List.Item.Meta description={__(description) || NO_DATA} />
      </div>
      <div className={styles.iconItem}>
        <FontIcon size={SIZES.MD_S} onClick={handleEditClick} className="cursor-pointer m-r-32">
          icon-edit
        </FontIcon>
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Status')}</div>
        <List.Item.Meta description={__(status) || NO_DATA} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Record Type')}</div>
        <List.Item.Meta description={__(entityReader.recordTypeDefinitionType(entity)) || NO_DATA} />
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.subItem}>{__('Available Fields')}</div>
        <TagGenerator fields={fields} />
      </div>
      <PropertyControlledComponent controllerProperty={entityReader.recordTypeDefinitionType(entity) === RECORD_TYPES.DERIVED}>
        <div className={styles.derivationConditionContainer}>
          <DerivationConditionTable condition={recordTypeReader.derivationCondition(recordTypeDetails)} entity={entity} />
        </div>
      </PropertyControlledComponent>
    </div>
  );
};

RecordTypeCardView.propTypes = {
  entity: PropTypes.object,
  recordTypeDetails: PropTypes.object,
  handleEditClick: PropTypes.func.isRequired,
};

RecordTypeCardView.defaultProps = {
  entity: undefined,
  recordTypeDetails: EMPTY_OBJECT,
};
export default RecordTypeCardView;
