import { useCallback, useMemo, useState } from 'react';

import _keyBy from 'lodash/keyBy';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import actionHandlers from './helpers/useShareRecordForm.actionHandlers';
import getShareRecordFormFields from '../../helpers/shareRecordForm.fields';

import RECORD_FIELDS from '../../../../../../../../../../constants/recordFields';

const useShareRecordForm = ({ onUserAddition, contextId, entityRecord, entityDef, shareAndManageRecordUserDetails, wwdAccessTypesOptions }) => {
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [allUsersById, setAllUsersById] = useState({});
  const [isUserAdditionInProgress, setIsUserAdditionInProgress] = useState(false);

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload } = action;

      const actionHandler = actionHandlers[type];

      if (actionHandler) {
        actionHandler({
          payload,
          formValues,
          formErrors,
          setFormValues,
          setFormErrors,
          onUserAddition,
          entityRecord,
          entityDef,
          allUsersById,
          setIsUserAdditionInProgress,
        });
      }
    },
    [allUsersById, entityDef, entityRecord, formErrors, formValues, onUserAddition],
  );

  const handleSubmit = useCallback(() => {
    triggerSubmit(contextId);
  }, [contextId]);

  const handleAddNewUsersDetail = useCallback((usersDetail) => {
    const users = _keyBy(usersDetail, RECORD_FIELDS.ID);
    setAllUsersById((prev) => ({ ...prev, ...users }));
  }, []);

  const fields = useMemo(
    () => getShareRecordFormFields(handleAddNewUsersDetail, shareAndManageRecordUserDetails, wwdAccessTypesOptions),
    [handleAddNewUsersDetail, shareAndManageRecordUserDetails, wwdAccessTypesOptions],
  );

  return { isUserAdditionInProgress, formValues, formErrors, handleAction, handleSubmit, fields };
};

export default useShareRecordForm;
