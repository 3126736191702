import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import SwitchRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch/Switch';

import { GRID_PROPERTIES_CONFIGURE_FIELD_IDS } from '../constants/gridPropertiesConfigure.fieldIds';
import { WIDGET_POSITION_OPTIONS } from '../constants/gridPropertiesConfigure.general.constants';

const WIDGET_POSITION_FIELD = {
  id: GRID_PROPERTIES_CONFIGURE_FIELD_IDS.WIDGET_POSITION,
  renderer: SelectInput,
  renderOptions: {
    required: true,
    label: __('Widget Position'),
    validators: [isRequiredRule],
    placeholder: __('Select Widget Position'),
    options: WIDGET_POSITION_OPTIONS,
  },
};
const SCROLL_ENABLED_FIELD = {
  id: GRID_PROPERTIES_CONFIGURE_FIELD_IDS.WIDGET_POSITION,
  renderer: SwitchRenderer,
  renderOptions: {
    label: __('Enable Scroll'),
  },
};

const GRID_PROPERTIES_CONFIGURE_FIELDS = {
  [GRID_PROPERTIES_CONFIGURE_FIELD_IDS.WIDGET_POSITION]: WIDGET_POSITION_FIELD,
  [GRID_PROPERTIES_CONFIGURE_FIELD_IDS.SCROLL_ENABLED]: SCROLL_ENABLED_FIELD,
};

export { GRID_PROPERTIES_CONFIGURE_FIELDS };
