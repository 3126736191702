import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import {
  SUBSCRIPTION_CONFIG_NAME_FIELD,
  SUBSCRIPTION_CONFIG_DISPLAY_NAME_FIELD,
  SUBSCRIPTION_CONFIG_STATUS_FIELD,
  SUBSCRIPTION_CONFIG_PATH_FIELD,
  SUBSCRIPTION_CONFIG_SERVER_CREDENTIAL_FIELD,
  SUBSCRIPTION_CONFIG_SUBSCRIPTIONS_FIELD,
} from '../constants/subscriptionConfigForm.fields';
import FIELD_IDS from '../constants/subscriptionConfigForm.fieldIds';
import { FORM_MODES } from '../../../../../../constants/general.constants';

import styles from '../subscriptionConfigForm.module.scss';

const rowCheck = {
  [FIELD_IDS.STATUS]: ({ formMode }) => formMode === FORM_MODES.EDIT,
};

const getSections = (formMode) => [
  {
    rows: [{ columns: [FIELD_IDS.SUBSCRIPTION_CONFIG_DISPLAY_NAME, FIELD_IDS.SUBSCRIPTION_CONFIG_NAME] }],
  },
  {
    rows: [{ columns: [FIELD_IDS.SERVER_CREDENTIAL, FIELD_IDS.PATH] }],
  },
  {
    rows: filterRows([FIELD_IDS.STATUS], { formMode }, rowCheck),
  },
  {
    className: styles.fullWidthSection,
    rows: [{ columns: [FIELD_IDS.SUBSCRIPTIONS] }],
  },
];

const FORM_FIELDS = {
  [FIELD_IDS.SUBSCRIPTION_CONFIG_NAME]: SUBSCRIPTION_CONFIG_NAME_FIELD,
  [FIELD_IDS.SUBSCRIPTION_CONFIG_DISPLAY_NAME]: SUBSCRIPTION_CONFIG_DISPLAY_NAME_FIELD,
  [FIELD_IDS.STATUS]: SUBSCRIPTION_CONFIG_STATUS_FIELD,
  [FIELD_IDS.SERVER_CREDENTIAL]: SUBSCRIPTION_CONFIG_SERVER_CREDENTIAL_FIELD,
  [FIELD_IDS.PATH]: SUBSCRIPTION_CONFIG_PATH_FIELD,
  [FIELD_IDS.SUBSCRIPTIONS]: SUBSCRIPTION_CONFIG_SUBSCRIPTIONS_FIELD,
};

const getFields = (formMode) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.SUBSCRIPTION_CONFIG_NAME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.SUBSCRIPTION_CONFIG_NAME], [
    {
      path: 'disabled',
      value: formMode === FORM_MODES.EDIT,
    },
  ]),
});

export { getSections, getFields };
