const FIELD_IDS = {
  SCHEDULE_TYPE: 'type',
  NAME: 'name',
  DESCRIPTION: 'description',
  STATUS: 'scheduledStatus',
  DISPLAY_NAME: 'displayName',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  TIME_ZONE: 'timeZone',
  CRON_EXPRESSION: 'cronExpression',
  SIMPLE_FOREVER: 'forever',
  SIMPLE_TRIGGER_TIME: 'simpleTriggerTime',
  SIMPLE_REPEAT_COUNT: 'repeatCount',
  TASK_TYPE: 'taskType',
  VIEW_NAME: 'viewName',
  ENTITY_NAME: 'entityName',
  FILE_FORMAT: 'fileFormat',
};

export default FIELD_IDS;
