import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import _includes from 'lodash/includes';

// Constants
import ROUTES from './constants/routes';
import { TENANT_UNIVERSE_WORKSPACE_ID } from '../../constants/general.constants';
import { TENANT_UNIVERSE_BASE_ROUTE } from '../../constants/routes';

// Readers
import localStorageReader from '../../readers/localStorage.reader';
import userLocalStorageReader from '../../readers/loginResponse.reader';

// Pages
import HomePage from './pages/homePage';
import EntityRecord from './pages/entityRecordListPage/EntityRecordListPage';
import EntityRecordPage from './pages/entityRecordPage';
import EntityRecordDetailsPage from './pages/entityRecordDetailPage';
import ApplicationRenderer from './pages/applicationRenderer';
import ExportListPage from './pages/exportListPage/ExportListPage';
import Dashboard from './pages/dashboard/Dashboard';
import {
  ApprovalManagementWithWrappedWithSize,
  ApprovalRequestFormWithWrappedWithSize,
  ApprovalRequestDetailsPageWithWrappedWithSize,
} from './pages/approvalCentre';

const DevPlatformExperience = () => {
  const userInfo = localStorageReader.userInfo();
  const currentWorkspaceId = userLocalStorageReader.workspaceId(userInfo);

  if (_includes(currentWorkspaceId, TENANT_UNIVERSE_WORKSPACE_ID)) {
    return <Redirect to={TENANT_UNIVERSE_BASE_ROUTE} />;
  }

  return (
    <div className="full-height full-width">
      <Switch>
        <Route path={ROUTES.EXPORT_LIST_PAGE_ROUTE} component={ExportListPage} />
        <Route path={ROUTES.APPLICATION_RENDERER_ROUTE} component={ApplicationRenderer} />
        <Route path={ROUTES.CREATE_ENTITY_RECORD_ROUTE} component={EntityRecordPage} />
        <Route path={ROUTES.EDIT_ENTITY_RECORD_ROUTE} component={EntityRecordPage} />
        <Route path={ROUTES.ENTITY_RECORD_DETAIL_ROUTE} component={EntityRecordDetailsPage} />
        <Route path={ROUTES.ENTITY_RECORD_RIGHT_PANE_ROUTE} component={EntityRecord} />
        <Route path={ROUTES.ENTITY_RECORD_ROUTE} component={EntityRecord} />
        <Route path={ROUTES.DASHBOARD_PAGE} component={Dashboard} />
        <Route exact path={ROUTES.APPROVAL_CENTRE_MANAGEMENT} component={ApprovalManagementWithWrappedWithSize} />
        <Route exact path={ROUTES.APPROVAL_CENTRE_CREATE_REQUEST} component={ApprovalRequestFormWithWrappedWithSize} />
        <Route exact path={ROUTES.APPROVAL_CENTRE_REQUEST} component={ApprovalRequestDetailsPageWithWrappedWithSize} />
        <Route exact path={ROUTES.APPROVAL_CENTRE_EDIT_REQUEST} component={ApprovalRequestFormWithWrappedWithSize} />
        <Route path={ROUTES.HOME_PAGE_ROUTE} component={HomePage} />
      </Switch>
    </div>
  );
};

export default DevPlatformExperience;
