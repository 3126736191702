import { DEFAULTS_LINK } from '@udecode/plate-link';

const { getNodeProps } = DEFAULTS_LINK;

const makeGetLinkCellNodeProps =
  (readOnly) =>
  ({ element }) => ({
    ...getNodeProps?.({ element }),
    disableEditing: readOnly,
  });

const createLinkCellNodeOptions = (readOnly) => ({
  ...DEFAULTS_LINK,
  getNodeProps: makeGetLinkCellNodeProps(readOnly),
});

export default createLinkCellNodeOptions;
