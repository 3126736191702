import EventEmitter from 'eventemitter3';

// Event Types

const linkDialogEvent = new EventEmitter();

export const showLinkDialog = (editor) => {
  linkDialogEvent.emit(`SHOW_LINK_DIALOG_${editor.id}`);
};

export const hideLinkDialog = (editor) => {
  linkDialogEvent.emit(`HIDE_LINK_DIALOG_${editor.id}`);
};

export default linkDialogEvent;
