import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import Heading from '@tekion/tekion-components/atoms/Heading';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Button from '@tekion/tekion-components/atoms/Button';
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox/Checkbox';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import CustomActionsConfiguratorModal from './components/customActionsConfiguratorModal';

import ACTION_HANDLERS from './helpers/customActionsConfigurator.actionHandlers';

import ACTION_TYPES from './constants/customActionsConfigurator.actionTypes';
import {
  ACTIONS_CONFIGURATOR_FORM_CONTEXT_ID,
  FIELD_IDS,
  CUSTOM_ROW_ACTION_CONFIGURATOR_FORM_CONTEXT_ID,
} from './constants/customActionsConfigurator.constants';
import { TAB_IDS } from './components/customActionsConfiguratorModal/customActionsConfiguratorModal.constants';

import styles from './customActionsConfigurator.module.scss';

const CustomActionsConfigurator = ({
  isModalVisible,
  customRowActionsLeftPanelSelectedIndex,
  selectedTabId,
  selectedViewComponentProperties,
  entity,
  formValues,
  errors,
  onAction,
}) => {
  const handleSubmit = useCallback(() => {
    const actionsValue = tget(formValues, TAB_IDS.ACTION_BUILDER_ACTIONS_TAB);
    const customRowActionsValue = tget(formValues, TAB_IDS.CUSTOM_ACTIONS_TAB);

    if (_isEmpty(actionsValue)) {
      onAction({ type: FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT, payload: { additional: { actionType: ACTION_TYPES.ON_SUBMIT } } });
    } else {
      triggerSubmit(ACTIONS_CONFIGURATOR_FORM_CONTEXT_ID, { actionType: ACTION_TYPES.ON_SUBMIT });
    }

    if (_isEmpty(customRowActionsValue)) {
      onAction({ type: FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT, payload: { additional: { actionType: ACTION_TYPES.ON_SUBMIT } } });
    } else {
      triggerSubmit(CUSTOM_ROW_ACTION_CONFIGURATOR_FORM_CONTEXT_ID, { actionType: ACTION_TYPES.ON_SUBMIT });
    }
  }, [formValues, onAction]);

  const handleCancelModal = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CANCEL_MODAL,
    });
  }, [onAction]);

  const handleConfigureClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CLICK_CONFIGURE_ACTIONS,
    });
  }, [onAction]);

  const handleShowAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          onAction({
            type: ACTION_TYPES.ON_SHOW_ACTIONS_CHANGE,
            payload,
          });
          break;
        }

        default: {
          break;
        }
      }
    },
    [onAction],
  );

  const showActionsValue = useMemo(() => tget(selectedViewComponentProperties, [FIELD_IDS.SHOW_ACTIONS]), [selectedViewComponentProperties]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE,
      payload: { selectedViewComponentProperties },
    });
  }, [onAction, selectedViewComponentProperties]);

  return (
    <div className={styles.customActionsComponent}>
      <Heading size={5} className={styles.customActionComponentHeading}>
        {__('Row actions configurator')}
      </Heading>
      <div className={styles.actionsComponent}>
        {/* <div className={styles.addedCustomActionsContainer}>
          <div className={styles.addedCustomActionSection}>
            {__('Added Actions ')}({countOfSelectedCustomActions})
          </div>
        </div> */}
        <CheckboxRenderer
          id={FIELD_IDS.SHOW_ACTIONS}
          required
          checkboxLabel={__('Show Actions')}
          value={showActionsValue}
          validators={[isRequiredRule]}
          onAction={handleShowAction}
        />
        <Button disabled={!showActionsValue} view={Button.VIEW.TERTIARY} className={styles.configureButton} onClick={handleConfigureClick}>
          {__('Configure')}
        </Button>
      </div>
      <Modal
        visible={isModalVisible}
        width={SIZES.L}
        bodyStyle={{ padding: 0 }}
        title={__('Configure Row Actions')}
        submitBtnText={__('Submit')}
        onSubmit={handleSubmit}
        onCancel={handleCancelModal}
      >
        <CustomActionsConfiguratorModal
          showActionsValue={showActionsValue}
          customRowActionsLeftPanelSelectedIndex={customRowActionsLeftPanelSelectedIndex}
          selectedTabId={selectedTabId}
          entity={entity}
          formValues={formValues}
          errors={errors}
          onAction={onAction}
        />
      </Modal>
    </div>
  );
};

CustomActionsConfigurator.propTypes = {
  isModalVisible: PropTypes.bool,
  customRowActionsLeftPanelSelectedIndex: PropTypes.number,
  selectedTabId: PropTypes.string,
  selectedViewComponentProperties: PropTypes.object,
  entity: PropTypes.object,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

CustomActionsConfigurator.defaultProps = {
  isModalVisible: false,
  customRowActionsLeftPanelSelectedIndex: 0,
  selectedTabId: TAB_IDS.ACTION_BUILDER_ACTIONS_TAB,
  selectedViewComponentProperties: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(CustomActionsConfigurator);
