import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';
import _concat from 'lodash/concat';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _isArray from 'lodash/isArray';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { COMPONENT_KEYS, PROPERTIES_FIELD_IDS, APPLICATION_CONFIG_FIELD_IDS } from '../../../constants/applications.constants';
import { COMPONENTS_TYPES } from '../../../../../../../constants/applicationRenderer.constants';
import { ENTITY_APPLICATION_MAPPING_FIELD_IDS } from '../../../../../../../constants/entityApplicationMapping.constants';
import { FIELD_ERROR_MESSAGE } from '../constants/entityMappings.constants';

const getTabOptions = (applicationConfiguration) => {
  const components = tget(applicationConfiguration, APPLICATION_CONFIG_FIELD_IDS.COMPONENTS, EMPTY_ARRAY);
  const navigationComponent = _find(components, (component) => tget(component, COMPONENT_KEYS.TYPE) === COMPONENTS_TYPES.NAVIGATION);

  if (navigationComponent) {
    const children = tget(navigationComponent, COMPONENT_KEYS.CHILDREN, EMPTY_ARRAY);

    return _filter(
      _map(children, (tab) => {
        const properties = tget(tab, COMPONENT_KEYS.PROPERTIES, EMPTY_ARRAY);
        const flattenedProps = _isArray(properties)
          ? _reduce(
              properties,
              (_flattened, { type, value }) => ({
                ..._flattened,
                [type]: value,
              }),
              {},
            )
          : properties;

        return {
          label: tget(flattenedProps, PROPERTIES_FIELD_IDS.TAB_DISPLAY_NAME),
          value: tget(flattenedProps, PROPERTIES_FIELD_IDS.TAB_NAME),
        };
      }),
      ({ value }) => !!value,
    );
  }

  return EMPTY_ARRAY;
};

const validateRow = (mappingRow, hasTabs = false) => {
  let rowHasErrors = false;
  const error = {};

  const {
    [ENTITY_APPLICATION_MAPPING_FIELD_IDS.ENTITY_NAME]: entityName,
    [ENTITY_APPLICATION_MAPPING_FIELD_IDS.PAGE_NAME]: pageName,
    [ENTITY_APPLICATION_MAPPING_FIELD_IDS.TAB_NAME]: tabName,
  } = mappingRow;

  if (_isEmpty(entityName)) {
    rowHasErrors = true;
    _set(error, ENTITY_APPLICATION_MAPPING_FIELD_IDS.ENTITY_NAME, FIELD_ERROR_MESSAGE);
  }
  if (_isEmpty(pageName)) {
    rowHasErrors = true;
    _set(error, ENTITY_APPLICATION_MAPPING_FIELD_IDS.PAGE_NAME, FIELD_ERROR_MESSAGE);
  }
  if (hasTabs && _isEmpty(tabName)) {
    rowHasErrors = true;
    _set(error, ENTITY_APPLICATION_MAPPING_FIELD_IDS.TAB_NAME, FIELD_ERROR_MESSAGE);
  }

  return { rowErrors: error, rowHasErrors };
};

const validateAndSanitizeMappings = (applicationName, tabOptions, mappings) => {
  const hasTabs = !_isEmpty(tabOptions);

  return _reduce(
    mappings,
    ({ payload, errors, hasErrors }, mapping) => {
      const { rowErrors, rowHasErrors } = validateRow(mapping, hasTabs);

      return {
        payload: _concat(payload, {
          ...mapping,
          [ENTITY_APPLICATION_MAPPING_FIELD_IDS.APPLICATION_NAME]: applicationName,
        }),
        errors: _concat(errors, rowErrors),
        hasErrors: hasErrors || rowHasErrors,
      };
    },
    { payload: [], errors: [], hasErrors: false },
  );
};

export { getTabOptions, validateRow, validateAndSanitizeMappings };
