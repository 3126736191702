import { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';

const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

const INITIAL_STATE_EXPORT_LIST_PROPS = {
  currentPage: 0,
  pageSize: 50,
};

const TABLE_MANAGER_PROPS = {
  showHeader: true,
  showSearch: true,
  showFilter: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const HEADER_PROPS = {
  label: __('Bundles'),
};

const BUNDLE_STATE = {
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
};

const BUNDLE_STATE_COLOR_MAP = {
  [BUNDLE_STATE.PUBLISHED]: COLOR_MAP.GREEN,
  [BUNDLE_STATE.DRAFT]: COLOR_MAP.ORANGE,
};

const BUNDLE_STATE_LABEL_MAP = {
  [BUNDLE_STATE.PUBLISHED]: __('Published'),
  [BUNDLE_STATE.DRAFT]: __('Draft'),
};

const BUNDLE_STATE_FILTER_OPTIONS = {
  PUBLISHED: { label: __('Published'), value: BUNDLE_STATE.PUBLISHED },
  DRAFT: { label: __('Saved as draft'), value: BUNDLE_STATE.DRAFT },
};

export {
  TABLE_CONSTANTS,
  INITIAL_STATE_EXPORT_LIST_PROPS,
  TABLE_MANAGER_PROPS,
  HEADER_PROPS,
  BUNDLE_STATE,
  BUNDLE_STATE_COLOR_MAP,
  BUNDLE_STATE_LABEL_MAP,
  BUNDLE_STATE_FILTER_OPTIONS,
};
