import { useMemo } from 'react';
import { Editor, Node } from 'slate';

// constants
import FIELD_IDS from '../constants/templateVariableForm.fieldIds';

const getInitialFormValues = (editor, selectedTemplateVariableNode) => {
  if (selectedTemplateVariableNode) {
    const [selectedTemplateVariableNodeElement] = selectedTemplateVariableNode;
    return {
      [FIELD_IDS.VARIABLE_NAME]: Node.string(selectedTemplateVariableNodeElement),
    };
  }
  const text = Editor.string(editor, editor.selection);
  return {
    [FIELD_IDS.VARIABLE_NAME]: text,
  };
};

function useFormInitialValues(editor, selectedTemplateVariableNode) {
  const initialValues = useMemo(() => getInitialFormValues(editor, selectedTemplateVariableNode), [editor, selectedTemplateVariableNode]);

  return initialValues;
}

export default useFormInitialValues;
