import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { APPROVAL_STATUS_TYPES } from '../../../../constants/approvalCentre.constants';
import COLUMN_IDS from './approvalTasksList.columnIds';

const ACTION_TYPES = {
  APPROVE_REQUEST: 'APPROVE_REQUEST',
  REJECT_REQUEST: 'REJECT_REQUEST',
};

const INITIAL_STATE = {
  selectedFilters: [
    {
      type: COLUMN_IDS.STATUS,
      operator: OPERATORS.IN,
      values: [APPROVAL_STATUS_TYPES.PENDING],
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showFilter: true,
  showHeader: false,
  showSearch: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const APPROVE_REQUEST_ROW_ACTION = {
  id: ACTION_TYPES.APPROVE_REQUEST,
  name: __('Approve Request'),
};

const REJECT_REQUEST_ROW_ACTION = {
  id: ACTION_TYPES.REJECT_REQUEST,
  name: __('Reject Request'),
};

export { ACTION_TYPES, INITIAL_STATE, TABLE_MANAGER_PROPS, APPROVE_REQUEST_ROW_ACTION, REJECT_REQUEST_ROW_ACTION };
