import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import withFormPageState from '@tekion/tekion-components/connectors/withFormPageState';
import AppLogo from '@tekion/tekion-components/atoms/AppLogo';
import NotificationWrapper from '@tekion/tekion-components/organisms/NotificationWrapper';
import { FormWithSubmission } from '@tekion/tekion-components/pages/formPage';
import ACTION_HANDLER from './authentication.actionHandler';
import styles from './authentication.module.scss';

const getAuthenticationFormContainer = (customActionHandlers = EMPTY_OBJECT) => {
  const FormWithState = withFormPageState({}, { ...ACTION_HANDLER, ...customActionHandlers })(FormWithSubmission);

  const FormContainer = ({ renderActionComponent, ...restProps }) => (
    <div className={`${styles.container} full-height`}>
      <div className={styles.coverImage} />
      <div className={styles.loginForm}>
        <div className={`full-width ${styles.iconContainer}`}>
          <AppLogo />
        </div>

        <FormWithState {...restProps} className={styles.formPageContainer} footerComponent={null} headerComponent={null} />

        {renderActionComponent()}
      </div>

      <NotificationWrapper />
    </div>
  );

  FormContainer.propTypes = {
    renderActionComponent: PropTypes.func,
  };

  FormContainer.defaultProps = {
    renderActionComponent: _noop,
  };

  return FormContainer;
};

export default getAuthenticationFormContainer;
