import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS } from '../constants/buttonPropertiesConfigure.fieldIds';

const getFormFormattedSelectedWidgetProperties = (selectedWidgetProperties) => {
  const buttonViewType = tget(selectedWidgetProperties, BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS.BUTTON_VIEW_TYPE);

  if (!_isEmpty(buttonViewType)) {
    return { ...selectedWidgetProperties, [BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS.BUTTON_VIEW_TYPE]: _castArray(buttonViewType) };
  }
  return selectedWidgetProperties;
};

const getConfigFormattedSelectedWidgetProperties = (value, id, selectedWidgetProperties) => {
  if (id === BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS.BUTTON_VIEW_TYPE) {
    return { ...selectedWidgetProperties, [BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS.BUTTON_VIEW_TYPE]: getArraySafeValue(value) };
  }
  return { ...selectedWidgetProperties, [id]: value };
};

export { getFormFormattedSelectedWidgetProperties, getConfigFormattedSelectedWidgetProperties };
