import { VIEW_TYPES } from '../../../../../constants/viewBuilder.constants';
import { WIDGET_TYPES } from '../../../../../constants/visualBuilder';
import { PROPERTIES_CONFIGURATOR } from '../constants/propertyPanel.constants';

const getPropertiesPanelByViewType = (viewType) => {
  if (viewType === VIEW_TYPES.LIST_VIEW || viewType === VIEW_TYPES.GRID_VIEW) {
    return [
      PROPERTIES_CONFIGURATOR.ENTITY_AND_VIEW_CONFIGURATOR,
      PROPERTIES_CONFIGURATOR.WIDGET_NAME_CONFIGURATOR,
      PROPERTIES_CONFIGURATOR.EVENT_HANDLER_CONFIGURATOR,
      PROPERTIES_CONFIGURATOR.CUSTOM_STYLE_CONFIGURATOR,
      PROPERTIES_CONFIGURATOR.PRE_APPLIED_FILTERS_CONFIGURATOR,
    ];
  }
  if (viewType === VIEW_TYPES.DETAIL_VIEW) {
    return [
      PROPERTIES_CONFIGURATOR.ENTITY_AND_VIEW_CONFIGURATOR,
      PROPERTIES_CONFIGURATOR.WIDGET_NAME_CONFIGURATOR,
      PROPERTIES_CONFIGURATOR.EVENT_HANDLER_CONFIGURATOR,
      PROPERTIES_CONFIGURATOR.CUSTOM_STYLE_CONFIGURATOR,
      PROPERTIES_CONFIGURATOR.CUSTOM_ACTIONS_CONFIGURATOR,
    ];
  }
  return [
    PROPERTIES_CONFIGURATOR.ENTITY_AND_VIEW_CONFIGURATOR,
    PROPERTIES_CONFIGURATOR.WIDGET_NAME_CONFIGURATOR,
    PROPERTIES_CONFIGURATOR.EVENT_HANDLER_CONFIGURATOR,
    PROPERTIES_CONFIGURATOR.CUSTOM_STYLE_CONFIGURATOR,
  ];
};

const getPropertiesPanelByWidgetType = (viewType) => ({
  [WIDGET_TYPES.BUTTON_WIDGET]: [
    PROPERTIES_CONFIGURATOR.WIDGET_NAME_CONFIGURATOR,
    PROPERTIES_CONFIGURATOR.BUTTON_PROPERTIES_CONFIGURATOR,
    PROPERTIES_CONFIGURATOR.EVENT_HANDLER_CONFIGURATOR,
    PROPERTIES_CONFIGURATOR.CUSTOM_STYLE_CONFIGURATOR,
  ],
  [WIDGET_TYPES.TAB_WIDGET]: [
    PROPERTIES_CONFIGURATOR.WIDGET_NAME_CONFIGURATOR,
    PROPERTIES_CONFIGURATOR.TAB_PROPERTIES_CONFIGURATOR,
    PROPERTIES_CONFIGURATOR.EVENT_HANDLER_CONFIGURATOR,
    PROPERTIES_CONFIGURATOR.CUSTOM_STYLE_CONFIGURATOR,
  ],
  [WIDGET_TYPES.TEXT_WIDGET]: [
    PROPERTIES_CONFIGURATOR.WIDGET_NAME_CONFIGURATOR,
    PROPERTIES_CONFIGURATOR.TEXT_PROPERTIES_CONFIGURATOR,
    PROPERTIES_CONFIGURATOR.EVENT_HANDLER_CONFIGURATOR,
    PROPERTIES_CONFIGURATOR.CUSTOM_STYLE_CONFIGURATOR,
  ],
  [WIDGET_TYPES.CONTAINER]: [PROPERTIES_CONFIGURATOR.GRID_PROPERTIES_CONFIGURATOR, PROPERTIES_CONFIGURATOR.CUSTOM_STYLE_CONFIGURATOR],
  [WIDGET_TYPES.PARENT_WIDGET]: getPropertiesPanelByViewType(viewType),
  [WIDGET_TYPES.CHILD_WIDGET]: getPropertiesPanelByViewType(viewType),
  [WIDGET_TYPES.STANDARD_WIDGET]: getPropertiesPanelByViewType(viewType),
  [WIDGET_TYPES.SIMILAR_WIDGET]: getPropertiesPanelByViewType(viewType),
});

export { getPropertiesPanelByWidgetType };
