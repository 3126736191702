import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../constants/actionBuilder.constants';

const ACTION_BUILDER_FORM_CONTEXT_ID = 'ACTION_BUILDER_FORM';

const INITIAL_STATE = {
  formValues: {
    [ACTION_DEFINITION_FIELD_IDS.ENTRY_CONDITION]: {
      [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: [EMPTY_OBJECT],
      [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: '1',
    },
    [ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION]: {
      [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: [EMPTY_OBJECT],
    },
    [ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS]: {
      [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: [EMPTY_OBJECT],
      [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: '1',
    },
    [ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS]: [EMPTY_OBJECT],
  },
};

const ACTION_BUILDER_ACTION_TYPES = {
  CREATE_RECORD: 'CREATE_RECORD',
  UPDATE_RECORD: 'UPDATE_RECORD',
  UPDATE_RECORDS: 'UPDATE_RECORDS',
  SEND_EMAIL: 'EMAIL',
  SEND_EMAILS: 'BULK_EMAIL',
  GET_RECORDS: 'GET_RECORDS',
  DELETE_RECORD: 'DELETE_RECORD',
  WORKFLOW_TRIGGER: 'WORKFLOW_TRIGGER',
  UPSERT_RECORD: 'UPSERT_RECORD',
};

const ACTION_BUILDER_ACTION_TYPE_OPTIONS = [
  {
    label: __('Create Record'),
    value: ACTION_BUILDER_ACTION_TYPES.CREATE_RECORD,
  },
  {
    label: __('Update Record'),
    value: ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD,
  },
  {
    label: __('Update Records'),
    value: ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORDS,
  },
  {
    label: __('Delete Record'),
    value: ACTION_BUILDER_ACTION_TYPES.DELETE_RECORD,
  },
  {
    label: __('Bulk Email'),
    value: ACTION_BUILDER_ACTION_TYPES.SEND_EMAILS,
  },
  {
    label: __('Trigger Workflow'),
    value: ACTION_BUILDER_ACTION_TYPES.WORKFLOW_TRIGGER,
  },
];

const ACTION_DEFINITION_OPERATOR_OPTIONS = [
  {
    label: __('Set'),
    value: 'SET',
  },
];

const USER_INPUT_TYPES = {
  FORM: 'FORM',
  FIELD: 'FIELD',
};

const USER_INPUT_TYPE_OPTIONS = [
  {
    label: __('Form'),
    value: USER_INPUT_TYPES.FORM,
  },
  {
    label: __('Select Fields'),
    value: USER_INPUT_TYPES.FIELD,
  },
];

const UPDATE_RECORD_VALUES = {
  SAME: true,
  OTHER: false,
};

const UPDATE_RECORD_OPTIONS = [
  {
    label: __('Same'),
    value: UPDATE_RECORD_VALUES.SAME,
  },
  {
    label: __('Other'),
    value: UPDATE_RECORD_VALUES.OTHER,
  },
];

const DEFAULT_ACTION_LEVEL = 'ENTITY';

const ACTION_DEFINITION_CONDITION_VARIABLE_NAME = '$target';

const FILTER_CONDITION_VARIABLE_NAME = '$filter';

export {
  ACTION_BUILDER_FORM_CONTEXT_ID,
  INITIAL_STATE,
  ACTION_BUILDER_ACTION_TYPES,
  ACTION_BUILDER_ACTION_TYPE_OPTIONS,
  ACTION_DEFINITION_OPERATOR_OPTIONS,
  USER_INPUT_TYPES,
  USER_INPUT_TYPE_OPTIONS,
  UPDATE_RECORD_VALUES,
  UPDATE_RECORD_OPTIONS,
  DEFAULT_ACTION_LEVEL,
  ACTION_DEFINITION_CONDITION_VARIABLE_NAME,
  FILTER_CONDITION_VARIABLE_NAME,
};
