const FIELD_IDS = {
  RELATED_ENTITY: 'relatedEntity',
  RELATED_FIELD: 'relatedField',
  VIEW_NAME: 'viewName',
};

const FIELD_LABELS = {
  [FIELD_IDS.RELATED_ENTITY]: __('Related Entity'),
  [FIELD_IDS.RELATED_FIELD]: __('Related Field'),
  [FIELD_IDS.VIEW_NAME]: __('View'),
};

const ENTITY_RELATION_META_DATA_KEYS = {
  ENTITY_RELATION_META_DATA_LIST: 'entityRelationMetadataList',
  RELATED_ENTITY_NAME: 'relatedEntityName',
  RELATED_FIELD: 'relatedField',
};

const LIST_VIEW_CONFIG_PROPERTY_KEYS = {
  EXPANDABLE_ROWS_ENABLED: 'expandableRowsEnabled',
  VIEW_RECORD_ACTION_CONFIGS: 'viewRecordActionConfigs',
  VIEW_RECORD_ACTION_TYPE: 'viewRecordActionType',
  ON_CLICK_ACTION_TYPE: 'onClickActionType',
  RELATED_VIEW_PROPS: 'relatedViewProps',
};

const VIEW_RECORD_ACTION_TYPES = {
  CELL_CLICK: 'CELL_CLICK',
};
const ON_CLICK_ACTION_TYPES = {
  EXPAND: 'EXPAND',
};

const ERROR_MESSAGES = {
  [FIELD_IDS.RELATED_ENTITY]: __('Related Entity is Required'),
  [FIELD_IDS.RELATED_FIELD]: __('Related Field is Required'),
  [FIELD_IDS.VIEW_NAME]: __('View is Required'),
};

const ROWS = 20;

export {
  FIELD_IDS,
  FIELD_LABELS,
  ENTITY_RELATION_META_DATA_KEYS,
  LIST_VIEW_CONFIG_PROPERTY_KEYS,
  VIEW_RECORD_ACTION_TYPES,
  ON_CLICK_ACTION_TYPES,
  ERROR_MESSAGES,
  ROWS,
};
