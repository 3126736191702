import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { NOTIFICATION_FIELD_IDS, NOTIFICATION_STATUS_TYPES } from '../../../../../../../../../constants/notification.constants';

const ROWS = 15;

const generatePayload = (showUnreadOnly, groupKey, nextPageToken = null) => {
  const filters = [];

  if (showUnreadOnly) {
    filters.push({
      field: NOTIFICATION_FIELD_IDS.STATUS,
      filterType: OPERATORS.IN,
      values: [NOTIFICATION_STATUS_TYPES.NEW, NOTIFICATION_STATUS_TYPES.SEEN],
    });
  } else {
    filters.push({
      field: NOTIFICATION_FIELD_IDS.STATUS,
      filterType: OPERATORS.IN,
      values: [NOTIFICATION_STATUS_TYPES.NEW, NOTIFICATION_STATUS_TYPES.SEEN, NOTIFICATION_STATUS_TYPES.READ],
    });
  }

  if (groupKey) {
    filters.push({
      field: NOTIFICATION_FIELD_IDS.GROUP_KEY,
      filterType: OPERATORS.IN,
      values: [groupKey],
    });
  }

  return { filters, nextPageToken, rows: ROWS };
};

export { generatePayload };
