import _reduce from 'lodash/reduce';
import _concat from 'lodash/concat';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _get from 'lodash/get';

import { tget } from '@tekion/tekion-base/utils/general';
import sortByKey from '@tekion/tekion-base/utils/sortByKey';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { convertMsToTimeInputFormat } from '../../../../utils/convertTimeUnits';
import DELAY_TASK_MODAL_FIELD_IDS from '../constants/delayTaskModal.fieldIds';
import { DELAY_TASK_TABS, DELAY_TASK_TAB_IDS } from '../constants/delayTaskModal.tabs';
import { SORT_KEYS } from '../constants/delayTaskModal.constants';
import { MINUTES } from '../../../../constants/workflow.constants';

const getEventTabs = (eventList, type) =>
  _reduce(
    eventList,
    (tabsList, event) => {
      const id = tget(event, DELAY_TASK_MODAL_FIELD_IDS.ID);
      const eventType = tget(event, DELAY_TASK_MODAL_FIELD_IDS.EVENT_TYPE);
      const displayName = tget(event, `${DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME}`);
      const condition = tget(event, `userData.data.${DELAY_TASK_MODAL_FIELD_IDS.CONDITION}`);
      const entityName = tget(event, DELAY_TASK_MODAL_FIELD_IDS.ENTITY_NAME);
      const workflowId = tget(event, DELAY_TASK_MODAL_FIELD_IDS.WORKFLOW);

      return _concat(tabsList, {
        id,
        displayName,
        values: {
          [DELAY_TASK_MODAL_FIELD_IDS.DISPLAY_NAME]: displayName,
          [DELAY_TASK_MODAL_FIELD_IDS.EVENT_TYPE]: eventType,
          [DELAY_TASK_MODAL_FIELD_IDS.ENTITY_NAME]: entityName,
          [DELAY_TASK_MODAL_FIELD_IDS.CONDITION]: condition,
          [DELAY_TASK_MODAL_FIELD_IDS.WORKFLOW]: workflowId,
        },
        errors: {},
        type,
      });
    },
    [],
  );

const generateTabs = (userData, uiMetadata) => {
  const executionDelay = convertMsToTimeInputFormat(tget(userData, DELAY_TASK_MODAL_FIELD_IDS.EXECUTION_DELAY, 0), [
    _get(uiMetadata, 'delayTime.unit', MINUTES),
  ]);
  const expectedEventTypes = tget(userData, DELAY_TASK_MODAL_FIELD_IDS.EXPECTED_EVENT_TYPE, []);
  const exitEventTypes = tget(userData, DELAY_TASK_MODAL_FIELD_IDS.EXIT_EVENT_TYPE, []);

  const timeDelayTab = { ...DELAY_TASK_TABS.TIME_DELAY };
  _set(timeDelayTab, 'values', { [DELAY_TASK_MODAL_FIELD_IDS.EXECUTION_DELAY]: executionDelay });

  const expectedEventTypeTabs = getEventTabs(expectedEventTypes, DELAY_TASK_TAB_IDS.ADD_DELAY_EXIT_EVENT);
  const exitEventTypesTabs = getEventTabs(exitEventTypes, DELAY_TASK_TAB_IDS.ADD_WORKFLOW_EXIT_EVENT);

  return [
    timeDelayTab,
    DELAY_TASK_TABS.ADD_DELAY_EXIT_EVENT,
    ...expectedEventTypeTabs,
    DELAY_TASK_TABS.ADD_WORKFLOW_EXIT_EVENT,
    ...exitEventTypesTabs,
  ];
};

/*
{
  "body___executionDelay": 600000,
  "body___expectedEventType": [
      {
          "id": "931a4bfe-66f4-45db-8a2a-93a7c30658a5",
          "uiMetadata": {
              "optionsByConditionTypes": {}
          },
          "eventType": "LEAD_UPDATED",
          "userData": {
              "data": {
                  "comparisons": [
                      {
                          "lhs": {
                              "type": "K",
                              "value": "lead.appointmentsInfo.appointmentsSet"
                          },
                          "rhs": {
                              "type": "C",
                              "value": "EXIST"
                          },
                          "operator": "EXISTS",
                          "uiMetadata": {
                              "operatorId": "EXISTS_BOOL",
                              "isDisabled": false,
                              "id": "lead.appointmentsInfo.appointmentsSet",
                              "assetValueType": "LIST"
                          }
                      }
                  ],
                  "joinOperator": "AND"
              }
          },
          "workflowId": null
      }
  ],
  "body___exitEventType": [
      {
          "id": "687a6542-779f-4bf5-bc12-bc043d1fead7",
          "uiMetadata": {
              "optionsByConditionTypes": {}
          },
          "eventType": "APPOINTMENT_CONFIRMED",
          "workflowId": "626fa6c059080100070f2b1d",
          "userData": {
              "data": {
                  "comparisons": [
                      {
                          "lhs": {
                              "type": "K",
                              "value": "lead.appointmentsInfo.appointmentsSet"
                          },
                          "rhs": {
                              "type": "C",
                              "value": "NOT_EXIST"
                          },
                          "operator": "EXISTS",
                          "uiMetadata": {
                              "operatorId": "EXISTS_BOOL",
                              "isDisabled": false,
                              "id": "lead.appointmentsInfo.appointmentsSet",
                              "assetValueType": "LIST"
                          }
                      }
                  ],
                  "joinOperator": "AND"
              }
          }
      }
  ]
}
*/

const getTimeUnitOptions = (optArray, sort = false) => {
  const options = _map(optArray, (option) => ({
    label: _get(option, 'displayName', EMPTY_STRING),
    value: _get(option, 'id'),
    ...option,
  }));

  return sort ? sortByKey(options, SORT_KEYS.LABEL) : options;
};

export { generateTabs, getTimeUnitOptions };
