import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import { FIELD_IDS } from './cronExpression.constants';

import styles from './cronExpression.module.scss';

const CronExpression = ({ id, value, onAction }) => {
  const secondValue = _get(value, FIELD_IDS.SECONDS, EMPTY_STRING);
  const minuteValue = _get(value, FIELD_IDS.MINUTES, EMPTY_STRING);
  const hourValue = _get(value, FIELD_IDS.HOURS, EMPTY_STRING);
  const dayOfMonthValue = _get(value, FIELD_IDS.DAY_OF_MONTH, EMPTY_STRING);
  const monthValue = _get(value, FIELD_IDS.MONTH, EMPTY_STRING);
  const dayOfWeekValue = _get(value, FIELD_IDS.DAY_OF_WEEK, EMPTY_STRING);
  const yearValue = _get(value, FIELD_IDS.YEAR, EMPTY_STRING);

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { id: fieldId, value: fieldValue } = payload;
          onAction({ type, payload: { id, value: { ...value, [fieldId]: fieldValue } } });
          break;
        }

        default: {
          onAction(action);
          break;
        }
      }
    },
    [id, value, onAction],
  );

  return (
    <div className={styles.flexRow}>
      <TextInput
        required
        id={FIELD_IDS.SECONDS}
        label={__('Seconds')}
        fieldClassName={fieldLayoutStyles.fieldC}
        value={secondValue}
        onAction={handleAction}
      />
      <TextInput
        required
        id={FIELD_IDS.MINUTES}
        label={__('Minutes')}
        fieldClassName={fieldLayoutStyles.fieldC}
        value={minuteValue}
        onAction={handleAction}
      />
      <TextInput
        required
        id={FIELD_IDS.HOURS}
        label={__('Hours')}
        fieldClassName={fieldLayoutStyles.fieldC}
        value={hourValue}
        onAction={handleAction}
      />
      <TextInput
        required
        id={FIELD_IDS.DAY_OF_MONTH}
        label={__('Day of Month')}
        fieldClassName={fieldLayoutStyles.fieldC}
        value={dayOfMonthValue}
        onAction={handleAction}
      />
      <TextInput
        required
        id={FIELD_IDS.MONTH}
        label={__('Month')}
        fieldClassName={fieldLayoutStyles.fieldC}
        value={monthValue}
        onAction={handleAction}
      />
      <TextInput
        required
        id={FIELD_IDS.DAY_OF_WEEK}
        label={__('Day of Week')}
        fieldClassName={fieldLayoutStyles.fieldC}
        value={dayOfWeekValue}
        onAction={handleAction}
      />
      <TextInput
        required
        id={FIELD_IDS.YEAR}
        label={__('Year')}
        fieldClassName={fieldLayoutStyles.fieldC}
        value={yearValue}
        onAction={handleAction}
      />
    </div>
  );
};

CronExpression.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

CronExpression.defaultProps = {
  value: EMPTY_OBJECT,
};

export default CronExpression;
