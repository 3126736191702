import React, { useCallback, useEffect, useState } from 'react';
import _isFunction from 'lodash/isFunction';

import { tget } from '@tekion/tekion-base/utils/general';

import pusherEventEmitter, { PUSHER_EVENT_EMITTER_EVENT_TYPES } from '../../../../eventEmitters/pusherEventEmitter';
import NotificationDrawer from './components/notificationDrawer/NotificationDrawer';
import NotificationBell from './components/notificationBell';

// Actions
import communicationsActions from '../../../../actions/communications.actions';

// Constants
import { NOTIFICATION_FIELD_IDS, NOTIFICATION_TYPES } from '../../../../constants/notification.constants';

// Handlers
import PUSH_NOTIFICATION_HANDLERS, { defaultPushNotificationHandler } from './pushNotificationHandlers';

const Notifications = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState();

  const loadUnreadCount = useCallback(async () => {
    const _unreadCount = await communicationsActions.fetchUnreadCount();
    setUnreadCount(_unreadCount);
  }, []);

  const handleNewNotification = useCallback(
    (notificationEvent) => {
      const notificationType = tget(notificationEvent, NOTIFICATION_FIELD_IDS.TYPE);

      if (notificationType === NOTIFICATION_TYPES.NUDGE) {
        const eventType = tget(notificationEvent, NOTIFICATION_FIELD_IDS.EVENT_TYPE);
        const handler = PUSH_NOTIFICATION_HANDLERS[eventType];

        if (_isFunction(handler)) {
          handler(notificationEvent);
        } else {
          defaultPushNotificationHandler(notificationEvent);
        }
      }
      loadUnreadCount();
      // TODO: Add alert handling logic
    },
    [loadUnreadCount],
  );

  const toggleDrawer = useCallback(() => setIsDrawerOpen((_isDrawerOpen) => !_isDrawerOpen), []);

  useEffect(() => {
    pusherEventEmitter.on(PUSHER_EVENT_EMITTER_EVENT_TYPES.NEW_NOTIFICATION, handleNewNotification);

    return () => pusherEventEmitter.removeListener(PUSHER_EVENT_EMITTER_EVENT_TYPES.NEW_NOTIFICATION, handleNewNotification);
  }, [handleNewNotification]);

  useEffect(() => {
    loadUnreadCount();
  }, [loadUnreadCount]);

  return (
    <>
      <NotificationBell unSeenCount={unreadCount} onClick={toggleDrawer} />
      {isDrawerOpen && <NotificationDrawer onClose={toggleDrawer} />}
    </>
  );
};

export default Notifications;
