const COLUMN_IDS = {
  DRAG_ICON: 'dragIcon',
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  SORTABLE: 'sortable',
  SEARCHABLE: 'searchable',
  FILTERABLE: 'filterable',
  DEFAULT_FILTER: 'defaultFilter',
  MANDATORY: 'mandatory',
  EDITABLE: 'editable',
  DISABLED: 'disabled',
};

const TABLE_COLUMNS = [
  COLUMN_IDS.NAME,
  COLUMN_IDS.DISPLAY_NAME,
  COLUMN_IDS.DRAG_ICON,
  COLUMN_IDS.SORTABLE,
  COLUMN_IDS.SEARCHABLE,
  COLUMN_IDS.FILTERABLE,
  COLUMN_IDS.DEFAULT_FILTER,
  COLUMN_IDS.MANDATORY,
  COLUMN_IDS.EDITABLE,
  COLUMN_IDS.DISABLED,
];

export { COLUMN_IDS, TABLE_COLUMNS };
