import withVoidSelection from './overrides/withVoidSelection';

/**
 * This is a fix for void selection issue in Slate js. Currently if a void element is selected, user cannot enter a new element below it.
 * For reference https://github.com/ianstormtaylor/slate/issues/3991
 */
const createVoidSelectionPlugin = () => ({
  withOverrides: withVoidSelection(),
});

export default createVoidSelectionPlugin;
