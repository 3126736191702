import { TASK_DEF_IDS } from '../../../constants/workflow.constants';

const DEFAULT_ICON = 'icon-skills';
const DEFAULT_COLOR = '#ff7b32';
const DEFAULT_BACKGROUND_COLOR = 'ffa1664d';

const DEFAULT_STYLE_PROPERTIES = {
  icon: DEFAULT_ICON,
  color: DEFAULT_COLOR,
  backgroundColor: DEFAULT_BACKGROUND_COLOR,
};

const HIDDEN_NODES_TASK_DEFS = [TASK_DEF_IDS.LOOP_CONTINUE, TASK_DEF_IDS.LOOP_EXIT];

export { DEFAULT_ICON, DEFAULT_COLOR, DEFAULT_BACKGROUND_COLOR, DEFAULT_STYLE_PROPERTIES, HIDDEN_NODES_TASK_DEFS };
