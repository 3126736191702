import _map from 'lodash/map';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import { TAB_PROPERTIES_CONFIGURE_FIELD_IDS } from '../constants/tabPropertiesConfigurator.fieldIds';
import { TAB_TEMPLATE_TYPE, TAB_TEMPLATE_TYPE_TO_DISPLAY_NAME } from '../../../../../constants/visualBuilder.properties.constants';
import { TAB_POSITION_OPTIONS, TAB_SIZE_OPTIONS } from '../constants/tabPropertiesConfigurator.general.constants';

const TAB_POSITION_FIELD = {
  id: TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_POSITION,
  renderer: SelectInput,
  renderOptions: {
    required: true,
    label: __('Tab Position'),
    validators: [isRequiredRule],
    placeholder: __('Select Tab Position'),
    options: TAB_POSITION_OPTIONS,
  },
};

const TAB_SIZE_FIELD = {
  id: TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_SIZE,
  renderer: SelectInput,
  renderOptions: {
    required: true,
    label: __('Tab Size'),
    validators: [isRequiredRule],
    placeholder: __('Select Tab Size'),
    options: TAB_SIZE_OPTIONS,
  },
};

const TAB_TYPE_OPTIONS = _map(TAB_TEMPLATE_TYPE, (templateType) => ({ label: TAB_TEMPLATE_TYPE_TO_DISPLAY_NAME[templateType], value: templateType }));

const TAB_TEMPLATE_TYPE_FIELD = {
  id: TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_TEMPLATE_TYPE,
  renderer: SelectInput,
  renderOptions: {
    required: true,
    label: __('Tab template'),
    validators: [isRequiredRule],
    placeholder: __('Select Tab Template'),
    options: TAB_TYPE_OPTIONS,
  },
};

// const VIEW_FIELD = {
//   id: ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW,
//   renderer: SelectInput,
//   renderOptions: {
//     label: __('View Name'),
//     validators: [isRequiredRule],
//     placeholder: __('Enter Widget Name'),
//     // serviceHandler: searchViewConfigurations,
//   },
// };

const TAB_PROPERTIES_CONFIGURE_FIELDS = {
  [TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_POSITION]: TAB_POSITION_FIELD,
  [TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_SIZE]: TAB_SIZE_FIELD,
  [TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_TEMPLATE_TYPE]: TAB_TEMPLATE_TYPE_FIELD,
};

export { TAB_PROPERTIES_CONFIGURE_FIELDS };
