import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { FORM_MODES } from '../../../../../../constants/general.constants';
import ROUTES from '../../../../constants/routes';
import ACTION_TYPES from '../constants/permissionSetForm.actionTypes';
import { createPermissionSet, editPermissionByName, getPermissionByName } from '../../../../../../actions/permissionSetManagement.action';

const handleFormInit = async ({ getState, setState }) => {
  const { match = EMPTY_OBJECT, history } = getState();
  const { permissionName } = _get(match, 'params', EMPTY_OBJECT);

  if (!_isEmpty(permissionName)) {
    setState({ formMode: FORM_MODES.EDIT, isDataLoading: true });
    let permissionData = {};
    if (!_has(history, 'location.state.permissionData')) {
      permissionData = await getPermissionByName(permissionName);
    } else {
      permissionData = _get(history, 'location.state.permissionData', EMPTY_OBJECT);
    }
    setState({ isDataLoading: false, permissionData });
  } else {
    setState({ formMode: FORM_MODES.CREATE });
  }
};

const handleFieldChange = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { id, value } = params;
  const { permissionData } = getState();
  const updatedValues = { ...permissionData, [id]: value };
  setState({ permissionData: updatedValues });
};

const handleErrors = ({ params = EMPTY_OBJECT, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const handleSubmit = async ({ getState, setState }) => {
  const { formMode, permissionData, history } = getState();
  setState({ isSubmitting: true });

  if (formMode === FORM_MODES.CREATE) {
    await createPermissionSet({ ...permissionData, fieldPermission: {}, entityPermission: {} });
  } else {
    const { match } = getState();
    const permissionName = _get(match, 'params.permissionName');
    await editPermissionByName(permissionName, permissionData);
  }
  setState({ isSubmitting: false });
  history.push(ROUTES.PERMISSION_SET_LIST_ROUTE);
};

const handleCancel = ({ getState }) => {
  const { history } = getState();
  history.push(ROUTES.PERMISSION_SET_LIST_ROUTE);
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleFormInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [ACTION_TYPES.ON_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL]: handleCancel,
};

export default ACTION_HANDLERS;
