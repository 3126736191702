import React from 'react';
import PropTypes from 'prop-types';

import _castArray from 'lodash/castArray';
import _noop from 'lodash/noop';

// Utils
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

// Components
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';

// Constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

const AttributeSelect = ({ keepAttributeAsArray, value, onAction, ...restProps }) => {
  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload = EMPTY_OBJECT } = action;
    if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
      const { value: _value } = payload;

      if (keepAttributeAsArray) {
        onAction(action);
      } else {
        onAction({
          type,
          payload: {
            ...payload,
            value: getArraySafeValue(_value),
          },
        });
      }
    } else {
      onAction(action);
    }
  };

  return <Select {...restProps} value={_castArray(value)} onAction={handleAction} />;
};

AttributeSelect.propTypes = {
  keepAttributeAsArray: PropTypes.bool,
  value: PropTypes.string,
  onAction: PropTypes.func,
};

AttributeSelect.defaultProps = {
  keepAttributeAsArray: false,
  value: '',
  onAction: _noop,
};

export default AttributeSelect;
