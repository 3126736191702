import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import { FIELD_IDS } from './switchWorkflowModal.constants';

import style from './switchWorkflowModal.module.scss';

const FORM_SECTIONS = [
  {
    rows: [
      {
        columns: [FIELD_IDS.WORKFLOW],
        className: style.sectionContainer,
      },
    ],
  },
];

const getFieldConfig = (workflowOptions) => ({
  [FIELD_IDS.WORKFLOW]: {
    id: FIELD_IDS.WORKFLOW,
    renderer: Select,
    renderOptions: {
      required: true,
      label: __('Workflow'),
      options: workflowOptions,
      placeholder: __('Select Workflow'),
      validator: [isRequiredRule],
    },
  },
});

export { FORM_SECTIONS, getFieldConfig };
