import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _nth from 'lodash/nth';
import _size from 'lodash/size';
import _last from 'lodash/last';
import _get from 'lodash/get';
import _includes from 'lodash/includes';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Tekion-components
// import Input from '@tekion/tekion-components/molecules/Input';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Button from '@tekion/tekion-components/atoms/Button';

// Components
import WithSize from '../../connectors/withSize';
import Tree from '../Tree';

// Constants
import STUDIO_ROUTES from '../../pages/devPlatform/constants/routes';
import { TENANT_UNIVERSE_BASE_ROUTE, STUDIO_ROUTE } from '../../constants/routes';
import PAGE_IDS from '../../pages/devPlatform/constants/PageIds.constants';
import {
  PROJECT_TREE_DATA,
  APP_TREE_DATA,
  ENTITY_TREE_DATA,
  GOVERNANCE_TREE_DATA,
  PERMISSION_TREE_DATA,
  TENANT_WORKSPACE_TREE_DATA,
  TENANT_GOVERNANCE_TREE_DATA,
  WORKFLOW_BUILDER_TREE_DATA,
  SIDEBAR_VISIBLE_PAGE_IDS,
  ROLES_TREE_DATA,
  RECORD_GROUPINGS_TREE_DATA,
  WWD_TREE_DATA,
  REPORTING_TREE_DATA,
  TEMPLATES_TREE_DATA,
  JOB_SCHEDULER_TREE_DATA,
  VISUAL_BUILDER_TREE_DATA,
  APPROVAL_TREE_DATA,
  METADATA_TREE_DATA,
  SERVER_CREDENTIALS_TREE_DATA,
  SUBSCRIPTION_CONFIG_TREE_DATA,
  TENANT_LOGIN_CONFIG_TREE_DATA,
  GLOBAL_SELECT_LIST_TREE_DATA,
  IMPORT_DATA_TREE_DATA,
} from './sideBar.constants';
import { VIEW_BUILDER_LIST_TAB_IDS } from '../../pages/devPlatform/pages/viewBuilderList/constants/viewBuilderList.general';
import { WORKFLOW_BUILDER_MODE } from '../../constants/general.constants';

// Styles
import styles from './sideBar.module.scss';

// TO Do: Later Implement search logic
// const onSearchValueChange = () => {};

const SideBar = (props) => {
  const { history, sanitizedPathName, contentHeight } = props;

  const [isExpanded, setIsExpanded] = useState(true);
  const pathName = _get(history, 'location.pathname');
  const isTenantUniversePath = _includes(pathName, TENANT_UNIVERSE_BASE_ROUTE);

  let isSideBarShown = useMemo(
    () =>
      pathName === STUDIO_ROUTE ||
      pathName === TENANT_UNIVERSE_BASE_ROUTE ||
      _includes(SIDEBAR_VISIBLE_PAGE_IDS, _last(sanitizedPathName)) ||
      _includes(pathName, PAGE_IDS.PROJECT_APPLICATIONS) ||
      _includes(pathName, PAGE_IDS.PERMISSION_SET_CONFIGURE) ||
      _includes(pathName, PAGE_IDS.REPORTEE) ||
      _includes(pathName, `/${PAGE_IDS.VIEW_BUILDER}/${VIEW_BUILDER_LIST_TAB_IDS.RECORD_TYPE_VIEWS}`) ||
      _includes(pathName, `/${PAGE_IDS.VIEW_BUILDER}/${VIEW_BUILDER_LIST_TAB_IDS.ENTITY_VIEWS}`) ||
      _includes(pathName, PAGE_IDS.APPROVAL_SETTING_DETAIL) ||
      _includes(pathName, PAGE_IDS.ADD_METADATA_COMPONENT) ||
      _includes(pathName, `/${PAGE_IDS.AUTH_PROVIDERS_LIST}`) ||
      _includes(pathName, `/${PAGE_IDS.EXTERNAL_CREDENTIALS_LIST}`) ||
      _includes(pathName, `/${PAGE_IDS.SERVER_CREDENTIALS_LIST}`) ||
      _includes(pathName, `/${PAGE_IDS.WORKFLOW_BUILDER}/${WORKFLOW_BUILDER_MODE.PROCESS}`) ||
      _includes(pathName, `/${PAGE_IDS.WORKFLOW_BUILDER}/${WORKFLOW_BUILDER_MODE.RULE}`) ||
      _includes(pathName, `/${PAGE_IDS.DATA_IMPORTS}`),

    [pathName, sanitizedPathName],
  );

  const toggleExpand = useCallback(() => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  }, []);

  const isProjectTreeActive = _includes(pathName, PAGE_IDS.PROJECTS) || _includes(pathName, PAGE_IDS.PROJECT_APPLICATIONS);
  const isApplicationTreeActive = _includes(pathName, PAGE_IDS.APPLICATIONS) && !isProjectTreeActive;
  const isEntityTreeActive = _includes(pathName, PAGE_IDS.ENTITIES);
  const isPermissionSetTreeActive = _includes(pathName, PAGE_IDS.PERMISSION_SET);
  const isWorkflowBuilderTreeActive = _includes(pathName, PAGE_IDS.WORKFLOW_BUILDER);
  const isGovernanceTreeActive = (_includes(pathName, PAGE_IDS.USERS) || _includes(pathName, PAGE_IDS.USER_GROUPS)) && !isPermissionSetTreeActive;
  const isMetadataTreeActive = _includes(pathName, PAGE_IDS.EXPORTS);
  const isTenantLoginConfigActive = _includes(pathName, PAGE_IDS.LOGIN_CONFIGURATIONS);
  const isTenantWorkspaceActive = _last(sanitizedPathName) === PAGE_IDS.WORKSPACES;

  const isRolesTreeActive = _includes(pathName, PAGE_IDS.REPORTEE);
  const isRecordGroupingsTreeActive = _includes(pathName, PAGE_IDS.ENTITY_RECORD_GROUP_LIST);
  const isWwdTreeActive = _includes(pathName, PAGE_IDS.WWD_PERMISSIONS);
  const isReportingTreeActive = _includes(pathName, PAGE_IDS.DASHBOARDS);
  const isJobSchedulerActive = _includes(pathName, PAGE_IDS.JOBS);
  const isVisualBuilderTreeActive = _includes(pathName, PAGE_IDS.VISUAL_BUILDER);
  const isServerCredentialTreeActive = _includes(pathName, PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE);
  const isDataImportTreeActive = _includes(pathName, PAGE_IDS.IMPORT_DATA_STUDIO);

  const isTemplatesTreeActive = _includes(pathName, PAGE_IDS.TEMPLATE_BUILDER);
  const isApprovalBuilderTreeActive = _includes(pathName, PAGE_IDS.APPROVAL_STUDIO);
  const isSubscriptionConfigTreeActive = _includes(pathName, PAGE_IDS.SUBSCRIPTION_CONFIGS);
  const isGlobalSelectListTreeActive = _includes(pathName, PAGE_IDS.GLOBAL_SELECT_LIST);

  let entityChildActiveLevel = 0;
  let entitySiblingNumber = 0;
  let selectedEntityName = EMPTY_STRING;
  let selectedProjectName = EMPTY_STRING;
  let governanceSiblingNumber = 0;
  let selectedPermissionSetName = EMPTY_STRING;
  let selectedRolesName = EMPTY_STRING;
  let approvalSiblingNumber = 0;
  let metadataSiblingNumber = 0;
  let serverCredentialConfigureSiblingNumber = 0;

  if (isProjectTreeActive && _size(sanitizedPathName) > 3) {
    selectedProjectName = _get(history, 'location.state.projectData.displayName', _last(sanitizedPathName));
  }

  if (isPermissionSetTreeActive && _size(sanitizedPathName) > 3) {
    selectedPermissionSetName = _get(history, 'location.state.permissionData.name', _nth(sanitizedPathName, 3));
  }

  if (isEntityTreeActive && _size(sanitizedPathName) > 3) {
    selectedEntityName = _nth(sanitizedPathName, 3);
    entityChildActiveLevel = _includes(pathName, PAGE_IDS.FIELDS) ? 1 : 2;
    if (entityChildActiveLevel === 2) {
      if (_includes(pathName, PAGE_IDS.VALIDATION_RULE_BUILDER)) {
        entitySiblingNumber = 1;
      } else if (_includes(pathName, PAGE_IDS.VIEW_BUILDER)) {
        entitySiblingNumber = 2;
      } else if (_includes(pathName, PAGE_IDS.ACTION_BUILDER)) {
        entitySiblingNumber = 3;
      } else if (_includes(pathName, PAGE_IDS.RECORD_SHARING_RULES)) {
        entitySiblingNumber = 4;
      } else if (_includes(pathName, PAGE_IDS.RECORD_TYPE)) {
        entitySiblingNumber = 5;
      }
    } else {
      entitySiblingNumber = 1;
    }
  }

  if (isApprovalBuilderTreeActive && _size(sanitizedPathName) > 3) {
    if (_includes(pathName, PAGE_IDS.APPROVAL_GROUPS)) {
      approvalSiblingNumber = 1;
    } else if (_includes(pathName, PAGE_IDS.APPROVAL_CATEGORIES)) {
      approvalSiblingNumber = 2;
    } else if (_includes(pathName, PAGE_IDS.APPROVAL_SETTINGS)) {
      approvalSiblingNumber = 3;
    } else if (_includes(pathName, PAGE_IDS.APPROVAL_SETTING_DETAIL)) {
      approvalSiblingNumber = 3;
    } else if (_includes(pathName, PAGE_IDS.APPROVAL_PROCESSES)) {
      approvalSiblingNumber = 4;
    }
  }

  if (isGovernanceTreeActive) {
    if (_includes(pathName, PAGE_IDS.USER_GROUPS)) {
      governanceSiblingNumber = 1;
    } else if (_includes(pathName, PAGE_IDS.USERS)) {
      governanceSiblingNumber = 2;
    } else {
      governanceSiblingNumber = 3;
    }
  }

  if (isMetadataTreeActive) {
    if (_includes(pathName, PAGE_IDS.EXPORTS)) {
      metadataSiblingNumber = 1;
    } else {
      metadataSiblingNumber = 2;
    }
  }

  if (isServerCredentialTreeActive) {
    if (_includes(pathName, PAGE_IDS.AUTH_PROVIDERS_LIST)) {
      serverCredentialConfigureSiblingNumber = 1;
    } else if (_includes(pathName, PAGE_IDS.EXTERNAL_CREDENTIALS_LIST)) {
      serverCredentialConfigureSiblingNumber = 2;
    } else {
      serverCredentialConfigureSiblingNumber = 3;
    }
  }

  if (isRolesTreeActive && _size(sanitizedPathName) > 3) {
    selectedRolesName = _get(history, 'location.state.selectedRoleData.name', _last(sanitizedPathName));
  }

  const handleItemClick = useCallback(
    (path, state) => {
      history.push({ pathname: path, state });
    },
    [history],
  );

  const entityTreeData = useMemo(() => {
    const newTreeEntityData = [...ENTITY_TREE_DATA];
    if (_isEmpty(selectedEntityName)) {
      return newTreeEntityData;
    }
    newTreeEntityData[0] = {
      ...newTreeEntityData[0],
      children: [
        {
          label: __(selectedEntityName),
          key: selectedEntityName,
          goTo: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${selectedEntityName}/${PAGE_IDS.FIELDS}`,
          children: [
            {
              label: __('Validation Rule Builder'),
              key: 'validation-rule-builder',
              goTo: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${selectedEntityName}/${PAGE_IDS.VALIDATION_RULE_BUILDER}`,
            },
            {
              label: __('View Builder'),
              key: 'view-builder',
              // eslint-disable-next-line max-len
              goTo: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${selectedEntityName}/${PAGE_IDS.VIEW_BUILDER}/${VIEW_BUILDER_LIST_TAB_IDS.ENTITY_VIEWS}`,
            },
            {
              label: __('Action Builder'),
              key: 'action-builder',
              goTo: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${selectedEntityName}/${PAGE_IDS.ACTION_BUILDER}`,
            },
            {
              label: __('Record Sharing Rules'),
              key: 'record-sharing-rules',
              goTo: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${selectedEntityName}/${PAGE_IDS.RECORD_SHARING_RULES}`,
            },
            {
              label: __('Record Type'),
              key: 'record-type',
              goTo: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${selectedEntityName}/${PAGE_IDS.RECORD_TYPE}`,
            },
          ],
        },
      ],
    };
    return newTreeEntityData;
  }, [selectedEntityName]);

  const projectTreeData = useMemo(() => {
    const newProjectTreeData = [...PROJECT_TREE_DATA];
    if (_isEmpty(selectedProjectName)) {
      return newProjectTreeData;
    }
    newProjectTreeData[0] = {
      ...newProjectTreeData[0],
      children: [
        {
          label: __(selectedProjectName),
          key: selectedProjectName,
          goTo: `${STUDIO_ROUTE}/${PAGE_IDS.PROJECT_APPLICATIONS}/${_last(sanitizedPathName)}`,
        },
      ],
    };
    return newProjectTreeData;
  }, [selectedProjectName, sanitizedPathName]);

  const permissionTreeData = useMemo(() => {
    const newPermissionSetTreeData = [...PERMISSION_TREE_DATA];
    if (_isEmpty(selectedPermissionSetName)) {
      return newPermissionSetTreeData;
    }
    newPermissionSetTreeData[0] = {
      ...newPermissionSetTreeData[0],
      children: [
        {
          label: __(selectedPermissionSetName),
          key: selectedPermissionSetName,
          goTo: `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_CONFIGURE}/${_nth(sanitizedPathName, 3)}/${_last(sanitizedPathName)}`,
          state: _get(history, 'location.state'),
        },
      ],
    };
    return newPermissionSetTreeData;
  }, [selectedPermissionSetName, sanitizedPathName, history]);
  const roleTreeData = useMemo(() => {
    const newRolesTreeData = [...ROLES_TREE_DATA];
    if (_isEmpty(selectedRolesName)) {
      return newRolesTreeData;
    }
    newRolesTreeData[0] = {
      ...newRolesTreeData[0],
      children: [
        {
          label: __(selectedRolesName),
          key: selectedRolesName,
          goTo: `${STUDIO_ROUTE}/${PAGE_IDS.REPORTEE}/${PAGE_IDS.ROLE_CONFIGURE}/${_last(sanitizedPathName)}`,
        },
      ],
    };
    return newRolesTreeData;
  }, [selectedRolesName, sanitizedPathName]);

  const approvalTreeData = useMemo(() => {
    const _approvalTreeData = [...APPROVAL_TREE_DATA];

    const _approvalTreeChildren = [
      {
        label: __('Group'),
        key: 'approval-group',
        goTo: STUDIO_ROUTES.APPROVAL_GROUPS_ROUTE,
      },
      {
        label: __('Categories'),
        key: 'approval-categories',
        goTo: STUDIO_ROUTES.APPROVAL_CATEGORIES_ROUTE,
      },
      {
        label: __('Settings'),
        key: 'approval-settings',
        goTo: STUDIO_ROUTES.APPROVAL_SETTINGS_ROUTE,
      },
      {
        label: __('Processes'),
        key: 'approval-processes',
        goTo: STUDIO_ROUTES.APPROVAL_PROCESSES_ROUTE,
      },
    ];

    _approvalTreeData[0] = {
      ..._approvalTreeData[0],
      children: _approvalTreeChildren,
    };

    return _approvalTreeData;
  }, []);

  if (isPermissionSetTreeActive && _size(sanitizedPathName) > 5) {
    isSideBarShown = false;
  }

  if (isRolesTreeActive && (_includes(pathName, PAGE_IDS.REPORTEE_CREATE) || _includes(pathName, PAGE_IDS.REPORTEE_EDIT))) {
    isSideBarShown = false;
  }

  return (
    <PropertyControlledComponent controllerProperty={isSideBarShown}>
      <div style={{ height: contentHeight }} className={isExpanded ? styles.openContainer : styles.closeContainer}>
        {!isExpanded && (
          <Button view={Button.VIEW.ICON} className={styles.openButton} onClick={toggleExpand}>
            <FontIcon className={styles.icon} size={SIZES.MD}>
              icon-right-arrow-thin
            </FontIcon>
          </Button>
        )}
        <PropertyControlledComponent controllerProperty={isExpanded}>
          {/* <div className={styles.header}>
            <Input
              className={styles.search}
              onChange={onSearchValueChange}
              placeholder={__('Quick Search')}
              prefix={<FontIcon>icon-search</FontIcon>}
            />
            <Button view={Button.VIEW.ICON} className={styles.closeButton} onClick={toggleExpand}>
              <FontIcon className={styles.icon} size={SIZES.MD}>
                icon-left-arrow-thin
              </FontIcon>
            </Button>
          </div> */}
          <PropertyControlledComponent controllerProperty={!isTenantUniversePath}>
            <Tree
              treeData={projectTreeData}
              onItemClick={handleItemClick}
              isActive={isProjectTreeActive}
              showChildren={!_isEmpty(selectedProjectName)}
              activeDefaultLevel={1}
              activeSiblingNumber={1}
            />
            <Tree treeData={APP_TREE_DATA} onItemClick={handleItemClick} isActive={isApplicationTreeActive} />
            <Tree
              treeData={entityTreeData}
              showChildren={!_isEmpty(selectedEntityName)}
              onItemClick={handleItemClick}
              isActive={isEntityTreeActive}
              activeDefaultLevel={entityChildActiveLevel}
              activeSiblingNumber={entitySiblingNumber}
            />
            <Tree treeData={WORKFLOW_BUILDER_TREE_DATA} onItemClick={handleItemClick} isActive={isWorkflowBuilderTreeActive} />
            <Tree treeData={VISUAL_BUILDER_TREE_DATA} onItemClick={handleItemClick} isActive={isVisualBuilderTreeActive} />
            <Tree treeData={RECORD_GROUPINGS_TREE_DATA} onItemClick={handleItemClick} isActive={isRecordGroupingsTreeActive} />
            <Tree
              treeData={approvalTreeData}
              showChildren={isApprovalBuilderTreeActive}
              isActive={isApprovalBuilderTreeActive}
              activeDefaultLevel={1}
              activeSiblingNumber={approvalSiblingNumber}
              onItemClick={handleItemClick}
            />
            <Tree
              isActive={isRolesTreeActive}
              showChildren={!_isEmpty(selectedRolesName)}
              treeData={roleTreeData}
              onItemClick={handleItemClick}
              activeDefaultLevel={1}
              activeSiblingNumber={1}
            />
            <Tree
              isActive={isPermissionSetTreeActive}
              showChildren={!_isEmpty(selectedPermissionSetName)}
              treeData={permissionTreeData}
              onItemClick={handleItemClick}
              activeDefaultLevel={1}
              activeSiblingNumber={1}
            />
            <Tree treeData={WWD_TREE_DATA} onItemClick={handleItemClick} isActive={isWwdTreeActive} />
            <Tree
              showChildren={isMetadataTreeActive}
              isActive={isMetadataTreeActive}
              activeDefaultLevel={1}
              activeSiblingNumber={metadataSiblingNumber}
              treeData={METADATA_TREE_DATA}
              onItemClick={handleItemClick}
            />
            <Tree isActive={isSubscriptionConfigTreeActive} treeData={SUBSCRIPTION_CONFIG_TREE_DATA} onItemClick={handleItemClick} />
            <Tree
              treeData={GOVERNANCE_TREE_DATA}
              onItemClick={handleItemClick}
              showChildren={isGovernanceTreeActive}
              isActive={isGovernanceTreeActive}
              activeDefaultLevel={1}
              activeSiblingNumber={governanceSiblingNumber}
            />
            <Tree isActive={isDataImportTreeActive} treeData={IMPORT_DATA_TREE_DATA} onItemClick={handleItemClick} />
            <Tree treeData={REPORTING_TREE_DATA} onItemClick={handleItemClick} isActive={isReportingTreeActive} />
            <Tree isActive={isTemplatesTreeActive} treeData={TEMPLATES_TREE_DATA} onItemClick={handleItemClick} />
            <Tree treeData={JOB_SCHEDULER_TREE_DATA} onItemClick={handleItemClick} isActive={isJobSchedulerActive} />
            <Tree treeData={GLOBAL_SELECT_LIST_TREE_DATA} onItemClick={handleItemClick} isActive={isGlobalSelectListTreeActive} />
            <Tree
              isActive={isServerCredentialTreeActive}
              showChildren={isServerCredentialTreeActive}
              activeDefaultLevel={1}
              activeSiblingNumber={serverCredentialConfigureSiblingNumber}
              treeData={SERVER_CREDENTIALS_TREE_DATA}
              onItemClick={handleItemClick}
            />
          </PropertyControlledComponent>
          <PropertyControlledComponent controllerProperty={isTenantUniversePath}>
            <Tree isActive={isTenantWorkspaceActive} treeData={TENANT_WORKSPACE_TREE_DATA} onItemClick={handleItemClick} />
            <Tree isActive={isTenantLoginConfigActive} treeData={TENANT_LOGIN_CONFIG_TREE_DATA} onItemClick={handleItemClick} />
            <Tree
              treeData={TENANT_GOVERNANCE_TREE_DATA}
              onItemClick={handleItemClick}
              showChildren={isGovernanceTreeActive}
              isActive={isGovernanceTreeActive}
              activeDefaultLevel={1}
              activeSiblingNumber={governanceSiblingNumber}
            />
          </PropertyControlledComponent>
        </PropertyControlledComponent>
      </div>
    </PropertyControlledComponent>
  );
};

SideBar.propTypes = {
  contentHeight: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  sanitizedPathName: PropTypes.array,
};

SideBar.defaultProps = {
  sanitizedPathName: EMPTY_ARRAY,
};

export default WithSize({ hasPageFooter: 0, hasPageHeader: 0 })(SideBar);
