import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import ButtonConfiguratorRenderer from '../buttonConfiguratorRenderer';
import EventHandlerConfigurator from '../eventHandlerConfigurator';
import CustomStylesConfigurator from '../customStylesConfigurator';

import { FIELD_IDS } from './buttonPropertiesForm.constants';

const BUTTON_FIELD = {
  id: FIELD_IDS.BUTTON,
  renderer: ButtonConfiguratorRenderer,
};

const EVENT_HANDLER_FIELD = {
  id: FIELD_IDS.EVENT_HANDLERS,
  renderer: EventHandlerConfigurator,
};

const CUSTOM_STYLE_FIELD = {
  id: FIELD_IDS.CUSTOM_STYLES,
  renderer: CustomStylesConfigurator,
};

const FORM_FIELDS = {
  [FIELD_IDS.BUTTON]: BUTTON_FIELD,
  [FIELD_IDS.EVENT_HANDLERS]: EVENT_HANDLER_FIELD,
  [FIELD_IDS.CUSTOM_STYLES]: CUSTOM_STYLE_FIELD,
};

const getFields = (entity, properties, componentType) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.BUTTON]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.BUTTON], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
  ]),
  [FIELD_IDS.EVENT_HANDLERS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.EVENT_HANDLERS], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
  [FIELD_IDS.CUSTOM_STYLES]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CUSTOM_STYLES], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
});

export { getFields };
