// TODO:Use this const everywhere
const VIEW_TYPES = {
  DETAIL_VIEW: 'DETAIL_VIEW',
  LIST_VIEW: 'LIST_VIEW',
  FORM_VIEW: 'FORM_VIEW',
  GRID_VIEW: 'GRID_VIEW',
  CELL_VIEW: 'CELL_VIEW',
  RIGHT_PANE_VIEW: 'RIGHT_PANE_VIEW',
  RECORD_TYPE_SELECTION_VIEW: 'RECORD_TYPE_SELECTION_VIEW',
};

const CELL_VIEW_FIELD_IDS = {
  CELL_TYPE: 'cellType',
};

const CELL_TYPES = {
  SIMPLE: 'SIMPLE',
  COMPOUND: 'COMPOUND',
};

// This const defines keys outside recursive structure of section
const VIEW_CONFIGURATION_FIELD_IDS = {
  ENTITY_NAME: 'entityName',
  VIEW_CONFIG_TYPE: 'viewConfigType',
  VIEW_TYPE: 'viewType',
  ID: 'id',
  NAME: 'name',
  DESCRIPTION: 'description',
  APPLICABLE_RECORD_TYPES: 'applicableRecordTypes',
  DISPLAY_NAME: 'properties.title',
  SECTION: 'section',
  PROPERTIES: 'properties',
};

const FORM_MODES = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
};

const VIEW_CONFIG_TYPES = {
  STANDARD: 'STANDARD',
  CUSTOM: 'CUSTOM',
};

const VIEW_BUILDER_DATE_TIME_FORMATS = {
  ABBREVIATED_MONTH_DATE_YEAR_WITH_TIME_MERIDIAN: 'ABBREVIATED_MONTH_DATE_YEAR_WITH_TIME_MERIDIAN',
  DATE_DIFFERENCE_HUMANISED: 'DATE_DIFFERENCE_HUMANISED',
};

const VIEW_BUILDER_LIST_TAB_IDS = {
  RECORD_TYPE_VIEW: 'record-type-view',
  ENTITY_VIEW: 'entity-view',
};

// This const defines keys inside recursive structure of section
const VIEW_CONFIGURATION_GENERAL_KEYS = {
  PROPERTIES: 'properties',
  VIEW_TYPE: 'viewType',
  SECTION_ID: 'sectionId',
  TYPE: 'type',
  RENDERER_PROPS: 'rendererProps',
  CHILDREN: 'children',
};

const VIEW_CONFIGURATION_PROPERTIES_ID = {
  EVENT_HANDLERS: 'eventHandlers',
  CUSTOM_ACTIONS: 'rowActionProps',
};

// Combined keys of properties & rendererProps
const ALL_VIEW_PROPERTY_KEYS = {
  VIEW_TYPE: 'viewType',
  CELL_TYPE: 'cellType',
  FIELD_NAMES: 'fieldNames',
  TEMPLATE: 'template',
  RENDERER_PROPS: 'rendererProps',
  LOOK_UP_FIELD_NAMES: 'lookupFieldNames',
  TITLE: 'title',
  COLUMN_COUNT: 'numberOfColumns',
  KEY_SPAN: 'keySpan',
  VALUE_SPAN: 'valueSpan',
  IS_KEY_VISIBLE: 'isKeyVisible',
  WIDTH: 'width',
  TABLE_PAGE_SIZE: 'pageSize',
  TABLE_MIN_ROWS: 'minRows',
  TABLE_ROW_HEIGHT: 'rowHeight',
  TABLE_CURRENT_PAGE: 'currentPage',
  TABLE_RESULT_PER_PAGE: 'resultPerPages',
  TABLE_SHOW_PAGINATION: 'showPagination',
  TABLE_FIXED_PAGINATION: 'fixedPagination',
  TABLE_SHOW_HEADER: 'showHeader',
  TABLE_SHOW_SUB_HEADER: 'showSubHeader',
  TABLE_SHOW_REFRESH_ICON: 'showRefreshIcon',
  TABLE_SHOW_FILTER: 'showFilter',
  TABLE_SHOW_SEARCH_BY_FIELD: 'showSearchByField',
  TABLE_SHOW_MULTI_SORT: 'showMultiSort',
  TABLE_PAGE_SIZE_OPTIONS: 'pageSizeOptions',
  GRID_TABLE_ROW_COUNT: 'rowCount',
  GRID_TABLE_COLUMN_COUNT: 'columnCount',
  EDITABLE: 'editable',
  MANDATORY: 'mandatory',
  DISABLED: 'disabled',
  IMAGE_RADIO_BUTTON: 'imageType',
  IMAGE_PATH: 'imagePath',
  GRID: 'gridRenderer',
  FILTER_COMPONENT: 'filterComponent',
  EXPANDABLE_ROW_CONFIG_FORM: 'expandableRowConfigForm',
  DOWNLOAD_OPTIONS: 'downloadOptions',
  COMPONENT_RENDERER: 'RENDERER',
  CARD_VIEW_NAME: 'cardViewName',
  BUTTON: 'button',
  IS_RTE_TRUNCATED_TYPE: 'isRTETruncatedType',
  IS_AVATAR_VIEW: 'isAvatarView',
  DATE_TIME_FORMAT: 'dateTimeFormat',
  EVENT_HANDLERS: 'eventHandlers',
  CUSTOM_STYLES: 'customStyles',
  ACTIONS_TO_SHOW: 'actionsToShow',
  SEARCH_OPTIONS: 'searchOptions',
  TEXT_AREA_ROWS: 'textAreaRows',
  PREFILLED_VALUE: 'prefilledValue',
  SHOW_ACTIONS: 'showActions',
  SECTION_TITLE_SIZE: 'sectionTitleSize',
  CELL_VIEW_NAME: 'cellViewName',
  DEFAULT_SORT_DETAILS: 'defaultSortDetails',
  CUSTOM_CODE: 'customCode',
  CONDITIONAL_STYLES_GETTER: 'conditionalStylesGetter',
  SEARCHABLE_FIELDS: 'searchableFields',
  SORTABLE_FIELDS: 'sortableFields',
  ENABLE_ACTION_SCRIPT: 'enableActionScript',
  CUSTOM_ACTIONS: 'rowActionProps',
  BUTTON_ICON: 'buttonIcon',
  BUTTON_TEXT: 'buttonText',
  BUTTON_TEXT_TYPE: 'buttonTextType',
  LOOKUP_ENTITY_VIEW_NAME: 'lookUpEntityViewName',
  IS_CREATE_NEW_RECORD_ENABLED: 'isCreateNewRecordEnabled',
  COMPLEX_VIEW_NAME: 'complexViewName',
  COMPLEX_VIEW_ENTITY_NAME: 'complexViewEntityName',
  PRE_APPLIED_FILTERS: 'preAppliedFilters',
  FILTER_META_DATA: 'filterMetadata',
  // FE added properties
  SIMILAR_VIEW_DETAILS: 'similarViewDetails',
  VIEW_ID: 'viewId',
  VIEW_NAME: 'viewName',
  SORT_DETAILS: 'sortDetails',
};

const RENDERER_PROP_KEYS = {
  CELL_VIEW_NAME: 'cellViewName',
  CARD_VIEW_NAME: 'cardViewName',
  COMPLEX_VIEW_NAME: 'complexViewName',
  COMPLEX_VIEW_ENTITY_NAME: 'complexViewEntityName',
  DEFAULT_SORT_DETAILS: 'defaultSortDetails',
  CUSTOM_CODE: 'customCode',
  CUSTOM_STYLES: 'customStyles',
  CONDITIONAL_STYLES_GETTER: 'conditionalStylesGetter',
  SEARCHABLE_FIELDS: 'searchableFields',
  SORTABLE_FIELDS: 'sortableFields',
  DATE_TIME_FORMAT: 'dateTimeFormat',
  SIMILAR_VIEW_DETAILS: 'similarViewDetails',
  ACTIONS_TO_SHOW: 'actionsToShow',
  COMPONENT_RENDERER: 'RENDERER',
  TEXT_AREA_ROWS: 'textAreaRows',
  PREFILLED_VALUE: 'prefilledValue',
  ENABLE_ACTION_SCRIPT: 'enableActionScript',
  IS_KEY_VISIBLE: 'isKeyVisible',
  BUTTON: 'button',
  IS_AVATAR_VIEW: 'isAvatarView',
  IS_RTE_TRUNCATED_TYPE: 'isRTETruncatedType',
  SECTION_TITLE_SIZE: 'sectionTitleSize',
  BUTTON_ICON: 'buttonIcon',
  BUTTON_TEXT: 'buttonText',
  BUTTON_TEXT_TYPE: 'buttonTextType',
  LOOKUP_ENTITY_VIEW_NAME: 'lookUpEntityViewName',
  IS_CREATE_NEW_RECORD_ENABLED: 'isCreateNewRecordEnabled',
};

export {
  VIEW_TYPES,
  CELL_TYPES,
  VIEW_CONFIGURATION_FIELD_IDS,
  FORM_MODES,
  VIEW_CONFIG_TYPES,
  CELL_VIEW_FIELD_IDS,
  VIEW_BUILDER_LIST_TAB_IDS,
  VIEW_BUILDER_DATE_TIME_FORMATS,
  VIEW_CONFIGURATION_GENERAL_KEYS,
  VIEW_CONFIGURATION_PROPERTIES_ID,
  RENDERER_PROP_KEYS,
  ALL_VIEW_PROPERTY_KEYS,
};
