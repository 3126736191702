import _get from 'lodash/get';
import _pick from 'lodash/pick';

import { setValueInLocalStorage } from '@tekion/tekion-base/utils/localStorage';
// import { setCookie } from '@tekion/tekion-base/utils/cookieStorage';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
// import { getDefaultHeaders } from '../../../../services/apiService/apiService.helper';
import { USER_KEYS } from './LoginPage.constants';
import LS_CACHE_KEYS from '../../../../constants/localStorage.cacheKeys';

const saveLoginData = (response) => {
  const loginData = _get(response, 'data') || EMPTY_OBJECT;
  const loginUserData = _pick(loginData, USER_KEYS);

  // Set values in LS
  setValueInLocalStorage(LS_CACHE_KEYS.TOKEN, loginData.accessToken);
  setValueInLocalStorage(LS_CACHE_KEYS.USER, JSON.stringify(loginUserData));
  // const cookieData = _omit(getDefaultHeaders(), 'tekion-api-token');
  // setCookie(LS_CACHE_KEYS.COOKIES, cookieData);

  return loginData;
};

export { saveLoginData };
