import React from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { tget } from '@tekion/tekion-base/utils/general';

import { getFormFormattedSelectedWidgetProperties, getConfigFormattedSelectedWidgetProperties } from './helpers/buttonPropertiesConfigure.helpers';
import { BUTTON_PROPERTIES_CONFIGURE_FIELDS } from './helpers/buttonPropertiesConfigure.fields';
import { BUTTON_PROPERTIES_CONFIGURE_SECTIONS } from './helpers/buttonPropertiesConfigure.sections';

import { ACTION_TYPES as VISUAL_BUILDER_ACTION_TYPES, COMPONENT_CONFIG_KEYS } from '../../../../constants/visualBuilder.general.constants';

const ButtonPropertiesConfigure = ({ selectedWidgetConfig, onChangeWidgetConfigurationAction }) => {
  const widgetName = tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, '');
  const selectedWidgetProperties = tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, {});
  const formFormattedSelectedWidgetProperties = getFormFormattedSelectedWidgetProperties(selectedWidgetProperties);

  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload = EMPTY_OBJECT } = action;
    switch (type) {
      case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
        const { id, value } = payload;
        const configFormattedSelectedWidgetProperties = getConfigFormattedSelectedWidgetProperties(value, id, selectedWidgetProperties);

        onChangeWidgetConfigurationAction({
          type: VISUAL_BUILDER_ACTION_TYPES.UPDATE_WIDGET_CONFIGURATION,
          payload: {
            value: { widgetConfiguration: { ...selectedWidgetConfig, properties: { ...configFormattedSelectedWidgetProperties } }, widgetName },
          },
        });
        break;
      }
      // case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
      //   const { errors: newErrors } = payload;
      //   break;
      // }
      default:
    }
  };

  return (
    <FormBuilder
      sections={BUTTON_PROPERTIES_CONFIGURE_SECTIONS}
      fields={BUTTON_PROPERTIES_CONFIGURE_FIELDS}
      values={formFormattedSelectedWidgetProperties}
      onAction={handleAction}
    />
  );
};

ButtonPropertiesConfigure.propTypes = {
  selectedWidgetConfig: PropTypes.object,
  onChangeWidgetConfigurationAction: PropTypes.func,
};

ButtonPropertiesConfigure.defaultProps = {
  selectedWidgetConfig: undefined,
  onChangeWidgetConfigurationAction: _noop,
};

export default ButtonPropertiesConfigure;
