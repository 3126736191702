import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

// Constants
import { DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';
import { tget } from '@tekion/tekion-base/utils/general';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { STUDIO_ROUTE } from '../../../../constants/routes';
import PAGE_IDS from '../../constants/PageIds.constants';

import COLUMNS from './constants/entityList.columnIds';
import ACTION_TYPES from './constants/entityList.actionTypes';
import { fetchEntities } from '../../../../actions/entityManagement.actions';
import { getPayload } from './helpers/entityList.helpers';

const handleInit = async ({ getState, setState, params }) => {
  const { pageSize = DEFAULT_PAGE_INFO.rows, searchText } = getState();
  const currentPageSize = _get(params, 'pageSize', pageSize);
  const currentSearchText = _get(params, 'searchText', searchText);
  const currentPageToken = _get(params, 'nextPageToken');
  setState({ loading: true });

  const payload = getPayload({
    searchText: currentSearchText,
    searchField: COLUMNS.DISPLAY_NAME,
    pageSize: currentPageSize,
    nextPageToken: currentPageToken,
  });
  const response = await fetchEntities(payload);
  const entities = tget(response, 'hits', EMPTY_ARRAY);

  setState({
    entities,
    totalNumberOfEntries: _get(response, 'count', 0),
    loading: false,
    nextPageToken: _get(response, 'nextPageToken'),
  });
};

const handleRedirectToCreate = ({ getState }) => {
  const { history } = getState();
  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITY_CREATE}`);
};

const handleRowClick = ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const entity = _get(params, 'value.original', EMPTY_OBJECT);
  const entityName = _get(entity, 'name');
  if (!_isEmpty(entityName)) {
    history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELDS}`);
  }
};

const handleEditEntity = ({ getState, params }) => {
  const { history } = getState();
  const entityName = _get(params, 'name');
  if (!_isEmpty(entityName)) {
    const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITY_EDIT}/${entityName}`;
    history.push({ pathname, state: { entity: params } });
  }
};

const handleSearchApply = async ({ setState, params, getState }) => {
  setState(
    {
      searchText: _get(params, 'value'),
      currentPage: 0,
    },
    () => {
      handleInit({ getState, setState, params: { searchText: _get(params, 'value'), currentPage: 0 } });
    },
  );
};

const handlePageUpdate = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');
  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }

  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleInit({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInit,
  [ACTION_TYPES.REDIRECT_TO_ENTITY_CREATION]: handleRedirectToCreate,
  [ACTION_TYPES.EDIT_ENTITY_DETAIL]: handleEditEntity,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_SEARCH_TERM_UPDATE]: handleSearchApply,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleRowClick,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleInit,
};

export default ACTION_HANDLERS;
