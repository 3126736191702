import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components/molecules/loader';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import ApprovalSettingCard from './components/approvalSettingCard/ApprovalSettingCard';
import FieldsList from './components/fieldsList/FieldsList';

import ACTION_HANDLERS from './helpers/approvalSettingDetails.actionHandlers';

import PAGE_IDS from '../../../../constants/PageIds.constants';
import FIELD_IDS from '../constants/approvalSettings.fieldIds';
import { ACTION_TYPES, INITIAL_STATE } from './constants/approvalSettingDetails.constants';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import ROUTES from '../../../../constants/routes';

const ApprovalSettingDetails = ({ isLoading, approvalSetting, history, onAction }) => {
  const fieldsList = tget(approvalSetting, FIELD_IDS.FIELDS, EMPTY_ARRAY);

  const handleCreateClick = useCallback(() => {
    const settingName = tget(approvalSetting, FIELD_IDS.NAME);

    if (!_isEmpty(settingName)) {
      const path = `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_SETTING}/${settingName}/${PAGE_IDS.APPROVAL_SETTING_ADD_FIELD}`;
      history.push({ pathname: path, state: { approvalSetting } });
    }
  }, [approvalSetting, history]);

  const handleFieldClick = useCallback(
    (fieldName) => {
      const settingName = tget(approvalSetting, FIELD_IDS.NAME);

      if (!_isEmpty(settingName) && !_isEmpty(fieldName)) {
        history.push(
          // eslint-disable-next-line max-len
          `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_SETTING}/${settingName}/${PAGE_IDS.APPROVAL_SETTING_EDIT_FIELD}/${fieldName}`,
        );
      }
    },
    [approvalSetting, history],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loader id={PAGE_IDS.APPROVAL_SETTING_DETAIL} />;
  }

  return (
    <>
      <Page.Header hasBack goBackTo={ROUTES.APPROVAL_SETTINGS_ROUTE}>
        <Heading>{__('Approval Setting details')}</Heading>
      </Page.Header>
      <ApprovalSettingCard approvalSetting={approvalSetting} />
      <FieldsList fieldsList={fieldsList} onCreateClick={handleCreateClick} onFieldClick={handleFieldClick} />
    </>
  );
};

ApprovalSettingDetails.propTypes = {
  isLoading: PropTypes.bool,
  approvalSetting: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onAction: PropTypes.func.isRequired,
};

ApprovalSettingDetails.defaultProps = {
  isLoading: false,
  approvalSetting: EMPTY_OBJECT,
};

export default withActions(INITIAL_STATE, ACTION_HANDLERS)(ApprovalSettingDetails);
