exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ssoProvidersRenderer_container__7SNbqTT5K7{width:100%}.ssoProvidersRenderer_heading__m4zPXSr7dU{width:100%;display:flex;justify-content:center;align-items:center}.ssoProvidersRenderer_heading__m4zPXSr7dU::before{height:.1rem;width:30%;content:\"\"}[dir] .ssoProvidersRenderer_heading__m4zPXSr7dU::before{background-color:#d4d5d6}[dir=ltr] .ssoProvidersRenderer_heading__m4zPXSr7dU::before{margin-right:2.4rem}[dir=rtl] .ssoProvidersRenderer_heading__m4zPXSr7dU::before{margin-left:2.4rem}.ssoProvidersRenderer_heading__m4zPXSr7dU::after{height:.1rem;width:30%;content:\"\"}[dir] .ssoProvidersRenderer_heading__m4zPXSr7dU::after{background-color:#d4d5d6}[dir=ltr] .ssoProvidersRenderer_heading__m4zPXSr7dU::after{margin-left:2.4rem}[dir=rtl] .ssoProvidersRenderer_heading__m4zPXSr7dU::after{margin-right:2.4rem}.ssoProvidersRenderer_heading2__dVt1Amp6Nv{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}[dir] .ssoProvidersRenderer_heading2__dVt1Amp6Nv{margin:0 auto}.ssoProvidersRenderer_providersContainer__4NtJAEUbBZ{width:100%}[dir] .ssoProvidersRenderer_providersContainer__4NtJAEUbBZ{margin-top:2.4rem}.ssoProvidersRenderer_providerButton__nG8wPgHbHr{display:flex;align-items:center;justify-content:center;width:100%;gap:1.2rem}[dir] .ssoProvidersRenderer_providerButton__nG8wPgHbHr{border:.1rem solid #d4d5d6;border-radius:.2rem;padding:.4rem .8rem;margin-bottom:2.4rem;cursor:pointer}[dir] .ssoProvidersRenderer_providerButton__nG8wPgHbHr:hover{background-color:#ebf4ff}.ssoProvidersRenderer_logoImage__t2mhRsKgwP{width:100%}[dir] .ssoProvidersRenderer_logoImage__t2mhRsKgwP{margin:auto}.ssoProvidersRenderer_logoPlaceHolder__ba9SZJSZQX{font-size:2.1rem}", ""]);

// Exports
exports.locals = {
	"container": "ssoProvidersRenderer_container__7SNbqTT5K7",
	"heading": "ssoProvidersRenderer_heading__m4zPXSr7dU",
	"heading2": "ssoProvidersRenderer_heading2__dVt1Amp6Nv",
	"providersContainer": "ssoProvidersRenderer_providersContainer__4NtJAEUbBZ",
	"providerButton": "ssoProvidersRenderer_providerButton__nG8wPgHbHr",
	"logoImage": "ssoProvidersRenderer_logoImage__t2mhRsKgwP",
	"logoPlaceHolder": "ssoProvidersRenderer_logoPlaceHolder__ba9SZJSZQX"
};