import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { REMOVE_ACTION, EDIT_ACTION } from '../constants/listInputTable.constants';

const getActionsForRow = (isDisabled, showDeleteAction) => {
  if (isDisabled) {
    return [EDIT_ACTION];
  }
  if (showDeleteAction) {
    return [REMOVE_ACTION];
  }
  return EMPTY_ARRAY;
};

export { getActionsForRow };
