import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _trim from 'lodash/trim';
import _toLower from 'lodash/toLower';
import _filter from 'lodash/filter';
import _noop from 'lodash/noop';
import _includes from 'lodash/includes';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Components
import SearchInput from '@tekion/tekion-components/molecules/searchInput';
import Modal from '@tekion/tekion-components/molecules/Modal';
import WorkSpaceCard from './components/WorkSpaceCard';

// Readers
import LOCAL_STORAGE_READER from '../../readers/localStorage.reader';
import USER_READER from '../../readers/tenantUser.reader';

import styles from './workspaceModal.module.scss';

const WorkspaceModal = (props) => {
  const { isVisible, allWorkSpaces, handleWorkSpaceItemClick, handleWorkSpaceModalVisibility } = props;

  const [searchText, setSearchText] = useState(EMPTY_STRING);
  const [workSpaceList, setWorkSpaceList] = useState(EMPTY_ARRAY);

  const homeWorkspaceId = useMemo(() => USER_READER.homeWorkspaceId(LOCAL_STORAGE_READER.userInfo()), []);

  const workspacesWithOutUniverse = useMemo(
    () => _filter(allWorkSpaces, (workSpace) => !_includes(_get(workSpace, 'workspaceId', EMPTY_STRING), 'universe')),
    [allWorkSpaces],
  );

  useEffect(() => {
    setWorkSpaceList(workspacesWithOutUniverse);
  }, [workspacesWithOutUniverse]);

  const cards = useMemo(
    () =>
      _map(workSpaceList, (card) => {
        const id = _get(card, 'workspaceId', EMPTY_STRING);
        return <WorkSpaceCard key={id} isHomeWorkspace={homeWorkspaceId === id} cardData={card} onCardClick={handleWorkSpaceItemClick} />;
      }),
    [homeWorkspaceId, workSpaceList, handleWorkSpaceItemClick],
  );

  const renderBody = useCallback(() => <div className={styles.cards}>{cards}</div>, [cards]);

  const handleSearch = useCallback(
    (value) => {
      let filteredWorkspaceList = [...workspacesWithOutUniverse];
      if (!_isEmpty(value)) {
        const filterSearchText = _trim(_toLower(value));
        filteredWorkspaceList = _filter(filteredWorkspaceList, (workspace) => {
          const workspaceName = _get(workspace, 'workspaceName', EMPTY_STRING);
          return _includes(workspaceName, filterSearchText);
        });
      }
      setSearchText(value);
      setWorkSpaceList(filteredWorkspaceList);
    },
    [workspacesWithOutUniverse],
  );

  return (
    <Modal
      destroyOnClose
      showFooter={false}
      visible={isVisible}
      title={__('Select Workspace')}
      bodyStyle={{ height: '700px', 'overflow-y': 'scroll' }}
      width={1210}
      onCancel={handleWorkSpaceModalVisibility}
    >
      <SearchInput
        className={styles.rightShift}
        textInputClass={styles.search}
        inputContainerClassName={styles.searchContainer}
        value={searchText}
        onChange={handleSearch}
      />

      <div>{renderBody()}</div>
    </Modal>
  );
};

WorkspaceModal.propTypes = {
  isVisible: PropTypes.bool,
  allWorkSpaces: PropTypes.array,
  handleWorkSpaceItemClick: PropTypes.func,
  handleWorkSpaceModalVisibility: PropTypes.func,
};

WorkspaceModal.defaultProps = {
  isVisible: false,
  allWorkSpaces: EMPTY_ARRAY,
  handleWorkSpaceItemClick: _noop,
  handleWorkSpaceModalVisibility: _noop,
};

export default WorkspaceModal;
