import { CONDITION_TYPES } from '../../../../constants/workflow.constants';

const TITLE_BY_TARGET = {
  [CONDITION_TYPES.IF]: __('Change to if condition'),
  [CONDITION_TYPES.IF_ELSE]: __('Change to if else condition'),
  [CONDITION_TYPES.CHECK]: __('Change to check field value condition'),
};

const LABEL_BY_TARGET = {
  [CONDITION_TYPES.IF]: __('Choose a path to follow the if condition'),
  [CONDITION_TYPES.IF_ELSE]: __('Choose path to follow the if else condition'),
  [CONDITION_TYPES.SWITCH]: __('Choose path to follow the check condition'),
};

const MAX_TAG_COUNT = 2;

const CONTEXT_ID = 'WORKFLOW_ENGINE_CHANGE_TO_MODAL';

const PATH_LABEL = {
  YES: __('True Path'),
  NO: __('False Path'),
  DEFAULT: __('Path'),
};

export { PATH_LABEL, LABEL_BY_TARGET, MAX_TAG_COUNT, CONTEXT_ID, TITLE_BY_TARGET };
