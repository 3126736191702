import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput/TextInput';

import DATA_TYPES from '../../../../../../../../constants/fieldDefinition.dataTypes';

const CONTEXT_ID = 'LOOP_MODAL_FORM';

const FIELD_IDS = {
  VARIABLE_NAME: 'variableName',
  ENTITY_NAME: 'entityName',
  RECORD_COUNT: 'recordCount',
  VALUE_TYPE: 'valueType',
  STATIC_VALUE: 'value',
  DERIVED_FIELD: 'field',
  DATA_TYPE: 'dataType',
};

const RECORD_COUNT_TYPES = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
};
const VALUE_TYPES = {
  STATIC: 'static',
  DERIVED: 'derived',
};

const RECORD_COUNT_OPTIONS = [
  {
    label: __('Single'),
    value: RECORD_COUNT_TYPES.SINGLE,
  },
  {
    label: __('Multiple'),
    value: RECORD_COUNT_TYPES.MULTIPLE,
  },
];

const VALUE_TYPE_OPTIONS = [
  {
    label: __('Static'),
    value: VALUE_TYPES.STATIC,
  },
  {
    label: __('Derived'),
    value: VALUE_TYPES.DERIVED,
  },
];

const DATA_TYPES_LABELS = {
  [DATA_TYPES.TEXT]: __('Text'),
  [DATA_TYPES.NUMBER]: __('Number'),
  [DATA_TYPES.DATE]: __('Date'),
  [DATA_TYPES.BOOLEAN]: __('Boolean'),
  [DATA_TYPES.COMPLEX]: __('Complex'),
};

const DATA_TYPE_OPTIONS = [
  { label: DATA_TYPES_LABELS[DATA_TYPES.TEXT], value: DATA_TYPES.TEXT },
  { label: DATA_TYPES_LABELS[DATA_TYPES.NUMBER], value: DATA_TYPES.NUMBER },
  { label: DATA_TYPES_LABELS[DATA_TYPES.BOOLEAN], value: DATA_TYPES.BOOLEAN },
];

const VARIABLE_NODE_TYPES = {
  DEFINED: 'DEFINED',
  SET: 'SET',
};

const FIELD_RENDERERS = {
  [DATA_TYPES.TEXT]: TextInputField,
  [DATA_TYPES.NUMBER]: NumberInputField,
  [DATA_TYPES.BOOLEAN]: SelectInput,
};

const RENDERER_OPTIONS_BASED_FIELD_RENDERER = {
  [DATA_TYPES.NUMBER]: { autoFocus: true, triggerChangeOnBlur: false, min: 1, precision: 0 },
  [DATA_TYPES.BOOLEAN]: {
    options: [
      { label: __('True'), value: true },
      { label: __('False'), value: false },
    ],
  },
};

const SYSTEM_DEFINED_VARIABLES = ['$record', '$current', '$system'];

export {
  FIELD_IDS,
  CONTEXT_ID,
  RECORD_COUNT_OPTIONS,
  VALUE_TYPE_OPTIONS,
  VALUE_TYPES,
  VARIABLE_NODE_TYPES,
  DATA_TYPE_OPTIONS,
  FIELD_RENDERERS,
  RENDERER_OPTIONS_BASED_FIELD_RENDERER,
  SYSTEM_DEFINED_VARIABLES,
};
