import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import importDataServices from '../services/importData.services';

const getImportTemplateById = async (id) => {
  try {
    const response = await importDataServices.getImportTemplateById(id);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch import template, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateImportTemplateById = async (id, payload = EMPTY_OBJECT) => {
  try {
    const response = await importDataServices.updateImportTemplateById(id, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update import template, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createImportTask = async (payload) => {
  try {
    const response = await importDataServices.createImportTask(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create import task, please try again later.')));
    return false;
  }
};

const startImportById = async (id) => {
  try {
    const response = await importDataServices.startImportById(id);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to start import process, please try again later.')));
    return false;
  }
};

const searchImports = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await importDataServices.searchImports(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to search imports, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const searchBatchTasks = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await importDataServices.searchBatchTasks(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to search batch tasks, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getImportTaskById = async (id) => {
  try {
    const response = await importDataServices.getImportTaskById(id);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to import task by id, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getProgressForImportTaskById = async (id) => {
  try {
    const response = await importDataServices.getProgressForImportTaskById(id);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get progress for import task, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export {
  getImportTemplateById,
  updateImportTemplateById,
  createImportTask,
  startImportById,
  searchBatchTasks,
  getImportTaskById,
  getProgressForImportTaskById,
  searchImports,
};
