exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dashboard_body__9UTZQ5WKK2{overflow-y:auto}[dir] .dashboard_body__9UTZQ5WKK2{background-color:#eeeff0}.dashboard_dashboardContainer__uSaYG2Sqv2{width:100;height:100;overflow:hidden}.dashboard_dashboardHeaderContainer__axuiz9rJXe .dashboard_content__2nWtFBqxNZ{display:flex;align-items:center}.dashboard_editContainer__cjHG7XoLbL{display:flex;justify-content:space-around;align-items:center}[dir=ltr] .dashboard_icon__uD6vP75cbj{margin-right:.5rem}[dir=rtl] .dashboard_icon__uD6vP75cbj{margin-left:.5rem}.dashboard_dashboardSelect__4CjkfhVPv8{width:25rem;font-weight:800;font-size:medium}[dir] .dashboard_dashboardSelect__4CjkfhVPv8{border-color:#d3d3d3}[dir=ltr] .dashboard_dashboardSelect__4CjkfhVPv8{margin-right:1rem}[dir=rtl] .dashboard_dashboardSelect__4CjkfhVPv8{margin-left:1rem}.dashboard_customFilterContainer__4dBMMQaaym{display:flex;align-items:center}[dir] .dashboard_customFilterContainer__4dBMMQaaym{margin-top:1.2rem;margin-bottom:1rem}[dir=ltr] .dashboard_customFilterContainer__4dBMMQaaym{margin-left:2rem}[dir=rtl] .dashboard_customFilterContainer__4dBMMQaaym{margin-right:2rem}[dir=ltr] .dashboard_dateFilter__qptpLHShYt{margin-left:1rem}[dir=rtl] .dashboard_dateFilter__qptpLHShYt{margin-right:1rem}.dashboard_flowItem__iTNKinmvnN{display:flex;flex-flow:row;justify-content:space-between;align-items:center}[dir=ltr] .dashboard_flowItem__iTNKinmvnN{margin-left:.4rem}[dir=rtl] .dashboard_flowItem__iTNKinmvnN{margin-right:.4rem}.dashboard_label__qBzVJ8M6kj{font-size:large;font-weight:300}[dir] .dashboard_label__qBzVJ8M6kj{cursor:pointer}[dir=ltr] .dashboard_label__qBzVJ8M6kj{margin-right:.5rem}[dir=rtl] .dashboard_label__qBzVJ8M6kj{margin-left:.5rem}", ""]);

// Exports
exports.locals = {
	"body": "dashboard_body__9UTZQ5WKK2",
	"dashboardContainer": "dashboard_dashboardContainer__uSaYG2Sqv2",
	"dashboardHeaderContainer": "dashboard_dashboardHeaderContainer__axuiz9rJXe",
	"content": "dashboard_content__2nWtFBqxNZ",
	"editContainer": "dashboard_editContainer__cjHG7XoLbL",
	"icon": "dashboard_icon__uD6vP75cbj",
	"dashboardSelect": "dashboard_dashboardSelect__4CjkfhVPv8",
	"customFilterContainer": "dashboard_customFilterContainer__4dBMMQaaym",
	"dateFilter": "dashboard_dateFilter__qptpLHShYt",
	"flowItem": "dashboard_flowItem__iTNKinmvnN",
	"label": "dashboard_label__qBzVJ8M6kj"
};