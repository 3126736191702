import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { tget } from '@tekion/tekion-base/utils/general';
import { DATE_TIME_FORMATS, getFormattedDateTime } from '@tekion/tekion-base/utils/dateUtils';

import UserAvatar from '../../../../../../../../../atoms/userAvatar/UserAvatar';

import commentReader from '../../../../../../../../../readers/comment.reader';
import workspaceUserReader from '../../../../../../../../../readers/workSpaceUser.reader';

import styles from './comment.module.scss';

const Comment = ({ comment }) => {
  const userData = useMemo(() => tget(comment, 'userData'), [comment]);
  const name = useMemo(() => workspaceUserReader.fullName(userData), [userData]);
  const picSrc = useMemo(() => workspaceUserReader.profilePicture(userData), [userData]);
  const content = useMemo(() => commentReader.content(comment), [comment]);
  const commentTime = useMemo(
    () => getFormattedDateTime(commentReader.createdTime(comment), DATE_TIME_FORMATS.MONTH_DATE_YEAR_TIME_12HR_FORMAT),
    [comment],
  );

  return (
    <div className={styles.commentContainer}>
      <div className={styles.indexContainer}>
        <UserAvatar displayName={name} userAvatar={picSrc} />
      </div>
      <div className={styles.commentDetailsContainer}>
        <div className={styles.userDetailContainer}>
          <span className={styles.userName}>{name}</span>
          <span className={styles.commentTime}>{commentTime}</span>
        </div>
        <div className={styles.commentContent}>{content}</div>
      </div>
    </div>
  );
};

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
};

export default Comment;
