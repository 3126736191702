import _times from 'lodash/times';
import { insertNodes, someNode } from '@udecode/plate-common';
import { ELEMENT_TABLE } from '@udecode/plate-table';
import { getPlatePluginType } from '@udecode/plate-core';

// utils/ helpers
import { uuid } from '@tekion/tekion-base/utils/general';
import { createEmptyRowNode } from './richTextEditor.tableGeneral';

const createEmptyTableNode = (editor, size) => {
  const { cols: columnCount, rows: rowCount } = size;
  const columnIds = _times(columnCount, uuid);
  const headerRow = createEmptyRowNode({ editor, columnCount, columnIds, isHeader: true });
  const rows = _times(rowCount, () => createEmptyRowNode({ editor, columnCount, columnIds }));
  return {
    type: getPlatePluginType(editor, ELEMENT_TABLE),
    children: [headerRow, ...rows],
  };
};

const insertTable = (editor, size) => {
  if (
    !someNode(editor, {
      match: { type: getPlatePluginType(editor, ELEMENT_TABLE) },
    })
  ) {
    insertNodes(editor, createEmptyTableNode(editor, size));
  }
};

export default insertTable;
