const ACTION_TYPES = {
  INIT_DATA: 'INIT_DATA ',
  ON_TAB_CLICK: 'ON_TAB_CLICK',
  ON_NAVIGATE_TO_PAGE: 'ON_NAVIGATE_TO_PAGE',
  SET_APPLICATION_VARIABLES: 'SET_APPLICATION_VARIABLES',
  SET_ENTITY_DEF: 'SET_ENTITY_DEF',
  SET_ENTITY_VIEW_CONFIG: 'SET_ENTITY_VIEW_CONFIG',
  SET_RECORD_TYPE_VIEW_CONFIG: 'SET_RECORD_TYPE_VIEW_CONFIG',
};

const NAVIGATION_TYPES = {
  TOP: 'top',
  LEFT: 'left',
};

const APPLICATION_COMPONENT_TYPES = {
  NAVIGATION: 'NAVIGATION',
  NO_NAVIGATION: 'NO_NAVIGATION',
  HEADER: 'HEADER',
  FOOTER: 'FOOTER',
};

const PROPERTY_IDS = {
  APPROVAL_METADATA: 'approvalMetadata',
};

const APPROVAL_TAB_ID = 'APPROVAL_CENTRE';

export { ACTION_TYPES, NAVIGATION_TYPES, APPLICATION_COMPONENT_TYPES, PROPERTY_IDS, APPROVAL_TAB_ID };
