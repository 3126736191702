import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { DEFAULT_FILTER_GROUP } from '@tekion/tekion-components/organisms/filterSection';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { downloadData, fetchAllExportsData, retriggerExport, cancelExport } from '../../../../../actions/exportsListPage.actions';
import { downloadURI } from '../../../../../utils/downloadFile';
import { getPayload } from './exportListPage.helpers';
import ACTION_TYPES from '../constants/exportListPage.actionTypes';

import readers from '../readers/exportListPage.readers';

const fetchAllRequests = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  setState({ loading: true });
  const { pageSize, selectedFilters } = getState();
  const currentPageToken = _get(params, 'nextPageToken');

  const payload = getPayload(pageSize, selectedFilters, currentPageToken);

  const exportDataResponse = await fetchAllExportsData(payload);
  const exportList = tget(exportDataResponse, 'hits', EMPTY_ARRAY);

  setState({
    exportList,
    totalCount: _get(exportDataResponse, 'count'),
    isLoaded: true,
    loading: false,
    nextPageToken: _get(exportDataResponse, 'nextPageToken'),
  });
};

const handlePageChange = ({ setState, getState, params }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');

  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }
  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }
  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      fetchAllRequests({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const handleFilterChange = ({ getState, setState, params }) => {
  const selectedFilters = _get(params, 'value', []);
  const selectedFilterGroup = _get(params, 'selectedFilterGroup', DEFAULT_FILTER_GROUP);
  setState(
    {
      selectedFilters,
      appliedFiltersGroup: selectedFilterGroup,
      currentPage: 0,
    },
    () => {
      fetchAllRequests({ getState, setState });
    },
  );
};

const handleDownloadData = async ({ params = EMPTY_OBJECT }) => {
  const id = _get(params, 'value.id');
  const fileName = _get(params, 'value.name');
  const response = await downloadData(id);
  if (!_isEmpty(response)) {
    const { url } = response;
    return downloadURI(url, fileName);
  }
  return EMPTY_OBJECT;
};

const handleCancelExport = async ({ getState, setState, params }) => {
  const id = _get(params, 'value.id');
  const response = await cancelExport(id);
  if (!_isEmpty(response)) {
    fetchAllRequests({ getState, setState });
  }
};

const handleRetriggerExport = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const exportData = _get(params, 'value');
  const requestBody = {
    fileFormat: readers.downloadTypeReader(exportData),
    entityName: readers.exportTemplateEntityNameReader(exportData),
    viewName: _get(exportData, 'manualInput.viewName'),
  };
  const response = await retriggerExport(requestBody);
  if (!_isEmpty(response)) {
    fetchAllRequests({ getState, setState });
  }
};

const handleRefreshListView = ({ getState, setState }) => {
  setState(
    {
      currentPage: 0,
    },
    () => {
      fetchAllRequests({ getState, setState });
    },
  );
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.DOWNLOAD_DATA]: handleDownloadData,
  [ACTION_TYPES.CANCEL_EXPORT]: handleCancelExport,
  [ACTION_TYPES.RETRIGGER_EXPORT]: handleRetriggerExport,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: fetchAllRequests,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleRefreshListView,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageChange,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleFilterChange,
};

export default ACTION_HANDLERS;
