exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".entityRecordRightPane_drawerHeaderContainer__etW2N969Jh{display:flex;align-items:center;justify-content:flex-start;height:6.4rem}[dir] .entityRecordRightPane_drawerHeaderContainer__etW2N969Jh{padding:1.6rem 3.2rem;background-color:#edeef0}[dir=ltr] .entityRecordRightPane_drawerHeaderContainer__etW2N969Jh .entityRecordRightPane_drawerHeadingLabel__szgG8vAtvA{margin-right:1rem}[dir=rtl] .entityRecordRightPane_drawerHeaderContainer__etW2N969Jh .entityRecordRightPane_drawerHeadingLabel__szgG8vAtvA{margin-left:1rem}.entityRecordRightPane_drawerBodyContainer__mjVTBqWg16{height:calc(100% - 6.4rem);overflow-y:scroll}[dir=ltr] .entityRecordRightPane_drawerBodyContainer__mjVTBqWg16{padding:2rem 0 0 1rem}[dir=rtl] .entityRecordRightPane_drawerBodyContainer__mjVTBqWg16{padding:2rem 1rem 0 0}[dir] .entityRecordRightPane_icon__3w3ScJozVf{margin:1rem;cursor:pointer}.entityRecordRightPane_icons__v9ECA3EtKc{display:flex;flex-direction:row;justify-content:flex-end}[dir=ltr] .entityRecordRightPane_icons__v9ECA3EtKc{margin:0 2rem 1rem 0}[dir=rtl] .entityRecordRightPane_icons__v9ECA3EtKc{margin:0 0 1rem 2rem}.entityRecordRightPane_viewViewer__iU8Btc1yxY{height:100%;width:100%}[dir] .entityRecordRightPane_viewViewer__iU8Btc1yxY{margin-bottom:3rem}", ""]);

// Exports
exports.locals = {
	"drawerHeaderContainer": "entityRecordRightPane_drawerHeaderContainer__etW2N969Jh",
	"drawerHeadingLabel": "entityRecordRightPane_drawerHeadingLabel__szgG8vAtvA",
	"drawerBodyContainer": "entityRecordRightPane_drawerBodyContainer__mjVTBqWg16",
	"icon": "entityRecordRightPane_icon__3w3ScJozVf",
	"icons": "entityRecordRightPane_icons__v9ECA3EtKc",
	"viewViewer": "entityRecordRightPane_viewViewer__iU8Btc1yxY"
};