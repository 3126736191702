import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import applicationManagementServices from '../services/application.services';

const getApplicationList = async (payload = EMPTY_OBJECT) => {
  try {
    const receivedData = await applicationManagementServices.getApplicationList(payload);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch applications, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createApplication = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await applicationManagementServices.createApplication(payload);
    const responseData = getDataFromResponse(response);
    return responseData;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, __('Failed to create application, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateApplication = async (name = EMPTY_STRING, payload = EMPTY_OBJECT) => {
  try {
    const response = await applicationManagementServices.updateApplication(name, payload);
    const responseData = getDataFromResponse(response);
    toaster(TOASTER_TYPE.SUCCESS, __('Application updated successfully.'));
    return responseData;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, __('Failed to update application, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getApplication = async (name = EMPTY_STRING) => {
  try {
    const response = await applicationManagementServices.getApplicationDetails(name);
    const responseData = getDataFromResponse(response);
    return responseData;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, __('Failed to get application details, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export { getApplicationList, createApplication, updateApplication, getApplication };
