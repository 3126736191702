const PUSH_NOTIFICATION_FIELD_IDS = {
  ACTION_TYPE: 'actionType',
  RECEIVER_IDS: 'receiverIds',
  PUSHER_ENTITY_NAME: 'pusherEntityName',
  APPLICATION_NAME: 'appName',
  PUSHER_RECORD_ID: 'pusherRecordId',
  TEMPLATES: 'templates',
};

export default PUSH_NOTIFICATION_FIELD_IDS;
