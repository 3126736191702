import ROUTES from '../../../../constants/routes';

const DATA_IMPORT_NAVIGATION_DATA = [{ label: __('Data Import'), goTo: ROUTES.IMPORT_DATA_LIST_ROUTE, key: '1' }];

const IMPORT_TASK_IDS = {
  ID: 'id',
  BATCH_SIZE: 'batchSize',
  MEDIA_ID: 'mediaId',
};

const IMPORT_BATCH_COLUMN_IDS = {
  ID: 'id',
  IMPORT_TASK_ID: 'importTaskId',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  RECORDS_PROCESSED: 'recordsProcessed',
  RECORDS_FAILED: 'recordsFailed',
  VIEW_REQUEST: 'viewRequest',
  VIEW_RESULT: 'viewResult',
  TASK_STATUS: 'taskStatus',
  RAW_DATA_MEDIA_ID: 'rawDataMediaId',
  RESPONSE_MEDIA_ID: 'responseMediaId',
};

const IMPORT_BATCH_CARD_DETAIL_IDS = {
  QUEUED_BATCHES: 'queuedBatches',
  IN_PROGRESS_BATCHES: 'inProgressBatches',
  COMPLETED_BATCHES: 'completedBatches',
  FAILED_BATCHES: 'failedBatches',
  PROGRESS: 'progress',
  RECORDS_PROCESSED: 'recordsProcessed',
  RECORDS_FAILED: 'recordsFailed',
  STATUS: 'status',
};

const TABLE_MANAGER_PROPS = {
  showHeader: false,
  showSearch: false,
  showSubHeader: true,
  showRefreshIcon: true,
  showFilter: false,
};

const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

export { DATA_IMPORT_NAVIGATION_DATA, TABLE_MANAGER_PROPS, TABLE_CONSTANTS, IMPORT_TASK_IDS, IMPORT_BATCH_COLUMN_IDS, IMPORT_BATCH_CARD_DETAIL_IDS };
