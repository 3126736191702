const GENERAL_EVENT_NAMES = {
  ACTION_BUILDER_EVENT: 'ACTION_BUILDER_EVENT',
};

const ADDITIONAL_PAYLOAD_IDS_BY_GENERAL_EVENT_NAME = {
  [GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT]: {
    ENTITY_NAME: 'entityName',
    ENTITY_RECORD_ID: 'entityRecordId',
  },
};

export { GENERAL_EVENT_NAMES, ADDITIONAL_PAYLOAD_IDS_BY_GENERAL_EVENT_NAME };
