import React from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import AntCheckBox from '@tekion/tekion-components/atoms/checkbox';

const Options = ({ label, isSelected, ...restProps }) => (
  <div>
    <components.Option {...restProps}>
      <AntCheckBox label={label} value={isSelected} onChange={_noop} />
    </components.Option>
  </div>
);

Options.propTypes = {
  label: PropTypes.string,
  isSelected: PropTypes.bool,
};

Options.defaultProps = {
  label: EMPTY_STRING,
  isSelected: false,
};

export default React.memo(Options);
