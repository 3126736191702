import compose from 'recompose/compose';
import _capitalize from 'lodash/capitalize';
import _property from 'lodash/property';

import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

// Constants
import { NO_DATA } from '@tekion/tekion-base/app.constants';
import COLUMN_IDS from './fieldList.columnIds';
import { ACTION_TYPES } from './fieldList.actionTypes';
import { FIELD_STATUS_COLOR_MAP, FIELD_STATUS_LABEL_MAP } from './fieldList.constants';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const getStatusProps = () => ({
  colorMap: FIELD_STATUS_COLOR_MAP,
  labelMap: FIELD_STATUS_LABEL_MAP,
});

const capitalizeValue = (value) => _capitalize(value) || NO_DATA;

const COLUMN_CONFIG = [
  {
    Header: __('Display name'),
    accessor: COLUMN_IDS.DISPLAY_NAME,
    id: COLUMN_IDS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Name'),
    accessor: COLUMN_IDS.NAME,
    id: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Field type'),
    accessor: compose(capitalizeValue, _property(COLUMN_IDS.FIELD_TYPE)),
    id: COLUMN_IDS.FIELD_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Data type'),
    accessor: compose(capitalizeValue, _property(COLUMN_IDS.DATA_TYPE)),
    id: COLUMN_IDS.DATA_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Creatable'),
    accessor: COLUMN_IDS.CREATABLE,
    id: COLUMN_IDS.CREATABLE,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Editable'),
    accessor: COLUMN_IDS.EDITABLE,
    id: COLUMN_IDS.EDITABLE,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Importable'),
    accessor: COLUMN_IDS.IMPORTABLE,
    id: COLUMN_IDS.IMPORTABLE,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Exportable'),
    accessor: COLUMN_IDS.EXPORTABLE,
    id: COLUMN_IDS.EXPORTABLE,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Mandatory'),
    accessor: COLUMN_IDS.MANDATORY,
    id: COLUMN_IDS.MANDATORY,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Must be unique'),
    accessor: COLUMN_IDS.UNIQUE_CONSTRAINT,
    id: COLUMN_IDS.UNIQUE_CONSTRAINT,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('List view enabled'),
    accessor: COLUMN_IDS.LIST_VIEW_ENABLED,
    id: COLUMN_IDS.LIST_VIEW_ENABLED,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Detail view enabled'),
    accessor: COLUMN_IDS.DETAILED_VIEW_ENABLED,
    id: COLUMN_IDS.DETAILED_VIEW_ENABLED,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Searchable'),
    accessor: COLUMN_IDS.SEARCHABLE,
    id: COLUMN_IDS.SEARCHABLE,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Sortable'),
    accessor: COLUMN_IDS.SORTABLE,
    id: COLUMN_IDS.SORTABLE,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Sort direction'),
    accessor: compose(capitalizeValue, _property(COLUMN_IDS.SORT_DIRECTION)),
    id: COLUMN_IDS.SORT_DIRECTION,
    Cell: TextRenderer,
  },
];

const EDIT_FIELD_DETAIL = {
  id: ACTION_TYPES.EDIT_FIELD_DETAIL,
  name: __('Edit Attribute'),
};

const ROW_ACTIONS = [EDIT_FIELD_DETAIL];

export { COLUMN_CONFIG, ROW_ACTIONS };
