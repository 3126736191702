import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _size from 'lodash/size';

import { tget } from '@tekion/tekion-base/utils/general';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import EntitySettingsIconRenderer from '../../../atoms/entitySettingsIconRenderer/EntitySettingsIconRenderer';
import ACTION_TYPES from '../constants/entitySettings.actionTypes';

import { COLUMN_IDS, FIELD_IDS } from '../constants/entitySettings.constants';
import styles from '../entitySettings.module.scss';

const EntitySettingsAccessIconRenderer = makeCellRenderer(EntitySettingsIconRenderer);

const getEntityDisplayName = (resolvedEntityNamesToDisplayNameMap) => (rowInfo) => {
  const entityName = _get(rowInfo, [FIELD_IDS.ENTITY_ACCESS, COLUMN_IDS.ENTITY_NAME]);

  return tget(resolvedEntityNamesToDisplayNameMap, entityName, entityName);
};

const getReadPermission = (rowInfo) => {
  const actionTypes = _get(rowInfo, [FIELD_IDS.ENTITY_ACCESS, FIELD_IDS.ACTION_TYPES]);

  return _includes(actionTypes, COLUMN_IDS.READ);
};

const getCreatePermission = (rowInfo) => {
  const actionTypes = _get(rowInfo, [FIELD_IDS.ENTITY_ACCESS, FIELD_IDS.ACTION_TYPES]);

  return _includes(actionTypes, COLUMN_IDS.CREATE);
};

const getEditPermission = (rowInfo) => {
  const actionTypes = _get(rowInfo, [FIELD_IDS.ENTITY_ACCESS, FIELD_IDS.ACTION_TYPES]);

  return _includes(actionTypes, COLUMN_IDS.UPDATE);
};

const getDeletePermission = (rowInfo) => {
  const actionTypes = _get(rowInfo, [FIELD_IDS.ENTITY_ACCESS, FIELD_IDS.ACTION_TYPES]);

  return _includes(actionTypes, COLUMN_IDS.DELETE);
};

const getTotalFieldsAccessor = (rowInfo) => _size(_get(rowInfo, FIELD_IDS.FIELD_ACCESS_MAP));

const getColumnConfig = (resolvedEntityNamesToDisplayNameMap) => [
  {
    Header: __('Entity Name'),
    accessor: getEntityDisplayName(resolvedEntityNamesToDisplayNameMap),
    id: COLUMN_IDS.ENTITY_NAME,
    Cell: TextRenderer,
    className: styles.entitySettingsColumn,
  },
  {
    Header: __('Read'),
    accessor: getReadPermission,
    id: COLUMN_IDS.READ,
    Cell: EntitySettingsAccessIconRenderer,
    className: styles.entitySettingsColumn,
  },
  {
    Header: __('Create'),
    accessor: getCreatePermission,
    id: COLUMN_IDS.CREATE,
    Cell: EntitySettingsAccessIconRenderer,
    className: styles.entitySettingsColumn,
  },
  {
    Header: __('Edit'),
    accessor: getEditPermission,
    id: COLUMN_IDS.UPDATE,
    Cell: EntitySettingsAccessIconRenderer,
    className: styles.entitySettingsColumn,
  },
  {
    Header: __('Delete'),
    accessor: getDeletePermission,
    id: COLUMN_IDS.DELETE,
    Cell: EntitySettingsAccessIconRenderer,
    className: styles.entitySettingsColumn,
  },
  {
    Header: __('Total Fields'),
    accessor: getTotalFieldsAccessor,
    id: COLUMN_IDS.TOTAL_FIELDS,
    Cell: TextRenderer,
    className: styles.entitySettingsColumn,
  },
];
const EDIT_ACTION = {
  id: ACTION_TYPES.EDIT,
  name: __('Edit'),
};

const ROW_ACTIONS = [EDIT_ACTION];

export { getColumnConfig, ROW_ACTIONS };
