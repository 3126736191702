// Lodash
import _curry from 'lodash/curry';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';
import _forEach from 'lodash/forEach';
import _uniq from 'lodash/uniq';
import _compact from 'lodash/compact';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
// Constants
import { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Utils
import resolver from '@tekion/tekion-base/bulkResolvers';
import { TOASTER_TYPE, toaster } from '@tekion/tekion-components/organisms/NotificationWrapper';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';

import { makeAuditAssetDTO, makeAuditByAssetTypeDTO, makeAuditParentAssetDTO, makeBulkAuditAssetDTO } from './auditLogs.request';
import auditLogServices from '../../../../../services/auditLog.services';
import { fetchEntityRecords } from '../../../../../actions/recordManagement.actions';
import { getMasterEntityDef } from '../../../../../actions/entityManagement.actions';
import { getResourcesToBeResolved } from '../../../resolvers/auditLogsEntity';

import { getAllRecordIdsToFetch, resolvedValues } from './auditLogs.helpers';
import { INITIAL_STATE } from '../constants/auditLogs.general';
import ACTION_TYPES from '../constants/auditLogs.actionTypes';

const handleAuditLogsResolvedSuccess = _curry(async ({ setState, getState, nextPageToken, nextPageTokenMap }, activitiesResolvedResponse) => {
  const { activities = EMPTY_ARRAY, callback = _noop } = getState();
  let updatedActivities = [...activities, ...activitiesResolvedResponse];
  const { assetType } = getState();
  const entityDef = await getMasterEntityDef(assetType);
  const recordIdsByEntityName = getAllRecordIdsToFetch(updatedActivities, entityDef);
  const promises = [];
  _forEach(recordIdsByEntityName, (value, key) => {
    promises.push(
      fetchEntityRecords(key, {
        filters: [
          {
            field: 'id',
            values: _compact(_uniq(value)),
            filterType: OPERATORS.IN,
          },
        ],
      }),
    );
  });

  const records = await Promise.all(promises);
  let recordsByEntityName = {};
  _forEach(records, (record) => {
    const hits = tget(record, 'hits', EMPTY_ARRAY);
    if (!_isEmpty(hits)) {
      const entityName = _get(getArraySafeValue(hits), 'entityName');
      recordsByEntityName = { ...recordsByEntityName, [entityName]: _keyBy(hits, 'id') };
    }
  });

  updatedActivities = resolvedValues(updatedActivities, entityDef, recordsByEntityName);

  setState(
    {
      pageToken: nextPageToken,
      nextPageTokenMap,
      isFetchingAuditLogs: false,
      activities: updatedActivities,
      entityDef,
    },
    () => callback(activitiesResolvedResponse),
  );
});

const handleAuditLogsResolvedError =
  ({ setState, getState, nextPageToken, nextPageTokenMap }, activitiesResponse) =>
  () => {
    handleAuditLogsResolvedSuccess(
      {
        setState,
        getState,
        nextPageToken,
        nextPageTokenMap,
      },
      activitiesResponse,
    );
  };

const handleFetchAuditLogsSuccess = _curry((setState, getState, auditLogsResponse) => {
  const { resultList, nextPageToken = EMPTY_STRING, nextPageTokens: nextPageTokenMap = EMPTY_OBJECT } = auditLogsResponse || EMPTY_OBJECT;
  const { auditParams } = getState();
  const { localLookup, additionalPathsAndResourcesToBeResolved } = auditParams || EMPTY_OBJECT;

  return resolver
    .getResolvedData('', resultList, {
      localLookup,
      entityAdditionalProps: {
        getResourcesToBeResolved: getResourcesToBeResolved(additionalPathsAndResourcesToBeResolved),
      },
    })
    .then(
      handleAuditLogsResolvedSuccess({
        setState,
        getState,
        nextPageToken,
        nextPageTokenMap,
      }),
    )
    .catch(
      handleAuditLogsResolvedError(
        {
          setState,
          getState,
          nextPageToken,
          nextPageTokenMap,
        },
        resultList,
      ),
    );
});

const handleFetchAuditLogsError = (setState) => () => {
  setState({
    isFetchingAuditLogs: false,
    pageToken: undefined,
  });
  toaster(TOASTER_TYPE.ERROR, __('Could not fetch audit logs.'));
};

const getAuditDTOCreator = (isParentAsset, isBulkAudit, fetchByAssetType) => {
  if (fetchByAssetType) return makeAuditByAssetTypeDTO;

  if (isBulkAudit) return makeBulkAuditAssetDTO;

  return isParentAsset ? makeAuditParentAssetDTO : makeAuditAssetDTO;
};

const getAuditService = () => auditLogServices.fetchAuditLogs;

const handleFetchAuditLog = ({ getState, setState }) => {
  const {
    assetId,
    assetType,
    pageToken,
    nextPageTokenMap,
    auditParams: {
      isParentAsset = false,
      isBulkAudit,
      fetchByAssetType = false,
      additionalBulkAuditAssets,
      useCustomAuditLogs,
      getCustomAuditLogs = _noop,
    },
  } = getState();

  if (!(assetId && assetType)) {
    setState({ isFetchingAuditLogs: false });
    return;
  }
  const auditDTOCreator = getAuditDTOCreator(isParentAsset, isBulkAudit, fetchByAssetType);

  const auditLogsPayload = auditDTOCreator({
    assetId,
    assetType,
    pageToken,
    additionalBulkAuditAssets,
    nextPageTokenMap,
  });

  setState({ isFetchingAuditLogs: true });

  if (useCustomAuditLogs) {
    getCustomAuditLogs(auditLogsPayload)
      .then(getDataFromResponse)
      .then(handleFetchAuditLogsSuccess(setState, getState))
      .catch(handleFetchAuditLogsError(setState));
    return;
  }
  const auditService = getAuditService(isParentAsset, isBulkAudit, fetchByAssetType);

  const payload = { ...auditLogsPayload, pageToken: '' };

  auditService(payload).then(getDataFromResponse).then(handleFetchAuditLogsSuccess(setState, getState)).catch(handleFetchAuditLogsError(setState));
};

const handleResetAuditLog = ({ setState }) => {
  setState(INITIAL_STATE);
};

const handleActivityReset = ({ getState, setState }) => {
  setState(INITIAL_STATE, () => {
    handleFetchAuditLog({ getState, setState });
  });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.FETCH_AUDIT_LOG]: handleFetchAuditLog,
  [ACTION_TYPES.RESET_AUDIT_LOG]: handleResetAuditLog,
  [ACTION_TYPES.RESET_ACTIVITES]: handleActivityReset,
};

export default ACTION_HANDLERS;
