import _flow from 'lodash/flow';
import _property from 'lodash/property';
import _mapValues from 'lodash/mapValues';

import { shareAndManageRecordContextGetters } from '../hooks/useShareAndManageRecordModal';

import { ACTION_EXECUTION_MODAL_CONTEXT_KEYS } from '../constants/actionExecutionModalProvider.constants';

const createActionExecutionModalContextValue = (shareAndManageContextValues) => ({
  [ACTION_EXECUTION_MODAL_CONTEXT_KEYS.SHARE_AND_MANAGE_RECORD_ACTION_MODAL]: shareAndManageContextValues,
});

const getShareAndManageRecordContextValue = _property(ACTION_EXECUTION_MODAL_CONTEXT_KEYS.SHARE_AND_MANAGE_RECORD_ACTION_MODAL);

const shareAndManageUpdatedGetters = _mapValues(shareAndManageRecordContextGetters, (shareAndManageGetter) =>
  _flow(getShareAndManageRecordContextValue, shareAndManageGetter),
);

const actionExecutionModalContextGetters = {
  ...shareAndManageUpdatedGetters,
};

export { createActionExecutionModalContextValue, actionExecutionModalContextGetters };
