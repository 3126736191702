import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getConfigFormattedSelectedWidgetProperties, getFormFormattedSelectedWidgetProperties } from './helpers/textPropertiesConfigure.helpers';
import TEXT_PROPERTIES_CONFIGURE_FIELDS from './helpers/textPropertiesConfigure.fields';
import { TEXT_PROPERTIES_CONFIGURE_SECTIONS } from './helpers/textPropertiesConfigure.sections';
import { ACTION_TYPES as VISUAL_BUILDER_ACTION_TYPES, COMPONENT_CONFIG_KEYS } from '../../../../constants/visualBuilder.general.constants';

const TextPropertiesConfigure = ({ selectedWidgetConfig, onChangeWidgetConfigurationAction }) => {
  const widgetName = useMemo(() => tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, ''), [selectedWidgetConfig]);
  const selectedWidgetProperties = useMemo(() => tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, {}), [selectedWidgetConfig]);
  const formFormattedSelectedWidgetProperties = getFormFormattedSelectedWidgetProperties(selectedWidgetProperties);

  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload = EMPTY_OBJECT } = action;
    switch (type) {
      case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
        const { id, value } = payload;
        const configFormattedSelectedWidgetProperties = getConfigFormattedSelectedWidgetProperties(value, id, selectedWidgetProperties);

        onChangeWidgetConfigurationAction({
          type: VISUAL_BUILDER_ACTION_TYPES.UPDATE_WIDGET_CONFIGURATION,
          payload: {
            value: { widgetConfiguration: { ...selectedWidgetConfig, properties: { ...configFormattedSelectedWidgetProperties } }, widgetName },
          },
        });
        break;
      }
      default:
    }
  };

  return (
    <FormBuilder
      sections={TEXT_PROPERTIES_CONFIGURE_SECTIONS}
      fields={TEXT_PROPERTIES_CONFIGURE_FIELDS}
      values={formFormattedSelectedWidgetProperties}
      onAction={handleAction}
    />
  );
};

TextPropertiesConfigure.propTypes = {
  selectedWidgetConfig: PropTypes.object,
  onChangeWidgetConfigurationAction: PropTypes.func,
};

TextPropertiesConfigure.defaultProps = {
  selectedWidgetConfig: undefined,
  onChangeWidgetConfigurationAction: _noop,
};

export default TextPropertiesConfigure;
