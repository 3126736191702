import produce from 'immer';

import _find from 'lodash/find';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _keys from 'lodash/keys';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { TASK_VALUES } from '@tekion/tekion-rule-engine/src/constants/ruleEngineWorkflow.taskTypes';
import { isConditionNode, isLoopNode } from '@tekion/tekion-rule-engine';

import { deleteByConditions } from './deleteNodeModal.helpers';
import { FIELD_IDS } from './deleteNodeModal.constants';

const handleInit = ({ setState, getState }) => {
  const { stepDetails } = getState();
  let shouldShowRadio = false;
  let options = [];

  // For Condition Nodes
  if (isConditionNode(stepDetails)) {
    shouldShowRadio = true;
    const childList = _get(stepDetails, 'nodesByConditionValue', {});
    const keys = _keys(childList);
    options = deleteByConditions(keys, true);
  } else if (isLoopNode(stepDetails)) {
    // For Loop Nodes
    shouldShowRadio = true;
    const keys = [TASK_VALUES.NO];
    options = deleteByConditions(keys, true);
  }

  setState({ shouldShowRadio, options });
};

const handleOnFieldChange = ({ params, setState }) => {
  const { id, value } = params;

  setState(
    produce((draft) => {
      _set(draft, `values.${id}`, value);
    }),
  );
};

const handleSubmit = ({ getState }) => {
  const { onSubmit, values, options } = getState();

  onSubmit({ values, selectedOption: _find(options, (opt) => opt.value === values[FIELD_IDS.SELECTED_OPTION]) });
};

const handleErrors = ({ setState, params }) => {
  const { errors } = params;
  setState({ errors });
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnFieldChange,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
};

export default ACTION_HANDLERS;
