import _get from 'lodash/get';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FIELD_IDS } from './recordTypeDetailForm.fields';
import { RECORD_TYPES } from '../../../../../constants/general.constants';

import styles from '../recordTypeDetailForm.module.scss';

const rowCheck = {
  [FIELD_IDS.DERIVATION_FIELDS]: ({ formValues }) => _get(formValues, FIELD_IDS.RECORD_TYPE) === RECORD_TYPES.DERIVED,
};

const getSections = (formValues) => [
  {
    rows: [
      {
        className: styles.formContainer,
        columns: [FIELD_IDS.RECORD_TYPE],
      },
      {
        columns: filterRows([FIELD_IDS.DERIVATION_FIELDS], { formValues }, rowCheck),
      },
    ],
  },
];

export { getSections };
