import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';

import WithSize from '../../../../../connectors/withSize';

import ACTION_HANDLERS from './helpers/dataImportList.actionHandlers';
import COLUMN_CONFIG from './helpers/dataImportList.columns';
import { getFilterProps, createTableProps } from './helpers/dataImportList.helpers';

import { HEADER_PROPS, INITIAL_STATE_FOR_DATA_IMPORT_LIST, SUB_HEADER_PROPS, TABLE_MANAGER_PROPS } from './constants/dataImportList.constants';

const DataImportList = ({ isLoading, currentPage, pageSize, contentHeight, totalCount, nextPageToken, tableData, selectedFilters, onAction }) => {
  const tableProps = useMemo(
    () => createTableProps(isLoading, currentPage, pageSize, totalCount, onAction),
    [isLoading, currentPage, pageSize, totalCount, onAction],
  );
  const filterProps = useMemo(() => getFilterProps(selectedFilters), [selectedFilters]);

  return (
    <Page style={{ height: contentHeight }}>
      <TableManager
        tokenPagination
        nextPageToken={nextPageToken}
        headerProps={HEADER_PROPS}
        subHeaderProps={SUB_HEADER_PROPS}
        tableManagerProps={TABLE_MANAGER_PROPS}
        tableProps={tableProps}
        columns={COLUMN_CONFIG}
        data={tableData}
        filterProps={filterProps}
        onAction={onAction}
      />
    </Page>
  );
};

DataImportList.propTypes = {
  isLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  nextPageToken: PropTypes.string,
  tableData: PropTypes.array,
  selectedFilters: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

DataImportList.defaultProps = {
  isLoading: false,
  currentPage: DEFAULT_PAGE_INFO.start,
  pageSize: DEFAULT_PAGE_SIZE,
  totalCount: 0,
  nextPageToken: EMPTY_STRING,
  tableData: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
};

export default compose(
  WithSize({ hasPageFooter: 0, hasPageHeader: 0 }),
  withActions(INITIAL_STATE_FOR_DATA_IMPORT_LIST, ACTION_HANDLERS),
)(DataImportList);
