import _dropWhile from 'lodash/dropWhile';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _filter from 'lodash/filter';
import _startCase from 'lodash/startCase';
import _camelCase from 'lodash/camelCase';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _castArray from 'lodash/castArray';
import _reduce from 'lodash/reduce';
import _isObject from 'lodash/isObject';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { fetchEntityDefByName } from '../actions/entityManagement.actions';
import { fetchEntityRecords } from '../actions/recordManagement.actions';

import { INVALID_FILTER_VALUES } from '../constants/filter.constants';
import DATA_TYPES from '../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../constants/fieldDefinition.fieldTypes';

import entityReader from '../readers/entity.reader';
import fieldDefinitionReader from '../readers/fieldDefinition.reader';

// This function will take array of filters and remove filterObj with empty Values
// [
//     {
//         field: 'id',
//         values: [],
//         filterType:'IN'
//     }
// ]
const getCompactedFiltersPayload = (filters) =>
  _dropWhile(filters, (filter) => {
    const values = _get(filter, 'values');
    const validFilterValues = _filter(values, (value) => !_includes(INVALID_FILTER_VALUES, value));
    if (_isEmpty(validFilterValues)) return true;
    return false;
  });

const pascalCase = (str) => _startCase(_camelCase(str));

// variablesValueToResolve: Array with variableName excluding $record
// (i.e for example we want to resolve $record.QuestionId.title then variableName: [QuestionId,title])
const resolveVariableDataFromRecordData = async (variableValueToResolve, index, recordData, entityDef) => {
  if (index >= _size(variableValueToResolve)) {
    return;
  }

  const fieldName = variableValueToResolve[index];
  const fieldDefs = entityReader.fieldDefinitions(entityDef);
  const fieldDefFromFieldName = _find(fieldDefs, { name: fieldName });

  const fieldType = fieldDefinitionReader.fieldType(fieldDefFromFieldName);
  const dataType = fieldDefinitionReader.dataType(fieldDefFromFieldName);

  if ((fieldType !== FIELD_TYPES.RELATIONSHIP && dataType !== DATA_TYPES.COMPLEX) || index + 1 === _size(variableValueToResolve)) {
    // eslint-disable-next-line consistent-return
    return tget(recordData, fieldName, tget(recordData, ['entity', fieldName], EMPTY_STRING));
  } else if (fieldType === FIELD_TYPES.RELATIONSHIP) {
    const value = tget(recordData, ['entity', fieldName], EMPTY_STRING);
    const lookupEntityName = fieldDefinitionReader.lookupFieldEntityType(fieldDefFromFieldName);
    const lookupFieldName = fieldDefinitionReader.lookupField(fieldDefFromFieldName);

    const newRecordToFetchPayload = { filters: [{ field: lookupFieldName, filterType: OPERATORS.IN, values: _castArray(value) }] };

    const [lookupEntityDef, newRecordDataResponse] = await Promise.all([
      fetchEntityDefByName(lookupEntityName),
      fetchEntityRecords(lookupEntityName, newRecordToFetchPayload),
    ]);
    const newRecordData = getArraySafeValue(tget(newRecordDataResponse, 'hits', [{}]));

    // eslint-disable-next-line consistent-return
    return resolveVariableDataFromRecordData(variableValueToResolve, index + 1, newRecordData, lookupEntityDef);
  } else if (dataType === DATA_TYPES.COMPLEX) {
    const value = tget(recordData, ['entity', fieldName], {});
    const newRecordData = { ...recordData, entity: { ...value } };

    const complexEntityName = fieldDefinitionReader.complexFieldDefinitionEntityName(fieldDefFromFieldName);
    const newEntityDef = await fetchEntityDefByName(complexEntityName);

    // eslint-disable-next-line consistent-return
    return resolveVariableDataFromRecordData(variableValueToResolve, index + 1, newRecordData, newEntityDef);
  }
};

const getFlattenedObject = (inputObject) =>
  _reduce(
    inputObject,
    (prevFlattenedObject, currentValue, currentKey) => {
      if (_isObject(currentValue)) {
        const flattenedConfiguration = getFlattenedObject(currentValue);
        return { ...prevFlattenedObject, ...flattenedConfiguration };
      }

      return { ...prevFlattenedObject, [currentKey]: currentValue };
    },
    {},
  );

export { getCompactedFiltersPayload, pascalCase, resolveVariableDataFromRecordData, getFlattenedObject };
