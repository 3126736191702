exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".subscriptionConfigLogDetails_subscriptionConfigLogs__2XQ694Y5vG{display:flex;flex-direction:column}[dir] .subscriptionConfigLogDetails_subscriptionConfigLogs__2XQ694Y5vG{margin:2rem;padding:2rem;box-shadow:0 .2rem .6rem #969aa3;border-radius:.8rem;border:0}.subscriptionConfigLogDetails_icon__fVe6hK8z6H{color:#969aa3}[dir] .subscriptionConfigLogDetails_icon__fVe6hK8z6H{cursor:pointer}[dir=ltr] .subscriptionConfigLogDetails_icon__fVe6hK8z6H{padding-left:.8rem}[dir=rtl] .subscriptionConfigLogDetails_icon__fVe6hK8z6H{padding-right:.8rem}.subscriptionConfigLogDetails_logFieldContainer__4f3N54ifk7{display:flex;align-items:center}.subscriptionConfigLogDetails_logLabel__7LSBCdR84s{color:#969aa3}[dir] .subscriptionConfigLogDetails_logLabel__7LSBCdR84s{padding:.4rem}.subscriptionConfigLogDetails_logValue__evHiG7UamZ{max-height:25rem;overflow:scroll}[dir] .subscriptionConfigLogDetails_logValue__evHiG7UamZ{padding:.4rem}[dir=ltr] .subscriptionConfigLogDetails_logValue__evHiG7UamZ{margin-left:2rem}[dir=rtl] .subscriptionConfigLogDetails_logValue__evHiG7UamZ{margin-right:2rem}", ""]);

// Exports
exports.locals = {
	"subscriptionConfigLogs": "subscriptionConfigLogDetails_subscriptionConfigLogs__2XQ694Y5vG",
	"icon": "subscriptionConfigLogDetails_icon__fVe6hK8z6H",
	"logFieldContainer": "subscriptionConfigLogDetails_logFieldContainer__4f3N54ifk7",
	"logLabel": "subscriptionConfigLogDetails_logLabel__7LSBCdR84s",
	"logValue": "subscriptionConfigLogDetails_logValue__evHiG7UamZ"
};