import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _has from 'lodash/has';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchEntities } from '../../../../../actions/entityManagement.actions';
import { searchViewConfigurations } from '../../../../../actions/viewBuilderPage.actions';
import { getJob, createJob, updateJob, getTimeZones } from '../../../../../actions/jobSchedule.actions';

import { generatePayloadFromFormValues, getPayloadForSearchViews, generateFormValuesFromApiResponse } from './jobScheduleForm.helpers';

import ACTION_TYPES from '../constants/jobScheduleForm.actionTypes';
import { FORM_MODES } from '../../../../../constants/general.constants';
import FIELD_IDS from '../constants/jobScheduleForm.fieldIds';
import ROUTES from '../../../constants/routes';

import entityReader from '../../../../../readers/entity.reader';

const handleInit = async ({ getState, setState }) => {
  const { match } = getState();
  const jobScheduleId = _get(match, 'params.jobScheduleId');
  const response = await fetchEntities({ start: 0, rows: 5000 });
  const entitiesData = tget(response, 'hits', EMPTY_ARRAY);
  const entityNameOptions = _map(entitiesData, (data) => ({ label: entityReader.displayName(data), value: entityReader.name(data) }));

  const timeZoneResponse = await getTimeZones();
  const timeZones = _get(timeZoneResponse, 'timezones', EMPTY_ARRAY);
  const timeZoneOptions = _map(timeZones, (timeZone) => ({ label: __(timeZone), value: timeZone }));

  if (!_isEmpty(jobScheduleId)) {
    setState({ formMode: FORM_MODES.EDIT, isFetching: true });
    const apiResponse = await getJob(jobScheduleId);
    const formValues = generateFormValuesFromApiResponse(apiResponse);
    setState({ isFetching: false, formValues });
  } else {
    setState({ formMode: FORM_MODES.CREATE });
  }

  setState({ entityNameOptions, timeZoneOptions });
};

const handleFieldChange = ({ params, getState, setState }) => {
  const { id, value } = params;
  const { formValues } = getState();
  let updatedValues = { ...formValues, [id]: value };

  if (id === FIELD_IDS.ENTITY_NAME) {
    updatedValues = { ...updatedValues, [FIELD_IDS.VIEW_NAME]: [] };
  }

  setState({ formValues: updatedValues });
};

const handleErrors = ({ params, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const handleSubmit = async ({ getState, setState }) => {
  const { formMode, formValues, history, match } = getState();
  const jobScheduleId = _get(match, 'params.jobScheduleId');
  const payload = generatePayloadFromFormValues(formValues);

  setState({ isSubmitting: true });

  if (formMode === FORM_MODES.CREATE) {
    const response = await createJob(payload);
    if (!_isEmpty(response)) {
      setTimeout(() => {
        history.push(ROUTES.JOB_SCHEDULE_LIST_ROUTE);
      }, ES_REFETCH_DELAY);
    }
  } else if (formMode === FORM_MODES.EDIT) {
    const response = await updateJob(jobScheduleId, payload);
    if (!_isEmpty(response)) {
      setTimeout(() => {
        history.push(ROUTES.JOB_SCHEDULE_LIST_ROUTE);
      }, ES_REFETCH_DELAY);
    }
  }

  setState({ isSubmitting: false });
};

const handleEntityChange = async ({ params, getState, setState }) => {
  const selectedEntityName = params;
  const { viewOptionsDefsStore = EMPTY_OBJECT } = getState();
  let viewOptions = [];

  if (_has(viewOptionsDefsStore, selectedEntityName)) {
    viewOptions = viewOptionsDefsStore[selectedEntityName];
  } else {
    const payload = getPayloadForSearchViews(_castArray(selectedEntityName));
    const response = await searchViewConfigurations(payload);
    const views = tget(response, 'hits', EMPTY_ARRAY);
    viewOptions = _map(views, (view) => ({ label: _get(view, 'properties.title', EMPTY_STRING), value: _get(view, 'id') }));
    setState({ viewOptionsDefsStore: { ...viewOptionsDefsStore, [selectedEntityName]: viewOptions } });
  }

  setState({ viewOptions });
};

const handleOnCancel = ({ getState }) => {
  const { history } = getState();
  history.push(ROUTES.JOB_SCHEDULE_LIST_ROUTE);
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_ENTITY_CHANGE]: handleEntityChange,
  [ACTION_TYPES.ON_CANCEL]: handleOnCancel,
};

export default ACTION_HANDLERS;
