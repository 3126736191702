import { FORM_MODES } from '../../../../../../constants/general.constants';

const ACTION_TYPES = {
  ON_SELECTED_WIDGET_COMPONENT_PROPERTIES_CHANGE: 'ON_SELECTED_WIDGET_COMPONENT_PROPERTIES_CHANGE',
  ADD_ROW: 'ADD_ROW',
  DELETE_ROW: 'DELETE_ROW',
  ON_CHANGE: 'ON_CHANGE',
  ON_CLICK_ADD_FILTER: 'ON_CLICK_ADD_FILTER',
  ON_CLICK_EDIT_FILTER: 'ON_CLICK_EDIT_FILTER',
  ON_SAVE_MODAL: 'ON_SAVE_MODAL',
  ON_CANCEL_MODAL: 'ON_CANCEL_MODAL',
  OPEN_MODAL: 'OPEN_MODAL',
};

const FILTER_FIELD_LABEL_MAP = {
  FIELD: __('Field'),
  OPERATOR: __('Operator'),
  VALUE: __('Value'),
};

const FIELD_IDS = {
  PRE_APPLIED_FILTERS: 'preAppliedFilters',
  FILTER_LIST: 'filterList',
  FIELD_NAME: 'fieldName',
  FIELD: 'field',
  FILTER_TYPE: 'filterType',
  VALUES: 'values',
  DELETE: 'delete',
  DISABLED: 'disabled',
  RENDERER_PROPS: 'rendererProps',
};

const MODAL_TITLES = {
  [FORM_MODES.CREATE]: __('Add Filter'),
  [FORM_MODES.EDIT]: __('Edit Filter'),
};

const MODAL_BUTTON_TITLES = {
  [FORM_MODES.CREATE]: __('Done'),
  [FORM_MODES.EDIT]: __('Save'),
};

export { ACTION_TYPES, FIELD_IDS, FILTER_FIELD_LABEL_MAP, MODAL_TITLES, MODAL_BUTTON_TITLES };
