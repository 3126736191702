import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import { DATE_TIME_FORMATS, getFormattedDateTime } from '@tekion/tekion-base/utils/dateUtils';
import Heading from '@tekion/tekion-components/atoms/Heading/Heading';
import NullComponent from '@tekion/tekion-components/atoms/nullComponent/NullComponent';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import FieldValuePair from '../fieldValuePair/FieldValuePair';
import Approver from '../approver/Approver';
import ApproversDetailView from '../approversDetailView/ApproversDetailView';

import {
  APPROVAL_CENTRE_FIELD_IDS,
  APPROVAL_STATUS_LABELS,
  APPROVAL_STATUS_TYPES,
} from '../../../../../../../../../constants/approvalCentre.constants';

import { ASSET_TYPES } from '../../../../../../../../../constants/general.constants';

import styles from './stage.module.scss';

const Stage = ({ index, stage }) => {
  const [isApproverDetailVisible, setIsApproverDetailVisible] = useState(false);

  const minimumApprovals = tget(stage, APPROVAL_CENTRE_FIELD_IDS.MIN_APPROVALS);
  const stageApprovers = tget(stage, APPROVAL_CENTRE_FIELD_IDS.APPROVERS);
  const taskDetails = tget(stage, APPROVAL_CENTRE_FIELD_IDS.TASK, EMPTY_OBJECT);
  const eligibleApprovers = tget(taskDetails, APPROVAL_CENTRE_FIELD_IDS.ELIGIBLE_APPROVERS, []);
  const allRemainingApprovers = useMemo(
    () => (_isEmpty(eligibleApprovers) ? stageApprovers : { [ASSET_TYPES.USER]: eligibleApprovers }),
    [eligibleApprovers, stageApprovers],
  );

  const status = tget(taskDetails, APPROVAL_CENTRE_FIELD_IDS.STATUS);
  const rejectors = tget(taskDetails, APPROVAL_CENTRE_FIELD_IDS.REJECTORS, []);
  const approvedApprovers = tget(taskDetails, APPROVAL_CENTRE_FIELD_IDS.APPROVERS, []);
  const validityEpoch = tget(taskDetails, APPROVAL_CENTRE_FIELD_IDS.VALIDITY, undefined);
  const validity = useMemo(
    () => (validityEpoch ? getFormattedDateTime(validityEpoch, DATE_TIME_FORMATS.MONTH_DATE_YEAR_TIME_12HR_FORMAT) : NO_DATA),
    [validityEpoch],
  );

  const renderApprovers = useCallback(
    (approvers) => (
      <div className={styles.approversContainer}>
        {_map(approvers, (approverId, approverIndex) => (
          <Approver key={approverIndex} approverId={approverId} taskDetails={taskDetails} />
        ))}
      </div>
    ),
    [taskDetails],
  );

  const handleExpandApproverDetails = () => {
    setIsApproverDetailVisible((prev) => !prev);
  };

  return (
    <div className={styles.stageContainer}>
      <div
        className={cx({
          [styles.completedContainer]: status === APPROVAL_STATUS_TYPES.COMPLETED,
          [styles.indexContainer]: status !== APPROVAL_STATUS_TYPES.COMPLETED,
        })}
      >
        <div
          className={cx(styles.index, {
            [styles.pending]: status === APPROVAL_STATUS_TYPES.PENDING,
            [styles.completed]: status === APPROVAL_STATUS_TYPES.COMPLETED,
            [styles.rejected]: status === APPROVAL_STATUS_TYPES.REJECTED,
            [styles.expired]: status === APPROVAL_STATUS_TYPES.CANCELLED || status === APPROVAL_STATUS_TYPES.EXPIRED || _isEmpty(status),
          })}
        >
          {__(`${index + 1}`)}
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.approvalStatusSection}>
            <Heading size={3}>{__('Stage - {{index}}', { index: index + 1 })}</Heading>
            {status && (
              <span
                className={cx(styles.statusTag, {
                  [styles.pending]: status === APPROVAL_STATUS_TYPES.PENDING,
                  [styles.completed]: status === APPROVAL_STATUS_TYPES.COMPLETED,
                  [styles.rejected]: status === APPROVAL_STATUS_TYPES.REJECTED,
                  [styles.expired]: status === APPROVAL_STATUS_TYPES.CANCELLED || status === APPROVAL_STATUS_TYPES.EXPIRED,
                })}
              >
                {APPROVAL_STATUS_LABELS[status]}
              </span>
            )}
          </div>
          <div className={styles.approvalRequestDetailSection}>
            <div className={styles.approvalMinimumApprovals}>
              <FieldValuePair icon="icon-checked-outlined" label={__('Minimum Approvals')} value={minimumApprovals} />
            </div>
            <FieldValuePair icon="icon-clock-in" label={__('Stage Validity')} value={validity} />
          </div>
        </div>

        {!_isEmpty(rejectors) && renderApprovers(rejectors)}
        {!_isEmpty(approvedApprovers) && renderApprovers(approvedApprovers)}

        {status !== APPROVAL_STATUS_TYPES.COMPLETED && status !== APPROVAL_STATUS_TYPES.REJECTED && (
          <>
            <FieldValuePair
              icon={isApproverDetailVisible ? 'icon-chevron-down' : 'icon-right-arrow-thin'}
              label={__('Approvers')}
              iconClassName={styles.approversDropDownIcon}
              value={status === APPROVAL_STATUS_TYPES.PENDING ? `(${_size(eligibleApprovers)})` : ''}
              onClickIcon={handleExpandApproverDetails}
            />
            <PropertyControlledComponent controllerProperty={isApproverDetailVisible} fallback={NullComponent}>
              <div className={styles.approversContainer}>
                {_map(allRemainingApprovers, (approvers, approverAssetType) => (
                  <ApproversDetailView
                    isLabelShowing={_isEmpty(eligibleApprovers)}
                    key={approverAssetType}
                    approvers={approvers}
                    assetType={approverAssetType}
                  />
                ))}
              </div>
            </PropertyControlledComponent>
          </>
        )}
      </div>
    </div>
  );
};

Stage.propTypes = {
  index: PropTypes.number.isRequired,
  stage: PropTypes.object.isRequired,
};

export default Stage;
