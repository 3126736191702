import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import _noop from 'lodash/noop';
import _head from 'lodash/head';
import _get from 'lodash/get';

import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import Switch from '@tekion/tekion-components/molecules/Switch';

const SwitchCellRenderer = ({ column, nestingPath, data }) => {
  const id = _get(column, 'id', '');
  const index = _head(nestingPath);

  return <Switch id={`${id}_${index}`} checked={data} onChange={_noop} disabled />;
};

SwitchCellRenderer.propTypes = {
  column: PropTypes.object.isRequired,
  data: PropTypes.string.isRequired,
  nestingPath: PropTypes.array.isRequired,
};

export default compose(makeCellRenderer, React.memo)(SwitchCellRenderer);
