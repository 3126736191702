import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';

const BUTTON_RADIO_VALUES = {
  TEXT: 'text',
  ICON: 'icon',
};

const BUTTON_VIEWS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  ICON: 'icon',
  LINK: 'link',
};

const BUTTON_RADIO_OPTIONS = [
  { label: __('Text'), value: BUTTON_RADIO_VALUES.TEXT },
  { label: __('Icon'), value: BUTTON_RADIO_VALUES.ICON },
];

const BUTTON_CONFIGURATOR_IDS = {
  BUTTON_ICON: ALL_VIEW_PROPERTY_KEYS.BUTTON_ICON,
  BUTTON_TEXT: ALL_VIEW_PROPERTY_KEYS.BUTTON_TEXT,
  BUTTON_TEXT_TYPE: ALL_VIEW_PROPERTY_KEYS.BUTTON_TEXT_TYPE,
  EVENT_HANDLERS: ALL_VIEW_PROPERTY_KEYS.EVENT_HANDLERS,
};

const BUTTON_TEXT_TYPE_OPTIONS = [
  { label: __('Primary'), value: BUTTON_VIEWS.PRIMARY },
  { label: __('Secondary'), value: BUTTON_VIEWS.SECONDARY },
  { label: __('Tertiary'), value: BUTTON_VIEWS.TERTIARY },
  { label: __('Link'), value: BUTTON_VIEWS.LINK },
];

export { BUTTON_RADIO_OPTIONS, BUTTON_RADIO_VALUES, BUTTON_CONFIGURATOR_IDS, BUTTON_TEXT_TYPE_OPTIONS, BUTTON_VIEWS };
