import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _valuesIn from 'lodash/valuesIn';
import { defaultMemoize } from 'reselect';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox/Checkbox';

import { mergeRenderOptions } from '../../../helpers/fieldsForm.general.helpers';

import LOOKUP_DETAILS_FORM_FIELD_IDS from '../constants/lookupDetailsForm.fieldIds';
import LOOKUP_DETAILS_FORM_LABELS from '../constants/lookupDetailsForm.labels';

const LOOKUP_ENABLED_FIELD = {
  id: LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: __('Allow Lookup using field?'),
  },
};

const LOOKUP_DETAILS_FORM_FIELDS = {
  [LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]: LOOKUP_ENABLED_FIELD,
};

const getFieldLabel = defaultMemoize((fieldId) => tget(LOOKUP_DETAILS_FORM_LABELS, fieldId, NO_DATA));

const getFields = (renderOptions) => {
  const fieldsRenderOptions = {};
  const mergedRenderOptions = mergeRenderOptions(fieldsRenderOptions, _valuesIn(LOOKUP_DETAILS_FORM_FIELD_IDS), renderOptions, getFieldLabel);

  return _reduce(
    _valuesIn(LOOKUP_DETAILS_FORM_FIELD_IDS),
    (fields, fieldId) => ({
      ...fields,
      [fieldId]: addToRenderOptions(_get(LOOKUP_DETAILS_FORM_FIELDS, fieldId), _get(mergedRenderOptions, fieldId, EMPTY_ARRAY)),
    }),
    EMPTY_OBJECT,
  );
};

export default getFields;
