import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _forEach from 'lodash/forEach';
import _set from 'lodash/set';
import _get from 'lodash/get';
import { Transforms } from 'slate';
import { getPreventDefaultHandler } from '@udecode/plate-common';
import { ToolbarButton } from '@udecode/plate-toolbar';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';

import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import useEditor from '../../../hooks/useEditor';

const ResetToolbarButton = ({ icon }) => {
  const editorRef = useEditor();

  const onMouseDown = useCallback(
    (event) =>
      getPreventDefaultHandler((editor) => {
        _forEach(_get(editor, 'children', EMPTY_ARRAY), () => {
          Transforms.delete(editor, { at: [0] });
        });

        _set(editor, 'children', [
          {
            type: ELEMENT_PARAGRAPH,
            children: [{ text: '' }],
          },
        ]);
      }, editorRef)(event),
    [editorRef],
  );

  return <ToolbarButton icon={<FontIcon size={SIZES.MD_S}>{icon}</FontIcon>} onMouseDown={onMouseDown} />;
};

ResetToolbarButton.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default React.memo(ResetToolbarButton);
