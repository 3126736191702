import React from 'react';
import PropTypes from 'prop-types';

import styles from './fieldValuePair.module.scss';

const FieldValuePair = ({ label, value }) => {
  let valueToDisplay = value;

  if (typeof value === 'boolean') {
    valueToDisplay = value ? 'True' : 'False';
  }

  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{valueToDisplay}</div>
    </div>
  );
};

FieldValuePair.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default FieldValuePair;
