const REMOVE_ACTION = {
  id: 'REMOVE_ROW',
  name: __('Remove row'),
  icon: 'icon-trash',
};
const EDIT_ACTION = {
  id: 'EDIT_ROW',
  name: __('Edit'),
  icon: 'icon-edit',
};
const TABLE_ACTION_TYPES = {
  ON_CHANGE: 'ON_CHANGE',
  TABLE_ACTION_CLICK: 'TABLE_ACTION_CLICK',
  REMOVE_ROW: 'REMOVE_ROW',
  EDIT_ROW: 'EDIT_ROW',
};

const COLUMN_IDS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  FIELD_TYPE: 'fieldType',
};

export { REMOVE_ACTION, EDIT_ACTION, TABLE_ACTION_TYPES, COLUMN_IDS };
