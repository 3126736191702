const FIELD_IDS = {
  ID: 'id',
  ENTITY_NAME: 'entityName',
  FIELD_TO_MATCH: 'fieldToMatch',
  MEDIA_ID: 'mediaId',
  REPORTS_NAME: 'reportsName',
  TEMPLATE_ID: 'templateId',
  OPERATION_TYPE: 'operationType',
  ADDITIONAL_DATA: 'additionalData',
  BATCH_SIZE: 'batchSize',
  SEQUENTIAL: 'sequential',
  UNIQUE_DATA: 'uniqueData',
  FIELD_MAPPING: 'fieldMapping',
};

const TEMPLATE_FIELD_IDS = {
  ID: 'id',
  COLUMN_DEFINITIONS: 'columnDefinitions',
  DISPLAY_NAME: 'displayName',
  FIELD_NAME: 'fieldName',
};

const FIELD_MAPPING_IDS = {
  COLUMN_TYPE: 'columnType',
  DISPLAY_NAME: 'displayName',
  FIELD_NAME: 'fieldName',
  FIELD_TO_DEFAULT_VALUE_MAP: 'fieldToDefaultValueMap',
  DEFAULT_VALUE: 'defaultValue',
  DELIMITER: 'delimiter',
};

const OPERATION_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  UPSERT: 'UPSERT',
  CREATE_IF_ABSENT: 'CREATE_IF_ABSENT',
};

const OPERATION_TYPE_OPTIONS = [
  { label: __('Create'), value: OPERATION_TYPES.CREATE },
  { label: __('Update'), value: OPERATION_TYPES.UPDATE },
  { label: __('Upsert'), value: OPERATION_TYPES.UPSERT },
  { label: __('Create If Absent'), value: OPERATION_TYPES.CREATE_IF_ABSENT },
];

const REPORTS_NAME_OPTIONS = [{ label: __('Entity'), value: 'ENTITY' }];

const DATA_IMPORT_FORM_CONTEXT_ID = 'DATA_IMPORT_FORM_CONTEXT_ID';

const ENTITY_SELECT_DROPDOWN_ROWS = 10;

const ADDITIONAL_DATA_FORM_FIELDS = [FIELD_IDS.ENTITY_NAME, FIELD_IDS.FIELD_TO_MATCH];

const PRIMARY_BUTTON_LABELS = {
  GET_TEMPLATE: __('Get Template'),
  SAVE_TEMPLATE: __('Save Template'),
  IMPORT: __('Import'),
};

export {
  DATA_IMPORT_FORM_CONTEXT_ID,
  FIELD_IDS,
  TEMPLATE_FIELD_IDS,
  FIELD_MAPPING_IDS,
  OPERATION_TYPES,
  OPERATION_TYPE_OPTIONS,
  ENTITY_SELECT_DROPDOWN_ROWS,
  ADDITIONAL_DATA_FORM_FIELDS,
  REPORTS_NAME_OPTIONS,
  PRIMARY_BUTTON_LABELS,
};
