import DEPENDENCY_CONFIG_FORM_FIELD_IDS from './dependencyConfigForm.fieldIds';

import styles from '../../../fieldsForm.module.scss';

const DEPENDENCY_CONFIG_FORM_SECTIONS = [
  {
    header: {
      label: __('Dependency Config Options'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [DEPENDENCY_CONFIG_FORM_FIELD_IDS.DEPENDENCY_CONFIG_TABLE],
      },
    ],
  },
];

export default DEPENDENCY_CONFIG_FORM_SECTIONS;
