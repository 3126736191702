import _get from 'lodash/get';

import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { STATUS } from '@tekion/tekion-base/constants/statuses';

import wwdPermissionsManagementServices from '../services/wwdPermissions.services';

const fetchWwdPermissionList = async (payload) => {
  try {
    const receivedData = await wwdPermissionsManagementServices.fetchWwdPermissionList(payload);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch WWD Permission List, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const editWWDPermissionData = async (entityName, payload) => {
  try {
    const response = await wwdPermissionsManagementServices.editWWDPermissionData(entityName, payload);
    const data = getDataFromResponse(response);
    return { data, status: _get(response, 'data.status') };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Edit WWD Permission , please try again later.')));
    return { data: EMPTY_OBJECT, status: STATUS.FAILED };
  }
};

export { fetchWwdPermissionList, editWWDPermissionData };
