import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';
import TableManager from '@tekion/tekion-components/organisms/TableManager';

import withSize from '../../../../connectors/withSize';

import WWDPermissionEditModal from './components/wwdPermissionEditModal/WWDPermissionEditModal';

import { getTableProps } from './helpers/wwdPermissionList.helpers';
import ACTION_HANDLERS from './helpers/wwdPermissionList.actionHandler';
import COLUMNS from './helpers/wwdPermissionList.columns';
import { HEADER_PROPS, TABLE_MANAGER_PROPS } from './constants/wwdPermissionList.tableConstants';
import { TABLE_CONSTANTS } from './constants/wwdPermissionList.constants';

const WWDPermissionList = ({
  loading,
  isWWDPermissionEditModalVisible,
  isWWDPermissionEditModalSubmitting,
  contentHeight,
  currentPage,
  pageSize,
  totalCount,
  errors,
  wwdPermissionFormValues,
  wwdPermissionList,
  onAction,
}) => {
  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({
        type: actionType,
        payload,
      });
    },
    [onAction],
  );

  return (
    <div style={{ height: contentHeight }}>
      <TableManager
        tokenPagination
        showPagination
        pageSize={pageSize}
        tableManagerProps={TABLE_MANAGER_PROPS}
        headerProps={HEADER_PROPS}
        columns={COLUMNS}
        data={wwdPermissionList}
        tableProps={getTableProps(handleRowAction, currentPage, loading, pageSize, totalCount)}
        onAction={onAction}
      />

      <WWDPermissionEditModal
        isWWDPermissionEditModalVisible={isWWDPermissionEditModalVisible}
        isWWDPermissionEditModalSubmitting={isWWDPermissionEditModalSubmitting}
        wwdPermissionFormValues={wwdPermissionFormValues}
        errors={errors}
        onAction={onAction}
      />
    </div>
  );
};

WWDPermissionList.propTypes = {
  loading: PropTypes.bool,
  isWWDPermissionEditModalVisible: PropTypes.bool,
  isWWDPermissionEditModalSubmitting: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  errors: PropTypes.object,
  wwdPermissionFormValues: PropTypes.object,
  wwdPermissionList: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

WWDPermissionList.defaultProps = {
  loading: false,
  isWWDPermissionEditModalVisible: false,
  isWWDPermissionEditModalSubmitting: false,
  currentPage: TABLE_CONSTANTS.CURRENT_PAGE,
  pageSize: TABLE_CONSTANTS.PAGE_SIZE,
  totalCount: TABLE_CONSTANTS.TOTAL_NUMBER_OF_ENTRIES,
  errors: EMPTY_OBJECT,
  wwdPermissionFormValues: EMPTY_OBJECT,
  wwdPermissionList: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageFooter: false, hasPageHeader: false }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(WWDPermissionList);
