const FIELD_IDS = {
  SUBSCRIPTION_CONFIG_NAME: 'subscriptionConfigName',
  SUBSCRIPTION_CONFIG_RESPONSE_CODE: 'responseCode',
  SUBSCRIPTION_CONFIG_PATH: 'path',
  SUBSCRIPTION_CONFIG_RESPONSE_BODY: 'responseBody',
  SUBSCRIPTION_CONFIG_BODY: 'body',
  SUBSCRIPTION_CONFIG_RESPONSE_HEADERS: 'responseHeaders',
  SUBSCRIPTION_CONFIG_LOG_CREATED_TIME: 'createdTime',
  SUBSCRIPTION_CONFIG_REQUEST: 'request',
};

export default FIELD_IDS;
