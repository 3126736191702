import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/cms/entities';

const getRecordType = (entityName, recordName) => ApiService.put(`${BASE_URL}/${entityName}/record-type/${recordName}`);

const createRecordType = (entityName, payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/${entityName}/record-type`, payload);

const updateRecordType = (entityName, payload = EMPTY_OBJECT) => ApiService.put(`${BASE_URL}/${entityName}/record-type`, payload);

const createRecordTypeOverride = (entityName, payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/${entityName}/record-type/override`, payload);

const updateRecordTypeOverride = (entityName, recordTypeOverrideName, payload = EMPTY_OBJECT) =>
  ApiService.put(`${BASE_URL}/${entityName}/record-type/override/name/${recordTypeOverrideName}`, payload);

const getRecordTypeOverride = (entityName, recordName) => ApiService.get(`${BASE_URL}/${entityName}/record-type/${recordName}/override`);

const updateRecordTypeDefinition = (entityName, payload = EMPTY_OBJECT) =>
  ApiService.put(`${BASE_URL}/${entityName}/record-type/definition`, payload);

export default {
  createRecordType,
  updateRecordType,
  getRecordType,
  createRecordTypeOverride,
  updateRecordTypeOverride,
  getRecordTypeOverride,
  updateRecordTypeDefinition,
};
