import { TAB_PROPERTIES_CONFIGURE_FIELD_IDS } from '../constants/tabPropertiesConfigurator.fieldIds';

const TAB_PROPERTIES_CONFIGURE_SECTIONS = [
  {
    rows: [
      {
        columns: [TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_TEMPLATE_TYPE],
      },
      {
        columns: [TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_POSITION],
      },
      {
        columns: [TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_SIZE],
      },
    ],
  },
];

export { TAB_PROPERTIES_CONFIGURE_SECTIONS };
