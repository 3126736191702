import React, { useCallback, useEffect, useMemo } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';
import Button from '@tekion/tekion-components/atoms/Button';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import withActions from '@tekion/tekion-components/connectors/withActions';

import WithSize from '../../../../../../connectors/withSize';

import getExternalCredentialFormFields from './helpers/externalCredentialForm.fields';
import getExternalCredentialFormSection from './helpers/externalCredentialForm.sections';
import ACTION_HANDLERS from './helpers/externalCredentialForm.actionHandlers';

import { FORM_MODES } from '../../../../../../constants/general.constants';
import ACTION_TYPES from './constants/externalCredentialForm.actionTypes';
import { EXTERNAL_CREDENTIAL_FORM_CONTEXT_ID, FIELD_IDS } from './constants/externalCredentialForm.constants';

import styles from './externalCredentialForm.module.scss';

const ExternalCredentialForm = ({
  isDataLoading,
  isSaveLoading,
  isExternalCredentialSectionVisible,
  isExternalCredentialsLoading,
  isExternalCredentialTypeChanged,
  contentHeight,
  formMode,
  externalCredentialFormValue,
  errors,
  onAction,
}) => {
  const formHeading = formMode === FORM_MODES.CREATE ? __('Create External Credential') : __('Edit External Credential');
  const type = tget(externalCredentialFormValue, FIELD_IDS.TYPE, EMPTY_STRING);
  const fields = useMemo(() => getExternalCredentialFormFields(formMode), [formMode]);

  const handleRevealClick = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_REVEAL_CLICK });
  }, [onAction]);

  const handleRedirect = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_REDIRECTION,
    });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(EXTERNAL_CREDENTIAL_FORM_CONTEXT_ID);
  }, []);

  const renderExternalDetailsShowButton = useCallback(
    () => (
      <Button loading={isExternalCredentialsLoading} view={Button.VIEW.TERTIARY} className={styles.showButton} onClick={handleRevealClick}>
        {isExternalCredentialSectionVisible ? __('Hide External Credential Details') : __('Show External Credential Details')}
        <FontIcon className="inline m-l-8" size={SIZES.MD_S}>
          {isExternalCredentialSectionVisible ? 'icon-eye' : 'icon-eye-outline'}
        </FontIcon>
      </Button>
    ),
    [isExternalCredentialsLoading, handleRevealClick, isExternalCredentialSectionVisible],
  );

  const getExternalCredentialSectionHeader = useCallback(
    () => (
      <PropertyControlledComponent
        controllerProperty={formMode === FORM_MODES.CREATE || isExternalCredentialTypeChanged}
        fallback={renderExternalDetailsShowButton()}
      >
        {__('External Credential Details')}
      </PropertyControlledComponent>
    ),
    [formMode, isExternalCredentialTypeChanged, renderExternalDetailsShowButton],
  );

  const section = useMemo(
    () =>
      getExternalCredentialFormSection({
        type,
        isExternalCredentialSectionVisible,
        isExternalCredentialTypeChanged,
        formMode,
        getExternalCredentialSectionHeader,
      }),
    [type, isExternalCredentialSectionVisible, isExternalCredentialTypeChanged, formMode, getExternalCredentialSectionHeader],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction]);

  return (
    <Page className="full-height full-width">
      <Page.Header hasBack goBackHandler={handleRedirect}>
        <Heading className="full-width">{formHeading}</Heading>
      </Page.Header>

      <Page.Body style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          isFetching={isDataLoading}
          contextId={EXTERNAL_CREDENTIAL_FORM_CONTEXT_ID}
          fields={fields}
          sections={section}
          values={externalCredentialFormValue}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>

      <Page.Footer>
        <SaveComponent
          primaryActionLoading={isSaveLoading}
          primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Create') : __('Update')}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleRedirect}
        />
      </Page.Footer>
    </Page>
  );
};

ExternalCredentialForm.propTypes = {
  isDataLoading: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  isExternalCredentialSectionVisible: PropTypes.bool,
  isExternalCredentialsLoading: PropTypes.bool,
  isExternalCredentialTypeChanged: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  externalCredentialFormValue: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ExternalCredentialForm.defaultProps = {
  isDataLoading: false,
  isSaveLoading: false,
  isExternalCredentialsLoading: false,
  isExternalCredentialSectionVisible: false,
  isExternalCredentialTypeChanged: false,
  formMode: FORM_MODES.CREATE,
  externalCredentialFormValue: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default compose(WithSize({ hasPageFooter: 1, hasPageHeader: 1 }), withActions({}, ACTION_HANDLERS))(ExternalCredentialForm);
