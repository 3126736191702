import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import _get from 'lodash/get';

import { ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './listColumn.module.scss';

const ListColumn = (props) => {
  const { sectionId, className, componentConfig } = props;

  const displayName = _get(componentConfig, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.TITLE}`);

  return (
    <div key={sectionId} className={cx(styles.listColumn, className)}>
      {displayName}
    </div>
  );
};

ListColumn.propTypes = {
  sectionId: PropTypes.string.isRequired,
  className: PropTypes.string,
  componentConfig: PropTypes.object.isRequired,
};

ListColumn.defaultProps = {
  className: '',
};

export default ListColumn;
