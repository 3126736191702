import _identity from 'lodash/identity';

// Form Validators
import { isRequiredRule, isLessThanRule } from '@tekion/tekion-base/utils/formValidators';

// Components
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import SortConditionList from '../../../../../../molecules/sortConditionList';
import ValidationOverrideSection from '../organisms/validationOverrideSection';
import EntityAsyncSelect from '../../../../molecules/EntityAsyncSelect/EntityAsyncSelect';
import SendEmailsActionDefinitionSection from '../organisms/sendEmailsActionDefinitionSection';
import AsyncPaginatedSelect from '../../../../../../organisms/asyncPaginatedSelect/AsyncPaginatedSelect';

import { getWorkflowOptions, handleFetchWorkflows } from './actionBuilderForm.fieldHelpers';

// Constants
import { ACTION_BUILDER_ACTION_TYPE_OPTIONS, USER_INPUT_TYPE_OPTIONS, UPDATE_RECORD_OPTIONS } from '../constants/actionBuilderForm.general';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../constants/actionBuilder.constants';

const maxLimit20Rule = isLessThanRule(20, _identity, __('Limit cannot be more than 20.'));

const ACTION_NAME_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.ACTION_NAME,
  renderer: TextInput,
  renderOptions: {
    size: 6,
    label: __('Action Name'),
    validators: [isRequiredRule],
    placeholder: __('This will be auto filled'),
  },
};

const ACTION_DISPLAY_NAME = {
  id: ACTION_DEFINITION_FIELD_IDS.ACTION_DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Display Name'),
    required: true,
    validators: [isRequiredRule],
    placeholder: __('Enter display name'),
  },
};

const ACTION_TYPE_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE,
  renderer: SelectInput,
  renderOptions: {
    label: __('Action Type'),
    placeholder: __('Select Action Type'),
    options: ACTION_BUILDER_ACTION_TYPE_OPTIONS,
    validators: [isRequiredRule],
  },
};

const TARGET_ENTITY_NAME_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME,
  renderer: EntityAsyncSelect,
  renderOptions: {
    label: __('Target Entity'),
    placeholder: __('Select target entity'),
    required: true,
    validators: [isRequiredRule],
  },
};

const ERROR_MESSAGE_ON_ENTRY_CONDITION_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.ERROR_MESSAGE_ON_ENTRY_CONDITION,
  renderer: TextInput,
  renderOptions: {
    label: __('Error Message'),
    placeholder: __('Type Here'),
  },
};

const SORT_CONDITIONS_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS,
  renderer: SortConditionList,
};

const VALIDATION_RULE_OVERRIDE_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.VALIDATION_RULE_OVERRIDE,
  renderer: ValidationOverrideSection,
};

const USER_INPUT_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.USER_INPUT,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: __('User Input'),
    size: 4,
  },
};

const SHOULD_SORT_RECORDS_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: __('Sort'),
    size: 4,
  },
};

const USER_INPUT_TYPE_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.USER_INPUT_TYPE,
  renderer: Radio,
  renderOptions: {
    radios: USER_INPUT_TYPE_OPTIONS,
  },
};

const UPDATE_RECORD_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD,
  renderer: Radio,
  renderOptions: {
    radios: UPDATE_RECORD_OPTIONS,
    label: __('Which record to update?'),
  },
};

const FORM_VIEW_NAME_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME,
  renderer: SelectInput,
  renderOptions: {
    size: 6,
    placeholder: __('Select Form'),
  },
};

const SELECTED_FIELDS_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS,
  renderer: SelectInput,
  renderOptions: {
    isClearable: true,
    isMulti: true,
    placeholder: __('Select Fields'),
    closeMenuOnSelect: false,
    hideSelectedOptions: false,
  },
};

const RECORDS_LIMIT_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT,
  renderer: TextInput,
  renderOptions: {
    type: 'number',
    label: __('Limit'),
    placeholder: __('Enter the number'),
    required: true,
    validators: [isRequiredRule, maxLimit20Rule],
  },
};

const SEND_EMAILS_ACTION_DEFINITION_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION,
  renderer: SendEmailsActionDefinitionSection,
};

const WORKFLOW_ID_FIELD = {
  id: ACTION_DEFINITION_FIELD_IDS.WORKFLOW_ID,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    label: __('Workflow'),
    placeholder: __('Select Workflow'),
    required: true,
    validator: [isRequiredRule],
    serviceHandler: handleFetchWorkflows,
    getOptions: getWorkflowOptions,
  },
};

const ACTION_BUILDER_FIELDS = {
  [ACTION_DEFINITION_FIELD_IDS.ACTION_NAME]: ACTION_NAME_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.ACTION_DISPLAY_NAME]: ACTION_DISPLAY_NAME,
  [ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE]: ACTION_TYPE_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME]: TARGET_ENTITY_NAME_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.WORKFLOW_ID]: WORKFLOW_ID_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.ERROR_MESSAGE_ON_ENTRY_CONDITION]: ERROR_MESSAGE_ON_ENTRY_CONDITION_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.VALIDATION_RULE_OVERRIDE]: VALIDATION_RULE_OVERRIDE_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.USER_INPUT]: USER_INPUT_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.USER_INPUT_TYPE]: USER_INPUT_TYPE_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME]: FORM_VIEW_NAME_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS]: SELECTED_FIELDS_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS]: SORT_CONDITIONS_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS]: SHOULD_SORT_RECORDS_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT]: RECORDS_LIMIT_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD]: UPDATE_RECORD_FIELD,
  [ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION]: SEND_EMAILS_ACTION_DEFINITION_FIELD,
};

export default ACTION_BUILDER_FIELDS;
