import { PROPERTIES_FIELD_IDS } from '../../../../../constants/applicationBuilder.constants';

const FIELD_IDS = {
  TAB_DISPLAY_NAME: PROPERTIES_FIELD_IDS.TAB_DISPLAY_NAME,
  TAB_NAME: PROPERTIES_FIELD_IDS.TAB_NAME,
  TAB_ICON: PROPERTIES_FIELD_IDS.TAB_ICON,
  PAGE_NAME: PROPERTIES_FIELD_IDS.PAGE_NAME,
  ENTITIES_RENDERING_INFO: PROPERTIES_FIELD_IDS.ENTITIES_RENDERING_INFO,
};

const ENTITY_INFO_FIELD_IDS = {
  ENTITY_NAME: 'entity',
  FORM_VIEW_NAME: 'formView',
  DETAIL_VIEW_NAME: 'detailView',
};

const TAB_PROPERTIES_CONTEXT_ID = 'TAB_PROPERTIES_CONTEXT_ID';

export { FIELD_IDS, TAB_PROPERTIES_CONTEXT_ID, ENTITY_INFO_FIELD_IDS };
