import { useCallback, useState } from 'react';

import _get from 'lodash/get';
import _find from 'lodash/find';

import { fetchOnOpenData } from './helpers/useActionBuilderActions.data';
import { generateOptions } from './helpers/useActionBuilderActions.general';
import { triggerOnActionModalInit } from '../../../../../../connectors/withActionExecutionModal';

import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../constants/actionBuilder.constants';
import { OPTION_KEYS as MENU_OPTION_KEYS } from '../../../../../../atoms/menu';

import entityReader from '../../../../../../readers/entity.reader';

// actionBuilderProp should be builder object of actionBuilderPropsBuilder.js
const useActionBuilderActions = ({ actionModalContextId, entityDef, entityRecord, actionBuilderProps }) => {
  const [actionBuilderOptions, setActionBuilderOptions] = useState([]);

  const entityName = entityReader.name(entityDef);
  const recordId = _get(entityRecord, 'id');

  const handleActionBuilderOnOpen = useCallback(async () => {
    const { actionDefs, validActionNames } = await fetchOnOpenData({ entityName, actionBuilderProps, recordId });

    const handleOnOptionClick = ({ [MENU_OPTION_KEYS.ID]: actionName }) => {
      const _actionDef = _find(actionDefs, { [ACTION_DEFINITION_FIELD_IDS.ACTION_NAME]: actionName });
      triggerOnActionModalInit(actionModalContextId, { recordId, actionName, actionDef: _actionDef, recordData: entityRecord, entityDef });
    };

    const options = generateOptions(actionDefs, validActionNames, handleOnOptionClick);
    setActionBuilderOptions(options);
  }, [actionBuilderProps, actionModalContextId, entityDef, entityName, entityRecord, recordId]);

  return { actionBuilderOptions, handleActionBuilderOnOpen };
};

export default useActionBuilderActions;
