const FILE_VIEW_TYPE = {
  THUMBNAIL: 'thumbnail',
  LIST: 'list',
};

const DEFAULT_MAX_FILE_SIZE = 52428800;
const MB_TO_BYTES = 1024 * 1024;

const FILE_UPLOAD_SUCCESS_MESSAGE = __('Your file is successfully uploaded.Please refresh After sometime to see the preview.');
const DUPLICATE_FILE_UPLOAD_MESSAGE =
  'with same name already exists .Please either change the name and then Upload or delete the older one and upload this file again.';

export { FILE_VIEW_TYPE, DEFAULT_MAX_FILE_SIZE, MB_TO_BYTES, FILE_UPLOAD_SUCCESS_MESSAGE, DUPLICATE_FILE_UPLOAD_MESSAGE };
