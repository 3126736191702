import _castArray from 'lodash/castArray';
import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';
import _omit from 'lodash/omit';
import _valuesIn from 'lodash/valuesIn';
import _pick from 'lodash/pick';
import _set from 'lodash/set';

import { tget } from '@tekion/tekion-base/utils/general';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { fetchEntityRecordById } from '../../../../../actions/recordManagement.actions';
import { getCompactedFiltersPayload } from '../../../../../helpers/general.helpers';

import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../constants/viewBuilder.constants';
import { WIDGET_TYPES } from '../../../../../constants/visualBuilder';
import { COMPONENT_CONFIG_KEYS } from '../../../constants/visualBuilder.general.constants';
import { FORM_MODES } from '../../../../../constants/general.constants';
import {
  APPROVAL_CENTRE_FIELD_IDS,
  CUSTOM_ENTITY_CATEGORY,
  DEFAULT_GROUP,
  FORM_MODES_TO_ACTION_TYPES,
} from '../../../../../constants/approvalCentre.constants';
import SYSTEM_DEFINED_FIELDS from '../../../../../constants/systemDefinedFields';

import entityReader from '../../../../../readers/entity.reader';
import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';

const getViewConfigurationSearchPayload = (entityViewNames, entityName) => ({
  filters: getCompactedFiltersPayload([
    { field: VIEW_CONFIGURATION_FIELD_IDS.NAME, filterType: OPERATORS.IN, values: _castArray(entityViewNames) },
    { field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME, filterType: OPERATORS.IN, values: _castArray(entityName) },
  ]),
});

const getInitialFormValues = (entityDef, pageEntityRecord, componentConfig, widgetType) => {
  const fieldDefsByName = _keyBy(entityReader.fieldDefinitions(entityDef), 'name');
  let values = {};

  const isRecordTypeEnabled = entityReader.recordTypeEnabled(entityDef);
  if (isRecordTypeEnabled) {
    const defaultRecordType = entityReader.defaultRecordType(entityDef);
    values = { ...values, recordTypeId: defaultRecordType };
  }

  switch (widgetType) {
    case WIDGET_TYPES.CHILD_WIDGET: {
      const relatedField = tget(componentConfig, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${COMPONENT_CONFIG_KEYS.RELATIVE_FIELD_NAME}`);
      const relatedFieldDef = tget(fieldDefsByName, relatedField, EMPTY_OBJECT);
      const relatedFieldEntityLookupField = fieldDefinitionReader.lookupField(relatedFieldDef);

      let relatedFieldValue = null;
      if (_has(pageEntityRecord, relatedFieldEntityLookupField)) {
        relatedFieldValue = tget(pageEntityRecord, relatedFieldEntityLookupField);
      } else if (_has(pageEntityRecord, `entity.${relatedFieldEntityLookupField}`)) {
        relatedFieldValue = tget(pageEntityRecord, ['entity', relatedFieldEntityLookupField]);
      }

      values = { ...values, [relatedField]: relatedFieldValue };
      break;
    }

    default: {
      break;
    }
  }

  return values;
};

const getEntityRecordFromWidgetType = async ({ widgetType, pageEntityRecord, pageEntityName, entityRecordId }) => {
  let entityRecord = {};

  switch (widgetType) {
    case WIDGET_TYPES.STANDARD_WIDGET: {
      // Considering standard widget type will be of the same entity as page entity
      entityRecord = pageEntityRecord;
      if (_isEmpty(entityRecord)) {
        entityRecord = await fetchEntityRecordById(pageEntityName, entityRecordId);
      }
      break;
    }

    default: {
      break;
    }
  }

  return entityRecord;
};

const getPayloadForApprovalMatchingProcess = (formValues, entityDef, formMode) => {
  const entityName = entityReader.name(entityDef);
  const entityDisplayName = entityReader.displayName(entityDef);
  const actionTextForName = formMode === FORM_MODES.CREATE ? 'Creation' : 'Updation';

  const payload = {
    [APPROVAL_CENTRE_FIELD_IDS.GROUP]: DEFAULT_GROUP,
    [APPROVAL_CENTRE_FIELD_IDS.CATEGORY]: CUSTOM_ENTITY_CATEGORY,
    [APPROVAL_CENTRE_FIELD_IDS.TYPE]: entityName,
    [APPROVAL_CENTRE_FIELD_IDS.NAME]: `${actionTextForName} request for ${entityDisplayName}'s record`,
    [APPROVAL_CENTRE_FIELD_IDS.DATA]: {
      [APPROVAL_CENTRE_FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.ACTION_TYPE]: tget(FORM_MODES_TO_ACTION_TYPES, formMode, EMPTY_STRING),
      [APPROVAL_CENTRE_FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.CUSTOM_ENTITY_REQUEST]: {
        entity: {
          ..._omit(formValues, _valuesIn(SYSTEM_DEFINED_FIELDS)),
        },
        ..._pick(formValues, _valuesIn(SYSTEM_DEFINED_FIELDS)),
      },
    },
  };

  const id = tget(formValues, 'id');
  if (formMode === FORM_MODES.EDIT && !_isEmpty(id)) {
    _set(payload, [APPROVAL_CENTRE_FIELD_IDS.DATA, 'id'], id);
  }

  return payload;
};

export { getViewConfigurationSearchPayload, getInitialFormValues, getEntityRecordFromWidgetType, getPayloadForApprovalMatchingProcess };
