// Tekion-components
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import COLUMN_IDS from '../constants/validationRuleBuilderList.columnIds';
import ACTION_TYPES from '../constants/validationRuleBuilderList.actionTypes';
import { RULE_STATUS_LABEL_MAP, RULE_STATUS_COLOR_MAP } from '../constants/validationRuleBuilderList.general';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const getStatusProps = () => ({
  labelMap: RULE_STATUS_LABEL_MAP,
  colorMap: RULE_STATUS_COLOR_MAP,
});

const COLUMN_CONFIG = [
  {
    Header: __('Rule Name'),
    id: COLUMN_IDS.RULE_NAME,
    accessor: COLUMN_IDS.RULE_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Error Message'),
    id: COLUMN_IDS.ERROR_MESSAGE,
    accessor: COLUMN_IDS.ERROR_MESSAGE,
    Cell: TextRenderer,
  },
  {
    Header: __('Status'),
    id: COLUMN_IDS.STATUS,
    accessor: COLUMN_IDS.STATUS,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Modified By'),
    id: COLUMN_IDS.MODIFIED_BY,
    accessor: COLUMN_IDS.MODIFIED_BY,
    Cell: TextRenderer,
  },
];

const EDIT_VALIDATION_RULE = {
  id: ACTION_TYPES.EDIT_VALIDATION_RULE,
  name: __('Edit Rule'),
};

const ACTIVE_VALIDATION_RULE = {
  id: ACTION_TYPES.ACTIVE_VALIDATION_RULE,
  name: __('Activate Rule'),
};

const INACTIVE_VALIDATION_RULE = {
  id: ACTION_TYPES.INACTIVE_VALIDATION_RULE,
  name: __('Deactivate Rule'),
};

const DEFAULT_ROW_ACTIONS = [EDIT_VALIDATION_RULE];

export { COLUMN_CONFIG, ACTIVE_VALIDATION_RULE, INACTIVE_VALIDATION_RULE, DEFAULT_ROW_ACTIONS };
