import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';
import FIELD_IDS from './subscriptionConfigLogs.fieldIds';

const SUBSCRIPTION_CONFIG_LOG_CREATED_TIME_FILTER = {
  id: FIELD_IDS.SUBSCRIPTION_CONFIG_LOG_CREATED_TIME,
  type: FILTER_TYPES.DATE_TIME_WITHOUT_PRESET,
  name: __('Log time'),
  additional: {
    showTime: true,
  },
};

const DEFAULT_FILTERS_TYPES = [FIELD_IDS.SUBSCRIPTION_CONFIG_LOG_CREATED_TIME];

export { SUBSCRIPTION_CONFIG_LOG_CREATED_TIME_FILTER, DEFAULT_FILTERS_TYPES };
