import _map from 'lodash/map';
import _compact from 'lodash/compact';
import _split from 'lodash/split';
import _uniq from 'lodash/uniq';
import _has from 'lodash/has';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, NO_DATA } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { getWorkspaceUserList } from './workspaceUserManagement.actions';
import { getAllRecordGroups } from './recordGroup.actions';
import { fetchEntities } from './entityManagement.actions';
import { getWorkspaceProjectList } from './workspaceProjectManagement.actions';
import { getTenantUserList } from './tenantUserManagement.actions';

import { LOOKUP_FIELD_TO_RESOLVE, LOOKUP_ITEM_TO_RESOLVE } from '../constants/globalLookupItemsResolver.constants';
import { TENANT_UNIVERSE_PAGE_ID } from '../constants/routes';

const getResolvedResponseToItemTypeMap = async (action, args, itemType, fieldNameToResolve, returnOnlyDisplayNameInResponse) => {
  const response = await action(...args);
  const hits = tget(response, 'hits', EMPTY_ARRAY);

  const resolvedHits = _reduce(
    hits,
    (prevResolvedHits, detail) => {
      const detailKey = _get(detail, fieldNameToResolve);
      let detailValue = detail;

      if (returnOnlyDisplayNameInResponse) {
        const displayName = tget(detail, 'displayName', tget(detail, 'displayname'));
        const firstName = tget(detail, 'entity.firstName', tget(detail, 'entity.firstname'));
        const lastName = tget(detail, 'entity.lastName', tget(detail, 'entity.lastname'));
        const fullName = firstName && lastName ? `${firstName} ${lastName}` : null;
        const name = tget(detail, 'name', NO_DATA);

        detailValue = fullName || displayName || name;
      }

      return {
        ...prevResolvedHits,
        [detailKey]: detailValue,
      };
    },
    {},
  );

  return {
    [itemType]: resolvedHits,
  };
};

const generatePayloadAndAdditionalData = (idsOrNamesMap) => {
  const fieldNameToResolve = _has(idsOrNamesMap, LOOKUP_FIELD_TO_RESOLVE.ID) ? LOOKUP_FIELD_TO_RESOLVE.ID : LOOKUP_FIELD_TO_RESOLVE.NAME;
  const listToResolve = tget(idsOrNamesMap, fieldNameToResolve, EMPTY_ARRAY);
  const uniqValues = _uniq(listToResolve);
  const rows = _size(uniqValues);
  const payload = { filters: [{ field: fieldNameToResolve, filterType: OPERATORS.IN, values: uniqValues }], rows };

  return { payload, fieldNameToResolve, listToResolve };
};

const globalLookupItemsResolver = async (itemTypesToIdsOrNamesMap, returnOnlyDisplayNameInResponse = false) => {
  const url = window.location.href;
  const matchArray = _split(url, '/');
  const isTenantUniverse = _includes(matchArray, TENANT_UNIVERSE_PAGE_ID);

  const promiseArray = _compact(
    _map(itemTypesToIdsOrNamesMap, (idsOrNamesMap, itemType) => {
      const { payload, listToResolve, fieldNameToResolve } = generatePayloadAndAdditionalData(idsOrNamesMap);

      if (_isEmpty(listToResolve)) {
        return null;
      }

      switch (itemType) {
        case LOOKUP_ITEM_TO_RESOLVE.USER: {
          if (isTenantUniverse) {
            return getResolvedResponseToItemTypeMap(getTenantUserList, [payload], itemType, fieldNameToResolve, returnOnlyDisplayNameInResponse);
          } else {
            return getResolvedResponseToItemTypeMap(getWorkspaceUserList, [payload], itemType, fieldNameToResolve, returnOnlyDisplayNameInResponse);
          }
        }
        case LOOKUP_ITEM_TO_RESOLVE.USER_GROUP: {
          return getResolvedResponseToItemTypeMap(getAllRecordGroups, [payload], itemType, fieldNameToResolve, returnOnlyDisplayNameInResponse);
        }
        case LOOKUP_ITEM_TO_RESOLVE.PROJECT: {
          return getResolvedResponseToItemTypeMap(getWorkspaceProjectList, [payload], itemType, fieldNameToResolve, returnOnlyDisplayNameInResponse);
        }
        case LOOKUP_ITEM_TO_RESOLVE.ENTITY: {
          return getResolvedResponseToItemTypeMap(fetchEntities, [payload, true], itemType, fieldNameToResolve, returnOnlyDisplayNameInResponse);
        }
        default:
          return null;
      }
    }),
  );

  if (_isEmpty(promiseArray)) {
    return {};
  }

  const resolvedList = await Promise.all(promiseArray);

  const resolvedListToItemTypeMap = _reduce(
    resolvedList,
    (prevResolvedListToItemTypeMap, resolvedListToItemType) => ({
      ...prevResolvedListToItemTypeMap,
      ...resolvedListToItemType,
    }),
    {},
  );

  return resolvedListToItemTypeMap;
};

export default globalLookupItemsResolver;
