const CURRENT_ENTITY_NAMESPACE = '$record';
const ADDRESSEE_NAMESPACE = '$addressee';

const CONDITION_BUILDER_TYPES = {
  CRITERIA: 'CRITERIA',
  SCRIPT: 'SCRIPT',
};

const SYSTEM_DEFINED_VARIABLES = {
  RECORD: '$record',
  SYSTEM: '$System',
  CURRENT_USER: '$CurrentUser',
};

const HELPER_TEXT_VALUE_FIELD = {
  [SYSTEM_DEFINED_VARIABLES.RECORD]:
    '1) $record means it will contain fields of the record on which this action will get executed (i.e source record fields).',
  [SYSTEM_DEFINED_VARIABLES.SYSTEM]: '2) $System refers to system fields like time and date.',
  [SYSTEM_DEFINED_VARIABLES.CURRENT_USER]:
    '3) $CurrentUser refers to current logged in user data current inside condition builder we support currentUser.userId.',
};

export { CURRENT_ENTITY_NAMESPACE, CONDITION_BUILDER_TYPES, ADDRESSEE_NAMESPACE, HELPER_TEXT_VALUE_FIELD, SYSTEM_DEFINED_VARIABLES };
