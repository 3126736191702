// Tekion-components
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import COLUMN_IDS from '../constants/visualBuilderList.columnIds';
import ACTION_TYPES from '../constants/visualBuilderList.actionTypes';

const COLUMN_CONFIG = [
  {
    Header: __('Page Name'),
    id: COLUMN_IDS.PAGE_NAME,
    accessor: COLUMN_IDS.PAGE_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Page Type'),
    id: COLUMN_IDS.PAGE_TYPE,
    accessor: COLUMN_IDS.PAGE_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Description'),
    id: COLUMN_IDS.DESCRIPTION,
    accessor: COLUMN_IDS.DESCRIPTION,
    Cell: TextRenderer,
  },
  {
    Header: __('Modified By'),
    id: COLUMN_IDS.MODIFIED_BY,
    accessor: COLUMN_IDS.MODIFIED_BY,
    Cell: TextRenderer,
  },
];

const EDIT_VISUAL_BUILDER = {
  id: ACTION_TYPES.EDIT_VISUAL_BUILDER,
  name: __('Edit Page'),
};

const CLONE_PAGE = {
  id: ACTION_TYPES.CLONE_PAGE_MODAL_OPEN,
  name: __('Clone Page'),
};

const ROW_ACTIONS = [EDIT_VISUAL_BUILDER, CLONE_PAGE];

export { COLUMN_CONFIG, ROW_ACTIONS };
