import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';

import { ROW_ACTIONS } from '../constants/externalCredentialList.constants';

const createTableProps = (isLoading, currentPage, pageSize, onAction) => ({
  loading: isLoading,
  currentPage: currentPage + 1,
  rowHeight: 35,
  minRows: 0,
  showPagination: true,
  pageSize,
  resultsPerPage: pageSize,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  onRowActionClick: (type, payload) => onAction({ type, payload }),
  getRowActions: () => ROW_ACTIONS,
});

const getPayload = ({ currentPage, currentPageToken, pageSize }) => ({
  limit: 0,
  rows: pageSize,
  start: currentPage * pageSize,
  nextPageToken: currentPageToken,
});

export { createTableProps, getPayload };
