exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".customActionsConfigurator_customActionsComponent__8mS7uSTgMh{width:100%}[dir] .customActionsConfigurator_customActionsComponent__8mS7uSTgMh{margin:.6rem 0}[dir] .customActionsConfigurator_customActionComponentHeading__sgV8johXTY{margin-bottom:1.6rem}[dir] .customActionsConfigurator_heading__jV7KpUxpTh{margin-bottom:1.6rem}.customActionsConfigurator_addedCustomActionsContainer__otuNCP8W1v{display:flex;flex-direction:column;color:#444f5c;font-size:1.4rem}.customActionsConfigurator_addedCustomActionSection__exyHKHmHZT{display:flex;flex-direction:column;width:12rem;color:gray;font-weight:bold}.customActionsConfigurator_configureButton__o1vmQrj4j5{display:flex;justify-content:center;height:2.2rem;font-weight:bolder;font-size:1.56rem}[dir] .customActionsConfigurator_configureButton__o1vmQrj4j5{padding:.2rem .8rem 0 !important}.customActionsConfigurator_actionsComponent__giVLSBWLFA{display:flex;justify-content:space-between;width:100%}[dir] .customActionsConfigurator_submitButton__uypvCfa1jc:hover{background-color:#ededed}.customActionsConfigurator_customActionsModal__dSurhFb9xe{display:flex}", ""]);

// Exports
exports.locals = {
	"customActionsComponent": "customActionsConfigurator_customActionsComponent__8mS7uSTgMh",
	"customActionComponentHeading": "customActionsConfigurator_customActionComponentHeading__sgV8johXTY",
	"heading": "customActionsConfigurator_heading__jV7KpUxpTh",
	"addedCustomActionsContainer": "customActionsConfigurator_addedCustomActionsContainer__otuNCP8W1v",
	"addedCustomActionSection": "customActionsConfigurator_addedCustomActionSection__exyHKHmHZT",
	"configureButton": "customActionsConfigurator_configureButton__o1vmQrj4j5",
	"actionsComponent": "customActionsConfigurator_actionsComponent__giVLSBWLFA",
	"submitButton": "customActionsConfigurator_submitButton__uypvCfa1jc",
	"customActionsModal": "customActionsConfigurator_customActionsModal__dSurhFb9xe"
};