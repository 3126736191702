import React from 'react';

import _get from 'lodash/get';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

// Components
import TextArea from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import Checkbox from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import SwitchRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import InfoBadge from '../../../../atoms/infoBadge/InfoBadge';

// Helpers
import { nameValidator } from '../../../../utils/formValidators';

// Constants
import { ACCESS_TYPE_OPTIONS, ENTITY_TYPE_OPTIONS, FIELD_IDS } from './entityForm.constants';
import { FORM_MODES, RECORD_ACCESS_TYPES } from '../../../../constants/general.constants';

import styles from './entityForm.module.scss';

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Display Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Display Name'),
  },
};

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Name'),
    placeholder: __('This will be autofilled'),
  },
};

const DESCRIPTION_FIELD = {
  id: FIELD_IDS.DESCRIPTION,
  renderer: TextArea,
  renderOptions: {
    rows: 3,
    label: __('Description'),
  },
};

const SEARCH_ENABLED_FIELD = {
  id: FIELD_IDS.SEARCH_ENABLED,
  renderer: Checkbox,
  renderOptions: {
    label: '',
    defaultValue: false,
    checkboxLabel: <InfoBadge label={__('Enable Search')} helpText={__('User should be able search on fields of this entity')} />,
  },
};

const LIST_VIEW_ENABLED_FIELD = {
  id: FIELD_IDS.LIST_VIEW_ENABLED,
  renderer: Checkbox,
  renderOptions: {
    label: '',
    defaultValue: false,
    checkboxLabel: <InfoBadge label={__('Enable List View')} helpText={__('Enable creation of list views of this entity ')} />,
  },
};

const CREATE_ENABLED_FIELD = {
  id: FIELD_IDS.CREATE_ENABLED,
  renderer: Checkbox,
  renderOptions: {
    label: '',
    defaultValue: false,
    checkboxLabel: <InfoBadge label={__('Enable Create')} helpText={__('Enable creation of records for this Entity ')} />,
  },
};

const EDIT_ENABLED_FIELD = {
  id: FIELD_IDS.EDIT_ENABLED,
  renderer: Checkbox,
  renderOptions: {
    label: '',
    defaultValue: false,
    checkboxLabel: <InfoBadge label={__('Enable Edit')} helpText={__('Enable editing of records for this Entity ')} />,
  },
};

const DETAIL_VIEW_ENABLED_FIELD = {
  id: FIELD_IDS.DETAIL_VIEW_ENABLED,
  renderer: Checkbox,
  renderOptions: {
    label: '',
    defaultValue: false,
    checkboxLabel: <InfoBadge label={__('Enable Detail View')} helpText={__('Enable creation of Detail views of this entity')} />,
    helpText: __(''),
    infoBadgeClassName: styles.infoIcon,
  },
};

const USER_API_ENABLED_FIELD = {
  id: FIELD_IDS.USER_API_ENABLED,
  renderer: Checkbox,
  renderOptions: {
    label: '',
    defaultValue: false,
    checkboxLabel: __('Enable User Api'),
  },
};

const AUDIT_ENABLED_FIELD = {
  id: FIELD_IDS.AUDIT_ENABLED,
  renderer: Checkbox,
  renderOptions: {
    label: '',
    defaultValue: false,
    checkboxLabel: <InfoBadge label={__('Enable Audit')} helpText={__('Enable creation of audit of this entity')} />,
  },
};

const APPROVAL_AUTOMATION_ENABLED_FIELD = {
  id: FIELD_IDS.APPROVAL_AUTOMATION_ENABLED,
  renderer: Checkbox,
  renderOptions: {
    label: '',
    defaultValue: false,
    checkboxLabel: (
      <InfoBadge label={__('Enable Approval Automation')} helpText={__('By Enabling this user can integrate approval process for this entity')} />
    ),
  },
};
const COMPLEX_FIELD = {
  id: FIELD_IDS.COMPLEX_FIELD,
  renderer: SwitchRenderer,
  renderOptions: {
    label: __('Is entity a complex field?'),
    defaultValue: false,
    helpText: __('This Entity will not have independent existence.'),
    infoBadgeClassName: styles.infoIcon,
  },
};

const ENTITY_TYPE_FIELD = {
  id: FIELD_IDS.ENTITY_TYPE,
  renderer: SelectInput,
  renderOptions: {
    label: __('Entity Type'),
    required: true,
    options: ENTITY_TYPE_OPTIONS,
    validators: [isRequiredRule],
  },
};

const GROUP_ENABLED_FIELD = {
  id: FIELD_IDS.GROUPS_ENABLED,
  renderer: Checkbox,
  renderOptions: {
    label: '',
    defaultValue: false,
    checkboxLabel: <InfoBadge label={__('Enable Groups')} helpText={__('Creation of record groups will be enabled for entity')} />,
  },
};

const COMMENTS_ENABLED_FIELD = {
  id: FIELD_IDS.COMMENTS_ALLOWED,
  renderer: Checkbox,
  renderOptions: {
    label: '',
    defaultValue: false,
    checkboxLabel: <InfoBadge label={__('Enable Comments')} helpText={__('Creation of Comments will be enabled for this entity')} />,
  },
};

const RECORD_TYPE_ENABLED_FIELD = {
  id: FIELD_IDS.RECORD_TYPE_ENABLED,
  renderer: SwitchRenderer,
  renderOptions: {
    label: __('Does this entity need a Record Type'),
    defaultValue: false,
    helpText: __('By enabling this field, you can add record type from entity tab.'),
    infoBadgeClassName: styles.infoIcon,
  },
};

const ACCESS_TYPE_FIELD = {
  id: FIELD_IDS.ACCESS_TYPE,
  renderer: SelectInput,
  renderOptions: {
    label: __('Access Type'),
    required: true,
    options: ACCESS_TYPE_OPTIONS,
    validators: [isRequiredRule],
    helpText: __(
      // eslint-disable-next-line max-len
      'Private: Only owner of the entity can read and write record , Public Read-Only: Everyone can see records of this entity, only owner can create records ,  Public Read Write: Everyone can create and read records of this entity',
    ),
    infoBadgeClassName: styles.infoIcon,
  },
};

const GRANT_ACCESS_USING_HIERARCHIES_ENABLED_FIELD = {
  id: FIELD_IDS.GRANT_ACCESS_USING_HIERARCHIES,
  renderer: Checkbox,
  renderOptions: {
    label: '',
    defaultValue: false,
    checkboxLabel: <InfoBadge label={__('Enable  access using role hierarchies')} helpText={__('Role hierarchies will be enable for entity')} />,
  },
};

const CREATE_EDIT_MODE_FIELDS = {
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DESCRIPTION]: DESCRIPTION_FIELD,
  [FIELD_IDS.SEARCH_ENABLED]: SEARCH_ENABLED_FIELD,
  [FIELD_IDS.LIST_VIEW_ENABLED]: LIST_VIEW_ENABLED_FIELD,
  [FIELD_IDS.CREATE_ENABLED]: CREATE_ENABLED_FIELD,
  [FIELD_IDS.EDIT_ENABLED]: EDIT_ENABLED_FIELD,
  [FIELD_IDS.DETAIL_VIEW_ENABLED]: DETAIL_VIEW_ENABLED_FIELD,
  [FIELD_IDS.USER_API_ENABLED]: USER_API_ENABLED_FIELD,
  [FIELD_IDS.AUDIT_ENABLED]: AUDIT_ENABLED_FIELD,
  [FIELD_IDS.COMPLEX_FIELD]: COMPLEX_FIELD,
  [FIELD_IDS.ENTITY_TYPE]: ENTITY_TYPE_FIELD,
  [FIELD_IDS.GROUPS_ENABLED]: GROUP_ENABLED_FIELD,
  [FIELD_IDS.RECORD_TYPE_ENABLED]: RECORD_TYPE_ENABLED_FIELD,
  [FIELD_IDS.ACCESS_TYPE]: ACCESS_TYPE_FIELD,
  [FIELD_IDS.GRANT_ACCESS_USING_HIERARCHIES]: GRANT_ACCESS_USING_HIERARCHIES_ENABLED_FIELD,
  [FIELD_IDS.COMMENTS_ALLOWED]: COMMENTS_ENABLED_FIELD,
  [FIELD_IDS.APPROVAL_AUTOMATION_ENABLED]: APPROVAL_AUTOMATION_ENABLED_FIELD,
};

const getFields = (mode, entity) => ({
  ...CREATE_EDIT_MODE_FIELDS,
  [FIELD_IDS.NAME]: addToRenderOptions(NAME_FIELD, [
    { path: 'disabled', value: mode !== FORM_MODES.CREATE },
    { path: 'required', value: mode === FORM_MODES.CREATE },
    { path: 'validators', value: mode === FORM_MODES.CREATE ? [nameValidator] : EMPTY_ARRAY },
  ]),
  [FIELD_IDS.COMPLEX_FIELD]: addToRenderOptions(COMPLEX_FIELD, [{ path: 'disabled', value: mode !== FORM_MODES.CREATE }]),
  [FIELD_IDS.GRANT_ACCESS_USING_HIERARCHIES]: addToRenderOptions(GRANT_ACCESS_USING_HIERARCHIES_ENABLED_FIELD, [
    { path: 'disabled', value: getArraySafeValue(_get(entity, FIELD_IDS.ACCESS_TYPE)) === RECORD_ACCESS_TYPES.PUBLIC_READ_WRITE },
  ]),
  [FIELD_IDS.RECORD_TYPE_ENABLED]: addToRenderOptions(RECORD_TYPE_ENABLED_FIELD, [
    { path: 'disabled', value: mode !== FORM_MODES.CREATE && _get(entity, [FIELD_IDS.COMPLEX_FIELD], false) },
  ]),
  [FIELD_IDS.GROUPS_ENABLED]: addToRenderOptions(GROUP_ENABLED_FIELD, [{ path: 'disabled', value: mode !== FORM_MODES.CREATE }]),
  [FIELD_IDS.ENTITY_TYPE]: addToRenderOptions(ENTITY_TYPE_FIELD, [{ path: 'isDisabled', value: mode !== FORM_MODES.CREATE }]),
});

export default getFields;
