import React, { useCallback, useEffect, useMemo } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';
import Spinner from '@tekion/tekion-components/molecules/SpinnerComponent';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import withSize from '../../../../connectors/withSize';

// Helpers
import getFields from './helpers/projectForm.fields';
import PROJECT_FORM_SECTIONS from './helpers/projectForm.sections';
import ACTION_HANDLERS from './helpers/projectForm.actionHandlers';

// Constants
import { FORM_MODES } from '../../../../constants/general.constants';
import ACTION_TYPES from './constants/projectForm.actionTypes';
import { INITIAL_STATES_PROJECT_FORM_PROPS, PROJECT_FORM_CONTEXT_ID } from './constants/projectForm.general.constants';

import styles from './projectForm.module.scss';

const ProjectForm = ({ isDataLoading, isSaveLoading, contentHeight, projectData, errors, match, onAction }) => {
  const projectName = _get(match, 'params.projectName', '');
  const formMode = _isEmpty(projectName) ? FORM_MODES.CREATE : FORM_MODES.EDIT;
  const handleFormHeader = formMode === FORM_MODES.CREATE ? __('Create New Project') : __('Edit Project');
  const fields = useMemo(() => getFields(formMode), [formMode]);

  const handleRedirect = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_REDIRECTION,
    });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(PROJECT_FORM_CONTEXT_ID);
  }, []);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INITIALIZE_PROJECT_FORM_VALUES });
  }, [onAction]);

  return (
    <Page className="full-height full-width">
      <Page.Header hasBack goBackHandler={handleRedirect}>
        <Heading className="full-width">{handleFormHeader}</Heading>
      </Page.Header>

      <Page.Body style={{ height: contentHeight }}>
        {isDataLoading && (
          <div className={styles.spinnerWrap}>
            <Spinner size="medium" />
          </div>
        )}

        {!isDataLoading && (
          <FormWithSubmission
            contextId={PROJECT_FORM_CONTEXT_ID}
            fields={fields}
            sections={PROJECT_FORM_SECTIONS}
            values={projectData}
            errors={errors}
            onAction={onAction}
          />
        )}
      </Page.Body>

      <Page.Footer>
        <SaveComponent
          primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Create') : __('Update')}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleRedirect}
          primaryActionLoading={isSaveLoading}
        />
      </Page.Footer>
    </Page>
  );
};

ProjectForm.propTypes = {
  isDataLoading: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  projectData: PropTypes.object,
  errors: PropTypes.object,
  match: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
};

ProjectForm.defaultProps = {
  isDataLoading: false,
  isSaveLoading: false,
  projectData: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default compose(
  withSize({ hasPageFooter: 1, hasPageHeader: 1 }),
  withActions(INITIAL_STATES_PROJECT_FORM_PROPS, ACTION_HANDLERS),
)(ProjectForm);
