import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/cms';
const CUSTOM_CODE_BASE_URL = 'core/u/ccs';

const fetchValidationRules = (entityName, payload) => ApiService.post(`${BASE_URL}/entities/${entityName}/validation-rules/search`, payload);

const createValidationRule = (entityName, payload) => ApiService.post(`${BASE_URL}/entities/${entityName}/validation-rules`, payload);

const updateValidationRule = (entityName, ruleName, payload) =>
  ApiService.put(`${BASE_URL}/entities/${entityName}/validation-rules/name/${ruleName}`, payload);

const getValidationRule = (entityName, ruleName, payload) =>
  ApiService.get(`${BASE_URL}/entities/${entityName}/validation-rules/name/${ruleName}`, payload);

const getCustomCodeMetaData = () => ApiService.get(`${CUSTOM_CODE_BASE_URL}/interface-metadata/ValidationRule`);
const getCustomCodeByClassName = (className) => ApiService.get(`${CUSTOM_CODE_BASE_URL}/code/VALIDATION_RULE/${className}`);
const saveCustomCode = (payload) => ApiService.post(`${CUSTOM_CODE_BASE_URL}/code`, payload);
const createTask = (payload) => ApiService.post(`${CUSTOM_CODE_BASE_URL}/task/compile`, payload);
const getTaskById = (taskId) => ApiService.get(`${CUSTOM_CODE_BASE_URL}/task/${taskId}`);

export default {
  fetchValidationRules,
  createValidationRule,
  updateValidationRule,
  getValidationRule,
  getCustomCodeMetaData,
  getCustomCodeByClassName,
  saveCustomCode,
  createTask,
  getTaskById,
};
