import _map from 'lodash/map';
import _identity from 'lodash/identity';
import _nth from 'lodash/nth';
import _split from 'lodash/split';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { getValueAsTemplate } from '../../utils';

import { ROWS } from './relationshipField.constant';

const getHasMore = (nextPageToken) => nextPageToken !== null;

const getLoadingMessage = () => __('Searching...');

const getResolvedLabelValueAccordingToTemplate = (lookUpDisplayNames, template, record) => {
  const fieldNameToEntityRecordValue = _reduce(
    lookUpDisplayNames,
    (acc, lookupDisplayName) => {
      const fieldName = _nth(_split(lookupDisplayName, '.'), 1);

      const fieldValue = tget(record, fieldName, tget(record, `entity.${fieldName}`, EMPTY_STRING));

      _set(acc, [lookupDisplayName], fieldValue);

      return acc;
    },
    {},
  );

  const resolvedLabelValue = getValueAsTemplate(template, fieldNameToEntityRecordValue);

  return resolvedLabelValue;
};

const getValueOption = (lookUpDisplayNames, lookUpFieldName, template, isDisplayNameResolvedAccordingToTemplate) => (record) => {
  let label;

  if (isDisplayNameResolvedAccordingToTemplate) {
    label = getResolvedLabelValueAccordingToTemplate(lookUpDisplayNames, template, record);
  } else {
    const firstLookupDisplayName = getArraySafeValue(lookUpDisplayNames);
    const lookupFieldName = _nth(_split(firstLookupDisplayName, '.'), 1);
    label = tget(record, lookupFieldName, tget(record, `entity.${lookupFieldName}`, EMPTY_STRING));
  }

  return {
    ...record,
    label,
    value: tget(record, lookUpFieldName, tget(record, `entity.${lookUpFieldName}`)),
  };
};

const getOptions = (data, lookUpDisplayNames, lookUpFieldName, template, isDisplayNameResolvedAccordingToTemplate) =>
  _map(data, getValueOption(lookUpDisplayNames, lookUpFieldName, template, isDisplayNameResolvedAccordingToTemplate));

const getSearchTextMetadataValue = (lookupDisplayNames) =>
  _map(lookupDisplayNames, (lookupDisplayName) => {
    const fieldName = _nth(_split(lookupDisplayName, '.'), 1);

    return { field: fieldName, boost: 1 };
  });

const getPayload = (nextPageToken, searchText, lookUpDisplayNames) => {
  const defaultPayloadValue = {
    rows: ROWS,
    nextPageToken,
    searchText,
  };

  const searchTextMetadataValue = getSearchTextMetadataValue(lookUpDisplayNames);

  return {
    ...defaultPayloadValue,
    searchTextMetadata: searchTextMetadataValue,
  };
};

const getPayloadForInit = (values, lookUpFieldName) => ({
  filters: [
    {
      field: lookUpFieldName,
      filterType: OPERATORS.IN,
      values,
    },
  ],
  rows: ROWS,
});

const getResourceParams = () => ({
  getOptionLabel: (option) => tget(option, 'label'),
  getOptionValue: (option) => tget(option, 'value'),
  getOptionData: _identity,
});

export { getResourceParams, getPayloadForInit, getPayload, getOptions, getHasMore, getLoadingMessage };
