import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import ManageRecord from './organisms/manageRecord';
import ShareRecordForm from './organisms/shareRecordForm';
import ManageAccessHeader from './molecules/manageAccessHeader';

import getRecordSharingAccessTypeOptions from './helpers/shareAndManageRecord.helpers';

import { SHARE_AND_MANAGE_RECORD_MODAL_MODE } from '../../constants/shareAndManageRecordModal.constants';

const ShareAndManageRecord = ({
  isDataLoading,
  mode,
  entityDef,
  entityRecord,
  wwdData,
  shareAndManageRecordUserDetails,
  onUpdateShareAndManageRecordUserDetails,
  onRemoveShareAndManageRecordUserDetail,
  onAddShareAndManageRecordUserDetail,
}) => {
  const wwdAccessTypesOptions = useMemo(() => getRecordSharingAccessTypeOptions(wwdData), [wwdData]);

  return (
    <>
      {mode === SHARE_AND_MANAGE_RECORD_MODAL_MODE.MANAGE_RECORD && <ManageAccessHeader wwdData={wwdData} entityRecord={entityRecord} />}
      {mode === SHARE_AND_MANAGE_RECORD_MODAL_MODE.SHARE_RECORD && (
        <ShareRecordForm
          entityDef={entityDef}
          entityRecord={entityRecord}
          wwdAccessTypesOptions={wwdAccessTypesOptions}
          shareAndManageRecordUserDetails={shareAndManageRecordUserDetails}
          onUserAddition={onAddShareAndManageRecordUserDetail}
        />
      )}
      <ManageRecord
        isDataLoading={isDataLoading}
        mode={mode}
        wwdAccessTypesOptions={wwdAccessTypesOptions}
        shareAndManageRecordUserDetails={shareAndManageRecordUserDetails}
        onUpdateShareAndManageRecordUserDetails={onUpdateShareAndManageRecordUserDetails}
        onRemoveShareAndManageRecordUserDetails={onRemoveShareAndManageRecordUserDetail}
      />
    </>
  );
};

ShareAndManageRecord.propTypes = {
  isDataLoading: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  entityDef: PropTypes.object,
  entityRecord: PropTypes.object,
  wwdData: PropTypes.object,
  shareAndManageRecordUserDetails: PropTypes.array,
  onUpdateShareAndManageRecordUserDetails: PropTypes.func,
  onRemoveShareAndManageRecordUserDetail: PropTypes.func,
  onAddShareAndManageRecordUserDetail: PropTypes.func,
};

ShareAndManageRecord.defaultProps = {
  isDataLoading: false,
  entityDef: EMPTY_OBJECT,
  entityRecord: EMPTY_OBJECT,
  wwdData: EMPTY_OBJECT,
  shareAndManageRecordUserDetails: EMPTY_ARRAY,
  onUpdateShareAndManageRecordUserDetails: _noop,
  onRemoveShareAndManageRecordUserDetail: _noop,
  onAddShareAndManageRecordUserDetail: _noop,
};

export default ShareAndManageRecord;
