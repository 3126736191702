import _get from 'lodash/get';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import Button from '@tekion/tekion-components/atoms/Button';

import ACTION_TYPES from './metadataComponentList.actionTypes';
import { BUNDLE_STATE } from './metadataComponentList.general.constants';

const COLUMN_IDS = {
  ENTITY_NAME: 'entityName',
  NAME: 'name',
  COMPONENT_TYPE: 'componentType',
  INCLUDED_BY: 'includedBy',
  ENTITY_DISPLAY_NAME: 'entityDisplayName',
};

const COMPONENT_LIST_COLUMNS = [
  {
    id: COLUMN_IDS.COMPONENT_TYPE,
    Header: __('Component Type'),
    accessor: COLUMN_IDS.COMPONENT_TYPE,
    Cell: TextRenderer,
  },
  {
    id: COLUMN_IDS.ENTITY_DISPLAY_NAME,
    Header: __('Entity'),
    accessor: COLUMN_IDS.ENTITY_DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    id: COLUMN_IDS.NAME,
    Header: __('Name'),
    accessor: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
];

const getSubHeaderProps = (bundle) => {
  const state = _get(bundle, 'state', EMPTY_STRING);
  if (state === BUNDLE_STATE.DRAFT) {
    return {
      subHeaderRightActions: [
        {
          renderer: Button,
          renderOptions: {
            label: __('Add Component'),
            view: Button.VIEW.PRIMARY,
          },
          action: ACTION_TYPES.COMPONENT_CONFIG_MODAL,
        },
      ],
    };
  }
  return {};
};

export { COLUMN_IDS, COMPONENT_LIST_COLUMNS, getSubHeaderProps };
