import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ReactEditor } from 'slate-react';
import { Resizable } from 're-resizable';

// constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// hooks
import useMediaWidth from './hooks/useMediaWidth';
import useEditor from '../../hooks/useEditor';

function withMediaResizing(WrappedComponent) {
  function WithMediaResizing(props) {
    const { element = EMPTY_OBJECT, className, enableMediaResizing } = props;
    const { width: nodeWidth = '50%' } = element;
    const editor = useEditor();
    const path = ReactEditor.findPath(editor, element);

    const { width, handleResizeStop, handleResize } = useMediaWidth({ nodeWidth, editor, path });
    return (
      <Resizable
        lockAspectRatio
        size={{ width }}
        onResize={handleResize}
        onResizeStop={handleResizeStop}
        maxWidth="100%"
        className={cx('m-b-20', className)}
        // enable prop takes an object whose default value enables resizing on all sides, hence passing undefined in case of resizing being enabled
        enable={enableMediaResizing ? undefined : EMPTY_OBJECT}
      >
        <WrappedComponent width={width} {...props} />
      </Resizable>
    );
  }

  WithMediaResizing.propTypes = {
    element: PropTypes.object.isRequired,
    className: PropTypes.string,
    enableMediaResizing: PropTypes.bool,
  };

  WithMediaResizing.defaultProps = {
    className: undefined,
    enableMediaResizing: false,
  };

  return WithMediaResizing;
}

export default withMediaResizing;
