const TAB_POSITIONS = {
  TOP: 'TOP',
  LEFT: 'LEFT',
};

const TAB_SIZES = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
};
export { TAB_POSITIONS, TAB_SIZES };
