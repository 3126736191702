import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { COLOR_MAP } from '@tekion/tekion-base/constants/statusColors';
import { STATUS } from '@tekion/tekion-base/constants/statuses';

const CURRENT_PAGE = 0;
const RESULTS_PER_PAGE = 50;

const COLUMN_ACCESSORS = {
  ACTION_NAME: 'name',
  ACTION_TYPE: 'actionType',
  STATUS: 'status',
  MODIFIED_BY: 'modifiedBy',
  ACTION_DISPLAY_NAME: 'displayName',
};
const CUSTOM_ACTIONS = {
  CREATE_NEW: 'CREATE_NEW',
  EDIT: 'EDIT',
  ACTIVATE: 'ACTIVATE',
  DEACTIVATE: 'DEACTIVATE',
  CLOSE_MODAL_REQUEST_DIALOG: 'CLOSE_MODAL_REQUEST_DIALOG',
  CONFIRM_MODAL_REQUEST: 'CONFIRM_MODAL_REQUEST',
};

const INITIAL_STATE = {
  searchField: COLUMN_ACCESSORS.ACTION_NAME,
  isTenantUniverse: false,
  showModal: false,
  modalContent: EMPTY_STRING,
  actionBuilderList: EMPTY_ARRAY,
  currentPage: CURRENT_PAGE,
  pageSize: RESULTS_PER_PAGE,
  resultsperpage: RESULTS_PER_PAGE,
};

const STATUS_TO_LABEL = {
  [STATUS.ACTIVE]: __('Active'),
  [STATUS.IN_ACTIVE]: __('Inactive'),
};

const STATUS_TO_COLOR = {
  [STATUS.ACTIVE]: COLOR_MAP.GREEN,
  [STATUS.IN_ACTIVE]: COLOR_MAP.RED,
};
const ACTION_TYPES = {
  CREATE_RECORD: 'CREATE_RECORD',
  UPDATE_RECORD: 'UPDATE_RECORD',
  EMAIL: 'EMAIL',
};
const STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

const ACTION_TYPES_OPTIONS = [
  {
    label: __('Create Record'),
    value: ACTION_TYPES.CREATE_RECORD,
  },
  {
    label: __('Update Record'),
    value: ACTION_TYPES.UPDATE_RECORD,
  },
  {
    label: __('Email'),
    value: ACTION_TYPES.EMAIL,
  },
];

const STATUS_OPTIONS = [
  {
    label: __('Active'),
    value: STATUSES.ACTIVE,
  },
  {
    label: __('Inactive'),
    value: STATUSES.INACTIVE,
  },
];
const ACTIVATE_MODAL_CONTENT = 'Activate Action';
const DEACTIVATE_MODAL_CONTENT = 'Deactivate Action';
const TEXT_STARTS_WITH = 'TEXT_STARTS_WITH';
const ENTITY = 'ENTITY';
const ACTION_LEVEL = 'actionLevel';
const ENTITY_NAME = 'entityName';
const IN = 'IN';

export {
  CURRENT_PAGE,
  RESULTS_PER_PAGE,
  COLUMN_ACCESSORS,
  CUSTOM_ACTIONS,
  INITIAL_STATE,
  STATUS_TO_LABEL,
  STATUS_TO_COLOR,
  STATUSES,
  ACTION_TYPES_OPTIONS,
  STATUS_OPTIONS,
  ACTIVATE_MODAL_CONTENT,
  DEACTIVATE_MODAL_CONTENT,
  TEXT_STARTS_WITH,
  ENTITY,
  ACTION_LEVEL,
  ENTITY_NAME,
  IN,
};
