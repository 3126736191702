import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

// Components
import withActions from '@tekion/tekion-components/connectors/withActions';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Tabs from '@tekion/tekion-components/molecules/Tabs';
import { DragDropContext, Droppable } from '@tekion/tekion-components/organisms/dragAndDrop';

import TabHeader from './components/TabHeader';
import TabContent from './components/TabContent';

import ACTION_HANDLERS from './helpers/delayTaskModal.actionHandlers';

// Constants
import DELAY_TASK_ACTION_TYPES from './constants/delayTaskModal.actionTypes';
import { INITIAL_STATE } from './constants/delayTaskModal.constants';

import styles from './delayTaskModal.module.scss';

const TAB_BAR_STYLE = { backgroundColor: '#f4f5f6' };

// TODO: hardcoding heights, give modal height.
const tabsContentInfo = { style: { height: '60rem' } };
const tabsHeaderInfo = { direction: 'vertical', style: { height: 600 - 75 } };

// Todo: FIELD DEFINITIONS of target entities to be passed
const DelayTaskModal = ({
  isConditionFetching,
  visible,
  selectedTabId,
  eventTypes,
  workflowOptions,
  entityName,
  stepDetails,
  fieldDefinitions,
  complexEntitiesFieldDefinitions,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  tabsList,
  tabErrorList,
  onAction,
}) => {
  const handleTabAdd = useCallback(
    (tab) => {
      onAction({ type: DELAY_TASK_ACTION_TYPES.ON_FORM_BLUR, payload: { type: DELAY_TASK_ACTION_TYPES.ON_TAB_ADD, payload: { tab }, onAction } });
      // Post validation will trigger action: { type: DELAY_TASK_ACTION_TYPES.ON_TAB_ADD, payload: { tab } }
    },
    [onAction],
  );

  const handleTabClick = useCallback(
    (tabId) => {
      onAction({ type: DELAY_TASK_ACTION_TYPES.ON_FORM_BLUR, payload: { type: DELAY_TASK_ACTION_TYPES.ON_TAB_CLICK, payload: { tabId }, onAction } });
      // Post validation will trigger action: { type: DELAY_TASK_ACTION_TYPES.ON_TAB_CLICK, payload: { tabId } }
    },
    [onAction],
  );

  const handleTabDelete = useCallback(
    (tab) => {
      onAction({ type: DELAY_TASK_ACTION_TYPES.ON_TAB_DELETE, payload: { tab } });
      // Post validation will trigger action:
      // { type: DELAY_TASK_ACTION_TYPES.ON_FORM_BLUR, payload: { type: DELAY_TASK_ACTION_TYPES.ON_TAB_DELETE, payload: { tab }, onAction } }
    },
    [onAction],
  );

  const handleTabDragEnd = useCallback(
    (dragResult) => {
      onAction({
        type: DELAY_TASK_ACTION_TYPES.ON_FORM_BLUR,
        payload: { type: DELAY_TASK_ACTION_TYPES.ON_TAB_DRAG_END, payload: { dragResult }, onAction },
      });
      // Post validation will trigger action: { type: DELAY_TASK_ACTION_TYPES.ON_TAB_DRAG_END, payload: { dragResult } }
    },
    [onAction],
  );

  const handleSubmit = useCallback(() => {
    onAction({
      type: DELAY_TASK_ACTION_TYPES.ON_FORM_BLUR,
      payload: { type: DELAY_TASK_ACTION_TYPES.ON_SUBMIT, payload: {}, onAction },
    });
    // Post validation will trigger action: { type: DELAY_TASK_ACTION_TYPES.ON_SUBMIT }
  }, [onAction]);

  const handleCancel = useCallback(() => {
    onAction({ type: DELAY_TASK_ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  useEffect(() => {
    onAction({ type: DELAY_TASK_ACTION_TYPES.ON_INIT, payload: { stepDetails } });
  }, [stepDetails, onAction]);

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title={stepDetails?.label || stepDetails?.displayName}
      width={Modal.SIZES.XXL}
      submitBtnText={__('Done')}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    >
      <DragDropContext onDragEnd={handleTabDragEnd}>
        <Droppable droppableId="EVENT_TABS" direction={tabsHeaderInfo.direction}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <Tabs style={tabsHeaderInfo.style} tabPosition="left" activeKey={selectedTabId} tabBarStyle={TAB_BAR_STYLE} onTabClick={handleTabClick}>
                {_map(tabsList, (tab, index) => (
                  <Tabs.TabPane
                    key={tab.id}
                    tab={<TabHeader index={index} selectedTabId={selectedTabId} tab={tab} onAdd={handleTabAdd} onDelete={handleTabDelete} />}
                  >
                    <div className={styles.tabContent} style={tabsContentInfo.style}>
                      <TabContent
                        isConditionFetching={isConditionFetching}
                        eventTypes={eventTypes}
                        workflowOptions={workflowOptions}
                        entityName={entityName}
                        tab={tab}
                        tabError={tget(tabErrorList, index, 0)}
                        fieldDefinitions={fieldDefinitions}
                        complexEntitiesFieldDefinitions={complexEntitiesFieldDefinitions}
                        onAction={onAction}
                        conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
                        mapOfVariableToEntityName={mapOfVariableToEntityName}
                      />
                    </div>
                  </Tabs.TabPane>
                ))}
              </Tabs>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Modal>
  );
};

DelayTaskModal.propTypes = {
  isConditionFetching: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  selectedTabId: PropTypes.string.isRequired,
  stepDetails: PropTypes.object.isRequired,
  fieldDefinitions: PropTypes.array.isRequired,
  complexEntitiesFieldDefinitions: PropTypes.object.isRequired,
  conditionBuilderFieldDefinitionObject: PropTypes.object.isRequired,
  mapOfVariableToEntityName: PropTypes.object.isRequired,
  eventTypes: PropTypes.array.isRequired,
  workflowOptions: PropTypes.array,
  tabErrorList: PropTypes.array,
  tabsList: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
};

DelayTaskModal.defaultProps = {
  isConditionFetching: false,
  tabErrorList: EMPTY_ARRAY,
  workflowOptions: EMPTY_ARRAY,
};

export default withActions(INITIAL_STATE, ACTION_HANDLERS)(DelayTaskModal);
