import moment from 'moment';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import {
  SCHEDULE_TYPE_FIELD,
  NAME_FIELD,
  DISPLAY_NAME_FIELD,
  DESCRIPTION_FIELD,
  STATUS_FIELD,
  START_TIME_FIELD,
  END_TIME_FIELD,
  TIME_ZONE_FIELD,
  CRON_EXPRESSION_FIELD,
  SIMPLE_FOREVER_FIELD,
  SIMPLE_TRIGGER_TIME_FIELD,
  SIMPLE_REPEAT_COUNT_FIELD,
  TASK_TYPE_FIELD,
  VIEW_NAME_FIELD,
  ENTITY_NAME_FIELD,
  FILE_FORMAT_FIELD,
} from '../constants/jobScheduleForm.fields';
import FIELD_IDS from '../constants/jobScheduleForm.fieldIds';
import { SCHEDULE_TYPES, TASK_TYPES } from '../constants/jobScheduleForm.general';

import styles from '../jobScheduleForm.module.scss';

const filterForScheduleTypeInput = {
  [FIELD_IDS.CRON_EXPRESSION]: ({ selectedScheduleType }) => selectedScheduleType === SCHEDULE_TYPES.CRON,
  [FIELD_IDS.SIMPLE_FOREVER]: ({ selectedScheduleType }) => selectedScheduleType === SCHEDULE_TYPES.SIMPLE,
  [FIELD_IDS.SIMPLE_TRIGGER_TIME]: ({ selectedScheduleType }) => selectedScheduleType === SCHEDULE_TYPES.SIMPLE,
  [FIELD_IDS.SIMPLE_REPEAT_COUNT]: ({ selectedScheduleType, isSimpleForever }) => selectedScheduleType === SCHEDULE_TYPES.SIMPLE && !isSimpleForever,
  [FIELD_IDS.END_TIME]: ({ selectedScheduleType, isSimpleForever }) =>
    selectedScheduleType !== SCHEDULE_TYPES.ONE_TIME && !(selectedScheduleType === SCHEDULE_TYPES.SIMPLE && isSimpleForever),
};

const filterForTaskTypeInput = {
  [FIELD_IDS.VIEW_NAME]: ({ selectedTaskType }) => selectedTaskType === TASK_TYPES.EXPORT,
  [FIELD_IDS.ENTITY_NAME]: ({ selectedTaskType }) => selectedTaskType === TASK_TYPES.EXPORT,
  [FIELD_IDS.FILE_FORMAT]: ({ selectedTaskType }) => selectedTaskType === TASK_TYPES.EXPORT,
};

const getSections = (selectedScheduleType, selectedTaskType, isSimpleForever) => [
  {
    rows: [{ columns: [FIELD_IDS.NAME, FIELD_IDS.STATUS] }, { columns: [FIELD_IDS.DESCRIPTION] }],
  },
  {
    subHeader: {
      label: __('Schedule Information'),
    },
    className: styles.maxWidth,
    rows: [
      { columns: [FIELD_IDS.SCHEDULE_TYPE, FIELD_IDS.TIME_ZONE] },
      {
        columns: [FIELD_IDS.START_TIME, ...filterRows([FIELD_IDS.END_TIME], { selectedScheduleType, isSimpleForever }, filterForScheduleTypeInput)],
      },
      { columns: filterRows([FIELD_IDS.SIMPLE_FOREVER], { selectedScheduleType }, filterForScheduleTypeInput) },
      {
        columns: filterRows(
          [FIELD_IDS.CRON_EXPRESSION, FIELD_IDS.SIMPLE_REPEAT_COUNT, FIELD_IDS.SIMPLE_TRIGGER_TIME],
          { selectedScheduleType, isSimpleForever },
          filterForScheduleTypeInput,
        ),
      },
    ],
  },
  {
    subHeader: {
      label: __('Task Info'),
    },
    rows: [
      { columns: [FIELD_IDS.TASK_TYPE] },
      { columns: filterRows([FIELD_IDS.ENTITY_NAME, FIELD_IDS.VIEW_NAME, FIELD_IDS.FILE_FORMAT], { selectedTaskType }, filterForTaskTypeInput) },
    ],
  },
];

const FORM_FIELDS = {
  [FIELD_IDS.SCHEDULE_TYPE]: SCHEDULE_TYPE_FIELD,
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.DESCRIPTION]: DESCRIPTION_FIELD,
  [FIELD_IDS.STATUS]: STATUS_FIELD,
  [FIELD_IDS.START_TIME]: START_TIME_FIELD,
  [FIELD_IDS.END_TIME]: END_TIME_FIELD,
  [FIELD_IDS.TIME_ZONE]: TIME_ZONE_FIELD,
  [FIELD_IDS.CRON_EXPRESSION]: CRON_EXPRESSION_FIELD,
  [FIELD_IDS.SIMPLE_FOREVER]: SIMPLE_FOREVER_FIELD,
  [FIELD_IDS.SIMPLE_TRIGGER_TIME]: SIMPLE_TRIGGER_TIME_FIELD,
  [FIELD_IDS.SIMPLE_REPEAT_COUNT]: SIMPLE_REPEAT_COUNT_FIELD,
  [FIELD_IDS.TASK_TYPE]: TASK_TYPE_FIELD,
  [FIELD_IDS.VIEW_NAME]: VIEW_NAME_FIELD,
  [FIELD_IDS.ENTITY_NAME]: ENTITY_NAME_FIELD,
  [FIELD_IDS.FILE_FORMAT]: FILE_FORMAT_FIELD,
};

const getFields = (entityNameOptions, viewOptions, timeZoneOptions, selectedScheduleType) => {
  const returnValue =
    selectedScheduleType === SCHEDULE_TYPES.ONE_TIME
      ? {
          path: 'disabledDate',
          value: (current) => (selectedScheduleType === SCHEDULE_TYPES.ONE_TIME ? current && current <= moment().startOf('day') : current),
        }
      : {};

  return {
    ...FORM_FIELDS,
    [FIELD_IDS.START_TIME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.START_TIME], [
      {
        ...returnValue,
      },
    ]),

    [FIELD_IDS.END_TIME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.END_TIME], [
      { path: 'required', value: selectedScheduleType !== SCHEDULE_TYPES.CRON },
    ]),
    [FIELD_IDS.ENTITY_NAME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.ENTITY_NAME], [{ path: 'options', value: entityNameOptions }]),
    [FIELD_IDS.VIEW_NAME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.VIEW_NAME], [{ path: 'options', value: viewOptions }]),
    [FIELD_IDS.TIME_ZONE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.TIME_ZONE], [{ path: 'options', value: timeZoneOptions }]),
  };
};

export { getSections, getFields };
