import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// styles
import styles from './withMediaStyling.module.scss';

// hooks
import useElementSelection from '../../hooks/useElementSelection';

const ALIGNMENT_TO_CLASSNAME = {
  center: 'justify-content-center',
  justify: 'justify-content-center',
  right: 'justify-content-end',
};

function withMediaStyling(WrappedComponent) {
  function WithMediaStyling(props) {
    const { element, enableMediaHighlighting } = props;
    const { align } = element;
    const alignmentClassName = ALIGNMENT_TO_CLASSNAME[align];
    const { isElementSelected } = useElementSelection();

    return (
      <div contentEditable={false} draggable={false} className={cx('d-flex', alignmentClassName, 'relative')}>
        <WrappedComponent
          {...props}
          className={cx({
            [styles.selectedMedia]: isElementSelected,
            [styles.mediaContainer]: enableMediaHighlighting,
          })}
        />
      </div>
    );
  }

  WithMediaStyling.propTypes = {
    element: PropTypes.object.isRequired,
    enableMediaHighlighting: PropTypes.bool,
  };

  WithMediaStyling.defaultProps = {
    enableMediaHighlighting: false,
  };

  return WithMediaStyling;
}

export default withMediaStyling;
