const FIELD_IDS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  FIELD_TYPE: 'fieldType',
  DATA_TYPE: 'dataType',
  PAGE_LAYOUT: 'pageLayout',
  CREATABLE: 'creatable',
  EDITABLE: 'editable',
  SEARCHABLE: 'searchable',
  SORTABLE: 'sortable',
  FILTERABLE: 'filterable',
  SORT_DIRECTION: 'defaultSortDirection',
  IMPORTABLE: 'importable',
  EXPORTABLE: 'exportable',
  MANDATORY: 'mandatory',
  UNIQUE_CONSTRAINT: 'uniqueConstraint',
  DISABLED: 'disabled',
  LIST_VIEW_ENABLED: 'listViewEnabled',
  DETAILED_VIEW_ENABLED: 'detailViewEnabled',
  ALLOW_LOOKUP_USING_FIELD: 'allowLookupUsingField',
  LOOKUP_DISPLAY_FIELD: 'displayField',
  LOOKUP_FIELD: 'lookupField',
  LOOKUP_ENTITY: 'lookupEntity',
  OPTIONS: 'options',
  TEXT_MIN_LENGTH: 'textMinLength',
  TEXT_MAX_LENGTH: 'textMaxLength',
  TEXT_REGEX: 'textRegex',
  LIST_INPUT_TABLE: 'listInputTable',
  LIST_ADD_ROW_BUTTON: 'listAddRowButton',
  COMPLEX_FIELD_ENTITY: 'complexEntityName',
  AUDIT_ENABLED: 'auditEnabled',
  MULTI_LINGUAL_INPUT_ENABLED: 'multilingualInputEnabled',
  MULTI_LINGUAL_KEY: 'multiLingualKey',
  HELP_TEXT: 'helpText',
  DEPENDENCY_CONFIG: 'dependencyConfigs',
  GROUPS_ALLOWED: 'groupsAllowed',
  CHILD_ENTITY_NAME: 'entityName',
  CHILD_FIELD: 'fieldToAggregate',
  ROLL_UP_FUNCTION: 'function',
  CHILD_RELATION_FIELD: 'relationshipField',
  FILTER_CRITERIA_FOR_CHILD: 'filterCriteriaForChild',
  CHILD_FILTER_CONDITION: 'childFilterCondition',
  FILTER_CRITERIA_FOR_PARENT: 'filterCriteriaForParent',
  UPDATE_CONDITION: 'updateCondition',
  CONTROLLING_FUNCTION: 'controllingFunction',
  CONTROLLING_FIELD_NAME: 'controllingFieldName',
  CONTROLLING_OPTIONS_CONFIGS: 'controllingOptionsConfigs',
  MULTI_VALUED_ENABLED: 'multiValued',
  SELECT_TEMPLATE_REGEX: 'selectTemplateRegex',
  TEST_REGEX_INPUT_STRING: 'textRegexInputString',
  PLACEHOLDER: 'placeholder',
};

export default FIELD_IDS;
