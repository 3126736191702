import React from 'react';
import PropTypes from 'prop-types';

import List from '@tekion/tekion-components/molecules/List';
import { EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import FileReader from '@tekion/tekion-base/utils/File.reader';

import { IMPORT_BATCH_CARD_DETAIL_IDS } from '../../constants/dataImportDetailPage.constants';

import mediaReader from '../../../../../../../readers/media.reader';

import styles from './importedFileCardView.module.scss';

const ImportedFileCardView = ({ uploadedFileData, importedFileCardViewData }) => {
  const renderTextData = (data) => <span className={styles.subItemDescription}>{__(data)}</span>;

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('File Name')}:</div>
        <List.Item.Meta description={renderTextData(mediaReader.fileName(uploadedFileData) || NO_DATA)} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Queued Batches')}:</div>
        <List.Item.Meta description={renderTextData(tget(importedFileCardViewData, IMPORT_BATCH_CARD_DETAIL_IDS.QUEUED_BATCHES))} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Failed Batches')}:</div>
        <List.Item.Meta description={renderTextData(tget(importedFileCardViewData, IMPORT_BATCH_CARD_DETAIL_IDS.FAILED_BATCHES))} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Records Failed')}:</div>
        <List.Item.Meta description={renderTextData(tget(importedFileCardViewData, IMPORT_BATCH_CARD_DETAIL_IDS.RECORDS_FAILED))} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('File ID')}:</div>
        <List.Item.Meta description={renderTextData(mediaReader.mediaId(uploadedFileData) || NO_DATA)} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('In progress Batches')}:</div>
        <List.Item.Meta description={renderTextData(tget(importedFileCardViewData, IMPORT_BATCH_CARD_DETAIL_IDS.IN_PROGRESS_BATCHES))} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Progress')}:</div>
        <List.Item.Meta description={renderTextData(tget(importedFileCardViewData, IMPORT_BATCH_CARD_DETAIL_IDS.PROGRESS))} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Status')}:</div>
        <List.Item.Meta description={renderTextData(tget(importedFileCardViewData, IMPORT_BATCH_CARD_DETAIL_IDS.STATUS))} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('File Type')}:</div>
        <List.Item.Meta description={renderTextData('Bulk')} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Completed Batches')}:</div>
        <List.Item.Meta description={renderTextData(tget(importedFileCardViewData, IMPORT_BATCH_CARD_DETAIL_IDS.COMPLETED_BATCHES))} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Records Processed')}:</div>
        <List.Item.Meta description={renderTextData(tget(importedFileCardViewData, IMPORT_BATCH_CARD_DETAIL_IDS.RECORDS_PROCESSED))} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Format')}:</div>
        <List.Item.Meta description={renderTextData(FileReader.getFileExtension(mediaReader.mimeType(uploadedFileData)) || NO_DATA)} />
      </div>
    </div>
  );
};

ImportedFileCardView.propTypes = {
  importedFileCardViewData: PropTypes.object,
  uploadedFileData: PropTypes.object,
};

ImportedFileCardView.defaultProps = {
  importedFileCardViewData: EMPTY_OBJECT,
  uploadedFileData: EMPTY_OBJECT,
};
export default React.memo(ImportedFileCardView);
