import _map from 'lodash/map';
import _last from 'lodash/last';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

const getVariablesFromTemplate = (body) => {
  try {
    const templateRegex = /\${(.*?)}/gm;
    const regexIterator = Array.from(body.matchAll(templateRegex));
    return _map(regexIterator, (regexMatch) => _last(regexMatch));
  } catch {
    return EMPTY_ARRAY;
  }
};

const getTemplateJson = (jsonBody) => {
  let value;
  if (!_isEmpty(jsonBody)) {
    try {
      value = JSON.parse(jsonBody);
    } catch (error) {
      value = undefined;
    }
  }
  return value;
};

export { getVariablesFromTemplate, getTemplateJson };
