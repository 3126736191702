import { defaultMemoize } from 'reselect';
import _reject from 'lodash/reject';
import _concat from 'lodash/concat';
import _size from 'lodash/size';

const getUpdatedValue = (selectedDays, id, valueForId) => {
  if (valueForId) {
    // If new value is true, add it to the list of selected days
    return _concat(selectedDays, id);
  }

  // else, remove it from the list
  return _reject(selectedDays, (day) => day === id);
};

const getIsDeselectDisabled = defaultMemoize((value, isDaySelected) => _size(value) === 1 && isDaySelected);

export { getIsDeselectDisabled, getUpdatedValue };
