import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/cas';

// Returns system resources and their field definitions
const fetchSystemResources = () => ApiService.get(`${BASE_URL}/variable-metadata/fetch-all`);

export default {
  fetchSystemResources,
};
