const getUuid = (() => {
  let lastUuid;

  return () => {
    let uuid = Date.now();

    if (uuid <= lastUuid) {
      uuid = lastUuid + 1;
    }

    lastUuid = uuid;
    return uuid.toString();
  };
})();

export default getUuid;
