import { compose } from 'recompose';

import _property from 'lodash/property';
import _get from 'lodash/get';
import _nthArg from 'lodash/nthArg';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';

// Components
import ColumnConfig from '@tekion/tekion-components/organisms/inputTable/builders/ColumnConfig';
import CellConfig from '@tekion/tekion-components/organisms/inputTable/builders/CellConfig';
import SelectInputCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/SelectInputCellRenderer';
import TextDisplayCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/TextDisplayCellRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { COLUMN_IDS, STATUS_COLOR_MAP, STATUS_LABEL_MAP, STATUS_TYPES } from '../constants/fieldMappingInputTable.general';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const COLUMN_WIDTHS = {
  [COLUMN_IDS.FIELD_NAME]: '26rem',
  [COLUMN_IDS.DISPLAY_NAME]: '26rem',
  [COLUMN_IDS.STATUS]: '26rem',
};

const fieldNameAccessor = _property(COLUMN_IDS.FIELD_NAME);
const displayNameAccessor = _property(COLUMN_IDS.DISPLAY_NAME);
const statusFieldAccessor = _property(COLUMN_IDS.STATUS);

const onOptionChange = compose(_property('value'), _nthArg(1));

const getFieldNameOptions = (options, selectedOptions = EMPTY_ARRAY) =>
  _map(options, (field) => {
    const label = _get(field, 'label', '');
    const value = _get(field, 'value', '');

    return {
      label,
      value,
      isDisabled: _includes(selectedOptions, _get(field, 'value')),
    };
  });

const mapFieldNameProps = (props, options, selectedOptions, isOptionDisabled) => ({
  isDisabled: isOptionDisabled,
  isClearable: true,
  options: getFieldNameOptions(options, selectedOptions),
});

const mapStatusProp = (data) => {
  const fieldName = getArraySafeValue(_get(data, `rowData.${COLUMN_IDS.FIELD_NAME}`, ''));
  const displayName = getArraySafeValue(_get(data, `rowData.${COLUMN_IDS.DISPLAY_NAME}`, ''));
  const status = !_isEmpty(fieldName) && !_isEmpty(displayName) ? STATUS_TYPES.MAPPED : STATUS_TYPES.UNMAPPED;

  return {
    value: status,
    colorMap: STATUS_COLOR_MAP,
    labelMap: STATUS_LABEL_MAP,
  };
};

const getFieldNameCell = (options, selectedOptions, isOptionDisabled) =>
  new CellConfig()
    .setComponent(SelectInputCellRenderer)
    .setMapCellPropsToComponentProps((props) => mapFieldNameProps(props, options, selectedOptions, isOptionDisabled));

const getDisplayNameCell = () => new CellConfig().setComponent(TextDisplayCellRenderer);

const getStatusCell = () => new CellConfig().setComponent(StatusCellRenderer).setMapCellPropsToComponentProps((props) => mapStatusProp(props));

const getMappedEntityFieldColumnConfig = (fieldNameOptions = EMPTY_ARRAY, selectedOptions = EMPTY_ARRAY, isOptionDisabled = false) =>
  new ColumnConfig(COLUMN_IDS.FIELD_NAME)
    .setHeader(__('Mapped Entity Fields'))
    .setAccessor(fieldNameAccessor)
    .addCellConfig(getFieldNameCell(fieldNameOptions, selectedOptions, isOptionDisabled))
    .setGetValueFromOnChange(onOptionChange)
    .setWidth(COLUMN_WIDTHS[COLUMN_IDS.FIELD_NAME]);

const getCSVFileFieldColumnConfig = () =>
  new ColumnConfig(COLUMN_IDS.DISPLAY_NAME)
    .setHeader(__('CSV File Fields'))
    .setAccessor(displayNameAccessor)
    .addCellConfig(getDisplayNameCell())
    .setWidth(COLUMN_WIDTHS[COLUMN_IDS.DISPLAY_NAME]);

const getStatusFieldColumnConfig = () =>
  new ColumnConfig(COLUMN_IDS.STATUS)
    .setHeader(__('Status'))
    .setAccessor(statusFieldAccessor)
    .addCellConfig(getStatusCell())
    .setWidth(COLUMN_WIDTHS[COLUMN_IDS.STATUS]);

const getColumns = (fieldNameOptions, selectedFieldNameOptions) => {
  const FIELD_NAME_COLUMN_CONFIG = getMappedEntityFieldColumnConfig(fieldNameOptions, selectedFieldNameOptions);
  const DISPLAY_NAME_COLUMN_CONFIG = getCSVFileFieldColumnConfig();
  const STATUS_COLUMN_CONFIG = getStatusFieldColumnConfig();

  return [FIELD_NAME_COLUMN_CONFIG, DISPLAY_NAME_COLUMN_CONFIG, STATUS_COLUMN_CONFIG];
};

export { getColumns };
