import ACTION_TYPES from './tableCellActionMenu.actionTypes';
import styles from '../tableCellActionMenu.module.scss';
import BackgroundColorSelector from '../molecules/backgroundColorSelector';

const TABLE_CELL_ACTIONS_CONFIG = [
  {
    id: 'CELL_BACKGROUND_ACTION',
    actions: [
      {
        actionType: ACTION_TYPES.CHANGE_CELL_BACKGROUND_COLOR,
        label: __('Cell Background'),
        className: styles.cellActionItem,
        Component: BackgroundColorSelector,
      },
    ],
  },
  {
    id: 'INSERT_ACTIONS',
    actions: [
      {
        actionType: ACTION_TYPES.ADD_ROW,
        label: __('Insert Row'),
        className: styles.cellActionItem,
      },
      {
        actionType: ACTION_TYPES.ADD_COLUMN,
        label: __('Insert Column'),
        className: styles.cellActionItem,
      },
    ],
  },
  {
    id: 'DELETE_ACTIONS',
    actions: [
      {
        actionType: ACTION_TYPES.DELETE_ROW,
        label: __('Delete Row'),
        className: styles.cellActionItem,
      },
      {
        actionType: ACTION_TYPES.DELETE_COLUMN,
        label: __('Delete Column'),
        className: styles.cellActionItem,
      },
    ],
  },
  {
    id: 'DELETE_TABLE_ACTION',
    actions: [
      {
        actionType: ACTION_TYPES.DELETE_TABLE,
        label: __('Delete Table'),
        className: `${styles.cellActionItem} ${styles.deleteTableButton}`,
      },
    ],
  },
];

export default TABLE_CELL_ACTIONS_CONFIG;
