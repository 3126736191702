// components
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import CheckboxInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';

// utils
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

// constants
import FIELD_IDS from './linkInsertionForm.fieldIds';

// styles
import styles from '../linkInsertionForm.module.scss';

export const SECTIONS = [
  {
    className: styles.sectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.TITLE],
      },
      {
        columns: [FIELD_IDS.URL],
      },
      {
        columns: [FIELD_IDS.SHOULD_OPEN_IN_NEW_TAB],
      },
    ],
  },
];

const handlePreventDefault = (event) => event.stopPropagation(); // this prevents the current selection text from getting deselected

export const FIELDS = {
  [FIELD_IDS.TITLE]: {
    renderer: TextInput,
    renderOptions: {
      label: __('Link Title'),
      required: true,
      validators: [isRequiredRule],
    },
  },
  [FIELD_IDS.URL]: {
    renderer: TextInput,
    renderOptions: {
      label: __('Link URL'),
      required: true,
      validators: [isRequiredRule],
    },
  },
  [FIELD_IDS.SHOULD_OPEN_IN_NEW_TAB]: {
    renderer: CheckboxInput,
    renderOptions: {
      checkboxLabel: __(' Open in new tab'),
      onMouseDown: handlePreventDefault,
    },
  },
};
