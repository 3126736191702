import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

// Components
import ValidationRulesRenderer from './validationRulesRenderer';

// Constants
import { VALIDATION_OVERRIDE_FIELD_IDS } from './validationOverrideSection.constants';

import styles from './validationOverrideSection.module.scss';

const ValidationSection = ({
  id,
  outerUserInput,
  actionType,
  errors,
  validationRules,
  formViewOptions,
  value,
  targetEntityFieldsByName,
  onAction,
}) => {
  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { id: fieldId, value: fieldValue } = payload;

          onAction({
            type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
            payload: {
              id,
              value: { ...value, [fieldId]: fieldValue },
            },
          });
          break;
        }
        default:
          onAction(action);
      }
    },
    [id, value, onAction],
  );

  if (_isEmpty(validationRules)) {
    return <div className={styles.container}>{__('No rules added')}</div>;
  }

  return (
    <div className={styles.container}>
      <ValidationRulesRenderer
        id={VALIDATION_OVERRIDE_FIELD_IDS.VALIDATION_OVERRIDES}
        outerUserInput={outerUserInput}
        actionType={actionType}
        value={_get(value, VALIDATION_OVERRIDE_FIELD_IDS.VALIDATION_OVERRIDES, {})}
        errors={errors}
        validationRules={validationRules}
        formViewOptions={formViewOptions}
        targetEntityFieldsByName={targetEntityFieldsByName}
        onAction={handleAction}
      />
    </div>
  );
};

ValidationSection.propTypes = {
  id: PropTypes.number.isRequired,
  outerUserInput: PropTypes.bool,
  actionType: PropTypes.string,
  errors: PropTypes.object,
  formViewOptions: PropTypes.array,
  validationRules: PropTypes.array,
  value: PropTypes.object,
  targetEntityFieldsByName: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ValidationSection.defaultProps = {
  outerUserInput: false,
  actionType: EMPTY_STRING,
  errors: EMPTY_OBJECT,
  validationRules: EMPTY_ARRAY,
  formViewOptions: EMPTY_ARRAY,
  value: EMPTY_OBJECT,
  targetEntityFieldsByName: EMPTY_OBJECT,
};

export default ValidationSection;
