import { defaultMemoize } from 'reselect';
import _isEmpty from 'lodash/isEmpty';

import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import ConditionBuilder from '../../../../../../organisms/conditionBuilder';
import { isConditionRequiredRule } from '../../../../../../organisms/conditionBuilder/helpers/conditionBuilder.general.helpers';
import CONDITION_BUILDER_MODES from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.modes';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import { CONDITION_BUILDER_TYPES } from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import { ENTITY_FIELD, RECORD_GROUP_DESCRIPTION_FIELD, RECORD_GROUP_NAME_FIELD } from '../constants/entityRecordGroupForm.fields';
import { FIELD_IDS } from '../../../../../../constants/entityRecordGroup.constants';

import styles from '../entityRecordGroupForm.module.scss';

const rowCheck = {
  [FIELD_IDS.CONDITION]: ({ isConditionFieldsLoading, entityName }) => !isConditionFieldsLoading && !_isEmpty(entityName),
};

const getSections = defaultMemoize((isConditionFieldsLoading, entityName) => [
  {
    rows: [{ columns: [FIELD_IDS.RECORD_GROUP_NAME, FIELD_IDS.ENTITY] }],
  },
  {
    rows: [{ columns: [FIELD_IDS.DESCRIPTION] }],
  },
  {
    className: styles.fullWidthSection,
    rows: [{ columns: filterRows([FIELD_IDS.CONDITION], { isConditionFieldsLoading, entityName }, rowCheck) }],
  },
]);

const FORM_FIELDS = {
  [FIELD_IDS.RECORD_GROUP_NAME]: RECORD_GROUP_NAME_FIELD,
  [FIELD_IDS.ENTITY]: ENTITY_FIELD,
  [FIELD_IDS.DESCRIPTION]: RECORD_GROUP_DESCRIPTION_FIELD,
};

const getFields = defaultMemoize((conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, formMode) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.ENTITY]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.ENTITY], [{ path: 'formMode', value: formMode }]),
  [FIELD_IDS.CONDITION]: {
    id: FIELD_IDS.CONDITION,
    renderer: ConditionBuilder,
    renderOptions: {
      validators: [isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)],
      required: true,
      containerClassname: fieldLayoutStyles.fieldC,
      mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
      conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
      childProps: {
        [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
          label: '',
          childProps: {
            [CONDITION_FIELD_IDS.FIELD]: {
              label: __('Field'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
              isVariablePrefixNeeded: false,
              variableNameForPrefixNotNeeded: '$record',
            },
            [CONDITION_FIELD_IDS.OPERATOR]: {
              label: __('Operator'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
              isVariablePrefixNeeded: false,
              variableNameForPrefixNotNeeded: '$record',
            },
            [CONDITION_FIELD_IDS.VALUES]: {
              label: __('Value'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
              isVariablePrefixNeeded: false,
              variableNameForPrefixNotNeeded: '$record',
            },
          },
        },
      },
    },
  },
}));

export { getSections, FORM_FIELDS, getFields };
