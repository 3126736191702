const ACTION_TYPES = {
  ON_CLICK_AUTH_CONFIG_TILE: 'ON_CLICK_AUTH_CONFIG_TILE',
  ON_FETCH_LOGIN_CONFIGURATIONS: 'ON_FETCH_LOGIN_CONFIGURATIONS',
  ON_CLOSE_AUTH_CONFIG_MODAL: 'ON_CLOSE_AUTH_CONFIG_MODAL',
  ON_SUBMIT_AUTH_CONFIG_MODAL: 'ON_SUBMIT_AUTH_CONFIG_MODAL',
  ON_REMOVE_CONFIGURATION: 'ON_REMOVE_CONFIGURATION',
  ON_CLICK_REMOVE_ICON: 'ON_CLICK_REMOVE_ICON',
  ON_CLOSE_CONFIRMATION_DIALOG: 'ON_CLOSE_CONFIRMATION_DIALOG',
};

export default ACTION_TYPES;
