import _size from 'lodash/size';
import _reduce from 'lodash/reduce';

import { hasUserAccessDenied, hasUserReadAccessType, hasUserReadWriteAccessType } from '../../helpers/manageRecord.general';

import {
  ALL_ACCESS_ACCESS_FILTER,
  READ_ACCESS_TYPE_FILTER,
  READ_WRITE_ACCESS_TYPE_FILTER,
  ACCESS_DENIED_ACCESS_TYPE_FILTER,
} from './accessTypeFilter.constants';

import styles from './accessTypeFilter.module.scss';

const getReadWriteAndAccessDeniedCount = (shareAndMangeRecordUsersDetails) => {
  const counts = _reduce(
    shareAndMangeRecordUsersDetails,
    (acc, shareAndManageRecordUserDetail) => {
      const isReadAccessType = hasUserReadAccessType(shareAndManageRecordUserDetail);
      const isReadAndWriteAccessType = hasUserReadWriteAccessType(shareAndManageRecordUserDetail);
      const isAccessDenied = hasUserAccessDenied(shareAndManageRecordUserDetail);

      if (isReadAccessType) {
        acc.readCount += 1;
      }

      if (isReadAndWriteAccessType) {
        acc.readWriteCount += 1;
      }

      if (isAccessDenied) {
        acc.accessDeniedCount += 1;
      }

      return acc;
    },
    {
      readCount: 0,
      readWriteCount: 0,
      accessDeniedCount: 0,
    },
  );

  return counts;
};

const getCountByAccessType = (shareAndManageRecordUserDetails) => {
  const allCount = _size(shareAndManageRecordUserDetails);

  const { readCount, readWriteCount, accessDeniedCount } = getReadWriteAndAccessDeniedCount(shareAndManageRecordUserDetails);

  return {
    [ALL_ACCESS_ACCESS_FILTER.id]: allCount,
    [READ_ACCESS_TYPE_FILTER.id]: readCount,
    [READ_WRITE_ACCESS_TYPE_FILTER.id]: readWriteCount,
    [ACCESS_DENIED_ACCESS_TYPE_FILTER.id]: accessDeniedCount,
  };
};

const getIconClassNameForFilterCard = (type) => {
  if (type === READ_ACCESS_TYPE_FILTER.id || type === READ_WRITE_ACCESS_TYPE_FILTER.id) {
    return styles.readAndWriteIconClassName;
  } else {
    return styles.accessDeniedIconClassName;
  }
};

export { getCountByAccessType, getIconClassNameForFilterCard };
