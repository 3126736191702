import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import makeClickableCellRenderer from '../../../../../../../../connectors/makeClickableCellRenderer';
import IconRenderer from '../../../../../../../../atoms/IconRenderer';

import { SUBSCRIPTION_COLUMN_IDS } from '../constants/subscriptionListRenderer.constants';

import styles from '../subscriptionListRenderer.module.scss';

const getIconProps = (iconName) => () => ({
  iconName,
  className: styles.iconContainer,
});

const getColumns = (handleRemoveSubscription, handleEditSubscription) => [
  {
    Header: __('Subscription Type'),
    id: SUBSCRIPTION_COLUMN_IDS.SUBSCRIPTION_TYPE,
    accessor: SUBSCRIPTION_COLUMN_IDS.SUBSCRIPTION_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Entity Name'),
    id: SUBSCRIPTION_COLUMN_IDS.SUBSCRIPTION_ENTITY_NAME,
    accessor: SUBSCRIPTION_COLUMN_IDS.SUBSCRIPTION_ENTITY_NAME,
    Cell: TextRenderer,
  },
  {
    id: SUBSCRIPTION_COLUMN_IDS.SUBSCRIPTION_EDIT,
    accessor: SUBSCRIPTION_COLUMN_IDS.SUBSCRIPTION_EDIT,
    minWidth: 4,
    Cell: makeClickableCellRenderer(handleEditSubscription)(IconRenderer),
    getProps: getIconProps('icon-edit'),
  },
  {
    id: SUBSCRIPTION_COLUMN_IDS.SUBSCRIPTION_DELETE,
    accessor: SUBSCRIPTION_COLUMN_IDS.SUBSCRIPTION_DELETE,
    minWidth: 4,
    Cell: makeClickableCellRenderer(handleRemoveSubscription)(IconRenderer),
    getProps: getIconProps('icon-trash'),
    fixed: 'right',
  },
];

export { getColumns };
