import _includes from 'lodash/includes';

import { tget } from '@tekion/tekion-base/utils/general';

import { PERMISSIONS_ACCESS_TYPES, PERMISSION_ACCESS_IDS } from '../../../../../../../constants/permissionSet.constants';
import { WWD_FIELD_IDS } from '../../../../../../../constants/wwdPermissions.constants';
import { READ_WRITE } from '../../../../../../../constants/recordSharingAccessTypes.constants';
import { RECORD_ACCESS_TYPES } from '../../../../../../../constants/general.constants';

const getEntityAccessTypes = (entityAccessResponse, userId) => {
  const userEntityAccessData = tget(entityAccessResponse, userId);

  const accessTypes = tget(userEntityAccessData, PERMISSION_ACCESS_IDS.ACTION_TYPES);

  return accessTypes;
};

const getWwdAccessType = (wwdData) => {
  const accessType = tget(wwdData, WWD_FIELD_IDS.ACCESS_TYPE);

  return accessType;
};

const getAllAccessTypes = ({ entityAccessResponse, userId, wwdData }) => {
  const entityAccessTypes = getEntityAccessTypes(entityAccessResponse, userId);
  const wwdAccessType = getWwdAccessType(wwdData);

  return { entityAccessTypes, wwdAccessType };
};

const checkForRecordAccessible = (recordAccessType) => recordAccessType === READ_WRITE.id;

const checkForEntityAccessible = (entityAccessTypes) =>
  _includes(entityAccessTypes, PERMISSIONS_ACCESS_TYPES.READ) && _includes(entityAccessTypes, PERMISSIONS_ACCESS_TYPES.UPDATE);

const checkForWWdAccessible = (accessType) => accessType !== RECORD_ACCESS_TYPES.PUBLIC_READ_WRITE;

const optionAccessible = (recordAccessType, entityAccessTypes, wwdAccessType) =>
  checkForRecordAccessible(recordAccessType) && checkForEntityAccessible(entityAccessTypes) && checkForWWdAccessible(wwdAccessType);

export { getAllAccessTypes, optionAccessible };
