const PUSHER_CONNECTION_STATES = {
  INITIALIZED: 'initialized',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  UNAVAILABLE: 'unavailable',
  FAILED: 'failed',
  DISCONNECTED: 'disconnected',
};

const PUSHER_CONNECTION_STATE_CHANGE = 'state_change';

export { PUSHER_CONNECTION_STATE_CHANGE };

export default PUSHER_CONNECTION_STATES;
