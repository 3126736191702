import _reduce from 'lodash/reduce';
import _includes from 'lodash/includes';

import { tget } from '@tekion/tekion-base/utils/general';

import { ASSIGNED_VARIABLE_FIELD_IDS, VARIABLE_TYPES } from '../constants/workflow.assignVariableConstants';
import { VARIABLE_PREFIX_NAME } from '../constants/workflow.constants';

const generateVariableMapForNode = (variables, mapOfVariableToEntityNameForCondition, scopedVariables) =>
  _reduce(
    variables,
    (_variableMap, variableInfo, variableName) => {
      const variableType = tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE);

      if (_includes(scopedVariables, variableName) && variableType !== VARIABLE_TYPES.PRIMITIVE) {
        return { ..._variableMap, [variableName]: tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME) };
      }

      return _variableMap;
    },
    {
      ...mapOfVariableToEntityNameForCondition,
      [VARIABLE_PREFIX_NAME]: VARIABLE_PREFIX_NAME,
    },
  );

export default generateVariableMapForNode;
