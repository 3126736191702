import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextArea from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';

import { FIELD_IDS } from '../../../../../../../constants/roles.constants';

const ROLE_DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Display Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Display Name'),
  },
};

const ROLE_NAME_FIELD = {
  id: FIELD_IDS.ROLE_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Role Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Role Name'),
  },
};

const REPORTS_ROLE_FIELD = {
  id: FIELD_IDS.REPORTS_TO_ROLE,
  renderer: SelectInput,
  renderOptions: {
    label: __('Reports To'),
  },
};

const ROLE_DESCRIPTION_FIELD = {
  id: FIELD_IDS.ROLE_DESCRIPTION,
  renderer: TextArea,
  renderOptions: {
    label: __('Description'),
    placeholder: __('Type Here'),
  },
};

const REPORTEE_FORM_FIELDS = {
  [FIELD_IDS.DISPLAY_NAME]: ROLE_DISPLAY_NAME_FIELD,
  [FIELD_IDS.ROLE_NAME]: ROLE_NAME_FIELD,
  [FIELD_IDS.REPORTS_TO_ROLE]: REPORTS_ROLE_FIELD,
  [FIELD_IDS.ROLE_DESCRIPTION]: ROLE_DESCRIPTION_FIELD,
};

const getFields = (roleOptions) => ({
  ...REPORTEE_FORM_FIELDS,
  [FIELD_IDS.REPORTS_TO_ROLE]: addToRenderOptions(REPORTS_ROLE_FIELD, [{ path: 'options', value: roleOptions }]),
});

export { FIELD_IDS, getFields };
