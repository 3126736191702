import _replace from 'lodash/replace';
import _get from 'lodash/get';
// Constants
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import COLUMN_IDS from './entityRecordGroupList.columnIds';

const getEntityNameAccessor = (rowData) => _replace(_get(rowData, COLUMN_IDS.ENTITY_NAME, EMPTY_STRING), 'ENTITY#', '');

const RECORD_GROUP_LIST_COLUMNS = [
  {
    id: COLUMN_IDS.RECORD_GROUP_NAME,
    Header: __('Record Group Name'),
    accessor: COLUMN_IDS.RECORD_GROUP_NAME,
  },

  {
    id: COLUMN_IDS.ENTITY_NAME,
    Header: __('Entity'),
    accessor: getEntityNameAccessor,
  },
  {
    id: COLUMN_IDS.DESCRIPTION,
    Header: __('Description'),
    accessor: COLUMN_IDS.DESCRIPTION,
  },
];

export default RECORD_GROUP_LIST_COLUMNS;
