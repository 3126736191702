import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const SERVER_CREDENTIAL_BASE_URL = '/core/u/scs/server/credentials';

// APIs for Server Credentials

const fetchServerCredentialByName = (name) => ApiService.get(`${SERVER_CREDENTIAL_BASE_URL}/${name}`);
const createServerCredential = (payload = EMPTY_OBJECT) => ApiService.post(`${SERVER_CREDENTIAL_BASE_URL}`, payload);
const updateServerCredentialByName = (name, payload = EMPTY_OBJECT) => ApiService.put(`${SERVER_CREDENTIAL_BASE_URL}/${name}`, payload);
const searchServerCredentials = (payload = EMPTY_OBJECT) => ApiService.post(`${SERVER_CREDENTIAL_BASE_URL}/search`, payload);

export default {
  fetchServerCredentialByName,
  createServerCredential,
  updateServerCredentialByName,
  searchServerCredentials,
};
