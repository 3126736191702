// Lodash
import _constant from 'lodash/constant';

// Components
import StatusRenderer, { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

// Constants
import COLUMN_IDS from '../constants/workspaceUserList.columnIds';
import { USER_STATUS } from '../constants/workspaceUserList.general.constants';
// Reader
import workspaceUserReader from '../../../../../../readers/workSpaceUser.reader';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const STATUS_COLOR_MAP = {
  [USER_STATUS.ACTIVE]: COLOR_MAP.GREEN,
  [USER_STATUS.INACTIVE]: COLOR_MAP.GREY,
};

const STATUS_LABEL_MAP = {
  [USER_STATUS.ACTIVE]: __('Active'),
  [USER_STATUS.INACTIVE]: __('Inactive'),
};

const getStatusProps = _constant({
  colorMap: STATUS_COLOR_MAP,
  labelMap: STATUS_LABEL_MAP,
});

const USER_LIST_COLUMNS = [
  {
    id: COLUMN_IDS.NAME,
    Header: __('Name'),
    accessor: workspaceUserReader.NAME,
    sortable: true,
    searchable: true,
  },
  {
    id: COLUMN_IDS.DISPLAY_NAME,
    Header: __('Display name'),
    accessor: workspaceUserReader.displayName,
    sortable: true,
    searchable: true,
  },
  {
    id: COLUMN_IDS.EMAIL,
    Header: __('Email'),
    accessor: workspaceUserReader.email,
    sortable: true,
    searchable: true,
  },
  {
    id: COLUMN_IDS.USER_TYPE,
    Header: __('User Type'),
    accessor: workspaceUserReader.userType,
    sortable: true,
    searchable: true,
  },
  {
    id: COLUMN_IDS.ROLE,
    Header: __('Role'),
    accessor: (user) => workspaceUserReader.roleName(user) || workspaceUserReader.roleId(user),
    sortable: true,
    searchable: true,
  },
  {
    id: COLUMN_IDS.ACTIVE,
    Header: __('Status'),
    accessor: workspaceUserReader.active,
    sortable: true,
    searchable: true,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
];

export default USER_LIST_COLUMNS;
