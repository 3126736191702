import { defaultMemoize } from 'reselect';

import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';
import _camelCase from 'lodash/camelCase';
import _slice from 'lodash/slice';
import _uniqBy from 'lodash/uniqBy';
import _head from 'lodash/head';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { COLUMN_IDS, MAX_LENGTH } from '../components/ListInputTable/constants/listInputTable.constants';
import { COLUMNS, ERROR_IDS, ERROR_MESSAGES, FIELD_TYPES } from '../constants/fieldsForm.constants';
import FIELD_IDS from '../constants/fieldsForm.fieldIds';
import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';

const validateInputTable = (values) => {
  let isEmptyValue = false;
  let isValidLength = false;
  _forEach(values, (val) => {
    const label = _get(val, COLUMN_IDS.LABEL, '');
    const value = _get(val, COLUMN_IDS.VALUE, '');
    isEmptyValue = _isEmpty(label) || _isEmpty(value);
    isValidLength = _size(value) <= MAX_LENGTH || _size(value) <= MAX_LENGTH;
  });
  const valid = !isEmptyValue && isValidLength;
  let error = '';
  if (isEmptyValue) error = ERROR_MESSAGES[ERROR_IDS.INPUT_TABLE_EMPTY_VALUE];
  if (!isValidLength) error = ERROR_MESSAGES[ERROR_IDS.INPUT_TABLE_STRING_LENGTH];
  return { valid, error };
};

const validateConfigTable = (values) => {
  let isEmptyValue = false;
  _forEach(values, (val) => {
    const dependencyType = _head(_get(val, COLUMNS.DEPENDENCY_TYPE, ['']));
    const fieldName = _head(_get(val, COLUMNS.FIELD_NAME, ['']));
    isEmptyValue = _isEmpty(fieldName) || _isEmpty(dependencyType);
  });
  const valid = !isEmptyValue;
  let error = '';
  if (isEmptyValue) error = ERROR_MESSAGES[ERROR_IDS.DEPENDENCY_TABLE_EMPTY_VALUE];

  return { valid, error };
};

const validateControllingOption = (values) => {
  let isEmptyValue = false;
  _forEach(values, (val) => {
    const controllingField = _head(_get(val, COLUMNS.OPTION, ['']));
    const controllingOptions = _head(_get(val, COLUMNS.VALUES, ['']));
    isEmptyValue = _isEmpty(controllingField) || _isEmpty(controllingOptions);
  });
  const valid = !isEmptyValue;
  let error = '';
  if (isEmptyValue) error = ERROR_MESSAGES[ERROR_IDS.CONTROLLING_OPTIONS_TABLE_EMPTY_VALUE];

  return { valid, error };
};

const getOptionsByName = (options, filterByAllowLookup = true) => {
  const filteredOptions = filterByAllowLookup ? _filter(options, (option) => _get(option, 'allowLookupUsingField', true)) : options;

  return _map(filteredOptions, (option) => ({
    label: _get(option, 'displayName', ''),
    value: _get(option, 'name', ''),
    dataType: _get(option, 'dataType', ''),
  }));
};

const isRegexRule = (fieldId, valueToTest) => {
  let isValid = true;
  try {
    RegExp(valueToTest);
  } catch {
    isValid = false;
  }
  return isValid ? { isValid } : { isValid, message: __('Not a Valid Regex') };
};
const getLookupEntityFilters = defaultMemoize(() => {
  const filters = [
    {
      field: 'complexField',
      values: [false],
      filterType: OPERATORS.IN,
    },
  ];

  return filters;
});

const getComplexEntityFilters = defaultMemoize((entityName) => [
  {
    field: 'complexField',
    values: [true],
    filterType: OPERATORS.IN,
  },
  {
    field: 'name',
    values: [entityName],
    filterType: OPERATORS.NIN,
  },
]);

const createRadioOption = (option) => ({
  label: _capitalize(option),
  value: option,
});

const pascalCase = (str) => _startCase(_camelCase(str)).replace(/ /g, '');

const getChildEntities = (entityDef) => {
  const entityMetaList = _get(entityDef, 'entityRelationMetadataList', EMPTY_ARRAY);
  const childEntitiesOptions = _uniqBy(
    _map(entityMetaList, (item) => ({
      label: _slice(pascalCase(_get(item, 'relatedEntityName')), 0, -1),
      value: _get(item, 'relatedEntityName'),
    })),
    'value',
  );
  const childRelationFieldOptionsByEntity = [];
  _map(entityMetaList, (item) => {
    const entityName = _get(item, 'relatedEntityName');
    if (!_get(childRelationFieldOptionsByEntity, entityName)) {
      childRelationFieldOptionsByEntity[entityName] = [];
    }

    childRelationFieldOptionsByEntity[entityName].push({
      label: _slice(pascalCase(_get(item, 'relatedField')), 0, -1),
      value: _get(item, 'relatedField'),
    });
  });
  return {
    childEntitiesOptions,
    childRelationFieldOptionsByEntity,
  };
};

const getControllingFieldOptions = (entity, formValues) => {
  let fieldDefinitions = _get(entity, 'fieldDefinitions', EMPTY_ARRAY);
  fieldDefinitions = _filter(fieldDefinitions, (item) => _get(item, 'fieldType') === FIELD_TYPES.SELECT);
  fieldDefinitions = _filter(fieldDefinitions, (item) => getArraySafeValue(_get(formValues, FIELD_IDS.NAME, EMPTY_STRING)) !== _get(item, 'name'));

  return _map(fieldDefinitions, (item) => ({
    label: __(_get(item, 'displayName')),
    value: _get(item, 'name'),
  }));
};

const getValuesOptions = (entity, fieldName) => {
  const fieldDef = _find(_get(entity, 'fieldDefinitions', EMPTY_ARRAY), (field) => {
    const name = fieldDefinitionReader.name(field);
    return name === fieldName;
  });

  const valueOptions = _get(fieldDef, 'optionConfig.options', EMPTY_ARRAY);

  return _map(valueOptions, (item) => ({
    label: _get(item, 'displayName'),
    value: _get(item, 'value'),
  }));
};

const getRegexOptions = (regexes) =>
  _map(regexes, (regex) => ({
    label: __(_get(regex, 'displayName')),
    value: _get(regex, 'id'),
    ...regex,
  }));

const getRegexValue = (regexOptions, value) => {
  const regexValue = _find(regexOptions, (regex) => getArraySafeValue(value) === _get(regex, 'id')) || EMPTY_OBJECT;
  return tget(regexValue, 'regex', EMPTY_STRING);
};

export {
  validateInputTable,
  getOptionsByName,
  isRegexRule,
  getComplexEntityFilters,
  getLookupEntityFilters,
  createRadioOption,
  getChildEntities,
  getControllingFieldOptions,
  getValuesOptions,
  validateConfigTable,
  validateControllingOption,
  getRegexOptions,
  getRegexValue,
};
