import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

import TextInput from '@tekion/tekion-components/molecules/TextInput';
import Modal from '@tekion/tekion-components/molecules/Modal';

import { APPROVAL_REJECT_REQUEST_COMMENT_MODAL_ACTION_TYPES } from '../../../../../../constants/approvalCentre.constants';

const ApprovalAddCommentModal = ({ isAddCommentModalVisible, isRejectLoading, onAction }) => {
  const [rejectRequestCommentContent, setRejectRequestCommentContent] = useState('');

  const handleSubmit = useCallback(() => {
    onAction({
      type: APPROVAL_REJECT_REQUEST_COMMENT_MODAL_ACTION_TYPES.ON_CONFIRM_REJECT_REQUEST,
      payload: { newCommentContent: rejectRequestCommentContent },
    });
    setRejectRequestCommentContent('');
  }, [rejectRequestCommentContent, onAction]);

  const handleCancel = useCallback(() => {
    onAction({
      type: APPROVAL_REJECT_REQUEST_COMMENT_MODAL_ACTION_TYPES.ON_CANCEL_REJECT_REQUEST,
    });
    setRejectRequestCommentContent('');
  }, [onAction]);

  const handleChangeCommentField = useCallback((event) => {
    setRejectRequestCommentContent(event.target.value);
  }, []);

  const renderModalContent = () => <TextInput value={rejectRequestCommentContent} onChange={handleChangeCommentField} />;

  return (
    <Modal
      destroyOnClose
      visible={isAddCommentModalVisible}
      loading={isRejectLoading}
      primaryBtnDisabled={_isEmpty(rejectRequestCommentContent)}
      width={Modal.SIZES.MD}
      title={__('Add Comment')}
      submitBtnText={__('Reject Request')}
      content={renderModalContent()}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />
  );
};

ApprovalAddCommentModal.propTypes = {
  isAddCommentModalVisible: PropTypes.bool,
  isRejectLoading: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
};

ApprovalAddCommentModal.defaultProps = {
  isAddCommentModalVisible: false,
  isRejectLoading: false,
};

export default ApprovalAddCommentModal;
