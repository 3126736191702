import _cloneDeep from 'lodash/cloneDeep';
import _nth from 'lodash/nth';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { FIELD_IDS } from './customRowActionsForm.constants';

const getSelectedCustomRowActionValueFromValues = (values, selectedLeftPanelIndex) => {
  const selectedIndexValue = _cloneDeep(_nth(values, selectedLeftPanelIndex));

  const rendererProps = tget(selectedIndexValue, 'rendererProps', EMPTY_ARRAY);
  const rendererPropsObject = _reduce(
    rendererProps,
    (result, rendererPropValue) => {
      const type = tget(rendererPropValue, 'type');
      const value = tget(rendererPropValue, 'value');
      _set(result, type, value);
      return result;
    },
    {},
  );

  const enableScriptValue = tget(rendererPropsObject, FIELD_IDS.ENABLE_ACTION_SCRIPT, EMPTY_STRING);

  _set(selectedIndexValue, FIELD_IDS.ENABLE_ACTION_SCRIPT, enableScriptValue);

  const configEventValue = getArraySafeValue(tget(selectedIndexValue, FIELD_IDS.EVENT_HANDLERS, [{}]));
  _set(selectedIndexValue, FIELD_IDS.EVENT_HANDLERS, configEventValue);

  _unset(selectedIndexValue, 'rendererProps');

  return selectedIndexValue;
};

const getUpdatedValueFromOnChangeValue = (selectedCustomRowActionValues, payload) => {
  const { id, value } = payload;

  const newValues = _cloneDeep(selectedCustomRowActionValues);

  const enableActionScript = id === FIELD_IDS.ENABLE_ACTION_SCRIPT ? value : tget(newValues, FIELD_IDS.ENABLE_ACTION_SCRIPT);
  const eventData = id === FIELD_IDS.EVENT_HANDLERS ? value : tget(newValues, FIELD_IDS.EVENT_HANDLERS);

  _unset(newValues, FIELD_IDS.ENABLE_ACTION_SCRIPT);
  if (!_isEmpty(enableActionScript)) {
    const rendererProps = [{ type: FIELD_IDS.ENABLE_ACTION_SCRIPT, value: enableActionScript }];
    _set(newValues, 'rendererProps', rendererProps);
  }

  if (!_isEmpty(eventData)) {
    _set(newValues, FIELD_IDS.EVENT_HANDLERS, _castArray(eventData));
  }

  if (id !== FIELD_IDS.ENABLE_ACTION_SCRIPT && id !== FIELD_IDS.EVENT_HANDLERS) {
    _set(newValues, id, value);
  }

  return newValues;
};

export { getSelectedCustomRowActionValueFromValues, getUpdatedValueFromOnChangeValue };
