exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".sideNavItem_container__UYr1s1Qk2W{display:flex;flex-flow:column;justify-content:space-around;align-items:center}[dir] .sideNavItem_container__UYr1s1Qk2W{border-bottom:.1rem solid #969aa3;padding:1.2rem;cursor:pointer}.sideNavItem_containerExpand__hZ6XW9qYb9{display:flex;justify-content:flex-start;align-items:center}[dir] .sideNavItem_containerExpand__hZ6XW9qYb9{border-bottom:.1rem solid #969aa3;padding:1.2rem;cursor:pointer}.sideNavItem_containerExpand__hZ6XW9qYb9 .sideNavItem_content__j7EvvxFutX{font-weight:bold;font-size:1.6rem}[dir=ltr] .sideNavItem_containerExpand__hZ6XW9qYb9 .sideNavItem_content__j7EvvxFutX{margin-left:2rem}[dir=rtl] .sideNavItem_containerExpand__hZ6XW9qYb9 .sideNavItem_content__j7EvvxFutX{margin-right:2rem}.sideNavItem_icon__81uMamMNcH{color:#969aa3}.sideNavItem_activeIcon__r7Lxc1pQ49{color:#4285f4}[dir] .sideNavItem_clickDisabled__ssrCGkKDqE{cursor:not-allowed}", ""]);

// Exports
exports.locals = {
	"container": "sideNavItem_container__UYr1s1Qk2W",
	"containerExpand": "sideNavItem_containerExpand__hZ6XW9qYb9",
	"content": "sideNavItem_content__j7EvvxFutX",
	"icon": "sideNavItem_icon__81uMamMNcH",
	"activeIcon": "sideNavItem_activeIcon__r7Lxc1pQ49",
	"clickDisabled": "sideNavItem_clickDisabled__ssrCGkKDqE"
};