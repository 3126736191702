import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _head from 'lodash/head';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import ResourceField from '../../../../../../../../../organisms/conditionBuilder/atoms/resourceField/ResourceField';
import { CURRENT_ENTITY_NAMESPACE } from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import { ACTION_TYPES } from '../../../constants/addWidget.general.constant';

const ResourceFieldRenderer = (props) => {
  const {
    isRelationshipRecursive,
    isComplexRecursive,
    fieldId,
    column,
    data,
    nestingPath,
    original,
    mapOfVariableToEntityName,
    conditionBuilderFieldDefinitionObject,
    onAction,
  } = props;
  const columnId = _get(column, 'id', '');
  const rowIndex = _head(nestingPath);

  const handleAction = useCallback(
    ({ type, payload }) => {
      if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
        onAction({
          type: ACTION_TYPES.ON_COLUMN_CONFIG_CHANGE,
          payload: {
            id: fieldId,
            columnId,
            value: _get(payload, 'value'),
            rowIndex,
            original,
            displayName: _get(payload, 'option.label', ''),
          },
        });
      }
    },
    [columnId, fieldId, onAction, original, rowIndex],
  );

  return (
    <ResourceField
      isVariablePrefixNeeded={false}
      isRelationshipRecursive={isRelationshipRecursive}
      isComplexRecursive={isComplexRecursive}
      id={`${columnId}_${rowIndex}`}
      value={data}
      variableNameForPrefixNotNeeded={CURRENT_ENTITY_NAMESPACE}
      mapOfVariableToEntityName={mapOfVariableToEntityName}
      conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
      onAction={handleAction}
    />
  );
};

ResourceFieldRenderer.propTypes = {
  isRelationshipRecursive: PropTypes.bool,
  isComplexRecursive: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
  column: PropTypes.object,
  original: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  data: PropTypes.array,
  nestingPath: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
};

ResourceFieldRenderer.defaultProps = {
  isRelationshipRecursive: false,
  isComplexRecursive: false,
  column: EMPTY_OBJECT,
  original: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  data: EMPTY_ARRAY,
};

export default makeCellRenderer(ResourceFieldRenderer);
