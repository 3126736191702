import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _isNil from 'lodash/isNil';
import _pullAt from 'lodash/pullAt';

import InputTable from '@tekion/tekion-components/organisms/inputTable/containers/withRowActions/Table';
import Button from '@tekion/tekion-components/atoms/Button';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/molecules/tableInputField/constants/TableInputField.actionTypes';

// Helpers
import { getActionsForRow, getSelectedOptions } from './helpers/controllingOptionConfigTable.general';
import { getColumns } from './helpers/controllingOptionConfigTable.columns';

// Constants
import { FORM_MODES } from '../../../../../../../../../constants/general.constants';
import { CONTROLLING_OPTION_TABLE_ACTION_TYPES, ROW_ACTION_PROPS } from './constants/controllingOptionConfigTable.general';
import SELECT_OPTIONS_FORM_FIELD_IDS from '../../constants/selectOptionsForm.fieldsIds';

// Styles
import styles from '../../../../fieldsForm.module.scss';

const ControllingOptionConfigTable = ({ isOptionDisabled, isValueDisabled, formMode, options, valuesOptions, error, value, onAction }) => {
  const selectedOptions = getSelectedOptions(value);

  const columns = useMemo(
    () => getColumns(options, selectedOptions, valuesOptions, isOptionDisabled, isValueDisabled),
    [options, selectedOptions, valuesOptions, isOptionDisabled, isValueDisabled],
  );

  const handleAction = useCallback(
    (action) => {
      const actionType = _get(action, 'type', EMPTY_STRING);

      if (actionType === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
        const index = _get(action, 'payload.nestingPath.[0]');
        const fieldId = _get(action, 'payload.id');
        const fieldValue = _get(action, 'payload.value');

        _set(value, [index, fieldId], fieldValue);

        onAction({
          type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
          payload: {
            id: SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS,
            value,
          },
        });
      } else if (actionType === TABLE_ACTION_TYPES.TABLE_ACTION_CLICK) {
        const { payload = EMPTY_OBJECT } = action;
        const tableActionType = _get(payload, 'actionType', EMPTY_STRING);

        if (tableActionType === CONTROLLING_OPTION_TABLE_ACTION_TYPES.REMOVE_ROW) {
          const { nestingPath } = payload;

          _pullAt(value, nestingPath);

          onAction({
            type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
            payload: {
              id: SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS,
              value,
            },
          });
        }
      }
    },
    [onAction, value],
  );

  const handleAddRow = useCallback(() => {
    onAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS, value: [...(_isNil(value) ? [] : value), {}] },
    });
  }, [value, onAction]);

  return (
    <div>
      <InputTable
        className={styles.inputTable}
        rowActionProps={ROW_ACTION_PROPS}
        additional={{ formMode }}
        error={error}
        columns={columns}
        value={value}
        getActionsForRow={getActionsForRow}
        onClick={handleAction}
        onAction={handleAction}
      />
      <Button className={styles.addButton} view={Button.VIEW.TERTIARY} onClick={handleAddRow}>
        {__('Add Controlled Option')}
      </Button>
    </div>
  );
};

ControllingOptionConfigTable.propTypes = {
  isOptionDisabled: PropTypes.bool,
  isValueDisabled: PropTypes.bool,
  formMode: PropTypes.string,
  error: PropTypes.array,
  options: PropTypes.array,
  value: PropTypes.array,
  valuesOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ControllingOptionConfigTable.defaultProps = {
  isOptionDisabled: false,
  isValueDisabled: false,
  formMode: FORM_MODES.CREATE,
  error: undefined,
  value: EMPTY_ARRAY,
  valuesOptions: EMPTY_ARRAY,
  options: EMPTY_ARRAY,
};

export default React.memo(ControllingOptionConfigTable);
