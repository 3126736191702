import React, { useCallback } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components/molecules/loader';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading/Heading';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import withSize from '../../../../connectors/withSize';

import ACTION_HANDLERS from './helpers/importForm.actionHandlers';
import { IMPORT_FORM_FIELDS } from './helpers/importForm.fields';
import importFormSections from './helpers/importForm.sections';

import { INITIAL_STATES_IMPORT_FORM_PROPS, IMPORT_FORM_CONTEXT_ID } from './constants/importForm.general.constants';

const ImportForm = ({ isDataLoading, isSaveLoading, contentHeight, importData, errors, onAction }) => {
  const handleSubmit = useCallback(() => {
    triggerSubmit(IMPORT_FORM_CONTEXT_ID);
  }, []);

  if (isDataLoading) {
    return <Loader />;
  }

  return (
    <Page>
      <Page.Header hasBack>
        <Heading className="full-width">{__('Import Details')}</Heading>
      </Page.Header>

      <Page.Body style={{ height: contentHeight }}>
        <FormWithSubmission
          contextId={IMPORT_FORM_CONTEXT_ID}
          fields={IMPORT_FORM_FIELDS}
          sections={importFormSections()}
          values={importData}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>

      <Page.Footer>
        <SaveComponent primaryButtonLabel={__('Import')} primaryActionLoading={isSaveLoading} onPrimaryAction={handleSubmit} />
      </Page.Footer>
    </Page>
  );
};

ImportForm.propTypes = {
  isDataLoading: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  importData: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ImportForm.defaultProps = {
  isDataLoading: false,
  isSaveLoading: false,
  importData: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default compose(withSize({ hasPageFooter: 1, hasPageHeader: 1 }), withActions(INITIAL_STATES_IMPORT_FORM_PROPS, ACTION_HANDLERS))(ImportForm);
