import React, { Component } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import Icon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Drawer from '@tekion/tekion-components/molecules/drawer';

import AuditLogs from './components/auditLogs';
import ApplicationRendererEventEmitter from '../../eventEmitters/applicationRendererEventEmitter';
import { EVENT_ACTIONS } from '../../constants/eventActions.constants';
import { INITIAL_STATE, BODY_STYLE } from './constants/auditLogsDrawer.general';

import styles from './auditLogsDrawer.module.scss';

class AuditLogsDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.addEventListener();
  }

  componentDidUpdate(prev) {
    const { drawerVisible, showAuditLogs } = this.props;
    if (!prev.drawerVisible && drawerVisible) {
      showAuditLogs();
    }
  }

  componentWillUnmount() {
    this.hideAuditLogDrawer();
    this.removeEventListeners();
  }

  showAuditLogDrawer = ({ entityRecord }) => {
    this.setState({
      visible: true,
      assetId: _get(entityRecord, 'id'),
      assetType: _get(entityRecord, 'entityName'),
      auditParams: {},
    });
  };

  hideAuditLogDrawer = () => {
    this.setState({ visible: false });
  };

  addEventListener() {
    ApplicationRendererEventEmitter.on(EVENT_ACTIONS.SHOW_AUDIT_LOG_DRAWER, this.showAuditLogDrawer);
  }

  // eslint-disable-next-line class-methods-use-this
  removeEventListeners() {
    ApplicationRendererEventEmitter.removeListener(EVENT_ACTIONS.SHOW_AUDIT_LOG_DRAWER, this.showAuditLogDrawer);
  }

  render() {
    const { visible, assetId, assetType, auditParams } = this.state;
    const auditLogsKey = `${assetId}_${assetType}`;
    const { icon, iconSize, showAuditLogs, headingSubPart, contentHeight, showIcon = false } = this.props;
    const heading = _isEmpty(headingSubPart) ? __('Audit Logs') : __('Audit Logs - {{headingSubPart}}', { headingSubPart });
    return (
      <>
        <PropertyControlledComponent controllerProperty={showIcon}>
          <Icon size={iconSize} className="cursor-pointer" onClick={showAuditLogs}>
            {icon}
          </Icon>
        </PropertyControlledComponent>

        <Drawer
          visible={visible}
          closable={false}
          destroyOnClose
          zIndex={2}
          width="60rem"
          bodyStyle={{ ...BODY_STYLE, height: contentHeight }}
          onClose={this.hideAuditLogDrawer}
          className={styles.drawerContainer}
        >
          <AuditLogs
            key={auditLogsKey}
            heading={heading}
            assetId={assetId}
            headingSubPart={headingSubPart}
            contentHeight={contentHeight}
            assetType={assetType}
            auditParams={auditParams}
            closeAuditLogs={this.hideAuditLogDrawer}
          />
        </Drawer>
      </>
    );
  }
}

AuditLogsDrawer.propTypes = {
  showIcon: PropTypes.bool,
  drawerVisible: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  headingSubPart: PropTypes.string,
  showAuditLogs: PropTypes.func.isRequired,
};

AuditLogsDrawer.defaultProps = {
  showIcon: false,
  drawerVisible: false,
  icon: 'icon-history',
  iconSize: SIZES.XL,
  headingSubPart: EMPTY_STRING,
};

export default AuditLogsDrawer;
