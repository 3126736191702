import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _keyBy from 'lodash/keyBy';
import _includes from 'lodash/includes';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import UserAvatar from '../../../../../../../../../atoms/userAvatar/UserAvatar';

import { getStatusIconToDisplay, getStatusTextToDisplay } from './approver.helpers';

import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../../../../../../constants/approvalCentre.constants';
import workspaceUserReader from '../../../../../../../../../readers/workSpaceUser.reader';

import styles from './approver.module.scss';

const Approver = ({ approverId, taskDetails }) => {
  const eligibleApprovers = _keyBy(tget(taskDetails, APPROVAL_CENTRE_FIELD_IDS.ELIGIBLE_APPROVERS), 'id');
  const approverDetails = tget(eligibleApprovers, approverId);
  const name = workspaceUserReader.fullName(approverDetails);
  const picSrc = workspaceUserReader.profilePicture(approverDetails);

  const stageApprovedBy = tget(taskDetails, APPROVAL_CENTRE_FIELD_IDS.APPROVERS, EMPTY_ARRAY);
  const stageRejectedBy = tget(taskDetails, APPROVAL_CENTRE_FIELD_IDS.REJECTORS, EMPTY_ARRAY);

  const hasUserApproved = useMemo(() => _includes(stageApprovedBy, approverId), [stageApprovedBy, approverId]);
  const hasUserRejected = useMemo(() => _includes(stageRejectedBy, approverId), [stageRejectedBy, approverId]);

  const statusIconToDisplay = getStatusIconToDisplay(hasUserApproved, hasUserRejected);
  const statusTextToDisplay = getStatusTextToDisplay(hasUserApproved, hasUserRejected);

  return (
    <div
      className={cx(styles.approversContainer, {
        [styles.approved]: hasUserApproved,
        [styles.rejected]: hasUserRejected,
      })}
    >
      <div className={styles.userContainer}>
        <UserAvatar displayName={name} userAvatar={picSrc} />
        <span className={styles.userName}>{name}</span>
      </div>
      {(hasUserApproved || hasUserRejected) && (
        <div
          className={cx(styles.statusContainer, {
            [styles.approvedStatus]: hasUserApproved,
            [styles.rejectedStatus]: hasUserRejected,
          })}
        >
          <FontIcon className="p-r-4">{statusIconToDisplay}</FontIcon>
          <span>{statusTextToDisplay}</span>
        </div>
      )}
    </div>
  );
};

Approver.propTypes = {
  approverId: PropTypes.string.isRequired,
  taskDetails: PropTypes.array.isRequired,
};

export default Approver;
