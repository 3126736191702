import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getSections } from './listViewColumnPropertiesForm.sections';
import { getFields } from './listViewColumnPropertiesForm.fields';
import { getListViewColumnPropertiesFromProperties, getUpdatedSelectedViewComponentFromOnChangeValue } from './listViewColumnPropertiesForm.helpers';

import { VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

const ListViewColumnPropertiesForm = ({ selectedViewComponent, entity, fieldDefinitionForSelectedFieldName, onAction }) => {
  const properties = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);
  const componentType = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.TYPE);
  const dataType = fieldDefinitionReader.dataType(fieldDefinitionForSelectedFieldName);
  const fieldType = fieldDefinitionReader.fieldType(fieldDefinitionForSelectedFieldName);

  const sections = useMemo(() => getSections(dataType, fieldType), [dataType, fieldType]);
  const fields = useMemo(() => getFields(entity, properties, componentType), [componentType, entity, properties]);
  const listViewColumnProperties = useMemo(() => getListViewColumnPropertiesFromProperties(properties), [properties]);

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const newSelectedViewComponent = getUpdatedSelectedViewComponentFromOnChangeValue(selectedViewComponent, payload);
          onAction({ type, payload: newSelectedViewComponent });
          break;
        }

        default: {
          onAction(action);
          break;
        }
      }
    },
    [selectedViewComponent, onAction],
  );

  return <FormBuilder className="full-width" sections={sections} fields={fields} values={listViewColumnProperties} onAction={handleAction} />;
};

ListViewColumnPropertiesForm.propTypes = {
  selectedViewComponent: PropTypes.object,
  entity: PropTypes.object,
  fieldDefinitionForSelectedFieldName: PropTypes.object,
  onAction: PropTypes.func,
};

ListViewColumnPropertiesForm.defaultProps = {
  selectedViewComponent: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  fieldDefinitionForSelectedFieldName: EMPTY_OBJECT,
  onAction: _noop,
};

export default ListViewColumnPropertiesForm;
