import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/cms';

const fetchEntities = (payload, includeFieldDefinitions) =>
  ApiService.post(`${BASE_URL}/entities/search?includeFields=${includeFieldDefinitions}`, payload);

const fetchEntityByName = (entityName) => ApiService.get(`${BASE_URL}/entities/${entityName}`);

const createEntity = (payload) => ApiService.post(`${BASE_URL}/entities`, payload);

const updateEntityByName = (entityName, payload) => ApiService.put(`${BASE_URL}/entities/${entityName}`, payload);

const fetchFieldDataByName = (entityName, fieldName) => ApiService.get(`${BASE_URL}/entity/${entityName}/fields/${fieldName}`);

const createField = (entityName, payload) => ApiService.post(`${BASE_URL}/entity/${entityName}/fields`, payload);

const updateField = (entityName, fieldName, payload) => ApiService.put(`${BASE_URL}/entity/${entityName}/fields/${fieldName}`, payload);

const updateSelectOption = (entityName, fieldName, value, payload) =>
  ApiService.put(`${BASE_URL}/entity/${entityName}/fields/${fieldName}/options/${value}`, payload);

const addSelectOption = (entityName, fieldName, payload) => ApiService.post(`${BASE_URL}/entity/${entityName}/fields/${fieldName}/options`, payload);

const fetchAllRegex = () => ApiService.post(`${BASE_URL}/regex/definitions/fetch-all`);

const matchRegex = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/regex/match`, payload);

export default {
  fetchEntities,
  fetchEntityByName,
  createEntity,
  updateEntityByName,
  fetchFieldDataByName,
  createField,
  updateField,
  updateSelectOption,
  addSelectOption,
  fetchAllRegex,
  matchRegex,
};
