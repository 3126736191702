export const COLUMN_IDS = {
  COLUMN_DISPLAY_NAME: 'displayName',
  SELECT_FIELD: 'field',
  SELECT_MEASUREMENT: 'measurement',
  FIELD_NAME: 'name',
  FIELD_DRAGGER_ICON: 'draggerIcon',
  SEARCHABLE: 'searchable',
  FILTERABLE: 'filter',
  TYPE: 'metricType',
  DELETE: 'delete',
};
