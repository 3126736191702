import _map from 'lodash/map';
import _valuesIn from 'lodash/valuesIn';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import MultiSelect from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/MultiSelectField';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';

import { convertEnumValue } from '../helpers/addWidget.general.helpers';
import { ADD_WIDGET_FORM_FIELDS } from './addWidget.general.constant';
import { CHART_WIDGET_TYPES } from '../../../../../../../organisms/reporting/molecules/widgetContainer/constants/widgetContainer.general.constants';

const CHART_TYPE_FIELD = {
  id: ADD_WIDGET_FORM_FIELDS.WIDGET_TYPE,
  renderer: SelectInput,
  renderOptions: {
    label: __('Select Chart'),
    validators: [isRequiredRule],
    required: true,
    size: 6,
    options: _map(_valuesIn(CHART_WIDGET_TYPES), (chart) => ({ value: chart, label: convertEnumValue(chart) })),
  },
};

const X_AXIS_FIELD = {
  id: ADD_WIDGET_FORM_FIELDS.X_AXIS,
  renderer: SelectInput,
  renderOptions: {
    label: __('Select X-axis'),
    validators: [isRequiredRule],
    required: true,
  },
};

const Y_AXIS_FIELD = {
  id: ADD_WIDGET_FORM_FIELDS.Y_AXIS,
  renderer: MultiSelect,
  renderOptions: {
    label: __('Select y-axis'),
    validators: [isRequiredRule],
    required: true,
    noOptionsMessage: () => __('No Measurement present for this dimension, please choose another dimension.'),
  },
};

export { CHART_TYPE_FIELD, X_AXIS_FIELD, Y_AXIS_FIELD };
