import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _size from 'lodash/size';

import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Loader from '@tekion/tekion-components/molecules/loader';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';

import RecordTypeCardView from './components/recordTypeCardView';
import OverrideFieldListTable from './components/overrideFieldListTable/OverrideFieldListTable';
import NavigationItem from '../../../../molecules/NavigationFlow/components/navigationItem/NavigationItem';
import withSize from '../../../../connectors/withSize';
import ACTION_HANDLERS from './helpers/recordTypeOverride.actionHandlers';
import { ACTION_TYPES, CONTEXT_ID } from './constants/recordTypeOverride.constants';

import ROUTES from '../../constants/routes';

import styles from './recordTypeOverride.module.scss';
import { FORM_MODES } from '../../../../constants/general.constants';

const RecordTypeOverride = ({
  isModalVisible,
  isSavingDetails,
  isLoading,
  history,
  formMode,
  fieldOverrideFormValues,
  selectedFields,
  errors,
  contentHeight,
  entity,
  recordTypeDetails,
  overrideFieldTableData,
  overrideFieldModalData,
  derivationFields,
  onAction,
}) => {
  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const handleSave = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_SAVE,
    });
  }, [onAction]);

  const handleEditClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_EDIT_CLICK,
    });
  }, [onAction]);

  const headerNavigationData = useMemo(
    () => [
      { label: __('RecordType'), goTo: `${ROUTES.ENTITY_LIST_ROUTE}/${_get(entity, 'name')}/record-type`, key: '1' },
      { label: __(_get(recordTypeDetails, 'name', NO_DATA)), key: '2' },
    ],
    [entity, recordTypeDetails],
  );

  const handleNavigationItemClick = useCallback(
    (goTo) => {
      if (!_isEmpty(goTo)) {
        history.push(goTo);
      }
    },
    [history],
  );

  const headerNavigation = useMemo(
    () =>
      _map(headerNavigationData, (data, index) => (
        <NavigationItem
          key={_get(data, 'key', EMPTY_STRING)}
          itemNumber={index}
          totalItems={_size(headerNavigationData)}
          data={data}
          onNavigationItemClick={handleNavigationItemClick}
        />
      )),
    [headerNavigationData, handleNavigationItemClick],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_MOUNT });
  }, [onAction]);

  if (isLoading) {
    return <Loader style={{ height: 316 }} />;
  }

  return (
    <Page>
      <Page.Header>
        <Heading className={styles.headerNavigationContainer} size={2}>
          {headerNavigation}
        </Heading>
      </Page.Header>
      <Page.Body className="full-width" style={{ height: contentHeight, overflowY: 'auto' }}>
        <RecordTypeCardView entity={entity} recordTypeDetails={recordTypeDetails} handleEditClick={handleEditClick} />

        <OverrideFieldListTable
          isModalVisible={isModalVisible}
          formMode={formMode}
          overrideFieldTableData={overrideFieldTableData}
          overrideFieldModalData={overrideFieldModalData}
          entity={entity}
          derivationFields={derivationFields}
          fieldOverrideFormValues={fieldOverrideFormValues}
          recordTypeDetails={recordTypeDetails}
          selectedFields={selectedFields}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>

      <Page.Footer>
        <SaveComponent
          id={CONTEXT_ID}
          primaryActionLoading={isSavingDetails}
          primaryButtonLabel={__('Save')}
          onPrimaryAction={handleSave}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

RecordTypeOverride.propTypes = {
  isSavingDetails: PropTypes.bool,
  isLoading: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  fieldOverrideFormValues: PropTypes.object,
  entity: PropTypes.object,
  errors: PropTypes.object,
  history: PropTypes.object.isRequired,
  recordTypeDetails: PropTypes.object,
  selectedFields: PropTypes.array,
  derivationFields: PropTypes.array,
  overrideFieldModalData: PropTypes.array,
  overrideFieldTableData: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

RecordTypeOverride.defaultProps = {
  isSavingDetails: false,
  isLoading: false,
  isModalVisible: false,
  formMode: FORM_MODES.CREATE,
  fieldOverrideFormValues: undefined,
  entity: EMPTY_OBJECT,
  recordTypeDetails: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  selectedFields: EMPTY_ARRAY,
  derivationFields: EMPTY_ARRAY,
  overrideFieldModalData: EMPTY_ARRAY,
  overrideFieldTableData: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: true, hasPageFooter: true }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(RecordTypeOverride);
