import _get from 'lodash/get';

import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { STATUS } from '@tekion/tekion-base/constants/statuses';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import TenantUserManagementServices from '../services/tenantUserManagement.services';

const getTenantUserList = async (payload = EMPTY_OBJECT) => {
  try {
    const receivedData = await TenantUserManagementServices.fetchTenantUserList(payload);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch Tenant User data, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const deleteUserById = async (id) => {
  try {
    const response = await TenantUserManagementServices.deleteTenantUserById(id);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to delete User data, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const saveUserData = async (payload) => {
  try {
    const response = await TenantUserManagementServices.saveTenantUserData(payload);
    return _get(response, 'data');
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Save User Data , please try again later.')));
    return STATUS.FAILED;
  }
};

const editUserData = async (id, payload) => {
  try {
    const response = await TenantUserManagementServices.editTenantUserData(id, payload);
    return _get(response, 'data.status');
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Edit User Data , please try again later.')));
    return STATUS.FAILED;
  }
};

const getUserById = async (id) => {
  try {
    const response = await TenantUserManagementServices.getTenantUserById(id);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed get User Data By this ID , please try again later.')));
    return EMPTY_OBJECT;
  }
};

export { getUserById, editUserData, saveUserData, deleteUserById, getTenantUserList };
