import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _uniq from 'lodash/uniq';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

import { tget } from '@tekion/tekion-base/utils/general';
import { TOASTER_TYPE, toaster } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';

// Actions
import {
  approveTask,
  cancelApprovalRequest,
  fetchApprovalTasksForApprovalId,
  getApprovalRequestById,
  rejectTask,
} from '../../../../../../actions/approvalCentre.actions';
import {
  getProcessByName,
  getApprovalSettingForGroupCategory,
  resolveApproversForProcess,
  resolvedApproversForTasks,
  fetchEntityDef,
} from '../../../../../../actions/approvalManagement.actions';
import { createComment, searchComments } from '../../../../../../actions/comments.actions';
import { getWorkspaceUserList } from '../../../../../../actions/workspaceUserManagement.actions';

import { getCommentsPayload, getPayloadForCreateComment, getPayloadForUserIds } from './approvalRequestDetailsPage.general.helpers';

// Constants
import {
  APPROVAL_CENTRE_FIELD_IDS,
  APPROVAL_REJECT_REQUEST_COMMENT_MODAL_ACTION_TYPES,
  APPROVAL_TAB_IDS,
} from '../../../../../../constants/approvalCentre.constants';
import ACTION_TYPES from '../constants/approvalRequestDetailsPage.actionTypes';
import PAGE_IDS from '../../../../constants/pageIds.constants';

const fetchUsers = async (ids) => {
  if (!_isEmpty(ids)) {
    const payload = getPayloadForUserIds(ids);
    const response = await getWorkspaceUserList(payload);
    const users = tget(response, 'hits', EMPTY_ARRAY);
    const userData = _keyBy(users, 'id');
    return userData;
  }

  return EMPTY_OBJECT;
};

const refineData = async (data) => {
  const ids = _uniq(_map(data, (value) => _get(value, APPROVAL_CENTRE_FIELD_IDS.CREATED_BY)));
  const userData = await fetchUsers(ids);
  const updatedData = _map(data, (value) => {
    const userId = tget(value, APPROVAL_CENTRE_FIELD_IDS.CREATED_BY);
    const user = tget(userData, userId, EMPTY_OBJECT);

    return {
      ...value,
      userData: user,
    };
  });

  return updatedData;
};

const handleFetchComments = async ({ setState, getState, params }) => {
  const { approvalId, commentsNextPageToken } = getState();
  const prevCommentsNextPageToken = tget(params, 'commentsNextPageToken', commentsNextPageToken);

  setState({ isShowMoreCommentsLoading: true });
  const commentsPayload = getCommentsPayload(approvalId, prevCommentsNextPageToken);
  const approvalCommentsResponse = await searchComments(commentsPayload);
  const newCommentNextPageToken = _get(approvalCommentsResponse, 'nextPageToken');
  const approvalComments = await refineData(tget(approvalCommentsResponse, 'hits', []));

  setState({
    approvalComments,
    commentsNextPageToken: newCommentNextPageToken,
    isLoading: false,
    isShowMoreCommentsLoading: false,
    hasMoreComments: !_isNil(newCommentNextPageToken),
  });
};

const handleAddComment = async ({ getState, setState, params }) => {
  const { newCommentContent } = params;
  const { approvalId } = getState();

  const payloadForCreateComment = getPayloadForCreateComment(approvalId, newCommentContent);

  setState({ isCommentAdding: true });
  await createComment(payloadForCreateComment);
  setTimeout(async () => {
    await handleFetchComments({ getState, setState, params: { commentsNextPageToken: '' } });
    setState({ isCommentAdding: false, isAddCommentModalVisible: false });
  }, ES_REFETCH_DELAY);
};

const handleInit = async ({ setState, getState }) => {
  setState({ isLoading: true });
  const { history, match } = getState();
  const approvalTask = tget(history, 'location.state.approvalTask', {});
  const approvalId = tget(match, 'params.approvalId', '');
  const approvalTabId = tget(match, 'params.approvalTabId');

  const approvalRequest = await getApprovalRequestById(approvalId);

  const processName = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.PROCESS_NAME);
  const group = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.GROUP);
  const category = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.CATEGORY);
  const type = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.TYPE);

  if (_isEmpty(approvalId) || _isEmpty(processName) || _isEmpty(group) || _isEmpty(category)) {
    setState({ isLoading: false }, () => {
      toaster(TOASTER_TYPE.ERROR, __('Failed to get fetch the request details, please try again later.'));
    });
  }

  const tasksForApprovalRequest = await fetchApprovalTasksForApprovalId(approvalId);
  const approverResolvedTasks = await resolvedApproversForTasks(tasksForApprovalRequest);
  const processForApprovalRequest = await getProcessByName(processName);
  const processStages = tget(processForApprovalRequest, APPROVAL_CENTRE_FIELD_IDS.STAGES, []);
  const resolvedApproversProcessStages = await resolveApproversForProcess(processStages);
  const approverResolvedProcess = { ...processForApprovalRequest, [APPROVAL_CENTRE_FIELD_IDS.STAGES]: resolvedApproversProcessStages };
  const settingForApprovalRequest = await getApprovalSettingForGroupCategory({ group, category });
  const entityDef = await fetchEntityDef({ category, type });

  setState(
    {
      tasksForApprovalRequest: approverResolvedTasks,
      processForApprovalRequest: approverResolvedProcess,
      settingForApprovalRequest,
      approvalId,
      entityDef,
      approvalRequest,
      approvalTask,
      approvalTabId,
    },
    () => handleFetchComments({ getState, setState, params: { commentsNextPageToken: '' } }),
  );
};

const handleApproveRequest = async ({ getState, setState }) => {
  setState({ isApproveLoading: true });

  const { isMountedInsideApplication = false, appRoute, approvalTask, history } = getState();
  const taskId = tget(approvalTask, APPROVAL_CENTRE_FIELD_IDS.ID);

  if (!_isEmpty(taskId)) {
    const response = await approveTask(taskId);
    if (!_isEmpty(response)) {
      let prefixPathName = '';

      if (isMountedInsideApplication) {
        prefixPathName = appRoute;
      }

      const pathName = `${prefixPathName}/${PAGE_IDS.APPROVAL_CENTRE}/${PAGE_IDS.MANAGEMENT}/${APPROVAL_TAB_IDS.APPROVALS}`;

      setTimeout(() => {
        toaster(TOASTER_TYPE.SUCCESS, __('Request approved successfully!'));
        setState({ isApproveLoading: false });

        history.push(pathName);
      }, ES_REFETCH_DELAY);
    } else {
      setState({ isApproveLoading: false });
    }
  } else {
    toaster(TOASTER_TYPE.ERROR, __('Failed to approve this request, please refresh the page and try again.'));
    setState({ isApproveLoading: false });
  }
};

const handleRejectRequest = async ({ getState, setState, params }) => {
  const { newCommentContent } = params;
  setState({ isRejectLoading: true });

  const { isMountedInsideApplication = false, appRoute, approvalTask, history } = getState();

  const taskId = tget(approvalTask, APPROVAL_CENTRE_FIELD_IDS.ID);

  if (!_isEmpty(taskId)) {
    const response = await rejectTask(taskId);
    if (!_isEmpty(response)) {
      let prefixPathName = '';

      if (isMountedInsideApplication) {
        prefixPathName = appRoute;
      }

      const pathName = `${prefixPathName}/${PAGE_IDS.APPROVAL_CENTRE}/${PAGE_IDS.MANAGEMENT}/${APPROVAL_TAB_IDS.APPROVALS}`;

      setTimeout(() => {
        toaster(TOASTER_TYPE.SUCCESS, __('Request rejected successfully!'));
        setState({ isRejectLoading: false }, () => handleAddComment({ getState, setState, params: { newCommentContent } }));

        history.push(pathName);
      }, ES_REFETCH_DELAY);
    } else {
      setState({ isRejectLoading: false, isAddCommentModalVisible: false });
    }
  } else {
    toaster(TOASTER_TYPE.ERROR, __('Failed to reject this request, please refresh the page and try again.'));
    setState({ isRejectLoading: false, isAddCommentModalVisible: false });
  }
};

const handleCloseAddCommentModal = ({ setState }) => {
  setState({ isAddCommentModalVisible: false });
};

const handleClickReject = ({ setState }) => {
  setState({ isAddCommentModalVisible: true });
};

const handleCancelApprovalRequestButtonClick = ({ setState }) => {
  setState({ isConfirmModalVisible: true });
};

const handleCancelCancelApprovalRequestClick = ({ setState }) => {
  setState({ isConfirmModalVisible: false });
};

const handleCancelConfirmClick = async ({ getState, setState }) => {
  const { isMountedInsideApplication = false, appRoute, history, approvalRequest } = getState();

  setState({ isCancellingRequest: true });

  const approvalId = tget(approvalRequest, 'id');
  if (!_isEmpty(approvalId)) {
    await cancelApprovalRequest(approvalId);
  }

  let prefixPathName = '';

  if (isMountedInsideApplication) {
    prefixPathName = appRoute;
  }

  const pathName = `${prefixPathName}/${PAGE_IDS.APPROVAL_CENTRE}/${PAGE_IDS.MANAGEMENT}/${APPROVAL_TAB_IDS.REQUESTS}`;

  history.push(pathName);

  setState({ isCancellingRequest: false });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [ACTION_TYPES.ON_ADD_COMMENT]: handleAddComment,
  [ACTION_TYPES.ON_FETCH_COMMENTS]: handleFetchComments,
  [ACTION_TYPES.APPROVE_REQUEST]: handleApproveRequest,
  [APPROVAL_REJECT_REQUEST_COMMENT_MODAL_ACTION_TYPES.ON_CONFIRM_REJECT_REQUEST]: handleRejectRequest,
  [ACTION_TYPES.REJECT_REQUEST]: handleClickReject,
  [APPROVAL_REJECT_REQUEST_COMMENT_MODAL_ACTION_TYPES.ON_CANCEL_REJECT_REQUEST]: handleCloseAddCommentModal,
  [ACTION_TYPES.ON_CANCEL_APPROVAL_REQUEST_BUTTON_CLICK]: handleCancelApprovalRequestButtonClick,
  [ACTION_TYPES.ON_CANCEL_CANCEL_APPROVAL_REQUEST_MODAL_CLICK]: handleCancelCancelApprovalRequestClick,
  [ACTION_TYPES.ON_CANCEL_CONFIRM_APPROVAL_REQUEST_MODAL_CLICK]: handleCancelConfirmClick,
};

export default ACTION_HANDLERS;
