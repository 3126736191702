const ROW_ACTION_PROPS = {
  visibleActionCount: 1,
};

const REMOVE_ACTION = {
  id: 'REMOVE_ROW',
  name: __('Remove Row'),
  icon: 'icon-trash',
};

const TABLE_ACTION_TYPES = {
  ON_CHANGE: 'ON_CHANGE',
  TABLE_ACTION_CLICK: 'TABLE_ACTION_CLICK',
  REMOVE_ROW: 'REMOVE_ROW',
  EDIT_ROW: 'EDIT_ROW',
};

const ROW_OPERATION = {
  EDIT: 'EDIT',
  ADD: 'ADD',
  DELETE: 'DELETE',
};

export { ROW_ACTION_PROPS, REMOVE_ACTION, TABLE_ACTION_TYPES, ROW_OPERATION };
