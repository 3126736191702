import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE } from '@udecode/plate-code-block';
import { isElement } from '@udecode/plate-core';
import { isType, getParent } from '@udecode/plate-common';
import { unwrapList, toggleList } from '@udecode/plate-list';

const preFormat = (editor) => unwrapList(editor);

const format = (editor, customFormatting) => {
  const selection = _get(editor, 'selection');
  if (!_isEmpty(selection)) {
    const parentEntry = getParent(editor, selection);
    if (!parentEntry) return;
    const [node] = parentEntry;
    if (isElement(node) && !isType(editor, node, ELEMENT_CODE_BLOCK) && !isType(editor, node, ELEMENT_CODE_LINE)) {
      customFormatting();
    }
  }
};

const formatList = (editor, elementType) => {
  format(editor, () =>
    toggleList(editor, {
      type: elementType,
    }),
  );
};

const formatText = (editor, text) => {
  format(editor, () => editor.insertText(text));
};

export { preFormat, format, formatList, formatText };
