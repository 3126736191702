import _size from 'lodash/size';
import _compact from 'lodash/compact';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _forEach from 'lodash/forEach';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// This function is setting the src equal to media id and remove id attribute from the image tag in html.

const getUpdatedImageTagInHtml = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const imgs = doc.getElementsByTagName('img');
  _forEach(imgs, (img) => {
    _set(img, 'src', _get(img, 'id', EMPTY_STRING));
    img.removeAttribute('id');
  });

  return doc.getElementsByTagName('body')[0].innerHTML;
};

// This function is gives mediaId after removing mediaid{}

const getMediaId = (imageString) => {
  if (imageString.startsWith('mediaid{')) {
    return imageString.substring(8, _size(imageString) - 1);
  }
  return '';
};

// This function gives all the mediaIds from the image tag in html.

const getHtmlMediaIds = (html) => {
  const mediaIds = [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const imgs = doc.getElementsByTagName('img');

  _forEach(imgs, (img) => {
    mediaIds.push(getMediaId(img.getAttribute('src')));
  });

  return _compact(mediaIds);
};

// This function puts the newly fetched url in the src and put mediaId in id in image tag in html.

const getResolvedMediaTemplate = (html, mediaData) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const imgs = doc.getElementsByTagName('img');
  _forEach(imgs, (img) => {
    const mediaId = getMediaId(img.getAttribute('src'));
    const url = _get(mediaData, `${mediaId}.normal.url`);
    _set(img, 'id', img.getAttribute('src'));
    _set(img, 'src', url);
  });

  return doc.getElementsByTagName('body')[0].innerHTML;
};

// This function puts the newly fetched url in the image children's data.

const getResolvedMediaJson = (json, mediaData) => {
  const newJson = _map(json, (item) => {
    const type = _get(item, 'type', 'p');
    if (type === 'img') {
      const mediaId = _get(item, 'data.mediaId');
      const url = _get(mediaData, `${mediaId}.normal.url`);
      const data = { ..._get(item, 'data'), url };
      return { ...item, data };
    } else {
      return item;
    }
  });
  return JSON.stringify(newJson);
};

export { getUpdatedImageTagInHtml, getHtmlMediaIds, getResolvedMediaTemplate, getResolvedMediaJson };
