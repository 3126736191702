import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';
import _intersection from 'lodash/intersection';
import _castArray from 'lodash/castArray';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { tget } from '@tekion/tekion-base/utils/general';

import PermissionsError from '../permissionsError';
import { PermissionsContext } from '../../context/permissions.context';
import { PERMISSIONS_CONTEXT_KEYS } from '../../constants/permissionsProvider.constants';

const returnChildren = (controlFlags, validFor, render, props) => {
  const result = _intersection(_castArray(controlFlags), _castArray(validFor));
  if (!_isEmpty(result)) {
    return render(props);
  }

  return <PermissionsError isAccessDenied message={__('You are not authorized to view this page.')} />;
};

// Use this route only when we want to control it by control flags. Otherwise use the normal route.
const PermissionsRoute = ({ render, validFor, ...rest }) => {
  const permissionsContext = useContext(PermissionsContext);
  const controlFlags = tget(permissionsContext, PERMISSIONS_CONTEXT_KEYS.CONTROL_FLAGS, EMPTY_OBJECT);

  return <Route {...rest} render={(props) => returnChildren(controlFlags, validFor, render, props)} />;
};

PermissionsRoute.propTypes = {
  render: PropTypes.func,
  validFor: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

PermissionsRoute.defaultProps = {
  render: _noop,
  validFor: EMPTY_STRING,
};

export default PermissionsRoute;
