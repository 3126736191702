import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { getAllRecordGroups } from '../../../../../../actions/recordGroup.actions';
import { getPayload } from './entityRecordGroupList.helpers';

import ROUTES from '../../../../constants/routes';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/entityRecordGroupList.actionTypes';
import { TABLE_CONSTANTS } from '../constants/entityRecordGroupList.general.constants';
import { FIELD_IDS } from '../../../../../../constants/entityRecordGroup.constants';

const handleInit = async ({ getState, setState, params }) => {
  const { pageSize, searchText } = getState();
  const currentPageToken = _get(params, 'nextPageToken');

  setState({ loading: true });
  const payload = getPayload(searchText, pageSize, currentPageToken);
  const recordGroupDataResponse = await getAllRecordGroups(payload);
  const recordGroupList = tget(recordGroupDataResponse, 'hits', EMPTY_ARRAY);

  setState({
    loading: false,
    totalNumberOfEntries: 0,
    recordGroupList,
    nextPageToken: '',
  });
};

const handleSearchApply = ({ setState, params, getState }) => {
  const searchText = _get(params, 'value');
  const currentPage = TABLE_CONSTANTS.CURRENT_PAGE;
  setState(
    {
      searchText,
      currentPage,
    },
    () => {
      handleInit({ getState, setState });
    },
  );
};

const handlePageUpdate = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  let pageNo = _get(params, 'value.page');
  const resultsPerPage = _get(params, 'value.resultsPerPage');
  let prevPageTokens = [...(previousPageTokens || [])];
  let currentPageToken = null;

  if (pageNo > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (pageNo === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }

  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleInit({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const handleCreateNewRule = ({ getState }) => {
  const { history } = getState();
  const path = `${ROUTES.ENTITY_RECORD_GROUP_CREATE_ROUTE}`;
  history.push(path);
};

const handleEditRecordGroupRequest = ({ getState, params }) => {
  const { history } = getState();

  const recordGroupName = tget(params, FIELD_IDS.RECORD_GROUP_NAME);

  const path = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITY_RECORD_GROUP_EDIT}/${recordGroupName}`;
  history.push({ pathname: path, state: { recordGroupInfo: params } });
};

const handleTableItemClick = ({ params, getState }) => {
  const recordGroupData = _get(params, 'value.original', EMPTY_OBJECT);
  handleEditRecordGroupRequest({ getState, params: recordGroupData });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchApply,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [ACTION_TYPES.CREATE_RECORD_GROUP]: handleCreateNewRule,
  [ACTION_TYPES.EDIT_RECORD_GROUP]: handleEditRecordGroupRequest,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleTableItemClick,
};

export default ACTION_HANDLERS;
