import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import Loader from '@tekion/tekion-components/molecules/loader';

import BlankWidgetContainer from '../../atoms/blankWidgetContainer';
import ApprovalStageCollapsiblePanels from '../../../../molecules/approvalStageCollapsiblePanels';
import EntityViewViewer from '../../../viewBuilder/organisms/entityViewViewer/EntityViewViewer';

import ACTION_HANDLERS from './helpers/formViewWidgetRenderer.actionHandlers';
import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';

import { FORM_MODES } from '../../../../constants/viewBuilder.constants';
import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../constants/approvalCentre.constants';
import { ACTION_TYPES, SAVE_COMPONENT_ID, CONTEXT_ID } from './constants/formViewWidgetRenderer.constants';
import { COMPONENT_CONFIG_KEYS, COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP, WIDGET_TYPES } from '../../constants/visualBuilder.general.constants';
import { CUSTOM_STYLE_IDS } from '../../../../constants/customStyles.constants';

import styles from './formViewWidgetRenderer.module.scss';

const SUPPORTED_CUSTOM_STYLE_FORM = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[WIDGET_TYPES.STANDARD_WIDGET];
const FormViewRendererWidget = ({
  isViewConfigure,
  isViewConfigLoading,
  isSavingDetails,
  isMatchingProcessLoading,
  isMatchingProcessLoaded,
  hasMatchingApprovalProcess,
  formMode,
  entityRecord,
  componentConfig,
  entityViewDefinition,
  entityDef,
  approvalProcess,
  initialFormValues,
  onAction,
}) => {
  const properties = tget(componentConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, EMPTY_OBJECT);

  const customStyles = tget(properties, COMPONENT_CONFIG_KEYS.CUSTOM_STYLES, EMPTY_OBJECT);

  const primaryBtnLabel = useMemo(() => {
    if (hasMatchingApprovalProcess) {
      return __('Send for approval');
    }

    return formMode === FORM_MODES.CREATE ? __('Create') : __('Update');
  }, [hasMatchingApprovalProcess, formMode]);

  const triggerFormSubmit = useCallback(() => {
    triggerSubmit(tget(entityViewDefinition, 'id', CONTEXT_ID));
  }, [entityViewDefinition]);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const handleSubmit = useCallback(
    (payload) => {
      onAction({ type: ACTION_TYPES.ON_FORM_SUBMIT, payload });
    },
    [onAction],
  );

  const handleFieldBlur = useCallback(
    (payload) => {
      onAction({
        type: ACTION_TYPES.ON_FIELD_BLUR,
        payload,
      });
    },
    [onAction],
  );

  const resolvedCustomStyles = useMemo(
    () => getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_FORM, customStyles, entityRecord),
    [entityRecord, customStyles],
  );

  const renderWidget = useCallback(
    () => (
      <div className={styles.formWidget} style={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTAINER, EMPTY_OBJECT)}>
        <div>
          <EntityViewViewer
            isPreviewMode
            widgetName={tget(componentConfig, 'id')}
            contextId={tget(entityViewDefinition, 'id', CONTEXT_ID)}
            entityDef={entityDef}
            entityViewConfiguration={entityViewDefinition}
            initialFormValues={initialFormValues}
            entityRecord={entityRecord}
            onFormFieldBlur={handleFieldBlur}
            onFormSubmit={handleSubmit}
          />
        </div>
        <div>
          {!_isEmpty(approvalProcess) && (
            <div className="p-t-12">
              <ApprovalStageCollapsiblePanels
                isApprovalProcessAvailable
                approvalStages={tget(approvalProcess, APPROVAL_CENTRE_FIELD_IDS.STAGES, EMPTY_ARRAY)}
              />
            </div>
          )}
          <SaveComponent
            id={SAVE_COMPONENT_ID}
            isPrimaryDisabled={isMatchingProcessLoading || !isMatchingProcessLoaded}
            primaryActionLoading={isSavingDetails}
            primaryButtonLabel={primaryBtnLabel}
            onPrimaryAction={triggerFormSubmit}
            onSecondaryAction={handleCancel}
          />
        </div>
      </div>
    ),
    [
      componentConfig,
      entityDef,
      entityRecord,
      entityViewDefinition,
      initialFormValues,
      isSavingDetails,
      approvalProcess,
      handleCancel,
      handleSubmit,
      isMatchingProcessLoading,
      isMatchingProcessLoaded,
      primaryBtnLabel,
      triggerFormSubmit,
      handleFieldBlur,
      resolvedCustomStyles,
    ],
  );

  // To do add Navigate_page_event
  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT_FORM, payload: { componentConfig } });
  }, [onAction, componentConfig]);

  if (isViewConfigLoading) return <Loader />;

  if (!isViewConfigure) return <BlankWidgetContainer componentConfig={componentConfig} />;

  return renderWidget();
};

FormViewRendererWidget.propTypes = {
  isViewConfigLoading: PropTypes.bool,
  isViewConfigure: PropTypes.bool,
  isSavingDetails: PropTypes.bool,
  isMatchingProcessLoading: PropTypes.bool,
  isMatchingProcessLoaded: PropTypes.bool,
  hasMatchingApprovalProcess: PropTypes.bool,
  formMode: PropTypes.string,
  entityDef: PropTypes.object,
  componentConfig: PropTypes.object,
  entityViewDefinition: PropTypes.object,
  entityRecord: PropTypes.object,
  initialFormValues: PropTypes.object,
  approvalProcess: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

FormViewRendererWidget.defaultProps = {
  isViewConfigLoading: true,
  isViewConfigure: true,
  isSavingDetails: false,
  isMatchingProcessLoading: false,
  isMatchingProcessLoaded: false,
  hasMatchingApprovalProcess: false,
  formMode: FORM_MODES.CREATE,
  entityDef: undefined,
  componentConfig: undefined,
  entityViewDefinition: undefined,
  entityRecord: undefined,
  approvalProcess: undefined,
  initialFormValues: EMPTY_OBJECT,
};

export default compose(withRouter, withActions(EMPTY_OBJECT, ACTION_HANDLERS))(FormViewRendererWidget);
