import _head from 'lodash/head';
import _last from 'lodash/last';
import _join from 'lodash/join';
import _split from 'lodash/split';
import _forEach from 'lodash/forEach';
import _replace from 'lodash/replace';
import _get from 'lodash/get';
import _snakeCase from 'lodash/snakeCase';
import _includes from 'lodash/includes';
import _valuesIn from 'lodash/valuesIn';
import _nth from 'lodash/nth';

import { DATE_TIME_FORMATS, getFormattedDateTime as _getFormattedDateTime, getDateDifferenceHumanised } from '@tekion/tekion-base/utils/dateUtils';
import { NO_DATA } from '@tekion/tekion-base/app.constants';

import SYSTEM_DEFINED_FIELDS from '../constants/systemDefinedFields';
import { VIEW_BUILDER_DATE_TIME_FORMATS } from '../constants/viewBuilder.constants';

const getFormattedDateTime =
  (format = DATE_TIME_FORMATS.ABBREVIATED_DATE_ALPHA_MONTH_WITH_TIME) =>
  (value) => {
    if (format === VIEW_BUILDER_DATE_TIME_FORMATS.DATE_DIFFERENCE_HUMANISED) {
      return value ? getDateDifferenceHumanised(value) : NO_DATA;
    } else {
      return value ? _getFormattedDateTime(value, format) : NO_DATA;
    }
  };

const toDisplayDateRange =
  (format = DATE_TIME_FORMATS.ABBREVIATED_DATE_ALPHA_MONTH_WITH_TIME) =>
  (value) => {
    const startDate = getFormattedDateTime(format)(_head(value));
    const endDate = getFormattedDateTime(format)(_last(value));
    return `From: ${startDate}, To: ${endDate}`;
  };

const joinByCommas = (value) => _join(value, ', ');

const toDisplayNumberRange = (value) => `From: ${_head(value)}, To: ${_last(value)}`;

const getSimpleFieldNameFromColumn = (fieldName) => _head(_split(fieldName, '.'));
// this function is use to get first field name.  (relationship__u.name) ==> relationship__u

const getNthChildOfFieldName = (fieldName, index) => _nth(_split(fieldName, '.'), index);
// this function is use to get nth field name.
// (relationship__u.name, 0) ==> relationship__u
// (relationship__u.name, 1) ==> name

const getValueAsTemplate = (template, value) => {
  const templateRegex = /\${(.*?)}/gm;
  const regexIterator = Array.from(template.matchAll(templateRegex));
  let finalValue = template;

  _forEach(regexIterator, (regexMatch) => {
    const stringMatch = _head(regexMatch);
    const stringValue = _last(regexMatch);
    finalValue = _replace(finalValue, stringMatch, _get(value, stringValue));
  });

  return finalValue;
};

const convertToSpaceLessLowerCaseString = (string) => _snakeCase(string);

const isSystemDefinedField = (field) => _includes(_valuesIn(SYSTEM_DEFINED_FIELDS), field);

const getUuid = (() => {
  let lastUuid;

  return () => {
    let uuid = Date.now();

    if (uuid <= lastUuid) {
      uuid = lastUuid + 1;
    }

    lastUuid = uuid;
    return uuid.toString();
  };
})();

export {
  getFormattedDateTime,
  toDisplayDateRange,
  toDisplayNumberRange,
  joinByCommas,
  getSimpleFieldNameFromColumn,
  getValueAsTemplate,
  convertToSpaceLessLowerCaseString,
  isSystemDefinedField,
  getNthChildOfFieldName,
  getUuid,
};
