import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';

// Helpers
import { getFields } from './constants/roleForm.fields';
import REPORTEE_FORM_SECTIONS from './constants/roleForm.sections';

const RoleForm = ({ contextId, errors, roleData, roleOptions, onAction }) => {
  const fields = useMemo(() => getFields(roleOptions), [roleOptions]);

  return (
    <FormWithSubmission
      contextId={contextId}
      fields={fields}
      sections={REPORTEE_FORM_SECTIONS}
      values={roleData}
      errors={errors}
      onAction={onAction}
    />
  );
};

RoleForm.propTypes = {
  contextId: PropTypes.string,
  errors: PropTypes.object,
  roleData: PropTypes.object,
  roleOptions: PropTypes.array,
  onAction: PropTypes.func,
};

RoleForm.defaultProps = {
  contextId: EMPTY_STRING,
  errors: EMPTY_OBJECT,
  roleData: EMPTY_OBJECT,
  roleOptions: EMPTY_ARRAY,
  onAction: _noop,
};

export default RoleForm;
