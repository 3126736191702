import _get from 'lodash/get';

import { fetchPageConfigurationByName } from '../../../../../../../../../actions/visualBuilder.actions';

import { ACTION_TYPES } from '../constants/applicationPageContent.constants';
import { PROPERTIES_FIELD_IDS } from '../../../constants/applicationBuilder.constants';

const handleInit = async ({ getState, setState }) => {
  const { tabContent } = getState();
  const pageName = _get(tabContent, PROPERTIES_FIELD_IDS.PAGE_NAME);
  const pageConfiguration = await fetchPageConfigurationByName(pageName);
  setState({ pageConfiguration });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
};

export default ACTION_HANDLERS;
