import { tget } from '@tekion/tekion-base/utils/general';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FIELD_IDS } from './formViewColumnPropertiesForm.constants';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';
import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import { TEXT_INPUT_TYPES } from '../../constants/viewBuilder.constants';

import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

import styles from './formViewColumnPropertiesForm.module.scss';

const rowCheck = {
  [FIELD_IDS.COMPONENT_RENDERER]: ({ fieldDefinitionForSelectedFieldName }) =>
    (fieldDefinitionReader.dataType(fieldDefinitionForSelectedFieldName) === DATA_TYPES.TEXT &&
      fieldDefinitionReader.fieldType(fieldDefinitionForSelectedFieldName) === FIELD_TYPES.TEXT) ||
    fieldDefinitionReader.dataType(fieldDefinitionForSelectedFieldName) === DATA_TYPES.BOOLEAN,
  [FIELD_IDS.TEXT_AREA_ROWS]: ({ properties }) => {
    const selectedTextInput = tget(properties, ALL_VIEW_PROPERTY_KEYS.COMPONENT_RENDERER);

    return selectedTextInput === TEXT_INPUT_TYPES.TEXT_AREA;
  },
};

const getSections = (fieldDefinitionForSelectedFieldName, properties) => [
  {
    rows: [
      {
        columns: [FIELD_IDS.MANDATORY],
      },
      {
        columns: [FIELD_IDS.EDITABLE],
      },
      {
        columns: [FIELD_IDS.DISABLED],
      },
      {
        columns: filterRows([FIELD_IDS.COMPONENT_RENDERER], { fieldDefinitionForSelectedFieldName }, rowCheck),
      },
      {
        columns: filterRows([FIELD_IDS.TEXT_AREA_ROWS], { properties }, rowCheck),
      },
      {
        columns: [FIELD_IDS.PREFILLED_VALUE],
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.EVENT_HANDLERS],
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.CUSTOM_STYLES],
      },
    ],
  },
];

export { getSections };
