import _get from 'lodash/get';

import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { STATUS } from '@tekion/tekion-base/constants/statuses';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import WorkspaceUserManagementServices from '../services/workspaceUserManagement.services';

const getWorkspaceUserList = async (payload = EMPTY_OBJECT) => {
  try {
    const receivedData = await WorkspaceUserManagementServices.fetchWorkspaceUserList(payload);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch Workspace User data, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const removeUserById = async (userId, workspaceId) => {
  try {
    const response = await WorkspaceUserManagementServices.removeWorkspaceUserById(userId, workspaceId);
    toaster(TOASTER_TYPE.SUCCESS, __('User removed successfully'));
    return _get(response, 'data.status');
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to delete User data, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const saveUserData = async (payload) => {
  try {
    const response = await WorkspaceUserManagementServices.saveWorkspaceUserData(payload);
    return _get(response, 'data');
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Save User Data , please try again later.')));
    return STATUS.FAILED;
  }
};

const editUserData = async (id, payload) => {
  try {
    const response = await WorkspaceUserManagementServices.editWorkspaceUserData(id, payload);
    return _get(response, 'data.status');
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Edit User Data , please try again later.')));
    return STATUS.FAILED;
  }
};

const getUserById = async (id) => {
  try {
    const response = await WorkspaceUserManagementServices.getWorkspaceUserById(id);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed get User Data By this ID , please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getUserWorkspaceDetailsById = async (userId) => {
  try {
    const response = await WorkspaceUserManagementServices.fetchUserWorkspaceDetailsById(userId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch User Details, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export { getUserWorkspaceDetailsById, getUserById, editUserData, saveUserData, getWorkspaceUserList, removeUserById };
