import GENERAL_ACTION_HANDLERS from './generalActionHandlers';
import TAB_ACTION_HANDLERS from './tabActionHandlers';
import FORM_ACTION_HANDLERS from './formActionHandlers';

const ACTION_HANDLERS = {
  ...GENERAL_ACTION_HANDLERS,
  ...TAB_ACTION_HANDLERS,
  ...FORM_ACTION_HANDLERS,
};

export default ACTION_HANDLERS;
