import { defaultMemoize } from 'reselect';

import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _reduce from 'lodash/reduce';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _keyBy from 'lodash/keyBy';
import _values from 'lodash/values';

import { EMPTY_ARRAY, EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';
import { getMomentValueOf, toMoment } from '@tekion/tekion-base/utils/dateUtils';

import FilterBuilder from '../../../../../../../../../builders/filterBuilder/FilterBuilder';

import { getWorkspaceUserList } from '../../../../../../../../../actions/workspaceUserManagement.actions';
import { getAllRecordGroups } from '../../../../../../../../../actions/recordGroup.actions';

import { APPROVAL_CENTRE_FIELD_IDS, APPROVAL_VALIDITY_TYPES } from '../../../../../../../../../constants/approvalCentre.constants';
import { ROWS, STAGE_APPROVER_TYPES } from '../constants/approvalStageFieldRenderer.constants';
import { FIELD_IDS as ROLE_FIELD_IDS } from '../../../../../../../../../constants/roles.constants';
import { FIELD_IDS as ENTITY_RECORD_GROUP_FIELD_IDS } from '../../../../../../../../../constants/entityRecordGroup.constants';

const getPayload = (nextPageToken = null, searchText = EMPTY_OBJECT, selectedIds = EMPTY_ARRAY) => {
  const filters = [];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: 'displayName',
      values: [searchText],
      filterType: OPERATORS.TEXT_STARTS_WITH,
    });
  }

  if (!_isEmpty(selectedIds) && _isEmpty(searchText)) {
    filters.push({
      field: 'id',
      values: selectedIds,
      filterType: OPERATORS.NIN,
    });
  }

  return {
    rows: ROWS,
    nextPageToken,
    filters,
  };
};

const getPayloadForSelectedUserValues = (values) => {
  const filters = new FilterBuilder().addIdFilter({ values }).getFilterPayload();
  return {
    filters,
  };
};

const getPayloadForSelectedUserGroupValues = (values) => {
  const filters = new FilterBuilder().addNameFilter({ values }).getFilterPayload();
  return {
    filters,
  };
};

const generateRoleOptions = defaultMemoize((data, rolesOptions) => {
  const roleData = tget(data, 'role', EMPTY_OBJECT);
  const children = tget(data, 'childRoles');

  rolesOptions.push({
    id: tget(roleData, ROLE_FIELD_IDS.ROLE_NAME),
    type: STAGE_APPROVER_TYPES.ROLE,
    description: __('Role'),
    displayName: tget(roleData, ROLE_FIELD_IDS.DISPLAY_NAME, tget(roleData, ROLE_FIELD_IDS.ROLE_NAME, NO_DATA)),
  });

  _map(children, (child) => generateRoleOptions(child, rolesOptions));
});

const createOptionsForStageApprovers = (roles, users, userGroups) => {
  const userOptions = _map(users, (user) => ({
    id: tget(user, 'id'),
    type: STAGE_APPROVER_TYPES.USER,
    description: __('User'),
    displayName: tget(user, 'displayName', tget(user, 'name', NO_DATA)),
  }));

  const userGroupOptions = _map(userGroups, (userGroup) => ({
    id: tget(userGroup, ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME),
    type: STAGE_APPROVER_TYPES.USER_GROUP,
    description: __('User Group'),
    displayName: tget(
      userGroup,
      ENTITY_RECORD_GROUP_FIELD_IDS.DISPLAY_NAME,
      tget(userGroup, ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME, NO_DATA),
    ),
  }));

  return [...roles, ...userOptions, ...userGroupOptions];
};

const getFormFormattedApprovalStageDetails = (payload) => {
  const minApprovals = tget(payload, APPROVAL_CENTRE_FIELD_IDS.MIN_APPROVALS, 0);
  let validity = tget(payload, APPROVAL_CENTRE_FIELD_IDS.VALIDITY, EMPTY_OBJECT);
  let approvers = tget(payload, APPROVAL_CENTRE_FIELD_IDS.APPROVERS, EMPTY_ARRAY);

  approvers = _map(approvers, (approver) => tget(approver, 'approverId'));

  if (tget(validity, APPROVAL_CENTRE_FIELD_IDS.VALIDITY_TYPE) === APPROVAL_VALIDITY_TYPES.EXACT) {
    const epoch = tget(validity, APPROVAL_CENTRE_FIELD_IDS.VALIDITY_EPOCH);
    validity = {
      ...validity,
      [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_EPOCH]: toMoment(epoch),
    };
  }

  return {
    ...validity,
    [APPROVAL_CENTRE_FIELD_IDS.MIN_APPROVALS]: minApprovals,
    [APPROVAL_CENTRE_FIELD_IDS.APPROVERS]: approvers,
  };
};

const getPayloadForApprovalStage = (formValues, stageApproversById) => {
  const validityType = tget(formValues, APPROVAL_CENTRE_FIELD_IDS.VALIDITY_TYPE);

  let validity = {};

  if (validityType === APPROVAL_VALIDITY_TYPES.EXACT) {
    const epoch = getMomentValueOf(tget(formValues, APPROVAL_CENTRE_FIELD_IDS.VALIDITY_EPOCH));

    validity = {
      [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_TYPE]: validityType,
      [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_EPOCH]: epoch,
    };
  } else if (validityType === APPROVAL_VALIDITY_TYPES.RELATIVE) {
    validity = _pick(formValues, [
      APPROVAL_CENTRE_FIELD_IDS.VALIDITY_TYPE,
      APPROVAL_CENTRE_FIELD_IDS.VALIDITY_DAYS,
      APPROVAL_CENTRE_FIELD_IDS.VALIDITY_HOURS,
      APPROVAL_CENTRE_FIELD_IDS.VALIDITY_MINUTES,
    ]);
  }

  const minApprovals = tget(formValues, APPROVAL_CENTRE_FIELD_IDS.MIN_APPROVALS);
  let approvers = tget(formValues, APPROVAL_CENTRE_FIELD_IDS.APPROVERS);
  approvers = _map(approvers, (approverId) => tget(stageApproversById, [approverId]));

  return {
    [APPROVAL_CENTRE_FIELD_IDS.APPROVERS]: approvers,
    [APPROVAL_CENTRE_FIELD_IDS.MIN_APPROVALS]: minApprovals,
    [APPROVAL_CENTRE_FIELD_IDS.VALIDITY]: validity,
  };
};

const getStageApproversById = (stageApproverOptions) =>
  _reduce(
    stageApproverOptions,
    (prevStageApproversById, role) => {
      const id = tget(role, 'id');
      const type = tget(role, 'type');

      return {
        ...prevStageApproversById,
        [id]: {
          approverId: id,
          type,
        },
      };
    },
    {},
  );

const resolveSelectedUsersAndUserGroups = async (stageDetailsInEditMode, rolesOptions) => {
  const approvers = tget(stageDetailsInEditMode, APPROVAL_CENTRE_FIELD_IDS.APPROVERS, EMPTY_ARRAY);

  const userApprovers = [];
  const userGroupApprovers = [];
  const roleApprovers = [];

  _forEach(approvers, (approver) => {
    const approverId = tget(approver, 'approverId');
    const type = tget(approver, 'type');

    if (type === STAGE_APPROVER_TYPES.USER) {
      userApprovers.push(approverId);
    } else if (type === STAGE_APPROVER_TYPES.USER_GROUP) {
      userGroupApprovers.push(approverId);
    } else if (type === STAGE_APPROVER_TYPES.ROLE) {
      roleApprovers.push(approverId);
    }
  });

  let users = [];
  let userGroups = [];
  let roles = [];

  if (!_isEmpty(userApprovers)) {
    const payloadForUsers = getPayloadForSelectedUserValues(userApprovers);
    const usersResponse = await getWorkspaceUserList(payloadForUsers);
    users = tget(usersResponse, 'hits', EMPTY_ARRAY);
  }

  if (!_isEmpty(userGroupApprovers)) {
    const payloadForUserGroups = getPayloadForSelectedUserGroupValues(userGroupApprovers);
    const userGroupsResponse = await getAllRecordGroups(payloadForUserGroups);
    userGroups = tget(userGroupsResponse, 'hits', EMPTY_ARRAY);
  }

  if (!_isEmpty(roleApprovers)) {
    roles = _filter(rolesOptions, (roleOption) => {
      const id = tget(roleOption, 'id');
      return _includes(roleApprovers, id);
    });
  }

  const initialOptionsForStageApprovers = createOptionsForStageApprovers(roles, users, userGroups);

  return { initialOptionsForStageApprovers, userApprovers, userGroupApprovers, roleApprovers };
};

const getApproverOptionsBasedOnApproverType = (approverType, initialOptionsForStageApprovers) => {
  if (approverType === STAGE_APPROVER_TYPES.ALL) {
    return initialOptionsForStageApprovers;
  } else if (approverType === STAGE_APPROVER_TYPES.USER) {
    return _filter(initialOptionsForStageApprovers, { type: STAGE_APPROVER_TYPES.USER });
  } else if (approverType === STAGE_APPROVER_TYPES.USER_GROUP) {
    return _filter(initialOptionsForStageApprovers, { type: STAGE_APPROVER_TYPES.USER_GROUP });
  } else if (approverType === STAGE_APPROVER_TYPES.ROLE) {
    return _filter(initialOptionsForStageApprovers, { type: STAGE_APPROVER_TYPES.ROLE });
  }
  return [];
};

const getSelectedApproverOptions = (selectedApprovers, selectedApproverOptions, approverOptions, initialOptionsForStageApprovers) => {
  const combinedApproverOptions = [...approverOptions, ...initialOptionsForStageApprovers, ...selectedApproverOptions];
  const combinedApproverOptionsById = _keyBy(combinedApproverOptions, 'id');
  const newSelectedApproverOptions = _pick(combinedApproverOptionsById, selectedApprovers);

  return _values(newSelectedApproverOptions);
};

export {
  getPayload,
  createOptionsForStageApprovers,
  generateRoleOptions,
  getPayloadForApprovalStage,
  getFormFormattedApprovalStageDetails,
  resolveSelectedUsersAndUserGroups,
  getStageApproversById,
  getApproverOptionsBasedOnApproverType,
  getSelectedApproverOptions,
};
