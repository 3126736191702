import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { VIEW_CONFIGURATION_FIELD_IDS, VIEW_TYPES } from '../../../../../../../constants/viewBuilder.constants';

const generateFormViewDefsPayload = (entityName) => ({
  filters: [
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      values: [entityName],
      filterType: OPERATORS.IN,
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
      values: [VIEW_TYPES.FORM_VIEW],
      filterType: OPERATORS.IN,
    },
  ],
  rows: 1,
});

export { generateFormViewDefsPayload };
