import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _castArray from 'lodash/castArray';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import Button from '@tekion/tekion-components/atoms/Button';
import Module from '@tekion/tekion-components/molecules/leftPanelItem';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

import AsyncPaginatedSelect from '../../../../../../../../../../../../../../organisms/asyncPaginatedSelect/AsyncPaginatedSelect';
import { fetchEntities } from '../../../../../../../../../../../../../../actions/entityManagement.actions';
import { searchEntityViewConfigurations } from '../../../../../../../../../../../../../../actions/entityViewDefinitions.actions';

import {
  getEntitiesOptions,
  getPayloadForEntitySearch,
  getPayloadForViewDefinitionsSearch,
  getViewsOptions,
} from './helpers/selectedEntityModal.helpers';
import ACTION_TYPES from '../../../../constants/tabProperties.actionTypes';
import { ENTITY_INFO_FIELD_IDS as FIELD_IDS } from '../../../../constants/tabProperties.constants';
import { VIEW_TYPES } from '../../../../../../../../../../../../../../constants/viewBuilder.constants';

import styles from './selectedEntityModal.module.scss';

const SelectedEntityModal = ({ selectedModuleIndex, error, selectedEntities, value, onAction }) => {
  const ref = useRef(null);

  const selectedValue = _get(value, selectedModuleIndex);
  const selectedError = useMemo(() => _get(error, selectedModuleIndex), [error, selectedModuleIndex]);

  const handleDeleteRow = useCallback(
    (moduleIndex) => (event) => {
      onAction({
        type: ACTION_TYPES.ON_DELETE_ROW,
        payload: { moduleIndex },
      });
      event.stopPropagation();
    },
    [onAction],
  );

  const handleAddRow = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_ADD_ROW,
    });
  }, [onAction]);

  const handleTabChange = useCallback(
    (moduleIndex) => {
      onAction({ type: ACTION_TYPES.ON_CHANGE_MODULE, payload: { moduleIndex } });
    },
    [onAction],
  );

  const handleAction = useCallback(
    ({ payload }) => {
      const actionType = _get(payload, 'eventDetails.action', ACTION_TYPES.ON_CHANGE);
      if (actionType === 'select-option' || actionType === ACTION_TYPES.ON_CHANGE) onAction({ type: ACTION_TYPES.ON_MODAL_FIELD_CHANGE, payload });
    },
    [onAction],
  );

  const renderModuleItem = useCallback(
    (searchOptionDisplayName, moduleIndex) => (
      <div
        className={cx(styles.moduleItem, {
          [styles.selectedBackgroundColor]: selectedModuleIndex === moduleIndex,
        })}
      >
        <div className={styles.moduleSection}>
          <div className={styles.moduleItemTitle}>{__(!_isEmpty(searchOptionDisplayName) ? searchOptionDisplayName : __('Untitled'))}</div>
          <FontIcon className={styles.moduleItemDeleteIcon} onClick={handleDeleteRow(moduleIndex)}>
            icon-trashcan
          </FontIcon>
        </div>
      </div>
    ),
    [handleDeleteRow, selectedModuleIndex],
  );

  const renderModule = useCallback(
    () =>
      _map(value, (currentValue, moduleIndex) => {
        const displayName = tget(currentValue, FIELD_IDS.ENTITY_NAME);

        return (
          <Module
            key={moduleIndex}
            moduleId={moduleIndex}
            title={renderModuleItem(displayName, moduleIndex)}
            selected={selectedModuleIndex === moduleIndex}
            onModuleSelect={handleTabChange}
          />
        );
      }),
    [value, handleTabChange, renderModuleItem, selectedModuleIndex],
  );

  useEffect(() => {
    const resetLoadedOptions = tget(ref, 'current.resetLoadedOptions', _noop);
    resetLoadedOptions();
  }, [selectedEntities]);

  return (
    <div className={styles.selectEntityHandlerModal}>
      <div className={styles.selectEntitySideBar}>
        <Button view={Button.VIEW.TERTIARY} className={styles.addEventButton} onClick={handleAddRow}>
          <FontIcon className={styles.addIcon}>icon-add-circle</FontIcon>
          {__('Add Entity')}
        </Button>
        {renderModule()}
      </div>
      <PropertyControlledComponent controllerProperty={!_isEmpty(value)}>
        <div className={styles.container}>
          <AsyncPaginatedSelect
            shouldFetchOnValueChange
            required
            id={FIELD_IDS.ENTITY_NAME}
            error={_get(selectedError, FIELD_IDS.ENTITY_NAME)}
            label={__('Entity')}
            ref={ref}
            value={_get(selectedValue, FIELD_IDS.ENTITY_NAME)}
            validators={[isRequiredRule]}
            getPayload={getPayloadForEntitySearch(selectedEntities)}
            getOptions={getEntitiesOptions}
            serviceHandler={fetchEntities}
            onAction={handleAction}
          />
          <div key={_get(selectedValue, FIELD_IDS.ENTITY_NAME)} className={styles.selectViews}>
            <AsyncPaginatedSelect
              shouldFetchOnValueChange
              required
              isDisabled={_isEmpty(_get(selectedValue, FIELD_IDS.ENTITY_NAME))}
              id={FIELD_IDS.FORM_VIEW_NAME}
              label={__('FormView')}
              value={_get(selectedValue, FIELD_IDS.FORM_VIEW_NAME)}
              error={_get(selectedError, FIELD_IDS.FORM_VIEW_NAME)}
              validators={[isRequiredRule]}
              getPayload={getPayloadForViewDefinitionsSearch(_castArray(_get(selectedValue, FIELD_IDS.ENTITY_NAME)), [VIEW_TYPES.FORM_VIEW])}
              getOptions={getViewsOptions}
              serviceHandler={searchEntityViewConfigurations}
              onAction={handleAction}
            />
            <AsyncPaginatedSelect
              shouldFetchOnValueChange
              required
              isDisabled={_isEmpty(_get(selectedValue, FIELD_IDS.ENTITY_NAME))}
              id={FIELD_IDS.DETAIL_VIEW_NAME}
              label={__('DetailView')}
              value={_get(selectedValue, FIELD_IDS.DETAIL_VIEW_NAME)}
              error={_get(selectedError, FIELD_IDS.DETAIL_VIEW_NAME)}
              validators={[isRequiredRule]}
              getPayload={getPayloadForViewDefinitionsSearch(_castArray(_get(selectedValue, FIELD_IDS.ENTITY_NAME)), [VIEW_TYPES.DETAIL_VIEW])}
              getOptions={getViewsOptions}
              serviceHandler={searchEntityViewConfigurations}
              onAction={handleAction}
            />
          </div>
        </div>
      </PropertyControlledComponent>
    </div>
  );
};

SelectedEntityModal.propTypes = {
  selectedModuleIndex: PropTypes.number,
  value: PropTypes.object,
  error: PropTypes.array,
  selectedEntities: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

SelectedEntityModal.defaultProps = {
  selectedModuleIndex: undefined,
  value: EMPTY_OBJECT,
  error: EMPTY_ARRAY,
  selectedEntities: EMPTY_ARRAY,
};

export default SelectedEntityModal;
