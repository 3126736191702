import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import { SIZES } from '@tekion/tekion-components/atoms/FontIcon/Icon.constants';

import ACTION_TYPES from '../../constants/recordSharingRuleList.actionTypes';
import styles from './recordSharingRuleUserDeleteCellIconRenderer.module.scss';

const RecordSharingRuleUserDeleteCellIconRenderer = ({ index, assignType, onAction }) => {
  const handleOnClickDeleteIcon = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_REMOVE_ASSIGNED_USERS, payload: { index, assignType } });
  }, [onAction, assignType, index]);

  return (
    <FontIcon className={styles.deleteIcon} size={SIZES.MD} onClick={handleOnClickDeleteIcon}>
      icon-trash
    </FontIcon>
  );
};

RecordSharingRuleUserDeleteCellIconRenderer.propTypes = {
  index: PropTypes.number,
  assignType: PropTypes.string,
  onAction: PropTypes.func.isRequired,
};

RecordSharingRuleUserDeleteCellIconRenderer.defaultProps = {
  index: 0,
  assignType: EMPTY_STRING,
};

export default makeCellRenderer(RecordSharingRuleUserDeleteCellIconRenderer);
