import { useCallback, useMemo, useState } from 'react';

import useActionBuilderActions from './hooks/useActionBuilderActions';
import useCustomActions from './hooks/useCustomActions';
import useShareAndManageRecordActions from './hooks/useShareAndManageRecordActions';

import { combineOptions } from './useActionRenderer.helpers';

const useActionRenderer = ({ actionModalContextId, entityDef, entityRecord, actionBuilderProps, customActionsData }) => {
  const [isDataLoading, setDataLoading] = useState(false);

  const { actionBuilderOptions, handleActionBuilderOnOpen } = useActionBuilderActions({
    actionModalContextId,
    entityDef,
    entityRecord,
    actionBuilderProps,
  });
  const { customActionOptions } = useCustomActions({ customActionsData, entityRecord });
  const { shareAndManageRecordOptions, handleShareAndManageRecordOnOpen } = useShareAndManageRecordActions({ entityRecord, entityDef });

  const handleDropDownClick = useCallback(
    async (event) => {
      event.stopPropagation();

      setDataLoading(true);

      await Promise.all([handleActionBuilderOnOpen(), handleShareAndManageRecordOnOpen()]);

      setDataLoading(false);
    },
    [handleActionBuilderOnOpen, handleShareAndManageRecordOnOpen],
  );

  const options = useMemo(
    () => combineOptions(actionBuilderOptions, customActionOptions, shareAndManageRecordOptions),
    [actionBuilderOptions, customActionOptions, shareAndManageRecordOptions],
  );

  return { isDataLoading, options, handleDropDownClick };
};

export default useActionRenderer;
