import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FIELD_IDS } from './sectionPropertiesForm.constants';
import { VIEW_TYPES } from '../../../../constants/viewBuilder.constants';

import styles from './sectionPropertiesForm.module.scss';

const rowCheck = {
  [FIELD_IDS.COLUMN_COUNT]: ({ viewType }) =>
    viewType !== VIEW_TYPES.LIST_VIEW && viewType !== VIEW_TYPES.FORM_VIEW && viewType !== VIEW_TYPES.GRID_VIEW,
};

const getSections = (viewType) => [
  {
    rows: [
      {
        columns: filterRows([FIELD_IDS.COLUMN_COUNT], { viewType }, rowCheck),
      },
    ],
  },
  {
    header: {
      label: __('Section Title Properties'),
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.TITLE, FIELD_IDS.SECTION_TITLE_SIZE],
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.EVENT_HANDLERS],
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.CUSTOM_STYLES],
      },
    ],
  },
];

export { getSections };
