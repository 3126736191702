import React from 'react';
import PropTypes from 'prop-types';

import _head from 'lodash/head';
import _get from 'lodash/get';
import _find from 'lodash/find';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Cell Renderers
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import { ACTION_TYPES } from '../../../constants/addWidget.general.constant';
import { COLUMN_IDS } from '../columnConfig.general.constants';
import { DATA_TYPES, FIELD_TYPES } from '../../../../../../fieldsForm/constants/fieldsForm.constants';

const CheckboxCellRenderer = (props) => {
  const { column, nestingPath, onAction, original, fieldId, options } = props;
  let { data } = props;
  const columnId = _get(column, 'id', '');
  const rowIndex = _head(nestingPath);
  const selectField = _head(_get(original, COLUMN_IDS.SELECT_FIELD));
  const fieldData = _find(options, (item) => item.name === selectField);
  const isDisabled =
    !_get(fieldData, 'filterable', false) ||
    _get(fieldData, 'fieldType') === FIELD_TYPES.RELATIONSHIP ||
    _get(fieldData, 'dataType') === DATA_TYPES.COMPLEX;
  if (isDisabled) {
    data = false;
  }

  const handleAction = ({ payload }) => {
    onAction({
      type: ACTION_TYPES.ON_COLUMN_CONFIG_CHANGE,
      payload: {
        id: fieldId,
        columnId,
        value: _get(payload, 'value'),
        rowIndex,
      },
    });
  };

  return <CheckboxRenderer id={`${columnId}_${rowIndex}`} value={data} onAction={handleAction} disabled={isDisabled} />;
};

CheckboxCellRenderer.propTypes = {
  column: PropTypes.object,
  data: PropTypes.bool,
  nestingPath: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
  original: PropTypes.object,
  options: PropTypes.array,
  fieldId: PropTypes.string.isRequired,
};

CheckboxCellRenderer.defaultProps = {
  column: EMPTY_OBJECT,
  data: false,
  options: EMPTY_ARRAY,
  original: EMPTY_OBJECT,
};

export default makeCellRenderer(CheckboxCellRenderer);
