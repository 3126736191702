import _map from 'lodash/map';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _find from 'lodash/find';
import _reduce from 'lodash/reduce';
import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import _split from 'lodash/split';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import getOperatorForFilterType from '@tekion/tekion-components/organisms/filterSection/factory/filterDialog.operator';
import getComponentForFilter from '@tekion/tekion-components/organisms/filterSection/filterDialog/containers/withFilterValue/withFilterValue.factory';
import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';

import FIELD_TYPES from '../../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../../constants/fieldDefinition.dataTypes';
import fieldDefinitionReader from '../../../../../../readers/fieldDefinition.reader';

const getOptions = (filters) => {
  const filterOptions = _map(filters, (filter) => {
    const label = _get(filter, 'name', EMPTY_STRING);
    let value = _get(filter, 'id', EMPTY_STRING);
    const type = _get(filter, 'type', EMPTY_STRING);

    if (value === OPERATORS.CONTAINS) {
      value = OPERATORS.IN;
    } else if (value === OPERATORS.NOT_CONTAINS) {
      value = OPERATORS.NIN;
    }

    return {
      label,
      value,
      type,
    };
  });
  return filterOptions;
};

const getFilterFieldOption = (fieldTypeOptions, fieldName) => _find(fieldTypeOptions, (option) => _get(option, 'value') === fieldName);

const getOptionsForOperator = (fieldTypeOptions, fieldName) => {
  const filterType = getFilterFieldOption(fieldTypeOptions, fieldName);
  const operators = getOperatorForFilterType(filterType);
  const operatorOptions = getOptions(operators);

  return operatorOptions;
};

const getComponentForFilterHelper = (fieldTypeOptions, fieldName, operatorType) => {
  const filterType = getFilterFieldOption(fieldTypeOptions, fieldName);
  const label = tget(filterType, 'label', EMPTY_STRING);
  const type = tget(filterType, 'type', EMPTY_STRING);
  const additional = tget(filterType, 'additional', EMPTY_OBJECT);

  const componentDetails = getComponentForFilter(
    { id: fieldName, name: label, type, additional },
    { operator: _head(operatorType), type: fieldName },
  );

  return componentDetails;
};

const getFilterForSelect = (filter, options) => ({
  ...filter,
  type: FILTER_TYPES.MULTI_SELECT,
  additional: {
    options: _map(options, (option) => ({
      label: _get(option, 'displayName'),
      value: _get(option, 'value'),
      additional: {
        ...option,
      },
    })),
  },
});

const getFilterForSimpleColumn = (fieldName, displayName, fieldDef) => {
  let fieldType = fieldDefinitionReader.fieldType(fieldDef);
  const dataType = fieldDefinitionReader.dataType(fieldDef);

  if (fieldType === FIELD_TYPES.SELECT) {
    const multiValued = fieldDefinitionReader.multiValued(fieldDef);
    if (multiValued) {
      fieldType = FILTER_TYPES.MULTI_SELECT;
    } else {
      fieldType = FILTER_TYPES.SINGLE_SELECT;
    }
  }

  const filter = {
    label: displayName,
    value: fieldName,
    type: FILTER_TYPES.STRING,
  };
  const options = fieldDefinitionReader.options(fieldDef) || [];

  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      switch (dataType) {
        case DATA_TYPES.DATE:
        case DATA_TYPES.DATE_TIME:
          return { ...filter, type: FILTER_TYPES.DATE };
        case DATA_TYPES.NUMBER:
          return { ...filter, type: FILTER_TYPES.NUMBER };
        default:
          return filter;
      }

    case FIELD_TYPES.SINGLE_SELECT:
    case FIELD_TYPES.MULTI_SELECT:
      return getFilterForSelect(filter, options);

    case FIELD_TYPES.RELATIONSHIP: {
      const lookUpField = _last(_split(fieldName, '.'));
      const relationshipEntityFieldDefinitions = fieldDefinitionReader.relationshipEntityFieldDefinitions(fieldDef) || {};
      const lookUpFieldDef = _get(relationshipEntityFieldDefinitions, lookUpField, {});
      const lookUpFilter = getFilterForSimpleColumn(lookUpField, displayName, lookUpFieldDef);
      return { ...lookUpFilter, value: fieldName };
    }
    default:
      return filter;
  }
};

const getFilters = (fieldDefinitions) => {
  const filters = _reduce(
    fieldDefinitions,
    (prevFilters, currentFieldDef) => {
      const isColumnFilterable = fieldDefinitionReader.filterable(currentFieldDef);
      if (!isColumnFilterable) return prevFilters;

      const displayName = fieldDefinitionReader.displayName(currentFieldDef);
      const fieldType = fieldDefinitionReader.fieldType(currentFieldDef);
      const dataType = fieldDefinitionReader.dataType(currentFieldDef);
      const fieldName = fieldDefinitionReader.name(currentFieldDef);

      if (fieldType === FIELD_TYPES.RELATIONSHIP || dataType === DATA_TYPES.COMPLEX) {
        let entityFieldDefinitions = {};

        if (fieldType === FIELD_TYPES.RELATIONSHIP) {
          entityFieldDefinitions = fieldDefinitionReader.relationshipEntityFieldDefinitions(currentFieldDef) || {};
        } else {
          entityFieldDefinitions = fieldDefinitionReader.complexEntityFieldDefinitions(currentFieldDef) || {};
        }

        let entityFieldFilters = getFilters(entityFieldDefinitions);
        entityFieldFilters = _map(entityFieldFilters, ({ label, value, ...restProps }) => ({
          ...restProps,
          label: !_isEmpty(label) ? `${displayName} - ${label}` : displayName,
          value: `${fieldName}.${value}`,
        }));

        return [...prevFilters, ...entityFieldFilters];
      } else {
        const filter = getFilterForSimpleColumn(fieldName, displayName, currentFieldDef);
        return [...prevFilters, filter];
      }
    },
    [],
  );

  return _compact(filters);
};

export { getOptionsForOperator, getComponentForFilterHelper, getFilterFieldOption, getFilters };
