import { FIELD_IDS } from './roleForm.fields';

const REPORTEE_FORM_SECTIONS = [
  {
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.ROLE_NAME],
      },
      {
        columns: [FIELD_IDS.REPORTS_TO_ROLE],
      },
      {
        columns: [FIELD_IDS.ROLE_DESCRIPTION],
      },
    ],
  },
];

export default REPORTEE_FORM_SECTIONS;
