import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { COMPONENT_TYPES } from '../../constants/viewBuilder.constants';
import { DND_ICON_DIRECTION } from './viewComponent.const';

const shouldApplyPadding = (style = {}) => {
  const widthPercentage = tget(style, 'width', '');
  const widthStr = getArraySafeValue(widthPercentage.match(/\d+/));

  if (!_isEmpty(widthStr)) {
    const width = Number(widthStr);
    return width >= 5;
  }

  return true;
};

const getDndButtonDirection = (componentType) => {
  if (
    componentType === COMPONENT_TYPES.SECTION_COLUMN ||
    componentType === COMPONENT_TYPES.FORM_VIEW_RENDERER ||
    componentType === COMPONENT_TYPES.LIST_VIEW_RENDERER ||
    componentType === COMPONENT_TYPES.COLUMN ||
    componentType === COMPONENT_TYPES.GRID_VIEW
  ) {
    return null;
  } else if (componentType === COMPONENT_TYPES.SECTION || componentType === COMPONENT_TYPES.FORM_VIEW_SECTION) {
    return DND_ICON_DIRECTION.TOP;
  }

  return DND_ICON_DIRECTION.LEFT;
};

export { shouldApplyPadding, getDndButtonDirection };
