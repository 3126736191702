const TREE_ACTION_TYPES = {
  ON_INITIALIZE_ROLES: 'ON_INITIALIZE_ROLES',
  ON_DELETE_ROLE: 'ON_DELETE_ROLE',
  ON_EXPAND_ROLE: 'ON_EXPAND_ROLE',
  ON_EDIT_ROLE: 'ON_EDIT_ROLE',
  ON_CLICK_ADD_REPORTEE: 'ON_CLICK_ADD_REPORTEE',
  ON_CLICK_COLLAPSE_ALL: 'ON_CLICK_COLLAPSE_ALL',
  ON_CLICK_EXPAND_ALL: 'ON_CLICK_EXPAND_ALL',
  ON_SELECT_ROLE: 'ON_SELECT_ROLE',
  ON_SEARCH_ROLE: 'ON_SEARCH_ROLE',
};

const MODAL_ACTION_TYPES = {
  ON_CLICK_CLEAR_FIELDS: 'ON_CLICK_CLEAR_FIELDS',
  ON_CLOSE_DELETE_MODAL: 'ON_CLOSE_DELETE_MODAL',
  ON_CLICK_DONE: 'ON_CLICK_DONE',
  ON_CHANGE_ROLE: 'ON_CHANGE_ROLE',
  ON_CLICK_MODAL_ADD_NEW_REPORTEE: 'ON_CLICK_MODAL_ADD_NEW_REPORTEE',
};

export { TREE_ACTION_TYPES, MODAL_ACTION_TYPES };
