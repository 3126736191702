import _get from 'lodash/get';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import PROPERTIES_FORM_FIELD_IDS from './propertiesForm.fieldIds';

import styles from '../propertiesForm.module.scss';
import fieldsFormStyles from '../../../fieldsForm.module.scss';

const rowCheck = {
  [PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION]: (sortable) => sortable,
};

const getSections = (values) => [
  {
    header: {
      label: __('Field Properties'),
      className: fieldsFormStyles.formSectionHeader,
    },
    rows: [
      {
        columns: [PROPERTIES_FORM_FIELD_IDS.MANDATORY, PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT],
      },
      {
        columns: [PROPERTIES_FORM_FIELD_IDS.CREATABLE, PROPERTIES_FORM_FIELD_IDS.EDITABLE],
      },
      {
        columns: [PROPERTIES_FORM_FIELD_IDS.IMPORTABLE, PROPERTIES_FORM_FIELD_IDS.EXPORTABLE],
      },
      {
        columns: [PROPERTIES_FORM_FIELD_IDS.SEARCHABLE, PROPERTIES_FORM_FIELD_IDS.FILTERABLE],
      },
      {
        className: styles.center,
        columns: filterRows(
          [PROPERTIES_FORM_FIELD_IDS.SORTABLE, PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION],
          _get(values, PROPERTIES_FORM_FIELD_IDS.SORTABLE, false),
          rowCheck,
        ),
      },
      {
        columns: [PROPERTIES_FORM_FIELD_IDS.AUDIT_ENABLED],
      },
    ],
  },
];
export default getSections;
