import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

// Tekion Components
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import Label from '@tekion/tekion-components/atoms/Label';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Loader from '@tekion/tekion-components/molecules/loader';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

// Components
import HelperText from '@tekion/tekion-components/atoms/HelperText';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import Popover, { POPOVER_PLACEMENT, POPOVER_TRIGGER } from '@tekion/tekion-components/molecules/popover';
import ConditionList from './organisms/conditionList';

// Helpers
import { deleteRow } from './helpers/conditionBuilder.general.helpers';

// Constants
import CONDITION_BUILDER_FIELD_IDS from './constants/conditionBuilder.fieldIds';
import CONDITION_BUILDER_MODES from './constants/conditionBuilder.modes';
import CONDITION_BUILDER_ACTION_TYPES from './constants/conditionBuilder.actionTypes';
import { CONDITION_BUILDER_TYPES, HELPER_TEXT_VALUE_FIELD, SYSTEM_DEFINED_VARIABLES } from './constants/conditionBuilder.general';

// Styles
import styles from './conditionBuilder.module.scss';

const ConditionBuilder = ({
  isFieldsLoading,
  required,
  id,
  conditionBuilderType,
  containerClassname,
  mode,
  header,
  label,
  value,
  error,
  childProps,
  onAction,
}) => {
  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE:
          {
            const { id: fieldId, value: fieldValue } = payload;

            const updatedValue = {
              ...value,
              [fieldId]: fieldValue,
              [CONDITION_BUILDER_FIELD_IDS.TYPE]: conditionBuilderType,
            };

            onAction({
              type,
              payload: {
                id,
                value: updatedValue,
              },
            });
          }
          break;
        case CONDITION_BUILDER_ACTION_TYPES.ADD_ROW: {
          const { id: fieldId, value: fieldValue } = payload;
          const existingExpression = value?.[CONDITION_BUILDER_FIELD_IDS.EXPRESSION];
          let conditionBuilderExpression = `${_size(fieldValue)}`;
          if (existingExpression) {
            conditionBuilderExpression = `${existingExpression} AND ${conditionBuilderExpression}`;
          }
          const updatedValue = {
            ...value,
            [fieldId]: fieldValue,
            [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: conditionBuilderExpression,
            [CONDITION_BUILDER_FIELD_IDS.TYPE]: conditionBuilderType,
          };
          onAction({
            type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
            payload: {
              id,
              value: updatedValue,
            },
          });

          onAction({ type: FORM_ACTION_TYPES.VALIDATION_SUCCESS, payload: { errors: error } });
          break;
        }
        case CONDITION_BUILDER_ACTION_TYPES.DELETE_ROW: {
          const { index } = payload;
          const numberToDelete = `${index + 1}`;
          const conditionExpression = value?.[CONDITION_BUILDER_FIELD_IDS.EXPRESSION];
          const criteriaList = value?.[CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST];
          const updatedCriteriaList = [...criteriaList];
          updatedCriteriaList.splice(index, 1);

          const newConditionExpression = deleteRow(conditionExpression, numberToDelete);

          onAction({
            type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
            payload: {
              id,
              value: {
                ...value,
                [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: updatedCriteriaList,
                [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: newConditionExpression,
                [CONDITION_BUILDER_FIELD_IDS.TYPE]: conditionBuilderType,
              },
            },
          });
          break;
        }
        default: {
          onAction(action);
        }
      }
    },
    [id, conditionBuilderType, value, error, onAction],
  );

  const renderPopOverContent = () => (
    <>
      <HelperText className="m-t-4">{HELPER_TEXT_VALUE_FIELD[SYSTEM_DEFINED_VARIABLES.RECORD]}</HelperText>
      <HelperText className="m-t-8">{HELPER_TEXT_VALUE_FIELD[SYSTEM_DEFINED_VARIABLES.SYSTEM]}</HelperText>
      <HelperText className="m-t-8">{HELPER_TEXT_VALUE_FIELD[SYSTEM_DEFINED_VARIABLES.CURRENT_USER]}</HelperText>
    </>
  );

  return (
    <div className={`${styles.mainContainer} ${containerClassname}`}>
      {!_isEmpty(header) && (
        <Heading size={2} className="m-b-16">
          {header}
        </Heading>
      )}

      {!isFieldsLoading && !_isEmpty(label) && (
        <Label required={required} className="m-b-8">
          {label}
        </Label>
      )}

      <div className={styles.subContainer}>
        <PropertyControlledComponent controllerProperty={!isFieldsLoading} fallback={<Loader id={id} />}>
          <div className={styles.logicInfoContainers}>
            {mode === CONDITION_BUILDER_MODES.CONDITION_MODE && (
              <div className={styles.logicContainer}>
                <TextInput
                  required={required}
                  id={CONDITION_BUILDER_FIELD_IDS.EXPRESSION}
                  containerClassName={styles.conditionLogicContainer}
                  label={__('Condition Logic')}
                  value={value?.[CONDITION_BUILDER_FIELD_IDS.EXPRESSION]}
                  error={error?.[CONDITION_BUILDER_FIELD_IDS.EXPRESSION]}
                  {...childProps?.[CONDITION_BUILDER_FIELD_IDS.EXPRESSION]}
                  onAction={handleAction}
                />
              </div>
            )}
            <div className={styles.infoContainer}>
              <Popover
                arrowPointAtCenter
                destroyTooltipOnHidecontent
                trigger={POPOVER_TRIGGER.HOVER}
                placement={POPOVER_PLACEMENT.LEFT}
                overlayClassName={styles.variablesInfo}
                content={renderPopOverContent()}
              >
                <span className="cursor-pointer">
                  <FontIcon>icon-info</FontIcon>
                </span>
              </Popover>
            </div>
          </div>
          <ConditionList
            required={required}
            id={CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST}
            mode={mode}
            showIndex={mode === CONDITION_BUILDER_MODES.CONDITION_MODE}
            label={__('Conditions')}
            value={value?.[CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]}
            error={error?.[CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]}
            {...childProps?.[CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]}
            onAction={handleAction}
          />
        </PropertyControlledComponent>
      </div>
    </div>
  );
};

ConditionBuilder.propTypes = {
  isFieldsLoading: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  conditionBuilderType: PropTypes.oneOf([CONDITION_BUILDER_TYPES.CRITERIA, CONDITION_BUILDER_TYPES.SCRIPT]),
  containerClassname: PropTypes.string,
  mode: PropTypes.string,
  header: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.object,
  error: PropTypes.object,
  childProps: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ConditionBuilder.defaultProps = {
  isFieldsLoading: false,
  required: false,
  conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
  containerClassname: '',
  mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
  header: '',
  label: '',
  value: EMPTY_OBJECT,
  error: EMPTY_OBJECT,
  childProps: EMPTY_OBJECT,
};

export default ConditionBuilder;
