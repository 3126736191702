import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { DEFAULT_RESULTS_PER_PAGE } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { searchJobs, getExecutionLogOfJob } from '../../../../../actions/jobSchedule.actions';
import { getPayload } from './jobScheduleList.helpers';
import ACTION_TYPES from '../constants/jobScheduleList.actionTypes';
import ROUTES from '../../../constants/routes';
import { STUDIO_ROUTE } from '../../../../../constants/routes';
import PAGE_IDS from '../../../constants/PageIds.constants';

const handleFetchData = async ({ getState, setState }) => {
  setState({ isLoading: true });

  const {
    selectedFilters = EMPTY_ARRAY,
    pageSize = DEFAULT_RESULTS_PER_PAGE,
    currentPage = 0,
    currentPageToken = EMPTY_STRING,
    searchText = EMPTY_STRING,
  } = getState();
  const payload = getPayload({ currentPage, currentPageToken, pageSize, selectedFilters, searchText });
  const response = await searchJobs(payload);
  const nextPageToken = _get(response, 'nextPageToken', EMPTY_STRING);
  const jobsData = tget(response, 'hits', EMPTY_ARRAY);

  setState({ isLoading: false, jobsData, nextPageToken });
};

const handleCreateJobSchedule = ({ getState }) => {
  const { history } = getState();
  history.push(ROUTES.JOB_SCHEDULE_CREATE_ROUTE);
};

const handleEditJobSchedule = ({ params, getState }) => {
  const { id: jobScheduleId } = params;
  const { history } = getState();

  if (!_isEmpty(jobScheduleId)) {
    const pathName = `${STUDIO_ROUTE}/${PAGE_IDS.JOB_EDIT}/${jobScheduleId}`;
    history.push(pathName);
  }
};

const getExecutionData = async ({ params, setState }) => {
  const { jobScheduleId, rowData } = params;
  const executionLogData = await getExecutionLogOfJob(jobScheduleId);

  setState({ isDrawerDataLoading: false, clickedRowData: rowData, clickedJobExecutionLogData: executionLogData });
};

const handleTableItemClick = ({ params, setState }) => {
  const jobScheduleId = _get(params, 'value.original.id');
  setState({ isDrawerVisible: true, isDrawerDataLoading: true });
  getExecutionData({ params: { jobScheduleId, rowData: _get(params, 'value.original', EMPTY_OBJECT) }, setState });
};

const handlePageUpdate = ({ params, getState, setState }) => {
  const { page, resultsPerPage } = _get(params, 'value', EMPTY_OBJECT);
  const { currentPage, pageSize, nextPageToken, previousPageTokens = [], currentPageToken } = getState();
  let newPageNumber = page;
  let newTokenNumber = null;
  let newPrevPageTokens = [...previousPageTokens];

  if (resultsPerPage !== pageSize) {
    newPageNumber = 1;
    newPrevPageTokens = [];
  } else if (page > currentPage + 1) {
    newTokenNumber = nextPageToken;
    newPrevPageTokens.push(currentPageToken);
  } else if (page !== 1) {
    newTokenNumber = newPrevPageTokens.pop();
  } else {
    newPrevPageTokens = [];
  }

  setState(
    { currentPage: newPageNumber - 1, pageSize: resultsPerPage, currentPageToken: newTokenNumber, previousPageTokens: newPrevPageTokens },
    () => handleFetchData({ getState, setState }),
  );
};

const handleFilterChange = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ selectedFilters: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => handleFetchData({ getState, setState }));
};

const handleSearch = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ searchText: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => handleFetchData({ getState, setState }));
};

const handleDrawerClose = ({ setState }) => {
  setState({ isDrawerVisible: false });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleFetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleFetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleTableItemClick,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleFilterChange,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearch,
  [ACTION_TYPES.CREATE_JOB_SCHEDULE]: handleCreateJobSchedule,
  [ACTION_TYPES.EDIT_JOB_SCHEDULE]: handleEditJobSchedule,
  [ACTION_TYPES.ON_DRAWER_CLOSE]: handleDrawerClose,
};

export default ACTION_HANDLERS;
