import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import ExternalCredentialServices from '../services/externalCredential.services';

const fetchExternalCredentialByName = async (name) => {
  try {
    const response = await ExternalCredentialServices.fetchExternalCredentialByName(name);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch external credential, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createExternalCredential = async (payload) => {
  try {
    const response = await ExternalCredentialServices.createExternalCredential(payload);
    toaster(TOASTER_TYPE.SUCCESS, __('External Credential created successfully.'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create external credential, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateExternalCredentialByName = async (name, payload) => {
  try {
    const response = await ExternalCredentialServices.updateExternalCredentialByName(name, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('External Credential updated successfully.'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update external credential, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const searchExternalCredentials = async (payload) => {
  try {
    const response = await ExternalCredentialServices.searchExternalCredentials(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to search external credential, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const fetchExternalCredentialByNameWithReveal = async (name) => {
  try {
    const response = await ExternalCredentialServices.fetchExternalCredentialByNameWithReveal(name);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch external credential, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export {
  fetchExternalCredentialByName,
  createExternalCredential,
  updateExternalCredentialByName,
  searchExternalCredentials,
  fetchExternalCredentialByNameWithReveal,
};
