import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _head from 'lodash/head';

import Button from '@tekion/tekion-components/atoms/Button';

// Constants
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import EntityField from './EntityField';
import { ACTION_TYPES } from '../compoundConfigModal.constants';

const EntityFieldList = ({ id, value, error, onAction, options }) => {
  const handleAction = useCallback(
    ({ type, payload }) => {
      if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
        const { id: fieldIndex, value: fieldValue } = payload;
        onAction({
          type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
          payload: {
            id,
            value: _set(value, fieldIndex, _head(fieldValue)),
            fieldIndex,
          },
        });
      } else {
        onAction({ type, payload });
      }
    },
    [onAction, id, value],
  );

  const handleAddClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ADD_FIELD,
    });
  }, [onAction]);

  return (
    <div>
      {_map(value, (condition, index) => (
        <EntityField key={index} index={index} errors={error?.[index]} onAction={handleAction} options={options} value={value[index]} />
      ))}
      <Button view={Button.VIEW.TERTIARY} onClick={handleAddClick}>
        {__('+Add')}
      </Button>
    </div>
  );
};

EntityFieldList.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.array,
  options: PropTypes.array,
  error: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

EntityFieldList.defaultProps = {
  value: EMPTY_ARRAY,
  error: EMPTY_ARRAY,
  options: EMPTY_ARRAY,
};

export default EntityFieldList;
