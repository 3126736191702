// Tekion-components
import Button from '@tekion/tekion-components/atoms/Button';
import { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';

// Constants
import ACTION_TYPES from './subscriptionConfigList.actionTypes';

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create New'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.ON_CREATE_SUBSCRIPTION_CONFIG,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showFilter: true,
  showHeader: false,
  showSearch: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const SUBSCRIPTION_CONFIG_STATUS = {
  ACTIVE: false,
  INACTIVE: true,
};

const SUBSCRIPTION_CONFIG_STATUS_COLOR_MAP = {
  [SUBSCRIPTION_CONFIG_STATUS.ACTIVE]: COLOR_MAP.GREEN,
  [SUBSCRIPTION_CONFIG_STATUS.INACTIVE]: COLOR_MAP.RED,
};

const SUBSCRIPTION_CONFIG_STATUS_LABEL_MAP = {
  [SUBSCRIPTION_CONFIG_STATUS.ACTIVE]: __('Active'),
  [SUBSCRIPTION_CONFIG_STATUS.INACTIVE]: __('Inactive'),
};

const DEFAULT_PAGE_SIZE = 50;

export { SUB_HEADER_PROPS, TABLE_MANAGER_PROPS, SUBSCRIPTION_CONFIG_STATUS_COLOR_MAP, SUBSCRIPTION_CONFIG_STATUS_LABEL_MAP, DEFAULT_PAGE_SIZE };
