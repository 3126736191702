import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../constants/approvalCentre.constants';

const COLUMN_IDS = {
  ID: APPROVAL_CENTRE_FIELD_IDS.ID,
  NAME: APPROVAL_CENTRE_FIELD_IDS.NAME,
  GROUP: APPROVAL_CENTRE_FIELD_IDS.GROUP,
  CATEGORY: APPROVAL_CENTRE_FIELD_IDS.CATEGORY,
  ENTITY: APPROVAL_CENTRE_FIELD_IDS.TYPE,
  CUSTOM_ENTITY_ACTION_TYPE: APPROVAL_CENTRE_FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.ACTION_TYPE,
  STATUS: APPROVAL_CENTRE_FIELD_IDS.STATUS,
  STAGES: APPROVAL_CENTRE_FIELD_IDS.STAGES,
  CREATED_BY: APPROVAL_CENTRE_FIELD_IDS.CREATED_BY,
  CREATED_TIME: APPROVAL_CENTRE_FIELD_IDS.CREATED_TIME,
  VALIDITY: APPROVAL_CENTRE_FIELD_IDS.VALIDITY,
  MIN_APPROVALS: APPROVAL_CENTRE_FIELD_IDS.MIN_APPROVALS,
  APPROVED_SO_FAR: APPROVAL_CENTRE_FIELD_IDS.APPROVED_SO_FAR,
  APPROVAL_LEVEL: APPROVAL_CENTRE_FIELD_IDS.APPROVAL_LEVEL,
  APPROVERS: APPROVAL_CENTRE_FIELD_IDS.APPROVERS,
  REJECTORS: APPROVAL_CENTRE_FIELD_IDS.REJECTORS,
  ELIGIBLE_APPROVERS: APPROVAL_CENTRE_FIELD_IDS.ELIGIBLE_APPROVERS,
};

export default COLUMN_IDS;
