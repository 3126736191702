import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/workflowengine';

const fetchProcesses = () => ApiService.get(`${BASE_URL}/management/workflow?module=DEV_PLATFORM&status=PUBLISHED,DRAFT`);
const getProcessById = (workflowId) => ApiService.get(`${BASE_URL}/management/workflow/${workflowId}`);
const createProcess = (payload) => ApiService.post(`${BASE_URL}/management/workflow/publish`, payload);
const updateProcess = (workflowId, payload) => ApiService.put(`${BASE_URL}/management/workflow/${workflowId}/publish`, payload);

const fetchProcessGlobalMetadata = () => ApiService.get(`${BASE_URL}/metadata/global`);
const fetchProcessTaskDefinitions = (eventTypes = 'RECORD_CREATED') =>
  ApiService.get(`${BASE_URL}/management/task?module=DEV_PLATFORM&&eventTypes=${eventTypes}`);
const validateProcess = (validationType, payload) =>
  ApiService.post(`${BASE_URL}/management/workflow/validate/v2/?validationType=${validationType}`, payload);

const activateProcess = (workflowId, payload) => ApiService.put(`${BASE_URL}/management/workflow/${workflowId}/activate`, payload);

const deactivateProcess = (workflowId, payload) => ApiService.put(`${BASE_URL}/management/workflow/${workflowId}/deactivate`, payload);

export default {
  fetchProcesses,
  getProcessById,
  createProcess,
  updateProcess,
  fetchProcessGlobalMetadata,
  fetchProcessTaskDefinitions,
  validateProcess,
  activateProcess,
  deactivateProcess,
};
