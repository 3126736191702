import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

// Components
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';

// Helpers
import { getResourceOptions } from './resourceField.helpers';
import { CURRENT_ENTITY_NAMESPACE } from '../../constants/conditionBuilder.general';

const ResourceField = ({
  required,
  isVariablePrefixNeeded,
  isRelationshipRecursive,
  isComplexRecursive,
  isCreatableFieldOnly,
  isEditableFieldOnly,
  isDisabled,
  id,
  fieldClassName,
  label,
  variableNameForPrefixNotNeeded,
  value,
  error,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  onAction,
}) => {
  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload = {} } = action;

    if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
      const { value: newValue } = payload;

      onAction({
        type,
        payload: {
          ...payload,
          value: getArraySafeValue(newValue),
        },
      });
    } else {
      onAction(action);
    }
  };

  const options = useMemo(
    () =>
      getResourceOptions({
        value,
        mapOfVariableToEntityName,
        conditionBuilderFieldDefinitionObject,
        isVariablePrefixNeeded,
        isRelationshipRecursive,
        isComplexRecursive,
        variableNameForPrefixNotNeeded,
        isCreatableFieldOnly,
        isEditableFieldOnly,
      }),
    [
      isCreatableFieldOnly,
      isEditableFieldOnly,
      value,
      mapOfVariableToEntityName,
      conditionBuilderFieldDefinitionObject,
      isVariablePrefixNeeded,
      isRelationshipRecursive,
      isComplexRecursive,
      variableNameForPrefixNotNeeded,
    ],
  );

  return (
    <Select
      id={id}
      isClearable
      isDisabled={isDisabled}
      hideSelectedOptions
      closeMenuOnSelect={false}
      required={required}
      fieldClassName={fieldClassName}
      label={label}
      value={_castArray(value)}
      error={error}
      options={options}
      onAction={handleAction}
    />
  );
};

ResourceField.propTypes = {
  required: PropTypes.bool,
  isVariablePrefixNeeded: PropTypes.bool,
  isRelationshipRecursive: PropTypes.bool,
  isComplexRecursive: PropTypes.bool,
  isCreatableFieldOnly: PropTypes.bool,
  isEditableFieldOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  fieldClassName: PropTypes.string,
  label: PropTypes.string,
  variableNameForPrefixNotNeeded: PropTypes.string,
  error: PropTypes.string,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  value: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ResourceField.defaultProps = {
  required: false,
  isVariablePrefixNeeded: true,
  isRelationshipRecursive: true,
  isComplexRecursive: true,
  isCreatableFieldOnly: false,
  isEditableFieldOnly: false,
  isDisabled: false,
  fieldClassName: '',
  label: '',
  variableNameForPrefixNotNeeded: CURRENT_ENTITY_NAMESPACE,
  error: '',
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  value: EMPTY_ARRAY,
};

export default ResourceField;
