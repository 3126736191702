import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _trim from 'lodash/trim';
import _toLower from 'lodash/toLower';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _includes from 'lodash/includes';

import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { fetchWorkspaces } from '../../../../actions/workSpace.actions';

const handleInit = async ({ setState }) => {
  const workspaceList = await fetchWorkspaces();
  const filteredWorkspaceList = workspaceList;
  const totalCount = _size(filteredWorkspaceList);
  setState({ workspaceList, totalCount, filteredWorkspaceList });
};

const handleSearch = ({ getState, setState, params }) => {
  const { workspaceList } = getState();
  const searchText = _trim(_toLower(_get(params, 'value', EMPTY_STRING)));
  let filteredWorkspaceList = workspaceList;

  if (!_isEmpty(filteredWorkspaceList) && !_isEmpty(searchText)) {
    filteredWorkspaceList = _filter(filteredWorkspaceList, (workspace) => {
      const { name } = workspace;
      const workspaceName = _toLower(name);
      return _includes(workspaceName, searchText);
    });
  }
  const totalCount = _size(filteredWorkspaceList);
  setState({ searchText, totalCount, filteredWorkspaceList });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_SEARCH_TERM_UPDATE]: handleSearch,
};

export default ACTION_HANDLERS;
