import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import List from '@tekion/tekion-components/molecules/List';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import ACTION_TYPES from '../../constants/permissionSetConfigure.actionTypes';

import { getModifiedTime } from '../../helpers/permissionSetConfigure.helpers';

import PERMISSION_READERS from '../../../../../../../readers/permissionSet.reader';

import styles from './permissionSetConfigureCardView.module.scss';

const PermissionSetConfigureCardView = ({ permissionCardData, onAction }) => {
  const handleEditClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CLICK_EDIT,
    });
  }, [onAction]);

  const permissionCreatedTime = useMemo(() => getModifiedTime(PERMISSION_READERS.createdTime(permissionCardData)), [permissionCardData]);
  const permissionModifiedTime = useMemo(() => getModifiedTime(PERMISSION_READERS.modifiedTime(permissionCardData)), [permissionCardData]);

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Display Name')}</div>
        <List.Item.Meta description={PERMISSION_READERS.name(permissionCardData) || NO_DATA} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Created On')}</div>
        <List.Item.Meta description={permissionCreatedTime || NO_DATA} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Modified On')}</div>
        <List.Item.Meta description={permissionModifiedTime || NO_DATA} />
      </div>

      <FontIcon size={SIZES.MD_S} onClick={handleEditClick} className="cursor-pointer m-r-32">
        icon-edit
      </FontIcon>
    </div>
  );
};

PermissionSetConfigureCardView.propTypes = {
  permissionCardData: PropTypes.object,
  onAction: PropTypes.func,
};

PermissionSetConfigureCardView.defaultProps = {
  permissionCardData: EMPTY_OBJECT,
  onAction: _noop,
};
export default PermissionSetConfigureCardView;
