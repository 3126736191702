import Button from '@tekion/tekion-components/atoms/Button';
import Heading from '@tekion/tekion-components/atoms/Heading';

import ACTION_TYPES from './recordSharingRuleList.actionTypes';

const HEADER_PROPS = {
  label: 'Rule List',
};

const TABLE_MANAGER_PROPS = {
  showHeader: false,
  showSearch: true,
  showFilter: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const SUB_HEADER_PROPS = {
  subHeaderLeftActions: [
    {
      renderer: Heading,
      renderOptions: {
        children: __('Record Sharing Rules'),
        size: 2,
      },
    },
  ],
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Add new rule'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_RULE,
    },
  ],
};

export { HEADER_PROPS, SUB_HEADER_PROPS, TABLE_MANAGER_PROPS };
