import EventEmitter from 'eventemitter3';

// Event Types
import EVENT_TYPES from '../constants/templateVariableDialog.eventTypes';

const templateVariableDialogEvent = new EventEmitter();

export const showTemplateVariableDialog = () => {
  templateVariableDialogEvent.emit(EVENT_TYPES.SHOW_TEMPLATE_VARIABLE_DIALOG);
};

export const hideTemplateVariableDialog = () => {
  templateVariableDialogEvent.emit(EVENT_TYPES.HIDE_TEMPLATE_VARIABLE_DIALOG);
};

export default templateVariableDialogEvent;
