import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cx from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import { FormWithSubmission } from '@tekion/tekion-components/pages/formPage';
import Loader from '@tekion/tekion-components/molecules/loader';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import withSize from '../../../../../connectors/withSize';

import { getFormFields } from './helpers/createEntityView.general.helpers';
import ACTION_HANDLERS from './helpers/createEntityView.actionHandlers';

import { getFormSections } from './constants/createEntityViewForm.sections';
import { FORM_MODES } from '../../../../../constants/general.constants';
import { ACTION_TYPES, CONTEXT_ID, FIELD_IDS } from './constants/createEntityView.constants';

import styles from './createEntityView.module.scss';

const CreateEntityView = ({ isLoading, isSaving, formMode, contentHeight, formValues, entity, errors, recordTypeOptions, onAction }) => {
  const viewType = useMemo(() => getArraySafeValue(_get(formValues, FIELD_IDS.VIEW_TYPE, EMPTY_ARRAY)), [formValues]);
  const formFields = useMemo(
    () => getFormFields(formValues, entity, viewType, formMode, recordTypeOptions, onAction),
    [entity, formMode, formValues, viewType, recordTypeOptions, onAction],
  );
  const formSections = useMemo(() => getFormSections(viewType), [viewType]);
  const formTitle = useMemo(() => (formMode === FORM_MODES.CREATE ? __('Create Entity View') : __('Edit Entity View')), [formMode]);
  const primaryButtonLabel = useMemo(() => (formMode === FORM_MODES.CREATE ? __('Save') : __('Update')), [formMode]);

  const isValidViewSummary = useMemo(
    () => !_isEmpty(formValues[FIELD_IDS.NAME]) && !_isEmpty(formValues[FIELD_IDS.VIEW_TYPE]) && !_isEmpty(formValues[FIELD_IDS.DISPLAY_NAME]),
    [formValues],
  );

  const handleSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, []);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_REDIRECTION });
  }, [onAction]);

  if (isLoading) {
    return <Loader id={CONTEXT_ID} />;
  }

  return (
    <Page className="full-height">
      <Page.Header hasBack className={styles.header}>
        <div className="full-width">
          <Heading>{formTitle}</Heading>
        </div>
      </Page.Header>
      <Page.Body className={cx('full-width', 'flex', styles.body)} style={{ height: contentHeight }}>
        <FormWithSubmission
          isFetching={isLoading}
          className={cx('full-width', 'p-3', styles.form)}
          contextId={CONTEXT_ID}
          errors={errors}
          values={formValues}
          fields={formFields}
          sections={formSections}
          onAction={onAction}
        />
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          primaryActionLoading={isSaving}
          isPrimaryDisabled={!isValidViewSummary}
          id={CONTEXT_ID}
          primaryButtonLabel={primaryButtonLabel}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

CreateEntityView.propTypes = {
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  formValues: PropTypes.object,
  entity: PropTypes.object,
  errors: PropTypes.object,
  recordTypeOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

CreateEntityView.defaultProps = {
  isLoading: false,
  isSaving: false,
  formMode: undefined,
  formValues: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  recordTypeOptions: EMPTY_ARRAY,
  errors: EMPTY_OBJECT,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(CreateEntityView);
