import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import DependencyConfigTable from '../components/dependencyConfigTable/DependencyConfigTable';

import { inputTableColumnRequiredValidator } from '../../../helpers/fieldsForm.general.helpers';

import DEPENDENCY_CONFIG_FORM_FIELD_IDS from '../constants/dependencyConfigForm.fieldIds';
import { COLUMNS } from '../components/dependencyConfigTable/constants/dependencyConfigTable.general';

const DEPENDENCY_CONFIG_TABLE_FIELD = {
  id: DEPENDENCY_CONFIG_FORM_FIELD_IDS.DEPENDENCY_CONFIG_TABLE,
  renderer: DependencyConfigTable,
  renderOptions: {
    validators: [inputTableColumnRequiredValidator([COLUMNS.DEPENDENCY_TYPE, COLUMNS.FIELD_NAME])],
  },
};

const getFields = (entity) => ({
  [DEPENDENCY_CONFIG_FORM_FIELD_IDS.DEPENDENCY_CONFIG_TABLE]: addToRenderOptions(DEPENDENCY_CONFIG_TABLE_FIELD, [{ path: 'entity', value: entity }]),
});

export default getFields;
