import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import _keyBy from 'lodash/keyBy';

import { EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components/molecules/loader';
import FilterSection from '@tekion/tekion-components/organisms/filterSection';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';

import HistogramChart from './components/histogramChart/HistogramChart';
import ChartOptionsRenderer from './ChartOptionsRenderer';
import { getDefaultGenericChartOptions } from './helpers/chartWidget.general.helpers';
import { createFilterProps } from '../widgetContainer/helpers/widgetContainer.filterConfig';
import ACTION_TYPES from './constants/chartWidget.actionTypes';
import ACTION_HANDLERS from './helpers/chartWidget.actionHandlers';
import { CHART_OPTIONS } from '../widgetContainer/constants/widgetContainer.general.constants';
import { SPLINE } from './constants/chartWidget.constants';
import { DRAGGABLE_CANCEL_CLASS_NAME } from '../gridLayout';

import styles from './chartWidget.module.scss';

const ChartWidget = ({
  axisData,
  graphData,
  chartType,
  chartLoading,
  filtersToRender,
  selectedFilters,
  entityFieldDefinitions,
  timeRange,
  isEditable,
  onAction,
}) => {
  const fieldDefinitionByName = useMemo(() => _keyBy(entityFieldDefinitions, 'name'), [entityFieldDefinitions]);

  const handleChartAreaSelection = useCallback(
    (event) => {
      if (!isEditable) {
        onAction({ type: ACTION_TYPES.ON_SELECT_CHART_AREA, payload: { event } });
      }
    },
    [onAction, isEditable],
  );

  const handleApplyFilters = useCallback(
    (filters) => {
      onAction({
        type: ACTION_TYPES.APPLY_FILTERS,
        payload: {
          filters,
        },
      });
    },
    [onAction],
  );

  const defaultGenericChartOptions = useMemo(
    () => getDefaultGenericChartOptions(axisData, graphData, handleChartAreaSelection, chartType),
    [axisData, graphData, chartType, handleChartAreaSelection],
  );

  const { filterTypes, defaultFilterTypes } = createFilterProps({
    selectedFilters,
    filtersToRender,
    fieldDefinitionByName,
  });

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT });
  }, [onAction, timeRange]);

  if (chartLoading) {
    return <Loader className="full-width full-height align-center" />;
  }

  return (
    <div className={`${styles.chartContainer} ${DRAGGABLE_CANCEL_CLASS_NAME}`}>
      <div className={`flex align-items-center p-x-24 ${styles.filterContainer}`}>
        <FilterSection
          alwaysRenderDefaultToolBar
          showFilterTrigger
          onFilterApply={handleApplyFilters}
          filterTypes={filterTypes}
          showDefaultFilter={false}
          defaultFilterTypes={defaultFilterTypes}
          selectedFilters={selectedFilters}
          showResultsCount={false}
        />
        <SelectInput
          label={__('Visualization')}
          components={{ Option: ChartOptionsRenderer }}
          options={CHART_OPTIONS}
          onAction={onAction}
          value={[chartType]}
        />
      </div>
      <HistogramChart options={defaultGenericChartOptions} />
    </div>
  );
};

ChartWidget.propTypes = {
  chartLoading: PropTypes.bool,
  isEditable: PropTypes.bool,
  chartType: PropTypes.string,
  timeRange: PropTypes.object,
  axisData: PropTypes.array,
  graphData: PropTypes.array,
  selectedFilters: PropTypes.array,
  entityFieldDefinitions: PropTypes.array,
  filtersToRender: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ChartWidget.defaultProps = {
  chartLoading: true,
  isEditable: false,
  chartType: SPLINE,
  timeRange: EMPTY_OBJECT,
  axisData: EMPTY_ARRAY,
  graphData: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
  entityFieldDefinitions: EMPTY_ARRAY,
  filtersToRender: EMPTY_ARRAY,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(ChartWidget);
