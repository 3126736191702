import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _map from 'lodash/map';

// Components
import InputTable from '@tekion/tekion-components/organisms/inputTable/containers/withRowActions/Table';
import Error from '@tekion/tekion-components/organisms/FormBuilder/components/error';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { getColumns } from './helper/fieldMappingInputTable.columns';

import { TABLE_ACTION_TYPES, COLUMN_IDS } from './constants/fieldMappingInputTable.general';
import ACTION_TYPES from '../../constants/dataImportForm.actionTypes';

import styles from './fieldMappingInputTable.module.scss';

const FieldMappingInputTable = ({ isLoadingFieldMapping, error, columnDefinitions, fieldNameOptions, onAction }) => {
  const selectedFieldNameOptions = useMemo(
    () => _map(columnDefinitions, (fieldMapping) => getArraySafeValue(tget(fieldMapping, [COLUMN_IDS.FIELD_NAME]))),
    [columnDefinitions],
  );

  const columns = useMemo(() => getColumns(fieldNameOptions, selectedFieldNameOptions), [fieldNameOptions, selectedFieldNameOptions]);

  const handleAction = useCallback(
    (action) => {
      const tableActionType = _get(action, 'type', EMPTY_STRING);
      const rowIndex = _get(action, 'payload.nestingPath.[0]');
      const id = _get(action, 'payload.id');
      const value = _get(action, 'payload.value');

      if (tableActionType === TABLE_ACTION_TYPES.ON_CHANGE) {
        onAction({
          type: ACTION_TYPES.TABLE_ON_CHANGE,
          payload: { rowIndex, id, value },
        });
      }
    },
    [onAction],
  );

  if (isLoadingFieldMapping) {
    return false;
  }

  return (
    <div>
      <span className={styles.inputTableHeading}>{__('Field Mappings')}</span>
      <InputTable columns={columns} value={columnDefinitions} onAction={handleAction} />
      <Error key="error" className={styles.errorMessage} error={error} />
    </div>
  );
};

FieldMappingInputTable.propTypes = {
  isLoadingFieldMapping: PropTypes.bool,
  error: PropTypes.string,
  columnDefinitions: PropTypes.array,
  fieldNameOptions: PropTypes.array,
  onAction: PropTypes.func,
};

FieldMappingInputTable.defaultProps = {
  isLoadingFieldMapping: false,
  error: undefined,
  columnDefinitions: EMPTY_ARRAY,
  fieldNameOptions: EMPTY_ARRAY,
  onAction: PropTypes.func,
};

export default React.memo(FieldMappingInputTable);
