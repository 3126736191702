import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@tekion/tekion-components/molecules/Modal';
import Label from '@tekion/tekion-components/atoms/Label';

import styles from '../workspaceUserForm.module.scss';

const UserCredModal = ({ isConfirmModalVisible, goBackHandler }) => (
  <Modal
    visible={isConfirmModalVisible}
    destroyOnClose
    hideSubmit
    width={Modal.SIZES.SM}
    title={__('User Credentials')}
    secondaryBtnText={__('Close')}
    onCancel={goBackHandler}
  >
    <div className={styles.userDetailModalContainer}>
      <Label highlight>
        {__(
          // eslint-disable-next-line max-len
          'Credentials has been sent to user email. Please check email and login with the credentials.',
        )}
      </Label>
    </div>
  </Modal>
);

UserCredModal.propTypes = {
  isConfirmModalVisible: PropTypes.bool.isRequired,
  goBackHandler: PropTypes.func.isRequired,
  userDetails: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
};
export default UserCredModal;
