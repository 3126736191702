const TENANT_UNIVERSE_WORKSPACE_ID = 'universe';

const ASSET_TYPES = {
  USER: 'USER',
  USER_GROUP: 'USER_GROUP',
  ROLE: 'ROLE',
};

const RECORD_ACCESS_TYPES = {
  PRIVATE: 'PRIVATE',
  PUBLIC_READONLY: 'PUBLIC_READONLY',
  PUBLIC_READ_WRITE: 'PUBLIC_READ_WRITE',
};

const FIELD_DEPENDENCY_TYPES = {
  STRONG: 'STRONG',
  WEAK: 'WEAK',
};

const RECORD_TYPES = {
  STANDARD: 'STANDARD',
  DERIVED: 'DERIVED',
};

const RECORD_GROUP_TYPES = {
  STATIC: 'STATIC',
  DYNAMIC: 'DYNAMIC',
};

const NAMESPACES = {
  CURRENT_RECORD: '$record',
  CURRENT_USER: '$currentUser',
  CURRENT_VARIABLES: '$variables',
  SYSTEM_TIME: '$System.time',
  SYSTEM_DATE: '$System.date',
  CURRENT_ADDRESSEE: '$addressee',
};

const FORM_MODES = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

const STANDARD_ENTITY_NAME = {
  USER: 'user',
  NOTIFICATION: 'notificationtask',
  EMAIL_METRIC: 'emailmetrics',
};

const USER_TYPES = {
  ADMIN: 'ADMIN',
  BASIC: 'BASIC',
};

const WORKFLOW_BUILDER_MODE = {
  PROCESS: 'processes',
  RULE: 'rules',
};

const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

export {
  TENANT_UNIVERSE_WORKSPACE_ID,
  RECORD_ACCESS_TYPES,
  FIELD_DEPENDENCY_TYPES,
  RECORD_GROUP_TYPES,
  FORM_MODES,
  RECORD_TYPES,
  NAMESPACES,
  STANDARD_ENTITY_NAME,
  USER_TYPES,
  ASSET_TYPES,
  WORKFLOW_BUILDER_MODE,
  TABLE_CONSTANTS,
};
