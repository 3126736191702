import _isEmpty from 'lodash/isEmpty';
import _concat from 'lodash/concat';
import _split from 'lodash/split';
import _size from 'lodash/size';
import _head from 'lodash/head';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _slice from 'lodash/slice';
import _set from 'lodash/set';

// Constants
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { getCreatableEditableFilteredFields, getOptionsForNewFieldDefinitions } from '../../helpers/conditionBuilder.general.helpers';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';

const getSelectedFieldDefsAndNameAndDisplayName = (
  fieldDefs,
  fieldSplitValue,
  index,
  result,
  conditionBuilderFieldDefinitionObject,
  isRelationshipRecursive,
  isComplexRecursive,
  isVariablePrefixNeeded,
  isCreatableFieldOnly,
  isEditableFieldOnly,
) => {
  if (index >= _size(fieldSplitValue)) {
    return;
  }

  const fieldDef = _get(fieldDefs, fieldSplitValue[index], EMPTY_OBJECT);
  const dataType = fieldDefinitionReader.dataType(fieldDef);
  const fieldType = fieldDefinitionReader.fieldType(fieldDef);
  if (dataType === DATA_TYPES.COMPLEX && isComplexRecursive) {
    const complexEntityName = _get(fieldDef, 'complexFieldDefinition.entityName');
    let complexEntityFieldDefs = _get(conditionBuilderFieldDefinitionObject, complexEntityName, EMPTY_OBJECT);

    complexEntityFieldDefs = getCreatableEditableFilteredFields(complexEntityFieldDefs, isCreatableFieldOnly, isEditableFieldOnly);

    _set(result, 'selectedFieldDefs', complexEntityFieldDefs);

    if (isVariablePrefixNeeded || (!isVariablePrefixNeeded && index !== 0)) {
      _set(result, 'selectedFieldName', `${result.selectedFieldName}.${fieldDefinitionReader.name(fieldDef)}`);
      _set(result, 'selectedFieldDisplayName', `${result.selectedFieldDisplayName}.${fieldDefinitionReader.displayName(fieldDef)}`);
    } else {
      _set(result, 'selectedFieldName', fieldDefinitionReader.name(fieldDef));
      _set(result, 'selectedFieldDisplayName', fieldDefinitionReader.displayName(fieldDef));
    }

    getSelectedFieldDefsAndNameAndDisplayName(
      complexEntityFieldDefs,
      fieldSplitValue,
      index + 1,
      result,
      conditionBuilderFieldDefinitionObject,
      isRelationshipRecursive,
      isComplexRecursive,
      isVariablePrefixNeeded,
      isCreatableFieldOnly,
      isEditableFieldOnly,
    );
  } else if (fieldType === FIELD_TYPES.RELATIONSHIP && isRelationshipRecursive) {
    const relationshipEntityName = _get(fieldDef, 'lookupField.entityType');
    let relationshipEntityFieldDefs = _get(conditionBuilderFieldDefinitionObject, relationshipEntityName, EMPTY_OBJECT);

    relationshipEntityFieldDefs = getCreatableEditableFilteredFields(relationshipEntityFieldDefs, isCreatableFieldOnly, isEditableFieldOnly);

    _set(result, 'selectedFieldDefs', relationshipEntityFieldDefs);

    if (isVariablePrefixNeeded || (!isVariablePrefixNeeded && index !== 0)) {
      _set(result, 'selectedFieldName', `${result.selectedFieldName}.${fieldDefinitionReader.name(fieldDef)}`);
      _set(result, 'selectedFieldDisplayName', `${result.selectedFieldDisplayName}.${fieldDefinitionReader.displayName(fieldDef)}`);
    } else {
      _set(result, 'selectedFieldName', fieldDefinitionReader.name(fieldDef));
      _set(result, 'selectedFieldDisplayName', fieldDefinitionReader.displayName(fieldDef));
    }

    getSelectedFieldDefsAndNameAndDisplayName(
      relationshipEntityFieldDefs,
      fieldSplitValue,
      index + 1,
      result,
      conditionBuilderFieldDefinitionObject,
      isRelationshipRecursive,
      isComplexRecursive,
      isVariablePrefixNeeded,
      isCreatableFieldOnly,
      isEditableFieldOnly,
    );
  }
};

const getResourceOptions = ({
  value,
  mapOfVariableToEntityName,
  conditionBuilderFieldDefinitionObject,
  isVariablePrefixNeeded,
  isRelationshipRecursive,
  isComplexRecursive,
  variableNameForPrefixNotNeeded,
  excludeFieldName,
  isCreatableFieldOnly,
  isEditableFieldOnly,
  isExcludedFieldName = true,
}) => {
  if (_isEmpty(value) && isVariablePrefixNeeded) {
    return _map(mapOfVariableToEntityName, (_, key) => ({ label: key, value: key }));
  }

  const splitValue = _split(value, '.');
  const variableName = isVariablePrefixNeeded ? _head(splitValue) : variableNameForPrefixNotNeeded;
  const entityName = mapOfVariableToEntityName[variableName];
  let fieldDefs = _get(conditionBuilderFieldDefinitionObject, entityName, EMPTY_OBJECT);

  fieldDefs = getCreatableEditableFilteredFields(fieldDefs, isCreatableFieldOnly, isEditableFieldOnly);

  if ((isVariablePrefixNeeded && _size(splitValue) === 1) || (!isVariablePrefixNeeded && _isEmpty(_head(splitValue)) && _size(splitValue) <= 1)) {
    const filteredFieldDefs = isExcludedFieldName
      ? _filter(fieldDefs, (fieldDef) => fieldDefinitionReader.name(fieldDef) !== excludeFieldName)
      : fieldDefs;

    const options = _map(filteredFieldDefs, (fieldDef) => ({
      label: isVariablePrefixNeeded ? `${variableName}.${fieldDefinitionReader.displayName(fieldDef)}` : fieldDefinitionReader.displayName(fieldDef),
      value: isVariablePrefixNeeded ? `${variableName}.${fieldDefinitionReader.name(fieldDef)}` : fieldDefinitionReader.name(fieldDef),
    }));

    return isVariablePrefixNeeded ? _concat({ label: variableName, value: variableName }, options) : options;
  }

  const fieldSplitValue = isVariablePrefixNeeded ? _slice(splitValue, 1) : splitValue;
  const preOptions = {
    selectedFieldDefs: fieldDefs,
    selectedFieldName: isVariablePrefixNeeded ? variableName : EMPTY_STRING,
    selectedFieldDisplayName: isVariablePrefixNeeded ? variableName : EMPTY_STRING,
  };

  getSelectedFieldDefsAndNameAndDisplayName(
    fieldDefs,
    fieldSplitValue,
    0,
    preOptions,
    conditionBuilderFieldDefinitionObject,
    isRelationshipRecursive,
    isComplexRecursive,
    isVariablePrefixNeeded,
    isCreatableFieldOnly,
    isEditableFieldOnly,
  );

  const { selectedFieldDefs, selectedFieldName, selectedFieldDisplayName } = preOptions;

  const filteredSelectedFieldDefs = isExcludedFieldName
    ? _filter(selectedFieldDefs, (fieldDef) => fieldDefinitionReader.name(fieldDef) !== excludeFieldName)
    : selectedFieldDefs;

  const options = getOptionsForNewFieldDefinitions(filteredSelectedFieldDefs, selectedFieldDisplayName, selectedFieldName);

  return !_isEmpty(selectedFieldDisplayName) && !_isEmpty(selectedFieldName)
    ? _concat({ label: selectedFieldDisplayName, value: selectedFieldName }, options)
    : options;
};

export { getResourceOptions };
