const ACTION_TYPES = {
  INIT: 'INIT',
  LOAD_MORE_COMMENTS: 'LOAD_MORE_COMMENTS',
  CREATE_COMMENT: 'ADD_COMMENT',
  EDIT_COMMENT: 'EDIT_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
  SHOW_REPLIES: 'SHOW_REPLIES',
  HIDE_REPLIES: 'HIDE_REPLIES',
  TOGGLE_REPLY_INPUT: 'TOGGLE_REPLY_INPUT',
  CHANGE_COMMENT_MODE: 'CHANGE_COMMENT_MODE',
};

export default ACTION_TYPES;
