import produce from 'immer';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { fetchEntityDefByName } from '../../../../../actions/entityManagement.actions';
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../actions/conditionBuilder.actions';
import { updateRecordTypeDefinition } from '../../../../../actions/recordType.actions';
import { isConditionRequiredRule } from '../../../../../organisms/conditionBuilder/helpers/conditionBuilder.general.helpers';
import { getErrorObject, getFieldNameFromFieldValue, getFieldOptions, getPayloadFromValues, getValues } from './recordTypeDetailForm.helpers';
import { FIELD_IDS } from './recordTypeDetailForm.fields';
import { STUDIO_ROUTE } from '../../../../../constants/routes';
import PAGE_IDS from '../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/recordTypeDetailsForm.actionTypes';
import CONDITION_BUILDER_MODES from '../../../../../organisms/conditionBuilder/constants/conditionBuilder.modes';
import CONDITION_FIELD_IDS from '../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import { RECORD_TYPES } from '../../../../../constants/general.constants';
import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';

const handleInit = async ({ getState, setState }) => {
  const { history, match } = getState();
  const entityName = _get(match, 'params.entityName');

  setState({ isLoading: true });
  let entity = _get(history, 'location.state.entity', EMPTY_OBJECT);

  if (_isEmpty(entity)) {
    entity = await fetchEntityDefByName(entityName);
  }

  const mapOfVariableToEntityName = { $record: entityName };
  const conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityName);

  const fieldDefinitions = _get(entity, 'fieldDefinitions', EMPTY_ARRAY);
  const fieldDefinitionByName = _keyBy(fieldDefinitions, 'name');
  const { formValues = EMPTY_OBJECT, selectedTabId, derivationFieldDefinitions = EMPTY_ARRAY } = getValues(entity, fieldDefinitionByName);
  const conditionErrorObject = getErrorObject(_get(formValues, 'allTabsByName', EMPTY_OBJECT));
  const fieldDefs = _get(conditionBuilderFieldDefinitionObject, entityName, EMPTY_OBJECT);

  _set(conditionBuilderFieldDefinitionObject, entityName, {});
  _set(conditionBuilderFieldDefinitionObject, `${entityName}_derivationFields`, fieldDefs);

  let filteredFieldDefs = _filter(fieldDefs, (fieldDef) =>
    _includes(_get(formValues, FIELD_IDS.DERIVATION_FIELDS), fieldDefinitionReader.name(fieldDef)),
  );

  filteredFieldDefs = _keyBy(filteredFieldDefs, 'name');
  _set(conditionBuilderFieldDefinitionObject, entityName, filteredFieldDefs);
  const fieldDefinitionsOptions = getFieldOptions(fieldDefinitions);

  setState({
    entity,
    isLoading: false,
    fieldDefinitionsOptions,
    formValues,
    mapOfVariableToEntityName,
    conditionBuilderFieldDefinitionObject,
    selectedTabId,
    derivationFieldDefinitions,
    conditionErrorObject,
  });
};

const handleFieldChange = ({ getState, setState, params }) => {
  const { id, value } = params;
  const { selectedTabId, conditionBuilderFieldDefinitionObject, match, formValues, conditionErrorObject } = getState();
  const entityName = _get(match, 'params.entityName', EMPTY_STRING);
  const updatedConditionBuilderFieldDefinitions = { ...conditionBuilderFieldDefinitionObject };
  const newConditionErrorObject = { ...conditionErrorObject };

  if (id === FIELD_IDS.RECORD_TYPE) {
    setState({ isTypeChangeModal: true, recordType: value });
    return;
  }

  if (id === FIELD_IDS.DERIVATION_FIELDS && _size(value) < _size(_get(formValues, FIELD_IDS.DERIVATION_FIELDS))) {
    return;
  }

  if (id === FIELD_IDS.DERIVATION_FIELDS) {
    const fieldDefs = _get(conditionBuilderFieldDefinitionObject, `${entityName}_derivationFields`, EMPTY_OBJECT);
    let filteredFieldDefs = _filter(fieldDefs, (fieldDef) => _includes(value, fieldDefinitionReader.name(fieldDef)));
    filteredFieldDefs = _keyBy(filteredFieldDefs, 'name');
    _set(updatedConditionBuilderFieldDefinitions, entityName, filteredFieldDefs);
  }

  if (id === FIELD_IDS.DERIVATION_ALL_CONDITIONS) {
    newConditionErrorObject[selectedTabId] = isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)(null, value);
    newConditionErrorObject[selectedTabId] = {
      ...newConditionErrorObject[selectedTabId],
      ..._get(newConditionErrorObject[selectedTabId], 'message'),
    };
    if (_isEmpty(_get(value, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST)) && _get(newConditionErrorObject[selectedTabId], 'isValid', false)) {
      newConditionErrorObject[selectedTabId] = { isValid: false, errorMessage: __('There should be atleast one condition') };
    }
  }

  setState(
    produce((draft) => {
      if (id === FIELD_IDS.DERIVATION_ALL_CONDITIONS) {
        _set(draft, `formValues.allTabsByName.${selectedTabId}`, value);
      } else {
        _set(draft, `formValues.${id}`, value);
      }
      if (id === FIELD_IDS.DERIVATION_FIELDS) {
        _set(draft, 'conditionBuilderFieldDefinitionObject', updatedConditionBuilderFieldDefinitions);
      }
      _set(draft, 'conditionErrorObject', newConditionErrorObject);
    }),
  );
};

const handleErrors = ({ setState, params }) => {
  const { errors } = params;

  setState({ errors });
};

const handleSubmit = async ({ getState, setState }) => {
  const { history, match, formValues, conditionErrorObject } = getState();
  const entityName = _get(match, 'params.entityName', '');
  const payload = getPayloadFromValues(formValues);
  let isValid = true;

  _forEach(conditionErrorObject, (value) => {
    if (!_get(value, 'isValid', true)) {
      isValid = false;
    }
  });

  setState({ isSaving: true });

  if (isValid) {
    const response = await updateRecordTypeDefinition(entityName, payload);
    if (!_isEmpty(response)) {
      setTimeout(() => {
        history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.RECORD_TYPE}`);
      }, ES_REFETCH_DELAY);
    } else {
      setState({ isSaving: false });
    }
  } else {
    toaster(TOASTER_TYPE.ERROR, __('Failed to update RecordType Definition. Please remove the errors and then try again.'));

    setState({ isSaving: false });
  }
};

const handleTabClick = ({ params, setState }) => {
  const selectedTabId = _get(params, 'tab');

  setState({ selectedTabId });
};

const handleDeselect = ({ params, getState, setState }) => {
  const { formValues, conditionBuilderFieldDefinitionObject, match } = getState();
  const selectedValue = _get(params, 'value');
  const allErrorTabs = [];
  let errorTabMsg = '';

  _forEach(_get(formValues, 'allTabsByName'), (value, key) => {
    const tab = _find(
      _get(value, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY),
      (condition) => getFieldNameFromFieldValue(_get(condition, CONDITION_FIELD_IDS.FIELD, [''])) === selectedValue,
    );
    if (tab) {
      allErrorTabs.push(key);
      errorTabMsg += `${key}, `;
    }
  });

  if (_isEmpty(errorTabMsg)) {
    const newFormValues = {
      ...formValues,
      [FIELD_IDS.DERIVATION_FIELDS]: _filter(_get(formValues, FIELD_IDS.DERIVATION_FIELDS), (item) => item !== selectedValue),
    };

    const entityName = _get(match, 'params.entityName');
    const updatedConditionBuilderFieldDefinitions = { ...conditionBuilderFieldDefinitionObject };
    const fieldDefs = _get(conditionBuilderFieldDefinitionObject, `${entityName}_derivationFields`, EMPTY_OBJECT);
    let filteredFieldDefs = _filter(fieldDefs, (fieldDef) =>
      _includes(_get(newFormValues, FIELD_IDS.DERIVATION_FIELDS, EMPTY_ARRAY), fieldDefinitionReader.name(fieldDef)),
    );

    filteredFieldDefs = _keyBy(filteredFieldDefs, 'name');
    _set(updatedConditionBuilderFieldDefinitions, entityName, filteredFieldDefs);

    setState({ formValues: newFormValues, conditionBuilderFieldDefinitionObject: updatedConditionBuilderFieldDefinitions });
    return;
  }

  setState({ isDeleteFieldModal: true, selectedValue, errorTabMsg, allErrorTabs });
};

const handleCancel = ({ setState }) => {
  setState({ isDeleteFieldModal: false });
};

const handleConfirmModalRequest = ({ getState, setState }) => {
  const { formValues, selectedValue, conditionBuilderFieldDefinitionObject, match, conditionErrorObject, allErrorTabs } = getState();
  const newAllTabsByName = { ..._get(formValues, 'allTabsByName') };
  const entityName = _get(match, 'params.entityName');
  const newConditionErrorObject = { ...conditionErrorObject };

  let newFormValues = {
    ...formValues,
    [FIELD_IDS.DERIVATION_FIELDS]: _filter(_get(formValues, FIELD_IDS.DERIVATION_FIELDS), (item) => item !== selectedValue),
  };

  _forEach(allErrorTabs, (item) => {
    let tabData = newAllTabsByName[item];
    tabData = {
      ...tabData,
      [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: _map(_get(tabData, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY), (condition) => {
        if (getFieldNameFromFieldValue(_get(condition, CONDITION_FIELD_IDS.FIELD, [''])) === selectedValue) {
          return EMPTY_OBJECT;
        } else return condition;
      }),
    };
    const error = isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)(null, tabData);
    newConditionErrorObject[item] = { ...error, ..._get(error, 'message') };

    if (_isEmpty(_get(tabData, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST)) && _get(newConditionErrorObject, [item, 'isValid'], false)) {
      newConditionErrorObject[item] = { isValid: false, errorMessage: __('There should be atleast one condition ') };
    }

    _set(newAllTabsByName, item, tabData);
  });

  newFormValues = { ...newFormValues, allTabsByName: newAllTabsByName };

  const updatedConditionBuilderFieldDefinitions = { ...conditionBuilderFieldDefinitionObject };
  const fieldDefs = _get(conditionBuilderFieldDefinitionObject, `${entityName}_derivationFields`, EMPTY_OBJECT);
  let filteredFieldDefs = _filter(fieldDefs, (fieldDef) =>
    _includes(_get(newFormValues, FIELD_IDS.DERIVATION_FIELDS, EMPTY_ARRAY), fieldDefinitionReader.name(fieldDef)),
  );

  filteredFieldDefs = _keyBy(filteredFieldDefs, 'name');
  _set(updatedConditionBuilderFieldDefinitions, entityName, filteredFieldDefs);

  setState({
    isDeleteFieldModal: false,
    formValues: newFormValues,
    conditionBuilderFieldDefinitionObject: updatedConditionBuilderFieldDefinitions,
    conditionErrorObject: newConditionErrorObject,
    allErrorTabs: EMPTY_ARRAY,
    errorTabMsg: EMPTY_STRING,
  });
};

const handleRecordTypeChangeCancel = ({ setState }) => {
  setState({ isTypeChangeModal: false });
};

const handleRecordTypeChangeSuccess = ({ getState, setState }) => {
  const { formValues, recordType = RECORD_TYPES.STANDARD } = getState();
  let newFormValues = {};
  let newConditionErrorObject = {};

  if (recordType === RECORD_TYPES.STANDARD) {
    const allTabsByName = {};

    _forEach(_get(formValues, 'allTabsByName', EMPTY_OBJECT), (_value, key) => {
      allTabsByName[key] = {};
      newConditionErrorObject[key] = {};
    });

    newFormValues = {
      [FIELD_IDS.RECORD_TYPE]: RECORD_TYPES.STANDARD,
      [FIELD_IDS.DERIVATION_FIELDS]: EMPTY_ARRAY,
      allTabsByName,
    };
  } else {
    const allTabsByName = {};

    _forEach(_get(formValues, 'allTabsByName', EMPTY_OBJECT), (_value, key) => {
      allTabsByName[key] = { [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: [{}], [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: '1' };
    });
    newFormValues = {
      [FIELD_IDS.RECORD_TYPE]: RECORD_TYPES.DERIVED,
      [FIELD_IDS.DERIVATION_FIELDS]: EMPTY_ARRAY,
      allTabsByName,
    };
    newConditionErrorObject = getErrorObject(allTabsByName);
  }

  setState({ isTypeChangeModal: false, formValues: newFormValues, conditionErrorObject: newConditionErrorObject });
};

const handleRedirect = ({ getState }) => {
  const { match, history } = getState();
  const entityName = _get(match, 'params.entityName');

  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.RECORD_TYPE}`);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [ACTION_TYPES.ON_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirect,
  [ACTION_TYPES.ON_TAB_CLICK]: handleTabClick,
  [ACTION_TYPES.ON_DESELECT]: handleDeselect,
  [ACTION_TYPES.ON_CANCEL]: handleCancel,
  [ACTION_TYPES.CONFIRM_MODAL_REQUEST]: handleConfirmModalRequest,
  [ACTION_TYPES.ON_TYPE_CHANGE_CANCEL]: handleRecordTypeChangeCancel,
  [ACTION_TYPES.ON_TYPE_CHANGE_SUCCESS]: handleRecordTypeChangeSuccess,
};

export default ACTION_HANDLERS;
