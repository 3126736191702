import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import RadioRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio/Radio';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';
import { RECORD_TYPES_OPTIONS } from '../constants/recordTypeDetailsForm.general.constants';
import ACTION_TYPES from '../constants/recordTypeDetailsForm.actionTypes';

import styles from '../recordTypeDetailForm.module.scss';

const FIELD_IDS = {
  RECORD_TYPE: 'recordDefinitionType',
  DERIVATION_FIELDS: 'derivationFields',
  DERIVATION_ALL_CONDITIONS: 'derivationAllCondition',
};

const RECORD_TYPE_FIELD = {
  id: FIELD_IDS.RECORD_TYPE,
  renderer: RadioRenderer,
  renderOptions: {
    label: __('Edit Record Type'),
    required: true,
    validators: [isRequiredRule],
    radios: RECORD_TYPES_OPTIONS,
    labelClassName: 'm-b-8',
  },
};

const DERIVATION_FIELD = {
  id: FIELD_IDS.DERIVATION_FIELDS,
  renderer: SelectInput,
  renderOptions: {
    label: __('Select Fields'),
    placeholder: __('Select Users'),
    key: 'select-bar',
    mode: 'multiple',
    required: true,
    validators: [isRequiredRule],
    className: styles.customSelect,
  },
};

const FORM_FIELDS = {
  [FIELD_IDS.RECORD_TYPE]: RECORD_TYPE_FIELD,
  [FIELD_IDS.DERIVATION_FIELDS]: DERIVATION_FIELD,
};

const getFields = (fieldDefinitionsOptions, onAction) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.DERIVATION_FIELDS]: addToRenderOptions(DERIVATION_FIELD, [
    {
      path: 'options',
      value: fieldDefinitionsOptions,
    },
    {
      path: 'onDeselect',
      value: (value) => onAction({ type: ACTION_TYPES.ON_DESELECT, payload: { value } }),
    },
  ]),
});

export { FIELD_IDS, getFields };
