import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _isString from 'lodash/isString';
import _includes from 'lodash/includes';
import _indexOf from 'lodash/indexOf';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _cloneDeep from 'lodash/cloneDeep';

import { getFieldsAndSectionFromView } from './formViewRenderer.helpers';
import { DISABLED_FIELDS } from './formViewRenderer.constants';
import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';

import styles from './formViewRenderer.module.scss';

const getColumnClassName = (columns) => {
  const columnSize = _size(columns);

  return styles[`complexRowWithItem${columnSize}`];
};

const changeFieldNamesForComplex = (complexFieldSections, complexFieldName) => {
  _forEach(
    complexFieldSections,
    (section, index) => {
      if (_isString(section)) {
        // eslint-disable-next-line no-param-reassign
        complexFieldSections[index] = `${complexFieldName}.${section}`;
      } else {
        const rows = _get(section, 'rows');
        _forEach(rows, (row) => {
          const columns = _get(row, 'columns');
          changeFieldNamesForComplex(columns, complexFieldName);
        });
      }
    },
    [],
  );
};

const addComplexSectionsInForm = (formSections, complexViewConfiguration, fieldDefinitionsByName, formMode) => {
  _forEach(formSections, (section) => {
    if (_isString(section)) {
      const dataType = _get(fieldDefinitionsByName, `${section}.dataType`);
      if (dataType === DATA_TYPES.COMPLEX) {
        const complexViewConfig = _get(complexViewConfiguration, section);
        const { formSections: complexFieldSections } = getFieldsAndSectionFromView(complexViewConfig);
        changeFieldNamesForComplex(complexFieldSections, section);
        formSections.splice(
          _indexOf(formSections, section),
          1,
          ..._map(complexFieldSections, (complexFieldSection) => ({
            ...complexFieldSection,
            className: getColumnClassName(formSections),
          })),
        );
      } else if (_includes(DISABLED_FIELDS, section)) {
        formSections.splice(0, 1);
      }
    } else {
      const rows = _get(section, 'rows');
      _forEach(rows, (row) => {
        const columns = _get(row, 'columns');
        addComplexSectionsInForm(columns, complexViewConfiguration, fieldDefinitionsByName, formMode);
      });
    }
  });
};

const getFormSections = (formSections, complexViewConfiguration, fieldDefinitionsByName, formMode) => {
  const finalSections = _cloneDeep(formSections);
  addComplexSectionsInForm(finalSections, complexViewConfiguration, fieldDefinitionsByName, formMode);
  return finalSections;
};

export { getFormSections };
