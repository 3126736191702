import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
// Components
import InputTable from '@tekion/tekion-components/organisms/inputTable/containers/withRowActions/Table';
import Button from '@tekion/tekion-components/atoms/Button';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Helpers
import { getActionsForRow } from './helper/listInputTable.general.helpers';

// Constants
import { LABEL_COLUMN_CONFIG, VALUE_COLUMN_CONFIG } from './helper/listInputTable.columns';
import { ROW_ACTION_PROPS, TABLE_ACTION_TYPES } from './constants/listInputTable.general.constants';
import { ACTION_TYPES } from '../../constants/recordTypeOverride.constants';

// Styles
import styles from '../../recordTypeOverride.module.scss';

const ListInputTable = (props) => {
  const { value, onAction } = props;

  const columns = useMemo(() => [LABEL_COLUMN_CONFIG, VALUE_COLUMN_CONFIG], []);

  const getActions = useCallback(() => getActionsForRow(), []);

  const handleAction = useCallback(
    (action) => {
      const actionType = _get(action, 'type', EMPTY_STRING);

      if (actionType === TABLE_ACTION_TYPES.ON_CHANGE) {
        onAction({
          type: ACTION_TYPES.OPTION_TABLE_ON_CHANGE,
          payload: _get(action, 'payload', EMPTY_OBJECT),
        });
      }
      if (actionType === TABLE_ACTION_TYPES.TABLE_ACTION_CLICK) {
        const tableActionType = _get(action, 'payload.actionType', EMPTY_STRING);
        const row = _get(action, 'payload.nestingPath.[0]', EMPTY_STRING);
        if (tableActionType === TABLE_ACTION_TYPES.REMOVE_ROW) {
          onAction({
            type: ACTION_TYPES.OPTION_TABLE_REMOVE_ROW,
            payload: { row },
          });
        }
      }
    },
    [onAction],
  );

  const handleAddRow = useCallback(() => {
    onAction({
      type: ACTION_TYPES.OPTION_TABLE_ADD_ROW,
    });
  }, [onAction]);

  return (
    <div className={styles.listInputContainer}>
      <InputTable
        columns={columns}
        value={value}
        getActionsForRow={getActions}
        className={styles.inputTable}
        rowActionProps={ROW_ACTION_PROPS}
        onClick={handleAction}
        onAction={handleAction}
      />
      <Button onClick={handleAddRow} view={Button.VIEW.TERTIARY}>
        {__('Add Option')}
      </Button>
    </div>
  );
};

ListInputTable.propTypes = {
  value: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ListInputTable.defaultProps = {
  value: EMPTY_ARRAY,
};

export default React.memo(ListInputTable);
