exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".workspaceModal_cards__iEP2BAYjkt{display:flex;flex-wrap:wrap;align-items:stretch;overflow-y:auto}.workspaceModal_search__cRyED9pAzD{display:flex;justify-content:flex-end;width:100%;height:4rem}.workspaceModal_searchContainer__6V9GvApjG3{width:28rem;color:#161616}[dir] .workspaceModal_searchContainer__6V9GvApjG3{border-radius:.4rem}[dir=ltr] .workspaceModal_searchContainer__6V9GvApjG3{margin:1.6rem 1rem 1rem 2rem}[dir=rtl] .workspaceModal_searchContainer__6V9GvApjG3{margin:1.6rem 2rem 1rem 1rem}.workspaceModal_rightShift__2Y1QrjjRTG{display:flex;justify-content:flex-end}", ""]);

// Exports
exports.locals = {
	"cards": "workspaceModal_cards__iEP2BAYjkt",
	"search": "workspaceModal_search__cRyED9pAzD",
	"searchContainer": "workspaceModal_searchContainer__6V9GvApjG3",
	"rightShift": "workspaceModal_rightShift__2Y1QrjjRTG"
};