import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _size from 'lodash/size';

import Dropdown from '@tekion/tekion-components/molecules/DropDown';
import Menu from '@tekion/tekion-components/molecules/Menu';
import Button from '@tekion/tekion-components/atoms/Button';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { ACTION_TYPES } from './listViewRenderer.constants';

const DownloadButton = ({ downloadOptions, onAction }) => {
  const handleMenuItemClick = useCallback(
    (payload) => {
      onAction({ type: ACTION_TYPES.ON_DOWNLOAD, payload });
    },
    [onAction],
  );

  const renderActionMenu = useCallback(
    () => (
      <Menu>
        {_size(downloadOptions) > 0 ? (
          _map(downloadOptions, ({ displayName, downloadType }) => (
            <Menu.Item key={downloadType} onClick={handleMenuItemClick}>
              {displayName}
            </Menu.Item>
          ))
        ) : (
          <Menu.Item>{__('No Export Option')}</Menu.Item>
        )}
      </Menu>
    ),
    [handleMenuItemClick, downloadOptions],
  );

  return (
    <Dropdown overlay={renderActionMenu} trigger={['click']}>
      <Button view="icon" icon="icon-download1" />
    </Dropdown>
  );
};

DownloadButton.propTypes = {
  downloadOptions: PropTypes.array,
  onAction: PropTypes.func,
};

DownloadButton.defaultProps = {
  downloadOptions: EMPTY_ARRAY,
  onAction: _noop,
};

export default DownloadButton;
