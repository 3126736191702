import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL_RECORD_GROUP = 'core/u';

const getAllRecordGroups = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL_RECORD_GROUP}/groups/search`, payload);

const createRecordGroup = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL_RECORD_GROUP}/groups`, payload);

const getRecordGroupByName = (recordGroupName) => ApiService.get(`${BASE_URL_RECORD_GROUP}/groups/name/${recordGroupName}`);

const updateRecordGroup = (recordGroupName, payload = EMPTY_OBJECT) =>
  ApiService.put(`${BASE_URL_RECORD_GROUP}/groups/name/${recordGroupName}`, payload);

const deleteRecordGroupByName = (recordGroupName) => ApiService.delete(`${BASE_URL_RECORD_GROUP}/groups/name/${recordGroupName}`);

export default { getAllRecordGroups, createRecordGroup, getRecordGroupByName, updateRecordGroup, deleteRecordGroupByName };
