exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".approvalCommentsView_commentSection__wCkMwNepjp{display:flex;flex-direction:column;justify-content:space-between;height:100%}.approvalCommentsView_commentSection__wCkMwNepjp .approvalCommentsView_commentDetailsSection__44atRLQ4uk{display:flex;justify-content:space-between;flex-direction:column}.approvalCommentsView_commentSection__wCkMwNepjp .approvalCommentsView_commentDetailsSection__44atRLQ4uk .approvalCommentsView_showMoreButton__aVdj51a2xb{display:flex;justify-content:center}[dir] .approvalCommentsView_commentSection__wCkMwNepjp .approvalCommentsView_commentDetailsSection__44atRLQ4uk .approvalCommentsView_showMoreButton__aVdj51a2xb{margin-top:2rem}.approvalCommentsView_commentInbox__mPtKGM8QMp{display:flex;justify-content:space-between}[dir] .approvalCommentsView_commentInbox__mPtKGM8QMp{border:1px solid #d4d5d6;margin:2rem 2rem 4rem;padding:1rem}[dir] .approvalCommentsView_commentInbox__mPtKGM8QMp .approvalCommentsView_commentContentContainer__qtasYeYuXe{border:none}.approvalCommentsView_commentInbox__mPtKGM8QMp .approvalCommentsView_commentContentContainer__qtasYeYuXe .approvalCommentsView_input__kxdMMG9zHF:focus{outline:none !important}.approvalCommentsView_spinnerWrap__9WX6qM9FX5{display:flex;justify-content:center;align-items:center;width:100%;height:100%}", ""]);

// Exports
exports.locals = {
	"commentSection": "approvalCommentsView_commentSection__wCkMwNepjp",
	"commentDetailsSection": "approvalCommentsView_commentDetailsSection__44atRLQ4uk",
	"showMoreButton": "approvalCommentsView_showMoreButton__aVdj51a2xb",
	"commentInbox": "approvalCommentsView_commentInbox__mPtKGM8QMp",
	"commentContentContainer": "approvalCommentsView_commentContentContainer__qtasYeYuXe",
	"input": "approvalCommentsView_input__kxdMMG9zHF",
	"spinnerWrap": "approvalCommentsView_spinnerWrap__9WX6qM9FX5"
};