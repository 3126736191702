import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _snakeCase from 'lodash/snakeCase';

// Tekion-base
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

// Actions
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../../../actions/conditionBuilder.actions';
import {
  getProcessByName,
  getApprovalSettingForGroupCategory,
  createProcess,
  updateProcess,
} from '../../../../../../../actions/approvalManagement.actions';

// Helpers
import { convertResponseToValues, convertValuesToPayload } from './approvalProcessForm.payloadHelpers';

// Constants
import { FORM_MODES } from '../../../../../../../constants/general.constants';
import { CURRENT_ENTITY_NAMESPACE } from '../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import ROUTES from '../../../../../constants/routes';
import { APPROVAL_CENTRE_FIELD_IDS, APPROVAL_VALIDITY_TYPES, CUSTOM_ENTITY_CATEGORY } from '../../../../../../../constants/approvalCentre.constants';
import FIELD_IDS from '../constants/approvalProcessForm.fieldIds';
import ACTION_TYPES from '../constants/approvalProcessForm.actionTypes';
import { INITIAL_STATE } from '../constants/approvalProcessForm.constants';

const handleLoadData = async ({ setState, processName }) => {
  setState({ formMode: FORM_MODES.EDIT, isApprovalProcessLoading: true });

  const data = await getProcessByName(processName);
  const formValues = convertResponseToValues(data);
  setState({
    isApprovalProcessLoading: false,
    formValues,
  });

  return formValues;
};

const handleLoadApprovalSetting = async ({ setState, data }) => {
  const group = getArraySafeValue(tget(data, FIELD_IDS.GROUP));
  const category = getArraySafeValue(tget(data, FIELD_IDS.CATEGORY));

  if (!_isEmpty(group) && !_isEmpty(category)) {
    setState({ isApprovalSettingLoading: true });

    const approvalSetting = await getApprovalSettingForGroupCategory({ group, category });
    const settingName = tget(approvalSetting, APPROVAL_CENTRE_FIELD_IDS.NAME);
    const fields = tget(approvalSetting, APPROVAL_CENTRE_FIELD_IDS.FIELDS);

    const mapOfVariableToEntityName = { [CURRENT_ENTITY_NAMESPACE]: settingName };

    setState({
      isApprovalSettingLoading: false,
      approvalSetting,
      mapOfVariableToEntityName,
      fieldDefinitionsForConditionBuilder: { [settingName]: _keyBy(fields, APPROVAL_CENTRE_FIELD_IDS.NAME) },
    });
  }
};

const handleLoadEntityFields = async ({ setState, getState, data }) => {
  const mapOfVariableToEntityName = {};
  const category = getArraySafeValue(tget(data, FIELD_IDS.CATEGORY));
  const entityType = getArraySafeValue(tget(data, FIELD_IDS.TYPE));

  const { fieldDefinitionsForConditionBuilder: existingDefinitions } = getState();

  if (category === CUSTOM_ENTITY_CATEGORY && !_isEmpty(entityType)) {
    _set(mapOfVariableToEntityName, CURRENT_ENTITY_NAMESPACE, entityType);

    setState({ isConditionFieldsLoading: true });

    const fieldDefinitionsForConditionBuilder = await fetchFieldDefinitionsForConditionBuilder(
      mapOfVariableToEntityName,
      existingDefinitions || {},
      false,
    );

    setState({
      isConditionFieldsLoading: false,
      mapOfVariableToEntityName,
      fieldDefinitionsForConditionBuilder,
    });
  }
};

const handleInit = async ({ setState, getState }) => {
  const { match = EMPTY_OBJECT } = getState();
  const { processName } = _get(match, 'params', '');

  if (_isEmpty(processName)) {
    setState({ formMode: FORM_MODES.CREATE });
  } else {
    setState({ formMode: FORM_MODES.EDIT });
    const data = await handleLoadData({ setState, getState, processName });
    handleLoadApprovalSetting({ setState, getState, data });
    handleLoadEntityFields({ setState, getState, data });
  }
};

const handleFieldChange = async ({ params, getState, setState }) => {
  const { id, value } = params;
  const { formValues, formMode } = getState();
  let updatedValues = { ...formValues, [id]: value };

  if (id === FIELD_IDS.GROUP || id === FIELD_IDS.CATEGORY) {
    updatedValues = {
      ...updatedValues,
      [FIELD_IDS.CONDITION]: INITIAL_STATE.formValues[FIELD_IDS.CONDITION],
      [FIELD_IDS.TYPE]: undefined,
    };

    setState({ formValues: updatedValues });
    handleLoadApprovalSetting({ setState, data: updatedValues });
  } else if (id === FIELD_IDS.TYPE) {
    updatedValues = {
      ...updatedValues,
      [FIELD_IDS.CONDITION]: INITIAL_STATE.formValues[FIELD_IDS.CONDITION],
    };

    setState({ formValues: updatedValues });
    handleLoadEntityFields({ getState, setState, data: updatedValues });
  } else if (id === FIELD_IDS.DISPLAY_NAME && formMode === FORM_MODES.CREATE) {
    updatedValues = { ...updatedValues, [FIELD_IDS.NAME]: _snakeCase(value) };
    setState({ formValues: updatedValues });
  } else {
    setState({ formValues: updatedValues });
  }
};

const handleErrors = ({ params, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const handleSubmit = async ({ getState, setState }) => {
  const { formMode, formValues = {}, history, match, errors } = getState();

  const validityType = tget(formValues, APPROVAL_CENTRE_FIELD_IDS.VALIDITY_TYPE);
  const days = tget(formValues, APPROVAL_CENTRE_FIELD_IDS.VALIDITY_DAYS);
  const hours = tget(formValues, APPROVAL_CENTRE_FIELD_IDS.VALIDITY_HOURS);
  const mins = tget(formValues, APPROVAL_CENTRE_FIELD_IDS.VALIDITY_MINUTES);

  let isValid = true;
  if (validityType === APPROVAL_VALIDITY_TYPES.RELATIVE) {
    if (_isNil(days) && _isNil(hours) && _isNil(mins)) {
      isValid = false;
      setState({ errors: { ...errors, [FIELD_IDS.VALIDITY_DAYS]: __('Please fill relative validity details') } });
    }
  }

  if (isValid) {
    setState({ isSubmitting: true });

    const payload = convertValuesToPayload(formValues);
    if (formMode === FORM_MODES.CREATE) {
      const response = await createProcess(payload);
      if (!_isEmpty(response)) {
        toaster(TOASTER_TYPE.SUCCESS, __('Approval process created succesfully.'));
        setTimeout(() => {
          history.push(ROUTES.APPROVAL_PROCESSES_ROUTE);
        }, ES_REFETCH_DELAY);
      } else {
        setState({ isSubmitting: false });
      }
    } else {
      const processName = tget(match, 'params.processName');
      const response = await updateProcess(processName, payload);
      if (!_isEmpty(response)) {
        toaster(TOASTER_TYPE.SUCCESS, __('Approval process updated successfully.'));
        setTimeout(() => {
          history.push(ROUTES.APPROVAL_PROCESSES_ROUTE);
        }, ES_REFETCH_DELAY);
      } else {
        setState({ isSubmitting: false });
      }
    }
  }
};

const handleOnFieldBlur = ({ getState, setState, params }) => {
  const { errors = EMPTY_OBJECT } = getState();
  const { id, value, newConditionExpression } = params;
  if (id === FIELD_IDS.CONDITION) {
    if (!_isEmpty(newConditionExpression)) {
      setState({ errors: { ...errors, [id]: value } });
    } else {
      setState({ errors: { ...errors, [id]: undefined } });
    }
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [FORM_ACTION_TYPES.ON_FIELD_BLUR]: handleOnFieldBlur,
};

export default ACTION_HANDLERS;
