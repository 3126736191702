import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

const FILTER_FIELD_KEYS = {
  FIELD: 'field',
  FILTER_TYPE: 'filterType',
  VALUES: 'values',
  SHOULD_FILTERS: 'shouldFilters',
};

const TEXT_STARTS_WITH = 'TEXT_STARTS_WITH';
const INVALID_FILTER_VALUES = [NaN, undefined, null, ''];
const TEXT_CONTAINS = 'TEXT_CONTAINS';
const FILTER_OPERATORS = { ...OPERATORS, TEXT_CONTAINS };

export default FILTER_FIELD_KEYS;
export { TEXT_STARTS_WITH, INVALID_FILTER_VALUES, TEXT_CONTAINS, FILTER_OPERATORS };
