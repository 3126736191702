import produce from 'immer';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _isNil from 'lodash/isNil';
import _filter from 'lodash/filter';
import _cloneDeep from 'lodash/cloneDeep';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import {
  formFormattedDefaultSortDetails,
  getAllSortableFields,
  getRendererPropFormattedDefaultSortDetails,
  getUpdatedDefaultSortDetails,
} from './sortOptionsConfigurator.helper';

import ACTION_TYPES from '../constants/sortOptionsConfigurator.actionTypes';
import { FIELD_IDS, ORDER_FIELD_OPTION_IDS } from '../constants/sortOptionsConfigurator.constants';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../../constants/viewBuilder.constants';

import entityReader from '../../../../../readers/entity.reader';

const handleInit = ({ getState, setState }) => {
  const { entity = EMPTY_OBJECT } = getState();

  const fieldDefinitions = entityReader.fieldDefinitions(entity);
  const sortableFieldOptions = getAllSortableFields(fieldDefinitions);

  setState({
    sortableFieldOptions,
  });
};

const handleSelectedViewComponentPropertiesChange = ({ setState, params = EMPTY_OBJECT }) => {
  const { selectedViewComponentProperties } = params;
  let allFormValues = {};
  const sortableFields = tget(selectedViewComponentProperties, FIELD_IDS.SORTABLE_FIELDS, EMPTY_ARRAY);
  let defaultSortDetails = tget(selectedViewComponentProperties, FIELD_IDS.DEFAULT_SORT_DETAILS, EMPTY_ARRAY);
  defaultSortDetails = formFormattedDefaultSortDetails(defaultSortDetails);

  if (!_isEmpty(sortableFields) && _isEmpty(defaultSortDetails)) {
    defaultSortDetails = [{ [FIELD_IDS.ORDER]: [ORDER_FIELD_OPTION_IDS.ASC] }];
  }

  allFormValues = { [FIELD_IDS.SORTABLE_FIELDS]: sortableFields || [], [FIELD_IDS.DEFAULT_SORT_DETAILS]: defaultSortDetails || [] };

  setState({ allFormValues });
};

const handleConfigureSortOptionClick = ({ getState, setState }) => {
  const { allFormValues = EMPTY_OBJECT } = getState();

  setState({
    prevState: {
      allFormValues: _cloneDeep(allFormValues),
    },
    isModalVisible: true,
    error: EMPTY_OBJECT,
  });
};

const handleOnChange = ({ setState, params }) => {
  const { id, value } = params;

  setState(
    produce((draft) => {
      _set(draft, ['allFormValues', id], value);
      if (id === FIELD_IDS.SORTABLE_FIELDS) {
        const defaultSortDetails = tget(draft, ['allFormValues', FIELD_IDS.DEFAULT_SORT_DETAILS], EMPTY_ARRAY);
        const updatedDefaultSortDetails = getUpdatedDefaultSortDetails(defaultSortDetails, value);
        _set(draft, ['allFormValues', FIELD_IDS.DEFAULT_SORT_DETAILS], updatedDefaultSortDetails);
      }
    }),
  );
};

const handleSubmit = ({ getState, setState }) => {
  const { allFormValues = EMPTY_OBJECT, onAction: configuratorOnAction } = getState();

  const sortableFields = tget(allFormValues, [FIELD_IDS.SORTABLE_FIELDS], EMPTY_ARRAY);
  const defaultSortDetails = tget(allFormValues, [FIELD_IDS.DEFAULT_SORT_DETAILS], EMPTY_ARRAY);

  const { isValid, error, rendererPropFormattedDefaultSortDetails } = getRendererPropFormattedDefaultSortDetails(defaultSortDetails);

  setState({ error });

  if (!isValid) {
    return;
  }

  const sortOptions = {
    [FIELD_IDS.SORTABLE_FIELDS]: sortableFields,
    [FIELD_IDS.DEFAULT_SORT_DETAILS]: rendererPropFormattedDefaultSortDetails,
  };

  configuratorOnAction({
    type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
    payload: { id: ALL_VIEW_PROPERTY_KEYS.SORT_DETAILS, value: sortOptions },
  });

  setState({ isModalVisible: false });
};

const handleCancelModal = ({ getState, setState }) => {
  const { prevState = EMPTY_OBJECT } = getState();

  setState({ isModalVisible: false, ...prevState });
};

const handleTableAddRow = ({ getState, setState }) => {
  const { allFormValues = EMPTY_OBJECT } = getState();
  const newAllFormValues = { ...allFormValues };
  const defaultSortDetails = tget(allFormValues, FIELD_IDS.DEFAULT_SORT_DETAILS, []);

  _set(newAllFormValues, [FIELD_IDS.DEFAULT_SORT_DETAILS], [...defaultSortDetails, { [FIELD_IDS.ORDER]: [ORDER_FIELD_OPTION_IDS.ASC] }]);

  setState({ allFormValues: newAllFormValues });
};

const handleTableDeleteRow = ({ getState, setState, params }) => {
  const { allFormValues } = getState();
  const rowIndex = _get(params, 'rowIndex');

  if (!_isNil(rowIndex)) {
    const newAllFormValues = { ...allFormValues };
    let sortDetails = tget(newAllFormValues, FIELD_IDS.DEFAULT_SORT_DETAILS, []);
    sortDetails = _filter(sortDetails, (data, index) => index !== rowIndex);

    _set(newAllFormValues, [FIELD_IDS.DEFAULT_SORT_DETAILS], sortDetails);
    setState({ allFormValues: newAllFormValues });
  }
};

const handleTableOnChange = ({ getState, setState, params }) => {
  const { allFormValues = EMPTY_OBJECT } = getState();
  const rowIndex = _get(params, 'rowIndex');
  const id = _get(params, 'id');
  const value = _get(params, 'value');

  if (!_isNil(rowIndex) && id) {
    const newAllFormValues = _cloneDeep(allFormValues);

    _set(newAllFormValues, [FIELD_IDS.DEFAULT_SORT_DETAILS, rowIndex, id], value);

    setState({ allFormValues: newAllFormValues });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE]: handleSelectedViewComponentPropertiesChange,
  [ACTION_TYPES.ON_CLICK_CONFIGURE_SORT_OPTIONS]: handleConfigureSortOptionClick,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnChange,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL_MODAL]: handleCancelModal,
  [ACTION_TYPES.TABLE_ADD_ROW]: handleTableAddRow,
  [ACTION_TYPES.TABLE_REMOVE_ROW]: handleTableDeleteRow,
  [ACTION_TYPES.TABLE_ON_CHANGE]: handleTableOnChange,
};

export default ACTION_HANDLERS;
