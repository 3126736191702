const ACTION_TYPES = {
  ON_FETCH_PROJECT_DATA: 'ON_FETCH_PROJECT_DATA',
  OPEN_CANCELATION_MODAL: 'OPEN_CANCELATION_MODAL',
  ON_CONFIRM_DIALOG_SUBMIT: 'ON_CONFIRM_DIALOG_SUBMIT',
  ON_CONFIRM_DIALOG_CANCEL: 'ON_CONFIRM_DIALOG_CANCEL',
  CREATE_EXPORT: 'CREATE_EXPORT',
  PUBLISH_BUNDLE: 'PUBLISH_BUNDLE',
  CLONE: 'CLONE',
  DELETE: 'DELETE',
};

export default ACTION_TYPES;
