const COLUMN_IDS = {
  USER_NAME: 'userName',
  ACCESS_TYPE: 'recordAccessType',
  EXPIRY_TIME: 'expiryTime',
  ACTION: 'action',
};

const ACCESS_DENIED_WARNING_MESSAGE = __(
  'This users might not have access to this record. He/She will need to be given access to the entity to be able to access the shared record.',
);

export { COLUMN_IDS, ACCESS_DENIED_WARNING_MESSAGE };
