import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AppSkeleton from './organisms/appSkeleton';
import DevPlatform from './pages/devPlatform';
import DevPlatformExperience from './pages/devPlatformExperience';
import NotificationRedirector from './pages/notificationRedirector';
import PermissionsRoute from './atoms/permissionsRoute';
import PermissionsProvider from './organisms/permissionsProvider';

import { BASE_ROUTE, STUDIO_ROUTE, NOTIFICATION_REDIRECTOR_ROUTE, TENANT_UNIVERSE_BASE_ROUTE } from './constants/routes';
import { PERMISSIONS_CONTROL_FLAGS } from './constants/permissionsProvider.constants';

function Routes() {
  // TODO: check use case of permissions for tenant universe

  return (
    <PermissionsProvider>
      <Switch>
        <Route path={NOTIFICATION_REDIRECTOR_ROUTE} component={NotificationRedirector} />
        <AppSkeleton>
          <Switch>
            <PermissionsRoute
              path={TENANT_UNIVERSE_BASE_ROUTE}
              render={(props) => <DevPlatform {...props} />}
              validFor={PERMISSIONS_CONTROL_FLAGS.DEV_PLATFORM_STUDIO}
            />
            <PermissionsRoute
              path={STUDIO_ROUTE}
              render={(props) => <DevPlatform {...props} />}
              validFor={PERMISSIONS_CONTROL_FLAGS.DEV_PLATFORM_STUDIO}
            />
            <PermissionsRoute
              path={BASE_ROUTE}
              render={(props) => <DevPlatformExperience {...props} />}
              validFor={PERMISSIONS_CONTROL_FLAGS.DEV_PLATFORM_EXPERIENCE}
            />
          </Switch>
        </AppSkeleton>
      </Switch>
    </PermissionsProvider>
  );
}

export default Routes;
