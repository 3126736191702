import _set from 'lodash/set';
import _forEach from 'lodash/forEach';
import _includes from 'lodash/includes';

import { tget } from '@tekion/tekion-base/utils/general';

import DATA_TYPES from '../../../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../../../constants/fieldDefinition.fieldTypes';
import { ASSIGNED_VARIABLE_FIELD_IDS, VARIABLE_TYPES } from '../constants/workflow.assignVariableConstants';

const getVariableFieldDefinition = (variableInfo) => {
  const variableType = tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE);
  const fieldDefinition = {
    name: tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_NAME),
    displayName: tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_NAME),
    dataType: DATA_TYPES.TEXT,
    creatable: true,
    editable: true,
    filterable: true,
    [ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE]: variableType,
  };

  if (variableType === VARIABLE_TYPES.PRIMITIVE) {
    return {
      ...fieldDefinition,
      dataType: tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_DATA_TYPE),
      fieldType: tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_FIELD_TYPE),
      multiValued: tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.MULTI_VALUED),
    };
  } else if (variableType === VARIABLE_TYPES.ENTITY_RECORD_LIST) {
    return {
      ...fieldDefinition,
      multiValued: true,
      [ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME]: tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME),
    };
  } else {
    return {
      ...fieldDefinition,
      fieldType: FIELD_TYPES.RELATIONSHIP,
      lookupField: {
        entityType: tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME),
      },
      creatable: false,
      editable: false,
      searchable: true,
    };
  }
};

const getWorkflowVariablesFieldDefinitions = (variablesInfo, scopedVariables) => {
  const workflowVariablesFieldDefinitions = {};
  _forEach(variablesInfo, (variableInfo, variableName) => {
    if (_includes(scopedVariables, variableName)) {
      _set(workflowVariablesFieldDefinitions, variableName, getVariableFieldDefinition(variableInfo));
    }
  });
  return workflowVariablesFieldDefinitions;
};

export default getWorkflowVariablesFieldDefinitions;
