import _map from 'lodash/map';
import _castArray from 'lodash/castArray';

import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

const ROW_COUNT = 20;

const getEntityValueOption = (entityDef) => ({
  label: __(`${tget(entityDef, 'displayName', EMPTY_STRING)}`) || __(`${tget(entityDef, 'name', EMPTY_STRING)}`),
  value: tget(entityDef, 'name', EMPTY_STRING),
});

const getEntityOptions = (data) => _map(data, getEntityValueOption);

const getEntityPayload = ({ nextPageToken, searchText = '' }) => ({
  filters: [{ field: 'displayName', filterType: OPERATORS.TEXT_STARTS_WITH, values: _castArray(searchText) }],
  rows: ROW_COUNT,
  nextPageToken,
});

export { getEntityOptions, getEntityPayload };
