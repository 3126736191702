import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { createRecordGroup, getRecordGroupDetails, updateRecordGroup } from '../../../../../../actions/recordGroup.actions';
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../../actions/conditionBuilder.actions';
import { getFormValues, getPayload } from './entityRecordGroupForm.helpers';

import ROUTES from '../../../../constants/routes';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import ACTION_TYPES from '../constants/entityRecordGroupForm.actionTypes';
import { FIELD_IDS } from '../../../../../../constants/entityRecordGroup.constants';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';

const handleLoadConditionFields = async ({ getState, setState }) => {
  const { formValues } = getState();

  setState({ isConditionFieldsLoading: true });
  const entityName = getArraySafeValue(_get(formValues, FIELD_IDS.ENTITY));

  const mapOfVariableToEntityName = { $record: entityName };
  const conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityName);

  setState({ isConditionFieldsLoading: false, conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName });
};

const handleFormInit = async ({ getState, setState }) => {
  const { match } = getState();
  const recordGroupName = _get(match, 'params.recordGroupName');
  setState({ loading: true });
  if (!_isEmpty(recordGroupName)) {
    const recordGroupDetails = await getRecordGroupDetails(recordGroupName);
    const newValues = getFormValues(recordGroupDetails);

    setState({
      loading: false,
      formMode: FORM_MODES.EDIT,
      formValues: newValues,
    });
    return;
  }

  setState({
    loading: false,
    formMode: FORM_MODES.CREATE,
  });
};

const handleFieldChange = ({ params, getState, setState }) => {
  const { id, value } = params;
  const { formValues } = getState();
  const prevEntityName = getArraySafeValue(_get(formValues, FIELD_IDS.ENTITY, ''));
  let updatedValues = { ...formValues, [id]: value };

  if (id === FIELD_IDS.ENTITY && getArraySafeValue(value) !== prevEntityName) {
    updatedValues = {
      ...updatedValues,
      [FIELD_IDS.CONDITION]: {
        [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: [EMPTY_OBJECT],
        [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: '1',
      },
    };
  }
  setState({ formValues: updatedValues });
};

const handleErrors = ({ params, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const handleSubmit = async ({ getState, setState }) => {
  const { errors, formMode, formValues, history, match } = getState();
  const recordGroupName = _get(match, 'params.recordGroupName', EMPTY_STRING);
  const hasErrors = _reduce(
    errors,
    (hasError, curr, fieldId) => {
      if (fieldId !== FIELD_IDS.CONDITION) return hasError || !_isEmpty(curr);
      return _reduce(curr, (conditionHasError, error) => hasError || conditionHasError || !_isEmpty(error), false);
    },
    false,
  );

  if (!hasErrors) {
    setState({ isSubmitting: true });
    const payload = getPayload(formValues);
    let response = {};
    if (formMode === FORM_MODES.EDIT) {
      response = await updateRecordGroup(recordGroupName, payload);
    } else {
      response = await createRecordGroup(payload);
    }
    if (!_isEmpty(response)) {
      setTimeout(() => {
        history.push(ROUTES.ENTITY_RECORD_GROUP_LIST);
      }, ES_REFETCH_DELAY);
    } else {
      setState({ isSubmitting: false });
    }
  } else {
    toaster(TOASTER_TYPE.ERROR, __('Please correct form errors.'));
  }
};

const handleCancel = ({ getState }) => {
  const { history } = getState();
  history.push(ROUTES.ENTITY_RECORD_GROUP_LIST);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.LOAD_CONDITION_FIELDS]: handleLoadConditionFields,
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleFormInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL]: handleCancel,
};

export default ACTION_HANDLERS;
