import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import cx from 'classnames';

import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';
import _size from 'lodash/size';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Heading from '@tekion/tekion-components/atoms/Heading';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Loader from '@tekion/tekion-components/molecules/loader';
import Button from '@tekion/tekion-components/atoms/Button';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';

import StagesDetailView from './components/approvalStagesDetailView';
import RequestDetails from './components/approvalRequestDetails';
import NavigationItem from '../../../../../molecules/NavigationFlow/components/navigationItem/NavigationItem';
import ApprovalStagesStatus from './components/approvalStagesStatus';
import ApprovalCommentsView from './components/approvalCommentsView/ApprovalCommentsView';
import ApprovalAddCommentModal from '../organisms/approvalAddCommentModal/ApprovalAddCommentModal';
import CancelApprovalRequestConfirmModal from '../organisms/cancelApprovalRequestConfirmModal/CancelApprovalRequestConfirmModal';
import StageApproval from './components/stageApproval';

import ACTION_HANDLERS from './helpers/approvalRequestDetailsPage.actionHandlers';
import { getApprovalRequestTopNavigationDefaultData } from './helpers/approvalRequestDetailsPage.general.helpers';

import ACTION_TYPES from './constants/approvalRequestDetailsPage.actionTypes';
import { APPROVAL_CENTRE_FIELD_IDS, APPROVAL_STATUS_TYPES } from '../../../../../constants/approvalCentre.constants';
import { TAB_IDS } from '../approvalManagement/constants/approvalManagement.constants';
import PAGE_IDS from '../../../constants/pageIds.constants';

import styles from './approvalRequestDetailsPage.module.scss';

const ApprovalRequestDetailsPage = ({
  isRejectLoading,
  isApproveLoading,
  isLoading,
  isRequestLoading,
  isCommentAdding,
  isShowMoreCommentsLoading,
  isAddCommentModalVisible,
  isConfirmModalVisible,
  isCancellingRequest,
  isMountedInsideApplication,
  hasMoreComments,
  contentHeight,
  approvalTabId,
  appRoute,
  currentLoggedInUserData,
  history,
  approvalComments,
  approvalRequest,
  approvalTask,
  tasksForApprovalRequest,
  processForApprovalRequest,
  settingForApprovalRequest,
  entityDef,
  applicationProperties,
  onAction,
}) => {
  const isEditCancelVisibleForRequest = useMemo(
    () => approvalTabId === TAB_IDS.REQUESTS && tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.STATUS) === APPROVAL_STATUS_TYPES.PENDING,
    [approvalTabId, approvalRequest],
  );

  const headerNavigationData = useMemo(
    () => [
      ...getApprovalRequestTopNavigationDefaultData(isMountedInsideApplication, appRoute, approvalTabId),
      { label: __(tget(approvalRequest, 'name', NO_DATA)), key: '2' },
    ],
    [isMountedInsideApplication, appRoute, approvalRequest, approvalTabId],
  );

  const handleEditApprovalRequestClick = useCallback(() => {
    let prefixPathName = '';

    if (isMountedInsideApplication) {
      prefixPathName = appRoute;
    }

    const approvalRequestId = tget(approvalRequest, 'id');

    const pathName = `${prefixPathName}/${PAGE_IDS.APPROVAL_CENTRE}/${PAGE_IDS.EDIT_REQUEST}/${approvalRequestId}`;

    history.push(pathName);
  }, [isMountedInsideApplication, history, appRoute, approvalRequest]);

  const handleCancelApprovalRequestButtonClick = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL_APPROVAL_REQUEST_BUTTON_CLICK });
  }, [onAction]);

  const handleCancelCancelClick = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL_CANCEL_APPROVAL_REQUEST_MODAL_CLICK });
  }, [onAction]);

  const handleCancelConfirmClick = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL_CONFIRM_APPROVAL_REQUEST_MODAL_CLICK });
  }, [onAction]);

  const handleNavigationItemClick = useCallback(
    (goTo) => {
      if (!_isEmpty(goTo)) {
        history.push(goTo);
      }
    },
    [history],
  );

  const headerNavigation = useMemo(
    () => (
      <>
        {_map(headerNavigationData, (data, index) => (
          <NavigationItem
            key={tget(data, 'key', EMPTY_STRING)}
            itemNumber={index}
            totalItems={_size(headerNavigationData)}
            data={data}
            onNavigationItemClick={handleNavigationItemClick}
          />
        ))}
      </>
    ),
    [handleNavigationItemClick, headerNavigationData],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction]);

  return (
    <Page style={{ height: contentHeight }}>
      <Page.Header>
        <Heading className={styles.headerNavigationContainer} size={2}>
          {headerNavigation}
        </Heading>
        <PropertyControlledComponent controllerProperty={isEditCancelVisibleForRequest}>
          <div className={styles.editCancelContainer}>
            <Button className="m-r-12" view={Button.VIEW.SECONDARY} onClick={handleCancelApprovalRequestButtonClick}>
              {__('Cancel Request')}
            </Button>
            <Button view={Button.VIEW.PRIMARY} onClick={handleEditApprovalRequestClick}>
              {__('Edit Request')}
            </Button>
          </div>
        </PropertyControlledComponent>
      </Page.Header>
      <PropertyControlledComponent controllerProperty={!isLoading && !isRequestLoading} fallback={<Loader id="REQUEST_PANEL" />}>
        <div className={styles.stageRequestCommentPanel}>
          <div className={styles.stageRequestPanel}>
            {approvalTabId === TAB_IDS.APPROVALS ? (
              <StageApproval isApproveLoading={isApproveLoading} isRejectLoading={isRejectLoading} approvalTask={approvalTask} onAction={onAction} />
            ) : (
              <ApprovalStagesStatus className={styles.panel} approvalProcess={processForApprovalRequest} approvalTasks={tasksForApprovalRequest} />
            )}
            <div
              className={cx({
                [styles.approvalRequestContainer]: approvalTabId === TAB_IDS.REQUESTS,
                [styles.approvalsContainer]: approvalTabId === TAB_IDS.APPROVALS,
              })}
            >
              <RequestDetails
                isMountedInsideApplication={isMountedInsideApplication}
                className={styles.panel}
                approvalTabId={approvalTabId}
                approvalRequest={approvalRequest}
                approvalSetting={settingForApprovalRequest}
                entityDef={entityDef}
                applicationProperties={applicationProperties}
              />
              <StagesDetailView className={styles.panel} approvalProcess={processForApprovalRequest} approvalTasks={tasksForApprovalRequest} />
            </div>
          </div>
          <div className={`${styles.panel} ${styles.leftBorder} ${styles.commentPanel} ${styles.widthCommentPanel} `}>
            <ApprovalCommentsView
              isCommentAdding={isCommentAdding}
              isShowMoreCommentsLoading={isShowMoreCommentsLoading}
              hasMoreComments={hasMoreComments}
              currentLoggedInUserData={currentLoggedInUserData}
              approvalComments={approvalComments}
              onAction={onAction}
            />
          </div>
        </div>
      </PropertyControlledComponent>
      <ApprovalAddCommentModal isAddCommentModalVisible={isAddCommentModalVisible} isRejectLoading={isRejectLoading} onAction={onAction} />
      <CancelApprovalRequestConfirmModal
        isVisible={isConfirmModalVisible}
        isCancellingRequest={isCancellingRequest}
        onCancelCancel={handleCancelCancelClick}
        onCancelConfirm={handleCancelConfirmClick}
      />
    </Page>
  );
};

ApprovalRequestDetailsPage.propTypes = {
  isRejectLoading: PropTypes.bool,
  isApproveLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCommentAdding: PropTypes.bool,
  isRequestLoading: PropTypes.bool,
  isShowMoreCommentsLoading: PropTypes.bool,
  isAddCommentModalVisible: PropTypes.bool,
  isConfirmModalVisible: PropTypes.bool,
  isCancellingRequest: PropTypes.bool,
  hasMoreComments: PropTypes.bool,
  isMountedInsideApplication: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  approvalTabId: PropTypes.string,
  appRoute: PropTypes.string,
  currentLoggedInUserData: PropTypes.object,
  history: PropTypes.object.isRequired,
  approvalComments: PropTypes.object,
  approvalRequest: PropTypes.object,
  approvalTask: PropTypes.object,
  processForApprovalRequest: PropTypes.object,
  settingForApprovalRequest: PropTypes.object,
  entityDef: PropTypes.object,
  applicationProperties: PropTypes.object,
  tasksForApprovalRequest: PropTypes.array,
  onAction: PropTypes.func,
};

ApprovalRequestDetailsPage.defaultProps = {
  isRejectLoading: false,
  isApproveLoading: false,
  isLoading: false,
  isCommentAdding: false,
  isRequestLoading: false,
  isShowMoreCommentsLoading: false,
  isAddCommentModalVisible: false,
  isConfirmModalVisible: false,
  isCancellingRequest: false,
  hasMoreComments: true,
  isMountedInsideApplication: false,
  approvalTabId: EMPTY_STRING,
  appRoute: EMPTY_STRING,
  currentLoggedInUserData: EMPTY_OBJECT,
  approvalComments: EMPTY_OBJECT,
  approvalRequest: EMPTY_OBJECT,
  approvalTask: EMPTY_OBJECT,
  processForApprovalRequest: EMPTY_OBJECT,
  settingForApprovalRequest: EMPTY_OBJECT,
  entityDef: EMPTY_OBJECT,
  applicationProperties: EMPTY_OBJECT,
  tasksForApprovalRequest: EMPTY_ARRAY,
  onAction: _noop,
};

export default compose(withRouter, withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ApprovalRequestDetailsPage);
