import _get from 'lodash/get';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _set from 'lodash/set';
import _reduce from 'lodash/reduce';
import _cloneDeep from 'lodash/cloneDeep';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/utils/constant';

import {
  checkDeletedModuleIsSelectedAndLastModule,
  filterFormValueForDeletedTab,
  getBeautifyFormatJsonString,
  getConfigFormattedCustomStyle,
  getFlattenedCustomStyleConfiguration,
  getFormFormattedCustomStyle,
  getFormattedCustomStyleConfiguration,
  getOmittedFieldIds,
} from './customStylesConfigurator.helper';
import { isRequiredScriptRule, isStringValidJson } from '../../../../../utils/formValidators';
import { pascalCase } from '../../../../../helpers/general.helpers';

import { FIELD_IDS, INITIAL_CUSTOM_STYLES_CONFIGURATION } from '../constants/customStylesConfigurator.constants';
import ACTION_TYPES from '../constants/customStylesConfigurator.actionTypes';
import { CUSTOM_STYLE_TYPES } from '../../../../../constants/customStyles.constants';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../../constants/viewBuilder.constants';

import customStylesReader from '../../../../../readers/customStyles.reader';

const handleSelectedViewComponentPropertiesChange = ({ getState, setState, params }) => {
  const { componentType = EMPTY_STRING } = getState();
  const { selectedViewComponentProperties } = params;

  const currentFieldTitle = customStylesReader.title(selectedViewComponentProperties) || pascalCase(componentType);
  let allFormValues = [];
  const customStyles = tget(selectedViewComponentProperties, ALL_VIEW_PROPERTY_KEYS.CUSTOM_STYLES, EMPTY_ARRAY);

  if (_isEmpty(customStyles)) {
    allFormValues = [INITIAL_CUSTOM_STYLES_CONFIGURATION];
  } else {
    allFormValues = _map(customStyles, (customStyle, customStyleType) => {
      const flattenedCustomStyleConfiguration = getFlattenedCustomStyleConfiguration(customStyle);
      const type = customStylesReader.type(customStyle);
      let value = customStylesReader.value(customStyle);

      if (type === CUSTOM_STYLE_TYPES.STYLES_OBJECT) {
        value = getFormFormattedCustomStyle(value);
      }

      return {
        ...flattenedCustomStyleConfiguration,
        [FIELD_IDS.CUSTOM_STYLE_TYPE]: customStyleType,
        [FIELD_IDS.VALUE]: value,
        [FIELD_IDS.SCRIPT]: value,
      };
    });
  }

  setState({ currentFieldTitle, allFormValues, selectedModuleIndex: 0 });
};

const handleConfigureCustomStyleClick = ({ getState, setState }) => {
  const { allFormValues, selectedModuleIndex } = getState();

  setState({
    prevState: {
      allFormValues,
      selectedModuleIndex,
    },
    isModalVisible: true,
  });
};

const handleAddCustomStyleClick = ({ getState, setState }) => {
  const { allFormValues = [] } = getState();

  const allCustomStyleSize = _size(allFormValues);

  setState({ allFormValues: [...allFormValues, INITIAL_CUSTOM_STYLES_CONFIGURATION], selectedModuleIndex: allCustomStyleSize });
};

const handleModuleIndexChange = ({ setState, params }) => {
  const { moduleIndex } = params;
  setState({ selectedModuleIndex: moduleIndex, errors: {} });
};

const handleOnChange = ({ getState, setState, params }) => {
  const { selectedModuleIndex, allFormValues = [] } = getState();
  const { id, value } = params;
  let fieldId = id;
  let fieldValue = value;
  let omittedFieldIds = [];
  const newAllFormValues = [...allFormValues];
  let selectedFormValues = allFormValues[selectedModuleIndex] || {};

  if (id === FIELD_IDS.CODE_PANEL) {
    fieldId = FIELD_IDS.TYPE;

    if (value) {
      fieldValue = CUSTOM_STYLE_TYPES.SCRIPT;
    } else {
      fieldValue = CUSTOM_STYLE_TYPES.STYLES_OBJECT;
    }

    omittedFieldIds = getOmittedFieldIds(fieldValue);
  } else if (fieldId === FIELD_IDS.SCRIPT) {
    selectedFormValues = { ...selectedFormValues, [FIELD_IDS.VALUE]: fieldValue };
  } else if (id === FIELD_IDS.BEAUTIFY_BUTTON) {
    let rteValue = customStylesReader.value(selectedFormValues);
    rteValue = getBeautifyFormatJsonString(rteValue);
    fieldId = FIELD_IDS.VALUE;
    fieldValue = rteValue;
  }

  selectedFormValues = _omit(selectedFormValues, omittedFieldIds);
  selectedFormValues = { ...selectedFormValues, [fieldId]: fieldValue };
  _set(newAllFormValues, [selectedModuleIndex], selectedFormValues);

  setState({ allFormValues: newAllFormValues });
};

const handleSubmit = ({ getState, setState }) => {
  const { allFormValues, onAction: configuratorOnAction } = getState();

  const newAllFormValues = _cloneDeep(allFormValues);

  const customStyles = _reduce(
    newAllFormValues,
    (prevCustomStyles, formValue) => {
      const customStyleType = customStylesReader.customStyleType(formValue);
      const type = customStylesReader.type(formValue);
      let value = customStylesReader.value(formValue);
      const updatedFormValue = _omit(formValue, [FIELD_IDS.CUSTOM_STYLE_TYPE]);

      if (type === CUSTOM_STYLE_TYPES.STYLES_OBJECT) {
        value = getConfigFormattedCustomStyle(value);
        _set(updatedFormValue, [FIELD_IDS.VALUE], value);
      }

      const formattedCustomStyleConfig = getFormattedCustomStyleConfiguration(updatedFormValue);

      return { ...prevCustomStyles, [customStyleType]: formattedCustomStyleConfig };
    },
    {},
  );

  configuratorOnAction({
    type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
    payload: { id: ALL_VIEW_PROPERTY_KEYS.CUSTOM_STYLES, value: customStyles },
  });

  setState({ isModalVisible: false });
};

const handleErrors = ({ getState, setState, params }) => {
  const { errors } = params;
  const { selectedModuleIndex, allFormValues } = getState();
  const selectedFormValues = allFormValues[selectedModuleIndex] || {};
  const type = customStylesReader.type(selectedFormValues);
  let updatedErrors = errors;

  const omittedFieldIds = getOmittedFieldIds(type);
  updatedErrors = _omit(updatedErrors, omittedFieldIds);

  setState({ errors: updatedErrors });
};

const handleValidationSuccess = ({ getState, setState, params }) => {
  const { selectedModuleIndex, allFormValues, errors = {} } = getState();
  const { additional } = params;
  const actionType = _get(additional, 'actionType');
  const payload = _get(additional, 'payload', {});
  const selectedFormValues = allFormValues[selectedModuleIndex] || {};

  const type = customStylesReader.type(selectedFormValues);
  const value = customStylesReader.value(selectedFormValues);

  if (type === CUSTOM_STYLE_TYPES.SCRIPT) {
    const { isValid, message } = isRequiredScriptRule(value);
    if (!isValid) {
      setState({ errors: { ...errors, [FIELD_IDS.ERROR_MESSAGE]: message } });
      return;
    }
  } else if (type === CUSTOM_STYLE_TYPES.STYLES_OBJECT) {
    const { isValid, message } = isStringValidJson(value);
    if (!isValid) {
      setState({ errors: { ...errors, [FIELD_IDS.ERROR_MESSAGE]: message } });
      return;
    }
  }

  switch (actionType) {
    case ACTION_TYPES.ON_CLICK_ADD_CUSTOM_STYLE:
      handleAddCustomStyleClick({ getState, setState });
      break;

    case ACTION_TYPES.ON_SAVE_MODAL:
      handleSubmit({ getState, setState });
      break;

    case ACTION_TYPES.ON_CHANGE_MODULE:
      handleModuleIndexChange({ getState, setState, params: payload });
      break;

    default:
      break;
  }
};

const handleDeleteRow = ({ getState, setState, params }) => {
  const { allFormValues, selectedModuleIndex } = getState();
  const { moduleIndex } = params;
  let newSelectedModuleIndex = selectedModuleIndex;

  const newAllFormValues = filterFormValueForDeletedTab(allFormValues, moduleIndex);

  if (checkDeletedModuleIsSelectedAndLastModule(moduleIndex, selectedModuleIndex, allFormValues)) {
    newSelectedModuleIndex = moduleIndex - 1;
  } else if (moduleIndex < selectedModuleIndex) {
    newSelectedModuleIndex -= 1;
  }

  setState({ allFormValues: newAllFormValues }, () => {
    handleModuleIndexChange({ getState, setState, params: { moduleIndex: newSelectedModuleIndex } });
  });
};

const handleCancelModal = ({ getState, setState }) => {
  const { prevState = EMPTY_OBJECT } = getState();

  setState({ isModalVisible: false, ...prevState });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE]: handleSelectedViewComponentPropertiesChange,
  [ACTION_TYPES.ON_CLICK_CONFIGURE_CUSTOM_STYLE]: handleConfigureCustomStyleClick,
  [ACTION_TYPES.ON_CLICK_ADD_CUSTOM_STYLE]: handleAddCustomStyleClick,
  [ACTION_TYPES.ON_CHANGE_MODULE]: handleModuleIndexChange,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleValidationSuccess,
  [ACTION_TYPES.ON_SAVE_MODAL]: handleSubmit,
  [ACTION_TYPES.DELETE_ROW]: handleDeleteRow,
  [ACTION_TYPES.ON_CANCEL_MODAL]: handleCancelModal,
};

export default ACTION_HANDLERS;
