import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _includes from 'lodash/includes';
import _isObject from 'lodash/isObject';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';

import { EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import {
  ROWS,
  FIELD_IDS,
  EVENT_HANDLER_PROPERTIES_FIELD_IDS,
  EVENT_HANDLER_FIELD_IDS,
  FIELD_IDS_TO_PICK_WHEN_CODE_PANEL_IS_ENABLED,
  COMMON_FIELD_IDS_TO_PICK_WHEN_CODE_PANEL_IS_DISABLED,
  MANDATORY_FIELD_IDS,
} from './eventHandler.constants';
import { EVENT_ACTIONS, EVENT_ACTIONS_TYPES } from '../../constants/eventActions.constants';
import { ACTION_DEFINITION_FIELD_IDS } from '../../constants/actionBuilder.constants';

const getValueOption = (pageConfig) => ({
  label: tget(pageConfig, 'displayName', EMPTY_STRING) || tget(pageConfig, 'name', EMPTY_STRING),
  value: tget(pageConfig, 'name', EMPTY_STRING),
});

const getOptions = (data) => _map(data, getValueOption);

const getPayload = ({ nextPageToken, searchText }) => ({
  rows: ROWS,
  nextPageToken,
  searchText,
});

const getValueOptionForAction = (actionData) => ({
  label: tget(actionData, ACTION_DEFINITION_FIELD_IDS.ACTION_DISPLAY_NAME, NO_DATA),
  value: tget(actionData, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME),
  entityName: tget(actionData, ACTION_DEFINITION_FIELD_IDS.ENTITY_NAME),
});

const getActionNameOptions = (data) => _map(data, getValueOptionForAction);

const getFlattenedEventHandlerConfiguration = (eventHandlerConfiguration) =>
  _reduce(
    eventHandlerConfiguration,
    (prevConfiguration, currentConfiguration, configurationKey) => {
      if (_isObject(currentConfiguration)) {
        const flattenedConfiguration = getFlattenedEventHandlerConfiguration(currentConfiguration);
        return { ...prevConfiguration, ...flattenedConfiguration };
      }

      return { ...prevConfiguration, [configurationKey]: currentConfiguration };
    },
    {},
  );

const getFormattedEventHandlerConfiguration = (eventHandlerConfiguration) =>
  _reduce(
    eventHandlerConfiguration,
    (prevConfiguration, currentConfiguration, configurationKey) => {
      if (_includes(EVENT_HANDLER_FIELD_IDS, configurationKey)) {
        _set(prevConfiguration, [configurationKey], currentConfiguration);
      } else if (_includes(EVENT_HANDLER_PROPERTIES_FIELD_IDS, configurationKey)) {
        _set(prevConfiguration, [FIELD_IDS.PROPERTIES, configurationKey], currentConfiguration);
      } else {
        _set(prevConfiguration, [FIELD_IDS.PROPERTIES, FIELD_IDS.METADATA, configurationKey], currentConfiguration);
      }
      return prevConfiguration;
    },
    {},
  );

const noOptionsMessage = () => __('No available events for this selection.');

const fieldIdValidationPreCheck = (fieldId, formEventData) => {
  switch (fieldId) {
    case FIELD_IDS.SCRIPT: {
      const subEventType = tget(formEventData, FIELD_IDS.SUB_EVENT_TYPE);
      return tget(formEventData, FIELD_IDS.CODE_PANEL, subEventType === EVENT_ACTIONS_TYPES.SCRIPT);
    }

    case FIELD_IDS.ACTION_TO_EXECUTE: {
      const subEventType = tget(formEventData, FIELD_IDS.SUB_EVENT_TYPE);
      return !tget(formEventData, FIELD_IDS.CODE_PANEL, subEventType === EVENT_ACTIONS_TYPES.SCRIPT);
    }

    case FIELD_IDS.PAGE_NAME_TO_NAVIGATE: {
      const actionToExecuteValue = tget(formEventData, FIELD_IDS.ACTION_TO_EXECUTE);
      const isNavigatePreviousPageEnabled = tget(formEventData, FIELD_IDS.NAVIGATE_TO_PREV_PAGE);
      return actionToExecuteValue === EVENT_ACTIONS.NAVIGATE && !isNavigatePreviousPageEnabled;
    }

    case FIELD_IDS.ACTION_NAME_TO_EXECUTE: {
      const actionToExecuteValue = tget(formEventData, FIELD_IDS.ACTION_TO_EXECUTE);
      return actionToExecuteValue === EVENT_ACTIONS.TRIGGER_ACTION_BUILDER_ACTION;
    }

    case FIELD_IDS.GENERATOR_TYPE: {
      const subEventType = tget(formEventData, FIELD_IDS.SUB_EVENT_TYPE);
      return tget(formEventData, FIELD_IDS.CODE_PANEL, subEventType === EVENT_ACTIONS_TYPES.SCRIPT);
    }

    default: {
      return true;
    }
  }
};

const eventHandlerValidator = (fieldId, valueToTest) => {
  const eventFormData = getFlattenedEventHandlerConfiguration(valueToTest);

  let isValid = true;
  const fieldMandatoryErrorMessage = __('This field is mandatory');

  const errorObject = {};

  _forEach(MANDATORY_FIELD_IDS, (mandatoryFieldId) => {
    const value = tget(eventFormData, mandatoryFieldId);
    const preValidationCheckValue = fieldIdValidationPreCheck(mandatoryFieldId, eventFormData);

    if (_isEmpty(value) && preValidationCheckValue) {
      isValid = false;
      _set(errorObject, mandatoryFieldId, fieldMandatoryErrorMessage);
    }
  });

  return isValid ? { isValid } : { isValid, message: errorObject };
};

const getFieldIdsToPickWhenCodePanelIsDisabled = (formEventValue) => {
  const actionToExecute = tget(formEventValue, FIELD_IDS.ACTION_TO_EXECUTE);

  switch (actionToExecute) {
    case EVENT_ACTIONS.NAVIGATE: {
      const isNavigateToPrevPage = tget(formEventValue, FIELD_IDS.NAVIGATE_TO_PREV_PAGE);

      if (isNavigateToPrevPage) {
        return [...COMMON_FIELD_IDS_TO_PICK_WHEN_CODE_PANEL_IS_DISABLED, FIELD_IDS.NAVIGATE_TO_PREV_PAGE];
      }

      return [...COMMON_FIELD_IDS_TO_PICK_WHEN_CODE_PANEL_IS_DISABLED, FIELD_IDS.PAGE_NAME_TO_NAVIGATE];
    }

    case EVENT_ACTIONS.TRIGGER_ACTION_BUILDER_ACTION: {
      return [...COMMON_FIELD_IDS_TO_PICK_WHEN_CODE_PANEL_IS_DISABLED, FIELD_IDS.ACTION_NAME_TO_EXECUTE, FIELD_IDS.ENTITY_NAME];
    }

    default: {
      return COMMON_FIELD_IDS_TO_PICK_WHEN_CODE_PANEL_IS_DISABLED;
    }
  }
};

const getEventConfigDataFromOnChangeValue = (formEventValue, payload) => {
  const { id: fieldId, value: fieldValue, selectedOption } = payload;

  let newValues = _cloneDeep(formEventValue);
  _set(newValues, fieldId, fieldValue);

  if (fieldId === FIELD_IDS.ACTION_NAME_TO_EXECUTE) {
    _set(newValues, [FIELD_IDS.ENTITY_NAME], tget(selectedOption, ACTION_DEFINITION_FIELD_IDS.ENTITY_NAME));
  }

  const subEventTypeValue = tget(newValues, FIELD_IDS.SUB_EVENT_TYPE);
  const codePanelValue =
    fieldId === FIELD_IDS.CODE_PANEL ? fieldValue : tget(newValues, FIELD_IDS.CODE_PANEL, subEventTypeValue === EVENT_ACTIONS_TYPES.SCRIPT);

  if (codePanelValue) {
    // If code panel is enable then we will pick field values which are relevant to script
    newValues = _pick(newValues, FIELD_IDS_TO_PICK_WHEN_CODE_PANEL_IS_ENABLED);

    _set(newValues, FIELD_IDS.SUB_EVENT_TYPE, EVENT_ACTIONS_TYPES.SCRIPT);
  } else {
    // If code panel is disable then we will pick field values which are relevant
    newValues = _pick(newValues, getFieldIdsToPickWhenCodePanelIsDisabled(newValues));

    _set(newValues, FIELD_IDS.SUB_EVENT_TYPE, EVENT_ACTIONS_TYPES.PRE_DEFINED);
  }

  const eventViewConfigData = getFormattedEventHandlerConfiguration(newValues);

  return eventViewConfigData;
};

export {
  getOptions,
  getPayload,
  getActionNameOptions,
  getFlattenedEventHandlerConfiguration,
  noOptionsMessage,
  eventHandlerValidator,
  getEventConfigDataFromOnChangeValue,
};
