import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _findIndex from 'lodash/findIndex';

import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { STATUS } from '@tekion/tekion-base/constants/statuses';
import { tget } from '@tekion/tekion-base/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { editUserData, saveUserData, getUserById } from '../../../../../../actions/tenantUserManagement.actions';
import { fetchWorkspaces } from '../../../../../../actions/workSpace.actions';

import { mapApiDataToForm, mapUserDataToApiFormat } from './tenantUserForm.helpers';
import { COLUMN_IDS } from '../components/configTable/constants/configTable.constants';
import { TENANT_UNIVERSE_BASE_ROUTE } from '../../../../../../constants/routes';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/tenantUserForm.actionTypes';
import workspaceReader from '../../../../../../readers/workspace.reader';
import tenantUserReader from '../../../../../../readers/tenantUser.reader';

const fetchAllWorkspace = async ({ setState }) => {
  const workspacesData = await fetchWorkspaces();
  const workspaceList = _map(workspacesData, (workspace) => ({
    name: workspaceReader.displayName(workspace) || workspaceReader.name(workspace),
    id: workspaceReader.id(workspace),
  }));
  setState({ workspaceList });
};

const handleInit = async ({ getState, setState }) => {
  const { match, location } = getState();
  const userId = _get(match, 'params.userId', EMPTY_STRING);

  setState({ isDataLoading: true });
  await fetchAllWorkspace({ setState });

  if (_isEmpty(userId)) {
    setState({ formMode: FORM_MODES.CREATE, isDataLoading: false, homeWorkspaceIndex: 0, userWorkspaceList: [] });
  } else {
    setState({ formMode: FORM_MODES.EDIT });
    let userData = _get(location, 'state.userInfo');

    if (_isEmpty(userData)) {
      userData = await getUserById(userId);
    }

    let userWorkspaceList = tenantUserReader.workspaceSpecificInfos(userData);
    userWorkspaceList = _map(userWorkspaceList, (workspace) => ({
      [COLUMN_IDS.WORKSPACE]: tenantUserReader.workspaceId(workspace),
      [COLUMN_IDS.ROLE]: tenantUserReader.roleName(workspace),
      [COLUMN_IDS.PROFILE_NAME]: tenantUserReader.profileName(workspace),
    }));
    const homeWorkspaceId = tenantUserReader.homeWorkspaceId(userData);
    const homeWorkspaceIndex = _findIndex(userWorkspaceList, (workspace) => tenantUserReader.workspaceId(workspace) === homeWorkspaceId);
    const selectedWorkspaces = _map(userWorkspaceList, (workspace) => tenantUserReader.workspaceId(workspace));

    const modifiedFormData = mapApiDataToForm(userData);
    setState({
      values: _get(modifiedFormData, 'userFormValues'),
      isDataLoading: false,
      userWorkspaceList,
      homeWorkspaceIndex,
      selectedWorkspaces,
    });
  }
};

const handleFieldChange = ({ setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  setState((state) => ({
    values: {
      ...state?.values,
      [id]: value,
    },
  }));
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;
  setState({ errors });
};

const handleRedirection = ({ getState, setState }) => {
  const { history } = getState();
  setState({ isConfirmModalVisible: false });
  const path = `${TENANT_UNIVERSE_BASE_ROUTE}/${PAGE_IDS.USERS}`;
  history.push(path);
};

const handleSubmit = async ({ getState, setState }) => {
  const { values, userWorkspaceList, homeWorkspaceIndex, formMode } = getState();
  const userObject = mapUserDataToApiFormat(values, userWorkspaceList, homeWorkspaceIndex);
  setState({ isSaveLoading: true });

  let status = EMPTY_STRING;
  let userDetails = {};
  if (formMode === FORM_MODES.CREATE) {
    const response = await saveUserData(userObject);
    status = tget(response, 'status', STATUS.FAILED);
    userDetails = tget(response, 'data', {});
  } else {
    const { match } = getState();
    const userId = _get(match, 'params.userId');
    status = await editUserData(userId, userObject);
  }

  if (status !== STATUS.FAILED) {
    setTimeout(() => {
      if (formMode === FORM_MODES.EDIT) {
        handleRedirection({ setState, getState });
      } else {
        setState({ isConfirmModalVisible: true, isSaveLoading: false, userDetails });
      }
    }, ES_REFETCH_DELAY);
  } else {
    setState({ isSaveLoading: false });
  }
};

const handleUserWorkspaceAdd = ({ setState, getState }) => {
  const { userWorkspaceList = EMPTY_ARRAY } = getState();
  setState({
    userWorkspaceList: [...userWorkspaceList, { [COLUMN_IDS.WORKSPACE]: null, [COLUMN_IDS.ROLE]: null, [COLUMN_IDS.PROFILE_NAME]: null }],
  });
};

const handleUserWorkspaceDelete = ({ setState, getState, params }) => {
  const { userWorkspaceList, homeWorkspaceIndex, selectedWorkspaces } = getState();
  const { index } = params;

  if (index < homeWorkspaceIndex) {
    setState({ homeWorkspaceIndex: homeWorkspaceIndex - 1 });
  } else if (index === homeWorkspaceIndex) {
    setState({ homeWorkspaceIndex: 0 });
  }

  const updatedUserWorkspaces = [...userWorkspaceList];
  updatedUserWorkspaces.splice(index, 1);

  const updatedSelectedWorkspaces = [...selectedWorkspaces];
  updatedSelectedWorkspaces.splice(index, 1);
  setState({ userWorkspaceList: updatedUserWorkspaces, selectedWorkspaces: updatedSelectedWorkspaces });
};

const handleUserWorkspaceUpdate = async ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { userWorkspaceList, selectedWorkspaces = EMPTY_ARRAY, homeWorkspaceIndex } = getState();
  const { index, key, value } = params;
  const updatedUserWorkspaces = [...userWorkspaceList];

  if (key === COLUMN_IDS.HOME_WORKSPACE_CHECKBOX) {
    if (homeWorkspaceIndex >= 0) {
      _set(updatedUserWorkspaces, [homeWorkspaceIndex, key], false);
    }
    setState({ homeWorkspaceIndex: index });
  }

  _set(updatedUserWorkspaces, [index, key], value);
  setState({ userWorkspaceList: updatedUserWorkspaces });

  if (key === COLUMN_IDS.WORKSPACE) {
    const updatedSelectedWorkspaces = [...selectedWorkspaces];
    updatedSelectedWorkspaces[index] = value;

    setState({ selectedWorkspaces: updatedSelectedWorkspaces });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INITIALIZE_USER_FORM_VALUES]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
  [ACTION_TYPES.ON_USER_WORKSPACE_ADD]: handleUserWorkspaceAdd,
  [ACTION_TYPES.ON_USER_WORKSPACE_DELETE]: handleUserWorkspaceDelete,
  [ACTION_TYPES.ON_USER_WORKSPACE_UPDATE]: handleUserWorkspaceUpdate,
};

export default ACTION_HANDLERS;
