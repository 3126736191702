import Heading from '@tekion/tekion-components/atoms/Heading';
import Button from '@tekion/tekion-components/atoms/Button';

import ACTION_TYPES from '../constants/entitySettings.actionTypes';

const getSubHeaderProps = (count) => ({
  subHeaderLeftActions: [
    {
      renderer: Heading,
      renderOptions: {
        children: __(`Entities (${count})`),
        size: 2,
      },
    },
  ],
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Add Entity'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.ADD_ENTITY_PERMISSION,
    },
  ],
});

export default getSubHeaderProps;
