import { useMemo, useState } from 'react';
import _times from 'lodash/times';
import _max from 'lodash/max';
import { getTableColumnCount } from '@udecode/plate-table';

// constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

export const COLUMN_MIN_WIDTH = 64;

export const DEFAULT_COLUMN_WIDTH = 200;

const getColumnWidth = (tableNode, resizingColumn) => (index) => {
  const cellNode = tableNode?.children?.[0]?.children?.[index];
  const { columnId } = cellNode || EMPTY_OBJECT;
  if (resizingColumn && resizingColumn.id === columnId) {
    return {
      id: columnId,
      width: _max([resizingColumn.width, COLUMN_MIN_WIDTH]),
    };
  }
  return {
    id: columnId,
    width: tableNode?.columnSizesById?.[columnId] || DEFAULT_COLUMN_WIDTH,
  };
};

const getTableColumnWidths = (tableNode, resizingColumn) => {
  const columnCount = getTableColumnCount(tableNode);
  const columnWidthsWithId = _times(columnCount, getColumnWidth(tableNode, resizingColumn));
  return columnWidthsWithId;
};

function useColumnWidths(element) {
  const [resizingColumn, setResizingColumn] = useState();
  const columnWidthsWithId = useMemo(() => getTableColumnWidths(element, resizingColumn), [resizingColumn, element]);
  return {
    columnWidthsWithId,
    setResizingColumn,
  };
}

export default useColumnWidths;
