import React from 'react';
import PropTypes from 'prop-types';

import Popover, { POPOVER_TRIGGER, POPOVER_PLACEMENT } from '@tekion/tekion-components/molecules/popover';
import { DATE_TIME_FORMATS, getDateDifferenceHumanised, getFormattedDateTime } from '@tekion/tekion-base/utils/dateUtils';

import styles from './notificationTimeStamp.module.scss';

const NotificationTimeStamp = ({ notificationTimeInEpoch }) => {
  const timeInDuration = getDateDifferenceHumanised(notificationTimeInEpoch);
  const formattedTime = getFormattedDateTime(notificationTimeInEpoch, DATE_TIME_FORMATS.DAY_MONTH_DATE_YEAR_TIME);

  const renderFormattedTime = () => <div className={styles.formattedTime}>{formattedTime}</div>;

  return (
    <Popover placement={POPOVER_PLACEMENT.TOP_LEFT} trigger={POPOVER_TRIGGER.HOVER} content={renderFormattedTime()}>
      <div className={styles.durationTime}>{timeInDuration}</div>
    </Popover>
  );
};

NotificationTimeStamp.propTypes = {
  notificationTimeInEpoch: PropTypes.string.isRequired,
};

export default NotificationTimeStamp;
