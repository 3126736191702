/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

// constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// components
import LinkSelectionPopup from './molecules/linkSelectionPopup';

// hooks
import useLinkSelectionPopupVisibility from './hooks/useLinkSelectionPopupVisibility';
import useLinkOnClick from './hooks/useLinkOnClick';
import useLinkSelectionPopupEvent from './hooks/useLinkSelectionPopupEvent';
import useEditor from '../../hooks/useEditor';

// helpers
import { getSelectedLinkNode } from '../../helpers/richTextEditor.link';
import TemplateVariableSelectionPopup from '../templateVariable/molecules/templateVariableSelectionPopup';

function Link(props) {
  const { children, attributes, element, nodeProps } = props;
  const { disableEditing } = nodeProps;
  const { url = '', shouldOpenInNewTab = true } = element;

  const editor = useEditor();

  const selectedLinkNode = getSelectedLinkNode(editor);

  const { showPopover, setShowPopover } = useLinkSelectionPopupVisibility(selectedLinkNode);

  useLinkSelectionPopupEvent({ setShowPopover, linkNodeElement: element, editor, selectedLinkNode });

  const handleClick = useLinkOnClick(setShowPopover, disableEditing);

  if (_isEmpty(url)) {
    return (
      <>
        <a {...attributes} onClick={handleClick}>
          <strong>{children}</strong>
        </a>
        {!disableEditing && <TemplateVariableSelectionPopup visible={showPopover} onVisibleChange={setShowPopover} linkNode={element} />}
      </>
    );
  } else {
    return (
      <>
        <a {...attributes} target={shouldOpenInNewTab ? '_blank' : '_self'} href={url} onClick={handleClick}>
          {children}
        </a>
        {!disableEditing && <LinkSelectionPopup visible={showPopover} onVisibleChange={setShowPopover} linkNode={element} />}
      </>
    );
  }
}

Link.propTypes = {
  children: PropTypes.node,
  attributes: PropTypes.object,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
    children: PropTypes.array.isRequired,
    shouldOpenInNewTab: PropTypes.bool,
  }).isRequired,
  nodeProps: PropTypes.shape({
    disableEditing: PropTypes.bool,
  }),
};

Link.defaultProps = {
  children: null,
  attributes: EMPTY_OBJECT,
  nodeProps: EMPTY_OBJECT,
};

export default React.memo(Link);
