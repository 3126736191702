import MEDIA_PROPERTIES_FORM_FIELD_IDS from './mediaPropertiesForm.fieldIds';

import styles from '../../../fieldsForm.module.scss';

const MEDIA_PROPERTIES_FORM_SECTIONS = [
  {
    header: {
      label: __('Media Properties'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [MEDIA_PROPERTIES_FORM_FIELD_IDS.PREVIEW_ENABLED, MEDIA_PROPERTIES_FORM_FIELD_IDS.FILE_ACCESS_AUDIT_ENABLED],
      },
    ],
  },
];

export default MEDIA_PROPERTIES_FORM_SECTIONS;
