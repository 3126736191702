const WORKSPACE_USER_MANAGEMENT_COLUMN_IDS = {
  NAME: 'NAME',
  FIRST_NAME: 'firstname',
  LAST_NAME: 'lastname',
  PHONE: 'phone',
  COUNTRY_CODE: 'countrycode',
  EMAIL: 'email',
  USER_TYPE: 'usertype',
  WORK_SPACE_IDS: 'workspaceIds',
  ROLE: 'roleid',
  DISPLAY_NAME: 'displayname',
  ACTIVE: 'active',
  LOCK_USER: 'lockuser',
  PROFILE_PICTURE: 'profilepicture',
};

export default WORKSPACE_USER_MANAGEMENT_COLUMN_IDS;
