const FILTER_FIELDS = {
  ID: 'id',
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  ENTITY_NAME: 'entityName',
  VIEW_NAME: 'viewName',
  DELETED: 'deleted',
  VIEW_TYPE: 'viewType',
};

export { FILTER_FIELDS };
