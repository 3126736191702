import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';

const FIELD_IDS = {
  KEY_SPAN: ALL_VIEW_PROPERTY_KEYS.KEY_SPAN,
  VALUE_SPAN: ALL_VIEW_PROPERTY_KEYS.VALUE_SPAN,
  IS_KEY_VISIBLE: ALL_VIEW_PROPERTY_KEYS.IS_KEY_VISIBLE,
  IS_AVATAR_VIEW: ALL_VIEW_PROPERTY_KEYS.IS_AVATAR_VIEW,
  IS_RTE_TRUNCATED_TYPE: ALL_VIEW_PROPERTY_KEYS.IS_RTE_TRUNCATED_TYPE,
  DATE_TIME_FORMAT: ALL_VIEW_PROPERTY_KEYS.DATE_TIME_FORMAT,
  EVENT_HANDLERS: ALL_VIEW_PROPERTY_KEYS.EVENT_HANDLERS,
  CUSTOM_STYLES: ALL_VIEW_PROPERTY_KEYS.CUSTOM_STYLES,
};

export { FIELD_IDS };
