import EVENT_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

const ACTION_TYPES = {
  FETCH_WIDGET_BY_NAME: 'FETCH_WIDGET_BY_NAME',
  ON_COLUMN_CONFIG_CHANGE: 'ON_COLUMN_CONFIG_CHANGE',
  ON_COLUMN_SELECT: 'ON_COLUMN_SELECT',
  ON_DELETE: 'ON_DELETE',
  ON_CLICK_ADD_NEW: 'ON_CLICK_ADD_NEW',
  SAVE_OR_UPDATE_WIDGET: EVENT_ACTION_TYPES.ON_FORM_SUBMIT,
  CLOSE_MODAL: 'CLOSE_MODAL',
  ON_FILTER_TABLE_CHANGE: 'ON_FILTER_TABLE_CHANGE',
};

const ADD_WIDGET_FORM_FIELDS = {
  WIDGET_TYPE: 'widgetType',
  WIDGET_NAME: 'name',
  DISPLAY_NAME: 'displayName',
  ENTITY_NAME: 'entityName',
  COLUMN_CONFIG_DIMENSIONS: 'dimensions',
  COLUMN_CONFIG_MEASUREMENTS: 'measurements',
  X_AXIS: 'xAxis',
  Y_AXIS: 'yAxis',
};

const METRIC_TYPES = {
  MEASUREMENTS: 'metric',
  DIMENSION: 'dimension',
};

const COLUMN_IDS = {
  COLUMN_DISPLAY_NAME: 'displayName',
  SELECT_FIELD: 'field',
  FILTERABLE: 'filter',
};

export { ACTION_TYPES, ADD_WIDGET_FORM_FIELDS, METRIC_TYPES, COLUMN_IDS };
