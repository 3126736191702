import _set from 'lodash/set';

const updateTabContentDetails = (index, key, payload, tabContentDetails) => {
  const { value } = payload;
  const newTabValue = { ...tabContentDetails[index], [key]: value };
  _set(tabContentDetails, index, newTabValue);
  return tabContentDetails;
};

export { updateTabContentDetails };
