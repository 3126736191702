const INIT = 'INIT';
const UPDATE_GRID_LAYOUT = 'UPDATE_GRID_LAYOUT';
const CHANGE_DASHBOARD = 'CHANGE_DASHBOARD';
const GO_BACK = 'GO_BACK';

export default {
  INIT,
  UPDATE_GRID_LAYOUT,
  CHANGE_DASHBOARD,
  GO_BACK,
};
