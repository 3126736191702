import _map from 'lodash/map';
import _floor from 'lodash/floor';

import { TOTAL_GRID_COLUMNS } from './gridLayout.constants';

const getAspectRatio = () => window.screen.width / window.screen.height;

const getRowHeight = (width) => {
  const aspectRatio = getAspectRatio();

  const rowHeight = _floor(width / TOTAL_GRID_COLUMNS / aspectRatio);

  return rowHeight;
};

const getGridPadding = (paddingX, paddingY) => [paddingX, paddingY];

const addStaticIfNotPresent = (layoutConfig) => ({
  static: true,
  ...layoutConfig,
});

const getLayoutWithDefaultStaticConfig = (layouts) => _map(layouts, addStaticIfNotPresent);

const getLayouts = (layouts) => _map(layouts, (item) => ({ ...item, minW: 5, minH: 1 }));

export { getRowHeight, getGridPadding, getLayoutWithDefaultStaticConfig, getLayouts };
