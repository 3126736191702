import { COMPONENT_TYPES, VIEW_TYPES } from '../../constants/viewBuilder.constants';

const IMAGE_RADIO_BUTTON_VALUES = {
  IMAGE: 'image',
  ICON: 'icon',
};

const CONFIGURATOR_COMPONENT_PANELS = {
  [VIEW_TYPES.DETAIL_VIEW]: [
    {
      panelId: 'detailViewLayouts',
      panelName: __('Detail view components'),
      components: [
        {
          type: COMPONENT_TYPES.SECTION,
          sectionId: COMPONENT_TYPES.SECTION,
          viewType: VIEW_TYPES.DETAIL_VIEW,
          properties: {
            viewType: VIEW_TYPES.DETAIL_VIEW,
          },
        },
        {
          type: COMPONENT_TYPES.COMMENT,
          sectionId: COMPONENT_TYPES.COMMENT,
          viewType: VIEW_TYPES.DETAIL_VIEW,
          properties: {
            viewType: VIEW_TYPES.DETAIL_VIEW,
          },
        },
        {
          type: COMPONENT_TYPES.BUTTON,
          sectionId: COMPONENT_TYPES.BUTTON,
          viewType: VIEW_TYPES.DETAIL_VIEW,
          properties: {
            viewType: VIEW_TYPES.DETAIL_VIEW,
          },
        },
      ],
    },
  ],
  [VIEW_TYPES.FORM_VIEW]: [
    {
      panelId: 'formViewLayouts',
      panelName: 'Form view components',
      components: [
        {
          type: COMPONENT_TYPES.FORM_VIEW_SECTION,
          sectionId: COMPONENT_TYPES.FORM_VIEW_SECTION,
          viewType: VIEW_TYPES.FORM_VIEW,
          properties: {
            viewType: VIEW_TYPES.FORM_VIEW,
          },
        },
        {
          type: COMPONENT_TYPES.FORM_VIEW_ROW,
          sectionId: COMPONENT_TYPES.FORM_VIEW_ROW,
          viewType: VIEW_TYPES.FORM_VIEW,
          properties: {
            viewType: VIEW_TYPES.FORM_VIEW,
          },
        },
      ],
    },
  ],
  [VIEW_TYPES.CELL_VIEW]: [
    {
      panelId: 'cellViewLayouts',
      panelName: 'Cell view components',
      components: [
        {
          type: COMPONENT_TYPES.ROW,
          sectionId: COMPONENT_TYPES.ROW,
          viewType: VIEW_TYPES.CELL_VIEW,
          properties: {
            viewType: VIEW_TYPES.CELL_VIEW,
          },
        },
        {
          type: COMPONENT_TYPES.IMAGE,
          sectionId: COMPONENT_TYPES.IMAGE,
          viewType: VIEW_TYPES.CELL_VIEW,
          properties: {
            viewType: VIEW_TYPES.CELL_VIEW,
          },
        },
        {
          type: COMPONENT_TYPES.BUTTON,
          sectionId: COMPONENT_TYPES.BUTTON,
          viewType: VIEW_TYPES.CELL_VIEW,
          properties: {
            viewType: VIEW_TYPES.CELL_VIEW,
          },
        },
      ],
    },
  ],
};

const CONFIGURATOR_PANEL_IDS = {
  PROPERTIES: 'properties',
  COMPONENTS: 'components',
  ENTITY: 'entity',
};

const CONFIGURATOR_PANEL_DISPLAY_NAMES = {
  [CONFIGURATOR_PANEL_IDS.PROPERTIES]: 'Properties',
  [CONFIGURATOR_PANEL_IDS.COMPONENTS]: 'Components',
  [CONFIGURATOR_PANEL_IDS.ENTITY]: 'Entity',
};

export { CONFIGURATOR_PANEL_IDS, CONFIGURATOR_PANEL_DISPLAY_NAMES, CONFIGURATOR_COMPONENT_PANELS, IMAGE_RADIO_BUTTON_VALUES };
