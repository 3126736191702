import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import Spinner, { SIZES } from '@tekion/tekion-components/molecules/SpinnerComponent';

import PermissionsError from '../../atoms/permissionsError';
import { PermissionsContext } from '../../context/permissions.context';
import { getProfileByName } from '../../actions/profile.actions';
import { getUserProfileNameForCurrentWorkspace } from './helpers/permissionsProvider.helpers';
import { PERMISSIONS_CONTEXT_KEYS } from '../../constants/permissionsProvider.constants';
import profileReader from '../../readers/profile.reader';

import styles from './permissionsProvider.module.scss';

const PermissionsProvider = ({ children }) => {
  const [controlFlags, setControlFlags] = useState(EMPTY_ARRAY);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const fetchProfileInfo = async () => {
    const profileName = getUserProfileNameForCurrentWorkspace();
    const profileInfo = await getProfileByName(profileName);

    if (!_isEmpty(profileInfo)) {
      const _controlFlags = profileReader.controlFlags(profileInfo);
      setControlFlags(_controlFlags);
      setIsSuccess(true);
    }

    setIsLoaded(true);
  };

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const contextValues = useMemo(() => ({ [PERMISSIONS_CONTEXT_KEYS.CONTROL_FLAGS]: controlFlags }), [controlFlags]);

  if (!isLoaded) {
    return (
      <div className={styles.spinner}>
        <Spinner size={SIZES.MEDIUM} />
      </div>
    );
  }

  if (isLoaded && !isSuccess) {
    return <PermissionsError />;
  }

  if (isSuccess) {
    return <PermissionsContext.Provider value={contextValues}>{children}</PermissionsContext.Provider>;
  }

  return null;
};

PermissionsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default PermissionsProvider;
