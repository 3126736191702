import { withProps } from 'recompose';

import { isRequiredRule, hasNumberRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';

import FormFieldsCheckbox from '../formFieldsCheckbox';
import PrefilledValueField from '../prefilledValueField';
import EventHandlerConfigurator from '../eventHandlerConfigurator';
import CustomStylesConfigurator from '../customStylesConfigurator';

import { getValidators, prefilledValueValidator } from '../prefilledValueField/prefilledValueField.helper';

import { FIELD_IDS, TEXT_RENDERER_OPTIONS, BOOL_RENDERER_OPTIONS } from './formViewColumnPropertiesForm.constants';
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';
import { BOOL_INPUT_TYPES, TEXT_INPUT_TYPES } from '../../constants/viewBuilder.constants';

import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

const mapPropsForComponentRenderer = ({ value, ...restProps }) => ({ ...restProps, value: [value] });

const MANDATORY_FIELD = {
  id: FIELD_IDS.MANDATORY,
  renderer: FormFieldsCheckbox,
  renderOptions: {
    label: '',
    checkboxLabel: __('Mandatory'),
    validators: [isRequiredRule],
  },
};

const EDITABLE_FIELD = {
  id: FIELD_IDS.EDITABLE,
  renderer: FormFieldsCheckbox,
  renderOptions: {
    label: '',
    checkboxLabel: __('Editable'),
    validators: [isRequiredRule],
  },
};

const DISABLED_FIELD = {
  id: FIELD_IDS.DISABLED,
  renderer: FormFieldsCheckbox,
  renderOptions: {
    label: '',
    checkboxLabel: __('Disable'),
    validators: [isRequiredRule],
  },
};

const COMPONENT_RENDERER_FIELD = {
  id: FIELD_IDS.COMPONENT_RENDERER,
  renderer: withProps(mapPropsForComponentRenderer)(Select),
  renderOptions: {
    isMulti: false,
    closeMenuOnSelect: true,
  },
};

const TEXT_AREA_ROWS_FIELD = {
  id: FIELD_IDS.TEXT_AREA_ROWS,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Number of Rows in Text Area'),
    defaultValue: 2,
    min: 1,
    placeholder: __('Enter the number of rows here'),
    validators: [isRequiredRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const PREFILLED_VALUE_FIELD = {
  id: FIELD_IDS.PREFILLED_VALUE,
  renderer: PrefilledValueField,
};

const EVENT_HANDLER_FIELD = {
  id: FIELD_IDS.EVENT_HANDLERS,
  renderer: EventHandlerConfigurator,
};

const CUSTOM_STYLE_FIELD = {
  id: FIELD_IDS.CUSTOM_STYLES,
  renderer: CustomStylesConfigurator,
};

const FORM_FIELDS = {
  [FIELD_IDS.MANDATORY]: MANDATORY_FIELD,
  [FIELD_IDS.EDITABLE]: EDITABLE_FIELD,
  [FIELD_IDS.DISABLED]: DISABLED_FIELD,
  [FIELD_IDS.COMPONENT_RENDERER]: COMPONENT_RENDERER_FIELD,
  [FIELD_IDS.TEXT_AREA_ROWS]: TEXT_AREA_ROWS_FIELD,
  [FIELD_IDS.PREFILLED_VALUE]: PREFILLED_VALUE_FIELD,
  [FIELD_IDS.CUSTOM_STYLES]: CUSTOM_STYLE_FIELD,
  [FIELD_IDS.EVENT_HANDLERS]: EVENT_HANDLER_FIELD,
};

const getPropsForSelectInputFieldForComponentRenderer = (fieldDefinitionForSelectedFieldName) => {
  const dataType = fieldDefinitionReader.dataType(fieldDefinitionForSelectedFieldName);
  const fieldType = fieldDefinitionReader.fieldType(fieldDefinitionForSelectedFieldName);

  if (dataType === DATA_TYPES.TEXT && fieldType === FIELD_TYPES.TEXT) {
    return {
      label: __('Text Renderer Options'),
      options: TEXT_RENDERER_OPTIONS,
      defaultValue: [TEXT_INPUT_TYPES.TEXT_INPUT],
    };
  } else if (dataType === DATA_TYPES.BOOLEAN) {
    return {
      label: __('Bool Renderer Options'),
      options: BOOL_RENDERER_OPTIONS,
      defaultValue: [BOOL_INPUT_TYPES.CHECKBOX],
    };
  }

  return {
    label: EMPTY_STRING,
    options: EMPTY_ARRAY,
    defaultValue: EMPTY_ARRAY,
  };
};

const getFields = (entity, properties, fieldDefinitionForSelectedFieldName, errors, componentType) => {
  const { label, options, defaultValue } = getPropsForSelectInputFieldForComponentRenderer(fieldDefinitionForSelectedFieldName);

  return {
    ...FORM_FIELDS,
    [FIELD_IDS.MANDATORY]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.MANDATORY], [
      { path: 'entity', value: entity },
      { path: 'selectedViewComponentProperties', value: properties },
    ]),
    [FIELD_IDS.EDITABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.EDITABLE], [
      { path: 'entity', value: entity },
      { path: 'selectedViewComponentProperties', value: properties },
    ]),
    [FIELD_IDS.DISABLED]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.DISABLED], [
      { path: 'entity', value: entity },
      { path: 'selectedViewComponentProperties', value: properties },
    ]),
    [FIELD_IDS.COMPONENT_RENDERER]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.COMPONENT_RENDERER], [
      { path: 'label', value: label },
      { path: 'options', value: options },
      { path: 'defaultValue', value: defaultValue },
    ]),
    [FIELD_IDS.PREFILLED_VALUE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.PREFILLED_VALUE], [
      { path: 'entity', value: entity },
      { path: 'selectedViewComponentProperties', value: properties },
      { path: 'errors', value: tget(errors, FIELD_IDS.PREFILLED_VALUE, EMPTY_STRING) },
      { path: 'validators', value: [prefilledValueValidator(getValidators(entity, properties))] },
    ]),
    [FIELD_IDS.EVENT_HANDLERS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.EVENT_HANDLERS], [
      { path: 'entity', value: entity },
      { path: 'selectedViewComponentProperties', value: properties },
      { path: 'componentType', value: componentType },
    ]),
    [FIELD_IDS.CUSTOM_STYLES]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CUSTOM_STYLES], [
      { path: 'entity', value: entity },
      { path: 'selectedViewComponentProperties', value: properties },
      { path: 'componentType', value: componentType },
    ]),
  };
};

export { getFields };
