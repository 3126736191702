import _castArray from 'lodash/castArray';
import _reduce from 'lodash/reduce';
import _concat from 'lodash/concat';
import _compact from 'lodash/compact';
import _uniq from 'lodash/uniq';
import _head from 'lodash/head';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

// Actions
import { fetchEntityDefByName } from './entityManagement.actions';
import { getAllRoles } from './rolesHierarchyManagement.actions';
import globalLookupItemsResolver from './globalLookupItemsResolver';

import { updateRolesDataByName } from '../helpers/approval.groupCategoryHelpers';
// Constants
import { APPROVAL_CENTRE_FIELD_IDS, CUSTOM_ENTITY_CATEGORY } from '../constants/approvalCentre.constants';
import { ASSET_TYPES } from '../constants/general.constants';
import { LOOKUP_ITEM_TO_RESOLVE, LOOKUP_FIELD_TO_RESOLVE } from '../constants/globalLookupItemsResolver.constants';

// Readers
import workspaceUserReader from '../readers/workSpaceUser.reader';
import userGroupReader from '../readers/userGroup.reader';
import roleReader from '../readers/role.reader';

// Services
import approvalManagementServices from '../services/approvalManagement.services';

const fetchGroups = async (payload) => {
  try {
    const response = await approvalManagementServices.GROUP.fetchGroups(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch groups, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createGroup = async (payload) => {
  try {
    const response = await approvalManagementServices.GROUP.createGroup(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create group, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchCategories = async (payload) => {
  try {
    const response = await approvalManagementServices.CATEGORY.fetchCategories(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch categories, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createCategory = async (payload) => {
  try {
    const response = await approvalManagementServices.CATEGORY.createCategory(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create category, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchSettings = async (payload) => {
  try {
    const response = await approvalManagementServices.SETTINGS.fetchSettings(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch settings, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getSettingByName = async (settingName) => {
  try {
    const response = await approvalManagementServices.SETTINGS.getSettingByName(settingName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch setting, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createSetting = async (payload) => {
  try {
    const response = await approvalManagementServices.SETTINGS.createSetting(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create setting, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const addFieldToSetting = async (settingName, payload) => {
  try {
    const response = await approvalManagementServices.SETTINGS.addFieldToSetting(settingName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to add field to setting, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateFieldOfSetting = async (settingName, fieldName, payload) => {
  try {
    const response = await approvalManagementServices.SETTINGS.updateFieldOfSetting(settingName, fieldName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update this field for setting, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getApprovalSettingForGroupCategory = async ({ group, category }) => {
  try {
    const payload = {
      filters: [
        {
          field: APPROVAL_CENTRE_FIELD_IDS.GROUP,
          values: _castArray(group),
          filterType: OPERATORS.IN,
        },
        {
          field: APPROVAL_CENTRE_FIELD_IDS.CATEGORY,
          values: _castArray(category),
          filterType: OPERATORS.IN,
        },
      ],
    };

    const data = await fetchSettings(payload);
    const hits = tget(data, 'hits', EMPTY_ARRAY);
    return _head(hits);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch setting for this process, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchEntityDef = async ({ category, type }) => {
  if (category === CUSTOM_ENTITY_CATEGORY) {
    try {
      const data = await fetchEntityDefByName(type);
      return data;
    } catch (error) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch the entity definition, please try again later.')));
      return EMPTY_OBJECT;
    }
  }

  return EMPTY_OBJECT;
};

const fetchProcesses = async (payload) => {
  try {
    const response = await approvalManagementServices.PROCESS.fetchProcesses(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch processes, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getProcessByName = async (processName) => {
  try {
    const response = await approvalManagementServices.PROCESS.getProcessByName(processName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get process, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createProcess = async (payload) => {
  try {
    const response = await approvalManagementServices.PROCESS.createProcess(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create process, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateProcess = async (processName, payload) => {
  try {
    const response = await approvalManagementServices.PROCESS.updateProcess(processName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update process, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const resolveApproversForProcess = async (stages) => {
  const approverIdsByAssetType = _reduce(
    stages,
    (result, stageDetails) => {
      const approvers = tget(stageDetails, APPROVAL_CENTRE_FIELD_IDS.APPROVERS, []);

      _forEach(approvers, (approver) => {
        const approverAssetType = tget(approver, APPROVAL_CENTRE_FIELD_IDS.TYPE);
        const modifiedApproversIds = tget(result, approverAssetType, []);
        _set(result, approverAssetType, _concat(modifiedApproversIds, [tget(approver, APPROVAL_CENTRE_FIELD_IDS.APPROVER_ID)]));
      });

      return result;
    },
    {},
  );

  const userIds = tget(approverIdsByAssetType, ASSET_TYPES.USER, []);
  const userGroupNames = tget(approverIdsByAssetType, ASSET_TYPES.USER_GROUP, []);
  const uniqueRoles = _compact(_uniq(tget(approverIdsByAssetType, ASSET_TYPES.ROLE, [])));

  let roles = [];

  if (!_isEmpty(uniqueRoles)) {
    roles = await getAllRoles();
  }

  const rolesDataByName = {};
  updateRolesDataByName(roles, rolesDataByName);

  const response = await globalLookupItemsResolver(
    {
      [LOOKUP_ITEM_TO_RESOLVE.USER]: { [LOOKUP_FIELD_TO_RESOLVE.ID]: userIds },
      [LOOKUP_ITEM_TO_RESOLVE.USER_GROUP]: { [LOOKUP_FIELD_TO_RESOLVE.NAME]: userGroupNames },
    },
    false,
  );
  const userMapById = tget(response, LOOKUP_ITEM_TO_RESOLVE.USER, EMPTY_OBJECT);
  const userGroupsMapByName = tget(response, LOOKUP_ITEM_TO_RESOLVE.USER_GROUP, EMPTY_OBJECT);

  const approverResolvedStages = _map(stages, (stageDetails) => {
    const approvers = tget(stageDetails, APPROVAL_CENTRE_FIELD_IDS.APPROVERS, []);

    const resolvedUsers = [];
    const resolvedUserGroups = [];
    const resolvedRoles = [];
    _forEach(approvers, (approverObj) => {
      const approverId = tget(approverObj, APPROVAL_CENTRE_FIELD_IDS.APPROVER_ID);
      const approverType = tget(approverObj, APPROVAL_CENTRE_FIELD_IDS.TYPE);

      if (approverType === ASSET_TYPES.USER) {
        const user = tget(userMapById, approverId, {});
        resolvedUsers.push({
          ...user,
          label: workspaceUserReader.fullName(user),
          value: workspaceUserReader.id(user),
        });
      }

      if (approverType === ASSET_TYPES.USER_GROUP) {
        const userGroup = tget(userGroupsMapByName, approverId, {});
        resolvedUserGroups.push({
          ...userGroup,
          label: userGroupReader.displayName(userGroup),
          value: userGroupReader.name(userGroup),
        });
      }

      if (approverType === ASSET_TYPES.ROLE) {
        const role = tget(rolesDataByName, approverId, {});

        resolvedRoles.push({
          ...role,
          label: roleReader.displayName(role),
          value: roleReader.name(role),
        });
      }
    });

    const resolvedApprovers = {};
    if (!_isEmpty(resolvedUsers)) {
      _set(resolvedApprovers, ASSET_TYPES.USER, resolvedUsers);
    }

    if (!_isEmpty(resolvedUserGroups)) {
      _set(resolvedApprovers, ASSET_TYPES.USER_GROUP, resolvedUserGroups);
    }

    if (!_isEmpty(resolvedRoles)) {
      _set(resolvedApprovers, ASSET_TYPES.ROLE, resolvedRoles);
    }

    return {
      ...stageDetails,
      [APPROVAL_CENTRE_FIELD_IDS.APPROVERS]: resolvedApprovers,
    };
  });

  return approverResolvedStages;
};

const resolvedApproversForTasks = async (tasks) => {
  const eligibleApproversIdsForAllTasks = _reduce(
    tasks,
    (_approversIds, taskDetails) => {
      const eligibleApproversIds = tget(taskDetails, APPROVAL_CENTRE_FIELD_IDS.ELIGIBLE_APPROVERS, []);

      return _concat(_approversIds, eligibleApproversIds);
    },
    [],
  );

  const userResponse = await globalLookupItemsResolver(
    { [LOOKUP_ITEM_TO_RESOLVE.USER]: { [LOOKUP_FIELD_TO_RESOLVE.ID]: eligibleApproversIdsForAllTasks } },
    false,
  );
  const userMapById = tget(userResponse, LOOKUP_ITEM_TO_RESOLVE.USER, EMPTY_OBJECT);

  const approverResolvedTasks = _map(tasks, (taskDetails) => {
    const eligibleApproversIds = tget(taskDetails, APPROVAL_CENTRE_FIELD_IDS.ELIGIBLE_APPROVERS, []);
    const resolvedApprovers = _map(eligibleApproversIds, (approverId) => {
      const user = tget(userMapById, approverId, {});
      return {
        ...user,
        label: workspaceUserReader.fullName(user),
        value: workspaceUserReader.id(user),
      };
    });
    return {
      ...taskDetails,
      [APPROVAL_CENTRE_FIELD_IDS.ELIGIBLE_APPROVERS]: resolvedApprovers,
    };
  });

  return approverResolvedTasks;
};

const enableProcess = async (processName, payload) => {
  try {
    const response = await approvalManagementServices.PROCESS.enableProcess(processName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to enable process, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const disableProcess = async (processName, payload) => {
  try {
    const response = await approvalManagementServices.PROCESS.disableProcess(processName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to disable process, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export {
  fetchGroups,
  createGroup,
  fetchCategories,
  createCategory,
  fetchSettings,
  getSettingByName,
  createSetting,
  addFieldToSetting,
  updateFieldOfSetting,
  getApprovalSettingForGroupCategory,
  fetchEntityDef,
  fetchProcesses,
  getProcessByName,
  createProcess,
  updateProcess,
  resolveApproversForProcess,
  resolvedApproversForTasks,
  enableProcess,
  disableProcess,
};
