import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import _noop from 'lodash/noop';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';

import { getFormFields } from './formViewRenderer.fieldConfig';
import { getFormSections } from './formViewRenderer.sectionConfig';
import { ACTION_TYPES, DEFAULT_CONTEXT_ID } from './formViewRenderer.constants';
import ACTION_HANDLERS from './formViewRenderer.actionHandlers';
import { getSectionsAndFieldsFromChildren } from './formViewRenderer.helpers';

import styles from './formViewRenderer.module.scss';

const FormViewRender = ({
  isLoading,
  contextId,
  entity,
  complexViewConfiguration,
  entityRecord,
  onAction,
  errors,
  warnings,
  formValues,
  formMode,
  componentConfig,
  dependentOptionsByFieldName,
  mapOfFormFieldNameToEditable,
}) => {
  const { formSections = [], formFields = {} } = useMemo(() => getSectionsAndFieldsFromChildren(componentConfig), [componentConfig]);

  const fieldDefinitionsByName = useMemo(() => _keyBy(_get(entity, 'fieldDefinitions', EMPTY_ARRAY), 'name'), [entity]);

  const fields = useMemo(
    () =>
      getFormFields(
        formFields,
        complexViewConfiguration,
        fieldDefinitionsByName,
        formMode,
        formValues,
        dependentOptionsByFieldName,
        mapOfFormFieldNameToEditable,
      ),
    [formFields, complexViewConfiguration, fieldDefinitionsByName, formMode, formValues, dependentOptionsByFieldName, mapOfFormFieldNameToEditable],
  );

  const sections = useMemo(
    () => getFormSections(formSections, complexViewConfiguration, fieldDefinitionsByName, formMode),
    [formSections, complexViewConfiguration, fieldDefinitionsByName, formMode],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT, payload: { entityRecord, formFields } });
  }, [onAction, entityRecord, componentConfig, formFields]);

  if (isLoading) return null;
  return (
    <div className={styles.formViewRenderer}>
      <FormWithSubmission
        className="full-width p-t-12"
        contextId={contextId}
        fields={fields}
        sections={sections}
        values={formValues}
        errors={errors}
        warnings={warnings}
        onAction={onAction}
      />
    </div>
  );
};

FormViewRender.propTypes = {
  isLoading: PropTypes.bool,
  entity: PropTypes.object,
  entityRecord: PropTypes.object,
  complexViewConfiguration: PropTypes.object,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  warnings: PropTypes.object,
  formMode: PropTypes.string,
  contextId: PropTypes.string,
  componentConfig: PropTypes.object,
  dependentOptionsByFieldName: PropTypes.object,
  mapOfFormFieldNameToEditable: PropTypes.object,
  onAction: PropTypes.func,
};

FormViewRender.defaultProps = {
  isLoading: true,
  entity: undefined,
  entityRecord: undefined,
  complexViewConfiguration: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  warnings: EMPTY_OBJECT,
  formValues: EMPTY_OBJECT,
  formMode: undefined,
  contextId: DEFAULT_CONTEXT_ID,
  componentConfig: EMPTY_OBJECT,
  dependentOptionsByFieldName: EMPTY_OBJECT,
  mapOfFormFieldNameToEditable: EMPTY_OBJECT,
  onAction: _noop,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS), React.memo)(FormViewRender);
