import { useCallback } from 'react';
import { unwrapNodes } from '@udecode/plate-common';
import { getPlatePluginType } from '@udecode/plate-core';
import { ReactEditor } from 'slate-react';

import { ELEMENT_TEMPLATE_VARIABLE } from '../../../../../../../plugins/templateVariable';

// helpers
import { showTemplateVariableDialog } from '../../../../../../../organisms/templateVariableDialog';

// hooks
import useEditor from '../../../../../../../hooks/useEditor';

const removeSelectedTemplateVariable = (editor) => {
  unwrapNodes(editor, { match: { type: getPlatePluginType(editor, ELEMENT_TEMPLATE_VARIABLE) } });
  ReactEditor.focus(editor);
};

const handleTemplateVariableEdition = (closePopup) => {
  closePopup();
  showTemplateVariableDialog();
};

function useTemplateVariablePopupActions(templateVariableNodeElement, closePopup) {
  const editor = useEditor();

  const handleEditTemplateVariable = useCallback(() => {
    handleTemplateVariableEdition(closePopup);
  }, [closePopup]);

  const handleTemplateVariableRemoval = useCallback(() => {
    removeSelectedTemplateVariable(editor);
  }, [editor]);

  return {
    handleTemplateVariableRemoval,
    handleEditTemplateVariable,
  };
}

export default useTemplateVariablePopupActions;
