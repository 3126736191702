import { useCallback, useEffect, useMemo, useState } from 'react';

import { createShareAndManageRecordModalContextValue } from './useShareAndManageRecordModal.contextHelpers';
import { fetchOnModalOpenData, shareRecordWithUsers, fetchWWDData, deleteSharedRecordData } from './helpers/useShareAndManageRecordModal.data';

const useShareAndManageRecordModal = (propEntityDef) => {
  const [isShareAndManageRecordModalVisible, setIsShareAndManageRecordModalVisible] = useState(false);
  const [shareAndManageRecordModalMetaData, setShareAndManageRecordModalMetaData] = useState({});
  const [shareAndManageRecordUsersData, setShareAndManageRecordUsersData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isDataSubmitting, setIsDataSubmitting] = useState(false);

  const { wwdData, entityDef, entityRecord } = shareAndManageRecordModalMetaData;

  const onShareAndManageRecordModalOpen = useCallback(({ mode, entityDef: _entityDef, entityRecord: _entityRecord }) => {
    setShareAndManageRecordModalMetaData((prev) => ({ ...prev, entityDef: _entityDef, entityRecord: _entityRecord, mode }));

    fetchOnModalOpenData({ mode, entityDef: _entityDef, entityRecord: _entityRecord, setIsDataLoading, setShareAndManageRecordUsersData });

    setIsShareAndManageRecordModalVisible(true);
  }, []);

  const onShareAndManageRecordModalClose = useCallback(() => {
    setIsShareAndManageRecordModalVisible(false);
    setShareAndManageRecordUsersData([]);
  }, []);

  const onModalSubmit = useCallback(
    (_shareAndManageRecordUsersData) => {
      shareRecordWithUsers({
        entityRecord,
        entityDef,
        setIsDataSubmitting,
        shareUsersData: _shareAndManageRecordUsersData,
        onShareAndManageRecordModalClose,
      });
    },
    [entityDef, entityRecord, onShareAndManageRecordModalClose],
  );

  const deleteSharedRecordForUser = useCallback(
    async (sharedRecordUserData) => {
      const response = await deleteSharedRecordData(sharedRecordUserData, entityDef, entityRecord);

      return response;
    },
    [entityDef, entityRecord],
  );

  const shareAndManageModalContextValue = useMemo(
    () => createShareAndManageRecordModalContextValue(wwdData, onShareAndManageRecordModalOpen),
    [wwdData, onShareAndManageRecordModalOpen],
  );

  useEffect(() => {
    fetchWWDData(propEntityDef).then((_wwdData) => {
      setShareAndManageRecordModalMetaData((prev) => ({ ...prev, wwdData: _wwdData }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isShareAndManageRecordModalVisible,
    isDataLoading,
    isDataSubmitting,
    shareAndManageRecordUsersData,
    shareAndManageRecordModalMetaData,
    shareAndManageModalContextValue,
    onShareAndManageRecordModalClose,
    onModalSubmit,
    deleteSharedRecordForUser,
  };
};

export default useShareAndManageRecordModal;
