const SYSTEM_DEFINED_FIELDS = {
  ID: 'id',
  ACCOUNT_ID: 'accountId',
  NAME: 'name',
  ENTITY_NAME: 'entityName',
  CREATED_TIME: 'createdTime',
  MODIFIED_TIME: 'modifiedTime',
  CREATED_BY: 'createdBy',
  LAST_MODIFIED_BY: 'lastModifiedBy',
  DELETED: 'deleted',
  ENTITY: 'entity',
  RECORD_TYPE_NAME: 'recordTypeName',
  RECORD_TYPE_ID: 'recordTypeId',
  OWNER_ID: 'ownerId',
};

export default SYSTEM_DEFINED_FIELDS;
