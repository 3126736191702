import _filter from 'lodash/filter';
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { pascalCase } from '../../../../../../../helpers/general.helpers';

import { FIELD_IDS as EVENT_HANDLER_MODAL_FIELD_IDS } from '../components/eventHandlerModal/eventHandlerModal.constants';
import eventConfiguratorReader from '../../../../../../../readers/eventConfigurator.reader';

const getSelectedEventHandlers = (allFormValues) => {
  const selectedEventHandlerTitles = [];
  const selectedEventHandlerEventTypes = [];
  _forEach(
    allFormValues,
    (currentFormValue) => {
      const eventValue = tget(currentFormValue, EVENT_HANDLER_MODAL_FIELD_IDS.EVENT_HANDLER, EMPTY_OBJECT);
      const eventType = eventConfiguratorReader.eventType(eventValue);
      if (!_isEmpty(eventType)) {
        selectedEventHandlerTitles.push(pascalCase(eventConfiguratorReader.eventType(eventValue)));
        selectedEventHandlerEventTypes.push(eventType);
      }
    },
    [],
  );
  return { selectedEventHandlerTitles, selectedEventHandlerEventTypes };
};

const filterFormValueForDeletedTab = (allFormValues, moduleIndex) => {
  const newAllFormValues = _filter(allFormValues, (eventHandler, index) => moduleIndex !== index);
  return _cloneDeep(newAllFormValues);
};

const checkDeletedModuleIsSelectedAndLastModule = (moduleIndex, selectedModuleIndex, newAllFormValues) =>
  moduleIndex === selectedModuleIndex && moduleIndex === _size(newAllFormValues) - 1 && moduleIndex >= 1;

export { getSelectedEventHandlers, filterFormValueForDeletedTab, checkDeletedModuleIsSelectedAndLastModule };
