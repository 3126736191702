import _compact from 'lodash/compact';
import _filter from 'lodash/filter';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { getCompactedFiltersPayload } from '../../../../../helpers/general.helpers';
import { getComplexAndRelationshipEntitiesFromEntityDef } from '../../../../../helpers/entityManagement.helpers';

import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../constants/viewBuilder.constants';
import { EVENT_NAMES } from '../../../../../constants/eventActions.constants';
import { DEFAULT_EVENT_HANDLER_FOR_RIGHT_PANE_VIEW } from '../constants/entityRecordListPage.constants';

const getPayloadForViewDefinitionSearch = (entityNames, viewTypes, viewConfigTypes) => {
  const filters = [
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      values: entityNames,
      filterType: OPERATORS.IN,
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
      values: viewTypes,
      filterType: OPERATORS.IN,
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_CONFIG_TYPE,
      values: viewConfigTypes,
      filterType: OPERATORS.IN,
    },
  ];

  return { filters: getCompactedFiltersPayload(filters) };
};

const getComplexFieldPayload = (entity) => {
  const { complexEntities, relationshipEntities } = getComplexAndRelationshipEntitiesFromEntityDef(entity);

  const complexAndRelationshipEntities = [...complexEntities, ...relationshipEntities];

  const complexAndRelationshipFieldFilterValues = _compact(complexAndRelationshipEntities);

  const filters = [
    {
      field: 'name',
      values: complexAndRelationshipFieldFilterValues,
      filterType: OPERATORS.IN,
    },
  ];
  return { filters: getCompactedFiltersPayload(filters) };
};

const getUpdatedEventHandlers = (eventHandlers = EMPTY_ARRAY) => {
  const updatedEventHandlers = _filter(eventHandlers, (eventHandler) => tget(eventHandler, 'eventType', '') !== EVENT_NAMES.ON_ROW_CLICK);
  return [...updatedEventHandlers, DEFAULT_EVENT_HANDLER_FOR_RIGHT_PANE_VIEW];
};

export { getPayloadForViewDefinitionSearch, getComplexFieldPayload, getUpdatedEventHandlers };
