import React, { useMemo, useCallback } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import Drawer from '@tekion/tekion-components/molecules/drawer';
import Loader from '@tekion/tekion-components/molecules/loader';
import Heading from '@tekion/tekion-components/atoms/Heading';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import withActions from '@tekion/tekion-components/connectors/withActions';
import { DEFAULT_RESULTS_PER_PAGE } from '@tekion/tekion-components/molecules/table/constants/table.constants';

import withSize from '../../../../connectors/withSize';
import ACTION_HANDLERS from './helpers/jobScheduleList.actionHandlers';
import { createFilterProps, createTableProps } from './helpers/jobScheduleList.helpers';

import {
  SUB_HEADER_PROPS,
  TABLE_MANAGER_PROPS,
  DEFAULT_CURRENT_PAGE,
  EXECUTION_LOG_STATUS_COLOR_MAP,
  EXECUTION_LOG_STATUS_LABEL_MAP,
  JOB_EXECUTION_SUCCESS_STATUS,
} from './constants/jobScheduleList.general';
import { COLUMN_CONFIG } from './constants/jobScheduleList.columns';
import ACTION_TYPES from './constants/jobScheduleList.actionTypes';
import COLUMN_IDS from './constants/jobScheduleList.columnIds';

import styles from './jobScheduleList.module.scss';

const JobScheduleList = ({
  isLoading,
  isDrawerVisible,
  isDrawerDataLoading,
  contentHeight,
  currentPage,
  pageSize,
  nextPageToken,
  searchText,
  clickedRowData,
  clickedJobExecutionLogData,
  jobsData,
  selectedFilters,
  onAction,
}) => {
  const tableProps = useMemo(() => createTableProps(isLoading, currentPage, pageSize, onAction), [isLoading, currentPage, pageSize, onAction]);
  const filterProps = useMemo(() => createFilterProps(onAction), [onAction]);

  const handleDrawerClose = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_DRAWER_CLOSE });
  }, [onAction]);

  return (
    <Page style={{ height: contentHeight }}>
      <TableManager
        tokenPagination
        nextPageToken={nextPageToken}
        searchText={searchText}
        subHeaderProps={SUB_HEADER_PROPS}
        tableManagerProps={TABLE_MANAGER_PROPS}
        tableProps={tableProps}
        columns={COLUMN_CONFIG}
        data={jobsData}
        filterProps={filterProps}
        selectedFilters={selectedFilters}
        onAction={onAction}
      />
      <Drawer visible={isDrawerVisible} onClose={handleDrawerClose}>
        <PropertyControlledComponent controllerProperty={!isDrawerDataLoading} fallback={<Loader />}>
          <div>
            <Heading size={2}>{__(tget(clickedRowData, COLUMN_IDS.JOB_SCHEDULE_NAME, NO_DATA))}</Heading>
            <div>{__('Last 20 Execution Log')}</div>
            {_map(clickedJobExecutionLogData, (logData, index) => (
              <div key={index} className={styles.executionLogContainer}>
                <div className={styles.index}>{index + 1} - </div>
                <StatusRenderer
                  data={tget(logData, 'status') === JOB_EXECUTION_SUCCESS_STATUS}
                  colorMap={EXECUTION_LOG_STATUS_COLOR_MAP}
                  labelMap={EXECUTION_LOG_STATUS_LABEL_MAP}
                />
              </div>
            ))}
          </div>
        </PropertyControlledComponent>
      </Drawer>
    </Page>
  );
};

JobScheduleList.propTypes = {
  isLoading: PropTypes.bool,
  isDrawerVisible: PropTypes.bool,
  isDrawerDataLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  nextPageToken: PropTypes.string,
  searchText: PropTypes.string,
  clickedRowData: PropTypes.object,
  clickedJobExecutionLogData: PropTypes.object,
  jobsData: PropTypes.array,
  selectedFilters: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

JobScheduleList.defaultProps = {
  isLoading: false,
  isDrawerVisible: false,
  isDrawerDataLoading: false,
  currentPage: DEFAULT_CURRENT_PAGE,
  pageSize: DEFAULT_RESULTS_PER_PAGE,
  nextPageToken: EMPTY_STRING,
  searchText: EMPTY_STRING,
  clickedRowData: EMPTY_OBJECT,
  clickedJobExecutionLogData: EMPTY_OBJECT,
  jobsData: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS), withSize({ hasPageHeader: false }))(JobScheduleList);
