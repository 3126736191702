// Tekion-components
import Button from '@tekion/tekion-components/atoms/Button';
import { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';

// Constants
import ACTION_TYPES from './approvalProcessList.actionTypes';

const HEADER_PROPS = {
  label: __('Approval Process'),
};

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create New'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_APPROVAL_PROCESS,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showFilter: true,
  showHeader: true,
  showSearch: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const RULE_STATUS = {
  ACTIVE: false,
  INACTIVE: true,
};

const RULE_STATUS_COLOR_MAP = {
  [RULE_STATUS.ACTIVE]: COLOR_MAP.GREEN,
  [RULE_STATUS.INACTIVE]: COLOR_MAP.RED,
};

const RULE_STATUS_LABEL_MAP = {
  [RULE_STATUS.ACTIVE]: __('Active'),
  [RULE_STATUS.INACTIVE]: __('Inactive'),
};

export { HEADER_PROPS, SUB_HEADER_PROPS, TABLE_MANAGER_PROPS, RULE_STATUS_COLOR_MAP, RULE_STATUS_LABEL_MAP };
