import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import { getAllGlobalSelectList } from '../../../../../../actions/globalSelectLists.actions';
import { getPayload } from './globalSelectList.helpers';
import ACTION_TYPES from '../constants/globalSelectList.actionTypes';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import PAGE_IDS from '../../../../constants/PageIds.constants';

const handleInit = async ({ getState, setState }) => {
  const { pageSize, searchText, pageToken } = getState();
  const payload = getPayload(pageSize, pageToken, searchText);

  setState({ loading: true });

  const globalSelectResponse = await getAllGlobalSelectList(payload);
  const globalSelectList = tget(globalSelectResponse, 'hits', EMPTY_ARRAY);
  const nextPageToken = tget(globalSelectResponse, 'nextPageToken', null);

  setState({ globalSelectList, loading: false, nextPageToken });
};

const handlePageSizeUpdate = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');

  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }
  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleInit({ getState, setState });
    },
  );
};

const handleSearchApply = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const searchText = tget(params, 'value', EMPTY_STRING);
  setState({ searchText }, () => {
    handleInit({ getState, setState });
  });
};

const handleCreateGlobalSelect = ({ getState }) => {
  const { history } = getState();
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.CREATE_GLOBAL_SELECT_LIST}`;
  history.push({ pathname });
};

const rowClickHandler = ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const data = tget(params, 'value.original', EMPTY_OBJECT);
  const globalSelectName = tget(data, 'name', EMPTY_STRING);
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.EDIT_GLOBAL_SELECT_LIST}/${globalSelectName}`;
  history.push(pathname);
};

const handleEdit = async ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const globalSelectName = tget(params, 'name', EMPTY_STRING);
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.EDIT_GLOBAL_SELECT_LIST}/${globalSelectName}`;
  history.push(pathname);
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageSizeUpdate,
  [TABLE_ACTION_TYPES.TABLE_SEARCH_TERM_UPDATE]: handleSearchApply,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: rowClickHandler,
  [ACTION_TYPES.EDIT]: handleEdit,
  [ACTION_TYPES.CREATE_GLOBAL_SELECT]: handleCreateGlobalSelect,
};

export default ACTION_HANDLERS;
