import DRAWER_ACTION_HANDLERS from './drawerActionHandlers';
import MODAL_ACTION_HANDLERS from './modalActionHandlers';
import NODE_ACTION_HANDLERS from './nodeActionHandlers';
import WORKFLOW_BUILDER_ACTION_HANDLERS from './workflowBuilderActionHandlers';

const ACTION_HANDLERS = {
  ...DRAWER_ACTION_HANDLERS,
  ...MODAL_ACTION_HANDLERS,
  ...NODE_ACTION_HANDLERS,
  ...WORKFLOW_BUILDER_ACTION_HANDLERS,
};

export default ACTION_HANDLERS;
