import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _snakeCase from 'lodash/snakeCase';
import _castArray from 'lodash/castArray';
import _remove from 'lodash/remove';
import _head from 'lodash/head';
import _toNumber from 'lodash/toNumber';
import _has from 'lodash/has';
import _omit from 'lodash/omit';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import _find from 'lodash/find';

import { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';

import {
  createField,
  updateField,
  updateSelectOption,
  addSelectOption,
  fetchEntityDefByName,
  getFieldData,
  fetchAllRegex,
  matchRegex,
} from '../../../../../actions/entityManagement.actions';
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../actions/conditionBuilder.actions';
import {
  getPayloadFromValues,
  getValuesFromPayload,
  getPropertyValuesFromPayload,
  getSaveListOptionPayload,
  getChildAggregateValuesFromPayload,
  getDropDownValuesFromPayload,
} from './fieldsForm.payload.helpers';
import {
  getOptionsByName,
  getRegexOptions,
  getRegexValue,
  validateConfigTable,
  validateControllingOption,
  validateInputTable,
} from './fieldsForm.helpers';
import { STUDIO_ROUTE } from '../../../../../constants/routes';
import { FORM_MODES } from '../../../../../constants/general.constants';
import PAGE_IDS from '../../../constants/PageIds.constants';
import FIELD_IDS from '../constants/fieldsForm.fieldIds';
import ACTION_TYPES from '../constants/fieldsForm.actionTypes';
import {
  DATA_TYPES,
  FIELD_TYPES,
  DATA_TYPE_OF_TARGET_VALUE,
  DEFAULT_SORT_DIRECTION,
  COLUMNS,
  SUBMIT_ADDITIONAL_VALUE,
} from '../constants/fieldsForm.constants';
import { COLUMN_IDS, EMPTY_ROW, ROW_OPERATION } from '../components/ListInputTable/constants/listInputTable.constants';
import entityDefinitionReader from '../../../../../readers/entity.reader';

const handleInitForm = async ({ setState, getState }) => {
  const { history = EMPTY_OBJECT, match = EMPTY_OBJECT } = getState();
  const { entityName, fieldName } = _get(match, 'params', EMPTY_OBJECT);
  let entity = _get(history, 'location.state.entity');
  let fieldData = EMPTY_OBJECT;
  let relationshipFields = EMPTY_OBJECT;

  setState({ fieldDataLoading: true });

  if (!entity) {
    entity = await fetchEntityDefByName(entityName);
  }
  const { hits: regexes, error } = await fetchAllRegex();
  const regexOptions = _isEmpty(error) ? getRegexOptions(regexes) : EMPTY_ARRAY;
  const mapOfVariableToEntityNameForEntryConditionParent = { $record: entityName };
  const conditionBuilderFieldDefinitionObjectParent = await fetchFieldDefinitionsForConditionBuilder(
    mapOfVariableToEntityNameForEntryConditionParent,
  );
  entity = { ...entity, mapOfVariableToEntityNameForEntryConditionParent, conditionBuilderFieldDefinitionObjectParent };
  if (fieldName && entityName) {
    if (!_has(history, 'location.state.fieldData')) {
      const fieldResponse = await getFieldData(entityName, fieldName);
      fieldData = _get(fieldResponse, 'response', EMPTY_OBJECT);
    } else {
      fieldData = _get(history, 'location.state.fieldData', EMPTY_OBJECT);
    }
    const valuesFromPayload = getValuesFromPayload(fieldData);
    const propertyValuesFromPayload = getPropertyValuesFromPayload(fieldData);

    const fieldType = _get(fieldData, FIELD_IDS.FIELD_TYPE, EMPTY_STRING);
    const dataType = _get(fieldData, FIELD_IDS.DATA_TYPE, EMPTY_STRING);

    let childEntityDefinitions = EMPTY_OBJECT;
    let childAggregateFieldValues = {};
    let selectedOptions = [];
    if (fieldType === FIELD_TYPES.SELECT) {
      selectedOptions = _compact(
        _map(_get(propertyValuesFromPayload, FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS), (item) => getArraySafeValue(_get(item, COLUMNS.OPTION))),
      );
    }
    if (fieldType === FIELD_TYPES.CHILD_AGGREGATE_SUMMARY) {
      childAggregateFieldValues = getChildAggregateValuesFromPayload(fieldData);
      const childEntityName = getArraySafeValue(_get(childAggregateFieldValues, FIELD_IDS.CHILD_ENTITY_NAME, EMPTY_STRING));

      const response = await fetchEntityDefByName(childEntityName);
      const childFieldDefinitions = entityDefinitionReader.fieldDefinitions(response);
      const mapOfVariableToEntityNameForEntryConditionChild = { $record: childEntityName };
      const conditionBuilderFieldDefinitionObjectChild = await fetchFieldDefinitionsForConditionBuilder(
        mapOfVariableToEntityNameForEntryConditionChild,
      );

      childEntityDefinitions = {
        [childEntityName]: { childFieldDefinitions, mapOfVariableToEntityNameForEntryConditionChild, conditionBuilderFieldDefinitionObjectChild },
      };
    }
    if (dataType === DATA_TYPES.COMPLEX || fieldType === FIELD_TYPES.RELATIONSHIP) {
      if (fieldType === FIELD_TYPES.RELATIONSHIP) {
        const lookupEntityName = _get(fieldData, 'lookupField.entityType', EMPTY_STRING);

        const response = await fetchEntityDefByName(lookupEntityName);

        const lookupDisplayFieldOptions = { [lookupEntityName]: getOptionsByName(_get(response, 'fieldDefinitions', EMPTY_ARRAY), false) };
        const lookupFieldOptions = { [lookupEntityName]: getOptionsByName(_get(response, 'fieldDefinitions', EMPTY_ARRAY)) };
        relationshipFields = {
          ...relationshipFields,
          options: { lookupDisplayFieldOptions, lookupFieldOptions },
          entityDefinitions: {
            [lookupEntityName]: {
              lookupDisplayFieldOptions,
              lookupFieldOptions,
            },
          },
        };
      }
    }

    setState({
      fieldData,
      entity,
      regexOptions,
      formValues: {
        ...fieldData,
        ...valuesFromPayload,
        ...propertyValuesFromPayload,
        ...childAggregateFieldValues,
        [FIELD_IDS.FIELD_TYPE]: _castArray(_get(fieldData, FIELD_IDS.FIELD_TYPE)),
        [FIELD_IDS.DATA_TYPE]: _castArray(_get(fieldData, FIELD_IDS.DATA_TYPE)),
        [FIELD_IDS.DEPENDENCY_CONFIG]: _map(_get(fieldData, FIELD_IDS.DEPENDENCY_CONFIG, EMPTY_ARRAY), (item) => ({
          fieldName: _castArray(_get(item, COLUMNS.FIELD_NAME)),
          dependencyType: _castArray(_get(item, COLUMNS.DEPENDENCY_TYPE)),
        })),
      },
      selectedFields: _compact(_map(_get(fieldData, FIELD_IDS.DEPENDENCY_CONFIG, (item) => _get(item, COLUMNS.FIELD_NAME)))),
      selectedOptions,
      fieldDataLoading: false,
      formMode: FORM_MODES.EDIT,
      ...relationshipFields,
      childEntityDefinitions,
    });
  } else {
    setState({
      entity,
      regexOptions,
      formValues: {
        [FIELD_IDS.SEARCHABLE]: false,
        [FIELD_IDS.LIST_INPUT_TABLE]: [{ ...EMPTY_ROW }],
      },
      fieldDataLoading: false,
      formMode: FORM_MODES.CREATE,
      ...relationshipFields,
    });
  }
};

const getLookupData = async ({ setState, getState }) => {
  let { entityDefinitions } = getState();
  const { formValues } = getState();
  const lookupEntity = _head(_get(formValues, 'lookupEntity', EMPTY_ARRAY));
  let options = EMPTY_OBJECT;
  if (_has(entityDefinitions, lookupEntity)) {
    const lookupData = _get(entityDefinitions, lookupEntity, EMPTY_OBJECT);
    options = { ...options, ...lookupData };
  }
  entityDefinitions = { ...entityDefinitions };
  setState({ loading: false, options, entityDefinitions });
};

const handleFieldChange = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { match, formValues, disableAutoFillForName = false, options, regexOptions = EMPTY_ARRAY, entity } = getState();
  let { entityDefinitions, childEntityDefinitions } = getState();
  const { id, value } = params;

  const fieldName = _get(match, 'params.fieldName', {});

  let newValues = {
    ...formValues,
    [id]: value,
  };
  if (id === FIELD_IDS.LOOKUP_ENTITY && getArraySafeValue(value) === _get(entity, 'name', EMPTY_STRING)) {
    newValues = { ...newValues, [FIELD_IDS.SEARCHABLE]: false };
  }

  if (id === FIELD_IDS.SELECT_TEMPLATE_REGEX) {
    newValues = {
      ...newValues,
      [FIELD_IDS.TEXT_REGEX]: getRegexValue(regexOptions, value),
    };
  }

  if (id === FIELD_IDS.DATA_TYPE) {
    if (_head(value) === DATA_TYPES.BOOLEAN || _head(value) === DATA_TYPES.COMPLEX) {
      newValues = {
        ...newValues,
        [FIELD_IDS.UNIQUE_CONSTRAINT]: false,
      };
    }
  } else if (id === FIELD_IDS.FIELD_TYPE) {
    if (_head(value) === FIELD_TYPES.LIST || _head(value) === FIELD_TYPES.SELECT || _head(value) === FIELD_TYPES.RANGE) {
      newValues = {
        ...newValues,
        [FIELD_IDS.UNIQUE_CONSTRAINT]: false,
      };
    }
  }
  if (id === FIELD_IDS.CHILD_ENTITY_NAME) {
    const entityName = _head(value);
    if (!_has(childEntityDefinitions, entityName)) {
      const response = await fetchEntityDefByName(entityName);
      const childFieldDefinitions = entityDefinitionReader.fieldDefinitions(response);
      const mapOfVariableToEntityNameForEntryConditionChild = { $record: entityName };
      const conditionBuilderFieldDefinitionObjectChild = await fetchFieldDefinitionsForConditionBuilder(
        mapOfVariableToEntityNameForEntryConditionChild,
      );

      childEntityDefinitions = {
        [entityName]: { childFieldDefinitions, mapOfVariableToEntityNameForEntryConditionChild, conditionBuilderFieldDefinitionObjectChild },
      };
      setState({ childEntityDefinitions });
    }
  }
  if (id === FIELD_IDS.LOOKUP_ENTITY) {
    _set(newValues, FIELD_IDS.LOOKUP_DISPLAY_FIELD, EMPTY_ARRAY);
    _set(newValues, FIELD_IDS.LOOKUP_FIELD, EMPTY_ARRAY);

    const entityName = _head(value);
    let lookupDisplayFieldOptions = {};
    let lookupFieldOptions = {};

    if (!_has(entityDefinitions, `${entityName}`)) {
      const response = await fetchEntityDefByName(entityName);

      lookupDisplayFieldOptions = { [entityName]: getOptionsByName(_get(response, 'fieldDefinitions', EMPTY_ARRAY), false) };
      lookupFieldOptions = { [entityName]: getOptionsByName(_get(response, 'fieldDefinitions', EMPTY_ARRAY)) };
      entityDefinitions = {
        ...entityDefinitions,
        [entityName]: {
          lookupDisplayFieldOptions,
          lookupFieldOptions,
        },
      };
      setState({ options: { ...options, lookupDisplayFieldOptions, lookupFieldOptions }, entityDefinitions });
    } else {
      lookupDisplayFieldOptions = _get(entityDefinitions, `${entityName}.lookupDisplayFieldOptions`, EMPTY_ARRAY);
      lookupFieldOptions = _get(entityDefinitions, `${entityName}.lookupFieldOptions`, EMPTY_ARRAY);
      setState({ options: { ...options, lookupDisplayFieldOptions, lookupFieldOptions } });
    }
  }

  // Name is same as displayName replacing spaces with _ and lower case
  if (id === FIELD_IDS.DISPLAY_NAME && _isEmpty(fieldName) && !disableAutoFillForName) {
    newValues = {
      ...newValues,
      [FIELD_IDS.NAME]: _snakeCase(value),
    };
  } else if (id === FIELD_IDS.NAME) setState({ disableAutoFillForName: true });
  else if (id === FIELD_IDS.DATA_TYPE) {
    newValues = {
      ...newValues,
      [FIELD_IDS.FIELD_TYPE]: '',
    };
    if (_head(value) === DATA_TYPES.COMPLEX) {
      getLookupData({ setState, getState, dataType: _head(value) });
    }
  } else if (id === FIELD_IDS.FIELD_TYPE && _head(value) === FIELD_TYPES.RELATIONSHIP) {
    newValues = {
      ...newValues,
      [FIELD_IDS.DATA_TYPE]: [DATA_TYPE_OF_TARGET_VALUE],
    };
    getLookupData({ setState, getState, fieldType: _head(value) });
  } else if (id === FIELD_IDS.LIST_ADD_ROW_BUTTON) {
    const tableData = _get(formValues, FIELD_IDS.LIST_INPUT_TABLE, EMPTY_ARRAY);
    newValues = { ...newValues, [FIELD_IDS.LIST_INPUT_TABLE]: [...tableData, { ...EMPTY_ROW }] };
  } else if (id === FIELD_IDS.UNIQUE_CONSTRAINT && value) {
    newValues = { ...newValues, [FIELD_IDS.MANDATORY]: true };
  } else if (id === FIELD_IDS.EDITABLE && value) {
    newValues = { ...newValues, [FIELD_IDS.CREATABLE]: true };
  } else if (id === FIELD_IDS.SORTABLE) {
    if (value) {
      newValues = {
        ...newValues,
        [FIELD_IDS.SORT_DIRECTION]: DEFAULT_SORT_DIRECTION,
        [FIELD_IDS.FILTERABLE]: _toNumber(_get(newValues, FIELD_IDS.TEXT_MAX_LENGTH, 0)) <= 256 || false,
      };
    } else {
      newValues = _omit(newValues, [FIELD_IDS.SORT_DIRECTION]);
    }
  } else if (id === FIELD_IDS.TEXT_MAX_LENGTH) {
    if (_toNumber(value) > 256) newValues = { ...newValues, [FIELD_IDS.FILTERABLE]: false };
    else {
      const allowLookup = _get(formValues, FIELD_IDS.ALLOW_LOOKUP_USING_FIELD, false);
      newValues = { ...newValues, [FIELD_IDS.FILTERABLE]: allowLookup };
    }
  } else if (id === FIELD_IDS.ALLOW_LOOKUP_USING_FIELD && value) {
    const textMaxLength = _get(formValues, FIELD_IDS.TEXT_MAX_LENGTH, 0);
    newValues = {
      ...newValues,
      [FIELD_IDS.MANDATORY]: true,
      [FIELD_IDS.UNIQUE_CONSTRAINT]: true,
      [FIELD_IDS.EXPORTABLE]: true,
      [FIELD_IDS.FILTERABLE]: textMaxLength < 255,
    };
  }

  setState({ formValues: newValues });
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  setState({
    errors: params?.errors,
  });
};

const handleSubmit = async ({ getState, setState, params }) => {
  const actionType = tget(params, 'additional.actionType', SUBMIT_ADDITIONAL_VALUE.SAVE);
  const { formValues, match, history, options, formMode } = getState();
  const fieldName = match?.params?.fieldName;
  const entityName = match?.params?.entityName;
  const payload = getPayloadFromValues(formValues, options);

  let formIsValid = true;
  let formError = '';
  const fieldType = _head(_get(formValues, FIELD_IDS.FIELD_TYPE));
  if (!_isEmpty(_get(formValues, FIELD_IDS.DEPENDENCY_CONFIG))) {
    const { valid, error } = validateConfigTable(_get(formValues, FIELD_IDS.DEPENDENCY_CONFIG));
    formIsValid = valid;
    formError = error;
  }
  if (fieldType === FIELD_TYPES.SELECT) {
    const { valid, error } = validateInputTable(_get(formValues, FIELD_IDS.LIST_INPUT_TABLE));
    formIsValid = formIsValid && valid;
    formError = `${formError}.${error}`;
    if (_get(formValues, FIELD_IDS.CONTROLLING_FUNCTION)) {
      const { valid: optionValid, error: optionError } = validateControllingOption(_get(formValues, FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS));
      formIsValid = formIsValid && optionValid;
      formError = `${formError}.${optionError}`;
    }
  }
  if (actionType === SUBMIT_ADDITIONAL_VALUE.SAVE_AND_CREATE_NEW) {
    setState({ isSavingDetailsCreateNew: true });
  } else {
    setState({ isSavingDetails: true });
  }

  if (formIsValid) {
    let response = {};
    if (formMode === FORM_MODES.EDIT) {
      response = await updateField(entityName, fieldName, payload);
    } else {
      response = await createField(entityName, payload);
    }

    if (!_isEmpty(response)) {
      if (actionType === SUBMIT_ADDITIONAL_VALUE.SAVE_AND_CREATE_NEW) {
        setState({
          isSavingDetailsCreateNew: false,
          formValues: {
            [FIELD_IDS.LIST_INPUT_TABLE]: [{ ...EMPTY_ROW }],
          },
          formMode: FORM_MODES.CREATE,
        });
      }
      if (actionType === SUBMIT_ADDITIONAL_VALUE.SAVE) {
        const url = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELDS}`;
        setTimeout(() => {
          history.push(url);
        }, ES_REFETCH_DELAY);
      }
    } else {
      setState({ isSavingDetails: false });
    }
  } else {
    setState({ isSavingDetails: false });
    toaster(TOASTER_TYPE.ERROR, formError);
  }
};

const handleOnCancel = ({ setState, getState }) => {
  const { history, match } = getState();
  const entityName = match?.params?.entityName;
  setState({ formValues: {} });
  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELDS}`);
};

const handleOptionTableOnChange = ({ setState, params = EMPTY_OBJECT, getState }) => {
  const { id, value } = params;
  const { formValues } = getState();
  const row = _get(value, 'row');
  const fieldValue = _get(value, 'value');
  const tableData = _get(formValues, FIELD_IDS.LIST_INPUT_TABLE, EMPTY_ARRAY);
  if (id === COLUMN_IDS.LABEL) {
    tableData[row] = { ...tableData[row], label: fieldValue };
    if (!_get(tableData[row], 'valueChanged', false)) {
      tableData[row] = { ...tableData[row], value: fieldValue };
    }
  } else tableData[row] = { ...tableData[row], value: fieldValue, valueChanged: true };

  setState({ formValues: { ...formValues, [FIELD_IDS.LIST_INPUT_TABLE]: [...tableData] } });
};

const handleOptionTableRemoveRow = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { formValues } = getState();
  const { row } = params;
  const tableData = _get(formValues, FIELD_IDS.LIST_INPUT_TABLE, EMPTY_ARRAY);
  _remove(tableData, (val, index) => index === row);
  setState({
    formValues: { ...formValues, [FIELD_IDS.LIST_INPUT_TABLE]: [...tableData] },
  });
};

const handleSaveRow = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { formValues, match } = getState();
  const fieldName = match?.params?.fieldName;
  const entityName = match?.params?.entityName;
  const { rowData } = params;
  const rowOperation = _get(rowData, 'operation', ROW_OPERATION.ADD);
  const { value, payload } = getSaveListOptionPayload(rowData);

  if (rowOperation === ROW_OPERATION.ADD) {
    const { response, error } = await addSelectOption(entityName, fieldName, payload);
    if (_isEmpty(error)) {
      toaster(TOASTER_TYPE.SUCCESS, __('option added successfully!'));
      const newValues = getDropDownValuesFromPayload(response);

      setState({
        formValues: { ...formValues, ...newValues },
        isModalVisible: false,
      });
    } else toaster(TOASTER_TYPE.ERROR, error);
  } else if (rowOperation === ROW_OPERATION.EDIT) {
    const { response, error } = await updateSelectOption(entityName, fieldName, value, payload);
    if (_isEmpty(error)) {
      toaster(TOASTER_TYPE.SUCCESS, __('option updated successfully!'));
      const newValues = getDropDownValuesFromPayload(response);

      setState({
        formValues: { ...formValues, ...newValues },
        isModalVisible: false,
      });
    } else toaster(TOASTER_TYPE.ERROR, error);
  }
};

const handleOptionTableAddRow = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { fieldData = EMPTY_OBJECT } = getState();
  let controllingOptions = _get(fieldData, 'optionConfig.controllingOptionsConfigs');
  controllingOptions = _get(
    _find(controllingOptions, (item) => _get(item, 'controlledOption') === _get(params, 'value')),
    'controllingOptions',
  );

  setState({ isModalVisible: true, modalData: { ...params, controllingOptions } });
};

const handleOptionTableModalClose = ({ setState }) => {
  setState({ isModalVisible: false, modalData: EMPTY_OBJECT });
};

const handleConfigTableOnChange = ({ setState, params, getState }) => {
  const { id, configId } = params;
  const { formValues, selectedFields, selectedOptions } = getState();

  const row = _head(_get(params, 'nestingPath'));
  const fieldValue = _get(params, 'value');

  let newSelectedFields = selectedFields;
  let newSelectedOptions = selectedOptions;

  const tableData = _get(formValues, configId, EMPTY_ARRAY);
  tableData[row] = { ...tableData[row], [id]: fieldValue };
  if (configId === FIELD_IDS.DEPENDENCY_CONFIG && id === COLUMNS.FIELD_NAME) {
    newSelectedFields = _compact(_map(tableData, (item) => _head(_get(item, COLUMNS.FIELD_NAME))));
  }
  if (configId === FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS && id === COLUMNS.OPTION) {
    newSelectedOptions = _compact(_map(tableData, (item) => _head(_get(item, COLUMNS.OPTION))));
  }

  setState({ formValues: { ...formValues, [configId]: [...tableData] }, selectedFields: newSelectedFields, selectedOptions: newSelectedOptions });
};

const handleConfigTableRemoveRow = ({ setState, params, getState }) => {
  const { formValues, selectedFields, selectedOptions } = getState();
  const { row, configId } = params;
  let newSelectedFields = selectedFields;
  let newSelectedOptions = selectedOptions;

  const tableData = _get(formValues, configId, EMPTY_ARRAY);
  _remove(tableData, (val, index) => index === row);
  if (configId === FIELD_IDS.DEPENDENCY_CONFIG) {
    newSelectedFields = _compact(_map(tableData, (item) => _head(_get(item, COLUMNS.FIELD_NAME))));
  }
  if (configId === FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS) {
    newSelectedOptions = _compact(_map(tableData, (item) => _head(_get(item, COLUMNS.OPTION))));
  }
  setState({
    formValues: { ...formValues, [configId]: [...tableData] },
    selectedFields: newSelectedFields,
    selectedOptions: newSelectedOptions,
  });
};

const handleConfigTableAddRow = ({ params, setState, getState }) => {
  const { formValues } = getState();
  const { configId } = params;
  const tableData = _get(formValues, configId, EMPTY_ARRAY);

  setState({ formValues: { ...formValues, [configId]: [...tableData, {}] } });
};

const handleBlur = async ({ setState, getState, params }) => {
  const { formValues, errors } = getState();
  const id = _get(params, 'id', EMPTY_STRING);
  if (id === FIELD_IDS.TEST_REGEX_INPUT_STRING) {
    const text = _get(formValues, FIELD_IDS.TEST_REGEX_INPUT_STRING);
    const regex = _get(formValues, FIELD_IDS.TEXT_REGEX);
    if (!_isEmpty(text) && !_isEmpty(regex)) {
      const data = await matchRegex({ regex, text });
      if (data === false) {
        setState({
          errors: { ...errors, [FIELD_IDS.TEST_REGEX_INPUT_STRING]: __("The text provided by you doesn't matches the regex provided above") },
        });
      } else if (data === true) {
        setState({
          errors: { ...errors, [FIELD_IDS.TEST_REGEX_INPUT_STRING]: undefined },
        });
      }
    }
  }
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.ON_FIELD_BLUR]: handleBlur,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL]: handleOnCancel,
  [ACTION_TYPES.INIT_FORM]: handleInitForm,
  [ACTION_TYPES.OPTION_TABLE_ON_CHANGE]: handleOptionTableOnChange,
  [ACTION_TYPES.OPTION_TABLE_REMOVE_ROW]: handleOptionTableRemoveRow,
  [ACTION_TYPES.OPTION_TABLE_SAVE_ROW]: handleSaveRow,
  [ACTION_TYPES.OPTION_TABLE_ADD_ROW]: handleOptionTableAddRow,
  [ACTION_TYPES.OPTION_TABLE_MODAL_CLOSE]: handleOptionTableModalClose,
  [ACTION_TYPES.CONFIG_TABLE_ON_CHANGE]: handleConfigTableOnChange,
  [ACTION_TYPES.CONFIG_TABLE_REMOVE_ROW]: handleConfigTableRemoveRow,
  [ACTION_TYPES.CONFIG_TABLE_ADD_ROW]: handleConfigTableAddRow,
};

export default ACTION_HANDLERS;
