import React, { useCallback, useState } from 'react';
import cx from 'classnames';

import _get from 'lodash/get';

import Modal from '@tekion/tekion-components/molecules/Modal';
import Button from '@tekion/tekion-components/atoms/Button';
import Loader from '@tekion/tekion-components/molecules/loader';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';

import HCaptchaRenderer from '../atoms/hCaptchaRenderer/hCaptchaRenderer';
import getFormContainer from '../AuthenticationForm/AuthenticationFormContainer';

import { sendResetPasswordMail } from './resetPasswordPage.action';

import { redirectToLogin } from '../../WithAuth.helpers';
import ACTION_HANDLER from './resetPasswordPage.actionHandler';
import { FORM_SECTION, FORM_CONFIG } from './resetPasswordPage.config';
import { ACTION_TYPES, FIELD_NAMES, FORGOT_PASSWORD_CONTEXT_ID } from './resetPasswordPage.constants';

import styles from '../AuthenticationForm/authentication.module.scss';

const ResetPasswordFormContainer = getFormContainer(ACTION_HANDLER);

const ResetPasswordPage = () => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalContent, setShowModalContent] = useState(true);
  const [stateFormValues, setStateFormValues] = useState({});

  const handleAction = useCallback(
    async (action) => {
      const { type, payload } = action;
      switch (type) {
        case ACTION_TYPES.RESEND_RESET_EMAIL: {
          const { captchaToken } = payload;
          const userName = _get(stateFormValues, `${FIELD_NAMES.USERNAME}`, '');
          setShowModalContent(false);
          sendResetPasswordMail({ email: userName, captchaToken })
            .then(() => {
              setShowModalContent(true);
            })
            .catch((error) => {
              toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Something went wrong. Please try again later')));
              setShowModal(false);
              setShowModalContent(true);
            });

          break;
        }
        case ACTION_TYPES.RESET_PASSWORD: {
          const { captchaToken } = payload;
          triggerSubmit(FORGOT_PASSWORD_CONTEXT_ID, { captchaToken });
          break;
        }
        default:
      }
    },
    [stateFormValues],
  );

  const onSubmit = useCallback((formValues) => {
    const userName = _get(formValues, `${FIELD_NAMES.USERNAME}`, '');
    const captchaToken = _get(formValues, FIELD_NAMES.CAPTCHA_TOKEN, '');

    setIsBtnLoading(true);
    sendResetPasswordMail({ email: userName, captchaToken })
      .then(() => {
        setIsBtnLoading(false);
        setShowModal(true);
        setStateFormValues(formValues);
      })
      .catch((error) => {
        toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Something went wrong. Please try again later')));
        setIsBtnLoading(false);
      });
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
    setShowModalContent(true);
  }, []);

  const handleBackToLoginClick = useCallback(() => {
    redirectToLogin();
  }, []);

  const renderActionComponent = useCallback(
    () => (
      <>
        <HCaptchaRenderer
          view={Button.VIEW.PRIMARY}
          loading={isBtnLoading}
          label={__('Reset Password')}
          className={styles.loginButton}
          onAction={handleAction}
          actionType={ACTION_TYPES.RESET_PASSWORD}
        />
        <Button view={Button.VIEW.TERTIARY} className={cx(styles.loginButton, styles.backToLogin)} onClick={handleBackToLoginClick}>
          {__('Back to login')}
        </Button>
      </>
    ),
    [handleAction, handleBackToLoginClick, isBtnLoading],
  );

  const renderModalContent = () => {
    const email = _get(stateFormValues, `${FIELD_NAMES.USERNAME}`, '');
    if (!showModalContent) {
      return (
        <div className="full-width full-height">
          <div className="flex-center">{__('Sending mail')}</div>
          <Loader />
        </div>
      );
    }

    return (
      <>
        <div className={cx(styles.loginButton, styles.modalContent, styles.backToLogin, styles.heading)}>
          {__('We have sent you an email link to verify your account')}
        </div>
        <div className={cx(styles.flexCenter, styles.loginButton, styles.modalContent, styles.emailContainer)}>
          <div className={styles.content}>{__('Verification email link sent to')}</div>
          <div className={styles.email}>{email}</div>
        </div>
        <div className={cx(styles.loginButton, styles.modalContent, styles.emailLink)}>{__("Didn't receive email link?")}</div>
        <HCaptchaRenderer
          view={Button.VIEW.TERTIARY}
          className={cx(styles.loginButton, styles.emailContainer)}
          label={__('Resend email link')}
          onAction={handleAction}
          actionType={ACTION_TYPES.RESEND_RESET_EMAIL}
        />
      </>
    );
  };

  return (
    <>
      <ResetPasswordFormContainer
        contextId={FORGOT_PASSWORD_CONTEXT_ID}
        getFields={FORM_CONFIG}
        getSections={FORM_SECTION}
        renderActionComponent={renderActionComponent}
        onSubmit={onSubmit}
      />
      <Modal visible={showModal} hideFooter content={renderModalContent()} width={Modal.SIZES.MD} onCancel={closeModal} />
    </>
  );
};

export default ResetPasswordPage;
