import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _get from 'lodash/get';
import _head from 'lodash/head';
import _split from 'lodash/split';
import _find from 'lodash/find';

import ListColumn from '../../atoms/listColumn/ListColumn';
import ViewComponent from '../../atoms/viewComponent/ViewComponent';
import DropZone from '../../atoms/dropZone/DropZone';
import DropZoneContainer from '../../atoms/dropZone/DropZoneContainer';

import { COMPONENT_TYPES, DEFAULT_WIDTH } from '../../constants/viewBuilder.constants';
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';

import { CELL_TYPES, ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

import styles from './listBuilderViewRenderer.module.scss';

const ListBuilderViewRenderer = (props) => {
  const {
    className,
    componentConfig: { sectionId, children },
    entity,
  } = props;

  return (
    <div key={sectionId} className={cx(styles.listBuilderViewRenderer, className)}>
      {children.map((columnConfig, index) => {
        const properties = _get(columnConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, {});
        const sectionColumnWidth = _get(properties, ALL_VIEW_PROPERTY_KEYS.WIDTH, DEFAULT_WIDTH);

        let isEditEnabled = false;
        const childComponentCellType = _get(columnConfig, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.CELL_TYPE}`);
        if (childComponentCellType === CELL_TYPES.COMPOUND) isEditEnabled = true;
        else {
          const fieldNames = _get(columnConfig, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.FIELD_NAMES}.[0]`);
          const fieldName = _head(_split(fieldNames, '.'));
          const fieldDefinition = _find(_get(entity, 'fieldDefinitions'), ['name', fieldName]);
          isEditEnabled =
            fieldDefinitionReader.fieldType(fieldDefinition) === FIELD_TYPES.RELATIONSHIP ||
            fieldDefinitionReader.dataType(fieldDefinition) === DATA_TYPES.COMPLEX;
        }

        return (
          <React.Fragment key={columnConfig.sectionId}>
            <DropZone
              id={sectionId}
              index={index}
              acceptedComponentTypes={[COMPONENT_TYPES.LIST_VIEW_COLUMN]}
              className={styles.dropZoneGutter}
              hiddenDropZoneClassName={styles.hiddenDropZone}
              onHoverClassName={styles.onHoverDropZoneGutter}
            />

            <ViewComponent
              key={columnConfig.sectionId}
              style={{ width: `${sectionColumnWidth}%` }}
              index={index}
              componentConfig={columnConfig}
              isEditEnabled={isEditEnabled}
              className={styles.viewComponent}
            >
              <ListColumn componentConfig={columnConfig} />
            </ViewComponent>
          </React.Fragment>
        );
      })}

      <DropZoneContainer
        id={sectionId}
        index={children.length}
        acceptedComponentTypes={[COMPONENT_TYPES.LIST_VIEW_COLUMN]}
        className={styles.dropZoneContainer}
        onHoverClassName={styles.onHoverDropZoneGutter}
      />
    </div>
  );
};

ListBuilderViewRenderer.propTypes = {
  className: PropTypes.string,
  componentConfig: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
};

ListBuilderViewRenderer.defaultProps = {
  className: '',
};

export default React.memo(ListBuilderViewRenderer);
