import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { tget } from '@tekion/tekion-base/utils/general';
import Heading from '@tekion/tekion-components/atoms/Heading/Heading';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Button from '@tekion/tekion-components/atoms/Button';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

import FieldValuePair from '../approvalRequestDetails/components/fieldValuePair/FieldValuePair';

import { APPROVAL_CENTRE_FIELD_IDS, APPROVAL_STATUS_TYPES } from '../../../../../../../constants/approvalCentre.constants';
import ACTION_TYPES from '../../constants/approvalRequestDetailsPage.actionTypes';

import styles from './stageApproval.module.scss';

const StageApproval = ({ isApproveLoading, isRejectLoading, approvalTask, onAction }) => {
  const createdBy = tget(approvalTask, APPROVAL_CENTRE_FIELD_IDS.CREATED_BY);
  const approvalLevel = tget(approvalTask, APPROVAL_CENTRE_FIELD_IDS.APPROVAL_LEVEL);
  const validity = tget(approvalTask, APPROVAL_CENTRE_FIELD_IDS.VALIDITY);
  const status = tget(approvalTask, APPROVAL_CENTRE_FIELD_IDS.STATUS);
  const showActions = status === APPROVAL_STATUS_TYPES.PENDING;

  const handleApprove = useCallback(() => onAction({ type: ACTION_TYPES.APPROVE_REQUEST }), [onAction]);

  const handleClickReject = useCallback(() => onAction({ type: ACTION_TYPES.REJECT_REQUEST }), [onAction]);

  return (
    <div className={styles.headerContentContainer}>
      <div className={styles.headerBodyContainer}>
        <Heading className="p-b-8" size={3}>
          {__('Stage Approval')}
        </Heading>
        <div className={styles.requestDetailsContainer}>
          <FieldValuePair label={__('Requested by')} value={createdBy} />
          <FieldValuePair label={__('Stage level')} value={approvalLevel + 1} />
          <FieldValuePair label={__('Stage valid till')} value={validity} />
        </div>
      </div>
      <PropertyControlledComponent controllerProperty={showActions}>
        <div className={styles.buttonsContainer}>
          <Button
            className={`m-r-8 ${styles.button} ${styles.greenColor}`}
            loading={isApproveLoading}
            view={Button.VIEW.SECONDARY}
            onClick={handleApprove}
          >
            <FontIcon size={FontIcon.SIZES.MD_S} className="p-r-8">
              icon-tick1
            </FontIcon>
            {__('Approve')}
          </Button>
          <Button
            className={`${styles.button} ${styles.redColor}`}
            loading={isRejectLoading}
            view={Button.VIEW.SECONDARY}
            onClick={handleClickReject}
          >
            <FontIcon size={FontIcon.SIZES.MD_S} className="p-r-8">
              icon-close2
            </FontIcon>
            {__('Reject')}
          </Button>
        </div>
      </PropertyControlledComponent>
    </div>
  );
};

StageApproval.propTypes = {
  isApproveLoading: PropTypes.bool,
  isRejectLoading: PropTypes.bool,
  approvalTask: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

StageApproval.defaultProps = {
  isApproveLoading: false,
  isRejectLoading: false,
  approvalTask: EMPTY_OBJECT,
};

export default StageApproval;
