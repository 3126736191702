exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".visualBuilderMetadataPage_body__mhPWT13nW4{overflow-y:scroll}.visualBuilderMetadataPage_form__daFgwRBJgk{max-width:130rem;height:calc(100% - 5rem)}.visualBuilderMetadataPage_formSection__jQyT2NDVxq{max-width:130rem}.visualBuilderMetadataPage_formSectionHeader__uyBs1CvUhF{font-size:1.8rem;max-width:100rem}.visualBuilderMetadataPage_footerContainer__iCE4fXzJdt{height:6.4rem}.visualBuilderMetadataPage_halfColumn__3NhHKU3mye{width:calc((100% - 2.4rem)/2)}.visualBuilderMetadataPage_header__tBAChTNqHY{justify-content:space-between}", ""]);

// Exports
exports.locals = {
	"body": "visualBuilderMetadataPage_body__mhPWT13nW4",
	"form": "visualBuilderMetadataPage_form__daFgwRBJgk",
	"formSection": "visualBuilderMetadataPage_formSection__jQyT2NDVxq",
	"formSectionHeader": "visualBuilderMetadataPage_formSectionHeader__uyBs1CvUhF",
	"footerContainer": "visualBuilderMetadataPage_footerContainer__iCE4fXzJdt",
	"halfColumn": "visualBuilderMetadataPage_halfColumn__3NhHKU3mye",
	"header": "visualBuilderMetadataPage_header__tBAChTNqHY"
};