import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _get from 'lodash/get';

import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';

import { getSimpleFieldNameFromColumn } from './widgetContainer.helpers';
import FIELD_TYPES from '../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../constants/fieldDefinition.dataTypes';
import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';

const getFilterForSelect = (filter, options) => ({
  ...filter,
  type: FILTER_TYPES.MULTI_SELECT,
  additional: {
    options: _map(options, (option) => ({
      label: _get(option, 'displayName'),
      value: _get(option, 'value'),
      additional: {
        ...option,
      },
    })),
  },
});

const getFilterForSimpleColumn = (fieldName, displayName, fieldDef) => {
  const fieldType = _get(fieldDef, 'fieldType');
  const dataType = _get(fieldDef, 'dataType');

  const filter = {
    id: fieldName,
    name: displayName,
    type: FILTER_TYPES.STRING,
  };
  const options = fieldDefinitionReader.options(fieldDef);

  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      switch (dataType) {
        case DATA_TYPES.DATE:
        case DATA_TYPES.DATE_TIME:
          return { ...filter, type: FILTER_TYPES.DATE };
        case DATA_TYPES.NUMBER:
          return { ...filter, type: FILTER_TYPES.NUMBER };
        default: {
          return filter;
        }
      }

    case FIELD_TYPES.SELECT:
      return getFilterForSelect(filter, options);

    default: {
      return filter;
    }
  }
};

const getFilters = (filtersToRender, fieldDefinitionByName) =>
  _compact(
    _map(filtersToRender, (configColumn) => {
      const fieldName = _get(configColumn, 'field', '');
      const fieldDef = _get(fieldDefinitionByName, getSimpleFieldNameFromColumn(fieldName), {});
      if (
        _get(fieldDef, 'filterable') === false ||
        _get(fieldDef, 'fieldType') === FIELD_TYPES.RELATIONSHIP ||
        _get(fieldDef, 'dataType') === DATA_TYPES.COMPLEX
      ) {
        return null;
      }
      const displayName = _get(fieldDef, 'displayName');
      const filter = getFilterForSimpleColumn(fieldName, displayName, fieldDef);

      return filter;
    }),
  );

const createFilterProps = ({ selectedFilters, filtersToRender, fieldDefinitionByName }) => {
  const filters = getFilters(filtersToRender, fieldDefinitionByName);
  const defaultFilterTypes = _map(filters, (filter) => filter.id);

  return {
    filterTypes: filters,
    defaultFilterTypes,
    selectedFilters,
    showReset: false,
    showClear: true,
    showDefaultFilter: false,
    showSortTrigger: false,
  };
};

export { createFilterProps };
