import React, { useEffect, useMemo } from 'react';
import compose from 'recompose/compose';
import renameProps from 'recompose/renameProps';
import PropTypes from 'prop-types';

import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import COMPLEX_FIELD_DETAILS_FORM_ACTION_HANDLERS from './helpers/complexFieldDetailsForm.actionHandlers';
import getFields from './helpers/complexFieldDetailsForm.fields';

import COMPLEX_FIELD_DETAILS_FORM_SECTIONS from './constants/complexFieldDetailsForm.sections';
import FIELDS_FORM_FIELD_IDS from '../../constants/fieldsForm.fieldIds';
import ACTION_TYPES from '../../constants/fieldsForm.actionTypes';

const ComplexFieldDetailsForm = ({ isInitialStateLoaded, values, error, renderOptions, onAction }) => {
  const fields = useMemo(() => getFields(renderOptions), [renderOptions]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_RENDER_OPTIONS_CHANGE, params: { renderOptions } });
  }, [renderOptions, onAction]);

  return (
    <FormWithSubmission
      className="full-width"
      isFetching={!isInitialStateLoaded}
      contextId={FIELDS_FORM_FIELD_IDS.COMPLEX_FIELD_DETAILS_FORM}
      errors={error}
      values={values}
      fields={fields}
      sections={COMPLEX_FIELD_DETAILS_FORM_SECTIONS}
      onAction={onAction}
    />
  );
};

ComplexFieldDetailsForm.propTypes = {
  isInitialStateLoaded: PropTypes.bool,
  values: PropTypes.object,
  error: PropTypes.object,
  renderOptions: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ComplexFieldDetailsForm.defaultProps = {
  isInitialStateLoaded: false,
  values: EMPTY_OBJECT,
  error: EMPTY_OBJECT,
  renderOptions: EMPTY_OBJECT,
};

export default compose(
  renameProps({ onAction: 'fieldsFormOnAction', value: 'fieldsFormComplexDetailsValues' }),
  withActions(EMPTY_OBJECT, COMPLEX_FIELD_DETAILS_FORM_ACTION_HANDLERS),
)(ComplexFieldDetailsForm);
