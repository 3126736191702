import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import EventHandler from '../../../../../../molecules/eventHandler';
import { eventHandlerValidator } from '../../../../../../molecules/eventHandler/eventHandler.helpers';

import { FIELD_IDS } from './eventHandlerModal.constants';

const EVENT_HANDLER_FIELD = {
  id: FIELD_IDS.EVENT_HANDLER,
  renderer: EventHandler,
  renderOptions: {
    validators: [eventHandlerValidator],
  },
};

const FIELDS = {
  [FIELD_IDS.EVENT_HANDLER]: EVENT_HANDLER_FIELD,
};

const getFields = (entityDef, eventTypeOptions) => ({
  ...FIELDS,
  [FIELD_IDS.EVENT_HANDLER]: addToRenderOptions(FIELDS[FIELD_IDS.EVENT_HANDLER], [
    { path: 'eventTypeOptions', value: eventTypeOptions },
    { path: 'entityDef', value: entityDef },
  ]),
});

export { getFields };
