import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _size from 'lodash/size';

// Components
import BaseTable from '@tekion/tekion-components/molecules/table/BaseTable';
import Button from '@tekion/tekion-components/atoms/Button';
import Heading from '@tekion/tekion-components/atoms/Heading';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { getColumns } from './helpers/configTable.columns';
import ACTION_TYPES from '../../constants/tenantUserForm.actionTypes';

import styles from './configTable.module.scss';

const ConfigTable = ({ homeWorkspaceIndex, workspaceList, userWorkspaceList, selectedWorkspaces, onAction }) => {
  const columns = getColumns();

  const handleAddUserWorkspace = () => {
    onAction({ type: ACTION_TYPES.ON_USER_WORKSPACE_ADD });
  };

  const getTdProps = useCallback(
    () => ({ workspaceList, selectedWorkspaces, onAction, homeWorkspaceIndex }),
    [workspaceList, selectedWorkspaces, onAction, homeWorkspaceIndex],
  );

  return (
    <div className={styles.userWorkspaceContainer}>
      <div className={styles.addWorkspaceContainer}>
        <Heading size={2} className={styles.heading}>
          {__('Select Workspace')}
        </Heading>
        <Button view={Button.VIEW.PRIMARY} className={styles.button} onClick={handleAddUserWorkspace}>
          {__('Add New')}
        </Button>
      </div>
      <BaseTable
        showPagination={false}
        columns={columns}
        data={userWorkspaceList}
        rowHeight={40}
        pageSize={_size(userWorkspaceList)}
        getTdProps={getTdProps}
      />
    </div>
  );
};

ConfigTable.propTypes = {
  homeWorkspaceIndex: PropTypes.number,
  workspaceList: PropTypes.array,
  userWorkspaceList: PropTypes.array,
  selectedWorkspaces: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ConfigTable.defaultProps = {
  homeWorkspaceIndex: 0,
  workspaceList: EMPTY_ARRAY,
  userWorkspaceList: EMPTY_ARRAY,
  selectedWorkspaces: EMPTY_ARRAY,
};

export default React.memo(ConfigTable);
