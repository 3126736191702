const DELAY_TASK_TAB_IDS = {
  TIME_DELAY: 'TIME_DELAY',
  ADD_DELAY_EXIT_EVENT: 'ADD_DELAY_EXIT_EVENT',
  ADD_WORKFLOW_EXIT_EVENT: 'ADD_WORKFLOW_EXIT_EVENT',
};

const DELAY_TASK_TABS = {
  TIME_DELAY: {
    id: DELAY_TASK_TAB_IDS.TIME_DELAY,
    displayName: __('Time delay'),
    type: DELAY_TASK_TAB_IDS.TIME_DELAY,
    values: {},
    errors: {},
  },
  ADD_DELAY_EXIT_EVENT: {
    id: DELAY_TASK_TAB_IDS.ADD_DELAY_EXIT_EVENT,
    displayName: __('Add delay exit event'),
  },
  ADD_WORKFLOW_EXIT_EVENT: {
    id: DELAY_TASK_TAB_IDS.ADD_WORKFLOW_EXIT_EVENT,
    displayName: __('Add workflow exit event'),
  },
};

export { DELAY_TASK_TAB_IDS, DELAY_TASK_TABS };
