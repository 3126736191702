import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _get from 'lodash/get';

// Tekion-base
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Tekion-components
import Button from '@tekion/tekion-components/atoms/Button';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

// Styles
import styles from './sideNavItem.module.scss';

const SideNavItem = (props) => {
  const { onItemClick, data, isExpand, isActive, isClickDisabled } = props;

  const onClick = useCallback(() => {
    if (!isClickDisabled) {
      onItemClick(_get(data, 'goTo', EMPTY_STRING));
    }
  }, [onItemClick, data, isClickDisabled]);

  const name = _get(data, 'name', EMPTY_STRING);
  const containerClassName = isExpand ? styles.containerExpand : styles.container;
  const clickDisabledClassName = isClickDisabled ? styles.clickDisabled : EMPTY_STRING;

  return (
    <div className={cx(containerClassName, clickDisabledClassName)} onClick={onClick}>
      <Button view={Button.VIEW.ICON}>
        <FontIcon className={isActive ? styles.activeIcon : styles.icon} size={SIZES.XXL} disabled={isClickDisabled}>
          icon-box-filled
        </FontIcon>
      </Button>
      <div className={styles.content}>{name}</div>
    </div>
  );
};

SideNavItem.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  data: PropTypes.object,
  isExpand: PropTypes.bool,
  isActive: PropTypes.bool,
  isClickDisabled: PropTypes.bool,
};

SideNavItem.defaultProps = {
  data: EMPTY_OBJECT,
  isExpand: false,
  isActive: false,
  isClickDisabled: false,
};

export default SideNavItem;
