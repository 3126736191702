// Constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

export const INITIAL_STATE = {
  visible: false,
  assetId: undefined,
  assetType: undefined,
  auditParams: EMPTY_OBJECT,
};

export const BODY_STYLE = {
  paddingBottom: 80,
  padding: 0,
};

export const BODY_DIV_ID = 'auditLogs';
