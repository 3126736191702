import _property from 'lodash/property';
import _get from 'lodash/get';

// Components
import ColumnConfig from '@tekion/tekion-components/organisms/inputTable/builders/ColumnConfig';
import CellConfig from '@tekion/tekion-components/organisms/inputTable/builders/CellConfig';
import TextDisplayCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/TextDisplayCellRenderer';

// Constants
import { COLUMN_IDS } from '../constants/userListTable.general';

const NAME_CELL = new CellConfig().setComponent(TextDisplayCellRenderer);

const EMAIL_CELL = new CellConfig().setComponent(TextDisplayCellRenderer);

const ROLE_CELL = new CellConfig().setComponent(TextDisplayCellRenderer);

const nameAccessor = _property(COLUMN_IDS.NAME);
const emailAccessor = _property(COLUMN_IDS.EMAIL);
const roleAccessor = _property(COLUMN_IDS.ROLE);

const onValueChange = (data, e) => ({
  row: _get(data, 'rowIndex'),
  value: _get(e, 'target.value', ''),
});

const NAME_COLUMN_CONFIG = new ColumnConfig(COLUMN_IDS.NAME).setHeader(__('Name')).setAccessor(nameAccessor).addCellConfig(NAME_CELL);

const EMAIL_COLUMN_CONFIG = new ColumnConfig(COLUMN_IDS.EMAIL).setHeader(__('Email')).setAccessor(emailAccessor).addCellConfig(EMAIL_CELL);

const ROLE_COLUMN_CONFIG = new ColumnConfig(COLUMN_IDS.ROLE).setHeader(__('Role')).setAccessor(roleAccessor).addCellConfig(ROLE_CELL);

export { nameAccessor, emailAccessor, roleAccessor, onValueChange, NAME_COLUMN_CONFIG, EMAIL_COLUMN_CONFIG, ROLE_COLUMN_CONFIG };
