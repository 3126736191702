import { WIDGET_TYPES } from '../../../constants/visualBuilder.general.constants';

const WIDGET_PANELS = {
  WIDGETS: 'WIDGETS',
  ELEMENTS: 'ELEMENTS',
  // PAGES: 'PAGES',
};

const WIDGETS_PANEL_HEADER_NAME = {
  [WIDGET_PANELS.ELEMENTS]: __('Elements'),
  [WIDGET_PANELS.WIDGETS]: __('Widgets'),
  [WIDGET_PANELS.PAGES]: __('Pages'),
};

const PANEL_ITEMS_NAME = {
  [WIDGET_TYPES.BUTTON_WIDGET]: __('Button Widget'),
  [WIDGET_TYPES.TAB_WIDGET]: __('Tab Widget'),
  [WIDGET_TYPES.TEXT_WIDGET]: __('Text Widget'),
  [WIDGET_TYPES.SIMILAR_WIDGET]: __('Similar Widget'),
  [WIDGET_TYPES.PARENT_WIDGET]: __('Parent Widget'),
  [WIDGET_TYPES.CHILD_WIDGET]: __('Child Widget'),
  [WIDGET_TYPES.STANDARD_WIDGET]: __('Standard Widget'),
};

export { WIDGETS_PANEL_HEADER_NAME, WIDGET_PANELS, PANEL_ITEMS_NAME };
