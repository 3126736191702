// TODO: remove duplicate from tekion-core

import { useEffect, useRef } from 'react';
import Hls from 'hls.js';

// Loaders
import fLoader from './loaders/floader';

const CONFIG = {
  fLoader,
};

const initialiseHlsPlayer = (videoRef, src, hls) => {
  const videoEl = videoRef.current;
  hls.attachMedia(videoEl);

  hls.on(Hls.Events.MEDIA_ATTACHED, () => {
    hls.loadSource(src);
  });
};

const useVideoStreaming = ({ videoRef, src }) => {
  const hlsRef = useRef();
  useEffect(() => {
    const isHlsSupported = Hls.isSupported();
    const existingHlsInstance = hlsRef.current;

    // If HLS is not supported then do not do anything
    if (!isHlsSupported) {
      return;
    }
    // If hls instance is already present then destroy the existing instance.
    if (existingHlsInstance) {
      existingHlsInstance.destroy();
    }
    hlsRef.current = new Hls(CONFIG);
    initialiseHlsPlayer(videoRef, src, hlsRef.current);
  }, [videoRef, src]);
};

export default useVideoStreaming;
