import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import _values from 'lodash/values';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _forEach from 'lodash/forEach';
import _set from 'lodash/set';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Modal from '@tekion/tekion-components/molecules/Modal';
import { FormWithSubmission } from '@tekion/tekion-components/pages/formPage';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import ConditionModal from '../conditionModal/ConditionModal';

import { getFormSections, getFormFields } from './helpers/conditionChangeToModal.formConfig';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import { TITLE_BY_TARGET, CONTEXT_ID } from './constants/conditionChangeToModal.general';
import { CONDITION_TYPES } from '../../../constants/workflow.constants';
import { FIELD_IDS } from './constants/conditionChangeToModal.fields';

const ConditionChangeToModal = ({
  targetType,
  pathOptions,
  sourceType,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  stepDetails,
  onCancel,
  onSubmit,
}) => {
  const [values, setValue] = useState({ [FIELD_IDS.YES_PATH]: null, [FIELD_IDS.NO_PATH]: null });
  const [errors, setErrors] = useState();
  const [newPathOptions, setNewPathOptions] = useState(pathOptions);

  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload = EMPTY_OBJECT } = action;
    switch (type) {
      case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
        const { value: updatedValue, id } = payload;
        setValue({ ...values, [id]: updatedValue });
        break;
      }
      case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
        const { errors: newErrors } = payload;
        setErrors(newErrors);
        break;
      }
      default:
    }
  };

  const formFields = useMemo(
    () => getFormFields({ targetType, sourceType, values, pathOptions: newPathOptions }),
    [targetType, sourceType, values, newPathOptions],
  );

  const formSections = useMemo(() => getFormSections(targetType, sourceType), [targetType, sourceType]);

  const handleSubmit = useCallback(() => {
    setValue({});
    setErrors({});

    if (targetType === CONDITION_TYPES.IF) {
      onSubmit({ true: values[FIELD_IDS.YES_PATH] });
    } else if (targetType === CONDITION_TYPES.SWITCH) {
      if (_size(newPathOptions) <= 1) {
        toaster(TOASTER_TYPE.ERROR, __('Please fill data in all fields.'));
        return;
      }

      const nodeTransferConfig = {};
      _forEach(newPathOptions, (option) => {
        _set(nodeTransferConfig, option.value, null);
      });
      _set(nodeTransferConfig, values[FIELD_IDS.YES_PATH], 'true');

      if (values[FIELD_IDS.NO_PATH]) {
        _set(nodeTransferConfig, values[FIELD_IDS.NO_PATH], 'false');
      }
      _set(stepDetails, 'userData', { data: { ...values[FIELD_IDS.CONDITION] } });
      onSubmit(nodeTransferConfig);
    } else {
      onSubmit(values);
    }
  }, [values, onSubmit, targetType, newPathOptions, stepDetails]);

  const handleCancel = () => {
    setValue({});
    setErrors({});
    onCancel();
  };

  const handleConditionModalChange = (data) => {
    const criteria = getArraySafeValue(_get(data, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, []));
    const optionValues = _get(criteria, CONDITION_FIELD_IDS.VALUES, EMPTY_ARRAY);
    const options = _map(optionValues, (optionValue) => ({ label: optionValue, value: optionValue }));
    if (targetType === CONDITION_TYPES.SWITCH) {
      setValue({ ...values, [FIELD_IDS.CONDITION]: data });
    }
    setNewPathOptions([...options, ...pathOptions]);
  };

  return (
    <Modal
      visible
      destroyOnClose
      title={TITLE_BY_TARGET[targetType]}
      submitBtnText={__('Change')}
      width={targetType === CONDITION_TYPES.SWITCH ? Modal.SIZES.XL : Modal.SIZES.SM}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    >
      <PropertyControlledComponent controllerProperty={targetType === CONDITION_TYPES.SWITCH}>
        <ConditionModal
          renderContentOnly
          stepDetails={stepDetails}
          targetType={targetType}
          conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
          mapOfVariableToEntityName={mapOfVariableToEntityName}
          onChangeConditionModal={handleConditionModalChange}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </PropertyControlledComponent>
      <FormWithSubmission
        sections={formSections}
        fields={formFields}
        contextId={CONTEXT_ID}
        values={values}
        errors={errors}
        onAction={handleAction}
      />
    </Modal>
  );
};

ConditionChangeToModal.propTypes = {
  targetType: PropTypes.oneOf(_values(CONDITION_TYPES)),
  sourceType: PropTypes.oneOf(_values(CONDITION_TYPES)),
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  stepDetails: PropTypes.object,
  pathOptions: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ConditionChangeToModal.defaultProps = {
  targetType: CONDITION_TYPES.IF,
  sourceType: CONDITION_TYPES.IF,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  stepDetails: EMPTY_OBJECT,
  pathOptions: EMPTY_ARRAY,
};

export default ConditionChangeToModal;
