import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _find from 'lodash/find';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import CreatableSelect from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import TextInputWithTags from '../../../../../../../atoms/TextInputWithTagsField';
import ValueField from '../../../../../../../organisms/conditionBuilder/atoms/valueField/ValueField';
import TemplateSection from '../../../../../../../organisms/templateSection';

import { getSourceFieldOptions, getDerivedFieldOptions, getTemplateValueFieldProps } from './sendEmailsActionDefinitionSection.helpers';
import { getStaticToFieldSelectProps } from '../../../../../../../helpers/actionBuilder.helpers';

import { SEND_EMAILS_ADDRESSEE_TYPE_OPTIONS, SEND_EMAILS_ADDRESSEE_TYPE } from './sendEmailsActionDefinitionSection.constants';
import { SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS } from '../../../../../../../constants/actionBuilder.constants';
import { ACTION_BUILDER_ACTION_TYPES } from '../../constants/actionBuilderForm.general';
import { TEMPLATE_TYPES } from '../../../../../../../constants/templateBuilder.constants';
import { NAMESPACES } from '../../../../../../../constants/general.constants';

import styles from './sendEmailsActionDefinitionSection.module.scss';

const SendEmailsActionDefinitionSection = ({
  isMultipleTemplateSelectAllowed,
  id,
  actionType,
  sourceEntityName,
  value,
  templateDefinitions,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  onAction,
}) => {
  const fromValue = value[SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.FROM];
  const toValue = value[SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.TO];
  const ccValue = value[SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.CC];
  const bccValue = value[SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.BCC];
  const senderDisplayName = value[SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.SENDER_DISPLAY_NAME];
  const sourceField = value[SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.SOURCE_FIELD];
  const emailTemplateSectionValue = value[SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES];
  const addresseeType = value[SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.ADDRESSEE_TYPE];
  const derivedEntityField = value[SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY_FIELD];

  const sourceFieldOptions = useMemo(
    () => getSourceFieldOptions(conditionBuilderFieldDefinitionObject[sourceEntityName]),
    [conditionBuilderFieldDefinitionObject, sourceEntityName],
  );

  const toOptions = useMemo(
    () => getStaticToFieldSelectProps(conditionBuilderFieldDefinitionObject[sourceEntityName], toValue),
    [toValue, sourceEntityName, conditionBuilderFieldDefinitionObject],
  );

  const sourceFieldEntityName = useMemo(() => {
    const sourceFieldDefs = conditionBuilderFieldDefinitionObject[sourceEntityName];
    const sourceFieldDef = _get(sourceFieldDefs, sourceField, EMPTY_OBJECT);
    return _get(sourceFieldDef, 'lookupField.entityType');
  }, [conditionBuilderFieldDefinitionObject, sourceEntityName, sourceField]);

  const updatedMapOfVariableToEntityName = useMemo(() => {
    if (_isEmpty(mapOfVariableToEntityName)) {
      if (actionType === ACTION_BUILDER_ACTION_TYPES.SEND_EMAILS && addresseeType === SEND_EMAILS_ADDRESSEE_TYPE.DERIVED) {
        return { [NAMESPACES.CURRENT_RECORD]: sourceEntityName, [NAMESPACES.CURRENT_ADDRESSEE]: sourceFieldEntityName };
      }
      return { [NAMESPACES.CURRENT_RECORD]: sourceEntityName };
    }
    return mapOfVariableToEntityName;
  }, [actionType, addresseeType, mapOfVariableToEntityName, sourceEntityName, sourceFieldEntityName]);

  const handleAction = useCallback(
    (action) => {
      const { type, payload } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { id: fieldId, value: fieldValue } = payload;
          let updatedValue = { ...value, [fieldId]: fieldValue };

          if (fieldId === SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.SOURCE_FIELD) {
            const lookupFieldName = _get(_find(sourceFieldOptions, { value: getArraySafeValue(fieldValue) }), 'lookupField');
            updatedValue = { ...updatedValue, [SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY]: lookupFieldName };
          }

          onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id, value: updatedValue } });
          break;
        }

        default: {
          onAction(action);
          break;
        }
      }
    },
    [id, value, sourceFieldOptions, onAction],
  );

  const renderSendEmailsBody = useCallback(
    () => (
      <div>
        <Radio
          required
          id={SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.ADDRESSEE_TYPE}
          fieldClassName={fieldLayoutStyles.fieldC}
          label={__('Addressee Type')}
          radios={SEND_EMAILS_ADDRESSEE_TYPE_OPTIONS}
          value={addresseeType}
          onAction={handleAction}
        />
        {!_isEmpty(addresseeType) && (
          <div>
            <div
              className={addresseeType === SEND_EMAILS_ADDRESSEE_TYPE.STATIC ? styles.subContainer : `${styles.subContainer} ${styles.derivedEntity}`}
            >
              <ValueField
                required
                showFieldSwitcher
                isRelationshipRecursive={false}
                isComplexRecursive={false}
                id={SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.FROM}
                label={__('From')}
                fieldClassName={fieldLayoutStyles.fieldC}
                value={fromValue}
                mapOfVariableToEntityName={{ $record: sourceEntityName }}
                conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
                onAction={handleAction}
              />
              {addresseeType === SEND_EMAILS_ADDRESSEE_TYPE.STATIC && (
                <CreatableSelect
                  required
                  mode="tags"
                  id={SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.TO}
                  label={__('To')}
                  fieldClassName={fieldLayoutStyles.fieldC}
                  className={styles.customSelect}
                  value={toValue}
                  options={toOptions}
                  onAction={handleAction}
                />
              )}
            </div>
            <div className={styles.subContainer}>
              {addresseeType === SEND_EMAILS_ADDRESSEE_TYPE.DERIVED && (
                <>
                  <Select
                    required
                    id={SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.SOURCE_FIELD}
                    label={__('Source Field')}
                    fieldClassName={fieldLayoutStyles.fieldC}
                    value={sourceField}
                    options={sourceFieldOptions}
                    onAction={handleAction}
                  />
                  <Select
                    required
                    isDisabled={_isEmpty(sourceField)}
                    id={SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY_FIELD}
                    label={__('Derived Entity Field')}
                    fieldClassName={fieldLayoutStyles.fieldC}
                    value={derivedEntityField}
                    options={getDerivedFieldOptions(getArraySafeValue(sourceField), sourceEntityName, conditionBuilderFieldDefinitionObject)}
                    onAction={handleAction}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    ),
    [
      fromValue,
      toValue,
      sourceEntityName,
      addresseeType,
      sourceField,
      derivedEntityField,
      sourceFieldOptions,
      toOptions,
      conditionBuilderFieldDefinitionObject,
      handleAction,
    ],
  );

  const renderSendEmailBody = useCallback(
    () => (
      <>
        <div className={styles.subContainer}>
          <ValueField
            required
            showFieldSwitcher
            id={SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.FROM}
            label={__('From')}
            fieldClassName={fieldLayoutStyles.fieldC}
            value={fromValue}
            mapOfVariableToEntityName={updatedMapOfVariableToEntityName}
            conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
            onAction={handleAction}
          />
          <TextInput
            required
            id={SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.SENDER_DISPLAY_NAME}
            label={__('Sender Display Name')}
            value={senderDisplayName}
            fieldClassName={fieldLayoutStyles.fieldC}
            onAction={handleAction}
          />
        </div>
        <div className={styles.subContainer}>
          <ValueField
            required
            showFieldSwitcher
            keepAttributeAsArray
            id={SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.TO}
            label={__('To')}
            fieldClassName={fieldLayoutStyles.fieldC}
            value={toValue}
            mapOfVariableToEntityName={updatedMapOfVariableToEntityName}
            conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
            fieldRenderer={TextInputWithTags}
            onAction={handleAction}
          />
        </div>
        <div className={styles.subContainer}>
          <ValueField
            required
            showFieldSwitcher
            keepAttributeAsArray
            id={SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.CC}
            label={__('Cc')}
            fieldClassName={fieldLayoutStyles.fieldC}
            value={ccValue}
            mapOfVariableToEntityName={updatedMapOfVariableToEntityName}
            conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
            fieldRenderer={TextInputWithTags}
            onAction={handleAction}
          />
        </div>
        <div className={styles.subContainer}>
          <ValueField
            required
            showFieldSwitcher
            keepAttributeAsArray
            id={SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.BCC}
            label={__('Bcc')}
            fieldClassName={fieldLayoutStyles.fieldC}
            value={bccValue}
            mapOfVariableToEntityName={updatedMapOfVariableToEntityName}
            conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
            fieldRenderer={TextInputWithTags}
            onAction={handleAction}
          />
        </div>
      </>
    ),
    [fromValue, updatedMapOfVariableToEntityName, conditionBuilderFieldDefinitionObject, handleAction, toValue, ccValue, bccValue, senderDisplayName],
  );

  const templateValueFieldProps = useMemo(() => getTemplateValueFieldProps(actionType, addresseeType), [actionType, addresseeType]);

  return (
    <div className={styles.container}>
      {actionType === ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL ? renderSendEmailBody() : renderSendEmailsBody()}
      <TemplateSection
        isMultipleTemplateSelectAllowed={isMultipleTemplateSelectAllowed}
        id={SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES}
        templateType={TEMPLATE_TYPES.EMAIL}
        conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
        mapOfVariableToEntityName={updatedMapOfVariableToEntityName}
        value={emailTemplateSectionValue}
        templateValueFieldProps={templateValueFieldProps}
        templateDefinitions={templateDefinitions}
        onAction={handleAction}
      />
    </div>
  );
};

SendEmailsActionDefinitionSection.propTypes = {
  isMultipleTemplateSelectAllowed: PropTypes.bool,
  id: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  sourceEntityName: PropTypes.string.isRequired,
  value: PropTypes.object,
  templateDefinitions: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

SendEmailsActionDefinitionSection.defaultProps = {
  isMultipleTemplateSelectAllowed: true,
  value: EMPTY_OBJECT,
  templateDefinitions: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
};

export default SendEmailsActionDefinitionSection;
