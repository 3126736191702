import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';

import { RECORD_TYPE_LIST_COLUMN_IDS } from './viewBuilderList.columnIds';
import { VIEW_TYPES as VIEW_BUILDER_VIEW_TYPES } from '../../../../../constants/viewBuilder.constants';

export const INITIAL_STATE_VIEW_BUILDER_LIST_PROPS = {
  currentPage: 0,
  pageSize: 50,
};

const TABLE_MANAGER_PROPS = {
  showFilter: true,
  showHeader: false,
  // showSearch: true,
  showSearchByField: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const FILTER_ID = {
  VIEW_TYPE_ID: 'viewType',
};

const VIEW_TYPE_FILTER = {
  id: FILTER_ID.VIEW_TYPE_ID,
  key: RECORD_TYPE_LIST_COLUMN_IDS.VIEW_TYPE,
  type: FILTER_TYPES.SINGLE_SELECT,
  name: __('View Type'),
};

const VIEW_TYPES = {
  DETAIL_VIEW: { label: __('Detail View'), value: VIEW_BUILDER_VIEW_TYPES.DETAIL_VIEW },
  LIST_VIEW: { label: __('List View'), value: VIEW_BUILDER_VIEW_TYPES.LIST_VIEW },
  FORM_VIEW: { label: __('Form View'), value: VIEW_BUILDER_VIEW_TYPES.FORM_VIEW },
  GRID_VIEW: { label: __('Grid View'), value: VIEW_BUILDER_VIEW_TYPES.GRID_VIEW },
  CELL_VIEW: { label: __('Cell View'), value: VIEW_BUILDER_VIEW_TYPES.CELL_VIEW },
};

const DEFAULT_FILTERS = [VIEW_TYPE_FILTER.id];
const DEFAULT_FILTER_GROUP = 'DEFAULT_FILTER_GROUP';

const CLONE_VIEW_MODAL_FORM_CONTEXT_ID = 'CLONE_VIEW_MODAL_FORM';

const VIEW_BUILDER_LIST_TAB_IDS = {
  ENTITY_VIEWS: 'entity-view',
  RECORD_TYPE_VIEWS: 'record-type-view',
};

const VIEW_BUILDER_LIST_TAB_TITLES = {
  [VIEW_BUILDER_LIST_TAB_IDS.RECORD_TYPE_VIEWS]: __('Record Type Views'),
  [VIEW_BUILDER_LIST_TAB_IDS.ENTITY_VIEWS]: __('Entity Views'),
};

const STATUS = {
  SUCCESS: 'success',
};

export {
  TABLE_MANAGER_PROPS,
  VIEW_TYPE_FILTER,
  DEFAULT_FILTERS,
  DEFAULT_FILTER_GROUP,
  VIEW_TYPES,
  CLONE_VIEW_MODAL_FORM_CONTEXT_ID,
  VIEW_BUILDER_LIST_TAB_IDS,
  VIEW_BUILDER_LIST_TAB_TITLES,
  STATUS,
};
