import _property from 'lodash/property';

// Tekion-components
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import { RECORD_TYPE_LIST_COLUMN_IDS, ENTITY_VIEW_LIST_COLUMN_IDS } from '../constants/viewBuilderList.columnIds';
import ACTION_TYPES from '../constants/viewBuilderList.actionTypes';
import { VIEW_BUILDER_LIST_TAB_IDS } from '../constants/viewBuilderList.general';
import { VIEW_CONFIGURATION_GENERAL_KEYS, ALL_VIEW_PROPERTY_KEYS } from '../../../../../constants/viewBuilder.constants';

const RECORD_TYPE_LIST_COLUMN_CONFIG = [
  {
    Header: __('Display Name'),
    id: RECORD_TYPE_LIST_COLUMN_IDS.VIEW_NAME,
    accessor: _property(`${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.TITLE}`),
    Cell: TextRenderer,
  },
  {
    Header: __('View Type'),
    id: RECORD_TYPE_LIST_COLUMN_IDS.VIEW_TYPE,
    accessor: RECORD_TYPE_LIST_COLUMN_IDS.VIEW_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('View Config Type'),
    id: RECORD_TYPE_LIST_COLUMN_IDS.VIEW_CONFIG_TYPE,
    accessor: RECORD_TYPE_LIST_COLUMN_IDS.VIEW_CONFIG_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Description'),
    id: RECORD_TYPE_LIST_COLUMN_IDS.DESCRIPTION,
    accessor: _property(`${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${RECORD_TYPE_LIST_COLUMN_IDS.DESCRIPTION}`),
    Cell: TextRenderer,
  },
  {
    Header: __('Modified By'),
    id: RECORD_TYPE_LIST_COLUMN_IDS.MODIFIED_BY,
    accessor: RECORD_TYPE_LIST_COLUMN_IDS.MODIFIED_BY,
    Cell: TextRenderer,
  },
];

const ENTITY_VIEW_LIST_COLUMN_CONFIG = [
  {
    Header: __('Name'),
    id: ENTITY_VIEW_LIST_COLUMN_IDS.NAME,
    accessor: ENTITY_VIEW_LIST_COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Display Name'),
    id: ENTITY_VIEW_LIST_COLUMN_IDS.DISPLAY_NAME,
    accessor: ENTITY_VIEW_LIST_COLUMN_IDS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('View Type'),
    id: ENTITY_VIEW_LIST_COLUMN_IDS.VIEW_TYPE,
    accessor: ENTITY_VIEW_LIST_COLUMN_IDS.VIEW_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Modified By'),
    id: ENTITY_VIEW_LIST_COLUMN_IDS.MODIFIED_BY,
    accessor: ENTITY_VIEW_LIST_COLUMN_IDS.MODIFIED_BY,
    Cell: TextRenderer,
  },
];

const EDIT_VIEW_BUILDER = {
  id: ACTION_TYPES.EDIT_VIEW_BUILDER,
  name: __('Edit View'),
};

const DELETE_ENTITY_VIEW_CONFIG = {
  id: ACTION_TYPES.DELETE_ENTITY_VIEW,
  name: __('Delete View'),
};

const CLONE_VIEW = {
  id: ACTION_TYPES.CLONE_VIEW_MODAL_OPEN,
  name: __('Clone View'),
};

const RECORD_TYPE_LIST_ROW_ACTIONS = [EDIT_VIEW_BUILDER, CLONE_VIEW];

const ENTITY_VIEW_LIST_ROW_ACTIONS = [EDIT_VIEW_BUILDER, DELETE_ENTITY_VIEW_CONFIG];

const COLUMN_CONFIG = {
  [VIEW_BUILDER_LIST_TAB_IDS.RECORD_TYPE_VIEWS]: RECORD_TYPE_LIST_COLUMN_CONFIG,
  [VIEW_BUILDER_LIST_TAB_IDS.ENTITY_VIEWS]: ENTITY_VIEW_LIST_COLUMN_CONFIG,
};

const ROW_ACTIONS = {
  [VIEW_BUILDER_LIST_TAB_IDS.RECORD_TYPE_VIEWS]: RECORD_TYPE_LIST_ROW_ACTIONS,
  [VIEW_BUILDER_LIST_TAB_IDS.ENTITY_VIEWS]: ENTITY_VIEW_LIST_ROW_ACTIONS,
};

export { COLUMN_CONFIG, ROW_ACTIONS };
