import React, { useCallback } from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import Options from './Options';
import styles from '../groupedRelationshipField.module.scss';

const Menu = ({ isMulti, searchText, groupOptions, sectionWiseInfo, selectedOptions, handleLoadOptions, onChangeOptions, ...restProps }) => {
  const handleScroll = useCallback(
    (index) => (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop - 1 <= e.target.clientHeight;

      if (bottom && _get(sectionWiseInfo[index], 'hasMore')) {
        handleLoadOptions(searchText, { index });
      }
    },
    [handleLoadOptions, searchText, sectionWiseInfo],
  );

  return (
    <components.Menu {...restProps} className={styles.menu}>
      {_map(groupOptions, (groupOption, index) => {
        const groupLabel = _get(groupOption, 'label');
        const options = _get(groupOption, 'options');

        return (
          <div className={styles.section} key={index}>
            <div className={styles.groupLabel}>{groupLabel}</div>
            {_isEmpty(options) ? (
              <div className={styles.noOptionsContainer}>No Options Available</div>
            ) : (
              <div className={styles.sectionOptionsContainer} onScroll={handleScroll(index)}>
                {_map(options, (option) => {
                  const optionLabel = _get(option, 'label');
                  const optionValue = _get(option, 'value');
                  return (
                    <Options
                      {...restProps}
                      isMulti={isMulti}
                      key={optionValue}
                      optionValue={optionValue}
                      optionLabel={optionLabel}
                      selectedOptions={selectedOptions}
                      onChangeOptions={onChangeOptions}
                    />
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </components.Menu>
  );
};

Menu.propTypes = {
  isMulti: PropTypes.bool,
  searchText: PropTypes.string,
  selectedOptions: PropTypes.array,
  groupOptions: PropTypes.array,
  sectionWiseInfo: PropTypes.array,
  handleLoadOptions: PropTypes.func,
  onChangeOptions: PropTypes.func,
};

Menu.defaultProps = {
  isMulti: false,
  searchText: EMPTY_STRING,
  selectedOptions: EMPTY_ARRAY,
  groupOptions: EMPTY_ARRAY,
  sectionWiseInfo: EMPTY_ARRAY,
  handleLoadOptions: _noop,
  onChangeOptions: _noop,
};
export default Menu;
