import Button from '@tekion/tekion-components/atoms/Button';
import { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';

import ACTION_TYPES from './jobScheduleList.actionTypes';
import COLUMN_IDS from './jobScheduleList.columnIds';

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create New'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_JOB_SCHEDULE,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showFilter: true,
  showHeader: false,
  showSearch: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const JOB_SCHEDULE_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

const JOB_SCHEDULE_STATUS_COLOR_MAP = {
  [JOB_SCHEDULE_STATUS.ACTIVE]: COLOR_MAP.GREEN,
  [JOB_SCHEDULE_STATUS.INACTIVE]: COLOR_MAP.RED,
};

const JOB_SCHEDULE_STATUS_LABEL_MAP = {
  [JOB_SCHEDULE_STATUS.ACTIVE]: __('Active'),
  [JOB_SCHEDULE_STATUS.INACTIVE]: __('Inactive'),
};

const DEFAULT_CURRENT_PAGE = 0;

const JOB_STATUS_FILTER_OPTIONS = [
  { label: __('Active'), value: 'ACTIVE' },
  { label: __('Inactive'), value: 'INACTIVE' },
];

const JOB_STATUS_FILTER = {
  id: COLUMN_IDS.STATUS,
  type: FILTER_TYPES.SINGLE_SELECT,
  name: __('Status'),
  additional: {
    options: JOB_STATUS_FILTER_OPTIONS,
  },
};

const DEFAULT_FILTERS_TYPES = [JOB_STATUS_FILTER.id];

const JOB_EXECUTION_SUCCESS_STATUS = 200;

const EXECUTION_LOG_STATUS = {
  SUCCESS: true,
  FAILED: false,
};

const EXECUTION_LOG_STATUS_LABEL_MAP = {
  [EXECUTION_LOG_STATUS.SUCCESS]: __('Success'),
  [EXECUTION_LOG_STATUS.FAILED]: __('Failed'),
};

const EXECUTION_LOG_STATUS_COLOR_MAP = {
  [EXECUTION_LOG_STATUS.SUCCESS]: COLOR_MAP.GREEN,
  [EXECUTION_LOG_STATUS.FAILED]: COLOR_MAP.RED,
};

export {
  SUB_HEADER_PROPS,
  TABLE_MANAGER_PROPS,
  JOB_SCHEDULE_STATUS,
  JOB_SCHEDULE_STATUS_COLOR_MAP,
  JOB_SCHEDULE_STATUS_LABEL_MAP,
  DEFAULT_CURRENT_PAGE,
  JOB_STATUS_FILTER,
  DEFAULT_FILTERS_TYPES,
  JOB_EXECUTION_SUCCESS_STATUS,
  EXECUTION_LOG_STATUS,
  EXECUTION_LOG_STATUS_COLOR_MAP,
  EXECUTION_LOG_STATUS_LABEL_MAP,
};
