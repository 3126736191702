import { useCallback } from 'react';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import useEditor from '../../../../../../../../hooks/useEditor';

function useTableCellActions(onAction) {
  const editor = useEditor();

  const onCellActionClick = useCallback(
    (params) => {
      const { type, payload } = params || EMPTY_OBJECT;
      onAction({
        type,
        payload: {
          ...payload,
          editor,
        },
      });
    },
    [onAction, editor],
  );

  return {
    onCellActionClick,
  };
}

export default useTableCellActions;
