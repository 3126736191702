/* eslint-disable import/order */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactEditor } from 'slate-react';

// helpers
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { getSelectedTemplateVariableNode } from '../../helpers/richTextEditor.link';

// components
import Modal from '@tekion/tekion-components/molecules/Modal';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import TemplateVariableInsertionForm, { TEMPLATE_VARIABLE_FORM_CONTEXT_ID } from './organisms/templateVariableForm';

// events
import templateVariableDialogEvent from './helpers/templateVariableDialog.event';

// constants
import EVENT_TYPES from './constants/templateVariableDialog.eventTypes';

// containers
import withEditor from '../../containers/withEditor';

class TemplateVariableDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTemplateVariableDialogVisible: false,
    };
    templateVariableDialogEvent.on(EVENT_TYPES.SHOW_TEMPLATE_VARIABLE_DIALOG, this.showTemplateVariableDialog);
    templateVariableDialogEvent.on(EVENT_TYPES.HIDE_TEMPLATE_VARIABLE_DIALOG, this.hideTemplateVariableDialog);
  }

  componentWillUnmount() {
    templateVariableDialogEvent.removeAllListeners();
  }

  // eslint-disable-next-line class-methods-use-this
  handleSubmit = () => {
    triggerSubmit(TEMPLATE_VARIABLE_FORM_CONTEXT_ID);
  };

  hideTemplateVariableDialog = () => {
    const { editor } = this.props;
    this.setState({
      isTemplateVariableDialogVisible: false,
    });
    ReactEditor.focus(editor);
  };

  showTemplateVariableDialog = () => {
    this.setState({
      isTemplateVariableDialogVisible: true,
    });
  };

  render() {
    const { isTemplateVariableDialogVisible } = this.state;
    const { editor } = this.props;
    const selectedTemplateVariableNode = getSelectedTemplateVariableNode(editor);

    return (
      <Modal
        visible={isTemplateVariableDialogVisible}
        onCancel={this.hideTemplateVariableDialog}
        title={__('Template Variable')}
        onSubmit={this.handleSubmit}
        destroyOnClose
      >
        <TemplateVariableInsertionForm
          selectedTemplateVariableNode={selectedTemplateVariableNode}
          hideTemplateVariableDialog={this.hideTemplateVariableDialog}
        />
      </Modal>
    );
  }
}

TemplateVariableDialog.propTypes = {
  editor: PropTypes.object,
};

TemplateVariableDialog.defaultProps = {
  editor: EMPTY_OBJECT,
};

export default withEditor(TemplateVariableDialog);
