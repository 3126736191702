import { STATUS } from '@tekion/tekion-base/constants/statuses';
import { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import { FORM_MODES } from './general.constants';

const CUSTOM_ENTITY_REQUEST_FIELD_IDS = {
  ACTION_TYPE: 'actionType',
  CUSTOM_ENTITY_REQUEST: 'customEntityRequest',
};

const APPROVAL_METADATA_PROPERTY_IDS = {
  IS_ENABLED: 'isEnabled',
  ENABLE_CREATION: 'enableCreation',
  GROUPS_ENABLED: 'groupsEnabled',
  CATEGORIES_ENABLED: 'categoriesEnabled',
  ENTITIES_ENABLED: 'entitiesEnabled',
  FILTERS_TO_SHOW: 'filtersToShow',
  ENTITIES_RENDERING_INFO: 'entitiesRenderingInfo',
  RENDERING_INFO_IDS: {
    ENTITY: 'entity',
    FORM_VIEW: 'formView',
    DETAIL_VIEW: 'detailView',
  },
};

const APPROVAL_EXECUTION_DETAIL_FIELD_IDS = {
  EXECUTION_FAILURE_REASON: 'executionFailureReason',
  EXECUTION_RESPONSE: 'executionResponse',
  EXECUTION_STATUS: 'executionStatus',
};

const APPROVAL_CENTRE_FIELD_IDS = {
  ID: 'id',
  CREATED_BY: 'createdBy',
  CREATED_TIME: 'createdTime',
  LAST_MODIFIED_TIME: 'modifiedTime',
  PROCESS_ID: 'processId',
  PROCESS_NAME: 'processName',
  APPROVAL_ID: 'approvalId',
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  DATA: 'data',
  GROUP: 'group',
  CATEGORY: 'category',
  FIELDS: 'fields',
  CONDITION: 'condition',
  INACTIVE: 'inactive',
  PRIORITY: 'priority',
  STAGES: 'stages',
  VALIDITY: 'validity',
  VALIDITY_TYPE: 'validityType',
  VALIDITY_EPOCH: 'epoch',
  VALIDITY_DAYS: 'days',
  VALIDITY_HOURS: 'hours',
  VALIDITY_MINUTES: 'minutes',
  MIN_APPROVALS: 'minApprovals',
  APPROVERS: 'approvers',
  REJECTORS: 'rejectors',
  APPROVED_SO_FAR: 'approvedSoFar',
  APPROVAL_LEVEL: 'approvalLevel',
  ELIGIBLE_APPROVERS: 'eligibleApprovers',
  APPROVER_ID: 'approverId',
  STATUS: 'status',
  TYPE: 'type',
  TASK: 'TASK',
  COMMENT: 'comment',
  APPROVAL_EXECUTION_DETAIL: 'approvalExecutionDetail',
  CUSTOM_ENTITY_REQUEST_FIELD_IDS,
  APPROVAL_EXECUTION_DETAIL_FIELD_IDS,
};

const APPROVAL_VALIDITY_TYPES = {
  EXACT: 'EXACT',
  RELATIVE: 'RELATIVE',
};

const DEFAULT_GROUP = 'DEFAULT';
const CUSTOM_ENTITY_CATEGORY = 'CUSTOM_ENTITY';

const CUSTOM_ENTITY_ACTION_TYPES = {
  CREATE_CUSTOM_ENTITY: 'CREATE_CUSTOM_ENTITY',
  UPDATE_CUSTOM_ENTITY: 'UPDATE_CUSTOM_ENTITY',
};

const FORM_MODES_TO_ACTION_TYPES = {
  [FORM_MODES.CREATE]: CUSTOM_ENTITY_ACTION_TYPES.CREATE_CUSTOM_ENTITY,
  [FORM_MODES.EDIT]: CUSTOM_ENTITY_ACTION_TYPES.UPDATE_CUSTOM_ENTITY,
};

const CUSTOM_ENTITY_ACTION_TYPE_LABEL = {
  CREATE_CUSTOM_ENTITY: __('Create'),
  UPDATE_CUSTOM_ENTITY: __('Update'),
};

const APPROVAL_STATUS_TYPES = {
  PENDING: STATUS.PENDING, // yet to approve or reject
  COMPLETED: STATUS.COMPLETED, // all required task completed
  REJECTED: STATUS.REJECTED, // eligible approver rejected some task
  EXPIRED: STATUS.EXPIRED, // approval validity over
  CANCELLED: STATUS.CANCELLED, // approval task validity over
  INACTIVE: STATUS.IN_ACTIVE, // approval updated
};

const APPROVAL_STATUS_LABELS = {
  [STATUS.PENDING]: __('Pending'), // yet to approve or reject
  [STATUS.COMPLETED]: __('Completed'), // all required task completed
  [STATUS.REJECTED]: __('Rejected'), // eligible approver rejected some task
  [STATUS.EXPIRED]: __('Expired'), // approval validity over
  [STATUS.CANCELLED]: __('Cancelled'), // approval task validity over
  [STATUS.IN_ACTIVE]: __('Inactive'), // approval updated
};

const APPROVAL_STATUS_TOOLTIPS = {
  [STATUS.PENDING]: __('Task pending on approvals'), // yet to approve or reject
  [STATUS.COMPLETED]: __('All tasks completed'), // all required task completed
  [STATUS.REJECTED]: __('Approver rejected the request'), // eligible approver rejected some task
  [STATUS.EXPIRED]: __('Validity over for approval'), // approval validity over
  [STATUS.CANCELLED]: __('Task Cancelled'), // approval task validity over
  [STATUS.IN_ACTIVE]: __('Approval updated'), // approval updated
};

const APPROVAL_STATUS_COLOR_MAPS = {
  [STATUS.PENDING]: COLOR_MAP.YELLOW, // yet to approve or reject
  [STATUS.COMPLETED]: COLOR_MAP.GREEN, // all required task completed
  [STATUS.REJECTED]: COLOR_MAP.RED, // eligible approver rejected some task
  [STATUS.EXPIRED]: COLOR_MAP.GREY, // approval validity over
  [STATUS.CANCELLED]: COLOR_MAP.GREY, // approval task validity over
  [STATUS.IN_ACTIVE]: COLOR_MAP.GREY, // approval updated
};

const APPROVAL_TAB_IDS = {
  APPROVALS: 'approvals',
  REQUESTS: 'requests',
};

const APPROVAL_ASSET_TYPE = 'APPROVAL';

const APPROVAL_REJECT_REQUEST_COMMENT_MODAL_ACTION_TYPES = {
  ON_CONFIRM_REJECT_REQUEST: 'ON_CONFIRM_REJECT_REQUEST',
  ON_CANCEL_REJECT_REQUEST: 'ON_CANCEL_REJECT_REQUEST',
};

const APPROVAL_EXECUTION_STATUS_TYPES = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export {
  APPROVAL_CENTRE_FIELD_IDS,
  APPROVAL_VALIDITY_TYPES,
  DEFAULT_GROUP,
  CUSTOM_ENTITY_CATEGORY,
  CUSTOM_ENTITY_REQUEST_FIELD_IDS,
  CUSTOM_ENTITY_ACTION_TYPES,
  CUSTOM_ENTITY_ACTION_TYPE_LABEL,
  APPROVAL_STATUS_TYPES,
  APPROVAL_STATUS_LABELS,
  APPROVAL_STATUS_TOOLTIPS,
  APPROVAL_STATUS_COLOR_MAPS,
  FORM_MODES_TO_ACTION_TYPES,
  APPROVAL_TAB_IDS,
  APPROVAL_ASSET_TYPE,
  APPROVAL_REJECT_REQUEST_COMMENT_MODAL_ACTION_TYPES,
  APPROVAL_METADATA_PROPERTY_IDS,
  APPROVAL_EXECUTION_DETAIL_FIELD_IDS,
  APPROVAL_EXECUTION_STATUS_TYPES,
};
