import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import loginDataServices from '../services/loginData.services';

const fetchLoginData = async () => {
  try {
    const response = await loginDataServices.fetchLoginData();
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch login data, please try again later.')));
    return EMPTY_ARRAY;
  }
};

export { fetchLoginData };
