import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _castArray from 'lodash/castArray';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import LoadingSpinner from '@tekion/tekion-components/molecules/loadingSpinner';

import styles from './relationship.module.scss';

const ClearIndicator = (props) => {
  const { isLoading, id, values, onAction } = props;

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();

      onAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: {
          id,
          value: [],
        },
      });
    },
    [id, onAction],
  );

  if (isLoading) return <LoadingSpinner size={20} className={styles.clearIndicator} />;

  if (_isEmpty(_castArray(values))) return null;

  return (
    <div onClick={handleClick}>
      <FontIcon className="cursor-pointer" size={SIZES.S}>
        icon-cross
      </FontIcon>
    </div>
  );
};

ClearIndicator.propTypes = {
  isLoading: PropTypes.bool,
  id: PropTypes.string.isRequired,
  values: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ClearIndicator.defaultProps = {
  isLoading: false,
  values: EMPTY_ARRAY,
};

export default React.memo(ClearIndicator);
