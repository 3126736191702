import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _unset from 'lodash/unset';
import _set from 'lodash/set';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import PROPERTIES_FORM_FIELD_IDS from '../constants/propertiesForm.fieldIds';

const getFieldsFormPropertiesValues = (values) => {
  const fieldsFormPropertiesValues = { ...values };
  const defaultSortDirection = _get(values, PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION);

  if (_isEmpty(defaultSortDirection)) {
    _unset(fieldsFormPropertiesValues, PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION);
  } else {
    _set(fieldsFormPropertiesValues, PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION, getArraySafeValue(defaultSortDirection));
  }

  return fieldsFormPropertiesValues;
};

export { getFieldsFormPropertiesValues };
