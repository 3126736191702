exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".navigationItem_flowItem__muSHmhMahz{display:flex;flex-flow:row;justify-content:space-between;align-items:center}[dir=ltr] .navigationItem_flowItem__muSHmhMahz{margin-left:.4rem}[dir=rtl] .navigationItem_flowItem__muSHmhMahz{margin-right:.4rem}[dir] .navigationItem_label__9XHwZuipVg{cursor:pointer}[dir=ltr] .navigationItem_icon__abCwXztX8J{margin-left:.4rem}[dir=rtl] .navigationItem_icon__abCwXztX8J{margin-right:.4rem}.navigationItem_lastNav__rydsArFf94{font-family:\"Proxima-Nova-Semibold\"}[dir] .navigationItem_lastNav__rydsArFf94{cursor:auto}", ""]);

// Exports
exports.locals = {
	"flowItem": "navigationItem_flowItem__muSHmhMahz",
	"label": "navigationItem_label__9XHwZuipVg",
	"icon": "navigationItem_icon__abCwXztX8J",
	"lastNav": "navigationItem_lastNav__rydsArFf94"
};