// Constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { FIELD_IDS } from '../../../../../../constants/validationRuleBuilder.constants';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';

const RULE_STATUS_RADIO_OPTIONS = {
  ACTIVE: true,
  INACTIVE: false,
};

const MODULE_TYPE = 'VALIDATION_RULE';
const TASK_TYPE = 'COMPILE_CODE';

const RULE_STATUS_RADIO_LABEL_MAP = {
  [RULE_STATUS_RADIO_OPTIONS.ACTIVE]: __('Active'),
  [RULE_STATUS_RADIO_OPTIONS.INACTIVE]: __('Inactive'),
};

const POLL = {
  INTERVAL: 1000,
  COUNT: 15,
};

const VALIDATION_FORM_CONTEXT_ID = 'VALIDATION_RULE_BUILDER_FORM';

const INITIAL_STATE = {
  formValues: {
    [FIELD_IDS.CONDITION]: {
      [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: [EMPTY_OBJECT],
      [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: '1',
    },
  },
};

export { RULE_STATUS_RADIO_OPTIONS, RULE_STATUS_RADIO_LABEL_MAP, VALIDATION_FORM_CONTEXT_ID, INITIAL_STATE, MODULE_TYPE, TASK_TYPE, POLL };
