import _isEmpty from 'lodash/isEmpty';
import { TEXT_STARTS_WITH } from '../../../../../constants/filter.constants';

const getPayload = ({ searchText, searchField, pageSize, nextPageToken }) => {
  let payload = { rows: pageSize };
  const filters = [];
  if (!_isEmpty(searchText)) {
    filters.push({
      field: searchField,
      values: [searchText],
      filterType: TEXT_STARTS_WITH,
    });
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      nextPageToken,
      ...payload,
    };
  }
  return payload;
};

export { getPayload };
