import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

const makeClickableCellRenderer = (onClickHandler) => (WrappedComponent) => {
  const WrappedComponentWithClickable = (props) => {
    const { index } = props;

    const handleClick = useMemo(() => onClickHandler(index), [index]);

    return <WrappedComponent {...props} onClick={handleClick} />;
  };

  WrappedComponentWithClickable.propTypes = {
    index: PropTypes.number.isRequired,
  };

  WrappedComponentWithClickable.defaultProps = {};

  return makeCellRenderer(WrappedComponentWithClickable);
};

export default makeClickableCellRenderer;
