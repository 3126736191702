import React, { useCallback, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent';

import WithSize from '../../../../connectors/withSize';
import ACTION_HANDLERS from './helpers/dashboardList.actionhandlers';

import { COLUMNS, TABLE_MANAGER_PROPS, getTableProps, SUB_HEADER_PROPS, HEADER_PROPS } from './constants/dashboardList.data';
import { CURRENT_PAGE, INITIAL_STATE, RESULTS_PER_PAGE } from './constants/dashboardList.constant';

const DashboardList = (props) => {
  const { contentHeight, dashboards, loading, searchText, currentPage, pageSize, nextPageToken, onAction } = props;

  const handleRowAction = useCallback(
    (actionType, value) => {
      onAction({
        type: actionType,
        payload: {
          value,
        },
      });
    },
    [onAction],
  );

  const tableProps = useMemo(
    () => getTableProps(handleRowAction, currentPage, loading, pageSize, dashboards.length),
    [handleRowAction, currentPage, loading, pageSize, dashboards],
  );

  return (
    <Page>
      <Page.Body style={{ height: contentHeight }}>
        <TableManager
          isLoaded
          disableHeight={false}
          columns={COLUMNS}
          loading={loading}
          data={dashboards}
          subHeaderProps={SUB_HEADER_PROPS}
          headerProps={HEADER_PROPS}
          tableProps={tableProps}
          tableManagerProps={TABLE_MANAGER_PROPS}
          onAction={onAction}
          searchText={searchText}
          searchPlaceholder={__('Enter Name')}
          tokenPagination
          nextPageToken={nextPageToken}
        />
      </Page.Body>
    </Page>
  );
};
DashboardList.propTypes = {
  contentHeight: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  dashboards: PropTypes.array,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  nextPageToken: PropTypes.string,
  searchText: PropTypes.string,
  onAction: PropTypes.func.isRequired,
};

DashboardList.defaultProps = {
  loading: false,
  dashboards: EMPTY_ARRAY,
  currentPage: CURRENT_PAGE,
  pageSize: RESULTS_PER_PAGE,
  nextPageToken: PropTypes.string,
  searchText: EMPTY_STRING,
};

export default compose(withActions(INITIAL_STATE, ACTION_HANDLERS), WithSize({ hasPageHeader: false }))(DashboardList);
