import _get from 'lodash/get';
import _set from 'lodash/set';
import _reduce from 'lodash/reduce';
import _valuesIn from 'lodash/valuesIn';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import AsyncPaginatedSelect from '../../../../../../../../organisms/asyncPaginatedSelect';

import { fetchEntities } from '../../../../../../../../actions/entityManagement.actions';

import { getPayload, getPayloadForInitialFetch } from './complexFieldDetailsForm.helpers';
import { getAsyncPaginatedSelectEntityOptions, mergeRenderOptions } from '../../../helpers/fieldsForm.general.helpers';

import COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS from '../constants/complexFieldDetailsForm.fieldIds';

const ENTITY_NAME_FIELD = {
  id: COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS.ENTITY_NAME,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    placeholder: __('Select Entity'),
    label: __('Entity'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
    shouldFetchOnValueChange: true,
    getPayloadForInitialFetch,
    getPayload,
    serviceHandler: fetchEntities,
    getOptions: getAsyncPaginatedSelectEntityOptions,
    noOptionsMessage: () => __('No other entity present, please add an entity to create a complex field.'),
  },
};

const COMPLEX_FIELD_DETAILS_FORM_FIELDS = {
  [COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS.ENTITY_NAME]: ENTITY_NAME_FIELD,
};

const getFields = (renderOptions) => {
  const mergedRenderOptions = mergeRenderOptions({}, _valuesIn(COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS), renderOptions);

  return _reduce(
    _valuesIn(COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS),
    (fields, fieldId) =>
      _set(fields, fieldId, addToRenderOptions(_get(COMPLEX_FIELD_DETAILS_FORM_FIELDS, fieldId), _get(mergedRenderOptions, fieldId, EMPTY_ARRAY))),
    {},
  );
};

export default getFields;
