import withNormalizedTableCellNode from './overrides/withNormalizedTableCellNode';
import withTableCopyPaste from './overrides/withTableCopyPaste';

function createTableCellNormalizerPlugin() {
  return {
    withOverrides: [withNormalizedTableCellNode(), withTableCopyPaste()],
  };
}

export default createTableCellNormalizerPlugin;
