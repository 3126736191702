const START_TIME = 'startTime';
const END_TIME = 'endTime';
const TIME_OF_THE_DAY = 'timeOfTheDay';
const DAYS_OF_WEEK = 'daysToExecute';
const EXECUTION_IN_DEALERSHIP_HOUR = 'executionInDealershipHour';
const EXECUTION_NOT_IN_DEALERSHIP_HOUR = 'executionNotInDealershipHour';

const FIELD_IDS = {
  START_TIME,
  END_TIME,
  TIME_OF_THE_DAY,
  DAYS_OF_WEEK,
  EXECUTION_IN_DEALERSHIP_HOUR,
  EXECUTION_NOT_IN_DEALERSHIP_HOUR,
};

export default FIELD_IDS;
