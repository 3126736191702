import _map from 'lodash/map';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';
import { STATUS } from '@tekion/tekion-base/constants/status.constants';

import withAsyncSelectFilterWrapper from '../../../../../../../../connectors/withAsyncSelectFilterWrapper/withAsyncSelectFilterWrapper';
import EntityAsyncSelect from '../../../../../../molecules/EntityAsyncSelect/EntityAsyncSelect';

import { WORKFLOW_LIST_COLUMN_IDS } from './workflowList.columnIds';

import entityReader from '../../../../../../../../readers/entity.reader';

import styles from '../workflowList.module.scss';

const WORKFLOW_STATUS_FILTER_OPTIONS = [
  { label: __('Active'), value: STATUS.PUBLISHED },
  { label: __('Inactive'), value: STATUS.DRAFT },
];

const getEntityOptions = (data) =>
  _map(data, (entityDef) => ({
    label: entityReader.displayName(entityDef),
    value: entityReader.name(entityDef),
  }));

const ENTITY_FILTER = {
  id: WORKFLOW_LIST_COLUMN_IDS.ENTITY_NAME,
  type: FILTER_TYPES.CUSTOM,
  key: WORKFLOW_LIST_COLUMN_IDS.ENTITY_NAME,
  name: __('Entity'),
  additional: {
    valueKey: 'value',
    component: withAsyncSelectFilterWrapper(EntityAsyncSelect),
    operators: [{ name: OPERATORS.IN, id: OPERATORS.IN }],
    renderProps: {
      required: true,
      isMulti: true,
      id: WORKFLOW_LIST_COLUMN_IDS.ENTITY_NAME,
      fieldClassName: styles.entitySelectFilter,
      getOptions: getEntityOptions,
    },
  },
};

const WORKFLOW_STATUS_FILTER = {
  id: WORKFLOW_LIST_COLUMN_IDS.STATUS,
  type: FILTER_TYPES.SINGLE_SELECT,
  name: __('Status'),
  additional: {
    options: WORKFLOW_STATUS_FILTER_OPTIONS,
  },
};

const FILTERS = {
  WORKFLOW_STATUS_FILTER,
  ENTITY_FILTER,
};

// They dont allow custom filter types as defaults, hence entity filter not showing up as default.
const DEFAULT_FILTERS_TYPES = [WORKFLOW_STATUS_FILTER.id, ENTITY_FILTER.id];

export { FILTERS, DEFAULT_FILTERS_TYPES };
