import _includes from 'lodash/includes';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _map from 'lodash/map';

const filterFieldOptions = (fieldOptions, value, index) => {
  const fieldValues = _map(value, 'field');

  return _filter(fieldOptions, (field) => {
    const fieldName = _get(field, 'value');
    return fieldValues[index] === fieldName || !_includes(fieldValues, fieldName);
  });
};

export { filterFieldOptions };
