import { defaultMemoize } from 'reselect';

import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _concat from 'lodash/concat';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _get from 'lodash/get';
import _isBoolean from 'lodash/isBoolean';
import _isNumber from 'lodash/isNumber';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { tget, uuid } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { ADD_TAB, FIELD_IDS, TAB_IDS, TAB_ONE, PROPERTIES_FIELD_IDS, COMPONENT_KEYS } from '../constants/applicationBuilder.constants';
import { COMPONENTS_TYPES } from '../../../../../../../constants/applicationRenderer.constants';

const getChildrenWithUpdatedComponentId = (children) => {
  if (_isEmpty(children)) return;

  _forEach(children, (child) => {
    if (_isEmpty(tget(child, COMPONENT_KEYS.COMPONENT_ID))) {
      const componentId = uuid();
      _set(child, COMPONENT_KEYS.COMPONENT_ID, componentId);
    }
    getChildrenWithUpdatedComponentId(tget(child, COMPONENT_KEYS.CHILDREN, EMPTY_ARRAY));
  });
};

const getApplicationWithUpdatedComponentId = (applicationConfig) => {
  _forEach(tget(applicationConfig, FIELD_IDS.COMPONENTS, EMPTY_ARRAY), (component) => {
    if (_isEmpty(tget(component, COMPONENT_KEYS.COMPONENT_ID))) {
      const componentId = uuid();
      _set(component, COMPONENT_KEYS.COMPONENT_ID, componentId);
    }

    getChildrenWithUpdatedComponentId(tget(component, COMPONENT_KEYS.CHILDREN, []));
  });

  return applicationConfig;
};

const getApplicationConfigWithUpdatedComponent = (applicationConfig, componentType, updatedComponent) => ({
  ...applicationConfig,
  [FIELD_IDS.COMPONENTS]: [
    ..._filter(tget(applicationConfig, FIELD_IDS.COMPONENTS, []), (item) => !tget(item, COMPONENT_KEYS.TYPE) === componentType),
    updatedComponent,
  ],
});

const getComponentFromApplicationConfig = (componentType, applicationConfig) =>
  _find(tget(applicationConfig, FIELD_IDS.COMPONENTS, EMPTY_ARRAY), (componentItem) => _get(componentItem, COMPONENT_KEYS.TYPE) === componentType);

const getFlattenProperties = (properties) =>
  _reduce(
    properties,
    (result, property) => {
      const type = tget(property, COMPONENT_KEYS.TYPE);
      const value = tget(property, 'value');

      if (!_isEmpty(type)) {
        return { ...result, [type]: value };
      }
      return result;
    },
    {},
  );

const getComponentWithFlattenProperties = (component) => {
  const flattenProperties = getFlattenProperties(tget(component, COMPONENT_KEYS.PROPERTIES, EMPTY_ARRAY));
  const children = tget(component, COMPONENT_KEYS.CHILDREN, EMPTY_ARRAY);
  let updatedChildren = [];

  if (!_isEmpty(children)) {
    updatedChildren = _map(children, (child) => getComponentWithFlattenProperties(child));
  }

  return { ...component, properties: flattenProperties, children: updatedChildren };
};

const getAppConfigWithFlattenProperties = (applicationConfig) => {
  const components = tget(applicationConfig, FIELD_IDS.COMPONENTS);
  const updatedComponents = _map(components, (component) => getComponentWithFlattenProperties(component));

  return {
    ...applicationConfig,
    properties: getFlattenProperties(tget(applicationConfig, COMPONENT_KEYS.PROPERTIES)),
    components: updatedComponents,
  };
};

const getUpdatedProperties = (properties) =>
  _reduce(
    properties,
    (result, value, key) => {
      if (_isNumber(value) || _isBoolean(value) || !_isEmpty(value)) return [...result, { type: key, value }];
      return result;
    },
    [],
  );

const getComponentWithUpdatedProperties = (component) => {
  const updatedProperties = getUpdatedProperties(tget(component, COMPONENT_KEYS.PROPERTIES, EMPTY_ARRAY));
  const children = tget(component, COMPONENT_KEYS.CHILDREN, EMPTY_ARRAY);
  let updatedChildren = [];

  if (!_isEmpty(children)) {
    updatedChildren = _map(children, (child) => getComponentWithUpdatedProperties(child));
  }

  return { ...component, properties: updatedProperties, children: updatedChildren };
};

const getAppConfigWithUpdatedProperties = (applicationConfig) => {
  const components = tget(applicationConfig, FIELD_IDS.COMPONENTS);
  const updatedComponents = _map(components, (component) => getComponentWithUpdatedProperties(component));

  return {
    ...applicationConfig,
    properties: getUpdatedProperties(tget(applicationConfig, COMPONENT_KEYS.PROPERTIES, EMPTY_ARRAY)),
    components: updatedComponents,
  };
};

const getComponentErrors = (componentType, applicationConfig) => {
  const component = getComponentFromApplicationConfig(componentType, applicationConfig);
  if (componentType === COMPONENTS_TYPES.NAVIGATION && !_isEmpty(component)) {
    const error = {};
    const children = tget(component, COMPONENT_KEYS.CHILDREN, []);
    _forEach(children, (child) => {
      const properties = tget(child, COMPONENT_KEYS.PROPERTIES);
      const tabNameError = isRequiredRule(EMPTY_STRING, tget(properties, PROPERTIES_FIELD_IDS.TAB_DISPLAY_NAME));

      if (!_isEmpty(tget(tabNameError, 'message'))) {
        _set(error, `${tget(child, COMPONENT_KEYS.COMPONENT_ID)}.${PROPERTIES_FIELD_IDS.TAB_DISPLAY_NAME}`, tget(tabNameError, 'message'));
      }

      const pageNameError = isRequiredRule(EMPTY_STRING, tget(child, PROPERTIES_FIELD_IDS.PAGE_NAME));

      if (!_isEmpty(tget(pageNameError, 'message'))) {
        _set(error, `${tget(child, COMPONENT_KEYS.COMPONENT_ID)}.${PROPERTIES_FIELD_IDS.PAGE_NAME}`, tget(pageNameError, 'message'));
      }
    });

    return error;
  }

  if (componentType === COMPONENTS_TYPES.NO_NAVIGATION && !_isEmpty(component)) {
    const error = {};
    const componentId = tget(component, COMPONENT_KEYS.COMPONENT_ID);
    const { message } = isRequiredRule(EMPTY_STRING, tget(component, 'pageName', EMPTY_STRING));

    if (!_isEmpty(message)) {
      _set(error, `${componentId}.${PROPERTIES_FIELD_IDS.PAGE_NAME}`, message);
    }

    return error;
  }

  return {};
};

const getErrors = (applicationConfig) => {
  let errorObject = {};
  _forEach(COMPONENTS_TYPES, (componentType) => {
    if (componentType === COMPONENTS_TYPES.NO_NAVIGATION) {
      errorObject = { ...errorObject, ...getComponentErrors(componentType, applicationConfig) };
    } else if (componentType === COMPONENTS_TYPES.NAVIGATION) {
      errorObject = { ...errorObject, ...getComponentErrors(componentType, applicationConfig) };
    }
  });
  return errorObject;
};

const getComponentData = (componentData) => {
  if (_isEmpty(componentData)) return null;
  const data = {};
  _set(data, 'id', tget(componentData, COMPONENT_KEYS.COMPONENT_ID, uuid()));
  _set(
    data,
    PROPERTIES_FIELD_IDS.DISPLAY_NAME,
    tget(componentData, `${COMPONENT_KEYS.PROPERTIES}.${PROPERTIES_FIELD_IDS.TAB_DISPLAY_NAME}`, 'Untitled'),
  );

  _set(data, PROPERTIES_FIELD_IDS.PAGE_NAME, tget(componentData, PROPERTIES_FIELD_IDS.PAGE_NAME));
  _set(data, PROPERTIES_FIELD_IDS.TAB_ICON, tget(componentData, `${COMPONENT_KEYS.PROPERTIES}.${PROPERTIES_FIELD_IDS.TAB_ICON}`));
  return data;
};

const getComponents = (components, properties) => {
  let allTabsData = [{ id: ADD_TAB, displayName: '' }];

  if (_isEmpty(components)) {
    allTabsData.push({ id: uuid(), displayName: TAB_ONE });
  } else {
    const tabData = _compact(_map(components, (item) => getComponentData(item)));
    allTabsData = _concat(allTabsData, tabData);
  }

  if (_size(allTabsData) === 1) {
    allTabsData.push({ id: uuid(), displayName: TAB_ONE });
  }

  if (!_isEmpty(properties)) {
    allTabsData.push({ id: TAB_IDS.APPROVAL, displayName: tget(properties, 'displayName', 'Approvals') });
  }

  return allTabsData;
};

const getApplicationData = (applicationData) => {
  const components = _find(
    tget(applicationData, FIELD_IDS.COMPONENTS, EMPTY_ARRAY),
    (component) => tget(component, COMPONENT_KEYS.TYPE) === COMPONENTS_TYPES.NAVIGATION,
  );
  let allTabs = [];
  let navigationType;
  const properties = tget(applicationData, `properties.${PROPERTIES_FIELD_IDS.APPROVAL_META_DATA}`, {});

  if (!_isEmpty(components)) {
    navigationType = tget(components, `properties.${PROPERTIES_FIELD_IDS.NAVIGATION_TYPE}`);
  }

  allTabs = getComponents(tget(components, COMPONENT_KEYS.CHILDREN, EMPTY_ARRAY), properties);
  return {
    navigationType,
    allTabs,
  };
};

const getComponentDetailsFromChildren = (children, selectedComponentId) => {
  if (_isEmpty(children)) return;
  let componentDetails = {};
  _forEach(children, (child) => {
    if (tget(child, COMPONENT_KEYS.COMPONENT_ID) === selectedComponentId) {
      componentDetails = child;
    } else if (!_isEmpty(tget(child, COMPONENT_KEYS.CHILDREN, EMPTY_ARRAY))) {
      componentDetails = getComponentDetailsFromChildren(tget(child, COMPONENT_KEYS.CHILDREN, EMPTY_ARRAY), selectedComponentId);
    }
  });

  // eslint-disable-next-line consistent-return
  return componentDetails;
};

const getComponentByComponentId = (applicationConfig, selectedComponentId) => {
  if (selectedComponentId === TAB_IDS.APPROVAL) {
    const componentDetails = { type: COMPONENTS_TYPES.TAB };
    return componentDetails;
  } else {
    let componentDetails = {};
    _forEach(tget(applicationConfig, FIELD_IDS.COMPONENTS, EMPTY_ARRAY), (component) => {
      if (tget(component, COMPONENT_KEYS.COMPONENT_ID) === selectedComponentId) {
        componentDetails = component;
      } else {
        componentDetails = getComponentDetailsFromChildren(tget(component, COMPONENT_KEYS.CHILDREN, EMPTY_ARRAY), selectedComponentId);
      }
    });
    return componentDetails;
  }
};

const getNavigationComponentId = defaultMemoize((applicationConfig) => {
  let componentId = tget(getComponentFromApplicationConfig(COMPONENTS_TYPES.NO_NAVIGATION, applicationConfig), COMPONENT_KEYS.COMPONENT_ID);
  if (_isEmpty(componentId)) {
    componentId = tget(getComponentFromApplicationConfig(COMPONENTS_TYPES.NAVIGATION, applicationConfig), COMPONENT_KEYS.COMPONENT_ID);
  }
  return componentId;
});

export {
  getApplicationData,
  getAppConfigWithFlattenProperties,
  getErrors,
  getApplicationConfigWithUpdatedComponent,
  getComponentData,
  getComponentFromApplicationConfig,
  getComponentByComponentId,
  getNavigationComponentId,
  getAppConfigWithUpdatedProperties,
  getApplicationWithUpdatedComponentId,
};
