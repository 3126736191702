import _get from 'lodash/get';
import _set from 'lodash/set';
import _reduce from 'lodash/reduce';
import _valuesIn from 'lodash/valuesIn';
import { defaultMemoize } from 'reselect';

import { tget } from '@tekion/tekion-base/utils/general';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox/Checkbox';
import { EMPTY_ARRAY, NO_DATA } from '@tekion/tekion-base/app.constants';

import { getFieldDataTypeOptions, getFieldTypeOptions } from './generalDetailsForm.general.helpers';
import { mergeRenderOptions } from '../../../helpers/fieldsForm.general.helpers';
import { nameValidator } from '../../../../../../../../utils/formValidators';

import GENERAL_DETAILS_FORM_FIELD_IDS from '../constants/generalDetailsForm.fieldIds';
import GENERAL_DETAILS_FORM_LABELS from '../constants/generalDetailsForm.labels';
import FIELD_TYPES from '../../../../../../../../constants/fieldDefinition.fieldTypes';

const NAME_FIELD = {
  id: GENERAL_DETAILS_FORM_FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    validators: [isRequiredRule, nameValidator],
    label: __('Name'),
    placeholder: __('This will be autofilled'),
  },
};

const DISPLAY_NAME_FIELD = {
  id: GENERAL_DETAILS_FORM_FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Display Name'),
    placeholder: __('Enter field display name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const FIELD_TYPE_FIELD = {
  id: GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Field Type'),
    label: __('Field Type'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const FIELD_DATA_TYPE_FIELD = {
  id: GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Field Data Type'),
    label: __('Field Data Type'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const MULTI_VALUED_FIELD = {
  id: GENERAL_DETAILS_FORM_FIELD_IDS.MULTI_VALUED,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: __('Multi Valued'),
    defaultValue: false,
  },
};

const PLACEHOLDER_FIELD = {
  id: GENERAL_DETAILS_FORM_FIELD_IDS.PLACEHOLDER,
  renderer: TextInput,
  renderOptions: {
    label: __('Placeholder'),
    placeholder: __('Enter Placeholder'),
  },
};

const HELP_TEXT_FIELD = {
  id: GENERAL_DETAILS_FORM_FIELD_IDS.HELP_TEXT,
  renderer: TextInput,
  renderOptions: {
    label: __('Help Text'),
    placeholder: __('Enter Help Text'),
  },
};

const GENERAL_DETAILS_FORM_FIELDS = {
  [GENERAL_DETAILS_FORM_FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [GENERAL_DETAILS_FORM_FIELD_IDS.NAME]: NAME_FIELD,
  [GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]: FIELD_TYPE_FIELD,
  [GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]: FIELD_DATA_TYPE_FIELD,
  [GENERAL_DETAILS_FORM_FIELD_IDS.MULTI_VALUED]: MULTI_VALUED_FIELD,
  [GENERAL_DETAILS_FORM_FIELD_IDS.PLACEHOLDER]: PLACEHOLDER_FIELD,
  [GENERAL_DETAILS_FORM_FIELD_IDS.HELP_TEXT]: HELP_TEXT_FIELD,
};

const getFieldLabel = defaultMemoize((fieldId) => tget(GENERAL_DETAILS_FORM_LABELS, fieldId, NO_DATA));

const getFields = (values, renderOptions) => {
  const dataType = _get(values, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE);
  const fieldType = _get(values, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE);
  const fieldsRenderOptions = {
    ...(!!tget(renderOptions, [GENERAL_DETAILS_FORM_FIELD_IDS.NAME, 'disabled'], false) && {
      [GENERAL_DETAILS_FORM_FIELD_IDS.NAME]: [
        {
          path: 'validators',
          value: [],
        },
      ],
    }),
    [GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]: [
      {
        path: 'options',
        value: getFieldDataTypeOptions(fieldType),
      },
      ...(fieldType === FIELD_TYPES.RELATIONSHIP
        ? [
            { path: 'placeholder', value: __('Data Type of Target Field') },
            { path: 'validators', value: EMPTY_ARRAY },
          ]
        : EMPTY_ARRAY),
    ],
    [GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]: [
      {
        path: 'options',
        value: getFieldTypeOptions(dataType),
      },
    ],
  };
  const mergedRenderOptions = mergeRenderOptions(fieldsRenderOptions, _valuesIn(GENERAL_DETAILS_FORM_FIELD_IDS), renderOptions, getFieldLabel);

  return _reduce(
    _valuesIn(GENERAL_DETAILS_FORM_FIELD_IDS),
    (fields, fieldId) =>
      _set(fields, fieldId, addToRenderOptions(_get(GENERAL_DETAILS_FORM_FIELDS, fieldId), _get(mergedRenderOptions, fieldId, EMPTY_ARRAY))),
    {},
  );
};
export default getFields;
