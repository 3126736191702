import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Input } from 'antd';

// Constants
import { DEFAULT_FONT_SIZE_OPTIONS, MINIMUM_FONT_SIZE, MAXIMUM_FONT_SIZE } from './constants/fontSizeInput.general';

// Hooks
import useDropdown from './hooks/useDropdown';
import useInputHandlers from './hooks/useInputHandlers';

// Styles
import styles from './fontSizeInput.module.scss';

const FontSizeInput = ({ value, onSelect, fontSizeOptions, onChange, minFontSize, maxFontSize }) => {
  const { onInputChange, onInputEnter, onInputBlur } = useInputHandlers({
    onSelect,
    onChange,
    minFontSize,
    maxFontSize,
  });
  const dropDown = useDropdown({ fontSizeOptions, onSelect });
  return (
    <Input
      onChange={onInputChange}
      onPressEnter={onInputEnter}
      onBlur={onInputBlur}
      className={`flex align-items-center ${styles.input}`}
      value={value}
      suffix={dropDown}
      maxLength={2}
    />
  );
};

FontSizeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  fontSizeOptions: PropTypes.array,
  minFontSize: PropTypes.number,
  maxFontSize: PropTypes.number,
};

FontSizeInput.defaultProps = {
  value: '',
  fontSizeOptions: DEFAULT_FONT_SIZE_OPTIONS,
  maxFontSize: MAXIMUM_FONT_SIZE,
  minFontSize: MINIMUM_FONT_SIZE,
};

export default FontSizeInput;
