import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import DaysOfWeekRenderer from './fieldRenderers/daysOfWeekRenderer/DaysOfWeekRenderer';
import { getTimeField } from './timeOfTheDay.helper';
import FIELD_IDS from './timeOfTheDay.fieldTypes';
import DAY_OPTIONS from './timeOfTheDay.constants';

import styles from './timeOfTheDay.module.scss';

const getFormSections = () => [
  {
    className: styles.section,
    rows: [
      {
        className: styles.paddingFormRow,
        columns: [FIELD_IDS.DAYS_OF_WEEK],
      },
      {
        className: styles.paddingFormRow,
        columns: [FIELD_IDS.START_TIME, FIELD_IDS.END_TIME],
      },
    ],
  },
];

const getFieldConfig = (fieldOptions) => ({
  [FIELD_IDS.START_TIME]: getTimeField(FIELD_IDS.START_TIME, fieldOptions),
  [FIELD_IDS.END_TIME]: getTimeField(FIELD_IDS.END_TIME, fieldOptions),
  [FIELD_IDS.DAYS_OF_WEEK]: {
    id: FIELD_IDS.DAYS_OF_WEEK,
    renderer: DaysOfWeekRenderer,
    renderOptions: {
      label: __('Days of the week'),
      options: DAY_OPTIONS,
      validators: [isRequiredRule],
      required: true,
      customDaysClassName: styles.customDaysSelector,
    },
  },
});

export { getFormSections, getFieldConfig };
