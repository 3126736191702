import _isEmpty from 'lodash/isEmpty';
import _unset from 'lodash/unset';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { FIELD_ERROR_MESSAGE } from '../../../constants/entityMappings.constants';
import { ACTION_TYPES } from '../../entityMappingsList/helpers/entityMappingsList.actionHandlers';

const handleFieldChange = ({ props, params = EMPTY_OBJECT }) => {
  const { index, value: rowValue, error: rowError, onAction } = props;

  const { id, value } = params;

  onAction({
    type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
    payload: { index, value: { ...rowValue, [id]: value } },
  });

  if (_isEmpty(value)) {
    onAction({
      type: ACTION_TYPES.ON_ERROR,
      payload: { index, error: { ...rowError, [id]: FIELD_ERROR_MESSAGE } },
    });
  } else {
    const newError = { ...rowError };
    _unset(newError, id);

    onAction({
      type: ACTION_TYPES.ON_ERROR,
      payload: { index, error: newError },
    });
  }
};

const handleRowDelete = ({ props }) => {
  const { index, onAction } = props;

  onAction({
    type: ACTION_TYPES.DELETE_ROW,
    payload: { index },
  });

  onAction({
    type: ACTION_TYPES.CLEAR_ERROR,
    payload: { index },
  });
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [ACTION_TYPES.DELETE_ROW]: handleRowDelete,
};

export default ACTION_HANDLERS;
