import _isFunction from 'lodash/isFunction';
import _includes from 'lodash/includes';
import _values from 'lodash/values';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { processUpdatedNode } from '@tekion/tekion-rule-engine/src/utils/core/helpers/tasks.helpers';
import { addNode, updateNodeDetails, deleteNode } from '@tekion/tekion-rule-engine/src/utils/core/crudOperations';

// Constants
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { isConditionNode, isLoopNode, isParentConditionNode, NODE_PLACEMENTS } from '@tekion/tekion-rule-engine';
import WORKFLOW_BUILDER_MODAL_TYPES from '../../../constants/workflowBuilder.modalTypes';
import WORKFLOW_BUILDER_ACTION_TYPES from '../../../constants/workflowBuilder.actionTypes';
import { CONDITION_TYPES, TASK_DEF_IDS, TASK_ICONS, TASK_LABELS } from '../../../constants/workflow.constants';
import { MODAL_MODES } from '../../../constants/workflowBuilder.constants';
import WORKFLOW_FIELD_IDS from '../../../constants/workflow.fieldIds';
import { CURRENT_ENTITY_NAMESPACE } from '../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import { ASSIGNED_VARIABLE_FIELD_IDS } from '../../../constants/workflow.assignVariableConstants';
import { STUDIO_ROUTE } from '../../../../../../../../constants/routes';
import PAGE_IDS from '../../../../../../constants/PageIds.constants';

import { loadFieldDefinitions } from './helperHandlers';

// Utils
import { generateNode, setNodeLabel } from '../../../utils/crudOperations';
import getUpdatedWorkflowWithVariableInfo from '../../../utils/getUpdatedWorkflowWithVariableInfo';

const handleStepModalSubmit = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { modalProps, workflow, selectedNode, nodePlacement } = getState();
  const { formValues, childLabels, assignedVariableInfo, usedVariableInfo } = params;

  const nodeData = _cloneDeep(tget(modalProps, 'stepDetails', {}));
  _set(nodeData, 'uiMetadata.usedVariables', usedVariableInfo);

  const mode = modalProps?.mode;
  const variablesInfo = modalProps?.variablesInfo;
  const variablesByNodeId = modalProps?.variablesByNodeId;
  const parentNodeId = modalProps?.parentNodeId;
  let updatedWorkflow;

  if (!_isEmpty(assignedVariableInfo)) {
    const variableName = tget(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_NAME, '');
    const entityName = tget(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME, '');
    const nodeId = tget(nodeData, 'nodeId') || parentNodeId;
    if (
      !_isEmpty(tget(variablesInfo, variableName)) &&
      tget(variablesInfo, `${variableName}.${ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME}`) !== entityName
    ) {
      toaster(TOASTER_TYPE.ERROR, __('This variable name has already been used. Please choose different a name'));
      return;
    } else if (
      !_isEmpty(tget(variablesInfo, variableName)) &&
      tget(variablesInfo, `${variableName}.${ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME}`) === entityName &&
      !_includes(tget(variablesByNodeId, nodeId), variableName)
    ) {
      toaster(TOASTER_TYPE.ERROR, __('This variable is not scooped for this node. Please choose different variable'));
      return;
    }
  }

  if (mode === MODAL_MODES.EDIT) {
    const label = tget(nodeData, 'label', EMPTY_STRING);
    _set(nodeData, 'displayName', label);

    const node = generateNode(nodeData, formValues);
    setNodeLabel(node);

    const config = {
      recalculateNodePositions: tget(node, 'uiMetadata.conditionType') === CONDITION_TYPES.SWITCH,
      childLabels,
    };

    updatedWorkflow = updateNodeDetails(workflow, node, {
      ...config,
      ...processUpdatedNode(workflow, node, config),
    });

    const currentNodeId = tget(nodeData, 'nodeId');
    updatedWorkflow = getUpdatedWorkflowWithVariableInfo(updatedWorkflow, currentNodeId, assignedVariableInfo, usedVariableInfo);
  } else {
    // ADD
    const node = generateNode(nodeData, formValues);
    setNodeLabel(node);
    updatedWorkflow = addNode(workflow, selectedNode.node, {
      task: node,
      label: selectedNode.label,
      nodePlacement,
      childLabels,
    });
    const parentNode = tget(updatedWorkflow, `nodeList.${selectedNode.node}`);

    let currentNodeId;
    if (nodePlacement === NODE_PLACEMENTS.TOP) {
      currentNodeId = tget(parentNode, 'nodeConfig.parent');
    } else if (selectedNode.label) {
      if (isConditionNode(parentNode)) {
        currentNodeId = tget(parentNode, `nodesByConditionValue.${selectedNode.label}`);
      } else {
        currentNodeId = tget(parentNode, `nodesByRepeatOutcome.${selectedNode.label}`);
      }
    } else {
      currentNodeId = tget(parentNode, 'nextNodeId');
    }

    updatedWorkflow = getUpdatedWorkflowWithVariableInfo(updatedWorkflow, currentNodeId, assignedVariableInfo, usedVariableInfo);
  }

  const entityName = tget(workflow, WORKFLOW_FIELD_IDS.ENTITY);
  const mapOfVariableToEntityNameForCondition = { [CURRENT_ENTITY_NAMESPACE]: entityName };
  loadFieldDefinitions({ setState, getState, params: { variableMap: mapOfVariableToEntityNameForCondition } });

  setState({
    isModalVisible: false,
    isStepDrawerVisible: false,
    modalType: '',
    modalProps: EMPTY_OBJECT,
    workflow: updatedWorkflow,
    mapOfVariableToEntityNameForCondition,
  });
};

const handleConfirmClick = ({ getState, setState, params }) => {
  const { modalProps = {}, selectedNode, workflow, history, workflowMode } = getState();
  const { mode } = modalProps;
  if (mode === MODAL_MODES.DELETE) {
    const { formValues } = params;
    const selectedOption = tget(formValues, 'selectedOption', {});
    const step = selectedNode?.node;

    let condition = EMPTY_OBJECT;

    if (isConditionNode(step) || isLoopNode(step)) {
      condition = {
        type: selectedOption.deleteCondition,
        keptChild: [selectedOption.value],
        ...(isParentConditionNode(step) && { label: tget(step, 'nodeConfig.label', null) }),
      };
    }

    let updatedWorkflow = deleteNode(workflow, selectedNode?.nodeId, condition);
    updatedWorkflow = getUpdatedWorkflowWithVariableInfo(updatedWorkflow, selectedNode?.nodeId, [], []);

    setState({
      workflow: updatedWorkflow,
      selectedNode: {},
      isModalVisible: false,
      modalProps: {},
      modalType: '',
    });
  } else if (mode === MODAL_MODES.CANCEL) {
    history.push(`${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${workflowMode}`);
  }
};

const handleChangeTaskDef = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { modalProps, workflow } = getState();
  const { formValues } = params;
  const nodeData = modalProps?.stepDetails;
  const currentNodeId = tget(nodeData, 'nodeId');
  const targetType = modalProps?.targetType;

  const node = { ...nodeData };
  setNodeLabel(node);

  const newConditionData = {
    ...node,
    type: 'CHANGE_CONDITION_TYPE',
    nodeTransferConfig: formValues,
    keptChild: _values(formValues),
    changedStepLabelTo: TASK_LABELS[targetType],
    taskDefId: TASK_DEF_IDS[targetType],
    entityDefId: TASK_DEF_IDS[targetType],
    icon: TASK_ICONS[targetType],
    newConditionType: targetType,
  };

  const updatedWorkflow = deleteNode(workflow, currentNodeId, newConditionData);

  setState({ workflow: updatedWorkflow, selectedNode: {}, isModalVisible: false, modalProps: {}, modalType: '', formValues: {} });
};

const MODAL_SUBMIT_HANDLERS = {
  [WORKFLOW_BUILDER_MODAL_TYPES.CONFIRMATION]: handleConfirmClick,
  [WORKFLOW_BUILDER_MODAL_TYPES.DELETE_CONFIRMATION_MODAL]: handleConfirmClick,
  [WORKFLOW_BUILDER_MODAL_TYPES.CONDITION]: handleStepModalSubmit,
  [WORKFLOW_BUILDER_MODAL_TYPES.ACTION]: handleStepModalSubmit,
  [WORKFLOW_BUILDER_MODAL_TYPES.DELAY_TASK]: handleStepModalSubmit,
  [WORKFLOW_BUILDER_MODAL_TYPES.CHECK_FIELD]: handleStepModalSubmit,
  [WORKFLOW_BUILDER_MODAL_TYPES.CHANGE_TO_IF]: handleChangeTaskDef,
  [WORKFLOW_BUILDER_MODAL_TYPES.CHANGE_TO_IF_ELSE]: handleChangeTaskDef,
  [WORKFLOW_BUILDER_MODAL_TYPES.CHANGE_TO_SWITCH]: handleChangeTaskDef,
  [WORKFLOW_BUILDER_MODAL_TYPES.GOTO]: handleStepModalSubmit,
  [WORKFLOW_BUILDER_MODAL_TYPES.REPEAT_NODE]: handleStepModalSubmit,
  [WORKFLOW_BUILDER_MODAL_TYPES.SWITCH_WORKFLOW]: handleStepModalSubmit,
  [WORKFLOW_BUILDER_MODAL_TYPES.SET_VARIABLE]: handleStepModalSubmit,
  [WORKFLOW_BUILDER_MODAL_TYPES.TIME_OF_THE_DAY]: handleStepModalSubmit,
  [WORKFLOW_BUILDER_MODAL_TYPES.PUSH_NOTIFICATION]: handleStepModalSubmit,
  [WORKFLOW_BUILDER_MODAL_TYPES.CALLOUT]: handleStepModalSubmit,
  [WORKFLOW_BUILDER_MODAL_TYPES.FETCH_AND_ITERATE]: handleStepModalSubmit,
  [WORKFLOW_BUILDER_MODAL_TYPES.FOR_LOOP]: handleStepModalSubmit,
};

const handleModalSubmit = ({ getState, setState, params }) => {
  const { modalType } = getState();
  const submitHandler = modalType && MODAL_SUBMIT_HANDLERS[modalType];
  if (_isFunction(submitHandler)) {
    submitHandler({ getState, setState, params });
  }
};

const handleModalCancel = ({ getState, setState }) => {
  const { workflow } = getState();
  const entityName = tget(workflow, WORKFLOW_FIELD_IDS.ENTITY);
  const mapOfVariableToEntityNameForCondition = { [CURRENT_ENTITY_NAMESPACE]: entityName };
  loadFieldDefinitions({ setState, getState, params: { variableMap: mapOfVariableToEntityNameForCondition } });

  setState({
    isModalVisible: false,
    modalProps: {},
    modalType: '',
    mapOfVariableToEntityNameForCondition,
  });
};

const MODAL_ACTION_HANDLERS = {
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_MODAL_SUBMIT]: handleModalSubmit,
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_MODAL_CANCEL]: handleModalCancel,
};

export default MODAL_ACTION_HANDLERS;
