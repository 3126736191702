import _isFunction from 'lodash/isFunction';
import _get from 'lodash/get';
import _keysIn from 'lodash/keysIn';
import _isEmpty from 'lodash/isEmpty';
import _mapValues from 'lodash/mapValues';
import _keyBy from 'lodash/keyBy';
import _has from 'lodash/has';
import _debounce from 'lodash/debounce';
import _omit from 'lodash/omit';
import _noop from 'lodash/noop';
import _set from 'lodash/set';
import _forEach from 'lodash/forEach';
import _reduce from 'lodash/reduce';
import _castArray from 'lodash/castArray';
import _map from 'lodash/map';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { fetchEntities } from '../../../../actions/entityManagement.actions';

import { searchViewConfigurations } from '../../../../actions/viewBuilderPage.actions';
import { getDerivedRecordType } from '../../../../actions/recordManagement.actions';
import {
  getComplexViewConfigNamesAndComplexEntities,
  getFormValuesFromResponse,
  getUpdatedFieldDefinitions,
  getPayloadFromFormValues,
  getDependentOptionMap,
  getFieldsWithRegexValidation,
} from './formViewRenderer.helpers';
import { getCompactedFiltersPayload } from '../../../../helpers/general.helpers';
import { getResolvedRichTextEditorFieldRecords } from '../../../../helpers/richTextField.helpers';
import { getPropertyFromViewConfig } from '../../helpers/viewBuilder.helper';
import { convertEventHandlersFromArrayToObjectByEventName, executeEventFromEventViewConfigData } from '../../../../utils/eventHandlers';
import { getSimpleFieldNameFromColumn } from '../../../../utils';

import { ACTION_TYPES, FORM_SCRIPT_RETURN_VALUE_IDS } from './formViewRenderer.constants';
import ENTITY_DEFINITION_FIELDS from '../../../../constants/entityDefinitionFields';
import {
  ALL_VIEW_PROPERTY_KEYS,
  FORM_MODES,
  VIEW_CONFIGURATION_FIELD_IDS,
  VIEW_CONFIGURATION_GENERAL_KEYS,
  VIEW_CONFIGURATION_PROPERTIES_ID,
  VIEW_TYPES,
} from '../../../../constants/viewBuilder.constants';
import { EVENT_REGISTER_ACTION_TYPES } from '../../../../connectors/withEventRegisterFormRenderer/withEventRegisterFormRenderer';
import { COMPONENT_TYPES } from '../../constants/viewBuilder.constants';
import { EVENT_NAMES } from '../../../../constants/eventActions.constants';
import {
  EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS,
  MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT,
} from '../../../../constants/eventHandlers.constants';
import SYSTEM_DEFINED_FIELDS from '../../../../constants/systemDefinedFields';

import entityReader from '../../../../readers/entity.reader';

const handleInit = async ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { entity, componentConfig, initialFormValues = {} } = getState();
  const fieldDefinitionsByName = _keyBy(_get(entity, 'fieldDefinitions', EMPTY_ARRAY), 'name');
  const dependentOptionsByFieldName = getDependentOptionMap(fieldDefinitionsByName);

  const fieldNames = _keysIn(fieldDefinitionsByName);
  const entityRecord = _get(params, 'entityRecord');
  const regexWorkerInstance = tget(params, 'regexWorkerInstance');

  // Creating a map of FieldName to its editable or not on basis of fieldDef and component config
  const formFields = tget(params, 'formFields', EMPTY_OBJECT);
  const mapOfFormFieldNameToEditable = _reduce(
    formFields,
    (result, formFieldProperty, formFieldName) => {
      const fieldDef = _get(fieldDefinitionsByName, formFieldName);
      const isFieldEditable = tget(formFieldProperty, 'editable', _get(fieldDef, 'editable', false)) || _isEmpty(entityRecord);
      _set(result, [formFieldName], isFieldEditable);

      return result;
    },
    {},
  );

  let complexViewConfiguration = {};
  let updatedFieldDefinitions = [..._get(entity, 'fieldDefinitions', [])];
  const complexViewConfigNames = {};
  const complexEntities = [];
  const mapOfComplexEntityNameToComplexViewConfigNames = {};

  setState({ isLoading: true });
  getComplexViewConfigNamesAndComplexEntities(
    componentConfig,
    complexViewConfigNames,
    complexEntities,
    fieldDefinitionsByName,
    mapOfComplexEntityNameToComplexViewConfigNames,
  );

  const fieldsWithRegexValidation = getFieldsWithRegexValidation(entity);

  if (!_isEmpty(complexEntities)) {
    const complexEntityDefPayload = {
      filters: getCompactedFiltersPayload([
        {
          field: ENTITY_DEFINITION_FIELDS.NAME,
          values: complexEntities,
          filterType: OPERATORS.IN,
        },
      ]),
    };

    const complexEntityDefinitions = await fetchEntities(complexEntityDefPayload, true);
    updatedFieldDefinitions = getUpdatedFieldDefinitions(entity, tget(complexEntityDefinitions, 'hits', EMPTY_ARRAY));

    const complexViewsForEntitiesPromises = _map(mapOfComplexEntityNameToComplexViewConfigNames, (viewNames, entityName) => {
      const searchViewNamesPayload = {
        filters: [
          {
            field: VIEW_CONFIGURATION_FIELD_IDS.NAME,
            filterType: OPERATORS.IN,
            values: viewNames,
          },
          {
            field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
            filterType: OPERATORS.IN,
            values: _castArray(entityName),
          },
        ],
      };

      return searchViewConfigurations(searchViewNamesPayload);
    });

    const responses = await Promise.all(complexViewsForEntitiesPromises);

    const mapOfEntityNameToViewDefByName = {};

    _forEach(responses, (response) => {
      const viewConfigs = tget(response, 'hits', EMPTY_ARRAY);

      if (!_isEmpty(viewConfigs)) {
        const entityName = tget(viewConfigs, `0.${VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME}`);
        const viewConfigsByName = _keyBy(viewConfigs, VIEW_CONFIGURATION_FIELD_IDS.NAME);

        _set(mapOfEntityNameToViewDefByName, entityName, viewConfigsByName);
      }
    });

    complexViewConfiguration = _mapValues(complexViewConfigNames, ({ complexViewName, complexEntityName }) => {
      const viewConfigForGivenEntityAndViewName = tget(mapOfEntityNameToViewDefByName, `${complexEntityName}.${complexViewName}`, {});

      return viewConfigForGivenEntityAndViewName;
    });
  }

  let updatedState = {
    formMode: FORM_MODES.CREATE,
    formValues: { ...initialFormValues },
    isLoading: false,
    complexViewConfiguration,
    entity: { ...entity, fieldDefinitions: updatedFieldDefinitions },
    dependentOptionsByFieldName,
    fieldsWithRegexValidation,
    regexWorkerInstance,
    mapOfFormFieldNameToEditable,
  };

  const result = [];
  getPropertyFromViewConfig(COMPONENT_TYPES.FORM_VIEW_COLUMN, componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT, result);
  let prefilledValues = {};

  _forEach(result, (value) => {
    const fieldName = getSimpleFieldNameFromColumn(getArraySafeValue(_get(value, 'fieldNames', [''])));
    const fieldValue = tget(value, ALL_VIEW_PROPERTY_KEYS.PREFILLED_VALUE);

    if (fieldName && fieldValue) prefilledValues = { ...prefilledValues, [fieldName]: fieldValue };
  });

  if (!_isEmpty(entityRecord)) {
    const entityDef = _get(updatedState, 'entity', EMPTY_OBJECT);
    let formValues = await getResolvedRichTextEditorFieldRecords(entityDef, entityRecord);
    formValues = getArraySafeValue(formValues);
    formValues = getFormValuesFromResponse(formValues, fieldNames);

    updatedState = {
      ...updatedState,
      formMode: FORM_MODES.EDIT,
      formValues,
    };
  } else {
    // Giving precedence to initial Values over Prefilled values
    updatedState = { ...updatedState, formValues: { ...prefilledValues, ..._get(updatedState, 'formValues', EMPTY_OBJECT) } };
  }

  setState({ ...updatedState });
};

const handleValidation = _debounce(({ getState, params, setState }) => {
  const { fieldsWithRegexValidation, formValues, errors, warnings = {} } = getState();
  const { id } = params;

  if (_has(fieldsWithRegexValidation, id)) {
    const { pattern, regex } = tget(fieldsWithRegexValidation, id);
    const valueToTest = tget(formValues, id);

    setState({ isRegexValidationInProgress: true });
    let validationResult;
    let regexWorkerInstance;
    if (window.Worker) {
      regexWorkerInstance = new Worker('./src/workers/regexWorker.js');

      regexWorkerInstance.addEventListener('message', (e) => {
        const { data } = e;
        validationResult = data;
      });

      setTimeout(() => {
        if (validationResult === undefined) {
          setState({
            warnings: {
              ...warnings,
              [id]: __('Regex validation taking too much time'),
            },
            isRegexValidationInProgress: false,
          });
        } else {
          const { isValidRegex } = validationResult;
          setState({
            errors: {
              ...errors,
              [id]: isValidRegex ? undefined : __('Input not valid as per pattern {{pattern}}.', { pattern }),
            },
            warnings: {
              ...warnings,
              [id]: undefined,
            },
            isRegexValidationInProgress: false,
          });
        }

        regexWorkerInstance.terminate();
      }, 1000);

      regexWorkerInstance.postMessage({ pattern, regex, valueToTest });
    }
  }
}, 500);

const handleFieldChange = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { onFormValueChange = _noop, componentConfig, entity, fieldsWithRegexValidation } = getState();
  const viewType = tget(componentConfig, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.VIEW_TYPE}`);

  if (_has(fieldsWithRegexValidation, id)) {
    handleValidation({ setState, getState, params: { id } });
  }

  let newFormValues;

  setState(
    (prevState) => {
      newFormValues = { ...tget(prevState, 'formValues', {}) };

      _set(newFormValues, id, value);

      onFormValueChange(newFormValues);

      return { ...prevState, formValues: newFormValues };
    },
    async () => {
      if (viewType === VIEW_TYPES.RECORD_TYPE_SELECTION_VIEW) {
        const payloadToFetchRecordType = { entity: { ..._omit(newFormValues, SYSTEM_DEFINED_FIELDS.RECORD_TYPE_ID) } };
        const entityName = entityReader.name(entity);
        const recordTypeDetails = await getDerivedRecordType(entityName, payloadToFetchRecordType);
        const recordTypeId = tget(recordTypeDetails, 'name');

        setState((prevState) => {
          const prevFormValues = tget(prevState, 'formValues', {});
          onFormValueChange({ ...prevFormValues, recordTypeId });

          return { ...prevState, formValues: { ...prevFormValues, recordTypeId } };
        });
      }
    },
  );
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;

  setState({ errors });
};

const handleSubmit = async ({ getState, params }) => {
  const { onFormSubmit, formValues, entity, contextId, isRegexValidationInProgress, componentConfig, errors = EMPTY_OBJECT } = getState();

  // Keeping this manual check to handle error set from custom script
  const hasError = _reduce(errors, (result, value) => result || !_isEmpty(value), false);
  if (hasError) {
    return;
  }

  let payload = getPayloadFromFormValues(formValues, entity);

  // Execution of Before Submit form event
  const properties = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);
  const eventHandlers = tget(properties, VIEW_CONFIGURATION_PROPERTIES_ID.EVENT_HANDLERS, EMPTY_ARRAY);
  const eventHandlerByEventName = convertEventHandlersFromArrayToObjectByEventName(eventHandlers);
  const beforeSubmitEventData = tget(eventHandlerByEventName, EVENT_NAMES.BEFORE_SUBMIT, EMPTY_OBJECT);

  const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
    [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$RECORD]: payload,
  };
  const returnValue = await executeEventFromEventViewConfigData(beforeSubmitEventData, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE);
  const isReturnValuePresentFromScript = tget(returnValue, EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS.IS_RETURN_VALUE_PRESENT);

  if (isReturnValuePresentFromScript) {
    const scriptReturnValue = tget(returnValue, EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS.RETURN_VALUE, EMPTY_OBJECT);

    // Checking if user has return a boolean that indicates return value from script wants to overwrite formValues
    const isFormValueOverridden = tget(scriptReturnValue, FORM_SCRIPT_RETURN_VALUE_IDS.IS_FORM_VALUE_OVERRIDDEN);

    if (isFormValueOverridden) {
      const userReturnFormValues = tget(scriptReturnValue, FORM_SCRIPT_RETURN_VALUE_IDS.FORM_VALUES, EMPTY_OBJECT);

      payload = { entity: userReturnFormValues };
    }
  }

  if (!_isEmpty(formValues) && !isRegexValidationInProgress) {
    onFormSubmit(payload, contextId, tget(params, 'additional'));
  }
};
const handleOnBlur = ({ getState, params }) => {
  const { onFormFieldBlur } = getState();
  // adding this time because of delay of setting new values in handleFieldChange
  if (_isFunction(onFormFieldBlur)) {
    setTimeout(() => {
      onFormFieldBlur(params);
    }, 1000);
  }
};

const handleSetError = ({ setState, params }) => {
  const { fieldName = EMPTY_STRING, errorMessage = EMPTY_STRING } = params;

  if (!_isEmpty(fieldName)) {
    setState((prevState) => {
      const prevError = tget(prevState, 'errors', {});

      return { ...prevState, errors: { ...prevError, [fieldName]: __(errorMessage) } };
    });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [FORM_ACTION_TYPES.ON_FIELD_BLUR]: handleOnBlur,
  [EVENT_REGISTER_ACTION_TYPES.SET_ERROR]: handleSetError,
};

export default ACTION_HANDLERS;
