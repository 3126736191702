import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { getFields } from './helpers/metadataComponentForm.field';
import componentFormSections from './helpers/metadataComponentForm.sections';

import { COMPONENT_FORM_CONTEXT_ID } from './constants/metadataComponentForm.general.constants';

const MetadataComponentForm = ({ componentData, errors, onAction }) => {
  const ref = useRef(null);

  const componentType = getArraySafeValue(tget(componentData, 'componentType', EMPTY_ARRAY));
  const entity = getArraySafeValue(tget(componentData, 'entityName', EMPTY_ARRAY));

  useEffect(() => {
    const resetLoadedOptions = tget(ref, 'current.resetLoadedOptions', _noop);
    resetLoadedOptions();
  }, [componentType, entity]);

  return (
    <FormWithSubmission
      contextId={COMPONENT_FORM_CONTEXT_ID}
      fields={getFields(ref, componentType, entity)}
      sections={componentFormSections(componentType, entity)}
      values={componentData}
      errors={errors}
      onAction={onAction}
    />
  );
};

MetadataComponentForm.propTypes = {
  componentData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default MetadataComponentForm;
