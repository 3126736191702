import { defaultMemoize } from 'reselect';

import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import FILTER_FIELD_KEYS, { TEXT_STARTS_WITH } from '../../../../../../constants/filter.constants';
import { ASSET_TYPES } from '../../../../../../constants/general.constants';

export const getPayload = defaultMemoize(({ searchText, searchField, pageSize, currentPage, nextPageToken }) => {
  let payload = { rows: pageSize, start: currentPage * pageSize };

  if (!_isEmpty(searchText)) {
    payload = {
      filters: [
        {
          field: searchField,
          values: [searchText],
          filterType: TEXT_STARTS_WITH,
        },
      ],
      ...payload,
    };
  }

  payload = {
    ...payload,
    filters: [
      ...tget(payload, 'filters', EMPTY_ARRAY),
      {
        [FILTER_FIELD_KEYS.FIELD]: 'assetType',
        [FILTER_FIELD_KEYS.VALUES]: [ASSET_TYPES.USER],
        [FILTER_FIELD_KEYS.FILTER_TYPE]: OPERATORS.IN,
      },
    ],
  };

  if (!_isEmpty(nextPageToken)) {
    payload = {
      nextPageToken,
      ...payload,
    };
  }

  return payload;
});
