import { useMemo } from 'react';
import COLORS from '@tekion/tekion-styles-next/scss/variables.scss';

const getEditableProps = (readOnly, editorStyles) => ({
  style: {
    padding: '1.5rem',
    backgroundColor: COLORS.white,
    overflow: 'auto',
    flex: '1 0',
    ...editorStyles,
  },
  readOnly,
});

function useEditableProps(readOnly, editorStyles) {
  const editableProps = useMemo(() => getEditableProps(readOnly, editorStyles), [readOnly, editorStyles]);

  return editableProps;
}

export default useEditableProps;
