import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';

import { getSections } from './propertiesForm.sections';
import { getFields } from './propertiesForm.fields';

const PropertiesForm = ({ selectedViewComponent, properties, entity, errors, onAction }) => {
  const sections = useMemo(() => getSections(selectedViewComponent), [selectedViewComponent]);

  const formFields = useMemo(
    () => getFields({ entity, properties, errors, selectedViewComponent }),
    [entity, properties, errors, selectedViewComponent],
  );

  return <FormBuilder sections={sections} fields={formFields} values={properties} onAction={onAction} />;
};

PropertiesForm.propTypes = {
  selectedViewComponent: PropTypes.object,
  properties: PropTypes.object,
  entity: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func,
};

PropertiesForm.defaultProps = {
  selectedViewComponent: EMPTY_OBJECT,
  properties: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  onAction: _noop,
};

export default PropertiesForm;
