import React, { useMemo } from 'react';

// Components
import ActionExecutionModal from './organisms/actionExecutionModal';
import ShareAndManageRecordModal from './organisms/shareAndManageRecordModal';
import ActionExecutionModalContext from './contexts/actionExecutionModal.context';

// Hooks
import useShareAndManageRecordModal from './hooks/useShareAndManageRecordModal';

// Context helpers
import { createActionExecutionModalContextValue } from './helpers/withActionExecutionModal.contextHelpers';

// Helpers
import { getValueFromProps } from './helpers/withActionExecutionModal.general';
import { getUuid } from '../../utils';

const withActionExecutionModal = (WrappedComponent) => {
  const WithActionExecutionModal = (props) => {
    const { entityDef } = getValueFromProps(props);

    const actionModalContextId = useMemo(() => getUuid(), []);
    const {
      isShareAndManageRecordModalVisible,
      isDataLoading,
      isDataSubmitting,
      shareAndManageRecordUsersData,
      shareAndManageRecordModalMetaData,
      shareAndManageModalContextValue,
      onShareAndManageRecordModalClose,
      onModalSubmit,
      deleteSharedRecordForUser,
    } = useShareAndManageRecordModal(entityDef);

    const actionModalContextValues = useMemo(
      () => createActionExecutionModalContextValue(shareAndManageModalContextValue),
      [shareAndManageModalContextValue],
    );

    return (
      <>
        <ActionExecutionModalContext.Provider value={actionModalContextValues}>
          <WrappedComponent {...props} actionModalContextId={actionModalContextId} />
        </ActionExecutionModalContext.Provider>

        <ActionExecutionModal actionModalContextId={actionModalContextId} />
        <ShareAndManageRecordModal
          isModalVisible={isShareAndManageRecordModalVisible}
          isDataLoading={isDataLoading}
          isDataSubmitting={isDataSubmitting}
          shareAndManageRecordModalMetaData={shareAndManageRecordModalMetaData}
          shareAndManageRecordUsersData={shareAndManageRecordUsersData}
          onModalSubmit={onModalSubmit}
          onShareRecordModalClose={onShareAndManageRecordModalClose}
          deleteSharedRecordForUser={deleteSharedRecordForUser}
        />
      </>
    );
  };

  return WithActionExecutionModal;
};

export default withActionExecutionModal;
