import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';

import Modal from '@tekion/tekion-components/molecules/Modal';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';

import { MODAL_INPUT_FIELD, ROW_OPERATION } from '../constants/listInputTable.constants';
import ACTION_TYPES from '../../../constants/fieldsForm.actionTypes';

import styles from './optionModal.module.scss';

const OptionModal = (props) => {
  const { visible, isControllingFunctionEnabled, controllingOptions, modalData, onAction } = props;

  const oldLabel = _get(modalData, 'label', '');
  const oldValue = _get(modalData, 'value', '');
  const oldControllingOptions = _get(modalData, MODAL_INPUT_FIELD.CONTROLLING_OPTIONS, EMPTY_ARRAY);
  const operation = _get(modalData, 'operation', '');

  const [modalState, setModalState] = useState({
    [MODAL_INPUT_FIELD.LABEL]: '',
    [MODAL_INPUT_FIELD.VALUE]: '',
    [MODAL_INPUT_FIELD.CONTROLLING_OPTIONS]: EMPTY_ARRAY,
  });

  const handleChange = useCallback(
    (action) => {
      if (_get(action, 'type') === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
        const id = _get(action, 'payload.id', '');
        const newValue = _get(action, 'payload.value', '');
        setModalState({ ...modalState, [id]: newValue });
      }
    },
    [modalState],
  );

  const handleModalSubmit = () => {
    const payload = {
      rowData: { ...modalState, operation, nestingPath: _get(modalData, 'nestingPath', EMPTY_ARRAY) },
    };
    onAction({
      type: ACTION_TYPES.OPTION_TABLE_SAVE_ROW,
      payload,
    });
  };

  const handleClose = useCallback(() => {
    onAction({
      type: ACTION_TYPES.OPTION_TABLE_MODAL_CLOSE,
    });
  }, [onAction]);

  useEffect(() => {
    setModalState({
      [MODAL_INPUT_FIELD.LABEL]: oldLabel,
      [MODAL_INPUT_FIELD.VALUE]: oldValue,
      [MODAL_INPUT_FIELD.CONTROLLING_OPTIONS]: oldControllingOptions,
    });
  }, [oldControllingOptions, oldLabel, oldValue]);
  return (
    <Modal title={__('Option update')} visible={visible} onCancel={handleClose} width={Modal.SIZES.MD} destroyOnClose onSubmit={handleModalSubmit}>
      <div className={styles.modalBody}>
        <TextInput
          id={MODAL_INPUT_FIELD.LABEL}
          value={_get(modalState, MODAL_INPUT_FIELD.LABEL, '')}
          label={__('Label')}
          fieldClassName={styles.input}
          onAction={handleChange}
        />
        <TextInput
          id={MODAL_INPUT_FIELD.VALUE}
          value={_get(modalState, MODAL_INPUT_FIELD.VALUE, '')}
          label={__('Value')}
          disabled={operation === ROW_OPERATION.EDIT}
          fieldClassName={styles.input}
          onAction={handleChange}
        />
      </div>
      <PropertyControlledComponent controllerProperty={isControllingFunctionEnabled}>
        <div className={styles.controllingOptionSelect}>
          <Select
            isMulti
            required
            id={MODAL_INPUT_FIELD.CONTROLLING_OPTIONS}
            label={__('Controlling Option')}
            validators={[isRequiredRule]}
            value={_get(modalState, MODAL_INPUT_FIELD.CONTROLLING_OPTIONS, EMPTY_ARRAY)}
            options={controllingOptions}
            onAction={handleChange}
          />
        </div>
      </PropertyControlledComponent>
    </Modal>
  );
};
OptionModal.propTypes = {
  isControllingFunctionEnabled: PropTypes.bool,
  modalData: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  controllingOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

OptionModal.defaultProps = {
  isControllingFunctionEnabled: false,
  modalData: EMPTY_OBJECT,
  controllingOptions: EMPTY_ARRAY,
};

export default React.memo(OptionModal);
