import { Editor, Node, Path, Range, Transforms } from 'slate';
import _set from 'lodash/set';
import _size from 'lodash/size';
import { ReactEditor } from 'slate-react';

const getInsertBreak = (editor, defaultInsertBreak) => () => {
  if (!editor.selection || !Range.isCollapsed(editor.selection)) {
    return defaultInsertBreak();
  }
  const parentPath = Path.parent(editor?.selection?.anchor?.path);
  const parentNode = Node.get(editor, parentPath);
  if (Editor.isVoid(editor, parentNode)) {
    Editor.insertNode(editor, {
      type: 'p',
      children: [{ text: '' }],
    });
    return undefined;
  }

  return defaultInsertBreak();
};

const getPreviousNodeFromPath = (editor, path) => {
  const prevNodePath = Path.previous(path);
  const prevNode = Node.get(editor, prevNodePath);
  return prevNode;
};

const checkIsNodeEmpty = (editor, path) => {
  const node = Node.get(editor, path);
  const isNodeEmpty = _size(Node.string(node)) === 0;
  return isNodeEmpty;
};

const setNodeAsSelected = (editor, prevNode) => {
  const nodePath = ReactEditor.findPath(editor, prevNode);
  return Transforms.select(editor, nodePath);
};

const getDeleteBackwards = (editor, defaultDeleteBackward) => (unit) => {
  if (!editor.selection || !Range.isCollapsed(editor.selection) || editor.selection.anchor.offset !== 0) {
    return defaultDeleteBackward(unit);
  }

  const parentPath = Path.parent(editor?.selection?.anchor?.path);
  const isParentEmpty = checkIsNodeEmpty(editor, parentPath);
  if (Path.hasPrevious(parentPath)) {
    const prevNode = getPreviousNodeFromPath(editor, parentPath);
    if (Editor.isVoid(editor, prevNode)) {
      if (isParentEmpty) {
        Transforms.removeNodes(editor);
      }
      return setNodeAsSelected(editor, prevNode);
    }
  }

  return defaultDeleteBackward(unit);
};

const withVoidSelection = () => (editor) => {
  const { deleteBackward, insertBreak } = editor;

  // if current selection is void node, insert a default node below
  _set(editor, 'insertBreak', getInsertBreak(editor, insertBreak));

  // if prev node is a void node, remove the current node and select the void node
  _set(editor, 'deleteBackward', getDeleteBackwards(editor, deleteBackward));

  return editor;
};

export default withVoidSelection;
