import produce from 'immer';

import _chunk from 'lodash/chunk';
import _set from 'lodash/set';
import _isNil from 'lodash/isNil';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _flatten from 'lodash/flatten';
import _filter from 'lodash/filter';
import _toLower from 'lodash/toLower';
import _includes from 'lodash/includes';

import { tget } from '@tekion/tekion-base/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { getWorkspaceUserList } from '../../../../../../../../../actions/workspaceUserManagement.actions';
import { getAllRecordGroups } from '../../../../../../../../../actions/recordGroup.actions';
import { getAllRoles } from '../../../../../../../../../actions/rolesHierarchyManagement.actions';

import {
  createOptionsForStageApprovers,
  getFormFormattedApprovalStageDetails,
  getPayload,
  getPayloadForApprovalStage,
  generateRoleOptions,
  resolveSelectedUsersAndUserGroups,
  getStageApproversById,
  getApproverOptionsBasedOnApproverType,
  getSelectedApproverOptions,
} from './approvalStageFieldRenderer.helpers';

import { APPROVAL_CENTRE_FIELD_IDS, APPROVAL_VALIDITY_TYPES } from '../../../../../../../../../constants/approvalCentre.constants';
import { ROWS, STAGE_APPROVER_TYPES } from '../constants/approvalStageFieldRenderer.constants';
import { FORM_MODES } from '../../../../../../../../../constants/general.constants';
import ACTION_TYPES from '../constants/approvalStageFieldRenderer.actionTypes';

import roleReader from '../../../../../../../../../readers/role.reader';

const handleInit = async ({ setState }) => {
  // Fetching roles on Init as it does not has any searchApi so have to fetch all the roles in one go, and then flattening it and
  // saving it in array as chunks with size of ROWS, this is done to mimic the searchApi experience, that is initially the first
  // chunk of roles of size = ROWS will be displayed and then onScroll the next chunk will be displayed.

  const roles = await getAllRoles();
  const rolesOptions = [];
  let rolesOptionsChunk = [];
  let stageApproversById = {};

  if (!_isEmpty(roles)) {
    generateRoleOptions(roles, rolesOptions);

    stageApproversById = getStageApproversById(rolesOptions);

    rolesOptionsChunk = _chunk(rolesOptions, ROWS);
  }

  setState({ stageApproversById, rolesOptionsChunk });
};

const fetchStageApprovers = async ({ getState, setState }) => {
  const {
    nextPageToken: { nextPageTokenForUsers, nextPageTokenForUserGroups, nextChunkIndexForRoles },
    selectedApproverType = STAGE_APPROVER_TYPES.ALL,
    searchText = EMPTY_STRING,
    stageApproversById = EMPTY_OBJECT,
    rolesOptionsChunk = EMPTY_ARRAY,
    searchResultsChunkForRoles = EMPTY_ARRAY,
    approverOptions = EMPTY_ARRAY,
    selectedApprovers: { userApprovers = EMPTY_ARRAY, userGroupApprovers = EMPTY_ARRAY, roleApprovers = EMPTY_ARRAY },
  } = getState();

  let newNextPageTokenForUsers = nextPageTokenForUsers;
  let newNextPageTokenForUserGroups = nextPageTokenForUserGroups;
  let newNextChunkIndexForRoles = nextChunkIndexForRoles;
  let users = EMPTY_ARRAY;
  let userGroups = EMPTY_ARRAY;
  let roles = EMPTY_ARRAY;

  setState({ isFetchingApprovers: true });

  if ((selectedApproverType === STAGE_APPROVER_TYPES.ALL || selectedApproverType === STAGE_APPROVER_TYPES.USER) && !_isNil(nextPageTokenForUsers)) {
    const payloadForUsers = getPayload(nextPageTokenForUsers, searchText, userApprovers);
    const usersResponse = await getWorkspaceUserList(payloadForUsers);
    users = tget(usersResponse, 'hits', EMPTY_ARRAY);
    newNextPageTokenForUsers = tget(usersResponse, 'nextPageToken', null);
  }

  if (
    (selectedApproverType === STAGE_APPROVER_TYPES.ALL || selectedApproverType === STAGE_APPROVER_TYPES.USER_GROUP) &&
    !_isNil(nextPageTokenForUserGroups)
  ) {
    const payloadForUserGroups = getPayload(nextPageTokenForUserGroups, searchText, userGroupApprovers);
    const userGroupsResponse = await getAllRecordGroups(payloadForUserGroups);
    userGroups = tget(userGroupsResponse, 'hits', EMPTY_ARRAY);
    newNextPageTokenForUserGroups = tget(userGroupsResponse, 'nextPageToken', null);
  }

  if (
    (selectedApproverType === STAGE_APPROVER_TYPES.ALL || selectedApproverType === STAGE_APPROVER_TYPES.ROLE) &&
    nextChunkIndexForRoles < _size(rolesOptionsChunk)
  ) {
    if (!_isEmpty(searchText)) {
      roles = tget(searchResultsChunkForRoles, nextChunkIndexForRoles, EMPTY_ARRAY);
    } else {
      roles = tget(rolesOptionsChunk, nextChunkIndexForRoles, EMPTY_ARRAY);
    }
    roles = _filter(roles, (role) => !_includes(roleApprovers, tget(role, 'id')));
    newNextChunkIndexForRoles = nextChunkIndexForRoles + 1;
  }

  const optionsForStageApprovers = createOptionsForStageApprovers(roles, users, userGroups);

  const newStageApproversById = getStageApproversById(optionsForStageApprovers);

  const newApproverOptions = [...approverOptions, ...optionsForStageApprovers];

  setState({
    isFetchingApprovers: false,
    nextPageToken: {
      nextPageTokenForUsers: newNextPageTokenForUsers,
      nextPageTokenForUserGroups: newNextPageTokenForUserGroups,
      nextChunkIndexForRoles: newNextChunkIndexForRoles,
    },
    stageApproversById: { ...stageApproversById, ...newStageApproversById },
    approverOptions: newApproverOptions,
  });
};

const handleChangeApproverType = ({ getState, setState, params }) => {
  const { formMode, initialOptionsForStageApprovers = EMPTY_ARRAY } = getState();
  const approverType = tget(params, 'approverType');
  let approverOptions = [];

  // In case of EDIT_MODE, manually adding the data of the Selected users or user groups based on the approverType, as their ids are excluded
  // in the search payload in fetchStageApprovers, so they will not be fetched

  if (formMode === FORM_MODES.EDIT) {
    approverOptions = getApproverOptionsBasedOnApproverType(approverType, initialOptionsForStageApprovers);
  }

  setState(
    {
      nextPageToken: {
        nextChunkIndexForRoles: 0,
        nextPageTokenForUsers: EMPTY_STRING,
        nextPageTokenForUserGroups: EMPTY_STRING,
      },
      selectedApproverType: approverType,
      approverOptions,
    },
    async () => {
      await fetchStageApprovers({ getState, setState });
    },
  );
};

const handleAddStageClick = ({ getState, setState }) => {
  setState(
    {
      isModalVisible: true,
      searchText: EMPTY_STRING,
      nextPageToken: {
        nextChunkIndexForRoles: 0,
        nextPageTokenForUsers: EMPTY_STRING,
        nextPageTokenForUserGroups: EMPTY_STRING,
      },
      selectedApproverType: STAGE_APPROVER_TYPES.ALL,
      formMode: FORM_MODES.CREATE,
      formValues: EMPTY_OBJECT,
      errors: EMPTY_OBJECT,
      selectedApprovers: EMPTY_OBJECT,
      approverOptions: [],
      selectedApproverOptions: [],
    },
    async () => {
      await fetchStageApprovers({ getState, setState });
    },
  );
};

const handleEditStageClick = async ({ getState, setState, params }) => {
  const { value = EMPTY_ARRAY, rolesOptionsChunk = EMPTY_ARRAY } = getState();
  const { index } = params;
  const stageDetailsInEditMode = tget(value, index, EMPTY_OBJECT);
  const formFormattedApprovalStageDetails = getFormFormattedApprovalStageDetails(stageDetailsInEditMode);
  const rolesOptions = _flatten(rolesOptionsChunk);

  setState({
    isModalVisible: true,
    isFetchingApprovers: true,
    formMode: FORM_MODES.EDIT,
    searchText: EMPTY_STRING,
    selectedApproverType: STAGE_APPROVER_TYPES.ALL,
    formValues: formFormattedApprovalStageDetails,
    errors: EMPTY_OBJECT,
    approverOptions: [],
    selectedApproverOptions: [],
  });

  // In Edit Mode, initially fetching the selected users and user groups, and then while fetching the new users and user groups
  // in fetchStageApprovers excluding the selected users and user groups. This is done, so as to put the selected users and user
  // groups at the top, which will provide a better UI experience.

  const { initialOptionsForStageApprovers, userApprovers, userGroupApprovers, roleApprovers } = await resolveSelectedUsersAndUserGroups(
    stageDetailsInEditMode,
    rolesOptions,
  );
  const newStageApproversById = getStageApproversById(initialOptionsForStageApprovers);

  setState(
    {
      stageIndexInEditMode: index,
      nextPageToken: {
        nextChunkIndexForRoles: 0,
        nextPageTokenForUsers: EMPTY_STRING,
        nextPageTokenForUserGroups: EMPTY_STRING,
      },
      stageApproversById: newStageApproversById,
      selectedApprovers: {
        userApprovers,
        userGroupApprovers,
        roleApprovers,
      },
      approverOptions: initialOptionsForStageApprovers,
      selectedApproverOptions: initialOptionsForStageApprovers,
      initialOptionsForStageApprovers,
    },
    async () => {
      await fetchStageApprovers({ getState, setState });
    },
  );
};

const handleFieldChange = ({ getState, setState, params }) => {
  const { selectedApproverOptions = EMPTY_ARRAY, approverOptions = EMPTY_ARRAY, initialOptionsForStageApprovers = EMPTY_ARRAY } = getState();
  const { id, value } = params;

  let newSelectedApproverOptions = [];

  setState(
    produce((draft) => {
      _set(draft, ['formValues', id], value);
      if (id === APPROVAL_CENTRE_FIELD_IDS.APPROVERS) {
        newSelectedApproverOptions = getSelectedApproverOptions(value, selectedApproverOptions, approverOptions, initialOptionsForStageApprovers);
        _set(draft, 'selectedApproverOptions', newSelectedApproverOptions);
      }
    }),
  );
};

const handleChangeApproverSearchText = ({ getState, setState, params }) => {
  const {
    formMode,
    selectedApproverType = STAGE_APPROVER_TYPES.ALL,
    rolesOptionsChunk = EMPTY_ARRAY,
    initialOptionsForStageApprovers = EMPTY_ARRAY,
  } = getState();
  const searchText = tget(params, 'searchText');
  let approverOptions = [];

  // In case of EDIT_MODE and EMPTY SEARCH TEXT, manually adding all the selected approverOptions based on the approverType, as they will
  // not be fetched due to exclusion in search payload

  if (_isEmpty(searchText) && formMode === FORM_MODES.EDIT) {
    approverOptions = getApproverOptionsBasedOnApproverType(selectedApproverType, initialOptionsForStageApprovers);
  }

  // Manually searching and saving the search results of roles as chunk, because it does not has any search api

  const rolesOptions = _flatten(rolesOptionsChunk);
  const searchResultsForRoles = _filter(rolesOptions, (role) => _includes(_toLower(roleReader.displayName(role)), _toLower(searchText)));
  const searchResultsChunkForRoles = _chunk(searchResultsForRoles, ROWS);

  setState(
    {
      nextPageToken: {
        nextChunkIndexForRoles: 0,
        nextPageTokenForUsers: EMPTY_STRING,
        nextPageTokenForUserGroups: EMPTY_STRING,
      },
      searchText,
      searchResultsChunkForRoles,
      approverOptions,
    },
    async () => {
      await fetchStageApprovers({ getState, setState });
    },
  );
};

const handleValidationSuccess = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;
  const { formValues } = getState();

  let newErrors = errors;

  const validityType = tget(formValues, APPROVAL_CENTRE_FIELD_IDS.VALIDITY_TYPE);

  if (validityType === APPROVAL_VALIDITY_TYPES.EXACT) {
    newErrors = _omit(newErrors, [
      APPROVAL_CENTRE_FIELD_IDS.VALIDITY_DAYS,
      APPROVAL_CENTRE_FIELD_IDS.VALIDITY_HOURS,
      APPROVAL_CENTRE_FIELD_IDS.VALIDITY_MINUTES,
    ]);
  } else if (validityType === APPROVAL_VALIDITY_TYPES.RELATIVE) {
    newErrors = _omit(newErrors, [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_EPOCH]);
  }

  setState({ errors: newErrors });
};

const handleDeleteStage = ({ getState, params }) => {
  const { id, value = EMPTY_ARRAY, onAction: approvalProcessFormOnAction } = getState();
  const { index } = params;

  const newStages = [...value];
  newStages.splice(index, 1);

  approvalProcessFormOnAction({
    type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
    payload: {
      id,
      value: newStages,
    },
  });
};

const handleModalSubmit = ({ getState, setState }) => {
  const {
    id,
    stageIndexInEditMode = undefined,
    formMode = FORM_MODES.CREATE,
    formValues,
    stageApproversById,
    value = EMPTY_ARRAY,
    onAction: approvalProcessFormOnAction,
  } = getState();

  const payloadForApprovalStage = getPayloadForApprovalStage(formValues, stageApproversById);

  let newStages = [...value];

  if (formMode === FORM_MODES.CREATE) {
    newStages = [...newStages, payloadForApprovalStage];
  } else if (formMode === FORM_MODES.EDIT) {
    _set(newStages, [stageIndexInEditMode], payloadForApprovalStage);
  }

  approvalProcessFormOnAction({
    type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
    payload: {
      id,
      value: newStages,
    },
  });

  setState({ isModalVisible: false });
};

const handleCancelModal = ({ setState }) => {
  setState({ isModalVisible: false });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [ACTION_TYPES.ON_CHANGE_APPROVER_TYPE]: handleChangeApproverType,
  [ACTION_TYPES.ON_ADD_STAGE_CLICK]: handleAddStageClick,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [ACTION_TYPES.ON_CANCEL_MODAL_CLICK]: handleCancelModal,
  [ACTION_TYPES.ON_CHANGE_APPROVER_SEARCH_TEXT]: handleChangeApproverSearchText,
  [ACTION_TYPES.ON_MODAL_SUBMIT]: handleModalSubmit,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleModalSubmit,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidationSuccess,
  [ACTION_TYPES.ON_SCROLL_END_DROPDOWN]: fetchStageApprovers,
  [ACTION_TYPES.ON_CLICK_EDIT_STAGE]: handleEditStageClick,
  [ACTION_TYPES.ON_DELETE_STAGE_CLICK]: handleDeleteStage,
};

export default ACTION_HANDLERS;
