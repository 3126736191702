const ACTION_TYPES = {
  ON_INIT: 'ON_INIT',
  ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE: 'ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE',
  DELETE_ROW: 'DELETE_ROW',
  ON_CLICK_CONFIGURE_SEARCH_OPTIONS: 'ON_CLICK_CONFIGURE_SEARCH_OPTIONS',
  ON_CLICK_ADD_SEARCH_OPTION: 'ON_CLICK_ADD_SEARCH_OPTION',
  ON_SAVE_MODAL: 'ON_SAVE_MODAL',
  ON_CANCEL_MODAL: 'ON_CANCEL_MODAL',
  ON_CHANGE_MODULE: 'ON_CHANGE_MODULE',
  TABLE_ADD_ROW: 'TABLE_ADD_ROW',
  TABLE_REMOVE_ROW: 'TABLE_REMOVE_ROW',
  TABLE_ON_CHANGE: 'TABLE_ON_CHANGE',
};

export default ACTION_TYPES;
