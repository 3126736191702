import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = '/core/u';
const ROLES_HIERARCHY_BASE_URL = 'core/u/governance/roles';
const USERS_ASSIGNMENT_BASE_URL = `${BASE_URL}/users/update/role`;

const getRoleByName = (roleName) => ApiService.get(`${ROLES_HIERARCHY_BASE_URL}/name/${roleName}`);

const editRoleByName = (roleName, payload = EMPTY_OBJECT) => ApiService.put(`${ROLES_HIERARCHY_BASE_URL}/name/${roleName}`, payload);

const createRole = (payload) => ApiService.post(ROLES_HIERARCHY_BASE_URL, payload);

const getAllRoles = () => ApiService.get(`${ROLES_HIERARCHY_BASE_URL}/name/hierarchy`);

const assignUsersByRoleName = (payload) => ApiService.post(`${USERS_ASSIGNMENT_BASE_URL}`, payload);

const getAllRolesByWorkspaceId = (workspaceId) => ApiService.get(`${BASE_URL}/universe/governance/roles/workspace/${workspaceId}`);

const searchRoles = (payload) => ApiService.post(`${BASE_URL}/governance/roles/search`, payload);

export default {
  createRole,
  getRoleByName,
  editRoleByName,
  getAllRoles,
  assignUsersByRoleName,
  getAllRolesByWorkspaceId,
  searchRoles,
};
