import React from 'react';
import PropTypes from 'prop-types';
import { getPlatePluginType } from '@udecode/plate-core';
import { ToolbarMark } from '@udecode/plate-toolbar';

// components
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

// hooks
import useEditor from '../../../hooks/useEditor';

function MarkToolbarButton(props) {
  const { icon, id: mark } = props;
  const editor = useEditor();

  return <ToolbarMark icon={<FontIcon size={SIZES.MD_S}>{icon}</FontIcon>} type={getPlatePluginType(editor, mark)} />;
}

MarkToolbarButton.propTypes = {
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default React.memo(MarkToolbarButton);
