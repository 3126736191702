import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Loader from '@tekion/tekion-components/molecules/loader';
import Heading from '@tekion/tekion-components/atoms/Heading/Heading';
import { tget } from '@tekion/tekion-base/utils/general';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import TabProperties from './components/tabProperties/TabProperties';
import NavigationProperties from './components/navigationProperties/NavigationProperties';
import { COMPONENTS_TYPES } from '../../../../../../../../constants/applicationRenderer.constants';

import styles from './propertiesPanel.module.scss';
import { getComponentByComponentId } from '../../helpers/applicationBuilder.helpers';

const PropertyPanel = ({ loading, selectedComponentId, applicationConfig, errorObject, applicationBuilderOnAction }) => {
  const selectedComponent = useMemo(
    () => getComponentByComponentId(applicationConfig, selectedComponentId),
    [selectedComponentId, applicationConfig],
  );
  const type = tget(selectedComponent, 'type');

  const PropertyComponent = useMemo(() => {
    switch (type) {
      case COMPONENTS_TYPES.TAB:
        return TabProperties;
      case COMPONENTS_TYPES.NAVIGATION:
        return NavigationProperties;
      case COMPONENTS_TYPES.NO_NAVIGATION:
        return NavigationProperties;
      default:
        return NavigationProperties;
    }
  }, [type]);

  if (loading) return <Loader />;

  return (
    <>
      <Heading className={styles.propertiesHeader} size={3}>
        {__('Properties')}
      </Heading>
      <PropertyComponent
        selectedComponent={selectedComponent}
        selectedComponentId={selectedComponentId}
        applicationConfig={applicationConfig}
        errorObject={errorObject}
        applicationBuilderOnAction={applicationBuilderOnAction}
      />
    </>
  );
};

PropertyPanel.propTypes = {
  loading: PropTypes.bool,
  selectedComponentId: PropTypes.string,
  applicationConfig: PropTypes.object,
  errorObject: PropTypes.object,
  applicationBuilderOnAction: PropTypes.func.isRequired,
};

PropertyPanel.defaultProps = {
  loading: false,
  selectedComponentId: EMPTY_STRING,
  applicationConfig: EMPTY_OBJECT,
  errorObject: EMPTY_OBJECT,
};

export default PropertyPanel;
