/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import { HeadingToolbar } from '@udecode/plate-toolbar';

// constants
import getToolbarStyles from './helpers/toolbar.styles';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// utils
import { getToolbarConfig } from './toolbar.utils';

// styles
import styles from './toolbar.module.scss';

function Toolbar({ toolbarStyles, toolbarGroups }) {
  return (
    <HeadingToolbar style={getToolbarStyles(toolbarStyles)}>
      {_map(getToolbarConfig(toolbarGroups), (groupConfig) => {
        const { options, id: groupId } = groupConfig;
        return (
          <div key={groupId} className={`${styles.controlsGroup} flex align-items-center`}>
            {_map(options, (option) => {
              const { id, renderProps = EMPTY_OBJECT, renderer: Component } = option;
              return <Component key={id} id={id} {...renderProps} />;
            })}
          </div>
        );
      })}
    </HeadingToolbar>
  );
}

Toolbar.propTypes = {
  toolbarStyles: PropTypes.object,
  toolbarGroups: PropTypes.array.isRequired,
};

Toolbar.defaultProps = {
  toolbarStyles: EMPTY_OBJECT,
};

export default React.memo(Toolbar);
