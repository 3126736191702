import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import _size from 'lodash/size';
import _uniqBy from 'lodash/uniqBy';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import { getWorkspaceUserList, removeUserById } from '../../../../../../actions/workspaceUserManagement.actions';
import { fetchRoles } from '../../../../../../actions/rolesHierarchyManagement.actions';

import { getPayload } from './workspaceUserList.helpers';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import { TEXT_STARTS_WITH } from '../../../../../../constants/filter.constants';
import ACTION_TYPES from '../constants/workspaceUserList.actionTypes';
import { TABLE_CONSTANTS, DIALOG_TEXT } from '../constants/workspaceUserList.general.constants';
import { FIELD_IDS as ROLES_FIELD_IDS } from '../../../../../../constants/roles.constants';

import workspaceUserReader from '../../../../../../readers/workSpaceUser.reader';

const handleInit = async ({ getState, setState, params }) => {
  setState({ loading: true });

  const { pageSize, searchField, searchText } = getState();
  const currentPageToken = _get(params, 'nextPageToken');

  const payload = getPayload({
    searchText,
    searchField,
    pageSize,
    filterType: TEXT_STARTS_WITH,
    nextPageToken: currentPageToken,
  });
  const userDataResponse = await getWorkspaceUserList(payload);
  const userList = tget(userDataResponse, 'hits', EMPTY_ARRAY);
  const roleNameList = _map(userList, 'entity.rolename');
  _uniqBy(roleNameList);
  let userRoleList = [];
  let userRoleDataResponse = {};
  if (!_isEmpty(roleNameList)) {
    const userRolePayload = {
      rows: _size(roleNameList),
      filters: [{ filterType: OPERATORS.IN, field: ROLES_FIELD_IDS.ROLE_NAME, values: roleNameList }],
    };
    userRoleDataResponse = await fetchRoles(userRolePayload);
    userRoleList = tget(userRoleDataResponse, 'hits', EMPTY_ARRAY);
  }
  const userRoleListByName = _keyBy(userRoleList, ROLES_FIELD_IDS.ROLE_NAME);

  const updatedUserList = _map(userList, (user) => {
    const roleName = workspaceUserReader.roleName(user);
    const userRoleData = _get(userRoleListByName, roleName);
    return { ...user, rolename: _get(userRoleData, ROLES_FIELD_IDS.DISPLAY_NAME) };
  });

  setState({
    loading: false,
    users: updatedUserList,
    totalNumberOfEntries: _get(userDataResponse, 'count'),
    nextPageToken: _get(userDataResponse, 'nextPageToken'),
  });
};

const handleSearchApply = async ({ getState, setState, params }) => {
  const searchText = _get(params, 'value');
  const currentPage = TABLE_CONSTANTS.CURRENT_PAGE;

  setState(
    {
      searchText,
      currentPage,
    },
    () => {
      handleInit({ getState, setState });
    },
  );
};

const handleRefreshListView = ({ getState, setState }) => {
  setState({ currentPage: 0 }, () => {
    handleInit({ getState, setState });
  });
};

const handlePageUpdate = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  let pageNo = _get(params, 'value.page');
  const resultsPerPage = _get(params, 'value.resultsPerPage');
  let prevPageTokens = [...(previousPageTokens || [])];
  let currentPageToken = null;

  if (pageNo > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (pageNo === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }

  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleInit({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const handleSearchFieldChange = ({ setState, params }) => {
  const searchField = _get(params, 'value');
  setState({ searchField });
};

const handleCreateNewUser = ({ getState }) => {
  const { history } = getState();
  const path = `${STUDIO_ROUTE}/${PAGE_IDS.USER_CREATE}`;
  history.push(path);
};

const handleCloseRequestDialog = ({ setState }) => {
  setState({ isRequestDialogVisible: false });
};

const handleConfirmRequest = async ({ getState, setState }) => {
  const { selectedUser } = getState();
  const userId = _get(selectedUser, 'id');
  const workspaceId = _get(selectedUser, 'workspaceId');
  const response = await removeUserById(userId, workspaceId);
  if (response === 'success') {
    handleInit({ setState, getState });
  }
  setState({ isRequestDialogVisible: false });
};

const handleEditUserRequest = ({ getState, params }) => {
  const { history } = getState();
  const userId = _get(params, 'id');
  const path = `${STUDIO_ROUTE}/${PAGE_IDS.USER_EDIT}/${userId}`;
  history.push({ pathname: path, state: { userInfo: params } });
};

const handleRemoveUserRequest = ({ setState, params }) => {
  const userName = _get(params, 'entity.displayName');
  setState({
    isRequestDialogVisible: true,
    dialogText: DIALOG_TEXT.REMOVE_TEXT(userName),
    selectedUser: params,
  });
};

const rowClickHandler = ({ getState, params }) => {
  const userData = _get(params, 'value.original', EMPTY_OBJECT);
  handleEditUserRequest({ getState, params: userData });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchApply,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleRefreshListView,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_SEARCH_FIELD]: handleSearchFieldChange,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: rowClickHandler,
  [ACTION_TYPES.CREATE_USER]: handleCreateNewUser,
  [ACTION_TYPES.ON_CLOSE_REQUEST_DIALOG]: handleCloseRequestDialog,
  [ACTION_TYPES.ON_CONFIRM_REQUEST]: handleConfirmRequest,
  [ACTION_TYPES.EDIT]: handleEditUserRequest,
  [ACTION_TYPES.REMOVE]: handleRemoveUserRequest,
};

export default ACTION_HANDLERS;
