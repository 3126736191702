import React, { useMemo } from 'react';
import cx from 'classnames';
import { useDrag } from 'react-dnd';
import PropTypes from 'prop-types';

import _includes from 'lodash/includes';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import { tget } from '@tekion/tekion-base/utils/general';

import { COMPONENT_TYPES } from '../../constants/viewBuilder.constants';
import { componentTypeToDisplayNameMap } from '../../constants/componentType.constant';
import { ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './componentPanels.module.scss';

const Component = ({ className, componentConfig, usedEntityFieldNames }) => {
  const sectionId = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
  const type = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.TYPE);
  const properties = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES);

  const displayName = tget(componentTypeToDisplayNameMap, type, _get(properties, ALL_VIEW_PROPERTY_KEYS.TITLE, EMPTY_STRING));
  const isDisabled = useMemo(
    () =>
      _reduce(
        usedEntityFieldNames,
        (result, entityFieldName) => result || _includes(tget(properties, ALL_VIEW_PROPERTY_KEYS.FIELD_NAMES), entityFieldName),
        false,
      ),
    [properties, usedEntityFieldNames],
  );

  const [{ isDragging }, dragRef] = useDrag({
    type,
    item: componentConfig,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !isDisabled,
  });

  return (
    <div
      ref={dragRef}
      key={sectionId}
      className={cx(styles.component, { [styles.isDragging]: isDragging, [styles.isDisabled]: isDisabled }, className)}
    >
      <FontIcon size={SIZES.S} className={styles.icon}>
        icon-operations
      </FontIcon>
      <div className={styles.title}>{displayName}</div>
    </div>
  );
};

Component.propTypes = {
  className: PropTypes.string,
  componentConfig: PropTypes.objectOf({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.keys(COMPONENT_TYPES)).isRequired,
  }).isRequired,
  usedEntityFieldNames: PropTypes.array,
};

Component.defaultProps = {
  className: EMPTY_STRING,
  usedEntityFieldNames: EMPTY_ARRAY,
};

export default Component;
