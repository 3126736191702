exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".columnConfig_columnTableContainer__9Joqtfjg2Z{width:100%}[dir] .columnConfig_columnTableContainer__9Joqtfjg2Z{padding:0 1.6rem;margin-bottom:2.4rem}.columnConfig_columnTableContainer__9Joqtfjg2Z .columnConfig_addColumnContainer__bEVYJmwKTL{display:flex;justify-content:space-between;align-items:center}[dir] .columnConfig_columnTableContainer__9Joqtfjg2Z .columnConfig_addColumnContainer__bEVYJmwKTL .columnConfig_heading__6vjHFeLKDT{margin-bottom:1.6rem}[dir] .columnConfig_columnTableContainer__9Joqtfjg2Z .columnConfig_addColumnContainer__bEVYJmwKTL .columnConfig_button__wnRkC9yMdn{margin-bottom:1.6rem}", ""]);

// Exports
exports.locals = {
	"columnTableContainer": "columnConfig_columnTableContainer__9Joqtfjg2Z",
	"addColumnContainer": "columnConfig_addColumnContainer__bEVYJmwKTL",
	"heading": "columnConfig_heading__6vjHFeLKDT",
	"button": "columnConfig_button__wnRkC9yMdn"
};