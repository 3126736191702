import React from 'react';

import { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import IconWithPopover from '@tekion/tekion-components/molecules/IconWithPopover';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import FIELD_IDS from '../constants/approvalProcessForm.fieldIds';
import { APPROVAL_VALIDITY_TYPES, CUSTOM_ENTITY_CATEGORY } from '../../../../../../../constants/approvalCentre.constants';

import styles from '../../../approvalStudio.module.scss';

const validitySectionHeader = (
  <div className="flex">
    {__('Validity for requests')}
    <IconWithPopover
      iconClass="inline m-l-8 align-self-center"
      icon="icon-info"
      size={SIZES.MD}
      content={<div className="p-8">{__('Validity for requests created using this process')}</div>}
    />
  </div>
);

const rowCheck = {
  [FIELD_IDS.TYPE]: ({ [FIELD_IDS.CATEGORY]: category } = {}) => category === CUSTOM_ENTITY_CATEGORY,
  [FIELD_IDS.VALIDITY_DAYS]: ({ [FIELD_IDS.VALIDITY_TYPE]: validityType } = {}) => validityType === APPROVAL_VALIDITY_TYPES.RELATIVE,
  [FIELD_IDS.VALIDITY_HOURS]: ({ [FIELD_IDS.VALIDITY_TYPE]: validityType } = {}) => validityType === APPROVAL_VALIDITY_TYPES.RELATIVE,
  [FIELD_IDS.VALIDITY_MINUTES]: ({ [FIELD_IDS.VALIDITY_TYPE]: validityType } = {}) => validityType === APPROVAL_VALIDITY_TYPES.RELATIVE,
  [FIELD_IDS.VALIDITY_EPOCH]: ({ [FIELD_IDS.VALIDITY_TYPE]: validityType } = {}) => validityType === APPROVAL_VALIDITY_TYPES.EXACT,
};

const getSections = (values) => [
  {
    className: styles.section,
    rows: [{ columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME] }],
  },
  {
    className: styles.section,
    rows: [{ columns: [FIELD_IDS.GROUP, FIELD_IDS.CATEGORY] }],
  },
  {
    className: styles.section,
    rows: [{ columns: filterRows([FIELD_IDS.TYPE], values, rowCheck) }],
  },
  {
    className: styles.conditionSection,
    rows: [{ columns: [FIELD_IDS.CONDITION] }],
  },

  {
    className: styles.section,
    rows: [{ columns: [FIELD_IDS.PRIORITY] }],
  },
  {
    className: styles.fullWidthSection,
    rows: [{ columns: [FIELD_IDS.STAGES] }],
  },
  {
    header: {
      label: validitySectionHeader,
    },
    className: styles.section,
    rows: [
      { columns: [FIELD_IDS.VALIDITY_TYPE] },
      {
        columns: filterRows(
          [FIELD_IDS.VALIDITY_DAYS, FIELD_IDS.VALIDITY_HOURS, FIELD_IDS.VALIDITY_MINUTES, FIELD_IDS.VALIDITY_EPOCH],
          values,
          rowCheck,
        ),
      },
    ],
  },
];

export default getSections;
