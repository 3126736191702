import _unset from 'lodash/unset';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { FIELD_IDS } from '../constants/customActionsVisualConfigurator.constants';
import ACTION_TYPES from '../constants/customActionsVisualConfigurator.actionTypes';
import { ACTION_TYPES as VISUAL_BUILDER_ACTION_TYPES, COMPONENT_CONFIG_KEYS } from '../../../../../constants/visualBuilder.general.constants';
import { CUSTOM_ACTIONS_PROPERTIES } from '../../../../../constants/visualBuilder.properties.constants';

const handleSelectedWidgetPropertiesChange = ({ setState, params = EMPTY_OBJECT }) => {
  const { selectedWidgetConfigProperties } = params;
  const entityName = tget(selectedWidgetConfigProperties, COMPONENT_CONFIG_KEYS.ENTITY_NAME);
  const actionsToShow = tget(selectedWidgetConfigProperties, CUSTOM_ACTIONS_PROPERTIES.ACTION_NAMES);

  setState({ formValues: { [FIELD_IDS.ACTIONS_TO_SHOW]: actionsToShow }, entityName });
};

const handleConfigureActionsClick = ({ getState, setState }) => {
  const { formValues = EMPTY_OBJECT } = getState();

  setState({
    prevState: {
      formValues,
    },
    isModalVisible: true,
  });
};

const handleOnChange = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { onChangeWidgetConfigurationAction, selectedWidgetConfig } = getState();
  const widgetName = tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME);
  const selectedWidgetConfigProperties = tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, {});

  if (id === CUSTOM_ACTIONS_PROPERTIES.SHOW_ACTIONS) {
    if (!value) {
      _unset(selectedWidgetConfigProperties, CUSTOM_ACTIONS_PROPERTIES.ACTION_NAMES);
    }
    onChangeWidgetConfigurationAction({
      type: VISUAL_BUILDER_ACTION_TYPES.UPDATE_WIDGET_CONFIGURATION,
      payload: {
        value: {
          widgetConfiguration: {
            ...selectedWidgetConfig,
            properties: { ...selectedWidgetConfigProperties, [CUSTOM_ACTIONS_PROPERTIES.SHOW_ACTIONS]: value },
          },
          widgetName,
        },
      },
    });
  }
  const newFormValues = { [id]: value };

  setState({ formValues: newFormValues });
};

const handleSubmit = ({ getState, setState }) => {
  const { formValues, onChangeWidgetConfigurationAction, selectedWidgetConfig } = getState();

  const widgetName = tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME);
  const actionNames = tget(formValues, [FIELD_IDS.ACTIONS_TO_SHOW], EMPTY_ARRAY);

  const selectedWidgetConfigProperties = tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, {});

  onChangeWidgetConfigurationAction({
    type: VISUAL_BUILDER_ACTION_TYPES.UPDATE_WIDGET_CONFIGURATION,
    payload: {
      value: {
        widgetConfiguration: {
          ...selectedWidgetConfig,
          properties: { ...selectedWidgetConfigProperties, [CUSTOM_ACTIONS_PROPERTIES.ACTION_NAMES]: actionNames },
        },
        widgetName,
      },
    },
  });

  setState({ isModalVisible: false });
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;

  setState({ errors });
};

const handleCancelModal = ({ getState, setState }) => {
  const { prevState = EMPTY_OBJECT } = getState();

  setState({ isModalVisible: false, ...prevState });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_SELECTED_WIDGET_PROPERTIES_CHANGE]: handleSelectedWidgetPropertiesChange,
  [ACTION_TYPES.ON_CLICK_CONFIGURE_ACTIONS]: handleConfigureActionsClick,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL_MODAL]: handleCancelModal,
};

export default ACTION_HANDLERS;
