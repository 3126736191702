import _filter from 'lodash/filter';

import { tget } from '@tekion/tekion-base/utils/general';

import { hasUserAccessDenied, hasUserReadAccessType, hasUserReadWriteAccessType } from './manageRecord.general';

import {
  ALL_ACCESS_ACCESS_FILTER,
  ACCESS_DENIED_ACCESS_TYPE_FILTER,
  READ_ACCESS_TYPE_FILTER,
  READ_WRITE_ACCESS_TYPE_FILTER,
} from '../molecules/accessTypeFilter';

import styles from '../manageRecordAccessTable.module.scss';

const getDataForReadWriteAccessType = (data) => _filter(data, hasUserReadWriteAccessType);

const getDataForReadAccessType = (data) => _filter(data, hasUserReadAccessType);

const getDataForAccessDenied = (data) => _filter(data, hasUserAccessDenied);

const getFilteredData = (data, selectedAccessType) => {
  switch (selectedAccessType) {
    case ALL_ACCESS_ACCESS_FILTER.id: {
      return data;
    }

    case ACCESS_DENIED_ACCESS_TYPE_FILTER.id: {
      return getDataForAccessDenied(data);
    }

    case READ_ACCESS_TYPE_FILTER.id: {
      return getDataForReadAccessType(data);
    }

    case READ_WRITE_ACCESS_TYPE_FILTER.id: {
      return getDataForReadWriteAccessType(data);
    }

    default: {
      return data;
    }
  }
};

const getClassNameFromRowInfo = (isSelectedAccessTypeNotOfAccessDenied) => (_, rowInfo) => {
  const original = tget(rowInfo, 'original');

  const isAccessDenied = hasUserAccessDenied(original);

  if (isAccessDenied && isSelectedAccessTypeNotOfAccessDenied) {
    return styles.accessDenied;
  }

  return '';
};

export { getFilteredData, getClassNameFromRowInfo };
