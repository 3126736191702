import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';

import Spinner from '@tekion/tekion-components/molecules/SpinnerComponent';
import withSize from '../../../../../connectors/withSize';
import NavigationItem from '../../../../../molecules/NavigationFlow/components/navigationItem';

// Constants
import { ACTION_TYPES } from './constants/entityFieldPermissionsConfigure.actionTypes';
import { DEFAULT_PERMISSION_FORM_PROPS, PERMISSION_SET_NAVIGATION_DEFAULT_DATA } from './constants/entityFieldPermissionsConfigure.general.constants';
import PAGE_IDS from '../../../constants/PageIds.constants';
import { STUDIO_ROUTE } from '../../../../../constants/routes';
import { TABS } from '../permissionSetConfigure/constants/permissionSetConfigure.constants';

// Helpers
import ACTION_HANDLERS from './formHelpers/entityFieldPermissionsConfigure.actionHandlers';
import EntityFieldPermissionTable from './components/entityFieldPermissionTable/EntityFieldPermissionTable';
import styles from './entityFieldPermissionsConfigure.module.scss';

const EntityFieldPermissionsConfigure = ({
  isDataLoading,
  isSaveLoading,
  contentHeight,
  entityPermissions,
  fieldPermissions,
  history,
  match,
  columnHeaderCheckbox,
  disabledFieldPermissionIds,
  permissionData,
  onAction,
}) => {
  const entityName = _get(match, 'params.entityName');
  const permissionName = _get(match, 'params.permissionName', '');

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INITIALIZE_FORM_VALUES });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_SUBMIT });
  }, [onAction]);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_REDIRECTION });
  }, [onAction]);

  const headerNavigationData = useMemo(
    () => [
      ...PERMISSION_SET_NAVIGATION_DEFAULT_DATA,
      {
        label: __(_get(permissionData, 'name', NO_DATA)),
        key: '2',
        goTo: `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_CONFIGURE}/${permissionName}/${TABS.ENTITY_SETTINGS}`,
      },
      { label: __(entityName), key: '3' },
    ],
    [permissionData, entityName, permissionName],
  );

  const handleNavigationItemClick = useCallback(
    (goTo) => {
      if (!_isEmpty(goTo)) {
        history.push({ pathname: goTo, state: { permissionData } });
      }
    },
    [history, permissionData],
  );

  const headerNavigation = useMemo(
    () =>
      _map(headerNavigationData, (data, index) => (
        <NavigationItem
          key={_get(data, 'key', EMPTY_STRING)}
          itemNumber={index}
          totalItems={_size(headerNavigationData)}
          data={data}
          onNavigationItemClick={handleNavigationItemClick}
        />
      )),
    [headerNavigationData, handleNavigationItemClick],
  );

  return (
    <Page>
      <Page.Header>
        <Heading className={styles.headerNavigationContainer} size={2}>
          {headerNavigation}
        </Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight, overflowY: 'scroll' }}>
        {isDataLoading && (
          <div className={styles.spinnerWrap}>
            <Spinner size="medium" />
          </div>
        )}
        {!isDataLoading && (
          <>
            <EntityFieldPermissionTable data={entityPermissions} permissionTableType="Entity" onAction={onAction} />
            <EntityFieldPermissionTable
              data={fieldPermissions}
              permissionTableType="Field"
              onAction={onAction}
              columnHeaderCheckbox={columnHeaderCheckbox}
              disabledFieldPermissionIds={disabledFieldPermissionIds}
            />
          </>
        )}
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          primaryButtonLabel={__('Save')}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancel}
          primaryActionLoading={isSaveLoading}
        />
      </Page.Footer>
    </Page>
  );
};
EntityFieldPermissionsConfigure.propTypes = {
  isDataLoading: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  contentHeight: PropTypes.number,
  columnHeaderCheckbox: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  permissionData: PropTypes.object,
  entityPermissions: PropTypes.array,
  fieldPermissions: PropTypes.array,
  disabledFieldPermissionIds: PropTypes.array,
  onAction: PropTypes.func,
};

EntityFieldPermissionsConfigure.defaultProps = {
  isDataLoading: false,
  isSaveLoading: false,
  contentHeight: 0,
  columnHeaderCheckbox: EMPTY_OBJECT,
  match: EMPTY_OBJECT,
  history: EMPTY_OBJECT,
  permissionData: EMPTY_OBJECT,
  entityPermissions: EMPTY_ARRAY,
  fieldPermissions: EMPTY_ARRAY,
  disabledFieldPermissionIds: EMPTY_ARRAY,
  onAction: _noop,
};

export default compose(
  withSize({ hasPageFooter: 1, hasPageHeader: 1 }),
  withActions(DEFAULT_PERMISSION_FORM_PROPS, ACTION_HANDLERS),
  React.memo,
)(EntityFieldPermissionsConfigure);
