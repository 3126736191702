import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import Page from '@tekion/tekion-components/molecules/pageComponent';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Loader from '@tekion/tekion-components/molecules/loader';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { ACTION_TYPES, CONTEXT_ID } from './entityForm.constants';
import { FORM_MODES } from '../../../../constants/general.constants';
import withSize from '../../../../connectors/withSize';
import ACTION_HANDLERS from './entityForm.actionHandlers';

// Form configurations
import getFields from './entityForm.fields';
import { getSections } from './entityForm.sections';

const EntityForm = ({ formMode, entity, errors, contentHeight, isSavingDetails, isEntityDataLoading, onAction }) => {
  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_MOUNT });
  }, [onAction]);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const headerTitle = formMode === FORM_MODES.CREATE ? __('Create Entity') : __('Update Entity');

  const triggerFormSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, []);

  const handleRedirect = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_REDIRECTION,
    });
  }, [onAction]);

  const fields = useMemo(() => getFields(formMode, entity), [entity, formMode]);
  const sections = useMemo(() => getSections(formMode), [formMode]);

  if (isEntityDataLoading) {
    return <Loader style={{ height: 316 }} />;
  }
  return (
    <Page>
      <Page.Header hasBack goBackHandler={handleRedirect}>
        <Heading>{headerTitle}</Heading>
      </Page.Header>
      <Page.Body className="full-width flex" style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          className="full-width p-t-12"
          contextId={CONTEXT_ID}
          fields={fields}
          sections={sections}
          values={entity}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id={CONTEXT_ID}
          primaryActionLoading={isSavingDetails}
          primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Create') : __('Update')}
          onPrimaryAction={triggerFormSubmit}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

EntityForm.propTypes = {
  isSavingDetails: PropTypes.bool,
  isEntityDataLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  entity: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func,
};

EntityForm.defaultProps = {
  isSavingDetails: false,
  isEntityDataLoading: false,
  entity: undefined,
  formMode: FORM_MODES.CREATE,
  errors: EMPTY_OBJECT,
  onAction: _noop,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(EntityForm);
