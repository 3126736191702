import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _valuesIn from 'lodash/valuesIn';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { RECORD_TYPES } from '../../../../constants/general.constants';
import SYSTEM_DEFINED_FIELDS from '../../../../constants/systemDefinedFields';

import entityReader from '../../../../readers/entity.reader';

const getRecordsFromEntityRecord = (entityRecord, entityDef, currentRecordType) => {
  const isRecordTypeEnabled = entityReader.recordTypeEnabled(entityDef);
  if (!isRecordTypeEnabled) {
    return { recordTypeSelectionRecord: {}, formData: entityRecord };
  }

  const flattedEntityRecord = { ...entityRecord, ...tget(entityRecord, 'entity', {}) };
  const recordTypeDefType = entityReader.recordTypeDefinitionType(entityDef);

  if (recordTypeDefType === RECORD_TYPES.DERIVED) {
    const derivedFields = entityReader.derivedRecordTypeFields(entityDef);
    const recordTypeSelectionRecord = {
      recordTypeId: tget(flattedEntityRecord, 'recordTypeId', currentRecordType),
      entity: {
        ..._pick(flattedEntityRecord, derivedFields),
      },
    };
    const formData = {
      ..._omit(entityRecord, ['entity', 'recordTypeId']),
      entity: {
        ..._omit(flattedEntityRecord, [...derivedFields, ..._valuesIn(SYSTEM_DEFINED_FIELDS)]),
      },
    };

    return { recordTypeSelectionRecord, formData };
  } else if (recordTypeDefType === RECORD_TYPES.STANDARD) {
    const recordTypeSelectionRecord = {
      recordTypeId: tget(flattedEntityRecord, 'recordTypeId', currentRecordType),
    };
    const formData = {
      ..._omit(entityRecord, ['entity', 'recordTypeId']),
      entity: {
        ..._omit(flattedEntityRecord, SYSTEM_DEFINED_FIELDS),
      },
    };
    return { recordTypeSelectionRecord, formData };
  }

  return { recordTypeSelectionRecord: EMPTY_OBJECT, formData: EMPTY_OBJECT };
};

export { getRecordsFromEntityRecord };
