import { Transforms, Element, Editor } from 'slate';
import _set from 'lodash/set';
import _times from 'lodash/times';
import _forEach from 'lodash/forEach';
import _every from 'lodash/every';
import _size from 'lodash/size';
import _reduce from 'lodash/reduce';
import { getAbove, setNodes } from '@udecode/plate-common';

// utils
import { uuid } from '@tekion/tekion-base/utils/general';

const setRowAndColumnIdInCellNodes = ({ cellNodes, rowId, columnIds, editor, path }) => {
  _forEach(cellNodes, (tableCellNode, index) => {
    setNodes(editor, { rowId, columnId: columnIds[index] }, { at: [...path, index] });
  });
};

const generateColumnIds = (tableRowNode) => {
  const noOfColumns = _size(tableRowNode.children);
  const columnIds = _times(noOfColumns, uuid);
  return columnIds;
};

// current deserialization does not add row and column ids
// current implementation does not support merged cells
const handleRowCopyPaste = ({ editor, rowNode, rowNodePath, columnIds }) => {
  const cellNodes = rowNode.children;
  const rowId = uuid();
  setRowAndColumnIdInCellNodes({ editor, path: rowNodePath, cellNodes, columnIds, rowId });
  setNodes(editor, { rowId }, { at: rowNodePath });
};

const handleTableCopyPaste = (editor, tableNode, tableNodePath) => {
  const nodes = Editor.nodes(editor, { at: tableNodePath });
  const columnIds = generateColumnIds(tableNode?.children?.[0]);
  // eslint-disable-next-line no-restricted-syntax
  for (const [node, path] of nodes) {
    if (node?.type === 'tr') {
      handleRowCopyPaste({
        editor,
        tableNode,
        rowNode: node,
        rowNodePath: path,
        columnIds,
      });
    }
  }
};

const addRowCellCount = (rowCellCounts, rowNode) => {
  const noOfCellsInRow = _size(rowNode?.children);
  rowCellCounts.push(noOfCellsInRow);
  return rowCellCounts;
};

const checkIfRowCellCountIsEqual = (firstRowCellCount) => (rowChildrenCount) => rowChildrenCount === firstRowCellCount;

const checkIfCopiedTableIsIncomplete = (tableNode) => {
  const rowNode = tableNode?.children?.[0];
  // to check if table is copied
  if (rowNode?.rowId) {
    return false;
  }
  const rowCellCounts = _reduce(tableNode?.children, addRowCellCount, []);
  return !_every(rowCellCounts, checkIfRowCellCountIsEqual(rowCellCounts[0]));
};

const makeNormalizeNode = (editor, defaultNormalizeNode) => (entry) => {
  const [node, path] = entry;

  if (Element.isElement(node) && (node.type === 'tr' || node.type === 'td' || node.type === 'th' || node.type === 'table')) {
    const parentCellNodeEntry = getAbove(editor, { match: { type: ['td', 'th'] }, at: path });
    // remove table, tr, td, th nodes which are inside another table
    if (parentCellNodeEntry) {
      Transforms.removeNodes(editor, { at: path });
      return;
    }
    // remove node if only a certain portion of table is copied
    const parentTableNodeEntry = getAbove(editor, { match: { type: ['table'] }, at: path });
    if (!parentTableNodeEntry && (node.type === 'tr' || node.type === 'td' || node.type === 'th')) {
      Transforms.removeNodes(editor, { at: path });
      return;
    }
    // removing table if all the tr does not have equal number of children
    if (node.type === 'table' && checkIfCopiedTableIsIncomplete(node)) {
      Transforms.removeNodes(editor, { at: path });
      return;
    }
    // add row and column ids to all the table rows and cells
    if (node.type === 'table' && !node?.children?.[0]?.rowId) {
      handleTableCopyPaste(editor, node, path);
      return;
    }
  }

  defaultNormalizeNode(entry);
};

const withTableCopyPaste = () => (editor) => {
  const { normalizeNode } = editor;

  _set(editor, 'normalizeNode', makeNormalizeNode(editor, normalizeNode));

  return editor;
};

export default withTableCopyPaste;
