import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import withEventSubmissionHOC from '@tekion/tekion-components/pages/formPage/withEventSubmission';
import { getFormFields, getFormSections } from './helpers/addTableWidget.formConfig';
import { ADD_WIDGET_FORM_FIELDS } from './constants/addWidget.general.constant';

const Form = withEventSubmissionHOC(FormBuilder);

const AddTableWidget = (props) => {
  const {
    widgetName,
    contextId,
    formValues,
    errors,
    widgetMetaData,
    selectedDimensions,
    selectedMeasurements,
    possibleXAxis,
    possibleYAxis,
    onAction,
  } = props;

  const entityName = _get(formValues, `${ADD_WIDGET_FORM_FIELDS.ENTITY_NAME}.0`, '');
  const sections = useMemo(() => getFormSections(entityName), [entityName]);

  const fields = useMemo(
    () =>
      getFormFields({
        possibleXAxis,
        possibleYAxis,
        selectedDimensions,
        selectedMeasurements,
        widgetMetaData,
        widgetName,
      }),
    [possibleXAxis, possibleYAxis, selectedDimensions, selectedMeasurements, widgetName, widgetMetaData],
  );
  return <Form fields={fields} values={formValues} onAction={onAction} sections={sections} errors={errors} contextId={contextId} />;
};

AddTableWidget.propTypes = {
  widgetName: PropTypes.string,
  contextId: PropTypes.string,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  widgetMetaData: PropTypes.object,
  selectedDimensions: PropTypes.array,
  selectedMeasurements: PropTypes.array,
  possibleXAxis: PropTypes.array,
  possibleYAxis: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

AddTableWidget.defaultProps = {
  widgetName: EMPTY_STRING,
  contextId: EMPTY_STRING,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  widgetMetaData: EMPTY_OBJECT,
  selectedDimensions: EMPTY_ARRAY,
  selectedMeasurements: EMPTY_ARRAY,
  possibleXAxis: EMPTY_ARRAY,
  possibleYAxis: EMPTY_ARRAY,
};

export default AddTableWidget;
