import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _filter from 'lodash/filter';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import SingleSelect from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SingleSelect';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import ButtonRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/button';
import Switch from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { tget } from '@tekion/tekion-base/utils/general';
import TextAreaField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';

import MonacoEditor, { CODE_LANGUAGES } from '../../../../../molecules/monacoEditor/MonacoEditor';

import { getCustomStyleOptionsForComponentType, getSelectedCustomStyles } from './customStylesConfigurator.helper';

import { FIELD_IDS } from '../constants/customStylesConfigurator.constants';
import { HELPER_CUSTOM_STYLES_INITIAL_VALUE_FOR_MONACO_EDITOR } from '../../../../../constants/customStyles.constants';

import customStylesReader from '../../../../../readers/customStyles.reader';

import styles from '../customStylesConfigurator.module.scss';

const CUSTOM_STYLE_TYPE_FIELD = {
  id: FIELD_IDS.CUSTOM_STYLE_TYPE,
  renderer: SingleSelect,
  renderOptions: {
    label: __('Type'),
    required: true,
    validators: [isRequiredRule],
    noOptionsMessage: () => __('No Available Style Types for this Selection.'),
  },
};

const CUSTOM_STYLE_CODE_PANEL_FIELD = {
  id: FIELD_IDS.CODE_PANEL,
  renderer: Switch,
  renderOptions: {
    label: __('Code Panel'),
  },
};

const CUSTOM_STYLE_SCRIPT_FIELD = {
  id: FIELD_IDS.SCRIPT,
  renderer: MonacoEditor,
  renderOptions: {
    required: true,
    validators: [isRequiredRule],
    label: __('Note'),
    helpText: __('Use /* ... */ for adding comments'),
    fieldLabelClassName: styles.codePanelInfoFieldLabel,
    infoBadgeClassName: styles.codePanelInfoBadge,
    language: CODE_LANGUAGES.JAVASCRIPT,
    height: '20rem',
    defaultValue: HELPER_CUSTOM_STYLES_INITIAL_VALUE_FOR_MONACO_EDITOR,
  },
};

const CUSTOM_STYLES_OBJECT_FIELD = {
  id: FIELD_IDS.VALUE,
  renderer: TextAreaField,
  renderOptions: {
    containerClassName: styles.textAreaValueField,
  },
};

const CUSTOM_STYLE_ERROR_FIELD = {
  id: FIELD_IDS.ERROR_MESSAGE,
  renderer: FieldLabel,
  renderOptions: {
    labelClassName: styles.codePanelErrorLabel,
    fieldLabelClassName: styles.codePanelErrorFieldLabel,
  },
};

const CUSTOM_STYLE_BEAUTIFY_BUTTON_FIELD = {
  id: FIELD_IDS.BEAUTIFY_BUTTON,
  renderer: ButtonRenderer,
  renderOptions: {
    id: FIELD_IDS.BEAUTIFY_BUTTON,
    label: __('Beautify'),
    className: styles.beautifyButton,
    view: ButtonRenderer.VIEW.TERTIARY,
  },
};

const CUSTOM_STYLE_FORM_FIELDS = {
  [FIELD_IDS.CUSTOM_STYLE_TYPE]: CUSTOM_STYLE_TYPE_FIELD,
  [FIELD_IDS.CODE_PANEL]: CUSTOM_STYLE_CODE_PANEL_FIELD,
  [FIELD_IDS.ERROR_MESSAGE]: CUSTOM_STYLE_ERROR_FIELD,
  [FIELD_IDS.VALUE]: CUSTOM_STYLES_OBJECT_FIELD,
  [FIELD_IDS.SCRIPT]: CUSTOM_STYLE_SCRIPT_FIELD,
  [FIELD_IDS.BEAUTIFY_BUTTON]: CUSTOM_STYLE_BEAUTIFY_BUTTON_FIELD,
};

const getFields = (customStylesConfiguration, allFormValues, componentType, errors) => {
  const customStyleType = customStylesReader.customStyleType(customStylesConfiguration);
  const { selectedCustomStyleTypes } = getSelectedCustomStyles(allFormValues);
  const customStyleOptions = getCustomStyleOptionsForComponentType(componentType);
  const newCustomStyleOptions = _filter(customStyleOptions, ({ value }) => customStyleType === value || !_includes(selectedCustomStyleTypes, value));

  return {
    ...CUSTOM_STYLE_FORM_FIELDS,
    [FIELD_IDS.CUSTOM_STYLE_TYPE]: addToRenderOptions(CUSTOM_STYLE_FORM_FIELDS[FIELD_IDS.CUSTOM_STYLE_TYPE], [
      { path: 'options', value: newCustomStyleOptions || [] },
    ]),
    [FIELD_IDS.CODE_PANEL]: addToRenderOptions(CUSTOM_STYLE_FORM_FIELDS[FIELD_IDS.CODE_PANEL], [
      { path: 'disabled', value: _isEmpty(customStyleType) },
    ]),
    [FIELD_IDS.ERROR_MESSAGE]: addToRenderOptions(CUSTOM_STYLE_FORM_FIELDS[FIELD_IDS.ERROR_MESSAGE], [
      { path: 'label', value: tget(errors, FIELD_IDS.ERROR_MESSAGE) },
    ]),
    [FIELD_IDS.VALUE]: addToRenderOptions(CUSTOM_STYLE_FORM_FIELDS[FIELD_IDS.VALUE], [{ path: 'disabled', value: _isEmpty(customStyleType) }]),
    [FIELD_IDS.BEAUTIFY_BUTTON]: addToRenderOptions(CUSTOM_STYLE_FORM_FIELDS[FIELD_IDS.BEAUTIFY_BUTTON], [
      { path: 'disabled', value: _isEmpty(customStyleType) },
    ]),
  };
};

export default getFields;

export { FIELD_IDS };
