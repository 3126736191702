exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".roleHeader_roleHeaderContainer__hyjeVfHCYd{display:flex;align-items:center}[dir] .roleHeader_roleHeaderContainer__hyjeVfHCYd{border-bottom:.1rem solid #edeef0}.roleHeader_roleHeaderContainer__hyjeVfHCYd .roleHeader_actionsContainer__btiNUyAuRu{align-items:center;position:absolute;display:none}[dir=ltr] .roleHeader_roleHeaderContainer__hyjeVfHCYd .roleHeader_actionsContainer__btiNUyAuRu{left:80%}[dir=rtl] .roleHeader_roleHeaderContainer__hyjeVfHCYd .roleHeader_actionsContainer__btiNUyAuRu{right:80%}.roleHeader_roleHeaderContainer__hyjeVfHCYd:hover .roleHeader_actionsContainer__btiNUyAuRu{display:flex}.roleHeader_titleRoleConatiner__4Mxj1QMP2s{font-family:\"Proxima-Nova-Regular\";font-size:1.7rem}[dir=ltr] .roleHeader_titleRoleConatiner__4Mxj1QMP2s{padding-right:3rem}[dir=rtl] .roleHeader_titleRoleConatiner__4Mxj1QMP2s{padding-left:3rem}[dir=ltr] .roleHeader_addReporteeButton__kKrHFu7Zkk{margin-right:2rem}[dir=rtl] .roleHeader_addReporteeButton__kKrHFu7Zkk{margin-left:2rem}", ""]);

// Exports
exports.locals = {
	"roleHeaderContainer": "roleHeader_roleHeaderContainer__hyjeVfHCYd",
	"actionsContainer": "roleHeader_actionsContainer__btiNUyAuRu",
	"titleRoleConatiner": "roleHeader_titleRoleConatiner__4Mxj1QMP2s",
	"addReporteeButton": "roleHeader_addReporteeButton__kKrHFu7Zkk"
};