import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import COLUMN_IDS from '../constants/projectSettings.columnIds';

const COLUMN_CONFIG = [
  {
    id: COLUMN_IDS.PROJECT_NAME,
    Header: __('Project Name'),
    accessor: COLUMN_IDS.PROJECT_NAME,
    Cell: TextRenderer,
  },
  {
    id: COLUMN_IDS.PROJECT_DISPLAY_NAME,
    Header: __('Display Name'),
    accessor: COLUMN_IDS.PROJECT_DISPLAY_NAME,
    Cell: TextRenderer,
  },
];

export { COLUMN_CONFIG };
