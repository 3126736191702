const ACTION_TYPES = {
  LOAD_ENTITY_DEF: 'LOAD_ENTITY_DEF',
  LOAD_ENTITY_VIEW_CONFIG: 'LOAD_ENTITY_VIEW_CONFIG',
  ACTION_CLICK: 'ACTION_CLICK',
  FETCH_ACTIONS: 'FETCH_ACTIONS',
  TRIGGER_ACTION_BUILDER_ACTION: 'TRIGGER_ACTION_BUILDER_ACTION',
  REFETCH_ENTITY_RECORD: 'REFETCH_ENTITY_RECORD',
};

export { ACTION_TYPES };
