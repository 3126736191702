import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _size from 'lodash/size';
import _compact from 'lodash/compact';
import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import _without from 'lodash/without';

import ExpandableSearchField from '@tekion/tekion-components/organisms/ExpandableSearchField';
import Tag from '@tekion/tekion-components/atoms/Tag';
import Label from '@tekion/tekion-components/atoms/Label';
import CheckBoxTable from '@tekion/tekion-components/molecules/table/CheckBoxTable';
import Error from '@tekion/tekion-components/organisms/FormBuilder/components/error';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getSelectedApproversDetail } from './stageApproversSelectField.helpers';
import { pascalCase } from '../../../../../../../../../../helpers/general.helpers';

import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../../../../../../../constants/approvalCentre.constants';
import { STAGE_APPROVER_OPTIONS, STAGE_APPROVER_TYPES } from '../../constants/approvalStageFieldRenderer.constants';
import ACTION_TYPES from '../../constants/approvalStageFieldRenderer.actionTypes';
import { APPROVER_TYPES_FOR_TAGS, COLUMN_CONFIG } from './stageApproversSelectField.constants';

import styles from './stageApproversSelectField.module.scss';

const StageApproversSelectField = ({
  isFetchingApprovers,
  selectedApproverType,
  error,
  selectedApproverOptions,
  approverOptions,
  value,
  onAction,
}) => {
  const handleSearchFieldChange = useCallback(
    (searchField) => {
      onAction({ type: ACTION_TYPES.ON_CHANGE_APPROVER_TYPE, payload: { approverType: searchField } });
    },
    [onAction],
  );

  const handleSearchValueChange = useMemo(
    () =>
      _debounce((searchText) => {
        onAction({ type: ACTION_TYPES.ON_CHANGE_APPROVER_SEARCH_TEXT, payload: { searchText } });
      }, 1000),
    [onAction],
  );

  const handleOnChangeApprover = useCallback(
    (newApprovers) => {
      onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id: APPROVAL_CENTRE_FIELD_IDS.APPROVERS, value: _compact(newApprovers) } });
    },
    [onAction],
  );

  const handleScroll = useCallback(
    (event) => {
      if (event) {
        const element = event.target;
        const isAtBottom = element.scrollHeight - element.scrollTop - element.clientHeight < 10;
        if (isAtBottom) {
          onAction({ type: ACTION_TYPES.ON_SCROLL_END_DROPDOWN });
        }
      }
    },
    [onAction],
  );

  const handleRemoveTag = useCallback(
    (approverId) => () => {
      onAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: { id: APPROVAL_CENTRE_FIELD_IDS.APPROVERS, value: _without(value, approverId) },
      });
    },
    [value, onAction],
  );

  const renderTags = useCallback(
    (approverType, selectedApproversDetail) => (
      <div key={approverType} className={styles.tagsContainer}>
        <div className={styles.tagsTitle}>{pascalCase(approverType)} :-</div>
        <div>
          {_map(selectedApproversDetail, (approverDetail) => (
            <Tag deletable key={tget(approverDetail, 'id')} onDelete={handleRemoveTag(tget(approverDetail, 'id'))}>
              {__(tget(approverDetail, 'displayName'))}
            </Tag>
          ))}
        </div>
      </div>
    ),
    [handleRemoveTag],
  );

  const renderSelectedUserUserGroupsAndRolesAsTags = useCallback(() => {
    const selectedApproversDetailByApproverType = getSelectedApproversDetail(selectedApproverOptions);

    return _map(APPROVER_TYPES_FOR_TAGS, (approverType) => {
      const selectedApproversDetail = tget(selectedApproversDetailByApproverType, approverType, EMPTY_ARRAY);
      return _size(selectedApproversDetail) > 0 ? renderTags(approverType, selectedApproversDetail) : null;
    });
  }, [renderTags, selectedApproverOptions]);

  const renderApproverSearchResultDropdown = useCallback(
    () => (
      <CheckBoxTable
        selectAll={!isFetchingApprovers && _size(approverOptions) === _size(value) && _size(value) > 0}
        loading={isFetchingApprovers}
        pageSize={_size(approverOptions)}
        minRows={_size(approverOptions)}
        keyField="id"
        className={styles.checkBoxTable}
        columns={COLUMN_CONFIG}
        data={approverOptions}
        selection={value}
        onSelect={handleOnChangeApprover}
        onAction={onAction}
      />
    ),
    [isFetchingApprovers, approverOptions, value, handleOnChangeApprover, onAction],
  );

  return (
    <div className={styles.selectFieldContainer}>
      <div className={styles.selectedApproversContainer}>
        {_size(value) > 0 ? <Label className={styles.selectedApproversContainerLabel}>{__('Selected Approvers')}</Label> : null}
        {renderSelectedUserUserGroupsAndRolesAsTags()}
      </div>
      <Label required>{__('Add approver')}</Label>
      <ExpandableSearchField
        isActive
        className={styles.expandableSearchByField}
        inputFieldClassName={styles.expandableSearchInputField}
        placeholder={__('Search Users, User groups, Roles ')}
        searchField={selectedApproverType}
        searchableFieldsOptions={STAGE_APPROVER_OPTIONS}
        onChange={handleSearchValueChange}
        onChangeSearchField={handleSearchFieldChange}
      />
      <div className={styles.approverSearchResultDropdown} onScroll={handleScroll}>
        {renderApproverSearchResultDropdown()}
      </div>
      <Error key="error" className={styles.errorMessage} error={error} />
    </div>
  );
};

StageApproversSelectField.propTypes = {
  isFetchingApprovers: PropTypes.bool,
  selectedApproverType: PropTypes.string,
  error: PropTypes.string,
  selectedApproverOptions: PropTypes.array,
  approverOptions: PropTypes.array,
  value: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

StageApproversSelectField.defaultProps = {
  isFetchingApprovers: false,
  selectedApproverType: STAGE_APPROVER_TYPES.ALL,
  error: undefined,
  selectedApproverOptions: EMPTY_ARRAY,
  approverOptions: EMPTY_ARRAY,
  value: EMPTY_ARRAY,
};

export default StageApproversSelectField;
