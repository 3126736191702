// Tekion-components
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import COLUMN_IDS from '../constants/approvalGroupList.columnIds';

const COLUMN_CONFIG = [
  {
    Header: __('Group'),
    id: COLUMN_IDS.GROUP,
    accessor: COLUMN_IDS.GROUP,
    Cell: TextRenderer,
  },
];

export default COLUMN_CONFIG;
