const FIELD_IDS = {
  DISPLAY_NAME: 'displayName',
  NAME: 'name',
  DESCRIPTION: 'description',
  TEMPLATE_TYPE: 'templateType',
  SUBJECT: 'subject',
  BODY_TITLE: 'bodyTitle',
  BODY: 'body',
  JSON_BODY: 'jsonBody',
  ID: 'id',
};

const TEMPLATE_TYPES = {
  EMAIL: 'EMAIL',
  TEXT: 'TEXT',
  PUSHER: 'PUSHER',
};

export { FIELD_IDS, TEMPLATE_TYPES };
