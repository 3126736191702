exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".componentPanels_componentPanels__vp6EFf3Ztd .componentPanels_panelTitle__p5gPmyK94j{font-weight:normal;font-size:1.4rem}[dir=ltr] .componentPanels_componentPanels__vp6EFf3Ztd .componentPanels_panelTitle__p5gPmyK94j{margin:0 0 1rem .2rem}[dir=rtl] .componentPanels_componentPanels__vp6EFf3Ztd .componentPanels_panelTitle__p5gPmyK94j{margin:0 .2rem 1rem 0}[dir] .componentPanels_componentPanels__vp6EFf3Ztd .componentPanels_componentPanel__oUuq4fLZ5s{margin-bottom:2rem}.componentPanels_componentPanels__vp6EFf3Ztd .componentPanels_componentPanel__oUuq4fLZ5s .componentPanels_components__fCv8JsYSqC{display:flex;flex-wrap:wrap}.componentPanels_componentPanels__vp6EFf3Ztd .componentPanels_componentPanel__oUuq4fLZ5s .componentPanels_components__fCv8JsYSqC .componentPanels_component__nmFjAcH8ww{min-width:6rem;height:4.8rem;display:flex;flex-direction:column;font-size:1.4rem;-webkit-user-select:none;user-select:none;align-items:center;justify-content:center}[dir] .componentPanels_componentPanels__vp6EFf3Ztd .componentPanels_componentPanel__oUuq4fLZ5s .componentPanels_components__fCv8JsYSqC .componentPanels_component__nmFjAcH8ww{padding:.5rem;margin:.2rem;cursor:pointer;text-align:center;border:.1rem solid #acacac}.componentPanels_componentPanels__vp6EFf3Ztd .componentPanels_componentPanel__oUuq4fLZ5s .componentPanels_components__fCv8JsYSqC .componentPanels_component__nmFjAcH8ww>*{width:100%}[dir] .componentPanels_componentPanels__vp6EFf3Ztd .componentPanels_isDragging__cTQbsZdjRo{border:.1rem dashed #0a99ff}.componentPanels_componentPanels__vp6EFf3Ztd .componentPanels_isDisabled__phntJhPwpN{opacity:.5}[dir] .componentPanels_placeholder__jXkyELQM4M{border-style:dashed}.componentPanels_placeholder__jXkyELQM4M>div{display:none}", ""]);

// Exports
exports.locals = {
	"componentPanels": "componentPanels_componentPanels__vp6EFf3Ztd",
	"panelTitle": "componentPanels_panelTitle__p5gPmyK94j",
	"componentPanel": "componentPanels_componentPanel__oUuq4fLZ5s",
	"components": "componentPanels_components__fCv8JsYSqC",
	"component": "componentPanels_component__nmFjAcH8ww",
	"isDragging": "componentPanels_isDragging__cTQbsZdjRo",
	"isDisabled": "componentPanels_isDisabled__phntJhPwpN",
	"placeholder": "componentPanels_placeholder__jXkyELQM4M"
};