import React, { useCallback, useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import ViewComponent from '../viewComponent/ViewComponent';
import ComponentRendererContainer from '../componentRendererContainer';
import ViewBuilderContext from '../../ViewBuilder.context';

import { DEFAULT_WIDTH, VIEW_TYPES } from '../../constants/viewBuilder.constants';
import { ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './row.module.scss';

const Row = (props) => {
  const { isPreviewMode, isSelected = false, viewType, className, componentConfig, ...restProps } = props;

  const { selectedViewComponentId, onSelect } = useContext(ViewBuilderContext);
  const isDropEnabled = viewType !== VIEW_TYPES.LIST_VIEW;
  const sectionId = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
  const children = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.CHILDREN, EMPTY_ARRAY);

  const handleSelect = useCallback(
    (event) => {
      event.stopPropagation();

      onSelect(sectionId);
    },
    [onSelect, sectionId],
  );

  const renderSectionInPreviewMode = () => (
    <div key={sectionId} className={cx(styles.row, styles.previewMode)}>
      <div className={styles.sectionRow}>
        {_map(children, (childComponentConfig, index) => {
          const width = _get(childComponentConfig, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.WIDTH}`, DEFAULT_WIDTH);

          return (
            <ComponentRendererContainer
              key={childComponentConfig.sectionId}
              isPreviewMode={isPreviewMode}
              isDeleteEnabled={false}
              columnIndex={index}
              style={{ width: `${width}%` }}
              componentConfig={childComponentConfig}
              {...restProps}
            />
          );
        })}
      </div>
    </div>
  );

  const renderSectionInBuilderMode = () => (
    <div
      key={sectionId}
      className={cx(styles.row, styles.builderMode, className, {
        [styles.selected]: isSelected || selectedViewComponentId === componentConfig.sectionId,
      })}
      onClick={handleSelect}
    >
      <div className={styles.sectionRow}>
        {_map(children, (childComponentConfig, index) => {
          const childSectionId = tget(childComponentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
          const width = _get(childComponentConfig, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.WIDTH}`);

          return (
            <ViewComponent
              key={childSectionId}
              isDeleteEnabled={index !== 0}
              isDropEnabled={isDropEnabled}
              className={styles.sectionColumn}
              index={index}
              style={{ width: `${width}%` }}
              componentConfig={childComponentConfig}
            >
              <ComponentRendererContainer
                isPreviewMode={isPreviewMode}
                isDropEnabled={isDropEnabled}
                columnIndex={index}
                componentConfig={childComponentConfig}
                {...restProps}
              />
            </ViewComponent>
          );
        })}
      </div>
    </div>
  );

  return isPreviewMode ? renderSectionInPreviewMode() : renderSectionInBuilderMode();
};

Row.propTypes = {
  className: PropTypes.string,
  isPreviewMode: PropTypes.bool,
  isDeleteEnabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  componentConfig: PropTypes.object.isRequired,
};

Row.defaultProps = {
  className: '',
  isSelected: false,
  isDeleteEnabled: true,
  isPreviewMode: false,
};

export default React.memo(Row);
