import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select/Select';

import FIELD_IDS from './timeOfTheDay.fieldTypes';

const getConditionTemplate = ({ lhsValue = EMPTY_STRING, rhsValue = EMPTY_STRING, operator = EMPTY_STRING, ...rest }) => ({
  lhs: {
    type: 'K',
    value: lhsValue,
  },
  rhs: {
    type: 'C',
    value: rhsValue,
  },
  operator,
  ...rest,
});

const computeUserData = (values) => {
  const { startTime, endTime, daysToExecute } = values;
  const userData = {
    comparisons: [
      getConditionTemplate({
        lhsValue: FIELD_IDS.START_TIME,
        rhsValue: _isNil(startTime) ? EMPTY_STRING : startTime,
        operator: 'GTE',
      }),
      getConditionTemplate({
        lhsValue: FIELD_IDS.END_TIME,
        rhsValue: _isNil(endTime) ? EMPTY_STRING : endTime,
        operator: 'LTE',
      }),
      getConditionTemplate({
        lhsValue: FIELD_IDS.DAYS_OF_WEEK,
        rhsValue: daysToExecute,
        operator: 'IN_LIST',
      }),
    ],
    joinOperator: 'AND',
  };
  return userData;
};

const parseUserData = (userData) => {
  const comparisons = _get(userData, 'comparisons', []);
  const values = {};

  comparisons.forEach((field) => {
    const lhsValue = _get(field, 'lhs.value');
    const rhsValue = _get(field, 'rhs.value');
    values[lhsValue] = rhsValue;
  });

  return values;
};

const getTimeField = (fieldId, fieldOptions) =>
  _isNil(fieldId)
    ? null
    : {
        id: fieldId,
        renderer: Select,
        renderOptions: {
          label: __(tget(fieldOptions, `${fieldId}.displayName`, __('Select Time'))),
          size: 6,
          required: true,
          validators: [isRequiredRule],
        },
      };

export { getTimeField, parseUserData, computeUserData };
