import React from 'react';
import PropTypes from 'prop-types';

import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

const TabHeader = ({ className, iconClassName, iconToRender, title }) => (
  <div className={className}>
    <FontIcon size={FontIcon.SIZES.L} className={iconClassName}>
      {iconToRender}
    </FontIcon>
    {title}
  </div>
);

TabHeader.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  iconToRender: PropTypes.string,
  title: PropTypes.string.isRequired,
};

TabHeader.defaultProps = {
  className: EMPTY_STRING,
  iconClassName: EMPTY_STRING,
  iconToRender: EMPTY_STRING,
};

export default TabHeader;
