import React, { useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Tekion-components
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';

// Tekion-connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Connectors
import withSize from '../../../../../connectors/withSize';

// Helpers
import { COLUMN_CONFIG } from './helpers/validationRuleBuilderList.columns';
import { createTableProps, createFilterProps } from './helpers/validationRuleBuilderList.helpers';
import ACTION_HANDLERS from './helpers/validationRuleBuilderList.actionHandlers';

// Constants
import { SUB_HEADER_PROPS, TABLE_MANAGER_PROPS, DEFAULT_PAGE_SIZE } from './constants/validationRuleBuilderList.general';

const ValidationRuleBuilderList = ({
  isLoading,
  contentHeight,
  currentPage,
  pageSize,
  searchText,
  nextPageToken,
  data,
  selectedFilters,
  onAction,
}) => {
  const tableProps = useMemo(() => createTableProps(isLoading, currentPage, pageSize, onAction), [isLoading, currentPage, pageSize, onAction]);
  const filterProps = useMemo(() => createFilterProps(onAction), [onAction]);

  return (
    <Page style={{ height: contentHeight }}>
      <TableManager
        tokenPagination
        nextPageToken={nextPageToken}
        searchText={searchText}
        subHeaderProps={SUB_HEADER_PROPS}
        tableManagerProps={TABLE_MANAGER_PROPS}
        tableProps={tableProps}
        columns={COLUMN_CONFIG}
        data={data}
        filterProps={filterProps}
        selectedFilters={selectedFilters}
        onAction={onAction}
      />
    </Page>
  );
};

ValidationRuleBuilderList.propTypes = {
  isLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  searchText: PropTypes.string,
  nextPageToken: PropTypes.string,
  data: PropTypes.array,
  selectedFilters: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ValidationRuleBuilderList.defaultProps = {
  isLoading: false,
  currentPage: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  searchText: EMPTY_STRING,
  nextPageToken: EMPTY_STRING,
  data: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: true }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ValidationRuleBuilderList);
