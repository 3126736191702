import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { APPROVAL_CENTRE_FIELD_IDS, APPROVAL_VALIDITY_TYPES } from '../../../../../../../../../constants/approvalCentre.constants';

import styles from '../approvalStageFieldRenderer.module.scss';

const rowCheck = {
  [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_EPOCH]: ({ validityType }) => validityType === APPROVAL_VALIDITY_TYPES.EXACT,
  [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_DAYS]: ({ validityType }) => validityType === APPROVAL_VALIDITY_TYPES.RELATIVE,
  [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_HOURS]: ({ validityType }) => validityType === APPROVAL_VALIDITY_TYPES.RELATIVE,
  [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_MINUTES]: ({ validityType }) => validityType === APPROVAL_VALIDITY_TYPES.RELATIVE,
};

const getSections = (validityType) => [
  {
    className: styles.sectionFieldsContainer,
    rows: [
      {
        columns: [APPROVAL_CENTRE_FIELD_IDS.APPROVERS],
      },
      {
        columns: [APPROVAL_CENTRE_FIELD_IDS.MIN_APPROVALS],
      },
      {
        columns: [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_TYPE],
      },
      {
        columns: filterRows([APPROVAL_CENTRE_FIELD_IDS.VALIDITY_EPOCH], { validityType }, rowCheck),
      },
      {
        columns: filterRows(
          [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_DAYS, APPROVAL_CENTRE_FIELD_IDS.VALIDITY_HOURS, APPROVAL_CENTRE_FIELD_IDS.VALIDITY_MINUTES],
          { validityType },
          rowCheck,
        ),
      },
    ],
  },
];

export default getSections;
