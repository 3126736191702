import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _isNil from 'lodash/isNil';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import LeftPanel from '@tekion/tekion-components/molecules/leftPanelItem';
import Button from '@tekion/tekion-components/atoms/Button';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { getFields } from './customRowActionsForm.fields';
import { getSelectedCustomRowActionValueFromValues, getUpdatedValueFromOnChangeValue } from './customRowActionsForm.helpers';

import { FIELD_IDS, SECTIONS } from './customRowActionsForm.constants';
import ACTION_TYPES from '../../constants/customActionsConfigurator.actionTypes';
import { CUSTOM_ROW_ACTION_CONFIGURATOR_FORM_CONTEXT_ID } from '../../constants/customActionsConfigurator.constants';

import styles from './customRowActionsForm.module.scss';

const CustomRowActionsForm = ({ selectedLeftPanelIndex, entity, errors, values, onAction }) => {
  const fields = useMemo(() => getFields(entity), [entity]);

  const handleLeftPanelItemClick = useCallback((index) => {
    triggerSubmit(CUSTOM_ROW_ACTION_CONFIGURATOR_FORM_CONTEXT_ID, { actionType: ACTION_TYPES.ON_CUSTOM_ACTION_LEFT_PANEL_CLICK, value: index });
  }, []);

  const handleLeftPanelDeleteClick = useCallback(
    (index) => (event) => {
      event.stopPropagation();
      onAction({ type: ACTION_TYPES.ON_CUSTOM_ACTION_LEFT_PANEL_DELETE_CLICK, payload: { index } });
    },
    [onAction],
  );

  const renderLeftPanelTitle = useCallback(
    (index, displayName) => (
      <div className={styles.leftPanelTitleContainer}>
        <div>{__(displayName)}</div>
        <FontIcon onClick={handleLeftPanelDeleteClick(index)}>icon-trash</FontIcon>
      </div>
    ),
    [handleLeftPanelDeleteClick],
  );

  const renderLeftPanelItems = useCallback(
    () =>
      _map(values, (value, index) => {
        const displayName = tget(value, FIELD_IDS.DISPLAY_NAME, 'Untitled');

        return (
          <LeftPanel
            key={index}
            selected={index === selectedLeftPanelIndex}
            moduleId={index}
            title={renderLeftPanelTitle(index, displayName)}
            onModuleSelect={handleLeftPanelItemClick}
          />
        );
      }),
    [values, selectedLeftPanelIndex, handleLeftPanelItemClick, renderLeftPanelTitle],
  );

  const selectedCustomRowActionValues = useMemo(
    () => getSelectedCustomRowActionValueFromValues(values, selectedLeftPanelIndex),
    [values, selectedLeftPanelIndex],
  );

  const handleAddCustomActionClick = useCallback(() => {
    // Indicates that user haven't configured any custom row actions
    if (_isNil(selectedCustomRowActionValues)) {
      onAction({ type: FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT, payload: { additional: { actionType: ACTION_TYPES.ON_CUSTOM_ACTION_ADD_CLICK } } });
    } else {
      triggerSubmit(CUSTOM_ROW_ACTION_CONFIGURATOR_FORM_CONTEXT_ID, { actionType: ACTION_TYPES.ON_CUSTOM_ACTION_ADD_CLICK });
    }
  }, [selectedCustomRowActionValues, onAction]);

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const newValues = getUpdatedValueFromOnChangeValue(selectedCustomRowActionValues, payload);

          onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id: selectedLeftPanelIndex, value: newValues } });

          break;
        }

        default: {
          onAction(action);
          break;
        }
      }
    },
    [selectedCustomRowActionValues, onAction, selectedLeftPanelIndex],
  );

  return (
    <div className={styles.container}>
      <div className={styles.leftPanelContainer}>
        <Button className={styles.addButton} view={Button.VIEW.TERTIARY} onClick={handleAddCustomActionClick}>
          <FontIcon>icon-add-circle</FontIcon>
          {__('Add Custom Row Action')}
        </Button>
        {renderLeftPanelItems()}
      </div>
      <FormWithSubmission
        className={styles.formContainer}
        contextId={CUSTOM_ROW_ACTION_CONFIGURATOR_FORM_CONTEXT_ID}
        sections={SECTIONS}
        fields={fields}
        errors={errors}
        values={selectedCustomRowActionValues}
        onAction={handleAction}
      />
    </div>
  );
};

CustomRowActionsForm.propTypes = {
  selectedLeftPanelIndex: PropTypes.number,
  entity: PropTypes.object,
  errors: PropTypes.object,
  values: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

CustomRowActionsForm.defaultProps = {
  selectedLeftPanelIndex: 0,
  entity: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  values: EMPTY_ARRAY,
};

export default CustomRowActionsForm;
