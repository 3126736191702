import { produce } from 'immer';

import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _head from 'lodash/head';
import _last from 'lodash/last';
import _find from 'lodash/find';
import _split from 'lodash/split';
import _isObject from 'lodash/isObject';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _castArray from 'lodash/castArray';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { pascalCase } from '../../../helpers/general.helpers';
import { getSimpleFieldNameFromColumn } from '../../../utils';
import getUuid from '../../../utils/getUuid';

import { COMPONENT_TYPES } from '../constants/viewBuilder.constants';
import { componentTypeToPropertiesMap } from '../constants/componentType.constant';
import DATA_TYPES from '../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../constants/fieldDefinition.fieldTypes';
import { ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_FIELD_IDS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../constants/viewBuilder.constants';

import fieldDefinitionReader from '../../../readers/fieldDefinition.reader';
import entityReader from '../../../readers/entity.reader';

const getFieldDefinitionOfFieldFromEntity = (entity, fieldName) =>
  _find(_get(entity, 'fieldDefinitions'), ['name', _head(_split(getArraySafeValue(fieldName), '.'))]);

const getFirstFieldName = (fieldName) => _head(_split(fieldName, '.'));

// --------------------------------------------------------------------------------------------------//

// -------------- Find component by id function will return a section with given id --------------------- //

const _findComponentById = (component, targetComponentId) => {
  const sectionId = _get(component, 'sectionId');
  const children = _get(component, 'children', []);

  if (sectionId === targetComponentId) {
    return component;
  } else {
    let targetComponent = false;

    _forEach(children, (childComponent) => {
      const found = _findComponentById(childComponent, targetComponentId);

      if (found) {
        targetComponent = found;
        return false;
      }

      return true;
    });

    return targetComponent;
  }
};

const findComponentById = (viewConfiguration, targetComponentId) => {
  const rootComponent = _get(viewConfiguration, 'section', false);

  return rootComponent ? _findComponentById(rootComponent, targetComponentId) : false;
};

// --------------------------------------------------------------------------------------------------//

// -------------- Find parent component function will return parent section of given id --------------------- //

const _findParentComponentById = (component, targetComponentId) => {
  const children = _get(component, 'children', []);
  let hasChildFound = false;

  _forEach(children, (childComponent) => {
    if (childComponent.sectionId === targetComponentId) {
      hasChildFound = component;
      return false;
    } else {
      hasChildFound = _findParentComponentById(childComponent, targetComponentId);

      return !hasChildFound;
    }
  });

  return hasChildFound;
};

const findParentComponentById = (viewConfiguration, targetComponentId) => _findParentComponentById(viewConfiguration.section, targetComponentId);

// --------------------------------------------------------------------------------------------------//

// This function will return array of fields that are present in view config -------------------------//

const _getFieldNamesFromChildren = (section, fieldNames, entity = EMPTY_OBJECT) => {
  let propertiesFieldNames = _get(section, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.FIELD_NAMES}`, EMPTY_ARRAY);
  if (!_isEmpty(propertiesFieldNames)) {
    // Checking if the field is either relationship or complex, because only those fields will have "." between their fieldName.
    if (_includes(_head(propertiesFieldNames), '.')) {
      const fieldName = getFirstFieldName(_head(propertiesFieldNames));
      const fieldDefinition = _find(entityReader.fieldDefinitions(entity), (fieldDef) => fieldDefinitionReader.name(fieldDef) === fieldName);
      const dataType = fieldDefinitionReader.dataType(fieldDefinition);
      const fieldType = fieldDefinitionReader.fieldType(fieldDefinition);

      if (dataType === DATA_TYPES.COMPLEX) {
        propertiesFieldNames = _castArray(fieldName);
      } else if (fieldType === FIELD_TYPES.RELATIONSHIP) {
        const lookupField = fieldDefinitionReader.lookupFieldDisplayField(fieldDefinition);
        propertiesFieldNames = _castArray(`${fieldName}.${lookupField}`);
      }
    }

    fieldNames.push(...propertiesFieldNames);
  } else {
    _forEach(_get(section, 'children'), (child) => {
      _getFieldNamesFromChildren(child, fieldNames, entity);
    });
  }
};

const getFieldNamesFromChildren = (component, entity) => {
  const fieldNames = [];
  _getFieldNamesFromChildren(component, fieldNames, entity);
  return fieldNames;
};

// --------------------------------------------------------------------------------------------------//

// ------- This function traverse in parent component to check if given child is present or not -------------------//

const findParentChildRelationship = (parentComponent, childComponent) => {
  const children = _get(parentComponent, 'children');
  let hasChildFound = false;

  _forEach(children, (child) => {
    if (child.sectionId === childComponent.sectionId) {
      hasChildFound = true;
      return false;
    } else {
      hasChildFound = findParentChildRelationship(child, childComponent);

      return !hasChildFound;
    }
  });

  return hasChildFound;
};
// --------------------------------------------------------------------------------------------------//

// -----  Function to check if component is editable or not

const isComponentEditable = (componentConfig, entity) => {
  let isEditEnabled = false;
  const childComponentCellType = _get(componentConfig, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.CELL_TYPE}`);

  if (childComponentCellType === 'COMPOUND') isEditEnabled = true;
  else {
    const fieldNames = _get(componentConfig, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.FIELD_NAMES}.[0]`);
    const fieldDefinition = getFieldDefinitionOfFieldFromEntity(entity, fieldNames);
    isEditEnabled =
      fieldDefinitionReader.fieldType(fieldDefinition) === FIELD_TYPES.RELATIONSHIP ||
      fieldDefinitionReader.dataType(fieldDefinition) === DATA_TYPES.COMPLEX;
  }

  return isEditEnabled;
};

const isComponentDeletable = (type) => {
  let isDeleteEnabled = true;

  if (type === COMPONENT_TYPES.GRID_VIEW || type === COMPONENT_TYPES.LIST_VIEW_RENDERER) {
    isDeleteEnabled = false;
  }

  return isDeleteEnabled;
};

// ---------------------------------------------------------------------------------------------------//

// Commented this function as it is not being used anywhere and was giving error on commit due to "no-unused-modules" lint
// const getUrlParam = (paramName, location = window.location) => new URLSearchParams(location.search).get(paramName);

const getLastFieldName = (fieldName) => _last(_split(fieldName, '.'));

const getComponentProperties = ({ componentType, defaultPropertyValues = {}, defaultProperties = {} }) => {
  const properties = _cloneDeep(_get(componentTypeToPropertiesMap, componentType, defaultProperties));
  const propertiesWithDefaultValues = { ...properties, ...defaultPropertyValues };

  return propertiesWithDefaultValues;
};

const getComponentToAddInChildrenOfChildComponent = (childComponent) => {
  const childComponentType = _get(childComponent, 'type');
  if (childComponentType === COMPONENT_TYPES.SECTION) {
    return COMPONENT_TYPES.SECTION_COLUMN;
  } else if (childComponentType === COMPONENT_TYPES.ROW) {
    return COMPONENT_TYPES.COLUMN;
  } else {
    return '';
  }
};

const insertComponent = (parentViewComponent, childComponent, index, viewType) => {
  const properties = getComponentProperties({ componentType: childComponent.type });

  const viewComponentConfiguration = {
    ...childComponent,

    sectionId: getUuid(),
    index,
    properties: { ...properties, ...tget(childComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, {}) },
    children: [],
  };

  const componentToAddInChildrenOfChildComponent = getComponentToAddInChildrenOfChildComponent(childComponent);

  if (!_isEmpty(componentToAddInChildrenOfChildComponent)) {
    const componentToAddInChildrenOfChildComponentProperties = getComponentProperties({
      componentType: componentToAddInChildrenOfChildComponent,
      defaultPropertyValues: { viewType },
    });
    viewComponentConfiguration.children = [
      {
        type: componentToAddInChildrenOfChildComponent,
        sectionId: getUuid(),
        viewType,
        properties: componentToAddInChildrenOfChildComponentProperties,
        children: [],
      },
    ];
  }

  parentViewComponent.children.splice(index, 0, viewComponentConfiguration);
};

const insertViewComponent = (parentViewComponent, viewComponent, index) => {
  // eslint-disable-next-line no-param-reassign
  viewComponent.index = index;
  parentViewComponent.children.splice(viewComponent.index, 0, viewComponent);
};

const removeViewComponent = (parentComponent, childComponent) => parentComponent.children.splice(childComponent.index, 1)[0];

const reIndexChildComponents = (parentComponent) => {
  parentComponent.children.forEach((childComponent, index) => {
    // eslint-disable-next-line no-param-reassign
    childComponent.index = index;
  });
};

const hasErrorInConfiguratorForm = (errorObject) => {
  let hasError = false;
  _forEach(errorObject, (error) => {
    if (_isObject(error)) {
      hasError = hasError || hasErrorInConfiguratorForm(error);
    } else {
      hasError = hasError || !_isEmpty(error);
    }
  });
  return hasError;
};

const checkIsFieldMultiValued = (columnDef, fieldDefinitionByName) => {
  const fieldName = getSimpleFieldNameFromColumn(getArraySafeValue(_get(columnDef, 'fieldNames', [])));
  const fieldDef = _get(fieldDefinitionByName, fieldName, {});
  return _get(fieldDef, 'multiValued');
};

const checkForRichTextEditorField = (columnDef, fieldDefinitionByName) => {
  const fieldName = getSimpleFieldNameFromColumn(getArraySafeValue(_get(columnDef, 'fieldNames', [])));
  const fieldDef = _get(fieldDefinitionByName, fieldName, {});
  if (fieldDefinitionReader.dataType(fieldDef) === DATA_TYPES.COMPLEX || fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.RELATIONSHIP) {
    const newColumnDef = { ...columnDef, fieldNames: [getLastFieldName(fieldName)] };
    const complexEntityDefinitions = fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef);
    return checkForRichTextEditorField(newColumnDef, complexEntityDefinitions);
  }
  return fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.RICH_TEXT_EDITOR;
};

const checkForMediaTypeField = (columnDef, fieldDefinitionByName) => {
  const fieldName = getSimpleFieldNameFromColumn(getArraySafeValue(_get(columnDef, 'fieldNames', [])));
  const fieldDef = _get(fieldDefinitionByName, fieldName, {});
  if (fieldDefinitionReader.dataType(fieldDef) === DATA_TYPES.COMPLEX || fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.RELATIONSHIP) {
    const newColumnDef = { ...columnDef, fieldNames: [getLastFieldName(fieldName)] };
    const complexEntityDefinitions = fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef);
    return checkForMediaTypeField(newColumnDef, complexEntityDefinitions);
  }
  return { isMediaField: fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.MEDIA, fieldDef };
};

const getPlainTextFromHtml = (html) => {
  let plainText = '';
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const tags = doc.getElementsByTagName('p');
  _forEach(tags, (tag) => {
    plainText += `${tag.innerText} `;
  });
  return plainText;
};

const defaultTruthyFunction = () => true;

// -----  Function to find any property from viewConfig

const getPropertyFromViewConfig = (
  type,
  viewConfigSection,
  path = undefined,
  fallback = undefined,
  result = [],
  checkIfPropertyFoundIsValid = defaultTruthyFunction,
) => {
  if (_isEmpty(viewConfigSection)) {
    return;
  }

  const currentType = tget(viewConfigSection, 'type', EMPTY_STRING);
  const viewConfigSectionChildren = tget(viewConfigSection, 'children', EMPTY_ARRAY);

  if (type === currentType) {
    const propertyFound = _get(viewConfigSection, path) || fallback || viewConfigSection;
    if (checkIfPropertyFoundIsValid(propertyFound, currentType)) {
      result.push(propertyFound);
    }
  }

  _forEach(viewConfigSectionChildren, (childConfig) => {
    getPropertyFromViewConfig(type, childConfig, path, fallback, result, checkIfPropertyFoundIsValid);
  });
};

// -----  Function to update any property in viewConfig

const updatePropertyInViewConfig = (type, path, value, viewConfigSection, checkIfPropertyFoundIsValid = defaultTruthyFunction) => {
  if (_isEmpty(viewConfigSection)) {
    return;
  }
  const currentType = tget(viewConfigSection, 'type', EMPTY_STRING);
  const viewConfigSectionChildren = tget(viewConfigSection, 'children', EMPTY_ARRAY);

  if (type === currentType) {
    if (checkIfPropertyFoundIsValid(viewConfigSection, currentType)) {
      _set(viewConfigSection, path, value);
    }
  }

  _forEach(viewConfigSectionChildren, (childConfig) => {
    updatePropertyInViewConfig(type, path, value, childConfig, checkIfPropertyFoundIsValid);
  });
};

const getChildrenOfCurrentNode = (viewConfigSection) => {
  const viewConfigSectionChildren = tget(viewConfigSection, 'children', EMPTY_ARRAY);
  const children = _map(viewConfigSectionChildren, (childViewConfig) => getChildrenOfCurrentNode(childViewConfig));

  return {
    title:
      tget(viewConfigSection, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.TITLE}`) ||
      pascalCase(tget(viewConfigSection, 'type')),
    key: tget(viewConfigSection, 'sectionId'),
    children,
  };
};

const convertViewConfigIntoTreeStructure = (viewConfiguration) => ({
  title:
    tget(viewConfiguration, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.TITLE}`) ||
    pascalCase(tget(viewConfiguration, 'viewType')),
  key: tget(viewConfiguration, 'id'),
  children: getChildrenOfCurrentNode(tget(viewConfiguration, 'section')),
});

const getPathNameFromSectionToUpdate = (viewConfigRecursiveObject, recursivePath, result, updatedSelectedViewComponentId) => {
  const children = tget(viewConfigRecursiveObject, VIEW_CONFIGURATION_GENERAL_KEYS.CHILDREN, EMPTY_ARRAY);

  _forEach(children, (child, index) => {
    const id = tget(child, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);

    if (id === updatedSelectedViewComponentId) {
      _set(result, '0', `${recursivePath}.children[${index}]`);
      return;
    }

    getPathNameFromSectionToUpdate(child, `${recursivePath}.children[${index}]`, result, updatedSelectedViewComponentId);
  });
};

// Util for getting updated view config by passing an updated view component
const getUpdatedViewConfigForUpdatedSelectedViewComponent = (viewConfig, updatedSelectedViewComponent) => {
  const updatedSelectedViewComponentId = tget(updatedSelectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
  const pathFromSectionToUpdate = ['section'];
  const section = tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.SECTION);

  getPathNameFromSectionToUpdate(section, 'section', pathFromSectionToUpdate, updatedSelectedViewComponentId);

  const newViewConfig = produce(viewConfig, (draftState) => {
    const pathName = pathFromSectionToUpdate[0];

    _set(draftState, pathName, updatedSelectedViewComponent);
  });

  return newViewConfig;
};

export {
  getUuid,
  // getUrlParam,
  findComponentById,
  findParentComponentById,
  getComponentProperties,
  getFieldNamesFromChildren,
  insertComponent,
  insertViewComponent,
  removeViewComponent,
  reIndexChildComponents,
  isComponentEditable,
  getFieldDefinitionOfFieldFromEntity,
  getFirstFieldName,
  // getLastFieldName,
  findParentChildRelationship,
  isComponentDeletable,
  hasErrorInConfiguratorForm,
  checkIsFieldMultiValued,
  checkForRichTextEditorField,
  getPropertyFromViewConfig,
  updatePropertyInViewConfig,
  getPlainTextFromHtml,
  convertViewConfigIntoTreeStructure,
  getUpdatedViewConfigForUpdatedSelectedViewComponent,
  checkForMediaTypeField,
};
