import _isEmpty from 'lodash/isEmpty';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FIELD_IDS } from '../constants/dataImportForm.constants';

const rowCheck = {
  [FIELD_IDS.FIELD_MAPPING]: ({ templateId }) => !_isEmpty(templateId),
};

const getSections = (templateId) => [
  {
    rows: [
      {
        columns: [FIELD_IDS.ENTITY_NAME],
      },
      {
        columns: [FIELD_IDS.REPORTS_NAME],
      },
      {
        columns: [FIELD_IDS.OPERATION_TYPE],
      },
      {
        columns: [FIELD_IDS.FIELD_TO_MATCH],
      },
      {
        columns: [FIELD_IDS.MEDIA_ID],
      },
      {
        columns: [FIELD_IDS.BATCH_SIZE],
      },
      {
        columns: [FIELD_IDS.SEQUENTIAL, FIELD_IDS.UNIQUE_DATA],
      },
      {
        columns: filterRows([FIELD_IDS.FIELD_MAPPING], { templateId }, rowCheck),
      },
    ],
  },
];

export default getSections;
