import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';

import { DEFAULT_USER_LIST_PROPS, TABLE_CONSTANTS, SEARCHABLE_OPTIONS } from './constants/tenantUserList.general.constants';

// Table Helpers
import TENANT_USER_LIST_COLUMNS from './tableHelpers/tenantUserList.columns';
import { getTableProps } from './tableHelpers/tenantUserList.helpers';
import { HEADER_PROPS, TABLE_MANAGER_PROPS, SUB_HEADER_PROPS } from './tableHelpers/tenantUserList.tableHelpers';
import ACTION_HANDLERS from './tableHelpers/tenantUserList.actionHandlers';

const TenantUserList = ({ loading, pageSize, currentPage, totalNumberOfEntries, searchText, searchField, nextPageToken, users, onAction }) => {
  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({
        type: actionType,
        payload,
      });
    },
    [onAction],
  );

  return (
    <TableManager
      tokenPagination
      tableManagerProps={TABLE_MANAGER_PROPS}
      subHeaderProps={SUB_HEADER_PROPS}
      headerProps={HEADER_PROPS}
      columns={TENANT_USER_LIST_COLUMNS}
      data={users}
      pageSize={pageSize}
      searchText={searchText}
      searchField={searchField}
      searchableFieldsOptions={SEARCHABLE_OPTIONS}
      nextPageToken={nextPageToken}
      tableProps={getTableProps(handleRowAction, currentPage, loading, pageSize, totalNumberOfEntries)}
      onAction={onAction}
    />
  );
};
TenantUserList.propTypes = {
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalNumberOfEntries: PropTypes.number,
  searchText: PropTypes.string,
  searchField: PropTypes.string,
  nextPageToken: PropTypes.string,
  users: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

TenantUserList.defaultProps = {
  loading: true,
  pageSize: DEFAULT_PAGE_SIZE,
  currentPage: DEFAULT_PAGE_INFO.start,
  totalNumberOfEntries: TABLE_CONSTANTS.TOTAL_NUMBER_OF_ENTRIES,
  searchText: EMPTY_STRING,
  searchField: EMPTY_STRING,
  nextPageToken: EMPTY_STRING,
  users: EMPTY_ARRAY,
};

export default withActions(DEFAULT_USER_LIST_PROPS, ACTION_HANDLERS)(TenantUserList);
