exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".timeOfTheDay_modalHeader__mVQb4LZQMc{color:#161616;font-size:1.4rem;font-weight:600;letter-spacing:.03rem;line-height:1.7rem}[dir] .timeOfTheDay_modalHeader__mVQb4LZQMc{margin-bottom:2.4rem}[dir] .timeOfTheDay_section__2svZQMpBBk{padding:0}[dir] .timeOfTheDay_customDaysSelector__ijYurHgoxF{padding:0 !important}[dir] .timeOfTheDay_formRow__jNynS8gefB{margin-bottom:-1.5rem}[dir] .timeOfTheDay_formRow__jNynS8gefB .ant-radio-wrapper{padding-bottom:2rem}[dir=ltr] .timeOfTheDay_paddingFormRow__8BZrNATwR5{padding-left:2.2rem}[dir=rtl] .timeOfTheDay_paddingFormRow__8BZrNATwR5{padding-right:2.2rem}[dir] .timeOfTheDay_error__4dc7A85z6f{margin-top:-9rem}[dir=ltr] .timeOfTheDay_error__4dc7A85z6f{padding-left:5.4rem}[dir=rtl] .timeOfTheDay_error__4dc7A85z6f{padding-right:5.4rem}", ""]);

// Exports
exports.locals = {
	"modalHeader": "timeOfTheDay_modalHeader__mVQb4LZQMc",
	"section": "timeOfTheDay_section__2svZQMpBBk",
	"customDaysSelector": "timeOfTheDay_customDaysSelector__ijYurHgoxF",
	"formRow": "timeOfTheDay_formRow__jNynS8gefB",
	"paddingFormRow": "timeOfTheDay_paddingFormRow__8BZrNATwR5",
	"error": "timeOfTheDay_error__4dc7A85z6f"
};