import React from 'react';
import PropTypes from 'prop-types';
import _castArray from 'lodash/castArray';
import _set from 'lodash/set';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import Button from '@tekion/tekion-components/atoms/Button';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { filterFieldOptions } from './sortCondition.helpers';
import SORT_CONDITION_FIELD_IDS from './sortCondition.fieldIds';
import { SORT_ORDER_OPTIONS } from './sortCondition.constants';
import SORT_CONDITION_LIST_ACTION_TYPES from '../sortConditionList.actionTypes';

// Styles
import styles from './sortCondition.module.scss';

const SortCondition = ({ index, condition, errors, fieldOptions, value, onAction }) => {
  const fieldName = condition?.[SORT_CONDITION_FIELD_IDS.FIELD];
  const orderValue = condition?.[SORT_CONDITION_FIELD_IDS.SORT_ORDER];
  const filteredOptions = filterFieldOptions(fieldOptions, value, index);

  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload = EMPTY_OBJECT } = action;
    switch (type) {
      case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
        const { id: fieldId, value: fieldValue } = payload;
        const updatedCondition = {
          ...condition,
          [fieldId]: getArraySafeValue(fieldValue),
        };
        if (fieldId === SORT_CONDITION_FIELD_IDS.FIELD) {
          _set(updatedCondition, SORT_CONDITION_FIELD_IDS.SORT_ORDER, undefined);
          _set(updatedCondition, SORT_CONDITION_FIELD_IDS.KEY, getArraySafeValue(fieldValue));
        }
        onAction({
          type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
          payload: {
            index,
            condition: updatedCondition,
          },
        });
        break;
      }
      default:
        onAction(action);
    }
  };

  const onDeleteClick = () => {
    onAction({
      type: SORT_CONDITION_LIST_ACTION_TYPES.DELETE_ROW,
      payload: {
        index,
      },
    });
  };

  return (
    <div className={styles.container}>
      <Select
        id={SORT_CONDITION_FIELD_IDS.FIELD}
        fieldClassName={fieldLayoutStyles.fieldC}
        label={__('Field')}
        value={_castArray(fieldName)}
        error={errors?.[SORT_CONDITION_FIELD_IDS.FIELD]}
        options={filteredOptions}
        onAction={handleAction}
      />
      <Select
        id={SORT_CONDITION_FIELD_IDS.SORT_ORDER}
        label={__('Order')}
        fieldClassName={fieldLayoutStyles.fieldC}
        value={_castArray(orderValue)}
        error={errors?.[SORT_CONDITION_FIELD_IDS.SORT_ORDER]}
        options={SORT_ORDER_OPTIONS}
        onAction={handleAction}
      />
      <Button view={Button.VIEW.ICON} icon="icon-trash" iconClassName={styles.icon} onClick={onDeleteClick} />
    </div>
  );
};

SortCondition.propTypes = {
  index: PropTypes.number.isRequired,
  condition: PropTypes.object,
  errors: PropTypes.object,
  fieldOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
  value: PropTypes.array,
};

SortCondition.defaultProps = {
  condition: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  fieldOptions: EMPTY_ARRAY,
  value: EMPTY_ARRAY,
};

export default SortCondition;
