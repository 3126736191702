import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _map from 'lodash/map';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Button from '@tekion/tekion-components/atoms/Button';
import Module from '@tekion/tekion-components/molecules/leftPanelItem';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

import { getFields } from './eventHandlerModal.fields';
import { EVENT_NAMES_MAP_BASED_ON_VIEW_TYPE_AND_COMPONENT_TYPE } from '../../helpers/eventHandlerConfigurator.eventMapper';
import { pascalCase } from '../../../../../../helpers/general.helpers';
import { getEventTypeOptionsFromValues } from './eventHandlerModal.helpers';

import { SECTIONS } from './eventHandlerModal.sections';
import ACTION_TYPES from '../../constants/eventHandlerConfigurator.actionTypes';
import { EVENT_CONFIGURATOR_FORM_CONTEXT_ID } from '../../constants/eventHandlerConfigurator.constants';
import { FIELD_IDS } from './eventHandlerModal.constants';

import eventConfiguratorReader from '../../../../../../readers/eventConfigurator.reader';

import styles from '../../eventHandler.module.scss';

const EventHandlerModal = ({ selectedModuleIndex, viewType, componentType, entity, allFormValues, errors, onAction }) => {
  const handleTabChange = useCallback((moduleIndex) => {
    triggerSubmit(EVENT_CONFIGURATOR_FORM_CONTEXT_ID, { actionType: ACTION_TYPES.ON_CHANGE_MODULE, payload: { moduleIndex } });
  }, []);

  const handleAddEventClick = useCallback(() => {
    if (!_isEmpty(allFormValues)) {
      triggerSubmit(EVENT_CONFIGURATOR_FORM_CONTEXT_ID, { actionType: ACTION_TYPES.ON_CLICK_ADD_EVENT });
    } else {
      onAction({ type: ACTION_TYPES.ON_CLICK_ADD_EVENT });
      onAction({ type: ACTION_TYPES.ON_CHANGE_MODULE, payload: { moduleIndex: 0 } });
    }
  }, [allFormValues, onAction]);

  const handleDeleteClick = useCallback(
    (moduleIndex) => (event) => {
      onAction({
        type: ACTION_TYPES.DELETE_ROW,
        payload: { moduleIndex },
      });
      event.stopPropagation();
    },
    [onAction],
  );

  const values = useMemo(() => allFormValues[selectedModuleIndex] || {}, [allFormValues, selectedModuleIndex]);

  const eventTypeOptions = useMemo(
    () => getEventTypeOptionsFromValues(values, allFormValues, viewType, componentType),
    [allFormValues, componentType, values, viewType],
  );

  const renderModuleItem = useCallback(
    (eventType, moduleIndex) => (
      <div
        className={cx(styles.moduleItem, {
          [styles.selectedBackgroundColor]: selectedModuleIndex === moduleIndex,
        })}
      >
        <div className={styles.moduleSection}>
          <div className={styles.moduleItemTitle}>{__(!_isEmpty(eventType) ? pascalCase(eventType) : 'Untitled')}</div>
          <div>
            <FontIcon className={styles.moduleItemDeleteIcon} onClick={handleDeleteClick(moduleIndex)}>
              icon-trashcan
            </FontIcon>
          </div>
        </div>
      </div>
    ),
    [handleDeleteClick, selectedModuleIndex],
  );

  const renderActionModule = useCallback(
    () =>
      _map(allFormValues, (formValue, moduleIndex) => {
        const eventValue = tget(formValue, FIELD_IDS.EVENT_HANDLER, EMPTY_OBJECT);
        const eventType = eventConfiguratorReader.eventType(eventValue);
        return (
          <Module
            key={moduleIndex}
            moduleId={moduleIndex}
            title={renderModuleItem(eventType, moduleIndex)}
            selected={selectedModuleIndex === moduleIndex}
            onModuleSelect={handleTabChange}
          />
        );
      }),
    [allFormValues, handleTabChange, renderModuleItem, selectedModuleIndex],
  );

  const fields = useMemo(() => getFields(entity, eventTypeOptions), [entity, eventTypeOptions]);

  return (
    <div className={styles.eventHandlerModal}>
      <div className={styles.eventSideBar}>
        <Button
          disabled={_size(allFormValues) === _size(tget(EVENT_NAMES_MAP_BASED_ON_VIEW_TYPE_AND_COMPONENT_TYPE, [viewType, componentType], []))}
          view={Button.VIEW.TERTIARY}
          className={styles.addEventButton}
          onClick={handleAddEventClick}
        >
          <FontIcon className={styles.addIcon}>icon-add-circle</FontIcon>
          {__('Add Event')}
        </Button>
        {renderActionModule()}
      </div>
      <div className={styles.rightPanel}>
        <FormWithSubmission
          contextId={EVENT_CONFIGURATOR_FORM_CONTEXT_ID}
          sections={SECTIONS}
          fields={fields}
          values={values}
          errors={errors}
          onAction={onAction}
        />
      </div>
    </div>
  );
};

EventHandlerModal.propTypes = {
  selectedModuleIndex: PropTypes.number,
  viewType: PropTypes.string,
  componentType: PropTypes.string,
  entity: PropTypes.object,
  allFormValues: PropTypes.array,
  errors: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

EventHandlerModal.defaultProps = {
  selectedModuleIndex: undefined,
  viewType: undefined,
  componentType: undefined,
  entity: EMPTY_OBJECT,
  errors: EMPTY_ARRAY,
  allFormValues: EMPTY_ARRAY,
};

export default EventHandlerModal;
