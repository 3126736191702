import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { TENANT_UNIVERSE_BASE_ROUTE } from '../../../../../../constants/routes';

import { getTenantUserList } from '../../../../../../actions/tenantUserManagement.actions';
import { getPayload } from './tenantUserList.helpers';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/tenantUserList.actionTypes';
import { TABLE_CONSTANTS } from '../constants/tenantUserList.general.constants';

const handleInit = async ({ setState, params, getState }) => {
  setState({ loading: true });

  const { pageSize, searchField, searchText } = getState();
  const currentPageToken = _get(params, 'nextPageToken');
  const payload = getPayload({ searchText, searchField, pageSize, nextPageToken: currentPageToken });
  const userDataResponse = await getTenantUserList(payload);
  const userList = tget(userDataResponse, 'hits', EMPTY_ARRAY);

  setState({
    loading: false,
    totalNumberOfEntries: _get(userDataResponse, 'count'),
    users: userList,
    nextPageToken: _get(userDataResponse, 'nextPageToken'),
  });
};

const handleSearchApply = async ({ setState, params, getState }) => {
  const searchText = _get(params, 'value');
  const currentPage = TABLE_CONSTANTS.CURRENT_PAGE;
  setState(
    {
      searchText,
      currentPage,
    },
    () => {
      handleInit({ getState, setState });
    },
  );
};

const handlePageUpdate = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  let pageNo = _get(params, 'value.page');
  const resultsPerPage = _get(params, 'value.resultsPerPage');
  let prevPageTokens = [...(previousPageTokens || [])];
  let currentPageToken = null;

  if (pageNo > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (pageNo === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }

  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleInit({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const handleSearchFieldChange = ({ params, setState }) => {
  const searchField = _get(params, 'value');
  setState({ searchField });
};

const handleCreateNewUser = ({ getState }) => {
  const { history } = getState();
  const path = `${TENANT_UNIVERSE_BASE_ROUTE}/${PAGE_IDS.USER_CREATE}`;
  history.push(path);
};

const handleEditUserRequest = ({ params, getState }) => {
  const { history } = getState();
  const path = `${TENANT_UNIVERSE_BASE_ROUTE}/${PAGE_IDS.USER_EDIT}/${params.id}`;
  history.push({ pathname: path, state: { userInfo: params } });
};

const handleTableItemClick = ({ params, getState }) => {
  const userData = _get(params, 'value.original', EMPTY_OBJECT);
  handleEditUserRequest({ getState, params: userData });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchApply,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_SEARCH_FIELD]: handleSearchFieldChange,
  [ACTION_TYPES.CREATE_USER]: handleCreateNewUser,
  [ACTION_TYPES.EDIT_USER]: handleEditUserRequest,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleTableItemClick,
};

export default ACTION_HANDLERS;
