// Renderers
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
// Constants

const COLUMN_IDS = {
  DISPLAY_NAME: 'displayName',
  DESCRIPTION: 'description',
  NAME: 'name',
};

const COLUMN_CONFIG = [
  {
    Header: __('Display name'),
    accessor: COLUMN_IDS.DISPLAY_NAME,
    id: COLUMN_IDS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Name'),
    accessor: 'name',
    id: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Description'),
    accessor: COLUMN_IDS.DESCRIPTION,
    id: COLUMN_IDS.DESCRIPTION,
    Cell: TextRenderer,
  },
];

export { COLUMN_IDS, COLUMN_CONFIG };
