import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import _size from 'lodash/size';
import _get from 'lodash/get';
import _min from 'lodash/min';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _last from 'lodash/last';
import _slice from 'lodash/slice';
import _noop from 'lodash/noop';
import cx from 'classnames';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import TableTokenPagination from '@tekion/tekion-components/organisms/TableTokenPagination';
import TablePagination from '@tekion/tekion-components/molecules/table/TablePagination';
import Loader from '@tekion/tekion-components/molecules/loader';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import ViewRendererContext from '../../../../../context/viewRenderer.context';
import variableApiFactory from '../../../../../factories/variableApi.Factory';
import Card from './Card';

import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../../utils/customStyles';

import { COMPONENT_TYPES, COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP, VIEW_CONTEXT_KEYS } from '../../../constants/viewBuilder.constants';
import { CUSTOM_STYLE_IDS } from '../../../../../constants/customStyles.constants';
import { RENDERER_PROP_KEYS, ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../../constants/viewBuilder.constants';

import styles from './gridViewRenderer.module.scss';

const SUPPORTED_CUSTOM_STYLE_SECTION = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[COMPONENT_TYPES.GRID_VIEW];

const GridViewRenderer = ({
  style,
  columns,
  data,
  cellViewConfigurationsByName,
  componentConfig,
  cardViewConfiguration,
  entity,
  onAction,
  loading,
  ...restProps
}) => {
  const gridViewProperties = useMemo(() => _get(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT), [componentConfig]);

  const viewContextValue = useContext(ViewRendererContext);

  const viewVariables = useMemo(() => tget(viewContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.viewVariables`, EMPTY_STRING), [viewContextValue]);
  const handleSetViewVariables = useMemo(
    () => tget(viewContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.handleSetViewVariables`, _noop),
    [viewContextValue],
  );
  const variablesApi = useMemo(() => variableApiFactory(viewVariables, handleSetViewVariables), [viewVariables, handleSetViewVariables]);

  const defaultCardViewConfigFromGridView = _get(componentConfig, 'children', EMPTY_ARRAY);
  const columnCount = _get(gridViewProperties, ALL_VIEW_PROPERTY_KEYS.GRID_TABLE_COLUMN_COUNT, 3);
  const rowCount = _get(gridViewProperties, ALL_VIEW_PROPERTY_KEYS.GRID_TABLE_ROW_COUNT, 3);
  const maxCardsPerPage = columnCount * rowCount;
  const showPagination = _get(gridViewProperties, 'showPagination', false);

  const customStyles = useMemo(() => _get(gridViewProperties, RENDERER_PROP_KEYS.CUSTOM_STYLES, EMPTY_OBJECT), [gridViewProperties]);
  const resolvedCustomStyles = useMemo(
    () => getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_SECTION, customStyles, {}, variablesApi),
    [customStyles, variablesApi],
  );

  const handleTableChange = (actionType) => (value) => {
    onAction({ type: actionType, payload: { value } });
  };

  const changePage = handleTableChange(TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE);
  const getPaginationComponent = () => {
    const {
      totalNumberOfEntries,
      currentPage,
      pagerSize,
      pageSizeOptions,
      paginationClass,
      totalNumberOfPages,
      displayFirstLast,
      tokenPagination,
      nextPageToken,
    } = restProps;

    if (tokenPagination) {
      return (
        <TableTokenPagination
          displayFirstLast={displayFirstLast}
          isLoading={loading}
          currentPage={currentPage}
          changePage={changePage}
          resultsPerPage={maxCardsPerPage}
          resultsPerPageOptions={pageSizeOptions}
          className={paginationClass}
          nextPageToken={nextPageToken}
          canSetResultsPerPage={false}
        />
      );
    }

    return (
      <TablePagination
        displayFirstLast={displayFirstLast}
        isLoading={loading}
        pagerSize={pagerSize}
        totalNumberOfEntries={totalNumberOfEntries}
        currentPage={currentPage}
        changePage={changePage}
        resultsPerPage={maxCardsPerPage}
        resultsPerPageOptions={pageSizeOptions}
        className={paginationClass}
        totalNumberOfPages={totalNumberOfPages}
        canSetResultsPerPage={false}
      />
    );
  };

  const { dataColumns, actionColumn } = useMemo(() => {
    const showActions = _get(componentConfig, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.SHOW_ACTIONS}`, false);
    const result = { dataColumns: columns, actionColumn: {} };
    if (showActions) {
      result.actionColumn = _last(columns);
      result.dataColumns = _slice(columns, 0, _size(columns) - 1);
    }
    return result;
  }, [columns, componentConfig]);

  const renderCards = () =>
    _compact(
      _map(data, (record, index) => {
        if (index < _min([maxCardsPerPage, _size(data)])) {
          return (
            <Card
              dataIndex={index}
              cardFields={dataColumns}
              columnCount={columnCount}
              cellViewConfigurationsByName={cellViewConfigurationsByName}
              defaultCardViewConfigFromGridView={defaultCardViewConfigFromGridView}
              cardViewConfiguration={cardViewConfiguration}
              entity={entity}
              record={record}
              cardCustomStyles={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CARD_STYLE, EMPTY_OBJECT)}
              actionColumn={actionColumn}
            />
          );
        }
        return null;
      }),
    );

  const renderNoData = () => <span className={styles.noData}>{__('No data')}</span>;

  return (
    <>
      {loading ? (
        <div className={styles.loaderStyle}>
          <Loader />
        </div>
      ) : (
        <div style={style} className={cx(styles.gridViewRenderer, styles.previewMode)}>
          {_size(data) > 0 ? renderCards() : renderNoData()}
        </div>
      )}
      {showPagination && getPaginationComponent()}
    </>
  );
};

GridViewRenderer.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  cellViewConfigurationsByName: PropTypes.object,
  componentConfig: PropTypes.object,
  cardViewConfiguration: PropTypes.object,
  entity: PropTypes.object.isRequired,
  style: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

GridViewRenderer.defaultProps = {
  loading: false,
  columns: EMPTY_ARRAY,
  cellViewConfigurationsByName: EMPTY_OBJECT,
  componentConfig: EMPTY_OBJECT,
  cardViewConfiguration: EMPTY_OBJECT,
  data: EMPTY_ARRAY,
  style: EMPTY_OBJECT,
};

export default GridViewRenderer;
