import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import Modal from '@tekion/tekion-components/molecules/Modal';
import { FormWithSubmission } from '@tekion/tekion-components/pages/formPage';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import getAuthConfigFormFields, { FIELD_IDS } from './helpers/authConfigModal.fields';
import getAuthConfigFormSections from './helpers/authConfigModal.sections';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import { AUTH_CONFIG_FORM_CONTEXT_ID } from './constants/authConfigModal.general.constants';
import ACTION_TYPES from '../../constants/loginConfigurationsDashboard.actionTypes';
import { PROVIDERS_DISPLAY_NAME } from '../../constants/loginConfigurationsDashboard.general.constants';

const AuthConfigModal = ({
  isAuthConfigModalVisible,
  isAuthConfigModalSubmitting,
  selectedProviderConfig,
  authConfigFormValues,
  providerTypeOptions,
  errors,
  onAction,
}) => {
  const handleClose = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CLOSE_AUTH_CONFIG_MODAL });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_SUBMIT_AUTH_CONFIG_MODAL, payload: { selectedProviderConfig } });
  }, [onAction, selectedProviderConfig]);

  const formMode = _isEmpty(selectedProviderConfig) ? FORM_MODES.CREATE : FORM_MODES.EDIT;

  const selectedProviderType = tget(authConfigFormValues, FIELD_IDS.PROVIDER_TYPE);

  const sections = useMemo(() => getAuthConfigFormSections(formMode, authConfigFormValues), [formMode, authConfigFormValues]);
  const fields = useMemo(() => getAuthConfigFormFields(providerTypeOptions), [providerTypeOptions]);

  const modalTitle =
    formMode === FORM_MODES.CREATE
      ? __('Add New Configuration')
      : __('{{providerDisplayName}} Configuration', { providerDisplayName: PROVIDERS_DISPLAY_NAME[selectedProviderType] });

  return (
    <Modal
      destroyOnClose
      visible={isAuthConfigModalVisible}
      loading={isAuthConfigModalSubmitting}
      title={modalTitle}
      onCancel={handleClose}
      onSubmit={handleSubmit}
    >
      <FormWithSubmission
        contextId={AUTH_CONFIG_FORM_CONTEXT_ID}
        fields={fields}
        sections={sections}
        values={authConfigFormValues}
        errors={errors}
        onAction={onAction}
      />
    </Modal>
  );
};

AuthConfigModal.propTypes = {
  isAuthConfigModalVisible: PropTypes.bool,
  isAuthConfigModalSubmitting: PropTypes.bool,
  authConfigFormValues: PropTypes.object,
  selectedProviderConfig: PropTypes.object,
  errors: PropTypes.object,
  providerTypeOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

AuthConfigModal.defaultProps = {
  isAuthConfigModalVisible: false,
  isAuthConfigModalSubmitting: false,
  authConfigFormValues: EMPTY_OBJECT,
  selectedProviderConfig: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  providerTypeOptions: EMPTY_ARRAY,
};

export default React.memo(AuthConfigModal);
