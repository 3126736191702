import _isEmpty from 'lodash/isEmpty';
import _split from 'lodash/split';
import _head from 'lodash/head';
import _get from 'lodash/get';
import _slice from 'lodash/slice';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { getSelectedFieldDef } from '../../helpers/conditionBuilder.general.helpers';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

// Constants
import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';
import CONDITION_FIELD_IDS from '../../constants/condition.fieldIds';
import { OPERATORS, RECORD_CHANGE_OPERATORS } from './operatorField.constants';

const getOperatorOptions = ({
  values,
  mapOfVariableToEntityName,
  conditionBuilderFieldDefinitionObject,
  isVariablePrefixNeeded,
  variableNameForPrefixNotNeeded,
  isCustomOperatorOptions,
  customOperatorOptions,
  enableRecordChangeComparatorOperators,
}) => {
  if (isCustomOperatorOptions) {
    return customOperatorOptions;
  }

  const resourceValue = values?.[CONDITION_FIELD_IDS.FIELD];
  const splitValue = _split(resourceValue, '.');

  const splitResourceValue = isVariablePrefixNeeded ? _slice(splitValue, 1) : splitValue;
  const variableName = isVariablePrefixNeeded ? _head(splitValue) : variableNameForPrefixNotNeeded;
  const entityName = mapOfVariableToEntityName[variableName];
  const fieldDefs = _get(conditionBuilderFieldDefinitionObject, entityName, EMPTY_OBJECT);

  const selectedFieldDef = getSelectedFieldDef(splitResourceValue, 0, fieldDefs, conditionBuilderFieldDefinitionObject);

  if (_isEmpty(selectedFieldDef)) {
    return EMPTY_ARRAY;
  }

  const defaultOperators = enableRecordChangeComparatorOperators ? [OPERATORS.IS_NULL, OPERATORS.CHANGED] : [OPERATORS.IS_NULL];
  const recordChangeOperators = enableRecordChangeComparatorOperators ? RECORD_CHANGE_OPERATORS : EMPTY_ARRAY;

  const dataType = fieldDefinitionReader.dataType(selectedFieldDef);
  switch (dataType) {
    case DATA_TYPES.TEXT:
      return [OPERATORS.IN, OPERATORS.NOT_IN, OPERATORS.STARTS_WITH, OPERATORS.ENDS_WITH, OPERATORS.IS_NULL, ...recordChangeOperators];
    case DATA_TYPES.BOOLEAN:
      return [OPERATORS.EQUALS, OPERATORS.DOES_NOT_EQUAL, OPERATORS.IS_NULL, ...recordChangeOperators];
    case DATA_TYPES.NUMBER:
    case DATA_TYPES.DATE:
    case DATA_TYPES.DATE_TIME:
      return [
        OPERATORS.EQUALS,
        OPERATORS.DOES_NOT_EQUAL,
        OPERATORS.LESS_THAN,
        OPERATORS.GREATER_THAN,
        OPERATORS.LESS_THAN_EQUAL_TO,
        OPERATORS.GREATER_THAN_EQUAL_TO,
        OPERATORS.IS_NULL,
        ...recordChangeOperators,
      ];
    default:
      return defaultOperators;
  }
};

export { getOperatorOptions };
