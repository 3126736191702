const ACTION_TYPES = {
  INIT_DATA: 'INIT_DATA',
  ADD_APPLICATION: 'ADD_APPLICATION',
  ON_SAVE_MODAL: 'ON_SAVE_MODAL',
  ON_CANCEL_MODAL: 'ON_CANCEL_MODAL',
  ON_TRANSFER_ITEM: 'ON_TRANSFER_ITEM',
  ON_CLICK_EDIT: 'ON_CLICK_EDIT',
  REMOVE_APPLICATION: 'REMOVE_APPLICATION',
  ON_SCROLL: 'ON_SCROLL',
  ON_SEARCH: 'ON_SEARCH',
};
export default ACTION_TYPES;
