import ACTION_TYPES from './exportList.actionTypes';

const DELETE_ACTION = {
  id: ACTION_TYPES.OPEN_CANCELATION_MODAL,
  name: __('Delete'),
};

const PUBLISH_ACTION = {
  id: ACTION_TYPES.PUBLISH_BUNDLE,
  name: __('Publish'),
};

export { DELETE_ACTION, PUBLISH_ACTION };
