import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import jobScheduleServices from '../services/jobSchedule.services';

const createJob = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await jobScheduleServices.createJob(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create job, please try again later')));
    return EMPTY_OBJECT;
  }
};

const searchJobs = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await jobScheduleServices.searchJob(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get jobs, please try again later')));
    return EMPTY_OBJECT;
  }
};

const getJob = async (id) => {
  try {
    const response = await jobScheduleServices.getJob(id);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get job, please try again later')));
    return EMPTY_OBJECT;
  }
};

const updateJob = async (id, payload = EMPTY_OBJECT) => {
  try {
    const response = await jobScheduleServices.updateJob(id, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update job, please try again later')));
    return EMPTY_OBJECT;
  }
};

const deleteJob = async (id) => {
  try {
    const response = await jobScheduleServices.deleteJob(id);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to delete job, please try again later')));
    return EMPTY_OBJECT;
  }
};

const getTimeZones = async () => {
  try {
    const response = await jobScheduleServices.getTimeZones();
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch time zones, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const getExecutionLogOfJob = async (id) => {
  try {
    const response = await jobScheduleServices.executionLogOfJob(id);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error), __('Failed to fetch execution log,please try again later!'));
    return EMPTY_OBJECT;
  }
};

export { createJob, searchJobs, getJob, updateJob, deleteJob, getTimeZones, getExecutionLogOfJob };
