import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import styles from './draggableWidget.module.scss';

const DraggableWidget = ({ index, type, children, widgetComponentConfig }) => {
  const [{ isDragging }, dragRef] = useDrag({
    type,
    item: {
      widgetComponentConfig,
      index,
      type,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleDrag = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div
      className={styles.draggable}
      ref={dragRef}
      style={{
        opacity: isDragging ? '0.5' : '1',
      }}
      onMouseDown={handleDrag}
    >
      {children}
    </div>
  );
};

DraggableWidget.propTypes = {
  type: PropTypes.string,
  index: PropTypes.number.isRequired,
  children: PropTypes.any,
  widgetComponentConfig: PropTypes.object,
};

DraggableWidget.defaultProps = {
  type: '',
  children: undefined,
  widgetComponentConfig: EMPTY_OBJECT,
};

export default DraggableWidget;
