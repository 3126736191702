const FIELD_IDS = {
  SUBSCRIPTION_CONFIG_DISPLAY_NAME: 'displayName',
  SUBSCRIPTION_CONFIG_NAME: 'name',
  SUBSCRIPTIONS: 'subscriptions',
  SERVER_CREDENTIAL: 'serverCredentialName',
  STATUS: 'inactive',
  PATH: 'path',
  VERIFIED: 'verified',
};

export default FIELD_IDS;
