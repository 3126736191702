import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import Heading from '@tekion/tekion-components/atoms/Heading';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Button from '@tekion/tekion-components/atoms/Button';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';

import TagsRenderer from '../../atoms/tagsRenderer/TagsRenderer';
import CustomStylesModal from './components/CustomStylesModal';

import ACTION_HANDLERS from './helpers/customStylesConfigurator.actionHandlers';
import { getSelectedCustomStyles } from './helpers/customStylesConfigurator.helper';

import ACTION_TYPES from './constants/customStylesConfigurator.actionTypes';
import { CUSTOM_STYLES_CONFIGURATOR_FORM_CONTEXT_ID } from './constants/customStylesConfigurator.constants';

import styles from './customStylesConfigurator.module.scss';

const CustomStylesConfigurator = ({
  isModalVisible,
  currentFieldTitle,
  selectedModuleIndex,
  componentType,
  allFormValues,
  selectedViewComponentProperties,
  errors,
  onAction,
}) => {
  const handleSubmit = useCallback(() => {
    if (!_isEmpty(allFormValues)) {
      triggerSubmit(CUSTOM_STYLES_CONFIGURATOR_FORM_CONTEXT_ID, { actionType: ACTION_TYPES.ON_SAVE_MODAL });
    } else {
      onAction({
        type: ACTION_TYPES.ON_SAVE_MODAL,
      });
    }
  }, [allFormValues, onAction]);

  const handleCancelModal = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CANCEL_MODAL,
    });
  }, [onAction]);

  const handleConfigureClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CLICK_CONFIGURE_CUSTOM_STYLE,
    });
  }, [onAction]);

  const { selectedCustomStyleTitles } = useMemo(() => getSelectedCustomStyles(allFormValues), [allFormValues]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE,
      payload: { selectedViewComponentProperties },
    });
  }, [onAction, selectedViewComponentProperties]);

  return (
    <div className={styles.customStyleHandlerComponent}>
      <Heading size={5} className={styles.customStyleComponentHeading}>
        {__('Custom Styles Configurator')}
      </Heading>
      <div className={styles.customStyleComponent}>
        <div className={styles.addedCustomStyleContainer}>
          <div className={styles.addedCustomStyleSection}>
            {__('Added Styles ')}({_size(selectedCustomStyleTitles)})
            <PropertyControlledComponent controllerProperty={_size(selectedCustomStyleTitles) > 0}>
              <TagsRenderer fieldValue={selectedCustomStyleTitles} maxLimit={2} tagClassName={styles.tag} tagContentClassName={styles.tagContent} />
            </PropertyControlledComponent>
          </div>
        </div>
        <Button disable view={Button.VIEW.TERTIARY} className={styles.configureButton} onClick={handleConfigureClick}>
          {__('Configure')}
        </Button>
      </div>
      <Modal
        visible={isModalVisible}
        width={SIZES.XL}
        bodyStyle={{ padding: 0 }}
        title={__(`Custom Styles for ${currentFieldTitle}`)}
        submitBtnText={__('Submit')}
        onSubmit={handleSubmit}
        onCancel={handleCancelModal}
      >
        <CustomStylesModal
          selectedModuleIndex={selectedModuleIndex}
          componentType={componentType}
          allFormValues={allFormValues}
          errors={errors}
          onAction={onAction}
        />
      </Modal>
    </div>
  );
};

CustomStylesConfigurator.propTypes = {
  isModalVisible: PropTypes.bool,
  currentFieldTitle: PropTypes.string,
  componentType: PropTypes.string,
  selectedModuleIndex: PropTypes.number,
  selectedViewComponentProperties: PropTypes.object,
  allFormValues: PropTypes.array,
  errors: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

CustomStylesConfigurator.defaultProps = {
  isModalVisible: false,
  selectedModuleIndex: undefined,
  currentFieldTitle: EMPTY_STRING,
  componentType: undefined,
  selectedViewComponentProperties: EMPTY_OBJECT,
  allFormValues: EMPTY_ARRAY,
  errors: EMPTY_ARRAY,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(CustomStylesConfigurator);
