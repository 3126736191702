import React, { useCallback } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';

import ACTION_TYPES from './constants/appList.actionTypes';
import { INITIAL_STATE_APP_LIST_PROPS, TABLE_CONSTANTS } from './constants/appList.general.constants';
import TABLE_MANAGER_PROPS from './constants/appList.tableManagerProps';
import HEADER_PROPS from './constants/appList.headerProps';
import SUB_HEADER_PROPS from './constants/appList.subHeaderProps';

import { COLUMN_CONFIG } from './helpers/appList.columns';
import { getFilterProps, getTableProps } from './helpers/appList.helpers';
import ACTION_HANDLERS from './helpers/appList.actionHandlers';
import EntityMappings from '../../organisms/entityMappings/EntityMappings';

const AppList = ({
  loading,
  isEntityMappingsModalVisible,
  currentPage,
  pageSize,
  totalNumberOfEntries,
  searchText,
  selectedFilterGroup,
  nextPageToken,
  rowActionApplication,
  applications,
  selectedFilters,
  onAction,
}) => {
  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({
        type: actionType,
        payload,
      });
    },
    [onAction],
  );

  const handleCancelModal = () => onAction({ type: ACTION_TYPES.CLOSE_ENTITY_MAPPINGS });

  return (
    <>
      <TableManager
        tokenPagination
        searchText={searchText}
        nextPageToken={nextPageToken}
        columns={COLUMN_CONFIG}
        tableManagerProps={TABLE_MANAGER_PROPS}
        headerProps={HEADER_PROPS}
        subHeaderProps={SUB_HEADER_PROPS}
        data={applications}
        filterProps={getFilterProps(selectedFilters, selectedFilterGroup)}
        tableProps={getTableProps(handleRowAction, currentPage, loading, pageSize, totalNumberOfEntries)}
        onAction={onAction}
      />
      {isEntityMappingsModalVisible && <EntityMappings applicationConfiguration={rowActionApplication} onClose={handleCancelModal} />}
    </>
  );
};

AppList.propTypes = {
  loading: PropTypes.bool,
  isEntityMappingsModalVisible: PropTypes.bool,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalNumberOfEntries: PropTypes.number,
  searchText: PropTypes.string,
  selectedFilterGroup: PropTypes.string,
  nextPageToken: PropTypes.string,
  rowActionApplication: PropTypes.object,
  selectedFilters: PropTypes.array,
  applications: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

AppList.defaultProps = {
  loading: true,
  isEntityMappingsModalVisible: false,
  pageSize: DEFAULT_PAGE_SIZE,
  currentPage: DEFAULT_PAGE_INFO.start,
  totalNumberOfEntries: TABLE_CONSTANTS.TOTAL_NUMBER_OF_ENTRIES,
  selectedFilterGroup: EMPTY_STRING,
  nextPageToken: EMPTY_STRING,
  searchText: EMPTY_STRING,
  rowActionApplication: EMPTY_OBJECT,
  selectedFilters: EMPTY_ARRAY,
  applications: EMPTY_ARRAY,
};

export default compose(withActions(INITIAL_STATE_APP_LIST_PROPS, ACTION_HANDLERS), React.memo)(AppList);
