import React from 'react';
import { Node } from 'slate';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

// components
import Button from '@tekion/tekion-components/atoms/Button';
import IconAsBtn from '@tekion/tekion-components/atoms/iconAsBtn';

// hooks
import useTemplateVariablePopupActions from './hooks/useTemplateVariablePopupActions';

// styles
import styles from './templateVariableSelectionPopupContent.module.scss';

function TemplateVariableSelectionPopupContent(props) {
  const { templateVariableNodeElement, closePopup } = props;

  const templateVariableDisplayValue = templateVariableNodeElement && Node.string(templateVariableNodeElement);

  const { handleEditTemplateVariable, handleTemplateVariableRemoval } = useTemplateVariablePopupActions(templateVariableNodeElement, closePopup);

  return (
    <div className={`p-16 relative flex flex-column ${styles.popupContainer}`}>
      <IconAsBtn onClick={closePopup} containerClassName={`absolute ${styles.closeBtn}`}>
        icon-close2
      </IconAsBtn>
      <div className={styles.templateVariableContentContainer}>
        <div className={styles.templateVariableContent}>{templateVariableDisplayValue}</div>
      </div>
      <div className={`d-flex ${styles.popupActions} p-t-12 full-width`}>
        <Button label={__('Edit')} key="edit" onClick={handleEditTemplateVariable} className="m-r-8" />
        <Button label={__('Remove')} key="remove" onClick={handleTemplateVariableRemoval} view={Button.VIEW.PRIMARY} />
      </div>
    </div>
  );
}

TemplateVariableSelectionPopupContent.propTypes = {
  templateVariableNodeElement: PropTypes.shape({
    type: PropTypes.string,
    children: PropTypes.array,
  }),
  closePopup: PropTypes.func,
};

TemplateVariableSelectionPopupContent.defaultProps = {
  templateVariableNodeElement: undefined,
  closePopup: _noop,
};

export default React.memo(TemplateVariableSelectionPopupContent);
