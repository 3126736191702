import { defaultMemoize } from 'reselect';

import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import workspaceUserReader from '../../../../../../../../readers/workSpaceUser.reader';

const ROWS = 20;

const getHasMore = defaultMemoize((nextPageToken) => !_isEmpty(nextPageToken));

const getLoadingMessage = () => __('Searching...');

const getWorkSpaceUserOptions = defaultMemoize((data, existingUserIds, rolesDataById) => {
  const filteredUsersData = _filter(data, (userData) => !_includes(existingUserIds, _get(userData, 'id', EMPTY_STRING)));
  return _map(filteredUsersData, (user) => {
    const roleId = workspaceUserReader.roleId(user);
    const roleData = _get(rolesDataById, roleId, EMPTY_OBJECT);
    return {
      ...user,
      label: `${workspaceUserReader.firstName(user)}  ${workspaceUserReader.lastName(user)} - ${
        _get(roleData, 'displayName') || __('No Role Assigned')
      }`,
      value: workspaceUserReader.id(user),
    };
  });
});

const getPayload = defaultMemoize((nextPageToken, searchText) => ({
  rows: ROWS,
  nextPageToken,
  searchText,
}));

export { getLoadingMessage, getWorkSpaceUserOptions, getPayload, getHasMore };
