const FIELD_IDS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  SEARCH_METADATA: 'searchMetadata',
  FIELD: 'field',
  BOOST: 'boost',
  RELATIONSHIP_FIELD_INFO: 'relationshipFieldInfo',
};

const ROWS = 20;

const SEARCH_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID = 'SEARCH_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID';

const INITIAL_SEARCH_OPTION = {
  [FIELD_IDS.SEARCH_METADATA]: [{ [FIELD_IDS.BOOST]: 1 }],
};

export { FIELD_IDS, SEARCH_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID, ROWS, INITIAL_SEARCH_OPTION };
