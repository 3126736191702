import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import withActions from '@tekion/tekion-components/connectors/withActions';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import Loader from '@tekion/tekion-components/molecules/loader';

import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';
import Modal from '@tekion/tekion-components/molecules/Modal';
import PageViewer from './organisms/pageViewer/PageViewer';
import PropertyPanel from './organisms/propertyPanel';
import WidgetsPanel from './organisms/widgetsPanel/WidgetPanel';

import VisualBuilderContext from '../../context/visualBuilder.context';
import ACTION_HANDLERS from './helpers/visualBuilder.actionHandlers';
import { ACTION_TYPES, BUTTON_VIEWS, VISUAL_BUILDER_CONTEXT_KEYS } from './constants/visualBuilder.general.constants';

import styles from './visualBuilder.module.scss';

const VisualBuilder = (props) => {
  const {
    isLoading,
    isSaveLoading,
    showCancelModal,
    showPreviewModal,
    contentWidth,
    contentHeight,
    pageType,
    pageConfiguration,
    previewPageConfiguration,
    selectedWidgetConfig,
    pageEntity,
    pageEntityRecord,
    visualBuilderRendererContextValue,
    history,
    onAction,
  } = props;

  const selectedWidgetComponentId = useMemo(() => tget(selectedWidgetConfig, 'widgetName', ''), [selectedWidgetConfig]);

  const handleAddContainer = useCallback(() => {
    onAction({ type: ACTION_TYPES.ADD_GRID_LAYOUT });
  }, [onAction]);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const handleConfirmationDialogSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CONFIRM_DIALOG_SUBMIT });
  }, [onAction]);

  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CONFIRM_DIALOG_CANCEL });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_SUBMIT });
  }, [onAction]);

  const handlePreviewModalClose = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_PREVIEW_MODAL_CLOSE });
  }, [onAction]);

  const handleOpenPreviewModal = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_PREVIEW_MODAL_OPEN });
  }, [onAction]);

  const handleWidgetComponentSelect = useCallback(
    (widgetComponentConfig) => {
      onAction({ type: ACTION_TYPES.ON_WIDGET_CLICK, payload: { widgetComponentConfig } });
    },
    [onAction],
  );

  const handleWidgetComponentDelete = useCallback(
    (id, index, deleteWidgetComponentConfig) => {
      onAction({ type: ACTION_TYPES.ON_WIDGET_DELETE, payload: { deleteWidgetComponentConfig, index, deletedWidgetComponentId: id } });
    },
    [onAction],
  );

  const handleWidgetComponentDrop = useCallback(
    (id, index, droppedComponent) => {
      onAction({
        type: ACTION_TYPES.ON_DROP,
        payload: {
          id,
          index,
          droppedComponent,
        },
      });
    },
    [onAction],
  );

  const applicationContext = useMemo(
    () => ({
      ...visualBuilderRendererContextValue,
      [VISUAL_BUILDER_CONTEXT_KEYS.SET_APPLICATION_VARIABLES]: (variableName, value) => {
        onAction({ type: ACTION_TYPES.SET_APPLICATION_VARIABLES, payload: { variableName, value } });
      },
    }),
    [visualBuilderRendererContextValue, onAction],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <VisualBuilderContext.Provider
      /* eslint-disable-next-line react/jsx-no-constructed-context-values */
      value={{
        selectedWidgetComponentId,
        onSelect: handleWidgetComponentSelect,
        onDelete: handleWidgetComponentDelete,
        onDrop: handleWidgetComponentDrop,
      }}
    >
      <div className={styles.visualBuilderContainer}>
        <div className={styles.canvasPanelsContainer}>
          <DndProvider backend={HTML5Backend}>
            <div className={styles.widgetCanvasContainer}>
              <div className={styles.widgetPanelItems} style={{ height: contentHeight }}>
                <WidgetsPanel pageType={pageType} onClickAddContainer={handleAddContainer} />
              </div>

              <div className={styles.pageViewerContainer}>
                <PageViewer
                  isPreviewMode={false}
                  pageConfiguration={pageConfiguration}
                  contentWidth={0.78 * contentWidth}
                  contentHeight={contentHeight}
                  pageEntity={pageEntity}
                  pageEntityRecord={pageEntityRecord}
                  applicationContext={applicationContext}
                  history={history}
                  visualBuilderOnAction={onAction}
                />
              </div>
            </div>
          </DndProvider>

          <div className={styles.propertyPanelContainer}>
            <PropertyPanel
              pageEntity={pageEntity}
              pageConfiguration={pageConfiguration}
              selectedWidgetConfig={selectedWidgetConfig}
              onChangeWidgetConfigurationAction={onAction}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <PropertyControlledComponent controllerProperty={showCancelModal}>
            <ConfirmationDialog
              isVisible={showCancelModal}
              title={__('Cancel Changes')}
              content={__('Are you sure you want to cancel all the changes made by you? All data will be lost if you click yes.')}
              onSubmit={handleConfirmationDialogSubmit}
              onCancel={handleConfirmationDialogCancel}
            />
          </PropertyControlledComponent>

          <PropertyControlledComponent controllerProperty={showPreviewModal}>
            <Modal title={__('Preview')} centered visible={showPreviewModal} width="90%" showFooter={false} onCancel={handlePreviewModalClose}>
              <div className={styles.previewContainer}>
                <PageViewer
                  isPreviewMode
                  pageConfiguration={previewPageConfiguration}
                  contentWidth={contentWidth}
                  contentHeight={contentHeight}
                  pageEntity={pageEntity}
                  pageEntityRecord={pageEntityRecord}
                  applicationContext={applicationContext}
                  history={history}
                  visualBuilderOnAction={onAction}
                />
              </div>
            </Modal>
          </PropertyControlledComponent>

          <SaveComponent
            showAdditionalButton
            additionalActionLoading={isSaveLoading}
            primaryButtonLabel={__('Preview')}
            primaryActionView={BUTTON_VIEWS.SECONDARY}
            additionalActionView={BUTTON_VIEWS.PRIMARY}
            additionalButtonLabel={__('Save')}
            onAdditionalAction={handleSubmit}
            onSecondaryAction={handleCancel}
            onPrimaryAction={handleOpenPreviewModal}
          />
        </div>
      </div>
    </VisualBuilderContext.Provider>
  );
};

VisualBuilder.propTypes = {
  isLoading: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  showCancelModal: PropTypes.bool,
  showPreviewModal: PropTypes.bool,
  contentWidth: PropTypes.number,
  contentHeight: PropTypes.number,
  pageType: PropTypes.string,
  pageConfiguration: PropTypes.object,
  selectedWidgetConfig: PropTypes.object,
  previewPageConfiguration: PropTypes.object,
  pageEntity: PropTypes.object,
  pageEntityRecord: PropTypes.object,
  visualBuilderRendererContextValue: PropTypes.object,
  history: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
};

VisualBuilder.defaultProps = {
  isLoading: false,
  isSaveLoading: false,
  showCancelModal: false,
  showPreviewModal: false,
  contentWidth: 0,
  contentHeight: 0,
  pageType: EMPTY_STRING,
  selectedWidgetConfig: undefined,
  pageConfiguration: undefined,
  previewPageConfiguration: undefined,
  pageEntity: undefined,
  pageEntityRecord: undefined,
  visualBuilderRendererContextValue: EMPTY_OBJECT,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(VisualBuilder);
