import React, { useMemo } from 'react';
import compose from 'recompose/compose';
import renameProps from 'recompose/renameProps';
import PropTypes from 'prop-types';

import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import SELECT_OPTIONS_FORM_ACTION_HANDLERS from './helpers/selectOptionsForm.actionHandlers';
import getFields from './helpers/selectOptionsForm.fields';
import getSections from './helpers/selectOptionsForm.sections';

import FIELDS_FORM_FIELD_IDS from '../../constants/fieldsForm.fieldIds';

const SelectOptionsForm = ({ isModalVisible, modalData, entity, renderOptions, error, values, onAction }) => {
  const fields = useMemo(
    () => getFields(entity, values, isModalVisible, modalData, renderOptions),
    [entity, values, isModalVisible, modalData, renderOptions],
  );
  const sections = useMemo(() => getSections(values), [values]);

  return (
    <FormWithSubmission
      className="full-width"
      contextId={FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM}
      errors={error}
      values={values}
      fields={fields}
      sections={sections}
      onAction={onAction}
    />
  );
};

SelectOptionsForm.propTypes = {
  isModalVisible: PropTypes.bool,
  modalData: PropTypes.object,
  entity: PropTypes.object,
  renderOptions: PropTypes.object,
  error: PropTypes.object,
  values: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

SelectOptionsForm.defaultProps = {
  isModalVisible: false,
  modalData: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  renderOptions: EMPTY_OBJECT,
  error: undefined,
  values: EMPTY_OBJECT,
};

export default compose(
  renameProps({ onAction: 'fieldsFormOnAction', value: 'fieldsFormSelectOptionsValues' }),
  withActions(EMPTY_OBJECT, SELECT_OPTIONS_FORM_ACTION_HANDLERS),
)(SelectOptionsForm);
