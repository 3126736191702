import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { editPermissionByName, getPermissionByName } from '../../../../../../../../actions/permissionSetManagement.action';
import globalLookupItemsResolver from '../../../../../../../../actions/globalLookupItemsResolver';

import { createAddProjectsPayload } from './projectSettings.helpers';

import { LOOKUP_ITEM_TO_RESOLVE, LOOKUP_FIELD_TO_RESOLVE } from '../../../../../../../../constants/globalLookupItemsResolver.constants';
import { ACTION_TYPES as ASSIGN_MODAL_ACTION_TYPES } from '../../assignModal/assignModal.constants';
import ACTION_TYPES from '../constants/projectSettings.actionTypes';

const fetchData = async ({ getState, setState }) => {
  const { match } = getState();
  const permissionName = _get(match, 'params.permissionName', EMPTY_STRING);

  setState({ isLoading: true });
  const permissionResponse = await getPermissionByName(permissionName);
  const projectPermission = _get(permissionResponse, 'projectPermission', EMPTY_OBJECT);
  let projectsData = [];
  let projectNames = [];
  if (!_isEmpty(projectPermission)) {
    projectNames = _get(projectPermission, 'projectNames', EMPTY_ARRAY);

    if (!_isEmpty(projectNames)) {
      const response = await globalLookupItemsResolver({ [LOOKUP_ITEM_TO_RESOLVE.PROJECT]: { [LOOKUP_FIELD_TO_RESOLVE.NAME]: projectNames } }, false);
      const resolvedProjectNamesToProjectDataMap = tget(response, LOOKUP_ITEM_TO_RESOLVE.PROJECT, EMPTY_OBJECT);
      projectsData = _map(resolvedProjectNamesToProjectDataMap);
    }
  }

  setState({ projectSettingsData: projectsData, isLoading: false, permissionData: permissionResponse, totalCount: _size(projectsData) });
};

const handleAssignProjectClick = ({ setState }) => {
  setState({ isAssignProjectModalVisible: true });
};

const handleAssignProjectModalClose = ({ setState }) => {
  setState({ isAssignProjectModalVisible: false });
};

const handleProjectDropdownChange = ({ params, setState }) => {
  const { value = EMPTY_ARRAY } = params;
  setState({ selectedProjectsModalData: value });
};

const handleProjectDropdownDone = async ({ getState, setState }) => {
  const { match, selectedProjectsModalData, permissionData } = getState();
  const projectNamesFromValue = _map(selectedProjectsModalData, 'value');
  const permissionName = _get(match, 'params.permissionName', EMPTY_STRING);
  setState({ isAssignProjectModalDataSubmitting: true });

  if (!_isEmpty(projectNamesFromValue)) {
    const payload = createAddProjectsPayload(projectNamesFromValue, permissionData);
    const response = await editPermissionByName(permissionName, payload);

    if (!_isEmpty(response)) {
      setState({ selectedProjectsModalData: [] });
    }
  }
  setState({ isAssignProjectModalVisible: false, isAssignProjectModalDataSubmitting: false }, () => fetchData({ getState, setState }));
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: fetchData,
  [ACTION_TYPES.ASSIGN_PROJECT_CLICK]: handleAssignProjectClick,
  [ASSIGN_MODAL_ACTION_TYPES.ASSIGN_MODAL_CLOSE]: handleAssignProjectModalClose,
  [ASSIGN_MODAL_ACTION_TYPES.ASSIGN_DROPDOWN_ON_CHANGE]: handleProjectDropdownChange,
  [ASSIGN_MODAL_ACTION_TYPES.ASSIGN_DROPDOWN_DONE]: handleProjectDropdownDone,
};

export default ACTION_HANDLERS;
