import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import { TAB_SIZES } from './tabWidgetRenderer.constants';
import { TAB_TEMPLATE_TYPE_TO_CLASSNAME } from '../../constants/visualBuilder.properties.constants';

import styles from './tabWidgetRenderer.module.scss';

const TabHeader = ({ isTabSelected, iconToRender, title, tabSize, tabTemplateType }) => {
  const tabHeaderClassName = cx(styles.tabHeader, tget(styles, TAB_TEMPLATE_TYPE_TO_CLASSNAME[tabTemplateType], EMPTY_STRING), {
    [styles.isSelected]: isTabSelected,
  });

  const tabSizeClassName = cx(styles.displayName, {
    [styles.small]: tabSize === TAB_SIZES.SMALL,
    [styles.medium]: tabSize === TAB_SIZES.MEDIUM,
    [styles.large]: tabSize === TAB_SIZES.LARGE,
  });

  return (
    <div className={tabHeaderClassName}>
      <PropertyControlledComponent controllerProperty={!_isEmpty(iconToRender)}>
        <FontIcon size={FontIcon.SIZES.L} className={styles.iconClassName}>
          {iconToRender}
        </FontIcon>
      </PropertyControlledComponent>
      <span className={tabSizeClassName}> {__('{{title}}', { title })}</span>
    </div>
  );
};

TabHeader.propTypes = {
  isTabSelected: PropTypes.bool,
  iconToRender: PropTypes.string,
  tabSize: PropTypes.string,
  title: PropTypes.string.isRequired,
  tabTemplateType: PropTypes.string.isRequired,
};

TabHeader.defaultProps = {
  isTabSelected: false,
  iconToRender: EMPTY_STRING,
  tabSize: TAB_SIZES.MEDIUM,
};

export default TabHeader;
