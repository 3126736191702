import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';

import { GENERAL_PROPERTIES, TEXT_WIDGET_PROPERTIES } from '../../constants/visualBuilder.properties.constants';
import { COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP, WIDGET_TYPES } from '../../constants/visualBuilder.general.constants';
import { CUSTOM_STYLE_IDS } from '../../../../constants/customStyles.constants';

const SUPPORTED_CUSTOM_STYLE_TAB = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[WIDGET_TYPES.TEXT_WIDGET];

const getTextProps = (properties) => {
  const customStyles = tget(properties, GENERAL_PROPERTIES.CUSTOM_STYLES);
  const resolvedCustomStyles = getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_TAB, customStyles);

  const containerStyle = tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTAINER, EMPTY_OBJECT);

  const textProps = {
    size: tget(properties, TEXT_WIDGET_PROPERTIES.SIZE, 2),
    style: containerStyle,
  };

  return textProps;
};

export { getTextProps };
