import React, { useEffect, useCallback } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';
import Drawer from '@tekion/tekion-components/molecules/drawer';
import Heading from '@tekion/tekion-components/atoms/Heading';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import ViewViewer from '../../../../organisms/viewBuilder/organisms/viewViewer/ViewViewer';
import ACTION_HANDLERS from './helpers/entityRecordRightPane.actionHandlers';
import { ACTION_TYPES, DRAWER_STYLE, DRAWER_BODY_STYLE } from './constants/entityRecordRightPane.constants';

import styles from './entityRecordRightPane.module.scss';

// eslint-disable-next-line no-unused-vars
const EntityRecordRightPane = ({ isDrawerVisible, entity, entityRecord, entityRecordId, rightPaneViewConfig, onAction }) => {
  const handleCloseDrawer = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CLOSE_SIDE_PANE });
  }, [onAction]);

  const handleEditClick = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CLICK_EDIT });
  }, [onAction]);

  const handleViewClick = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CLICK_VIEW });
  }, [onAction]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT_FORM });
  }, [onAction]);

  return (
    <Drawer
      style={DRAWER_STYLE}
      bodyStyle={DRAWER_BODY_STYLE}
      visible={isDrawerVisible}
      onClose={handleCloseDrawer}
      closable
      destroyOnClose
      width={Drawer.SIZES.LG}
    >
      <div className={styles.drawerHeaderContainer}>
        <Heading size={2} className={styles.drawerHeadingLabel}>
          {__('Entity Record')}
        </Heading>
      </div>
      <div className={styles.drawerBodyContainer}>
        <div className={styles.icons}>
          <FontIcon size={SIZES.MD_S} onClick={handleEditClick} className={styles.icon}>
            icon-edit
          </FontIcon>
          <FontIcon size={SIZES.MD_S} onClick={handleViewClick} className={styles.icon}>
            icon-details
          </FontIcon>
        </div>
        <PropertyControlledComponent controllerProperty={!_isEmpty(entity)}>
          <div className={styles.viewViewer}>
            <ViewViewer isPreviewMode viewConfiguration={rightPaneViewConfig} entity={entity} entityRecord={entityRecord} />
          </div>
        </PropertyControlledComponent>
      </div>
    </Drawer>
  );
};

EntityRecordRightPane.propTypes = {
  isDrawerVisible: PropTypes.bool,
  rightPaneViewConfig: PropTypes.object,
  entity: PropTypes.object,
  entityRecord: PropTypes.object,
  entityRecordId: PropTypes.string,
  onAction: PropTypes.func.isRequired,
};

EntityRecordRightPane.defaultProps = {
  isDrawerVisible: false,
  rightPaneViewConfig: EMPTY_OBJECT,
  entity: undefined,
  entityRecord: undefined,
  entityRecordId: undefined,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS))(EntityRecordRightPane);
