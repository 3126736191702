import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';

// Tekion-components
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

// Services
import validationRuleServices from '../services/validationRule.services';

const fetchValidationRules = async (entityName, payload = EMPTY_OBJECT) => {
  try {
    const response = await validationRuleServices.fetchValidationRules(entityName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch validation rules, please try again later!')));
    return EMPTY_ARRAY;
  }
};

const createValidationRule = async (entityName, payload = EMPTY_OBJECT) => {
  try {
    if (_isEmpty(_get(payload, 'condition', EMPTY_OBJECT))) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage(EMPTY_OBJECT, __(' Condition field cannot be empty. ')));
      return EMPTY_OBJECT;
    }
    const response = await validationRuleServices.createValidationRule(entityName, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Validation rule created successfully'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create validation rule, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const updateValidationRule = async (entityName, ruleName, payload = EMPTY_OBJECT) => {
  try {
    if (_isEmpty(_get(payload, 'condition', EMPTY_OBJECT))) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage(EMPTY_OBJECT, __(' Condition field cannot be empty. ')));
      return EMPTY_OBJECT;
    }
    const response = await validationRuleServices.updateValidationRule(entityName, ruleName, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Validation rule updated successfully'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update validation rule, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const getValidationRule = async (entityName, ruleName, payload = EMPTY_OBJECT) => {
  try {
    const response = await validationRuleServices.getValidationRule(entityName, ruleName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get validation rule, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const createTask = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await validationRuleServices.createTask(payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Your Code is compiled in few seconds.Please wait for a moment.'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create Task for Compilation of Custom Code, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const getTaskById = async (taskId) => {
  try {
    const response = await validationRuleServices.getTaskById(taskId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get status of task for Compilation of Custom Code, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const getCustomCodeMetaData = async () => {
  try {
    const response = await validationRuleServices.getCustomCodeMetaData();
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get custom code metaData, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const getCustomCodeByClassName = async (className) => {
  try {
    const response = await validationRuleServices.getCustomCodeByClassName(className);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get custom code, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const saveCustomCode = async (payload) => {
  try {
    const response = await validationRuleServices.saveCustomCode(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to save custom code, please try again later!')));
    return EMPTY_OBJECT;
  }
};

export {
  fetchValidationRules,
  createValidationRule,
  updateValidationRule,
  getValidationRule,
  getTaskById,
  getCustomCodeByClassName,
  getCustomCodeMetaData,
  saveCustomCode,
  createTask,
};
