const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showHeader: false,
  showSearch: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const INITIAL_STATE_ENTITY_TAB_PROPS = {
  pageSize: 50,
};

export { TABLE_MANAGER_PROPS, INITIAL_STATE_ENTITY_TAB_PROPS };
