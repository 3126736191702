import { PUSH_NOTIFICATION_FIELD_IDS } from '../../../constants/nodeDataFieldIds';
import { TASK_DEF_IDS } from '../../../constants/workflow.constants';

const NOTIFICATION_INITIAL_FORM_VALUES = {
  [PUSH_NOTIFICATION_FIELD_IDS.ACTION_TYPE]: TASK_DEF_IDS.PUSH_NOTIFICATION,
};

const ACTION_TYPES = {
  ON_ASYNC_LOADED_OPTIONS: 'ON_ASYNC_LOADED_OPTIONS',
};

export { NOTIFICATION_INITIAL_FORM_VALUES, ACTION_TYPES };
