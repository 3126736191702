import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import compose from 'recompose/compose';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';
import { tget } from '@tekion/tekion-base/utils/general';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import useDeepCompareEffect from '../../../../hooks/useDeepCompareEffect';
import ViewViewer from '../viewViewer/ViewViewer';
import ViewRendererContext from '../../../../context/viewRenderer.context';

import ACTION_HANDLERS from './entityViewViewer.actionHandlers';

import { VIEW_CONTEXT_KEYS } from '../../constants/viewBuilder.constants';
import { ACTION_TYPES } from './entityViewViewer.constants';
import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../constants/viewBuilder.constants';

const EntityViewViewer = ({
  widgetName,
  contextId,
  recordTypeSelectionViewName,
  entityViewConfiguration,
  recordTypeEntityDef,
  applicationContext,
  entityRecord,
  viewVariables,
  viewConfigsByName,
  currentViewConfig,
  preFilledRecordSelectionFormValues,
  recordTypeSelectionRecord,
  formData,
  onAction,
  ...restProps
}) => {
  const recordSelectionViewConfig = useMemo(
    () => tget(viewConfigsByName, recordTypeSelectionViewName),
    [viewConfigsByName, recordTypeSelectionViewName],
  );

  const handleSetViewVariables = useCallback(
    (payload) => {
      onAction({
        type: ACTION_TYPES.ON_VIEW_VARIABLE_CHANGE,
        payload,
      });
    },
    [onAction],
  );

  const handleClearVariables = useCallback(() => {
    onAction({
      type: ACTION_TYPES.CLEAR_VIEW_VARIABLES,
    });
  }, [onAction]);

  const handleRecordSelectionFormSubmit = useCallback(
    (payload, formContextId, additional) => {
      onAction({
        type: ACTION_TYPES.ON_FORM_SUBMIT,
        payload: {
          recordTypeFormValues: payload,
          formContextId,
          additional,
        },
      });
    },
    [onAction],
  );

  const handleSubmit = useCallback(
    (payload) => {
      if (!_isEmpty(recordTypeSelectionViewName)) {
        triggerSubmit(recordTypeSelectionViewName, payload);
      } else {
        handleRecordSelectionFormSubmit(payload, contextId);
      }
    },
    [handleRecordSelectionFormSubmit, recordTypeSelectionViewName, contextId],
  );

  const handleRecordSelectionFormValueChange = useCallback(
    (payload) => {
      onAction({
        type: ACTION_TYPES.ON_RECORD_SELECTION_FIELD_CHANGE,
        payload,
      });
    },
    [onAction],
  );

  const handleFormValueChange = useCallback(
    (payload) => {
      onAction({
        type: ACTION_TYPES.ON_FORM_VALUE_CHANGE,
        payload,
      });
    },
    [onAction],
  );

  const handleFormFieldBlur = useCallback(
    (payload) => {
      onAction({
        type: ACTION_TYPES.ON_BLUR,
        payload,
      });
    },
    [onAction],
  );

  const viewContextProviderValue = useMemo(
    () => ({
      [VIEW_CONTEXT_KEYS.VIEW_CONTEXT]: { viewVariables, handleSetViewVariables, handleClearVariables },
      [VIEW_CONTEXT_KEYS.WIDGET_CONTEXT]: { widgetName },
      [VIEW_CONTEXT_KEYS.APPLICATION_CONTEXT]: applicationContext,
    }),
    [viewVariables, handleSetViewVariables, handleClearVariables, applicationContext, widgetName],
  );

  useDeepCompareEffect(() => {
    onAction({ type: ACTION_TYPES.INIT, payload: { entityViewConfiguration } });
  }, [onAction, entityViewConfiguration]);

  return (
    <ViewRendererContext.Provider value={viewContextProviderValue}>
      {recordTypeSelectionViewName ? (
        <ViewViewer
          contextId={recordTypeSelectionViewName}
          entity={recordTypeEntityDef}
          entityRecord={recordTypeSelectionRecord}
          viewConfiguration={recordSelectionViewConfig}
          initialFormValues={preFilledRecordSelectionFormValues}
          onFormSubmit={handleRecordSelectionFormSubmit}
          onFormValueChange={handleRecordSelectionFormValueChange}
          onFormFieldBlur={handleFormFieldBlur}
        />
      ) : null}
      <ViewViewer
        {...restProps}
        isPreviewMode
        contextId={contextId}
        viewConfigName={tget(currentViewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME)}
        applicationContext={applicationContext}
        viewConfiguration={currentViewConfig}
        viewConfigsByName={viewConfigsByName}
        entity={recordTypeEntityDef}
        entityRecord={formData}
        onFormSubmit={handleSubmit}
        onFormValueChange={handleFormValueChange}
        onFormFieldBlur={handleFormFieldBlur}
      />
    </ViewRendererContext.Provider>
  );
};

EntityViewViewer.propTypes = {
  widgetName: PropTypes.string,
  contextId: PropTypes.string,
  recordTypeSelectionViewName: PropTypes.string,
  entityViewConfiguration: PropTypes.object.isRequired,
  recordTypeEntityDef: PropTypes.object,
  entityRecord: PropTypes.object,
  applicationContext: PropTypes.object,
  viewVariables: PropTypes.object,
  viewConfigsByName: PropTypes.object,
  currentViewConfig: PropTypes.object,
  preFilledRecordSelectionFormValues: PropTypes.object,
  recordTypeSelectionRecord: PropTypes.object,
  formData: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};
EntityViewViewer.defaultProps = {
  widgetName: EMPTY_STRING,
  contextId: EMPTY_STRING,
  entityRecord: EMPTY_OBJECT,
  applicationContext: EMPTY_OBJECT,
  recordTypeEntityDef: EMPTY_OBJECT,
  viewVariables: EMPTY_OBJECT,
  viewConfigsByName: EMPTY_OBJECT,
  currentViewConfig: EMPTY_OBJECT,
  recordTypeSelectionViewName: undefined,
  preFilledRecordSelectionFormValues: EMPTY_OBJECT,
  recordTypeSelectionRecord: EMPTY_OBJECT,
  formData: EMPTY_OBJECT,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS), React.memo)(EntityViewViewer);
