import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import _set from 'lodash/set';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import Modal from '@tekion/tekion-components/molecules/Modal';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import {
  getApiFormattedFormValuesAndUsedVariables,
  getAssignedVariableInfo,
  getFormFormattedFormValues,
  getResponseAssignedVariableOptions,
} from './calloutModal.helpers';

import { getSections, getFieldConfig } from './calloutModal.fieldConfig';
import { CALLOUT_INITIAL_FORM_VALUES, CONTEXT_ID } from './calloutModal.constants';
import { MODAL_MODES } from '../../../constants/workflowBuilder.constants';
import { CALLOUT_FIELD_IDS } from '../../../constants/nodeDataFieldIds';
import { FIELD_EVENT_ACTION_NAMES } from '../../../constants/workflow.constants';

import styles from './calloutModal.module.scss';

const CallOutModal = ({
  mode,
  parentNodeId,
  stepDetails,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  variablesInfo,
  variablesByNodeId,
  onCancel,
  onSubmit,
}) => {
  const [values, setFormValues] = useState(CALLOUT_INITIAL_FORM_VALUES);
  const [errors, setErrors] = useState({});
  const [responseVariableOptions, setResponseVariableOptions] = useState([]);

  const nodeId = tget(stepDetails, 'nodeId') || parentNodeId;

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { value: updatedValue, id, eventDetails } = payload;

          if (id === CALLOUT_FIELD_IDS.ASSIGNED_VARIABLE && tget(eventDetails, 'action') === FIELD_EVENT_ACTION_NAMES.CREATE_OPTION) {
            setResponseVariableOptions((prevOptions) => [...prevOptions, { label: updatedValue, value: updatedValue }]);
          }

          setFormValues((prev) => ({ ...prev, [id]: updatedValue }));
          break;
        }
        case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
          const { errors: newErrors } = payload;
          setErrors(newErrors);
          break;
        }

        case FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT: {
          setFormValues((_formValues) => {
            const { apiFormValues, usedVariables } = getApiFormattedFormValuesAndUsedVariables(_formValues);
            _set(stepDetails, 'uiMetadata.usedVariables', _uniq(usedVariables));

            const assignedVariableInfo = getAssignedVariableInfo(_formValues);

            onSubmit(apiFormValues, [], assignedVariableInfo, _uniq(usedVariables));
            return {};
          });
          setErrors({});
          break;
        }

        default:
      }
    },
    [onSubmit, stepDetails],
  );

  const fields = useMemo(
    () => getFieldConfig(conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, responseVariableOptions),
    [conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, responseVariableOptions],
  );

  const sections = useMemo(() => getSections(values), [values]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, []);

  const handleCancel = useCallback(() => {
    setFormValues({});
    setErrors({});
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    if (mode === MODAL_MODES.EDIT) {
      const userData = tget(stepDetails, 'userData.data');
      if (!_isEmpty(userData)) {
        const updatedFormValues = getFormFormattedFormValues(userData);
        setFormValues(updatedFormValues);
      }
    }
  }, [mode, stepDetails]);

  useEffect(() => {
    setResponseVariableOptions((prev) => {
      const entityName = tget(values, CALLOUT_FIELD_IDS.ENTITY_NAME, EMPTY_STRING);
      const scopedVariables = tget(variablesByNodeId, nodeId, EMPTY_ARRAY);
      const _responseVariableOptions = getResponseAssignedVariableOptions(variablesInfo, scopedVariables, entityName);

      return [...prev, ..._responseVariableOptions];
    });
  }, [nodeId, values, variablesByNodeId, variablesInfo]);

  return (
    <Modal
      visible
      destroyOnClose
      title={__('Callout Info')}
      submitBtnText={__('Add')}
      width={SIZES.XL}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    >
      <FormWithSubmission
        className={styles.callOutContentContainer}
        contextId={CONTEXT_ID}
        sections={sections}
        fields={fields}
        values={values}
        errors={errors}
        onAction={handleAction}
      />
    </Modal>
  );
};

CallOutModal.propTypes = {
  mode: PropTypes.string,
  parentNodeId: PropTypes.string.isRequired,
  stepDetails: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  variablesInfo: PropTypes.object,
  variablesByNodeId: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

CallOutModal.defaultProps = {
  mode: EMPTY_STRING,
  stepDetails: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  variablesInfo: EMPTY_OBJECT,
  variablesByNodeId: EMPTY_OBJECT,
  onSubmit: _noop,
  onCancel: _noop,
};

export default CallOutModal;
