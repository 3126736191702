import { defaultMemoize } from 'reselect';
import _isEmpty from 'lodash/isEmpty';
import _reject from 'lodash/reject';
import _head from 'lodash/head';
import _get from 'lodash/get';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import { getControllingFieldOptions, getValuesOptions } from './approvalSettingFieldForm.helpers';
import { nameValidator } from '../../../../../../../utils/formValidators';

import { FORM_MODES } from '../../../../../../../constants/general.constants';
import FIELD_TYPES from '../../../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../../../constants/fieldDefinition.dataTypes';
import APPROVAL_SETTING_FIELD_IDS from '../../constants/approvalSettings.fieldIds';
import FIELD_IDS from '../constants/approvalSettingFieldForm.fieldIds';
import { FORM_FIELDS } from '../constants/approvalSettingFieldForm.fields';
import { FIELD_TYPE_OPTIONS, FIELD_TYPE_OPTIONS_TEXT } from '../constants/approvalSettingFieldForm.constants';

const getFormFields = defaultMemoize(({ formValues, formMode, approvalSetting, modalData, isModalVisible, selectedFields, selectedOptions }) => {
  const dataType = getArraySafeValue(_get(formValues, FIELD_IDS.DATA_TYPE));

  const disableDataTypeField = false;
  let fieldTypeOptions = FIELD_TYPE_OPTIONS;

  switch (dataType) {
    case DATA_TYPES.TEXT:
      fieldTypeOptions = _reject(fieldTypeOptions, (option) => _get(option, 'value', '') === FIELD_TYPES.RANGE);
      fieldTypeOptions = [...fieldTypeOptions, ...FIELD_TYPE_OPTIONS_TEXT];
      break;
    case DATA_TYPES.NUMBER:
      fieldTypeOptions = _reject(fieldTypeOptions, (option) => {
        const value = _get(option, 'value', '');
        if (value === FIELD_TYPES.SELECT) return true;
        return false;
      });
      break;
    case DATA_TYPES.DATE:
      fieldTypeOptions = _reject(fieldTypeOptions, (option) => {
        const value = _get(option, 'value', '');
        if (value === FIELD_TYPES.SELECT) return true;
        return false;
      });
      break;
    case DATA_TYPES.BOOLEAN:
      fieldTypeOptions = _reject(fieldTypeOptions, (option) => {
        const value = _get(option, 'value', '');
        if (value === FIELD_TYPES.SELECT || value === FIELD_TYPES.RANGE) return true;
        return false;
      });
      break;
    default:
  }

  const tableData = _get(formValues, FIELD_IDS.LIST_INPUT_TABLE);

  return {
    ...FORM_FIELDS,
    [FIELD_IDS.NAME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.NAME], [
      { path: 'disabled', value: formMode === FORM_MODES.EDIT },
      { path: 'required', value: formMode === FORM_MODES.CREATE },
      { path: 'validators', value: formMode === FORM_MODES.EDIT ? EMPTY_ARRAY : [nameValidator] },
    ]),
    [FIELD_IDS.FIELD_TYPE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.FIELD_TYPE], [
      { path: 'options', value: fieldTypeOptions },
      { path: 'isDisabled', value: formMode === FORM_MODES.EDIT },
    ]),
    [FIELD_IDS.DATA_TYPE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.DATA_TYPE], [
      { path: 'isDisabled', value: disableDataTypeField || formMode === FORM_MODES.EDIT },
    ]),
    [FIELD_IDS.MULTI_VALUED_ENABLED]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.MULTI_VALUED_ENABLED], [
      { path: 'disabled', value: formMode === FORM_MODES.EDIT },
    ]),
    [FIELD_IDS.DEPENDENCY_CONFIG]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.DEPENDENCY_CONFIG], [
      { path: 'fieldDefinitions', value: _get(approvalSetting, APPROVAL_SETTING_FIELD_IDS.FIELDS, EMPTY_ARRAY) },
      { path: 'selectedFields', value: selectedFields },
    ]),
    [FIELD_IDS.LIST_INPUT_TABLE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.LIST_INPUT_TABLE], [
      { path: 'data', value: tableData },
      { path: 'formMode', value: formMode },
      { path: 'modalData', value: modalData },
      { path: 'isModalVisible', value: isModalVisible },
      {
        path: 'isControllingFunctionEnabled',
        value: _get(formValues, FIELD_IDS.CONTROLLING_FUNCTION, false),
      },
      {
        path: 'controllingOptions',
        value: getValuesOptions(approvalSetting, _head(_get(formValues, FIELD_IDS.CONTROLLING_FIELD_NAME))),
      },
    ]),
    [FIELD_IDS.CONTROLLING_FIELD_NAME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CONTROLLING_FIELD_NAME], [
      {
        path: 'options',
        value: getControllingFieldOptions(approvalSetting, formValues),
      },
    ]),
    [FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS], [
      {
        path: 'options',
        value: _get(formValues, FIELD_IDS.LIST_INPUT_TABLE),
      },
      {
        path: 'selectedOptions',
        value: selectedOptions,
      },
      {
        path: 'valuesOptions',
        value: getValuesOptions(approvalSetting, _head(_get(formValues, FIELD_IDS.CONTROLLING_FIELD_NAME))),
      },
      { path: 'isOptionDisabled', value: _isEmpty(_get(_get(formValues, FIELD_IDS.LIST_INPUT_TABLE), '[0].value')) },
      { path: 'isValueDisabled', value: _isEmpty(_get(formValues, FIELD_IDS.CONTROLLING_FIELD_NAME)) },
    ]),
  };
});

export { getFormFields };
