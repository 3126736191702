import { hasNumberRule, isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';

import CustomStylesConfigurator from '../customStylesConfigurator';

import { percentageValidationRule } from '../../../../utils/formValidators';

import { FIELD_IDS } from './cellViewColumnPropertiesForm.constants';

const WIDTH_FIELD = {
  id: FIELD_IDS.WIDTH,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Width(%)'),
    defaultValue: 100,
    min: 0,
    max: 100,
    validators: [isRequiredRule, percentageValidationRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const CUSTOM_STYLE_FIELD = {
  id: FIELD_IDS.CUSTOM_STYLES,
  renderer: CustomStylesConfigurator,
};

const FORM_FIELDS = {
  [FIELD_IDS.WIDTH]: WIDTH_FIELD,
  [FIELD_IDS.CUSTOM_STYLES]: CUSTOM_STYLE_FIELD,
};

const getFields = (entity, properties, componentType) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.CUSTOM_STYLES]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CUSTOM_STYLES], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
});

export { getFields };
