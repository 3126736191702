const ACTION_TYPES = {
  INIT: 'INIT',
  LAYOUT_CHANGE: 'LAYOUT_CHANGE',
};

const INITIAL_STATE = {
  isLoading: true,
};

export { ACTION_TYPES, INITIAL_STATE };
