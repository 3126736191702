import React, { useCallback, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';

import withSize from '../../../../../../../connectors/withSize';
import AssignModal from '../assignModal';

import { getWorkspaceUserList } from '../../../../../../../actions/workspaceUserManagement.actions';
import ACTION_HANDLERS from './helpers/usersSetting.actionHandlers';
import { createTableProps, getSubHeaderProps } from './helpers/usersSetting.helpers';

import { COLUMN_CONFIG } from './helpers/usersSetting.columns';
import { TABLE_MANAGER_PROPS } from './constants/usersSetting.general';
import { TABS } from '../../constants/permissionSetConfigure.constants';

const UsersSettings = ({
  contentHeight,
  isLoading,
  isAssignUserModalVisible,
  isAssignUserModalDataSubmitting,
  totalCount,
  userDropdownOptions,
  selectedUsersModalData,
  usersSettingData,
  userIds,
  onAction,
}) => {
  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({
        type: actionType,
        payload,
      });
    },
    [onAction],
  );

  const tableProps = useMemo(() => createTableProps(isLoading, handleRowAction), [isLoading, handleRowAction]);

  return (
    <Page>
      <Page.Body style={{ height: contentHeight - 110 }}>
        <TableManager
          subHeaderProps={getSubHeaderProps(totalCount)}
          tableManagerProps={TABLE_MANAGER_PROPS}
          columns={COLUMN_CONFIG}
          tableProps={tableProps}
          data={usersSettingData}
          onAction={onAction}
        />
        <AssignModal
          isAssignModalVisible={isAssignUserModalVisible}
          isMulti
          isAssignModalSubmitting={isAssignUserModalDataSubmitting}
          modalTitle={__('Assign User')}
          selectDropdownOptions={userDropdownOptions}
          selectedDropdownData={selectedUsersModalData}
          getOptionsApi={getWorkspaceUserList}
          tabType={TABS.USERS}
          existingData={userIds}
          onAction={onAction}
        />
      </Page.Body>
    </Page>
  );
};

UsersSettings.propTypes = {
  contentHeight: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isAssignUserModalVisible: PropTypes.bool,
  isAssignUserModalDataSubmitting: PropTypes.bool,
  totalCount: PropTypes.number,
  userIds: PropTypes.array,
  userDropdownOptions: PropTypes.array,
  selectedUsersModalData: PropTypes.array,
  usersSettingData: PropTypes.array,
  onAction: PropTypes.func,
};

UsersSettings.defaultProps = {
  isLoading: false,
  isAssignUserModalVisible: false,
  isAssignUserModalDataSubmitting: false,
  totalCount: 0,
  userIds: EMPTY_ARRAY,
  userDropdownOptions: EMPTY_ARRAY,
  selectedUsersModalData: EMPTY_ARRAY,
  usersSettingData: EMPTY_ARRAY,
  onAction: _noop,
};

export default compose(withSize({ hasPageHeader: true }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(UsersSettings);
