import _property from 'lodash/property';

const contentType = _property('content-type');
const contentDisposition = _property('content-disposition');

const RESPONSE_HEADER_READER = {
  contentType,
  contentDisposition,
};

export default RESPONSE_HEADER_READER;
