import ApiService from './apiService/apiService';

const BASE_URL = '/core/u/approval-mgmt';

const fetchAllApprovalTasks = (payload) => ApiService.post(`${BASE_URL}/approval-tasks/search`, payload);
const fetchApprovalTasksForApprovalId = (approvalId) => ApiService.get(`${BASE_URL}/approval-tasks/approval-id/${approvalId}`);
const approveTask = (taskId) => ApiService.put(`${BASE_URL}/approval-tasks/${taskId}/approve`);
const rejectTask = (taskId) => ApiService.put(`${BASE_URL}/approval-tasks/${taskId}/reject`);

const fetchMatchingApprovalProcess = (payload) => ApiService.post(`${BASE_URL}/approvals/matching-process/fetch`, payload);

const fetchAllApprovalRequests = (payload) => ApiService.post(`${BASE_URL}/approvals/search`, payload);
const createApprovalRequest = (payload) => ApiService.post(`${BASE_URL}/approvals`, payload);
const createApprovalRequestUsingProcess = (processName, payload) => ApiService.post(`${BASE_URL}/approvals/${processName}`, payload);
const updateApprovalRequest = (approvalId, payload) => ApiService.put(`${BASE_URL}/approvals/${approvalId}`, payload);
const updateApprovalRequestUsingProcess = (approvalId, processName, payload) =>
  ApiService.put(`${BASE_URL}/approvals/${approvalId}/processes/${processName}`, payload);
const getApprovalRequestById = (approvalId) => ApiService.get(`${BASE_URL}/approvals/${approvalId}`);
const cancelApprovalRequest = (approvalId) => ApiService.put(`${BASE_URL}/approvals/${approvalId}/cancel`);

const approvalCentreServices = {
  fetchAllApprovalTasks,
  fetchApprovalTasksForApprovalId,
  approveTask,
  rejectTask,
  fetchMatchingApprovalProcess,
  fetchAllApprovalRequests,
  createApprovalRequest,
  createApprovalRequestUsingProcess,
  updateApprovalRequest,
  updateApprovalRequestUsingProcess,
  getApprovalRequestById,
  cancelApprovalRequest,
};

export default approvalCentreServices;
