const ACTION_TYPES = {
  CREATE_WORKFLOW: 'CREATE_WORKFLOW',
  EDIT_WORKFLOW: 'EDIT_WORKFLOW',
  ACTIVE_WORKFLOW: 'ACTIVE_WORKFLOW',
  INACTIVE_WORKFLOW: 'INACTIVE_WORKFLOW',
  ON_CANCEL_CONFIRMATION_MODAL: 'ON_CANCEL_CONFIRMATION_MODAL',
  ON_SUBMIT_CONFIRMATION_MODAL: 'ON_SUBMIT_CONFIRMATION_MODAL',
};

export default ACTION_TYPES;
