import _map from 'lodash/map';
import _includes from 'lodash/includes';
import workspaceReader from '../../../../../../../readers/workspace.reader';

const workspaceOptions = (selectedWorkspaces, workspaceList) =>
  _map(workspaceList, (workspace) => ({
    label: workspaceReader.displayName(workspace) || workspaceReader.name(workspace),
    value: workspaceReader.id(workspace),
    disabled: _includes(selectedWorkspaces, workspaceReader.id(workspace)),
  }));

export { workspaceOptions };
