exports = module.exports = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .notificationCard_card__sUAbV4CgoQ{margin:1.2rem;padding:.4rem;cursor:pointer}[dir=ltr] .notificationCard_card__sUAbV4CgoQ{padding-left:1.2rem}[dir=rtl] .notificationCard_card__sUAbV4CgoQ{padding-right:1.2rem}.notificationCard_card__sUAbV4CgoQ:hover .notificationCard_clearIcon__f9ic4Zvb11{visibility:visible}[dir] .notificationCard_card__sUAbV4CgoQ:hover .notificationCard_cardContainer__anntcoCUFM{background-color:#ebf4ff}[dir] .notificationCard_card__sUAbV4CgoQ:hover .notificationCard_body__j9TWrsuQcQ{background-color:#ebf4ff}.notificationCard_subject__nsCuQF5cG5{font-family:\"Proxima-Nova-Semibold\";white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:70%}.notificationCard_header__htE7BqR4Dq{display:flex;justify-content:space-between;align-items:center}[dir] .notificationCard_header__htE7BqR4Dq{margin-bottom:1rem}.notificationCard_header__htE7BqR4Dq .notificationCard_clearIcon__f9ic4Zvb11{visibility:hidden}.notificationCard_header__htE7BqR4Dq .notificationCard_groupKey__6hLk67ALg1{font-family:\"Proxima-Nova-Semibold\";font-size:1.6rem}.notificationCard_subHeader__prpj7X8C25{display:flex;justify-content:space-between}", ""]);

// Exports
exports.locals = {
	"card": "notificationCard_card__sUAbV4CgoQ",
	"clearIcon": "notificationCard_clearIcon__f9ic4Zvb11",
	"cardContainer": "notificationCard_cardContainer__anntcoCUFM",
	"body": "notificationCard_body__j9TWrsuQcQ",
	"subject": "notificationCard_subject__nsCuQF5cG5",
	"header": "notificationCard_header__htE7BqR4Dq",
	"groupKey": "notificationCard_groupKey__6hLk67ALg1",
	"subHeader": "notificationCard_subHeader__prpj7X8C25"
};