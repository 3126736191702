import React from 'react';
import PropTypes from 'prop-types';

import _castArray from 'lodash/castArray';
import _noop from 'lodash/noop';

// Utils
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

// Constants
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

const SingletonValueFieldWrapper = (Field) => {
  const SingletonValueField = ({ value, onAction, ...restProps }) => {
    const handleAction = (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
        const { value: _value } = payload;
        onAction({
          type,
          payload: {
            ...payload,
            value: _castArray(_value),
          },
        });
      } else {
        onAction(action);
      }
    };

    return <Field {...restProps} value={getArraySafeValue(value)} onAction={handleAction} />;
  };

  SingletonValueField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onAction: PropTypes.func,
  };

  SingletonValueField.defaultProps = {
    value: undefined,
    onAction: _noop,
  };

  return SingletonValueField;
};

export default SingletonValueFieldWrapper;
