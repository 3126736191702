import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/cms';

const saveViewConfiguration = (entityName, payload) => ApiService.post(`${BASE_URL}/entities/${entityName}/view-definitions`, payload);

const updateViewConfiguration = (entityName, viewName, payload) =>
  ApiService.put(`${BASE_URL}/entities/${entityName}/view-definitions/name/${viewName}`, payload);

const searchViewConfigurations = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/view-definitions/search`, payload);

const fetchViewConfigurationByName = (entityName, viewName) => ApiService.get(`${BASE_URL}/entities/${entityName}/view-definitions/name/${viewName}`);

const searchViewConfigurationsByRecordType = (payload = EMPTY_OBJECT, params = EMPTY_OBJECT) =>
  ApiService.post(`${BASE_URL}/view-definitions/search/record-type/name`, payload, params);

export default {
  searchViewConfigurations,
  searchViewConfigurationsByRecordType,
  saveViewConfiguration,
  updateViewConfiguration,
  fetchViewConfigurationByName,
};
