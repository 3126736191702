const NOTIFICATION_DRAWER_WIDTH = 452;

const NOTIFICATION_DRAWER_TAB_IDS = {
  PRIMARY: 'PRIMARY',
  ACTIVITY: 'ACTIVITY',
  SYSTEM_ALERTS: 'SYSTEM_ALERTS',
  FLAGGED: 'FLAGGED',
};

const TAB_CONTENT_FILTER_TYPES = {
  BY_GROUP: 'BY_GROUP',
  MOST_RECENT: 'MOST_RECENT',
};

const TAB_CONTENT_FILTER_OPTIONS = [
  {
    label: __('By type'),
    value: TAB_CONTENT_FILTER_TYPES.BY_GROUP,
  },
  {
    label: __('Most recent'),
    value: TAB_CONTENT_FILTER_TYPES.MOST_RECENT,
  },
];

export { NOTIFICATION_DRAWER_WIDTH, NOTIFICATION_DRAWER_TAB_IDS, TAB_CONTENT_FILTER_TYPES, TAB_CONTENT_FILTER_OPTIONS };
