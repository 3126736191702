import _map from 'lodash/map';
import _get from 'lodash/get';

const getGenericOptions = (listOfItems) =>
  _map(listOfItems, (item) => ({
    label: item,
    value: item,
  }));

const getGridUpdateRequestBody = (params) =>
  _map(params, (widget) => ({
    widgetName: widget.i,
    row: widget.x,
    column: widget.y,
    width: widget.w,
    height: widget.h,
    staticWidget: widget.static,
  }));

const transformResponseBodyToGridLayout = (params) =>
  _map(params, (widget) => ({
    i: _get(widget, 'widgetName'),
    x: _get(widget, 'row'),
    y: _get(widget, 'column'),
    w: _get(widget, 'width', 3),
    h: _get(widget, 'height', 3),
    static: _get(widget, 'staticWidget', 3),
  }));

export { getGenericOptions, getGridUpdateRequestBody, transformResponseBodyToGridLayout };
