import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _noop from 'lodash/noop';

// Tekion-base
import { EMPTY_ARRAY, NO_DATA } from '@tekion/tekion-base/app.constants';

// Tekion-components
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

// Styles
import styles from './navigationItem.module.scss';

const NavigationItem = (props) => {
  const { itemNumber, totalItems, data, onNavigationItemClick } = props;

  const handleItemClick = useCallback(() => {
    onNavigationItemClick(_get(data, 'goTo', ''));
  }, [data, onNavigationItemClick]);

  return (
    <div className={styles.flowItem}>
      <div onClick={handleItemClick} className={itemNumber !== totalItems - 1 ? styles.label : `${styles.label} ${styles.lastNav}`}>
        {_get(data, 'label', NO_DATA)}
      </div>
      {itemNumber !== totalItems - 1 && <FontIcon className={styles.icon}>icon-right-arrow-thick</FontIcon>}
    </div>
  );
};

NavigationItem.propTypes = {
  itemNumber: PropTypes.number,
  totalItems: PropTypes.number,
  data: PropTypes.array,
  onNavigationItemClick: PropTypes.func,
};

NavigationItem.defaultProps = {
  itemNumber: 0,
  totalItems: 0,
  data: EMPTY_ARRAY,
  onNavigationItemClick: _noop,
};

export default NavigationItem;
