import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { TEMPLATE_TYPES } from '../../../../../../constants/templateBuilder.constants';
import { TOOLBAR_GROUPS as TOOLBAR_GROUPS_KEYS } from '../../../../../../organisms/richTextEditor/constants/richTextEditor.plugins';

const INITIAL_STATES_TEMPLATE_FORM_PROPS = {
  values: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

const TEMPLATE_TYPE_OPTIONS = [
  {
    label: __('Email'),
    value: TEMPLATE_TYPES.EMAIL,
  },
  {
    label: __('Text'),
    value: TEMPLATE_TYPES.TEXT,
  },
  {
    label: __('Push'),
    value: TEMPLATE_TYPES.PUSHER,
  },
];

const FREEMARKER = 'FREEMARKER';
const DRAFT = 'DRAFT';

const TEMPLATE_FORM_CONTEXT_ID = 'TEMPLATE_MANAGEMENT_FORM';

const TOOLBAR_GROUPS = {
  FONT_SIZE: TOOLBAR_GROUPS_KEYS.FONT_SIZE,
  LIST: TOOLBAR_GROUPS_KEYS.LIST,
  MARKS: TOOLBAR_GROUPS_KEYS.MARKS,
  FONT_COLOR: TOOLBAR_GROUPS_KEYS.FONT_COLOR,
  ALIGNMENT: TOOLBAR_GROUPS_KEYS.ALIGNMENT,
  INSERTION: TOOLBAR_GROUPS_KEYS.INSERTION,
  RESET: TOOLBAR_GROUPS_KEYS.RESET,
  SHORTCUTS: TOOLBAR_GROUPS_KEYS.SHORTCUTS,
};

// eslint-disable-next-line no-template-curly-in-string
const HELP_TEXT = __('Add template variable inside ${}. ex: ${var}');

export { TEMPLATE_FORM_CONTEXT_ID, INITIAL_STATES_TEMPLATE_FORM_PROPS, TEMPLATE_TYPE_OPTIONS, FREEMARKER, DRAFT, TOOLBAR_GROUPS, HELP_TEXT };
