import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_STRING } from 'tbase/app.constants';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import withAsyncSelect from 'tcomponents/molecules/advancedSelect/containers/withAsyncSelect';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchEntities, fetchEntityDefByName } from '../../../../actions/entityManagement.actions';
import { getDefaultFilters, getHasMore, getPayload, getTargetEntityOptions, entityOption } from './entityAsyncSelect.helper';
import { FORM_MODES } from '../../../../constants/general.constants';

const AsyncSelect = withAsyncSelect(Select);

const EntityAsyncSelect = ({ value, filters, formMode, ...restProps }) => {
  const [nextPageToken, setNextPageToken] = useState(EMPTY_STRING);
  const [searchText, setSearchText] = useState(EMPTY_STRING);
  const ref = useRef(null);

  const handleLoadOptions = useCallback(
    async (inputString = EMPTY_STRING) => {
      let payload;
      if (inputString !== searchText) {
        payload = getPayload(EMPTY_STRING, inputString, filters);
        setNextPageToken(EMPTY_STRING);
        setSearchText(inputString);
      } else {
        payload = getPayload(nextPageToken, inputString, filters);
      }
      if (formMode === FORM_MODES.EDIT) {
        const newFilters = _get(payload, 'filters', EMPTY_ARRAY);
        newFilters.push({
          field: 'name',
          values: [getArraySafeValue(value)],
          filterType: OPERATORS.NIN,
        });
        payload = { ...payload, filters: newFilters };
      }

      const data = await fetchEntities(payload);
      const hits = tget(data, 'hits', EMPTY_ARRAY);
      const currentNextPageToken = _get(data, 'nextPageToken');
      let newOptions = getTargetEntityOptions(hits);
      if (formMode === FORM_MODES.EDIT && _isEmpty(nextPageToken)) {
        const entityDef = await fetchEntityDefByName(value);
        const displayName = _get(entityDef, 'displayName');
        const entityValue = getArraySafeValue(value);
        newOptions = [...newOptions, entityOption(entityValue, displayName)];
      }
      const hasMore = getHasMore(currentNextPageToken);
      setNextPageToken(currentNextPageToken);
      return { options: newOptions, hasMore };
    },
    [filters, formMode, nextPageToken, searchText, value],
  );

  useEffect(() => {
    ref.current.resetLoadedOptions();
  }, [filters]);

  return (
    <AsyncSelect
      {...restProps}
      ref={ref}
      refreshOptions
      openMenuOnFocus
      loadOptions={handleLoadOptions}
      value={value}
      loadingMessage={__('Searching...')}
      newSelect
    />
  );
};
EntityAsyncSelect.propTypes = {
  value: PropTypes.string.isRequired,
  formMode: PropTypes.string,
  filters: PropTypes.array,
};

EntityAsyncSelect.defaultProps = {
  formMode: FORM_MODES.CREATE,
  filters: getDefaultFilters(),
};

export default EntityAsyncSelect;
