import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';
import { getOptionsForOperator, getComponentForFilterHelper } from '../helpers/filterForm.config';
import { getFilterFieldOption } from '../helpers/filterForm.utils';

import { FIELD_IDS, FIELDS } from '../constants/filterForm.constants';
import ACTION_TYPES from '../constants/filterForm.actionTypes';

import filterReader from '../../../../../readers/filter.reader';

import styles from '../filterForm.module.scss';

const FilterRow = ({ index, fieldsConfig, value, error, filterableFieldTypeOptions, onAction }) => {
  const fieldName = _get(value, [FIELD_IDS.FIELD], '');
  const operatorType = _get(value, [FIELD_IDS.FILTER_TYPE], '');

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { id: fieldId, value: selectValue } = payload;
          onAction({
            type,
            payload: {
              id: fieldId,
              value: selectValue,
              index,
            },
          });

          break;
        }
        case ACTION_TYPES.DELETE_ROW: {
          onAction({
            type,
            payload: { index },
          });
          break;
        }
        default:
          onAction(action);
      }
    },
    [index, onAction],
  );

  const handleDeleteClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.DELETE_ROW,
      payload: { index },
    });
  }, [onAction, index]);

  const handleInputComponentChange = useCallback(
    (params) => {
      onAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: { id: FIELD_IDS.VALUES, value: params, index },
      });
    },
    [index, onAction],
  );

  const fieldTypeSelectOptions = useMemo(
    () =>
      _map(filterableFieldTypeOptions, (option) => ({
        ...option,
        disabled: _get(fieldsConfig, [filterReader.value(option), FIELD_IDS.DISABLED], false),
      })),
    [fieldsConfig, filterableFieldTypeOptions],
  );

  const operatorOptions = useMemo(() => getOptionsForOperator(filterableFieldTypeOptions, fieldName), [filterableFieldTypeOptions, fieldName]);

  const renderInputComponent = useCallback(() => {
    if (!_isEmpty(operatorType)) {
      const inputComponent = getComponentForFilterHelper(filterableFieldTypeOptions, fieldName, operatorType);
      const filterFieldOption = getFilterFieldOption(filterableFieldTypeOptions, fieldName);
      const fieldType = _get(filterFieldOption, 'type');
      const { component: InputComponent, baseProps } = inputComponent;

      return (
        <div>
          <span className={styles.valueFieldTitle}>{FIELDS.VALUE}</span>
          <InputComponent
            {...baseProps}
            id={FIELD_IDS.VALUES}
            onChange={handleInputComponentChange}
            values={value?.[FIELD_IDS.VALUES]}
            value={value?.[FIELD_IDS.VALUES]}
            error={error?.[FIELD_IDS.VALUES]}
            disabled={_isEmpty(operatorType)}
            required
            className={fieldType === FILTER_TYPES.MULTI_SELECT ? styles.selectValueField : styles.valueField}
          />
          <span className={styles.errorMessage}>{error?.[FIELD_IDS.VALUES]}</span>
        </div>
      );
    } else {
      return (
        <Select
          id={FIELD_IDS.VALUES}
          label={FIELDS.VALUE}
          value={value?.[FIELD_IDS.VALUES]}
          error={error?.[FIELD_IDS.VALUES]}
          disabled={_isEmpty(operatorType)}
          options={EMPTY_ARRAY}
          onAction={handleAction}
          className={styles.inputSelect}
          required
        />
      );
    }
  }, [error, fieldName, filterableFieldTypeOptions, handleAction, handleInputComponentChange, operatorType, value]);

  return (
    <div className={styles.selectSection}>
      <div>
        <Select
          id={FIELD_IDS.FIELD}
          label={FIELDS.FIELD}
          value={value?.[FIELD_IDS.FIELD]}
          error={error?.[FIELD_IDS.FIELD]}
          options={fieldTypeSelectOptions}
          onAction={handleAction}
          required
          className={styles.selectInput}
        />
      </div>
      <div>
        <Select
          id={FIELD_IDS.FILTER_TYPE}
          label={FIELDS.OPERATOR}
          value={value?.[FIELD_IDS.FILTER_TYPE]}
          error={error?.[FIELD_IDS.FILTER_TYPE]}
          disabled={_isEmpty(fieldName)}
          options={operatorOptions}
          onAction={handleAction}
          className={styles.selectInput}
          required
        />
      </div>
      {renderInputComponent()}
      <FontIcon className={styles.deleteIcon} onClick={handleDeleteClick}>
        icon-trashcan
      </FontIcon>
    </div>
  );
};

FilterRow.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.object,
  error: PropTypes.object,
  fieldsConfig: PropTypes.object,
  filterableFieldTypeOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

FilterRow.defaultProps = {
  error: EMPTY_OBJECT,
  value: EMPTY_OBJECT,
  fieldsConfig: EMPTY_OBJECT,
  filterableFieldTypeOptions: EMPTY_ARRAY,
};

export default FilterRow;
