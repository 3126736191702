import _map from 'lodash/map';
import roleReader from '../../../../../../../readers/role.reader';

const getOptionsForRole = (roleList) =>
  _map(roleList, (role) => ({
    label: roleReader.displayName(role),
    value: roleReader.name(role),
  }));

export { getOptionsForRole };
