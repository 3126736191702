import { defaultMemoize } from 'reselect';

export const getFieldTags = defaultMemoize((fields) => {
  const totalFields = fields.length;

  if (totalFields > 2) {
    let remainingFields = totalFields - 2;
    const newFields = fields.slice(0, 2);
    remainingFields = `+ ${remainingFields} more`;

    return {
      newFields,
      remainingFields,
    };
  }
  return { newFields: fields, remainingFields: undefined };
});
