import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _map from 'lodash/map';

import Menu from '@tekion/tekion-components/molecules/Menu';
import LoadingSpinner from '@tekion/tekion-components/molecules/loadingSpinner';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import UserAvatar from '../../../../atoms/userAvatar/UserAvatar';

import styles from '../../asyncMentions.module.scss';
import { NO_SUGGESTION_FOUND } from '../../asyncMentions.constants';

const SuggestionsListRenderer = ({ areMentionsLoading, suggestions }) => (
  <Menu className={styles.menuPopup}>
    {_map(suggestions, ({ suggestion: { id, display, profilePicture }, ...props }) => (
      <Menu.Item
        disabled={id === NO_SUGGESTION_FOUND}
        key={id}
        className={cx(styles.menuItem, {
          [styles.disabled]: id === NO_SUGGESTION_FOUND,
        })}
      >
        <div className={styles.menuItemContent} {...props}>
          <UserAvatar userAvatar={profilePicture} displayName={display} userAvatarContainerClassName={styles.userAvatar} avatarSize="small" />
          <div className={styles.menuItemText}>{__(display)}</div>
        </div>
      </Menu.Item>
    ))}

    <PropertyControlledComponent controllerProperty={areMentionsLoading}>
      <div className={styles.fallbackLoader}>
        <LoadingSpinner size={20} className={styles.loadingSpinner} />
      </div>
    </PropertyControlledComponent>
  </Menu>
);

SuggestionsListRenderer.propTypes = {
  areMentionsLoading: PropTypes.bool,
  suggestions: PropTypes.array,
};

SuggestionsListRenderer.defaultProps = {
  areMentionsLoading: false,
  suggestions: EMPTY_ARRAY,
};

export default SuggestionsListRenderer;
