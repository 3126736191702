import _head from 'lodash/head';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _last from 'lodash/last';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { fetchActions, fetchApplicableActionsForRecords } from '../../../../../../../actions/actionBuilder.actions';

import { getPayloadForActions } from './useActionBuilderActions.general';

import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../../constants/actionBuilder.constants';

const fetchActionsData = async (entityName, actionNames) => {
  const searchRequest = getPayloadForActions(entityName, actionNames);

  const response = await fetchActions(searchRequest);

  return tget(response, 'hits', EMPTY_ARRAY);
};

const fetchValidateActions = async (entityName, recordId) => {
  const response = await fetchApplicableActionsForRecords(entityName, { recordIds: [recordId] });

  return tget(response, recordId, EMPTY_ARRAY);
};

const fetchOnOpenData = async ({ entityName, actionBuilderProps, recordId }) => {
  const isDataFetchRequired = actionBuilderProps.getDataFetchRequired();
  const promises = [];

  if (isDataFetchRequired) {
    const actionNamesToShow = actionBuilderProps.getActionNamesToShow();
    promises.push(fetchActionsData(entityName, actionNamesToShow));
  }

  promises.push(fetchValidateActions(entityName, recordId));

  const responses = await Promise.all(promises);

  const actionDefs = isDataFetchRequired ? _head(responses) : actionBuilderProps.getActionDefs();

  const validActionDefs = _last(responses);
  const validActionNames = _map(validActionDefs, (actionDef) => _get(actionDef, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME));

  return { actionDefs, validActionNames };
};

export { fetchOnOpenData };
