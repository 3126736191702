// Renderers
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
// Constants
import COLUMN_IDS from './entityList.columnIds';
import ACTION_TYPES from './entityList.actionTypes';
import { FIELD_STATUS_COLOR_MAP, FIELD_STATUS_LABEL_MAP } from './entityList.general';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const getStatusProps = () => ({
  colorMap: FIELD_STATUS_COLOR_MAP,
  labelMap: FIELD_STATUS_LABEL_MAP,
});

const COLUMN_CONFIG = [
  {
    Header: __('Display name'),
    accessor: COLUMN_IDS.DISPLAY_NAME,
    id: COLUMN_IDS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Name'),
    accessor: 'name',
    id: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Description'),
    accessor: COLUMN_IDS.DESCRIPTION,
    id: COLUMN_IDS.DESCRIPTION,
    Cell: TextRenderer,
  },
  {
    Header: __('Search enabled'),
    accessor: 'searchEnabled',
    id: COLUMN_IDS.SEARCH_ENABLED,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('List view enabled'),
    accessor: 'listViewEnabled',
    id: COLUMN_IDS.LIST_VIEW_ENABLED,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Create enabled'),
    accessor: 'createViewEnabled',
    id: COLUMN_IDS.CREATE_ENABLED,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Edit enabled'),
    accessor: 'editEnabled',
    id: COLUMN_IDS.EDIT_ENABLED,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Detail view enabled'),
    accessor: 'detailViewEnabled',
    id: COLUMN_IDS.DETAIL_VIEW_ENABLED,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('User api enabled'),
    accessor: 'userApiEnabled',
    id: COLUMN_IDS.USER_API_ENABLED,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
];

const EDIT_ENTITY_DETAIL = {
  id: ACTION_TYPES.EDIT_ENTITY_DETAIL,
  name: __('Edit Entity'),
};

const ROW_ACTIONS = [EDIT_ENTITY_DETAIL];

export { COLUMN_CONFIG, ROW_ACTIONS };
