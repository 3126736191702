import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import _set from 'lodash/set';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FormEvent from '@tekion/tekion-base/utils/formEvent';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Button from '@tekion/tekion-components/atoms/Button';
import NullComponent from '@tekion/tekion-components/atoms/nullComponent';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import Loader from '@tekion/tekion-components/molecules/loader';
import withActions from '@tekion/tekion-components/connectors/withActions';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';

import ACTION_HANDLERS from './actionExecutionModal.actionHandlers';
import { getAdditionalPropForRenderedComponent, getComponentRendererFromModalType, getModalPropsFromModalType } from './actionExecutionModal.helpers';

import { ACTION_TYPES, ACTION_EXECUTION_MODAL_TYPES, ACTION_EXECUTION_MODAL_EVENT_TYPES } from './actionExecutionModal.constants';

import styles from './actionExecutionModal.module.scss';

const ActionExecutionModal = ({
  isModalVisible,
  isDataLoading,
  isModalDataSubmitting,
  actionModalContextId,
  contextId,
  modalType,
  propsForRenderedComponent,
  onAction,
}) => {
  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CLOSE_MODAL });
  }, [onAction]);

  const ModalContentRenderer = useMemo(() => getComponentRendererFromModalType(modalType), [modalType]);

  const modalProps = useMemo(() => {
    const additionalData = {};

    if (modalType === ACTION_EXECUTION_MODAL_TYPES.USER_INPUT) {
      _set(additionalData, 'contextId', contextId);
    } else if (modalType === ACTION_EXECUTION_MODAL_TYPES.BULK_EMAIL_USER_INPUT) {
      _set(additionalData, 'onAction', onAction);
    }

    return getModalPropsFromModalType(modalType, additionalData);
  }, [modalType, contextId, onAction]);

  const additionalPropsForRenderedComponent = useMemo(() => getAdditionalPropForRenderedComponent(modalType, onAction), [modalType, onAction]);

  const renderFooterRightSection = useCallback(() => {
    const handleFooterRightSectionClick = () => {
      onAction({ type: ACTION_TYPES.ON_MODAL_FOOTER_RIGHT_SECTION_CLICK, payload: { modalType } });
    };

    switch (modalType) {
      case ACTION_EXECUTION_MODAL_TYPES.BULK_EMAIL_USER_INPUT: {
        return <Button onClick={handleFooterRightSectionClick}>{__('Email Preview')}</Button>;
      }

      default: {
        return NullComponent;
      }
    }
  }, [modalType, onAction]);

  useEffect(() => {
    const handleModalInit = (payload) => {
      onAction({ type: ACTION_TYPES.ON_MODAL_INIT, payload });
    };

    FormEvent.on(`${ACTION_EXECUTION_MODAL_EVENT_TYPES.ON_MODAL_INIT}:${actionModalContextId}`, handleModalInit);

    return () => {
      FormEvent.removeListener(`${ACTION_EXECUTION_MODAL_EVENT_TYPES.ON_MODAL_INIT}:${actionModalContextId}`);
    };
  }, [actionModalContextId, onAction]);

  return (
    <Modal
      destroyOnClose
      visible={isModalVisible}
      loading={isModalDataSubmitting}
      width={SIZES.XL}
      onCancel={handleCancel}
      renderFooterRightSection={renderFooterRightSection}
      {...modalProps}
    >
      <PropertyControlledComponent controllerProperty={!isDataLoading} fallback={<Loader />}>
        <div className={styles.actionExecutionModal}>
          <ModalContentRenderer {...propsForRenderedComponent} {...additionalPropsForRenderedComponent} />
        </div>
      </PropertyControlledComponent>
    </Modal>
  );
};

ActionExecutionModal.propTypes = {
  isModalVisible: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  isModalDataSubmitting: PropTypes.bool,
  contextId: PropTypes.string,
  actionModalContextId: PropTypes.string.isRequired,
  modalType: PropTypes.oneOf([ACTION_EXECUTION_MODAL_TYPES.USER_INPUT, ACTION_EXECUTION_MODAL_TYPES.BULK_EMAIL_USER_INPUT]),
  propsForRenderedComponent: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ActionExecutionModal.defaultProps = {
  isModalVisible: false,
  isDataLoading: false,
  isModalDataSubmitting: false,
  contextId: EMPTY_STRING,
  modalType: ACTION_EXECUTION_MODAL_TYPES.USER_INPUT,
  propsForRenderedComponent: EMPTY_OBJECT,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(ActionExecutionModal);
