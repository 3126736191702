const DURATION_TYPES = {
  FOREVER: 'FOREVER',
  DATE_AND_TIME: 'DATE_AND_TIME',
};
const DURATION_OPTIONS = [
  {
    label: 'Forever',
    value: DURATION_TYPES.FOREVER,
  },
  {
    label: 'Date & Time',
    value: DURATION_TYPES.DATE_AND_TIME,
  },
];

const DATE_PICKER_ID = 'DATE_PICKER_ID';
const DURATION_TYPE_ID = 'DURATION_TYPE_ID';

export { DURATION_OPTIONS, DATE_PICKER_ID, DURATION_TYPE_ID, DURATION_TYPES };
