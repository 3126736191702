import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _keysIn from 'lodash/keysIn';
import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import _valuesIn from 'lodash/valuesIn';

import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import {
  ALL_VIEW_PROPERTY_KEYS,
  CELL_TYPES,
  RENDERER_PROP_KEYS,
  VIEW_CONFIGURATION_FIELD_IDS,
  VIEW_CONFIGURATION_GENERAL_KEYS,
} from '../constants/viewBuilder.constants';

const RENDERER_PROP_KEYS_ARRAY = _valuesIn(RENDERER_PROP_KEYS);

const extractAllCellViewNamesFromViewConfiguration = (section, cellViewNames) => {
  const { properties, children } = section;
  const cellType = tget(properties, ALL_VIEW_PROPERTY_KEYS.CELL_TYPE);

  if (cellType === CELL_TYPES.COMPOUND) {
    const cellViewName = tget(properties, ALL_VIEW_PROPERTY_KEYS.CELL_VIEW_NAME);

    if (cellViewName) {
      cellViewNames.push(cellViewName);
    }
  }

  _forEach(children, (childSection) => extractAllCellViewNamesFromViewConfiguration(childSection, cellViewNames));
};

const getPayloadForViews = (viewNames) => {
  let filters = [];
  if (!_isEmpty(viewNames)) {
    filters = [
      {
        field: VIEW_CONFIGURATION_FIELD_IDS.NAME,
        filterType: OPERATORS.IN,
        values: _castArray(viewNames),
      },
    ];
  }
  return {
    filters,
  };
};

// This flatten properties function is meant to use inside this file only
const getFlattenProperties = (properties) => {
  const rendererProps = _reduce(
    tget(properties, VIEW_CONFIGURATION_GENERAL_KEYS.RENDERER_PROPS, EMPTY_ARRAY),
    (result, rendererProp) => {
      const type = tget(rendererProp, 'type');
      const value = tget(rendererProp, 'value');
      if (!_isEmpty(type)) {
        return { ...result, [type]: value };
      }
      return result;
    },
    {},
  );

  return {
    ..._omit(properties, VIEW_CONFIGURATION_GENERAL_KEYS.RENDERER_PROPS),
    ...rendererProps,
  };
};

const combinePropertiesAndRendererPropsFromViewConfig = (viewRecursiveObject, viewName) => {
  const properties = tget(viewRecursiveObject, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);
  const flattenedProperties = getFlattenProperties(properties);
  _set(flattenedProperties, ALL_VIEW_PROPERTY_KEYS.VIEW_NAME, viewName);
  _set(viewRecursiveObject, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, flattenedProperties);

  const children = tget(viewRecursiveObject, VIEW_CONFIGURATION_GENERAL_KEYS.CHILDREN, EMPTY_ARRAY);
  _forEach(children, (child) => {
    combinePropertiesAndRendererPropsFromViewConfig(child, viewName);
  });
};

// Ideally only actions should call this function
// Assuming actions is calling this function avoiding use of cloneDeep
const convertApiResponseToViewConfig = (viewConfig) => {
  const section = tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.SECTION, EMPTY_ARRAY);
  const viewConfigProperties = tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.PROPERTIES, EMPTY_OBJECT);
  const viewName = tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME);
  combinePropertiesAndRendererPropsFromViewConfig(section, viewName);
  const updatedViewConfigProperties = getFlattenProperties(viewConfigProperties);

  _set(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.SECTION, section);
  _set(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.PROPERTIES, updatedViewConfigProperties);

  return viewConfig;
};

// Ideally only actions should call this function
// And hence avoiding use of cloneDeep
const bulkViewApiResponseToViewConfigResolver = (viewConfigs) => _map(viewConfigs, (viewConfig) => convertApiResponseToViewConfig(viewConfig));

const makeRendererPropsWithProperties = (properties) => {
  const rendererPropsInProperties = _pick(properties, RENDERER_PROP_KEYS_ARRAY);
  const rendererProps = _reduce(
    rendererPropsInProperties,
    (result, value, key) => [
      ...result,
      {
        type: key,
        value,
      },
    ],
    [],
  );

  return {
    ..._omit(properties, [..._keysIn(rendererPropsInProperties), ALL_VIEW_PROPERTY_KEYS.VIEW_ID, ALL_VIEW_PROPERTY_KEYS.VIEW_NAME]),
    rendererProps,
  };
};

const insertRendererPropsInViewConfig = (viewRecursiveObject) => {
  const properties = tget(viewRecursiveObject, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);
  const propertiesWithRendererProps = makeRendererPropsWithProperties(properties);
  _set(viewRecursiveObject, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, propertiesWithRendererProps);

  const children = tget(viewRecursiveObject, VIEW_CONFIGURATION_GENERAL_KEYS.CHILDREN);
  _forEach(children, (child) => {
    insertRendererPropsInViewConfig(child);
  });
};

// This view config comes from component hence first doing cloneDeep
const convertViewConfigToApiViewConfig = (viewConfig) => {
  const viewConfigToConvert = _cloneDeep(viewConfig);

  const section = tget(viewConfigToConvert, VIEW_CONFIGURATION_FIELD_IDS.SECTION, EMPTY_ARRAY);
  const viewConfigProperties = tget(viewConfigToConvert, VIEW_CONFIGURATION_FIELD_IDS.PROPERTIES, EMPTY_OBJECT);
  insertRendererPropsInViewConfig(section);
  const updatedViewConfigProperties = makeRendererPropsWithProperties(viewConfigProperties);

  _set(viewConfigToConvert, VIEW_CONFIGURATION_FIELD_IDS.SECTION, section);
  _set(viewConfigToConvert, VIEW_CONFIGURATION_FIELD_IDS.PROPERTIES, updatedViewConfigProperties);

  return viewConfigToConvert;
};

export {
  extractAllCellViewNamesFromViewConfiguration,
  getPayloadForViews,
  convertApiResponseToViewConfig,
  convertViewConfigToApiViewConfig,
  bulkViewApiResponseToViewConfigResolver,
};
