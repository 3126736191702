import { NO_DATA } from '@tekion/tekion-base/app.constants';

const DEFAULT_SHORTCUTS_INFO = [
  {
    preview: 'Heading 1',
    markdown: '# Heading 1',
    shortcut: 'cmd+opt+1',
  },
  {
    preview: 'Heading 2',
    markdown: '## Heading 2',
    shortcut: 'cmd+opt+2',
  },
  {
    preview: 'Heading 3',
    markdown: '### Heading 3',
    shortcut: 'cmd+opt+3',
  },

  {
    preview: 'Highlight',
    markdown: '> Highlight',
    shortcut: 'cmd+shift+.',
  },
  {
    preview: __('Paragraph'),
    markdown: NO_DATA,
    shortcut: 'cmd+opt+0',
  },
  {
    preview: __('Inserts empty line below'),
    markdown: NO_DATA,
    shortcut: 'cmd+enter',
  },
  {
    preview: __('Inserts empty line above'),
    markdown: NO_DATA,
    shortcut: 'cmd+shift+enter',
  },
];

export { DEFAULT_SHORTCUTS_INFO };
