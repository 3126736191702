import ApiService from './apiService/apiService';

const BASE_URL = '/core/u/approval-mgmt';

const fetchGroups = (payload) => ApiService.post(`${BASE_URL}/groups/search`, payload);
const createGroup = (payload) => ApiService.post(`${BASE_URL}/groups`, payload);

const fetchCategories = (payload) => ApiService.post(`${BASE_URL}/categories/search`, payload);
const createCategory = (payload) => ApiService.post(`${BASE_URL}/categories`, payload);

const fetchSettings = (payload) => ApiService.post(`${BASE_URL}/approval-settings/search`, payload);
const getSettingByName = (settingName) => ApiService.get(`${BASE_URL}/approval-settings/${settingName}`);
const createSetting = (payload) => ApiService.post(`${BASE_URL}/approval-settings`, payload);
const addFieldToSetting = (settingName, payload) => ApiService.post(`${BASE_URL}/approval-settings/${settingName}/fields`, payload);
const updateFieldOfSetting = (settingName, fieldName, payload) =>
  ApiService.put(`${BASE_URL}/approval-settings/${settingName}/fields/${fieldName}`, payload);

const fetchProcesses = (payload) => ApiService.post(`${BASE_URL}/approval-process/search`, payload);
const getProcessByName = (processName) => ApiService.get(`${BASE_URL}/approval-process/${processName}`);
const createProcess = (payload) => ApiService.post(`${BASE_URL}/approval-process`, payload);
const updateProcess = (processName, payload) => ApiService.put(`${BASE_URL}/approval-process/${processName}`, payload);
const enableProcess = (processName, payload) => ApiService.put(`${BASE_URL}/approval-process/${processName}/enable`, payload);
const disableProcess = (processName, payload) => ApiService.put(`${BASE_URL}/approval-process/${processName}/disable`, payload);

const approvalManagementServices = {
  GROUP: {
    fetchGroups,
    createGroup,
  },
  CATEGORY: {
    fetchCategories,
    createCategory,
  },
  SETTINGS: {
    fetchSettings,
    getSettingByName,
    createSetting,
    addFieldToSetting,
    updateFieldOfSetting,
  },
  PROCESS: {
    fetchProcesses,
    getProcessByName,
    createProcess,
    updateProcess,
    enableProcess,
    disableProcess,
  },
};

export default approvalManagementServices;
