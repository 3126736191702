// TODO: Make field pages use this common constant
const DATA_TYPES = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  DATE_TIME: 'DATE_TIME',
  BOOLEAN: 'BOOLEAN',
  COMPLEX: 'COMPLEX',
  ADDRESS: 'ADDRESS',
  TIME: 'TIME',
};

export default DATA_TYPES;
