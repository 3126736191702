import { tget } from '@tekion/tekion-base/utils/general';

import { getCommentCount } from '../comments.actions';

const Comments = {
  fetchCommentsCount: async (assetType, assetId) => {
    const response = await getCommentCount(assetType, assetId);

    return tget(response, 'count', 0);
  },
};

export default Comments;
