import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _size from 'lodash/size';

import Heading from '@tekion/tekion-components/atoms/Heading';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Button from '@tekion/tekion-components/atoms/Button';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import SelectedEntityModal from './components/selectedEntityModal/SelectedEntityModal';
import ACTION_TYPES from '../../constants/tabProperties.actionTypes';

import styles from './selectedEntityConfigurator.module.scss';

const SelectEntityConfigurator = ({ isModalVisible, selectedModuleIndex, entityErrorObject, selectedEntities, configureEntityValues, onAction }) => {
  const handleSubmit = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_SAVE_MODAL,
    });
  }, [onAction]);

  const handleCancelModal = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CANCEL_MODAL,
    });
  }, [onAction]);

  const handleConfigureClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CONFIGURE_CLICK,
    });
  }, [onAction]);

  return (
    <div className={styles.selectedEntityHandlerModal}>
      <div className={styles.selectedEntityComponent}>
        <Heading size={5} className={styles.selectedEntityComponentHeading}>
          {`${__('Selected Entities ')}(${_size(selectedEntities)})`}
        </Heading>
        <Button disable view={Button.VIEW.TERTIARY} className={styles.configureButton} onClick={handleConfigureClick}>
          {__('Configure')}
        </Button>
      </div>
      <Modal
        visible={isModalVisible}
        width={SIZES.L}
        title={__('Configure entities')}
        submitBtnText={__('Submit')}
        onSubmit={handleSubmit}
        onCancel={handleCancelModal}
      >
        <SelectedEntityModal
          value={configureEntityValues}
          selectedModuleIndex={selectedModuleIndex}
          selectedEntities={selectedEntities}
          error={entityErrorObject}
          onAction={onAction}
        />
      </Modal>
    </div>
  );
};

SelectEntityConfigurator.propTypes = {
  isModalVisible: PropTypes.bool,
  selectedModuleIndex: PropTypes.number,
  entityErrorObject: PropTypes.object,
  configureEntityValues: PropTypes.array,
  selectedEntities: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

SelectEntityConfigurator.defaultProps = {
  isModalVisible: false,
  selectedModuleIndex: 0,
  entityErrorObject: EMPTY_OBJECT,
  configureEntityValues: EMPTY_ARRAY,
  selectedEntities: EMPTY_ARRAY,
};

export default SelectEntityConfigurator;
