const COLUMN_IDS = {
  ENTITY_NAME: 'scope',
  FIELD_NAME: 'fieldName',
  READ: 'READ',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
};

const PERMISSION_TYPE = {
  ENTITY: 'Entity',
  FIELD: 'Field',
};

export { COLUMN_IDS, PERMISSION_TYPE };
