import _isEmpty from 'lodash/isEmpty';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { ADD_WIDGET_FORM_FIELDS } from '../constants/addWidget.general.constant';
import { DIMENSION_FIELD, MEASUREMENT_FIELD } from '../constants/addTableWidget.fields';
import { WIDGET_NAME_FIELD, DISPLAY_NAME_FIELD, ENTITY_NAME_FIELD } from '../constants/addWidget.fields';
import { FORM_MODES } from '../../../../../../../constants/general.constants';

import styles from '../addWidget.module.scss';

const getFormSections = (entityName) => {
  let columnSection = [
    {
      className: styles.sectionContainer,
      header: { label: __('Widget Details') },
      rows: [
        {
          columns: [ADD_WIDGET_FORM_FIELDS.DISPLAY_NAME, ADD_WIDGET_FORM_FIELDS.WIDGET_NAME],
        },
        {
          columns: [ADD_WIDGET_FORM_FIELDS.ENTITY_NAME],
        },
      ],
    },
  ];
  if (!_isEmpty(entityName)) {
    const columnContent = [
      {
        className: styles.sectionContainer,
        header: { label: __('Choose columns') },
        rows: [
          {
            columns: [ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS],
          },
          {
            columns: [ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS],
          },
        ],
      },
    ];
    columnSection = [...columnSection, ...columnContent];
  }
  return columnSection;
};

const getFormFields = ({ possibleXAxis, possibleYAxis, selectedDimensions, selectedMeasurements, widgetMetaData, widgetName }) => ({
  [ADD_WIDGET_FORM_FIELDS.WIDGET_NAME]: WIDGET_NAME_FIELD,
  [ADD_WIDGET_FORM_FIELDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [ADD_WIDGET_FORM_FIELDS.ENTITY_NAME]: addToRenderOptions(ENTITY_NAME_FIELD, {
    path: 'formMode',
    value: _isEmpty(widgetName) ? FORM_MODES.CREATE : FORM_MODES.EDIT,
  }),
  [ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS]: addToRenderOptions(DIMENSION_FIELD, [
    {
      path: 'options',
      value: possibleXAxis,
    },
    {
      path: 'selectedFieldValues',
      value: selectedDimensions,
    },
    {
      path: 'metrics',
      value: widgetMetaData,
    },
  ]),
  [ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS]: addToRenderOptions(MEASUREMENT_FIELD, [
    {
      path: 'options',
      value: possibleYAxis,
    },
    {
      path: 'selectedFieldValues',
      value: selectedMeasurements,
    },
  ]),
});

export { getFormFields, getFormSections };
