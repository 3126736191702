import _filter from 'lodash/filter';
import _includes from 'lodash/includes';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { getSelectedEventHandlers } from '../../helpers/eventHandlerConfigurator.helper';

import { FIELD_IDS, WIDGET_TYPE_TO_EVENT_TYPE_OPTIONS } from './eventHandlerModal.constants';

import eventConfiguratorReader from '../../../../../../../../readers/eventConfigurator.reader';

const getEventTypeOptions = (values, allFormValues, widgetType) => {
  const eventValue = tget(values, FIELD_IDS.EVENT_HANDLER, EMPTY_OBJECT);
  const eventType = eventConfiguratorReader.eventType(eventValue);
  const { selectedEventHandlerEventTypes } = getSelectedEventHandlers(allFormValues);

  const newEventTypeOptions = _filter(
    WIDGET_TYPE_TO_EVENT_TYPE_OPTIONS[widgetType],
    ({ value }) => eventType === value || !_includes(selectedEventHandlerEventTypes, value),
  );

  return newEventTypeOptions;
};

export { getEventTypeOptions };
