import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import BaseTable from '@tekion/tekion-components/molecules/table/BaseTable';
import SearchInput from '@tekion/tekion-components/molecules/searchInput';

import { ASSIGN_TYPES } from '../../constants/recordSharingRuleList.general.constants';
import { getUserColumns, getUserGroupColumns } from './constants/recordSharingRuleAssignmentDetailTable.columns';
import ACTION_TYPES from '../../constants/recordSharingRuleList.actionTypes';

import styles from './recordSharingRuleAssignmentDetailTable.module.scss';

const RecordSharingRuleAssignmentDetailTable = ({ data, assignType, searchText, onAction }) => {
  const columns = assignType === ASSIGN_TYPES.USER ? getUserColumns(assignType) : getUserGroupColumns(assignType);
  const searchPlaceholder = assignType === ASSIGN_TYPES.USER ? __('Search User') : __('Search User Group');
  const handleUserSearch = useCallback(
    (searchTerm) => {
      onAction({
        type: ACTION_TYPES.ON_SEARCH_USER,
        payload: { searchTerm, assignType },
      });
    },
    [assignType, onAction],
  );

  return (
    <div className={styles.assignmentContainer}>
      <SearchInput
        placeholder={searchPlaceholder}
        textInputClass={styles.search}
        inputContainerClassName={styles.searchContainer}
        value={searchText}
        onChange={handleUserSearch}
      />
      <div className={styles.assignmentTableContainer}>
        <BaseTable showPagination={false} minRows={0} rowHeight={30} columns={columns} data={data} onAction={onAction} />
      </div>
    </div>
  );
};

RecordSharingRuleAssignmentDetailTable.propTypes = {
  assignType: PropTypes.string,
  searchText: PropTypes.string,
  data: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

RecordSharingRuleAssignmentDetailTable.defaultProps = {
  assignType: EMPTY_STRING,
  searchText: EMPTY_STRING,
  data: EMPTY_ARRAY,
};

export default React.memo(RecordSharingRuleAssignmentDetailTable);
