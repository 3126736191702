import _map from 'lodash/map';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _includes from 'lodash/includes';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { SECTION_LABELS } from './groupedRelationshipField.constants';
import { FIELD_IDS as ENTITY_RECORD_GROUP_FIELD_IDS } from '../../constants/entityRecordGroup.constants';

const ROWS = 20;

const getHasMore = (nextPageToken) => nextPageToken !== null;

const getLoadingMessage = () => __('Searching...');

const getValueOption = (lookUpDisplayName) => (record) => ({
  ...record,
  label: __(tget(record, lookUpDisplayName, _get(record, `entity.${lookUpDisplayName}`, EMPTY_STRING))),
  value: _get(record, 'id', EMPTY_STRING),
});

const getOptions = (entityRecordList, entityRecordGroupList, lookUpDisplayName) => [
  { label: SECTION_LABELS.RECORDS, options: _map(entityRecordList, getValueOption(lookUpDisplayName)) },
  {
    label: SECTION_LABELS.GROUPS,
    options: _map(entityRecordGroupList, (group) => ({
      ...group,
      label: __(tget(group, ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME, EMPTY_STRING)),
      value: _get(group, ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME, EMPTY_STRING),
    })),
  },
];

const modifyOptions = (entityRecordList, entityRecordGroupList, lookUpDisplayName, groupOptions) => {
  let newOptions = [];
  _forEach(groupOptions, (groupOption) => {
    const label = _get(groupOption, 'label');
    const options = _get(groupOption, 'options');

    if (label === SECTION_LABELS.RECORDS) {
      newOptions = [
        ...newOptions,
        { label: SECTION_LABELS.RECORDS, options: [...options, ..._map(entityRecordList, getValueOption(lookUpDisplayName))] },
      ];
    } else {
      newOptions = [
        ...newOptions,
        {
          label: SECTION_LABELS.GROUPS,
          options: [
            ...options,
            ..._map(entityRecordGroupList, (group) => ({
              ...group,
              label: __(tget(group, ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME, EMPTY_STRING)),
              value: _get(group, ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME, EMPTY_STRING),
            })),
          ],
        },
      ];
    }
  });
  return newOptions;
};

const getPayload = (searchText, nextPageToken, payloadParams) => ({
  rows: ROWS,
  nextPageToken,
  searchText,
  ...payloadParams,
});

const covertSelectedValues = (newOptions, selectedValues) => {
  let updatedSelectedValues = [];
  _forEach(newOptions, (option) => {
    const sectionOptions = _get(option, 'options', []);
    _forEach(sectionOptions, (sectionOption) => {
      const sectionOptionLabel = _get(sectionOption, 'label');
      const sectionOptionValue = _get(sectionOption, 'value');
      if (_includes(selectedValues, sectionOptionValue)) {
        updatedSelectedValues = [...updatedSelectedValues, { label: sectionOptionLabel, value: sectionOptionValue }];
      }
    });
  });
  return updatedSelectedValues;
};

export { getHasMore, getOptions, getLoadingMessage, getValueOption, modifyOptions, getPayload, covertSelectedValues };
