import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _omit from 'lodash/omit';
import _cloneDeep from 'lodash/cloneDeep';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { isErrorEmpty } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { fetchAllRegex, matchRegex } from '../../../../../../../../actions/entityManagement.actions';

import { getFieldsFormTextPropertiesValue, getRegexOptions, getRegexValue, getTextPropertiesFormValues } from './textPropertiesForm.helpers';

import FIELDS_FORM_FIELD_IDS from '../../../constants/fieldsForm.fieldIds';
import TEXT_PROPERTIES_FORM_FIELD_IDS from '../constants/textPropertiesForm.fieldIds';
import { FIELD_FORM_CONTEXT_ID } from '../../../constants/fieldsForm.constants';

const handleFormInit = async ({ getState, setState }) => {
  const { fieldsFormTextPropertiesValue = {} } = getState();

  const { hits: regexes, error } = await fetchAllRegex();
  const regexpOptions = _isEmpty(error) ? getRegexOptions(regexes) : EMPTY_ARRAY;

  setState({ values: getTextPropertiesFormValues(fieldsFormTextPropertiesValue), regexpOptions });
};

const handleFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { regexpOptions, values: oldValues = EMPTY_OBJECT, fieldsFormOnAction } = getState();
  const values = { ...oldValues, [id]: value };

  if (id === TEXT_PROPERTIES_FORM_FIELD_IDS.SELECT_TEMPLATE_REGEX) {
    _set(values, TEXT_PROPERTIES_FORM_FIELD_IDS.PATTERN, getRegexValue(regexpOptions, value));
  }

  setState({ values }, () =>
    fieldsFormOnAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM, value: getFieldsFormTextPropertiesValue(values) },
    }),
  );
};

const handleValidationSuccess = ({ getState, params = EMPTY_OBJECT }) => {
  const { fieldsFormOnAction } = getState();
  const { errors } = params;

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: { id: FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM, errors: _omit(errors, [TEXT_PROPERTIES_FORM_FIELD_IDS.TEST_REGEX_INPUT_STRING]) },
  });
};

const handleFieldBlur = async ({ setState, getState, params }) => {
  const { values = EMPTY_OBJECT, error = {} } = getState();
  const { id } = params;

  if (
    _includes(
      [
        TEXT_PROPERTIES_FORM_FIELD_IDS.PATTERN,
        TEXT_PROPERTIES_FORM_FIELD_IDS.SELECT_TEMPLATE_REGEX,
        TEXT_PROPERTIES_FORM_FIELD_IDS.TEST_REGEX_INPUT_STRING,
      ],
      id,
    )
  ) {
    const { [TEXT_PROPERTIES_FORM_FIELD_IDS.PATTERN]: regex, [TEXT_PROPERTIES_FORM_FIELD_IDS.TEST_REGEX_INPUT_STRING]: text } = values;

    if (!_isEmpty(text) && !_isEmpty(regex)) {
      const data = await matchRegex({ regex, text });

      if (data === false) {
        _set(error, TEXT_PROPERTIES_FORM_FIELD_IDS.TEST_REGEX_INPUT_STRING, __("The text provided by you doesn't match the regex provided above"));
      } else if (data === true) {
        _unset(error, TEXT_PROPERTIES_FORM_FIELD_IDS.TEST_REGEX_INPUT_STRING);
      }

      setState({ error });
    }
  }
};

const handleSubmit = ({ getState, params = EMPTY_OBJECT }) => {
  const { additional = {} } = params;
  const { formErrors } = additional;
  const { fieldsFormOnAction } = getState();

  fieldsFormOnAction({ type: FORM_ACTION_TYPES.VALIDATION_SUCCESS, payload: { id: FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM, errors: undefined } });

  _set(formErrors, FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM, false);

  if (isErrorEmpty(formErrors)) {
    triggerSubmit(FIELD_FORM_CONTEXT_ID, _cloneDeep(additional));
  }
};

const TEXT_PROPERTIES_FORM_ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleFormInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.ON_FIELD_BLUR]: handleFieldBlur,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidationSuccess,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
};

export default TEXT_PROPERTIES_FORM_ACTION_HANDLERS;
