import _last from 'lodash/last';
import _split from 'lodash/split';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _has from 'lodash/has';
import _size from 'lodash/size';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';

import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import FIELD_TYPES from '../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../constants/fieldDefinition.dataTypes';
import { FIELD_IDS } from '../constants/filterForm.constants';

import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';
import filterReader from '../../../../../readers/filter.reader';

const getFilterForSelect = (filter, options) => ({
  ...filter,
  type: FILTER_TYPES.MULTI_SELECT,
  additional: {
    options: _map(options, (option) => ({
      label: _get(option, 'displayName'),
      value: _get(option, 'value'),
      additional: {
        ...option,
      },
    })),
  },
});

const getFilterFieldOption = (fieldTypeOptions, fieldName) => _find(fieldTypeOptions, (option) => _get(option, 'value') === fieldName);

const getInfoIconSelectedFields = (filterableFieldTypeOptions, fieldsConfig) => {
  const selectedPreAppliedFields = [];
  const selectedUpfrontFields = [];

  _forEach(filterableFieldTypeOptions, (option) => {
    if (_has(fieldsConfig, [filterReader.value(option), FIELD_IDS.DISABLED])) {
      selectedPreAppliedFields.push(option);
    }

    if (_get(fieldsConfig, [filterReader.value(option), FIELD_IDS.UPFRONT])) {
      selectedUpfrontFields.push(option);
    }
  });

  return { selectedPreAppliedFields, selectedUpfrontFields };
};

const countOfEmptyFieldsInPreAppliedFilter = (preAppliedFilters) =>
  _size(_filter(preAppliedFilters, (filterObject) => _isEmpty(filterReader.field(filterObject))));

const getFilterForSimpleColumn = (fieldName, displayName, fieldDef) => {
  let fieldType = fieldDefinitionReader.fieldType(fieldDef);
  const dataType = fieldDefinitionReader.dataType(fieldDef);

  if (fieldType === FIELD_TYPES.SELECT) {
    const multiValued = fieldDefinitionReader.multiValued(fieldDef);
    if (multiValued) {
      fieldType = FILTER_TYPES.MULTI_SELECT;
    } else {
      fieldType = FILTER_TYPES.SINGLE_SELECT;
    }
  }

  const filter = {
    label: displayName,
    value: fieldName,
    type: FILTER_TYPES.STRING,
  };
  const options = fieldDefinitionReader.options(fieldDef) || [];

  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      switch (dataType) {
        case DATA_TYPES.DATE:
        case DATA_TYPES.DATE_TIME:
          return { ...filter, type: FILTER_TYPES.DATE };
        case DATA_TYPES.NUMBER:
          return { ...filter, type: FILTER_TYPES.NUMBER };
        default:
          return filter;
      }

    case FIELD_TYPES.SINGLE_SELECT:
    case FIELD_TYPES.MULTI_SELECT:
      return getFilterForSelect(filter, options);

    case FIELD_TYPES.RELATIONSHIP: {
      const lookUpField = _last(_split(fieldName, '.'));
      const relationshipEntityFieldDefinitions = fieldDefinitionReader.relationshipEntityFieldDefinitions(fieldDef) || {};
      const lookUpFieldDef = _get(relationshipEntityFieldDefinitions, lookUpField, {});
      const lookUpFilter = getFilterForSimpleColumn(lookUpField, displayName, lookUpFieldDef);
      return { ...lookUpFilter, value: fieldName };
    }
    default:
      return filter;
  }
};

const getOptions = (filters) => {
  const filterOptions = _map(filters, (filter) => {
    const label = _get(filter, 'name', EMPTY_STRING);
    let value = _get(filter, 'id', EMPTY_STRING);
    const type = _get(filter, 'type', EMPTY_STRING);

    if (value === OPERATORS.CONTAINS) {
      value = OPERATORS.IN;
    } else if (value === OPERATORS.NOT_CONTAINS) {
      value = OPERATORS.NIN;
    }

    return {
      label,
      value,
      type,
    };
  });
  return filterOptions;
};

export { getFilterForSimpleColumn, getOptions, getFilterFieldOption, getInfoIconSelectedFields, countOfEmptyFieldsInPreAppliedFilter };
