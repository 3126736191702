import produce from 'immer';

import _set from 'lodash/set';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _omit from 'lodash/omit';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import {
  createImportTask,
  getImportTaskById,
  getImportTemplateById,
  startImportById,
  updateImportTemplateById,
} from '../../../../../../actions/importData.actions';

import {
  getColumnDefinitionsFromTemplate,
  getMappedApiFormatValueToFormValue,
  getMappedFormValueToApiFormat,
  getPayloadForUpdatingColumnDefinitions,
  validateIfAllUniqueAndMandatoryFieldsAreMapped,
} from './dataImportForm.helpers';

import ACTION_TYPES from '../constants/dataImportForm.actionTypes';
import { FIELD_IDS, TEMPLATE_FIELD_IDS } from '../constants/dataImportForm.constants';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import PAGE_IDS from '../../../../constants/PageIds.constants';

import entityReader from '../../../../../../readers/entity.reader';
import fieldDefinitionReader from '../../../../../../readers/fieldDefinition.reader';

const handleFieldChange = ({ setState, params }) => {
  const { id, value, selectedOption = EMPTY_OBJECT } = params;

  setState(
    produce((draft) => {
      _set(draft, `dataImportFormValue.${id}`, value);
      if (id === FIELD_IDS.ENTITY_NAME) {
        const fieldDefinitions = entityReader.fieldDefinitions(selectedOption) || EMPTY_ARRAY;
        const entityFieldOptions = _map(fieldDefinitions, (fieldDef) => ({
          value: fieldDefinitionReader.name(fieldDef),
          label: fieldDefinitionReader.displayName(fieldDef),
        }));
        _set(draft, 'entityFieldOptions', entityFieldOptions);
        _set(draft, 'selectedEntityFieldDefinitions', fieldDefinitions);
      }
    }),
  );
};

const handleErrors = ({ setState, params }) => {
  const { errors } = params;

  setState({ errors });
};

const handleRedirection = ({ getState }) => {
  const { history } = getState();

  history.goBack();
};

const createImportTaskAndFetchFieldMappingTemplate = async ({ getState, setState }) => {
  const { dataImportFormValue = EMPTY_OBJECT } = getState();

  const mappedFormValueToApiFormat = getMappedFormValueToApiFormat(dataImportFormValue);
  const importTaskId = await createImportTask(mappedFormValueToApiFormat);

  if (!_isEmpty(importTaskId)) {
    let columnDefinitions = EMPTY_ARRAY;
    let newDataImportFormValue = dataImportFormValue;

    setState({ isLoadingFieldMapping: true });

    const taskResponse = await getImportTaskById(importTaskId);
    const templateId = tget(taskResponse, FIELD_IDS.TEMPLATE_ID);

    if (tget(taskResponse, FIELD_IDS.ID) === importTaskId) {
      newDataImportFormValue = getMappedApiFormatValueToFormValue(taskResponse);
    }

    if (!_isEmpty(templateId)) {
      const templateResponse = await getImportTemplateById(templateId);
      columnDefinitions = getColumnDefinitionsFromTemplate(templateResponse);
    }

    setState({
      templateId,
      dataImportFormValue: newDataImportFormValue,
      columnDefinitions,
      isLoadingFieldMapping: false,
    });
  }

  setState({ isSaveLoading: false });
};

const handleFieldMappingValidation = ({ getState, setState }) => {
  const { errors = EMPTY_OBJECT, columnDefinitions = EMPTY_ARRAY, selectedEntityFieldDefinitions = EMPTY_ARRAY } = getState();

  const { isValid, message } = validateIfAllUniqueAndMandatoryFieldsAreMapped(columnDefinitions, selectedEntityFieldDefinitions);

  if (!isValid) {
    setState({ errors: { ...errors, [FIELD_IDS.FIELD_MAPPING]: message } });
  } else {
    setState({ errors: { ..._omit(errors, [FIELD_IDS.FIELD_MAPPING]) } });
  }

  return isValid;
};

const handleSubmit = async ({ getState, setState }) => {
  const {
    isColumnDefinitionsEdited = false,
    templateId = EMPTY_STRING,
    dataImportFormValue = EMPTY_OBJECT,
    columnDefinitions = EMPTY_ARRAY,
  } = getState();

  const importTaskId = tget(dataImportFormValue, FIELD_IDS.ID);

  setState({ isSaveLoading: true });

  if (_isEmpty(importTaskId)) {
    // Had to add a delay here, because just after uploading the file if we create our ImportTask, then the api is breaking
    // so needed to give some delay before we create the importTask
    setTimeout(() => {
      createImportTaskAndFetchFieldMappingTemplate({ getState, setState });
    }, [ES_REFETCH_DELAY]);
  } else if (isColumnDefinitionsEdited) {
    const payloadForColumnDefinitions = getPayloadForUpdatingColumnDefinitions(columnDefinitions);
    await updateImportTemplateById(templateId, payloadForColumnDefinitions);

    setState({ isColumnDefinitionsEdited: false, isSaveLoading: false });
  } else {
    const isValid = handleFieldMappingValidation({ getState, setState });
    setState({ isConfirmationModalVisible: isValid, isSaveLoading: false });
  }
};

const handleTableFieldChange = ({ getState, setState, params }) => {
  const rowIndex = tget(params, 'rowIndex');
  const id = tget(params, 'id');
  const value = tget(params, 'value');

  if (!_isNil(rowIndex) && id) {
    setState(
      produce((draft) => {
        _set(draft, [TEMPLATE_FIELD_IDS.COLUMN_DEFINITIONS, rowIndex, id], value);
        _set(draft, 'isColumnDefinitionsEdited', true);
      }),
      () => {
        handleFieldMappingValidation({ getState, setState });
      },
    );
  }
};

const handleStartImportTask = async ({ getState }) => {
  const { dataImportFormValue = EMPTY_OBJECT, history = EMPTY_OBJECT } = getState();
  const importTaskId = tget(dataImportFormValue, FIELD_IDS.ID);

  const response = await startImportById(importTaskId);

  if (response) {
    const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.IMPORT_DATA_STUDIO}/${PAGE_IDS.DATA_IMPORTS}/${importTaskId}`;
    history.push({
      pathname,
      state: { importTaskData: dataImportFormValue },
    });
  }
};

const handleCancelConfirmationModal = ({ setState }) => {
  setState({ isConfirmationModalVisible: false });
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
  [ACTION_TYPES.TABLE_ON_CHANGE]: handleTableFieldChange,
  [ACTION_TYPES.ON_CANCEL_CONFIRMATION_MODAL]: handleCancelConfirmationModal,
  [ACTION_TYPES.START_IMPORT_TASK]: handleStartImportTask,
};

export default ACTION_HANDLERS;
