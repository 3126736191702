const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
};

const INITIAL_STATE = {
  currentPage: 0,
  pageSize: 50,
};

const TABLE_MANAGER_PROPS = {
  showHeader: true,
  showSearch: true,
  showFilter: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const HEADER_PROPS = {
  label: __('Global Selects'),
};

export { TABLE_CONSTANTS, INITIAL_STATE, TABLE_MANAGER_PROPS, HEADER_PROPS };
