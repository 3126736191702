import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _last from 'lodash/last';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import {
  getApplicationConfigWithUpdatedComponent,
  getComponentByComponentId,
  getComponentFromApplicationConfig,
} from '../../../helpers/applicationBuilder.helpers';
import { addNewTabToApplicationConfig, getUpdatedChildrenOnDrag } from './navigationNeededComponent.helpers';

import { ADD_TAB, COMPONENT_KEYS, DIALOG_TEXT, HOME_TAB, TITLE_TEXT } from '../../../constants/applicationBuilder.constants';
import ACTION_TYPES from '../../../constants/applicationBuilder.actionTypes';
import { COMPONENTS_TYPES } from '../../../../../../../../../constants/applicationRenderer.constants';

const handleInit = ({ getState, setState }) => {
  const { selectedComponentId, applicationConfig } = getState();
  const componentDetails = getComponentByComponentId(applicationConfig, selectedComponentId);
  const selectedComponentType = tget(componentDetails, 'type');

  if (selectedComponentType === COMPONENTS_TYPES.TAB) {
    setState({ selectedTabId: selectedComponentId, selectedComponentType });
  }
};

const handleAddNewTab = ({ getState }) => {
  const { applicationConfig, applicationBuilderOnAction } = getState();
  const updatedApplicationConfig = addNewTabToApplicationConfig(applicationConfig);
  applicationBuilderOnAction({ type: ACTION_TYPES.SET_APPLICATION_CONFIG, payload: { applicationConfig: updatedApplicationConfig } });
};

const handleOnDragEnd = ({ params = EMPTY_OBJECT, getState }) => {
  const { result = EMPTY_OBJECT } = params;
  const { applicationConfig, applicationBuilderOnAction } = getState();
  let component = getComponentFromApplicationConfig(COMPONENTS_TYPES.NAVIGATION, applicationConfig);
  const children = _get(component, COMPONENT_KEYS.CHILDREN, EMPTY_ARRAY);
  component = { ...component, children: getUpdatedChildrenOnDrag(children, result) };
  const updatedApplicationConfig = getApplicationConfigWithUpdatedComponent(applicationConfig, COMPONENTS_TYPES.NAVIGATION, component);
  applicationBuilderOnAction({ type: ACTION_TYPES.SET_APPLICATION_CONFIG, payload: { applicationConfig: updatedApplicationConfig } });
};

const handleOnDeleteTab = ({ params = EMPTY_OBJECT, setState }) => {
  const selectedDeleteTabId = _get(params, 'tabInfo.id', '');

  setState({
    isDeleteDialogVisible: true,
    dialogText: DIALOG_TEXT.DELETE_TAB,
    titleText: TITLE_TEXT.DELETE_TITLE_TEXT,
    selectedDeleteTabId,
  });
};

const handleTabClick = ({ params, getState, setState }) => {
  const { applicationBuilderOnAction } = getState();
  const selectedTabId = _get(params, 'tab');
  if (selectedTabId !== ADD_TAB) {
    setState({ selectedTabId });
    applicationBuilderOnAction({ type: ACTION_TYPES.SET_SELECTED_COMPONENT, payload: { selectedComponentId: selectedTabId } });
  }
};

const handleOnCancelDeleteRequest = ({ setState }) => {
  setState({ isDeleteDialogVisible: false });
};

const handleOnConfirmTabDelete = ({ getState, setState }) => {
  const { selectedDeleteTabId, applicationConfig, applicationBuilderOnAction } = getState();
  let component = getComponentFromApplicationConfig(COMPONENTS_TYPES.NAVIGATION, applicationConfig);
  const children = _filter(_get(component, COMPONENT_KEYS.CHILDREN, []), (child) => _get(child, COMPONENT_KEYS.COMPONENT_ID) !== selectedDeleteTabId);
  component = { ...component, children };
  const updatedApplicationConfig = getApplicationConfigWithUpdatedComponent(applicationConfig, COMPONENTS_TYPES.NAVIGATION, component);

  setState(
    {
      selectedTabId: _get(_last(children), COMPONENT_KEYS.COMPONENT_ID),
      isDeleteDialogVisible: false,
    },
    () => {
      const { selectedTabId } = getState();
      applicationBuilderOnAction({ type: ACTION_TYPES.SET_SELECTED_COMPONENT, payload: { selectedComponentId: selectedTabId } });
      applicationBuilderOnAction({ type: ACTION_TYPES.SET_APPLICATION_CONFIG, payload: { applicationConfig: updatedApplicationConfig } });
    },
  );
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT_NAVIGATION_COMPONENT]: handleInit,
  [ACTION_TYPES.ADD_NEW_TAB]: handleAddNewTab,
  [ACTION_TYPES.ON_DRAG_END]: handleOnDragEnd,
  [ACTION_TYPES.DELETE_TAB]: handleOnDeleteTab,
  [ACTION_TYPES.ON_TAB_CLICK]: handleTabClick,
  [ACTION_TYPES.ON_CANCEL_DELETE_REQUEST]: handleOnCancelDeleteRequest,
  [ACTION_TYPES.ON_CONFIRM_DELETE_REQUEST]: handleOnConfirmTabDelete,
};

export default ACTION_HANDLERS;
