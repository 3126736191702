const FIELD_IDS = {
  NAME: 'NAME',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  COUNTRY_CODE: 'countryCode',
  EMAIL: 'email',
  PROFILE_NAME: 'profileName',
  WORK_SPACE_IDS: 'workspaceIds',
  ROLE: 'roleName',
  DISPLAY_NAME: 'displayName',
  ACTIVE: 'active',
  LOCK_USER: 'lockUser',
  PROFILE_PICTURE: 'profilePicture',
};

export default FIELD_IDS;
