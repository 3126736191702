import { COLUMN_IDS } from '../constants/configTab.constants';
import { getCellComponent } from './configTab.columnHelpers';

const COLUMN_KEYS = [COLUMN_IDS.TAB_NAME, COLUMN_IDS.TAB_ICON, COLUMN_IDS.ACTION];

const TAB_NAMES_COLUMN_DATA = {
  [COLUMN_IDS.TAB_NAME]: {
    Header: __('Name'),
    sortable: false,
  },
  [COLUMN_IDS.TAB_ICON]: {
    Header: __('Icon'),
    sortable: false,
  },
  [COLUMN_IDS.ACTION]: {
    Header: __('Action'),
    resizable: false,
    fixed: 'right',
    minWidth: 25,
  },
};

const getColumns = (id, onAction, tabContentDetails, tabContentChildren) => {
  const columns = COLUMN_KEYS.map((key) => ({
    ...TAB_NAMES_COLUMN_DATA[key],
    key,
    accessor: key,
    Cell: ({ value, index }) =>
      getCellComponent({
        id,
        key,
        value,
        index,
        tabContentDetails,
        tabContentChildren,
        onAction,
      }),
  }));
  return columns;
};

export { COLUMN_KEYS, getColumns };
