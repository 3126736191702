import { defaultMemoize } from 'reselect';

import _get from 'lodash/get';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { DATE_TIME_FORMATS, getFormattedDateTime } from '@tekion/tekion-base/utils/dateUtils';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import addOptionsToAdditional from '@tekion/tekion-base/utils/addOptionsToAdditional';

import { BUNDLE_STATE_FILTER, DEFAULT_FILTERS, DEFAULT_FILTER_GROUP } from '../constants/exportList.filters';
import { DELETE_ACTION, PUBLISH_ACTION } from '../constants/exportList.rowActions';
import { BUNDLE_STATE_FILTER_OPTIONS, BUNDLE_STATE } from '../constants/exportList.general.constants';
import { COLUMN_IDS } from '../constants/exportList.table.constants';
import { TEXT_STARTS_WITH } from '../../../../../constants/filter.constants';

const getRowActions = (props) => {
  const bundleState = tget(props, 'state', EMPTY_STRING);

  if (bundleState === BUNDLE_STATE.PUBLISHED) {
    return [DELETE_ACTION];
  }

  return [DELETE_ACTION, PUBLISH_ACTION];
};

const getTableProps = defaultMemoize((handleRowAction, currentPage, loading, rows, totalNumberOfEntries) => ({
  currentPage: currentPage + 1,
  loading,
  showPagination: true,
  pageSize: rows,
  showActions: true,
  resultsPerPage: rows,
  totalNumberOfEntries,
  minRows: 0,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  rows,
  onRowActionClick: handleRowAction,
  getRowActions,
}));

const getModifiedFilters = defaultMemoize((selectedFilters) => {
  const modifiedFilters = _reduce(
    selectedFilters,
    (result, filter) => {
      if (!_isEmpty(_get(filter, 'values'))) {
        result.push({
          values: _get(filter, 'values'),
          field: _get(filter, 'type'),
          filterType: _get(filter, 'operator'),
        });
      }

      return result;
    },
    [],
  );

  return modifiedFilters;
});

const getOptions = defaultMemoize((options) =>
  _map(options, (option) => ({
    value: option.value,
    label: option.label,
  })),
);

const getFilterProps = defaultMemoize((selectedFilters, selectedFilterGroup) => {
  const filterTypes = [addOptionsToAdditional(BUNDLE_STATE_FILTER, getOptions(BUNDLE_STATE_FILTER_OPTIONS))];

  return {
    filterTypes,
    selectedFilters,
    appliedFilterGroup: selectedFilterGroup || DEFAULT_FILTER_GROUP,
    defaultFilterTypes: DEFAULT_FILTERS,
  };
});

const getPayload = defaultMemoize((pageSize, nextPageToken, searchText, selectedFilters) => {
  let payload = { rows: pageSize };
  let filters = EMPTY_ARRAY;

  if (!_isEmpty(selectedFilters)) {
    filters = [...getModifiedFilters(selectedFilters)];
  }

  if (!_isEmpty(searchText)) {
    filters = [
      {
        field: COLUMN_IDS.DISPLAY_NAME,
        values: [searchText],
        filterType: TEXT_STARTS_WITH,
      },
      ...filters,
    ];
  }

  if (!_isEmpty(filters)) {
    payload = {
      filters,
      ...payload,
    };
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      nextPageToken,
      ...payload,
    };
  }

  return payload;
});

const getTime = (createdTime) => getFormattedDateTime(createdTime, DATE_TIME_FORMATS.DATE_MONTH_YEAR);

const getExportData = (exportResponse) => {
  const exportList = tget(exportResponse, 'hits', EMPTY_ARRAY);

  _map(exportList, (exports) => {
    const createdTime = tget(exports, 'createdTime', EMPTY_STRING);
    _set(exports, 'createdTime', getTime(createdTime));
  });

  return exportList;
};

export { getTableProps, getPayload, getFilterProps, getExportData };
