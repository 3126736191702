import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import LoadingSpinner from '@tekion/tekion-components/molecules/loadingSpinner';
import TMenu from '@tekion/tekion-components/molecules/Menu';
import Tooltip from '@tekion/tekion-components/atoms/tooltip';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

import { getValuesFromOption, handleMenuOptionClick } from './menu.helpers';

import styles from './menu.module.scss';

// menuOptions should be array of object created from menuOptionBuilder
const Menu = ({
  isLoading,
  menuClassName,
  menuItemClassName,
  labelClassName,
  loaderClassName,
  toolTipClassName,
  toolTipIconName,
  toolTipPlacement,
  emptyOptionsMessage,
  menuOptions,
  ...rest
}) => {
  const handleMenuClick = useCallback(
    (action) => {
      handleMenuOptionClick(action, menuOptions);
    },
    [menuOptions],
  );

  const renderOption = useCallback(
    (option) => {
      const { id, label, disabled, disableMessage } = getValuesFromOption(option);

      return (
        <TMenu.Item key={id} disabled={disabled} className={`${styles.menuItem} ${menuItemClassName}`} onClick={handleMenuClick}>
          <div className={`${styles.label} ${labelClassName}`}>{label}</div>
          {disabled ? (
            <Tooltip className={toolTipClassName} placement={toolTipPlacement} title={disableMessage}>
              <FontIcon>{toolTipIconName}</FontIcon>
            </Tooltip>
          ) : null}
        </TMenu.Item>
      );
    },
    [handleMenuClick, menuItemClassName, toolTipIconName, toolTipPlacement, toolTipClassName, labelClassName],
  );

  return (
    <TMenu {...rest} className={`${styles.menuContainer} ${menuClassName}`}>
      {isLoading && (
        <div className={` ${styles.loader} ${loaderClassName}`}>
          <LoadingSpinner size={35} />
        </div>
      )}
      {!isLoading && _isEmpty(menuOptions) && <div>{emptyOptionsMessage}</div>}
      {!isLoading && !_isEmpty(menuOptions) && _map(menuOptions, renderOption)}
    </TMenu>
  );
};

Menu.propTypes = {
  isLoading: PropTypes.bool,
  menuClassName: PropTypes.string,
  loaderClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  menuItemClassName: PropTypes.string,
  toolTipClassName: PropTypes.string,
  toolTipPlacement: PropTypes.string,
  toolTipIconName: PropTypes.string,
  emptyOptionsMessage: PropTypes.string,
  menuOptions: PropTypes.array,
};

Menu.defaultProps = {
  isLoading: false,
  menuClassName: '',
  loaderClassName: '',
  labelClassName: '',
  menuItemClassName: '',
  toolTipClassName: '',
  toolTipPlacement: 'topRight',
  toolTipIconName: 'icon-information-filled',
  emptyOptionsMessage: __('No Data'),
  menuOptions: EMPTY_ARRAY,
};

export default Menu;
