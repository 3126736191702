import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';

import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { tget } from '@tekion/tekion-base/utils/general';

// Actions
import {
  createTask,
  createValidationRule,
  getCustomCodeByClassName,
  getValidationRule,
  saveCustomCode,
  updateValidationRule,
} from '../../../../../../actions/validationRuleBuilder.actions';
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../../actions/conditionBuilder.actions';
import { fetchEntityDefByName } from '../../../../../../actions/entityManagement.actions';

// Helpers
import { generateFormFieldsFromData, generatePayloadFromFormValues } from './validationRuleBuilderForm.payloadHelpers';
import { getClassName, getCode, getInitialFunction } from './validationRuleBuilderForm.helpers';

// Constants
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import ACTION_TYPES from '../constants/validationRuleBuilderForm.actionTypes';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import { FIELD_IDS } from '../../../../../../constants/validationRuleBuilder.constants';
import { MODULE_TYPE, TASK_TYPE } from '../constants/validationRuleBuilderForm.general';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import { CONDITION_BUILDER_TYPES } from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';

// this function is used for compilation of code use in future

const creationTask = async (taskPayload, resolve, reject) => {
  const response = await createTask(taskPayload);
  const taskStatus = _get(response, 'taskStatus', '');
  const taskResponse = _get(response, 'response');
  if (taskStatus === 'COMPLETED') {
    resolve();
  } else {
    reject(taskResponse);
  }
  return EMPTY_OBJECT;
};

const handleLoadConditionFields = async ({ getState, setState }) => {
  const { match } = getState();
  const { entityName } = _get(match, 'params', EMPTY_OBJECT);

  setState({ isConditionFieldsLoading: true });

  const mapOfVariableToEntityName = { $record: entityName };
  const conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityName);

  setState({
    isConditionFieldsLoading: false,
    conditionBuilderFieldDefinitionObject,
    mapOfVariableToEntityName,
  });
};

const handleFormInit = async ({ getState, setState }) => {
  const { match } = getState();
  const { entityName, ruleName } = _get(match, 'params', EMPTY_OBJECT);

  setState({ formMode: FORM_MODES.EDIT, isFetchingRule: true });
  const entity = await fetchEntityDefByName(entityName);

  if (!_isEmpty(entityName) && !_isEmpty(ruleName)) {
    const data = await getValidationRule(entityName, ruleName);
    const condition = tget(data, FIELD_IDS.CONDITION, EMPTY_OBJECT);
    const type = tget(condition, CONDITION_BUILDER_FIELD_IDS.TYPE, CONDITION_BUILDER_TYPES.CRITERIA);
    let response = {};
    if (type === CONDITION_BUILDER_TYPES.SCRIPT) {
      const className = getClassName(_get(data, FIELD_IDS.RULE_NAME, EMPTY_STRING));
      response = await getCustomCodeByClassName(className);
    }
    const formValues = generateFormFieldsFromData(data, response);
    setState({ isFetchingRule: false, formValues, entity, code: _get(response, 'code', EMPTY_STRING) });
  } else {
    setState({ isFetchingRule: false, formMode: FORM_MODES.CREATE, entity });
  }
};

const handleFieldChange = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { id, value } = params;
  const { formValues } = getState();
  let updatedValues = { ...formValues };
  if (id === FIELD_IDS.ENABLE_RICH_TEXT_EDITOR && value === true) {
    const { metaData } = getState();
    const customCode = getInitialFunction(metaData);
    updatedValues = { ...updatedValues, customCode: [customCode] };
  }

  updatedValues = { ...updatedValues, [id]: value };
  setState({ formValues: updatedValues });
};

const handleErrors = ({ params = EMPTY_OBJECT, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const handleSubmitValidationRule = async ({ getState, setState, params }) => {
  const { errors, formMode, match, formValues, history } = getState();
  const customCodeError = _get(params, 'customCodeError', EMPTY_STRING);

  const { entityName, ruleName } = _get(match, 'params', EMPTY_OBJECT);
  const hasErrors = _reduce(
    errors,
    (hasError, curr, fieldId) => {
      if (fieldId !== FIELD_IDS.CONDITION) return hasError || !_isEmpty(curr);
      return _reduce(curr, (conditionHasError, error) => hasError || conditionHasError || !_isEmpty(error), false);
    },
    false,
  );

  if (!hasErrors && _isEmpty(customCodeError)) {
    const payload = generatePayloadFromFormValues(formValues);
    if (formMode === FORM_MODES.CREATE) {
      const response = await createValidationRule(entityName, payload);
      if (!_isEmpty(response)) {
        setTimeout(() => {
          history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VALIDATION_RULE_BUILDER}`);
        }, ES_REFETCH_DELAY);
      } else {
        setState({ isSubmitting: false });
      }
    } else {
      const response = await updateValidationRule(entityName, ruleName, payload);
      if (!_isEmpty(response)) {
        setTimeout(() => {
          history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VALIDATION_RULE_BUILDER}`);
        }, ES_REFETCH_DELAY);
      } else {
        setState({ isSubmitting: false });
      }
    }
  } else {
    toaster(TOASTER_TYPE.ERROR, __('Please correct form errors.'));
  }
};

const handleSubmit = async ({ getState, setState }) => {
  const { formValues, metaData, code } = getState();

  const customCodeEnabled = _get(formValues, FIELD_IDS.ENABLE_RICH_TEXT_EDITOR, false);
  let customCodeError;
  setState({ isSubmitting: true, errorLink: customCodeError });
  if (customCodeEnabled) {
    const className = getClassName(_get(formValues, FIELD_IDS.RULE_NAME, EMPTY_STRING));
    const customCodePayload = {
      moduleType: MODULE_TYPE,
      className,
      code: getCode(formValues, metaData, code, className),
      rawCode: JSON.stringify(_get(formValues, `${FIELD_IDS.CUSTOM_CODE}.editorContent`, EMPTY_OBJECT)),
    };

    const data = await saveCustomCode(customCodePayload);
    if (!_isEmpty(data)) {
      const taskPayload = { moduleType: MODULE_TYPE, taskType: TASK_TYPE, className };

      const promise = new Promise((resolve, reject) => {
        creationTask(taskPayload, resolve, reject);
      });
      promise
        .then(() => {
          toaster(TOASTER_TYPE.SUCCESS, __('Compilation of code is successful.'));
          handleSubmitValidationRule({ getState, setState, params: { customCodeError } });
        })
        .catch((error) => {
          customCodeError = error;
          toaster(TOASTER_TYPE.ERROR, __('Failed to compile the code.Please try Again Later'));
          setState({ errorLink: customCodeError, isSubmitting: false });
        });
    } else {
      setState({ isSubmitting: false });
    }
  } else {
    handleSubmitValidationRule({ getState, setState, params: { customCodeError: EMPTY_STRING } });
  }
};

const handleCancel = ({ getState }) => {
  const { history, match } = getState();
  const { entityName } = _get(match, 'params', EMPTY_OBJECT);
  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VALIDATION_RULE_BUILDER}`);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.LOAD_CONDITION_FIELDS]: handleLoadConditionFields,
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleFormInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL]: handleCancel,
};

export default ACTION_HANDLERS;
