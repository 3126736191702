import { getPlatePluginTypes, getRenderElement } from '@udecode/plate-core';

export const ELEMENT_VIDEO = 'video';

const createVideoPlugin = () => ({
  pluginKeys: ELEMENT_VIDEO,
  renderElement: getRenderElement(ELEMENT_VIDEO),
  // deserialize: getImageDeserialize(),
  voidTypes: getPlatePluginTypes(ELEMENT_VIDEO),
});

export default createVideoPlugin;
