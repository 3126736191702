import React from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _noop from 'lodash/noop';

import { EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import { getPlainTextFromHtml } from '../../helpers/viewBuilder.helper';

const RichTextEditorTableRenderer = ({ column = EMPTY_OBJECT, original = EMPTY_OBJECT }) => {
  const accessor = _get(column, 'accessor', _noop);

  let value = accessor(original);
  value = getPlainTextFromHtml(value) || NO_DATA;

  return <div>{__(value)}</div>;
};

RichTextEditorTableRenderer.propTypes = {
  column: PropTypes.object,
  original: PropTypes.object,
};

RichTextEditorTableRenderer.defaultProps = {
  column: EMPTY_OBJECT,
  original: EMPTY_OBJECT,
};

export default makeCellRenderer(RichTextEditorTableRenderer);
