import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _includes from 'lodash/includes';
import _map from 'lodash/map';
import _isNil from 'lodash/isNil';
import _isEqual from 'lodash/isEqual';
import _keyBy from 'lodash/keyBy';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Components
import Heading from '@tekion/tekion-components/atoms/Heading';
import Loader from '@tekion/tekion-components/molecules/loader';
import NullComponent from '@tekion/tekion-components/atoms/nullComponent/NullComponent';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import FieldAndChangedValuePair from '../fieldAndChangedValuePair/FieldAndChangedValuePair';

// Constants
import FIELD_TYPES from '../../../../../../../../../constants/fieldDefinition.fieldTypes';
import { APPROVAL_CENTRE_FIELD_IDS, CUSTOM_ENTITY_REQUEST_FIELD_IDS } from '../../../../../../../../../constants/approvalCentre.constants';
import SYSTEM_DEFINED_FIELDS from '../../../../../../../../../constants/systemDefinedFields';
import DATA_TYPES from '../../../../../../../../../constants/fieldDefinition.dataTypes';

import fieldDefinitionReader from '../../../../../../../../../readers/fieldDefinition.reader';

import styles from './requestedEntityRecordChanges.module.scss';

const RequestedEntityRecordChanges = ({ isEntityRecordResolving, approvalRequest, entityRecord, entityFieldDefByName }) => {
  const data = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.DATA, EMPTY_OBJECT);
  const recordRequestedData = tget(data, CUSTOM_ENTITY_REQUEST_FIELD_IDS.CUSTOM_ENTITY_REQUEST, EMPTY_OBJECT);

  const renderComplexFields = useCallback((fieldDef, newRecordFieldValue, prevRecordValue) => {
    const complexEntityFieldDefinitionsByName = _keyBy(fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef), 'name');
    return _map(newRecordFieldValue, (newComplexFieldValue, complexField) => {
      const prevComplexFieldValue = tget(prevRecordValue, complexField);
      const complexFieldDef = tget(complexEntityFieldDefinitionsByName, complexField);
      const complexDisplayName = fieldDefinitionReader.displayName(complexFieldDef);

      if (!_isEqual(prevComplexFieldValue, newComplexFieldValue)) {
        return (
          <FieldAndChangedValuePair key={complexField} newValue={newComplexFieldValue} prevValue={prevComplexFieldValue} label={complexDisplayName} />
        );
      } else {
        return <NullComponent />;
      }
    });
  }, []);

  const renderRequestedChangedFields = useCallback(
    () => (
      <div className={styles.requestedChangesContainer}>
        {_map(entityFieldDefByName, (fieldDef, fieldName) => {
          const isFieldSystemDefined = _includes(SYSTEM_DEFINED_FIELDS, fieldName);
          const newRecordFieldValue = isFieldSystemDefined ? tget(recordRequestedData, fieldName) : tget(recordRequestedData, `entity.${fieldName}`);

          if (!_isNil(newRecordFieldValue) && (fieldName === SYSTEM_DEFINED_FIELDS.NAME || !isFieldSystemDefined)) {
            const fieldType = fieldDefinitionReader.fieldType(fieldDef);
            const dataType = fieldDefinitionReader.dataType(fieldDef);
            const fieldDisplayName = fieldDefinitionReader.displayName(fieldDef);

            let prevRecordValue = isFieldSystemDefined ? tget(entityRecord, fieldName) : tget(entityRecord, `entity.${fieldName}`);

            if (fieldType === FIELD_TYPES.RELATIONSHIP) {
              const lookupDisplayField = fieldDefinitionReader.lookupFieldDisplayField(fieldDef);
              const lookupKeyForRecord = `${fieldName}.${lookupDisplayField}`;
              const lookupDisplayValue = tget(entityRecord, ['entity', lookupKeyForRecord]);
              prevRecordValue = lookupDisplayValue;
              return <FieldAndChangedValuePair key={fieldName} newValue={newRecordFieldValue} prevValue={prevRecordValue} label={fieldDisplayName} />;
            } else if (dataType === DATA_TYPES.COMPLEX) {
              return renderComplexFields(fieldDef, newRecordFieldValue, prevRecordValue);
            } else {
              return <FieldAndChangedValuePair key={fieldName} newValue={newRecordFieldValue} prevValue={prevRecordValue} label={fieldDisplayName} />;
            }
          }
          return <NullComponent />;
        })}
      </div>
    ),
    [entityFieldDefByName, entityRecord, recordRequestedData, renderComplexFields],
  );

  return (
    <>
      <Heading className="p-b-8" size={4}>
        {__('Changes Requested')}
      </Heading>
      <PropertyControlledComponent controllerProperty={!isEntityRecordResolving} fallback={<Loader />}>
        {renderRequestedChangedFields()}
      </PropertyControlledComponent>
    </>
  );
};

RequestedEntityRecordChanges.propTypes = {
  isEntityRecordResolving: PropTypes.bool,
  approvalRequest: PropTypes.object.isRequired,
  entityFieldDefByName: PropTypes.object,
  entityRecord: PropTypes.object,
};

RequestedEntityRecordChanges.defaultProps = {
  isEntityRecordResolving: false,
  entityRecord: EMPTY_OBJECT,
  entityFieldDefByName: EMPTY_OBJECT,
};

export default RequestedEntityRecordChanges;
