import { FIELD_IDS, PROVIDER_TYPES } from '../constants/authProviderForm.constants';

const getFieldsBasedOnProviderType = (providerType) => {
  let applicableFieldIdsForGivenProviderType = [FIELD_IDS.PROVIDER_TYPE, FIELD_IDS.NAME, FIELD_IDS.DISPLAY_NAME, FIELD_IDS.URL_SUFFIX];

  switch (providerType) {
    case PROVIDER_TYPES.APPLE: {
      applicableFieldIdsForGivenProviderType = [
        ...applicableFieldIdsForGivenProviderType,
        FIELD_IDS.TEAM_ID,
        FIELD_IDS.SERVICE_ID,
        FIELD_IDS.KEY_ID,
        FIELD_IDS.KEY_MEDIA_ID,
        FIELD_IDS.DEFAULT_SCOPES,
        FIELD_IDS.CUSTOM_ERROR_URL,
        FIELD_IDS.CUSTOM_LOGOUT_URL,
        FIELD_IDS.REGISTRATION_HANDLER,
        FIELD_IDS.REGISTER_AS,
        FIELD_IDS.PORTAL,
        FIELD_IDS.ICON_URL,
      ];
      break;
    }
    case PROVIDER_TYPES.FACEBOOK: {
      applicableFieldIdsForGivenProviderType = [
        ...applicableFieldIdsForGivenProviderType,
        FIELD_IDS.KEY,
        FIELD_IDS.SECRET,
        FIELD_IDS.AUTHORIZE_END_POINT_URL,
        FIELD_IDS.TOKEN_END_POINT_URL,
        FIELD_IDS.USER_INFO_END_POINT_URL,
        FIELD_IDS.DEFAULT_SCOPE,
        FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE,
        FIELD_IDS.CUSTOM_ERROR_URL,
        FIELD_IDS.CUSTOM_LOGOUT_URL,
        FIELD_IDS.REGISTRATION_HANDLER,
        FIELD_IDS.EXECUTE_REGISTRATION_AS,
        FIELD_IDS.PORTAL,
        FIELD_IDS.ICON_URL,
      ];
      break;
    }
    case PROVIDER_TYPES.GITHUB: {
      applicableFieldIdsForGivenProviderType = [
        ...applicableFieldIdsForGivenProviderType,
        FIELD_IDS.KEY,
        FIELD_IDS.SECRET,
        FIELD_IDS.AUTHORIZE_END_POINT_URL,
        FIELD_IDS.TOKEN_END_POINT_URL,
        FIELD_IDS.DEFAULT_SCOPE,
        FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE,
        FIELD_IDS.CUSTOM_ERROR_URL,
        FIELD_IDS.CUSTOM_LOGOUT_URL,
        FIELD_IDS.PORTAL,
        FIELD_IDS.CUSTOM_LOGOUT_URL,
      ];
      break;
    }
    case PROVIDER_TYPES.GOOGLE: {
      applicableFieldIdsForGivenProviderType = [
        ...applicableFieldIdsForGivenProviderType,
        FIELD_IDS.KEY,
        FIELD_IDS.SECRET,
        FIELD_IDS.AUTHORIZE_END_POINT_URL,
        FIELD_IDS.TOKEN_END_POINT_URL,
        FIELD_IDS.USER_INFO_END_POINT_URL,
        FIELD_IDS.DEFAULT_SCOPE,
        FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE,
        FIELD_IDS.CUSTOM_ERROR_URL,
        FIELD_IDS.CUSTOM_LOGOUT_URL,
        FIELD_IDS.REGISTRATION_HANDLER,
        FIELD_IDS.EXECUTE_REGISTRATION_AS,
        FIELD_IDS.PORTAL,
        FIELD_IDS.ICON_URL,
      ];
      break;
    }
    case PROVIDER_TYPES.MICROSOFT: {
      applicableFieldIdsForGivenProviderType = [
        ...applicableFieldIdsForGivenProviderType,
        FIELD_IDS.KEY,
        FIELD_IDS.SECRET,
        FIELD_IDS.AUTHORIZE_END_POINT_URL,
        FIELD_IDS.TOKEN_END_POINT_URL,
        FIELD_IDS.USER_INFO_END_POINT_URL,
        FIELD_IDS.TOKEN_ISSUER,
        FIELD_IDS.DEFAULT_SCOPE,
        FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE,
        FIELD_IDS.CUSTOM_ERROR_URL,
        FIELD_IDS.CUSTOM_LOGOUT_URL,
        FIELD_IDS.REGISTRATION_HANDLER,
        FIELD_IDS.EXECUTE_REGISTRATION_AS,
        FIELD_IDS.PORTAL,
        FIELD_IDS.ICON_URL,
      ];
      break;
    }
    case PROVIDER_TYPES.MICROSOFT_ACCESS_CONTROL_SERVICE: {
      applicableFieldIdsForGivenProviderType = [
        ...applicableFieldIdsForGivenProviderType,
        FIELD_IDS.KEY,
        FIELD_IDS.SECRET,
        FIELD_IDS.AUTHORIZE_END_POINT_URL,
        FIELD_IDS.TOKEN_END_POINT_URL,
        FIELD_IDS.DEFAULT_SCOPE,
        FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE,
        FIELD_IDS.CUSTOM_ERROR_URL,
        FIELD_IDS.CUSTOM_LOGOUT_URL,
        FIELD_IDS.PORTAL,
        FIELD_IDS.ICON_URL,
      ];
      break;
    }
    case PROVIDER_TYPES.LINKEDIN: {
      applicableFieldIdsForGivenProviderType = [
        ...applicableFieldIdsForGivenProviderType,
        FIELD_IDS.KEY,
        FIELD_IDS.SECRET,
        FIELD_IDS.AUTHORIZE_END_POINT_URL,
        FIELD_IDS.TOKEN_END_POINT_URL,
        FIELD_IDS.USER_INFO_END_POINT_URL,
        FIELD_IDS.DEFAULT_SCOPE,
        FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE,
        FIELD_IDS.CUSTOM_ERROR_URL,
        FIELD_IDS.CUSTOM_LOGOUT_URL,
        FIELD_IDS.REGISTRATION_HANDLER,
        FIELD_IDS.EXECUTE_REGISTRATION_AS,
        FIELD_IDS.PORTAL,
        FIELD_IDS.ICON_URL,
      ];
      break;
    }
    case PROVIDER_TYPES.OPEN_ID: {
      applicableFieldIdsForGivenProviderType = [
        ...applicableFieldIdsForGivenProviderType,
        FIELD_IDS.KEY,
        FIELD_IDS.SECRET,
        FIELD_IDS.AUTHORIZE_END_POINT_URL,
        FIELD_IDS.TOKEN_END_POINT_URL,
        FIELD_IDS.USER_INFO_END_POINT_URL,
        FIELD_IDS.TOKEN_ISSUER,
        FIELD_IDS.DEFAULT_SCOPE,
        FIELD_IDS.SEND_ACCESS_TOKEN_IN_HEADER,
        FIELD_IDS.SEND_CLIENT_CREDS_IN_HEADER,
        FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE,
        FIELD_IDS.CUSTOM_ERROR_URL,
        FIELD_IDS.CUSTOM_LOGOUT_URL,
        FIELD_IDS.REGISTRATION_HANDLER,
        FIELD_IDS.EXECUTE_REGISTRATION_AS,
        FIELD_IDS.PORTAL,
        FIELD_IDS.ICON_URL,
      ];
      break;
    }
    case PROVIDER_TYPES.SLACK: {
      applicableFieldIdsForGivenProviderType = [
        ...applicableFieldIdsForGivenProviderType,
        FIELD_IDS.KEY,
        FIELD_IDS.SECRET,
        FIELD_IDS.AUTHORIZE_END_POINT_URL,
        FIELD_IDS.TOKEN_END_POINT_URL,
        FIELD_IDS.USER_INFO_END_POINT_URL,
        FIELD_IDS.DEFAULT_SCOPE,
        FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE,
        FIELD_IDS.CUSTOM_ERROR_URL,
        FIELD_IDS.CUSTOM_LOGOUT_URL,
        FIELD_IDS.REGISTRATION_HANDLER,
        FIELD_IDS.EXECUTE_REGISTRATION_AS,
        FIELD_IDS.PORTAL,
        FIELD_IDS.ICON_URL,
      ];
      break;
    }
    case PROVIDER_TYPES.TEKION: {
      applicableFieldIdsForGivenProviderType = [
        ...applicableFieldIdsForGivenProviderType,
        FIELD_IDS.KEY,
        FIELD_IDS.SECRET,
        FIELD_IDS.AUTHORIZE_END_POINT_URL,
        FIELD_IDS.TOKEN_END_POINT_URL,
        FIELD_IDS.USER_INFO_END_POINT_URL,
        FIELD_IDS.DEFAULT_SCOPE,
        FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE,
        FIELD_IDS.CUSTOM_ERROR_URL,
        FIELD_IDS.CUSTOM_LOGOUT_URL,
        FIELD_IDS.REGISTRATION_HANDLER,
        FIELD_IDS.EXECUTE_REGISTRATION_AS,
        FIELD_IDS.PORTAL,
        FIELD_IDS.ICON_URL,
      ];
      break;
    }
    case PROVIDER_TYPES.TWITTER: {
      applicableFieldIdsForGivenProviderType = [
        ...applicableFieldIdsForGivenProviderType,
        FIELD_IDS.KEY,
        FIELD_IDS.SECRET,
        FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE,
        FIELD_IDS.CUSTOM_ERROR_URL,
        FIELD_IDS.CUSTOM_LOGOUT_URL,
        FIELD_IDS.REGISTRATION_HANDLER,
        FIELD_IDS.EXECUTE_REGISTRATION_AS,
        FIELD_IDS.PORTAL,
        FIELD_IDS.ICON_URL,
      ];
      break;
    }
    case PROVIDER_TYPES.CUSTOM: {
      applicableFieldIdsForGivenProviderType = [
        ...applicableFieldIdsForGivenProviderType,
        FIELD_IDS.KEY,
        FIELD_IDS.SECRET,
        FIELD_IDS.AUTHORIZE_END_POINT_URL,
        FIELD_IDS.TOKEN_END_POINT_URL,
        FIELD_IDS.USER_INFO_END_POINT_URL,
        FIELD_IDS.DEFAULT_SCOPE,
        FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE,
        FIELD_IDS.CUSTOM_ERROR_URL,
        FIELD_IDS.CUSTOM_LOGOUT_URL,
        FIELD_IDS.REGISTRATION_HANDLER,
        FIELD_IDS.EXECUTE_REGISTRATION_AS,
        FIELD_IDS.PORTAL,
        FIELD_IDS.ICON_URL,
        FIELD_IDS.DEFAULT_SCOPES,
        FIELD_IDS.KEY_ID,
        FIELD_IDS.KEY_MEDIA_ID,
        FIELD_IDS.REGISTER_AS,
        FIELD_IDS.SEND_ACCESS_TOKEN_IN_HEADER,
        FIELD_IDS.SEND_CLIENT_CREDS_IN_HEADER,
        FIELD_IDS.TOKEN_END_POINT_URL,
        FIELD_IDS.SERVICE_ID,
        FIELD_IDS.TOKEN_ISSUER,
        FIELD_IDS.URL_SUFFIX,
      ];
      break;
    }
    default: {
      break;
    }
  }

  return applicableFieldIdsForGivenProviderType;
};

export { getFieldsBasedOnProviderType };
