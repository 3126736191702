import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import {
  addLoginConfigurations,
  fetchLoginConfigurations,
  updateLoginConfigurations,
  removeLoginConfigurations,
} from '../../../../../actions/loginConfigurations.actions';

import { getProviderTypeOptions } from './loginConfigurationsDashboard.helpers';
import ACTION_TYPES from '../constants/loginConfigurationsDashboard.actionTypes';
import { FIELD_IDS } from '../components/authConfigModal/helpers/authConfigModal.fields';

const handleClickAuthConfigTile = ({ params = {}, setState }) => {
  const { providerConfig } = params;
  setState({ authConfigFormValues: providerConfig, isAuthConfigModalVisible: true, selectedProviderConfig: providerConfig });
};

const handleFetchLoginConfigurations = async ({ setState }) => {
  setState({ isLoading: true });
  const loginConfigurations = await fetchLoginConfigurations();
  const providerConfigurations = tget(loginConfigurations, 'providerConfigurations', []);
  const providerConfigurationsByProviderType = _keyBy(providerConfigurations, 'providerType');
  const providerTypeOptions = getProviderTypeOptions(providerConfigurationsByProviderType);

  setState({ providerConfigurations, providerTypeOptions, isLoading: false });
};

const handleCloseAuthConfigModal = ({ setState }) => {
  setState({ isAuthConfigModalVisible: false });
};

const handleSubmitAuthConfig = async ({ setState, getState }) => {
  const { authConfigFormValues, selectedProviderConfig } = getState();

  setState({ isAuthConfigModalSubmitting: true });
  if (_isEmpty(selectedProviderConfig)) {
    await addLoginConfigurations(authConfigFormValues);
  } else {
    await updateLoginConfigurations(authConfigFormValues);
  }
  setState({ isAuthConfigModalSubmitting: false, isAuthConfigModalVisible: false }, () => handleFetchLoginConfigurations({ setState }));
};

const handleFieldChange = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { id, value } = params;
  const { authConfigFormValues } = getState();
  const updatedValues = { ...authConfigFormValues, [id]: value };
  setState({ authConfigFormValues: updatedValues });
};

const handleErrors = ({ params = EMPTY_OBJECT, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const handleOpenConfirmationDialogForRemoveConfig = ({ params, setState }) => {
  const { selectedProviderConfig } = params;
  setState({ isRemoveConfigConfirmationDialogVisible: true, selectedProviderConfig });
};

const handleRemoveConfiguration = async ({ getState, setState }) => {
  const { selectedProviderConfig } = getState();
  const providerType = tget(selectedProviderConfig, FIELD_IDS.PROVIDER_TYPE);
  await removeLoginConfigurations(providerType);
  setState({ isRemoveConfigConfirmationDialogVisible: false, selectedProviderConfig }, () => handleFetchLoginConfigurations({ setState }));
};

const handleCloseConfirmationDialogForRemoveConfig = ({ setState }) => {
  setState({ isRemoveConfigConfirmationDialogVisible: false, selectedProviderConfig: {} });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_CLICK_AUTH_CONFIG_TILE]: handleClickAuthConfigTile,
  [ACTION_TYPES.ON_FETCH_LOGIN_CONFIGURATIONS]: handleFetchLoginConfigurations,
  [ACTION_TYPES.ON_CLOSE_AUTH_CONFIG_MODAL]: handleCloseAuthConfigModal,
  [ACTION_TYPES.ON_SUBMIT_AUTH_CONFIG_MODAL]: handleSubmitAuthConfig,
  [ACTION_TYPES.ON_REMOVE_CONFIGURATION]: handleRemoveConfiguration,
  [ACTION_TYPES.ON_CLICK_REMOVE_ICON]: handleOpenConfirmationDialogForRemoveConfig,
  [ACTION_TYPES.ON_CLOSE_CONFIRMATION_DIALOG]: handleCloseConfirmationDialogForRemoveConfig,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
};

export default ACTION_HANDLERS;
