import { compose } from 'recompose';

import _property from 'lodash/property';
import _get from 'lodash/get';
import _nthArg from 'lodash/nthArg';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';

// Components
import ColumnConfig from '@tekion/tekion-components/organisms/inputTable/builders/ColumnConfig';
import CellConfig from '@tekion/tekion-components/organisms/inputTable/builders/CellConfig';
import SelectInputCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/SelectInputCellRenderer';
import NumberInputCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/NumberInputCellRenderer';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Constants
import { COLUMN_IDS } from '../constants/searchFieldsTable.general';

const COLUMN_WIDTHS = {
  [COLUMN_IDS.FIELD]: '26rem',
  [COLUMN_IDS.BOOST]: '26rem',
};

const fieldAccessor = _property(COLUMN_IDS.FIELD);
const boostAccessor = _property(COLUMN_IDS.BOOST);

const onOptionChange = compose(_property('value'), _nthArg(1));

const setGetNumberInput = (_, params) => params;

const getFieldOptions = (options, selectedOptions = EMPTY_ARRAY) =>
  _map(options, (field) => ({
    label: _get(field, 'label', ''),
    value: _get(field, 'value', ''),
    isDisabled: _includes(selectedOptions, _get(field, 'value')),
  }));

const mapFieldProps = (props, options, selectedOptions, isOptionDisabled) => {
  const value = _get(props, `rowData.${COLUMN_IDS.FIELD}`);
  return {
    validators: [isRequiredRule],
    required: true,
    options: getFieldOptions(options, selectedOptions),
    isDisabled: isOptionDisabled,
    error: _isEmpty(value) && !isOptionDisabled ? __('Please Select Search Field') : '',
  };
};

const mapBoostProps = (data = EMPTY_OBJECT) => {
  const value = _get(data, ['rowData', COLUMN_IDS.BOOST], '');
  return {
    value,
    placeholder: __('Enter Priority Number'),
    validators: [isRequiredRule],
    required: true,
  };
};

const getFieldCell = (options, selectedOptions, isOptionDisabled) =>
  new CellConfig()
    .setComponent(SelectInputCellRenderer)
    .setMapCellPropsToComponentProps((props) => mapFieldProps(props, options, selectedOptions, isOptionDisabled));

const BoostCell = new CellConfig().setComponent(NumberInputCellRenderer).setMapCellPropsToComponentProps((props) => mapBoostProps(props));

const getFieldColumnConfig = (searchableFieldOptions = EMPTY_ARRAY, selectedOptions = EMPTY_ARRAY, isOptionDisabled = false) =>
  new ColumnConfig(COLUMN_IDS.FIELD)
    .setHeader(__('Field'))
    .setAccessor(fieldAccessor)
    .addCellConfig(getFieldCell(searchableFieldOptions, selectedOptions, isOptionDisabled))
    .setGetValueFromOnChange(onOptionChange)
    .setWidth(COLUMN_WIDTHS[COLUMN_IDS.FIELD]);

const getBoostColumnConfig = () =>
  new ColumnConfig(COLUMN_IDS.BOOST)
    .setHeader(__('Boost'))
    .setAccessor(boostAccessor)
    .addCellConfig(BoostCell)
    .setGetValueFromOnChange(setGetNumberInput)
    .setWidth(COLUMN_WIDTHS[COLUMN_IDS.BOOST]);

const getColumns = (searchableFieldOptions, selectedSearchOptions) => {
  const FIELD_COLUMN_CONFIG = getFieldColumnConfig(searchableFieldOptions, selectedSearchOptions);
  const BOOST_COLUMN_CONFIG = getBoostColumnConfig();

  return [FIELD_COLUMN_CONFIG, BOOST_COLUMN_CONFIG];
};

export { getFieldColumnConfig, getBoostColumnConfig, getColumns };
