// Lodash
import _keyBy from 'lodash/keyBy';
import _mapValues from 'lodash/mapValues';

const PRIVATE = {
  id: 'PRIVATE',
  name: 'Private',
};

const PUBLIC_READONLY = {
  id: 'PUBLIC_READONLY',
  name: 'Public Read',
};

const PUBLIC_READ_WRITE = {
  id: 'PUBLIC_READ_WRITE',
  name: 'Public Read - Write',
};

const WWD_ACCESS_TYPES = [PRIVATE, PUBLIC_READONLY, PUBLIC_READ_WRITE];

const WWD_ACCESS_TYPES_BY_ID = _keyBy(WWD_ACCESS_TYPES, 'id');

const WWD_ACCESS_TYPE = _mapValues(WWD_ACCESS_TYPES, 'id');

export { PRIVATE, PUBLIC_READONLY, PUBLIC_READ_WRITE, WWD_ACCESS_TYPE };
export default WWD_ACCESS_TYPES_BY_ID;
