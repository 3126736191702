import _cloneDeep from 'lodash/cloneDeep';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import {
  CUSTOM_ROW_ACTION_ENABLE_SCRIPT_INITIAL_VALUE,
  CUSTOM_ROW_ACTION_INITIAL_EVENT_DATA,
  FIELD_IDS,
  CUSTOM_ROW_ACTION_INITIAL_DATA,
} from '../constants/customActionsConfigurator.constants';
import ACTION_TYPES from '../constants/customActionsConfigurator.actionTypes';
import { TAB_IDS } from '../components/customActionsConfiguratorModal/customActionsConfiguratorModal.constants';
import { FIELD_IDS as CUSTOM_ROW_ACTION_FIELD_IDS } from '../components/customRowActionsForm/customRowActionsForm.constants';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../../constants/viewBuilder.constants';

const handleSelectedViewComponentPropertiesChange = ({ setState, params }) => {
  const { selectedViewComponentProperties } = params;

  const actionsToShow = tget(selectedViewComponentProperties, ALL_VIEW_PROPERTY_KEYS.ACTIONS_TO_SHOW, EMPTY_ARRAY);

  const actionsFormValues = { [FIELD_IDS.ACTIONS_TO_SHOW]: actionsToShow || EMPTY_ARRAY };

  // Custom Row actions Data
  let customRowActionsData = tget(selectedViewComponentProperties, ALL_VIEW_PROPERTY_KEYS.CUSTOM_ACTIONS, EMPTY_ARRAY);

  if (_isEmpty(customRowActionsData)) {
    customRowActionsData = [{ [CUSTOM_ROW_ACTION_FIELD_IDS.EVENT_HANDLERS]: CUSTOM_ROW_ACTION_INITIAL_EVENT_DATA }];
  }

  const customRowActionsEnableScriptData = tget(customRowActionsData, CUSTOM_ROW_ACTION_FIELD_IDS.ENABLE_ACTION_SCRIPT);
  if (_isEmpty(customRowActionsEnableScriptData)) {
    const enableScriptRendererProps = [
      { type: CUSTOM_ROW_ACTION_FIELD_IDS.ENABLE_ACTION_SCRIPT, value: CUSTOM_ROW_ACTION_ENABLE_SCRIPT_INITIAL_VALUE },
    ];
    _set(customRowActionsData, CUSTOM_ROW_ACTION_FIELD_IDS.RENDERER_PROPS, enableScriptRendererProps);
  }

  setState({
    formValues: { [TAB_IDS.ACTION_BUILDER_ACTIONS_TAB]: actionsFormValues, [TAB_IDS.CUSTOM_ACTIONS_TAB]: customRowActionsData },
  });
};

const handleConfigureActionsClick = ({ getState, setState }) => {
  const { formValues = EMPTY_OBJECT } = getState();

  setState({
    prevState: {
      formValues,
    },
    isModalVisible: true,
  });
};

const handleOnChange = ({ getState, setState, params }) => {
  const { formValues } = getState();
  const { id, value } = params;

  const newFormValues = { ...formValues, [id]: value };

  setState({ formValues: newFormValues });
};

const handleSubmit = ({ getState, setState }) => {
  const { formValues, onAction: configuratorOnAction, isReadyToSubmit = false } = getState();

  if (isReadyToSubmit) {
    const actionsData = tget(formValues, TAB_IDS.ACTION_BUILDER_ACTIONS_TAB);
    const customRowActionsData = tget(formValues, TAB_IDS.CUSTOM_ACTIONS_TAB);

    const actionsToShow = tget(actionsData, [FIELD_IDS.ACTIONS_TO_SHOW], EMPTY_ARRAY);

    configuratorOnAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: ALL_VIEW_PROPERTY_KEYS.ACTIONS_TO_SHOW, value: actionsToShow },
    });

    configuratorOnAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: ALL_VIEW_PROPERTY_KEYS.CUSTOM_ACTIONS, value: customRowActionsData },
    });

    setState({ isModalVisible: false });
  } else {
    setState({ isReadyToSubmit: true });
  }
};

const handleCustomActionAddClick = ({ getState, setState }) => {
  const { formValues = {} } = getState();

  const customRowActionsData = tget(formValues, TAB_IDS.CUSTOM_ACTIONS_TAB, []);
  const newCustomRowActionsData = _cloneDeep(customRowActionsData);
  newCustomRowActionsData.push(CUSTOM_ROW_ACTION_INITIAL_DATA);

  const newFormValues = { ...formValues, [TAB_IDS.CUSTOM_ACTIONS_TAB]: newCustomRowActionsData };

  setState({ formValues: newFormValues, customRowActionsLeftPanelSelectedIndex: _size(newCustomRowActionsData) - 1 });
};

const handleActionsTabChange = ({ setState, params = EMPTY_STRING }) => {
  setState({ selectedTabId: params });
};

const handleTriggerSubmit = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const actionType = tget(params, 'additional.actionType');
  const value = tget(params, 'additional.value');

  switch (actionType) {
    case ACTION_TYPES.ON_CUSTOM_ACTION_LEFT_PANEL_CLICK: {
      setState({ customRowActionsLeftPanelSelectedIndex: value });
      break;
    }

    case ACTION_TYPES.ON_SUBMIT: {
      handleSubmit({ getState, setState });
      break;
    }

    case ACTION_TYPES.ON_CUSTOM_ACTION_ADD_CLICK: {
      handleCustomActionAddClick({ getState, setState });
      break;
    }

    case ACTION_TYPES.ON_ACTIONS_TAB_CHANGE: {
      handleActionsTabChange({ setState, params: value });
      break;
    }

    default: {
      break;
    }
  }
};

const handleErrors = ({ getState, setState, params }) => {
  const { id, value } = params;

  const { errors = {} } = getState();

  const newErrors = { ...errors, [id]: value };

  setState({ errors: newErrors });
};

const handleCancelModal = ({ getState, setState }) => {
  const { prevState = EMPTY_OBJECT } = getState();

  setState({ isModalVisible: false, ...prevState });
};

const handleCustomRowActionLeftPanelDeleteClick = ({ getState, setState, params }) => {
  const index = tget(params, 'index');

  const { formValues, customRowActionsLeftPanelSelectedIndex, errors } = getState();

  const customRowActionData = tget(formValues, TAB_IDS.CUSTOM_ACTIONS_TAB, []);
  const newCustomRowActionData = _filter(customRowActionData, (_, indexValue) => indexValue !== index);

  const newFormValues = { ...formValues, [TAB_IDS.CUSTOM_ACTIONS_TAB]: newCustomRowActionData };

  let newCustomRowActionsLeftPanelSelectedIndex = customRowActionsLeftPanelSelectedIndex;
  // last index value was selected & removed was also the same
  if (customRowActionsLeftPanelSelectedIndex === _size(newCustomRowActionData) && customRowActionsLeftPanelSelectedIndex !== 0) {
    newCustomRowActionsLeftPanelSelectedIndex = customRowActionsLeftPanelSelectedIndex - 1;
  }

  // Resetting error of custom action form as empty
  const newErrors = { ...errors, [TAB_IDS.CUSTOM_ACTIONS_TAB]: {} };

  setState({ formValues: newFormValues, customRowActionsLeftPanelSelectedIndex: newCustomRowActionsLeftPanelSelectedIndex, errors: newErrors });
};

const handleOnShowActionChange = ({ getState, params = EMPTY_OBJECT }) => {
  const { value } = params;
  const { onAction: configuratorOnAction } = getState();

  configuratorOnAction({
    type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
    payload: { id: ALL_VIEW_PROPERTY_KEYS.SHOW_ACTIONS, value },
  });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE]: handleSelectedViewComponentPropertiesChange,
  [ACTION_TYPES.ON_CLICK_CONFIGURE_ACTIONS]: handleConfigureActionsClick,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleTriggerSubmit,
  [ACTION_TYPES.ON_CANCEL_MODAL]: handleCancelModal,
  [ACTION_TYPES.ON_CUSTOM_ACTION_LEFT_PANEL_DELETE_CLICK]: handleCustomRowActionLeftPanelDeleteClick,
  [ACTION_TYPES.ON_SHOW_ACTIONS_CHANGE]: handleOnShowActionChange,
};

export default ACTION_HANDLERS;
