import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _isString from 'lodash/isString';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';

// Tekion-components
import PropertyControllerComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

// Constants
import { BASE_ROUTE } from '../../../../constants/routes';

// Styles
import styles from './treeNode.module.scss';

const TreeNode = (props) => {
  const { nodeData, level, onItemClick, showChildren, isActive, activeDefaultLevel, activeSiblingNumber } = props;
  const [isChildrenVisible, setChildrenVisibility] = useState(showChildren);
  const activeStyle = isActive ? styles.active : '';

  useEffect(() => {
    setChildrenVisibility(showChildren);
  }, [showChildren]);

  const hasChildren = !_isEmpty(_get(nodeData, 'children', EMPTY_STRING));
  const label = _get(nodeData, 'label', NO_DATA);
  const icon = _get(nodeData, 'icon', null);
  const goTo = _get(nodeData, 'goTo', BASE_ROUTE);
  const state = _get(nodeData, 'state', EMPTY_OBJECT);

  const onClick = useCallback(() => {
    onItemClick(goTo, state);
  }, [onItemClick, goTo, state]);

  const getTreeNode = useCallback(
    () =>
      _map(_get(nodeData, 'children', EMPTY_ARRAY), (node, index) => (
        <TreeNode
          nodeData={node}
          key={_get(node, 'key', EMPTY_STRING)}
          level={level + 1}
          onItemClick={onItemClick}
          showChildren={showChildren}
          isActive={activeDefaultLevel === 1 && activeSiblingNumber === index + 1}
          activeDefaultLevel={activeDefaultLevel - 1}
          activeSiblingNumber={activeSiblingNumber}
        />
      )),
    [nodeData, level, onItemClick, showChildren, activeDefaultLevel, activeSiblingNumber],
  );

  const getIcon = useCallback(() => (_isString(icon) ? <FontIcon>{icon}</FontIcon> : icon), [icon]);

  return (
    <div className={styles.container}>
      <div onClick={onClick} className={styles.node}>
        <PropertyControllerComponent controllerProperty={level > 0}>
          <div className={styles.line} />
        </PropertyControllerComponent>
        <div className={activeStyle}>{getIcon()}</div>
        <div className={activeStyle}>{label}</div>
      </div>
      <PropertyControllerComponent controllerProperty={hasChildren && isChildrenVisible}>
        <div className={level > 0 ? styles.nodeSubChildren : styles.nodeChildren}>{getTreeNode()}</div>
      </PropertyControllerComponent>
    </div>
  );
};

TreeNode.propTypes = {
  nodeData: PropTypes.object,
  level: PropTypes.number,
  onItemClick: PropTypes.func,
  showChildren: PropTypes.bool,
  isActive: PropTypes.bool,
  activeDefaultLevel: PropTypes.number,
  activeSiblingNumber: PropTypes.number,
};

TreeNode.defaultProps = {
  nodeData: EMPTY_OBJECT,
  level: 0,
  onItemClick: _noop,
  showChildren: false,
  isActive: false,
  activeDefaultLevel: 0,
  activeSiblingNumber: 0,
};

export default TreeNode;
