// Lodash

import _split from 'lodash/split';
import _size from 'lodash/size';
import _get from 'lodash/get';
import _nth from 'lodash/nth';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import DATA_TYPES from '../../../constants/fieldDefinition.dataTypes';
import fieldDefinitionReader from '../../../readers/fieldDefinition.reader';

export const getParsedValue = (value) => {
  let parsedValue = value;
  try {
    parsedValue = JSON.parse(value);
  } catch {
    parsedValue = value;
  }
  return parsedValue;
};

export const recursiveFunctionForAttributeFieldDef = (attribute, fieldDefinitionByName) => {
  const attributeArray = _split(attribute, '.');
  if (_size(attributeArray) === 1) {
    return _get(fieldDefinitionByName, getArraySafeValue(attributeArray));
  } else {
    const fieldName = _nth(attributeArray, 1);
    const fieldDef = _get(fieldDefinitionByName, fieldName);
    if (fieldDefinitionReader.dataType(fieldDef) === DATA_TYPES.COMPLEX) {
      if (_size(attributeArray) > 2) {
        return recursiveFunctionForAttributeFieldDef(_nth(attributeArray, 2), fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef));
      } else {
        return fieldDef;
      }
    }
    return fieldDef;
  }
};
