const CONTEXT_ID = 'SUBSCRIPTION_FORM_MODAL';

const SUBSCRIPTION_EVENT_TYPES = {
  RECORD_CREATE: 'RECORD_CREATE',
  RECORD_UPDATE: 'RECORD_UPDATE',
};

const SUBSCRIPTION_EVENT_TYPE_OPTIONS = [
  { label: __('Record Create'), value: SUBSCRIPTION_EVENT_TYPES.RECORD_CREATE },
  { label: __('Record update'), value: SUBSCRIPTION_EVENT_TYPES.RECORD_UPDATE },
];

export { CONTEXT_ID, SUBSCRIPTION_EVENT_TYPE_OPTIONS };
