import { useMemo } from 'react';
import { Editor, Node } from 'slate';

// constants
import FIELD_IDS from '../constants/linkInsertionForm.fieldIds';

const getInitialFormValues = (editor, selectedLinkNode) => {
  if (selectedLinkNode) {
    const [selectedLinkNodeElement] = selectedLinkNode;
    return {
      [FIELD_IDS.TITLE]: Node.string(selectedLinkNodeElement),
      [FIELD_IDS.URL]: selectedLinkNodeElement?.url,
      [FIELD_IDS.SHOULD_OPEN_IN_NEW_TAB]: selectedLinkNodeElement?.shouldOpenInNewTab,
    };
  }
  const text = Editor.string(editor, editor.selection);
  return {
    [FIELD_IDS.TITLE]: text,
  };
};

function useFormInitialValues(editor, selectedLinkNode) {
  const initialValues = useMemo(() => getInitialFormValues(editor, selectedLinkNode), [editor, selectedLinkNode]);

  return initialValues;
}

export default useFormInitialValues;
