import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import TASK_TYPES from '@tekion/tekion-rule-engine/src/constants/ruleEngineWorkflow.taskTypes';
import { stepsIteratorWithCallback } from '@tekion/tekion-rule-engine/src/utils/core/crudOperations';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { getValidatedData } from '../../../../../../actions/processBuilder.actions';
import { TASK_DEF_IDS, VALIDATION_TYPES, CONDITION_TYPES } from '../constants/workflow.constants';
import { DROPDOWN_MENU } from '../constants/workflowBuilder.constants';

const getFlatNodeList = (workflow) => {
  // purpose of this function is to sanitise workflow before sending it to backend
  // we remove our custom step added while creating the data
  // remove if there is any ghost nodes
  // In future we can do workflow validation here

  const currentRootNodeId = tget(workflow, 'rootNodeId');
  const currentNodeList = tget(workflow, 'nodeList');
  const newRootNodeId = tget(workflow, `nodeList[${currentRootNodeId}].nextNodeId`);
  const newNodeList = [];
  const setNodeList = (_, n) => newNodeList.push(n);

  stepsIteratorWithCallback(currentNodeList, newRootNodeId, setNodeList);

  return {
    ...workflow,
    rootNodeId: newRootNodeId,
    nodeList: newNodeList,
  };
};

const generateValidationAction = (workflow) => () => {
  const flatNodeList = getFlatNodeList(workflow);
  if (!_isEmpty(flatNodeList?.nodeList)) {
    return getValidatedData(VALIDATION_TYPES.REALTIME, flatNodeList);
  }
  return EMPTY_OBJECT;
};

const getDropdownOptions = (nodeData) => {
  const taskType = tget(nodeData, 'uiMetadata.taskType');
  const conditionType = tget(nodeData, 'uiMetadata.conditionType');
  const taskDefId = tget(nodeData, 'taskDefId');

  if (taskType === TASK_TYPES.EVENT) {
    return [];
  }

  if (taskType === TASK_TYPES.CONDITION && conditionType === CONDITION_TYPES.IF) {
    return [DROPDOWN_MENU.EDIT, DROPDOWN_MENU.DELETE, DROPDOWN_MENU.CHANGE_TO_IF_ELSE, DROPDOWN_MENU.CHANGE_TO_CHECK];
  }
  if (taskType === TASK_TYPES.CONDITION && conditionType === CONDITION_TYPES.IF_ELSE) {
    return [DROPDOWN_MENU.EDIT, DROPDOWN_MENU.DELETE, DROPDOWN_MENU.CHANGE_TO_IF, DROPDOWN_MENU.CHANGE_TO_CHECK];
  }
  if (taskType === TASK_TYPES.CONDITION && conditionType === CONDITION_TYPES.SWITCH) {
    if (taskDefId === TASK_DEF_IDS.DELAY_TASK) {
      return [DROPDOWN_MENU.EDIT, DROPDOWN_MENU.DELETE];
    }
    return [DROPDOWN_MENU.EDIT, DROPDOWN_MENU.DELETE, DROPDOWN_MENU.CHANGE_TO_IF, DROPDOWN_MENU.CHANGE_TO_IF_ELSE];
  }
  if (taskDefId === TASK_DEF_IDS.REPEAT_NODE) {
    return [DROPDOWN_MENU.EDIT, DROPDOWN_MENU.DELETE, DROPDOWN_MENU.UNLINK_CONNECTOR_PATH];
  }
  if (taskDefId === TASK_DEF_IDS.GOTO) {
    return [DROPDOWN_MENU.DELETE, DROPDOWN_MENU.UNLINK_CONNECTOR_PATH];
  }
  if (taskDefId === TASK_DEF_IDS.LOOP_CONTINUE) {
    return [DROPDOWN_MENU.CHANGE_TO_LOOP_EXIT];
  }
  if (taskDefId === TASK_DEF_IDS.LOOP_EXIT) {
    return [DROPDOWN_MENU.CHANGE_TO_LOOP_CONTINUE];
  }
  if (taskDefId === TASK_DEF_IDS.SET_VARIABLE) {
    return [DROPDOWN_MENU.DELETE];
  }
  return [DROPDOWN_MENU.EDIT, DROPDOWN_MENU.DELETE];
};

export { getDropdownOptions, generateValidationAction };
