import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

const HEADER_PROPS = {
  hasBack: true,
};

const DEFAULT_USER_FORM_PROPS = {
  values: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

const USER_FORM_CONTEXT_ID = 'USER_MANAGEMENT_FORM';

const ROLE_OPTIONS = [
  { label: __('Primary'), value: 'primary' },
  { label: __('Admin'), value: 'admin' },
];

export { ROLE_OPTIONS, USER_FORM_CONTEXT_ID, DEFAULT_USER_FORM_PROPS, HEADER_PROPS };
