import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import FormSection from '../../atoms/formSection/FormSection';
import ViewComponent from '../../atoms/viewComponent/ViewComponent';
import DropZone from '../../atoms/dropZone/DropZone';
import DropZoneContainer from '../../atoms/dropZone/DropZoneContainer';

import { COMPONENT_TYPES } from '../../constants/viewBuilder.constants';

import styles from './formBuilderViewRenderer.module.scss';

const FormBuilderViewRenderer = (props) => {
  const {
    className,
    componentConfig: { sectionId, children },
    entity,
  } = props;

  return (
    <div key={sectionId} className={cx(styles.formBuilderViewRenderer, className)}>
      {children.map((formSection, index) => (
        <React.Fragment key={formSection.sectionId}>
          <DropZone
            id={sectionId}
            index={index}
            acceptedComponentTypes={[COMPONENT_TYPES.FORM_VIEW_SECTION]}
            className={styles.dropZoneGutter}
            hiddenDropZoneClassName={styles.hiddenDropZone}
            onHoverClassName={styles.onHoverDropZoneGutter}
          />
          <ViewComponent key={formSection.sectionId} index={index} componentConfig={formSection}>
            <FormSection componentConfig={formSection} entity={entity} />
          </ViewComponent>
        </React.Fragment>
      ))}

      <DropZoneContainer
        id={sectionId}
        className={styles.dropZoneContainer}
        onHoverClassName={styles.onHoverDropZoneGutter}
        index={children.length}
        acceptedComponentTypes={[COMPONENT_TYPES.FORM_VIEW_SECTION]}
      />
    </div>
  );
};

FormBuilderViewRenderer.propTypes = {
  className: PropTypes.string,
  componentConfig: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
};

FormBuilderViewRenderer.defaultProps = {
  className: '',
};

export default React.memo(FormBuilderViewRenderer);
