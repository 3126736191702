exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".notificationDrawer_drawer__avAHnp59yt .ant-drawer-wrapper-body{overflow:revert !important}.notificationDrawer_notifications__8a5VSqaPjk{height:1rem;width:10rem}.notificationDrawer_header__hqXc46SSzN{display:flex;justify-content:space-between;align-items:center}[dir] .notificationDrawer_header__hqXc46SSzN{padding:2rem 2.4rem;background-color:#edeef0}[dir=ltr] .notificationDrawer_closeIcon__jN5i9EsjJp{margin:.4rem 0 0 1.2rem}[dir=rtl] .notificationDrawer_closeIcon__jN5i9EsjJp{margin:.4rem 1.2rem 0 0}[dir] .notificationDrawer_closeIcon__jN5i9EsjJp:hover{cursor:pointer}.notificationDrawer_tabs__vCHFxdqmGS{height:100%}.notificationDrawer_tabs__vCHFxdqmGS .ant-tabs-tab{color:#444f5c;font-size:1.6rem;font-family:\"Proxima-Nova-Semibold\"}[dir] .notificationDrawer_tabs__vCHFxdqmGS .ant-tabs-tab{margin:0 !important;padding:1.2rem 0 !important}[dir=ltr] .notificationDrawer_tabs__vCHFxdqmGS .ant-tabs-tab:first-child{margin-left:2.4rem !important}[dir=rtl] .notificationDrawer_tabs__vCHFxdqmGS .ant-tabs-tab:first-child{margin-right:2.4rem !important}[dir=ltr] .notificationDrawer_tabs__vCHFxdqmGS .ant-tabs-tab:not(:first-child){margin-left:3.2rem !important}[dir=rtl] .notificationDrawer_tabs__vCHFxdqmGS .ant-tabs-tab:not(:first-child){margin-right:3.2rem !important}[dir=ltr] .notificationDrawer_tabs__vCHFxdqmGS .ant-tabs-tab:last-child{margin-right:2.4rem !important}[dir=rtl] .notificationDrawer_tabs__vCHFxdqmGS .ant-tabs-tab:last-child{margin-left:2.4rem !important}.notificationDrawer_tabs__vCHFxdqmGS .ant-tabs-nav{height:5rem}.notificationDrawer_tabs__vCHFxdqmGS .ant-tabs-content{height:100%}", ""]);

// Exports
exports.locals = {
	"drawer": "notificationDrawer_drawer__avAHnp59yt",
	"notifications": "notificationDrawer_notifications__8a5VSqaPjk",
	"header": "notificationDrawer_header__hqXc46SSzN",
	"closeIcon": "notificationDrawer_closeIcon__jN5i9EsjJp",
	"tabs": "notificationDrawer_tabs__vCHFxdqmGS"
};