import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import COLUMN_IDS from '../constants/userGroupsSetting.columnIds';
import { PERMISSION_USER_GROUP_STATUS_COLOR_MAP, PERMISSION_USER_GROUP_STATUS_LABEL_MAP } from '../constants/userGroupsSetting.general';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const getStatusProps = () => ({
  labelMap: PERMISSION_USER_GROUP_STATUS_LABEL_MAP,
  colorMap: PERMISSION_USER_GROUP_STATUS_COLOR_MAP,
});

const COLUMN_CONFIG = [
  {
    id: COLUMN_IDS.USER_GROUP_NAME,
    Header: __('Group Name'),
    accessor: COLUMN_IDS.USER_GROUP_NAME,
    Cell: TextRenderer,
  },
  {
    id: COLUMN_IDS.STATUS,
    Header: __('Status'),
    accessor: COLUMN_IDS.STATUS,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
];

export { COLUMN_CONFIG };
