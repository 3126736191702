// Tekion-components
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import { WORKFLOW_LIST_COLUMN_IDS, RULE_LIST_COLUMN_IDS } from '../constants/workflowList.columnIds';
import { WORKFLOW_STATUS_LABEL_MAP, WORKFLOW_STATUS_COLOR_MAP } from '../constants/workflowList.general';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const getStatusProps = () => ({
  labelMap: WORKFLOW_STATUS_LABEL_MAP,
  colorMap: WORKFLOW_STATUS_COLOR_MAP,
});

const WORKFLOW_LIST_COLUMNS = [
  {
    Header: __('Process name'),
    id: WORKFLOW_LIST_COLUMN_IDS.WORKFLOW_NAME,
    accessor: WORKFLOW_LIST_COLUMN_IDS.WORKFLOW_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Description'),
    id: WORKFLOW_LIST_COLUMN_IDS.DESCRIPTION,
    accessor: WORKFLOW_LIST_COLUMN_IDS.DESCRIPTION,
    Cell: TextRenderer,
  },
  {
    Header: __('Entity'),
    id: WORKFLOW_LIST_COLUMN_IDS.ENTITY_NAME,
    accessor: WORKFLOW_LIST_COLUMN_IDS.ENTITY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Event type'),
    id: WORKFLOW_LIST_COLUMN_IDS.EVENT_TYPE,
    accessor: WORKFLOW_LIST_COLUMN_IDS.EVENT_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Status'),
    id: WORKFLOW_LIST_COLUMN_IDS.STATUS,
    accessor: WORKFLOW_LIST_COLUMN_IDS.STATUS,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
];

const RULE_LIST_COLUMNS = [
  {
    Header: __('Rule name'),
    id: RULE_LIST_COLUMN_IDS.WORKFLOW_NAME,
    accessor: RULE_LIST_COLUMN_IDS.WORKFLOW_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Description'),
    id: RULE_LIST_COLUMN_IDS.DESCRIPTION,
    accessor: RULE_LIST_COLUMN_IDS.DESCRIPTION,
    Cell: TextRenderer,
  },
  {
    Header: __('Entity'),
    id: RULE_LIST_COLUMN_IDS.ENTITY_NAME,
    accessor: RULE_LIST_COLUMN_IDS.ENTITY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Event type'),
    id: RULE_LIST_COLUMN_IDS.EVENT_TYPE,
    accessor: RULE_LIST_COLUMN_IDS.EVENT_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Status'),
    id: RULE_LIST_COLUMN_IDS.STATUS,
    accessor: RULE_LIST_COLUMN_IDS.STATUS,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Priority'),
    id: RULE_LIST_COLUMN_IDS.PRIORITY,
    accessor: RULE_LIST_COLUMN_IDS.PRIORITY,
    Cell: TextRenderer,
  },
];

export { WORKFLOW_LIST_COLUMNS, RULE_LIST_COLUMNS };
