exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".fieldValuePair_stageFieldContainer__guWFWsBefG{display:flex;align-items:center}[dir=ltr] .fieldValuePair_stageFieldContainer__guWFWsBefG{margin-right:1.6rem}[dir=rtl] .fieldValuePair_stageFieldContainer__guWFWsBefG{margin-left:1.6rem}.fieldValuePair_stageFieldContainer__guWFWsBefG .fieldValuePair_icon__7WbV4XtfsK{color:#969aa3}[dir] .fieldValuePair_stageFieldContainer__guWFWsBefG .fieldValuePair_icon__7WbV4XtfsK{padding:.4rem}.fieldValuePair_stageFieldContainer__guWFWsBefG .fieldValuePair_label__wosERHagH9{color:#969aa3}[dir] .fieldValuePair_stageFieldContainer__guWFWsBefG .fieldValuePair_label__wosERHagH9{padding:.4rem}.fieldValuePair_stageFieldContainer__guWFWsBefG .fieldValuePair_value__pBTdFXrLwP{font-weight:600}[dir] .fieldValuePair_stageFieldContainer__guWFWsBefG .fieldValuePair_value__pBTdFXrLwP{padding:.4rem}", ""]);

// Exports
exports.locals = {
	"stageFieldContainer": "fieldValuePair_stageFieldContainer__guWFWsBefG",
	"icon": "fieldValuePair_icon__7WbV4XtfsK",
	"label": "fieldValuePair_label__wosERHagH9",
	"value": "fieldValuePair_value__pBTdFXrLwP"
};