import React from 'react';

import { mapProps } from 'recompose';
import _get from 'lodash/get';

// Cell Renderers
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import Icon from '@tekion/tekion-components/atoms/FontIcon';

import CheckboxCellRenderer from './renderers/CheckboxCellRenderer';
import InputCellRenderer from './renderers/InputCellRenderer';
import SelectInputRenderer from './renderers/SelectInputCellRenderer';
import ResourceFieldRenderer from './renderers/ResourceFieldRenderer';
import IconRenderer from './renderers/IconRenderer';

import { COLUMN_IDS } from './columnConfig.general.constants';
import { ADD_WIDGET_FORM_FIELDS } from '../../constants/addWidget.general.constant';

const DraggerIcon = () => <Icon size={Icon.SIZES.S}>icon-drag-and-drop</Icon>;
const DraggerIconCellRenderer = makeCellRenderer(DraggerIcon);

const COLUMN_CONFIG_BY_KEY = {
  [COLUMN_IDS.COLUMN_DISPLAY_NAME]: {
    Header: __('Column Header'),
    id: COLUMN_IDS.COLUMN_DISPLAY_NAME,
    accessor: COLUMN_IDS.COLUMN_DISPLAY_NAME,
    Cell: InputCellRenderer,
  },
  [COLUMN_IDS.SELECT_FIELD]: {
    id: COLUMN_IDS.SELECT_FIELD,
    accessor: COLUMN_IDS.SELECT_FIELD,
    Cell: TextRenderer,
  },
  [COLUMN_IDS.FILTERABLE]: {
    Header: __('Filterable'),
    id: COLUMN_IDS.FILTERABLE,
    accessor: COLUMN_IDS.FILTERABLE,
    Cell: CheckboxCellRenderer,
  },
};

const getColumns = (showFilterColumn, options, fieldTitle, selectedFieldValues, metrics, fieldId) => {
  let selectFieldColumn = {
    ...COLUMN_CONFIG_BY_KEY[COLUMN_IDS.SELECT_FIELD],
    Header: __(`${fieldTitle}`),
    minWidth: 100,
    Cell: mapProps((props) => ({ ...props, options, selectedFieldValues }))(SelectInputRenderer),
  };

  if (fieldId === ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS) {
    const mapOfVariableToEntityName = _get(metrics, 'mapOfVariableToEntityName', {});
    const conditionBuilderFieldDefinitionObject = _get(metrics, 'conditionBuilderFieldDefinitionObject', {});
    selectFieldColumn = {
      ...selectFieldColumn,
      Cell: mapProps((props) => ({
        ...props,
        mapOfVariableToEntityName,
        conditionBuilderFieldDefinitionObject,
        isRelationshipRecursive: false,
      }))(ResourceFieldRenderer),
    };
  }
  const columns = [{ ...selectFieldColumn }, { ...COLUMN_CONFIG_BY_KEY[COLUMN_IDS.COLUMN_DISPLAY_NAME] }];

  if (showFilterColumn) {
    columns.push({
      ...COLUMN_CONFIG_BY_KEY[COLUMN_IDS.FILTERABLE],
      Cell: mapProps((props) => ({ ...props, options, selectedFieldValues }))(CheckboxCellRenderer),
      minWidth: 60,
    });
  }

  return columns;
};

const getDraggableColumns = (showFilterColumn, options, fieldTitle, selectedFieldValues, metrics, fieldId) => [
  {
    id: COLUMN_IDS.FIELD_DRAGGER_ICON,
    resizable: false,
    Cell: DraggerIconCellRenderer,
    width: 40,
  },
  ...getColumns(showFilterColumn, options, fieldTitle, selectedFieldValues, metrics, fieldId),
  {
    id: COLUMN_IDS.DELETE,
    key: COLUMN_IDS.DELETE,
    accessor: COLUMN_IDS.DELETE,
    Header: __('Action'),
    Cell: IconRenderer,
    sortable: false,
    resizable: false,
    fixed: 'right',
    minWidth: 25,
  },
];

export { getDraggableColumns };
