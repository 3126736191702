import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { NO_DATA } from '@tekion/tekion-base/app.constants';

import { FIELD_IDS, ROWS } from '../constants/serverCredentialForm.constants';

const getPayloadForExternalCredential = ({ nextPageToken }) => ({
  limit: 0,
  rows: ROWS,
  nextPageToken: _isEmpty(nextPageToken) ? null : nextPageToken,
});

const getValueOptionForExternalCredential = (externalCredential) => ({
  label: __(`${tget(externalCredential, [FIELD_IDS.DISPLAY_NAME], NO_DATA)}`),
  value: tget(externalCredential, 'name'),
});

const getExternalCredentialOptions = (data) => _map(data, getValueOptionForExternalCredential);

export { getPayloadForExternalCredential, getExternalCredentialOptions };
