import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';

// Components
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';
import withSize from '../../../../../connectors/withSize';

// Table Helpers
import TABLE_MANAGER_PROPS from './helpers/permissionSetList.tableManagerProps';
import SUB_HEADER_PROPS from './helpers/permissionSetList.subHeaderProps';
import PERMISSION_SET_LIST_COLUMNS from './helpers/permissionSetList.columns';
import { getTableProps } from './helpers/permissionSetList.helpers';
import HEADER_PROPS from './helpers/permissionSetList.headerProps';
import ACTION_HANDLERS from './helpers/permissionSetList.actionHandlers';

// Constants
import ACTION_TYPES from './constants/permissionSetList.actionTypes';
import {
  TABLE_CONSTANTS,
  INITIAL_STATE_PERMISSION_LIST_PROPS,
  DELETE_PERMISSION_CONFIRMATION_CONTENT,
} from './constants/permissionSetList.general.constants';

const PermissionSetList = ({
  loading,
  isConfirmationModalVisible,
  isPermissionDeleting,
  contentHeight,
  pageSize,
  currentPage,
  confirmationModalTitle,
  searchText,
  nextPageToken,
  totalNumberOfEntries,
  permissions,
  onAction,
}) => {
  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_FETCH_PERMISSION_DATA });
  }, [onAction]);

  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({
        type: actionType,
        payload,
      });
    },
    [onAction],
  );
  const tableProps = useMemo(
    () => getTableProps(handleRowAction, currentPage, loading, pageSize, totalNumberOfEntries),
    [handleRowAction, currentPage, pageSize, loading, totalNumberOfEntries],
  );

  const handleConfirmationCancelClick = () => {
    onAction({ type: ACTION_TYPES.DELETE_PERMISSION_CONFIRMATION_CANCEL_CLICK });
  };

  const handleConfirmationDoneClick = () => {
    onAction({ type: ACTION_TYPES.DELETE_PERMISSION_CONFIRMATION_DONE_CLICK });
  };

  return (
    <div style={{ height: contentHeight }}>
      <TableManager
        tokenPagination
        columns={PERMISSION_SET_LIST_COLUMNS}
        tableManagerProps={TABLE_MANAGER_PROPS}
        subHeaderProps={SUB_HEADER_PROPS}
        headerProps={HEADER_PROPS}
        pageSize={pageSize}
        searchText={searchText}
        nextPageToken={nextPageToken}
        data={permissions}
        tableProps={tableProps}
        onAction={onAction}
      />
      <ConfirmationDialog
        isVisible={isConfirmationModalVisible}
        loading={isPermissionDeleting}
        title={confirmationModalTitle}
        content={DELETE_PERMISSION_CONFIRMATION_CONTENT}
        onCancel={handleConfirmationCancelClick}
        onSubmit={handleConfirmationDoneClick}
      />
    </div>
  );
};

PermissionSetList.propTypes = {
  loading: PropTypes.bool,
  isConfirmationModalVisible: PropTypes.bool,
  isPermissionDeleting: PropTypes.bool,
  contentHeight: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  confirmationModalTitle: PropTypes.string,
  searchText: PropTypes.string,
  nextPageToken: PropTypes.string,
  totalNumberOfEntries: PropTypes.number,
  permissions: PropTypes.array,
  onAction: PropTypes.func,
};

PermissionSetList.defaultProps = {
  loading: true,
  isConfirmationModalVisible: false,
  isPermissionDeleting: false,
  contentHeight: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  currentPage: DEFAULT_PAGE_INFO.start,
  confirmationModalTitle: EMPTY_STRING,
  searchText: EMPTY_STRING,
  nextPageToken: EMPTY_STRING,
  totalNumberOfEntries: TABLE_CONSTANTS.TOTAL_NUMBER_OF_ENTRIES,
  permissions: EMPTY_ARRAY,
  onAction: _noop,
};

export default compose(
  withSize({ hasPageFooter: 0, hasPageHeader: 0 }),
  withActions(INITIAL_STATE_PERMISSION_LIST_PROPS, ACTION_HANDLERS),
  React.memo,
)(PermissionSetList);
