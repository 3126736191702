import _get from 'lodash/get';
import _head from 'lodash/head';
import _map from 'lodash/map';
import _has from 'lodash/has';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _castArray from 'lodash/castArray';
import _includes from 'lodash/includes';
import _values from 'lodash/values';
import _unset from 'lodash/unset';
import _set from 'lodash/set';
import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import updateElementAtIndex from '@tekion/tekion-base/utils/updateElementAtIndex';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { fetchEntityDefByName } from '../../../../../../actions/entityManagement.actions';
import { editPermissionByName, getPermissionByName } from '../../../../../../actions/permissionSetManagement.action';
import { getDisabledFieldPermissionIds, getModifiedColumnHeaderCheckbox, getPayload } from './entityFieldPermissionsConfigure.helpers';

import { ACTION_TYPES } from '../constants/entityFieldPermissionsConfigure.actionTypes';
import { COLUMN_IDS } from '../components/entityFieldPermissionTable/constants/entityFieldPermissionTable.constants';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import { TABS } from '../../permissionSetConfigure/constants/permissionSetConfigure.constants';

const setInitialFormValues = async ({ setState, getState }) => {
  const { match, history, columnHeaderCheckbox } = getState();
  setState({ isDataLoading: true });
  const permissionName = _get(match, 'params.permissionName');
  const entityName = _get(match, 'params.entityName');

  let entityFieldPermissionData = {};
  let trieEntityFieldPermissions = {};

  if (!_has(history, 'location.state.entityPermissionData')) {
    const allEntityFieldPermission = await getPermissionByName(permissionName);
    let entityAccess = _get(allEntityFieldPermission, 'entityPermission.entityAccess', []);
    let fieldAccess = _get(allEntityFieldPermission, 'fieldPermission.entityFieldsAccesses', []);
    entityAccess = _keyBy(entityAccess, COLUMN_IDS.ENTITY_NAME);
    fieldAccess = _keyBy(fieldAccess, 'entityName');

    _forEach(entityAccess, (access, entity) => {
      const fieldAccessMap = _get(fieldAccess, `${entity}.fieldAccesses`, []);
      _keyBy(fieldAccessMap, 'fieldName');
      _set(trieEntityFieldPermissions, `${entity}.entityAccess`, access);
      _set(trieEntityFieldPermissions, `${entity}.fieldAccessMap`, fieldAccessMap);
    });
    entityFieldPermissionData = _get(trieEntityFieldPermissions, entityName);
  } else {
    trieEntityFieldPermissions = _get(history, 'location.state.trieEntityFieldPermissions', EMPTY_ARRAY);
    entityFieldPermissionData = _get(history, 'location.state.entityPermissionData', EMPTY_ARRAY);
  }

  const entityDefinition = await fetchEntityDefByName(entityName);
  const fieldDefinitions = _get(entityDefinition, 'fieldDefinitions');
  let fieldPermissions = _get(entityFieldPermissionData, 'fieldAccessMap');

  _forEach(fieldDefinitions, (fieldDef) => {
    const fieldName = _get(fieldDef, 'name');
    if (_isEmpty(_get(fieldPermissions, fieldName))) {
      fieldPermissions[fieldName] = { fieldName, actionTypes: [], recordTypeName: entityName };
    }
  });
  fieldPermissions = _values(fieldPermissions);
  const modifiedColumnHeaderCheckbox = getModifiedColumnHeaderCheckbox(columnHeaderCheckbox, fieldPermissions);
  const entityActionTypes = _get(entityFieldPermissionData, 'entityAccess.actionTypes');
  const disabledFieldPermissionIds = getDisabledFieldPermissionIds(entityActionTypes);
  const permissionData = _get(history, 'location.state.permissionData', EMPTY_OBJECT);

  setState({
    entityPermissions: _castArray(_get(entityFieldPermissionData, 'entityAccess')),
    fieldPermissions,
    trieEntityFieldPermissions,
    columnHeaderCheckbox: modifiedColumnHeaderCheckbox,
    disabledFieldPermissionIds,
    isDataLoading: false,
    permissionData,
  });
};

const handleSubmit = async ({ getState, setState }) => {
  const { fieldPermissions, entityPermissions, trieEntityFieldPermissions, match, history, disabledFieldPermissionIds, permissionData } = getState();
  setState({ isSaveLoading: true });
  const permissionName = _get(match, 'params.permissionName');
  const entityName = _get(match, 'params.entityName');
  const modifiedTrieEntityFieldPermissions = { ...trieEntityFieldPermissions };
  const entityPermissionByEntityName = _get(modifiedTrieEntityFieldPermissions, entityName);

  _set(entityPermissionByEntityName, 'entityAccess', entityPermissions[0]);

  _forEach(fieldPermissions, (field) => {
    const fieldActionTypes = _get(field, 'actionTypes');
    const modifiedFieldActionTypes = _filter(fieldActionTypes, (actionType) => !_includes(disabledFieldPermissionIds, actionType));
    const fieldAccessMap = _get(entityPermissionByEntityName, 'fieldAccessMap');
    const modifiedFieldPermission = { ...field };
    _set(modifiedFieldPermission, 'actionTypes', modifiedFieldActionTypes);
    const fieldName = _get(field, COLUMN_IDS.FIELD_NAME);
    fieldAccessMap[fieldName] = modifiedFieldPermission;
    _set(entityPermissionByEntityName, 'fieldAccessMap', fieldAccessMap);
  });

  _set(modifiedTrieEntityFieldPermissions, entityName, entityPermissionByEntityName);
  const apiEntityFieldPermissions = getPayload(modifiedTrieEntityFieldPermissions, permissionData);
  await editPermissionByName(permissionName, apiEntityFieldPermissions);
  setState({ isSaveLoading: false });

  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_CONFIGURE}/${permissionName}/${TABS.ENTITY_SETTINGS}`;

  history.push({ pathname, state: { permissionData: apiEntityFieldPermissions } });
};

const handleChangePermissions = ({ params, setState, getState }) => {
  const { columnHeaderCheckbox, disabledFieldPermissionIds } = getState();
  const { nestingPath, id, value } = params;
  const updatedValue = { ..._get(params, 'additional.updatedValue') };
  let actionTypes = _get(updatedValue, 'actionTypes');
  if (_get(updatedValue, id) && !_includes(actionTypes, id)) {
    actionTypes = [...actionTypes, id];
  }
  if (!_get(updatedValue, id)) {
    actionTypes = _filter(actionTypes, (action) => action !== id);
  }
  _set(updatedValue, 'actionTypes', actionTypes);
  _unset(updatedValue, id);

  const pathToUpdate = _head(nestingPath);
  let updatedPermissionSettings = [];
  let checked = true;

  if (_get(updatedValue, COLUMN_IDS.ENTITY_NAME)) {
    const { entityPermissions } = getState();
    let modifiedDisabledFieldPermissionIds = [...disabledFieldPermissionIds];
    if (!value) {
      modifiedDisabledFieldPermissionIds = [...modifiedDisabledFieldPermissionIds, id];
    } else {
      modifiedDisabledFieldPermissionIds = _filter(modifiedDisabledFieldPermissionIds, (disablePermissionId) => disablePermissionId !== id);
    }
    updatedPermissionSettings = updateElementAtIndex(entityPermissions, updatedValue, pathToUpdate);
    setState({ entityPermissions: updatedPermissionSettings, disabledFieldPermissionIds: modifiedDisabledFieldPermissionIds });
  } else {
    const { fieldPermissions } = getState();
    updatedPermissionSettings = updateElementAtIndex(fieldPermissions, updatedValue, pathToUpdate);
    _forEach(updatedPermissionSettings, (permission) => {
      const permissionActionTypes = _get(permission, 'actionTypes');
      if (!_includes(permissionActionTypes, id)) {
        checked = false;
      }
    });
    const modifiedColumnHeaderCheckbox = { ...columnHeaderCheckbox };
    modifiedColumnHeaderCheckbox[id] = checked;
    setState({ fieldPermissions: updatedPermissionSettings, columnHeaderCheckbox: modifiedColumnHeaderCheckbox });
  }
};

const handleChangePermissionColumnHeader = ({ getState, setState, params }) => {
  const { columnHeaderCheckbox } = getState();
  const { data, id, value } = params;
  const modifiedData = _map(data, (permission) => {
    const updatedPermission = { ...permission };
    let actionTypes = [..._get(updatedPermission, 'actionTypes')];

    if (value && !_includes(actionTypes, id)) {
      actionTypes = [...actionTypes, id];
    } else if (!value) actionTypes = _filter(actionTypes, (action) => action !== id);
    _set(updatedPermission, 'actionTypes', actionTypes);
    return updatedPermission;
  });
  const modifiedPermission = { ...columnHeaderCheckbox };
  modifiedPermission[id] = value;
  setState({ fieldPermissions: modifiedData, columnHeaderCheckbox: modifiedPermission });
};

const handleCancelEntityFieldForm = ({ getState }) => {
  const { history, location, match } = getState();
  const permissionName = _get(match, 'params.permissionName');
  const permissionData = _get(location, 'state.permissionData', EMPTY_OBJECT);
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_CONFIGURE}/${permissionName}/${TABS.ENTITY_SETTINGS}`;

  history.push({ pathname, state: { permissionData } });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INITIALIZE_FORM_VALUES]: setInitialFormValues,
  [ACTION_TYPES.ON_SUBMIT]: handleSubmit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleChangePermissions,
  [ACTION_TYPES.ON_CHANGE_PERMISSION_TABLE_HEADER]: handleChangePermissionColumnHeader,
  [ACTION_TYPES.ON_REDIRECTION]: handleCancelEntityFieldForm,
};

export default ACTION_HANDLERS;
