import TextArea from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';

import { CUSTOM_STYLES_CONFIGURE_FIELD_IDS } from '../constants/customStylesConfigure.fieldIds';

const CUSTOM_STYLES_FIELD = {
  id: CUSTOM_STYLES_CONFIGURE_FIELD_IDS.CUSTOM_STYLES,
  renderer: TextArea,
  renderOptions: {
    label: __('Custom Styles'),
    rows: 8,
  },
};

const CUSTOM_STYLES_CONFIGURE_FIELDS = {
  [CUSTOM_STYLES_CONFIGURE_FIELD_IDS.CUSTOM_STYLES]: CUSTOM_STYLES_FIELD,
};

export { CUSTOM_STYLES_CONFIGURE_FIELDS };
