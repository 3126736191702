/* eslint-disable import/order */
import React, { useCallback } from 'react';
import _noop from 'lodash/noop';
import PropTypes from 'prop-types';

// components
import Popover from '@tekion/tekion-components/molecules/popover';
import TemplateVariableSelectionPopupContent from './molecules/templateVariableSelectionPopupContent';

// constants
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

// helpers
import { getSelectedTemplateVariableNode } from '../../../../helpers/richTextEditor.link';

// containers
import withEditor from '../../../../containers/withEditor';

const renderPopoverContent = (templateVariableNode, closePopup) => {
  const [templateVariableNodeElement] = templateVariableNode || EMPTY_ARRAY;
  return <TemplateVariableSelectionPopupContent templateVariableNodeElement={templateVariableNodeElement} closePopup={closePopup} />;
};

function TemplateVariableSelectionPopup(props) {
  const { editor, visible, onVisibleChange } = props;
  const templateVariableNode = getSelectedTemplateVariableNode(editor);
  const closePopup = useCallback(() => onVisibleChange(false), [onVisibleChange]);

  return (
    <Popover
      content={renderPopoverContent(templateVariableNode, closePopup)}
      visible={Boolean(templateVariableNode && visible)}
      onVisibleChange={onVisibleChange}
      destroyTooltipOnHide
    />
  );
}

TemplateVariableSelectionPopup.propTypes = {
  editor: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  onVisibleChange: PropTypes.func,
};

TemplateVariableSelectionPopup.defaultProps = {
  visible: false,
  onVisibleChange: _noop,
};

export default withEditor(TemplateVariableSelectionPopup);
