import { EVENT_NAMES, EVENT_NAME_TO_LABEL_MAP } from '../../../../../../../../constants/eventActions.constants';
import { WIDGET_TYPES } from '../../../../../../constants/visualBuilder.general.constants';

const FIELD_IDS = {
  EVENT_HANDLER: 'EVENT_HANDLER',
};

const COMMON_EVENT_TYPE_OPTIONS = [{ label: EVENT_NAME_TO_LABEL_MAP[EVENT_NAMES.LOAD], value: EVENT_NAMES.LOAD }];

const RELATED_WIDGET_EVENT_TYPE_OPTIONS = [...COMMON_EVENT_TYPE_OPTIONS];

const CHILD_WIDGET_EVENT_TYPE_OPTIONS = [...COMMON_EVENT_TYPE_OPTIONS];

const SIMILAR_WIDGET_EVENT_TYPE_OPTIONS = [...COMMON_EVENT_TYPE_OPTIONS];

const STANDARD_WIDGET_EVENT_TYPE_OPTIONS = [...COMMON_EVENT_TYPE_OPTIONS];

const BUTTON_VIEW_WIDGET_EVENT_TYPE_OPTIONS = [
  ...COMMON_EVENT_TYPE_OPTIONS,
  { label: EVENT_NAME_TO_LABEL_MAP[EVENT_NAMES.CLICK], value: EVENT_NAMES.CLICK },
];
const TEXT_VIEW_WIDGET_EVENT_TYPE_OPTIONS = [...COMMON_EVENT_TYPE_OPTIONS];

const WIDGET_TYPE_TO_EVENT_TYPE_OPTIONS = {
  [WIDGET_TYPES.RELATED_WIDGET]: RELATED_WIDGET_EVENT_TYPE_OPTIONS,
  [WIDGET_TYPES.SIMILAR_WIDGET]: SIMILAR_WIDGET_EVENT_TYPE_OPTIONS,
  [WIDGET_TYPES.CHILD_WIDGET]: CHILD_WIDGET_EVENT_TYPE_OPTIONS,
  [WIDGET_TYPES.STANDARD_WIDGET]: STANDARD_WIDGET_EVENT_TYPE_OPTIONS,
  [WIDGET_TYPES.BUTTON_WIDGET]: BUTTON_VIEW_WIDGET_EVENT_TYPE_OPTIONS,
  [WIDGET_TYPES.TEXT_WIDGET]: TEXT_VIEW_WIDGET_EVENT_TYPE_OPTIONS,
};

export { FIELD_IDS, WIDGET_TYPE_TO_EVENT_TYPE_OPTIONS };
