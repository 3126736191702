import _size from 'lodash/size';

import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import ShareAndManageRecordServices from '../services/shareAndManageRecord.services';

const fetchRecordAccess = async (entityName, recordId) => {
  try {
    const payload = {
      entityName,
      recordId,
    };

    const response = await ShareAndManageRecordServices.fetchRecordAccess(payload);

    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch access, please try again later!')));
    return '';
  }
};

const fetchEntityAccessForUsers = async (entityName, recordTypeId, userIds) => {
  try {
    const response = await ShareAndManageRecordServices.fetchEntityAccessForUsers(entityName, recordTypeId, userIds);

    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch access, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const shareRecord = async (payload) => {
  try {
    const response = await ShareAndManageRecordServices.shareRecord(payload);
    const data = getDataFromResponse(response);

    const failureMap = tget(data, 'failureMap', EMPTY_OBJECT);
    const success = tget(data, 'success', EMPTY_ARRAY);

    const failureCount = _size(failureMap);
    const successCount = _size(success);

    if (failureCount === 0) {
      toaster(TOASTER_TYPE.SUCCESS, __('You have successfully shared the records to {{successCount}} users.', { successCount }));
    } else {
      toaster(TOASTER_TYPE.INFO, __('Record sharing failed for {{failureCount}} users, please check manage access for more info!', { failureCount }));
    }

    return data;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to share record, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const fetchSharingDataForRecord = async (entityName, recordId) => {
  try {
    const response = await ShareAndManageRecordServices.fetchSharingDataForRecord(entityName, recordId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch sharing data, please try again later!')));
    return EMPTY_ARRAY;
  }
};

const deleteSharedRecordForUser = async (entityName, recordId, userId) => {
  try {
    const response = await ShareAndManageRecordServices.deleteSharedRecordForUser(entityName, recordId, userId);
    return response;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to remove access, please try again later')));
    return EMPTY_OBJECT;
  }
};

export { fetchRecordAccess, fetchEntityAccessForUsers, shareRecord, fetchSharingDataForRecord, deleteSharedRecordForUser };
