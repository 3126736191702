import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';
import withActions from '@tekion/tekion-components/connectors/withActions';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Loader from '@tekion/tekion-components/molecules/SpinnerComponent/Spinner';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

import withSize from '../../../../connectors/withSize';
import AuthConfigTile from './components/authConfigTile';
import AuthConfigModal from './components/authConfigModal';

import ACTION_HANDLERS from './helpers/loginConfigurationsDashboard.actionHandlers';
import ACTION_TYPES from './constants/loginConfigurationsDashboard.actionTypes';
import { PROVIDERS_DISPLAY_NAME } from './constants/loginConfigurationsDashboard.general.constants';

import styles from './loginConfigurationsDashboard.module.scss';

const LoginConfigurationsDashboard = ({
  isLoading,
  isAuthConfigModalVisible,
  isAuthConfigModalSubmitting,
  isRemoveConfigConfirmationDialogVisible,
  contentHeight,
  authConfigFormValues,
  selectedProviderConfig,
  errors,
  providerConfigurations,
  providerTypeOptions,
  onAction,
}) => {
  const handleClickAuthConfigTile = useCallback(
    (providerConfig) => {
      onAction({ type: ACTION_TYPES.ON_CLICK_AUTH_CONFIG_TILE, payload: { providerConfig } });
    },
    [onAction],
  );

  const handleConfirmationDialogSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_REMOVE_CONFIGURATION });
  }, [onAction]);

  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CLOSE_CONFIRMATION_DIALOG });
  }, [onAction]);

  const renderAddNewConfigTile = useCallback(
    () => (
      <div onClick={() => handleClickAuthConfigTile()} className={styles.providerTile}>
        <div className={styles.header}>
          <FontIcon size={SIZES.XXXL}>icon-add2</FontIcon>
          <div>{__('Add New Config')}</div>
        </div>
      </div>
    ),
    [handleClickAuthConfigTile],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_FETCH_LOGIN_CONFIGURATIONS });
  }, [onAction]);

  return (
    <Page>
      <Page.Header>
        <Heading size={1}>{__('Login Configurations')}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight }}>
        <PropertyControlledComponent controllerProperty={!isLoading} fallback={<Loader className={styles.loader} />}>
          <div className={styles.buttonContainer}>
            {_map(providerConfigurations, (providerConfig) => (
              <AuthConfigTile
                isAuthConfigModalVisible={isAuthConfigModalVisible}
                key={tget(providerConfig, 'providerType')}
                providerConfig={providerConfig}
                onClickAuthConfigTile={handleClickAuthConfigTile}
                onAction={onAction}
              />
            ))}
            {!_isEmpty(providerTypeOptions) && renderAddNewConfigTile()}
          </div>
          <AuthConfigModal
            isAuthConfigModalVisible={isAuthConfigModalVisible}
            isAuthConfigModalSubmitting={isAuthConfigModalSubmitting}
            selectedProviderConfig={selectedProviderConfig}
            authConfigFormValues={authConfigFormValues}
            errors={errors}
            providerTypeOptions={providerTypeOptions}
            onAction={onAction}
          />

          <ConfirmationDialog
            isVisible={isRemoveConfigConfirmationDialogVisible}
            title={__('Remove Configuration')}
            content={__('Are you sure you want to remove {{providerDisplayName}} login configuration?', {
              providerDisplayName: PROVIDERS_DISPLAY_NAME[tget(selectedProviderConfig, 'providerType', '')],
            })}
            onSubmit={handleConfirmationDialogSubmit}
            onCancel={handleConfirmationDialogCancel}
          />
        </PropertyControlledComponent>
      </Page.Body>
    </Page>
  );
};

LoginConfigurationsDashboard.propTypes = {
  isLoading: PropTypes.bool,
  isAuthConfigModalVisible: PropTypes.bool,
  isAuthConfigModalSubmitting: PropTypes.bool,
  isRemoveConfigConfirmationDialogVisible: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  authConfigFormValues: PropTypes.object,
  selectedProviderConfig: PropTypes.object,
  errors: PropTypes.object,
  providerConfigurations: PropTypes.array,
  providerTypeOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

LoginConfigurationsDashboard.defaultProps = {
  isLoading: false,
  isAuthConfigModalVisible: false,
  isAuthConfigModalSubmitting: false,
  isRemoveConfigConfirmationDialogVisible: false,
  authConfigFormValues: EMPTY_OBJECT,
  selectedProviderConfig: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  providerConfigurations: EMPTY_ARRAY,
  providerTypeOptions: EMPTY_ARRAY,
};

export default compose(
  withSize({ hasPageFooter: false, hasPageHeader: true }),
  withActions(EMPTY_OBJECT, ACTION_HANDLERS),
  React.memo,
)(LoginConfigurationsDashboard);
