import { useContext, useCallback, useState } from 'react';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchOnOpenData } from './helpers/useShareAndManageRecordAction.data';
import { generateOptions } from './helpers/useShareAndManageRecordActions.general';

import {
  ActionExecutionModalContext,
  SHARE_AND_MANAGE_RECORD_MODAL_MODE,
  actionExecutionModalContextGetters,
} from '../../../../../../connectors/withActionExecutionModal';
import ApplicationRendererContext from '../../../../../../context/applicationRenderer.context';
import { OPTION_KEYS as MENU_OPTION_KEYS } from '../../../../../../atoms/menu';
import { SHARE_RECORD_CONFIG } from './useShareAndManageRecordActions.constants';
import { APPLICATION_CONTEXT_KEYS } from '../../../../../../constants/applicationRenderer.constants';

import entityReader from '../../../../../../readers/entity.reader';

const useShareAndManageRecordActions = ({ entityRecord, entityDef }) => {
  const actionExecutionModalContextValue = useContext(ActionExecutionModalContext);
  const applicationRendererContextValue = useContext(ApplicationRendererContext);
  const [shareAndManageRecordOptions, setShareAndManageRecordOptions] = useState([]);

  const currentUserData = tget(applicationRendererContextValue, APPLICATION_CONTEXT_KEYS.CURRENT_USER, EMPTY_OBJECT);

  const shareAndManageRecordModalOpen = actionExecutionModalContextGetters.getOnShareAndManageRecordModalOpen(actionExecutionModalContextValue);
  const wwdData = actionExecutionModalContextGetters.getWwdData(actionExecutionModalContextValue);

  const entityName = entityReader.name(entityDef);

  const handleOptionClick = useCallback(
    ({ [MENU_OPTION_KEYS.ID]: id }) => {
      const mode = id === SHARE_RECORD_CONFIG.ID ? SHARE_AND_MANAGE_RECORD_MODAL_MODE.SHARE_RECORD : SHARE_AND_MANAGE_RECORD_MODAL_MODE.MANAGE_RECORD;

      shareAndManageRecordModalOpen({
        mode,
        entityDef,
        entityRecord,
      });
    },
    [entityDef, entityRecord, shareAndManageRecordModalOpen],
  );

  const handleShareAndManageRecordOnOpen = useCallback(async () => {
    const { isOptionAccessible } = await fetchOnOpenData({ entityName, entityRecord, wwdData, currentUserData });

    if (isOptionAccessible) {
      const options = generateOptions(handleOptionClick);
      setShareAndManageRecordOptions(options);
    }
  }, [entityName, entityRecord, handleOptionClick, wwdData, currentUserData]);

  return { shareAndManageRecordOptions, handleShareAndManageRecordOnOpen };
};

export default useShareAndManageRecordActions;
