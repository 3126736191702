import React, { useCallback } from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _includes from 'lodash/includes';

import AntCheckBox from '@tekion/tekion-components/atoms/checkbox';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import styles from '../groupedRelationshipField.module.scss';

const Options = ({ isMulti, optionValue, optionLabel, selectedOptions, onChangeOptions, ...restProps }) => {
  const handleOnClick = useCallback(() => {
    onChangeOptions(optionValue, optionLabel);
  }, [onChangeOptions, optionLabel, optionValue]);

  return (
    <components.Option {...restProps}>
      <div className={styles.option} onClick={handleOnClick}>
        {isMulti ? <AntCheckBox label={optionLabel} value={_includes(selectedOptions, optionValue)} onChange={_noop} /> : optionLabel}
      </div>
    </components.Option>
  );
};

Options.propTypes = {
  isMulti: PropTypes.bool,
  optionValue: PropTypes.string,
  optionLabel: PropTypes.string,
  selectedOptions: PropTypes.object,
  onChangeOptions: PropTypes.func,
};

Options.defaultProps = {
  isMulti: false,
  optionValue: EMPTY_STRING,
  optionLabel: EMPTY_STRING,
  selectedOptions: EMPTY_OBJECT,
  onChangeOptions: _noop,
};

export default React.memo(Options);
