import _pick from 'lodash/pick';
import _valuesIn from 'lodash/valuesIn';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { updateSelectedViewComponent } from '../../organisms/propertiesForm/propertiesForm.helper';

import { FIELD_IDS } from './cellViewColumnPropertiesForm.constants';
import {
  PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS,
  VIEW_COMPONENT_UPDATE_ACTION_ID,
  VIEW_COMPONENT_UPDATE_ACTION_TYPE,
} from '../../organisms/propertiesForm/propertiesForm.constants';

const ARRAY_OF_FIELD_IDS = _valuesIn(FIELD_IDS);

const getCellViewColumnPropertiesFromProperties = (properties) => _pick(properties, ARRAY_OF_FIELD_IDS);

const getUpdatedSelectedViewComponentFromOnChangeValue = (selectedViewComponent, payload = EMPTY_OBJECT) => {
  const { id, value } = payload;

  const propertiesToUpdate = [
    { [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_NAME]: id, [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_VALUE]: value },
  ];

  const action = {
    [VIEW_COMPONENT_UPDATE_ACTION_ID.ACTION_TYPE]: VIEW_COMPONENT_UPDATE_ACTION_TYPE.PROPERTIES_UPDATE,
    [VIEW_COMPONENT_UPDATE_ACTION_ID.PAYLOAD]: propertiesToUpdate,
  };

  const newSelectedViewComponent = updateSelectedViewComponent(selectedViewComponent, action);

  return newSelectedViewComponent;
};

export { getCellViewColumnPropertiesFromProperties, getUpdatedSelectedViewComponentFromOnChangeValue };
