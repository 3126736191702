exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .clickableDiv_clickableDiv__pPyqXs7uXc{margin:5rem}.clickableDiv_clickableDiv__pPyqXs7uXc .clickableDiv_button__3m6uAjFg7f{height:25rem;width:25rem;display:flex;justify-content:center;align-items:center;flex-direction:column}[dir] .clickableDiv_clickableDiv__pPyqXs7uXc .clickableDiv_button__3m6uAjFg7f{border:.1rem solid #969aa3;border-radius:1rem}.clickableDiv_clickableDiv__pPyqXs7uXc .clickableDiv_button__3m6uAjFg7f .clickableDiv_addIconDivContainer__fQUWLqAYMt{display:flex;justify-content:center;align-items:center;height:9.5rem;width:9.5rem}[dir] .clickableDiv_clickableDiv__pPyqXs7uXc .clickableDiv_button__3m6uAjFg7f .clickableDiv_addIconDivContainer__fQUWLqAYMt{margin:3rem 0 2rem;background:#ccc;border-radius:16px}[dir] .clickableDiv_clickableDiv__pPyqXs7uXc .clickableDiv_button__3m6uAjFg7f .clickableDiv_header__u9VgAzrWWQ{margin-bottom:4rem}[dir] .clickableDiv_clickableDiv__pPyqXs7uXc:hover{cursor:pointer}", ""]);

// Exports
exports.locals = {
	"clickableDiv": "clickableDiv_clickableDiv__pPyqXs7uXc",
	"button": "clickableDiv_button__3m6uAjFg7f",
	"addIconDivContainer": "clickableDiv_addIconDivContainer__fQUWLqAYMt",
	"header": "clickableDiv_header__u9VgAzrWWQ"
};