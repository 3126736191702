import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import _isNil from 'lodash/isNil';

import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';

import { getValueOfFieldFromFieldDefinitionOfFieldName } from '../../../../helpers/entityManagement.helpers';

import { getDisabledFieldBasedOnDefaultValue } from './helpers/formFieldsCheckbox.helpers';

import entityReader from '../../../../readers/entity.reader';

const FormFieldsCheckbox = ({ id, value, entity, selectedViewComponentProperties, ...restProps }) => {
  const fieldName = useMemo(
    () => getArraySafeValue(tget(selectedViewComponentProperties, 'fieldNames', EMPTY_ARRAY)),
    [selectedViewComponentProperties],
  );
  const defaultValue = useMemo(
    () => getValueOfFieldFromFieldDefinitionOfFieldName(fieldName, entityReader.fieldDefinitions(entity), id),
    [id, fieldName, entity],
  );

  const isCheckBoxDisabled = useMemo(() => getDisabledFieldBasedOnDefaultValue(id, defaultValue), [defaultValue, id]);

  return <CheckboxRenderer {...restProps} id={id} value={_isNil(value) ? defaultValue : value} disabled={isCheckBoxDisabled} />;
};

FormFieldsCheckbox.propTypes = {
  value: PropTypes.bool,
  id: PropTypes.string.isRequired,
  checkboxLabel: PropTypes.string,
  entity: PropTypes.object.isRequired,
  selectedViewComponentProperties: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

FormFieldsCheckbox.defaultProps = {
  value: undefined,
  checkboxLabel: EMPTY_STRING,
  selectedViewComponentProperties: EMPTY_OBJECT,
};

export default FormFieldsCheckbox;
