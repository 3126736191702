import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import withActions from '@tekion/tekion-components/connectors/withActions';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Button from '@tekion/tekion-components/atoms/Button';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import FilterList from './components/FilterList';

import ACTION_HANDLERS from './preAppliedFiltersConfigurator.actionHandlers';

import { FIELD_IDS, ACTION_TYPES, MODAL_TITLES, MODAL_BUTTON_TITLES } from './preAppliedFiltersConfigurator.constants';
import { FORM_MODES } from '../../../../../../constants/general.constants';

import styles from './preAppliedFiltersConfigurator.module.scss';

const PreAppliedFiltersConfigurator = ({
  isModalVisible,
  mode,
  selectedWidgetConfig,
  fieldsConfig,
  errors,
  preAppliedFilters,
  filterableFieldTypeOptions,
  onAction,
}) => {
  const handleSaveModal = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_SAVE_MODAL });
  }, [onAction]);

  const handleCancelModal = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL_MODAL });
  }, [onAction]);

  const handleAddFilterClick = useCallback(() => {
    onAction({ type: ACTION_TYPES.OPEN_MODAL });
  }, [onAction]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_SELECTED_WIDGET_COMPONENT_PROPERTIES_CHANGE, payload: { selectedWidgetConfig } });
  }, [selectedWidgetConfig, onAction]);

  return (
    <>
      <Button disable view={Button.VIEW.TERTIARY} className={styles.addButton} onClick={handleAddFilterClick}>
        <FontIcon className={styles.addIcon}>icon-add-circle</FontIcon>
        {__('Add Filter')}
      </Button>

      <Modal
        visible={isModalVisible}
        width={SIZES.XL}
        title={MODAL_TITLES[mode]}
        submitBtnText={MODAL_BUTTON_TITLES[mode]}
        onSubmit={handleSaveModal}
        onCancel={handleCancelModal}
      >
        <FilterList
          id={FIELD_IDS.FILTER_LIST}
          fieldsConfig={fieldsConfig}
          preAppliedFilters={preAppliedFilters}
          errors={errors}
          filterableFieldTypeOptions={filterableFieldTypeOptions}
          onAction={onAction}
        />
      </Modal>
    </>
  );
};

PreAppliedFiltersConfigurator.propTypes = {
  isModalVisible: PropTypes.bool,
  mode: PropTypes.oneOf([FORM_MODES.CREATE, FORM_MODES.EDIT]),
  selectedWidgetConfig: PropTypes.object,
  fieldsConfig: PropTypes.object,
  errors: PropTypes.object,
  preAppliedFilters: PropTypes.array,
  filterableFieldTypeOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

PreAppliedFiltersConfigurator.defaultProps = {
  isModalVisible: false,
  mode: FORM_MODES.CREATE,
  selectedWidgetConfig: EMPTY_OBJECT,
  fieldsConfig: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  preAppliedFilters: EMPTY_ARRAY,
  filterableFieldTypeOptions: EMPTY_ARRAY,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(PreAppliedFiltersConfigurator);
