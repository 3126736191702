exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".widgetsPanel_fontStyle__etfZLkeWVH,.widgetsPanel_listGroupHeader__KEf4EqcVDm{color:#161616;font-size:1.4rem;letter-spacing:.03rem;line-height:1.7rem}.widgetsPanel_listGroupHeader__KEf4EqcVDm{font-family:\"Proxima-Nova-Bold\"}[dir] .widgetsPanel_listGroupHeader__KEf4EqcVDm{padding:1.6rem 4rem}[dir] .widgetsPanel_collapseStyle__7j2xRc7CTG .ant-collapse-content-box{padding:0 !important}.widgetsPanel_widgets__p7ahpr9LwH{display:flex;gap:.3rem}[dir] .widgetsPanel_widgets__p7ahpr9LwH{background-color:#f4f5f6;padding:1.5rem;margin:.5rem 0;cursor:pointer}[dir] .widgetsPanel_widgets__p7ahpr9LwH:hover{border:.1rem dashed #007bfe}.widgetsPanel_btnDnd__6FSxKepj8Y{z-index:5;align-self:center;color:#89b6df}[dir] .widgetsPanel_btnDnd__6FSxKepj8Y{cursor:grab}.widgetsPanel_addContainerButton__5CYW49TKMx{align-self:center}[dir] .widgetsPanel_addContainerButton__5CYW49TKMx{padding:1rem}", ""]);

// Exports
exports.locals = {
	"fontStyle": "widgetsPanel_fontStyle__etfZLkeWVH",
	"listGroupHeader": "widgetsPanel_listGroupHeader__KEf4EqcVDm",
	"collapseStyle": "widgetsPanel_collapseStyle__7j2xRc7CTG",
	"widgets": "widgetsPanel_widgets__p7ahpr9LwH",
	"btnDnd": "widgetsPanel_btnDnd__6FSxKepj8Y",
	"addContainerButton": "widgetsPanel_addContainerButton__5CYW49TKMx"
};