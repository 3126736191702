import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _constant from 'lodash/constant';

// Components
import InputTable from '@tekion/tekion-components/organisms/inputTable/containers/withRowActions/Table';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Button from '@tekion/tekion-components/atoms/Button';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { getColumns } from './helper/searchFieldsTable.columns';

import { ROW_ACTION_PROPS, REMOVE_ACTION, TABLE_ACTION_TYPES, COLUMN_IDS } from './constants/searchFieldsTable.general';
import ACTION_TYPES from '../../constants/searchOptionsConfigurator.actionTypes';

import styles from '../../searchOptionsConfigurator.module.scss';

const SearchFieldsTable = ({ data, searchableFieldOptions, onAction }) => {
  const getActions = _constant([REMOVE_ACTION]);

  const selectedSearchOptions = useMemo(() => _map(data, (searchOption) => getArraySafeValue(tget(searchOption, [COLUMN_IDS.FIELD]))), [data]);

  const columns = useMemo(() => getColumns(searchableFieldOptions, selectedSearchOptions), [searchableFieldOptions, selectedSearchOptions]);

  const handleAction = useCallback(
    (action) => {
      const tableActionType = _get(action, 'type', EMPTY_STRING);
      const rowIndex = _get(action, 'payload.nestingPath.[0]');
      const id = _get(action, 'payload.id');
      const value = _get(action, 'payload.value');

      if (tableActionType === TABLE_ACTION_TYPES.ON_CHANGE) {
        onAction({
          type: ACTION_TYPES.TABLE_ON_CHANGE,
          payload: { rowIndex, id, value },
        });
      } else if (tableActionType === TABLE_ACTION_TYPES.TABLE_ACTION_CLICK) {
        const actionType = _get(action, 'payload.actionType');

        onAction({
          type: actionType,
          payload: { rowIndex },
        });
      }
    },
    [onAction],
  );

  const handleClick = useCallback(() => {
    onAction({ type: ACTION_TYPES.TABLE_ADD_ROW });
  }, [onAction]);

  return (
    <div>
      <InputTable columns={columns} value={data} rowActionProps={ROW_ACTION_PROPS} getActionsForRow={getActions} onAction={handleAction} />
      <Button disabled={_size(data) === _size(searchableFieldOptions)} view={Button.VIEW.TERTIARY} className={styles.addButton} onClick={handleClick}>
        {__('+Add Field')}
      </Button>
    </div>
  );
};

SearchFieldsTable.propTypes = {
  data: PropTypes.array,
  searchableFieldOptions: PropTypes.array,
  onAction: PropTypes.func,
};

SearchFieldsTable.defaultProps = {
  data: EMPTY_ARRAY,
  searchableFieldOptions: EMPTY_ARRAY,
  onAction: PropTypes.func,
};

export default React.memo(SearchFieldsTable);
