import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _concat from 'lodash/concat';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';

const getEventTypesFromMetadata = (globalMetadata) => {
  let _eventTypes = [];

  if (!_isEmpty(globalMetadata)) {
    const modules = tget(globalMetadata, 'modules');

    _forEach(modules, (module) => {
      if (!_isEmpty(module)) {
        const submodules = tget(module, 'submodules');

        _forEach(submodules, (submodule) => {
          if (!_isEmpty(submodule)) {
            const eventTypesForSubmodule = tget(submodule, 'eventTypes', []);

            _eventTypes = _concat(_eventTypes, eventTypesForSubmodule);
          }
        });
      }
    });
  }

  _eventTypes = _map(_eventTypes, (eventType) => ({
    ...eventType,
    label: eventType?.displayName,
    value: eventType?.id,
  }));

  return _eventTypes;
};

export default getEventTypesFromMetadata;
