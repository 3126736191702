const HEADER_PROPS = {
  label: __('Workspace Wide Default Sharing Rules'),
};

const TABLE_MANAGER_PROPS = {
  showHeader: true,
  showSearch: true,
  showFilter: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

export { HEADER_PROPS, TABLE_MANAGER_PROPS };
