import ApiService from './apiService/apiService';

const SUBSCRIPTION_CONFIG_BASE_URL = 'core/u/event-hub/subscriptions';
const SUBSCRIPTION_CONFIG_LOG_BASE_URL = 'core/u/wds/webhook/transactions';

const fetchSubscriptionsConfigs = (payload) => ApiService.post(`${SUBSCRIPTION_CONFIG_BASE_URL}/search`, payload);
const createSubscriptionConfig = (payload) => ApiService.post(`${SUBSCRIPTION_CONFIG_BASE_URL}`, payload);
const updateSubscriptionConfigByName = (subscriptionConfigName, payload) =>
  ApiService.put(`${SUBSCRIPTION_CONFIG_BASE_URL}/${subscriptionConfigName}`, payload);

const getSubscriptionConfigByName = (name) => ApiService.get(`${SUBSCRIPTION_CONFIG_BASE_URL}/${name}`);
const verifySubscriptionConfigUrl = (payload) => ApiService.post(`${SUBSCRIPTION_CONFIG_BASE_URL}/verify`, payload);
const fetchSubscriptionConfigLogs = (subscriptionConfigName) => ApiService.get(`${SUBSCRIPTION_CONFIG_LOG_BASE_URL}/${subscriptionConfigName}`);

const searchSubscriptionConfigLogsByTime = (subscriptionConfigName, payload) =>
  ApiService.post(`${SUBSCRIPTION_CONFIG_LOG_BASE_URL}/${subscriptionConfigName}/time`, payload);

const getSubscriptionConfigLogsStatus = (subscriptionConfigName) =>
  ApiService.get(`${SUBSCRIPTION_CONFIG_BASE_URL}/logging/${subscriptionConfigName}`);

const enableSubscriptionConfigLog = (subscriptionConfigName) =>
  ApiService.post(`${SUBSCRIPTION_CONFIG_BASE_URL}/logging/${subscriptionConfigName}/enable`);

const disableSubscriptionConfigLog = (subscriptionConfigName) =>
  ApiService.post(`${SUBSCRIPTION_CONFIG_BASE_URL}/logging/${subscriptionConfigName}/disable`);

const activeSubscriptionConfig = (subscriptionConfigName, payload) =>
  ApiService.put(`${SUBSCRIPTION_CONFIG_BASE_URL}/${subscriptionConfigName}/active`, payload);

const inactiveSubscriptionConfig = (subscriptionConfigName, payload) =>
  ApiService.put(`${SUBSCRIPTION_CONFIG_BASE_URL}/${subscriptionConfigName}/deactivate`, payload);

export default {
  fetchSubscriptionsConfigs,
  getSubscriptionConfigByName,
  verifySubscriptionConfigUrl,
  updateSubscriptionConfigByName,
  createSubscriptionConfig,
  fetchSubscriptionConfigLogs,
  getSubscriptionConfigLogsStatus,
  enableSubscriptionConfigLog,
  disableSubscriptionConfigLog,
  searchSubscriptionConfigLogsByTime,
  activeSubscriptionConfig,
  inactiveSubscriptionConfig,
};
