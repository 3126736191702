import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _keyBy from 'lodash/keyBy';
import _some from 'lodash/some';
import _head from 'lodash/head';
import _size from 'lodash/size';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import { toMoment } from '@tekion/tekion-base/utils/dateUtils';
import { tget } from '@tekion/tekion-base/utils/general';

import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { KEY_CODES } from '@tekion/tekion-components/organisms/FormBuilder/constants/inputKeys';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { defaultOnChangeHandler } from '@tekion/tekion-components/connectors/withFormPageState/withFormPageState.helpers';

import { updateApiHeaders } from '../../../../services/apiService/apiService';
import { logIn, fetchConfigurations, verifyMFA, resendOTP } from '../../../../services/loginService';

import { saveLoginData } from './LoginPage.action';

import { getErrorMsgByCode, getProviderIPSSOURL } from './LoginPage.helpers';
import { redirectToLastRoute } from '../../WithAuth.helpers';

import { setCookie } from '../../../../utils/cookieStorage';

import { FORGOT_PASSWORD_PATHNAME, UPDATE_PASSWORD_PATHNAME } from '../../WithAuth.constants';
import { TENANT_UNIVERSE_BASE_ROUTE } from '../../../../constants/routes';
import {
  FIELD_NAMES,
  FIELDS_TO_PICK_FOR_LOGIN,
  LOGIN_PAGE_READER,
  LOGIN_ACTION_TYPES,
  LOGIN_PROVIDERS,
  LOGIN_CONFIG_KEYS,
  FIELDS_TO_PICK_FOR_OTP_VALIDATION,
  FIELDS_TO_PICK_FOR_OTP,
  ERROR_CODES,
  RESEND_OTP_TIME,
} from './LoginPage.constants';

const defaultFieldOnChangeHandler = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { values } = getState();

  if (!_isEmpty(params.id)) {
    setState({ values: { ...values, [params.id]: params.value } });
  }
};

const callToasterForNoURL = () => {
  toaster(TOASTER_TYPE.ERROR, __('Something went wrong. Please try again later'));
};

const handleErrors = ({ error, setState, getState }) => {
  const { values } = getState();
  const errorCode = _get(error, 'data.errorDetails.errorCode');
  toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, getErrorMsgByCode(errorCode)));

  const updatedValues = {};
  switch (errorCode) {
    case ERROR_CODES.MFA_TOKEN_EXPIRED:
      updatedValues.showMfaField = false;
      updatedValues.showOTPField = false;
      updatedValues[FIELD_NAMES.OTP] = '';
      break;
    default:
      break;
  }

  setState({ values: { ...values, ...updatedValues, isBtnLoading: false } });
};

const logUserIn = (loginResponse, { reDirectUrl = '/' }, { setState, getState }) => {
  const { values } = getState();
  const loginData = _get(loginResponse, 'data', EMPTY_OBJECT);
  const passwordSet = LOGIN_PAGE_READER.passwordSet(loginData);
  const tenantId = LOGIN_PAGE_READER.tenantId(loginData);
  const userId = LOGIN_PAGE_READER.userId(loginData);
  const email = LOGIN_PAGE_READER.email(loginData);
  const workspaceId = LOGIN_PAGE_READER.workspaceId(loginData);
  const token = LOGIN_PAGE_READER.tekionApiToken(loginData);

  updateApiHeaders({
    tenantId,
    userId,
    email,
    workspaceId,
    'tekion-api-token': token,
  });
  const isTenantUniverse = _includes(workspaceId, 'universe');
  let updatedUrl = isTenantUniverse && reDirectUrl === '/' ? TENANT_UNIVERSE_BASE_ROUTE : reDirectUrl;
  if (!passwordSet) {
    updatedUrl = UPDATE_PASSWORD_PATHNAME;
  }

  saveLoginData(loginResponse);
  redirectToLastRoute(updatedUrl);

  return setState({
    values: {
      ...values,
      isBtnLoading: false,
      [FIELD_NAMES.TENANT_ID]: tenantId,
      [FIELD_NAMES.USER_ID]: userId,
      [FIELD_NAMES.EMAIL]: email,
    },
  });
};

const handleResendOTP = async ({ getState, setState, params }) => {
  const { values } = getState();
  const captchaToken = tget(params, FIELD_NAMES.CAPTCHA_TOKEN, '');
  const updatedFormValues = { ...values, showOTPField: false, isBtnLoading: true, captchaToken };
  setState({ values: updatedFormValues });
  try {
    await resendOTP(_pick(updatedFormValues, FIELDS_TO_PICK_FOR_OTP));
    setState({
      values: {
        ...values,
        showOTPField: true,
        isBtnLoading: false,
        expiryTime: toMoment().add(RESEND_OTP_TIME),
      },
    });
  } catch (error) {
    handleErrors({ error, getState, setState });
  }
};

const handleVerifyOTP = async ({ getState, setState }, { reDirectUrl }) => {
  const { values } = getState();
  try {
    const loginResponse = await verifyMFA(_pick(values, FIELDS_TO_PICK_FOR_OTP_VALIDATION));

    /* Normal flow */
    logUserIn(loginResponse, { reDirectUrl }, { getState, setState });
  } catch (error) {
    handleErrors({ error, getState, setState });
  }
};

const handleValidateEmail = async ({ getState, setState }) => {
  const { values } = getState();
  const email = _get(values, FIELD_NAMES.USERNAME);
  const captchaToken = _get(values, FIELD_NAMES.CAPTCHA_TOKEN);

  try {
    const response = await fetchConfigurations({ email, captchaToken });
    const data = tget(response, 'data.providerConfigurations', EMPTY_ARRAY);
    const providerTypeMap = _keyBy(data, LOGIN_CONFIG_KEYS.PROVIDER_TYPE);
    const hasTekionProvider = _some(providerTypeMap, (_, key) => key === LOGIN_PROVIDERS.TEKION);

    if (_size(data) < 2) {
      const auth = _head(data);
      const providerType = _get(auth, LOGIN_CONFIG_KEYS.PROVIDER_TYPE);
      const ipSSOUrl = _get(auth, LOGIN_CONFIG_KEYS.IP_SSO_URL);

      if (providerType === LOGIN_PROVIDERS.TEKION) {
        setState({ values: { ...values, showNormalForm: true, isBtnLoading: false, hasTekionProvider } });
      } else {
        setCookie('email', email);
        setCookie('authProvider', providerType);
        window.location.href = ipSSOUrl;
      }
    } else {
      setState({
        values: {
          ...values,
          showMultiAuthProvider: true,
          showNormalForm: true,
          isBtnLoading: false,
          providerTypeMap,
          hasTekionProvider,
        },
      });
    }
  } catch (error) {
    handleErrors({ error, getState, setState });
  }
};

const handleLogin = async ({ getState, setState }, { reDirectUrl = '/' }, invokedAfterValidation) => {
  const { values } = getState();
  setState({ values: { ...values, isBtnLoading: true } });

  try {
    if (!invokedAfterValidation) {
      return handleLogin({ getState, setState }, { reDirectUrl }, true);
    }

    if (!tget(values, 'showNormalForm', false)) {
      return handleValidateEmail({ getState, setState });
    }

    if (values.showOTPField) {
      return handleVerifyOTP({ getState, setState }, { reDirectUrl });
    }

    const loginResponse = await logIn({ ..._pick(values, FIELDS_TO_PICK_FOR_LOGIN), type: LOGIN_PROVIDERS.TEKION });
    const loginData = _get(loginResponse, 'data', EMPTY_OBJECT);
    const tenantId = LOGIN_PAGE_READER.tenantId(loginData);
    const userId = LOGIN_PAGE_READER.userId(loginData);
    const email = LOGIN_PAGE_READER.email(loginData);
    const isMFAEnabled = LOGIN_PAGE_READER.mfaEnabled(loginData);
    const mfaAuthTypes = LOGIN_PAGE_READER.mfaAuthTypes(loginData);

    if (isMFAEnabled) {
      const { accessToken: mfaToken } = loginData;
      const primaryMFAAuthType = getArraySafeValue(mfaAuthTypes);

      return setState({
        values: {
          ...values,
          showMfaField: true,
          isBtnLoading: false,
          showResend: true,
          showOTPField: true,
          expiryTime: toMoment().add(RESEND_OTP_TIME),
          mfaAuthTypes,
          [FIELD_NAMES.TENANT_ID]: tenantId,
          [FIELD_NAMES.USER_ID]: userId,
          [FIELD_NAMES.MFA_TOKEN]: mfaToken,
          [FIELD_NAMES.AUTHENTICATOR_TYPE]: primaryMFAAuthType,
          [FIELD_NAMES.AUTHENTICATOR]: primaryMFAAuthType,
          [FIELD_NAMES.EMAIL]: email,
        },
      });
    }

    return logUserIn(loginResponse, { reDirectUrl }, { getState, setState });
  } catch (error) {
    return handleErrors({ error, getState, setState });
  }
};

const handleRedirectToSSO = ({ params, getState }) => {
  const providerType = _get(params, LOGIN_CONFIG_KEYS.PROVIDER_TYPE);
  if (!_isEmpty(providerType)) {
    const { values } = getState();
    const providerTypeMap = tget(values, 'providerTypeMap', EMPTY_OBJECT);
    const provider = providerTypeMap[providerType];
    const email = _get(values, FIELD_NAMES.USERNAME);
    if (!_isEmpty(provider)) {
      const providerIPSSOURL = getProviderIPSSOURL(providerType, provider);
      if (!_isEmpty(providerIPSSOURL)) {
        setCookie('email', email);
        setCookie('authProvider', providerType);
        window.location.href = providerIPSSOURL;
      } else {
        callToasterForNoURL();
      }
    }
  }
};

const handleResetLogin = ({ setState }) => {
  setState({ values: EMPTY_OBJECT, errors: EMPTY_OBJECT });
};

const handleHCaptchaToken = ({ getState, setState, params }, { reDirectUrl = '/' }) => {
  const { values } = getState();
  const captchaToken = tget(params, FIELD_NAMES.CAPTCHA_TOKEN);

  setState({ values: { ...values, captchaToken } }, () => handleLogin({ getState, setState }, { reDirectUrl }, true));
};

const FIELD_ID_TO_ON_ENTER_KEY_PRESS_MAP = {
  [FIELD_NAMES.PASSWORD]: handleLogin,
  [FIELD_NAMES.OTP]: handleLogin,
  [FIELD_NAMES.USERNAME]: handleLogin,
};

const FIELD_ID_TO_CHANGE_HANDLER_MAP = {
  DEFAULT: defaultFieldOnChangeHandler,
  [FIELD_NAMES.LOGIN_BUTTON]: handleLogin,
  [FIELD_NAMES.FORGOT_PASSWORD_BUTTON]: (_, { history }) => {
    history.push(FORGOT_PASSWORD_PATHNAME);
  },
  [FIELD_NAMES.DONT_CHALLANGE]: defaultOnChangeHandler,
};

const getLoginActionHandlers = (history, reDirectUrl = '/') => ({
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: async ({ getState, setState, params = EMPTY_OBJECT }) => {
    const handler = _get(FIELD_ID_TO_CHANGE_HANDLER_MAP, params.id, FIELD_ID_TO_CHANGE_HANDLER_MAP.DEFAULT);

    handler({ getState, setState, params }, { reDirectUrl, history });
  },
  [FORM_ACTION_TYPES.ON_KEY_PRESS]: ({ getState, setState, params = EMPTY_OBJECT }) => {
    if (params.value !== KEY_CODES.ENTER) return;
    const handler = _get(FIELD_ID_TO_ON_ENTER_KEY_PRESS_MAP, params.id, _noop);

    handler({ getState, setState, params }, { reDirectUrl, history });
  },
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: (params) => handleLogin(params, { reDirectUrl, history }, true),
  [LOGIN_ACTION_TYPES.REDIRECT_TO_SSO]: handleRedirectToSSO,
  [LOGIN_ACTION_TYPES.RESET_LOGIN]: handleResetLogin,
  [LOGIN_ACTION_TYPES.GET_HCAPTCHA_TOKEN]: (params) => handleHCaptchaToken(params, { reDirectUrl }),
  [LOGIN_ACTION_TYPES.RESEND_OTP]: handleResendOTP,
});

export default getLoginActionHandlers;
