import { defaultMemoize } from 'reselect';

import _pick from 'lodash/pick';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _castArray from 'lodash/castArray';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import MultiSelectField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/MultiSelectField';

import { VIEW_FORM_FIELDS, getDetailsViewFormSections, LIST_VIEW_FORM_SECTIONS } from '../constants/createViewForm.config';
import { COLUMN_IDS, TABLE_COLUMNS } from '../components/configTable/configTable.constants';
import { FIELD_IDS, VIEW_TYPE_OPTIONS } from '../constants/createView.constants';
import { VIEW_TYPES } from '../../../../../../organisms/viewBuilder/constants/viewBuilder.constants';
import FIELD_TYPES from '../../../../../../constants/fieldDefinition.fieldTypes';
import { ALL_VIEW_PROPERTY_KEYS, CELL_TYPES, VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../../constants/viewBuilder.constants';
import { FORM_MODES } from '../../../../../../constants/general.constants';

const getFormFields = defaultMemoize((selectedColumnIds, viewType, recordTypeOptions, formMode) => ({
  ...VIEW_FORM_FIELDS,
  [FIELD_IDS.COLUMN_TABLE]: addToRenderOptions(VIEW_FORM_FIELDS[FIELD_IDS.COLUMN_TABLE], [
    { path: 'selectedColumnIds', value: selectedColumnIds },
    { path: 'viewType', value: viewType },
  ]),
  [FIELD_IDS.VIEW_TYPE]: addToRenderOptions(VIEW_FORM_FIELDS[FIELD_IDS.VIEW_TYPE], [
    { path: 'isDisabled', value: formMode === FORM_MODES.EDIT },
    {
      path: 'options',
      value:
        formMode === FORM_MODES.EDIT ? VIEW_TYPE_OPTIONS : _filter(VIEW_TYPE_OPTIONS, ({ value }) => value !== VIEW_TYPES.RECORD_TYPE_SELECTION_VIEW),
    },
  ]),
  [FIELD_IDS.VIEW_NAME]: addToRenderOptions(VIEW_FORM_FIELDS[FIELD_IDS.VIEW_NAME], [{ path: 'disabled', value: formMode === FORM_MODES.EDIT }]),
  [FIELD_IDS.APPLICABLE_RECORD_TYPES]: {
    id: FIELD_IDS.APPLICABLE_RECORD_TYPES,
    renderer: viewType === VIEW_TYPES.LIST_VIEW || viewType === VIEW_TYPES.GRID_VIEW ? MultiSelectField : SelectInput,
    renderOptions: {
      label: __('Record Types'),
      required: viewType !== VIEW_TYPES.RECORD_TYPE_SELECTION_VIEW,
      options: recordTypeOptions,
      validators: viewType === VIEW_TYPES.RECORD_TYPE_SELECTION_VIEW ? [] : [isRequiredRule],
    },
  },
}));

const getTableConfig = (entity) => {
  const fieldDefinitions = tget(entity, 'fieldDefinitions', EMPTY_ARRAY);

  return _map(fieldDefinitions, (field) => {
    const rowData = _pick(field, TABLE_COLUMNS);
    const id = _get(field, 'id');

    return {
      ...rowData,
      [COLUMN_IDS.DEFAULT_FILTER]: false,
      id,
      field,
    };
  });
};

const getFormSections = defaultMemoize((formValues, formMode, isCurrentEntityRecordTypeEnabled) => {
  const viewType = getArraySafeValue(_get(formValues, FIELD_IDS.VIEW_TYPE));
  const recordType = getArraySafeValue(_get(formValues, FIELD_IDS.APPLICABLE_RECORD_TYPES));

  if (
    formMode === FORM_MODES.EDIT ||
    _isEmpty(viewType) ||
    _isEmpty(recordType) ||
    viewType === VIEW_TYPES.CELL_VIEW ||
    viewType === VIEW_TYPES.GRID_VIEW
  ) {
    return getDetailsViewFormSections(isCurrentEntityRecordTypeEnabled);
  } else {
    return [...getDetailsViewFormSections(isCurrentEntityRecordTypeEnabled), ...LIST_VIEW_FORM_SECTIONS];
  }
});

const columnMapper = (formColumnTable, viewType) =>
  _map(formColumnTable, (column) => {
    const name = _get(column, COLUMN_IDS.NAME, '');
    const fieldType = _get(column, 'field.fieldType');
    const lookUpDisplayName = _get(column, 'field.lookupField.displayField');
    const fieldNames = fieldType === FIELD_TYPES.RELATIONSHIP ? `${name}.${lookUpDisplayName}` : name;

    switch (viewType) {
      case VIEW_TYPES.LIST_VIEW:
      case VIEW_TYPES.GRID_VIEW:
        return {
          cellType: CELL_TYPES.SIMPLE,
          title: _get(column, COLUMN_IDS.DISPLAY_NAME, ''),
          fieldNames: _castArray(fieldNames),
          template: `$\{${fieldNames}}`,
          filterable: _get(column, COLUMN_IDS.FILTERABLE),
          searchable: _get(column, COLUMN_IDS.SEARCHABLE),
          sortable: _get(column, COLUMN_IDS.SORTABLE),
        };
      case VIEW_TYPES.FORM_VIEW:
        return {
          cellType: CELL_TYPES.SIMPLE,
          title: _get(column, COLUMN_IDS.DISPLAY_NAME, ''),
          fieldNames: _castArray(name),
          template: `$\{${name}}`,
          mandatory: _get(column, COLUMN_IDS.MANDATORY),
          editable: _get(column, COLUMN_IDS.EDITABLE),
          disabled: _get(column, COLUMN_IDS.DISABLED),
        };

      case VIEW_TYPES.DETAIL_VIEW:
      default:
        return {
          cellType: CELL_TYPES.SIMPLE,
          title: _get(column, COLUMN_IDS.DISPLAY_NAME, ''),
          fieldNames: _castArray(fieldNames),
          template: `$\{${fieldNames}}`,
        };
    }
  });

const getViewSummary = (formValues, selectedColumnIds) => {
  const allFieldsConfig = _get(formValues, FIELD_IDS.COLUMN_TABLE, EMPTY_ARRAY);
  const viewType = _get(formValues, FIELD_IDS.VIEW_TYPE)[0];

  const selectedFieldsConfig = _filter(allFieldsConfig, (fieldConfig) => _includes(selectedColumnIds, fieldConfig.id));
  const applicableRecordTypes = _map(_get(formValues, FIELD_IDS.APPLICABLE_RECORD_TYPES), (recordType) => ({ recordTypeName: recordType }));
  return {
    [VIEW_CONFIGURATION_FIELD_IDS.PROPERTIES]: { [ALL_VIEW_PROPERTY_KEYS.TITLE]: tget(formValues, FIELD_IDS.VIEW_DISPLAY_NAME, EMPTY_STRING) },
    [FIELD_IDS.VIEW_NAME]: _get(formValues, FIELD_IDS.VIEW_NAME, EMPTY_STRING),
    [FIELD_IDS.VIEW_TYPE]: _get(formValues, FIELD_IDS.VIEW_TYPE, EMPTY_ARRAY),
    [FIELD_IDS.VIEW_DESCRIPTION]: _get(formValues, FIELD_IDS.VIEW_DESCRIPTION, EMPTY_STRING),
    [FIELD_IDS.COLUMN_TABLE]: columnMapper(selectedFieldsConfig, viewType),
    [FIELD_IDS.APPLICABLE_RECORD_TYPES]: applicableRecordTypes,
  };
};

const getRecordTypeOptions = (entity) => {
  const recordTypes = tget(entity, 'recordTypeDefinition.recordTypes', EMPTY_ARRAY);
  return _map(recordTypes, (recordType) => ({ label: tget(recordType, 'displayName'), value: tget(recordType, 'name') }));
};

export { getFormFields, getTableConfig, getFormSections, getViewSummary, getRecordTypeOptions };
