import _castArray from 'lodash/castArray';
import _isEmpty from 'lodash/isEmpty';
import _unset from 'lodash/unset';

// Utils
import { tget } from '@tekion/tekion-base/utils/general';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

// Components
import ConditionBuilder from '../../../../../../../../../organisms/conditionBuilder';
import SendEmailsActionDefinitionSection from '../../../../../../actionBuilder/actionBuilderForm/organisms/sendEmailsActionDefinitionSection';

// Helpers
import { convertFieldsToOptions } from '../../../../../../actionBuilder/actionBuilderForm/formHelpers/actionBuilderForm.fieldHelpers';
import { isConditionRequiredRule } from '../../../../../../../../../organisms/conditionBuilder/helpers/conditionBuilder.general.helpers';

// Constants
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../../../../constants/actionBuilder.constants';
import CONDITION_BUILDER_MODES from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.modes';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import {
  ACTION_BUILDER_ACTION_TYPES,
  ACTION_DEFINITION_CONDITION_VARIABLE_NAME,
  ACTION_DEFINITION_OPERATOR_OPTIONS,
  FILTER_CONDITION_VARIABLE_NAME,
} from '../../../../../../actionBuilder/actionBuilderForm/constants/actionBuilderForm.general';
import ACTION_BUILDER_FIELDS from '../../../../../../actionBuilder/actionBuilderForm/formHelpers/actionBuilderForm.constantFields';
import { CONDITION_BUILDER_TYPES } from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import { GET_RECORD_FIELD_IDS, RECORD_COUNT_FIELD } from '../constants/recordActionModal.fieldIds';
import { MODAL_MODES } from '../../../../constants/workflowBuilder.constants';

import { TARGET_ENTITY_LABELS } from '../constants/recordActionModal.constants';

const getFields = (
  mode,
  values,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  mapOfVariableToEntityNameForFilterCondition,
  mapOfVariableToEntityNameForActionDefinition,
  emailTemplateDefStore,
) => {
  const actionType = tget(values, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE);
  const assignedVariable = getArraySafeValue(tget(values, ACTION_DEFINITION_FIELD_IDS.ASSIGNED_VARIABLE, ['']));
  _unset(mapOfVariableToEntityName, assignedVariable);
  const targetEntity = getArraySafeValue(tget(values, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME));

  return {
    ...ACTION_BUILDER_FIELDS,
    [GET_RECORD_FIELD_IDS.RECORD_COUNT_FIELD_ID]: RECORD_COUNT_FIELD,
    [ACTION_DEFINITION_FIELD_IDS.ACTION_NAME]: addToRenderOptions(ACTION_BUILDER_FIELDS[ACTION_DEFINITION_FIELD_IDS.ACTION_NAME], [
      { path: 'required', value: true },
    ]),
    [ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME]: addToRenderOptions(ACTION_BUILDER_FIELDS[ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME], [
      { path: 'isDisabled', value: _isEmpty(actionType) || mode === MODAL_MODES.EDIT },
      { path: 'label', value: TARGET_ENTITY_LABELS[actionType] },
      { path: 'formMode', value: mode },
    ]),
    [ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS]: addToRenderOptions(ACTION_BUILDER_FIELDS[ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS], [
      { path: 'fieldOptions', value: convertFieldsToOptions(tget(conditionBuilderFieldDefinitionObject, targetEntity), actionType, true) },
    ]),
    [ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT]: addToRenderOptions(ACTION_BUILDER_FIELDS[ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT], [
      {
        path: 'size',
        value: 6,
      },
    ]),
    [ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION]: {
      id: ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION,
      renderer: ConditionBuilder,
      renderOptions: {
        containerClassname: fieldLayoutStyles.fieldC,
        mode: CONDITION_BUILDER_MODES.ACTION_MODE,
        conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
        validators: [isConditionRequiredRule(CONDITION_BUILDER_MODES.ACTION_MODE)],
        required: true,
        childProps: {
          [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
            label: '',
            childProps: {
              [CONDITION_FIELD_IDS.FIELD]: {
                label: __('Field'),
                mapOfVariableToEntityName: mapOfVariableToEntityNameForActionDefinition,
                conditionBuilderFieldDefinitionObject,
                isVariablePrefixNeeded: false,
                variableNameForPrefixNotNeeded: ACTION_DEFINITION_CONDITION_VARIABLE_NAME,
                isCreatableFieldOnly: actionType === ACTION_BUILDER_ACTION_TYPES.CREATE_RECORD,
                isEditableFieldOnly:
                  actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD ||
                  actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORDS ||
                  actionType === ACTION_BUILDER_ACTION_TYPES.UPSERT_RECORD,
              },
              [CONDITION_FIELD_IDS.OPERATOR]: {
                label: __('Operator'),
                mapOfVariableToEntityName: mapOfVariableToEntityNameForActionDefinition,
                conditionBuilderFieldDefinitionObject,
                isVariablePrefixNeeded: false,
                variableNameForPrefixNotNeeded: ACTION_DEFINITION_CONDITION_VARIABLE_NAME,
                isCustomOperatorOptions: true,
                customOperatorOptions: ACTION_DEFINITION_OPERATOR_OPTIONS,
              },
              [CONDITION_FIELD_IDS.VALUES]: {
                showFieldSwitcher: true,
                label: __('Value'),
                conditionBuilderMode: CONDITION_BUILDER_MODES.ACTION_MODE,
                mapOfVariableToEntityName: { ...mapOfVariableToEntityName, ...mapOfVariableToEntityNameForActionDefinition },
                conditionBuilderFieldDefinitionObject,
                isResourceFieldExcluded: false,
                isVariablePrefixNeeded: false,
                variableNameForPrefixNotNeeded: ACTION_DEFINITION_CONDITION_VARIABLE_NAME,
                attributeExcludeVariableNames: _castArray(ACTION_DEFINITION_CONDITION_VARIABLE_NAME),
              },
            },
          },
        },
      },
    },
    [ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS]: {
      id: ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS,
      renderer: ConditionBuilder,
      renderOptions: {
        containerClassname: fieldLayoutStyles.fieldC,
        header: __('Filter conditions on target record'),
        mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
        conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
        validators: [isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)],
        required: true,
        childProps: {
          [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
            label: '',
            childProps: {
              [CONDITION_FIELD_IDS.FIELD]: {
                label: __('Field'),
                isVariablePrefixNeeded: false,
                variableNameForPrefixNotNeeded: FILTER_CONDITION_VARIABLE_NAME,
                mapOfVariableToEntityName: mapOfVariableToEntityNameForFilterCondition,
                conditionBuilderFieldDefinitionObject,
              },
              [CONDITION_FIELD_IDS.OPERATOR]: {
                label: __('Operator'),
                mapOfVariableToEntityName: mapOfVariableToEntityNameForFilterCondition,
                conditionBuilderFieldDefinitionObject,
                isVariablePrefixNeeded: false,
                variableNameForPrefixNotNeeded: FILTER_CONDITION_VARIABLE_NAME,
              },
              [CONDITION_FIELD_IDS.VALUES]: {
                showFieldSwitcher: true,
                label: __('Value'),
                mapOfVariableToEntityName: { ...mapOfVariableToEntityName, ...mapOfVariableToEntityNameForFilterCondition },
                conditionBuilderFieldDefinitionObject,
                isResourceFieldExcluded: false,
                isVariablePrefixNeeded: false,
                keepAttributeAsArray: true,
                variableNameForPrefixNotNeeded: FILTER_CONDITION_VARIABLE_NAME,
                attributeExcludeVariableNames: _castArray(FILTER_CONDITION_VARIABLE_NAME),
              },
            },
          },
        },
      },
    },
    [ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION]: {
      id: ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION,
      renderer: SendEmailsActionDefinitionSection,
      renderOptions: {
        conditionBuilderFieldDefinitionObject,
        mapOfVariableToEntityName,
        templateDefinitions: emailTemplateDefStore,
        actionType,
        isMultipleTemplateSelectAllowed: false,
      },
    },
  };
};

export default getFields;
