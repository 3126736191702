import React from 'react';
import PropTypes from 'prop-types';
import { tget } from '@tekion/tekion-base/utils/general';
import Icon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import ACTION_TYPES from '../../constants/tenantUserForm.actionTypes';

import styles from './deleteIcon.module.scss';

const DeleteIcon = ({ index, tdProps }) => {
  const { onAction } = tget(tdProps, 'rest');

  const handleDeleteUserWorkspace = () => {
    onAction({ type: ACTION_TYPES.ON_USER_WORKSPACE_DELETE, payload: { index } });
  };

  return (
    <Icon size={SIZES.MD} className={styles.iconContainer} onClick={handleDeleteUserWorkspace}>
      icon-trash
    </Icon>
  );
};

DeleteIcon.propTypes = {
  index: PropTypes.number.isRequired,
  tdProps: PropTypes.object.isRequired,
};

export default DeleteIcon;
