import { defaultMemoize } from 'reselect';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import _filter from 'lodash/filter';
import _isNil from 'lodash/isNil';
import _set from 'lodash/set';
import _replace from 'lodash/replace';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import CONDITION_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import { FIELD_IDS } from '../../../../../../constants/entityRecordGroup.constants';
import { RECORD_GROUP_TYPES } from '../../../../../../constants/general.constants';

const getFormValues = (recordGroupDetails) => {
  const { displayName, description, assetType } = recordGroupDetails;
  const newValues = {};

  newValues[FIELD_IDS.RECORD_GROUP_NAME] = displayName;
  newValues[FIELD_IDS.ENTITY] = _replace(assetType, 'ENTITY#', '');
  newValues[FIELD_IDS.DESCRIPTION] = description;
  newValues[FIELD_IDS.CONDITION] = {
    [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: [EMPTY_OBJECT],
    [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: '1',
  };

  const condition = _get(recordGroupDetails, 'condition', EMPTY_OBJECT);
  const criteriaList = _get(condition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY);

  newValues[FIELD_IDS.CONDITION] = {
    ...condition,
    [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: _map(criteriaList, (criteria) => {
      const resourceFieldValue = _get(criteria, CONDITION_FIELD_IDS.FIELD, EMPTY_ARRAY);
      return {
        ...criteria,
        [CONDITION_FIELD_IDS.FIELD]: _castArray(resourceFieldValue),
      };
    }),
  };

  return newValues;
};

const getPayload = defaultMemoize((value) => {
  const name = _get(value, FIELD_IDS.RECORD_GROUP_NAME, EMPTY_STRING);
  const entity = _get(value, FIELD_IDS.ENTITY, EMPTY_STRING);
  const displayName = _get(value, FIELD_IDS.RECORD_GROUP_NAME, EMPTY_STRING);
  const description = _get(value, FIELD_IDS.DESCRIPTION, EMPTY_STRING);
  const assetType = `ENTITY#${getArraySafeValue(entity)}`;

  const data = {
    groupType: RECORD_GROUP_TYPES.DYNAMIC,
    name,
    displayName,
    description,
    assetType,
  };
  let condition = _get(value, FIELD_IDS.CONDITION, EMPTY_OBJECT);

  if (_isEmpty(condition)) {
    return data;
  }

  const criteriaList = _get(condition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY);
  const newCriteriaList = _filter(
    criteriaList,
    (criteria) =>
      !_isEmpty(criteria) &&
      !_isNil(_get(criteria, CONDITION_FIELD_IDS.FIELD, null)) &&
      !_isNil(_get(criteria, CONDITION_FIELD_IDS.OPERATOR, null)) &&
      !_isNil(_get(criteria, CONDITION_FIELD_IDS.VALUES, null)),
  );

  if (_isEmpty(newCriteriaList)) {
    return data;
  }

  condition = {
    ...condition,
    [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: _map(newCriteriaList, (criteria) => {
      const resourceFieldValue = _get(criteria, CONDITION_FIELD_IDS.FIELD, EMPTY_ARRAY);
      return {
        ...criteria,
        [CONDITION_FIELD_IDS.FIELD]: getArraySafeValue(resourceFieldValue),
      };
    }),
  };
  _set(data, 'condition', condition);

  return data;
});

export { getFormValues, getPayload };
