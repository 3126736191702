import _reduce from 'lodash/reduce';
import _uniqBy from 'lodash/uniqBy';
import _has from 'lodash/has';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _nth from 'lodash/nth';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _split from 'lodash/split';

import { EMPTY_STRING, EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { ACTION_BUILDER_ACTION_TYPES } from '../../../constants/actionBuilderForm.general';
import fieldDefinitionReader from '../../../../../../../../readers/fieldDefinition.reader';

const getFieldOptionsAndValuesForValidationRule = (actionType, targetEntityFieldsByName, validationRule) => {
  const criteriaList = _get(validationRule, 'condition.criteriaList', EMPTY_ARRAY);
  const uniqCriteriaList = _uniqBy(criteriaList, 'fieldName');
  const defaultFieldDefsOptionObject = _reduce(
    uniqCriteriaList,
    (ans, criteria) => {
      const selectedValidationField = _get(criteria, 'fieldName', EMPTY_STRING);
      const sanitizedSelectedValidationField = _split(selectedValidationField, '.');
      const fieldName = _nth(sanitizedSelectedValidationField, 1);
      const fieldDef = _get(targetEntityFieldsByName, fieldName, EMPTY_OBJECT);
      const isFieldCreatable = fieldDefinitionReader.creatable(fieldDef);
      const isFieldEditable = fieldDefinitionReader.editable(fieldDef);
      const createConditionValue = actionType === ACTION_BUILDER_ACTION_TYPES.CREATE_RECORD && isFieldCreatable;
      const updateConditionValue = actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD && isFieldEditable;

      if (!_isEmpty(fieldDef) && (createConditionValue || updateConditionValue)) {
        _set(ans, fieldDefinitionReader.name(fieldDef), {
          label: fieldDefinitionReader.displayName(fieldDef),
          value: fieldDefinitionReader.name(fieldDef),
          isDisabled: true,
          isClearable: false,
        });
      }

      return ans;
    },
    {},
  );

  const defaultFieldValues = _map(defaultFieldDefsOptionObject, 'value');

  const fieldOptions = _reduce(
    targetEntityFieldsByName,
    (ans, fieldDef) => {
      const isFieldCreatable = fieldDefinitionReader.creatable(fieldDef);
      const isFieldEditable = fieldDefinitionReader.editable(fieldDef);
      const createConditionValue = actionType === ACTION_BUILDER_ACTION_TYPES.CREATE_RECORD && isFieldCreatable;
      const updateConditionValue = actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD && isFieldEditable;
      if (!_isEmpty(fieldDef) && (createConditionValue || updateConditionValue)) {
        if (_has(defaultFieldDefsOptionObject, fieldDefinitionReader.name(fieldDef))) {
          ans.push(defaultFieldDefsOptionObject[fieldDefinitionReader.name(fieldDef)]);
        } else {
          ans.push({ label: fieldDefinitionReader.displayName(fieldDef), value: fieldDefinitionReader.name(fieldDef) });
        }
      }
      return ans;
    },
    [],
  );
  return { fieldOptions, defaultFieldValues };
};

export { getFieldOptionsAndValuesForValidationRule };
