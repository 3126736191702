const ADD_ROW = 'ADD_ROW';
const DELETE_ROW = 'DELETE_ROW';
const ADD_COLUMN = 'ADD_COLUMN';
const DELETE_COLUMN = 'DELETE_COLUMN';
const CHANGE_CELL_BACKGROUND_COLOR = 'CHANGE_CELL_BACKGROUND_COLOR';
const DELETE_TABLE = 'DELETE_TABLE';

const ACTION_TYPES = {
  ADD_COLUMN,
  ADD_ROW,
  DELETE_COLUMN,
  DELETE_ROW,
  CHANGE_CELL_BACKGROUND_COLOR,
  DELETE_TABLE,
};

export default ACTION_TYPES;
