import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import ServerCredentialServices from '../services/serverCredential.services';

const fetchServerCredentialByName = async (name) => {
  try {
    const response = await ServerCredentialServices.fetchServerCredentialByName(name);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch server credential, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createServerCredential = async (payload) => {
  try {
    const response = await ServerCredentialServices.createServerCredential(payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Server Credential created successfully.'));

    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create server credential, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateServerCredentialByName = async (name, payload) => {
  try {
    const response = await ServerCredentialServices.updateServerCredentialByName(name, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Server Credential updated successfully.'));

    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update server credential, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const searchServerCredentials = async (payload) => {
  try {
    const response = await ServerCredentialServices.searchServerCredentials(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to search server credential, please try again later.')));
    return EMPTY_ARRAY;
  }
};

export { fetchServerCredentialByName, createServerCredential, updateServerCredentialByName, searchServerCredentials };
