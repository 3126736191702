const DASHBOARD_TYPES = {
  USER_CREATED: 'USER_CREATED',
};

const COLUMN_TYPES = {
  DIMENSION: 'DIMENSION',
  METRIC: 'METRIC',
};

const RELATIONSHIP_FIELD_COLUMN_DATA = {
  COLUMN_NAME: 'columnName',
  DISPLAY_FIELD: 'displayField',
  FIELD: 'field',
  ENTITY_NAME: 'entityName',
};

export { DASHBOARD_TYPES, COLUMN_TYPES, RELATIONSHIP_FIELD_COLUMN_DATA };
