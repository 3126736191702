import React from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import ViewViewer from '../../../organisms/viewViewer/ViewViewer';

import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../constants/viewBuilder.constants';

const CellViewViewer = ({ original, viewConfiguration, entity }) => {
  if (_isEmpty(viewConfiguration) || _isEmpty(_get(viewConfiguration, VIEW_CONFIGURATION_FIELD_IDS.NAME))) {
    return null;
  }

  return (
    <ViewViewer
      isPreviewMode
      isFilterPersist={false}
      viewConfigName={_get(viewConfiguration, VIEW_CONFIGURATION_FIELD_IDS.NAME)}
      viewConfiguration={viewConfiguration}
      entity={entity}
      entityRecord={original}
    />
  );
};

CellViewViewer.propTypes = {
  viewConfiguration: PropTypes.object,
  entity: PropTypes.object,
  original: PropTypes.object,
};

CellViewViewer.defaultProps = {
  viewConfiguration: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  original: EMPTY_OBJECT,
};

export default makeCellRenderer(CellViewViewer);
