import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { TOASTER_TYPE, toaster } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { fallBackForFunctionUsingPromise, generateFunction } from '../factories/functionFactory';
import { triggerEventToApplicationRenderer } from '../eventEmitters/helpers/applicationRendererEventEmitter.helpers';
import { triggerEventToWidget } from '../eventEmitters/helpers/visualBuilderEventEmitter.helpers';

import {
  FALLBACK_FOR_ARGUMENT_NAME_VALUE,
  EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS,
  ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT,
} from '../constants/eventHandlers.constants';
import { FIELD_IDS as EVENT_FIELD_IDS, EVENT_META_DATA_FIELD_IDS, SCRIPT_TYPES } from '../constants/event.fieldIds';
import { EVENT_ACTIONS, EVENT_ACTIONS_TYPES } from '../constants/eventActions.constants';
import { COMPONENT_CONFIG_KEYS } from '../constants/visualBuilder';

const convertEventHandlersFromArrayToObjectByEventName = (eventHandlers) =>
  _reduce(
    eventHandlers,
    (result, eventData) => {
      const eventType = _get(eventData, EVENT_FIELD_IDS.EVENT_TYPE);
      const properties = _get(eventData, EVENT_FIELD_IDS.PROPERTIES);
      _set(result, eventType, properties);
      return result;
    },
    {},
  );

const getArgumentValueForScript = (mapOfArgumentNameAvailableInScriptToValue) =>
  _map(ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT, (value) =>
    tget(mapOfArgumentNameAvailableInScriptToValue, value, FALLBACK_FOR_ARGUMENT_NAME_VALUE[value]),
  );

const copyPageLinkToClipboard = async (link) => {
  try {
    await navigator.clipboard.writeText(link);
    toaster(TOASTER_TYPE.SUCCESS, __('Link copied.'));
  } catch {
    toaster(TOASTER_TYPE.ERROR, __('Error copying link.'));
  }
};

const executeCopyPageLinkToClipboard = async (metaData, entityRecord) => {
  const pageId = tget(metaData, EVENT_META_DATA_FIELD_IDS.PAGE_NAME_TO_NAVIGATE);
  const recordId = tget(entityRecord, 'id');
  const currentURL = window.location.href;
  const searchParams = window.location.search;

  const searchParamURL = new URLSearchParams(searchParams);
  searchParamURL.set('pageName', pageId);
  searchParamURL.set('pageRecordId', recordId);

  const newUrl = `${currentURL}?${searchParamURL.toString()}`;
  await copyPageLinkToClipboard(newUrl);
};

const executeEventFromEventViewConfigData = async (
  eventHandler,
  mapOfArgumentNameAvailableInScriptToValue,
  entityRecord = {},
  widgetName = EMPTY_STRING,
) => {
  const eventDataSubEventType = _get(eventHandler, EVENT_FIELD_IDS.SUB_EVENT_TYPE);
  const metaData = tget(eventHandler, EVENT_FIELD_IDS.META_DATA, EMPTY_OBJECT);

  const returnValue = { [EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS.IS_RETURN_VALUE_PRESENT]: false };

  if (eventDataSubEventType === EVENT_ACTIONS_TYPES.SCRIPT) {
    const scriptGeneratorType = tget(metaData, EVENT_FIELD_IDS.GENERATOR_TYPE, SCRIPT_TYPES.SYNC);
    const fallbackOfScript = scriptGeneratorType === SCRIPT_TYPES.SYNC ? EMPTY_STRING : fallBackForFunctionUsingPromise;

    const script = tget(eventHandler, EVENT_FIELD_IDS.SCRIPT, fallbackOfScript);

    let scriptReturnValue;

    if (scriptGeneratorType === SCRIPT_TYPES.SYNC) {
      const codeToExecute = generateFunction([...ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT], script);
      scriptReturnValue = codeToExecute(...getArgumentValueForScript(mapOfArgumentNameAvailableInScriptToValue));
    } else if (scriptGeneratorType === SCRIPT_TYPES.ASYNC) {
      const codeToExecute = generateFunction([...ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT], script);
      scriptReturnValue = await codeToExecute(...getArgumentValueForScript(mapOfArgumentNameAvailableInScriptToValue));
    }

    if (!_isEmpty(scriptReturnValue)) {
      _set(returnValue, EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS.IS_RETURN_VALUE_PRESENT, true);
      _set(returnValue, EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS.RETURN_VALUE, scriptReturnValue);
    }
  } else if (eventDataSubEventType === EVENT_ACTIONS_TYPES.PRE_DEFINED) {
    const actionToExecute = tget(eventHandler, EVENT_FIELD_IDS.ACTION_TO_EXECUTE);

    switch (actionToExecute) {
      case EVENT_ACTIONS.NAVIGATE: {
        triggerEventToApplicationRenderer(EVENT_ACTIONS.NAVIGATE, { ...metaData, entityRecord });
        break;
      }

      case EVENT_ACTIONS.TRIGGER_ACTION_BUILDER_ACTION: {
        const targetWidgetName = tget(metaData, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING);
        triggerEventToWidget(targetWidgetName, EVENT_ACTIONS.TRIGGER_ACTION_BUILDER_ACTION, { ...metaData, entityRecord });
        break;
      }

      case EVENT_ACTIONS.TOGGLE_COMMENT_SECTION: {
        triggerEventToWidget(widgetName, EVENT_ACTIONS.TOGGLE_COMMENT_SECTION, { ...metaData, entityRecord });
        break;
      }

      case EVENT_ACTIONS.COPY_PAGE_LINK_TO_CLIPBOARD: {
        await executeCopyPageLinkToClipboard(metaData, entityRecord);
        break;
      }

      case EVENT_ACTIONS.SHOW_AUDIT_LOG_DRAWER: {
        triggerEventToApplicationRenderer(EVENT_ACTIONS.SHOW_AUDIT_LOG_DRAWER, { ...metaData, entityRecord });
        break;
      }

      default: {
        break;
      }
    }
  }

  return returnValue;
};

export { convertEventHandlersFromArrayToObjectByEventName, executeEventFromEventViewConfigData };
