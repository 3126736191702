import _isEmpty from 'lodash/isEmpty';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';

import SearchFieldsTable from '../components/searchFieldsTable/SearchFieldsTable';

import { FIELD_IDS } from '../constants/searchOptionsConfigurator.constants';

import styles from '../searchOptionsConfigurator.module.scss';

const SEARCH_OPTION_NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const SEARCH_OPTION_DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Display Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const SEARCH_METADATA_FIELD = {
  id: FIELD_IDS.SEARCH_METADATA,
  renderer: SearchFieldsTable,
  renderOptions: {
    label: __('Field'),
  },
};

const RELATIONSHIP_FIELD_INFO_FIELD = {
  id: FIELD_IDS.RELATIONSHIP_FIELD_INFO,
  renderer: FieldLabel,
  renderOptions: {
    label: __('Note'),
    helpText: __('In case of Relationship Fields, only fields belonging to the same entity can be provided for search.'),
    fieldLabelClassName: styles.infoFieldLabel,
    infoBadgeClassName: styles.infoBadge,
  },
};

const SEARCH_OPTIONS_FORM_FIELDS = {
  [FIELD_IDS.NAME]: SEARCH_OPTION_NAME_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: SEARCH_OPTION_DISPLAY_NAME_FIELD,
  [FIELD_IDS.SEARCH_METADATA]: SEARCH_METADATA_FIELD,
  [FIELD_IDS.RELATIONSHIP_FIELD_INFO]: RELATIONSHIP_FIELD_INFO_FIELD,
};

const getFields = (searchFields, searchableFieldOptions, relationshipFieldOptions) => ({
  ...SEARCH_OPTIONS_FORM_FIELDS,
  [FIELD_IDS.SEARCH_METADATA]: addToRenderOptions(SEARCH_OPTIONS_FORM_FIELDS[FIELD_IDS.SEARCH_METADATA], [
    { path: 'data', value: searchFields },
    { path: 'searchableFieldOptions', value: !_isEmpty(relationshipFieldOptions) ? relationshipFieldOptions : searchableFieldOptions },
  ]),
});

export default getFields;
