// components
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';

// utils
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

// constants
import FIELD_IDS from './templateVariableForm.fieldIds';

// styles
import styles from '../templateVariableForm.module.scss';

export const SECTIONS = [
  {
    className: styles.sectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.VARIABLE_NAME],
      },
    ],
  },
];

export const FIELDS = {
  [FIELD_IDS.VARIABLE_NAME]: {
    renderer: TextInput,
    renderOptions: {
      label: __('Variable Name'),
      required: true,
      validators: [isRequiredRule],
    },
  },
};
