// Plate
import { withProps } from '@udecode/plate-common';
import { withStyledProps, StyledElement, StyledLeaf } from '@udecode/plate-styled-components';
import { MARK_BOLD, MARK_ITALIC, MARK_UNDERLINE, MARK_STRIKETHROUGH } from '@udecode/plate-basic-marks';
import { MARK_FONT_SIZE, MARK_BG_COLOR, MARK_COLOR } from '@udecode/plate-font';
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_H4, ELEMENT_H5, ELEMENT_H6 } from '@udecode/plate-heading';
import { ELEMENT_IMAGE } from '@udecode/plate-image';
import { ELEMENT_LINK } from '@udecode/plate-link';
import { ELEMENT_LI, ELEMENT_OL, ELEMENT_UL } from '@udecode/plate-list';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { ELEMENT_TABLE, ELEMENT_TD, ELEMENT_TH, ELEMENT_TR } from '@udecode/plate-table';
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote';
import { ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE } from '@udecode/plate-code-block';
import { BlockquoteElement } from '@udecode/plate-block-quote-ui';
import { CodeBlockElement, CodeLineElement } from '@udecode/plate-code-block-ui';
import { css } from 'styled-components';

// custom components
import { TableCell, Table, TableHeaderCell } from './table';
import Image from './image';
import Video from './video';
import Link from './link';
import FontStylingCoomponent from './fontStylingCoomponent';
// import TemplateVariable from './templateVariable';

// constants
import { ELEMENT_VIDEO } from '../plugins/video';
// import { ELEMENT_TEMPLATE_VARIABLE } from '../plugins/templateVariable';

export const components = {
  [ELEMENT_BLOCKQUOTE]: BlockquoteElement,
  [ELEMENT_CODE_BLOCK]: withProps(CodeBlockElement, {
    options: {
      hotkey: ['java', 'javascript'],
      syntax: true,
      syntaxPopularFirst: false,
    },
    styles: {
      root: [
        css`
          background-color: #f6f6f7;
          height: 100%;
          float: none;
          width: 75%;
          padding: 1rem;
          select {
            margin-bottom: 10px;
            float: none !important;
          }
          code {
            color: black;
            padding: 1rem;
          }
        `,
      ],
    },
  }),
  [ELEMENT_CODE_LINE]: CodeLineElement,
  [ELEMENT_H1]: withProps(StyledElement, { as: 'h1' }),
  [ELEMENT_H2]: withProps(StyledElement, { as: 'h2' }),
  [ELEMENT_H3]: withProps(StyledElement, { as: 'h3' }),
  [ELEMENT_H4]: withProps(StyledElement, { as: 'h4' }),
  [ELEMENT_H5]: withProps(StyledElement, { as: 'h5' }),
  [ELEMENT_H6]: withProps(StyledElement, { as: 'h6' }),
  [ELEMENT_IMAGE]: withProps(Image, {
    uploadSuccessMessage: __('Your file is successfully uploaded.Please wait for sometime to load the Image'),
  }),
  [ELEMENT_VIDEO]: Video,
  [ELEMENT_LI]: withProps(StyledElement, { as: 'li' }),
  [ELEMENT_UL]: withProps(StyledElement, { as: 'ul' }),
  [ELEMENT_OL]: withProps(StyledElement, { as: 'ol' }),
  [ELEMENT_PARAGRAPH]: withProps(StyledElement, {
    as: 'p',
    styles: {
      root: {
        margin: '.4rem 0',
      },
    },
  }),
  [ELEMENT_TABLE]: Table,
  [ELEMENT_TD]: TableCell,
  [ELEMENT_TH]: TableHeaderCell,
  [ELEMENT_TR]: withProps(StyledElement, { as: 'tr' }),
  [MARK_BOLD]: withProps(StyledLeaf, { as: 'strong' }),
  [MARK_ITALIC]: withProps(StyledLeaf, { as: 'em' }),
  [MARK_STRIKETHROUGH]: withProps(StyledLeaf, { as: 's' }),
  [MARK_UNDERLINE]: withProps(StyledLeaf, { as: 'u' }),
  [MARK_COLOR]: FontStylingCoomponent,
  [MARK_BG_COLOR]: withStyledProps(StyledLeaf, {
    leafProps: {
      [MARK_BG_COLOR]: ['backgroundColor'],
    },
  }),
  [MARK_FONT_SIZE]: FontStylingCoomponent,
  [ELEMENT_LINK]: Link,
};
