const TENANT_USER_MANAGEMENT_COLUMN_IDS = {
  NAME: 'NAME',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  COUNTRY_CODE: 'countryCode',
  EMAIL: 'email',
  HOME_WORKSPACE: 'homeWorkspaceId',
  ROLE: 'roleId',
  DISPLAY_NAME: 'displayName',
  ACTIVE: 'active',
  LOCK_USER: 'lockUser',
  PROFILE_PICTURE: 'profilePicture',
};

export default TENANT_USER_MANAGEMENT_COLUMN_IDS;
