import _get from 'lodash/get';
import _set from 'lodash/set';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _head from 'lodash/head';
import _forEach from 'lodash/forEach';
import _uniq from 'lodash/uniq';
import _size from 'lodash/size';
import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';
import _snakeCase from 'lodash/snakeCase';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import {
  fetchRecordSharingRuleList,
  createRecordSharingRule,
  editRecordSharingRule,
  deleteRecordSharingRule,
} from '../../../../../actions/recordSharingRules.actions';
import { getWorkspaceUserList } from '../../../../../actions/workspaceUserManagement.actions';
import { getAllRecordGroups } from '../../../../../actions/recordGroup.actions';

import { getModalFormSubmitPayload, getRecordGroupName, isRecordGroupName } from './recordSharingRuleList.helpers';
import { FORM_MODES } from '../../../../../constants/general.constants';
import { FIELD_IDS as ENTITY_RECORD_GROUP_FIELD_IDS } from '../../../../../constants/entityRecordGroup.constants';
import RECORD_GROUP_RULE_LIST_COLUMN_IDS from '../constants/recordSharingRuleList.columnIds';
import ACTION_TYPES from '../constants/recordSharingRuleList.actionTypes';
import { ASSIGN_TYPES, PRINCIPAL_TYPES, SHARING_RULE_MODAL_TITLE } from '../constants/recordSharingRuleList.general.constants';
import { FIELD_IDS as RECORD_SHARING_RULE_FIELD_IDS } from '../../../../../constants/recordSharingRule.constants';

const handleInit = async ({ getState, setState }) => {
  const { match } = getState();
  const entityName = _get(match, 'params.entityName', '');

  setState({ loading: true });
  const recordSharingRuleList = await fetchRecordSharingRuleList(entityName);
  let assignedUserIds = [];
  let assignedUserGroupNames = [];
  const allEntityRecordGroupsNames = [];
  let getAllAssignedUsersDataByKeyId = {};
  let getAllAssignedUserGroupsDataByKeyName = {};
  let getAllEntityRecordGroupDetailsByName = {};

  _forEach(recordSharingRuleList, (rule) => {
    const recordGroupName = _get(rule, RECORD_GROUP_RULE_LIST_COLUMN_IDS.RECORD_GROUP_NAME, EMPTY_STRING);
    if (!_isEmpty(recordGroupName)) {
      allEntityRecordGroupsNames.push(recordGroupName);
    }
    const sharingSettings = _get(rule, 'sharingSettings', EMPTY_ARRAY);
    let allAssignedIds = [];
    _forEach(sharingSettings, (shareSetting) => {
      const principalType = _get(shareSetting, 'principalType', '');

      if (principalType === PRINCIPAL_TYPES.USER) {
        allAssignedIds = _get(shareSetting, 'principalIdentifiers', []);
      }
    });

    _forEach(allAssignedIds, (id) => {
      if (isRecordGroupName(id)) {
        assignedUserGroupNames = [...assignedUserGroupNames, getRecordGroupName(id)];
      } else {
        assignedUserIds = [...assignedUserIds, id];
      }
    });
  });

  _uniq(assignedUserGroupNames);
  _uniq(assignedUserIds);

  if (!_isEmpty(assignedUserIds)) {
    const userDataPayload = {
      rows: _size(assignedUserIds),
      filters: [
        {
          field: 'id',
          values: assignedUserIds,
          filterType: OPERATORS.IN,
        },
      ],
    };
    const getAllAssignedUsersDataResponse = await getWorkspaceUserList(userDataPayload);
    getAllAssignedUsersDataByKeyId = _keyBy(tget(getAllAssignedUsersDataResponse, 'hits', EMPTY_ARRAY), 'id');
  }

  if (!_isEmpty(assignedUserGroupNames)) {
    const userGroupDataPayload = {
      rows: _size(assignedUserGroupNames),
      filters: [
        {
          field: ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME,
          values: assignedUserGroupNames,
          filterType: OPERATORS.IN,
        },
      ],
      assetType: 'USER',
    };
    const getAllAssignedUserGroupsDataResponse = await getAllRecordGroups(userGroupDataPayload);
    getAllAssignedUserGroupsDataByKeyName = _keyBy(
      tget(getAllAssignedUserGroupsDataResponse, 'hits', EMPTY_ARRAY),
      ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME,
    );
  }

  if (!_isEmpty(allEntityRecordGroupsNames)) {
    const entityRecordGroupDataPayload = {
      rows: _size(allEntityRecordGroupsNames),
      filters: [
        {
          field: ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME,
          values: allEntityRecordGroupsNames,
          filterType: OPERATORS.IN,
        },
      ],
      assetType: entityName,
    };
    const getAllEntityRecordGroupDetailResponse = await getAllRecordGroups(entityRecordGroupDataPayload);
    getAllEntityRecordGroupDetailsByName = _keyBy(
      tget(getAllEntityRecordGroupDetailResponse, 'hits', EMPTY_ARRAY),
      ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME,
    );
  }

  setState({
    loading: false,
    totalNumberOfEntries: 0,
    recordSharingRuleList,
    filteredRecordSharingRuleList: recordSharingRuleList,
    nextPageToken: '',
    entityName,
    getAllAssignedUsersDataByKeyId,
    getAllAssignedUserGroupsDataByKeyName,
    getAllEntityRecordGroupDetailsByName,
  });
};

const handleSearchApply = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { recordSharingRuleList } = getState();
  const searchText = _get(params, 'value', '');
  const filteredRecordSharingRuleList = _filter(recordSharingRuleList, (rule) => {
    const ruleName = _get(rule, RECORD_GROUP_RULE_LIST_COLUMN_IDS.RULE_NAME, EMPTY_STRING);
    return _includes(ruleName, searchText);
  });

  setState({
    searchText,
    filteredRecordSharingRuleList,
  });
};

const handleCreateNewRule = ({ setState }) => {
  setState({
    isAssignRecordSharingRuleModalVisible: true,
    modalFormMode: FORM_MODES.CREATE,
    modalTitle: SHARING_RULE_MODAL_TITLE.CREATE_RULE,
  });
};

const handleEditSharingRuleRequest = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { getAllAssignedUsersDataByKeyId, getAllAssignedUserGroupsDataByKeyName } = getState();
  const sharingSettings = _get(params, 'sharingSettings', EMPTY_ARRAY);
  const sharingSettingsKeyByPrincipalType = _keyBy(sharingSettings, 'principalType');
  const allAssignedIds = _get(sharingSettingsKeyByPrincipalType, 'USER.principalIdentifiers', EMPTY_ARRAY);
  let assignedUserList = [];
  let assignedUserGroupList = [];

  _forEach(allAssignedIds, (id) => {
    if (isRecordGroupName(id)) {
      const groupInfo = _get(getAllAssignedUserGroupsDataByKeyName, getRecordGroupName(id));
      assignedUserGroupList = [
        ...assignedUserGroupList,
        {
          ...groupInfo,
          label: _get(groupInfo, ENTITY_RECORD_GROUP_FIELD_IDS.DISPLAY_NAME),
          value: _get(groupInfo, ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME),
        },
      ];
    } else {
      const userInfo = _get(getAllAssignedUsersDataByKeyId, id);
      assignedUserList = [
        ...assignedUserList,
        {
          ...userInfo,
          label: `${_get(userInfo, 'entity.firstname', EMPTY_STRING)}  ${_get(userInfo, 'entity.lastname')}`,
          value: _get(userInfo, 'id', EMPTY_STRING),
        },
      ];
    }
  });

  setState({
    isAssignRecordSharingRuleModalVisible: true,
    modalFormMode: FORM_MODES.EDIT,
    modalTitle: SHARING_RULE_MODAL_TITLE.EDIT_RULE,
    recordSharingRuleFormValues: params,
    assignedUserGroupList,
    assignedUserList,
    filteredAssignedUserGroupList: assignedUserGroupList,
    filteredAssignedUserList: assignedUserList,
  });
};

const handleTableItemClick = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const recordSharingRuleData = _get(params, 'value.original', EMPTY_OBJECT);
  handleEditSharingRuleRequest({ getState, setState, params: recordSharingRuleData });
};

const handleFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { recordSharingRuleFormValues } = getState();
  const updatedValues = { ...recordSharingRuleFormValues, [id]: value };
  if (id === RECORD_SHARING_RULE_FIELD_IDS.RULE_DISPLAY_NAME) {
    _set(updatedValues, RECORD_SHARING_RULE_FIELD_IDS.RECORD_GROUP_RULE_NAME, _snakeCase(value));
  }

  setState({ recordSharingRuleFormValues: updatedValues });
};

const handleModalCancelRequest = ({ setState }) => {
  setState({
    isAssignRecordSharingRuleModalVisible: false,
    recordSharingRuleFormValues: {},
    assignedUserGroupList: [],
    assignedUserList: [],
    filteredAssignedUserGroupList: [],
    filteredAssignedUserList: [],
  });
};

const handleSearchUsers = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { assignedUserList, assignedUserGroupList } = getState();
  const searchText = _get(params, 'searchTerm', '');
  const assignType = _get(params, 'assignType', '');
  if (assignType === ASSIGN_TYPES.USER) {
    const filteredAssignedUserList = _filter(assignedUserList, (user) => _includes(_get(user, 'name'), searchText));
    setState({ userSearchText: searchText, filteredAssignedUserList });
  } else {
    const filteredAssignedUserGroupList = _filter(assignedUserGroupList, (group) => _includes(_get(group, 'displayName'), searchText));
    setState({ userGroupSearchText: searchText, filteredAssignedUserGroupList });
  }
};

const handleRemoveAssignedUsers = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { assignedUserList, assignedUserGroupList, filteredAssignedUserGroupList, filteredAssignedUserList } = getState();
  const assignType = _get(params, 'assignType');
  const index = _get(params, 'index');

  if (assignType === ASSIGN_TYPES.USER) {
    const updatedFilteredAssignUserList = [...filteredAssignedUserList];
    const removedUser = updatedFilteredAssignUserList.splice(index, 1);
    const updatedAssignedUserList = _filter(assignedUserList, (user) => _get(user, 'id') !== _get(_head(removedUser), 'id'));

    setState({ filteredAssignedUserList: updatedFilteredAssignUserList, assignedUserList: updatedAssignedUserList });
  } else {
    const updateFilteredAssignUserGroupList = [...filteredAssignedUserGroupList];
    const removedUserGroup = updateFilteredAssignUserGroupList.splice(index, 1);
    const updatedAssignedUserGroupList = _filter(
      assignedUserGroupList,
      (group) =>
        _get(group, ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME) !==
        _get(_head(removedUserGroup), ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME),
    );

    setState({ filteredAssignedUserGroupList: updateFilteredAssignUserGroupList, assignedUserGroupList: updatedAssignedUserGroupList });
  }
};

const handleChangeAssignedUsers = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { userSearchText } = getState();
  const updatedAssignedUserList = _get(params, 'selectedUsers');
  setState({ assignedUserList: updatedAssignedUserList }, () =>
    handleSearchUsers({ getState, setState, params: { searchTerm: userSearchText, assignType: ASSIGN_TYPES.USER } }),
  );
};

const handleChangeAssignedUserGroups = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { userGroupSearchText } = getState();
  const updatedAssignedUserGroupList = _get(params, 'selectedUserGroups');
  setState({ assignedUserGroupList: updatedAssignedUserGroupList }, () =>
    handleSearchUsers({ getState, setState, params: { searchTerm: userGroupSearchText, assignType: ASSIGN_TYPES.USER_GROUP } }),
  );
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;
  setState({ errors });
};

const handleSharingRuleModalFormSubmit = async ({ getState, setState }) => {
  const { modalFormMode, recordSharingRuleFormValues, assignedUserList, assignedUserGroupList, entityName } = getState();
  const recordSharingRuleName = _get(recordSharingRuleFormValues, RECORD_SHARING_RULE_FIELD_IDS.RECORD_GROUP_RULE_NAME);
  const payload = getModalFormSubmitPayload(assignedUserGroupList, assignedUserList, recordSharingRuleFormValues);
  setState({ isAssignModalSubmitting: true });
  if (modalFormMode === FORM_MODES.CREATE) {
    await createRecordSharingRule(entityName, payload);
  } else {
    await editRecordSharingRule(recordSharingRuleName, entityName, payload);
  }
  setState({ isAssignRecordSharingRuleModalVisible: false, isAssignModalSubmitting: false }, () => handleInit({ getState, setState }));
};

const handleDeleteRuleRowActionClick = ({ setState, params }) => {
  const sharingRuleDisplayName = tget(params, RECORD_SHARING_RULE_FIELD_IDS.RULE_DISPLAY_NAME);

  setState({
    isConfirmationModalVisible: true,
    recordSharingRuleDataToPerformAction: params,
    confirmationModalTitle: __('Delete {{sharingRuleDisplayName}} sharing rule', { sharingRuleDisplayName }),
  });
};

const handleDeleteRuleConfirmationDoneClick = async ({ getState, setState }) => {
  const { recordSharingRuleDataToPerformAction, entityName } = getState();

  setState({ isRuleDeleting: true });

  const recordSharingName = tget(recordSharingRuleDataToPerformAction, RECORD_SHARING_RULE_FIELD_IDS.RECORD_GROUP_RULE_NAME);

  await deleteRecordSharingRule(recordSharingName, entityName);

  setState({ isRuleDeleting: false, isConfirmationModalVisible: false });

  handleInit({ getState, setState });
};

const handleDeleteRuleConfirmationCancelClick = ({ setState }) => {
  setState({ isConfirmationModalVisible: false, recordSharingRuleDataToPerformAction: {} });
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSharingRuleModalFormSubmit,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchApply,
  [ACTION_TYPES.CREATE_RULE]: handleCreateNewRule,
  [ACTION_TYPES.ON_ASSIGN_MODAL_CANCEL_REQUEST]: handleModalCancelRequest,
  [ACTION_TYPES.ON_SEARCH_USER]: handleSearchUsers,
  [ACTION_TYPES.ON_REMOVE_ASSIGNED_USERS]: handleRemoveAssignedUsers,
  [ACTION_TYPES.ON_CHANGE_ASSIGNED_USERS]: handleChangeAssignedUsers,
  [ACTION_TYPES.ON_CHANGE_ASSIGNED_USER_GROUPS]: handleChangeAssignedUserGroups,
  [ACTION_TYPES.EDIT_RULE]: handleEditSharingRuleRequest,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleTableItemClick,
  [ACTION_TYPES.DELETE_RULE]: handleDeleteRuleRowActionClick,
  [ACTION_TYPES.DELETE_RULE_CONFIRMATION_DONE_CLICK]: handleDeleteRuleConfirmationDoneClick,
  [ACTION_TYPES.DELETE_RULE_CONFIRMATION_CANCEL_CLICK]: handleDeleteRuleConfirmationCancelClick,
};

export default ACTION_HANDLERS;
