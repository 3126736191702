import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

// Constants
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { getFetchEntityPayload } from './helpers/entityTab.helpers';

import { COLUMN_IDS } from './helpers/entityTab.columns';
import { fetchEntities } from '../../../../../../actions/entityManagement.actions';
import { TAB_IDS } from '../../constants/tabs.config';

const handleInit = async ({ setState, getState, params }) => {
  const { pageSize, searchText } = getState();
  const currentPageSize = _get(params, 'pageSize', pageSize);
  const currentSearchText = _get(params, 'searchText', searchText);
  const currentPageToken = _get(params, 'nextPageToken');

  setState({ loading: true });

  const payload = getFetchEntityPayload({
    searchText: currentSearchText,
    searchField: COLUMN_IDS.DISPLAY_NAME,
    pageSize: currentPageSize,
    nextPageToken: currentPageToken,
  });
  const response = await fetchEntities(payload);
  const entities = tget(response, 'hits', EMPTY_ARRAY);

  setState({
    entities,
    totalNumberOfEntries: _get(response, 'count', 0),
    loading: false,
    nextPageToken: _get(response, 'nextPageToken'),
  });
};

const handleSearch = async ({ setState, getState, params }) => {
  const searchText = _get(params, 'value');
  setState(
    {
      searchText,
      currentPage: 0,
    },
    () => {
      handleInit({ getState, setState, params: { searchText: _get(params, 'value') } });
    },
  );
};

const handlePageUpdate = ({ setState, getState, params }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');
  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }

  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleInit({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const handleRowClick = ({ getState, params }) => {
  const { history } = getState();
  const entity = _get(params, 'value.original');
  const entityName = entity.name;

  const pathname = `${TAB_IDS.ENTITIES}/${entityName}`;
  history.push({ pathname });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_SEARCH_TERM_UPDATE]: handleSearch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleRowClick,
};

export default ACTION_HANDLERS;
