import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _size from 'lodash/size';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _values from 'lodash/values';
import _reverse from 'lodash/reverse';
import _isEmpty from 'lodash/isEmpty';
import _trim from 'lodash/trim';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { isImage } from '@tekion/tekion-base/helpers/media.helper';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import Gallery from '@tekion/tekion-components/molecules/Gallery';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import Icon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Avatar from '@tekion/tekion-components/atoms/Avatar/Avatar';

import { GALLERY_MODES } from './imageThumbnail.constants';

import style from './imageThumbnail.module.scss';
import 'antd/lib/avatar/style/index';

const ImageThumbnails = (props) => {
  const {
    isGalleryControlsEnabled,
    isAvatarDisplay,
    viewOnly,
    disablePrint,
    nextPrevButton,
    displayCount,
    mode,
    className,
    avatarDisplayName,
    thumbnailImageClassName,
    mediaList,
    removeMedia,
    onSelection,
  } = props;

  const gallery = useRef();
  const remainingMediaCount = _size(mediaList) - displayCount;
  const thumbnailsToShow = _filter(_reverse([...mediaList]), (imageObj, index) => index < displayCount);
  const avatarUrl = _get(getArraySafeValue(mediaList), 'url', EMPTY_STRING);

  const onImageClick = useCallback(
    (index) => (event) => {
      event.stopPropagation();
      gallery.current.openGallery(index);
    },
    [],
  );

  const onGalleryClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const renderImage = useCallback(
    (imgObj) => {
      if (_get(imgObj, 'isUploading', false)) {
        return <span className={style.thumbnailCounterHolder}>{`${imgObj.progress.toFixed()}%`}</span>;
      }
      if (isImage(_get(imgObj, 'contentType'))) {
        return (
          <div className={cx(style.thumbnailImageContainer)}>
            <img alt={__('no Preview')} src={_get(imgObj, 'url')} className={cx(style.thumbnailImage, thumbnailImageClassName)} />
            <div className={style.thumbnailLabel}>{__(_get(imgObj, 'name', EMPTY_STRING))}</div>
          </div>
        );
      }
      return null;
    },
    [thumbnailImageClassName],
  );

  const renderAvatar = useCallback(
    () => (
      <div className={style.avatarContainer}>
        {_trim(avatarDisplayName) || _trim(avatarUrl) ? (
          <Avatar src={avatarUrl} name={avatarDisplayName} displayName={false} size="small" />
        ) : (
          <Icon size={SIZES.XXL}>icon-avatar-outline</Icon>
        )}
      </div>
    ),
    [avatarDisplayName, avatarUrl],
  );

  const renderRemainingMedia = useCallback(
    () => (
      <div
        onClick={onImageClick(displayCount)}
        className={cx(
          style.thumbnailNumberContainer,
          {
            [style.thumbnailNumberContainerSpecialEffect]: _isEmpty(thumbnailImageClassName),
          },
          thumbnailImageClassName,
        )}
      >
        <div className={style.thumbnailNumberHolder}>
          <span>{` + ${remainingMediaCount}`}</span>
        </div>
      </div>
    ),
    [displayCount, onImageClick, remainingMediaCount, thumbnailImageClassName],
  );

  return (
    <div className={cx(style.imageThumbnail, { 'd-inline-flex': true }, className)} onClick={onGalleryClick}>
      <PropertyControlledComponent controllerProperty={!isAvatarDisplay} fallback={renderAvatar()}>
        <div className={style.container}>
          {_map(thumbnailsToShow, (imgObj, index) => (
            <div
              key={_get(imgObj, 'mediaId')}
              className={cx(
                style.thumbnailImageHolder,
                {
                  [style.thumbnailImageBorder]: !isImage(_get(imgObj, 'contentType')) || _get(imgObj, 'isUploading', false),
                },
                thumbnailImageClassName,
              )}
              onClick={onImageClick(index)}
            >
              {renderImage(imgObj)}
            </div>
          ))}
          {remainingMediaCount > 0 && renderRemainingMedia()}
        </div>
        <Gallery
          isControlsEnabled={isGalleryControlsEnabled}
          disablePrint={disablePrint}
          mode={mode}
          nextPrevButton={nextPrevButton}
          ref={gallery}
          mediaList={mediaList}
          onDelete={viewOnly ? _noop : removeMedia}
          onSelection={viewOnly ? _noop : onSelection}
        />
      </PropertyControlledComponent>
    </div>
  );
};

ImageThumbnails.propTypes = {
  isGalleryControlsEnabled: PropTypes.bool,
  nextPrevButton: PropTypes.bool,
  isAvatarDisplay: PropTypes.bool,
  viewOnly: PropTypes.bool,
  disablePrint: PropTypes.bool,
  displayCount: PropTypes.number,
  className: PropTypes.string,
  avatarDisplayName: PropTypes.string,
  thumbnailImageClassName: PropTypes.string,
  mediaList: PropTypes.array,
  mode: PropTypes.oneOf(_values(GALLERY_MODES)),
  removeMedia: PropTypes.func,
  onSelection: PropTypes.func,
};

ImageThumbnails.defaultProps = {
  isGalleryControlsEnabled: true,
  viewOnly: false,
  nextPrevButton: false,
  disablePrint: false,
  isAvatarDisplay: false,
  displayCount: 1,
  className: '',
  avatarDisplayName: EMPTY_STRING,
  thumbnailImageClassName: EMPTY_STRING,
  mode: GALLERY_MODES.DEFAULT,
  mediaList: EMPTY_ARRAY,
  removeMedia: _noop,
  onSelection: _noop,
};

export default ImageThumbnails;
