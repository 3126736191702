import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _size from 'lodash/size';
import _map from 'lodash/map';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import SectionTitle from '../sectionTitle/SectionTitle';
import FormSectionRow from '../formSectionRow/FormSectionRow';
import ViewComponent from '../viewComponent/ViewComponent';
import DropZone from '../dropZone/DropZone';
import DropZoneContainer from '../dropZone/DropZoneContainer';

import { COMPONENT_TYPES, MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE } from '../../constants/viewBuilder.constants';
import { VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './formSection.module.scss';

const FormSection = (props) => {
  const { className, componentConfig, ...restProps } = props;

  const sectionId = _get(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
  const sectionRows = _get(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.CHILDREN, EMPTY_ARRAY);

  return (
    <div key={sectionId} className={cx(styles.formSection, className)}>
      <SectionTitle className={styles.formSectionTitle} componentConfig={componentConfig} />

      <div className={styles.formSectionRows}>
        {_map(sectionRows, (formSectionRow, index) => (
          <React.Fragment key={tget(formSectionRow, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID)}>
            <DropZone
              id={sectionId}
              index={index}
              className={styles.dropZoneGutter}
              onHoverClassName={styles.onHoverDropZoneGutter}
              hiddenDropZoneClassName={styles.hiddenDropZone}
              acceptedComponentTypes={MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE[COMPONENT_TYPES.FORM_VIEW_SECTION]}
            />
            <ViewComponent index={index} key={formSectionRow.sectionId} className={styles.formSectionRow} componentConfig={formSectionRow}>
              <FormSectionRow rowIndex={index} componentConfig={formSectionRow} {...restProps} />
            </ViewComponent>
          </React.Fragment>
        ))}

        <DropZoneContainer
          id={sectionId}
          index={_size(sectionRows) + 1}
          className={styles.dropZoneContainer}
          acceptedComponentTypes={MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE[COMPONENT_TYPES.FORM_VIEW_SECTION]}
        />
      </div>
    </div>
  );
};

FormSection.propTypes = {
  className: PropTypes.string,
  componentConfig: PropTypes.object.isRequired,
};

FormSection.defaultProps = {
  className: '',
};

export default React.memo(FormSection);
