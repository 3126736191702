import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TPopover, { POPOVER_TRIGGER } from '@tekion/tekion-components/molecules/popover';
import IconAsBtn from '@tekion/tekion-components/atoms/iconAsBtn';
import Content from '@tekion/tekion-components/atoms/Content';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import historyManager from '../../builders/historyManager';
import { KEY_BOARD_EVENT_KEYS, KEY_BOARD_KEY_CODES } from '../../constants/keyboard.const';

import styles from './historyControlManager.module.scss';

const registerChangeToHistoryManager = (newState) => {
  historyManager.recordChange(newState);
};

const HistoryControlManager = ({ isKeyShortcutEnabled, className, onUndoRedo }) => {
  // For Keyboard event
  // Undo CMD/ctrl + z
  // Redo cmd/ctrl +shift +z
  const handleKeyDown = useCallback(
    (event) => {
      const isCtrlClicked = event.ctrlKey === true || event.metaKey === true;
      const isShiftClicked = event.shiftKey === true;

      event.stopPropagation();
      if (isKeyShortcutEnabled && isCtrlClicked && isShiftClicked && event.keyCode === KEY_BOARD_KEY_CODES.Z_KEY_CODE) {
        onUndoRedo(historyManager.redo());
      } else if (isKeyShortcutEnabled && isCtrlClicked && event.keyCode === KEY_BOARD_KEY_CODES.Z_KEY_CODE) {
        onUndoRedo(historyManager.undo());
      }
    },
    [onUndoRedo, isKeyShortcutEnabled],
  );

  // For Button events
  const handleUndoChanges = useCallback(() => {
    onUndoRedo(historyManager.undo());
  }, [onUndoRedo]);

  const handleRedoChanges = useCallback(() => {
    onUndoRedo(historyManager.redo());
  }, [onUndoRedo]);

  const renderPopoverContent = useCallback((content) => <Content className="p-2">{content}</Content>, []);

  useEffect(() => {
    document.addEventListener(KEY_BOARD_EVENT_KEYS.KEY_DOWN, handleKeyDown);

    return () => {
      document.removeEventListener(KEY_BOARD_EVENT_KEYS.KEY_DOWN, handleKeyDown);

      if (historyManager) {
        historyManager.reset();
      }
    };
  }, [handleKeyDown]);

  return (
    <div className={cx(styles.historyController, className)}>
      <TPopover trigger={POPOVER_TRIGGER.HOVER} content={renderPopoverContent(__('Undo'))}>
        <IconAsBtn
          disabled={!historyManager.canUndo()}
          containerClassName={cx({ [styles.iconContainerClassName]: historyManager.canUndo() })}
          onClick={handleUndoChanges}
        >
          icon-undo
        </IconAsBtn>
      </TPopover>
      <div className={styles.verticalRule} />
      <TPopover trigger={POPOVER_TRIGGER.HOVER} content={renderPopoverContent(__('Redo'))}>
        <IconAsBtn
          disabled={!historyManager.canRedo()}
          containerClassName={cx({ [styles.iconContainerClassName]: historyManager.canRedo() })}
          onClick={handleRedoChanges}
        >
          icon-redo
        </IconAsBtn>
      </TPopover>
    </div>
  );
};

HistoryControlManager.propTypes = {
  isKeyShortcutEnabled: PropTypes.bool,
  className: PropTypes.string,
  onUndoRedo: PropTypes.func.isRequired,
};

HistoryControlManager.defaultProps = {
  isKeyShortcutEnabled: true,
  className: EMPTY_STRING,
};

export { registerChangeToHistoryManager };
export default HistoryControlManager;
