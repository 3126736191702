import React from 'react';
import PropTypes from 'prop-types';

import Icon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

// This Icon Renderer Component is used to display Icons,
// It has an IconName Prop in which the Icon name can be sent for displaying.
// It can be used in Tables as well as components to render icons

const IconRenderer = ({ iconName, iconSize, ...rest }) => (
  <Icon size={iconSize} {...rest}>
    {iconName}
  </Icon>
);

IconRenderer.propTypes = {
  iconSize: PropTypes.string,
  iconName: PropTypes.string,
};

IconRenderer.defaultProps = {
  iconSize: SIZES.L,
  iconName: 'icon-box-filled',
};

export default React.memo(IconRenderer);
