import React from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import Modal from '@tekion/tekion-components/molecules/Modal';
import { Select } from '@tekion/tekion-components/molecules/advancedSelect';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import withMultiSelect from '@tekion/tekion-components/molecules/multiSelect/withMultiSelect';
import withSelectAllOptions from '@tekion/tekion-components/hoc/withSelectAllOptions';

import styles from './addFieldModal.module.scss';

const MultiSelect = withSelectAllOptions(withMultiSelect(Select));

const AddFieldModal = (props) => {
  const { visible, fields, fieldOptions, handleChange, handleModalSubmit, handleClose } = props;

  return (
    <Modal title={__('Add Field')} visible={visible} onCancel={handleClose} width={Modal.SIZES.MD} destroyOnClose onSubmit={handleModalSubmit}>
      <div className={styles.userSelect}>
        <MultiSelect placeholder={__('Select Fields')} key="select-bar" options={fieldOptions} values={fields} onChange={handleChange} isMulti />
      </div>
    </Modal>
  );
};

AddFieldModal.propTypes = {
  visible: PropTypes.bool,
  fields: PropTypes.array,
  fieldOptions: PropTypes.array,
  handleChange: PropTypes.func,
  handleModalSubmit: PropTypes.func,
  handleClose: PropTypes.func,
};

AddFieldModal.defaultProps = {
  visible: false,
  fields: EMPTY_ARRAY,
  fieldOptions: EMPTY_ARRAY,
  handleChange: _noop,
  handleModalSubmit: _noop,
  handleClose: _noop,
};
export default React.memo(AddFieldModal);
