exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".configTab_configTabContainer__5fStM5qKo3{width:100%}[dir] .configTab_configTabContainer__5fStM5qKo3{padding:0 2.4rem;margin-bottom:2.4rem}.configTab_configTabContainer__5fStM5qKo3 .configTab_addNewTabButton__aXFn9XZGrg{display:flex;justify-content:flex-end}[dir] .configTab_configTabContainer__5fStM5qKo3 .configTab_addNewTabButton__aXFn9XZGrg{margin:1rem 0}.configTab_selectContainer__f5C5BMdQMd{width:100%}.configTab_iconContainer__5pugwNmeHZ{width:100%;display:flex;justify-content:center}[dir] .configTab_iconContainer__5pugwNmeHZ{cursor:pointer}.configTab_iconContainer__5pugwNmeHZ.configTab_trashIconContainer__jK7CBdNsHD:hover{color:#007bfe}", ""]);

// Exports
exports.locals = {
	"configTabContainer": "configTab_configTabContainer__5fStM5qKo3",
	"addNewTabButton": "configTab_addNewTabButton__aXFn9XZGrg",
	"selectContainer": "configTab_selectContainer__f5C5BMdQMd",
	"iconContainer": "configTab_iconContainer__5pugwNmeHZ",
	"trashIconContainer": "configTab_trashIconContainer__jK7CBdNsHD"
};