const WORKFLOW_BUILDER_MODAL_TYPES = {
  CONDITION: 'CONDITION',
  ACTION: 'ACTION',
  CONFIRMATION: 'CONFIRMATION',
  DELETE_CONFIRMATION_MODAL: 'DELETE_CONFIRMATION_MODAL',
  DELAY_TASK: 'DELAY_TASK',
  CHANGE_TO_IF: 'CHANGE_TO_IF',
  CHANGE_TO_IF_ELSE: 'CHANGE_TO_IF_ELSE',
  CHECK_FIELD: 'CHECK_FIELD',
  CHANGE_TO_SWITCH: 'CHANGE_TO_SWITCH',
  REPEAT_NODE: 'REPEAT_NODE',
  GOTO: 'GOTO',
  LOOP_NODE: 'LOOP_NODE',
  SWITCH_WORKFLOW: 'SWITCH_WORKFLOW',
  TIME_OF_THE_DAY: 'TIME_OF_THE_DAY',
  SET_VARIABLE: 'SET_VARIABLE',
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
  CALLOUT: 'CALLOUT',
  FETCH_AND_ITERATE: 'FETCH_AND_ITERATE',
  FOR_LOOP: 'FOR_LOOP',
};

export default WORKFLOW_BUILDER_MODAL_TYPES;
