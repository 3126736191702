import Button from '@tekion/tekion-components/atoms/Button';

import ACTION_TYPES from './appList.actionTypes';

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create Application'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.ON_CLICK_CREATE_APPLICATION,
    },
  ],
};

export default SUB_HEADER_PROPS;
