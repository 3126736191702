import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';
import withActions from '@tekion/tekion-components/connectors/withActions';

import RecordSharingRuleAssignModal from './components/recordSharingRuleAssignModal/RecordSharingRuleAssignModal';
import getRuleListColumns from './tableHelpers/recordSharingRuleList.columns';
import { getTableProps } from './tableHelpers/recordSharingRuleList.helpers';
import ACTION_HANDLERS from './tableHelpers/recordSharingRuleList.actionHandlers';
import { HEADER_PROPS, TABLE_MANAGER_PROPS, SUB_HEADER_PROPS } from './constants/recordSharingRuleList.tableConstants';
import { DEFAULT_RULE_LIST_PROPS, DELETE_RULE_CONTENT, TABLE_CONSTANTS } from './constants/recordSharingRuleList.general.constants';
import ACTION_TYPES from './constants/recordSharingRuleList.actionTypes';

const RecordSharingRuleList = ({
  loading,
  isAssignRecordSharingRuleModalVisible,
  isAssignModalSubmitting,
  isConfirmationModalVisible,
  isRuleDeleting,
  pageSize,
  currentPage,
  totalNumberOfEntries,
  searchText,
  userGroupSearchText,
  userSearchText,
  entityName,
  confirmationModalTitle,
  modalTitle,
  recordSharingRuleFormValues,
  getAllAssignedUsersDataByKeyId,
  getAllAssignedUserGroupsDataByKeyName,
  getAllEntityRecordGroupDetailsByName,
  filteredAssignedUserList,
  filteredAssignedUserGroupList,
  assignedUserList,
  assignedUserGroupList,
  filteredRecordSharingRuleList,
  onAction,
}) => {
  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({
        type: actionType,
        payload,
      });
    },
    [onAction],
  );

  const columns = useMemo(
    () => getRuleListColumns(getAllAssignedUsersDataByKeyId, getAllAssignedUserGroupsDataByKeyName, getAllEntityRecordGroupDetailsByName),
    [getAllAssignedUserGroupsDataByKeyName, getAllAssignedUsersDataByKeyId, getAllEntityRecordGroupDetailsByName],
  );

  const tableProps = useMemo(
    () => getTableProps(handleRowAction, currentPage, loading, pageSize, totalNumberOfEntries),
    [currentPage, handleRowAction, loading, pageSize, totalNumberOfEntries],
  );
  const handleConfirmationCancelClick = () => {
    onAction({ type: ACTION_TYPES.DELETE_RULE_CONFIRMATION_CANCEL_CLICK });
  };

  const handleConfirmationDoneClick = () => {
    onAction({ type: ACTION_TYPES.DELETE_RULE_CONFIRMATION_DONE_CLICK });
  };

  return (
    <>
      <TableManager
        tableManagerProps={TABLE_MANAGER_PROPS}
        subHeaderProps={SUB_HEADER_PROPS}
        headerProps={HEADER_PROPS}
        columns={columns}
        data={filteredRecordSharingRuleList}
        searchText={searchText}
        tableProps={tableProps}
        onAction={onAction}
      />
      <RecordSharingRuleAssignModal
        isAssignRecordSharingRuleModalVisible={isAssignRecordSharingRuleModalVisible}
        isAssignModalSubmitting={isAssignModalSubmitting}
        recordSharingRuleFormValues={recordSharingRuleFormValues}
        userSearchText={userSearchText}
        userGroupSearchText={userGroupSearchText}
        entityName={entityName}
        modalTitle={modalTitle}
        assignedUserList={assignedUserList}
        assignedUserGroupList={assignedUserGroupList}
        filteredAssignedUserList={filteredAssignedUserList}
        filteredAssignedUserGroupList={filteredAssignedUserGroupList}
        onAction={onAction}
      />
      <ConfirmationDialog
        isVisible={isConfirmationModalVisible}
        loading={isRuleDeleting}
        title={confirmationModalTitle}
        content={DELETE_RULE_CONTENT}
        onCancel={handleConfirmationCancelClick}
        onSubmit={handleConfirmationDoneClick}
      />
    </>
  );
};
RecordSharingRuleList.propTypes = {
  loading: PropTypes.bool,
  isAssignRecordSharingRuleModalVisible: PropTypes.bool,
  isAssignModalSubmitting: PropTypes.bool,
  isConfirmationModalVisible: PropTypes.bool,
  isRuleDeleting: PropTypes.bool,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalNumberOfEntries: PropTypes.number,
  searchText: PropTypes.string,
  userGroupSearchText: PropTypes.string,
  userSearchText: PropTypes.string,
  entityName: PropTypes.string,
  modalTitle: PropTypes.string,
  confirmationModalTitle: PropTypes.string,
  recordSharingRuleFormValues: PropTypes.object,
  getAllAssignedUsersDataByKeyId: PropTypes.object,
  getAllAssignedUserGroupsDataByKeyName: PropTypes.object,
  getAllEntityRecordGroupDetailsByName: PropTypes.object,
  filteredAssignedUserList: PropTypes.array,
  filteredAssignedUserGroupList: PropTypes.array,
  assignedUserList: PropTypes.array,
  assignedUserGroupList: PropTypes.array,
  filteredRecordSharingRuleList: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

RecordSharingRuleList.defaultProps = {
  loading: false,
  isAssignRecordSharingRuleModalVisible: false,
  isAssignModalSubmitting: false,
  isConfirmationModalVisible: false,
  isRuleDeleting: false,
  pageSize: DEFAULT_PAGE_SIZE,
  currentPage: DEFAULT_PAGE_INFO.current,
  totalNumberOfEntries: TABLE_CONSTANTS.TOTAL_NUMBER_OF_ENTRIES,
  searchText: EMPTY_STRING,
  userGroupSearchText: EMPTY_STRING,
  userSearchText: EMPTY_STRING,
  entityName: EMPTY_STRING,
  modalTitle: EMPTY_STRING,
  confirmationModalTitle: EMPTY_STRING,
  recordSharingRuleFormValues: EMPTY_OBJECT,
  getAllAssignedUsersDataByKeyId: EMPTY_OBJECT,
  getAllAssignedUserGroupsDataByKeyName: EMPTY_OBJECT,
  getAllEntityRecordGroupDetailsByName: EMPTY_OBJECT,
  filteredAssignedUserList: EMPTY_ARRAY,
  filteredAssignedUserGroupList: EMPTY_ARRAY,
  assignedUserList: EMPTY_ARRAY,
  assignedUserGroupList: EMPTY_ARRAY,
  filteredRecordSharingRuleList: EMPTY_ARRAY,
};

export default withActions(DEFAULT_RULE_LIST_PROPS, ACTION_HANDLERS)(RecordSharingRuleList);
