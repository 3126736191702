import React, { useCallback, useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _size from 'lodash/size';
import _map from 'lodash/map';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import DropZone from '../dropZone/DropZone';
import DropZoneContainer from '../dropZone/DropZoneContainer';
import ViewComponent from '../viewComponent/ViewComponent';
import ComponentRendererContainer from '../componentRendererContainer';
import ViewBuilderContext from '../../ViewBuilder.context';

import { isComponentEditable } from '../../helpers/viewBuilder.helper';
import { COMPONENT_TYPES, MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE } from '../../constants/viewBuilder.constants';
import { VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './column.module.scss';

const Column = ({ isPreviewMode, isDeleteEnabled, isDropEnabled, className, columnIndex, style, componentConfig, ...restProps }) => {
  const { sectionId: columnId, children = [] } = componentConfig;
  const { onSelect } = useContext(ViewBuilderContext);

  const handleSelect = useCallback(
    (event) => {
      event.stopPropagation();

      onSelect(columnId, columnIndex);
    },
    [columnId, columnIndex, onSelect],
  );

  const renderColumnDropZone = useCallback(() => {
    if (!isDropEnabled) {
      return null;
    }

    return (
      <DropZoneContainer
        key={columnId}
        id={columnId}
        className={styles.dropZoneContainer}
        index={_size(children)}
        acceptedComponentTypes={MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE[COMPONENT_TYPES.COLUMN]}
      />
    );
  }, [isDropEnabled, children, columnId]);

  const renderSectionInPreviewMode = () => (
    <div key={columnIndex} className={cx(styles.column, styles.previewMode)} style={style} onClick={handleSelect}>
      {_map(children, (childComponentConfig) => {
        const sectionId = tget(childComponentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
        const componentProps = {
          isPreviewMode,
          componentConfig: childComponentConfig,
        };

        return <ComponentRendererContainer key={sectionId} {...componentProps} {...restProps} />;
      })}
    </div>
  );

  const renderSectionInBuilderMode = () => (
    <div key={columnIndex} className={cx(styles.column, styles.builderMode, className)} style={style} onClick={handleSelect}>
      {_map(children, (childComponentConfig, index) => {
        const sectionId = tget(childComponentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
        const type = tget(childComponentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.TYPE);
        const componentProps = {
          isPreviewMode,
          componentConfig: childComponentConfig,
          ...restProps,
        };

        const isEditEnabled = isComponentEditable(childComponentConfig, _get(componentProps, 'entity'));

        if (type === COMPONENT_TYPES.FORM_VIEW_RENDERER) {
          return <ComponentRendererContainer key={sectionId} {...componentProps} />;
        } else {
          return (
            <React.Fragment key={sectionId}>
              {isDropEnabled && (
                <DropZone
                  id={columnId}
                  index={index}
                  className={styles.dropZoneGutter}
                  hiddenDropZoneClassName={styles.hiddenDropZone}
                  onHoverClassName={styles.onHoverDropZoneGutter}
                  acceptedComponentTypes={MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE[COMPONENT_TYPES.COLUMN]}
                />
              )}

              <ViewComponent key={sectionId} index={index} componentConfig={childComponentConfig} isEditEnabled={isEditEnabled}>
                <ComponentRendererContainer {...componentProps} />
              </ViewComponent>
            </React.Fragment>
          );
        }
      })}

      {renderColumnDropZone()}
    </div>
  );

  return isPreviewMode ? renderSectionInPreviewMode() : renderSectionInBuilderMode();
};

Column.propTypes = {
  isPreviewMode: PropTypes.bool,
  isDeleteEnabled: PropTypes.bool,
  isDropEnabled: PropTypes.bool,
  columnIndex: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  componentConfig: PropTypes.object.isRequired,
};

Column.defaultProps = {
  isPreviewMode: false,
  isDeleteEnabled: true,
  isDropEnabled: true,
  columnIndex: 0,
  className: EMPTY_STRING,
  style: EMPTY_OBJECT,
};

export default React.memo(Column);
