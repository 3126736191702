import _isEmpty from 'lodash/isEmpty';

import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { tget } from '@tekion/tekion-base/utils/general';

import { CUSTOM_STYLES_CONFIGURE_FIELD_IDS } from '../constants/customStylesConfigure.fieldIds';
import { CUSTOM_STYLE_TYPES } from '../../../../../../../constants/customStyles.constants';

const getFormFormattedSelectedWidgetProperties = (selectedWidgetProperties) => {
  const customStyles = tget(selectedWidgetProperties, CUSTOM_STYLES_CONFIGURE_FIELD_IDS.CUSTOM_STYLES);
  let updatedCustomStyle = tget(customStyles, 'container.value', '');
  if (!_isEmpty(updatedCustomStyle)) {
    try {
      updatedCustomStyle = JSON.stringify({ ...updatedCustomStyle }, undefined, 4);
      return { ...selectedWidgetProperties, [CUSTOM_STYLES_CONFIGURE_FIELD_IDS.CUSTOM_STYLES]: updatedCustomStyle };
    } catch (err) {
      toaster(TOASTER_TYPE.ERROR, __('Not a valid JSON'));
    }
  }
  return { ...selectedWidgetProperties };
};

const getConfigFormattedSelectedWidgetProperties = (id, selectedWidgetProperties) => {
  let updatedValue = tget(selectedWidgetProperties, id);

  if (!_isEmpty(updatedValue)) {
    try {
      updatedValue = JSON.parse(updatedValue);
      const updatedCustomStylesValue = { container: { type: CUSTOM_STYLE_TYPES.STYLES_OBJECT, value: updatedValue } };
      return { ...selectedWidgetProperties, [id]: updatedCustomStylesValue };
    } catch (err) {
      toaster(TOASTER_TYPE.ERROR, __('Not a valid JSON'));
      return false;
    }
  }
  return { ...selectedWidgetProperties };
};

const getBeautifyFormatJsonString = (customStyles) => {
  if (!_isEmpty(customStyles)) {
    try {
      return JSON.stringify(JSON.parse(customStyles), undefined, 4);
    } catch (err) {
      toaster(TOASTER_TYPE.ERROR, __('Not a valid JSON'));
    }
  }
  return customStyles;
};

export { getFormFormattedSelectedWidgetProperties, getConfigFormattedSelectedWidgetProperties, getBeautifyFormatJsonString };
