import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import updatePasswordApiService from './updatePasswordPage.api';

import { clearLoginData, redirectToLogin } from '../../WithAuth.helpers';
import { getUpdatedPayload } from './updatePasswordPage.helpers';

const handleUpdatePasswordSuccess = () => {
  clearLoginData();
  redirectToLogin(true);
  toaster(TOASTER_TYPE.SUCCESS, __('Password updated'));
};

const handleUpdatePasswordFailure = (error) => {
  toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Something went wrong. Please try again later')));
};

const updatePassword = async (newPassword, updateBtnLoading) => {
  try {
    await updatePasswordApiService.updatePassword(getUpdatedPayload(newPassword));
    handleUpdatePasswordSuccess();
  } catch (error) {
    handleUpdatePasswordFailure(error);
  } finally {
    updateBtnLoading(false);
  }
};

export { updatePassword, handleUpdatePasswordFailure, handleUpdatePasswordSuccess };
