import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _size from 'lodash/size';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';

import { DEFAULT_FILTER_GROUP } from '@tekion/tekion-components/organisms/filterSection';

import withActions from '@tekion/tekion-components/connectors/withActions';

import NavigationItem from '../../../../molecules/NavigationFlow/components/navigationItem';
import withSize from '../../../../connectors/withSize';
import { getTableProps } from './helpers/exportListPage.helpers';
import { COLUMNS } from './constants/exportListPage.columns';
import { createFilterProps } from './helpers/exportListPage.filters';
import ACTION_HANDLERS from './helpers/exportListPage.actionHandlers';
import { TABLE_MANAGER_PROPS, EXPORTS_LIST_PAGE_DEFAULT_PROPS, EXPORT_HEADER_NAVIGATION_DEFAULT_DATA } from './constants/exportListPage.constants';

import styles from './exportListPage.module.scss';

const ExportListPage = ({
  loading,
  contentHeight,
  currentPage,
  pageSize,
  totalCount,
  selectedFilterGroup,
  nextPageToken,
  selectedFilters,
  exportList,
  history,
  onAction,
}) => {
  const handleRowAction = useCallback(
    (actionType, value) => {
      onAction({
        type: actionType,
        payload: {
          value,
        },
      });
    },
    [onAction],
  );

  const tableProps = useMemo(
    () => getTableProps(handleRowAction, { loading, totalCount, currentPage, pageSize }),
    [handleRowAction, currentPage, pageSize, loading, totalCount],
  );

  const handleNavigationItemClick = useCallback(
    (goTo) => {
      if (!_isEmpty(goTo)) {
        history.push(goTo);
      }
    },
    [history],
  );

  const headerNavigation = useMemo(
    () =>
      _map(EXPORT_HEADER_NAVIGATION_DEFAULT_DATA, (data, index) => (
        <NavigationItem
          key={_get(data, 'key', EMPTY_STRING)}
          itemNumber={index}
          totalItems={_size(EXPORT_HEADER_NAVIGATION_DEFAULT_DATA)}
          data={data}
          onNavigationItemClick={handleNavigationItemClick}
        />
      )),
    [handleNavigationItemClick],
  );

  return (
    <Page>
      <Page.Header>
        <Heading className={styles.headerNavigationContainer} size={2}>
          {headerNavigation}
        </Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight }}>
        <TableManager
          loading={loading}
          tokenPagination
          tableManagerProps={TABLE_MANAGER_PROPS}
          tableProps={tableProps}
          columns={COLUMNS}
          pageSize={pageSize}
          data={exportList}
          filterProps={createFilterProps(selectedFilterGroup)}
          selectedFilters={selectedFilters}
          nextPageToken={nextPageToken}
          onAction={onAction}
        />
      </Page.Body>
    </Page>
  );
};

ExportListPage.propTypes = {
  loading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  selectedFilterGroup: PropTypes.string,
  nextPageToken: PropTypes.string,
  selectedFilters: PropTypes.array,
  exportList: PropTypes.array,
  history: PropTypes.object.isRequired,
  onAction: PropTypes.func,
};

ExportListPage.defaultProps = {
  loading: false,
  currentPage: EXPORTS_LIST_PAGE_DEFAULT_PROPS.currentPage,
  pageSize: EXPORTS_LIST_PAGE_DEFAULT_PROPS.pageSize,
  totalCount: 0,
  selectedFilterGroup: DEFAULT_FILTER_GROUP,
  nextPageToken: EMPTY_STRING,
  exportList: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
  onAction: _noop,
};

export default compose(withActions(EXPORTS_LIST_PAGE_DEFAULT_PROPS, ACTION_HANDLERS), withSize({ hasPageHeader: true }))(ExportListPage);
