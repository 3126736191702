import _includes from 'lodash/includes';

import { READ_ONLY, READ_WRITE } from '../../../../../../../../../constants/recordSharingAccessTypes.constants';
import { ShareAndManageRecordUserDetailGetters } from '../../../../../../../hooks/useShareAndManageRecordModal/shareAndManageRecordUserData';
import { PERMISSIONS_ACCESS_TYPES } from '../../../../../../../../../constants/permissionSet.constants';

const hasUserReadAccessType = (shareAndManageRecordUserDetail) => {
  const recordAccessType = ShareAndManageRecordUserDetailGetters.recordAccessType(shareAndManageRecordUserDetail);

  return recordAccessType === READ_ONLY.id;
};

const hasUserReadWriteAccessType = (shareAndManageRecordUserDetail) => {
  const recordAccessType = ShareAndManageRecordUserDetailGetters.recordAccessType(shareAndManageRecordUserDetail);

  return recordAccessType === READ_WRITE.id;
};

const hasUserAccessDenied = (shareAndManageRecordUserDetail) => {
  const entityAccessTypes = ShareAndManageRecordUserDetailGetters.entityAccessTypes(shareAndManageRecordUserDetail);
  const recordAccessType = ShareAndManageRecordUserDetailGetters.recordAccessType(shareAndManageRecordUserDetail);

  if (recordAccessType === READ_ONLY.id && !_includes(entityAccessTypes, PERMISSIONS_ACCESS_TYPES.READ)) {
    return true;
  }

  if (
    recordAccessType === READ_WRITE.id &&
    (!_includes(entityAccessTypes, PERMISSIONS_ACCESS_TYPES.READ) || !_includes(entityAccessTypes, PERMISSIONS_ACCESS_TYPES.UPDATE))
  ) {
    return true;
  }

  return false;
};

export { hasUserAccessDenied, hasUserReadAccessType, hasUserReadWriteAccessType };
