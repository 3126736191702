import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _isNil from 'lodash/isNil';
import _castArray from 'lodash/castArray';

// Utils
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { VALIDATION_OVERRIDE_FIELD_IDS } from '../organisms/validationOverrideSection/validationOverrideSection.constants';
import VALIDATION_OVERRIDE_RULE_FIELD_IDS from '../organisms/validationOverrideSection/validationRulesRenderer/validationRulesRenderer.fieldIds';
import { ACTION_DEFINITION_FIELD_IDS, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS } from '../../../../../../constants/actionBuilder.constants';
import { ACTION_BUILDER_ACTION_TYPES, USER_INPUT_TYPES } from '../constants/actionBuilderForm.general';
import CONDITION_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../../constants/viewBuilder.constants';
import { SEND_EMAILS_ADDRESSEE_TYPE } from '../organisms/sendEmailsActionDefinitionSection/sendEmailsActionDefinitionSection.constants';
import { TEMPLATE_RENDERER_FIELD_IDS } from '../../../../../../organisms/templateSection/templateRenderer/templateRenderer.constant';

const generateValidationRuleOverridePayload = (formValues) => {
  const payload = {};

  const validationOverrides = tget(
    formValues,
    `${ACTION_DEFINITION_FIELD_IDS.VALIDATION_RULE_OVERRIDE}.${VALIDATION_OVERRIDE_FIELD_IDS.VALIDATION_OVERRIDES}`,
    EMPTY_ARRAY,
  );

  const outerUserInput = tget(formValues, ACTION_DEFINITION_FIELD_IDS.USER_INPUT);

  const validationOverridePayloadValue = _reduce(
    validationOverrides,
    (result, rule, ruleName) => {
      const isMessageOverriden = tget(rule, VALIDATION_OVERRIDE_RULE_FIELD_IDS.IS_MESSAGE_OVERRIDEN, false);
      const isUserInput = tget(rule, VALIDATION_OVERRIDE_RULE_FIELD_IDS.USER_INPUT, false);
      const userInputType = tget(rule, VALIDATION_OVERRIDE_RULE_FIELD_IDS.USER_INPUT_TYPE);
      const viewName = getArraySafeValue(tget(rule, VALIDATION_OVERRIDE_RULE_FIELD_IDS.FORM_VIEW_NAME, EMPTY_STRING));
      const selectedFields = tget(rule, VALIDATION_OVERRIDE_RULE_FIELD_IDS.SELECTED_FIELDS, EMPTY_ARRAY);
      const selectedFieldsArray = _map(selectedFields, (field) => ({ fieldName: field }));
      if (isMessageOverriden) {
        if (isUserInput && !outerUserInput) {
          if (userInputType === USER_INPUT_TYPES.FORM) {
            result.push({
              ruleName,
              [VALIDATION_OVERRIDE_RULE_FIELD_IDS.FORM_VIEW_NAME]: viewName,
              ..._pick(rule, [VALIDATION_OVERRIDE_RULE_FIELD_IDS.ERROR_MESSAGE]),
            });
          } else if (userInputType === USER_INPUT_TYPES.FIELD) {
            result.push({
              ruleName,
              [VALIDATION_OVERRIDE_RULE_FIELD_IDS.SELECTED_FIELDS]: selectedFieldsArray,
              ..._pick(rule, [VALIDATION_OVERRIDE_RULE_FIELD_IDS.ERROR_MESSAGE]),
            });
          } else {
            result.push({
              ruleName,
              ..._pick(rule, [VALIDATION_OVERRIDE_RULE_FIELD_IDS.ERROR_MESSAGE]),
            });
          }
        } else {
          result.push({
            ruleName,
            ..._pick(rule, [VALIDATION_OVERRIDE_RULE_FIELD_IDS.ERROR_MESSAGE]),
          });
        }
      }
      return result;
    },
    [],
  );

  _set(payload, VALIDATION_OVERRIDE_FIELD_IDS.VALIDATION_OVERRIDES, validationOverridePayloadValue);

  return payload;
};

const generateSendEmailsActionTypePayload = (formValues) => {
  let sendEmailValues = { ..._get(formValues, ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION, {}) };
  const addresseeType = _get(sendEmailValues, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.ADDRESSEE_TYPE);
  const sourceField = _get(sendEmailValues, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.SOURCE_FIELD);
  const derivedField = _get(sendEmailValues, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY_FIELD);
  const derivedEntity = _get(sendEmailValues, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY);

  if (addresseeType === SEND_EMAILS_ADDRESSEE_TYPE.DERIVED) {
    _set(sendEmailValues, 'emailAddresseeConfig', {
      [SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.SOURCE_FIELD]: sourceField,
      [SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY_FIELD]: getArraySafeValue(derivedField),
      [SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY]: derivedEntity,
    });

    sendEmailValues = _omit(sendEmailValues, [
      SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.SOURCE_FIELD,
      SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY_FIELD,
      SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.TO,
      SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY,
    ]);
  }

  const selectedTemplates = _filter(
    _get(sendEmailValues, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES, EMPTY_ARRAY),
    (value) => !_isEmpty(value),
  );

  const selectedTemplatesAccordingToPayload = _reduce(
    selectedTemplates,
    (ans, selectedTemplate) => {
      const templateName = _get(selectedTemplate, TEMPLATE_RENDERER_FIELD_IDS.SELECTED_TEMPLATE);
      const variableValueMap = _get(selectedTemplate, TEMPLATE_RENDERER_FIELD_IDS.MAP_OF_VARIABLES, EMPTY_OBJECT);
      _set(ans, templateName, variableValueMap);
      return ans;
    },
    {},
  );

  _set(sendEmailValues, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES, selectedTemplatesAccordingToPayload);

  return sendEmailValues;
};

const generatePayloadFromFormValues = (formValues, entityName) => {
  let payload = _omit(formValues, [
    ACTION_DEFINITION_FIELD_IDS.USER_INPUT,
    ACTION_DEFINITION_FIELD_IDS.USER_INPUT_TYPE,
    ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS,
    ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION,
  ]);

  _set(payload, ACTION_DEFINITION_FIELD_IDS.ACTION_LEVEL, 'ENTITY');

  const formActionType = tget(formValues, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE, [null]);
  _set(payload, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE, getArraySafeValue(formActionType));

  const formTargetEntityName = tget(formValues, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME, [null]);
  _set(payload, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME, getArraySafeValue(formTargetEntityName));

  // Entry Condition
  const formEntryCondition = tget(formValues, ACTION_DEFINITION_FIELD_IDS.ENTRY_CONDITION, EMPTY_OBJECT);
  const formEntryConditionCriteriaList = tget(formEntryCondition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST);
  const formEntryConditionExpression = tget(formEntryCondition, CONDITION_BUILDER_FIELD_IDS.EXPRESSION);

  const newEntryConditionCriteriaList = _filter(formEntryConditionCriteriaList, (criteria) => {
    if (!_isEmpty(criteria)) {
      const criteriaFieldName = tget(criteria, 'fieldName');
      _set(criteria, 'fieldName', getArraySafeValue(criteriaFieldName));
    }
    return !_isEmpty(criteria);
  });
  _set(formEntryCondition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, newEntryConditionCriteriaList);
  if (_isEmpty(newEntryConditionCriteriaList) || _isEmpty(formEntryConditionExpression)) {
    _unset(payload, ACTION_DEFINITION_FIELD_IDS.ENTRY_CONDITION);
  }

  // Filter Condition
  const recordsToUpdate = {};
  const conditionObject = tget(formValues, ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS, EMPTY_OBJECT);
  const formFilterCriteriaList = tget(conditionObject, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY);
  const formFilterConditionExpression = tget(conditionObject, CONDITION_BUILDER_FIELD_IDS.EXPRESSION);

  const newFilterConditionCriteriaList = _filter(formFilterCriteriaList, (criteria) => {
    if (!_isEmpty(criteria)) {
      const criteriaFieldName = tget(criteria, 'fieldName', [null]);
      const criteriaValueFieldValue = tget(criteria, CONDITION_FIELD_IDS.VALUES, EMPTY_STRING);
      _set(criteria, 'fieldName', getArraySafeValue(criteriaFieldName));
      _set(criteria, 'values', _castArray(criteriaValueFieldValue));
    }
    return !_isEmpty(criteria);
  });
  _set(conditionObject, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, newFilterConditionCriteriaList);
  if (!(_isEmpty(newFilterConditionCriteriaList) || _isEmpty(formFilterConditionExpression))) {
    _set(recordsToUpdate, 'condition', conditionObject);
  }

  const shouldSortRecords = tget(formValues, ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS, false);
  const sortConditions = tget(formValues, ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS, [null]);
  const filteredSortConditions = _filter(sortConditions, (condition) => !_isEmpty(condition));
  if (shouldSortRecords && !_isEmpty(filteredSortConditions)) {
    _set(recordsToUpdate, 'sortList', filteredSortConditions);
  } else {
    _set(recordsToUpdate, 'sortList', null);
  }

  // Limit
  const formLimit = tget(formValues, ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT, '1');
  _set(recordsToUpdate, 'limit', formLimit);

  if (getArraySafeValue(formActionType) === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORDS) {
    _set(payload, 'recordsToUpdate', recordsToUpdate);
  }
  if (getArraySafeValue(formActionType) === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD) {
    _set(payload, 'recordToUpdate', recordsToUpdate);
  }

  // Entity Name
  _set(payload, 'entityName', entityName);

  // Action Defination
  const formPrefilledData = tget(formValues, ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION, EMPTY_OBJECT);

  const formPrefilledDataCriteriaList = tget(formPrefilledData, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY);
  const modifiedPrefilledDataCriteriaList = {};
  _forEach(formPrefilledDataCriteriaList, (criteria) => {
    if (
      !_isEmpty(criteria) &&
      !_isEmpty(_get(criteria, CONDITION_FIELD_IDS.FIELD)) &&
      !_isNil(_get(criteria, CONDITION_FIELD_IDS.OPERATOR)) &&
      !_isNil(_get(criteria, CONDITION_FIELD_IDS.VALUES))
    ) {
      const criteriaFieldName = tget(criteria, 'fieldName', [null]);
      const criteriaValues = tget(criteria, 'values', EMPTY_STRING);
      modifiedPrefilledDataCriteriaList[getArraySafeValue(criteriaFieldName)] = criteriaValues;
    }
  });

  _set(payload, ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION, modifiedPrefilledDataCriteriaList);
  if (_isEmpty(modifiedPrefilledDataCriteriaList)) {
    _unset(payload, ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION);
  }

  // outer user Input
  const outerUserInput = _get(formValues, ACTION_DEFINITION_FIELD_IDS.USER_INPUT);

  if (outerUserInput && _get(formValues, ACTION_DEFINITION_FIELD_IDS.USER_INPUT_TYPE) === USER_INPUT_TYPES.FIELD) {
    const selectedFields = tget(formValues, ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS, EMPTY_ARRAY);
    if (_isEmpty(selectedFields)) {
      _set(payload, ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS, null);
    } else {
      _set(
        payload,
        ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS,
        _map(selectedFields, (field) => ({ fieldName: field })),
      );
    }
  } else {
    _set(payload, ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS, null);
  }

  if (outerUserInput && _get(formValues, ACTION_DEFINITION_FIELD_IDS.USER_INPUT_TYPE) === USER_INPUT_TYPES.FORM) {
    const formViewName = tget(formValues, ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME, [null]);
    _set(payload, ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME, getArraySafeValue(formViewName));
  } else {
    _set(payload, ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME, null);
  }

  // validation Rule
  _set(payload, ACTION_DEFINITION_FIELD_IDS.VALIDATION_RULE_OVERRIDE, generateValidationRuleOverridePayload(formValues));

  // Send Email action Type override section
  if (getArraySafeValue(formActionType) === ACTION_BUILDER_ACTION_TYPES.SEND_EMAILS) {
    const sendEmailValuesForPayload = generateSendEmailsActionTypePayload(formValues);

    payload = _omit({ ...payload, ...sendEmailValuesForPayload }, [ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME]);
  }

  payload = _omit(
    payload,
    [ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS, ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT],
    ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS,
  );

  return payload;
};

const getPayloadForViewDefinitionsSearch = (entityNames, viewTypes, viewConfigTypes) => {
  const filters = [
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      values: _castArray(entityNames),
      filterType: OPERATORS.IN,
    },
  ];

  if (!_isEmpty(viewTypes)) {
    filters.push({
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
      values: viewTypes,
      filterType: OPERATORS.IN,
    });
  }

  if (!_isEmpty(viewConfigTypes)) {
    filters.push({
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_CONFIG_TYPE,
      values: viewConfigTypes,
      filterType: OPERATORS.IN,
    });
  }

  return { filters };
};

export { generatePayloadFromFormValues, getPayloadForViewDefinitionsSearch };
