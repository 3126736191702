import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _keys from 'lodash/keys';
import _includes from 'lodash/includes';
import _set from 'lodash/set';
import _uniq from 'lodash/uniq';
import _remove from 'lodash/remove';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import TASK_TYPES from '@tekion/tekion-rule-engine/src/constants/ruleEngineWorkflow.taskTypes';

import { ASSIGNED_VARIABLE_FIELD_IDS } from '../../constants/workflow.assignVariableConstants';

const getNestedLoopNodeIds = (loopNodeId, nodeList, nestedLoopNodeIds) => {
  if (_isEmpty(loopNodeId)) {
    return [];
  }
  nestedLoopNodeIds.push(loopNodeId);
  const loopNode = tget(nodeList, loopNodeId);
  return getNestedLoopNodeIds(tget(loopNode, 'uiMetadata.loopNodeId'), nodeList, nestedLoopNodeIds);
};

const updateGivenNodeScopedVariables = (variablesInfo, nodeId, nodeList, ancestorNodeId, visitedAncestorNodes, isReachable) => {
  if (!ancestorNodeId) {
    return isReachable;
  }

  if (ancestorNodeId === nodeId) {
    return true;
  }

  if (_includes(visitedAncestorNodes, ancestorNodeId)) {
    return isReachable;
  }

  visitedAncestorNodes.push(ancestorNodeId);
  const ancestorNodeData = tget(nodeList, ancestorNodeId);

  let newIsReachable = isReachable;
  if (ancestorNodeData?.nodesByConditionValue) {
    const childNodeKeys = _keys(ancestorNodeData.nodesByConditionValue);

    _forEach(childNodeKeys, (__label) => {
      const id = ancestorNodeData.nodesByConditionValue[__label];
      newIsReachable = newIsReachable || updateGivenNodeScopedVariables(variablesInfo, nodeId, nodeList, id, visitedAncestorNodes, isReachable);
    });
  }

  if (ancestorNodeData?.nodesByRepeatOutcome) {
    const childNodeKeys = _keys(ancestorNodeData.nodesByRepeatOutcome);
    _forEach(childNodeKeys, (__label) => {
      const id = ancestorNodeData.nodesByRepeatOutcome[__label];
      newIsReachable = newIsReachable || updateGivenNodeScopedVariables(variablesInfo, nodeId, nodeList, id, visitedAncestorNodes, isReachable);
    });
  }

  newIsReachable =
    newIsReachable ||
    updateGivenNodeScopedVariables(variablesInfo, nodeId, nodeList, tget(ancestorNodeData, 'nextNodeId'), visitedAncestorNodes, isReachable);

  _remove(visitedAncestorNodes, (visitedNodeId) => visitedNodeId === ancestorNodeId);

  return newIsReachable;
};

const validateByNode = (nodeData, additional, variablesByNodeId) => {
  const workflow = tget(additional, 'workflow');
  const nodeList = tget(workflow, 'nodeList');
  const nodeId = tget(nodeData, 'nodeId');
  const variablesInfo = tget(workflow, 'uiMetadata.variablesInfo');
  let allScopedVariablesByNodeId = [];
  const errorList = [];

  const nestedLoopNodeIds = [];
  getNestedLoopNodeIds(nodeId, nodeList, nestedLoopNodeIds);

  _forEach(nodeList, (node) => {
    const nodeType = tget(node, 'uiMetadata.taskType');
    const ancestorNodeId = tget(node, 'nodeId');
    let isReachable = false;
    let assignedVariable = '';
    if (nodeType === TASK_TYPES.ACTION) {
      isReachable = updateGivenNodeScopedVariables(variablesInfo, nodeId, nodeList, ancestorNodeId, [], isReachable);
      assignedVariable = getArraySafeValue(tget(node, 'userData.data.assignedVariable'));
    }

    if (nodeType === TASK_TYPES.LOOP && _includes(nestedLoopNodeIds, ancestorNodeId)) {
      isReachable = updateGivenNodeScopedVariables(variablesInfo, nodeId, nodeList, ancestorNodeId, [], isReachable);
      assignedVariable = getArraySafeValue(tget(node, 'userData.data.loopVariableName'));
    }

    if (isReachable) {
      if (tget(variablesInfo, `${assignedVariable}.${ASSIGNED_VARIABLE_FIELD_IDS.DECLARED_AT_NODE_ID}`) === ancestorNodeId) {
        allScopedVariablesByNodeId.push(assignedVariable);
      }
    }
  });

  allScopedVariablesByNodeId = _uniq(allScopedVariablesByNodeId);
  const nodeTaskType = tget(nodeData, 'uiMetadata.taskType');
  if (nodeTaskType === TASK_TYPES.ACTION) {
    const assignedVariable = getArraySafeValue(tget(nodeData, 'userData.data.assignedVariable'));
    const declaredAtNodeId = tget(variablesInfo, `${assignedVariable}.${ASSIGNED_VARIABLE_FIELD_IDS.DECLARED_AT_NODE_ID}`, '');

    if (!_isEmpty(assignedVariable) && declaredAtNodeId !== nodeId && !_includes(allScopedVariablesByNodeId, assignedVariable)) {
      errorList.push({ message: `${assignedVariable} variable used which is not scoped for this node` });
    }
  }

  const usedVariables = tget(nodeData, 'uiMetadata.usedVariables', []);
  _forEach(usedVariables, (variable) => {
    if (!_includes(allScopedVariablesByNodeId, variable)) {
      errorList.push({ message: `${variable} variable used which is not scoped for this node` });
    }
  });

  _set(variablesByNodeId, nodeId, allScopedVariablesByNodeId);

  return errorList;
};
export default validateByNode;
