import ApplicationTab from '../components/applicationTab';
import EntityTab from '../components/entityTab';
import ReportsTab from '../components/reportTab';

const TAB_IDS = {
  APPLICATIONS: 'applications',
  ENTITIES: 'entities',
  REPORTING: 'reporting',
};

const TAB_TITLE = {
  [TAB_IDS.APPLICATIONS]: __('Application'),
  [TAB_IDS.ENTITIES]: __('Entity'),
  [TAB_IDS.REPORTING]: __('Reports'),
};

const TAB_COMPONENT = {
  [TAB_IDS.APPLICATIONS]: ApplicationTab,
  [TAB_IDS.ENTITIES]: EntityTab,
  [TAB_IDS.REPORTING]: ReportsTab,
};

const TAB_ICON = {
  [TAB_IDS.APPLICATIONS]: 'icon-thumbnail-view',
  [TAB_IDS.ENTITIES]: 'icon-group-by',
  [TAB_IDS.REPORTING]: 'icon-align-bottom',
};

export { TAB_IDS, TAB_TITLE, TAB_COMPONENT, TAB_ICON };
