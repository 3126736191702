exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".customStylesConfigure_stylesConfigureContainer__9eFP8eJxnr{display:flex;width:100%;justify-content:end}[dir] .customStylesConfigure_stylesConfigureContainer__9eFP8eJxnr{padding:.2rem 1rem 1rem !important}.customStylesConfigure_stylesConfigureContainer__9eFP8eJxnr .customStylesConfigure_stylesConfigureButton__pJiCUmQ6Lr{height:2.4rem;font-weight:bolder;font-size:1.56rem}.customStylesConfigure_beautifyButton__joxB1sF6fh{height:2.2rem;font-weight:bolder;align-self:flex-end;font-size:1.56rem}[dir=ltr] .customStylesConfigure_beautifyButton__joxB1sF6fh{padding:.2rem .8rem 4rem 2rem !important}[dir=rtl] .customStylesConfigure_beautifyButton__joxB1sF6fh{padding:.2rem 2rem 4rem .8rem !important}[dir] .customStylesConfigure_textAreaContainer__59a4G1Hn8r{margin:2rem 2rem 0}", ""]);

// Exports
exports.locals = {
	"stylesConfigureContainer": "customStylesConfigure_stylesConfigureContainer__9eFP8eJxnr",
	"stylesConfigureButton": "customStylesConfigure_stylesConfigureButton__pJiCUmQ6Lr",
	"beautifyButton": "customStylesConfigure_beautifyButton__joxB1sF6fh",
	"textAreaContainer": "customStylesConfigure_textAreaContainer__59a4G1Hn8r"
};