import FIELD_IDS from './jobScheduleForm.fieldIds';

const SCHEDULE_TYPES = {
  CRON: 'CRON',
  SIMPLE: 'SIMPLE',
  ONE_TIME: 'ONE_TYPE',
};

const SCHEDULE_TYPE_OPTIONS = [
  { label: __('CRON'), value: SCHEDULE_TYPES.CRON },
  { label: __('Simple'), value: SCHEDULE_TYPES.SIMPLE },
  { label: __('One Time'), value: SCHEDULE_TYPES.ONE_TIME },
];

const STATUS_TYPE = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

const STATUS_OPTIONS = [
  { label: __('Active'), value: STATUS_TYPE.ACTIVE },
  { label: __('Inactive'), value: STATUS_TYPE.INACTIVE },
];

const JOB_SCHEDULE_FORM_CONTEXT_ID = 'JOB_SCHEDULE_FORM_CONTEXT_ID';

const TASK_TYPES = {
  EXPORT: 'EXPORT',
  REPORT: 'REPORT',
};

const TASK_TYPE_OPTIONS = [{ label: __('Export'), value: TASK_TYPES.EXPORT }];

const EXPORT_FILE_DOWNLOAD_TYPE = {
  CSV: 'CSV',
  EXCEL: 'EXCEL',
};

const EXPORT_FILE_DOWNLOAD_TYPE_OPTIONS = [
  { label: __('Csv'), value: EXPORT_FILE_DOWNLOAD_TYPE.CSV },
  { label: __('Excel'), value: EXPORT_FILE_DOWNLOAD_TYPE.EXCEL },
];

const DEFAULT_PAYLOAD_PICK_VALUE = [FIELD_IDS.NAME, FIELD_IDS.DISPLAY_NAME, FIELD_IDS.DESCRIPTION, FIELD_IDS.STATUS];

export {
  SCHEDULE_TYPES,
  SCHEDULE_TYPE_OPTIONS,
  STATUS_TYPE,
  STATUS_OPTIONS,
  JOB_SCHEDULE_FORM_CONTEXT_ID,
  TASK_TYPES,
  TASK_TYPE_OPTIONS,
  EXPORT_FILE_DOWNLOAD_TYPE,
  EXPORT_FILE_DOWNLOAD_TYPE_OPTIONS,
  DEFAULT_PAYLOAD_PICK_VALUE,
};
