import _set from 'lodash/set';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _split from 'lodash/split';
import _nth from 'lodash/nth';
import _has from 'lodash/has';
import _find from 'lodash/find';
import _cloneDeep from 'lodash/cloneDeep';
import _filter from 'lodash/filter';
import _isNil from 'lodash/isNil';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';

import { getResolvedValue } from '../../../../utils/visualBuilder.utils';

import { NAMESPACES } from '../../../../constants/general.constants';
import { COMPONENT_CONFIG_KEYS, VIEW_OVERRIDE_FIELD_IDS, WIDGET_TYPES } from '../../constants/visualBuilder.general.constants';

import entityReader from '../../../../readers/entity.reader';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

const getDefaultFilter = (pageEntityRecord, masterEntityFieldDefs, relatedFieldName, isLookupField = true) => {
  const relatedFieldEntityFieldDef = _find(masterEntityFieldDefs, { name: relatedFieldName });

  const relatedFieldEntityLookupField = isLookupField
    ? fieldDefinitionReader.lookupField(relatedFieldEntityFieldDef)
    : `${relatedFieldName}.${fieldDefinitionReader.lookupField(relatedFieldEntityFieldDef)}`;

  let values = [];

  if (_has(pageEntityRecord, relatedFieldEntityLookupField)) {
    values = _castArray(tget(pageEntityRecord, relatedFieldEntityLookupField, EMPTY_ARRAY));
  } else if (_has(pageEntityRecord, ['entity', relatedFieldEntityLookupField])) {
    values = _castArray(tget(pageEntityRecord, ['entity', relatedFieldEntityLookupField], EMPTY_ARRAY));
  }

  // Filtering Null & undefined values
  values = _filter(values, (value) => !_isNil(value));

  return {
    field: isLookupField ? relatedFieldName : fieldDefinitionReader.lookupField(relatedFieldEntityFieldDef),
    filterType: OPERATORS.IN,
    values,
  };
};

const getFilterMetadataAndPreAppliedFilters = (componentConfig, defaultFilter, pageEntityRecord, currentUserData, applicationVariables) => {
  const filters = _map(_get(componentConfig, 'properties.preAppliedFilters', []), (filter) => {
    const firstValue = getArraySafeValue(_get(filter, 'values', EMPTY_ARRAY));
    if (_includes(firstValue, '$')) {
      let resolvedValue = [];
      if (_includes(firstValue, NAMESPACES.CURRENT_VARIABLES)) {
        const variableName = _nth(_split(firstValue, '.'), 1);
        resolvedValue = tget(applicationVariables, variableName, []);
      } else {
        resolvedValue = getResolvedValue(firstValue, {
          [NAMESPACES.CURRENT_RECORD]: pageEntityRecord,
          [NAMESPACES.CURRENT_USER]: currentUserData,
        });
      }
      return { ...filter, values: !_isEmpty(resolvedValue) ? _castArray(resolvedValue) : [] };
    }
    return filter;
  }); // [] because we are pushing object in it

  if (!_isEmpty(defaultFilter)) {
    filters.push(defaultFilter);
  }

  // Commenting this line, as for now we will not be updating the filterMetaData based on the preAppliedFilters provided in Visual Builder.
  // const filterMetadata = _map(filters, (filter) => ({ fieldName: _get(filter, 'field'), upfront: false }));
  return { preAppliedFilters: filters /* filterMetadata */ };
};

const getResolvedSimilarViewDetails = (similarViewDetails, pageEntityRecord) => {
  const searchText = tget(similarViewDetails, 'searchText', EMPTY_STRING);
  const recordId = tget(similarViewDetails, 'recordId', EMPTY_STRING);

  let resolvedSearchText = searchText;
  if (_includes(searchText, NAMESPACES.CURRENT_RECORD)) {
    resolvedSearchText = getResolvedValue(searchText, { [NAMESPACES.CURRENT_RECORD]: pageEntityRecord });
  }

  let resolvedRecordId = recordId;
  if (_includes(recordId, NAMESPACES.CURRENT_RECORD)) {
    resolvedRecordId = getResolvedValue(recordId, { [NAMESPACES.CURRENT_RECORD]: pageEntityRecord });
  }

  const resolvedSimilarViewDetails = _cloneDeep(similarViewDetails);
  _set(resolvedSimilarViewDetails, 'searchText', resolvedSearchText);
  _set(resolvedSimilarViewDetails, 'recordId', resolvedRecordId);

  return resolvedSimilarViewDetails;
};

const getViewOverrides = ({
  widgetType,
  componentConfig,
  pageEntityRecord = {},
  masterEntityDef,
  pageEntityDef,
  currentUserData,
  applicationVariables,
}) => {
  const viewOverrides = {};
  let defaultFilter = {};
  let similarViewDetails = {};

  //  Updating view config according to the widgetType to handle default Filter case
  switch (widgetType) {
    case WIDGET_TYPES.CHILD_WIDGET: {
      const relatedFieldName = _get(componentConfig, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${COMPONENT_CONFIG_KEYS.RELATIVE_FIELD_NAME}`);
      defaultFilter = getDefaultFilter(pageEntityRecord, entityReader.fieldDefinitions(masterEntityDef), relatedFieldName);
      break;
    }

    case WIDGET_TYPES.PARENT_WIDGET: {
      const childFieldName = _get(componentConfig, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${COMPONENT_CONFIG_KEYS.RELATIVE_FIELD_NAME}`);
      defaultFilter = getDefaultFilter(pageEntityRecord, entityReader.fieldDefinitions(pageEntityDef), childFieldName, false);
      break;
    }

    case WIDGET_TYPES.SIMILAR_WIDGET: {
      const searchFieldName = tget(componentConfig, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${COMPONENT_CONFIG_KEYS.FIELD_TO_SEARCH_ON}`);
      const searchFieldValue = `$record.${searchFieldName}`;
      const recordId = '$record.id';
      const similarViewDetailsValueToResolve = {
        searchText: searchFieldValue,
        searchFields: _castArray(searchFieldName),
        recordId,
        isSimilarViewEnabled: true,
      };
      similarViewDetails = getResolvedSimilarViewDetails(similarViewDetailsValueToResolve, pageEntityRecord);
      break;
    }

    case WIDGET_TYPES.STANDARD_WIDGET: {
      break;
    }

    default: {
      break;
    }
  }

  const filterMetadataAndPreAppliedFilters = getFilterMetadataAndPreAppliedFilters(
    componentConfig,
    defaultFilter,
    pageEntityRecord,
    currentUserData,
    applicationVariables,
  );

  // Setting resolved view overrides
  _set(viewOverrides, VIEW_OVERRIDE_FIELD_IDS.FILTER_METADATA_AND_PRE_APPLIED_FILTERS, filterMetadataAndPreAppliedFilters);
  _set(viewOverrides, VIEW_OVERRIDE_FIELD_IDS.SIMILAR_VIEW_DETAILS, similarViewDetails);

  return viewOverrides;
};

export { getViewOverrides };
