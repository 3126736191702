import React, { useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _split from 'lodash/split';
import _noop from 'lodash/noop';
import _nth from 'lodash/nth';

import { Col, Row } from '@tekion/tekion-components/molecules/Grid';
import { EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import TagsRenderer from '../../atoms/tagsRenderer';
import MediaUploaderFormRenderer from '../../../mediaUploader/MediaUploaderFormRenderer';

import ViewRendererContext from '../../../../context/viewRenderer.context';
import variableApiFactory from '../../../../factories/variableApi.Factory';

import { getFieldDefProperties } from './cellViewAttributeRenderer.helpers';
import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';
import { entityRecordAccessor } from '../../helpers/entityRecordAccessor';

import { COMPONENT_TYPES, COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP, DEFAULT_WIDTH, VIEW_CONTEXT_KEYS } from '../../constants/viewBuilder.constants';
import { GALLERY_MODES } from '../../../mediaUploader/components/ImageThumbnails/imageThumbnail.constants';
import { CUSTOM_STYLE_IDS } from '../../../../constants/customStyles.constants';
import { VIEW_CONFIGURATION_GENERAL_KEYS, RENDERER_PROP_KEYS, ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';
import entityReader from '../../../../readers/entity.reader';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

import styles from './cellViewAttributeRenderer.module.scss';

const SUPPORTED_CUSTOM_STYLE_SECTION = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[COMPONENT_TYPES.CELL_VIEW_ATTRIBUTE];

const CellViewAttributeRenderer = ({ className, componentConfig, entity, entityRecord }) => {
  const { sectionId } = componentConfig;
  const properties = useMemo(() => _get(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT), [componentConfig]);

  const viewContextValue = useContext(ViewRendererContext);

  const viewVariables = useMemo(() => tget(viewContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.viewVariables`, EMPTY_STRING), [viewContextValue]);
  const handleSetViewVariables = useMemo(
    () => tget(viewContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.handleSetViewVariables`, _noop),
    [viewContextValue],
  );
  const variablesApi = useMemo(() => variableApiFactory(viewVariables, handleSetViewVariables), [viewVariables, handleSetViewVariables]);

  const fieldDefinitionByName = _keyBy(entityReader.fieldDefinitions(entity), 'name');
  const fieldNames = _get(properties, ALL_VIEW_PROPERTY_KEYS.FIELD_NAMES);
  const accessor = entityRecordAccessor(properties, fieldDefinitionByName);
  const fieldValue = accessor(entityRecord) || NO_DATA;
  const width = _get(properties, ALL_VIEW_PROPERTY_KEYS.WIDTH, DEFAULT_WIDTH);
  const displayName = _get(properties, ALL_VIEW_PROPERTY_KEYS.TITLE);

  const customStyles = useMemo(() => tget(properties, RENDERER_PROP_KEYS.CUSTOM_STYLES, {}), [properties]);
  const resolvedCustomStyles = useMemo(
    () => getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_SECTION, customStyles, entityRecord, variablesApi),
    [customStyles, entityRecord, variablesApi],
  );

  const renderMedia = useCallback(
    (field, isMultiValued, isRelationshipField, isFieldComplex) => {
      let newFieldValue = fieldValue;
      let fieldDef = _get(fieldDefinitionByName, field);
      let entityRecordId = _get(entityRecord, 'id');

      if (isRelationshipField) {
        newFieldValue = _get(entityRecord, ['entity', field]);
        const splitFieldName = _split(field, '.');
        const parentFieldName = _nth(splitFieldName, 0);
        const childFieldName = _nth(splitFieldName, 1);

        const parentFieldDef = _get(fieldDefinitionByName, parentFieldName);
        const relationalFieldDefs = fieldDefinitionReader.relationshipEntityFieldDefinitions(parentFieldDef);
        fieldDef = _get(relationalFieldDefs, childFieldName);
        entityRecordId = _get(entityRecord, ['entity', `${parentFieldName}.id`]);
      }

      if (isFieldComplex) {
        newFieldValue = _get(entityRecord, `entity.${field}`);
      }

      newFieldValue = _split(newFieldValue, ',');
      const isAvatarDisplay = _get(properties, ALL_VIEW_PROPERTY_KEYS.IS_AVATAR_VIEW, false);

      return (
        <MediaUploaderFormRenderer
          viewOnly
          isAvatarDisplay={isAvatarDisplay}
          showRefreshIcon={false}
          multiple={isMultiValued}
          value={newFieldValue}
          recordId={entityRecordId}
          fieldDef={fieldDef}
          mode={GALLERY_MODES.VIEW_ONLY}
          accept=".jpg,.png,.jpeg"
          displayCount={4}
          avatarDisplayName={fieldValue}
        />
      );
    },
    [entityRecord, fieldValue, properties, fieldDefinitionByName],
  );

  return (
    <Row key={sectionId} style={{ width: `${width}%` }} className={cx(styles.cellViewAttributeRenderer, className)}>
      <Col className={styles.valueContainer}>
        {_map(fieldNames, (field) => {
          const { isMultiValued, isMediaField, isRelationshipField, isFieldComplex } = getFieldDefProperties(field, fieldDefinitionByName);

          if (isMediaField) {
            return renderMedia(field, isMultiValued, isRelationshipField, isFieldComplex);
          } else if (isMultiValued) {
            return <TagsRenderer fieldValue={fieldValue} maxLimit={3} />;
          }

          return (
            <div key={displayName} className={styles.value} style={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTAINER, EMPTY_OBJECT)}>
              <span style={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTENT, EMPTY_OBJECT)}>{__(fieldValue)}</span>
            </div>
          );
        })}
      </Col>
    </Row>
  );
};

CellViewAttributeRenderer.propTypes = {
  className: PropTypes.string,
  entity: PropTypes.object,
  entityRecord: PropTypes.object,
  componentConfig: PropTypes.object.isRequired,
};

CellViewAttributeRenderer.defaultProps = {
  className: '',
  entity: undefined,
  entityRecord: undefined,
};

export default CellViewAttributeRenderer;
