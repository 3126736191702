import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import withActions from '@tekion/tekion-components/connectors/withActions';
import NotificationWrapper from '@tekion/tekion-components/organisms/NotificationWrapper';
import Tabs from '@tekion/tekion-components/molecules/Tabs';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

import ApplicationPageRenderer from './components/ApplicationPageRenderer';
import ApplicationTabHeader from './components/ApplicationTabHeader';
import AuditLogsDrawer from '../../../../organisms/auditLogsDrawer/AuditLogsDrawer';
import ApplicationRendererEventEmitter from '../../../../eventEmitters/applicationRendererEventEmitter';
import ApprovalPagesRenderer from './components/ApprovalPagesRenderer';

import { createAxiosInstanceForExternal } from '../../../../services/apiService/apiService';
import { getUpdatedContentHeightWidth } from './helpers/applicationRenderer.helpers';
import { getSearchParamsFromUrl } from '../../../../utils/visualBuilder.utils';
import ACTION_HANDLERS from './helpers/applicationRenderer.actionHandlers';

import PAGE_IDS from '../../constants/pageIds.constants';
import { EVENT_ACTIONS } from '../../../../constants/eventActions.constants';
import { ACTION_TYPES, NAVIGATION_TYPES, PROPERTY_IDS } from './constants/applicationRenderer.constants';
import { APPROVAL_METADATA_PROPERTY_IDS } from '../../../../constants/approvalCentre.constants';
import { APPLICATION_CONTEXT_KEYS, APPLICATION_SEARCH_PARAM_IDS } from '../../../../constants/applicationRenderer.constants';

import styles from './applicationRenderer.module.scss';

const ApplicationRenderer = (props) => {
  const {
    isMountedExternally,
    isNavigationRequired,
    contentHeight,
    contentWidth,
    selectedTabId,
    externallyMountedModuleName,
    navigationType,
    mountedPrefixPath,
    tabsData,
    pageConfigByName,
    applicationData,
    applicationRendererContextValue,
    apiConfig,
    match,
    history,
    onAction,
  } = props;
  const appName = tget(match, 'params.appName');
  const appRoute = `${mountedPrefixPath}/${appName}`;

  const searchParams = getSearchParamsFromUrl(history);
  const currentPageName = _get(searchParams, APPLICATION_SEARCH_PARAM_IDS.PAGE_NAME);
  const approvalTabMetadata = tget(applicationData, ['properties', PROPERTY_IDS.APPROVAL_METADATA], EMPTY_OBJECT);
  const isApprovalTabEnabled = tget(approvalTabMetadata, APPROVAL_METADATA_PROPERTY_IDS.IS_ENABLED, false);

  const { updatedContentHeight, updatedContentWidth } = useMemo(
    () => getUpdatedContentHeightWidth({ hasTabs: isNavigationRequired, contentHeight, contentWidth, tabPosition: navigationType }),
    [contentHeight, contentWidth, navigationType, isNavigationRequired],
  );

  const setApplicationVariables = useCallback(
    (variableName, value) => {
      onAction({ type: ACTION_TYPES.SET_APPLICATION_VARIABLES, payload: { variableName, value } });
    },
    [onAction],
  );

  const setEntityDef = useCallback(
    (entityDefsByName) => {
      onAction({ type: ACTION_TYPES.SET_ENTITY_DEF, payload: { entityDefsByName } });
    },
    [onAction],
  );

  const setEntityViewConfig = useCallback(
    (entityViewConfigsToUpdate) => {
      onAction({ type: ACTION_TYPES.SET_ENTITY_VIEW_CONFIG, payload: { entityViewConfigsToUpdate } });
    },
    [onAction],
  );

  const setRecordTypeViewConfig = useCallback(
    (recordTypeViewConfigsToUpdate) => {
      onAction({
        type: ACTION_TYPES.SET_RECORD_TYPE_VIEW_CONFIG,
        payload: { recordTypeViewConfigsToUpdate },
      });
    },
    [onAction],
  );

  const applicationContext = useMemo(
    () => ({
      ...applicationRendererContextValue,
      [APPLICATION_CONTEXT_KEYS.SET_APPLICATION_VARIABLES]: setApplicationVariables,
      [APPLICATION_CONTEXT_KEYS.SET_ENTITY_DEF]: setEntityDef,
      [APPLICATION_CONTEXT_KEYS.SET_ENTITY_VIEW_CONFIG]: setEntityViewConfig,
      [APPLICATION_CONTEXT_KEYS.SET_RECORD_TYPE_VIEW_CONFIG]: setRecordTypeViewConfig,
    }),
    [applicationRendererContextValue, setEntityDef, setEntityViewConfig, setRecordTypeViewConfig, setApplicationVariables],
  );

  const currentLoggedInUserData = useMemo(
    () => tget(applicationRendererContextValue, APPLICATION_CONTEXT_KEYS.CURRENT_USER, EMPTY_OBJECT),
    [applicationRendererContextValue],
  );

  const handleTabClick = useCallback(
    (tab) => {
      onAction({
        type: ACTION_TYPES.ON_TAB_CLICK,
        payload: { nextTabId: tab, currentTabId: selectedTabId },
      });
    },
    [onAction, selectedTabId],
  );

  const handleNavigateToPage = useCallback(
    (payload) => {
      onAction({
        type: ACTION_TYPES.ON_NAVIGATE_TO_PAGE,
        payload,
      });
    },
    [onAction],
  );

  useEffect(() => {
    if (isMountedExternally) {
      createAxiosInstanceForExternal({
        headers: _get(apiConfig, 'headers'),
        moduleBaseURL: _get(apiConfig, 'baseURL'),
        externallyMountedModuleName,
      });
    }
    onAction({ type: ACTION_TYPES.INIT_DATA });
    ApplicationRendererEventEmitter.on(EVENT_ACTIONS.NAVIGATE, handleNavigateToPage);

    return () => {
      ApplicationRendererEventEmitter.removeListener(EVENT_ACTIONS.NAVIGATE, handleNavigateToPage);
    };
  }, [onAction, handleNavigateToPage, isMountedExternally, apiConfig, externallyMountedModuleName]);

  const renderApplicationPageRenderer = useCallback(
    (tabInfo) => (
      <ApplicationPageRenderer
        isNavigationRequired={isNavigationRequired}
        currentPageName={currentPageName}
        contentHeight={updatedContentHeight}
        contentWidth={updatedContentWidth}
        tabId={_get(tabInfo, 'id')}
        selectedTabId={selectedTabId}
        pageConfigByName={pageConfigByName}
        history={history}
        applicationContext={applicationContext}
      />
    ),
    [applicationContext, currentPageName, history, isNavigationRequired, pageConfigByName, selectedTabId, updatedContentHeight, updatedContentWidth],
  );

  const renderApplicationContent = useCallback(
    (tabInfo) => {
      if (isApprovalTabEnabled) {
        // TODO: create a common route helper for routing approval related pages using appRoute.
        return (
          <Switch>
            <Route
              path={`${match.path}/${PAGE_IDS.APPROVAL_CENTRE}`}
              render={() => (
                <ApprovalPagesRenderer
                  isMountedInsideApplication
                  contentHeight={updatedContentHeight}
                  contentWidth={updatedContentWidth}
                  appRoute={appRoute}
                  currentLoggedInUserData={currentLoggedInUserData}
                  applicationProperties={tabInfo}
                />
              )}
            />
            <Route path={match.path} render={() => renderApplicationPageRenderer(tabInfo)} />
          </Switch>
        );
      }

      return renderApplicationPageRenderer(tabInfo);
    },
    [isApprovalTabEnabled, renderApplicationPageRenderer, match.path, updatedContentHeight, updatedContentWidth, appRoute, currentLoggedInUserData],
  );

  if (_isEmpty(applicationData)) {
    return null;
  } else if (!isNavigationRequired) {
    return (
      <>
        <div className={styles.noNavigationContainer}>{renderApplicationContent()}</div>
        <AuditLogsDrawer contentHeight={updatedContentHeight} />
        {/* NotificationWrapper is added to get the notification functionality in bundle. */}
        <PropertyControlledComponent controllerProperty={isMountedExternally}>
          <NotificationWrapper />
        </PropertyControlledComponent>
      </>
    );
  } else
    return (
      <>
        <Tabs
          animated={false}
          tabPosition={navigationType}
          activeKey={selectedTabId}
          className={styles.tabs}
          tabBarStyle={{ backgroundColor: '#f4f5f6' }}
          onTabClick={handleTabClick}
        >
          {_map(tabsData, (tabInfo) => (
            <Tabs.TabPane
              key={tget(tabInfo, 'id', EMPTY_STRING)}
              tab={<ApplicationTabHeader title={tget(tabInfo, 'displayName', EMPTY_STRING)} iconToRender={tget(tabInfo, 'tabIcon', EMPTY_STRING)} />}
            >
              {renderApplicationContent(tabInfo)}
            </Tabs.TabPane>
          ))}
        </Tabs>
        <AuditLogsDrawer contentHeight={updatedContentHeight} />
        {/* NotificationWrapper is added to get the notification functionality in bundle. */}
        <PropertyControlledComponent controllerProperty={isMountedExternally}>
          <NotificationWrapper />
        </PropertyControlledComponent>
      </>
    );
};

ApplicationRenderer.propTypes = {
  isMountedExternally: PropTypes.bool,
  isNavigationRequired: PropTypes.bool,
  contentWidth: PropTypes.number.isRequired,
  contentHeight: PropTypes.number.isRequired,
  selectedTabId: PropTypes.string,
  navigationType: PropTypes.string,
  externallyMountedModuleName: PropTypes.string.isRequired,
  mountedPrefixPath: PropTypes.string,
  pageConfigByName: PropTypes.object,
  applicationRendererContextValue: PropTypes.object,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
  applicationData: PropTypes.object,
  apiConfig: PropTypes.object,
  tabsData: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ApplicationRenderer.defaultProps = {
  isMountedExternally: true,
  isNavigationRequired: true,
  selectedTabId: undefined,
  navigationType: NAVIGATION_TYPES.TOP,
  mountedPrefixPath: `/${PAGE_IDS.APPLICATIONS}`,
  pageConfigByName: EMPTY_OBJECT,
  applicationRendererContextValue: EMPTY_OBJECT,
  applicationData: EMPTY_OBJECT,
  apiConfig: EMPTY_OBJECT,
  tabsData: EMPTY_ARRAY,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(ApplicationRenderer);
