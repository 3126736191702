import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Modal from '@tekion/tekion-components/molecules/Modal';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import Popover, { POPOVER_PLACEMENT, POPOVER_TRIGGER } from '@tekion/tekion-components/molecules/popover';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';
import withActions from '@tekion/tekion-components/connectors/withActions';
import LoadingSpinner from '@tekion/tekion-components/molecules/loadingSpinner';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import HelperText from '@tekion/tekion-components/atoms/HelperText/HelperText';

import AsyncPaginatedSelect from '../../../asyncPaginatedSelect/AsyncPaginatedSelect';
import EntityFieldList from './entityFieldList/EntityFieldList';

import ACTION_HANDLERS from './compoundConfigModal.actionHandlers';
import { getOptionsForCellViewName, getPayloadForCellViewName, handleSearchViewConfigurations } from './compoundConfigModal.helpers';

import {
  ACTION_TYPES,
  DEFINED_VARIABLES,
  FIELD_IDS,
  FORM_VIEW_TYPE_FIELD_IDS,
  HELPER_TEXT_VALUE_FIELD,
  MODAL_TYPE,
  RADIO_BUTTON_OPTIONS,
} from './compoundConfigModal.constants';
import { VIEW_TYPES } from '../../constants/viewBuilder.constants';

import styles from './compoundConfigModal.module.scss';

const CompoundConfigModal = ({
  showSwitchForCellViewAndTemplate,
  isCellViewEnabled,
  showCompoundConfigModal,
  isLoading,
  type,
  modalType,
  values,
  errors,
  viewConfiguration,
  entity,
  options,
  onAction,
}) => {
  const viewType = _get(viewConfiguration, 'viewType');
  const entityName = _get(entity, 'name');
  const handleClose = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_MODAL_CANCEL,
    });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_MODAL_SAVE,
    });
  }, [onAction]);

  const handleToggleSwitch = useCallback(
    (value) => {
      onAction({
        type: ACTION_TYPES.ON_TOGGLE_SWITCH,
        payload: { value },
      });
    },
    [onAction],
  );

  const renderTextInput = useCallback(
    () => (
      <TextInput
        required
        id={FIELD_IDS.DISPLAY_NAME}
        error={errors[FIELD_IDS.DISPLAY_NAME]}
        value={values[FIELD_IDS.DISPLAY_NAME]}
        label={__('Display Name')}
        fieldClassName={fieldLayoutStyles.fieldC}
        onAction={onAction}
      />
    ),
    [errors, onAction, values],
  );

  const renderPopOverContent = () => (
    <div className={styles.helperText}>
      <HelperText className="m-t-4">{HELPER_TEXT_VALUE_FIELD[DEFINED_VARIABLES.HELPER1]}</HelperText>
      <HelperText className="m-t-8">{HELPER_TEXT_VALUE_FIELD[DEFINED_VARIABLES.HELPER2]}</HelperText>
      <HelperText className="m-t-8">{HELPER_TEXT_VALUE_FIELD[DEFINED_VARIABLES.HELPER3]}</HelperText>
    </div>
  );

  const renderTemplateAndEntityFieldList = useCallback(
    () => (
      <div className={styles.subContainer}>
        <div className={styles.logicInfoContainer}>
          <div className={styles.logicContainer}>
            <TextInput
              required
              id={FIELD_IDS.TEMPLATE}
              error={errors[FIELD_IDS.TEMPLATE]}
              value={values[FIELD_IDS.TEMPLATE]}
              label={__('Template')}
              placeholder={__('{1}-{2}')}
              fieldClassName={fieldLayoutStyles.fieldC}
              onAction={onAction}
            />
          </div>
          <div className={styles.infoContainer}>
            <Popover
              arrowPointAtCenter
              destroyTooltipOnHidecontent
              trigger={POPOVER_TRIGGER.HOVER}
              placement={POPOVER_PLACEMENT.TOP}
              overlayClassName={styles.variablesInfo}
              content={renderPopOverContent()}
            >
              <span className="cursor-pointer">
                <FontIcon>icon-info</FontIcon>
              </span>
            </Popover>
          </div>
        </div>
        <EntityFieldList value={values[FIELD_IDS.ENTITY_FIELDS]} id={FIELD_IDS.ENTITY_FIELDS} options={options} onAction={onAction} />
      </div>
    ),
    [values, errors, options, onAction],
  );

  const renderContent = useCallback(() => {
    if (modalType === MODAL_TYPE.COMPLEX_FIELD_VIEW_SELECT) {
      return (
        <Select
          id={FORM_VIEW_TYPE_FIELD_IDS.SELECTED_VIEW_NAME}
          hideSelectedOptions
          fieldClassName={fieldLayoutStyles.fieldC}
          value={values[FORM_VIEW_TYPE_FIELD_IDS.SELECTED_VIEW_NAME]}
          error={errors[FORM_VIEW_TYPE_FIELD_IDS.SELECTED_VIEW_NAME]}
          options={options}
          onAction={onAction}
        />
      );
    } else if (viewType === VIEW_TYPES.CELL_VIEW || modalType === MODAL_TYPE.RELATIONSHIP_FIELD_COMPOUND) {
      return renderTemplateAndEntityFieldList();
    } else {
      return (
        <>
          {renderTextInput()}
          {renderTemplateAndEntityFieldList()}
        </>
      );
    }
  }, [modalType, viewType, values, errors, options, onAction, renderTemplateAndEntityFieldList, renderTextInput]);

  const renderSwitch = useCallback(
    () => (
      <Radio className={styles.radio} size="large" id={FIELD_IDS.BUTTON} radios={RADIO_BUTTON_OPTIONS} value={type} onAction={handleToggleSwitch} />
    ),
    [handleToggleSwitch, type],
  );

  const renderAsyncSelectForCellViewName = useCallback(
    () => (
      <>
        {renderTextInput()}
        <AsyncPaginatedSelect
          required
          id={FIELD_IDS.CELL_VIEW_NAME}
          label={__('Cell View')}
          fieldClassName={styles.asyncSelect}
          value={_get(values, [FIELD_IDS.CELL_VIEW_NAME])}
          error={errors?.[FIELD_IDS.CELL_VIEW_NAME]}
          serviceHandler={handleSearchViewConfigurations(onAction)}
          getOptions={getOptionsForCellViewName}
          getPayload={getPayloadForCellViewName(entityName)}
          onAction={onAction}
        />
      </>
    ),
    [renderTextInput, values, entityName, errors, onAction],
  );

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.INIT,
    });
  }, [onAction]);

  return (
    <Modal
      visible={showCompoundConfigModal}
      destroyOnClose
      width={Modal.SIZES.M}
      title={__('Compound Field')}
      onCancel={handleClose}
      onSubmit={handleSubmit}
    >
      <PropertyControlledComponent controllerProperty={showSwitchForCellViewAndTemplate}>{renderSwitch(type)}</PropertyControlledComponent>
      <PropertyControlledComponent controllerProperty={!isCellViewEnabled} fallback={renderAsyncSelectForCellViewName()}>
        {isLoading ? <LoadingSpinner className={styles.loader} /> : renderContent()}
      </PropertyControlledComponent>
    </Modal>
  );
};

CompoundConfigModal.propTypes = {
  showSwitchForCellViewAndTemplate: PropTypes.bool,
  isCellViewEnabled: PropTypes.bool,
  showCompoundConfigModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  modalType: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  viewConfiguration: PropTypes.object,
  entity: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  onAction: PropTypes.func.isRequired,
};

CompoundConfigModal.defaultProps = {
  showSwitchForCellViewAndTemplate: false,
  isCellViewEnabled: false,
  showCompoundConfigModal: false,
  isLoading: false,
  type: undefined,
  modalType: undefined,
  values: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  viewConfiguration: EMPTY_OBJECT,
  options: EMPTY_ARRAY,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(CompoundConfigModal);
