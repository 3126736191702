import _isEmpty from 'lodash/isEmpty';
import _values from 'lodash/values';
import _get from 'lodash/get';
import _remove from 'lodash/remove';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { repositionNodesOnDrop, updateNodeDetails, deleteNode, addNode } from '@tekion/tekion-rule-engine/src/utils/core/crudOperations';
import { processUpdatedNode, removeCustomDragPath, isParentIsLinkedWithPath } from '@tekion/tekion-rule-engine/src/utils/core/helpers/tasks.helpers';
import { TASK_TYPES } from '@tekion/tekion-rule-engine';
import { TASK_VALUES } from '@tekion/tekion-rule-engine/src/constants/ruleEngineWorkflow.taskTypes';

// Helpers
import { loadFieldDefinitions } from './helperHandlers';

// Utils
import generateVariableMapForNode from '../../../utils/generateVariableMapForNode';
import generatePathOptions from '../../../utils/generatePathOptions';

// Constants
import WORKFLOW_BUILDER_ACTION_TYPES from '../../../constants/workflowBuilder.actionTypes';
import WORKFLOW_FIELD_IDS from '../../../constants/workflow.fieldIds';
import WORKFLOW_BUILDER_MODAL_TYPES from '../../../constants/workflowBuilder.modalTypes';
import TASK_DEF_ID_TO_MODAL_TYPE from '../../../constants/workflowBuilder.taskDefToModalType';
import { CONDITION_TYPES, TASK_LABELS, TASK_ICONS, TASK_DEF_IDS } from '../../../constants/workflow.constants';
import { MODAL_MODES, NODE_OPERATIONS } from '../../../constants/workflowBuilder.constants';
import { LOOP_NODE_FIELD_IDS } from '../../../constants/nodeDataFieldIds';

const handleNodeDnd = ({ getState, setState, params }) => {
  const { workflow } = getState();
  const { dndNodes } = params;
  const { nodeId, currentParentId, newParentId, config, type } = dndNodes;

  let updatedWorkflow;
  switch (type) {
    case 'CARD_WITHOUT_LEAF':
    default:
      updatedWorkflow = repositionNodesOnDrop(workflow, nodeId, currentParentId, newParentId, {
        ...config,
        setNodelistBeforeComplete: isParentIsLinkedWithPath,
      });
  }
  setState({ workflow: updatedWorkflow });
};

const handleOnAddNode = ({ setState, params = EMPTY_OBJECT }) => {
  const { parentNode, label, nodePlacement, isAddStep = false } = params;
  const parentNodeId = tget(parentNode, 'nodeId');
  const parentNodeType = tget(parentNode, 'uiMetadata.taskType');
  const parentEntityId = tget(parentNode, 'uiMetadata.entityDefId');

  setState({
    isStepDrawerVisible: true,
    selectedNode: {
      node: parentNodeId,
      label,
      nodeType: parentNodeType,
      parentEntityId,
      isAddStep,
    },
    nodePlacement,
  });
};

const handleChangeToIfElseCondition = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { workflow } = getState();
  const { nodeTransferConfig, currentlyOperatingOn, nodeData, targetType } = params;

  const newConditionData = {
    ...nodeData,
    type: 'CHANGE_CONDITION_TYPE',
    nodeTransferConfig,
    keptChild: _values(nodeTransferConfig),
    changedStepLabelTo: TASK_LABELS[targetType],
    newConditionType: targetType,
    taskDefId: TASK_DEF_IDS[targetType],
    entityDefId: TASK_DEF_IDS[targetType],
    icon: TASK_ICONS[targetType],
  };

  const updatedWorkflow = deleteNode(workflow, currentlyOperatingOn, newConditionData);

  setState({ workflow: updatedWorkflow });
};

const handleChangeLoopLeafNodes = ({ getState, setState, params }) => {
  const { workflow } = getState();
  const { nodeData, nodeId } = params;
  const taskDefId = tget(nodeData, 'taskDefId');
  let newConditionData = {
    type: 'CHANGE_LOOP_LEAF_NODES',
  };

  if (taskDefId === TASK_DEF_IDS.LOOP_CONTINUE) {
    newConditionData = {
      ...newConditionData,
      taskDefId: TASK_DEF_IDS.LOOP_EXIT,
      entityDefId: TASK_DEF_IDS.LOOP_EXIT,
      changedStepLabelTo: __('Loop Exit'),
      icon: 'icon-cross',
    };
  } else if (taskDefId === TASK_DEF_IDS.LOOP_EXIT) {
    newConditionData = {
      ...newConditionData,
      taskDefId: TASK_DEF_IDS.LOOP_CONTINUE,
      entityDefId: TASK_DEF_IDS.LOOP_CONTINUE,
      changedStepLabelTo: __('Loop Continue'),
      icon: 'icon-refresh',
    };
  }

  const updatedWorkflow = deleteNode(workflow, nodeId, newConditionData);
  setState({ workflow: updatedWorkflow });
};

const unlinkConnectorPath = ({ params, getState, setState }) => {
  const { workflow } = getState();
  const { nodeData } = params;

  const updates = removeCustomDragPath(nodeData);
  const updatedNode = { ...nodeData, ...updates };

  const config = { recalculateupdatedNodePositions: _get(nodeData, 'uiMetadata.conditionType') === CONDITION_TYPES.SWITCH, childLabels: null };

  const updatedWorkflow = updateNodeDetails(workflow, updatedNode, {
    ...config,
    ...processUpdatedNode(workflow, updatedNode, config),
  });

  setState({ workflow: updatedWorkflow });
};

const handleMenuClick = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { workflow, conditionBuilderFieldDefinitionObject, mapOfVariableToEntityNameForCondition, eventTypes, variablesByNodeId } = getState();
  const { nodeId, actionType, onEntityChange } = params;
  const eventType = tget(workflow, 'eventType');
  const nodeData = tget(workflow, `nodeList.${nodeId}`);
  const entityName = tget(workflow, WORKFLOW_FIELD_IDS.ENTITY);
  const variablesInfo = tget(workflow, 'uiMetadata.variablesInfo');
  const parentNodeId = tget(nodeData, 'nodeConfig.parent');
  const parentTaskType = tget(nodeData, 'nodeConfig.parentTaskType');
  const label = tget(nodeData, 'nodeConfig.label');

  const parentVariables = [...tget(variablesByNodeId, parentNodeId, [])];
  if (parentTaskType === TASK_TYPES.LOOP && label === TASK_VALUES.NO) {
    const loopNodeData = tget(workflow, `nodeList.${parentNodeId}`);
    const loopVariableName = tget(loopNodeData, `userData.data.${LOOP_NODE_FIELD_IDS.LOOP_VARIABLE_NAME}`);
    _remove(parentVariables, (variable) => variable === loopVariableName);
  }

  const variableMap = generateVariableMapForNode(variablesInfo, mapOfVariableToEntityNameForCondition, parentVariables);

  switch (actionType) {
    case NODE_OPERATIONS.ADD_TRIGGER:
      setState({ isTriggerDrawerVisible: true });
      break;
    case NODE_OPERATIONS.EDIT:
      if (!_isEmpty(nodeData)) {
        const taskDefId = tget(nodeData, 'taskDefId');
        setState({
          isModalVisible: true,
          modalType: TASK_DEF_ID_TO_MODAL_TYPE[taskDefId],
          modalProps: {
            isLoading: true,
            entityName,
            eventTypes,
            eventType,
            mode: MODAL_MODES.EDIT,
            stepDetails: nodeData,
            conditionBuilderFieldDefinitionObject,
            mapOfVariableToEntityName: variableMap,
            onEntityChange,
            variablesInfo,
            variablesByNodeId,
            scopedVariables: parentVariables,
          },
        });

        const { conditionBuilderFieldDefinitionObject: fieldDefsObject, mapOfVariableToEntityName } = await loadFieldDefinitions({
          getState,
          setState,
          params: { variableMap, scopedVariables: parentVariables },
        });

        setState((state) => ({
          ...state,
          modalProps: {
            ...state.modalProps,
            isLoading: false,
            conditionBuilderFieldDefinitionObject: fieldDefsObject,
            mapOfVariableToEntityName,
          },
        }));
      }
      break;
    case NODE_OPERATIONS.DELETE:
      if (!_isEmpty(nodeData)) {
        setState({
          isModalVisible: true,
          modalType: WORKFLOW_BUILDER_MODAL_TYPES.DELETE_CONFIRMATION_MODAL,
          modalProps: {
            stepDetails: nodeData,
            entityName,
            mode: MODAL_MODES.DELETE,
            title: __('Confirm delete'),
            headerContent: __('Are you sure you want to delete?'),
            submitBtnText: __('Delete'),
            variablesByNodeId,
          },
          selectedNode: {
            node: nodeData,
            nodeId,
          },
        });
      }
      break;
    case NODE_OPERATIONS.CHANGE_TO_IF_ELSE:
      {
        const sourceType = tget(nodeData, 'uiMetadata.conditionType', EMPTY_STRING);
        const childNodes = tget(nodeData, 'nodeConfig.childNodes', EMPTY_ARRAY);
        const pathOptions = generatePathOptions(childNodes, EMPTY_OBJECT, CONDITION_TYPES.IF_ELSE);

        if (sourceType === CONDITION_TYPES.IF) {
          const changeConditionParams = {
            targetType: CONDITION_TYPES.IF_ELSE,
            nodeTransferConfig: { true: 'true', false: null },
            currentlyOperatingOn: nodeId,
            nodeData,
          };
          handleChangeToIfElseCondition({ getState, setState, params: changeConditionParams });
          break;
        }
        setState({
          isModalVisible: true,
          modalType: WORKFLOW_BUILDER_MODAL_TYPES.CHANGE_TO_IF,
          modalProps: {
            targetType: CONDITION_TYPES.IF_ELSE,
            sourceType,
            pathOptions,
            stepDetails: nodeData,
          },
        });
      }
      break;

    case NODE_OPERATIONS.CHANGE_TO_IF:
      {
        const sourceType = tget(nodeData, 'uiMetadata.conditionType', EMPTY_STRING);
        const childNodes = tget(nodeData, 'nodeConfig.childNodes', EMPTY_ARRAY);
        const pathOptions = generatePathOptions(childNodes, EMPTY_OBJECT, CONDITION_TYPES.IF);

        setState({
          isModalVisible: true,
          modalType: WORKFLOW_BUILDER_MODAL_TYPES.CHANGE_TO_IF_ELSE,
          modalProps: {
            targetType: CONDITION_TYPES.IF,
            sourceType,
            pathOptions,
            stepDetails: nodeData,
          },
        });
      }
      break;

    case NODE_OPERATIONS.CHANGE_TO_CHECK: {
      const sourceType = tget(nodeData, 'uiMetadata.conditionType', EMPTY_STRING);
      const pathOptions = generatePathOptions(EMPTY_ARRAY, EMPTY_OBJECT, CONDITION_TYPES.SWITCH);

      setState({
        isModalVisible: true,
        modalType: WORKFLOW_BUILDER_MODAL_TYPES.CHANGE_TO_SWITCH,
        modalProps: {
          targetType: CONDITION_TYPES.SWITCH,
          sourceType,
          pathOptions,
          stepDetails: nodeData,
          conditionBuilderFieldDefinitionObject,
          mapOfVariableToEntityName: mapOfVariableToEntityNameForCondition,
          onEntityChange,
          isTargetFieldsLoading: false,
        },
      });
      break;
    }
    case NODE_OPERATIONS.UNLINK_CONNECTOR_PATH:
      unlinkConnectorPath({ getState, setState, params: { nodeData } });
      break;

    case NODE_OPERATIONS.CHANGE_TO_LOOP_CONTINUE:
    case NODE_OPERATIONS.CHANGE_TO_LOOP_EXIT:
      handleChangeLoopLeafNodes({ getState, setState, params: { nodeData, nodeId } });
      break;

    default:
  }
};

const handleUpdateNode = ({ getState, setState, params }) => {
  const { workflow } = getState();
  const { config, node } = params;

  const updatedWorkflow = updateNodeDetails(workflow, node, {
    ...config,
    ...processUpdatedNode(workflow, node, config),
  });

  setState({ workflow: updatedWorkflow });
};

const handleRepositionNodeOnDrop = ({ getState, setState, params }) => {
  const { workflow } = getState();
  const updatedWorkflow = repositionNodesOnDrop(workflow, params, isParentIsLinkedWithPath);

  setState({
    workflow: updatedWorkflow,
  });
};

const handleDuplicateNode = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { workflow } = getState();
  const { parentNode, content } = params;
  const updatedWorkflow = addNode(workflow, parentNode, content);

  setState({ workflow: updatedWorkflow });
};

const handleExpandToggle = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { expandedNodes = EMPTY_OBJECT } = getState();
  const { nodeId } = params;

  setState({
    expandedNodes: { ...expandedNodes, [nodeId]: !expandedNodes[nodeId] },
  });
};

const NODE_ACTION_HANDLERS = {
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_ADD_NODE]: handleOnAddNode,
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_NODE_DND]: handleNodeDnd,
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_NODE_MENU_CLICK]: handleMenuClick,
  [WORKFLOW_BUILDER_ACTION_TYPES.NODE_UPDATE]: handleUpdateNode,
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_NODE_DROP_REPOSITION]: handleRepositionNodeOnDrop,
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_NODE_DUPLICATE]: handleDuplicateNode,
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_NODE_EXPAND]: handleExpandToggle,
};

export default NODE_ACTION_HANDLERS;
