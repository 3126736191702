import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
import _set from 'lodash/set';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { searchViewConfigurationsByRecordType } from '../../../../../../../../actions/viewBuilderPage.actions';

import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../../../../constants/viewBuilder.constants';
import { ENTITY_VIEW_FORM_FIELD_IDS, ROWS } from '../constants/entityViewForm.general.constants';

const getValueOption = (viewConfig) => ({
  label: tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.DISPLAY_NAME, tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME, EMPTY_STRING)),
  value: tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME),
});

const getOptions = (selectedViewRecordTypeMetadata, viewRecordTypeMetadataList) => (data) => {
  const selectedRecordTypeName = getArraySafeValue(tget(selectedViewRecordTypeMetadata, ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME, EMPTY_STRING));
  const selectedViewName = getArraySafeValue(tget(selectedViewRecordTypeMetadata, ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME, EMPTY_STRING));
  const recordTypeToViewNameMap = {};

  _map(viewRecordTypeMetadataList, (viewRecordTypeMetadata) => {
    const recordTypeName = getArraySafeValue(tget(viewRecordTypeMetadata, ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME, EMPTY_STRING));
    const viewName = getArraySafeValue(tget(viewRecordTypeMetadata, ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME, EMPTY_STRING));
    const prevValue = recordTypeToViewNameMap[recordTypeName] || EMPTY_ARRAY;
    if (!_isEmpty(viewName)) {
      recordTypeToViewNameMap[recordTypeName] = [...prevValue, viewName];
    }
  });

  return _compact(
    _map(data, (viewConfig) => {
      const viewName = tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME);
      if (selectedViewName === viewName || !_includes(recordTypeToViewNameMap[selectedRecordTypeName], viewName)) {
        return getValueOption(viewConfig);
      }
      return null;
    }),
  );
};

const getPayload =
  ({ entityName, viewType }) =>
  ({ nextPageToken, searchText }) => {
    const filters = [
      {
        field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
        values: [entityName],
        filterType: OPERATORS.IN,
      },
      {
        field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
        values: [viewType],
        filterType: OPERATORS.IN,
      },
    ];

    if (!_isEmpty(searchText)) {
      filters.push({
        field: VIEW_CONFIGURATION_FIELD_IDS.NAME,
        values: [searchText],
        filterType: OPERATORS.TEXT_STARTS_WITH,
      });
    }

    return {
      rows: ROWS,
      nextPageToken,
      filters,
    };
  };

const handleSearchViewConfigurationsByRecordType = (recordTypeName) => (payload) => searchViewConfigurationsByRecordType(recordTypeName, payload);

const generateRecordTypeOptions = (recordTypeOptions, viewRecordTypeMetadataList, currentRecordTypeName) => {
  const selectedRecordTypeNames = _compact(
    _map(viewRecordTypeMetadataList, (viewRecordTypeMetadata) =>
      getArraySafeValue(_get(viewRecordTypeMetadata, ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME)),
    ),
  );

  return _filter(recordTypeOptions, ({ value }) => value === currentRecordTypeName || !_includes(selectedRecordTypeNames, value));
};

const entityViewRequiredRule = (fieldId, values) => {
  const errorMsg = __('This field is required');

  let isValid = true;

  const errors = _map(values, (value) => {
    const error = {};

    if (_isEmpty(getArraySafeValue(_get(value, ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME)))) {
      isValid = false;
      _set(error, ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME, errorMsg);
    }

    if (_isEmpty(getArraySafeValue(_get(value, ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME)))) {
      isValid = false;
      _set(error, ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME, errorMsg);
    }

    return error;
  });

  return isValid ? { isValid } : { isValid, message: errors };
};

export { getOptions, getPayload, handleSearchViewConfigurationsByRecordType, generateRecordTypeOptions, entityViewRequiredRule };
