import _property from 'lodash/property';

const profileReader = {
  name: _property('name'),
  displayName: _property('displayName'),
  description: _property('description'),
  modifiedTime: _property('modifiedTime'),
  createdTime: _property('createdTime'),
  controlFlags: _property('controlFlags'),
};

export default profileReader;
