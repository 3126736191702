import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getScaleAdjustedSize, getSizeErrorCorrectionFactor } from '@tekion/tekion-components/utils/general';

import { WorkSpaceSizeContext } from '../context/workSpaceSize.context';

export const DEFAULT_OPTIONS = {
  hasPageFooter: 0,
  hasPageHeader: 1,
  hasTabSection: 0,
  headerHeight: 64,
  footerHeight: 64,
  tabSectionHeight: 55,
};

const WithSize = (options = {}) => {
  const mergedOptions = { ...DEFAULT_OPTIONS, ...options };
  const { hasPageFooter, hasPageHeader, hasTabSection, headerHeight, footerHeight, tabSectionHeight } = mergedOptions;

  return (WrappedComponent) => {
    const WrapperComponent = (props) => {
      const { displayScale, ...rest } = props;
      const workSpaceSize = useContext(WorkSpaceSizeContext);

      useEffect(() => {
        window.pxToRemErrorRate = getSizeErrorCorrectionFactor(displayScale);
      }, [displayScale]);

      let { contentHeight = 0 } = workSpaceSize;
      const { contentWidth } = workSpaceSize;

      if (hasPageHeader) {
        contentHeight -= getScaleAdjustedSize(headerHeight);
      }

      if (hasPageFooter) {
        contentHeight -= getScaleAdjustedSize(footerHeight);
      }

      if (hasTabSection) {
        contentHeight -= getScaleAdjustedSize(tabSectionHeight);
      }

      return <WrappedComponent contentHeight={contentHeight} {...rest} contentWidth={contentWidth} />;
    };

    WrapperComponent.propTypes = {
      displayScale: PropTypes.number.isRequired,
    };
    return WrapperComponent;
  };
};

export default WithSize;
