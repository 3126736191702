const COLUMN_IDS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  FIELD_TYPE: 'fieldType',
  DATA_TYPE: 'dataType',
  CREATABLE: 'creatable',
  EDITABLE: 'editable',
  SEARCHABLE: 'searchable',
  SORTABLE: 'sortable',
  SORT_DIRECTION: 'defaultSortDirection',
  IMPORTABLE: 'importable',
  EXPORTABLE: 'exportable',
  MANDATORY: 'mandatory',
  UNIQUE_CONSTRAINT: 'uniqueConstraint',
  DISABLED: 'disabled',
  LIST_VIEW_ENABLED: 'listViewEnabled',
  DETAILED_VIEW_ENABLED: 'detailViewEnabled',
  ALLOW_LOOKUP_USING_FIELD: 'allowLookupUsingField',
  LOOKUP_NAME: 'lookupName',
  LOOKUP_FIELD: 'lookupField',
  COMPLEX_FIELD_DEFINITION: 'complexFieldDefinition',
  OPTIONS: 'options',
};

export default COLUMN_IDS;
