import _keyBy from 'lodash/keyBy';
import _mapValues from 'lodash/mapValues';

const READ_ONLY = {
  id: 'READ_ONLY',
  name: 'Read',
};

const READ_WRITE = {
  id: 'READ_WRITE',
  name: 'Read - Write',
};

const RECORD_SHARING_ACCESS_TYPES = [READ_ONLY, READ_WRITE];

const RECORD_SHARING_ACCESS_TYPES_BY_ID = _keyBy(RECORD_SHARING_ACCESS_TYPES, 'id');

const RECORD_SHARING_ACCESS_TYPE = _mapValues(RECORD_SHARING_ACCESS_TYPES, 'id');

export { READ_ONLY, READ_WRITE, RECORD_SHARING_ACCESS_TYPE, RECORD_SHARING_ACCESS_TYPES };

export default RECORD_SHARING_ACCESS_TYPES_BY_ID;
