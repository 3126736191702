import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _map from 'lodash/map';
import _reject from 'lodash/reject';
import _noop from 'lodash/noop';
import _isObject from 'lodash/isObject';

import Tag from '@tekion/tekion-components/atoms/Tag';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import styles from './asyncTag.module.scss';

const AsyncTag = (props) => {
  const { isTagDeletable, tagClassName, value, onChange, tagsWrapperClassName } = props;

  const handleTagDelete = useCallback(
    (val) => {
      onChange(_reject(value, ['value', val]));
    },
    [value, onChange],
  );

  const renderTag = useCallback(
    (selectedUserTag) => {
      if (!_isObject(selectedUserTag) || !selectedUserTag) {
        return null;
      }
      // lets show all the users in tags
      return (
        <Tag className={cx(styles.asyncTag, tagClassName)} data={selectedUserTag.value} deletable={isTagDeletable} onDelete={handleTagDelete}>
          {selectedUserTag.label}
        </Tag>
      );
    },
    [isTagDeletable, tagClassName, handleTagDelete],
  );

  return <div className={cx(styles.asyncTags, tagsWrapperClassName)}>{_map(value, renderTag)}</div>;
};

AsyncTag.propTypes = {
  isTagDeletable: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  tagsWrapperClassName: PropTypes.string,
  tagClassName: PropTypes.string,
  onChange: PropTypes.func,
};

AsyncTag.defaultProps = {
  isTagDeletable: true,
  value: EMPTY_ARRAY,
  tagsWrapperClassName: '',
  tagClassName: '',
  onChange: _noop,
};

export default AsyncTag;
