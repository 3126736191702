exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".approvalSettingFieldForm_headerContainer__c6yHQDN3LQ{justify-content:space-between !important}.approvalSettingFieldForm_tagContainer__7wDohyA4g6{display:flex}.approvalSettingFieldForm_tagContainer__7wDohyA4g6 .approvalSettingFieldForm_tag__gNoCwA7wso{font-size:1.2rem}[dir] .approvalSettingFieldForm_tagContainer__7wDohyA4g6 .approvalSettingFieldForm_tag__gNoCwA7wso{margin:.4rem;padding:.4rem .8rem;background:#f4f5f6;border:.1rem solid #d4d5d6;border-radius:10rem}[dir] .approvalSettingFieldForm_titleTextPadding__aRj46EpJcH{padding-bottom:1.2rem}.approvalSettingFieldForm_addFormStyle__rQuQvc9ErR{max-width:none !important}.approvalSettingFieldForm_form__ff2CWCj2Kb{height:calc(100% - 5rem);overflow-y:auto}.approvalSettingFieldForm_formSectionHeader__h1cXjQFE7X{font-size:1.8rem}.approvalSettingFieldForm_footerContainer__9gq8xWdox2{height:6.4rem}.approvalSettingFieldForm_inputTable__qTSAkRz34j{min-height:0 !important}[dir] .approvalSettingFieldForm_inputTable__qTSAkRz34j{margin-bottom:1.5rem}.approvalSettingFieldForm_addRowButton__bBYo9p7j96{width:15%}.approvalSettingFieldForm_configTableHeading__dq3XDnuAMz{font-size:1.6rem;font-weight:bold}[dir] .approvalSettingFieldForm_configTableHeading__dq3XDnuAMz{margin-bottom:.8rem}[dir] .approvalSettingFieldForm_addRowInputTableButton__n4Q2SgZwwk{margin-bottom:2rem}", ""]);

// Exports
exports.locals = {
	"headerContainer": "approvalSettingFieldForm_headerContainer__c6yHQDN3LQ",
	"tagContainer": "approvalSettingFieldForm_tagContainer__7wDohyA4g6",
	"tag": "approvalSettingFieldForm_tag__gNoCwA7wso",
	"titleTextPadding": "approvalSettingFieldForm_titleTextPadding__aRj46EpJcH",
	"addFormStyle": "approvalSettingFieldForm_addFormStyle__rQuQvc9ErR",
	"form": "approvalSettingFieldForm_form__ff2CWCj2Kb",
	"formSectionHeader": "approvalSettingFieldForm_formSectionHeader__h1cXjQFE7X",
	"footerContainer": "approvalSettingFieldForm_footerContainer__9gq8xWdox2",
	"inputTable": "approvalSettingFieldForm_inputTable__qTSAkRz34j",
	"addRowButton": "approvalSettingFieldForm_addRowButton__bBYo9p7j96",
	"configTableHeading": "approvalSettingFieldForm_configTableHeading__dq3XDnuAMz",
	"addRowInputTableButton": "approvalSettingFieldForm_addRowInputTableButton__n4Q2SgZwwk"
};