import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Loader from '@tekion/tekion-components/molecules/loader';

import withSize from '../../../../connectors/withSize';
import ACTION_HANDLERS from './helpers/recordTypeList.actionHandlers';
import { COLUMNS, getSubHeaderProps, TABLE_MANAGER_PROPS, getTableProps } from './constants/recordTypeList.data';
import { CUSTOM_ACTIONS, INITIAL_STATE } from './constants/recordTypeList.constants';

import styles from './recordTypeList.module.scss';

const RecordTypeList = (props) => {
  const { loading, showModal, contentHeight, entity, recordTypes, modalContent, onAction } = props;

  const recordType = _get(entity, 'recordTypeDefinition.type');
  const recordTypeEnabled = _get(entity, 'recordTypeEnabled', false);
  const derivationFields = _get(entity, 'recordTypeDefinition.recordTypeDerivationConfig.derivationFields');
  const SubHeaderProps = useMemo(() => getSubHeaderProps(onAction, recordType, derivationFields), [derivationFields, onAction, recordType]);

  const handleRowAction = useCallback(
    (actionType, value) => {
      onAction({
        type: actionType,
        payload: {
          value,
        },
      });
    },
    [onAction],
  );

  const tableProps = useMemo(() => getTableProps(handleRowAction, loading, recordTypes.length), [handleRowAction, loading, recordTypes]);

  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({
      type: CUSTOM_ACTIONS.CLOSE_MODAL_REQUEST_DIALOG,
    });
  }, [onAction]);

  const handleConfirmationDialogSubmit = useCallback(() => {
    onAction({ type: CUSTOM_ACTIONS.CONFIRM_MODAL_REQUEST });
  }, [onAction]);

  useEffect(() => {
    onAction({ type: CUSTOM_ACTIONS.ON_INIT });
  }, [onAction]);

  if (loading) {
    return <Loader style={{ height: 316 }} />;
  }

  return (
    <Page>
      <Page.Body style={{ height: contentHeight }}>
        <PropertyControlledComponent
          controllerProperty={recordTypeEnabled}
          fallback={<div className={styles.container}>{__('No Record Type For this entity as Record Type is not enabled')}</div>}
        >
          <TableManager
            isLoaded
            loading={loading}
            showPagination={false}
            disableHeight={false}
            columns={COLUMNS}
            data={recordTypes}
            subHeaderProps={SubHeaderProps}
            tableProps={tableProps}
            tableManagerProps={TABLE_MANAGER_PROPS}
            onAction={onAction}
          />
        </PropertyControlledComponent>
        <PropertyControlledComponent controllerProperty={showModal}>
          <ConfirmationDialog
            isVisible={showModal}
            onSubmit={handleConfirmationDialogSubmit}
            onCancel={handleConfirmationDialogCancel}
            content={__(`${modalContent}`)}
          />
        </PropertyControlledComponent>
      </Page.Body>
    </Page>
  );
};

RecordTypeList.propTypes = {
  contentHeight: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  showModal: PropTypes.bool,
  modalContent: PropTypes.string,
  recordTypes: PropTypes.array,
  entity: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

RecordTypeList.defaultProps = {
  loading: false,
  showModal: false,
  modalContent: EMPTY_STRING,
  recordTypes: EMPTY_ARRAY,
  entity: EMPTY_OBJECT,
};

export default compose(withActions(INITIAL_STATE, ACTION_HANDLERS), withSize({ hasPageHeader: true }))(RecordTypeList);
