import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import _remove from 'lodash/remove';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _has from 'lodash/has';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';
import _find from 'lodash/find';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';

import { VIEW_TYPES } from '../../constants/viewBuilder.constants';
import {
  ENTITY_RELATION_META_DATA_KEYS,
  LIST_VIEW_CONFIG_PROPERTY_KEYS,
  VIEW_RECORD_ACTION_TYPES,
  ON_CLICK_ACTION_TYPES,
  FIELD_IDS,
  ROWS,
} from './expandableRowConfigForm.constants';
import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../constants/viewBuilder.constants';

const findIndexForExpandableRowConfig = (viewRecordActionConfigs) =>
  _findIndex(
    viewRecordActionConfigs,
    (config) =>
      _get(config, LIST_VIEW_CONFIG_PROPERTY_KEYS.VIEW_RECORD_ACTION_TYPE, '') === VIEW_RECORD_ACTION_TYPES.CELL_CLICK &&
      _get(config, LIST_VIEW_CONFIG_PROPERTY_KEYS.ON_CLICK_ACTION_TYPE, '') === ON_CLICK_ACTION_TYPES.EXPAND,
  );

const getValuesFromProperties = (selectedViewComponentProperties) => {
  let expandableRowsEnabled = false;
  let value = EMPTY_OBJECT;

  if (selectedViewComponentProperties) {
    expandableRowsEnabled = _get(selectedViewComponentProperties, [LIST_VIEW_CONFIG_PROPERTY_KEYS.EXPANDABLE_ROWS_ENABLED], false);
    const viewRecordActionConfigs = _get(selectedViewComponentProperties, [LIST_VIEW_CONFIG_PROPERTY_KEYS.VIEW_RECORD_ACTION_CONFIGS], EMPTY_ARRAY);
    const configIndex = findIndexForExpandableRowConfig(viewRecordActionConfigs);
    value = _get(viewRecordActionConfigs, [configIndex, LIST_VIEW_CONFIG_PROPERTY_KEYS.RELATED_VIEW_PROPS], EMPTY_OBJECT);
  }

  return { expandableRowsEnabled, value };
};

const getRelatedEntityAndFieldOptions = (entity) => {
  const entityRelationMetadataList = _get(entity, ENTITY_RELATION_META_DATA_KEYS.ENTITY_RELATION_META_DATA_LIST, []);

  const relatedEntityOptions = [];
  const relatedEntityFieldOptions = {};

  _forEach(entityRelationMetadataList, (entityRelation) => {
    const entityName = _get(entityRelation, ENTITY_RELATION_META_DATA_KEYS.RELATED_ENTITY_NAME);
    const fieldName = _get(entityRelation, ENTITY_RELATION_META_DATA_KEYS.RELATED_FIELD);

    relatedEntityOptions.push({ label: entityName, value: entityName });
    const optionsForEntity = _get(relatedEntityFieldOptions, entityName, []);
    optionsForEntity.push({ label: fieldName, value: fieldName });
    _set(relatedEntityFieldOptions, entityName, [...optionsForEntity]);
  });

  return { relatedEntityOptions, relatedEntityFieldOptions };
};

const getUpdatedExpandableRowConfigValue = (selectedViewComponentProperties, isExpandableRowsEnabled, payload) => {
  const properties = _cloneDeep(selectedViewComponentProperties);
  const expandableRowConfigValues = { [LIST_VIEW_CONFIG_PROPERTY_KEYS.EXPANDABLE_ROWS_ENABLED]: isExpandableRowsEnabled };

  if (properties) {
    const viewRecordActionConfigs = _get(properties, [LIST_VIEW_CONFIG_PROPERTY_KEYS.VIEW_RECORD_ACTION_CONFIGS], []);

    let actionConfigIndex = findIndexForExpandableRowConfig(viewRecordActionConfigs);

    if (isExpandableRowsEnabled) {
      actionConfigIndex = actionConfigIndex === -1 ? _size(viewRecordActionConfigs) : actionConfigIndex;
      let relatedViewProps = _get(viewRecordActionConfigs, [actionConfigIndex, LIST_VIEW_CONFIG_PROPERTY_KEYS.RELATED_VIEW_PROPS], EMPTY_OBJECT);

      if (_has(payload, FIELD_IDS.RELATED_ENTITY)) {
        relatedViewProps = EMPTY_OBJECT;
      }

      viewRecordActionConfigs[actionConfigIndex] = {
        [LIST_VIEW_CONFIG_PROPERTY_KEYS.VIEW_RECORD_ACTION_TYPE]: VIEW_RECORD_ACTION_TYPES.CELL_CLICK,
        [LIST_VIEW_CONFIG_PROPERTY_KEYS.ON_CLICK_ACTION_TYPE]: ON_CLICK_ACTION_TYPES.EXPAND,
        [LIST_VIEW_CONFIG_PROPERTY_KEYS.RELATED_VIEW_PROPS]: {
          ...relatedViewProps,
          ...payload,
        },
      };
    } else {
      _remove(viewRecordActionConfigs, (config, index) => index === actionConfigIndex);
    }

    _set(expandableRowConfigValues, [LIST_VIEW_CONFIG_PROPERTY_KEYS.VIEW_RECORD_ACTION_CONFIGS], viewRecordActionConfigs);
  }

  return expandableRowConfigValues;
};

const getValueOption = (viewConfig) => ({
  label: tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.DISPLAY_NAME, tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME, EMPTY_STRING)),
  value: tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME, EMPTY_STRING),
});

const getOptions = (data) => _map(data, getValueOption);

const getPayload =
  (entityName) =>
  ({ nextPageToken, searchText }) => {
    const filters = [
      {
        field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
        values: _castArray(entityName),
        filterType: OPERATORS.IN,
      },
      {
        field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
        values: [VIEW_TYPES.LIST_VIEW],
        filterType: OPERATORS.IN,
      },
    ];

    if (!_isEmpty(searchText)) {
      filters.push({
        field: VIEW_CONFIGURATION_FIELD_IDS.NAME,
        values: [searchText],
        filterType: OPERATORS.TEXT_STARTS_WITH,
      });
    }

    return {
      rows: ROWS,
      nextPageToken,
      filters,
    };
  };

const isExpandableConfigFormValidated = (fieldId, valueToTest) => {
  let isValid = true;
  const message = {};

  if (_get(valueToTest, LIST_VIEW_CONFIG_PROPERTY_KEYS.EXPANDABLE_ROWS_ENABLED)) {
    const viewRecordActionConfigs = _get(valueToTest, LIST_VIEW_CONFIG_PROPERTY_KEYS.VIEW_RECORD_ACTION_CONFIGS, EMPTY_ARRAY);
    const expandableRowActionConfig = _find(
      viewRecordActionConfigs,
      (config) =>
        _get(config, LIST_VIEW_CONFIG_PROPERTY_KEYS.VIEW_RECORD_ACTION_TYPE, '') === VIEW_RECORD_ACTION_TYPES.CELL_CLICK &&
        _get(config, LIST_VIEW_CONFIG_PROPERTY_KEYS.ON_CLICK_ACTION_TYPE, '') === ON_CLICK_ACTION_TYPES.EXPAND,
    );

    const relatedViewProps = _get(expandableRowActionConfig, [LIST_VIEW_CONFIG_PROPERTY_KEYS.RELATED_VIEW_PROPS]);

    if (_isEmpty(_get(relatedViewProps, [FIELD_IDS.RELATED_ENTITY]))) {
      isValid = false;
      message[FIELD_IDS.RELATED_ENTITY] = __('Related Entity is Required');
    }
    if (_isEmpty(_get(relatedViewProps, [FIELD_IDS.RELATED_FIELD]))) {
      isValid = false;
      message[FIELD_IDS.RELATED_FIELD] = __('Related Field is Required');
    }
    if (_isEmpty(_get(relatedViewProps, [FIELD_IDS.VIEW_NAME]))) {
      isValid = false;
      message[FIELD_IDS.VIEW_NAME] = __('View is Required');
    }
  }
  return isValid ? { isValid } : { isValid, message };
};

export {
  getValuesFromProperties,
  getRelatedEntityAndFieldOptions,
  getUpdatedExpandableRowConfigValue,
  getOptions,
  getPayload,
  isExpandableConfigFormValidated,
};
