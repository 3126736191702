import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';
import { EXPORTS_LIST_PAGE_FIELD_IDS } from './exportListPage.constants';

const DATE_RANGE_FILTER = {
  id: EXPORTS_LIST_PAGE_FIELD_IDS.CREATED_TIME_FIELD,
  key: EXPORTS_LIST_PAGE_FIELD_IDS.CREATED_TIME_FIELD,
  type: FILTER_TYPES.DATE,
  name: __('Created Date'),
};

const STATUS_FILTER = {
  id: EXPORTS_LIST_PAGE_FIELD_IDS.STATUS_FIELD,
  key: EXPORTS_LIST_PAGE_FIELD_IDS.STATUS_FIELD,
  type: FILTER_TYPES.MULTI_SELECT,
  name: __('Status'),
};

const FILTERS = {
  [EXPORTS_LIST_PAGE_FIELD_IDS.CREATED_TIME_FIELD]: DATE_RANGE_FILTER,
  [EXPORTS_LIST_PAGE_FIELD_IDS.STATUS_FIELD]: STATUS_FILTER,
};

const DEFAULT_FILTER_TYPES = [DATE_RANGE_FILTER.id, STATUS_FILTER.id];

export { DATE_RANGE_FILTER, STATUS_FILTER, FILTERS, DEFAULT_FILTER_TYPES };
