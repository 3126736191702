import { useCallback } from 'react';
import ACTION_TYPES from '../constants/templateVariableForm.actionTypes';

function useFormActions(onAction, editor) {
  const handleTemplateVariableCreation = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.CREATE_TEMPLATE_VARIABLE,
        payload: {
          editor,
          formValues,
        },
      });
    },
    [onAction, editor],
  );

  return {
    handleTemplateVariableCreation,
  };
}

export default useFormActions;
