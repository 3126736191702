import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _reduce from 'lodash/reduce';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { getFieldsBasedOnAuthType } from './externalCredentialForm.helpers';

import { AUTH_TYPES, FIELD_IDS } from '../constants/externalCredentialForm.constants';
import { FORM_MODES } from '../../../../../../../constants/general.constants';

import styles from '../externalCredentialForm.module.scss';

const BASIC_DETAILS_SECTION = [
  {
    header: {
      label: __('General Details'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
      },
      {
        columns: [FIELD_IDS.TYPE],
      },
    ],
  },
];

const getAuthCredentialSection = ({
  rowCheck,
  isExternalCredentialSectionVisible,
  isExternalCredentialTypeChanged,
  formMode,
  getExternalCredentialSectionHeader,
}) => {
  let section = {
    header: {
      label: getExternalCredentialSectionHeader(),
      className: styles.formSectionHeader,
    },
    rows: [],
  };

  if (isExternalCredentialSectionVisible || formMode === FORM_MODES.CREATE || isExternalCredentialTypeChanged) {
    section = {
      ...section,
      rows: [
        {
          columns: filterRows([FIELD_IDS.AUTH_PROVIDER_NAME, FIELD_IDS.SCOPE], {}, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.CREATE_AUTH_PROVIDER], {}, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.USER_NAME, FIELD_IDS.PASSWORD], {}, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.APPLICATION_NAME, FIELD_IDS.PLATFORM], {}, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.PRE_SHARED_KEY, FIELD_IDS.SIGNING_ALGORITHM], {}, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.SERVICE, FIELD_IDS.REGION], {}, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.AWS_ACCOUNT_ID, FIELD_IDS.ACCESS_KEY, FIELD_IDS.SECRET_KEY], {}, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.HEADER_KEY, FIELD_IDS.API_KEY], {}, rowCheck),
        },
      ],
    };
  }

  return [section];
};

const getRowCheck = (fieldsBasedOnAuthType) =>
  _reduce(
    FIELD_IDS,
    (prevRowCheck, fieldId) => {
      if (!_includes(fieldsBasedOnAuthType, fieldId)) {
        return { ...prevRowCheck, [fieldId]: () => false };
      }
      return prevRowCheck;
    },
    {},
  );

const getExternalCredentialFormSection = ({
  type,
  isExternalCredentialSectionVisible,
  isExternalCredentialTypeChanged,
  formMode,
  getExternalCredentialSectionHeader,
}) => {
  const fieldsBasedOnAuthType = getFieldsBasedOnAuthType(type);
  const rowCheck = getRowCheck(fieldsBasedOnAuthType);

  if (_isEmpty(type) || type === AUTH_TYPES.NO_AUTH) {
    return BASIC_DETAILS_SECTION;
  }

  return [
    ...BASIC_DETAILS_SECTION,
    ...getAuthCredentialSection({
      rowCheck,
      isExternalCredentialSectionVisible,
      isExternalCredentialTypeChanged,
      formMode,
      getExternalCredentialSectionHeader,
    }),
  ];
};

export default getExternalCredentialFormSection;
