exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".validationRuleBuilder_fullWidthSection__cvs769YKcj{max-width:120rem}.validationRuleBuilder_customSelect__iySJRFgs2n{width:100%}[dir] .validationRuleBuilder_customSelect__iySJRFgs2n .ant-select-selection__choice{padding-top:2px}.validationRuleBuilder_customSelect__iySJRFgs2n .ant-select-selection__choice__remove{top:-1px}[dir=ltr] .validationRuleBuilder_customSelect__iySJRFgs2n .ant-select-selection__choice__remove{right:-1px}[dir=rtl] .validationRuleBuilder_customSelect__iySJRFgs2n .ant-select-selection__choice__remove{left:-1px}.validationRuleBuilder_richTextEditorWrapper__mpiutLDCFL{min-height:18rem;max-height:50rem;max-width:90rem;min-width:90rem}[dir] .validationRuleBuilder_richTextEditorWrapper__mpiutLDCFL{margin-bottom:2rem}.validationRuleBuilder_infoIcon__vY4U8WaGBD{bottom:-0.3rem}.validationRuleBuilder_errorLink__hBGFR9QB4a{color:red;text-decoration:underline;-webkit-text-decoration-color:red;text-decoration-color:red;font-size:medium;font-weight:bold}[dir=ltr] .validationRuleBuilder_errorLink__hBGFR9QB4a{margin-left:2.8rem}[dir=rtl] .validationRuleBuilder_errorLink__hBGFR9QB4a{margin-right:2.8rem}", ""]);

// Exports
exports.locals = {
	"fullWidthSection": "validationRuleBuilder_fullWidthSection__cvs769YKcj",
	"customSelect": "validationRuleBuilder_customSelect__iySJRFgs2n",
	"richTextEditorWrapper": "validationRuleBuilder_richTextEditorWrapper__mpiutLDCFL",
	"infoIcon": "validationRuleBuilder_infoIcon__vY4U8WaGBD",
	"errorLink": "validationRuleBuilder_errorLink__hBGFR9QB4a"
};