exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".validationRulesRenderer_columnContainer__jRjQmTmH8F{display:flex;flex-direction:row;align-items:center}[dir] .validationRulesRenderer_columnContainer__jRjQmTmH8F{margin-bottom:2rem}.validationRulesRenderer_container__xtUQEgcG92{width:100%}[dir] .validationRulesRenderer_container__xtUQEgcG92{background-color:#fcfcfc;padding:1.6rem;border:.1rem dashed #969aa3;border-radius:.5rem;margin-top:1.2rem}.validationRulesRenderer_checkBox__31uP9aXZNB{width:30%;display:flex;align-items:center}.validationRulesRenderer_textInput__qy6xdvhx1U{width:50%}.validationRulesRenderer_mainContainer__iXgSgoJ6De{width:100%}.validationRulesRenderer_innerContainer__8rbtrJaCEo{display:flex;flex-direction:column;width:100%}.validationRulesRenderer_labelContainer__kUJQTyQD3D{display:flex;flex-direction:row}[dir] .validationRulesRenderer_labelContainer__kUJQTyQD3D{margin-bottom:1.2rem}.validationRulesRenderer_firstLabel__ofsc8vjgVw{width:30%}.validationRulesRenderer_secondLabel__cfCPEWR3BE{width:70%}[dir=ltr] .validationRulesRenderer_secondLabel__cfCPEWR3BE{margin-left:1rem}[dir=rtl] .validationRulesRenderer_secondLabel__cfCPEWR3BE{margin-right:1rem}.validationRulesRenderer_inputContainer__9pSCEuhpfb{width:50%}.validationRulesRenderer_statusLabel__74fCjHWoZ2{width:20%;display:flex;flex-direction:\"row\";justify-content:flex-end}[dir] .validationRulesRenderer_activeLabel__gTBmTZmta7{background-color:#60d156}[dir] .validationRulesRenderer_inactiveLabel__okccinGHNM{background-color:#f52908}", ""]);

// Exports
exports.locals = {
	"columnContainer": "validationRulesRenderer_columnContainer__jRjQmTmH8F",
	"container": "validationRulesRenderer_container__xtUQEgcG92",
	"checkBox": "validationRulesRenderer_checkBox__31uP9aXZNB",
	"textInput": "validationRulesRenderer_textInput__qy6xdvhx1U",
	"mainContainer": "validationRulesRenderer_mainContainer__iXgSgoJ6De",
	"innerContainer": "validationRulesRenderer_innerContainer__8rbtrJaCEo",
	"labelContainer": "validationRulesRenderer_labelContainer__kUJQTyQD3D",
	"firstLabel": "validationRulesRenderer_firstLabel__ofsc8vjgVw",
	"secondLabel": "validationRulesRenderer_secondLabel__cfCPEWR3BE",
	"inputContainer": "validationRulesRenderer_inputContainer__9pSCEuhpfb",
	"statusLabel": "validationRulesRenderer_statusLabel__74fCjHWoZ2",
	"activeLabel": "validationRulesRenderer_activeLabel__gTBmTZmta7",
	"inactiveLabel": "validationRulesRenderer_inactiveLabel__okccinGHNM"
};