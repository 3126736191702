import _toLower from 'lodash/toLower';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

const getButtonProps = (properties, BUTTON_VIEW) => {
  const buttonViewType = _toLower(tget(properties, 'buttonType', BUTTON_VIEW.PRIMARY));

  let buttonProps = { view: buttonViewType };

  if (buttonViewType === BUTTON_VIEW.ICON) {
    buttonProps = { ...buttonProps, icon: tget(properties, 'icon', EMPTY_STRING) };
  } else {
    buttonProps = { ...buttonProps, label: __(`${tget(properties, 'text', EMPTY_STRING)}`) };
  }

  return buttonProps;
};

export { getButtonProps };
