import _includes from 'lodash/includes';
import _get from 'lodash/get';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import GENERAL_DETAILS_FORM_FIELD_IDS from '../constants/generalDetailsForm.fieldIds';

import {
  DATA_TYPES_WITHOUT_MULTIVALUED,
  DATA_TYPES_WITHOUT_PLACEHOLDER,
  FIELD_TYPES_WITHOUT_MULTIVALUED,
  FIELD_TYPES_WITHOUT_PLACEHOLDER,
} from '../constants/generalDetailsForm.general.constants';

import styles from '../../../fieldsForm.module.scss';

const rowCheck = {
  [GENERAL_DETAILS_FORM_FIELD_IDS.PLACEHOLDER]: ({ dataType, fieldType }) =>
    !(
      _includes(DATA_TYPES_WITHOUT_PLACEHOLDER, getArraySafeValue(dataType)) ||
      _includes(FIELD_TYPES_WITHOUT_PLACEHOLDER, getArraySafeValue(fieldType))
    ),
  [GENERAL_DETAILS_FORM_FIELD_IDS.MULTI_VALUED]: ({ dataType, fieldType }) =>
    !(
      _includes(DATA_TYPES_WITHOUT_MULTIVALUED, getArraySafeValue(dataType)) ||
      _includes(FIELD_TYPES_WITHOUT_MULTIVALUED, getArraySafeValue(fieldType))
    ),
};

const getSections = (values) => {
  const dataType = _get(values, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE);
  const fieldType = _get(values, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE);

  return [
    {
      header: {
        label: __('General Details'),
        className: styles.formSectionHeader,
      },
      rows: [
        {
          columns: [GENERAL_DETAILS_FORM_FIELD_IDS.DISPLAY_NAME, GENERAL_DETAILS_FORM_FIELD_IDS.NAME],
        },
        {
          columns: [GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE],
        },
        {
          columns: filterRows([GENERAL_DETAILS_FORM_FIELD_IDS.MULTI_VALUED], { dataType, fieldType }, rowCheck),
        },
        {
          columns: filterRows([GENERAL_DETAILS_FORM_FIELD_IDS.PLACEHOLDER], { dataType, fieldType }, rowCheck),
        },
        {
          columns: [GENERAL_DETAILS_FORM_FIELD_IDS.HELP_TEXT],
        },
      ],
    },
  ];
};

export default getSections;
