exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".sideBar_openContainer__c2aVdn3grD{display:flex;flex-flow:column;width:24rem;overflow-y:scroll}[dir] .sideBar_openContainer__c2aVdn3grD{background-color:#f4f5f6;padding-bottom:1.6rem}[dir=ltr] .sideBar_openContainer__c2aVdn3grD{border-right:.1rem solid #969aa3}[dir=rtl] .sideBar_openContainer__c2aVdn3grD{border-left:.1rem solid #969aa3}.sideBar_openContainer__c2aVdn3grD .sideBar_header__dDGwGK1kX1{display:flex;flex-flow:row;align-items:center}.sideBar_closeContainer__kDa3LB8dsS{display:flex;flex-flow:column;width:1.2rem;align-items:center}.sideBar_search__vwuEKiocaf{width:16rem}[dir] .sideBar_search__vwuEKiocaf{margin:1.6rem .8rem 0;border-color:#fff}[dir] .sideBar_openButton__vsR4DZuD8Q{margin-top:1.6rem;background-color:#fff;border:.1rem solid #161616 !important;border-radius:5rem;padding:.4rem !important}[dir=ltr] .sideBar_openButton__vsR4DZuD8Q{margin-left:-1.2rem}[dir=rtl] .sideBar_openButton__vsR4DZuD8Q{margin-right:-1.2rem}[dir] .sideBar_openButton__vsR4DZuD8Q:hover{background:#f4f5f6}[dir] .sideBar_closeButton__cFvQGjVZHT{margin-top:1.6rem;background-color:#fff;border:.1rem solid #161616 !important;border-radius:5rem;padding:.4rem !important}[dir=ltr] .sideBar_closeButton__cFvQGjVZHT{margin-left:5rem}[dir=rtl] .sideBar_closeButton__cFvQGjVZHT{margin-right:5rem}[dir] .sideBar_closeButton__cFvQGjVZHT:hover{background-color:#f4f5f6}", ""]);

// Exports
exports.locals = {
	"openContainer": "sideBar_openContainer__c2aVdn3grD",
	"header": "sideBar_header__dDGwGK1kX1",
	"closeContainer": "sideBar_closeContainer__kDa3LB8dsS",
	"search": "sideBar_search__vwuEKiocaf",
	"openButton": "sideBar_openButton__vsR4DZuD8Q",
	"closeButton": "sideBar_closeButton__cFvQGjVZHT"
};