import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import _get from 'lodash/get';
import _noop from 'lodash/noop';

import { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { NativeSelect as Select } from '@tekion/tekion-components/molecules/advancedSelect';
import withAsyncSelect from '@tekion/tekion-components/molecules/advancedSelect/containers/withAsyncSelect';
import withMultiSelect from '@tekion/tekion-components/molecules/multiSelect/withMultiSelect';

import Option from './Options';

import { getLoadingMessage, getHasMore, getPayload, getWorkSpaceUserOptions } from './userRoleSelect.helper';
import { getWorkspaceUserList } from '../../../../../../../../actions/workspaceUserManagement.actions';

import styles from './asyncUserMultiSelect.module.scss';

const AsyncSelect = compose(withMultiSelect, withAsyncSelect)(Select);

const UserRoleSelect = ({ values, className, onChange, history, existingUserIds, rolesDataById, ...restProps }) => {
  const [nextPageToken, setNextPageToken] = useState(EMPTY_STRING);
  const [searchText, setSearchText] = useState(EMPTY_STRING);

  const handleLoadOptions = useCallback(
    async (inputString = EMPTY_STRING) => {
      let payload = EMPTY_OBJECT;

      if (inputString !== searchText) {
        payload = getPayload(EMPTY_STRING, inputString);
        setNextPageToken(EMPTY_STRING);
        setSearchText(inputString);
      } else {
        payload = getPayload(nextPageToken, inputString);
      }

      const data = await getWorkspaceUserList(payload);
      const hits = tget(data, 'hits', EMPTY_ARRAY);
      const currentNextPageToken = _get(data, 'nextPageToken');
      const newOptions = getWorkSpaceUserOptions(hits, existingUserIds, rolesDataById);
      const hasMore = getHasMore(currentNextPageToken);
      setNextPageToken(currentNextPageToken);
      return { options: newOptions, hasMore };
    },
    [nextPageToken, searchText, existingUserIds, rolesDataById],
  );

  return (
    <AsyncSelect
      {...restProps}
      components={{ Option }}
      refreshOptions
      onChange={onChange}
      openMenuOnFocus
      loadOptions={handleLoadOptions}
      value={values}
      loadingMessage={getLoadingMessage}
      newSelect
    />
  );
};
UserRoleSelect.propTypes = {
  placeholder: PropTypes.string,
  values: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
  history: PropTypes.object,
  existingUserIds: PropTypes.array,
  rolesDataById: PropTypes.object,
};

UserRoleSelect.defaultProps = {
  placeholder: __('Type Here'),
  values: EMPTY_ARRAY,
  onChange: _noop,
  className: styles.select,
  history: EMPTY_OBJECT,
  existingUserIds: EMPTY_ARRAY,
  rolesDataById: EMPTY_OBJECT,
};

export default withRouter(UserRoleSelect);
