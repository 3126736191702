import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import { SIZES } from '@tekion/tekion-components/atoms/FontIcon/Icon.constants';

const RecordSharingRulePermissionIconRenderer = ({ data }) => {
  const icon = useMemo(() => (data ? 'icon-tick1' : 'icon-cross'), [data]);

  return (
    <FontIcon className="p-l-4 inline" size={SIZES.MD}>
      {icon}
    </FontIcon>
  );
};

RecordSharingRulePermissionIconRenderer.propTypes = {
  data: PropTypes.bool,
};

RecordSharingRulePermissionIconRenderer.defaultProps = {
  data: false,
};

export default makeCellRenderer(RecordSharingRulePermissionIconRenderer);
