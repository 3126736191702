import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { FIELD_IDS } from './templateBuilderForm.fields';

const getTemplateData = (templateData) => {
  let jsonBody = _get(templateData, FIELD_IDS.JSON_BODY);
  if (!_isEmpty(jsonBody)) {
    try {
      jsonBody = JSON.parse(jsonBody);
    } catch (error) {
      jsonBody = undefined;
    }
  } else {
    jsonBody = undefined;
  }

  return { ...templateData, [FIELD_IDS.TEMPLATE_TYPE]: [_get(templateData, `${FIELD_IDS.TEMPLATE_TYPE}`)], [FIELD_IDS.JSON_BODY]: jsonBody };
};

export { getTemplateData };
