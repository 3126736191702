import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cx from 'classnames';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Loader from '@tekion/tekion-components/molecules/loader';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import withEventSubmission from '@tekion/tekion-components/pages/formPage/withEventSubmission';

import withSize from '../../../../../connectors/withSize';

import ACTION_HANDLERS from './helpers/visualBuilderMetadataPage.actionHandlers';

import { FORM_MODES } from '../../../../../constants/general.constants';
import { ACTION_TYPES, CONTEXT_ID } from './constants/visualBuilderMetadataPage.constants';
import { getPageFormFields, getPageFormSections } from './constants/visualBuilderMetadataForm.config';

// Styles
import styles from './visualBuilderMetadataPage.module.scss';

const Form = withEventSubmission(FormBuilder);

const VisualBuilderMetadataPage = ({ isLoading, formMode, contentHeight, formValues, formErrors, onAction }) => {
  const headerTitle = useMemo(() => (formMode === FORM_MODES.CREATE ? __('Create New Page') : __('Update Page')), [formMode]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, []);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const sections = useMemo(() => getPageFormSections(formValues), [formValues]);
  const fields = useMemo(() => getPageFormFields(formMode), [formMode]);

  const renderPageContent = () => {
    if (isLoading) {
      return <Loader />;
    } else {
      return (
        <Form
          className={`full-width p-3 ${styles.form}`}
          contextId={CONTEXT_ID}
          sections={sections}
          fields={fields}
          values={formValues}
          errors={formErrors}
          onAction={onAction}
        />
      );
    }
  };

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction]);

  return (
    <Page className="full-height">
      <Page.Header hasBack className={styles.header} goBackTo="">
        <div className="full-width flex justify-content-between">
          <Heading>{headerTitle}</Heading>
        </div>
      </Page.Header>
      <Page.Body className={cx('full-width flex', styles.body)} style={{ height: contentHeight }}>
        {renderPageContent()}
      </Page.Body>
      <Page.Footer>
        <SaveComponent id={CONTEXT_ID} primaryButtonLabel={__('Next')} onPrimaryAction={handleSubmit} onSecondaryAction={handleCancel} />
      </Page.Footer>
    </Page>
  );
};

VisualBuilderMetadataPage.propTypes = {
  isLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  formValues: PropTypes.object,
  formErrors: PropTypes.arrayOf(PropTypes.object),
  onAction: PropTypes.func.isRequired,
};

VisualBuilderMetadataPage.defaultProps = {
  isLoading: true,
  formMode: FORM_MODES.CREATE,
  formValues: EMPTY_OBJECT,
  formErrors: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(VisualBuilderMetadataPage);
