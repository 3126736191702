import React, { useEffect, useMemo } from 'react';
import compose from 'recompose/compose';
import renameProps from 'recompose/renameProps';
import PropTypes from 'prop-types';

import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import LOOKUP_DETAILS_FORM_ACTION_HANDLERS from './helpers/lookupDetailsForm.actionHandlers';
import getFields from './helpers/lookupDetailsForm.fields';

import LOOKUP_DETAILS_FORM_SECTIONS from './constants/lookupDetailsForm.sections';
import FIELDS_FORM_FIELD_IDS from '../../constants/fieldsForm.fieldIds';
import ACTION_TYPES from '../../constants/fieldsForm.actionTypes';

const LookupDetailsForm = ({ renderOptions, values, onAction }) => {
  const fields = useMemo(() => getFields(renderOptions), [renderOptions]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_RENDER_OPTIONS_CHANGE, payload: { renderOptions } });
  }, [renderOptions, onAction]);

  return (
    <FormWithSubmission
      className="full-width"
      contextId={FIELDS_FORM_FIELD_IDS.LOOKUP_DETAILS_FORM}
      values={values}
      fields={fields}
      sections={LOOKUP_DETAILS_FORM_SECTIONS}
      onAction={onAction}
    />
  );
};

LookupDetailsForm.propTypes = {
  renderOptions: PropTypes.object,
  values: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

LookupDetailsForm.defaultProps = {
  renderOptions: EMPTY_OBJECT,
  values: EMPTY_OBJECT,
};

export default compose(
  renameProps({ onAction: 'fieldsFormOnAction', value: 'fieldsFormLookupDetailsValue' }),
  withActions(EMPTY_OBJECT, LOOKUP_DETAILS_FORM_ACTION_HANDLERS),
)(LookupDetailsForm);
