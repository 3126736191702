import { FORM_MODES } from '../../../../../../../constants/viewBuilder.constants';

const INITIAL_MAPPINGS = [{}];

const FIELD_ERROR_MESSAGE = __('This field is mandatory!');

const MODE_LABELS = {
  [FORM_MODES.CREATE]: __('create'),
  [FORM_MODES.EDIT]: __('update'),
};

export { INITIAL_MAPPINGS, FIELD_ERROR_MESSAGE, MODE_LABELS };
