import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import withActions from '@tekion/tekion-components/connectors/withActions';
import { tget } from '@tekion/tekion-base/utils/general';

import { getSections } from './helpers/tabProperties.sections';
import { getFields } from './helpers/tabProperties.fields';
import ACTION_HANDLERS from './helpers/tabProperties.actionHandlers';

import ACTION_TYPES from './constants/tabProperties.actionTypes';
import { TAB_PROPERTIES_CONTEXT_ID } from './constants/tabProperties.constants';

import styles from './tabProperties.module.scss';

const TabProperties = ({
  isModalVisible,
  selectedModuleIndex,
  selectedComponentId,
  tabValues,
  errorObject,
  entityErrorObject,
  selectedEntities,
  configureEntityValues,
  onAction,
}) => {
  const fields = useMemo(
    () => getFields(isModalVisible, entityErrorObject, selectedModuleIndex, selectedEntities, configureEntityValues),
    [isModalVisible, entityErrorObject, selectedModuleIndex, selectedEntities, configureEntityValues],
  );
  const sections = useMemo(() => getSections(selectedComponentId), [selectedComponentId]);
  const errors = useMemo(() => tget(errorObject, selectedComponentId, EMPTY_OBJECT), [errorObject, selectedComponentId]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction, selectedComponentId]);

  return (
    <>
      <div className={styles.tabHeader}>{__('Tab')}</div>
      <FormWithSubmission
        contextId={TAB_PROPERTIES_CONTEXT_ID}
        fields={fields}
        sections={sections}
        errors={errors}
        values={tabValues}
        onAction={onAction}
      />
    </>
  );
};

TabProperties.propTypes = {
  isModalVisible: PropTypes.bool,
  selectedModuleIndex: PropTypes.number,
  selectedComponentId: PropTypes.string,
  tabValues: PropTypes.object,
  errorObject: PropTypes.object,
  entityErrorObject: PropTypes.array,
  selectedEntities: PropTypes.array,
  configureEntityValues: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

TabProperties.defaultProps = {
  isModalVisible: false,
  selectedModuleIndex: 0,
  selectedComponentId: EMPTY_STRING,
  tabValues: EMPTY_OBJECT,
  errorObject: EMPTY_OBJECT,
  entityErrorObject: EMPTY_ARRAY,
  selectedEntities: EMPTY_ARRAY,
  configureEntityValues: EMPTY_ARRAY,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(TabProperties);
