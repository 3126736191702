import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../../../../constants/approvalCentre.constants';

const APPROVAL_PROCESS_FIELD_IDS = {
  DISPLAY_NAME: APPROVAL_CENTRE_FIELD_IDS.DISPLAY_NAME,
  NAME: APPROVAL_CENTRE_FIELD_IDS.NAME,
  GROUP: APPROVAL_CENTRE_FIELD_IDS.GROUP,
  CATEGORY: APPROVAL_CENTRE_FIELD_IDS.CATEGORY,
  INACTIVE: APPROVAL_CENTRE_FIELD_IDS.INACTIVE,
  PRIORITY: APPROVAL_CENTRE_FIELD_IDS.PRIORITY,
  CONDITION: APPROVAL_CENTRE_FIELD_IDS.CONDITION,
  STAGES: APPROVAL_CENTRE_FIELD_IDS.STAGES,
  VALIDITY: APPROVAL_CENTRE_FIELD_IDS.VALIDITY,
  VALIDITY_TYPE: APPROVAL_CENTRE_FIELD_IDS.VALIDITY_TYPE,
  VALIDITY_DAYS: APPROVAL_CENTRE_FIELD_IDS.VALIDITY_DAYS,
  VALIDITY_HOURS: APPROVAL_CENTRE_FIELD_IDS.VALIDITY_HOURS,
  VALIDITY_MINUTES: APPROVAL_CENTRE_FIELD_IDS.VALIDITY_MINUTES,
  VALIDITY_EPOCH: APPROVAL_CENTRE_FIELD_IDS.VALIDITY_EPOCH,
  APPROVERS: APPROVAL_CENTRE_FIELD_IDS.APPROVERS,
  APPROVER_ID: APPROVAL_CENTRE_FIELD_IDS.APPROVER_ID,
  MIN_APPROVALS: APPROVAL_CENTRE_FIELD_IDS.MIN_APPROVALS,
  TYPE: APPROVAL_CENTRE_FIELD_IDS.TYPE,
};

export default APPROVAL_PROCESS_FIELD_IDS;
