exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .activityLog_activityLogContainer__94gYhRLo4i{padding:1rem 2rem;border-bottom:.1rem solid #d4d5d6}.activityLog_activityLogHeader__iYc1uX6JxE{display:flex;justify-content:space-between;align-items:center}[dir] .activityLog_activityLogHeader__iYc1uX6JxE{margin:.5rem 0;padding:1rem 2rem;background-color:#f4f5f6}.activityLog_changeTime__899ZpeMQur{color:#969aa3}.activityLog_userName__kMecxWGEe6{max-width:75%}[dir] .activityLog_createdTag__4m6gNiSyRw{background-color:#00bfa5}", ""]);

// Exports
exports.locals = {
	"activityLogContainer": "activityLog_activityLogContainer__94gYhRLo4i",
	"activityLogHeader": "activityLog_activityLogHeader__iYc1uX6JxE",
	"changeTime": "activityLog_changeTime__899ZpeMQur",
	"userName": "activityLog_userName__kMecxWGEe6",
	"createdTag": "activityLog_createdTag__4m6gNiSyRw"
};