exports = module.exports = require("../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .selectedEntityConfigurator_selectedEntityComponentHeading__cEXe8i9y3s{margin-bottom:1.6rem}[dir] .selectedEntityConfigurator_heading__968Jm5S9Pv{margin-bottom:1.6rem}.selectedEntityConfigurator_configureButton__dV8aE1kjGm{display:flex;justify-content:center;height:2.2rem;font-weight:bolder;font-size:1.56rem}[dir] .selectedEntityConfigurator_configureButton__dV8aE1kjGm{padding:.2rem .8rem 0 !important}.selectedEntityConfigurator_selectedEntityComponent__nqVTMM7HBB{display:flex;justify-content:space-between;width:100%}[dir] .selectedEntityConfigurator_submitButton__w9jdvELEef:hover{background-color:#ededed}.selectedEntityConfigurator_selectedEntityHandlerModal__xzWHXGFkb7{width:100%}[dir] .selectedEntityConfigurator_addButton__sEM4JuxACG{margin:1rem 0 0}", ""]);

// Exports
exports.locals = {
	"selectedEntityComponentHeading": "selectedEntityConfigurator_selectedEntityComponentHeading__cEXe8i9y3s",
	"heading": "selectedEntityConfigurator_heading__968Jm5S9Pv",
	"configureButton": "selectedEntityConfigurator_configureButton__dV8aE1kjGm",
	"selectedEntityComponent": "selectedEntityConfigurator_selectedEntityComponent__nqVTMM7HBB",
	"submitButton": "selectedEntityConfigurator_submitButton__w9jdvELEef",
	"selectedEntityHandlerModal": "selectedEntityConfigurator_selectedEntityHandlerModal__xzWHXGFkb7",
	"addButton": "selectedEntityConfigurator_addButton__sEM4JuxACG"
};