exports = module.exports = require("../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "", ""]);

// Exports
exports.locals = {
	"atomic50": "#a1a7ad",
	"denim10": "#ecf2fd",
	"denim30": "#c6dafb",
	"denim50": "#a0c2f9",
	"tekionGreen10": "#e5f8f6",
	"tekionGreen30": "#b2ebe4",
	"tekionGreen50": "#7fdfd2",
	"electricViolet10": "#f2eff9",
	"electricViolet30": "#ebd8ff",
	"electricViolet50": "#cc7ff9",
	"buttercupYellow10": "#fdf6e9",
	"buttercupYellow30": "#fbe4bd",
	"buttercupYellow50": "#f9d291",
	"gold10": "#fffae5",
	"gold30": "#fff2b2",
	"gold50": "#ffe97f"
};