import { defaultMemoize } from 'reselect';

import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import addOptionsToAdditional from '@tekion/tekion-base/utils/addOptionsToAdditional';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import { DEFAULT_FILTER_GROUP } from '@tekion/tekion-components/organisms/filterSection';

import { STATUS_FILTER, STATUS_OPTIONS, DEFAULT_FILTERS, OPERATION_TYPE_FILTER, OPERATION_TYPE_OPTIONS } from '../constants/dataImportList.constants';

const createTableProps = (isLoading, currentPage, pageSize, totalCount, onAction) => ({
  loading: isLoading,
  currentPage: currentPage + 1,
  rowHeight: 35,
  minRows: 0,
  totalNumberOfEntries: totalCount,
  showPagination: true,
  pageSize,
  resultsPerPage: pageSize,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  onRowActionClick: (type, payload) => onAction({ type, payload }),
});

const getFilterProps = (selectedFilters) => {
  const filterTypes = [addOptionsToAdditional(OPERATION_TYPE_FILTER, OPERATION_TYPE_OPTIONS), addOptionsToAdditional(STATUS_FILTER, STATUS_OPTIONS)];

  return {
    filterTypes,
    selectedFilters,
    appliedFilterGroup: DEFAULT_FILTER_GROUP,
    defaultFilterTypes: DEFAULT_FILTERS,
    showResultsCount: true,
  };
};

const getModifiedFilters = defaultMemoize((selectedFilters) => {
  const modifiedFilters = _reduce(
    selectedFilters,
    (result, filter) => {
      if (!_isEmpty(tget(filter, 'values'))) {
        result.push({
          values: tget(filter, 'values'),
          field: tget(filter, 'type'),
          filterType: tget(filter, 'operator'),
        });
      }
      return result;
    },
    [],
  );
  return modifiedFilters;
});

const getPayload = defaultMemoize((selectedFilters, pageSize, nextPageToken) => {
  let payload = { rows: pageSize, countRequired: true };
  let filters = EMPTY_ARRAY;

  if (!_isEmpty(selectedFilters)) {
    filters = [...getModifiedFilters(selectedFilters)];
  }

  if (!_isEmpty(filters)) {
    payload = {
      filters,
      ...payload,
    };
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      nextPageToken,
      ...payload,
    };
  }
  return payload;
});

export { createTableProps, getFilterProps, getPayload };
