import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

const INITIAL_STATE = {
  values: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

const GLOBAL_SELECT_LIST_FORM_CONTEXT_ID = 'GLOBAL_SELECT_LIST_FORM';

const FIELD_IDS = {
  DISPLAY_NAME: 'displayName',
  NAME: 'name',
  DESCRIPTION: 'description',
  OPTIONS: 'options',
};

export { GLOBAL_SELECT_LIST_FORM_CONTEXT_ID, INITIAL_STATE, FIELD_IDS };
