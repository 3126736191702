import _get from 'lodash/get';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _isNil from 'lodash/isNil';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { isErrorEmpty } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { fieldDataTypeCheck, getFieldsFormGeneralDetailsValues, getGeneralDetailsFormValues } from './generalDetailsForm.general.helpers';

import GENERAL_DETAILS_FORM_FIELD_IDS from '../constants/generalDetailsForm.fieldIds';
import ACTION_TYPES from '../../../constants/fieldsForm.actionTypes';
import FIELDS_FORM_FIELD_IDS from '../../../constants/fieldsForm.fieldIds';
import { FIELD_FORM_CONTEXT_ID } from '../../../constants/fieldsForm.constants';
import FIELD_TYPES from '../../../../../../../../constants/fieldDefinition.fieldTypes';
import {
  DATA_TYPES_WITHOUT_PLACEHOLDER,
  FIELD_IDS_WITH_VARYING_RENDER_OPTIONS,
  FIELD_TYPES_WITHOUT_PLACEHOLDER,
  PLACEHOLDERS,
} from '../constants/generalDetailsForm.general.constants';

const handleInitForm = ({ getState, setState }) => {
  const { fieldsFormGeneralDetailsValues = {}, disabledAutoFillForName = false } = getState();

  setState({ values: getGeneralDetailsFormValues(fieldsFormGeneralDetailsValues), isDisabledAutoFillForName: disabledAutoFillForName });
};

const handleFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { values: oldValues = EMPTY_OBJECT, fieldsFormOnAction, isDisabledAutoFillForName = false } = getState();
  const values = { ...oldValues, [id]: value };

  if (id === GENERAL_DETAILS_FORM_FIELD_IDS.DISPLAY_NAME && !isDisabledAutoFillForName) {
    _set(values, GENERAL_DETAILS_FORM_FIELD_IDS.NAME, _snakeCase(value));
  }

  if (id === GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE && getArraySafeValue(value) === FIELD_TYPES.RELATIONSHIP) {
    _unset(values, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE);
  }

  if (id === GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE || id === GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE) {
    const fieldType = getArraySafeValue(_get(values, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE));
    const dataType = getArraySafeValue(_get(values, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE));
    const defaultPlaceholder = _get(PLACEHOLDERS, fieldType === FIELD_TYPES.RELATIONSHIP ? fieldType : [dataType, fieldType]);

    if (!_isEmpty(defaultPlaceholder)) {
      _set(values, GENERAL_DETAILS_FORM_FIELD_IDS.PLACEHOLDER, defaultPlaceholder);
    }
  }

  if (
    (id === GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE && _includes(DATA_TYPES_WITHOUT_PLACEHOLDER, getArraySafeValue(value))) ||
    (id === GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE && _includes(FIELD_TYPES_WITHOUT_PLACEHOLDER, getArraySafeValue(value)))
  ) {
    _unset(values, GENERAL_DETAILS_FORM_FIELD_IDS.PLACEHOLDER);
  }

  if (id === GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE) {
    const fieldDataTypeChecker = _get(fieldDataTypeCheck, getArraySafeValue(value));

    if (fieldDataTypeChecker) {
      const fieldType = _get(values, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE);
      if (!fieldDataTypeChecker(getArraySafeValue(fieldType))) {
        _unset(values, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE);
      }
    }
  }

  setState({ values, isDisabledAutoFillForName: isDisabledAutoFillForName || id === GENERAL_DETAILS_FORM_FIELD_IDS.NAME }, () =>
    fieldsFormOnAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, value: getFieldsFormGeneralDetailsValues(values) },
    }),
  );
};

const handleValidationSuccess = ({ getState, params = EMPTY_OBJECT }) => {
  const { errors } = params;
  const { fieldsFormOnAction } = getState();

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: { id: FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, errors },
  });
};

const handleRenderOptionsChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { renderOptions = EMPTY_OBJECT } = params;
  const { values: oldValues = EMPTY_OBJECT, fieldsFormOnAction, fieldsFormGeneralDetailsValues } = getState();
  const values = { ...oldValues };

  let changed = false;

  _forEach(FIELD_IDS_WITH_VARYING_RENDER_OPTIONS, (fieldId) => {
    const value = _get(renderOptions, [fieldId, 'value']);

    if (!_isNil(value) && value !== _get(values, fieldId)) {
      if (_get(fieldsFormGeneralDetailsValues, fieldId) !== value) {
        _set(fieldsFormGeneralDetailsValues, fieldId, value);
        changed = true;
      }

      _set(values, fieldId, value);
    }
  });

  if (changed) {
    setState({ values }, () =>
      fieldsFormOnAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: { id: FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, value: getFieldsFormGeneralDetailsValues(fieldsFormGeneralDetailsValues) },
      }),
    );
  }
};

const handleSubmit = ({ getState, params = EMPTY_OBJECT }) => {
  const { additional = {} } = params;
  const { formErrors } = additional;
  const { fieldsFormOnAction } = getState();

  fieldsFormOnAction({ type: FORM_ACTION_TYPES.VALIDATION_SUCCESS, payload: { id: FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, errors: undefined } });

  _set(formErrors, FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, false);

  if (isErrorEmpty(formErrors)) {
    triggerSubmit(FIELD_FORM_CONTEXT_ID, _cloneDeep(additional));
  }
};

const GENERAL_DETAILS_FORM_ACTION_HANDLERS = {
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleInitForm,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [ACTION_TYPES.ON_RENDER_OPTIONS_CHANGE]: handleRenderOptionsChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidationSuccess,
};

export default GENERAL_DETAILS_FORM_ACTION_HANDLERS;
