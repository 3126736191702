import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';
import _toUpper from 'lodash/toUpper';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

import { LOGIN_PROVIDERS, PROVIDERS_DISPLAY_NAME, PROVIDER_IMAGES } from '../../constants/loginConfigurationsDashboard.general.constants';
import ACTION_TYPES from '../../constants/loginConfigurationsDashboard.actionTypes';

import styles from './authConfigTile.module.scss';

const AuthConfigTile = ({ providerConfig, onClickAuthConfigTile, onAction }) => {
  const getInitialsFromName = useCallback((name) => {
    const initials = name ? name.match(/\b\w/g) || [] : [];

    return _toUpper((initials.shift() || '') + (initials.pop() || ''));
  }, []);

  const renderLogo = useCallback(
    (providerType) => {
      const initials = getInitialsFromName(PROVIDERS_DISPLAY_NAME[providerType]);
      const logoImage = PROVIDER_IMAGES[providerType];
      if (_isEmpty(logoImage)) return <div className={styles.logoPlaceHolder}>{initials}</div>;
      return <img className={styles.imgSize} src={logoImage} alt={logoImage} />;
    },
    [getInitialsFromName],
  );

  const handleClickRemoveConfig = useCallback(
    (event) => {
      event?.stopPropagation();
      onAction({ type: ACTION_TYPES.ON_CLICK_REMOVE_ICON, payload: { selectedProviderConfig: providerConfig } });
    },
    [onAction, providerConfig],
  );

  const providerType = tget(providerConfig, 'providerType');

  return (
    <div key={providerType} onClick={() => onClickAuthConfigTile(providerConfig)} className={styles.providerTile}>
      <PropertyControlledComponent controllerProperty={providerType !== LOGIN_PROVIDERS.TEKION}>
        <FontIcon className={styles.removeIcon} onClick={handleClickRemoveConfig}>
          icon-trash
        </FontIcon>
      </PropertyControlledComponent>
      <div className={styles.contentContainer}>
        <div className={styles.providerLogo}>{renderLogo(providerType)}</div>
        <div className={styles.providerName}>{PROVIDERS_DISPLAY_NAME[providerType]}</div>
      </div>
    </div>
  );
};

AuthConfigTile.propTypes = {
  providerConfig: PropTypes.object,
  onClickAuthConfigTile: PropTypes.func,
  onAction: PropTypes.func.isRequired,
};

AuthConfigTile.defaultProps = {
  providerConfig: EMPTY_OBJECT,
  onClickAuthConfigTile: _noop,
};

export default React.memo(AuthConfigTile);
