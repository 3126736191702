import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import Button from '@tekion/tekion-components/atoms/Button';

import { BUNDLE_STATE_LABEL_MAP, BUNDLE_STATE_COLOR_MAP } from './exportList.general.constants';
import ACTION_TYPES from './exportList.actionTypes';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const getStatusProps = () => ({
  colorMap: BUNDLE_STATE_COLOR_MAP,
  labelMap: BUNDLE_STATE_LABEL_MAP,
});

const COLUMN_IDS = {
  DISPLAY_NAME: 'displayName',
  NAME: 'name',
  DESCRIPTIONS: 'description',
  STATE: 'state',
  CREATED_TIME: 'createdTime',
};

const EXPORT_LIST_COLUMNS = [
  {
    id: COLUMN_IDS.DISPLAY_NAME,
    Header: __('Display Name'),
    accessor: COLUMN_IDS.DISPLAY_NAME,
    searchable: true,
    Cell: TextRenderer,
  },
  {
    id: COLUMN_IDS.NAME,
    Header: __('Name'),
    accessor: COLUMN_IDS.NAME,
    searchable: true,
    Cell: TextRenderer,
  },
  {
    id: COLUMN_IDS.DESCRIPTIONS,
    Header: __('Description'),
    accessor: COLUMN_IDS.DESCRIPTIONS,
    Cell: TextRenderer,
  },
  {
    id: COLUMN_IDS.STATE,
    Header: __('State'),
    accessor: COLUMN_IDS.STATE,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    id: COLUMN_IDS.CREATED_TIME,
    Header: __('Created on'),
    accessor: COLUMN_IDS.CREATED_TIME,
    Cell: TextRenderer,
  },
];

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create bundle'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_EXPORT,
    },
  ],
};

export { COLUMN_IDS, EXPORT_LIST_COLUMNS, SUB_HEADER_PROPS };
