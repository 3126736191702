import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import _noop from 'lodash/noop';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import withActions from '@tekion/tekion-components/connectors/withActions';

import ClickableDiv from './components/clickableDiv';

import { CLICKABLE_DIV_IDS, WELCOME_MSG, DESCRIPTION } from './constants/studioHomePage.general.constants';
import ACTION_HANDLERS from './helpers/studioHomePage.actionHandlers';

import styles from './studioHomePage.module.scss';

function StudioHomePage(props) {
  const { onAction } = props;
  return (
    <Page>
      <Page.Body>
        <div className={styles.welcomeMsgContainer}>
          <Heading size={1} className={styles.header}>
            {WELCOME_MSG}
          </Heading>
          <Heading size={3} className={styles.header}>
            {DESCRIPTION}
          </Heading>
          <div className={styles.buttonContainer}>
            <ClickableDiv onAction={onAction} id={CLICKABLE_DIV_IDS.CREATE_APPLICATION} />
            <ClickableDiv onAction={onAction} id={CLICKABLE_DIV_IDS.CREATE_ENTITY} />
          </div>
        </div>
      </Page.Body>
    </Page>
  );
}

StudioHomePage.propTypes = {
  onAction: PropTypes.func,
};

StudioHomePage.defaultProps = {
  onAction: _noop,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS), React.memo)(StudioHomePage);
