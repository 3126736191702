import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Modal from '@tekion/tekion-components/molecules/Modal';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import ACTION_TYPES from '../../constants/wwdPermissionList.actionTypes';
import { COLUMN_IDS } from '../../constants/wwdPermissionList.constants';
import { CONTEXT_ID } from './wwdPermissionEditModal.constants';
import { FORM_FIELDS, FORM_SECTION } from './wwdPermissionEditModal.formFields';

const WWDPermissionEditModal = ({
  isWWDPermissionEditModalVisible,
  isWWDPermissionEditModalSubmitting,
  errors,
  wwdPermissionFormValues,
  onAction,
}) => {
  const entityName = _get(wwdPermissionFormValues, COLUMN_IDS.ENTITY_NAME);

  const handleClose = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CLOSE_WWD_PERMISSION_EDIT_MODAL,
    });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, []);

  return (
    <Modal
      visible={isWWDPermissionEditModalVisible}
      loading={isWWDPermissionEditModalSubmitting}
      destroyOnClose
      title={__(`WWD Settings (${entityName})`)}
      onCancel={handleClose}
      onSubmit={handleSubmit}
    >
      <FormWithSubmission
        contextId={CONTEXT_ID}
        fields={FORM_FIELDS}
        sections={FORM_SECTION}
        values={wwdPermissionFormValues}
        errors={errors}
        onAction={onAction}
      />
    </Modal>
  );
};
WWDPermissionEditModal.propTypes = {
  isWWDPermissionEditModalVisible: PropTypes.bool,
  isWWDPermissionEditModalSubmitting: PropTypes.bool,
  wwdPermissionFormValues: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};
WWDPermissionEditModal.defaultProps = {
  isWWDPermissionEditModalVisible: false,
  isWWDPermissionEditModalSubmitting: false,
  wwdPermissionFormValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default WWDPermissionEditModal;
