import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _isNil from 'lodash/isNil';
import _noop from 'lodash/noop';
import _get from 'lodash/get';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

// Utils
import { DATE_TIME_FORMATS, getFormattedDateTime, getUnix, toMoment, toMomentWithParserFormat } from '@tekion/tekion-base/utils/dateUtils';

import style from '../formViewRenderer.module.scss';

const DatePickerWrapper = (DatePickerField) => {
  const WrappedComponent = React.memo(({ showTime, value, onAction, ...restProps }) => {
    const momentValue = useMemo(() => {
      if (_isNil(value)) return undefined;
      if (showTime) {
        return toMomentWithParserFormat(value, DATE_TIME_FORMATS.DATE_WITH_TIME) || toMoment(value);
      }
      return toMomentWithParserFormat(value, DATE_TIME_FORMATS.ISO_8601_DATE) || toMoment(value);
    }, [showTime, value]);

    const handleAction = useCallback(
      (action = EMPTY_OBJECT) => {
        const type = _get(action, 'type');
        const payload = _get(action, 'payload', EMPTY_OBJECT);
        const _value = _get(action, 'payload.value', EMPTY_ARRAY);
        if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
          onAction({
            type,
            payload: {
              ...payload,
              value: showTime ? getUnix(_value) : getFormattedDateTime(_value, DATE_TIME_FORMATS.ISO_8601_DATE),
            },
          });
        } else {
          onAction(action);
        }
      },
      [onAction, showTime],
    );

    return (
      <DatePickerField showTime={showTime} value={momentValue} onAction={handleAction} {...restProps} containerClassName={style.datePickerWrapper} />
    );
  });

  WrappedComponent.propTypes = {
    showTime: PropTypes.bool,
    value: PropTypes.string,
    onAction: PropTypes.func,
  };

  WrappedComponent.defaultProps = {
    showTime: false,
    value: undefined,
    onAction: _noop,
  };

  return WrappedComponent;
};

export default DatePickerWrapper;
