import _map from 'lodash/map';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { getCurrentTimeUnix } from '@tekion/tekion-base/utils/dateUtils';

import { DEFAULT_PAGE_SIZE } from '../constants/auditLogs.general';

export const makeAuditAssetDTO = ({ assetId, assetType, pageSize = DEFAULT_PAGE_SIZE, pageToken }) => ({
  assetId,
  assetType,
  pageSize,
  pageToken,
  includeUserMimicInfo: true,
});

export const makeAuditParentAssetDTO = ({ assetId, assetType, pageSize = DEFAULT_PAGE_SIZE, pageToken }) => ({
  parentAssetId: assetId,
  parentAssetType: assetType,
  pageSize,
  pageToken,
  includeUserMimicInfo: true,
});

export const makeAuditByAssetTypeDTO = ({ assetType, pageSize = DEFAULT_PAGE_SIZE, pageToken }) => ({
  assetType,
  pageSize,
  nextPageToken: pageToken,
  startTime: 0,
  endTime: getCurrentTimeUnix(),
});

export const makeBulkAuditAssetDTO = ({
  assetId: primaryAssetId,
  assetType: primaryAssetType,
  pageSize = DEFAULT_PAGE_SIZE,
  additionalBulkAuditAssets = EMPTY_ARRAY,
  nextPageTokenMap,
}) => {
  const assetListForAudit = [...additionalBulkAuditAssets, { assetId: primaryAssetId, assetType: primaryAssetType }];
  const assetRequest = _map(assetListForAudit, (asset = EMPTY_OBJECT) => {
    const { assetId = EMPTY_STRING, assetType = EMPTY_STRING } = asset;
    const pageToken = _get(nextPageTokenMap, `${assetType}-${assetId}`);
    return {
      ...asset,
      ...(pageToken ? { pageToken } : {}),
    };
  });

  return {
    pageSize,
    requests: assetRequest,
  };
};
