import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';
import { defaultMemoize } from 'reselect';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS from '../constants/complexFieldDetailsForm.fieldIds';
import { COMPLEX_FIELD_FILTERS } from '../constants/complexFieldDetailsForm.general.constants';

const getSearchTextPayload = defaultMemoize((nextPageToken, searchText = '', filters = []) => ({
  filters: [...filters, { field: 'displayName', filterType: OPERATORS.TEXT_STARTS_WITH, values: _castArray(searchText) }],
  rows: 10,
  nextPageToken,
}));

const getPayloadForInitialFetch = defaultMemoize((value) => ({
  rows: 1,
  filters: [...COMPLEX_FIELD_FILTERS, { field: 'name', filterType: OPERATORS.IN, values: _castArray(value) }],
}));

const getPayload = defaultMemoize(({ nextPageToken, searchText }) => {
  const filters = [...COMPLEX_FIELD_FILTERS];
  if (!_isEmpty(searchText)) {
    return getSearchTextPayload(nextPageToken, searchText, filters);
  }
  return {
    rows: 10,
    nextPageToken,
    filters,
  };
});

const getFieldsFormComplexDetailsValues = (values) => {
  const entityName = _get(values, COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS.ENTITY_NAME);

  if (!_isEmpty(entityName)) {
    return {
      [COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS.ENTITY_NAME]: entityName,
    };
  }

  return {};
};

const getComplexDetailsFormValues = (fieldsFormComplexDetailsValues) => ({
  [COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS.ENTITY_NAME]: _get(fieldsFormComplexDetailsValues, COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS.ENTITY_NAME),
});

export { getPayloadForInitialFetch, getPayload, getFieldsFormComplexDetailsValues, getComplexDetailsFormValues };
