import { TEXT_PROPERTIES_CONFIGURE_FIELD_IDS } from '../constants/textPropertiesConfigure.fieldIds';

const TEXT_PROPERTIES_CONFIGURE_SECTIONS = [
  {
    rows: [
      {
        columns: [TEXT_PROPERTIES_CONFIGURE_FIELD_IDS.TEXT_LABEL],
      },
      {
        columns: [TEXT_PROPERTIES_CONFIGURE_FIELD_IDS.TEXT_SIZE],
      },
    ],
  },
];

export { TEXT_PROPERTIES_CONFIGURE_SECTIONS };
