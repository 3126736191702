import _find from 'lodash/find';
import _remove from 'lodash/remove';

import { tget } from '@tekion/tekion-base/utils/general';
import { isLoopNode } from '@tekion/tekion-rule-engine';
import { TASK_VALUES } from '@tekion/tekion-rule-engine/src/constants/ruleEngineWorkflow.taskTypes';

// Helpers
import { loadFieldDefinitions } from './helperHandlers';

// Constants
import WORKFLOW_BUILDER_ACTION_TYPES from '../../../constants/workflowBuilder.actionTypes';
import WORKFLOW_FIELD_IDS from '../../../constants/workflow.fieldIds';
import TASK_DEF_ID_TO_MODAL_TYPE from '../../../constants/workflowBuilder.taskDefToModalType';
import { TASK_DEF_IDS } from '../../../constants/workflow.constants';
import { MODAL_MODES } from '../../../constants/workflowBuilder.constants';
import { LOOP_NODE_FIELD_IDS } from '../../../constants/nodeDataFieldIds';

// Utils
import generateVariableMapForNode from '../../../utils/generateVariableMapForNode';
import assignTrigger from '../../../utils/assignTrigger';

const handleStepItemClick = async ({ getState, setState, params }) => {
  const {
    workflow,
    conditionBuilderFieldDefinitionObject,
    mapOfVariableToEntityNameForCondition,
    eventTypes,
    stepDrawerData,
    selectedNode,
    variablesByNodeId,
  } = getState();
  const { stepId, onEntityChange } = params;
  const { isAddStep, node, label } = selectedNode;

  const parentNodeId = isAddStep ? node : tget(workflow, `nodeList.${node}.nodeConfig.parent`);
  const stepDetails = _find(stepDrawerData.items, { id: stepId });
  const taskDefId = tget(stepDetails, 'taskDefId', TASK_DEF_IDS.IF);
  const eventType = tget(workflow, 'eventType');
  const entityName = tget(workflow, WORKFLOW_FIELD_IDS.ENTITY, '');
  const variablesInfo = tget(workflow, 'uiMetadata.variablesInfo', {});

  const isParentLoopNode = isLoopNode(tget(workflow, `nodeList.${parentNodeId}`));
  const parentVariables = [...tget(variablesByNodeId, parentNodeId, [])];
  if (isParentLoopNode && label === TASK_VALUES.NO) {
    const loopNodeData = tget(workflow, `nodeList.${parentNodeId}`);
    const loopVariableName = tget(loopNodeData, `userData.data.${LOOP_NODE_FIELD_IDS.LOOP_VARIABLE_NAME}`);
    _remove(parentVariables, (variable) => variable === loopVariableName);
  }

  const variableMap = generateVariableMapForNode(variablesInfo, mapOfVariableToEntityNameForCondition, parentVariables);

  setState({
    isModalVisible: true,
    isStepDrawerVisible: false,
    modalType: TASK_DEF_ID_TO_MODAL_TYPE[taskDefId],
    modalProps: {
      isLoading: true,
      eventType,
      entityName,
      mode: MODAL_MODES.ADD,
      stepDetails,
      conditionBuilderFieldDefinitionObject,
      mapOfVariableToEntityName: variableMap,
      eventTypes,
      onEntityChange,
      repeatWithNoLoop: taskDefId === TASK_DEF_IDS.GOTO,
      variablesInfo,
      variablesByNodeId,
      parentNodeId,
      scopedVariables: parentVariables,
    },
  });

  const { conditionBuilderFieldDefinitionObject: fieldDefsObject, mapOfVariableToEntityName } = await loadFieldDefinitions({
    getState,
    setState,
    params: { variableMap, scopedVariables: parentVariables },
  });

  setState((state) => ({
    ...state,
    modalProps: {
      ...state.modalProps,
      isLoading: false,
      conditionBuilderFieldDefinitionObject: fieldDefsObject,
      mapOfVariableToEntityName,
    },
  }));
};

const handleTriggerItemClick = ({ getState, setState, params }) => {
  const { workflow, triggerDrawerData } = getState();
  const { triggerId } = params;

  setState({
    isTriggerDrawerVisible: false,
    workflow: assignTrigger(workflow, triggerId, triggerDrawerData.items),
  });
};

const handleCloseStepDrawer = ({ setState }) => setState({ isStepDrawerVisible: false });

const handleCloseTriggerDrawer = ({ setState }) => setState({ isTriggerDrawerVisible: false });

const DRAWER_ACTION_HANDLERS = {
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_STEP_ITEM_CLICK]: handleStepItemClick,
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_TRIGGER_ITEM_CLICK]: handleTriggerItemClick,
  [WORKFLOW_BUILDER_ACTION_TYPES.CLOSE_STEP_DRAWER]: handleCloseStepDrawer,
  [WORKFLOW_BUILDER_ACTION_TYPES.CLOSE_TRIGGER_DRAWER]: handleCloseTriggerDrawer,
};

export default DRAWER_ACTION_HANDLERS;
