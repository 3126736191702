import { VIEW_TYPES } from '../../../../../../../constants/viewBuilder.constants';

const VIEW_TYPE_OPTIONS_LABEL = {
  [VIEW_TYPES.LIST_VIEW]: __('List View '),
  [VIEW_TYPES.FORM_VIEW]: __('Form View '),
  [VIEW_TYPES.DETAIL_VIEW]: __('Detail View '),
  [VIEW_TYPES.GRID_VIEW]: __('Grid View '),
};

export { VIEW_TYPE_OPTIONS_LABEL };
