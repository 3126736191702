import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import _forEach from 'lodash/forEach';

import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import { tget } from '@tekion/tekion-base/utils/general';
import { STATUS } from '@tekion/tekion-base/constants/status.constants';
import { IMPORT_BATCH_CARD_DETAIL_IDS, IMPORT_BATCH_COLUMN_IDS, IMPORT_TASK_IDS } from '../constants/dataImportDetailPage.constants';

import styles from '../dataImportDetailPage.module.scss';

const createTableProps = (isLoading, currentPage, pageSize) => ({
  loading: isLoading,
  currentPage: currentPage + 1,
  rowHeight: 35,
  minRows: 0,
  showPagination: true,
  pageSize,
  resultsPerPage: pageSize,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: false,
});

const getSubHeaderProps = (resultCount = 0) => ({
  subHeaderLeftActions: [
    {
      renderer: TextRenderer,
      renderOptions: {
        value: `${__('Batches')} (${resultCount})`,
        contentClassName: styles.subHeaderContent,
      },
    },
  ],
});

const getPayloadForImportTaskBatch = (pageSize, nextPageToken, importTaskId) => {
  let payload = {
    rows: pageSize,
    filters: [
      {
        field: IMPORT_BATCH_COLUMN_IDS.IMPORT_TASK_ID,
        values: [importTaskId],
        filterType: OPERATORS.IN,
      },
    ],
  };

  if (!_isEmpty(nextPageToken)) {
    payload = {
      nextPageToken,
      ...payload,
    };
  }

  return payload;
};

const getImportedFileCardViewData = (batchData, importTaskData, taskImportStatus) => {
  const totalBatches = _size(batchData);
  const batchSize = tget(importTaskData, IMPORT_TASK_IDS.BATCH_SIZE);
  const batchStatusCount = {};

  _forEach(batchData, (batchRowData) => {
    const taskStatus = tget(batchRowData, IMPORT_BATCH_COLUMN_IDS.TASK_STATUS);
    batchStatusCount[taskStatus] = batchStatusCount[taskStatus] ? batchStatusCount[taskStatus] + 1 : 1;
  });

  const completedBatches = tget(batchStatusCount, STATUS.COMPLETED, 0);
  const failedBatches = tget(batchStatusCount, STATUS.FAILED, 0);
  const inProgressBatches = tget(batchStatusCount, STATUS.IN_PROGRESS, 0);
  const pendingBatches = tget(batchStatusCount, STATUS.PENDING, 0);
  const recordsProcessed = completedBatches * batchSize;
  const recordsFailed = failedBatches * batchSize;

  return {
    [IMPORT_BATCH_CARD_DETAIL_IDS.STATUS]:
      taskImportStatus === 100 || totalBatches === 0 || completedBatches + failedBatches === totalBatches ? __('Completed') : __('In Progress'),
    [IMPORT_BATCH_CARD_DETAIL_IDS.COMPLETED_BATCHES]: completedBatches,
    [IMPORT_BATCH_CARD_DETAIL_IDS.FAILED_BATCHES]: failedBatches,
    [IMPORT_BATCH_CARD_DETAIL_IDS.IN_PROGRESS_BATCHES]: inProgressBatches,
    [IMPORT_BATCH_CARD_DETAIL_IDS.QUEUED_BATCHES]: pendingBatches,
    [IMPORT_BATCH_CARD_DETAIL_IDS.RECORDS_FAILED]: recordsFailed,
    [IMPORT_BATCH_CARD_DETAIL_IDS.RECORDS_PROCESSED]: recordsProcessed,
    [IMPORT_BATCH_CARD_DETAIL_IDS.PROGRESS]: `${taskImportStatus}%`,
  };
};

export { createTableProps, getSubHeaderProps, getPayloadForImportTaskBatch, getImportedFileCardViewData };
