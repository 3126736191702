// Tekion-components
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import COLUMN_IDS from '../constants/approvalCategoryList.columnIds';

const COLUMN_CONFIG = [
  {
    Header: __('Category'),
    id: COLUMN_IDS.CATEGORY,
    accessor: COLUMN_IDS.CATEGORY,
    Cell: TextRenderer,
  },
];

export default COLUMN_CONFIG;
