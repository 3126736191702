import React from 'react';
import PropTypes from 'prop-types';

// components
import IconAsBtn from '@tekion/tekion-components/atoms/iconAsBtn';

// hooks
import useMediaDeletion from './hooks/useMediaDeletion';

// styles
import styles from './withMediaDeletion.module.scss';

function withMediaDeletion(WrappedComponent) {
  function WithMediaDeletion(props) {
    const { element, enableMediaDeletion } = props;
    const { handleMediaDeletion } = useMediaDeletion(element);

    return (
      <div className={`${styles.container} full-height full-width`}>
        <WrappedComponent {...props} />
        {enableMediaDeletion && (
          <IconAsBtn onClick={handleMediaDeletion} containerClassName={styles.deleteIcon}>
            icon-trash
          </IconAsBtn>
        )}
      </div>
    );
  }

  WithMediaDeletion.propTypes = {
    element: PropTypes.shape({
      data: PropTypes.shape({
        mediaId: PropTypes.string.isRequired,
      }).isRequired,
      type: PropTypes.string.isRequired,
      children: PropTypes.array.isRequired,
    }).isRequired,
    enableMediaDeletion: PropTypes.bool,
  };

  WithMediaDeletion.defaultProps = {
    enableMediaDeletion: false,
  };

  return WithMediaDeletion;
}

export default withMediaDeletion;
