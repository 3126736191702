import React from 'react';
import PropTypes from 'prop-types';

import _head from 'lodash/head';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getGenericOptions } from '../../../helpers/addWidget.general.helpers';
import { ACTION_TYPES } from '../../../constants/addWidget.general.constant';

const SelectInputRenderer = (props) => {
  const { column, data, nestingPath, onAction, original, fieldId, options, selectedFieldValues } = props;
  const columnId = _get(column, 'id', '');
  const rowIndex = _head(nestingPath);

  const handleAction = ({ type, payload }) => {
    if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
      onAction({
        type: ACTION_TYPES.ON_COLUMN_CONFIG_CHANGE,
        payload: {
          id: fieldId,
          columnId,
          value: _get(payload, 'value'),
          rowIndex,
          original,
          displayName: _get(payload, 'option.label', ''),
        },
      });
    }
  };

  return (
    <SelectInput id={`${columnId}_${rowIndex}`} value={data} onAction={handleAction} options={getGenericOptions(options, selectedFieldValues)} />
  );
};

SelectInputRenderer.propTypes = {
  column: PropTypes.object,
  data: PropTypes.array,
  nestingPath: PropTypes.array.isRequired,
  original: PropTypes.object,
  selectedFieldValues: PropTypes.array,
  fieldId: PropTypes.string.isRequired,
  options: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

SelectInputRenderer.defaultProps = {
  column: EMPTY_OBJECT,
  data: EMPTY_ARRAY,
  original: EMPTY_OBJECT,
  selectedFieldValues: EMPTY_ARRAY,
  options: EMPTY_ARRAY,
};

export default makeCellRenderer(SelectInputRenderer);
