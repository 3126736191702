import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _size from 'lodash/size';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Button from '@tekion/tekion-components/atoms/Button';

import { TREE_ACTION_TYPES } from '../../constants/reporteeList.actionTypes';
import styles from './roleHeader.module.scss';

const RoleHeader = ({ contentWidth, totalRoles, roleHeaderName, roleKey, topMostRoleKey, expandedKeys, onAction }) => {
  // const handleDeleteRole = useCallback(
  //   (event) => {
  //     event?.stopPropagation();
  //     onAction({ type: ACTION_TYPES.ON_DELETE_ROLE, payload: { selectedRoleName: roleHeaderName } });
  //   },
  //   [onAction, roleHeaderName],
  // );

  const handleEditRole = useCallback(
    (event) => {
      event?.stopPropagation();
      onAction({ type: TREE_ACTION_TYPES.ON_EDIT_ROLE, payload: { selectedRoleKey: roleKey } });
    },
    [onAction, roleKey],
  );

  const handleRoleHeaderAddReportee = useCallback(
    (event) => {
      event?.stopPropagation();
      onAction({ type: TREE_ACTION_TYPES.ON_CLICK_ADD_REPORTEE, payload: { selectedRoleKey: roleKey } });
    },
    [onAction, roleKey],
  );

  const handleClickCollapseAll = useCallback(
    (event) => {
      event?.stopPropagation();
      onAction({ type: TREE_ACTION_TYPES.ON_CLICK_COLLAPSE_ALL });
    },
    [onAction],
  );

  const handleClickExpandAll = useCallback(
    (event) => {
      event?.stopPropagation();
      onAction({ type: TREE_ACTION_TYPES.ON_CLICK_EXPAND_ALL });
    },
    [onAction],
  );

  return (
    <div className={styles.roleHeaderContainer} style={{ width: contentWidth - 35 }}>
      <div className={styles.titleRoleConatiner}>{roleHeaderName}</div>
      {roleKey === topMostRoleKey && (
        <>
          <Button view={Button.VIEW.TERTIARY} onClick={handleClickCollapseAll} disabled={_size(expandedKeys) === 0}>
            {__('Collapse All')}
          </Button>
          <Button view={Button.VIEW.TERTIARY} onClick={handleClickExpandAll} disabled={_size(expandedKeys) === totalRoles}>
            {__('Expand All')}
          </Button>
        </>
      )}
      <div className={styles.actionsContainer}>
        <Button view={Button.VIEW.TERTIARY} onClick={handleRoleHeaderAddReportee} className={styles.addReporteeButton}>
          {__('Add Reportee')}
        </Button>
        <FontIcon size={SIZES.MD_S} onClick={handleEditRole} className="cursor-pointer m-r-32">
          icon-edit
        </FontIcon>
        {/* <FontIcon size={SIZES.MD_S} onClick={handleDeleteRole} className="cursor-pointer m-r-32">
          icon-trash
        </FontIcon> */}
      </div>
    </div>
  );
};

RoleHeader.propTypes = {
  contentWidth: PropTypes.number,
  totalRoles: PropTypes.number,
  roleHeaderName: PropTypes.string,
  roleKey: PropTypes.string,
  topMostRoleKey: PropTypes.string,
  expandedKeys: PropTypes.array,
  onAction: PropTypes.func,
};

RoleHeader.defaultProps = {
  contentWidth: 0,
  totalRoles: 0,
  roleHeaderName: EMPTY_STRING,
  roleKey: EMPTY_STRING,
  topMostRoleKey: EMPTY_STRING,
  expandedKeys: EMPTY_ARRAY,
  onAction: _noop,
};

export default React.memo(RoleHeader);
