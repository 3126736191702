import React, { useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';

// Tekion-components
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';

// Tekion-connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Connectors
import withSize from '../../../../../../connectors/withSize';

// Helpers
import { createTableProps } from './helpers/approvalSettingsList.helpers';
import COLUMN_CONFIG from './helpers/approvalSettingsList.columns';
import ACTION_HANDLERS from './helpers/approvalSettingsList.actionHandlers';

// Constants
import { SUB_HEADER_PROPS, TABLE_MANAGER_PROPS } from './constants/approvalSettingsList.constants';

const ApprovalGroupList = ({ isLoading, contentHeight, currentPage, pageSize, searchText, nextPageToken, data, selectedFilters, onAction }) => {
  const tableProps = useMemo(() => createTableProps(isLoading, currentPage, pageSize, onAction), [isLoading, currentPage, pageSize, onAction]);

  return (
    <Page style={{ height: contentHeight }}>
      <TableManager
        tokenPagination
        nextPageToken={nextPageToken}
        searchText={searchText}
        subHeaderProps={SUB_HEADER_PROPS}
        tableManagerProps={TABLE_MANAGER_PROPS}
        tableProps={tableProps}
        columns={COLUMN_CONFIG}
        data={data}
        selectedFilters={selectedFilters}
        onAction={onAction}
      />
    </Page>
  );
};

ApprovalGroupList.propTypes = {
  isLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  searchText: PropTypes.string,
  nextPageToken: PropTypes.string,
  data: PropTypes.array,
  selectedFilters: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ApprovalGroupList.defaultProps = {
  isLoading: false,
  currentPage: DEFAULT_PAGE_INFO.start,
  pageSize: DEFAULT_PAGE_SIZE,
  searchText: EMPTY_STRING,
  nextPageToken: EMPTY_STRING,
  data: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: false }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ApprovalGroupList);
