import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Button from '@tekion/tekion-components/atoms/Button';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Heading from '@tekion/tekion-components/atoms/Heading';
import withSize from '../../../../connectors/withSize';

import NavigationItem from '../../../../molecules/NavigationFlow/components/navigationItem/NavigationItem';
import MetadataComponentForm from '../metadataComponentForm';
import MetadataCardView from '../metadataCardView';

import { getTableProps, getFilterProps } from './helpers/metadataComponentList.helpers';
import ACTION_HANDLERS from './helpers/metadataComponentList.actionHandlers';

import {
  HEADER_PROPS,
  INITIAL_STATE_COMPONENT_LIST_PROPS,
  TABLE_CONSTANTS,
  TABLE_MANAGER_PROPS,
  BUNDLE_NAVIGATION_DEFAULT_DATA,
} from './constants/metadataComponentList.general.constants';
import ACTION_TYPES from './constants/metadataComponentList.actionTypes';
import { COMPONENT_LIST_COLUMNS, getSubHeaderProps } from './constants/metadataComponentList.table.constants';
import { COMPONENT_FORM_CONTEXT_ID } from '../metadataComponentForm/constants/metadataComponentForm.general.constants';

import styles from './metadataComponentList.module.scss';

const MetadataComponentList = ({
  isLoaded,
  loading,
  isComponentAddModalOpen,
  contentHeight,
  pageSize,
  currentPage,
  totalNumberOfEntries,
  searchText,
  selectedFilterGroup,
  nextPageToken,
  componentData,
  bundle,
  history,
  match,
  formErrors,
  selectedFilters,
  components,
  onAction,
}) => {
  const handleModalClose = useCallback(() => {
    onAction({ type: ACTION_TYPES.COMPONENT_CONFIG_MODAL });
  }, [onAction]);

  const renderFooterLeftSection = useCallback(() => <Button onClick={handleModalClose}>{__('Exit')}</Button>, [handleModalClose]);

  const handleSubmitAndModalClose = useCallback(() => {
    triggerSubmit(COMPONENT_FORM_CONTEXT_ID, { payload: { shouldModalRemainOpen: false } });
  }, []);

  const handleSubmitAndModalOpen = useCallback(() => {
    triggerSubmit(COMPONENT_FORM_CONTEXT_ID, { payload: { shouldModalRemainOpen: true } });
  }, []);

  const handleBundlePublish = useCallback(() => {
    onAction({ type: ACTION_TYPES.PUBLISH_BUNDLE, payload: { bundle } });
  }, [onAction, bundle]);

  const handleBundleSaveAsDraft = useCallback(() => {
    onAction({ type: ACTION_TYPES.SAVE_BUNDLE_AS_DRAFT });
  }, [onAction]);

  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({ type: actionType, payload });
    },
    [onAction],
  );

  const headerNavigationData = useMemo(
    () => [...BUNDLE_NAVIGATION_DEFAULT_DATA, { label: __(_get(bundle, 'displayName', NO_DATA)), key: '2' }],
    [bundle],
  );

  const handleNavigationItemClick = useCallback(
    (goTo) => {
      if (!_isEmpty(goTo)) {
        history.push(goTo);
      }
    },
    [history],
  );

  const headerNavigation = useMemo(
    () =>
      _map(headerNavigationData, (data, index) => (
        <NavigationItem
          key={_get(data, 'key', EMPTY_STRING)}
          itemNumber={index}
          totalItems={_size(headerNavigationData)}
          data={data}
          onNavigationItemClick={handleNavigationItemClick}
        />
      )),
    [headerNavigationData, handleNavigationItemClick],
  );

  const state = _get(bundle, 'state', EMPTY_STRING);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.FETCH_BUNDLE_DATA });
  }, [onAction]);

  return (
    <Page>
      <Page.Header>
        <Heading className={styles.headerNavigationContainer} size={2}>
          {headerNavigation}
        </Heading>
      </Page.Header>
      <Page.Body>
        <MetadataCardView history={history} bundle={bundle} />
        <div className={styles.tableContainer} style={{ height: contentHeight - 150 }}>
          <TableManager
            tokenPagination
            isLoaded={isLoaded}
            pageSize={pageSize}
            searchText={searchText}
            nextPageToken={nextPageToken}
            columns={COMPONENT_LIST_COLUMNS}
            tableManagerProps={TABLE_MANAGER_PROPS}
            headerProps={HEADER_PROPS}
            subHeaderProps={getSubHeaderProps(bundle)}
            data={components}
            tableProps={getTableProps(handleRowAction, currentPage, loading, pageSize, totalNumberOfEntries, state)}
            filterProps={getFilterProps(selectedFilters, selectedFilterGroup)}
            onAction={onAction}
          />
        </div>
      </Page.Body>
      <PropertyControlledComponent controllerProperty={state === 'DRAFT'}>
        <Page.Footer>
          <SaveComponent
            primaryButtonLabel={__('Publish')}
            secondaryButtonLabel={__('Save as draft')}
            onPrimaryAction={handleBundlePublish}
            onSecondaryAction={handleBundleSaveAsDraft}
          />
        </Page.Footer>
      </PropertyControlledComponent>
      <PropertyControlledComponent controllerProperty={isComponentAddModalOpen}>
        <Modal
          visible={isComponentAddModalOpen}
          title={__('Add Component')}
          submitBtnText={__('Save and Continue')}
          secondaryBtnText={__('Save and Exit')}
          cancelButtonProps={{ view: 'primary' }}
          renderFooterLeftSection={renderFooterLeftSection}
          onCancel={handleModalClose}
          onSecondarySubmit={handleSubmitAndModalClose}
          onSubmit={handleSubmitAndModalOpen}
        >
          <MetadataComponentForm history={history} match={match} componentData={componentData} errors={formErrors} onAction={onAction} />
        </Modal>
      </PropertyControlledComponent>
    </Page>
  );
};

MetadataComponentList.propTypes = {
  loading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isComponentAddModalOpen: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalNumberOfEntries: PropTypes.number,
  searchText: PropTypes.string,
  selectedFilterGroup: PropTypes.string,
  nextPageToken: PropTypes.string,
  componentData: PropTypes.object,
  bundle: PropTypes.object,
  formErrors: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  components: PropTypes.array,
  selectedFilters: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

MetadataComponentList.defaultProps = {
  loading: false,
  isLoaded: true,
  isComponentAddModalOpen: false,
  pageSize: DEFAULT_PAGE_SIZE,
  currentPage: DEFAULT_PAGE_INFO.start,
  totalNumberOfEntries: TABLE_CONSTANTS.TOTAL_NUMBER_OF_ENTRIES,
  searchText: EMPTY_STRING,
  selectedFilterGroup: EMPTY_STRING,
  nextPageToken: undefined,
  componentData: EMPTY_OBJECT,
  bundle: EMPTY_OBJECT,
  formErrors: EMPTY_OBJECT,
  components: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
};

export default compose(
  withSize({ hasPageFooter: 1, hasPageHeader: 1 }),
  withActions(INITIAL_STATE_COMPONENT_LIST_PROPS, ACTION_HANDLERS),
  React.memo,
)(MetadataComponentList);
