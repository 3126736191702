import produce from 'immer';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _snakeCase from 'lodash/snakeCase';
import _set from 'lodash/set';
import _size from 'lodash/size';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';

import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import {
  createSubscriptionConfig,
  getSubscriptionConfigByName,
  updateSubscriptionConfigByName,
  verifySubscriptionConfigUrl,
} from '../../../../../../actions/subscriptionConfig.actions';
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../../actions/conditionBuilder.actions';

import { getPayloadForSubscriptionConfigUrlVerify } from './subscriptionConfigForm.helpers';

import FIELD_IDS from '../constants/subscriptionConfigForm.fieldIds';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import ACTION_TYPES from '../constants/subscriptionConfigForm.actionTypes';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import { SYSTEM_DEFINED_VARIABLES } from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';

const handleLoadConditionFields = async ({ setState, params }) => {
  const { entityName } = params;

  setState({ isEntityFieldsLoading: true });

  const mapOfVariableToEntityName = { [SYSTEM_DEFINED_VARIABLES.RECORD]: entityName };
  const conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityName);

  setState({
    isEntityFieldsLoading: false,
    conditionBuilderFieldDefinitionObject,
    mapOfVariableToEntityName,
  });
};

const handleFormInit = async ({ getState, setState }) => {
  const { match, history } = getState();
  const { subscriptionConfigName } = _get(match, 'params', EMPTY_OBJECT);

  setState({ formMode: FORM_MODES.EDIT, isFetchingSubscriptionConfig: true });

  if (!_isEmpty(subscriptionConfigName)) {
    let subscriptionConfig = {};
    if (!_isEmpty(tget(history, 'location.state.subscriptionConfig'))) {
      subscriptionConfig = tget(history, 'location.state.subscriptionConfig');
    } else {
      subscriptionConfig = await getSubscriptionConfigByName(subscriptionConfigName);
    }
    const isSubscriptionConfigVerified = tget(subscriptionConfig, FIELD_IDS.VERIFIED, false);
    const isSubscriptionConfigInActive = tget(subscriptionConfig, FIELD_IDS.STATUS, false);
    setState({ isFetchingSubscriptionConfig: false, formValues: subscriptionConfig, isSubscriptionConfigVerified, isSubscriptionConfigInActive });
  } else {
    setState({
      isFetchingSubscriptionConfig: false,
      formMode: FORM_MODES.CREATE,
      isSubscriptionConfigVerified: false,
      isSubscriptionConfigInActive: false,
    });
  }
};

const handleFieldChange = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { id, value } = params;
  const { formMode, disableAutoFillForName } = getState();

  if (id === FIELD_IDS.PATH || id === FIELD_IDS.SERVER_CREDENTIAL) {
    setState({ isSubscriptionConfigVerified: false });
  }

  setState(
    produce((draft) => {
      _set(draft, `formValues.${id}`, value);

      if (formMode === FORM_MODES.CREATE && id === FIELD_IDS.SUBSCRIPTION_CONFIG_DISPLAY_NAME && !disableAutoFillForName) {
        _set(draft, `formValues.${FIELD_IDS.SUBSCRIPTION_CONFIG_NAME}`, _snakeCase(value));
      } else if (id === FIELD_IDS.SUBSCRIPTION_CONFIG_NAME) {
        setState({ disableAutoFillForName: true });
      }
    }),
  );
};

const handleErrors = ({ params = EMPTY_OBJECT, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const handleSubmit = async ({ getState, setState }) => {
  const { formValues, isSubscriptionConfigVerified, formMode, match, history } = getState();

  setState({ isSubmitting: true });
  if (!isSubscriptionConfigVerified) {
    const payloadForSubscriptionConfigUrlVerify = getPayloadForSubscriptionConfigUrlVerify(formValues);
    const subscriptionConfigVerifyingResponse = await verifySubscriptionConfigUrl(payloadForSubscriptionConfigUrlVerify);

    setState({ isSubscriptionConfigVerified: subscriptionConfigVerifyingResponse, isSubmitting: false });
  } else {
    let response = {};
    if (formMode === FORM_MODES.CREATE) {
      response = await createSubscriptionConfig(formValues);
    } else {
      const { subscriptionConfigName } = _get(match, 'params', EMPTY_OBJECT);
      response = await updateSubscriptionConfigByName(subscriptionConfigName, formValues);
    }

    if (!_isEmpty(response)) {
      setTimeout(() => {
        history.push(`${STUDIO_ROUTE}/${PAGE_IDS.SUBSCRIPTION_CONFIGS}`);
      }, ES_REFETCH_DELAY);
    } else {
      setState({ isSubmitting: false });
    }
  }
};

const handleCancel = ({ getState }) => {
  const { history } = getState();
  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.SUBSCRIPTION_CONFIGS}`);
};

const handleRedirectToLogs = ({ getState }) => {
  const { history, match } = getState();
  const { subscriptionConfigName } = _get(match, 'params', EMPTY_OBJECT);
  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.SUBSCRIPTION_CONFIG_LOGS}/${subscriptionConfigName}`);
};

const handleCancelModalSubscriptionForm = ({ setState }) => {
  setState({ isSubscriptionModalVisible: false, selectedSubscriptionIndex: -1, selectedSubscriptionValues: {} });
};

const handleSubmitSubscriptionModal = ({ getState, params, setState }) => {
  const { formValues = {} } = getState();
  const subscriptionList = tget(formValues, FIELD_IDS.SUBSCRIPTIONS, []);
  const { subscriptionData, subscriptionIndex } = params;
  let updatedSelectedSubscriptionIndex = subscriptionIndex;

  if (updatedSelectedSubscriptionIndex === -1) {
    updatedSelectedSubscriptionIndex = _size(subscriptionList);
  }
  const updatedSubscriptionList = [...subscriptionList];
  _set(updatedSubscriptionList, updatedSelectedSubscriptionIndex, { ...subscriptionData });
  const updatedFormValues = { ...formValues, [FIELD_IDS.SUBSCRIPTIONS]: updatedSubscriptionList };

  setState({ formValues: updatedFormValues, isSubscriptionModalVisible: false, selectedSubscriptionIndex: -1, selectedSubscriptionValues: {} });
};

const handleClickAddNewSubscription = ({ setState }) => {
  setState({ isSubscriptionModalVisible: true });
};

const handleEditSubscription = ({ setState, getState, params }) => {
  const { formValues } = getState();
  const { index } = params;
  const subscriptionList = tget(formValues, FIELD_IDS.SUBSCRIPTIONS, []);
  setState({ isSubscriptionModalVisible: true, selectedSubscriptionValues: tget(subscriptionList, index), selectedSubscriptionIndex: index });
};

const handleRemoveSubscription = ({ setState, getState, params }) => {
  const { formValues } = getState();
  const { index } = params;
  const subscriptionList = [...tget(formValues, FIELD_IDS.SUBSCRIPTIONS, [])];
  subscriptionList.splice(index, 1);
  const updatedFormValues = { ...formValues, [FIELD_IDS.SUBSCRIPTIONS]: subscriptionList };
  setState({ formValues: updatedFormValues });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.LOAD_CONDITION_FIELDS]: handleLoadConditionFields,
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleFormInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL]: handleCancel,
  [ACTION_TYPES.ON_REDIRECT_TO_LOGS]: handleRedirectToLogs,
  [ACTION_TYPES.ON_CANCEL_MODAL_SUBSCRIPTION_FORM]: handleCancelModalSubscriptionForm,
  [ACTION_TYPES.ON_SUBMIT_SUBSCRIPTION_MODAL]: handleSubmitSubscriptionModal,
  [ACTION_TYPES.ON_CLICK_ADD_NEW_SUBSCRIPTION]: handleClickAddNewSubscription,
  [ACTION_TYPES.ON_EDIT_SUBSCRIPTION]: handleEditSubscription,
  [ACTION_TYPES.ON_REMOVE_SUBSCRIPTION]: handleRemoveSubscription,
};

export default ACTION_HANDLERS;
