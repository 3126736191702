import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { defaultMemoize } from 'reselect';

import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import WithKeyPressInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput/withKeyPressInput';
import Button from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/button';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import SSOProvidersRenderer from './components/ssoProvidersRenderer';
import BackToLogin from './components/backToLogin';
import MFARenderer from './components/mfaRenderer';
import OTPInputRenderer from './components/otpInputRenderer';
import HCaptchaRenderer from '../atoms/hCaptchaRenderer/hCaptchaRenderer';

import { getAuthTypeLabel } from './LoginPage.helpers';
import { FIELD_NAMES, LOGIN_ACTION_TYPES } from './LoginPage.constants';

import styles from '../AuthenticationForm/authentication.module.scss';
import loginStyles from './loginPage.module.scss';

const checkIfShouldShowUserName = ({ showNormalForm, showMfaField, hasTekionProvider }) => {
  if (!showNormalForm) return true;
  if (!hasTekionProvider) return false;
  if (!showMfaField) return true;
  return false;
};

const checkIfShouldShowPassword = ({ showMfaField, showNormalForm, hasTekionProvider }) => showNormalForm && hasTekionProvider && !showMfaField;

const checkIfShouldShowAuthenticator = ({ showMfaField, showNormalForm }) => showNormalForm && showMfaField;

const checkIfShouldShowForgotPasswordButton = ({ showNormalForm, hasTekionProvider, showMfaField }) =>
  showNormalForm && hasTekionProvider && !showMfaField;

const rowFilter = {
  [FIELD_NAMES.USERNAME]: checkIfShouldShowUserName,
  [FIELD_NAMES.PASSWORD]: checkIfShouldShowPassword,
  [FIELD_NAMES.FORGOT_PASSWORD_BUTTON]: checkIfShouldShowForgotPasswordButton,
  [FIELD_NAMES.SSO_PROVIDERS]: ({ showNormalForm, showMfaField }) => showNormalForm && !showMfaField,
  [FIELD_NAMES.BACK_TO_LOGIN]: ({ showNormalForm }) => showNormalForm,
  [FIELD_NAMES.LOGIN_BUTTON]: ({ showNormalForm, hasTekionProvider }) => {
    if (!showNormalForm) return true;
    if (!hasTekionProvider) return false;
    return true;
  },
  [FIELD_NAMES.OTP]: ({ showNormalForm, showMfaField }) => showNormalForm && showMfaField,
  [FIELD_NAMES.AUTHENTICATOR]: checkIfShouldShowAuthenticator,
};

const getFormSection = defaultMemoize(({ showMfaField, showNormalForm, showMultiAuthProvider, hasTekionProvider }) => {
  const formSections = [
    {
      className: styles.sectionFieldsContainer,
      sectionWrapperClassName: styles.sectionWrapperContainer,
      rows: [
        {
          columns: filterRows(
            [FIELD_NAMES.USERNAME],
            {
              showNormalForm,
              hasTekionProvider,
              showMfaField,
            },
            rowFilter,
          ),
        },
        {
          className: styles.concernInputContainer,
          columns: filterRows(
            [FIELD_NAMES.PASSWORD],
            {
              showNormalForm,
              showMultiAuthProvider,
              hasTekionProvider,
              showMfaField,
            },
            rowFilter,
          ),
        },
        {
          columns: filterRows(
            [FIELD_NAMES.FORGOT_PASSWORD_BUTTON],
            {
              showNormalForm,
              showMultiAuthProvider,
              hasTekionProvider,
              showMfaField,
            },
            rowFilter,
          ),
        },
        {
          columns: filterRows([FIELD_NAMES.AUTHENTICATOR], { showMfaField, showNormalForm, showMultiAuthProvider }, rowFilter),
        },
        {
          columns: filterRows([FIELD_NAMES.OTP], { showMfaField, showNormalForm, showMultiAuthProvider }, rowFilter),
        },
        {
          className: styles.loginBtnSectionContainer,
          columns: filterRows(
            [FIELD_NAMES.LOGIN_BUTTON],
            {
              showNormalForm,
              showMultiAuthProvider,
              hasTekionProvider,
              showMfaField,
            },
            rowFilter,
          ),
        },
        ...(hasTekionProvider
          ? [
              {
                columns: filterRows([FIELD_NAMES.BACK_TO_LOGIN], { showNormalForm }, rowFilter),
              },
            ]
          : []),
        {
          columns: filterRows([FIELD_NAMES.SSO_PROVIDERS], { showNormalForm, showMultiAuthProvider, showMfaField }, rowFilter),
        },
        ...(!hasTekionProvider
          ? [
              {
                columns: filterRows([FIELD_NAMES.BACK_TO_LOGIN], { showNormalForm }, rowFilter),
              },
            ]
          : []),
      ],
    },
  ];

  return formSections;
});

const getFormFields = defaultMemoize(
  ({ isBtnLoading, showNormalForm, providerTypeMap, authenticatorType, showOTPField, expiryTime, showResend, mfaAuthTypes }) => ({
    [FIELD_NAMES.USERNAME]: {
      renderer: showNormalForm ? TextInput : WithKeyPressInput,
      renderOptions: {
        label: __('Username'),
        required: true,
        validators: [isRequiredRule],
        disabled: showNormalForm,
        className: styles.textInputPadding,
        placeholder: __('Email'),
      },
    },

    [FIELD_NAMES.PASSWORD]: {
      renderer: WithKeyPressInput,
      renderOptions: {
        type: 'password',
        required: true,
        label: __('Password'),
        validators: [isRequiredRule],
        className: styles.textInputPadding,
      },
    },

    [FIELD_NAMES.FORGOT_PASSWORD_BUTTON]: {
      renderer: Button,
      renderOptions: {
        className: loginStyles.forgotButton,
        fieldClassName: loginStyles.forgotButtonField,
        view: Button.VIEW.TERTIARY,
        label: __('Forgot Password ?'),
      },
    },

    [FIELD_NAMES.LOGIN_BUTTON]: {
      renderer: HCaptchaRenderer,
      renderOptions: {
        view: Button.VIEW.PRIMARY,
        label: showNormalForm ? __('Login') : __('Next'),
        loading: isBtnLoading,
        actionType: LOGIN_ACTION_TYPES.GET_HCAPTCHA_TOKEN,
      },
    },

    [FIELD_NAMES.SSO_PROVIDERS]: {
      renderer: SSOProvidersRenderer,
      renderOptions: {
        providerTypeMap,
      },
    },

    [FIELD_NAMES.BACK_TO_LOGIN]: {
      renderer: BackToLogin,
      renderOptions: {
        label: __('Back to login'),
        actionType: LOGIN_ACTION_TYPES.RESET_LOGIN,
      },
    },

    [FIELD_NAMES.AUTHENTICATOR]: {
      renderer: MFARenderer,
      renderOptions: {
        label: getAuthTypeLabel(authenticatorType),
        authenticatorType,
        mfaAuthTypes,
      },
    },

    [FIELD_NAMES.OTP]: {
      renderer: OTPInputRenderer,
      renderOptions: {
        label: showOTPField ? __('Enter next code') : __('Enter code'),
        required: true,
        validators: [isRequiredRule],
        expiryTime,
        showResend,
        className: styles.textInputPadding,
      },
    },
  }),
);

export { getFormFields, getFormSection };
