import _isEmpty from 'lodash/isEmpty';
import _head from 'lodash/head';
import _castArray from 'lodash/castArray';

// Utils
import { tget } from '@tekion/tekion-base/utils/general';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

// Components
import ConditionBuilder from '../../../../../../organisms/conditionBuilder';

// Helpers
import { convertFieldsToOptions, getViewTypeOptions, convertViewsToOptions } from './actionBuilderForm.fieldHelpers';
import { isConditionRequiredRule } from '../../../../../../organisms/conditionBuilder/helpers/conditionBuilder.general.helpers';

// Constants
import { FORM_MODES } from '../../../../../../constants/general.constants';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../constants/actionBuilder.constants';
import CONDITION_BUILDER_MODES from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.modes';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import {
  ACTION_BUILDER_ACTION_TYPES,
  ACTION_DEFINITION_CONDITION_VARIABLE_NAME,
  ACTION_DEFINITION_OPERATOR_OPTIONS,
  FILTER_CONDITION_VARIABLE_NAME,
} from '../constants/actionBuilderForm.general';
import ACTION_BUILDER_FIELDS from './actionBuilderForm.constantFields';
import { CONDITION_BUILDER_TYPES } from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';

const getFields = (
  formMode,
  values,
  targetEntityFields,
  validationRules,
  targetEntityViews,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityNameForEntryCondition,
  mapOfVariableToEntityNameForActionDefinition,
  mapOfVariableToEntityNameForFilterCondition,
  emailTemplateDefStore,
  sourceEntityName,
) => {
  const actionType = tget(values, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE, []);
  const outerUserInput = tget(values, ACTION_DEFINITION_FIELD_IDS.USER_INPUT, false);

  return {
    ...ACTION_BUILDER_FIELDS,
    [ACTION_DEFINITION_FIELD_IDS.ACTION_NAME]: addToRenderOptions(ACTION_BUILDER_FIELDS[ACTION_DEFINITION_FIELD_IDS.ACTION_NAME], [
      { path: 'disabled', value: formMode !== FORM_MODES.CREATE },
      { path: 'required', value: formMode === FORM_MODES.CREATE },
    ]),
    [ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE]: addToRenderOptions(ACTION_BUILDER_FIELDS[ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE], [
      { path: 'isDisabled', value: formMode !== FORM_MODES.CREATE },
      { path: 'required', value: formMode === FORM_MODES.CREATE },
    ]),
    [ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME]: addToRenderOptions(ACTION_BUILDER_FIELDS[ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME], [
      { path: 'isDisabled', value: _isEmpty(actionType) },
      { path: 'formMode', value: formMode },
    ]),
    [ACTION_DEFINITION_FIELD_IDS.VALIDATION_RULE_OVERRIDE]: addToRenderOptions(
      ACTION_BUILDER_FIELDS[ACTION_DEFINITION_FIELD_IDS.VALIDATION_RULE_OVERRIDE],
      [
        { path: 'validationRules', value: validationRules },
        { path: 'formViewOptions', value: convertViewsToOptions(targetEntityViews) },
        { path: 'outerUserInput', value: outerUserInput },
        { path: 'targetEntityFieldsByName', value: targetEntityFields },
        { path: 'actionType', value: _head(actionType) },
      ],
    ),
    [ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS]: addToRenderOptions(ACTION_BUILDER_FIELDS[ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS], [
      { path: 'fieldOptions', value: convertFieldsToOptions(targetEntityFields, _head(actionType), true, true) },
    ]),
    [ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME]: addToRenderOptions(ACTION_BUILDER_FIELDS[ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME], [
      { path: 'options', value: getViewTypeOptions(values, targetEntityViews) },
    ]),
    [ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS]: addToRenderOptions(ACTION_BUILDER_FIELDS[ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS], [
      { path: 'options', value: getViewTypeOptions(values, targetEntityFields, _head(actionType)) },
    ]),
    [ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION]: addToRenderOptions(
      ACTION_BUILDER_FIELDS[ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION],
      [
        { path: 'conditionBuilderFieldDefinitionObject', value: conditionBuilderFieldDefinitionObject },
        { path: 'sourceEntityName', value: sourceEntityName },
        { path: 'templateDefinitions', value: emailTemplateDefStore },
        { path: 'actionType', value: _head(actionType) },
      ],
    ),
    [ACTION_DEFINITION_FIELD_IDS.ENTRY_CONDITION]: {
      id: ACTION_DEFINITION_FIELD_IDS.ENTRY_CONDITION,
      renderer: ConditionBuilder,
      renderOptions: {
        containerClassname: fieldLayoutStyles.fieldC,
        mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
        conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
        label: '',
        validators: [isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)],
        required: true,
        childProps: {
          [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
            label: '',
            childProps: {
              [CONDITION_FIELD_IDS.FIELD]: {
                label: __('Field'),
                conditionBuilderFieldDefinitionObject,
                mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryCondition,
              },
              [CONDITION_FIELD_IDS.OPERATOR]: {
                label: __('Operator'),
                conditionBuilderFieldDefinitionObject,
                mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryCondition,
              },
              [CONDITION_FIELD_IDS.VALUES]: {
                label: __('Value'),
                conditionBuilderFieldDefinitionObject,
                mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryCondition,
              },
            },
          },
        },
      },
    },
    [ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION]: {
      id: ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION,
      renderer: ConditionBuilder,
      renderOptions: {
        containerClassname: fieldLayoutStyles.fieldC,
        mode: CONDITION_BUILDER_MODES.ACTION_MODE,
        conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
        validators: [isConditionRequiredRule(CONDITION_BUILDER_MODES.ACTION_MODE)],
        required: true,
        childProps: {
          [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
            label: '',
            childProps: {
              [CONDITION_FIELD_IDS.FIELD]: {
                label: __('Field'),
                mapOfVariableToEntityName: mapOfVariableToEntityNameForActionDefinition,
                conditionBuilderFieldDefinitionObject,
                isVariablePrefixNeeded: false,
                variableNameForPrefixNotNeeded: ACTION_DEFINITION_CONDITION_VARIABLE_NAME,
                isCreatableFieldOnly: getArraySafeValue(actionType) === ACTION_BUILDER_ACTION_TYPES.CREATE_RECORD,
                isEditableFieldOnly:
                  getArraySafeValue(actionType) === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD ||
                  getArraySafeValue(actionType) === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORDS,
              },
              [CONDITION_FIELD_IDS.OPERATOR]: {
                label: __('Operator'),
                mapOfVariableToEntityName: mapOfVariableToEntityNameForActionDefinition,
                conditionBuilderFieldDefinitionObject,
                isVariablePrefixNeeded: false,
                variableNameForPrefixNotNeeded: ACTION_DEFINITION_CONDITION_VARIABLE_NAME,
                isCustomOperatorOptions: true,
                customOperatorOptions: ACTION_DEFINITION_OPERATOR_OPTIONS,
              },
              [CONDITION_FIELD_IDS.VALUES]: {
                showFieldSwitcher: true,
                label: __('Value'),
                conditionBuilderMode: CONDITION_BUILDER_MODES.ACTION_MODE,
                mapOfVariableToEntityName: { ...mapOfVariableToEntityNameForEntryCondition, ...mapOfVariableToEntityNameForActionDefinition },
                conditionBuilderFieldDefinitionObject,
                isResourceFieldExcluded: false,
                isVariablePrefixNeeded: false,
                variableNameForPrefixNotNeeded: ACTION_DEFINITION_CONDITION_VARIABLE_NAME,
                attributeExcludeVariableNames: _castArray(ACTION_DEFINITION_CONDITION_VARIABLE_NAME),
              },
            },
          },
        },
      },
    },
    [ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS]: {
      id: ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS,
      renderer: ConditionBuilder,
      renderOptions: {
        containerClassname: fieldLayoutStyles.fieldC,
        header: __('Filter conditions on target record'),
        mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
        conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
        validators: [isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)],
        required: true,
        childProps: {
          [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
            label: '',
            childProps: {
              [CONDITION_FIELD_IDS.FIELD]: {
                label: __('Field'),
                isVariablePrefixNeeded: false,
                variableNameForPrefixNotNeeded: FILTER_CONDITION_VARIABLE_NAME,
                mapOfVariableToEntityName: mapOfVariableToEntityNameForFilterCondition,
                conditionBuilderFieldDefinitionObject,
              },
              [CONDITION_FIELD_IDS.OPERATOR]: {
                label: __('Operator'),
                mapOfVariableToEntityName: mapOfVariableToEntityNameForFilterCondition,
                conditionBuilderFieldDefinitionObject,
                isVariablePrefixNeeded: false,
                variableNameForPrefixNotNeeded: FILTER_CONDITION_VARIABLE_NAME,
              },
              [CONDITION_FIELD_IDS.VALUES]: {
                showFieldSwitcher: true,
                label: __('Value'),
                mapOfVariableToEntityName: { ...mapOfVariableToEntityNameForEntryCondition, ...mapOfVariableToEntityNameForFilterCondition },
                conditionBuilderFieldDefinitionObject,
                isResourceFieldExcluded: false,
                isVariablePrefixNeeded: false,
                variableNameForPrefixNotNeeded: FILTER_CONDITION_VARIABLE_NAME,
                attributeExcludeVariableNames: _castArray(FILTER_CONDITION_VARIABLE_NAME),
              },
            },
          },
        },
      },
    },
  };
};

export default getFields;
