import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import { NO_DATA } from '@tekion/tekion-base/app.constants';

import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import FIELD_IDS from '../constants/subscriptionConfigForm.fieldIds';

const getPayloadForSubscriptionConfigUrlVerify = (values) => {
  const serverCredentialName = tget(values, FIELD_IDS.SERVER_CREDENTIAL);
  const path = tget(values, FIELD_IDS.PATH);
  return { serverCredentialName, path };
};

const ROWS = 20;

const getServerCredentialPayload = ({ nextPageToken, searchText }) => {
  const filters = [];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: 'name',
      values: [searchText],
      filterType: OPERATORS.TEXT_STARTS_WITH,
    });
  }

  return {
    rows: ROWS,
    nextPageToken: _isEmpty(nextPageToken) ? null : nextPageToken,
    filters,
  };
};

const getValueOptionForServerCredential = (serverCredential) => ({
  label: __(`${tget(serverCredential, 'displayName', NO_DATA)}`),
  value: tget(serverCredential, 'name'),
});

const getServerCredentialOptions = (data) => _map(data, getValueOptionForServerCredential);

export { getPayloadForSubscriptionConfigUrlVerify, getServerCredentialOptions, getServerCredentialPayload };
