import Pusher from 'pusher-js';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import TEnvReader from '../../../readers/env.reader';
import localStorageReader from '../../../readers/localStorage.reader';

import { getDefaultHeaders } from '../../../services/apiService/apiService.helper';

const getPusherConfig = (config = EMPTY_OBJECT) => ({
  cluster: config.region,
  apiKey: config.accessKey,
  tenantId: tget(config, 'tenantId'),
  workspaceId: tget(config, 'workspaceId'),
  userId: tget(config, 'userId'),
  clientId: 'web',
  authEndPoint: config.authEndPoint,
  'tekion-api-token': localStorageReader.apiToken(),
});

const createPusherInstance = (pusherConfig) => {
  const userInfo = localStorageReader.userInfo();
  const authEndPoint = `${TEnvReader.service}/api/core/u/pusher/channels/auth`;
  const config = getPusherConfig({ ...pusherConfig, ...userInfo, authEndPoint });

  return new Promise((resolve, reject) => {
    const pusherInstance = new Pusher(config.apiKey, {
      cluster: config.cluster,
      authEndpoint: config.authEndPoint,
      auth: {
        headers: getDefaultHeaders(config),
      },
      forceTLS: true,
    });

    pusherInstance.connection.bind('connected', () => {
      resolve(pusherInstance);
    });

    pusherInstance.connection.bind('error', (error) => {
      reject(__('Could not initialize pusher, please try again later by refreshing the page.'), error);
    });
  });
};

export default createPusherInstance;
