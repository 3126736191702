import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _isArray from 'lodash/isArray';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import Button from '@tekion/tekion-components/atoms/Button';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import CONDITION_LIST_ACTION_TYPES from '../../constants/conditionList.actionTypes';
import CONDITION_BUILDER_ACTION_TYPES from '../../constants/conditionBuilder.actionTypes';
import CONDITION_FIELD_IDS from '../../constants/condition.fieldIds';
import CONDITION_BUILDER_MODES from '../../constants/conditionBuilder.modes';

// Components
import Condition from '../../molecules/condition';

const ConditionList = ({ required, id, showIndex, mode, value, error, childProps, onAction }) => {
  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { index, condition } = payload;
          const updatedValue = [...value];
          updatedValue[index] = condition;
          onAction({
            type,
            payload: {
              id,
              value: updatedValue,
            },
          });
          break;
        }
        case CONDITION_LIST_ACTION_TYPES.DELETE_ROW: {
          onAction({
            type: CONDITION_BUILDER_ACTION_TYPES.DELETE_ROW,
            payload: {
              ...payload,
            },
          });
          break;
        }
        default:
          onAction(action);
      }
    },
    [id, value, onAction],
  );

  const onAddClick = useCallback(() => {
    onAction({
      type: CONDITION_BUILDER_ACTION_TYPES.ADD_ROW,
      payload: {
        id,
        value: _isArray(value) ? [...value, EMPTY_OBJECT] : [EMPTY_OBJECT],
      },
    });
  }, [id, value, onAction]);

  return (
    <div>
      {_map(value, (condition, index) => {
        const key = tget(condition, CONDITION_FIELD_IDS.FIELD, index);
        return (
          <Condition
            required={required}
            key={key}
            index={index}
            showIndex={showIndex}
            condition={condition}
            errors={error?.[index]}
            childProps={childProps}
            onAction={handleAction}
          />
        );
      })}
      {mode !== CONDITION_BUILDER_MODES.SINGLE_CONDITION_MODE && (
        <Button view={Button.VIEW.TERTIARY} onClick={onAddClick}>
          {__('+Add')}
        </Button>
      )}
    </div>
  );
};

ConditionList.propTypes = {
  required: PropTypes.bool,
  showIndex: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  childProps: PropTypes.object,
  value: PropTypes.array,
  error: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ConditionList.defaultProps = {
  required: false,
  childProps: EMPTY_OBJECT,
  value: EMPTY_ARRAY,
  error: EMPTY_ARRAY,
};

export default ConditionList;
