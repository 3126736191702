import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

const userGroupReader = {
  id: (user = EMPTY_OBJECT) => tget(user, 'id'),
  name: (user = EMPTY_OBJECT) => tget(user, 'name', EMPTY_STRING),
  description: (user = EMPTY_OBJECT) => tget(user, 'description', EMPTY_STRING),
  displayName: (user = EMPTY_OBJECT) => tget(user, 'displayName', EMPTY_STRING),
};

export default userGroupReader;
