import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _reduce from 'lodash/reduce';

import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import { EMPTY_STRING, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { TEXT_STARTS_WITH } from '../../../../../constants/filter.constants';
import { ROW_ACTIONS } from '../constants/jobScheduleList.columns';
import { JOB_STATUS_FILTER, DEFAULT_FILTERS_TYPES } from '../constants/jobScheduleList.general';
import COLUMN_IDS from '../constants/jobScheduleList.columnIds';

const createTableProps = (isLoading, currentPage, pageSize, onAction) => ({
  loading: isLoading,
  currentPage: currentPage + 1,
  rowHeight: 35,
  minRows: 0,
  showPagination: true,
  pageSize,
  resultsPerPage: pageSize,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  onRowActionClick: (type, payload) => onAction({ type, payload }),
  showActions: true,
  getRowActions: () => ROW_ACTIONS,
});

const createFilterProps = (onAction) => ({
  filterTypes: [JOB_STATUS_FILTER],
  onAction,
  defaultFilterTypes: DEFAULT_FILTERS_TYPES,
  showResultsCount: false,
});

const createFilterArrayForApiPayload = (selectedFilters) => {
  const filters = _reduce(
    selectedFilters,
    (ans, filter) => {
      if (!_isEmpty(filter, 'values')) {
        ans.push({
          field: _get(filter, 'type', EMPTY_STRING),
          values: _get(filter, 'values', EMPTY_ARRAY),
          filterType: _get(filter, 'operator', EMPTY_STRING),
        });
      }
      return ans;
    },
    [],
  );

  return filters;
};

const getPayload = ({ currentPage, currentPageToken, pageSize, selectedFilters, searchText }) => {
  const payload = { rows: pageSize, start: currentPage * pageSize, nextPageToken: currentPageToken };
  let filters = [];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: COLUMN_IDS.JOB_SCHEDULE_NAME,
      values: [searchText],
      filterType: TEXT_STARTS_WITH,
    });
  }

  if (!_isEmpty(selectedFilters)) {
    filters = [...filters, ...createFilterArrayForApiPayload(selectedFilters)];
  }

  if (!_isEmpty(filters)) {
    _set(payload, 'filters', filters);
  }
  return payload;
};

export { createTableProps, createFilterProps, getPayload };
