import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { ADD_WIDGET_FORM_FIELDS } from '../constants/addWidget.general.constant';
import { WIDGET_NAME_FIELD, DISPLAY_NAME_FIELD, ENTITY_NAME_FIELD } from '../constants/addWidget.fields';
import { CHART_TYPE_FIELD, X_AXIS_FIELD, Y_AXIS_FIELD } from '../constants/addChartWidget.fields';
import { FORM_MODES } from '../../../../../../../constants/general.constants';

import styles from '../addWidget.module.scss';

const getFormSections = (entityName) => {
  let columnSection = [
    {
      className: styles.sectionContainer,
      header: { label: __('Widget Details') },
      rows: [
        { columns: [ADD_WIDGET_FORM_FIELDS.WIDGET_TYPE] },
        {
          columns: [ADD_WIDGET_FORM_FIELDS.DISPLAY_NAME, ADD_WIDGET_FORM_FIELDS.WIDGET_NAME],
        },
        {
          columns: [ADD_WIDGET_FORM_FIELDS.ENTITY_NAME],
        },
      ],
    },
  ];
  if (!_isEmpty(entityName)) {
    const axisContent = [
      {
        className: styles.sectionContainer,
        header: { label: __('Choose Axis') },
        rows: [
          {
            columns: [ADD_WIDGET_FORM_FIELDS.X_AXIS, ADD_WIDGET_FORM_FIELDS.Y_AXIS],
          },
        ],
      },
    ];
    columnSection = [...columnSection, ...axisContent];
  }
  return columnSection;
};

const getFormFields = ({ possibleXAxis, possibleYAxis, formValues, widgetName }) => ({
  [ADD_WIDGET_FORM_FIELDS.WIDGET_TYPE]: CHART_TYPE_FIELD,
  [ADD_WIDGET_FORM_FIELDS.WIDGET_NAME]: WIDGET_NAME_FIELD,
  [ADD_WIDGET_FORM_FIELDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [ADD_WIDGET_FORM_FIELDS.ENTITY_NAME]: addToRenderOptions(ENTITY_NAME_FIELD, {
    path: 'formMode',
    value: _isEmpty(widgetName) ? FORM_MODES.CREATE : FORM_MODES.EDIT,
  }),
  [ADD_WIDGET_FORM_FIELDS.X_AXIS]: addToRenderOptions(X_AXIS_FIELD, {
    path: 'options',
    value: possibleXAxis,
  }),
  [ADD_WIDGET_FORM_FIELDS.Y_AXIS]: addToRenderOptions(Y_AXIS_FIELD, [
    {
      path: 'options',
      value: possibleYAxis,
    },

    { path: 'isDisabled', value: _isEmpty(_get(formValues, ADD_WIDGET_FORM_FIELDS.X_AXIS)) },
  ]),
});

export { getFormFields, getFormSections };
