import _property from 'lodash/property';

const entityName = _property('entityName');
const recordId = _property('recordId');
const userId = _property('userId');
const recordAccessType = _property('recordAccessType');
const expiryTime = _property('expiryInLong');

const SHARE_AND_MANAGE_RECORD_READER = {
  entityName,
  recordId,
  userId,
  recordAccessType,
  expiryTime,
};

export default SHARE_AND_MANAGE_RECORD_READER;
