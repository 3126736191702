import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// Components
import InputTable from '@tekion/tekion-components/organisms/inputTable/containers/withRowActions/Table';
import Button from '@tekion/tekion-components/atoms/Button';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Helpers
import { getActionsForRow } from './helper/controllingOptionConfigTable.general';
import { getColumns } from './helper/controllingOptionConfigTable.columns';

// Constants
import { FORM_MODES } from '../../../../../../constants/general.constants';
import { ROW_ACTION_PROPS, ROW_OPERATION, TABLE_ACTION_TYPES } from './constants/controllingOptionConfigTable.general';
import ACTION_TYPES from '../../constants/fieldsForm.actionTypes';

// Styles
import styles from '../../fieldsForm.module.scss';

const ControllingOptionConfigTable = (props) => {
  const { id, error, value, formMode, onAction, options, selectedOptions, valuesOptions, isOptionDisabled, isValueDisabled } = props;

  const handleAction = useCallback(
    (action) => {
      const actionType = _get(action, 'type', EMPTY_STRING);

      if (actionType === TABLE_ACTION_TYPES.ON_CHANGE) {
        onAction({
          type: ACTION_TYPES.CONFIG_TABLE_ON_CHANGE,
          payload: { ..._get(action, 'payload', EMPTY_OBJECT), configId: id },
        });
      }
      if (actionType === TABLE_ACTION_TYPES.TABLE_ACTION_CLICK) {
        const tableActionType = _get(action, 'payload.actionType', EMPTY_STRING);
        const row = _get(action, 'payload.nestingPath.[0]', EMPTY_STRING);
        if (tableActionType === TABLE_ACTION_TYPES.REMOVE_ROW) {
          onAction({
            type: ACTION_TYPES.CONFIG_TABLE_REMOVE_ROW,
            payload: { row, configId: id },
          });
        }
      }
    },

    [id, onAction],
  );

  const handleAddRow = useCallback(() => {
    onAction({
      type: ACTION_TYPES.CONFIG_TABLE_ADD_ROW,
      payload: { operation: ROW_OPERATION.ADD, configId: id },
    });
  }, [id, onAction]);

  const columns = useMemo(
    () => getColumns(options, selectedOptions, valuesOptions, isOptionDisabled, isValueDisabled),
    [options, selectedOptions, valuesOptions, isOptionDisabled, isValueDisabled],
  );

  return (
    <div>
      <div className={styles.configTableHeading}>{__('Controlled Options')}</div>
      <InputTable
        columns={columns}
        value={value}
        onAction={handleAction}
        getActionsForRow={getActionsForRow}
        className={styles.inputTable}
        rowActionProps={ROW_ACTION_PROPS}
        onClick={handleAction}
        additional={{ formMode }}
      />
      <Button onClick={handleAddRow} view={Button.VIEW.TERTIARY}>
        {__('Add +')}
      </Button>

      {!_isEmpty(error) && <div style={{ color: '#FF0000' }}>{__(`${error}`)}</div>}
    </div>
  );
};

ControllingOptionConfigTable.propTypes = {
  isOptionDisabled: PropTypes.bool,
  isValueDisabled: PropTypes.bool,
  formMode: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.object,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  value: PropTypes.array,
  valuesOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ControllingOptionConfigTable.defaultProps = {
  isOptionDisabled: true,
  isValueDisabled: true,
  formMode: FORM_MODES.CREATE,
  id: EMPTY_STRING,
  error: EMPTY_OBJECT,
  selectedOptions: EMPTY_ARRAY,
  value: EMPTY_ARRAY,
  valuesOptions: EMPTY_ARRAY,
  options: EMPTY_ARRAY,
};

export default React.memo(ControllingOptionConfigTable);
