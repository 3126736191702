import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import { defaultMemoize } from 'reselect';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import { TEXT_STARTS_WITH } from '../../../../../../constants/filter.constants';
import { ROW_ACTIONS } from './templateBuilderList.columns';

const createTableProps = (onAction, loading, currentPage, pageSize, templates) => ({
  loading,
  currentPage: currentPage + 1,
  resultPerPages: pageSize,
  pageSize,
  totalNumberOfEntries: _size(templates),
  rowHeight: 35,
  showPagination: true,
  fixedPagination: true,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  minRows: 0,
  onRowActionClick: (type, payload) => onAction({ type, payload }),
  showActions: true,
  getRowActions: () => ROW_ACTIONS,
});

const getPayload = defaultMemoize(({ searchText, searchField, pageSize, nextPageToken }) => {
  let payload = { rows: pageSize };
  if (!_isEmpty(searchText)) {
    payload = {
      ...payload,
      filters: [
        {
          field: searchField,
          values: [searchText],
          filterType: TEXT_STARTS_WITH,
        },
      ],
    };
  }
  if (!_isEmpty(nextPageToken)) {
    payload = {
      ...payload,
      nextPageToken,
    };
  }
  return payload;
});

export { createTableProps, getPayload };
