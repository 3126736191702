import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import Popover from '@tekion/tekion-components/molecules/popover';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import TableCellActionMenu from './molecules/tableCellActionMenu';

// styles
import styles from '../../tableCell.module.scss';

const renderContent = (setShowPopoverContent, element, cellBackgroundColor) => (
  <TableCellActionMenu setShowPopoverContent={setShowPopoverContent} element={element} cellBackgroundColor={cellBackgroundColor} />
);

function TableCellPopover(props) {
  const { element, cellBackgroundColor } = props;
  const [showPopoverContent, setShowPopoverContent] = useState(false);

  return (
    <div className={`${styles.popoverIcon} absolute`} contentEditable={false}>
      <Popover
        content={renderContent(setShowPopoverContent, element, cellBackgroundColor)}
        visible={showPopoverContent}
        onVisibleChange={setShowPopoverContent}
        placement="rightTop"
      >
        <FontIcon size={SIZES.S} className="cursor-pointer">
          icon-chevron-down
        </FontIcon>
      </Popover>
    </div>
  );
}

TableCellPopover.propTypes = {
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  }).isRequired,
  cellBackgroundColor: PropTypes.string,
};

TableCellPopover.defaultProps = {
  cellBackgroundColor: undefined,
};

export default React.memo(TableCellPopover);
