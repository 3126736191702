import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

import { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { createAssignment, getAssignments, removeAssignment } from '../../../../../../../../actions/permissionSetManagement.action';
import globalLookupItemsResolver from '../../../../../../../../actions/globalLookupItemsResolver';

import { createAssignmentPayload, createSearchPayload } from './userGroupsSetting.helpers';

import { ACTION_TYPES as ASSIGN_MODAL_ACTION_TYPES } from '../../assignModal/assignModal.constants';
import { LOOKUP_ITEM_TO_RESOLVE, LOOKUP_FIELD_TO_RESOLVE } from '../../../../../../../../constants/globalLookupItemsResolver.constants';
import ACTION_TYPES from '../constants/userGroupsSetting.actionTypes';
import { USER_GROUP_FIELD_IDS } from '../constants/userGroupsSetting.general';
import { FIELD_IDS as PERMISSION_SET_FIELD_IDS } from '../../../../../../../../constants/permissionSet.constants';

const fetchData = async ({ getState, setState }) => {
  const { match } = getState();
  const permissionName = _get(match, 'params.permissionName', EMPTY_STRING);
  setState({ isLoading: true });
  const payload = createSearchPayload(permissionName);
  const response = await getAssignments(payload);
  const assignmentsData = tget(response, 'hits', EMPTY_ARRAY);
  let userGroupsData = [];
  let userGroupNames = [];
  if (!_isEmpty(assignmentsData)) {
    userGroupNames = _map(assignmentsData, (data) => _get(data, 'principalIdentifier', EMPTY_STRING));
    const userGroupResponse = await globalLookupItemsResolver(
      { [LOOKUP_ITEM_TO_RESOLVE.USER_GROUP]: { [LOOKUP_FIELD_TO_RESOLVE.NAME]: userGroupNames } },
      false,
    );
    const userGroups = _map(tget(userGroupResponse, LOOKUP_ITEM_TO_RESOLVE.USER_GROUP, EMPTY_OBJECT));
    userGroupsData = _map(userGroups, (data) => ({
      userGroupName: _get(data, USER_GROUP_FIELD_IDS.DISPLAY_NAME, EMPTY_STRING),
      status: !_get(data, USER_GROUP_FIELD_IDS.DELETED, true),
      name: _get(data, USER_GROUP_FIELD_IDS.NAME, EMPTY_STRING),
    }));
  }
  setState({ assignmentsData, userGroupsSettingData: userGroupsData, isLoading: false, userGroupNames, totalCount: _size(userGroupsData) });
};

const handleAssignUserGroupClick = ({ setState }) => {
  setState({ isAssignUserGroupModalVisible: true });
};

const handleAssignUserGroupModalClose = ({ setState }) => {
  setState({ isAssignUserGroupModalVisible: false });
};

const handleUserGroupDropdownChange = ({ params, setState }) => {
  const { value = EMPTY_ARRAY } = params;
  setState({ selectedUserGroupsModalData: value });
};

const handleUserGroupDropdownDone = async ({ getState, setState }) => {
  const { match, selectedUserGroupsModalData } = getState();
  const permissionName = _get(match, 'params.permissionName', EMPTY_STRING);
  const userGroupNamesFromValue = _map(selectedUserGroupsModalData, 'value');
  if (!_isEmpty(userGroupNamesFromValue)) {
    setState({ isAssignUserGroupModalDataSubmitting: true });
    const payload = createAssignmentPayload(userGroupNamesFromValue, permissionName);
    const response = await createAssignment(payload);
    if (!_isEmpty(response)) {
      setState({ selectedUserGroupsModalData: [] });
    }
  }
  setState({ isAssignUserGroupModalVisible: false, isAssignUserGroupModalDataSubmitting: false }, () => fetchData({ getState, setState }));
};

const handleRemoveAssignedUserGroup = async ({ setState, getState, params }) => {
  const { assignmentsData = [] } = getState();
  const principalId = _get(params, 'name', EMPTY_STRING);
  const userGroupObject = _find(assignmentsData, ['principalIdentifier', principalId]);
  const recordName = _get(userGroupObject, PERMISSION_SET_FIELD_IDS.NAME, EMPTY_STRING);

  await removeAssignment(recordName);

  fetchData({ getState, setState });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: fetchData,
  [ACTION_TYPES.ASSIGN_USER_GROUP_CLICK]: handleAssignUserGroupClick,
  [ASSIGN_MODAL_ACTION_TYPES.ASSIGN_MODAL_CLOSE]: handleAssignUserGroupModalClose,
  [ASSIGN_MODAL_ACTION_TYPES.ASSIGN_DROPDOWN_ON_CHANGE]: handleUserGroupDropdownChange,
  [ASSIGN_MODAL_ACTION_TYPES.ASSIGN_DROPDOWN_DONE]: handleUserGroupDropdownDone,
  [ACTION_TYPES.REMOVE_GROUP_ACTION]: handleRemoveAssignedUserGroup,
};

export default ACTION_HANDLERS;
