import _property from 'lodash/property';

// TODO: Make Dev-platform's pages use this reader
const fieldDefinitionReader = {
  name: _property('name'),
  entityName: _property('entityName'),
  displayName: _property('displayName'),
  fieldType: _property('fieldType'),
  dataType: _property('dataType'),
  helpText: _property('helpText'),
  createdTime: _property('createdTime'),
  creatable: _property('creatable'),
  editable: _property('editable'),
  searchable: _property('searchable'),
  sortable: _property('sortable'),
  filterable: _property('filterable'),
  importable: _property('importable'),
  exportable: _property('exportable'),
  mandatory: _property('mandatory'),
  uniqueConstraint: _property('uniqueConstraint'),
  disabled: _property('disabled'),
  listViewEnabled: _property('listViewEnabled'),
  detailViewEnabled: _property('detailViewEnabled'),
  allowLookupUsingField: _property('allowLookupUsingField'),
  optionConfig: _property('optionConfig'),
  options: _property('optionConfig.options'),
  controllingFieldName: _property('optionConfig.controllingFieldName'),
  controllingOptionsConfigs: _property('optionConfig.controllingOptionsConfigs'),
  constraints: _property('constraints'),
  constraintType: _property('constraints.type'),
  constraintMinLength: _property('constraints.minLength'),
  constraintMaxLength: _property('constraints.maxLength'),
  lookupFieldEntityType: _property('lookupField.entityType'),
  lookupField: _property('lookupField.field'),
  lookupFieldDisplayField: _property('lookupField.displayField'),
  complexFieldDefinitionEntityName: _property('complexFieldDefinition.entityName'),
  relationshipEntityFieldDefinitions: _property('relationshipEntityFieldDefinitions'),
  complexEntityFieldDefinitions: _property('complexEntityFieldDefinitions'),
  multiValued: _property('multiValued'),
  placeholder: _property('placeholder'),
  dependencyConfigs: _property('dependencyConfigs'),
  mediaPreviewEnabled: _property('mediaProperties.previewEnabled'),
};

export default fieldDefinitionReader;
