import React, { useCallback, useEffect } from 'react';
import { compose } from 'recompose';

import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components//molecules/loader';

import withSize from '../../../../../connectors/withSize';
import { COLUMNS, HEADER_PROPS, TABLE_MANAGER_PROPS, getTableProps, SUB_HEADER_PROPS } from './constants/userGroupList.data';
import ACTION_HANDLERS from './helpers/userGroupList.actionhandlers';
import { CURRENT_PAGE, CUSTOM_ACTIONS, INITIAL_STATE, RESULTS_PER_PAGE, SEARCHABLE_OPTIONS } from './constants/userGroupList.constants';

const UserGroupList = (props) => {
  const { onAction, currentPage, pageSize, searchText, searchField, loading, contentHeight, showDeleteModal, userGroupList, nextPageToken } = props;

  const handleRowAction = useCallback(
    (actionType, value) => {
      onAction({
        type: actionType,
        payload: {
          value,
        },
      });
    },
    [onAction],
  );

  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({
      type: CUSTOM_ACTIONS.CLOSE_DELETE_REQUEST_DIALOG,
    });
  }, [onAction]);

  const handleConfirmationDialogSubmit = useCallback(() => {
    onAction({ type: CUSTOM_ACTIONS.CONFIRM_DELETE_REQUEST });
  }, [onAction]);

  const tableProps = getTableProps(handleRowAction, currentPage, loading, pageSize, userGroupList.length);

  useEffect(() => {
    onAction({
      type: CUSTOM_ACTIONS.FETCH_DATA,
    });
  }, [onAction]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Page>
      <Page.Body style={{ height: contentHeight }}>
        <TableManager
          columns={COLUMNS}
          loading={loading}
          data={userGroupList}
          subHeaderProps={SUB_HEADER_PROPS}
          headerProps={HEADER_PROPS}
          tableProps={tableProps}
          tableManagerProps={TABLE_MANAGER_PROPS}
          onAction={onAction}
          searchText={searchText}
          searchField={searchField}
          searchableFieldsOptions={SEARCHABLE_OPTIONS}
          tokenPagination
          nextPageToken={nextPageToken}
        />
        <PropertyControlledComponent controllerProperty={showDeleteModal}>
          <ConfirmationDialog
            isVisible={showDeleteModal}
            title={__('Delete UserGroup')}
            content={__('Are you sure you want to delete this user group.')}
            onSubmit={handleConfirmationDialogSubmit}
            onCancel={handleConfirmationDialogCancel}
          />
        </PropertyControlledComponent>
      </Page.Body>
    </Page>
  );
};
UserGroupList.propTypes = {
  showDeleteModal: PropTypes.bool,
  loading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  searchText: PropTypes.string,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  searchField: PropTypes.string,
  userGroupList: PropTypes.array,
  nextPageToken: PropTypes.string,
  onAction: PropTypes.func.isRequired,
};

UserGroupList.defaultProps = {
  loading: false,
  showDeleteModal: false,
  searchText: EMPTY_STRING,
  pageSize: RESULTS_PER_PAGE,
  currentPage: CURRENT_PAGE,
  searchField: EMPTY_STRING,
  userGroupList: EMPTY_ARRAY,
  nextPageToken: EMPTY_STRING,
};

export default compose(withActions(INITIAL_STATE, ACTION_HANDLERS), withSize({ hasPageHeader: false }))(UserGroupList);
