import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import _slice from 'lodash/slice';
import _size from 'lodash/size';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, NO_DATA } from '@tekion/tekion-base/app.constants';
import { getNthChildOfFieldName, getSimpleFieldNameFromColumn } from '../../../../utils';
import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

const getFieldDefProperties = (fieldName, fieldDefinitionByName) => {
  const firstFieldName = getSimpleFieldNameFromColumn(fieldName);
  const fieldDefForFirstFieldName = _get(fieldDefinitionByName, firstFieldName);
  const isFieldComplex = fieldDefinitionReader.dataType(fieldDefForFirstFieldName) === DATA_TYPES.COMPLEX;
  const isRelationshipField = fieldDefinitionReader.fieldType(fieldDefForFirstFieldName) === FIELD_TYPES.RELATIONSHIP;
  let isMultiValued = fieldDefinitionReader.multiValued(fieldDefForFirstFieldName);
  let isRichTextEditorField = fieldDefinitionReader.fieldType(fieldDefForFirstFieldName) === FIELD_TYPES.RICH_TEXT_EDITOR;
  let isMediaField = fieldDefinitionReader.fieldType(fieldDefForFirstFieldName) === FIELD_TYPES.MEDIA;

  if (isFieldComplex) {
    const complexEntityFieldDefinitions = fieldDefinitionReader.complexEntityFieldDefinitions(fieldDefForFirstFieldName);
    const secondFieldName = getNthChildOfFieldName(fieldName, 1);
    const fieldDefForSecondFieldName = _get(complexEntityFieldDefinitions, secondFieldName);
    isMultiValued = isMultiValued || fieldDefinitionReader.multiValued(fieldDefForSecondFieldName);
    isRichTextEditorField = fieldDefinitionReader.fieldType(fieldDefForSecondFieldName) === FIELD_TYPES.RICH_TEXT_EDITOR;
    isMediaField = fieldDefinitionReader.fieldType(fieldDefForSecondFieldName) === FIELD_TYPES.MEDIA;
  }

  if (isRelationshipField) {
    const relationshipEntityFieldDefinitions = fieldDefinitionReader.relationshipEntityFieldDefinitions(fieldDefForFirstFieldName);
    const secondFieldName = getNthChildOfFieldName(fieldName, 1);
    const fieldDefForSecondFieldName = _get(relationshipEntityFieldDefinitions, secondFieldName);
    isMultiValued = isMultiValued || fieldDefinitionReader.multiValued(fieldDefForSecondFieldName);
    isRichTextEditorField = fieldDefinitionReader.fieldType(fieldDefForSecondFieldName) === FIELD_TYPES.RICH_TEXT_EDITOR;
    isMediaField = fieldDefinitionReader.fieldType(fieldDefForSecondFieldName) === FIELD_TYPES.MEDIA;
  }

  return { isFieldComplex, isMultiValued, isRichTextEditorField, isMediaField, isRelationshipField };
};

const getRichTextEditorValue = (fieldValue) => {
  let newValue;

  if (!_isEmpty(fieldValue) && fieldValue !== NO_DATA) {
    try {
      newValue = JSON.parse(fieldValue);
      if (_size(newValue) !== 1) {
        const lastValue = _last(newValue);
        const type = _get(lastValue, 'type');
        const children = getArraySafeValue(tget(lastValue, 'children', EMPTY_ARRAY));
        if (type === 'p' && _isEmpty(_get(children, 'text'))) {
          newValue = _slice(newValue, 0, -1);
        }
      }
    } catch (error) {
      newValue = undefined;
    }
  }
  if (_isEmpty(newValue) && !_isEmpty(fieldValue)) {
    return { newFieldValue: fieldValue, isHtml: true };
  } else {
    return { newFieldValue: newValue, isHtml: false };
  }
};

export { getFieldDefProperties, getRichTextEditorValue };
