import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';

// Constants
import COLUMN_IDS from '../constants/subscriptionConfigList.columnIds';
import {
  ACTIVE_SUBSCRIPTION_CONFIG,
  DEFAULT_ROW_ACTIONS,
  INACTIVE_SUBSCRIPTION_CONFIG,
  REDIRECT_TO_SUBSCRIPTION_CONFIG_LOGS,
} from './subscriptionConfigList.columns';
import { SUBSCRIPTION_CONFIG_STATUS_FILTER, DEFAULT_FILTERS_TYPES } from '../constants/subscriptionConfigList.filterTypes';
import { TEXT_CONTAINS } from '../../../../../../constants/filter.constants';

const createTableProps = (isLoading, currentPage, pageSize, onAction) => ({
  loading: isLoading,
  currentPage: currentPage + 1,
  rowHeight: 35,
  minRows: 0,
  showPagination: true,
  pageSize,
  resultsPerPage: pageSize,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  onRowActionClick: (type, payload) => onAction({ type, payload }),
  showActions: true,
  getRowActions: (rowData) => {
    const inactive = _get(rowData, COLUMN_IDS.STATUS);
    const ACTIONS = !inactive
      ? [...DEFAULT_ROW_ACTIONS, INACTIVE_SUBSCRIPTION_CONFIG, REDIRECT_TO_SUBSCRIPTION_CONFIG_LOGS]
      : [...DEFAULT_ROW_ACTIONS, ACTIVE_SUBSCRIPTION_CONFIG];
    return ACTIONS;
  },
});

const createFilterProps = (onAction) => ({
  filterTypes: [SUBSCRIPTION_CONFIG_STATUS_FILTER],
  onAction,
  defaultFilterTypes: DEFAULT_FILTERS_TYPES,
  showResultsCount: false,
});

const createFilterArrayForApiPayload = (selectedFilters) => {
  const filters = _map(
    _filter(selectedFilters, (filter) => !_isEmpty(_get(filter, 'values'))),
    (filter) => ({
      field: _get(filter, 'type', EMPTY_STRING),
      values: _get(filter, 'values', EMPTY_ARRAY),
      filterType: _get(filter, 'operator', EMPTY_STRING),
    }),
  );

  return filters;
};

const getPayload = ({ currentPage, currentPageToken, pageSize, selectedFilters, searchText }) => {
  const payload = { rows: pageSize, start: currentPage * pageSize, nextPageToken: currentPageToken };
  let filters = [];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: COLUMN_IDS.SUBSCRIPTION_CONFIG_DISPLAY_NAME,
      values: [searchText],
      filterType: TEXT_CONTAINS,
    });
  }

  if (!_isEmpty(selectedFilters)) {
    filters = [...filters, ...createFilterArrayForApiPayload(selectedFilters)];
  }

  if (!_isEmpty(filters)) {
    _set(payload, 'filters', filters);
  }

  return payload;
};

export { createTableProps, createFilterProps, getPayload };
