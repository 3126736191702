import _property from 'lodash/property';
import _flow from 'lodash/flow';

import { SHARE_AND_MANAGE_RECORD_USER_DATA_KEYS } from './shareAndManageRecordUserData.constants';

import workspaceUserReader from '../../../../../readers/workSpaceUser.reader';

const baseReadPoint = _property(SHARE_AND_MANAGE_RECORD_USER_DATA_KEYS.BASE_PATH);

const recordAccessType = _flow(baseReadPoint, _property(SHARE_AND_MANAGE_RECORD_USER_DATA_KEYS.RECORD_ACCESS_TYPE));

const expiryTime = _flow(baseReadPoint, _property(SHARE_AND_MANAGE_RECORD_USER_DATA_KEYS.EXPIRY_TIME));

const entityAccessTypes = _flow(baseReadPoint, _property(SHARE_AND_MANAGE_RECORD_USER_DATA_KEYS.ENTITY_ACCESS_TYPES));

const SHARE_AND_MANAGE_RECORD_USER_GETTERS = {
  ...workspaceUserReader,
  recordAccessType,
  expiryTime,
  entityAccessTypes,
};

export default SHARE_AND_MANAGE_RECORD_USER_GETTERS;
