import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _reduce from 'lodash/reduce';
import _sortBy from 'lodash/sortBy';
import _size from 'lodash/size';
import _last from 'lodash/last';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';

// Components
import FieldValuePair from '../fieldValuePair/FieldValuePair';

// Constants
import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../../../../../../constants/approvalCentre.constants';

import fieldDefinitionReader from '../../../../../../../../../readers/fieldDefinition.reader';

const SettingRequest = ({ rowClassName, approvalRequest, approvalSetting }) => {
  const data = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.DATA, EMPTY_OBJECT);

  const rows = useMemo(() => {
    const fields = tget(approvalSetting, APPROVAL_CENTRE_FIELD_IDS.FIELDS, []);
    const sortedFields = _sortBy(fields, fieldDefinitionReader.createdTime);

    const _rows = _reduce(
      sortedFields,
      (curRows, field) => {
        const fieldName = fieldDefinitionReader.name(field);
        const fieldLabel = fieldDefinitionReader.displayName(field);

        const lastRow = _last(curRows);
        if (_size(lastRow) === 2) {
          const newRow = [{ fieldName, fieldLabel }];
          curRows.push(newRow);
        } else if (_size(lastRow) === 1) {
          lastRow.push({ fieldName, fieldLabel });
        } else {
          const newRow = [{ fieldName, fieldLabel }];
          curRows.push(newRow);
        }

        return curRows;
      },
      [],
    );

    return _rows;
  }, [approvalSetting]);

  return _map(rows, (row) => (
    <div className={rowClassName}>
      {_map(row, (column) => {
        const { fieldLabel, fieldName } = column;
        const fieldValue = tget(data, fieldName, NO_DATA);
        return <FieldValuePair label={fieldLabel} value={fieldValue} />;
      })}
    </div>
  ));
};

SettingRequest.propTypes = {
  rowClassName: PropTypes.string,
  approvalRequest: PropTypes.object.isRequired,
  approvalSetting: PropTypes.object.isRequired,
};

SettingRequest.defaultProps = {
  rowClassName: EMPTY_STRING,
};

export default SettingRequest;
