import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _startsWith from 'lodash/startsWith';
import _trim from 'lodash/trim';
import _toLower from 'lodash/toLower';

import { EMPTY_OBJECT, EMPTY_STRING, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { getAllDashboards } from '../../../../../../../actions/reporting.actions';
import ACTION_TYPES from '../constants/reportTab.constants';

const handleInit = async ({ setState }) => {
  setState({ loading: true });

  const dashboardResponse = await getAllDashboards();
  const dashboards = tget(dashboardResponse, 'hits', EMPTY_ARRAY);
  setState({ loading: false, dashboards });
};

const handleSearch = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { applicationsByProjectNames, selectedProject } = getState();
  const { searchTerm } = params;
  const selectedProjectValue = _get(selectedProject, 'value', '');
  const searchText = _trim(_toLower(searchTerm));
  const applicationList = _get(applicationsByProjectNames, selectedProjectValue, []);
  const newApplicationList = _filter(applicationList, (application) => {
    const displayName = _toLower(_get(application, 'displayName', EMPTY_STRING));
    return _startsWith(displayName, searchText);
  });

  setState({ searchText, applicationList: newApplicationList });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: handleInit,
  [ACTION_TYPES.ON_SEARCH_CHANGE]: handleSearch,
};

export default ACTION_HANDLERS;
