const ASSIGNED_VARIABLE_DATA_TYPES = {
  SINGLE: 'OBJECT',
  MULTIPLE: 'ARRAY',
};

const ASSIGNED_VARIABLE_DATA_TYPE_OPTIONS = [
  {
    label: __('Single'),
    value: ASSIGNED_VARIABLE_DATA_TYPES.SINGLE,
  },
  {
    label: __('Multiple'),
    value: ASSIGNED_VARIABLE_DATA_TYPES.MULTIPLE,
  },
];

export { ASSIGNED_VARIABLE_DATA_TYPES, ASSIGNED_VARIABLE_DATA_TYPE_OPTIONS };
