const CONTEXT_ID = 'CREATE_USER_GROUP';

const ACTION_TYPES = {
  INIT: 'INIT',
  MODAL_CLICK: 'MODAL_CLICK',
  MODAL_SAVE: 'MODAL_SAVE',
  MODAL_CLOSE: 'MODAL_CLOSE',
  TABLE_REMOVE_ROW: 'TABLE_REMOVE_ROW',
  LOAD_CONDITION_FIELDS: 'LOAD_CONDITION_FIELDS',
};

const FIELD_IDS = {
  USER_GROUP_NAME: 'USER_GROUP_NAME',
  DESCRIPTION: 'DESCRIPTION',
  GROUP_TYPE: 'GROUP_TYPE',
  USERS: 'USERS',
  CONDITION: 'CONDITION',
};

const GROUP_TYPES = {
  STATIC: 'STATIC',
  DYNAMIC: 'DYNAMIC',
};

const GROUP_TYPE_OPTIONS = [
  {
    label: __('Static'),
    value: GROUP_TYPES.STATIC,
  },
];

const EDIT_USER_GROUP = 'edit-user-group';

export { CONTEXT_ID, ACTION_TYPES, FIELD_IDS, GROUP_TYPES, GROUP_TYPE_OPTIONS, EDIT_USER_GROUP };
