import React from 'react';
import PropTypes from 'prop-types';

import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import styles from './fieldValuePair.module.scss';

const FieldValuePair = ({ icon, label, value }) => (
  <div className={styles.stageFieldContainer}>
    {icon && <FontIcon className={styles.icon}>{icon}</FontIcon>}
    <div className={styles.label}>{label}</div>
    <div className={styles.value}>{value}</div>
  </div>
);

FieldValuePair.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

FieldValuePair.defaultProps = {
  icon: undefined,
};

export default React.memo(FieldValuePair);
