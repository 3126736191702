import { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';

import ROUTES from '../../../../../constants/routes';

const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showHeader: false,
  showSearch: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const ROLES_USER_STATUS = {
  ACTIVE: true,
  INACTIVE: false,
};

const ROLES_USER_STATUS_LABEL_MAP = {
  [ROLES_USER_STATUS.ACTIVE]: __('Active'),
  [ROLES_USER_STATUS.INACTIVE]: __('Inactive'),
};

const ROLES_USER_STATUS_COLOR_MAP = {
  [ROLES_USER_STATUS.ACTIVE]: COLOR_MAP.GREEN,
  [ROLES_USER_STATUS.INACTIVE]: COLOR_MAP.RED,
};

const INITIAL_STATES_ASSIGN_USER_ROLE = { currentPage: 0, pageSize: 50 };
const ROLES_NAVIGATION_DEFAULT_DATA = [{ label: __('Roles'), goTo: ROUTES.REPORTEE_ROUTE, key: 'roleConfigure' }];

export {
  TABLE_MANAGER_PROPS,
  ROLES_USER_STATUS_LABEL_MAP,
  ROLES_USER_STATUS_COLOR_MAP,
  ROLES_NAVIGATION_DEFAULT_DATA,
  INITIAL_STATES_ASSIGN_USER_ROLE,
};
