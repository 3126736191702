import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';

import { fetchProcesses } from '../../../../../../../../actions/processBuilder.actions';

import { ACTION_TYPES } from './switchWorkflowModal.constants';

const onLoadForm = async ({ setState }) => {
  setState({
    isFormLoading: true,
  });

  const workflowList = await fetchProcesses();
  const workflowOptions = _map(workflowList, (workflow) => ({ label: tget(workflow, 'name'), value: tget(workflow, 'id') }));

  setState({ isFormLoading: false, workflowOptions });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_LOAD]: onLoadForm,
};

export default ACTION_HANDLERS;
