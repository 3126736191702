import _isEmpty from 'lodash/isEmpty';
import _nth from 'lodash/nth';

import { fetchRecordAccess, fetchEntityAccessForUsers } from '../../../../../../../actions/shareAndManageRecord.actions';
import { getAllAccessTypes, optionAccessible } from './useShareAndManageRecordAction.response';

import workspaceUserReader from '../../../../../../../readers/workSpaceUser.reader';
import EntityRecordReader from '../../../../../../../readers/entityRecord.reader';

const fetchOnOpenData = async ({ entityName, entityRecord, wwdData, currentUserData }) => {
  const recordId = EntityRecordReader.id(entityRecord);
  const recordTypeId = EntityRecordReader.recordTypeId(entityRecord);

  // Getting userId from local storage
  const userId = workspaceUserReader.id(currentUserData);

  const promises = [];

  // Fetching record access
  promises.push(fetchRecordAccess(entityName, recordId));

  if (!_isEmpty(userId)) {
    promises.push(fetchEntityAccessForUsers(entityName, recordTypeId, [userId]));
  }

  const responses = await Promise.all(promises);

  const recordAccessType = responses[0];

  const { entityAccessTypes, wwdAccessType } = getAllAccessTypes({ entityAccessResponse: _nth(responses, 1), userId, wwdData });

  const isOptionAccessible = optionAccessible(recordAccessType, entityAccessTypes, wwdAccessType);

  return { isOptionAccessible };
};

export { fetchOnOpenData };
