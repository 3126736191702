exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".detailViewAttributeRenderer_detailViewAttributeRenderer__gtWbCvSGsZ{min-width:10rem}.detailViewAttributeRenderer_detailViewAttributeRenderer__gtWbCvSGsZ .ant-col-15{width:100%}[dir] .detailViewAttributeRenderer_detailViewAttributeRenderer__gtWbCvSGsZ .detailViewAttributeRenderer_padding__cenKBMJZ8r{padding:.5rem}.detailViewAttributeRenderer_detailViewAttributeRenderer__gtWbCvSGsZ .detailViewAttributeRenderer_keyContainer__s3DAhYf9hN{font-weight:bold}.detailViewAttributeRenderer_detailViewAttributeRenderer__gtWbCvSGsZ .detailViewAttributeRenderer_keyContainer__s3DAhYf9hN .detailViewAttributeRenderer_key__dwQBkz7sU8{display:flex;align-items:center}[dir=ltr] .detailViewAttributeRenderer_detailViewAttributeRenderer__gtWbCvSGsZ .detailViewAttributeRenderer_keyContainer__s3DAhYf9hN .detailViewAttributeRenderer_key__dwQBkz7sU8 .detailViewAttributeRenderer_icon__s6rUgYeswp{margin-right:1rem}[dir=rtl] .detailViewAttributeRenderer_detailViewAttributeRenderer__gtWbCvSGsZ .detailViewAttributeRenderer_keyContainer__s3DAhYf9hN .detailViewAttributeRenderer_key__dwQBkz7sU8 .detailViewAttributeRenderer_icon__s6rUgYeswp{margin-left:1rem}.detailViewAttributeRenderer_richTextEditorWrapper__jykQTYR8bt{max-width:100%}.detailViewAttributeRenderer_titleCustomStyle__eUToKuvwKQ{font-size:2rem;font-weight:bold}.detailViewAttributeRenderer_truncatedText__t7qY933eGG{display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis}", ""]);

// Exports
exports.locals = {
	"detailViewAttributeRenderer": "detailViewAttributeRenderer_detailViewAttributeRenderer__gtWbCvSGsZ",
	"padding": "detailViewAttributeRenderer_padding__cenKBMJZ8r",
	"keyContainer": "detailViewAttributeRenderer_keyContainer__s3DAhYf9hN",
	"key": "detailViewAttributeRenderer_key__dwQBkz7sU8",
	"icon": "detailViewAttributeRenderer_icon__s6rUgYeswp",
	"richTextEditorWrapper": "detailViewAttributeRenderer_richTextEditorWrapper__jykQTYR8bt",
	"titleCustomStyle": "detailViewAttributeRenderer_titleCustomStyle__eUToKuvwKQ",
	"truncatedText": "detailViewAttributeRenderer_truncatedText__t7qY933eGG"
};