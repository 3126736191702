import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _size from 'lodash/size';

import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import withActions from '@tekion/tekion-components/connectors/withActions';

import { EMPTY_STRING, EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import Tabs from '@tekion/tekion-components/molecules/Tabs';

import withSize from '../../../../../connectors/withSize';
import EntitySettings from './components/entitySettings';
import UsersSettings from './components/usersSettings';
import UserGroupsSettings from './components/userGroupsSettings';
import ProjectSettings from './components/projectSettings';
import PermissionSetConfigureCardView from './components/permissionSetConfigureCardView';
import NavigationItem from '../../../../../molecules/NavigationFlow/components/navigationItem';

import { TABS, PERMISSION_SET_NAVIGATION_DEFAULT_DATA } from './constants/permissionSetConfigure.constants';
import { STUDIO_ROUTE } from '../../../../../constants/routes';
import PAGE_IDS from '../../../constants/PageIds.constants';
import ACTION_HANDLERS from './permissionSetConfigure.actionHandlers';
import ACTION_TYPES from './constants/permissionSetConfigure.actionTypes';

import styles from './permissionSetConfigure.module.scss';

const PermissionSetConfigure = ({ contentHeight, history, match, permissionData, onAction }) => {
  const { tabName, permissionName } = _get(match, 'params', EMPTY_OBJECT);

  const onTabChangeHandler = useCallback(
    (newTabName) => {
      const path = `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_CONFIGURE}/${permissionName}/${newTabName}`;
      onAction({ type: ACTION_TYPES.ON_TAB_CHANGE, payload: { path } });
    },
    [onAction, permissionName],
  );

  const headerNavigationData = useMemo(
    () => [...PERMISSION_SET_NAVIGATION_DEFAULT_DATA, { label: __(_get(permissionData, 'name', NO_DATA)), key: '2' }],
    [permissionData],
  );

  const handleNavigationItemClick = useCallback(
    (goTo) => {
      if (!_isEmpty(goTo)) {
        history.push(goTo);
      }
    },
    [history],
  );

  const headerNavigation = useMemo(
    () =>
      _map(headerNavigationData, (data, index) => (
        <NavigationItem
          key={_get(data, 'key', EMPTY_STRING)}
          itemNumber={index}
          totalItems={_size(headerNavigationData)}
          data={data}
          onNavigationItemClick={handleNavigationItemClick}
        />
      )),
    [headerNavigationData, handleNavigationItemClick],
  );

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.INIT_DATA,
    });
  }, [onAction]);

  return (
    <Page>
      <Page.Header>
        <Heading className={styles.headerNavigationContainer} size={2}>
          {headerNavigation}
        </Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight - 24 }}>
        <PermissionSetConfigureCardView permissionCardData={permissionData} onAction={onAction} />
        <div className={styles.tabsContainer} style={{ height: contentHeight - 110 }}>
          <Tabs className="full-height" activeKey={tabName} onChange={onTabChangeHandler}>
            <Tabs.TabPane tab={__('Entity Settings')} key={TABS.ENTITY_SETTINGS}>
              <EntitySettings history={history} match={match} permissionData={permissionData} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={__('Project Settings')} key={TABS.PROJECT_SETTINGS}>
              <ProjectSettings match={match} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={__('Users')} key={TABS.USERS}>
              <UsersSettings match={match} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={__('User Groups')} key={TABS.USER_GROUPS}>
              <UserGroupsSettings match={match} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Page.Body>
    </Page>
  );
};
PermissionSetConfigure.propTypes = {
  contentHeight: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.object,
  permissionData: PropTypes.object,
  onAction: PropTypes.func,
};

PermissionSetConfigure.defaultProps = {
  match: EMPTY_OBJECT,
  permissionData: EMPTY_OBJECT,
  contentHeight: 0,
  onAction: _noop,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 0 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(PermissionSetConfigure);
