/* eslint-disable import/order */
import React, { useMemo } from 'react';

// components
import Dropdown, { DROPDOWN_TRIGGER } from '@tekion/tekion-components/molecules/DropDown';
import FontColorList from './molecules/fontColorList';

// hooks
import useDropdownVisibility from './hooks/useDropdownVisibility';
import useDropdownContent from './hooks/useDropdownContent';

const renderDropdownOverlay = (onCloseDropdown) => <FontColorList onCloseDropdown={onCloseDropdown} />;

function FontColorToolbarButton() {
  const { onCloseDropdown, showDropdown, setShowDropdown } = useDropdownVisibility();

  const dropdownOverlay = useMemo(() => renderDropdownOverlay(onCloseDropdown), [onCloseDropdown]);

  const dropdownContent = useDropdownContent(showDropdown);

  return (
    <Dropdown
      trigger={[DROPDOWN_TRIGGER.CLICK]}
      overlay={dropdownOverlay}
      visible={showDropdown}
      onVisibleChange={setShowDropdown}
      placement="bottomLeft"
    >
      {dropdownContent}
    </Dropdown>
  );
}

export default FontColorToolbarButton;
