import { Path } from 'slate';
import produce from 'immer';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _set from 'lodash/set';
import { insertNodes, someNode, getAbove } from '@udecode/plate-common';
import { ELEMENT_TABLE, ELEMENT_TD, ELEMENT_TH } from '@udecode/plate-table';
import { getPlatePluginType } from '@udecode/plate-core';

// utils
import { uuid } from '@tekion/tekion-base/utils/general';

// constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// helpers
import { createEmptyCellNode } from './richTextEditor.tableGeneral';

const getIsHeaderRow = (editor, cellNodes) => cellNodes?.[0]?.type === getPlatePluginType(editor, ELEMENT_TH);

const getNewCellPath = produce((nextColumnCellPath, { rowIndex, rowNodePositionInPath }) =>
  _set(nextColumnCellPath, `${rowNodePositionInPath}`, rowIndex),
);

const insertCellInRow =
  ({ nextColumnCellPath, rowNodePositionInPath, editor, currentRowIdx, newColumnId }) =>
  (row, rowIndex) => {
    const newRowCellPath = getNewCellPath(nextColumnCellPath, { rowIndex, rowNodePositionInPath });
    const { children: cellNodes, rowId } = row || EMPTY_OBJECT;
    const isHeaderRow = getIsHeaderRow(editor, cellNodes);
    insertNodes(
      editor,
      createEmptyCellNode({
        editor,
        rowId,
        isHeader: isHeaderRow,
        columnId: newColumnId,
      }),
      {
        at: newRowCellPath,
        select: rowIndex === currentRowIdx,
      },
    );
  };

const getNextColumnCellPath = (currentCellItem) => {
  const [, currentCellPath] = currentCellItem || EMPTY_ARRAY;
  const nextColumnCellPath = Path.next(currentCellPath);
  return nextColumnCellPath;
};

const insertColumnNodeInTable = ({ currentCellItem, editor, currentTableItem }) => {
  const nextColumnCellPath = getNextColumnCellPath(currentCellItem);
  const rowNodePositionInPath = _size(nextColumnCellPath) - 2;
  const currentRowIdx = nextColumnCellPath[rowNodePositionInPath];
  const newColumnId = uuid();
  const rowNodes = currentTableItem[0].children;

  _forEach(
    rowNodes,
    insertCellInRow({
      nextColumnCellPath,
      rowNodePositionInPath,
      editor,
      currentRowIdx,
      newColumnId,
    }),
  );
};

const insertColumn = (editor) => {
  if (
    someNode(editor, {
      match: { type: getPlatePluginType(editor, ELEMENT_TABLE) },
    })
  ) {
    const currentCellItem = getAbove(editor, {
      match: {
        type: [getPlatePluginType(editor, ELEMENT_TH), getPlatePluginType(editor, ELEMENT_TD)],
      },
    });

    const currentTableItem = getAbove(editor, {
      match: { type: getPlatePluginType(editor, ELEMENT_TABLE) },
    });

    if (currentCellItem && currentTableItem) {
      insertColumnNodeInTable({ currentCellItem, editor, currentTableItem });
    }
  }
};

export default insertColumn;
