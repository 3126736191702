import _isString from 'lodash/isString';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { VARIABLE_PREFIX_NAME } from '../constants/workflow.constants';

const checkValueIsVariable = (value) => {
  if (_isString(value)) {
    const variableName = getArraySafeValue(value.split('.'));
    return variableName === VARIABLE_PREFIX_NAME;
  }
  return false;
};

const getVariableName = (value) => tget(value.split('.'), '1', EMPTY_STRING);

export { checkValueIsVariable, getVariableName };
