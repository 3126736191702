import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _head from 'lodash/head';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

// Components
import Loader from '@tekion/tekion-components/molecules/loader';
import EntityViewViewer from '../../../../../../../organisms/viewBuilder/organisms/entityViewViewer/EntityViewViewer';

// Actions
import { fetchEntityViewConfigurationByName, searchEntityViewConfigurations } from '../../../../../../../actions/entityViewDefinitions.actions';
import { getMasterEntityDef } from '../../../../../../../actions/entityManagement.actions';

import { generateFormViewDefsPayload } from './recordFieldsForm.helpers';

// Constants
import { APPROVAL_METADATA_PROPERTY_IDS, CUSTOM_ENTITY_CATEGORY } from '../../../../../../../constants/approvalCentre.constants';
import FIELD_IDS from '../../constants/approvalRequestForm.fieldIds';

const RecordFieldsForm = ({ contextId, formValues, applicationProperties, onFormFieldBlur, onFormSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [entityDef, setEntityDef] = useState(EMPTY_OBJECT);
  const [entityViewDef, setEntityViewDef] = useState(EMPTY_OBJECT);

  const category = getArraySafeValue(tget(formValues, FIELD_IDS.CATEGORY, []));
  const entityName = getArraySafeValue(tget(formValues, FIELD_IDS.TYPE, []));

  const entityRecord = useMemo(
    () => tget(formValues, [FIELD_IDS.DATA, FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.CUSTOM_ENTITY_REQUEST]),
    [formValues],
  );

  const loadEntityViewConfigs = useCallback(
    async (_entityName) => {
      const entitiesRenderingInfo = tget(applicationProperties, APPROVAL_METADATA_PROPERTY_IDS.ENTITIES_RENDERING_INFO, EMPTY_ARRAY);
      const currentEntityRenderingInfo = _find(entitiesRenderingInfo, { [APPROVAL_METADATA_PROPERTY_IDS.RENDERING_INFO_IDS.ENTITY]: _entityName });
      const entityViewDefName = tget(currentEntityRenderingInfo, APPROVAL_METADATA_PROPERTY_IDS.RENDERING_INFO_IDS.FORM_VIEW);

      // Note: If needed here we can add Page Loading support as well.

      // Fetching user selected entity view if present in applicationProperties
      if (!_isEmpty(entityViewDefName)) {
        setIsLoading(true);
        setIsLoaded(false);

        const promises = [getMasterEntityDef(_entityName), fetchEntityViewConfigurationByName(_entityName, entityViewDefName)];
        const [_entityDef, _entityViewDef] = await Promise.all(promises);

        setEntityDef(_entityDef);
        setEntityViewDef(_entityViewDef);
        setIsLoaded(true);
        setIsLoading(false);
      } else {
        // Currently fetching all form entity views & rendering the topmost one.
        // TODO: Once standard entity views are done, integrate fetching the standard one here.
        setIsLoading(true);
        setIsLoaded(false);

        const formViewDefsPayload = generateFormViewDefsPayload(entityName);
        const promises = [getMasterEntityDef(_entityName), searchEntityViewConfigurations(formViewDefsPayload)];
        const [_entityDef, formViewDefinitionsResponse] = await Promise.all(promises);
        const formViewDefinitions = tget(formViewDefinitionsResponse, 'hits', EMPTY_ARRAY);

        setEntityDef(_entityDef);
        setEntityViewDef(_head(formViewDefinitions));
        setIsLoaded(true);
        setIsLoading(false);
      }
    },
    [entityName, applicationProperties],
  );

  useEffect(() => {
    if (category === CUSTOM_ENTITY_CATEGORY && !_isEmpty(entityName)) {
      loadEntityViewConfigs(entityName);
    }
  }, [category, entityName, loadEntityViewConfigs]);

  if (isLoading) {
    return <Loader id={contextId} />;
  }

  if (!isLoaded) {
    return null;
  }

  return (
    <EntityViewViewer
      isPreviewMode
      contextId={contextId}
      entityDef={entityDef}
      entityViewConfiguration={entityViewDef}
      entityRecord={entityRecord}
      onFormFieldBlur={onFormFieldBlur}
      onFormSubmit={onFormSubmit}
    />
  );
};

RecordFieldsForm.propTypes = {
  contextId: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  applicationProperties: PropTypes.object,
  onFormFieldBlur: PropTypes.func,
  onFormSubmit: PropTypes.func,
};

RecordFieldsForm.defaultProps = {
  applicationProperties: EMPTY_OBJECT,
  onFormFieldBlur: _noop,
  onFormSubmit: _noop,
};

export default RecordFieldsForm;
