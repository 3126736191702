// TODO: remove duplicate from tekion-core
import _get from 'lodash/get';
import _head from 'lodash/head';

// Utils
import castArrayIfPresent from '@tekion/tekion-base/utils/castArrayIfPresent';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';

// Services
import { fetchStreamingVideoUrl } from '../../../../../services/richTextEditor.video';

export const getIsVideoFragmented = (response) => _get(response, 'responseMap.fragment');

const getVideoInformation = (response) => {
  const mediaResponse = getDataFromResponse(response);
  const videoInfo = _head(mediaResponse);
  const signedMediaUrl = _get(videoInfo, 'normal.url');
  const isVideoFragmented = _get(videoInfo, 'normal.mediaMeta.fragment');

  return {
    signedMediaUrl,
    isVideoFragmented,
  };
};

export const getVideoManifestUrl = (mediaId) => {
  const manifestRequest = castArrayIfPresent(mediaId);
  return fetchStreamingVideoUrl(manifestRequest).then(getVideoInformation);
};
