import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';
import _size from 'lodash/size';
import _forEach from 'lodash/forEach';
import _set from 'lodash/set';
import _includes from 'lodash/includes';

import { tget } from '@tekion/tekion-base/utils/general';

import SearchRequest from '../../../../../../../builders/searchRequest';
import FilterBuilder from '../../../../../../../builders/filterBuilder';
import { MenuOptionBuilder } from '../../../../../../../atoms/menu';

import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../../constants/actionBuilder.constants';

const getPayloadForActions = (entityName, actionsNameToShow) => {
  const searchRequest = new SearchRequest();
  const FilterObject = new FilterBuilder();

  FilterObject.addEntityNameFilter({
    values: _castArray(entityName),
  }).addNameFilter({
    values: _castArray(actionsNameToShow),
  });

  if (!_isEmpty(actionsNameToShow)) {
    searchRequest.setRows(_size(actionsNameToShow));
  } else {
    searchRequest.setRows(100);
  }

  searchRequest.setFilters(FilterObject.getFilterPayload());

  return searchRequest;
};

const generateOptions = (actionDefs, validActionNames, onOptionClick) => {
  const MenuOptionObject = new MenuOptionBuilder();

  _forEach(actionDefs, (actionDef) => {
    const actionName = tget(actionDef, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME);
    const actionDisplayName = tget(actionDef, ACTION_DEFINITION_FIELD_IDS.ACTION_DISPLAY_NAME);
    const actionErrorMessage = tget(
      actionDef,
      ACTION_DEFINITION_FIELD_IDS.ERROR_MESSAGE_ON_ENTRY_CONDITION,
      __('Record could not qualify entry condition'),
    );

    const option = { label: actionDisplayName, id: actionName, onClick: onOptionClick };

    if (!_includes(validActionNames, actionName)) {
      _set(option, 'disabled', true);
      _set(option, 'disableMessage', actionErrorMessage);
    }

    MenuOptionObject.addOption({
      label: option.label,
      id: option.id,
      disabled: option.disabled,
      disableMessage: option.disableMessage,
      onClick: option.onClick,
    });
  });

  return MenuOptionObject.getOptions();
};

export { getPayloadForActions, generateOptions };
