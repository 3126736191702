import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _valuesIn from 'lodash/valuesIn';
import _filter from 'lodash/filter';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_STRING, EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { fetchWidgets, createDashboard, fetchDashboard, updateDashboard } from '../../../../../actions/reporting.actions';
import { createListData, getWidgetItems, updateItemLayoutOnAddWidget } from './createDashboard.helpers';
import { getGridUpdateRequestBody, transformResponseBodyToGridLayout } from '../../../../../organisms/reporting/helpers/reporting.general.helpers';
import ACTION_TYPES from '../constants/createDashboard.actionTypes';
import { DASHBOARD_TYPES } from '../../../../../organisms/reporting/constants/reporting.general.constants';
import dashboardReader from '../../../../../readers/reportingDashboard.reader';

import { STUDIO_ROUTE } from '../../../../../constants/routes';
import { FORM_MODES } from '../../../../../constants/general.constants';
import PAGE_IDS from '../../../constants/PageIds.constants';
import { EDIT_DASHBOARD_COMPONENT_FIELDS } from '../components/editDashboardModal/constants/editDashboardComponent.general.constants';

const handleInit = async ({ params = EMPTY_OBJECT, setState, getState }) => {
  const { formValues, match } = getState();
  const mode = _get(match, 'params.dashboardName') ? FORM_MODES.EDIT : FORM_MODES.CREATE;

  const leftPanelLoader = _get(params, 'leftPanelLoading', true);
  setState({ loading: true, leftPanelLoading: leftPanelLoader, mode });

  const response = await fetchWidgets();
  const listData = createListData(tget(response, 'hits', EMPTY_ARRAY));

  setState({
    data: listData,
    loading: false,
  });

  if (mode === FORM_MODES.CREATE) {
    setState({ leftPanelLoading: false });
  }

  if (_isEmpty(_get(formValues, 'name')) && mode !== FORM_MODES.EDIT) {
    setState({
      isEditDashboardModalVisible: true,
    });
  }
};

const handleListWidgetItemAdd = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { widgetItems = [], layoutsByName = {} } = getState();
  const { isWidgetUpdated = false, name } = params;
  const widgetNames = _map(widgetItems, (widgetItem) => widgetItem.widgetName);

  if (!isWidgetUpdated && _includes(widgetNames, name)) {
    toaster(TOASTER_TYPE.ERROR, __('Widget Is Already Added In Dashboard.'));
    return;
  }

  const { updatedWidgetItems, updatedLayoutByName } = updateItemLayoutOnAddWidget({
    widgetItems,
    layoutsByName,
    widgetName: name,
  });

  setState({
    widgetItems: updatedWidgetItems,
    layoutsByName: _keyBy(updatedLayoutByName, 'i'),
  });
};

const handleRemoveWidget = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { widgetItems = [] } = getState();
  const { widgetName } = params;
  const updatedWidgetItems = _filter(widgetItems, (item) => item.widgetName !== widgetName);

  setState({
    widgetItems: updatedWidgetItems,
  });
};

const handleCreateDashboard = async ({ getState }) => {
  const { formValues = EMPTY_OBJECT, layoutsByName, mode, dashboard, history } = getState();
  const updatedLayout = _valuesIn(layoutsByName);
  const layouts = getGridUpdateRequestBody(updatedLayout);
  const name = _get(formValues, 'name');
  const displayName = _get(formValues, 'displayName');

  if (_isEmpty(name)) {
    toaster(TOASTER_TYPE.ERROR, __('Dashboard name cannot be empty. Open the dashboard edit modal and add dashboard name'));
    return;
  }

  if (_isEmpty(displayName)) {
    toaster(TOASTER_TYPE.ERROR, __('Dashboard display name cannot be empty. Open the dashboard edit modal and add dashboard display name'));
    return;
  }

  const requestBody = {
    dashboardType: DASHBOARD_TYPES.USER_CREATED,
    name,
    displayName,
    widgetLayouts: layouts,
  };
  let response;

  if (mode !== FORM_MODES.EDIT) {
    response = await createDashboard(requestBody);
  } else {
    const updatePayload = {
      ...dashboard,
      name,
      displayName,
      widgetLayouts: layouts,
    };

    response = await updateDashboard(dashboard?.name, updatePayload);
  }

  if (!_isEmpty(response)) {
    const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTING}/${PAGE_IDS.DASHBOARDS}`;
    history.push(pathname);
  }
};

const handleCreateWidget = ({ params = EMPTY_OBJECT, setState }) => {
  setState({ isAddWidgetModalVisible: true, widgetType: params });
};

const handleEditWidget = ({ params = EMPTY_OBJECT, setState }) => {
  const { widgetName, widgetType } = params;

  setState({ isAddWidgetModalVisible: true, widgetType, widgetToEdit: widgetName });
};

const updatedWidget = async ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { name = EMPTY_STRING, isWidgetUpdated = false } = params;
  if (!isWidgetUpdated) {
    await handleInit({ params: { leftPanelLoading: false }, getState, setState });
    handleListWidgetItemAdd({ params, getState, setState });
  } else {
    setState({ loading: false, updatedWidget: name }, () => {
      setState({ updatedWidget: '' });
    });
  }
};

const handleCloseModal = ({ params = EMPTY_OBJECT, setState, getState }) => {
  const { name, isWidgetUpdated = false } = params;

  setState({ isAddWidgetModalVisible: false, widgetType: EMPTY_STRING, widgetToEdit: EMPTY_STRING });

  if (name) {
    setState({ loading: true }, () => updatedWidget({ params: { name, isWidgetUpdated }, getState, setState }));
  }
};

const handleUpdateGridLayout = ({ params = EMPTY_OBJECT, setState }) => {
  const updatedLayout = _get(params, 'value');

  setState({ layoutsByName: _keyBy(updatedLayout, 'i') });
};

const handleEditDashboard = ({ setState }) => {
  setState({ isEditDashboardModalVisible: true });
};

const handleCloseDashboardModal = ({ setState }) => {
  setState({ isEditDashboardModalVisible: false });
};

const handleSaveDashboardModal = async ({ setState }) => {
  setState({ isEditDashboardModalVisible: false });
};

const handleOnFieldChange = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { formValues } = getState();
  const newValues = {
    ...formValues,
    [id]: value,
  };

  setState({ formValues: newValues });
};

const handleOpenDashboardDrawer = ({ setState }) => {
  setState({ openDrawer: true });
};

const handleCloseDashboardDrawer = ({ setState }) => {
  setState({ openDrawer: false });
};

const handleInitDashboard = async ({ getState, setState }) => {
  const { match, formValues = EMPTY_OBJECT } = getState();
  const dashboardName = _get(match, 'params.dashboardName');

  setState({ loading: true, leftPanelLoading: true });

  const dashboard = await fetchDashboard(dashboardName);
  const widgetLayouts = _get(dashboard, 'widgetLayouts', EMPTY_ARRAY);
  const layouts = transformResponseBodyToGridLayout(widgetLayouts);
  const layoutsByName = _keyBy(layouts, 'i');
  const widgetItems = getWidgetItems(widgetLayouts);

  setState({
    dashboard,
    widgetItems,
    layoutsByName,
    leftPanelLoading: false,
    formValues: {
      ...formValues,
      [EDIT_DASHBOARD_COMPONENT_FIELDS.DASHBOARD_NAME]: dashboardReader.name(dashboard),
      [EDIT_DASHBOARD_COMPONENT_FIELDS.DISPLAY_NAME]: dashboardReader.displayName(dashboard),
    },
    haveDashboardEditAccess: true,
  });
};

const handleCancelDashboardCreate = async ({ getState }) => {
  const { history } = getState();
  history.goBack();
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: handleInit,
  [ACTION_TYPES.CLOSE_MODAL]: handleCloseModal,
  [ACTION_TYPES.EDIT_WIDGET]: handleEditWidget,
  [ACTION_TYPES.REMOVE_WIDGET]: handleRemoveWidget,
  [ACTION_TYPES.CREATE_DASHBOARD]: handleCreateDashboard,
  [ACTION_TYPES.EDIT_DASHBOARD]: handleEditDashboard,
  [ACTION_TYPES.CLOSE_EDIT_DASHBOARD_MODAL]: handleCloseDashboardModal,
  [ACTION_TYPES.SAVE_EDIT_DASHBOARD_MODAL]: handleSaveDashboardModal,
  [ACTION_TYPES.CLOSE_DASHBOARD_DRAWER]: handleCloseDashboardDrawer,
  [ACTION_TYPES.OPEN_DASHBOARD_DRAWER]: handleOpenDashboardDrawer,
  [ACTION_TYPES.INIT_DASHBOARD]: handleInitDashboard,
  [ACTION_TYPES.CANCEL_DASHBOARD_CREATE]: handleCancelDashboardCreate,
  [ACTION_TYPES.LIST_WIDGET_ITEM_ADD]: handleListWidgetItemAdd,
  [ACTION_TYPES.CREATE_WIDGET]: handleCreateWidget,
  [ACTION_TYPES.UPDATE_GRID_LAYOUT]: handleUpdateGridLayout,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnFieldChange,
};

export default ACTION_HANDLERS;
