import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _snakeCase from 'lodash/snakeCase';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { updateTemplate, getTemplateByName, createTemplate } from '../../../../../../actions/template.actions';
import { getTemplateData } from './templateBuilderForm.helpers';
import { getUpdatedImageTagInHtml } from '../../../../../../utils/template.utils';
import { FIELD_IDS } from './templateBuilderForm.fields';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import { FIELD_IDS as TEMPLATE_BUILDER_FIELD_IDS, TEMPLATE_TYPES } from '../../../../../../constants/templateBuilder.constants';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/templateBuilderForm.actionTypes';
import { DRAFT, FREEMARKER } from '../constants/templateBuilderForm.general.constants';

const setInitialTemplateFormValues = async ({ getState, setState }) => {
  const { location, match } = getState();
  const templateName = _get(match, 'params.templateName');

  if (_isEmpty(templateName)) {
    setState({ formMode: FORM_MODES.CREATE });
    return;
  }

  setState({ isDataLoading: true });
  let templateData = _get(location, 'state.templateInfo');

  if (_isEmpty(templateData)) {
    templateData = await getTemplateByName(templateName);
  }
  setState({
    templateData: getTemplateData(templateData),
    isDataLoading: false,
    formMode: FORM_MODES.EDIT,
  });
};

const handleFieldChange = ({ getState, setState, params }) => {
  const { formMode, disableAutoFillForName = false, templateData } = getState();
  const { id, value } = params;

  let newTemplateData = {
    ...templateData,
    [id]: value,
  };
  if (id === FIELD_IDS.TEMPLATE_TYPE) {
    newTemplateData = { ...newTemplateData, [FIELD_IDS.JSON_BODY]: undefined };
  }
  if (formMode === FORM_MODES.CREATE && id === FIELD_IDS.DISPLAY_NAME && !disableAutoFillForName) {
    newTemplateData = {
      ...newTemplateData,
      [FIELD_IDS.NAME]: _snakeCase(value),
    };
  } else if (id === FIELD_IDS.NAME) {
    setState({ disableAutoFillForName: true });
  }

  setState({
    templateData: newTemplateData,
  });
};

const handleErrors = ({ setState, params, getState }) => {
  const { errors } = params;
  const { templateData = {} } = getState();
  const templateType = _get(templateData, 'templateType.0');

  if (templateType === TEMPLATE_TYPES.TEXT) {
    _set(errors, 'subject', undefined);
  }

  setState({ errors });
};

const handleRedirection = ({ getState }) => {
  const { history } = getState();

  const path = `${STUDIO_ROUTE}/${PAGE_IDS.TEMPLATE_BUILDER}`;
  history.push({ pathname: path });
};

const handleSubmit = async ({ getState, setState }) => {
  const { history, templateData, formMode } = getState();
  const jsonBody = JSON.stringify(_get(templateData, `${FIELD_IDS.JSON_BODY}.editorContent`));
  setState({ isSaveLoading: true });
  let body = _get(templateData, FIELD_IDS.BODY, EMPTY_STRING);
  const templateType = getArraySafeValue(_get(templateData, FIELD_IDS.TEMPLATE_TYPE));

  if (templateType === TEMPLATE_TYPES.EMAIL || templateType === TEMPLATE_TYPES.PUSHER) {
    body = getUpdatedImageTagInHtml(_get(templateData, `${FIELD_IDS.JSON_BODY}.htmlContent`)) || _get(templateData, FIELD_IDS.BODY);
  }

  let response = {};

  if (formMode === FORM_MODES.CREATE) {
    response = await createTemplate({
      ...templateData,
      [FIELD_IDS.TEMPLATE_TYPE]: getArraySafeValue(_get(templateData, `${FIELD_IDS.TEMPLATE_TYPE}`)),
      [FIELD_IDS.BODY]: body,
      systemcreated: true,
      templateEngine: FREEMARKER,
      templateStatus: DRAFT,
      jsonBody,
    });
  } else {
    const templateName = _get(templateData, TEMPLATE_BUILDER_FIELD_IDS.NAME);
    const payload = {
      ...templateData,
      [FIELD_IDS.TEMPLATE_TYPE]: getArraySafeValue(_get(templateData, FIELD_IDS.TEMPLATE_TYPE)),
      [FIELD_IDS.BODY]: body,
      [FIELD_IDS.JSON_BODY]: jsonBody || JSON.stringify(_get(templateData, FIELD_IDS.JSON_BODY)),
    };

    response = await updateTemplate(templateName, payload);
  }
  if (!_isEmpty(response)) {
    setTimeout(() => {
      history.push(`${STUDIO_ROUTE}/${PAGE_IDS.TEMPLATE_BUILDER}`);
    }, ES_REFETCH_DELAY);
  } else {
    setState({ isSaveLoading: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INITIALIZE_TEMPLATE_FORM_VALUES]: setInitialTemplateFormValues,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
};

export default ACTION_HANDLERS;
