// Validators
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

// Renderers
import TextInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import TextAreaField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import ConfigTable from '../components/configTable';

// Constants
import { FIELD_IDS, VIEW_TYPE_OPTIONS } from './createView.constants';

// Styles
import styles from '../createView.module.scss';

const detailsViewRowCheck = {
  [FIELD_IDS.APPLICABLE_RECORD_TYPES]: ({ isCurrentEntityRecordTypeEnabled }) => isCurrentEntityRecordTypeEnabled,
};

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.VIEW_DISPLAY_NAME,
  renderer: TextInputField,
  renderOptions: {
    label: __('Display Name'),
    placeHolder: __('Enter Display Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const NAME_FIELD = {
  id: FIELD_IDS.VIEW_NAME,
  renderer: TextInputField,
  renderOptions: {
    label: __('Name'),
    placeHolder: __('Enter name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const VIEW_TYPE_FIELD = {
  id: FIELD_IDS.VIEW_TYPE,
  renderer: SelectInput,
  renderOptions: {
    label: __('Type'),
    required: true,
    options: VIEW_TYPE_OPTIONS,
    validators: [isRequiredRule],
  },
};

const DESCRIPTION_FIELD = {
  id: FIELD_IDS.VIEW_DESCRIPTION,
  renderer: TextAreaField,
  renderOptions: {
    label: __('Description'),
    placeholder: __('Type Here'),
    rows: 8,
  },
};

const COLUMN_TABLE_FIELD = {
  id: FIELD_IDS.COLUMN_TABLE,
  renderer: ConfigTable,
  renderOptions: {
    required: false,
    label: __('Name'),
  },
};

const VIEW_FORM_FIELDS = {
  [FIELD_IDS.VIEW_DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.VIEW_NAME]: NAME_FIELD,
  [FIELD_IDS.VIEW_TYPE]: VIEW_TYPE_FIELD,
  [FIELD_IDS.VIEW_DESCRIPTION]: DESCRIPTION_FIELD,
  [FIELD_IDS.COLUMN_TABLE]: COLUMN_TABLE_FIELD,
};

const getDetailsViewFormSections = (isCurrentEntityRecordTypeEnabled) => [
  {
    className: styles.formSection,
    header: {
      label: __('View Details'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [FIELD_IDS.VIEW_DISPLAY_NAME, FIELD_IDS.VIEW_NAME],
        className: styles.halfColumn,
      },
      {
        columns: [FIELD_IDS.VIEW_TYPE],
        className: styles.halfColumn,
      },
      {
        columns: filterRows([FIELD_IDS.APPLICABLE_RECORD_TYPES], { isCurrentEntityRecordTypeEnabled }, detailsViewRowCheck),
        className: styles.halfColumn,
      },
      {
        columns: [FIELD_IDS.VIEW_DESCRIPTION],
        className: styles.halfColumn,
      },
    ],
  },
];

const LIST_VIEW_FORM_SECTIONS = [
  {
    className: styles.formSection,
    header: {
      label: __('Choose and reorder columns'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [FIELD_IDS.COLUMN_TABLE],
      },
    ],
  },
];

export { VIEW_FORM_FIELDS, getDetailsViewFormSections, LIST_VIEW_FORM_SECTIONS };
