import _set from 'lodash/set';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _reduce from 'lodash/reduce';
import _isObject from 'lodash/isObject';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import _map from 'lodash/map';

import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { pascalCase } from '../../../../../helpers/general.helpers';

import { CUSTOM_STYLES_FIELD_IDS, FIELD_IDS } from '../constants/customStylesConfigurator.constants';
import { CUSTOM_STYLE_LABEL_MAP, CUSTOM_STYLE_TYPES } from '../../../../../constants/customStyles.constants';
import { COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP } from '../../../constants/viewBuilder.constants';

import customStylesReader from '../../../../../readers/customStyles.reader';

const getSelectedCustomStyles = (allFormValues) => {
  const selectedCustomStyleTitles = [];
  const selectedCustomStyleTypes = [];

  _forEach(
    allFormValues,
    (currentFormValue) => {
      const customStyleType = customStylesReader.customStyleType(currentFormValue);
      if (!_isEmpty(customStyleType)) {
        selectedCustomStyleTitles.push(pascalCase(customStyleType));
        selectedCustomStyleTypes.push(customStyleType);
      }
    },
    [],
  );
  return { selectedCustomStyleTitles, selectedCustomStyleTypes };
};

const getFlattenedCustomStyleConfiguration = (customStyleConfiguration) =>
  _reduce(
    customStyleConfiguration,
    (prevConfiguration, currentConfiguration, configurationKey) => {
      if (_isObject(currentConfiguration) && configurationKey !== FIELD_IDS.VALUE) {
        const flattenedConfiguration = getFlattenedCustomStyleConfiguration(currentConfiguration);
        return { ...prevConfiguration, ...flattenedConfiguration };
      }

      return { ...prevConfiguration, [configurationKey]: currentConfiguration };
    },
    {},
  );

const getFormattedCustomStyleConfiguration = (customStyleConfiguration) =>
  _reduce(
    customStyleConfiguration,
    (prevConfiguration, currentConfiguration, configurationKey) => {
      if (_includes(CUSTOM_STYLES_FIELD_IDS, configurationKey)) {
        _set(prevConfiguration, [configurationKey], currentConfiguration);
      } else {
        _set(prevConfiguration, [FIELD_IDS.METADATA, configurationKey], currentConfiguration);
      }
      return prevConfiguration;
    },
    {},
  );

const getCustomStyleConfigurationFormValue = (allFormValues, selectedModuleIndex) => {
  const currentFormValue = _cloneDeep(allFormValues[selectedModuleIndex]) || {};

  const type = _get(currentFormValue, [FIELD_IDS.TYPE]);
  if (type === CUSTOM_STYLE_TYPES.SCRIPT) {
    _set(currentFormValue, [FIELD_IDS.CODE_PANEL], true);
  }
  return currentFormValue;
};

const filterFormValueForDeletedTab = (allFormValues, moduleIndex) => {
  const newAllFormValues = _filter(allFormValues, (customStyleConfig, index) => moduleIndex !== index);
  return _cloneDeep(newAllFormValues);
};

const checkDeletedModuleIsSelectedAndLastModule = (moduleIndex, selectedModuleIndex, newAllFormValues) =>
  moduleIndex === selectedModuleIndex && moduleIndex === _size(newAllFormValues) - 1 && moduleIndex >= 1;

const getOmittedFieldIds = (type) => {
  let omittedFieldIds = [];

  if (type === CUSTOM_STYLE_TYPES.SCRIPT) {
    omittedFieldIds = [FIELD_IDS.VALUE, FIELD_IDS.ERROR_MESSAGE];
  } else if (type === CUSTOM_STYLE_TYPES.STYLES_OBJECT) {
    omittedFieldIds = [FIELD_IDS.VALUE, FIELD_IDS.SCRIPT, FIELD_IDS.ERROR_MESSAGE];
  }

  return omittedFieldIds;
};

const getCustomStyleOptionsForComponentType = (componentType) => {
  const customStyleIdsForComponentType = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[componentType] || [];

  const customStyleOptions = _map(customStyleIdsForComponentType, (customStyleId) => ({
    label: CUSTOM_STYLE_LABEL_MAP[customStyleId],
    value: customStyleId,
  }));

  return customStyleOptions || [];
};

const getFormFormattedCustomStyle = (customStyle) => {
  let updatedCustomStyle = customStyle;
  if (!_isEmpty(customStyle)) {
    updatedCustomStyle = JSON.stringify({ ...updatedCustomStyle }, undefined, 4);
  }
  return updatedCustomStyle;
};

const getConfigFormattedCustomStyle = (customStyle) => {
  let updatedValue = customStyle;

  if (!_isEmpty(updatedValue)) {
    updatedValue = JSON.parse(updatedValue);
  }

  return updatedValue;
};

const getBeautifyFormatJsonString = (customStyle) => {
  try {
    const updatedValue = JSON.stringify(JSON.parse(customStyle), undefined, 4);
    return updatedValue;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, 'Failed to Parse, Script is Invalid');
    return customStyle;
  }
};

export {
  pascalCase,
  getSelectedCustomStyles,
  getFormattedCustomStyleConfiguration,
  getFlattenedCustomStyleConfiguration,
  filterFormValueForDeletedTab,
  checkDeletedModuleIsSelectedAndLastModule,
  getOmittedFieldIds,
  getCustomStyleOptionsForComponentType,
  getCustomStyleConfigurationFormValue,
  getFormFormattedCustomStyle,
  getConfigFormattedCustomStyle,
  getBeautifyFormatJsonString,
};
