import _map from 'lodash/map';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import TextInputRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';
import RadioRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio/Radio';
import TextAreaField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';

import RecordGroupAsyncSelect from '../../../../../molecules/recordGroupAsyncSelect';
import RecordSharingRuleAssignmentFieldRenderer from '../../recordSharingRuleAssignmentFieldRenderer';
import { PERMISSION_RADIO_LABEL_MAP, PERMISSION_RADIO_OPTIONS } from './recordSharingRuleAssignModal.general';
import { FIELD_IDS } from '../../../../../../../constants/recordSharingRule.constants';

import styles from '../recordSharingRuleAssignModal.module.scss';

const createRadio = (option) => ({
  label: PERMISSION_RADIO_LABEL_MAP[option],
  value: option,
});

const RECORD_GROUP_RULE_NAME_FIELD = {
  id: FIELD_IDS.RECORD_GROUP_RULE_NAME,
  renderer: TextInputRenderer,
  renderOptions: {
    required: true,
    label: __('Name'),
    validators: [isRequiredRule],
  },
};
const RECORD_GROUP_RULE_DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.RULE_DISPLAY_NAME,
  renderer: TextInputRenderer,
  renderOptions: {
    required: true,
    label: __('Display Name'),
    validators: [isRequiredRule],
  },
};
const RECORD_GROUP_RULE_DESCRIPTION_FIELD = {
  id: FIELD_IDS.RULE_DESCRIPTION,
  renderer: TextAreaField,
  renderOptions: {
    label: __('Description'),
  },
};

const ENTITY_RECORD_GROUP_FIELD = {
  id: FIELD_IDS.ENTITY_RECORD_GROUP,
  renderer: RecordGroupAsyncSelect,
  renderOptions: {
    required: true,
    label: __('Record Group'),
    validators: [isRequiredRule],
  },
};

const PERMISSION_FIELD = {
  id: FIELD_IDS.PERMISSION,
  renderer: RadioRenderer,
  renderOptions: {
    label: __('Permission'),
    required: true,
    validators: [isRequiredRule],
    radios: _map(PERMISSION_RADIO_OPTIONS, createRadio),
    labelClassName: 'm-b-8',
  },
};
const RULE_ASSIGNMENT_FIELD = {
  id: FIELD_IDS.USERS,
  renderer: RecordSharingRuleAssignmentFieldRenderer,
  renderOptions: {
    containerClassname: `${fieldLayoutStyles.fieldC} ${styles.assignFieldContainer}`,
    classname: styles.assignFieldContainer,
  },
};

export {
  RECORD_GROUP_RULE_NAME_FIELD,
  ENTITY_RECORD_GROUP_FIELD,
  PERMISSION_FIELD,
  RULE_ASSIGNMENT_FIELD,
  RECORD_GROUP_RULE_DISPLAY_NAME_FIELD,
  RECORD_GROUP_RULE_DESCRIPTION_FIELD,
};
