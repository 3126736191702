import _get from 'lodash/get';
import _size from 'lodash/size';
import _castArray from 'lodash/castArray';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import { editWWDPermissionData, fetchWwdPermissionList } from '../../../../../actions/wwdPermissionsManagement.actions';
import { fetchEntities } from '../../../../../actions/entityManagement.actions';
import { getEntityPayload, getPayload } from './wwdPermissionList.helpers';
import ACTION_TYPES from '../constants/wwdPermissionList.actionTypes';
import { COLUMN_IDS, TABLE_CONSTANTS } from '../constants/wwdPermissionList.constants';
import entityReader from '../../../../../readers/entity.reader';

const handleInit = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { pageSize = TABLE_CONSTANTS.PAGE_SIZE, searchText } = getState();
  const currentPageToken = _get(params, 'nextPageToken');
  const payload = getPayload(searchText, pageSize, currentPageToken);

  setState({ loading: true });
  const wwdPermissionsDataResponse = await fetchWwdPermissionList(payload);
  let wwdPermissionList = tget(wwdPermissionsDataResponse, 'hits', EMPTY_ARRAY);
  const entityPayload = getEntityPayload(wwdPermissionList, pageSize);
  const entityData = await fetchEntities(entityPayload);
  const entityDisplayMapper = _reduce(
    tget(entityData, 'hits', EMPTY_ARRAY),
    (result, dataValue) => ({ ...result, [entityReader.name(dataValue)]: entityReader.displayName(dataValue) }),
    {},
  );

  wwdPermissionList = _map(wwdPermissionList, (data) => ({
    ...data,
    entityDisplayName: tget(entityDisplayMapper, _get(data, 'entityName'), _get(data, 'entityName')),
  }));

  setState({
    loading: false,
    totalCount: _size(wwdPermissionList),
    wwdPermissionList,
    filteredPermissionData: wwdPermissionList,
  });
};

const handleSearchApply = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const searchText = _get(params, 'value');
  const currentPage = TABLE_CONSTANTS.CURRENT_PAGE;
  setState(
    {
      searchText,
      currentPage,
    },
    () => {
      handleInit({ getState, setState });
    },
  );
};

const handlePageUpdate = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  let pageNo = _get(params, 'value.page');
  const resultsPerPage = _get(params, 'value.resultsPerPage');
  let prevPageTokens = [...(previousPageTokens || [])];
  let currentPageToken = null;

  if (pageNo > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (pageNo === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }

  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleInit({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const handleRefereshListView = ({ getState, setState }) => {
  setState({ currentPage: TABLE_CONSTANTS.CURRENT_PAGE }, () => {
    handleInit({ getState, setState });
  });
};

const handleOpenWWDPermissionModal = ({ setState, params = EMPTY_OBJECT }) => {
  const wwdPermissionFormValues = { ...params, accessType: _castArray(_get(params, 'accessType', EMPTY_STRING)) };
  setState({ isWWDPermissionEditModalVisible: true, wwdPermissionFormValues });
};

const handleRowClick = ({ setState, params = EMPTY_OBJECT }) => {
  const wwdPermissionFormValues = _get(params, 'value.original', EMPTY_OBJECT);
  handleOpenWWDPermissionModal({ setState, params: wwdPermissionFormValues });
};

const handleCloseWWDPermissionModal = ({ setState }) => {
  setState({ isWWDPermissionEditModalVisible: false, wwdPermissionFormValues: {} });
};

const handleFieldChange = ({ setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  setState((state) => ({
    wwdPermissionFormValues: {
      ...state?.wwdPermissionFormValues,
      [id]: value,
    },
  }));
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;
  setState({ errors });
};

const handleModalwwdPermissionFormSubmit = async ({ getState, setState }) => {
  const { wwdPermissionFormValues } = getState();
  setState({ isWWDPermissionEditModalSubmitting: true });
  const wwdPermissionEntityName = _get(wwdPermissionFormValues, 'entityName', EMPTY_STRING);

  const payload = {
    [COLUMN_IDS.ENTITY_NAME]: _get(wwdPermissionFormValues, COLUMN_IDS.ENTITY_NAME),
    [COLUMN_IDS.ROLE_HIERARCHY]: _get(wwdPermissionFormValues, COLUMN_IDS.ROLE_HIERARCHY),
    accessType: getArraySafeValue(_get(wwdPermissionFormValues, 'accessType')),
  };
  await editWWDPermissionData(wwdPermissionEntityName, payload);
  setState({ isWWDPermissionEditModalVisible: false, isWWDPermissionEditModalSubmitting: false }, () => {
    handleInit({ getState, setState });
  });
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleModalwwdPermissionFormSubmit,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInit,
  [ACTION_TYPES.WWD_PERMISSION_EDIT]: handleOpenWWDPermissionModal,
  [ACTION_TYPES.ON_CLOSE_WWD_PERMISSION_EDIT_MODAL]: handleCloseWWDPermissionModal,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchApply,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleRefereshListView,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleRowClick,
};

export default ACTION_HANDLERS;
