import _join from 'lodash/join';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

const tenantUserReader = {
  NAME: (user = EMPTY_OBJECT) => _join([user.firstName, user.lastName], ' '),
  firstName: (user = EMPTY_OBJECT) => _get(user, 'firstName'),
  lastName: (user = EMPTY_OBJECT) => _get(user, 'lastName'),
  phone: (user = EMPTY_OBJECT) => _get(user, 'phone'),
  countryCode: (user = EMPTY_OBJECT) => _get(user, 'countryCode'),
  email: (user = EMPTY_OBJECT) => _get(user, 'email'),
  homeWorkspaceId: (user = EMPTY_OBJECT) => _get(user, 'homeWorkspaceId'),
  workspaceSpecificInfos: (user = EMPTY_OBJECT) => _get(user, 'workspaceSpecificInfos', EMPTY_ARRAY),
  displayName: (user = EMPTY_OBJECT) => _get(user, 'displayName'),
  active: (user = EMPTY_OBJECT) => _get(user, 'active'),
  lockUser: (user = EMPTY_OBJECT) => _get(user, 'lockUser'),
  profilePicture: (user = EMPTY_OBJECT) => _get(user, 'profilePicture'),
  password: (user = EMPTY_OBJECT) => _get(user, 'password', ''),
  roleName: (workspace = EMPTY_OBJECT) => _get(workspace, 'roleName', '-'),
  profileName: (workspace = EMPTY_OBJECT) => _get(workspace, 'profileName'),
  workspaceId: (workspace = EMPTY_OBJECT) => _get(workspace, 'workspaceId'),
};

export default tenantUserReader;
