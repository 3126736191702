import _castArray from 'lodash/castArray';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _entries from 'lodash/entries';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _unset from 'lodash/unset';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { ACTION_DEFINITION_FIELD_IDS, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS } from '../../../../../../constants/actionBuilder.constants';
import { ACTION_BUILDER_ACTION_TYPES, USER_INPUT_TYPES } from '../constants/actionBuilderForm.general';
import { VALIDATION_OVERRIDE_FIELD_IDS } from '../organisms/validationOverrideSection/validationOverrideSection.constants';
import VALIDATION_OVERRIDE_RULE_FIELD_IDS from '../organisms/validationOverrideSection/validationRulesRenderer/validationRulesRenderer.fieldIds';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import { SEND_EMAILS_ADDRESSEE_TYPE } from '../organisms/sendEmailsActionDefinitionSection/sendEmailsActionDefinitionSection.constants';
import { TEMPLATE_RENDERER_FIELD_IDS } from '../../../../../../organisms/templateSection/templateRenderer/templateRenderer.constant';
import { CONDITION_BUILDER_TYPES } from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';

const covertApiValidationRuleOverrideToFormValues = (data) => {
  const formData = {};

  const validationOverrides = tget(
    data,
    `${ACTION_DEFINITION_FIELD_IDS.VALIDATION_RULE_OVERRIDE}.${VALIDATION_OVERRIDE_FIELD_IDS.VALIDATION_OVERRIDES}`,
    EMPTY_ARRAY,
  );

  const validationRuleOverrideFormData = _reduce(
    validationOverrides,
    (result, rule) => {
      const viewName = _get(rule, VALIDATION_OVERRIDE_RULE_FIELD_IDS.FORM_VIEW_NAME, EMPTY_STRING);
      const selectedFields = _get(rule, VALIDATION_OVERRIDE_RULE_FIELD_IDS.SELECTED_FIELDS, EMPTY_ARRAY);
      const selectedFieldsArray = _map(selectedFields, 'fieldName');
      if (!_isEmpty(viewName)) {
        _set(result, _get(rule, VALIDATION_OVERRIDE_RULE_FIELD_IDS.RULE_NAME), {
          [VALIDATION_OVERRIDE_RULE_FIELD_IDS.IS_MESSAGE_OVERRIDEN]: true,
          [VALIDATION_OVERRIDE_RULE_FIELD_IDS.USER_INPUT]: true,
          [VALIDATION_OVERRIDE_RULE_FIELD_IDS.USER_INPUT_TYPE]: USER_INPUT_TYPES.FORM,
          [VALIDATION_OVERRIDE_RULE_FIELD_IDS.FORM_VIEW_NAME]: _castArray(viewName),
          ..._pick(rule, [VALIDATION_OVERRIDE_RULE_FIELD_IDS.ERROR_MESSAGE]),
        });
      } else if (!_isEmpty(selectedFields)) {
        _set(result, _get(rule, VALIDATION_OVERRIDE_RULE_FIELD_IDS.RULE_NAME), {
          [VALIDATION_OVERRIDE_RULE_FIELD_IDS.IS_MESSAGE_OVERRIDEN]: true,
          [VALIDATION_OVERRIDE_RULE_FIELD_IDS.USER_INPUT]: true,
          [VALIDATION_OVERRIDE_RULE_FIELD_IDS.USER_INPUT_TYPE]: USER_INPUT_TYPES.FIELD,
          [VALIDATION_OVERRIDE_RULE_FIELD_IDS.SELECTED_FIELDS]: selectedFieldsArray,
          ..._pick(rule, [VALIDATION_OVERRIDE_RULE_FIELD_IDS.ERROR_MESSAGE]),
        });
      } else {
        _set(result, _get(rule, VALIDATION_OVERRIDE_RULE_FIELD_IDS.RULE_NAME), {
          [VALIDATION_OVERRIDE_RULE_FIELD_IDS.IS_MESSAGE_OVERRIDEN]: true,
          ..._pick(rule, [VALIDATION_OVERRIDE_RULE_FIELD_IDS.ERROR_MESSAGE]),
        });
      }
      return result;
    },
    {},
  );

  _set(formData, VALIDATION_OVERRIDE_FIELD_IDS.VALIDATION_OVERRIDES, validationRuleOverrideFormData);

  return formData;
};

const convertApiSendEmailsToFormValues = (data) => {
  const sendEmailsFormData = _pick(data, [SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.ADDRESSEE_TYPE, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.FROM]);
  const addresseeType = _get(data, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.ADDRESSEE_TYPE);

  if (addresseeType === SEND_EMAILS_ADDRESSEE_TYPE.DERIVED) {
    const sourceField = _castArray(_get(data, `emailAddresseeConfig.${SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.SOURCE_FIELD}`));
    const derivedEntity = _get(data, `emailAddresseeConfig.${SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY}`);
    const derviedEntityField = _castArray(_get(data, `emailAddresseeConfig.${SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY_FIELD}`));

    _set(sendEmailsFormData, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.SOURCE_FIELD, sourceField);
    _set(sendEmailsFormData, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY, derivedEntity);
    _set(sendEmailsFormData, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY_FIELD, derviedEntityField);
  } else if (addresseeType === SEND_EMAILS_ADDRESSEE_TYPE.STATIC) {
    const toValue = _get(data, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.TO);
    _set(sendEmailsFormData, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.TO, toValue);
  }

  const apiTemplateValue = _get(data, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES, EMPTY_OBJECT);
  const templateFormValue = _map(apiTemplateValue, (templateValue, key) => ({
    [TEMPLATE_RENDERER_FIELD_IDS.SELECTED_TEMPLATE]: key,
    [TEMPLATE_RENDERER_FIELD_IDS.MAP_OF_VARIABLES]: templateValue,
  }));

  _set(sendEmailsFormData, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES, templateFormValue);

  return sendEmailsFormData;
};

const convertApiDataToFormValues = (data) => {
  const apiActionTypeValue = _get(data, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE);
  _set(data, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE, _castArray(apiActionTypeValue));

  let apiRecordsToUpdate = {};
  if (apiActionTypeValue === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORDS) {
    apiRecordsToUpdate = _get(data, 'recordsToUpdate', EMPTY_OBJECT);
  }
  if (apiActionTypeValue === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD) {
    apiRecordsToUpdate = _get(data, 'recordToUpdate', EMPTY_OBJECT);
  }

  const filterCondition = {};
  const apiFilterCondition = _get(apiRecordsToUpdate, 'condition');
  _set(filterCondition, CONDITION_BUILDER_FIELD_IDS.EXPRESSION, _get(apiFilterCondition, CONDITION_BUILDER_FIELD_IDS.EXPRESSION, '1'));
  let filterConditionCriteriaList = _get(apiFilterCondition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST);

  _forEach(filterConditionCriteriaList, (criteria) => {
    const fieldName = _get(criteria, 'fieldName');
    _set(criteria, 'fieldName', _castArray(fieldName));
  });

  if (_isEmpty(filterConditionCriteriaList)) {
    filterConditionCriteriaList = [EMPTY_OBJECT];
  }

  _set(filterCondition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, filterConditionCriteriaList);
  _set(
    filterCondition,
    CONDITION_BUILDER_FIELD_IDS.TYPE,
    _get(apiFilterCondition, CONDITION_BUILDER_FIELD_IDS.TYPE, CONDITION_BUILDER_TYPES.CRITERIA),
  );
  _set(data, ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS, filterCondition);

  const sortList = _get(apiRecordsToUpdate, 'sortList', EMPTY_ARRAY);
  if (!_isEmpty(sortList)) {
    _set(data, ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS, true);
    _set(data, ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS, sortList);
  }
  const apiPrefilledData = _get(data, ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION);
  const actionDefinationCriteriaList = _map(_entries(apiPrefilledData), ([key, value]) => ({
    fieldName: _castArray(key),
    values: value,
    operatorType: 'SET',
  }));
  const modifiedPrefilledData = { [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: actionDefinationCriteriaList };
  _set(data, ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION, modifiedPrefilledData);

  const apiManualInput = _get(data, ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS);
  if (!_isEmpty(apiManualInput)) {
    _set(data, ACTION_DEFINITION_FIELD_IDS.USER_INPUT, true);
    _set(data, ACTION_DEFINITION_FIELD_IDS.USER_INPUT_TYPE, USER_INPUT_TYPES.FIELD);
    _set(
      data,
      ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS,
      _map(apiManualInput, (field) => _get(field, 'fieldName')),
    );
  }

  const apiViewName = _get(data, ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME);
  if (!_isEmpty(apiViewName)) {
    _set(data, ACTION_DEFINITION_FIELD_IDS.USER_INPUT, true);
    _set(data, ACTION_DEFINITION_FIELD_IDS.USER_INPUT_TYPE, USER_INPUT_TYPES.FORM);
    _set(data, ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME, _castArray(apiViewName));
  }

  if (apiActionTypeValue !== ACTION_BUILDER_ACTION_TYPES.SEND_EMAILS && apiActionTypeValue !== ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL) {
    const apiTargetEntityName = _get(data, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME);
    _set(data, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME, _castArray(apiTargetEntityName));
  }

  const apiEntryCondition = _get(data, ACTION_DEFINITION_FIELD_IDS.ENTRY_CONDITION);
  const apiEntryConditionCriteriaList = _get(apiEntryCondition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST);
  _forEach(apiEntryConditionCriteriaList, (criteria) => {
    const fieldName = _get(criteria, 'fieldName');
    _set(criteria, 'fieldName', _castArray(fieldName));
  });

  if (_isEmpty(apiEntryCondition)) {
    _set(data, ACTION_DEFINITION_FIELD_IDS.ENTRY_CONDITION, [EMPTY_OBJECT]);
  }

  _set(data, ACTION_DEFINITION_FIELD_IDS.VALIDATION_RULE_OVERRIDE, covertApiValidationRuleOverrideToFormValues(data));

  if (apiActionTypeValue === ACTION_BUILDER_ACTION_TYPES.SEND_EMAILS) {
    _set(data, ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION, convertApiSendEmailsToFormValues(data));
    _unset(data, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.TO);
    _unset(data, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.FROM);
    _unset(data, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.ADDRESSEE_TYPE);
    _unset(data, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY);
    _unset(data, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY_FIELD);
    _unset(data, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES);
    _unset(data, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.SOURCE_FIELD);
  }

  return data;
};

const validateFormValues = (formValues) => {
  const shouldSortRecords = tget(formValues, ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS, false);
  const sortConditions = tget(formValues, ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS, [null]);
  const filteredSortConditions = _filter(sortConditions, (condition) => !_isEmpty(condition));

  if (shouldSortRecords && _isEmpty(filteredSortConditions)) {
    return {
      isValid: false,
      errorMessage: __('Please enter sort conditions, or disable the sort.'),
    };
  }

  return {
    isValid: true,
    errorMessage: '',
  };
};

export { convertApiDataToFormValues, covertApiValidationRuleOverrideToFormValues, validateFormValues };
