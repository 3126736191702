import React from 'react';
import PropTypes from 'prop-types';
import _isFunction from 'lodash/isFunction';
import _map from 'lodash/map';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import Button from '@tekion/tekion-components/atoms/Button';
import EntityMappingRow from '../entityMappingRow/EntityMappingRow';

import ACTION_HANDLERS, { ACTION_TYPES } from './helpers/entityMappingsList.actionHandlers';

const EntityMappingsList = (props) => {
  const { value: rows, error, tabOptions } = props;

  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload } = action;
    const handler = ACTION_HANDLERS[type];

    if (_isFunction(handler)) {
      handler({ props, params: payload });
    }
  };

  const handleAddClick = () => {
    const handler = ACTION_HANDLERS[ACTION_TYPES.ON_ADD_CLICK];

    if (_isFunction(handler)) {
      handler({ props });
    }
  };

  return (
    <>
      {_map(rows, (row, index) => (
        <EntityMappingRow key={index} index={index} value={row} error={error?.[index]} rows={rows} tabOptions={tabOptions} onAction={handleAction} />
      ))}
      <Button view={Button.VIEW.TERTIARY} onClick={handleAddClick}>
        {__('+ Add')}
      </Button>
    </>
  );
};

EntityMappingsList.propTypes = {
  value: PropTypes.array,
  error: PropTypes.array,
  tabOptions: PropTypes.array,
};

EntityMappingsList.defaultProps = {
  value: EMPTY_ARRAY,
  error: EMPTY_ARRAY,
  tabOptions: EMPTY_ARRAY,
};

export default EntityMappingsList;
