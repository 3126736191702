import React, { useCallback } from 'react';
import _noop from 'lodash/noop';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

const withAsyncSelectFilterWrapper = (WrappedComponent) => {
  const WithAsyncSelectFilterWrapper = (props) => {
    const renderProps = tget(props, 'additional.renderProps', EMPTY_OBJECT);
    const isDisabled = tget(props, 'additional.isDisabled', false);
    const onChange = tget(props, 'onChange', _noop);

    const handleAction = useCallback(
      (action) => {
        const { type, payload = EMPTY_OBJECT } = action;
        if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
          const value = tget(payload, 'value', EMPTY_STRING);
          onChange({ value });
        }
      },
      [onChange],
    );

    return <WrappedComponent {...props} {...renderProps} isDisabled={isDisabled} onAction={handleAction} />;
  };

  return WithAsyncSelectFilterWrapper;
};

export default withAsyncSelectFilterWrapper;
