import React, { useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components/molecules/loader';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';

import EntityCardView from '../../entityCardView';
import withSize from '../../../../../connectors/withSize';

// Helpers

import ACTION_HANDLERS from './helpers/fieldList.actionHandlers';
import { createTableProps } from './helpers/fieldList.helpers';

import { TABLE_MANAGER_PROPS, SUB_HEADER_PROPS } from './constants/fieldList.constants';
import { ACTION_TYPES } from './constants/fieldList.actionTypes';
import { COLUMN_CONFIG } from './constants/fieldList.columns';

import styles from './fieldList.module.scss';

const FieldList = ({ fieldDataLoading, fieldsData, pageSize, currentPage, entity, history, contentHeight, onAction }) => {
  const tableProps = useMemo(
    () => createTableProps(onAction, fieldDataLoading, currentPage, pageSize, fieldsData),
    [onAction, fieldDataLoading, currentPage, pageSize, fieldsData],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT_DATA });
  }, [onAction]);

  if (fieldDataLoading) {
    return <Loader />;
  }
  return (
    <>
      <EntityCardView entity={entity} history={history} onAction={onAction} />
      <div className={styles.tableConainer} style={{ height: contentHeight - 150 }}>
        <TableManager
          containerClassName={styles.tableContainer}
          subHeaderProps={SUB_HEADER_PROPS}
          tableProps={tableProps}
          tableManagerProps={TABLE_MANAGER_PROPS}
          columns={COLUMN_CONFIG}
          data={fieldsData}
          onAction={onAction}
        />
      </div>
    </>
  );
};

FieldList.propTypes = {
  fieldDataLoading: PropTypes.bool,
  fieldsData: PropTypes.array,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  contentHeight: PropTypes.number.isRequired,
  entity: PropTypes.object,
  history: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
};

FieldList.defaultProps = {
  fieldDataLoading: false,
  fieldsData: EMPTY_ARRAY,
  pageSize: DEFAULT_PAGE_INFO.rows,
  currentPage: DEFAULT_PAGE_INFO.start,
  entity: undefined,
};

export default compose(withSize({ hasPageHeader: true, hasPageFooter: 0 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(FieldList);
