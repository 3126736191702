import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import _sumBy from 'lodash/sumBy';
import _property from 'lodash/property';

// lodash
import _map from 'lodash/map';
import _min from 'lodash/min';

// constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// context
import { TableColumnSizeContextProvider } from './context/TableColumnSizeContext';

// hooks
import useColumnWidths, { DEFAULT_COLUMN_WIDTH } from './hooks/useColumnWidths';
import useHoveredColumnId from './hooks/useHoveredColumnId';

// helpers
import { getTableWidth } from './table.helpers';

function Table(props) {
  const tableRef = useRef(null);

  const { attributes, children, element, nodeProps } = props;

  const { hoveredColumnId, setHoveredColumnId, resetHoveredColumnId } = useHoveredColumnId();

  const { setResizingColumn, columnWidthsWithId } = useColumnWidths(element);

  const columnSizeContextValue = useMemo(
    () => ({ setResizingColumn, hoveredColumnId, setHoveredColumnId, resetHoveredColumnId }),
    [setResizingColumn, hoveredColumnId, setHoveredColumnId, resetHoveredColumnId],
  );

  const sumOfColumnsWidth = _sumBy(columnWidthsWithId, _property('width'));

  return (
    <TableColumnSizeContextProvider value={columnSizeContextValue}>
      <table
        {...attributes}
        {...nodeProps}
        style={{ display: 'block', maxWidth: 'full-width', width: 'fit-content', paddingRight: 4, borderCollapse: 'collapse' }}
        ref={tableRef}
      >
        <tbody style={{ width: _min(sumOfColumnsWidth, getTableWidth(tableRef)), display: 'table', tableLayout: 'fixed' }}>
          <colgroup contentEditable={false}>
            {_map(columnWidthsWithId, ({ width, id }) => (
              // eslint-disable-next-line react/no-array-index-key
              <col key={id} style={{ width: width || DEFAULT_COLUMN_WIDTH }} />
            ))}
          </colgroup>
          {children}
        </tbody>
      </table>
    </TableColumnSizeContextProvider>
  );
}

Table.propTypes = {
  attributes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  element: PropTypes.object.isRequired,
  nodeProps: PropTypes.object,
};

Table.defaultProps = {
  nodeProps: EMPTY_OBJECT,
};

export default React.memo(Table);
