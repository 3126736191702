import _set from 'lodash/set';
import _includes from 'lodash/includes';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { FIELD_IDS, SYSTEM_DEFINED_VARIABLES, VALUE_TYPES } from './variableModal.constants';
import { ASSIGNED_VARIABLE_FIELD_IDS, VARIABLE_TYPES } from '../../../constants/workflow.assignVariableConstants';

const getUserData = (formValues) => {
  const variableName = tget(formValues, FIELD_IDS.VARIABLE_NAME);
  const valueType = tget(formValues, FIELD_IDS.VALUE_TYPE);
  const userData = { [FIELD_IDS.VARIABLE_NAME]: variableName, [FIELD_IDS.VALUE_TYPE]: valueType };

  if (valueType === VALUE_TYPES.STATIC) {
    const dataType = tget(formValues, FIELD_IDS.DATA_TYPE);
    const variableValue = tget(formValues, FIELD_IDS.STATIC_VALUE);
    _set(userData, FIELD_IDS.DATA_TYPE, dataType);
    _set(userData, FIELD_IDS.STATIC_VALUE, getArraySafeValue(variableValue));
  } else {
    const derivedField = tget(formValues, FIELD_IDS.DERIVED_FIELD);
    _set(userData, FIELD_IDS.DERIVED_FIELD, derivedField);
  }
  return userData;
};

const getAssignedAndUsedVariableInfo = (formValues, variablesInfo, entity) => {
  const usedVariableInfo = [];
  const assignedVariableInfo = {};
  const valueType = tget(formValues, FIELD_IDS.VALUE_TYPE);
  const variableName = tget(formValues, FIELD_IDS.VARIABLE_NAME);
  _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_NAME, variableName);
  if (valueType === VALUE_TYPES.DERIVED) {
    const derivedField = tget(formValues, FIELD_IDS.DERIVED_FIELD);
    const derivedVariableName = derivedField.split('.')[0];
    if (!_includes(SYSTEM_DEFINED_VARIABLES, derivedVariableName)) {
      const derivedVariableInfo = tget(variablesInfo, derivedVariableName, {});
      const derivedVariableType = tget(derivedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE);
      const derivedVariableEntity = tget(derivedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME);
      _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE, derivedVariableType);
      _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME, derivedVariableEntity);
      usedVariableInfo.push(derivedVariableName);
    } else {
      _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE, VARIABLE_TYPES.ENTITY_RECORD);
      _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME, entity);
    }
  } else {
    const variableDataType = getArraySafeValue(tget(formValues, FIELD_IDS.DATA_TYPE, ['']));
    _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE, VARIABLE_TYPES.PRIMITIVE);
    _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_DATA_TYPE, variableDataType);
  }

  return { assignedVariableInfo, usedVariableInfo };
};

export { getUserData, getAssignedAndUsedVariableInfo };
