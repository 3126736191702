const ACTION_TYPES = {
  ON_INIT: 'ON_INIT',
  ON_ADD_COMMENT: 'ON_ADD_COMMENT',
  ON_FETCH_COMMENTS: 'ON_FETCH_COMMENTS',
  APPROVE_REQUEST: 'APPROVE_REQUEST',
  REJECT_REQUEST: 'REJECT_REQUEST',
  ON_CANCEL_APPROVAL_REQUEST_BUTTON_CLICK: 'ON_CANCEL_APPROVAL_REQUEST_BUTTON_CLICK',
  ON_CANCEL_CANCEL_APPROVAL_REQUEST_MODAL_CLICK: 'ON_CANCEL_CANCEL_APPROVAL_REQUEST_MODAL_CLICK',
  ON_CANCEL_CONFIRM_APPROVAL_REQUEST_MODAL_CLICK: 'ON_CANCEL_CONFIRM_APPROVAL_REQUEST_MODAL_CLICK',
};

export default ACTION_TYPES;
