import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _split from 'lodash/split';
import { COLUMN_TYPES } from '../../../constants/reporting.general.constants';

import widgetReader from '../../../../../readers/reportingWidget.reader';

const getColumnsFromWidget = (widget) => {
  const dimensions = widgetReader.dimensions(widget);
  const measurements = widgetReader.measurements(widget);
  const columns = [];
  _forEach(dimensions, (item) => {
    const column = {
      name: _get(item, 'name'),
      type: COLUMN_TYPES.DIMENSION,
      displayName: _get(item, 'displayName'),
      reportingDimension: {
        ...item,
        supportedFunctions: ['FIELD'],
      },
    };
    columns.push(column);
  });
  _forEach(measurements, (item) => {
    const column = {
      name: _get(item, 'name'),
      type: COLUMN_TYPES.METRIC,
      displayName: _get(item, 'displayName'),
      reportingMeasurement: {
        ...item,
      },
    };
    columns.push(column);
  });

  return columns;
};

const getFiltersToRender = (widget) => {
  const dimensions = widgetReader.dimensions(widget);
  const filters = [];
  _forEach(dimensions, (item) => {
    if (_get(item, 'filter') === true) {
      filters.push(item);
    }
  });
  return filters;
};

const getSimpleFieldNameFromColumn = (fieldName) => _head(_split(fieldName, '.'));

export { getColumnsFromWidget, getFiltersToRender, getSimpleFieldNameFromColumn };
