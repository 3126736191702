import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import { defaultMemoize } from 'reselect';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { tget } from '@tekion/tekion-base/utils/general';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import ConditionBuilder from '../../../../../../../../organisms/conditionBuilder/ConditionBuilder';

import {
  CONDITION_BUILDER_FIELD_IDS,
  CONDITION_BUILDER_MODES,
  CONDITION_BUILDER_TYPES,
  CONDITION_FIELD_IDS,
  isConditionRequiredRule,
} from '../../../../../../../../organisms/conditionBuilder';
import { expressionRequiredRule } from '../../../../../../../../utils/formValidators';
import { getChildEntities } from './childAggregateSummaryForm.general.helpers';

import CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS from '../constants/childAggregateSummaryForm.fieldIds';
import {
  FILTER_CRITERIA_FOR_CHILD_OPTIONS,
  FILTER_CRITERIA_FOR_PARENT_OPTIONS,
  ROLL_UP_FUNCTIONS,
  ROLL_UP_OPTIONS,
} from '../constants/childAggregateSummaryForm.general.constants';
import { NAMESPACES } from '../../../../../../../../constants/general.constants';

const CHILD_ENTITY_NAME_FIELD = {
  id: CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_ENTITY_NAME,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select child entity'),
    label: __('Child Entity'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const ROLL_UP_FUNCTION_FIELD = {
  id: CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.ROLL_UP_FUNCTION,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select function'),
    label: __('Child Aggregate Function'),
    size: 5,
    required: true,
    validators: [isRequiredRule],
    options: ROLL_UP_OPTIONS,
  },
};

const CHILD_RELATIONSHIP_FIELD = {
  id: CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_RELATIONSHIP,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Interlinking field'),
    label: __('Interlinking Field'),
    size: 5,
    required: true,
    validators: [isRequiredRule],
  },
};

const AGGREGATE_FIELD = {
  id: CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.AGGREGATE_FIELD,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select field'),
    label: __('Field To Aggregate'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const FILTER_CRITERIA_FOR_CHILD_FIELD = {
  id: CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.FILTER_CRITERIA_FOR_CHILD,
  renderer: Radio,
  renderOptions: {
    validators: [isRequiredRule],
    required: true,
    radios: FILTER_CRITERIA_FOR_CHILD_OPTIONS,
  },
};

const FILTER_CRITERIA_FOR_PARENT_FIELD = {
  id: CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.FILTER_CRITERIA_FOR_PARENT,
  renderer: Radio,
  renderOptions: {
    validators: [isRequiredRule],
    required: true,
    radios: FILTER_CRITERIA_FOR_PARENT_OPTIONS,
  },
};

const CHILD_AGGREGATE_SUMMARY_FORM_FIELDS = {
  [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_ENTITY_NAME]: CHILD_ENTITY_NAME_FIELD,
  [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.ROLL_UP_FUNCTION]: ROLL_UP_FUNCTION_FIELD,
  [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_RELATIONSHIP]: CHILD_RELATIONSHIP_FIELD,
  [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.AGGREGATE_FIELD]: AGGREGATE_FIELD,
  [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.FILTER_CRITERIA_FOR_CHILD]: FILTER_CRITERIA_FOR_CHILD_FIELD,
  [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.FILTER_CRITERIA_FOR_PARENT]: FILTER_CRITERIA_FOR_PARENT_FIELD,
};

const getConditionFields = defaultMemoize(
  (mapOfVariableToEntityNameForEntryConditionChild, conditionBuilderFieldDefinitionObjectChild, entity = EMPTY_OBJECT) => {
    const { mapOfVariableToEntityNameForEntryConditionParent = EMPTY_OBJECT, conditionBuilderFieldDefinitionObjectParent = EMPTY_OBJECT } = entity;

    return {
      [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_FILTER_CONDITION]: {
        id: CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_FILTER_CONDITION,
        renderer: ConditionBuilder,
        renderOptions: {
          validators: [expressionRequiredRule, isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)],
          required: true,
          containerClassname: fieldLayoutStyles.fieldC,
          mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
          conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
          childProps: {
            [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
              label: '',
              childProps: {
                [CONDITION_FIELD_IDS.FIELD]: {
                  label: __('Field'),
                  mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryConditionChild,
                  conditionBuilderFieldDefinitionObject: conditionBuilderFieldDefinitionObjectChild,
                  isVariablePrefixNeeded: false,
                  variableNameForPrefixNotNeeded: NAMESPACES.CURRENT_RECORD,
                },
                [CONDITION_FIELD_IDS.OPERATOR]: {
                  label: __('Operator'),
                  mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryConditionChild,
                  conditionBuilderFieldDefinitionObject: conditionBuilderFieldDefinitionObjectChild,
                  isVariablePrefixNeeded: false,
                  variableNameForPrefixNotNeeded: NAMESPACES.CURRENT_RECORD,
                },
                [CONDITION_FIELD_IDS.VALUES]: {
                  label: __('Value'),
                  mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryConditionChild,
                  conditionBuilderFieldDefinitionObject: conditionBuilderFieldDefinitionObjectChild,
                  isVariablePrefixNeeded: false,
                  variableNameForPrefixNotNeeded: NAMESPACES.CURRENT_RECORD,
                },
              },
            },
          },
        },
      },

      [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.UPDATE_CONDITION]: {
        id: CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.UPDATE_CONDITION,
        renderer: ConditionBuilder,
        renderOptions: {
          validators: [expressionRequiredRule, isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)],
          required: true,
          containerClassname: fieldLayoutStyles.fieldC,
          mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
          conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
          childProps: {
            [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
              label: '',
              childProps: {
                [CONDITION_FIELD_IDS.FIELD]: {
                  label: __('Field'),
                  mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryConditionParent,
                  conditionBuilderFieldDefinitionObject: conditionBuilderFieldDefinitionObjectParent,
                  isVariablePrefixNeeded: false,
                  variableNameForPrefixNotNeeded: NAMESPACES.CURRENT_RECORD,
                },
                [CONDITION_FIELD_IDS.OPERATOR]: {
                  label: __('Operator'),
                  mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryConditionParent,
                  conditionBuilderFieldDefinitionObject: conditionBuilderFieldDefinitionObjectParent,
                  isVariablePrefixNeeded: false,
                  variableNameForPrefixNotNeeded: NAMESPACES.CURRENT_RECORD,
                },
                [CONDITION_FIELD_IDS.VALUES]: {
                  label: __('Value'),
                  mapOfVariableToEntityName: mapOfVariableToEntityNameForEntryConditionParent,
                  conditionBuilderFieldDefinitionObject: conditionBuilderFieldDefinitionObjectParent,
                  isVariablePrefixNeeded: false,
                  variableNameForPrefixNotNeeded: NAMESPACES.CURRENT_RECORD,
                },
              },
            },
          },
        },
      },
    };
  },
);

const getFields = (entity, childEntityDefinitions, values) => {
  let childFieldDefinitions;
  let mapOfVariableToEntityNameForEntryConditionChild;
  let conditionBuilderFieldDefinitionObjectChild;

  const { childEntitiesOptions, childRelationFieldOptionsByEntity } = getChildEntities(entity);
  const entityName = getArraySafeValue(_get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_ENTITY_NAME));
  const aggregateFunction = getArraySafeValue(_get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.ROLL_UP_FUNCTION));

  if (!_isEmpty(entityName)) {
    childFieldDefinitions = _get(childEntityDefinitions, `${entityName}.childFieldDefinitions`);
    mapOfVariableToEntityNameForEntryConditionChild = _get(
      childEntityDefinitions,
      `${entityName}.mapOfVariableToEntityNameForEntryConditionChild`,
      EMPTY_OBJECT,
    );
    conditionBuilderFieldDefinitionObjectChild = _get(
      childEntityDefinitions,
      `${entityName}.conditionBuilderFieldDefinitionObjectChild`,
      EMPTY_OBJECT,
    );

    if (!_isEmpty(_get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.ROLL_UP_FUNCTION))) {
      if (getArraySafeValue(_get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.ROLL_UP_FUNCTION)) === ROLL_UP_FUNCTIONS.COUNT) {
        childFieldDefinitions = _filter(childFieldDefinitions, (field) => _get(field, 'name') === 'id');
      }
    }
  }

  return {
    ...CHILD_AGGREGATE_SUMMARY_FORM_FIELDS,
    [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_ENTITY_NAME]: addToRenderOptions(
      CHILD_AGGREGATE_SUMMARY_FORM_FIELDS[CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_ENTITY_NAME],
      [{ path: 'options', value: childEntitiesOptions }],
    ),
    [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_RELATIONSHIP]: addToRenderOptions(
      CHILD_AGGREGATE_SUMMARY_FORM_FIELDS[CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_RELATIONSHIP],
      [
        {
          path: 'options',
          value: _get(
            childRelationFieldOptionsByEntity,
            _get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_ENTITY_NAME, EMPTY_STRING),
            EMPTY_ARRAY,
          ),
        },
        { path: 'isDisabled', value: _isEmpty(entityName) || _isEmpty(aggregateFunction) },
      ],
    ),
    [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.AGGREGATE_FIELD]: addToRenderOptions(
      CHILD_AGGREGATE_SUMMARY_FORM_FIELDS[CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.AGGREGATE_FIELD],
      [
        {
          path: 'options',
          value: _map(childFieldDefinitions, (item) => ({
            label: tget(item, 'displayName', NO_DATA),
            value: _get(item, 'name'),
          })),
        },
        { path: 'isDisabled', value: _isEmpty(entityName) || _isEmpty(aggregateFunction) },
      ],
    ),
    ...getConditionFields(mapOfVariableToEntityNameForEntryConditionChild, conditionBuilderFieldDefinitionObjectChild, entity),
  };
};

export default getFields;
