import { RECORD_ACCESS_TYPES } from '../../../../constants/general.constants';

const ACTION_TYPES = {
  ON_MOUNT: 'ON_MOUNT',
  ON_CANCEL: 'ON_CANCEL',
  ON_REDIRECTION: 'ON_REDIRECTION',
};

const CONTEXT_ID = 'ENTITY_FORM';

const FIELD_IDS = {
  DISPLAY_NAME: 'displayName',
  DESCRIPTION: 'description',
  NAME: 'name',
  SEARCH_ENABLED: 'searchEnabled',
  LIST_VIEW_ENABLED: 'listViewEnabled',
  CREATE_ENABLED: 'createViewEnabled',
  EDIT_ENABLED: 'editEnabled',
  DETAIL_VIEW_ENABLED: 'detailViewEnabled',
  USER_API_ENABLED: 'userApiEnabled',
  AUDIT_ENABLED: 'auditEnabled',
  COMPLEX_FIELD: 'complexField',
  GROUPS_ENABLED: 'groupsEnabled',
  RECORD_TYPE_ENABLED: 'recordTypeEnabled',
  ENTITY_TYPE: 'type',
  ACCESS_TYPE: 'accessType',
  GRANT_ACCESS_USING_HIERARCHIES: 'grantAccessUsingHierarchies',
  RECORD_DEFINITION_TYPE: 'recordTypeDefinition.type',
  COMMENTS_ALLOWED: 'commentsAllowed',
  APPROVAL_AUTOMATION_ENABLED: 'approvalAutomationEnabled',
};

const ENTITY_TYPES = {
  CUSTOM: 'CUSTOM',
  EXTERNAL: 'EXTERNAL',
};

const ENTITY_TYPE_OPTIONS = [
  {
    label: __('Custom'),
    value: ENTITY_TYPES.CUSTOM,
  },
  {
    label: __('External'),
    value: ENTITY_TYPES.EXTERNAL,
  },
];

const ACCESS_TYPE_OPTIONS = [
  {
    label: __('Private'),
    value: RECORD_ACCESS_TYPES.PRIVATE,
  },
  {
    label: __('Public Read-Only'),
    value: RECORD_ACCESS_TYPES.PUBLIC_READONLY,
  },
  {
    label: __('Public Read Write'),
    value: RECORD_ACCESS_TYPES.PUBLIC_READ_WRITE,
  },
];

export { ACTION_TYPES, CONTEXT_ID, FIELD_IDS, ENTITY_TYPE_OPTIONS, ACCESS_TYPE_OPTIONS, ENTITY_TYPES };
