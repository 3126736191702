import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import { LOGIN_PROVIDERS, PROVIDERS_DISPLAY_NAME } from '../constants/loginConfigurationsDashboard.general.constants';

const getProviderTypeOptions = (providerConfigurationsByProviderType) => {
  let remainingProviderTypeOptions = [];
  _forEach(LOGIN_PROVIDERS, (providerType) => {
    if (!_has(providerConfigurationsByProviderType, providerType)) {
      remainingProviderTypeOptions = [...remainingProviderTypeOptions, { label: PROVIDERS_DISPLAY_NAME[providerType], value: providerType }];
    }
  });
  return remainingProviderTypeOptions;
};

export { getProviderTypeOptions };
