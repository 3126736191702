import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import HelperText from '@tekion/tekion-components/atoms/HelperText';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';

import mediaUploaderHOC from '../../../mediaUploader/mediaUploaderHOC';
import Image from './Image';
import useUploadMedia from '../../hooks/useUploadMedia';
import useUpdateMediaNode from '../../hooks/useUpdateMediaNode';
import { MAX_IMAGE_FILE_SIZE } from './constants/image.general';

function ImageContainer(props) {
  const { element = EMPTY_OBJECT, attributes, addMedia, mediaList, children, nodeProps } = props;
  const { enableMediaDeletion = true, enableMediaResizing = true, enableImagePreview = true, enableMediaHighlighting = true } = nodeProps;
  const { data = EMPTY_OBJECT } = element;
  const { mediaId, selectedFile } = data;
  const { shouldUpload } = useUploadMedia({
    mediaId,
    addMedia,
    selectedFile,
    element,
  });
  useUpdateMediaNode({ mediaList, element });

  return (
    <div {...attributes}>
      {children}
      <PropertyControlledComponent
        controllerProperty={!shouldUpload}
        fallback={
          <HelperText type="assistive" contentEditable={false}>
            {__('Uploading Image...')}
          </HelperText>
        }
      >
        <Image
          element={element}
          enableMediaDeletion={enableMediaDeletion}
          enableMediaResizing={enableMediaResizing}
          enableImagePreview={enableImagePreview}
          enableMediaHighlighting={enableMediaHighlighting}
          mediaList={mediaList}
        />
      </PropertyControlledComponent>
    </div>
  );
}

ImageContainer.propTypes = {
  element: PropTypes.shape({
    data: PropTypes.shape({
      mediaId: PropTypes.string,
      selectedFile: PropTypes.object,
    }).isRequired,
    type: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  }).isRequired,
  attributes: PropTypes.object.isRequired,
  addMedia: PropTypes.func.isRequired,
  mediaList: PropTypes.array,
  children: PropTypes.node,
  nodeProps: PropTypes.object,
};

ImageContainer.defaultProps = {
  mediaList: EMPTY_ARRAY,
  nodeProps: EMPTY_OBJECT,
  children: null,
};

export default compose(withProps({ shouldRequestThumbnail: false, maxFileSize: MAX_IMAGE_FILE_SIZE }), mediaUploaderHOC)(ImageContainer);
