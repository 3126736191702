const FIELD_IDS = {
  TYPE: 'type',
  SUB_EVENT_TYPE: 'subEventType',
  META_DATA: 'metadata',
  ACTION_TO_EXECUTE: 'actionToExecute',
  SCRIPT: 'script',
  GENERATOR_TYPE: 'generatorType',
  EVENT_TYPE: 'eventType',
  PROPERTIES: 'properties',
};

const SCRIPT_TYPES = {
  SYNC: 'synchronous',
  ASYNC: 'asynchronous',
};

const EVENT_META_DATA_FIELD_IDS = {
  PAGE_ID_TO_NAVIGATE: 'pageIdToNavigate',
  PAGE_NAME_TO_NAVIGATE: 'pageNameToNavigate',
  ACTION_ID_TO_EXECUTE: 'actionIdToExecute',
  ACTION_NAME_TO_EXECUTE: 'actionNameToExecute',
  NAVIGATE_TO_PREV_PAGE: 'navigateToPrevPage',
};

export { FIELD_IDS, SCRIPT_TYPES, EVENT_META_DATA_FIELD_IDS };
