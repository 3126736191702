import _isNumber from 'lodash/isNumber';
import _isString from 'lodash/isString';
import _size from 'lodash/size';
import _head from 'lodash/head';
import _isFinite from 'lodash/isFinite';

// Tekion-base
import { DEFAULT_DISPLAY_SCALE, DISPLAY_SCALES, DEFAULT_SCALE_TYPE, SCALE_TYPES, DISPLAY_SIZES } from '@tekion/tekion-base/constants/general';
import { tget } from '@tekion/tekion-base/utils/general';

const domScaleTypeKey = 'data-scale-type';

const isPxSizeString = (sizeString) => /^\d+(\.\d+)?(px)?$/.test(sizeString);

export const getFontSizeFromDOM = () => {
  const { fontSize } = document.querySelector('html').style;
  const matches = (fontSize || '').match(/(\d){1,3}(\.\d+)?%/g);
  if (_size(matches) !== 1) return DEFAULT_DISPLAY_SCALE;
  const numericScale = parseFloat(_head(matches));
  if (_isFinite(numericScale)) return numericScale;
  return DEFAULT_DISPLAY_SCALE;
};

export const getScaleAdjustedSize = (sizeInPx, extraArgs) => {
  const { currentDisplayScale, defaultDisplayScale, errorCorrectionFactor, isErrorCorrectionEnabled } = {
    currentDisplayScale: getFontSizeFromDOM(),
    defaultDisplayScale: DEFAULT_DISPLAY_SCALE,
    errorCorrectionFactor: window.pxToRemErrorRate || 0,
    isErrorCorrectionEnabled: true,
    ...extraArgs,
  };
  const sizeToAdjust = _isString(sizeInPx) && isPxSizeString(sizeInPx) ? parseFloat(sizeInPx) : sizeInPx;
  if (!_isNumber(sizeToAdjust)) return sizeInPx;
  const pxScalingFactor = currentDisplayScale / defaultDisplayScale;
  const scaleAdjustedSize = sizeToAdjust * pxScalingFactor;
  if (!isErrorCorrectionEnabled) return scaleAdjustedSize;
  // error-corrected, scale-adjusted size in px
  return scaleAdjustedSize * (1 + errorCorrectionFactor);
};

export const getScaleTypeFromDOM = () => {
  const htmlDomEl = document.querySelector('html');
  return tget(htmlDomEl, domScaleTypeKey, DEFAULT_SCALE_TYPE);
};

export const setFontSizeInDOM = (fontSize) => {
  const fontSizeToSet = _isNumber(fontSize) ? fontSize : DEFAULT_DISPLAY_SCALE;
  document.querySelector('html').style.fontSize = `max(${fontSizeToSet}%)`;
};

export const setDisplayScaleAutomatically = (width) => {
  if (getScaleTypeFromDOM() !== SCALE_TYPES.AUTOMATIC) return;
  let scaleTypeToSet = DEFAULT_DISPLAY_SCALE;
  if (width <= DISPLAY_SIZES.XS) scaleTypeToSet = DISPLAY_SCALES.XS;
  else if (width <= DISPLAY_SIZES.S) scaleTypeToSet = DISPLAY_SCALES.S;
  else if (width <= DISPLAY_SIZES.M) scaleTypeToSet = DISPLAY_SCALES.M;
  setFontSizeInDOM(scaleTypeToSet);
};
