import React from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _size from 'lodash/size';

import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import UserAvatar from '../../../../../../../../../atoms/userAvatar/UserAvatar';

import { ASSET_TYPE_DISPLAY_NAME } from './approversDetailView.constants';
import { ASSET_TYPES } from '../../../../../../../../../constants/general.constants';

import workspaceUserReader from '../../../../../../../../../readers/workSpaceUser.reader';
import roleReader from '../../../../../../../../../readers/role.reader';
import userGroupReader from '../../../../../../../../../readers/userGroup.reader';

import styles from './approversDetailView.module.scss';

const ApproversDetailView = ({ isLabelShowing, assetType, approvers }) => (
  <div className={styles.approversDetailSection}>
    <PropertyControlledComponent controllerProperty={isLabelShowing}>
      <div>
        <span className={styles.approverAssetTypeDisplayName}>{ASSET_TYPE_DISPLAY_NAME[assetType]}</span>
        <span>{` (${_size(approvers)})`}</span>
      </div>
    </PropertyControlledComponent>
    <div className={styles.approversSection}>
      <PropertyControlledComponent controllerProperty={assetType === ASSET_TYPES.USER}>
        <div className={styles.approversRow}>
          {_map(approvers, (approver) => {
            const name = workspaceUserReader.fullName(approver);
            const picSrc = workspaceUserReader.profilePicture(approver);
            return (
              <div className={styles.approversCol}>
                <div className={styles.userContainer}>
                  <UserAvatar displayName={name} userAvatar={picSrc} avatarSize="small" />
                  <span className={styles.userName}>{name}</span>
                </div>
              </div>
            );
          })}
        </div>
      </PropertyControlledComponent>
      <PropertyControlledComponent controllerProperty={assetType === ASSET_TYPES.USER_GROUP}>
        <div className={styles.approversRow}>
          {_map(approvers, (approver) => {
            const displayName = userGroupReader.displayName(approver);
            return <div className={styles.approversCol}>{displayName}</div>;
          })}
        </div>
      </PropertyControlledComponent>
      <PropertyControlledComponent controllerProperty={assetType === ASSET_TYPES.ROLE}>
        <div className={styles.approversRow}>
          {_map(approvers, (approver) => {
            const displayName = roleReader.displayName(approver);
            return <div className={styles.approversCol}>{displayName}</div>;
          })}
        </div>
      </PropertyControlledComponent>
    </div>
  </div>
);

ApproversDetailView.propTypes = {
  isLabelShowing: PropTypes.bool,
  assetType: PropTypes.string.isRequired,
  approvers: PropTypes.array.isRequired,
};

ApproversDetailView.defaultProps = {
  isLabelShowing: false,
};

export default ApproversDetailView;
