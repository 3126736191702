import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';
import _head from 'lodash/head';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { DEFAULT_RESULTS_PER_PAGE } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { DATE_TIME_FORMATS, getFormattedDateTime } from '@tekion/tekion-base/utils/dateUtils';
import { getImportTaskById, getProgressForImportTaskById, searchBatchTasks } from '../../../../../../actions/importData.actions';
import { getPreSignedUrls } from '../../../../../../actions/media.actions';
import { downloadURI } from '../../../../../../utils/downloadFile';

import { getImportedFileCardViewData, getPayloadForImportTaskBatch } from './dataImportDetailPage.helpers';

import { IMPORT_BATCH_COLUMN_IDS, IMPORT_TASK_IDS } from '../constants/dataImportDetailPage.constants';
import ACTION_TYPES from '../constants/dataImportDetailPage.actionTypes';

const handleFetchImportTaskBatches = async ({ getState, setState }) => {
  const { pageSize = DEFAULT_RESULTS_PER_PAGE, importTaskId, importTaskData, pageToken = null } = getState();

  setState({ isLoading: true });

  const payload = getPayloadForImportTaskBatch(pageSize, pageToken, importTaskId);
  const response = await searchBatchTasks(payload);
  let batchData = tget(response, 'hits', EMPTY_ARRAY);
  const nextPageToken = tget(response, 'nextPageToken', null);

  batchData = _map(batchData, (batchRowData) => ({
    ...batchRowData,
    [IMPORT_BATCH_COLUMN_IDS.START_TIME]: getFormattedDateTime(
      tget(batchRowData, IMPORT_BATCH_COLUMN_IDS.START_TIME),
      DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_WITH_TIME,
    ),
    [IMPORT_BATCH_COLUMN_IDS.END_TIME]: getFormattedDateTime(
      tget(batchRowData, IMPORT_BATCH_COLUMN_IDS.END_TIME),
      DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_WITH_TIME,
    ),
  }));

  const taskImportStatus = await getProgressForImportTaskById(importTaskId);

  const importedFileCardViewData = getImportedFileCardViewData(batchData, importTaskData, taskImportStatus);

  setState({ batchData, importedFileCardViewData, nextPageToken, isLoading: false });
};

const handleInit = async ({ getState, setState }) => {
  const { history, match } = getState();

  const importTaskId = tget(match, 'params.importTaskId');
  let importTaskData = tget(history, 'location.state.importTaskData', EMPTY_OBJECT);

  setState({ isImportTaskLoading: true });

  if (_isEmpty(importTaskData)) {
    importTaskData = await getImportTaskById(importTaskId);
  }

  const mediaId = tget(importTaskData, IMPORT_TASK_IDS.MEDIA_ID);
  const uploadedFileDataResponse = await getPreSignedUrls([mediaId]);

  setState({ isImportTaskLoading: false, importTaskId, uploadedFileData: _head(uploadedFileDataResponse), importTaskData }, () => {
    handleFetchImportTaskBatches({ getState, setState });
  });
};

const handlePageSizeUpdate = ({ getState, setState, params }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens = EMPTY_ARRAY, pageToken } = getState();
  const { page, resultsPerPage } = tget(params, 'value');

  let prevPageTokens = [...previousPageTokens];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }
  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }
  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleFetchImportTaskBatches({ getState, setState });
    },
  );
};

const handleDownloadData = async ({ params = EMPTY_OBJECT }) => {
  const columnId = tget(params, 'columnId');
  let mediaId;

  if (columnId === IMPORT_BATCH_COLUMN_IDS.VIEW_REQUEST) {
    mediaId = tget(params, ['values', IMPORT_BATCH_COLUMN_IDS.RAW_DATA_MEDIA_ID]);
  } else if (columnId === IMPORT_BATCH_COLUMN_IDS.VIEW_RESULT) {
    mediaId = tget(params, ['values', IMPORT_BATCH_COLUMN_IDS.RESPONSE_MEDIA_ID]);
  }

  if (!_isEmpty(mediaId)) {
    const response = await getPreSignedUrls([mediaId]);
    const mediaResponse = getArraySafeValue(response);

    if (!_isEmpty(mediaResponse)) {
      const url = tget(mediaResponse, 'normal.url');
      const fileName = tget(mediaResponse, 'normal.filename');

      return downloadURI(url, fileName);
    }
  }

  return EMPTY_OBJECT;
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleFetchImportTaskBatches,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageSizeUpdate,
  [ACTION_TYPES.DOWNLOAD_DATA]: handleDownloadData,
};

export default ACTION_HANDLERS;
