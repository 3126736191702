import React from 'react';

import Heading from '@tekion/tekion-components/atoms/Heading';
import Content from '@tekion/tekion-components/atoms/Content';
import emptyNotificationBell from '@tekion/tekion-components/assets/images/emptyNotificationBell.svg';

import styles from './emptyNotification.module.scss';

const EmptyNotification = () => (
  <div className={`${styles.placeholder} text-center`}>
    <img src={emptyNotificationBell} alt={__('No notifications found')} />
    <Heading size={3} className={`${styles.placeholderHeading} full-width`}>
      {__('You don’t have any notifications right now.')}
    </Heading>
    <Content className={`full-width flex-center ${styles.emptyContent}`}>{__('We will notify you, when some activity is performed.')}</Content>
  </div>
);

export default EmptyNotification;
