import React, { useEffect } from 'react';
import { isAuthenticated, redirectToLogin } from '../../WithAuth.helpers';
import { logOut } from '../../../../services/loginService';

const LogoutPage = () => {
  useEffect(() => {
    const isUserAuthenticated = isAuthenticated();

    if (!isUserAuthenticated) {
      redirectToLogin();
    }
    logOut({ shouldRedirect: false }).then(() => {
      window.location.href = '/';
    });
  }, []);

  return (
    <div className="full-width full-height flex-center">
      <h1>{__('Logging you out...')}</h1>
    </div>
  );
};

export default LogoutPage;
