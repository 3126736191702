import { DELAY_TASK_TAB_IDS } from './delayTaskModal.tabs';

const UNTITLED_TAB_NAME = __('Untitled');

const LABEL = 'label';
const DISPLAY_NAME = 'displayName';

const INITIAL_STATE = {
  selectedTabId: DELAY_TASK_TAB_IDS.TIME_DELAY,
};

const SORT_KEYS = {
  LABEL,
  DISPLAY_NAME,
};

const TASK_VALUES = {
  TIME_DELAY: 'TIME_DELAY',
};

export { INITIAL_STATE, UNTITLED_TAB_NAME, SORT_KEYS, TASK_VALUES };
