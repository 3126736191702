import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Lodash
import _map from 'lodash/map';

// Constant
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Components
import Tag from '@tekion/tekion-components/atoms/Tag/Tag';
import Content from '@tekion/tekion-components/atoms/Content';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';

import ChangeLog from '../changeLog';
import ActivityReader from '../../readers/Activity';
import { getCalendarDateDisplay, getUserDisplayName, getIsCreatedLog } from './activityLog.helpers';

import styles from './activityLog.module.scss';

class ActivityLog extends PureComponent {
  // eslint-disable-next-line class-methods-use-this, react/no-unstable-nested-components
  renderChangeLog = (formattersMap, lookup) => (change, index) =>
    <ChangeLog key={index} change={change} lookup={lookup} formattersMap={formattersMap} />;

  renderChanges() {
    const { activity, formattersMap } = this.props;
    const changes = ActivityReader.changes(activity);
    const { _lookup = EMPTY_OBJECT } = activity;
    return _map(changes, this.renderChangeLog(formattersMap, _lookup));
  }

  render() {
    const { activity } = this.props;
    const createdTime = ActivityReader.createdTime(activity);
    const userId = ActivityReader.userId(activity);
    const user = ActivityReader.userName(activity);
    const userName = getUserDisplayName(user, userId);
    const displayTime = getCalendarDateDisplay(createdTime);
    const updateTypes = ActivityReader.updateTypes(activity);
    const isCreatedLog = getIsCreatedLog(updateTypes);
    return (
      <div className={styles.activityLogContainer}>
        <div className={styles.activityLogHeader}>
          <Content className={styles.userName}>
            <PropertyControlledComponent controllerProperty={isCreatedLog}>
              <Tag className={styles.createdTag}>{__('Created')}</Tag>
            </PropertyControlledComponent>
            {__('By {{ userName }}', { userName })}
          </Content>
          <Content className={styles.changeTime}>{displayTime}</Content>
        </div>
        {this.renderChanges()}
      </div>
    );
  }
}

ActivityLog.propTypes = {
  activity: PropTypes.object,
  formattersMap: PropTypes.object,
};

ActivityLog.defaultProps = {
  activity: EMPTY_OBJECT,
  formattersMap: EMPTY_OBJECT,
};

export default ActivityLog;
