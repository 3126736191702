const SELECTED_OPTION = 'selectedOption';

const CONTEXT_ID = 'DELETE_STEP_MODAL';
const FIELD_IDS = {
  SELECTED_OPTION,
};

const BOOLEAN_LABELS = {
  true: __('true'),
  false: __('false'),
};

const ELLIPSIS_LENGTH = 90;

export { ELLIPSIS_LENGTH, BOOLEAN_LABELS, CONTEXT_ID, FIELD_IDS };
