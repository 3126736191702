import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FIELD_IDS } from '../constants/tabProperties.constants';
import { TAB_IDS } from '../../../../../constants/applicationBuilder.constants';

const rowCheck = {
  [FIELD_IDS.ENTITIES_RENDERING_INFO]: ({ selectedTabId }) => selectedTabId === TAB_IDS.APPROVAL,
  [FIELD_IDS.PAGE_NAME]: ({ selectedTabId }) => selectedTabId !== TAB_IDS.APPROVAL,
};

const getSections = (selectedTabId) => [
  {
    rows: [
      {
        columns: [FIELD_IDS.TAB_DISPLAY_NAME],
      },
      {
        columns: [FIELD_IDS.TAB_ICON],
      },
      {
        columns: filterRows([FIELD_IDS.ENTITIES_RENDERING_INFO], { selectedTabId }, rowCheck),
      },
      {
        columns: filterRows([FIELD_IDS.PAGE_NAME], { selectedTabId }, rowCheck),
      },
    ],
  },
];

export { getSections };
