import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import Modal from '@tekion/tekion-components/molecules/Modal';

import CustomAsyncSelect from '../customAsyncSelect';
import AsyncTag from '../asyncTag';

import { ACTION_TYPES } from './assignModal.constants';

import styles from './assignModal.module.scss';

const AssignModal = ({
  isAssignModalVisible,
  isMulti,
  isAssignModalSubmitting,
  modalTitle,
  selectedDropdownData,
  tabType,
  getOptionsApi,
  onAction,
  existingData,
}) => {
  const handleModalClose = useCallback(() => {
    onAction({ type: ACTION_TYPES.ASSIGN_MODAL_CLOSE });
  }, [onAction]);

  const handleSelectDropDownOnChange = useCallback(
    (value) => {
      onAction({ type: ACTION_TYPES.ASSIGN_DROPDOWN_ON_CHANGE, payload: { value } });
    },
    [onAction],
  );

  const handleOnDone = useCallback(() => {
    onAction({ type: ACTION_TYPES.ASSIGN_DROPDOWN_DONE });
  }, [onAction]);

  const renderBody = useCallback(
    () => (
      <>
        <div className={isMulti ? styles.select : ''}>
          <CustomAsyncSelect
            placeholder={__('Select Entity')}
            key="select-bar"
            value={selectedDropdownData}
            getOptionsApi={getOptionsApi}
            onChange={handleSelectDropDownOnChange}
            tabType={tabType}
            existingData={existingData}
            isMulti={isMulti || false}
          />
        </div>
        <AsyncTag value={selectedDropdownData} onChange={handleSelectDropDownOnChange} />
      </>
    ),
    [isMulti, selectedDropdownData, existingData, tabType, getOptionsApi, handleSelectDropDownOnChange],
  );

  useEffect(() => {
    if (isAssignModalVisible) onAction({ type: ACTION_TYPES.ON_ASSIGN_MODAL_MOUNT });
  }, [onAction, isAssignModalVisible]);

  return (
    <Modal
      visible={isAssignModalVisible}
      loading={isAssignModalSubmitting}
      title={modalTitle}
      submitBtnText={__('Done')}
      onCancel={handleModalClose}
      onSubmit={handleOnDone}
      className={styles.modalContainer}
    >
      <div className={styles.modalContainer}>{renderBody()}</div>
    </Modal>
  );
};

AssignModal.propTypes = {
  isAssignModalVisible: PropTypes.bool,
  isMulti: PropTypes.bool,
  isAssignModalSubmitting: PropTypes.bool,
  modalTitle: PropTypes.string,
  tabType: PropTypes.string,
  selectedDropdownData: PropTypes.array,
  existingData: PropTypes.array,
  getOptionsApi: PropTypes.func,
  onAction: PropTypes.func,
};

AssignModal.defaultProps = {
  isAssignModalVisible: false,
  isMulti: false,
  isAssignModalSubmitting: false,
  modalTitle: 'Assign',
  tabType: EMPTY_STRING,
  selectedDropdownData: EMPTY_ARRAY,
  existingData: EMPTY_ARRAY,
  getOptionsApi: _noop,
  onAction: _noop,
};

export default AssignModal;
