import React from 'react';
import PropTypes from 'prop-types';
import Header from '@tekion/tekion-components/atoms/Heading';

import styles from './permissionsError.module.scss';

const PermissionsError = ({ message, isAccessDenied }) => (
  <div>
    {isAccessDenied && <Header className={styles.accessDeniedMessage}>{__('Access Denied')}</Header>}
    <Header size={4} className={styles.errorMessage}>
      {message}
    </Header>
  </div>
);

PermissionsError.propTypes = {
  isAccessDenied: PropTypes.bool,
  message: PropTypes.string,
};

PermissionsError.defaultProps = {
  isAccessDenied: false,
  message: __('You do not have the permissions to access the content'),
};

export default PermissionsError;
