import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _toString from 'lodash/toString';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import TagsRenderer from '../../../../organisms/viewBuilder/atoms/tagsRenderer';

import { ASSET_TYPE_DISPLAY_NAME } from '../../constants/approvalStageCollapsiblePanels.constants';

import styles from './approversDetails.module.scss';

const StageApproversDetail = ({ assetTypes, approversDetails }) => {
  const getTags = useCallback((approvers, assetType) => {
    const tag = _toString(_map(tget(approvers, assetType, []), 'label'));
    if (_isEmpty(tag)) {
      return NO_DATA;
    }
    return tag;
  }, []);

  return (
    <div className={styles.stageApproversSection}>
      {_map(assetTypes, (assetType) => (
        <div key={assetType} className={styles.approversDetailsSection}>
          <div className={styles.approverAssetTypeSection}>
            <span className={styles.approverAssetTypeDisplayName}>
              {`${ASSET_TYPE_DISPLAY_NAME[assetType]} (${_size(tget(approversDetails, assetType))})`}
            </span>
          </div>
          <div className={styles.approversTagsSection}>
            <TagsRenderer
              fieldValue={getTags(approversDetails, assetType)}
              maxLimit={15}
              tagsRendererClassName={styles.tagsRenderer}
              tagClassName={styles.tags}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

StageApproversDetail.propTypes = {
  approversDetails: PropTypes.object,
  assetTypes: PropTypes.array,
};

StageApproversDetail.defaultProps = {
  approversDetails: EMPTY_OBJECT,
  assetTypes: EMPTY_ARRAY,
};

export default StageApproversDetail;
