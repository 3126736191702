import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import Button from '@tekion/tekion-components/atoms/Button';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { tget } from '@tekion/tekion-base/utils/general';

import { updatePassword } from './updatePasswordPage.action';
import { getSearchParamsFromUrl } from '../../../../utils/visualBuilder.utils';
import { setValueInLocalStorage } from '../../../../utils/localStorage';

import getAuthenticationForm from '../AuthenticationForm/AuthenticationFormContainer';

import ACTION_HANDLERS from './updatePasswordPage.actionHandler';
import { clearLoginData, isAuthenticated, redirectToLogin } from '../../WithAuth.helpers';
import { isUnlockAccountPage } from './updatePasswordPage.helpers';

import { FORM_SECTION, getFormConfig } from './updatePasswordPage.config';
import { FIELD_NAMES, UPDATE_PASSWORD_FORM_CONTEXT_ID } from './updatePasswordPage.constants';
import LS_CACHE_KEYS from '../../../../constants/localStorage.cacheKeys';

import styles from '../AuthenticationForm/authentication.module.scss';

const UpdatePasswordFormContainer = getAuthenticationForm(ACTION_HANDLERS);
const UpdatePasswordPage = ({ history }) => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    const logInDetails = getSearchParamsFromUrl(history);
    if (!_isEmpty(logInDetails)) {
      const userEmail = tget(logInDetails, 'email', '');
      const token = tget(logInDetails, 'token');
      setValueInLocalStorage(LS_CACHE_KEYS.TOKEN, token);
      setValueInLocalStorage(LS_CACHE_KEYS.USER, JSON.stringify({ email: userEmail }));
    }
  }, [history]);

  const handleSubmitButtonClick = useCallback(() => triggerSubmit(UPDATE_PASSWORD_FORM_CONTEXT_ID), []);

  const handleBackToLoginClick = useCallback(() => {
    setLoggingOut(true);
    clearLoginData();
    toaster(TOASTER_TYPE.SUCCESS, __('Logged Out Successfully'));
    redirectToLogin();
  }, []);

  const updateBtnLoading = useCallback((value) => {
    setIsBtnLoading(value);
  }, []);

  const onSubmit = useCallback(
    (formValues) => {
      const newPassword = _get(formValues, `${FIELD_NAMES.NEW_PASSWORD}`, '');
      setIsBtnLoading(true);

      updatePassword(newPassword, updateBtnLoading);
    },
    [updateBtnLoading],
  );

  const renderUpdateButton = useCallback(() => {
    const isUnlockAccPage = isUnlockAccountPage(history);
    return (
      <Button view={Button.VIEW.PRIMARY} loading={isBtnLoading} className={styles.loginButton} onClick={handleSubmitButtonClick}>
        {isUnlockAccPage ? __('Change Password and Unlock Account') : __('Update Password')}
      </Button>
    );
  }, [handleSubmitButtonClick, history, isBtnLoading]);

  const renderLogoutButton = useMemo(
    () => (
      <div className="flex-center">
        <Button
          disabled={isBtnLoading}
          loading={loggingOut}
          className={styles.backToLogin}
          view={Button.VIEW.TERTIARY}
          onClick={handleBackToLoginClick}
        >
          {__('Back to login')}
        </Button>
      </div>
    ),
    [handleBackToLoginClick, isBtnLoading, loggingOut],
  );

  const renderActionComponent = useCallback(
    () => (
      <div>
        {renderUpdateButton()}
        {isAuthenticated() && renderLogoutButton}
      </div>
    ),
    [renderLogoutButton, renderUpdateButton],
  );

  const fromSections = useCallback(() => FORM_SECTION, []);

  const formConfig = (formValues) => {
    const newPassword = _get(formValues, `${FIELD_NAMES.NEW_PASSWORD}`, '');
    return getFormConfig(newPassword);
  };

  return (
    <UpdatePasswordFormContainer
      isUnlockAccountPage={isUnlockAccountPage(history)}
      contextId={UPDATE_PASSWORD_FORM_CONTEXT_ID}
      className={styles.formPageContainer}
      getSections={fromSections}
      getFields={formConfig}
      renderActionComponent={renderActionComponent}
      onSubmit={onSubmit}
    />
  );
};

UpdatePasswordPage.propTypes = {
  history: PropTypes.object,
};
UpdatePasswordPage.defaultProps = {
  history: EMPTY_OBJECT,
};
export default UpdatePasswordPage;
