import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _reduce from 'lodash/reduce';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Actions
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../../../../../actions/conditionBuilder.actions';
import { fetchEntityDefByName } from '../../../../../../../../../actions/entityManagement.actions';

import { VARIABLE_PREFIX_NAME } from '../../../../constants/workflow.constants';

// Utils
import setEntityDisplayName from '../../../../utils/setEntityDisplayName';
import getWorkflowVariablesFieldDefinitions from '../../../../utils/getWorkflowVariablesFieldDefinitions';

const loadAndSetEntityDisplayName = async ({ setState, params = EMPTY_OBJECT }) => {
  const { entityName } = params;
  if (entityName) {
    const entityDefinition = await fetchEntityDefByName(entityName);

    setState((state) => ({
      workflow: setEntityDisplayName(state?.workflow, entityDefinition),
    }));
  }
};

const loadFieldDefinitions = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { mapOfVariableToEntityNameForCondition, conditionBuilderFieldDefinitionObject, workflow } = getState();
  const { variableMap = mapOfVariableToEntityNameForCondition, scopedVariables = [] } = params;

  if (!_isEmpty(variableMap)) {
    const updatedConditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(
      variableMap,
      conditionBuilderFieldDefinitionObject,
    );

    const variablesInfo = tget(workflow, 'uiMetadata.variablesInfo');

    const workflowVariablesFieldDefinitions = getWorkflowVariablesFieldDefinitions(variablesInfo, scopedVariables);

    _set(updatedConditionBuilderFieldDefinitionObject, VARIABLE_PREFIX_NAME, workflowVariablesFieldDefinitions);

    const updatedVariableMap = _reduce(
      variableMap,
      (result, info, name) => {
        if (_isEmpty(tget(variablesInfo, name))) {
          _set(result, name, info);
          return result;
        }
        return result;
      },
      {},
    );

    setState((state) => ({
      ...state,
      conditionBuilderFieldDefinitionObject: updatedConditionBuilderFieldDefinitionObject,
      mapOfVariableToEntityNameForCondition: updatedVariableMap,
    }));

    return { conditionBuilderFieldDefinitionObject: updatedConditionBuilderFieldDefinitionObject, mapOfVariableToEntityName: updatedVariableMap };
  }

  return EMPTY_OBJECT;
};

export { loadAndSetEntityDisplayName, loadFieldDefinitions };
