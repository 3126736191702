import { tget } from '@tekion/tekion-base/utils/general';

import _omit from 'lodash/omit';

import { executeAction } from '../actionBuilder.actions';

const Actions = {
  execute: async (actionName, payload) => {
    const response = await executeAction(actionName, payload);

    return { ..._omit(response, 'entity'), ...tget(response, 'entity', {}) };
  },
};

export default Actions;
