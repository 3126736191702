import _get from 'lodash/get';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

import { EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { isErrorEmpty } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { fetchEntityDefByName } from '../../../../../../../../actions/entityManagement.actions';

import { getFieldsFormRelationshipDetailsValues, getOptionsByName, getRelationshipDetailsFormValues } from './relationshipDetailsForm.helpers';

import FIELDS_FORM_FIELD_IDS from '../../../constants/fieldsForm.fieldIds';
import RELATIONSHIP_DETAILS_FORM_FIELD_IDS from '../constants/relationshipDetailsForm.fieldIds';
import { FIELD_FORM_CONTEXT_ID } from '../../../constants/fieldsForm.constants';
import ACTION_TYPES from '../../../constants/fieldsForm.actionTypes';

const handleFormInit = async ({ getState, setState }) => {
  const { cachedEntityDefs, fieldsFormRelationshipDetailsValue = EMPTY_OBJECT, fieldsFormOnAction } = getState();

  const values = getRelationshipDetailsFormValues(fieldsFormRelationshipDetailsValue);
  const entityName = _get(values, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY);

  if (!_isEmpty(entityName)) {
    setState({ values, isInitialStateLoaded: true, isDisabled: true });
    let entityDef = _get(cachedEntityDefs, entityName);

    if (_isEmpty(entityDef)) {
      entityDef = await fetchEntityDefByName(entityName);
      fieldsFormOnAction({ type: ACTION_TYPES.ON_ENTITY_DEF_CHANGE, payload: { entityName, entityDef } });
    }

    const isGroupingAllowed = _get(entityDef, 'groupsEnabled', false);
    const fieldDefinitions = _get(entityDef, 'fieldDefinitions', EMPTY_ARRAY);

    setState({
      isGroupingAllowed,
      lookupDisplayFieldOptions: getOptionsByName(fieldDefinitions, false),
      lookupFieldOptions: getOptionsByName(fieldDefinitions),
      isDisabled: false,
    });
  } else {
    setState({ values, isGroupingAllowed: true, isInitialStateLoaded: true });
  }
};

const handleFieldChange = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { values: oldValues = EMPTY_OBJECT, cachedEntityDefs, fieldsFormOnAction } = getState();
  const values = { ...oldValues, [id]: value };

  if (id === RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY) {
    _set(values, id, getArraySafeValue(value));
    _unset(values, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_DISPLAY);
    _unset(values, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP);

    const entityName = getArraySafeValue(value);

    let entityDef = _get(cachedEntityDefs, entityName);

    if (_isEmpty(entityDef)) {
      entityDef = await fetchEntityDefByName(entityName);
      fieldsFormOnAction({ type: ACTION_TYPES.ON_ENTITY_DEF_CHANGE, payload: { entityName, entityDef } });
    }

    const isGroupingAllowed = _get(entityDef, 'groupsEnabled', false);
    const fieldDefinitions = _get(entityDef, 'fieldDefinitions', EMPTY_ARRAY);

    setState(
      {
        isGroupingAllowed,
        lookupDisplayFieldOptions: getOptionsByName(fieldDefinitions, false),
        lookupFieldOptions: getOptionsByName(fieldDefinitions),
        values,
      },
      () =>
        fieldsFormOnAction({
          type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
          payload: { id: FIELDS_FORM_FIELD_IDS.RELATIONSHIP_DETAILS_FORM, value: getFieldsFormRelationshipDetailsValues(values) },
        }),
    );
  } else {
    setState({ values }, () =>
      fieldsFormOnAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: { id: FIELDS_FORM_FIELD_IDS.RELATIONSHIP_DETAILS_FORM, value: getFieldsFormRelationshipDetailsValues(values) },
      }),
    );
  }
};

const handleValidationSuccess = ({ getState, params = EMPTY_OBJECT }) => {
  const { errors } = params;
  const { fieldsFormOnAction } = getState();

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: { id: FIELDS_FORM_FIELD_IDS.RELATIONSHIP_DETAILS_FORM, errors },
  });
};

const handleSubmit = ({ getState, params = EMPTY_OBJECT }) => {
  const { additional = {} } = params;
  const { formErrors } = additional;
  const { fieldsFormOnAction } = getState();

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: { id: FIELDS_FORM_FIELD_IDS.RELATIONSHIP_DETAILS_FORM, errors: undefined },
  });

  _set(formErrors, FIELDS_FORM_FIELD_IDS.RELATIONSHIP_DETAILS_FORM, false);

  if (isErrorEmpty(formErrors)) {
    triggerSubmit(FIELD_FORM_CONTEXT_ID, _cloneDeep(additional));
  }
};

const RELATIONSHIP_DETAILS_FORM_ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleFormInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidationSuccess,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
};

export default RELATIONSHIP_DETAILS_FORM_ACTION_HANDLERS;
