import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cx from 'classnames';

// constants
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// components
import LoadingSpinner from '@tekion/tekion-components/molecules/loadingSpinner';
import ImagePreview from './molecules/imagePreview';

// container
import withMediaResizing from '../../containers/withMediaResizing';
import withMediaStyling from '../../containers/withMediaStyling';
import withMediaDeletion from '../../containers/withMediaDeletion';

// hooks
import useSecuredImageURL from './hooks/useSecuredImageURL';
import useUpdateMediaNode from '../../hooks/useUpdateMediaNode';
import useImagePreview from './hooks/useImagePreview';
import useImageOnClick from './hooks/useImageOnClick';
import styles from './image.module.scss';

const Image = (props) => {
  const { width, element = EMPTY_OBJECT, enableImagePreview, mediaList } = props;
  const { data = EMPTY_OBJECT } = element;
  const { mediaId = EMPTY_STRING } = data;

  const { isLoading, presignedUrl } = useSecuredImageURL(mediaId, mediaList);

  const handleImageLoad = useCallback(() => URL.revokeObjectURL(presignedUrl), [presignedUrl]);

  const { showImagePreview, closeImagePreview, openImagePreview } = useImagePreview();

  const handleImageClick = useImageOnClick(openImagePreview, enableImagePreview);

  const shouldShowPreview = enableImagePreview && showImagePreview;

  useUpdateMediaNode({ mediaList, url: presignedUrl, element, width });

  return (
    <>
      <div className="full-width full-height">
        {isLoading ? (
          <LoadingSpinner className={styles.loadingContainer} size={30} />
        ) : (
          <div className={cx({ 'cursor-pointer': enableImagePreview })} onClick={handleImageClick}>
            <img draggable={false} src={presignedUrl} alt={__('Failed to load image')} className="full-width full-height" onLoad={handleImageLoad} />
          </div>
        )}
      </div>
      {shouldShowPreview && <ImagePreview imageSrc={presignedUrl} onClose={closeImagePreview} />}
    </>
  );
};

Image.propTypes = {
  width: PropTypes.number,
  enableImagePreview: PropTypes.bool,
  element: PropTypes.shape({
    data: PropTypes.shape({
      mediaId: PropTypes.string.isRequired,
    }).isRequired,
    type: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  }).isRequired,
  mediaList: PropTypes.array.isRequired,
};

Image.defaultProps = {
  width: 200,
  enableImagePreview: false,
};

export default compose(withMediaStyling, withMediaResizing, withMediaDeletion, React.memo)(Image);
