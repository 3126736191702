import React from 'react';
import PropTypes from 'prop-types';
import { ToolbarList } from '@udecode/plate-list-ui';
import { getPlatePluginType } from '@udecode/plate-core';

// components
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

// hooks
import useEditor from '../../../hooks/useEditor';

function ListToolbarButton(props) {
  const { icon, id: pluginKey } = props;
  const editor = useEditor();
  return <ToolbarList key={pluginKey} type={getPlatePluginType(editor, pluginKey)} icon={<FontIcon size={SIZES.MD_S}>{icon}</FontIcon>} />;
}

ListToolbarButton.propTypes = {
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default ListToolbarButton;
