import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Route, Switch, withRouter } from 'react-router-dom';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components/molecules/loader';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import VisualBuilder from '../../../../organisms/visualBuilder';

import VisualBuilderMetadataPage from './visualBuilderMetadataPage/VisualBuilderMetadataPage';

import ACTION_HANDLERS from './visualBuilderPage.actionHandlers';

import { ACTION_TYPES } from './visualBuilderPage.constants';
import WithSize from '../../../../connectors/withSize';

const VisualBuilderPage = ({ isLoading, contentWidth, contentHeight, match, history, pageConfiguration, onAction, widgetConfiguration }) => {
  const pageName = _get(match, 'params.pageName');

  const handlePageMetadataSubmit = useCallback(
    (newPageMetadata, widgets) => {
      onAction({
        type: ACTION_TYPES.SAVE_PAGE_METADATA,
        payload: {
          pageMetadata: newPageMetadata,
          widgets,
        },
      });

      history.push(`${match.url}/configure`);
    },
    [history, match.url, onAction],
  );

  const renderConfigure = () => (
    <VisualBuilder
      pageConfiguration={pageConfiguration}
      isLoading={isLoading}
      contentWidth={contentWidth}
      contentHeight={contentHeight}
      match={match}
      history={history}
      widgetConfiguration={widgetConfiguration}
    />
  );

  const renderCreate = () => (
    <VisualBuilderMetadataPage isLoading={isLoading} history={history} match={match} onPageMetadataSubmit={handlePageMetadataSubmit} />
  );

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.INIT,
    });
  }, [onAction]);

  if (pageName && _isEmpty(pageConfiguration)) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route exact path={`${match.path}/configure`} render={renderConfigure} />
      <Route path={match.path} render={renderCreate} />
    </Switch>
  );
};

VisualBuilderPage.propTypes = {
  isLoading: PropTypes.bool,
  contentWidth: PropTypes.number,
  contentHeight: PropTypes.number,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
  pageConfiguration: PropTypes.object,
  widgetConfiguration: PropTypes.array,
};

VisualBuilderPage.defaultProps = {
  isLoading: true,
  contentWidth: 0,
  contentHeight: 0,
  pageConfiguration: EMPTY_OBJECT,
  widgetConfiguration: EMPTY_ARRAY,
};

export default compose(WithSize({ hasPageHeader: 1 }), withRouter, withActions(EMPTY_OBJECT, ACTION_HANDLERS))(VisualBuilderPage);
