import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';

import { toMoment } from '@tekion/tekion-base/utils/dateUtils';
import { ACTIVITY_TYPES, SYTEM_USER, RELATIVE_DATE_FORMAT } from './activityLog.constants';

export const getUserDisplayName = (user, userId) => {
  if (userId === SYTEM_USER || _isEmpty(user)) return __('System User');
  return user;
};

export const getCalendarDateDisplay = (epochOrMomentObject) => toMoment(epochOrMomentObject).calendar(null, RELATIVE_DATE_FORMAT);

export const getIsCreatedLog = (updateTypes) => _includes(updateTypes, ACTIVITY_TYPES.CREATED);
