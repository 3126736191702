import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import Modal from '@tekion/tekion-components/molecules/Modal';
import Loader from '@tekion/tekion-components/molecules/loader';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import getFieldConfig from './loopNodeModal.fields';
import getSections from './loopNodeModal.sections';
import { getApiFormattedNodeDataAndUsedVariables, getFormFormattedNodeData, getLoopVariableInfo } from './loopNodeModal.helpers';

import { CONTEXT_ID } from './loopNodeModal.constants';
import { LOOP_NODE_FIELD_IDS } from '../../../constants/nodeDataFieldIds';
import { FILTER_CONDITION_VARIABLE_NAME } from '../../../../../actionBuilder/actionBuilderForm/constants/actionBuilderForm.general';
import { MODAL_MODES } from '../../../constants/workflowBuilder.constants';
import { FILTER_CONDITION_LOADER } from '../recordActionModal/constants/recordActionModal.constants';
import { TASK_DEF_IDS } from '../../../constants/workflow.constants';

const LoopNodeModal = ({
  isLoading,
  mode,
  stepDetails,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  onEntityChange,
  onCancel,
  onSubmit,
  ...rest
}) => {
  const [filterConditionVariableMap, setFilterConditionVariableMap] = useState({});

  const [formValues, setFormValues] = useState();
  const [errors, setErrors] = useState();

  const isTargetFieldsLoading = tget(rest, FILTER_CONDITION_LOADER);
  const taskDefId = tget(stepDetails, 'taskDefId');
  const modalTitle = taskDefId === TASK_DEF_IDS.FETCH_AND_ITERATE ? __('Fetch and Iterate') : __('Iterate');

  const fields = useMemo(
    () => getFieldConfig(conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, filterConditionVariableMap),
    [conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, filterConditionVariableMap],
  );

  const sections = useMemo(() => getSections(taskDefId, isTargetFieldsLoading, formValues), [taskDefId, isTargetFieldsLoading, formValues]);

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { value: updatedValue, id } = payload;
          if (id === LOOP_NODE_FIELD_IDS.ENTITY_NAME) {
            const _filterConditionVariableMap = {
              [FILTER_CONDITION_VARIABLE_NAME]: updatedValue,
            };
            setFilterConditionVariableMap(_filterConditionVariableMap);
            onEntityChange(_filterConditionVariableMap, FILTER_CONDITION_LOADER);
          }
          setFormValues((prev) => ({ ...prev, [id]: updatedValue }));
          break;
        }
        case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
          const { errors: newErrors } = payload;
          setErrors(newErrors);
          break;
        }
        case FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT: {
          const { apiFormValues, usedVariables } = getApiFormattedNodeDataAndUsedVariables(formValues);
          const loopVariableInfo = getLoopVariableInfo(apiFormValues, taskDefId, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject);
          onSubmit(apiFormValues, [], loopVariableInfo, usedVariables);
          setFormValues({});
          setErrors({});
          break;
        }
        default:
      }
    },
    [formValues, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject, onEntityChange, taskDefId, onSubmit],
  );

  const handleSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, []);

  const handleCancel = useCallback(() => {
    setFormValues({});
    setErrors({});
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    if (mode === MODAL_MODES.EDIT) {
      const userData = tget(stepDetails, 'userData.data');
      if (!_isEmpty(userData)) {
        const entityName = tget(userData, LOOP_NODE_FIELD_IDS.ENTITY_NAME);

        if (!_isEmpty(entityName)) {
          const _filterConditionVariableMap = {
            [FILTER_CONDITION_VARIABLE_NAME]: entityName,
          };
          setFilterConditionVariableMap(_filterConditionVariableMap);
          onEntityChange(_filterConditionVariableMap, FILTER_CONDITION_LOADER);
        }
        const updatedUserData = getFormFormattedNodeData(userData);
        setFormValues(updatedUserData);
      }
    }
  }, [mode, stepDetails, onEntityChange]);

  return (
    <Modal visible destroyOnClose width={Modal.SIZES.L} title={modalTitle} submitBtnText={__('Done')} onCancel={handleCancel} onSubmit={handleSubmit}>
      <FormWithSubmission
        isFetching={isLoading}
        contextId={CONTEXT_ID}
        sections={sections}
        fields={fields}
        values={formValues}
        errors={errors}
        onAction={handleAction}
      />
      {isTargetFieldsLoading && <Loader id="TARGET_FIELDS_LOADING" />}
    </Modal>
  );
};

LoopNodeModal.propTypes = {
  isLoading: PropTypes.bool,
  mode: PropTypes.bool,
  stepDetails: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onEntityChange: PropTypes.func,
};

LoopNodeModal.defaultProps = {
  isLoading: false,
  mode: MODAL_MODES.ADD,
  stepDetails: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  onSubmit: _noop,
  onCancel: _noop,
  onEntityChange: _noop,
};

export default LoopNodeModal;
