import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _forEach from 'lodash/forEach';

import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { DEFAULT_RESULTS_PER_PAGE } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';

import globalLookupItemsResolver from '../../../../../../actions/globalLookupItemsResolver';
import { searchImports } from '../../../../../../actions/importData.actions';
import { getPreSignedUrls } from '../../../../../../actions/media.actions';

import { getPayload } from './dataImportList.helpers';

import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/dataImportList.actionTypes';
import { COLUMN_IDS, TABLE_CONSTANTS } from '../constants/dataImportList.constants';
import { LOOKUP_FIELD_TO_RESOLVE, LOOKUP_ITEM_TO_RESOLVE } from '../../../../../../constants/globalLookupItemsResolver.constants';

import mediaReader from '../../../../../../readers/media.reader';

const handleFetchData = async ({ getState, setState }) => {
  const { pageSize = DEFAULT_RESULTS_PER_PAGE, pageToken = null, selectedFilters } = getState();

  const payload = getPayload(selectedFilters, pageSize, pageToken);

  setState({ isLoading: true });

  const response = await searchImports(payload);

  const nextPageToken = tget(response, 'nextPageToken', null);
  let tableData = tget(response, 'hits', EMPTY_ARRAY);
  const totalCount = tget(response, 'count');

  const entityNames = [];
  const mediaIds = [];

  _forEach(tableData, (rowData) => {
    const entityName = tget(rowData, [COLUMN_IDS.ADDITIONAL_DATA, COLUMN_IDS.ENTITY_NAME]);
    const mediaId = tget(rowData, COLUMN_IDS.MEDIA_ID);

    entityNames.push(entityName);
    mediaIds.push(mediaId);
  });

  const resolvedResponse = await globalLookupItemsResolver(
    { [LOOKUP_ITEM_TO_RESOLVE.ENTITY]: { [LOOKUP_FIELD_TO_RESOLVE.NAME]: entityNames } },
    true,
  );
  const entityDisplayNameMapByName = tget(resolvedResponse, LOOKUP_ITEM_TO_RESOLVE.ENTITY, EMPTY_OBJECT);
  let uploadedFilesDataResponse = await getPreSignedUrls(mediaIds);
  uploadedFilesDataResponse = _keyBy(uploadedFilesDataResponse, 'mediaId');

  tableData = _map(tableData, (rowData) => {
    const entityName = tget(rowData, [COLUMN_IDS.ADDITIONAL_DATA, COLUMN_IDS.ENTITY_NAME]);
    const mediaId = tget(rowData, COLUMN_IDS.MEDIA_ID);
    const media = tget(uploadedFilesDataResponse, mediaId, EMPTY_OBJECT);

    return {
      ...rowData,
      [COLUMN_IDS.FILE_NAME]: mediaReader.fileName(media) || NO_DATA,
      [COLUMN_IDS.ENTITY_DISPLAY_NAME]: tget(entityDisplayNameMapByName, entityName, NO_DATA),
    };
  });

  setState({ tableData, totalCount, nextPageToken, isLoading: false });
};

const handleImportData = ({ getState }) => {
  const { history } = getState();
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.IMPORT_DATA_STUDIO}/${PAGE_IDS.CREATE_IMPORT}`;

  history.push({ pathname });
};

const handleSetFilter = async ({ params = EMPTY_OBJECT, setState, getState }) => {
  const filters = _get(params, 'value', EMPTY_ARRAY);

  setState(
    {
      selectedFilters: filters,
      currentPage: TABLE_CONSTANTS.CURRENT_PAGE,
    },
    () => {
      handleFetchData({ getState, setState });
    },
  );
};

const handleTableItemClick = ({ getState, params }) => {
  const { history } = getState();
  const importTaskData = _get(params, ['value', 'original']);
  const importTaskId = _get(importTaskData, [COLUMN_IDS.ID]);

  if (!_isEmpty(importTaskId)) {
    const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.IMPORT_DATA_STUDIO}/${PAGE_IDS.DATA_IMPORTS}/${importTaskId}`;
    history.push({ pathname, state: { importTaskData } });
  }
};

const handlePageSizeUpdate = ({ getState, setState, params }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens = EMPTY_ARRAY, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');

  let prevPageTokens = [...previousPageTokens];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }
  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }
  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleFetchData({ getState, setState });
    },
  );
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleFetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleSetFilter,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleFetchData,
  [ACTION_TYPES.ON_CLICK_IMPORT_NEW]: handleImportData,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleTableItemClick,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageSizeUpdate,
};

export default ACTION_HANDLERS;
