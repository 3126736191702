import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { DATE_TIME_FORMATS, toMoment, getMomentValueOf, getToday } from '@tekion/tekion-base/utils/dateUtils';

import DateInput from '@tekion/tekion-components/atoms/DateInput';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import { ShareAndManageRecordUserDetailSetters } from '../../../../../../../../hooks/useShareAndManageRecordModal/shareAndManageRecordUserData';

import styles from './durationRenderer.module.scss';

const DurationRenderer = ({ index, data, original, onValueChange }) => {
  const momentValue = useMemo(() => toMoment(data), [data]);

  const handleChange = useCallback(
    (newMomentValue) => {
      const newValue = getMomentValueOf(newMomentValue);

      const updatedValue = ShareAndManageRecordUserDetailSetters.setExpiryTime(original, newValue);

      onValueChange(index, updatedValue);
    },
    [index, onValueChange, original],
  );

  const disabledDate = useCallback((current) => current && current < getToday(), []);

  return (
    <DateInput
      showTime
      className={styles.durationClass}
      containerClassName={styles.durationFieldContainerClass}
      value={momentValue}
      dateFormat={DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_WITH_TIME}
      disabledDate={disabledDate}
      onChange={handleChange}
    />
  );
};

DurationRenderer.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.number,
  original: PropTypes.object.isRequired,
  onValueChange: PropTypes.func,
};

DurationRenderer.defaultProps = {
  data: null,
  onValueChange: _noop,
};

export default makeCellRenderer(DurationRenderer);
