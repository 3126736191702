const HOME_PAGE_ROUTE = '/:tabId?';
const ENTITY_RECORD_ROUTE = '/entities/:entityName';
const CREATE_ENTITY_RECORD_ROUTE = '/entities/:entityName/create-entity-record';
const EDIT_ENTITY_RECORD_ROUTE = '/entities/:entityName/edit-entity-record/:entityRecordId';
const ENTITY_RECORD_DETAIL_ROUTE = '/entities/:entityName/view-entity-record/:entityRecordId';
const ENTITY_RECORD_RIGHT_PANE_ROUTE = '/entities/:entityName/entity-record-right-pane/:entityRecordId';
const APPLICATION_RENDERER_ROUTE = '/applications/:appName';
const EXPORT_LIST_PAGE_ROUTE = '/exports';
const DASHBOARD_PAGE = '/reporting/dashboards/:dashboardName';
const APPROVAL_CENTRE_CREATE_REQUEST = '/approval-centre/create-request';
const APPROVAL_CENTRE_EDIT_REQUEST = '/approval-centre/edit-request/:approvalId';
const APPROVAL_CENTRE_MANAGEMENT = '/approval-centre/management/:approvalTabId?';
const APPROVAL_CENTRE_REQUEST = '/approval-centre/management/:approvalTabId?/:approvalId?';

export default {
  HOME_PAGE_ROUTE,
  ENTITY_RECORD_ROUTE,
  CREATE_ENTITY_RECORD_ROUTE,
  EDIT_ENTITY_RECORD_ROUTE,
  ENTITY_RECORD_DETAIL_ROUTE,
  ENTITY_RECORD_RIGHT_PANE_ROUTE,
  APPLICATION_RENDERER_ROUTE,
  EXPORT_LIST_PAGE_ROUTE,
  DASHBOARD_PAGE,
  APPROVAL_CENTRE_CREATE_REQUEST,
  APPROVAL_CENTRE_EDIT_REQUEST,
  APPROVAL_CENTRE_MANAGEMENT,
  APPROVAL_CENTRE_REQUEST,
};
