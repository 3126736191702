import produce from 'immer';
import _snakeCase from 'lodash/snakeCase';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

// Constants
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { fetchEntityDefByName, createNewEntity, updateEntity } from '../../../../actions/entityManagement.actions';
import { ACTION_TYPES, ENTITY_TYPES, FIELD_IDS } from './entityForm.constants';
import { STUDIO_ROUTE } from '../../../../constants/routes';
import { FORM_MODES, RECORD_ACCESS_TYPES } from '../../../../constants/general.constants';
import PAGE_IDS from '../../constants/PageIds.constants';

const handleOnMount = async ({ setState, getState }) => {
  const { history = EMPTY_OBJECT, match = EMPTY_OBJECT } = getState();
  const { entityName } = _get(match, 'params', {});
  if (_isEmpty(entityName)) {
    setState({
      formMode: FORM_MODES.CREATE,
      entity: {
        [FIELD_IDS.ENTITY_TYPE]: [ENTITY_TYPES.CUSTOM],
        [FIELD_IDS.CREATE_ENABLED]: true,
        [FIELD_IDS.DETAIL_VIEW_ENABLED]: true,
        [FIELD_IDS.EDIT_ENABLED]: true,
        [FIELD_IDS.LIST_VIEW_ENABLED]: true,
        [FIELD_IDS.SEARCH_ENABLED]: true,
      },
    });
  } else {
    setState({ formMode: FORM_MODES.EDIT, isEntityDataLoading: true });
    let entity = _get(history, 'location.state.entity');

    if (!entity) {
      entity = await fetchEntityDefByName(entityName);
    }

    entity = { ...entity, type: _castArray(_get(entity, FIELD_IDS.ENTITY_TYPE, EMPTY_STRING)) };

    setState({ entity, isEntityDataLoading: false });
  }
};

const handleFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { formMode, disableAutoFillForName = false } = getState();

  setState(
    produce((draft) => {
      _set(draft, `entity.${id}`, value);

      if (formMode === FORM_MODES.CREATE && id === FIELD_IDS.DISPLAY_NAME && !disableAutoFillForName) {
        _set(draft, `entity.${FIELD_IDS.NAME}`, _snakeCase(value));
      } else if (id === FIELD_IDS.NAME) {
        setState({ disableAutoFillForName: true });
      } else if (id === FIELD_IDS.COMPLEX_FIELD && value) {
        _set(draft, `entity.${FIELD_IDS.RECORD_TYPE_ENABLED}`, false);
      } else if (id === FIELD_IDS.RECORD_TYPE_ENABLED && value) {
        _set(draft, `entity.${FIELD_IDS.COMPLEX_FIELD}`, false);
      } else if (id === FIELD_IDS.ACCESS_TYPE && getArraySafeValue(value) === RECORD_ACCESS_TYPES.PUBLIC_READ_WRITE) {
        _set(draft, `entity.${FIELD_IDS.GRANT_ACCESS_USING_HIERARCHIES}`, false);
      }
    }),
  );
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;

  setState({ errors });
};

const handleSubmit = async ({ setState, getState }) => {
  const { history, entity, formMode } = getState();

  const submitAction = formMode === FORM_MODES.CREATE ? createNewEntity : updateEntity;

  setState({ isSavingDetails: true });
  let payload = { ...entity };

  payload = {
    ...payload,
    type: getArraySafeValue(_get(entity, FIELD_IDS.ENTITY_TYPE)),
    accessType: getArraySafeValue(_get(entity, FIELD_IDS.ACCESS_TYPE)),
  };
  const response = await submitAction(payload);

  if (!_isEmpty(response)) {
    const entityName = _get(response, 'name');
    setTimeout(() => {
      history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELDS}`);
    }, ES_REFETCH_DELAY);
  } else {
    setState({ isSavingDetails: false });
  }
};

const handleRedirection = ({ getState }) => {
  const { history } = getState();

  const path = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}`;
  history.push({ pathname: path });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_MOUNT]: handleOnMount,
  [ACTION_TYPES.ON_CANCEL]: handleRedirection,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
};

export default ACTION_HANDLERS;
