import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

const COMPLEX_FIELD_FILTERS = [
  {
    field: 'complexField',
    values: [true],
    filterType: OPERATORS.IN,
  },
];

export { COMPLEX_FIELD_FILTERS };
