import _get from 'lodash/get';
import _split from 'lodash/split';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { getAllRecordGroups, deleteRecordGroup } from '../../../../../../actions/recordGroup.actions';
import { getPayload } from './userGroupList.helpers';

import { CURRENT_PAGE, CUSTOM_ACTIONS } from '../constants/userGroupList.constants';
import { EDIT_USER_GROUP } from '../../createUserGroup/constants/createUserGroup.constants';
import { TENANT_UNIVERSE_BASE_ROUTE, STUDIO_ROUTE, TENANT_UNIVERSE_PAGE_ID } from '../../../../../../constants/routes';
import ROUTES from '../../../../constants/routes';
import { FIELD_IDS } from '../../../../../../constants/entityRecordGroup.constants';

const fetchAllUserGroupsList = async ({ params, setState, getState }) => {
  setState({ loading: true });
  const { match } = getState();
  const url = _get(match, 'url', '');
  const matchArray = _split(url, '/');
  const isTenantUniverse = _includes(matchArray, TENANT_UNIVERSE_PAGE_ID);

  const { pageSize, currentPage, searchField, searchText } = getState();

  const currentPageToken = _get(params, 'nextPageToken');
  const payload = getPayload({
    searchText,
    searchField,
    pageSize,
    currentPage,
    nextPageToken: currentPageToken,
  });
  const userDataResponse = await getAllRecordGroups(payload);
  const userGroupList = tget(userDataResponse, 'hits', EMPTY_ARRAY);

  setState({
    isLoaded: true,
    loading: false,
    userGroupList,
    nextPageToken: _get(userDataResponse, 'nextPageToken', ''),
    isTenantUniverse,
  });
};

const handleRefresh = ({ getState, setState }) => {
  setState({ currentPage: CURRENT_PAGE }, () => {
    fetchAllUserGroupsList({ params: { nextPageToken: EMPTY_STRING }, getState, setState });
  });
};

const handleSearchApply = async ({ setState, params, getState }) => {
  const searchText = _get(params, 'value', '');
  setState({ searchText, currentPage: CURRENT_PAGE }, () => {
    fetchAllUserGroupsList({
      getState,
      setState,
      params: { nextPageToken: EMPTY_STRING },
    });
  });
};

const handleSearchFieldChange = ({ params, setState }) => {
  const searchField = _get(params, 'value', '');
  setState({ searchField });
};

const handlePageUpdate = ({ setState, getState, params }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');
  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }

  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      fetchAllUserGroupsList({ params: { nextPageToken: currentPageToken }, getState, setState });
    },
  );
};

const handleAddNewGroup = ({ getState }) => {
  const { history, isTenantUniverse } = getState();

  if (isTenantUniverse) {
    const pathName = ROUTES.TENANT_UNIVERSE_USER_GROUP_CREATE_ROUTE;
    history.push(pathName);
  } else {
    history.push(ROUTES.USER_GROUP_CREATE_ROUTE);
  }
};

const handleDeleteRequest = async ({ params, setState }) => {
  const { value } = params;
  const name = _get(value, FIELD_IDS.RECORD_GROUP_NAME);

  setState({ showDeleteModal: true, deleteName: name });
};

const handleEditRequest = ({ params, getState }) => {
  const { value } = params;
  const name = _get(value, FIELD_IDS.RECORD_GROUP_NAME);
  const { history, isTenantUniverse } = getState();
  if (!_isEmpty(name) && isTenantUniverse) {
    const pathName = `${TENANT_UNIVERSE_BASE_ROUTE}/${EDIT_USER_GROUP}/${name}`;
    history.push(pathName);
  } else if (!_isEmpty(name)) {
    const pathName = `${STUDIO_ROUTE}/${EDIT_USER_GROUP}/${name}`;
    history.push(pathName);
  }
};

const handleRowClick = ({ params, getState }) => {
  const name = _get(params, `value.original.${FIELD_IDS.RECORD_GROUP_NAME}`, '');
  const { history, isTenantUniverse } = getState();
  if (!_isEmpty(name) && isTenantUniverse) {
    const pathName = `${TENANT_UNIVERSE_BASE_ROUTE}/${EDIT_USER_GROUP}/${name}`;
    history.push(pathName);
  } else if (!_isEmpty(name)) {
    const pathName = `${STUDIO_ROUTE}/${EDIT_USER_GROUP}/${name}`;
    history.push(pathName);
  }
};

const handleConfirmDeleteRequest = async ({ setState, getState }) => {
  setState({ showDeleteModal: false, loading: true });

  const name = _get(getState(), 'deleteName');
  const data = await deleteRecordGroup(name);

  if (data) {
    const { pageSize, searchField, searchText } = getState();

    const payload = getPayload({
      searchText,
      searchField,
      pageSize,
      currentPage: CURRENT_PAGE,
      nextPageToken: EMPTY_STRING,
    });

    const userGroupData = await getAllRecordGroups(payload);
    const userGroupList = tget(userGroupData, 'hits', EMPTY_ARRAY);

    setState({
      loading: false,
      userGroupList,
      currentPage: CURRENT_PAGE,
      nextPageToken: _get(userGroupData, 'nextPageToken', ''),
    });
  }
};

const handleCloseDeleteDialog = ({ setState }) => {
  setState({ showDeleteModal: false });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [CUSTOM_ACTIONS.ADD_NEW_GROUP]: handleAddNewGroup,
  [TABLE_ACTION_TYPES.TABLE_SEARCH_FIELD]: handleSearchFieldChange,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchApply,
  [CUSTOM_ACTIONS.DELETE]: handleDeleteRequest,
  [CUSTOM_ACTIONS.EDIT]: handleEditRequest,
  [CUSTOM_ACTIONS.FETCH_DATA]: fetchAllUserGroupsList,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleRowClick,
  [CUSTOM_ACTIONS.CLOSE_DELETE_REQUEST_DIALOG]: handleCloseDeleteDialog,
  [CUSTOM_ACTIONS.CONFIRM_DELETE_REQUEST]: handleConfirmDeleteRequest,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleRefresh,
};

export default ACTION_HANDLERS;
