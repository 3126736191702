import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';

import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import CancelApprovalRequestConfirmModal from '../../../pages/devPlatformExperience/pages/approvalCentre/organisms/cancelApprovalRequestConfirmModal';

// Helpers
import { createTableProps, createFilterProps } from './helpers/approvalRequestsList.helpers';
import getColumns from './helpers/approvalRequestsList.columns';
import ACTION_HANDLERS from './helpers/approvalRequestsList.actionHandlers';

// Constants
import { ACTION_TYPES, TABLE_MANAGER_PROPS } from './constants/approvalRequestsList.constants';

const ApprovalRequestList = ({
  isMountedInsideApplication,
  isLoading,
  isConfirmModalVisible,
  isCancellingRequest,
  contentHeight,
  currentPage,
  pageSize,
  searchText,
  nextPageToken,
  filterMetadata,
  selectedFilters,
  applicationProperties,
  data,
  onRowClick,
  onAction,
}) => {
  const tableProps = useMemo(
    () => createTableProps(isLoading, currentPage, pageSize, onAction, onRowClick),
    [isLoading, currentPage, pageSize, onAction, onRowClick],
  );
  const filterProps = useMemo(
    () => createFilterProps(filterMetadata, applicationProperties, onAction),
    [filterMetadata, applicationProperties, onAction],
  );
  const columns = useMemo(() => getColumns(isMountedInsideApplication), [isMountedInsideApplication]);

  const handleCancelCancelClick = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL_CANCEL_APPROVAL_REQUEST_MODAL_CLICK });
  }, [onAction]);

  const handleCancelConfirmClick = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL_CONFIRM_APPROVAL_REQUEST_MODAL_CLICK });
  }, [onAction]);

  return (
    <Page style={{ height: contentHeight - 64 }}>
      <TableManager
        tokenPagination
        nextPageToken={nextPageToken}
        searchText={searchText}
        tableManagerProps={TABLE_MANAGER_PROPS}
        tableProps={tableProps}
        columns={columns}
        data={data}
        filterProps={filterProps}
        selectedFilters={selectedFilters}
        onAction={onAction}
      />

      <CancelApprovalRequestConfirmModal
        isVisible={isConfirmModalVisible}
        isCancellingRequest={isCancellingRequest}
        onCancelCancel={handleCancelCancelClick}
        onCancelConfirm={handleCancelConfirmClick}
      />
    </Page>
  );
};

ApprovalRequestList.propTypes = {
  isMountedInsideApplication: PropTypes.bool,
  isLoading: PropTypes.bool,
  isConfirmModalVisible: PropTypes.bool,
  isCancellingRequest: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  searchText: PropTypes.string,
  nextPageToken: PropTypes.string,
  filterMetadata: PropTypes.shape({
    GROUPS: PropTypes.array,
    CATEGORIES: PropTypes.array,
  }),
  applicationProperties: PropTypes.object,
  data: PropTypes.array,
  selectedFilters: PropTypes.array,
  onRowClick: PropTypes.func,
  onAction: PropTypes.func.isRequired,
};

ApprovalRequestList.defaultProps = {
  isMountedInsideApplication: false,
  isLoading: false,
  isConfirmModalVisible: false,
  isCancellingRequest: false,
  currentPage: DEFAULT_PAGE_INFO.start,
  pageSize: DEFAULT_PAGE_SIZE,
  searchText: EMPTY_STRING,
  nextPageToken: EMPTY_STRING,
  filterMetadata: {
    GROUPS: EMPTY_ARRAY,
    CATEGORIES: EMPTY_ARRAY,
  },
  applicationProperties: EMPTY_OBJECT,
  data: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
  onRowClick: undefined,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(ApprovalRequestList);
