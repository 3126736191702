import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import Loader from '@tekion/tekion-components/molecules/loader';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';
import withActions from '@tekion/tekion-components/connectors/withActions';

import withSize from '../../../../../../connectors/withSize';

import getFields from './applicationFormPage.fields';

import { ACTION_TYPES, CONTEXT_ID } from './applicationFormPage.constants';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import SECTIONS from './applicationFormPage.sections';
import ACTION_HANDLERS from './applicationFormPage.actionHandlers';

const ApplicationFormPage = ({ showCancelModal, savingDetails, appDataLoading, contentHeight, formMode, applicationData, errors, onAction }) => {
  const headerTitle = formMode === FORM_MODES.CREATE ? __('Create Application') : __('Update Application');

  const formSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, []);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const handleConfirmationDialogSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CONFIRM_REQUEST });
  }, [onAction]);

  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL_REQUEST });
  }, [onAction]);

  const handleRedirect = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_REDIRECTION,
    });
  }, [onAction]);

  const fields = useMemo(() => getFields(formMode), [formMode]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_MOUNT });
  }, [onAction]);

  if (appDataLoading) {
    return <Loader style={{ height: 316 }} />;
  }
  return (
    <Page>
      <Page.Header hasBack goBackHandler={handleRedirect}>
        <Heading>{headerTitle}</Heading>
      </Page.Header>
      <Page.Body className="full-width flex" style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          className="full-width p-t-12"
          contextId={CONTEXT_ID}
          fields={fields}
          sections={SECTIONS}
          values={applicationData}
          errors={errors}
          onAction={onAction}
        />
        <PropertyControlledComponent controllerProperty={showCancelModal}>
          <ConfirmationDialog
            isVisible={showCancelModal}
            title={__('Cancel Changes')}
            content={__('Are you sure you want to cancel all the changes made by you? All data will be lost if you click yes.')}
            onSubmit={handleConfirmationDialogSubmit}
            onCancel={handleConfirmationDialogCancel}
          />
        </PropertyControlledComponent>
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id={CONTEXT_ID}
          primaryActionLoading={savingDetails}
          primaryButtonLabel={__('Next')}
          onPrimaryAction={formSubmit}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

ApplicationFormPage.propTypes = {
  savingDetails: PropTypes.bool,
  appDataLoading: PropTypes.bool,
  showCancelModal: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  errors: PropTypes.object,
  applicationData: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ApplicationFormPage.defaultProps = {
  savingDetails: false,
  appDataLoading: false,
  showCancelModal: false,
  formMode: FORM_MODES.CREATE,
  errors: EMPTY_OBJECT,
  applicationData: EMPTY_OBJECT,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ApplicationFormPage);
