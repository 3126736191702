import React from 'react';
import PropTypes from 'prop-types';
import { defaultMemoize } from 'reselect';

import getAuthenticationForm from '../AuthenticationForm/AuthenticationFormContainer';
import getLoginActionHandlers from './LoginPage.actionHandler';
import { getFormFields, getFormSection } from './LoginPage.config';
import { LOGIN_FORM_CONTEXT_ID } from './LoginPage.constants';

const LoginPage = ({ history, reDirectUrl }) => {
  const getLoginFormContainer = defaultMemoize(() => getAuthenticationForm(getLoginActionHandlers(history, reDirectUrl)));
  const LoginFormContainer = getLoginFormContainer();

  return <LoginFormContainer contextId={LOGIN_FORM_CONTEXT_ID} getFields={getFormFields} getSections={getFormSection} />;
};

LoginPage.propTypes = {
  history: PropTypes.object.isRequired,
  reDirectUrl: PropTypes.string,
};

LoginPage.defaultProps = {
  reDirectUrl: '/',
};

export default LoginPage;
