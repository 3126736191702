import TextInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput/TextInput';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { fetchGroups, fetchCategories } from '../../../../../../../actions/approvalManagement.actions';

import AsyncPaginatedSelect from '../../../../../../../organisms/asyncPaginatedSelect';

import {
  generateCategoryOptionsFromHits,
  generateGroupOptionsFromHits,
  getAsyncSelectPayload,
} from '../../../../../../../helpers/approval.groupCategoryHelpers';

import { FIELD_IDS } from '../constants/approvalSettingForm.constants';

const APPROVAL_SETTING_DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInputField,
  renderOptions: {
    required: true,
    size: 6,
    label: __('Display Name'),
    placeholder: __('Enter display name'),
    validators: [isRequiredRule],
  },
};

const APPROVAL_GROUP_FIELD = {
  id: FIELD_IDS.GROUP,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    label: __('Group'),
    getPayload: getAsyncSelectPayload(FIELD_IDS.GROUP),
    getOptions: generateGroupOptionsFromHits(),
    serviceHandler: fetchGroups,
  },
};

const APPROVAL_CATEGORY_FIELD = {
  id: FIELD_IDS.CATEGORY,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    label: __('Category'),
    getPayload: getAsyncSelectPayload(FIELD_IDS.CATEGORY),
    getOptions: generateCategoryOptionsFromHits(),
    serviceHandler: fetchCategories,
  },
};

const APPROVAL_SETTING_FIELDS = {
  [FIELD_IDS.GROUP]: APPROVAL_GROUP_FIELD,
  [FIELD_IDS.CATEGORY]: APPROVAL_CATEGORY_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: APPROVAL_SETTING_DISPLAY_NAME_FIELD,
};

export default APPROVAL_SETTING_FIELDS;
