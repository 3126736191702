import COLUMN_IDS from './recordSharingRuleList.columnIds';

const DEFAULT_RULE_LIST_PROPS = {
  currentPage: 0,
  pageSize: 50,
  searchField: COLUMN_IDS.RULE_NAME,
};

const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

const ASSIGN_TYPES = {
  USER: 'user',
  USER_GROUP: 'userGroup',
};

const SHARING_RULE_MODAL_TITLE = {
  CREATE_RULE: __('Create New Rule'),
  EDIT_RULE: __('Edit Rule'),
};

const PRINCIPAL_TYPES = {
  USER: 'USER',
};

const DELETE_RULE_CONTENT = __('Are you sure you want to delete sharing rule? It will be permanently deleted if you click yes.');

export { DEFAULT_RULE_LIST_PROPS, TABLE_CONSTANTS, ASSIGN_TYPES, SHARING_RULE_MODAL_TITLE, PRINCIPAL_TYPES, DELETE_RULE_CONTENT };
