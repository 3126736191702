import React from 'react';
import { defaultMemoize } from 'reselect';

import _pick from 'lodash/pick';
import _isNil from 'lodash/isNil';
import _castArray from 'lodash/castArray';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _entries from 'lodash/entries';
import _reduce from 'lodash/reduce';
import _valuesIn from 'lodash/valuesIn';
import _isFunction from 'lodash/isFunction';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';

import InfoBadge from '../../../../../../atoms/infoBadge';

import SELECT_OPTIONS_FORM_FIELD_IDS from '../components/selectOptionsForm/constants/selectOptionsForm.fieldsIds';
import COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS from '../components/complexFieldDetailsForm/constants/complexFieldDetailsForm.fieldIds';
import TEXT_PROPERTIES_FORM_FIELD_IDS from '../components/textPropertiesForm/constants/textPropertiesForm.fieldIds';
import RELATIONSHIP_DETAILS_FORM_FIELD_IDS from '../components/relationshipDetailsForm/constants/relationshipDetailsForm.fieldIds';
import FIELDS_FORM_FIELD_IDS from '../constants/fieldsForm.fieldIds';
import GENERAL_DETAILS_FORM_FIELD_IDS from '../components/generalDetailsForm/constants/generalDetailsForm.fieldIds';
import LOOKUP_DETAILS_FORM_FIELD_IDS from '../components/lookupDetailsForm/constants/lookupDetailsForm.fieldIds';
import VIEW_DETAILS_FORM_FIELD_IDS from '../components/viewDetailsForm/constants/viewDetailsForm.fieldIds';
import PROPERTIES_FORM_FIELD_IDS from '../components/propertiesForm/constants/propertiesForm.fieldIds';
import FIELD_TYPES from '../../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../../constants/fieldDefinition.dataTypes';

const getGeneralDetailsFormPayload = (formValues) => {
  const fieldType = _get(formValues, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE);

  if (fieldType === FIELD_TYPES.RELATIONSHIP) {
    return {
      ...formValues,
      [GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]: DATA_TYPES.TEXT,
    };
  }

  return formValues;
};

const getDependencyConfigFormPayload = (formValues) => formValues;

const getViewDetailsFormPayload = (formValues) => formValues;

const getChildAggregateSummaryFormPayload = (formValues) => ({ aggregateSummaryFieldInfo: formValues });

const getRelationshipDetailsFormPayload = (formValues) => ({
  lookupField: formValues,
});

const getTextPropertiesFormPayload = (formValues) => {
  const pattern = _get(formValues, TEXT_PROPERTIES_FORM_FIELD_IDS.PATTERN);

  return {
    constraints: [
      {
        type: 'STRING_LENGTH',
        minLength: _get(formValues, TEXT_PROPERTIES_FORM_FIELD_IDS.MIN_TEXT_LENGTH),
        maxLength: _get(formValues, TEXT_PROPERTIES_FORM_FIELD_IDS.MAX_TEXT_LENGTH),
      },
      ...(!_isEmpty(pattern) ? [{ type: 'REGEX', pattern }] : EMPTY_ARRAY),
    ],
  };
};

const getSelectOptionsFormPayload = (formValues) => ({ optionConfig: formValues });

const getComplexFieldFormPayload = (formValues) => ({ complexFieldDefinition: formValues });

const getPropertiesFormPayload = (formValues) => formValues;

const getLookupDetailsFormPayload = (formValues) => formValues;

const getMediaPropertiesFormPayload = (formValues) => ({ [FIELDS_FORM_FIELD_IDS.MEDIA_PROPERTIES_FORM]: formValues });

const FORM_IDS_PAYLOAD_MAPPER = {
  [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM]: getGeneralDetailsFormPayload,
  [FIELDS_FORM_FIELD_IDS.CHILD_AGGREGATE_SUMMARY_FORM]: getChildAggregateSummaryFormPayload,
  [FIELDS_FORM_FIELD_IDS.DEPENDENCY_CONFIG_FORM]: getDependencyConfigFormPayload,
  [FIELDS_FORM_FIELD_IDS.COMPLEX_FIELD_DETAILS_FORM]: getComplexFieldFormPayload,
  [FIELDS_FORM_FIELD_IDS.LOOKUP_DETAILS_FORM]: getLookupDetailsFormPayload,
  [FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM]: getPropertiesFormPayload,
  [FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM]: getSelectOptionsFormPayload,
  [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM]: getTextPropertiesFormPayload,
  [FIELDS_FORM_FIELD_IDS.VIEW_DETAILS_FORM]: getViewDetailsFormPayload,
  [FIELDS_FORM_FIELD_IDS.RELATIONSHIP_DETAILS_FORM]: getRelationshipDetailsFormPayload,
  [FIELDS_FORM_FIELD_IDS.MEDIA_PROPERTIES_FORM]: getMediaPropertiesFormPayload,
};

const getPayloadForSection = (formId) => {
  const payloadMapper = _get(FORM_IDS_PAYLOAD_MAPPER, formId);

  return (formValues) => {
    const payload = payloadMapper(tget(formValues, formId, EMPTY_OBJECT));

    return payload;
  };
};

const getSelectOptionsFormValues = (fieldData) => {
  const optionConfig = _get(fieldData, 'optionConfig');

  if (_isEmpty(optionConfig)) {
    return {};
  }

  const options = _get(optionConfig, 'options');
  const controllingFieldName = _get(optionConfig, 'controllingFieldName');
  const controlledOptions = _get(optionConfig, 'controllingOptionsConfigs');

  return {
    [SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS]: options,
    ...(!_isEmpty(controllingFieldName) && { [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD]: controllingFieldName }),
    ...(!_isEmpty(controlledOptions) && { [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS]: controlledOptions }),
  };
};

const getComplexFieldFormValues = (fieldData) => ({
  [COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS.ENTITY_NAME]: _get(fieldData, ['complexFieldDefinition', COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS.ENTITY_NAME]),
});

const getRelationshipDetailsFormValues = (fieldData) => {
  const lookupField = _get(fieldData, 'lookupField');
  const displayField = _get(lookupField, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_DISPLAY);
  const entityType = _get(lookupField, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY);
  const field = _get(lookupField, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP);

  return {
    ...(!_isEmpty(displayField) && { [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_DISPLAY]: displayField }),
    ...(!_isEmpty(lookupField) && { [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY]: entityType }),
    ...(!_isEmpty(field) && { [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP]: field }),
    [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.GROUPS_ALLOWED]: _get(fieldData, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.GROUPS_ALLOWED, false),
  };
};

const getGeneralDetailsFormValues = (fieldData) => _pick(fieldData, _valuesIn(GENERAL_DETAILS_FORM_FIELD_IDS));

const getLookupDetailsFormValues = (fieldData) => _pick(fieldData, _valuesIn(LOOKUP_DETAILS_FORM_FIELD_IDS));

const getViewDetailsFormValues = (fieldData) => _pick(fieldData, _valuesIn(VIEW_DETAILS_FORM_FIELD_IDS));

const getPropertiesFormValues = (fieldData) => _pick(fieldData, _valuesIn(PROPERTIES_FORM_FIELD_IDS));

const getChildAggregateSummaryFormValues = (fieldData) => {
  const aggregateSummaryFieldInfo = _get(fieldData, 'aggregateSummaryFieldInfo');

  return aggregateSummaryFieldInfo;
};

const getDependencyConfigFormValues = (fieldData) => {
  const dependencyConfigs = _get(fieldData, 'dependencyConfigs');

  if (!_isEmpty(dependencyConfigs)) {
    return { dependencyConfigs };
  }

  return {};
};

const getTextPropertiesFormValues = (fieldData) => {
  const { constraints = EMPTY_ARRAY } = fieldData;

  return _reduce(
    constraints,
    (textPropertiesFormValues, constraint) => {
      const type = _get(constraint, 'type');

      if (type === 'STRING_LENGTH') {
        _set(textPropertiesFormValues, TEXT_PROPERTIES_FORM_FIELD_IDS.MIN_TEXT_LENGTH, _get(constraint, 'minLength'));
        _set(textPropertiesFormValues, TEXT_PROPERTIES_FORM_FIELD_IDS.MAX_TEXT_LENGTH, _get(constraint, 'maxLength'));
      }

      if (type === 'REGEX') {
        _set(textPropertiesFormValues, TEXT_PROPERTIES_FORM_FIELD_IDS.PATTERN, _get(constraint, 'pattern'));
      }

      return textPropertiesFormValues;
    },
    {},
  );
};

const getMediaPropertiesFormValues = (fieldData) => tget(fieldData, FIELDS_FORM_FIELD_IDS.MEDIA_PROPERTIES_FORM, {});

const FORM_IDS_FORM_VALUES_MAPPER = {
  [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM]: getGeneralDetailsFormValues,
  [FIELDS_FORM_FIELD_IDS.CHILD_AGGREGATE_SUMMARY_FORM]: getChildAggregateSummaryFormValues,
  [FIELDS_FORM_FIELD_IDS.DEPENDENCY_CONFIG_FORM]: getDependencyConfigFormValues,
  [FIELDS_FORM_FIELD_IDS.COMPLEX_FIELD_DETAILS_FORM]: getComplexFieldFormValues,
  [FIELDS_FORM_FIELD_IDS.LOOKUP_DETAILS_FORM]: getLookupDetailsFormValues,
  [FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM]: getPropertiesFormValues,
  [FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM]: getSelectOptionsFormValues,
  [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM]: getTextPropertiesFormValues,
  [FIELDS_FORM_FIELD_IDS.VIEW_DETAILS_FORM]: getViewDetailsFormValues,
  [FIELDS_FORM_FIELD_IDS.RELATIONSHIP_DETAILS_FORM]: getRelationshipDetailsFormValues,
  [FIELDS_FORM_FIELD_IDS.MEDIA_PROPERTIES_FORM]: getMediaPropertiesFormValues,
};

const getInitialState = (fieldData) =>
  _reduce(FIELDS_FORM_FIELD_IDS, (initialState, formId) => _set(initialState, formId, _get(FORM_IDS_FORM_VALUES_MAPPER, formId)(fieldData)), {});

const getInfoBadge = (label, helpText = NO_DATA) => <InfoBadge label={label} helpText={helpText} />;

const mergeRenderOptions = (defaultRenderOptions, formFieldIds, renderOptions, getFieldLabel) =>
  _reduce(
    formFieldIds,
    (mergedRenderOptions, fieldId) => {
      const defaultRenderOptionsForField = _get(defaultRenderOptions, fieldId, EMPTY_ARRAY);
      const extraRenderOptionsObjForField = _get(renderOptions, fieldId, EMPTY_OBJECT);
      const extraRenderOptionsForField = _map(_entries(extraRenderOptionsObjForField, EMPTY_OBJECT), ([path, value]) => ({
        path,
        value,
      }));
      const helpText = _get(extraRenderOptionsObjForField, 'helpText');
      const isHelpTextInfoBadgeNeeded = !_isEmpty(helpText) && _isFunction(getFieldLabel);

      return {
        ...mergedRenderOptions,
        [fieldId]: [
          ...defaultRenderOptionsForField,
          ...extraRenderOptionsForField,
          ...(isHelpTextInfoBadgeNeeded ? [{ path: 'checkboxLabel', value: getInfoBadge(getFieldLabel(fieldId), helpText) }] : []),
        ],
      };
    },
    {},
  );

const inputTableColumnRequiredValidator = (columns) => (fieldId, allValues) => {
  const columnIds = _castArray(columns);

  let isValid = true;

  const errors = _map(allValues, (value) => {
    const error = _reduce(
      columnIds,
      (acc, columnId) => {
        const columnValue = getArraySafeValue(_get(value, columnId));

        if (_isEmpty(columnValue)) {
          isValid = false;
          _set(acc, columnId, __('This field is mandatory'));
        }

        return acc;
      },
      {},
    );

    return error;
  });

  return isValid ? { isValid } : { isValid, message: errors };
};

const inputTableUniqueColumnValidator = (columns) => (fieldId, allValues) => {
  const columnIds = _castArray(columns);
  const prevOccurrenceOfValue = _reduce(columnIds, (acc, columnId) => _set(acc, columnId, {}), {});
  let isValid = true;
  const errors = _map(allValues, (value, index) => {
    const error = _reduce(
      columnIds,
      (acc, columnId) => {
        const columnValue = _get(value, columnId);
        const prevIndex = _get(prevOccurrenceOfValue, [columnId, columnValue]);

        if (_isNil(prevIndex) || _isNil(columnValue)) {
          _set(prevOccurrenceOfValue, [columnId, columnValue], index);
        } else {
          isValid = false;
          _set(acc, columnId, __('Duplicate value found'));
        }

        return acc;
      },
      {},
    );

    return error;
  });
  return isValid ? { isValid } : { isValid, message: errors };
};

const getAsyncPaginatedSelectEntityOptions = defaultMemoize((data) =>
  _map(data, (entity) => ({ label: tget(entity, 'displayName', NO_DATA), value: tget(entity, 'name', EMPTY_STRING) })),
);

export {
  getPayloadForSection,
  getInitialState,
  mergeRenderOptions,
  getInfoBadge,
  inputTableUniqueColumnValidator,
  inputTableColumnRequiredValidator,
  getAsyncPaginatedSelectEntityOptions,
};
