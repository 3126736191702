exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".recordSharingRuleAssignmentDetailTable_assignmentContainer__39B5qnhtBq{width:100%}[dir=ltr] .recordSharingRuleAssignmentDetailTable_assignmentContainer__39B5qnhtBq{padding-right:1rem}[dir=rtl] .recordSharingRuleAssignmentDetailTable_assignmentContainer__39B5qnhtBq{padding-left:1rem}.recordSharingRuleAssignmentDetailTable_assignmentContainer__39B5qnhtBq .recordSharingRuleAssignmentDetailTable_search__it4iFcpxbN{display:flex;justify-content:flex-end;width:100%;height:3.2rem}.recordSharingRuleAssignmentDetailTable_assignmentContainer__39B5qnhtBq .recordSharingRuleAssignmentDetailTable_searchContainer__4xKuzdfN6k{width:39rem}[dir] .recordSharingRuleAssignmentDetailTable_assignmentContainer__39B5qnhtBq .recordSharingRuleAssignmentDetailTable_searchContainer__4xKuzdfN6k{padding-top:1rem;background-color:#fff}.recordSharingRuleAssignmentDetailTable_assignmentContainer__39B5qnhtBq .recordSharingRuleAssignmentDetailTable_assignmentTableContainer__pDhkHbXj5f{height:30rem;width:39rem;overflow-y:auto}[dir] .recordSharingRuleAssignmentDetailTable_assignmentContainer__39B5qnhtBq .recordSharingRuleAssignmentDetailTable_assignmentTableContainer__pDhkHbXj5f{border:.05rem solid gray;background-color:#fff}", ""]);

// Exports
exports.locals = {
	"assignmentContainer": "recordSharingRuleAssignmentDetailTable_assignmentContainer__39B5qnhtBq",
	"search": "recordSharingRuleAssignmentDetailTable_search__it4iFcpxbN",
	"searchContainer": "recordSharingRuleAssignmentDetailTable_searchContainer__4xKuzdfN6k",
	"assignmentTableContainer": "recordSharingRuleAssignmentDetailTable_assignmentTableContainer__pDhkHbXj5f"
};