import _castArray from 'lodash/castArray';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';

import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { NOTIFICATION_STATUS_TYPES, NOTIFICATION_TYPES, STATUS_UPDATE_REQUEST_TYPES } from '../../constants/notification.constants';

import communicationServices from '../../services/communication.services';

const markAllNotificationsSeen = async (notificationType) => {
  try {
    const payload = {
      updateType: STATUS_UPDATE_REQUEST_TYPES.NOTIFICATION_TYPE,
      notificationTypes: [notificationType],
      communicationStatus: NOTIFICATION_STATUS_TYPES.SEEN,
    };

    const response = await communicationServices.updateStatus(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to mark all as seen.')));
    return EMPTY_OBJECT;
  }
};

const markAllNotificationsAsRead = async (notificationType) => {
  try {
    const payload = {
      updateType: STATUS_UPDATE_REQUEST_TYPES.NOTIFICATION_TYPE,
      notificationTypes: [notificationType],
      communicationStatus: NOTIFICATION_STATUS_TYPES.READ,
    };

    const response = await communicationServices.updateStatus(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to mark all as read, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const markNotificationAsRead = async (notificationId, notificationStatus = NOTIFICATION_STATUS_TYPES.NEW) => {
  try {
    if (notificationStatus !== NOTIFICATION_STATUS_TYPES.NEW) {
      return EMPTY_OBJECT;
    }

    const payload = {
      updateType: STATUS_UPDATE_REQUEST_TYPES.NOTIFICATION_ID,
      notificationIds: _castArray(notificationId),
      communicationStatus: NOTIFICATION_STATUS_TYPES.READ,
    };

    const response = await communicationServices.updateStatus(payload);

    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to mark as read, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const clearAllNotifications = async (notificationType) => {
  try {
    const payload = {
      updateType: STATUS_UPDATE_REQUEST_TYPES.NOTIFICATION_TYPE,
      notificationTypes: [notificationType],
      communicationStatus: NOTIFICATION_STATUS_TYPES.DELETED,
    };

    const response = await communicationServices.updateStatus(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to clear all, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const markGroupAsRead = async (groupKey) => {
  try {
    const payload = {
      updateType: STATUS_UPDATE_REQUEST_TYPES.GROUP,
      groupKey,
      communicationStatus: NOTIFICATION_STATUS_TYPES.READ,
      notificationTypes: [NOTIFICATION_TYPES.NUDGE],
    };

    const response = await communicationServices.updateStatus(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to mark group {{ groupKey }} as read, please try again later.', { groupKey })));
    return EMPTY_OBJECT;
  }
};

const clearGroupNotifications = async (groupKey) => {
  try {
    const payload = {
      updateType: STATUS_UPDATE_REQUEST_TYPES.GROUP,
      groupKey,
      communicationStatus: NOTIFICATION_STATUS_TYPES.DELETED,
      notificationTypes: [NOTIFICATION_TYPES.NUDGE],
    };

    const response = await communicationServices.updateStatus(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to clear the group {{ groupKey }}, please try again later.', { groupKey })));
    return EMPTY_OBJECT;
  }
};

const clearNotifications = async (notificationIds) => {
  try {
    const payload = {
      updateType: STATUS_UPDATE_REQUEST_TYPES.NOTIFICATION_ID,
      notificationIds: _castArray(notificationIds),
      communicationStatus: NOTIFICATION_STATUS_TYPES.DELETED,
    };

    const response = await communicationServices.updateStatus(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to clear the notifications, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export default {
  markAllNotificationsSeen,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  clearAllNotifications,
  markGroupAsRead,
  clearGroupNotifications,
  clearNotifications,
};
