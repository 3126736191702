import { ELEMENT_IMAGE } from '@udecode/plate-image';
import { ELEMENT_LINK } from '@udecode/plate-link';
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote';
import { ELEMENT_CODE_BLOCK } from '@udecode/plate-code-block';
import { ELEMENT_TABLE } from '@udecode/plate-table';

import { Node, Text } from 'slate';
import _map from 'lodash/map';
import _join from 'lodash/join';
import _isArray from 'lodash/isArray';
import _includes from 'lodash/includes';

// constants
import { ELEMENT_VIDEO } from '../plugins/video';
import SUPPORTED_INLINE_NODES from '../constants/richTextEditor.inlineNodes';

// recursive logic to get the display string
const serializeNodeToDisplayText = (node) => {
  if (Text.isText(node)) {
    const { text = '' } = node;
    return text;
  }
  if (_includes(SUPPORTED_INLINE_NODES, node?.type)) {
    return Node.string(node);
  }

  // eslint-disable-next-line no-use-before-define
  const nodeDisplayText = getPlainTextFromRTEJSON(node?.children);
  if (nodeDisplayText === '') {
    return nodeDisplayText;
  }
  return nodeDisplayText.concat(' ');
};

const serializeNodeToDisplayParagraphText = (node) => {
  if (Text.isText(node)) {
    const { text = '' } = node;
    return text;
  }

  if (_includes([ELEMENT_CODE_BLOCK, ELEMENT_TABLE, ELEMENT_IMAGE, ELEMENT_VIDEO, ELEMENT_LINK, ELEMENT_BLOCKQUOTE], node?.type)) {
    return '';
  }

  // eslint-disable-next-line no-use-before-define
  const nodeDisplayText = getParagraphText(node?.children);
  if (nodeDisplayText === '') {
    return nodeDisplayText;
  }
  return nodeDisplayText.concat(' ');
};

const getPlainTextFromRTEJSON = (nodes) => {
  if (nodes && _isArray(nodes)) {
    return _join(_map(nodes, serializeNodeToDisplayText), '');
  }
  return '';
};

export const getParagraphText = (nodes) => {
  if (nodes && _isArray(nodes)) {
    return _join(_map(nodes, serializeNodeToDisplayParagraphText), '');
  }
  return '';
};

export default getPlainTextFromRTEJSON;
