const FIELD_IDS = {
  NAME: 'NAME',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  COUNTRY_CODE: 'countryCode',
  EMAIL: 'email',
  HOME_WORKSPACE: 'homeWorkspaceId',
  USER_TYPE: 'userType',
  ROLE_NAME: 'roleName',
  PROFILE_NAME: 'profileName',
  DISPLAY_NAME: 'displayName',
  ACTIVE: 'active',
  LOCK_USER: 'lockUser',
  PROFILE_PICTURE: 'profilePicture',
  WORKSPACE_SPECIFIC_INFOS: 'workspaceSpecificInfos',
};

export default FIELD_IDS;
