import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _find from 'lodash/find';

import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import NullComponent from '@tekion/tekion-components/atoms/nullComponent/NullComponent';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { checkForShowingPrefilledValue, getRenderOptions, getRendererForField } from './prefilledValueField.helper';
import { getSimpleFieldNameFromColumn } from '../../../../utils';
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';

import entityReader from '../../../../readers/entity.reader';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

const PrefilledValueField = ({ errors, entity, selectedViewComponentProperties, onAction, ...restProps }) => {
  const selectedViewComponentFieldName = useMemo(
    () => getSimpleFieldNameFromColumn(getArraySafeValue(_get(selectedViewComponentProperties, 'fieldNames', ['']))),
    [selectedViewComponentProperties],
  );

  const fieldDefinitionForSelectedFieldName = _find(entityReader.fieldDefinitions(entity), { name: selectedViewComponentFieldName });

  const showPrefilledValue = useMemo(() => checkForShowingPrefilledValue(fieldDefinitionForSelectedFieldName), [fieldDefinitionForSelectedFieldName]);

  const Component = useMemo(() => getRendererForField(fieldDefinitionForSelectedFieldName), [fieldDefinitionForSelectedFieldName]);

  const rendererOptions = useMemo(() => getRenderOptions(fieldDefinitionForSelectedFieldName), [fieldDefinitionForSelectedFieldName]);

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          let value = _get(payload, 'value');
          if (fieldDefinitionReader.fieldType(fieldDefinitionForSelectedFieldName) === FIELD_TYPES.RICH_TEXT_EDITOR) {
            value = _get(value, 'htmlContent');
          }
          onAction({
            type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
            payload: { id: ALL_VIEW_PROPERTY_KEYS.PREFILLED_VALUE, value },
          });
          break;
        }
        default:
          onAction(action);
      }
    },
    [fieldDefinitionForSelectedFieldName, onAction],
  );

  return (
    <PropertyControlledComponent controllerProperty={showPrefilledValue} fallback={<NullComponent />}>
      <div className="full-width">
        <Component {...restProps} {...rendererOptions} error={errors} onAction={handleAction} />
      </div>
    </PropertyControlledComponent>
  );
};

PrefilledValueField.propTypes = {
  errors: PropTypes.string,
  entity: PropTypes.object.isRequired,
  selectedViewComponentProperties: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

PrefilledValueField.defaultProps = {
  errors: EMPTY_STRING,
  selectedViewComponentProperties: EMPTY_OBJECT,
};

export default PrefilledValueField;
