import produce from 'immer';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _snakeCase from 'lodash/snakeCase';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import {
  createAuthProvider,
  fetchAuthProviderByName,
  fetchAuthProviderByNameWithReveal,
  updateAuthProviderByName,
} from '../../../../../../../actions/authProvider.actions';

import { getFlattenedObject } from '../../../../../../../helpers/general.helpers';

import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import { FORM_MODES } from '../../../../../../../constants/general.constants';
import PAGE_IDS from '../../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/authProviderForm.actionTypes';
import { FIELD_IDS } from '../constants/authProviderForm.constants';

const handleInit = async ({ getState, setState }) => {
  const { match } = getState();
  const authProviderName = _get(match, 'params.authProviderName');

  if (_isEmpty(authProviderName)) {
    setState({ formMode: FORM_MODES.CREATE });
  } else {
    setState({ isDataLoading: true, formMode: FORM_MODES.EDIT });

    const authFormValue = await fetchAuthProviderByName(authProviderName);
    const selectedProviderType = tget(authFormValue, [FIELD_IDS.PROVIDER_TYPE]);

    setState({ authFormValue, isDataLoading: false, selectedProviderType });
  }
};

const handleFieldChange = ({ getState, setState, params }) => {
  const { formMode, selectedProviderType = EMPTY_STRING, disableAutoFillForName = false } = getState();
  const { id, value } = params;

  setState(
    produce((draft) => {
      if (id === FIELD_IDS.DISPLAY_NAME && formMode === FORM_MODES.CREATE && !disableAutoFillForName) {
        _set(draft, `authFormValue.${FIELD_IDS.NAME}`, _snakeCase(value));
      } else if (id === FIELD_IDS.NAME) {
        _set(draft, 'disableAutoFillForName', true);
      } else if (id === FIELD_IDS.PROVIDER_TYPE && formMode === FORM_MODES.EDIT) {
        _set(draft, 'isProviderTypeChanged', selectedProviderType !== value);
      }
      _set(draft, `authFormValue.${id}`, value);
    }),
  );
};

const handleErrors = ({ setState, params }) => {
  const { errors } = params;

  setState({ errors });
};

const handleRedirection = ({ getState }) => {
  const { history, authFormValue } = getState();
  const historyState = tget(history, 'location.state', EMPTY_OBJECT);
  const isRedirectedFromExternalCredential = tget(historyState, 'isRedirectedFromExternalCredential', false);

  if (isRedirectedFromExternalCredential) {
    const value = _get(authFormValue, FIELD_IDS.NAME);
    const label = _get(authFormValue, FIELD_IDS.DISPLAY_NAME);
    const path = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.EXTERNAL_CREDENTIAL_CREATE}`;

    history.push({ pathname: path, state: { ...historyState, isRedirectedBackFromAuthProvider: true, authProviderDetails: { value, label } } });
  } else {
    const path = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.AUTH_PROVIDERS_LIST}`;
    history.push(path);
  }
};

const handleSubmit = async ({ getState, setState }) => {
  const { authFormValue, formMode } = getState();
  const authProviderName = _get(authFormValue, FIELD_IDS.NAME);
  let response;

  setState({ isSaveLoading: true });

  if (formMode === FORM_MODES.CREATE) {
    response = await createAuthProvider(authFormValue);
  } else if (formMode === FORM_MODES.EDIT) {
    response = await updateAuthProviderByName(authProviderName, authFormValue);
  }

  if (_get(response, [FIELD_IDS.NAME]) === authProviderName) {
    handleRedirection({ getState });
  }

  setState({ isSaveLoading: false });
};

const handleOnReveal = async ({ getState, setState }) => {
  const { authFormValue, isAuthDetailSectionVisible = false } = getState();
  const authProviderName = _get(authFormValue, FIELD_IDS.NAME);

  if (!isAuthDetailSectionVisible) {
    setState({ isAuthDetailLoading: true });

    const response = await fetchAuthProviderByNameWithReveal(authProviderName);
    const flattenedAuthDetailFormValue = getFlattenedObject(response);

    setState({ authFormValue: flattenedAuthDetailFormValue, isAuthDetailLoading: false, isAuthDetailSectionVisible: true });
  } else {
    setState({ isAuthDetailSectionVisible: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
  [ACTION_TYPES.ON_REVEAL_CLICK]: handleOnReveal,
};

export default ACTION_HANDLERS;
