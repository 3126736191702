import React, { useEffect, useMemo } from 'react';
import compose from 'recompose/compose';
import renameProps from 'recompose/renameProps';
import PropTypes from 'prop-types';

import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import PROPERTIES_FORM_ACTION_HANDLERS from './helpers/propertiesForm.actionHandlers';
import getFields from './helpers/propertiesForm.fields';

import getSections from './constants/propertiesForm.sections';
import ACTION_TYPES from '../../constants/fieldsForm.actionTypes';
import FIELDS_FORM_FIELD_IDS from '../../constants/fieldsForm.fieldIds';

const PropertiesForm = ({ renderOptions, values, error, onAction }) => {
  const fields = useMemo(() => getFields(renderOptions), [renderOptions]);
  const sections = useMemo(() => getSections(values), [values]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_RENDER_OPTIONS_CHANGE, payload: { renderOptions } });
  }, [renderOptions, onAction]);

  return (
    <FormWithSubmission
      className="full-width"
      contextId={FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM}
      values={values}
      errors={error}
      fields={fields}
      sections={sections}
      onAction={onAction}
    />
  );
};

PropertiesForm.propTypes = {
  renderOptions: PropTypes.object,
  values: PropTypes.object,
  error: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

PropertiesForm.defaultProps = {
  renderOptions: EMPTY_OBJECT,
  values: EMPTY_OBJECT,
  error: undefined,
};

export default compose(
  renameProps({ onAction: 'fieldsFormOnAction', value: 'fieldsFormPropertiesValue' }),
  withActions(EMPTY_OBJECT, PROPERTIES_FORM_ACTION_HANDLERS),
)(PropertiesForm);
