import _isEmpty from 'lodash/isEmpty';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

// Constants
import SUBSCRIPTION_FIELD_IDS from '../constants/subscriptionFormModal.fieldIds';

// Styles
import styles from '../subscriptionFormModal.module.scss';

const rowCheck = {
  [SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_CONDITION]: ({ entityName, isEntityFieldsLoading }) => !isEntityFieldsLoading && !_isEmpty(entityName),
};

const getSections = (entityName, isEntityFieldsLoading) => [
  {
    rows: [{ columns: [SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_TYPE, SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_ENTITY_NAME] }],
  },
  {
    className: styles.fullWidthSection,
    rows: [{ columns: filterRows([SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_CONDITION], { entityName, isEntityFieldsLoading }, rowCheck) }],
  },
];

export default getSections;
