import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const WORKSPACE_USER_API_ROUTES = {
  SEARCH_USER_DATA: 'core/u/cas/custom-entities/user/search',
  UNIVERSE_USER_DATA: 'core/u/cas/custom-entities/user',
  WORKSPACE_USER: 'core/u/users',
};

const getWorkspaceUserById = (id) => ApiService.get(`${WORKSPACE_USER_API_ROUTES.UNIVERSE_USER_DATA}/${id}`);
const fetchWorkspaceUserList = (payload = EMPTY_OBJECT) => ApiService.post(WORKSPACE_USER_API_ROUTES.SEARCH_USER_DATA, payload);
const saveWorkspaceUserData = (payload = EMPTY_OBJECT) => ApiService.post(WORKSPACE_USER_API_ROUTES.UNIVERSE_USER_DATA, payload);
const editWorkspaceUserData = (id, payload = EMPTY_OBJECT) => ApiService.put(`${WORKSPACE_USER_API_ROUTES.UNIVERSE_USER_DATA}/${id}`, payload);
const removeWorkspaceUserById = (userId, workspaceId) => ApiService.delete(`${WORKSPACE_USER_API_ROUTES.WORKSPACE_USER}/${userId}/${workspaceId}`);
const fetchUserWorkspaceDetailsById = (userId) => ApiService.get(`${WORKSPACE_USER_API_ROUTES.WORKSPACE_USER}/${userId}/details`);

export default {
  removeWorkspaceUserById,
  getWorkspaceUserById,
  fetchWorkspaceUserList,
  saveWorkspaceUserData,
  editWorkspaceUserData,
  fetchUserWorkspaceDetailsById,
};
