import React, { useEffect, useMemo, useCallback } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _get from 'lodash/get';
import _noop from 'lodash/noop';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Tekion-components
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import Tabs from '@tekion/tekion-components/molecules/Tabs';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Modal from '@tekion/tekion-components/molecules/Modal';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

// Tekion-connectors
import withActions from '@tekion/tekion-components/connectors/withActions';
import { DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';

// Connectors
import withSize from '../../../../connectors/withSize';

// Helpers
import { createTableProps, getFilterProps, getSearchableColumns, getTableSubHeaderProps } from './helpers/viewBuilderList.helpers';
import { CLONE_VIEW_MODAL_FORM_SECTIONS, CLONE_VIEW_MODAL_FORM_FIELDS } from './helpers/viewBuilderCloneModal.formConfig';
import ACTION_HANDLERS from './viewBuilderList.actionHandlers';

// Constants
import { COLUMN_CONFIG } from './helpers/viewBuilderList.columns';
import {
  INITIAL_STATE_VIEW_BUILDER_LIST_PROPS,
  TABLE_MANAGER_PROPS,
  CLONE_VIEW_MODAL_FORM_CONTEXT_ID,
  VIEW_BUILDER_LIST_TAB_IDS,
  VIEW_BUILDER_LIST_TAB_TITLES,
} from './constants/viewBuilderList.general';

import ACTION_TYPES from './constants/viewBuilderList.actionTypes';

const ViewBuilderList = (props) => {
  const {
    isLoading,
    isCloneViewModalVisible,
    isCloneViewModalDataSubmitting,
    contentHeight,
    contentWidth,
    totalCount,
    pageSize,
    currentPage,
    nextPageToken,
    searchText,
    searchField,
    selectedFilterGroup,
    cloneViewModalFormData,
    cloneViewModalFormErrors,
    match,
    selectedFilters,
    data,
    onAction,
  } = props;

  const selectedTabId = _get(match, 'params.tabId', VIEW_BUILDER_LIST_TAB_IDS.ENTITY_VIEWS);

  const tableProps = useMemo(
    () => createTableProps(isLoading, totalCount, pageSize, currentPage, selectedTabId, onAction),
    [isLoading, totalCount, pageSize, currentPage, selectedTabId, onAction],
  );

  const searchableColumns = useMemo(() => getSearchableColumns(selectedTabId), [selectedTabId]);

  const handleTabClick = useCallback(
    (tabId) =>
      onAction({
        type: ACTION_TYPES.ON_VIEWS_TAB_CHANGE,
        payload: { tabId },
      }),
    [onAction],
  );

  const handleCloneViewModalClose = useCallback(() => {
    onAction({ type: ACTION_TYPES.CLONE_VIEW_MODAL_CLOSE });
  }, [onAction]);

  const handleCloneViewModalSubmit = useCallback(() => {
    triggerSubmit(CLONE_VIEW_MODAL_FORM_CONTEXT_ID);
  }, []);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_VIEWS_TAB_CHANGE });
  }, [onAction]);

  return (
    <Page>
      <Page.Body style={{ width: contentWidth }}>
        <Tabs activeKey={selectedTabId} onTabClick={handleTabClick}>
          {_map(VIEW_BUILDER_LIST_TAB_IDS, (tabName) => {
            const tabTitle = VIEW_BUILDER_LIST_TAB_TITLES[tabName];
            return (
              <Tabs.TabPane tab={tabTitle} key={tabName}>
                <div style={{ height: contentHeight }}>
                  <TableManager
                    tokenPagination
                    subHeaderProps={getTableSubHeaderProps(tabName)}
                    tableManagerProps={TABLE_MANAGER_PROPS}
                    nextPageToken={nextPageToken}
                    pageSize={pageSize}
                    searchText={searchText}
                    searchField={searchField}
                    searchableFieldsOptions={searchableColumns}
                    columns={COLUMN_CONFIG[selectedTabId]}
                    tableProps={tableProps}
                    filterProps={getFilterProps(selectedFilters, selectedFilterGroup)}
                    data={data}
                    onAction={onAction}
                  />
                </div>
              </Tabs.TabPane>
            );
          })}
        </Tabs>
        <PropertyControlledComponent controllerProperty={isCloneViewModalVisible}>
          <Modal
            destroyOnClose
            visible={isCloneViewModalVisible}
            loading={isCloneViewModalDataSubmitting}
            width={Modal.SIZES.MD}
            title={__('Clone View')}
            onCancel={handleCloneViewModalClose}
            onSubmit={handleCloneViewModalSubmit}
          >
            <FormWithSubmission
              contextId={CLONE_VIEW_MODAL_FORM_CONTEXT_ID}
              sections={CLONE_VIEW_MODAL_FORM_SECTIONS}
              fields={CLONE_VIEW_MODAL_FORM_FIELDS}
              values={cloneViewModalFormData}
              errors={cloneViewModalFormErrors}
              onAction={onAction}
            />
          </Modal>
        </PropertyControlledComponent>
      </Page.Body>
    </Page>
  );
};

ViewBuilderList.propTypes = {
  isLoading: PropTypes.bool,
  isCloneViewModalVisible: PropTypes.bool,
  isCloneViewModalDataSubmitting: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  contentWidth: PropTypes.number.isRequired,
  searchText: PropTypes.string,
  searchField: PropTypes.string,
  match: PropTypes.object,
  data: PropTypes.array,
  selectedFilters: PropTypes.array,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  selectedFilterGroup: PropTypes.string,
  totalCount: PropTypes.number,
  nextPageToken: PropTypes.string,
  cloneViewModalFormData: PropTypes.object,
  cloneViewModalFormErrors: PropTypes.object,
  onAction: PropTypes.func,
};

ViewBuilderList.defaultProps = {
  isLoading: false,
  isCloneViewModalVisible: false,
  isCloneViewModalDataSubmitting: false,
  searchText: EMPTY_STRING,
  searchField: EMPTY_STRING,
  match: EMPTY_OBJECT,
  data: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
  pageSize: 0,
  currentPage: DEFAULT_PAGE_INFO.start,
  selectedFilterGroup: EMPTY_STRING,
  totalCount: 0,
  nextPageToken: EMPTY_STRING,
  cloneViewModalFormData: EMPTY_OBJECT,
  cloneViewModalFormErrors: EMPTY_OBJECT,
  onAction: _noop,
};

export default compose(
  withSize({ hasPageHeader: 0, hasTabSection: 1 }),
  withActions(INITIAL_STATE_VIEW_BUILDER_LIST_PROPS, ACTION_HANDLERS),
)(ViewBuilderList);
