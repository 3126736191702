import { defaultMemoize } from 'reselect';
import _includes from 'lodash/includes';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';

import { EMPTY_ARRAY, EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { APPROVAL_CENTRE_FIELD_IDS } from '../constants/approvalCentre.constants';

const DEFAULT_SELECT_DROPDOWN_ROWS = 20;

const getAsyncSelectPayload = defaultMemoize((searchField) => ({ nextPageToken, searchText }) => {
  const filters = [];

  if (!_isEmpty(searchText) && !_isEmpty(searchField)) {
    filters.push({
      field: searchField,
      values: [searchText],
      filterType: OPERATORS.TEXT_STARTS_WITH,
    });
  }

  return {
    rows: DEFAULT_SELECT_DROPDOWN_ROWS,
    nextPageToken,
    filters,
  };
});

const _rightCurriedMap = (mapCallback) => (list) => _compact(_map(list, mapCallback));

const generateGroupOptionsFromHits = () =>
  _rightCurriedMap((data = EMPTY_OBJECT) => {
    const group = tget(data, APPROVAL_CENTRE_FIELD_IDS.NAME, NO_DATA);
    return {
      label: group,
      value: group,
    };
  });

const generateCategoryOptionsFromHits = (excludes = EMPTY_ARRAY) =>
  _rightCurriedMap((data = EMPTY_OBJECT) => {
    const category = tget(data, APPROVAL_CENTRE_FIELD_IDS.NAME, NO_DATA);
    if (_includes(excludes, category)) {
      return undefined;
    }

    return {
      label: category,
      value: category,
    };
  });

const updateRolesDataByName = defaultMemoize((data, rolesDataByName) => {
  const roleData = tget(data, 'role', EMPTY_OBJECT);
  const name = tget(roleData, 'name');
  const children = tget(data, 'childRoles');
  _set(rolesDataByName, name, roleData);
  _map(children, (child) => updateRolesDataByName(child, rolesDataByName));
});

export { getAsyncSelectPayload, generateGroupOptionsFromHits, generateCategoryOptionsFromHits, updateRolesDataByName };
