import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const METADATA_BASE_URL = 'core/u/mbs';

const fetchBundleList = (payload = EMPTY_OBJECT) => ApiService.post(`${METADATA_BASE_URL}/bundles/search`, payload);

const saveBundleData = (payload = EMPTY_OBJECT) => ApiService.post(`${METADATA_BASE_URL}/bundles`, payload);

const getBundleById = (id) => ApiService.get(`${METADATA_BASE_URL}/bundles/${id}`);

const deleteBundleById = (id) => ApiService.delete(`${METADATA_BASE_URL}/bundles/${id}`);

const updateBundleData = (id, payload = EMPTY_OBJECT) => ApiService.put(`${METADATA_BASE_URL}/bundles/${id}`, payload);

const fetchComponentsOfBundle = (id, payload) => ApiService.post(`${METADATA_BASE_URL}/bundles/${id}/components/search`, payload);

const addComponentToBundle = (id, payload) => ApiService.post(`${METADATA_BASE_URL}/bundles/${id}/components`, payload);

const publishBundleById = (id) => ApiService.post(`${METADATA_BASE_URL}/bundles/${id}/publish`, id);

const importBundleByMediaId = (payload) => ApiService.post(`${METADATA_BASE_URL}/import`, payload);

const deleteComponentFromBundle = (bundleId, componentId) => ApiService.delete(`${METADATA_BASE_URL}/bundles/${bundleId}/components/${componentId}`);

export default {
  fetchBundleList,
  saveBundleData,
  getBundleById,
  updateBundleData,
  fetchComponentsOfBundle,
  addComponentToBundle,
  deleteBundleById,
  publishBundleById,
  importBundleByMediaId,
  deleteComponentFromBundle,
};
