import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import withActions from '@tekion/tekion-components/connectors/withActions';
import LoadingSpinner from '@tekion/tekion-components/molecules/loadingSpinner';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Icon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import CommentList from './components/CommentList';
import ACTION_HANDLERS from './helpers/commentRenderer.actionHandlers';
import ACTION_TYPES from './constants/commentRenderer.actionTypes';

import styles from './commentRenderer.module.scss';

const CommentRenderer = ({ areCommentsLoading, className, onAction, ...restProps }) => {
  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT });
  }, [onAction]);

  if (areCommentsLoading) {
    return <LoadingSpinner className={styles.loadingContainer} size={30} />;
  }

  return (
    <Page className={`full-width full-height ${className} `}>
      <Page.Header>
        <Icon size={SIZES.M} className={styles.commentIcon}>
          icon-comment
        </Icon>
        <Heading size={3}>{__('Comments')}</Heading>
      </Page.Header>
      <Page.Body>
        <CommentList {...restProps} commentLevel={1} onAction={onAction} />
      </Page.Body>
    </Page>
  );
};

CommentRenderer.propTypes = {
  areCommentsLoading: PropTypes.bool,
  className: PropTypes.string,
  onAction: PropTypes.func.isRequired,
};

CommentRenderer.defaultProps = {
  areCommentsLoading: false,
  className: EMPTY_STRING,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(CommentRenderer);
