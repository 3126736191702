import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import reportingServices from '../services/reporting.services';

const fetchDashboard = async (dashboardName) => {
  try {
    const resp = await reportingServices.getDashboard(dashboardName);
    return getDataFromResponse(resp);
  } catch (e) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(e, __('Failed to fetch dashboard details. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchWidget = async (widgetName) => {
  try {
    const resp = await reportingServices.getWidget(widgetName);
    return getDataFromResponse(resp);
  } catch (e) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(e, __('Failed to fetch widget. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchReport = async (payload) => {
  try {
    const resp = await reportingServices.getReport(payload);
    return getDataFromResponse(resp);
  } catch (e) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(e, __('Failed to fetch report. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateDashboard = async (name, payload = EMPTY_OBJECT) => {
  try {
    const resp = await reportingServices.updateDashboard(name, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Dashboard updated'));
    return getDataFromResponse(resp);
  } catch (e) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(e, __('Failed to update Dashboard. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchWidgets = async (payload) => {
  try {
    const resp = await reportingServices.getAllWidgets(payload);
    return getDataFromResponse(resp);
  } catch (e) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(e, __('Failed to fetch widgets. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createDashboard = async (payload) => {
  try {
    const resp = await reportingServices.createDashboard(payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Dashboard Created'));
    return getDataFromResponse(resp);
  } catch (e) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(e, __('Failed to create Dashboard. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createWidget = async (payload) => {
  try {
    const resp = await reportingServices.createNewWidget(payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Widget added'));
    return getDataFromResponse(resp);
  } catch (e) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(e, __('Failed to create widget. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateWidget = async (widgetName, payload) => {
  try {
    const resp = await reportingServices.updateWidget(widgetName, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Widget updated'));
    return getDataFromResponse(resp);
  } catch (e) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(e, __('Failed to update widget. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getAllDashboards = async (payload) => {
  try {
    const resp = await reportingServices.getAllDashboards(payload);
    return getDataFromResponse(resp);
  } catch (e) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(e, __('Failed to get all dashboards. Please try again later.')));
    return EMPTY_ARRAY;
  }
};

const getMetaDataByEntityName = async (entityName) => {
  try {
    const resp = await reportingServices.getMetaDataByEntityName(entityName);
    return getDataFromResponse(resp);
  } catch (e) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(e, __('Failed to get MetaData . Please try again later.')));
    return EMPTY_ARRAY;
  }
};

const getMetaDataByFieldName = async (entityName, fieldName) => {
  try {
    const resp = await reportingServices.getMetaDataByFieldName(entityName, fieldName);
    return getDataFromResponse(resp);
  } catch (e) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(e, __('Failed to get MetaData. Please try again later.')));
    return EMPTY_ARRAY;
  }
};

export {
  fetchDashboard,
  fetchReport,
  fetchWidget,
  fetchWidgets,
  getAllDashboards,
  getMetaDataByEntityName,
  getMetaDataByFieldName,
  updateDashboard,
  updateWidget,
  createDashboard,
  createWidget,
};
