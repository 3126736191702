import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';

import { EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

// Components
import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Loader from '@tekion/tekion-components/molecules/loader';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import WithSize from '../../../../../connectors/withSize';

// Form Helpers
import getFields from './formHelpers/actionBuilderForm.fields';
import getSections from './formHelpers/actionBuilerForm.sections';
import ACTION_HANDLERS from './helpers/actionBuilderForm.actionHandlers';

// Constants
import { FORM_MODES, STANDARD_ENTITY_NAME } from '../../../../../constants/general.constants';
import ACTION_TYPES from './constants/actionBuilderForm.actionTypes';
import { ACTION_BUILDER_ACTION_TYPES, ACTION_BUILDER_FORM_CONTEXT_ID, INITIAL_STATE } from './constants/actionBuilderForm.general';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../constants/actionBuilder.constants';

const ActionBuilderForm = ({
  isSubmitting,
  isFetchingAction,
  isConditionFieldsLoading,
  isActionDataLoading,
  isCancelModalOpen,
  contentHeight,
  formMode,
  match,
  formValues,
  errors,
  targetEntityValidationRulesStore,
  targetEntityViewsStore,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityNameForEntryCondition,
  mapOfVariableToEntityNameForActionDefinition,
  mapOfVariableToEntityNameForFilterCondition,
  emailTemplateDefStore,
  onAction,
}) => {
  const entityName = match?.params.entityName;

  const targetEntity = formValues?.[ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME];
  const actionType = getArraySafeValue(formValues?.[ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE]);
  const targetEntityFields = targetEntity ? conditionBuilderFieldDefinitionObject?.[targetEntity] : EMPTY_OBJECT;
  const targetEntityValidationRules = targetEntity ? targetEntityValidationRulesStore?.[targetEntity] : EMPTY_ARRAY;
  const targetEntityViews = targetEntity ? targetEntityViewsStore?.[targetEntity] : EMPTY_ARRAY;

  // const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const handleCancelClick = useCallback(() => {
    // setIsCancelModalOpen(true);
    onAction({ type: ACTION_TYPES.ON_CANCEL_MODAL_OPEN });
  }, [onAction]);

  const handleCancelConfirm = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const handleCancelCancel = useCallback(() => {
    // setIsCancelModalOpen(false);
    onAction({ type: ACTION_TYPES.ON_CANCEL_MODAL_CLOSE });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(ACTION_BUILDER_FORM_CONTEXT_ID);
  }, []);

  const headerTitle = formMode === FORM_MODES.CREATE ? __('Create Action') : __('Update Action');
  const primaryButtonLabel = formMode === FORM_MODES.CREATE ? __('Create') : __('Update');

  const fields = useMemo(
    () =>
      getFields(
        formMode,
        formValues,
        targetEntityFields,
        targetEntityValidationRules,
        targetEntityViews,
        conditionBuilderFieldDefinitionObject,
        mapOfVariableToEntityNameForEntryCondition,
        mapOfVariableToEntityNameForActionDefinition,
        mapOfVariableToEntityNameForFilterCondition,
        emailTemplateDefStore,
        entityName,
      ),
    [
      formMode,
      formValues,
      targetEntityFields,
      targetEntityValidationRules,
      targetEntityViews,
      conditionBuilderFieldDefinitionObject,
      mapOfVariableToEntityNameForEntryCondition,
      mapOfVariableToEntityNameForActionDefinition,
      mapOfVariableToEntityNameForFilterCondition,
      emailTemplateDefStore,
      entityName,
    ],
  );

  const sections = useMemo(() => getSections(entityName, formValues), [entityName, formValues]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.ON_INIT,
    });
  }, [onAction]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.LOAD_CONDITION_FIELDS });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // To be triggered on mount ONLY.

  useEffect(() => {
    if (!_isEmpty(targetEntity) && !_has(conditionBuilderFieldDefinitionObject, targetEntity)) {
      onAction({ type: ACTION_TYPES.LOAD_TARGET_ENTITY_FIELDS, payload: { targetEntity } });
    } else if (
      (actionType === ACTION_BUILDER_ACTION_TYPES.SEND_EMAILS || actionType === ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL) &&
      !_has(conditionBuilderFieldDefinitionObject, STANDARD_ENTITY_NAME.NOTIFICATION)
    ) {
      onAction({ type: ACTION_TYPES.LOAD_TARGET_ENTITY_FIELDS, payload: { targetEntity: STANDARD_ENTITY_NAME.NOTIFICATION } });
    }
  }, [targetEntity, conditionBuilderFieldDefinitionObject, actionType, onAction]);

  useEffect(() => {
    if (!_isEmpty(targetEntity) && !_has(targetEntityValidationRulesStore, targetEntity)) {
      onAction({ type: ACTION_TYPES.LOAD_TARGET_ENTITY_VALIDATION_RULES, payload: { targetEntity } });
    }
  }, [targetEntityValidationRulesStore, targetEntity, onAction]);

  useEffect(() => {
    if (!_isEmpty(targetEntity) && !_has(targetEntityViewsStore, targetEntity)) {
      onAction({ type: ACTION_TYPES.LOAD_TARGET_ENTITY_VIEWS, payload: { targetEntity } });
    }
  }, [targetEntityViewsStore, targetEntity, onAction]);

  if (isActionDataLoading) {
    return <Loader id={ACTION_BUILDER_FORM_CONTEXT_ID} style={{ height: 316 }} />;
  }

  return (
    <Page>
      <Page.Header hasBack>
        <Heading>{headerTitle}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          className="p-t-12"
          isFetching={isFetchingAction || isConditionFieldsLoading}
          contextId={ACTION_BUILDER_FORM_CONTEXT_ID}
          sections={sections}
          fields={fields}
          values={formValues}
          errors={errors}
          onAction={onAction}
        />
        {/* TODO: verify if both PropertyControlledComponent and ConfirmationDialog are needed */}
        <PropertyControlledComponent controllerProperty={isCancelModalOpen}>
          <ConfirmationDialog
            isVisible={isCancelModalOpen}
            title={__('Cancel Changes')}
            onSubmit={handleCancelConfirm}
            onCancel={handleCancelCancel}
            content={__('Are you sure you want to cancel all the changes made by you? All data will be lost if you click yes.')}
          />
        </PropertyControlledComponent>
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id={ACTION_BUILDER_FORM_CONTEXT_ID}
          primaryActionLoading={isSubmitting}
          primaryButtonLabel={primaryButtonLabel}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancelClick}
        />
      </Page.Footer>
    </Page>
  );
};

ActionBuilderForm.propTypes = {
  contentHeight: PropTypes.number.isRequired,
  isSubmitting: PropTypes.bool,
  isFetchingAction: PropTypes.bool,
  isConditionFieldsLoading: PropTypes.bool,
  isActionDataLoading: PropTypes.bool,
  isCancelModalOpen: PropTypes.bool,
  formMode: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      entityName: PropTypes.string,
    }),
  }).isRequired,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  targetEntityViewsStore: PropTypes.object,
  targetEntityValidationRulesStore: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes,
  mapOfVariableToEntityNameForEntryCondition: PropTypes.object,
  mapOfVariableToEntityNameForActionDefinition: PropTypes.object,
  mapOfVariableToEntityNameForFilterCondition: PropTypes.object,
  emailTemplateDefStore: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ActionBuilderForm.defaultProps = {
  isSubmitting: false,
  isFetchingAction: false,
  isConditionFieldsLoading: false,
  isActionDataLoading: false,
  isCancelModalOpen: false,
  formMode: FORM_MODES.CREATE,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  targetEntityViewsStore: EMPTY_OBJECT,
  targetEntityValidationRulesStore: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  mapOfVariableToEntityNameForEntryCondition: EMPTY_OBJECT,
  mapOfVariableToEntityNameForActionDefinition: EMPTY_OBJECT,
  mapOfVariableToEntityNameForFilterCondition: EMPTY_OBJECT,
  emailTemplateDefStore: EMPTY_OBJECT,
};

export default compose(WithSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(INITIAL_STATE, ACTION_HANDLERS))(ActionBuilderForm);
