import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _snakeCase from 'lodash/snakeCase';
import _remove from 'lodash/remove';
import _pick from 'lodash/pick';

import { tget } from '@tekion/tekion-base/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';

import {
  createGlobalSelectList,
  getGlobalSelectByName,
  updateGlobalListOptions,
  updateGlobalSelectList,
} from '../../../../../../actions/globalSelectLists.actions';
import { getOptions, getOptionsForPayload } from './globalSelectListForm.helpers';

import { FIELD_IDS } from '../constants/globalSelectListForm.general.constants';
import ACTION_TYPES from '../constants/globalSelectListForm.actionTypes';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import { FORM_MODES } from '../../../../../../constants/viewBuilder.constants';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import { COLUMN_IDS } from '../components/optionsTable/constants/optionTable.constants';

const handleInit = async ({ getState, setState }) => {
  const { match } = getState();
  const globalSelectName = _get(match, 'params.globalSelectName');

  if (_isEmpty(globalSelectName)) {
    setState({ formMode: FORM_MODES.CREATE });
    return;
  }

  setState({ isDataLoading: true });
  let formValues = EMPTY_OBJECT;
  let oldOptions = [];
  formValues = await getGlobalSelectByName(globalSelectName);
  oldOptions = tget(formValues, FIELD_IDS.OPTIONS);

  setState({ formValues, oldOptions, isDataLoading: false, formMode: FORM_MODES.EDIT, globalSelectName });
};

const handleFieldChange = ({ getState, setState, params }) => {
  const { formMode, disableAutoFillForName = false, formValues } = getState();
  const { id, value } = params;

  const updatedValues = { ...formValues };
  updatedValues[id] = value;

  if (formMode === FORM_MODES.CREATE && id === FIELD_IDS.DISPLAY_NAME && !disableAutoFillForName) {
    updatedValues[FIELD_IDS.NAME] = _snakeCase(value);
  } else if (id === FIELD_IDS.NAME) {
    setState({ disableAutoFillForName: true });
  }

  setState({ formValues: updatedValues });
};

const handleErrors = ({ setState, params }) => {
  const { errors } = params;

  setState({ errors });
};

const handleTableOnChange = ({ setState, params = EMPTY_OBJECT, getState }) => {
  const { id, value } = params;
  const { formValues } = getState();
  const row = _get(value, 'row');
  const fieldValue = _get(value, 'value');
  const tableData = _get(formValues, FIELD_IDS.OPTIONS, []);
  if (id === COLUMN_IDS.LABEL) {
    tableData[row] = { ...tget(tableData, row, {}), label: fieldValue };
    if (!_get(tget(tableData, row, {}), 'valueChanged', false)) {
      tableData[row] = { ...tget(tableData, row, {}), value: fieldValue };
    }
  } else tableData[row] = { ...tget(tableData, row, {}), value: fieldValue, valueChanged: true };

  setState({ formValues: { ...formValues, [FIELD_IDS.OPTIONS]: [...tableData] } });
};

const handleTableRemoveRow = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { formValues } = getState();
  const { row } = params;
  const tableData = _get(formValues, FIELD_IDS.OPTIONS, EMPTY_ARRAY);
  _remove(tableData, (val, index) => index === row);
  setState({
    formValues: { ...formValues, [FIELD_IDS.OPTIONS]: [...tableData] },
  });
};

const handleTableAddRow = ({ getState, setState }) => {
  const { formValues = EMPTY_OBJECT, formMode } = getState();
  const tableData = _get(formValues, FIELD_IDS.OPTIONS, []);
  let newTableRow = {};
  if (formMode === FORM_MODES.EDIT) {
    newTableRow = { label: '', value: '', isEditable: true };
  }
  setState({ formValues: { ...formValues, [FIELD_IDS.OPTIONS]: [...tableData, newTableRow] } });
};

const handleSubmit = async ({ getState, setState }) => {
  const { history, formValues, match, oldOptions } = getState();

  setState({ isSaveLoading: true });
  const globalSelectName = _get(match, 'params.globalSelectName');

  if (_isEmpty(globalSelectName)) {
    const payload = { ...formValues, [FIELD_IDS.OPTIONS]: getOptions(tget(formValues, FIELD_IDS.OPTIONS, EMPTY_ARRAY)) };
    const data = await createGlobalSelectList(payload);
    if (!_isEmpty(data)) {
      setTimeout(() => {
        history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_IDS.GLOBAL_SELECT_LIST}` });
      }, ES_REFETCH_DELAY);
    } else {
      setState({ isSaveLoading: false });
    }
  } else {
    const payload = { ..._pick(formValues, [FIELD_IDS.DESCRIPTION, FIELD_IDS.DISPLAY_NAME]) };
    const optionsPayload = getOptionsForPayload(oldOptions, formValues);
    const promises = [updateGlobalSelectList(globalSelectName, payload)];
    if (!_isEmpty(tget(optionsPayload, FIELD_IDS.OPTIONS))) {
      promises.push(updateGlobalListOptions(globalSelectName, optionsPayload));
    }
    const [data, optionResponse] = await Promise.all(promises);
    if (!_isEmpty(data) && (_isEmpty(tget(optionsPayload, FIELD_IDS.OPTIONS)) || !_isEmpty(optionResponse))) {
      setTimeout(() => {
        history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_IDS.GLOBAL_SELECT_LIST}` });
      }, ES_REFETCH_DELAY);
    } else {
      if (!_isEmpty(optionResponse)) {
        setState({
          oldOptions: tget(formValues, FIELD_IDS.OPTIONS, oldOptions),
          formValues: { ...formValues, [FIELD_IDS.OPTIONS]: getOptions(tget(formValues, FIELD_IDS.OPTIONS)) },
        });
      }
      setState({ isSaveLoading: false });
    }
  }
};

const handleOnCancel = ({ setState }) => {
  setState({ showCancelModal: true });
};

const handleOnCancelRequest = ({ setState }) => {
  setState({ showCancelModal: false });
};

const handleOnConfirmRequest = ({ getState, setState }) => {
  setState({ showCancelModal: false });
  const { history } = getState();
  const path = `${STUDIO_ROUTE}/${PAGE_IDS.GLOBAL_SELECT_LIST}`;

  history.push(path);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.TABLE_ON_CHANGE]: handleTableOnChange,
  [ACTION_TYPES.TABLE_REMOVE_ROW]: handleTableRemoveRow,
  [ACTION_TYPES.TABLE_ADD_ROW]: handleTableAddRow,
  [ACTION_TYPES.ON_CANCEL]: handleOnCancel,
  [ACTION_TYPES.ON_CANCEL_MODAL_REQUEST]: handleOnCancelRequest,
  [ACTION_TYPES.ON_CONFIRM_MODAL_REQUEST]: handleOnConfirmRequest,
};

export default ACTION_HANDLERS;
