import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import MediaUploader from './MediaUploader';
import { getMediaList } from './mediaUploader.helper';

import styles from './mediaUploader.module.scss';

const MediaUploaderFormRenderer = ({ id, value, multiple, onAction, ...restProps }) => {
  const mediaList = useMemo(() => getMediaList(value), [value]);

  const handleOnChange = useCallback(
    (payload) => {
      let mediaValue = _map(payload, 'mediaId');
      if (!multiple) {
        mediaValue = getArraySafeValue(mediaValue);
      }
      onAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: {
          value: mediaValue,
          id,
        },
      });
    },
    [multiple, onAction, id],
  );

  return (
    <div>
      <FieldLabel labelClassName={styles.fieldLabel} {...restProps} />
      <MediaUploader
        multiple={multiple}
        mediaList={mediaList}
        onChange={handleOnChange}
        validFormats=".jpg,.png,.jpeg,.pdf,.txt,.json"
        {...restProps}
      />
    </div>
  );
};

MediaUploaderFormRenderer.propTypes = {
  multiple: PropTypes.bool,
  id: PropTypes.string.isRequired,
  value: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

MediaUploaderFormRenderer.defaultProps = {
  multiple: false,
  value: EMPTY_ARRAY,
};

export default React.memo(MediaUploaderFormRenderer);
