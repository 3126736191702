import produce from 'immer';

import _set from 'lodash/set';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { importBundleByMediaId } from '../../../../../actions/metadataManagement.actions';

const handleFieldsChange = ({ setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;

  setState(
    produce((draft) => {
      _set(draft, `importData.${id}`, value);
    }),
  );
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;

  setState({ errors });
};

const handleFormSubmit = async ({ getState }) => {
  const { importData } = getState();

  await importBundleByMediaId(importData);
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldsChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleFormSubmit,
};

export default ACTION_HANDLERS;
