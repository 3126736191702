import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

const workspaceReader = {
  id: (workspace = EMPTY_OBJECT) => tget(workspace, 'id'),
  name: (workspace = EMPTY_OBJECT) => tget(workspace, 'name', EMPTY_STRING),
  displayName: (workspace = EMPTY_OBJECT) => tget(workspace, 'displayName', EMPTY_STRING),
};

export default workspaceReader;
