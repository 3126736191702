import _property from 'lodash/property';

const filterReader = {
  field: _property('field'),
  fieldName: _property('fieldName'),
  filterType: _property('filterType'),
  values: _property('values'),
  upfront: _property('upfront'),
  preAppliedFilters: _property('preAppliedFilters'),
  filterMetadata: _property('filterMetadata'),
  value: _property('value'),
};

export default filterReader;
