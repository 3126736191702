exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".numberWithSelect_customNumberInput__wak2e5Vwbs{width:100%;height:100%;min-width:18rem}[dir] .numberWithSelect_customNumberInput__wak2e5Vwbs{border:none}[dir] .numberWithSelect_customNumberInput__wak2e5Vwbs .ant-select{margin-top:-1rem}.numberWithSelect_disabledStepper__fa49Q5xq3u .ant-input-number-handler-wrap{display:none}[dir=ltr] .numberWithSelect_addOnBefore__8ZjnC3Ro6H{border-right:.1rem solid #d4d5d6}[dir=rtl] .numberWithSelect_addOnBefore__8ZjnC3Ro6H{border-left:.1rem solid #d4d5d6}[dir] .numberWithSelect_addOnAfter__437xmJ9JBS{padding:0 !important}[dir=ltr] .numberWithSelect_addOnAfter__437xmJ9JBS{border-left:.1rem solid #d4d5d6}[dir=rtl] .numberWithSelect_addOnAfter__437xmJ9JBS{border-right:.1rem solid #d4d5d6}.numberWithSelect_addOnBefore__8ZjnC3Ro6H,.numberWithSelect_addOnAfter__437xmJ9JBS{white-space:nowrap;line-height:1.4rem;height:100%}[dir] .numberWithSelect_addOnBefore__8ZjnC3Ro6H, [dir] .numberWithSelect_addOnAfter__437xmJ9JBS{padding:0 !important}[dir] .numberWithSelect_addOnBefore__8ZjnC3Ro6H.numberWithSelect_disabled__uLPGsonh9d, [dir] .numberWithSelect_addOnAfter__437xmJ9JBS.numberWithSelect_disabled__uLPGsonh9d{background-color:#edeef0}.numberWithSelect_numberInputField__vdKbApVYGB{display:flex;align-items:center;height:3.2rem}[dir] .numberWithSelect_numberInputField__vdKbApVYGB{border:.1rem solid #969aa3;border-radius:.2rem}[dir] .numberWithSelect_numberInputFieldFocused__ogLFLyCqFE{border-color:#4285f4}[dir] .numberWithSelect_numberInputFieldWarning__e3rnt96vGH{border-color:#f80}[dir] .numberWithSelect_numberInputFieldError__dSnmkNdfFF{border-color:#f52908}.numberWithSelect_additionalText__jUjuSikvK4{white-space:nowrap}[dir] .numberWithSelect_additionalText__jUjuSikvK4{padding-top:.4rem}[dir=ltr] .numberWithSelect_additionalText__jUjuSikvK4{padding-right:.8rem}[dir=rtl] .numberWithSelect_additionalText__jUjuSikvK4{padding-left:.8rem}", ""]);

// Exports
exports.locals = {
	"customNumberInput": "numberWithSelect_customNumberInput__wak2e5Vwbs",
	"disabledStepper": "numberWithSelect_disabledStepper__fa49Q5xq3u",
	"addOnBefore": "numberWithSelect_addOnBefore__8ZjnC3Ro6H",
	"addOnAfter": "numberWithSelect_addOnAfter__437xmJ9JBS",
	"disabled": "numberWithSelect_disabled__uLPGsonh9d",
	"numberInputField": "numberWithSelect_numberInputField__vdKbApVYGB",
	"numberInputFieldFocused": "numberWithSelect_numberInputFieldFocused__ogLFLyCqFE",
	"numberInputFieldWarning": "numberWithSelect_numberInputFieldWarning__e3rnt96vGH",
	"numberInputFieldError": "numberWithSelect_numberInputFieldError__dSnmkNdfFF",
	"additionalText": "numberWithSelect_additionalText__jUjuSikvK4"
};