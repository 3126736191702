import { createTablePlugin as createPlateTablePlugin } from '@udecode/plate-table';
import { getAbove } from '@udecode/plate-common';
import { getPlatePluginTypes } from '@udecode/plate-core';
import { ELEMENT_UL, ELEMENT_OL } from '@udecode/plate-list';

const getTableKeyDown = (defaultTableKeyDown) => (editor) => (e) => {
  const listTypes = getPlatePluginTypes([ELEMENT_OL, ELEMENT_UL])(editor);
  if (e.key === 'Tab' && editor.selection) {
    const listSelected = getAbove(editor, {
      at: editor.selection,
      match: { type: listTypes },
    });

    if (!listSelected) {
      defaultTableKeyDown(editor)(e);
    }
  }
};

const createTablePlugin = () => {
  const { onKeyDown, ...rest } = createPlateTablePlugin();
  return {
    ...rest,
    onKeyDown: getTableKeyDown(onKeyDown),
  };
};

export default createTablePlugin;
