import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import { tget } from '@tekion/tekion-base/utils/general';

import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Button from '@tekion/tekion-components/atoms/Button';
import Modal from '@tekion/tekion-components/molecules/Modal';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';

import ApprovalStageCollapsiblePanels from '../../../../../../../../molecules/approvalStageCollapsiblePanels';

import ACTION_HANDLERS from './helpers/approvalStageFieldRenderer.actionHandlers';
import getFields from './helpers/approvalStageFieldRenderer.fields';
import getSections from './helpers/approvalStageFieldRenderer.sections';

import { APPROVAL_STAGE_INPUT_FORM_CONTEXT_ID, STAGE_APPROVER_TYPES } from './constants/approvalStageFieldRenderer.constants';
import ACTION_TYPES from './constants/approvalStageFieldRenderer.actionTypes';
import { FORM_MODES } from '../../../../../../../../constants/general.constants';
import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../../../../../constants/approvalCentre.constants';

import styles from './approvalStageFieldRenderer.module.scss';

const ApprovalStageFieldRenderer = ({
  isModalVisible,
  isFetchingApprovers,
  formMode,
  selectedApproverType,
  formValues,
  errors,
  value,
  approverOptions,
  selectedApproverOptions,
  onAction,
}) => {
  const validityType = tget(formValues, [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_TYPE]);
  const modalTitle = formMode === FORM_MODES.CREATE ? __('Stage Approver Details') : __('Edit Stage Approver Details');

  const fields = useMemo(
    () => getFields({ selectedApproverType, selectedApproverOptions, approverOptions, isFetchingApprovers }),
    [selectedApproverType, selectedApproverOptions, approverOptions, isFetchingApprovers],
  );
  const sections = useMemo(() => getSections(validityType), [validityType]);

  const handleApprovalStageModalSubmit = useCallback(() => {
    triggerSubmit(APPROVAL_STAGE_INPUT_FORM_CONTEXT_ID);
  }, []);

  const handleApprovalStageModalCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL_MODAL_CLICK });
  }, [onAction]);

  const handleAddStage = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_ADD_STAGE_CLICK });
  }, [onAction]);

  const handleEditStage = useCallback(
    (index) => {
      onAction({ type: ACTION_TYPES.ON_CLICK_EDIT_STAGE, payload: { index } });
    },
    [onAction],
  );

  const handleDeleteStage = useCallback(
    (index) => {
      onAction({
        type: ACTION_TYPES.ON_DELETE_STAGE_CLICK,
        payload: {
          index,
        },
      });
    },
    [onAction],
  );

  const renderNoStagesMessage = useCallback(
    () => (
      <span className={styles.noStagesMessage}>
        <p className={styles.noStagesMessageContent}>{__('No Stages Added')}</p>
      </span>
    ),
    [],
  );

  const renderApprovalStageCollapsiblePanels = useCallback(
    () => (
      <PropertyControlledComponent controllerProperty={!_isEmpty(value)} fallback={renderNoStagesMessage()}>
        <ApprovalStageCollapsiblePanels
          isBuilderMode
          containerClassName={styles.tableContainer}
          approvalStages={value}
          onEditStage={handleEditStage}
          onDeleteStage={handleDeleteStage}
        />
      </PropertyControlledComponent>
    ),
    [value, handleDeleteStage, handleEditStage, renderNoStagesMessage],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction]);

  return (
    <div className="full-width">
      <div className={styles.headerContainer}>
        <Heading size={2}>{__('Stages')}</Heading>
        <Button view={Button.VIEW.SECONDARY} onClick={handleAddStage}>
          {__('Add Stage')}
        </Button>
      </div>
      <Modal
        visible={isModalVisible}
        width={SIZES.L}
        title={modalTitle}
        submitBtnText={__('Submit')}
        bodyStyle={{ padding: 0 }}
        onSubmit={handleApprovalStageModalSubmit}
        onCancel={handleApprovalStageModalCancel}
      >
        <FormWithSubmission
          contextId={APPROVAL_STAGE_INPUT_FORM_CONTEXT_ID}
          fields={fields}
          sections={sections}
          values={formValues}
          errors={errors}
          onAction={onAction}
        />
      </Modal>
      {renderApprovalStageCollapsiblePanels()}
    </div>
  );
};

ApprovalStageFieldRenderer.propTypes = {
  isModalVisible: PropTypes.bool,
  isFetchingApprovers: PropTypes.bool,
  formMode: PropTypes.string,
  selectedApproverType: PropTypes.string,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  value: PropTypes.array,
  approverOptions: PropTypes.array,
  selectedApproverOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ApprovalStageFieldRenderer.defaultProps = {
  isModalVisible: false,
  isFetchingApprovers: false,
  formMode: FORM_MODES.CREATE,
  selectedApproverType: STAGE_APPROVER_TYPES.ALL,
  errors: EMPTY_OBJECT,
  formValues: EMPTY_OBJECT,
  value: EMPTY_ARRAY,
  approverOptions: EMPTY_ARRAY,
  selectedApproverOptions: EMPTY_ARRAY,
};

export default withActions({}, ACTION_HANDLERS)(ApprovalStageFieldRenderer);
