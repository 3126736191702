import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _castArray from 'lodash/castArray';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

const SelectInputFieldWrapper = (WrappedComponent) => {
  const WrappedComponentWithFieldWrapper = (props) => {
    const { value, onAction } = props;

    const handleAction = useCallback(
      (action) => {
        const { type, payload = EMPTY_OBJECT } = action;

        switch (type) {
          case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
            const { value: newValue } = payload;
            onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { ...payload, value: getArraySafeValue(newValue) } });
            break;
          }

          default: {
            onAction(action);
            break;
          }
        }
      },
      [onAction],
    );

    return <WrappedComponent {...props} value={_castArray(value)} onAction={handleAction} />;
  };

  WrappedComponentWithFieldWrapper.propTypes = {
    value: PropTypes.object.isRequired,
    onAction: PropTypes.func.isRequired,
  };

  WrappedComponentWithFieldWrapper.defaultProps = {};

  return WrappedComponentWithFieldWrapper;
};

export default SelectInputFieldWrapper;
