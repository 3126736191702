/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import cx from 'classnames';

// components
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Popover, { POPOVER_TRIGGER, POPOVER_PLACEMENT } from '@tekion/tekion-components/molecules/popover';
import ColorButton from './molecules/colorButton';
import { BACKGROUND_COLORS } from './hooks/useColorPalette/useColorPalette.constants';

// Hooks
import useColorPalette from './hooks/useColorPalette';

// styles
import COLORS from '@tekion/tekion-styles-next/scss/variables.scss';
import styles from './backgroundColorSelector.module.scss';

function BackgroundColorSelector(props) {
  const { onAction, actionType, label, cellBackgroundColor, className, btnClassName, isRightIconRequired, colors, disabled } = props;
  const { colorPalette, isColorPaletteVisible, setIsColorPaletteVisible } = useColorPalette({
    colors,
    cellBackgroundColor,
    onAction,
    actionType,
  });

  return (
    <div className={cx(className, 'p-x-12')}>
      <Popover
        content={colorPalette}
        placement={POPOVER_PLACEMENT.RIGHT_TOP}
        trigger={POPOVER_TRIGGER.HOVER}
        onVisibleChange={setIsColorPaletteVisible}
        visible={isColorPaletteVisible && !disabled}
      >
        <div className={styles.cellBackgroundAction}>
          {label}
          <ColorButton color={cellBackgroundColor} className={btnClassName} disabled={disabled} />
          {isRightIconRequired && (
            <FontIcon size={SIZES.S} className="cursor-pointer" color={COLORS.gunMetal}>
              icon-right-arrow-thin
            </FontIcon>
          )}
        </div>
      </Popover>
    </div>
  );
}

BackgroundColorSelector.propTypes = {
  onAction: PropTypes.func,
  actionType: PropTypes.string,
  label: PropTypes.string,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  }).isRequired,
  cellBackgroundColor: PropTypes.string,
  className: PropTypes.string,
  btnClassName: PropTypes.string,
  isRightIconRequired: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

BackgroundColorSelector.defaultProps = {
  onAction: _noop,
  actionType: undefined,
  label: undefined,
  cellBackgroundColor: undefined,
  className: undefined,
  btnClassName: undefined,
  isRightIconRequired: true,
  colors: BACKGROUND_COLORS,
  disabled: false,
};

export default React.memo(BackgroundColorSelector);
