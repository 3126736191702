import React from 'react';
import PropTypes from 'prop-types';

import Heading from '@tekion/tekion-components/atoms/Heading';

import Component from './Component';

import styles from './componentPanels.module.scss';

const ComponentPanels = ({ panels, usedEntityFieldNames }) => (
  <div className={styles.componentPanels}>
    {panels.map((panel) => {
      const { panelId, panelName, components = [] } = panel;

      return (
        <div className={styles.componentPanel} key={panelId}>
          <Heading size={5} className={styles.panelTitle}>
            {panelName}
          </Heading>

          <div className={styles.components}>
            {components.map((component) => {
              const { index, className, ...componentConfig } = component;
              const { sectionId } = componentConfig;

              return (
                <Component
                  key={sectionId}
                  className={className}
                  index={index}
                  componentConfig={componentConfig}
                  usedEntityFieldNames={usedEntityFieldNames}
                />
              );
            })}
          </div>
        </div>
      );
    })}
  </div>
);

ComponentPanels.propTypes = {
  panels: PropTypes.arrayOf(PropTypes.object),
  usedEntityFieldNames: PropTypes.array,
};

ComponentPanels.defaultProps = {
  panels: [],
  usedEntityFieldNames: [],
};

export default ComponentPanels;
