import { PUSH_NOTIFICATION_FIELD_IDS } from '../../../../constants/nodeDataFieldIds';

import styles from '../pushNotificationModal.module.scss';

const FORM_SECTIONS = [
  {
    className: styles.fullWidthSection,
    rows: [{ columns: [PUSH_NOTIFICATION_FIELD_IDS.RECEIVER_IDS] }, { columns: [PUSH_NOTIFICATION_FIELD_IDS.APPLICATION_NAME] }],
  },
  {
    className: styles.fullWidthSection,
    rows: [
      {
        columns: [PUSH_NOTIFICATION_FIELD_IDS.PUSHER_ENTITY_NAME, PUSH_NOTIFICATION_FIELD_IDS.PUSHER_RECORD_ID],
      },
    ],
  },
  {
    className: styles.fullWidthSection,
    rows: [PUSH_NOTIFICATION_FIELD_IDS.TEMPLATES],
  },
];

export default FORM_SECTIONS;
