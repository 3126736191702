const SEND_EMAILS_ADDRESSEE_TYPE = {
  STATIC: 'STATIC',
  DERIVED: 'DERIVED',
};

const SEND_EMAILS_ADDRESSEE_TYPE_OPTIONS = [
  { label: __('Static'), value: SEND_EMAILS_ADDRESSEE_TYPE.STATIC },
  { label: __('Derived'), value: SEND_EMAILS_ADDRESSEE_TYPE.DERIVED },
];

export { SEND_EMAILS_ADDRESSEE_TYPE, SEND_EMAILS_ADDRESSEE_TYPE_OPTIONS };
