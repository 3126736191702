exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".customRowActionsForm_container__xqk4dKtyQ9{display:flex;width:100%}.customRowActionsForm_leftPanelContainer__qc5g6qRGWX{width:25%}[dir=ltr] .customRowActionsForm_leftPanelContainer__qc5g6qRGWX{border-right:.1rem solid #969aa3}[dir=rtl] .customRowActionsForm_leftPanelContainer__qc5g6qRGWX{border-left:.1rem solid #969aa3}.customRowActionsForm_leftPanelContainer__qc5g6qRGWX .customRowActionsForm_addButton__t5deBkXjzg{display:flex}[dir] .customRowActionsForm_leftPanelContainer__qc5g6qRGWX .customRowActionsForm_addButton__t5deBkXjzg{padding:1.2rem !important}.customRowActionsForm_formContainer__joxSZAP1ez{width:100%}[dir] .customRowActionsForm_formContainer__joxSZAP1ez{margin-top:1.2rem}.customRowActionsForm_leftPanelTitleContainer__5cA6JSeb7s{display:flex;width:18rem;overflow-x:scroll;justify-content:space-between}", ""]);

// Exports
exports.locals = {
	"container": "customRowActionsForm_container__xqk4dKtyQ9",
	"leftPanelContainer": "customRowActionsForm_leftPanelContainer__qc5g6qRGWX",
	"addButton": "customRowActionsForm_addButton__t5deBkXjzg",
	"formContainer": "customRowActionsForm_formContainer__joxSZAP1ez",
	"leftPanelTitleContainer": "customRowActionsForm_leftPanelTitleContainer__5cA6JSeb7s"
};