exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".approver_approversContainer__fSGinzZC7D{position:relative;display:flex;justify-content:space-between}[dir] .approver_approversContainer__fSGinzZC7D{margin:.8rem;background-color:#f4f5f6}.approver_approved__ukcewfXEEH::before{content:\"\";position:absolute;width:.2rem;height:100%}[dir] .approver_approved__ukcewfXEEH::before{background-color:#4ba343}.approver_rejected__smpe5kdTeS::before{content:\"\";position:absolute;width:.2rem;height:100%}[dir] .approver_rejected__smpe5kdTeS::before{background-color:#d12307}.approver_approvedStatus__3bLZLckjue{color:#4ba343}.approver_rejectedStatus__ejvGCeJA8t{color:#d12307}.approver_userContainer__b8YfBA7AUC{display:flex;align-items:center}[dir] .approver_userContainer__b8YfBA7AUC{padding:.8rem 1.6rem}[dir=ltr] .approver_userContainer__b8YfBA7AUC .approver_userName__pwMmPWxNMP{padding-left:.6rem}[dir=rtl] .approver_userContainer__b8YfBA7AUC .approver_userName__pwMmPWxNMP{padding-right:.6rem}.approver_statusContainer__jAdf5trkrS{display:flex;align-items:center}[dir=ltr] .approver_statusContainer__jAdf5trkrS{padding-right:1.2rem}[dir=rtl] .approver_statusContainer__jAdf5trkrS{padding-left:1.2rem}", ""]);

// Exports
exports.locals = {
	"approversContainer": "approver_approversContainer__fSGinzZC7D",
	"approved": "approver_approved__ukcewfXEEH",
	"rejected": "approver_rejected__smpe5kdTeS",
	"approvedStatus": "approver_approvedStatus__3bLZLckjue",
	"rejectedStatus": "approver_rejectedStatus__ejvGCeJA8t",
	"userContainer": "approver_userContainer__b8YfBA7AUC",
	"userName": "approver_userName__pwMmPWxNMP",
	"statusContainer": "approver_statusContainer__jAdf5trkrS"
};