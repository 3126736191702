import _get from 'lodash/get';

import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { STATUS } from '@tekion/tekion-base/constants/statuses';

import WorkspaceProjectManagementServices from '../services/projectManagement.services';

const getWorkspaceProjectList = async (payload) => {
  try {
    const receivedData = await WorkspaceProjectManagementServices.fetchWorkspaceProjectList(payload);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch Workspace Project List, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const saveProjectData = async (payload) => {
  try {
    const response = await WorkspaceProjectManagementServices.saveProjectData(payload);
    const data = getDataFromResponse(response);
    return { data, status: _get(response, 'data.status') };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Save Project Data , please try again later.')));
    return { data: EMPTY_OBJECT, status: STATUS.FAILED };
  }
};

const editProjectData = async (name, payload) => {
  try {
    const response = await WorkspaceProjectManagementServices.editProjectData(name, payload);
    const data = getDataFromResponse(response);
    return { data, status: _get(response, 'data.status') };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Edit Project Data , please try again later.')));
    return { data: EMPTY_OBJECT, status: STATUS.FAILED };
  }
};

const getProjectByName = async (name) => {
  try {
    const response = await WorkspaceProjectManagementServices.getProjectByName(name);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed get Project Data By this ID , please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchApplicationsForProject = async (name) => {
  try {
    const response = await WorkspaceProjectManagementServices.fetchApplicationsForProject(name);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed get Project Data By this ID , please try again later.')));
    return EMPTY_ARRAY;
  }
};

const addApplicationsToProject = async (name, payload) => {
  try {
    const response = await WorkspaceProjectManagementServices.addApplicationsToProject(name, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Applications Added Successfully!'));
    return _get(response, 'data.status');
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed get Project Data By this ID , please try again later.')));
    return EMPTY_OBJECT;
  }
};

const removeApplicationFromProject = async (name, applicationId) => {
  try {
    const response = await WorkspaceProjectManagementServices.removeApplicationFromProject(name, applicationId);
    toaster(TOASTER_TYPE.SUCCESS, __('Application Removed Successfully!'));
    return _get(response, 'data.status');
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed get Project Data By this ID , please try again later.')));

    return EMPTY_OBJECT;
  }
};

export {
  getWorkspaceProjectList,
  saveProjectData,
  editProjectData,
  getProjectByName,
  fetchApplicationsForProject,
  addApplicationsToProject,
  removeApplicationFromProject,
};
