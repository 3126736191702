import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _includes from 'lodash/includes';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

// Components
import SideNavItem from '../SideNavItem';
import { PermissionsContext } from '../../../../context/permissions.context';

// Constants
import { SIDENAV_EXPERIENCE_DATA, SIDENAV_STUDIO_DATA } from '../../constants/appSkeleton.general';
import { STUDIO_ROUTE, TENANT_UNIVERSE_PAGE_ID } from '../../../../constants/routes';
import { PERMISSIONS_CONTEXT_KEYS, PERMISSIONS_CONTROL_FLAGS } from '../../../../constants/permissionsProvider.constants';

// Styles
import styles from './sideNav.module.scss';

const SideNav = (props) => {
  const permissionsContext = useContext(PermissionsContext);
  const controlFlags = tget(permissionsContext, PERMISSIONS_CONTEXT_KEYS.CONTROL_FLAGS, EMPTY_OBJECT);

  const { history } = props;
  const isExpand = false; // This Boolean will be used to check for application-domain check(For App builder-true, else false)
  const pathName = _get(history, 'location.pathname');

  const onItemClick = useCallback(
    (data) => {
      history.push(data);
    },
    [history],
  );

  const isStudioPath = _includes(pathName, STUDIO_ROUTE);

  const isHomeShown = !_includes(pathName, TENANT_UNIVERSE_PAGE_ID);

  const isStudioShown = _includes(controlFlags, PERMISSIONS_CONTROL_FLAGS.DEV_PLATFORM_STUDIO);

  return (
    <div className={isExpand ? styles.sideNavExpandable : styles.sideNav}>
      {isHomeShown && <SideNavItem data={SIDENAV_EXPERIENCE_DATA} isExpand={isExpand} onItemClick={onItemClick} isActive={!isStudioPath} />}
      {isStudioShown && (
        <SideNavItem
          isExpand={isExpand}
          isActive={isStudioPath}
          isClickDisabled={!isHomeShown}
          data={SIDENAV_STUDIO_DATA}
          onItemClick={onItemClick}
        />
      )}
    </div>
  );
};

SideNav.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default SideNav;
