import ACTION_TYPES from '../constants/recordSharingRuleList.actionTypes';

const EDIT_ACTION = {
  id: ACTION_TYPES.EDIT_RULE,
  name: __('Edit'),
};

const DELETE_ACTION = {
  id: ACTION_TYPES.DELETE_RULE,
  name: __('Delete'),
};

export { EDIT_ACTION, DELETE_ACTION };
