exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".templateVariableSelectionPopupContent_popupActions__gL1mgqAX8o{justify-content:flex-end}.templateVariableSelectionPopupContent_closeBtn__dSyQu41wH4{top:1.2rem}[dir] .templateVariableSelectionPopupContent_closeBtn__dSyQu41wH4{padding:.4rem}[dir=ltr] .templateVariableSelectionPopupContent_closeBtn__dSyQu41wH4{right:1.2rem}[dir=rtl] .templateVariableSelectionPopupContent_closeBtn__dSyQu41wH4{left:1.2rem}.templateVariableSelectionPopupContent_popupContainer__pWiTfk1eiD{width:38rem;height:14rem;justify-content:space-between}.templateVariableSelectionPopupContent_templateVariableContent__fRXtgxuoUC{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.templateVariableSelectionPopupContent_templateVariableContentContainer__sN8BocNKS9{width:30rem}", ""]);

// Exports
exports.locals = {
	"popupActions": "templateVariableSelectionPopupContent_popupActions__gL1mgqAX8o",
	"closeBtn": "templateVariableSelectionPopupContent_closeBtn__dSyQu41wH4",
	"popupContainer": "templateVariableSelectionPopupContent_popupContainer__pWiTfk1eiD",
	"templateVariableContent": "templateVariableSelectionPopupContent_templateVariableContent__fRXtgxuoUC",
	"templateVariableContentContainer": "templateVariableSelectionPopupContent_templateVariableContentContainer__sN8BocNKS9"
};