exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".createDashboard_bodyContainer__4MB7TdMHj8{display:flex;flex-direction:row;height:100%}[dir] .createDashboard_bodyContainer__4MB7TdMHj8{background-color:#eeeff0}.createDashboard_loaderWrapper__68umtihLPg{width:33.4rem;min-height:4rem}[dir] .createDashboard_loaderWrapper__68umtihLPg{border-bottom:.1em solid #d4d5d6}[dir=ltr] .createDashboard_editButton__5QtmtAdEaU{margin-left:1rem}[dir=rtl] .createDashboard_editButton__5QtmtAdEaU{margin-right:1rem}.createDashboard_headerContainer__hgMJG3TnJv .createDashboard_content__nNqVgfKWg5{display:flex;justify-content:space-between;align-items:center}.createDashboard_editContainer__gFkmoGSg3q{display:flex;justify-content:space-around;align-items:center}.createDashboard_editContainer__gFkmoGSg3q .createDashboard_dashboardName__cKvJEJxt6C{font-size:1.8rem}[dir=ltr] .createDashboard_editContainer__gFkmoGSg3q .createDashboard_dashboardName__cKvJEJxt6C{padding-left:2rem}[dir=rtl] .createDashboard_editContainer__gFkmoGSg3q .createDashboard_dashboardName__cKvJEJxt6C{padding-right:2rem}", ""]);

// Exports
exports.locals = {
	"bodyContainer": "createDashboard_bodyContainer__4MB7TdMHj8",
	"loaderWrapper": "createDashboard_loaderWrapper__68umtihLPg",
	"editButton": "createDashboard_editButton__5QtmtAdEaU",
	"headerContainer": "createDashboard_headerContainer__hgMJG3TnJv",
	"content": "createDashboard_content__nNqVgfKWg5",
	"editContainer": "createDashboard_editContainer__gFkmoGSg3q",
	"dashboardName": "createDashboard_dashboardName__cKvJEJxt6C"
};