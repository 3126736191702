import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import TextArea from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';

import { BUNDLE_STATE } from '../../exportList/constants/exportList.general.constants';
import { FORM_MODES } from '../../../../../constants/general.constants';

const FIELD_IDS = {
  DISPLAY_NAME: 'displayName',
  NAME: 'name',
  DESCRIPTION: 'description',
};

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Display Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Display Name'),
  },
};

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Name'),
  },
};

const DESCRIPTION_FIELD = {
  id: FIELD_IDS.DESCRIPTION,
  renderer: TextArea,
  renderOptions: {
    label: __('Description'),
    placeholder: __('Enter Description'),
  },
};

const EXPORT_FORM_FIELDS = {
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DESCRIPTION]: DESCRIPTION_FIELD,
};

const getFields = (mode, bundleState) => ({
  ...EXPORT_FORM_FIELDS,
  [FIELD_IDS.NAME]: addToRenderOptions(NAME_FIELD, [{ path: 'disabled', value: mode === FORM_MODES.EDIT || bundleState === BUNDLE_STATE.PUBLISHED }]),
  [FIELD_IDS.DISPLAY_NAME]: addToRenderOptions(DISPLAY_NAME_FIELD, [{ path: 'disabled', value: bundleState === BUNDLE_STATE.PUBLISHED }]),
  [FIELD_IDS.DESCRIPTION]: addToRenderOptions(DESCRIPTION_FIELD, [
    {
      path: 'disabled',
      value: bundleState === BUNDLE_STATE.PUBLISHED,
    },
  ]),
});

export default getFields;

export { FIELD_IDS };
