/* This file is not using right now as we are not supporting Time of the day node */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Modal from '@tekion/tekion-components/molecules/Modal';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Error from '@tekion/tekion-components/organisms/FormBuilder/components/error';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { computeUserData, parseUserData } from './timeOfTheDay.helper';
import { getFormSections, getFieldConfig } from './timeOfTheDayModal.fields';
import { ALL_DAYS_SELECTED, CONSTANTS } from './timeOfTheDay.constants';
import { MODAL_MODES } from '../../../constants/workflowBuilder.constants';

import FIELD_IDS from './timeOfTheDay.fieldTypes';

import styles from './timeOfTheDay.module.scss';

const CONTEXT_ID = 'TIME_OF_THE_DAY_MODAL_FORM';

const TimeOfTheDayModal = (props) => {
  const { mode, isError, fieldOptions, stepDetails, onSubmit, onCancel } = props;

  const [values, setValue] = useState();
  const [errors, setErrors] = useState();

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { value: updatedValue, id } = payload;
          setValue({ ...values, [id]: updatedValue });
          break;
        }
        case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
          const { errors: newErrors } = payload;
          setErrors(newErrors);
          break;
        }
        default:
      }
    },
    [values],
  );

  const handleSubmit = useCallback(() => {
    setValue({});
    setErrors({});
    onSubmit(computeUserData(values));
  }, [values, onSubmit]);

  const handleCancel = useCallback(() => {
    setValue({});
    setErrors({});
    onCancel();
  }, [onCancel]);

  const formFields = useMemo(() => getFieldConfig(fieldOptions), [fieldOptions]);
  const formSections = useMemo(() => getFormSections(fieldOptions), [fieldOptions]);

  const getTimeOfTheDayContent = useMemo(
    () => (
      <>
        <FormWithSubmission
          sections={formSections}
          fields={formFields}
          values={values}
          errors={errors}
          contextId={CONTEXT_ID}
          onAction={handleAction}
        />
        <PropertyControlledComponent controllerProperty={isError}>
          <Error error={CONSTANTS.ERROR} className={styles.error} />
        </PropertyControlledComponent>
      </>
    ),
    [values, errors, formFields, handleAction, formSections, isError],
  );

  useEffect(() => {
    if (mode === MODAL_MODES.EDIT) {
      const userData = tget(stepDetails, 'userData.data');
      if (!_isEmpty(userData)) {
        setValue((_value) => ({ ..._value, ...parseUserData(userData) }));
      }
    } else {
      setValue((_value) => ({ ..._value, [FIELD_IDS.DAYS_OF_WEEK]: ALL_DAYS_SELECTED }));
    }
  }, [stepDetails, mode]);

  return (
    <Modal
      visible
      destroyOnClose
      title={__(CONSTANTS.MODAL_TITLE)}
      width={Modal.SIZES.MD}
      submitBtnText={__(CONSTANTS.SUBMIT_BTN_TEXT)}
      content={getTimeOfTheDayContent}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

TimeOfTheDayModal.propTypes = {
  isError: PropTypes.bool,
  mode: PropTypes.string,
  stepDetails: PropTypes.object,
  fieldOptions: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

TimeOfTheDayModal.defaultProps = {
  isError: false,
  mode: EMPTY_STRING,
  fieldOptions: EMPTY_OBJECT,
  stepDetails: EMPTY_OBJECT,
  onSubmit: _noop,
  onCancel: _noop,
};

export default TimeOfTheDayModal;
