import _keys from 'lodash/keys';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _omitBy from 'lodash/omitBy';
import _includes from 'lodash/includes';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FIELD_DATA_TYPES_LABELS, FIELD_TYPES_LABELS } from '../constants/generalDetailsForm.general.constants';
import GENERAL_DETAILS_FORM_FIELD_IDS from '../constants/generalDetailsForm.fieldIds';
import DATA_TYPES from '../../../../../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../../../../../constants/fieldDefinition.fieldTypes';

const fieldDataTypeCheck = {
  [DATA_TYPES.TEXT]: (fieldType) =>
    _isEmpty(fieldType) ||
    _includes([FIELD_TYPES.TEXT, FIELD_TYPES.SELECT, FIELD_TYPES.MEDIA, FIELD_TYPES.RELATIONSHIP, FIELD_TYPES.RICH_TEXT_EDITOR], fieldType),
  [DATA_TYPES.NUMBER]: (fieldType) =>
    _isEmpty(fieldType) || _includes([FIELD_TYPES.TEXT, FIELD_TYPES.RANGE, FIELD_TYPES.CHILD_AGGREGATE_SUMMARY, FIELD_TYPES.RELATIONSHIP], fieldType),
  [DATA_TYPES.DATE]: (fieldType) => _isEmpty(fieldType) || _includes([FIELD_TYPES.TEXT, FIELD_TYPES.RANGE], fieldType),
  [DATA_TYPES.DATE_TIME]: (fieldType) => _isEmpty(fieldType) || _includes([FIELD_TYPES.TEXT], fieldType),
  [DATA_TYPES.BOOLEAN]: (fieldType) => _isEmpty(fieldType) || _includes([FIELD_TYPES.TEXT], fieldType),
  [DATA_TYPES.COMPLEX]: (fieldType) => _isEmpty(fieldType) || _includes([FIELD_TYPES.TEXT], fieldType),
};

const fieldTypeCheck = {
  [FIELD_TYPES.TEXT]: (fieldDataType) =>
    _isEmpty(fieldDataType) ||
    _includes([DATA_TYPES.TEXT, DATA_TYPES.NUMBER, DATA_TYPES.DATE, DATA_TYPES.DATE_TIME, DATA_TYPES.BOOLEAN, DATA_TYPES.COMPLEX], fieldDataType),
  [FIELD_TYPES.RANGE]: (fieldDataType) => _isEmpty(fieldDataType) || _includes([DATA_TYPES.NUMBER, DATA_TYPES.DATE], fieldDataType),
  [FIELD_TYPES.MEDIA]: (fieldDataType) => _includes([DATA_TYPES.TEXT], fieldDataType),
  [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: (fieldDataType) => _includes([DATA_TYPES.NUMBER], fieldDataType),
  [FIELD_TYPES.RELATIONSHIP]: (fieldDataType) => _isEmpty(fieldDataType) || _includes([DATA_TYPES.TEXT, DATA_TYPES.NUMBER], fieldDataType),
  [FIELD_TYPES.RICH_TEXT_EDITOR]: (fieldDataType) => _includes([DATA_TYPES.TEXT], fieldDataType),
  [FIELD_TYPES.SELECT]: (fieldDataType) => _isEmpty(fieldDataType) || _includes([DATA_TYPES.TEXT], fieldDataType),
};

const getFieldDataTypeOptions = () =>
  _map(_keys(FIELD_DATA_TYPES_LABELS), (fieldDataType) => ({
    value: fieldDataType,
    label: tget(FIELD_DATA_TYPES_LABELS, fieldDataType, NO_DATA),
  }));

const getFieldTypeOptions = (fieldDataType) =>
  _map(filterRows(_keys(FIELD_TYPES_LABELS), fieldDataType, fieldTypeCheck), (fieldType) => ({
    value: fieldType,
    label: tget(FIELD_TYPES_LABELS, fieldType, NO_DATA),
  }));

const getFieldsFormGeneralDetailsValues = (values, renderOptions) => {
  const fieldsFormGeneralDetailsValues = _omitBy(
    values,
    (value, fieldId) => _isNil(value) || !!_get(renderOptions, [fieldId, 'disabled']) || !!_get(renderOptions, [fieldId, 'isDisabled']),
  );
  const fieldType = _get(fieldsFormGeneralDetailsValues, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE);
  const dataType = _get(fieldsFormGeneralDetailsValues, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE);

  if (!_isEmpty(fieldType)) {
    _set(fieldsFormGeneralDetailsValues, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE, getArraySafeValue(fieldType));
  }

  if (!_isEmpty(dataType)) {
    _set(fieldsFormGeneralDetailsValues, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE, getArraySafeValue(dataType));
  }

  return fieldsFormGeneralDetailsValues;
};

const getGeneralDetailsFormValues = (fieldsFormGeneralDetailsValues) => {
  const values = _omitBy(fieldsFormGeneralDetailsValues, _isNil);
  const fieldType = _get(fieldsFormGeneralDetailsValues, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE);
  const dataType = _get(fieldsFormGeneralDetailsValues, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE);

  if (!_isEmpty(fieldType)) {
    _set(values, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE, _castArray(fieldType));
  }

  if (!_isEmpty(dataType)) {
    _set(values, GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE, _castArray(dataType));
  }

  return values;
};

export { getFieldDataTypeOptions, getFieldTypeOptions, getFieldsFormGeneralDetailsValues, getGeneralDetailsFormValues, fieldDataTypeCheck };
