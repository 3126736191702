import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _head from 'lodash/head';
import _set from 'lodash/set';
import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import _valuesIn from 'lodash/valuesIn';
import _reduce from 'lodash/reduce';
import _filter from 'lodash/filter';
import _isObject from 'lodash/isObject';
import _size from 'lodash/size';
import _split from 'lodash/split';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { getUpdatedImageTagInHtml } from '../../../../utils/template.utils';
import { getSimpleFieldNameFromColumn } from '../../../../utils';
import { COMPONENT_TYPES } from '../../constants/viewBuilder.constants';
import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';
import SYSTEM_DEFINED_FIELDS from '../../../../constants/systemDefinedFields';
import { CONSTRAINT_TYPES } from './formViewRenderer.constants';
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import { ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';
import entityReader from '../../../../readers/entity.reader';

import styles from './formViewRenderer.module.scss';

const getComplexViewConfigNamesAndComplexEntities = (
  componentConfig,
  complexViewNames,
  complexEntities,
  fieldDefinitionByName,
  mapOfEntityNameToComplexViewConfigNames,
) => {
  const type = _get(componentConfig, 'type');
  if (type === COMPONENT_TYPES.FORM_VIEW_COLUMN) {
    const complexViewName = _get(componentConfig, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.COMPLEX_VIEW_NAME}`);
    const fieldName = _head(_get(componentConfig, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.FIELD_NAMES}`, ['']));
    const complexEntityName = fieldDefinitionReader.complexFieldDefinitionEntityName(_get(fieldDefinitionByName, fieldName));

    if (!_isEmpty(complexViewName)) {
      _set(complexViewNames, fieldName, { complexViewName, complexEntityName });

      const complexViewNamesOfCurrentEntity = tget(mapOfEntityNameToComplexViewConfigNames, complexEntityName, []);
      complexViewNamesOfCurrentEntity.push(complexViewName);
      _set(mapOfEntityNameToComplexViewConfigNames, complexEntityName, complexViewNamesOfCurrentEntity);
    }
    if (!_isEmpty(complexEntityName)) complexEntities.push(complexEntityName);
  } else {
    _forEach(_get(componentConfig, 'children'), (child) => {
      getComplexViewConfigNamesAndComplexEntities(
        child,
        complexViewNames,
        complexEntities,
        fieldDefinitionByName,
        mapOfEntityNameToComplexViewConfigNames,
      );
    });
  }

  return complexViewNames;
};

const getFormValuesFromResponse = (data, fieldNames) => {
  const entityRecord = { ...data, ..._get(data, 'entity', {}) };
  const formValues = _pick(entityRecord, fieldNames);
  return formValues;
};

const getFormRendererComponent = (section) => {
  const type = _get(section, 'type', '');
  if (type === COMPONENT_TYPES.FORM_VIEW_RENDERER) {
    return section;
  } else {
    let childSection = false;
    _forEach(_get(section, 'children'), (child) => {
      childSection = getFormRendererComponent(child);
      if (childSection) return false;
      return true;
    });
    return childSection;
  }
};

const getSectionsAndFieldsFromChildren = (value) => {
  const children = _get(value, 'children', []);
  const childFormFields = {};

  const childFormSections = _map(children, (formSection) => {
    const rows = _get(formSection, 'children', []);
    const sectionLabel = _get(formSection, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.TITLE}`, '');

    return {
      header: {
        label: sectionLabel,
      },
      rows: _map(rows, (row) => {
        const columns = _get(row, 'children', []);

        return {
          columns: _map(columns, (column) => {
            const columnProperties = _get(column, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, {});
            const fieldName = _get(columnProperties, 'fieldNames.[0]');
            childFormFields[fieldName] = columnProperties;

            return fieldName;
          }),
          className: styles.columnContainer,
        };
      }),
    };
  });

  return {
    formSections: childFormSections,
    formFields: childFormFields,
  };
};

const getFieldsAndSectionFromView = (viewConfig) => {
  const section = _get(viewConfig, 'section');

  const formRendererComponent = getFormRendererComponent(section);
  const { formFields, formSections } = getSectionsAndFieldsFromChildren(formRendererComponent);

  return { formFields, formSections };
};

const getUpdatedFieldDefinitions = (entity, complexEntity) => {
  const complexEntitiesByName = _keyBy(complexEntity, 'name');

  const updatedFieldDefinitions = _map(entityReader.fieldDefinitions(entity), (field) => {
    const dataType = fieldDefinitionReader.dataType(field);

    if (dataType === DATA_TYPES.COMPLEX) {
      const lookUpEntity = fieldDefinitionReader.complexFieldDefinitionEntityName(field);
      const complexEntityFieldDefinitions = entityReader.fieldDefinitions(_get(complexEntitiesByName, lookUpEntity));

      return {
        ...field,
        complexEntityFieldDefinitions: {
          ..._keyBy(complexEntityFieldDefinitions, 'name'),
        },
      };
    }
    return field;
  });

  return updatedFieldDefinitions;
};

const getModifiedRichTextEditorFieldPayload = (fieldDefinitionsByName, formValues) => {
  let newFormValues = { ...formValues };
  _forEach(formValues, (value, key) => {
    const fieldDef = _get(fieldDefinitionsByName, getSimpleFieldNameFromColumn(key), EMPTY_OBJECT);
    if (fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.RICH_TEXT_EDITOR) {
      const newHtmlValue = getUpdatedImageTagInHtml(_get(value, 'htmlContent', EMPTY_STRING));
      newFormValues = { ...newFormValues, [key]: _isEmpty(newHtmlValue) ? value : newHtmlValue };
    }
    if (fieldDefinitionReader.dataType(fieldDef) === DATA_TYPES.COMPLEX) {
      const newFieldDefinitionsByName = fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef);
      newFormValues = { ...newFormValues, [key]: getModifiedRichTextEditorFieldPayload(newFieldDefinitionsByName, value) };
    }
  });
  return newFormValues;
};

const getModifiedRelationshipFieldPayload = (fieldDefinitionsByName, formValues) => {
  let newFormValues = { ...formValues };
  _forEach(formValues, (value, key) => {
    const fieldDef = _get(fieldDefinitionsByName, getSimpleFieldNameFromColumn(key), EMPTY_OBJECT);
    if (fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.RELATIONSHIP) {
      const lookupField = fieldDefinitionReader.lookupField(fieldDef) || 'id';
      const multiValued = fieldDefinitionReader.multiValued(fieldDef);
      let fieldValue = value;
      if (multiValued) {
        fieldValue = _map(fieldValue, (data) => {
          if (_isObject(data)) {
            return _get(data, lookupField) || tget(data, `entity.${lookupField}`, EMPTY_STRING);
          } else {
            return data;
          }
        });
      }
      newFormValues = { ...newFormValues, [key]: fieldValue };
    } else if (fieldDefinitionReader.dataType(fieldDef) === DATA_TYPES.COMPLEX) {
      const newFieldDefinitionsByName = fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef);
      newFormValues = { ...newFormValues, [key]: getModifiedRelationshipFieldPayload(newFieldDefinitionsByName, value) };
    }
  });
  return newFormValues;
};

const getPayloadFromFormValues = (formValues, entity) => {
  const fieldDefinitionsByName = _keyBy(_get(entity, 'fieldDefinitions', EMPTY_ARRAY), 'name');
  let newFormValues = getModifiedRichTextEditorFieldPayload(fieldDefinitionsByName, formValues);
  newFormValues = getModifiedRelationshipFieldPayload(fieldDefinitionsByName, newFormValues);

  if (!_isEmpty(newFormValues)) {
    const payload = {
      ..._pick(newFormValues, _valuesIn(SYSTEM_DEFINED_FIELDS)),
      entity: {
        ..._omit(newFormValues, _valuesIn(SYSTEM_DEFINED_FIELDS)),
      },
    };

    return payload;
  } else return {};
};

const getDependentOptionMap = (fieldDefinitionByName) => {
  const dependentOptionsByFieldName = _reduce(
    fieldDefinitionByName,
    (result, fieldDef, fieldName) => {
      const controllingFieldName = fieldDefinitionReader.controllingFieldName(fieldDef);

      if (controllingFieldName) {
        const controllingOptionsConfigs = fieldDefinitionReader.controllingOptionsConfigs(fieldDef);

        const optionValuesByControllingFieldValues = _reduce(
          controllingOptionsConfigs,
          (draft, optionConfig) => {
            const controlledOptionValue = _get(optionConfig, 'controlledOption');
            const controllingOptions = _get(optionConfig, 'controllingOptions', EMPTY_ARRAY);
            let newResult = draft;

            _forEach(controllingOptions, (controllingOption) => {
              const controlledOptionOfResult = _get(newResult, controllingOption, []);
              controlledOptionOfResult.push(controlledOptionValue);
              newResult = {
                ...newResult,
                [controllingOption]: controlledOptionOfResult,
              };
            });

            return newResult;
          },
          {},
        );

        return {
          ...result,
          [fieldName]: optionValuesByControllingFieldValues,
        };
      }

      return result;
    },
    {},
  );

  return dependentOptionsByFieldName;
};

const getFieldsWithRegexValidation = (entity) => {
  const fieldDefinitions = entityReader.fieldDefinitions(entity);
  const fieldsWithRegexValidation = _reduce(
    fieldDefinitions,
    (result, fieldDef) => {
      const constraints = fieldDefinitionReader.constraints(fieldDef);
      const fieldId = fieldDefinitionReader.name(fieldDef);
      const regexConstraint = _filter(constraints, (constraint) => {
        const constraintType = tget(constraint, 'type');
        if (constraintType === CONSTRAINT_TYPES.REGEX) {
          return true;
        }
        return false;
      });

      if (!_isEmpty(regexConstraint)) {
        const pattern = _get(getArraySafeValue(regexConstraint), 'pattern', '');
        let regex;

        try {
          regex = new RegExp(pattern);
        } catch {
          return true; // Continue
        }

        return { ...result, [fieldId]: { pattern, regex } };
      }
      return result;
    },
    {},
  );

  return fieldsWithRegexValidation;
};

const checkLookupFieldNamesValidation = (lookupFieldNames) => {
  if (_isEmpty(lookupFieldNames)) {
    return false;
  }

  const result = _reduce(
    lookupFieldNames,
    (acc, lookupFieldName) => {
      // currentField.childField
      const hasCurrentAndChildField = _size(_split(lookupFieldName, '.')) === 2;

      return acc && hasCurrentAndChildField;
    },
    true,
  );

  return result;
};

export {
  getFormValuesFromResponse,
  getFieldsAndSectionFromView,
  getSectionsAndFieldsFromChildren,
  getComplexViewConfigNamesAndComplexEntities,
  getUpdatedFieldDefinitions,
  getPayloadFromFormValues,
  getDependentOptionMap,
  getFieldsWithRegexValidation,
  checkLookupFieldNamesValidation,
};
