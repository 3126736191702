import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';

// Constants
import { DEFAULT_USER_LIST_PROPS, TABLE_CONSTANTS, SEARCHABLE_OPTIONS } from './constants/workspaceUserList.general.constants';
import ACTION_TYPES from './constants/workspaceUserList.actionTypes';

// Table Helpers
import USER_LIST_COLUMNS from './tableHelpers/workspaceUserList.columns';
import { getTableProps } from './tableHelpers/workspaceUserList.helpers';
import { HEADER_PROPS, SUB_HEADER_PROPS, TABLE_MANAGER_PROPS } from './constants/workspaceUserList.tableProps';
import ACTION_HANDLERS from './tableHelpers/workspaceUserList.actionHandlers';

const WorkspaceUserList = ({
  loading,
  isRequestDialogVisible,
  pageSize,
  currentPage,
  totalNumberOfEntries,
  dialogText,
  searchText,
  searchField,
  nextPageToken,
  users,
  onAction,
}) => {
  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CLOSE_REQUEST_DIALOG,
    });
  }, [onAction]);

  const handleConfirmationDialogSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CONFIRM_REQUEST });
  }, [onAction]);

  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({
        type: actionType,
        payload,
      });
    },
    [onAction],
  );

  return (
    <>
      <TableManager
        tokenPagination
        columns={USER_LIST_COLUMNS}
        tableManagerProps={TABLE_MANAGER_PROPS}
        subHeaderProps={SUB_HEADER_PROPS}
        headerProps={HEADER_PROPS}
        searchText={searchText}
        searchField={searchField}
        searchableFieldsOptions={SEARCHABLE_OPTIONS}
        nextPageToken={nextPageToken}
        data={users}
        tableProps={getTableProps(handleRowAction, currentPage, loading, pageSize, totalNumberOfEntries)}
        onAction={onAction}
      />
      <ConfirmationDialog
        isVisible={isRequestDialogVisible}
        title={__('Confirmation Message')}
        onSubmit={handleConfirmationDialogSubmit}
        onCancel={handleConfirmationDialogCancel}
        content={dialogText}
      />
    </>
  );
};
WorkspaceUserList.propTypes = {
  loading: PropTypes.bool,
  isRequestDialogVisible: PropTypes.bool,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalNumberOfEntries: PropTypes.number,
  dialogText: PropTypes.string,
  searchText: PropTypes.string,
  searchField: PropTypes.string,
  nextPageToken: PropTypes.string,
  users: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

WorkspaceUserList.defaultProps = {
  loading: true,
  isRequestDialogVisible: false,
  pageSize: DEFAULT_PAGE_SIZE,
  currentPage: DEFAULT_PAGE_INFO.start,
  totalNumberOfEntries: TABLE_CONSTANTS.TOTAL_NUMBER_OF_ENTRIES,
  dialogText: EMPTY_STRING,
  searchText: EMPTY_STRING,
  searchField: EMPTY_STRING,
  nextPageToken: EMPTY_STRING,
  users: EMPTY_ARRAY,
};

export default withActions(DEFAULT_USER_LIST_PROPS, ACTION_HANDLERS)(WorkspaceUserList);
