import { useCallback } from 'react';

const handleLinkClick = (setShowPopover, disableEditing) => {
  if (disableEditing) {
    return true;
  }
  setShowPopover(true);
  // return false; disables the default link behaviour
  return false;
};

function useLinkOnClick(setShowPopover, disableEditing) {
  const handleClick = useCallback(() => handleLinkClick(setShowPopover, disableEditing), [setShowPopover, disableEditing]);

  return handleClick;
}

export default useLinkOnClick;
