const BASE_ROUTE = '/';
const LOGOUT_ROUTE = '/logout';
const STUDIO_ROUTE = '/studio';

// Will add better structure for all routes
const TENANT_UNIVERSE_PAGE_ID = 'tenant-universe';
const TENANT_UNIVERSE_BASE_ROUTE = `/${TENANT_UNIVERSE_PAGE_ID}`;

const NOTIFICATION_REDIRECTOR_ROUTE = '/notification';

export { BASE_ROUTE, LOGOUT_ROUTE, STUDIO_ROUTE, TENANT_UNIVERSE_BASE_ROUTE, TENANT_UNIVERSE_PAGE_ID, NOTIFICATION_REDIRECTOR_ROUTE };
