import _castArray from 'lodash/castArray';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { VIEW_CONFIGURATION_FIELD_IDS } from '../constants/viewBuilder.constants';

const getPayload = (viewNames, entityName, additionalPayload) => {
  const filters = [
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.NAME,
      filterType: OPERATORS.IN,
      values: _castArray(viewNames),
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      filterType: OPERATORS.IN,
      values: _castArray(entityName),
    },
  ];

  const payload = {
    ...additionalPayload,
    filters: [...tget(additionalPayload, 'filters', EMPTY_ARRAY), ...filters],
  };

  return payload;
};
export { getPayload };
