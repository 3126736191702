import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import CONDITION_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';

const COLUMNS = [
  {
    Header: __('Field'),
    accessor: CONDITION_FIELD_IDS.FIELD,
    id: CONDITION_FIELD_IDS.FIELD,
    Cell: TextRenderer,
  },
  {
    Header: __('Operator'),
    accessor: CONDITION_FIELD_IDS.OPERATOR,
    id: CONDITION_FIELD_IDS.OPERATOR,
    Cell: TextRenderer,
  },
  {
    Header: __('Values'),
    accessor: CONDITION_FIELD_IDS.VALUES,
    id: CONDITION_FIELD_IDS.VALUES,
    Cell: TextRenderer,
  },
];

export { COLUMNS };
