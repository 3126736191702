import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _map from 'lodash/map';

import { VIEW_TYPES } from '../../constants/viewBuilder.constants';

import styles from './configurator.module.scss';

const TabBarRenderer = ({ viewType, panels = [], onTabClick }) => (
  <div className={styles.tabs}>
    {_map(panels, (panel) => {
      const {
        key,
        props: { tab, className, isActive, isDisabled },
      } = panel;

      return (
        <div
          key={tab}
          className={cx(styles.tab, className, {
            [styles.active]: isActive,
            [styles.disabled]: isDisabled,
            [styles.tabFlexBasisStyleForListAndGridView]: viewType === VIEW_TYPES.LIST_VIEW || viewType === VIEW_TYPES.GRID_VIEW,
          })}
          onClick={(event) => onTabClick(key, event)}
        >
          {__(tab)}
        </div>
      );
    })}
  </div>
);

TabBarRenderer.propTypes = {
  viewType: PropTypes.string,
  panels: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTabClick: PropTypes.func.isRequired,
};

TabBarRenderer.defaultProps = {
  viewType: undefined,
};

export default TabBarRenderer;
