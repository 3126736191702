import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import { tget } from '@tekion/tekion-base/utils/general';

import { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import IconAsBtn from '@tekion/tekion-components/atoms/iconAsBtn';
import BadgeWrapper from '@tekion/tekion-components/atoms/BadgeWrapper/BadgeWrapper';
import Popover, { POPOVER_TRIGGER, POPOVER_PLACEMENT } from '@tekion/tekion-components/molecules/popover';

import { NOTIFICATION_FIELD_IDS, NOTIFICATION_GROUP_FIELD_IDS } from '../../../../../../../../constants/notification.constants';

import styles from './notificationGroup.module.scss';

const UNREAD_COUNT_BADGE_STYLE = {
  cursor: 'pointer',
  marginRight: '0.8rem',
  backgroundColor: '#4285f4',
};

const NotificationGroupHeader = ({ isGroupExpanded, notificationGroup, onGroupExpandClick, onClearGroupClick, onMarkGroupAsReadClick }) => {
  const topNotification = tget(notificationGroup, NOTIFICATION_GROUP_FIELD_IDS.COMMUNICATION);
  const count = tget(notificationGroup, NOTIFICATION_GROUP_FIELD_IDS.COUNT);
  const unreadCount = tget(notificationGroup, NOTIFICATION_GROUP_FIELD_IDS.UNREAD_COUNT);

  const groupKey = tget(topNotification, NOTIFICATION_FIELD_IDS.GROUP_KEY, __('Notification group'));

  return (
    <div className="flex justify-content-between align-items-center">
      <span className={styles.groupHeader}>
        {groupKey} ({count})
      </span>
      <div className="flex">
        <IconAsBtn id="CLEAR_BUTTON" className={styles.clearIcon} size={SIZES.S} onClick={onClearGroupClick}>
          icon-cross
        </IconAsBtn>
        <Popover
          placement={POPOVER_PLACEMENT.TOP}
          trigger={POPOVER_TRIGGER.HOVER}
          content={<div className={styles.unreadCountToolTip}>{__('Mark group as read')}</div>}
        >
          <BadgeWrapper badgeContent={unreadCount} style={UNREAD_COUNT_BADGE_STYLE} onClick={onMarkGroupAsReadClick} />
        </Popover>
        <IconAsBtn id="COLLAPSE_BUTTON" className="p-t-4" disabled={count === 1} size={SIZES.S} onClick={onGroupExpandClick}>
          {isGroupExpanded ? 'icon-chevron-up' : 'icon-chevron-down'}
        </IconAsBtn>
      </div>
    </div>
  );
};

NotificationGroupHeader.propTypes = {
  isGroupExpanded: PropTypes.bool,
  notificationGroup: PropTypes.object.isRequired,
  onGroupExpandClick: PropTypes.func,
  onClearGroupClick: PropTypes.func,
  onMarkGroupAsReadClick: PropTypes.func,
};

NotificationGroupHeader.defaultProps = {
  isGroupExpanded: false,
  onGroupExpandClick: _noop,
  onClearGroupClick: _noop,
  onMarkGroupAsReadClick: _noop,
};

export default NotificationGroupHeader;
