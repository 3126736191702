import React from 'react';
import PropTypes from 'prop-types';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';

import LabelValueRenderer from '../../organisms/manageRecord/atoms/labelValueRenderer';

import WWD_ACCESS_TYPES_BY_ID from '../../../../../../../../constants/wwdAccessTypes.constants';
import { WWD_FIELD_IDS } from '../../../../../../../../constants/wwdPermissions.constants';

import styles from './manageAccessHeader.module.scss';

const ManageAccessHeader = ({ wwdData, entityRecord }) => {
  const wwdAccessType = tget(wwdData, WWD_FIELD_IDS.ACCESS_TYPE, NO_DATA);
  const wwdAccessTypeLabel = WWD_ACCESS_TYPES_BY_ID[wwdAccessType]?.name;
  const ownerName = tget(entityRecord, ['entity', 'ownerId.name'], NO_DATA);

  return (
    <div className={styles.container}>
      <LabelValueRenderer label={__('WWD Access')} value={wwdAccessTypeLabel} />
      <LabelValueRenderer className={styles.spacing} label={__('Owner')} value={ownerName} />
    </div>
  );
};

ManageAccessHeader.propTypes = {
  wwdData: PropTypes.object,
  entityRecord: PropTypes.object,
};

ManageAccessHeader.defaultProps = {
  wwdData: EMPTY_OBJECT,
  entityRecord: EMPTY_OBJECT,
};

export default ManageAccessHeader;
