import _get from 'lodash/get';
import _set from 'lodash/set';
import _forEach from 'lodash/forEach';
import _isNil from 'lodash/isNil';
import _valuesIn from 'lodash/valuesIn';
import _cloneDeep from 'lodash/cloneDeep';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { isErrorEmpty } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getComplexDetailsFormValues, getFieldsFormComplexDetailsValues } from './complexFieldDetailsForm.helpers';

import FIELDS_FORM_FIELD_IDS from '../../../constants/fieldsForm.fieldIds';
import ACTION_TYPES from '../../../constants/fieldsForm.actionTypes';
import COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS from '../constants/complexFieldDetailsForm.fieldIds';
import { FIELD_FORM_CONTEXT_ID } from '../../../constants/fieldsForm.constants';

const handleFormInit = ({ getState, setState }) => {
  const { fieldsFormComplexDetailsValues = EMPTY_OBJECT } = getState();

  setState({ values: getComplexDetailsFormValues(fieldsFormComplexDetailsValues), isInitialStateLoaded: true });
};

const handleFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { values: oldValues = EMPTY_OBJECT, fieldsFormOnAction } = getState();

  const values = { ...oldValues, [id]: value };

  if (id === COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS.ENTITY_NAME) {
    _set(values, id, getArraySafeValue(value));
  }

  setState({ values }, () =>
    fieldsFormOnAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: { id: FIELDS_FORM_FIELD_IDS.COMPLEX_FIELD_DETAILS_FORM, value: getFieldsFormComplexDetailsValues(values) },
    }),
  );
};

const handleValidationSuccess = ({ getState, params = EMPTY_OBJECT }) => {
  const { fieldsFormOnAction } = getState();
  const { errors } = params;

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: { id: FIELDS_FORM_FIELD_IDS.COMPLEX_FIELD_DETAILS_FORM, errors },
  });
};

const handleRenderOptionsChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { renderOptions = EMPTY_OBJECT } = params;
  const { values: oldValues = EMPTY_OBJECT, fieldsFormOnAction } = getState();
  const values = { ...oldValues };

  let changed = false;

  _forEach(_valuesIn(COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS), (fieldId) => {
    const value = _get(renderOptions, [fieldId, 'value']);

    if (!_isNil(value) && value !== _get(values, fieldId)) {
      changed = true;
      _set(values, fieldId, value);
    }
  });

  if (changed) {
    setState({ values }, () =>
      fieldsFormOnAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: { id: FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, value: getFieldsFormComplexDetailsValues(values) },
      }),
    );
  }
};

const handleSubmit = ({ getState, params = EMPTY_OBJECT }) => {
  const { additional } = params;
  const { fieldsFormOnAction } = getState();
  const { formErrors } = additional;

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: { id: FIELDS_FORM_FIELD_IDS.COMPLEX_FIELD_DETAILS_FORM, errors: undefined },
  });

  _set(formErrors, FIELDS_FORM_FIELD_IDS.COMPLEX_FIELD_DETAILS_FORM, false);

  if (isErrorEmpty(formErrors)) {
    triggerSubmit(FIELD_FORM_CONTEXT_ID, _cloneDeep(additional));
  }
};

const COMPLEX_FIELD_DETAILS_FORM_ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleFormInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidationSuccess,
  [ACTION_TYPES.ON_RENDER_OPTIONS_CHANGE]: handleRenderOptionsChange,
};

export default COMPLEX_FIELD_DETAILS_FORM_ACTION_HANDLERS;
