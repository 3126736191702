import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _filter from 'lodash/filter';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import DATA_TYPES from '../../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../../constants/fieldDefinition.fieldTypes';
import { FIELD_IDS } from '../constants/sortOptionsConfigurator.constants';

import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';

const getAddedSortOptions = (selectedSortableFieldOptions) =>
  _reduce(
    selectedSortableFieldOptions,
    (prevAddedOptions, sortOption) => {
      const displayName = tget(sortOption, ['label']);
      if (displayName) {
        return [...prevAddedOptions, displayName];
      }
      return prevAddedOptions;
    },
    [],
  );

const getAllSortableFields = (fieldDefinitions) =>
  _reduce(
    fieldDefinitions,
    (prevSortableFields, fieldDef) => {
      const isColumnSortable = fieldDefinitionReader.sortable(fieldDef);
      const fieldType = fieldDefinitionReader.fieldType(fieldDef);

      if (!isColumnSortable || fieldType === FIELD_TYPES.RELATIONSHIP) return prevSortableFields;

      const displayName = fieldDefinitionReader.displayName(fieldDef);
      const fieldName = fieldDefinitionReader.name(fieldDef);
      const dataType = fieldDefinitionReader.dataType(fieldDef);

      if (dataType === DATA_TYPES.COMPLEX) {
        const complexEntityFieldDefinitions = fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef);
        let sortableComplexFields = getAllSortableFields(complexEntityFieldDefinitions);
        sortableComplexFields = _map(sortableComplexFields, ({ label, value }) => ({
          label: __(`${displayName} - ${label}`),
          value: `${fieldName}.${value}`,
        }));

        return [...prevSortableFields, ...sortableComplexFields];
      }

      prevSortableFields.push({
        label: __(displayName),
        value: fieldName,
      });
      return prevSortableFields;
    },
    [],
  );

const getSelectedSortableFieldOptions = (sortableFieldOptions, selectedSortableFields) =>
  _filter(sortableFieldOptions, (sortableFieldOption) => {
    const fieldName = tget(sortableFieldOption, 'value', EMPTY_STRING);

    return _includes(selectedSortableFields, fieldName);
  });

const getRendererPropFormattedDefaultSortDetails = (defaultSortDetails) => {
  let isValid = true;
  let errorMessage = '';

  const rendererPropFormattedDefaultSortDetails = _reduce(
    defaultSortDetails,
    (prevFormattedDefaultSortDetails, defaultSortDetail) => {
      const field = getArraySafeValue(_get(defaultSortDetail, [FIELD_IDS.FIELD]));
      const order = getArraySafeValue(_get(defaultSortDetail, [FIELD_IDS.ORDER]));

      if (!_isEmpty(field) && !_isEmpty(order)) {
        return {
          ...prevFormattedDefaultSortDetails,
          [field]: order,
        };
      } else if (_size(defaultSortDetails) > 1) {
        isValid = false;
        errorMessage = __('All field and order values are required');
      }

      return prevFormattedDefaultSortDetails;
    },
    {},
  );

  return { isValid, error: { [FIELD_IDS.DEFAULT_SORT_DETAILS]: errorMessage }, rendererPropFormattedDefaultSortDetails };
};

const formFormattedDefaultSortDetails = (defaultSortDetails) =>
  _reduce(
    defaultSortDetails,
    (prevDefaultSortDetails, value, key) => {
      if (!_isEmpty(key) && !_isEmpty(value)) {
        return [...prevDefaultSortDetails, { [FIELD_IDS.FIELD]: _castArray(key), [FIELD_IDS.ORDER]: _castArray(value) }];
      }

      return prevDefaultSortDetails;
    },
    [],
  );

const getUpdatedDefaultSortDetails = (defaultSortDetails, sortableFields = EMPTY_ARRAY) =>
  _reduce(
    defaultSortDetails,
    (prevDefaultSortDetails, defaultSortDetail) => {
      const field = getArraySafeValue(tget(defaultSortDetail, [FIELD_IDS.FIELD]));

      if (_includes(sortableFields, field)) {
        return [...prevDefaultSortDetails, defaultSortDetail];
      }

      return prevDefaultSortDetails;
    },
    [],
  );

export {
  getAddedSortOptions,
  getAllSortableFields,
  getSelectedSortableFieldOptions,
  getRendererPropFormattedDefaultSortDetails,
  formFormattedDefaultSortDetails,
  getUpdatedDefaultSortDetails,
};
