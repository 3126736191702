import _values from 'lodash/values';

import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import TextAreaField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { EMPTY_ARRAY } from '@tekion/tekion-base/utils/constant';

import RichTextEditor from '../../../../../../organisms/richTextEditor';

import { nameValidator } from '../../../../../../utils/formValidators';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import { TEMPLATE_TYPE_OPTIONS, TOOLBAR_GROUPS, HELP_TEXT } from '../constants/templateBuilderForm.general.constants';
import { FIELD_IDS } from '../../../../../../constants/templateBuilder.constants';

import styles from '../templateBuilderForm.module.scss';

const TEMPLATE_DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Display Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Display Name'),
  },
};

const TEMPLATE_NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Name'),
    placeholder: __('This will be autofilled'),
  },
};

const TEMPLATE_DESCRIPTION_FIELD = {
  id: FIELD_IDS.DESCRIPTION,
  renderer: TextAreaField,
  renderOptions: {
    label: __('Description'),
    placeholder: __('Type Here'),
    rows: 4,
  },
};

const TEMPLATE_TYPE_FIELD = {
  id: FIELD_IDS.TEMPLATE_TYPE,
  renderer: SelectInput,
  renderOptions: {
    label: __('Template type'),
    required: true,
    options: TEMPLATE_TYPE_OPTIONS,
    validators: [isRequiredRule],
  },
};

const TEMPLATE_SUBJECT_FIELD = {
  id: FIELD_IDS.SUBJECT,
  renderer: TextInput,
  renderOptions: {
    label: __('Subject'),
    required: true,
    validators: [isRequiredRule],
    helpText: HELP_TEXT,
    helpTextClassName: 'p-4',
  },
};

const TEMPLATE_BODY_TITLE_FIELD = {
  id: FIELD_IDS.BODY_TITLE,
  renderer: FieldLabel,
  renderOptions: {
    required: true,
    label: __('Body'),
    helpText: HELP_TEXT,
    infoBadgeClassName: styles.infoBadge,
  },
};
const TEMPLATE_BODY_FIELD = {
  id: FIELD_IDS.BODY,
  renderer: TextAreaField,
  renderOptions: {
    rows: 7,
    label: __('Body'),
    helpText: HELP_TEXT,
    required: true,
    validators: [isRequiredRule],
  },
};

const TEMPLATE_JSON_BODY_FIELD = {
  id: FIELD_IDS.JSON_BODY,
  renderer: RichTextEditor,
  renderOptions: {
    editorId: 'editorid',
    className: `overflow-auto ${styles.richTextEditorWrapper}`,
    toolbarGroups: _values(TOOLBAR_GROUPS),
    toolbarStyles: {
      borderBottom: '0.1rem solid gray',
      padding: '1.2rem 0',
      minHeight: 'fit-content',
      position: 'sticky',
      top: '0',
      zIndex: '1',
    },
  },
};

const TEMPLATE_FORM_FIELDS = {
  [FIELD_IDS.DISPLAY_NAME]: TEMPLATE_DISPLAY_NAME_FIELD,
  [FIELD_IDS.NAME]: TEMPLATE_NAME_FIELD,
  [FIELD_IDS.DESCRIPTION]: TEMPLATE_DESCRIPTION_FIELD,
  [FIELD_IDS.SUBJECT]: TEMPLATE_SUBJECT_FIELD,
  [FIELD_IDS.BODY_TITLE]: TEMPLATE_BODY_TITLE_FIELD,
  [FIELD_IDS.JSON_BODY]: TEMPLATE_JSON_BODY_FIELD,
  [FIELD_IDS.BODY]: TEMPLATE_BODY_FIELD,
  [FIELD_IDS.TEMPLATE_TYPE]: TEMPLATE_TYPE_FIELD,
};

const getFields = (mode) => ({
  ...TEMPLATE_FORM_FIELDS,
  [FIELD_IDS.NAME]: addToRenderOptions(TEMPLATE_NAME_FIELD, [
    { path: 'disabled', value: mode !== FORM_MODES.CREATE },
    { path: 'required', value: mode === FORM_MODES.CREATE },
    { path: 'validators', value: mode === FORM_MODES.CREATE ? [nameValidator] : EMPTY_ARRAY },
  ]),
  [FIELD_IDS.TEMPLATE_TYPE]: addToRenderOptions(TEMPLATE_TYPE_FIELD, [{ path: 'isDisabled', value: mode !== FORM_MODES.CREATE }]),
});

export { FIELD_IDS, getFields };
