import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import { ASSIGNED_VARIABLE_DATA_TYPE_OPTIONS } from '../../../../constants/workflow.nodeVariableConstants';

const GET_RECORD_FIELD_IDS = {
  RECORD_COUNT_FIELD_ID: 'variableType',
};

const RECORD_COUNT_FIELD = {
  id: GET_RECORD_FIELD_IDS.RECORD_COUNT_FIELD_ID,
  renderer: Radio,
  renderOptions: {
    required: true,
    label: __('Records'),
    validators: [isRequiredRule],
    radios: ASSIGNED_VARIABLE_DATA_TYPE_OPTIONS,
  },
};

export { RECORD_COUNT_FIELD, GET_RECORD_FIELD_IDS };
