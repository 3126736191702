import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _difference from 'lodash/difference';

import { tget } from '@tekion/tekion-base/utils/general';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import {
  convertApiResponseToViewConfig,
  extractAllCellViewNamesFromViewConfiguration,
  getPayloadForViews,
  bulkViewApiResponseToViewConfigResolver,
  convertViewConfigToApiViewConfig,
} from '../helpers/viewBuilder.helpers';

import viewBuilderServices from '../services/viewBuilder.services';

import { VIEW_CONFIGURATION_FIELD_IDS } from '../constants/viewBuilder.constants';

const fetchViewConfigurationByName = async (entityName, viewName) => {
  try {
    const responseData = await viewBuilderServices.fetchViewConfigurationByName(entityName, viewName);
    const viewConfiguration = getDataFromResponse(responseData);

    const convertedViewConfig = convertApiResponseToViewConfig(viewConfiguration);

    return convertedViewConfig;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch view, please try again later.')));
    return false;
  }
};

const searchViewConfigurations = async (payload = EMPTY_OBJECT) => {
  try {
    const receivedData = await viewBuilderServices.searchViewConfigurations(payload);
    const viewConfigsResponse = getDataFromResponse(receivedData);
    const viewConfigs = tget(viewConfigsResponse, 'hits', EMPTY_ARRAY);

    const convertedViewConfigs = bulkViewApiResponseToViewConfigResolver(viewConfigs);

    return { ...viewConfigsResponse, hits: convertedViewConfigs };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch views, please try again later.')));
    return false;
  }
};

const saveViewConfiguration = async (entityName, viewConfiguration) => {
  try {
    const apiViewConfig = convertViewConfigToApiViewConfig(viewConfiguration);

    const responseData = await viewBuilderServices.saveViewConfiguration(entityName, apiViewConfig);
    const savedViewConfiguration = getDataFromResponse(responseData);

    const convertedViewConfig = convertApiResponseToViewConfig(savedViewConfiguration);

    return convertedViewConfig;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to save view, please try again later.')));
    return false;
  }
};

const updateViewConfiguration = async (entityName, viewName, viewConfiguration) => {
  try {
    const apiViewConfig = convertViewConfigToApiViewConfig(viewConfiguration);

    const responseData = await viewBuilderServices.updateViewConfiguration(entityName, viewName, apiViewConfig);
    const savedViewConfiguration = getDataFromResponse(responseData);

    const convertedViewConfiguration = convertApiResponseToViewConfig(savedViewConfiguration);

    return convertedViewConfiguration;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update view, please try again later.')));
    return false;
  }
};

const searchViewConfigurationsByRecordType = async (recordTypeName, payload = EMPTY_OBJECT) => {
  try {
    const params = { recordTypeName };
    const receivedData = await viewBuilderServices.searchViewConfigurationsByRecordType(payload, params);
    const viewConfigResponse = getDataFromResponse(receivedData);
    const viewConfigs = tget(viewConfigResponse, 'hits', EMPTY_ARRAY);

    const convertedViewConfigs = bulkViewApiResponseToViewConfigResolver(viewConfigs);

    return { ...viewConfigResponse, hits: convertedViewConfigs };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch views, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getCellViewConfigsByNameFromViewConfig = async ({ viewConfig = {}, existingViewNames = [] }) => {
  const cellViewNames = [];
  let newViewConfigsByName = {};
  extractAllCellViewNamesFromViewConfiguration(tget(viewConfig, VIEW_CONFIGURATION_FIELD_IDS.SECTION, EMPTY_OBJECT), cellViewNames);
  const viewNamesToFetch = _difference(cellViewNames, existingViewNames);

  if (!_isEmpty(cellViewNames)) {
    const payload = getPayloadForViews(viewNamesToFetch);
    const response = await searchViewConfigurations(payload);
    const viewConfigs = tget(response, 'hits', EMPTY_ARRAY);

    newViewConfigsByName = _keyBy(viewConfigs, VIEW_CONFIGURATION_FIELD_IDS.NAME);
  }

  return newViewConfigsByName;
};

export {
  fetchViewConfigurationByName,
  searchViewConfigurations,
  searchViewConfigurationsByRecordType,
  saveViewConfiguration,
  updateViewConfiguration,
  getCellViewConfigsByNameFromViewConfig,
};
