import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _valuesIn from 'lodash/valuesIn';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import ChildAggregateSummaryForm from '../components/childAggregateSummaryForm';
import ComplexFieldDetailsForm from '../components/complexFieldDetailsForm';
import DependencyConfigForm from '../components/dependencyConfigForm';
import GeneralDetailsForm from '../components/generalDetailsForm';
import PropertiesForm from '../components/propertiesForm';
import RelationshipDetailsForm from '../components/relationshipDetailsForm';
import SelectOptionsForm from '../components/selectOptionsForm';
import ViewDetailsForm from '../components/viewDetailsForm';
import LookupDetailsForm from '../components/lookupDetailsForm';
import TextPropertiesForm from '../components/textPropertiesForm';
import MediaPropertiesForm from '../components/mediaPropertiesForm/MediaPropertiesForm';

import { getRenderOptions } from './fieldsForm.helpers';

import FIELDS_FORM_FIELD_IDS from '../constants/fieldsForm.fieldIds';
import GENERAL_DETAILS_FORM_FIELD_IDS from '../components/generalDetailsForm/constants/generalDetailsForm.fieldIds';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import PROPERTIES_FORM_FIELD_IDS from '../components/propertiesForm/constants/propertiesForm.fieldIds';
import DEPENDENCY_CONFIG_FORM_FIELD_IDS from '../components/dependencyConfigForm/constants/dependencyConfigForm.fieldIds';
import COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS from '../components/complexFieldDetailsForm/constants/complexFieldDetailsForm.fieldIds';
import TEXT_PROPERTIES_FORM_FIELD_IDS from '../components/textPropertiesForm/constants/textPropertiesForm.fieldIds';
import VIEW_DETAILS_FORM_FIELD_IDS from '../components/viewDetailsForm/constants/viewDetailsForm.fieldIds';
import LOOKUP_DETAILS_FORM_FIELD_IDS from '../components/lookupDetailsForm/constants/lookupDetailsForm.fieldIds';
import RELATIONSHIP_DETAILS_FORM_FIELD_IDS from '../components/relationshipDetailsForm/constants/relationshipDetailsForm.fieldIds';
import SELECT_OPTIONS_FORM_FIELD_IDS from '../components/selectOptionsForm/constants/selectOptionsForm.fieldsIds';
import CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS from '../components/childAggregateSummaryForm/constants/childAggregateSummaryForm.fieldIds';

import fieldDefinitionReader from '../../../../../../readers/fieldDefinition.reader';
import entityReader from '../../../../../../readers/entity.reader';

const GENERAL_DETAILS_FORM_FIELD = {
  id: FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM,
  renderer: GeneralDetailsForm,
};

const DEPENDENCY_CONFIG_FORM_FIELD = {
  id: FIELDS_FORM_FIELD_IDS.DEPENDENCY_CONFIG_FORM,
  renderer: DependencyConfigForm,
};

const VIEW_DETAILS_FORM_FIELD = {
  id: FIELDS_FORM_FIELD_IDS.VIEW_DETAILS_FORM,
  renderer: ViewDetailsForm,
};

const SELECT_OPTIONS_FORM_FIELD = {
  id: FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM,
  renderer: SelectOptionsForm,
};

const RELATIONSHIP_DETAILS_FORM_FIELD = {
  id: FIELDS_FORM_FIELD_IDS.RELATIONSHIP_DETAILS_FORM,
  renderer: RelationshipDetailsForm,
};

const CHILD_AGGREGATE_SUMMARY_FORM_FIELD = {
  id: FIELDS_FORM_FIELD_IDS.CHILD_AGGREGATE_SUMMARY_FORM,
  renderer: ChildAggregateSummaryForm,
};

const COMPLEX_FIELD_DETAILS_FORM_FIELD = {
  id: FIELDS_FORM_FIELD_IDS.COMPLEX_FIELD_DETAILS_FORM,
  renderer: ComplexFieldDetailsForm,
};

const PROPERTIES_FORM_FIELD = {
  id: FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM,
  renderer: PropertiesForm,
};

const TEXT_PROPERTIES_FORM_FIELD = {
  id: FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM,
  renderer: TextPropertiesForm,
};

const LOOKUP_DETAILS_FORM_FIELD = {
  id: FIELDS_FORM_FIELD_IDS.LOOKUP_DETAILS_FORM,
  renderer: LookupDetailsForm,
};

const MEDIA_PROPERTIES_FORM_FIELD = {
  id: FIELDS_FORM_FIELD_IDS.MEDIA_PROPERTIES_FORM,
  renderer: MediaPropertiesForm,
};

const FORM_FIELDS = {
  [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM]: GENERAL_DETAILS_FORM_FIELD,
  [FIELDS_FORM_FIELD_IDS.DEPENDENCY_CONFIG_FORM]: DEPENDENCY_CONFIG_FORM_FIELD,
  [FIELDS_FORM_FIELD_IDS.VIEW_DETAILS_FORM]: VIEW_DETAILS_FORM_FIELD,
  [FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM]: SELECT_OPTIONS_FORM_FIELD,
  [FIELDS_FORM_FIELD_IDS.RELATIONSHIP_DETAILS_FORM]: RELATIONSHIP_DETAILS_FORM_FIELD,
  [FIELDS_FORM_FIELD_IDS.CHILD_AGGREGATE_SUMMARY_FORM]: CHILD_AGGREGATE_SUMMARY_FORM_FIELD,
  [FIELDS_FORM_FIELD_IDS.COMPLEX_FIELD_DETAILS_FORM]: COMPLEX_FIELD_DETAILS_FORM_FIELD,
  [FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM]: PROPERTIES_FORM_FIELD,
  [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM]: TEXT_PROPERTIES_FORM_FIELD,
  [FIELDS_FORM_FIELD_IDS.LOOKUP_DETAILS_FORM]: LOOKUP_DETAILS_FORM_FIELD,
  [FIELDS_FORM_FIELD_IDS.MEDIA_PROPERTIES_FORM]: MEDIA_PROPERTIES_FORM_FIELD,
};

const getFields = (formMode, entityName, formValues, initialState, cachedEntityDefs) => {
  const entity = _get(cachedEntityDefs, entityName);

  const entityWithoutCurrentFieldDef = {
    ...entity,
    fieldDefinitions: _filter(
      entityReader.fieldDefinitions(entity),
      (fieldDefinition) =>
        !_isEmpty(fieldDefinitionReader.name(fieldDefinition)) &&
        fieldDefinitionReader.name(fieldDefinition) !==
          _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.NAME]),
    ),
  };

  return {
    ...FORM_FIELDS,
    [FIELDS_FORM_FIELD_IDS.LOOKUP_DETAILS_FORM]: addToRenderOptions(LOOKUP_DETAILS_FORM_FIELD, [
      {
        path: 'renderOptions',
        value: getRenderOptions(formMode, formValues, initialState, _valuesIn(LOOKUP_DETAILS_FORM_FIELD_IDS)),
      },
    ]),
    [FIELDS_FORM_FIELD_IDS.VIEW_DETAILS_FORM]: addToRenderOptions(VIEW_DETAILS_FORM_FIELD, [
      {
        path: 'renderOptions',
        value: getRenderOptions(formMode, formValues, initialState, _valuesIn(VIEW_DETAILS_FORM_FIELD_IDS)),
      },
    ]),
    [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM]: addToRenderOptions(TEXT_PROPERTIES_FORM_FIELD, [
      {
        path: 'renderOptions',
        value: getRenderOptions(formMode, formValues, initialState, _valuesIn(TEXT_PROPERTIES_FORM_FIELD_IDS)),
      },
    ]),
    [FIELDS_FORM_FIELD_IDS.COMPLEX_FIELD_DETAILS_FORM]: addToRenderOptions(COMPLEX_FIELD_DETAILS_FORM_FIELD, [
      {
        path: 'renderOptions',
        value: getRenderOptions(formMode, formValues, initialState, _valuesIn(COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS)),
      },
    ]),
    [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM]: addToRenderOptions(GENERAL_DETAILS_FORM_FIELD, [
      {
        path: 'disabledAutoFillForName',
        value: formMode === FORM_MODES.EDIT,
      },

      {
        path: 'renderOptions',
        value: getRenderOptions(formMode, formValues, initialState, _valuesIn(GENERAL_DETAILS_FORM_FIELD_IDS)),
      },
    ]),
    [FIELDS_FORM_FIELD_IDS.DEPENDENCY_CONFIG_FORM]: addToRenderOptions(DEPENDENCY_CONFIG_FORM_FIELD, [
      {
        path: 'entity',
        value: entity,
      },

      {
        path: 'renderOptions',
        value: getRenderOptions(formMode, formValues, initialState, _valuesIn(DEPENDENCY_CONFIG_FORM_FIELD_IDS)),
      },
    ]),
    [FIELDS_FORM_FIELD_IDS.RELATIONSHIP_DETAILS_FORM]: addToRenderOptions(RELATIONSHIP_DETAILS_FORM_FIELD, [
      {
        path: 'entity',
        value: entity,
      },
      {
        path: 'renderOptions',
        value: getRenderOptions(formMode, formValues, initialState, _valuesIn(RELATIONSHIP_DETAILS_FORM_FIELD_IDS)),
      },
      {
        path: 'cachedEntityDefs',
        value: cachedEntityDefs,
      },
    ]),
    [FIELDS_FORM_FIELD_IDS.CHILD_AGGREGATE_SUMMARY_FORM]: addToRenderOptions(CHILD_AGGREGATE_SUMMARY_FORM_FIELD, [
      {
        path: 'entity',
        value: entity,
      },

      {
        path: 'renderOptions',
        value: getRenderOptions(formMode, formValues, initialState, _valuesIn(CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS)),
      },
      {
        path: 'cachedEntityDefs',
        value: cachedEntityDefs,
      },
    ]),
    [FIELDS_FORM_FIELD_IDS.SELECT_OPTIONS_FORM]: addToRenderOptions(SELECT_OPTIONS_FORM_FIELD, [
      {
        path: 'entity',
        value: entityWithoutCurrentFieldDef,
      },

      {
        path: 'renderOptions',
        value: getRenderOptions(formMode, formValues, initialState, _valuesIn(SELECT_OPTIONS_FORM_FIELD_IDS)),
      },
      ...(formMode === FORM_MODES.EDIT
        ? [
            {
              path: 'fieldName',
              value: _get(formValues, [FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, GENERAL_DETAILS_FORM_FIELD_IDS.NAME]),
            },
          ]
        : EMPTY_ARRAY),
    ]),
    [FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM]: addToRenderOptions(FORM_FIELDS[FIELDS_FORM_FIELD_IDS.PROPERTIES_FORM], [
      {
        path: 'renderOptions',
        value: getRenderOptions(formMode, formValues, initialState, _valuesIn(PROPERTIES_FORM_FIELD_IDS)),
      },
    ]),
  };
};

export default getFields;
