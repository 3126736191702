exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".shareRecordForm_shareRecordForm__stgj3EHts2{display:flex;align-items:center}[dir] .shareRecordForm_shareRecordForm__stgj3EHts2 .shareRecordForm_addButton__pHMDwXV2y7{margin-top:.4rem}.shareRecordForm_shareRecordFormSectionContainer__8hFfNButPX{max-width:100%}[dir] .shareRecordForm_shareRecordFormSectionContainer__8hFfNButPX{padding:0}[dir=ltr] .shareRecordForm_shareRecordFormSectionContainer__8hFfNButPX{padding-right:2rem}[dir=rtl] .shareRecordForm_shareRecordFormSectionContainer__8hFfNButPX{padding-left:2rem}.shareRecordForm_accessTypeField__54fn9dRVLB{max-width:14rem}", ""]);

// Exports
exports.locals = {
	"shareRecordForm": "shareRecordForm_shareRecordForm__stgj3EHts2",
	"addButton": "shareRecordForm_addButton__pHMDwXV2y7",
	"shareRecordFormSectionContainer": "shareRecordForm_shareRecordFormSectionContainer__8hFfNButPX",
	"accessTypeField": "shareRecordForm_accessTypeField__54fn9dRVLB"
};