import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';

// Lodash
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _get from 'lodash/get';

// Constants
import { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Button from '@tekion/tekion-components';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import Heading from '@tekion/tekion-components/atoms/Heading/Heading';
import Loader from '@tekion/tekion-components/molecules/loader';

import ActivityLog from '../activityLog';
import AUDIT_LOGS_DRAWER_FORMATTERS from '../../helpers/auditLogsDrawer.formatters';
import ACTION_HANDLERS from './helpers/auditLogs.actionHandler';
import ACTION_TYPES from './constants/auditLogs.actionTypes';
import { INITIAL_STATE } from './constants/auditLogs.general';
import { BODY_DIV_ID } from '../../constants/auditLogsDrawer.general';
import ActivityReader from '../../readers/Activity';

import styles from './auditLogs.module.scss';

class AuditLogs extends PureComponent {
  componentDidMount() {
    this.getAuditLogs();
  }

  componentDidUpdate(prevProps) {
    const { assetId } = this.props;
    if (_get(prevProps, 'assetId') !== assetId) {
      const { onAction } = this.props;
      onAction({
        type: ACTION_TYPES.RESET_ACTIVITES,
      });
    }
  }

  getAuditLogs() {
    const { onAction } = this.props;
    onAction({
      type: ACTION_TYPES.FETCH_AUDIT_LOG,
    });
  }

  getAdditionalAuditLogs = () => {
    const { isFetchingAuditLogs, pageToken, nextPageTokenMap } = this.props;
    if (!isFetchingAuditLogs && (pageToken || !_isEmpty(nextPageTokenMap))) {
      this.getAuditLogs();
    }
  };

  // eslint-disable-next-line class-methods-use-this, react/no-unstable-nested-components
  renderActivity = (formattersMap) => (activity) => {
    const auditId = ActivityReader.auditId(activity);
    return <ActivityLog key={auditId} activity={activity} formattersMap={formattersMap} />;
  };

  renderActivities = () => {
    const { activities, auditParams } = this.props;

    const { formattersMap } = auditParams;
    const updatedFormattersMap = {
      ...AUDIT_LOGS_DRAWER_FORMATTERS,
      ...formattersMap,
    };
    return _map(activities, this.renderActivity(updatedFormattersMap));
  };

  // eslint-disable-next-line class-methods-use-this
  renderLoader = () => <Loader className="full-height" />;

  // eslint-disable-next-line class-methods-use-this
  renderNoLogPlaceholder = () => <div className="m-t-32 flex flex-column flex-center full-height">{__('No Logs Available!')}</div>;

  // eslint-disable-next-line class-methods-use-this
  scrollToTop = () => {
    const divToBeScrolledTop = document.getElementById(BODY_DIV_ID);
    if (divToBeScrolledTop) divToBeScrolledTop.scrollTo({ top: 0, behavior: 'smooth' });
  };

  renderScrollTopButton = () => (
    <Button className="m-r-32" view={Button.VIEW.TERTIARY} onClick={this.scrollToTop}>
      {__('Scroll To Top')}
    </Button>
  );

  render() {
    const { activities, isFetchingAuditLogs, closeAuditLogs, assetId, pageToken, nextPageTokenMap, contentHeight, heading, shouldHideHeading } =
      this.props;
    const hasActivities = !_isEmpty(activities);
    const shouldRenderNoLogPlaceholder = !isFetchingAuditLogs && (!hasActivities || !assetId);

    return (
      <div className={`full-height ${styles.drawerContainer}`}>
        <PropertyControlledComponent controllerProperty={!shouldHideHeading}>
          <div className={classNames('d-flex justify-content-between align-items-center full-width', styles.header)}>
            <Heading size={1}>{heading}</Heading>
            <div className="d-flex justify-content-center align-items-center">
              <PropertyControlledComponent controllerProperty={hasActivities}>{this.renderScrollTopButton()}</PropertyControlledComponent>
              <Button view={Button.VIEW.ICON} icon="icon-close" onClick={closeAuditLogs} />
            </div>
          </div>
        </PropertyControlledComponent>

        <div id={BODY_DIV_ID} className={styles.modalScroll} style={{ height: contentHeight }}>
          <InfiniteScroll
            hasMore={pageToken || !_isEmpty(nextPageTokenMap)}
            useWindow={false}
            pageStart={0}
            loadMore={this.getAdditionalAuditLogs}
            loader={<Loader className="text-center" />}
          >
            <PropertyControlledComponent controllerProperty={hasActivities}>{this.renderActivities()}</PropertyControlledComponent>
            <PropertyControlledComponent controllerProperty={!hasActivities && isFetchingAuditLogs}>
              {this.renderLoader()}
            </PropertyControlledComponent>
            <PropertyControlledComponent controllerProperty={shouldRenderNoLogPlaceholder}>
              {this.renderNoLogPlaceholder()}
            </PropertyControlledComponent>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

AuditLogs.propTypes = {
  isFetchingAuditLogs: PropTypes.bool,
  shouldHideHeading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  assetId: PropTypes.string,
  pageToken: PropTypes.string,
  heading: PropTypes.string,
  nextPageTokenMap: PropTypes.object,
  auditParams: PropTypes.object,
  activities: PropTypes.array,
  closeAuditLogs: PropTypes.func,
  onAction: PropTypes.func,
};

AuditLogs.defaultProps = {
  shouldHideHeading: false,
  isFetchingAuditLogs: true,
  assetId: undefined,
  pageToken: undefined,
  heading: EMPTY_STRING,
  auditParams: EMPTY_OBJECT,
  nextPageTokenMap: EMPTY_OBJECT,
  activities: EMPTY_ARRAY,
  closeAuditLogs: _noop,
  onAction: _noop,
};

export default withActions(INITIAL_STATE, ACTION_HANDLERS)(AuditLogs);
