import _property from 'lodash/property';

const customStylesReader = {
  title: _property('title'),
  type: _property('type'),
  customStyleType: _property('customStyleType'),
  value: _property('value'),
  metadata: _property('metadata'),
  jsonScript: _property('metadata.jsonScript'),
  editorId: _property('editorId'),
};

export default customStylesReader;
