const MENTIONS_ACTION_TYPES = {
  ON_CHANGE_MENTIONS_CONTENT: 'ON_CHANGE_MENTIONS_CONTENT',
};

const NO_SUGGESTION_FOUND = 'NO_SUGGESTION_FOUND';

const MENTION_TYPES = {
  USER: 'USER',
};

const DEFAULT_MENTIONS_STYLE = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
    width: '100%',
  },

  '&multiLine': {
    control: {
      minHeight: 15,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: 'none',
    },
  },

  '&singleLine': {
    display: 'inline-block',

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },
};

export { MENTIONS_ACTION_TYPES, NO_SUGGESTION_FOUND, MENTION_TYPES, DEFAULT_MENTIONS_STYLE };
