import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cx from 'classnames';

import _map from 'lodash/map';
import _get from 'lodash/get';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Tabs from '@tekion/tekion-components/molecules/Tabs';
import Button from '@tekion/tekion-components/atoms/Button';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Icon from '@tekion/tekion-components/atoms/FontIcon/Icon';

import TabHeader from './components/tabHeader';
import { TAB_IDS, TAB_COMPONENT, TAB_TITLE, TAB_ICON } from './constants/tabs.config';
import ACTION_TYPES from './constants/homePage.actionTypes';
import ACTION_HANDLERS from './helpers/homePage.actionHandlers';

import styles from './homePage.module.scss';

const HomePage = ({ match, history, onAction }) => {
  const { tabId } = _get(match, 'params', EMPTY_OBJECT);

  const handleTabClick = useCallback(
    (tab) =>
      onAction({
        type: ACTION_TYPES.ON_TAB_CHANGE,
        payload: { tab },
      }),
    [onAction],
  );

  const handleStudioClick = useCallback(
    () =>
      onAction({
        type: ACTION_TYPES.ON_STUDIO_CLICK,
      }),
    [onAction],
  );

  const handleMyExportsClick = useCallback(
    () =>
      onAction({
        type: ACTION_TYPES.ON_MY_EXPORTS_CLICK,
      }),
    [onAction],
  );

  const handleApprovalCentreClick = useCallback(
    () =>
      onAction({
        type: ACTION_TYPES.ON_APPROVAL_CENTRE_CLICK,
      }),
    [onAction],
  );

  const appModulesTabRightSection = useMemo(
    () => (
      <div className={styles.appModulesTabRightSection}>
        <Button view={Button.VIEW.SECONDARY} className={cx(styles.tabPanelButton, styles.tabPanelDownloadButton)} onClick={handleApprovalCentreClick}>
          <Icon size={Icon.SIZES.M}>icon-checked-outlined</Icon>
          <b>{__('Approval centre')}</b>
        </Button>
        <Button view={Button.VIEW.SECONDARY} className={cx(styles.tabPanelButton, styles.tabPanelDownloadButton)} onClick={handleMyExportsClick}>
          <Icon size={Icon.SIZES.M}>icon-download1</Icon>
          <b>{__('My Downloads')}</b>
        </Button>
        <Button view={Button.VIEW.SECONDARY} className={cx(styles.tabPanelButton, styles.tabPanelStudioButton)} onClick={handleStudioClick}>
          <Icon size={Icon.SIZES.M}>icon-caret-up</Icon>
          <b>{__('Studio')}</b>
        </Button>
      </div>
    ),
    [handleStudioClick, handleMyExportsClick, handleApprovalCentreClick],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT });
  }, [onAction, tabId]);

  return (
    <Page>
      <Page.Body>
        <div className={styles.appModules}>
          <Tabs
            activeKey={tabId}
            type={Tabs.TAB_TYPE.LINE_VARIANT_GENERAL}
            tabBarExtraContent={appModulesTabRightSection}
            onTabClick={handleTabClick}
          >
            {_map(TAB_IDS, (tabName) => {
              const tabTitle = TAB_TITLE[tabName];
              const TabComponent = TAB_COMPONENT[tabName];
              const iconToRender = TAB_ICON[tabName];

              return (
                <Tabs.TabPane
                  tab={
                    <TabHeader className={styles.tabPanelContent} iconClassName={styles.tabPanelIcon} iconToRender={iconToRender} title={tabTitle} />
                  }
                  key={tabName}
                  className={styles.tabPanelContent}
                >
                  <TabComponent history={history} />
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </div>
      </Page.Body>
    </Page>
  );
};

HomePage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS))(HomePage);
