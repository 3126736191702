import React, { useMemo } from 'react';
import compose from 'recompose/compose';
import renameProps from 'recompose/renameProps';
import PropTypes from 'prop-types';

import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import CHILD_AGGREGATE_SUMMARY_FORM_ACTION_HANDLERS from './helpers/childAggregateSummaryForm.actionHandlers';
import getSections from './helpers/childAggregateSummaryForm.sections';
import getFields from './helpers/childAggregateSummaryForm.fields';

import FIELDS_FORM_FIELD_IDS from '../../constants/fieldsForm.fieldIds';

const ChildAggregateSummaryForm = ({ entity, error, childEntityDefinitions, values, onAction }) => {
  const fields = useMemo(() => getFields(entity, childEntityDefinitions, values), [entity, childEntityDefinitions, values]);
  const sections = useMemo(() => getSections(values), [values]);

  return (
    <FormWithSubmission
      className="full-width"
      contextId={FIELDS_FORM_FIELD_IDS.CHILD_AGGREGATE_SUMMARY_FORM}
      fields={fields}
      values={values}
      errors={error}
      sections={sections}
      onAction={onAction}
    />
  );
};

ChildAggregateSummaryForm.propTypes = {
  entity: PropTypes.object,
  error: PropTypes.object,
  childEntityDefinitions: PropTypes.object,
  values: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ChildAggregateSummaryForm.defaultProps = {
  entity: EMPTY_OBJECT,
  error: undefined,
  values: EMPTY_OBJECT,
  childEntityDefinitions: EMPTY_OBJECT,
};

export default compose(
  renameProps({ onAction: 'fieldsFormOnAction', value: 'fieldsFormChildAggregateSummaryValues' }),
  withActions(EMPTY_OBJECT, CHILD_AGGREGATE_SUMMARY_FORM_ACTION_HANDLERS),
)(ChildAggregateSummaryForm);
