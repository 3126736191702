import React, { useMemo, useRef, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';

import HighCharts from 'highcharts';
import HighChartsReact from 'highcharts-react-official';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import styles from './histogramChart.module.scss';

const HistogramChart = ({ options }) => {
  const ChartsRef = useRef(null);
  const layoutRef = createRef();
  const chartConfig = useMemo(() => options, [options]);

  useEffect(() => {
    if (ChartsRef.current) {
      ChartsRef.current.chart.update(chartConfig, true, true);
    }
  }, [chartConfig]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries) {
        ChartsRef.current.chart.reflow();
      }
    });

    if (layoutRef.current) {
      observer.observe(layoutRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [layoutRef]);

  return (
    <div ref={layoutRef} className={styles.highChartContainer}>
      <HighChartsReact
        ref={ChartsRef}
        allowChartUpdate={false}
        highcharts={HighCharts}
        options={chartConfig}
        containerProps={{ className: styles.chartContent }}
      />
    </div>
  );
};

HistogramChart.propTypes = {
  options: PropTypes.object,
};

HistogramChart.defaultProps = { options: EMPTY_OBJECT };

export default HistogramChart;
