const COLUMN_IDS = {
  ENTITY_NAME: 'scope',
  READ: 'READ',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  TOTAL_FIELDS: 'totalFields',
};

const FIELD_IDS = {
  ENTITY_ACCESS: 'entityAccess',
  ACTION_TYPES: 'actionTypes',
  FIELD_ACCESS_MAP: 'fieldAccessMap',
};

const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showHeader: false,
  showSearch: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

export { COLUMN_IDS, TABLE_MANAGER_PROPS, FIELD_IDS };
