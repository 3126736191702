import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { getPayload } from './permissionSetList.helpers';
import { deletePermissionByName, getPermissionList } from '../../../../../../actions/permissionSetManagement.action';

import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/permissionSetList.actionTypes';
import { TABLE_CONSTANTS } from '../constants/permissionSetList.general.constants';
import { COLUMN_IDS } from './permissionSetList.columns';
import { TABS } from '../../permissionSetConfigure/constants/permissionSetConfigure.constants';
import { FIELD_IDS as PERMISSION_SET_FIELD_IDS } from '../../../../../../constants/permissionSet.constants';

const handleFetchPermissions = async ({ setState, getState, params }) => {
  setState({ loading: true });

  const { pageSize, searchText } = getState();
  const currentPageSize = _get(params, 'pageSize', pageSize);
  const currentSearchText = _get(params, 'searchText', searchText);
  const currentPageToken = _get(params, 'nextPageToken');

  const payload = getPayload({
    searchText: currentSearchText,
    searchField: COLUMN_IDS.PERMISSION_NAME,
    pageSize: currentPageSize,
    nextPageToken: currentPageToken,
  });

  const permissionDataResponse = await getPermissionList(payload);
  const permissions = tget(permissionDataResponse, 'hits', EMPTY_ARRAY);
  setState({
    totalNumberOfEntries: 1,
    permissions,
    loading: false,
    nextPageToken: _get(permissionDataResponse, 'nextPageToken'),
  });
};

const handleSearchApply = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  setState(
    {
      searchText: _get(params, 'value'),
    },
    () => {
      handleFetchPermissions({ getState, setState, params: { searchText: _get(params, 'value') } });
    },
  );
};

const handlePageSizeUpdate = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');

  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }
  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }
  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleFetchPermissions({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const handleRefreshListView = async ({ getState, setState }) => {
  setState(
    {
      currentPage: TABLE_CONSTANTS.CURRENT_PAGE,
    },
    () => {
      handleFetchPermissions({ getState, setState });
    },
  );
};

const handleCreateNewPermission = ({ getState }) => {
  const { history } = getState();
  const path = `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_CREATE}`;
  history.push({ pathname: path });
};

const handleEditPermissionRequest = ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();

  const permissionName = tget(params, PERMISSION_SET_FIELD_IDS.NAME);

  const path = `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_EDIT}/${permissionName}`;
  history.push({ pathname: path, state: { permissionInfo: params } });
};

const rowClickHandler = ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const permissionData = _get(params, 'value.original', EMPTY_OBJECT);
  const permissionName = _get(permissionData, PERMISSION_SET_FIELD_IDS.NAME, EMPTY_STRING);
  const path = `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_CONFIGURE}/${permissionName}/${TABS.ENTITY_SETTINGS}`;
  history.push({ pathname: path, state: { permissionData } });
};

const handleDeleteRowActionClick = ({ setState, params }) => {
  const permissionSetDisplayName = _get(params, PERMISSION_SET_FIELD_IDS.DISPLAY_NAME);

  setState({
    isConfirmationModalVisible: true,
    confirmationModalTitle: __('Delete {{permissionSetDisplayName}} permission set', { permissionSetDisplayName }),
    permissionSetDataToPerformAction: params,
  });
};

const handleDeletePermissionConfirmationCancelClick = ({ setState }) => {
  setState({ isConfirmationModalVisible: false, permissionSetDataToPerformAction: {}, confirmationModalTitle: '' });
};

const handleDeletePermissionConfirmationDoneClick = async ({ getState, setState }) => {
  const { permissionSetDataToPerformAction } = getState();

  const permissionName = tget(permissionSetDataToPerformAction, PERMISSION_SET_FIELD_IDS.NAME);

  setState({ isPermissionDeleting: true });

  await deletePermissionByName(permissionName);

  handleRefreshListView({ getState, setState });
  setState({ isPermissionDeleting: false, isConfirmationModalVisible: false });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchApply,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleRefreshListView,
  [ACTION_TYPES.CREATE_PERMISSION]: handleCreateNewPermission,
  [ACTION_TYPES.ON_FETCH_PERMISSION_DATA]: handleFetchPermissions,
  [ACTION_TYPES.EDIT]: handleEditPermissionRequest,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: rowClickHandler,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageSizeUpdate,
  [ACTION_TYPES.DELETE]: handleDeleteRowActionClick,
  [ACTION_TYPES.DELETE_PERMISSION_CONFIRMATION_CANCEL_CLICK]: handleDeletePermissionConfirmationCancelClick,
  [ACTION_TYPES.DELETE_PERMISSION_CONFIRMATION_DONE_CLICK]: handleDeletePermissionConfirmationDoneClick,
};

export default ACTION_HANDLERS;
