import PropTypes from 'prop-types';
import React from 'react';
import _head from 'lodash/head';
import _get from 'lodash/get';
import { compose } from 'recompose';
// Cell Renderers
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { ACTION_TYPES, FIELD_IDS } from '../../../constants/createView.constants';
// Constants
import { COLUMN_IDS } from '../configTable.constants';

const getIsCheckboxDisabledFromFieldDefinition = (field, columnId) => {
  switch (columnId) {
    case COLUMN_IDS.FILTERABLE:
    case COLUMN_IDS.SORTABLE:
    case COLUMN_IDS.SEARCHABLE:
    case COLUMN_IDS.EDITABLE:
      return !_get(field, columnId, false);
    case COLUMN_IDS.MANDATORY:
    case COLUMN_IDS.DISABLED:
      return _get(field, columnId, true);
    case COLUMN_IDS.DEFAULT_FILTER:
      return !_get(field, COLUMN_IDS.FILTERABLE, false);
    default:
      return true;
  }
};

const CheckboxCellRenderer = (props) => {
  const { column, data, nestingPath, onAction, original } = props;
  const columnId = _get(column, 'id', '');
  const rowIndex = _head(nestingPath);

  let isCheckboxDisabled = getIsCheckboxDisabledFromFieldDefinition(tget(original, 'field', EMPTY_OBJECT), columnId);
  if (columnId === COLUMN_IDS.DEFAULT_FILTER) {
    isCheckboxDisabled = !_get(original, COLUMN_IDS.FILTERABLE, false);
  }

  const handleAction = ({ payload }) => {
    onAction({
      type: ACTION_TYPES.ON_TABLE_CHANGE,
      payload: {
        id: FIELD_IDS.COLUMN_TABLE,
        columnId,
        value: _get(payload, 'value'),
        rowIndex,
      },
    });
  };
  return <CheckboxRenderer id={`${columnId}_${rowIndex}`} value={data} onAction={handleAction} disabled={isCheckboxDisabled} />;
};

CheckboxCellRenderer.propTypes = {
  column: PropTypes.object.isRequired,
  data: PropTypes.string.isRequired,
  nestingPath: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
  original: PropTypes.object.isRequired,
};

export default compose(makeCellRenderer, React.memo)(CheckboxCellRenderer);
