import { defaultMemoize } from 'reselect';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _concat from 'lodash/concat';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { fetchProcesses } from '../../../../../../actions/processBuilder.actions';
import { fetchRules } from '../../../../../../actions/ruleBuilder.actions';

// Constants
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../constants/actionBuilder.constants';
import { USER_INPUT_TYPES, ACTION_BUILDER_ACTION_TYPES } from '../constants/actionBuilderForm.general';
import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../../constants/viewBuilder.constants';

// Readers
import fieldDefinitionReader from '../../../../../../readers/fieldDefinition.reader';
import workflowReader from '../../../../../../readers/workflow.readers';

const isCreatableFieldDef = (actionType, field, isCreatableIgnored = false) =>
  (actionType === ACTION_BUILDER_ACTION_TYPES.CREATE_RECORD && fieldDefinitionReader.creatable(field)) || isCreatableIgnored;

const isEditableFieldDef = (actionType, field, isEditableIgnored = false) =>
  ((actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD || actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORDS) &&
    fieldDefinitionReader.editable(field)) ||
  isEditableIgnored;

const convertFieldsToOptions = defaultMemoize((fields, actionType, isCreatableIgnored = false, isEditableIgnored = false) =>
  _reduce(
    fields,
    (result, field) => {
      if (isCreatableFieldDef(actionType, field, isCreatableIgnored)) {
        result.push({
          label: fieldDefinitionReader.displayName(field),
          value: fieldDefinitionReader.name(field),
        });
      } else if (isEditableFieldDef(actionType, field, isEditableIgnored)) {
        result.push({
          label: fieldDefinitionReader.displayName(field),
          value: fieldDefinitionReader.name(field),
        });
      }

      return result;
    },
    [],
  ),
);

const convertViewsToOptions = defaultMemoize((fields) =>
  _map(fields, (field) => ({
    label: tget(field, VIEW_CONFIGURATION_FIELD_IDS.DISPLAY_NAME, _get(field, VIEW_CONFIGURATION_FIELD_IDS.NAME)),
    value: _get(field, VIEW_CONFIGURATION_FIELD_IDS.NAME),
  })),
);

const getViewTypeOptions = (values, fieldDefinitions, actionType) => {
  const viewTypeDetail = tget(values, ACTION_DEFINITION_FIELD_IDS.USER_INPUT_TYPE);
  if (viewTypeDetail === USER_INPUT_TYPES.FIELD) {
    return convertFieldsToOptions(fieldDefinitions, actionType);
  } else if (viewTypeDetail === USER_INPUT_TYPES.FORM) {
    // TODO: Change to feeding View definitions here.
    return convertViewsToOptions(fieldDefinitions);
  }
  return EMPTY_ARRAY;
};

const getValueOption = (workflowData) => ({ label: workflowReader.displayName(workflowData), value: workflowReader.id(workflowData) });

const getWorkflowOptions = (data) => _map(data, getValueOption);

const handleFetchWorkflows = async () => {
  const workflowResponse = await fetchProcesses();
  const ruleResponse = await fetchRules();
  return { hits: _concat(workflowResponse, ruleResponse) };
};

export { convertFieldsToOptions, getViewTypeOptions, convertViewsToOptions, getWorkflowOptions, handleFetchWorkflows };
