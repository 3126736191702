import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

const makeInputCellRendererForFieldRenderer = (WrappedComponent) => {
  const WrappedComponentWithInputCellWrapper = (props) => {
    const { onAction, onChange } = props;
    const handleAction = useCallback(
      (action) => {
        const { type, payload } = action;

        switch (type) {
          case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
            const { value } = payload;
            onChange({ value });
            break;
          }

          default: {
            onAction(action);
            break;
          }
        }
      },
      [onAction, onChange],
    );

    return <WrappedComponent {...props} onAction={handleAction} />;
  };

  WrappedComponentWithInputCellWrapper.propTypes = {
    onChange: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired,
  };

  WrappedComponentWithInputCellWrapper.defaultProps = {};

  return WrappedComponentWithInputCellWrapper;
};

export default makeInputCellRendererForFieldRenderer;
