import ACTION_TYPES from './exportListPage.actionTypes';

const DOWNLOAD_DATA = {
  id: ACTION_TYPES.DOWNLOAD_DATA,
  name: __('Download Data'),
};

const CANCEL_EXPORT = {
  id: ACTION_TYPES.CANCEL_EXPORT,
  name: __('Cancel Export'),
};

const RETRIGGER_EXPORT = {
  id: ACTION_TYPES.RETRIGGER_EXPORT,
  name: __('Retrigger Export'),
};

const TASK_SUCCESS_ACTIONS = [DOWNLOAD_DATA, RETRIGGER_EXPORT];
const TASK_PENDING_ACTIONS = [CANCEL_EXPORT];
const TASK_FAILED_ACTIONS = [RETRIGGER_EXPORT];

export { TASK_FAILED_ACTIONS, TASK_PENDING_ACTIONS, TASK_SUCCESS_ACTIONS };
