import produce from 'immer';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _snakeCase from 'lodash/snakeCase';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchPageConfigurationByName } from '../../../../../../actions/visualBuilder.actions';
import { getPageMetadata, getFormValuesFromPageConfiguration } from './visualBuilderMetadataPage.general.helpers';

import { ACTION_TYPES, FIELD_IDS } from '../constants/visualBuilderMetadataPage.constants';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import { modifyPageConfigFromApi } from '../../../../../../helpers/visualBuilder.helpers';

const handleInit = async ({ setState, getState }) => {
  const { history = EMPTY_OBJECT, match = EMPTY_OBJECT } = getState();
  const { pageName } = tget(match, 'params', {});

  if (_isEmpty(pageName)) {
    setState({
      formMode: FORM_MODES.CREATE,
    });
  } else {
    setState({ formMode: FORM_MODES.EDIT, isLoading: true });
    let pageConfiguration = tget(history, 'location.state.pageConfig');

    if (!pageConfiguration) {
      pageConfiguration = await fetchPageConfigurationByName(pageName);
    }

    const [newPageConfiguration, widgetConfiguration] = await modifyPageConfigFromApi(pageConfiguration);

    const formValues = getFormValuesFromPageConfiguration(newPageConfiguration);

    setState({ formValues, isLoading: false, pageConfiguration: newPageConfiguration, widgetConfiguration });
  }
};

const handleFieldOnChange = ({ setState, getState, params }) => {
  const { formMode, disableAutoFillForName = false } = getState();
  const { id, value } = params;

  setState(
    produce((draft) => {
      _set(draft, `formValues.${id}`, value);
      if (formMode === FORM_MODES.CREATE && id === FIELD_IDS.PAGE_TITLE && !disableAutoFillForName) {
        _set(draft, `formValues.${FIELD_IDS.PAGE_NAME}`, _snakeCase(value));
      } else if (id === FIELD_IDS.PAGE_NAME) {
        _set(draft, 'disableAutoFillForName', true);
      }
    }),
  );
};

const handleValidation = ({ setState, params }) => {
  const { errors } = params;

  setState({ formErrors: errors });
};

const handleOnCancel = ({ getState }) => {
  const { history } = getState();

  history.goBack();
};

const handleSubmit = async ({ getState }) => {
  const { formValues, onPageMetadataSubmit, pageConfiguration = EMPTY_OBJECT, widgetConfiguration } = getState();
  const pageMetadata = getPageMetadata(formValues, pageConfiguration);

  onPageMetadataSubmit(pageMetadata, widgetConfiguration);
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidation,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldOnChange,
  [ACTION_TYPES.ON_CANCEL]: handleOnCancel,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_INIT]: handleInit,
};

export default ACTION_HANDLERS;
