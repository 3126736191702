import _get from 'lodash/get';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _trim from 'lodash/trim';
import _toLower from 'lodash/toLower';
import _isEmpty from 'lodash/isEmpty';
import _head from 'lodash/head';
import _find from 'lodash/find';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchApplicationsForProject, getWorkspaceProjectList } from '../../../../../../../actions/workspaceProjectManagement.actions';

import { setValueInLocalStorage } from '../../../../../../../utils/localStorage';
import LS_CACHE_KEYS from '../../../../../../../constants/localStorage.cacheKeys';
import { ACTION_TYPES } from '../constants/applicationTab.constants';

import localStorageReader from '../../../../../../../readers/localStorage.reader';

const handleProjectChange = async ({ getState, setState, params }) => {
  const { applicationsByProjectNames = EMPTY_OBJECT } = getState();
  const selectedProject = _get(params, 'selectedProject');
  const currentProjectContext = localStorageReader.projectContext() || {};
  const currentUserInfo = localStorageReader.userInfo();
  const currentWorkspace = _get(currentUserInfo, 'workspaceId');

  if (!_isEmpty(selectedProject)) {
    currentProjectContext[currentWorkspace] = _get(selectedProject, 'value');
  }

  setValueInLocalStorage(LS_CACHE_KEYS.PROJECT_CONTEXT, JSON.stringify(currentProjectContext));
  const selectedProjectValue = _get(selectedProject, 'value');
  setState({ selectedProject, loading: true });

  const applicationList = _get(applicationsByProjectNames, selectedProjectValue, EMPTY_ARRAY);

  if (_isEmpty(applicationList) && !_isEmpty(selectedProjectValue)) {
    const newApplicationsList = await fetchApplicationsForProject(selectedProjectValue);
    setState({
      applicationsByProjectNames: { ...applicationsByProjectNames, [selectedProjectValue]: newApplicationsList },
      applicationList: newApplicationsList,
      loading: false,
    });
  } else {
    setState({ applicationList, loading: false });
  }
};

const initApplicationsList = async ({ getState, setState }) => {
  setState({ loading: true });

  const projectsResponse = await getWorkspaceProjectList();
  const projects = tget(projectsResponse, 'hits', EMPTY_ARRAY);

  const projectOptions = _map(projects, (project) => ({
    label: _get(project, 'displayName', EMPTY_STRING) || _get(project, 'name', EMPTY_STRING),
    value: _get(project, 'name', EMPTY_STRING),
  }));
  const currentProjectContext = localStorageReader.projectContext();
  const currentUserInfo = localStorageReader.userInfo();
  const currentWorkspace = _get(currentUserInfo, 'workspaceId');
  let defaultProject = _head(projectOptions);

  if (!_isEmpty(_get(currentProjectContext, currentWorkspace))) {
    defaultProject = _find(projectOptions, (project) => _get(project, 'value') === _get(currentProjectContext, currentWorkspace));
  }
  await handleProjectChange({ getState, setState, params: { selectedProject: defaultProject } });
  setState({ loading: false });
};

const handleSearch = ({ getState, setState, params }) => {
  const { applicationsByProjectNames, selectedProject } = getState();
  const { searchTerm } = params;
  const selectedProjectValue = _get(selectedProject, 'value');
  const searchText = _trim(_toLower(searchTerm));
  const applicationList = _get(applicationsByProjectNames, selectedProjectValue, []);
  const newApplicationList = _filter(applicationList, (application) => {
    const displayName = _toLower(_get(application, 'displayName', EMPTY_STRING));
    return _includes(displayName, searchText);
  });

  setState({ searchText, applicationList: newApplicationList });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: initApplicationsList,
  [ACTION_TYPES.ON_SEARCH_CHANGE]: handleSearch,
  [ACTION_TYPES.ON_PROJECT_CHANGE]: handleProjectChange,
};

export default ACTION_HANDLERS;
