exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".delayTaskModal_minWidth100__vhz3SnJ8o9{min-width:100%}.delayTaskModal_sectionContainer__32GX4JV3Ae{max-width:100%}.delayTaskModal_draggableContainer__9X6DiEFqSZ{width:20rem;display:flex;flex-direction:row;align-items:center;justify-content:space-between}[dir] .delayTaskModal_addButtonStyle__bypXaqBzMN{margin:.4rem 7rem}.delayTaskModal_headerNavigationContainer__wSGQmkVork{display:flex;flex-flow:row;font-family:\"Proxima-Nova-Regular\"}.delayTaskModal_button__unbhfxjhnH{display:flex}[dir] .delayTaskModal_button__unbhfxjhnH:focus{background-color:#f4f5f6}.delayTaskModal_textContent__9i9YMVM4QF{display:flex;width:65%}[dir=ltr] .delayTaskModal_homeIcon__5KNCUYxaEC{margin-right:.8rem}[dir=rtl] .delayTaskModal_homeIcon__5KNCUYxaEC{margin-left:.8rem}.delayTaskModal_tabContent__7KrChu8hdE{overflow-y:scroll}[dir] .delayTaskModal_tabContent__7KrChu8hdE{border:1px dashed gray}[dir=ltr] .delayTaskModal_tabContent__7KrChu8hdE{padding:.8rem .8rem 1rem 0}[dir=rtl] .delayTaskModal_tabContent__7KrChu8hdE{padding:.8rem 0 1rem .8rem}", ""]);

// Exports
exports.locals = {
	"minWidth100": "delayTaskModal_minWidth100__vhz3SnJ8o9",
	"sectionContainer": "delayTaskModal_sectionContainer__32GX4JV3Ae",
	"draggableContainer": "delayTaskModal_draggableContainer__9X6DiEFqSZ",
	"addButtonStyle": "delayTaskModal_addButtonStyle__bypXaqBzMN",
	"headerNavigationContainer": "delayTaskModal_headerNavigationContainer__wSGQmkVork",
	"button": "delayTaskModal_button__unbhfxjhnH",
	"textContent": "delayTaskModal_textContent__9i9YMVM4QF",
	"homeIcon": "delayTaskModal_homeIcon__5KNCUYxaEC",
	"tabContent": "delayTaskModal_tabContent__7KrChu8hdE"
};