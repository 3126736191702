const ACTION_TYPES = {
  INIT: 'INIT',
  FETCH_ENTITY: 'FETCH_ENTITY',
  FETCH_ALL_ENTITIES: 'FETCH_ALL_ENTITIES',
  FETCH_ENTITY_RECORDS: 'FETCH_ENTITY_RECORDS',
  SAVE_VIEW_SUMMARY: 'SAVE_VIEW_SUMMARY',
  SAVE_VIEW_CONFIGURATION: 'SAVE_VIEW_CONFIGURATION',
  CANCEL_VIEW_CONFIGURATION: 'CANCEL_VIEW_CONFIGURATION',
  SAVE_VIEW_CONFIGURATION_MODAL_CLOSE: 'SAVE_VIEW_CONFIGURATION_MODAL_CLOSE',
  PREVIEW_VIEW_CONFIG: 'PREVIEW_VIEW_CONFIG',
};

export { ACTION_TYPES };
