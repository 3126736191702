const FIELD_IDS = {
  RULE_NAME: 'name',
  RULE_STATUS: 'active',
  ERROR_MESSAGE: 'errorMessage',
  ENABLE_RICH_TEXT_EDITOR: 'enableRichTextEditor',
  CONDITION: 'condition',
  APPLICABLE_RECORD_TYPES: 'applicableRecordTypes',
  CUSTOM_CODE: 'customCode',
};

export { FIELD_IDS };
