import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';

import Tabs from '@tekion/tekion-components/molecules/Tabs';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import TabHeader from './TabHeader';
import { getWidgetReference } from '../../helpers/visualBuilder.helper';

import { COMPONENT_CONFIG_KEYS, COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP, WIDGET_TYPES } from '../../constants/visualBuilder.general.constants';
import { TAB_POSITIONS, TAB_SIZES } from './tabWidgetRenderer.constants';
import { CUSTOM_STYLE_IDS } from '../../../../constants/customStyles.constants';
import { TAB_TEMPLATE_TYPE, TAB_TEMPLATE_TYPE_TO_CLASSNAME } from '../../constants/visualBuilder.properties.constants';
import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';

import styles from './tabWidgetRenderer.module.scss';

const SUPPORTED_CUSTOM_STYLE_TAB = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[WIDGET_TYPES.TAB_WIDGET];

const TabWidgetRenderer = ({ isPreviewMode, componentConfig, ...restProps }) => {
  const [selectedTabId, setSelectedTabId] = useState();

  const children = _get(componentConfig, COMPONENT_CONFIG_KEYS.CHILDREN);
  const firstChildren = getArraySafeValue(children);
  const initialSelectedTabId = _get(firstChildren, COMPONENT_CONFIG_KEYS.WIDGET_NAME);
  const customStyles = tget(componentConfig, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${COMPONENT_CONFIG_KEYS.CUSTOM_STYLES}`, EMPTY_OBJECT);

  const tabTemplateType = getArraySafeValue(tget(componentConfig, 'properties.tabTemplateType', TAB_TEMPLATE_TYPE.DEFAULT));
  const tabPosition = _toLower(tget(componentConfig, 'properties.position', TAB_POSITIONS.TOP));
  const tabSize = tget(componentConfig, 'properties.size', TAB_SIZES.MEDIUM);

  const handleTabClick = useCallback((tabId) => {
    setSelectedTabId(tabId);
  }, []);

  const tabClassName = useMemo(() => tget(styles, TAB_TEMPLATE_TYPE_TO_CLASSNAME[tabTemplateType], EMPTY_STRING), [tabTemplateType]);

  const resolvedCustomStyles = useMemo(
    () => getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_TAB, customStyles),
    [customStyles],
  );

  const renderWidget = () => (
    <Tabs
      animated={false}
      tabPosition={tabPosition}
      activeKey={selectedTabId || initialSelectedTabId}
      className={cx(styles.tabCommonStyles, tabClassName, { [styles.builderMode]: !isPreviewMode, [styles.previewMode]: isPreviewMode })}
      style={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTAINER, EMPTY_OBJECT)}
      type={tabTemplateType}
      onTabClick={handleTabClick}
    >
      {_map(children, (child, index) => {
        // TODO: make const for properties of widget
        const id = _get(child, COMPONENT_CONFIG_KEYS.WIDGET_NAME);
        const tabDisplayName = _get(componentConfig, `properties.metadataList.${index}.name`, `tab-${index}`);
        const tabIcon = _get(componentConfig, `properties.metadataList.${index}.icon`, '');
        let widgetType = _get(child, 'widgetType');

        if (widgetType === WIDGET_TYPES.CONTAINER) {
          widgetType = WIDGET_TYPES.TAB_PANE;
        }

        const ComponentRenderer = getWidgetReference({ componentType: widgetType, isPreviewMode: true });
        const isTabSelected = selectedTabId ? id === selectedTabId : id === initialSelectedTabId;

        return (
          <Tabs.TabPane
            className={styles.tabPaneStyle}
            tab={
              <TabHeader
                title={tabDisplayName}
                iconToRender={tabIcon}
                isTabSelected={isTabSelected}
                tabSize={tabSize}
                tabTemplateType={tabTemplateType}
              />
            }
            key={id}
          >
            <ComponentRenderer isPreviewMode={isPreviewMode} key={id} componentConfig={child} {...restProps} />
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );

  return renderWidget();
};

TabWidgetRenderer.propTypes = {
  isPreviewMode: PropTypes.bool,
  componentConfig: PropTypes.object,
};

TabWidgetRenderer.defaultProps = {
  isPreviewMode: false,
  componentConfig: EMPTY_OBJECT,
};

export default TabWidgetRenderer;
