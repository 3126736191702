import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';

import { ShareAndManageRecordUserDetailSetters } from '../../../../../../../../../hooks/useShareAndManageRecordModal/shareAndManageRecordUserData';

import RECORD_FIELDS from '../../../../../../../../../../../constants/recordFields';
import { SHARE_RECORD_FORM_FIELD_IDS } from '../../../constants/shareRecordForm.fieldIds';
import { DURATION_TYPE_ID, DATE_PICKER_ID, DURATION_TYPES } from '../../../organisms/durationFieldRenderer';

import entityReader from '../../../../../../../../../../../readers/entity.reader';

const getPayloadForUsersEntityPermission = (entityDef, entityRecord, formValues) => {
  const entityName = entityReader.name(entityDef);
  const recordType = tget(entityRecord, RECORD_FIELDS.RECORD_TYPE_ID);
  const userIds = tget(formValues, SHARE_RECORD_FORM_FIELD_IDS.USERS);
  return { entityName, recordType, payload: userIds };
};

const getUserDetailForSharingRecord = (allUsersById, entityPermissionsForUsers, formValues) => {
  const recordAccessType = tget(formValues, SHARE_RECORD_FORM_FIELD_IDS.ACCESS_TYPE);
  const duration = tget(formValues, SHARE_RECORD_FORM_FIELD_IDS.SET_DURATION);

  const durationType = tget(duration, DURATION_TYPE_ID);

  let expiryInLong = null;
  if (durationType === DURATION_TYPES.DATE_AND_TIME) {
    expiryInLong = tget(duration, DATE_PICKER_ID);
  }

  const userDetailsWithEntityPermissions = _map(entityPermissionsForUsers, (userEntityPermissionDetail, userId) => {
    const userDetails = tget(allUsersById, userId, {});

    const shareAndManageRecordUserData = ShareAndManageRecordUserDetailSetters.createShareAndManageAccessRecordUserData({
      userObject: userDetails,
      manageAccessObject: { recordAccessType, expiryInLong },
      entityAccessObject: userEntityPermissionDetail,
    });

    return shareAndManageRecordUserData;
  });

  return userDetailsWithEntityPermissions;
};

export { getPayloadForUsersEntityPermission, getUserDetailForSharingRecord };
