import { DEFAULTS_TH } from '@udecode/plate-table';

const { getNodeProps } = DEFAULTS_TH;

const makeGetThCellNodeProps =
  (readOnly) =>
  ({ element }) => ({
    ...getNodeProps?.({ element }),
    enableTableResizing: !readOnly,
  });

const createThCellNodeOptions = (readOnly) => ({
  ...DEFAULTS_TH,
  getNodeProps: makeGetThCellNodeProps(readOnly),
});

export default createThCellNodeOptions;
