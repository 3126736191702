import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

// Tekion-components
import Button from '@tekion/tekion-components/atoms/Button';
import Heading from '@tekion/tekion-components/atoms/Heading';

// Constants
import ACTION_TYPES from './approvalSettingsList.actionTypes';

const SUB_HEADER_PROPS = {
  subHeaderLeftActions: [
    {
      renderer: Heading,
      renderOptions: {
        children: __('Approval Settings'),
      },
      action: TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH,
    },
  ],
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create New'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_APPROVAL_SETTING,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showHeader: false,
  showSearch: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

export { SUB_HEADER_PROPS, TABLE_MANAGER_PROPS };
