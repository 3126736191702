const AVATAR_PROPS = { size: 'small' };

const WIDTH_SMALL = { width: 100 };

const WIDTH_MEDIUM = { width: 150 };

const WIDTH_LARGE = { width: 200 };

const PARAGRAPH_PROPS = { rows: 2, width: '100%' };

export { AVATAR_PROPS, WIDTH_LARGE, WIDTH_MEDIUM, WIDTH_SMALL, PARAGRAPH_PROPS };
