// Tekion-components
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import COLUMN_IDS from '../../constants/approvalSettings.fieldIds';

const COLUMN_CONFIG = [
  {
    Header: __('Group'),
    id: COLUMN_IDS.GROUP,
    accessor: COLUMN_IDS.GROUP,
    Cell: TextRenderer,
  },
  {
    Header: __('Category'),
    id: COLUMN_IDS.CATEGORY,
    accessor: COLUMN_IDS.CATEGORY,
    Cell: TextRenderer,
  },
];

export default COLUMN_CONFIG;
