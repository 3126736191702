import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _map from 'lodash/map';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';
import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import {
  BUTTON_RADIO_OPTIONS,
  BUTTON_RADIO_VALUES,
  BUTTON_CONFIGURATOR_IDS,
  BUTTON_VIEWS,
  BUTTON_TEXT_TYPE_OPTIONS,
} from './buttonConfiguratorRenderer.constants';
import { ICONS } from '../../constants/viewBuilder.constants';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from '../../organisms/configurator/configurator.module.scss';

const ButtonConfiguratorRenderer = ({ selectedViewComponentProperties, onAction }) => {
  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { id, value } = payload;
          const newViewComponentProperties = { ...selectedViewComponentProperties, [id]: value };

          onAction({
            type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
            payload: { id: ALL_VIEW_PROPERTY_KEYS.BUTTON, value: newViewComponentProperties },
          });

          break;
        }
        default:
          onAction(action);
      }
    },
    [onAction, selectedViewComponentProperties],
  );

  const renderChildren = useCallback(
    (buttonRadioTypeValue) => {
      if (buttonRadioTypeValue === BUTTON_RADIO_VALUES.TEXT) {
        return (
          <>
            <Select
              id={BUTTON_CONFIGURATOR_IDS.BUTTON_TEXT_TYPE}
              label={__('Button Text Type')}
              value={tget(selectedViewComponentProperties, BUTTON_CONFIGURATOR_IDS.BUTTON_TEXT_TYPE)}
              defaultValue={BUTTON_VIEWS.PRIMARY}
              fieldClassName={styles.buttonType}
              options={BUTTON_TEXT_TYPE_OPTIONS}
              onAction={handleAction}
            />
            <TextInput
              id={BUTTON_CONFIGURATOR_IDS.BUTTON_TEXT}
              label={__('Text')}
              value={tget(selectedViewComponentProperties, BUTTON_CONFIGURATOR_IDS.BUTTON_TEXT)}
              onAction={handleAction}
            />
          </>
        );
      } else if (buttonRadioTypeValue === BUTTON_RADIO_VALUES.ICON) {
        return (
          <div className={styles.grid}>
            {_map(ICONS, (icon) => {
              const iconStyle = tget(selectedViewComponentProperties, BUTTON_CONFIGURATOR_IDS.BUTTON_ICON) === icon ? styles.disabled : styles.active;

              return (
                <div
                  key={icon}
                  className={cx(styles.icon, iconStyle)}
                  onClick={() =>
                    handleAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id: BUTTON_CONFIGURATOR_IDS.BUTTON_ICON, value: icon } })
                  }
                >
                  <FontIcon size={SIZES.L}>{icon}</FontIcon>
                </div>
              );
            })}
          </div>
        );
      }

      return null;
    },
    [selectedViewComponentProperties, handleAction],
  );

  return (
    <div className={styles.buttonConfiguratorRenderer}>
      <Radio
        id={ALL_VIEW_PROPERTY_KEYS.BUTTON}
        radios={BUTTON_RADIO_OPTIONS}
        value={tget(selectedViewComponentProperties, ALL_VIEW_PROPERTY_KEYS.BUTTON)}
        onAction={handleAction}
      />
      <div className={styles.textOrIconInput}>{renderChildren(tget(selectedViewComponentProperties, ALL_VIEW_PROPERTY_KEYS.BUTTON))}</div>
    </div>
  );
};

ButtonConfiguratorRenderer.propTypes = {
  selectedViewComponentProperties: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default ButtonConfiguratorRenderer;
