exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".valueField_customSelect__mc2ig3Vufk{width:100%}[dir] .valueField_customSelect__mc2ig3Vufk .ant-select-selection__choice{padding-top:2px}.valueField_customSelect__mc2ig3Vufk .ant-select-selection__choice__remove{top:-1px}[dir=ltr] .valueField_customSelect__mc2ig3Vufk .ant-select-selection__choice__remove{right:-1px}[dir=rtl] .valueField_customSelect__mc2ig3Vufk .ant-select-selection__choice__remove{left:-1px}.valueField_labelButtonContainer__chSW59eaVq{display:flex;justify-content:space-between}.valueField_labelButtonContainer__chSW59eaVq .valueField_labelContainer__wZ63PNujet{width:18%;display:flex;justify-content:space-between;align-items:center}[dir] .valueField_labelButtonContainer__chSW59eaVq .valueField_labelContainer__wZ63PNujet .valueField_valueInfoIcon__g4sWac9Akw{padding-bottom:.1rem}.valueField_valueFieldInfo__sEEw6UJZhs{max-width:45rem}.valueField_valueFieldInfo__sEEw6UJZhs .ant-popover-inner-content{font-family:\"Proxima-Nova-Semibold\"}[dir] .valueField_valueFieldInfo__sEEw6UJZhs .ant-popover-inner-content{padding:1rem !important}", ""]);

// Exports
exports.locals = {
	"customSelect": "valueField_customSelect__mc2ig3Vufk",
	"labelButtonContainer": "valueField_labelButtonContainer__chSW59eaVq",
	"labelContainer": "valueField_labelContainer__wZ63PNujet",
	"valueInfoIcon": "valueField_valueInfoIcon__g4sWac9Akw",
	"valueFieldInfo": "valueField_valueFieldInfo__sEEw6UJZhs"
};