import React, { useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _keyBy from 'lodash/keyBy';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';

import WithSize from '../../../../connectors/withSize';
import { getTableProps } from './helpers/tableWidget.helpers';

import { createFilterProps } from '../widgetContainer/helpers/widgetContainer.filterConfig';
import ACTION_HANDLERS from './helpers/tableWidget.actionHandlers';
import { TABLE_MANAGER_PROPS } from './constants/tableWidget.general.constants';
import ACTION_TYPES from './constants/tableWidget.actionTypes';
import { getDefaultTimeRange } from '../../../../pages/devPlatformExperience/pages/dashboard/helpers/dashboard.helpers';

const TableWidget = (props) => {
  const { data, columns, loading, selectedFilters, filtersToRender, entityFieldDefinitions, aggregateAfterKey, currentPage, timeRange, onAction } =
    props;

  const fieldDefinitionByName = useMemo(() => _keyBy(entityFieldDefinitions, 'name'), [entityFieldDefinitions]);
  const filterProps = useMemo(
    () =>
      createFilterProps({
        selectedFilters,
        filtersToRender,
        fieldDefinitionByName,
      }),
    [fieldDefinitionByName, filtersToRender, selectedFilters],
  );

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.INIT,
    });
  }, [onAction, timeRange]);

  return (
    <TableManager
      data={data}
      columns={columns}
      loading={loading}
      onAction={onAction}
      tableManagerProps={TABLE_MANAGER_PROPS}
      tableProps={getTableProps(props)}
      tokenPagination
      nextPageToken={aggregateAfterKey}
      currentPage={currentPage}
      selectedFilters={selectedFilters}
      filterProps={filterProps}
    />
  );
};

TableWidget.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  columns: PropTypes.array,
  aggregateAfterKey: PropTypes.string,
  currentPage: PropTypes.number,
  selectedFilters: PropTypes.array,
  filtersToRender: PropTypes.array,
  entityFieldDefinitions: PropTypes.array,
  timeRange: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

TableWidget.defaultProps = {
  loading: true,
  data: EMPTY_ARRAY,
  columns: EMPTY_ARRAY,
  aggregateAfterKey: EMPTY_STRING,
  currentPage: 0,
  selectedFilters: EMPTY_ARRAY,
  filtersToRender: EMPTY_ARRAY,
  entityFieldDefinitions: EMPTY_ARRAY,
  timeRange: getDefaultTimeRange(),
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS), WithSize({ hasPageFooter: 1, hasPageHeader: 0 }))(TableWidget);
