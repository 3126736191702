exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .fieldsForm_titleTextPadding__4vgbYUGjFo{padding-bottom:1.2rem}.fieldsForm_addFormStyle__qBAE2TKRuv{max-width:none !important}.fieldsForm_form__jenadYZcqS{height:calc(100% - 5rem);overflow-y:auto}.fieldsForm_formSectionHeader__wDjDFTEcgf{font-size:1.8rem}.fieldsForm_footerContainer__kb3STLe3QL{height:6.4rem}.fieldsForm_inputTable__aaBy7Xbyn9{min-height:0 !important}[dir] .fieldsForm_inputTable__aaBy7Xbyn9{margin-bottom:1.5rem}.fieldsForm_addRowButton__9nAPNTnUeZ{width:15%}.fieldsForm_configTableHeading__uxJt2k712N{font-size:1.6rem;font-weight:bold}[dir] .fieldsForm_configTableHeading__uxJt2k712N{margin-bottom:.8rem}.fieldsForm_controllingSettingsInfoIcon__kj8SrRTd6p{bottom:-0.2rem}[dir] .fieldsForm_addButton__qYhzuQe9RV{margin-bottom:2rem}.fieldsForm_halfColumn__undfjvJTGR{width:calc((100% - 2.4rem)/2)}.fieldsForm_sectionContainer__g2vkTFTiao{max-width:100%}[dir] .fieldsForm_sectionContainer__g2vkTFTiao{padding:0}", ""]);

// Exports
exports.locals = {
	"titleTextPadding": "fieldsForm_titleTextPadding__4vgbYUGjFo",
	"addFormStyle": "fieldsForm_addFormStyle__qBAE2TKRuv",
	"form": "fieldsForm_form__jenadYZcqS",
	"formSectionHeader": "fieldsForm_formSectionHeader__wDjDFTEcgf",
	"footerContainer": "fieldsForm_footerContainer__kb3STLe3QL",
	"inputTable": "fieldsForm_inputTable__aaBy7Xbyn9",
	"addRowButton": "fieldsForm_addRowButton__9nAPNTnUeZ",
	"configTableHeading": "fieldsForm_configTableHeading__uxJt2k712N",
	"controllingSettingsInfoIcon": "fieldsForm_controllingSettingsInfoIcon__kj8SrRTd6p",
	"addButton": "fieldsForm_addButton__qYhzuQe9RV",
	"halfColumn": "fieldsForm_halfColumn__undfjvJTGR",
	"sectionContainer": "fieldsForm_sectionContainer__g2vkTFTiao"
};