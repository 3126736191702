import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _unset from 'lodash/unset';
import _find from 'lodash/find';
import _has from 'lodash/has';
import _reduce from 'lodash/reduce';
import _forEach from 'lodash/forEach';
import _castArray from 'lodash/castArray';
import _size from 'lodash/size';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { getCompactedFiltersPayload } from './general.helpers';
import { getIncludeFieldsFromEntityDef } from './entityManagement.helpers';

import ENTITY_DEFINITION_FIELDS from '../constants/entityDefinitionFields';
import { COMPONENT_CONFIG_KEYS, PAGE_CONFIG_IDS, VIEW_OVERRIDE_CONFIG_IDS } from '../constants/visualBuilder';
import { searchWidgetConfiguration } from '../actions/visualBuilder.actions';
import { CUSTOM_ACTIONS_PROPERTIES } from '../organisms/visualBuilder/constants/visualBuilder.properties.constants';

const getPayloadForEntity = (entities) => {
  let payload = {};
  if (!_isEmpty(entities)) {
    payload = {
      filters: getCompactedFiltersPayload([
        {
          field: ENTITY_DEFINITION_FIELDS.NAME,
          values: entities,
          filterType: OPERATORS.IN,
        },
      ]),
    };
  }
  return payload;
};

const getPayloadForRecord = (recordValueToFilter, filterFieldName, entityDef, fetchOneRecord) => {
  const includeFields = getIncludeFieldsFromEntityDef(entityDef);

  let payload = { includeFields };
  if (fetchOneRecord) {
    payload = {
      ...payload,
      rows: 1,
    };
  } else {
    payload = {
      ...payload,
      filters: [
        {
          field: filterFieldName,
          filterType: OPERATORS.IN,
          values: _castArray(recordValueToFilter),
        },
      ],
    };
  }

  return payload;
};

const simplifyViewOverrideConfig = (properties) => {
  if (_has(properties, VIEW_OVERRIDE_CONFIG_IDS.VIEW_OVERRIDE_CONFIG)) {
    const viewOverrideConfig = tget(properties, VIEW_OVERRIDE_CONFIG_IDS.VIEW_OVERRIDE_CONFIG, EMPTY_ARRAY);

    if (_has(viewOverrideConfig, VIEW_OVERRIDE_CONFIG_IDS.SHOW_ACTIONS)) {
      _set(properties, CUSTOM_ACTIONS_PROPERTIES.SHOW_ACTIONS, tget(viewOverrideConfig, VIEW_OVERRIDE_CONFIG_IDS.SHOW_ACTIONS));
    }

    if (_has(viewOverrideConfig, VIEW_OVERRIDE_CONFIG_IDS.ACTION_NAMES)) {
      _set(properties, CUSTOM_ACTIONS_PROPERTIES.ACTION_NAMES, tget(viewOverrideConfig, VIEW_OVERRIDE_CONFIG_IDS.ACTION_NAMES));
    }

    if (_has(viewOverrideConfig, VIEW_OVERRIDE_CONFIG_IDS.PRE_APPLIED_FILTERS)) {
      _set(properties, VIEW_OVERRIDE_CONFIG_IDS.PRE_APPLIED_FILTERS, tget(viewOverrideConfig, VIEW_OVERRIDE_CONFIG_IDS.PRE_APPLIED_FILTERS));
    }

    _unset(properties, VIEW_OVERRIDE_CONFIG_IDS.VIEW_OVERRIDE_CONFIG);
  }
};

const flattenCustomStyles = (customStyles) =>
  _reduce(
    customStyles,
    (result, customStyle) => {
      const type = tget(customStyle, 'type');
      const value = tget(customStyle, 'value');
      _set(result, type, value);
      return result;
    },
    {},
  );

const modifyNewPageConfig = (containers, customStyles, eventHandlers, layouts, widgetContainers, widgets) => {
  _forEach(widgetContainers, (widgetContainer) => {
    const widgetName = tget(widgetContainer, PAGE_CONFIG_IDS.WIDGET_NAME, EMPTY_STRING);
    const childWidgets = tget(widgetContainer, PAGE_CONFIG_IDS.WIDGET_CHILDREN, EMPTY_ARRAY);

    const customStylesForGivenWidget = _find(
      customStyles,
      (customStyle) => tget(customStyle, PAGE_CONFIG_IDS.WIDGET_NAME, EMPTY_ARRAY) === widgetName,
    );

    const eventHandlersForGivenWidget = _find(
      eventHandlers,
      (eventHandler) => tget(eventHandler, PAGE_CONFIG_IDS.WIDGET_NAME, EMPTY_ARRAY) === widgetName,
    );

    const layoutForGivenWidget = _find(layouts, (layout) => tget(layout, PAGE_CONFIG_IDS.WIDGET_NAME, EMPTY_STRING) === widgetName);

    _unset(layoutForGivenWidget, PAGE_CONFIG_IDS.WIDGET_NAME);

    const widgetPropertiesForGivenWidget = _find(widgets, (widget) => tget(widget, PAGE_CONFIG_IDS.PAGE_NAME, EMPTY_STRING) === widgetName);

    const widgetType = tget(widgetPropertiesForGivenWidget, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${COMPONENT_CONFIG_KEYS.WIDGET_TYPE}`, EMPTY_STRING);

    const widgetDisplayName = tget(widgetPropertiesForGivenWidget, PAGE_CONFIG_IDS.PAGE_DISPLAY_NAME, '');
    _unset(widgetPropertiesForGivenWidget, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${COMPONENT_CONFIG_KEYS.WIDGET_TYPE}`);

    const children = [];
    let properties = {};

    if (!_isEmpty(customStylesForGivenWidget)) {
      const customStyle = tget(customStylesForGivenWidget, PAGE_CONFIG_IDS.WIDGET_CUSTOM_STYLE, EMPTY_ARRAY);
      const flattenedCustomStyles = flattenCustomStyles(customStyle);
      properties = {
        ...properties,
        [PAGE_CONFIG_IDS.PAGE_CUSTOM_STYLES]: flattenedCustomStyles,
      };
    }

    if (!_isEmpty(eventHandlersForGivenWidget)) {
      properties = {
        ...properties,
        [PAGE_CONFIG_IDS.PAGE_EVENT_HANDLERS]: tget(eventHandlersForGivenWidget, PAGE_CONFIG_IDS.PAGE_EVENT_HANDLERS, EMPTY_ARRAY),
      };
    }

    if (!_isEmpty(layoutForGivenWidget)) {
      properties = {
        ...properties,
        layout: layoutForGivenWidget,
      };
    }

    if (!_isEmpty(widgetPropertiesForGivenWidget)) {
      properties = {
        ...properties,
        ...tget(widgetPropertiesForGivenWidget, COMPONENT_CONFIG_KEYS.PROPERTIES, EMPTY_OBJECT),
        widgetDisplayName,
      };
    }

    containers.push({
      [COMPONENT_CONFIG_KEYS.WIDGET_TYPE]: widgetType,
      [COMPONENT_CONFIG_KEYS.WIDGET_NAME]: widgetName,
      [COMPONENT_CONFIG_KEYS.CHILDREN]: children,
      properties,
    });

    simplifyViewOverrideConfig(properties);
    modifyNewPageConfig(children, customStyles, eventHandlers, layouts, childWidgets, widgets);
  });
};

const getAllWidgetsInPageConfiguration = (widgetNames, widgetContainers) => {
  _forEach(widgetContainers, (widgetContainer) => {
    const widgetName = tget(widgetContainer, PAGE_CONFIG_IDS.WIDGET_NAME, EMPTY_STRING);
    const childWidgets = tget(widgetContainer, PAGE_CONFIG_IDS.WIDGET_CHILDREN, EMPTY_ARRAY);
    widgetNames.push(widgetName);
    getAllWidgetsInPageConfiguration(widgetNames, childWidgets);
  });
};

const modifyPageConfigFromApi = async (pageConfiguration) => {
  const customStyles = tget(pageConfiguration, PAGE_CONFIG_IDS.PAGE_CUSTOM_STYLES, EMPTY_ARRAY);
  const eventHandlers = tget(pageConfiguration, PAGE_CONFIG_IDS.PAGE_EVENT_HANDLERS, EMPTY_ARRAY);
  const layouts = tget(pageConfiguration, PAGE_CONFIG_IDS.PAGE_LAYOUTS, EMPTY_ARRAY);
  const widgetContainers = tget(pageConfiguration, PAGE_CONFIG_IDS.PAGE_WIDGET_CONTAINERS, EMPTY_ARRAY);
  const oldConfigContainers = tget(pageConfiguration, PAGE_CONFIG_IDS.PAGE_CONTAINERS, []); // This line will be removed while merging

  const widgetNames = [];
  let widgets = [];
  getAllWidgetsInPageConfiguration(widgetNames, widgetContainers);
  // empty widget name filter will be resulted in error in API
  if (!_isEmpty(widgetNames)) {
    const payload = { filters: [{ filterType: OPERATORS.IN, field: 'name', values: widgetNames }], rows: _size(widgetNames) };

    widgets = await searchWidgetConfiguration(payload);
  }
  const newPageConfiguration = _omit(pageConfiguration, [
    PAGE_CONFIG_IDS.PAGE_CUSTOM_STYLES,
    PAGE_CONFIG_IDS.PAGE_EVENT_HANDLERS,
    PAGE_CONFIG_IDS.PAGE_LAYOUTS,
    PAGE_CONFIG_IDS.PAGE_WIDGET_CONTAINERS,
    PAGE_CONFIG_IDS.WIDGETS,
  ]);

  _set(newPageConfiguration, PAGE_CONFIG_IDS.PAGE_CONTAINERS, []);
  _set(newPageConfiguration, 'oldConfigContainers', oldConfigContainers); // This line will be removed while merging
  const containers = _get(newPageConfiguration, PAGE_CONFIG_IDS.PAGE_CONTAINERS);

  modifyNewPageConfig(containers, customStyles, eventHandlers, layouts, widgetContainers, widgets);

  return [newPageConfiguration, widgets];
};

export { getPayloadForEntity, getPayloadForRecord, modifyPageConfigFromApi };
