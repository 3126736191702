import _isFunction from 'lodash/isFunction';
import _map from 'lodash/map';
import _concat from 'lodash/concat';

import { tget } from '@tekion/tekion-base/utils/general';

// Constants
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { MINUTES, TASK_DEF_IDS } from '../constants/workflow.constants';
import CONDITION_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import { OPERATORS } from '../../../../../../organisms/conditionBuilder/atoms/operatorField/operatorField.constants';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../constants/actionBuilder.constants';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import { CALLOUT_FIELD_IDS, LOOP_NODE_FIELD_IDS, PUSH_NOTIFICATION_FIELD_IDS } from '../constants/nodeDataFieldIds';

const criteriaMapper = (data) => {
  const conditionList = tget(data, 'condition');
  return _map(conditionList?.[CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST], (criteria) => {
    const resource = tget(criteria, CONDITION_FIELD_IDS.FIELD);
    const operator = tget(criteria, CONDITION_FIELD_IDS.OPERATOR);
    const values = tget(criteria, CONDITION_FIELD_IDS.VALUES);
    const operatorLabel = tget(OPERATORS, `${operator}.label`, operator);

    return {
      key: `${resource} ${operatorLabel}`,
      value: values,
    };
  });
};

const actionContentMapper = (data) => {
  const content = [];

  content.push({
    key: __('Target entity'),
    value: data?.[ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME],
  });

  const actionDefinition = data?.[ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION];
  const mappedPrefilledValues = _map(actionDefinition, (value, field) => ({
    key: `Set "${field}" to `,
    value,
  }));

  return content.concat(mappedPrefilledValues);
};

const getDelayNodeContent = (data, uiMetadata) => {
  const content = [];

  content.push({
    key: __('Time Delay'),
    value: `${tget(uiMetadata, 'delayTime.time', 0)} ${tget(uiMetadata, 'delayTime.unit', MINUTES)}`,
  });

  return content;
};

const getRepeatNodeContent = (data) => {
  const content = [];

  content.push({
    key: __('Repeat Step By'),
    value: `${tget(data, 'totalCount')} Times`,
  });

  return content;
};

const getSwitchWorkflowNodeContent = (data, uiMetadata) => {
  const content = [];

  content.push({
    key: __('Workflow'),
    value: tget(uiMetadata, 'workflowName', EMPTY_STRING),
  });
  return content;
};

const getPusherNotificationNodeContent = (data) => {
  const content = [
    {
      key: __('Entity'),
      value: tget(data, PUSH_NOTIFICATION_FIELD_IDS.PUSHER_ENTITY_NAME, EMPTY_STRING),
    },
    {
      key: __('Record ID'),
      value: tget(data, PUSH_NOTIFICATION_FIELD_IDS.PUSHER_RECORD_ID, EMPTY_STRING),
    },
    {
      key: __('Receivers'),
      value: tget(data, PUSH_NOTIFICATION_FIELD_IDS.RECEIVER_IDS, EMPTY_STRING),
    },
    {
      key: __('Application'),
      value: tget(data, PUSH_NOTIFICATION_FIELD_IDS.APPLICATION_NAME, EMPTY_STRING),
    },
  ];

  return content;
};

const getCallOutNodeContent = (data) => {
  const content = [
    {
      key: __('Server Credential'),
      value: tget(data, CALLOUT_FIELD_IDS.SERVER_CREDENTIAL_NAME, EMPTY_STRING),
    },
    {
      key: __('Path'),
      value: tget(data, CALLOUT_FIELD_IDS.PATH, EMPTY_STRING),
    },
    {
      key: __('HTTP Method'),
      value: tget(data, CALLOUT_FIELD_IDS.HTTP_METHOD, EMPTY_STRING),
    },
    {
      key: __('Connect Timeout'),
      value: __(`${tget(data, CALLOUT_FIELD_IDS.CONNECT_TIME_OUT, EMPTY_STRING)} sec`),
    },
    {
      key: __('Read Timeout'),
      value: __(`${tget(data, CALLOUT_FIELD_IDS.READ_TIME_OUT, EMPTY_STRING)} sec`),
    },
  ];
  const requestHeaders = tget(data, CALLOUT_FIELD_IDS.HEADERS, {});
  const requestHeadersContent = _map(requestHeaders, (value, key) => ({
    key,
    value,
  }));

  const requestBody = tget(data, CALLOUT_FIELD_IDS.REQUEST_BODY, {});
  const requestBodyContent = _map(requestBody, (value, key) => ({
    key,
    value,
  }));

  const requestParams = tget(data, CALLOUT_FIELD_IDS.REQUEST_PARAMS, {});
  const requestParamsContent = _map(requestParams, (value, key) => ({
    key,
    value,
  }));

  return _concat(content, requestHeadersContent, requestBodyContent, requestParamsContent);
};
const getForLoopNodeContent = (data) => {
  const content = [
    {
      key: __('Loop Variable Name '),
      value: tget(data, LOOP_NODE_FIELD_IDS.LOOP_VARIABLE_NAME, EMPTY_STRING),
    },
    {
      key: __('List to loop over'),
      value: tget(data, LOOP_NODE_FIELD_IDS.LIST_TO_LOOP_OVER, EMPTY_STRING),
    },
  ];

  return content;
};

const getFetchIterateNodeContent = (data) => {
  const content = [
    {
      key: __('Loop Variable Name '),
      value: tget(data, LOOP_NODE_FIELD_IDS.LOOP_VARIABLE_NAME, EMPTY_STRING),
    },
    {
      key: __('Entity Name'),
      value: tget(data, LOOP_NODE_FIELD_IDS.ENTITY_NAME, EMPTY_STRING),
    },
  ];

  return content;
};

const contentMapperByTaskDefId = {
  [TASK_DEF_IDS.IF]: criteriaMapper,
  [TASK_DEF_IDS.IF_ELSE]: criteriaMapper,
  [TASK_DEF_IDS.SWITCH]: criteriaMapper,
  condition: criteriaMapper,
  [TASK_DEF_IDS.CREATE_RECORD]: actionContentMapper,
  [TASK_DEF_IDS.UPDATE_RECORD]: actionContentMapper,
  [TASK_DEF_IDS.GET_RECORDS]: actionContentMapper,
  [TASK_DEF_IDS.UPSERT_RECORD]: actionContentMapper,
  [TASK_DEF_IDS.DELAY_TASK]: getDelayNodeContent,
  [TASK_DEF_IDS.REPEAT_NODE]: getRepeatNodeContent,
  [TASK_DEF_IDS.SWITCH_WORKFLOW]: getSwitchWorkflowNodeContent,
  [TASK_DEF_IDS.SEND_EMAIL]: actionContentMapper,
  [TASK_DEF_IDS.PUSH_NOTIFICATION]: getPusherNotificationNodeContent,
  [TASK_DEF_IDS.CALLOUT]: getCallOutNodeContent,
  [TASK_DEF_IDS.FOR_LOOP]: getForLoopNodeContent,
  [TASK_DEF_IDS.FETCH_AND_ITERATE]: getFetchIterateNodeContent,
};

const getNodeContent = (taskDefId, data, uiMetadata) => {
  const nodeContentGetter = contentMapperByTaskDefId[taskDefId];
  if (_isFunction(nodeContentGetter)) {
    return nodeContentGetter(data, uiMetadata);
  }

  return [];
};

export default getNodeContent;
