const LOG_IN_DATA = {
  TENANT_ID: 'tenantId',
  HOME_WORKSPACE_ID: 'homeWorkspaceId',
  WORKSPACE_ID: 'workspaceId',
  USER_ID: 'userId',
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  USER_WORKSPACE_DETAILS: 'userWorkspaceDetails',
  WORKSPACE_NAME: 'workspaceName',
  WORKSPACE_DISPLAY_NAME: 'workspaceDisplayName',
  ROLE_NAME: 'roleName',
  PROFILE_NAME: 'profileName',
  USER_TYPE: 'userType',
};

export default LOG_IN_DATA;
