import React from 'react';

import _map from 'lodash/map';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import Ellipsis from '@tekion/tekion-components/atoms/Ellipsis';

import { FIELD_IDS, ELLIPSIS_LENGTH } from './deleteNodeModal.constants';

import styles from './deleteNodeModal.module.scss';

const FORM_SECTIONS = [
  {
    className: styles.section,
    rows: [
      {
        columns: [FIELD_IDS.SELECTED_OPTION],
      },
    ],
  },
];

const getFieldConfig = (radios) => {
  const radiosWithEllipsis = _map(radios, (radioOption) => ({
    ...radioOption,
    label: (
      <Ellipsis tooltip className={styles.labelStyle} length={ELLIPSIS_LENGTH}>
        {radioOption.label}
      </Ellipsis>
    ),
  }));
  return {
    [FIELD_IDS.SELECTED_OPTION]: {
      id: FIELD_IDS.SELECTED_OPTION,
      renderer: Radio,
      renderOptions: {
        size: 6,
        required: true,
        validators: [isRequiredRule],
        radios: radiosWithEllipsis,
      },
    },
  };
};

export { FORM_SECTIONS, getFieldConfig };
