import { defaultMemoize } from 'reselect';
import _includes from 'lodash/includes';
import _split from 'lodash/split';
import _head from 'lodash/head';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _slice from 'lodash/slice';
import _keys from 'lodash/keys';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';

// Components
import TextInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';
import DatePicker from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/datePicker';
import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';
import NullComponent from '@tekion/tekion-components/atoms/nullComponent';
import TextInputWithTags from '../../../../atoms/TextInputWithTagsField';

import { getSelectedFieldDef } from '../../helpers/conditionBuilder.general.helpers';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

// Wrappers
import DateFieldWrapper from '../../../../wrappers/DateFieldWrapper';
import SingletonValueFieldWrapper from './SingletonValueFieldWrapper';

// Constants
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';
import { BOOLEAN_OPTIONS } from './valueField.constants';
import { OPERATORS } from '../operatorField/operatorField.constants';
import CONDITION_BUILDER_MODES from '../../constants/conditionBuilder.modes';
import CONDITION_FIELD_IDS from '../../constants/condition.fieldIds';

const DatePickerField = DateFieldWrapper(DatePicker);

const TextInputFieldWrapped = SingletonValueFieldWrapper(TextInputField);
const NumberInputFieldWrapped = SingletonValueFieldWrapper(NumberInputField);
const DatePickerFieldWrapped = SingletonValueFieldWrapper(DatePickerField);
const SelectFieldWrapped = SingletonValueFieldWrapper(Select);

const getRenderer = defaultMemoize((fieldDef, operatorValue, conditionBuilderMode) => {
  const fieldType = fieldDefinitionReader.fieldType(fieldDef);
  const dataType = fieldDefinitionReader.dataType(fieldDef);
  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      switch (dataType) {
        case DATA_TYPES.TEXT:
          if (operatorValue === OPERATORS.IN.value || operatorValue === OPERATORS.NOT_IN.value) {
            return TextInputWithTags;
          } // Starts with, Ends with, Changed, Changed from, Changed to
          return conditionBuilderMode === CONDITION_BUILDER_MODES.ACTION_MODE ? TextInputField : TextInputFieldWrapped;
        case DATA_TYPES.NUMBER:
          return conditionBuilderMode === CONDITION_BUILDER_MODES.ACTION_MODE ? NumberInputField : NumberInputFieldWrapped;
        case DATA_TYPES.DATE:
          return conditionBuilderMode === CONDITION_BUILDER_MODES.ACTION_MODE ? DatePickerField : DatePickerFieldWrapped;
        case DATA_TYPES.DATE_TIME:
          return conditionBuilderMode === CONDITION_BUILDER_MODES.ACTION_MODE ? DatePickerField : DatePickerFieldWrapped;
        case DATA_TYPES.BOOLEAN:
          return conditionBuilderMode === CONDITION_BUILDER_MODES.ACTION_MODE ? Select : SelectFieldWrapped;
        default:
          return NullComponent;
      }
    case FIELD_TYPES.LIST:
      switch (dataType) {
        case DATA_TYPES.TEXT:
        case DATA_TYPES.NUMBER:
          return TextInputWithTags;
        default:
          return NullComponent;
      }
    case FIELD_TYPES.SELECT:
      return Select;
    case FIELD_TYPES.CHILD_AGGREGATE_SUMMARY:
      switch (dataType) {
        case DATA_TYPES.NUMBER:
          return NumberInputFieldWrapped;
        default:
          return NullComponent;
      }
    default:
      return NullComponent;
  }
});

const getFieldProps = defaultMemoize((fieldDef, mode, operator, conditionBuilderMode) => {
  const fieldProps = {};

  const fieldType = fieldDefinitionReader.fieldType(fieldDef);
  const dataType = fieldDefinitionReader.dataType(fieldDef);
  const multiValued = fieldDefinitionReader.multiValued(fieldDef);

  if (dataType === DATA_TYPES.BOOLEAN) {
    _set(fieldProps, 'options', BOOLEAN_OPTIONS);
  }

  if (dataType === DATA_TYPES.DATE_TIME) {
    _set(fieldProps, 'showTime', true);
    _set(fieldProps, 'format', DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_US_WITH_24_HOUR_TIME);
  }

  if (
    (fieldType === FIELD_TYPES.SELECT && multiValued) ||
    (conditionBuilderMode === CONDITION_BUILDER_MODES.CONDITION_MODE && fieldType === FIELD_TYPES.SELECT && !multiValued)
  ) {
    // Multiselect props
    _set(fieldProps, 'mode', 'multiple');
    _set(fieldProps, 'closeMenuOnSelect', false);
  }

  if (fieldType === FIELD_TYPES.SELECT) {
    _set(
      fieldProps,
      'options',
      _map(fieldDefinitionReader.options(fieldDef), (option) => {
        const { value } = option;
        return { label: value, value };
      }),
    );
  } else if (fieldType === FIELD_TYPES.TEXT && dataType === DATA_TYPES.NUMBER) {
    _set(fieldProps, 'triggerChangeOnBlur', false);
  } else if (fieldType === FIELD_TYPES.RANGE && dataType === DATA_TYPES.NUMBER) {
    _set(fieldProps, 'containerClassName', 'full-width');
  }

  return fieldProps;
});

const isAttribute = (value, mapOfVariableToEntityName) => {
  const variableNames = _keys(mapOfVariableToEntityName);
  const parsedValue = _split(getArraySafeValue(value), '.');
  const entityNameSpace = _head(parsedValue);
  return _includes(variableNames, entityNameSpace);
};

const getValueFieldDef = ({
  values,
  mapOfVariableToEntityName,
  conditionBuilderFieldDefinitionObject,
  isVariablePrefixNeeded,
  variableNameForPrefixNotNeeded,
}) => {
  const resourceValue = values?.[CONDITION_FIELD_IDS.FIELD];
  const splitValue = _split(resourceValue, '.');

  const splitResourceValue = isVariablePrefixNeeded ? _slice(splitValue, 1) : splitValue;
  const variableName = isVariablePrefixNeeded ? _head(splitValue) : variableNameForPrefixNotNeeded;
  const entityName = mapOfVariableToEntityName[variableName];
  const fieldDefs = _get(conditionBuilderFieldDefinitionObject, entityName, EMPTY_OBJECT);

  const selectedFieldDef = getSelectedFieldDef(splitResourceValue, 0, fieldDefs, conditionBuilderFieldDefinitionObject);
  return selectedFieldDef;
};

export { getRenderer, getFieldProps, isAttribute, getValueFieldDef };
