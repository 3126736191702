import _keys from 'lodash/keys';
import _size from 'lodash/size';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { AUTHENTICATOR_TYPES, ERROR_CODE_TO_MSG, LOGIN_PROVIDERS } from './LoginPage.constants';

const getErrorMsgByCode = (errCode) => {
  if (!errCode || !ERROR_CODE_TO_MSG[errCode]) {
    return __('Something went wrong.');
  }
  return ERROR_CODE_TO_MSG[errCode];
};

const checkIfNoTekionProvider = (providerTypeMap) => !_has(providerTypeMap, 'TEKION');

const checkIfNoProviderOtherThanTekion = (providerTypeMap) => {
  const keys = _keys(providerTypeMap);
  return _has(providerTypeMap, 'TEKION') && _size(keys) === 1;
};

const getProviderIPSSOURL = (providerType, provider) => {
  let providerIPSSOURL = tget(provider, 'ipSsoUrl');
  if (!_isEmpty(providerIPSSOURL) && providerType === LOGIN_PROVIDERS.OKTA_OPENID) {
    const redirectUrl = tget(provider, 'redirectUrl');
    const state = tget(provider, 'state');
    const codeChallenge = tget(provider, 'codeChallenge');
    const clientId = tget(provider, 'clientId');
    // eslint-disable-next-line max-len
    providerIPSSOURL = `${providerIPSSOURL}oauth2/v1/authorize?response_type=code&response_mode=form_post&client_id=${clientId}&scope=openid+email+profile&redirect_uri=${redirectUrl}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
  }
  return providerIPSSOURL;
};

const getAuthTypeLabel = (authType) => {
  if (authType === AUTHENTICATOR_TYPES.GOOGLE_AUTHENTICATOR) return __('Enter OTP from');
  return __('Sent OTP through');
};

export { checkIfNoProviderOtherThanTekion, checkIfNoTekionProvider, getErrorMsgByCode, getProviderIPSSOURL, getAuthTypeLabel };
