import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import addOptionsToField from '@tekion/tekion-base/utils/addOptionsToField';

import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';

import SelectInputFieldWrapper from '../../../../../../../../selectInputFieldWrapper/SelectInputFieldWrapper';
import { WorkspaceUserAsyncSelect } from '../../../../../../../../../organisms/asyncSelects';

import DurationFieldRenderer from '../organisms/durationFieldRenderer';

import { getPayloadForUsers, getUsersDetailsServiceHandler } from './shareRecordForm.helpers';

import { SHARE_RECORD_FORM_FIELD_IDS } from '../constants/shareRecordForm.fieldIds';

import styles from '../shareRecordForm.module.scss';

const USERS_FIELD = {
  id: SHARE_RECORD_FORM_FIELD_IDS.USERS,
  renderer: WorkspaceUserAsyncSelect,
  renderOptions: {
    isMulti: true,
    label: __('Users'),
    required: true,
    validators: [isRequiredRule],
  },
};

const ACCESS_TYPE_FIELD = {
  id: SHARE_RECORD_FORM_FIELD_IDS.ACCESS_TYPE,
  renderer: SelectInputFieldWrapper(SelectInput),
  renderOptions: {
    label: __('Access Type'),
    required: true,
    fieldClassName: styles.accessTypeField,
    size: 3,
    validators: [isRequiredRule],
  },
};

const SET_DURATION_FIELD = {
  id: SHARE_RECORD_FORM_FIELD_IDS.SET_DURATION,
  renderer: DurationFieldRenderer,
  renderOptions: {
    size: 5,
    label: __('expiryTime'),
    required: true,
    validators: [isRequiredRule],
  },
};

const SHARE_RECORD_FORM_FIELDS = {
  [SHARE_RECORD_FORM_FIELD_IDS.USERS]: USERS_FIELD,
  [SHARE_RECORD_FORM_FIELD_IDS.ACCESS_TYPE]: ACCESS_TYPE_FIELD,
  [SHARE_RECORD_FORM_FIELD_IDS.SET_DURATION]: SET_DURATION_FIELD,
};

const getShareRecordFormFields = (addNewUsersHandler, shareAndManageRecordUserDetails, wwdAccessTypesOptions) => ({
  ...SHARE_RECORD_FORM_FIELDS,
  [SHARE_RECORD_FORM_FIELD_IDS.ACCESS_TYPE]: addOptionsToField(ACCESS_TYPE_FIELD, wwdAccessTypesOptions),
  [SHARE_RECORD_FORM_FIELD_IDS.USERS]: addToRenderOptions(USERS_FIELD, [
    {
      path: 'serviceHandler',
      value: getUsersDetailsServiceHandler(addNewUsersHandler),
    },
    { path: 'getPayload', value: getPayloadForUsers(shareAndManageRecordUserDetails) },
  ]),
});

export default getShareRecordFormFields;
