import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _uniq from 'lodash/uniq';
import _pick from 'lodash/pick';
import _first from 'lodash/first';
import _split from 'lodash/split';
import _drop from 'lodash/drop';
import _forEach from 'lodash/forEach';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { checkValueIsVariable, getVariableName } from '../../../utils/workflowVariables.utils';
import { getFieldDefForLastSelectedField } from '../../../utils/validators/lastFieldMultiValuedValidatorRule';

import { TASK_DEF_IDS } from '../../../constants/workflow.constants';
import { CONDITION_BUILDER_FIELD_IDS, CONDITION_BUILDER_TYPES, CONDITION_FIELD_IDS } from '../../../../../../../../organisms/conditionBuilder';
import { LOOP_NODE_FIELD_IDS } from '../../../constants/nodeDataFieldIds';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../../../constants/actionBuilder.constants';
import { ASSIGNED_VARIABLE_FIELD_IDS, VARIABLE_TYPES } from '../../../constants/workflow.assignVariableConstants';

import fieldDefinitionReader from '../../../../../../../../readers/fieldDefinition.reader';
import entityReader from '../../../../../../../../readers/entity.reader';

const ROWS = 20;

const getEntityPayload = ({ nextPageToken, searchText }) => {
  const filters = [];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: 'name',
      values: [searchText],
      filterType: OPERATORS.TEXT_STARTS_WITH,
    });
  }

  return {
    rows: ROWS,
    nextPageToken,
    filters,
  };
};

const getValueOptionForEntity = (entityData) => ({
  label: entityReader.displayName(entityData) || NO_DATA,
  value: entityReader.name(entityData) || EMPTY_STRING,
});

const getEntityOptions = (data) => _map(data, getValueOptionForEntity);

const getFormFormattedNodeData = (formValues) => {
  const updatedFormValues = { ...formValues };
  const searchRequest = tget(formValues, ACTION_DEFINITION_FIELD_IDS.SEARCH_REQUEST, {});

  const filterConditions = { ...tget(searchRequest, ACTION_DEFINITION_FIELD_IDS.CONDITION) };

  _set(updatedFormValues, ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS, filterConditions);

  return updatedFormValues;
};

const getApiFormattedNodeDataAndUsedVariables = (formValues) => {
  const updatedApiFormValues = _pick(formValues, [
    LOOP_NODE_FIELD_IDS.ENTITY_NAME,
    LOOP_NODE_FIELD_IDS.LIST_TO_LOOP_OVER,
    LOOP_NODE_FIELD_IDS.LOOP_VARIABLE_NAME,
    LOOP_NODE_FIELD_IDS.LOOP_NAME,
  ]);
  const usedVariables = [];

  const searchRequest = {};
  const filterConditions = tget(formValues, ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS, {});
  const filterCriteriaList = tget(filterConditions, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY);

  _forEach(filterCriteriaList, (criteria) => {
    const value = getArraySafeValue(tget(criteria, CONDITION_FIELD_IDS.VALUES, []));
    if (checkValueIsVariable(value)) {
      const variableName = getVariableName(value);
      usedVariables.push(variableName);
    }
  });

  const criteriaList = tget(filterConditions, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY);
  const expression = tget(filterConditions, CONDITION_BUILDER_FIELD_IDS.EXPRESSION);

  if (!_isEmpty(criteriaList) && !_isEmpty(expression)) {
    _set(searchRequest, `${ACTION_DEFINITION_FIELD_IDS.CONDITION}.${CONDITION_BUILDER_FIELD_IDS.EXPRESSION}`, expression);
    _set(searchRequest, `${ACTION_DEFINITION_FIELD_IDS.CONDITION}.${CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST}`, criteriaList);
    _set(searchRequest, `${ACTION_DEFINITION_FIELD_IDS.CONDITION}.${CONDITION_BUILDER_FIELD_IDS.TYPE}`, CONDITION_BUILDER_TYPES.CRITERIA);
    _set(updatedApiFormValues, ACTION_DEFINITION_FIELD_IDS.SEARCH_REQUEST, searchRequest);
  }

  const listToLoopOver = tget(formValues, LOOP_NODE_FIELD_IDS.LIST_TO_LOOP_OVER);
  if (checkValueIsVariable(listToLoopOver)) {
    const variableName = getVariableName(listToLoopOver);
    usedVariables.push(variableName);
  }

  return { apiFormValues: updatedApiFormValues, usedVariables: _uniq(usedVariables) };
};

const getLoopVariableInfo = (formValues, taskDefId, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject) => {
  let loopVariableInfo = {};
  /**
   * Below being the variable structure to be stored at uiMetadata of workflow
   * customer: {
    entityName: "customer__u",
    variableDataType: "RECORD"/"LIST_OF_RECORDS",
  },
  "variables": {
        "Question_author": {
            "type": "PRIMITIVE",
            "fieldDefinitionMinimal": {
                "fieldType": "TEXT",
                "dataType": "TEXT",
                "multiValued": true
            }
        }
    }
   */

  const loopVariableName = tget(formValues, LOOP_NODE_FIELD_IDS.LOOP_VARIABLE_NAME);

  _set(loopVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_NAME, loopVariableName);

  if (taskDefId === TASK_DEF_IDS.FETCH_AND_ITERATE) {
    const entityName = tget(formValues, LOOP_NODE_FIELD_IDS.ENTITY_NAME);
    loopVariableInfo = {
      ...loopVariableInfo,
      [ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME]: entityName,
      [ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE]: VARIABLE_TYPES.ENTITY_RECORD,
    };
  } else {
    const listToLoopOver = tget(formValues, LOOP_NODE_FIELD_IDS.LIST_TO_LOOP_OVER);
    const selectedVariable = _first(_split(listToLoopOver, '.'));
    const selectedVariableEntityName = tget(mapOfVariableToEntityName, selectedVariable);
    const selectedOption = _drop(_split(listToLoopOver, '.'));

    const fieldDefForListToLoopOver = getFieldDefForLastSelectedField(
      selectedOption,
      conditionBuilderFieldDefinitionObject,
      selectedVariableEntityName,
    );

    const variableType = tget(fieldDefForListToLoopOver, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE);

    if (variableType === VARIABLE_TYPES.ENTITY_RECORD_LIST) {
      loopVariableInfo = {
        ...loopVariableInfo,
        [ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE]: VARIABLE_TYPES.ENTITY_RECORD,
        [ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME]: tget(fieldDefForListToLoopOver, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME),
      };
    } else {
      loopVariableInfo = {
        ...loopVariableInfo,
        [ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_DATA_TYPE]: fieldDefinitionReader.dataType(fieldDefForListToLoopOver),
        [ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE]: VARIABLE_TYPES.PRIMITIVE,
        [ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_FIELD_TYPE]: fieldDefinitionReader.fieldType(fieldDefForListToLoopOver),
        [ASSIGNED_VARIABLE_FIELD_IDS.MULTI_VALUED]: false,
      };
    }
  }

  return loopVariableInfo;
};

export { getEntityOptions, getEntityPayload, getFormFormattedNodeData, getApiFormattedNodeDataAndUsedVariables, getLoopVariableInfo };
