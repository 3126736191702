import { defaultMemoize } from 'reselect';

import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _head from 'lodash/head';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';

import getOperatorForFilterType from '@tekion/tekion-components/organisms/filterSection/factory/filterDialog.operator';
import getComponentForFilter from '@tekion/tekion-components/organisms/filterSection/filterDialog/containers/withFilterValue/withFilterValue.factory';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { getFilterForSimpleColumn, getOptions, getFilterFieldOption } from './filterForm.utils';

import FIELD_TYPES from '../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../constants/fieldDefinition.dataTypes';
import { FIELD_IDS } from '../constants/filterForm.constants';

import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';

const getFilters = defaultMemoize((fieldDefinitions) => {
  const filters = _reduce(
    fieldDefinitions,
    (prevFilters, currentFieldDef) => {
      const isColumnFilterable = fieldDefinitionReader.filterable(currentFieldDef);
      const fieldType = fieldDefinitionReader.fieldType(currentFieldDef);

      if (!isColumnFilterable || fieldType === FIELD_TYPES.MEDIA || fieldType === FIELD_TYPES.RICH_TEXT_EDITOR) return prevFilters;

      const dataType = fieldDefinitionReader.dataType(currentFieldDef);
      const displayName = fieldDefinitionReader.displayName(currentFieldDef);
      const fieldName = fieldDefinitionReader.name(currentFieldDef);

      if (fieldType === FIELD_TYPES.RELATIONSHIP || dataType === DATA_TYPES.COMPLEX) {
        let entityFieldDefinitions = {};

        if (fieldType === FIELD_TYPES.RELATIONSHIP) {
          entityFieldDefinitions = fieldDefinitionReader.relationshipEntityFieldDefinitions(currentFieldDef) || {};
        } else {
          entityFieldDefinitions = fieldDefinitionReader.complexEntityFieldDefinitions(currentFieldDef) || {};
        }

        let entityFieldFilters = getFilters(entityFieldDefinitions);
        entityFieldFilters = _map(entityFieldFilters, ({ label, value, ...restProps }) => ({
          ...restProps,
          label: !_isEmpty(label) ? `${displayName} - ${label}` : displayName,
          value: `${fieldName}.${value}`,
        }));

        return [...prevFilters, ...entityFieldFilters];
      } else {
        const filter = getFilterForSimpleColumn(fieldName, displayName, currentFieldDef);
        return [...prevFilters, filter];
      }
    },
    [],
  );

  return _compact(filters);
});

const getOptionsForOperator = defaultMemoize((fieldTypeOptions, fieldName) => {
  const filterType = getFilterFieldOption(fieldTypeOptions, fieldName);
  const operators = getOperatorForFilterType(filterType);
  const operatorOptions = getOptions(operators);

  return operatorOptions;
});

const getComponentForFilterHelper = defaultMemoize((fieldTypeOptions, fieldName, operatorType) => {
  const filterType = getFilterFieldOption(fieldTypeOptions, fieldName);
  const label = tget(filterType, 'label', EMPTY_STRING);
  const type = tget(filterType, 'type', EMPTY_STRING);
  const additional = tget(filterType, 'additional', EMPTY_OBJECT);

  const componentDetails = getComponentForFilter(
    { id: fieldName, name: label, type, additional },
    { operator: _head(operatorType), type: fieldName },
  );

  return componentDetails;
});

const getFilterMetadataWithFilterableFieldsOfMainEntity = (fieldDefinitions) =>
  _reduce(
    fieldDefinitions,
    (prevFilterMetadata, currentFieldDef) => {
      const isColumnFilterable = fieldDefinitionReader.filterable(currentFieldDef);
      const fieldType = fieldDefinitionReader.fieldType(currentFieldDef);
      const dataType = fieldDefinitionReader.dataType(currentFieldDef);

      if (
        !isColumnFilterable ||
        fieldType === FIELD_TYPES.MEDIA ||
        fieldType === FIELD_TYPES.RICH_TEXT_EDITOR ||
        fieldType === FIELD_TYPES.RELATIONSHIP ||
        dataType === DATA_TYPES.COMPLEX
      ) {
        return prevFilterMetadata;
      }

      const fieldName = fieldDefinitionReader.name(currentFieldDef);

      return [
        ...prevFilterMetadata,
        {
          [FIELD_IDS.FIELD_NAME]: fieldName,
          [FIELD_IDS.UPFRONT]: false,
        },
      ];
    },
    [],
  );

export { getFilters, getOptionsForOperator, getComponentForFilterHelper, getFilterMetadataWithFilterableFieldsOfMainEntity };
