import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components/molecules/loader';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import { FIELD_FORM_CONTEXT_ID, SUBMIT_ADDITIONAL_VALUE } from './constants/fieldsForm.constants';
import { FORM_MODES } from '../../../../constants/general.constants';
import ACTION_TYPES from './constants/fieldsForm.actionTypes';
import withSize from '../../../../connectors/withSize';
import ACTION_HANDLERS from './helpers/fieldsForm.actionHandlers';

// Form configurations
import { getFormFields } from './helpers/fieldsForm.config';
import { getFormSections } from './helpers/fieldsForm.sections';

const FieldsForm = ({
  isSavingDetails,
  isSavingDetailsCreateNew,
  isModalVisible,
  fieldDataLoading,
  contentHeight,
  formMode,
  entity,
  options = EMPTY_OBJECT,
  modalData,
  formValues,
  errors,
  selectedFields,
  childEntityDefinitions,
  selectedOptions,
  regexOptions,
  onAction,
}) => {
  const triggerFormSave = useCallback(
    (actionType) => () => {
      triggerSubmit(FIELD_FORM_CONTEXT_ID, { actionType });
    },
    [],
  );

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const sections = useMemo(
    () =>
      getFormSections({
        formValues,
        formMode,
      }),
    [formValues, formMode],
  );

  const fields = useMemo(
    () =>
      getFormFields({
        isModalVisible,
        formMode,
        formValues,
        entity,
        options,
        modalData,
        selectedFields,
        childEntityDefinitions,
        selectedOptions,
        regexOptions,
      }),
    [options, formValues, formMode, entity, modalData, isModalVisible, selectedFields, childEntityDefinitions, selectedOptions, regexOptions],
  );

  const headerTitle = formMode === FORM_MODES.CREATE ? __('Create Field') : __('Update Field');

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT_FORM });
  }, [onAction]);

  if (fieldDataLoading) {
    return <Loader />;
  }
  return (
    <Page>
      <Page.Header hasBack>
        <Heading>{headerTitle}</Heading>
      </Page.Header>
      <Page.Body className="full-width flex" style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          className="full-width"
          contextId={FIELD_FORM_CONTEXT_ID}
          fields={fields}
          sections={sections}
          values={formValues}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id={FIELD_FORM_CONTEXT_ID}
          showAdditionalButton={formMode === FORM_MODES.CREATE}
          additionalActionLoading={isSavingDetailsCreateNew}
          primaryActionLoading={isSavingDetails}
          additionalButtonLabel={__('Save and Create New')}
          additionalActionView="primary"
          primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Save') : __('Update')}
          onAdditionalAction={triggerFormSave(SUBMIT_ADDITIONAL_VALUE.SAVE_AND_CREATE_NEW)}
          onPrimaryAction={triggerFormSave(SUBMIT_ADDITIONAL_VALUE.SAVE)}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

FieldsForm.propTypes = {
  isSavingDetails: PropTypes.bool,
  isSavingDetailsCreateNew: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  fieldDataLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  options: PropTypes.object,
  entity: PropTypes.object,
  modalData: PropTypes.object,
  formValues: PropTypes.object,
  childEntityDefinitions: PropTypes.object,
  errors: PropTypes.object,
  selectedFields: PropTypes.array,
  selectedOptions: PropTypes.array,
  regexOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

FieldsForm.defaultProps = {
  isSavingDetails: false,
  isSavingDetailsCreateNew: false,
  isModalVisible: false,
  fieldDataLoading: false,
  formMode: FORM_MODES.CREATE,
  options: EMPTY_OBJECT,
  entity: undefined,
  modalData: EMPTY_OBJECT,
  childEntityDefinitions: EMPTY_OBJECT,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  selectedFields: EMPTY_ARRAY,
  selectedOptions: EMPTY_ARRAY,
  regexOptions: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(FieldsForm);
