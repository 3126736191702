import React, { useEffect, useCallback } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';
// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import Loader from '@tekion/tekion-components/molecules/loader';
import ViewViewer from '../../../../organisms/viewBuilder/organisms/viewViewer/ViewViewer';
import withSize from '../../../../connectors/withSize';

import { FORM_MODES } from '../../../../constants/viewBuilder.constants';
import { ACTION_TYPES } from './constants/entityRecordPage.constants';
import ACTION_HANDLERS from './helpers/entityRecordPage.actionHandlers';

import styles from './entityRecordPage.module.scss';

// eslint-disable-next-line no-unused-vars
const EntityRecordPage = ({ formMode, contentHeight, entity, entityRecord, onAction, isSavingDetails, complexViewConfigMapper, formViewConfig }) => {
  const headerTitle = formMode === FORM_MODES.CREATE ? __('Add New Record') : __('Edit Record');

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT_FORM });
  }, [onAction]);

  const triggerFormSubmit = useCallback(() => {
    triggerSubmit('FORM_VIEW_RENDERER');
  }, []);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const handleSubmit = useCallback(
    (payload) => {
      onAction({ type: ACTION_TYPES.ON_FORM_SUBMIT, payload });
    },
    [onAction],
  );

  const renderViewViewer = useCallback(
    () => (
      <div className={styles.viewViewer}>
        <ViewViewer isPreviewMode entity={entity} viewConfiguration={formViewConfig} onFormSubmit={handleSubmit} entityRecord={entityRecord} />
      </div>
    ),
    [entity, formViewConfig, entityRecord, handleSubmit],
  );
  const handleGoBack = useCallback(() => {
    onAction({ type: ACTION_TYPES.GO_BACK });
  }, [onAction]);
  if (_isEmpty(entity)) return <Loader />;

  return (
    <Page>
      <Page.Header hasBack goBackHandler={handleGoBack}>
        <Heading>{headerTitle}</Heading>
      </Page.Header>
      <Page.Body className={styles.body} style={{ height: contentHeight }}>
        {renderViewViewer()}
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id="SAVE_COMPONENT"
          primaryActionLoading={isSavingDetails}
          primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Create') : __('Update')}
          onPrimaryAction={triggerFormSubmit}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

EntityRecordPage.propTypes = {
  formMode: PropTypes.string,
  contentHeight: PropTypes.number.isRequired,
  complexViewConfigMapper: PropTypes.object,
  entity: PropTypes.object,
  formViewConfig: PropTypes.object,
  entityRecord: PropTypes.object,
  isSavingDetails: PropTypes.bool,
  onAction: PropTypes.func,
};

EntityRecordPage.defaultProps = {
  formMode: FORM_MODES.CREATE,
  entity: undefined,
  formViewConfig: undefined,
  complexViewConfigMapper: undefined,
  entityRecord: undefined,
  isSavingDetails: false,
  onAction: _noop,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(EntityRecordPage);
