// TODO: Make field pages use this common constant
const FIELD_TYPES = {
  TEXT: 'TEXT',
  LIST: 'LIST',
  RANGE: 'RANGE',
  RELATIONSHIP: 'RELATIONSHIP',
  SINGLE_SELECT: 'SINGLE_SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
  SELECT: 'SELECT',
  RICH_TEXT_EDITOR: 'RICH_TEXT',
  CHILD_AGGREGATE_SUMMARY: 'AGGREGATE_SUMMARY',
  MEDIA: 'MEDIA',
};

export default FIELD_TYPES;
