import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import SingleSelect from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SingleSelect';
import CheckBoxField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import { FIELD_IDS, PROVIDER_TYPE_OPTIONS } from '../constants/authProviderForm.constants';
import { FORM_MODES } from '../../../../../../../constants/general.constants';

const PROVIDER_TYPE_FIELD = {
  id: FIELD_IDS.PROVIDER_TYPE,
  renderer: SingleSelect,
  renderOptions: {
    id: FIELD_IDS.PROVIDER_TYPE,
    required: true,
    label: __('Provider Type'),
    options: PROVIDER_TYPE_OPTIONS,
    validators: [isRequiredRule],
  },
};

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Name'),
    validators: [isRequiredRule],
  },
};

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Display Name'),
    validators: [isRequiredRule],
  },
};

const URL_SUFFIX_FIELD = {
  id: FIELD_IDS.URL_SUFFIX,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Url Suffix'),
    validators: [isRequiredRule],
  },
};

const KEY_FIELD = {
  id: FIELD_IDS.KEY,
  renderer: TextInput,
  renderOptions: {
    label: __('Key'),
  },
};

const SECRET_FIELD = {
  id: FIELD_IDS.SECRET,
  renderer: TextInput,
  renderOptions: {
    label: __('Secret'),
  },
};

const AUTHORIZE_END_POINT_URL_FIELD = {
  id: FIELD_IDS.AUTHORIZE_END_POINT_URL,
  renderer: TextInput,
  renderOptions: {
    label: __('Authorize End Point URL'),
  },
};

const TOKEN_END_POINT_URL_FIELD = {
  id: FIELD_IDS.TOKEN_END_POINT_URL,
  renderer: TextInput,
  renderOptions: {
    label: __('Token End Point URL'),
  },
};

const USER_INFO_END_POINT_URL_FIELD = {
  id: FIELD_IDS.USER_INFO_END_POINT_URL,
  renderer: TextInput,
  renderOptions: {
    label: __('User Info End Point URL'),
  },
};

const TOKEN_ISSUER_FIELD = {
  id: FIELD_IDS.TOKEN_ISSUER,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Token Issuer'),
    validators: [isRequiredRule],
  },
};

const DEFAULT_SCOPE_FIELD = {
  id: FIELD_IDS.DEFAULT_SCOPE,
  renderer: TextInput,
  renderOptions: {
    label: __('Default Scope'),
  },
};

const INCLUDE_CONSUMER_SECRET_IN_RESPONSE_FIELD = {
  id: FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE,
  renderer: CheckBoxField,
  renderOptions: {
    checkboxLabel: __('Include Consume Secret in Response'),
  },
};

const SEND_ACCESS_TOKEN_IN_HEADER_FIELD = {
  id: FIELD_IDS.SEND_ACCESS_TOKEN_IN_HEADER,
  renderer: CheckBoxField,
  renderOptions: {
    checkboxLabel: __('Send Access Token in Header'),
  },
};

const SEND_CLIENT_CREDS_IN_HEADER_FIELD = {
  id: FIELD_IDS.SEND_CLIENT_CREDS_IN_HEADER,
  renderer: CheckBoxField,
  renderOptions: {
    checkboxLabel: __('Send Client Creds in Header'),
  },
};

const CUSTOM_ERROR_URL_FIELD = {
  id: FIELD_IDS.CUSTOM_ERROR_URL,
  renderer: TextInput,
  renderOptions: {
    label: __('Custom Error URL'),
  },
};

const CUSTOM_LOGOUT_URL_FIELD = {
  id: FIELD_IDS.CUSTOM_LOGOUT_URL,
  renderer: TextInput,
  renderOptions: {
    label: __('Custom Logout URL'),
  },
};

const REGISTRATION_HANDLER_FIELD = {
  id: FIELD_IDS.REGISTRATION_HANDLER,
  renderer: TextInput,
  renderOptions: {
    label: __('Registration Handler'),
  },
};

const EXECUTE_REGISTRATION_AS_FIELD = {
  id: FIELD_IDS.EXECUTE_REGISTRATION_AS,
  renderer: TextInput,
  renderOptions: {
    label: __('Execute Registration As'),
  },
};

const PORTAL_FIELD = {
  id: FIELD_IDS.PORTAL,
  renderer: TextInput,
  renderOptions: {
    label: __('Portal'),
  },
};

const ICON_URL_FIELD = {
  id: FIELD_IDS.ICON_URL,
  renderer: TextInput,
  renderOptions: {
    label: __('Icon URL'),
  },
};

const TEAM_ID_FIELD = {
  id: FIELD_IDS.TEAM_ID,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Team ID'),
    validators: [isRequiredRule],
  },
};

const SERVICE_ID_FIELD = {
  id: FIELD_IDS.SERVICE_ID,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Service ID'),
    validators: [isRequiredRule],
  },
};

const KEY_ID_FIELD = {
  id: FIELD_IDS.KEY_ID,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Key ID'),
    validators: [isRequiredRule],
  },
};

const KEY_MEDIA_ID_FIELD = {
  id: FIELD_IDS.KEY_MEDIA_ID,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Key Media ID'),
    validators: [isRequiredRule],
  },
};

const DEFAULT_SCOPES_FIELD = {
  id: FIELD_IDS.DEFAULT_SCOPES,
  renderer: TextInput,
  renderOptions: {
    label: __('Default Scopes'),
  },
};

const REGISTER_AS_FIELD = {
  id: FIELD_IDS.REGISTER_AS,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Register As'),
    validators: [isRequiredRule],
  },
};

const AUTH_PROVIDER_FORM_FIELDS = {
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.PROVIDER_TYPE]: PROVIDER_TYPE_FIELD,
  [FIELD_IDS.URL_SUFFIX]: URL_SUFFIX_FIELD,
  [FIELD_IDS.KEY]: KEY_FIELD,
  [FIELD_IDS.SECRET]: SECRET_FIELD,
  [FIELD_IDS.AUTHORIZE_END_POINT_URL]: AUTHORIZE_END_POINT_URL_FIELD,
  [FIELD_IDS.TOKEN_END_POINT_URL]: TOKEN_END_POINT_URL_FIELD,
  [FIELD_IDS.USER_INFO_END_POINT_URL]: USER_INFO_END_POINT_URL_FIELD,
  [FIELD_IDS.DEFAULT_SCOPE]: DEFAULT_SCOPE_FIELD,
  [FIELD_IDS.INCLUDE_CONSUMER_SECRET_IN_RESPONSE]: INCLUDE_CONSUMER_SECRET_IN_RESPONSE_FIELD,
  [FIELD_IDS.CUSTOM_ERROR_URL]: CUSTOM_ERROR_URL_FIELD,
  [FIELD_IDS.CUSTOM_LOGOUT_URL]: CUSTOM_LOGOUT_URL_FIELD,
  [FIELD_IDS.REGISTRATION_HANDLER]: REGISTRATION_HANDLER_FIELD,
  [FIELD_IDS.EXECUTE_REGISTRATION_AS]: EXECUTE_REGISTRATION_AS_FIELD,
  [FIELD_IDS.PORTAL]: PORTAL_FIELD,
  [FIELD_IDS.ICON_URL]: ICON_URL_FIELD,
  [FIELD_IDS.TEAM_ID]: TEAM_ID_FIELD,
  [FIELD_IDS.SERVICE_ID]: SERVICE_ID_FIELD,
  [FIELD_IDS.KEY_ID]: KEY_ID_FIELD,
  [FIELD_IDS.KEY_MEDIA_ID]: KEY_MEDIA_ID_FIELD,
  [FIELD_IDS.DEFAULT_SCOPES]: DEFAULT_SCOPES_FIELD,
  [FIELD_IDS.REGISTER_AS]: REGISTER_AS_FIELD,
  [FIELD_IDS.TOKEN_ISSUER]: TOKEN_ISSUER_FIELD,
  [FIELD_IDS.SEND_ACCESS_TOKEN_IN_HEADER]: SEND_ACCESS_TOKEN_IN_HEADER_FIELD,
  [FIELD_IDS.SEND_CLIENT_CREDS_IN_HEADER]: SEND_CLIENT_CREDS_IN_HEADER_FIELD,
};

const getAuthProviderFields = (formMode) => ({
  ...AUTH_PROVIDER_FORM_FIELDS,
  [FIELD_IDS.NAME]: addToRenderOptions(AUTH_PROVIDER_FORM_FIELDS[FIELD_IDS.NAME], [{ path: 'disabled', value: formMode === FORM_MODES.EDIT }]),
});

export default getAuthProviderFields;
