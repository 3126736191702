exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .widgetContainer_widget__5TSyEGvA3z{border-radius:.5rem;background-color:#fff}.widgetContainer_widget__5TSyEGvA3z .widgetContainer_widgetHeader__nbGYEc2iDV{height:5rem;display:flex;justify-content:space-between;align-items:center}[dir] .widgetContainer_widget__5TSyEGvA3z .widgetContainer_widgetHeader__nbGYEc2iDV{padding:1rem;border-bottom:.1rem solid #eeeff0;margin-bottom:1rem}[dir] .widgetContainer_widget__5TSyEGvA3z .widgetContainer_widgetBody__6SA5Ttf49H{padding:1rem 1rem 0}[dir] .widgetContainer_widgetButton__eMBNPEtti5{margin:1rem}", ""]);

// Exports
exports.locals = {
	"widget": "widgetContainer_widget__5TSyEGvA3z",
	"widgetHeader": "widgetContainer_widgetHeader__nbGYEc2iDV",
	"widgetBody": "widgetContainer_widgetBody__6SA5Ttf49H",
	"widgetButton": "widgetContainer_widgetButton__eMBNPEtti5"
};