const SORT_ORDERS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const SORT_ORDER_OPTIONS = [
  { label: __(SORT_ORDERS.ASC), value: SORT_ORDERS.ASC },
  { label: __(SORT_ORDERS.DESC), value: SORT_ORDERS.DESC },
];

export { SORT_ORDER_OPTIONS };
