import { withProps } from 'recompose';

import _map from 'lodash/map';

import { hasNumberRule, isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';
import Checkbox from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import MultiSelect from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/MultiSelectField';

import FilterForm from '../filterForm';
import SortOptionsConfigurator from '../sortOptionsConfigurator';
import CustomActionsConfigurator from '../customActionsConfigurator';
import SearchOptionsConfigurator from '../searchOptionsConfigurator';
import EventHandlerConfigurator from '../eventHandlerConfigurator';
import CustomStylesConfigurator from '../customStylesConfigurator';
import SelectViewField from '../selectViewField';

import { percentageValidationRule } from '../../../../utils/formValidators';
import { selectCardViewFieldValidator } from '../selectViewField/selectViewField.helper';

import { FIELD_IDS, DOWNLOAD_OPTIONS } from './gridViewRendererPropertiesForm.constants';

const mapPropsForCheckbox = ({ value, defaultValue, ...restProps }) => ({
  ...restProps,
  value: value === null || value === undefined ? defaultValue : value,
});

const mapPropsForDownload = ({ value, ...restProps }) => {
  const newValue = _map(value, ({ downloadType }) => downloadType);
  return { ...restProps, value: newValue };
};

const TITLE_FIELD = {
  id: FIELD_IDS.TITLE,
  renderer: TextInput,
  renderOptions: {
    label: __('Title'),
    defaultValue: 'Section',
  },
};

const WIDTH_FIELD = {
  id: FIELD_IDS.WIDTH,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Width(%)'),
    defaultValue: 100,
    min: 0,
    max: 100,
    validators: [isRequiredRule, percentageValidationRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const TABLE_SHOW_HEADER_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_HEADER,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show header'),
    validators: [isRequiredRule],
  },
};

const TABLE_SHOW_SUB_HEADER_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_SUB_HEADER,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show sub header'),
    validators: [isRequiredRule],
  },
};

const TABLE_SHOW_REFRESH_ICON_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_REFRESH_ICON,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show refresh icon'),
    validators: [isRequiredRule],
  },
};

const TABLE_SHOW_MULTI_SORT_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_MULTI_SORT,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show Multi sort'),
    validators: [isRequiredRule],
  },
};

const TABLE_SHOW_FILTER_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_FILTER,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show filter'),
    validators: [isRequiredRule],
  },
};

const TABLE_SHOW_SEARCH_BY_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_SEARCH_BY_FIELD,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show search by field'),
    validators: [isRequiredRule],
  },
};

const TABLE_FIXED_PAGINATION_FIELD = {
  id: FIELD_IDS.TABLE_FIXED_PAGINATION,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Fixed pagination'),
    validators: [isRequiredRule],
  },
};

const TABLE_SHOW_PAGINATION_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_PAGINATION,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show Pagination'),
    validators: [isRequiredRule],
  },
};

const DOWNLOAD_OPTIONS_FIELD = {
  id: FIELD_IDS.DOWNLOAD_OPTIONS,
  renderer: withProps(mapPropsForDownload)(MultiSelect),
  renderOptions: {
    label: __('Download options'),
    isMulti: true,
    closeMenuOnSelect: false,
    defaultValue: [],
    options: DOWNLOAD_OPTIONS,
  },
};

const FILTER_COMPONENT_FIELD = {
  id: FIELD_IDS.FILTER_COMPONENT,
  renderer: FilterForm,
};

const SORT_DETAILS_FIELD = {
  id: FIELD_IDS.SORT_DETAILS,
  renderer: SortOptionsConfigurator,
};

const ACTIONS_TO_SHOW_FIELD = {
  id: FIELD_IDS.ACTIONS_TO_SHOW,
  renderer: CustomActionsConfigurator,
};

const SEARCH_OPTIONS_FIELD = {
  id: FIELD_IDS.SEARCH_OPTIONS,
  renderer: SearchOptionsConfigurator,
};

const EVENT_HANDLER_FIELD = {
  id: FIELD_IDS.EVENT_HANDLERS,
  renderer: EventHandlerConfigurator,
};

const CUSTOM_STYLE_FIELD = {
  id: FIELD_IDS.CUSTOM_STYLES,
  renderer: CustomStylesConfigurator,
};

const GRID_TABLE_ROW_COUNT_FIELD = {
  id: FIELD_IDS.GRID_TABLE_ROW_COUNT,
  renderer: NumberInputField,
  renderOptions: {
    label: __('No of rows in table'),
    defaultValue: 3,
    min: 1,
    max: 5,
    validators: [isRequiredRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const GRID_TABLE_COLUMN_COUNT_FIELD = {
  id: FIELD_IDS.GRID_TABLE_COLUMN_COUNT,
  renderer: NumberInputField,
  renderOptions: {
    label: __('No of columns in table'),
    defaultValue: 3,
    min: 1,
    max: 5,
    validators: [isRequiredRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const CARD_VIEW_NAME_FIELD = {
  id: FIELD_IDS.CARD_VIEW_NAME,
  renderer: SelectViewField,
  renderOptions: {
    validators: [selectCardViewFieldValidator],
  },
};

const FORM_FIELDS = {
  [FIELD_IDS.TITLE]: TITLE_FIELD,
  [FIELD_IDS.WIDTH]: WIDTH_FIELD,
  [FIELD_IDS.TABLE_SHOW_HEADER]: TABLE_SHOW_HEADER_FIELD,
  [FIELD_IDS.TABLE_SHOW_SUB_HEADER]: TABLE_SHOW_SUB_HEADER_FIELD,
  [FIELD_IDS.TABLE_SHOW_REFRESH_ICON]: TABLE_SHOW_REFRESH_ICON_FIELD,
  [FIELD_IDS.TABLE_SHOW_MULTI_SORT]: TABLE_SHOW_MULTI_SORT_FIELD,
  [FIELD_IDS.TABLE_SHOW_FILTER]: TABLE_SHOW_FILTER_FIELD,
  [FIELD_IDS.TABLE_SHOW_SEARCH_BY_FIELD]: TABLE_SHOW_SEARCH_BY_FIELD,
  [FIELD_IDS.TABLE_FIXED_PAGINATION]: TABLE_FIXED_PAGINATION_FIELD,
  [FIELD_IDS.TABLE_SHOW_PAGINATION]: TABLE_SHOW_PAGINATION_FIELD,
  [FIELD_IDS.DOWNLOAD_OPTIONS]: DOWNLOAD_OPTIONS_FIELD,
  [FIELD_IDS.FILTER_COMPONENT]: FILTER_COMPONENT_FIELD,
  [FIELD_IDS.SORT_DETAILS]: SORT_DETAILS_FIELD,
  [FIELD_IDS.ACTIONS_TO_SHOW]: ACTIONS_TO_SHOW_FIELD,
  [FIELD_IDS.SEARCH_OPTIONS]: SEARCH_OPTIONS_FIELD,
  [FIELD_IDS.EVENT_HANDLERS]: EVENT_HANDLER_FIELD,
  [FIELD_IDS.CUSTOM_STYLES]: CUSTOM_STYLE_FIELD,
  [FIELD_IDS.GRID_TABLE_ROW_COUNT]: GRID_TABLE_ROW_COUNT_FIELD,
  [FIELD_IDS.GRID_TABLE_COLUMN_COUNT]: GRID_TABLE_COLUMN_COUNT_FIELD,
  [FIELD_IDS.CARD_VIEW_NAME]: CARD_VIEW_NAME_FIELD,
};

const getFields = (componentType, entity, properties, errors) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.FILTER_COMPONENT]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.FILTER_COMPONENT], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
  ]),
  [FIELD_IDS.ACTIONS_TO_SHOW]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.ACTIONS_TO_SHOW], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
  ]),
  [FIELD_IDS.SEARCH_OPTIONS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.SEARCH_OPTIONS], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
  ]),
  [FIELD_IDS.SORT_DETAILS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.SORT_DETAILS], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
  ]),
  [FIELD_IDS.EVENT_HANDLERS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.EVENT_HANDLERS], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
  [FIELD_IDS.CUSTOM_STYLES]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CUSTOM_STYLES], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
  [FIELD_IDS.CARD_VIEW_NAME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CARD_VIEW_NAME], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'errors', value: tget(errors, FIELD_IDS.CARD_VIEW_NAME, EMPTY_OBJECT) },
  ]),
});

export { getFields };
