import _property from 'lodash/property';

const workflowReader = {
  id: _property('id'),
  name: _property('name'),
  displayName: _property('displayName'),
  description: _property('description'),
};

export default workflowReader;
