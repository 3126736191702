import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

const INITIAL_STATES_IMPORT_FORM_PROPS = {
  values: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

const IMPORT_FORM_CONTEXT_ID = 'IMPORT_BUNDLE';

export { IMPORT_FORM_CONTEXT_ID, INITIAL_STATES_IMPORT_FORM_PROPS };
