import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _get from 'lodash/get';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';
import Error from '@tekion/tekion-components/organisms/FormBuilder/components/error';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import RichTextEditor from '../../organisms/richTextEditor';
import { RICH_TEXT_EDITOR_STYLES } from '../../organisms/viewBuilder/constants/viewBuilder.constants';

import styles from './richTextEditorRenderer.module.scss';

const RichTextEditorRenderer = ({ disabled, value, error, label, onAction, ...restProps }) => {
  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const type = _get(action, 'type');
      const payload = _get(action, 'payload', EMPTY_OBJECT);
      const _value = _get(action, 'payload.value', EMPTY_ARRAY);
      if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
        onAction({
          type,
          payload: {
            ...payload,
            value: _value,
          },
        });
      } else {
        onAction(action);
      }
    },
    [onAction],
  );

  return (
    <div className={cx(fieldLayoutStyles.fieldC, styles.fieldRichText)}>
      <FieldLabel label={label} {...restProps} />
      <RichTextEditor
        readOnly={disabled}
        value={value}
        toolbarStyles={RICH_TEXT_EDITOR_STYLES}
        className={`full-width overflow-auto ${styles.richTextEditorWrapper}`}
        onAction={handleAction}
        {...restProps}
      />
      <Error error={error} />
    </div>
  );
};

RichTextEditorRenderer.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

RichTextEditorRenderer.defaultProps = {
  disabled: false,
  label: EMPTY_STRING,
  error: EMPTY_STRING,
  value: undefined,
};

export default React.memo(RichTextEditorRenderer);
