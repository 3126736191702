import { useRef, useCallback } from 'react';

function useFileInput() {
  const inputRef = useRef();
  const onClick = useCallback(() => {
    inputRef.current.value = null;
    inputRef.current.click();
  }, [inputRef]);

  return {
    onClick,
    inputRef,
  };
}

export default useFileInput;
