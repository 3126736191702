import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _concat from 'lodash/concat';
import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _reject from 'lodash/reject';

import InfiniteScroll from 'react-infinite-scroller';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Components
import WithSize from '../../../../../../../../connectors/withSize';
import NotificationSubHeader from '../../atoms/notificationSubHeader/NotificationSubHeader';
import EmptyNotification from '../../atoms/emptyNotification';
import NotificationCardSkeleton from '../../atoms/notificationCardSkeleton';
import NotificationCard from './components/notificationCard';

import communicationsActions from '../../../../../../../../actions/communications.actions';

// Helpers
import { generatePayload } from './helpers/notificationCardList.helpers';

// Constants
import { NOTIFICATION_FIELD_IDS, NOTIFICATION_GROUP_FIELD_IDS, NOTIFICATION_TYPES } from '../../../../../../../../constants/notification.constants';
import { TAB_CONTENT_FILTER_TYPES } from '../../constants/notificationDrawer.constants';

import styles from './notificationCardList.module.scss';

const NotificationCardList = ({
  isGroupExpanded,
  showUnreadOnly,
  contentHeight,
  filterType,
  cardContainerClassName,
  notificationGroup,
  onFilterChange,
}) => {
  const topNotification = tget(notificationGroup, NOTIFICATION_GROUP_FIELD_IDS.COMMUNICATION);
  const groupKey = tget(topNotification, NOTIFICATION_FIELD_IDS.GROUP_KEY);

  const [notifications, setNotifications] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);

  const [isScrollLoading, setIsScrollLoading] = useState(false);
  const [{ isInitialLoading, isInitialErrored, isInitialFetched }, setInitialFetchingState] = useState({});

  const loadNotificationsForGroup = useCallback(async () => {
    setInitialFetchingState({ isInitialLoading: true });

    const payload = generatePayload(showUnreadOnly, groupKey);
    const { hits, isErrored, nextPageToken: _nextPageToken } = await communicationsActions.fetchNotifications(payload);

    setNotifications(hits);
    setNextPageToken(_nextPageToken);
    setInitialFetchingState({ isInitialErrored: isErrored, isInitialFetched: true, isInitialLoading: false });
  }, [showUnreadOnly, groupKey]);

  const loadMoreNotificationsForGroup = async () => {
    if (nextPageToken && isInitialFetched && !isScrollLoading) {
      setIsScrollLoading(true);
      const payload = generatePayload(showUnreadOnly, groupKey, nextPageToken);
      const { hits, isErrored, nextPageToken: _nextPageToken } = await communicationsActions.fetchNotifications(payload);

      if (!isErrored) {
        setNotifications((_cur) => _concat(_cur, hits));
        setNextPageToken(_nextPageToken);
      }

      setIsScrollLoading(false);
    }
  };

  const removeNotification = useCallback(
    (notificationId) =>
      setNotifications((_notifications) =>
        _reject(_notifications, (notification) => {
          const _notificationId = tget(notification, NOTIFICATION_FIELD_IDS.NOTIFICATION_ID);

          if (!_isEmpty(_notificationId) && _notificationId === notificationId) {
            return true;
          }

          return false;
        }),
      ),
    [],
  );

  const handleMarkAllAsRead = async () => {
    const response = await communicationsActions.markAllNotificationsAsRead(NOTIFICATION_TYPES.NUDGE);

    if (!_isEmpty(response)) {
      loadNotificationsForGroup();
    }
  };

  const handleClearAll = async () => {
    const response = await communicationsActions.clearAllNotifications(NOTIFICATION_TYPES.NUDGE);

    if (!_isEmpty(response)) {
      loadNotificationsForGroup();
    }
  };

  useEffect(() => {
    setInitialFetchingState({ isInitialLoading: false, isInitialErrored: false, isInitialFetched: false });
    setNotifications([]);
    setNextPageToken(null);
  }, [filterType, showUnreadOnly]);

  useEffect(() => {
    if (
      ((filterType === TAB_CONTENT_FILTER_TYPES.BY_GROUP && isGroupExpanded) || filterType === TAB_CONTENT_FILTER_TYPES.MOST_RECENT) &&
      !isInitialFetched
    ) {
      loadNotificationsForGroup();
    }
  }, [isGroupExpanded, isInitialFetched, filterType, showUnreadOnly, loadNotificationsForGroup]);

  if (isInitialLoading) {
    return <NotificationCardSkeleton />;
  }

  if (isInitialErrored) {
    return <EmptyNotification />;
  }

  if (filterType === TAB_CONTENT_FILTER_TYPES.BY_GROUP && !isGroupExpanded) {
    return <NotificationCard cardContainerClassName={cardContainerClassName} notification={topNotification} />;
  }

  const isActionDisabled = isInitialErrored || (isInitialFetched && _isEmpty(notifications));

  return (
    <>
      {filterType === TAB_CONTENT_FILTER_TYPES.MOST_RECENT && (
        <NotificationSubHeader
          isActionDisabled={isActionDisabled}
          filterType={filterType}
          onFilterChange={onFilterChange}
          onMarkAllAsRead={handleMarkAllAsRead}
          onClearAll={handleClearAll}
        />
      )}
      <div style={{ height: contentHeight }} className={styles.listContainer}>
        <InfiniteScroll
          useWindow={false}
          hasMore={!_isEmpty(nextPageToken)}
          pageStart={0}
          loadMore={loadMoreNotificationsForGroup}
          loader={NotificationCardSkeleton}
        >
          {_map(notifications, (notification, index) => {
            const notificationId = tget(notification, NOTIFICATION_FIELD_IDS.NOTIFICATION_ID, index);
            return (
              <NotificationCard
                key={notificationId}
                notification={notification}
                removeNotification={removeNotification}
                showNotificationHeader={filterType === TAB_CONTENT_FILTER_TYPES.MOST_RECENT}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    </>
  );
};

NotificationCardList.propTypes = {
  isGroupExpanded: PropTypes.bool,
  showUnreadOnly: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  cardContainerClassName: PropTypes.string,
  filterType: PropTypes.string.isRequired,
  notificationGroup: PropTypes.object,
  onFilterChange: PropTypes.func,
};

NotificationCardList.defaultProps = {
  isGroupExpanded: false,
  showUnreadOnly: false,
  cardContainerClassName: EMPTY_STRING,
  notificationGroup: EMPTY_OBJECT,
  onFilterChange: _noop,
};

export default WithSize({ hasPageHeader: 1, headerHeight: 250 })(NotificationCardList);
