import { hasNumberRule, isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';

import EventHandlerConfigurator from '../eventHandlerConfigurator';
import CustomStylesConfigurator from '../customStylesConfigurator';

import { FIELD_IDS } from './sectionPropertiesForm.constants';

const COLUMN_COUNT_FIELD = {
  id: FIELD_IDS.COLUMN_COUNT,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Number of Columns'),
    defaultValue: 1,
    min: 1,
    placeholder: __('Enter the number of columns here'),
    validators: [isRequiredRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const TITLE_FIELD = {
  id: FIELD_IDS.TITLE,
  renderer: TextInput,
  renderOptions: {
    label: __('Title'),
    defaultValue: 'Section',
  },
};

const SECTION_TITLE_SIZE_FIELD = {
  id: FIELD_IDS.SECTION_TITLE_SIZE,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Size of title'),
    defaultValue: 1,
    min: 1,
    max: 6,
    placeholder: __('Enter the size of header here'),
    validators: [isRequiredRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const EVENT_HANDLER_FIELD = {
  id: FIELD_IDS.EVENT_HANDLERS,
  renderer: EventHandlerConfigurator,
};

const CUSTOM_STYLE_FIELD = {
  id: FIELD_IDS.CUSTOM_STYLES,
  renderer: CustomStylesConfigurator,
};

const FORM_FIELDS = {
  [FIELD_IDS.COLUMN_COUNT]: COLUMN_COUNT_FIELD,
  [FIELD_IDS.TITLE]: TITLE_FIELD,
  [FIELD_IDS.SECTION_TITLE_SIZE]: SECTION_TITLE_SIZE_FIELD,
  [FIELD_IDS.EVENT_HANDLERS]: EVENT_HANDLER_FIELD,
  [FIELD_IDS.CUSTOM_STYLES]: CUSTOM_STYLE_FIELD,
};

const getFields = (entity, componentType, properties) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.EVENT_HANDLERS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.EVENT_HANDLERS], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
  [FIELD_IDS.CUSTOM_STYLES]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CUSTOM_STYLES], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
});

export { getFields };
