import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components/molecules/loader';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import withSize from '../../../../../../connectors/withSize';

// Helpers
import ACTION_HANDLERS from './helpers/approvalSettingFieldForm.actionHandlers';
import { getFormFields } from './helpers/approvalSettingFieldForm.config';
import { getFormSections } from './helpers/approvalSettingFieldForm.sections';

// Constants
import { FIELD_FORM_CONTEXT_ID } from './constants/approvalSettingFieldForm.constants';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import APPROVAL_SETTING_FIELD_IDS from '../constants/approvalSettings.fieldIds';
import ACTION_TYPES from './constants/approvalSettingFieldForm.actionTypes';

import styles from './approvalSettingFieldForm.module.scss';

const ApprovalSettingsFieldsForm = ({
  isSavingDetails,
  isModalVisible,
  fieldDataLoading,
  formMode,
  contentHeight,
  approvalSetting,
  modalData,
  formValues,
  errors,
  selectedFields,
  selectedOptions,
  onAction,
}) => {
  const settingName = tget(approvalSetting, APPROVAL_SETTING_FIELD_IDS.NAME);
  const group = tget(approvalSetting, APPROVAL_SETTING_FIELD_IDS.GROUP);
  const category = tget(approvalSetting, APPROVAL_SETTING_FIELD_IDS.CATEGORY);

  const headerTitle = formMode === FORM_MODES.CREATE ? __('Add Field') : __('Update Field');
  const goBackRoute = `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_SETTING_DETAIL}/${settingName}`;

  const triggerFormSave = useCallback(() => {
    triggerSubmit(FIELD_FORM_CONTEXT_ID);
  }, []);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const sections = useMemo(
    () =>
      getFormSections({
        formValues,
        formMode,
      }),
    [formValues, formMode],
  );

  const fields = useMemo(
    () =>
      getFormFields({
        formValues,
        formMode,
        approvalSetting,
        modalData,
        isModalVisible,
        selectedFields,
        selectedOptions,
      }),
    [formValues, formMode, approvalSetting, modalData, isModalVisible, selectedFields, selectedOptions],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT_FORM });
  }, [onAction]);

  if (fieldDataLoading) {
    return <Loader />;
  }
  return (
    <Page>
      <Page.Header contentClassName={styles.headerContainer} hasBack goBackTo={goBackRoute}>
        <Heading>{__('Approval Setting - {{headerTitle}}', { headerTitle })}</Heading>
        <div className={styles.tagContainer}>
          <div className="p-r-4">
            {__('Group:')}
            <span className={styles.tag}>{group}</span>
          </div>
          <div>
            {__(' Category: {{category}}')}
            <span className={styles.tag}>{category}</span>
          </div>
        </div>
      </Page.Header>
      <Page.Body className="full-width flex" style={{ height: contentHeight, overflowY: 'auto', paddingTop: '1.2rem' }}>
        <FormWithSubmission
          className="full-width"
          contextId={FIELD_FORM_CONTEXT_ID}
          fields={fields}
          sections={sections}
          values={formValues}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id={FIELD_FORM_CONTEXT_ID}
          primaryActionLoading={isSavingDetails}
          primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Save') : __('Update')}
          onPrimaryAction={triggerFormSave}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

ApprovalSettingsFieldsForm.propTypes = {
  isSavingDetails: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  fieldDataLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  approvalSetting: PropTypes.object,
  modalData: PropTypes.object,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  selectedFields: PropTypes.array,
  selectedOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ApprovalSettingsFieldsForm.defaultProps = {
  isSavingDetails: false,
  isModalVisible: false,
  fieldDataLoading: false,
  formMode: FORM_MODES.CREATE,
  approvalSetting: undefined,
  modalData: EMPTY_OBJECT,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  selectedFields: EMPTY_ARRAY,
  selectedOptions: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ApprovalSettingsFieldsForm);
