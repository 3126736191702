const ACTION_TYPES = {
  ON_INIT: 'ON_INIT',
  TABLE_ON_CHANGE: 'TABLE_ON_CHANGE',
  TABLE_REMOVE_ROW: 'TABLE_REMOVE_ROW',
  TABLE_ADD_ROW: 'TABLE_ADD_ROW',
  ON_CANCEL: 'ON_CANCEL',
  ON_CONFIRM_MODAL_REQUEST: 'ON_CONFIRM_MODAL_REQUEST',
  ON_CANCEL_MODAL_REQUEST: 'ON_CANCEL_MODAL_REQUEST',
};
export default ACTION_TYPES;
