import { defaultMemoize } from 'reselect';
import _set from 'lodash/set';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Button from '@tekion/tekion-components/atoms/Button';
import ACTION_TYPES from '../constants/projectSettings.actionTypes';

const createTableProps = defaultMemoize((isLoading) => ({
  loading: isLoading,
  rowHeight: 35,
  minRows: 0,
  showPagination: false,
}));

const createAddProjectsPayload = defaultMemoize((projectNames, permissionData) => {
  const modifiedPermissions = { ...permissionData };
  const projectPermissions = tget(modifiedPermissions, 'projectPermission', EMPTY_OBJECT);
  let modifiedProjectNames = tget(projectPermissions, 'projectNames', EMPTY_ARRAY);

  modifiedProjectNames = [...modifiedProjectNames, ...projectNames];
  _set(modifiedPermissions, 'projectPermission.projectNames', modifiedProjectNames);
  return modifiedPermissions;
});

const getSubHeaderProps = defaultMemoize((count) => ({
  subHeaderLeftActions: [
    {
      renderer: Heading,
      renderOptions: {
        children: __(`Projects (${count})`),
        size: 2,
      },
    },
  ],
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Add Project'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.ASSIGN_PROJECT_CLICK,
    },
  ],
}));

export { createTableProps, getSubHeaderProps, createAddProjectsPayload };
