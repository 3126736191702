exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".appSkeleton_container__9cUSwu7kGq{height:100vh}.appSkeleton_topHeader__5yhesYEYAZ{height:6.4rem;width:100%;display:flex;justify-content:space-between;align-items:center;font-family:\"Proxima-Nova-Bold\"}[dir] .appSkeleton_topHeader__5yhesYEYAZ{background-color:#969aa3;padding:1.8rem}[dir] .appSkeleton_tekion__qr298Z7H51{padding:0 2.4rem}.appSkeleton_appWrapper__9xmjURYo7V{display:flex;flex-flow:row;height:calc(100% - 6.4rem)}", ""]);

// Exports
exports.locals = {
	"container": "appSkeleton_container__9cUSwu7kGq",
	"topHeader": "appSkeleton_topHeader__5yhesYEYAZ",
	"tekion": "appSkeleton_tekion__qr298Z7H51",
	"appWrapper": "appSkeleton_appWrapper__9xmjURYo7V"
};