import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import EventHandlerConfigurator from '../eventHandlerConfigurator';

import { FIELD_IDS } from './formViewRendererPropertiesForm.constants';

const EVENT_HANDLER_FIELD = {
  id: FIELD_IDS.EVENT_HANDLERS,
  renderer: EventHandlerConfigurator,
};

const FORM_FIELDS = {
  [FIELD_IDS.EVENT_HANDLERS]: EVENT_HANDLER_FIELD,
};

const getFields = (properties, entity, componentType) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.EVENT_HANDLERS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.EVENT_HANDLERS], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
});

export { getFields };
