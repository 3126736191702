import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import TextAreaInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';

import { FORM_MODES } from '../../../../../../constants/general.constants';
import { FIELD_IDS } from '../../../../../../constants/permissionSet.constants';

const PERMISSION_SET_DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Display Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Display Name'),
  },
};

const PERMISSION_SET_NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Name'),
    placeholder: __('Enter Permission Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const PERMISSION_SET_DESCRIPTION_FIELD = {
  id: FIELD_IDS.DESCRIPTION,
  renderer: TextAreaInput,
  renderOptions: {
    label: __('Description'),
    placeholder: __('Enter description'),
  },
};

const PERMISSION_SET_FORM_FIELDS = {
  [FIELD_IDS.NAME]: PERMISSION_SET_NAME_FIELD,
  [FIELD_IDS.DESCRIPTION]: PERMISSION_SET_DESCRIPTION_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: PERMISSION_SET_DISPLAY_NAME_FIELD,
};

const getFields = (mode) => ({
  ...PERMISSION_SET_FORM_FIELDS,
  [FIELD_IDS.NAME]: addToRenderOptions(PERMISSION_SET_NAME_FIELD, [
    // { path: 'disabled', value: mode !== FORM_MODES.CREATE },
    { path: 'required', value: mode === FORM_MODES.CREATE },
  ]),
});

export default getFields;

export { FIELD_IDS };
