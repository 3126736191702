import produce from 'immer';

import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import _snakeCase from 'lodash/snakeCase';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { saveBundleData, getBundleById, updateBundleData } from '../../../../../actions/metadataManagement.actions';

import { FIELD_IDS } from './exportForm.fields';
import { STATUS } from '../constants/exportForm.general.constants';
import { STUDIO_ROUTE } from '../../../../../constants/routes';
import { FORM_MODES } from '../../../../../constants/general.constants';
import ACTION_TYPES from '../constants/exportForm.actionTypes';
import PAGE_IDS from '../../../constants/PageIds.constants';

import METADATA_READER from '../../../../../readers/metadataManagement.reader';

const handleOnMount = async ({ getState, setState }) => {
  const { match, history } = getState();
  const { bundleId } = tget(match, 'params', EMPTY_OBJECT);

  if (_isEmpty(bundleId)) {
    setState({ formMode: FORM_MODES.CREATE });

    return;
  }

  setState({ isDataLoading: true });

  let bundleData = tget(history, 'state.bundleInfo', EMPTY_OBJECT);

  if (_isEmpty(bundleData)) {
    bundleData = await getBundleById(bundleId);
  }

  const bundleState = METADATA_READER.state(bundleData);

  setState({ exportData: bundleData, isDataLoading: false, formMode: FORM_MODES.EDIT, bundleState });
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;

  setState({ errors });
};

const handleFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { disableAutoFillForName = false, formMode } = getState();
  const { id, value } = params;

  setState(
    produce((draft) => {
      _set(draft, `exportData.${id}`, value);
      if (formMode === FORM_MODES.CREATE && id === FIELD_IDS.DISPLAY_NAME && !disableAutoFillForName) {
        _set(draft, `exportData.${FIELD_IDS.NAME}`, _snakeCase(value));
      } else if (id === FIELD_IDS.NAME) setState({ disableAutoFillForName: true });
    }),
  );
};

const handleRedirection = ({ getState }) => {
  const { history } = getState();
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.EXPORTS}`;

  history.push({ pathname });
};

const handleSubmit = async ({ getState, setState }) => {
  const { exportData = {}, history, match } = getState();
  const bundleId = tget(match, 'params.bundleId', '');
  let responseData = EMPTY_OBJECT;
  let responseStatus = EMPTY_STRING;

  setState({ isSaveLoading: true, isDataLoading: true });

  if (_isEmpty(bundleId)) {
    const { data, status } = await saveBundleData(exportData);
    responseData = data;
    responseStatus = status;
  } else {
    const { data, status } = await updateBundleData(bundleId, exportData);
    responseData = data;
    responseStatus = status;
  }

  setState({ isSaveLoading: false, isDataLoading: false });

  if (responseStatus === STATUS.SUCCESS) {
    const id = tget(responseData, 'id', bundleId);
    const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.EXPORTS}/${PAGE_IDS.ADD_METADATA_COMPONENT}/${id}`;
    history.push({ pathname, state: { bundleInfo: responseData } });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_MOUNT]: handleOnMount,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
};

export default ACTION_HANDLERS;
