// Lodash
import _property from 'lodash/property';

const attribute = _property('attribute');
const oldValues = _property('oldValues');
const newValues = _property('newValues');
const attributeDisplay = _property('attributeDisplay');
const auditType = _property('auditType');
const lookupType = _property('lookupType');
const attributeType = _property('attributeType');
const nestedAuditChanges = _property('nestedAuditChange');
const complexObjectDisplayName = _property('complexObjectDisplayName');

const READERS = {
  attribute,
  oldValues,
  newValues,
  attributeDisplay,
  auditType,
  lookupType,
  attributeType,
  nestedAuditChanges,
  complexObjectDisplayName,
};

export default READERS;
