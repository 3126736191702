import React, { useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';

// Constants
import { DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { TABLE_MANAGER_PROPS, INITIAL_STATE_ENTITY_TAB_PROPS } from './constants/entityTab.constants';
import withSize from '../../../../../../connectors/withSize';

// Helpers
import { COLUMN_CONFIG } from './helpers/entityTab.columns';
import ACTION_HANDLERS from './entityTab.actionHandlers';
import { createTableProps } from './helpers/entityTab.helpers';

import styles from './entityTab.module.scss';

const EntityTab = ({ searchText, entities, nextPageToken, pageSize, currentPage, contentHeight, loading, onAction }) => {
  const tableProps = useMemo(() => createTableProps(loading, currentPage, pageSize, entities), [loading, currentPage, pageSize, entities]);

  return (
    <Page className={styles.entityTabContainer} style={{ height: contentHeight }}>
      <TableManager
        tableProps={tableProps}
        tableManagerProps={TABLE_MANAGER_PROPS}
        columns={COLUMN_CONFIG}
        data={entities}
        onAction={onAction}
        pageSize={pageSize}
        currentPage={currentPage}
        searchText={searchText}
        tokenPagination
        nextPageToken={nextPageToken}
      />
    </Page>
  );
};

EntityTab.propTypes = {
  entities: PropTypes.array,
  searchText: PropTypes.string,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  contentHeight: PropTypes.number.isRequired,
  nextPageToken: PropTypes.string,
  loading: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
};

EntityTab.defaultProps = {
  searchText: EMPTY_STRING,
  entities: EMPTY_ARRAY,
  pageSize: DEFAULT_PAGE_INFO.rows,
  currentPage: DEFAULT_PAGE_INFO.start,
  nextPageToken: undefined,
  loading: false,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 0 }), withActions(INITIAL_STATE_ENTITY_TAB_PROPS, ACTION_HANDLERS))(EntityTab);
