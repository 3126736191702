const FORGOT_PASSWORD_CONTEXT_ID = 'FORGOT_PASSWORD_CONTEXT_ID';

const FIELD_NAMES = {
  USERNAME: 'username',
  CAPTCHA_TOKEN: 'captchaToken',
};

const ACTION_TYPES = {
  RESEND_RESET_EMAIL: 'RESEND_RESET_EMAIL',
  RESET_PASSWORD: 'RESET_PASSWORD',
};

export { FORGOT_PASSWORD_CONTEXT_ID, FIELD_NAMES, ACTION_TYPES };
