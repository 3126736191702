/* eslint-disable import/no-unused-modules */
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _includes from 'lodash/includes';
import _trim from 'lodash/trim';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _last from 'lodash/last';
import _identity from 'lodash/identity';
import _toNumber from 'lodash/toNumber';
import _isString from 'lodash/isString';

import { EMPTY_STRING, EMPTY_OBJECT } from 'tbase/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import {
  minimumLengthRule as _minimumLengthRule,
  maximumLengthRule as _maximumLengthRule,
  isIntegerRule as _isIntegerRule,
} from '@tekion/tekion-base/utils/formValidators';
import { CONDITION_BUILDER_FIELD_IDS } from '../organisms/conditionBuilder';

const betweenLengthRule = (minValue, maxValue, valueToTest) => {
  const isValid = _minimumLengthRule('', valueToTest, minValue).isValid && _maximumLengthRule(maxValue)('', valueToTest).isValid;

  if (isValid) {
    return { isValid };
  }
  return {
    isValid,
    message: __('Length must be between {{minValue}} to {{maxValue}}', {
      minValue,
      maxValue,
    }),
  };
};

const ruleValidator = (minValue, maxValue, valueToTest) => {
  if (_isNil(valueToTest) || valueToTest === EMPTY_STRING) {
    return true;
  }
  if ((_isNil(minValue) || _toNumber(minValue) < _toNumber(valueToTest)) && (_isNil(maxValue) || _toNumber(maxValue) > _toNumber(valueToTest))) {
    return true;
  }
  return false;
};

const minValueRule = (minValue, maxValue) => (fieldId, valueToTest) => {
  const fromValue = _get(valueToTest, '0', null);
  const isValid = ruleValidator(minValue, maxValue, fromValue);
  if (isValid) {
    return { isValid };
  }
  return {
    isValid,
    message: {
      from: __(`Value must be between ${minValue} to ${maxValue}`),
    },
  };
};

const maxValueRule = (minValue, maxValue) => (fieldId, valueToTest) => {
  const toValue = _get(valueToTest, '1', null);
  const isValid = ruleValidator(minValue, maxValue, toValue);
  if (isValid) {
    return { isValid };
  }
  return {
    isValid,
    message: {
      to: __(`Value must be between ${minValue} to ${maxValue}`),
    },
  };
};

const minGreaterThanRule =
  (minValue, maxValue, formatter = _identity) =>
  (fieldId, valueToTest) => {
    const fromValue = _get(valueToTest, '0', null);
    const isValid = ruleValidator(minValue, maxValue, fromValue);
    if (isValid) {
      return { isValid };
    }
    return {
      isValid: false,
      message: __('Start date should be in between {{ minValue }} and {{ maxValue }}', {
        minValue: formatter(minValue),
        maxValue: formatter(maxValue),
      }),
    };
  };

const maxLessThanRule =
  (minValue, maxValue, formatter = _identity) =>
  (fieldId, valueToTest) => {
    const toValue = _get(valueToTest, '1', null);
    const isValid = ruleValidator(minValue, maxValue, toValue);
    if (isValid) {
      return { isValid };
    }
    return {
      isValid: false,
      message: __('End date should be in between {{ minValue }} and {{ maxValue }}', {
        minValue: formatter(minValue),
        maxValue: formatter(maxValue),
      }),
    };
  };

const isRequiredRuleForNumberRange = (fieldId, valueToTest) => {
  if (_isNil(_head(valueToTest))) return { isValid: false, message: { from: __('This field is mandatory') } };
  if (_isNil(_last(valueToTest))) return { isValid: false, message: { to: __('This field is mandatory') } };
  return { isValid: true };
};

const minimumLengthRule = (length) => (fieldId, valueToTest) => _minimumLengthRule(fieldId, valueToTest, length);

const regexValidator = (pattern, regex) => (fieldId, valueToTest) =>
  regex.test(valueToTest) ? { isValid: true } : { isValid: false, message: __('Input not valid as per pattern {{pattern}}.', { pattern }) };

const hexCodeValidationRule = (fieldId, value) =>
  /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/gm.test(value)
    ? { isValid: true }
    : {
        isValid: false,
        message: 'Invalid color code!',
      };

const percentageValidationRule = (fieldId, value) => {
  const numberValue = Number(value);

  return !Number.isNaN(numberValue) && numberValue >= 0 && numberValue <= 100
    ? { isValid: true }
    : {
        isValid: false,
        message: 'Invalid number range!',
      };
};

const isPersonNameRequiredRule = (firstNameKey, lastNameKey) => (fieldId, valueToTest) => {
  let isValid = '';
  const firstName = valueToTest?.[firstNameKey];
  const lastName = valueToTest?.[lastNameKey];

  const isFirstNamePresent = !(_trim(firstName) === '' || _isNil(firstName));
  const isLastNamePresent = !(_trim(lastName) === '' || _isNil(lastName));
  isValid = isFirstNamePresent && isLastNamePresent;
  const message = {};
  if (!isFirstNamePresent) {
    message[firstNameKey] = __('First name is mandatory');
  }
  if (!isLastNamePresent) {
    message[lastNameKey] = __('last name is mandatory');
  }
  // return isValid ? { isValid } : { isValid, message: __('First name and last name are mandatory') };
  return isValid
    ? { isValid }
    : {
        isValid,
        message,
      };
};

const nameValidator = (fieldId, valueToTest) => {
  if (_isEmpty(valueToTest)) return { isValid: false, message: __('This field is mandatory') };
  if (valueToTest.match(/__/)) return { isValid: false, message: __('Name cannot contain __ (double underscore)') };
  const upperCaseReg = /^(?=.*[A-Z])/;
  if (upperCaseReg.test(valueToTest)) return { isValid: false, message: __('Name cannot have uppercase letters') };
  if (valueToTest.match(/ /)) return { isValid: false, message: __('Name cannot have spaces') };

  return { isValid: true };
};

const ruleNameValidator = (fieldId, valueToTest) => {
  if (_isEmpty(valueToTest)) return { isValid: false, message: __('Please enter a name') };
  if (valueToTest.match(/^[0-9].*/)) return { isValid: false, message: __('Name cannot starts with digit. ') };

  return { isValid: true };
};

const classNameValidator = (valueToTest) => {
  if (_isEmpty(valueToTest)) return false;
  if (!valueToTest.match(/^[A-Z].*/)) return false;
  if (!valueToTest.match('^[A-Za-z0-9_$]*$')) return false;

  return true;
};

const isRequiredScriptRule = (script) => {
  let isValid = true;
  let message = __('This field is mandatory');

  if (!script || _trim(script) === '') {
    isValid = false;
  } else if (_includes(script, '//')) {
    isValid = false;
    message = __('Only Use /* ... */ for adding comments.');
  }

  return isValid ? { isValid } : { isValid, message };
};

const isStringValidJson = (customStyle) => {
  let isValid = true;
  let message = '';

  if (!_isEmpty(customStyle)) {
    try {
      JSON.parse(customStyle, undefined, 4);
    } catch (err) {
      isValid = false;
      message = __('Not a valid JSON Request body');
    }
  } else {
    isValid = false;
    message = __('This field is mandatory');
  }

  return isValid ? { isValid } : { isValid, message };
};

const entityViewNameValidator = (fieldId, valueToTest) => {
  if (!valueToTest.match('^[a-z0-9_]*$'))
    return {
      isValid: false,
      message: __('Name can contain only lower case alphabets , digits and underscore . Name should start with lower case alphabets'),
    };

  return { isValid: true };
};

const isRegexRule = (fieldId, valueToTest) => {
  let isValid = true;

  try {
    RegExp(valueToTest);
  } catch {
    isValid = false;
  }

  return isValid ? { isValid } : { isValid, message: __('Not a Valid Regex') };
};

const isRichTextEditorTextValidator = (fieldId, valueToTest) => {
  const value = tget(valueToTest, 'plainText', EMPTY_STRING);
  if (_isString(valueToTest)) {
    let newValue;
    try {
      newValue = valueToTest.replace(/<pre[^<>]*\/*>(.*?)(<\/pre>)/gm, '');
      newValue = newValue.replace(/<[^>]+>/g, '');
      newValue = newValue.replace(/&nbsp/g, ' ');
    } catch {
      newValue = EMPTY_STRING;
    }
    if (_isEmpty(newValue)) {
      return { isValid: false, message: __('Please enter some text') };
    } else {
      return { isValid: true };
    }
  }
  if (_isEmpty(value)) {
    return { isValid: false, message: __('Please enter some text') };
  }
  return { isValid: true };
};

const expressionRequiredRule = (fieldId, values = EMPTY_OBJECT) => {
  const expression = _get(values, CONDITION_BUILDER_FIELD_IDS.EXPRESSION);
  const criteriaList = _get(values, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST);
  const isValid = !_isEmpty(expression) || !_isEmpty(criteriaList);

  return isValid
    ? { isValid }
    : { isValid, message: { [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: __('Please add some criteria for the condition.') } };
};

// This validator can take string & number both and validate whether resultant number is integer or not.
// As compared to isIntegerRule from tbase which considers strings as invalid.
const isIntegerRule = (fieldId, valueToTest) => _isIntegerRule(fieldId, Number(valueToTest));

export {
  minimumLengthRule,
  regexValidator,
  maxLessThanRule,
  minGreaterThanRule,
  maxValueRule,
  minValueRule,
  isRequiredRuleForNumberRange,
  isPersonNameRequiredRule,
  hexCodeValidationRule,
  percentageValidationRule,
  nameValidator,
  classNameValidator,
  ruleNameValidator,
  isRequiredScriptRule,
  entityViewNameValidator,
  isStringValidJson,
  isRegexRule,
  isRichTextEditorTextValidator,
  isIntegerRule,
  expressionRequiredRule,
  betweenLengthRule,
};
