import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _get from 'lodash/get';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Components
import TreeNode from './components/TreeNode';

// Styles
import styles from './tree.module.scss';

const Tree = (props) => {
  const { treeData, ...rest } = props;

  const getTreeNode = () => _map(treeData, (node) => <TreeNode nodeData={node} key={_get(node, 'key', EMPTY_STRING)} level={0} {...rest} />);

  return <div className={styles.container}>{getTreeNode()}</div>;
};

Tree.propTypes = {
  treeData: PropTypes.array,
};

Tree.defaultProps = {
  treeData: EMPTY_ARRAY,
};

export default Tree;
