import { useEffect, useState, useCallback } from 'react';

// Helpers
import { getMark, setMarks } from '@udecode/plate-common';
import { Transforms } from 'slate';
import { getPlatePluginType } from '@udecode/plate-core';

// Constants
import { MARK_FONT_SIZE } from '@udecode/plate-font';
import { DEFAULT_FONT_SIZE } from '../molecules/fontSizeInput';

// Hooks
import useEditor from '../../../../hooks/useEditor';

// Utils
import { getEditorSafeSelection } from '../../../utils/toolbar.selection';

const getInitialFontSize = (editor, fontSizeMarkType) => {
  const currentFontSizeAtCursor = editor && getMark(editor, fontSizeMarkType);
  const initialFontSize = currentFontSizeAtCursor || DEFAULT_FONT_SIZE;
  return initialFontSize;
};

const useFontSize = () => {
  const editor = useEditor();
  const fontSizeMarkType = getPlatePluginType(editor, MARK_FONT_SIZE);
  const initialFontSize = getInitialFontSize(editor, fontSizeMarkType);
  const [fontSize, setFontSize] = useState(initialFontSize);

  useEffect(() => {
    setFontSize(initialFontSize);
  }, [initialFontSize]);

  const applyFontSize = useCallback(
    (size) => {
      setFontSize(size);
      const editorSelection = getEditorSafeSelection(editor);
      Transforms.select(editor, editorSelection);
      setMarks(editor, { [fontSizeMarkType]: Number(size) });
    },
    [editor, setFontSize, fontSizeMarkType],
  );

  return { fontSize, setFontSize, applyFontSize };
};

export default useFontSize;
