exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".propertiesForm_center__gtkozHDTSi{display:flex;justify-content:center;align-items:center}.propertiesForm_horizontal__e2thqze969{display:flex;flex-direction:row}[dir=ltr] .propertiesForm_fieldLabel__9e5jVZdcXF{left:1rem}[dir=rtl] .propertiesForm_fieldLabel__9e5jVZdcXF{right:1rem}.propertiesForm_left__4YSRoQ3yAr{top:.2rem;opacity:1}[dir] .propertiesForm_left__4YSRoQ3yAr{cursor:pointer}[dir=ltr] .propertiesForm_left__4YSRoQ3yAr{left:1rem}[dir=rtl] .propertiesForm_left__4YSRoQ3yAr{right:1rem}", ""]);

// Exports
exports.locals = {
	"center": "propertiesForm_center__gtkozHDTSi",
	"horizontal": "propertiesForm_horizontal__e2thqze969",
	"fieldLabel": "propertiesForm_fieldLabel__9e5jVZdcXF",
	"left": "propertiesForm_left__4YSRoQ3yAr"
};