import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getSections } from './sectionPropertiesForm.sections';
import { getFields } from './sectionPropertiesForm.fields';
import { getSectionPropertiesFromProperties, getUpdatedSelectedViewComponentFromOnChangeValue } from './sectionPropertiesForm.helpers';

import { VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

const SectionPropertiesForm = ({ selectedViewComponent, entity, onAction }) => {
  const viewType = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.VIEW_TYPE);
  const componentType = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.TYPE);
  const properties = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);

  const sections = useMemo(() => getSections(viewType), [viewType]);
  const fields = useMemo(() => getFields(entity, componentType, properties), [componentType, entity, properties]);
  const sectionProperties = useMemo(() => getSectionPropertiesFromProperties(properties), [properties]);

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const newSelectedViewComponent = getUpdatedSelectedViewComponentFromOnChangeValue(selectedViewComponent, payload);
          onAction({ type, payload: newSelectedViewComponent });
          break;
        }

        default: {
          onAction(action);
          break;
        }
      }
    },
    [selectedViewComponent, onAction],
  );

  return <FormBuilder className="full-width" sections={sections} fields={fields} values={sectionProperties} onAction={handleAction} />;
};

SectionPropertiesForm.propTypes = {
  selectedViewComponent: PropTypes.object,
  entity: PropTypes.object,
  onAction: PropTypes.func,
};

SectionPropertiesForm.defaultProps = {
  selectedViewComponent: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  onAction: _noop,
};

export default SectionPropertiesForm;
