import React from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _split from 'lodash/split';
import _noop from 'lodash/noop';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import MediaUploaderFormRenderer from '../../../mediaUploader/MediaUploaderFormRenderer';

import { GALLERY_MODES } from '../../../mediaUploader/components/ImageThumbnails/imageThumbnail.constants';

import styles from './mediaCellRenderer.module.scss';

const MediaCellRenderer = ({ multiple, column = EMPTY_OBJECT, fieldDef, original = EMPTY_OBJECT }) => {
  const accessor = _get(column, 'accessor', _noop);

  const value = _split(accessor(original), ',');

  const recordId = _get(original, 'id');

  return (
    <MediaUploaderFormRenderer
      viewOnly
      showRefreshIcon={false}
      multiple={multiple}
      displayCount={0}
      recordId={recordId}
      mode={GALLERY_MODES.VIEW_ONLY}
      accept=".jpg,.png,.jpeg"
      value={value}
      thumbnailImageClassName={styles.thumbnailImage}
      fieldDef={fieldDef}
    />
  );
};

MediaCellRenderer.propTypes = {
  multiple: PropTypes.bool,
  column: PropTypes.object,
  original: PropTypes.object,
  fieldDef: PropTypes.object,
};

MediaCellRenderer.defaultProps = {
  multiple: false,
  column: EMPTY_OBJECT,
  original: EMPTY_OBJECT,
  fieldDef: EMPTY_OBJECT,
};

export default makeCellRenderer(MediaCellRenderer);
