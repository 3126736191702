import { defaultMemoize } from 'reselect';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import FILTER_FIELD_KEYS, { TEXT_STARTS_WITH } from '../../../../../../constants/filter.constants';

import RECORD_GROUP_LIST_COLUMN_IDS from '../constants/entityRecordGroupList.columnIds';
import { EDIT_ACTION } from '../constants/entityRecordGroupList.tableConstants';
import { PRINCIPAL_TYPES } from '../../../recordGroupRuleList/constants/recordSharingRuleList.general.constants';

const getTableProps = defaultMemoize((handleRowAction, currentPage, loading, rows, totalNumberOfEntries) => ({
  currentPage: currentPage + 1,
  loading,
  showPagination: true,
  pageSize: rows,
  resultsPerPage: rows,
  totalNumberOfEntries,
  minRows: 0,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  rows,
  onRowActionClick: handleRowAction,
  getRowActions: () => [EDIT_ACTION],
}));

const getPayload = defaultMemoize((searchText, pageSize, nextPageToken) => {
  let payload = { rows: pageSize };

  if (!_isEmpty(searchText)) {
    payload = {
      ...payload,
      filters: [
        {
          field: RECORD_GROUP_LIST_COLUMN_IDS.RECORD_GROUP_NAME,
          values: [searchText],
          filterType: TEXT_STARTS_WITH,
        },
      ],
    };
  }

  payload = {
    ...payload,
    filters: [
      ..._get(payload, 'filters', EMPTY_ARRAY),
      {
        [FILTER_FIELD_KEYS.FIELD]: RECORD_GROUP_LIST_COLUMN_IDS.ENTITY_NAME,
        [FILTER_FIELD_KEYS.VALUES]: [PRINCIPAL_TYPES.USER],
        [FILTER_FIELD_KEYS.FILTER_TYPE]: OPERATORS.NIN,
      },
    ],
  };

  if (!_isEmpty(nextPageToken)) {
    payload = {
      ...payload,
      nextPageToken,
    };
  }
  return payload;
});

export { getTableProps, getPayload };
