import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _noop from 'lodash/noop';

import Button from '@tekion/tekion-components/atoms/Button';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import styles from '../../../AuthenticationForm/authentication.module.scss';

const BackToLogin = ({ actionType, onAction }) => {
  const handleClick = useCallback(() => {
    onAction({
      type: actionType,
    });
  }, [onAction, actionType]);

  return (
    <Button view={Button.VIEW.TERTIARY} className={cx(styles.loginButton, styles.backToLogin)} onClick={handleClick}>
      {__('Back to login')}
    </Button>
  );
};

BackToLogin.propTypes = {
  actionType: PropTypes.string,
  onAction: PropTypes.func,
};

BackToLogin.defaultProps = {
  actionType: EMPTY_STRING,
  onAction: _noop,
};

export default React.memo(BackToLogin);
