import COLUMN_IDS from './tenantUserList.columnIds';

const DEFAULT_USER_LIST_PROPS = {
  currentPage: 0,
  pageSize: 50,
  searchField: COLUMN_IDS.DISPLAY_NAME,
};

const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

const SEARCHABLE_OPTIONS = [
  {
    label: __('Name'),
    value: COLUMN_IDS.DISPLAY_NAME,
  },
  {
    label: __('Email'),
    value: COLUMN_IDS.EMAIL,
  },
];

const USER_STATUS = {
  ACTIVE: true,
  INACTIVE: false,
};

export { DEFAULT_USER_LIST_PROPS, TABLE_CONSTANTS, SEARCHABLE_OPTIONS, USER_STATUS };
