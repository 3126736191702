import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _noop from 'lodash/noop';
import { getMark } from '@udecode/plate-common';
import { getPlatePluginType } from '@udecode/plate-core';
import { MARK_COLOR } from '@udecode/plate-font';

// components
import Button from '@tekion/tekion-components/atoms/Button';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

// styles
import COLORS from '@tekion/tekion-styles-next/scss/variables.scss';
import styles from '../../fontColorToolbarButton.module.scss';

// hooks
import useEditor from '../../../../../hooks/useEditor';
import useOnColorSelection from './hooks/useOnColorSelection';

// Constants
import { FONT_COLORS } from './fontColorList.constants';

const renderFontColorButton =
  ({ onColorSelection, selectedFontColorMark }) =>
  (color) => {
    const isSelected = selectedFontColorMark === color;
    return (
      <Button key={color} className={styles.colorButton} onClick={() => onColorSelection(color)} style={{ backgroundColor: color }}>
        {isSelected && (
          <FontIcon size={SIZES.S} color={COLORS.white}>
            icon-tick1
          </FontIcon>
        )}
      </Button>
    );
  };

function FontColorList(props) {
  const { onCloseDropdown, fontColors } = props;
  const editor = useEditor();
  const fontColorMarkType = getPlatePluginType(editor, MARK_COLOR);
  const selectedFontColorMark = getMark(editor, fontColorMarkType) || COLORS.black;

  const onColorSelection = useOnColorSelection(onCloseDropdown, editor, fontColorMarkType);

  return (
    <div className={`p-x-8 p-y-8 ${styles.fontColorList}`}>
      {_map(fontColors, renderFontColorButton({ onColorSelection, selectedFontColorMark }))}
    </div>
  );
}

FontColorList.propTypes = {
  onCloseDropdown: PropTypes.func,
  fontColors: PropTypes.array,
};

FontColorList.defaultProps = {
  onCloseDropdown: _noop,
  fontColors: FONT_COLORS,
};

export default FontColorList;
