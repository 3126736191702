import { getPlatePluginType } from '@udecode/plate-core';
import { ELEMENT_LINK } from '@udecode/plate-link';
import { getAbove } from '@udecode/plate-common';
import { ELEMENT_TEMPLATE_VARIABLE } from '../plugins/templateVariable';

export const getSelectedLinkNode = (editor) => {
  if (editor?.selection) {
    const type = getPlatePluginType(editor, ELEMENT_LINK);
    const linkNode = getAbove(editor, {
      match: { type },
      at: editor.selection,
    });
    return linkNode;
  }
  return undefined;
};

export const getSelectedTemplateVariableNode = (editor) => {
  if (editor?.selection) {
    const type = getPlatePluginType(editor, ELEMENT_TEMPLATE_VARIABLE);
    const templateVariableNode = getAbove(editor, {
      match: { type },
      at: editor.selection,
    });
    return templateVariableNode;
  }
  return undefined;
};
