exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .imagePropertiesForm_formBuilderSectionContainer__fPuTFmiPFE{padding:0 0 .4rem;margin:1.2rem 0 .4rem;border-bottom:.1rem solid #e5e5e5}.imagePropertiesForm_formSectionHeader__cFYne59bxS{font-weight:normal}.imagePropertiesForm_grid__cTtiGrujXU{overflow-y:auto;display:flex;flex-direction:row}.imagePropertiesForm_grid__cTtiGrujXU .imagePropertiesForm_icon__hsVJvHF4px{height:3rem;width:3rem;justify-content:center;align-items:center}[dir] .imagePropertiesForm_grid__cTtiGrujXU .imagePropertiesForm_icon__hsVJvHF4px{border:.1rem solid #e5e5e5;margin:.5rem}.imagePropertiesForm_grid__cTtiGrujXU .imagePropertiesForm_icon__hsVJvHF4px.imagePropertiesForm_active__omCG8C9cLk{font-weight:bold}[dir] .imagePropertiesForm_grid__cTtiGrujXU .imagePropertiesForm_icon__hsVJvHF4px.imagePropertiesForm_active__omCG8C9cLk{background:#fafafa}.imagePropertiesForm_grid__cTtiGrujXU .imagePropertiesForm_icon__hsVJvHF4px.imagePropertiesForm_disabled__up1cvz3irr{pointer-events:none;font-size:0}[dir] .imagePropertiesForm_grid__cTtiGrujXU .imagePropertiesForm_icon__hsVJvHF4px.imagePropertiesForm_disabled__up1cvz3irr{background:#e5e5e5}", ""]);

// Exports
exports.locals = {
	"formBuilderSectionContainer": "imagePropertiesForm_formBuilderSectionContainer__fPuTFmiPFE",
	"formSectionHeader": "imagePropertiesForm_formSectionHeader__cFYne59bxS",
	"grid": "imagePropertiesForm_grid__cTtiGrujXU",
	"icon": "imagePropertiesForm_icon__hsVJvHF4px",
	"active": "imagePropertiesForm_active__omCG8C9cLk",
	"disabled": "imagePropertiesForm_disabled__up1cvz3irr"
};