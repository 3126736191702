import _snakeCase from 'lodash/snakeCase';
import _castArray from 'lodash/castArray';
import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import _remove from 'lodash/remove';
import _find from 'lodash/find';
import _head from 'lodash/head';
import _get from 'lodash/get';
import _map from 'lodash/map';

import { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

// Actions
import { getSettingByName, addFieldToSetting, updateFieldOfSetting } from '../../../../../../../actions/approvalManagement.actions';

// Helpers
import { getPayloadFromValues, getValuesFromPayload, getPropertyValuesFromPayload } from './approvalSettingFieldForm.payload.helpers';
import { validateConfigTable, validateControllingOption, validateInputTable } from './approvalSettingFieldForm.helpers';

// Constants
import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import { FORM_MODES } from '../../../../../../../constants/general.constants';
import PAGE_IDS from '../../../../../constants/PageIds.constants';
import FIELD_TYPES from '../../../../../../../constants/fieldDefinition.fieldTypes';
import APPROVAL_SETTING_FIELD_IDS from '../../constants/approvalSettings.fieldIds';
import FIELD_IDS from '../constants/approvalSettingFieldForm.fieldIds';
import ACTION_TYPES from '../constants/approvalSettingFieldForm.actionTypes';
import { COLUMNS } from '../constants/approvalSettingFieldForm.constants';
import { COLUMN_IDS, EMPTY_ROW } from '../components/ListInputTable/constants/listInputTable.constants';

const handleInitForm = async ({ setState, getState }) => {
  const { history = EMPTY_OBJECT, match = EMPTY_OBJECT } = getState();
  const { settingName, fieldName } = _get(match, 'params', EMPTY_OBJECT);

  let approvalSetting = _get(history, 'location.state.approvalSetting');
  let fieldData = _get(history, 'location.state.fieldData', EMPTY_OBJECT);

  setState({ fieldDataLoading: true });

  if (!approvalSetting && settingName) {
    approvalSetting = await getSettingByName(settingName);
  }

  if (fieldName && settingName) {
    if (_isEmpty(fieldData)) {
      const fieldsList = tget(approvalSetting, APPROVAL_SETTING_FIELD_IDS.FIELDS);
      fieldData = _find(fieldsList, { [FIELD_IDS.NAME]: fieldName });
    }

    const valuesFromPayload = getValuesFromPayload(fieldData);
    const propertyValuesFromPayload = getPropertyValuesFromPayload(fieldData);

    const fieldType = _get(fieldData, FIELD_IDS.FIELD_TYPE, EMPTY_STRING);

    let selectedOptions = [];
    if (fieldType === FIELD_TYPES.SELECT) {
      selectedOptions = _compact(
        _map(_get(propertyValuesFromPayload, FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS), (item) => getArraySafeValue(_get(item, COLUMNS.OPTION))),
      );
    }

    setState({
      fieldDataLoading: false,
      formMode: FORM_MODES.EDIT,
      approvalSetting,
      fieldData,
      formValues: {
        ...fieldData,
        ...valuesFromPayload,
        ...propertyValuesFromPayload,
        [FIELD_IDS.FIELD_TYPE]: _castArray(_get(fieldData, FIELD_IDS.FIELD_TYPE)),
        [FIELD_IDS.DATA_TYPE]: _castArray(_get(fieldData, FIELD_IDS.DATA_TYPE)),
        [FIELD_IDS.DEPENDENCY_CONFIG]: _map(_get(fieldData, FIELD_IDS.DEPENDENCY_CONFIG, EMPTY_ARRAY), (item) => ({
          fieldName: _castArray(_get(item, COLUMNS.FIELD_NAME)),
          dependencyType: _castArray(_get(item, COLUMNS.DEPENDENCY_TYPE)),
        })),
      },
      selectedFields: _compact(_map(_get(fieldData, FIELD_IDS.DEPENDENCY_CONFIG, (item) => _get(item, COLUMNS.FIELD_NAME)))),
      selectedOptions,
    });
  } else {
    setState({
      fieldDataLoading: false,
      formMode: FORM_MODES.CREATE,
      approvalSetting,
      formValues: {
        [FIELD_IDS.LIST_INPUT_TABLE]: [{ ...EMPTY_ROW }],
      },
    });
  }
};

const handleFieldChange = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { formValues, disableAutoFillForName = false, formMode } = getState();

  const { id, value } = params;

  let newValues = {
    ...formValues,
    [id]: value,
  };

  switch (id) {
    case FIELD_IDS.DISPLAY_NAME:
      if (!disableAutoFillForName && formMode === FORM_MODES.CREATE) {
        newValues = {
          ...newValues,
          [FIELD_IDS.NAME]: _snakeCase(value),
        };
      }
      break;
    case FIELD_IDS.NAME:
      setState({ disableAutoFillForName: true });
      break;
    case FIELD_IDS.DATA_TYPE:
      newValues = {
        ...newValues,
        [FIELD_IDS.FIELD_TYPE]: '',
      };
      break;
    case FIELD_IDS.LIST_ADD_ROW_BUTTON: {
      const tableData = _get(formValues, FIELD_IDS.LIST_INPUT_TABLE, EMPTY_ARRAY);
      newValues = { ...newValues, [FIELD_IDS.LIST_INPUT_TABLE]: [...tableData, { ...EMPTY_ROW }] };

      break;
    }
    default:
  }

  setState({ formValues: newValues });
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  setState({
    errors: params?.errors,
  });
};

const handleSubmit = async ({ getState, setState }) => {
  const { formMode, match, history, formValues, options } = getState();

  const settingName = match?.params?.settingName;
  const fieldName = match?.params?.fieldName;
  const payload = getPayloadFromValues(formValues, options);
  const fieldType = _head(_get(formValues, FIELD_IDS.FIELD_TYPE));

  let formIsValid = true;
  let formError = '';

  if (!_isEmpty(_get(formValues, FIELD_IDS.DEPENDENCY_CONFIG))) {
    const { valid, error } = validateConfigTable(_get(formValues, FIELD_IDS.DEPENDENCY_CONFIG));
    formIsValid = valid;
    formError = error;
  }

  if (fieldType === FIELD_TYPES.SELECT) {
    const { valid, error } = validateInputTable(_get(formValues, FIELD_IDS.LIST_INPUT_TABLE));
    formIsValid = formIsValid && valid;
    formError = `${formError}.${error}`;

    if (_get(formValues, FIELD_IDS.CONTROLLING_FUNCTION)) {
      const { valid: optionValid, error: optionError } = validateControllingOption(_get(formValues, FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS));
      formIsValid = formIsValid && optionValid;
      formError = `${formError}.${optionError}`;
    }
  }

  setState({ isSavingDetails: true });

  if (formIsValid) {
    let response = {};
    if (formMode === FORM_MODES.EDIT) {
      response = await updateFieldOfSetting(settingName, fieldName, payload);
    } else {
      response = await addFieldToSetting(settingName, payload);
    }

    if (!_isEmpty(response)) {
      setTimeout(() => {
        history.push(`${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_SETTING_DETAIL}/${settingName}`);
      }, ES_REFETCH_DELAY);
    } else {
      setState({ isSavingDetails: false });
    }
  } else {
    setState({ isSavingDetails: false });
    toaster(TOASTER_TYPE.ERROR, formError);
  }
};

const handleOnCancel = ({ setState, getState }) => {
  const { history, match } = getState();
  const settingName = match?.params?.settingName;

  setState({ formValues: {} });

  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_SETTING_DETAIL}/${settingName}`);
};

const handleOptionTableOnChange = ({ setState, params = EMPTY_OBJECT, getState }) => {
  const { id, value } = params;
  const { formValues, formMode } = getState();

  const row = _get(value, 'row');
  const fieldValue = _get(value, 'value');
  const tableData = _get(formValues, FIELD_IDS.LIST_INPUT_TABLE, EMPTY_ARRAY);

  if (id === COLUMN_IDS.LABEL) {
    tableData[row] = { ...tableData[row], label: fieldValue };
    if (!_get(tableData[row], 'valueChanged', false) && (formMode === FORM_MODES.CREATE || _get(tableData[row], 'isEditable', false))) {
      tableData[row] = { ...tableData[row], value: fieldValue };
    }
  } else {
    tableData[row] = { ...tableData[row], value: fieldValue, valueChanged: true };
  }

  setState({ formValues: { ...formValues, [FIELD_IDS.LIST_INPUT_TABLE]: [...tableData] } });
};

const handleOptionTableRemoveRow = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { formValues } = getState();
  const { row } = params;
  const tableData = _get(formValues, FIELD_IDS.LIST_INPUT_TABLE, EMPTY_ARRAY);

  _remove(tableData, (val, index) => index === row);

  setState({
    formValues: { ...formValues, [FIELD_IDS.LIST_INPUT_TABLE]: [...tableData] },
  });
};

const handleOptionTableAddRow = ({ getState, setState }) => {
  const { formValues } = getState();
  const tableData = _get(formValues, FIELD_IDS.LIST_INPUT_TABLE, EMPTY_ARRAY);
  const newTableData = [...tableData, { label: '', value: '', isEditable: true }];

  setState({ formValues: { ...formValues, [FIELD_IDS.LIST_INPUT_TABLE]: [...newTableData] } });
};

const handleConfigTableOnChange = ({ setState, params, getState }) => {
  const { id, configId } = params;
  const { formValues, selectedFields, selectedOptions } = getState();

  const row = _head(_get(params, 'nestingPath'));
  const fieldValue = _get(params, 'value');

  let newSelectedFields = selectedFields;
  let newSelectedOptions = selectedOptions;

  const tableData = _get(formValues, configId, EMPTY_ARRAY);
  tableData[row] = { ...tableData[row], [id]: fieldValue };

  if (configId === FIELD_IDS.DEPENDENCY_CONFIG && id === COLUMNS.FIELD_NAME) {
    newSelectedFields = _compact(_map(tableData, (item) => _head(_get(item, COLUMNS.FIELD_NAME))));
  }

  if (configId === FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS && id === COLUMNS.OPTION) {
    newSelectedOptions = _compact(_map(tableData, (item) => _head(_get(item, COLUMNS.OPTION))));
  }

  setState({ formValues: { ...formValues, [configId]: [...tableData] }, selectedFields: newSelectedFields, selectedOptions: newSelectedOptions });
};

const handleConfigTableRemoveRow = ({ setState, params, getState }) => {
  const { formValues, selectedFields, selectedOptions } = getState();
  const { row, configId } = params;
  let newSelectedFields = selectedFields;
  let newSelectedOptions = selectedOptions;

  const tableData = _get(formValues, configId, EMPTY_ARRAY);
  _remove(tableData, (val, index) => index === row);

  if (configId === FIELD_IDS.DEPENDENCY_CONFIG) {
    newSelectedFields = _compact(_map(tableData, (item) => _head(_get(item, COLUMNS.FIELD_NAME))));
  } else if (configId === FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS) {
    newSelectedOptions = _compact(_map(tableData, (item) => _head(_get(item, COLUMNS.OPTION))));
  }

  setState({
    formValues: { ...formValues, [configId]: [...tableData] },
    selectedFields: newSelectedFields,
    selectedOptions: newSelectedOptions,
  });
};

const handleConfigTableAddRow = ({ params, setState, getState }) => {
  const { formValues } = getState();
  const { configId } = params;
  const tableData = _get(formValues, configId, EMPTY_ARRAY);

  setState({ formValues: { ...formValues, [configId]: [...tableData, {}] } });
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL]: handleOnCancel,
  [ACTION_TYPES.INIT_FORM]: handleInitForm,
  [ACTION_TYPES.OPTION_TABLE_ON_CHANGE]: handleOptionTableOnChange,
  [ACTION_TYPES.OPTION_TABLE_REMOVE_ROW]: handleOptionTableRemoveRow,
  [ACTION_TYPES.OPTION_TABLE_ADD_ROW]: handleOptionTableAddRow,
  [ACTION_TYPES.CONFIG_TABLE_ON_CHANGE]: handleConfigTableOnChange,
  [ACTION_TYPES.CONFIG_TABLE_REMOVE_ROW]: handleConfigTableRemoveRow,
  [ACTION_TYPES.CONFIG_TABLE_ADD_ROW]: handleConfigTableAddRow,
};

export default ACTION_HANDLERS;
