import React, { useCallback, useMemo, useContext } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import _keyBy from 'lodash/keyBy';
import _split from 'lodash/split';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import _nth from 'lodash/nth';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { Col, Row } from '@tekion/tekion-components/molecules/Grid';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';

import ViewViewer from '../../organisms/viewViewer/ViewViewer';
import TagsRenderer from '../../atoms/tagsRenderer';
import MediaUploaderFormRenderer from '../../../mediaUploader/MediaUploaderFormRenderer';
import RichTextEditor from '../../../richTextEditor';

import ViewRendererContext from '../../../../context/viewRenderer.context';
import variableApiFactory from '../../../../factories/variableApi.Factory';

import Records from '../../../../actions/customCodeApis/customCode.RecordApi';
import Actions from '../../../../actions/customCodeApis/customCode.ActionApi';

import { getPlainTextFromHtml } from '../../helpers/viewBuilder.helper';
import { getFieldDefProperties, getRichTextEditorValue } from './detailViewAttributeRenderer.helpers';
import { entityRecordAccessor } from '../../helpers/entityRecordAccessor';
import { getParagraphText } from '../../../richTextEditor/helpers/richTextEditor.getPlainText';
import { convertEventHandlersFromArrayToObjectByEventName, executeEventFromEventViewConfigData } from '../../../../utils/eventHandlers';
import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';

import { MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT } from '../../../../constants/eventHandlers.constants';
import {
  DEFAULT_COLUMN_SPAN_VALUE,
  RICH_TEXT_EDITOR_STYLES,
  VIEW_CONTEXT_KEYS,
  COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP,
  COMPONENT_TYPES,
} from '../../constants/viewBuilder.constants';
import {
  VIEW_CONFIGURATION_PROPERTIES_ID,
  CELL_TYPES,
  CELL_VIEW_FIELD_IDS,
  VIEW_CONFIGURATION_GENERAL_KEYS,
  RENDERER_PROP_KEYS,
  ALL_VIEW_PROPERTY_KEYS,
} from '../../../../constants/viewBuilder.constants';
import { GALLERY_MODES } from '../../../mediaUploader/components/ImageThumbnails/imageThumbnail.constants';
import { EVENT_NAMES } from '../../../../constants/eventActions.constants';
import { CUSTOM_STYLE_IDS } from '../../../../constants/customStyles.constants';

import entityReader from '../../../../readers/entity.reader';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

import styles from './detailViewAttributeRenderer.module.scss';

const SUPPORTED_CUSTOM_STYLE_SECTION = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[COMPONENT_TYPES.DETAIL_VIEW_ATTRIBUTE];

const DetailViewAttributeRenderer = ({
  isPreviewMode,
  className,
  componentConfig,
  entity,
  entityRecord,
  viewConfigsByName,
  viewConfigurationsByName,
}) => {
  const { sectionId } = componentConfig;
  const properties = useMemo(() => _get(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT), [componentConfig]);

  const viewContextValue = useContext(ViewRendererContext);
  const widgetName = tget(viewContextValue, `${VIEW_CONTEXT_KEYS.WIDGET_CONTEXT}.widgetName`, EMPTY_STRING);

  const viewVariables = useMemo(() => tget(viewContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.viewVariables`, EMPTY_STRING), [viewContextValue]);
  const handleSetViewVariables = useMemo(
    () => tget(viewContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.handleSetViewVariables`, _noop),
    [viewContextValue],
  );
  const variablesApi = useMemo(() => variableApiFactory(viewVariables, handleSetViewVariables), [viewVariables, handleSetViewVariables]);

  const fieldDefinitionByName = useMemo(() => _keyBy(entityReader.fieldDefinitions(entity), 'name'), [entity]);
  const fieldNames = _get(properties, ALL_VIEW_PROPERTY_KEYS.FIELD_NAMES);

  const accessor = entityRecordAccessor(properties, fieldDefinitionByName);
  const fieldValue = accessor(entityRecord) || NO_DATA;
  const width = _get(properties, ALL_VIEW_PROPERTY_KEYS.WIDTH, 100);
  const keyColSpan = _get(properties, ALL_VIEW_PROPERTY_KEYS.KEY_SPAN, 3);
  const valueColSpan = _get(properties, ALL_VIEW_PROPERTY_KEYS.VALUE_SPAN, DEFAULT_COLUMN_SPAN_VALUE);
  const displayName = _get(properties, ALL_VIEW_PROPERTY_KEYS.TITLE);
  const isKeyVisible = _get(properties, ALL_VIEW_PROPERTY_KEYS.IS_KEY_VISIBLE, true);
  const cellType = _get(properties, CELL_VIEW_FIELD_IDS.CELL_TYPE);
  const cellViewName = _get(properties, [RENDERER_PROP_KEYS.CELL_VIEW_NAME]);

  const customStyles = useMemo(() => tget(properties, RENDERER_PROP_KEYS.CUSTOM_STYLES, {}), [properties]);
  const resolvedCustomStyles = useMemo(
    () => getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_SECTION, customStyles, entityRecord, variablesApi),
    [customStyles, entityRecord, variablesApi],
  );

  const isTruncatedRTE = _get(properties, ALL_VIEW_PROPERTY_KEYS.IS_RTE_TRUNCATED_TYPE);

  const renderRichTextEditor = useCallback(
    (isFieldComplex, isRelationshipField, field) => {
      let richTextEditorValue = fieldValue;

      if (isRelationshipField) {
        richTextEditorValue = _get(entityRecord, ['entity', field]);
      }

      if (isFieldComplex) {
        richTextEditorValue = _get(entityRecord, `entity.${field}`);
      }

      const { newFieldValue = undefined, isHtml = true } = getRichTextEditorValue(richTextEditorValue);
      richTextEditorValue = newFieldValue;

      if (isTruncatedRTE) {
        let truncatedValue;
        if (isHtml) {
          truncatedValue = getPlainTextFromHtml(richTextEditorValue);
        } else {
          truncatedValue = getParagraphText(richTextEditorValue);
        }
        return <div className={styles.truncatedText}>{truncatedValue}</div>;
      }
      if (richTextEditorValue === NO_DATA) {
        richTextEditorValue = undefined;
      }
      return (
        <RichTextEditor
          readOnly
          value={richTextEditorValue}
          editorId={_get(entityRecord, 'id', EMPTY_STRING)}
          className={`full-width overflow-auto ${styles.richTextEditorWrapper}`}
          editorStyles={{ padding: 0 }}
          toolbarStyles={RICH_TEXT_EDITOR_STYLES}
        />
      );
    },
    [fieldValue, isTruncatedRTE, entityRecord],
  );

  const renderMedia = useCallback(
    (field, isMultiValued, isRelationshipField, isFieldComplex) => {
      let newFieldValue = fieldValue;
      let fieldDef = _get(fieldDefinitionByName, field);
      let entityRecordId = _get(entityRecord, 'id');

      if (fieldValue === NO_DATA) {
        newFieldValue = undefined;
      }

      if (isRelationshipField) {
        newFieldValue = _get(entityRecord, ['entity', field]);

        const splitFieldName = _split(field, '.');
        const parentFieldName = _nth(splitFieldName, 0);
        const childFieldName = _nth(splitFieldName, 1);

        const parentFieldDef = _get(fieldDefinitionByName, parentFieldName);
        const relationalFieldDefs = fieldDefinitionReader.relationshipEntityFieldDefinitions(parentFieldDef);
        fieldDef = _get(relationalFieldDefs, childFieldName);
        entityRecordId = _get(entityRecord, ['entity', `${parentFieldName}.id`]);
      }

      if (isFieldComplex) {
        newFieldValue = _get(entityRecord, `entity.${field}`);
      }

      newFieldValue = _split(newFieldValue, ',');
      const isAvatarDisplay = _get(properties, ALL_VIEW_PROPERTY_KEYS.IS_AVATAR_VIEW, false);

      return (
        <MediaUploaderFormRenderer
          viewOnly
          isAvatarDisplay={isAvatarDisplay}
          showRefreshIcon={false}
          multiple={isMultiValued}
          recordId={entityRecordId}
          fieldDef={fieldDef}
          value={newFieldValue}
          mode={GALLERY_MODES.VIEW_ONLY}
          accept=".jpg,.png,.jpeg"
          displayCount={4}
          avatarDisplayName={fieldValue}
        />
      );
    },
    [entityRecord, fieldValue, properties, fieldDefinitionByName],
  );

  const handleClick = useCallback(
    ({ isEntityRecordOverridden = false, overriddenEntityRecordValue = entityRecord }) => {
      const eventHandlers = tget(properties, VIEW_CONFIGURATION_PROPERTIES_ID.EVENT_HANDLERS, EMPTY_ARRAY);
      const modifiedEventHandlers = convertEventHandlersFromArrayToObjectByEventName(eventHandlers);

      const onClickEventData = tget(modifiedEventHandlers, EVENT_NAMES.CLICK, EMPTY_OBJECT);

      let updatedEntityRecord = entityRecord;
      if (isEntityRecordOverridden) {
        updatedEntityRecord = overriddenEntityRecordValue;
      }

      const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$RECORD]: entityRecord,
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RECORDS]: Records,
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.ACTIONS]: Actions,
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.VARIABLES]: variablesApi,
      };

      executeEventFromEventViewConfigData(onClickEventData, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE, updatedEntityRecord, widgetName);
    },
    [widgetName, properties, entityRecord, variablesApi],
  );

  const handleTagClick = useCallback(
    (event, fieldName, index) => {
      event.stopPropagation();

      const splitFieldName = _split(fieldName, '.');
      const firstFieldName = getArraySafeValue(splitFieldName);
      const updatedEntityRecord = { ...entityRecord, id: tget(entityRecord, ['entity', `${firstFieldName}.id`, `${index}`], EMPTY_STRING) };

      handleClick({ isEntityRecordOverridden: true, overriddenEntityRecordValue: updatedEntityRecord });
    },
    [entityRecord, handleClick],
  );

  if (cellType === CELL_TYPES.COMPOUND && cellViewName) {
    return (
      <div>
        <ViewViewer
          viewConfigName={cellViewName}
          viewConfiguration={tget(viewConfigsByName, [cellViewName], tget(viewConfigurationsByName, [cellViewName], EMPTY_OBJECT))}
          entity={entity}
          entityRecord={entityRecord}
        />
      </div>
    );
  }

  return (
    <Row
      key={sectionId}
      style={{ width: `${width}%`, ...tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.ROW, {}) }}
      className={cx(styles.detailViewAttributeRenderer, className)}
      onClick={isPreviewMode ? handleClick : _noop}
    >
      <PropertyControlledComponent controllerProperty={isKeyVisible}>
        <Col span={keyColSpan} className={styles.keyContainer}>
          <div className={styles.key}>
            <div className={styles.text}>{__(displayName)}</div>
          </div>
        </Col>
        <Col span={1} className={styles.delimiter}>
          :
        </Col>
      </PropertyControlledComponent>
      <Col span={valueColSpan} className={styles.valueContainer}>
        {_map(fieldNames, (field) => {
          const { isFieldComplex, isMultiValued, isRichTextEditorField, isMediaField, isRelationshipField } = getFieldDefProperties(
            field,
            fieldDefinitionByName,
          );

          if (isRichTextEditorField) {
            return renderRichTextEditor(isFieldComplex, isRelationshipField, field);
          } else if (isMediaField) {
            return renderMedia(field, isMultiValued, isRelationshipField, isFieldComplex);
          } else if (isMultiValued && !isRichTextEditorField && !isMediaField) {
            return (
              <TagsRenderer
                style={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTENT, EMPTY_OBJECT)}
                maxLimit={3}
                fieldValue={fieldValue}
                onTagClick={(event, index) => handleTagClick(event, field, index)}
              />
            );
          }

          return (
            <div key={displayName} className={styles.value}>
              <span style={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTENT, EMPTY_OBJECT)}>{__(fieldValue)}</span>
            </div>
          );
        })}
      </Col>
    </Row>
  );
};

DetailViewAttributeRenderer.propTypes = {
  isPreviewMode: PropTypes.bool,
  className: PropTypes.string,
  entity: PropTypes.object,
  entityRecord: PropTypes.object,
  viewConfigsByName: PropTypes.object,
  viewConfigurationsByName: PropTypes.object,
  componentConfig: PropTypes.object.isRequired,
};

DetailViewAttributeRenderer.defaultProps = {
  isPreviewMode: true,
  className: '',
  entity: undefined,
  viewConfigsByName: EMPTY_OBJECT,
  viewConfigurationsByName: EMPTY_OBJECT,
  entityRecord: undefined,
};

export default DetailViewAttributeRenderer;
// TODO test memo
