import _get from 'lodash/get';

import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import { COLUMN_IDS } from './fieldListTable.constant';
import { FORM_MODES } from '../../../../../../../constants/general.constants';

const COLUMNS = [
  {
    Header: __('Name'),
    accessor: COLUMN_IDS.NAME,
    id: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Display Name'),
    accessor: COLUMN_IDS.DISPLAY_NAME,
    id: COLUMN_IDS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Description'),
    accessor: COLUMN_IDS.FIELD_TYPE,
    id: COLUMN_IDS.FIELD_TYPE,
    Cell: TextRenderer,
  },
];

const TABLE_MANAGER_PROPS = {
  showSearch: false,
  showFilter: false,
  showHeader: false,
  showSubHeader: false,
  showRefreshIcon: false,
};

const DELETE_ACTION = {
  id: 'DELETE',
  name: __('Delete'),
};

const OVERRIDE_ACTION = {
  id: 'OVERRIDE',
  name: __('Override'),
};

const getRow = (rowData, formMode, derivationFields) => {
  if (derivationFields.includes(_get(rowData, 'name'))) return [];
  const ACTIONS = formMode === FORM_MODES.CREATE ? [DELETE_ACTION] : [DELETE_ACTION, OVERRIDE_ACTION];
  return ACTIONS;
};

const getTableProps = (handleRowAction, formMode, derivationFields) => ({
  minRows: 0,
  showPagination: false,
  showActions: true,
  onRowActionClick: handleRowAction,
  getRowActions: (rowData) => getRow(rowData, formMode, derivationFields),
});

export { COLUMNS, TABLE_MANAGER_PROPS, getTableProps };
