import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _replace from 'lodash/replace';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT, EMPTY_STRING, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import IconAsBtn from '@tekion/tekion-components/atoms/iconAsBtn';
import { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Popover, { POPOVER_TRIGGER } from '@tekion/tekion-components/molecules/popover';

import { useValidationContext } from '@tekion/tekion-rule-engine/src/contexts/ValidationContext';

import styles from './notificationPanel.module.scss';

const NotificationPanel = ({ cardData }) => {
  const nodeId = _get(cardData, 'nodeId');
  const { errorsByNodeId = EMPTY_OBJECT } = useValidationContext();
  const errors = _get(errorsByNodeId, `[${nodeId}].errors`, EMPTY_ARRAY);

  const errorIcon = useMemo(() => {
    if (_isEmpty(errors)) return null;

    const popoverContent = (
      <div className={styles.errorMessageContainer}>
        {_map(errors, (error) => (
          <div key={_replace(error.message, /\s/g, EMPTY_STRING)}>{error.message}</div>
        ))}
      </div>
    );

    const popoverProps = {
      content: popoverContent,
      trigger: POPOVER_TRIGGER.HOVER,
    };

    return (
      <Popover {...popoverProps}>
        <IconAsBtn size={SIZES.MD} containerClassName="m-auto" className={styles.errorIcon}>
          icon-alert1
        </IconAsBtn>
      </Popover>
    );
  }, [errors]);

  return errorIcon;
};

NotificationPanel.propTypes = {
  cardData: PropTypes.object,
};

NotificationPanel.defaultProps = {
  cardData: EMPTY_OBJECT,
};

export default memo(NotificationPanel);
