import { defaultMemoize } from 'reselect';
import _map from 'lodash/map';

import Button from '@tekion/tekion-components/atoms/Button';
import Heading from '@tekion/tekion-components/atoms/Heading';

import ACTION_TYPES from '../constants/userGroupsSetting.actionTypes';
import { REMOVE_GROUP_ACTION } from '../constants/userGroupSetting.rowActions';

const createTableProps = defaultMemoize((isLoading, handleRowAction) => ({
  loading: isLoading,
  rowHeight: 35,
  minRows: 0,
  showPagination: false,
  showActions: true,
  onRowActionClick: handleRowAction,
  getRowActions: () => [REMOVE_GROUP_ACTION],
}));

const createAssignmentPayload = defaultMemoize((userGroupNames, permissionName) =>
  _map(userGroupNames, (userGroupName) => ({
    principalIdentifier: userGroupName,
    principalType: 'GROUP',
    permissionSetNames: [permissionName],
    name: `g_${permissionName}_${userGroupName}`,
    displayName: `g_${userGroupName}`,
  })),
);

const createSearchPayload = defaultMemoize((permissionName) => ({
  filters: [
    {
      field: 'permissionSetNames',
      filterType: 'IN',
      values: [permissionName],
    },
    {
      field: 'principalType',
      filterType: 'IN',
      values: ['GROUP'],
    },
  ],
}));

const createUserGroupSearchPayload = defaultMemoize((userGroupIds) => ({ filters: [{ field: 'id', filterType: 'IN', values: userGroupIds }] }));

const getSubHeaderProps = defaultMemoize((count) => ({
  subHeaderLeftActions: [
    {
      renderer: Heading,
      renderOptions: {
        children: __(`Assigned user groups (${count})`),
        size: 2,
      },
    },
  ],
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Assign Group'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.ASSIGN_USER_GROUP_CLICK,
    },
  ],
}));

export { createTableProps, createAssignmentPayload, getSubHeaderProps, createSearchPayload, createUserGroupSearchPayload };
