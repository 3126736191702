const ACTION_TYPES = {
  ON_COMPONENT_DROP: 'ON_COMPONENT_DROP',
  ON_COMPONENT_SELECT: 'ON_COMPONENT_SELECT',
  ON_COMPONENT_DELETE: 'ON_COMPONENT_DELETE',
  ON_COMPONENT_EDIT_CLICK: 'ON_COMPONENT_EDIT_CLICK',
  ON_PREVIEW_CLICK: 'ON_PREVIEW_CLICK',
  ON_PREVIEW_MODAL_CLOSE: 'ON_PREVIEW_MODAL_CLOSE',
  ON_CONFIGURATOR_ACTION: 'ON_CONFIGURATOR_ACTION',
  ON_COMPOUND_CONFIG_MODAL_SAVE: 'ON_COMPOUND_CONFIG_MODAL_SAVE',
  ON_COMPOUND_CONFIG_MODAL_CANCEL: 'ON_COMPOUND_CONFIG_MODAL_CANCEL',
  ON_INITIAL_VIEW_CONFIGURATION_CHANGE: 'ON_INITIAL_VIEW_CONFIGURATION_CHANGE',
  ON_TREE_NODE_CLICK: 'ON_TREE_NODE_CLICK',
  ON_INIT: 'ON_INIT',
  ON_UNDO_REDO: 'ON_UNDO_REDO',
  ON_VIEW_TREE_STRUCTURE_CLICK: 'ON_VIEW_TREE_STRUCTURE_CLICK',
};

export default ACTION_TYPES;
