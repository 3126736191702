import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactSelect from 'react-select';
import { compose } from 'recompose';

import _get from 'lodash/get';
import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

import { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { VirtualizedMultiAsync } from '@tekion/tekion-components/molecules/virtualizedMultiSelect';
import withFetchAsyncResource from '@tekion/tekion-components/molecules/advancedSelect/containers/withAsyncResource/withFetchAsyncResource';
import WithAsyncSelectHOC from '@tekion/tekion-components/molecules/advancedSelect/containers/withAsyncSelect';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import Error from '@tekion/tekion-components/organisms/FormBuilder/components/error';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import { ASYNC_PAGINATED_RESOURCE_TYPE, RESOURCE_PARAMS, SELECT_STYLE } from './asyncPaginatedSelect.constants';

import styles from './asyncPaginatedSelect.module.scss';

const AsyncSelect = compose(withFetchAsyncResource, WithAsyncSelectHOC)(ReactSelect);

const AsyncPaginatedSelect = React.forwardRef(
  (
    {
      isMulti,
      id,
      fieldClassName,
      error,
      value,
      serviceHandler,
      getPayload,
      getPayloadForInitialFetch,
      getOptions,
      getLoadingMessage,
      setRef,
      onAction,
      ...restProps
    },
    ref,
  ) => {
    const [nextPageToken, setNextPageToken] = useState(EMPTY_STRING);

    const handleChange = useCallback(
      ({ value: newValue, option }) => {
        onAction({
          type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
          payload: { id, value: isMulti ? newValue : getArraySafeValue(newValue), selectedOption: option },
        });
      },
      [id, isMulti, onAction],
    );

    const handleLoadOptions = useCallback(
      async (_, payload) => {
        const searchText = tget(payload, 'searchQuery');
        const start = tget(payload, 'pageInfo.start');
        let apiPayload = EMPTY_OBJECT;

        if (start === 0) {
          setNextPageToken(EMPTY_STRING);
          apiPayload = getPayload({ nextPageToken: EMPTY_STRING, searchText });
        } else {
          apiPayload = getPayload({ nextPageToken, searchText });
        }

        const data = await serviceHandler(apiPayload);
        const hits = tget(data, 'hits', EMPTY_ARRAY);
        const currentNextPageToken = _get(data, 'nextPageToken');
        const newOptions = getOptions(hits);
        const hasMore = !_isEmpty(currentNextPageToken);

        setNextPageToken(currentNextPageToken);

        return { resources: newOptions, hasMore };
      },
      [serviceHandler, getOptions, getPayload, nextPageToken],
    );

    const handleInitValueFetch = useCallback(
      async (_, selectedValues) => {
        const apiPayload = getPayloadForInitialFetch(selectedValues);

        const data = await serviceHandler(apiPayload);

        const hits = tget(data, 'hits', EMPTY_ARRAY);

        const options = getOptions(hits);

        return options;
      },
      [getOptions, getPayloadForInitialFetch, serviceHandler],
    );

    const Component = useMemo(() => (isMulti ? VirtualizedMultiAsync : AsyncSelect), [isMulti]);

    return (
      <div key={id} className={cx(fieldLayoutStyles.fieldC, fieldClassName)}>
        <FieldLabel {...restProps} />
        <div
          className={cx(styles.field, {
            [styles.multiSelect]: isMulti,
          })}
        >
          <Component
            {...restProps}
            childRef={setRef || ref}
            refreshOptions
            openMenuOnFocus
            isAsyncInputClearable
            closeMenuOnSelect={!isMulti}
            hideSelectedOptions={false}
            isMulti={isMulti}
            isSearchable
            styles={SELECT_STYLE}
            menuPosition="fixed"
            resourceType={ASYNC_PAGINATED_RESOURCE_TYPE}
            value={_castArray(value)}
            resourceParams={RESOURCE_PARAMS}
            lookUpSearchApi={handleLoadOptions}
            lookupByKeysApi={handleInitValueFetch}
            onChange={handleChange}
            loadingMessage={getLoadingMessage}
          />
        </div>
        <Error error={error} />
      </div>
    );
  },
);

AsyncPaginatedSelect.propTypes = {
  isMulti: PropTypes.bool,
  isCreateOptionEnabled: PropTypes.bool,
  createOptionLabel: PropTypes.string,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  fieldClassName: PropTypes.string,
  error: PropTypes.string,
  additionalPropForPayload: PropTypes.object,
  dependentPropForPayload: PropTypes.object,
  value: PropTypes.array,
  setRef: PropTypes.func,
  serviceHandler: PropTypes.func,
  getPayload: PropTypes.func,
  getPayloadForInitialFetch: PropTypes.func,
  getOptions: PropTypes.func,
  getLoadingMessage: PropTypes.func,
  onAction: PropTypes.func,
};

AsyncPaginatedSelect.defaultProps = {
  isMulti: false,
  isCreateOptionEnabled: false,
  createOptionLabel: undefined,
  className: EMPTY_STRING,
  fieldClassName: EMPTY_STRING,
  error: EMPTY_STRING,
  additionalPropForPayload: EMPTY_OBJECT,
  dependentPropForPayload: EMPTY_OBJECT,
  value: EMPTY_ARRAY,
  setRef: undefined,
  serviceHandler: _noop,
  getPayload: _noop,
  getOptions: _noop,
  getPayloadForInitialFetch: _noop,
  getLoadingMessage: () => __('Searching...'),
  onAction: _noop,
};

export default AsyncPaginatedSelect;
