import _reduce from 'lodash/reduce';
import _size from 'lodash/size';
import { Path } from 'slate';
import { insertNodes, someNode, getAbove } from '@udecode/plate-common';
import { ELEMENT_TABLE, ELEMENT_TR } from '@udecode/plate-table';
import { getPlatePluginType } from '@udecode/plate-core';
import { createEmptyRowNode } from './richTextEditor.tableGeneral';

const addColumnId = (acc, cellNode) => {
  acc.push(cellNode?.columnId);
  return acc;
};

const getEmptyRowNode = ({ editor, currentRowElem }) => {
  const { children: tableCellNodes } = currentRowElem;
  const columnIds = _reduce(tableCellNodes, addColumnId, []);
  const rowNode = createEmptyRowNode({
    editor,
    columnCount: _size(tableCellNodes),
    columnIds,
  });
  return rowNode;
};

const insertRow = (editor) => {
  if (
    someNode(editor, {
      match: { type: getPlatePluginType(editor, ELEMENT_TABLE) },
    })
  ) {
    const currentRowItem = getAbove(editor, {
      match: { type: getPlatePluginType(editor, ELEMENT_TR) },
    });
    if (currentRowItem) {
      const [currentRowElem, currentRowPath] = currentRowItem;
      const rowNode = getEmptyRowNode({
        currentRowElem,
        editor,
      });
      insertNodes(editor, rowNode, {
        at: Path.next(currentRowPath),
        select: true,
      });
    }
  }
};

export default insertRow;
