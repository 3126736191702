import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';

import { COLUMNS_IDS } from '../helpers/appList.columns';

const FILTER_ID = {
  NAVIGATION_TYPE_ID: 'navigationInfo.navigationType',
};

const NAVIGATION_TYPE_FILTER = {
  id: FILTER_ID.NAVIGATION_TYPE_ID,
  key: COLUMNS_IDS.NAVIGATION_TYPE,
  type: FILTER_TYPES.SINGLE_SELECT,
  name: __('Navigation Type'),
};
const DEFAULT_FILTERS = [NAVIGATION_TYPE_FILTER.id];
const DEFAULT_FILTER_GROUP = 'DEFAULT_FILTER_GROUP';

export { NAVIGATION_TYPE_FILTER, DEFAULT_FILTERS, DEFAULT_FILTER_GROUP };
