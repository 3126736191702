import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import { uuid } from '@tekion/tekion-base/utils/general';
import ApiService from '../services/apiService/apiService';

const getURIBlob = (url) =>
  ApiService.getMedia(
    url,

    {
      'Cache-Control': 'max-age=0',
    },
    'blob',
  );

const downloadURI = (url, name) =>
  getURIBlob(url).then((_ref) => {
    const blob = _ref.data;
    const type = _get(blob, 'type', '') || '';
    const fileType = _last(type.split('/')) || '';
    const fileName = !_isEmpty(name) ? name : ''.concat(uuid(), '.').concat(fileType);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    link.click();
  });

export { downloadURI, getURIBlob };
