import React from 'react';
import { notification } from 'antd';
import _forEach from 'lodash/forEach';

import RichTextEditor from '../../../richTextEditor';
import communicationsActions from '../../../../actions/communications.actions';
import { getCustomEntityNotificationFields, getDefaultNotificationFields } from './helpers/notification.eventHelpers';

import { PUSH_NOTIFICATION_EVENT_TYPES } from '../../../../constants/notification.constants';

import styles from './notification.module.scss';

// This may cause issue when we will have more than one application
const handleNotificationClick = (notificationId, status, notificationRoute) => async () => {
  // Need to await as we need to wait for the API call to complete before redirecting. otherwise call gets cancelled
  await communicationsActions.markNotificationAsRead(notificationId, status);

  window.location.href = notificationRoute;
};

const customEntityEventHandler = (notificationEvent) => {
  const { id, subject, body, applications, entityName, recordId, status } = getCustomEntityNotificationFields(notificationEvent);

  // Ideally, send notification nodes will be configured in workflow and contain only one application.
  _forEach(applications, (application) => {
    const notificationRoute = `notification?application=${application}&entity=${entityName}&recordId=${recordId}`;

    notification.open({
      key: id,
      duration: null,
      placement: 'topRight',
      message: subject,
      className: styles.notificationToaster,
      description: <RichTextEditor readOnly value={body} editorId={id} editorStyles={{ padding: 0 }} />,
      onClick: handleNotificationClick(id, status, notificationRoute),
    });
  });
};

const defaultPushNotificationHandler = (notificationEvent) => {
  const { id, subject, body } = getDefaultNotificationFields(notificationEvent);

  // Since no handler was found, we will just display the event, and not support on click on unrecognised event.
  // If click needs to be handled, do have an event handler added.
  notification.open({
    key: id,
    duration: 15,
    placement: 'topRight',
    message: subject,
    description: <RichTextEditor readOnly value={body} editorId={id} editorStyles={{ padding: 0 }} />,
    className: styles.notificationToaster,
  });
  // notification.config({
  //   placement,
  //   bottom,
  //   duration,
  // });
};

const PUSH_NOTIFICATION_HANDLERS = {
  [PUSH_NOTIFICATION_EVENT_TYPES.CUSTOM_ENTITY]: customEntityEventHandler,
};

export { defaultPushNotificationHandler };

export default PUSH_NOTIFICATION_HANDLERS;
