import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _isFunction from 'lodash/isFunction';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _last from 'lodash/last';
import _split from 'lodash/split';
import _reduce from 'lodash/reduce';
import _unset from 'lodash/unset';

import { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Components
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import Button from '@tekion/tekion-components/atoms/Button';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import NullComponent from '@tekion/tekion-components/atoms/nullComponent/NullComponent';
import Info from '@tekion/tekion-components/molecules/Info';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import Popover, { POPOVER_PLACEMENT, POPOVER_TRIGGER } from '@tekion/tekion-components/molecules/popover';
import AttributeSelect from './AttributeSelectField';

// Helpers
import { getFieldProps, getRenderer as defaultGetRenderer, isAttribute, getValueFieldDef } from './valueField.helpers';
import { getResourceOptions } from '../resourceField/resourceField.helpers';

// Constants
import CONDITION_BUILDER_MODES from '../../constants/conditionBuilder.modes';
import CONDITION_FIELD_IDS from '../../constants/condition.fieldIds';
import { MODE_SWITCHER_BUTTON_TEXTS, MODE_SWITCHER_PLACEHOLDERS, VALUE_FIELD_MODES } from './valueField.constants';
import { CURRENT_ENTITY_NAMESPACE } from '../../constants/conditionBuilder.general';
import { OPERATORS, OPERATORS_WITH_VALUE_NULL } from '../operatorField/operatorField.constants';

// Styles
import styles from './valueField.module.scss';

const ValueField = ({
  id,
  isDisabled,
  isVariablePrefixNeeded,
  isRelationshipRecursive,
  isComplexRecursive,
  isResourceFieldExcluded,
  showFieldSwitcher,
  required,
  keepAttributeAsArray,
  conditionBuilderMode,
  initialFieldSwitcherMode,
  label,
  fieldClassName,
  helpText,
  variableNameForPrefixNotNeeded,
  error,
  value,
  mapOfVariableToEntityName,
  conditionBuilderFieldDefinitionObject,
  attributeExcludeVariableNames,
  values,
  fieldRenderer,
  getRenderer,
  onAction,
}) => {
  const operatorValue = values?.[CONDITION_FIELD_IDS.OPERATOR];

  const [mode, setMode] = useState(() => {
    if (!_isEmpty(initialFieldSwitcherMode)) {
      return initialFieldSwitcherMode;
    }
    if (isAttribute(value, mapOfVariableToEntityName)) {
      return VALUE_FIELD_MODES.FIELD;
    }
    return VALUE_FIELD_MODES.STATIC_VALUE;
  }); // Value field Mode - select a field or enter static value.

  const buttonText = MODE_SWITCHER_BUTTON_TEXTS[mode] || MODE_SWITCHER_BUTTON_TEXTS.STATIC_VALUE;

  const onModeButtonClick = useCallback(() => {
    setMode((currentMode) => {
      if (currentMode === VALUE_FIELD_MODES.FIELD) {
        return VALUE_FIELD_MODES.STATIC_VALUE;
      }
      return VALUE_FIELD_MODES.FIELD;
    });
    onAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: {
        id,
        value: undefined,
      },
    });
  }, [id, onAction]);

  const fieldDef = useMemo(
    () =>
      getValueFieldDef({
        values,
        mapOfVariableToEntityName,
        conditionBuilderFieldDefinitionObject,
        isVariablePrefixNeeded,
        variableNameForPrefixNotNeeded,
      }),
    [values, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject, isVariablePrefixNeeded, variableNameForPrefixNotNeeded],
  );

  const fieldProps = useMemo(
    () => getFieldProps(fieldDef, mode, operatorValue, conditionBuilderMode),
    [mode, fieldDef, operatorValue, conditionBuilderMode],
  );

  let Field = fieldRenderer;
  if (!_isEmpty(fieldDef) && _isFunction(getRenderer)) {
    Field = getRenderer(fieldDef, operatorValue, conditionBuilderMode);
  }

  const renderPopOverContent = useCallback(() => <div>{__('Please press enter to save values')}</div>, []);

  const attributeOptions = useMemo(() => {
    if (mode === VALUE_FIELD_MODES.FIELD) {
      const resourceFieldValue = _get(values, CONDITION_FIELD_IDS.FIELD, EMPTY_ARRAY);
      const lastFieldName = _last(_split(resourceFieldValue, '.'));
      const attributeMapOfVariableToEntityName = _reduce(
        attributeExcludeVariableNames,
        (ans, variableName) => {
          _unset(ans, variableName);
          return ans;
        },
        { ...mapOfVariableToEntityName },
      );
      return getResourceOptions({
        value,
        mapOfVariableToEntityName: attributeMapOfVariableToEntityName,
        conditionBuilderFieldDefinitionObject,
        isRelationshipRecursive,
        isComplexRecursive,
        isVariablePrefixNeeded: true,
        isExcludedFieldName: isResourceFieldExcluded,
        excludeFieldName: lastFieldName,
      });
    }
    return EMPTY_ARRAY;
  }, [
    mode,
    value,
    values,
    mapOfVariableToEntityName,
    conditionBuilderFieldDefinitionObject,
    isRelationshipRecursive,
    isComplexRecursive,
    isResourceFieldExcluded,
    attributeExcludeVariableNames,
  ]);

  if (_includes(OPERATORS_WITH_VALUE_NULL, operatorValue)) {
    return <NullComponent />;
  }

  return (
    <div className={fieldClassName}>
      <div className={`${styles.labelButtonContainer}`}>
        <div className={styles.labelContainer}>
          <FieldLabel label={label} />
          {(operatorValue === OPERATORS.IN.value || operatorValue === OPERATORS.NOT_IN.value) && (
            <Popover
              arrowPointAtCenter
              destroyTooltipOnHidecontent
              trigger={POPOVER_TRIGGER.HOVER}
              placement={POPOVER_PLACEMENT.RIGHT}
              overlayClassName={styles.valueFieldInfo}
              content={renderPopOverContent()}
            >
              <span className={styles.valueInfoIcon}>
                <FontIcon>icon-info</FontIcon>
              </span>
            </Popover>
          )}
          {helpText && <Info helpText={helpText} />}
        </div>
        {showFieldSwitcher && (
          <Button highlightOnHover={false} disabled={isDisabled} view={Button.VIEW.TERTIARY} onClick={onModeButtonClick}>
            {buttonText}
          </Button>
        )}
      </div>
      {mode === VALUE_FIELD_MODES.STATIC_VALUE ? (
        <Field
          required={required}
          id={id}
          {...fieldProps}
          disabled={isDisabled}
          className={styles.customSelect}
          placeholder={MODE_SWITCHER_PLACEHOLDERS[mode]}
          value={value}
          error={error}
          onAction={onAction}
        />
      ) : (
        <AttributeSelect
          isClearable
          hideSelectedOptions
          isDisabled={isDisabled}
          closeMenuOnSelect={false}
          required={required}
          id={id}
          keepAttributeAsArray={keepAttributeAsArray}
          placeholder={MODE_SWITCHER_PLACEHOLDERS[mode]}
          value={value}
          error={error}
          options={attributeOptions}
          onAction={onAction}
        />
      )}
    </div>
  );
};

ValueField.propTypes = {
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isVariablePrefixNeeded: PropTypes.bool,
  showFieldSwitcher: PropTypes.bool,
  isRelationshipRecursive: PropTypes.bool,
  isComplexRecursive: PropTypes.bool,
  isResourceFieldExcluded: PropTypes.bool,
  keepAttributeAsArray: PropTypes.bool,
  conditionBuilderMode: PropTypes.oneOf([CONDITION_BUILDER_MODES.ACTION_MODE, CONDITION_BUILDER_MODES.CONDITION_MODE]),
  initialFieldSwitcherMode: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  fieldClassName: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  variableNameForPrefixNotNeeded: PropTypes.string,
  mapOfVariableToEntityName: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  values: PropTypes.object,
  attributeExcludeVariableNames: PropTypes.array,
  fieldRenderer: PropTypes.element,
  getRenderer: PropTypes.func,
  onAction: PropTypes.func.isRequired,
};

ValueField.defaultProps = {
  required: false,
  isDisabled: false,
  showFieldSwitcher: false,
  isVariablePrefixNeeded: true,
  isRelationshipRecursive: true,
  isComplexRecursive: true,
  isResourceFieldExcluded: true,
  keepAttributeAsArray: false,
  conditionBuilderMode: CONDITION_BUILDER_MODES.CONDITION_MODE,
  initialFieldSwitcherMode: EMPTY_STRING,
  helpText: EMPTY_STRING,
  label: '',
  fieldClassName: '',
  value: '',
  error: '',
  variableNameForPrefixNotNeeded: CURRENT_ENTITY_NAMESPACE,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  values: EMPTY_OBJECT,
  attributeExcludeVariableNames: EMPTY_ARRAY,
  fieldRenderer: TextInput,
  getRenderer: defaultGetRenderer,
};

export default ValueField;
