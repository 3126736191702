import styles from './conditionModal.module.scss';

const FIELD_IDS = {
  CONDITION: 'condition',
};

const SECTIONS = [
  {
    className: styles.fullWidthSection,
    rows: [{ columns: [FIELD_IDS.CONDITION] }],
  },
];

const CHECK_FIELD_OPERATOR_OPTIONS = [
  {
    label: __('is any of'),
    value: 'IN',
  },
];

const CHILD_LABELS = {
  TRUE: 'true',
  FALSE: 'false',
};

export { FIELD_IDS, SECTIONS, CHECK_FIELD_OPERATOR_OPTIONS, CHILD_LABELS };
