import produce from 'immer';

import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _size from 'lodash/size';

import {
  VIEW_COMPONENT_UPDATE_ACTION_TYPE,
  VIEW_COMPONENT_UPDATE_ACTION_ID,
  PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS,
} from './propertiesForm.constants';
import { ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_FIELD_IDS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

// This function is used to update properties inside view component and will return updated value of selected view component
// propertiesValueToUpdate is array of object where object has the following structure
// { propertyName, propertyValue }
// So this can used for updating multiple properties of view component as well
const updatePropertiesValueForViewComponent = (selectedViewComponent, propertiesValueToUpdate) => {
  const newSelectedViewComponent = produce(selectedViewComponent, (draft) => {
    _forEach(propertiesValueToUpdate, (value) => {
      const propertyName = _get(value, PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_NAME);
      const propertyValue = _get(value, PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_VALUE);

      _set(draft, `${VIEW_CONFIGURATION_FIELD_IDS.PROPERTIES}.${propertyName}`, propertyValue);
    });
  });

  return newSelectedViewComponent;
};

const updateChildrenForViewComponent = (selectedViewComponent, children) => {
  const newSelectedViewComponent = produce(selectedViewComponent, (draft) => {
    _set(draft, VIEW_CONFIGURATION_GENERAL_KEYS.CHILDREN, children);

    _set(draft, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.COLUMN_COUNT}`, _size(children));
  });

  return newSelectedViewComponent;
};

// action object will contain actionType and payload
const updateSelectedViewComponent = (selectedViewComponent, action) => {
  const actionType = _get(action, VIEW_COMPONENT_UPDATE_ACTION_ID.ACTION_TYPE);

  const payload = _get(action, VIEW_COMPONENT_UPDATE_ACTION_ID.PAYLOAD);

  switch (actionType) {
    case VIEW_COMPONENT_UPDATE_ACTION_TYPE.PROPERTIES_UPDATE: {
      return updatePropertiesValueForViewComponent(selectedViewComponent, payload);
    }

    case VIEW_COMPONENT_UPDATE_ACTION_TYPE.CHILDREN_UPDATE: {
      return updateChildrenForViewComponent(selectedViewComponent, payload);
    }

    default: {
      return selectedViewComponent;
    }
  }
};

export { updateSelectedViewComponent };
