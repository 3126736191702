import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Tabs from '@tekion/tekion-components/molecules/Tabs';
import { tget } from '@tekion/tekion-base/utils/general';

import WorkflowList from './components/workflowList/WorkflowList';

import ACTION_HANDLERS from './helpers/workflowBuilderList.actionHandlers';

import { TAB_IDS, TAB_NAMES_BY_TAB_ID } from './constants/workflowBuilderList.constants';
import { WORKFLOW_BUILDER_MODE } from '../../../../../constants/general.constants';
import ACTION_TYPES from './constants/workflowBuilderList.actionTypes';

const WorkflowBuilderList = ({ history, match, onAction }) => {
  const { tabId } = tget(match, 'params', EMPTY_OBJECT);

  const handleTabChange = useCallback(
    (newTabId) => {
      onAction({ type: ACTION_TYPES.ON_TAB_CHANGE, payload: { newTabId } });
    },
    [onAction],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction, tabId]);

  return (
    <Page>
      <Page.Body>
        <Tabs activeKey={tabId} onChange={handleTabChange}>
          <Tabs.TabPane key={TAB_IDS.PROCESSES} tab={TAB_NAMES_BY_TAB_ID[TAB_IDS.PROCESSES]}>
            <WorkflowList workflowMode={WORKFLOW_BUILDER_MODE.PROCESS} history={history} />
          </Tabs.TabPane>
          <Tabs.TabPane key={TAB_IDS.RULES} tab={TAB_NAMES_BY_TAB_ID[TAB_IDS.RULES]}>
            <WorkflowList workflowMode={WORKFLOW_BUILDER_MODE.RULE} history={history} />
          </Tabs.TabPane>
        </Tabs>
      </Page.Body>
    </Page>
  );
};

WorkflowBuilderList.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(WorkflowBuilderList);
