import React from 'react';
import PropTypes from 'prop-types';

import _trim from 'lodash/trim';

import Avatar from '@tekion/tekion-components/atoms/Avatar';
import Icon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import styles from './userAvatar.module.scss';

const UserAvatar = ({ userAvatar, displayName, avatarSize, userAvatarContainerClassName }) => (
  <div className={userAvatarContainerClassName}>
    {_trim(displayName) || _trim(userAvatar) ? (
      <Avatar src={userAvatar} name={displayName} displayName={false} containerClassName={styles.commentAvatarIcon} size={avatarSize} />
    ) : (
      <Icon size={avatarSize} className={styles.avatarIcon}>
        icon-avatar-outline
      </Icon>
    )}
  </div>
);

UserAvatar.propTypes = {
  userAvatar: PropTypes.string,
  displayName: PropTypes.string,
  avatarSize: PropTypes.string,
  userAvatarContainerClassName: PropTypes.string,
};

UserAvatar.defaultProps = {
  userAvatar: undefined,
  displayName: EMPTY_STRING,
  avatarSize: SIZES.XXL,
  userAvatarContainerClassName: EMPTY_STRING,
};

export default UserAvatar;
