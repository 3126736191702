import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Loader from '@tekion/tekion-components/molecules/loader';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';

// Helpers
import { getApplicationEntityMapping } from '../../actions/entityApplicationMapping.actions';
import { getSearchParamsFromUrl } from '../../utils/visualBuilder.utils';

// Styles
import styles from './notificationRedirector.module.scss';

const NotificationRedirector = ({ history }) => {
  const [isInvalid, setIsInvalid] = useState(false);

  const searchParams = getSearchParamsFromUrl(history);
  const applicationName = searchParams?.application;
  const entityName = searchParams?.entity;
  const recordId = searchParams?.recordId;

  const handleMetadataFetchAndRedirection = useCallback(async () => {
    const entityAppMapping = await getApplicationEntityMapping(applicationName, entityName);

    if (!entityAppMapping || !applicationName || !recordId) {
      setIsInvalid(true);
      return;
    }

    const { tabName, pageName } = entityAppMapping;
    if (tabName) {
      window.location.href = `/applications/${applicationName}?tab=${tabName}&pageName=${pageName}&pageRecordId=${recordId}`;
    } else {
      window.location.href = `/applications/${applicationName}?pageName=${pageName}&pageRecordId=${recordId}`;
    }
    // None of the below dependencies are expected to change through the lifecycle of this component
    // If does, the redirection will occur with earlier mappings and result in unexpected behavior.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleMetadataFetchAndRedirection();
  }, [handleMetadataFetchAndRedirection]);

  if (isInvalid) {
    return (
      <Page>
        <Page.Header hasBack contentClassName={styles.pageHeader}>
          <Heading size={3}>{__('Unable to find the page you were looking for!')}</Heading>
          <div>{__('Click back button to go back.')}</div>
        </Page.Header>
      </Page>
    );
  }

  return (
    <div className={`full-height full-width ${styles.loaderContainer}`}>
      <Loader id="NOTIFICATION_REDIRECTOR" />
    </div>
  );
};

NotificationRedirector.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default NotificationRedirector;
