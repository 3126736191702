import { defaultMemoize } from 'reselect';
import Button from '@tekion/tekion-components/atoms/Button';

import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import { COLUMN_ACCESSORS, CUSTOM_ACTIONS } from './userGroupList.constants';

const COLUMNS = [
  {
    Header: __('Name'),
    accessor: COLUMN_ACCESSORS.NAME,
    id: COLUMN_ACCESSORS.NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Display Name'),
    accessor: COLUMN_ACCESSORS.DISPLAY_NAME,
    id: COLUMN_ACCESSORS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Description'),
    accessor: COLUMN_ACCESSORS.DESCRIPTION,
    id: COLUMN_ACCESSORS.DESCRIPTION,
    Cell: TextRenderer,
  },
];

const HEADER_PROPS = {
  label: __('User Group List'),
};

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        view: Button.VIEW.PRIMARY,
        label: __('Add New Group'),
        className: 'm-r-20',
      },
      action: CUSTOM_ACTIONS.ADD_NEW_GROUP,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showSearchByField: true,
  showFilter: false,
  showHeader: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const DELETE_ACTION = {
  id: 'DELETE',
  name: __('Delete'),
};

const EDIT_ACTION = {
  id: 'EDIT',
  name: __('Edit'),
};

const getRowAction = () => [DELETE_ACTION, EDIT_ACTION];

const getTableProps = defaultMemoize((handleRowAction, currentPage, loading, rows, totalNumberOfEntries) => ({
  currentPage: currentPage + 1,
  loading,
  pageSize: rows,
  resultsPerPage: rows,
  minRows: 0,
  showPagination: true,
  rows,
  totalNumberOfEntries,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  onRowActionClick: handleRowAction,
  getRowActions: getRowAction,
}));

export { COLUMNS, HEADER_PROPS, TABLE_MANAGER_PROPS, getTableProps, SUB_HEADER_PROPS, COLUMN_ACCESSORS };
