import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _size from 'lodash/size';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
// Components
import BaseTable from '@tekion/tekion-components/molecules/table/BaseTable';
import Button from '@tekion/tekion-components/atoms/Button';

import { getColumns } from './helpers/configTab.columns';
import ACTION_TYPES from '../../constants/tabPropertiesConfigurator.actionTypes';

import styles from './configTab.module.scss';

const ConfigTab = ({ id, onAction, tabContentDetails, tabContentChildren }) => {
  const columns = getColumns(id, onAction, tabContentDetails, tabContentChildren);

  const handleAddTab = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_ADD_TAB });
  }, [onAction]);

  return (
    <div className={styles.configTabContainer}>
      <div className={styles.addNewTabButton}>
        <Button view={Button.VIEW.SECONDARY} onClick={handleAddTab}>
          {__('Add New')}
        </Button>
      </div>

      <BaseTable showPagination={false} columns={columns} data={tabContentDetails} rowHeight={40} pageSize={_size(tabContentDetails)} />
    </div>
  );
};

ConfigTab.propTypes = {
  id: PropTypes.string,
  tabContentDetails: PropTypes.array,
  tabContentChildren: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ConfigTab.defaultProps = {
  id: EMPTY_STRING,
  tabContentDetails: [{}],
  tabContentChildren: EMPTY_ARRAY,
};

export default ConfigTab;
