const ACTION_TYPES = {
  INIT: 'INIT',
  INIT_DASHBOARD: 'INIT_DASHBOARD',
  CREATE_DASHBOARD: 'CREATE_DASHBOARD',
  EDIT_DASHBOARD: 'EDIT_DASHBOARD',
  CREATE_WIDGET: 'CREATE_WIDGET',
  EDIT_WIDGET: 'EDIT_WIDGET',
  REMOVE_WIDGET: 'REMOVE_WIDGET',
  CLOSE_MODAL: 'CLOSE_MODAL',
  CLOSE_EDIT_DASHBOARD_MODAL: 'CLOSE_EDIT_DASHBOARD_MODAL',
  SAVE_EDIT_DASHBOARD_MODAL: 'SAVE_EDIT_DASHBOARD_MODAL',
  OPEN_DASHBOARD_DRAWER: 'OPEN_DASHBOARD_DRAWER',
  CLOSE_DASHBOARD_DRAWER: 'CLOSE_DASHBOARD_DRAWER',
  UPDATE_GRID_LAYOUT: 'UPDATE_GRID_LAYOUT',
  CANCEL_DASHBOARD_CREATE: 'CANCEL_DASHBOARD_CREATE',
  LIST_WIDGET_ITEM_ADD: 'LIST_WIDGET_ITEM_ADD',
};

export default ACTION_TYPES;
