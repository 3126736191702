import { GRID_PROPERTIES_CONFIGURE_FIELD_IDS } from '../constants/gridPropertiesConfigure.fieldIds';

const GRID_PROPERTIES_CONFIGURE_SECTIONS = [
  {
    rows: [
      {
        columns: [GRID_PROPERTIES_CONFIGURE_FIELD_IDS.WIDGET_POSITION],
      },
      {
        columns: [GRID_PROPERTIES_CONFIGURE_FIELD_IDS.SCROLL_ENABLED],
      },
    ],
  },
];

export { GRID_PROPERTIES_CONFIGURE_SECTIONS };
