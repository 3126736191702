import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _head from 'lodash/head';
import _includes from 'lodash/includes';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FORM_FIELDS, FIELD_IDS } from './addOverrideFieldModal.constants';
import FIELD_TYPES from '../../../../../../../constants/fieldDefinition.fieldTypes';

const getFields = (fieldOverrideFormValues, applicableFields) => {
  const fieldNameDef = _find(
    applicableFields,
    (field) => _get(field, 'name') === _head(_get(fieldOverrideFormValues, FIELD_IDS.FIELD_NAME, EMPTY_ARRAY)),
  );
  const fieldType = _get(fieldNameDef, 'fieldType', EMPTY_STRING);
  let attributesOptions = [{ label: __('Display Name'), value: FIELD_IDS.DISPLAY_NAME }];

  if (fieldType === FIELD_TYPES.SELECT) {
    attributesOptions = [...attributesOptions, { label: __('Options'), value: FIELD_IDS.OPTIONS }];
  }

  return {
    ...FORM_FIELDS,
    [FIELD_IDS.FIELD_NAME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.FIELD_NAME], [
      {
        path: 'options',
        value: applicableFields,
      },
    ]),
    [FIELD_IDS.ATTRIBUTE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.ATTRIBUTE], [
      { path: 'isDisabled', value: _isEmpty(_get(fieldOverrideFormValues, FIELD_IDS.FIELD_NAME)) },
      {
        path: 'options',
        value: attributesOptions,
      },
    ]),
  };
};

const rowCheck = {
  [FIELD_IDS.DISPLAY_NAME]: ({ fieldOverrideFormValues }) => _includes(_get(fieldOverrideFormValues, FIELD_IDS.ATTRIBUTE), FIELD_IDS.DISPLAY_NAME),
  [FIELD_IDS.OPTIONS]: ({ fieldOverrideFormValues }) => _includes(_get(fieldOverrideFormValues, FIELD_IDS.ATTRIBUTE), FIELD_IDS.OPTIONS),
};

const getSections = (fieldOverrideFormValues) => [
  {
    rows: [
      {
        columns: [FIELD_IDS.FIELD_NAME, FIELD_IDS.ATTRIBUTE],
      },
      {
        columns: filterRows([FIELD_IDS.DISPLAY_NAME], { fieldOverrideFormValues }, rowCheck),
      },
      {
        columns: filterRows([FIELD_IDS.OPTIONS], { fieldOverrideFormValues }, rowCheck),
      },
    ],
  },
];

export { getFields, getSections };
