import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';

import { COLUMN_IDS } from './exportList.table.constants';

const FILTER_ID = {
  BUNDLE_STATE: 'state',
};

const BUNDLE_STATE_FILTER = {
  id: FILTER_ID.BUNDLE_STATE,
  key: COLUMN_IDS.STATE,
  type: FILTER_TYPES.SINGLE_SELECT,
  name: __('State'),
};

const DEFAULT_FILTERS = [FILTER_ID.BUNDLE_STATE];
const DEFAULT_FILTER_GROUP = 'DEFAULT_FILTER_GROUP';

export { BUNDLE_STATE_FILTER, DEFAULT_FILTERS, DEFAULT_FILTER_GROUP };
