import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';

import CustomStylesConfigurator from '../customStylesConfigurator';
import IconGridRenderer from './IconGridRenderer';

import { FIELD_IDS, IMAGE_RADIO_OPTIONS } from './imagePropertiesForm.constants';
import { IMAGE_RADIO_BUTTON_VALUES } from '../../organisms/propertiesForm/propertiesForm.constants';

const IMAGE_RADIO_BUTTON_FIELD = {
  id: FIELD_IDS.IMAGE_RADIO_BUTTON,
  renderer: Radio,
  renderOptions: {
    radios: IMAGE_RADIO_OPTIONS,
    defaultValue: IMAGE_RADIO_BUTTON_VALUES.ICON,
    type: 'RADIO',
  },
};

const IMAGE_PATH_FIELD = {
  id: FIELD_IDS.IMAGE_PATH,
  renderer: TextInput,
  renderOptions: {
    label: __('Image Path'),
    defaultValue: '',
  },
};

const GRID_FIELD = {
  id: FIELD_IDS.GRID,
  renderer: IconGridRenderer,
};

const CUSTOM_STYLE_FIELD = {
  id: FIELD_IDS.CUSTOM_STYLES,
  renderer: CustomStylesConfigurator,
};

const FORM_FIELDS = {
  [FIELD_IDS.IMAGE_RADIO_BUTTON]: IMAGE_RADIO_BUTTON_FIELD,
  [FIELD_IDS.IMAGE_PATH]: IMAGE_PATH_FIELD,
  [FIELD_IDS.GRID]: GRID_FIELD,
  [FIELD_IDS.CUSTOM_STYLES]: CUSTOM_STYLE_FIELD,
};

const getFields = (componentType, properties, entity) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.CUSTOM_STYLES]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CUSTOM_STYLES], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
});

export { getFields };
