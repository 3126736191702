import React from 'react';

import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import InfoBadge from '@tekion/tekion-components/molecules/infoBadge';
import { POPOVER_PLACEMENT } from '@tekion/tekion-components/molecules/popover';

// Constants
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../../../../constants/actionBuilder.constants';
import { ACTION_BUILDER_ACTION_TYPES } from '../../../../../../actionBuilder/actionBuilderForm/constants/actionBuilderForm.general';
import { GET_RECORD_FIELD_IDS } from '../constants/recordActionModal.fieldIds';
import { ACTION_DEFINITION_HELP_TEXT_LABEL } from '../constants/recordActionModal.constants';
import { ASSIGNED_VARIABLE_DATA_TYPES } from '../../../../constants/workflow.nodeVariableConstants';

// Styles
import styles from '../recordActionModal.module.scss';

const rowCheck = {
  [ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME]: ({ actionType }) => actionType !== ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL,
  [ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS]: ({ actionType, shouldUpdateSameRecord }) =>
    ((actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD || actionType === ACTION_BUILDER_ACTION_TYPES.UPSERT_RECORD) &&
      !shouldUpdateSameRecord) ||
    actionType === ACTION_BUILDER_ACTION_TYPES.GET_RECORDS,
  [ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS]: ({ actionType, shouldUpdateSameRecord }) =>
    ((actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD || actionType === ACTION_BUILDER_ACTION_TYPES.UPSERT_RECORD) &&
      !shouldUpdateSameRecord) ||
    actionType === ACTION_BUILDER_ACTION_TYPES.GET_RECORDS,
  [ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS]: ({ isSortRequired }) => isSortRequired,
  [ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT]: ({ actionType, actionData }) =>
    actionType === ACTION_BUILDER_ACTION_TYPES.GET_RECORDS &&
    tget(actionData, GET_RECORD_FIELD_IDS.RECORD_COUNT_FIELD_ID) !== ASSIGNED_VARIABLE_DATA_TYPES.SINGLE,

  [ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD]: ({ isTargetSameAsParameter, actionType }) =>
    isTargetSameAsParameter && (actionType === ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD || actionType === ACTION_BUILDER_ACTION_TYPES.UPSERT_RECORD),
  [GET_RECORD_FIELD_IDS.RECORD_COUNT_FIELD_ID]: ({ actionType }) => actionType === ACTION_BUILDER_ACTION_TYPES.GET_RECORDS,
};

const getActionInfoSection = (actionType) => ({
  subHeader: {
    label: __('Action Info'),
  },
  rows: [
    {
      columns: filterRows([ACTION_DEFINITION_FIELD_IDS.ACTION_NAME, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME], { actionType }, rowCheck),
    },
  ],
});

const getSections = (entityName, actionData, isTargetFieldsLoading) => {
  const actionType = tget(actionData, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE);
  const targetEntity = getArraySafeValue(tget(actionData, `${ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME}`));
  const isTargetSameAsParameter = targetEntity === entityName;

  if (_isEmpty(actionType) || (actionType !== ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL && _isEmpty(targetEntity)) || isTargetFieldsLoading) {
    return [getActionInfoSection(actionType)];
  }

  const isSortRequired = tget(actionData, ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS, false);
  const shouldUpdateSameRecord = tget(actionData, ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD, false);

  const sections = [
    getActionInfoSection(actionType),
    {
      rows: [
        {
          columns: filterRows([GET_RECORD_FIELD_IDS.RECORD_COUNT_FIELD_ID], { actionType }, rowCheck),
        },
      ],
    },
    {
      rows: [
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD], { actionType, isTargetSameAsParameter }, rowCheck),
        },
      ],
    },
    {
      className: styles.conditionBuilderFormSection,
      rows: [
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS], { actionType, shouldUpdateSameRecord }, rowCheck),
        },
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS], { actionType, shouldUpdateSameRecord }, rowCheck),
        },
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS], { isSortRequired }, rowCheck),
        },
      ],
    },
    {
      rows: [
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT], { actionType, actionData }, rowCheck),
        },
      ],
    },
  ];

  if (actionType !== ACTION_BUILDER_ACTION_TYPES.GET_RECORDS && actionType !== ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL) {
    sections.push({
      className: styles.conditionBuilderFormSection,
      subHeader: {
        label:
          actionType === ACTION_BUILDER_ACTION_TYPES.CREATE_RECORD ? (
            <>
              <span className={styles.actionDefSubHeading}>{__('Action Definition')}</span>
              <InfoBadge helpText={ACTION_DEFINITION_HELP_TEXT_LABEL.LABEL} infoPlacement={POPOVER_PLACEMENT.RIGHT} />
            </>
          ) : (
            __('Action Definition')
          ),
        className: styles.actionDefSubHeader,
      },
      rows: [
        {
          columns: [ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION],
        },
      ],
    });
  }

  if (actionType === ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL) {
    sections.push({
      className: styles.conditionBuilderFormSection,
      subHeader: {
        label: __('Action Definition'),
      },
      rows: [
        {
          columns: [ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION],
        },
      ],
    });
  }

  return sections;
};

export default getSections;
