import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import COLUMN_IDS from '../constants/authProviderList.columnsIds';

const COLUMN_CONFIG = [
  {
    Header: __('Name'),
    id: COLUMN_IDS.NAME,
    accessor: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Display Name'),
    id: COLUMN_IDS.DISPLAY_NAME,
    accessor: COLUMN_IDS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('URL Suffix'),
    id: COLUMN_IDS.URL_SUFFIX,
    accessor: COLUMN_IDS.URL_SUFFIX,
    Cell: TextRenderer,
  },
  {
    Header: __('Provider Type'),
    id: COLUMN_IDS.PROVIDER_TYPE,
    accessor: COLUMN_IDS.PROVIDER_TYPE,
    Cell: TextRenderer,
  },
];

export default COLUMN_CONFIG;
