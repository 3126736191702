import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const AUTH_PROVIDER_BASE_URL = '/core/u/scs/auth/providers';

// APIs for Auth Providers

const fetchAuthProviderByName = (name) => ApiService.get(`${AUTH_PROVIDER_BASE_URL}/${name}`);
const createAuthProvider = (payload = EMPTY_OBJECT) => ApiService.post(`${AUTH_PROVIDER_BASE_URL}`, payload);
const updateAuthProviderByName = (name, payload = EMPTY_OBJECT) => ApiService.put(`${AUTH_PROVIDER_BASE_URL}/${name}`, payload);
const searchAuthProviders = (payload = EMPTY_OBJECT) => ApiService.post(`${AUTH_PROVIDER_BASE_URL}/search`, payload);
const fetchAuthProviderByNameWithReveal = (name) => ApiService.get(`${AUTH_PROVIDER_BASE_URL}/${name}/reveal`);

export default {
  fetchAuthProviderByName,
  createAuthProvider,
  updateAuthProviderByName,
  searchAuthProviders,
  fetchAuthProviderByNameWithReveal,
};
