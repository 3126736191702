import { defaultMemoize } from 'reselect';

import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';

import { TEXT_STARTS_WITH } from '../../../../../../../constants/filter.constants';

const createTableProps = (loading, currentPage, pageSize, entities) => ({
  loading,
  currentPage: currentPage + 1,
  resultPerPages: pageSize,
  pageSize,
  totalNumberOfEntries: _size(entities),
  rowHeight: 35,
  showPagination: true,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  minRows: 0,
});

const getFetchEntityPayload = defaultMemoize(({ searchText, searchField, pageSize, nextPageToken }) => {
  let payload = { rows: pageSize };

  const filters = [
    {
      field: 'complexField',
      values: [false],
      filterType: OPERATORS.IN,
    },
  ];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: searchField,
      values: [searchText],
      filterType: TEXT_STARTS_WITH,
    });
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      ...payload,
      nextPageToken,
    };
  }

  return { ...payload, filters };
});

export { createTableProps, getFetchEntityPayload };
