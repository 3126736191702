import { defaultMemoize } from 'reselect';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { getFormattedDateTime, DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';

const getTableProps = defaultMemoize((currentPage, totalCount, loading, rows) => ({
  currentPage: currentPage + 1,
  loading,
  resultsPerPage: rows,
  rowHeight: 40,
  totalNumberOfEntries: totalCount,
  minRows: 0,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  selection: EMPTY_ARRAY,
  pageSize: rows,
}));

const getProjectCreatedTime = defaultMemoize((epochTime) => getFormattedDateTime(epochTime, DATE_TIME_FORMATS.NATIVE_DATE_FORMAT));

export { getTableProps, getProjectCreatedTime };
