export const RESIZER_ENABLE = { right: true };

export const RESIZER_STYLES = { width: '100%', height: '100%' };

export const RESIZER_HANDLE_STYLES = {
  right: {
    right: '-.2rem',
    width: '.4rem',
    height: '100%',
    position: 'absolute',
    zIndex: 2,
    visibility: 'visible',
  },
};
