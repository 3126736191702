import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../../../../constants/approvalCentre.constants';

const APPROVAL_CATEGORY_COLUMN_IDS = {
  NAME: APPROVAL_CENTRE_FIELD_IDS.NAME,
  DISPLAY_NAME: APPROVAL_CENTRE_FIELD_IDS.DISPLAY_NAME,
  GROUP: APPROVAL_CENTRE_FIELD_IDS.GROUP,
  CATEGORY: APPROVAL_CENTRE_FIELD_IDS.CATEGORY,
  INACTIVE: APPROVAL_CENTRE_FIELD_IDS.INACTIVE,
  PRIORITY: APPROVAL_CENTRE_FIELD_IDS.PRIORITY,
};

export default APPROVAL_CATEGORY_COLUMN_IDS;
