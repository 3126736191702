import { useEffect } from 'react';
import { compose } from 'recompose';
import _head from 'lodash/head';

// Plate/Slate
import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';

// readers
import MediaReader from '@tekion/tekion-base/readers/Media';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// hooks
import useEditor from './useEditor';

const setMediaIdInNode = ({ uploadedMediaId, url = EMPTY_STRING, editor, element, width }) => {
  if (uploadedMediaId) {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { data: { mediaId: uploadedMediaId, url, width } }, { at: path });
  }
};

const getUploadedMediaId = compose(MediaReader.mediaId, _head);

function useUpdateMediaNode({ mediaList, url, element, width }) {
  const editor = useEditor();
  const uploadedMediaId = getUploadedMediaId(mediaList);

  useEffect(() => {
    setMediaIdInNode({ editor, uploadedMediaId, url, element, width });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedMediaId, url, width]);
}

export default useUpdateMediaNode;
