import { useCallback, useMemo, useState } from 'react';

import { ALL_ACCESS_ACCESS_FILTER, ACCESS_DENIED_ACCESS_TYPE_FILTER } from '../../molecules/accessTypeFilter';
import { getColumns } from '../../helpers/manageRecordAccessTable.config';
import { getClassNameFromRowInfo, getFilteredData } from '../../helpers/manageRecordAccessTable.helpers';

const useManageRecordAccessTable = ({
  wwdAccessTypesOptions,
  shareAndManageRecordUserDetails,
  onUpdateShareAndManageRecordUserDetails,
  onRemoveShareAndManageRecordUserDetails,
}) => {
  const [selectedAccessTypeFilter, setSelectedAccessFilter] = useState(ALL_ACCESS_ACCESS_FILTER.id);

  const isSelectedAccessTypeFilterNotOfAccessDenied = selectedAccessTypeFilter !== ACCESS_DENIED_ACCESS_TYPE_FILTER.id;

  const columns = useMemo(
    () =>
      getColumns({
        isSelectedAccessTypeFilterNotOfAccessDenied,
        wwdAccessTypesOptions,
        onUpdateShareAndManageRecordUserDetails,
        onRemoveShareAndManageRecordUserDetails,
      }),
    [
      isSelectedAccessTypeFilterNotOfAccessDenied,
      onRemoveShareAndManageRecordUserDetails,
      onUpdateShareAndManageRecordUserDetails,
      wwdAccessTypesOptions,
    ],
  );

  const filteredData = useMemo(
    () => getFilteredData(shareAndManageRecordUserDetails, selectedAccessTypeFilter),
    [selectedAccessTypeFilter, shareAndManageRecordUserDetails],
  );

  const getRowClassName = useMemo(
    () => getClassNameFromRowInfo(isSelectedAccessTypeFilterNotOfAccessDenied),
    [isSelectedAccessTypeFilterNotOfAccessDenied],
  );

  const handleSetAccessTypeFilter = useCallback((newFilterValue) => {
    setSelectedAccessFilter(newFilterValue);
  }, []);

  return { isSelectedAccessTypeFilterNotOfAccessDenied, selectedAccessTypeFilter, columns, filteredData, getRowClassName, handleSetAccessTypeFilter };
};

export default useManageRecordAccessTable;
