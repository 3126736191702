import { defaultMemoize } from 'reselect';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';
import { NO_DATA } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import RELATIONSHIP_DETAILS_FORM_FIELD_IDS from '../constants/relationshipDetailsForm.fieldIds';

const getLookupEntityFilters = defaultMemoize((entityName) => {
  const filters = [
    {
      field: 'complexField',
      values: [false],
      filterType: OPERATORS.IN,
    },
    {
      field: 'name',
      values: [entityName],
      filterType: OPERATORS.NIN,
    },
  ];

  return filters;
});

const getSearchTextPayload = defaultMemoize((nextPageToken, searchText = '', filters = []) => ({
  filters: [...filters, { field: 'displayName', filterType: OPERATORS.TEXT_STARTS_WITH, values: _castArray(searchText) }],
  rows: 10,
  nextPageToken,
}));

const getPayloadForInitialFetch = defaultMemoize((entityName) => (value) => {
  const filters = getLookupEntityFilters(entityName);

  return {
    rows: 1,
    filters: [...filters, { field: 'name', filterType: OPERATORS.IN, values: _castArray(value) }],
  };
});

const getPayload = defaultMemoize((entityName) => ({ nextPageToken, searchText }) => {
  const filters = getLookupEntityFilters(entityName);

  if (!_isEmpty(searchText)) {
    return getSearchTextPayload(nextPageToken, searchText, filters);
  }

  return {
    rows: 10,
    nextPageToken,
    filters,
  };
});

const getRelationshipDetailsFormValues = (fieldsFormRelationshipDetailsValues) => {
  const displayField = _get(fieldsFormRelationshipDetailsValues, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_DISPLAY);
  const entityType = _get(fieldsFormRelationshipDetailsValues, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY);
  const field = _get(fieldsFormRelationshipDetailsValues, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP);

  return {
    ...(!_isEmpty(displayField) && { [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_DISPLAY]: _castArray(displayField) }),
    ...(!_isEmpty(entityType) && { [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY]: entityType }),
    ...(!_isEmpty(field) && { [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP]: _castArray(field) }),
    [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.GROUPS_ALLOWED]: _get(
      fieldsFormRelationshipDetailsValues,
      RELATIONSHIP_DETAILS_FORM_FIELD_IDS.GROUPS_ALLOWED,
      false,
    ),
  };
};

const getFieldsFormRelationshipDetailsValues = (values) => {
  const displayField = _get(values, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_DISPLAY);
  const entityType = _get(values, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY);
  const field = _get(values, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP);

  return {
    ...(!_isEmpty(displayField) && { [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_DISPLAY]: getArraySafeValue(displayField) }),
    ...(!_isEmpty(entityType) && { [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY]: getArraySafeValue(entityType) }),
    ...(!_isEmpty(field) && { [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP]: getArraySafeValue(field) }),
    [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.GROUPS_ALLOWED]: _get(values, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.GROUPS_ALLOWED, false),
  };
};

const getOptionsByName = (options, filterByAllowLookup = true) => {
  const filteredOptions = filterByAllowLookup ? _filter(options, (option) => _get(option, 'allowLookupUsingField', true)) : options;

  return _map(filteredOptions, (option) => ({
    label: tget(option, 'displayName', NO_DATA),
    value: _get(option, 'name', ''),
    dataType: _get(option, 'dataType', ''),
  }));
};

export { getOptionsByName, getPayload, getPayloadForInitialFetch, getRelationshipDetailsFormValues, getFieldsFormRelationshipDetailsValues };
