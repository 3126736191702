const ACTION_TYPES = {
  CREATE_RULE: 'CREATE_RULE',
  EDIT_RULE: 'EDIT_RULE',
  DELETE_RULE: 'DELETE_RULE',
  ON_ASSIGN_MODAL_CANCEL_REQUEST: 'ON_ASSIGN_MODAL_CANCEL_REQUEST',
  ON_REMOVE_ASSIGNED_USERS: 'ON_REMOVE_ASSIGNED_USERS',
  ON_CHANGE_ASSIGNED_USERS: 'ON_CHANGE_ASSIGNED_USERS',
  ON_CHANGE_ASSIGNED_USER_GROUPS: 'ON_CHANGE_ASSIGNED_USER_GROUPS',
  ON_SEARCH_USER: ' ON_SEARCH_USER',
  DELETE_RULE_CONFIRMATION_DONE_CLICK: 'DELETE_RULE_CONFIRMATION_DONE_CLICK',
  DELETE_RULE_CONFIRMATION_CANCEL_CLICK: 'DELETE_RULE_CONFIRMATION_CANCEL_CLICK',
};

export default ACTION_TYPES;
