const TAB_IDS = {
  PROCESSES: 'processes',
  RULES: 'rules',
};

const TAB_NAMES_BY_TAB_ID = {
  [TAB_IDS.PROCESSES]: __('Processes'),
  [TAB_IDS.RULES]: __('Rules'),
};

export { TAB_IDS, TAB_NAMES_BY_TAB_ID };
