import ApiService from './apiService/apiService';

const MAPPINGS_BASE_URL = 'core/u/cms/entity-app/mappings';

const fetchEntityMappingsForApplication = (applicationName) => ApiService.get(`${MAPPINGS_BASE_URL}/app/${applicationName}`);
const createUpdateEntityMappingsForApplication = (payload) => ApiService.post(MAPPINGS_BASE_URL, payload);

const fetchApplicationEntityMapping = (applicationName, entityName) =>
  ApiService.get(`${MAPPINGS_BASE_URL}/app/${applicationName}/entity/${entityName}`);

const searchEntityApplicationMappings = (payload) => ApiService.post(`${MAPPINGS_BASE_URL}/search`, payload);

export default {
  fetchEntityMappingsForApplication,
  createUpdateEntityMappingsForApplication,
  fetchApplicationEntityMapping,
  searchEntityApplicationMappings,
};
