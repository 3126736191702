import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _size from 'lodash/size';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Collapse from '@tekion/tekion-components/molecules/Collapse';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Loader from '@tekion/tekion-components/molecules/loader';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import FieldListTable from './components/fieldListTable/FieldListTable';
import withSize from '../../../../connectors/withSize';
import { getFields } from './helpers/recordType.fields';
import { getSections } from './helpers/recordType.section';
import ACTION_HANDLERS from './helpers/recordType.actionHandlers';
import { ACTION_TYPES, CONTEXT_ID } from './constants/recordType.constants';
import { FORM_MODES } from '../../../../constants/general.constants';

import styles from './recordType.module.scss';

const RecordTypeForm = ({
  isModalVisible,
  isSavingDetails,
  isLoading,
  contentHeight,
  formMode,
  formValues,
  errors,
  entity,
  mapOfVariableToEntityName,
  conditionBuilderFieldDefinitionObject,
  fieldTableData,
  fieldModalData,
  derivationFields,
  onAction,
}) => {
  const sections = useMemo(() => getSections(entity), [entity]);
  const fields = useMemo(
    () => getFields(formMode, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject),
    [conditionBuilderFieldDefinitionObject, formMode, mapOfVariableToEntityName],
  );
  const headerTitle = useMemo(() => (formMode === FORM_MODES.CREATE ? __('Create Record Type') : __('Update Record Type')), [formMode]);

  const triggerFormSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, []);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_REDIRECTION });
  }, [onAction]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_MOUNT });
  }, [onAction]);

  if (isLoading) {
    return <Loader style={{ height: 316 }} />;
  }

  return (
    <Page>
      <Page.Header hasBack>
        <Heading>{headerTitle}</Heading>
      </Page.Header>
      <Page.Body className="full-width" style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          className="full-width p-t-12"
          contextId={CONTEXT_ID}
          fields={fields}
          sections={sections}
          values={formValues}
          errors={errors}
          onAction={onAction}
        />
        <Collapse bodyClassName={styles.collapse}>
          <Collapse.Panel
            key="fieldList"
            panelKey="fieldList"
            header={<div className={styles.applicableFieldsHeader}>{__(`Applicable Fields ( ${_size(fieldModalData)} )`)}</div>}
          >
            <FieldListTable
              isModalVisible={isModalVisible}
              formMode={formMode}
              fieldTableData={fieldTableData}
              fieldModalData={fieldModalData}
              entity={entity}
              derivationFields={derivationFields}
              onAction={onAction}
            />
          </Collapse.Panel>
        </Collapse>
      </Page.Body>

      <Page.Footer>
        <SaveComponent
          id={CONTEXT_ID}
          primaryActionLoading={isSavingDetails}
          primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Create') : __('Update')}
          onPrimaryAction={triggerFormSubmit}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

RecordTypeForm.propTypes = {
  isSavingDetails: PropTypes.bool,
  isLoading: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  formValues: PropTypes.object,
  entity: PropTypes.object,
  errors: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  fieldModalData: PropTypes.array,
  fieldTableData: PropTypes.array,
  derivationFields: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

RecordTypeForm.defaultProps = {
  isSavingDetails: false,
  isLoading: false,
  isModalVisible: false,
  formValues: undefined,
  fieldModalData: EMPTY_ARRAY,
  entity: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  formMode: FORM_MODES.CREATE,
  fieldTableData: EMPTY_ARRAY,
  derivationFields: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(RecordTypeForm);
