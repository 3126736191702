import { defaultMemoize } from 'reselect';

import Button from '@tekion/tekion-components/atoms/Button';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import { CUSTOM_ACTIONS, COLUMN_ACCESSORS } from './dashboardList.constant';

const COLUMNS = [
  {
    Header: __('DashBoard Name'),
    accessor: COLUMN_ACCESSORS.DASHBOARD_NAME,
    id: COLUMN_ACCESSORS.DASHBOARD_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Display Name'),
    accessor: COLUMN_ACCESSORS.DISPLAY_NAME,
    id: COLUMN_ACCESSORS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
];

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        view: Button.VIEW.PRIMARY,
        label: __('Create New Dashboard'),
      },
      action: CUSTOM_ACTIONS.CREATE_NEW_DASHBOARD,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showSearch: true,
  showHeader: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const HEADER_PROPS = {
  label: __('Dashboards'),
  hasBack: false,
};

const EDIT_ACTION = {
  id: 'EDIT',
  name: __('Edit'),
};

const getTableProps = defaultMemoize((handleRowAction, currentPage, loading, rows, totalNumberOfEntries) => ({
  currentPage: currentPage + 1,
  loading,
  pageSize: rows,
  resultsPerPage: rows,
  minRows: 0,
  showPagination: true,
  rows,
  totalNumberOfEntries,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  onRowActionClick: handleRowAction,
  getRowActions: () => [EDIT_ACTION],
}));

export { COLUMNS, TABLE_MANAGER_PROPS, getTableProps, SUB_HEADER_PROPS, HEADER_PROPS };
