import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';

import { tget } from '@tekion/tekion-base/utils/general';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { fetchEntityRecords } from './recordManagement.actions';
import { getComplexAndRelationshipEntitiesFromEntityDef, getResolvedEntityDefinitions } from '../helpers/entityManagement.helpers';
import { getPayloadForEntity, getPayloadForRecord } from '../helpers/visualBuilder.helpers';

import entityServices from '../services/entity.services';
import entityReader from '../readers/entity.reader';

const fetchEntities = async (payload = EMPTY_OBJECT, includeFieldDefinitions = false) => {
  try {
    const response = await entityServices.fetchEntities(payload, includeFieldDefinitions);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error));
    return EMPTY_OBJECT;
  }
};

const createNewEntity = async (payload) => {
  try {
    const response = await entityServices.createEntity(payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Entity created successfully'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error));
    return EMPTY_OBJECT;
  }
};

const updateEntity = async (payload) => {
  const entityName = _get(payload, 'name');
  try {
    const response = await entityServices.updateEntityByName(entityName, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Entity updated successfully'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error));
    return EMPTY_OBJECT;
  }
};

const fetchEntityDefByName = async (entityName) => {
  try {
    const response = await entityServices.fetchEntityByName(entityName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error));
    return EMPTY_OBJECT;
  }
};

const getFieldData = async (entityName, fieldName) => {
  try {
    const response = await entityServices.fetchFieldDataByName(entityName, fieldName);
    return { response: getDataFromResponse(response), error: '' };
  } catch (err) {
    return {
      response: EMPTY_OBJECT,
      error: getErrorMessage(err, __('Failed to fetch field details, please try again later.')),
    };
  }
};

const createField = async (entityName, values) => {
  try {
    const response = await entityServices.createField(entityName, values);
    toaster(TOASTER_TYPE.SUCCESS, __('Field created successfully'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error));
    return EMPTY_OBJECT;
  }
};

const updateField = async (entityName, fieldName, values) => {
  try {
    const response = await entityServices.updateField(entityName, fieldName, values);
    toaster(TOASTER_TYPE.SUCCESS, __('Field updated successfully'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error));
    return EMPTY_OBJECT;
  }
};

const updateSelectOption = async (entityName, fieldName, value, payload) => {
  try {
    const response = await entityServices.updateSelectOption(entityName, fieldName, value, payload);
    return { response: getDataFromResponse(response), error: '' };
  } catch (err) {
    return {
      response: EMPTY_OBJECT,
      error: getErrorMessage(err, __('Failed to update option, please try again later.')),
    };
  }
};

const addSelectOption = async (entityName, fieldName, payload) => {
  try {
    const response = await entityServices.addSelectOption(entityName, fieldName, payload);
    return { response: getDataFromResponse(response), error: '' };
  } catch (err) {
    return {
      response: EMPTY_OBJECT,
      error: getErrorMessage(err, __('Failed to add option, please try again later.')),
    };
  }
};

const fetchAllRegex = async () => {
  try {
    const response = await entityServices.fetchAllRegex();
    return { hits: getDataFromResponse(response) };
  } catch (err) {
    return {
      response: EMPTY_OBJECT,
      error: getErrorMessage(err, __('Failed to fetch all regex, please try again later.')),
    };
  }
};

const matchRegex = async (payload) => {
  try {
    const response = await entityServices.matchRegex(payload);
    return getDataFromResponse(response);
  } catch (err) {
    return {
      response: EMPTY_OBJECT,
      error: getErrorMessage(err, __('Failed to match regex, please try again later.')),
    };
  }
};

const getMasterEntityDef = async (entityName) => {
  const entityDef = await fetchEntityDefByName(entityName);

  const { complexEntities, relationshipEntities } = getComplexAndRelationshipEntitiesFromEntityDef(entityDef);
  const entitiesToSearch = [...complexEntities, ...relationshipEntities];

  let masterEntityDef = entityDef;
  if (!_isEmpty(entitiesToSearch)) {
    const entityPayload = getPayloadForEntity(entitiesToSearch);
    const entityResponse = await fetchEntities(entityPayload, true);
    const entityDefsByName = _keyBy(tget(entityResponse, 'hits', EMPTY_ARRAY), 'name');
    masterEntityDef = getResolvedEntityDefinitions(entityDef, entityDefsByName);
  }

  return masterEntityDef;
};

const getMasterEntityRecord = async (recordValueToFilter, filterFieldName, masterEntityDef, fetchOneRecord = false) => {
  const entityName = entityReader.name(masterEntityDef);

  const recordPayload = getPayloadForRecord(recordValueToFilter, filterFieldName, masterEntityDef, fetchOneRecord);
  const recordResponse = await fetchEntityRecords(entityName, recordPayload);
  const entityRecord = getArraySafeValue(tget(recordResponse, 'hits', [{}]));

  return entityRecord;
};

export {
  fetchEntities,
  addSelectOption,
  createNewEntity,
  updateEntity,
  fetchEntityDefByName,
  getFieldData,
  createField,
  updateField,
  updateSelectOption,
  fetchAllRegex,
  matchRegex,
  getMasterEntityDef,
  getMasterEntityRecord,
};
