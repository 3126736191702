import { EVENT_NAMES, EVENT_NAME_TO_LABEL_MAP } from '../../../../../../constants/eventActions.constants';

const FIELD_IDS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  EVENT_HANDLERS: 'eventHandlers',
  RENDERER_PROPS: 'rendererProps',
  ENABLE_ACTION_SCRIPT: 'enableActionScript',
};

const SECTIONS = [
  {
    rows: [
      { columns: [FIELD_IDS.NAME, FIELD_IDS.DISPLAY_NAME] },
      { columns: [FIELD_IDS.EVENT_HANDLERS] },
      { columns: [FIELD_IDS.ENABLE_ACTION_SCRIPT] },
    ],
  },
];

const EVENT_NAME_OPTIONS = [{ label: EVENT_NAME_TO_LABEL_MAP[EVENT_NAMES.CLICK], value: EVENT_NAMES.CLICK }];

export { FIELD_IDS, SECTIONS, EVENT_NAME_OPTIONS };
