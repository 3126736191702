import _map from 'lodash/map';
import _get from 'lodash/get';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { getUuid, getComponentProperties } from './viewBuilder.helper';
import { convertToSpaceLessLowerCaseString } from '../../../utils';

import { VIEW_SUMMARY_FIELD_IDS, COMPONENT_TYPES, VIEW_TYPES } from '../constants/viewBuilder.constants';

const getEmptyViewConfiguration = () => ({
  viewType: VIEW_TYPES.CUSTOM_VIEW,
  description: 'Empty View Configuration',
  section: {
    type: COMPONENT_TYPES.SECTION,
    sectionId: getUuid(),
    properties: getComponentProperties({ componentType: COMPONENT_TYPES.SECTION }),
    children: [
      {
        type: COMPONENT_TYPES.SECTION_COLUMN,
        sectionId: getUuid(),
        properties: getComponentProperties({ componentType: COMPONENT_TYPES.SECTION_COLUMN }),
        children: [],
      },
    ],
  },
});

const getDetailViewConfiguration = (viewSummary, entity) => {
  const entityName = _get(entity, 'name', EMPTY_ARRAY);
  const entityDisplayName = _get(entity, 'displayName', EMPTY_ARRAY);
  const viewName = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.VIEW_NAME, EMPTY_STRING);
  const viewDescription = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.VIEW_DESCRIPTION, EMPTY_STRING);
  const viewSummaryFieldDefinitions = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.COLUMN_TABLE, EMPTY_ARRAY);
  const applicableRecordTypes = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.APPLICABLE_RECORD_TYPES, EMPTY_ARRAY);
  const containerSectionProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.SECTION,
    defaultPropertyValues: { title: __(`Details view - ${entityDisplayName}`), viewType: VIEW_TYPES.DETAIL_VIEW },
  });
  const containerSectionColumnProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.SECTION_COLUMN,
    defaultPropertyValues: { viewType: VIEW_TYPES.DETAIL_VIEW },
  });
  const detailsViewAttributeRendererProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.DETAIL_VIEW_ATTRIBUTE,
    defaultPropertyValues: { viewType: VIEW_TYPES.DETAIL_VIEW },
  });

  return {
    viewType: VIEW_TYPES.DETAIL_VIEW,
    name: convertToSpaceLessLowerCaseString(viewName),
    entityName,
    properties: {
      title: viewName,
      description: viewDescription,
      theme: 'DEFAULT',
    },
    applicableRecordTypes,
    section: {
      type: COMPONENT_TYPES.SECTION,
      viewType: VIEW_TYPES.DETAIL_VIEW,
      sectionId: getUuid(),
      properties: containerSectionProperties,
      children: [
        {
          type: COMPONENT_TYPES.SECTION_COLUMN,
          viewType: VIEW_TYPES.DETAIL_VIEW,
          sectionId: getUuid(),
          properties: containerSectionColumnProperties,
          children: _map(viewSummaryFieldDefinitions, (property, index) => ({
            type: COMPONENT_TYPES.DETAIL_VIEW_ATTRIBUTE,
            viewType: VIEW_TYPES.DETAIL_VIEW,
            sectionId: getUuid(),
            index,
            properties: { ...detailsViewAttributeRendererProperties, ...property },
          })),
        },
      ],
    },
  };
};

const getListViewConfiguration = (viewSummary, entity) => {
  const entityName = _get(entity, 'name', EMPTY_ARRAY);
  const entityDisplayName = _get(entity, 'displayName', EMPTY_ARRAY);
  const viewName = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.VIEW_NAME, EMPTY_STRING);
  const viewDescription = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.VIEW_DESCRIPTION, EMPTY_STRING);
  const columns = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.COLUMN_TABLE, EMPTY_ARRAY);
  const applicableRecordTypes = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.APPLICABLE_RECORD_TYPES, EMPTY_ARRAY);

  const containerSectionProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.SECTION,
    defaultPropertyValues: { title: __(`List view - ${entityDisplayName}`), viewType: VIEW_TYPES.LIST_VIEW },
  });
  const containerSectionColumnProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.SECTION_COLUMN,
    defaultPropertyValues: { viewType: VIEW_TYPES.LIST_VIEW },
  });
  const listBuilderViewRendererComponentProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.LIST_VIEW_RENDERER,
    defaultPropertyValues: { viewType: VIEW_TYPES.LIST_VIEW },
  });

  return {
    viewType: VIEW_TYPES.LIST_VIEW,
    name: convertToSpaceLessLowerCaseString(viewName),
    entityName,
    properties: {
      title: viewName,
      description: viewDescription,
      theme: 'DEFAULT',
    },
    applicableRecordTypes,
    section: {
      type: COMPONENT_TYPES.SECTION,
      viewType: VIEW_TYPES.LIST_VIEW,
      sectionId: getUuid(),
      properties: containerSectionProperties,
      children: [
        {
          type: COMPONENT_TYPES.SECTION_COLUMN,
          viewType: VIEW_TYPES.LIST_VIEW,
          sectionId: getUuid(),
          properties: containerSectionColumnProperties,
          children: [
            {
              type: COMPONENT_TYPES.LIST_VIEW_RENDERER,
              viewType: VIEW_TYPES.LIST_VIEW,
              sectionId: getUuid(),
              properties: listBuilderViewRendererComponentProperties,
              children: _map(columns, (column, index) => {
                const listBuilderViewColumnComponentProperties = getComponentProperties({
                  componentType: COMPONENT_TYPES.LIST_VIEW_COLUMN,
                  defaultPropertyValues: {
                    ...column,
                    viewType: VIEW_TYPES.LIST_VIEW,
                  },
                });

                return {
                  type: COMPONENT_TYPES.LIST_VIEW_COLUMN,
                  viewType: VIEW_TYPES.LIST_VIEW,
                  sectionId: getUuid(),
                  index,
                  properties: listBuilderViewColumnComponentProperties,
                };
              }),
            },
          ],
        },
      ],
    },
  };
};

// Check customPreferences format here - https://tekion.atlassian.net/browse/DPF-1090
const getFormViewConfiguration = (viewSummary, entity, customPreferences = {}) => {
  const entityName = _get(entity, 'name', EMPTY_ARRAY);
  const entityDisplayName = _get(entity, 'displayName', EMPTY_ARRAY);

  const viewName = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.VIEW_NAME, EMPTY_STRING);
  const viewSummaryFieldDefinitions = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.COLUMN_TABLE, EMPTY_ARRAY);
  const viewDescription = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.VIEW_DESCRIPTION, EMPTY_STRING);
  const applicableRecordTypes = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.APPLICABLE_RECORD_TYPES, EMPTY_ARRAY);

  const containerPreferences = tget(customPreferences, 'container', {});
  const containerSectionProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.SECTION,
    defaultPropertyValues: { title: __(`Form view - ${entityDisplayName}`), ...containerPreferences, viewType: VIEW_TYPES.FORM_VIEW },
  });

  const containerSectionColumnPreferences = tget(customPreferences, 'containerSectionColumn', {});
  const containerSectionColumnProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.SECTION_COLUMN,
    defaultPropertyValues: { ...containerSectionColumnPreferences, viewType: VIEW_TYPES.FORM_VIEW },
  });
  const formViewRendererFormSectionProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.FORM_VIEW_SECTION,
    defaultPropertyValues: { viewType: VIEW_TYPES.FORM_VIEW },
  });
  const formViewRendererFormRowProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.FORM_VIEW_ROW,
    defaultPropertyValues: { viewType: VIEW_TYPES.FORM_VIEW },
  });

  return {
    viewType: VIEW_TYPES.FORM_VIEW,
    name: convertToSpaceLessLowerCaseString(viewName),
    entityName,
    properties: {
      title: viewName,
      description: viewDescription,
      theme: 'DEFAULT',
      viewType: VIEW_TYPES.FORM_VIEW,
    },
    applicableRecordTypes,
    section: {
      type: COMPONENT_TYPES.SECTION,
      viewType: VIEW_TYPES.FORM_VIEW,
      sectionId: getUuid(),
      properties: { ...containerSectionProperties, viewType: VIEW_TYPES.FORM_VIEW },
      children: [
        {
          type: COMPONENT_TYPES.SECTION_COLUMN,
          viewType: VIEW_TYPES.FORM_VIEW,
          sectionId: getUuid(),
          properties: { ...containerSectionColumnProperties, viewType: VIEW_TYPES.FORM_VIEW },
          children: [
            {
              type: COMPONENT_TYPES.FORM_VIEW_RENDERER,
              viewType: VIEW_TYPES.FORM_VIEW,
              sectionId: getUuid(),
              properties: {
                viewType: VIEW_TYPES.FORM_VIEW,
              },
              children: [
                {
                  type: COMPONENT_TYPES.FORM_VIEW_SECTION,
                  viewType: VIEW_TYPES.FORM_VIEW,
                  sectionId: getUuid(),
                  properties: { ...formViewRendererFormSectionProperties, viewType: VIEW_TYPES.FORM_VIEW },
                  children: _map(viewSummaryFieldDefinitions, (field) => {
                    const formBuilderViewColumnComponentProperties = getComponentProperties({
                      componentType: COMPONENT_TYPES.FORM_VIEW_COLUMN,
                      defaultPropertyValues: {
                        lookupFieldNames: [],
                        complexViewName: '',
                        ...field,
                        viewType: VIEW_TYPES.FORM_VIEW,
                      },
                    });

                    return {
                      type: COMPONENT_TYPES.FORM_VIEW_ROW,
                      viewType: VIEW_TYPES.FORM_VIEW,
                      sectionId: getUuid(),
                      properties: { ...formViewRendererFormRowProperties, viewType: VIEW_TYPES.FORM_VIEW },
                      children: [
                        {
                          type: COMPONENT_TYPES.FORM_VIEW_COLUMN,
                          viewType: VIEW_TYPES.FORM_VIEW,
                          sectionId: getUuid(),
                          properties: { ...formBuilderViewColumnComponentProperties, viewType: VIEW_TYPES.FORM_VIEW },
                          index: 0,
                        },
                      ],
                    };
                  }),
                },
              ],
            },
          ],
        },
      ],
    },
  };
};

const getCellViewConfiguration = (viewSummary, entity) => {
  const entityName = _get(entity, 'name', EMPTY_ARRAY);
  const viewName = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.VIEW_NAME, EMPTY_STRING);
  const applicableRecordTypes = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.APPLICABLE_RECORD_TYPES, EMPTY_ARRAY);

  const rowProperties = getComponentProperties({ componentType: COMPONENT_TYPES.ROW, defaultPropertyValues: { viewType: VIEW_TYPES.CELL_VIEW } });

  const columnProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.COLUMN,
    defaultPropertyValues: { viewType: VIEW_TYPES.CELL_VIEW },
  });

  return {
    viewType: VIEW_TYPES.CELL_VIEW,
    name: convertToSpaceLessLowerCaseString(viewName),
    entityName,
    applicableRecordTypes,
    properties: {
      title: viewName,
      description: '',
      theme: 'DEFAULT',
    },

    section: {
      type: COMPONENT_TYPES.ROW,
      viewType: VIEW_TYPES.CELL_VIEW,
      sectionId: getUuid(),
      properties: rowProperties,
      children: [
        {
          type: COMPONENT_TYPES.COLUMN,
          viewType: VIEW_TYPES.CELL_VIEW,
          sectionId: getUuid(),
          properties: columnProperties,
          children: [],
        },
      ],
    },
  };
};

const getGridViewConfiguration = (viewSummary, entity) => {
  const entityName = _get(entity, 'name', EMPTY_ARRAY);
  const entityDisplayName = _get(entity, 'displayName', EMPTY_ARRAY);
  const viewName = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.VIEW_NAME, EMPTY_STRING);
  const viewDescription = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.VIEW_DESCRIPTION, EMPTY_STRING);
  const selectedEntityFields = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.COLUMN_TABLE, EMPTY_ARRAY);
  const applicableRecordTypes = _get(viewSummary, VIEW_SUMMARY_FIELD_IDS.APPLICABLE_RECORD_TYPES, EMPTY_ARRAY);

  const containerSectionProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.SECTION,
    defaultPropertyValues: { title: __(`Grid view - ${entityDisplayName}`), viewType: VIEW_TYPES.GRID_VIEW },
  });
  const containerSectionColumnProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.SECTION_COLUMN,
    defaultPropertyValues: { viewType: VIEW_TYPES.GRID_VIEW },
  });
  const gridBuilderViewRendererComponentProperties = getComponentProperties({
    componentType: COMPONENT_TYPES.GRID_VIEW,
    defaultPropertyValues: { viewType: VIEW_TYPES.GRID_VIEW },
  });

  return {
    viewType: VIEW_TYPES.GRID_VIEW,
    name: convertToSpaceLessLowerCaseString(viewName),
    entityName,
    properties: {
      title: viewName,
      description: viewDescription,
      theme: 'DEFAULT',
    },
    applicableRecordTypes,
    section: {
      type: COMPONENT_TYPES.SECTION,
      viewType: VIEW_TYPES.GRID_VIEW,
      sectionId: getUuid(),
      properties: containerSectionProperties,
      children: [
        {
          type: COMPONENT_TYPES.SECTION_COLUMN,
          viewType: VIEW_TYPES.GRID_VIEW,
          sectionId: getUuid(),
          properties: containerSectionColumnProperties,
          children: [
            {
              type: COMPONENT_TYPES.GRID_VIEW,
              viewType: VIEW_TYPES.GRID_VIEW,
              sectionId: getUuid(),
              properties: gridBuilderViewRendererComponentProperties,
              children: _map(selectedEntityFields, (entityField, index) => {
                const gridBuilderViewAttributeComponentProperties = getComponentProperties({
                  componentType: COMPONENT_TYPES.GRID_VIEW_ATTRIBUTE,
                  defaultPropertyValues: {
                    ...entityField,
                    viewType: VIEW_TYPES.GRID_VIEW,
                  },
                });

                return {
                  type: COMPONENT_TYPES.GRID_VIEW_ATTRIBUTE,
                  viewType: VIEW_TYPES.GRID_VIEW,
                  sectionId: getUuid(),
                  index,
                  properties: gridBuilderViewAttributeComponentProperties,
                };
              }),
            },
          ],
        },
      ],
    },
  };
};

export {
  getEmptyViewConfiguration,
  getDetailViewConfiguration,
  getListViewConfiguration,
  getFormViewConfiguration,
  getCellViewConfiguration,
  getGridViewConfiguration,
};
