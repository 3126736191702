const INITIAL_STATE_APP_LIST_PROPS = {
  currentPage: 0,
  pageSize: 50,
};

const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

const NAVIGATION_TYPE = {
  TOP_NAVIGATION: 'top',
  LEFT_NAVIGATION: 'left',
};

const NAVIGATION_TYPES = {
  NONE: { label: __('None'), value: null },
  TOP_NAVIGATION: { label: __('Top'), value: 'TOP_NAVIGATION' },
  LEFT_NAVIGATION: { label: __('Left'), value: 'LEFT_NAVIGATION' },
};

const NAVIGATION_MAPPING = {
  [NAVIGATION_TYPE.TOP_NAVIGATION]: 'TOP_NAVIGATION',
  [NAVIGATION_TYPE.LEFT_NAVIGATION]: 'LEFT_NAVIGATION',
};
export { INITIAL_STATE_APP_LIST_PROPS, TABLE_CONSTANTS, NAVIGATION_TYPES, NAVIGATION_MAPPING };
