import produce from 'immer';

import _get from 'lodash/get';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _compact from 'lodash/compact';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import {
  checkDeletedModuleIsSelectedAndLastModule,
  filterFormValueForDeletedTab,
  getErrors,
  getTabPropertiesValues,
  setTabPropertiesInApplicationConfig,
} from './tabProperties.helpers';

import APPLICATION_BUILDER_ACTION_TYPES from '../../../../../constants/applicationBuilder.actionTypes';
import ACTION_TYPES from '../constants/tabProperties.actionTypes';
import { FIELD_IDS, ENTITY_INFO_FIELD_IDS as MODAL_FIELD_IDS } from '../constants/tabProperties.constants';
import { TAB_IDS } from '../../../../../constants/applicationBuilder.constants';

const handleInit = ({ getState, setState }) => {
  const { selectedComponentId, selectedComponent, applicationConfig } = getState();
  const tabValues = getTabPropertiesValues(applicationConfig, selectedComponentId, selectedComponent);

  let selectedEntities = [];
  if (selectedComponentId === TAB_IDS.APPROVAL) {
    _forEach(_get(tabValues, `${FIELD_IDS.ENTITIES_RENDERING_INFO}`), (value) => {
      selectedEntities.push(_get(value, MODAL_FIELD_IDS.ENTITY_NAME));
    });
    selectedEntities = _compact(selectedEntities);
    setState({ selectedModuleIndex: 0, selectedEntities });
  }
  setState({ tabValues });
};

const handleConfigureClick = ({ getState, setState }) => {
  const { tabValues } = getState();
  setState({
    isModalVisible: true,
    configureEntityValues: { [FIELD_IDS.ENTITIES_RENDERING_INFO]: _get(tabValues, FIELD_IDS.ENTITIES_RENDERING_INFO) },
  });
};

const handleFieldOnChange = ({ getState, setState, params }) => {
  const { id, value } = params;
  const { selectedComponentId } = getState();

  setState(
    produce((draft) => {
      _set(draft, `tabValues.${id}`, value);
    }),
    () => {
      const { applicationBuilderOnAction, applicationConfig, tabValues } = getState();
      const updatedApplicationConfig = setTabPropertiesInApplicationConfig(applicationConfig, tabValues, selectedComponentId);
      applicationBuilderOnAction({
        type: APPLICATION_BUILDER_ACTION_TYPES.SET_APPLICATION_CONFIG,
        payload: { applicationConfig: updatedApplicationConfig },
      });
    },
  );
};

const handleSubmit = ({ getState, setState }) => {
  const { configureEntityValues, tabValues, applicationBuilderOnAction, selectedComponentId, applicationConfig, selectedEntities } = getState();
  const newTabValues = { ...tabValues, ...configureEntityValues };

  const { isValid, entityErrorObject } = getErrors(configureEntityValues);
  if (isValid) {
    const updatedApplicationConfig = setTabPropertiesInApplicationConfig(applicationConfig, newTabValues, selectedComponentId, selectedEntities);
    applicationBuilderOnAction({
      type: APPLICATION_BUILDER_ACTION_TYPES.SET_APPLICATION_CONFIG,
      payload: { applicationConfig: updatedApplicationConfig },
    });

    setState({ isModalVisible: false, tabValues: newTabValues });
  }
  setState({ entityErrorObject });
};

const handleModuleChange = ({ setState, params = EMPTY_OBJECT }) => {
  const { moduleIndex } = params;
  setState({ selectedModuleIndex: moduleIndex });
};

const handleDeleteRow = ({ getState, setState, params }) => {
  const { configureEntityValues, selectedModuleIndex } = getState();
  const { moduleIndex } = params;
  let newSelectedModuleIndex = selectedModuleIndex;
  const entitiesRenderingInfo = _get(configureEntityValues, FIELD_IDS.ENTITIES_RENDERING_INFO);

  const newEntitiesRenderingInfo = filterFormValueForDeletedTab(entitiesRenderingInfo, moduleIndex);

  if (checkDeletedModuleIsSelectedAndLastModule(moduleIndex, selectedModuleIndex, entitiesRenderingInfo)) {
    newSelectedModuleIndex = moduleIndex - 1;
  } else if (moduleIndex < selectedModuleIndex) {
    newSelectedModuleIndex -= 1;
  }
  let selectedEntities = [];
  _forEach(newEntitiesRenderingInfo, (value) => {
    selectedEntities.push(_get(value, MODAL_FIELD_IDS.ENTITY_NAME));
  });
  selectedEntities = _compact(selectedEntities);

  setState(
    { configureEntityValues: { ...configureEntityValues, [FIELD_IDS.ENTITIES_RENDERING_INFO]: newEntitiesRenderingInfo }, selectedEntities },
    () => {
      handleModuleChange({ getState, setState, params: { moduleIndex: newSelectedModuleIndex } });
    },
  );
};

const handleCancelModal = ({ getState, setState }) => {
  const { tabValues } = getState();
  const entitiesRenderingInfo = _get(tabValues, FIELD_IDS.ENTITIES_RENDERING_INFO);

  let selectedEntities = [];
  _forEach(entitiesRenderingInfo, (value) => {
    selectedEntities.push(_get(value, MODAL_FIELD_IDS.ENTITY_NAME));
  });
  selectedEntities = _compact(selectedEntities);
  setState({ isModalVisible: false, selectedEntities });
};

const handleAddRow = ({ getState, setState }) => {
  const { configureEntityValues } = getState();

  setState({
    configureEntityValues: { [FIELD_IDS.ENTITIES_RENDERING_INFO]: [..._get(configureEntityValues, FIELD_IDS.ENTITIES_RENDERING_INFO, []), {}] },
  });
};

const handleTabFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;

  const { selectedModuleIndex, configureEntityValues } = getState();
  const entitiesRenderingInfo = _get(configureEntityValues, FIELD_IDS.ENTITIES_RENDERING_INFO, []);
  const newEntitiesRenderingInfo = _cloneDeep(entitiesRenderingInfo);
  _set(newEntitiesRenderingInfo, [selectedModuleIndex, id], value);

  if (id === MODAL_FIELD_IDS.ENTITY_NAME) {
    let selectedEntities = [];
    _forEach(newEntitiesRenderingInfo, (info) => {
      selectedEntities.push(_get(info, MODAL_FIELD_IDS.ENTITY_NAME));
    });
    selectedEntities = _compact(selectedEntities);
    setState({ selectedEntities });
  }
  const newConfigureEntityValues = { ...configureEntityValues, [FIELD_IDS.ENTITIES_RENDERING_INFO]: newEntitiesRenderingInfo };
  const { entityErrorObject } = getErrors(newConfigureEntityValues);
  setState({
    configureEntityValues: newConfigureEntityValues,
    entityErrorObject,
  });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [ACTION_TYPES.ON_CONFIGURE_CLICK]: handleConfigureClick,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldOnChange,
  [ACTION_TYPES.ON_SAVE_MODAL]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL_MODAL]: handleCancelModal,
  [ACTION_TYPES.ON_DELETE_ROW]: handleDeleteRow,
  [ACTION_TYPES.ON_ADD_ROW]: handleAddRow,
  [ACTION_TYPES.ON_CHANGE_MODULE]: handleModuleChange,
  [ACTION_TYPES.ON_MODAL_FIELD_CHANGE]: handleTabFieldChange,
};

export default ACTION_HANDLERS;
