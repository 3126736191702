import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import { fetchEntityDefByName } from '../../../../../actions/entityManagement.actions';
import { updateRecordType } from '../../../../../actions/recordType.actions';
import { ACTIVATE_MODAL_CONTENT, CUSTOM_ACTIONS, DEACTIVATE_MODAL_CONTENT } from '../constants/recordTypeList.constants';
import PAGE_IDS from '../../../constants/PageIds.constants';
import { STUDIO_ROUTE } from '../../../../../constants/routes';

const handleInit = async ({ getState, setState }) => {
  const { match } = getState();
  const entityName = _get(match, 'params.entityName', EMPTY_STRING);

  setState({ loading: true });

  const entity = await fetchEntityDefByName(entityName);
  const recordTypes = _get(entity, 'recordTypeDefinition.recordTypes', EMPTY_ARRAY);

  setState({
    entity,
    recordTypes,
    isLoaded: true,
    loading: false,
  });
};

const handleEditClick = ({ getState }) => {
  const { history = EMPTY_OBJECT, match = EMPTY_OBJECT, entity } = getState();
  const { entityName } = _get(match, 'params', {});

  if (!_isEmpty(entityName))
    history.push({
      pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.RECORD_TYPE_DETAILS}`,
      state: { entity },
    });
};

const handleCreateNewAction = ({ getState }) => {
  const { history, match, entity } = getState();
  const entityName = _get(match, 'params.entityName', '');

  history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.RECORD_TYPE_CREATE}`, state: entity });
};

const handleEditRequest = ({ params, getState }) => {
  const { history, match } = getState();
  const recordName = _get(params, 'value.name', '');
  const entityName = _get(match, 'params.entityName', '');

  if (!_isEmpty(recordName))
    history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.RECORD_TYPE_EDIT}/${recordName}` });
};

const handleRowClick = ({ params, getState }) => {
  const { history, match, entity } = getState();
  const recordTypeDetails = _get(params, 'value.original', EMPTY_OBJECT);
  const recordName = _get(recordTypeDetails, 'name', '');
  const entityName = _get(match, 'params.entityName', '');
  if (!_isEmpty(recordName))
    history.push({
      pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.RECORD_TYPE}/${recordName}`,
      state: { entity, recordTypeDetails },
    });
};

const handleConfirmRequest = async ({ getState, setState }) => {
  const { value, match } = getState();
  const entityName = _get(match, 'params.entityName', EMPTY_STRING);

  setState({ showModal: false, loading: true });

  const status = _get(value, 'active', false);
  const active = !status;
  const payload = { ...value, active };

  const data = await updateRecordType(entityName, payload);

  if (!_isEmpty(data)) {
    handleInit({ getState, setState });
  } else {
    setState({ loading: false });
  }
};

const handleActivateStatus = async ({ params, setState }) => {
  setState({ showModal: true, modalContent: `${ACTIVATE_MODAL_CONTENT} ${params.value.name}`, value: params.value });
};

const handleDeactivateStatus = async ({ params, setState }) => {
  setState({ showModal: true, modalContent: `${DEACTIVATE_MODAL_CONTENT} ${params.value.name}`, value: params.value });
};

const handleCloseModalRequest = ({ setState }) => {
  setState({ showModal: false });
};

const ACTION_HANDLERS = {
  [CUSTOM_ACTIONS.CREATE_NEW]: handleCreateNewAction,
  [CUSTOM_ACTIONS.EDIT]: handleEditRequest,
  [CUSTOM_ACTIONS.ACTIVATE]: handleActivateStatus,
  [CUSTOM_ACTIONS.DEACTIVATE]: handleDeactivateStatus,
  [CUSTOM_ACTIONS.ON_EDIT_CLICK]: handleEditClick,
  [CUSTOM_ACTIONS.CLOSE_MODAL_REQUEST_DIALOG]: handleCloseModalRequest,
  [CUSTOM_ACTIONS.CONFIRM_MODAL_REQUEST]: handleConfirmRequest,
  [CUSTOM_ACTIONS.ON_INIT]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleRowClick,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleInit,
};
export default ACTION_HANDLERS;
