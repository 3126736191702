import { defaultMemoize } from 'reselect';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _some from 'lodash/some';

import { EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { FIELD_IDS as ROLES_FIELD_IDS } from '../../../../../../../constants/roles.constants';

const getParentKey = defaultMemoize((key, tree) => {
  let parentKey;

  _forEach(tree, (node) => {
    const children = _get(node, 'children');
    const nodeKey = _get(node, 'key');
    if (children) {
      if (_some(children, (item) => item.key === key)) {
        parentKey = nodeKey;
      } else if (getParentKey(key, children)) {
        parentKey = getParentKey(key, children);
      }
    }
  });
  return parentKey;
});

const getModifiedTreeData = defaultMemoize((data, rolesDataByName) => {
  const roleData = _get(data, 'role', EMPTY_OBJECT);
  const title = tget(roleData, ROLES_FIELD_IDS.DISPLAY_NAME, NO_DATA);
  const name = _get(roleData, ROLES_FIELD_IDS.ROLE_NAME);
  const children = _get(data, 'childRoles');

  _set(rolesDataByName, name, roleData);

  const updatedChildren = _map(children, (child) => getModifiedTreeData(child, rolesDataByName));

  return { title, key: name, children: updatedChildren, roleData };
});

const getAllRoleOptions = (data, array) => {
  if (_isEmpty(data)) return;

  const label = _get(data, 'title', '');
  const value = _get(data, 'key', '');

  array.push({ label, value });

  const children = _get(data, 'children', []);

  _forEach(children, (child) => {
    getAllRoleOptions(child, array);
  });
};

export { getParentKey, getModifiedTreeData, getAllRoleOptions };
