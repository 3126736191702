import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import ViewRendererContext from '../../../../context/viewRenderer.context';
import ComponentRendererContainer from '../../atoms/componentRendererContainer';

import variableApiFactory from '../../../../factories/variableApi.Factory';
import Records from '../../../../actions/customCodeApis/customCode.RecordApi';
import Actions from '../../../../actions/customCodeApis/customCode.ActionApi';

import { convertEventHandlersFromArrayToObjectByEventName, executeEventFromEventViewConfigData } from '../../../../utils/eventHandlers';

import { MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT } from '../../../../constants/eventHandlers.constants';
import { APPLICATION_CONTEXT_KEYS } from '../../../../constants/applicationRenderer.constants';
import { EVENT_NAMES } from '../../../../constants/eventActions.constants';
import { VIEW_CONTEXT_KEYS } from '../../constants/viewBuilder.constants';
import {
  VIEW_CONFIGURATION_FIELD_IDS,
  VIEW_CONFIGURATION_GENERAL_KEYS,
  VIEW_CONFIGURATION_PROPERTIES_ID,
} from '../../../../constants/viewBuilder.constants';

import styles from './viewViewer.module.scss';

const ViewViewer = ({ isPreviewMode, selectedViewComponentId, viewConfiguration, applicationContext, entityRecord, ...restProps }) => {
  const viewContextValue = useContext(ViewRendererContext);
  const widgetName = tget(viewContextValue, `${VIEW_CONTEXT_KEYS.WIDGET_CONTEXT}.widgetName`, EMPTY_STRING);
  const viewSpecificContext = tget(viewContextValue, VIEW_CONTEXT_KEYS.VIEW_CONTEXT, EMPTY_OBJECT);
  const applicationSpecificContext = tget(viewContextValue, VIEW_CONTEXT_KEYS.APPLICATION_CONTEXT, EMPTY_OBJECT);
  const { viewVariables = {}, handleSetViewVariables = _noop, handleClearVariables = _noop } = viewSpecificContext;
  const { applicationVariables = {} } = applicationSpecificContext;

  const viewContainerSectionId = tget(
    viewConfiguration,
    `${VIEW_CONFIGURATION_FIELD_IDS.SECTION}.${VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID}`,
    false,
  );
  const viewType = _get(viewConfiguration, VIEW_CONFIGURATION_GENERAL_KEYS.VIEW_TYPE);

  const properties = tget(viewConfiguration, `${VIEW_CONFIGURATION_FIELD_IDS.SECTION}.${VIEW_CONFIGURATION_FIELD_IDS.PROPERTIES}`, EMPTY_OBJECT);
  const eventHandlers = tget(properties, VIEW_CONFIGURATION_PROPERTIES_ID.EVENT_HANDLERS, EMPTY_ARRAY);
  const modifiedEventHandlers = convertEventHandlersFromArrayToObjectByEventName(eventHandlers);

  const variablesApi = useMemo(
    () => variableApiFactory({ ...viewVariables, ...applicationVariables }, handleSetViewVariables),
    [viewVariables, handleSetViewVariables, applicationVariables],
  );

  useEffect(() => {
    const onLoadEventData = tget(modifiedEventHandlers, EVENT_NAMES.LOAD, EMPTY_OBJECT);
    const currentUser = tget(applicationContext, APPLICATION_CONTEXT_KEYS.CURRENT_USER, EMPTY_OBJECT);

    const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$RECORD]: entityRecord,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$CURRENT_USER]: currentUser,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RECORDS]: Records,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.VARIABLES]: variablesApi,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.ACTIONS]: Actions,
    };

    executeEventFromEventViewConfigData(onLoadEventData, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE, entityRecord, widgetName);

    // To be called on mount only.
    return () => {
      handleClearVariables();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityRecord]);

  return viewContainerSectionId ? (
    <div className={styles.viewViewer}>
      <ComponentRendererContainer
        key={viewContainerSectionId}
        isSelected={selectedViewComponentId === undefined || viewContainerSectionId === selectedViewComponentId}
        isPreviewMode={isPreviewMode}
        isDeleteEnabled={false}
        viewType={viewType}
        componentConfig={viewConfiguration.section}
        entityRecord={entityRecord}
        {...restProps}
      />
    </div>
  ) : null;
};

ViewViewer.propTypes = {
  isPreviewMode: PropTypes.bool,
  selectedViewComponentId: PropTypes.bool,
  viewConfiguration: PropTypes.object,
  entityRecord: PropTypes.object.isRequired,
  applicationContext: PropTypes.object,
};

ViewViewer.defaultProps = {
  isPreviewMode: true,
  selectedViewComponentId: undefined,
  viewConfiguration: undefined,
  applicationContext: EMPTY_OBJECT,
};

export default React.memo(ViewViewer);
