import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import TextInputRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import EntityAsyncSelect from '../../../../../molecules/EntityAsyncSelect/EntityAsyncSelect';

import { ADD_WIDGET_FORM_FIELDS } from './addWidget.general.constant';

const WIDGET_NAME_FIELD = {
  id: ADD_WIDGET_FORM_FIELDS.WIDGET_NAME,
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Widget Name'),
    validators: [isRequiredRule],
    required: true,
  },
};

const DISPLAY_NAME_FIELD = {
  id: ADD_WIDGET_FORM_FIELDS.DISPLAY_NAME,
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Display Name'),
    validators: [isRequiredRule],
    required: true,
  },
};
const ENTITY_NAME_FIELD = {
  id: ADD_WIDGET_FORM_FIELDS.ENTITY_NAME,
  renderer: EntityAsyncSelect,
  renderOptions: {
    label: __('Select Entity'),
    validators: [isRequiredRule],
    required: true,
  },
};

export { WIDGET_NAME_FIELD, DISPLAY_NAME_FIELD, ENTITY_NAME_FIELD };
