import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
// Components
import InputTable from '@tekion/tekion-components/organisms/inputTable/containers/withRowActions/Table';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/molecules/tableInputField/constants/TableInputField.actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import Button from '@tekion/tekion-components/atoms/Button';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Constants
import { COLUMNS } from './helper/optionTable.columns';
import { REMOVE_ACTION, ROW_ACTION_PROPS, TABLE_ACTIONS } from './constants/optionTable.constants';
import ACTION_TYPES from '../../constants/globalSelectListForm.actionTypes';
import { FORM_MODES } from '../../../../../../../constants/viewBuilder.constants';

// Styles
import styles from './optionTable.module.scss';

const OptionTable = (props) => {
  const { value, formMode, onAction } = props;

  const additionalValues = useMemo(() => ({ formMode }), [formMode]);
  const getActions = useCallback(() => [REMOVE_ACTION], []);

  const handleAction = useCallback(
    (action) => {
      const actionType = _get(action, 'type', EMPTY_STRING);

      if (actionType === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
        onAction({
          type: ACTION_TYPES.TABLE_ON_CHANGE,
          payload: _get(action, 'payload', EMPTY_OBJECT),
        });
      }

      if (actionType === TABLE_ACTION_TYPES.TABLE_ACTION_CLICK) {
        const tableActionType = _get(action, 'payload.actionType', EMPTY_STRING);
        const row = _get(action, 'payload.nestingPath.[0]', EMPTY_STRING);
        if (tableActionType === TABLE_ACTIONS.REMOVE_ROW) {
          onAction({
            type: ACTION_TYPES.TABLE_REMOVE_ROW,
            payload: { row },
          });
        }
      }
    },
    [onAction],
  );

  const handleAddRow = useCallback(() => {
    onAction({
      type: ACTION_TYPES.TABLE_ADD_ROW,
    });
  }, [onAction]);

  return (
    <div>
      <InputTable
        columns={COLUMNS}
        value={value}
        className={styles.inputTable}
        rowActionProps={ROW_ACTION_PROPS}
        additional={additionalValues}
        getActionsForRow={getActions}
        onClick={handleAction}
        onAction={handleAction}
      />

      <Button view={Button.VIEW.TERTIARY} className={styles.addButton} onClick={handleAddRow}>
        {__('Add Option')}
      </Button>
    </div>
  );
};

OptionTable.propTypes = {
  formMode: PropTypes.string,
  value: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

OptionTable.defaultProps = {
  formMode: FORM_MODES.CREATE,
  value: EMPTY_ARRAY,
};

export default React.memo(OptionTable);
