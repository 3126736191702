const PERMISSION_RADIO_OPTIONS = {
  READ: 'READ_ONLY',
  READ_WRITE: 'READ_WRITE',
};

const PERMISSION_RADIO_LABEL_MAP = {
  [PERMISSION_RADIO_OPTIONS.READ]: __('Read'),
  [PERMISSION_RADIO_OPTIONS.READ_WRITE]: __('Read - Write'),
};

const RECORD_RULE_SHARING_FORM_CONTEXT_ID = 'RECORD_RULE_SHARING_FORM_CONTEXT_ID';

export { PERMISSION_RADIO_OPTIONS, PERMISSION_RADIO_LABEL_MAP, RECORD_RULE_SHARING_FORM_CONTEXT_ID };
