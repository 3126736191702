import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _map from 'lodash/map';
import _reject from 'lodash/reject';
import _noop from 'lodash/noop';
import _isObject from 'lodash/isObject';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Tag from '@tekion/tekion-components/atoms/Tag';

import styles from './asyncTag.module.scss';

const AsyncTag = ({ areTagsDeletable, tagClassName, value, tagsWrapperClassName, onChange }) => {
  const handleRemoveTag = useCallback(
    (val) => {
      onChange(_reject(value, ['value', val]), {});
    },
    [value, onChange],
  );

  const renderTag = useCallback(
    (tagData) => {
      if (!_isObject(tagData) || !tagData) return null;
      return (
        <Tag className={cx(styles.tag, tagClassName)} data={tagData.value} deletable={areTagsDeletable} onDelete={handleRemoveTag}>
          {tagData.label}
        </Tag>
      );
    },
    [areTagsDeletable, tagClassName, handleRemoveTag],
  );

  return <div className={cx(styles.tags, tagsWrapperClassName)}>{_map(value, renderTag)}</div>;
};
AsyncTag.propTypes = {
  areTagsDeletable: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  tagsWrapperClassName: PropTypes.string,
  tagClassName: PropTypes.string,
  onChange: PropTypes.func,
};
AsyncTag.defaultProps = {
  areTagsDeletable: true,
  value: EMPTY_ARRAY,
  tagsWrapperClassName: EMPTY_STRING,
  tagClassName: EMPTY_STRING,
  onChange: _noop,
};
export default AsyncTag;
