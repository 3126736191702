import compose from 'recompose/compose';
import { defaultMemoize } from 'reselect';

import _property from 'lodash/property';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _nthArg from 'lodash/nthArg';

import { tget } from '@tekion/tekion-base/utils/general';
import { NO_DATA } from '@tekion/tekion-base/app.constants';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import ColumnConfig from '@tekion/tekion-components/organisms/inputTable/builders/ColumnConfig';
import CellConfig from '@tekion/tekion-components/organisms/inputTable/builders/CellConfig';
import SelectInputCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/SelectInputCellRenderer';

import { COLUMNS, DEPENDENCY_OPTIONS } from '../constants/dependencyConfigTable.general';

import styles from '../../../../selectOptionsForm/components/controllingOptionConfigTable/controllingOption.module.scss';

const getFieldOptions = defaultMemoize((fieldDefinitions, selectedFields) =>
  _map(fieldDefinitions, (field) => ({
    label: tget(field, 'displayName', NO_DATA),
    value: _get(field, 'name', ''),
    isDisabled: _includes(selectedFields, field.name),
  })),
);

const mapFieldProps = defaultMemoize((props, fieldDefinitions, selectedFields) => {
  const { error } = props;

  return {
    validators: [isRequiredRule],
    required: true,
    options: getFieldOptions(fieldDefinitions, selectedFields),
    className: styles.customSelect,
    error,
  };
});

const mapValueProps = defaultMemoize((props) => {
  const { error } = props;

  return {
    validators: [isRequiredRule],
    required: true,
    options: DEPENDENCY_OPTIONS,
    className: styles.customSelect,
    error,
  };
});

const getFieldCell = (fieldDefinitions, selectedFields) =>
  new CellConfig()
    .setComponent(SelectInputCellRenderer)
    .setMapCellPropsToComponentProps((props) => mapFieldProps(props, fieldDefinitions, selectedFields));

const VALUE_CELL = new CellConfig().setComponent(SelectInputCellRenderer).setMapCellPropsToComponentProps((props) => mapValueProps(props));

const COLUMN_WIDTHS = {
  [COLUMNS.FIELD_NAME]: '30rem',
  [COLUMNS.DEPENDENCY_TYPE]: '30rem',
};

const valueAccessor = _property(COLUMNS.DEPENDENCY_TYPE);
const labelAccessor = _property(COLUMNS.FIELD_NAME);

const onValueChange = compose(_property('value'), _nthArg(1));

const getFieldColumnConfig = (fieldDefinitions, selectedFields) =>
  new ColumnConfig(COLUMNS.FIELD_NAME)
    .setHeader(__('Field'))
    .setGetValueFromOnChange(onValueChange)
    .setAccessor(labelAccessor)
    .addCellConfig(getFieldCell(fieldDefinitions, selectedFields))
    .setWidth(COLUMN_WIDTHS[COLUMNS.FIELD_NAME]);

const VALUE_COLUMN_CONFIG = new ColumnConfig(COLUMNS.DEPENDENCY_TYPE)
  .setHeader(__('DependencyType'))
  .setGetValueFromOnChange(onValueChange)
  .setAccessor(valueAccessor)
  .addCellConfig(VALUE_CELL)
  .setWidth(COLUMN_WIDTHS[COLUMNS.DEPENDENCY_TYPE]);

const getColumns = (fieldDefinitions, selectedFields) => {
  const fieldColumnConfig = getFieldColumnConfig(fieldDefinitions, selectedFields);

  return [fieldColumnConfig, VALUE_COLUMN_CONFIG];
};

export { getColumns };
