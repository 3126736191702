import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

// Components
import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Loader from '@tekion/tekion-components/molecules/loader';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';

import WithSize from '../../../../../../connectors/withSize';

// Form Helpers
import getFields from './helpers/approvalProcessForm.fields';
import getSections from './helpers/approvalProcessForm.sections';
import ACTION_HANDLERS from './helpers/approvalProcessForm.actionHandlers';

// Constants
import { FORM_MODES } from '../../../../../../constants/general.constants';
import FIELD_IDS from './constants/approvalProcessForm.fieldIds';
import ACTION_TYPES from './constants/approvalProcessForm.actionTypes';
import { CONTEXT_ID, INITIAL_STATE } from './constants/approvalProcessForm.constants';
import ROUTES from '../../../../constants/routes';

const ApprovalProcessForm = ({
  isSubmitting,
  isApprovalSettingLoading,
  isConditionFieldsLoading,
  isApprovalProcessLoading,
  contentHeight,
  formMode,
  history,
  formValues,
  errors,
  mapOfVariableToEntityName,
  fieldDefinitionsForConditionBuilder,
  onAction,
}) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const displayName = formValues?.[FIELD_IDS.DISPLAY_NAME];

  const handleCancelClick = useCallback(() => {
    setIsCancelModalOpen(true);
  }, []);

  const handleCancelCancel = useCallback(() => {
    setIsCancelModalOpen(false);
  }, []);

  const handleCancelConfirm = useCallback(() => {
    history.push(ROUTES.APPROVAL_PROCESSES_ROUTE); // List route
  }, [history]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, []);

  const headerTitle = formMode === FORM_MODES.CREATE ? __('Create Approval Process') : displayName;
  const primaryButtonLabel = formMode === FORM_MODES.CREATE ? __('Create') : __('Update');

  const fields = useMemo(
    () => getFields(isApprovalSettingLoading, isConditionFieldsLoading, formMode, fieldDefinitionsForConditionBuilder, mapOfVariableToEntityName),
    [isApprovalSettingLoading, isConditionFieldsLoading, formMode, mapOfVariableToEntityName, fieldDefinitionsForConditionBuilder],
  );

  const sections = useMemo(() => getSections(formValues), [formValues]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // To be triggered on mount ONLY.

  if (isApprovalProcessLoading) {
    return <Loader id={CONTEXT_ID} style={{ height: 316 }} />;
  }

  return (
    <Page>
      <Page.Header hasBack>
        <Heading>{headerTitle}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          className="p-t-12"
          isFetching={isApprovalProcessLoading}
          contextId={CONTEXT_ID}
          sections={sections}
          fields={fields}
          values={formValues}
          errors={errors}
          onAction={onAction}
        />
        <ConfirmationDialog
          isVisible={isCancelModalOpen}
          title={__('Cancel Changes')}
          content={__('Are you sure you want to cancel all the changes made by you? All data will be lost if you click yes.')}
          onSubmit={handleCancelConfirm}
          onCancel={handleCancelCancel}
        />
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id={CONTEXT_ID}
          primaryActionLoading={isSubmitting}
          primaryButtonLabel={primaryButtonLabel}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancelClick}
        />
      </Page.Footer>
    </Page>
  );
};

ApprovalProcessForm.propTypes = {
  isSubmitting: PropTypes.bool,
  isConditionFieldsLoading: PropTypes.bool,
  isApprovalSettingLoading: PropTypes.bool,
  isApprovalProcessLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  fieldDefinitionsForConditionBuilder: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ApprovalProcessForm.defaultProps = {
  isSubmitting: false,
  isConditionFieldsLoading: false,
  isApprovalSettingLoading: false,
  isApprovalProcessLoading: false,
  formMode: FORM_MODES.CREATE,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  fieldDefinitionsForConditionBuilder: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
};

export default compose(WithSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(INITIAL_STATE, ACTION_HANDLERS))(ApprovalProcessForm);
