import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _size from 'lodash/size';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import RecordSharingRuleAssignmentDetailTable from '../recordSharingRuleAssignmentDetailTable';
import RecordGroupAsyncSelect from '../../../../molecules/recordGroupAsyncSelect';
import UserAsyncSelect from '../../../../molecules/userAsyncSelect/UserAsyncSelect';
import ACTION_TYPES from '../../constants/recordSharingRuleList.actionTypes';
import { ASSIGN_TYPES } from '../../constants/recordSharingRuleList.general.constants';

import styles from './recordSharingRuleAssignmentFieldRenderer.module.scss';

const RecordSharingRuleAssignmentFieldRenderer = ({
  containerClassname,
  userSearchText,
  userGroupSearchText,
  assignedUserList,
  assignedUserGroupList,
  filteredAssignedUserList,
  filteredAssignedUserGroupList,
  onAction,
}) => {
  const handleChangeAssignedUsers = useCallback(
    (selectedUsers) => {
      onAction({ type: ACTION_TYPES.ON_CHANGE_ASSIGNED_USERS, payload: { selectedUsers } });
    },
    [onAction],
  );

  const handleChangeAssignedUserGroups = useCallback(
    (selectedUserGroups) => {
      onAction({ type: ACTION_TYPES.ON_CHANGE_ASSIGNED_USER_GROUPS, payload: { selectedUserGroups } });
    },
    [onAction],
  );

  return (
    <div className={`${styles.mainContainer} ${containerClassname}`}>
      <div>
        <div className={styles.tableHeadingContainer}>{__(`Assigned Users (${_size(filteredAssignedUserList)})`)}</div>
        <UserAsyncSelect
          isMulti
          placeholder={__('Select Users')}
          key="select-bar"
          className={styles.asyncSelect}
          value={assignedUserList}
          onChange={handleChangeAssignedUsers}
        />
        <RecordSharingRuleAssignmentDetailTable
          assignType={ASSIGN_TYPES.USER}
          searchText={userSearchText}
          data={filteredAssignedUserList}
          onAction={onAction}
        />
      </div>
      <div>
        <div className={styles.tableHeadingContainer}>{__(`Assigned User Groups (${_size(filteredAssignedUserGroupList)})`)}</div>
        <RecordGroupAsyncSelect
          isMulti
          placeholder={__('Select User Groups')}
          key="select-bar"
          entityName="USER"
          className={styles.asyncSelect}
          value={assignedUserGroupList}
          onChange={handleChangeAssignedUserGroups}
        />
        <RecordSharingRuleAssignmentDetailTable
          assignType={ASSIGN_TYPES.USER_GROUP}
          searchText={userGroupSearchText}
          data={filteredAssignedUserGroupList}
          onAction={onAction}
        />
      </div>
    </div>
  );
};

RecordSharingRuleAssignmentFieldRenderer.propTypes = {
  containerClassname: PropTypes.string,
  userSearchText: PropTypes.string,
  userGroupSearchText: PropTypes.string,
  assignedUserList: PropTypes.array,
  assignedUserGroupList: PropTypes.array,
  filteredAssignedUserList: PropTypes.array,
  filteredAssignedUserGroupList: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

RecordSharingRuleAssignmentFieldRenderer.defaultProps = {
  containerClassname: EMPTY_STRING,
  userSearchText: EMPTY_STRING,
  userGroupSearchText: EMPTY_STRING,
  assignedUserList: EMPTY_STRING,
  assignedUserGroupList: EMPTY_STRING,
  filteredAssignedUserList: EMPTY_STRING,
  filteredAssignedUserGroupList: EMPTY_STRING,
};

export default RecordSharingRuleAssignmentFieldRenderer;
