import { PAGE_TYPES } from '../../../../../constants/visualBuilder';
import { WIDGET_TYPES } from '../../../constants/visualBuilder.general.constants';
import { WIDGET_PANELS } from '../constants/widgetPanel.constants';

const getMapOfWidgetsToWidgetPanel = (pageType) => {
  if (pageType === PAGE_TYPES.HOME_PAGE) {
    return {
      [WIDGET_PANELS.ELEMENTS]: [WIDGET_TYPES.BUTTON_WIDGET, WIDGET_TYPES.TAB_WIDGET, WIDGET_TYPES.TEXT_WIDGET],
      [WIDGET_PANELS.WIDGETS]: [WIDGET_TYPES.STANDARD_WIDGET],
      [WIDGET_PANELS.PAGES]: [],
    };
  }
  return {
    [WIDGET_PANELS.ELEMENTS]: [WIDGET_TYPES.BUTTON_WIDGET, WIDGET_TYPES.TAB_WIDGET, WIDGET_TYPES.TEXT_WIDGET],
    [WIDGET_PANELS.WIDGETS]: [WIDGET_TYPES.CHILD_WIDGET, WIDGET_TYPES.PARENT_WIDGET, WIDGET_TYPES.SIMILAR_WIDGET, WIDGET_TYPES.STANDARD_WIDGET],
    [WIDGET_PANELS.PAGES]: [],
  };
};

export { getMapOfWidgetsToWidgetPanel };
