import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/cms';

const saveEntityViewConfiguration = (entityName, payload) => ApiService.post(`${BASE_URL}/entities/${entityName}/entity-view-definitions`, payload);

const updateEntityViewConfiguration = (entityName, viewName, payload) =>
  ApiService.put(`${BASE_URL}/entities/${entityName}/entity-view-definitions/name/${viewName}`, payload);

const searchEntityViewConfigurations = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/entity-view-definitions/search`, payload);

const fetchEntityViewConfigurationByName = (entityName, viewName) =>
  ApiService.get(`${BASE_URL}/entities/${entityName}/entity-view-definitions/name/${viewName}`);

const deleteEntityViewConfiguration = (entityName, viewName) =>
  ApiService.delete(`${BASE_URL}/entities/${entityName}/entity-view-definitions/name/${viewName}`);

export default {
  searchEntityViewConfigurations,
  saveEntityViewConfiguration,
  updateEntityViewConfiguration,
  fetchEntityViewConfigurationByName,
  deleteEntityViewConfiguration,
};
