import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import IconRenderer from '../../../../atoms/IconRenderer';

const IconCellRenderer = makeCellRenderer(IconRenderer);

const COLUMN_IDS = {
  ICON: 'workspaceIcon',
  WORKSPACE_NAME: 'displayName',
  DESCRIPTION: 'description',
};

const COLUMN_NAMES = {
  [COLUMN_IDS.ICON]: __('Icon'),
  [COLUMN_IDS.WORKSPACE_NAME]: __('Workspace Name'),
  [COLUMN_IDS.DESCRIPTION]: __('Description'),
};

const COLUMN_CONFIG = [
  {
    Header: COLUMN_NAMES[COLUMN_IDS.ICON],
    accessor: COLUMN_IDS.ICON,
    key: COLUMN_IDS.ICON,
    id: COLUMN_IDS.ICON,
    Cell: IconCellRenderer,
  },
  {
    Header: COLUMN_NAMES[COLUMN_IDS.WORKSPACE_NAME],
    accessor: COLUMN_IDS.WORKSPACE_NAME,
    key: COLUMN_IDS.WORKSPACE_NAME,
    id: COLUMN_IDS.WORKSPACE_NAME,
    Cell: TextRenderer,
  },
  {
    Header: COLUMN_NAMES[COLUMN_IDS.DESCRIPTION],
    accessor: COLUMN_IDS.DESCRIPTION,
    key: COLUMN_IDS.DESCRIPTION,
    id: COLUMN_IDS.DESCRIPTION,
    Cell: TextRenderer,
  },
];

const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showTotalCount: true,
  showHeader: false,
  showSubHeader: true,
  showRefreshIcon: false,
  showSearch: true,
};

export { COLUMN_IDS, COLUMN_NAMES, COLUMN_CONFIG, TABLE_MANAGER_PROPS };
