import _reduce from 'lodash/reduce';

import { tget } from '@tekion/tekion-base/utils/general';

import { COMPONENT_TYPES } from './viewBuilder.constants';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../constants/viewBuilder.constants';
import { IMAGE_RADIO_BUTTON_VALUES } from '../organisms/propertiesForm/propertiesForm.constants';

const PROPERTY_KEY_TO_DEFAULT_VALUE_MAP = {
  [ALL_VIEW_PROPERTY_KEYS.TITLE]: 'Section',
  [ALL_VIEW_PROPERTY_KEYS.SECTION_TITLE_SIZE]: 1,
  [ALL_VIEW_PROPERTY_KEYS.COLUMN_COUNT]: 1,
  [ALL_VIEW_PROPERTY_KEYS.KEY_SPAN]: 8,
  [ALL_VIEW_PROPERTY_KEYS.VALUE_SPAN]: 15,
  [ALL_VIEW_PROPERTY_KEYS.WIDTH]: 100,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_PAGE_SIZE]: 10,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_MIN_ROWS]: 0,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_ROW_HEIGHT]: 50,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_CURRENT_PAGE]: 1,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_RESULT_PER_PAGE]: 10,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_PAGINATION]: true,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_FIXED_PAGINATION]: true,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_HEADER]: true,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_SUB_HEADER]: true,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_REFRESH_ICON]: true,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_FILTER]: true,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_SEARCH_BY_FIELD]: true,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_MULTI_SORT]: true,
  [ALL_VIEW_PROPERTY_KEYS.TABLE_PAGE_SIZE_OPTIONS]: [10, 20, 50],
  [ALL_VIEW_PROPERTY_KEYS.IMAGE_RADIO_BUTTON]: IMAGE_RADIO_BUTTON_VALUES.ICON,
  [ALL_VIEW_PROPERTY_KEYS.IMAGE_PATH]: '',
  [ALL_VIEW_PROPERTY_KEYS.GRID_TABLE_ROW_COUNT]: 3,
  [ALL_VIEW_PROPERTY_KEYS.GRID_TABLE_COLUMN_COUNT]: 3,
  [ALL_VIEW_PROPERTY_KEYS.DOWNLOAD_OPTIONS]: [],
  [ALL_VIEW_PROPERTY_KEYS.TEXT_AREA_ROWS]: 2,
  [ALL_VIEW_PROPERTY_KEYS.IS_KEY_VISIBLE]: true,
  [ALL_VIEW_PROPERTY_KEYS.IS_AVATAR_VIEW]: false,
  [ALL_VIEW_PROPERTY_KEYS.IS_RTE_TRUNCATED_TYPE]: false,
  [ALL_VIEW_PROPERTY_KEYS.DATE_TIME_FORMAT]: [],
};

const getDefaultValueFromFormFields = (id) => tget(PROPERTY_KEY_TO_DEFAULT_VALUE_MAP, id, null);

const LIST_VIEW_PROPERTIES = [
  ALL_VIEW_PROPERTY_KEYS.TITLE,
  ALL_VIEW_PROPERTY_KEYS.TABLE_MIN_ROWS,
  ALL_VIEW_PROPERTY_KEYS.TABLE_ROW_HEIGHT,
  ALL_VIEW_PROPERTY_KEYS.TABLE_CURRENT_PAGE,
  ALL_VIEW_PROPERTY_KEYS.TABLE_RESULT_PER_PAGE,
  ALL_VIEW_PROPERTY_KEYS.TABLE_PAGE_SIZE,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_PAGINATION,
  ALL_VIEW_PROPERTY_KEYS.TABLE_FIXED_PAGINATION,
  ALL_VIEW_PROPERTY_KEYS.SHOW_ACTIONS,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_HEADER,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_SUB_HEADER,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_REFRESH_ICON,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_FILTER,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_SEARCH_BY_FIELD,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_MULTI_SORT,
  ALL_VIEW_PROPERTY_KEYS.TABLE_PAGE_SIZE_OPTIONS,
  ALL_VIEW_PROPERTY_KEYS.WIDTH,
];

const GRID_VIEW_PROPERTIES = [
  ALL_VIEW_PROPERTY_KEYS.TITLE,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_PAGINATION,
  ALL_VIEW_PROPERTY_KEYS.TABLE_FIXED_PAGINATION,
  ALL_VIEW_PROPERTY_KEYS.SHOW_ACTIONS,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_HEADER,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_SUB_HEADER,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_REFRESH_ICON,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_FILTER,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_SEARCH_BY_FIELD,
  ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_MULTI_SORT,
  ALL_VIEW_PROPERTY_KEYS.GRID_TABLE_COLUMN_COUNT,
  ALL_VIEW_PROPERTY_KEYS.GRID_TABLE_ROW_COUNT,
];

const componentTypeToPropertiesMap = {
  [COMPONENT_TYPES.SECTION]: {
    [ALL_VIEW_PROPERTY_KEYS.COLUMN_COUNT]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.COLUMN_COUNT),
  },
  [COMPONENT_TYPES.SECTION_COLUMN]: { [ALL_VIEW_PROPERTY_KEYS.WIDTH]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.WIDTH) },
  [COMPONENT_TYPES.DETAIL_VIEW_ATTRIBUTE]: {
    [ALL_VIEW_PROPERTY_KEYS.KEY_SPAN]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.KEY_SPAN),
    [ALL_VIEW_PROPERTY_KEYS.VALUE_SPAN]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.VALUE_SPAN),
    [ALL_VIEW_PROPERTY_KEYS.WIDTH]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.WIDTH),
  },
  [COMPONENT_TYPES.LIST_VIEW_RENDERER]: _reduce(
    LIST_VIEW_PROPERTIES,
    (result, property) => ({
      ...result,
      [property]: getDefaultValueFromFormFields(property),
    }),
    {},
  ),
  [COMPONENT_TYPES.GRID_VIEW]: _reduce(
    GRID_VIEW_PROPERTIES,
    (result, property) => ({
      ...result,
      [property]: getDefaultValueFromFormFields(property),
    }),
    {},
  ),
  [COMPONENT_TYPES.LIST_VIEW_COLUMN]: {
    [ALL_VIEW_PROPERTY_KEYS.WIDTH]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.WIDTH),
  },
  [COMPONENT_TYPES.GRID_VIEW_ATTRIBUTE]: {},
  [COMPONENT_TYPES.CELL_VIEW_ATTRIBUTE]: {
    [ALL_VIEW_PROPERTY_KEYS.WIDTH]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.WIDTH),
  },
  [COMPONENT_TYPES.FORM_VIEW_SECTION]: {
    [ALL_VIEW_PROPERTY_KEYS.WIDTH]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.WIDTH),
  },
  [COMPONENT_TYPES.FORM_VIEW_ROW]: {
    [ALL_VIEW_PROPERTY_KEYS.WIDTH]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.WIDTH),
  },
  [COMPONENT_TYPES.FORM_VIEW_COLUMN]: {
    [ALL_VIEW_PROPERTY_KEYS.WIDTH]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.WIDTH),
  },
  [COMPONENT_TYPES.COLUMN]: {
    [ALL_VIEW_PROPERTY_KEYS.WIDTH]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.WIDTH),
  },
  [COMPONENT_TYPES.ROW]: {
    [ALL_VIEW_PROPERTY_KEYS.COLUMN_COUNT]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.COLUMN_COUNT),
  },
  [COMPONENT_TYPES.IMAGE]: {
    [ALL_VIEW_PROPERTY_KEYS.IMAGE_RADIO_BUTTON]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.IMAGE_RADIO_BUTTON),
    [ALL_VIEW_PROPERTY_KEYS.WIDTH]: getDefaultValueFromFormFields(ALL_VIEW_PROPERTY_KEYS.WIDTH),
  },
};

const componentTypeToDisplayNameMap = {
  [COMPONENT_TYPES.SECTION]: __('Section'),
  [COMPONENT_TYPES.COMMENT]: __('Comment'),
  [COMPONENT_TYPES.FORM_VIEW_SECTION]: __('Form Section'),
  [COMPONENT_TYPES.FORM_VIEW_ROW]: __('Form Row'),
  [COMPONENT_TYPES.ROW]: __('Cell Row'),
  [COMPONENT_TYPES.IMAGE]: __('Image'),
  [COMPONENT_TYPES.BUTTON]: __('Button'),
};

export { componentTypeToPropertiesMap, componentTypeToDisplayNameMap };
