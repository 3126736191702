import React from 'react';
import { ToolbarCodeBlock } from '@udecode/plate-code-block-ui';
import PropTypes from 'prop-types';
// components
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import useEditor from '../../../hooks/useEditor';
import { shouldEnableLink } from '../linkToolbarButton/helpers/linkToolbarButton.general';

function CodeBlockToolbarButton({ icon }) {
  const editor = useEditor();
  const isEnabled = shouldEnableLink(editor);

  return (
    <ToolbarCodeBlock
      icon={
        <FontIcon size={SIZES.MD_S} disabled={!isEnabled}>
          {icon}
        </FontIcon>
      }
    />
  );
}

CodeBlockToolbarButton.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default React.memo(CodeBlockToolbarButton);
