import { useEffect, useRef } from 'react';
import _isEqual from 'lodash/isEqual';
// ref from https://github.com/kentcdodds/use-deep-compare-effect

const deepCompareEquals = (a, b) => _isEqual(a, b);

const useDeepCompareMemoize = (value) => {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

const useDeepCompareEffect = (callback, dependencies) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

export default useDeepCompareEffect;
