import _get from 'lodash/get';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { FIELD_IDS } from '../constants/recordType.constants';
import { RECORD_TYPES } from '../../../../../constants/general.constants';
import styles from '../recordType.module.scss';

const getDerivationConditionSection = (entity) => {
  if (_get(entity, 'recordTypeDefinition.type') === RECORD_TYPES.DERIVED) {
    return [
      {
        className: styles.condition,
        rows: [
          {
            columns: [FIELD_IDS.DERIVATION_CONDITION],
          },
        ],
      },
    ];
  } else return EMPTY_ARRAY;
};

const getSections = (entity) => [
  {
    header: {
      label: __('Record Type Details'),
    },
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
      },
      {
        columns: [FIELD_IDS.DESCRIPTION],
      },
      {
        columns: [FIELD_IDS.STATUS],
      },
    ],
  },
  ...getDerivationConditionSection(entity),
];

export { getSections };
