import { combineReducers } from 'redux';

import { REDUCER_NAMES } from '../constants/reducers.constants';

import skeletonReducer from './skeleton.reducer';
import listViewReducer from './listViewRenderer.reducer';
import applicationVariablesReducer from './applicationVariables.reducer';

const defaultReducers = {
  [REDUCER_NAMES.SKELETON]: skeletonReducer,
  [REDUCER_NAMES.LIST_VIEW_RENDERER]: listViewReducer,
  [REDUCER_NAMES.APPLICATION_VARIABLES]: applicationVariablesReducer,
};

const rootReducer = combineReducers(defaultReducers);

export default {
  defaultReducers,
  rootReducer,
};
