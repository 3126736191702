exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".comment_commentContainer__aMug2EFeBp{display:flex}[dir] .comment_commentContainer__aMug2EFeBp{padding:.8rem}.comment_commentContainer__aMug2EFeBp .comment_indexContainer__u3Z9DGnYSm{position:relative}[dir] .comment_commentContainer__aMug2EFeBp .comment_indexContainer__u3Z9DGnYSm{padding:.3rem .9rem;border-radius:50%}.comment_commentContainer__aMug2EFeBp .comment_indexContainer__u3Z9DGnYSm::after{content:\"\";position:absolute;width:.1rem;top:3.5rem;height:100%}[dir] .comment_commentContainer__aMug2EFeBp .comment_indexContainer__u3Z9DGnYSm::after{background-color:#d4d5d6}[dir=ltr] .comment_commentContainer__aMug2EFeBp .comment_indexContainer__u3Z9DGnYSm::after{left:50%}[dir=rtl] .comment_commentContainer__aMug2EFeBp .comment_indexContainer__u3Z9DGnYSm::after{right:50%}.comment_commentContainer__aMug2EFeBp .comment_commentDetailsContainer__fDx79SeMJC{width:100%;overflow:hidden}[dir] .comment_commentContainer__aMug2EFeBp .comment_commentDetailsContainer__fDx79SeMJC{padding:.8rem}.comment_commentContainer__aMug2EFeBp .comment_commentDetailsContainer__fDx79SeMJC .comment_userDetailContainer__4y5WV8tBmi{color:#969aa3;display:flex;justify-content:space-between}[dir] .comment_commentContainer__aMug2EFeBp .comment_commentDetailsContainer__fDx79SeMJC .comment_userDetailContainer__4y5WV8tBmi{padding-bottom:2rem}.comment_commentContainer__aMug2EFeBp .comment_commentDetailsContainer__fDx79SeMJC .comment_userDetailContainer__4y5WV8tBmi .comment_userName__kKmxUK3sRD{font-weight:600}.comment_commentContainer__aMug2EFeBp .comment_commentDetailsContainer__fDx79SeMJC .comment_commentContent__wooGfCg1UG{display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis}", ""]);

// Exports
exports.locals = {
	"commentContainer": "comment_commentContainer__aMug2EFeBp",
	"indexContainer": "comment_indexContainer__u3Z9DGnYSm",
	"commentDetailsContainer": "comment_commentDetailsContainer__fDx79SeMJC",
	"userDetailContainer": "comment_userDetailContainer__4y5WV8tBmi",
	"userName": "comment_userName__kKmxUK3sRD",
	"commentContent": "comment_commentContent__wooGfCg1UG"
};