import _nth from 'lodash/nth';

import { ACTION_TYPES } from '../reportingWidgetRenderer.constants';

const handleApplyDashboardFilter = ({ setState, params }) => {
  const { value } = params;

  setState({
    timeRange: {
      startTime: _nth(value),
      endTime: _nth(value, 1),
    },
  });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_FIELD_CHANGE]: handleApplyDashboardFilter,
};

export default ACTION_HANDLERS;
