import { EDIT_DASHBOARD_COMPONENT_FIELDS } from '../constants/editDashboardComponent.general.constants';
import { DASHBOARD_NAME_FIELD, DISPLAY_NAME_FIELD } from './editDashboard.fields';
import styles from '../editDashboardModal.module.scss';

const FORM_SECTIONS = [
  {
    className: styles.sectionContainer,
    rows: [
      {
        columns: [EDIT_DASHBOARD_COMPONENT_FIELDS.DISPLAY_NAME],
      },
      {
        columns: [EDIT_DASHBOARD_COMPONENT_FIELDS.DASHBOARD_NAME],
      },
    ],
  },
];

const FORM_FIELDS = {
  [EDIT_DASHBOARD_COMPONENT_FIELDS.DASHBOARD_NAME]: DASHBOARD_NAME_FIELD,
  [EDIT_DASHBOARD_COMPONENT_FIELDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
};

export { FORM_FIELDS, FORM_SECTIONS };
