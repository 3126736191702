import _noop from 'lodash/noop';
import _head from 'lodash/head';

import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import {
  deleteSharedRecordForUser,
  fetchEntityAccessForUsers,
  fetchSharingDataForRecord,
  shareRecord,
} from '../../../../../actions/shareAndManageRecord.actions';
import { getWorkspaceUserList } from '../../../../../actions/workspaceUserManagement.actions';
import { fetchWwdPermissionList } from '../../../../../actions/wwdPermissionsManagement.actions';

import {
  entityAccessForUsersPayloadCreation,
  manageAccessDataPayloadCreation,
  userDataFromIdsPayloadCreation,
  shareRecordUsersPayloadCreation,
  wwdPayloadCreation,
  deleteSharedRecordPayloadCreation,
} from './useShareAndManageRecordModal.request';
import { getUserIdsFromManageAccessesData, combineAllUserManageAndEntityAccessData } from './useShareAndManageRecordModal.response';

import { SHARE_AND_MANAGE_RECORD_MODAL_MODE } from '../useShareAndManageRecordModal.constants';
import SearchResponseReader from '../../../../../readers/searchResponse.reader';

const fetchUserDataFromIds = async (userIds) => {
  const searchRequest = userDataFromIdsPayloadCreation(userIds);
  const response = await getWorkspaceUserList(searchRequest);
  const usersData = SearchResponseReader.hits(response);

  return usersData;
};

const fetchManageAccessRecordData = async (entityDef, entityRecord) => {
  const { entityName, recordId } = manageAccessDataPayloadCreation(entityDef, entityRecord);
  const response = await fetchSharingDataForRecord(entityName, recordId);

  return response;
};

const fetchDependentDataOfManageAccess = async (manageAccessData, entityDef, entityRecord) => {
  const userIds = getUserIdsFromManageAccessesData(manageAccessData);
  const { entityName, recordTypeId } = entityAccessForUsersPayloadCreation(entityDef, entityRecord);

  const promises = [];
  promises.push(fetchUserDataFromIds(userIds));
  promises.push(fetchEntityAccessForUsers(entityName, recordTypeId, userIds));
  const responses = await Promise.all(promises);

  return { usersData: responses[0], entityAccessUsersData: responses[1] };
};

const fetchOnModalOpenManageAccessData = async ({ entityDef, entityRecord, setShareAndManageRecordUsersData = _noop, setIsDataLoading = _noop }) => {
  setIsDataLoading(true);

  const manageAccessData = await fetchManageAccessRecordData(entityDef, entityRecord);

  const { usersData, entityAccessUsersData } = await fetchDependentDataOfManageAccess(manageAccessData, entityDef, entityRecord);

  const data = combineAllUserManageAndEntityAccessData(usersData, manageAccessData, entityAccessUsersData);

  setShareAndManageRecordUsersData(data);

  setIsDataLoading(false);
};

const fetchOnModalOpenData = ({ mode, entityDef, entityRecord, setShareAndManageRecordUsersData = _noop, setIsDataLoading = _noop }) => {
  if (mode === SHARE_AND_MANAGE_RECORD_MODAL_MODE.MANAGE_RECORD) {
    fetchOnModalOpenManageAccessData({ entityDef, entityRecord, setShareAndManageRecordUsersData, setIsDataLoading });
  }
};

const shareRecordWithUsers = async ({
  entityRecord,
  entityDef,
  shareUsersData,
  setIsDataSubmitting = _noop,
  onShareAndManageRecordModalClose = _noop,
}) => {
  const payload = shareRecordUsersPayloadCreation(shareUsersData, entityDef, entityRecord);

  setIsDataSubmitting(true);

  await shareRecord(payload);

  setIsDataSubmitting(false);
  onShareAndManageRecordModalClose();
};

const fetchWWDData = async (entityDef) => {
  const searchRequest = wwdPayloadCreation(entityDef);

  const response = await fetchWwdPermissionList(searchRequest);

  const wwdData = _head(SearchResponseReader.hits(response));

  return wwdData;
};

const deleteSharedRecordData = async (sharedRecordUserData, entityDef, entityRecord) => {
  const { recordId, userId, entityName } = deleteSharedRecordPayloadCreation(sharedRecordUserData, entityDef, entityRecord);

  toaster(TOASTER_TYPE.INFO, __('Delete is in process, please wait for some time'));

  const response = await deleteSharedRecordForUser(entityName, recordId, userId);

  return response;
};

export { fetchOnModalOpenData, shareRecordWithUsers, fetchWWDData, deleteSharedRecordData };
