import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import { tget } from '@tekion/tekion-base/utils/general';

import getFields from '../helpers/sortOptionsConfigurator.fields';
import getSortOptionsFormSection from '../helpers/sortOptionsConfigurator.sections';

import { FIELD_IDS, SORT_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID } from '../constants/sortOptionsConfigurator.constants';

import styles from '../sortOptionsConfigurator.module.scss';

const SortOptionsModal = ({ error, allFormValues, sortableFieldOptions, selectedSortableFieldOptions, onAction }) => {
  const defaultSortDetails = tget(allFormValues, [FIELD_IDS.DEFAULT_SORT_DETAILS], EMPTY_ARRAY);

  const fields = useMemo(
    () => getFields(sortableFieldOptions, selectedSortableFieldOptions, defaultSortDetails, error),
    [sortableFieldOptions, selectedSortableFieldOptions, defaultSortDetails, error],
  );

  const sections = useMemo(() => getSortOptionsFormSection(), []);

  return (
    <div className={styles.sortOptionsHandlerModal}>
      <div className={styles.rightPanel}>
        <PropertyControlledComponent controllerProperty={!_isEmpty(allFormValues)}>
          <FormWithSubmission
            className="full-width p-t-12"
            contextId={SORT_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID}
            fields={fields}
            sections={sections}
            values={allFormValues}
            onAction={onAction}
          />
        </PropertyControlledComponent>
      </div>
    </div>
  );
};

SortOptionsModal.propTypes = {
  error: PropTypes.object,
  allFormValues: PropTypes.array,
  sortableFieldOptions: PropTypes.array,
  selectedSortableFieldOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

SortOptionsModal.defaultProps = {
  error: EMPTY_OBJECT,
  sortableFieldOptions: EMPTY_ARRAY,
  selectedSortableFieldOptions: EMPTY_ARRAY,
  allFormValues: EMPTY_ARRAY,
};

export default SortOptionsModal;
