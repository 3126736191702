import _keyBy from 'lodash/keyBy';
import _values from 'lodash/values';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _has from 'lodash/has';
import _uniq from 'lodash/uniq';
import _compact from 'lodash/compact';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchEntities } from './entityManagement.actions';

// Readers
import entityReader from '../readers/entity.reader';
import fieldDefinitionReader from '../readers/fieldDefinition.reader';
import DATA_TYPES from '../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../constants/fieldDefinition.fieldTypes';

// Services
import conditionBuilderServices from '../services/conditionBuilder.services';

const MAX_RECURSIVE_DEPTH_FOR_FETCH_FIELD_DEFINITIONS = 4;

const fetchEntitiesWithFieldDefinitions = async (entitiesName) => {
  const payload = { filters: [{ field: 'name', filterType: OPERATORS.IN, values: entitiesName }] };
  const response = await fetchEntities(payload, true);
  return tget(response, 'hits', EMPTY_ARRAY);
};

const fetchingRecursiveEntityFieldDefinitions = async (entitiesName, isRelationshipRecursive, isComplexRecursive, result, currentDepth) => {
  if (_isEmpty(entitiesName) || currentDepth > MAX_RECURSIVE_DEPTH_FOR_FETCH_FIELD_DEFINITIONS) {
    return {};
  }

  const response = await fetchEntitiesWithFieldDefinitions(entitiesName);
  const newEntitiesNameToFetch = [];
  const mapOfEntityNameToFieldDefinitions = _reduce(
    response,
    (ans, data) => {
      const name = entityReader.name(data);
      const fieldDefinitionsByName = _keyBy(entityReader.fieldDefinitions(data), 'name');
      _set(ans, name, fieldDefinitionsByName);
      _forEach(fieldDefinitionsByName, (fieldDef) => {
        const dataType = fieldDefinitionReader.dataType(fieldDef);
        const fieldType = fieldDefinitionReader.fieldType(fieldDef);
        if (dataType === DATA_TYPES.COMPLEX && isComplexRecursive) {
          const complexEntityName = _get(fieldDef, 'complexFieldDefinition.entityName');
          newEntitiesNameToFetch.push(complexEntityName);
        } else if (fieldType === FIELD_TYPES.RELATIONSHIP && isRelationshipRecursive) {
          const relationshipEntityName = _get(fieldDef, 'lookupField.entityType');
          newEntitiesNameToFetch.push(relationshipEntityName);
        }
      });
      return ans;
    },
    result,
  );

  const nonExistingEntitiesNameToFetch = _uniq(_filter(newEntitiesNameToFetch, (entityName) => !_has(mapOfEntityNameToFieldDefinitions, entityName)));

  await fetchingRecursiveEntityFieldDefinitions(
    nonExistingEntitiesNameToFetch,
    isRelationshipRecursive,
    isComplexRecursive,
    mapOfEntityNameToFieldDefinitions,
    currentDepth + 1,
  );

  return {};
};

const fetchFieldDefinitionsForConditionBuilder = async (
  mapOfVariablesToEntityName,
  alreadyPresentFieldDefinitions = {},
  isSystemResourceNeeded = true,
  isRelationshipRecursive = true,
  isComplexRecursive = true,
) => {
  const entitiesName = _compact(_filter(_values(mapOfVariablesToEntityName), (value) => !_has(alreadyPresentFieldDefinitions, value)));
  const data = { ...alreadyPresentFieldDefinitions };

  await fetchingRecursiveEntityFieldDefinitions(entitiesName, isRelationshipRecursive, isComplexRecursive, data, 0);

  if (isSystemResourceNeeded) {
    const systemResourceResponse = await conditionBuilderServices.fetchSystemResources();
    const systemResources = getDataFromResponse(systemResourceResponse);
    _forEach(systemResources, (systemResource) => {
      const namespaces = _get(systemResource, 'namespaces');
      const fieldDefinitions = _get(systemResource, 'fieldDefinitions', EMPTY_OBJECT);
      const fieldDefinitionByName = _keyBy(fieldDefinitions, 'name');
      _forEach(namespaces, (namespace) => {
        _set(data, namespace, fieldDefinitionByName);
        _set(mapOfVariablesToEntityName, namespace, namespace);
      });
    });
  }

  return data;
};

export { fetchFieldDefinitionsForConditionBuilder };
