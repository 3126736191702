import { defaultMemoize } from 'reselect';
import _size from 'lodash/size';

import { ROW_ACTIONS } from '../constants/fieldList.columns';

const createTableProps = defaultMemoize((onAction, loading, currentPage, pageSize, fields) => ({
  loading,
  currentPage: currentPage + 1,
  resultPerPages: pageSize,
  pageSize,
  totalNumberOfEntries: _size(fields),
  rowHeight: 35,
  minRows: 0,
  onRowActionClick: (type, payload) => onAction({ type, payload }),
  showActions: true,
  getRowActions: () => ROW_ACTIONS,
}));

export { createTableProps };
