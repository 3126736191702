exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".auditLogs_footerContainer__tMKpWxCCxM{height:6.4rem}.auditLogs_drawerContainer__xiJqqvmzY7{position:static}.auditLogs_modalScroll__bXUTxuYUDt{overflow-y:scroll}.auditLogs_scrollTopButton__28btEDkQEw{position:fixed;bottom:2rem}[dir=ltr] .auditLogs_scrollTopButton__28btEDkQEw{left:2rem}[dir=rtl] .auditLogs_scrollTopButton__28btEDkQEw{right:2rem}.auditLogs_header__iSJXQUP4u9{height:5.6rem;font-family:\"Proxima-Nova-Semibold\"}[dir] .auditLogs_header__iSJXQUP4u9{background-color:#edeef0;padding:0 2rem}", ""]);

// Exports
exports.locals = {
	"footerContainer": "auditLogs_footerContainer__tMKpWxCCxM",
	"drawerContainer": "auditLogs_drawerContainer__xiJqqvmzY7",
	"modalScroll": "auditLogs_modalScroll__bXUTxuYUDt",
	"scrollTopButton": "auditLogs_scrollTopButton__28btEDkQEw",
	"header": "auditLogs_header__iSJXQUP4u9"
};