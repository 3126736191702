import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/tasks';

const createJob = (payload) => ApiService.post(`${BASE_URL}`, payload);

const searchJob = (payload) => ApiService.post(`${BASE_URL}/search`, payload);

const getJob = (id) => ApiService.get(`${BASE_URL}/${id}`);

const updateJob = (id, payload) => ApiService.put(`${BASE_URL}/${id}`, payload);

const deleteJob = (id) => ApiService.delete(`${BASE_URL}/${id}`);

const getTimeZones = () => ApiService.get(`${BASE_URL}/utility/bootstrap`);

const executionLogOfJob = (id) => ApiService.get(`${BASE_URL}/${id}/info`);

export default {
  createJob,
  searchJob,
  getJob,
  updateJob,
  deleteJob,
  getTimeZones,
  executionLogOfJob,
};
