const LOOKUP_ITEM_TO_RESOLVE = {
  USER: 'user',
  USER_GROUP: 'user_group',
  PROJECT: 'project',
  ENTITY: 'entity',
};

const LOOKUP_FIELD_TO_RESOLVE = {
  ID: 'id',
  NAME: 'name',
};

export { LOOKUP_ITEM_TO_RESOLVE, LOOKUP_FIELD_TO_RESOLVE };
