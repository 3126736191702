// Renderers
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

// Constants
import { COLUMN_IDS, ACTION_TYPES } from '../constants/templateBuilderList.contants';

const COLUMN_CONFIG = [
  {
    Header: __('Display name'),
    accessor: COLUMN_IDS.DISPLAY_NAME,
    id: COLUMN_IDS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Name'),
    accessor: COLUMN_IDS.NAME,
    id: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Template Type'),
    accessor: COLUMN_IDS.TEMPLATE_TYPE,
    id: COLUMN_IDS.TEMPLATE_TYPE,
    Cell: TextRenderer,
  },
  {
    Header: __('Description'),
    accessor: COLUMN_IDS.DESCRIPTION,
    id: COLUMN_IDS.DESCRIPTION,
    Cell: TextRenderer,
  },
];

const EDIT_TEMPLATE_DETAIL = {
  id: ACTION_TYPES.EDIT_TEMPLATE_DETAIL,
  name: __('Edit Template'),
};

const DELETE_TEMPLATE = {
  id: ACTION_TYPES.DELETE_TEMPLATE_ROW_ACTION_CLICK,
  name: __('Delete Template'),
};

const ROW_ACTIONS = [EDIT_TEMPLATE_DETAIL, DELETE_TEMPLATE];

export { COLUMN_CONFIG, ROW_ACTIONS };
