import _get from 'lodash/get';
import _head from 'lodash/head';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { fetchEntityDefByName, fetchEntities } from '../../../../../actions/entityManagement.actions';
import { searchViewConfigurations } from '../../../../../actions/viewBuilderPage.actions';

import { getResolvedEntityDefinitions } from '../../../../../helpers/entityManagement.helpers';
import { getComplexFieldPayload, getPayloadForViewDefinitionSearch, getUpdatedEventHandlers } from './entityRecordListPage.helpers';
import { getPropertyFromViewConfig, updatePropertyInViewConfig } from '../../../../../organisms/viewBuilder/helpers/viewBuilder.helper';

import { ACTION_TYPES } from '../constants/entityRecordListPage.constants';
import PAGE_IDS from '../../../constants/pageIds.constants';
import {
  VIEW_CONFIG_TYPES,
  VIEW_TYPES,
  ALL_VIEW_PROPERTY_KEYS,
  VIEW_CONFIGURATION_GENERAL_KEYS,
} from '../../../../../constants/viewBuilder.constants';
import { COMPONENT_TYPES } from '../../../../../organisms/viewBuilder/constants/viewBuilder.constants';

const handleInit = async ({ getState, setState }) => {
  const { match } = getState();
  const { entityName, entityRecordId } = _get(match, 'params', {});
  const payload = getPayloadForViewDefinitionSearch([entityName], [VIEW_TYPES.LIST_VIEW], [VIEW_CONFIG_TYPES.STANDARD]);
  const [entity, listViewConfigResponse] = await Promise.all([fetchEntityDefByName(entityName), searchViewConfigurations(payload)]);
  const listViewConfig = _head(tget(listViewConfigResponse, 'hits', [{}]));

  const result = [];

  getPropertyFromViewConfig(
    COMPONENT_TYPES.LIST_VIEW_RENDERER,
    tget(listViewConfig, 'section'),
    `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.EVENT_HANDLERS}`,
    EMPTY_ARRAY,
    result,
  );

  const eventHandlers = getArraySafeValue(result);

  const updatedEventHandlers = getUpdatedEventHandlers(eventHandlers);

  updatePropertyInViewConfig(
    COMPONENT_TYPES.LIST_VIEW_RENDERER,
    `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.EVENT_HANDLERS}`,
    updatedEventHandlers,
    tget(listViewConfig, 'section'),
  );

  const complexEntityPayload = getComplexFieldPayload(entity);

  const complexEntity = await fetchEntities(complexEntityPayload, true);

  const complexAndRelationshipEntities = tget(complexEntity, 'hits', EMPTY_ARRAY);
  const complexAndRelationshipEntitiesByName = _keyBy(complexAndRelationshipEntities, 'name');
  const updatedEntity = getResolvedEntityDefinitions(entity, complexAndRelationshipEntitiesByName);
  // adding complex and relationship lookup entity's field def in  original field
  if (!_isEmpty(entityRecordId)) {
    setState({ entity: updatedEntity, listViewConfig, isDrawerVisible: true, entityRecordId });
  } else setState({ entity: updatedEntity, listViewConfig });
};

const handleSidePaneClick = ({ getState, setState, params }) => {
  const { history, match } = getState();
  const { entityName } = _get(match, 'params', {});
  const entityRecord = _get(params, 'entityRecord', {});
  const entityRecordId = _get(entityRecord, 'id', EMPTY_STRING);
  const { search } = history.location;

  setState({ isDrawerVisible: true, entityRecordId });
  const path = `${entityName}/${PAGE_IDS.ENTITY_RECORD_RIGHT_PANE}/${entityRecordId}`;
  history.push({ pathname: path, search, state: { entityRecord } });
};

const handleCloseSidePane = ({ getState, setState }) => {
  const { history, match } = getState();
  const { entityName } = _get(match, 'params', {});
  const { search } = history.location;
  setState({ isDrawerVisible: false });
  const path = `/entities/${entityName}`;
  history.push({ pathname: path, search });
};

const handleCreateClick = ({ getState }) => {
  const { history, match, entity } = getState();
  const entityName = _get(match, 'params.entityName', '');
  const path = `${entityName}/${PAGE_IDS.CREATE_ENTITY_RECORD}`;

  history.push({ pathname: path, state: { entity } });
};

const handleGoBack = ({ getState }) => {
  const { history } = getState();
  history.push('/entities');
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [ACTION_TYPES.ON_OPEN_SIDE_PANE]: handleSidePaneClick,
  [ACTION_TYPES.ON_CLOSE_SIDE_PANE]: handleCloseSidePane,
  [ACTION_TYPES.ON_CLICK_CREATE]: handleCreateClick,
  [ACTION_TYPES.GO_BACK]: handleGoBack,
};

export default ACTION_HANDLERS;
