import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Button from '@tekion/tekion-components/atoms/Button';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import { tget } from '@tekion/tekion-base/utils/general';
import AsyncMentions from '../../../molecules/asyncMentions';

import { checkCommentContentIsValid } from '../helpers/commentRenderer.helpers';

import { BUTTON_ACTION_TYPES, BUTTON_LABELS, COMMENT_MODES } from '../constants/commentRenderer.constants';
import ACTION_TYPES from '../constants/commentRenderer.actionTypes';
import { MENTIONS_ACTION_TYPES } from '../../../molecules/asyncMentions/asyncMentions.constants';

import commentReader from '../../../readers/comment.reader';

import styles from '../commentRenderer.module.scss';
import workspaceUserReader from '../../../readers/workSpaceUser.reader';
import UserAvatar from '../../../atoms/userAvatar/UserAvatar';

const CommentInput = ({
  isPreviewMode,
  commentIndex,
  parentCommentIndex,
  parentCommentId,
  commentMode,
  commentLevel,
  comment,
  currentUser,
  scrollToTopComment,
  onAction,
}) => {
  const [commentContent, setCommentContent] = useState(commentReader.content(comment) || EMPTY_STRING);
  const [isCommentInputBoxFocused, setIsCommentInputBoxFocused] = useState(false);
  const isCommentContentValid = useMemo(() => checkCommentContentIsValid(commentContent), [commentContent]);

  const handleAction = useCallback(
    (action) => {
      const type = tget(action, 'type');
      const payload = tget(action, 'payload');

      switch (type) {
        case MENTIONS_ACTION_TYPES.ON_CHANGE_MENTIONS_CONTENT: {
          const newContent = tget(payload, 'newContent', EMPTY_STRING);
          setCommentContent(newContent);
          break;
        }
        default: {
          onAction(action);
        }
      }
    },
    [onAction],
  );

  const handleCreateCommentClick = useCallback(() => {
    const commentId = commentReader.id(comment);
    if (isPreviewMode) {
      if (commentMode === COMMENT_MODES.EDIT) {
        onAction({ type: ACTION_TYPES.EDIT_COMMENT, payload: { commentId, content: commentContent } });
        setIsCommentInputBoxFocused(false);
        setCommentContent('');
      } else {
        const inReplyTo = parentCommentId || commentReader.inReplyTo(comment) || commentId;
        onAction({ type: ACTION_TYPES.CREATE_COMMENT, payload: { inReplyTo, content: commentContent, scrollToTopComment } });
        setIsCommentInputBoxFocused(false);
        setCommentContent('');
      }
    }
  }, [comment, isPreviewMode, commentMode, onAction, commentContent, parentCommentId, scrollToTopComment]);

  const handleCancelClick = useCallback(() => {
    if (commentMode === COMMENT_MODES.CREATE) {
      setIsCommentInputBoxFocused(false);
      setCommentContent(commentReader.content(comment) || EMPTY_STRING);
      if (!_isEmpty(parentCommentId)) {
        onAction({ type: ACTION_TYPES.TOGGLE_REPLY_INPUT, payload: { commentId: parentCommentId } });
      }
    } else {
      onAction({
        type: ACTION_TYPES.CHANGE_COMMENT_MODE,
        payload: { commentMode: COMMENT_MODES.VIEW, commentPath: { commentIndex, parentCommentIndex } },
      });
    }
  }, [commentMode, comment, parentCommentId, onAction, commentIndex, parentCommentIndex]);

  const onFocusCommentInputBox = useCallback(() => {
    setIsCommentInputBoxFocused(true);
  }, []);

  return (
    <div className={cx('full-width', styles.commentInputBox)}>
      <div className={styles.commentInputArea}>
        <div onFocus={onFocusCommentInputBox} className={styles.mentionsInputContainer}>
          <UserAvatar
            userAvatar={workspaceUserReader.profilePicture(currentUser)}
            displayName={workspaceUserReader.NAME(currentUser)}
            userAvatarContainerClassName={styles.userAvatar}
            avatarSize={commentLevel === 1 ? 'XXL' : 'small'}
          />
          <AsyncMentions content={commentContent} onAction={handleAction} />
        </div>

        <PropertyControlledComponent controllerProperty={isCommentInputBoxFocused}>
          <Button
            view={Button.VIEW.TERTIARY}
            label={BUTTON_LABELS[BUTTON_ACTION_TYPES.CANCEL]}
            className={styles.commentInputBoxButton}
            onClick={handleCancelClick}
          />
          <Button
            view={Button.VIEW.TERTIARY}
            label={commentLevel === 1 ? BUTTON_LABELS[BUTTON_ACTION_TYPES.CREATE_COMMENT] : BUTTON_LABELS[BUTTON_ACTION_TYPES.CREATE_REPLY]}
            className={cx(styles.commentInputBoxButton, { [styles.commentInputBoxDisabledButton]: !isCommentContentValid })}
            onClick={handleCreateCommentClick}
          />
        </PropertyControlledComponent>
      </div>
    </div>
  );
};

CommentInput.propTypes = {
  isPreviewMode: PropTypes.bool,
  commentIndex: PropTypes.number,
  parentCommentIndex: PropTypes.number,
  commentLevel: PropTypes.number,
  parentCommentId: PropTypes.string,
  commentMode: PropTypes.string,
  comment: PropTypes.object,
  currentUser: PropTypes.object,
  scrollToTopComment: PropTypes.func,
  onAction: PropTypes.func.isRequired,
};

CommentInput.defaultProps = {
  isPreviewMode: true,
  commentIndex: undefined,
  commentLevel: undefined,
  parentCommentIndex: undefined,
  parentCommentId: undefined,
  commentMode: COMMENT_MODES.CREATE,
  comment: EMPTY_OBJECT,
  currentUser: EMPTY_OBJECT,
  scrollToTopComment: _noop,
};

export default React.memo(CommentInput);
