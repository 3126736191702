import React, { useCallback, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';

// Tekion-components
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';

// Tekion-connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Connectors

import withSize from '../../../../../../connectors/withSize';

// Helpers
import { createFilterProps, createTableProps } from './helpers/approvalProcessList.helpers';
import COLUMN_CONFIG from './helpers/approvalProcessList.columns';
import ACTION_HANDLERS from './helpers/approvalProcessList.actionHandlers';

// Constants
import { HEADER_PROPS, SUB_HEADER_PROPS, TABLE_MANAGER_PROPS } from './constants/approvalProcessList.constants';
import ACTION_TYPES from './constants/approvalProcessList.actionTypes';

const ApprovalGroupList = ({
  isLoading,
  isRequestDialogVisible,
  contentHeight,
  currentPage,
  pageSize,
  modalContent,
  modalTitle,
  searchText,
  nextPageToken,
  data,
  selectedFilters,
  onAction,
}) => {
  const tableProps = useMemo(() => createTableProps(isLoading, currentPage, pageSize, onAction), [isLoading, currentPage, pageSize, onAction]);
  const filterProps = useMemo(() => createFilterProps(isLoading, currentPage, pageSize, onAction), [isLoading, currentPage, pageSize, onAction]);

  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CANCEL_CONFIRMATION_MODAL,
    });
  }, [onAction]);

  const handleConfirmationDialogSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_SUBMIT_CONFIRMATION_MODAL });
  }, [onAction]);

  return (
    <Page style={{ height: contentHeight }}>
      <TableManager
        tokenPagination
        nextPageToken={nextPageToken}
        searchText={searchText}
        headerProps={HEADER_PROPS}
        subHeaderProps={SUB_HEADER_PROPS}
        tableManagerProps={TABLE_MANAGER_PROPS}
        tableProps={tableProps}
        filterProps={filterProps}
        columns={COLUMN_CONFIG}
        data={data}
        selectedFilters={selectedFilters}
        onAction={onAction}
      />
      <ConfirmationDialog
        isVisible={isRequestDialogVisible}
        title={modalTitle}
        content={modalContent}
        onSubmit={handleConfirmationDialogSubmit}
        onCancel={handleConfirmationDialogCancel}
      />
    </Page>
  );
};

ApprovalGroupList.propTypes = {
  isLoading: PropTypes.bool,
  isRequestDialogVisible: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  searchText: PropTypes.string,
  modalContent: PropTypes.string,
  modalTitle: PropTypes.string,
  nextPageToken: PropTypes.string,
  data: PropTypes.array,
  selectedFilters: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ApprovalGroupList.defaultProps = {
  isLoading: false,
  isRequestDialogVisible: false,
  currentPage: DEFAULT_PAGE_INFO.start,
  pageSize: DEFAULT_PAGE_SIZE,
  searchText: EMPTY_STRING,
  modalContent: EMPTY_STRING,
  modalTitle: EMPTY_STRING,
  nextPageToken: EMPTY_STRING,
  data: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: false }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ApprovalGroupList);
