// Lodash
import _join from 'lodash/join';

// Constants
import { MARK_BOLD, MARK_ITALIC, MARK_UNDERLINE, MARK_STRIKETHROUGH } from '@udecode/plate-basic-marks';
import { ELEMENT_IMAGE } from '@udecode/plate-image';
import { ELEMENT_TABLE } from '@udecode/plate-table';
import { ELEMENT_OL, ELEMENT_UL } from '@udecode/plate-list';
import { ELEMENT_LINK } from '@udecode/plate-link';
import { ELEMENT_CODE_BLOCK } from '@udecode/plate-code-block';

// constants
import MIME_TYPES from '@tekion/tekion-base/constants/ExtensionVsMIME';
import { IMAGE_MIME_TYPES } from '@tekion/tekion-base/constants/mediaMimeTypes';
// import { ELEMENT_VIDEO } from '../../plugins/video';
// import { ELEMENT_TEMPLATE_VARIABLE } from '../../plugins/templateVariable';

// Components
import MediaUploader from '../molecules/mediaUploader';
import MarkToolbarButton from '../molecules/markToolbarButton';
import AlignmentToolbarButton from '../molecules/alignmentToolbarButton';
import TableToolbarButton from '../molecules/tableToolbarButton';
import ListToolbarButton from '../molecules/listToolbarButton';
import LinkToolbarButton from '../molecules/linkToolbarButton';
import CodeBlockToolbarButton from '../molecules/codeBlockToolbarButton';
// import TemplateVariableToolbarButton from '../molecules/templateVariableToolbarButton';
import FontColorToolbarButton from '../molecules/fontColorToolbarButton';
import FontSizeToolbarButton from '../molecules/fontSizeToolbarButton';
import ResetToolbarButton from '../molecules/resetToolbarButton';
import ShortcutsInfoToolbarButton from '../molecules/shortcutsInfoToolbarButton';

import { TOOLBAR_GROUPS } from '../../constants/richTextEditor.plugins';

export const MARK_OPTIONS_CONFIG = [
  {
    id: MARK_BOLD,
    renderProps: {
      icon: 'icon-bold',
    },
    renderer: MarkToolbarButton,
  },
  {
    id: MARK_ITALIC,
    renderProps: {
      icon: 'icon-italic',
    },
    renderer: MarkToolbarButton,
  },
  {
    id: MARK_UNDERLINE,
    renderProps: {
      icon: 'icon-underline',
    },
    renderer: MarkToolbarButton,
  },
  {
    id: MARK_STRIKETHROUGH,
    renderProps: {
      mark: MARK_STRIKETHROUGH,
      icon: 'icon-strikethrough',
    },
    renderer: MarkToolbarButton,
  },
];

const FONT_COLOR_CONFIG = [
  {
    id: 'FONT_COLOR',
    renderer: FontColorToolbarButton,
  },
];

const ALIGNMENT_OPTIONS_CONFIG = [
  {
    id: 'left',
    renderProps: {
      icon: 'icon-left-aligned',
    },
    renderer: AlignmentToolbarButton,
  },
  {
    id: 'center',
    renderProps: {
      icon: 'icon-center-aligned',
    },
    renderer: AlignmentToolbarButton,
  },
  {
    id: 'right',
    renderProps: {
      icon: 'icon-right-aligned',
    },
    renderer: AlignmentToolbarButton,
  },
  {
    id: 'justify',
    renderProps: {
      icon: 'icon-format-align-justify',
    },
    renderer: AlignmentToolbarButton,
  },
];

const LISTS_CONFIG = [
  {
    id: ELEMENT_OL,
    renderer: ListToolbarButton,
    renderProps: {
      icon: 'icon-numbered-list',
    },
  },
  {
    id: ELEMENT_UL,
    renderer: ListToolbarButton,
    renderProps: {
      icon: 'icon-bulleted-list',
    },
  },
];

const CODE_CONFIG = [
  {
    id: ELEMENT_CODE_BLOCK,
    renderer: CodeBlockToolbarButton,
    renderProps: {
      icon: 'icon-html',
    },
  },
];

const INSERT_OPTIONS_CONFIG = [
  {
    id: ELEMENT_TABLE,
    renderer: TableToolbarButton,
  },
  {
    id: ELEMENT_IMAGE,
    renderProps: {
      icon: 'icon-insert-image',
      accept: _join([...IMAGE_MIME_TYPES, MIME_TYPES.gif], ','),
    },
    renderer: MediaUploader,
  },
  // {
  //   id: ELEMENT_VIDEO,
  //   renderProps: {
  //     icon: 'icon-insert-video',
  //     accept: MIME_TYPES.mp4,
  //   },
  //   renderer: MediaUploader,
  // },

  {
    id: ELEMENT_LINK,
    renderer: LinkToolbarButton,
  },
  // {
  //   id: ELEMENT_TEMPLATE_VARIABLE,
  //   renderer: TemplateVariableToolbarButton,
  // },
];

const FONT_SIZE_CONFIG = [
  {
    id: 'FONT_SIZE',
    renderer: FontSizeToolbarButton,
  },
];

const RESET_CONFIG = [
  {
    id: 'RESET',
    renderer: ResetToolbarButton,
    renderProps: {
      icon: 'icon-trash',
    },
  },
];

const SHORTCUTS_INFO_CONFIG = [
  {
    id: 'SHORTCUTS',
    renderer: ShortcutsInfoToolbarButton,
    renderProps: {
      icon: 'icon-info',
    },
  },
];

const TOOLBAR_CONFIG = {
  [TOOLBAR_GROUPS.FONT_SIZE]: { id: TOOLBAR_GROUPS.FONT_SIZE, options: FONT_SIZE_CONFIG },
  [TOOLBAR_GROUPS.LIST]: { id: TOOLBAR_GROUPS.LIST, options: LISTS_CONFIG },
  [TOOLBAR_GROUPS.MARKS]: { id: TOOLBAR_GROUPS.MARKS, options: MARK_OPTIONS_CONFIG },
  [TOOLBAR_GROUPS.FONT_COLOR]: { id: TOOLBAR_GROUPS.FONT_COLOR, options: FONT_COLOR_CONFIG },
  [TOOLBAR_GROUPS.ALIGNMENT]: { id: TOOLBAR_GROUPS.ALIGNMENT, options: ALIGNMENT_OPTIONS_CONFIG },
  [TOOLBAR_GROUPS.INSERTION]: { id: TOOLBAR_GROUPS.INSERTION, options: INSERT_OPTIONS_CONFIG },
  [TOOLBAR_GROUPS.CODE]: { id: TOOLBAR_GROUPS.CODE, options: CODE_CONFIG },
  [TOOLBAR_GROUPS.RESET]: { id: TOOLBAR_GROUPS.RESET, options: RESET_CONFIG },
  [TOOLBAR_GROUPS.SHORTCUTS]: { id: TOOLBAR_GROUPS.SHORTCUTS, options: SHORTCUTS_INFO_CONFIG },
};

export default TOOLBAR_CONFIG;
