const ACTION_TYPES = {
  ON_MOUNT: 'ON_MOUNT',
  ON_CANCEL: 'ON_CANCEL',
  ON_CANCEL_REQUEST: 'ON_CANCEL_REQUEST',
  ON_CONFIRM_REQUEST: 'ON_CONFIRM_REQUEST',
  ON_REDIRECTION: 'ON_REDIRECTION',
};

const CONTEXT_ID = 'APPLICATION_FORM';

const FIELD_IDS = {
  DISPLAY_NAME: 'displayName',
  DESCRIPTION: 'description',
  NAME: 'name',
  MEDIA_ID: 'mediaId',
  APPLICATION_ICON: 'applicationIcon',
};

export { ACTION_TYPES, CONTEXT_ID, FIELD_IDS };
