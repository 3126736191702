import _property from 'lodash/property';

const metadataManagementReader = {
  id: _property('id'),
  name: _property('name'),
  displayName: _property('displayName'),
  description: _property('description'),
  createdTime: _property('createdTime'),
  state: _property('state'),
  mediaId: _property('mediaId'),
};

export default metadataManagementReader;
