import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import pusherServices from '../services/pusher.services';

const fetchPusherMetadata = async () => {
  try {
    const response = await pusherServices.fetchPusherMetadata();
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Could not initialize pusher, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export { fetchPusherMetadata };
