import _size from 'lodash/size';
import _cloneDeep from 'lodash/cloneDeep';

// This functionality follows Memento pattern. It is memory Intensive. So keeping 1000 states only in history.
class HistoryManager {
  constructor() {
    this.history = [];
    this.currentPosition = -1;
  }

  reset() {
    this.history = [];
    this.currentPosition = -1;
  }

  recordChange(newState) {
    if (this.currentPosition < _size(this.history) - 1) {
      this.history = this.history.slice(0, this.currentPosition + 1);
    }

    this.history.push(_cloneDeep(newState));
    this.currentPosition = _size(this.history) - 1;

    if (_size(this.history) > 1000) {
      this.history = this.history.slice(1);
    }
  }

  undo() {
    if (this.currentPosition > 0) {
      this.currentPosition -= 1;
    }
    return this.history[this.currentPosition];
  }

  redo() {
    if (this.currentPosition < _size(this.history) - 1) {
      this.currentPosition += 1;
    }
    return this.history[this.currentPosition];
  }

  canUndo() {
    return this.currentPosition > 0;
  }

  canRedo() {
    return this.currentPosition < _size(this.history) - 1;
  }
}

export default HistoryManager;
