import { Editor } from 'slate';

// Constants
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

const isEditorSelectionEmpty = (selection) => {
  if (!selection?.anchor || !selection?.focus) return true;
  return false;
};

export const getEditorSafeSelection = (editor) => {
  const selection = editor?.selection;
  if (isEditorSelectionEmpty(selection)) {
    return Editor.start(editor, EMPTY_ARRAY);
  }
  return selection;
};
