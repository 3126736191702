exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".metadataCardView_container__n6M4ouhPjQ{display:flex;flex-wrap:wrap}[dir] .metadataCardView_container__n6M4ouhPjQ{margin:2.4rem 2.4rem 0;border:1px solid #dad3d3}.metadataCardView_item__mGjXR185iK{display:flex;flex:0 1 24%;height:2rem}[dir] .metadataCardView_item__mGjXR185iK{margin-bottom:1%;padding-top:.8rem}[dir=ltr] .metadataCardView_item__mGjXR185iK{padding-left:.8rem}[dir=rtl] .metadataCardView_item__mGjXR185iK{padding-right:.8rem}[dir=ltr] .metadataCardView_subItem__apNybQrmq1{margin-right:.8rem}[dir=rtl] .metadataCardView_subItem__apNybQrmq1{margin-left:.8rem}.metadataCardView_iconItem__ssrWosAA2D{display:flex;flex-direction:row-reverse;flex:0 1 24%;height:3rem}[dir] .metadataCardView_iconItem__ssrWosAA2D{margin-bottom:1%;padding-top:.8rem}[dir=ltr] .metadataCardView_iconItem__ssrWosAA2D{padding-right:.8rem}[dir=rtl] .metadataCardView_iconItem__ssrWosAA2D{padding-left:.8rem}", ""]);

// Exports
exports.locals = {
	"container": "metadataCardView_container__n6M4ouhPjQ",
	"item": "metadataCardView_item__mGjXR185iK",
	"subItem": "metadataCardView_subItem__apNybQrmq1",
	"iconItem": "metadataCardView_iconItem__ssrWosAA2D"
};