import React from 'react';
import { defaultMemoize } from 'reselect';
import cx from 'classnames';

import Icon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { updateTabContentDetails } from './configTab.helpers';

import ACTION_TYPES from '../../../constants/tabPropertiesConfigurator.actionTypes';
import { COLUMN_IDS } from '../constants/configTab.constants';

import styles from '../configTab.module.scss';

const handleAction = defaultMemoize((id, key, index, tabContentDetails, onAction) => (action) => {
  const { type, payload = {} } = action;
  if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
    const newTabContentDetails = updateTabContentDetails(index, key, payload, tabContentDetails);
    onAction({
      type,
      payload: { id, value: newTabContentDetails },
    });
  } else {
    onAction(action);
  }
});

const handleDeleteTab = (index, onAction, tabContentChildren) => {
  onAction({ type: ACTION_TYPES.ON_DELETE_TAB, payload: { index, tabContentChildren } });
};

const getCellComponent = ({ id, key, value, index, tabContentDetails, tabContentChildren, onAction }) => {
  let Cell = null;

  switch (key) {
    case COLUMN_IDS.TAB_NAME:
      Cell = (
        <TextInput
          className={styles.selectContainer}
          containerClassName={styles.selectContainer}
          placeholder={__('Tab Name')}
          value={value}
          onAction={handleAction(id, key, index, tabContentDetails, onAction)}
        />
      );
      break;
    case COLUMN_IDS.TAB_ICON:
      Cell = (
        <TextInput
          className={styles.selectContainer}
          containerClassName={styles.selectContainer}
          placeholder={__('Icon Name')}
          value={value}
          onAction={handleAction(id, key, index, tabContentDetails, onAction)}
        />
      );

      break;
    case COLUMN_IDS.ACTION:
      if (index > 0) {
        Cell = (
          <Icon
            size={SIZES.MD}
            className={cx(styles.iconContainer, styles.trashIconContainer)}
            onClick={() => handleDeleteTab(index, onAction, tabContentChildren)}
          >
            icon-trash
          </Icon>
        );
      } else {
        Cell = null;
      }
      break;

    default:
      Cell = null;
  }
  return Cell;
};

export { getCellComponent };
