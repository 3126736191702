import _forEach from 'lodash/forEach';

import { tget } from '@tekion/tekion-base/utils/general';
import { DELETE_NODE_WITH_ALL_CHILD } from '@tekion/tekion-rule-engine';

import { KEEP_BY_CONDITION } from '../../../constants/workflowBuilder.constants';
import { BOOLEAN_LABELS } from './deleteNodeModal.constants';

const deleteByConditions = (conditions, addAllOpt = false) => {
  const options = [];

  if (addAllOpt) {
    options.push(DELETE_NODE_WITH_ALL_CHILD);
  }

  if (conditions) {
    _forEach(conditions, (condition) => {
      options.push({
        deleteCondition: KEEP_BY_CONDITION,
        label: __('Keep the {{condition}} Path after deletion', {
          condition: tget(BOOLEAN_LABELS, condition, condition),
        }),
        value: condition,
      });
    });
  }

  return options;
};

export { deleteByConditions };
