const WORKFLOW_LIST_COLUMN_IDS = {
  WORKFLOW_NAME: 'displayName',
  ENTITY_NAME: 'entityName',
  DESCRIPTION: 'description',
  STATUS: 'status',
  EVENT_TYPE: 'uiMetadata.eventLabel',
};

const RULE_LIST_COLUMN_IDS = {
  WORKFLOW_NAME: 'displayName',
  ENTITY_NAME: 'entityName',
  DESCRIPTION: 'description',
  STATUS: 'status',
  EVENT_TYPE: 'uiMetadata.eventLabel',
  PRIORITY: 'priority',
};

export { WORKFLOW_LIST_COLUMN_IDS, RULE_LIST_COLUMN_IDS };
