import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { isErrorEmpty } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { getFieldsFormMediaPropertiesValue, getMediaPropertiesFormValues } from './mediaPropertiesForm.helpers';

import MEDIA_PROPERTIES_FORM_FIELD_IDS from '../constants/mediaPropertiesForm.fieldIds';
import FIELDS_FORM_FIELD_IDS from '../../../constants/fieldsForm.fieldIds';
import { FIELD_FORM_CONTEXT_ID } from '../../../constants/fieldsForm.constants';

const handleFormInit = async ({ getState, setState }) => {
  const { fieldsFormMediaPropertiesValue = {} } = getState();
  setState({ values: getMediaPropertiesFormValues(fieldsFormMediaPropertiesValue) });
};

const handleFieldChange = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  const { values: oldValues = EMPTY_OBJECT, fieldsFormOnAction } = getState();
  const values = { ...oldValues, [id]: value };

  if (value && id === MEDIA_PROPERTIES_FORM_FIELD_IDS.PREVIEW_ENABLED) {
    _set(values, MEDIA_PROPERTIES_FORM_FIELD_IDS.FILE_ACCESS_AUDIT_ENABLED, false);
  } else if (value && id === MEDIA_PROPERTIES_FORM_FIELD_IDS.FILE_ACCESS_AUDIT_ENABLED) {
    _set(values, MEDIA_PROPERTIES_FORM_FIELD_IDS.PREVIEW_ENABLED, false);
  }

  setState({ values }, () =>
    fieldsFormOnAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: {
        id: FIELDS_FORM_FIELD_IDS.MEDIA_PROPERTIES_FORM,
        value: getFieldsFormMediaPropertiesValue(values),
      },
    }),
  );
};

const handleValidationSuccess = ({ getState, params = EMPTY_OBJECT }) => {
  const { fieldsFormOnAction } = getState();
  const { errors } = params;

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: { id: FIELDS_FORM_FIELD_IDS.MEDIA_PROPERTIES_FORM, errors },
  });
};

const handleSubmit = ({ getState, params = EMPTY_OBJECT }) => {
  const { additional = {} } = params;
  const { formErrors } = additional;
  const { fieldsFormOnAction } = getState();

  fieldsFormOnAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: { id: FIELDS_FORM_FIELD_IDS.MEDIA_PROPERTIES_FORM, errors: undefined },
  });

  _set(formErrors, FIELDS_FORM_FIELD_IDS.MEDIA_PROPERTIES_FORM, false);

  if (isErrorEmpty(formErrors)) {
    triggerSubmit(FIELD_FORM_CONTEXT_ID, _cloneDeep(additional));
  }
};

const MEDIA_PROPERTIES_FORM_ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleFormInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidationSuccess,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
};

export default MEDIA_PROPERTIES_FORM_ACTION_HANDLERS;
