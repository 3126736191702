const FIELD_IDS = {
  RECORD_GROUP_RULE_NAME: 'name',
  RULE_DISPLAY_NAME: 'displayName',
  RULE_DESCRIPTION: 'description',
  ENTITY_RECORD_GROUP: 'recordGroupName',
  USERS: 'users',
  PERMISSION: 'accessType',
};

export { FIELD_IDS };
