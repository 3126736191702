import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _includes from 'lodash/includes';
import _unset from 'lodash/unset';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { getVariableName, checkValueIsVariable } from '../../../utils/workflowVariables.utils';

import { REQUEST_DETAILS_FIELD_IDS } from './calloutModal.constants';
import { CALLOUT_FIELD_IDS } from '../../../constants/nodeDataFieldIds';
import { ASSIGNED_VARIABLE_FIELD_IDS, VARIABLE_TYPES } from '../../../constants/workflow.assignVariableConstants';
import { ASSIGNED_VARIABLE_DATA_TYPES } from '../../../constants/workflow.nodeVariableConstants';

const getValueOptionForServerCredential = (serverCredential) => ({
  label: tget(serverCredential, 'displayName', NO_DATA),
  value: tget(serverCredential, 'name'),
});

const getServerCredentialOptions = (data) => _map(data, getValueOptionForServerCredential);

const ROWS = 20;

const getServerCredentialPayload = ({ nextPageToken, searchText }) => {
  const filters = [];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: 'name',
      values: [searchText],
      filterType: OPERATORS.TEXT_STARTS_WITH,
    });
  }

  return {
    rows: ROWS,
    nextPageToken: _isEmpty(nextPageToken) ? null : nextPageToken,
    filters,
  };
};

const getApiFormattedFormValuesAndUsedVariables = (formValues) => {
  const updatedApiFormValues = _pick(formValues, [
    CALLOUT_FIELD_IDS.CALLOUT_ACTION_NAME,
    CALLOUT_FIELD_IDS.ACTION_TYPE,
    CALLOUT_FIELD_IDS.CONNECT_TIME_OUT,
    CALLOUT_FIELD_IDS.READ_TIME_OUT,
    CALLOUT_FIELD_IDS.SERVER_CREDENTIAL_NAME,
    CALLOUT_FIELD_IDS.HTTP_METHOD,
    CALLOUT_FIELD_IDS.FIELD_MAPPING_ID,
    CALLOUT_FIELD_IDS.PATH,
    CALLOUT_FIELD_IDS.ENTITY_NAME,
    CALLOUT_FIELD_IDS.VARIABLE_TYPE,
    CALLOUT_FIELD_IDS.ASSIGNED_VARIABLE,
  ]);
  const usedVariables = [];

  const isResponseExpected = tget(formValues, CALLOUT_FIELD_IDS.RESPONSE_EXPECTED, false);
  if (!isResponseExpected) {
    _unset(updatedApiFormValues, CALLOUT_FIELD_IDS.ENTITY_NAME);
    _unset(updatedApiFormValues, CALLOUT_FIELD_IDS.ASSIGNED_VARIABLE);
    _unset(updatedApiFormValues, CALLOUT_FIELD_IDS.VARIABLE_TYPE);
  }

  // Request Headers
  const requestHeaders = tget(formValues, CALLOUT_FIELD_IDS.HEADERS, []);
  const updatedRequestHeaders = {};
  _forEach(requestHeaders, (requestDetail) => {
    const requestKey = tget(requestDetail, REQUEST_DETAILS_FIELD_IDS.REQUEST_KEY, EMPTY_STRING);
    if (!_isEmpty(requestKey)) {
      const requestValue = tget(requestDetail, REQUEST_DETAILS_FIELD_IDS.REQUEST_VALUE, EMPTY_STRING);

      if (checkValueIsVariable(requestValue)) {
        usedVariables.push(getVariableName(requestValue));
      }
      _set(updatedRequestHeaders, [requestKey], requestValue);
    }
  });
  _set(updatedApiFormValues, CALLOUT_FIELD_IDS.HEADERS, updatedRequestHeaders);

  // Request Body
  const requestBody = tget(formValues, CALLOUT_FIELD_IDS.REQUEST_BODY, []);
  const updatedRequestBody = {};
  _forEach(requestBody, (body) => {
    const requestKey = tget(body, REQUEST_DETAILS_FIELD_IDS.REQUEST_KEY, EMPTY_STRING);

    if (!_isEmpty(requestKey)) {
      const requestValue = tget(body, REQUEST_DETAILS_FIELD_IDS.REQUEST_VALUE, EMPTY_STRING);
      if (checkValueIsVariable(requestValue)) {
        usedVariables.push(getVariableName(requestValue));
      }
      _set(updatedRequestBody, [requestKey], requestValue);
    }
  });

  _set(updatedApiFormValues, CALLOUT_FIELD_IDS.REQUEST_BODY, updatedRequestBody);

  // Request Params
  const requestParams = tget(formValues, CALLOUT_FIELD_IDS.REQUEST_PARAMS, []);
  const updatedRequestParams = {};
  _forEach(requestParams, (param) => {
    const requestKey = tget(param, REQUEST_DETAILS_FIELD_IDS.REQUEST_KEY, EMPTY_STRING);
    if (!_isEmpty(requestKey)) {
      const requestValue = tget(param, REQUEST_DETAILS_FIELD_IDS.REQUEST_VALUE, EMPTY_STRING);
      if (checkValueIsVariable(requestValue)) {
        usedVariables.push(getVariableName(requestValue));
      }

      _set(updatedRequestParams, [requestKey], requestValue);
    }
  });
  _set(updatedApiFormValues, CALLOUT_FIELD_IDS.REQUEST_PARAMS, updatedRequestParams);

  return { apiFormValues: updatedApiFormValues, usedVariables };
};

const getFormFormattedFormValues = (apiFormValues) => {
  const updatedFormValues = _pick(apiFormValues, [
    CALLOUT_FIELD_IDS.CALLOUT_ACTION_NAME,
    CALLOUT_FIELD_IDS.ACTION_TYPE,
    CALLOUT_FIELD_IDS.CONNECT_TIME_OUT,
    CALLOUT_FIELD_IDS.READ_TIME_OUT,
    CALLOUT_FIELD_IDS.SERVER_CREDENTIAL_NAME,
    CALLOUT_FIELD_IDS.HTTP_METHOD,
    CALLOUT_FIELD_IDS.FIELD_MAPPING_ID,
    CALLOUT_FIELD_IDS.PATH,
    CALLOUT_FIELD_IDS.ENTITY_NAME,
    CALLOUT_FIELD_IDS.ASSIGNED_VARIABLE,
    CALLOUT_FIELD_IDS.VARIABLE_TYPE,
  ]);

  const assignedVariable = tget(updatedFormValues, CALLOUT_FIELD_IDS.ASSIGNED_VARIABLE, EMPTY_STRING);

  if (!_isEmpty(assignedVariable)) {
    _set(updatedFormValues, CALLOUT_FIELD_IDS.RESPONSE_EXPECTED, true);
  }

  // Request Headers
  const requestHeaders = tget(apiFormValues, CALLOUT_FIELD_IDS.HEADERS, {});
  const updatedRequestHeaders = _map(requestHeaders, (headerValue, headerKey) => ({
    [REQUEST_DETAILS_FIELD_IDS.REQUEST_KEY]: headerKey,
    [REQUEST_DETAILS_FIELD_IDS.REQUEST_VALUE]: headerValue,
  }));

  _set(updatedFormValues, CALLOUT_FIELD_IDS.HEADERS, updatedRequestHeaders);

  // Request Body
  const requestBody = tget(apiFormValues, CALLOUT_FIELD_IDS.REQUEST_BODY, {});
  const updatedRequestBody = _map(requestBody, (requestBodyValue, requestBodyKey) => ({
    [REQUEST_DETAILS_FIELD_IDS.REQUEST_KEY]: requestBodyKey,
    [REQUEST_DETAILS_FIELD_IDS.REQUEST_VALUE]: requestBodyValue,
  }));

  _set(updatedFormValues, CALLOUT_FIELD_IDS.REQUEST_BODY, updatedRequestBody);

  // Request Params
  const requestParams = tget(apiFormValues, CALLOUT_FIELD_IDS.REQUEST_PARAMS, {});
  const updatedRequestParams = _map(requestParams, (requestParamValue, requestParamKey) => ({
    [REQUEST_DETAILS_FIELD_IDS.REQUEST_KEY]: requestParamKey,
    [REQUEST_DETAILS_FIELD_IDS.REQUEST_VALUE]: requestParamValue,
  }));

  _set(updatedFormValues, CALLOUT_FIELD_IDS.REQUEST_PARAMS, updatedRequestParams);

  return updatedFormValues;
};

const getResponseAssignedVariableOptions = (variablesInfo, scopedVariables, entityName) => {
  const variableOptions = [];
  _forEach(variablesInfo, (variableInfo, variableName) => {
    if (_includes(scopedVariables, variableName) && tget(variableInfo, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME) === entityName) {
      variableOptions.push({
        label: variableName,
        value: variableName,
      });
    }
  });
  return variableOptions;
};

const getAssignedVariableInfo = (formValues) => {
  const assignedVariableInfo = {};
  /**
   * Below being the variable structure to be stored at uiMetadata of workflow
   * customer: {
    entityName: "customer__u",
    declaredAtNodeId: "fbgjgs",
    usedAtNodeIds: ["riosatu", "xzcmnbmz"]
  },
   */

  const isResponseExpected = tget(formValues, CALLOUT_FIELD_IDS.RESPONSE_EXPECTED, false);
  if (isResponseExpected) {
    const entityName = tget(formValues, CALLOUT_FIELD_IDS.ENTITY_NAME);
    const assignedVariable = tget(formValues, CALLOUT_FIELD_IDS.ASSIGNED_VARIABLE);
    const variableType = tget(formValues, CALLOUT_FIELD_IDS.VARIABLE_TYPE);

    _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_NAME, assignedVariable);
    _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.ENTITY_NAME, entityName);

    if (variableType === ASSIGNED_VARIABLE_DATA_TYPES.MULTIPLE) {
      _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE, VARIABLE_TYPES.ENTITY_RECORD_LIST);
    } else {
      _set(assignedVariableInfo, ASSIGNED_VARIABLE_FIELD_IDS.VARIABLE_TYPE, VARIABLE_TYPES.ENTITY_RECORD);
    }
  }

  return assignedVariableInfo;
};

export {
  getServerCredentialOptions,
  getApiFormattedFormValuesAndUsedVariables,
  getFormFormattedFormValues,
  getServerCredentialPayload,
  getResponseAssignedVariableOptions,
  getAssignedVariableInfo,
};
