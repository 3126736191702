import React, { useMemo } from 'react';
import compose from 'recompose/compose';
import renameProps from 'recompose/renameProps';
import PropTypes from 'prop-types';

import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import TEXT_PROPERTIES_FORM_ACTION_HANDLERS from './helpers/textPropertiesForm.actionHandlers';
import getFields from './helpers/textPropertiesForm.fields';

import TEXT_PROPERTIES_FORM_SECTIONS from './constants/textPropertiesForm.sections';
import FIELDS_FORM_FIELD_IDS from '../../constants/fieldsForm.fieldIds';

const TextPropertiesForm = ({ renderOptions, error, values, regexpOptions, onAction }) => {
  const fields = useMemo(() => getFields(regexpOptions, renderOptions), [regexpOptions, renderOptions]);

  return (
    <FormWithSubmission
      className="full-width"
      contextId={FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM}
      errors={error}
      values={values}
      fields={fields}
      sections={TEXT_PROPERTIES_FORM_SECTIONS}
      onAction={onAction}
    />
  );
};

TextPropertiesForm.propTypes = {
  renderOptions: PropTypes.object,
  error: PropTypes.object,
  values: PropTypes.object,
  regexpOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

TextPropertiesForm.defaultProps = {
  renderOptions: EMPTY_OBJECT,
  error: undefined,
  values: EMPTY_OBJECT,
  regexpOptions: EMPTY_ARRAY,
};

export default compose(
  renameProps({ onAction: 'fieldsFormOnAction', value: 'fieldsFormTextPropertiesValue' }),
  withActions(EMPTY_OBJECT, TEXT_PROPERTIES_FORM_ACTION_HANDLERS),
)(TextPropertiesForm);
