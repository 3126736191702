import _isNumber from 'lodash/isNumber';
import _castArray from 'lodash/castArray';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { TEXT_PROPERTIES_CONFIGURE_FIELD_IDS } from '../constants/textPropertiesConfigure.fieldIds';

const getFormFormattedSelectedWidgetProperties = (selectedWidgetProperties) => {
  const textSize = tget(selectedWidgetProperties, TEXT_PROPERTIES_CONFIGURE_FIELD_IDS.TEXT_SIZE);

  if (_isNumber(textSize)) {
    return { ...selectedWidgetProperties, [TEXT_PROPERTIES_CONFIGURE_FIELD_IDS.TEXT_SIZE]: _castArray(textSize) };
  }
  return selectedWidgetProperties;
};

const getConfigFormattedSelectedWidgetProperties = (value, id, selectedWidgetProperties) => {
  if (id === TEXT_PROPERTIES_CONFIGURE_FIELD_IDS.TEXT_SIZE) {
    return { ...selectedWidgetProperties, [TEXT_PROPERTIES_CONFIGURE_FIELD_IDS.TEXT_SIZE]: getArraySafeValue(value) };
  }
  return { ...selectedWidgetProperties, [id]: value };
};

export { getFormFormattedSelectedWidgetProperties, getConfigFormattedSelectedWidgetProperties };
