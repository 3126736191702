import _forEach from 'lodash/forEach';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import _reduce from 'lodash/reduce';
import _omit from 'lodash/omit';

import { EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { checkValueIsVariable, getVariableName } from '../../../../utils/workflowVariables.utils';

import { TEMPLATE_RENDERER_FIELD_IDS } from '../../../../../../../../../organisms/templateSection/templateRenderer/templateRenderer.constant';
import { PUSH_NOTIFICATION_FIELD_IDS } from '../../../../constants/nodeDataFieldIds';

const ROWS = 20;

const getEntityPayload = ({ nextPageToken, searchText }) => {
  const filters = [];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: 'name',
      values: [searchText],
      filterType: OPERATORS.TEXT_STARTS_WITH,
    });
  }

  return {
    rows: ROWS,
    nextPageToken,
    filters,
  };
};

const getApplicationPayload = ({ nextPageToken, searchText }) => {
  const filters = [];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: 'displayName',
      values: [searchText],
      filterType: OPERATORS.TEXT_STARTS_WITH,
    });
  }

  return {
    rows: ROWS,
    nextPageToken,
    filters,
  };
};

const getValueOptionForApplication = (appData) => ({
  label: tget(appData, 'displayName', NO_DATA),
  value: tget(appData, 'name', ''),
});

const getApplicationOptions = (data) => _map(data, getValueOptionForApplication);

const getValueOptionForEntity = (entityData) => ({
  label: tget(entityData, 'displayName', NO_DATA),
  value: tget(entityData, 'name', EMPTY_STRING),
});

const getEntityOptions = (data) => _map(data, getValueOptionForEntity);

const getFormFormattedFormValues = (userData) => {
  const updatedFormValues = _omit(userData, [PUSH_NOTIFICATION_FIELD_IDS.TEMPLATES]);
  const apiTemplateValue = tget(userData, PUSH_NOTIFICATION_FIELD_IDS.TEMPLATES, EMPTY_OBJECT);
  const templateFormValue = _map(apiTemplateValue, (templateValue, key) => ({
    [TEMPLATE_RENDERER_FIELD_IDS.SELECTED_TEMPLATE]: key,
    [TEMPLATE_RENDERER_FIELD_IDS.MAP_OF_VARIABLES]: templateValue,
  }));

  _set(updatedFormValues, PUSH_NOTIFICATION_FIELD_IDS.TEMPLATES, _isEmpty(templateFormValue) ? [{}] : templateFormValue);
  return updatedFormValues;
};

const getApiFormattedFormValues = (formValues) => {
  const updatedApiFormValues = _omit(formValues, [PUSH_NOTIFICATION_FIELD_IDS.TEMPLATES]);
  const templates = tget(formValues, PUSH_NOTIFICATION_FIELD_IDS.TEMPLATES, []);

  const updatedTemplate = _reduce(
    templates,
    (res, template) => {
      const templateName = tget(template, TEMPLATE_RENDERER_FIELD_IDS.SELECTED_TEMPLATE);
      const variableValueMap = tget(template, TEMPLATE_RENDERER_FIELD_IDS.MAP_OF_VARIABLES, EMPTY_OBJECT);
      _set(res, templateName, variableValueMap);
      return res;
    },
    {},
  );

  _set(updatedApiFormValues, PUSH_NOTIFICATION_FIELD_IDS.TEMPLATES, updatedTemplate);
  return updatedApiFormValues;
};

const getUsedVariables = (formValues) => {
  const usedVariables = [];

  // Receiver's ids
  const receiverIds = tget(formValues, PUSH_NOTIFICATION_FIELD_IDS.RECEIVER_IDS);
  _forEach(receiverIds, (receiverId) => {
    if (checkValueIsVariable(receiverId)) {
      usedVariables.push(getVariableName(receiverId));
    }
  });

  // Pusher Record Id
  const pusherRecordId = tget(formValues, PUSH_NOTIFICATION_FIELD_IDS.PUSHER_RECORD_ID);

  if (checkValueIsVariable(pusherRecordId)) {
    usedVariables.push(getVariableName(pusherRecordId));
  }

  // Pusher Notification Template Variables
  _forEach(tget(formValues, PUSH_NOTIFICATION_FIELD_IDS.TEMPLATES), (template) => {
    _forEach(template, (variableValue) => {
      if (checkValueIsVariable(variableValue)) {
        usedVariables.push(getVariableName(variableValue));
      }
    });
  });

  return _uniq(usedVariables);
};

export {
  getApplicationPayload,
  getEntityPayload,
  getApplicationOptions,
  getEntityOptions,
  getUsedVariables,
  getApiFormattedFormValues,
  getFormFormattedFormValues,
};
