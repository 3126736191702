import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import CustomStylesConfigurator from '../customStylesConfigurator/CustomStylesConfigurator';

import { FIELD_IDS } from './formViewSectionPropertiesForm.constants';

const CUSTOM_STYLE_FIELD = {
  id: FIELD_IDS.CUSTOM_STYLES,
  renderer: CustomStylesConfigurator,
};

const FORM_FIELDS = {
  [FIELD_IDS.CUSTOM_STYLES]: CUSTOM_STYLE_FIELD,
};

const getFields = (entity, properties, componentType) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.CUSTOM_STYLES]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CUSTOM_STYLES], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
});

export { getFields };
