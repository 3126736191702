import Button from '@tekion/tekion-components/atoms/Button';

import ACTION_TYPES from '../constants/permissionSetList.actionTypes';

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create Permission'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_PERMISSION,
    },
  ],
};

export default SUB_HEADER_PROPS;
