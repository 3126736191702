import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';

import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _castArray from 'lodash/castArray';

import { tget } from '@tekion/tekion-base/utils/general';
import Heading from '@tekion/tekion-components/atoms/Heading';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { getPropertiesPanelByWidgetType } from './helpers/propertyPanel.helpers';
import { pascalCase } from '../../../../helpers/general.helpers';

import { COMPONENT_CONFIG_KEYS, WIDGET_TYPES } from '../../constants/visualBuilder.general.constants';
import { CONFIGURATOR_BY_PROPERTIES, PROPERTIES_PANEL_HEADER_NAME } from './constants/propertyPanel.constants';
import { VIEW_TYPES } from '../../../../constants/viewBuilder.constants';
import { PAGE_TYPES } from '../../../../constants/visualBuilder';

import styles from './propertyPanel.module.scss';

const PropertyPanel = ({ selectedWidgetConfig, pageConfiguration, onChangeWidgetConfigurationAction, pageEntity }) => {
  const [activeKeys, setActiveKeys] = useState([]);
  const onPanelChange = useCallback((key) => setActiveKeys(_castArray(key)), []);
  const widgetType = useMemo(() => tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_TYPE, WIDGET_TYPES.CONTAINER), [selectedWidgetConfig]);
  const viewType = useMemo(
    () => tget(selectedWidgetConfig, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${COMPONENT_CONFIG_KEYS.VIEW_TYPE}`, VIEW_TYPES.LIST_VIEW),
    [selectedWidgetConfig],
  );
  const propertiesPanelToRender = useMemo(() => tget(getPropertiesPanelByWidgetType(viewType), widgetType), [widgetType, viewType]);
  const pageType = useMemo(() => tget(pageConfiguration, 'pageType', PAGE_TYPES.HOME_PAGE), [pageConfiguration]);

  const renderPanel = useCallback(
    (propertyConfiguratorName) => {
      const ConfiguratorToRender = CONFIGURATOR_BY_PROPERTIES[propertyConfiguratorName];

      return (
        <Collapse.Panel
          header={
            <Heading size={3} className={styles.listGroupHeader}>
              {PROPERTIES_PANEL_HEADER_NAME[propertyConfiguratorName]}
            </Heading>
          }
          key={propertyConfiguratorName}
        >
          <ConfiguratorToRender
            selectedWidgetConfig={selectedWidgetConfig}
            pageType={pageType}
            pageEntity={pageEntity}
            onChangeWidgetConfigurationAction={onChangeWidgetConfigurationAction}
          />
        </Collapse.Panel>
      );
    },
    [selectedWidgetConfig, pageType, onChangeWidgetConfigurationAction, pageEntity],
  );

  return (
    <>
      <div className={styles.headingBorder}>
        {__('Configurator for')} {widgetType ? <span className={styles.configuratorHeading}>{__(pascalCase(widgetType))}</span> : EMPTY_STRING}
      </div>

      <Collapse activeKey={activeKeys} className={styles.collapseStyle} onChange={onPanelChange}>
        {_map(propertiesPanelToRender, renderPanel)}
      </Collapse>
    </>
  );
};

PropertyPanel.propTypes = {
  pageConfiguration: PropTypes.object,
  selectedWidgetConfig: PropTypes.object,
  pageEntity: PropTypes.object,
  onChangeWidgetConfigurationAction: PropTypes.func,
};

PropertyPanel.defaultProps = {
  pageConfiguration: undefined,
  selectedWidgetConfig: undefined,
  pageEntity: undefined,
  onChangeWidgetConfigurationAction: _noop,
};

export default PropertyPanel;
