import _constant from 'lodash/constant';

import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer, { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import COLUMN_IDS from '../constants/serverCredentialList.columnIds';
import { STATUS } from '../constants/serverCredentialList.constants';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const STATUS_COLOR_MAP = {
  [STATUS.ACTIVE]: COLOR_MAP.GREEN,
  [STATUS.INACTIVE]: COLOR_MAP.GREY,
};

const STATUS_LABEL_MAP = {
  [STATUS.ACTIVE]: __('Active'),
  [STATUS.INACTIVE]: __('Inactive'),
};

const getStatusProps = _constant({
  colorMap: STATUS_COLOR_MAP,
  labelMap: STATUS_LABEL_MAP,
});

const COLUMN_CONFIG = [
  {
    Header: __('Display Name'),
    id: COLUMN_IDS.DISPLAY_NAME,
    accessor: COLUMN_IDS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Name'),
    id: COLUMN_IDS.NAME,
    accessor: COLUMN_IDS.NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('URL'),
    id: COLUMN_IDS.URL,
    accessor: COLUMN_IDS.URL,
    Cell: TextRenderer,
  },
  {
    Header: __('External Credential Name'),
    id: COLUMN_IDS.EXTERNAL_CREDENTIAL_NAME,
    accessor: COLUMN_IDS.EXTERNAL_CREDENTIAL_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Client Certificate'),
    id: COLUMN_IDS.CLIENT_CERTIFICATE,
    accessor: COLUMN_IDS.CLIENT_CERTIFICATE,
    Cell: TextRenderer,
  },
  {
    Header: __('Generate Auth Headers'),
    id: COLUMN_IDS.GENERATE_AUTH_HEADERS,
    accessor: COLUMN_IDS.GENERATE_AUTH_HEADERS,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Allow Formula in Headers'),
    id: COLUMN_IDS.ALLOW_FORMULA_IN_HEADERS,
    accessor: COLUMN_IDS.ALLOW_FORMULA_IN_HEADERS,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Allow Formula in Body'),
    id: COLUMN_IDS.ALLOW_FORMULA_IN_BODY,
    accessor: COLUMN_IDS.ALLOW_FORMULA_IN_BODY,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
  {
    Header: __('Open Api Specification Id'),
    id: COLUMN_IDS.OPEN_API_SPECIFICATION_ID,
    accessor: COLUMN_IDS.OPEN_API_SPECIFICATION_ID,
    Cell: TextRenderer,
  },
];

export default COLUMN_CONFIG;
