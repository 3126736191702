import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _find from 'lodash/find';

import Modal from '@tekion/tekion-components/molecules/Modal';
import Loader from '@tekion/tekion-components/molecules/loader';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import withActions from '@tekion/tekion-components/connectors/withActions';

import ACTION_HANDLERS from './switchWorkflowModal.actionHandler';

import { FORM_SECTIONS, getFieldConfig } from './switchWorkflowModal.fields';
import { ACTION_TYPES, CONTEXT_ID, FIELD_IDS } from './switchWorkflowModal.constants';
import { MODAL_MODES } from '../../../constants/workflowBuilder.constants';

const SwitchWorkflowModal = ({ mode, isFormLoading, stepDetails, workflowOptions, onCancel, onSubmit, onAction }) => {
  const [values, setValue] = useState();
  const [errors, setErrors] = useState();

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { value: updatedValue, id } = payload;
          setValue({ ...values, [id]: updatedValue });
          break;
        }
        case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
          const { errors: newErrors } = payload;
          setErrors(newErrors);
          break;
        }
        default:
      }
    },
    [values],
  );

  const handleSubmit = useCallback(() => {
    setValue({});
    setErrors({});
    const selectedWorkflow = _find(workflowOptions, ['value', tget(values, FIELD_IDS.WORKFLOW)]);
    _set(stepDetails, 'uiMetadata.workflowName', tget(selectedWorkflow, 'label'));
    onSubmit(values);
  }, [values, onSubmit, stepDetails, workflowOptions]);

  const handleCancel = useCallback(() => {
    setValue({});
    setErrors({});
    onCancel();
  }, [onCancel]);

  const formFields = useMemo(() => getFieldConfig(workflowOptions), [workflowOptions]);

  const modalContent = useMemo(
    () => (
      <PropertyControlledComponent controllerProperty={!isFormLoading} fallback={<Loader />}>
        <FormWithSubmission
          sections={FORM_SECTIONS}
          fields={formFields}
          values={values}
          errors={errors}
          onAction={handleAction}
          contextId={CONTEXT_ID}
        />
      </PropertyControlledComponent>
    ),
    [values, errors, formFields, isFormLoading, handleAction],
  );

  const submitButtonText = useMemo(() => (mode === MODAL_MODES.EDIT ? __('Save') : __('Add')), [mode]);

  useEffect(() => {
    if (mode === MODAL_MODES.EDIT) {
      const userData = tget(stepDetails, 'userData.data');
      if (!_isEmpty(userData)) {
        setValue((_value) => ({ ..._value, ...userData }));
      }
    }
  }, [stepDetails, mode]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.ON_LOAD,
    });
  }, [onAction]);

  return (
    <Modal
      visible
      destroyOnClose
      title={__('Switch Workflow')}
      submitBtnText={submitButtonText}
      content={modalContent}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      width={Modal.SIZES.SM}
    />
  );
};

SwitchWorkflowModal.propTypes = {
  isFormLoading: PropTypes.bool,
  mode: PropTypes.string,
  stepDetails: PropTypes.object,
  workflowOptions: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onAction: PropTypes.func.isRequired,
};

SwitchWorkflowModal.defaultProps = {
  isFormLoading: false,
  mode: EMPTY_STRING,
  stepDetails: EMPTY_OBJECT,
  workflowOptions: EMPTY_ARRAY,
  onSubmit: _noop,
  onCancel: _noop,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(SwitchWorkflowModal);
