import { tget } from '@tekion/tekion-base/utils/general';
import { STATUS } from '@tekion/tekion-base/constants/statuses';

import DEFAULT_ROW_ACTIONS, { ACTIVATE_WORKFLOW, DEACTIVATE_WORKFLOW } from './workflowList.actionFactory';
import { DEFAULT_FILTERS_TYPES, FILTERS } from '../constants/workflowList.filterTypes';
import { WORKFLOW_LIST_COLUMNS, RULE_LIST_COLUMNS } from './workflowList.columns';
import { WORKFLOW_BUILDER_MODE } from '../../../../../../../../constants/general.constants';
import { WORKFLOW_LIST_COLUMN_IDS } from '../constants/workflowList.columnIds';

const getRowActions = (rowData) => {
  const status = tget(rowData, WORKFLOW_LIST_COLUMN_IDS.STATUS);
  const ACTIONS = status === STATUS.PUBLISHED ? [...DEFAULT_ROW_ACTIONS, DEACTIVATE_WORKFLOW] : [...DEFAULT_ROW_ACTIONS, ACTIVATE_WORKFLOW];
  return ACTIONS;
};

const createTableProps = (isLoading, pageSize, onAction) => ({
  loading: isLoading,
  currentPage: 1,
  rowHeight: 35,
  minRows: 0,
  showPagination: false,
  pageSize,
  onRowActionClick: (type, payload) => onAction({ type, payload }),
  showActions: true,
  getRowActions,
});

const createFilterProps = (onAction) => ({
  showResultsCount: false,
  defaultFilterTypes: DEFAULT_FILTERS_TYPES,
  filterTypes: [FILTERS.ENTITY_FILTER, FILTERS.WORKFLOW_STATUS_FILTER],
  onAction,
});

const getTableColumnsFromWorkflowMode = (workflowMode) =>
  workflowMode === WORKFLOW_BUILDER_MODE.PROCESS ? WORKFLOW_LIST_COLUMNS : RULE_LIST_COLUMNS;

export { createTableProps, createFilterProps, getTableColumnsFromWorkflowMode };
