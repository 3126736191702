import isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import RecordGroupServices from '../services/recordGroup.services';
import { RECORD_GROUP_TYPES } from '../constants/general.constants';

const getAllRecordGroups = async (payload) => {
  try {
    const response = await RecordGroupServices.getAllRecordGroups(payload);
    const responseData = getDataFromResponse(response);
    return responseData;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, __('Failed to  Get Record Groups, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const deleteRecordGroup = async (recordGroupName) => {
  try {
    const response = await RecordGroupServices.deleteRecordGroupByName(recordGroupName);
    const responseData = getDataFromResponse(response);
    return responseData;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, __('Failed to  Delete Record Group, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createRecordGroup = async (rawData) => {
  try {
    if (_get(rawData, 'groupType', EMPTY_STRING) === RECORD_GROUP_TYPES.DYNAMIC && isEmpty(_get(rawData, 'condition', EMPTY_OBJECT))) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage(EMPTY_OBJECT, __(' Condition field cannot be empty. ')));
      return EMPTY_OBJECT;
    }
    const response = await RecordGroupServices.createRecordGroup(rawData);
    const responseData = getDataFromResponse(response);
    return responseData;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, __('Failed to create Record Group, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getRecordGroupDetails = async (recordGroupName) => {
  try {
    const response = await RecordGroupServices.getRecordGroupByName(recordGroupName);
    const responseData = getDataFromResponse(response);
    return responseData;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, __('Failed to get details of record group, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const updateRecordGroup = async (recordGroupName, rawData) => {
  try {
    if (_get(rawData, 'groupType', EMPTY_STRING) === RECORD_GROUP_TYPES.DYNAMIC && isEmpty(_get(rawData, 'condition', EMPTY_OBJECT))) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage(EMPTY_OBJECT, __(' Condition field cannot be empty. ')));
      return EMPTY_OBJECT;
    }
    const response = await RecordGroupServices.updateRecordGroup(recordGroupName, rawData);
    const responseData = getDataFromResponse(response);
    return responseData;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, __('Failed to  Update Record Group, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export { getAllRecordGroups, deleteRecordGroup, createRecordGroup, getRecordGroupDetails, updateRecordGroup };
