import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _values from 'lodash/values';
import _size from 'lodash/size';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import WidgetComponent from '../widgetComponent/WidgetComponent';
import DropZone from '../dropZone/DropZone';

import { getWidgetReference } from '../../helpers/visualBuilder.helper';
import { COMPONENT_CONFIG_KEYS, WIDGET_TYPES } from '../../constants/visualBuilder.general.constants';

import styles from './tabPaneContainer.module.scss';

const TabPaneContainer = (props) => {
  const { isPreviewMode, componentConfig, pageViewerOnAction, visualBuilderOnAction, ...restProps } = props;

  const widgetName = tget(componentConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING);
  const components = tget(componentConfig, COMPONENT_CONFIG_KEYS.CHILDREN, EMPTY_ARRAY);

  const renderContainerInBuilderMode = useCallback(
    () => (
      <div key={widgetName} className={cx(styles.container, styles.builderMode)}>
        {_map(components, (widgetConfig, index) => {
          const { widgetType, componentRef, properties } = widgetConfig;
          const viewType = tget(properties, 'viewType', '');
          const ComponentRenderer = componentRef || getWidgetReference({ componentType: widgetType, isPreviewMode: true, viewType });

          return (
            <>
              <DropZone id={widgetName} key={widgetName} index={index} acceptedComponentTypes={_values(WIDGET_TYPES)} />
              <WidgetComponent key={widgetName} index={index} widgetComponentConfig={widgetConfig}>
                <ComponentRenderer
                  isPreviewMode={isPreviewMode}
                  key={tget(widgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING)}
                  widgetName={tget(widgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING)}
                  widgetType={widgetType}
                  componentConfig={widgetConfig}
                  {...restProps}
                />
              </WidgetComponent>
            </>
          );
        })}
        <DropZone id={widgetName} index={_size(components)} acceptedComponentTypes={_values(WIDGET_TYPES)} />
      </div>
    ),
    [widgetName, components, isPreviewMode, restProps],
  );

  const renderContainerInPreviewMode = useCallback(
    () => (
      <div key={widgetName} className={cx(styles.container, [styles.previewMode])}>
        {_map(tget(componentConfig, COMPONENT_CONFIG_KEYS.CHILDREN, EMPTY_ARRAY), (widgetConfig) => {
          const { widgetType, componentRef, properties } = widgetConfig;
          const viewType = tget(properties, 'viewType', '');
          const ComponentRenderer = componentRef || getWidgetReference({ componentType: widgetType, isPreviewMode: true, viewType });

          return (
            <ComponentRenderer
              isPreviewMode={isPreviewMode}
              key={tget(widgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING)}
              widgetName={tget(widgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING)}
              widgetType={widgetType}
              componentConfig={widgetConfig}
              {...restProps}
            />
          );
        })}
      </div>
    ),
    [componentConfig, isPreviewMode, widgetName, restProps],
  );

  return isPreviewMode ? renderContainerInPreviewMode() : renderContainerInBuilderMode();
};

TabPaneContainer.propTypes = {
  isPreviewMode: PropTypes.bool,
  componentConfig: PropTypes.object.isRequired,
};

TabPaneContainer.defaultProps = {
  isPreviewMode: false,
};

export default TabPaneContainer;
