const DELAY_TASK_ACTION_TYPES = {
  ON_INIT: 'ON_INIT',
  ON_TAB_ADD: 'ON_TAB_ADD',
  ON_TAB_CLICK: 'ON_TAB_CLICK',
  ON_TAB_DELETE: 'ON_TAB_DELETE',
  ON_TAB_DRAG_END: 'ON_TAB_DRAG_END',
  ON_FORM_BLUR: 'ON_FORM_BLUR',
  ON_SUBMIT: 'ON_SUBMIT',
  ON_CANCEL: 'ON_CANCEL',
};

export default DELAY_TASK_ACTION_TYPES;
