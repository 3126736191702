import React from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { FormWithSubmission } from '@tekion/tekion-components/pages/formPage';
import Button from '@tekion/tekion-components/atoms/Button';

import useShareRecordForm from './hooks/useShareRecordForm/useShareRecordForm';

import SHARE_RECORD_FORM_SECTIONS from './helpers/shareRecordForm.sections';

import { SHARE_RECORD_FORM_CONTEXT_ID } from './constants/shareRecordForm.general.constants';

import styles from './shareRecordForm.module.scss';

const ShareRecordForm = ({ entityDef, entityRecord, wwdAccessTypesOptions, shareAndManageRecordUserDetails, onUserAddition }) => {
  const {
    isUserAdditionInProgress,
    formValues,
    formErrors,
    handleSubmit = _noop,
    handleAction = _noop,
    fields,
  } = useShareRecordForm({
    onUserAddition,
    contextId: SHARE_RECORD_FORM_CONTEXT_ID,
    entityRecord,
    entityDef,
    shareAndManageRecordUserDetails,
    wwdAccessTypesOptions,
  });

  return (
    <div className={styles.shareRecordForm}>
      <FormWithSubmission
        className="full-width"
        contextId={SHARE_RECORD_FORM_CONTEXT_ID}
        fields={fields}
        sections={SHARE_RECORD_FORM_SECTIONS}
        values={formValues}
        errors={formErrors}
        onAction={handleAction}
      />
      <Button loading={isUserAdditionInProgress} className={styles.addButton} label={__('Add')} view={Button.VIEW.SECONDARY} onClick={handleSubmit} />
    </div>
  );
};

ShareRecordForm.propTypes = {
  entityDef: PropTypes.object,
  entityRecord: PropTypes.object,
  wwdAccessTypesOptions: PropTypes.array,
  shareAndManageRecordUserDetails: PropTypes.array,
  onUserAddition: PropTypes.func,
};

ShareRecordForm.defaultProps = {
  entityDef: EMPTY_OBJECT,
  entityRecord: EMPTY_OBJECT,
  wwdAccessTypesOptions: EMPTY_ARRAY,
  shareAndManageRecordUserDetails: EMPTY_ARRAY,
  onUserAddition: _noop,
};

export default ShareRecordForm;
