import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import { DATE_TIME_FORMATS, getFormattedDateTime } from '@tekion/tekion-base/utils/dateUtils';
import { TOASTER_TYPE, toaster } from '@tekion/tekion-components/organisms/NotificationWrapper';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';

// Constants
import { APPROVAL_CENTRE_FIELD_IDS } from '../constants/approvalCentre.constants';

// Services
import approvalCentreServices from '../services/approvalCentre.services';

const fetchAllApprovalTasks = async (payload) => {
  try {
    const response = await approvalCentreServices.fetchAllApprovalTasks(payload);
    const data = getDataFromResponse(response);
    const hits = tget(data, 'hits', EMPTY_ARRAY);
    const nextPageToken = tget(data, 'nextPageToken');

    const formattedApprovalTasks = _map(hits, (approvalTask = {}) => {
      const { [APPROVAL_CENTRE_FIELD_IDS.VALIDITY]: validity, [APPROVAL_CENTRE_FIELD_IDS.CREATED_TIME]: createdTime } = approvalTask;
      const dateFormattedApprovalTask = {
        ...approvalTask,
        [APPROVAL_CENTRE_FIELD_IDS.VALIDITY]: getFormattedDateTime(validity, DATE_TIME_FORMATS.MONTH_DATE_YEAR_TIME_12HR_FORMAT),
        [APPROVAL_CENTRE_FIELD_IDS.CREATED_TIME]: getFormattedDateTime(createdTime, DATE_TIME_FORMATS.MONTH_DATE_YEAR_TIME_12HR_FORMAT),
      };
      return dateFormattedApprovalTask;
    });

    return {
      data: formattedApprovalTasks,
      nextPageToken,
    };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch approval tasks, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchApprovalTasksForApprovalId = async (approvalId) => {
  try {
    const response = await approvalCentreServices.fetchApprovalTasksForApprovalId(approvalId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch tasks for this request, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const approveTask = async (taskId) => {
  try {
    const response = await approvalCentreServices.approveTask(taskId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to approve this request, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const rejectTask = async (taskId) => {
  try {
    const response = await approvalCentreServices.rejectTask(taskId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to reject this request, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchMatchingApprovalProcess = async (payload) => {
  try {
    const response = await approvalCentreServices.fetchMatchingApprovalProcess(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch approval process for this request, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchAllApprovalRequests = async (payload) => {
  try {
    const response = await approvalCentreServices.fetchAllApprovalRequests(payload);
    const data = getDataFromResponse(response);
    const hits = tget(data, 'hits', EMPTY_ARRAY);
    const nextPageToken = tget(data, 'nextPageToken');

    const formattedApprovals = _map(hits, (approval = {}) => {
      const {
        [APPROVAL_CENTRE_FIELD_IDS.VALIDITY]: validity,
        [APPROVAL_CENTRE_FIELD_IDS.CREATED_TIME]: createdTime,
        [APPROVAL_CENTRE_FIELD_IDS.LAST_MODIFIED_TIME]: lastModifiedTime,
      } = approval;
      const dateFormattedApproval = {
        ...approval,
        [APPROVAL_CENTRE_FIELD_IDS.VALIDITY]: getFormattedDateTime(validity, DATE_TIME_FORMATS.MONTH_DATE_YEAR_TIME_12HR_FORMAT),
        [APPROVAL_CENTRE_FIELD_IDS.CREATED_TIME]: getFormattedDateTime(createdTime, DATE_TIME_FORMATS.MONTH_DATE_YEAR_TIME_12HR_FORMAT),
        [APPROVAL_CENTRE_FIELD_IDS.LAST_MODIFIED_TIME]: getFormattedDateTime(lastModifiedTime, DATE_TIME_FORMATS.MONTH_DATE_YEAR_TIME_12HR_FORMAT),
      };
      return dateFormattedApproval;
    });

    return {
      data: formattedApprovals,
      nextPageToken,
    };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch approval requests, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createApprovalRequestUsingProcess = async (approvalProcess, payload) => {
  const processName = tget(approvalProcess, APPROVAL_CENTRE_FIELD_IDS.NAME);

  if (!_isEmpty(processName)) {
    try {
      const response = await approvalCentreServices.createApprovalRequestUsingProcess(processName, payload);
      toaster(TOASTER_TYPE.SUCCESS, __('Request sent for approval!'));
      return getDataFromResponse(response);
    } catch (error) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create approval request, please try again later.')));
      return EMPTY_OBJECT;
    }
  } else {
    try {
      const response = await approvalCentreServices.createApprovalRequest(payload);
      toaster(TOASTER_TYPE.SUCCESS, __('Request sent for approval!'));
      return getDataFromResponse(response);
    } catch (error) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create approval request, please try again later.')));
      return EMPTY_OBJECT;
    }
  }
};

const updateApprovalRequestUsingProcess = async (approvalId, approvalProcess, payload) => {
  const processName = tget(approvalProcess, APPROVAL_CENTRE_FIELD_IDS.NAME);

  if (!_isEmpty(processName)) {
    try {
      const response = await approvalCentreServices.updateApprovalRequestUsingProcess(approvalId, processName, payload);
      return getDataFromResponse(response);
    } catch (error) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update approval request, please try again later.')));
      return EMPTY_OBJECT;
    }
  } else {
    try {
      const response = await approvalCentreServices.updateApprovalRequest(approvalId, payload);
      return getDataFromResponse(response);
    } catch (error) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update approval request, please try again later.')));
      return EMPTY_OBJECT;
    }
  }
};

const cancelApprovalRequest = async (approvalId) => {
  try {
    const response = approvalCentreServices.cancelApprovalRequest(approvalId);
    toaster(TOASTER_TYPE.SUCCESS, __('Approval Request cancelled successfully!'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to cancel approval request, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getApprovalRequestById = async (approvalId) => {
  try {
    const response = await approvalCentreServices.getApprovalRequestById(approvalId);
    const approvalRequest = getDataFromResponse(response);
    const {
      [APPROVAL_CENTRE_FIELD_IDS.VALIDITY]: validity,
      [APPROVAL_CENTRE_FIELD_IDS.CREATED_TIME]: createdTime,
      [APPROVAL_CENTRE_FIELD_IDS.LAST_MODIFIED_TIME]: lastModifiedTime,
    } = approvalRequest;

    const dateFormattedApproval = {
      ...approvalRequest,
      [APPROVAL_CENTRE_FIELD_IDS.VALIDITY]: getFormattedDateTime(validity, DATE_TIME_FORMATS.MONTH_DATE_YEAR_TIME_12HR_FORMAT),
      [APPROVAL_CENTRE_FIELD_IDS.CREATED_TIME]: getFormattedDateTime(createdTime, DATE_TIME_FORMATS.MONTH_DATE_YEAR_TIME_12HR_FORMAT),
      [APPROVAL_CENTRE_FIELD_IDS.LAST_MODIFIED_TIME]: getFormattedDateTime(lastModifiedTime, DATE_TIME_FORMATS.MONTH_DATE_YEAR_TIME_12HR_FORMAT),
    };

    return dateFormattedApproval;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch approval request, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export {
  fetchAllApprovalTasks,
  fetchAllApprovalRequests,
  createApprovalRequestUsingProcess,
  updateApprovalRequestUsingProcess,
  cancelApprovalRequest,
  approveTask,
  rejectTask,
  fetchApprovalTasksForApprovalId,
  fetchMatchingApprovalProcess,
  getApprovalRequestById,
};
