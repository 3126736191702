import { defaultMemoize } from 'reselect';
import _head from 'lodash/head';
import _get from 'lodash/get';

// Utils
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

// Constants
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import FIELD_IDS from '../constants/approvalSettingFieldForm.fieldIds';
import FIELD_TYPES from '../../../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../../../constants/fieldDefinition.dataTypes';
import { FORM_MODES } from '../../../../../../../constants/general.constants';

// Styles
import styles from '../approvalSettingFieldForm.module.scss';

const rowCheck = {
  [FIELD_IDS.LIST_ADD_ROW_BUTTON]: ({ formMode }) => formMode === FORM_MODES.CREATE,
  [FIELD_IDS.CONTROLLING_FIELD_NAME]: ({ formValues }) => _get(formValues, FIELD_IDS.CONTROLLING_FUNCTION, false),
  [FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS]: ({ formValues }) => _get(formValues, FIELD_IDS.CONTROLLING_FUNCTION, false),
  [FIELD_IDS.MULTI_VALUED_ENABLED]: ({ formValues }) => {
    const dataType = _head(_get(formValues, FIELD_IDS.DATA_TYPE, EMPTY_ARRAY));
    const fieldType = _head(_get(formValues, FIELD_IDS.FIELD_TYPE, EMPTY_ARRAY));
    return (
      dataType !== DATA_TYPES.BOOLEAN &&
      fieldType !== FIELD_TYPES.RANGE &&
      fieldType !== FIELD_TYPES.RICH_TEXT_EDITOR &&
      fieldType !== FIELD_TYPES.CHILD_AGGREGATE_SUMMARY
    );
  },
};

const basicFieldDetails = (formValues) => [
  {
    header: {
      label: __('General Details'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
      },
      {
        columns: [FIELD_IDS.DATA_TYPE, FIELD_IDS.FIELD_TYPE],
      },
      {
        columns: filterRows([FIELD_IDS.MULTI_VALUED_ENABLED, FIELD_IDS.MANDATORY], { formValues }, rowCheck),
      },
      {
        columns: [FIELD_IDS.HELP_TEXT],
      },
      {
        columns: [FIELD_IDS.DEPENDENCY_CONFIG],
      },
    ],
  },
];

const TEXT_SECTION = [
  {
    header: {
      label: __('Text Length Constraints'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [FIELD_IDS.TEXT_MIN_LENGTH, FIELD_IDS.TEXT_MAX_LENGTH],
      },
    ],
  },
  {
    header: {
      label: __('Text Pattern Rule'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [FIELD_IDS.TEXT_REGEX],
      },
    ],
  },
];

const getListInputTableSection = defaultMemoize((formMode, formValues) => [
  {
    header: {
      label: __('Options to select from'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [FIELD_IDS.LIST_INPUT_TABLE],
      },
      {
        columns: filterRows([FIELD_IDS.LIST_ADD_ROW_BUTTON], { formMode }, rowCheck),
      },
      {
        columns: [FIELD_IDS.CONTROLLING_FUNCTION],
      },
      {
        columns: filterRows([FIELD_IDS.CONTROLLING_FIELD_NAME], { formValues }, rowCheck),
      },
      {
        columns: filterRows([FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS], { formValues }, rowCheck),
      },
    ],
  },
]);

export const getFormPropertiesSections = ({ formValues, formMode }) => {
  let fieldType = getArraySafeValue(_get(formValues, FIELD_IDS.FIELD_TYPE, EMPTY_ARRAY));
  const dataType = getArraySafeValue(_get(formValues, FIELD_IDS.DATA_TYPE, EMPTY_ARRAY));
  const multiValued = _get(formValues, FIELD_IDS.MULTI_VALUED_ENABLED);

  if (fieldType === FIELD_TYPES.SELECT && multiValued) {
    fieldType = FIELD_TYPES.MULTI_SELECT;
  } else if (fieldType === FIELD_TYPES.SELECT && !multiValued) {
    fieldType = FIELD_TYPES.SINGLE_SELECT;
  }

  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      if (dataType === DATA_TYPES.TEXT) return TEXT_SECTION;
      return EMPTY_ARRAY;
    case FIELD_TYPES.LIST:
      if (dataType === DATA_TYPES.TEXT) return TEXT_SECTION;
      return EMPTY_ARRAY;
    case FIELD_TYPES.SINGLE_SELECT:
      if (dataType === DATA_TYPES.TEXT) return getListInputTableSection(formMode, formValues);
      return EMPTY_ARRAY;
    case FIELD_TYPES.MULTI_SELECT:
      if (dataType === DATA_TYPES.TEXT) return getListInputTableSection(formMode, formValues);
      return EMPTY_ARRAY;
    default:
      return EMPTY_ARRAY;
  }
};

export const getFormSections = ({ formValues, formMode }) => [
  ...basicFieldDetails(formValues),
  ...getFormPropertiesSections({ formValues, formMode }),
];
