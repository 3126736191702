import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _unset from 'lodash/unset';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

// Components
import Button from '@tekion/tekion-components/atoms/Button';
import ResourceField from '../../atoms/resourceField';
import OperatorField from '../../atoms/operatorField/OperatorField';
import ValueField from '../../atoms/valueField/ValueField';

// Constants
import { OPERATORS_WITH_VALUE_NULL } from '../../atoms/operatorField/operatorField.constants';
import CONDITION_FIELD_IDS from '../../constants/condition.fieldIds';
import CONDITION_LIST_ACTION_TYPES from '../../constants/conditionList.actionTypes';

// Styles
import styles from './condition.module.scss';

const Condition = ({ required, showIndex, index, condition, errors, childProps, onAction }) => {
  const resourceValue = condition?.[CONDITION_FIELD_IDS.FIELD];
  const operatorValue = condition?.[CONDITION_FIELD_IDS.OPERATOR];
  const valueFieldValue = condition?.[CONDITION_FIELD_IDS.VALUES];

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { id: fieldId, value: fieldValue } = payload;
          const updatedCondition = {
            ...condition,
            [fieldId]: fieldValue,
          };

          if (fieldId === CONDITION_FIELD_IDS.FIELD) {
            _set(updatedCondition, CONDITION_FIELD_IDS.OPERATOR, undefined);
            _set(updatedCondition, CONDITION_FIELD_IDS.VALUES, undefined);
          }

          if (fieldId === CONDITION_FIELD_IDS.OPERATOR && _includes(OPERATORS_WITH_VALUE_NULL, fieldValue)) {
            _unset(updatedCondition, CONDITION_FIELD_IDS.VALUES);
          }

          onAction({
            type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
            payload: {
              index,
              condition: updatedCondition,
            },
          });
          break;
        }

        default:
          onAction(action);
      }
    },
    [index, condition, onAction],
  );

  const onDeleteClick = useCallback(() => {
    onAction({
      type: CONDITION_LIST_ACTION_TYPES.DELETE_ROW,
      payload: {
        index,
      },
    });
  }, [index, onAction]);

  return (
    <div className={styles.container}>
      {showIndex && <div className={styles.index}>{index + 1}.</div>}
      <ResourceField
        required={required}
        id={CONDITION_FIELD_IDS.FIELD}
        label={__('Resource')}
        fieldClassName={fieldLayoutStyles.fieldC}
        value={resourceValue}
        error={errors?.[CONDITION_FIELD_IDS.FIELD]}
        {...childProps?.[CONDITION_FIELD_IDS.FIELD]}
        onAction={handleAction}
      />
      <OperatorField
        required={required}
        isDisabled={_isEmpty(resourceValue)}
        id={CONDITION_FIELD_IDS.OPERATOR}
        label={__('Operator')}
        fieldClassName={fieldLayoutStyles.fieldC}
        value={operatorValue}
        error={errors?.[CONDITION_FIELD_IDS.OPERATOR]}
        values={condition}
        {...childProps?.[CONDITION_FIELD_IDS.OPERATOR]}
        onAction={handleAction}
      />
      <ValueField
        required={required}
        isDisabled={_isEmpty(resourceValue) || _isEmpty(operatorValue)}
        id={CONDITION_FIELD_IDS.VALUES}
        label={__('Value')}
        fieldClassName={fieldLayoutStyles.fieldC}
        value={valueFieldValue}
        error={errors?.[CONDITION_FIELD_IDS.VALUES]}
        values={condition}
        {...childProps?.[CONDITION_FIELD_IDS.VALUES]}
        onAction={handleAction}
      />
      <Button view={Button.VIEW.ICON} icon="icon-trash" iconClassName={styles.icon} onClick={onDeleteClick} />
    </div>
  );
};

Condition.propTypes = {
  required: PropTypes.bool,
  showIndex: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  condition: PropTypes.object,
  errors: PropTypes.object,
  childProps: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

Condition.defaultProps = {
  required: false,
  condition: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  childProps: EMPTY_OBJECT,
};

export default Condition;
