import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import { CUSTOM_ENTITY_CATEGORY } from '../../../../../../constants/approvalCentre.constants';
import FIELD_IDS from '../constants/approvalRequestForm.fieldIds';

import styles from '../approvalRequestForm.module.scss';

const rowCheck = {
  [FIELD_IDS.TYPE]: ({ [FIELD_IDS.CATEGORY]: category } = {}) => category === CUSTOM_ENTITY_CATEGORY,
};

const getSettingIdentifierSections = (values) => [
  {
    className: styles.section,
    rows: [
      { columns: [FIELD_IDS.NAME] },
      { columns: [FIELD_IDS.GROUP, FIELD_IDS.CATEGORY] },
      { columns: filterRows([FIELD_IDS.TYPE], values, rowCheck) },
    ],
  },
  {
    className: styles.section,
    rows: [{ columns: [FIELD_IDS.COMMENT] }],
  },
];

export default getSettingIdentifierSections;
