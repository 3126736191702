import _join from 'lodash/join';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';

// Services
import ruleBuilderServices from '../services/ruleBuilder.services';

const fetchRules = async () => {
  try {
    const response = await ruleBuilderServices.fetchRules();
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch rules, please try again later!')));
    return EMPTY_ARRAY;
  }
};

const createRule = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await ruleBuilderServices.createRule(payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Rule created successfully!'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create the rule, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateRule = async (workflowId, payload = EMPTY_OBJECT) => {
  try {
    const response = await ruleBuilderServices.updateRule(workflowId, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Rule updated successfully!'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update the rule, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getRuleById = async (workflowId) => {
  try {
    const response = await ruleBuilderServices.getRuleById(workflowId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get the rule, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchRuleTaskDefinitions = async (eventTypes) => {
  const _eventTypes = _join(eventTypes, ',');
  try {
    const response = await ruleBuilderServices.fetchRuleTaskDefinitions(_eventTypes);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch task definitions, please try again later!')));
    return EMPTY_ARRAY;
  }
};

const fetchRuleGlobalMetadata = async () => {
  try {
    const response = await ruleBuilderServices.fetchRuleGlobalMetadata();
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch event types, please try again later!')));
    return EMPTY_OBJECT;
  }
};

const activateRule = async (workflowId, payload = EMPTY_OBJECT) => {
  try {
    const response = await ruleBuilderServices.activateRule(workflowId, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Rule activate successfully!'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to activate the rule, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const deactivateRule = async (workflowId, payload = EMPTY_OBJECT) => {
  try {
    const response = await ruleBuilderServices.deactivateRule(workflowId, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Rule deactivate successfully!'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to deactivate the rule, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export { fetchRules, createRule, updateRule, getRuleById, fetchRuleTaskDefinitions, fetchRuleGlobalMetadata, activateRule, deactivateRule };
