import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _toString from 'lodash/toString';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import Collapse from '@tekion/tekion-components/molecules/Collapse';
import Heading from '@tekion/tekion-components/atoms/Heading';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Loader from '@tekion/tekion-components/molecules/loader';

import ApprovalStagePanelHeader from './components/approvalStagePanelHeader/ApprovalStagePanelHeader';
import StageApproversDetail from './components/approversDetails/ApproversDetails';

import { resolveApproversForProcess } from '../../actions/approvalManagement.actions';
import { getStagesData } from './approvalStageCollapsiblePanels.helpers';

import { APPROVAL_CENTRE_FIELD_IDS } from '../../constants/approvalCentre.constants';
import { ASSET_TYPES } from '../../constants/general.constants';

import styles from './approvalStageCollapsiblePanels.module.scss';

const ApprovalStageCollapsiblePanels = ({
  isBuilderMode,
  isApprovalProcessAvailable,
  tableClassname,
  containerClassName,
  approvalStages,
  ...restProps
}) => {
  const [isApproverResolving, setIsApproverResolving] = useState();
  const [stagesData, setStagesData] = useState([]);
  const [activeCollapsePanels, setActiveCollapsePanels] = useState([]);

  const resolveApproversForStagesTableData = useCallback(async () => {
    setIsApproverResolving(true);
    const userResolvedApprovers = await resolveApproversForProcess(approvalStages);
    const _stagesTableData = getStagesData(userResolvedApprovers);
    setIsApproverResolving(false);
    setActiveCollapsePanels([..._map(_stagesTableData, (_, index) => _toString(index))]);
    return setStagesData(_stagesTableData);
  }, [approvalStages]);

  const handleCollapsePanelToggleChange = useCallback((newActiveCollapsePanels) => {
    setActiveCollapsePanels(newActiveCollapsePanels);
  }, []);

  useEffect(() => {
    resolveApproversForStagesTableData();
  }, [resolveApproversForStagesTableData]);

  if (!isBuilderMode) {
    if (!isApprovalProcessAvailable) {
      return <div>{__('No matching approval process.')}</div>;
    }

    if (_isEmpty(approvalStages)) {
      return <div>{__('No approval stages, the request will be approved automatically.')}</div>;
    }
  }

  return (
    <>
      <PropertyControlledComponent controllerProperty={!isBuilderMode}>
        <Heading size={1} className="p-l-24 p-b-8">
          {__('Approval stages')}
        </Heading>
      </PropertyControlledComponent>
      <PropertyControlledComponent controllerProperty={!isApproverResolving} fallback={<Loader id="STAGE_DETAILS" />}>
        {_isEmpty(stagesData) ? (
          <div className="m-l-24">{__('No approval stages, your request will be auto-approved.')}</div>
        ) : (
          <div className={cx(styles.stageDetailsSection, containerClassName)}>
            <Collapse
              bordered={false}
              className={tableClassname}
              collapseHeaderClassName={styles.panelHeader}
              bodyClassName={styles.collapseBody}
              activeKey={activeCollapsePanels}
              onChange={handleCollapsePanelToggleChange}
            >
              {_map(stagesData, (stageDetails, index) => (
                <Collapse.Panel
                  key={index}
                  panelKey={index}
                  headerClass={styles.panelHeader}
                  header={<ApprovalStagePanelHeader isBuilderMode={isBuilderMode} index={index} stageDetails={stageDetails} {...restProps} />}
                >
                  <StageApproversDetail assetTypes={ASSET_TYPES} approversDetails={tget(stageDetails, APPROVAL_CENTRE_FIELD_IDS.APPROVERS)} />
                </Collapse.Panel>
              ))}
            </Collapse>
          </div>
        )}
      </PropertyControlledComponent>
    </>
  );
};

ApprovalStageCollapsiblePanels.propTypes = {
  isBuilderMode: PropTypes.bool,
  isApprovalProcessAvailable: PropTypes.bool,
  containerClassName: PropTypes.string,
  tableClassname: PropTypes.string,
  approvalStages: PropTypes.array,
};

ApprovalStageCollapsiblePanels.defaultProps = {
  isBuilderMode: false,
  isApprovalProcessAvailable: true,
  containerClassName: EMPTY_STRING,
  tableClassname: EMPTY_STRING,
  approvalStages: EMPTY_ARRAY,
};

export default ApprovalStageCollapsiblePanels;
