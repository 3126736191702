import _filter from 'lodash/filter';
import _split from 'lodash/split';
import _tail from 'lodash/last';
import _toLower from 'lodash/toLower';

import { tget, uuid } from '@tekion/tekion-base/utils/general';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getConfigFormattedSelectedWidgetProperties, getFormFormattedSelectedWidgetProperties } from './tabPropertiesConfigurator.helpers';

import { TAB_PROPERTIES_CONFIGURE_FIELD_IDS } from '../constants/tabPropertiesConfigurator.fieldIds';
import {
  ACTION_TYPES as VISUAL_BUILDER_ACTION_TYPES,
  COMPONENT_CONFIG_KEYS,
  WIDGET_TYPES,
  WIDGET_POSITIONS,
} from '../../../../../constants/visualBuilder.general.constants';
import ACTION_TYPES from '../constants/tabPropertiesConfigurator.actionTypes';

const handleSelectedViewComponentPropertiesChange = ({ getState, setState }) => {
  const { selectedWidgetConfig } = getState();

  const selectedWidgetChildren = tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.CHILDREN, []);
  const selectedWidgetProperties = tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, {});
  const formFormattedSelectedWidgetProperties = getFormFormattedSelectedWidgetProperties(selectedWidgetProperties);
  const tabContentDetails = tget(formFormattedSelectedWidgetProperties, TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_NAMES, []);

  setState({ formFormattedSelectedWidgetProperties, tabContentDetails, tabContentChildren: selectedWidgetChildren });
};

const handleConfigureTabClick = ({ getState, setState }) => {
  const { formFormattedSelectedWidgetProperties } = getState();
  setState({
    tabContentDetails: tget(formFormattedSelectedWidgetProperties, TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_NAMES, [{}]),
    isTabConfigureModalVisible: true,
  });
};

const handleAddTab = ({ getState, setState }) => {
  const { tabContentDetails = [], tabContentChildren } = getState();

  const newChildren = {
    widgetName: `${_toLower(WIDGET_TYPES.CONTAINER)}_${_tail(_split(uuid(), '-'))}`,
    widgetType: WIDGET_TYPES.CONTAINER,
    properties: { width: 20, widgetPosition: WIDGET_POSITIONS.VERTICAL },
  };

  setState({
    tabContentDetails: [...tabContentDetails, {}],
    tabContentChildren: [...tabContentChildren, newChildren],
  });
};

const handleOnChange = ({ getState, setState, params }) => {
  const { formFormattedSelectedWidgetProperties, selectedWidgetConfig, onChangeWidgetConfigurationAction } = getState();

  const { id, value } = params;

  if (id === TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_NAMES) {
    setState({ tabContentDetails: value });
  } else {
    const newTabFormValues = getConfigFormattedSelectedWidgetProperties(value, id, formFormattedSelectedWidgetProperties);

    const widgetName = tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, '');
    onChangeWidgetConfigurationAction({
      type: VISUAL_BUILDER_ACTION_TYPES.UPDATE_WIDGET_CONFIGURATION,
      payload: {
        value: { widgetConfiguration: { ...selectedWidgetConfig, properties: { ...newTabFormValues } }, widgetName },
      },
    });

    setState({ formFormattedSelectedWidgetProperties: newTabFormValues });
  }
};

const handleSubmit = ({ getState, setState }) => {
  const { tabContentDetails, formFormattedSelectedWidgetProperties, selectedWidgetConfig, onChangeWidgetConfigurationAction, tabContentChildren } =
    getState();
  const widgetName = tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, '');
  const newTabFormValues = getConfigFormattedSelectedWidgetProperties(
    tabContentDetails,
    TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_NAMES,
    formFormattedSelectedWidgetProperties,
  );

  if (newTabFormValues) {
    onChangeWidgetConfigurationAction({
      type: VISUAL_BUILDER_ACTION_TYPES.UPDATE_WIDGET_CONFIGURATION,
      payload: {
        value: { widgetConfiguration: { ...selectedWidgetConfig, properties: { ...newTabFormValues }, children: tabContentChildren }, widgetName },
      },
    });
  }

  setState({ isTabConfigureModalVisible: false, formFormattedSelectedWidgetProperties: newTabFormValues });
};

const handleDeleteTab = ({ getState, setState, params }) => {
  const { tabContentDetails } = getState();
  const { index, tabContentChildren } = params;

  const newTabContentDetails = _filter(tabContentDetails, (tabContent, tabIndex) => index !== tabIndex);
  const newTabContentChildren = _filter(tabContentChildren, (tabContent, tabIndex) => index !== tabIndex);

  setState({
    tabContentDetails: newTabContentDetails,
    tabContentChildren: newTabContentChildren,
  });
};

const handleCancelModal = ({ getState, setState }) => {
  const { formFormattedSelectedWidgetProperties } = getState();
  setState({
    isTabConfigureModalVisible: false,
    tabContentDetails: tget(formFormattedSelectedWidgetProperties, TAB_PROPERTIES_CONFIGURE_FIELD_IDS.TAB_NAMES, []),
  });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE]: handleSelectedViewComponentPropertiesChange,
  [ACTION_TYPES.ON_CLICK_CONFIGURE_TAB]: handleConfigureTabClick,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnChange,
  [ACTION_TYPES.ON_SAVE_MODAL]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL_MODAL]: handleCancelModal,
  [ACTION_TYPES.ON_DELETE_TAB]: handleDeleteTab,
  [ACTION_TYPES.ON_ADD_TAB]: handleAddTab,
};

export default ACTION_HANDLERS;
