import { useState, useEffect } from 'react';
import _head from 'lodash/head';
import _get from 'lodash/get';

// constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { STATUS } from '@tekion/tekion-base/constants/status.constants';

// hooks
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import useLoading from '@tekion/tekion-components/hooks/useLoading';

import { getPreSignedUrls } from '../../../../../actions/media.actions';
import { POLL } from '../constants/image.general';

const pollTask = async (mediaId, resolve, reject, counter = 1) => {
  if (counter === POLL.COUNT) {
    return reject();
  }
  const newCounter = counter + 1;
  const response = await getPreSignedUrls([mediaId]);
  const status = _get(getArraySafeValue(response), 'uploadStatus');
  if (status === STATUS.COMPLETED) {
    resolve(response);
  } else if (status === STATUS.FAILED) {
    reject();
  } else setTimeout(() => pollTask(mediaId, resolve, reject, newCounter), POLL.INTERVAL);
  return EMPTY_OBJECT;
};

const generateSecuredMediaUrl = async ({ mediaId, setLoaded, setPresignedUrl }) => {
  const promise = new Promise((resolve, reject) => {
    pollTask(mediaId, resolve, reject);
  });
  promise
    .then(async (response) => {
      // If we have "n" answers it will show "n" toasters simultaneously
      // toaster(TOASTER_TYPE.SUCCESS, __('Image is successfully loaded'));
      const presignedUrl = _get(_head(response), 'normal.url');
      setPresignedUrl(presignedUrl);
      setLoaded();
    })
    .catch(() => {
      toaster(TOASTER_TYPE.ERROR, __('Failed to get image.Please delete it by clicking on delete icon and upload it again.'));
      setLoaded();
    });
};

function useSecuredImageURL(mediaId) {
  const [presignedUrl, setPresignedUrl] = useState('');
  const [isLoading, setLoaded] = useLoading(true);

  useEffect(() => {
    if (mediaId) {
      generateSecuredMediaUrl({ mediaId, setLoaded, setPresignedUrl });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, EMPTY_ARRAY);

  return { isLoading, presignedUrl };
}

export default useSecuredImageURL;
