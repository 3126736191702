import cx from 'classnames';

import ColumnConfig from '../components/columnConfig/ColumnConfig';
import { ADD_WIDGET_FORM_FIELDS } from './addWidget.general.constant';

import styles from '../addWidget.module.scss';

const DIMENSION_FIELD = {
  id: ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS,
  renderer: ColumnConfig,
  renderOptions: {
    headingTitle: __('Select Dimensions'),
    showFilterColumn: true,
    fieldTitle: __('Dimension'),
    fieldId: ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS,
    className: cx('full-width', styles.columnTable),
  },
};

const MEASUREMENT_FIELD = {
  id: ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS,
  renderer: ColumnConfig,
  renderOptions: {
    headingTitle: __('Select Measurements'),
    fieldTitle: __('Measurements'),
    fieldId: ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS,
    className: cx('full-width', styles.columnTable),
  },
};

export { DIMENSION_FIELD, MEASUREMENT_FIELD };
