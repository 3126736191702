exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".leftSubheader_leftSubheaderContainer__9JybFEgps1{display:flex;align-self:flex-end;align-items:flex-end}[dir=ltr] .leftSubheader_leftSubheaderContainer__9JybFEgps1{margin-left:1rem}[dir=rtl] .leftSubheader_leftSubheaderContainer__9JybFEgps1{margin-right:1rem}.leftSubheader_recordTypeValue__a8YcebqJVc{font-weight:bold}[dir=ltr] .leftSubheader_recordTypeValue__a8YcebqJVc{margin-right:.8rem}[dir=rtl] .leftSubheader_recordTypeValue__a8YcebqJVc{margin-left:.8rem}.leftSubheader_derivationFieldsContainer__pNpDmDKASz{display:flex;align-self:flex-end;align-items:flex-end}[dir=ltr] .leftSubheader_derivationFieldsContainer__pNpDmDKASz{margin-left:2.4rem}[dir=rtl] .leftSubheader_derivationFieldsContainer__pNpDmDKASz{margin-right:2.4rem}[dir] .leftSubheader_button__gc6NXdW7EB{padding-bottom:.2rem !important}[dir] .leftSubheader_editIcon__2MTsmsRkH7{margin-bottom:.4rem}", ""]);

// Exports
exports.locals = {
	"leftSubheaderContainer": "leftSubheader_leftSubheaderContainer__9JybFEgps1",
	"recordTypeValue": "leftSubheader_recordTypeValue__a8YcebqJVc",
	"derivationFieldsContainer": "leftSubheader_derivationFieldsContainer__pNpDmDKASz",
	"button": "leftSubheader_button__gc6NXdW7EB",
	"editIcon": "leftSubheader_editIcon__2MTsmsRkH7"
};