import React, { useCallback, useState, useRef, useMemo } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import PropTypes from 'prop-types';

import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';

import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import CommentContentRenderer from './components/commentContentRenderer';
import SuggestionsListRenderer from './components/suggestionsListRenderer';

import { checkIfIsNilNextPageToken, defaultGetOptions, defaultGetPayload, defaultServiceHandler } from './asyncMentions.helpers';

import { DEFAULT_MENTIONS_STYLE, MENTIONS_ACTION_TYPES, NO_SUGGESTION_FOUND } from './asyncMentions.constants';

import styles from './asyncMentions.module.scss';

const AsyncMentions = ({
  isPreviewMode,
  trigger,
  content,
  placement,
  notFoundMentionsDisplayMessage,
  staticMentionOptions,
  getPayload,
  serviceHandler,
  getOptions,
  displayTransform,
  onAction,
  ...restProps
}) => {
  const ref = useRef(null);

  const [areMentionsLoading, setAreMentionsLoading] = useState(false);
  const [mentionOptions, setMentionOptions] = useState(staticMentionOptions);
  const [nextPageToken, setNextPageToken] = useState(EMPTY_STRING);
  const [searchText, setSearchText] = useState(EMPTY_STRING);

  const handleChange = useCallback(
    (event) => {
      const newContent = event.target.value;
      onAction({ type: MENTIONS_ACTION_TYPES.ON_CHANGE_MENTIONS_CONTENT, payload: { newContent } });
    },
    [onAction],
  );

  const handleBlur = useCallback(() => {
    setSearchText(EMPTY_STRING);
    setNextPageToken(EMPTY_STRING);
    setMentionOptions(EMPTY_ARRAY);
    setAreMentionsLoading(false);
  }, []);

  const fetchMentions = useMemo(
    () =>
      _debounce(async (inputString, callback, isScroll = false) => {
        let payload = {};
        setAreMentionsLoading(true);

        if (callback) {
          // since callback is provided to us by Mentions on trigger only, and in case where we want to fetch the data onScroll,
          // then that is not handled by Mentions, so in those cases we would not be getting callback,
          // so storing callback function into ref so that we can use it later, as ref does not looses it's value during re-renders
          ref.current = callback;
        }

        if (inputString === searchText) {
          payload = getPayload(searchText, nextPageToken);
        } else {
          setSearchText(inputString);
          payload = getPayload(inputString, EMPTY_STRING);
        }

        const response = await serviceHandler(payload);
        const hits = tget(response, 'hits', EMPTY_ARRAY);
        const newNextPageToken = tget(response, 'nextPageToken');
        let newMentionOptions = getOptions(hits);

        newMentionOptions = isScroll
          ? [...staticMentionOptions, ...mentionOptions, ...newMentionOptions]
          : [...staticMentionOptions, ...newMentionOptions];

        setMentionOptions(newMentionOptions);
        setNextPageToken(newNextPageToken);
        setAreMentionsLoading(false);

        if (_isEmpty(newMentionOptions)) {
          newMentionOptions = [{ id: NO_SUGGESTION_FOUND, display: notFoundMentionsDisplayMessage }];
        }

        callback(newMentionOptions);
      }, 500),
    [getOptions, getPayload, mentionOptions, nextPageToken, notFoundMentionsDisplayMessage, searchText, serviceHandler, staticMentionOptions],
  );

  const getData = useCallback(
    (inputString, callback) => {
      setAreMentionsLoading(true);
      return fetchMentions(inputString, callback, false);
    },
    [fetchMentions],
  );

  const handleScroll = useCallback(
    (event) => {
      if (event) {
        const element = event.target;
        const isAtBottom = element.scrollHeight - element.scrollTop - element.clientHeight < 10;

        if (isAtBottom && checkIfIsNilNextPageToken(nextPageToken)) {
          setAreMentionsLoading(true);
          fetchMentions(searchText, ref.current, true);
        }
      }
    },
    [nextPageToken, fetchMentions, searchText],
  );

  const renderCustomSuggestionsContainer = useCallback(
    (children) => {
      const suggestionsList = tget(children, 'props.children', EMPTY_ARRAY);
      const suggestions = _map(suggestionsList, (suggestion) =>
        _pick(tget(suggestion, 'props', EMPTY_OBJECT), ['onClick', 'onMouseEnter', 'suggestion']),
      );

      return (
        <div className={styles.suggestionsContainer} onScroll={handleScroll}>
          <SuggestionsListRenderer
            areMentionsLoading={areMentionsLoading}
            notFoundMentionsDisplayMessage={notFoundMentionsDisplayMessage}
            suggestions={suggestions}
          />
        </div>
      );
    },
    [areMentionsLoading, notFoundMentionsDisplayMessage, handleScroll],
  );

  return (
    <PropertyControlledComponent controllerProperty={!isPreviewMode} fallback={<CommentContentRenderer content={content} />}>
      <MentionsInput
        allowSuggestionsAboveCursor
        allowSpaceInQuery
        value={content}
        placement={placement}
        className={styles.mentionsTextArea}
        placeholder={__('Add comment, use the @ symbol to tag other users.')}
        style={DEFAULT_MENTIONS_STYLE}
        customSuggestionsContainer={renderCustomSuggestionsContainer}
        onChange={handleChange}
        onBlur={handleBlur}
        {...restProps}
      >
        <Mention
          isLoading={areMentionsLoading}
          appendSpaceOnAdd
          trigger={trigger}
          className="mentions__mention"
          markup="@[__display__]<@__id__>"
          data={getData}
          displayTransform={displayTransform}
          {...restProps}
        />
      </MentionsInput>
    </PropertyControlledComponent>
  );
};

AsyncMentions.propTypes = {
  isPreviewMode: PropTypes.bool,
  trigger: PropTypes.string,
  placement: PropTypes.string,
  content: PropTypes.string,
  notFoundMentionsDisplayMessage: PropTypes.string,
  staticMentionOptions: PropTypes.array,
  serviceHandler: PropTypes.func,
  getPayload: PropTypes.func,
  getOptions: PropTypes.func,
  displayTransform: PropTypes.func,
  onAction: PropTypes.func.isRequired,
};

AsyncMentions.defaultProps = {
  isPreviewMode: false,
  trigger: '@',
  placement: 'bottom',
  content: EMPTY_STRING,
  notFoundMentionsDisplayMessage: __('No users found'),
  staticMentionOptions: EMPTY_ARRAY,
  serviceHandler: defaultServiceHandler,
  getPayload: defaultGetPayload,
  getOptions: defaultGetOptions,
  displayTransform: (id, display) => __(`@${display}`),
};

export default AsyncMentions;
