import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _size from 'lodash/size';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { checkDeletedModuleIsSelectedAndLastModule, filterFormValueForDeletedTab } from './eventHandlerConfigurator.helper';

import { getUpdatedSelectedComponentProperties } from '../../../../../../viewBuilder/organisms/configurator/configurator.helper';

import { FIELD_IDS, INITIAL_EVENT_HANDLER_CONFIGURATION } from '../constants/eventHandlerConfigurator.constants';
import { ACTION_TYPES as VISUAL_BUILDER_ACTION_TYPES, COMPONENT_CONFIG_KEYS } from '../../../../../constants/visualBuilder.general.constants';
import ACTION_TYPES from '../constants/eventHandlerConfigurator.actionTypes';
import { FIELD_IDS as EVENT_HANDLER_MODAL_FIELD_IDS } from '../components/eventHandlerModal/eventHandlerModal.constants';

import eventConfiguratorReader from '../../../../../../../readers/eventConfigurator.reader';

const handleSelectedViewComponentPropertiesChange = ({ setState, params }) => {
  const { selectedWidgetConfig = {} } = params;

  const currentFieldTitle = eventConfiguratorReader.title(selectedWidgetConfig);
  const eventHandlers = tget(selectedWidgetConfig, 'properties.eventHandlers') || [];
  const widgetType = eventConfiguratorReader.widgetType(selectedWidgetConfig);

  let allFormValues = _map(eventHandlers, (eventData) => ({ [EVENT_HANDLER_MODAL_FIELD_IDS.EVENT_HANDLER]: eventData }));

  if (_isEmpty(eventHandlers)) {
    allFormValues = [INITIAL_EVENT_HANDLER_CONFIGURATION];
  }

  setState({ currentFieldTitle, widgetType, allFormValues, selectedModuleIndex: 0 });
};

const handleConfigureEventClick = ({ getState, setState }) => {
  const { allFormValues, selectedModuleIndex } = getState();

  setState({
    prevState: {
      allFormValues,
      selectedModuleIndex,
    },
    isModalVisible: true,
  });
};

const handleAddEventClick = ({ getState, setState }) => {
  const { allFormValues = [] } = getState();

  const allEventHandlerSize = _size(allFormValues);

  setState({ allFormValues: [...allFormValues, INITIAL_EVENT_HANDLER_CONFIGURATION], selectedModuleIndex: allEventHandlerSize });
};

const handleModuleIndexChange = ({ setState, params }) => {
  const { moduleIndex } = params;
  setState({ selectedModuleIndex: moduleIndex, errors: {} });
};

const handleOnChange = ({ getState, setState, params }) => {
  const { selectedModuleIndex, allFormValues = [] } = getState();
  const { id, value } = params;
  const newAllFormValues = [...allFormValues];

  const selectedFormValues = { [id]: value };

  _set(newAllFormValues, [selectedModuleIndex], selectedFormValues);

  setState({ allFormValues: newAllFormValues });
};

const handleSubmit = ({ getState, setState }) => {
  const { allFormValues, onChangeWidgetConfigurationAction, selectedWidgetConfig } = getState();

  const eventHandlers = _map(allFormValues, (formValue) => tget(formValue, EVENT_HANDLER_MODAL_FIELD_IDS.EVENT_HANDLER, EMPTY_OBJECT));
  const widgetName = tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, '');

  const newViewComponentProperties = getUpdatedSelectedComponentProperties(tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, {}), [
    {
      id: FIELD_IDS.EVENT_HANDLERS,
      value: eventHandlers,
    },
  ]);

  onChangeWidgetConfigurationAction({
    type: VISUAL_BUILDER_ACTION_TYPES.UPDATE_WIDGET_CONFIGURATION,
    payload: {
      value: { widgetConfiguration: { ...selectedWidgetConfig, properties: { ...newViewComponentProperties } }, widgetName },
    },
  });
  setState({ isModalVisible: false });
};

const handleErrors = ({ setState, params }) => {
  const { errors } = params;

  setState({ errors });
};

const handleValidationSuccess = ({ getState, setState, params }) => {
  const { additional } = params;
  const actionType = _get(additional, 'actionType');
  const payload = _get(additional, 'payload', {});

  switch (actionType) {
    case ACTION_TYPES.ON_CLICK_ADD_EVENT:
      handleAddEventClick({ getState, setState });
      break;

    case ACTION_TYPES.ON_SAVE_MODAL:
      handleSubmit({ getState, setState });
      break;

    case ACTION_TYPES.ON_CHANGE_MODULE:
      handleModuleIndexChange({ getState, setState, params: payload });
      break;

    default:
      break;
  }
};

const handleDeleteRow = ({ getState, setState, params }) => {
  const { allFormValues, selectedModuleIndex } = getState();
  const { moduleIndex } = params;
  let newSelectedModuleIndex = selectedModuleIndex;

  const newAllFormValues = filterFormValueForDeletedTab(allFormValues, moduleIndex);

  if (checkDeletedModuleIsSelectedAndLastModule(moduleIndex, selectedModuleIndex, allFormValues)) {
    newSelectedModuleIndex = moduleIndex - 1;
  } else if (moduleIndex < selectedModuleIndex) {
    newSelectedModuleIndex -= 1;
  }

  setState({ allFormValues: newAllFormValues }, () => {
    handleModuleIndexChange({ getState, setState, params: { moduleIndex: newSelectedModuleIndex } });
  });
};

const handleCancelModal = ({ getState, setState }) => {
  const { prevState = EMPTY_OBJECT } = getState();
  setState({ isModalVisible: false, ...prevState });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE]: handleSelectedViewComponentPropertiesChange,
  [ACTION_TYPES.ON_CLICK_CONFIGURE_EVENT]: handleConfigureEventClick,
  [ACTION_TYPES.ON_CLICK_ADD_EVENT]: handleAddEventClick,
  [ACTION_TYPES.ON_CHANGE_MODULE]: handleModuleIndexChange,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleValidationSuccess,
  [ACTION_TYPES.ON_SAVE_MODAL]: handleSubmit,
  [ACTION_TYPES.DELETE_ROW]: handleDeleteRow,
  [ACTION_TYPES.ON_CANCEL_MODAL]: handleCancelModal,
};

export default ACTION_HANDLERS;
