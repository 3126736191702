import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { FIELD_IDS } from '../constants/globalSelectListForm.general.constants';

const getOptions = (options) =>
  _map(options, (option) => ({
    label: _get(option, 'label'),
    value: _get(option, 'value'),
  }));

const getOptionsForPayload = (oldOptions, formValues) => {
  const options = tget(formValues, FIELD_IDS.OPTIONS, EMPTY_ARRAY);
  const oldOptionsByValue = _keyBy(oldOptions, 'value');
  const optionsPayload = _filter(options, (option) => {
    let isOldOption = false;
    if (!_isEmpty(tget(oldOptionsByValue, tget(option, 'value')))) {
      isOldOption = true;
    }
    return !isOldOption;
  });
  return {
    [FIELD_IDS.OPTIONS]: getOptions(optionsPayload),
  };
};

export { getOptionsForPayload, getOptions };
