import { PAGE_TYPES } from '../../../../../../constants/visualBuilder';

const CONTEXT_ID = 'VISUAL_BUILDER_METADATA_PAGE';

const ACTION_TYPES = {
  INITIALIZE: 'INITIALIZE',
  ON_CANCEL: 'ON_CANCEL',
  ON_INIT: 'ON_INIT',
};

const FIELD_IDS = {
  PAGE_TITLE: 'title',
  PAGE_NAME: 'name',
  PAGE_DESCRIPTION: 'description',
  PAGE_TYPE: 'pageType',
  LAYOUT_NAME: 'layout',
  PAGE_ENTITY_NAME: 'entityName',
};

const PAGE_TYPE_OPTIONS = [
  {
    label: __('App Page'),
    value: PAGE_TYPES.HOME_PAGE,
  },
  {
    label: __('Record Page'),
    value: PAGE_TYPES.RECORD_DETAIL_PAGE,
  },
];

export { FIELD_IDS, CONTEXT_ID, ACTION_TYPES, PAGE_TYPE_OPTIONS };
