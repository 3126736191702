const getOverrideStyles = (isAccessDenied, isSelectedAccessTypeNotOfAccessDenied) => ({
  control: (base) => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: isAccessDenied && isSelectedAccessTypeNotOfAccessDenied ? '#feeae6' : 'white',
  }),
});

export { getOverrideStyles };
