exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".linkSelectionPopupContent_popupActions__6YzXV6UKPT{justify-content:flex-end}.linkSelectionPopupContent_closeBtn__qp5D5P9BZL{top:1.2rem}[dir] .linkSelectionPopupContent_closeBtn__qp5D5P9BZL{padding:.4rem}[dir=ltr] .linkSelectionPopupContent_closeBtn__qp5D5P9BZL{right:1.2rem}[dir=rtl] .linkSelectionPopupContent_closeBtn__qp5D5P9BZL{left:1.2rem}.linkSelectionPopupContent_popupContainer__akGH5yfQp6{width:38rem;height:14rem;justify-content:space-between}.linkSelectionPopupContent_linkContent__ofuhzWrEsR{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.linkSelectionPopupContent_linkContentContainer__pTPkwpzhJ4{width:30rem}", ""]);

// Exports
exports.locals = {
	"popupActions": "linkSelectionPopupContent_popupActions__6YzXV6UKPT",
	"closeBtn": "linkSelectionPopupContent_closeBtn__qp5D5P9BZL",
	"popupContainer": "linkSelectionPopupContent_popupContainer__akGH5yfQp6",
	"linkContent": "linkSelectionPopupContent_linkContent__ofuhzWrEsR",
	"linkContentContainer": "linkSelectionPopupContent_linkContentContainer__pTPkwpzhJ4"
};