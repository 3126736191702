const APPLICATION_SEARCH_PARAM_IDS = {
  PAGE_NAME: 'pageName',
  TAB_ID: 'tab',
  PAGE_ID: 'pageId',
  PAGE_RECORD_ID: 'pageRecordId',
};

const COMPONENTS_TYPES = {
  NAVIGATION: 'NAVIGATION',
  NO_NAVIGATION: 'NO_NAVIGATION',
  HEADER: 'HEADER',
  FOOTER: 'FOOTER',
  TAB: 'TAB',
};

const APPLICATION_CONTEXT_KEYS = {
  CURRENT_USER: 'currentUser',
  APPLICATION_VARIABLES: 'applicationVariables',
  SET_APPLICATION_VARIABLES: 'setApplicationVariables',
  ENTITIES_DEF: 'entitiesDef',
  SET_ENTITY_DEF: 'setEntityDef',
  ENTITY_VIEW_CONFIG_BY_NAME_FOR_ENTITIES: 'entityViewConfigByNameForEntities',
  SET_ENTITY_VIEW_CONFIG: 'setEntityViewConfig',
  RECORD_TYPE_VIEW_CONFIG_BY_NAME: 'recordTypeViewConfigByName',
  RECORD_TYPE_VIEW_CONFIG_BY_NAME_FOR_ENTITIES: 'recordTypeViewConfigByNameForEntities',
  SET_RECORD_TYPE_VIEW_CONFIG: 'setRecordTypeViewConfig',
};

export { APPLICATION_SEARCH_PARAM_IDS, COMPONENTS_TYPES, APPLICATION_CONTEXT_KEYS };
