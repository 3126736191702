import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _values from 'lodash/values';

// components
import Modal from '@tekion/tekion-components/molecules/Modal';
import { EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { DEFAULT_SHORTCUTS_INFO_WITH_JSX, SHORTCUTS_INFOS } from './shortcutsInfo.config';

// containers
import withEditor from '../../containers/withEditor';

// events
import { hideShortcutsInfoDialog, shortcutsInfoDialogEvent } from './helpers/shortcutsInfoDialog.event';
import { getShortcutsInfo } from './helpers/shortcutsInfoDialog.helpers';

import { TOOLBAR_GROUPS } from '../../constants/richTextEditor.plugins';

import styles from './shortcutsInfoDialog.module.scss';

const ShortcutsInfoDialog = ({ editor, toolbarGroups }) => {
  const [isShortcutsInfoDialogVisible, setIsShortcutsInfoDialogVisible] = useState(false);

  const handleShowShortcutsInfoDialog = useCallback(() => setIsShortcutsInfoDialogVisible(true), [setIsShortcutsInfoDialogVisible]);
  const handleHideShortcutsInfoDialog = useCallback(() => setIsShortcutsInfoDialogVisible(false), [setIsShortcutsInfoDialogVisible]);
  const handleCancel = useCallback(() => hideShortcutsInfoDialog(editor), [editor]);

  const shortcutsInfos = [...DEFAULT_SHORTCUTS_INFO_WITH_JSX, ...getShortcutsInfo(SHORTCUTS_INFOS, toolbarGroups)];

  useEffect(() => {
    shortcutsInfoDialogEvent.on(`SHOW_SHORTCUTS_INFO_DIALOG_${editor.id}`, handleShowShortcutsInfoDialog);
    shortcutsInfoDialogEvent.on(`HIDE_SHORTCUTS_INFO_DIALOG_${editor.id}`, handleHideShortcutsInfoDialog);
    return () => shortcutsInfoDialogEvent.removeAllListeners();
  }, [editor.id, handleHideShortcutsInfoDialog, handleShowShortcutsInfoDialog]);

  return (
    <Modal
      hideSubmit
      destroyOnClose
      visible={isShortcutsInfoDialogVisible}
      title={__('Shortcuts')}
      secondaryBtnText={__('Close')}
      onCancel={handleCancel}
    >
      <table className={styles.shortcutsInfoTable}>
        <tr>
          <th>{__('Result')}</th>
          <th>{__('Markdown')}</th>
          <th>{__('Shortcut')}</th>
        </tr>
        {_map(shortcutsInfos, (shortcutsInfo) => (
          <tr>
            <td>{tget(shortcutsInfo, 'preview', NO_DATA)}</td>
            <td>{tget(shortcutsInfo, 'markdown', NO_DATA)}</td>
            <td>{tget(shortcutsInfo, 'shortcut', NO_DATA)}</td>
          </tr>
        ))}
      </table>
    </Modal>
  );
};

ShortcutsInfoDialog.propTypes = {
  editor: PropTypes.object,
  toolbarGroups: PropTypes.array,
};

ShortcutsInfoDialog.defaultProps = {
  editor: EMPTY_OBJECT,
  toolbarGroups: _values(TOOLBAR_GROUPS),
};

export default withEditor(ShortcutsInfoDialog);
