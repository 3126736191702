exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".sendEmailsActionDefinitionSection_container__eArgAVyHGV{width:100%}[dir] .sendEmailsActionDefinitionSection_container__eArgAVyHGV{background-color:#fcfcfc;padding:1.6rem;border:.1rem dashed #969aa3;border-radius:.5rem}.sendEmailsActionDefinitionSection_subContainer__4Yq3LoWTLw{display:flex;flex-direction:row;justify-content:space-between}.sendEmailsActionDefinitionSection_derivedEntity__fj666wa62a{width:50%}.sendEmailsActionDefinitionSection_customSelect__ptuf8ANfyf{width:100%}[dir] .sendEmailsActionDefinitionSection_customSelect__ptuf8ANfyf .ant-select-selection__choice{padding-top:2px}.sendEmailsActionDefinitionSection_customSelect__ptuf8ANfyf .ant-select-selection__choice__remove{top:-1px}[dir=ltr] .sendEmailsActionDefinitionSection_customSelect__ptuf8ANfyf .ant-select-selection__choice__remove{right:-1px}[dir=rtl] .sendEmailsActionDefinitionSection_customSelect__ptuf8ANfyf .ant-select-selection__choice__remove{left:-1px}", ""]);

// Exports
exports.locals = {
	"container": "sendEmailsActionDefinitionSection_container__eArgAVyHGV",
	"subContainer": "sendEmailsActionDefinitionSection_subContainer__4Yq3LoWTLw",
	"derivedEntity": "sendEmailsActionDefinitionSection_derivedEntity__fj666wa62a",
	"customSelect": "sendEmailsActionDefinitionSection_customSelect__ptuf8ANfyf"
};