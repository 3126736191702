exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dataImportDetailPage_loadingContainer__dEo8mbub7z{height:100%;display:flex;justify-content:center;align-items:center}.dataImportDetailPage_headerNavigationContainer__23beD1bToQ{display:flex;flex-flow:row;font-family:\"Proxima-Nova-Regular\"}.dataImportDetailPage_subHeaderContent__myq2rPMfGW{font-size:2rem;font-weight:bold}.dataImportDetailPage_pageBody__qJERbLYKHJ{display:flex;width:100%;flex-direction:column;justify-content:center;align-items:center}.dataImportDetailPage_pageBody__qJERbLYKHJ .dataImportDetailPage_tableContainer__uCaZBpPSTf{width:98%}[dir] .dataImportDetailPage_pageBody__qJERbLYKHJ .dataImportDetailPage_tableContainer__uCaZBpPSTf{margin:.8rem;border:.1rem solid #d4d5d6}", ""]);

// Exports
exports.locals = {
	"loadingContainer": "dataImportDetailPage_loadingContainer__dEo8mbub7z",
	"headerNavigationContainer": "dataImportDetailPage_headerNavigationContainer__23beD1bToQ",
	"subHeaderContent": "dataImportDetailPage_subHeaderContent__myq2rPMfGW",
	"pageBody": "dataImportDetailPage_pageBody__qJERbLYKHJ",
	"tableContainer": "dataImportDetailPage_tableContainer__uCaZBpPSTf"
};