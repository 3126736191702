const ACTION_TYPES = {
  ON_MODAL_SAVE: 'ON_MODAL_SAVE',
  ON_MODAL_CANCEL: 'ON_MODAL_CANCEL',
  INIT: 'INIT',
  ADD_FIELD: 'ADD_FIELD',
  DELETE_FIELD: 'DELETE_FIELD',
  ON_TOGGLE_SWITCH: 'ON_TOGGLE_SWITCH',
  ON_ASYNC_LOADED_OPTIONS: 'ON_ASYNC_LOADED_OPTIONS',
};

const FIELD_IDS = {
  DISPLAY_NAME: 'displayName',
  TEMPLATE: 'template',
  ENTITY_FIELDS: 'fieldNames',
  CELL_VIEW_NAME: 'cellViewName',
  BUTTON: 'button',
  CELL_VIEW: 'cellView',
  ENTITY_NAME: 'entityName',
};
const RADIO_BUTTON_OPTIONS = [
  { label: __('Template'), value: FIELD_IDS.TEMPLATE },
  { label: __('Cell View'), value: FIELD_IDS.CELL_VIEW },
];
const FORM_VIEW_TYPE_FIELD_IDS = {
  SELECTED_VIEW_NAME: 'selectedViewName',
};

const MODAL_TYPE = {
  NORMAL_FIELD_COMPOUND: 'NORMAL_FIELD_COMPOUND',
  RELATIONSHIP_FIELD_COMPOUND: 'RELATIONSHIP_FIELD_COMPOUND',
  COMPLEX_FIELD_COMPOUND: 'COMPLEX_FIELD_COMPOUND',
  COMPLEX_FIELD_VIEW_SELECT: 'COMPLEX_FIELD_VIEW_SELECT',
};

const ROWS = 20;

const DEFINED_VARIABLES = {
  HELPER1: 'message-1',
  HELPER2: 'message-2',
  HELPER3: 'message-3',
};
const HELPER_TEXT_VALUE_FIELD = {
  [DEFINED_VARIABLES.HELPER1]: __('1) Click add button to select field from dropdown menu.'),
  [DEFINED_VARIABLES.HELPER2]: __('2) Provide the template  based on the index of the fields used.'),
  [DEFINED_VARIABLES.HELPER3]: __('3) e.g  {1}-{2} : internally , it will be used as <value_of_field_at_index 1> - <value_of_field_at_index_2>'),
};

export { ACTION_TYPES, FIELD_IDS, FORM_VIEW_TYPE_FIELD_IDS, MODAL_TYPE, ROWS, RADIO_BUTTON_OPTIONS, HELPER_TEXT_VALUE_FIELD, DEFINED_VARIABLES };
