import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import FilterCard from '../../atoms/filterCard';

import { getCountByAccessType, getIconClassNameForFilterCard } from './accessTypeFilter.helpers';
import { ACCESS_TYPE_FILTERS, ALL_ACCESS_ACCESS_FILTER } from './accessTypeFilter.constants';

import styles from './accessTypeFilter.module.scss';

const AccessTypeFilter = ({ selectedFilter, shareAndManageRecordUserDetails, onFilterChange }) => {
  const countByAccessType = useMemo(() => getCountByAccessType(shareAndManageRecordUserDetails), [shareAndManageRecordUserDetails]);

  return (
    <div className={styles.container}>
      {_map(ACCESS_TYPE_FILTERS, ({ id, name, iconName }, index) => (
        <FilterCard
          key={id}
          id={id}
          className={cx({ [styles.filterClassName]: index >= 1, [styles.selectedFilterClassName]: selectedFilter === id })}
          iconName={iconName}
          iconClassName={getIconClassNameForFilterCard(id)}
          name={name}
          count={countByAccessType[id]}
          onClick={onFilterChange}
        />
      ))}
    </div>
  );
};

AccessTypeFilter.propTypes = {
  shareAndManageRecordUserDetails: PropTypes.array,
  selectedFilter: PropTypes.string,
  onFilterChange: PropTypes.func,
};

AccessTypeFilter.defaultProps = {
  shareAndManageRecordUserDetails: EMPTY_ARRAY,
  selectedFilter: ALL_ACCESS_ACCESS_FILTER.id,
  onFilterChange: _noop,
};

export default AccessTypeFilter;
