import _intersectionBy from 'lodash/intersectionBy';
import _intersection from 'lodash/intersection';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _isFunction from 'lodash/isFunction';

import { tget } from '@tekion/tekion-base/utils/general';
import addOptionsToAdditional from '@tekion/tekion-base/utils/addOptionsToAdditional';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { APPROVAL_CENTRE_FIELD_IDS, APPROVAL_STATUS_TYPES, APPROVAL_METADATA_PROPERTY_IDS } from '../../../../constants/approvalCentre.constants';
import { STATUS_FILTER, GROUP_FILTER, CATEGORY_FILTER, DEFAULT_FILTERS_TYPES } from '../constants/approvalRequestsList.filterTypes';
import COLUMN_IDS from '../constants/approvalRequestsList.columnIds';
import { CANCEL_ROW_ACTION, EDIT_ROW_ACTION } from '../constants/approvalRequestsList.constants';

// Readers
import localStorageReader from '../../../../readers/localStorage.reader';
import userReader from '../../../../readers/loginResponse.reader';

const getRowActions = (rowData) => {
  const status = tget(rowData, COLUMN_IDS.STATUS);
  if (status === APPROVAL_STATUS_TYPES.PENDING) {
    return [EDIT_ROW_ACTION, CANCEL_ROW_ACTION];
  }

  return [];
};

const getOnRowClick = (onRowClick) => (_isFunction(onRowClick) ? { onRowClick } : EMPTY_OBJECT);

const createTableProps = (isLoading, currentPage, pageSize, onAction, onRowClick) => ({
  showPagination: true,
  loading: isLoading,
  currentPage: currentPage + 1,
  minRows: 0,
  pageSize,
  resultsPerPage: pageSize,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  getRowActions,
  onRowActionClick: _isFunction(onRowClick) ? onRowClick : (type, payload) => onAction({ type, payload }),
  ...getOnRowClick(onRowClick),
});

const createFilterProps = (filterMetadata, applicationProperties, onAction) => {
  const filtersToShow = tget(applicationProperties, APPROVAL_METADATA_PROPERTY_IDS.FILTERS_TO_SHOW);
  const filterTypes = [
    STATUS_FILTER,
    addOptionsToAdditional(GROUP_FILTER, filterMetadata.GROUPS),
    addOptionsToAdditional(CATEGORY_FILTER, filterMetadata.CATEGORIES),
  ];

  const filtersToShowAsObjectArray = filtersToShow === undefined ? filterTypes : _map(filtersToShow, (filterToShow) => ({ id: filterToShow }));

  return {
    showResultsCount: false,
    defaultFilterTypes: filtersToShow === undefined ? DEFAULT_FILTERS_TYPES : _intersection(DEFAULT_FILTERS_TYPES, filtersToShow),
    filterTypes: _intersectionBy(filterTypes, filtersToShowAsObjectArray, 'id'),
    onAction,
  };
};

const getCurrentUserFilter = (currentLoggedInUserData) => {
  let currentUserId;

  if (!_isEmpty(currentLoggedInUserData)) {
    currentUserId = tget(currentLoggedInUserData, 'id');
  } else {
    const currentUser = localStorageReader.userInfo();
    currentUserId = userReader.userID(currentUser);
  }

  const currentUserFilter = {
    field: COLUMN_IDS.CREATED_BY,
    values: [currentUserId],
    filterType: OPERATORS.IN,
  };

  return currentUserFilter;
};

const setFiltersInPayload = (payload, applicationProperties, selectedFilters, searchText, currentLoggedInUserData) => {
  const groupsEnabled = tget(applicationProperties, APPROVAL_METADATA_PROPERTY_IDS.GROUPS_ENABLED, null);
  const categoriesEnabled = tget(applicationProperties, APPROVAL_METADATA_PROPERTY_IDS.CATEGORIES_ENABLED, null);
  const entitiesEnabled = tget(applicationProperties, APPROVAL_METADATA_PROPERTY_IDS.ENTITIES_ENABLED, null);

  const currentUserFilter = getCurrentUserFilter(currentLoggedInUserData);
  const filters = _map(
    _filter(selectedFilters, (filter) => !_isEmpty(_get(filter, 'values'))),
    (filter) => ({
      field: _get(filter, 'type', EMPTY_STRING),
      values: _get(filter, 'values', EMPTY_ARRAY),
      filterType: _get(filter, 'operator', EMPTY_STRING),
    }),
  );

  if (!_isEmpty(searchText)) {
    filters.push({
      field: COLUMN_IDS.NAME,
      filterType: 'TEXT_CONTAINS',
      values: [searchText],
    });
  }

  if (!_isEmpty(groupsEnabled)) {
    filters.push({
      field: COLUMN_IDS.GROUP,
      values: _compact(groupsEnabled),
      filterType: OPERATORS.IN,
    });
  }

  if (!_isEmpty(categoriesEnabled)) {
    filters.push({
      field: COLUMN_IDS.CATEGORY,
      values: _compact(categoriesEnabled),
      filterType: OPERATORS.IN,
    });
  }

  if (!_isEmpty(entitiesEnabled)) {
    filters.push({
      field: APPROVAL_CENTRE_FIELD_IDS.TYPE,
      values: _compact(entitiesEnabled),
      filterType: OPERATORS.IN,
    });
  }

  const DEFAULT_INACTIVE_STATUS_FILTER = {
    field: COLUMN_IDS.STATUS,
    filterType: OPERATORS.NIN,
    values: [APPROVAL_STATUS_TYPES.INACTIVE],
  };

  _set(payload, 'filters', [...filters, currentUserFilter, DEFAULT_INACTIVE_STATUS_FILTER]);
  return filters;
};

const getPayload = ({ applicationProperties, currentPage, currentPageToken, pageSize, selectedFilters, searchText, currentLoggedInUserData }) => {
  const payload = { rows: pageSize, start: currentPage * pageSize, nextPageToken: currentPageToken };

  setFiltersInPayload(payload, applicationProperties, selectedFilters, searchText, currentLoggedInUserData);

  return payload;
};

export { createTableProps, createFilterProps, getPayload };
