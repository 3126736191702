import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';

import { REQUEST_DETAILS_FIELD_IDS } from '../../calloutModal.constants';

const checkLastRowFilled = (value) => {
  const lastIndex = _size(value) - 1;
  return _size(value) > 0 && _isEmpty(tget(value, [lastIndex, REQUEST_DETAILS_FIELD_IDS.REQUEST_KEY]));
};

export { checkLastRowFilled };
