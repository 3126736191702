import React from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';

const CancelApprovalRequestConfirmModal = ({ isVisible, isCancellingRequest, onCancelConfirm, onCancelCancel }) => (
  <ConfirmationDialog
    loading={isCancellingRequest}
    isVisible={isVisible}
    title={__('Cancel Approval Request')}
    content={__('Are you sure you want to cancel approval request. Once cancelled it cannot be changed.')}
    onSubmit={onCancelConfirm}
    onCancel={onCancelCancel}
  />
);

CancelApprovalRequestConfirmModal.propTypes = {
  isVisible: PropTypes.bool,
  isCancellingRequest: PropTypes.bool,
  onCancelConfirm: PropTypes.func,
  onCancelCancel: PropTypes.func,
};

CancelApprovalRequestConfirmModal.defaultProps = {
  isVisible: false,
  isCancellingRequest: false,
  onCancelConfirm: _noop,
  onCancelCancel: _noop,
};

export default CancelApprovalRequestConfirmModal;
