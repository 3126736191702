import _forEach from 'lodash/forEach';
import _set from 'lodash/set';

import { tget } from '@tekion/tekion-base/utils/general';

const getSelectedApproversDetail = (selectedApproverOptions) => {
  const selectedApproversDetailByApproverType = {};

  _forEach(selectedApproverOptions, (approverOption) => {
    const approverType = tget(approverOption, 'type');
    const prevSelectedApproversDetailByApproverType = tget(selectedApproversDetailByApproverType, approverType, []);
    _set(selectedApproversDetailByApproverType, approverType, [...prevSelectedApproversDetailByApproverType, approverOption]);
  });

  return selectedApproversDetailByApproverType;
};

export { getSelectedApproversDetail };
