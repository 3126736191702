import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import { BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS } from '../constants/buttonPropertiesConfigure.fieldIds';
import { BUTTON_VIEW_TYPE_OPTIONS } from '../constants/buttonPropertiesConfigure.general.constants';

const BUTTON_VIEW_TYPE_FIELD = {
  id: BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS.BUTTON_VIEW_TYPE,
  renderer: SelectInput,
  renderOptions: {
    required: true,
    label: __('Button View Type'),
    validators: [isRequiredRule],
    placeholder: __('Select Button View Type'),
    options: BUTTON_VIEW_TYPE_OPTIONS,
  },
};
const BUTTON_LABEL_FIELD = {
  id: BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS.BUTTON_LABEL,
  renderer: TextInput,
  renderOptions: {
    label: __('Button label'),
  },
};
const BUTTON_ICON_FIELD = {
  id: BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS.BUTTON_ICON,
  renderer: TextInput,
  renderOptions: {
    label: __('Button Icon'),
  },
};

const BUTTON_PROPERTIES_CONFIGURE_FIELDS = {
  [BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS.BUTTON_ICON]: BUTTON_ICON_FIELD,
  [BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS.BUTTON_LABEL]: BUTTON_LABEL_FIELD,
  [BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS.BUTTON_VIEW_TYPE]: BUTTON_VIEW_TYPE_FIELD,
};

export { BUTTON_PROPERTIES_CONFIGURE_FIELDS };
