const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

const INITIAL_STATE_PERMISSION_LIST_PROPS = {
  currentPage: 0,
  pageSize: 50,
};

const TEXT_STARTS_WITH = 'TEXT_STARTS_WITH';

const DELETE_PERMISSION_CONFIRMATION_CONTENT = __('Are you sure you want to delete permission set? It will be permanently deleted if you click yes.');

export { TEXT_STARTS_WITH, TABLE_CONSTANTS, INITIAL_STATE_PERMISSION_LIST_PROPS, DELETE_PERMISSION_CONFIRMATION_CONTENT };
