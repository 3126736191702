const COLUMNS = {
  DISPLAY_NAME: 'displayName',
  DESCRIPTION: 'description',
  NAME: 'name',
  SEARCH_ENABLED: 'searchEnabled',
  LIST_VIEW_ENABLED: 'listViewEnabled',
  CREATE_ENABLED: 'createViewEnabled',
  EDIT_ENABLED: 'editEnabled',
  DETAIL_VIEW_ENABLED: 'detailViewEnabled',
  USER_API_ENABLED: 'userApiEnabled',
  AUDIT_ENABLED: 'auditEnabled',
  COMPLEX_FIELD: 'complexField',
};

export default COLUMNS;
