import _get from 'lodash/get';
import _map from 'lodash/map';
import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _castArray from 'lodash/castArray';

import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';

import mediaServices from '../services/mediaServices';
import ResponseHeaderReader from '../readers/responseHeader.reader';
import MediaReader from '../readers/media.reader';

const getPreSignedUrls = async (payload) => {
  try {
    const response = await mediaServices.getPreSignedUrls(payload);
    return getDataFromResponse(response);
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err));
    return EMPTY_OBJECT;
  }
};

const initiateUpload = async (media, failureCb) => {
  try {
    const payload = {
      fileName: _get(media, 'name'),
      mimeType: _get(media, 'file.type'),
    };

    const assetType = _get(media, 'assetType');
    if (!_isEmpty(assetType)) {
      _set(payload, 'assetType', assetType);
    }

    const apiResponse = await mediaServices.initiateUpload(payload);

    return getDataFromResponse(apiResponse);
  } catch (err) {
    const errorMessage = getErrorMessage(err);

    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err));
    failureCb({ media, errorMessage });

    return EMPTY_OBJECT;
  }
};

const uploadFileAtS3Url = async (objectURL, media, onProgressUpdate, failureCb) => {
  try {
    const file = _get(media, 'file');
    const fileType = _get(file, 'type');

    const apiResponse = mediaServices.uploadFileAtS3Url(objectURL, file, fileType, onProgressUpdate);

    return apiResponse;
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err));
    failureCb({ media });

    return EMPTY_OBJECT;
  }
};

const uploadFile = async (media, progressCb = _noop, successCb = _noop, failureCb = _noop) => {
  progressCb({ media, progress: 0 });

  const onProgressUpdate = (progressEvent) => {
    const progress = (progressEvent.loaded / progressEvent.total) * 100;
    progressCb({ media, progress });
  };

  const initiateUploadResponse = await initiateUpload(media, failureCb);
  const objectURL = _get(initiateUploadResponse, 'objectURL');
  const mediaId = _get(initiateUploadResponse, 'mediaId');

  const uploadFileResponse = await uploadFileAtS3Url(objectURL, media, onProgressUpdate, failureCb);
  const status = _get(uploadFileResponse, 'status');
  if (status === 200 && mediaId) {
    successCb({ media, response: initiateUploadResponse });
  } else {
    failureCb({ media, errorMessage: EMPTY_STRING });
  }
};

const uploadMediaList = async (mediaList, onProgressUpdateCb, successCb, failureCb) => {
  const mediaUploadPromises = _map(
    mediaList,
    (selectedFile) =>
      new Promise(() => {
        uploadFile(selectedFile, onProgressUpdateCb, successCb, failureCb);
      }),
  );

  await Promise.all(mediaUploadPromises);
};

const getPreSignedUrlForCustomEntity = async (entityName, fieldNames, recordId) => {
  try {
    const payload = {
      entityName,
      fieldNames: _castArray(fieldNames),
      recordId,
    };

    const response = await mediaServices.getPreSignedUrlForCustomEntity(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get media, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const downloadMediaForCustomEntity = async ({ entityName, fieldName, recordId, mediaId }) => {
  try {
    const payload = {
      entityName,
      fieldName,
      recordId,
      mediaId,
    };

    const response = await mediaServices.downloadMediaForCustomEntity(payload);
    const { data } = response;

    const responseHeader = response.headers;
    const contentType = ResponseHeaderReader.contentType(responseHeader);
    const blobObject = new Blob([data], { type: contentType });

    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blobObject);

    // Extracting filename from the Content-Disposition header
    const contentDisposition = ResponseHeaderReader.contentDisposition(responseHeader);
    const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.*)"/);
    const fileName = fileNameMatch ? fileNameMatch[1] : 'downloaded-file';

    downloadLink.download = fileName;

    // Append the link to the body and invoking click to trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Remove the link from the DOM
    document.body.removeChild(downloadLink);

    return response;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to download media, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const bulkDownloadCustomEntityMedia = async ({ entityName, fieldName, recordId, mediaIds }) => {
  if (!_isEmpty(recordId)) {
    toaster(TOASTER_TYPE.INFO, __('We are in process of downloading file it might take some time!'));

    const promises = _map(mediaIds, (mediaId) => downloadMediaForCustomEntity({ entityName, fieldName, recordId, mediaId }));

    const responses = await Promise.all(promises);

    return responses;
  }

  return EMPTY_ARRAY;
};

const getMediaListWithSignedUrls = async (mediaList, mediaIdsToBeResolved, entityName, fieldNames, recordId) => {
  if (!_isEmpty(mediaIdsToBeResolved) && !_isEmpty(recordId)) {
    const preSignedUrlResponse = await getPreSignedUrlForCustomEntity(entityName, fieldNames, recordId);
    const preSignedUrlsByMediaId = _reduce(
      preSignedUrlResponse,
      (result, obj, index) => {
        const mediaId = mediaIdsToBeResolved[index];
        const url = MediaReader.url(obj);
        const mimeType = MediaReader.mimeType(obj);
        const fileName = MediaReader.fileName(obj) || EMPTY_STRING;

        return {
          ...result,
          [mediaId]: { url, mimeType, fileName },
        };
      },
      {},
    );
    const mediaListWithUrl = _map(mediaList, (media) => ({
      ...media,
      url: _get(preSignedUrlsByMediaId, `${_get(media, 'mediaId')}.url`),
      mimeType: _get(preSignedUrlsByMediaId, `${_get(media, 'mediaId')}.mimeType`),
      contentType: _get(preSignedUrlsByMediaId, `${_get(media, 'mediaId')}.mimeType`),
      name: _get(preSignedUrlsByMediaId, `${_get(media, 'mediaId')}.fileName`),
    }));

    return { mediaListWithUrl, preSignedUrlsByMediaId };
  }

  return { mediaListWithUrl: mediaList, preSignedUrlsByMediaId: EMPTY_OBJECT };
};

export { uploadMediaList, getMediaListWithSignedUrls, getPreSignedUrls, bulkDownloadCustomEntityMedia };
