import React from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import DropDown, { DROPDOWN_PLACEMENT, DROPDOWN_TRIGGER } from '@tekion/tekion-components/molecules/DropDown';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

import Menu from '../../atoms/menu';

import useActionRenderer from './hooks/useActionRenderer';

import styles from './actionRenderer.module.scss';

const DROPDOWN_TRIGGER_TYPE = [DROPDOWN_TRIGGER.CLICK];

const ActionRenderer = ({ className, actionModalContextId, entityDef, entityRecord, actionBuilderProps, customActionsData }) => {
  const { isDataLoading, options, handleDropDownClick } = useActionRenderer({
    entityDef,
    entityRecord,
    actionBuilderProps,
    customActionsData,
    actionModalContextId,
  });

  const renderActionMenu = () => <Menu isLoading={isDataLoading} menuOptions={options} />;

  return (
    <DropDown
      className={` ${styles.dropDownContainer} ${className}`}
      onClick={handleDropDownClick}
      overlay={renderActionMenu}
      placement={DROPDOWN_PLACEMENT.BOTTOM_RIGHT}
      trigger={DROPDOWN_TRIGGER_TYPE}
    >
      <FontIcon data-disable-sort-start>icon-overflow</FontIcon>
    </DropDown>
  );
};

ActionRenderer.propTypes = {
  className: PropTypes.string,
  actionModalContextId: PropTypes.string.isRequired,
  entityDef: PropTypes.object.isRequired,
  entityRecord: PropTypes.object.isRequired,
  actionBuilderProps: PropTypes.object,
  customActionsData: PropTypes.array,
};

ActionRenderer.defaultProps = {
  className: '',
  actionBuilderProps: EMPTY_OBJECT,
  customActionsData: EMPTY_ARRAY,
};

export default ActionRenderer;
