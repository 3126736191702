import _includes from 'lodash/includes';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _set from 'lodash/set';
import _isNil from 'lodash/isNil';

import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import FIELD_TYPES from '../../../../../../../../../constants/fieldDefinition.fieldTypes';
import SYSTEM_DEFINED_FIELDS from '../../../../../../../../../constants/systemDefinedFields';
import { VIEW_CONFIGURATION_FIELD_IDS, VIEW_TYPES } from '../../../../../../../../../constants/viewBuilder.constants';

import fieldDefinitionReader from '../../../../../../../../../readers/fieldDefinition.reader';
import entityDefinitionReader from '../../../../../../../../../readers/entity.reader';

const generateDetailViewDefsPayload = (entityName) => ({
  filters: [
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      values: [entityName],
      filterType: OPERATORS.IN,
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
      values: [VIEW_TYPES.DETAIL_VIEW],
      filterType: OPERATORS.IN,
    },
  ],
  rows: 1,
});

const getResolvedEntityRecord = (entityDef, entityRecord, record) => {
  const fields = entityDefinitionReader.fieldDefinitions(entityDef);
  const lookupFields = _filter(fields, (field) => {
    const fieldType = fieldDefinitionReader.fieldType(field);
    return fieldType === FIELD_TYPES.RELATIONSHIP;
  });

  let resolvedEntityRecord = { ...record };

  _forEach(lookupFields, (lookupField) => {
    const fieldName = fieldDefinitionReader.name(lookupField);
    const lookupDisplayField = fieldDefinitionReader.lookupFieldDisplayField(lookupField);
    const lookupKeyForRecord = `${fieldName}.${lookupDisplayField}`;
    const isFieldSystemDefined = _includes(SYSTEM_DEFINED_FIELDS, fieldName);

    let lookupValue = isFieldSystemDefined ? tget(resolvedEntityRecord, fieldName) : tget(resolvedEntityRecord, `entity.${fieldName}`);

    if (_isNil(lookupValue)) {
      lookupValue = isFieldSystemDefined ? tget(entityRecord, lookupKeyForRecord) : tget(entityRecord, `entity.${lookupKeyForRecord}`);
    }

    if (!_isNil(lookupValue)) {
      // Need to do following as with _set, the lookupKeyForRecord path gets broken down at dot and converts to object
      if (isFieldSystemDefined) {
        resolvedEntityRecord[lookupKeyForRecord] = lookupValue;
      } else {
        resolvedEntityRecord.entity[lookupKeyForRecord] = lookupValue;
      }
    }
  });

  resolvedEntityRecord = { ...entityRecord, ...resolvedEntityRecord };
  const entityRecordData = tget(entityRecord, 'entity', {});
  const requestedRecordData = tget(resolvedEntityRecord, 'entity', {});
  _set(resolvedEntityRecord, 'entity', { ...entityRecordData, ...requestedRecordData });

  return resolvedEntityRecord;
};

export { generateDetailViewDefsPayload, getResolvedEntityRecord };
