import ROUTES from '../../../../../constants/routes';

const FIELD_IDS = {
  COMPONENTS: 'components',
};

const NAVIGATION_TYPES = {
  TOP: 'top',
  LEFT: 'left',
};

const COMPONENT_KEYS = {
  CHILDREN: 'children',
  COMPONENT_ID: 'componentId',
  TYPE: 'type',
  PROPERTIES: 'properties',
};

const PROPERTIES_FIELD_IDS = {
  TAB_DISPLAY_NAME: 'tabDisplayName',
  TAB_NAME: 'tabName',
  TAB_ICON: 'tabIcon',
  PAGE_NAME: 'pageName',
  ENTITIES_RENDERING_INFO: 'entitiesRenderingInfo',
  IS_NAVIGATION_REQUIRED: 'isNavigationRequired',
  NAVIGATION_TYPE: 'navigationType',
  APPROVAL_INTEGRATION: 'approvalIntegration',
  NAVIGATION_PROPERTIES: 'navigationProperties',
  APPROVAL_META_DATA: 'approvalMetadata',
  DISPLAY_NAME: 'displayName',
};

const COMPONENT_IDS = {
  TAB_PROPERTIES: 'tabProperties',
  NAVIGATION_PROPERTIES: 'navigationProperties',
  HEADER: 'header',
  FOOTER: 'footer',
};

const DIRECTION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

const ADD_TAB = 'addTab';

const VIEWS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const TAB_ONE = 'Tab One';

const TAB_IDS = {
  APPROVAL: 'Approval',
};

const APPLICATION_TOP_NAVIGATION_DEFAULT_DATA = [{ label: __('Apps'), goTo: ROUTES.APPLICATION_LIST_ROUTE, key: '1' }];

const DIALOG_TEXT = {
  CANCEL_TEXT: __('Are you sure you want to cancel all the changes made by you? All data will be lost if you click yes.'),
  DELETE_TAB: __('Are you sure you want to delete this tab from application? All data for this tab will be lost if you click yes.'),
};

const TITLE_TEXT = {
  CANCEL_TITLE_TEXT: __('Cancel Changes'),
  DELETE_TITLE_TEXT: __('Delete Tab'),
};

export {
  DIRECTION,
  ADD_TAB,
  VIEWS,
  TAB_ONE,
  APPLICATION_TOP_NAVIGATION_DEFAULT_DATA,
  DIALOG_TEXT,
  TITLE_TEXT,
  FIELD_IDS,
  COMPONENT_IDS,
  TAB_IDS,
  PROPERTIES_FIELD_IDS,
  COMPONENT_KEYS,
  NAVIGATION_TYPES,
};
