import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';

// Constants
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import { TEXT_STARTS_WITH } from '../../../../../../../constants/filter.constants';
import COLUMN_IDS from '../constants/approvalGroupList.columnIds';

const createTableProps = (isLoading, currentPage, pageSize) => ({
  showPagination: true,
  loading: isLoading,
  minRows: 0,
  rowHeight: 35,
  pageSize,
  resultsPerPage: pageSize,
  currentPage: currentPage + 1,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
});

const getPayload = ({ currentPage, currentPageToken, pageSize, searchText }) => {
  const payload = { rows: pageSize, start: currentPage * pageSize, nextPageToken: currentPageToken };
  const filters = [];

  if (!_isEmpty(searchText)) {
    filters.push({
      field: COLUMN_IDS.GROUP,
      values: [searchText],
      filterType: TEXT_STARTS_WITH,
    });
  }

  if (!_isEmpty(filters)) {
    _set(payload, 'filters', filters);
  }

  return payload;
};

export { createTableProps, getPayload };
