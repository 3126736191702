const ACTION_TYPES = {
  ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE: 'ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE',
  DELETE_ROW: 'DELETE_ROW',
  ON_CLICK_CONFIGURE_EVENT: 'ON_CLICK_CONFIGURE_EVENT',
  ON_CLICK_ADD_EVENT: 'ON_CLICK_ADD_EVENT',
  ON_SAVE_MODAL: 'ON_SAVE_MODAL',
  ON_CANCEL_MODAL: 'ON_CANCEL_MODAL',
  ON_CHANGE_MODULE: 'ON_CHANGE_MODULE',
};

export default ACTION_TYPES;
