import { withProps } from 'recompose';

import _map from 'lodash/map';
import _get from 'lodash/get';

import { hasNumberRule, isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';
import Checkbox from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import MultiSelect from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/MultiSelectField';

import ExpandableRowConfigForm from '../expandableRowConfigForm';
import FilterForm from '../filterForm';
import SortOptionsConfigurator from '../sortOptionsConfigurator';
import CustomActionsConfigurator from '../customActionsConfigurator';
import SearchOptionsConfigurator from '../searchOptionsConfigurator';
import EventHandlerConfigurator from '../eventHandlerConfigurator';
import CustomStylesConfigurator from '../customStylesConfigurator';

import { percentageValidationRule } from '../../../../utils/formValidators';
import { isExpandableConfigFormValidated } from '../expandableRowConfigForm/expandableRowConfigForm.helper';

import { FIELD_IDS, TABLE_PAGE_SIZE_OPTIONS, DOWNLOAD_OPTIONS } from './listViewRendererPropertiesForm.constants';

const mapPropsForCheckbox = ({ value, defaultValue, ...restProps }) => ({
  ...restProps,
  value: value === null || value === undefined ? defaultValue : value,
});

const mapPropsForDownload = ({ value, ...restProps }) => {
  const newValue = _map(value, ({ downloadType }) => downloadType);
  return { ...restProps, value: newValue };
};

const TITLE_FIELD = {
  id: FIELD_IDS.TITLE,
  renderer: TextInput,
  renderOptions: {
    label: __('Title'),
    defaultValue: 'Section',
  },
};

const WIDTH_FIELD = {
  id: FIELD_IDS.WIDTH,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Width(%)'),
    defaultValue: 100,
    min: 0,
    max: 100,
    validators: [isRequiredRule, percentageValidationRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const TABLE_SHOW_HEADER_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_HEADER,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show header'),
    validators: [isRequiredRule],
  },
};

const TABLE_SHOW_SUB_HEADER_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_SUB_HEADER,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show sub header'),
    validators: [isRequiredRule],
  },
};

const TABLE_SHOW_REFRESH_ICON_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_REFRESH_ICON,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show refresh icon'),
    validators: [isRequiredRule],
  },
};

const TABLE_SHOW_MULTI_SORT_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_MULTI_SORT,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show Multi sort'),
    validators: [isRequiredRule],
  },
};

const TABLE_SHOW_FILTER_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_FILTER,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show filter'),
    validators: [isRequiredRule],
  },
};

const TABLE_SHOW_SEARCH_BY_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_SEARCH_BY_FIELD,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show search by field'),
    validators: [isRequiredRule],
  },
};

const EXPANDABLE_ROW_CONFIG_FORM_FIELD = {
  id: FIELD_IDS.EXPANDABLE_ROW_CONFIG_FORM,
  renderer: ExpandableRowConfigForm,
  renderOptions: {
    validators: [isExpandableConfigFormValidated],
  },
};

const TABLE_MIN_ROWS_FIELD = {
  id: FIELD_IDS.TABLE_MIN_ROWS,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Minimum rows of table'),
    defaultValue: 0,
    min: 0,
    validators: [isRequiredRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const TABLE_ROW_HEIGHT_FIELD = {
  id: FIELD_IDS.TABLE_ROW_HEIGHT,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Row height of table'),
    defaultValue: 50,
    min: 1,
    validators: [isRequiredRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const TABLE_CURRENT_PAGE_FIELD = {
  id: FIELD_IDS.TABLE_CURRENT_PAGE,
  renderer: NumberInputField,
  renderOptions: {
    label: __('current of table'),
    defaultValue: 1,
    min: 1,
    validators: [isRequiredRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const TABLE_RESULT_PER_PAGE_FIELD = {
  id: FIELD_IDS.TABLE_RESULT_PER_PAGE,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Result per page'),
    defaultValue: 10,
    min: 1,
    validators: [isRequiredRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const TABLE_PAGE_SIZE_FIELD = {
  id: FIELD_IDS.TABLE_PAGE_SIZE,
  renderer: NumberInputField,
  renderOptions: {
    label: __('Page Size of table'),
    defaultValue: 10,
    min: 1,
    validators: [isRequiredRule, hasNumberRule],
    triggerChangeOnBlur: false,
  },
};

const TABLE_FIXED_PAGINATION_FIELD = {
  id: FIELD_IDS.TABLE_FIXED_PAGINATION,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Fixed pagination'),
    validators: [isRequiredRule],
  },
};

const TABLE_SHOW_PAGINATION_FIELD = {
  id: FIELD_IDS.TABLE_SHOW_PAGINATION,
  renderer: withProps(mapPropsForCheckbox)(Checkbox),
  renderOptions: {
    label: '',
    defaultValue: true,
    checkboxLabel: __('Show Pagination'),
    validators: [isRequiredRule],
  },
};

const TABLE_PAGE_SIZE_OPTIONS_FIELD = {
  id: FIELD_IDS.TABLE_PAGE_SIZE_OPTIONS,
  renderer: Select,
  renderOptions: {
    label: __('Page Size options'),
    isMulti: true,
    closeMenuOnSelect: false,
    defaultValue: [10, 20, 50],
    options: TABLE_PAGE_SIZE_OPTIONS,
  },
};

const DOWNLOAD_OPTIONS_FIELD = {
  id: FIELD_IDS.DOWNLOAD_OPTIONS,
  renderer: withProps(mapPropsForDownload)(MultiSelect),
  renderOptions: {
    label: __('Download options'),
    isMulti: true,
    closeMenuOnSelect: false,
    defaultValue: [],
    options: DOWNLOAD_OPTIONS,
  },
};

const FILTER_COMPONENT_FIELD = {
  id: FIELD_IDS.FILTER_COMPONENT,
  renderer: FilterForm,
};

const SORT_DETAILS_FIELD = {
  id: FIELD_IDS.SORT_DETAILS,
  renderer: SortOptionsConfigurator,
};

const ACTIONS_TO_SHOW_FIELD = {
  id: FIELD_IDS.ACTIONS_TO_SHOW,
  renderer: CustomActionsConfigurator,
};

const SEARCH_OPTIONS_FIELD = {
  id: FIELD_IDS.SEARCH_OPTIONS,
  renderer: SearchOptionsConfigurator,
};

const EVENT_HANDLER_FIELD = {
  id: FIELD_IDS.EVENT_HANDLERS,
  renderer: EventHandlerConfigurator,
};

const CUSTOM_STYLE_FIELD = {
  id: FIELD_IDS.CUSTOM_STYLES,
  renderer: CustomStylesConfigurator,
};

const FORM_FIELDS = {
  [FIELD_IDS.TITLE]: TITLE_FIELD,
  [FIELD_IDS.WIDTH]: WIDTH_FIELD,
  [FIELD_IDS.TABLE_SHOW_HEADER]: TABLE_SHOW_HEADER_FIELD,
  [FIELD_IDS.TABLE_SHOW_SUB_HEADER]: TABLE_SHOW_SUB_HEADER_FIELD,
  [FIELD_IDS.TABLE_SHOW_REFRESH_ICON]: TABLE_SHOW_REFRESH_ICON_FIELD,
  [FIELD_IDS.TABLE_SHOW_MULTI_SORT]: TABLE_SHOW_MULTI_SORT_FIELD,
  [FIELD_IDS.TABLE_SHOW_FILTER]: TABLE_SHOW_FILTER_FIELD,
  [FIELD_IDS.TABLE_SHOW_SEARCH_BY_FIELD]: TABLE_SHOW_SEARCH_BY_FIELD,
  [FIELD_IDS.EXPANDABLE_ROW_CONFIG_FORM]: EXPANDABLE_ROW_CONFIG_FORM_FIELD,
  [FIELD_IDS.TABLE_MIN_ROWS]: TABLE_MIN_ROWS_FIELD,
  [FIELD_IDS.TABLE_ROW_HEIGHT]: TABLE_ROW_HEIGHT_FIELD,
  [FIELD_IDS.TABLE_CURRENT_PAGE]: TABLE_CURRENT_PAGE_FIELD,
  [FIELD_IDS.TABLE_RESULT_PER_PAGE]: TABLE_RESULT_PER_PAGE_FIELD,
  [FIELD_IDS.TABLE_PAGE_SIZE]: TABLE_PAGE_SIZE_FIELD,
  [FIELD_IDS.TABLE_FIXED_PAGINATION]: TABLE_FIXED_PAGINATION_FIELD,
  [FIELD_IDS.TABLE_SHOW_PAGINATION]: TABLE_SHOW_PAGINATION_FIELD,
  [FIELD_IDS.TABLE_PAGE_SIZE_OPTIONS]: TABLE_PAGE_SIZE_OPTIONS_FIELD,
  [FIELD_IDS.DOWNLOAD_OPTIONS]: DOWNLOAD_OPTIONS_FIELD,
  [FIELD_IDS.FILTER_COMPONENT]: FILTER_COMPONENT_FIELD,
  [FIELD_IDS.SORT_DETAILS]: SORT_DETAILS_FIELD,
  [FIELD_IDS.ACTIONS_TO_SHOW]: ACTIONS_TO_SHOW_FIELD,
  [FIELD_IDS.SEARCH_OPTIONS]: SEARCH_OPTIONS_FIELD,
  [FIELD_IDS.EVENT_HANDLERS]: EVENT_HANDLER_FIELD,
  [FIELD_IDS.CUSTOM_STYLES]: CUSTOM_STYLE_FIELD,
};

const getFields = (componentType, entity, properties, errors) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.FILTER_COMPONENT]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.FILTER_COMPONENT], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
  ]),
  [FIELD_IDS.EXPANDABLE_ROW_CONFIG_FORM]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.EXPANDABLE_ROW_CONFIG_FORM], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'errors', value: _get(errors, FIELD_IDS.EXPANDABLE_ROW_CONFIG_FORM, EMPTY_OBJECT) },
  ]),
  [FIELD_IDS.ACTIONS_TO_SHOW]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.ACTIONS_TO_SHOW], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
  ]),
  [FIELD_IDS.SEARCH_OPTIONS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.SEARCH_OPTIONS], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
  ]),
  [FIELD_IDS.SORT_DETAILS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.SORT_DETAILS], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
  ]),
  [FIELD_IDS.EVENT_HANDLERS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.EVENT_HANDLERS], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
  [FIELD_IDS.CUSTOM_STYLES]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.CUSTOM_STYLES], [
    { path: 'entity', value: entity },
    { path: 'selectedViewComponentProperties', value: properties },
    { path: 'componentType', value: componentType },
  ]),
});

export { getFields };
