import { Tree, Icon } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _noop from 'lodash/noop';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import ExpandableSearch from '@tekion/tekion-components/organisms/ExpandableSearch';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Button from '@tekion/tekion-components/atoms/Button';

import withActions from '@tekion/tekion-components/connectors/withActions';
import withSize from '../../../../../../connectors/withSize';

import DeleteModal from './components/deleteModal';
import RoleHeader from './components/roleHeader';
import ACTION_HANDLERS from './helpers/reporteeList.actionHandlers';
import { TREE_ACTION_TYPES } from './constants/reporteeList.actionTypes';

import styles from './reporteeList.module.scss';

const { TreeNode } = Tree;

const ReporteeList = ({
  isDeleteModalVisible,
  isModalRoleFormVisible,
  autoExpandParent,
  contentHeight,
  contentWidth,
  totalRoles,
  searchText,
  selectedRoleName,
  selectedRoleValue,
  topMostRoleKey,
  errors,
  newReporteeData,
  expandedKeys,
  treeData,
  roleOptions,
  onAction,
}) => {
  useEffect(() => {
    onAction({ type: TREE_ACTION_TYPES.ON_INITIALIZE_ROLES });
  }, [onAction]);

  const onSelect = useCallback(
    (selectedKeys) => {
      onAction({ type: TREE_ACTION_TYPES.ON_SELECT_ROLE, payload: { selectedKeys } });
    },
    [onAction],
  );

  const handleExpand = useCallback(
    (currentExpandedKeys) => {
      onAction({ type: TREE_ACTION_TYPES.ON_EXPAND_ROLE, payload: { expandedKeys: currentExpandedKeys } });
    },
    [onAction],
  );

  const handleClickAddReportee = useCallback(() => {
    onAction({ type: TREE_ACTION_TYPES.ON_CLICK_ADD_REPORTEE });
  }, [onAction]);

  const handleSearchValueChange = useCallback(
    (currentSearchText) => {
      onAction({ type: TREE_ACTION_TYPES.ON_SEARCH_ROLE, payload: { searchText: currentSearchText } });
    },
    [onAction],
  );

  const renderTreeNodes = useCallback(
    (data) =>
      _map(data, (item) => (
        <TreeNode
          title={
            <RoleHeader
              roleHeaderName={item.title}
              topMostRoleKey={topMostRoleKey}
              roleKey={item.key}
              onAction={onAction}
              expandedKeys={expandedKeys}
              totalRoles={totalRoles}
              contentWidth={contentWidth - 35}
            />
          }
          key={item.key}
          dataRef={item}
          className={styles.treeNodeContainer}
        >
          {_get(item, 'children') && renderTreeNodes(item.children)}
        </TreeNode>
      )),
    [onAction, expandedKeys, contentWidth, topMostRoleKey, totalRoles],
  );

  return (
    <Page className="full-height full-width">
      <Page.Body className={styles.bodyContainer} style={{ height: contentHeight }}>
        <div className={styles.headerContainer}>
          <Heading className="full-width">{__(`Roles (${totalRoles || 0})`)}</Heading>
          <div className={styles.rightHeaderContainer}>
            <ExpandableSearch className={styles.expandableSearch} onSearch={handleSearchValueChange} value={searchText} />
            <Button view={Button.VIEW.PRIMARY} onClick={handleClickAddReportee}>
              {__('Add Role')}
            </Button>
          </div>
        </div>
        <div className={styles.tableHeaderContainer}>
          <div className={styles.headingRoleNameContainer}>
            <div className={styles.titleRoleNameContainer}>{__('Role Name')}</div>
          </div>
          <div className={styles.headingActionsContainer}>
            <div className={styles.titleActionsContainer}>{__('Actions')}</div>
          </div>
        </div>
        <Tree
          style={{ width: contentWidth - 40, overflowX: 'hidden' }}
          switcherIcon={<Icon type="down" />}
          onSelect={onSelect}
          onExpand={handleExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          showIcon={false}
        >
          {renderTreeNodes(treeData)}
        </Tree>
        <DeleteModal
          isDeleteModalVisible={isDeleteModalVisible}
          isModalRoleFormVisible={isModalRoleFormVisible}
          newReporteeData={newReporteeData}
          deletedRoleName={selectedRoleName}
          roleOptions={roleOptions}
          selectedRoleValue={selectedRoleValue}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>
    </Page>
  );
};

ReporteeList.propTypes = {
  isDeleteModalVisible: PropTypes.bool,
  isModalRoleFormVisible: PropTypes.bool,
  autoExpandParent: PropTypes.bool,
  contentHeight: PropTypes.number,
  contentWidth: PropTypes.number,
  totalRoles: PropTypes.number,
  searchText: PropTypes.string,
  selectedRoleName: PropTypes.string,
  selectedRoleValue: PropTypes.string,
  topMostRoleKey: PropTypes.string,
  errors: PropTypes.object,
  newReporteeData: PropTypes.object,
  expandedKeys: PropTypes.array,
  treeData: PropTypes.array,
  roleOptions: PropTypes.array,
  onAction: PropTypes.func,
};

ReporteeList.defaultProps = {
  isDeleteModalVisible: false,
  isModalRoleFormVisible: false,
  autoExpandParent: false,
  contentHeight: 0,
  contentWidth: 0,
  totalRoles: 0,
  searchText: EMPTY_STRING,
  selectedRoleName: EMPTY_STRING,
  selectedRoleValue: EMPTY_STRING,
  topMostRoleKey: EMPTY_STRING,
  errors: EMPTY_OBJECT,
  newReporteeData: EMPTY_OBJECT,
  expandedKeys: EMPTY_ARRAY,
  treeData: EMPTY_ARRAY,
  roleOptions: EMPTY_ARRAY,
  onAction: _noop,
};

export default compose(withSize({ hasPageFooter: 0, hasPageHeader: 0 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS), React.memo)(ReporteeList);
