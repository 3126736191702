const ACTION_TYPES = {
  ON_CREATE_SUBSCRIPTION_CONFIG: 'ON_CREATE_SUBSCRIPTION_CONFIG',
  ON_EDIT_SUBSCRIPTION_CONFIG: 'ON_EDIT_SUBSCRIPTION_CONFIG',
  ON_ENABLE_SUBSCRIPTION_LOGS: 'ON_ENABLE_SUBSCRIPTION_LOGS',
  ON_INACTIVE_SUBSCRIPTION_CONFIG: 'ON_INACTIVE_SUBSCRIPTION_CONFIG',
  ON_ACTIVE_SUBSCRIPTION_CONFIG: 'ON_ACTIVE_SUBSCRIPTION_CONFIG',
  ON_REDIRECT_TO_SUBSCRIPTION_LOGS: 'ON_REDIRECT_TO_SUBSCRIPTION_LOGS',
};

export default ACTION_TYPES;
