import _reduce from 'lodash/reduce';

import { COMPONENT_TYPES, VIEW_TYPES } from '../../../constants/viewBuilder.constants';
import { EVENT_NAMES } from '../../../../../constants/eventActions.constants';

const getEventNamesForFormView = (componentType) => {
  switch (componentType) {
    case COMPONENT_TYPES.FORM_VIEW_RENDERER: {
      return [EVENT_NAMES.BEFORE_SUBMIT];
    }
    case COMPONENT_TYPES.FORM_VIEW_COLUMN: {
      return [EVENT_NAMES.OPTIONS_LOAD, EVENT_NAMES.CHANGE, EVENT_NAMES.BLUR, EVENT_NAMES.BEFORE_DATA_LOAD];
    }
    default: {
      return [];
    }
  }
};

const getEventNamesForDetailView = (componentType) => {
  switch (componentType) {
    case COMPONENT_TYPES.SECTION: {
      return [EVENT_NAMES.LOAD];
    }
    case COMPONENT_TYPES.DETAIL_VIEW_ATTRIBUTE: {
      return [EVENT_NAMES.CLICK];
    }
    case COMPONENT_TYPES.BUTTON: {
      return [EVENT_NAMES.CLICK, EVENT_NAMES.LOAD];
    }
    default: {
      return [];
    }
  }
};

const getEventNamesForListView = (componentType) => {
  switch (componentType) {
    case COMPONENT_TYPES.LIST_VIEW_RENDERER:
    case COMPONENT_TYPES.GRID_VIEW: {
      return [EVENT_NAMES.ON_ROW_CLICK, EVENT_NAMES.AFTER_DATA_LOAD, EVENT_NAMES.LOAD];
    }
    default: {
      return [];
    }
  }
};

const getEventNamesForCellView = (componentType) => {
  switch (componentType) {
    case COMPONENT_TYPES.BUTTON: {
      return [EVENT_NAMES.CLICK, EVENT_NAMES.LOAD];
    }
    default: {
      return [];
    }
  }
};

const getEventNamesMappingBasedOnViewTypeAndComponentType = () => {
  const eventNamesBasedOnViewTypeAndComponentType = _reduce(
    VIEW_TYPES,
    (prevEventNamesBasedOnViewTypeAndComponentType, viewType) => {
      const eventNamesBasedOnViewType = _reduce(
        COMPONENT_TYPES,
        (prevComponentTypeToEventNamesMapping, componentType) => {
          switch (viewType) {
            case VIEW_TYPES.FORM_VIEW: {
              const formViewEventNames = getEventNamesForFormView(componentType);
              return { ...prevComponentTypeToEventNamesMapping, [componentType]: formViewEventNames };
            }
            case VIEW_TYPES.DETAIL_VIEW: {
              const detailViewEventNames = getEventNamesForDetailView(componentType);
              return { ...prevComponentTypeToEventNamesMapping, [componentType]: detailViewEventNames };
            }
            case VIEW_TYPES.LIST_VIEW:
            case VIEW_TYPES.GRID_VIEW: {
              const listViewEventNames = getEventNamesForListView(componentType);
              return { ...prevComponentTypeToEventNamesMapping, [componentType]: listViewEventNames };
            }
            case VIEW_TYPES.CELL_VIEW: {
              const cellViewEventNames = getEventNamesForCellView(componentType);
              return { ...prevComponentTypeToEventNamesMapping, [componentType]: cellViewEventNames };
            }
            default: {
              return prevComponentTypeToEventNamesMapping;
            }
          }
        },
        {},
      );
      return { ...prevEventNamesBasedOnViewTypeAndComponentType, [viewType]: eventNamesBasedOnViewType };
    },
    {},
  );
  return eventNamesBasedOnViewTypeAndComponentType;
};

const EVENT_NAMES_MAP_BASED_ON_VIEW_TYPE_AND_COMPONENT_TYPE = getEventNamesMappingBasedOnViewTypeAndComponentType();

export { EVENT_NAMES_MAP_BASED_ON_VIEW_TYPE_AND_COMPONENT_TYPE };
