import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _get from 'lodash/get';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import withActions from '@tekion/tekion-components/connectors/withActions';

import withSize from '../../../../../connectors/withSize';

import { getSections, getFields } from './helpers/entityRecordGroupForm.formConfig';
import ACTION_HANDLERS from './helpers/entityRecordGroupForm.actionHandlers';
import { FORM_MODES } from '../../../../../constants/general.constants';
import { ENTITY_RECORD_GROUP_FORM_CONTEXT_ID, INITIAL_STATE } from './constants/entityRecordGroupForm.general';
import ACTION_TYPES from './constants/entityRecordGroupForm.actionTypes';
import { FIELD_IDS } from '../../../../../constants/entityRecordGroup.constants';

const EntityRecordGroupForm = ({
  isSubmitting,
  loading,
  isConditionFieldsLoading,
  formMode,
  contentHeight,
  formValues,
  errors,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  onAction,
}) => {
  const entityName = getArraySafeValue(_get(formValues, FIELD_IDS.ENTITY));
  const headerTitle = formMode === FORM_MODES.CREATE ? __('Create Entity Record Group') : __('Update Entity Record Group');
  const primaryButtonLabel = formMode === FORM_MODES.CREATE ? __('Create') : __('Update');
  const fields = getFields(conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, formMode);
  const sections = useMemo(() => getSections(isConditionFieldsLoading, entityName), [isConditionFieldsLoading, entityName]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(ENTITY_RECORD_GROUP_FORM_CONTEXT_ID);
  }, []);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.LOAD_CONDITION_FIELDS });
  }, [onAction, entityName]);

  return (
    <Page>
      <Page.Header hasBack>
        <Heading>{headerTitle}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          className="p-t-12"
          isFetching={loading}
          contextId={ENTITY_RECORD_GROUP_FORM_CONTEXT_ID}
          sections={sections}
          fields={fields}
          values={formValues}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id={ENTITY_RECORD_GROUP_FORM_CONTEXT_ID}
          primaryActionLoading={isSubmitting}
          primaryButtonLabel={primaryButtonLabel}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

EntityRecordGroupForm.propTypes = {
  isSubmitting: PropTypes.bool,
  loading: PropTypes.bool,
  isConditionFieldsLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

EntityRecordGroupForm.defaultProps = {
  isSubmitting: false,
  loading: false,
  isConditionFieldsLoading: false,
  formMode: FORM_MODES.CREATE,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(INITIAL_STATE, ACTION_HANDLERS))(EntityRecordGroupForm);
