import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { NO_DATA } from '@tekion/tekion-base/app.constants';
import { DATE_TIME_FORMATS, getFormattedDateTime } from '@tekion/tekion-base/utils/dateUtils';

import { APPROVAL_CENTRE_FIELD_IDS, APPROVAL_VALIDITY_TYPES } from '../../constants/approvalCentre.constants';

const getValidityData = (validity = {}) => {
  const {
    [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_TYPE]: validityType,
    [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_EPOCH]: epoch,
    [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_DAYS]: days,
    [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_HOURS]: hours,
    [APPROVAL_CENTRE_FIELD_IDS.VALIDITY_MINUTES]: minutes,
  } = validity;

  if (validityType === APPROVAL_VALIDITY_TYPES.EXACT) {
    return getFormattedDateTime(epoch, DATE_TIME_FORMATS.MONTH_DATE_YEAR_TIME_12HR_FORMAT);
  } else if (validityType === APPROVAL_VALIDITY_TYPES.RELATIVE) {
    const daysDisplay = days > 0 ? __('{{days}} days ', { days }) : '';
    const hoursDisplay = hours > 0 ? __('{{hours}} hours ', { hours }) : '';
    const minutesDisplay = minutes > 0 ? __('{{minutes}} minutes', { minutes }) : '';
    return `${daysDisplay}${hoursDisplay}${minutesDisplay}`;
  }

  return NO_DATA;
};

const getStagesData = (stages) =>
  _map(stages, (stageDetails, index) => {
    const approvers = tget(stageDetails, APPROVAL_CENTRE_FIELD_IDS.APPROVERS, []);
    const validity = tget(stageDetails, APPROVAL_CENTRE_FIELD_IDS.VALIDITY, {});
    const validityColumnData = getValidityData(validity);

    return {
      [APPROVAL_CENTRE_FIELD_IDS.APPROVAL_LEVEL]: index + 1,
      [APPROVAL_CENTRE_FIELD_IDS.APPROVERS]: approvers,
      [APPROVAL_CENTRE_FIELD_IDS.MIN_APPROVALS]: tget(stageDetails, APPROVAL_CENTRE_FIELD_IDS.MIN_APPROVALS),
      [APPROVAL_CENTRE_FIELD_IDS.VALIDITY]: validityColumnData,
    };
  });

export { getStagesData };
