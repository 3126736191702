import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import List from '@tekion/tekion-components/molecules/List';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import { EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { getProjectCreatedTime } from '../../helpers/projectApplicationList.helpers';

import ACTION_TYPES from '../../constants/projectApplicationList.actionTypes';

import styles from './projectApplicationsCardView.module.scss';

const ProjectApplicationsCardView = ({ projectData, onAction }) => {
  const projectCreatedTime = getProjectCreatedTime(tget(projectData, 'createdTime', NO_DATA));

  const handleEditClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CLICK_EDIT,
    });
  }, [onAction]);

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Display Name')}</div>
        <List.Item.Meta description={tget(projectData, 'displayName', NO_DATA)} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Name')}</div>
        <List.Item.Meta description={tget(projectData, 'name', NO_DATA)} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Created Time')}</div>
        <List.Item.Meta description={projectCreatedTime || NO_DATA} />
      </div>
      <FontIcon size={FontIcon.SIZES.MD_S} onClick={handleEditClick} className="cursor-pointer m-r-32">
        icon-edit
      </FontIcon>
    </div>
  );
};

ProjectApplicationsCardView.propTypes = {
  projectData: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ProjectApplicationsCardView.defaultProps = {
  projectData: EMPTY_OBJECT,
};
export default ProjectApplicationsCardView;
