import _get from 'lodash/get';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _uniq from 'lodash/uniq';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import _has from 'lodash/has';
import _castArray from 'lodash/castArray';

// Tekion-base
import { getFormattedDateTime, DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';
import { EMPTY_ARRAY, NO_DATA, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

// Constants
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

// Actions
import { searchViewConfigurations, saveViewConfiguration } from '../../../../actions/viewBuilderPage.actions';
import { searchEntityViewConfigurations, deleteEntityViewConfiguration } from '../../../../actions/entityViewDefinitions.actions';
import { getWorkspaceUserList } from '../../../../actions/workspaceUserManagement.actions';

import { convertToSpaceLessLowerCaseString } from '../../../../utils';
import { getPayload, getPayloadForUserIds } from './helpers/viewBuilderList.helpers';
import { CLONE_VIEW_MODAL_FORM_IDS } from './helpers/viewBuilderCloneModal.formConfig';

import { DEFAULT_FILTER_GROUP, STATUS, VIEW_BUILDER_LIST_TAB_IDS } from './constants/viewBuilderList.general';
import { RECORD_TYPE_LIST_COLUMN_IDS } from './constants/viewBuilderList.columnIds';
import ACTION_TYPES from './constants/viewBuilderList.actionTypes';
import { STUDIO_ROUTE } from '../../../../constants/routes';
import PAGE_IDS from '../../constants/PageIds.constants';

const fetchUsers = async (ids) => {
  if (!_isEmpty(ids)) {
    const payload = getPayloadForUserIds(ids);
    const response = await getWorkspaceUserList(payload);
    const users = tget(response, 'hits', EMPTY_ARRAY);
    const userData = _keyBy(users, 'id');
    return userData;
  }
  return EMPTY_OBJECT;
};

const refineData = async (data) => {
  const ids = _uniq(_map(data, (value) => _get(value, RECORD_TYPE_LIST_COLUMN_IDS.MODIFIED_BY)));
  const userData = await fetchUsers(ids);

  return _map(data, (value) => ({
    ...value,
    [RECORD_TYPE_LIST_COLUMN_IDS.MODIFIED_BY]: `${_get(
      userData[_get(value, RECORD_TYPE_LIST_COLUMN_IDS.MODIFIED_BY)],
      'entity.displayName',
      NO_DATA,
    )}, ${getFormattedDateTime(_get(value, 'modifiedTime', ''), DATE_TIME_FORMATS.ABBREVIATED_DATE_ALPHA_MONTH)}`,
  }));
};

const handleTableItemsFetch = async ({ params, getState, setState }) => {
  const { match, pageSize, searchText, searchField, selectedTabId = VIEW_BUILDER_LIST_TAB_IDS.ENTITY_VIEWS, selectedFilters } = getState();
  const entityName = _get(match, 'params.entityName', '');

  const currentPageSize = _get(params, 'pageSize', pageSize);
  const currentPageToken = _get(params, 'nextPageToken');
  const currentSearchText = _get(params, 'searchText', searchText);
  const currentFilters = _get(params, 'selectedFilters', selectedFilters);
  let currentSearchField = _get(params, 'searchField', searchField);
  let data = [];

  setState({ isLoading: true });

  if (currentSearchText && !currentSearchField) {
    currentSearchField = RECORD_TYPE_LIST_COLUMN_IDS.VIEW_NAME;
  }

  const payload = getPayload(entityName, currentSearchText, currentSearchField, currentPageSize, currentFilters, currentPageToken);

  if (selectedTabId === VIEW_BUILDER_LIST_TAB_IDS.RECORD_TYPE_VIEWS) {
    data = await searchViewConfigurations(payload);
    const currentTabId = _get(getState(), 'selectedTabId');
    if (currentTabId !== VIEW_BUILDER_LIST_TAB_IDS.RECORD_TYPE_VIEWS) {
      return;
    }
  } else {
    data = await searchEntityViewConfigurations(payload);
    const currentTabId = _get(getState(), 'selectedTabId');
    if (currentTabId === VIEW_BUILDER_LIST_TAB_IDS.RECORD_TYPE_VIEWS) {
      return;
    }
  }

  const resolvedData = await refineData(tget(data, 'hits', EMPTY_ARRAY));
  const nextPageToken = _get(data, 'nextPageToken');

  setState({ data: resolvedData, isLoading: false, totalCount: _size(resolvedData), nextPageToken });
};

const handleTabChange = ({ setState, getState, params }) => {
  const { history, match = EMPTY_OBJECT, selectedTabId } = getState();
  const entityName = _get(match, 'params.entityName', '');
  let newSelectedTabId = VIEW_BUILDER_LIST_TAB_IDS.ENTITY_VIEWS;

  if (_has(params, 'tabId')) {
    newSelectedTabId = tget(params, 'tabId');
  } else if (_has(match, 'params.tabId')) {
    newSelectedTabId = _get(match, 'params.tabId');
  }

  const initialState = {
    searchText: '',
    selectedFilters: [],
    nextPageToken: null,
    currentPage: 0,
    previousPageTokens: null,
    pageToken: null,
  };

  if (!_isEmpty(entityName) && selectedTabId !== newSelectedTabId) {
    history.replace({
      pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VIEW_BUILDER}/${newSelectedTabId}`,
    });
    setState({ selectedTabId: newSelectedTabId, data: [], ...initialState }, () => {
      handleTableItemsFetch({ getState, setState });
    });
  }
};

const handleCreateView = ({ getState }) => {
  const { history, match, selectedTabId } = getState();
  const entityName = _get(match, 'params.entityName', '');

  if (!_isEmpty(entityName)) {
    history.push({
      pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VIEW_BUILDER_CREATE}/${selectedTabId}`,
    });
  }
};

const handleViewItemClick = ({ params, getState }) => {
  const { history, selectedTabId, data } = getState();
  const {
    value: {
      original: { entityName, name },
      index,
    },
  } = params;

  if (selectedTabId === VIEW_BUILDER_LIST_TAB_IDS.RECORD_TYPE_VIEWS) {
    history.push({
      pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VIEW_BUILDER_EDIT}/${selectedTabId}/${name}`,
      state: { viewConfiguration: tget(params, 'value.original', EMPTY_OBJECT) },
    });
  } else {
    const currentEntityViewConfig = data[index];
    const viewName = _get(currentEntityViewConfig, 'name');

    history.push({
      // eslint-disable-next-line max-len
      pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VIEW_BUILDER_EDIT}/${selectedTabId}/${viewName}`,
      state: { currentEntityViewConfig },
    });
  }
};

const handleSetFilter = async ({ params = EMPTY_OBJECT, getState, setState }) => {
  const filters = _get(params, 'value', EMPTY_ARRAY);
  const selectedFilterGroup = _get(params, 'selectedFilterGroup', DEFAULT_FILTER_GROUP);

  setState(
    {
      selectedFilters: filters,
      currentPage: 0,
      selectedFilterGroup,
    },
    () => {
      handleTableItemsFetch({ getState, setState });
    },
  );
};

const handlePageSizeUpdate = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');

  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }
  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleTableItemsFetch({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const handleSearchTextUpdate = ({ setState, params = EMPTY_OBJECT, getState }) => {
  setState(
    {
      searchText: _get(params, 'value'),
      currentPage: 0,
    },
    () => {
      handleTableItemsFetch({ getState, setState });
    },
  );
};

const handleSearchFieldChange = ({ params, setState }) => {
  const searchField = _get(params, 'value');
  setState({ searchField });
};

const handleEditClick = ({ getState, params = EMPTY_OBJECT }) => {
  const { entityName = EMPTY_STRING, name = EMPTY_STRING } = params;
  const { history, selectedTabId } = getState();

  if (selectedTabId === VIEW_BUILDER_LIST_TAB_IDS.RECORD_TYPE_VIEWS) {
    history.push({
      pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VIEW_BUILDER_EDIT}/${selectedTabId}/${name}`,
      state: { viewConfiguration: params },
    });
  } else {
    const viewName = _get(params, 'name');
    history.push({
      pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VIEW_BUILDER_EDIT}/${selectedTabId}/${viewName}`,
      state: { currentEntityViewConfig: params },
    });
  }
};

const handleCloneViewModalOpen = ({ params, setState }) => {
  const { displayName = EMPTY_STRING, description = EMPTY_STRING } = params;
  const clonedDefaultDisplayName = `Clone of ${displayName}`;
  const clonedDefaultDescription = `Clone of ${description}`;
  setState({
    isCloneViewModalVisible: true,
    rowActionClickedItemViewConfiguration: params,
    cloneViewModalFormData: {
      [CLONE_VIEW_MODAL_FORM_IDS.NAME]: clonedDefaultDisplayName,
      [CLONE_VIEW_MODAL_FORM_IDS.DESCRIPTION]: clonedDefaultDescription,
    },
  });
};

const handleCloneViewModalClose = ({ setState }) => {
  setState({ isCloneViewModalVisible: false, cloneViewModalFormData: {}, rowActionClickedItemViewConfiguration: {} });
};

const handleCloneView = async ({ getState, setState }) => {
  const { rowActionClickedItemViewConfiguration = EMPTY_OBJECT, match, cloneViewModalFormData = EMPTY_OBJECT } = getState();
  setState({ isCloneViewModalDataSubmitting: true });
  const entityName = _get(match, 'params.entityName', EMPTY_STRING);
  const rowActionClickedItemViewConfigurationProperties = tget(rowActionClickedItemViewConfiguration, 'properties');
  const clonedViewConfiguration = {
    ...rowActionClickedItemViewConfiguration,
    properties: {
      ...rowActionClickedItemViewConfigurationProperties,
      ...cloneViewModalFormData,
    },
    [RECORD_TYPE_LIST_COLUMN_IDS.VIEW_NAME]: convertToSpaceLessLowerCaseString(tget(cloneViewModalFormData, CLONE_VIEW_MODAL_FORM_IDS.NAME)),
  };

  const response = await saveViewConfiguration(entityName, clonedViewConfiguration);
  if (response === false) {
    setState({
      isCloneViewModalDataSubmitting: false,
      isCloneViewModalVisible: false,
      cloneViewModalFormData: {},
      rowActionClickedItemViewConfiguration: {},
    });
  } else {
    setState(
      {
        isCloneViewModalDataSubmitting: false,
        isCloneViewModalVisible: false,
        cloneViewModalFormData: {},
        rowActionClickedItemViewConfiguration: {},
      },
      () => handleTableItemsFetch({ getState, setState }),
    );
  }
};

const handleErrors = ({ params, setState }) => {
  const { errors } = params;
  setState({ cloneViewModalFormErrors: errors });
};

const handleCloneViewModalFieldChange = ({ params, getState, setState }) => {
  const { id, value } = params;
  const { cloneViewModalFormData } = getState();
  const newCloneViewModalFormData = { ...cloneViewModalFormData, [id]: value };

  setState({ cloneViewModalFormData: newCloneViewModalFormData });
};

const handleDeleteEntityView = async ({ getState, setState, params }) => {
  const { match } = getState();

  const entityName = _get(match, 'params.entityName', '');
  const viewName = _get(params, 'name');

  const status = await deleteEntityViewConfiguration(entityName, viewName);
  if (status === STATUS.SUCCESS) {
    toaster(TOASTER_TYPE.SUCCESS, __('Entity View Deleted Successfully.'));
    handleTableItemsFetch({ getState, setState });
  }
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleTableItemsFetch,
  [ACTION_TYPES.CREATE_VIEW_BUILDER]: handleCreateView,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleViewItemClick,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleSetFilter,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageSizeUpdate,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchTextUpdate,
  [ACTION_TYPES.EDIT_VIEW_BUILDER]: handleEditClick,
  [ACTION_TYPES.DELETE_ENTITY_VIEW]: handleDeleteEntityView,
  [ACTION_TYPES.CLONE_VIEW_MODAL_OPEN]: handleCloneViewModalOpen,
  [ACTION_TYPES.CLONE_VIEW_MODAL_CLOSE]: handleCloneViewModalClose,
  [ACTION_TYPES.CLONE_VIEW_MODAL_SUBMIT]: handleCloneView,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleCloneViewModalFieldChange,
  [ACTION_TYPES.ON_VIEWS_TAB_CHANGE]: handleTabChange,
  [TABLE_ACTION_TYPES.TABLE_SEARCH_FIELD]: handleSearchFieldChange,
};

export default ACTION_HANDLERS;
