import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { fetchPageConfigurationByName } from '../../../../actions/visualBuilder.actions';
import { convertPageMetadataToPageConfiguration } from './visualBuilder.helpers';
import { ACTION_TYPES } from './visualBuilderPage.constants';
import { modifyPageConfigFromApi } from '../../../../helpers/visualBuilder.helpers';

const handleInit = async ({ getState, setState }) => {
  const { match, history } = getState();
  const pageName = _get(match, 'params.pageName');
  let pageConfig = {};
  let widgetConfiguration = [];

  setState({
    isLoading: true,
    isEditMode: !!pageName,
  });

  if (pageName) {
    if (!_isEmpty(_get(history, 'location.state'))) {
      pageConfig = _get(history, 'location.state');
    } else {
      pageConfig = await fetchPageConfigurationByName(pageName, false);
      [pageConfig, widgetConfiguration] = await modifyPageConfigFromApi(pageConfig);
    }
  }

  setState({
    pageConfiguration: pageConfig,
    widgetConfiguration,
    isLoading: false,
  });
};

const handleSavePageMetadata = ({ setState, params }) => {
  const { pageMetadata, widgets } = params;
  const pageConfiguration = convertPageMetadataToPageConfiguration(pageMetadata);
  setState({ pageConfiguration, widgetConfiguration: widgets });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: handleInit,
  [ACTION_TYPES.SAVE_PAGE_METADATA]: handleSavePageMetadata,
};

export default ACTION_HANDLERS;
