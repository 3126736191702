import { FIELD_DEPENDENCY_TYPES } from '../../../../../../../../../../constants/general.constants';

const ROW_ACTION_PROPS = {
  visibleActionCount: 1,
};

const DEPENDENCY_CONFIG_TABLE_ACTION_TYPES = {
  REMOVE_ROW: 'REMOVE_ROW',
};

const REMOVE_ACTION = {
  id: DEPENDENCY_CONFIG_TABLE_ACTION_TYPES.REMOVE_ROW,
  name: __('Remove Row'),
  icon: 'icon-trash',
};

const DEPENDENCY_OPTIONS = [
  {
    label: __('Strong'),
    value: FIELD_DEPENDENCY_TYPES.STRONG,
  },
  {
    label: __('Weak'),
    value: FIELD_DEPENDENCY_TYPES.WEAK,
  },
];

const COLUMNS = {
  FIELD_NAME: 'fieldName',
  DEPENDENCY_TYPE: 'dependencyType',
};

export { ROW_ACTION_PROPS, REMOVE_ACTION, DEPENDENCY_CONFIG_TABLE_ACTION_TYPES, DEPENDENCY_OPTIONS, COLUMNS };
