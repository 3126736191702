import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

const FIELD_IDS = {
  MEDIA_ID: 'mediaId',
};

const MEDIA_ID_FIELD = {
  id: FIELD_IDS.MEDIA_ID,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Media Id '),
    validators: [isRequiredRule],
    placeholder: __('Enter Media Id'),
  },
};

const IMPORT_FORM_FIELDS = {
  [FIELD_IDS.MEDIA_ID]: MEDIA_ID_FIELD,
};

export { IMPORT_FORM_FIELDS, FIELD_IDS };
