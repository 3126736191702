import React from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import _toString from 'lodash/toString';

import { NO_DATA } from '@tekion/tekion-base/app.constants';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import styles from './fieldAndChangedValuePair.module.scss';

const FieldAndChangedValuePair = ({ label, prevValue, newValue }) => {
  let prevValueToDisplay = prevValue;
  let newValueToDisplay = newValue;

  if (typeof prevValue === 'boolean') {
    prevValueToDisplay = prevValue ? __('True') : __('False');
  }

  if (typeof newValue === 'boolean') {
    newValueToDisplay = newValue ? __('True') : __('False');
  }

  return (
    <div className={styles.container}>
      <div className={styles.label}>{label} : </div>
      <div className={styles.value}>
        <div className={styles.prevValue}>{_isEmpty(prevValueToDisplay) ? NO_DATA : _toString(prevValueToDisplay)}</div>
        <FontIcon className={styles.arrowIcon} size={SIZES.S}>
          icon-arrow_right
        </FontIcon>
        <div className={styles.newValue}>{_isEmpty(newValueToDisplay) ? NO_DATA : _toString(newValueToDisplay)}</div>
      </div>
    </div>
  );
};

FieldAndChangedValuePair.propTypes = {
  label: PropTypes.string.isRequired,
  prevValue: PropTypes.string.isRequired,
  newValue: PropTypes.string.isRequired,
};

export default FieldAndChangedValuePair;
