import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import _keyBy from 'lodash/keyBy';

// Tekion-base
import { getFormattedDateTime, DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import { fetchValidationRules, updateValidationRule } from '../../../../../../actions/validationRuleBuilder.actions';
import { getWorkspaceUserList } from '../../../../../../actions/workspaceUserManagement.actions';
import { getPayload, getPayloadForUserIds } from './validationRuleBuilderList.helpers';

import ACTION_TYPES from '../constants/validationRuleBuilderList.actionTypes';
import COLUMN_IDS from '../constants/validationRuleBuilderList.columnIds';
import { DEFAULT_PAGE_SIZE } from '../constants/validationRuleBuilderList.general';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import { FIELD_IDS } from '../../../../../../constants/validationRuleBuilder.constants';

const fetchUsers = async (ids) => {
  if (!_isEmpty(ids)) {
    const payload = getPayloadForUserIds(ids);
    const response = await getWorkspaceUserList(payload);
    const users = tget(response, 'hits', EMPTY_ARRAY);
    const userData = _keyBy(users, 'id');
    return userData;
  }
  return EMPTY_OBJECT;
};

const refineData = async (data) => {
  const ids = _uniq(_map(data, (value) => _get(value, COLUMN_IDS.MODIFIED_BY)));
  const userData = await fetchUsers(ids);
  const updatedData = _map(data, (value) => ({
    ...value,
    [COLUMN_IDS.MODIFIED_BY]: `${_get(userData[_get(value, COLUMN_IDS.MODIFIED_BY)], 'entity.displayname', NO_DATA)}, ${getFormattedDateTime(
      _get(value, 'modifiedTime', ''),
      DATE_TIME_FORMATS.ABBREVIATED_DATE_ALPHA_MONTH,
    )}`,
  }));
  return updatedData;
};

const fetchData = async ({ getState, setState }) => {
  setState({ isLoading: true });
  const {
    match,
    selectedFilters = EMPTY_ARRAY,
    pageSize = DEFAULT_PAGE_SIZE,
    currentPage = 0,
    currentPageToken = EMPTY_STRING,
    searchText = EMPTY_STRING,
  } = getState();
  const entityName = _get(match, 'params.entityName', '');
  const payload = getPayload({ currentPage, currentPageToken, pageSize, searchText, selectedFilters });
  const response = await fetchValidationRules(entityName, payload);
  const data = tget(response, 'hits', EMPTY_ARRAY);
  const nextPageToken = _get(response, 'nextPageToken', EMPTY_STRING);
  const refinedData = await refineData(data);
  setState({ data: refinedData, isLoading: false, nextPageToken });
};

const handleSearch = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ searchText: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => fetchData({ getState, setState }));
};

const handlePageUpdate = ({ params, getState, setState }) => {
  const { page, resultsPerPage } = _get(params, 'value', EMPTY_OBJECT);
  const { currentPage, pageSize, nextPageToken, previousPageTokens = [], currentPageToken } = getState();
  let newPageNumber = page;
  let newTokenNumber = null;
  let newPrevPageTokens = [...previousPageTokens];

  if (resultsPerPage !== pageSize) {
    newPageNumber = 1;
    newPrevPageTokens = [];
  } else if (page > currentPage + 1) {
    newTokenNumber = nextPageToken;
    newPrevPageTokens.push(currentPageToken);
  } else if (page !== 1) {
    newTokenNumber = newPrevPageTokens.pop();
  } else {
    newPrevPageTokens = [];
  }

  setState(
    { currentPage: newPageNumber - 1, pageSize: resultsPerPage, currentPageToken: newTokenNumber, previousPageTokens: newPrevPageTokens },
    () => fetchData({ getState, setState }),
  );
};

const handleFilterChange = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ selectedFilters: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => fetchData({ getState, setState }));
};

const handleCreateValidationRule = ({ getState }) => {
  const { history, match } = getState();
  const entityName = _get(match, 'params.entityName', '');
  if (!_isEmpty(entityName)) {
    history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VALIDATION_RULE_BUILDER_CREATE}`);
  }
};

const handleEditValidationRule = ({ params, getState }) => {
  const ruleName = tget(params, FIELD_IDS.RULE_NAME);

  const { history, match } = getState();

  const entityName = _get(match, 'params.entityName', '');
  if (!_isEmpty(entityName) && !_isEmpty(ruleName)) {
    history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VALIDATION_RULE_BUILDER_EDIT}/${ruleName}`);
  }
};

const handleActivateValidationRule = async ({ params, getState, setState }) => {
  const { name, entityName } = params;
  setState({ isLoading: true });

  const response = await updateValidationRule(entityName, name, { ...params, active: true });

  setState({ isLoading: false });
  if (!_isEmpty(response)) {
    fetchData({ getState, setState });
  }
};

const handleInactivateValidationRule = async ({ params, getState, setState }) => {
  const { name, entityName } = params;

  setState({ isLoading: true });
  const response = await updateValidationRule(entityName, name, { ...params, active: false });
  setState({ isLoading: false });

  if (!_isEmpty(response)) {
    fetchData({ getState, setState });
  }
};

const handleValidationRuleClick = ({ getState, params = EMPTY_OBJECT }) => {
  const { history, match } = getState();
  const entityName = _get(match, 'params.entityName', '');

  const ruleName = _get(params, `value.original.${FIELD_IDS.RULE_NAME}`);

  if (!_isEmpty(entityName) && !_isEmpty(ruleName)) {
    history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VALIDATION_RULE_BUILDER_EDIT}/${ruleName}`);
  }
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleFilterChange,
  [ACTION_TYPES.CREATE_VALIDATION_RULE]: handleCreateValidationRule,
  [ACTION_TYPES.EDIT_VALIDATION_RULE]: handleEditValidationRule,
  [ACTION_TYPES.ACTIVE_VALIDATION_RULE]: handleActivateValidationRule,
  [ACTION_TYPES.INACTIVE_VALIDATION_RULE]: handleInactivateValidationRule,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleValidationRuleClick,
};

export default ACTION_HANDLERS;
