import _get from 'lodash/get';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import MetadataManagementService from '../services/metadataManagement.services';

const getMetadataExportList = async (payload) => {
  try {
    const receivedData = await MetadataManagementService.fetchBundleList(payload);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch bundle list, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const saveBundleData = async (payload) => {
  try {
    const receivedData = await MetadataManagementService.saveBundleData(payload);
    const data = getDataFromResponse(receivedData);
    return { data, status: _get(receivedData, 'data.status') };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to save bundle data , please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getBundleById = async (id) => {
  try {
    const receivedData = await MetadataManagementService.getBundleById(id);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get bundle data, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const deleteBundleById = async (id) => {
  try {
    const receiveData = await MetadataManagementService.deleteBundleById(id);
    toaster(TOASTER_TYPE.SUCCESS, 'Bundle along with all its components deleted successfully');
    return getDataFromResponse(receiveData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to delete bundle, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateBundleData = async (id, payload) => {
  try {
    const receivedData = await MetadataManagementService.updateBundleData(id, payload);
    const data = getDataFromResponse(receivedData);
    return { data, status: _get(receivedData, 'data.status') };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to edit bundle data, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchComponentsOfBundle = async (id, payload) => {
  try {
    const receivedData = await MetadataManagementService.fetchComponentsOfBundle(id, payload);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to get component data, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const addComponentToBundle = async (id, payload) => {
  try {
    const receivedData = await MetadataManagementService.addComponentToBundle(id, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Component added successfully.'));
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to add component data, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const publishBundleById = async (id) => {
  try {
    const receivedData = await MetadataManagementService.publishBundleById(id);
    toaster(TOASTER_TYPE.SUCCESS, __('Bundle published successfully.'));
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to publish bundle data, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const importBundleByMediaId = async (payload) => {
  try {
    const receivedData = await MetadataManagementService.importBundleByMediaId(payload);
    toaster(TOASTER_TYPE.SUCCESS, __('bundle imported successfully.'));
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to import bundle data, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const deleteComponentFromBundle = async (bundleId, componentId) => {
  try {
    const receiveData = await MetadataManagementService.deleteComponentFromBundle(bundleId, componentId);
    toaster(TOASTER_TYPE.SUCCESS, __('Component along with all its dependent component deleted successfully'));
    return getDataFromResponse(receiveData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to delete component, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export {
  getMetadataExportList,
  saveBundleData,
  getBundleById,
  updateBundleData,
  fetchComponentsOfBundle,
  addComponentToBundle,
  deleteBundleById,
  publishBundleById,
  importBundleByMediaId,
  deleteComponentFromBundle,
};
