const ACTION_TYPES = {
  ON_INIT_NAVIGATION_COMPONENT: 'ON_INIT_NAVIGATION_COMPONENT',
  INIT_DATA: 'INIT_DATA ',
  ADD_NEW_TAB: 'ADD_NEW_TAB',
  ON_DRAG_END: 'ON_DRAG_END',
  ON_EDIT_CLICK: 'ON_EDIT_CLICK',
  DELETE_TAB: 'DELETE_TAB',
  ON_TAB_CLICK: 'ON_TAB_CLICK',
  ON_CANCEL_REQUEST: 'ON_CANCEL_REQUEST',
  ON_CONFIRM_REQUEST: 'ON_CONFIRM_REQUEST',
  ON_CANCEL_DELETE_REQUEST: 'ON_CANCEL_DELETE_REQUEST',
  ON_CONFIRM_DELETE_REQUEST: 'ON_CONFIRM_DELETE_REQUEST',
  ON_CANCEL: 'ON_CANCEL',
  ON_SAVE: 'ON_SAVE',
  OPEN_PREVIEW_MODAL: 'OPEN_PREVIEW_MODAL',
  CLOSE_PREVIEW_MODAL: 'CLOSE_PREVIEW_MODAL',
  SET_APPLICATION_VARIABLES: 'SET_APPLICATION_VARIABLES',
  SET_APPLICATION_CONFIG: 'SET_APPLICATION_CONFIG',
  SET_SELECTED_COMPONENT: 'SET_SELECTED_COMPONENT',
  ON_ENTITY_MAPPINGS_CLICK: 'ON_ENTITY_MAPPINGS_CLICK',
  ON_CLOSE_ENTITY_MAPPINS_MODAL: 'ON_CLOSE_ENTITY_MAPPINS_MODAL',
};

export default ACTION_TYPES;
