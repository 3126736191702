import _get from 'lodash/get';
import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import _castArray from 'lodash/castArray';
import _filter from 'lodash/filter';
import _snakeCase from 'lodash/snakeCase';
import _head from 'lodash/head';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _keyBy from 'lodash/keyBy';

import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { tget } from '@tekion/tekion-base/utils/general';
import {
  saveEntityViewConfiguration,
  updateEntityViewConfiguration,
  fetchEntityViewConfigurationByName,
} from '../../../../../../actions/entityViewDefinitions.actions';
import { searchViewConfigurations } from '../../../../../../actions/viewBuilderPage.actions';
import { getFlattenedFormValues, getFormattedFormValues, getPayload, validateViewRecordTypeMetadataList } from './createEntityView.general.helpers';

import { ACTION_TYPES, FIELD_IDS } from '../constants/createEntityView.constants';
import { VIEW_TYPES } from '../../../../../../organisms/viewBuilder/constants/viewBuilder.constants';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import { ENTITY_VIEW_FORM_FIELD_IDS } from '../components/entityViewForm/constants/entityViewForm.general.constants';

import entityReader from '../../../../../../readers/entity.reader';
import recordTypeReader from '../../../../../../readers/recordType.reader';
import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../../constants/viewBuilder.constants';

const handleInit = async ({ setState, getState }) => {
  const { entity = EMPTY_OBJECT, history = EMPTY_OBJECT, match = EMPTY_OBJECT } = getState();
  const entityName = _get(match, 'params.entityName');
  const viewName = _get(match, 'params.viewName');
  let currentEntityViewConfig = tget(history, ['location', 'state', 'currentEntityViewConfig'], undefined);

  let formMode = FORM_MODES.CREATE;
  let formValues = { [FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST]: [{}] };

  if (viewName) {
    formMode = FORM_MODES.EDIT;
    if (!currentEntityViewConfig) {
      currentEntityViewConfig = await fetchEntityViewConfigurationByName(entityName, viewName);
    }
    formValues = currentEntityViewConfig;

    const viewType = tget(formValues, FIELD_IDS.VIEW_TYPE);

    if (viewType === VIEW_TYPES.LIST_VIEW || viewType === VIEW_TYPES.GRID_VIEW) {
      const viewRecordTypeMetaData = _head(tget(formValues, FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST)) || {};
      const listViewName = tget(viewRecordTypeMetaData, ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME, EMPTY_STRING);

      formValues = {
        ...formValues,
        [FIELD_IDS.LIST_VIEW_NAME]: listViewName,
      };
    }
  }

  const formattedFormValues = getFormattedFormValues(formValues);

  const recordTypeOptions = _map(entityReader.recordTypeDefinitionRecordTypes(entity), (recordType) => ({
    label: recordTypeReader.displayName(recordType),
    value: recordTypeReader.name(recordType),
  }));

  setState({ formValues: formattedFormValues, formMode, recordTypeOptions });
};

const handleRedirection = ({ getState }) => {
  const { history } = getState();

  history.goBack();
};

const handleFieldOnChange = async ({ setState, getState, params }) => {
  const { disableAutoFillForName, formMode, formValues = {}, match = EMPTY_OBJECT, entity } = getState();
  const { id, value } = params;

  let newFormValues = {
    ...formValues,
    [id]: value,
  };

  if (id === FIELD_IDS.DISPLAY_NAME && formMode === FORM_MODES.CREATE && !disableAutoFillForName) {
    newFormValues = {
      ...newFormValues,
      [FIELD_IDS.NAME]: _snakeCase(value),
    };
  } else if (id === FIELD_IDS.NAME) {
    setState({ disableAutoFillForName: true });
  } else if (id === FIELD_IDS.VIEW_TYPE) {
    _unset(newFormValues, FIELD_IDS.LIST_VIEW_NAME);

    const viewRecordTypeMetadataList = _get(newFormValues, [FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST], []);
    const updatedViewRecordTypeMetadataList = _map(viewRecordTypeMetadataList, (viewRecordTypeMetadata) => ({
      ...viewRecordTypeMetadata,
      [ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME]: [''],
    }));

    newFormValues = {
      ...newFormValues,
      [FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST]: updatedViewRecordTypeMetadataList,
    };

    if (getArraySafeValue(value) === VIEW_TYPES.FORM_VIEW && entityReader.recordTypeEnabled(entity)) {
      const entityName = _get(match, 'params.entityName');
      const payload = getPayload({ entityName, viewType: VIEW_TYPES.RECORD_TYPE_SELECTION_VIEW });
      const response = await searchViewConfigurations(payload);
      const recordTypeSelectionViewConfig = getArraySafeValue(tget(response, 'hits', []));
      const recordTypeSelectionViewName = _get(recordTypeSelectionViewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME);

      newFormValues = {
        ...newFormValues,
        [FIELD_IDS.RECORD_TYPE_SELECTION_VIEW_NAME]: recordTypeSelectionViewName,
      };
    }
  }

  setState({
    formValues: newFormValues,
  });
};

const handleValidation = ({ setState, params }) => {
  const { errors } = params;

  setState({ errors });
};

const handleEntityFormAddRow = ({ setState, getState }) => {
  const { formValues } = getState();
  const tableData = _get(formValues, FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST, EMPTY_ARRAY);

  setState({ formValues: { ...formValues, [FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST]: [...tableData, {}] } });
};

const handleEntityFormOnChange = ({ setState, params = EMPTY_OBJECT, getState }) => {
  const { id, value, row } = params;
  const { formValues = EMPTY_OBJECT, errors } = getState();

  const tableData = _get(formValues, FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST, []);

  tableData[row] = { ...tableData[row], [id]: _castArray(value) };

  if (id === ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME) {
    _unset(tableData[row], ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME);
  }

  const newErrors = _cloneDeep(errors);

  _unset(newErrors, [FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST, row, id]);

  setState({ formValues: { ...formValues, [FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST]: [...tableData] }, errors: newErrors });
};

const handleEntityFormRemoveRow = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { formValues } = getState();
  const { row } = params;
  let tableData = _get(formValues, FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST, EMPTY_ARRAY);

  tableData = _filter(tableData, (val, index) => index !== row);

  setState({
    formValues: { ...formValues, [FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST]: [...tableData] },
  });
};

const handleSubmit = async ({ setState, getState }) => {
  try {
    const { formMode, formValues, entity, listViewByName = EMPTY_OBJECT } = getState();
    const entityName = entityReader.name(entity);
    const viewType = getArraySafeValue(tget(formValues, FIELD_IDS.VIEW_TYPE));

    let viewRecordTypeMetadataList = _get(formValues, FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST, []);

    if (viewType === VIEW_TYPES.LIST_VIEW || viewType === VIEW_TYPES.GRID_VIEW) {
      const viewName = tget(formValues, FIELD_IDS.LIST_VIEW_NAME);
      const viewConfig = tget(listViewByName, viewName, EMPTY_OBJECT);
      const recordType = tget(viewConfig, 'applicableRecordTypes[0].recordTypeName');

      viewRecordTypeMetadataList = [{ [ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME]: recordType, [ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME]: viewName }];
      _set(formValues, [FIELD_IDS.VIEW_RECORD_TYPE_META_DATA_LIST], viewRecordTypeMetadataList);
    }

    const { isValid, message } = validateViewRecordTypeMetadataList(viewRecordTypeMetadataList);

    if (isValid) {
      const viewName = _get(formValues, FIELD_IDS.NAME);
      const formattedFormValues = getFlattenedFormValues(formValues);

      let response;

      setState({ isSaving: true });

      if (formMode === FORM_MODES.CREATE) {
        response = await saveEntityViewConfiguration(entityName, formattedFormValues);
      } else {
        response = await updateEntityViewConfiguration(entityName, viewName, formattedFormValues);
      }

      setState({ isSaving: false });

      if (_get(response, [FIELD_IDS.NAME]) === viewName) {
        toaster(TOASTER_TYPE.SUCCESS, __('Entity View Saved Successfully.'));
        handleRedirection({ getState });
      }
    } else {
      toaster(TOASTER_TYPE.ERROR, message);
    }
  } catch (error) {
    setState({ errors: error });
    toaster(TOASTER_TYPE.ERROR, __('Failed to Save Entity View.'));
  }
};

const handleListViewAsyncData = ({ getState, setState, params = {} }) => {
  const { id, value } = params;
  const { listViewByName = {} } = getState();

  if (id === FIELD_IDS.LIST_VIEW_NAME) {
    const newListViewByName = _keyBy(value, VIEW_CONFIGURATION_FIELD_IDS.NAME);
    setState({ listViewByName: { ...listViewByName, ...newListViewByName } });
  }
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleInit,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleValidation,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldOnChange,
  [ACTION_TYPES.ON_ADD_ROW]: handleEntityFormAddRow,
  [ACTION_TYPES.ON_REMOVE_ROW]: handleEntityFormRemoveRow,
  [ACTION_TYPES.ON_ENTITY_FORM_CHANGE]: handleEntityFormOnChange,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
  [ACTION_TYPES.ON_ASYNC_LOADED_OPTIONS]: handleListViewAsyncData,
};

export default ACTION_HANDLERS;
