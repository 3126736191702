import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

// Lodash
import _noop from 'lodash/noop';

// components
import Button from '@tekion/tekion-components/atoms/Button';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

// styles
import COLORS from '@tekion/tekion-styles-next/scss/variables.scss';
import styles from './colorButton.module.scss';

// Helpers
import { makeButtonStyles } from './colorButton.helpers';

function ColorButton(props) {
  const { color, onColorSelection, isColorSelected, className, disabled } = props;
  const handleColorSelection = useCallback(() => onColorSelection(color), [onColorSelection, color]);

  const buttonStyles = useMemo(() => makeButtonStyles(color), [color]);

  return (
    <Button className={cx(styles.colorShape, className)} onClick={handleColorSelection} style={buttonStyles} disabled={disabled}>
      {isColorSelected && (
        <FontIcon size={SIZES.S} color={COLORS.gunMetal}>
          icon-tick1
        </FontIcon>
      )}
    </Button>
  );
}

ColorButton.propTypes = {
  color: PropTypes.string,
  onColorSelection: PropTypes.func,
  isColorSelected: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

ColorButton.defaultProps = {
  color: undefined,
  onColorSelection: _noop,
  isColorSelected: false,
  className: '',
  disabled: false,
};

export default ColorButton;
