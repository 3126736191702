import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _concat from 'lodash/concat';
import _get from 'lodash/get';
import _find from 'lodash/find';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { getComponentData } from '../../../helpers/applicationBuilder.helpers';
import { COMPONENT_KEYS, PROPERTIES_FIELD_IDS, TAB_IDS, FIELD_IDS } from '../../../constants/applicationBuilder.constants';
import { COMPONENTS_TYPES } from '../../../../../../../../../constants/applicationRenderer.constants';

const getComponents = (components, properties) => {
  let allTabsData = [];

  const tabData = _compact(_map(components, (item) => getComponentData(item)));
  allTabsData = _concat(allTabsData, tabData);

  if (!_isEmpty(properties)) {
    allTabsData.push({ id: TAB_IDS.APPROVAL, displayName: _get(properties, PROPERTIES_FIELD_IDS.DISPLAY_NAME, 'Approvals') });
  }
  return allTabsData;
};

const getApplicationDetails = (applicationData) => {
  const components = _find(
    tget(applicationData, FIELD_IDS.COMPONENTS, EMPTY_ARRAY),
    (component) =>
      tget(component, COMPONENT_KEYS.TYPE) === COMPONENTS_TYPES.NAVIGATION || _get(component, COMPONENT_KEYS.TYPE) === COMPONENTS_TYPES.NO_NAVIGATION,
  );

  const isNavigationRequired = _get(components, COMPONENT_KEYS.TYPE) === COMPONENTS_TYPES.NAVIGATION;
  let allTabs = [];
  let navigationType;
  if (isNavigationRequired) {
    const properties = tget(applicationData, `properties.${PROPERTIES_FIELD_IDS.APPROVAL_META_DATA}`, {});
    if (!_isEmpty(components)) {
      navigationType = tget(components, `properties.${PROPERTIES_FIELD_IDS.NAVIGATION_TYPE}`);
    }
    allTabs = getComponents(tget(components, COMPONENT_KEYS.CHILDREN, EMPTY_ARRAY), properties);
  }

  return {
    navigationType,
    allTabs,
    isNavigationRequired,
  };
};
export { getApplicationDetails };
