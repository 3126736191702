const FIELD_IDS = {
  ID: 'id',
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  AUTH_PROTOCOL: 'authProtocol',
  TYPE: 'type',
  AUTH_PROVIDER_NAME: 'authProviderName',
  SCOPE: 'scope',
  USER_NAME: 'userName',
  PASSWORD: 'password',
  APPLICATION_NAME: 'applicationName',
  PLATFORM: 'platform',
  SERVICE: 'service',
  REGION: 'region',
  AWS_ACCOUNT_ID: 'awsAccountId',
  ACCESS_KEY: 'accessKey',
  SECRET_KEY: 'secretKey',
  PRE_SHARED_KEY: 'preSharedKey',
  SIGNING_ALGORITHM: 'signingAlgorithm',
  CREATE_AUTH_PROVIDER: 'createAuthProvider',
  HEADER_KEY: 'headerKey',
  API_KEY: 'apiKey',
};

const AUTH_TYPES = {
  BASIC_AUTH: 'BASIC_AUTH',
  OAUTH2: 'OAUTH2',
  AWS_SIGNATURE_4: 'AWS_SIGNATURE_4',
  NO_AUTH: 'NO_AUTH',
  APP_REGISTRY: 'APP_REGISTRY',
  PRE_SHARED_KEY: 'PRE_SHARED_KEY',
  API_KEY: 'API_KEY',
};

const AUTH_TYPE_TO_FIELDS = {
  [AUTH_TYPES.APP_REGISTRY]: [FIELD_IDS.APPLICATION_NAME, FIELD_IDS.PLATFORM],
  [AUTH_TYPES.BASIC_AUTH]: [FIELD_IDS.USER_NAME, FIELD_IDS.PASSWORD],
  [AUTH_TYPES.OAUTH2]: [FIELD_IDS.AUTH_PROVIDER_NAME, FIELD_IDS.SCOPE, FIELD_IDS.CREATE_AUTH_PROVIDER],
  [AUTH_TYPES.NO_AUTH]: [],
  [AUTH_TYPES.AWS_SIGNATURE_4]: [FIELD_IDS.SERVICE, FIELD_IDS.REGION, FIELD_IDS.AWS_ACCOUNT_ID, FIELD_IDS.ACCESS_KEY, FIELD_IDS.SECRET_KEY],
  [AUTH_TYPES.PRE_SHARED_KEY]: [FIELD_IDS.PRE_SHARED_KEY, FIELD_IDS.SIGNING_ALGORITHM],
  [AUTH_TYPES.API_KEY]: [FIELD_IDS.HEADER_KEY, FIELD_IDS.API_KEY],
};

const SIGNING_ALGORITHM_TYPES = {
  SHA_256: 'SHA_256',
  MD5: 'MD5',
};

const AUTH_TYPES_OPTIONS = [
  { label: __('Basic Auth'), value: AUTH_TYPES.BASIC_AUTH },
  { label: __('OAuth2'), value: AUTH_TYPES.OAUTH2 },
  { label: __('AWS Signature 4'), value: AUTH_TYPES.AWS_SIGNATURE_4 },
  { label: __('No Auth'), value: AUTH_TYPES.NO_AUTH },
  { label: __('App Registry'), value: AUTH_TYPES.APP_REGISTRY },
  { label: __('Pre Shared Key'), value: AUTH_TYPES.PRE_SHARED_KEY },
  { label: __('Api Key'), value: AUTH_TYPES.API_KEY },
];

const SIGNING_ALGORITHM_OPTIONS = [
  { label: __('SHA 256'), value: SIGNING_ALGORITHM_TYPES.SHA_256 },
  { label: __('MD5'), value: SIGNING_ALGORITHM_TYPES.MD5 },
];

const EXTERNAL_CREDENTIAL_FORM_CONTEXT_ID = 'EXTERNAL_CREDENTIAL_FORM_CONTEXT_ID';

const ROWS = 5;

export { FIELD_IDS, AUTH_TYPES_OPTIONS, AUTH_TYPES, EXTERNAL_CREDENTIAL_FORM_CONTEXT_ID, AUTH_TYPE_TO_FIELDS, SIGNING_ALGORITHM_OPTIONS, ROWS };
