import { FIELD_IDS } from './customActionsVisualConfigurator.constants';

const SECTIONS = [
  {
    rows: [
      {
        columns: [FIELD_IDS.ACTIONS_TO_SHOW],
      },
    ],
  },
];

export { SECTIONS };
