import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';

import ListInputTable from '../../listInputTable/ListInputTable';
import { FIELD_IDS } from '../../../constants/recordTypeOverride.constants';

const FIELD_NAME_FIELD = {
  id: FIELD_IDS.FIELD_NAME,
  renderer: SelectInput,
  renderOptions: {
    required: true,
    label: __('Select Field'),
    validators: [isRequiredRule],
    noOptionsMessage: () => __('No Available Fields to Override.First select available fields by click on edit icon .'),
  },
};

const ATTRIBUTE_FIELD = {
  id: FIELD_IDS.ATTRIBUTE,
  renderer: SelectInput,
  renderOptions: {
    required: true,
    isMulti: true,
    label: __('Select Attribute '),
    validators: [isRequiredRule],
  },
};

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Display Name'),
    validators: [isRequiredRule],
  },
};

const OPTION_FIELD = {
  id: FIELD_IDS.OPTIONS,
  renderer: ListInputTable,
  renderOptions: {
    required: true,
    validators: [isRequiredRule],
  },
};

const FORM_FIELDS = {
  [FIELD_IDS.FIELD_NAME]: FIELD_NAME_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.ATTRIBUTE]: ATTRIBUTE_FIELD,
  [FIELD_IDS.OPTIONS]: OPTION_FIELD,
};

const CONTEXT_ID = 'OVERRIDE_MODAL';

export { FIELD_IDS, FORM_FIELDS, CONTEXT_ID };
