import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import CheckboxRenderer from '@tekion/tekion-components/molecules/CellRenderers/CheckboxRenderer';

import { ACTION_TYPES } from '../../constants/entityFieldPermissionsConfigure.actionTypes';
import styles from './entityFieldPermissionTableColumnHeader.module.scss';

const EntityFieldPermissionTableColumnHeader = ({ id, data, name, checked, onAction, disabled }) => {
  const handleOnClickHeaderCheckBox = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CHANGE_PERMISSION_TABLE_HEADER, payload: { data, id, value: !checked } });
  }, [onAction, data, id, checked]);

  return (
    <div className={styles.columnHeaderContainer}>
      <CheckboxRenderer onClick={handleOnClickHeaderCheckBox} checked={checked} disabled={disabled} />
      <span className={styles.headerLabelContainer}>{__(name)}</span>
    </div>
  );
};

EntityFieldPermissionTableColumnHeader.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.array,
  onAction: PropTypes.func,
};

EntityFieldPermissionTableColumnHeader.defaultProps = {
  checked: false,
  disabled: false,
  id: EMPTY_STRING,
  name: EMPTY_STRING,
  data: EMPTY_ARRAY,
  onAction: _noop,
};

export default EntityFieldPermissionTableColumnHeader;
