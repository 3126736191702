import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import actionBuilderServices from '../services/actionBuilder.services';

const fetchActions = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await actionBuilderServices.fetchActions(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch actions, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchAction = async (actionName) => {
  try {
    const response = await actionBuilderServices.fetchAction(actionName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch action, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createAction = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await actionBuilderServices.createAction(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create action, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateAction = async (actionName, payload = EMPTY_OBJECT) => {
  try {
    const response = await actionBuilderServices.updateAction(actionName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update action, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const executeAction = async (actionName, payload) => {
  try {
    const response = await actionBuilderServices.executeAction(actionName, payload);

    const data = getDataFromResponse(response);

    if (!_isEmpty(data) || data === true) {
      if (!_isEmpty(_get(data, 'referenceId'))) {
        toaster(TOASTER_TYPE.SUCCESS, __('Request successfully sent for approval. '));
      } else {
        toaster(TOASTER_TYPE.SUCCESS, __('Action executed successfully!'));
      }
    }

    return data;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to execute action, please try again later.')));
    return { error: true, failedValidationName: _get(error, 'data.errorDetails.params.1', EMPTY_STRING) };
  }
};

const fetchApplicableActionsForRecords = async (entityName, payload) => {
  try {
    const response = await actionBuilderServices.fetchApplicableActionsForRecords(entityName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch actions, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchRecordForUpdateActionType = async (actionName, recordId) => {
  try {
    const response = await actionBuilderServices.fetchRecordForUpdateActionType(actionName, recordId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch record to update, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export { fetchActions, fetchAction, createAction, updateAction, executeAction, fetchApplicableActionsForRecords, fetchRecordForUpdateActionType };
