import produce from 'immer';

import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _has from 'lodash/has';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _isNil from 'lodash/isNil';
import _filter from 'lodash/filter';
import _snakeCase from 'lodash/snakeCase';
import _cloneDeep from 'lodash/cloneDeep';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import {
  areSearchOptionsValid,
  checkDeletedModuleIsSelectedAndLastModule,
  filterFormValueForDeletedTab,
  getAllSearchableFields,
  getFormFormattedSearchOptions,
  getRelationshipField,
  getRendererPropFormattedSearchOptions,
} from './searchOptionsConfigurator.helper';
import { getSimpleFieldNameFromColumn } from '../../../../../utils';

import ACTION_TYPES from '../constants/searchOptionsConfigurator.actionTypes';
import { FIELD_IDS, INITIAL_SEARCH_OPTION } from '../constants/searchOptionsConfigurator.constants';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../../constants/viewBuilder.constants';

import entityReader from '../../../../../readers/entity.reader';

const handleSelectedViewComponentPropertiesChange = ({ setState, params = EMPTY_OBJECT }) => {
  const { selectedViewComponentProperties } = params;
  const searchOptions = tget(selectedViewComponentProperties, [ALL_VIEW_PROPERTY_KEYS.SEARCH_OPTIONS], EMPTY_ARRAY);
  let allFormValues = [];

  if (_isEmpty(searchOptions)) {
    allFormValues = [INITIAL_SEARCH_OPTION];
  } else {
    allFormValues = getFormFormattedSearchOptions(searchOptions);
  }

  setState({ allFormValues, selectedModuleIndex: 0 });
};

const handleConfigureSearchOptionClick = ({ getState, setState }) => {
  const { allFormValues = EMPTY_OBJECT, selectedModuleIndex, entity = EMPTY_OBJECT } = getState();

  const fieldDefinitions = entityReader.fieldDefinitions(entity);
  const searchableFieldOptionsByRelationshipField = {};
  const searchableFieldOptions = getAllSearchableFields(fieldDefinitions, searchableFieldOptionsByRelationshipField);
  const relationshipField = getRelationshipField(selectedModuleIndex, allFormValues, searchableFieldOptionsByRelationshipField);

  setState({
    prevState: {
      allFormValues: _cloneDeep(allFormValues),
      selectedModuleIndex,
    },
    isModalVisible: true,
    searchableFieldOptions,
    searchableFieldOptionsByRelationshipField,
    relationshipField,
    errors: EMPTY_OBJECT,
  });
};

const handleAddSearchOptionsClick = ({ getState, setState }) => {
  const { allFormValues = [] } = getState();
  const allSearchOptions = _size(allFormValues);

  setState({
    allFormValues: [...allFormValues, INITIAL_SEARCH_OPTION],
    selectedModuleIndex: allSearchOptions,
    relationshipField: undefined,
    disableAutoFillForName: false,
  });
};

const handleModuleIndexChange = ({ getState, setState, params }) => {
  const { allFormValues = EMPTY_ARRAY, searchableFieldOptionsByRelationshipField } = getState();
  const { moduleIndex } = params;

  const relationshipField = getRelationshipField(moduleIndex, allFormValues, searchableFieldOptionsByRelationshipField);

  setState({ selectedModuleIndex: moduleIndex, errors: {}, relationshipField });
};

const handleOnChange = ({ getState, setState, params }) => {
  const { selectedModuleIndex, disableAutoFillForName = false } = getState();
  const { id, value } = params;

  setState(
    produce((draft) => {
      _set(draft, ['allFormValues', selectedModuleIndex, id], value);

      if (id === FIELD_IDS.DISPLAY_NAME && !disableAutoFillForName) {
        _set(draft, ['allFormValues', selectedModuleIndex, FIELD_IDS.NAME], _snakeCase(value));
      } else if (id === FIELD_IDS.NAME) {
        setState({ disableAutoFillForName: true });
      }
    }),
  );
};

const handleSubmit = ({ getState, setState }) => {
  const { allFormValues, onAction: configuratorOnAction } = getState();
  const newAllFormValues = _cloneDeep(allFormValues);

  const formattedFormValues = getRendererPropFormattedSearchOptions(newAllFormValues);

  const searchOptions = {
    [ALL_VIEW_PROPERTY_KEYS.SEARCH_OPTIONS]: formattedFormValues,
  };

  configuratorOnAction({
    type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
    payload: { id: ALL_VIEW_PROPERTY_KEYS.SEARCH_OPTIONS, value: searchOptions },
  });

  setState({ isModalVisible: false });
};

const handleErrors = ({ getState, setState, params }) => {
  const { selectedModuleIndex, allFormValues } = getState();
  let { errors } = params;

  const name = tget(allFormValues, [selectedModuleIndex, FIELD_IDS.NAME]);

  if (!_isEmpty(name)) {
    errors = _omit(errors, [FIELD_IDS.NAME]);
  }

  setState({ errors });
};

const handleValidationSuccess = ({ getState, setState, params }) => {
  const { selectedModuleIndex, allFormValues } = getState();
  const { additional } = params;
  const actionType = _get(additional, 'actionType');
  const payload = _get(additional, 'payload', {});
  const selectedFormValues = allFormValues[selectedModuleIndex] || {};
  const searchFields = tget(selectedFormValues, [FIELD_IDS.SEARCH_METADATA], EMPTY_ARRAY);

  const { isValid } = areSearchOptionsValid(searchFields);

  if (!isValid) {
    return;
  }

  switch (actionType) {
    case ACTION_TYPES.ON_CLICK_ADD_SEARCH_OPTION:
      handleAddSearchOptionsClick({ getState, setState });
      break;

    case ACTION_TYPES.ON_SAVE_MODAL:
      handleSubmit({ getState, setState });
      break;

    case ACTION_TYPES.ON_CHANGE_MODULE:
      handleModuleIndexChange({ getState, setState, params: payload });
      break;

    default:
      break;
  }
};

const handleDeleteRow = ({ getState, setState, params }) => {
  const { allFormValues, selectedModuleIndex } = getState();
  const { moduleIndex } = params;
  let newSelectedModuleIndex = selectedModuleIndex;

  const newAllFormValues = filterFormValueForDeletedTab(allFormValues, moduleIndex);

  if (checkDeletedModuleIsSelectedAndLastModule(moduleIndex, selectedModuleIndex, allFormValues)) {
    newSelectedModuleIndex = moduleIndex - 1;
  } else if (moduleIndex < selectedModuleIndex) {
    newSelectedModuleIndex -= 1;
  }

  setState({ allFormValues: newAllFormValues }, () => {
    handleModuleIndexChange({ getState, setState, params: { moduleIndex: newSelectedModuleIndex } });
  });
};

const handleCancelModal = ({ getState, setState }) => {
  const { prevState = EMPTY_OBJECT } = getState();

  setState({ isModalVisible: false, ...prevState });
};

const handleTableAddRow = ({ getState, setState }) => {
  const { selectedModuleIndex, allFormValues } = getState();
  const newAllFormValues = [...allFormValues];
  const selectedFormValues = allFormValues[selectedModuleIndex] || {};
  const searchFields = tget(selectedFormValues, FIELD_IDS.SEARCH_METADATA, []);

  _set(newAllFormValues, [selectedModuleIndex, FIELD_IDS.SEARCH_METADATA], [...searchFields, { [FIELD_IDS.BOOST]: 1 }]);

  setState({ allFormValues: newAllFormValues });
};

const handleTableDeleteRow = ({ getState, setState, params }) => {
  const { selectedModuleIndex, allFormValues, searchableFieldOptionsByRelationshipField } = getState();
  const rowIndex = _get(params, 'rowIndex');

  if (!_isNil(rowIndex)) {
    const newAllFormValues = [...allFormValues];
    const selectedFormValues = allFormValues[selectedModuleIndex] || {};
    let searchFields = tget(selectedFormValues, FIELD_IDS.SEARCH_METADATA, []);
    const deletedFieldName = tget(searchFields, [rowIndex, FIELD_IDS.FIELD]);
    searchFields = _filter(searchFields, (data, index) => index !== rowIndex);

    if (_size(searchFields) === 0 && deletedFieldName) {
      const simpleField = getSimpleFieldNameFromColumn(deletedFieldName);

      if (_has(searchableFieldOptionsByRelationshipField, simpleField)) {
        setState({ relationshipField: undefined });
      }
    }

    _set(newAllFormValues, [selectedModuleIndex, FIELD_IDS.SEARCH_METADATA], searchFields);
    setState({ allFormValues: newAllFormValues });
  }
};

const handleTableOnChange = ({ getState, setState, params }) => {
  const { selectedModuleIndex, relationshipField, allFormValues, searchableFieldOptionsByRelationshipField = EMPTY_OBJECT } = getState();
  const rowIndex = _get(params, 'rowIndex');
  const id = _get(params, 'id');
  const value = _get(params, 'value');

  if (!_isNil(rowIndex) && id) {
    const newAllFormValues = _cloneDeep(allFormValues);
    const simpleField = getSimpleFieldNameFromColumn(value);

    if (id === FIELD_IDS.FIELD && !relationshipField && _has(searchableFieldOptionsByRelationshipField, simpleField)) {
      _set(
        newAllFormValues,
        [selectedModuleIndex, FIELD_IDS.SEARCH_METADATA],
        [
          {
            [FIELD_IDS.FIELD]: value,
            [FIELD_IDS.BOOST]: 1,
          },
        ],
      );

      setState({ relationshipField: simpleField });
    } else {
      _set(newAllFormValues, [selectedModuleIndex, FIELD_IDS.SEARCH_METADATA, rowIndex, id], value);
    }

    setState({ allFormValues: newAllFormValues });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE]: handleSelectedViewComponentPropertiesChange,
  [ACTION_TYPES.ON_CLICK_CONFIGURE_SEARCH_OPTIONS]: handleConfigureSearchOptionClick,
  [ACTION_TYPES.ON_CLICK_ADD_SEARCH_OPTION]: handleAddSearchOptionsClick,
  [ACTION_TYPES.ON_CHANGE_MODULE]: handleModuleIndexChange,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleValidationSuccess,
  [ACTION_TYPES.ON_SAVE_MODAL]: handleSubmit,
  [ACTION_TYPES.DELETE_ROW]: handleDeleteRow,
  [ACTION_TYPES.ON_CANCEL_MODAL]: handleCancelModal,
  [ACTION_TYPES.TABLE_ADD_ROW]: handleTableAddRow,
  [ACTION_TYPES.TABLE_REMOVE_ROW]: handleTableDeleteRow,
  [ACTION_TYPES.TABLE_ON_CHANGE]: handleTableOnChange,
};

export default ACTION_HANDLERS;
