const AUTH_CONFIG_FORM_CONTEXT_ID = 'AUTH_CONFIG_FORM_CONTEXT_ID';
const MFA_AUTH_TYPES = {
  SMS: 'SMS',
  EMAIL: 'EMAIL',
};

const MFA_AUTH_TYPE_OPTIONS = [
  { label: __(MFA_AUTH_TYPES.SMS), value: MFA_AUTH_TYPES.SMS },
  { label: __(MFA_AUTH_TYPES.EMAIL), value: MFA_AUTH_TYPES.EMAIL },
];

export { AUTH_CONFIG_FORM_CONTEXT_ID, MFA_AUTH_TYPE_OPTIONS };
