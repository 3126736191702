import { defaultMemoize } from 'reselect';
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import RecordSharingRuleUserDeleteCellIconRenderer from '../../recordSharingRuleUserDeleteCellIconRenderer';
import workspaceUserReader from '../../../../../../../readers/workSpaceUser.reader';

const USER_TABLE_COLUMN_ID = {
  USER_NAME: 'name',
  ROLE: 'role',
  DELETE_USER: 'deleteUser',
};

const USER_GROUP_TABLE_COLUMN_ID = {
  USER_GROUP_NAME: 'displayName',
  TOTAL_USERS: 'totalUsers',
  DELETE_USER_GROUP: 'deleteUserGroup',
};

const getUserGroupColumns = defaultMemoize((assignType) => [
  {
    id: USER_GROUP_TABLE_COLUMN_ID.USER_GROUP_NAME,
    Header: __('Group Name'),
    accessor: USER_GROUP_TABLE_COLUMN_ID.USER_GROUP_NAME,
    Cell: TextRenderer,
  },
  {
    id: USER_GROUP_TABLE_COLUMN_ID.TOTAL_USERS,
    Header: __('Total Users'),
    accessor: USER_GROUP_TABLE_COLUMN_ID.TOTAL_USERS,
    Cell: TextRenderer,
  },
  {
    id: USER_GROUP_TABLE_COLUMN_ID.DELETE_USER_GROUP,
    Header: __('Delete'),
    accessor: USER_GROUP_TABLE_COLUMN_ID.DELETE_USER_GROUP,
    Cell: RecordSharingRuleUserDeleteCellIconRenderer,
    minWidth: 50,
    getProps: (props) => ({
      ...props,
      assignType,
    }),
  },
]);

const getUserColumns = defaultMemoize((assignType) => [
  {
    id: USER_TABLE_COLUMN_ID.USER_NAME,
    Header: __('User Name'),
    accessor: workspaceUserReader.NAME,
    Cell: TextRenderer,
  },
  {
    id: USER_TABLE_COLUMN_ID.ROLE,
    Header: __('Role'),
    accessor: USER_TABLE_COLUMN_ID.ROLE,
    Cell: TextRenderer,
  },
  {
    id: USER_TABLE_COLUMN_ID.DELETE_USER,
    Header: __('Delete'),
    accessor: USER_TABLE_COLUMN_ID.DELETE_USER,
    Cell: RecordSharingRuleUserDeleteCellIconRenderer,
    minWidth: 50,
    getProps: (props) => ({
      ...props,
      assignType,
    }),
  },
]);

export { getUserGroupColumns, getUserColumns };
