import _get from 'lodash/get';
import _has from 'lodash/has';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _set from 'lodash/set';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { searchComments, createComment, updateCommentById, deleteCommentById } from '../../../actions/comments.actions';
import { fetchEntityRecords } from '../../../actions/recordManagement.actions';

import {
  setRepliesInCommentsTree,
  removeCommentFromCommentsTree,
  findCommentById,
  getRequiredUserDetails,
  updatedCommentModeInCommentsTree,
  findCommentObjAndIndexById,
  updateCommentMode,
  transformHtmlCommentToPlainText,
} from './commentRenderer.helpers';
import { getPayloadForComments, getPayloadForUsers } from './commentRenderer.payload.helpers';

import ACTION_TYPES from '../constants/commentRenderer.actionTypes';
import { COMMENT_FIELDS, COMMENT_ROWS, REPLY_ROWS } from '../constants/commentRenderer.constants';
import { STANDARD_ENTITY_NAME } from '../../../constants/general.constants';

import commentReader from '../../../readers/comment.reader';

const getUserDetails = async (commentsList, userDetailsById) => {
  let userIds = _uniq(_map(commentsList, (comment) => _get(comment, 'createdBy')));
  userIds = _filter(userIds, (userId) => !_has(userDetailsById, userId));

  if (!_isEmpty(userIds)) {
    const payload = getPayloadForUsers(userIds);
    const userResponse = await fetchEntityRecords(STANDARD_ENTITY_NAME.USER, payload);
    const userDetails = _get(userResponse, 'hits', []);
    return getRequiredUserDetails(userDetails);
  }
  return EMPTY_OBJECT;
};

const handleFetchCommentsAndUserDetails = async ({ payloadForComments, userDetailsById }) => {
  const response = await searchComments(payloadForComments);
  const totalCount = _get(response, 'count');
  const newNextPageToken = _get(response, 'nextPageToken');

  let newCommentsTree = tget(response, 'hits', []);
  newCommentsTree = transformHtmlCommentToPlainText(newCommentsTree);
  newCommentsTree = updatedCommentModeInCommentsTree(newCommentsTree);
  const userDetails = await getUserDetails(newCommentsTree, userDetailsById);
  const newUserDetailsById = _keyBy(userDetails, 'id');

  return { newCommentsTree, newUserDetailsById, newNextPageToken, totalCount };
};

const handleInit = async ({ getState, setState, params = {} }) => {
  const { assetType, assetId, commentsTree = [], userDetailsById = EMPTY_OBJECT } = getState();

  const { nextPageToken = null, rows = COMMENT_ROWS } = params;

  setState({ areCommentsLoading: true });

  const payloadForComments = getPayloadForComments(assetType, assetId, [null], nextPageToken, rows);

  const { newCommentsTree, newUserDetailsById, newNextPageToken, totalCount } = await handleFetchCommentsAndUserDetails({
    payloadForComments,
    userDetailsById,
  });

  const updatedCommentsTree = nextPageToken || _isEmpty(newCommentsTree) ? [...commentsTree, ...newCommentsTree] : newCommentsTree;

  setState({
    commentsTree: updatedCommentsTree,
    userDetailsById: { ...userDetailsById, ...newUserDetailsById },
    nextPageToken: _size(updatedCommentsTree) < totalCount ? newNextPageToken : null,
    areCommentsLoading: false,
  });
};

const handleShowReplies = async ({ getState, setState, params = {} }) => {
  const { assetType, assetId, commentsTree, userDetailsById } = getState();
  const { commentId, nextPageToken = null, rows = REPLY_ROWS, setAreRepliesLoading = _noop } = params;

  const payloadForComments = getPayloadForComments(assetType, assetId, [commentId], nextPageToken, rows);

  const {
    newCommentsTree: repliesList,
    newUserDetailsById,
    newNextPageToken,
    totalCount,
  } = await handleFetchCommentsAndUserDetails({
    payloadForComments,
    userDetailsById,
  });

  const updatedNextPageToken = _size(repliesList) < totalCount ? newNextPageToken : null;

  const updatedCommentsTree = setRepliesInCommentsTree(commentId, commentsTree, repliesList, nextPageToken, updatedNextPageToken);

  setState(
    {
      commentsTree: updatedCommentsTree,
      userDetailsById: { ...userDetailsById, ...newUserDetailsById },
    },
    () => setAreRepliesLoading(false),
  );
};

const handleLoadMoreComments = async ({ getState, setState, params = {} }) => {
  const { commentId, setLoadingMoreComments = _noop } = params;
  const { nextPageToken, commentsTree } = getState();

  if (_isEmpty(commentId)) {
    await handleInit({
      getState,
      setState,
      params: {
        nextPageToken,
        rows: COMMENT_ROWS,
      },
    });
  } else {
    const comment = findCommentById(commentsTree, commentId);
    const nextPageTokenForReply = commentReader.nextPageToken(comment);
    await handleShowReplies({ getState, setState, params: { commentId, nextPageToken: nextPageTokenForReply, rows: REPLY_ROWS } });
  }

  setLoadingMoreComments(false);
};

const handleChangeCommentMode = ({ getState, setState, params = {} }) => {
  const { commentsTree } = getState();
  const { commentMode, commentPath } = params;
  const { commentIndex, parentCommentIndex } = commentPath;

  const newCommentsTree = updateCommentMode(commentsTree, commentIndex, parentCommentIndex, commentMode);

  setState({ commentsTree: newCommentsTree });
};

const handleCreateComment = async ({ getState, setState, params = {} }) => {
  const { assetType, assetId, commentsTree = EMPTY_ARRAY } = getState();
  const { content, inReplyTo, scrollToTopComment = _noop } = params;

  const payloadForCreateComment = {
    assetType,
    assetId,
    inReplyTo,
    content,
  };

  const response = await createComment(payloadForCreateComment);
  const commentId = commentReader.id(response);

  if (!_isEmpty(commentId)) {
    if (_isEmpty(inReplyTo)) {
      await handleInit({
        getState,
        setState,
        params: {
          nextPageToken: null,
          rows: _size(commentsTree) + 1,
        },
      });
    } else {
      const parentComment = findCommentById(commentsTree, inReplyTo);
      const replies = commentReader.replies(parentComment) || [];
      await handleShowReplies({ getState, setState, params: { commentId: inReplyTo, nextPageToken: null, rows: _size(replies) + 1 } });
    }
    scrollToTopComment();
  }
};

const handleEditComment = async ({ getState, setState, params = {} }) => {
  const { commentsTree } = getState();
  const { commentId, content } = params;

  const response = await updateCommentById(commentId, {
    content,
  });
  const inReplyTo = commentReader.inReplyTo(response);

  if (!_isEmpty(commentId)) {
    if (_isEmpty(inReplyTo)) {
      await handleInit({ getState, setState, params: { nextPageToken: null, rows: _size(commentsTree) } });
    } else {
      await handleShowReplies({ getState, setState, params: { commentId: inReplyTo, nextPageToken: null, rows: REPLY_ROWS } });
    }
  }
};

const handleDeleteComment = async ({ getState, setState, params = {} }) => {
  const { commentsTree } = getState();
  const { commentId, inReplyTo } = params;
  let newCommentsTree = [...commentsTree];

  const response = await deleteCommentById(commentId);
  if (response) {
    newCommentsTree = removeCommentFromCommentsTree(newCommentsTree, commentId, inReplyTo);
    setState({ commentsTree: newCommentsTree });
  }
};

const handleHideReplies = async ({ getState, setState, params = {} }) => {
  const { commentsTree } = getState();
  const { comment, commentIndex } = params;
  const newCommentsTree = [...commentsTree];
  const newComment = { ...comment };

  _set(newComment, COMMENT_FIELDS.SHOW_REPLIES_ENABLED, false);
  _set(newCommentsTree, [commentIndex], newComment);

  setState({
    commentsTree: newCommentsTree,
  });
};

const handleToggleReplyInput = ({ getState, setState, params = {} }) => {
  const { commentsTree } = getState();
  const { commentId, scrollToCommentInput = _noop } = params;
  const newCommentsTree = [...commentsTree];

  const { index, comment } = findCommentObjAndIndexById(newCommentsTree, commentId);

  const replies = commentReader.replies(comment);
  const prevValue = commentReader.showReplyInput(comment);

  if (_isEmpty(replies)) {
    _set(comment, COMMENT_FIELDS.SHOW_REPLY_INPUT, !prevValue);
  } else {
    _set(comment, COMMENT_FIELDS.SHOW_REPLIES_ENABLED, true);
  }

  _set(newCommentsTree, [index], comment);

  setState(
    {
      commentsTree: newCommentsTree,
    },
    () => scrollToCommentInput(),
  );
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: handleInit,
  [ACTION_TYPES.LOAD_MORE_COMMENTS]: handleLoadMoreComments,
  [ACTION_TYPES.CREATE_COMMENT]: handleCreateComment,
  [ACTION_TYPES.EDIT_COMMENT]: handleEditComment,
  [ACTION_TYPES.DELETE_COMMENT]: handleDeleteComment,
  [ACTION_TYPES.SHOW_REPLIES]: handleShowReplies,
  [ACTION_TYPES.HIDE_REPLIES]: handleHideReplies,
  [ACTION_TYPES.TOGGLE_REPLY_INPUT]: handleToggleReplyInput,
  [ACTION_TYPES.CHANGE_COMMENT_MODE]: handleChangeCommentMode,
};
export default ACTION_HANDLERS;
