import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _size from 'lodash/size';
import _map from 'lodash/map';

// Components
import InputTable from '@tekion/tekion-components/organisms/inputTable/containers/withRowActions/Table';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Button from '@tekion/tekion-components/atoms/Button';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { getColumns } from './helper/sortFieldsTable.columns';

import { ROW_ACTION_PROPS, ROW_ACTIONS, TABLE_ACTION_TYPES, COLUMN_IDS } from './constants/sortFieldsTable.general';
import ACTION_TYPES from '../../constants/sortOptionsConfigurator.actionTypes';

import styles from '../../sortOptionsConfigurator.module.scss';

const SortFieldsTable = ({ data, sortableFieldOptions, onAction }) => {
  const selectedSortOptions = useMemo(() => _map(data, (sortOption) => getArraySafeValue(tget(sortOption, [COLUMN_IDS.SORT_FIELD]))), [data]);

  const columns = useMemo(() => getColumns(sortableFieldOptions, selectedSortOptions), [sortableFieldOptions, selectedSortOptions]);

  const handleAction = useCallback(
    (action) => {
      const tableActionType = _get(action, 'type', EMPTY_STRING);
      const rowIndex = _get(action, 'payload.nestingPath.[0]');
      const id = _get(action, 'payload.id');
      const value = _get(action, 'payload.value');

      if (tableActionType === TABLE_ACTION_TYPES.ON_CHANGE) {
        onAction({
          type: ACTION_TYPES.TABLE_ON_CHANGE,
          payload: { rowIndex, id, value },
        });
      } else if (tableActionType === TABLE_ACTION_TYPES.TABLE_ACTION_CLICK) {
        const actionType = _get(action, 'payload.actionType');

        onAction({
          type: actionType,
          payload: { rowIndex },
        });
      }
    },
    [onAction],
  );

  const handleClick = useCallback(() => {
    onAction({ type: ACTION_TYPES.TABLE_ADD_ROW });
  }, [onAction]);

  return (
    <div>
      <span className={styles.defaultSortInputTableHeading}>{__('Default Sort Options')}</span>
      <InputTable columns={columns} value={data} rowActionProps={ROW_ACTION_PROPS} getActionsForRow={ROW_ACTIONS} onAction={handleAction} />
      <Button disabled={_size(data) === _size(sortableFieldOptions)} view={Button.VIEW.TERTIARY} className={styles.addButton} onClick={handleClick}>
        {__('+Add Field')}
      </Button>
    </div>
  );
};

SortFieldsTable.propTypes = {
  data: PropTypes.array,
  sortableFieldOptions: PropTypes.array,
  onAction: PropTypes.func,
};

SortFieldsTable.defaultProps = {
  data: EMPTY_ARRAY,
  sortableFieldOptions: EMPTY_ARRAY,
  onAction: PropTypes.func,
};

export default React.memo(SortFieldsTable);
