import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import SwitchRenderer, { LABEL_POSITIONS } from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch';

import { COLUMN_IDS } from '../../constants/wwdPermissionList.constants';
import { ACCESS_TYPE_OPTIONS } from './wwdPermissionEditModal.constants';

const ACCESS_TYPE_FIELD = {
  id: COLUMN_IDS.ACCESS_TYPE,
  renderer: SelectInput,
  renderOptions: {
    required: true,
    label: __('Access Permission'),
    validators: [isRequiredRule],
    options: ACCESS_TYPE_OPTIONS,
  },
};

const ROLE_HIERARCHY_FIELD = {
  id: COLUMN_IDS.DETAIL_VIEW,
  renderer: SwitchRenderer,
  renderOptions: {
    label: __('Role Hierarchy'),
    labelPosition: LABEL_POSITIONS.BEFORE,
  },
};

const FORM_FIELDS = {
  [COLUMN_IDS.ACCESS_TYPE]: ACCESS_TYPE_FIELD,
  [COLUMN_IDS.ROLE_HIERARCHY]: ROLE_HIERARCHY_FIELD,
};

const FORM_SECTION = [
  {
    rows: [
      {
        columns: [COLUMN_IDS.ACCESS_TYPE],
      },
      {
        columns: [COLUMN_IDS.ROLE_HIERARCHY],
      },
    ],
  },
];

export { FORM_SECTION, FORM_FIELDS };
