import _get from 'lodash/get';
import _find from 'lodash/find';
import _noop from 'lodash/noop';

import { OPTION_KEYS } from './builders/menuOptionBuilder';

const getValuesFromOption = (option) => {
  const label = option[OPTION_KEYS.LABEL];
  const id = option[OPTION_KEYS.ID];
  const disabled = option[OPTION_KEYS.DISABLED];
  const disableMessage = option[OPTION_KEYS.DISABLE_MESSAGE];

  return { label, id, disabled, disableMessage };
};

// For handling event object when menu is clicked
const handleMenuClickEvent = (event) => {
  event.stopPropagation();
};

// For handling menu click
const handleMenuClick = (id, menuOptions) => {
  const option = _find(menuOptions, { [OPTION_KEYS.ID]: id });

  const _onClick = _get(option, OPTION_KEYS.ON_CLICK, _noop);
  _onClick(option);
};

// Entry level function of menu click
const handleMenuOptionClick = (action, menuOptions) => {
  const id = _get(action, 'key', '');
  const event = _get(action, 'domEvent');

  // Handling event
  handleMenuClickEvent(event);

  // Handling menu click
  handleMenuClick(id, menuOptions);
};

export { getValuesFromOption, handleMenuOptionClick };
