import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';

import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import statusUpdateActions from './statusUpdateActions';

import communicationServices from '../../services/communication.services';

const fetchUnreadCount = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await communicationServices.fetchUnreadCount(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch unread count, please try again later.')));
    return undefined;
  }
};

const fetchGroupedNotifications = async (payload = {}) => {
  try {
    const response = await communicationServices.fetchLatestCommunicationForAllGroups(payload);
    // TODO: Mark all incoming communications as SEEN

    return { hits: getDataFromResponse(response), isErrored: false };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch unread notifications, please try again later.')));
    return { hits: EMPTY_ARRAY, isErrored: true };
  }
};

const fetchNotifications = async (payload) => {
  try {
    const response = await communicationServices.fetchCommunications(payload);
    const data = getDataFromResponse(response);
    const hits = tget(data, 'hits', EMPTY_ARRAY);
    const nextPageToken = tget(data, 'nextPageToken');
    // TODO: Mark all incoming communications as SEEN

    return {
      hits,
      isErrored: false,
      nextPageToken,
    };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch more, please try again later.')));

    return {
      hits: EMPTY_ARRAY,
      isErrored: true,
      nextPageToken: undefined,
    };
  }
};

export default { fetchUnreadCount, fetchGroupedNotifications, fetchNotifications, ...statusUpdateActions };
