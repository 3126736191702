import _size from 'lodash/size';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _isNil from 'lodash/isNil';
import _map from 'lodash/map';
import _unionBy from 'lodash/unionBy';
import _keyBy from 'lodash/keyBy';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import {
  getProjectByName,
  addApplicationsToProject,
  removeApplicationFromProject,
  fetchApplicationsForProject,
} from '../../../../../actions/workspaceProjectManagement.actions';
import { getApplicationList } from '../../../../../actions/applicationManagement.actions';
import { getPayload } from './projectApplicationList.helpers';

import ACTION_TYPES from '../constants/projectApplicationList.actionTypes';
import { STUDIO_ROUTE } from '../../../../../constants/routes';
import PAGE_IDS from '../../../constants/PageIds.constants';
import { APPLICATION_CHECKBOX_LENGTH, APPLICATION_CONTAINER_LENGTH, MODAL_DIRECTION } from '../constants/projectApplicationList.constants';

const handleInit = async ({ getState, setState }) => {
  const { history = EMPTY_OBJECT, match = EMPTY_OBJECT } = getState();
  let projectData = _get(history, 'location.state.projectData', EMPTY_ARRAY);
  const projectName = _get(match, 'params.projectName', EMPTY_STRING);

  setState({ loading: true });

  if (!_has(history, 'location.state.projectData')) {
    projectData = await getProjectByName(projectName);
  }

  const projectApplications = await fetchApplicationsForProject(projectName);

  setState({
    loading: false,
    selectedApplications: EMPTY_ARRAY,
    applicationList: EMPTY_ARRAY,
    projectName,
    projectData,
    projectApplications,
    totalCount: _size(projectApplications),
  });
};

const handleAddApplicationModal = async ({ getState, setState, params }) => {
  const { projectApplications, nextPageToken, applicationList, searchText, mapNameToApplication, selectedApplications } = getState();
  const currentSearchText = _get(params, 'searchText', EMPTY_STRING);

  if (_isEmpty(nextPageToken) && currentSearchText === searchText) {
    return;
  }

  setState({ isApplicationModalVisible: true });

  if (_isNil(mapNameToApplication)) {
    setState({ isApplicationLoading: true });
  }

  let pageToken = nextPageToken;
  let filteredApplications = EMPTY_OBJECT;
  let applications = EMPTY_ARRAY;
  let nameToApplication = EMPTY_OBJECT;
  let mapSelectedApplications = EMPTY_OBJECT;

  if (currentSearchText === searchText || _isNil(searchText)) {
    applications = [...applicationList];
  } else {
    pageToken = EMPTY_STRING;
  }

  const payload = getPayload(currentSearchText, pageToken);

  const response = await getApplicationList(payload);
  filteredApplications = tget(response, 'hits', EMPTY_ARRAY);

  if (!_isNil(projectApplications)) {
    mapSelectedApplications = _reduce(
      projectApplications,
      (prevSelectedApplications, application) => {
        const newSelectedApplications = { ...prevSelectedApplications };
        const name = _get(application, 'name', EMPTY_STRING);
        if (!_isEmpty(name)) {
          newSelectedApplications[name] = true;
        }
        return newSelectedApplications;
      },
      EMPTY_OBJECT,
    );
  }

  filteredApplications = _filter(filteredApplications, (application) => {
    const name = _get(application, 'name');
    return name && !mapSelectedApplications[name];
  });

  nameToApplication = _keyBy(filteredApplications, 'name');

  let selectedApplicationList = [];
  if (!_isNil(selectedApplications) && !_isNil(mapNameToApplication)) {
    selectedApplicationList = _map(selectedApplications, (application) => {
      const applicationObject = mapNameToApplication[application];
      return applicationObject;
    });
  }

  applications = _unionBy(applications, filteredApplications, selectedApplicationList, 'name');

  if (!_isNil(mapNameToApplication)) {
    nameToApplication = { ...nameToApplication, ...mapNameToApplication };
  }

  if (
    (applications.length - selectedApplications.length) * APPLICATION_CHECKBOX_LENGTH <= APPLICATION_CONTAINER_LENGTH &&
    _isEmpty(currentSearchText)
  ) {
    handleAddApplicationModal({ getState, setState, params });
  }

  setState({
    searchText: currentSearchText,
    applicationList: applications,
    nextPageToken: _get(response, 'nextPageToken', EMPTY_STRING),
    mapNameToApplication: nameToApplication,
    isApplicationLoading: false,
  });
};

const handleCancelModal = ({ setState }) => {
  setState({
    mapNameToApplication: null,
    nextPageToken: undefined,
    applicationList: EMPTY_ARRAY,
    selectedApplications: EMPTY_ARRAY,
    isApplicationModalVisible: false,
    searchText: undefined,
    isSaveLoading: false,
  });
};

const handleScroll = ({ getState, setState, params }) => {
  const direction = _get(params, 'direction', MODAL_DIRECTION.LEFT);

  if (direction === MODAL_DIRECTION.LEFT) {
    handleAddApplicationModal({ getState, setState });
  }
};

const handleTransferItem = ({ getState, setState, params }) => {
  const { newSelectedApplications } = params;
  const { applicationList } = getState();

  setState({ selectedApplications: newSelectedApplications, filteredSelectedApplications: newSelectedApplications });

  if ((applicationList.length - newSelectedApplications.length) * APPLICATION_CHECKBOX_LENGTH <= APPLICATION_CONTAINER_LENGTH) {
    handleScroll({ getState, setState });
  }
};

const handleEditClick = ({ getState }) => {
  const { history, projectData } = getState();
  const projectName = _get(projectData, 'name', EMPTY_STRING);
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.PROJECT_EDIT}/${projectName}`;

  history.push({ pathname, state: { projectInfo: projectData } });
};

const handleSaveApplications = async ({ getState, setState }) => {
  const { projectName, selectedApplications } = getState();

  setState({ isSaveLoading: true });

  if (!_isEmpty(selectedApplications)) {
    const response = await addApplicationsToProject(projectName, selectedApplications);
    if (response === 'success') {
      handleInit({ setState, getState });
    }
  }

  handleCancelModal({ setState });
};

const handleRemoveApplication = async ({ getState, setState, params }) => {
  const { projectName } = getState();
  const applicationName = _get(params, 'name', EMPTY_STRING);

  const response = await removeApplicationFromProject(projectName, applicationName);

  if (response === 'success') {
    handleInit({ setState, getState });
  }
};

const handleSearch = ({ getState, setState, params }) => {
  const searchText = _get(params, 'searchText', EMPTY_STRING);
  const direction = _get(params, 'direction', EMPTY_STRING);

  if (direction === MODAL_DIRECTION.LEFT) {
    handleAddApplicationModal({ getState, setState, params: { direction, searchText } });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT_DATA]: handleInit,
  [ACTION_TYPES.ADD_APPLICATION]: handleAddApplicationModal,
  [ACTION_TYPES.ON_SAVE_MODAL]: handleSaveApplications,
  [ACTION_TYPES.ON_CANCEL_MODAL]: handleCancelModal,
  [ACTION_TYPES.ON_TRANSFER_ITEM]: handleTransferItem,
  [ACTION_TYPES.ON_CLICK_EDIT]: handleEditClick,
  [ACTION_TYPES.REMOVE_APPLICATION]: handleRemoveApplication,
  [ACTION_TYPES.ON_SCROLL]: handleScroll,
  [ACTION_TYPES.ON_SEARCH]: handleSearch,
};

export default ACTION_HANDLERS;
