import DATA_TYPES from '../../../../../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../../../../../constants/fieldDefinition.fieldTypes';
import GENERAL_DETAILS_FORM_FIELD_IDS from './generalDetailsForm.fieldIds';

const FIELD_TYPES_LABELS = {
  [FIELD_TYPES.TEXT]: __('Input'),
  [FIELD_TYPES.RANGE]: __('Range'),
  [FIELD_TYPES.RELATIONSHIP]: __('Relationship'),
  [FIELD_TYPES.SELECT]: __('Select'),
  [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: __('Child Aggregate Summary'),
  [FIELD_TYPES.RICH_TEXT_EDITOR]: __('Text Editor'),
  [FIELD_TYPES.MEDIA]: __('Media'),
};

const FIELD_DATA_TYPES_LABELS = {
  [DATA_TYPES.TEXT]: __('Text'),
  [DATA_TYPES.NUMBER]: __('Number'),
  [DATA_TYPES.DATE]: __('Date'),
  [DATA_TYPES.DATE_TIME]: __('Date Time'),
  [DATA_TYPES.BOOLEAN]: __('Boolean'),
  [DATA_TYPES.COMPLEX]: __('Complex'),
};

const PLACEHOLDERS = {
  [FIELD_TYPES.RELATIONSHIP]: __('Select'),
  [DATA_TYPES.TEXT]: {
    [FIELD_TYPES.TEXT]: __('Enter text'),
    [FIELD_TYPES.SELECT]: __('Select option'),
    [FIELD_TYPES.RELATIONSHIP]: __('Select'),
  },
  [DATA_TYPES.NUMBER]: {
    [FIELD_TYPES.TEXT]: __('Enter number'),
    [FIELD_TYPES.RANGE]: __('Enter number range'),
  },
  [DATA_TYPES.DATE]: {
    [FIELD_TYPES.RANGE]: __('Select date range'),
    [FIELD_TYPES.TEXT]: __('Select date'),
  },
  [DATA_TYPES.DATE_TIME]: {
    [FIELD_TYPES.TEXT]: __('Select date time'),
  },
};

const FIELD_TYPES_WITHOUT_MULTIVALUED = [FIELD_TYPES.RICH_TEXT_EDITOR, FIELD_TYPES.RANGE, FIELD_TYPES.CHILD_AGGREGATE_SUMMARY];

const DATA_TYPES_WITHOUT_MULTIVALUED = [DATA_TYPES.BOOLEAN, DATA_TYPES.COMPLEX];

const FIELD_TYPES_WITHOUT_PLACEHOLDER = [FIELD_TYPES.MEDIA, FIELD_TYPES.CHILD_AGGREGATE_SUMMARY, FIELD_TYPES.RICH_TEXT_EDITOR];

const DATA_TYPES_WITHOUT_PLACEHOLDER = [DATA_TYPES.BOOLEAN, DATA_TYPES.COMPLEX];

const FIELD_IDS_WITH_VARYING_RENDER_OPTIONS = [GENERAL_DETAILS_FORM_FIELD_IDS.MULTI_VALUED];

export {
  FIELD_TYPES_LABELS,
  FIELD_DATA_TYPES_LABELS,
  FIELD_TYPES_WITHOUT_MULTIVALUED,
  DATA_TYPES_WITHOUT_MULTIVALUED,
  FIELD_TYPES_WITHOUT_PLACEHOLDER,
  DATA_TYPES_WITHOUT_PLACEHOLDER,
  FIELD_IDS_WITH_VARYING_RENDER_OPTIONS,
  PLACEHOLDERS,
};
