import { compose } from 'recompose';
import { defaultMemoize } from 'reselect';
import _property from 'lodash/property';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _nthArg from 'lodash/nthArg';
import _get from 'lodash/get';
import _map from 'lodash/map';

// Validators
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

// Components
import ColumnConfig from '@tekion/tekion-components/organisms/inputTable/builders/ColumnConfig';
import CellConfig from '@tekion/tekion-components/organisms/inputTable/builders/CellConfig';
import SelectInputCellRenderer from 'tcomponents/organisms/inputTable/cellRenderers/SelectInputCellRenderer';

// Constants
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { DEPENDENCY_OPTIONS } from '../constants/dependencyConfigTable.general';
import { COLUMNS } from '../../../constants/approvalSettingFieldForm.constants';

const getFieldOptions = (fieldDefinitions, selectedFields = EMPTY_ARRAY) =>
  _map(fieldDefinitions, (field) => ({
    label: _get(field, 'displayName', ''),
    value: _get(field, 'name', ''),
    isDisabled: _includes(selectedFields, field.name),
  }));

const mapFieldProps = defaultMemoize((props, fieldDefinitions, selectedFields) => {
  const value = _get(props, `rowData.${COLUMNS.FIELD_NAME}`);
  return {
    validators: [isRequiredRule],
    required: true,
    options: getFieldOptions(fieldDefinitions, selectedFields),
    error: _isEmpty(value) ? __('Please select FieldName') : '',
  };
});

const mapValueProps = defaultMemoize((props) => {
  const value = _get(props, `rowData.${COLUMNS.DEPENDENCY_TYPE}`);
  return {
    validators: [isRequiredRule],
    required: true,
    options: DEPENDENCY_OPTIONS,
    error: _isEmpty(value) ? __('Please Select dependency Type') : '',
  };
});

const getFieldCell = (fieldDefinitions, selectedFields) =>
  new CellConfig()
    .setComponent(SelectInputCellRenderer)
    .setMapCellPropsToComponentProps((props) => mapFieldProps(props, fieldDefinitions, selectedFields));

const VALUE_CELL = new CellConfig().setComponent(SelectInputCellRenderer).setMapCellPropsToComponentProps((props) => mapValueProps(props));

const COLUMN_WIDTHS = {
  [COLUMNS.FIELD_NAME]: '30rem',
  [COLUMNS.DEPENDENCY_TYPE]: '30rem',
};

const valueAccessor = _property(COLUMNS.DEPENDENCY_TYPE);
const labelAccessor = _property(COLUMNS.FIELD_NAME);

const onValueChange = compose(_property('value'), _nthArg(1));

const getFieldColumnConfig = (fieldDefinitions, selectedFields) =>
  new ColumnConfig(COLUMNS.FIELD_NAME)
    .setHeader(__('Field'))
    .setGetValueFromOnChange(onValueChange)
    .setAccessor(labelAccessor)
    .addCellConfig(getFieldCell(fieldDefinitions, selectedFields))
    .setWidth(COLUMN_WIDTHS[COLUMNS.FIELD_NAME]);

const VALUE_COLUMN_CONFIG = new ColumnConfig(COLUMNS.DEPENDENCY_TYPE)
  .setHeader(__('DependencyType'))
  .setGetValueFromOnChange(onValueChange)
  .setAccessor(valueAccessor)
  .addCellConfig(VALUE_CELL)
  .setWidth(COLUMN_WIDTHS[COLUMNS.DEPENDENCY_TYPE]);

const getColumns = (fieldDefinitions, selectedFields) => {
  const fieldColumnConfig = getFieldColumnConfig(fieldDefinitions, selectedFields);
  return [fieldColumnConfig, VALUE_COLUMN_CONFIG];
};

export { COLUMN_WIDTHS, valueAccessor, labelAccessor, onValueChange, getColumns };
