import _intersectionBy from 'lodash/intersectionBy';
import _intersection from 'lodash/intersection';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _isFunction from 'lodash/isFunction';

import { tget } from '@tekion/tekion-base/utils/general';
import addOptionsToAdditional from '@tekion/tekion-base/utils/addOptionsToAdditional';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';

// Constants
import {
  APPROVAL_ASSET_TYPE,
  APPROVAL_CENTRE_FIELD_IDS,
  APPROVAL_STATUS_TYPES,
  APPROVAL_METADATA_PROPERTY_IDS,
} from '../../../../constants/approvalCentre.constants';

import { STATUS_FILTER, GROUP_FILTER, CATEGORY_FILTER, DEFAULT_FILTERS_TYPES } from '../constants/approvalTasksList.filterTypes';
import COLUMN_IDS from '../constants/approvalTasksList.columnIds';
import { APPROVE_REQUEST_ROW_ACTION, REJECT_REQUEST_ROW_ACTION } from '../constants/approvalTasksList.general';

import localStorageReader from '../../../../readers/localStorage.reader';
import userReader from '../../../../readers/loginResponse.reader';

const getRowActions = (rowData) => {
  const status = tget(rowData, COLUMN_IDS.STATUS);

  if (status === APPROVAL_STATUS_TYPES.PENDING) {
    return [APPROVE_REQUEST_ROW_ACTION, REJECT_REQUEST_ROW_ACTION];
  }

  return [];
};

const getOnRowClick = (onRowClick) => (_isFunction(onRowClick) ? { onRowClick } : EMPTY_OBJECT);

const createTableProps = (isLoading, currentPage, pageSize, onAction, onRowClick) => ({
  showPagination: true,
  loading: isLoading,
  currentPage: currentPage + 1,
  minRows: 0,
  pageSize,
  resultsPerPage: pageSize,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  getRowActions,
  onRowActionClick: _isFunction(onRowClick) ? onRowClick : (type, payload) => onAction({ type, payload }),
  ...getOnRowClick(onRowClick),
});

const createFilterProps = (filterMetadata, applicationProperties, onAction) => {
  const filtersToShow = tget(applicationProperties, APPROVAL_METADATA_PROPERTY_IDS.FILTERS_TO_SHOW);
  const filterTypes = [
    STATUS_FILTER,
    addOptionsToAdditional(GROUP_FILTER, filterMetadata.GROUPS),
    addOptionsToAdditional(CATEGORY_FILTER, filterMetadata.CATEGORIES),
  ];

  const filtersToShowAsObjectArray = filtersToShow === undefined ? filterTypes : _map(filtersToShow, (filterToShow) => ({ id: filterToShow }));

  return {
    showResultsCount: false,
    defaultFilterTypes: filtersToShow === undefined ? DEFAULT_FILTERS_TYPES : _intersection(DEFAULT_FILTERS_TYPES, filtersToShow),
    filterTypes: _intersectionBy(filterTypes, filtersToShowAsObjectArray, 'id'),
    onAction,
  };
};

const getCurrentUserFilter = (currentLoggedInUserData) => {
  let currentUserId;

  if (!_isEmpty(currentLoggedInUserData)) {
    currentUserId = tget(currentLoggedInUserData, 'id');
  } else {
    const currentUser = localStorageReader.userInfo();
    currentUserId = userReader.userID(currentUser);
  }

  const currentUserFilter = {
    field: COLUMN_IDS.ELIGIBLE_APPROVERS,
    values: [currentUserId],
    filterType: OPERATORS.IN,
  };

  return currentUserFilter;
};

const setFiltersInPayload = (payload, applicationProperties, selectedFilters, currentLoggedInUserData) => {
  const groupsEnabled = tget(applicationProperties, 'groupsEnabled', null);
  const categoriesEnabled = tget(applicationProperties, 'categoriesEnabled', null);
  const entitiesEnabled = tget(applicationProperties, 'entitiesEnabled', null);

  const filters = _map(
    _filter(selectedFilters, (filter) => !_isEmpty(_get(filter, 'values'))),
    (filter) => ({
      field: _get(filter, 'type', EMPTY_STRING),
      values: _get(filter, 'values', EMPTY_ARRAY),
      filterType: _get(filter, 'operator', EMPTY_STRING),
    }),
  );

  const currentUserFilter = getCurrentUserFilter(currentLoggedInUserData);

  if (!_isEmpty(groupsEnabled)) {
    filters.push({
      field: COLUMN_IDS.GROUP,
      values: _compact(groupsEnabled),
      filterType: OPERATORS.IN,
    });
  }

  if (!_isEmpty(categoriesEnabled)) {
    filters.push({
      field: COLUMN_IDS.CATEGORY,
      values: _compact(categoriesEnabled),
      filterType: OPERATORS.IN,
    });
  }

  if (!_isEmpty(entitiesEnabled)) {
    filters.push({
      field: APPROVAL_CENTRE_FIELD_IDS.TYPE,
      values: _compact(entitiesEnabled),
      filterType: OPERATORS.IN,
    });
  }

  _set(payload, 'filters', [...filters, currentUserFilter]);
};

const getPayload = ({ applicationProperties, currentPage, currentPageToken, pageSize, selectedFilters, searchText, currentLoggedInUserData }) => {
  const payload = {
    rows: pageSize,
    start: currentPage * pageSize,
    nextPageToken: currentPageToken,
    searchText,
  };

  setFiltersInPayload(payload, applicationProperties, selectedFilters, currentLoggedInUserData);

  return payload;
};

const getPayloadForUserIds = (ids) => ({
  filters: [
    {
      field: 'id',
      values: ids,
      filterType: OPERATORS.IN,
    },
  ],
});

const getPayloadForCreateComment = (approvalId, commentContent) => ({
  assetType: APPROVAL_ASSET_TYPE,
  assetId: approvalId,
  inReplyTo: null,
  content: commentContent,
});

export { createTableProps, createFilterProps, getPayload, getPayloadForUserIds, getPayloadForCreateComment };
