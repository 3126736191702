import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _head from 'lodash/head';
import _get from 'lodash/get';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import TextInputCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/TextInputCellRenderer';

import { ACTION_TYPES } from '../../../constants/addWidget.general.constant';

const InputCellRenderer = (props) => {
  const { column, nestingPath, original, onAction, fieldId, data } = props;

  const columnId = _get(column, 'id', '');
  const rowIndex = _head(nestingPath);

  const onChange = useCallback(
    (e) => {
      onAction({
        type: ACTION_TYPES.ON_COLUMN_CONFIG_CHANGE,
        payload: {
          id: fieldId,
          columnId,
          value: e?.target?.value || '',
          rowIndex,
          original,
        },
      });
    },
    [onAction, columnId, rowIndex, original, fieldId],
  );

  return <TextInputCellRenderer id={`${columnId}_${rowIndex}`} value={data} onChange={onChange} className="full-width" />;
};
InputCellRenderer.propTypes = {
  column: PropTypes.object,
  data: PropTypes.string,
  nestingPath: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
  original: PropTypes.object,
  fieldId: PropTypes.string.isRequired,
};
InputCellRenderer.defaultProps = {
  column: EMPTY_OBJECT,
  data: '',
  original: EMPTY_OBJECT,
};
export default makeCellRenderer(InputCellRenderer);
