import { useCallback } from 'react';

import { tget } from '@tekion/tekion-base/utils/general';
import { getToday, toMoment } from '@tekion/tekion-base/utils/dateUtils';

import { DATE_PICKER_ID, DURATION_TYPES, DURATION_TYPE_ID } from '../../constants/durationFieldRenderer.general.constants';
import { triggerDurationFieldRendererActions } from './useDurationFieldRenderer.helpers';

const useDurationFieldRenderer = (id, value, onAction) => {
  const datePickerValue = toMoment(tget(value, DATE_PICKER_ID));
  const durationTypeValue = tget(value, DURATION_TYPE_ID);

  const handleAction = useCallback((action) => triggerDurationFieldRendererActions({ action, id, value, onAction }), [id, onAction, value]);

  const isDatePickerDisabled = durationTypeValue !== DURATION_TYPES.DATE_AND_TIME;
  const disabledDate = (current) => current && current < getToday();

  return { isDatePickerDisabled, disabledDate, datePickerValue, durationTypeValue, handleAction };
};

export default useDurationFieldRenderer;
