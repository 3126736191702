import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';
import COLUMN_IDS from './validationRuleBuilderList.columnIds';

const RULE_STATUS_FILTER_OPTIONS = [
  { label: __('Active'), value: true },
  { label: __('Inactive'), value: false },
];

const RULE_STATUS_FILTER = {
  id: COLUMN_IDS.STATUS,
  type: FILTER_TYPES.SINGLE_SELECT,
  name: __('Status'),
  additional: {
    options: RULE_STATUS_FILTER_OPTIONS,
  },
};

const DEFAULT_FILTERS_TYPES = [RULE_STATUS_FILTER.id];

export { RULE_STATUS_FILTER, DEFAULT_FILTERS_TYPES };
