import { DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';
import { isRequiredRule, nonNegativeRule, nonZeroRule } from '@tekion/tekion-base/utils/formValidators';

// Components
import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import DateTimeRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/datePicker';
import AsyncPaginatedSelect from '../../../../../../../organisms/asyncPaginatedSelect';
import EntityAsyncSelect from '../../../../../molecules/EntityAsyncSelect';
import ApprovalStageFieldRenderer from '../components/approvalStageFieldRenderer/ApprovalStageFieldRenderer';
import ConditionBuilder, {
  CONDITION_BUILDER_FIELD_IDS,
  CONDITION_FIELD_IDS,
  isConditionRequiredRule,
} from '../../../../../../../organisms/conditionBuilder';

// Actions
import { fetchGroups, fetchCategories } from '../../../../../../../actions/approvalManagement.actions';

// Helpers
import {
  generateCategoryOptionsFromHits,
  generateGroupOptionsFromHits,
  getAsyncSelectPayload,
} from '../../../../../../../helpers/approval.groupCategoryHelpers';
import { isIntegerRule } from '../../../../../../../utils/formValidators';

// Constants
import { FORM_MODES } from '../../../../../../../constants/general.constants';
import { VALIDITY_TYPE_OPTIONS } from '../constants/approvalProcessForm.valueConstants';
import FIELD_IDS from '../constants/approvalProcessForm.fieldIds';

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    size: 6,
    label: __('Display Name'),
    placeholder: __('Enter display name'),
    validators: [isRequiredRule],
  },
};

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    size: 6,
    label: __('Name'),
    placeholder: __('This will be autofilled'),
    validators: [isRequiredRule],
  },
};

const GROUP_FIELD = {
  id: FIELD_IDS.GROUP,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    required: true,
    label: __('Group'),
    size: 6,
    getPayload: getAsyncSelectPayload(FIELD_IDS.GROUP),
    getOptions: generateGroupOptionsFromHits(),
    validators: [isRequiredRule],
    serviceHandler: fetchGroups,
  },
};

const CATEGORY_FIELD = {
  id: FIELD_IDS.CATEGORY,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    required: true,
    label: __('Category'),
    size: 6,
    getPayload: getAsyncSelectPayload(FIELD_IDS.CATEGORY),
    getOptions: generateCategoryOptionsFromHits(),
    validators: [isRequiredRule],
    serviceHandler: fetchCategories,
  },
};

const PRIORITY_FIELD = {
  id: FIELD_IDS.PRIORITY,
  renderer: TextInput,
  renderOptions: {
    required: true,
    size: 6,
    type: 'number',
    label: __('Priority'),
    placeholder: __('Enter priority'),
    validators: [nonNegativeRule, nonZeroRule, isIntegerRule],
  },
};

const ENTITY_TYPE_FIELD = {
  id: FIELD_IDS.TYPE,
  renderer: EntityAsyncSelect,
  renderOptions: {
    label: __('Entity'),
    placeholder: __('Select entity'),
    size: 6,
    required: true,
    validators: [isRequiredRule],
  },
};

const VALIDITY_TYPE_FIELD = {
  id: FIELD_IDS.VALIDITY_TYPE,
  renderer: Radio,
  renderOptions: {
    size: 6,
    required: true,
    validators: [isRequiredRule],
    radios: VALIDITY_TYPE_OPTIONS,
  },
};

const DAYS_FIELD = {
  id: FIELD_IDS.VALIDITY_DAYS,
  renderer: TextInput,
  renderOptions: {
    required: true,
    size: 3,
    type: 'number',
    placeholder: __('Days'),
    addonAfter: __('Days'),
  },
};

const HOURS_FIELD = {
  id: FIELD_IDS.VALIDITY_HOURS,
  renderer: TextInput,
  renderOptions: {
    required: true,
    size: 3,
    type: 'number',
    placeholder: __('Hours'),
    addonAfter: __('Hours'),
  },
};

const MINUTES_FIELD = {
  id: FIELD_IDS.VALIDITY_MINUTES,
  renderer: TextInput,
  renderOptions: {
    required: true,
    size: 3,
    type: 'number',
    placeholder: __('Minutes'),
    addonAfter: __('Mins'),
  },
};

const VALIDITY_EPOCH_FIELD = {
  id: FIELD_IDS.VALIDITY_EPOCH,
  renderer: DateTimeRenderer,
  renderOptions: {
    required: true,
    showTime: true,
    size: 6,
    placeholder: __('Choose date & time'),
    format: DATE_TIME_FORMATS.MONTH_DATE_YEAR_TIME_12HR_FORMAT,
    validators: [isRequiredRule],
  },
};

const APPROVAL_STAGES_FIELD = {
  id: FIELD_IDS.STAGES,
  renderer: ApprovalStageFieldRenderer,
  renderOptions: {
    mode: FORM_MODES.CREATE,
    required: true,
    label: __('Stages'),
  },
};

const FIELDS = {
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.GROUP]: GROUP_FIELD,
  [FIELD_IDS.CATEGORY]: CATEGORY_FIELD,
  [FIELD_IDS.PRIORITY]: PRIORITY_FIELD,
  [FIELD_IDS.TYPE]: ENTITY_TYPE_FIELD,
  [FIELD_IDS.VALIDITY_TYPE]: VALIDITY_TYPE_FIELD,
  [FIELD_IDS.VALIDITY_DAYS]: DAYS_FIELD,
  [FIELD_IDS.VALIDITY_HOURS]: HOURS_FIELD,
  [FIELD_IDS.VALIDITY_MINUTES]: MINUTES_FIELD,
  [FIELD_IDS.VALIDITY_EPOCH]: VALIDITY_EPOCH_FIELD,
  [FIELD_IDS.STAGES]: APPROVAL_STAGES_FIELD,
};

const getFormModeBasedField = (field, formMode) => {
  if (formMode === FORM_MODES.EDIT) {
    return addToRenderOptions(field, [
      { path: 'disabled', value: true },
      { path: 'isDisabled', value: true },
      { path: 'fieldClassName', value: 'is-disabled' },
    ]);
  }

  return field;
};

const generateFields = (
  isApprovalSettingLoading,
  isConditionFieldsLoading,
  formMode,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
) => ({
  ...FIELDS,
  [FIELD_IDS.GROUP]: getFormModeBasedField(FIELDS[FIELD_IDS.GROUP], formMode),
  [FIELD_IDS.CATEGORY]: getFormModeBasedField(FIELDS[FIELD_IDS.CATEGORY], formMode),
  [FIELD_IDS.TYPE]: getFormModeBasedField(FIELDS[FIELD_IDS.TYPE], formMode),
  [FIELD_IDS.NAME]: getFormModeBasedField(FIELDS[FIELD_IDS.NAME], formMode),
  [FIELD_IDS.CONDITION]: {
    id: FIELD_IDS.CONDITION,
    renderer: ConditionBuilder,
    renderOptions: {
      containerClassname: fieldLayoutStyles.fieldC,
      label: '',
      validators: [isConditionRequiredRule()],
      required: true,
      isFieldsLoading: isApprovalSettingLoading || isConditionFieldsLoading,
      childProps: {
        [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
          label: '',
          childProps: {
            [CONDITION_FIELD_IDS.FIELD]: {
              isVariablePrefixNeeded: false,
              label: __('Field'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
            },
            [CONDITION_FIELD_IDS.OPERATOR]: {
              isVariablePrefixNeeded: false,
              label: __('Operator'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
            },
            [CONDITION_FIELD_IDS.VALUES]: {
              isVariablePrefixNeeded: false,
              label: __('Value'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
            },
          },
        },
      },
    },
  },
});

export default generateFields;
