const APPLICATION_CONFIG_FIELD_IDS = {
  COMPONENTS: 'components',
  NAME: 'name',
  API_NAME: 'apiName',
};

const COMPONENT_KEYS = {
  CHILDREN: 'children',
  COMPONENT_ID: 'componentId',
  TYPE: 'type',
  PROPERTIES: 'properties',
};

const PROPERTIES_FIELD_IDS = {
  TAB_DISPLAY_NAME: 'tabDisplayName',
  TAB_NAME: 'tabName',
  TAB_ICON: 'tabIcon',
  PAGE_ID: 'pageId',
  ENTITIES_RENDERING_INFO: 'entitiesRenderingInfo',
  IS_NAVIGATION_REQUIRED: 'isNavigationRequired',
  NAVIGATION_TYPE: 'navigationType',
  APPROVAL_INTEGRATION: 'approvalIntegration',
  NAVIGATION_PROPERTIES: 'navigationProperties',
  APPROVAL_META_DATA: 'approvalMetadata',
  DISPLAY_NAME: 'displayName',
};

const COMPONENT_IDS = {
  TAB_PROPERTIES: 'tabProperties',
  NAVIGATION_PROPERTIES: 'navigationProperties',
  HEADER: 'header',
  FOOTER: 'footer',
};

export { APPLICATION_CONFIG_FIELD_IDS, COMPONENT_IDS, PROPERTIES_FIELD_IDS, COMPONENT_KEYS };
