import { SHARE_RECORD_FORM_FIELD_IDS } from '../constants/shareRecordForm.fieldIds';

import styles from '../shareRecordForm.module.scss';

const SHARE_RECORD_FORM_SECTIONS = [
  {
    className: styles.shareRecordFormSectionContainer,
    rows: [
      {
        columns: [SHARE_RECORD_FORM_FIELD_IDS.USERS, SHARE_RECORD_FORM_FIELD_IDS.ACCESS_TYPE, SHARE_RECORD_FORM_FIELD_IDS.SET_DURATION],
      },
    ],
  },
];

export default SHARE_RECORD_FORM_SECTIONS;
