import { useCallback, useEffect, useMemo, useState } from 'react';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';

// Actions
import { fetchPusherMetadata } from '../../actions/pusher.actions';

// Event triggers
import { triggerPushNotificationReceived, triggerPusherStateChange } from '../../eventEmitters/helpers/pusherEventEmitter.helpers';

// Helpers
import createPusherInstance from './helpers/pusher.instanceCreator';
import { createTenantLevelChannelName, createWorkspaceLevelChannelName, createUserLevelChannelName } from './helpers/pusher.channelNameHelpers';

// Constants
import { PUSH_NOTIFICATION_EVENT_TYPES } from '../../constants/notification.constants';
import { PUSHER_CONNECTION_STATE_CHANGE } from './constants/pusher.connectionStates';

// Readers
import localStorageReader from '../../readers/localStorage.reader';

const handleNewNotification = (notificationEvent) => {
  let notification = notificationEvent;

  try {
    notification = JSON.parse(notificationEvent);
  } catch (error) {
    console.warn(error);
  }

  if (!_isEmpty(notification)) {
    triggerPushNotificationReceived(notification);
  }
};

// states will contain current & previous state strings.
// Will be one of PUSHER_CONNECTION_STATES, check file ./pusherConnectionStates
const handlePusherStateChange = (states) => {
  if (!_isEmpty(states)) {
    triggerPusherStateChange(states);
  }
};

const usePusher = () => {
  const [pusherInstance, setPusherInstance] = useState();
  const [envPrefix, setEnvPrefix] = useState();

  const eventHandlersByChannel = useMemo(() => {
    const userInfo = localStorageReader.userInfo();
    const { userId, tenantId, workspaceId } = userInfo;

    const userChannel = createUserLevelChannelName(userId, tenantId, workspaceId, envPrefix);
    const workspaceChannel = createWorkspaceLevelChannelName(userId, tenantId, workspaceId, envPrefix);
    const tenantChannel = createTenantLevelChannelName(userId, tenantId, workspaceId, envPrefix);

    return [
      {
        channelName: userChannel,
        eventHandlers: [{ eventName: PUSH_NOTIFICATION_EVENT_TYPES.CUSTOM_ENTITY, handler: handleNewNotification }],
      },
      {
        channelName: workspaceChannel,
        eventHandlers: [{ eventName: PUSH_NOTIFICATION_EVENT_TYPES.CUSTOM_ENTITY, handler: handleNewNotification }],
      },
      {
        channelName: tenantChannel,
        eventHandlers: [{ eventName: PUSH_NOTIFICATION_EVENT_TYPES.CUSTOM_ENTITY, handler: handleNewNotification }],
      },
    ];
  }, [envPrefix]);

  const instantiatePusher = useCallback(async () => {
    const pusherConfig = await fetchPusherMetadata();

    if (!_isEmpty(pusherConfig)) {
      setEnvPrefix(pusherConfig.envPrefix);
      createPusherInstance(pusherConfig)
        .then((_pusherInstance) => setPusherInstance(_pusherInstance))
        .catch((error, pusherError) => {
          console.error({ error, pusherError });
        });
    }
  }, []);

  const disconnectPusher = useCallback(() => {
    setPusherInstance((_pusherInstance) => {
      if (_pusherInstance) {
        _pusherInstance.disconnect();
      }

      return undefined;
    });
  }, []);

  // Instantiation
  useEffect(() => {
    instantiatePusher();

    return () => {
      disconnectPusher();
    };
  }, [instantiatePusher, disconnectPusher]);

  // Subscription and event binding
  useEffect(() => {
    if (pusherInstance) {
      _forEach(eventHandlersByChannel, (eventHandlerForChannel) => {
        const channelName = eventHandlerForChannel?.channelName;

        // Subscribing to channel
        const pusherChannelInstance = pusherInstance.subscribe(channelName);
        pusherChannelInstance.bind('pusher:subscription_error', (error) => {
          console.error('error: ', error);
        });

        _forEach(eventHandlerForChannel?.eventHandlers, (eventHandler) => {
          const { eventName, handler } = eventHandler;
          // Binding event handlers to events
          pusherChannelInstance.bind(eventName, handler);
        });
      });
    }

    return () => {
      if (pusherInstance) {
        _forEach(eventHandlersByChannel, (eventHandlerForChannel) => {
          const channelName = eventHandlerForChannel?.channelName;

          if (pusherInstance.channel) {
            const pusherChannelInstance = pusherInstance.channel(channelName);

            if (pusherChannelInstance) {
              pusherChannelInstance.unbind('pusher:subscription_error');

              _forEach(eventHandlerForChannel?.eventHandlers, (eventHandler) => {
                const { eventName } = eventHandler;
                // Unbinding from events
                pusherChannelInstance.unbind(eventName);
              });
            }
          }
        });
      }
    };
  }, [pusherInstance, eventHandlersByChannel]);

  useEffect(() => {
    if (pusherInstance?.connection) {
      pusherInstance.connection.bind(PUSHER_CONNECTION_STATE_CHANGE, handlePusherStateChange);
    }

    return () => {
      if (pusherInstance?.connection) {
        pusherInstance.connection.unbind(PUSHER_CONNECTION_STATE_CHANGE, handlePusherStateChange);
      }
    };
  }, [pusherInstance]);

  return pusherInstance;
};

export default usePusher;
