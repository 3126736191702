import _get from 'lodash/get';
import _has from 'lodash/has';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { getPermissionByName } from '../../../../../actions/permissionSetManagement.action';

import { STUDIO_ROUTE } from '../../../../../constants/routes';
import PAGE_IDS from '../../../constants/PageIds.constants';
import ACTION_TYPES from './constants/permissionSetConfigure.actionTypes';

const handleInitData = async ({ getState, setState }) => {
  const { match, history } = getState();
  const permissionName = _get(match, 'params.permissionName');
  let permissionData = {};

  if (!_has(history, 'location.state.permissionData')) {
    permissionData = await getPermissionByName(permissionName);
  } else {
    permissionData = _get(history, 'location.state.permissionData', EMPTY_ARRAY);
  }

  setState({ permissionData });
};

const handleTabChange = ({ getState, params = EMPTY_OBJECT }) => {
  const { path } = params;
  const { history, permissionData } = getState();
  history.push({ pathname: path, state: { permissionData } });
};

const handleEditClick = ({ getState }) => {
  const { history, match, permissionData } = getState();
  const permissionName = _get(match, 'params.permissionName');
  const path = `${STUDIO_ROUTE}/${PAGE_IDS.PERMISSION_SET_EDIT}/${permissionName}`;
  history.push({ pathname: path, state: { permissionData } });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT_DATA]: handleInitData,
  [ACTION_TYPES.ON_TAB_CHANGE]: handleTabChange,
  [ACTION_TYPES.ON_CLICK_EDIT]: handleEditClick,
};

export default ACTION_HANDLERS;
