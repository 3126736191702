import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import _toString from 'lodash/toString';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import FieldValuePair from '../fieldValuePair/FieldValuePair';

import FIELD_IDS from '../../constants/subscriptionConfigLogs.fieldIds';

import styles from './subscriptionConfigLogDetails.module.scss';

const SubscriptionConfigLogDetails = ({ logDetails }) => {
  const [logDetailExpandedInfo, setLogDetailExpandedInfo] = useState({});

  const subscriptionConfigName = tget(logDetails, FIELD_IDS.SUBSCRIPTION_CONFIG_NAME, NO_DATA);
  const responseCode = tget(logDetails, FIELD_IDS.SUBSCRIPTION_CONFIG_RESPONSE_CODE, NO_DATA);
  const path = tget(logDetails, `${FIELD_IDS.SUBSCRIPTION_CONFIG_REQUEST}.${FIELD_IDS.SUBSCRIPTION_CONFIG_PATH}`, NO_DATA);
  const body = tget(logDetails, `${FIELD_IDS.SUBSCRIPTION_CONFIG_REQUEST}.${FIELD_IDS.SUBSCRIPTION_CONFIG_BODY}`, null);
  const responseBody = tget(logDetails, FIELD_IDS.SUBSCRIPTION_CONFIG_RESPONSE_BODY, null);
  const responseHeaders = tget(logDetails, FIELD_IDS.SUBSCRIPTION_CONFIG_RESPONSE_HEADERS, []);

  const handleExpand = useCallback(
    (logId) => () => {
      setLogDetailExpandedInfo((prev) => {
        const updatedPrevValues = { ...prev };
        _set(updatedPrevValues, logId, !tget(prev, logId, false));
        return updatedPrevValues;
      });
    },
    [],
  );

  const renderResponseHeaders = useCallback(
    () => (
      <>
        <div className={styles.logFieldContainer}>
          <div className={styles.logLabel}>{__('Response Headers')}</div>
          <PropertyControlledComponent controllerProperty={!_isEmpty(responseHeaders)} fallback={NO_DATA}>
            <FontIcon className={styles.icon} onClick={handleExpand(FIELD_IDS.SUBSCRIPTION_CONFIG_RESPONSE_HEADERS)}>
              {tget(logDetailExpandedInfo, FIELD_IDS.SUBSCRIPTION_CONFIG_RESPONSE_HEADERS, false) ? 'icon-chevron-up' : 'icon-chevron-down'}
            </FontIcon>
          </PropertyControlledComponent>
        </div>

        <div className={styles.logValue}>
          {tget(logDetailExpandedInfo, FIELD_IDS.SUBSCRIPTION_CONFIG_RESPONSE_HEADERS, false) &&
            _map(responseHeaders, (headerValue, header) => <FieldValuePair key={header} label={__(`${header}`)} value={_toString(headerValue)} />)}
        </div>
      </>
    ),
    [handleExpand, logDetailExpandedInfo, responseHeaders],
  );

  const renderBody = useCallback(
    () => (
      <>
        <div className={styles.logFieldContainer}>
          <div className={styles.logLabel}>{__('Body')}</div>
          <PropertyControlledComponent controllerProperty={!_isEmpty(body)} fallback={NO_DATA}>
            <FontIcon className={styles.icon} onClick={handleExpand(FIELD_IDS.SUBSCRIPTION_CONFIG_BODY)}>
              {tget(logDetailExpandedInfo, FIELD_IDS.SUBSCRIPTION_CONFIG_BODY, false) ? 'icon-chevron-up' : 'icon-chevron-down'}
            </FontIcon>
          </PropertyControlledComponent>
        </div>
        <PropertyControlledComponent controllerProperty={tget(logDetailExpandedInfo, [FIELD_IDS.SUBSCRIPTION_CONFIG_BODY], false)}>
          <pre className={styles.logValue}>{JSON.stringify(JSON.parse(body), null, 2)}</pre>
        </PropertyControlledComponent>
      </>
    ),
    [body, handleExpand, logDetailExpandedInfo],
  );

  const renderResponseBody = useCallback(
    () => (
      <>
        <div className={styles.logFieldContainer}>
          <div className={styles.logLabel}>{__('Response Body')}</div>
          <PropertyControlledComponent controllerProperty={!_isEmpty(responseBody)} fallback={NO_DATA}>
            <FontIcon className={styles.icon} onClick={handleExpand(FIELD_IDS.SUBSCRIPTION_CONFIG_RESPONSE_BODY)}>
              {tget(logDetailExpandedInfo, FIELD_IDS.SUBSCRIPTION_CONFIG_RESPONSE_BODY, false) ? 'icon-chevron-up' : 'icon-chevron-down'}
            </FontIcon>
          </PropertyControlledComponent>
        </div>

        <PropertyControlledComponent controllerProperty={tget(logDetailExpandedInfo, FIELD_IDS.SUBSCRIPTION_CONFIG_RESPONSE_BODY, false)}>
          <pre className={styles.logValue}>{JSON.stringify(JSON.parse(responseBody), null, 2)}</pre>
        </PropertyControlledComponent>
      </>
    ),
    [handleExpand, logDetailExpandedInfo, responseBody],
  );

  return (
    <div className={styles.subscriptionConfigLogs}>
      <FieldValuePair label={__('Subscriptions Config Name')} value={subscriptionConfigName} />
      <FieldValuePair label={__('Response Code')} value={responseCode} />
      <FieldValuePair label={__('Path')} value={path} />
      {renderResponseBody()}
      {renderBody()}
      {renderResponseHeaders()}
    </div>
  );
};

SubscriptionConfigLogDetails.propTypes = {
  logDetails: PropTypes.object,
};
SubscriptionConfigLogDetails.defaultProps = {
  logDetails: EMPTY_OBJECT,
};

export default React.memo(SubscriptionConfigLogDetails);
