import { tget } from '@tekion/tekion-base/utils/general';

import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { FIELD_NAMES } from './resetPasswordPage.constants';

const handleSubmit = ({ getState, params }) => {
  const { onSubmit, values } = getState();
  const captchaToken = tget(params, `additional.${FIELD_NAMES.CAPTCHA_TOKEN}`);
  const newFormValues = { ...values, captchaToken };

  onSubmit(newFormValues);
};

const ACTION_HANDLER = {
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
};

export default ACTION_HANDLER;
