import _get from 'lodash/get';
import _size from 'lodash/size';
import _castArray from 'lodash/castArray';

import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { STUDIO_ROUTE } from '../../../../../constants/routes';
import PAGE_IDS from '../../../constants/PageIds.constants';

import { TABLE_CONSTANTS } from '../constants/projectList.general.constants';
import ACTION_TYPES from '../constants/projectList.actionTypes';

import { COLUMN_IDS } from './projectList.columns';
import { getPayload } from './projectList.helpers';

import { getWorkspaceProjectList } from '../../../../../actions/workspaceProjectManagement.actions';

const handleFetchProjects = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { pageSize, searchText } = getState();
  const currentPageSize = _get(params, 'pageSize', pageSize);
  const currentSearchText = _get(params, 'searchText', searchText);
  const currentPageToken = _get(params, 'nextPageToken');

  const payload = getPayload({
    searchText: currentSearchText,
    searchField: COLUMN_IDS.PROJECT_NAME,
    pageSize: currentPageSize,
    nextPageToken: currentPageToken,
  });

  setState({ loading: true });

  const projectDataResponse = await getWorkspaceProjectList(payload);
  const projectList = tget(projectDataResponse, 'hits', EMPTY_ARRAY);

  setState({
    projects: projectList,
    totalNumberOfEntries: _size(projectList),
    isLoaded: true,
    loading: false,
    nextPageToken: _get(projectDataResponse, 'nextPageToken'),
  });
};

const handleSearchApply = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const searchText = _get(params, 'value');

  setState(
    {
      searchText,
    },
    () => {
      handleFetchProjects({ getState, setState, params: { searchText: _get(params, 'value') } });
    },
  );
};

const handlePageSizeUpdate = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');

  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }
  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }
  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleFetchProjects({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const handleRefreshListView = async ({ getState, setState }) => {
  setState(
    {
      currentPage: TABLE_CONSTANTS.CURRENT_PAGE,
    },
    () => {
      handleFetchProjects({ getState, setState });
    },
  );
};

const handleCreateNewProject = ({ getState }) => {
  const { history } = getState();
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.PROJECT_CREATE}`;

  history.push({ pathname });
};

const handleEditProjectRequest = ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.PROJECT_EDIT}/${params.id}`;

  history.push({ pathname, state: { projectInfo: params } });
};

const rowClickHandler = ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const projectData = _get(params, 'value.original', EMPTY_OBJECT);
  const projectName = _get(projectData, 'name', EMPTY_STRING);
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.PROJECT_APPLICATIONS}/${projectName}`;

  history.push({ pathname, state: { projectData } });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchApply,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleRefreshListView,
  [ACTION_TYPES.CREATE_PROJECT]: handleCreateNewProject,
  [ACTION_TYPES.ON_FETCH_PROJECT_DATA]: handleFetchProjects,
  [ACTION_TYPES.EDIT]: handleEditProjectRequest,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: rowClickHandler,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageSizeUpdate,
};

export default ACTION_HANDLERS;
