import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { EMPTY_ARRAY } from '@tekion/tekion-base/utils/constant';

import { nameValidator } from '../../../../../utils/formValidators';
import { FORM_MODES } from '../../../../../constants/general.constants';

const FIELD_IDS = {
  DISPLAY_NAME: 'displayName',
  NAME: 'name',
};

const PROJECT_DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Display Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Display Name'),
  },
};

const PROJECT_NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Name'),
    placeholder: __('This will be autofilled'),
  },
};

const PROJECT_FORM_FIELDS = {
  [FIELD_IDS.DISPLAY_NAME]: PROJECT_DISPLAY_NAME_FIELD,
  [FIELD_IDS.NAME]: PROJECT_NAME_FIELD,
};

const getFields = (mode) => ({
  ...PROJECT_FORM_FIELDS,
  [FIELD_IDS.NAME]: addToRenderOptions(PROJECT_NAME_FIELD, [
    { path: 'disabled', value: mode !== FORM_MODES.CREATE },
    { path: 'required', value: mode === FORM_MODES.CREATE },
    { path: 'validators', value: mode === FORM_MODES.CREATE ? [nameValidator] : EMPTY_ARRAY },
  ]),
});

export default getFields;

export { FIELD_IDS };
