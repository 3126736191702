import React from 'react';
import PropTypes from 'prop-types';

import { NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import List from '@tekion/tekion-components/molecules/List';

import FIELD_IDS from '../../../constants/approvalSettings.fieldIds';

import styles from './approvalSettingCard.module.scss';

const ApprovalSettingCard = ({ approvalSetting }) => (
  <div className={styles.container}>
    <div className={styles.item}>
      <div className={styles.subItem}>{__('Group: ')}</div>
      <List.Item.Meta description={tget(approvalSetting, FIELD_IDS.GROUP, NO_DATA)} />
    </div>
    <div className={styles.item}>
      <div className={styles.subItem}>{__('Category: ')}</div>
      <List.Item.Meta description={tget(approvalSetting, FIELD_IDS.CATEGORY, NO_DATA)} />
    </div>
  </div>
);

ApprovalSettingCard.propTypes = {
  approvalSetting: PropTypes.object.isRequired,
};

export default React.memo(ApprovalSettingCard);
