import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _noop from 'lodash/noop';

import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import styles from './fieldValuePair.module.scss';

const FieldValuePair = ({ icon, iconClassName, label, value, onClickIcon }) => (
  <div className={styles.stageFieldContainer}>
    <FontIcon className={cx(styles.icon, iconClassName)} onClick={onClickIcon}>
      {icon}
    </FontIcon>
    <div className={styles.label}>{label}</div>
    <div className={styles.value}>{value}</div>
  </div>
);

FieldValuePair.propTypes = {
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClickIcon: PropTypes.func,
};

FieldValuePair.defaultProps = {
  icon: 'icon-clock-in',
  iconClassName: EMPTY_STRING,
  onClickIcon: _noop,
};

export default FieldValuePair;
