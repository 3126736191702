import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _uniq from 'lodash/uniqBy';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { getActionBuilderList, getPayloadForModifiedByIds, getRawData } from './actionBuilderList.helpers';
import { ACTIVATE_MODAL_CONTENT, CURRENT_PAGE, CUSTOM_ACTIONS, DEACTIVATE_MODAL_CONTENT, STATUSES } from '../constants/actionBuilderList.constants';
import { fetchActions, updateAction } from '../../../../../../actions/actionBuilder.actions';
import { getWorkspaceUserList } from '../../../../../../actions/workspaceUserManagement.actions';

import PAGE_IDS from '../../../../constants/PageIds.constants';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../constants/actionBuilder.constants';

const getActionBuilderListData = async (data) => {
  const userIds = _map(data, (item) => item.lastModifiedBy);
  const uniqueIds = _uniq(userIds);
  const filters = getPayloadForModifiedByIds(uniqueIds);
  const usersData = await getWorkspaceUserList({ filters });
  const users = tget(usersData, 'hits', EMPTY_ARRAY);
  const actionBuilderList = getActionBuilderList(data, users);

  return actionBuilderList;
};

const fetchAllActions = async ({ params, getState, setState }) => {
  const { pageSize, currentPage, searchField, searchText, selectedFilters, match = EMPTY_OBJECT } = getState();
  const entityName = _get(match, 'params.entityName', EMPTY_STRING);

  setState({ loading: true });
  const currentPageToken = _get(params, 'nextPageToken');
  const actionBuilderDataResponse = await fetchActions(
    getRawData({
      searchText,
      searchField,
      selectedFilters,
      pageSize,
      currentPage,
      nextPageToken: currentPageToken,
      entityName,
    }),
  );
  let actionBuilderList = tget(actionBuilderDataResponse, 'hits', EMPTY_ARRAY);

  if (!_isEmpty(actionBuilderList)) {
    actionBuilderList = await getActionBuilderListData(actionBuilderList);
  }

  setState({
    actionBuilderList,
    isLoaded: true,
    loading: false,
    nextPageToken: _get(actionBuilderDataResponse, 'nextPageToken', ''),
  });
};

const handleRefresh = ({ getState, setState }) => {
  setState({ currentPage: CURRENT_PAGE }, () => {
    fetchAllActions({ params: { nextPageToken: EMPTY_STRING }, getState, setState });
  });
};
const handleSearchApply = ({ params, getState, setState }) => {
  setState({ searchText: _get(params, 'value', ''), currentPage: CURRENT_PAGE }, () => {
    fetchAllActions({ params: { nextPageToken: EMPTY_STRING }, getState, setState });
  });
};

const handleCreateNewAction = ({ getState }) => {
  const { history, match } = getState();
  const entityName = _get(match, 'params.entityName', '');
  history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.ACTION_BUILDER_CREATE}` });
};

const handlePageUpdate = ({ params, getState, setState }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');
  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }

  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      fetchAllActions({ params: { nextPageToken: currentPageToken }, getState, setState });
    },
  );
};

const handleEditRequest = ({ params, getState }) => {
  const { history, match } = getState();

  const actionName = _get(params, `value.${ACTION_DEFINITION_FIELD_IDS.ACTION_NAME}`);
  const entityName = _get(match, 'params.entityName', '');

  if (!_isEmpty(actionName)) {
    history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.ACTION_BUILDER_EDIT}/${actionName}` });
  }
};

const handleRowClick = ({ params, getState }) => {
  const { history, match } = getState();

  const actionName = _get(params, `value.original.${ACTION_DEFINITION_FIELD_IDS.ACTION_NAME}`);
  const entityName = _get(match, 'params.entityName', '');

  if (!_isEmpty(actionName)) {
    history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.ACTION_BUILDER_EDIT}/${actionName}` });
  }
};

const handleSetFilter = ({ params, getState, setState }) => {
  setState({ loading: true });
  const value = _get(params, 'value', EMPTY_ARRAY);
  setState({ selectedFilters: value, currentPage: CURRENT_PAGE }, () => {
    fetchAllActions({ params: { nextPageToken: EMPTY_STRING }, getState, setState });
  });
};

const handleConfirmRequest = async ({ getState, setState }) => {
  setState({ showModal: false, loading: true, currentPage: CURRENT_PAGE });
  const { value } = getState();

  const status = _get(value, 'status', '');
  const actionName = _get(value, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME);
  const newStatus = status === STATUSES.ACTIVE ? STATUSES.INACTIVE : STATUSES.ACTIVE;

  const payload = { ...value, status: newStatus };

  const data = await updateAction(actionName, payload);

  if (!_isEmpty(data)) {
    fetchAllActions({ params: { nextPageToken: EMPTY_STRING }, getState, setState });
  }
};

const handleActivateStatus = async ({ params, setState }) => {
  setState({ showModal: true, modalContent: `${ACTIVATE_MODAL_CONTENT} ${params.value.name}`, value: params.value });
};

const handleDeactivateStatus = async ({ params, setState }) => {
  setState({ showModal: true, modalContent: `${DEACTIVATE_MODAL_CONTENT} ${params.value.name}`, value: params.value });
};

const handleCloseModalRequest = ({ setState }) => {
  setState({ showModal: false });
};

const ACTION_HANDLERS = {
  [CUSTOM_ACTIONS.CREATE_NEW]: handleCreateNewAction,
  [CUSTOM_ACTIONS.EDIT]: handleEditRequest,
  [CUSTOM_ACTIONS.ACTIVATE]: handleActivateStatus,
  [CUSTOM_ACTIONS.DEACTIVATE]: handleDeactivateStatus,
  [CUSTOM_ACTIONS.CLOSE_MODAL_REQUEST_DIALOG]: handleCloseModalRequest,
  [CUSTOM_ACTIONS.CONFIRM_MODAL_REQUEST]: handleConfirmRequest,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleRefresh,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchApply,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleSetFilter,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleRowClick,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: fetchAllActions,
};
export default ACTION_HANDLERS;
