import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

// Constants
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { USER_INPUT_TYPES } from '../../../constants/actionBuilder.constants';

// Helpers
import { getCompactedFiltersPayload } from '../../../helpers/general.helpers';

import { ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_FIELD_IDS } from '../../../constants/viewBuilder.constants';

// Readers
import entityDefinitionReader from '../../../readers/entity.reader';

const getSelectedInputType = (formViewName, selectedFieldsForInput) => {
  if (!_isEmpty(formViewName)) return USER_INPUT_TYPES.FORM;
  if (!_isEmpty(selectedFieldsForInput)) return USER_INPUT_TYPES.FIELD;
  return false;
};

const getComplexFieldsAndViewNames = (
  section,
  fieldDefinitionByName,
  complexLookUpEntities,
  viewConfigsToFetchForNames,
  complexFieldNameViewNameMap,
) => {
  const type = _get(section, 'type', '');
  if (type === 'FORM_VIEW_RENDERER') {
    const formFields = _get(section, 'rendererProperty.formFields', []);
    _forEach(formFields, (fieldProp, fieldName) => {
      const dataType = _get(fieldDefinitionByName, `${fieldName}.dataType`);
      const lookUpEntity = _get(fieldDefinitionByName, `${fieldName}.complexFieldDefinition.entityName`);
      if (dataType === 'COMPLEX') {
        const viewName = _get(fieldProp, ALL_VIEW_PROPERTY_KEYS.COMPLEX_VIEW_NAME);
        complexLookUpEntities.push(lookUpEntity);
        viewConfigsToFetchForNames.push(viewName);
        _set(complexFieldNameViewNameMap, fieldName, viewName);
      }
    });
  } else {
    _forEach(_get(section, 'children'), (child) => {
      getComplexFieldsAndViewNames(child, fieldDefinitionByName, complexLookUpEntities, viewConfigsToFetchForNames, complexFieldNameViewNameMap);
    });
  }
};

const getSearchPayloadsAndMapForNestedEntities = (entityDef, formViewConfig) => {
  const entityDefsToFetch = [];
  const viewConfigsToFetchForNames = [];
  const complexFieldNameViewNameMap = {};
  const section = _get(formViewConfig, 'section');
  const fieldDefinitionByName = _keyBy(entityDefinitionReader.fieldDefinitions(entityDef), 'name');
  getComplexFieldsAndViewNames(section, fieldDefinitionByName, entityDefsToFetch, viewConfigsToFetchForNames, complexFieldNameViewNameMap);

  const entityDefsFilter = [
    {
      field: 'name',
      values: entityDefsToFetch,
      filterType: OPERATORS.IN,
    },
  ];
  const viewDefsFilter = [
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.NAME,
      values: viewConfigsToFetchForNames,
      filterType: OPERATORS.IN,
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      filterType: OPERATORS.IN,
      values: _castArray(entityDefinitionReader.name(entityDef)),
    },
  ];

  const entityDefsSearchPayload = { filters: getCompactedFiltersPayload(entityDefsFilter) };
  const viewConfigsSearchPayload = { filters: getCompactedFiltersPayload(viewDefsFilter) };

  return { entityDefsSearchPayload, viewConfigsSearchPayload, complexFieldNameViewNameMap };
};

const generateInputFormViewConfiguration = () => {};

export { getSelectedInputType, generateInputFormViewConfiguration, getSearchPayloadsAndMapForNestedEntities };
