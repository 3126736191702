import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import ListViewRenderer from '../listViewRenderer/ListViewRenderer';

import styles from './gridViewRenderer.module.scss';

const GridViewRenderer = ({ className, componentConfig, entity, ...restProps }) => (
  <div className={cx(styles.gridViewRenderer, styles.builderMode, className)}>
    <ListViewRenderer componentConfig={componentConfig} entity={entity} {...restProps} />
  </div>
);

GridViewRenderer.propTypes = {
  className: PropTypes.string,
  componentConfig: PropTypes.object,
  entity: PropTypes.object,
  entityRecord: PropTypes.object,
};

GridViewRenderer.defaultProps = {
  className: '',
  componentConfig: {
    sectionId: '',
    children: [],
  },
  entity: EMPTY_OBJECT,
  entityRecord: EMPTY_OBJECT,
};

export default React.memo(GridViewRenderer);
