import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _size from 'lodash/size';

// Components
import withDragNDropTable from '@tekion/tekion-components/organisms/withDragNDropTable';
import BaseTable from '@tekion/tekion-components/molecules/table/BaseTable';
import withCheckboxTable from '@tekion/tekion-components/organisms/withCheckboxTable/withCheckBoxTable';

// Constants
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { getColumns } from './configTable.columns';
import { ACTION_TYPES } from '../../constants/createView.constants';

const DraggableTable = withDragNDropTable(withCheckboxTable(BaseTable));

const ConfigTable = ({ onAction, viewType, value, fieldClassName, selectedColumnIds, ...restProps }) => {
  const pageSize = _size(value);
  const columns = getColumns(viewType);

  const handleRowToggle = useCallback(
    (_selectedColumnIds) => {
      onAction({ type: ACTION_TYPES.ROW_SELECT, payload: { selectedColumnIds: _selectedColumnIds } });
    },
    [onAction],
  );

  const getTdProps = useCallback(() => ({ onAction }), [onAction]);

  return (
    <DraggableTable
      className={fieldClassName}
      rowHeight={48}
      shouldAddNewRow={false}
      pageSize={pageSize}
      columns={columns}
      data={value}
      value={value}
      getTdProps={getTdProps}
      selection={selectedColumnIds}
      onSelect={handleRowToggle}
      onAction={onAction}
      {...restProps}
    />
  );
};

ConfigTable.propTypes = {
  fieldClassName: PropTypes.string,
  viewType: PropTypes.string.isRequired,
  value: PropTypes.array,
  selectedColumnIds: PropTypes.arrayOf(PropTypes.string),
  onAction: PropTypes.func.isRequired,
};

ConfigTable.defaultProps = {
  value: EMPTY_ARRAY,
  fieldClassName: EMPTY_STRING,
  selectedColumnIds: EMPTY_ARRAY,
};

export default React.memo(ConfigTable);
