import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Icon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import Button from '@tekion/tekion-components/atoms/Button';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import TagGenerator from '../../../../molecules/tagGenerator/TagGenerator';
import { CUSTOM_ACTIONS } from '../../constants/recordTypeList.constants';
import { RECORD_TYPES } from '../../../../../../constants/general.constants';

import styles from './leftSubheader.module.scss';

const LeftSubHeaderSection = (props) => {
  const { recordType, derivationFields, onAction } = props;

  const handleEdit = useCallback(() => {
    onAction({ type: CUSTOM_ACTIONS.ON_EDIT_CLICK });
  }, [onAction]);

  const renderDerivationField = useCallback(
    (fields) => (
      <div className={styles.derivationFieldsContainer}>
        <div>{__('Derivation Fields: ')}</div>
        <Button className={styles.button} onClick={handleEdit} view={Button.VIEW.TERTIARY}>
          {__('Add ')}
        </Button>
        <TagGenerator fields={fields} />
        <Icon className={styles.editIcon} size={SIZES.MD_S} onClick={handleEdit}>
          icon-edit
        </Icon>
      </div>
    ),
    [handleEdit],
  );

  return (
    <div className={styles.leftSubheaderContainer}>
      <div>{__('Record Type : ')}</div>
      <div className={styles.recordTypeValue}>{__(`${recordType}`)}</div>
      <Icon className={styles.editIcon} size={SIZES.MD_S} onClick={handleEdit}>
        icon-edit
      </Icon>
      {recordType === RECORD_TYPES.DERIVED && renderDerivationField(derivationFields)}
    </div>
  );
};

LeftSubHeaderSection.propTypes = {
  recordType: PropTypes.string,
  derivationFields: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

LeftSubHeaderSection.defaultProps = {
  recordType: RECORD_TYPES.STANDARD,
  derivationFields: EMPTY_ARRAY,
};

export default makeCellRenderer(LeftSubHeaderSection);
