// Tekion-components
import Button from '@tekion/tekion-components/atoms/Button';

// Constants
import { STATUS } from '@tekion/tekion-base/constants/statuses';
import { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';
import ACTION_TYPES from './workflowList.actionTypes';

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create New'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_WORKFLOW,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showHeader: false,
  showSearch: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const WORKFLOW_STATUS_COLOR_MAP = {
  [STATUS.PUBLISHED]: COLOR_MAP.GREEN,
  [STATUS.DRAFT]: COLOR_MAP.RED,
};

const WORKFLOW_STATUS_LABEL_MAP = {
  [STATUS.PUBLISHED]: __('Active'),
  [STATUS.DRAFT]: __('Inactive'),
};

export { SUB_HEADER_PROPS, TABLE_MANAGER_PROPS, WORKFLOW_STATUS_COLOR_MAP, WORKFLOW_STATUS_LABEL_MAP };
