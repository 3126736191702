import Button from '@tekion/tekion-components/atoms/Button';

import ACTION_TYPES from './serverCredentialList.actionTypes';

const HEADER_PROPS = {
  label: __('Server Credentials'),
};

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create Server Credential'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_SERVER_CREDENTIAL,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showHeader: true,
  showSearch: false,
  showSubHeader: true,
  showRefreshIcon: true,
  showFilter: false,
};

const EDIT_SERVER_CREDENTIAL = {
  id: ACTION_TYPES.EDIT_SERVER_CREDENTIAL,
  name: __('Edit'),
};

const ROW_ACTIONS = [EDIT_SERVER_CREDENTIAL];

const STATUS = {
  ACTIVE: true,
  INACTIVE: false,
};

const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

const INITIAL_STATE_SERVER_CREDENTIAL_LIST = {
  currentPage: 0,
  pageSize: 50,
};

export { HEADER_PROPS, SUB_HEADER_PROPS, TABLE_MANAGER_PROPS, ROW_ACTIONS, STATUS, TABLE_CONSTANTS, INITIAL_STATE_SERVER_CREDENTIAL_LIST };
