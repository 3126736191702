exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".createUserGroup_form__bcoKAYrR3P{overflow-y:auto}.createUserGroup_body__txwGbAi6Ny{overflow-y:auto}.createUserGroup_footerContainer__rtWCLMDyK4{height:6.4rem}.createUserGroup_halfColumn__5m5jSzQptB{width:calc((100% - 2.4rem)/2)}[dir] .createUserGroup_inputTable__rieZu3uUzd{margin:3rem}.createUserGroup_addUserHeader__oqJGm4DKys{display:flex;justify-content:space-between}[dir] .createUserGroup_addUserHeader__oqJGm4DKys{margin-bottom:1rem}.createUserGroup_fullWidthSection__4us978uphz{max-width:120rem}", ""]);

// Exports
exports.locals = {
	"form": "createUserGroup_form__bcoKAYrR3P",
	"body": "createUserGroup_body__txwGbAi6Ny",
	"footerContainer": "createUserGroup_footerContainer__rtWCLMDyK4",
	"halfColumn": "createUserGroup_halfColumn__5m5jSzQptB",
	"inputTable": "createUserGroup_inputTable__rieZu3uUzd",
	"addUserHeader": "createUserGroup_addUserHeader__oqJGm4DKys",
	"fullWidthSection": "createUserGroup_fullWidthSection__4us978uphz"
};