import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import Modal from '@tekion/tekion-components/molecules/Modal';

import AsyncTag from '../asyncTag';
import UserRoleSelect from '../userRoleSelect';
import ACTION_TYPES from '../../constants/roleConfigure.actionTypes';

import styles from './addUserRoleModal.module.scss';

const AddUserRoleModal = ({ visible, isAssignModalSubmitting, modalData, rolesDataById, existingUserIds, onAction }) => {
  const [modalState, setModalState] = useState({ users: [] });

  useEffect(() => {
    setModalState({ users: tget(modalData, 'selectedUsers', []) });
  }, [modalData]);

  const handleChange = useCallback((selectedUsers) => {
    setModalState({ users: selectedUsers });
  }, []);

  const handleTagDelete = useCallback((updatedUsers) => {
    setModalState({ users: updatedUsers });
  }, []);

  const handleModalSubmit = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_MODAL_SAVE,
      payload: modalState,
    });
  }, [onAction, modalState]);

  const handleClose = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_MODAL_CLOSE,
    });
  }, [onAction]);

  return (
    <Modal
      loading={isAssignModalSubmitting}
      visible={visible}
      title={__('Assign User')}
      onCancel={handleClose}
      width={Modal.SIZES.MD}
      destroyOnClose
      onSubmit={handleModalSubmit}
    >
      <div className={styles.select}>
        <UserRoleSelect
          placeholder={__('Select Users')}
          key="select-bar"
          values={modalState.users}
          onChange={handleChange}
          existingUserIds={existingUserIds}
          rolesDataById={rolesDataById}
          isMulti
        />
      </div>
      <AsyncTag value={modalState.users} onChange={handleTagDelete} />
    </Modal>
  );
};

AddUserRoleModal.propTypes = {
  visible: PropTypes.bool,
  isAssignModalSubmitting: PropTypes.bool,
  modalData: PropTypes.object,
  rolesDataById: PropTypes.object,
  existingUserIds: PropTypes.array,
  onAction: PropTypes.func,
};

AddUserRoleModal.defaultProps = {
  visible: false,
  isAssignModalSubmitting: false,
  modalData: EMPTY_OBJECT,
  rolesDataById: EMPTY_OBJECT,
  existingUserIds: EMPTY_ARRAY,
  onAction: _noop,
};
export default React.memo(AddUserRoleModal);
