exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".tenantUserForm_userFormSections__gVfxYcGrE6{max-width:100rem}.tenantUserForm_userStatusFormContainer__86JcWWnKHU{font-family:\"Proxima-Nova-Regular\";font-size:1.4rem;letter-spacing:.03rem;color:#444f5c;line-height:1.7rem}[dir] .tenantUserForm_userStatusFormContainer__86JcWWnKHU{margin:0}.tenantUserForm_spinnerWrap__e7bBvdK8gt{display:flex;justify-content:center;align-items:center;width:100%;height:100%}.tenantUserForm_formSectionHeader__9i45Zb6Vb4{font-size:1.8rem;max-width:100rem}.tenantUserForm_userDetailModalContainer__vQfhxM1BwK{display:flex;flex-direction:column;align-items:flex-start;width:100%}[dir] .tenantUserForm_userDetailModalContainer__vQfhxM1BwK{margin-bottom:1rem}", ""]);

// Exports
exports.locals = {
	"userFormSections": "tenantUserForm_userFormSections__gVfxYcGrE6",
	"userStatusFormContainer": "tenantUserForm_userStatusFormContainer__86JcWWnKHU",
	"spinnerWrap": "tenantUserForm_spinnerWrap__e7bBvdK8gt",
	"formSectionHeader": "tenantUserForm_formSectionHeader__9i45Zb6Vb4",
	"userDetailModalContainer": "tenantUserForm_userDetailModalContainer__vQfhxM1BwK"
};