import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _unset from 'lodash/unset';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import FIELD_IDS from '../constants/tenantUserForm.fieldIds';
import tenantUserReader from '../../../../../../readers/tenantUser.reader';

const mapPhoneDataToApiFormat = (userFormValues) => {
  const phoneNo = _get(userFormValues, FIELD_IDS.PHONE);
  const phone = _get(phoneNo, 'phoneNumber', EMPTY_STRING);
  const countryCode = _get(phoneNo, 'phoneCode', EMPTY_STRING);

  return { phone, countryCode };
};

const mapUserWorkspaceListToApiFormat = (userWorkspaceList) => {
  _forEach(userWorkspaceList, (workspace) => {
    _unset(workspace, 'checkbox');
  });

  return userWorkspaceList;
};

const mapUserDataToApiFormat = (userFormValues, userWorkspaceList, homeWorkspaceIndex) => {
  const name = _get(userFormValues, FIELD_IDS.NAME);
  const firstName = _get(name, FIELD_IDS.FIRST_NAME);
  const lastName = _get(name, FIELD_IDS.LAST_NAME);
  const profilePicture = _get(userFormValues, FIELD_IDS.PROFILE_PICTURE);
  const email = _get(userFormValues, FIELD_IDS.EMAIL);
  const { phone, countryCode } = mapPhoneDataToApiFormat(userFormValues);
  const displayName = _get(userFormValues, FIELD_IDS.DISPLAY_NAME);
  const active = _get(userFormValues, FIELD_IDS.ACTIVE, false);
  const homeWorkspaceId = _get(userWorkspaceList[homeWorkspaceIndex], 'workspaceId', null);
  const userWorkspaceDetails = mapUserWorkspaceListToApiFormat(userWorkspaceList);

  const finalObject = {
    firstName,
    lastName,
    email,
    phone,
    profilePicture,
    countryCode,
    displayName,
    homeWorkspaceId,
    userWorkspaceDetails,
    active: !active,
  };

  return finalObject;
};

const mapApiDataToForm = (params) => {
  let userFormValues = {};
  userFormValues = {
    ...userFormValues,
    [FIELD_IDS.NAME]: { firstName: tenantUserReader.firstName(params), lastName: tenantUserReader.lastName(params) },
    [FIELD_IDS.PHONE]: { phoneNumber: tenantUserReader.phone(params), phoneCode: tenantUserReader.countryCode(params) },
    [FIELD_IDS.EMAIL]: tenantUserReader.email(params),
    [FIELD_IDS.ACTIVE]: !tenantUserReader.active(params),
    [FIELD_IDS.DISPLAY_NAME]: tenantUserReader.displayName(params),
    [FIELD_IDS.PROFILE_PICTURE]: tenantUserReader.profilePicture(params),
  };

  return {
    userFormValues,
  };
};

export { mapUserDataToApiFormat, mapApiDataToForm };
