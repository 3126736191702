import _castArray from 'lodash/castArray';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import FilterBuilder from '../../../../../../../../../builders/filterBuilder';

import { getWorkspaceUserList } from '../../../../../../../../../actions/workspaceUserManagement.actions';

import { ShareAndManageRecordUserDetailGetters } from '../../../../../../../hooks/useShareAndManageRecordModal/shareAndManageRecordUserData';

const getUsersDetailsServiceHandler = (addNewUsersHandler) => async (payload) => {
  const workspaceUsersResponse = await getWorkspaceUserList(payload);
  const value = tget(workspaceUsersResponse, 'hits', EMPTY_ARRAY);
  addNewUsersHandler(value);

  return workspaceUsersResponse;
};

const ROW_COUNT = 20;

const getAllUserIds = (shareAndManageRecordUserDetails) =>
  _map(shareAndManageRecordUserDetails, (userDetail) => ShareAndManageRecordUserDetailGetters.id(userDetail));

const getPayloadForUsers =
  (shareAndManageRecordUserDetails) =>
  ({ nextPageToken, searchText }) => {
    const filters = new FilterBuilder()
      .addNameFilter({ values: _castArray(searchText), operator: OPERATORS.TEXT_STARTS_WITH })
      .addIdFilter({ values: getAllUserIds(shareAndManageRecordUserDetails), operator: OPERATORS.NIN })
      .getFilterPayload();

    return {
      filters,
      rows: ROW_COUNT,
      nextPageToken,
    };
  };

export { getUsersDetailsServiceHandler, getPayloadForUsers };
