const FIELD_IDS = {
  ACTIONS_TO_SHOW: 'actionsToShow',
};

const ROWS = 20;

const ENTITY = 'ENTITY';
const ACTIVE = 'ACTIVE';

const ACTIONS_CONFIGURATOR_FORM_CONTEXT_ID = 'VISUAL_ACTIONS_CONFIGURATOR_FORM';

export { FIELD_IDS, ROWS, ACTIONS_CONFIGURATOR_FORM_CONTEXT_ID, ENTITY, ACTIVE };
