import React, { useCallback } from 'react';
import _noop from 'lodash/noop';
import PropTypes from 'prop-types';
import cx from 'classnames';

function DefaultCellActionElement(props) {
  const { label, onAction, actionType, className } = props;
  const onActionClick = useCallback(() => {
    onAction({
      type: actionType,
    });
  }, [onAction, actionType]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={onActionClick} className={cx(className, 'cursor-pointer')} type="button">
      {label}
    </div>
  );
}

DefaultCellActionElement.propTypes = {
  label: PropTypes.string,
  onAction: PropTypes.func,
  actionType: PropTypes.string,
  className: PropTypes.string,
};

DefaultCellActionElement.defaultProps = {
  label: undefined,
  onAction: _noop,
  actionType: undefined,
  className: undefined,
};

export default DefaultCellActionElement;
