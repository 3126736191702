import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import ProfileServices from '../services/profile.services';

const getProfileList = async (payload = EMPTY_OBJECT) => {
  try {
    const receivedData = await ProfileServices.fetchProfileList(payload);

    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch profiles, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const getProfileByName = async (name) => {
  try {
    const receivedData = await ProfileServices.fetchProfileByName(name);

    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch profile, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getAllProfilesByWorkspaceId = async (workspaceId) => {
  try {
    const receivedData = await ProfileServices.getAllProfilesByWorkspaceId(workspaceId);

    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch profiles, please try again later.')));

    return EMPTY_ARRAY;
  }
};

export { getProfileList, getProfileByName, getAllProfilesByWorkspaceId };
