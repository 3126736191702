const ROW_ACTION_PROPS = {
  title: __('Actions'),
  width: 80,
  visibleActionCount: 1,
};

const SECTION_TABLE_ROW_STYLE = {
  height: '100%',
  alignItems: 'center',
  padding: '0.8rem',
};

const TABLE_ACTION_TYPES = {
  TABLE_ACTION_CLICK: 'TABLE_ACTION_CLICK',
  ON_ADD_REQUEST_DETAIL: 'ON_ADD_REQUEST_DETAIL',
  ON_REMOVE: 'ON_REMOVE',
};

const ROW_ACTIONS = [
  {
    id: TABLE_ACTION_TYPES.ON_REMOVE,
    name: __('Remove'),
    icon: 'icon-trash',
  },
];

export { SECTION_TABLE_ROW_STYLE, ROW_ACTION_PROPS, TABLE_ACTION_TYPES, ROW_ACTIONS };
