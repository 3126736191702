import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Resizable } from 're-resizable';

// styles
import styles from './tableCellResizer.module.scss';

// context
import TableColumnSizeContext from '../../../context/TableColumnSizeContext';

// hooks
import useTableCellResize from './hooks/useTableCellResize';
import useEditor from '../../../../../hooks/useEditor';
import useTableCellResizerStyles from './hooks/useTableCellResizerStyles';

// constants
import { RESIZER_ENABLE, RESIZER_HANDLE_STYLES, RESIZER_STYLES } from './constants/tableCellResizer.general';

function TableCellResizer(props) {
  const { element } = props;
  const editor = useEditor();
  const { columnId } = element;
  const { setResizingColumn, hoveredColumnId, setHoveredColumnId, resetHoveredColumnId } = useContext(TableColumnSizeContext);

  const setCurrentColumnAsHovered = useCallback(() => setHoveredColumnId(columnId), [columnId, setHoveredColumnId]);

  const { handleResize, handleResizeStop, handleResizeStart, isResizing } = useTableCellResize({
    setResizingColumn,
    columnId,
    element,
    editor,
  });

  const { resizerWrapperStyles, rightResizeHandleStyle } = useTableCellResizerStyles(isResizing, hoveredColumnId, columnId);

  return (
    <div
      className={`${styles.resizableWrapper} absolute full-height full-width`}
      contentEditable={false}
      onMouseOver={setCurrentColumnAsHovered}
      onFocus={setCurrentColumnAsHovered}
      onMouseOut={resetHoveredColumnId}
      onBlur={resetHoveredColumnId}
      style={resizerWrapperStyles}
    >
      <Resizable
        size={RESIZER_STYLES}
        enable={RESIZER_ENABLE}
        onResize={handleResize}
        onResizeStop={handleResizeStop}
        handleStyles={RESIZER_HANDLE_STYLES}
        onResizeStart={handleResizeStart}
      />
      <div className={`${styles.handle} absolute`} style={rightResizeHandleStyle} />
    </div>
  );
}

TableCellResizer.propTypes = {
  element: PropTypes.object.isRequired,
};

export default React.memo(TableCellResizer);
