import _map from 'lodash/map';

import { getAllProfilesByWorkspaceId } from '../../../../../../../actions/profile.actions';

import profileReader from '../../../../../../../readers/profile.reader';

const getOptionsForProfile = (profileList) =>
  _map(profileList, (profile) => ({
    label: profileReader.displayName(profile),
    value: profileReader.name(profile),
  }));

const serviceHandlerForGetAllProfilesByWorkspaceId = async (workspaceId) => {
  const response = await getAllProfilesByWorkspaceId(workspaceId);
  return { hits: response };
};

export { getOptionsForProfile, serviceHandlerForGetAllProfilesByWorkspaceId };
