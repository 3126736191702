import { MenuOptionBuilder } from '../../../../../../../atoms/menu';

import { SHARE_RECORD_CONFIG, MANAGE_ACCESS_RECORD_CONFIG } from '../useShareAndManageRecordActions.constants';

const generateOptions = (onOptionClick) => {
  const MenuOptionObject = new MenuOptionBuilder();

  MenuOptionObject.addOption({
    label: SHARE_RECORD_CONFIG.LABEL,
    id: SHARE_RECORD_CONFIG.ID,
    onClick: onOptionClick,
  }).addOption({
    label: MANAGE_ACCESS_RECORD_CONFIG.LABEL,
    id: MANAGE_ACCESS_RECORD_CONFIG.ID,
    onClick: onOptionClick,
  });

  return MenuOptionObject.getOptions();
};

export { generateOptions };
