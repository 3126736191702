const FIELD_NAMES = {
  NEW_PASSWORD: 'newPassword',
  CONFIRM_NEW_PASSWORD: 'confirmNewPassword',
  CHAR_COUNT: 'charCount',
  UPPERCASE_CHAR: 'upperCaseChar',
  LOWERCASE_CHAR: 'lowerCaseChar',
  NUMBER: 'number',
  SYMBOL: 'symbol',
  USER_INFO: 'userInfo',
  MAX_CHAR_COUNT: 'maxCharCount',
};

const PASSWORD_STATUS = {
  PASS: 'pass',
  FAIL: 'fail',
  INITIAL: 'initial',
};

const ICON_TYPES = {
  [PASSWORD_STATUS.PASS]: 'icon-passed',
  [PASSWORD_STATUS.FAIL]: 'icon-failed',
  [PASSWORD_STATUS.INITIAL]: 'icon-info',
};

const ICON_COLOR = {
  [PASSWORD_STATUS.PASS]: 'green',
  [PASSWORD_STATUS.FAIL]: 'red',
  [PASSWORD_STATUS.INITIAL]: 'black',
};

const UPDATE_PASSWORD_FORM_CONTEXT_ID = 'UPDATE_PASSWORD_FORM_CONTEXT_ID';

const PASSWORD_POLICY_DEFAULTS = {
  MAX_LENGTH: 20,
  MIN_LENGTH: 8,
  MIN_SYMBOLS: 1,
  MIN_DIGITS: 1,
  REQUIRE_UPPERCASE: true,
  REQUIRE_LOWERCASE: true,
  REQUIRE_NUMBER: true,
  REQUIRE_SYMBOL: false,
  REQUIRE_USER_INFO: false,
};

const DIGIT_REGEX = /\D/g;
const SPECIAL_CHARS_REGEX = /[^ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;

const UNLOCK_ACCOUNT_PARAMS_VALUE = 'true';

export {
  UNLOCK_ACCOUNT_PARAMS_VALUE,
  SPECIAL_CHARS_REGEX,
  DIGIT_REGEX,
  UPDATE_PASSWORD_FORM_CONTEXT_ID,
  PASSWORD_POLICY_DEFAULTS,
  ICON_COLOR,
  ICON_TYPES,
  PASSWORD_STATUS,
  FIELD_NAMES,
};
