import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import withProps from 'recompose/withProps';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _head from 'lodash/head';
import _castArray from 'lodash/castArray';
import _isNumber from 'lodash/isNumber';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { NativeSelect as Select } from '@tekion/tekion-components/molecules/advancedSelect';
import withAsyncSelect from '@tekion/tekion-components/molecules/advancedSelect/containers/withAsyncSelect';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import Error from '@tekion/tekion-components/organisms/FormBuilder/components/error';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import Menu from './components/Menu';

import { fetchEntityRecords } from '../../actions/recordManagement.actions';
import { getAllRecordGroups } from '../../actions/recordGroup.actions';
import { getHasMore, getLoadingMessage, getOptions, getPayload, modifyOptions, covertSelectedValues } from './groupedRelationshipField.helper';
import { TEXT_STARTS_WITH } from '../../constants/filter.constants';

const AsyncSelect = withAsyncSelect(Select);

const GroupedRelationshipField = (props) => {
  const { isMulti, value, dropDownClassName, id, label, lookUpEntityName, lookUpDisplayName, error, onAction, ...restProps } = props;

  const [searchText, setSearchText] = useState(EMPTY_STRING);
  const [recordGroupSectionInfo, setRecordGroupSectionInfo] = useState({ hasMore: true, nextPageToken: '' });
  const [recordSectionInfo, setRecordSectionInfo] = useState({ hasMore: true, nextPageToken: '' });
  const [selectedValues, setSelectedValues] = useState([]);

  const [options, setOptions] = useState(EMPTY_ARRAY);

  const getEntityRecordGroupList = useCallback(
    async (inputString) => {
      let entityRecordGroupPayload = {};
      let entityRecordGroupList = [];
      const recordGroupPayloadParams = {
        filters: [
          {
            field: 'name',
            values: [inputString],
            filterType: TEXT_STARTS_WITH,
          },
          {
            field: 'assetType',
            values: [lookUpEntityName],
            filterType: 'IN',
          },
        ],
      };

      if (inputString !== searchText) {
        entityRecordGroupPayload = getPayload(inputString, EMPTY_STRING, recordGroupPayloadParams);
        setRecordGroupSectionInfo((prev) => ({ ...prev, nextPageToken: EMPTY_STRING }));
      } else {
        const nextPageToken = _get(recordGroupSectionInfo, 'nextPageToken');
        entityRecordGroupPayload = getPayload(inputString, nextPageToken, recordGroupPayloadParams);
      }

      let currentNextPageToken = _get(recordGroupSectionInfo, 'nextPageToken');

      if (lookUpEntityName) {
        const entityRecordGroupResponse = await getAllRecordGroups(entityRecordGroupPayload);
        entityRecordGroupList = tget(entityRecordGroupResponse, 'hits', []);
        currentNextPageToken = _get(entityRecordGroupResponse, 'nextPageToken');

        setRecordGroupSectionInfo((prev) => ({ ...prev, nextPageToken: currentNextPageToken }));
      }
      const hasMoreGroups = getHasMore(currentNextPageToken);
      setRecordGroupSectionInfo((prev) => ({ ...prev, hasMore: hasMoreGroups }));

      return entityRecordGroupList;
    },
    [lookUpEntityName, searchText, recordGroupSectionInfo],
  );

  const getEntityRecordList = useCallback(
    async (inputString) => {
      let entityRecordPayload = {};
      let entityRecordList = [];
      const recordPayloadParams = {
        filters: [
          {
            field: 'entityName',
            values: [inputString],
            filterType: TEXT_STARTS_WITH,
          },
        ],
      };

      if (inputString !== searchText) {
        entityRecordPayload = getPayload(inputString, EMPTY_STRING, recordPayloadParams);
        setRecordSectionInfo((prev) => ({ ...prev, nextPageToken: EMPTY_STRING }));
      } else {
        const nextPageToken = _get(recordSectionInfo, 'nextPageToken');
        entityRecordPayload = getPayload(inputString, nextPageToken, recordPayloadParams);
      }
      let currentNextPageToken = _get(recordSectionInfo, 'nextPageToken');

      if (lookUpEntityName) {
        const entityRecordResponse = await fetchEntityRecords(lookUpEntityName, entityRecordPayload);
        entityRecordList = tget(entityRecordResponse, 'hits', []);
        currentNextPageToken = _get(entityRecordResponse, 'nextPageToken');

        setRecordSectionInfo((prev) => ({ ...prev, nextPageToken: currentNextPageToken }));
      }
      const hasMoreRecords = getHasMore(currentNextPageToken);
      setRecordSectionInfo((prev) => ({ ...prev, hasMore: hasMoreRecords }));

      return entityRecordList;
    },
    [lookUpEntityName, searchText, recordSectionInfo],
  );

  const handleLoadOptions = useCallback(
    async (inputString, { index }) => {
      let entityRecordGroupList = [];
      let entityRecordList = [];
      let newOptions = [...options];

      if (inputString !== searchText) {
        setRecordGroupSectionInfo((prev) => ({ ...prev, hasMore: true }));
        setRecordSectionInfo((prev) => ({ ...prev, hasMore: true }));
      }

      if (index !== 0 || inputString !== searchText) {
        entityRecordGroupList = await getEntityRecordGroupList(inputString);
      }

      if (index !== 1 || inputString !== searchText) {
        entityRecordList = await getEntityRecordList(inputString);
      }

      if (_isEmpty(newOptions) || inputString !== searchText) {
        newOptions = getOptions(entityRecordList, entityRecordGroupList, lookUpDisplayName);
      } else {
        newOptions = modifyOptions(entityRecordList, entityRecordGroupList, lookUpDisplayName, options);
      }

      if (!_isNumber(index)) {
        const updatedSelectedValues = covertSelectedValues(newOptions, _castArray(value));
        setSelectedValues(updatedSelectedValues);
      }

      setSearchText(inputString);
      setOptions(newOptions);
      return { options: newOptions };
    },
    [lookUpDisplayName, getEntityRecordList, getEntityRecordGroupList, options, searchText, value],
  );

  const onChangeOptionsFromCustomMenu = (optionValue, optionLabel) => {
    const newSelectedValue = [{ label: optionLabel, value: optionValue }];
    let fieldValue = [];

    if (isMulti) {
      let updatedSelectedValues = [...selectedValues];
      const selectedValuesIds = _map(selectedValues, 'value');
      if (!_includes(selectedValuesIds, optionValue)) {
        updatedSelectedValues = [...updatedSelectedValues, ...newSelectedValue];
      } else {
        updatedSelectedValues = _filter(updatedSelectedValues, (selectedValue) => _get(selectedValue, 'value') !== optionValue);
      }
      setSelectedValues(updatedSelectedValues);
      fieldValue = _map(updatedSelectedValues, 'value');
    } else {
      setSelectedValues(newSelectedValue);
      fieldValue = _head(_map(newSelectedValue, 'value'));
    }

    onAction({
      type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
      payload: {
        id,
        value: fieldValue,
      },
    });
  };

  const onChange = useCallback(
    (payload) => {
      let fieldvalue = [];
      if (!payload) {
        setSelectedValues([]);
        if (!isMulti) {
          fieldvalue = '';
        }
      } else {
        setSelectedValues(payload);
        if (isMulti) {
          fieldvalue = _map(payload, 'value');
        } else {
          fieldvalue = _head(_map(payload, 'value'));
        }
      }
      onAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: {
          id,
          value: fieldvalue,
        },
      });
    },
    [isMulti, id, onAction],
  );

  return (
    <div className={fieldLayoutStyles.fieldC}>
      <FieldLabel {...props} />
      <AsyncSelect
        {...restProps}
        refreshOptions
        isMulti={isMulti}
        components={{
          Menu: withProps({
            isMulti,
            searchText,
            groupOptions: options,
            selectedOptions: _map(selectedValues, 'value'),
            sectionWiseInfo: [{ ...recordSectionInfo }, { ...recordGroupSectionInfo }],
            handleLoadOptions,
            onChangeOptions: onChangeOptionsFromCustomMenu,
          })(Menu),
        }}
        onChange={onChange}
        openMenuOnFocus
        loadOptions={handleLoadOptions}
        value={selectedValues}
        loadingMessage={getLoadingMessage}
      />
      <Error error={error} />
    </div>
  );
};

GroupedRelationshipField.propTypes = {
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  dropDownClassName: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  lookUpEntityName: PropTypes.string,
  lookUpDisplayName: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

GroupedRelationshipField.defaultProps = {
  isMulti: true,
  placeholder: __('Type Here'),
  dropDownClassName: EMPTY_STRING,
  id: EMPTY_STRING,
  label: EMPTY_STRING,
  lookUpEntityName: EMPTY_STRING,
  lookUpDisplayName: EMPTY_STRING,
  error: EMPTY_STRING,
  value: EMPTY_ARRAY,
};

export default GroupedRelationshipField;
