import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = 'core/u';
const PROFILE_BASE_ROUTE = `${BASE_URL}/governance/profiles`;

const fetchProfileList = (payload = EMPTY_OBJECT) => ApiService.post(`${PROFILE_BASE_ROUTE}/search`, payload);
const fetchProfileByName = (name) => ApiService.get(`${PROFILE_BASE_ROUTE}/${name}`);

const getAllProfilesByWorkspaceId = (workspaceId) => ApiService.get(`${BASE_URL}/universe/governance/profiles/workspace/${workspaceId}`);

export default {
  fetchProfileList,
  fetchProfileByName,
  getAllProfilesByWorkspaceId,
};
