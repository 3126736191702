const SUBMIT_BTN_TEXT = __('Save');
const MODAL_TITLE = __('Add "Time of the Day" Workflow');
const EXECUTION_IN_DEALERSHIP_HOUR_LABEL = __('Execution during dealership working hours');
const EXECUTION_NOT_IN_DEALERSHIP_HOUR_LABEL = __('Execute during the selected day and time range');
const ERROR = __('Start Time should be less than End Time');

const CONSTANTS = {
  SUBMIT_BTN_TEXT,
  MODAL_TITLE,
  EXECUTION_IN_DEALERSHIP_HOUR_LABEL,
  EXECUTION_NOT_IN_DEALERSHIP_HOUR_LABEL,
  ERROR,
};

const SUNDAY = 'SUNDAY';
const MONDAY = 'MONDAY';
const TUESDAY = 'TUESDAY';
const WEDNESDAY = 'WEDNESDAY';
const THURSDAY = 'THURSDAY';
const FRIDAY = 'FRIDAY';
const SATURDAY = 'SATURDAY';

const DAY_OPTIONS = [
  {
    value: SUNDAY,
    label: 'S',
  },
  {
    value: MONDAY,
    label: 'M',
  },
  {
    value: TUESDAY,
    label: 'T',
  },
  {
    value: WEDNESDAY,
    label: 'W',
  },
  {
    value: THURSDAY,
    label: 'T',
  },
  {
    value: FRIDAY,
    label: 'F',
  },
  {
    value: SATURDAY,
    label: 'S',
  },
];

const ALL_DAYS_SELECTED = [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY];

export default DAY_OPTIONS;

export { ALL_DAYS_SELECTED, CONSTANTS };
