const ACTION_TYPES = {
  ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE: 'ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE',
  ADD_ROW: 'ADD_ROW',
  DELETE_ROW: 'DELETE_ROW',
  ON_CHANGE: 'ON_CHANGE',
  ON_CLICK_ADD_FILTER: 'ON_CLICK_ADD_FILTER',
  ON_SAVE_MODAL: 'ON_SAVE_MODAL',
  ON_CANCEL_MODAL: 'ON_CANCEL_MODAL',
};

export default ACTION_TYPES;
