import _get from 'lodash/get';

import _find from 'lodash/find';
import _set from 'lodash/set';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { COMPONENT_KEYS, PROPERTIES_FIELD_IDS } from '../../../../../constants/applicationBuilder.constants';
import { COMPONENTS_TYPES } from '../../../../../../../../../../../constants/applicationRenderer.constants';
import { FIELD_IDS, NAVIGATION_TYPE_INITIAL_CONFIG, APPROVAL_INTEGRATION_INITIAL_CONFIG } from '../constants/navigationProperties.constants';

const getNavigationProperties = (component, applicationConfig) => {
  const values = {};
  _set(values, PROPERTIES_FIELD_IDS.PAGE_NAME, tget(component, PROPERTIES_FIELD_IDS.PAGE_NAME));
  const componentType = tget(component, 'type');
  if (componentType === COMPONENTS_TYPES.NAVIGATION) {
    _set(values, `${PROPERTIES_FIELD_IDS.IS_NAVIGATION_REQUIRED}`, true);
    _set(values, `${PROPERTIES_FIELD_IDS.NAVIGATION_TYPE}`, tget(component, `properties.${PROPERTIES_FIELD_IDS.NAVIGATION_TYPE}`));
    _set(
      values,
      `${PROPERTIES_FIELD_IDS.APPROVAL_INTEGRATION}`,
      !_isEmpty(_get(applicationConfig, `properties.${PROPERTIES_FIELD_IDS.APPROVAL_META_DATA}`)),
    );
  }
  return values;
};

const setNavigationPropertiesInApplicationConfig = (applicationConfig, properties, propertyId) => {
  let components = _find(
    _get(applicationConfig, 'components', EMPTY_ARRAY),
    (component) => _get(component, 'type') === COMPONENTS_TYPES.NAVIGATION || _get(component, 'type') === COMPONENTS_TYPES.NO_NAVIGATION,
  );

  let componentsObject = { ...components };
  let approvalProperties = tget(applicationConfig, COMPONENT_KEYS.PROPERTIES, {});
  const isNavigationRequired = tget(properties, FIELD_IDS.NAVIGATION, false);

  if (isNavigationRequired) {
    if (propertyId === FIELD_IDS.NAVIGATION) {
      componentsObject = {
        ...componentsObject,
        ...NAVIGATION_TYPE_INITIAL_CONFIG,
      };
    } else if (propertyId === FIELD_IDS.NAVIGATION_TYPE) {
      componentsObject = {
        ...componentsObject,
        properties: { [FIELD_IDS.NAVIGATION_TYPE]: _get(properties, FIELD_IDS.NAVIGATION_TYPE) },
      };
    } else if (propertyId === FIELD_IDS.APPROVAL_INTEGRATION) {
      if (_get(properties, FIELD_IDS.APPROVAL_INTEGRATION)) {
        approvalProperties = {
          ...approvalProperties,
          [FIELD_IDS.APPROVAL_META_DATA]: {
            ...APPROVAL_INTEGRATION_INITIAL_CONFIG,
          },
        };
      } else {
        approvalProperties = {};
      }
    }
  } else {
    componentsObject = {
      ...componentsObject,
      type: COMPONENTS_TYPES.NO_NAVIGATION,
      pageName: tget(properties, PROPERTIES_FIELD_IDS.PAGE_NAME, null),
      children: [],
      properties: {},
    };
  }

  components = [
    ..._filter(
      _get(applicationConfig, 'components', []),
      (item) =>
        !_get(item, COMPONENT_KEYS.TYPE) === COMPONENTS_TYPES.NAVIGATION || !_get(item, COMPONENT_KEYS.TYPE) === COMPONENTS_TYPES.NO_NAVIGATION,
    ),
    componentsObject,
  ];

  const updatedApplicationConfig = { ...applicationConfig, components, properties: approvalProperties };
  return { updatedApplicationConfig };
};

const getConfirmationDialogContent = (confirmationModalId) => {
  if (confirmationModalId === FIELD_IDS.APPROVAL_INTEGRATION) {
    return __(
      // eslint-disable-next-line max-len
      'Are you sure you want to change this field. All the information related to approval filled by you is lost , also the approval tab is removed if you click yes.',
    );
  } else {
    return __('Are you sure you want to change this field. All the information filled by you is lost. All tabs are removed if you click yes.');
  }
};
export { getConfirmationDialogContent, setNavigationPropertiesInApplicationConfig, getNavigationProperties };
