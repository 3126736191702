import { defaultMemoize } from 'reselect';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import withKeyPressInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput/withKeyPressInput';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';

import PasswordStrengthRenderer from './components/PasswordValidationRenderer';
import { FIELD_NAMES, PASSWORD_POLICY_DEFAULTS } from './updatePasswordPage.constants';

import styles from '../AuthenticationForm/authentication.module.scss';

const FORM_SECTION = [
  {
    rows: [
      {
        columns: [FIELD_NAMES.NEW_PASSWORD],
      },
      {
        className: styles.concernInputContainer,
        columns: [FIELD_NAMES.CONFIRM_NEW_PASSWORD],
      },
      {
        columns: [FIELD_NAMES.CHAR_COUNT],
      },
      {
        columns: [FIELD_NAMES.MAX_CHAR_COUNT],
      },
      {
        columns: [FIELD_NAMES.UPPERCASE_CHAR],
      },
      {
        columns: [FIELD_NAMES.LOWERCASE_CHAR],
      },
      {
        columns: [FIELD_NAMES.NUMBER],
      },
      {
        columns: [FIELD_NAMES.SYMBOL],
      },
    ],
  },
];

const getFormConfig = defaultMemoize((newPassword) => {
  const minLength = PASSWORD_POLICY_DEFAULTS.MIN_LENGTH;
  const maxLength = PASSWORD_POLICY_DEFAULTS.MAX_LENGTH;
  const minDigits = PASSWORD_POLICY_DEFAULTS.MIN_DIGITS;
  const minSymbols = PASSWORD_POLICY_DEFAULTS.MIN_SYMBOLS;

  return {
    [FIELD_NAMES.NEW_PASSWORD]: {
      renderer: TextInput,
      renderOptions: {
        type: 'password',
        label: __('New password'),
        required: true,
        validators: [isRequiredRule],
        className: styles.textInputPadding,
      },
    },
    [FIELD_NAMES.CONFIRM_NEW_PASSWORD]: {
      renderer: withKeyPressInput,
      renderOptions: {
        type: 'password',
        required: true,
        label: __('Confirm new password'),
        validators: [isRequiredRule],
        className: styles.textInputPadding,
      },
    },
    [FIELD_NAMES.CHAR_COUNT]: {
      renderer: PasswordStrengthRenderer,
      renderOptions: {
        label: __('Password should be between {{minLength}} to {{maxLength}} characters', { minLength, maxLength }),
        newPassword,
      },
    },
    [FIELD_NAMES.UPPERCASE_CHAR]: {
      renderer: PasswordStrengthRenderer,
      renderOptions: {
        label: __('Password must contain 1 or more uppercase characters'),
        newPassword,
      },
    },
    [FIELD_NAMES.LOWERCASE_CHAR]: {
      renderer: PasswordStrengthRenderer,
      renderOptions: {
        label: __('Password must contain 1 or more lowercase characters'),
        newPassword,
      },
    },
    [FIELD_NAMES.NUMBER]: {
      renderer: PasswordStrengthRenderer,
      renderOptions: {
        label: __('Password must contain {{minDigits}} or more digit characters', { minDigits }),
        newPassword,
      },
    },
    [FIELD_NAMES.SYMBOL]: {
      renderer: PasswordStrengthRenderer,
      renderOptions: {
        label: __('Password must contain {{minSymbols}} or more special characters', { minSymbols }),
        newPassword,
      },
    },
  };
});

export { getFormConfig, FORM_SECTION };
