import _get from 'lodash/get';

import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { STATUS } from '@tekion/tekion-base/constants/statuses';

import RecordSharingRulesManagementServices from '../services/recordSharingRules.services';

const fetchRecordSharingRuleList = async (entityName) => {
  try {
    const receivedData = await RecordSharingRulesManagementServices.fetchRecordSharingRuleList(entityName);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch Record Sharing Rule List, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const editRecordSharingRule = async (recordSharingName, entityName, payload) => {
  try {
    const response = await RecordSharingRulesManagementServices.editRecordSharingRule(recordSharingName, entityName, payload);
    const data = getDataFromResponse(response);
    return { data, status: _get(response, 'data.status') };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Edit Sharing Rule , please try again later.')));
    return { data: EMPTY_OBJECT, status: STATUS.FAILED };
  }
};

const createRecordSharingRule = async (entityName, payload) => {
  try {
    const response = await RecordSharingRulesManagementServices.createRecordSharingRule(entityName, payload);
    toaster(TOASTER_TYPE.SUCCESS, __('New Rule Added Successfully!'));
    return _get(response, 'data.status');
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to add new rule , please try again later.')));
    return EMPTY_OBJECT;
  }
};

const deleteRecordSharingRule = async (recordSharingName, entityName) => {
  try {
    const response = await RecordSharingRulesManagementServices.deleteRecordSharingRule(recordSharingName, entityName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to delete rule, please try again later.')));
    return EMPTY_OBJECT;
  }
};

export { fetchRecordSharingRuleList, createRecordSharingRule, editRecordSharingRule, deleteRecordSharingRule };
