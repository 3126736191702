import { getPlatePluginTypes, getRenderElement } from '@udecode/plate-core';

export const ELEMENT_TEMPLATE_VARIABLE = 'a';

const createTemplateVariablePlugin = () => ({
  pluginKeys: ELEMENT_TEMPLATE_VARIABLE,
  renderElement: getRenderElement(ELEMENT_TEMPLATE_VARIABLE),
  // deserialize: getImageDeserialize(),
  voidTypes: getPlatePluginTypes(ELEMENT_TEMPLATE_VARIABLE),
});

export default createTemplateVariablePlugin;
