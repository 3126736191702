exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".formBuilderViewRenderer_formBuilderViewRenderer__5UDWnr9fmd{height:100%;display:flex;flex-direction:column}.formBuilderViewRenderer_formBuilderViewRenderer__5UDWnr9fmd .formBuilderViewRenderer_dropZoneGutter__nxpmXzYThv{height:2rem}[dir] .formBuilderViewRenderer_formBuilderViewRenderer__5UDWnr9fmd .formBuilderViewRenderer_dropZoneGutter__nxpmXzYThv{background:#fff}.formBuilderViewRenderer_formBuilderViewRenderer__5UDWnr9fmd .formBuilderViewRenderer_dropZoneGutter__nxpmXzYThv:last-child{flex-grow:1}.formBuilderViewRenderer_formBuilderViewRenderer__5UDWnr9fmd .formBuilderViewRenderer_hiddenDropZone__bmUuJSPJSG{height:500%;top:-250%;width:100%}.formBuilderViewRenderer_formBuilderViewRenderer__5UDWnr9fmd .formBuilderViewRenderer_onHoverDropZoneGutter__roWXfxme3s{height:8rem}[dir=ltr] .formBuilderViewRenderer_formBuilderViewRenderer__5UDWnr9fmd .formBuilderViewRenderer_onHoverDropZoneGutter__roWXfxme3s{background:repeating-linear-gradient(-60deg, #ffffff, #ffe0ec 20%) !important}[dir=rtl] .formBuilderViewRenderer_formBuilderViewRenderer__5UDWnr9fmd .formBuilderViewRenderer_onHoverDropZoneGutter__roWXfxme3s{background:repeating-linear-gradient(60deg, #ffffff, #ffe0ec 20%) !important}.formBuilderViewRenderer_formBuilderViewRenderer__5UDWnr9fmd .formBuilderViewRenderer_dropZoneContainer__wyUBLJ3AhY{flex-grow:1;min-height:5rem}[dir] .formBuilderViewRenderer_formBuilderViewRenderer__5UDWnr9fmd .formBuilderViewRenderer_dropZoneContainer__wyUBLJ3AhY{background:#fff}", ""]);

// Exports
exports.locals = {
	"formBuilderViewRenderer": "formBuilderViewRenderer_formBuilderViewRenderer__5UDWnr9fmd",
	"dropZoneGutter": "formBuilderViewRenderer_dropZoneGutter__nxpmXzYThv",
	"hiddenDropZone": "formBuilderViewRenderer_hiddenDropZone__bmUuJSPJSG",
	"onHoverDropZoneGutter": "formBuilderViewRenderer_onHoverDropZoneGutter__roWXfxme3s",
	"dropZoneContainer": "formBuilderViewRenderer_dropZoneContainer__wyUBLJ3AhY"
};