import _get from 'lodash/get';
import _includes from 'lodash/includes';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import ACTION_TYPES from '../constants/homePage.actionTypes';
import { TAB_IDS } from '../constants/tabs.config';
import { STUDIO_ROUTE } from '../../../../../constants/routes';
import ROUTES from '../../../constants/routes';
import PAGE_IDS from '../../../constants/pageIds.constants';

const handleInit = ({ setState, getState }) => {
  const { match, history } = getState();

  const tabId = _get(match, 'params.tabId', EMPTY_STRING);

  if (!_includes(TAB_IDS, tabId)) {
    history.replace(`/${TAB_IDS.APPLICATIONS}`);
  } else {
    setState({ tabId });
  }
};

const handleTabChange = ({ getState, params }) => {
  const { history } = getState();
  const tabId = _get(params, 'tab', TAB_IDS.APPLICATIONS);

  history.push({ pathname: `/${tabId}` });
};

const handleStudioClick = ({ getState }) => {
  const { history } = getState();
  const path = STUDIO_ROUTE;
  history.push(path);
};

const handleMyExportsClick = ({ getState }) => {
  const { history } = getState();
  const path = ROUTES.EXPORT_LIST_PAGE_ROUTE;
  history.push(path);
};

const handleApprovalCentreClick = ({ getState }) => {
  const { history } = getState();
  const path = `/${PAGE_IDS.APPROVAL_CENTRE}/${PAGE_IDS.MANAGEMENT}`;
  history.push(path);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_TAB_CHANGE]: handleTabChange,
  [ACTION_TYPES.INIT]: handleInit,
  [ACTION_TYPES.ON_STUDIO_CLICK]: handleStudioClick,
  [ACTION_TYPES.ON_MY_EXPORTS_CLICK]: handleMyExportsClick,
  [ACTION_TYPES.ON_APPROVAL_CENTRE_CLICK]: handleApprovalCentreClick,
};

export default ACTION_HANDLERS;
