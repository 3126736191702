exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".approvalStageFieldRenderer_headerContainer__oEM1tZXgao{display:flex;justify-content:space-between;width:100%}[dir] .approvalStageFieldRenderer_headerContainer__oEM1tZXgao{padding-bottom:.6rem}[dir=ltr] .approvalStageFieldRenderer_tableContainer__qSHvMPVEp6{margin:.8rem 0 0 .8rem}[dir=rtl] .approvalStageFieldRenderer_tableContainer__qSHvMPVEp6{margin:.8rem .8rem 0 0}.approvalStageFieldRenderer_noStagesMessage__4AwY2BvoTt{display:flex;justify-content:center}.approvalStageFieldRenderer_noStagesMessage__4AwY2BvoTt .approvalStageFieldRenderer_noStagesMessageContent__vGZuwpav98{font-weight:bold}[dir] .approvalStageFieldRenderer_noStagesMessage__4AwY2BvoTt .approvalStageFieldRenderer_noStagesMessageContent__vGZuwpav98{background-color:#f3f4f6;padding:.8rem 2rem;border:.3rem}.approvalStageFieldRenderer_sectionFieldsContainer__fUujxnfScB{min-width:100%}", ""]);

// Exports
exports.locals = {
	"headerContainer": "approvalStageFieldRenderer_headerContainer__oEM1tZXgao",
	"tableContainer": "approvalStageFieldRenderer_tableContainer__qSHvMPVEp6",
	"noStagesMessage": "approvalStageFieldRenderer_noStagesMessage__4AwY2BvoTt",
	"noStagesMessageContent": "approvalStageFieldRenderer_noStagesMessageContent__vGZuwpav98",
	"sectionFieldsContainer": "approvalStageFieldRenderer_sectionFieldsContainer__fUujxnfScB"
};