import React, { useMemo, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { withProps } from 'recompose';
import cx from 'classnames';
import _keyBy from 'lodash/keyBy';
import _noop from 'lodash/noop';

import { EMPTY_OBJECT, EMPTY_STRING, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import GridViewRenderer from './gridViewRenderer/GridViewRenderer';
import ChildListViewRenderer from './childListViewRenderer';

import VisualBuilderEventEmitter from '../../../../eventEmitters/visualBuilderEventEmitter';
import GeneralEventEmitter from '../../../../eventEmitters/generalEventEmitter';

import ViewRendererContext from '../../../../context/viewRenderer.context';
import variableApiFactory from '../../../../factories/variableApi.Factory';

import ACTION_HANDLERS from './listViewRenderer.actionHandlers';
import { generateColumns } from './listViewRenderer.columnConfig';
import { createFilterProps, getSortableColumns } from './listViewRenderer.filterConfig';
import {
  createTableProps,
  getPropsFromConfig,
  getTableHeaderProps,
  getTableSubHeaderProps,
  getParentChildRelationshipFilter,
  generateCustomStyleForRow,
} from './listViewRenderer.helpers';

import { ACTION_TYPES, LIST_VIEW_RENDERER_DEFAULT_PROPS } from './listViewRenderer.constants';
import { COMPONENT_TYPES, VIEW_CONTEXT_KEYS } from '../../constants/viewBuilder.constants';
import { EVENT_ACTIONS } from '../../../../constants/eventActions.constants';
import { GENERAL_EVENT_NAMES, ADDITIONAL_PAYLOAD_IDS_BY_GENERAL_EVENT_NAME } from '../../../../eventEmitters/constants/generalEventEmitter.constants';
import { ACTION_DEFINITION_ACTION_TYPES } from '../../../../constants/actionBuilder.constants';
import { ALL_VIEW_PROPERTY_KEYS, RENDERER_PROP_KEYS } from '../../../../constants/viewBuilder.constants';

import entityReader from '../../../../readers/entity.reader';

import styles from './listViewRenderer.module.scss';

const ListViewRenderer = ({
  componentConfig,
  entity,
  onAction,
  selectedFilters,
  searchText,
  searchField,
  sortDetails,
  currentPage,
  pageSize,
  totalCount,
  isLoading,
  sectionId,
  className,
  nextPageToken,
  actionModalContextId,
  data,
  currentExpandedRows,
  isExpandableRowsEnabled,
  childEntity,
  childViewConfiguration,
  childRelationshipField,
  cellViewConfigurationsByName,
  cardViewConfiguration,
  searchOptionsFromProperties,
  actionBuilderPropBuilder,
  customRowActionsData,
}) => {
  const viewDetailsContextValue = useContext(ViewRendererContext);

  const entityName = entityReader.name(entity);

  const widgetName = tget(viewDetailsContextValue, `${VIEW_CONTEXT_KEYS.WIDGET_CONTEXT}.widgetName`, EMPTY_STRING);

  const viewVariables = useMemo(
    () => tget(viewDetailsContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.viewVariables`, EMPTY_STRING),
    [viewDetailsContextValue],
  );
  const handleSetViewVariables = useMemo(
    () => tget(viewDetailsContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.handleSetViewVariables`, _noop),
    [viewDetailsContextValue],
  );
  const variablesApi = useMemo(() => variableApiFactory(viewVariables, handleSetViewVariables), [viewVariables, handleSetViewVariables]);
  const currentLoggedInUser = useMemo(
    () => tget(viewDetailsContextValue, `${VIEW_CONTEXT_KEYS.APPLICATION_CONTEXT}.currentUser`),
    [viewDetailsContextValue],
  );

  const { type: componentType, children, properties } = componentConfig;
  const tableHeaderName = tget(properties, ALL_VIEW_PROPERTY_KEYS.TITLE, EMPTY_STRING);
  const showActions = tget(properties, ALL_VIEW_PROPERTY_KEYS.SHOW_ACTIONS, false);
  const fieldDefinitions = tget(entity, 'fieldDefinitions', EMPTY_ARRAY);
  const fieldDefinitionByName = useMemo(() => _keyBy(fieldDefinitions, 'name'), [fieldDefinitions]);

  const getTableComponent = useMemo(() => {
    if (componentType === COMPONENT_TYPES.GRID_VIEW)
      return withProps({ componentConfig, cardViewConfiguration, cellViewConfigurationsByName, entity })(GridViewRenderer);
    else return null;
  }, [componentType, componentConfig, cardViewConfiguration, cellViewConfigurationsByName, entity]);

  const customStyles = tget(properties, RENDERER_PROP_KEYS.CUSTOM_STYLES, {});

  const { configColumns, configTableManagerProps, configTableProps, filterMetadata, downloadOptions } = getPropsFromConfig(properties, children);

  const headerProps = useMemo(() => getTableHeaderProps(tableHeaderName), [tableHeaderName]);

  const subHeaderProps = useMemo(() => getTableSubHeaderProps(downloadOptions), [downloadOptions]);

  const getTrProps = useCallback((state, rowInfo) => generateCustomStyleForRow(rowInfo, customStyles, variablesApi), [customStyles, variablesApi]);

  const tableProps = useMemo(
    () =>
      createTableProps({
        loading: isLoading,
        totalCount,
        currentPage,
        pageSize,
        configTableManagerProps: configTableProps,
        currentExpandedRows,
        getTrProps,
        onAction,
      }),
    [isLoading, totalCount, currentPage, pageSize, configTableProps, currentExpandedRows, getTrProps, onAction],
  );

  const tableColumns = useMemo(
    () =>
      generateColumns({
        entity,
        configColumns,
        fieldDefinitionByName,
        showActions,
        cellViewConfigurationsByName,
        customRowActionsData,
        properties,
        actionBuilderPropBuilder,
        actionModalContextId,
      }),
    [
      cellViewConfigurationsByName,
      entity,
      configColumns,
      fieldDefinitionByName,
      showActions,
      customRowActionsData,
      properties,
      actionBuilderPropBuilder,
      actionModalContextId,
    ],
  );

  const filterProps = useMemo(
    () =>
      createFilterProps({
        selectedFilters,
        fieldDefinitionByName,
        filterMetadata,
      }),
    [selectedFilters, fieldDefinitionByName, filterMetadata],
  );

  const sortableColumns = useMemo(() => getSortableColumns(properties, fieldDefinitionByName), [fieldDefinitionByName, properties]);

  const getChildRelationshipContext = useCallback(
    (rowData) => getParentChildRelationshipFilter(childRelationshipField, childEntity, rowData),
    [childEntity, childRelationshipField],
  );

  const renderSubComponent = useCallback(
    (rowData) => (
      <ChildListViewRenderer
        entity={childEntity}
        viewConfiguration={childViewConfiguration}
        childRelationshipContext={getChildRelationshipContext(rowData)}
      />
    ),
    [childEntity, childViewConfiguration, getChildRelationshipContext],
  );

  const getChildTableProps = useMemo(() => {
    if (isExpandableRowsEnabled) {
      return {
        SubComponent: renderSubComponent,
      };
    }
    return {};
  }, [renderSubComponent, isExpandableRowsEnabled]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.FETCH_DATA,
      payload: { variablesApi, currentLoggedInUser },
    });
    // Need to trigger this once only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onAction, componentConfig]);
  // Adding componentConfig in the above dependency to handle builder mode rendering
  // Need to test that it doesn't populate unnecessary loaders

  useEffect(() => {
    const handleActionTrigger = (payload) => {
      onAction({ type: ACTION_TYPES.TRIGGER_ACTION_BUILDER_ACTION, payload });
    };

    VisualBuilderEventEmitter.on(`${widgetName}:${EVENT_ACTIONS.TRIGGER_ACTION_BUILDER_ACTION}`, handleActionTrigger);

    return () => {
      VisualBuilderEventEmitter.removeAllListeners(`${widgetName}:${EVENT_ACTIONS.TRIGGER_ACTION_BUILDER_ACTION}`);
    };
  }, [widgetName, onAction]);

  useEffect(() => {
    const ACTION_BUILDER_EVENT_FUNCTIONS = {
      CREATE_RECORD: (payload) => {
        const ADDITIONAL_PAYLOAD_IDS_FOR_ACTION_EVENT = ADDITIONAL_PAYLOAD_IDS_BY_GENERAL_EVENT_NAME[GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT];

        const entityNameFromEvent = tget(payload, ADDITIONAL_PAYLOAD_IDS_FOR_ACTION_EVENT.ENTITY_NAME);

        // Currently we assume that when create record event comes we want to refresh
        if (entityNameFromEvent === entityName) {
          onAction({ type: TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH });
        }
      },
      UPDATE_RECORD: (payload) => {
        const ADDITIONAL_PAYLOAD_IDS_FOR_ACTION_EVENT = ADDITIONAL_PAYLOAD_IDS_BY_GENERAL_EVENT_NAME[GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT];

        const entityNameFromEvent = tget(payload, ADDITIONAL_PAYLOAD_IDS_FOR_ACTION_EVENT.ENTITY_NAME);

        // Currently we assume that when create record event comes we want to refresh
        if (entityNameFromEvent === entityName) {
          onAction({ type: TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH });
        }
      },
    };

    // Registering general events

    // Registering ACTION_BUILDER general events
    GeneralEventEmitter.on(
      `${GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT}:${ACTION_DEFINITION_ACTION_TYPES.CREATE_RECORD}`,
      ACTION_BUILDER_EVENT_FUNCTIONS.CREATE_RECORD,
    );
    GeneralEventEmitter.on(
      `${GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT}:${ACTION_DEFINITION_ACTION_TYPES.UPDATE_RECORD}`,
      ACTION_BUILDER_EVENT_FUNCTIONS.UPDATE_RECORD,
    );

    return () => {
      GeneralEventEmitter.removeListener(
        `${GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT}:${ACTION_DEFINITION_ACTION_TYPES.CREATE_RECORD}`,
        ACTION_BUILDER_EVENT_FUNCTIONS.CREATE_RECORD,
      );
      GeneralEventEmitter.removeListener(
        `${GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT}:${ACTION_DEFINITION_ACTION_TYPES.UPDATE_RECORD}`,
        ACTION_BUILDER_EVENT_FUNCTIONS.UPDATE_RECORD,
      );
    };
  }, [entityName, onAction]);

  return (
    <div key={sectionId} className={cx(styles.listViewRenderer, className)}>
      <TableManager
        tableComponent={getTableComponent}
        pageSize={pageSize}
        currentPage={currentPage}
        headerProps={headerProps}
        tableProps={tableProps}
        tableManagerProps={configTableManagerProps}
        columns={tableColumns}
        data={data}
        onAction={onAction}
        searchText={searchText}
        filterProps={filterProps}
        subHeaderProps={subHeaderProps}
        searchField={searchField}
        searchableFieldsOptions={searchOptionsFromProperties}
        sortDetails={sortDetails}
        isMultiSort
        disableHeight
        tokenPagination
        nextPageToken={nextPageToken}
        allColumns={sortableColumns}
        {...getChildTableProps}
      />
    </div>
  );
};

ListViewRenderer.propTypes = {
  isLoading: PropTypes.bool,
  isExpandableRowsEnabled: PropTypes.bool,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  sectionId: PropTypes.string.isRequired,
  className: PropTypes.string,
  childRelationshipField: PropTypes.string,
  nextPageToken: PropTypes.string,
  currentPage: PropTypes.string.isRequired,
  searchText: PropTypes.string,
  searchField: PropTypes.string,
  actionModalContextId: PropTypes.string,
  entity: PropTypes.object,
  componentConfig: PropTypes.object,
  sortDetails: PropTypes.object,
  currentExpandedRows: PropTypes.object,
  childEntity: PropTypes.object,
  childViewConfiguration: PropTypes.object,
  cardViewConfiguration: PropTypes.object,
  cellViewConfigurationsByName: PropTypes.object,
  actionBuilderPropBuilder: PropTypes.object,
  selectedFilters: PropTypes.array,
  searchOptionsFromProperties: PropTypes.array,
  data: PropTypes.arrayOf(PropTypes.object),
  customRowActionsData: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ListViewRenderer.defaultProps = {
  isLoading: true,
  isExpandableRowsEnabled: false,
  pageSize: 0,
  totalCount: 0,
  nextPageToken: EMPTY_STRING,
  className: EMPTY_STRING,
  entity: undefined,
  searchText: '',
  searchField: '',
  childRelationshipField: '',
  actionModalContextId: '',
  sortDetails: EMPTY_OBJECT,
  componentConfig: EMPTY_OBJECT,
  currentExpandedRows: EMPTY_OBJECT,
  childEntity: EMPTY_OBJECT,
  childViewConfiguration: EMPTY_OBJECT,
  cardViewConfiguration: EMPTY_OBJECT,
  cellViewConfigurationsByName: EMPTY_OBJECT,
  actionBuilderPropBuilder: EMPTY_OBJECT,
  data: [],
  searchOptionsFromProperties: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
  customRowActionsData: EMPTY_ARRAY,
};

export default withActions(LIST_VIEW_RENDERER_DEFAULT_PROPS, ACTION_HANDLERS)(ListViewRenderer);
