import _property from 'lodash/property';

const permissionSetReader = {
  name: _property('name'),
  displayName: _property('displayName'),
  description: _property('description'),
  modifiedTime: _property('modifiedTime'),
  createdTime: _property('createdTime'),
};

export default permissionSetReader;
