import { FIELD_IDS } from './exportForm.fields';

const exportFormSections = () => [
  {
    header: {
      className: 'p-t-12',
      label: __('Export details'),
    },
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
      },
      {
        columns: [FIELD_IDS.DESCRIPTION],
      },
    ],
  },
];

export default exportFormSections;
