import { isRequiredRule, nonNegativeRule } from '@tekion/tekion-base/utils/formValidators';
import { DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInputRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import TextArea from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';
import RadioRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import DateTimeRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/datePicker';
import TimePickerRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/timePicker';
import NumberInputRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';
import SwitchRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch';

import CronExpression from '../components/cronExpression';

import { SCHEDULE_TYPE_OPTIONS, STATUS_OPTIONS, TASK_TYPE_OPTIONS, EXPORT_FILE_DOWNLOAD_TYPE_OPTIONS, STATUS_TYPE } from './jobScheduleForm.general';
import FIELD_IDS from './jobScheduleForm.fieldIds';

const SCHEDULE_TYPE_FIELD = {
  id: FIELD_IDS.SCHEDULE_TYPE,
  renderer: Select,
  renderOptions: {
    label: __('Schedule Type'),
    required: true,
    validators: [isRequiredRule],
    options: SCHEDULE_TYPE_OPTIONS,
  },
};

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const DESCRIPTION_FIELD = {
  id: FIELD_IDS.DESCRIPTION,
  renderer: TextArea,
  renderOptions: {
    label: __('Description'),
  },
};

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Display Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const STATUS_FIELD = {
  id: FIELD_IDS.RULE_STATUS,
  renderer: RadioRenderer,
  renderOptions: {
    label: __('Job Status'),
    defaultValue: STATUS_TYPE.ACTIVE,
    required: true,
    validators: [isRequiredRule],
    radios: STATUS_OPTIONS,
    labelClassName: 'm-b-8',
  },
};

const START_TIME_FIELD = {
  id: FIELD_IDS.START_TIME,
  renderer: DateTimeRenderer,
  renderOptions: {
    label: __('Start Time'),
    required: true,
    validators: [isRequiredRule],
    showTime: true,
    format: DATE_TIME_FORMATS.DATE_MONTH_YEAR_WITH_TIME_IN_24HR_FORMAT,
    placeholder: __('Select date and time'),
  },
};

const END_TIME_FIELD = {
  id: FIELD_IDS.END_TIME,
  renderer: DateTimeRenderer,
  renderOptions: {
    label: __('End Time'),
    required: true,
    validators: [isRequiredRule],
    showTime: true,
    format: DATE_TIME_FORMATS.DATE_MONTH_YEAR_WITH_TIME_IN_24HR_FORMAT,
    placeholder: __('Select date and time'),
  },
};

const TIME_ZONE_FIELD = {
  id: FIELD_IDS.TIME_ZONE,
  renderer: Select,
  renderOptions: {
    label: __('Time Zone'),
    required: true,
    validators: [isRequiredRule],
  },
};

const CRON_EXPRESSION_FIELD = {
  id: FIELD_IDS.CRON_EXPRESSION,
  renderer: CronExpression,
};

const SIMPLE_FOREVER_FIELD = {
  id: FIELD_IDS.SIMPLE_FOREVER,
  renderer: SwitchRenderer,
  renderOptions: {
    label: __('Do you want to run this job forever ?'),
  },
};

const SIMPLE_TRIGGER_TIME_FIELD = {
  id: FIELD_IDS.SIMPLE_TRIGGER_TIME,
  renderer: TimePickerRenderer,
  renderOptions: {
    label: __('Trigger Time'),
    required: true,
    validators: [isRequiredRule],
  },
};

const SIMPLE_REPEAT_COUNT_FIELD = {
  id: FIELD_IDS.SIMPLE_REPEAT_COUNT,
  renderer: NumberInputRenderer,
  renderOptions: {
    label: __('Number Of Repetitions'),
    validators: [nonNegativeRule],
  },
};

const TASK_TYPE_FIELD = {
  id: FIELD_IDS.TASK_TYPE,
  renderer: Select,
  renderOptions: {
    label: __('Task Type'),
    required: true,
    validators: [isRequiredRule],
    options: TASK_TYPE_OPTIONS,
  },
};

const VIEW_NAME_FIELD = {
  id: FIELD_IDS.VIEW_NAME,
  renderer: Select,
  renderOptions: {
    label: __('View'),
    required: true,
    validators: [isRequiredRule],
  },
};

const ENTITY_NAME_FIELD = {
  id: FIELD_IDS.ENTITY_NAME,
  renderer: Select,
  renderOptions: {
    label: __('Entity Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const FILE_FORMAT_FIELD = {
  id: FIELD_IDS.FILE_FORMAT,
  renderer: Select,
  renderOptions: {
    label: __('File Format'),
    required: true,
    validators: [isRequiredRule],
    options: EXPORT_FILE_DOWNLOAD_TYPE_OPTIONS,
  },
};

export {
  SCHEDULE_TYPE_FIELD,
  NAME_FIELD,
  DESCRIPTION_FIELD,
  DISPLAY_NAME_FIELD,
  STATUS_FIELD,
  START_TIME_FIELD,
  END_TIME_FIELD,
  TIME_ZONE_FIELD,
  CRON_EXPRESSION_FIELD,
  SIMPLE_FOREVER_FIELD,
  SIMPLE_TRIGGER_TIME_FIELD,
  SIMPLE_REPEAT_COUNT_FIELD,
  TASK_TYPE_FIELD,
  VIEW_NAME_FIELD,
  ENTITY_NAME_FIELD,
  FILE_FORMAT_FIELD,
};
