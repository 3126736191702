import { FIELD_IDS } from '../constants/customActionsConfigurator.constants';

const SECTION_FOR_CUSTOM_ACTIONS = [
  {
    rows: [
      {
        columns: [FIELD_IDS.CUSTOM_ACTIONS_INFO],
      },
      {
        columns: [FIELD_IDS.SHOW_ACTIONS],
      },
      {
        columns: [FIELD_IDS.ACTIONS_TO_SHOW],
      },
    ],
  },
];

export default SECTION_FOR_CUSTOM_ACTIONS;
