import { useCallback } from 'react';
import { compose } from 'recompose';

// Lodash
import _isNaN from 'lodash/isNaN';

// Constants
import { DEFAULT_FONT_SIZE } from '../constants/fontSizeInput.general';

const isInvalidFontSize = compose(_isNaN, Number);

const getTruncatedFontValue = (value, maxFontSize) => value.slice(0, String(maxFontSize).length - 1);

const getFontValue = ({ value, maxFontSize, minFontSize }) => {
  if (value && Number(value) === 0) return minFontSize; /** 0 is not a valid font size */
  if (value > maxFontSize) return getTruncatedFontValue(value, maxFontSize);
  if (isInvalidFontSize(value)) return DEFAULT_FONT_SIZE;
  return value;
};

const useInputHandlers = ({ onChange, onSelect, maxFontSize, minFontSize }) => {
  const onInputChange = useCallback(
    (e) => {
      const value = getFontValue({
        value: e.target.value,
        maxFontSize,
        minFontSize,
      });
      onChange(value);
    },
    [onChange, maxFontSize, minFontSize],
  );

  const onInputSelect = useCallback((e) => onSelect(e.target.value), [onSelect]);

  return {
    onInputChange,
    onInputEnter: onInputSelect,
    onInputBlur: onInputSelect,
  };
};

export default useInputHandlers;
