exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".recordtypeCardView_container__gJY8GWuvsw{display:flex;flex-wrap:wrap}[dir] .recordtypeCardView_container__gJY8GWuvsw{margin:2.4rem 2.4rem 0;border:1px solid #ccc}.recordtypeCardView_item__78uVQ9VY4v{display:flex;flex-direction:row;flex:0 1 24%;height:20px}[dir] .recordtypeCardView_item__78uVQ9VY4v{margin-bottom:1%;padding-top:.8rem}[dir=ltr] .recordtypeCardView_item__78uVQ9VY4v{padding-left:.8rem}[dir=rtl] .recordtypeCardView_item__78uVQ9VY4v{padding-right:.8rem}.recordtypeCardView_fieldContainer__Y9urTP7TYn{display:flex;flex-direction:row;flex:0 1 50%}[dir] .recordtypeCardView_fieldContainer__Y9urTP7TYn{margin-bottom:1%;padding-top:.8rem}[dir=ltr] .recordtypeCardView_fieldContainer__Y9urTP7TYn{padding-left:.8rem}[dir=rtl] .recordtypeCardView_fieldContainer__Y9urTP7TYn{padding-right:.8rem}[dir=ltr] .recordtypeCardView_subItem__goBJsAQBr7{margin-right:.8rem}[dir=rtl] .recordtypeCardView_subItem__goBJsAQBr7{margin-left:.8rem}.recordtypeCardView_iconItem__uLZacCrFvm{display:flex;flex-direction:row-reverse;flex:0 1 24%;height:20px}[dir] .recordtypeCardView_iconItem__uLZacCrFvm{margin-bottom:1%;padding-top:.8rem}[dir=ltr] .recordtypeCardView_iconItem__uLZacCrFvm{padding-right:.8rem}[dir=rtl] .recordtypeCardView_iconItem__uLZacCrFvm{padding-left:.8rem}.recordtypeCardView_derivationConditionContainer__2A4zAs2Q2w{width:100%}[dir] .recordtypeCardView_derivationConditionContainer__2A4zAs2Q2w .ant-collapse-borderless > .ant-collapse-item{border:none}", ""]);

// Exports
exports.locals = {
	"container": "recordtypeCardView_container__gJY8GWuvsw",
	"item": "recordtypeCardView_item__78uVQ9VY4v",
	"fieldContainer": "recordtypeCardView_fieldContainer__Y9urTP7TYn",
	"subItem": "recordtypeCardView_subItem__goBJsAQBr7",
	"iconItem": "recordtypeCardView_iconItem__uLZacCrFvm",
	"derivationConditionContainer": "recordtypeCardView_derivationConditionContainer__2A4zAs2Q2w"
};