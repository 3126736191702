import axios from 'axios';
import { defaultMemoize } from 'reselect';
import _get from 'lodash/get';
import _set from 'lodash/set';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { getDefaultHeaders } from './apiService.helper';
import { logOut } from '../loginService';
import envReader from '../../readers/env.reader';

let axiosInstance;

const handleStatusCode401 = () => logOut();
const handleStatusCode403 = (error = EMPTY_OBJECT) => error;
const handleStatusCode500 = (error = EMPTY_OBJECT) => error;

const ERROR_INTERCEPTORS = {
  401: handleStatusCode401,
  403: handleStatusCode403,
  500: handleStatusCode500,
};

const onRequestFulfillInterceptor = (config) => ({
  ...config,
  metadata: { startTime: new Date() },
});

const onRequestFulfillInterceptorForARC = async (config, moduleBaseURL) => {
  const newConfig = config || {};
  const externalAuthToken = localStorage.getItem('externalAuthToken');
  newConfig.headers['dp-api-token'] = externalAuthToken;
  const decodedJwt = JSON.parse(atob(externalAuthToken.split('.')[1]));

  if (decodedJwt.exp * 1000 < Date.now() + 10000) {
    await axios
      .post(
        `${moduleBaseURL}u/token`,
        {},
        {
          headers: {
            'tekion-api-token': config.headers['tekion-api-token'],
            roleId: config.headers.roleId,
            userId: config.headers.userId,
            tenantname: config.headers.tenantname,
            dealerId: config.headers.dealerId,
            tenantId: config.headers.tenantId,
            'original-userid': config.headers.originalUserid,
            'original-tenantid': config.headers.originalTenantid,
            clientId: 'web',
            'tek-siteId': config.headers.tekSiteId,
            locale: config.headers.locale,
            program: config.headers.program,
          },
        },
      )
      .then((response) => {
        const proxyApiToken = _get(response, 'data');
        newConfig.headers['dp-api-token'] = proxyApiToken;
        localStorage.setItem('externalAuthToken', proxyApiToken);
      });
  }

  return {
    ...newConfig,
    metadata: { startTime: new Date() },
  };
};

const onResponseFailureInterceptor = (unhandledError = EMPTY_OBJECT) => {
  const response = _get(unhandledError, 'response');
  const { status } = response;
  const interceptor = ERROR_INTERCEPTORS[status];
  let error = response;

  if (interceptor) {
    error = interceptor(response);
  }

  return Promise.reject(error);
};

const onResponseFulfillInterceptor = (response) => ({
  ...response,

  config: {
    metadata: {
      endTime: new Date(),
    },
  },
  duration: response.config.metadata.endTime - response.config.metadata.startTime,
  body: response.data,
  statusCode: response.status,
});

const EXTERNAL_MODULE_NAMES = {
  ARC: 'ARC',
  TAP: 'TAP',
};

const getExternalModuleOnRequestFulFillInterceptor = (externallyMountedModuleName, moduleBaseURL) => {
  switch (externallyMountedModuleName) {
    case EXTERNAL_MODULE_NAMES.ARC: {
      return async (config) => {
        const response = await onRequestFulfillInterceptorForARC(config, moduleBaseURL);
        return response;
      };
    }

    case EXTERNAL_MODULE_NAMES.TAP: {
      return onRequestFulfillInterceptor;
    }

    default: {
      return onRequestFulfillInterceptor;
    }
  }
};

const getExternalModuleAxiosInstanceBaseURL = (externallyMountedModuleName, moduleBaseURL) => {
  switch (externallyMountedModuleName) {
    case EXTERNAL_MODULE_NAMES.ARC: {
      return `${moduleBaseURL}dp-proxy/`;
    }

    case EXTERNAL_MODULE_NAMES.TAP: {
      return moduleBaseURL;
    }

    default: {
      return moduleBaseURL;
    }
  }
};

const createAxiosInstance = defaultMemoize(({ headers } = EMPTY_OBJECT) => {
  const newAxiosInstance = axios.create({
    baseURL: `${envReader.service}/api/`,
    headers: {
      'Content-Type': 'application/json',
      ...getDefaultHeaders(),
      ...headers,
    },
  });

  newAxiosInstance.interceptors.request.use(onRequestFulfillInterceptor);
  newAxiosInstance.interceptors.response.use(onResponseFulfillInterceptor, onResponseFailureInterceptor);
  axiosInstance = newAxiosInstance;
});

const createAxiosInstanceForExternal = defaultMemoize(({ headers, moduleBaseURL, externallyMountedModuleName } = EMPTY_OBJECT) => {
  const baseURL = getExternalModuleAxiosInstanceBaseURL(externallyMountedModuleName, moduleBaseURL);

  const axiosInstanceForExternal = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  });

  const onRequestFulfillInterceptorForExternal = getExternalModuleOnRequestFulFillInterceptor(externallyMountedModuleName, baseURL);

  axiosInstanceForExternal.interceptors.request.use(onRequestFulfillInterceptorForExternal);
  axiosInstanceForExternal.interceptors.response.use(onResponseFulfillInterceptor, onResponseFailureInterceptor);
  axiosInstance = axiosInstanceForExternal;
});

const updateApiHeaders = defaultMemoize((newHeaders) => {
  _set(axiosInstance, 'defaults.headers', { ...axiosInstance.defaults.headers, ...newHeaders });
});

const get = (url, params) => axiosInstance({ method: 'GET', url, params });
const post = (url, data, params) => axiosInstance({ method: 'POST', url, data, params });
const put = (url, data, params) => axiosInstance({ method: 'PUT', url, data, params });

// responseType needs to be passed either at creating axios instance time or while invoking directly from axios
// hence implement the below approach
const getCustomEntityMedia = (url, data, params) =>
  axios.post(url, data, {
    baseURL: `${envReader.service}/api/`,
    params,
    headers: { ...axiosInstance.defaults.headers },
    responseType: 'arraybuffer',
  });

const getMedia = (url, headers, responseType, params) => axiosInstance({ method: 'GET', url, params, headers, responseType });

const _delete = (url, data, query) =>
  axiosInstance({
    method: 'DELETE',
    url,
    data,
    params: query,
  });

const patch = (url, data, query) =>
  axiosInstance({
    method: 'PATCH',
    url,
    data,
    params: query,
  });

const ApiService = {
  get,
  post,
  put,
  delete: _delete,
  patch,
  getMedia,
  getCustomEntityMedia,
};

export default ApiService;
export { createAxiosInstance, updateApiHeaders, createAxiosInstanceForExternal };
