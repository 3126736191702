import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import List from '@tekion/tekion-components/molecules/List';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import { EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import ENTITY_READERS from '../../../../readers/entity.reader';
import { STUDIO_ROUTE } from '../../../../constants/routes';
import PAGE_IDS from '../../constants/PageIds.constants';

import { getEntityCreatedTime } from './entityCard.helpers';

import styles from './entityCardView.module.scss';

const EntityCardView = ({ entity, history }) => {
  const onEditClick = useCallback(() => {
    const entityName = _get(entity, 'name');
    if (!_isEmpty(entityName)) {
      const path = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITY_EDIT}/${entityName}`;
      history.push({ pathname: path, state: { entity } });
    }
  }, [entity, history]);

  const entityCreatedTime = getEntityCreatedTime(ENTITY_READERS.createdTime(entity));

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Display Name')}</div>
        <List.Item.Meta description={ENTITY_READERS.displayName(entity) || NO_DATA} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Name')}</div>
        <List.Item.Meta description={ENTITY_READERS.name(entity) || NO_DATA} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Description')}</div>
        <List.Item.Meta description={ENTITY_READERS.description(entity) || NO_DATA} />
      </div>
      <div className={styles.iconItem}>
        <FontIcon size={SIZES.MD_S} onClick={onEditClick} className="cursor-pointer m-r-32">
          icon-edit
        </FontIcon>
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Created Time')}</div>
        <List.Item.Meta description={entityCreatedTime || NO_DATA} />
      </div>
    </div>
  );
};

EntityCardView.propTypes = {
  entity: PropTypes.object,
  history: PropTypes.object,
};

EntityCardView.defaultProps = {
  entity: undefined,
  history: EMPTY_OBJECT,
};
export default EntityCardView;
