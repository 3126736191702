const PROPERTIES_FIELD_IDS = {
  SECTION_PROPERTIES_FORM: 'SECTION_PROPERTIES_FORM',
  SECTION_COLUMN_PROPERTIES_FORM: 'SECTION_COLUMN_PROPERTIES_FORM',
  DETAIL_VIEW_ATTRIBUTE_PROPERTIES_FORM: 'DETAIL_VIEW_ATTRIBUTE_PROPERTIES_FORM',
  LIST_VIEW_RENDERER_PROPERTIES_FORM: 'LIST_VIEW_RENDERER_PROPERTIES_FORM',
  LIST_VIEW_COLUMN_PROPERTIES_FORM: 'LIST_VIEW_COLUMN_PROPERTIES_FORM',
  GRID_VIEW_RENDERER_PROPERTIES_FORM: 'GRID_VIEW_RENDERER_PROPERTIES_FORM',
  FORM_VIEW_RENDERER_PROPERTIES_FORM: 'FORM_VIEW_RENDERER_PROPERTIES_FORM',
  FORM_VIEW_COLUMN_PROPERTIES_FORM: 'FORM_VIEW_COLUMN_PROPERTIES_FORM',
  FORM_VIEW_ROW_PROPERTIES_FORM: 'FORM_VIEW_ROW_PROPERTIES_FORM',
  FORM_VIEW_SECTION_PROPERTIES_FORM: 'FORM_VIEW_SECTION_PROPERTIES_FORM',
  CELL_VIEW_ROW_PROPERTIES_FORM: 'CELL_VIEW_ROW_PROPERTIES_FORM',
  CELL_VIEW_COLUMN_PROPERTIES_FORM: 'CELL_VIEW_COLUMN_PROPERTIES_FORM',
  IMAGE_PROPERTIES_FORM: 'IMAGE_PROPERTIES_FORM',
  CELL_VIEW_ATTRIBUTE_PROPERTIES_FORM: 'CELL_VIEW_ATTRIBUTE_PROPERTIES_FORM',
  GRID_VIEW_ATTRIBUTE_PROPERTIES_FORM: 'GRID_VIEW_ATTRIBUTE_PROPERTIES_FORM',
  BUTTON_PROPERTIES_FORM: 'BUTTON_PROPERTIES_FORM',
};

const IMAGE_RADIO_BUTTON_VALUES = {
  IMAGE: 'image',
  ICON: 'icon',
};

const PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS = {
  PROPERTY_NAME: 'propertyName',
  PROPERTY_VALUE: 'propertyValue',
};

const VIEW_COMPONENT_UPDATE_ACTION_TYPE = {
  PROPERTIES_UPDATE: 'PROPERTIES_UPDATE',
  CHILDREN_UPDATE: 'CHILDREN_UPDATE',
};

const VIEW_COMPONENT_UPDATE_ACTION_ID = {
  ACTION_TYPE: 'ACTION_TYPE',
  PAYLOAD: 'PAYLOAD',
};

export {
  PROPERTIES_FIELD_IDS,
  IMAGE_RADIO_BUTTON_VALUES,
  PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS,
  VIEW_COMPONENT_UPDATE_ACTION_ID,
  VIEW_COMPONENT_UPDATE_ACTION_TYPE,
};
