import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _toNumber from 'lodash/toNumber';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { DATE_TIME_FORMATS, getFormattedDateTime } from '@tekion/tekion-base/utils/dateUtils';
import { tget } from '@tekion/tekion-base/utils/general';

import { joinByCommas, toDisplayNumberRange } from '../../../../../utils';
import { recursiveFunctionForAttributeFieldDef } from '../../../helpers/auditLogsDrawer.general';
import FIELD_TYPES from '../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../constants/fieldDefinition.dataTypes';
import activityReader from '../../../readers/Activity';
import changeReader from '../../../readers/Change';
import entityReader from '../../../../../readers/entity.reader';
import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';
import workspaceUserReader from '../../../../../readers/workSpaceUser.reader';

const getAllRecordIdsToFetch = (resultList, entityDef) => {
  let recordsIds = {};
  const fieldDefinitionByName = _keyBy(entityReader.fieldDefinitions(entityDef), 'name');
  _forEach(resultList, (data) => {
    const changes = activityReader.changes(data);
    const userId = activityReader.userId(data);
    recordsIds = { ...recordsIds, user: [...tget(recordsIds, 'user', EMPTY_ARRAY), userId] };

    _forEach(changes, (change) => {
      const attribute = changeReader.attribute(change);
      const fieldDef = recursiveFunctionForAttributeFieldDef(attribute, fieldDefinitionByName);
      if (fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.RELATIONSHIP) {
        const entityName = fieldDefinitionReader.lookupFieldEntityType(fieldDef);

        if (changeReader.newValues(change)) {
          recordsIds = { ...recordsIds, [entityName]: [...tget(recordsIds, entityName, EMPTY_ARRAY), changeReader.newValues(change)] };
        }
        if (changeReader.oldValues(change)) {
          recordsIds = { ...recordsIds, [entityName]: [...tget(recordsIds, entityName, EMPTY_ARRAY), changeReader.oldValues(change)] };
        }
      }
    });
  });

  return recordsIds;
};

const getDisplayNameForSingleSelect = (optionsByValue, value) => tget(optionsByValue, `${value}.displayName`) || value;

const getDispayValue = (value, fieldDefinition, recordsByEntityName) => {
  if (!value) {
    return value;
  }
  const fieldType = fieldDefinitionReader.fieldType(fieldDefinition);
  const dataType = fieldDefinitionReader.dataType(fieldDefinition);

  const optionsByValue = _keyBy(fieldDefinitionReader.options(fieldDefinition), 'value');
  const isMultiValued = fieldDefinitionReader.multiValued(fieldDefinition);

  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      switch (dataType) {
        case DATA_TYPES.TEXT:
        case DATA_TYPES.NUMBER:
        case DATA_TYPES.BOOLEAN:
        case DATA_TYPES.COMPLEX:
        case DATA_TYPES.DATE:
          return value;
        case DATA_TYPES.DATE_TIME:
          return getFormattedDateTime(_toNumber(value), DATE_TIME_FORMATS.ABBREVIATED_DATE_ALPHA_MONTH_WITH_TIME);
        default:
          return value;
      }
    case FIELD_TYPES.MEDIA:
      return value;

    case FIELD_TYPES.LIST:
      return joinByCommas(value);

    case FIELD_TYPES.RANGE:
      return toDisplayNumberRange(value);

    case FIELD_TYPES.SELECT: {
      if (!isMultiValued) getDisplayNameForSingleSelect(optionsByValue, value);
      else return value;
      break;
    }

    case FIELD_TYPES.RELATIONSHIP: {
      const entityName = fieldDefinitionReader.lookupFieldEntityType(fieldDefinition);
      const fieldName = fieldDefinitionReader.lookupFieldDisplayField(fieldDefinition);
      return tget(tget(tget(recordsByEntityName, entityName), value), fieldName);
    }

    default:
      return value;
  }
  return value;
};

const resolvedValues = (resultList, entityDef, recordsByEntityName) => {
  const fieldDefinitionByName = _keyBy(entityReader.fieldDefinitions(entityDef), 'name');
  return _map(resultList, (data) => {
    let changes = activityReader.changes(data);
    let userName = activityReader.userId(data);
    userName = workspaceUserReader.NAME(tget(tget(recordsByEntityName, 'user'), userName));
    changes = _map(changes, (change) => {
      const attribute = changeReader.attribute(change);
      const fieldDef = recursiveFunctionForAttributeFieldDef(attribute, fieldDefinitionByName);
      let oldValues = changeReader.oldValues(change);
      let newValues = changeReader.newValues(change);
      const attributeDisplay = fieldDefinitionReader.displayName(fieldDef) || attribute;
      oldValues = getDispayValue(oldValues, fieldDef, recordsByEntityName);
      newValues = getDispayValue(newValues, fieldDef, recordsByEntityName);
      return { ...change, attributeDisplay, oldValues, newValues };
    });
    return {
      ...data,
      changes,
      userName,
    };
  });
};

export { getAllRecordIdsToFetch, resolvedValues };
