import _get from 'lodash/get';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FIELD_IDS } from '../constants/customStylesConfigurator.constants';

const rowCheck = {
  [FIELD_IDS.SCRIPT]: ({ customStylesConfigurationFormValue }) => _get(customStylesConfigurationFormValue, [FIELD_IDS.CODE_PANEL], false),
  [FIELD_IDS.VALUE]: ({ customStylesConfigurationFormValue }) => !_get(customStylesConfigurationFormValue, [FIELD_IDS.CODE_PANEL], false),
  [FIELD_IDS.BEAUTIFY_BUTTON]: ({ customStylesConfigurationFormValue }) => !_get(customStylesConfigurationFormValue, [FIELD_IDS.CODE_PANEL], false),
};

const getCustomStyleFormSection = (customStylesConfigurationFormValue) => [
  {
    rows: [
      {
        columns: [FIELD_IDS.CUSTOM_STYLE_TYPE],
      },
      {
        columns: [FIELD_IDS.CODE_PANEL],
      },
      {
        columns: filterRows([FIELD_IDS.SCRIPT], { customStylesConfigurationFormValue }, rowCheck),
      },
      {
        columns: filterRows([FIELD_IDS.VALUE], { customStylesConfigurationFormValue }, rowCheck),
      },
      {
        columns: [FIELD_IDS.ERROR_MESSAGE],
      },

      {
        columns: filterRows([FIELD_IDS.BEAUTIFY_BUTTON], { customStylesConfigurationFormValue }, rowCheck),
      },
    ],
  },
];

export default getCustomStyleFormSection;
