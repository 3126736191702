exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".propertyPanel_fontStyle__cJUAN59SoT,.propertyPanel_listGroupHeader__bRYDK7c8GK{color:#161616;font-size:1.4rem;letter-spacing:.03rem;line-height:1.7rem}.propertyPanel_headingBorder__4RE1BUixvy{font-weight:normal;height:5rem}[dir=ltr] .propertyPanel_headingBorder__4RE1BUixvy{padding:1.7rem 0 1.7rem 1.7rem}[dir=rtl] .propertyPanel_headingBorder__4RE1BUixvy{padding:1.7rem 1.7rem 1.7rem 0}.propertyPanel_listGroupHeader__bRYDK7c8GK{font-family:\"Proxima-Nova-Bold\"}[dir] .propertyPanel_listGroupHeader__bRYDK7c8GK{padding:1.6rem 4rem}.propertyPanel_configuratorHeading__dbgzMy9VuN{font-weight:bold}[dir] .propertyPanel_collapseStyle__oVRVEETMpz .ant-collapse-content-box{padding:0 !important}", ""]);

// Exports
exports.locals = {
	"fontStyle": "propertyPanel_fontStyle__cJUAN59SoT",
	"listGroupHeader": "propertyPanel_listGroupHeader__bRYDK7c8GK",
	"headingBorder": "propertyPanel_headingBorder__4RE1BUixvy",
	"configuratorHeading": "propertyPanel_configuratorHeading__dbgzMy9VuN",
	"collapseStyle": "propertyPanel_collapseStyle__oVRVEETMpz"
};