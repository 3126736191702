import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _pullAt from 'lodash/pullAt';
import _cloneDeep from 'lodash/cloneDeep';
import _size from 'lodash/size';
import _isNil from 'lodash/isNil';

import InputTable from '@tekion/tekion-components/organisms/inputTable/containers/withRowActions/Table';
import Button from '@tekion/tekion-components/atoms/Button';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/molecules/tableInputField/constants/TableInputField.actionTypes';

import OptionModal from './optionModal/OptionModal';

// Helpers
import { getActionsForRow } from './helpers/listInputTable.general';
import COLUMNS from './helpers/listInputTable.columns';

// Constants
import { COLUMN_IDS, OPTIONS_TABLE_ACTION_TYPES, ROW_ACTION_PROPS } from './constants/listInputTable.constants';
import SELECT_OPTIONS_FORM_FIELD_IDS from '../../constants/selectOptionsForm.fieldsIds';

// Styles
import styles from '../../../../fieldsForm.module.scss';

const ListInputTable = ({ isModalVisible, isDisabled, modalData, error, value, onAction }) => {
  const showDeleteAction = useMemo(() => _size(value) > 1, [value]);

  const getActions = useCallback(() => getActionsForRow(isDisabled, showDeleteAction), [isDisabled, showDeleteAction]);

  const additional = useMemo(() => ({ isDisabled }), [isDisabled]);

  const handleAction = useCallback(
    (action) => {
      const actionType = _get(action, 'type', EMPTY_STRING);
      const payload = _get(action, 'payload', EMPTY_OBJECT);
      const newValue = _cloneDeep(value);

      if (actionType === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
        const index = _get(payload, 'nestingPath.[0]');
        const fieldId = _get(payload, 'id');
        const fieldValue = _get(payload, 'value');

        _set(newValue, [index, fieldId], fieldValue);

        if (fieldId === COLUMN_IDS.LABEL) {
          const disableAutoFillForValue = _get(newValue, [index, 'disableAutoFillForValue'], false);

          if (!disableAutoFillForValue) {
            _set(newValue, [index, COLUMN_IDS.VALUE], fieldValue);
          }
        } else if (fieldId === COLUMN_IDS.VALUE) {
          _set(newValue, [index, 'disableAutoFillForValue'], true);
        }

        onAction({
          type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
          payload: {
            id: SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS,
            value: newValue,
          },
        });
      } else if (actionType === TABLE_ACTION_TYPES.TABLE_ACTION_CLICK) {
        const tableActionType = _get(payload, 'actionType', EMPTY_STRING);

        if (tableActionType === OPTIONS_TABLE_ACTION_TYPES.REMOVE_ROW) {
          if (_size(newValue) === 1) {
            return;
          }

          const { nestingPath } = payload;

          _pullAt(newValue, nestingPath);

          onAction({
            type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
            payload: {
              id: SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS,
              value: newValue,
            },
          });
        } else if (tableActionType === OPTIONS_TABLE_ACTION_TYPES.EDIT_ROW) {
          onAction({
            type: OPTIONS_TABLE_ACTION_TYPES.EDIT_ROW,
            payload,
          });
        }
      }
    },
    [value, onAction],
  );

  const handleAddRow = useCallback(() => {
    if (isDisabled) {
      onAction({ type: OPTIONS_TABLE_ACTION_TYPES.EDIT_MODE_ADD_ROW });
    } else {
      onAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: { id: SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS, value: [...(_isNil(value) ? [] : value), {}] },
      });
    }
  }, [isDisabled, value, onAction]);

  return (
    <div>
      <OptionModal visible={isModalVisible} modalData={modalData} onAction={onAction} />
      <InputTable
        id={SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS}
        className={styles.inputTable}
        columns={COLUMNS}
        rowActionProps={ROW_ACTION_PROPS}
        additional={additional}
        value={value}
        error={error}
        getActionsForRow={getActions}
        onClick={handleAction}
        onAction={handleAction}
      />
      <Button view={Button.VIEW.TERTIARY} className={styles.addButton} onClick={handleAddRow}>
        {__('Add Option')}
      </Button>
    </div>
  );
};

ListInputTable.propTypes = {
  isModalVisible: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.array,
  modalData: PropTypes.object,
  value: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ListInputTable.defaultProps = {
  isModalVisible: false,
  isDisabled: false,
  error: undefined,
  modalData: EMPTY_OBJECT,
  value: EMPTY_ARRAY,
};

export default React.memo(ListInputTable);
