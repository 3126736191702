import _get from 'lodash/get';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { getApplicationList } from '../../../../../../../actions/applicationManagement.actions';
import { getPayload } from './appList.helpers';

import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import PAGE_IDS from '../../../../../constants/PageIds.constants';
import ROUTES from '../../../../../constants/routes';
import { TABLE_CONSTANTS } from '../constants/appList.general.constants';
import ACTION_TYPES from '../constants/appList.actionTypes';
import { DEFAULT_FILTER_GROUP } from '../constants/appList.filter';
import { COLUMNS_IDS } from './appList.columns';

const handleInit = async ({ setState, getState, params = EMPTY_OBJECT }) => {
  setState({ loading: true });
  const { pageSize, searchText, selectedFilters } = getState();
  const currentPageToken = _get(params, 'nextPageToken');

  const payload = getPayload(searchText, selectedFilters, pageSize, currentPageToken);

  const applicationResponse = await getApplicationList(payload);
  const applicationList = tget(applicationResponse, 'hits', EMPTY_ARRAY);

  setState({
    applications: applicationList,
    totalNumberOfEntries: _size(applicationList),
    loading: false,
    nextPageToken: _get(applicationResponse, 'nextPageToken'),
  });
};

const handlePageSizeUpdate = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');

  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }
  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleInit({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const handleSearchTextUpdate = async ({ setState, params = EMPTY_OBJECT, getState }) => {
  const searchText = _get(params, 'value');
  setState(
    {
      searchText,
      currentPage: TABLE_CONSTANTS.CURRENT_PAGE,
    },
    () => {
      handleInit({ getState, setState });
    },
  );
};

const handleRefreshData = ({ setState, getState }) => {
  setState(
    {
      currentPage: TABLE_CONSTANTS.CURRENT_PAGE,
    },
    () => {
      handleInit({ getState, setState });
    },
  );
};

const handleSetFilter = async ({ params = EMPTY_OBJECT, setState, getState }) => {
  const filters = _get(params, 'value', EMPTY_ARRAY);
  const selectedFilterGroup = _get(params, 'selectedFilterGroup', DEFAULT_FILTER_GROUP);

  setState(
    {
      selectedFilters: filters,
      currentPage: TABLE_CONSTANTS.CURRENT_PAGE,
      selectedFilterGroup,
    },
    () => {
      handleInit({ getState, setState });
    },
  );
};

const handleClickCreateApplication = ({ getState }) => {
  const { history } = getState();
  const path = ROUTES.APPLICATION_CREATE_ROUTE;
  history.push(path);
};

const handleEditRequest = ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const appName = tget(params, 'name');
  const path = `${STUDIO_ROUTE}/${PAGE_IDS.APPLICATION_EDIT}/${appName}`;
  history.push(path);
};

const rowClickHandler = ({ params = EMPTY_OBJECT, getState }) => {
  const appData = _get(params, 'value.original', EMPTY_OBJECT);
  handleEditRequest({ getState, params: appData });
};

const handleEntityMappingsClick = ({ setState, params = EMPTY_OBJECT }) => {
  const applicationName = tget(params, COLUMNS_IDS.NAME, tget(params, COLUMNS_IDS.API_NAME));

  if (!_isEmpty(applicationName)) {
    setState({ isEntityMappingsModalVisible: true, rowActionApplication: params });
  }
};

const handleEntityMappingsClose = ({ setState }) => {
  setState({ isEntityMappingsModalVisible: false, rowActionApplication: EMPTY_OBJECT });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleRefreshData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleSetFilter,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageSizeUpdate,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchTextUpdate,
  [ACTION_TYPES.ON_CLICK_CREATE_APPLICATION]: handleClickCreateApplication,
  [ACTION_TYPES.ON_ENTITY_MAPPINGS_CLICK]: handleEntityMappingsClick,
  [ACTION_TYPES.CLOSE_ENTITY_MAPPINGS]: handleEntityMappingsClose,
  [ACTION_TYPES.EDIT]: handleEditRequest,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: rowClickHandler,
};

export default ACTION_HANDLERS;
