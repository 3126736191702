exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".changeLog_changeLogContainer__ePQP2hQQV2{word-break:normal}[dir] .changeLog_changeLogContainer__ePQP2hQQV2{padding:.5rem 1rem}[dir] .changeLog_arrow__sZEjUYccyt{margin:0 1.2rem}.changeLog_emptyLabel__wpYaEZ1DLD{color:#969aa3;font-size:small}[dir=ltr] .changeLog_emptyLabel__wpYaEZ1DLD{margin-right:2rem}[dir=rtl] .changeLog_emptyLabel__wpYaEZ1DLD{margin-left:2rem}[dir] .changeLog_attributeDisplay__mcFg7L5DPh{padding:.4rem 0}[dir=ltr] .changeLog_listIndex__pFevxdvduG{left:-0.2rem}[dir=rtl] .changeLog_listIndex__pFevxdvduG{right:-0.2rem}.changeLog_suffixClassName__oW3R9AL121{font-size:small;color:#969aa3}[dir=ltr] .changeLog_suffixClassName__oW3R9AL121{margin-right:2rem}[dir=rtl] .changeLog_suffixClassName__oW3R9AL121{margin-left:2rem}", ""]);

// Exports
exports.locals = {
	"changeLogContainer": "changeLog_changeLogContainer__ePQP2hQQV2",
	"arrow": "changeLog_arrow__sZEjUYccyt",
	"emptyLabel": "changeLog_emptyLabel__wpYaEZ1DLD",
	"attributeDisplay": "changeLog_attributeDisplay__mcFg7L5DPh",
	"listIndex": "changeLog_listIndex__pFevxdvduG",
	"suffixClassName": "changeLog_suffixClassName__oW3R9AL121"
};