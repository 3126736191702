import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getSections } from './formViewColumnPropertiesForm.sections';
import { getFields } from './formViewColumnPropertiesForm.fields';
import { getFormViewColumnPropertiesFromProperties, getUpdatedSelectedViewComponentFromOnChangeValue } from './formViewColumnPropertiesForm.helpers';

import { VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

const FormViewColumnPropertiesForm = ({ selectedViewComponent, entity, fieldDefinitionForSelectedFieldName, errors, onAction }) => {
  const properties = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);
  const componentType = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.TYPE);

  const sections = useMemo(() => getSections(fieldDefinitionForSelectedFieldName, properties), [fieldDefinitionForSelectedFieldName, properties]);
  const fields = useMemo(
    () => getFields(entity, properties, fieldDefinitionForSelectedFieldName, errors, componentType),
    [componentType, entity, errors, fieldDefinitionForSelectedFieldName, properties],
  );
  const formViewColumnProperties = useMemo(() => getFormViewColumnPropertiesFromProperties(properties), [properties]);

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const updatedSelectedViewComponent = getUpdatedSelectedViewComponentFromOnChangeValue(selectedViewComponent, payload);
          onAction({ type, payload: updatedSelectedViewComponent });
          break;
        }

        default: {
          onAction(action);
        }
      }
    },
    [selectedViewComponent, onAction],
  );

  return <FormBuilder className="full-width" sections={sections} fields={fields} values={formViewColumnProperties} onAction={handleAction} />;
};

FormViewColumnPropertiesForm.propTypes = {
  selectedViewComponent: PropTypes.object,
  entity: PropTypes.object,
  fieldDefinitionForSelectedFieldName: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func,
};

FormViewColumnPropertiesForm.defaultProps = {
  selectedViewComponent: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  fieldDefinitionForSelectedFieldName: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  onAction: _noop,
};

export default FormViewColumnPropertiesForm;
