import { getUuid } from '../../utils';

const ROWS = 20;
const RESOURCE_TYPE = 'RELATIONSHIP_RECORDS';
const RELOAD_OPTIONS_DELAY = 10;

const CREATE_NEW_OPTION = {
  label: __('Create New'),
  value: `CREATE_NEW_${getUuid()}`,
};

const CREATE_STATIC_OPTIONS = [CREATE_NEW_OPTION];

export { ROWS, RESOURCE_TYPE, RELOAD_OPTIONS_DELAY, CREATE_NEW_OPTION, CREATE_STATIC_OPTIONS };
