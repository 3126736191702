import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

// Components
import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Loader from '@tekion/tekion-components/molecules/loader';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';

import ApprovalStageCollapsiblePanels from '../../../../../molecules/approvalStageCollapsiblePanels';
import SettingFieldsForm from './components/settingFieldsForm/SettingFieldsForm';
import RecordFieldsForm from './components/recordFieldsForm/RecordFieldsForm';

// Form Helpers
import getSettingIdentifierFields from './helpers/approvalRequestForm.fields';
import getSettingIdentifierSections from './helpers/approvalRequestForm.sections';
import ACTION_HANDLERS from './helpers/approvalRequestForm.actionHandlers';

// Constants
import { FORM_MODES } from '../../../../../constants/general.constants';
import { ACTION_TYPES, CONTEXT_IDS, INITIAL_STATE } from './constants/approvalRequestForm.constants';
import { APPROVAL_CENTRE_FIELD_IDS, CUSTOM_ENTITY_CATEGORY } from '../../../../../constants/approvalCentre.constants';
import PAGE_IDS from '../../../constants/pageIds.constants';
import { DEFAULT_OPTIONS } from '../../../../../connectors/withSize';
import FIELD_IDS from './constants/approvalRequestForm.fieldIds';

// Documentation for an system design overview can be found:
// https://tekion.atlassian.net/wiki/spaces/DP/pages/3130425949/Approval+Centre+Architecture+Overview

const ApprovalRequestForm = ({
  isSubmitting,
  isMatchingApprovalProcessLoading,
  isMatchingApprovalProcessLoaded,
  isRequestLoading,
  isMountedInsideApplication,
  contentHeight,
  appRoute,
  formMode,
  history,
  matchingApprovalProcess,
  applicationProperties,
  formValues,
  existingRequestData,
  errors,
  onAction,
}) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const groupValue = getArraySafeValue(tget(formValues, FIELD_IDS.GROUP, EMPTY_ARRAY));
  const categoryValue = getArraySafeValue(tget(formValues, FIELD_IDS.CATEGORY, EMPTY_ARRAY));

  const headerTitle = formMode === FORM_MODES.CREATE ? __('Create Approval Request') : __('Edit Approval Request');

  const isDataFormVisible = groupValue && categoryValue;
  const showSettingFieldsForm = isDataFormVisible && categoryValue !== CUSTOM_ENTITY_CATEGORY;
  const showRecordForm = isDataFormVisible && categoryValue === CUSTOM_ENTITY_CATEGORY;

  const isProcessInfoVisible = !isMatchingApprovalProcessLoading && (!_isEmpty(matchingApprovalProcess) || isMatchingApprovalProcessLoaded);

  const getPrimaryButtonLabel = () => {
    if (!_isEmpty(matchingApprovalProcess)) {
      return __('Send for approval');
    }

    return formMode === FORM_MODES.CREATE ? __('Create') : __('Update');
  };

  const primaryButtonLabel = getPrimaryButtonLabel();

  const updatedContentHeight = useMemo(
    () => (isMountedInsideApplication ? contentHeight - DEFAULT_OPTIONS.headerHeight - DEFAULT_OPTIONS.footerHeight : contentHeight),
    [contentHeight, isMountedInsideApplication],
  );

  const settingIdentifierSections = useMemo(() => getSettingIdentifierSections(formValues), [formValues]);
  const settingIdentifierFields = useMemo(() => getSettingIdentifierFields(formMode), [formMode]);

  const handleCancelClick = useCallback(() => {
    setIsCancelModalOpen(true);
  }, []);

  const handleCancelCancel = useCallback(() => {
    setIsCancelModalOpen(false);
  }, []);

  const handleCancelConfirm = useCallback(() => {
    let prefixPathName = '';

    if (isMountedInsideApplication) {
      prefixPathName = appRoute;
    }

    const pathName = `${prefixPathName}/${PAGE_IDS.APPROVAL_CENTRE}/${PAGE_IDS.MANAGEMENT}/${PAGE_IDS.APPROVAL_REQUESTS}`;

    history.push(pathName); // List route
  }, [isMountedInsideApplication, appRoute, history]);

  const handleSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_SUBMIT_CLICK });
  }, [onAction]);

  const handleRecordFormFieldBlur = useCallback(
    (payload) => {
      onAction({
        type: ACTION_TYPES.ON_RECORD_FORM_FIELD_BLUR,
        payload,
      });
    },
    [onAction],
  );

  const handleRecordFormSubmit = useCallback(
    (payload) => {
      onAction({
        type: ACTION_TYPES.ON_RECORD_FORM_SUBMIT,
        payload,
      });
    },
    [onAction],
  );

  const handleSettingFieldsValueChange = useCallback(
    (updatedValues) => {
      onAction({ type: ACTION_TYPES.ON_SETTING_FIELD_CHANGE, payload: { updatedValues } });
    },
    [onAction],
  );

  const handleSettingFormBlur = useCallback(
    (payload) => {
      onAction({ type: ACTION_TYPES.ON_SETTING_FORM_BLUR, payload });
    },
    [onAction],
  );

  const handleSettingFormSubmit = useCallback(
    (payload) => {
      onAction({ type: ACTION_TYPES.ON_SETTING_FORM_SUBMIT, payload });
    },
    [onAction],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // To be triggered on mount ONLY.

  if (isRequestLoading) {
    return <Loader id={CONTEXT_IDS.IDENTIFIER} style={{ height: 316 }} />;
  }

  return (
    <Page>
      <Page.Header hasBack>
        <Heading>{headerTitle}</Heading>
      </Page.Header>
      <Page.Body style={{ height: updatedContentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          className="p-t-12"
          isFetching={false}
          contextId={CONTEXT_IDS.IDENTIFIER}
          sections={settingIdentifierSections}
          fields={settingIdentifierFields}
          values={formValues}
          errors={errors}
          onAction={onAction}
        />
        <PropertyControlledComponent controllerProperty={showSettingFieldsForm}>
          <SettingFieldsForm
            contextId={CONTEXT_IDS.DATA}
            formValues={formValues}
            existingRequestData={existingRequestData}
            onFormValueChange={handleSettingFieldsValueChange}
            onFormFieldBlur={handleSettingFormBlur}
            onFormSubmit={handleSettingFormSubmit}
          />
        </PropertyControlledComponent>
        <PropertyControlledComponent controllerProperty={showRecordForm}>
          <div>
            <RecordFieldsForm
              contextId={CONTEXT_IDS.ENTITY_RECORD}
              formValues={formValues}
              applicationProperties={applicationProperties}
              onFormFieldBlur={handleRecordFormFieldBlur}
              onFormSubmit={handleRecordFormSubmit}
            />
          </div>
        </PropertyControlledComponent>
        <PropertyControlledComponent controllerProperty={isProcessInfoVisible}>
          <ApprovalStageCollapsiblePanels
            isApprovalProcessAvailable={!_isEmpty(matchingApprovalProcess)}
            approvalStages={tget(matchingApprovalProcess, APPROVAL_CENTRE_FIELD_IDS.STAGES, EMPTY_ARRAY)}
          />
        </PropertyControlledComponent>
        <ConfirmationDialog
          isVisible={isCancelModalOpen}
          title={__('Cancel Changes')}
          content={__('Are you sure you want to cancel all the changes made by you? All data will be lost if you click yes.')}
          onSubmit={handleCancelConfirm}
          onCancel={handleCancelCancel}
        />
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id={CONTEXT_IDS.IDENTIFIER}
          primaryActionLoading={isSubmitting || isMatchingApprovalProcessLoading}
          primaryButtonLabel={primaryButtonLabel}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancelClick}
        />
      </Page.Footer>
    </Page>
  );
};

ApprovalRequestForm.propTypes = {
  isSubmitting: PropTypes.bool,
  isRequestLoading: PropTypes.bool,
  isMatchingApprovalProcessLoading: PropTypes.bool,
  isMatchingApprovalProcessLoaded: PropTypes.bool,
  isMountedInsideApplication: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  appRoute: PropTypes.string,
  formMode: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  matchingApprovalProcess: PropTypes.object,
  applicationProperties: PropTypes.object,
  existingRequestData: PropTypes.object,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ApprovalRequestForm.defaultProps = {
  isSubmitting: false,
  isRequestLoading: false,
  isMatchingApprovalProcessLoading: false,
  isMatchingApprovalProcessLoaded: false,
  isMountedInsideApplication: false,
  appRoute: EMPTY_STRING,
  formMode: FORM_MODES.CREATE,
  matchingApprovalProcess: EMPTY_OBJECT,
  applicationProperties: EMPTY_OBJECT,
  existingRequestData: EMPTY_OBJECT,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default compose(withRouter, withActions(INITIAL_STATE, ACTION_HANDLERS))(ApprovalRequestForm);
