exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".conditionBuilder_mainContainer__sHnFR2YzDb{width:100%}[dir] .conditionBuilder_mainContainer__sHnFR2YzDb{background-color:#fff}.conditionBuilder_subContainer__jKuza19W3p{width:100%}[dir] .conditionBuilder_subContainer__jKuza19W3p{background-color:#fcfcfc;padding:1.6rem;border:.1rem dashed #969aa3;border-radius:.5rem}[dir] .conditionBuilder_conditionLogicContainer__fzXHG7ieBT{margin-bottom:3.2rem}.conditionBuilder_logicInfoContainers__3BH1rDe3wJ{width:100%;display:flex;align-items:center}[dir=ltr] .conditionBuilder_infoContainer__wPjqYCZh4G{margin:0 0 .8rem .8rem}[dir=rtl] .conditionBuilder_infoContainer__wPjqYCZh4G{margin:0 .8rem .8rem 0}.conditionBuilder_variablesInfo__6jWRvSC6A9{max-width:45rem}.conditionBuilder_variablesInfo__6jWRvSC6A9 .ant-popover-inner-content{font-family:\"Proxima-Nova-Semibold\"}[dir] .conditionBuilder_variablesInfo__6jWRvSC6A9 .ant-popover-inner-content{padding:1rem !important}", ""]);

// Exports
exports.locals = {
	"mainContainer": "conditionBuilder_mainContainer__sHnFR2YzDb",
	"subContainer": "conditionBuilder_subContainer__jKuza19W3p",
	"conditionLogicContainer": "conditionBuilder_conditionLogicContainer__fzXHG7ieBT",
	"logicInfoContainers": "conditionBuilder_logicInfoContainers__3BH1rDe3wJ",
	"infoContainer": "conditionBuilder_infoContainer__wPjqYCZh4G",
	"variablesInfo": "conditionBuilder_variablesInfo__6jWRvSC6A9"
};