import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import SingleSelect from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SingleSelect';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import AsyncPaginatedCreatableSelect from '../../../../../../../molecules/asyncPaginatedCreatableSelect/AsyncPaginatedCreatableSelect';

import { searchAuthProviders } from '../../../../../../../actions/authProvider.actions';

import { getAuthProviderOptions, getPayloadForAuthProvider } from './externalCredentialForm.helpers';

import { FIELD_IDS, AUTH_TYPES_OPTIONS, SIGNING_ALGORITHM_OPTIONS } from '../constants/externalCredentialForm.constants';
import { FORM_MODES } from '../../../../../../../constants/general.constants';

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Name'),
    validators: [isRequiredRule],
  },
};

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Display Name'),
    validators: [isRequiredRule],
  },
};

const TYPE_FIELD = {
  id: FIELD_IDS.TYPE,
  renderer: SingleSelect,
  renderOptions: {
    label: __('Auth Type'),
    options: AUTH_TYPES_OPTIONS,
  },
};

const AUTH_PROVIDER_NAME_FIELD = {
  id: FIELD_IDS.AUTH_PROVIDER_NAME,
  renderer: AsyncPaginatedCreatableSelect,
  renderOptions: {
    isMulti: false,
    label: __('Auth Provider Name'),
    id: FIELD_IDS.AUTH_PROVIDER_NAME,
    createOptionPosition: 'first',
    getFormattedCreateLabel: () => __('Create Auth Provider'),
    checkIsValidNewOption: () => true,
    serviceHandler: searchAuthProviders,
    getOptions: getAuthProviderOptions,
    getPayload: getPayloadForAuthProvider,
  },
};

const SCOPE_FIELD = {
  id: FIELD_IDS.SCOPE,
  renderer: TextInput,
  renderOptions: {
    label: __('Scope'),
  },
};

const USER_NAME_FIELD = {
  id: FIELD_IDS.USER_NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Username'),
  },
};

const PASSWORD_FIELD = {
  id: FIELD_IDS.PASSWORD,
  renderer: TextInput,
  renderOptions: {
    label: __('Password'),
  },
};

const APPLICATION_NAME_FIELD = {
  id: FIELD_IDS.APPLICATION_NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Application Name'),
  },
};

const PLATFORM_FIELD = {
  id: FIELD_IDS.PLATFORM,
  renderer: TextInput,
  renderOptions: {
    label: __('Platform'),
  },
};

const SERVICE_FIELD = {
  id: FIELD_IDS.SERVICE,
  renderer: TextInput,
  renderOptions: {
    label: __('Service'),
  },
};

const REGION_FIELD = {
  id: FIELD_IDS.REGION,
  renderer: TextInput,
  renderOptions: {
    label: __('Region'),
  },
};

const AWS_ACCOUNT_ID_FIELD = {
  id: FIELD_IDS.AWS_ACCOUNT_ID,
  renderer: TextInput,
  renderOptions: {
    label: __('AWS Account ID'),
  },
};

const ACCESS_KEY_FIELD = {
  id: FIELD_IDS.ACCESS_KEY,
  renderer: TextInput,
  renderOptions: {
    label: __('Access Key'),
  },
};

const SECRET_KEY_FIELD = {
  id: FIELD_IDS.SECRET_KEY,
  renderer: TextInput,
  renderOptions: {
    label: __('Secret Key'),
  },
};

const PRE_SHARED_KEY_FIELD = {
  id: FIELD_IDS.PRE_SHARED_KEY,
  renderer: TextInput,
  renderOptions: {
    label: __('Pre Shared Key'),
  },
};

const SIGNING_ALGORITHM_FIELD = {
  id: FIELD_IDS.SIGNING_ALGORITHM,
  renderer: SingleSelect,
  renderOptions: {
    label: __('Signing Algorithm'),
    options: SIGNING_ALGORITHM_OPTIONS,
  },
};

const HEADER_KEY_FIELD = {
  id: FIELD_IDS.HEADER_KEY,
  renderer: TextInput,
  renderOptions: {
    label: __('Header Key'),
  },
};

const API_KEY_FIELD = {
  id: FIELD_IDS.API_KEY,
  renderer: TextInput,
  renderOptions: {
    label: __('Api Key'),
  },
};

const EXTERNAL_CREDENTIAL_FORM_FIELDS = {
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.TYPE]: TYPE_FIELD,
  [FIELD_IDS.AUTH_PROVIDER_NAME]: AUTH_PROVIDER_NAME_FIELD,
  [FIELD_IDS.SCOPE]: SCOPE_FIELD,
  [FIELD_IDS.USER_NAME]: USER_NAME_FIELD,
  [FIELD_IDS.PASSWORD]: PASSWORD_FIELD,
  [FIELD_IDS.APPLICATION_NAME]: APPLICATION_NAME_FIELD,
  [FIELD_IDS.PLATFORM]: PLATFORM_FIELD,
  [FIELD_IDS.SERVICE]: SERVICE_FIELD,
  [FIELD_IDS.REGION]: REGION_FIELD,
  [FIELD_IDS.AWS_ACCOUNT_ID]: AWS_ACCOUNT_ID_FIELD,
  [FIELD_IDS.ACCESS_KEY]: ACCESS_KEY_FIELD,
  [FIELD_IDS.SERVICE]: SECRET_KEY_FIELD,
  [FIELD_IDS.PRE_SHARED_KEY]: PRE_SHARED_KEY_FIELD,
  [FIELD_IDS.SIGNING_ALGORITHM]: SIGNING_ALGORITHM_FIELD,
  [FIELD_IDS.HEADER_KEY]: HEADER_KEY_FIELD,
  [FIELD_IDS.API_KEY]: API_KEY_FIELD,
};

const getExternalCredentialFormFields = (formMode) => ({
  ...EXTERNAL_CREDENTIAL_FORM_FIELDS,
  [FIELD_IDS.NAME]: addToRenderOptions(EXTERNAL_CREDENTIAL_FORM_FIELDS[FIELD_IDS.NAME], [{ path: 'disabled', value: formMode === FORM_MODES.EDIT }]),
});

export default getExternalCredentialFormFields;
