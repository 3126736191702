import _isNil from 'lodash/isNil';
import _forEach from 'lodash/forEach';
import _includes from 'lodash/includes';

import { tget } from '@tekion/tekion-base/utils/general';

import {
  DEFAULT_ICON,
  DEFAULT_COLOR,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_STYLE_PROPERTIES,
  HIDDEN_NODES_TASK_DEFS,
} from '../constants/rightDrawer.constants';

const getStylePropertiesFromUiMetadata = (uiMetadata) => {
  if (_isNil(uiMetadata)) return DEFAULT_STYLE_PROPERTIES;

  const icon = tget(uiMetadata, 'icon', DEFAULT_ICON);
  const color = tget(uiMetadata, 'color', DEFAULT_COLOR);
  const backgroundColor = tget(uiMetadata, 'backgroundColor', DEFAULT_BACKGROUND_COLOR);

  return { icon, color, backgroundColor };
};

const getItemsByTaskCategory = (steps) => {
  const itemsByTaskCategory = {};
  _forEach(steps, (step) => {
    const taskDefId = tget(step, 'taskDefId');

    if (!_includes(HIDDEN_NODES_TASK_DEFS, taskDefId)) {
      const taskCategory = tget(step, 'taskCategory');
      const updatedItems = tget(itemsByTaskCategory, taskCategory, []);
      updatedItems.push(step);
      itemsByTaskCategory[taskCategory] = updatedItems;
    }
  });
  return itemsByTaskCategory;
};

export { getStylePropertiesFromUiMetadata, getItemsByTaskCategory };
