const PAGE_TYPES = {
  HOME_PAGE: 'HOME_PAGE',
  RECORD_DETAIL_PAGE: 'RECORD_DETAIL_PAGE',
};

// This const is defined in BE
const PAGE_CONFIG_IDS = {
  PAGE_CONTAINERS: 'containers',
  PAGE_TYPE: 'pageType',
  TITLE: 'title',
  PAGE_NAME: 'name',
  PAGE_DISPLAY_NAME: 'displayName',
  PAGE_TITLE: 'title',
  PAGE_DESCRIPTION: 'description',
  PAGE_PROPERTIES: 'properties',
  PAGE_EVENT_HANDLERS: 'eventHandlers',
  PAGE_CUSTOM_STYLES: 'customStyles',
  PAGE_LAYOUTS: 'layouts',
  PAGE_WIDGET_CONTAINERS: 'widgetContainers',
  PAGE_AUTOMATED: 'automated',
  PAGE_ENTITY_NAME: 'entityName',
  WIDGET_NAME: 'widgetName',
  WIDGET_EVENT_HANDLER: 'eventHandler',
  WIDGET_CUSTOM_STYLE: 'customStyle',
  WIDGET_LAYOUT: 'layout',
  WIDGET_WIDTH: 'width',
  WIDGET_HEIGHT: 'height',
  WIDGET_ROW: 'row',
  WIDGET_COLUMN: 'column',
  WIDGET_CHILDREN: 'childWidgets',
  WIDGETS: 'widgets',
};

const VIEW_OVERRIDE_CONFIG_IDS = {
  VIEW_OVERRIDE_CONFIG: 'entityViewOverrideConfig',
  EVENT_HANDLER: 'eventHandlerData',
  ACTION_NAMES: 'actionNames',
  PRE_APPLIED_FILTERS: 'preAppliedFilters',
  SHOW_ACTIONS: 'showActions',
};

// This const is defined in BE
const EVENT_HANDLER_KEYS = {
  EVENT_TYPE: 'eventType', // ENUMs defined in src/constants/eventActions.constants.js
  PROPERTIES: 'properties',
  SUB_EVENT_TYPE: 'subEventType', // [SCRIPT, PRE_DEFINED]
  ACTION_TO_EXECUTE: 'actionToExecute',
  METADATA: 'metadata',
  ACTION_NAME_TO_EXECUTE: 'actionNameToExecute', // need to deprecate ACTION_ID_TO_EXECUTE
  PAGE_NAME_TO_NAVIGATE: 'pageNameToNavigate', // need to deprecate PAGE_ID_TO_NAVIGATE
  NAVIGATE_TO_PREV_PAGE: 'navigateToPrevPage',
};

const WIDGET_TYPES = {
  PARENT_WIDGET: 'PARENT_WIDGET',
  CONTAINER: 'CONTAINER',
  BUTTON_WIDGET: 'BUTTON_WIDGET',
  TEXT_WIDGET: 'TEXT_WIDGET',
  TAB_WIDGET: 'TAB_WIDGET',
  REPORTING_WIDGET: 'REPORTING_WIDGET',
  TAB_PANE: 'TAB_PANE',
  CHILD_WIDGET: 'CHILD_WIDGET',
  RELATED_WIDGET: 'RELATED_WIDGET',
  SIMILAR_WIDGET: 'SIMILARITY_WIDGET',
  STANDARD_WIDGET: 'STANDARD_WIDGET',
};

const COMPONENT_CONFIG_KEYS = {
  CHILDREN: 'children',
  WIDGET_ID: 'widgetId',
  PROPERTIES: 'properties',
  WIDGET_TYPE: 'widgetType',
  WIDGET_NAME: 'widgetName',
};

const PAGE_FIELD_IDS = {
  PAGE_TITLE: 'title',
  PAGE_NAME: 'name',
  PAGE_DESCRIPTION: 'description',
  PAGE_TYPE: 'pageType',
  LAYOUT_NAME: 'layout',
  PAGE_ENTITY_NAME: 'entityName',
};

// This const is defined in BE.
const ALL_WIDGET_PROPERTY_KEY = {
  WIDGET_TYPE: 'widgetType',
  VIEW_TYPE: 'viewType',
  BUTTON_TYPE: 'buttonType',
  ENTITY_NAME: 'entityName',
  VIEW_NAME: 'entityViewName', // need to deprecate viewId
  CHILD_ENTITY_NAME: 'childEntityName',
  RELATIVE_FIELD_NAME: 'relativeFieldName',
  PRE_APPLIED_FILTERS: 'preAppliedFilters',
  CUSTOM_STYLES: 'customStyles',
  BUTTON_VIEWS: 'BUTTON_VIEWS',
  EVENT_HANDLERS: 'eventHandlers',
  WIDGET_NAME: 'widgetName', // need to deprecate widgetId

  // ---------Widget Specific Properties ------------//
  // Text widget
  SIZE: 'size',
  LABEL: 'label',
  ON_CLICK_ENABLED: 'onClickEnabled',
  // Tab widget
  TAB_POSITION: 'position',
  TAB_SIZE: 'size',
  TAB_METADATA_LIST: 'metadataList',
  TAB_NAME: 'name',
  TAB_ICON: 'icon',
  TAB_CUSTOM_STYLES: 'customStyles',
  // Button widget
  BUTTON_TEXT: 'text',
  BUTTON_VIEW_TYPE: 'buttonType', // need to deprecate viewType
  BUTTON_ICON: 'icon',
  // container widget
  WIDGET_POSITION: 'widgetPosition', //  deprecate  widgetPositions ['horizontal', 'vertical']
  SCROLL_ENABLED: 'scrollEnabled',
  // reporting widget
  TIME_TRIGGER_PRESENT: 'timeTriggerPresent', // add this const to widget
  // similar widget
  SIMILAR_SEARCH_METADATA: 'similarSearchMetadataList',
  SIMILAR_SEARCH_FIELD: 'field',
  SIMILAR_SEARCH_BOOST: 'boost',
  SIMILAR_SEARCH_FUZZY_PARAMS: 'fuzzyParams',
  SIMILAR_SEARCH_FUZZY: 'fuzzy',

  // ---------Generic Properties -----//
  // View Overrides
  VIEW_OVERRIDE_CONFIG: 'viewOverrideConfig',
  SHOW_ACTIONS: 'showActions',
  ACTION_NAMES: 'actionNames', // Need to deprecate actionIds
  FILTER: 'filter', // BE will change this preAppliedFilter
  FILTER_FIELD: 'field',
  FILTER_FILTER_TYPE: 'filterType',
  FILTER_VALUES: 'values',
  EVENT_HANDLER_DATA: 'eventHandlerData',
};

export {
  PAGE_TYPES,
  PAGE_CONFIG_IDS,
  COMPONENT_CONFIG_KEYS,
  WIDGET_TYPES,
  ALL_WIDGET_PROPERTY_KEY,
  EVENT_HANDLER_KEYS,
  VIEW_OVERRIDE_CONFIG_IDS,
  PAGE_FIELD_IDS,
};
