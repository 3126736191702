import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import IconRenderer from '../../../../atoms/IconRenderer/IconRenderer';
import FieldValuePair from '../fieldValuePair/FieldValuePair';

import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../constants/approvalCentre.constants';

import styles from './approvalStagePanelHeader.module.scss';

const ApprovalStagePanelHeader = ({ isBuilderMode, index, stageDetails, onEditStage, onDeleteStage }) => {
  const stageLevel = useMemo(() => tget(stageDetails, [APPROVAL_CENTRE_FIELD_IDS.APPROVAL_LEVEL], 0), [stageDetails]);
  const stageMinApprovals = useMemo(() => tget(stageDetails, [APPROVAL_CENTRE_FIELD_IDS.MIN_APPROVALS], 0), [stageDetails]);
  const stageValidity = useMemo(() => tget(stageDetails, [APPROVAL_CENTRE_FIELD_IDS.VALIDITY], NO_DATA), [stageDetails]);

  const handleClickDeleteStage = useCallback(
    (event) => {
      event.stopPropagation();
      onDeleteStage(index);
    },
    [index, onDeleteStage],
  );

  const handleClickEditStage = useCallback(
    (event) => {
      event.stopPropagation();
      onEditStage(index);
    },
    [index, onEditStage],
  );

  return (
    <div className={styles.stageDetailsHeader}>
      <div className={styles.stageLevelApproversSection}>
        <span className={styles.stageLevel}>{__(`${stageLevel}`)}</span>
      </div>
      <div className={styles.stageApproversValiditySection}>
        <div className={styles.minApprovals}>
          <FieldValuePair icon="icon-checked-outlined" label={__('Minimum Approvals')} value={stageMinApprovals} />
        </div>
        <FieldValuePair icon="icon-clock-in" label={__('Stage Validity')} value={stageValidity} />
        <PropertyControlledComponent controllerProperty={isBuilderMode}>
          <div className={styles.iconSection}>
            <IconRenderer iconName="icon-edit" iconSize={SIZES.MD} onClick={handleClickEditStage} />
            <IconRenderer iconName="icon-trashcan" iconSize={SIZES.MD} onClick={handleClickDeleteStage} />
          </div>
        </PropertyControlledComponent>
      </div>
    </div>
  );
};

ApprovalStagePanelHeader.propTypes = {
  isBuilderMode: PropTypes.bool,
  index: PropTypes.number,
  stageDetails: PropTypes.object,
  onEditStage: PropTypes.func,
  onDeleteStage: PropTypes.func,
};

ApprovalStagePanelHeader.defaultProps = {
  isBuilderMode: false,
  index: undefined,
  stageDetails: EMPTY_OBJECT,
  onEditStage: _noop,
  onDeleteStage: _noop,
};

export default ApprovalStagePanelHeader;
