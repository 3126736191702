import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import Dropdown, { DROPDOWN_PLACEMENT, DROPDOWN_TRIGGER } from '@tekion/tekion-components/molecules/DropDown';
import Menu from '@tekion/tekion-components/molecules/Menu';
import Icon from '@tekion/tekion-components/atoms/FontIcon';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Button from '@tekion/tekion-components/atoms/Button';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import { getDateDifferenceHumanised } from '@tekion/tekion-base/utils/dateUtils';

import UserAvatar from '../../../atoms/userAvatar/UserAvatar';
import CommentInput from './CommentInput';
import CommentList from './CommentList';
import AsyncMentions from '../../../molecules/asyncMentions';

import { COMMENT_ACTION_TYPES, COMMENT_ACTIONS, BUTTON_ACTION_TYPES, BUTTON_LABELS, COMMENT_MODES } from '../constants/commentRenderer.constants';
import ACTION_TYPES from '../constants/commentRenderer.actionTypes';
import workspaceUserReader from '../../../readers/workSpaceUser.reader';
import commentReader from '../../../readers/comment.reader';

import styles from '../commentRenderer.module.scss';

const Comment = ({
  isPreviewMode,
  commentIndex,
  parentCommentIndex,
  commentLevel,
  commentRef,
  parentCommentId,
  comment,
  userDetailsById,
  currentUser,
  scrollToCommentInputHandler,
  scrollToTopComment,
  onAction,
}) => {
  const [areRepliesLoading, setAreRepliesLoading] = useState(false);
  const commentId = commentReader.id(comment);
  const userId = commentReader.createdBy(comment);
  const commentMode = commentReader.commentMode(comment);
  const userDetail = _get(userDetailsById, userId, EMPTY_OBJECT);
  const userName = commentMode === COMMENT_MODES.VIEW ? workspaceUserReader.NAME(userDetail) : workspaceUserReader.NAME(currentUser);
  const userAvatar =
    commentMode === COMMENT_MODES.VIEW ? workspaceUserReader.profilePicture(userDetail) : workspaceUserReader.profilePicture(currentUser);
  const createdTime = commentReader.createdTime(comment);
  const modifiedTime = commentReader.modifiedTime(comment);
  const humanisedCommentCreatedTime = getDateDifferenceHumanised(createdTime);
  const commentContent = commentReader.content(comment);
  const commentReplies = commentReader.replies(comment);
  const showRepliesEnabled = commentReader.showRepliesEnabled(comment);
  const showReplyInput = commentReader.showReplyInput(comment);
  const nextPageToken = commentReader.nextPageToken(comment);

  const commentInputRef = useRef(commentLevel + 1);

  const scrollToCommentInput = useCallback(() => {
    if (commentInputRef && commentInputRef.current && commentInputRef.current.scrollIntoView) {
      commentInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, []);

  const onCommentActionClick = useCallback(
    (action) => {
      const actionId = _get(action, 'key', EMPTY_STRING);

      if (actionId === COMMENT_ACTION_TYPES.EDIT) {
        onAction({
          type: ACTION_TYPES.CHANGE_COMMENT_MODE,
          payload: { commentMode: COMMENT_MODES.EDIT, commentPath: { commentIndex, parentCommentIndex } },
        });
      } else if (actionId === COMMENT_ACTION_TYPES.DELETE) {
        onAction({
          type: ACTION_TYPES.DELETE_COMMENT,
          payload: {
            commentId,
            inReplyTo: commentReader.inReplyTo(comment),
          },
        });
      }
    },
    [parentCommentIndex, commentIndex, commentId, comment, onAction],
  );

  const renderCommentActions = useCallback(
    () => (
      <Menu className={styles.actionMenu}>
        {_map(COMMENT_ACTIONS, ({ label, value }) => (
          <Menu.Item key={value} onClick={onCommentActionClick}>
            <div>{__(label)}</div>
          </Menu.Item>
        ))}
      </Menu>
    ),
    [onCommentActionClick],
  );

  const renderCommentActionDropdown = useCallback(
    () => (
      <Dropdown overlay={renderCommentActions} placement={DROPDOWN_PLACEMENT.BOTTOM_RIGHT} trigger={DROPDOWN_TRIGGER.CLICK}>
        <Icon data-disable-sort-start>icon-overflow</Icon>
      </Dropdown>
    ),
    [renderCommentActions],
  );

  const handleShowRepliesClick = useCallback(() => {
    if (showRepliesEnabled) {
      onAction({ type: ACTION_TYPES.HIDE_REPLIES, payload: { comment, commentIndex } });
    } else {
      setAreRepliesLoading(true);
      onAction({ type: ACTION_TYPES.SHOW_REPLIES, payload: { commentId, setAreRepliesLoading } });
    }
  }, [showRepliesEnabled, commentIndex, commentId, comment, onAction]);

  const handleReplyClick = useCallback(() => {
    if (commentLevel === 1) {
      onAction({ type: ACTION_TYPES.TOGGLE_REPLY_INPUT, payload: { commentId, scrollToCommentInput } });
    } else {
      scrollToCommentInputHandler();
    }
  }, [commentLevel, commentId, scrollToCommentInput, scrollToCommentInputHandler, onAction]);

  const renderReplies = useCallback(() => {
    const renderComponent = [];
    if (commentLevel === 1 && showRepliesEnabled && _isEmpty(commentReplies)) {
      renderComponent.push(<div className={styles.noRepliesMessage}>{__('No replies to show')}</div>);
    }
    if (showReplyInput || (commentLevel === 1 && showRepliesEnabled && !_isEmpty(commentReplies))) {
      renderComponent.push(
        <CommentList
          commentLevel={2}
          parentCommentIndex={commentIndex}
          nextPageToken={nextPageToken}
          parentCommentId={commentId}
          commentInputRef={commentInputRef}
          commentsTree={commentReplies}
          userDetailsById={userDetailsById}
          currentUser={currentUser}
          scrollToCommentInput={scrollToCommentInput}
          onAction={onAction}
        />,
      );
    }

    if (!_isEmpty(renderComponent)) {
      return renderComponent;
    }

    return null;
  }, [
    commentLevel,
    showRepliesEnabled,
    commentReplies,
    showReplyInput,
    commentIndex,
    nextPageToken,
    commentId,
    userDetailsById,
    currentUser,
    scrollToCommentInput,
    onAction,
  ]);

  const renderComment = useCallback(
    () => (
      <div className={styles.commentContent}>
        <div className={styles.commentContentHeader}>
          <div className={styles.commentContentHeaderLeft}>
            <UserAvatar userAvatar={userAvatar} displayName={userName} userAvatarContainerClassName={styles.userAvatar} avatarSize="small" />
            <div className={styles.commentContentName}>{userName}</div>
            <div className={styles.commentContentTime}>
              {humanisedCommentCreatedTime}
              {createdTime !== modifiedTime ? __(' (edited) ') : null}
            </div>
          </div>
          {renderCommentActionDropdown()}
        </div>
        <AsyncMentions isPreviewMode userAvatar={userAvatar} userName={userName} content={commentContent} />
      </div>
    ),
    [commentContent, createdTime, humanisedCommentCreatedTime, modifiedTime, renderCommentActionDropdown, userAvatar, userName],
  );

  const renderContent = () => (
    <div ref={commentRef} className={styles.commentSection}>
      <div className={styles.commentContentBox}>
        <PropertyControlledComponent
          controllerProperty={commentMode === COMMENT_MODES.VIEW}
          fallback={
            <CommentInput
              isPreviewMode={isPreviewMode}
              commentIndex={commentIndex}
              parentCommentIndex={parentCommentIndex}
              commentLevel={commentLevel}
              parentCommentId={parentCommentId}
              commentMode={commentMode}
              comment={comment}
              currentUser={currentUser}
              scrollToTopComment={scrollToTopComment}
              onAction={onAction}
            />
          }
        >
          {renderComment()}
          <div className={styles.commentContentBoxFooter}>
            <PropertyControlledComponent controllerProperty={commentLevel === 1}>
              <Button
                loading={areRepliesLoading}
                view={Button.VIEW.TERTIARY}
                label={showRepliesEnabled ? BUTTON_LABELS[BUTTON_ACTION_TYPES.HIDE_REPLIES] : BUTTON_LABELS[BUTTON_ACTION_TYPES.SHOW_REPLIES]}
                className={styles.commentContentBoxFooterButton}
                onClick={handleShowRepliesClick}
              />
            </PropertyControlledComponent>
            <Button
              view={Button.VIEW.TERTIARY}
              label={BUTTON_LABELS[BUTTON_ACTION_TYPES.REPLY]}
              className={styles.commentContentBoxFooterButton}
              onClick={handleReplyClick}
            />
          </div>
          <div className={styles.repliesContainer}>{renderReplies()}</div>
        </PropertyControlledComponent>
      </div>
    </div>
  );

  return <div className={styles.comment}>{renderContent()}</div>;
};

Comment.propTypes = {
  isPreviewMode: PropTypes.bool,
  commentIndex: PropTypes.number,
  parentCommentIndex: PropTypes.number,
  commentLevel: PropTypes.number,
  commentRef: PropTypes.object,
  parentCommentId: PropTypes.string,
  comment: PropTypes.object,
  userDetailsById: PropTypes.object,
  currentUser: PropTypes.object,
  scrollToCommentInputHandler: PropTypes.func,
  scrollToTopComment: PropTypes.func,
  onAction: PropTypes.func.isRequired,
};

Comment.defaultProps = {
  isPreviewMode: true,
  commentIndex: undefined,
  parentCommentIndex: undefined,
  commentLevel: undefined,
  commentRef: null,
  parentCommentId: undefined,
  comment: EMPTY_OBJECT,
  userDetailsById: EMPTY_OBJECT,
  currentUser: EMPTY_OBJECT,
  scrollToCommentInputHandler: _noop,
  scrollToTopComment: _noop,
};

export default React.memo(Comment);
