import _constant from 'lodash/constant';

import ACTION_TYPES from '../../../constants/sortOptionsConfigurator.actionTypes';
import { ORDER_FIELD_OPTION_IDS } from '../../../constants/sortOptionsConfigurator.constants';

const ROW_ACTION_PROPS = {
  visibleActionCount: 1,
};

const REMOVE_ACTION = {
  id: ACTION_TYPES.TABLE_REMOVE_ROW,
  name: __('Remove row'),
  icon: 'icon-trash',
};

const ROW_ACTIONS = _constant([REMOVE_ACTION]);

const EDIT_ACTION = {
  id: 'EDIT_ROW',
  name: __('Edit'),
  icon: 'icon-edit',
};

const TABLE_ACTION_TYPES = {
  ON_CHANGE: 'ON_CHANGE',
  TABLE_ACTION_CLICK: 'TABLE_ACTION_CLICK',
};

const COLUMN_IDS = {
  SORT_FIELD: 'field',
  SORT_ORDER: 'order',
};

const SORT_ORDER_FIELD_OPTIONS = [
  { label: __('ASC'), value: ORDER_FIELD_OPTION_IDS.ASC },
  { label: __('DESC'), value: ORDER_FIELD_OPTION_IDS.DESC },
];

export { ROW_ACTION_PROPS, REMOVE_ACTION, EDIT_ACTION, TABLE_ACTION_TYPES, COLUMN_IDS, SORT_ORDER_FIELD_OPTIONS, ROW_ACTIONS };
