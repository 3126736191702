import _map from 'lodash/map';
import _castArray from 'lodash/castArray';

import { NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { ACTIVE, ENTITY, ROWS } from '../constants/customActionsVisualConfigurator.constants';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../../constants/actionBuilder.constants';
import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../../../constants/viewBuilder.constants';

const getPayload =
  (entityName) =>
  ({ nextPageToken, searchText }) => ({
    rows: ROWS,
    nextPageToken,
    searchText,
    filters: [
      {
        field: ACTION_DEFINITION_FIELD_IDS.ACTION_LEVEL,
        values: [ENTITY],
        filterType: OPERATORS.IN,
      },
      {
        field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
        values: _castArray(entityName),
        filterType: OPERATORS.IN,
      },
      {
        field: ACTION_DEFINITION_FIELD_IDS.STATUS,
        values: [ACTIVE],
        filterType: OPERATORS.IN,
      },
    ],
  });

const getValueOptionForAction = (actionData) => ({
  label: tget(actionData, ACTION_DEFINITION_FIELD_IDS.ACTION_DISPLAY_NAME, NO_DATA),
  value: tget(actionData, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME),
});

const getActionNameOptions = (data) => _map(data, getValueOptionForAction);

export { getPayload, getActionNameOptions };
