import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getPreventDefaultHandler } from '@udecode/plate-common';
import { ToolbarButton } from '@udecode/plate-toolbar';

import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import useEditor from '../../../hooks/useEditor';

import { showShortcutsInfoDialog } from '../../../organisms/shortcutsInfoDialog/helpers/shortcutsInfoDialog.event';

const ShortcutsInfoToolbarButton = ({ icon }) => {
  const editorRef = useEditor();

  const shortcutsInfoIcon = useMemo(() => <FontIcon size={SIZES.MD_S}>{icon}</FontIcon>, [icon]);

  const handleMouseDown = useCallback(
    (event) =>
      getPreventDefaultHandler((editor) => {
        showShortcutsInfoDialog(editor);
      }, editorRef)(event),
    [editorRef],
  );

  return <ToolbarButton icon={shortcutsInfoIcon} onMouseDown={handleMouseDown} />;
};

ShortcutsInfoToolbarButton.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default React.memo(ShortcutsInfoToolbarButton);
