import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import withActions from '@tekion/tekion-components/connectors/withActions';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';

import { getFields } from './helpers/navigationProperties.fields';
import { getConfirmationDialogContent } from './helpers/navigationProperties.helpers';
import ACTION_HANDLERS from './helpers/navigationProperties.actionHandlers';
import { NAVIGATION_PROPERTIES_CONTEXT_ID } from './constants/navigationProperties.constants';
import { SECTIONS } from './constants/navigationProperties.section';
import ACTION_TYPES from './constants/navigationProperties.actionTypes';

const NavigationProperties = ({ isRequestDialogVisible, confirmationModalId, selectedComponentId, errorObject, formValues, onAction }) => {
  const fields = useMemo(() => getFields(formValues), [formValues]);
  const confirmationDialogContent = useMemo(() => getConfirmationDialogContent(confirmationModalId), [confirmationModalId]);

  const errors = useMemo(() => tget(errorObject, selectedComponentId, EMPTY_OBJECT), [errorObject, selectedComponentId]);
  const handleConfirmationDialogSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CONFIRM_REQUEST });
  }, [onAction]);

  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL_REQUEST });
  }, [onAction]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction]);

  return (
    <>
      <FormWithSubmission
        contextId={NAVIGATION_PROPERTIES_CONTEXT_ID}
        fields={fields}
        sections={SECTIONS}
        errors={errors}
        values={formValues}
        onAction={onAction}
      />
      <PropertyControlledComponent controllerProperty={isRequestDialogVisible}>
        <ConfirmationDialog
          isVisible={isRequestDialogVisible}
          title={__('Cancel Changes')}
          content={confirmationDialogContent}
          onSubmit={handleConfirmationDialogSubmit}
          onCancel={handleConfirmationDialogCancel}
        />
      </PropertyControlledComponent>
    </>
  );
};

NavigationProperties.propTypes = {
  isRequestDialogVisible: PropTypes.bool,
  confirmationModalId: PropTypes.string,
  selectedComponentId: PropTypes.string,
  formValues: PropTypes.object,
  errorObject: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

NavigationProperties.defaultProps = {
  isRequestDialogVisible: false,
  confirmationModalId: EMPTY_STRING,
  selectedComponentId: EMPTY_STRING,
  errorObject: EMPTY_OBJECT,
  formValues: EMPTY_OBJECT,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(NavigationProperties);
