import _pick from 'lodash/pick';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';

import { defaultMemoize } from 'reselect';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import FILTER_FIELD_KEYS from '../../../../../constants/filter.constants';
import { COLUMN_IDS } from '../constants/exportListPage.columns';
import { TASK_SUCCESS_ACTIONS, TASK_PENDING_ACTIONS, TASK_FAILED_ACTIONS } from '../constants/exportListPage.rowActions';

import { EXPORT_STATUSES } from '../constants/exportListPage.constants';

const getRowActions = (rowData) => {
  const status = _get(rowData, COLUMN_IDS.STATUS, EXPORT_STATUSES.FAILED);

  if (status === EXPORT_STATUSES.IN_PROGRESS || status === EXPORT_STATUSES.NEW) {
    return TASK_PENDING_ACTIONS;
  }

  if (status === EXPORT_STATUSES.COMPLETED) {
    return TASK_SUCCESS_ACTIONS;
  }

  return TASK_FAILED_ACTIONS;
};

const getTableProps = (handleRowAction, { totalCount, loading, currentPage, pageSize }) => ({
  minRows: 0,
  totalNumberOfEntries: totalCount,
  loading,
  currentPage: currentPage + 1,
  showPagination: true,
  resultsPerPage: pageSize,
  pageSize,
  rowHeight: 40,
  showActions: true,
  getRowActions,
  onRowActionClick: handleRowAction,
});

const createFiltersObject = defaultMemoize((selectedFilters) => {
  const filtersModified = _map(selectedFilters, (filter) => ({
    ...filter,
    [FILTER_FIELD_KEYS.FIELD]: _get(filter, 'type'),
    [FILTER_FIELD_KEYS.FILTER_TYPE]: _get(filter, 'operator'),
  }));
  const filters = _map(
    _filter(filtersModified, (filter) => !_isEmpty(_get(filter, 'values'))),
    (filter) => _pick(filter, [FILTER_FIELD_KEYS.FIELD, FILTER_FIELD_KEYS.FILTER_TYPE, FILTER_FIELD_KEYS.VALUES]),
  );

  return filters;
});

const getPayload = defaultMemoize((pageSize, selectedFilters, nextPageToken) => {
  let payload = {
    rows: pageSize,
    countRequired: true,
    sortList: [
      {
        field: COLUMN_IDS.CREATED_TIME,
        order: 'DESC',
      },
    ],
  };

  let filters = EMPTY_ARRAY;

  if (!_isEmpty(selectedFilters)) {
    filters = [...createFiltersObject(selectedFilters)];
  }

  if (!_isEmpty(filters)) {
    payload = {
      filters,
      ...payload,
    };
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      nextPageToken,
      ...payload,
    };
  }
  //  else {
  //   payload = {
  //     sortList: [
  //       {
  //         field: COLUMN_IDS.CREATED_TIME,
  //         order: 'DESC',
  //       },
  //     ],
  //     ...payload,
  //   };
  // }

  return payload;
});

export { getTableProps, createFiltersObject, getPayload };
