const COLUMN_IDS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  FIELD_TYPE: 'fieldType',
  DATA_TYPE: 'dataType',
  MANDATORY: 'mandatory',
  LOOKUP_NAME: 'lookupName',
  LOOKUP_FIELD: 'lookupField',
  // COMPLEX_FIELD_DEFINITION: 'complexFieldDefinition',
  OPTIONS: 'options',
};

export default COLUMN_IDS;
