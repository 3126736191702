import _isEmpty from 'lodash/isEmpty';

import { SHARE_AND_MANAGE_RECORD_MODAL_MODE } from '../../constants/shareAndManageRecordModal.constants';

const deleteSharedRecordDataOnMode = async (mode, sharedRecordUserStateData, deleteSharedRecordForUser) => {
  let isSuccess = true;

  if (mode === SHARE_AND_MANAGE_RECORD_MODAL_MODE.MANAGE_RECORD) {
    const response = await deleteSharedRecordForUser(sharedRecordUserStateData);

    isSuccess = !_isEmpty(response);
  }

  return { isSuccess };
};

export { deleteSharedRecordDataOnMode };
