import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Button from '@tekion/tekion-components/atoms/Button';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import EntityViewFormField from './components/EntityViewFormField';
import { ACTION_TYPES } from '../../constants/createEntityView.constants';
import { ENTITY_VIEW_FORM_FIELD_IDS } from './constants/entityViewForm.general.constants';

import styles from '../../createEntityView.module.scss';

const EntityViewForm = ({ entityName, viewType, recordTypeOptions, viewRecordTypeMetadataList, error, onAction }) => {
  const handleAction = useCallback(
    (row) => (action) => {
      const actionType = tget(action, 'type', EMPTY_STRING);
      switch (actionType) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const payload = tget(action, 'payload', EMPTY_OBJECT);
          const id = _get(payload, 'id');
          const value = getArraySafeValue(_get(payload, 'value'));

          if (id === ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME && value !== getArraySafeValue(tget(viewRecordTypeMetadataList, [row, id]))) {
            onAction({
              type: ACTION_TYPES.ON_ENTITY_FORM_CHANGE,
              payload: { id: ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME, value: '', row },
            });
          }

          onAction({
            type: ACTION_TYPES.ON_ENTITY_FORM_CHANGE,
            payload: { ...payload, row },
          });

          break;
        }
        default:
          onAction(action);
      }
    },
    [onAction, viewRecordTypeMetadataList],
  );

  const handleAddRow = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_ADD_ROW,
    });
  }, [onAction]);

  const handleDeleteRow = useCallback(
    (row) => () => {
      onAction({
        type: ACTION_TYPES.ON_REMOVE_ROW,
        payload: { row },
      });
    },
    [onAction],
  );

  const renderEntityViewFormList = useCallback(
    () =>
      _map(viewRecordTypeMetadataList, (viewRecordTypeMetadata, index) => (
        <EntityViewFormField
          key={_get(viewRecordTypeMetadata, ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME)}
          index={index}
          entityName={entityName}
          viewType={viewType}
          error={_get(error, index)}
          recordTypeOptions={recordTypeOptions}
          viewRecordTypeMetadataList={viewRecordTypeMetadataList}
          handleDeleteRow={handleDeleteRow(index)}
          onAction={handleAction(index)}
        />
      )),
    [viewRecordTypeMetadataList, entityName, viewType, recordTypeOptions, error, handleDeleteRow, handleAction],
  );

  return (
    <div className={styles.entityViewForm}>
      <Heading size={3} inline={false}>
        {__('Record Types to Views Mapping')}
      </Heading>
      <div className={styles.tableHeader}>
        <div className={styles.tableHeaderContent}>
          <div className={styles.tableHeading}>{__('Record Type')}</div>
          <div className={styles.tableHeading}>{__('View')}</div>
        </div>
      </div>
      {renderEntityViewFormList()}
      <div>
        <Button
          disabled={_size(recordTypeOptions) === _size(viewRecordTypeMetadataList)}
          className={styles.addOptionButton}
          view={Button.VIEW.TERTIARY}
          onClick={handleAddRow}
        >
          {__('Add Option')}
        </Button>
      </div>
    </div>
  );
};

EntityViewForm.propTypes = {
  entityName: PropTypes.string,
  viewType: PropTypes.string,
  recordTypeOptions: PropTypes.array,
  viewRecordTypeMetadataList: PropTypes.array,
  error: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

EntityViewForm.defaultProps = {
  entityName: undefined,
  viewType: undefined,
  recordTypeOptions: EMPTY_ARRAY,
  viewRecordTypeMetadataList: EMPTY_ARRAY,
  error: undefined,
};

export default React.memo(EntityViewForm);
