import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/ruleengine';

const fetchRules = () => ApiService.get(`${BASE_URL}/management/workflow?module=DEV_PLATFORM&status=PUBLISHED,DRAFT`);
const getRuleById = (workflowId) => ApiService.get(`${BASE_URL}/management/workflow/${workflowId}`);
const createRule = (payload) => ApiService.post(`${BASE_URL}/management/workflow/publish`, payload);
const updateRule = (workflowId, payload) => ApiService.put(`${BASE_URL}/management/workflow/${workflowId}/publish`, payload);

const fetchRuleGlobalMetadata = () => ApiService.get(`${BASE_URL}/metadata/global`);
const fetchRuleTaskDefinitions = (eventTypes = 'RECORD_CREATED') =>
  ApiService.get(`${BASE_URL}/management/task?module=DEV_PLATFORM&&eventTypes=${eventTypes}`);

const activateRule = (workflowId, payload) => ApiService.put(`${BASE_URL}/management/workflow/${workflowId}/activate`, payload);

const deactivateRule = (workflowId, payload) => ApiService.put(`${BASE_URL}/management/workflow/${workflowId}/deactivate`, payload);

export default {
  fetchRules,
  getRuleById,
  createRule,
  updateRule,
  fetchRuleGlobalMetadata,
  fetchRuleTaskDefinitions,
  activateRule,
  deactivateRule,
};
