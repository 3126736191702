import { defaultMemoize } from 'reselect';
import _map from 'lodash/map';
import _get from 'lodash/get';

import { FIELD_IDS } from '../../../../constants/entityRecordGroup.constants';

const ROW_COUNT = 20;

const getHasMore = (nextPageToken) => nextPageToken !== null;

const getLoadingMessage = () => __('Searching...');

const getPayload = defaultMemoize((nextPageToken, searchText) => ({
  rows: ROW_COUNT,
  nextPageToken,
  searchText,
}));

const getRecordGroupOptions = defaultMemoize((recordGroups) =>
  _map(recordGroups, (group) => ({
    ...group,
    label: _get(group, FIELD_IDS.DISPLAY_NAME),
    value: _get(group, FIELD_IDS.RECORD_GROUP_NAME),
  })),
);

export { getHasMore, getPayload, getRecordGroupOptions, getLoadingMessage };
