import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _compact from 'lodash/compact';
import _castArray from 'lodash/castArray';
import _filter from 'lodash/filter';
import _parseInt from 'lodash/parseInt';
import _keyBy from 'lodash/keyBy';
import _uniq from 'lodash/uniq';

import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { fetchEntities, fetchEntityDefByName } from '../../../../../../actions/entityManagement.actions';

import { getFilters } from './preAppliedFiltersConfigurator.helpers';
import { getCompactedFiltersPayload } from '../../../../../../helpers/general.helpers';

import { ACTION_TYPES, FIELD_IDS } from './preAppliedFiltersConfigurator.constants';
import { COMPONENT_CONFIG_KEYS, ACTION_TYPES as VISUAL_BUILDER_ACTION_TYPES } from '../../../../constants/visualBuilder.general.constants';
import FIELD_TYPES from '../../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../../constants/fieldDefinition.dataTypes';
import entityReader from '../../../../../../readers/entity.reader';
import fieldDefinitionReader from '../../../../../../readers/fieldDefinition.reader';
import filterReader from '../../../../../../readers/filter.reader';

const getComplexAndRelationshipEntityPayload = (fieldDefinitions) => {
  const lookupEntityNames = _uniq(
    _compact(
      _map(fieldDefinitions, (field) => {
        const fieldType = fieldDefinitionReader.fieldType(field);
        const dataType = fieldDefinitionReader.dataType(field);
        if (fieldType === FIELD_TYPES.RELATIONSHIP) {
          return fieldDefinitionReader.lookupFieldEntityType(field);
        } else if (dataType === DATA_TYPES.COMPLEX) {
          return fieldDefinitionReader.complexFieldDefinitionEntityName(field);
        }
        return null;
      }),
    ),
  );

  const filters = [
    {
      field: 'name',
      values: [...lookupEntityNames],
      filterType: OPERATORS.IN,
    },
  ];
  return { filters: getCompactedFiltersPayload(filters) };
};

const getUpdatedFieldDefinitions = (fieldDefinitions, lookupEntities) => {
  const lookupEntityByName = _keyBy(lookupEntities, 'name');

  const updatedFieldDefinitions = _map(fieldDefinitions, (field) => {
    const fieldType = fieldDefinitionReader.fieldType(field);
    const dataType = fieldDefinitionReader.dataType(field);
    let lookUpEntity = '';
    if (dataType === DATA_TYPES.COMPLEX) {
      lookUpEntity = fieldDefinitionReader.complexFieldDefinitionEntityName(field);
    } else if (fieldType === FIELD_TYPES.RELATIONSHIP) {
      lookUpEntity = fieldDefinitionReader.lookupFieldEntityType(field);
    }

    const relationshipEntityFieldDefinitions = _get(lookupEntityByName, `${lookUpEntity}.fieldDefinitions`, EMPTY_ARRAY);

    if (dataType === DATA_TYPES.COMPLEX) {
      return {
        complexEntityFieldDefinitions: _keyBy(relationshipEntityFieldDefinitions, 'name'),
        ...field,
      };
    } else if (fieldType === FIELD_TYPES.RELATIONSHIP) {
      return {
        relationshipEntityFieldDefinitions: _keyBy(relationshipEntityFieldDefinitions, 'name'),
        ...field,
      };
    }
    return field;
  });

  return updatedFieldDefinitions;
};

const handleSelectedWidgetChange = async ({ params, getState, setState }) => {
  const { mapOfEntityDefByEntityName = {} } = getState();

  const selectedWidgetConfig = tget(params, 'selectedWidgetConfig', {});
  const selectedWidgetProperties = tget(selectedWidgetConfig, 'properties', {});
  const selectedWidgetEntityName = tget(selectedWidgetProperties, COMPONENT_CONFIG_KEYS.ENTITY_NAME);

  let entityDef = tget(mapOfEntityDefByEntityName, selectedWidgetEntityName);

  if (_isEmpty(entityDef)) {
    entityDef = await fetchEntityDefByName(selectedWidgetEntityName);

    const lookupEntityPayload = getComplexAndRelationshipEntityPayload(entityReader.fieldDefinitions(entityDef));

    const lookupEntities = await fetchEntities(lookupEntityPayload, true);

    _set(
      entityDef,
      'fieldDefinitions',
      getUpdatedFieldDefinitions(entityReader.fieldDefinitions(entityDef), tget(lookupEntities, 'hits', EMPTY_ARRAY)),
    );

    setState({ mapOfEntityDefByEntityName: { ...mapOfEntityDefByEntityName, [selectedWidgetEntityName]: entityDef } });
  }

  const fieldDefinitions = entityReader.fieldDefinitions(entityDef);
  const fieldTypeOptions = getFilters(fieldDefinitions);

  const preAppliedFilters = tget(selectedWidgetProperties, COMPONENT_CONFIG_KEYS.PRE_APPLIED_FILTERS, []);

  const fieldsConfig = {};
  let filterableFieldTypeOptions = [];

  filterableFieldTypeOptions = fieldTypeOptions;

  if (!_isEmpty(preAppliedFilters)) {
    _map(preAppliedFilters, (filterData) => {
      _set(fieldsConfig, [filterReader.field(filterData), FIELD_IDS.DISABLED], true);
    });
  }

  setState({
    fieldsConfig,
    fieldTypeOptions,
    filterableFieldTypeOptions,
    preAppliedFilters,
    errors: [],
  });
};

const handleCloseModal = ({ setState }) => {
  setState({ isModalVisible: false });
};

const handleSaveModal = ({ getState, setState }) => {
  const { selectedWidgetConfig, preAppliedFilters, onChangeWidgetConfigurationAction } = getState();

  const selectedWidgetConfigProperties = tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, {});
  const selectedWidgetName = tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME);

  onChangeWidgetConfigurationAction({
    type: VISUAL_BUILDER_ACTION_TYPES.UPDATE_WIDGET_CONFIGURATION,
    payload: {
      value: {
        widgetConfiguration: {
          ...selectedWidgetConfig,
          [COMPONENT_CONFIG_KEYS.PROPERTIES]: {
            ...selectedWidgetConfigProperties,
            [COMPONENT_CONFIG_KEYS.PRE_APPLIED_FILTERS]: preAppliedFilters,
          },
        },
        [COMPONENT_CONFIG_KEYS.WIDGET_NAME]: selectedWidgetName,
      },
    },
  });

  setState({ isModalVisible: false });
};

const handleAddRow = ({ getState, setState }) => {
  const { preAppliedFilters = [] } = getState();

  setState({ preAppliedFilters: [...preAppliedFilters, {}] });
};

const handleOnChange = ({ getState, setState, params }) => {
  const { fieldsConfig = {}, preAppliedFilters = [], errors = [] } = getState();

  const { id, value } = params;
  const index = tget(params, 'index');
  const newFieldsConfig = _cloneDeep(fieldsConfig);

  const newErrors = [...errors];

  const filterList = _cloneDeep(preAppliedFilters);
  const prevFilterRowData = _get(filterList, `${index}`, {});
  const prevErrorRowData = _get(errors, `${index}`, {});
  newErrors[index] = { ...prevErrorRowData, [id]: null };

  if (id === FIELD_IDS.FIELD) {
    // setting previous fieldname as disabled
    const prevFieldName = filterReader.field(prevFilterRowData);
    if (!_isEmpty(prevFieldName)) {
      _set(newFieldsConfig, [prevFieldName, FIELD_IDS.DISABLED], false);
    }
    filterList[index] = { [id]: value };
    _set(newFieldsConfig, [value, FIELD_IDS.DISABLED], true);
  } else if (id === FIELD_IDS.FILTER_TYPE) {
    filterList[index] = { ...prevFilterRowData, [id]: value };
  } else if (id === FIELD_IDS.VALUES) {
    let newValue = value;

    if (_has(value, 'value')) {
      newValue = tget(value, 'value');
    }

    filterList[index] = { ...prevFilterRowData, [id]: _compact(_castArray(newValue)) };
  }
  setState({
    preAppliedFilters: filterList,
    errors: [...newErrors],
  });

  setState({ fieldsConfig: newFieldsConfig });
};

const handleDeleteRow = ({ getState, setState, params }) => {
  const { index } = params;
  const { preAppliedFilters = [], fieldsConfig = {} } = getState();
  const newFieldsConfig = _cloneDeep(fieldsConfig);

  const fieldName = _get(preAppliedFilters, [index, FIELD_IDS.FIELD], '');
  if (!_isEmpty(fieldName)) {
    _set(newFieldsConfig, [fieldName, FIELD_IDS.DISABLED], false);
  }

  const filterList = _filter(preAppliedFilters, (filterRow, rowIndex) => _parseInt(rowIndex) !== _parseInt(index));

  setState({
    fieldsConfig: newFieldsConfig,
    preAppliedFilters: [...filterList],
  });
};

const handleOpenModal = ({ setState }) => {
  setState({ isModalVisible: true });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_SELECTED_WIDGET_COMPONENT_PROPERTIES_CHANGE]: handleSelectedWidgetChange,
  [ACTION_TYPES.ON_CANCEL_MODAL]: handleCloseModal,
  [ACTION_TYPES.ON_SAVE_MODAL]: handleSaveModal,
  [ACTION_TYPES.ADD_ROW]: handleAddRow,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnChange,
  [ACTION_TYPES.DELETE_ROW]: handleDeleteRow,
  [ACTION_TYPES.OPEN_MODAL]: handleOpenModal,
};

export default ACTION_HANDLERS;
