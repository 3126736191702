import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const TENANT_USER_API_ROUTES = {
  SEARCH_USER_DATA: 'core/u/universe/users/search',
  UNIVERSE_USER_DATA: 'core/u/universe/users',
};

const getTenantUserById = (id) => ApiService.get(`${TENANT_USER_API_ROUTES.UNIVERSE_USER_DATA}/${id}`);
const fetchTenantUserList = (payload = EMPTY_OBJECT) => ApiService.post(TENANT_USER_API_ROUTES.SEARCH_USER_DATA, payload);
const saveTenantUserData = (payload = EMPTY_OBJECT) => ApiService.post(TENANT_USER_API_ROUTES.UNIVERSE_USER_DATA, payload);
const editTenantUserData = (id, payload = EMPTY_OBJECT) => ApiService.put(`${TENANT_USER_API_ROUTES.UNIVERSE_USER_DATA}/${id}`, payload);
const deleteTenantUserById = (id) => ApiService.put(`${TENANT_USER_API_ROUTES.SEARCH_USER_DATA}/${id}`);

export default {
  deleteTenantUserById,
  fetchTenantUserList,
  saveTenantUserData,
  editTenantUserData,
  getTenantUserById,
};
