import ROUTES from '../../pages/devPlatform/constants/routes';
import PAGE_IDS from '../../pages/devPlatform/constants/PageIds.constants';

export const PROJECT_TREE_DATA = [
  {
    label: __('Projects'),
    key: 'projects',
    icon: 'icon-table2',
    goTo: ROUTES.PROJECT_LIST_ROUTE,
  },
];

export const APP_TREE_DATA = [
  {
    label: __('Apps'),
    key: 'my-apps',
    icon: 'icon-thumbnail-view',
    goTo: ROUTES.APPLICATION_LIST_ROUTE,
  },
];

export const ENTITY_TREE_DATA = [
  {
    label: __('Entity'),
    key: 'entity',
    icon: 'icon-group-by',
    goTo: ROUTES.ENTITY_LIST_ROUTE,
  },
];

export const GOVERNANCE_TREE_DATA = [
  {
    label: __('Governance'),
    key: 'governance',
    icon: 'icon-line-view',
    goTo: ROUTES.USER_GROUP_LIST_ROUTE,
    children: [
      { label: __('User Groups'), key: 'user-groups', goTo: ROUTES.USER_GROUP_LIST_ROUTE, icon: 'icon-team' },
      { label: __('Users'), key: 'users', goTo: ROUTES.USER_LIST_ROUTE, icon: 'icon-profile' },
    ],
  },
];

export const METADATA_TREE_DATA = [
  {
    label: __('Metadata management'),
    key: 'metadata',
    icon: 'icon-line-view',
    goTo: ROUTES.EXPORT_LIST_ROUTE,
    children: [
      { label: __('Export Metadata'), key: 'export', goTo: ROUTES.EXPORT_LIST_ROUTE, icon: 'icon-export' },
      { label: __('Import Metadata'), key: 'import', goTo: ROUTES.IMPORT_CREATE_ROUTE, icon: 'icon-import' },
    ],
  },
];

export const PERMISSION_TREE_DATA = [
  {
    label: __('Permission Sets'),
    key: 'permission',
    icon: 'icon-lock',
    goTo: ROUTES.PERMISSION_SET_LIST_ROUTE,
  },
];

export const REPORTING_TREE_DATA = [
  {
    label: __('Report Builder'),
    key: 'reporting',
    icon: 'icon-align-bottom',
    goTo: ROUTES.DASHBOARD_LIST_ROUTE,
  },
];

export const WORKFLOW_BUILDER_TREE_DATA = [
  {
    label: __('Workflow builder'),
    key: 'workflow',
    icon: 'icon-if-else',
    goTo: ROUTES.PROCESS_LIST_ROUTE,
  },
];

export const TENANT_WORKSPACE_TREE_DATA = [
  {
    label: __('Workspaces'),
    key: 'workspaces',
    icon: 'icon-nine-dots-menu',
    goTo: ROUTES.WORKSPACE_LIST_ROUTE,
  },
];

export const TENANT_GOVERNANCE_TREE_DATA = [
  {
    label: __('Governance'),
    key: 'tenant-governance',
    icon: 'icon-line-view',
    goTo: ROUTES.TENANT_UNIVERSE_USER_GROUP_LIST_ROUTE,
    children: [
      {
        label: __('User Groups'),
        key: 'user-groups',
        goTo: ROUTES.TENANT_UNIVERSE_USER_GROUP_LIST_ROUTE,
        icon: 'icon-team',
      },
      { label: __('Users'), key: 'users', goTo: ROUTES.TENANT_UNIVERSE_USER_LIST_ROUTE, icon: 'icon-profile' },
    ],
  },
];

export const TENANT_LOGIN_CONFIG_TREE_DATA = [
  {
    label: __('Login Configurations'),
    key: 'login-configurations',
    goTo: ROUTES.LOGIN_CONFIGURATIONS_ROUTE,
    icon: 'icon-customer-portal',
  },
];

export const ROLES_TREE_DATA = [
  {
    label: __('Roles'),
    key: 'roles',
    icon: 'icon-role',
    goTo: ROUTES.REPORTEE_ROUTE,
  },
];

export const RECORD_GROUPINGS_TREE_DATA = [
  { label: __('Record Groupings'), key: 'record-groupings', icon: 'icon-forms', goTo: ROUTES.ENTITY_RECORD_GROUP_LIST },
];

export const WWD_TREE_DATA = [
  {
    label: __('WWD'),
    key: 'wwd',
    icon: 'icon-export',
    goTo: ROUTES.WWD_PERMISSION_LIST_ROUTE,
  },
];
export const TEMPLATES_TREE_DATA = [
  {
    label: __('Notification templates'),
    key: 'templates',
    icon: 'icon-templates',
    goTo: ROUTES.TEMPLATE_BUILDER_LIST_ROUTE,
  },
];

export const JOB_SCHEDULER_TREE_DATA = [
  { label: __('Job Scheduler'), key: 'job-scheduler', icon: 'icon-task', goTo: ROUTES.JOB_SCHEDULE_LIST_ROUTE },
];

export const VISUAL_BUILDER_TREE_DATA = [
  { label: __('Visual Builder'), key: 'visual-builder', icon: 'icon-task', goTo: ROUTES.VISUAL_BUILDER_LIST_ROUTE },
];

export const APPROVAL_TREE_DATA = [
  {
    label: __('Approval Builder'),
    key: 'approval',
    icon: 'icon-checked-outlined',
    goTo: ROUTES.APPROVAL_PROCESSES_ROUTE,
  },
];

export const SERVER_CREDENTIALS_TREE_DATA = [
  {
    label: __('Server Credentials'),
    key: 'server-credential-configure',
    icon: 'icon-details',
    goTo: ROUTES.AUTH_PROVIDER_LIST_ROUTE,
    children: [
      { label: __('Auth'), key: 'auth-provider', goTo: ROUTES.AUTH_PROVIDER_LIST_ROUTE },
      { label: __('External'), key: 'external-credential', goTo: ROUTES.EXTERNAL_CREDENTIAL_LIST_ROUTE },
      { label: __('Server'), key: 'server-credential', goTo: ROUTES.SERVER_CREDENTIAL_LIST_ROUTE },
    ],
  },
];

export const SUBSCRIPTION_CONFIG_TREE_DATA = [
  {
    label: __('Subscription Configurations'),
    key: 'subscriptions',
    icon: 'icon-configuration',
    goTo: ROUTES.SUBSCRIPTION_CONFIG_LIST_ROUTE,
  },
];

export const GLOBAL_SELECT_LIST_TREE_DATA = [
  {
    label: __('Global Selects'),
    key: 'global-selects',
    icon: 'icon-thumbnail-view',
    goTo: ROUTES.GLOBAL_SELECT_LIST_ROUTE,
  },
];

export const IMPORT_DATA_TREE_DATA = [
  {
    label: __('Data Import'),
    key: 'import-data-studio',
    icon: 'icon-import-document',
    goTo: ROUTES.IMPORT_DATA_LIST_ROUTE,
  },
];

export const SIDEBAR_VISIBLE_PAGE_IDS = [
  PAGE_IDS.PROJECTS,
  PAGE_IDS.USERS,
  PAGE_IDS.USER_GROUPS,
  PAGE_IDS.APPLICATIONS,
  PAGE_IDS.ENTITIES,
  PAGE_IDS.FIELDS,
  PAGE_IDS.VALIDATION_RULE_BUILDER,
  PAGE_IDS.VIEW_BUILDER,
  PAGE_IDS.ACTION_BUILDER,
  PAGE_IDS.PERMISSION_SET,
  PAGE_IDS.ENTITY_RECORD_GROUP_LIST,
  PAGE_IDS.RECORD_SHARING_RULES,
  PAGE_IDS.WWD_PERMISSIONS,
  PAGE_IDS.DASHBOARDS,
  PAGE_IDS.TEMPLATE_BUILDER,
  PAGE_IDS.RECORD_TYPE,
  PAGE_IDS.JOBS,
  PAGE_IDS.VISUAL_BUILDER,
  PAGE_IDS.WORKFLOW_BUILDER,
  PAGE_IDS.APPROVAL_GROUPS,
  PAGE_IDS.APPROVAL_CATEGORIES,
  PAGE_IDS.APPROVAL_SETTINGS,
  PAGE_IDS.APPROVAL_SETTING_DETAIL,
  PAGE_IDS.APPROVAL_PROCESSES,
  PAGE_IDS.APPROVAL_PROCESS_EDIT,
  PAGE_IDS.EXPORTS,
  PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE,
  PAGE_IDS.SUBSCRIPTION_CONFIGS,
  PAGE_IDS.LOGIN_CONFIGURATIONS,
  PAGE_IDS.GLOBAL_SELECT_LIST,
  PAGE_IDS.WORKSPACES,
  IMPORT_DATA_TREE_DATA,
];
