const COLUMN_IDS = {
  JOB_SCHEDULE_NAME: 'name',
  DESCRIPTION: 'description',
  DISPLAY_NAME: 'displayName',
  STATUS: 'scheduledStatus',
  SCHEDULE_TYPE: 'scheduleData.type',
  TASK_TYPE: 'taskInfo.taskType',
};

export default COLUMN_IDS;
