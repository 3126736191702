import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import Heading from '@tekion/tekion-components/atoms/Heading';
import TextInput from '@tekion/tekion-components/molecules/TextInput';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Button from '@tekion/tekion-components/atoms/Button';
import Spinner from '@tekion/tmatter/lib/components/atoms/spinner';

import Comment from './components/comment/Comment';
import UserAvatar from '../../../../../../../atoms/userAvatar/UserAvatar';

import { COMMENT_FIELDS } from '../../../../../../../organisms/commentRenderer/constants/commentRenderer.constants';
import ACTION_TYPES from '../../constants/approvalRequestDetailsPage.actionTypes';

import localStorageReader from '../../../../../../../readers/localStorage.reader';
import tenantUserReader from '../../../../../../../readers/tenantUser.reader';

import styles from './approvalCommentsView.module.scss';

const ApprovalCommentsView = ({
  isCommentAdding,
  isShowMoreCommentsLoading,
  hasMoreComments,
  currentLoggedInUserData,
  approvalComments,
  onAction,
}) => {
  const [newCommentContent, setCommentContent] = useState('');
  const currentUser = !_isEmpty(currentLoggedInUserData) ? currentLoggedInUserData : localStorageReader.userInfo();
  const currentUserName = tenantUserReader.NAME(currentUser);
  const currentUserPicSrc = tenantUserReader.profilePicture(currentUser);

  const handleAddComment = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_ADD_COMMENT, payload: { newCommentContent } });
    setCommentContent('');
  }, [newCommentContent, onAction]);

  const handleChangeCommentField = useCallback((event) => {
    setCommentContent(event.target.value);
  }, []);

  const handleShowMoreComments = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_FETCH_COMMENTS });
  }, [onAction]);

  const renderCommentHeader = useCallback(
    () => (
      <Heading className="p-t-16 p-l-16" size={3}>
        {__('Comments')}
      </Heading>
    ),
    [],
  );

  return (
    <div className={styles.commentSection}>
      <div className={styles.commentDetailsSection}>
        {isShowMoreCommentsLoading ? (
          <div>
            {renderCommentHeader()}
            <div className={styles.spinnerWrap}>
              <Spinner size="large" />
            </div>
          </div>
        ) : (
          <>
            <div>
              {renderCommentHeader()}
              <div>
                {_map(approvalComments, (comment, commentIndex) => (
                  <Comment key={tget(comment, COMMENT_FIELDS.ID)} index={commentIndex} comment={comment} />
                ))}
              </div>
            </div>
            {hasMoreComments && (
              <div className={styles.showMoreButton}>
                <Button view={Button.VIEW.TERTIARY} onClick={handleShowMoreComments}>
                  {__('Show more')}
                </Button>
              </div>
            )}
          </>
        )}
      </div>

      <div className={styles.commentInbox}>
        <UserAvatar displayName={currentUserName} userAvatar={currentUserPicSrc} />
        <TextInput className={styles.commentContentContainer} value={newCommentContent} onChange={handleChangeCommentField} />
        {isCommentAdding ? (
          <div className={styles.spinnerWrap}>
            <Spinner size="medium" />
          </div>
        ) : (
          <FontIcon
            loading={isCommentAdding}
            disabled={_isEmpty(newCommentContent)}
            className="cursor-pointer"
            size={SIZES.XXL}
            onClick={handleAddComment}
          >
            icon-send-arrow
          </FontIcon>
        )}
      </div>
    </div>
  );
};

ApprovalCommentsView.propTypes = {
  isCommentAdding: PropTypes.bool,
  isShowMoreCommentsLoading: PropTypes.bool,
  hasMoreComments: PropTypes.bool,
  currentLoggedInUserData: PropTypes.object,
  approvalComments: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ApprovalCommentsView.defaultProps = {
  isCommentAdding: false,
  isShowMoreCommentsLoading: false,
  hasMoreComments: true,
  currentLoggedInUserData: EMPTY_OBJECT,
  approvalComments: EMPTY_ARRAY,
};

export default ApprovalCommentsView;
