import React, { useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _get from 'lodash/get';
import _divide from 'lodash/divide';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { Col, Row } from '@tekion/tekion-components/molecules/Grid';
import NullComponent from '@tekion/tekion-components/atoms/nullComponent';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';

import ViewRendererContext from '../../../../../context/viewRenderer.context';
import ViewViewer from '../../../organisms/viewViewer/ViewViewer';

import { VIEW_CONTEXT_KEYS } from '../../../constants/viewBuilder.constants';
import { VIEW_CONFIGURATION_GENERAL_KEYS, ALL_VIEW_PROPERTY_KEYS } from '../../../../../constants/viewBuilder.constants';

import styles from './gridViewRenderer.module.scss';

const Card = ({
  dataIndex,
  cardFields,
  columnCount,
  defaultCardViewConfigFromGridView,
  cellViewConfigurationsByName,
  cardViewConfiguration,
  entity,
  record,
  cardCustomStyles,
  actionColumn,
}) => {
  const viewDetailsContextValue = useContext(ViewRendererContext);
  const applicationContext = tget(viewDetailsContextValue, VIEW_CONTEXT_KEYS.APPLICATION_CONTEXT, EMPTY_OBJECT);

  const widgetName = tget(viewDetailsContextValue, `${VIEW_CONTEXT_KEYS.WIDGET_CONTEXT}.widgetName`, EMPTY_STRING);

  const columnWidth = _divide(100, columnCount);
  const margin = _divide(columnWidth, 50);
  const padding = _divide(columnWidth, 50);
  const ActionColumnRenderer = useMemo(() => _get(actionColumn, 'Cell', NullComponent), [actionColumn]);

  const renderViewByCardViewConfiguration = useCallback(
    () => (
      <ViewViewer
        isPreviewMode
        widgetName={widgetName}
        viewConfigurationsByName={cellViewConfigurationsByName}
        viewConfiguration={cardViewConfiguration}
        entity={entity}
        entityRecord={record}
        applicationContext={applicationContext}
      />
    ),
    [widgetName, cellViewConfigurationsByName, cardViewConfiguration, entity, record, applicationContext],
  );

  return (
    <div className={styles.card} style={{ width: `${columnWidth - 2 * margin}%`, margin: `${margin}%`, padding: `${padding}%`, ...cardCustomStyles }}>
      <div style={{ width: '100%' }}>
        {!_isEmpty(cardViewConfiguration)
          ? renderViewByCardViewConfiguration()
          : _map(cardFields, (cardAttribute, index) => {
              const attributeConfig = defaultCardViewConfigFromGridView[index];
              const properties = _get(attributeConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);
              const keySpan = _get(properties, ALL_VIEW_PROPERTY_KEYS.KEY_SPAN);
              const valueSpan = _get(properties, ALL_VIEW_PROPERTY_KEYS.VALUE_SPAN);
              const isKeyVisible = _get(properties, ALL_VIEW_PROPERTY_KEYS.IS_KEY_VISIBLE, true);
              const sectionId = _get(attributeConfig, 'sectionId');
              const displayName = _get(cardAttribute, 'displayName', EMPTY_STRING);
              const accessor = _get(cardAttribute, 'accessor');
              const fieldValue = accessor(record);

              return (
                <Row key={sectionId} style={{ width: '100%' }} className={styles.cardAttributeClass}>
                  <PropertyControlledComponent controllerProperty={isKeyVisible}>
                    <Col span={keySpan} className={styles.keyContainer}>
                      <div className={styles.key}>
                        <div className={styles.text}>{__(displayName)}</div>
                      </div>
                    </Col>
                    <Col span={1} className={styles.delimiter}>
                      :
                    </Col>
                  </PropertyControlledComponent>
                  <Col span={valueSpan} className={styles.valueContainer}>
                    <div className={styles.value}>{__(fieldValue)}</div>
                  </Col>
                </Row>
              );
            })}
      </div>
      {!_isEmpty(actionColumn) && (
        <ActionColumnRenderer
          {..._get(actionColumn, 'getProps', _noop)()}
          index={dataIndex}
          className={styles.cardActionClass}
          original={record}
          value={_get(record, _get(actionColumn, 'accessor'), EMPTY_ARRAY)}
        />
      )}
    </div>
  );
};

Card.propTypes = {
  dataIndex: PropTypes.number.isRequired,
  cardFields: PropTypes.array,
  cellViewConfigurationsByName: PropTypes.object,
  defaultCardViewConfigFromGridView: PropTypes.object,
  cardViewConfiguration: PropTypes.object,
  entity: PropTypes.object,
  record: PropTypes.object,
  actionColumn: PropTypes.object,
  cardCustomStyles: PropTypes.object,
  columnCount: PropTypes.number,
};

Card.defaultProps = {
  cardFields: EMPTY_ARRAY,
  defaultCardViewConfigFromGridView: EMPTY_OBJECT,
  cellViewConfigurationsByName: EMPTY_OBJECT,
  record: EMPTY_OBJECT,
  cardViewConfiguration: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  actionColumn: EMPTY_OBJECT,
  cardCustomStyles: EMPTY_OBJECT,
  columnCount: 1,
};

export default Card;
