import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';
import _set from 'lodash/set';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

// Components
import Loader from '@tekion/tekion-components/molecules/loader';
import ViewViewer from '../../../../../../../organisms/viewBuilder/organisms/viewViewer/ViewViewer';

// Actions
import { getApprovalSettingForGroupCategory } from '../../../../../../../actions/approvalManagement.actions';

// Helpers
import { getFormViewConfigurationFromFields } from './settingFieldsForm.helpers';

// Constants
import { APPROVAL_CENTRE_FIELD_IDS, CUSTOM_ENTITY_CATEGORY } from '../../../../../../../constants/approvalCentre.constants';
import FIELD_IDS from '../../constants/approvalRequestForm.fieldIds';

const SettingFieldsForm = ({ contextId, existingRequestData, formValues, onFormValueChange, onFormFieldBlur, onFormSubmit }) => {
  const [isApprovalSettingLoading, setIsApprovalSettingLoading] = useState(false);
  const [isApprovalSettingLoaded, setIsApprovalSettingLoaded] = useState(false);
  const [approvalSetting, setApprovalSetting] = useState(EMPTY_OBJECT);

  const group = getArraySafeValue(tget(formValues, FIELD_IDS.GROUP, []));
  const category = getArraySafeValue(tget(formValues, FIELD_IDS.CATEGORY, []));

  const entityDefForView = useMemo(() => {
    const fields = tget(approvalSetting, APPROVAL_CENTRE_FIELD_IDS.FIELDS);

    return { ...approvalSetting, name: 'approvalSetting', fieldDefinitions: fields };
  }, [approvalSetting]);

  const viewConfiguration = useMemo(() => getFormViewConfigurationFromFields(approvalSetting), [approvalSetting]);

  const initialFormValues = useMemo(() => {
    const entityRecordValue = _cloneDeep(existingRequestData);
    _set(entityRecordValue, 'entity', tget(entityRecordValue, 'data', EMPTY_OBJECT));

    return entityRecordValue;
  }, [existingRequestData]);

  const loadApprovalSetting = useCallback(async (_group, _category) => {
    setIsApprovalSettingLoaded(false);
    setIsApprovalSettingLoading(true);

    const _approvalSetting = await getApprovalSettingForGroupCategory({ group: _group, category: _category });

    setApprovalSetting(_approvalSetting);
    setIsApprovalSettingLoaded(true);
    setIsApprovalSettingLoading(false);
  }, []);

  useEffect(() => {
    if (!_isEmpty(group) && !_isEmpty(category)) {
      if (category !== CUSTOM_ENTITY_CATEGORY) {
        loadApprovalSetting(group, category);
      }
    }
  }, [group, category, loadApprovalSetting]);

  if (isApprovalSettingLoading) {
    return <Loader id={contextId} />;
  }

  if (!isApprovalSettingLoaded) {
    return null;
  }

  return (
    <div>
      <ViewViewer
        isPreviewMode
        contextId={contextId}
        entity={entityDefForView}
        viewConfiguration={viewConfiguration}
        entityRecord={initialFormValues}
        onFormValueChange={onFormValueChange}
        onFormFieldBlur={onFormFieldBlur}
        onFormSubmit={onFormSubmit}
      />
    </div>
  );
};

SettingFieldsForm.propTypes = {
  contextId: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  existingRequestData: PropTypes.object,
  onFormValueChange: PropTypes.func,
  onFormFieldBlur: PropTypes.func,
  onFormSubmit: PropTypes.func,
};

SettingFieldsForm.defaultProps = {
  existingRequestData: EMPTY_OBJECT,
  onFormValueChange: _noop,
  onFormFieldBlur: _noop,
  onFormSubmit: _noop,
};

export default SettingFieldsForm;
