import ACTION_TYPES from './appList.actionTypes';

const EDIT_ACTION = {
  id: ACTION_TYPES.EDIT,
  name: __('Edit'),
};

const ENTITY_MAPPINGS = {
  id: ACTION_TYPES.ON_ENTITY_MAPPINGS_CLICK,
  name: __('Entity mappings'),
};

export { EDIT_ACTION, ENTITY_MAPPINGS };
