const BUTTON_VIEW_TYPE_OPTIONS = [
  { label: __('Primary'), value: __('PRIMARY') },
  { label: __('Secondary'), value: __('SECONDARY') },
  { label: __('Tertiary'), value: __('TERTIARY') },
  { label: __('Icon'), value: __('ICON') },
  { label: __('Link'), value: __('LINK') },
  { label: __('Custom'), value: __('CUSTOM') },
];

export { BUTTON_VIEW_TYPE_OPTIONS };
