import { CUSTOM_STYLE_TYPES, HELPER_RICH_TEXT_EDITOR_INITIAL_VALUE_FOR_CUSTOM_STYLES_OBJECT } from '../../../../../constants/customStyles.constants';

const FIELD_IDS = {
  CUSTOM_STYLES: 'customStyles',
  TYPE: 'type',
  CUSTOM_STYLE_TYPE: 'customStyleType',
  CODE_PANEL: 'codePanel',
  VALUE: 'value',
  METADATA: 'metadata',
  PROPERTIES: 'properties',
  SCRIPT: 'script',
  BEAUTIFY_BUTTON: 'beautifyButton',
  ERROR_MESSAGE: 'ERROR_MESSAGE',
};

const ROWS = 20;

const INITIAL_CUSTOM_STYLES_CONFIGURATION = {
  [FIELD_IDS.TYPE]: CUSTOM_STYLE_TYPES.STYLES_OBJECT,
  [FIELD_IDS.VALUE]: HELPER_RICH_TEXT_EDITOR_INITIAL_VALUE_FOR_CUSTOM_STYLES_OBJECT,
};

const CUSTOM_STYLES_CONFIGURATOR_FORM_CONTEXT_ID = 'CUSTOM_STYLES_CONFIGURATOR_FORM';

const CUSTOM_STYLES_FIELD_IDS = [FIELD_IDS.TYPE, FIELD_IDS.CUSTOM_STYLE_TYPE, FIELD_IDS.VALUE, FIELD_IDS.METADATA];

export { FIELD_IDS, CUSTOM_STYLES_CONFIGURATOR_FORM_CONTEXT_ID, CUSTOM_STYLES_FIELD_IDS, INITIAL_CUSTOM_STYLES_CONFIGURATION, ROWS };
