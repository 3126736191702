import { FIELD_IDS } from './permissionSetForm.fields';

const PERMISSION_SET_FORM_SECTIONS = [
  {
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
      },
      {
        columns: [FIELD_IDS.DESCRIPTION],
      },
    ],
  },
];

export default PERMISSION_SET_FORM_SECTIONS;
