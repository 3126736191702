import _map from 'lodash/map';

import { SCRIPT_TYPES } from '../../constants/event.fieldIds';
import { EVENT_ACTIONS, EVENT_ACTION_NAME_TO_LABEL } from '../../constants/eventActions.constants';

const FIELD_IDS = {
  EVENT_HANDLERS: 'eventHandlers',
  EVENT_TYPE: 'eventType',
  CODE_PANEL: 'codePanel',
  SCRIPT: 'script',
  ACTION_TO_EXECUTE: 'actionToExecute',
  PAGE_NAME_TO_NAVIGATE: 'pageNameToNavigate',
  SUB_EVENT_TYPE: 'subEventType',
  GENERATOR_TYPE: 'generatorType',
  METADATA: 'metadata',
  PROPERTIES: 'properties',
  ACTION_ID_TO_EXECUTE: 'actionIdToExecute',
  NAVIGATE_TO_PREV_PAGE: 'navigateToPrevPage',
  ACTION_NAME_TO_EXECUTE: 'actionNameToExecute',
  ENTITY_NAME: 'entityName',
};

const ROWS = 20;

const EVENT_HANDLER_PROPERTIES_FIELD_IDS = [FIELD_IDS.ACTION_TO_EXECUTE, FIELD_IDS.SUB_EVENT_TYPE, FIELD_IDS.SCRIPT, FIELD_IDS.METADATA];

const EVENT_HANDLER_FIELD_IDS = [FIELD_IDS.EVENT_TYPE, FIELD_IDS.PROPERTIES];

const GENERATOR_TYPE_OPTIONS = [
  { label: __('Async'), value: SCRIPT_TYPES.ASYNC },
  { label: __('Sync'), value: SCRIPT_TYPES.SYNC },
];

const ACTION_TO_EXECUTE_OPTIONS = _map(EVENT_ACTIONS, (value) => ({ label: EVENT_ACTION_NAME_TO_LABEL[value], value }));

const FIELD_IDS_TO_PICK_WHEN_CODE_PANEL_IS_ENABLED = [
  FIELD_IDS.EVENT_TYPE,
  FIELD_IDS.CODE_PANEL,
  FIELD_IDS.SCRIPT,
  FIELD_IDS.SUB_EVENT_TYPE,
  FIELD_IDS.GENERATOR_TYPE,
];

const COMMON_FIELD_IDS_TO_PICK_WHEN_CODE_PANEL_IS_DISABLED = [FIELD_IDS.EVENT_TYPE, FIELD_IDS.SUB_EVENT_TYPE, FIELD_IDS.ACTION_TO_EXECUTE];

const MANDATORY_FIELD_IDS = [
  FIELD_IDS.EVENT_TYPE,
  FIELD_IDS.SCRIPT,
  FIELD_IDS.ACTION_TO_EXECUTE,
  FIELD_IDS.PAGE_NAME_TO_NAVIGATE,
  FIELD_IDS.ACTION_NAME_TO_EXECUTE,
  FIELD_IDS.GENERATOR_TYPE,
];

export {
  FIELD_IDS,
  ROWS,
  EVENT_HANDLER_PROPERTIES_FIELD_IDS,
  EVENT_HANDLER_FIELD_IDS,
  GENERATOR_TYPE_OPTIONS,
  ACTION_TO_EXECUTE_OPTIONS,
  FIELD_IDS_TO_PICK_WHEN_CODE_PANEL_IS_ENABLED,
  COMMON_FIELD_IDS_TO_PICK_WHEN_CODE_PANEL_IS_DISABLED,
  MANDATORY_FIELD_IDS,
};
