const ACTION_TYPES = {
  CREATE_PERMISSION: 'CREATE_PERMISSION',
  ON_FETCH_PERMISSION_DATA: 'ON_FETCH_PERMISSION_DATA',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  DELETE_PERMISSION_CONFIRMATION_DONE_CLICK: 'DELETE_PERMISSION_CONFIRMATION_DONE_CLICK',
  DELETE_PERMISSION_CONFIRMATION_CANCEL_CLICK: 'DELETE_PERMISSION_CONFIRMATION_CANCEL_CLICK',
};

export default ACTION_TYPES;
