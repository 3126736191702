import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactGridLayout from 'react-grid-layout';

import _noop from 'lodash/noop';

// Constants
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { TOTAL_GRID_COLUMNS, DRAGGABLE_CANCEL_CLASS_NAME } from './gridLayout.constants';

// Styles
import 'react-grid-layout/css/styles.css';
import './gridLayout.module.scss';
import { getLayouts } from './gridLayout.helpers';

const GridLayout = (props) => {
  const { width, children, layouts, onLayoutChange } = props;

  const updatedLayout = useMemo(() => getLayouts(layouts), [layouts]);

  return (
    <ReactGridLayout
      width={width}
      layout={updatedLayout}
      rowHeight={300}
      className="layout"
      minW={5}
      minH={2}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={TOTAL_GRID_COLUMNS}
      draggableCancel={`.${DRAGGABLE_CANCEL_CLASS_NAME}`}
      onLayoutChange={onLayoutChange}
      isDraggable
      isResizable
    >
      {children}
    </ReactGridLayout>
  );
};

GridLayout.propTypes = {
  children: PropTypes.elementType,
  width: PropTypes.number,
  layouts: PropTypes.arrayOf(
    PropTypes.shape({
      i: PropTypes.string,
      x: PropTypes.number,
      y: PropTypes.number,
      w: PropTypes.number,
      h: PropTypes.number,
    }),
  ),
  onLayoutChange: PropTypes.func,
};

GridLayout.defaultProps = {
  width: 0,
  layouts: EMPTY_ARRAY,
  children: null,
  onLayoutChange: _noop,
};

export default GridLayout;
