import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import { SIZES } from '@tekion/tekion-components/atoms/FontIcon/Icon.constants';

const EntitySettingsIconRenderer = ({ data }) => {
  const icon = useMemo(() => (data ? 'icon-tick1' : 'icon-cross'), [data]);
  return (
    <FontIcon className="p-l-4 inline" size={SIZES.MD}>
      {icon}
    </FontIcon>
  );
};

EntitySettingsIconRenderer.propTypes = {
  data: PropTypes.bool,
};

EntitySettingsIconRenderer.defaultProps = {
  data: false,
};

export default React.memo(EntitySettingsIconRenderer);
