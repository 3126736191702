const WORKFLOW_BUILDER_ACTION_TYPES = {
  ON_BUILDER_INIT: 'ON_BUILDER_INIT',
  ON_STEP_ITEM_CLICK: 'ON_STEP_ITEM_CLICK',
  ON_TRIGGER_ITEM_CLICK: 'ON_TRIGGER_ITEM_CLICK',
  ON_ENTITY_CHANGE: 'ON_ENTITY_CHANGE',
  ON_ADD_NODE: 'ON_ADD_NODE',
  ON_NODE_DND: 'ON_NODE_DND',
  ON_NODE_MENU_CLICK: 'ON_NODE_MENU_CLICK',
  ON_CANCEL_CLICK: 'ON_CANCEL_CLICK',
  ON_MODAL_SUBMIT: 'ON_MODAL_SUBMIT',
  ON_MODAL_CANCEL: 'ON_MODAL_CANCEL',
  ON_WORKFLOW_SUBMIT: 'ON_WORKFLOW_SUBMIT',
  CLOSE_TRIGGER_DRAWER: 'CLOSE_TRIGGER_DRAWER',
  CLOSE_STEP_DRAWER: 'CLOSE_STEP_DRAWER',
  NODE_UPDATE: 'NODE_UPDATE',
  ON_NODE_DROP_REPOSITION: 'ON_NODE_DROP_REPOSITION',
  ON_NODE_DUPLICATE: 'ON_NODE_DUPLICATE',
  ON_UPDATE_MAP_OF_SCOPED_VARIABLES_BY_NODE: 'ON_UPDATE_MAP_OF_SCOPED_VARIABLES_BY_NODE',
  ON_UNDO_REDO_WORKFLOW_UPDATES: 'ON_UNDO_REDO_WORKFLOW_UPDATES',
  ON_NODE_EXPAND: 'ON_NODE_EXPAND',
};

export default WORKFLOW_BUILDER_ACTION_TYPES;
