import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';
import { IMAGE_RADIO_BUTTON_VALUES } from '../../organisms/propertiesForm/propertiesForm.constants';

const FIELD_IDS = {
  IMAGE_RADIO_BUTTON: ALL_VIEW_PROPERTY_KEYS.IMAGE_RADIO_BUTTON,
  IMAGE_PATH: ALL_VIEW_PROPERTY_KEYS.IMAGE_PATH,
  GRID: ALL_VIEW_PROPERTY_KEYS.GRID,
  CUSTOM_STYLES: ALL_VIEW_PROPERTY_KEYS.CUSTOM_STYLES,
};

const IMAGE_RADIO_OPTIONS = [
  {
    label: __('Image'),
    value: IMAGE_RADIO_BUTTON_VALUES.IMAGE,
  },
  {
    label: __('Icon'),
    value: IMAGE_RADIO_BUTTON_VALUES.ICON,
  },
];

export { FIELD_IDS, IMAGE_RADIO_OPTIONS };
