import COLORS from '@tekion/tekion-styles-next/scss/variables.scss';

import CELL_BACKGROUND_COLORS from './styles/useColorPalette.colors.scss';

export const BACKGROUND_COLORS = [
  COLORS.white,
  CELL_BACKGROUND_COLORS.denim10,
  CELL_BACKGROUND_COLORS.tekionGreen10,
  CELL_BACKGROUND_COLORS.electricViolet10,
  CELL_BACKGROUND_COLORS.buttercupYellow10,
  CELL_BACKGROUND_COLORS.gold10,
  COLORS.glitter,
  CELL_BACKGROUND_COLORS.denim30,
  CELL_BACKGROUND_COLORS.tekionGreen30,
  CELL_BACKGROUND_COLORS.electricViolet30,
  CELL_BACKGROUND_COLORS.buttercupYellow30,
  CELL_BACKGROUND_COLORS.gold30,
  CELL_BACKGROUND_COLORS.atomic50,
  CELL_BACKGROUND_COLORS.denim50,
  CELL_BACKGROUND_COLORS.tekionGreen50,
  CELL_BACKGROUND_COLORS.electricViolet50,
  CELL_BACKGROUND_COLORS.buttercupYellow50,
  CELL_BACKGROUND_COLORS.gold50,
];
