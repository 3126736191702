/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
import _get from 'lodash/get';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _uniq from 'lodash/uniq';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import _unset from 'lodash/unset';
import _forEach from 'lodash/forEach';
import _cloneDeep from 'lodash/cloneDeep';
import _castArray from 'lodash/castArray';
import _omit from 'lodash/omit';

import { getFormattedDateTime, DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';
import { EMPTY_ARRAY, NO_DATA, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

import { savePageConfiguration, saveWidgetConfiguration, searchPageConfigurations } from '../../../../../actions/visualBuilder.actions';
import { getPayload, getPayloadForUserIds } from './visualBuilderList.helpers';
import { getWorkspaceUserList } from '../../../../../actions/workspaceUserManagement.actions';
import { DEFAULT_FILTER_GROUP } from '../constants/visualBuilderList.general';
import { CLONE_PAGE_MODAL_FORM_IDS } from './visualBuilderCloneModal.formConfig';
import { STUDIO_ROUTE } from '../../../../../constants/routes';
import PAGE_IDS from '../../../constants/PageIds.constants';
import COLUMN_IDS from '../constants/visualBuilderList.columnIds';
import ACTION_TYPES from '../constants/visualBuilderList.actionTypes';

const fetchUsers = async (ids) => {
  if (!_isEmpty(ids)) {
    const payload = getPayloadForUserIds(ids);
    const response = await getWorkspaceUserList(payload);
    const users = tget(response, 'hits', EMPTY_ARRAY);
    const userData = _keyBy(users, 'id');
    return userData;
  }
  return EMPTY_OBJECT;
};

const refineData = async (data) => {
  const ids = _uniq(_map(data, (value) => _get(value, COLUMN_IDS.MODIFIED_BY)));
  const userData = await fetchUsers(ids);
  return _map(data, (value) => ({
    ...value,
    [COLUMN_IDS.MODIFIED_BY]: `${_get(userData[_get(value, COLUMN_IDS.MODIFIED_BY)], 'entity.displayname', NO_DATA)}, ${getFormattedDateTime(
      _get(value, 'modifiedTime', ''),
      DATE_TIME_FORMATS.ABBREVIATED_DATE_ALPHA_MONTH,
    )}`,
  }));
};

const handleTableItemsFetch = async ({ params, getState, setState }) => {
  const { pageSize, searchText, selectedFilters } = getState();

  const currentPageToken = _get(params, 'nextPageToken');
  const payload = getPayload(searchText, COLUMN_IDS.PAGE_NAME, pageSize, selectedFilters, currentPageToken);

  setState({ isLoading: true });

  const data = await searchPageConfigurations(payload);
  const resolvedData = await refineData(tget(data, 'hits', EMPTY_ARRAY));
  setState({
    data: resolvedData,
    isLoading: false,
    totalCount: _size(resolvedData),
    nextPageToken: _get(data, 'nextPageToken', ''),
  });
};

const handleCreatePage = ({ getState }) => {
  const { history } = getState();
  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.VISUAL_BUILDER_CREATE}`);
};

const handlePageItemClick = ({ params, getState }) => {
  const { history } = getState();
  const pageConfig = _get(params, 'value.original', EMPTY_OBJECT);
  const pageName = _get(pageConfig, 'name');

  history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_IDS.VISUAL_BUILDER_EDIT}/${pageName}`, state: { pageConfig } });
};

const handleSetFilters = async ({ params = EMPTY_OBJECT, getState, setState }) => {
  const filters = _get(params, 'value', EMPTY_ARRAY);
  const selectedFilterGroup = _get(params, 'selectedFilterGroup', DEFAULT_FILTER_GROUP);

  setState(
    {
      selectedFilters: filters,
      currentPage: 0,
      selectedFilterGroup,
    },
    () => {
      handleTableItemsFetch({ getState, setState, params: { nextPageToken: EMPTY_STRING } });
    },
  );
};

const handlePageSizeUpdate = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');

  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }
  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleTableItemsFetch({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const handleSearchTextUpdate = ({ setState, params = EMPTY_OBJECT, getState }) => {
  setState(
    {
      searchText: _get(params, 'value'),
      currentPage: 0,
    },
    () => {
      handleTableItemsFetch({ getState, setState, params: { nextPageToken: EMPTY_STRING } });
    },
  );
};

const handleEditClick = ({ getState, params = EMPTY_OBJECT }) => {
  const { id = EMPTY_STRING } = params;
  const { history } = getState();
  history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_IDS.VISUAL_BUILDER_EDIT}/${id}`, state: { pageConfig: params } });
};

const handleClonePageModalOpen = ({ params, setState }) => {
  const { name = EMPTY_STRING, description = EMPTY_STRING } = params;
  const clonedDefaultPageName = `clone_of_${name}`;
  const clonedDefaultDescription = `Clone of ${description}`;
  setState({
    isClonePageModalVisible: true,
    rowActionClickedItemPageConfiguration: params,
    clonePageModalFormData: {
      [CLONE_PAGE_MODAL_FORM_IDS.PAGE_NAME]: clonedDefaultPageName,
      [CLONE_PAGE_MODAL_FORM_IDS.DESCRIPTION]: clonedDefaultDescription,
    },
  });
};

const handleClonePageModalClose = ({ setState }) => {
  setState({ isClonePageModalVisible: false, clonePageModalFormData: {}, rowActionClickedItemPageConfiguration: {} });
};

const handleClonePage = async ({ getState, setState }) => {
  const { rowActionClickedItemPageConfiguration = EMPTY_OBJECT, clonePageModalFormData = EMPTY_OBJECT } = getState();
  setState({ isClonePageModalDataSubmitting: true });
  let clonedPageConfiguration = { ...rowActionClickedItemPageConfiguration, ...clonePageModalFormData };

  clonedPageConfiguration = { ..._omit(clonedPageConfiguration, ['id']) };
  const response = await savePageConfiguration(clonedPageConfiguration);
  if (response === false) {
    setState({
      isClonePageModalDataSubmitting: false,
      isClonePageModalVisible: false,
      clonePageModalFormData: {},
      rowActionClickedItemPageConfiguration: {},
    });
  } else {
    setState(
      {
        isClonePageModalDataSubmitting: false,
        isClonePageModalVisible: false,
        clonePageModalFormData: {},
        rowActionClickedItemPageConfiguration: {},
      },
      () => handleTableItemsFetch({ getState, setState }),
    );
  }
};

const handleErrors = ({ params, setState }) => {
  const { errors } = params;
  setState({ clonePageModalFormErrors: errors });
};

const handleClonePageModalFieldChange = ({ params, getState, setState }) => {
  const { id, value } = params;
  const { clonePageModalFormData } = getState();
  const newClonePageModalFormData = { ...clonePageModalFormData, [id]: value };
  setState({ clonePageModalFormData: newClonePageModalFormData });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleTableItemsFetch,
  [ACTION_TYPES.CREATE_VISUAL_BUILDER]: handleCreatePage,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handlePageItemClick,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleSetFilters,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageSizeUpdate,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchTextUpdate,
  [ACTION_TYPES.EDIT_VISUAL_BUILDER]: handleEditClick,
  [ACTION_TYPES.CLONE_PAGE_MODAL_OPEN]: handleClonePageModalOpen,
  [ACTION_TYPES.CLONE_PAGE_MODAL_CLOSE]: handleClonePageModalClose,
  [ACTION_TYPES.CLONE_PAGE_MODAL_SUBMIT]: handleClonePage,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleClonePageModalFieldChange,
};

export default ACTION_HANDLERS;
