import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';
import { BOOL_INPUT_TYPES, TEXT_INPUT_TYPES } from '../../constants/viewBuilder.constants';

const FIELD_IDS = {
  MANDATORY: ALL_VIEW_PROPERTY_KEYS.MANDATORY,
  EDITABLE: ALL_VIEW_PROPERTY_KEYS.EDITABLE,
  DISABLED: ALL_VIEW_PROPERTY_KEYS.DISABLED,
  COMPONENT_RENDERER: ALL_VIEW_PROPERTY_KEYS.COMPONENT_RENDERER,
  TEXT_AREA_ROWS: ALL_VIEW_PROPERTY_KEYS.TEXT_AREA_ROWS,
  WIDTH: ALL_VIEW_PROPERTY_KEYS.WIDTH,
  PREFILLED_VALUE: ALL_VIEW_PROPERTY_KEYS.PREFILLED_VALUE,
  EVENT_HANDLERS: ALL_VIEW_PROPERTY_KEYS.EVENT_HANDLERS,
  CUSTOM_STYLES: ALL_VIEW_PROPERTY_KEYS.CUSTOM_STYLES,
};

const TEXT_RENDERER_OPTIONS = [
  { label: __('Text Input'), value: TEXT_INPUT_TYPES.TEXT_INPUT },
  { label: __('Text Area'), value: TEXT_INPUT_TYPES.TEXT_AREA },
];

const BOOL_RENDERER_OPTIONS = [
  { label: __('Checkbox'), value: BOOL_INPUT_TYPES.CHECKBOX },
  { label: __('Switch'), value: BOOL_INPUT_TYPES.SWITCH },
];

const CUSTOM_ON_CHANGE_FIELD_IDS = [FIELD_IDS.COMPONENT_RENDERER];

export { FIELD_IDS, TEXT_RENDERER_OPTIONS, BOOL_RENDERER_OPTIONS, CUSTOM_ON_CHANGE_FIELD_IDS };
