import { tget } from '@tekion/tekion-base/utils/general';
import { stepsIteratorWithCallback } from '@tekion/tekion-rule-engine/src/utils/core/crudOperations';
import { getWorkflowVariablesContext } from './getUpdatedWorkflowWithVariableInfo';

const getWorkflowPayload = (workflow) => {
  // purpose of this function is to sanitise workflow before sending it to backend
  // we remove our custom step added while creating the data
  // remove if there is any ghost nodes
  // In future we can do workflow validation here

  const currentRootNodeId = tget(workflow, 'rootNodeId');
  const currentNodeList = tget(workflow, 'nodeList');
  const newRootNodeId = tget(workflow, `nodeList[${currentRootNodeId}].nextNodeId`);
  const variablesInfo = tget(workflow, 'uiMetadata.variablesInfo', {});
  const newNodeList = [];

  const setNodeList = (_, n) => newNodeList.push(n);

  stepsIteratorWithCallback(currentNodeList, newRootNodeId, setNodeList);

  return {
    ...workflow,
    rootNodeId: newRootNodeId,
    nodeList: newNodeList,
    variables: getWorkflowVariablesContext(variablesInfo),
  };
};

export default getWorkflowPayload;
