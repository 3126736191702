const FIELD_IDS = {
  ID: 'id',
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  URL: 'url',
  EXTERNAL_CREDENTIAL_NAME: 'externalCredentialName',
  CLIENT_CERTIFICATE: 'clientCertificate',
  GENERATE_AUTH_HEADERS: 'generateAuthHeaders',
  ALLOW_FORMULA_IN_HEADERS: 'allowFormulaInHeaders',
  ALLOW_FORMULA_IN_BODY: 'allowFormulaInBody',
  OPEN_API_SPECIFICATION_ID: 'openApiSpecificationId',
  CREATE_EXTERNAL_CREDENTIAL: 'createExternalCredential',
};

const SERVER_CREDENTIAL_FORM_CONTEXT_ID = 'SERVER_CREDENTIAL_FORM_CONTEXT_ID';

const ROWS = 5;

export { FIELD_IDS, SERVER_CREDENTIAL_FORM_CONTEXT_ID, ROWS };
