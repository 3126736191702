import SYSTEM_DEFINED_FIELDS from '../../../../constants/systemDefinedFields';

const ACTION_TYPES = {
  INIT: 'INIT',
  POPULATE_LOOKUP_DATA: 'POPULATE_LOOKUP_DATA',
  ON_VIEW_SELECT: 'ON_VIEW_SELECT',
  SET_COMPLEX_FIELD_COLUMNS_DATA: 'SET_COMPLEX_FIELD_COLUMNS_DATA',
  TABLE_ITEMS_SET_FILTER: 'TABLE_ITEMS_SET_FILTER',
  TABLE_ITEMS_SORT: 'TABLE_ITEMS_SORT',
  ON_EXPORT_CLICK: 'ON_EXPORT_CLICK',
};

const DISABLED_FIELDS = [
  SYSTEM_DEFINED_FIELDS.ID,
  SYSTEM_DEFINED_FIELDS.CREATED_BY,
  SYSTEM_DEFINED_FIELDS.MODIFIED_TIME,
  SYSTEM_DEFINED_FIELDS.CREATED_TIME,
];

const MULTI_SELECT_CUSTOM_STYLES = {
  multiValueLabel: (styles) => ({
    ...styles,
    padding: '0 0.4rem 0 1rem',
    'font-size': '1.4rem',
  }),
};

const CONSTRAINT_TYPES = {
  STRING_LENGTH: 'STRING_LENGTH',
  REGEX: 'REGEX',
  NUMBER_RANGE: 'NUMBER_RANGE',
  RANGE_CONSTRAINT: 'RANGE',
};

const DEFAULT_CONTEXT_ID = 'FORM_VIEW_RENDERER';

const FORM_SCRIPT_RETURN_VALUE_IDS = {
  IS_FORM_VALUE_OVERRIDDEN: 'isFormValueOverridden',
  FORM_VALUES: 'formValues',
};

export { ACTION_TYPES, DISABLED_FIELDS, MULTI_SELECT_CUSTOM_STYLES, CONSTRAINT_TYPES, DEFAULT_CONTEXT_ID, FORM_SCRIPT_RETURN_VALUE_IDS };
