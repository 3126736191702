import _keys from 'lodash/keys';
import _map from 'lodash/map';

import { defaultMemoize } from 'reselect';
import addOptionsToAdditional from '@tekion/tekion-base/utils/addOptionsToAdditional';
import { DEFAULT_FILTER_GROUP } from '@tekion/tekion-components/organisms/filterSection';

import { DEFAULT_FILTER_TYPES, FILTERS } from '../constants/exportListPage.constants.filters';
import { EXPORTS_LIST_PAGE_FIELD_IDS, EXPORT_STATUSES } from '../constants/exportListPage.constants';

const createDropDownOptions = defaultMemoize((list = []) => _map(list, (item) => ({ label: item, value: item })));
const getStatusFilter = (statuses) => addOptionsToAdditional(FILTERS[EXPORTS_LIST_PAGE_FIELD_IDS.STATUS_FIELD], createDropDownOptions(statuses));

const createFilterProps = defaultMemoize((selectedFilterGroup) => {
  const filterTypes = [FILTERS[EXPORTS_LIST_PAGE_FIELD_IDS.CREATED_TIME_FIELD], getStatusFilter(_keys(EXPORT_STATUSES))];
  return {
    filterTypes,
    defaultFilterTypes: DEFAULT_FILTER_TYPES,
    appliedFilterGroup: selectedFilterGroup || DEFAULT_FILTER_GROUP,
  };
});

export { createDropDownOptions, createFilterProps };
