/* eslint-disable import/order */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactEditor } from 'slate-react';

// helpers
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { getSelectedLinkNode } from '../../helpers/richTextEditor.link';

// components
import Modal from '@tekion/tekion-components/molecules/Modal';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import LinkInsertionForm, { LINK_INSERTION_FORM_CONTEXT_ID } from './organisms/linkInsertionForm';

// events
import linkDialogEvent from './helpers/linkDialog.event';

// containers
import withEditor from '../../containers/withEditor';

class LinkDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLinkDialogVisible: false,
    };
    const { editor } = props;
    linkDialogEvent.on(`SHOW_LINK_DIALOG_${editor.id}`, this.showLinkDialog);
    linkDialogEvent.on(`HIDE_LINK_DIALOG_${editor.id}`, this.hideLinkDialog);
  }

  componentWillUnmount() {
    linkDialogEvent.removeAllListeners();
  }

  // eslint-disable-next-line class-methods-use-this
  handleSubmit = () => {
    triggerSubmit(LINK_INSERTION_FORM_CONTEXT_ID);
  };

  hideLinkDialog = () => {
    const { editor } = this.props;
    this.setState({
      isLinkDialogVisible: false,
    });
    ReactEditor.focus(editor);
  };

  showLinkDialog = () => {
    this.setState({
      isLinkDialogVisible: true,
    });
  };

  render() {
    const { isLinkDialogVisible } = this.state;
    const { editor } = this.props;
    const selectedLinkNode = getSelectedLinkNode(editor);

    return (
      <Modal visible={isLinkDialogVisible} onCancel={this.hideLinkDialog} title={__('Link')} onSubmit={this.handleSubmit} destroyOnClose>
        <LinkInsertionForm selectedLinkNode={selectedLinkNode} hideLinkDialog={this.hideLinkDialog} />
      </Modal>
    );
  }
}

LinkDialog.propTypes = {
  editor: PropTypes.object,
};

LinkDialog.defaultProps = {
  editor: EMPTY_OBJECT,
};

export default withEditor(LinkDialog);
