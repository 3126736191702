import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import withActions from '@tekion/tekion-components/connectors/withActions';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import withSize from '../../../../connectors/withSize';
import { getSections, getFields } from './helpers/jobScheduleForm.formConfig';
import ACTION_HANDLERS from './helpers/jobScheduleForm.actionHandlers';
import { FORM_MODES } from '../../../../constants/general.constants';
import ACTION_TYPES from './constants/jobScheduleForm.actionTypes';
import { JOB_SCHEDULE_FORM_CONTEXT_ID } from './constants/jobScheduleForm.general';
import FIELD_IDS from './constants/jobScheduleForm.fieldIds';

const JobScheduleForm = ({
  isSubmitting,
  isFetching,
  contentHeight,
  formValues,
  errors,
  entityNameOptions,
  viewOptions,
  timeZoneOptions,
  match,
  onAction,
}) => {
  const jobScheduleId = _get(match, 'params.jobScheduleId');
  const formMode = _isEmpty(jobScheduleId) ? FORM_MODES.CREATE : FORM_MODES.EDIT;
  const headerTitle = formMode === FORM_MODES.CREATE ? __('Create Job Schedule') : __('Edit Job Schedule');
  const primaryButtonLabel = formMode === FORM_MODES.CREATE ? __('Create') : __('Update');

  const selectedScheduleType = getArraySafeValue(_get(formValues, FIELD_IDS.SCHEDULE_TYPE));
  const selectTaskType = getArraySafeValue(_get(formValues, FIELD_IDS.TASK_TYPE));
  const selectedEntityName = getArraySafeValue(_get(formValues, FIELD_IDS.ENTITY_NAME));
  const isSimpleForever = _get(formValues, FIELD_IDS.SIMPLE_FOREVER);

  const sections = useMemo(
    () => getSections(selectedScheduleType, selectTaskType, isSimpleForever),
    [selectedScheduleType, selectTaskType, isSimpleForever],
  );
  const fields = useMemo(
    () => getFields(entityNameOptions, viewOptions, timeZoneOptions, selectedScheduleType),
    [entityNameOptions, viewOptions, timeZoneOptions, selectedScheduleType],
  );

  const handleSubmit = useCallback(() => {
    triggerSubmit(JOB_SCHEDULE_FORM_CONTEXT_ID);
  }, []);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  useEffect(() => {
    if (!_isEmpty(selectedEntityName)) {
      onAction({ type: ACTION_TYPES.ON_ENTITY_CHANGE, payload: selectedEntityName });
    }
  }, [selectedEntityName, onAction]);

  return (
    <Page>
      <Page.Header hasBack>
        <Heading>{headerTitle}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          isFetching={isFetching}
          className="p-t-12"
          contextId={JOB_SCHEDULE_FORM_CONTEXT_ID}
          sections={sections}
          fields={fields}
          values={formValues}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>
      <Page.Footer>
        <Page.Footer>
          <SaveComponent
            id={JOB_SCHEDULE_FORM_CONTEXT_ID}
            primaryActionLoading={isSubmitting}
            primaryButtonLabel={primaryButtonLabel}
            onPrimaryAction={handleSubmit}
            onSecondaryAction={handleCancel}
          />
        </Page.Footer>
      </Page.Footer>
    </Page>
  );
};

JobScheduleForm.propTypes = {
  isSubmitting: PropTypes.bool,
  isFetching: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  match: PropTypes.object.isRequired,
  entityNameOptions: PropTypes.array,
  viewOptions: PropTypes.array,
  timeZoneOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

JobScheduleForm.defaultProps = {
  isSubmitting: false,
  isFetching: false,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  entityNameOptions: EMPTY_ARRAY,
  viewOptions: EMPTY_ARRAY,
  timeZoneOptions: EMPTY_ARRAY,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS), withSize({ hasPageHeader: true, hasPageFooter: true }))(JobScheduleForm);
