import _map from 'lodash/map';
import _size from 'lodash/size';
import _valuesIn from 'lodash/valuesIn';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { DEFAULT_LAYOUT } from '../constants/createDashboard.general.constants';

const createListData = (widgets) =>
  _map(widgets, (widget, i) => ({
    widgetType: widget.widgetType,
    title: widget.name || __(`Widget #${i + 1}`),
    name: widget.name,
  }));

const updateItemLayoutOnAddWidget = ({ widgetItems = EMPTY_ARRAY, layoutsByName = EMPTY_OBJECT, widgetName: params = '' }) => {
  const isEvenWidget = _size(widgetItems) % 2 === 0;
  const updatedWidgetItems = [...widgetItems, { widgetName: params }];
  const updatedLayoutByName = [
    ..._valuesIn(layoutsByName),
    {
      i: params,
      h: DEFAULT_LAYOUT.h,
      w: DEFAULT_LAYOUT.w,
      x: isEvenWidget ? DEFAULT_LAYOUT.x_even : DEFAULT_LAYOUT.x_odd,
      y: DEFAULT_LAYOUT.y,
      minH: DEFAULT_LAYOUT.minH,
      minW: DEFAULT_LAYOUT.minW,
    },
  ];

  return { updatedWidgetItems, updatedLayoutByName };
};

const getWidgetItems = (widgetLayouts) => _map(widgetLayouts, (item) => ({ widgetName: item.widgetName }));

export { createListData, updateItemLayoutOnAddWidget, getWidgetItems };
