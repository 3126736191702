import React from 'react';
import { Node } from 'slate';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

// components
import Button from '@tekion/tekion-components/atoms/Button';
import IconAsBtn from '@tekion/tekion-components/atoms/iconAsBtn';

// constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// hooks
import useLinkPopupActions from './hooks/useLinkPopupActions';

// styles
import styles from './linkSelectionPopupContent.module.scss';

function LinkSelectionPopupContent(props) {
  const { linkNodeElement, closePopup } = props;

  const { url } = linkNodeElement || EMPTY_OBJECT;

  const linkDisplayValue = linkNodeElement && Node.string(linkNodeElement);

  const { handleEditLink, handleLinkRemoval, handleUrlClick } = useLinkPopupActions(linkNodeElement, closePopup, url);

  return (
    <div className={`p-16 relative flex flex-column ${styles.popupContainer}`}>
      <IconAsBtn onClick={closePopup} containerClassName={`absolute ${styles.closeBtn}`}>
        icon-close2
      </IconAsBtn>
      <div className={styles.linkContentContainer}>
        <div className={styles.linkContent}>{linkDisplayValue}</div>
        <Button view={Button.VIEW.LINK} className="full-width" onClick={handleUrlClick}>
          <span className={`full-width text-left-align ${styles.linkContent}`}>{url}</span>
        </Button>
      </div>
      <div className={`d-flex ${styles.popupActions} p-t-12 full-width`}>
        <Button label={__('Edit')} key="edit" onClick={handleEditLink} className="m-r-8" />
        <Button label={__('Remove')} key="remove" onClick={handleLinkRemoval} view={Button.VIEW.PRIMARY} />
      </div>
    </div>
  );
}

LinkSelectionPopupContent.propTypes = {
  linkNodeElement: PropTypes.shape({
    type: PropTypes.string,
    children: PropTypes.array,
  }),
  closePopup: PropTypes.func,
};

LinkSelectionPopupContent.defaultProps = {
  linkNodeElement: undefined,
  closePopup: _noop,
};

export default React.memo(LinkSelectionPopupContent);
