import React, { useMemo, useCallback } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Tekion-components
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Modal from '@tekion/tekion-components/molecules/Modal';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

// Tekion-connectors
import withActions from '@tekion/tekion-components/connectors/withActions';
import { DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';

// Connectors
import withSize from '../../../../connectors/withSize';

import { createTableProps, getFilterProps } from './helpers/visualBuilderList.helpers';
import ACTION_HANDLERS from './helpers/visualBuilderList.actionHandlers';
import { CLONE_PAGE_MODAL_FORM_SECTIONS, CLONE_PAGE_MODAL_FORM_FIELDS } from './helpers/visualBuilderCloneModal.formConfig';
import { COLUMN_CONFIG } from './helpers/visualBuilderList.columns';
import {
  INITIAL_STATE_VISUAL_BUILDER_LIST_PROPS,
  SUB_HEADER_PROPS,
  TABLE_MANAGER_PROPS,
  CLONE_PAGE_MODAL_FORM_CONTEXT_ID,
} from './constants/visualBuilderList.general';
import ACTION_TYPES from './constants/visualBuilderList.actionTypes';

const VisualBuilderList = (props) => {
  const {
    isLoading,
    isClonePageModalVisible,
    isClonePageModalDataSubmitting,
    contentHeight,
    data,
    selectedFilters,
    selectedFilterGroup,
    totalCount,
    pageSize,
    currentPage,
    nextPageToken,
    clonePageModalFormData,
    clonePageModalFormErrors,
    onAction,
  } = props;

  const tableProps = useMemo(
    () => createTableProps(isLoading, totalCount, pageSize, currentPage, onAction),
    [isLoading, totalCount, pageSize, currentPage, onAction],
  );

  const handleClonePageModalClose = useCallback(() => {
    onAction({ type: ACTION_TYPES.CLONE_PAGE_MODAL_CLOSE });
  }, [onAction]);

  const handleClonePageModalSubmit = useCallback(() => {
    triggerSubmit(CLONE_PAGE_MODAL_FORM_CONTEXT_ID);
  }, []);

  return (
    <Page style={{ height: contentHeight }}>
      <TableManager
        subHeaderProps={SUB_HEADER_PROPS}
        tableManagerProps={TABLE_MANAGER_PROPS}
        columns={COLUMN_CONFIG}
        tableProps={tableProps}
        filterProps={getFilterProps(selectedFilters, selectedFilterGroup)}
        data={data}
        pageSize={pageSize}
        onAction={onAction}
        tokenPagination
        nextPageToken={nextPageToken}
      />
      <PropertyControlledComponent controllerProperty={isClonePageModalVisible}>
        <Modal
          destroyOnClose
          visible={isClonePageModalVisible}
          loading={isClonePageModalDataSubmitting}
          width={Modal.SIZES.MD}
          title={__('Clone Page')}
          onCancel={handleClonePageModalClose}
          onSubmit={handleClonePageModalSubmit}
        >
          <FormWithSubmission
            contextId={CLONE_PAGE_MODAL_FORM_CONTEXT_ID}
            sections={CLONE_PAGE_MODAL_FORM_SECTIONS}
            fields={CLONE_PAGE_MODAL_FORM_FIELDS}
            values={clonePageModalFormData}
            errors={clonePageModalFormErrors}
            onAction={onAction}
          />
        </Modal>
      </PropertyControlledComponent>
    </Page>
  );
};

VisualBuilderList.propTypes = {
  contentHeight: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isClonePageModalVisible: PropTypes.bool,
  isClonePageModalDataSubmitting: PropTypes.bool,
  data: PropTypes.array,
  selectedFilters: PropTypes.array,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  selectedFilterGroup: PropTypes.string,
  totalCount: PropTypes.number,
  nextPageToken: PropTypes.string,
  clonePageModalFormData: PropTypes.object,
  clonePageModalFormErrors: PropTypes.object,
  onAction: PropTypes.func,
};

VisualBuilderList.defaultProps = {
  isLoading: false,
  isClonePageModalVisible: false,
  isClonePageModalDataSubmitting: false,
  data: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
  pageSize: 0,
  currentPage: DEFAULT_PAGE_INFO.start,
  selectedFilterGroup: EMPTY_STRING,
  totalCount: 0,
  nextPageToken: EMPTY_STRING,
  clonePageModalFormData: EMPTY_OBJECT,
  clonePageModalFormErrors: EMPTY_OBJECT,
  onAction: _noop,
};

export default compose(
  withSize({ hasPageHeader: false, hasPageFooter: false }),
  withActions(INITIAL_STATE_VISUAL_BUILDER_LIST_PROPS, ACTION_HANDLERS),
)(VisualBuilderList);
