import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { removeNonDigits } from '@tekion/tekion-base/formatters/number';
import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';

import { FIELD_IDS, MIN_INPUT_RANGE, MAX_INPUT_RANGE } from './repeatNodeModal.constants';

import styles from './repeatNodeModal.module.scss';

const FORM_SECTIONS = [
  {
    className: styles.section,
    rows: [
      {
        columns: [FIELD_IDS.TOTAL_COUNT],
      },
    ],
  },
];

const getFieldConfig = {
  [FIELD_IDS.TOTAL_COUNT]: {
    id: FIELD_IDS.TOTAL_COUNT,
    renderer: NumberInputField,
    renderOptions: {
      size: 6,
      required: true,
      validators: [isRequiredRule],
      addonAfter: __('Times'),
      placeholder: __('Type Here'),
      shouldDisabledStepper: true,
      min: MIN_INPUT_RANGE,
      max: MAX_INPUT_RANGE,
      parser: removeNonDigits,
    },
  },
};

export { getFieldConfig, FORM_SECTIONS };
