import LOOKUP_DETAILS_FORM_FIELD_IDS from './lookupDetailsForm.fieldIds';

import styles from '../../../fieldsForm.module.scss';

const LOOKUP_DETAILS_FORM_SECTIONS = [
  {
    header: {
      label: __('Lookup Details'),
      className: styles.formSectionHeader,
    },
    rows: [
      {
        columns: [LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED],
      },
    ],
  },
];

export default LOOKUP_DETAILS_FORM_SECTIONS;
