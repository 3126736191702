import React, { useCallback, useContext, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _noop from 'lodash/noop';
import _size from 'lodash/size';
import _head from 'lodash/head';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import DropZone from '../dropZone/DropZone';
import DropZoneContainer from '../dropZone/DropZoneContainer';
import ViewComponent from '../viewComponent/ViewComponent';
import ComponentRendererContainer from '../componentRendererContainer';
import ViewBuilderContext from '../../ViewBuilder.context';
import ViewRendererContext from '../../../../context/viewRenderer.context';

import { isComponentEditable, isComponentDeletable } from '../../helpers/viewBuilder.helper';
import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';
import variableApiFactory from '../../../../factories/variableApi.Factory';

import {
  COMPONENT_TYPES,
  COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP,
  MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE,
  VIEW_CONTEXT_KEYS,
} from '../../constants/viewBuilder.constants';
import { VIEW_CONFIGURATION_GENERAL_KEYS, RENDERER_PROP_KEYS } from '../../../../constants/viewBuilder.constants';
import { CUSTOM_STYLE_IDS } from '../../../../constants/customStyles.constants';

import styles from './sectionColumn.module.scss';

const SUPPORTED_CUSTOM_STYLE_SECTION = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[COMPONENT_TYPES.SECTION_COLUMN];

const SectionColumn = ({
  isPreviewMode,
  isDeleteEnabled,
  isDropEnabled,
  className,
  columnIndex,
  style,
  componentConfig,
  entityRecord,
  ...restProps
}) => {
  const properties = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);

  const viewContextValue = useContext(ViewRendererContext);
  const { onSelect } = useContext(ViewBuilderContext);

  const viewVariables = useMemo(() => tget(viewContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.viewVariables`, EMPTY_STRING), [viewContextValue]);
  const handleSetViewVariables = useMemo(
    () => tget(viewContextValue, `${VIEW_CONTEXT_KEYS.VIEW_CONTEXT}.handleSetViewVariables`, _noop),
    [viewContextValue],
  );
  const variablesApi = useMemo(() => variableApiFactory(viewVariables, handleSetViewVariables), [viewVariables, handleSetViewVariables]);

  const customStyles = tget(properties, RENDERER_PROP_KEYS.CUSTOM_STYLES, EMPTY_OBJECT);
  const resolvedCustomStyles = useMemo(
    () => getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_SECTION, customStyles, entityRecord, variablesApi),
    [entityRecord, variablesApi, customStyles],
  );

  const sectionColumnId = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
  const children = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.CHILDREN, EMPTY_ARRAY);

  const combinedContainerStyleObject = useMemo(
    () => ({ ...style, ...tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTAINER, {}) }),
    [style, resolvedCustomStyles],
  );

  const handleSelect = useCallback(
    (event) => {
      event.stopPropagation();

      onSelect(sectionColumnId, columnIndex);
    },
    [sectionColumnId, onSelect, columnIndex],
  );

  const renderSectionColumnDropZone = () => {
    if (!isDropEnabled) {
      return null;
    }

    if (_size(children) >= 1 && tget(_head(children), VIEW_CONFIGURATION_GENERAL_KEYS.TYPE) === COMPONENT_TYPES.FORM_VIEW_RENDERER) {
      return null;
    }

    return (
      <DropZoneContainer
        id={sectionColumnId}
        index={children.length}
        className={styles.dropZoneGutter}
        onHoverClassName={styles.onHoverDropZoneGutter}
        acceptedComponentTypes={MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE[COMPONENT_TYPES.SECTION_COLUMN]}
      />
    );
  };

  const renderSectionInPreviewMode = () => (
    <div key={columnIndex} className={cx(styles.sectionColumn, styles.previewMode)} style={combinedContainerStyleObject} onClick={handleSelect}>
      {_map(children, (childComponentConfig) => {
        const childSectionId = tget(childComponentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
        const componentProps = {
          isPreviewMode,
          componentConfig: childComponentConfig,
        };

        return <ComponentRendererContainer key={childSectionId} {...componentProps} entityRecord={entityRecord} {...restProps} />;
      })}
    </div>
  );

  const renderSectionInBuilderMode = () => (
    <div
      key={columnIndex}
      className={cx(styles.sectionColumn, styles.builderMode, className)}
      style={combinedContainerStyleObject}
      onClick={handleSelect}
    >
      {_map(children, (childComponentConfig, index) => {
        const type = tget(childComponentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.TYPE);
        const childSectionId = tget(childComponentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.SECTION_ID);
        const componentProps = {
          isPreviewMode,
          componentConfig: childComponentConfig,
          entityRecord,
          ...restProps,
        };

        const isEditEnabled = isComponentEditable(childComponentConfig, _get(componentProps, 'entity'));
        const isDeleteEnabledForChildren = isComponentDeletable(type);

        if (type === COMPONENT_TYPES.FORM_VIEW_RENDERER) {
          return (
            <ViewComponent
              isDeleteEnabled={false}
              isEditEnabled={false}
              index={index}
              key={childComponentConfig.sectionId}
              className={styles.formBuilderContainer}
              componentConfig={childComponentConfig}
            >
              <ComponentRendererContainer {...componentProps} />
            </ViewComponent>
          );
        } else {
          return (
            <React.Fragment key={childSectionId}>
              {isDropEnabled && (
                <DropZone
                  index={index}
                  id={sectionColumnId}
                  hiddenDropZoneClassName={styles.hiddenDropZone}
                  className={styles.dropZoneGutter}
                  onHoverClassName={styles.onHoverDropZoneGutter}
                  acceptedComponentTypes={MAP_OF_COMPONENT_TYPE_TO_ACCEPTED_COMPONENT_TYPE_IN_DROP_ZONE[COMPONENT_TYPES.SECTION_COLUMN]}
                />
              )}

              <ViewComponent
                key={childSectionId}
                isDeleteEnabled={isDeleteEnabledForChildren}
                isEditEnabled={isEditEnabled}
                index={index}
                componentConfig={childComponentConfig}
              >
                <ComponentRendererContainer {...componentProps} />
              </ViewComponent>
            </React.Fragment>
          );
        }
      })}

      {renderSectionColumnDropZone()}
    </div>
  );

  return isPreviewMode ? renderSectionInPreviewMode() : renderSectionInBuilderMode();
};

SectionColumn.propTypes = {
  isPreviewMode: PropTypes.bool,
  isDeleteEnabled: PropTypes.bool,
  isDropEnabled: PropTypes.bool,
  columnIndex: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  componentConfig: PropTypes.object.isRequired,
};

SectionColumn.defaultProps = {
  isPreviewMode: false,
  isDeleteEnabled: true,
  isDropEnabled: true,
  columnIndex: 0,
  className: EMPTY_STRING,
  style: {},
};

export default React.memo(SectionColumn);
