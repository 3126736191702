import _isEmpty from 'lodash/isEmpty';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FIELD_IDS } from '../constants/searchOptionsConfigurator.constants';

const rowCheck = {
  [FIELD_IDS.RELATIONSHIP_FIELD_INFO]: ({ relationshipField }) => !_isEmpty(relationshipField),
};

const getSearchOptionsFormSection = (relationshipField) => [
  {
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
      },
      {
        columns: filterRows([FIELD_IDS.RELATIONSHIP_FIELD_INFO], { relationshipField }, rowCheck),
      },
      {
        columns: [FIELD_IDS.SEARCH_METADATA],
      },
    ],
  },
];

export default getSearchOptionsFormSection;
