import React from 'react';
import PropTypes from 'prop-types';

import Select from '@tekion/tekion-components/molecules/Select';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { workspaceOptions } from './workspaceSelect.helpers';
import ACTION_TYPES from '../../constants/tenantUserForm.actionTypes';

import styles from './workspaceSelect.module.scss';

const WorkspaceSelect = ({ column, index, value, tdProps }) => {
  const { onAction, workspaceList, selectedWorkspaces } = tget(tdProps, 'rest', EMPTY_OBJECT);
  const { key } = column;

  const handleUserWorkspaceChange = (newValue) =>
    onAction({
      type: ACTION_TYPES.ON_USER_WORKSPACE_UPDATE,
      payload: {
        index,
        key,
        value: newValue,
      },
    });

  return (
    <Select
      showSearch
      filterOption
      className={styles.selectContainer}
      containerClassName={styles.selectContainer}
      placeholder={__(' Workspace')}
      value={value}
      options={workspaceOptions(selectedWorkspaces, workspaceList)}
      optionFilterProp="children"
      onChange={handleUserWorkspaceChange}
    />
  );
};

WorkspaceSelect.propTypes = {
  column: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  tdProps: PropTypes.shape({
    rest: PropTypes.shape({
      onAction: PropTypes.func.isRequired,
      selectedWorkspaces: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string]),
};

WorkspaceSelect.defaultProps = {
  value: EMPTY_ARRAY,
};
export default WorkspaceSelect;
