import { useCallback } from 'react';
import _noop from 'lodash/noop';

// eslint-disable-next-line default-param-last
const imageClickHandler = (openImagePreview = _noop, enableImagePreview) => {
  if (enableImagePreview) {
    openImagePreview();
  }
};

function useImageOnClick(openImagePreview, enableImagePreview) {
  const handleImageClick = useCallback(() => imageClickHandler(openImagePreview, enableImagePreview), [openImagePreview, enableImagePreview]);

  return handleImageClick;
}

export default useImageOnClick;
