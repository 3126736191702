exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".recordTypeOverride_collapse__snCGgdwqkY{width:100%}[dir=ltr] .recordTypeOverride_collapse__snCGgdwqkY{border-left:none}[dir=rtl] .recordTypeOverride_collapse__snCGgdwqkY{border-right:none}.recordTypeOverride_overrideFieldsHeading__arvB8sg3rf{font-size:1.8rem}.recordTypeOverride_overrideContainer__jmCqL4rmc4{height:100}[dir] .recordTypeOverride_overrideContainer__jmCqL4rmc4{margin:2.4rem 2.4rem 0;border:1px solid #ccc}.recordTypeOverride_buttonContainer__mVdydnhdBL{display:flex;justify-content:space-between}[dir] .recordTypeOverride_buttonContainer__mVdydnhdBL{margin:2rem 2.4rem}.recordTypeOverride_addOverrideFieldHeader__kzra5RAkkJ{display:flex;justify-content:space-between;align-items:center;width:100%}[dir] .recordTypeOverride_addOverrideFieldHeader__kzra5RAkkJ{padding-top:1.5rem;padding-bottom:1.5rem}[dir=ltr] .recordTypeOverride_addOverrideFieldHeader__kzra5RAkkJ{margin-right:2.4rem}[dir=rtl] .recordTypeOverride_addOverrideFieldHeader__kzra5RAkkJ{margin-left:2.4rem}.recordTypeOverride_inputTable__466119NA2X{min-height:0 !important}[dir] .recordTypeOverride_inputTable__466119NA2X{margin-bottom:1.5rem}.recordTypeOverride_headerNavigationContainer__iRQD4NQmYa{display:flex;flex-flow:row;font-family:\"Proxima-Nova-Regular\"}.recordTypeOverride_expressionHeading__cXPqZYvgRB{font-size:\"Proxima-Nova-Regular\";font-weight:bold}.recordTypeOverride_conditionExpression__4fTxs1RiZx{width:20%;display:flex;align-items:center}[dir] .recordTypeOverride_conditionExpression__4fTxs1RiZx{margin-top:1rem;margin-bottom:2rem}.recordTypeOverride_contentText__j6t2vgWddu{font-weight:bold;font-size:2rem}[dir] .recordTypeOverride_contentText__j6t2vgWddu{margin:2rem}.recordTypeOverride_contentContainer__mWzYPHk4Ub{height:100%;display:flex;flex-flow:column;align-items:center;justify-content:center}.recordTypeOverride_listInputContainer__s2Yz2WnY7z{display:flex;flex-direction:column;align-items:flex-start}", ""]);

// Exports
exports.locals = {
	"collapse": "recordTypeOverride_collapse__snCGgdwqkY",
	"overrideFieldsHeading": "recordTypeOverride_overrideFieldsHeading__arvB8sg3rf",
	"overrideContainer": "recordTypeOverride_overrideContainer__jmCqL4rmc4",
	"buttonContainer": "recordTypeOverride_buttonContainer__mVdydnhdBL",
	"addOverrideFieldHeader": "recordTypeOverride_addOverrideFieldHeader__kzra5RAkkJ",
	"inputTable": "recordTypeOverride_inputTable__466119NA2X",
	"headerNavigationContainer": "recordTypeOverride_headerNavigationContainer__iRQD4NQmYa",
	"expressionHeading": "recordTypeOverride_expressionHeading__cXPqZYvgRB",
	"conditionExpression": "recordTypeOverride_conditionExpression__4fTxs1RiZx",
	"contentText": "recordTypeOverride_contentText__j6t2vgWddu",
	"contentContainer": "recordTypeOverride_contentContainer__mWzYPHk4Ub",
	"listInputContainer": "recordTypeOverride_listInputContainer__s2Yz2WnY7z"
};