import _pick from 'lodash/pick';
import _valuesIn from 'lodash/valuesIn';
import _set from 'lodash/set';
import _includes from 'lodash/includes';
import _size from 'lodash/size';
import _isNumber from 'lodash/isNumber';
import _slice from 'lodash/slice';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { getComponentProperties } from '../../helpers/viewBuilder.helper';
import { getUuid } from '../../../../utils';
import { updateSelectedViewComponent } from '../../organisms/propertiesForm/propertiesForm.helper';

import { CUSTOM_ON_CHANGE_FIELD_IDS, FIELD_IDS } from './sectionPropertiesForm.constants';
import { VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';
import { COMPONENT_TYPES } from '../../constants/viewBuilder.constants';
import {
  PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS,
  VIEW_COMPONENT_UPDATE_ACTION_ID,
  VIEW_COMPONENT_UPDATE_ACTION_TYPE,
} from '../../organisms/propertiesForm/propertiesForm.constants';

const ARRAY_OF_FIELD_IDS = _valuesIn(FIELD_IDS);

const getSectionPropertiesFromProperties = (properties) => _pick(properties, ARRAY_OF_FIELD_IDS);

const columnCountOnChangeHandler = (selectedViewComponent, payload) => {
  let value = tget(payload, 'value');
  const id = tget(payload, 'id');

  const children = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.CHILDREN, []);
  const properties = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, {});
  const viewType = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.VIEW_TYPE);

  const childrenSize = _size(children);

  if (!_isNumber(value) || value < 1) {
    value = 1;
  }

  _set(properties, id, value);

  let newChildren = children;

  if (childrenSize >= value) {
    newChildren = _slice(children, 0, value) || [];
  } else {
    const componentTypeToAppend = COMPONENT_TYPES.SECTION_COLUMN;

    const newChildrenToAppend = [];
    const columnCount = parseInt(value, 10);
    const sectionColumnProperties = getComponentProperties({ componentType: componentTypeToAppend, defaultPropertyValues: { viewType } });

    for (let columnIndex = 0; columnIndex < columnCount - childrenSize; columnIndex++) {
      newChildrenToAppend.push({
        type: componentTypeToAppend,
        sectionId: getUuid(),
        viewType,
        properties: sectionColumnProperties,
        children: [],
      });
    }

    newChildren = [...children, ...newChildrenToAppend];
  }

  const action = {
    [VIEW_COMPONENT_UPDATE_ACTION_ID.ACTION_TYPE]: VIEW_COMPONENT_UPDATE_ACTION_TYPE.CHILDREN_UPDATE,
    [VIEW_COMPONENT_UPDATE_ACTION_ID.PAYLOAD]: newChildren,
  };

  const newSelectedViewComponent = updateSelectedViewComponent(selectedViewComponent, action);

  return newSelectedViewComponent;
};

const customOnChangeHandlerByFieldId = {
  [FIELD_IDS.COLUMN_COUNT]: columnCountOnChangeHandler,
};

const getUpdatedSelectedViewComponentFromOnChangeValue = (selectedViewComponent, payload = EMPTY_OBJECT) => {
  const { id, value } = payload;

  if (!_includes(CUSTOM_ON_CHANGE_FIELD_IDS, id)) {
    const propertiesToUpdate = [
      { [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_NAME]: id, [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_VALUE]: value },
    ];

    const action = {
      [VIEW_COMPONENT_UPDATE_ACTION_ID.ACTION_TYPE]: VIEW_COMPONENT_UPDATE_ACTION_TYPE.PROPERTIES_UPDATE,
      [VIEW_COMPONENT_UPDATE_ACTION_ID.PAYLOAD]: propertiesToUpdate,
    };

    const newSelectedViewComponent = updateSelectedViewComponent(selectedViewComponent, action);
    return newSelectedViewComponent;
  }

  const resultFromCustomOnChange = customOnChangeHandlerByFieldId[id](selectedViewComponent, payload);

  return resultFromCustomOnChange;
};

export { getSectionPropertiesFromProperties, getUpdatedSelectedViewComponentFromOnChangeValue };
