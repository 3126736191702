import _property from 'lodash/property';

const roleReader = {
  id: _property('id'),
  name: _property('name'),
  displayName: _property('displayName'),
  description: _property('description'),
  modifiedTime: _property('modifiedTime'),
  createdTime: _property('createdTime'),
  workspaceId: _property('workspaceId'),
};

export default roleReader;
