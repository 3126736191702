import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { NO_DATA } from '@tekion/tekion-base/app.constants';

import { AUTH_TYPE_TO_FIELDS, FIELD_IDS, ROWS } from '../constants/externalCredentialForm.constants';

const getFieldsBasedOnAuthType = (type) => {
  const generalFields = [FIELD_IDS.NAME, FIELD_IDS.DISPLAY_NAME];

  const applicableFieldIdsForGivenAuthType = AUTH_TYPE_TO_FIELDS[type] || [];

  return [...generalFields, ...applicableFieldIdsForGivenAuthType];
};

const getPayloadForExternalCredentials = (externalCredentialFlattenedFormValue) => {
  const OUTER_MOST_FIELDS = [FIELD_IDS.NAME, FIELD_IDS.DISPLAY_NAME];

  const outerMostFieldValues = _pick(externalCredentialFlattenedFormValue, OUTER_MOST_FIELDS);
  const payloadFormattedExternalCredentials = _omit(externalCredentialFlattenedFormValue, OUTER_MOST_FIELDS);

  return {
    ...outerMostFieldValues,
    [FIELD_IDS.AUTH_PROTOCOL]: { ...payloadFormattedExternalCredentials },
  };
};

const getPayloadForAuthProvider = ({ nextPageToken }) => ({
  rows: ROWS,
  limit: 0,
  nextPageToken: _isEmpty(nextPageToken) ? null : nextPageToken,
});

const getValueOptionForAuthProvider = (authProvider) => ({
  label: __(`${tget(authProvider, [FIELD_IDS.DISPLAY_NAME], NO_DATA)}`),
  value: tget(authProvider, [FIELD_IDS.NAME]),
});

const getAuthProviderOptions = (data) => _map(data, getValueOptionForAuthProvider);

export { getFieldsBasedOnAuthType, getPayloadForExternalCredentials, getPayloadForAuthProvider, getAuthProviderOptions };
