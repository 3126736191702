import { defaultMemoize } from 'reselect';

import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _startsWith from 'lodash/startsWith';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { getFormattedDateTime, DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';

import { DELETE_ACTION, EDIT_ACTION } from './recordSharingRuleList.rowActions';
import { TEXT_STARTS_WITH } from '../../../../../constants/filter.constants';
import { PRINCIPAL_TYPES } from '../constants/recordSharingRuleList.general.constants';
import { FIELD_IDS } from '../../../../../constants/recordSharingRule.constants';
import { FIELD_IDS as ENTITY_RECORD_FIELD_IDS } from '../../../../../constants/entityRecordGroup.constants';

const getRowActions = () => [EDIT_ACTION, DELETE_ACTION];

const getTableProps = defaultMemoize((handleRowAction, currentPage, loading, rows, totalNumberOfEntries) => ({
  currentPage: currentPage + 1,
  loading,
  pageSize: rows,
  resultsPerPage: rows,
  totalNumberOfEntries,
  minRows: 0,
  rowHeight: 40,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  rows,
  onRowActionClick: handleRowAction,
  getRowActions,
}));

const getSearchPayload = defaultMemoize(({ searchText, searchField, pageSize, nextPageToken }) => {
  let payload = { rows: pageSize };

  if (!_isEmpty(searchText)) {
    payload = {
      ...payload,
      filters: [
        {
          field: searchField,
          values: [searchText],
          filterType: TEXT_STARTS_WITH,
        },
      ],
    };
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      ...payload,
      nextPageToken,
    };
  }
  return payload;
});

const getModifiedTime = defaultMemoize((epochTime) => getFormattedDateTime(epochTime, DATE_TIME_FORMATS.NATIVE_DATE_FORMAT));

const getModalFormSubmitPayload = defaultMemoize((assignedUserGroupList, assignedUserList, recordSharingRuleFormValues) => {
  const assignedUserIds = _map(assignedUserList, 'id');
  const assignedUserGroupNames = _map(assignedUserGroupList, (group) => `grp#${_get(group, ENTITY_RECORD_FIELD_IDS.RECORD_GROUP_NAME)}`);
  const combinedIds = [...assignedUserIds, ...assignedUserGroupNames];
  const recordGroupName = getArraySafeValue(_get(recordSharingRuleFormValues, FIELD_IDS.ENTITY_RECORD_GROUP, []));
  _set(recordSharingRuleFormValues, FIELD_IDS.ENTITY_RECORD_GROUP, recordGroupName);
  const payload = {
    ...recordSharingRuleFormValues,
    sharingSettings: [
      {
        principalIdentifiers: combinedIds,
        principalType: PRINCIPAL_TYPES.USER,
      },
    ],
  };
  return payload;
});

const isRecordGroupName = defaultMemoize((id) => _startsWith(id, 'grp#'));
const getRecordGroupName = defaultMemoize((id) => id.substring(4));

export { getTableProps, getSearchPayload, getModifiedTime, getModalFormSubmitPayload, isRecordGroupName, getRecordGroupName };
