import FIELD_TYPES from '../../../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../../../constants/fieldDefinition.dataTypes';
import FIELD_IDS from './approvalSettingFieldForm.fieldIds';

const FIELD_FORM_CONTEXT_ID = 'fieldDetailsForm';

const FIELD_TYPES_LABELS = {
  [FIELD_TYPES.TEXT]: __('Input'),
  [FIELD_TYPES.LIST]: __('List'),
  [FIELD_TYPES.RANGE]: __('Range'),
  [FIELD_TYPES.SELECT]: __('Select'),
  [FIELD_TYPES.RICH_TEXT_EDITOR]: __('Text Editor'),
  [FIELD_TYPES.MEDIA]: __('Media'),
};

const DATA_TYPES_LABELS = {
  [DATA_TYPES.TEXT]: __('Text'),
  [DATA_TYPES.NUMBER]: __('Number'),
  [DATA_TYPES.DATE]: __('Date'),
  [DATA_TYPES.BOOLEAN]: __('Boolean'),
};

const FIELD_TYPE_OPTIONS = [
  { label: FIELD_TYPES_LABELS[FIELD_TYPES.TEXT], value: FIELD_TYPES.TEXT },
  { label: FIELD_TYPES_LABELS[FIELD_TYPES.RANGE], value: FIELD_TYPES.RANGE },
  { label: FIELD_TYPES_LABELS[FIELD_TYPES.SELECT], value: FIELD_TYPES.SELECT },
];

const FIELD_TYPE_OPTIONS_TEXT = [
  { label: FIELD_TYPES_LABELS[FIELD_TYPES.RICH_TEXT_EDITOR], value: FIELD_TYPES.RICH_TEXT_EDITOR },
  { label: FIELD_TYPES_LABELS[FIELD_TYPES.MEDIA], value: FIELD_TYPES.MEDIA },
];

const DATA_TYPE_OPTIONS = [
  { label: DATA_TYPES_LABELS[DATA_TYPES.TEXT], value: DATA_TYPES.TEXT },
  { label: DATA_TYPES_LABELS[DATA_TYPES.NUMBER], value: DATA_TYPES.NUMBER },
  { label: DATA_TYPES_LABELS[DATA_TYPES.DATE], value: DATA_TYPES.DATE },
  { label: DATA_TYPES_LABELS[DATA_TYPES.BOOLEAN], value: DATA_TYPES.BOOLEAN },
];

const DETAILS_FIELD_IDS = [FIELD_IDS.NAME, FIELD_IDS.DISPLAY_NAME, FIELD_IDS.FIELD_TYPE, FIELD_IDS.DATA_TYPE];

const ERROR_IDS = {
  INPUT_TABLE_EMPTY_VALUE: 'inputTableEmptyValue',
  INPUT_TABLE_STRING_LENGTH: 'inputTableStringLength',
  DEPENDENCY_TABLE_EMPTY_VALUE: 'dependencyTableEmptyValue',
  CONTROLLING_OPTIONS_TABLE_EMPTY_VALUE: 'controllingOptionTableEmptyValue',
};

const ERROR_MESSAGES = {
  [ERROR_IDS.INPUT_TABLE_EMPTY_VALUE]: __('Please enter both Label and Value for dropdown options in the table.'),
  [ERROR_IDS.DEPENDENCY_TABLE_EMPTY_VALUE]: __('Please select both field and dependencyType from DependencyConfig Table or delete the row.'),
  [ERROR_IDS.CONTROLLING_OPTIONS_TABLE_EMPTY_VALUE]: __('Please select both controllingField and ControllingOptions in Controlling Options Table.'),
  [ERROR_IDS.INPUT_TABLE_STRING_LENGTH]: __('Length of Value cannot be more than 256 in the Table'),
};

const SECTION_IDS = {
  NUMBER_SECTION: 'NUMBER_SECTION',
  TEXT_SECTION: 'TEXT_SECTION',
  RANGE_SECTION: 'RANGE_SECTION',
  LIST_INPUT_SECTION: 'LIST_INPUT_SECTION',
  FIELD_DETAILS_SECTION: 'FIELD_DETAILS_SECTION',
};

const FIELD_DETAILS_FIELD_IDS = [FIELD_IDS.MANDATORY];

const CONSTRAINTS_TYPES = {
  STRING_LENGTH: 'STRING_LENGTH',
  REGEX: 'REGEX',
  NUMBER_RANGE: 'NUMBER_RANGE',
  RANGE_CONSTRAINT: 'RANGE',
  DATE_RANGE: 'DATE_RANGE',
};

const COLUMNS = {
  FIELD_NAME: 'fieldName',
  DEPENDENCY_TYPE: 'dependencyType',
  OPTION: 'option',
  VALUES: 'values',
};

export {
  FIELD_FORM_CONTEXT_ID,
  FIELD_TYPES_LABELS,
  DATA_TYPES_LABELS,
  FIELD_TYPE_OPTIONS,
  DATA_TYPE_OPTIONS,
  DETAILS_FIELD_IDS,
  ERROR_IDS,
  ERROR_MESSAGES,
  SECTION_IDS,
  FIELD_DETAILS_FIELD_IDS,
  CONSTRAINTS_TYPES,
  COLUMNS,
  FIELD_TYPE_OPTIONS_TEXT,
};
