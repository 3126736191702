import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import NullComponent from '@tekion/tekion-components/atoms/nullComponent/NullComponent';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { Draggable } from '@tekion/tekion-components/organisms/dragAndDrop';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Ellipsis from '@tekion/tekion-components/atoms/Ellipsis';
import Button from '@tekion/tekion-components/atoms/Button';
import Content from '@tekion/tekion-components/atoms/Content';

import ACTION_TYPES from '../../constants/applicationBuilder.actionTypes';
import { TAB_IDS } from '../../constants/applicationBuilder.constants';

import styles from '../../applicationBuilder.module.scss';

const EntityTabHeader = ({ index, selectedTabId, error, tabInfo, onAction }) => {
  const tabId = _get(tabInfo, 'id', EMPTY_STRING);
  const tabHeader = _get(tabInfo, 'displayName', EMPTY_STRING);

  const handleDeleteTab = useCallback(
    (event) => {
      event?.stopPropagation();
      onAction({ type: ACTION_TYPES.DELETE_TAB, payload: { tabInfo } });
    },
    [onAction, tabInfo],
  );

  const handleAddTab = useCallback(() => {
    onAction({ type: ACTION_TYPES.ADD_NEW_TAB });
  }, [onAction]);

  const renderHomeIcon = useCallback(() => {
    if (index === 1)
      return (
        <FontIcon className={styles.homeIcon} size={SIZES.S}>
          icon-home1
        </FontIcon>
      );
    return null;
  }, [index]);

  const renderErrorIcon = useCallback(
    () => (
      <PropertyControlledComponent controllerProperty={!_isEmpty(error)} fallback={<NullComponent />}>
        <FontIcon className={styles.errorInfoIcon}>icon-info</FontIcon>
      </PropertyControlledComponent>
    ),
    [error],
  );

  const renderTabHeaderContent = useCallback(
    () => (
      <Content className={styles.contentStyle}>
        <Ellipsis length={15} tooltip>
          {tabHeader}
        </Ellipsis>
      </Content>
    ),

    [tabHeader],
  );

  const renderTabsHeader = (provided) => {
    if (selectedTabId === tabId) {
      return (
        <div className={styles.draggableContainer} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
          {tabId !== TAB_IDS.APPROVAL && (
            <FontIcon size={SIZES.S} className={styles.dragIcon}>
              icon-drag-and-drop
            </FontIcon>
          )}
          <div className={styles.textContent}>
            {renderTabHeaderContent()}
            {renderErrorIcon()}
          </div>
          <div className="flex">
            {index === 1 ? (
              <FontIcon size={SIZES.S}>icon-home1</FontIcon>
            ) : (
              tabId !== TAB_IDS.APPROVAL && (
                <FontIcon size={SIZES.S} onClick={handleDeleteTab}>
                  icon-trash
                </FontIcon>
              )
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.draggableContainerWithoutIcon} ref={provided.innerRef} {...provided.draggableProps}>
          {renderHomeIcon()}
          {renderTabHeaderContent()}
          {renderErrorIcon()}
        </div>
      );
    }
  };

  if (index === 0) {
    return (
      <Button view={Button.VIEW.TERTIARY} className={styles.button} onClick={handleAddTab}>
        <FontIcon className={styles.addIcon}>icon-add-circle</FontIcon>
        {__('Add New Tab')}
      </Button>
    );
  }
  return (
    <Draggable key={tabId} draggableId={tabId} index={index}>
      {(provided) => renderTabsHeader(provided)}
    </Draggable>
  );
};
EntityTabHeader.propTypes = {
  index: PropTypes.number.isRequired,
  selectedTabId: PropTypes.string,
  tabInfo: PropTypes.object,
  error: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

EntityTabHeader.defaultProps = {
  selectedTabId: EMPTY_STRING,
  tabInfo: EMPTY_OBJECT,
  error: EMPTY_OBJECT,
};

export default EntityTabHeader;
