const DELAY_TASK_MODAL_FIELD_IDS = {
  EXECUTION_DELAY: 'executionDelay',
  EXPECTED_EVENT_TYPE: 'expectedEventType',
  EXIT_EVENT_TYPE: 'exitEventType',
  ID: 'id',
  EVENT_TYPE: 'eventType',
  ENTITY_NAME: 'entityName',
  DISPLAY_NAME: 'displayName',
  CONDITION: 'condition',
  WORKFLOW: 'workflowId',
};

export default DELAY_TASK_MODAL_FIELD_IDS;
