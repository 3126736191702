import React, { useMemo } from 'react';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import { getDropDownAttributes } from '../helpers/fontColorToolbarButton.general';

function useDropdownContent(showDropdown) {
  const { dropdownIconColor, dropDownIcon } = getDropDownAttributes(showDropdown);

  const dropdownContent = useMemo(
    () => (
      <div className="flex align-items-center">
        <FontIcon className="p-r-4">icon-format-color-text</FontIcon>
        <FontIcon size={SIZES.S} color={dropdownIconColor}>
          {dropDownIcon}
        </FontIcon>
      </div>
    ),
    [dropdownIconColor, dropDownIcon],
  );

  return dropdownContent;
}

export default useDropdownContent;
