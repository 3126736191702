import _isFunction from 'lodash/isFunction';
import { tget } from '@tekion/tekion-base/utils/general';

// Constants
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../constants/actionBuilder.constants';
import { TASK_DEF_IDS } from '../constants/workflow.constants';

const conditionDisplayNameGetter = (nodeDetails) => {
  const taskDefId = tget(nodeDetails, 'taskDefId');
  switch (taskDefId) {
    case TASK_DEF_IDS.IF:
      return __('If');
    case TASK_DEF_IDS.IF_ELSE:
      return __('If/else');

    case TASK_DEF_IDS.CHECK_FIELD:
      return __('Check Field Value');
    default: // would not enter default case, adding because of eslint.
      return '';
  }
};

const actionDisplayNameGetter = (nodeDetails) => {
  const actionNameGivenByUser = tget(nodeDetails, `userData.data.${ACTION_DEFINITION_FIELD_IDS.ACTION_NAME}`);

  return actionNameGivenByUser;
};

const nodeDisplayNameGetterByTaskDefId = {
  [TASK_DEF_IDS.IF]: conditionDisplayNameGetter,
  [TASK_DEF_IDS.IF_ELSE]: conditionDisplayNameGetter,
  [TASK_DEF_IDS.CREATE_RECORD]: actionDisplayNameGetter,
  [TASK_DEF_IDS.UPDATE_RECORD]: actionDisplayNameGetter,
  [TASK_DEF_IDS.UPSERT_RECORD]: actionDisplayNameGetter,
  [TASK_DEF_IDS.GET_RECORDS]: actionDisplayNameGetter,
  [TASK_DEF_IDS.CALLOUT]: actionDisplayNameGetter,
  [TASK_DEF_IDS.FETCH_AND_ITERATE]: actionDisplayNameGetter,
  [TASK_DEF_IDS.FOR_LOOP]: actionDisplayNameGetter,
};

const getNodeDisplayName = (nodeDetails) => {
  const taskDefId = tget(nodeDetails, 'taskDefId');
  const displayNameGetter = nodeDisplayNameGetterByTaskDefId[taskDefId];

  if (_isFunction(displayNameGetter)) {
    return displayNameGetter(nodeDetails);
  }
  return nodeDetails?.displayName;
};

export default getNodeDisplayName;
