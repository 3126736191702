import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Tekion components
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Drawer, { PLACEMENTS } from '@tekion/tekion-components/molecules/drawer';
import Switch from '@tekion/tekion-components/molecules/Switch';
import Tabs from '@tekion/tekion-components/molecules/Tabs';
import NotificationTab from './organisms/notificationTab';

// Constants
import { NOTIFICATION_DRAWER_WIDTH, NOTIFICATION_DRAWER_TAB_IDS } from './constants/notificationDrawer.constants';

import styles from './notificationDrawer.module.scss';

const MARGIN_TOP = '6.4rem';
const DRAWER_STYLE = { marginTop: MARGIN_TOP, height: `calc(100% - ${MARGIN_TOP})` };
const DRAWER_BODY_STYLE = { padding: 0, height: '100%' };

const NotificationDrawer = ({ onClose }) => {
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [currentTab, setCurrentTab] = useState(NOTIFICATION_DRAWER_TAB_IDS.PRIMARY);

  const toggleShowUnreadOnly = () => setShowUnreadOnly((_showUnreadOnly) => !_showUnreadOnly);

  const handleTabClick = (clickedTabId) => setCurrentTab(clickedTabId);

  return (
    <Drawer
      visible
      destroyOnClose
      closable={false}
      title={null}
      placement={PLACEMENTS.RIGHT}
      width={NOTIFICATION_DRAWER_WIDTH}
      style={DRAWER_STYLE}
      bodyStyle={DRAWER_BODY_STYLE}
      className={styles.drawer}
      onClose={onClose}
    >
      <div className={styles.header}>
        <Heading>{__('Notification Centre')}</Heading>
        <div className="flex">
          <span className="p-r-4">{__('Unread only')}</span>
          <Switch checked={showUnreadOnly} onClick={toggleShowUnreadOnly} />
          {/** Setting icon */}
          <FontIcon onClick={onClose} className={styles.closeIcon}>
            icon-cross
          </FontIcon>
        </div>
      </div>
      <Tabs className={styles.tabs} animated activeKey={currentTab} tabBarStyle={EMPTY_OBJECT} onTabClick={handleTabClick}>
        <Tabs.TabPane id={NOTIFICATION_DRAWER_TAB_IDS.PRIMARY} key={NOTIFICATION_DRAWER_TAB_IDS.PRIMARY} tab={__('Primary')}>
          <NotificationTab showUnreadOnly={showUnreadOnly} />
        </Tabs.TabPane>
        {/* <Tabs.TabPane id={NOTIFICATION_DRAWER_TAB_IDS.SYSTEM_ALERTS} key={NOTIFICATION_DRAWER_TAB_IDS.SYSTEM_ALERTS} tab={__('System Alerts')}>
          <div>Alerts</div>
        </Tabs.TabPane> */}
      </Tabs>
    </Drawer>
  );
};

NotificationDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default NotificationDrawer;
