import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import Heading from '@tekion/tekion-components/atoms/Heading';
import Button from '@tekion/tekion-components/atoms/Button';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Loader from '@tekion/tekion-components/molecules/loader';
import Tabs from '@tekion/tekion-components/molecules/Tabs';

import ApprovalTasksList from '../../../../../organisms/approvalManagementPages/approvalTasksList/ApprovalTasksList';
import ApprovalRequestsList from '../../../../../organisms/approvalManagementPages/approvalRequestsList/ApprovalRequestsList';

// Actions
import { fetchGroups, fetchCategories } from '../../../../../actions/approvalManagement.actions';

// Constants
import { APPROVAL_CENTRE_FIELD_IDS, APPROVAL_METADATA_PROPERTY_IDS } from '../../../../../constants/approvalCentre.constants';
import PAGE_IDS from '../../../constants/pageIds.constants';
import { TAB_IDS } from './constants/approvalManagement.constants';
import { DEFAULT_OPTIONS } from '../../../../../connectors/withSize';

// Styles
import styles from './approvalManagement.module.scss';

const optionGenerator = (response, getter) => {
  if (_isEmpty(response) || _isEmpty(getter)) return [];

  const { hits = [] } = response;

  return _map(hits, (item) => ({
    label: tget(item, getter),
    value: tget(item, getter),
  }));
};

const ApprovalManagement = ({
  isMountedInsideApplication,
  contentHeight,
  contentWidth,
  appRoute,
  match,
  history,
  applicationProperties,
  currentLoggedInUserData,
}) => {
  const approvalTabId = tget(match, 'params.approvalTabId');
  const hideCreateBtn = !tget(applicationProperties, APPROVAL_METADATA_PROPERTY_IDS.ENABLE_CREATION, true);

  const [isMetadataLoading, setIsMetadataLoading] = useState(false);
  const [metadata, setMetadata] = useState({ GROUPS: [], CATEGORIES: [] });
  const [selectedTabId, setSelectedTabId] = useState();

  const tabStyles = useMemo(() => {
    if (contentWidth > 0) {
      return { width: contentWidth };
    }
    return {};
  }, [contentWidth]);

  const handleTabChange = (tabKey) => history.push(tabKey);

  const handleCreateClick = () => history.push(`/${PAGE_IDS.APPROVAL_CENTRE}/${PAGE_IDS.CREATE_REQUEST}`);

  const fetchMetadata = useCallback(async () => {
    setIsMetadataLoading(true);

    const groupsResponse = await fetchGroups({ rows: 5000 });
    const categoriesResponse = await fetchCategories({ rows: 5000 });

    setMetadata({
      GROUPS: optionGenerator(groupsResponse, APPROVAL_CENTRE_FIELD_IDS.GROUP),
      CATEGORIES: optionGenerator(categoriesResponse, APPROVAL_CENTRE_FIELD_IDS.CATEGORY),
    });
    setIsMetadataLoading(false);
  }, []);

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  useEffect(() => {
    if (_isEmpty(approvalTabId)) {
      history.push(`${match.url}/${TAB_IDS.APPROVALS}`);
    } else {
      setSelectedTabId(approvalTabId);
    }
  }, [approvalTabId, history, match.url]);

  if (isMetadataLoading) {
    return <Loader id="APPROVAL_CENTRE" />;
  }

  return (
    <Page>
      <Page.Header contentClassName={styles.headerContainer}>
        <Heading>{__('Approval Centre')}</Heading>
        {!hideCreateBtn && (
          <Button view={Button.VIEW.PRIMARY} onClick={handleCreateClick}>
            {__('Create new request')}
          </Button>
        )}
      </Page.Header>
      <Tabs style={tabStyles} activeKey={selectedTabId} onChange={handleTabChange}>
        <Tabs.TabPane key={TAB_IDS.APPROVALS} tab={__('Approvals')}>
          <ApprovalTasksList
            isMountedInsideApplication={isMountedInsideApplication}
            contentHeight={contentHeight - DEFAULT_OPTIONS.headerHeight}
            appRoute={appRoute}
            currentLoggedInUserData={currentLoggedInUserData}
            filterMetadata={metadata}
            applicationProperties={applicationProperties}
            history={history}
            match={match}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key={TAB_IDS.REQUESTS} tab={__('Requests')}>
          <ApprovalRequestsList
            isMountedInsideApplication={isMountedInsideApplication}
            contentHeight={contentHeight - DEFAULT_OPTIONS.headerHeight}
            appRoute={appRoute}
            currentLoggedInUserData={currentLoggedInUserData}
            filterMetadata={metadata}
            applicationProperties={applicationProperties}
            history={history}
            match={match}
          />
        </Tabs.TabPane>
      </Tabs>
    </Page>
  );
};

ApprovalManagement.propTypes = {
  isMountedInsideApplication: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  contentWidth: PropTypes.number.isRequired,
  appRoute: PropTypes.string.isRequired,
  currentLoggedInUserData: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  applicationProperties: PropTypes.object,
};

ApprovalManagement.defaultProps = {
  isMountedInsideApplication: false,
  currentLoggedInUserData: EMPTY_OBJECT,
  applicationProperties: EMPTY_OBJECT,
};

export default withRouter(ApprovalManagement);
