import React from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import NullComponent from '@tekion/tekion-components/atoms/nullComponent/NullComponent';
import PageViewer from '../../../../../organisms/visualBuilder/organisms/pageViewer/PageViewer';

import styles from '../applicationRenderer.module.scss';

const ApplicationPageRenderer = ({
  isNavigationRequired,
  contentHeight,
  contentWidth,
  currentPageName,
  tabId,
  selectedTabId,
  pageConfigByName,
  history,
  applicationContext,
}) => {
  const config = _get(pageConfigByName, currentPageName);

  // We render pageViewer only when we will have page config
  // key is added to remount  page viewer on page config change
  if (!isNavigationRequired || (!_isEmpty(config) && tabId === selectedTabId)) {
    return (
      <div className={styles.pageViewer} style={{ height: contentHeight }}>
        <PageViewer
          isPreviewMode
          contentWidth={contentWidth}
          contentHeight={contentHeight}
          pageConfiguration={config}
          history={history}
          applicationContext={applicationContext}
          key={tget(config, 'id', EMPTY_STRING)}
        />
      </div>
    );
  }

  return NullComponent;
};

ApplicationPageRenderer.propTypes = {
  isNavigationRequired: PropTypes.bool,
  contentWidth: PropTypes.number.isRequired,
  contentHeight: PropTypes.number.isRequired,
  currentPageName: PropTypes.string.isRequired,
  tabId: PropTypes.string.isRequired,
  selectedTabId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  applicationContext: PropTypes.object.isRequired,
  pageConfigByName: PropTypes.object,
};

ApplicationPageRenderer.defaultProps = {
  isNavigationRequired: true,
  pageConfigByName: EMPTY_OBJECT,
};

export default ApplicationPageRenderer;
