import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _valuesIn from 'lodash/valuesIn';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import SwitchRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch/Switch';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import ListInputTable from '../components/listInputTable';
import ControllingOptionConfigTable from '../components/controllingOptionConfigTable/ControllingOptionConfigTable';

import { getControllingFieldOptions, getValuesOptions } from './selectOptionsForm.general.helpers';
import { mergeRenderOptions, inputTableColumnRequiredValidator, inputTableUniqueColumnValidator } from '../../../helpers/fieldsForm.general.helpers';

import SELECT_OPTIONS_FORM_FIELD_IDS from '../constants/selectOptionsForm.fieldsIds';
import { COLUMN_IDS } from '../components/listInputTable/constants/listInputTable.constants';
import { CONTROLLING_OPTION_TABLE_COLUMN_IDS } from '../components/controllingOptionConfigTable/constants/controllingOptionConfigTable.general';

import styles from '../../../fieldsForm.module.scss';

const OPTIONS_FIELD = {
  id: SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS,
  renderer: ListInputTable,
  renderOptions: {
    required: true,
    validators: [inputTableColumnRequiredValidator([COLUMN_IDS.LABEL, COLUMN_IDS.VALUE]), inputTableUniqueColumnValidator(COLUMN_IDS.VALUE)],
  },
};

const CONTROLLING_FIELD_ENABLED_FIELD = {
  id: SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD_ENABLED,
  renderer: SwitchRenderer,
  renderOptions: {
    label: __('Controlling Field Settings'),
    defaultValue: false,
    helpText: __('Are the options controlled by the selected option of another field.'),
    infoBadgeClassName: styles.controllingSettingsInfoIcon,
  },
};

const CONTROLLING_FIELD = {
  id: SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Controlling Field'),
    label: __('Controlling field'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const CONTROLLED_OPTIONS_FIELD = {
  id: SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS,
  renderer: ControllingOptionConfigTable,
  renderOptions: {
    required: true,
    validators: [inputTableColumnRequiredValidator([CONTROLLING_OPTION_TABLE_COLUMN_IDS.OPTION, CONTROLLING_OPTION_TABLE_COLUMN_IDS.VALUES])],
  },
};

const SELECT_OPTIONS_FORM_FIELDS = {
  [SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS]: OPTIONS_FIELD,
  [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD_ENABLED]: CONTROLLING_FIELD_ENABLED_FIELD,
  [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD]: CONTROLLING_FIELD,
  [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS]: CONTROLLED_OPTIONS_FIELD,
};

const getFields = (entity, values, isModalVisible, modalData, renderOptions) => {
  const controllingField = _get(values, SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD);
  const options = _get(values, SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS, EMPTY_ARRAY);
  const filteredOptions = _filter(options, ({ [COLUMN_IDS.LABEL]: label, [COLUMN_IDS.VALUE]: value }) => !_isEmpty(label) && !_isEmpty(value));

  const controlledOptionsOptions = _map(filteredOptions, (option) => ({
    label: getArraySafeValue(_get(option, COLUMN_IDS.LABEL)),
    value: getArraySafeValue(_get(option, COLUMN_IDS.VALUE)),
  }));

  const defaultRenderOptions = {
    [SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS]: [
      { path: 'isModalVisible', value: isModalVisible },
      { path: 'modalData', value: modalData },
    ],
    [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLING_FIELD]: [{ path: 'options', value: getControllingFieldOptions(entity) }],
    [SELECT_OPTIONS_FORM_FIELD_IDS.CONTROLLED_OPTIONS]: [
      {
        path: 'options',
        value: controlledOptionsOptions,
      },
      {
        path: 'valuesOptions',
        value: getValuesOptions(entity, controllingField),
      },
    ],
  };

  const mergedRenderOptions = mergeRenderOptions(defaultRenderOptions, _valuesIn(SELECT_OPTIONS_FORM_FIELD_IDS), renderOptions);

  return _reduce(
    _valuesIn(SELECT_OPTIONS_FORM_FIELD_IDS),
    (fields, fieldId) => _set(fields, fieldId, addToRenderOptions(_get(SELECT_OPTIONS_FORM_FIELDS, fieldId), _get(mergedRenderOptions, fieldId, []))),
    {},
  );
};

export default getFields;
