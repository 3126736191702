import _first from 'lodash/first';
import _split from 'lodash/split';
import _drop from 'lodash/drop';
import _forEach from 'lodash/forEach';

import { tget } from '@tekion/tekion-base/utils/general';

import FIELD_TYPES from '../../../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../../../constants/fieldDefinition.dataTypes';

import fieldDefinitionReader from '../../../../../../../readers/fieldDefinition.reader';

const getFieldDefForLastSelectedField = (selectedOption, conditionBuilderFieldDefinitionObject, entityName) => {
  const allSelectedFields = _split(selectedOption);
  let fieldDefinitionsForSelectedEntity = tget(conditionBuilderFieldDefinitionObject, entityName, {});
  let selectedFieldDef = {};
  _forEach(allSelectedFields, (selectedField) => {
    selectedFieldDef = tget(fieldDefinitionsForSelectedEntity, selectedField);
    if (fieldDefinitionReader.fieldType(selectedFieldDef) === FIELD_TYPES.RELATIONSHIP) {
      fieldDefinitionsForSelectedEntity = tget(conditionBuilderFieldDefinitionObject, fieldDefinitionReader.lookupFieldEntityType(selectedFieldDef));
    } else if (fieldDefinitionReader.dataType(selectedFieldDef) === DATA_TYPES.COMPLEX) {
      fieldDefinitionsForSelectedEntity = tget(
        conditionBuilderFieldDefinitionObject,
        fieldDefinitionReader.complexFieldDefinitionEntityName(selectedFieldDef),
      );
    }
  });
  return selectedFieldDef;
};

const isLastFieldMultiValuedValidatorRule = (conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName) => (fieldId, valueToTest) => {
  const selectedVariable = _first(_split(valueToTest, '.'));
  const entityName = tget(mapOfVariableToEntityName, selectedVariable);
  const selectedOption = _drop(_split(valueToTest, '.'));

  const selectedFieldDef = getFieldDefForLastSelectedField(selectedOption, conditionBuilderFieldDefinitionObject, entityName);
  const isSelectedFieldMultiValued = fieldDefinitionReader.multiValued(selectedFieldDef);

  return isSelectedFieldMultiValued
    ? { isValid: isSelectedFieldMultiValued }
    : { isValid: isSelectedFieldMultiValued, message: 'Please select multi valued field only' };
};

export { getFieldDefForLastSelectedField };
export default isLastFieldMultiValuedValidatorRule;
