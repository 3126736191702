import AccessTypeRenderer from '../atoms/accessTypeRenderer';
import DurationRenderer from '../atoms/durationRenderer';
import ActionRenderer from '../atoms/actionRenderer';
import UserNameWithWarningRenderer from '../atoms/userNameWithWarningRenderer';

import { COLUMN_IDS } from '../manageRecordAccessTable.constants';

import { ShareAndManageRecordUserDetailGetters } from '../../../../../../../hooks/useShareAndManageRecordModal/shareAndManageRecordUserData';

const COLUMNS = {
  [COLUMN_IDS.USER_NAME]: {
    id: COLUMN_IDS.USER_NAME,
    Header: __('User Name'),
    Cell: UserNameWithWarningRenderer,
    accessor: ShareAndManageRecordUserDetailGetters.NAME,
  },
  [COLUMN_IDS.ACCESS_TYPE]: {
    id: COLUMN_IDS.ACCESS_TYPE,
    Header: __('Access Type'),
    Cell: AccessTypeRenderer,
    accessor: ShareAndManageRecordUserDetailGetters.recordAccessType,
  },
  [COLUMN_IDS.EXPIRY_TIME]: {
    id: COLUMN_IDS.EXPIRY_TIME,
    Header: __('Duration'),
    Cell: DurationRenderer,
    accessor: ShareAndManageRecordUserDetailGetters.expiryTime,
  },
  [COLUMN_IDS.ACTION]: {
    id: COLUMN_IDS.ACTION,
    Header: __('Action'),
    Cell: ActionRenderer,
    accessor: COLUMN_IDS.ACTION,
    width: 75,
  },
};

const getColumns = ({
  isSelectedAccessTypeFilterNotOfAccessDenied,
  wwdAccessTypesOptions,
  onUpdateShareAndManageRecordUserDetails,
  onRemoveShareAndManageRecordUserDetails,
}) => {
  const columns = [];

  columns.push({ ...COLUMNS[COLUMN_IDS.USER_NAME], getProps: () => ({ isSelectedAccessTypeFilterNotOfAccessDenied }) });
  columns.push({
    ...COLUMNS[COLUMN_IDS.ACCESS_TYPE],
    getProps: () => ({ isSelectedAccessTypeFilterNotOfAccessDenied, wwdAccessTypesOptions, onValueChange: onUpdateShareAndManageRecordUserDetails }),
  });
  columns.push({ ...COLUMNS[COLUMN_IDS.EXPIRY_TIME], getProps: () => ({ onValueChange: onUpdateShareAndManageRecordUserDetails }) });
  columns.push({ ...COLUMNS[COLUMN_IDS.ACTION], getProps: () => ({ onDeleteClick: onRemoveShareAndManageRecordUserDetails }) });

  return columns;
};

export { getColumns };
