import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/governance';

const fetchWwdPermissionList = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/wwd/search`, payload);
const editWWDPermissionData = (entityName, payload = EMPTY_OBJECT) => ApiService.put(`${BASE_URL}/wwd/entityName/${entityName}`, payload);

export default {
  fetchWwdPermissionList,
  editWWDPermissionData,
};
