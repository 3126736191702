/* eslint-disable default-param-last */
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { COMMENT_FIELDS } from '../constants/commentRenderer.constants';

const getPayloadForUsers = (userIds = EMPTY_ARRAY) => ({
  filters: [{ field: 'id', filterType: OPERATORS.IN, values: userIds }],
});

const getPayloadForComments = (assetType = EMPTY_STRING, assetId = EMPTY_STRING, inReplyTo = EMPTY_ARRAY, nextPageToken, rows) => {
  const filters = [{ field: COMMENT_FIELDS.DELETED, filterType: OPERATORS.IN, values: [false] }];

  if (!_isEmpty(assetType)) {
    filters.push({ field: COMMENT_FIELDS.ASSET_TYPE, filterType: OPERATORS.IN, values: [assetType] });
  }
  if (!_isEmpty(assetId)) {
    filters.push({ field: COMMENT_FIELDS.ASSET_ID, filterType: OPERATORS.IN, values: [assetId] });
  }
  if (!_isEmpty(inReplyTo)) {
    filters.push({ field: COMMENT_FIELDS.IN_REPLY_TO, filterType: OPERATORS.IN, values: inReplyTo });
  }
  return _isEmpty(nextPageToken) ? { filters, rows, countRequired: true } : { filters, nextPageToken, rows, countRequired: true };
};

export { getPayloadForUsers, getPayloadForComments };
