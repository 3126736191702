import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _isNil from 'lodash/isNil';
import _trim from 'lodash/trim';

import { getPlainTextFromRTEJSON } from '../../richTextEditor';

import { COMMENT_FIELDS, COMMENT_MODES, REQUIRED_USER_DETAIL_FIELDS } from '../constants/commentRenderer.constants';

import commentReader from '../../../readers/comment.reader';

const findCommentObjAndIndexById = (commentList, commentId) => {
  const index = _findIndex(commentList, (commentData) => commentReader.id(commentData) === commentId);
  let comment = commentList[index] || {};
  comment = _cloneDeep(comment);
  return { index, comment };
};

const findCommentById = (commentList, commentId) => _find(commentList, (commentData) => commentReader.id(commentData) === commentId);

const filterCommentById = (commentList, commentId) => _filter(commentList, (commentData) => commentReader.id(commentData) !== commentId);

const setRepliesInCommentsTree = (commentId, commentsTree, repliesList, nextPageToken = null, newNextPageToken = null) => {
  const newCommentsTree = commentsTree;
  const { index: commentIndex, comment } = findCommentObjAndIndexById(commentsTree, commentId);
  const prevReplies = commentReader.replies(comment) || [];
  let newReplies = repliesList;

  if (nextPageToken || _isEmpty(repliesList)) {
    newReplies = [...prevReplies, ...repliesList];
  }

  _set(newCommentsTree, [commentIndex, COMMENT_FIELDS.REPLIES], newReplies);
  _set(newCommentsTree, [commentIndex, COMMENT_FIELDS.SHOW_REPLIES_ENABLED], true);
  _set(newCommentsTree, [commentIndex, COMMENT_FIELDS.SHOW_REPLY_INPUT], false);
  _set(newCommentsTree, [commentIndex, 'nextPageToken'], newNextPageToken);

  return newCommentsTree;
};

const removeCommentFromCommentsTree = (commentsTree, commentId, inReplyTo) => {
  let newCommentsTree = _cloneDeep(commentsTree);

  if (_isEmpty(inReplyTo)) {
    newCommentsTree = filterCommentById(newCommentsTree, commentId);
  } else {
    const comment = findCommentById(newCommentsTree, inReplyTo);
    let replies = commentReader.replies(comment) || [];
    replies = filterCommentById(replies, commentId);
    _set(comment, COMMENT_FIELDS.REPLIES, replies);
  }
  return newCommentsTree;
};

const updatedCommentModeInCommentsTree = (newCommentsTree) =>
  _map(newCommentsTree, (comment) => ({
    ...comment,
    [COMMENT_FIELDS.COMMENT_MODE]: COMMENT_MODES.VIEW,
  }));

const getRequiredUserDetails = (userDetails) => _map(userDetails, (userData) => _pick(userData, REQUIRED_USER_DETAIL_FIELDS));

const updateCommentMode = (commentsTree, commentIndex, parentCommentIndex, commentMode) => {
  const newCommentsTree = _cloneDeep(commentsTree);

  if (!_isNil(commentIndex) && !_isNil(parentCommentIndex)) {
    _set(newCommentsTree, [parentCommentIndex, COMMENT_FIELDS.REPLIES, commentIndex, COMMENT_FIELDS.COMMENT_MODE], commentMode);
  } else {
    _set(newCommentsTree, [commentIndex, COMMENT_FIELDS.COMMENT_MODE], commentMode);
  }
  return newCommentsTree;
};

const checkCommentContentIsValid = (commentContent) => {
  try {
    let isValid = true;

    if (_isEmpty(commentContent) || _trim(commentContent) === '') {
      isValid = false;
    }

    return isValid;
  } catch (error) {
    return false;
  }
};

const transformHtmlCommentToPlainText = (newCommentsTree) =>
  _map(newCommentsTree, (comment) => {
    let content = commentReader.content(comment);

    try {
      const jsonContent = JSON.parse(content);
      content = getPlainTextFromRTEJSON(jsonContent);
    } catch (error) {
      content = commentReader.content(comment);
    }

    return {
      ...comment,
      [COMMENT_FIELDS.CONTENT]: content,
      [COMMENT_FIELDS.COMMENT_MODE]: COMMENT_MODES.VIEW,
    };
  });

export {
  findCommentById,
  setRepliesInCommentsTree,
  removeCommentFromCommentsTree,
  updatedCommentModeInCommentsTree,
  getRequiredUserDetails,
  updateCommentMode,
  findCommentObjAndIndexById,
  checkCommentContentIsValid,
  transformHtmlCommentToPlainText,
};
