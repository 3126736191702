import Button from '@tekion/tekion-components/atoms/Button';
import { COLOR_MAP } from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer/StatusRenderer';

import ACTION_TYPES from './entityList.actionTypes';

const HEADER_PROPS = {
  label: __('Entities'),
  headerRightActions: [
    {
      renderer: Button,
      renderOptions: {
        view: Button.VIEW.PRIMARY,
        label: __('Create Entity'),
      },
      action: ACTION_TYPES.REDIRECT_TO_ENTITY_CREATION,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showHeader: true,
  showSearch: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const FIELD_STATUS = {
  CHECKED: true,
  UNCHECKED: false,
};

const FIELD_STATUS_COLOR_MAP = {
  [FIELD_STATUS.UNCHECKED]: COLOR_MAP.RED,
  [FIELD_STATUS.CHECKED]: COLOR_MAP.GREEN,
};

const FIELD_STATUS_LABEL_MAP = {
  [FIELD_STATUS.UNCHECKED]: __('No'),
  [FIELD_STATUS.CHECKED]: __('Yes'),
};

export { HEADER_PROPS, TABLE_MANAGER_PROPS, FIELD_STATUS, FIELD_STATUS_COLOR_MAP, FIELD_STATUS_LABEL_MAP };
