// Components
import Button from '@tekion/tekion-components/atoms/Button';

// Constants
import ACTION_TYPES from './entityRecordGroupList.actionTypes';

const HEADER_PROPS = {
  label: 'Record Group List',
};

const TABLE_MANAGER_PROPS = {
  showHeader: true,
  showSearch: true,
  showFilter: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create New Group'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_RECORD_GROUP,
    },
  ],
};

const EDIT_ACTION = {
  id: ACTION_TYPES.EDIT_RECORD_GROUP,
  name: __('Edit'),
};

export { HEADER_PROPS, SUB_HEADER_PROPS, TABLE_MANAGER_PROPS, EDIT_ACTION };
