import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import _size from 'lodash/size';
import _map from 'lodash/map';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import Heading from '@tekion/tekion-components/atoms/Heading';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Button from '@tekion/tekion-components/atoms/Button';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import { tget } from '@tekion/tekion-base/utils/general';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import TagsRenderer from '../../atoms/tagsRenderer/TagsRenderer';
import SortOptionsModal from './components/SortOptionsModal';

import ACTION_HANDLERS from './helpers/sortOptionsConfigurator.actionHandlers';
import { getAddedSortOptions, getSelectedSortableFieldOptions } from './helpers/sortOptionsConfigurator.helper';

import ACTION_TYPES from './constants/sortOptionsConfigurator.actionTypes';
import { SORT_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID, FIELD_IDS } from './constants/sortOptionsConfigurator.constants';

import styles from './sortOptionsConfigurator.module.scss';

const SortOptionsConfigurator = ({ isModalVisible, error, allFormValues, selectedViewComponentProperties, sortableFieldOptions, onAction }) => {
  const handleSubmit = useCallback(() => {
    triggerSubmit(SORT_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID);
  }, []);

  const handleCancelModal = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CANCEL_MODAL,
    });
  }, [onAction]);

  const handleConfigureClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CLICK_CONFIGURE_SORT_OPTIONS,
    });
  }, [onAction]);

  const selectedSortableFields = tget(allFormValues, [FIELD_IDS.SORTABLE_FIELDS], EMPTY_ARRAY);
  const selectedSortableFieldOptions = getSelectedSortableFieldOptions(sortableFieldOptions, selectedSortableFields);
  const selectedSortableFieldNames = useMemo(() => getAddedSortOptions(selectedSortableFieldOptions), [selectedSortableFieldOptions]);

  const defaultSortDetails = tget(allFormValues, [FIELD_IDS.DEFAULT_SORT_DETAILS], EMPTY_ARRAY);
  const selectedDefaultSortDetails = _map(defaultSortDetails, (sortDetail) => getArraySafeValue(tget(sortDetail, [FIELD_IDS.FIELD])));
  const selectedDefaultSortFieldOptions = getSelectedSortableFieldOptions(sortableFieldOptions, selectedDefaultSortDetails);
  const selectedDefaultSortFieldNames = useMemo(() => getAddedSortOptions(selectedDefaultSortFieldOptions), [selectedDefaultSortFieldOptions]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.ON_INIT,
    });
  }, [onAction]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE,
      payload: { selectedViewComponentProperties },
    });
  }, [onAction, selectedViewComponentProperties]);

  return (
    <div className={styles.sortOptionsHandlerComponent}>
      <div className={styles.sortOptionComponent}>
        <Heading size={5} className={styles.sortOptionsComponentHeading}>
          {__('Sort Options Configurator')}
        </Heading>
        <Button disable view={Button.VIEW.TERTIARY} className={styles.configureButton} onClick={handleConfigureClick}>
          {__('Configure')}
        </Button>
      </div>
      <div className={styles.addedSortOptionsContainer}>
        <div className={styles.addedSortOptionSection}>
          {__('Sortable Fields ')}({_size(selectedSortableFieldNames)})
          <PropertyControlledComponent controllerProperty={_size(selectedSortableFieldNames) > 0}>
            <TagsRenderer fieldValue={selectedSortableFieldNames} maxLimit={2} tagClassName={styles.tag} tagContentClassName={styles.tagContent} />
          </PropertyControlledComponent>
        </div>
      </div>
      <div className={styles.addedSortOptionsContainer}>
        <div className={styles.addedSortOptionSection}>
          {__('Default Sort Options ')}({_size(selectedDefaultSortFieldNames)})
          <PropertyControlledComponent controllerProperty={_size(selectedDefaultSortFieldNames) > 0}>
            <TagsRenderer fieldValue={selectedDefaultSortFieldNames} maxLimit={2} tagClassName={styles.tag} tagContentClassName={styles.tagContent} />
          </PropertyControlledComponent>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        width={SIZES.MD}
        bodyStyle={{ padding: 0 }}
        title={__('Sort Details for Table')}
        submitBtnText={__('Submit')}
        onSubmit={handleSubmit}
        onCancel={handleCancelModal}
      >
        <SortOptionsModal
          error={error}
          allFormValues={allFormValues}
          sortableFieldOptions={sortableFieldOptions}
          selectedSortableFieldOptions={selectedSortableFieldOptions}
          onAction={onAction}
        />
      </Modal>
    </div>
  );
};

SortOptionsConfigurator.propTypes = {
  isModalVisible: PropTypes.bool,
  error: PropTypes.object,
  selectedViewComponentProperties: PropTypes.object,
  allFormValues: PropTypes.array,
  sortableFieldOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

SortOptionsConfigurator.defaultProps = {
  isModalVisible: false,
  error: EMPTY_OBJECT,
  selectedViewComponentProperties: EMPTY_OBJECT,
  allFormValues: EMPTY_ARRAY,
  sortableFieldOptions: EMPTY_ARRAY,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(SortOptionsConfigurator);
