import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import _head from 'lodash/head';
import _find from 'lodash/find';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';

// Components
import Loader from '@tekion/tekion-components/molecules/loader';
import FieldValuePair from '../fieldValuePair/FieldValuePair';
import EntityViewViewer from '../../../../../../../../../organisms/viewBuilder/organisms/entityViewViewer/EntityViewViewer';

// Actions
import { fetchEntityViewConfigurationByName, searchEntityViewConfigurations } from '../../../../../../../../../actions/entityViewDefinitions.actions';

// Helpers
import { generateDetailViewDefsPayload, getResolvedEntityRecord } from './helpers';

// Constants
import {
  APPROVAL_CENTRE_FIELD_IDS,
  CUSTOM_ENTITY_REQUEST_FIELD_IDS,
  CUSTOM_ENTITY_ACTION_TYPE_LABEL,
  APPROVAL_METADATA_PROPERTY_IDS,
} from '../../../../../../../../../constants/approvalCentre.constants';

// Readers
import entityDefinitionReader from '../../../../../../../../../readers/entity.reader';

const CustomEntityRequest = ({
  isMountedInsideApplication,
  isEntityRecordResolving,
  rowClassName,
  approvalRequest,
  entityRecord,
  entityDef,
  applicationProperties,
}) => {
  const data = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.DATA, EMPTY_OBJECT);
  const actionType = tget(data, CUSTOM_ENTITY_REQUEST_FIELD_IDS.ACTION_TYPE);
  const record = tget(data, CUSTOM_ENTITY_REQUEST_FIELD_IDS.CUSTOM_ENTITY_REQUEST, EMPTY_OBJECT);

  const entityName = entityDefinitionReader.name(entityDef);

  const [isDetailViewDefLoading, setIsDetailViewDefLoading] = useState(true);
  const [detailViewDef, setDetailViewDef] = useState({});

  const fetchDetailViewDef = useCallback(async () => {
    if (isMountedInsideApplication) {
      const entitiesRenderingInfo = tget(applicationProperties, APPROVAL_METADATA_PROPERTY_IDS.ENTITIES_RENDERING_INFO, EMPTY_ARRAY);
      const currentEntityRenderingInfo = _find(entitiesRenderingInfo, { [APPROVAL_METADATA_PROPERTY_IDS.RENDERING_INFO_IDS.ENTITY]: entityName });
      const detailViewName = tget(currentEntityRenderingInfo, APPROVAL_METADATA_PROPERTY_IDS.RENDERING_INFO_IDS.DETAIL_VIEW);

      // NOTE: Here we can add Page support if required later.
      // Here fetching detail entiity view if present in applicationProperties.
      if (!_isEmpty(detailViewName)) {
        const _detailViewDef = await fetchEntityViewConfigurationByName(entityName, detailViewName);
        if (!_isEmpty(_detailViewDef)) {
          setDetailViewDef(_detailViewDef);
          setIsDetailViewDefLoading(false);
          return;
        }
      }
    }

    // If any of the above IF statements gave false, we will default to Standard detail view.
    // Currently fetching all detail entity views & rendering the topmost one.
    // TODO: Once standard entity views are done, integrate fetching the standard one here.
    const detailViewDefsPayload = generateDetailViewDefsPayload(entityName);
    const response = await searchEntityViewConfigurations(detailViewDefsPayload);
    const detailViewDefinitions = tget(response, 'hits', [{}]);

    setDetailViewDef(_head(detailViewDefinitions));
    setIsDetailViewDefLoading(false);
  }, [isMountedInsideApplication, entityName, applicationProperties]);

  const _entityRecord = useMemo(() => getResolvedEntityRecord(entityDef, entityRecord, record), [entityDef, entityRecord, record]);

  useEffect(() => {
    fetchDetailViewDef();
  }, [fetchDetailViewDef]);

  if (isDetailViewDefLoading) {
    return <Loader id="CUSTOM_ENTITY_REQUEST_VIEWER" />;
  }

  return (
    <>
      <div className={rowClassName}>
        <FieldValuePair label={__('Entity')} value={entityName} />
        <FieldValuePair label={__('Action type')} value={CUSTOM_ENTITY_ACTION_TYPE_LABEL[actionType] || NO_DATA} />
      </div>
      {isEntityRecordResolving ? (
        <Loader id="CUSTOM_ENTITY_REQUEST_VIEWER" />
      ) : (
        <EntityViewViewer
          isPreviewMode
          widgetId="CUSTOM_ENTITY_REQUEST_VIEWER"
          entityViewConfiguration={detailViewDef}
          entityDef={entityDef}
          entityRecord={_entityRecord}
        />
      )}
    </>
  );
};

CustomEntityRequest.propTypes = {
  isMountedInsideApplication: PropTypes.bool,
  isEntityRecordResolving: PropTypes.bool,
  rowClassName: PropTypes.string,
  approvalRequest: PropTypes.object.isRequired,
  entityDef: PropTypes.object.isRequired,
  entityRecord: PropTypes.object,
  applicationProperties: PropTypes.object,
};

CustomEntityRequest.defaultProps = {
  isMountedInsideApplication: false,
  isEntityRecordResolving: false,
  rowClassName: EMPTY_STRING,
  applicationProperties: EMPTY_OBJECT,
  entityRecord: EMPTY_OBJECT,
};

export default CustomEntityRequest;
