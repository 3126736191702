import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Modal from '@tekion/tekion-components/molecules/Modal';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import { getFields, getSections } from './constants/addOverrideFieldModal.field';
import { ACTION_TYPES } from '../../constants/recordTypeOverride.constants';
import { CONTEXT_ID } from './constants/addOverrideFieldModal.constants';

const OverrideFieldModal = ({ isModalVisible, fieldOverrideFormValues, availableFields, errors, onAction }) => {
  const fields = useMemo(() => getFields(fieldOverrideFormValues, availableFields), [availableFields, fieldOverrideFormValues]);
  const sections = useMemo(() => getSections(fieldOverrideFormValues), [fieldOverrideFormValues]);

  const handleClose = useCallback(() => {
    onAction({
      type: ACTION_TYPES.MODAL_CLOSE,
    });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, []);

  return (
    <Modal visible={isModalVisible} destroyOnClose width={Modal.SIZES.XL} title={__('Override Field')} onCancel={handleClose} onSubmit={handleSubmit}>
      <FormWithSubmission
        contextId={CONTEXT_ID}
        fields={fields}
        values={fieldOverrideFormValues}
        sections={sections}
        errors={errors}
        onAction={onAction}
      />
    </Modal>
  );
};

OverrideFieldModal.propTypes = {
  isModalVisible: PropTypes.bool,
  fieldOverrideFormValues: PropTypes.object,
  errors: PropTypes.object,
  availableFields: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

OverrideFieldModal.defaultProps = {
  isModalVisible: false,
  fieldOverrideFormValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  availableFields: EMPTY_ARRAY,
};

export default OverrideFieldModal;
