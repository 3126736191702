/* This file is not using right now as we are not supporting Time of the day node */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select as SelectAnt } from 'antd';
import cx from 'classnames';

import _map from 'lodash/map';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _noop from 'lodash/noop';

// Constants
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Components
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import NumberInput from '@tekion/tekion-components/molecules/NumberInput';
import Error from '@tekion/tekion-components/organisms/FormBuilder/components/error';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import s from './numberWithSelect.module.scss';

const { Option } = SelectAnt;

const NumberWithSelect = (props) => {
  const {
    value,
    addonAfterOptions,
    onFocus,
    id,
    onAction,
    customOnActionFn,
    onBlur,
    disabled,
    placeholder,
    min,
    max,
    precision,
    error,
    addonBefore,
    addonAfter,
    shouldDisabledStepper,
    className,
    warning,
    parser,
    fieldClassName,
    additionalText,
    containerClassName,
  } = props;

  const [isFocused, setFocused] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(_get(value, 'unit', _head(addonAfterOptions).value));
  const [selectedNumber, setSelectedNumber] = useState(_get(value, 'value', 0));
  const [isModified, setIsModified] = useState(false);

  const handleFocus = () => {
    setFocused(true);
    onFocus();
  };

  const handleBlur = useCallback(() => {
    const payload = {
      id,
      value: {
        value: selectedNumber,
        unit: selectedUnit,
      },
    };
    setFocused(false);
    onBlur();

    if (customOnActionFn) {
      customOnActionFn(payload.value);
    } else {
      onAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload,
      });
    }
  }, [customOnActionFn, id, onAction, onBlur, selectedNumber, selectedUnit]);

  useEffect(() => {
    if (isModified) {
      handleBlur();
    }
  }, [isModified, selectedNumber, handleBlur, selectedUnit]);

  // handle number change
  const handleChange = useCallback((num) => {
    setSelectedNumber(num);
    setIsModified(true);
  }, []);
  // on unit change
  const handleSelectOnChange = useCallback((unit) => {
    setSelectedUnit(unit);
    setIsModified(true);
  }, []);
  // renders the unit select box
  const renderSelectOptions = useCallback(() => {
    const renderOptions = _map(addonAfterOptions, (item) => (
      <Option key={item.value} value={item.value} disabled={item.disabled}>
        {item.label}
      </Option>
    ));

    return (
      // check select input from ANT
      <SelectAnt onChange={handleSelectOnChange} defaultValue={_head(addonAfterOptions).value} disabled={disabled} value={selectedUnit}>
        {renderOptions}
      </SelectAnt>
    );
  }, [addonAfterOptions, disabled, selectedUnit, handleSelectOnChange]);

  const renderNumberInput = () => (
    <div className={containerClassName}>
      <PropertyControlledComponent controllerProperty={additionalText}>
        <span className={s.additionalText}>{additionalText}</span>
      </PropertyControlledComponent>
      <div
        className={cx(s.numberInputField, {
          [s.numberInputFieldFocused]: isFocused,
          [s.numberInputFieldError]: !!error,
          [s.numberInputFieldWarning]: !!warning,
        })}
      >
        <NumberInput
          id={id}
          value={selectedNumber}
          onChange={handleChange}
          className={cx(s.customNumberInput, className, { [s.disabledStepper]: shouldDisabledStepper })}
          placeholder={placeholder}
          min={min}
          max={max}
          precision={precision}
          disabled={disabled}
          onFocus={handleFocus}
          addonBefore={addonBefore}
          addonAfter={addonAfterOptions ? renderSelectOptions() : addonAfter}
          parser={parser}
        />
      </div>
    </div>
  );

  const render = () => (
    <div className={fieldClassName}>
      <FieldLabel {...props} />
      {renderNumberInput()}
      <Error key="error" {...props} />
      <Error key="warning" className="warning-text" error={warning} />
    </div>
  );

  useEffect(() => {
    if (value) {
      setSelectedUnit(_get(value, 'unit'));
      setSelectedNumber(_get(value, 'value'));
    }
  }, [value]);

  return render();
};

NumberWithSelect.propTypes = {
  id: PropTypes.string.isRequired,
  shouldDisabledStepper: PropTypes.bool,
  fieldClassName: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  precision: PropTypes.number,
  disabled: PropTypes.bool,
  onAction: PropTypes.func,
  value: PropTypes.number,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  addonBefore: PropTypes.string,
  addonAfter: PropTypes.string,
  error: PropTypes.string,
  warning: PropTypes.string,
  parser: PropTypes.func,
  addonAfterOptions: PropTypes.object,
  className: PropTypes.string,
  customOnActionFn: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  additionalText: PropTypes.string,
  containerClassName: PropTypes.string,
};

NumberWithSelect.defaultProps = {
  onAction: _noop,
  onBlur: _noop,
  onFocus: _noop,
  customOnActionFn: false,
  fieldClassName: EMPTY_STRING,
  placeholder: EMPTY_STRING,
  addonBefore: EMPTY_STRING,
  addonAfter: EMPTY_STRING,
  error: EMPTY_STRING,
  min: 0,
  max: Infinity,
  precision: undefined,
  disabled: false,
  shouldDisabledStepper: false,
  value: undefined,
  warning: EMPTY_STRING,
  parser: undefined,
  addonAfterOptions: EMPTY_OBJECT,
  className: EMPTY_STRING,
  additionalText: EMPTY_STRING,
  containerClassName: EMPTY_STRING,
};

export default NumberWithSelect;
