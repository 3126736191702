import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Loader from '@tekion/tekion-components/molecules/loader';

import withSize from '../../../../../../connectors/withSize';
import TileButton from '../../../../../../atoms/tileButton/TileButton';
import ACTION_HANDLERS from './helpers/reportTab.actionhandlers';
import ACTION_TYPES from './constants/reportTab.constants';

import styles from './reportTab.module.scss';

const ReportTab = ({ loading, contentHeight, dashboards, onAction }) => {
  const getDashboardButtonUrl = useCallback((dashboard) => {
    const dashboardName = _get(dashboard, 'name');
    const pathName = `/reporting/dashboards/${dashboardName}`;
    return pathName;
  }, []);

  const renderDashboardList = useCallback(
    () =>
      _map(dashboards, (dashboard, index) => {
        const displayName = _get(dashboard, 'displayName');
        // const logoUrl = _get(dashboard, 'logoUrl');
        const pathName = getDashboardButtonUrl(dashboard);
        return (
          <Link to={{ pathname: pathName, state: { dashboard, dashboardList: dashboards } }}>
            <TileButton key={index} displayName={displayName} application={dashboard} />
          </Link>
        );
      }),
    [dashboards, getDashboardButtonUrl],
  );

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.INIT,
    });
  }, [onAction]);

  if (loading) return <Loader />;
  return (
    <Page className={styles.page}>
      <Page.Body style={{ height: contentHeight }}>
        <Heading className={styles.dashboardHeading}>{__(`All Dashboards (${_size(dashboards)})`)}</Heading>
        <div className={styles.tileListContainer}>{renderDashboardList()}</div>
      </Page.Body>
    </Page>
  );
};

ReportTab.propTypes = {
  loading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  dashboards: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ReportTab.defaultProps = {
  loading: false,
  dashboards: EMPTY_ARRAY,
};

export default compose(withSize({ hasFooter: 0, hasHeader: 0 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ReportTab);
