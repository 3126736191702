import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';

// Constants
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { STATUS } from '@tekion/tekion-base/constants/statuses';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { tget } from '@tekion/tekion-base/utils/general';
import { editUserData, getUserById, getUserWorkspaceDetailsById, saveUserData } from '../../../../../../actions/workspaceUserManagement.actions';
import { getAllRoles } from '../../../../../../actions/rolesHierarchyManagement.actions';

import { getRoleOptions, mapApiDataToForm, mapUserDataToApiFormat } from './workspaceUserForm.helpers';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/workspaceUserForm.actionTypes';

const fetchUserWorkspaceDetails = async (userId) => {
  const userDetails = await getUserWorkspaceDetailsById(userId);
  const userWorkspaceDetails = _get(userDetails, 'userWorkspaceDetails');

  return userWorkspaceDetails;
};

const fetchAllWorkspaceRoles = async () => {
  const roleOptions = [];
  const rolesTreeData = await getAllRoles();
  if (!_isEmpty(rolesTreeData)) {
    getRoleOptions(rolesTreeData, roleOptions);
  }
  return roleOptions;
};

const handleInit = async ({ setState, getState }) => {
  const { match, location } = getState();
  const userId = _get(match, 'params.userId', EMPTY_STRING);
  setState({ isDataLoading: true });
  const roleOptions = await fetchAllWorkspaceRoles();

  if (_isEmpty(userId)) {
    setState({ formMode: FORM_MODES.CREATE, isDataLoading: false, roleOptions });
  } else {
    setState({ formMode: FORM_MODES.EDIT });
    let userData = _get(location, 'state.userInfo');

    if (_isEmpty(userData)) {
      userData = await getUserById(userId);
    }

    const userWorkspaceDetails = await fetchUserWorkspaceDetails(userId);
    const isUserPresentInOtherWorkspace = _size(userWorkspaceDetails) > 1;
    const modifiedFormData = mapApiDataToForm(userData);
    setState({ values: _get(modifiedFormData, 'userFormValues'), isDataLoading: false, isUserPresentInOtherWorkspace, roleOptions });

    if (isUserPresentInOtherWorkspace) {
      toaster(TOASTER_TYPE.INFO, __('User is a part of multiple workspaces. Please contact tenant universe admin to update the disabled fields.'));
    }
  }
};

const handleFieldChange = ({ setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  setState((state) => ({
    values: {
      ...state?.values,
      [id]: value,
    },
  }));
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;
  setState({ errors });
};

const handleRedirection = ({ getState }) => {
  const { history } = getState();
  const path = `${STUDIO_ROUTE}/${PAGE_IDS.USERS}`;
  history.push(path);
};

const handleSubmit = async ({ getState, setState }) => {
  const { values, formMode } = getState();
  const userObject = mapUserDataToApiFormat(values);
  let status = EMPTY_STRING;
  let userDetails = {};
  setState({ isSaveLoading: true });

  if (formMode === FORM_MODES.CREATE) {
    const response = await saveUserData(userObject);
    status = tget(response, 'status', STATUS.FAILED);
    userDetails = tget(response, 'data', {});
  } else {
    const { match } = getState();
    const userId = _get(match, 'params.userId');
    status = await editUserData(userId, userObject);
  }

  if (status !== STATUS.FAILED) {
    setTimeout(() => {
      if (formMode === FORM_MODES.EDIT) {
        handleRedirection({ getState });
      } else {
        setState({ isConfirmModalVisible: true, isSaveLoading: false, userDetails });
      }
    }, ES_REFETCH_DELAY);
  } else {
    setState({ isSaveLoading: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INITIALIZE_USER_FORM_VALUES]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
};

export default ACTION_HANDLERS;
