import { compose } from 'recompose';
import { defaultMemoize } from 'reselect';

import _property from 'lodash/property';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';

import _nthArg from 'lodash/nthArg';

// Validators
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

// Components
import ColumnConfig from '@tekion/tekion-components/organisms/inputTable/builders/ColumnConfig';
import CellConfig from '@tekion/tekion-components/organisms/inputTable/builders/CellConfig';
import SelectInputCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/SelectInputCellRenderer';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';

// Constants
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { COLUMNS } from '../../../constants/approvalSettingFieldForm.constants';

import styles from '../controllingOption.module.scss';

const getFieldOptions = (options, selectedOptions = EMPTY_ARRAY) =>
  _map(options, (field) => ({
    label: _get(field, 'label', ''),
    value: _get(field, 'value', ''),
    isDisabled: _includes(selectedOptions, field.value),
  }));

const mapOptionProps = defaultMemoize((props, options, selectedOptions, isOptionDisabled) => {
  const value = _get(props, `rowData.${COLUMNS.OPTION}`);
  return {
    validators: [isRequiredRule],
    required: true,
    options: getFieldOptions(options, selectedOptions),
    isDisabled: isOptionDisabled,
    error: _isEmpty(value) && !isOptionDisabled ? __('Please select Controlling Field') : '',
  };
});

const mapValueProps = defaultMemoize((props, valuesOptions, isValueDisabled) => {
  const value = _get(props, `rowData.${COLUMNS.VALUES}`);
  return {
    validators: [isRequiredRule],
    required: true,
    options: valuesOptions,
    mode: 'multiple',
    disabled: isValueDisabled,
    className: styles.customSelect,
    error: _isEmpty(value) && !isValueDisabled ? __('Please select Controlling Options') : '',
  };
});

const getOptionCell = (options, selectedOptions, isOptionDisabled) =>
  new CellConfig()
    .setComponent(SelectInputCellRenderer)
    .setMapCellPropsToComponentProps((props) => mapOptionProps(props, options, selectedOptions, isOptionDisabled));

const getValueCell = (valuesOptions, isValueDisabled) =>
  new CellConfig().setComponent(SelectInput).setMapCellPropsToComponentProps((props) => mapValueProps(props, valuesOptions, isValueDisabled));

const COLUMN_WIDTHS = {
  [COLUMNS.OPTION]: '30rem',
  [COLUMNS.VALUES]: '30rem',
};

const valueAccessor = _property(COLUMNS.VALUES);
const optionAccessor = _property(COLUMNS.OPTION);

const onOptionChange = compose(_property('value'), _nthArg(1));
const onValueChange = (data, value) => [...value];

const getOptionColumnConfig = (options, selectedOptions, isOptionDisabled) =>
  new ColumnConfig(COLUMNS.OPTION)
    .setHeader(__('Option'))
    .setGetValueFromOnChange(onOptionChange)
    .setAccessor(optionAccessor)
    .addCellConfig(getOptionCell(options, selectedOptions, isOptionDisabled))
    .setWidth(COLUMN_WIDTHS[COLUMNS.OPTION]);

const getValueColumnConfig = (valuesOptions, isValueDisabled) =>
  new ColumnConfig(COLUMNS.VALUES)
    .setHeader(__('Values'))
    .setGetValueFromOnChange(onValueChange)
    .setAccessor(valueAccessor)
    .addCellConfig(getValueCell(valuesOptions, isValueDisabled))
    .setWidth(COLUMN_WIDTHS[COLUMNS.VALUES]);

const getColumns = (options, selectedOptions, valuesOptions, isOptionDisabled, isValueDisabled) => {
  const optionColumnConfig = getOptionColumnConfig(options, selectedOptions, isOptionDisabled);
  const valueColumnConfig = getValueColumnConfig(valuesOptions, isValueDisabled);
  return [optionColumnConfig, valueColumnConfig];
};

export { COLUMN_WIDTHS, onValueChange, getColumns };
