const ACTION_TYPES = {
  INIT: 'INIT',
  ON_VIEW_VARIABLE_CHANGE: 'ON_VIEW_VARIABLE_CHANGE',
  CLEAR_VIEW_VARIABLES: 'CLEAR_VIEW_VARIABLES',
  ON_FORM_SUBMIT: 'ON_FORM_SUBMIT',
  ON_FIELD_CHANGE: 'ON_FIELD_CHANGE',
  ON_RECORD_SELECTION_FIELD_CHANGE: 'ON_FIELD_CHANGE',
  ON_FORM_VALUE_CHANGE: 'ON_FORM_VALUE_CHANGE',
  ON_BLUR: 'ON_BLUR',
};

const ENTITY_VIEW_CONFIG_KEYS = {
  VIEW_RECORD_TYPE_METADATA_LIST: 'viewRecordTypeMetadataList',
  VIEW_TYPE: 'viewType',
  ENTITY_NAME: 'entityName',
  RECORD_TYPE_SELECTION_VIEW_NAME: 'recordTypeSelectionViewName',
};

export { ACTION_TYPES, ENTITY_VIEW_CONFIG_KEYS };
