const INITIAL_STATE = {
  isWorkflowLoading: true,
  isWorkflowFetched: false,
  isWorkflowModified: false,
  isWorkflowSubmitting: false,
  stepDrawerData: {
    isLoading: false,
    isError: false,
    error: '',
    items: [],
  },
  triggerDrawerData: {
    isLoading: false,
    isError: false,
    items: [],
  },
};

const NODE_OPERATIONS = {
  ADD_TRIGGER: 'ADD_TRIGGER',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  CHANGE_TO_IF: 'CHANGE_TO_IF',
  CHANGE_TO_IF_ELSE: 'CHANGE_TO_IF_ELSE',
  CHANGE_TO_CHECK: 'CHANGE_TO_CHECK',
  UNLINK_CONNECTOR_PATH: 'UNLINK_CONNECTOR_PATH',
  CHANGE_TO_LOOP_EXIT: 'CHANGE_TO_LOOP_EXIT',
  CHANGE_TO_LOOP_CONTINUE: 'CHANGE_TO_LOOP_CONTINUE',
};

const DROPDOWN_MENU = {
  EDIT: { key: NODE_OPERATIONS.EDIT, label: __('Edit') },
  DELETE: { key: NODE_OPERATIONS.DELETE, label: __('Delete') },
  CHANGE_TO_IF_ELSE: { key: NODE_OPERATIONS.CHANGE_TO_IF_ELSE, label: __('Change to If/Else') },
  CHANGE_TO_CHECK: { key: NODE_OPERATIONS.CHANGE_TO_CHECK, label: __('Change to Check Field Value') },
  CHANGE_TO_IF: { key: NODE_OPERATIONS.CHANGE_TO_IF, label: __('Change to If') },
  UNLINK_CONNECTOR_PATH: { key: NODE_OPERATIONS.UNLINK_CONNECTOR_PATH, label: __('Unlink') },
  CHANGE_TO_LOOP_EXIT: { key: NODE_OPERATIONS.CHANGE_TO_LOOP_EXIT, label: __('Change to Loop Exit') },
  CHANGE_TO_LOOP_CONTINUE: { key: NODE_OPERATIONS.CHANGE_TO_LOOP_CONTINUE, label: __('Change to Loop Continue') },
};

const SWITCH_DEFAULT_CASE = {
  id: 'default',
  displayName: __('None'),
};

const MODAL_MODES = {
  EDIT: 'EDIT',
  ADD: 'ADD',
  CANCEL: 'CANCEL',
  DELETE: 'DELETE',
};

const KEEP_BY_CONDITION = 'KEEP_BY_CONDITION';

const RECORD_UPDATED_EVENT_TYPE = 'RECORD_UPDATED';
const EVENT_TYPES_FOR_RECORD_CHANGE_OPERATORS = [RECORD_UPDATED_EVENT_TYPE];
const MAX_HISTORY = 30;
const MAX_FUTURE = 30;

export {
  INITIAL_STATE,
  DROPDOWN_MENU,
  NODE_OPERATIONS,
  SWITCH_DEFAULT_CASE,
  MODAL_MODES,
  KEEP_BY_CONDITION,
  EVENT_TYPES_FOR_RECORD_CHANGE_OPERATORS,
  MAX_HISTORY,
  MAX_FUTURE,
};
