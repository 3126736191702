import { MB_TO_BYTES } from '../../../constants/richTextEditor.media';

const MAX_IMAGE_FILE_SIZE = 25 * MB_TO_BYTES;

const POLL = {
  COUNT: 15,
  INTERVAL: 2000,
};

export { MAX_IMAGE_FILE_SIZE, POLL };
