import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';

import { COLUMN_IDS } from './metadataComponentList.table.constants';

const FILTER_ID = {
  INCLUDED_BY: 'includedBy',
  COMPONENT_TYPE: 'componentType',
};

const INCLUDED_BY_FILTER = {
  id: FILTER_ID.INCLUDED_BY,
  key: COLUMN_IDS.INCLUDED_BY,
  type: FILTER_TYPES.SINGLE_SELECT,
  name: __('Inlcuded By'),
};

const COMPONENT_TYPE_FILTERS = {
  id: FILTER_ID.COMPONENT_TYPE,
  key: COLUMN_IDS.COMPONENT_TYPE,
  type: FILTER_TYPES.MULTI_SELECT,
  name: __('Component Type'),
};

const DEFAULT_FILTERS = [];
const DEFAULT_FILTER_GROUP = 'DEFAULT_FILTER_GROUP';

export { INCLUDED_BY_FILTER, DEFAULT_FILTERS, DEFAULT_FILTER_GROUP, COMPONENT_TYPE_FILTERS };
