import { useCallback } from 'react';
import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import useEditor from '../../../hooks/useEditor';

function useMediaDeletion(element) {
  const editor = useEditor();
  const path = ReactEditor.findPath(editor, element);
  const handleMediaDeletion = useCallback(() => {
    Transforms.removeNodes(editor, { at: path });
  }, [editor, path]);

  return {
    handleMediaDeletion,
  };
}

export default useMediaDeletion;
