const ACTION_TYPES = {
  INIT_FORM: 'INIT_FORM',
  ON_CANCEL: 'ON_CANCEL',
  ON_FORM_SUBMIT: 'ON_FORM_SUBMIT',
  GO_BACK: 'GO_BACK',
  ON_FIELD_BLUR: 'ON_FIELD_BLUR',
};

const CONTEXT_ID = 'FORM_VIEW_RENDERER';
const SAVE_COMPONENT_ID = 'SAVE_COMPONENT';
const ID = 'id';

export { ACTION_TYPES, CONTEXT_ID, SAVE_COMPONENT_ID, ID };
