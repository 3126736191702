import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _head from 'lodash/head';

import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';

import { tget } from '@tekion/tekion-base/utils/general';
import { fetchEntityDefByName } from '../../../../../actions/entityManagement.actions';
import { searchViewConfigurations } from '../../../../../actions/viewBuilderPage.actions';
import { createEntityRecord, fetchEntityRecordById, updateEntityRecord } from '../../../../../actions/recordManagement.actions';

import { getPayloadForViewDefinitionSearch } from './entityRecordPage.helpers';

import { ACTION_TYPES } from '../constants/entityRecordPage.constants';
import { VIEW_CONFIG_TYPES, VIEW_TYPES, FORM_MODES } from '../../../../../constants/viewBuilder.constants';

const handleInit = async ({ getState, setState }) => {
  const { match } = getState();
  const entityName = _get(match, 'params.entityName');
  const entityRecordId = _get(match, 'params.entityRecordId');
  const viewDefinitionPayload = getPayloadForViewDefinitionSearch([entityName], [VIEW_TYPES.FORM_VIEW], [VIEW_CONFIG_TYPES.STANDARD]);

  const promises = [fetchEntityDefByName(entityName), searchViewConfigurations(viewDefinitionPayload)];
  if (!_isEmpty(entityRecordId)) {
    promises.push(fetchEntityRecordById(entityName, entityRecordId));
  }

  const [entity, formViewConfigResponse, entityRecord] = await Promise.all(promises);
  const formViewConfig = _head(tget(formViewConfigResponse, 'hits', [{}]));
  let updatedState = { formMode: FORM_MODES.CREATE, entity, formViewConfig };

  if (!_isEmpty(entityRecordId)) {
    updatedState = { ...updatedState, formMode: FORM_MODES.EDIT, entityRecord };
  }

  setState({ ...updatedState });
};

const handleSubmit = async ({ setState, getState, params: payload }) => {
  const { match, formMode, history } = getState();
  const entityName = _get(match, 'params.entityName');
  const entityRecordId = _get(match, 'params.entityRecordId');

  setState({ isSavingDetails: true });
  let isSuccess;

  if (formMode === FORM_MODES.CREATE) {
    isSuccess = await createEntityRecord(entityName, payload);
  } else {
    isSuccess = await updateEntityRecord(entityName, entityRecordId, payload);
  }

  setState({ isSavingDetails: false });
  if (isSuccess)
    setTimeout(() => {
      history.push(`/entities/${entityName}`);
    }, ES_REFETCH_DELAY);
};

const handleCancel = ({ getState }) => {
  const { history, match } = getState();
  const entityName = _get(match, 'params.entityName');
  history.push(`/entities/${entityName}`);
};

const handleGoBack = ({ getState }) => {
  const { history, match } = getState();
  const entityName = _get(match, 'params.entityName');
  history.push(`/entities/${entityName}`);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT_FORM]: handleInit,
  [ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL]: handleCancel,
  [ACTION_TYPES.GO_BACK]: handleGoBack,
};

export default ACTION_HANDLERS;
