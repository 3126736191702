import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';

import entityApplicationMappingServices from '../services/entityApplicationMapping.services';

const MAPPINGS_API_KEYS = {
  APPLICATION_NAME: 'applicationName',
  MAPPINGS: 'entityPageMappingRequests',
};

const getEntityMappingsForApplication = async (applicationName) => {
  try {
    const response = await entityApplicationMappingServices.fetchEntityMappingsForApplication(applicationName);
    return { data: getDataFromResponse(response) };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch entity mappings for this application, please try again later.')));
    return { data: EMPTY_OBJECT, isErrored: true };
  }
};

const createUpdateEntityMappingsForApplication = async (modeLabel, applicationName, entityMappings) => {
  const payload = {
    [MAPPINGS_API_KEYS.APPLICATION_NAME]: applicationName,
    [MAPPINGS_API_KEYS.MAPPINGS]: entityMappings,
  };

  try {
    const response = await entityApplicationMappingServices.createUpdateEntityMappingsForApplication(payload);
    return { data: getDataFromResponse(response) };
  } catch (error) {
    toaster(
      TOASTER_TYPE.ERROR,
      getErrorMessage(
        error,
        __('Failed to {{modeLabel}} entity mappings for application "{{applicationName}}", please try again later.', {
          modeLabel,
          applicationName,
        }),
      ),
    );
    return { data: EMPTY_OBJECT, isErrored: true };
  }
};

const getApplicationEntityMapping = async (applicationName, entityName) => {
  try {
    const response = await entityApplicationMappingServices.fetchApplicationEntityMapping(applicationName, entityName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(
      TOASTER_TYPE.ERROR,
      getErrorMessage(
        error,
        __('Failed to fetch mapping for entity {{entityName}} and application {{applicationName}}, please try again later.', {
          entityName,
          applicationName,
        }),
      ),
    );
    return EMPTY_OBJECT;
  }
};

export { getEntityMappingsForApplication, createUpdateEntityMappingsForApplication, getApplicationEntityMapping };
