import React from 'react';
import PropTypes from 'prop-types';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import Label from '@tekion/tekion-components/atoms/Label';
import WithKeyPressInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput/withKeyPressInput';

import ResendOtpButton from './components/resendOtpButton';

import styles from './otpInputRenderer.module.scss';

const OTPInputRenderer = ({ showResend, label, expiryTime, onAction, ...rest }) => (
  <div className="full-width">
    <div className={styles.header}>
      <Label>{label}</Label>
      {showResend && <ResendOtpButton showResend={showResend} expiryTime={expiryTime} onAction={onAction} />}
    </div>
    <WithKeyPressInput {...rest} autocomplete="off" autoFocus fieldClassName={styles.otp} onAction={onAction} />
  </div>
);

OTPInputRenderer.propTypes = {
  showResend: PropTypes.bool,
  label: PropTypes.string,
  expiryTime: PropTypes.number,
  onAction: PropTypes.func.isRequired,
};

OTPInputRenderer.defaultProps = {
  showResend: true,
  expiryTime: 60,
  label: EMPTY_STRING,
};

export default OTPInputRenderer;
