import { WIDGET_DISPLAY_NAMES } from '../../../../../constants/visualBuilder.widgetMapper';
import { WIDGET_NAME_CONFIGURE_FIELD_IDS } from '../constants/widgetNameConfigure.fieldIds';

const getFormFormattedSelectedWidgetProperties = (selectedWidgetProperties, widgetType) => ({
  ...selectedWidgetProperties,
  [WIDGET_NAME_CONFIGURE_FIELD_IDS.WIDGET_TYPE]: WIDGET_DISPLAY_NAMES[widgetType],
});

const getConfigFormattedSelectedWidgetProperties = (value, id, selectedWidgetProperties) => {
  if (id === WIDGET_NAME_CONFIGURE_FIELD_IDS.WIDGET_TYPE) {
    return { ...selectedWidgetProperties };
  }
  return { ...selectedWidgetProperties, [id]: value };
};

export { getFormFormattedSelectedWidgetProperties, getConfigFormattedSelectedWidgetProperties };
