import _findIndex from 'lodash/findIndex';
import _isNil from 'lodash/isNil';
import _find from 'lodash/find';

import { tget } from '@tekion/tekion-base/utils/general';

// Constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import DELAY_TASK_ACTION_TYPES from '../../constants/delayTaskModal.actionTypes';
import DELAY_TASK_MODAL_FIELD_IDS from '../../constants/delayTaskModal.fieldIds';
import { DELAY_TASK_TAB_IDS } from '../../constants/delayTaskModal.tabs';
import { UNTITLED_TAB_NAME } from '../../constants/delayTaskModal.constants';

// Utils
import getUuid from '../../../../../../../../../../utils/getUuid';

const handleTabAdd = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { tabsList } = getState();
  const { tab } = params;
  const tabId = tget(tab, DELAY_TASK_MODAL_FIELD_IDS.ID);

  const updatedTabList = _isNil(tabsList) ? [] : [...tabsList];
  const newTabId = getUuid();

  switch (tabId) {
    case DELAY_TASK_TAB_IDS.ADD_DELAY_EXIT_EVENT: {
      const addWorkflowTabIndex = _findIndex(tabsList, { [DELAY_TASK_MODAL_FIELD_IDS.ID]: DELAY_TASK_TAB_IDS.ADD_WORKFLOW_EXIT_EVENT });
      updatedTabList.splice(addWorkflowTabIndex, 0, {
        id: newTabId,
        displayName: UNTITLED_TAB_NAME,
        type: DELAY_TASK_TAB_IDS.ADD_DELAY_EXIT_EVENT,
        values: {},
        errors: {},
      });
      break;
    }
    case DELAY_TASK_TAB_IDS.ADD_WORKFLOW_EXIT_EVENT:
      updatedTabList.push({
        id: newTabId,
        displayName: UNTITLED_TAB_NAME,
        type: DELAY_TASK_TAB_IDS.ADD_WORKFLOW_EXIT_EVENT,
        values: {},
        errors: {},
      });
      break;
    default:
      break;
  }

  setState({ tabsList: updatedTabList, selectedTabId: newTabId });
};

const handleTabClick = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { tabsList } = getState();
  const { tabId } = params;

  if (tabId === DELAY_TASK_TAB_IDS.ADD_DELAY_EXIT_EVENT || tabId === DELAY_TASK_TAB_IDS.ADD_WORKFLOW_EXIT_EVENT) {
    const tab = _find(tabsList, { id: tabId });
    handleTabAdd({ getState, setState, params: { ...params, tab } });
  } else {
    setState({ selectedTabId: tabId });
  }
};

const handleTabDragEnd = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { tabsList } = getState();
  const { dragResult } = params;

  const newItems = Array.from(tabsList);
  const [removed] = newItems.splice(dragResult.source.index, 1);

  newItems.splice(dragResult.destination.index, 0, removed);

  setState({ tabsList: newItems });
};

const handleTabDelete = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { tabsList, selectedTabId } = getState();
  const { tab } = params;
  const tabId = tget(tab, DELAY_TASK_MODAL_FIELD_IDS.ID);

  const updatedTabList = _isNil(tabsList) ? [] : [...tabsList];
  const tabToDeleteIndex = _findIndex(updatedTabList, { id: tabId });

  updatedTabList.splice(tabToDeleteIndex, 1);

  setState({ tabsList: updatedTabList });

  if (tabId === selectedTabId) {
    setState({ selectedTabId: DELAY_TASK_TAB_IDS.TIME_DELAY });
  }
};

const TAB_ACTION_HANDLERS = {
  [DELAY_TASK_ACTION_TYPES.ON_TAB_ADD]: handleTabAdd,
  [DELAY_TASK_ACTION_TYPES.ON_TAB_CLICK]: handleTabClick,
  [DELAY_TASK_ACTION_TYPES.ON_TAB_DRAG_END]: handleTabDragEnd,
  [DELAY_TASK_ACTION_TYPES.ON_TAB_DELETE]: handleTabDelete,
};

export default TAB_ACTION_HANDLERS;
