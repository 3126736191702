import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _castArray from 'lodash/castArray';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

// Components
import Button from '@tekion/tekion-components/atoms/Button';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';

import { ACTION_TYPES } from '../compoundConfigModal.constants';

import styles from './entityFieldList.module.scss';

const EntityField = ({ index, required, options, value, error, onAction }) => {
  const onDeleteClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.DELETE_FIELD,
      payload: {
        index,
        value,
      },
    });
  }, [onAction, index, value]);

  return (
    <div className={styles.container}>
      <div className={styles.index}>{index + 1}.</div>
      <Select
        id={index}
        hideSelectedOptions
        fieldClassName={fieldLayoutStyles.fieldC}
        value={_castArray(value)}
        error={error}
        options={options}
        onAction={onAction}
        required={required}
      />
      <Button view={Button.VIEW.ICON} icon="icon-trash" iconClassName={styles.icon} onClick={onDeleteClick} />
    </div>
  );
};

EntityField.propTypes = {
  required: PropTypes.bool,
  index: PropTypes.number.isRequired,
  error: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

EntityField.defaultProps = {
  required: false,
  error: EMPTY_STRING,
  value: EMPTY_ARRAY,
  options: EMPTY_ARRAY,
};

export default EntityField;
