import { useCallback, useState, useEffect } from 'react';

import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { deleteSharedRecordDataOnMode } from './useShareAndManageRecordModal.helpers';
import { SHARE_AND_MANAGE_RECORD_MODAL_MODE } from '../../constants/shareAndManageRecordModal.constants';

const useShareAndManageRecordModal = ({ shareAndManageRecordModalMetaData, shareAndManageRecordUsersData, deleteSharedRecordForUser }) => {
  const [shareAndManageRecordUsersState, setShareAndManageRecordUsersState] = useState([]);

  const { entityDef, entityRecord, mode = SHARE_AND_MANAGE_RECORD_MODAL_MODE.SHARE_RECORD, wwdData } = shareAndManageRecordModalMetaData;

  const handleUpdateShareAndManageRecordUserDetails = useCallback((index, updatedValue) => {
    setShareAndManageRecordUsersState((prevValue) => {
      const newValue = [...prevValue];
      _set(newValue, index, updatedValue);

      return newValue;
    });
  }, []);

  const handleRemoveShareAndManageRecordUserDetail = useCallback(
    async (index) => {
      const sharedRecordUserStateData = shareAndManageRecordUsersState[index];

      const isSuccess = await deleteSharedRecordDataOnMode(mode, sharedRecordUserStateData, deleteSharedRecordForUser);

      if (isSuccess) {
        setShareAndManageRecordUsersState((prev) => {
          prev.splice(index, 1);

          return [...prev];
        });
      }
    },
    [mode, shareAndManageRecordUsersState, deleteSharedRecordForUser],
  );

  const handleAddShareAndManageRecordUserDetail = useCallback((shareAndManageUserDetail = EMPTY_ARRAY) => {
    setShareAndManageRecordUsersState((prev) => [...prev, ...shareAndManageUserDetail]);
  }, []);

  useEffect(() => {
    setShareAndManageRecordUsersState(_cloneDeep(shareAndManageRecordUsersData));
  }, [shareAndManageRecordUsersData]);

  return {
    mode,
    entityDef,
    entityRecord,
    wwdData,
    shareAndManageRecordUsersState,
    handleUpdateShareAndManageRecordUserDetails,
    handleRemoveShareAndManageRecordUserDetail,
    handleAddShareAndManageRecordUserDetail,
  };
};

export default useShareAndManageRecordModal;
