import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Heading from '@tekion/tekion-components/atoms/Heading';

import ACTION_TYPES from '../../constants/studioHomePage.actionTypes';

import styles from './clickableDiv.module.scss';

const ClickableDiv = (props) => {
  const { onAction, id } = props;
  const onDivClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onAction({ type: ACTION_TYPES.ON_CLICK_CONTAINER, payload: id });
  };

  return (
    <div onClick={onDivClick} role="button" tabIndex="-1" className={styles.clickableDiv}>
      <div className={styles.button}>
        <div className={styles.addIconDivContainer}>
          <FontIcon size={SIZES.XXXL}>icon-add2</FontIcon>
        </div>
        <Heading size={4} className={styles.header}>
          {id}
        </Heading>
      </div>
    </div>
  );
};

ClickableDiv.propTypes = {
  id: PropTypes.string,
  onAction: PropTypes.func,
};

ClickableDiv.defaultProps = {
  id: EMPTY_STRING,
  onAction: _noop,
};

export default React.memo(ClickableDiv);
