import { FIELD_IDS } from './applicationFormPage.constants';

const SECTIONS = [
  {
    header: {
      label: __('Application details'),
    },
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
      },
      {
        columns: [FIELD_IDS.DESCRIPTION],
      },
      {
        columns: [FIELD_IDS.MEDIA_ID],
      },
    ],
  },
];

export default SECTIONS;
