const FIELD_IDS = {
  IS_CARD_VIEW_ENABLED: 'IS_CARD_VIEW_ENABLED',
  VIEW_NAME: 'cardViewName',
};

const FIELD_LABELS = {
  [FIELD_IDS.IS_CARD_VIEW_ENABLED]: __('Use View as card'),
  [FIELD_IDS.VIEW_NAME]: __('View'),
};

const ROWS = 20;

export { FIELD_IDS, FIELD_LABELS, ROWS };
