import React from 'react';
import PropTypes from 'prop-types';

import _head from 'lodash/head';

import Icon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import { ACTION_TYPES } from '../../../constants/addWidget.general.constant';

const IconRenderer = (props) => {
  const { onAction, fieldId, nestingPath } = props;
  const rowIndex = _head(nestingPath);

  const handleDeleteRow = () => {
    onAction({ type: ACTION_TYPES.ON_DELETE, payload: { id: fieldId, index: rowIndex } });
  };

  return (
    <Icon size={SIZES.MD} onClick={handleDeleteRow}>
      icon-trash
    </Icon>
  );
};

IconRenderer.propTypes = {
  onAction: PropTypes.func.isRequired,
  nestingPath: PropTypes.array.isRequired,
  fieldId: PropTypes.string.isRequired,
};

export default makeCellRenderer(IconRenderer);
