import React from 'react';
import PropTypes from 'prop-types';

import { NO_DATA } from '@tekion/tekion-base/app.constants';

import styles from './labelValueRenderer.module.scss';

const LabelValueRenderer = ({ label, value, className }) => (
  <div className={className}>
    <div className={styles.label}>{label}</div>
    <div className={styles.value}>{value}</div>
  </div>
);

LabelValueRenderer.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
};

LabelValueRenderer.defaultProps = {
  label: NO_DATA,
  value: '',
  className: '',
};

export default LabelValueRenderer;
