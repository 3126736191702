/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';

// constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { MAX_VIDEO_FILE_SIZE, VIDEO_UPLOAD_QUERY_OPTIONS } from './constants/video.general';

// HOC
import mediaUploaderHOC from '@tekion/tekion-components/organisms/mediaUploader/mediaUploaderHOC';

// components
import HelperText from '@tekion/tekion-components/atoms/HelperText';
import Video from './Video';

// hooks
import useUploadMedia from '../../hooks/useUploadMedia';
import useUpdateMediaNode from '../../hooks/useUpdateMediaNode';

function VideoContainer(props) {
  const { element = EMPTY_OBJECT, attributes, addMedia, mediaList, children, nodeProps } = props;
  const { enableMediaDeletion, enableMediaResizing, enableMediaHighlighting, enableVideoControls } = nodeProps;
  const { data = EMPTY_OBJECT } = element;
  const { mediaId, selectedFile } = data;
  const { isUploading } = useUploadMedia({
    mediaId,
    addMedia,
    selectedFile,
    element,
    queryOptions: VIDEO_UPLOAD_QUERY_OPTIONS,
  });
  useUpdateMediaNode({ mediaList, element });
  return (
    <div {...attributes}>
      {children}
      {isUploading ? (
        <HelperText type="assistive" contentEditable={false}>
          {__('Uploading Video...')}
        </HelperText>
      ) : (
        <Video
          element={element}
          enableMediaDeletion={enableMediaDeletion}
          enableMediaResizing={enableMediaResizing}
          enableMediaHighlighting={enableMediaHighlighting}
          enableVideoControls={enableVideoControls}
        />
      )}
    </div>
  );
}

VideoContainer.propTypes = {
  element: PropTypes.shape({
    data: PropTypes.shape({
      mediaId: PropTypes.string,
      selectedFile: PropTypes.object,
    }).isRequired,
    type: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  }).isRequired,
  attributes: PropTypes.object.isRequired,
  addMedia: PropTypes.func.isRequired,
  mediaList: PropTypes.array,
  children: PropTypes.node,
  nodeProps: PropTypes.object,
};

VideoContainer.defaultProps = {
  mediaList: EMPTY_ARRAY,
  nodeProps: EMPTY_OBJECT,
  children: null,
};

export default compose(withProps({ shouldRequestThumbnail: true, maxFileSize: MAX_VIDEO_FILE_SIZE }), mediaUploaderHOC)(VideoContainer);
