exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .recordActionModal_modalBodyContainer__eC3YGUemeU .ant-modal-body{padding:0}.recordActionModal_conditionBuilderFormSection__8uJHCmhmf7{max-width:120rem}.recordActionModal_actionDefSubHeader__xzPNR2mem2{display:flex;align-items:center}[dir=ltr] .recordActionModal_actionDefSubHeader__xzPNR2mem2 .recordActionModal_actionDefSubHeading__i86PU4S8pg{margin-right:1rem}[dir=rtl] .recordActionModal_actionDefSubHeader__xzPNR2mem2 .recordActionModal_actionDefSubHeading__i86PU4S8pg{margin-left:1rem}.recordActionModal_footerLeftSection__x6BGcHhXWb{display:flex;align-items:center}[dir=ltr] .recordActionModal_footerLeftSection__x6BGcHhXWb .recordActionModal_assignVarCheckbox__eYn6PUQ1P2{padding-right:1.2rem}[dir=rtl] .recordActionModal_footerLeftSection__x6BGcHhXWb .recordActionModal_assignVarCheckbox__eYn6PUQ1P2{padding-left:1.2rem}.recordActionModal_recordActionModalContentContainer__Dm2nQ4G4p5{max-height:50rem;overflow-y:scroll;width:100%}[dir] .recordActionModal_recordActionModalContentContainer__Dm2nQ4G4p5{padding-top:1.6rem}", ""]);

// Exports
exports.locals = {
	"modalBodyContainer": "recordActionModal_modalBodyContainer__eC3YGUemeU",
	"conditionBuilderFormSection": "recordActionModal_conditionBuilderFormSection__8uJHCmhmf7",
	"actionDefSubHeader": "recordActionModal_actionDefSubHeader__xzPNR2mem2",
	"actionDefSubHeading": "recordActionModal_actionDefSubHeading__i86PU4S8pg",
	"footerLeftSection": "recordActionModal_footerLeftSection__x6BGcHhXWb",
	"assignVarCheckbox": "recordActionModal_assignVarCheckbox__eYn6PUQ1P2",
	"recordActionModalContentContainer": "recordActionModal_recordActionModalContentContainer__Dm2nQ4G4p5"
};