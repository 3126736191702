const FIELD_IDS = {
  SECONDS: 'seconds',
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAY_OF_MONTH: 'dayOfMonth',
  MONTH: 'month',
  DAY_OF_WEEK: 'dayOfWeek',
  YEAR: 'year',
};

export { FIELD_IDS };
