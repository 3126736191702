import { defaultMemoize } from 'reselect';
import _property from 'lodash/property';
import _get from 'lodash/get';

import ColumnConfig from '@tekion/tekion-components/organisms/inputTable/builders/ColumnConfig';
import CellConfig from '@tekion/tekion-components/organisms/inputTable/builders/CellConfig';
import TextInputCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/TextInputCellRenderer';
import { getTextInputValueOnChange } from '@tekion/tekion-components/organisms/inputTable/utils/onChangeValueAccessors';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { COLUMN_IDS, MAX_LENGTH } from '../constants/listInputTable.constants';

const mapLabelProps = defaultMemoize((data = EMPTY_OBJECT) => {
  const isDisabled = _get(data, 'additional.isDisabled', false);
  const value = _get(data, `rowData.${COLUMN_IDS.LABEL}`, '');
  const { error } = data;

  return {
    value,
    disabled: isDisabled,
    placeholder: __('Enter Label'),
    error,
    maxLength: MAX_LENGTH,
  };
});

const mapValueProps = defaultMemoize((data = EMPTY_OBJECT) => {
  const isDisabled = _get(data, 'additional.isDisabled', false);
  const value = _get(data, `rowData.${COLUMN_IDS.VALUE}`, '');
  const { error } = data;

  return {
    value,
    disabled: isDisabled,
    placeholder: __('Enter Value'),
    error,
    maxLength: MAX_LENGTH,
  };
});

const LABEL_CELL = new CellConfig().setComponent(TextInputCellRenderer).setMapCellPropsToComponentProps(mapLabelProps);

const VALUE_CELL = new CellConfig().setComponent(TextInputCellRenderer).setMapCellPropsToComponentProps(mapValueProps);

const COLUMN_WIDTHS = {
  [COLUMN_IDS.LABEL]: '30rem',
  [COLUMN_IDS.VALUE]: '30rem',
};

const valueAccessor = _property(COLUMN_IDS.VALUE);
const labelAccessor = _property(COLUMN_IDS.LABEL);

const LABEL_COLUMN_CONFIG = new ColumnConfig(COLUMN_IDS.LABEL)
  .setHeader(__('Label'))
  .setGetValueFromOnChange(getTextInputValueOnChange)
  .setAccessor(labelAccessor)
  .addCellConfig(LABEL_CELL)
  .setWidth(COLUMN_WIDTHS[COLUMN_IDS.LABEL]);

const VALUE_COLUMN_CONFIG = new ColumnConfig(COLUMN_IDS.VALUE)
  .setHeader(__('Value'))
  .setGetValueFromOnChange(getTextInputValueOnChange)
  .setAccessor(valueAccessor)
  .addCellConfig(VALUE_CELL)
  .setWidth(COLUMN_WIDTHS[COLUMN_IDS.VALUE]);

const COLUMNS = [LABEL_COLUMN_CONFIG, VALUE_COLUMN_CONFIG];

export default COLUMNS;
