import { defaultMemoize } from 'reselect';

import _isEmpty from 'lodash/isEmpty';

import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';

import { EDIT_ACTION, REMOVE_ACTION } from '../constants/workspaceUserList.rowActions';

const getTableProps = defaultMemoize((handleRowAction, currentPage, loading, rows, totalNumberOfEntries) => ({
  showPagination: true,
  loading,
  currentPage: currentPage + 1,
  pageSize: rows,
  resultsPerPage: rows,
  totalNumberOfEntries,
  minRows: 0,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  showActions: true,
  rows,
  onRowActionClick: handleRowAction,
  getRowActions: () => [EDIT_ACTION, REMOVE_ACTION],
}));

const getPayload = defaultMemoize(({ searchText, searchField, pageSize, filterType, nextPageToken }) => {
  let payload = { rows: pageSize };
  if (!_isEmpty(searchText)) {
    payload = {
      ...payload,
      filters: [
        {
          field: searchField,
          values: [searchText],
          filterType,
        },
      ],
    };
  }
  if (!_isEmpty(nextPageToken)) {
    payload = {
      ...payload,
      nextPageToken,
    };
  }
  return payload;
});

export { getTableProps, getPayload };
