import _head from 'lodash/head';

const CONDITION_TYPES = {
  IF: 'IF',
  IF_ELSE: 'IF_ELSE',
  SWITCH: 'SWITCH',
};

const TASK_DEF_IDS = {
  IF: 'if',
  IF_ELSE: 'ifelse',
  CREATE_RECORD: 'CREATE_RECORD',
  UPDATE_RECORD: 'UPDATE_RECORD',
  GET_RECORDS: 'GET_RECORDS',
  UPSERT_RECORD: 'UPSERT_RECORD',
  DELAY_TASK: 'DelayTask',
  SWITCH: 'CheckField',
  REPEAT_NODE: 'RepeatExecution',
  GOTO: 'Goto',
  TIME_OF_THE_DAY: 'ConditionalTimeOfTheDay',
  SWITCH_WORKFLOW: 'SwitchWorkflowExecution',
  SET_VARIABLE: 'SetVariable',
  SEND_EMAIL: 'EMAIL',
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
  CALLOUT: 'CALLOUT',
  FETCH_AND_ITERATE: 'FetchAndIterate',
  FOR_LOOP: 'ForLoop',
  LOOP_CONTINUE: 'LoopContinue',
  LOOP_EXIT: 'LoopExit',
};

const TASK_ICONS = {
  [CONDITION_TYPES.IF]: 'icon-if',
  [CONDITION_TYPES.IF_ELSE]: 'icon-if-else',
  [CONDITION_TYPES.SWITCH]: 'icon-tick1',
};

const TASK_LABELS = {
  [CONDITION_TYPES.IF]: __('If'),
  [CONDITION_TYPES.IF_ELSE]: __('If/Else'),
  [CONDITION_TYPES.SWITCH]: __('Check Field Value'),
};

const MINUTES = 'MINUTES';
const HOURS = 'HOURS';
const DAYS = 'DAYS';

const TIME_MAPPER = {
  [MINUTES]: 60000,
  [HOURS]: 3600000,
  [DAYS]: 86400000,
};

const TIME_UNIT_OPTIONS = [
  {
    label: 'Minutes',
    value: MINUTES,
  },
  {
    label: 'Hours',
    value: HOURS,
  },
  {
    label: 'Days',
    value: DAYS,
  },
];

const DEFAULT_TIME_VALUE = {
  value: 0,
  unit: _head(TIME_UNIT_OPTIONS).value,
};

const VALIDATION_TYPES = {
  REALTIME: 'REALTIME',
  SAVETIME: 'SAVETIME',
};

const NODE_STRUCTURE = {
  width: 350,
  horizontalMargin: 50,
  containerStyle: {
    maxWidth: '35rem',
    height: 'auto',
    margin: 'auto',
  },
};

const LOOP_NODE_STYLES = { borderColor: 'grey', borderStyle: 'dashed' };
const VARIABLE_PREFIX_NAME = '$context';

const FIELD_EVENT_ACTION_NAMES = {
  CREATE_OPTION: 'create-option',
};

export {
  CONDITION_TYPES,
  TASK_DEF_IDS,
  TASK_LABELS,
  TASK_ICONS,
  TIME_MAPPER,
  DEFAULT_TIME_VALUE,
  TIME_UNIT_OPTIONS,
  VALIDATION_TYPES,
  MINUTES,
  NODE_STRUCTURE,
  VARIABLE_PREFIX_NAME,
  LOOP_NODE_STYLES,
  FIELD_EVENT_ACTION_NAMES,
};
