import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _castArray from 'lodash/castArray';
import _set from 'lodash/set';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchEntityDefByName } from '../../../../actions/entityManagement.actions';
import { searchViewConfigurations } from '../../../../actions/viewBuilderPage.actions';

import {
  getFieldOptions,
  getUpdatedViewConfiguration,
  convertTemplateValueFromEntityFieldToIndex,
  getOptionsForComplexAndRelationship,
  searchFormViewPayload,
  modalValueErrorCheck,
} from './compoundConfigModal.helpers';
import { getFieldDefinitionOfFieldFromEntity, getFirstFieldName } from '../../helpers/viewBuilder.helper';

import { ACTION_TYPES, FIELD_IDS, FORM_VIEW_TYPE_FIELD_IDS, MODAL_TYPE } from './compoundConfigModal.constants';
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';
import { VIEW_TYPES } from '../../constants/viewBuilder.constants';
import { ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_FIELD_IDS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

const handleInit = async ({ setState, getState }) => {
  const { entity, selectedViewComponent, viewConfiguration } = getState();

  const viewType = _get(viewConfiguration, 'viewType');
  let showSwitchForCellViewAndTemplate = false;
  setState({ isLoading: true, type: FIELD_IDS.TEMPLATE });

  let modalType = '';
  const properties = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);

  let componentFieldNames = tget(properties, ALL_VIEW_PROPERTY_KEYS.FIELD_NAMES);
  const selectedCellViewName = tget(properties, ALL_VIEW_PROPERTY_KEYS.CELL_VIEW_NAME);
  const complexViewName = tget(properties, ALL_VIEW_PROPERTY_KEYS.COMPLEX_VIEW_NAME);

  if (_isEmpty(componentFieldNames) || !_isEmpty(selectedCellViewName)) {
    modalType = MODAL_TYPE.NORMAL_FIELD_COMPOUND;
  } else {
    const selectedFieldDefinition = getFieldDefinitionOfFieldFromEntity(entity, componentFieldNames);
    const dataType = fieldDefinitionReader.dataType(selectedFieldDefinition);
    const fieldType = fieldDefinitionReader.fieldType(selectedFieldDefinition);

    if (fieldType === FIELD_TYPES.RELATIONSHIP) {
      modalType = MODAL_TYPE.RELATIONSHIP_FIELD_COMPOUND;
    } else if (dataType === DATA_TYPES.COMPLEX && viewType === VIEW_TYPES.FORM_VIEW) {
      modalType = MODAL_TYPE.COMPLEX_FIELD_VIEW_SELECT;
    } else if (dataType === DATA_TYPES.COMPLEX) {
      modalType = MODAL_TYPE.COMPLEX_FIELD_COMPOUND;
    } else {
      modalType = MODAL_TYPE.NORMAL_FIELD_COMPOUND;
    }
  }

  if (
    viewType === VIEW_TYPES.FORM_VIEW &&
    modalType === MODAL_TYPE.RELATIONSHIP_FIELD_COMPOUND &&
    !_isEmpty(_get(selectedViewComponent, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.LOOK_UP_FIELD_NAMES}`))
  ) {
    componentFieldNames = _get(selectedViewComponent, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.LOOK_UP_FIELD_NAMES}`);
  }
  let fieldOptions = [];
  const entityFieldsTemplateValue = _get(
    selectedViewComponent,
    `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.TEMPLATE}`,
    '',
  );
  const displayName = _get(selectedViewComponent, `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.TITLE}`, '');
  const templateValue = convertTemplateValueFromEntityFieldToIndex(entityFieldsTemplateValue, componentFieldNames);

  const newState = {
    values: {
      [FIELD_IDS.TEMPLATE]: templateValue,
      [FIELD_IDS.ENTITY_FIELDS]: componentFieldNames,
      [FIELD_IDS.DISPLAY_NAME]: displayName,
      [FIELD_IDS.CELL_VIEW_NAME]: selectedCellViewName,
      [FORM_VIEW_TYPE_FIELD_IDS.SELECTED_VIEW_NAME]: _castArray(complexViewName),
    },
  };

  let complexLookUpEntityName;

  if (modalType === MODAL_TYPE.NORMAL_FIELD_COMPOUND) {
    const fieldDefinitions = _filter(_get(entity, 'fieldDefinitions'), (field) => {
      const dataType = fieldDefinitionReader.dataType(field);
      const fieldType = fieldDefinitionReader.fieldType(field);

      return fieldType !== FIELD_TYPES.RELATIONSHIP && dataType !== DATA_TYPES.COMPLEX;
    });

    fieldOptions = getFieldOptions(fieldDefinitions, componentFieldNames);
    showSwitchForCellViewAndTemplate = true;
  } else {
    const fieldName = getFirstFieldName(getArraySafeValue(componentFieldNames));
    const fieldDefinition = getFieldDefinitionOfFieldFromEntity(entity, componentFieldNames);
    const relationshipLookUpEntity = fieldDefinitionReader.lookupFieldEntityType(fieldDefinition);
    complexLookUpEntityName = fieldDefinitionReader.complexFieldDefinitionEntityName(fieldDefinition);

    if (modalType === MODAL_TYPE.RELATIONSHIP_FIELD_COMPOUND) {
      const relationshipEntityDefinition = await fetchEntityDefByName(relationshipLookUpEntity);
      fieldOptions = getOptionsForComplexAndRelationship(relationshipEntityDefinition, fieldName, componentFieldNames);
    } else if (modalType === MODAL_TYPE.COMPLEX_FIELD_COMPOUND) {
      const complexEntityDefinition = await fetchEntityDefByName(complexLookUpEntityName);
      fieldOptions = getOptionsForComplexAndRelationship(complexEntityDefinition, fieldName, componentFieldNames);
    } else if (modalType === MODAL_TYPE.COMPLEX_FIELD_VIEW_SELECT) {
      const payload = searchFormViewPayload(_castArray(complexLookUpEntityName));
      const response = await searchViewConfigurations(payload);
      const complexEntityViews = tget(response, 'hits', EMPTY_ARRAY);
      fieldOptions = _map(complexEntityViews, (viewDef) => ({
        label: tget(viewDef, VIEW_CONFIGURATION_FIELD_IDS.DISPLAY_NAME, _get(viewDef, VIEW_CONFIGURATION_FIELD_IDS.NAME)),
        value: _get(viewDef, VIEW_CONFIGURATION_FIELD_IDS.NAME),
      }));
    }
  }

  setState({
    ...newState,
    options: fieldOptions,
    isLoading: false,
    modalType,
    showSwitchForCellViewAndTemplate,
    isCellViewEnabled: showSwitchForCellViewAndTemplate ? !_isEmpty(selectedCellViewName) : false,
    type: !_isEmpty(selectedCellViewName) ? FIELD_IDS.CELL_VIEW : FIELD_IDS.TEMPLATE,
    complexLookUpEntityName,
  });
};

const handleOnChange = ({ setState, getState, params }) => {
  const { id, value } = params;
  const { isCellViewEnabled, values, options, complexLookUpEntityName } = getState();

  const newValues = {
    ...values,
    [id]: value,
  };

  if (id === FORM_VIEW_TYPE_FIELD_IDS.SELECTED_VIEW_NAME) {
    _set(newValues, ALL_VIEW_PROPERTY_KEYS.COMPLEX_VIEW_ENTITY_NAME, complexLookUpEntityName);
  }

  let newOptions = options;
  if (id === FIELD_IDS.ENTITY_FIELDS) {
    newOptions = _map(options, (option) =>
      _includes(value, _get(option, 'value')) ? { ...option, isDisabled: true } : { ...option, isDisabled: false },
    );
  }
  const { errors } = modalValueErrorCheck(isCellViewEnabled, newValues);

  setState({ values: newValues, options: newOptions, errors });
};

const handleAddField = ({ setState, getState }) => {
  const { values } = getState();
  const entityFieldValues = _get(values, FIELD_IDS.ENTITY_FIELDS, []);
  entityFieldValues.push('');

  setState({ values: { ...values, [FIELD_IDS.ENTITY_FIELDS]: entityFieldValues } });
};

const handleDeleteField = ({ setState, getState, params }) => {
  const { values, options } = getState();
  const { index, value } = params;
  const entityFieldValues = _get(values, FIELD_IDS.ENTITY_FIELDS, []);
  entityFieldValues.splice(index, 1);
  const newOptions = _map(options, (option) => (_get(option, 'value') === value ? { ...option, isDisabled: false } : option));

  setState({ values: { ...values, [FIELD_IDS.ENTITY_FIELDS]: entityFieldValues }, options: newOptions });
};

const handleToggleSwitch = ({ setState, params }) => {
  const value = _get(params, 'value.payload.value');
  const isCellViewEnabled = value === FIELD_IDS.CELL_VIEW;
  setState({ isCellViewEnabled, type: value });
};

const handleModalSave = ({ getState, setState }) => {
  const { isCellViewEnabled, onCompoundConfigModalSave, viewConfiguration, cellViewConfigurationByName, selectedViewComponent, values, modalType } =
    getState();
  const { hasErrors, errors } = modalValueErrorCheck(isCellViewEnabled, values);

  if (hasErrors) {
    setState({ errors });
  } else {
    const selectedCellViewConfiguration = _get(cellViewConfigurationByName, [_get(values, [FIELD_IDS.CELL_VIEW_NAME])], EMPTY_OBJECT);

    const newViewConfiguration = getUpdatedViewConfiguration(
      isCellViewEnabled,
      viewConfiguration,
      selectedViewComponent,
      values,
      modalType,
      selectedCellViewConfiguration,
    );

    onCompoundConfigModalSave(newViewConfiguration);
  }
};

const handleModalCancel = ({ getState }) => {
  const { onCompoundConfigModalCancel } = getState();
  onCompoundConfigModalCancel();
};

const handleLoadOptionsFromAsyncSelect = ({ setState, params }) => {
  const cellViewConfigurationList = _get(params, 'value', EMPTY_ARRAY);
  const cellViewConfigurationByName = _keyBy(cellViewConfigurationList, VIEW_CONFIGURATION_FIELD_IDS.NAME);

  setState({ cellViewConfigurationByName: { ...cellViewConfigurationByName } });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnChange,
  [ACTION_TYPES.ON_TOGGLE_SWITCH]: handleToggleSwitch,
  [ACTION_TYPES.ADD_FIELD]: handleAddField,
  [ACTION_TYPES.DELETE_FIELD]: handleDeleteField,
  [ACTION_TYPES.ON_MODAL_SAVE]: handleModalSave,
  [ACTION_TYPES.ON_MODAL_CANCEL]: handleModalCancel,
  [ACTION_TYPES.ON_ASYNC_LOADED_OPTIONS]: handleLoadOptionsFromAsyncSelect,
};

export default ACTION_HANDLERS;
