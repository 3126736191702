import produce from 'immer';
import _snakeCase from 'lodash/snakeCase';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// Constants
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { getApplication } from '../../../../../../actions/applicationManagement.actions';
import { ACTION_TYPES, FIELD_IDS } from './applicationFormPage.constants';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import PAGE_IDS from '../../../../constants/PageIds.constants';

const handleOnMount = async ({ getState, setState }) => {
  const { match, history } = getState();
  const { applicationName } = _get(match, 'params', EMPTY_OBJECT);

  if (_isEmpty(applicationName)) {
    const data = tget(history, 'location.state', EMPTY_OBJECT);
    setState({ formMode: FORM_MODES.CREATE, applicationData: data });
  } else {
    let data = tget(history, 'location.state', EMPTY_OBJECT);

    if (_isEmpty(data)) {
      setState({ appDataLoading: true });
      data = await getApplication(applicationName);
    }

    setState({ applicationData: data, appDataLoading: false, formMode: FORM_MODES.EDIT });
  }
};

const handleOnCancel = ({ setState }) => {
  setState({ showCancelModal: true });
};

const handleOnCancelRequest = ({ setState }) => {
  setState({ showCancelModal: false });
};

const handleOnConfirmRequest = ({ getState, setState }) => {
  setState({ showCancelModal: false });
  const { history } = getState();
  const path = `${STUDIO_ROUTE}/${PAGE_IDS.APPLICATIONS}`;

  history.push(path);
};

const handleFieldChange = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { formMode, disableAutoFillForName = false } = getState();
  const { id, value } = params;

  setState(
    produce((draft) => {
      _set(draft, `applicationData.${id}`, value);
      if (formMode === FORM_MODES.CREATE && id === FIELD_IDS.DISPLAY_NAME && !disableAutoFillForName) {
        _set(draft, `applicationData.${FIELD_IDS.NAME}`, _snakeCase(value));
      } else if (id === FIELD_IDS.NAME) setState({ disableAutoFillForName: true });
    }),
  );
};

const handleErrors = ({ params = EMPTY_OBJECT, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const handleSubmit = async ({ getState }) => {
  const { formMode, match, history, applicationData } = getState();
  if (formMode === FORM_MODES.CREATE) {
    history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_IDS.APPLICATIONS}/${PAGE_IDS.CONFIGURE_APPLICATION}`, state: applicationData });
  } else {
    const { applicationName } = _get(match, 'params', EMPTY_OBJECT);
    history.push({
      pathname: `${STUDIO_ROUTE}/${PAGE_IDS.APPLICATIONS}/${applicationName}/${PAGE_IDS.CONFIGURE_APPLICATION}`,
      state: applicationData,
    });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_MOUNT]: handleOnMount,
  [ACTION_TYPES.ON_CANCEL]: handleOnCancel,
  [ACTION_TYPES.ON_CANCEL_REQUEST]: handleOnCancelRequest,
  [ACTION_TYPES.ON_CONFIRM_REQUEST]: handleOnConfirmRequest,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_REDIRECTION]: handleOnConfirmRequest,
};

export default ACTION_HANDLERS;
