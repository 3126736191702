import Button from '@tekion/tekion-components/atoms/Button';

const COLUMN_IDS = {
  DISPLAY_NAME: 'displayName',
  DESCRIPTION: 'description',
  NAME: 'name',
  TEMPLATE_TYPE: 'templateType',
};

const ACTION_TYPES = {
  REDIRECT_TO_TEMPLATE_CREATION: 'REDIRECT_TO_TEMPLATE_CREATION',
  INIT_DATA: 'INIT_DATA',
  EDIT_TEMPLATE_DETAIL: 'EDIT_TEMPLATE_DETAIL',
  DELETE_TEMPLATE_ROW_ACTION_CLICK: 'DELETE_TEMPLATE_ROW_ACTION_CLICK',
  DELETE_TEMPLATE_CONFIRMATION_CANCEL_CLICK: 'DELETE_TEMPLATE_CONFIRMATION_CANCEL_CLICK',
  DELETE_TEMPLATE_CONFIRMATION_DONE_CLICK: 'DELETE_TEMPLATE_CONFIRMATION_DONE_CLICK',
};

const HEADER_PROPS = {
  label: __('Notification templates'),
  headerRightActions: [
    {
      renderer: Button,
      renderOptions: {
        view: Button.VIEW.PRIMARY,
        label: __('Create Template'),
      },
      action: ACTION_TYPES.REDIRECT_TO_TEMPLATE_CREATION,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showHeader: true,
  showSearch: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const DELETE_TEMPLATE_CONTENT = __('Are you sure you want to delete template? It will be permanently deleted if you click yes.');

export { COLUMN_IDS, ACTION_TYPES, HEADER_PROPS, TABLE_MANAGER_PROPS, DELETE_TEMPLATE_CONTENT };
