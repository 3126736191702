import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import { COLUMN_IDS } from './projectApplicationList.constants';
import ACTION_TYPES from './projectApplicationList.actionTypes';

const COLUMN_CONFIG = [
  {
    Header: __('Application Name'),
    accessor: COLUMN_IDS.APPLICATION_NAME,
    id: COLUMN_IDS.APPLICATION_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Display Name'),
    accessor: COLUMN_IDS.DISPLAY_NAME,
    id: COLUMN_IDS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Description'),
    accessor: COLUMN_IDS.DESCRIPTION,
    id: COLUMN_IDS.DESCRIPTION,
    Cell: TextRenderer,
  },
];

const REMOVE_ACTION = {
  id: ACTION_TYPES.REMOVE_APPLICATION,
  name: __('Remove'),
};

const ROW_ACTIONS = [REMOVE_ACTION];

export { COLUMN_CONFIG, REMOVE_ACTION, ROW_ACTIONS };
