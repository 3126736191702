import { defaultMemoize } from 'reselect';

import _isEmpty from 'lodash/isEmpty';

import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';

import { EDIT_ACTION } from '../constants/projectList.rowActions';
import { TEXT_STARTS_WITH } from '../constants/projectList.general.constants';

const getRowActions = () => [EDIT_ACTION];

const getTableProps = defaultMemoize((handleRowAction, currentPage, loading, rows, totalNumberOfEntries) => ({
  currentPage: currentPage + 1,
  loading,
  showPagination: true,
  pageSize: rows,
  showActions: true,
  resultsPerPage: rows,
  totalNumberOfEntries,
  minRows: 0,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  rows,
  onRowActionClick: handleRowAction,
  getRowActions,
}));

const getPayload = defaultMemoize(({ searchText, searchField, pageSize, nextPageToken }) => {
  let payload = { rows: pageSize };

  if (!_isEmpty(searchText)) {
    payload = {
      filters: [
        {
          field: searchField,
          values: [searchText],
          filterType: TEXT_STARTS_WITH,
        },
      ],
      ...payload,
    };
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      nextPageToken,
      ...payload,
    };
  }

  return payload;
});

export { getPayload, getTableProps };
