const BOOLEAN_OPTIONS = [
  {
    label: __('True'),
    value: true,
  },
  {
    label: __('False'),
    value: false,
  },
];

const VALUE_FIELD_MODES = {
  STATIC_VALUE: 'STATIC_VALUE',
  FIELD: 'FIELD',
};

const MODE_SWITCHER_BUTTON_TEXTS = {
  STATIC_VALUE: __('Choose field'),
  FIELD: __('Enter static values'),
};

const MODE_SWITCHER_PLACEHOLDERS = {
  STATIC_VALUE: __('Enter values'),
  FIELD: __('Choose field'),
};

export { BOOLEAN_OPTIONS, VALUE_FIELD_MODES, MODE_SWITCHER_BUTTON_TEXTS, MODE_SWITCHER_PLACEHOLDERS };
