exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".passwordValidation_container__xeSa3gFVUV{display:flex}[dir] .passwordValidation_container__xeSa3gFVUV{padding-bottom:1.2rem}.passwordValidation_icon__3HoEWVvWeG{line-height:1.42rem}[dir=ltr] .passwordValidation_icon__3HoEWVvWeG{padding-right:1.2rem}[dir=rtl] .passwordValidation_icon__3HoEWVvWeG{padding-left:1.2rem}.passwordValidation_pass__3JUL1DBQEm{color:#60d156}.passwordValidation_fail__fdHcr8eBZy{color:#f52908}", ""]);

// Exports
exports.locals = {
	"container": "passwordValidation_container__xeSa3gFVUV",
	"icon": "passwordValidation_icon__3HoEWVvWeG",
	"pass": "passwordValidation_pass__3JUL1DBQEm",
	"fail": "passwordValidation_fail__fdHcr8eBZy"
};