/* eslint func-names: 0 */

import Hls from 'hls.js';

// Helpers
import { getQueryManifestQueryString, appendQueryStringToRequestUrl } from './floader.helpers';

const addQueryParamsToFragmentUrl = (defaultLoad) =>
  function (context, config, callbacks) {
    const queryString = getQueryManifestQueryString(context);
    appendQueryStringToRequestUrl(context, queryString);
    defaultLoad(context, config, callbacks);
  };

class fLoader extends Hls.DefaultConfig.loader {
  constructor(config) {
    super(config);
    const load = this.load.bind(this);
    this.load = addQueryParamsToFragmentUrl(load);
  }
}

export default fLoader;
