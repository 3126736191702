// Fields
import FIELD_IDS from '../constants/workspaceUserForm.fieldIds';

import styles from '../workspaceUserForm.module.scss';

const USER_MANAGEMENT_SECTIONS = [
  {
    className: styles.userFormSections,
    header: {
      className: 'p-t-12',
      label: __('User details'),
    },

    rows: [
      {
        className: 'p-t-8',
        columns: [FIELD_IDS.NAME, FIELD_IDS.DISPLAY_NAME],
      },
      {
        columns: [FIELD_IDS.EMAIL, FIELD_IDS.PHONE],
      },
      {
        columns: [FIELD_IDS.PROFILE_NAME, FIELD_IDS.ROLE],
      },
    ],
  },
  {
    className: styles.userFormSections,
    rows: [
      {
        columns: [FIELD_IDS.PROFILE_PICTURE],
      },
    ],
  },
];

export { USER_MANAGEMENT_SECTIONS };
