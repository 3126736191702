exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".userAvatar_container__scAk1CzhdJ{display:flex;align-items:center}[dir] .userAvatar_container__scAk1CzhdJ{cursor:pointer}.userAvatar_downIcon__DVgVD5xxYb{color:#fff}[dir=ltr] .userAvatar_downIcon__DVgVD5xxYb{margin-left:.4rem}[dir=rtl] .userAvatar_downIcon__DVgVD5xxYb{margin-right:.4rem}.userAvatar_menu__ric1XMqmWe{width:30rem}[dir] .userAvatar_menu__ric1XMqmWe{border-radius:.4rem}.userAvatar_homeWorkSpace__u95JzjMAYr{display:flex;flex-flow:row;align-items:center;justify-content:space-between}", ""]);

// Exports
exports.locals = {
	"container": "userAvatar_container__scAk1CzhdJ",
	"downIcon": "userAvatar_downIcon__DVgVD5xxYb",
	"menu": "userAvatar_menu__ric1XMqmWe",
	"homeWorkSpace": "userAvatar_homeWorkSpace__u95JzjMAYr"
};