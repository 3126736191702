import _map from 'lodash/map';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _snakeCase from 'lodash/snakeCase';
import _capitalize from 'lodash/capitalize';

import { EMPTY_ARRAY } from '@tekion/tekion-base/utils/constant';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { classNameValidator } from '../../../../../../utils/formValidators';
import { FIELD_IDS } from '../../../../../../constants/validationRuleBuilder.constants';
import entityReader from '../../../../../../readers/entity.reader';

const getRecordTypeOptions = (entity) => {
  let recordTypesOptions = entityReader.recordTypeDefinitionRecordTypes(entity);
  recordTypesOptions = _map(recordTypesOptions, (item) => ({ label: _get(item, 'displayName'), value: _get(item, 'name') }));
  return recordTypesOptions;
};

const getInitialFunction = (data) => {
  const methodInfoList = _get(data, 'methodInfoList', EMPTY_ARRAY);
  const javaFunctionStringArray = [];
  _forEach(methodInfoList, (value) => {
    const methodName = _get(value, 'name', EMPTY_STRING);
    const accessSpecifier = _get(value, 'accessSpecifier', 'public');
    const returnType = _get(value, 'returnType', EMPTY_STRING);
    const methodParams = _get(value, 'methodParams', EMPTY_ARRAY);
    let methodParamsString = '';
    _forEach(methodParams, (methodValue) => {
      methodParamsString += `${_get(methodValue, 'dataType', EMPTY_STRING)} ${_get(methodValue, 'paramName', EMPTY_STRING)},`;
    });

    methodParamsString = methodParamsString.slice(0, -1);
    javaFunctionStringArray.push(` ${accessSpecifier} ${returnType} ${methodName}(${methodParamsString}) {`);
    javaFunctionStringArray.push('    // write code');
    javaFunctionStringArray.push(' }');
  });
  const children = _map(javaFunctionStringArray, (item) => ({
    type: 'code_line',
    children: [
      {
        text: `${item}`,
      },
    ],
  }));
  const customCode = {
    type: 'code_block',
    lang: 'java',
    children: _isEmpty(children)
      ? [
          {
            type: 'code_line',
            children: [
              {
                text: '',
              },
            ],
          },
        ]
      : children,
  };
  return customCode;
};

const getCode = (formValues, metaData, code, className) => {
  const interfaceName = _get(metaData, 'name', '');
  let text;
  if (_isEmpty(_get(formValues, `${FIELD_IDS.CUSTOM_CODE}.plainText`, EMPTY_STRING))) {
    text = code;
  } else {
    text = `public class ${className} implements ${interfaceName}{      @Override     `;
    text += _get(formValues, `${FIELD_IDS.CUSTOM_CODE}.plainText`, EMPTY_STRING);
    text += '}';
  }
  return text;
};

const getClassName = (ruleName) => {
  if (classNameValidator(ruleName)) {
    return ruleName;
  } else {
    let className = _snakeCase(ruleName);
    className = _capitalize(className);
    return className;
  }
};

export { getRecordTypeOptions, getInitialFunction, getCode, getClassName };
