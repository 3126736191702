import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { GRID_PROPERTIES_CONFIGURE_FIELD_IDS } from '../constants/gridPropertiesConfigure.fieldIds';

const getFormFormattedSelectedWidgetProperties = (selectedWidgetProperties) => {
  const widgetPosition = tget(selectedWidgetProperties, GRID_PROPERTIES_CONFIGURE_FIELD_IDS.WIDGET_POSITION);

  if (!_isEmpty(widgetPosition)) {
    return { ...selectedWidgetProperties, [GRID_PROPERTIES_CONFIGURE_FIELD_IDS.WIDGET_POSITION]: _castArray(widgetPosition) };
  }
  return selectedWidgetProperties;
};

const getConfigFormattedSelectedWidgetProperties = (value, id, selectedWidgetProperties) => {
  if (id === GRID_PROPERTIES_CONFIGURE_FIELD_IDS.WIDGET_POSITION) {
    return { ...selectedWidgetProperties, [GRID_PROPERTIES_CONFIGURE_FIELD_IDS.WIDGET_POSITION]: getArraySafeValue(value) };
  }
  return { ...selectedWidgetProperties, [id]: value };
};

export { getFormFormattedSelectedWidgetProperties, getConfigFormattedSelectedWidgetProperties };
