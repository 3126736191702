import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';
import _map from 'lodash/map';
import _values from 'lodash/values';
import _omit from 'lodash/omit';
import _keyBy from 'lodash/keyBy';
import _has from 'lodash/has';
import _concat from 'lodash/concat';
import _noop from 'lodash/noop';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _forEach from 'lodash/forEach';
import _debounce from 'lodash/debounce';
import _compact from 'lodash/compact';

import FileReader from '@tekion/tekion-base/utils/File.reader';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { bulkDownloadCustomEntityMedia, getMediaListWithSignedUrls, uploadMediaList } from '../../actions/media.actions';
import mediaServices from '../../services/mediaServices';
import {
  getSanitizedFileName,
  getUpdatedMediaFiles,
  isFileSizeAllowed,
  getMediaIdsToBeResolved,
  getFileNames,
  getToasterMessage,
  getNewFileCount,
  getUpdatedMediaListWithUrl,
} from './mediaUploader.helper';
import { DEFAULT_MAX_FILE_SIZE, DUPLICATE_FILE_UPLOAD_MESSAGE, FILE_UPLOAD_SUCCESS_MESSAGE } from './mediaUploader.constants';

import fieldDefinitionReader from '../../readers/fieldDefinition.reader';

export default (WrappedComponent) => {
  const HOC = (props) => {
    const { multiple, uploadSuccessMessage, mediaList, notifyOnError, onChange, maxFileSize, minFileSize, fieldDef, recordId } = props;
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isFetchingMedia, setIsFetchingMedia] = useState(true);
    const [mediaIdVsSignedUrlMapper, setMediaIdVsSignedUrlMapper] = useState(EMPTY_OBJECT);
    const [, setFileCountMap] = useState(EMPTY_OBJECT);
    const entityName = fieldDefinitionReader.entityName(fieldDef);
    const fieldName = fieldDefinitionReader.name(fieldDef);
    const isMediaPreviewEnabled = fieldDefinitionReader.mediaPreviewEnabled(fieldDef);

    const checkFileName = useCallback(
      (files) => {
        const errorMessage = [];

        setFileCountMap((prevFileCount) => {
          let newFileCount = { ...prevFileCount };
          _map(files, (file) => {
            const fileName = getSanitizedFileName(_get(file, 'name', '')) || '';
            if (_has(newFileCount, fileName)) {
              newFileCount = { ...newFileCount };
              errorMessage.push({ error: DUPLICATE_FILE_UPLOAD_MESSAGE, fileName });
            } else {
              newFileCount = { ...newFileCount, [fileName]: 1 };
            }
          });
          return newFileCount;
        });
        return errorMessage;
      },

      [],
    );

    const addSelectedFilesToState = useCallback(
      (media) => {
        setSelectedFiles((prevSelectedFiles) => {
          if (multiple) {
            const newArray = _concat(prevSelectedFiles, media);
            return newArray;
          } else return _castArray(media);
        });
      },
      [multiple],
    );

    const updateSelectedFiles = useCallback(
      (updatedMedia, isUploadDone = false) => {
        setSelectedFiles((prevSelectedFiles) => {
          const updatedMediaFiles = getUpdatedMediaFiles(prevSelectedFiles, updatedMedia);
          if (isUploadDone) {
            onChange(updatedMediaFiles);
          }
          return updatedMediaFiles;
        });
      },
      [onChange],
    );

    // disabling this as we dont need any dep
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getSignedUrlsList = useCallback(
      _debounce(async (newMediaList) => {
        if (isMediaPreviewEnabled) {
          setIsFetchingMedia(true);
          const mediaIdsToBeResolved = getMediaIdsToBeResolved(newMediaList, mediaIdVsSignedUrlMapper);
          if (!_isEmpty(mediaIdsToBeResolved)) {
            const { mediaListWithUrl = [], preSignedUrlsByMediaId = {} } = await getMediaListWithSignedUrls(
              newMediaList,
              mediaIdsToBeResolved,
              entityName,
              _castArray(fieldName),
              recordId,
            );
            setSelectedFiles((prevSelectedFiles) => {
              const updatedMediaFiles = getUpdatedMediaListWithUrl(prevSelectedFiles, mediaListWithUrl);
              return updatedMediaFiles;
            });
            setMediaIdVsSignedUrlMapper((prevState) => ({ ...prevState, ...preSignedUrlsByMediaId }));
            setFileCountMap((prevFileCount) => getNewFileCount(prevFileCount, mediaListWithUrl));
          }

          setIsFetchingMedia(false);
        } else {
          setSelectedFiles(mediaList);
        }
      }, 500),
      [],
    );

    const mediaUploadFailureCb = useCallback(
      ({ media }) => {
        const sanitizedFiles = _filter(selectedFiles, (selectedFile) => selectedFile.name !== media.name);
        setSelectedFiles(sanitizedFiles);
        setIsUploading(false);
        notifyOnError();
      },
      [notifyOnError, selectedFiles],
    );

    const mediaUploadProgressCb = useCallback(
      ({ media, progress }) => {
        const updatedMedia = {
          ...media,
          contentType: media.file.type,
          isUploading: progress >= 0,
          name: media.name,
          progress,
          cancelSource: media.cancelSource,
          documentId: media.documentId,
        };
        updateSelectedFiles(updatedMedia);
      },
      [updateSelectedFiles],
    );

    const mediaUploadSuccessCb = useCallback(
      async ({ response }) => {
        const { fileName } = response;
        updateSelectedFiles({ ...response, name: fileName, isUploading: false }, true);
        setIsUploading(false);
        toaster(TOASTER_TYPE.SUCCESS, _isEmpty(uploadSuccessMessage) ? FILE_UPLOAD_SUCCESS_MESSAGE : uploadSuccessMessage);
      },
      [updateSelectedFiles, uploadSuccessMessage],
    );

    const addMedia = useCallback(
      async (files) => {
        setIsUploading(true);
        if (!multiple) {
          setFileCountMap(() => ({}));
        }

        const errorMessages = await checkFileName(files);
        if (!_isEmpty(errorMessages)) {
          const fileNames = getFileNames(errorMessages);
          const toasterMessage = getToasterMessage(fileNames);
          notifyOnError();
          return toaster(TOASTER_TYPE.ERROR, toasterMessage);
        }

        const mediaToUpload = _map(files, (file) => ({
          file,
          name: file && getSanitizedFileName(file.name),
          url: FileReader.getFileURL(file),
          cancelSource: mediaServices.getSource(),
          documentId: FileReader.getFileURL(file),
          assetType: `ENTITY_${entityName}_${fieldName}`,
        }));

        const errorMessage = isFileSizeAllowed(mediaToUpload, maxFileSize, minFileSize);

        if (!_isEmpty(errorMessage)) {
          notifyOnError();
          return toaster(TOASTER_TYPE.ERROR, errorMessage);
        }

        await _forEach(mediaToUpload, (media) => addSelectedFilesToState(media));
        await uploadMediaList(mediaToUpload, mediaUploadProgressCb, mediaUploadSuccessCb, mediaUploadFailureCb);
        return EMPTY_OBJECT;
      },
      [
        multiple,
        checkFileName,
        maxFileSize,
        minFileSize,
        mediaUploadProgressCb,
        mediaUploadSuccessCb,
        mediaUploadFailureCb,
        notifyOnError,
        addSelectedFilesToState,
        entityName,
        fieldName,
      ],
    );

    const removeMedia = (mediaListToBeRemoved) => {
      const mediaIdList = _map(_castArray(mediaListToBeRemoved), 'mediaId');
      const selectedFilesWithMediaId = _keyBy(selectedFiles, 'mediaId');
      const mediaToUpdate = _values(_omit(selectedFilesWithMediaId, mediaIdList));
      onChange(_castArray(mediaToUpdate));
      setSelectedFiles(mediaToUpdate);
      setFileCountMap(() => {
        let newFileCount;
        _map(mediaToUpdate, (item) => {
          const fileName = _get(item, 'name');
          newFileCount = { ...newFileCount, [fileName]: 1 };
        });
        return newFileCount;
      });
    };

    const handleSelection = ({ ...data }) => {
      const { checked } = data;
      _set(data, 'checked', !checked);
      updateSelectedFiles(data);
    };

    const cancelMediaUpload = (documentId) => {
      selectedFiles.map((media) => {
        if (media.cancelSource && media.documentId === documentId) {
          media.cancelSource.cancel();
          toaster(TOASTER_TYPE.ERROR, __('Media Upload Aborted'));
        }
        return null;
      });

      setSelectedFiles((prevState) => _filter(prevState.selectedFiles, (media) => media.documentId !== documentId));
    };

    const onRefresh = useCallback(() => getSignedUrlsList(mediaList), [getSignedUrlsList, mediaList]);

    const downloadAllMedia = useCallback(async () => {
      const mediaIds = _compact(_map(mediaList, (media) => _get(media, 'mediaId')));

      await bulkDownloadCustomEntityMedia({ entityName, fieldName, recordId, mediaIds });
    }, [entityName, fieldName, mediaList, recordId]);

    const handleDownloadButtonClick = useCallback(
      async (event) => {
        event.stopPropagation();

        await downloadAllMedia();
      },
      [downloadAllMedia],
    );

    useEffect(() => {
      if (!_isEmpty(mediaList)) {
        getSignedUrlsList(mediaList);
      }
    }, [getSignedUrlsList, mediaList]);

    return (
      <WrappedComponent
        {...props}
        multiple={multiple}
        isUploading={isUploading}
        isFetchingMedia={isFetchingMedia}
        isMediaPreviewEnabled={isMediaPreviewEnabled}
        mediaList={selectedFiles}
        onSelection={handleSelection}
        addMedia={addMedia}
        removeMedia={removeMedia}
        cancelMediaUpload={cancelMediaUpload}
        onRefresh={onRefresh}
        onDownloadButtonClick={handleDownloadButtonClick}
      />
    );
  };

  HOC.propTypes = {
    shouldRequestThumbnail: PropTypes.bool,
    multiple: PropTypes.bool,
    shouldRequestSignedThumbnailUrl: PropTypes.bool,
    assetId: PropTypes.string,
    id: PropTypes.string,
    uploadSuccessMessage: PropTypes.string,
    recordId: PropTypes.string.isRequired,
    maxFileSize: PropTypes.number,
    minFileSize: PropTypes.number,
    fieldDef: PropTypes.object.isRequired,
    mediaList: PropTypes.array,
    value: PropTypes.array,
    cancelMediaUpload: PropTypes.func,
    onChange: PropTypes.func,
    notifyOnError: PropTypes.func,
  };

  HOC.defaultProps = {
    shouldRequestThumbnail: false,
    multiple: false,
    shouldRequestSignedThumbnailUrl: false,
    assetId: '',
    id: '',
    uploadSuccessMessage: undefined,
    maxFileSize: DEFAULT_MAX_FILE_SIZE,
    minFileSize: null,
    value: EMPTY_ARRAY,
    mediaList: EMPTY_ARRAY,
    cancelMediaUpload: _noop,
    onChange: _noop,
    notifyOnError: _noop,
  };

  return HOC;
};
