import _pick from 'lodash/pick';
import _valuesIn from 'lodash/valuesIn';
import _includes from 'lodash/includes';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { updateSelectedViewComponent } from '../../organisms/propertiesForm/propertiesForm.helper';

import { FIELD_IDS, CUSTOM_ON_CHANGE_FIELD_IDS } from './gridViewRendererPropertiesForm.constants';
import {
  PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS,
  VIEW_COMPONENT_UPDATE_ACTION_ID,
  VIEW_COMPONENT_UPDATE_ACTION_TYPE,
} from '../../organisms/propertiesForm/propertiesForm.constants';

const ARRAY_OF_FIELD_IDS = _valuesIn(FIELD_IDS);

const getGridViewRendererPropertiesFromProperties = (properties) => _pick(properties, ARRAY_OF_FIELD_IDS);

const downloadOptionsOnChangeHandler = (selectedViewComponent, payload) => {
  let value = tget(payload, 'value');
  const id = tget(payload, 'id');

  value = _map(value, (downloadValue) => ({
    displayName: downloadValue,
    downloadType: downloadValue,
    metadata: {},
  }));

  const propertiesToUpdate = [
    { [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_NAME]: id, [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_VALUE]: value },
  ];

  const action = {
    [VIEW_COMPONENT_UPDATE_ACTION_ID.ACTION_TYPE]: VIEW_COMPONENT_UPDATE_ACTION_TYPE.PROPERTIES_UPDATE,
    [VIEW_COMPONENT_UPDATE_ACTION_ID.PAYLOAD]: propertiesToUpdate,
  };

  const newSelectedViewComponent = updateSelectedViewComponent(selectedViewComponent, action);

  return newSelectedViewComponent;
};

const filterFieldOnChangeHandler = (selectedViewComponent, payload) => {
  const value = tget(payload, 'value', {});

  const propertiesToUpdate = _map(value, (_value, _key) => ({
    [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_NAME]: _key,
    [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_VALUE]: _value,
  }));

  const action = {
    [VIEW_COMPONENT_UPDATE_ACTION_ID.ACTION_TYPE]: VIEW_COMPONENT_UPDATE_ACTION_TYPE.PROPERTIES_UPDATE,
    [VIEW_COMPONENT_UPDATE_ACTION_ID.PAYLOAD]: propertiesToUpdate,
  };

  const newSelectedViewComponent = updateSelectedViewComponent(selectedViewComponent, action);

  return newSelectedViewComponent;
};

const searchOptionOnChangeHandler = (selectedViewComponent, payload) => {
  const value = tget(payload, 'value', {});

  const propertiesToUpdate = _map(value, (_value, _key) => ({
    [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_NAME]: _key,
    [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_VALUE]: _value,
  }));

  const action = {
    [VIEW_COMPONENT_UPDATE_ACTION_ID.ACTION_TYPE]: VIEW_COMPONENT_UPDATE_ACTION_TYPE.PROPERTIES_UPDATE,
    [VIEW_COMPONENT_UPDATE_ACTION_ID.PAYLOAD]: propertiesToUpdate,
  };

  const newSelectedViewComponent = updateSelectedViewComponent(selectedViewComponent, action);

  return newSelectedViewComponent;
};

const sortDetailsOnChangeHandler = (selectedViewComponent, payload) => {
  const value = tget(payload, 'value', {});

  const propertiesToUpdate = _map(value, (_value, _key) => ({
    [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_NAME]: _key,
    [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_VALUE]: _value,
  }));

  const action = {
    [VIEW_COMPONENT_UPDATE_ACTION_ID.ACTION_TYPE]: VIEW_COMPONENT_UPDATE_ACTION_TYPE.PROPERTIES_UPDATE,
    [VIEW_COMPONENT_UPDATE_ACTION_ID.PAYLOAD]: propertiesToUpdate,
  };

  const newSelectedViewComponent = updateSelectedViewComponent(selectedViewComponent, action);

  return newSelectedViewComponent;
};

const customOnChangeHandlerByFieldId = {
  [FIELD_IDS.DOWNLOAD_OPTIONS]: downloadOptionsOnChangeHandler,
  [FIELD_IDS.FILTER_COMPONENT]: filterFieldOnChangeHandler,
  [FIELD_IDS.SEARCH_OPTIONS]: searchOptionOnChangeHandler,
  [FIELD_IDS.SORT_DETAILS]: sortDetailsOnChangeHandler,
};

const getUpdatedSelectedViewComponentFromOnChangeValue = (selectedViewComponent, payload = EMPTY_OBJECT) => {
  const { id, value } = payload;

  if (!_includes(CUSTOM_ON_CHANGE_FIELD_IDS, id)) {
    const propertiesToUpdate = [
      { [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_NAME]: id, [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_VALUE]: value },
    ];

    const action = {
      [VIEW_COMPONENT_UPDATE_ACTION_ID.ACTION_TYPE]: VIEW_COMPONENT_UPDATE_ACTION_TYPE.PROPERTIES_UPDATE,
      [VIEW_COMPONENT_UPDATE_ACTION_ID.PAYLOAD]: propertiesToUpdate,
    };

    const newSelectedViewComponent = updateSelectedViewComponent(selectedViewComponent, action);

    return newSelectedViewComponent;
  }

  const resultFromCustomOnChange = customOnChangeHandlerByFieldId[id](selectedViewComponent, payload);

  return resultFromCustomOnChange;
};

export { getGridViewRendererPropertiesFromProperties, getUpdatedSelectedViewComponentFromOnChangeValue };
