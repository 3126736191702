import PROPERTIES_FORM_FIELD_IDS from '../components/propertiesForm/constants/propertiesForm.fieldIds';
import GENERAL_DETAILS_FORM_FIELD_IDS from '../components/generalDetailsForm/constants/generalDetailsForm.fieldIds';
import LOOKUP_DETAILS_FORM_FIELD_IDS from '../components/lookupDetailsForm/constants/lookupDetailsForm.fieldIds';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import TEXT_PROPERTIES_FORM_FIELD_IDS from '../components/textPropertiesForm/constants/textPropertiesForm.fieldIds';
import VIEW_DETAILS_FORM_FIELD_IDS from '../components/viewDetailsForm/constants/viewDetailsForm.fieldIds';
import SELECT_OPTIONS_FORM_FIELD_IDS from '../components/selectOptionsForm/constants/selectOptionsForm.fieldsIds';
import COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS from '../components/complexFieldDetailsForm/constants/complexFieldDetailsForm.fieldIds';
import RELATIONSHIP_DETAILS_FORM_FIELD_IDS from '../components/relationshipDetailsForm/constants/relationshipDetailsForm.fieldIds';
import FIELD_TYPES from '../../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../../constants/fieldDefinition.dataTypes';
import FIELDS_FORM_FIELD_IDS from './fieldsForm.fieldIds';

const FIELD_FORM_CONTEXT_ID = 'fieldDetailsForm';

const SUBMIT_ADDITIONAL_VALUE = {
  SAVE: 'SAVE',
  SAVE_AND_CREATE_NEW: 'SAVE_AND_CREATE_NEW',
};

const DATA_TYPES_WITHOUT_LOOKUP_ENABLED = [DATA_TYPES.TEXT, DATA_TYPES.NUMBER];

const EDIT_MODE_DISABLED_HELP_TEXT = __('This value cannot be changed');

const DISABLED_FIELD_PROPERTIES = {
  [GENERAL_DETAILS_FORM_FIELD_IDS.NAME]: {
    [FORM_MODES.EDIT]: {},
  },

  [GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_DATA_TYPE]: {
    [FORM_MODES.EDIT]: {
      disableKey: 'isDisabled',
    },
    [FIELD_TYPES.RELATIONSHIP]: {
      disableKey: 'isDisabled',
    },
  },
  [GENERAL_DETAILS_FORM_FIELD_IDS.FIELD_TYPE]: {
    [FORM_MODES.EDIT]: {
      disableKey: 'isDisabled',
    },
  },
  [GENERAL_DETAILS_FORM_FIELD_IDS.MULTI_VALUED]: {
    [FORM_MODES.EDIT]: {
      helpText: EDIT_MODE_DISABLED_HELP_TEXT,
    },
    [FIELD_TYPES.RICH_TEXT_EDITOR]: {
      helpText: __('Text editor fields cannot be multi-valued'),
      value: false,
    },
    [DATA_TYPES.BOOLEAN]: {
      helpText: __('Boolean fields cannot be multi-valued'),
      value: false,
    },
    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: {
      helpText: __('Child aggregate summary fields cannot be multi-valued'),
      value: false,
    },
    [FIELD_TYPES.RANGE]: {
      helpText: __('Range fields cannot be multi-valued'),
      value: false,
    },
    [LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]: {
      helpText: __('For enabling lookup, field must not be multi-valued'),
      value: false,
    },
    [PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT]: {
      helpText: __('Unique fields cannot be multi-valued'),
      value: false,
    },
  },
  [LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]: {
    [FORM_MODES.EDIT]: {
      helpText: EDIT_MODE_DISABLED_HELP_TEXT,
    },
    [GENERAL_DETAILS_FORM_FIELD_IDS.MULTI_VALUED]: {
      helpText: __('Multi-valued fields cannot be used for lookup'),
      value: false,
    },
    [DATA_TYPES.BOOLEAN]: {
      value: false,
    },
    [DATA_TYPES.DATE]: {
      value: false,
    },
    [DATA_TYPES.DATE_TIME]: {
      value: false,
    },
    [DATA_TYPES.COMPLEX]: {
      value: false,
    },
    [FIELD_TYPES.RICH_TEXT_EDITOR]: {
      value: false,
    },
    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: {
      value: false,
    },
    [FIELD_TYPES.MEDIA]: {
      value: false,
    },
    [FIELD_TYPES.RANGE]: {
      value: false,
    },
    [FIELD_TYPES.SELECT]: {
      value: false,
    },
    [FIELD_TYPES.RELATIONSHIP]: {
      value: false,
    },
  },
  [SELECT_OPTIONS_FORM_FIELD_IDS.OPTIONS]: {
    [FORM_MODES.EDIT]: {
      disableKey: 'isDisabled',
    },
  },
  [COMPLEX_FIELD_DETAILS_FORM_FIELD_IDS.ENTITY_NAME]: {
    [FORM_MODES.EDIT]: {
      disableKey: 'isDisabled',
    },
  },
  [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.GROUPS_ALLOWED]: {
    [FORM_MODES.EDIT]: {
      helpText: EDIT_MODE_DISABLED_HELP_TEXT,
    },
  },
  [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY]: {
    [FORM_MODES.EDIT]: {
      disableKey: 'isDisabled',
    },
  },
  [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP]: {
    [FORM_MODES.EDIT]: {
      disableKey: 'isDisabled',
    },
  },
  [VIEW_DETAILS_FORM_FIELD_IDS.LIST_VIEW_ENABLED]: {
    [FORM_MODES.EDIT]: {
      helpText: EDIT_MODE_DISABLED_HELP_TEXT,
    },
  },
  [VIEW_DETAILS_FORM_FIELD_IDS.DETAILED_VIEW_ENABLED]: {
    [FORM_MODES.EDIT]: {
      helpText: EDIT_MODE_DISABLED_HELP_TEXT,
    },
  },
  [TEXT_PROPERTIES_FORM_FIELD_IDS.MAX_TEXT_LENGTH]: {
    [FORM_MODES.EDIT]: {
      helpText: __('Existing records may not follow the updated constraints!'),
      isDisabled: false,
    },
  },
  [TEXT_PROPERTIES_FORM_FIELD_IDS.MIN_TEXT_LENGTH]: {
    [FORM_MODES.EDIT]: {
      helpText: __('Existing records may not follow the updated constraints!'),

      isDisabled: false,
    },
  },
  [TEXT_PROPERTIES_FORM_FIELD_IDS.PATTERN]: {
    [FORM_MODES.EDIT]: {
      helpText: __('Existing records may not follow the updated constraints!'),
      isDisabled: false,
    },
  },
  [PROPERTIES_FORM_FIELD_IDS.EDITABLE]: {
    [FORM_MODES.EDIT]: {
      helpText: EDIT_MODE_DISABLED_HELP_TEXT,
      value: true,
    },
    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: {
      helpText: __('Child aggregate summary fields cannot be editable'),
      value: false,
    },
  },
  [PROPERTIES_FORM_FIELD_IDS.SORTABLE]: {
    [FORM_MODES.EDIT]: {
      helpText: EDIT_MODE_DISABLED_HELP_TEXT,
    },
    [FIELD_TYPES.MEDIA]: {
      helpText: __('Media fields cannot be sortable'),
      value: false,
    },
    [FIELD_TYPES.RICH_TEXT_EDITOR]: {
      helpText: __('Text editor fields cannot be sortable'),
      value: false,
    },
    [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM]: {
      helpText: __('Text fields with length more than 256 cannot be sortable'),
      value: false,
    },
  },
  [PROPERTIES_FORM_FIELD_IDS.FILTERABLE]: {
    [FORM_MODES.EDIT]: {
      helpText: EDIT_MODE_DISABLED_HELP_TEXT,
    },
    [FIELD_TYPES.MEDIA]: {
      helpText: __('Media fields cannot be filterable'),
      value: false,
    },
    [FIELD_TYPES.RICH_TEXT_EDITOR]: {
      helpText: __('Text editor fields cannot be filterable'),
      value: false,
    },
    [LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]: {
      helpText: __('For enabling lookup, field must be filterable'),
      value: true,
    },
    [PROPERTIES_FORM_FIELD_IDS.SORTABLE]: {
      helpText: __('Sortable fields must be filterable'),
      value: true,
    },
    [FIELDS_FORM_FIELD_IDS.TEXT_PROPERTIES_FORM]: {
      helpText: __('Text fields with length more than 256 cannot be filterable'),
      value: false,
    },
  },
  [PROPERTIES_FORM_FIELD_IDS.SEARCHABLE]: {
    [FORM_MODES.EDIT]: {
      helpText: EDIT_MODE_DISABLED_HELP_TEXT,
    },
    [DATA_TYPES.NUMBER]: {
      helpText: __('Number fields cannot be searchable'),
      value: false,
    },
    [FIELD_TYPES.RANGE]: {
      helpText: __('Range fields cannot be searchable'),
      value: false,
    },
  },
  [PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT]: {
    [FORM_MODES.EDIT]: {
      helpText: EDIT_MODE_DISABLED_HELP_TEXT,
      // value: true,
    },
    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: {
      helpText: __('Child aggregate summary fields cannot be unique'),
      value: false,
    },
    [FIELD_TYPES.SELECT]: {
      helpText: __('Select fields cannot be unique'),
      value: false,
    },
    [FIELD_TYPES.RANGE]: {
      helpText: __('Range fields cannot be unique'),
      value: false,
    },
    [FIELD_TYPES.RICH_TEXT_EDITOR]: {
      helpText: __('Text editor fields cannot be unique'),
      value: false,
    },
    [FIELD_TYPES.MEDIA]: {
      helpText: __('Media fields cannot be unique'),
      value: false,
    },
    [GENERAL_DETAILS_FORM_FIELD_IDS.MULTI_VALUED]: {
      helpText: __('Multi-valued fields cannot be unique'),
      value: false,
    },
    [LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]: {
      helpText: __('For enabling lookup, field must be unique'),
      value: true,
    },
  },
  [PROPERTIES_FORM_FIELD_IDS.MANDATORY]: {
    [PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT]: {
      helpText: __('Unique fields must be mandatory'),
      value: true,
    },
    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: {
      helpText: __('Child aggregate summary fields cannot be mandatory'),
      value: false,
    },
  },
  [PROPERTIES_FORM_FIELD_IDS.EXPORTABLE]: {
    [LOOKUP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENABLED]: {
      helpText: __('For enabling lookup, field must be exportable'),
      value: true,
    },
  },
  [PROPERTIES_FORM_FIELD_IDS.IMPORTABLE]: {
    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: {
      helpText: __('Child aggregate summary fields cannot be importable'),
      value: false,
    },
  },
  [PROPERTIES_FORM_FIELD_IDS.CREATABLE]: {
    [FIELD_TYPES.CHILD_AGGREGATE_SUMMARY]: {
      helpText: __('Child aggregate summary fields cannot be creatable'),
      value: false,
    },
    [PROPERTIES_FORM_FIELD_IDS.EDITABLE]: {
      helpText: __('Editable fields must be creatable'),
      value: true,
    },
  },
  [PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION]: {
    [FORM_MODES.EDIT]: {
      helpText: EDIT_MODE_DISABLED_HELP_TEXT,
      disableKey: 'isDisabled',
    },
  },
};

export { FIELD_FORM_CONTEXT_ID, SUBMIT_ADDITIONAL_VALUE, DISABLED_FIELD_PROPERTIES, DATA_TYPES_WITHOUT_LOOKUP_ENABLED };
