import _concat from 'lodash/concat';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

const ACTION_TYPES = {
  ON_ADD_CLICK: 'ON_ADD_CLICK',
  DELETE_ROW: 'DELETE_ROW',
  ON_ERROR: 'ON_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
};

const handleFieldChange = ({ props, params = EMPTY_OBJECT }) => {
  const { value: rows, onAction } = props;
  const { index, value } = params;

  const updatedRows = [...rows];
  updatedRows.splice(index, 1, value);

  onAction({
    type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
    payload: { value: updatedRows },
  });
};

const handleAddClick = ({ props }) => {
  const { value: rows, onAction } = props;

  onAction({
    type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
    payload: { value: _concat(rows, EMPTY_OBJECT) },
  });
};

const handleRowDelete = ({ props, params = EMPTY_OBJECT }) => {
  const { value: rows, onAction } = props;
  const { index } = params;

  const updatedRows = [...rows];
  updatedRows.splice(index, 1);

  onAction({
    type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
    payload: { value: updatedRows },
  });
};

const handleError = ({ props, params = EMPTY_OBJECT }) => {
  const { error: errors, onAction } = props;
  const { index, error } = params;

  const updatedErrors = [...errors];
  updatedErrors.splice(index, 1, error);

  onAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: { errors: updatedErrors },
  });
};

const handleClearError = ({ props, params = EMPTY_OBJECT }) => {
  const { error: errors, onAction } = props;
  const { index } = params;

  const updatedErrors = [...errors];
  updatedErrors.splice(index, 1);

  onAction({
    type: FORM_ACTION_TYPES.VALIDATION_SUCCESS,
    payload: { errors: updatedErrors },
  });
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [ACTION_TYPES.ON_ADD_CLICK]: handleAddClick,
  [ACTION_TYPES.DELETE_ROW]: handleRowDelete,
  [ACTION_TYPES.ON_ERROR]: handleError,
  [ACTION_TYPES.CLEAR_ERROR]: handleClearError,
};

export { ACTION_TYPES };

export default ACTION_HANDLERS;
