import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _map from 'lodash/map';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import { tget } from '@tekion/tekion-base/utils/general';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Tabs from '@tekion/tekion-components/molecules/Tabs';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components/molecules/loader';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';

import ApplicationPreviewTabContent from './components/applicationPreviewTabContent';
import ApprovalPreviewComponent from '../approvalPreviewComponent';
import withSize from '../../../../../../../../connectors/withSize';

import ACTION_HANDLERS from './helpers/applicationPreviewPage.actionHandlers';
import ACTION_TYPES from './constants/applicationPreviewPage.actionTypes';
import { APPLICATION_CONTEXT_KEYS } from '../../../../../../../../constants/applicationRenderer.constants';
import { NAVIGATION_TYPES, TAB_IDS } from '../../constants/applicationBuilder.constants';

const TAB_BAR_STYLE = { backgroundColor: '#f4f5f6' };

const ApplicationPreviewPage = ({
  loading,
  isNavigationRequired,
  contentHeight,
  contentWidth,
  navigationType,
  selectedTabId,
  allTabs,
  history,
  landingPageData,
  applicationContext,
  onAction,
  ...restProps
}) => {
  const handleTabClick = useCallback(
    (tab) => {
      onAction({
        type: ACTION_TYPES.ON_TAB_CLICK,
        payload: { tab },
      });
    },
    [onAction],
  );

  const tabHeader = useCallback((tabInfo, index) => {
    const icon = _get(tabInfo, 'tabIcon');
    if (index === 0)
      return (
        <div className="flex-center">
          <FontIcon className="m-r-8">icon-home1</FontIcon>
          <div>{tget(tabInfo, 'displayName', NO_DATA)}</div>
        </div>
      );
    else
      return (
        <div className="flex-center">
          <FontIcon className="m-r-8">{icon}</FontIcon>
          <div>{tget(tabInfo, 'displayName', NO_DATA)}</div>
        </div>
      );
  }, []);

  const navigationSpecificTabsContent = useMemo(
    () => (navigationType === NAVIGATION_TYPES.LEFT ? { style: { height: contentHeight - 35 } } : { style: { height: contentHeight - 110 } }),
    [navigationType, contentHeight],
  );

  const updatedContentWidth = useMemo(
    () => (navigationType === NAVIGATION_TYPES.LEFT ? contentWidth : contentWidth + 200),
    [navigationType, contentWidth],
  );

  const updatedContentHeight = useMemo(
    () => (navigationType === NAVIGATION_TYPES.LEFT ? contentHeight - 35 : contentHeight - 100),
    [navigationType, contentHeight],
  );

  const renderBody = () => (
    <PropertyControlledComponent
      controllerProperty={isNavigationRequired}
      fallback={
        <ApplicationPreviewTabContent
          navigationType={navigationType}
          contentHeight={contentHeight}
          contentWidth={updatedContentWidth}
          tabInfo={landingPageData}
          history={history}
          style={{ height: contentHeight }}
          applicationContext={applicationContext}
          {...restProps}
        />
      }
    >
      <Tabs
        animated
        className="full-width full-height"
        tabPosition={navigationType}
        tabBarStyle={TAB_BAR_STYLE}
        activeKey={selectedTabId}
        onTabClick={handleTabClick}
      >
        {_map(allTabs, (tabInfo, index) => (
          <Tabs.TabPane tab={tabHeader(tabInfo, index)} key={tabInfo.id}>
            {tabInfo.id === TAB_IDS.APPROVAL ? (
              <ApprovalPreviewComponent
                isMountedInsideApplication
                contentHeight={updatedContentHeight}
                contentWidth={updatedContentWidth}
                currentLoggedInUserData={_get(applicationContext, APPLICATION_CONTEXT_KEYS.CURRENT_USER)}
                applicationProperties={tabInfo}
              />
            ) : (
              <ApplicationPreviewTabContent
                contentHeight={updatedContentHeight}
                contentWidth={updatedContentWidth}
                navigationType={navigationType}
                tabInfo={tabInfo}
                history={history}
                style={navigationSpecificTabsContent.style}
                applicationContext={applicationContext}
                onAction={onAction}
                {...restProps}
              />
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </PropertyControlledComponent>
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT_DATA });
  }, [onAction]);

  if (loading) return <Loader />;

  return (
    <Page className="full-height full-width overflow-scroll">
      <Page.Body className="full-height full-width">{renderBody()}</Page.Body>
    </Page>
  );
};

ApplicationPreviewPage.propTypes = {
  loading: PropTypes.bool,
  isNavigationRequired: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  contentWidth: PropTypes.number.isRequired,
  selectedTabId: PropTypes.string,
  navigationType: PropTypes.string,
  landingPageData: PropTypes.object,
  history: PropTypes.object,
  applicationContext: PropTypes.object,
  allTabs: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ApplicationPreviewPage.defaultProps = {
  loading: true,
  isNavigationRequired: false,
  navigationType: EMPTY_STRING,
  selectedTabId: EMPTY_STRING,
  landingPageData: EMPTY_OBJECT,
  history: EMPTY_OBJECT,
  applicationContext: EMPTY_OBJECT,
  allTabs: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ApplicationPreviewPage);
