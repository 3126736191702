import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './fieldValuePair.module.scss';

const FieldValuePair = ({ label, value }) => (
  <div className={cx(styles.fieldContainer)}>
    <div className={styles.label}>{label} : </div>
    <div className={styles.value}>{value}</div>
  </div>
);

FieldValuePair.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

FieldValuePair.defaultProps = {};

export default React.memo(FieldValuePair);
