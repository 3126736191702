import _property from 'lodash/property';

const id = _property('id');
const name = _property('name');
const entityName = _property('entityName');
const createdTime = _property('createdTime');
const modifiedTime = _property('modifiedTime');
const createdBy = _property('createdBy');
const lastModifiedBy = _property('lastModifiedBy');
const recordTypeId = _property('recordTypeId');
const entity = _property('entity');
const ownerId = _property('ownerId');

const ENTITY_RECORD_READER = {
  id,
  name,
  entityName,
  createdTime,
  createdBy,
  modifiedTime,
  lastModifiedBy,
  recordTypeId,
  entity,
  ownerId,
};

export default ENTITY_RECORD_READER;
