import { defaultMemoize } from 'reselect';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _head from 'lodash/head';
import _compact from 'lodash/compact';
import _castArray from 'lodash/castArray';
import _filter from 'lodash/filter';
import _size from 'lodash/size';

import { EMPTY_ARRAY, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';

import { getUuid } from '../../../../../utils';

import { FIELD_IDS } from '../components/addOverrideFieldModal/constants/addOverrideFieldModal.constants';
import { DESCRIPTION_MAX_LENGTH, FIELD_ATTRIBUTES } from '../constants/recordTypeOverride.constants';

const getAvailableFieldDefinitions = defaultMemoize((entity, recordTypeDetails, selectedFields, derivationFields) => {
  const applicableFields = _get(recordTypeDetails, 'availableFields');
  let availableFields = _map(applicableFields, (field) => ({
    ..._find(_get(entity, 'fieldDefinitions', EMPTY_ARRAY), (item) => _get(item, 'name') === field),
  }));
  availableFields = _filter(availableFields, (item) => !_includes(derivationFields, _get(item, 'name')));
  availableFields = _map(availableFields, (item) => ({
    ...item,
    label: _get(item, 'displayName'),
    value: _get(item, 'name'),
    isDisabled: _includes(selectedFields, _get(item, 'name')),
  }));
  return availableFields;
});

const getDisplayName = (entity, fieldName) => {
  const fieldDef = _find(_get(entity, 'fieldDefinitions', EMPTY_ARRAY), (field) => _get(field, 'name') === fieldName);
  return _get(fieldDef, 'displayName', '');
};

const getPayload = (overrideFieldModalData, recordTypeDetails) => {
  const payload = {
    name: `name_${getUuid()}`,
    recordTypeInfos: [
      {
        recordTypeName: _get(recordTypeDetails, 'name'),
      },
    ],
    fieldOverrides: _map(overrideFieldModalData, (item) => {
      const fieldData = { ..._pick(item, [FIELD_IDS.FIELD_NAME, FIELD_IDS.DISPLAY_NAME]) };
      const fieldOptions = _get(item, FIELD_IDS.OPTIONS);
      if (!_isEmpty(fieldOptions)) {
        const options = _map(fieldOptions, (option) => ({
          displayName: _get(option, 'label'),
          value: _get(option, 'value'),
        }));
        fieldData.optionConfig = { options };
      }
      fieldData[FIELD_IDS.FIELD_NAME] = _head(fieldData[FIELD_IDS.FIELD_NAME]);
      return fieldData;
    }),
  };
  return payload;
};

const getValuesFromPayload = (recordTypeOverride, entity) => {
  const overrideFieldModalData = _map(_get(recordTypeOverride, 'fieldOverrides'), (fieldOverride) => {
    const attribute = [..._compact(_map(FIELD_ATTRIBUTES, (item) => (!_isEmpty(_get(fieldOverride, item)) ? item : '')))];
    const options = _map(_get(fieldOverride, 'optionConfig.options', []), (item) => ({
      label: _get(item, 'displayName'),
      value: _get(item, 'value'),
    }));
    if (!_isEmpty(options)) {
      attribute.push(FIELD_IDS.OPTIONS);
    }
    return {
      [FIELD_IDS.FIELD_NAME]: _castArray(_get(fieldOverride, FIELD_IDS.FIELD_NAME)),
      attribute,
      options,
      [FIELD_IDS.DISPLAY_NAME]: _get(fieldOverride, FIELD_IDS.DISPLAY_NAME),
    };
  });
  const overrideFieldTableData = _map(overrideFieldModalData, (item) => ({
    name: getDisplayName(entity, _head(_get(item, FIELD_IDS.FIELD_NAME))),
    id: _head(_get(item, FIELD_IDS.FIELD_NAME, EMPTY_STRING)),
  }));
  const selectedFields = _map(overrideFieldTableData, 'id');

  return {
    overrideFieldModalData,
    overrideFieldTableData,
    selectedFields,
  };
};

const getDescription = (description = EMPTY_STRING) => {
  if (_isEmpty(description)) {
    return NO_DATA;
  }
  const size = _size(description) < DESCRIPTION_MAX_LENGTH ? _size(description) : DESCRIPTION_MAX_LENGTH;
  const trimSymbol = _size(description) < DESCRIPTION_MAX_LENGTH ? '' : '...';
  return `${description.slice(0, size)} ${trimSymbol}`;
};

export { getAvailableFieldDefinitions, getDisplayName, getPayload, getValuesFromPayload, getDescription };
