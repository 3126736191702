import EventEmitter from 'eventemitter3';

const shortcutsInfoDialogEvent = new EventEmitter();

const showShortcutsInfoDialog = (editor) => {
  shortcutsInfoDialogEvent.emit(`SHOW_SHORTCUTS_INFO_DIALOG_${editor.id}`);
};

const hideShortcutsInfoDialog = (editor) => {
  shortcutsInfoDialogEvent.emit(`HIDE_SHORTCUTS_INFO_DIALOG_${editor.id}`);
};

export { showShortcutsInfoDialog, hideShortcutsInfoDialog, shortcutsInfoDialogEvent };
