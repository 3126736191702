import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

// Components
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';

// Helpers
import { getOperatorOptions } from './operatorField.helpers';

// Constants
import { CURRENT_ENTITY_NAMESPACE } from '../../constants/conditionBuilder.general';

const OperatorField = ({
  id,
  required,
  isDisabled,
  isVariablePrefixNeeded,
  isCustomOperatorOptions,
  enableRecordChangeComparatorOperators,
  label,
  variableNameForPrefixNotNeeded,
  fieldClassName,
  error,
  value,
  mapOfVariableToEntityName,
  conditionBuilderFieldDefinitionObject,
  values,
  customOperatorOptions,
  onAction,
}) => {
  const options = useMemo(
    () =>
      getOperatorOptions({
        values,
        mapOfVariableToEntityName,
        conditionBuilderFieldDefinitionObject,
        isVariablePrefixNeeded,
        variableNameForPrefixNotNeeded,
        isCustomOperatorOptions,
        customOperatorOptions,
        enableRecordChangeComparatorOperators,
      }),
    [
      values,
      mapOfVariableToEntityName,
      conditionBuilderFieldDefinitionObject,
      isVariablePrefixNeeded,
      variableNameForPrefixNotNeeded,
      isCustomOperatorOptions,
      customOperatorOptions,
      enableRecordChangeComparatorOperators,
    ],
  );

  return (
    <Select
      id={id}
      required={required}
      disabled={isDisabled}
      label={label}
      fieldClassName={fieldClassName}
      value={value}
      error={error}
      options={options}
      onAction={onAction}
    />
  );
};

OperatorField.propTypes = {
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isVariablePrefixNeeded: PropTypes.bool,
  isCustomOperatorOptions: PropTypes.bool,
  enableRecordChangeComparatorOperators: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  fieldClassName: PropTypes.string,
  variableNameForPrefixNotNeeded: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  values: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  customOperatorOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

OperatorField.defaultProps = {
  required: false,
  isDisabled: false,
  isVariablePrefixNeeded: true,
  isCustomOperatorOptions: false,
  enableRecordChangeComparatorOperators: false,
  label: '',
  fieldClassName: '',
  value: '',
  error: '',
  variableNameForPrefixNotNeeded: CURRENT_ENTITY_NAMESPACE,
  values: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  customOperatorOptions: EMPTY_ARRAY,
};

export default OperatorField;
