import React, { useCallback, useMemo } from 'react';

// Lodash
import _map from 'lodash/map';

// Components
import { Menu } from 'antd';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import DropDown, { DROPDOWN_TRIGGER, DROPDOWN_PLACEMENT } from '@tekion/tekion-components/molecules/DropDown';

// Constants
import COLORS from '@tekion/tekion-styles-next/scss/variables.scss';

// Styles
import styles from '../fontSizeInput.module.scss';

const renderMenuItem = (size) => <Menu.Item key={size}>{size}</Menu.Item>;

const useDropdown = ({ onSelect, fontSizeOptions }) => {
  const onMenuItemClick = useCallback((menuItemData) => onSelect(menuItemData?.key), [onSelect]);

  const renderMenu = useMemo(
    () => <Menu onClick={onMenuItemClick}>{_map(fontSizeOptions, renderMenuItem)}</Menu>,
    [fontSizeOptions, onMenuItemClick],
  );

  const dropDownContent = useMemo(
    () => (
      <FontIcon className={`cursor-pointer ${styles.icon}`} color={COLORS.gunMetal} size={SIZES.MD_S}>
        icon-chevron-down
      </FontIcon>
    ),
    [],
  );

  const dropDown = useMemo(
    () => (
      <DropDown
        trigger={[DROPDOWN_TRIGGER.CLICK]}
        overlay={renderMenu}
        placement={[DROPDOWN_PLACEMENT.BOTTOM_RIGHT]}
        overlayClassName={styles.options}
      >
        {dropDownContent}
      </DropDown>
    ),
    [renderMenu, dropDownContent],
  );

  return dropDown;
};

export default useDropdown;
