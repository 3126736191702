import _isEmpty from 'lodash/isEmpty';

import SORT_ORDER_KEY from '@tekion/tekion-base/constants/sortOrder';

import { SORT_BE_KEY } from './searchRequest.constants';

class SearchRequest {
  constructor() {
    this.rows = null;
    this.filters = [];
    this.nextPageToken = null;
    this.sortList = [];
    this.searchText = null;
    this.searchField = null;
  }

  setRows(rows) {
    this.rows = rows;
    return this;
  }

  setFilters(filters) {
    this.filters = filters;
    return this;
  }

  setNextPageToken(nextPageToken) {
    this.nextPageToken = nextPageToken;
    return this;
  }

  setSortList(sortList) {
    this.sortList = sortList;
    return this;
  }

  addSortOption({ field, value = SORT_ORDER_KEY.ASC }) {
    // field is required
    if (_isEmpty(field)) {
      return this;
    }

    this.sortList.push({ [SORT_BE_KEY.FIELD]: field, [SORT_BE_KEY.ORDER]: value });
    return this;
  }

  setSearchText(searchText) {
    this.searchText = searchText;
    return this;
  }

  setSearchField(searchField) {
    this.searchField = searchField;
    return this;
  }
}

export default SearchRequest;
