import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _castArray from 'lodash/castArray';
import _keys from 'lodash/keys';
import _size from 'lodash/size';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

// Components
import { Collapse, Drawer } from 'antd';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import Loader from '@tekion/tekion-components/molecules/loader';
import Menu from '@tekion/tekion-components/molecules/Menu';

// Helpers
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import { getItemsByTaskCategory, getStylePropertiesFromUiMetadata } from './helpers/rightDrawer.helpers';

// Styles
import styles from './rightDrawer.module.scss';

const RightDrawer = ({ visible, header, drawerItemsData, onItemClick, onClose }) => {
  const { isError, isLoading, items } = drawerItemsData;

  const handleItemClick = useCallback(({ key }) => onItemClick(key), [onItemClick]);

  const itemsByTaskCategory = useMemo(() => {
    if (header === 'Choose a step') {
      return getItemsByTaskCategory(items);
    }
    return items;
  }, [items, header]);

  const [activeKeys, setActiveKeys] = useState(_keys(itemsByTaskCategory));
  const onPanelChange = useCallback((key) => setActiveKeys(_castArray(key)), []);
  return (
    !isError && (
      <Drawer
        onClose={onClose}
        title={__(header)}
        placement="right"
        visible={visible}
        width={408}
        key="rightDrawer"
        bodyStyle={{ padding: 0 }}
        headerStyle={{
          backgroundColor: '#EDEEF0',
          color: '#161616',
          fontSize: '2.1rem',
          fontWeight: 600,
          letterSpacing: '0.03rem',
          lineHeight: '2.5rem',
        }}
      >
        <PropertyControlledComponent controllerProperty={!isLoading} fallback={<Loader id="DRAWER_LOADER" />}>
          {header !== 'Choose a step' ? (
            <Menu onClick={handleItemClick}>
              {_map(items, ({ id, displayName, uiMetadata }) => {
                const { icon, color, backgroundColor } = getStylePropertiesFromUiMetadata(uiMetadata);
                return (
                  <Menu.Item key={id} className={styles.menuItem}>
                    <FontIcon className={styles.iconContainer} style={{ color, backgroundColor }}>
                      {icon}
                    </FontIcon>
                    {displayName}
                  </Menu.Item>
                );
              })}
            </Menu>
          ) : (
            <Collapse activeKey={activeKeys} onChange={onPanelChange} ghost className={styles.collapseStyle}>
              {_map(itemsByTaskCategory, (steps, itemsCategory) => (
                <Collapse.Panel
                  header={
                    <Heading size={3} className={styles.listGroupHeader}>
                      {itemsCategory}
                    </Heading>
                  }
                  key={itemsCategory}
                >
                  <PropertyControlledComponent controllerProperty={_size(steps)}>
                    <Menu onClick={handleItemClick}>
                      {_map(steps, ({ id, displayName, uiMetadata }) => {
                        const { icon, color, backgroundColor } = getStylePropertiesFromUiMetadata(uiMetadata);
                        return (
                          <Menu.Item key={id} className={styles.menuItem}>
                            <FontIcon className={styles.iconContainer} style={{ color, backgroundColor }}>
                              {icon}
                            </FontIcon>
                            {displayName}
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  </PropertyControlledComponent>
                </Collapse.Panel>
              ))}
            </Collapse>
          )}
        </PropertyControlledComponent>
      </Drawer>
    )
  );
};

RightDrawer.propTypes = {
  visible: PropTypes.bool,
  header: PropTypes.string,
  drawerItemsData: PropTypes.objectOf({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    items: PropTypes.array,
  }),
  onItemClick: PropTypes.func,
  onClose: PropTypes.func,
};

RightDrawer.defaultProps = {
  visible: false,
  header: '',
  drawerItemsData: {
    isLoading: false,
    isError: false,
    items: EMPTY_ARRAY,
  },
  onItemClick: _noop,
  onClose: _noop,
};

export default RightDrawer;
