import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _size from 'lodash/size';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Button from '@tekion/tekion-components/atoms/Button';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FilterRow from './FilterRow';

import { ACTION_TYPES } from '../preAppliedFiltersConfigurator.constants';

import styles from '../preAppliedFiltersConfigurator.module.scss';

const FilterList = ({ filterableFieldTypeOptions, fieldsConfig, preAppliedFilters, errors, onAction }) => {
  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { id: fieldId, value: selectValue, index } = payload;
          onAction({
            type,
            payload: {
              id: fieldId,
              value: selectValue,
              index,
            },
          });
          break;
        }
        case ACTION_TYPES.DELETE_ROW: {
          onAction({
            type: ACTION_TYPES.DELETE_ROW,
            payload: {
              ...payload,
            },
          });
          break;
        }
        default:
          onAction(action);
      }
    },
    [onAction],
  );

  const onAddClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ADD_ROW,
    });
  }, [onAction]);

  return (
    <div className={styles.filterContainer}>
      {_map(preAppliedFilters, (filterRow, index) => (
        <FilterRow
          key={index}
          index={index}
          value={filterRow}
          fieldsConfig={fieldsConfig}
          error={errors[index]}
          filterableFieldTypeOptions={filterableFieldTypeOptions}
          onAction={handleAction}
        />
      ))}
      <Button view={Button.VIEW.TERTIARY} onClick={onAddClick} disabled={_size(preAppliedFilters) === _size(filterableFieldTypeOptions)}>
        {__('+Add Filter')}
      </Button>
    </div>
  );
};

FilterList.propTypes = {
  fieldsConfig: PropTypes.object,
  filterableFieldTypeOptions: PropTypes.array,
  preAppliedFilters: PropTypes.array,
  errors: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

FilterList.defaultProps = {
  fieldsConfig: EMPTY_OBJECT,
  filterableFieldTypeOptions: EMPTY_ARRAY,
  errors: EMPTY_ARRAY,
  preAppliedFilters: EMPTY_ARRAY,
};

export default FilterList;
