import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import Modal from '@tekion/tekion-components/molecules/Modal';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';

import ShareAndManageRecord from './organisms/shareAndManageRecord';
import useShareAndManageRecordModal from './hooks/useShareAndManageRecordModal';

import { MODAL_MODE_DATA_BY_ID } from './constants/shareAndManageRecordModal.constants';

const ShareAndManageRecordModal = ({
  isModalVisible,
  isDataLoading,
  isDataSubmitting,
  shareAndManageRecordUsersData,
  onModalSubmit,
  shareAndManageRecordModalMetaData,
  onShareRecordModalClose,
  deleteSharedRecordForUser,
}) => {
  const {
    mode,
    entityDef,
    entityRecord,
    wwdData,
    shareAndManageRecordUsersState,
    handleUpdateShareAndManageRecordUserDetails,
    handleRemoveShareAndManageRecordUserDetail,
    handleAddShareAndManageRecordUserDetail,
  } = useShareAndManageRecordModal({ isModalVisible, shareAndManageRecordModalMetaData, shareAndManageRecordUsersData, deleteSharedRecordForUser });

  const handleSubmit = useCallback(() => {
    onModalSubmit(shareAndManageRecordUsersState);
  }, [shareAndManageRecordUsersState, onModalSubmit]);

  return (
    <Modal
      destroyOnClose
      visible={isModalVisible}
      loading={isDataSubmitting}
      title={MODAL_MODE_DATA_BY_ID[mode].modalTitle}
      width={SIZES.XL}
      submitBtnText={MODAL_MODE_DATA_BY_ID[mode].submitButtonLabel}
      onCancel={onShareRecordModalClose}
      onSubmit={handleSubmit}
    >
      <ShareAndManageRecord
        isDataLoading={isDataLoading}
        mode={mode}
        entityDef={entityDef}
        entityRecord={entityRecord}
        wwdData={wwdData}
        shareAndManageRecordUserDetails={shareAndManageRecordUsersState}
        onUpdateShareAndManageRecordUserDetails={handleUpdateShareAndManageRecordUserDetails}
        onRemoveShareAndManageRecordUserDetail={handleRemoveShareAndManageRecordUserDetail}
        onAddShareAndManageRecordUserDetail={handleAddShareAndManageRecordUserDetail}
      />
    </Modal>
  );
};

ShareAndManageRecordModal.propTypes = {
  isModalVisible: PropTypes.bool,
  isDataSubmitting: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  shareAndManageRecordModalMetaData: PropTypes.object,
  shareAndManageRecordUsersData: PropTypes.array,
  onShareRecordModalClose: PropTypes.func.isRequired,
  onModalSubmit: PropTypes.func,
  deleteSharedRecordForUser: PropTypes.func,
};

ShareAndManageRecordModal.defaultProps = {
  isModalVisible: false,
  isDataSubmitting: false,
  isDataLoading: false,
  shareAndManageRecordModalMetaData: EMPTY_OBJECT,
  shareAndManageRecordUsersData: EMPTY_ARRAY,
  onModalSubmit: _noop,
  deleteSharedRecordForUser: _noop,
};

export default ShareAndManageRecordModal;
