import _constant from 'lodash/constant';

import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer';

import { tget } from '@tekion/tekion-base/utils/general';
import { COLOR_MAP } from '@tekion/tekion-base/constants/statusColors';
import { STATUS } from '@tekion/tekion-base/constants/status.constants';

import { pascalCase } from '../../../../../../helpers/general.helpers';

import { COLUMN_IDS } from '../constants/dataImportList.constants';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const STATUS_COLOR_MAP = {
  [STATUS.COMPLETED]: COLOR_MAP.GREEN,
  [STATUS.FAILED]: COLOR_MAP.RED,
  [STATUS.IN_PROGRESS]: COLOR_MAP.ORANGE,
  [STATUS.PENDING]: COLOR_MAP.YELLOW,
};

const STATUS_LABEL_MAP = {
  [STATUS.COMPLETED]: __('Completed'),
  [STATUS.FAILED]: __('Failed'),
  [STATUS.IN_PROGRESS]: __('In Progress'),
  [STATUS.PENDING]: __('Pending'),
};

const getStatusProps = _constant({
  colorMap: STATUS_COLOR_MAP,
  labelMap: STATUS_LABEL_MAP,
});

const COLUMN_CONFIG = [
  {
    Header: __('Entity'),
    id: COLUMN_IDS.ENTITY_DISPLAY_NAME,
    accessor: COLUMN_IDS.ENTITY_DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Action Type'),
    id: COLUMN_IDS.OPERATION_TYPE,
    accessor: (rowData) => pascalCase(tget(rowData, COLUMN_IDS.OPERATION_TYPE)),
    Cell: TextRenderer,
  },
  {
    Header: __('File Name'),
    id: COLUMN_IDS.FILE_NAME,
    accessor: COLUMN_IDS.FILE_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('File Id'),
    id: COLUMN_IDS.MEDIA_ID,
    accessor: COLUMN_IDS.MEDIA_ID,
    Cell: TextRenderer,
  },
  {
    Header: __('Status'),
    id: COLUMN_IDS.TASK_STATUS,
    accessor: COLUMN_IDS.TASK_STATUS,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
];

export default COLUMN_CONFIG;
