import _map from 'lodash/map';
import _castArray from 'lodash/castArray';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import FilterBuilder from '../../../builders/filterBuilder';
import workspaceUserReader from '../../../readers/workSpaceUser.reader';

const ROW_COUNT = 100;

const getPayload =
  (_filters) =>
  ({ nextPageToken, searchText }) => {
    const filters = new FilterBuilder()
      .setFilters(_filters)
      .addNameFilter({ values: _castArray(searchText), operator: OPERATORS.TEXT_STARTS_WITH })
      .getFilterPayload();
    return {
      filters,
      rows: ROW_COUNT,
      nextPageToken,
    };
  };

const getPayloadForInitialFetch = (_filters) => (selectedValue) => {
  const filters = new FilterBuilder().setFilters(_filters).addIdFilter({ values: selectedValue }).getFilterPayload();
  return { filters };
};

const getOptionForWorkspaceUser = (user) => ({
  label: workspaceUserReader.NAME(user),
  value: workspaceUserReader.id(user),
});

const getWorkspaceUserOptions = (data) => _map(data, getOptionForWorkspaceUser);

export { getPayload, getWorkspaceUserOptions, getPayloadForInitialFetch };
