exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".tagsRenderer_flexRow__qm1LGztF2b{display:flex;flex-flow:row}.tagsRenderer_popover__oTyxbQSZJ4{display:grid;grid-template-columns:auto auto auto;grid-template-rows:auto;grid-row-gap:.8rem;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;max-height:20rem;overflow-y:scroll}[dir] .tagsRenderer_popover__oTyxbQSZJ4{padding:1.2rem 2rem}.tagsRenderer_tag__opSk6HTagz{max-width:7rem;text-overflow:ellipsis;display:flex;align-items:center;justify-content:center}[dir] .tagsRenderer_tag__opSk6HTagz{text-align:center}.tagsRenderer_tagPopover__akTdmznWpS{text-overflow:ellipsis;display:flex;align-items:center;justify-content:center}[dir] .tagsRenderer_tagPopover__akTdmznWpS{text-align:center}[dir] .tagsRenderer_tagContent__xdvJPFF74e{padding:.2rem 1rem}.tagsRenderer_tagFullName__guKvvzzejV{max-height:20rem;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}[dir] .tagsRenderer_tagFullName__guKvvzzejV{padding:.4rem 1rem}", ""]);

// Exports
exports.locals = {
	"flexRow": "tagsRenderer_flexRow__qm1LGztF2b",
	"popover": "tagsRenderer_popover__oTyxbQSZJ4",
	"tag": "tagsRenderer_tag__opSk6HTagz",
	"tagPopover": "tagsRenderer_tagPopover__akTdmznWpS",
	"tagContent": "tagsRenderer_tagContent__xdvJPFF74e",
	"tagFullName": "tagsRenderer_tagFullName__guKvvzzejV"
};