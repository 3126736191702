import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';

import { OPTION_KEYS } from './menuOptionBuilder.constants';

class MenuOptionBuilder {
  constructor() {
    this.options = [];
  }

  addOption({ label = '', id, disabled = false, disableMessage = '', additional, onClick = _noop }) {
    // id is required non-empty string parameter for generating options
    if (_isEmpty(id)) {
      return this;
    }

    this.options.push({
      [OPTION_KEYS.LABEL]: label,
      [OPTION_KEYS.ID]: id,
      [OPTION_KEYS.DISABLED]: disabled,
      [OPTION_KEYS.DISABLE_MESSAGE]: disableMessage,
      [OPTION_KEYS.ON_CLICK]: onClick,
      [OPTION_KEYS.ADDITIONAL]: additional,
    });

    return this;
  }

  getOptions() {
    return this.options;
  }

  setOptions(newOptionsToSet) {
    this.options = newOptionsToSet;
    return this;
  }

  addOptions(newOptions) {
    this.options = [...this.options, ...newOptions];
    return this;
  }
}

export default MenuOptionBuilder;
