import _property from 'lodash/property';

const recordTypeReader = {
  name: _property('name'),
  displayName: _property('displayName'),
  description: _property('description'),
  status: _property('active'),
  availableFields: _property('availableFields'),
  derivationCondition: _property('derivationCondition'),
};

export default recordTypeReader;
