import React, { useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';

// Tekion-components
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';

// Tekion-connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Connectors
import withSize from '../../../../../../connectors/withSize';

// Helpers
import { createTableProps } from './helpers/serverCredentialList.helpers';
import COLUMN_CONFIG from './helpers/serverCredentialList.columns';
import ACTION_HANDLERS from './helpers/serverCredentialList.actionHandlers';

// Constants
import {
  HEADER_PROPS,
  INITIAL_STATE_SERVER_CREDENTIAL_LIST,
  SUB_HEADER_PROPS,
  TABLE_MANAGER_PROPS,
} from './constants/serverCredentialList.constants';

const ServerCredentialList = ({ isLoading, contentHeight, currentPage, pageSize, nextPageToken, tableData, onAction }) => {
  const tableProps = useMemo(() => createTableProps(isLoading, currentPage, pageSize, onAction), [isLoading, currentPage, pageSize, onAction]);

  return (
    <Page style={{ height: contentHeight }}>
      <TableManager
        tokenPagination
        nextPageToken={nextPageToken}
        headerProps={HEADER_PROPS}
        subHeaderProps={SUB_HEADER_PROPS}
        tableManagerProps={TABLE_MANAGER_PROPS}
        tableProps={tableProps}
        columns={COLUMN_CONFIG}
        data={tableData}
        onAction={onAction}
      />
    </Page>
  );
};

ServerCredentialList.propTypes = {
  isLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  nextPageToken: PropTypes.string,
  tableData: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ServerCredentialList.defaultProps = {
  isLoading: false,
  currentPage: DEFAULT_PAGE_INFO.start,
  pageSize: DEFAULT_PAGE_SIZE,
  nextPageToken: EMPTY_STRING,
  tableData: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: false }), withActions(INITIAL_STATE_SERVER_CREDENTIAL_LIST, ACTION_HANDLERS))(ServerCredentialList);
