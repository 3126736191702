const TEMPLATE_RENDERER_FIELD_IDS = {
  SELECTED_TEMPLATE: 'templateName',
  MAP_OF_VARIABLES: 'fieldValueMap',
  TEMPLATE_TYPE: 'templateType',
};

const DEFAULT_TEMPLATE_SELECT_DROPDOWN_ROWS = 20;

const TEMPLATE_OPERATOR_TYPES = {
  IS: 'is',
};

const TEMPLATE_OPERATOR_OPTIONS = [{ label: 'Is', value: TEMPLATE_OPERATOR_TYPES.IS }];

const TEMPLATE_OPERATOR_VALUE = [TEMPLATE_OPERATOR_TYPES.IS];

const ACTION_TYPES = {
  ON_ASYNC_LOADED_OPTIONS: 'ON_ASYNC_LOADED_OPTIONS',
};

export { TEMPLATE_RENDERER_FIELD_IDS, DEFAULT_TEMPLATE_SELECT_DROPDOWN_ROWS, TEMPLATE_OPERATOR_OPTIONS, TEMPLATE_OPERATOR_VALUE, ACTION_TYPES };
