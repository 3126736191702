import _isNil from 'lodash/isNil';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import TASK_TYPES from '@tekion/tekion-rule-engine/src/constants/ruleEngineWorkflow.taskTypes';
import { getNewNode, getNodeConfig } from '@tekion/tekion-rule-engine/src/utils/core/index';

// Constants
import WORKFLOW_FIELD_IDS from '../constants/workflow.fieldIds';

// Utils
import assignTrigger from './assignTrigger';

const getInitialWorkflowData = (initialWorkflowData, triggerList) => {
  if (_isNil(initialWorkflowData)) return {};

  const blankTriggerNode = getNewNode({
    taskType: TASK_TYPES.BLANK_EVENT,
    label: '+ Add Trigger',
    uiMetadata: {
      taskType: TASK_TYPES.BLANK_EVENT,
      allowTasksBelow: true,
    },
  });

  const nodeList = {
    [blankTriggerNode.nodeId]: blankTriggerNode,
  };

  getNodeConfig(nodeList, blankTriggerNode.nodeId, null);

  const entityName = getArraySafeValue(tget(initialWorkflowData, WORKFLOW_FIELD_IDS.ENTITY, EMPTY_ARRAY));

  const workflow = {
    ...initialWorkflowData,
    createdTime: Date.now(),
    module: 'DEV_PLATFORM',
    name: initialWorkflowData[WORKFLOW_FIELD_IDS.DISPLAY_NAME].replace(/\s/g, ''),
    displayName: initialWorkflowData[WORKFLOW_FIELD_IDS.DISPLAY_NAME],
    description: initialWorkflowData[WORKFLOW_FIELD_IDS.DESCRIPTION],
    entityName,
    eventType: undefined, // If an event type has been chosen it'll be set in assignTrigger func.
    nodeList,
    rootNodeId: blankTriggerNode.nodeId,
    uiMetadata: {
      entityDisplayName: entityName,
    },
  };

  const eventType = getArraySafeValue(initialWorkflowData[WORKFLOW_FIELD_IDS.EVENT_TYPE]);

  if (!_isNil(eventType)) {
    const updatedWorkflow = assignTrigger(workflow, eventType, triggerList);

    if (!_isNil(updatedWorkflow)) {
      return updatedWorkflow;
    }
  }

  return workflow;
};

export default getInitialWorkflowData;
