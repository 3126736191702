exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".entityRecordDetailPage_header__nZJPDsomDi{width:100%;display:flex;flex-direction:row;justify-content:space-between}.entityRecordDetailPage_body__bACxNCjNWQ{display:flex;overflow-y:scroll;width:100%}.entityRecordDetailPage_viewViewer__c77H2DbysQ{height:100%;width:100%}[dir] .entityRecordDetailPage_viewViewer__c77H2DbysQ{margin:1rem}.entityRecordDetailPage_actionMenuItem__6RrMz5hN17{min-width:160px;height:100%;display:flex;align-items:center}[dir] .entityRecordDetailPage_actionMenuItem__6RrMz5hN17{padding:1rem 1.5rem}[dir=ltr] .entityRecordDetailPage_actionMenuTooltip__9KogGxoMDi{margin-left:1rem}[dir=rtl] .entityRecordDetailPage_actionMenuTooltip__9KogGxoMDi{margin-right:1rem}.entityRecordDetailPage_noDataContainer__jdaqcGa27n{font-weight:bold}[dir] .entityRecordDetailPage_noDataContainer__jdaqcGa27n{padding:1rem 2rem}", ""]);

// Exports
exports.locals = {
	"header": "entityRecordDetailPage_header__nZJPDsomDi",
	"body": "entityRecordDetailPage_body__bACxNCjNWQ",
	"viewViewer": "entityRecordDetailPage_viewViewer__c77H2DbysQ",
	"actionMenuItem": "entityRecordDetailPage_actionMenuItem__6RrMz5hN17",
	"actionMenuTooltip": "entityRecordDetailPage_actionMenuTooltip__9KogGxoMDi",
	"noDataContainer": "entityRecordDetailPage_noDataContainer__jdaqcGa27n"
};