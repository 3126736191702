import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import { activeSubscriptionConfig, fetchSubscriptionConfigs, inactiveSubscriptionConfig } from '../../../../../../actions/subscriptionConfig.actions';

import { getPayload } from './subscriptionConfigList.helpers';

import ACTION_TYPES from '../constants/subscriptionConfigList.actionTypes';
import { DEFAULT_PAGE_SIZE } from '../constants/subscriptionConfigList.general';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import ROUTES from '../../../../constants/routes';

const handleInit = async ({ getState, setState }) => {
  setState({ isLoading: true });
  const {
    selectedFilters = EMPTY_ARRAY,
    pageSize = DEFAULT_PAGE_SIZE,
    currentPage = 0,
    currentPageToken = EMPTY_STRING,
    searchText = EMPTY_STRING,
  } = getState();
  const payload = getPayload({ currentPage, currentPageToken, pageSize, searchText, selectedFilters });
  const response = await fetchSubscriptionConfigs(payload);
  const data = tget(response, 'hits', EMPTY_ARRAY);
  const nextPageToken = _get(response, 'nextPageToken', EMPTY_STRING);
  setState({ data, isLoading: false, nextPageToken });
};

const handleSearch = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ searchText: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => handleInit({ getState, setState }));
};

const handlePageUpdate = ({ params, getState, setState }) => {
  const { page, resultsPerPage } = _get(params, 'value', EMPTY_OBJECT);
  const { currentPage, pageSize, nextPageToken, previousPageTokens = [], currentPageToken } = getState();
  let newPageNumber = page;
  let newTokenNumber = null;
  let newPrevPageTokens = [...previousPageTokens];

  if (resultsPerPage !== pageSize) {
    newPageNumber = 1;
    newPrevPageTokens = [];
  } else if (page > currentPage + 1) {
    newTokenNumber = nextPageToken;
    newPrevPageTokens.push(currentPageToken);
  } else if (page !== 1) {
    newTokenNumber = newPrevPageTokens.pop();
  } else {
    newPrevPageTokens = [];
  }

  setState(
    { currentPage: newPageNumber - 1, pageSize: resultsPerPage, currentPageToken: newTokenNumber, previousPageTokens: newPrevPageTokens },
    () => handleInit({ getState, setState }),
  );
};

const handleFilterChange = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ selectedFilters: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => handleInit({ getState, setState }));
};

const handleCreateSubscriptionConfig = ({ getState }) => {
  const { history } = getState();
  history.push(ROUTES.SUBSCRIPTION_CONFIG_CREATE_ROUTE);
};

const handleEditSubscriptionConfig = ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const subscriptionConfigName = tget(params, 'name', {});

  if (!_isEmpty(subscriptionConfigName)) {
    history.push({
      pathname: `${STUDIO_ROUTE}/${PAGE_IDS.SUBSCRIPTION_CONFIG_EDIT}/${subscriptionConfigName}`,
      state: { subscriptionConfig: params },
    });
  }
};

const handleSubscriptionConfigClick = ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const subscriptionConfig = tget(params, 'value.original', {});
  const name = tget(subscriptionConfig, 'name');
  if (!_isEmpty(name)) {
    history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_IDS.SUBSCRIPTION_CONFIG_EDIT}/${name}`, state: { subscriptionConfig } });
  }
};

const handleRedirectToLogs = ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const name = tget(params, 'name');
  if (!_isEmpty(name)) {
    history.push({ pathname: `${STUDIO_ROUTE}/${PAGE_IDS.SUBSCRIPTION_CONFIG_LOGS}/${name}` });
  }
};

const handleActiveSubscriptionConfig = async ({ params, getState, setState }) => {
  const name = tget(params, 'name');
  setState({ isLoading: true });
  const response = await activeSubscriptionConfig(name, { ...params, inactive: false });

  setState({ isLoading: false });
  if (!_isEmpty(response)) {
    handleInit({ getState, setState });
  }
};

const handleInactiveSubscriptionConfig = async ({ params, getState, setState }) => {
  const name = tget(params, 'name');
  setState({ isLoading: true });
  const response = await inactiveSubscriptionConfig(name, { ...params, inactive: true });
  setState({ isLoading: false });
  if (!_isEmpty(response)) {
    handleInit({ getState, setState });
  }
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleFilterChange,
  [ACTION_TYPES.ON_CREATE_SUBSCRIPTION_CONFIG]: handleCreateSubscriptionConfig,
  [ACTION_TYPES.ON_EDIT_SUBSCRIPTION_CONFIG]: handleEditSubscriptionConfig,
  [ACTION_TYPES.ON_REDIRECT_TO_SUBSCRIPTION_LOGS]: handleRedirectToLogs,
  [ACTION_TYPES.ON_INACTIVE_SUBSCRIPTION_CONFIG]: handleInactiveSubscriptionConfig,
  [ACTION_TYPES.ON_ACTIVE_SUBSCRIPTION_CONFIG]: handleActiveSubscriptionConfig,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleSubscriptionConfigClick,
};

export default ACTION_HANDLERS;
