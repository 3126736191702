import _noop from 'lodash/noop';
import _difference from 'lodash/difference';
import _keysIn from 'lodash/keysIn';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _keyBy from 'lodash/keyBy';
import _has from 'lodash/has';
import _cloneDeep from 'lodash/cloneDeep';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { searchViewConfigurations } from './viewBuilderPage.actions';
import { getMasterEntityDef } from './entityManagement.actions';
import { fetchEntityViewConfigurationByName } from './entityViewDefinitions.actions';

import { getPayload } from '../helpers/applicationRenderer.helpers';

import { VIEW_CONFIGURATION_FIELD_IDS } from '../constants/viewBuilder.constants';

// The below action can used to fetch views corresponding to a specific entity only
const getRecordTypeViewDefFromCache = async ({
  cacheValue = {},
  setCacheHandler = _noop,
  viewNames = [],
  additionalPayload = {},
  entityName = '',
}) => {
  const cacheViewConfigsByName = tget(cacheValue, entityName, {});

  const viewNamesToFetch = _difference(viewNames, _keysIn(cacheViewConfigsByName));

  if (_isEmpty(viewNamesToFetch)) {
    return _cloneDeep(_pick(cacheViewConfigsByName, viewNames));
  }
  const payload = getPayload(viewNames, entityName, additionalPayload);
  const viewResponse = await searchViewConfigurations(payload);
  const viewConfigs = tget(viewResponse, 'hits', EMPTY_ARRAY);
  const newViewConfigsByName = _keyBy(viewConfigs, VIEW_CONFIGURATION_FIELD_IDS.NAME);

  setCacheHandler(_cloneDeep({ entityName, newViewConfigsByName }));
  return _pick({ ...cacheViewConfigsByName, ...newViewConfigsByName }, viewNames);
};

const getMasterEntityDefFromCache = async ({ cacheValue = {}, setCacheHandler = _noop, entityName }) => {
  if (_isEmpty(entityName)) return EMPTY_OBJECT;
  if (_has(cacheValue, entityName)) {
    return _cloneDeep(tget(cacheValue, entityName));
  }

  const masterEntityDef = await getMasterEntityDef(entityName);

  setCacheHandler(_cloneDeep({ [entityName]: masterEntityDef }));
  return masterEntityDef;
};

const getEntityViewDefFromCache = async ({ cacheValue = {}, setCacheHandler = _noop, entityViewName = '', entityName = '' }) => {
  const cacheEntityViewDefByName = tget(cacheValue, entityName, {});

  if (_has(cacheEntityViewDefByName, entityViewName)) {
    return _cloneDeep(tget(cacheEntityViewDefByName, entityViewName));
  }

  const entityViewConfiguration = await fetchEntityViewConfigurationByName(entityName, entityViewName);

  setCacheHandler(_cloneDeep({ entityName, newEntityViewConfigByName: { [entityViewName]: entityViewConfiguration } }));
  return entityViewConfiguration;
};

export { getRecordTypeViewDefFromCache, getMasterEntityDefFromCache, getEntityViewDefFromCache };
