import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import PermissionSetManagementServices from '../services/permissionSet.services';

const getPermissionByName = async (permissionName) => {
  try {
    const receivedData = await PermissionSetManagementServices.getPermissionByName(permissionName);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch Permission data, please try again later.')));
    return EMPTY_ARRAY;
  }
};
const getPermissionList = async (payload = EMPTY_OBJECT) => {
  try {
    const receivedData = await PermissionSetManagementServices.fetchPermissionList(payload);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch Permission data, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const editPermissionByName = async (permissionName, payload = EMPTY_OBJECT) => {
  try {
    const receivedData = await PermissionSetManagementServices.editPermissionByName(permissionName, payload);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to edit Permission data, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const deletePermissionByName = async (permissionName) => {
  try {
    const response = await PermissionSetManagementServices.deletePermissionByName(permissionName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to delete Permission set, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createPermissionSet = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await PermissionSetManagementServices.createPermissionSet(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create permission set')));
    return EMPTY_OBJECT;
  }
};

const createAssignment = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await PermissionSetManagementServices.createAssignment(payload);
    toaster(TOASTER_TYPE.SUCCESS, __('Assigned successfully!'));
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create assignment')));
    return EMPTY_OBJECT;
  }
};

const getAssignments = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await PermissionSetManagementServices.getAssignments(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch assignments')));
    return EMPTY_OBJECT;
  }
};

const removeAssignment = async (assignmentName) => {
  try {
    const response = await PermissionSetManagementServices.removeAssignment(assignmentName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to remove assignment')));
    return EMPTY_OBJECT;
  }
};

export {
  createPermissionSet,
  getPermissionByName,
  editPermissionByName,
  getPermissionList,
  createAssignment,
  getAssignments,
  removeAssignment,
  deletePermissionByName,
};
