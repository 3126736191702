exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".reportingWidgetRenderer_customFilterContainer__nHYZimnexq{display:flex;align-items:center}[dir] .reportingWidgetRenderer_customFilterContainer__nHYZimnexq{margin-top:1.8rem;margin-bottom:1rem}[dir=ltr] .reportingWidgetRenderer_customFilterContainer__nHYZimnexq{margin-left:2rem}[dir=rtl] .reportingWidgetRenderer_customFilterContainer__nHYZimnexq{margin-right:2rem}.reportingWidgetRenderer_reportingContainer__gyod6BzNJC{height:100%;width:100%;display:flex;flex-direction:column;overflow:scroll}[dir] .reportingWidgetRenderer_reportingContainer__gyod6BzNJC{margin-top:1rem;border:.2rem solid #969aa3}[dir=ltr] .reportingWidgetRenderer_dateFilter__rqRFuzepHZ{margin-left:1rem}[dir=rtl] .reportingWidgetRenderer_dateFilter__rqRFuzepHZ{margin-right:1rem}.reportingWidgetRenderer_widgetContainer__mYsQfLoEMd{height:100%}", ""]);

// Exports
exports.locals = {
	"customFilterContainer": "reportingWidgetRenderer_customFilterContainer__nHYZimnexq",
	"reportingContainer": "reportingWidgetRenderer_reportingContainer__gyod6BzNJC",
	"dateFilter": "reportingWidgetRenderer_dateFilter__rqRFuzepHZ",
	"widgetContainer": "reportingWidgetRenderer_widgetContainer__mYsQfLoEMd"
};