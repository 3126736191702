import React, { useCallback, useEffect, useMemo } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';
import Spinner from '@tekion/tekion-components/molecules/SpinnerComponent';
import { EMPTY_ARRAY } from '@tekion/tekion-base/utils/constant';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';

import withSize from '../../../../connectors/withSize';
import NavigationItem from '../../../../molecules/NavigationFlow/components/navigationItem/NavigationItem';
import DerivationConditionTabSection from './components/derivationConditionTabSection/DerivationConditionTabSection';

// Helpers
import { FIELD_IDS, getFields } from './helpers/recordTypeDetailForm.fields';
import { getSections } from './helpers/recordTypeDetailForm.sections';
import ACTION_HANDLERS from './helpers/recordTypeDetailForm.actionHandlers';

// Constants

import ACTION_TYPES from './constants/recordTypeDetailsForm.actionTypes';
import { CONTEXT_ID } from './constants/recordTypeDetailsForm.general.constants';
import ROUTES from '../../constants/routes';
import { RECORD_TYPES } from '../../../../constants/general.constants';

import styles from './recordTypeDetailForm.module.scss';

const RecordTypeDetailsForm = ({
  isLoading,
  isDeleteFieldModal,
  isSaving,
  isTypeChangeModal,
  contentHeight,
  errorTabMsg,
  selectedTabId,
  match,
  history,
  formValues,
  errors,
  conditionErrorObject,
  fieldDefinitionsOptions,
  mapOfVariableToEntityName,
  conditionBuilderFieldDefinitionObject,
  onAction,
}) => {
  const entityName = _get(match, 'params.entityName', '');
  const fields = useMemo(() => getFields(fieldDefinitionsOptions, onAction), [fieldDefinitionsOptions, onAction]);
  const Sections = useMemo(() => getSections(formValues), [formValues]);

  const headerNavigationData = useMemo(
    () => [
      { label: __('RecordType'), goTo: `${ROUTES.ENTITY_LIST_ROUTE}/${entityName}/record-type`, key: '1' },
      { label: __('Edit Record Type '), key: '2' },
    ],
    [entityName],
  );

  const handleNavigationItemClick = useCallback(
    (goTo) => {
      if (!_isEmpty(goTo)) {
        history.push(goTo);
      }
    },
    [history],
  );

  const headerNavigation = useMemo(
    () =>
      _map(headerNavigationData, (data, index) => (
        <NavigationItem
          key={_get(data, 'key', EMPTY_STRING)}
          itemNumber={index}
          totalItems={_size(headerNavigationData)}
          data={data}
          onNavigationItemClick={handleNavigationItemClick}
        />
      )),
    [headerNavigationData, handleNavigationItemClick],
  );

  const handleRedirect = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_REDIRECTION,
    });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_SUBMIT,
    });
  }, [onAction]);

  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const handleConfirmModalRequest = useCallback(() => {
    onAction({ type: ACTION_TYPES.CONFIRM_MODAL_REQUEST });
  }, [onAction]);

  const handleTypeChangeCancel = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_TYPE_CHANGE_CANCEL,
    });
  }, [onAction]);

  const handleTypeChangeSuccess = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_TYPE_CHANGE_SUCCESS,
    });
  }, [onAction]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction]);

  return (
    <Page className="full-height full-width">
      <Page.Header>
        <Heading className={styles.headerNavigationContainer} size={2}>
          {headerNavigation}
        </Heading>
      </Page.Header>

      <Page.Body style={{ height: contentHeight - 20 }}>
        <PropertyControlledComponent
          controllerProperty={!isLoading}
          fallback={
            <div className={styles.spinnerWrap}>
              <Spinner size="medium" />
            </div>
          }
        >
          <>
            <FormWithSubmission contextId={CONTEXT_ID} fields={fields} sections={Sections} values={formValues} errors={errors} onAction={onAction} />
            <PropertyControlledComponent controllerProperty={_get(formValues, FIELD_IDS.RECORD_TYPE) === RECORD_TYPES.DERIVED}>
              <div className="full-height full-width">
                <DerivationConditionTabSection
                  isLoading={isLoading}
                  allTabsByName={_get(formValues, 'allTabsByName')}
                  formValues={formValues}
                  selectedTabId={selectedTabId}
                  conditionErrorObject={conditionErrorObject}
                  mapOfVariableToEntityName={mapOfVariableToEntityName}
                  conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
                  onAction={onAction}
                />
              </div>
            </PropertyControlledComponent>
            <PropertyControlledComponent controllerProperty={isDeleteFieldModal}>
              <ConfirmationDialog
                isVisible={isDeleteFieldModal}
                title={__('Confirmation Message')}
                content={
                  <PropertyControlledComponent
                    controllerProperty={!_isEmpty(errorTabMsg)}
                    fallback={__('Are You sure you want to delete this field. This Field is not used in any RecordType')}
                  >
                    <>
                      {__("Are You sure you want to delete this field.This Field will be used in the following Record type's derivation condition ")}
                      <strong>{__(`( ${errorTabMsg} )`)}</strong>
                      {__(
                        // eslint-disable-next-line max-len
                        '.So this field is removed from the derivation Condition .Then Either add another field or delete that Row from derivation condition of recordTypes which has error.',
                      )}
                    </>
                  </PropertyControlledComponent>
                }
                onSubmit={handleConfirmModalRequest}
                onCancel={handleConfirmationDialogCancel}
              />
            </PropertyControlledComponent>
            <PropertyControlledComponent controllerProperty={isTypeChangeModal}>
              <ConfirmationDialog
                isVisible={isTypeChangeModal}
                title={__('Confirmation Message')}
                content={__('Are You sure you want to Change the Record Type.All the changes are gone if you do so')}
                onCancel={handleTypeChangeCancel}
                onSubmit={handleTypeChangeSuccess}
              />
            </PropertyControlledComponent>
          </>
        </PropertyControlledComponent>
      </Page.Body>

      <Page.Footer>
        <SaveComponent
          primaryButtonLabel={__('Save')}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleRedirect}
          primaryActionLoading={isSaving}
        />
      </Page.Footer>
    </Page>
  );
};

RecordTypeDetailsForm.propTypes = {
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  isTypeChangeModal: PropTypes.bool,
  isDeleteFieldModal: PropTypes.bool,
  selectedTabId: PropTypes.string,
  errorTabMsg: PropTypes.string,
  contentHeight: PropTypes.number.isRequired,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  conditionErrorObject: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  mapOfVariableToEntityName: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  fieldDefinitionsOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

RecordTypeDetailsForm.defaultProps = {
  isLoading: false,
  isSaving: false,
  isTypeChangeModal: false,
  isDeleteFieldModal: false,
  selectedTabId: EMPTY_STRING,
  errorTabMsg: EMPTY_STRING,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  conditionErrorObject: EMPTY_OBJECT,
  fieldDefinitionsOptions: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageFooter: 1, hasPageHeader: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(RecordTypeDetailsForm);
