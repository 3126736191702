import _split from 'lodash/split';
import _nth from 'lodash/nth';
import _drop from 'lodash/drop';
import _size from 'lodash/size';
import _has from 'lodash/has';
import _get from 'lodash/get';
import _head from 'lodash/head';

import { tget } from '@tekion/tekion-base/utils/general';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// WIP
const getRecordValueFromPageEntityRecord = (fieldArray, index, initialFieldToResolve, entityRecord, recordValue) => {
  let newRecordValue = '';
  if (index === _size(fieldArray)) {
    return recordValue;
  }

  if (_has(entityRecord, initialFieldToResolve)) {
    newRecordValue = getRecordValueFromPageEntityRecord(fieldArray, index + 1);
    return recordValue;
  } else if (_has(_get(entityRecord, 'entity'), initialFieldToResolve)) {
    newRecordValue = _get(entityRecord, initialFieldToResolve);
    return newRecordValue;
  }

  return '';
};

const getTempValue = (fieldArray, index, initialFieldToResolve, entityRecord) => {
  let newRecordValue;
  if (_has(entityRecord, initialFieldToResolve)) {
    newRecordValue = _get(entityRecord, initialFieldToResolve);
  } else if (_has(_get(entityRecord, 'entity'), initialFieldToResolve)) {
    newRecordValue = _get(entityRecord, `entity.${initialFieldToResolve}`);
  }
  return newRecordValue;
};

const getResolvedValue = (variableToResolve, data) => {
  const variableArray = _split(variableToResolve, '.');
  const variable = _head(variableArray);
  const dataForVariable = tget(data, variable, EMPTY_OBJECT);
  const fieldArray = _drop(variableArray, 1);
  const initialFieldToResolve = _nth(fieldArray, 0);
  const recordValue = getTempValue(fieldArray, 0, initialFieldToResolve, dataForVariable);
  return recordValue;
};

const getSearchParamsFromUrl = (history) => {
  const currentSearchParams = history.location.search;
  const searchParams = new URLSearchParams(currentSearchParams);

  const params = {};
  // _forEach will not work for search params
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};

export { getResolvedValue, getRecordValueFromPageEntityRecord, getSearchParamsFromUrl };
