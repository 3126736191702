import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _size from 'lodash/size';

// Components
import BaseTable from '@tekion/tekion-components/molecules/table/BaseTable';
import Button from '@tekion/tekion-components/atoms/Button';
import Heading from '@tekion/tekion-components/atoms/Heading';

// Constants
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { getColumns } from './helpers/subscriptionListRenderer.columns';
import ACTION_TYPES from '../../constants/subscriptionConfigForm.actionTypes';

import styles from './subscriptionListRenderer.module.scss';

const SubscriptionListRenderer = ({ value, onAction }) => {
  const handleRemoveSubscription = useCallback(
    (index) => () => {
      onAction({ type: ACTION_TYPES.ON_REMOVE_SUBSCRIPTION, payload: { index } });
    },
    [onAction],
  );

  const handleEditSubscription = useCallback(
    (index) => () => {
      onAction({ type: ACTION_TYPES.ON_EDIT_SUBSCRIPTION, payload: { index } });
    },
    [onAction],
  );

  const handleAddNewSubscription = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CLICK_ADD_NEW_SUBSCRIPTION });
  }, [onAction]);

  const columns = useMemo(() => getColumns(handleRemoveSubscription, handleEditSubscription), [handleRemoveSubscription, handleEditSubscription]);

  return (
    <div className={styles.subscriptionsContainer}>
      <div className={styles.addSubscriptionContainer}>
        <Heading size={2} className={styles.heading}>
          {__('Subscriptions')}
        </Heading>
        <Button view={Button.VIEW.PRIMARY} className={styles.button} onClick={handleAddNewSubscription}>
          {__('Add New')}
        </Button>
      </div>
      <BaseTable showPagination={false} columns={columns} data={value} rowHeight={40} pageSize={_size(value)} />
    </div>
  );
};

SubscriptionListRenderer.propTypes = {
  value: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

SubscriptionListRenderer.defaultProps = {
  value: EMPTY_ARRAY,
};

export default React.memo(SubscriptionListRenderer);
