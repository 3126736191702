const PROPERTIES_FORM_FIELD_IDS = {
  MANDATORY: 'mandatory',
  UNIQUE_CONSTRAINT: 'uniqueConstraint',
  CREATABLE: 'creatable',
  EDITABLE: 'editable',
  IMPORTABLE: 'importable',
  EXPORTABLE: 'exportable',
  SEARCHABLE: 'searchable',
  FILTERABLE: 'filterable',
  SORTABLE: 'sortable',
  SORT_DIRECTION: 'defaultSortDirection',
  AUDIT_ENABLED: 'auditEnabled',
  DISABLED: 'disabled',
};

export default PROPERTIES_FORM_FIELD_IDS;
