import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _remove from 'lodash/remove';
import _isEmpty from 'lodash/isEmpty';
import _split from 'lodash/split';
import _reduce from 'lodash/reduce';
import _unionBy from 'lodash/unionBy';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';
import _set from 'lodash/set';

import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';

import { getRecordGroupDetails, createRecordGroup, updateRecordGroup } from '../../../../../../actions/recordGroup.actions';
import { fetchEntityDefByName } from '../../../../../../actions/entityManagement.actions';
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../../actions/conditionBuilder.actions';
import { fetchRoles } from '../../../../../../actions/rolesHierarchyManagement.actions';
import globalLookupItemsResolver from '../../../../../../actions/globalLookupItemsResolver';

import { getRawData, getValues } from './createUserGroup.helpers';
import { CURRENT_ENTITY_NAMESPACE } from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import { ACTION_TYPES, GROUP_TYPES, FIELD_IDS } from '../constants/createUserGroup.constants';
import { FORM_MODES, STANDARD_ENTITY_NAME } from '../../../../../../constants/general.constants';
import { LOOKUP_ITEM_TO_RESOLVE, LOOKUP_FIELD_TO_RESOLVE } from '../../../../../../constants/globalLookupItemsResolver.constants';

import ROUTES from '../../../../constants/routes';
import { TENANT_UNIVERSE_BASE_ROUTE } from '../../../../../../constants/routes';
import { FIELD_IDS as ROLES_FIELD_IDS } from '../../../../../../constants/roles.constants';

const handleLoadConditionFields = async ({ setState }) => {
  setState({ isConditionFieldsLoading: true });

  const entityName = STANDARD_ENTITY_NAME.USER;
  const mapOfVariableToEntityName = { [CURRENT_ENTITY_NAMESPACE]: entityName };
  const conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityName);
  const data = await fetchEntityDefByName(entityName);
  const fieldDefinitions = _get(data, 'fieldDefinitions', EMPTY_ARRAY);
  const filterableFieldDefinitions = _filter(fieldDefinitions, (fieldDef) => tget(fieldDef, 'filterable'));
  const systemResources = _get(data, 'systemResources', EMPTY_ARRAY);
  _set(conditionBuilderFieldDefinitionObject, entityName, _keyBy(filterableFieldDefinitions, 'name'));

  setState({
    isConditionFieldsLoading: false,
    fieldDefinitions,
    systemResources,
    mapOfVariableToEntityName,
    conditionBuilderFieldDefinitionObject,
  });
};

const handleInit = async ({ getState, setState }) => {
  const { match } = getState();
  const name = _get(match, 'params.groupName');
  const url = _get(match, 'url', '');
  const matchArray = _split(url, '/');

  const isTenantUniverse = _includes(matchArray, TENANT_UNIVERSE_BASE_ROUTE);

  if (!isTenantUniverse) {
    await handleLoadConditionFields({ setState });
  }

  if (!_isEmpty(name)) {
    const userDetails = await getRecordGroupDetails(name);
    const newValues = getValues(userDetails);
    const { groupType } = userDetails;

    if (groupType === GROUP_TYPES.DYNAMIC) {
      setState({
        loading: false,
        formMode: FORM_MODES.EDIT,
        isTenantUniverse,
        values: newValues,
      });
      return;
    } else {
      const newModalData = {};
      const { containedIds } = userDetails;

      const newTableData = await globalLookupItemsResolver({ [LOOKUP_ITEM_TO_RESOLVE.USER]: { [LOOKUP_FIELD_TO_RESOLVE.ID]: containedIds } }, false);

      setState({
        loading: false,
        formMode: FORM_MODES.EDIT,
        isTenantUniverse,
        values: newValues,
        modalData: newModalData,
        tableData: _map(tget(newTableData, LOOKUP_ITEM_TO_RESOLVE.USER)),
      });
      return;
    }
  }

  setState({
    loading: false,
    formMode: FORM_MODES.CREATE,
    isTenantUniverse,
  });
};

const handleFieldChange = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { values } = getState();
  const { id, value } = params;
  const newValues = {
    ...values,
    [id]: value,
  };
  setState({ values: newValues });
};

const handleErrors = ({ params = EMPTY_OBJECT, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const handleSubmit = async ({ getState, setState }) => {
  setState({ isSaving: true });
  const { errors, values, match, tableData, history, isTenantUniverse } = getState();
  const rawData = getRawData(values, tableData, isTenantUniverse);
  const hasErrors = _reduce(
    errors,
    (hasError, curr, fieldId) => {
      if (fieldId !== FIELD_IDS.CONDITION) return hasError || !_isEmpty(curr);
      return _reduce(curr, (conditionHasError, error) => hasError || conditionHasError || !_isEmpty(error), false);
    },
    false,
  );
  const groupName = _get(match, 'params.groupName', '');
  if (hasErrors) {
    toaster(TOASTER_TYPE.ERROR, __('Please correct form errors.'));
    setState({ isSaving: false });
  } else {
    if (!_isEmpty(groupName)) {
      const updateDetails = await updateRecordGroup(groupName, rawData);
      if (!_isEmpty(updateDetails) && isTenantUniverse) {
        setTimeout(() => {
          history.push({ pathname: ROUTES.TENANT_UNIVERSE_USER_GROUP_LIST_ROUTE });
        }, ES_REFETCH_DELAY);
      } else if (!_isEmpty(updateDetails)) {
        setTimeout(() => {
          history.push({ pathname: ROUTES.USER_GROUP_LIST_ROUTE });
        }, ES_REFETCH_DELAY);
      } else {
        setState({ isSaving: false });
      }
      return;
    }

    const getData = await createRecordGroup(rawData);

    if (!_isEmpty(getData) && isTenantUniverse) {
      setTimeout(() => {
        history.push({ pathname: ROUTES.TENANT_UNIVERSE_USER_GROUP_LIST_ROUTE });
      }, ES_REFETCH_DELAY);
    } else if (!_isEmpty(getData)) {
      setTimeout(() => {
        history.push({ pathname: ROUTES.USER_GROUP_LIST_ROUTE });
      }, ES_REFETCH_DELAY);
    } else {
      setState({ isSaving: false });
    }
  }
};

const handleModalClick = ({ setState }) => {
  setState({ isModalVisible: true, modalData: {} });
};

const handleModalSave = async ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { users } = params;
  const { tableData = EMPTY_ARRAY, history } = getState();
  const isTenantUniverse = _includes(_get(history, 'location.pathname', ''), TENANT_UNIVERSE_BASE_ROUTE);
  let selectedUsers = _unionBy(tableData, users, 'id');

  if (!isTenantUniverse) {
    const roleNames = _reduce(
      selectedUsers,
      (acc, item) => {
        const roleName = _get(item, 'entity.rolename', EMPTY_STRING);

        if (!_isEmpty(roleName)) {
          acc.push(roleName);
        }

        return acc;
      },
      [],
    );

    let response = {};

    if (!_isEmpty(roleNames)) {
      response = await fetchRoles({
        filters: [
          {
            field: ROLES_FIELD_IDS.ROLE_NAME,
            values: roleNames,
            filterType: OPERATORS.IN,
          },
        ],
      });
    }

    const rolesByName = _keyBy(tget(response, 'hits', EMPTY_ARRAY), ROLES_FIELD_IDS.ROLE_NAME);
    selectedUsers = _map(selectedUsers, (item) => ({
      ...item,
      roleName: _get(rolesByName[_get(item, 'entity.rolename')], ROLES_FIELD_IDS.DISPLAY_NAME),
    }));
  }

  setState({ isModalVisible: false, modalData: { selectedUsers }, tableData: selectedUsers });
};

const handleModalClose = ({ setState }) => {
  setState({ isModalVisible: false });
};

const handleRemoveRow = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { tableData } = getState();
  const { row } = params;
  _remove(tableData, (val, index) => index === row);
  setState({ tableData: [...tableData] });
};

const handleOnFieldBlur = ({ getState, setState, params }) => {
  const { errors = EMPTY_OBJECT } = getState();
  const { id, value } = params;
  if (id === FIELD_IDS.CONDITION) {
    setState({ errors: { ...errors, [id]: value } });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.MODAL_CLICK]: handleModalClick,
  [ACTION_TYPES.MODAL_SAVE]: handleModalSave,
  [ACTION_TYPES.MODAL_CLOSE]: handleModalClose,
  [ACTION_TYPES.TABLE_REMOVE_ROW]: handleRemoveRow,
  [ACTION_TYPES.LOAD_CONDITION_FIELDS]: handleLoadConditionFields,
  [FORM_ACTION_TYPES.ON_FIELD_BLUR]: handleOnFieldBlur,
};

export default ACTION_HANDLERS;
