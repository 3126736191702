import React, { useCallback, useMemo } from 'react';

import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _set from 'lodash/set';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import WithSize from '../../../../../connectors/withSize';

import getFields from './helpers/fieldsForm.fields';
import getSections from './helpers/fieldsForm.sections';
import ACTION_HANDLERS from './helpers/fieldsForm.actionHandlers';

import { FORM_MODES } from '../../../../../constants/general.constants';
import ACTION_TYPES from './constants/fieldsForm.actionTypes';
import FIELDS_FORM_FIELD_IDS from './constants/fieldsForm.fieldIds';
import { FIELD_FORM_CONTEXT_ID, SUBMIT_ADDITIONAL_VALUE } from './constants/fieldsForm.constants';

const FieldsForm = ({
  isFetching,
  isFormDisabled,
  isSavingDetails,
  isSavingDetailsCreateNew,
  contentHeight,
  formMode,
  initialState,
  cachedEntityDefs,
  formValues,
  match,
  errors,
  onAction,
}) => {
  const { entityName } = useMemo(() => _get(match, 'params', EMPTY_OBJECT), [match]);

  const primaryButtonLabel = useMemo(() => (formMode === FORM_MODES.CREATE ? __('Save') : __('Update')), [formMode]);

  const fields = useMemo(
    () => getFields(formMode, entityName, formValues, initialState, cachedEntityDefs),
    [formMode, entityName, formValues, initialState, cachedEntityDefs],
  );

  const sections = useMemo(() => getSections(_get(formValues, FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM, {})), [formValues]);

  const triggerFormSave = useCallback(
    (actionType) => () => {
      const formIds = [];
      const formErrors = {};

      _forEach(sections, ({ rows }) => {
        const formId = _get(rows, '0.columns.0');

        _set(formErrors, formId, true);
        formIds.push(formId);
      });

      _forEach(formIds, (formId) => {
        triggerSubmit(formId, { formIds, actionType, formErrors });
      });
    },
    [sections],
  );

  const headerTitle = useMemo(() => (formMode === FORM_MODES.CREATE ? __('Create Field') : __('Update Field')), [formMode]);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  return (
    <Page>
      <Page.Header hasBack>
        <Heading>{headerTitle}</Heading>
      </Page.Header>
      <Page.Body className="full-width flex" style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          className="full-width"
          isFetching={isFetching}
          isFormDisabled={isFormDisabled}
          contextId={FIELD_FORM_CONTEXT_ID}
          values={formValues}
          errors={errors}
          fields={fields}
          sections={sections}
          onAction={onAction}
        />
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id={FIELD_FORM_CONTEXT_ID}
          showAdditionalButton={formMode === FORM_MODES.CREATE}
          isPrimaryDisabled={isFormDisabled}
          isAdditionalDisabled={isFetching}
          additionalActionLoading={isSavingDetailsCreateNew}
          primaryActionLoading={isSavingDetails}
          additionalActionView="primary"
          additionalButtonLabel={__('Save and Create New')}
          primaryButtonLabel={primaryButtonLabel}
          onAdditionalAction={triggerFormSave(SUBMIT_ADDITIONAL_VALUE.SAVE_AND_CREATE_NEW)}
          onPrimaryAction={triggerFormSave(SUBMIT_ADDITIONAL_VALUE.SAVE)}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

FieldsForm.propTypes = {
  isFormDisabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  isSavingDetails: PropTypes.bool,
  isSavingDetailsCreateNew: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  initialState: PropTypes.object,
  cachedEntityDefs: PropTypes.object,
  match: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

FieldsForm.defaultProps = {
  isFetching: true,
  isFormDisabled: true,
  isSavingDetails: false,
  isSavingDetailsCreateNew: false,
  formMode: FORM_MODES.CREATE,
  formValues: EMPTY_OBJECT,
  initialState: EMPTY_OBJECT,
  cachedEntityDefs: EMPTY_OBJECT,
  match: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default compose(WithSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(FieldsForm);
