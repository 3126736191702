import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../../../../constants/approvalCentre.constants';

const CONTEXT_ID = 'APPROVAL_SETTING_FORM';

const FIELD_IDS = {
  GROUP: APPROVAL_CENTRE_FIELD_IDS.GROUP,
  CATEGORY: APPROVAL_CENTRE_FIELD_IDS.CATEGORY,
  DISPLAY_NAME: APPROVAL_CENTRE_FIELD_IDS.DISPLAY_NAME,
};

export { CONTEXT_ID, FIELD_IDS };
