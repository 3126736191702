import _get from 'lodash/get';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { COLUMNS } from '../components/dependencyConfigTable/constants/dependencyConfigTable.general';
import DEPENDENCY_CONFIG_FORM_FIELD_IDS from '../constants/dependencyConfigForm.fieldIds';

const getDependencyConfigFormValues = (fieldsFormDependencyConfigValues) => ({
  [DEPENDENCY_CONFIG_FORM_FIELD_IDS.DEPENDENCY_CONFIG_TABLE]: _map(
    _get(fieldsFormDependencyConfigValues, DEPENDENCY_CONFIG_FORM_FIELD_IDS.DEPENDENCY_CONFIG_TABLE),
    (row) =>
      _reduce(
        COLUMNS,
        (field, columnId) => {
          _set(field, columnId, _castArray(_get(row, columnId)));
          return field;
        },
        {},
      ),
  ),
});

const getFieldsFormDependencyConfigValues = (values) => {
  const dependencyConfig = tget(values, DEPENDENCY_CONFIG_FORM_FIELD_IDS.DEPENDENCY_CONFIG_TABLE, EMPTY_ARRAY);

  return {
    [DEPENDENCY_CONFIG_FORM_FIELD_IDS.DEPENDENCY_CONFIG_TABLE]: _map(dependencyConfig, (dependencyConfigOption) =>
      _reduce(COLUMNS, (acc, columnId) => _set(acc, columnId, getArraySafeValue(_get(dependencyConfigOption, columnId))), {}),
    ),
  };
};

export { getDependencyConfigFormValues, getFieldsFormDependencyConfigValues };
