import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';

import { getValueFromLocalStorage } from '@tekion/tekion-base/utils/localStorage';
import { setCookie, getCookie } from '@tekion/tekion-base/utils/cookieStorage';

import { removeValueFromLocalStorage } from '../../utils/localStorage';
import { removeCookie } from '../../utils/cookieStorage';

import LS_CACHE_KEYS from '../../constants/localStorage.cacheKeys';
import { LOGIN_PATHNAME } from './WithAuth.constants';

const isAuthenticated = () => {
  const token = getValueFromLocalStorage(LS_CACHE_KEYS.TOKEN);
  const userDetails = getValueFromLocalStorage(LS_CACHE_KEYS.USER);

  return !(!token || _isEmpty(userDetails));
};

const redirectToLastRoute = (reDirectUrl = '/') => {
  if (reDirectUrl !== '/') {
    const sanitizedUrl = reDirectUrl[0] !== '/' ? '/' : reDirectUrl;
    window.location.href = `${window.location.origin}${sanitizedUrl}`;
  } else {
    window.location.href = getCookie('redirected_from');
  }
};

const redirectToLogin = (shouldRedirectByReplacing, additionalPath = '', shouldSetCookie = false) => {
  const loginUrl = `${window.location.origin}${LOGIN_PATHNAME}${additionalPath}`;
  if (shouldSetCookie) setCookie('redirected_from', window.location.href);
  if (shouldRedirectByReplacing) {
    window.location.replace(loginUrl);
    return;
  }
  if (window.location.href !== loginUrl) {
    window.location.href = loginUrl;
  }
};

const getRedirectUrl = (history) => {
  const baseObj = new URLSearchParams(history.location.search); // returns key value pairs in URL
  const DEFAULT_ROUTE = '/';
  return (
    _reduce(
      Array.from(baseObj.entries()), // making array of iterable object
      (basePath, [key, value]) => {
        const doesBasePathHasQueryKey = new RegExp(`(\\?|&)${key}=`);
        return key !== 'redirectTo' && !doesBasePathHasQueryKey.test(basePath) ? `${basePath}&${key}=${value}` : basePath;
      },
      baseObj.get('redirectTo'),
    ) || DEFAULT_ROUTE
  );
};

const clearLoginData = (shouldRedirect) => {
  removeValueFromLocalStorage(LS_CACHE_KEYS.TOKEN);
  removeValueFromLocalStorage(LS_CACHE_KEYS.USER);
  removeValueFromLocalStorage(LS_CACHE_KEYS.PROJECT_CONTEXT);
  removeValueFromLocalStorage(LS_CACHE_KEYS.VIEW_CONTEXTS);
  removeCookie(LS_CACHE_KEYS.COOKIES);

  if (window.zE) {
    window.zE('webWidget', 'logout');
  }

  if (shouldRedirect) {
    window.location.reload();
  }
};

export { isAuthenticated, getRedirectUrl, redirectToLastRoute, redirectToLogin, clearLoginData };
