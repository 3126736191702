import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { FIELD_IDS } from './cellViewAttributePropertiesForm.constants';
import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';

import styles from './cellViewAttributePropertiesForm.module.scss';

const rowCheck = {
  [FIELD_IDS.DATE_TIME_FORMAT]: ({ dataType }) => dataType === DATA_TYPES.DATE || dataType === DATA_TYPES.DATE_TIME,
  [FIELD_IDS.IS_AVATAR_VIEW]: ({ fieldType }) => fieldType === FIELD_TYPES.MEDIA,
  [FIELD_IDS.IS_RTE_TRUNCATED_TYPE]: ({ fieldType }) => fieldType === FIELD_TYPES.RICH_TEXT_EDITOR,
};

const getSections = (dataType, fieldType) => [
  {
    rows: [
      {
        columns: [FIELD_IDS.WIDTH],
      },
      {
        columns: filterRows([FIELD_IDS.IS_AVATAR_VIEW, FIELD_IDS.IS_RTE_TRUNCATED_TYPE], { fieldType }, rowCheck),
      },
      {
        columns: filterRows([FIELD_IDS.DATE_TIME_FORMAT], { dataType }, rowCheck),
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.EVENT_HANDLERS],
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.CUSTOM_STYLES],
      },
    ],
  },
];

export { getSections };
