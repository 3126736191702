export const setValueInLocalStorage = (key, value) => {
  window.localStorage.setItem(key, value);
};

export const removeValueFromLocalStorage = (key) => window.localStorage.removeItem(key);

export const getValueFromLocalStorage = (key) => window.localStorage.getItem(key);

export const getAllKeysInLocalStorage = () => {
  const keys = [];
  for (let i = 0; i < window.localStorage.length; i += 1) {
    keys.push(window.localStorage.key(i));
  }
  return keys;
};
