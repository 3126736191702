import { defaultMemoize } from 'reselect';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Validators
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

// Renderers
import TextAreaInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import ConditionBuilder from '../../../../../../organisms/conditionBuilder';
import CONDITION_BUILDER_MODES from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.modes';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import { FIELD_IDS } from './createUserGroup.constants';
import { CONDITION_BUILDER_TYPES, CURRENT_ENTITY_NAMESPACE } from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';

// import RadioGroup from './Radio';
import { getOperators, getOptions, getResourceFieldDef } from '../helpers/createUserGroup.helpers';
import styles from '../createUserGroup.module.scss';
import { isConditionRequiredRule } from '../../../../../../organisms/conditionBuilder/helpers/conditionBuilder.general.helpers';

const GROUP_NAME_FIELD = {
  id: FIELD_IDS.USER_GROUP_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Group Name '),
    placeholder: __('Enter Group Name'),
    validators: [isRequiredRule],
  },
};

const DESCRIPTION_FIELD = {
  id: FIELD_IDS.DESCRIPTION,
  renderer: TextAreaInput,
  renderOptions: {
    label: __('Description'),
    placeholder: __('Type Description'),
  },
};

const GROUP_TYPE_FIELD = {
  id: FIELD_IDS.GROUP_TYPE,
  renderer: Radio,
  renderOptions: {
    validators: [isRequiredRule],
    required: true,
  },
};

const FORM_FIELDS = {
  [FIELD_IDS.USER_GROUP_NAME]: GROUP_NAME_FIELD,
  [FIELD_IDS.DESCRIPTION]: DESCRIPTION_FIELD,
  [FIELD_IDS.GROUP_TYPE]: GROUP_TYPE_FIELD,
};

const getConditionFields = defaultMemoize((fieldDefinitions, systemResources, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject) => ({
  [FIELD_IDS.CONDITION]: {
    id: FIELD_IDS.CONDITION,
    renderer: ConditionBuilder,
    renderOptions: {
      validators: [isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)],
      required: true,
      containerClassname: fieldLayoutStyles.fieldC,
      mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
      conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
      childProps: {
        [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
          label: '',
          childProps: {
            [CONDITION_FIELD_IDS.FIELD]: {
              label: __('Resource'),
              getOptions: getOptions(systemResources, fieldDefinitions),
              mapOfVariableToEntityName,
              conditionBuilderFieldDefinitionObject,
              isVariablePrefixNeeded: false,
              variableNameForPrefixNotNeeded: CURRENT_ENTITY_NAMESPACE,
            },
            [CONDITION_FIELD_IDS.OPERATOR]: {
              label: __('Operator'),
              getOptions: getOperators,
              fieldDefinitions,
              mapOfVariableToEntityName,
              conditionBuilderFieldDefinitionObject,
              isVariablePrefixNeeded: false,
              variableNameForPrefixNotNeeded: CURRENT_ENTITY_NAMESPACE,
            },
            [CONDITION_FIELD_IDS.VALUES]: {
              label: __('Value'),
              fieldDefinitions,
              getFieldDef: getResourceFieldDef,
              mapOfVariableToEntityName,
              conditionBuilderFieldDefinitionObject,
              isVariablePrefixNeeded: false,
              variableNameForPrefixNotNeeded: CURRENT_ENTITY_NAMESPACE,
            },
          },
        },
      },
    },
  },
}));

const rowCheck = {
  [FIELD_IDS.GROUP_TYPE]: ({ isTenantUniverse }) => !isTenantUniverse,
};

const getGroupDetailsSection = defaultMemoize((isTenantUniverse) => [
  {
    header: {
      label: __('User Group details'),
    },
    rows: [
      {
        columns: [FIELD_IDS.USER_GROUP_NAME],
        className: styles.halfColumn,
      },
      {
        columns: [FIELD_IDS.DESCRIPTION],
      },
      {
        columns: filterRows([FIELD_IDS.GROUP_TYPE], { isTenantUniverse }, rowCheck),
      },
    ],
  },
]);

const DYNAMIC_USER_GROUP = [
  {
    className: styles.fullWidthSection,
    rows: [
      {
        columns: [FIELD_IDS.CONDITION],
      },
    ],
  },
];

const INITIAL_STATE = {
  values: {
    [FIELD_IDS.CONDITION]: {
      [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: [EMPTY_OBJECT],
      [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: '1',
    },
  },
};

export { INITIAL_STATE, DYNAMIC_USER_GROUP, getGroupDetailsSection, getConditionFields, getOperators, FORM_FIELDS };
