import { defaultMemoize } from 'reselect';

import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _castArray from 'lodash/castArray';
import _forEach from 'lodash/forEach';
import _reduce from 'lodash/reduce';
import _difference from 'lodash/difference';
import _size from 'lodash/size';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { fetchEntities } from '../../../../../../actions/entityManagement.actions';

import { ADDITIONAL_DATA_FORM_FIELDS, ENTITY_SELECT_DROPDOWN_ROWS, FIELD_IDS, TEMPLATE_FIELD_IDS } from '../constants/dataImportForm.constants';
import fieldDefinitionReader from '../../../../../../readers/fieldDefinition.reader';

const getValueOption = (entityDef) => ({
  label: __(`${tget(entityDef, 'displayName', EMPTY_STRING)}`) || __(`${tget(entityDef, 'name', EMPTY_STRING)}`),
  value: tget(entityDef, 'name', EMPTY_STRING),
  ...entityDef,
});

const getOptions = (data) => _map(data, getValueOption);

const getAsyncSelectPayload = defaultMemoize(({ nextPageToken, searchText }) => {
  const filters = [];

  if (!_isEmpty(searchText) && !_isEmpty(searchText)) {
    filters.push({
      field: 'name',
      values: [searchText],
      filterType: OPERATORS.TEXT_STARTS_WITH,
    });
  }

  return {
    rows: ENTITY_SELECT_DROPDOWN_ROWS,
    nextPageToken,
    filters,
  };
});

const handleFetchEntities = async (payload) => {
  const response = await fetchEntities(payload, true);
  return response;
};

const getMappedFormValueToApiFormat = (formValues) => {
  const additionalData = _pick(formValues, ADDITIONAL_DATA_FORM_FIELDS);
  const mappedFormValueToApiFormat = _omit(formValues, ADDITIONAL_DATA_FORM_FIELDS);

  return {
    ...mappedFormValueToApiFormat,
    [FIELD_IDS.ADDITIONAL_DATA]: additionalData,
  };
};

const getMappedApiFormatValueToFormValue = (taskResponse) => {
  const additionalData = tget(taskResponse, FIELD_IDS.ADDITIONAL_DATA, EMPTY_OBJECT);
  const formValues = _omit(taskResponse, [FIELD_IDS.ADDITIONAL_DATA]);

  return {
    ...formValues,
    ...additionalData,
  };
};

const getColumnDefinitionsFromTemplate = (templateResponse) =>
  _map(tget(templateResponse, TEMPLATE_FIELD_IDS.COLUMN_DEFINITIONS, EMPTY_ARRAY), (fieldMapping) => ({
    ...fieldMapping,
    [TEMPLATE_FIELD_IDS.FIELD_NAME]: _castArray(tget(fieldMapping, TEMPLATE_FIELD_IDS.FIELD_NAME)),
  }));

const getPayloadForUpdatingColumnDefinitions = (columnDefinitions) => {
  const apiFormattedColumnDefinitions = _map(columnDefinitions, (fieldMapping) => ({
    ...fieldMapping,
    [TEMPLATE_FIELD_IDS.FIELD_NAME]: getArraySafeValue(tget(fieldMapping, TEMPLATE_FIELD_IDS.FIELD_NAME)),
  }));

  return {
    [TEMPLATE_FIELD_IDS.COLUMN_DEFINITIONS]: apiFormattedColumnDefinitions,
  };
};

const getFieldMappingCount = (columnDefinitions) => {
  let mappedFieldsCount = 0;
  let unmappedFieldsCount = 0;

  _forEach(columnDefinitions, (fieldMapping) => {
    const fieldName = getArraySafeValue(tget(fieldMapping, TEMPLATE_FIELD_IDS.FIELD_NAME, ''));
    const displayName = getArraySafeValue(tget(fieldMapping, TEMPLATE_FIELD_IDS.DISPLAY_NAME, ''));

    if (!_isEmpty(fieldName) && !_isEmpty(displayName)) {
      mappedFieldsCount++;
    } else {
      unmappedFieldsCount++;
    }
  });

  return {
    mappedFieldsCount,
    unmappedFieldsCount,
  };
};

const validateIfAllUniqueAndMandatoryFieldsAreMapped = (columnDefinitions, selectedEntityFieldDefinitions) => {
  let isValid = true;
  let message = '';

  const uniqueOrMandatoryFields = _reduce(
    selectedEntityFieldDefinitions,
    (prevUniqueOrMandatoryFields, fieldDef) => {
      if (fieldDefinitionReader.uniqueConstraint(fieldDef) || fieldDefinitionReader.mandatory(fieldDef)) {
        return [...prevUniqueOrMandatoryFields, fieldDefinitionReader.name(fieldDef)];
      }

      return prevUniqueOrMandatoryFields;
    },
    [],
  );

  const mappedFields = _reduce(
    columnDefinitions,
    (prevMappedFields, fieldMapping) => {
      if (
        !_isEmpty(getArraySafeValue(tget(fieldMapping, TEMPLATE_FIELD_IDS.FIELD_NAME))) &&
        !_isEmpty(getArraySafeValue(tget(fieldMapping, TEMPLATE_FIELD_IDS.DISPLAY_NAME)))
      ) {
        return [...prevMappedFields, getArraySafeValue(tget(fieldMapping, TEMPLATE_FIELD_IDS.FIELD_NAME))];
      }

      return prevMappedFields;
    },
    [],
  );

  const unmappedUniqueOrMandatoryFields = _difference(uniqueOrMandatoryFields, mappedFields);

  if (_size(unmappedUniqueOrMandatoryFields) > 0) {
    isValid = false;
    message = __('All the unique and mandatory fields for the selected entity should be mapped');
  }

  return { isValid, message };
};

export {
  getOptions,
  getAsyncSelectPayload,
  handleFetchEntities,
  getMappedFormValueToApiFormat,
  getMappedApiFormatValueToFormValue,
  getColumnDefinitionsFromTemplate,
  getPayloadForUpdatingColumnDefinitions,
  getFieldMappingCount,
  validateIfAllUniqueAndMandatoryFieldsAreMapped,
};
