import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/cas/custom-entities';

const createEntityRecord = (entityName, payload) => ApiService.post(`${BASE_URL}/${entityName}`, payload);

const updateEntityRecord = (entityName, entityRecordId, payload) => ApiService.put(`${BASE_URL}/${entityName}/${entityRecordId}`, payload);

const fetchEntityRecords = (entityName, payload) => ApiService.post(`${BASE_URL}/${entityName}/search`, payload);

const fetchSimilarEntityRecords = (entityName, recordId, payload) =>
  ApiService.post(`${BASE_URL}/${entityName}/search/similar`, payload, { recordId });

const fetchEntityRecordById = (entityName, entityRecordId) => ApiService.get(`${BASE_URL}/${entityName}/${entityRecordId}`);

const getDerivedRecordType = (entityName, payload) => ApiService.post(`${BASE_URL}/${entityName}/derived/record-type`, payload);

export default {
  createEntityRecord,
  updateEntityRecord,
  fetchEntityRecords,
  fetchEntityRecordById,
  getDerivedRecordType,
  fetchSimilarEntityRecords,
};
