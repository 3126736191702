import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Select from '@tekion/tekion-components/molecules/Select';
import Button from '@tekion/tekion-components/atoms/Button';
import Modal from '@tekion/tekion-components/molecules/Modal';

import { MODAL_ACTION_TYPES } from '../../constants/reporteeList.actionTypes';
import { ROLE_FORM_CONTEXT_ID } from '../../constants/reporteeList.general.constants';
import RoleForm from '../../../../molecules/roleForm/RoleForm';

import styles from './deleteModal.module.scss';

const DeleteModal = ({
  isDeleteModalVisible,
  isModalRoleFormVisible,
  selectedRoleValue,
  deletedRoleName,
  newReporteeData,
  roleOptions,
  errors,
  onAction,
}) => {
  const handleDeleteModalClose = useCallback(() => {
    onAction({ type: MODAL_ACTION_TYPES.ON_CLOSE_DELETE_MODAL });
  }, [onAction]);

  const handleOnDone = useCallback(() => {
    onAction({ type: MODAL_ACTION_TYPES.ON_CLICK_DONE });
  }, [onAction]);

  const handleClickAddNewReportee = useCallback(() => {
    onAction({ type: MODAL_ACTION_TYPES.ON_CLICK_MODAL_ADD_NEW_REPORTEE });
  }, [onAction]);

  const handleClearRoleFormFields = useCallback(() => {
    onAction({ type: MODAL_ACTION_TYPES.ON_CLICK_CLEAR_FIELDS });
  }, [onAction]);

  const handleOnChangeRole = useCallback(
    (selectedvalue) => {
      onAction({ type: MODAL_ACTION_TYPES.ON_CHANGE_ROLE, payload: { selectedvalue } });
    },
    [onAction],
  );

  const isAddNewReporteeButtonDisabled = useMemo(() => !_isEmpty(selectedRoleValue), [selectedRoleValue]);

  const renderBody = useCallback(
    () => (
      <>
        <div className={styles.infoContainer}>
          <FontIcon size={SIZES.MD} className={styles.infoIconContainer}>
            icon-info
          </FontIcon>
          <div className={styles.infoContentContainer}>{__(`Transfer the reportees before deleting ${deletedRoleName} !`)}</div>
        </div>
        <div className={styles.selectLabelContainer}>{__('Transfer Reportees to')}</div>
        <div className={styles.reporteeSelectContainer}>
          <Select onChange={handleOnChangeRole} className={styles.reporteeSelect} options={roleOptions} value={selectedRoleValue} allowClear />
          <span className={styles.spanBox}>or</span>
          <Button view={Button.VIEW.SECONDARY} onClick={handleClickAddNewReportee} disabled={isAddNewReporteeButtonDisabled}>
            {__('Add New Reportee')}
          </Button>
        </div>

        {isModalRoleFormVisible && !isAddNewReporteeButtonDisabled && (
          <div className={styles.roleFormContainer}>
            <div className={styles.formHeaderContainer}>
              <div>{__('Transfer to New Reportee')}</div>
              <Button view={Button.VIEW.TERTIARY} onClick={handleClearRoleFormFields}>
                {__('Clear all Fields')}
              </Button>
            </div>
            <RoleForm contextId={ROLE_FORM_CONTEXT_ID} onAction={onAction} roleData={newReporteeData} roleOptions={roleOptions} errors={errors} />
          </div>
        )}
      </>
    ),
    [
      isModalRoleFormVisible,
      errors,
      newReporteeData,
      roleOptions,
      selectedRoleValue,
      deletedRoleName,
      isAddNewReporteeButtonDisabled,
      handleClearRoleFormFields,
      handleClickAddNewReportee,
      handleOnChangeRole,
      onAction,
    ],
  );

  return (
    <Modal
      visible={isDeleteModalVisible}
      title={__('Confirmation message')}
      submitBtnText={__('Done')}
      onCancel={handleDeleteModalClose}
      onSubmit={handleOnDone}
    >
      {renderBody()}
    </Modal>
  );
};

DeleteModal.propTypes = {
  isDeleteModalVisible: PropTypes.bool,
  isModalRoleFormVisible: PropTypes.bool,
  selectedRoleValue: PropTypes.string,
  deletedRoleName: PropTypes.string,
  newReporteeData: PropTypes.object,
  roleOptions: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func,
};

DeleteModal.defaultProps = {
  isDeleteModalVisible: false,
  isModalRoleFormVisible: false,
  selectedRoleValue: EMPTY_STRING,
  deletedRoleName: EMPTY_STRING,
  newReporteeData: EMPTY_OBJECT,
  roleOptions: EMPTY_ARRAY,
  errors: EMPTY_OBJECT,
  onAction: _noop,
};

export default DeleteModal;
