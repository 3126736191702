const ACTION_TYPES = {
  ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE: 'ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE',
  DELETE_ROW: 'DELETE_ROW',
  ON_CLICK_CONFIGURE_TAB: 'ON_CLICK_CONFIGURE_TAB',
  ON_CLICK_ADD_EVENT: 'ON_CLICK_ADD_EVENT',
  ON_SAVE_MODAL: 'ON_SAVE_MODAL',
  ON_CANCEL_MODAL: 'ON_CANCEL_MODAL',
  ON_CHANGE_MODULE: 'ON_CHANGE_MODULE',
  ON_ADD_TAB: 'ON_ADD_TAB',
  ON_DELETE_TAB: 'ON_DELETE_TAB',
  ON_TAB_NAME_UPDATE: 'ON_TAB_NAME_UPDATE',
};

export default ACTION_TYPES;
