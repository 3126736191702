import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import ROUTES from '../../../../../constants/routes';

const INITIAL_STATES_REPORTEE_FORM_PROPS = {
  roleData: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

const REPORTEE_FORM_CONTEXT_ID = 'REPORTEE_MANAGEMENT_FORM';

const ROLES_FORM_NAVIGATION_DEFAULT_DATA = [{ label: __('Roles'), goTo: ROUTES.REPORTEE_ROUTE, key: 'reportee' }];

export { REPORTEE_FORM_CONTEXT_ID, INITIAL_STATES_REPORTEE_FORM_PROPS, ROLES_FORM_NAVIGATION_DEFAULT_DATA };
