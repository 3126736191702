import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';

// Components
import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';

// eslint-disable-next-line max-len
import ApprovalAddCommentModal from '../../../pages/devPlatformExperience/pages/approvalCentre/organisms/approvalAddCommentModal/ApprovalAddCommentModal';

// Helpers
import { createTableProps, createFilterProps } from './helpers/approvalTasksList.helpers';
import getColumns from './helpers/approvalTasksList.columns';
import ACTION_HANDLERS from './helpers/approvalTasksList.actionHandlers';

// Constants
import { TABLE_MANAGER_PROPS, INITIAL_STATE } from './constants/approvalTasksList.general';

const ApprovalTasksList = ({
  isMountedInsideApplication,
  isAddCommentModalVisible,
  isLoading,
  contentHeight,
  currentPage,
  pageSize,
  nextPageToken,
  selectedFilters,
  filterMetadata,
  applicationProperties,
  data,
  onRowClick,
  onAction,
}) => {
  const tableProps = useMemo(
    () => createTableProps(isLoading, currentPage, pageSize, onAction, onRowClick),
    [isLoading, currentPage, pageSize, onAction, onRowClick],
  );
  const columns = useMemo(() => getColumns(isMountedInsideApplication), [isMountedInsideApplication]);
  const filterProps = useMemo(
    () => createFilterProps(filterMetadata, applicationProperties, onAction),
    [filterMetadata, applicationProperties, onAction],
  );

  return (
    <Page style={{ height: contentHeight - 64 }}>
      <TableManager
        tokenPagination
        nextPageToken={nextPageToken}
        tableManagerProps={TABLE_MANAGER_PROPS}
        tableProps={tableProps}
        columns={columns}
        data={data}
        filterProps={filterProps}
        selectedFilters={selectedFilters}
        onAction={onAction}
      />
      <ApprovalAddCommentModal isAddCommentModalVisible={isAddCommentModalVisible} onAction={onAction} />
    </Page>
  );
};

ApprovalTasksList.propTypes = {
  isMountedInsideApplication: PropTypes.bool,
  isAddCommentModalVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  nextPageToken: PropTypes.string,
  filterMetadata: PropTypes.shape({
    GROUPS: PropTypes.array,
    CATEGORIES: PropTypes.array,
  }),
  applicationProperties: PropTypes.object,
  data: PropTypes.array,
  selectedFilters: PropTypes.array,
  onRowClick: PropTypes.func,
  onAction: PropTypes.func.isRequired,
};

ApprovalTasksList.defaultProps = {
  isMountedInsideApplication: false,
  isAddCommentModalVisible: false,
  isLoading: false,
  currentPage: DEFAULT_PAGE_INFO.start,
  pageSize: DEFAULT_PAGE_SIZE,
  nextPageToken: EMPTY_STRING,
  filterMetadata: {
    GROUPS: EMPTY_ARRAY,
    CATEGORIES: EMPTY_ARRAY,
  },
  applicationProperties: EMPTY_OBJECT,
  data: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
  onRowClick: undefined,
};

export default withActions(INITIAL_STATE, ACTION_HANDLERS)(ApprovalTasksList);
