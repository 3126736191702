import { defaultMemoize } from 'reselect';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _isNil from 'lodash/isNil';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { ASSET_TYPES } from '../../../../../../constants/general.constants';
import DATA_TYPES from '../../../../../../constants/fieldDefinition.dataTypes';
import { CONDITION_FIELD_IDS, CONDITION_BUILDER_FIELD_IDS } from '../../../../../../organisms/conditionBuilder';
import { OPERATORS } from '../../../../../../organisms/conditionBuilder/atoms/operatorField/operatorField.constants';
import { FIELD_IDS, GROUP_TYPES, GROUP_TYPE_OPTIONS } from '../constants/createUserGroup.constants';
import { DYNAMIC_USER_GROUP, getGroupDetailsSection, FORM_FIELDS, getConditionFields } from '../constants/createUserGroup.config';

import fieldDefinitionReader from '../../../../../../readers/fieldDefinition.reader';

const getSections = defaultMemoize((values, isTenantUniverse) => {
  const groupType = _get(values, FIELD_IDS.GROUP_TYPE, '');
  const userGroupDetailSection = getGroupDetailsSection(isTenantUniverse);
  if (groupType === GROUP_TYPES.DYNAMIC) return [...userGroupDetailSection, ...DYNAMIC_USER_GROUP];
  else return userGroupDetailSection;
});

const getFields = defaultMemoize(
  (isTenantUniverse, systemResources, fieldDefinitions, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject) => {
    const groupTypeOptions = isTenantUniverse ? GROUP_TYPE_OPTIONS : [...GROUP_TYPE_OPTIONS, { label: __('Dynamic'), value: GROUP_TYPES.DYNAMIC }];
    const conditionField = getConditionFields(fieldDefinitions, systemResources, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject);
    return {
      ...FORM_FIELDS,
      ...conditionField,
      [FIELD_IDS.GROUP_TYPE]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.GROUP_TYPE], [{ path: 'radios', value: groupTypeOptions }]),
    };
  },
);

const getFieldOptions = defaultMemoize((fieldDefinitions) =>
  _map(fieldDefinitions, (field) => {
    const displayName = fieldDefinitionReader.displayName(field);
    return {
      label: displayName,
      value: field.name,
    };
  }),
);

const getOptions = (_, fieldDefinitions) => () => getFieldOptions(fieldDefinitions);

const getResourceFieldDef = (_, fieldDefinitions, fieldName) =>
  _find(fieldDefinitions, (field) => {
    const name = fieldDefinitionReader.name(field);
    return name === fieldName;
  });

const getOperators = (values, _, fieldDefinitions) => {
  const resourceValue = values?.[CONDITION_FIELD_IDS.FIELD];

  if (_isEmpty(resourceValue)) return EMPTY_ARRAY;

  const fieldName = getArraySafeValue(resourceValue);

  const fieldDef = _find(fieldDefinitions, (field) => {
    const name = fieldDefinitionReader.name(field);
    return name === fieldName;
  });

  if (_isEmpty(fieldDef)) {
    return EMPTY_ARRAY;
  }
  const dataType = fieldDefinitionReader.dataType(fieldDef);
  switch (dataType) {
    case DATA_TYPES.TEXT:
      return [OPERATORS.IN, OPERATORS.NOT_IN, OPERATORS.STARTS_WITH, OPERATORS.ENDS_WITH];
    case DATA_TYPES.BOOLEAN:
      return [OPERATORS.EQUALS, OPERATORS.DOES_NOT_EQUAL];
    case DATA_TYPES.NUMBER:
    case DATA_TYPES.DATE:
    case 'DATE_TIME':
      return [
        OPERATORS.EQUALS,
        OPERATORS.DOES_NOT_EQUAL,
        OPERATORS.LESS_THAN,
        OPERATORS.GREATER_THAN,
        OPERATORS.LESS_THAN_EQUAL_TO,
        OPERATORS.GREATER_THAN_EQUAL_TO,
      ];
    default:
      return EMPTY_ARRAY;
  }
};

const getRawData = (value, tableData, isTenantUniverse) => {
  let groupType;
  if (isTenantUniverse) {
    groupType = GROUP_TYPES.STATIC;
  } else {
    groupType = _get(value, FIELD_IDS.GROUP_TYPE, EMPTY_STRING);
  }

  const name = _get(value, FIELD_IDS.USER_GROUP_NAME, EMPTY_STRING);
  const displayName = _get(value, FIELD_IDS.USER_GROUP_NAME, EMPTY_STRING);
  const description = _get(value, FIELD_IDS.DESCRIPTION, EMPTY_STRING);
  const assetType = 'USER';

  if (groupType === GROUP_TYPES.DYNAMIC) {
    const data = {
      groupType,
      name,
      displayName,
      description,
      assetType: ASSET_TYPES.USER,
    };

    const condition = _get(value, FIELD_IDS.CONDITION, EMPTY_OBJECT);

    if (_isEmpty(condition)) {
      return data;
    }

    const criteriaList = _get(condition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST, EMPTY_ARRAY);
    const newCriteriaList = _filter(
      criteriaList,
      (criteria) =>
        !_isEmpty(criteria) &&
        !_isNil(_get(criteria, CONDITION_FIELD_IDS.FIELD, null)) &&
        !_isNil(_get(criteria, CONDITION_FIELD_IDS.OPERATOR, null)) &&
        !_isNil(_get(criteria, CONDITION_FIELD_IDS.VALUES, null)),
    );

    if (_isEmpty(newCriteriaList)) {
      return data;
    }

    data.condition = condition;
    return data;
  }

  const containedIds = _map(tableData, (item) => item.id);

  const data = {
    groupType,
    name,
    displayName,
    description,
    assetType,
    containedIds,
  };
  return data;
};

const getValues = (userDetails) => {
  const { displayName, groupType, description } = userDetails;
  const newValues = {};

  newValues[FIELD_IDS.USER_GROUP_NAME] = displayName;
  newValues[FIELD_IDS.GROUP_TYPE] = groupType;
  newValues[FIELD_IDS.DESCRIPTION] = description;
  newValues[FIELD_IDS.CONDITION] = {
    [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: [EMPTY_OBJECT],
    [CONDITION_BUILDER_FIELD_IDS.EXPRESSION]: '1',
  };

  if (groupType === GROUP_TYPES.DYNAMIC) {
    const condition = _get(userDetails, 'condition', EMPTY_OBJECT);
    newValues[FIELD_IDS.CONDITION] = condition;
  }

  return newValues;
};

export { getSections, getFields, getFieldOptions, getOptions, getResourceFieldDef, getOperators, getRawData, getValues };
