import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _noop from 'lodash/noop';

// Components
import Button from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/button';
import Popover, { POPOVER_PLACEMENT, POPOVER_TRIGGER } from '@tekion/tekion-components/molecules/popover';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Error from '@tekion/tekion-components/organisms/FormBuilder/components/error';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';

// Constants
import COLORS from '@tekion/tekion-styles-next/scss/variables.scss';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

// Utils
import { getUpdatedValue, getIsDeselectDisabled } from './daysOfWeekRenderer.utils';

// Styles
import styles from './daysOfWeekRenderer.module.scss';

const CROSS_ICON = 'icon-cross';

const DaysOfWeekRenderer = (props) => {
  const { id, value, disabled, fieldClassName, customDaysClassName, options, error, warning, inheritParentClasses, onAction } = props;
  const handleAction = useCallback(
    ({ payload = EMPTY_OBJECT }) => {
      const { id: buttonId } = payload;
      if (!buttonId) return null;

      const updatedValue = getUpdatedValue(value, buttonId, !_includes(value, buttonId));
      return onAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: {
          id,
          value: updatedValue,
        },
      });
    },
    [onAction, id, value],
  );

  const renderPopoverContent = useCallback(
    () => (
      <div className={styles.popoverContainer}>
        <div className={styles.iconContainer}>
          <FontIcon size={SIZES.S} color={COLORS.white}>
            {CROSS_ICON}
          </FontIcon>
        </div>
        <div className={styles.tipContainer}>{__('Can’t remove the day. Atleast one day has to be selected.')}</div>
      </div>
    ),
    [],
  );

  const renderButton = useCallback(
    ({ option, isDaySelected, isDeselectDisabled }) => (
      <Button
        key={option.value}
        id={option.value}
        view={isDaySelected ? Button.VIEW.PRIMARY : Button.VIEW.SECONDARY}
        shape="circle"
        label={option?.label}
        className={cx(styles.dayButton, {
          [styles.primaryButton]: isDaySelected,
          [styles.secondaryButton]: !isDaySelected,
          [styles.deselectDisabledCursor]: isDeselectDisabled,
        })}
        disabled={disabled}
        onAction={isDeselectDisabled ? _noop : handleAction}
      />
    ),
    [disabled, handleAction],
  );

  const renderOption = useCallback(
    (option) => {
      const isDaySelected = _includes(value, option?.value);
      const isDeselectDisabled = getIsDeselectDisabled(value, isDaySelected);
      if (isDeselectDisabled) {
        return (
          <Popover
            key={option.value}
            trigger={POPOVER_TRIGGER.HOVER}
            content={renderPopoverContent()}
            placement={POPOVER_PLACEMENT.TOP}
            arrowPointAtCenter
          >
            {renderButton({
              option,
              isDaySelected,
              isDeselectDisabled,
            })}
          </Popover>
        );
      }
      return renderButton({
        option,
        isDaySelected,
        isDeselectDisabled,
      });
    },
    [renderButton, renderPopoverContent, value],
  );

  return (
    <div className={inheritParentClasses ? fieldClassName : EMPTY_STRING}>
      <FieldLabel {...props} />
      <div className={`flex ${customDaysClassName}`}>{_map(options, renderOption)}</div>
      <Error key="error" error={error} />
      <Error key="warning" error={warning} />
    </div>
  );
};

DaysOfWeekRenderer.propTypes = {
  inheritParentClasses: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  warning: PropTypes.string,
  customDaysClassName: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  fieldClassName: PropTypes.string,
  onAction: PropTypes.func.isRequired,
};

DaysOfWeekRenderer.defaultProps = {
  inheritParentClasses: true,
  disabled: false,
  customDaysClassName: EMPTY_STRING,
  fieldClassName: EMPTY_STRING,
  error: EMPTY_STRING,
  warning: EMPTY_STRING,
  value: EMPTY_ARRAY,
  options: EMPTY_ARRAY,
};

export default DaysOfWeekRenderer;
