const COMMENT_ACTION_TYPES = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

const COMMENT_ACTIONS = [
  {
    label: __('Edit'),
    value: COMMENT_ACTION_TYPES.EDIT,
  },
  {
    label: __('Delete'),
    value: COMMENT_ACTION_TYPES.DELETE,
  },
];

const BUTTON_ACTION_TYPES = {
  SHOW_REPLIES: 'SHOW_REPLIES',
  HIDE_REPLIES: 'HIDE_REPLIES',
  REPLY: 'REPLY',
  CREATE_COMMENT: 'CREATE_COMMENT',
  CREATE_REPLY: 'CREATE_REPLY',
  CANCEL: 'CANCEL',
};

const BUTTON_LABELS = {
  [BUTTON_ACTION_TYPES.REPLY]: __('Reply'),
  [BUTTON_ACTION_TYPES.SHOW_REPLIES]: __('Show Replies'),
  [BUTTON_ACTION_TYPES.HIDE_REPLIES]: __('Hide Replies'),
  [BUTTON_ACTION_TYPES.CREATE_COMMENT]: __('Post'),
  [BUTTON_ACTION_TYPES.CREATE_REPLY]: __('Reply'),
  [BUTTON_ACTION_TYPES.CANCEL]: __('Cancel'),
};

const COMMENT_FIELDS = {
  ID: 'id',
  IN_REPLY_TO: 'inReplyTo',
  CREATED_BY: 'createdBy',
  CONTENT: 'content',
  CREATED_TIME: 'createdTime',
  MODIFIED_TIME: 'modifiedTime',
  ASSET_ID: 'assetId',
  ASSET_TYPE: 'assetType',
  DELETED: 'deleted',
  REPLIES: 'replies',
  SHOW_REPLIES_ENABLED: 'showRepliesEnabled',
  SHOW_REPLY_INPUT: 'showReplyInput',
  COMMENT_MODE: 'commentMode',
};

const COMMENT_MODES = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
};

const COMMENT_ROWS = 3;
const REPLY_ROWS = 3;

const REQUIRED_USER_DETAIL_FIELDS = ['id', 'name', 'entity.active', 'entity.email', 'entity.firstname', 'entity.lastname', 'entity.profilepicture'];

export {
  COMMENT_ACTION_TYPES,
  COMMENT_ACTIONS,
  BUTTON_ACTION_TYPES,
  BUTTON_LABELS,
  COMMENT_FIELDS,
  COMMENT_MODES,
  COMMENT_ROWS,
  REPLY_ROWS,
  REQUIRED_USER_DETAIL_FIELDS,
};
