import React from 'react';
import Header from '@tekion/tekion-components/atoms/Heading';
import PropTypes from 'prop-types';

import Button from '@tekion/tekion-components/atoms/Button';
import Icon from '@tekion/tekion-components/atoms/FontIcon/Icon';
import Dropdown from '@tekion/tekion-components/molecules/DropDown';

import styles from '../../leftPanel.module.scss';

const ErrorMessage = ({ addWidgetDropdownMenu }) => (
  <div>
    <Header className={styles.message}>{__('No Widgets Added Yet')}</Header>
    <Header className={styles.message} size={4}>
      {__('Click on Add widget to add new widget or use existing widget')}
    </Header>
    <div className={styles.createWidgetContainer}>
      <Dropdown overlay={addWidgetDropdownMenu}>
        <Button view="primary" className={styles.createWidgetButton}>
          <Icon size="MD" className>
            icon-add2
          </Icon>
          <span>New Widget</span>
        </Button>
      </Dropdown>
    </div>
  </div>
);

ErrorMessage.propTypes = {
  addWidgetDropdownMenu: PropTypes.node,
};

ErrorMessage.defaultProps = {
  addWidgetDropdownMenu: null,
};

export default ErrorMessage;
