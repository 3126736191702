const PAGE_IDS = {
  HOME_PAGE: 'homepage',
  APPLICATIONS: 'applications',
  CREATE_ENTITY_RECORD: 'create-entity-record',
  EDIT_ENTITY_RECORD: 'edit-entity-record',
  VIEW_ENTITY_RECORD: 'view-entity-record',
  ENTITY_RECORD_RIGHT_PANE: 'entity-record-right-pane',
  APPROVAL_CENTRE: 'approval-centre',
  MANAGEMENT: 'management',
  APPROVALS: 'approvals',
  APPROVAL_REQUESTS: 'requests',
  CREATE_REQUEST: 'create-request',
  EDIT_REQUEST: 'edit-request',
};

export default PAGE_IDS;
