import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';
import { tget } from '@tekion/tekion-base/utils/general';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

// Actions
import { fetchSettings } from '../../../../../../../actions/approvalManagement.actions';

// Helpers
import { getPayload } from './approvalSettingsList.helpers';

// Constants
import ROUTES from '../../../../../constants/routes';
import FIELD_IDS from '../../constants/approvalSettings.fieldIds';
import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import PAGE_IDS from '../../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/approvalSettingsList.actionTypes';

const fetchData = async ({ getState, setState }) => {
  setState({ isLoading: true });
  const {
    pageSize = DEFAULT_PAGE_SIZE,
    currentPage = DEFAULT_PAGE_INFO.start,
    currentPageToken = EMPTY_STRING,
    searchText = EMPTY_STRING,
  } = getState();

  const payload = getPayload({ currentPage, currentPageToken, pageSize, searchText });

  const response = await fetchSettings(payload);
  const data = tget(response, 'hits', EMPTY_ARRAY);
  const nextPageToken = _get(response, 'nextPageToken', EMPTY_STRING);
  setState({ data, isLoading: false, nextPageToken });
};

const handleSearch = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ searchText: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => fetchData({ getState, setState }));
};

const handlePageUpdate = ({ params, getState, setState }) => {
  const { page, resultsPerPage } = _get(params, 'value', EMPTY_OBJECT);
  const { currentPage, pageSize, nextPageToken, previousPageTokens = [], currentPageToken } = getState();
  let newPageNumber = page;
  let newTokenNumber = null;
  let newPrevPageTokens = [...previousPageTokens];

  if (resultsPerPage !== pageSize) {
    newPageNumber = 1;
    newPrevPageTokens = [];
  } else if (page > currentPage + 1) {
    newTokenNumber = nextPageToken;
    newPrevPageTokens.push(currentPageToken);
  } else if (page !== 1) {
    newTokenNumber = newPrevPageTokens.pop();
  } else {
    newPrevPageTokens = [];
  }

  setState(
    { currentPage: newPageNumber - 1, pageSize: resultsPerPage, currentPageToken: newTokenNumber, previousPageTokens: newPrevPageTokens },
    () => fetchData({ getState, setState }),
  );
};

const handleRowItemClick = ({ params, getState }) => {
  const { history } = getState();
  const approvalSetting = tget(params, 'value.original', {});
  const name = tget(approvalSetting, FIELD_IDS.NAME);

  if (!_isEmpty(name)) {
    const path = `${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_SETTING_DETAIL}/${name}`;
    history.push({ pathname: path, state: { approvalSetting } });
  }
};

const handleCreateApprovalSettingClick = ({ getState }) => {
  const { history } = getState();
  history.push(ROUTES.APPROVAL_SETTING_CREATE_ROUTE);
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleRowItemClick,
  [ACTION_TYPES.CREATE_APPROVAL_SETTING]: handleCreateApprovalSettingClick,
};

export default ACTION_HANDLERS;
