import React from 'react';

// components
import { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import IconAsBtn from '@tekion/tekion-components/atoms/iconAsBtn';

// styles
import COLORS from '@tekion/tekion-styles-next/scss/variables.scss';
import styles from './linkToolbarButton.module.scss';

// hooks
import useLinkButtonClick from './hooks/useLinkButtonClick';
import useEditor from '../../../hooks/useEditor';

// helpers
import { getSelectedLinkNode } from '../../../helpers/richTextEditor.link';
import { shouldEnableLink } from './helpers/linkToolbarButton.general';

function LinkToolbarButton() {
  const editor = useEditor();
  const selectedLinkNode = getSelectedLinkNode(editor);
  const isEnabled = shouldEnableLink(editor);
  const { handleClick } = useLinkButtonClick(selectedLinkNode);
  const iconColor = selectedLinkNode ? COLORS.dodgerBlue : undefined;

  return (
    <IconAsBtn size={SIZES.MD_S} onClick={handleClick} containerClassName={styles.linkButton} disabled={!isEnabled} color={iconColor}>
      icon-insert-link
    </IconAsBtn>
  );
}

export default LinkToolbarButton;
