import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _has from 'lodash/has';
import _uniq from 'lodash/uniq';

// Tekion-base
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';

import { getMetaDataByEntityName } from '../../../../../../../actions/reporting.actions';
import { fetchEntities } from '../../../../../../../actions/entityManagement.actions';
import { getMetrics } from './addWidget.general.helpers';
import DATA_TYPES from '../../../../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../../../../constants/fieldDefinition.fieldTypes';
import fieldDefinitionReader from '../../../../../../../readers/fieldDefinition.reader';
import entityReader from '../../../../../../../readers/entity.reader';

const MAX_RECURSIVE_DEPTH_FOR_FETCH_FIELD_DEFINITIONS = 2;

const fetchEntitiesMetaData = async (entitiesName) => {
  const payload = { filters: [{ field: 'name', filterType: OPERATORS.IN, values: entitiesName }] };
  const response = await fetchEntities(payload, true);
  const metaDataPromises = [];
  _map(entitiesName, (entity) => {
    metaDataPromises.push(getMetaDataByEntityName(entity));
  });
  const metaData = await Promise.all(metaDataPromises);
  const metaDataNew = {};
  _forEach(metaData, (value, i) => {
    metaDataNew[entitiesName[i]] = value;
  });

  return { response: tget(response, 'hits', EMPTY_ARRAY), metaData: metaDataNew };
};

const fetchingRecursiveEntityMetaData = async (entitiesName, isRelationshipRecursive, isComplexRecursive, result, metaDataResult, currentDepth) => {
  if (_isEmpty(entitiesName) || currentDepth > MAX_RECURSIVE_DEPTH_FOR_FETCH_FIELD_DEFINITIONS) {
    return {};
  }
  const { response = EMPTY_ARRAY, metaData = EMPTY_ARRAY } = await fetchEntitiesMetaData(entitiesName);

  const newEntitiesNameToFetch = [];
  const mapOfEntityNameToFieldDefinitions = _reduce(
    response,
    (ans, data) => {
      const name = entityReader.name(data);
      const fieldDefinitionsByName = _keyBy(entityReader.fieldDefinitions(data), 'name');
      _set(ans, name, fieldDefinitionsByName);
      _forEach(fieldDefinitionsByName, (fieldDef) => {
        const dataType = fieldDefinitionReader.dataType(fieldDef);
        const fieldType = fieldDefinitionReader.fieldType(fieldDef);
        if (dataType === DATA_TYPES.COMPLEX && isComplexRecursive) {
          const complexEntityName = _get(fieldDef, 'complexFieldDefinition.entityName');
          newEntitiesNameToFetch.push(complexEntityName);
        } else if (fieldType === FIELD_TYPES.RELATIONSHIP && isRelationshipRecursive) {
          const relationshipEntityName = _get(fieldDef, 'lookupField.entityType');
          newEntitiesNameToFetch.push(relationshipEntityName);
        }
      });
      return ans;
    },
    result,
  );

  const mapOfEntityMetaData = _reduce(
    metaData,
    (ans, value, key) => {
      _set(ans, key, value);
      return ans;
    },
    metaDataResult,
  );

  const nonExistingEntitiesNameToFetch = _uniq(_filter(newEntitiesNameToFetch, (entityName) => !_has(mapOfEntityNameToFieldDefinitions, entityName)));

  await fetchingRecursiveEntityMetaData(
    nonExistingEntitiesNameToFetch,
    isRelationshipRecursive,
    isComplexRecursive,
    mapOfEntityNameToFieldDefinitions,
    mapOfEntityMetaData,
    currentDepth + 1,
  );

  return {};
};

const getMetaDataOptions = (
  entityName,
  fieldDefinitions,
  mapOfEntityNameToFieldDefinitions,
  mapOfEntityMetaData,
  metaDataOptions,
  prefixValue,
  depth,
) => {
  if (_isEmpty(entityName) || depth > MAX_RECURSIVE_DEPTH_FOR_FETCH_FIELD_DEFINITIONS) {
    return {};
  }
  const options = getMetrics(mapOfEntityMetaData[entityName], prefixValue);
  const mapOfMetaDataOptions = _reduce(
    options,
    (ans, data) => {
      ans.push(data);
      return ans;
    },
    metaDataOptions,
  );
  _forEach(fieldDefinitions, (fieldDef) => {
    const dataType = fieldDefinitionReader.dataType(fieldDef);
    const fieldName = fieldDefinitionReader.name(fieldDef);
    const newPrefixValue = _isEmpty(prefixValue) ? fieldName : `${prefixValue}.${fieldName}`;
    if (dataType === DATA_TYPES.COMPLEX) {
      const complexEntityName = _get(fieldDef, 'complexFieldDefinition.entityName');
      getMetaDataOptions(
        complexEntityName,
        mapOfEntityNameToFieldDefinitions[complexEntityName],
        mapOfEntityNameToFieldDefinitions,
        mapOfEntityMetaData,
        mapOfMetaDataOptions,
        newPrefixValue,
        depth + 1,
      );
    }
  });
  return {};
};

const fetchMetaDataForWidget = async (
  entityName,
  alreadyPresentFieldDefinitions = {},
  isRelationshipRecursive = false,
  isComplexRecursive = true,
) => {
  const data = { ...alreadyPresentFieldDefinitions };
  const metaData = {};
  await fetchingRecursiveEntityMetaData(entityName, isRelationshipRecursive, isComplexRecursive, data, metaData, 0);
  const metaDataOptions = [];
  getMetaDataOptions(entityName, data[getArraySafeValue(entityName)], data, metaData, metaDataOptions, '', 1);
  return metaDataOptions;
};

export { fetchMetaDataForWidget };
