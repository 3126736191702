exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".emailPreviewRenderer_emailNotPresent__sYse7dkwtq{font-size:1.8rem}[dir] .emailPreviewRenderer_emailNotPresent__sYse7dkwtq{text-align:center}.emailPreviewRenderer_container__e6dKM9rCF1{width:100%;height:100%;overflow-y:auto}[dir] .emailPreviewRenderer_container__e6dKM9rCF1{background-color:#fff;padding:.8rem}.emailPreviewRenderer_flexRow__q7e9R62p4M{display:flex}.emailPreviewRenderer_subContainer__aTqLERZbLx{align-items:center}.emailPreviewRenderer_richTextEditorWrapper__xgusRoCCDy{min-height:30rem;max-height:50rem;width:90%}", ""]);

// Exports
exports.locals = {
	"emailNotPresent": "emailPreviewRenderer_emailNotPresent__sYse7dkwtq",
	"container": "emailPreviewRenderer_container__e6dKM9rCF1",
	"flexRow": "emailPreviewRenderer_flexRow__q7e9R62p4M",
	"subContainer": "emailPreviewRenderer_subContainer__aTqLERZbLx",
	"richTextEditorWrapper": "emailPreviewRenderer_richTextEditorWrapper__xgusRoCCDy"
};