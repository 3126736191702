const LIST_VIEW_REDUCER_ACTION_TYPES = {
  SET_LIST_VIEW_FILTERS_CONTEXT_IN_REDUX: 'SET_LIST_VIEW_FILTERS_CONTEXT_IN_REDUX',
};

const APPLICATION_VARIABLES_REDUCER_ACTION_TYPES = {
  SET_VARIABLE: 'SET_VARIABLE',
  CLEAR_VARIABLES: 'CLEAR_VARIABLES',
};

export { LIST_VIEW_REDUCER_ACTION_TYPES, APPLICATION_VARIABLES_REDUCER_ACTION_TYPES };
