import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';

import EventHandler from '../../../../../../molecules/eventHandler';
import MonacoEditor, { CODE_LANGUAGES } from '../../../../../../molecules/monacoEditor/MonacoEditor';

import { eventHandlerValidator } from '../../../../../../molecules/eventHandler/eventHandler.helpers';

import { FIELD_IDS, EVENT_NAME_OPTIONS } from './customRowActionsForm.constants';

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Display Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const EVENT_HANDLER_FIELD = {
  id: FIELD_IDS.EVENT_HANDLERS,
  renderer: EventHandler,
  renderOptions: {
    eventTypeOptions: EVENT_NAME_OPTIONS,
    validators: [eventHandlerValidator],
  },
};

const ENABLE_ACTION_SCRIPT_FIELD = {
  id: FIELD_IDS.ENABLE_ACTION_SCRIPT,
  renderer: MonacoEditor,
  renderOptions: {
    label: __('Enable Action Script'),
    required: true,
    language: CODE_LANGUAGES.JAVASCRIPT,
    height: '20rem',
    validators: [isRequiredRule],
  },
};

const FORM_FIELDS = {
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.EVENT_HANDLERS]: EVENT_HANDLER_FIELD,
  [FIELD_IDS.ENABLE_ACTION_SCRIPT]: ENABLE_ACTION_SCRIPT_FIELD,
};

const getFields = (entity) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.EVENT_HANDLERS]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.EVENT_HANDLERS], [{ path: 'entityDef', value: entity }]),
});

export { getFields };
