import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _map from 'lodash/map';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { ICONS } from '../../constants/viewBuilder.constants';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './imagePropertiesForm.module.scss';

const IconGridRenderer = (props) => {
  const { fieldClassName, value, onAction } = props;

  const handleIconClick = useCallback(
    (key) => {
      onAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: { id: ALL_VIEW_PROPERTY_KEYS.IMAGE_PATH, value: key },
      });
    },
    [onAction],
  );

  return (
    <div className={cx(fieldClassName, styles.grid)}>
      {_map(ICONS, (icon) => {
        const iconStyle = value === icon ? styles.disabled : styles.active;

        return (
          <div key={icon} className={cx(styles.icon, iconStyle)} onClick={() => handleIconClick(icon)}>
            <FontIcon size={SIZES.L}>{icon}</FontIcon>
          </div>
        );
      })}
    </div>
  );
};

IconGridRenderer.propTypes = {
  fieldClassName: PropTypes.string,
  value: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

IconGridRenderer.defaultProps = {
  fieldClassName: EMPTY_STRING,
  value: EMPTY_ARRAY,
};

export default React.memo(IconGridRenderer);
