import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _split from 'lodash/split';
import _slice from 'lodash/slice';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';

import { EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import Tag from '@tekion/tekion-components/atoms/Tag';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import PopOver, { POPOVER_TRIGGER, POPOVER_PLACEMENT } from '@tekion/tekion-components/molecules/popover';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import styles from './tagsRenderer.module.scss';

const TagsRenderer = ({
  label,
  maxLimit = 2,
  fieldValue,
  tagsRendererClassName,
  tagClassName,
  tagContentClassName,
  style,
  column = EMPTY_OBJECT,
  original = EMPTY_OBJECT,
  onTagClick,
}) => {
  const accessor = _get(column, 'accessor', _noop);
  let values = _split(fieldValue, ',');
  if (_isEmpty(fieldValue)) {
    values = _split(accessor(original), ',');
  }

  const showContent = _size(values) > 1 || getArraySafeValue(values) !== NO_DATA;
  const showPopoverContent = _size(values) > maxLimit;
  const visibleCellValues = _slice(values, 0, maxLimit);
  const popoverValues = _slice(values, maxLimit);

  const renderPopOverContent = useCallback(
    () => (
      <div className={styles.popover}>
        {_map(popoverValues, (value, index) => (
          <Tag
            key={`${index}-${value}`}
            className={cx(styles.tagPopover, tagClassName)}
            contentClassName={styles.tagContent}
            style={style}
            onClick={(event) => onTagClick(event, index + maxLimit)}
          >
            {__(`${value}`)}
          </Tag>
        ))}
      </div>
    ),
    [popoverValues, tagClassName, style, maxLimit, onTagClick],
  );

  const renderPopoverValues = useCallback(
    (popOverValues) => (
      <PopOver placement={POPOVER_PLACEMENT.TOP_RIGHT} trigger={POPOVER_TRIGGER.HOVER} content={renderPopOverContent()}>
        <Tag key={popOverValues} className={cx(styles.tag, tagClassName)}>
          {__(`+${_size(popOverValues)} more`)}
        </Tag>
      </PopOver>
    ),
    [renderPopOverContent, tagClassName],
  );

  const renderFullTagName = useCallback((value) => <div className={styles.tagFullName}>{__('{{value}}', { value })}</div>, []);

  return (
    <div>
      {_isEmpty(label) && <FieldLabel label={label} />}
      <div className={cx(styles.flexRow, tagsRendererClassName)}>
        {showContent
          ? _map(visibleCellValues, (value, index) => (
              <PopOver placement={POPOVER_PLACEMENT.TOP_RIGHT} trigger={POPOVER_TRIGGER.HOVER} content={renderFullTagName(value)}>
                <Tag
                  key={value}
                  className={cx(styles.tag, tagClassName)}
                  contentClassName={cx(styles.tagContent, tagContentClassName)}
                  style={style}
                  onClick={(event) => onTagClick(event, index)}
                >
                  {__('{{value}}', { value })}
                </Tag>
              </PopOver>
            ))
          : NO_DATA}
        {showPopoverContent && renderPopoverValues(popoverValues)}
      </div>
    </div>
  );
};

TagsRenderer.propTypes = {
  label: PropTypes.string,
  maxLimit: PropTypes.number,
  fieldValue: PropTypes.string,
  tagsRendererClassName: PropTypes.string,
  tagClassName: PropTypes.string,
  tagContentClassName: PropTypes.string,
  style: PropTypes.object,
  column: PropTypes.object,
  original: PropTypes.object,
  onTagClick: PropTypes.func,
};

TagsRenderer.defaultProps = {
  label: EMPTY_STRING,
  fieldValue: EMPTY_STRING,
  tagsRendererClassName: '',
  tagClassName: '',
  tagContentClassName: '',
  maxLimit: 2,
  style: EMPTY_OBJECT,
  column: EMPTY_OBJECT,
  original: EMPTY_OBJECT,
  onTagClick: _noop,
};

export default makeCellRenderer(TagsRenderer);
