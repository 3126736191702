import { useCallback, useState } from 'react';
import _max from 'lodash/max';
import { ReactEditor } from 'slate-react';
import { findNode, setNodes } from '@udecode/plate-common';
import { ELEMENT_TABLE } from '@udecode/plate-table';

// constants
import { COLUMN_MIN_WIDTH } from '../../../../hooks/useColumnWidths';

const setTableColumnSize = ({ setIsResizing, editor, columnId, width, element }) => {
  setIsResizing(false);
  const table = findNode(editor, {
    at: ReactEditor.findPath(editor, element),
    match: { type: ELEMENT_TABLE },
  });
  if (!table) return;

  const [tableNode, tablePath] = table;

  const { columnSizesById } = tableNode;
  const updatedColumnSizesBId = {
    ...columnSizesById,
    [columnId]: width,
  };

  setNodes(editor, { columnSizesById: updatedColumnSizesBId }, { at: tablePath });
};

function useTableCellResize({ setResizingColumn, columnId, element, editor }) {
  const [isResizing, setIsResizing] = useState(false);

  const handleResize = useCallback(
    (e, direction, ref) => {
      setResizingColumn({
        id: columnId,
        width: _max([ref.offsetWidth, COLUMN_MIN_WIDTH]),
      });
    },
    [setResizingColumn, columnId],
  );

  const handleResizeStop = useCallback(
    (e, direction, ref) => {
      setTableColumnSize({
        setIsResizing,
        editor,
        columnId,
        width: _max([ref.offsetWidth, COLUMN_MIN_WIDTH]),
        element,
      });
    },
    [element, editor, columnId],
  );

  const handleResizeStart = useCallback(() => {
    setIsResizing(true);
  }, []);

  return {
    isResizing,
    handleResize,
    handleResizeStop,
    handleResizeStart,
  };
}

export default useTableCellResize;
