import { useMemo } from 'react';

// styles
import COLORS from '@tekion/tekion-styles-next/scss/variables.scss';

const getResizerWrapperStyles = (isResizing) => ({
  visibility: isResizing ? 'visible' : 'hidden',
});

const getResizeHandleStyle = (isHovered) => ({
  backgroundColor: isHovered ? COLORS.dodgerBlue : undefined,
});

function useTableCellResizerStyles(isResizing, hoveredColumnId, columnId) {
  const isHovered = hoveredColumnId ? hoveredColumnId === columnId : false;

  const resizerWrapperStyles = useMemo(() => getResizerWrapperStyles(isResizing), [isResizing]);

  const rightResizeHandleStyle = useMemo(() => getResizeHandleStyle(isHovered), [isHovered]);

  return {
    resizerWrapperStyles,
    rightResizeHandleStyle,
  };
}

export default useTableCellResizerStyles;
