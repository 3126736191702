import { COLOR_MAP } from '@tekion/tekion-base/constants/statusColors';

const TABLE_ACTION_TYPES = {
  ON_CHANGE: 'ON_CHANGE',
};

const COLUMN_IDS = {
  DISPLAY_NAME: 'displayName',
  FIELD_NAME: 'fieldName',
  STATUS: 'status',
};

const STATUS_TYPES = {
  MAPPED: 'MAPPED',
  UNMAPPED: 'UNMAPPED',
};

const STATUS_COLOR_MAP = {
  [STATUS_TYPES.MAPPED]: COLOR_MAP.GREEN,
  [STATUS_TYPES.UNMAPPED]: COLOR_MAP.RED,
};

const STATUS_LABEL_MAP = {
  [STATUS_TYPES.MAPPED]: __('Mapped'),
  [STATUS_TYPES.UNMAPPED]: __('Unmapped'),
};

export { TABLE_ACTION_TYPES, COLUMN_IDS, STATUS_TYPES, STATUS_COLOR_MAP, STATUS_LABEL_MAP };
