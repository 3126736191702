import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/cms';
const PROJECT_BASE_URL = 'core/u/constructs';

const getProjectByName = (name) => ApiService.get(`${PROJECT_BASE_URL}/projects/name/${name}`);

const fetchWorkspaceProjectList = (payload = EMPTY_OBJECT) => ApiService.post(`${PROJECT_BASE_URL}/projects/search`, payload);

const addApplicationsToProject = (name, payload = EMPTY_ARRAY) => ApiService.post(`${BASE_URL}/projects/name/${name}/applications-mappings`, payload);

const fetchApplicationsForProject = (name) => ApiService.get(`${BASE_URL}/projects/name/${name}/applications-mappings`);

const removeApplicationFromProject = (name, applicationName) =>
  ApiService.delete(`${BASE_URL}/projects/name/${name}/applications-mappings/${applicationName}/remove`);

const saveProjectData = (payload = EMPTY_OBJECT) => ApiService.post(`${PROJECT_BASE_URL}/projects`, payload);

const editProjectData = (name, payload = EMPTY_OBJECT) => ApiService.put(`${PROJECT_BASE_URL}/projects/name/${name}`, payload);

export default {
  fetchWorkspaceProjectList,
  saveProjectData,
  editProjectData,
  getProjectByName,
  fetchApplicationsForProject,
  addApplicationsToProject,
  removeApplicationFromProject,
};
