import _map from 'lodash/map';

import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './listViewRendererPropertiesForm.module.scss';

const FIELD_IDS = {
  TITLE: ALL_VIEW_PROPERTY_KEYS.TITLE,
  WIDTH: ALL_VIEW_PROPERTY_KEYS.WIDTH,
  TABLE_SHOW_HEADER: ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_HEADER,
  TABLE_SHOW_SUB_HEADER: ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_SUB_HEADER,
  TABLE_SHOW_REFRESH_ICON: ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_REFRESH_ICON,
  TABLE_SHOW_MULTI_SORT: ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_MULTI_SORT,
  TABLE_SHOW_FILTER: ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_FILTER,
  TABLE_SHOW_SEARCH_BY_FIELD: ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_SEARCH_BY_FIELD,
  EXPANDABLE_ROW_CONFIG_FORM: ALL_VIEW_PROPERTY_KEYS.EXPANDABLE_ROW_CONFIG_FORM,
  TABLE_MIN_ROWS: ALL_VIEW_PROPERTY_KEYS.TABLE_MIN_ROWS,
  TABLE_ROW_HEIGHT: ALL_VIEW_PROPERTY_KEYS.TABLE_ROW_HEIGHT,
  TABLE_CURRENT_PAGE: ALL_VIEW_PROPERTY_KEYS.TABLE_CURRENT_PAGE,
  TABLE_RESULT_PER_PAGE: ALL_VIEW_PROPERTY_KEYS.TABLE_RESULT_PER_PAGE,
  TABLE_PAGE_SIZE: ALL_VIEW_PROPERTY_KEYS.TABLE_PAGE_SIZE,
  TABLE_FIXED_PAGINATION: ALL_VIEW_PROPERTY_KEYS.TABLE_FIXED_PAGINATION,
  TABLE_SHOW_PAGINATION: ALL_VIEW_PROPERTY_KEYS.TABLE_SHOW_PAGINATION,
  TABLE_PAGE_SIZE_OPTIONS: ALL_VIEW_PROPERTY_KEYS.TABLE_PAGE_SIZE_OPTIONS,
  DOWNLOAD_OPTIONS: ALL_VIEW_PROPERTY_KEYS.DOWNLOAD_OPTIONS,
  FILTER_COMPONENT: ALL_VIEW_PROPERTY_KEYS.FILTER_COMPONENT,
  SORT_DETAILS: ALL_VIEW_PROPERTY_KEYS.SORT_DETAILS,
  ACTIONS_TO_SHOW: ALL_VIEW_PROPERTY_KEYS.ACTIONS_TO_SHOW,
  SEARCH_OPTIONS: ALL_VIEW_PROPERTY_KEYS.SEARCH_OPTIONS,
  EVENT_HANDLERS: ALL_VIEW_PROPERTY_KEYS.EVENT_HANDLERS,
  CUSTOM_STYLES: ALL_VIEW_PROPERTY_KEYS.CUSTOM_STYLES,
};

const SECTIONS = [
  {
    rows: [
      {
        columns: [FIELD_IDS.TITLE, FIELD_IDS.WIDTH],
      },
    ],
  },
  {
    header: {
      size: 5,
      label: __('Table Manager Properties'),
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.TABLE_SHOW_HEADER, FIELD_IDS.TABLE_SHOW_SUB_HEADER],
      },
      {
        columns: [FIELD_IDS.TABLE_SHOW_REFRESH_ICON, FIELD_IDS.TABLE_SHOW_MULTI_SORT],
      },
      {
        columns: [FIELD_IDS.TABLE_SHOW_FILTER, FIELD_IDS.TABLE_SHOW_SEARCH_BY_FIELD],
      },
      {
        columns: [FIELD_IDS.EXPANDABLE_ROW_CONFIG_FORM],
      },
    ],
  },
  {
    header: {
      size: 5,
      label: __('Table Properties'),
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.TABLE_MIN_ROWS, FIELD_IDS.TABLE_ROW_HEIGHT],
      },
      {
        columns: [FIELD_IDS.TABLE_CURRENT_PAGE, FIELD_IDS.TABLE_RESULT_PER_PAGE],
      },
      {
        columns: [FIELD_IDS.TABLE_PAGE_SIZE],
      },
      {
        columns: [FIELD_IDS.TABLE_FIXED_PAGINATION, FIELD_IDS.TABLE_SHOW_PAGINATION],
      },
      {
        columns: [FIELD_IDS.TABLE_PAGE_SIZE_OPTIONS],
      },
      {
        columns: [FIELD_IDS.DOWNLOAD_OPTIONS],
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.FILTER_COMPONENT],
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.SORT_DETAILS],
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.EVENT_HANDLERS],
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.CUSTOM_STYLES],
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.ACTIONS_TO_SHOW],
      },
    ],
  },
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.SEARCH_OPTIONS],
      },
    ],
  },
];

const TABLE_PAGE_SIZE_VALUES = [10, 20, 50, 100, 200];

const TABLE_PAGE_SIZE_OPTIONS = _map(TABLE_PAGE_SIZE_VALUES, (value) => ({ label: __(value), value }));

const DOWNLOAD_OPTIONS = [
  { label: __('PDF'), value: 'PDF' },
  { label: __('EXCEL'), value: 'EXCEL' },
  { label: __('XML'), value: 'XML' },
  { label: __('JSON'), value: 'JSON' },
  { label: __('CSV'), value: 'CSV' },
];

const CUSTOM_ON_CHANGE_FIELD_IDS = [
  FIELD_IDS.DOWNLOAD_OPTIONS,
  FIELD_IDS.EXPANDABLE_ROW_CONFIG_FORM,
  FIELD_IDS.FILTER_COMPONENT,
  FIELD_IDS.SEARCH_OPTIONS,
  FIELD_IDS.SORT_DETAILS,
];

export { FIELD_IDS, SECTIONS, TABLE_PAGE_SIZE_OPTIONS, DOWNLOAD_OPTIONS, CUSTOM_ON_CHANGE_FIELD_IDS };
