import { VIEW_TYPES } from '../../../../../../organisms/viewBuilder/constants/viewBuilder.constants';

const CONTEXT_ID = 'VIEW_FORM';

const ACTION_TYPES = {
  INITIALIZE: 'INITIALIZE',
  ROW_SELECT: 'ROW_TOGGLE',
  ON_TABLE_CHANGE: 'ON_TABLE_CHANGE',
  ON_TABLE_DISPLAY_NAME_CLICK: 'ON_TABLE_DISPLAY_NAME_CLICK',
  ON_REDIRECTION: 'ON_REDIRECTION',
};

const FIELD_IDS = {
  VIEW_DISPLAY_NAME: 'displayName',
  VIEW_NAME: 'name',
  VIEW_DESCRIPTION: 'description',
  VIEW_TYPE: 'viewType',
  COLUMN_TABLE: 'columns',
  FILTERS: 'FILTERS',
  APPLICABLE_RECORD_TYPES: 'applicableRecordTypes',
  COLUMN_FORM_LAYOUT_FIELD: 'COLUMN_FORM_LAYOUT_FIELD',
  COLUMN_FORM_FIELDS: 'COLUMN_FORM_FIELDS',
  LIST_VIEW_ROW_ACTION_CLICK: 'LIST_VIEW_ROW_ACTION_CLICK',
};

const VIEW_TYPE_OPTIONS = [
  {
    label: __('Detail View'),
    value: VIEW_TYPES.DETAIL_VIEW,
  },
  {
    label: __('List View'),
    value: VIEW_TYPES.LIST_VIEW,
  },
  {
    label: __('Form View'),
    value: VIEW_TYPES.FORM_VIEW,
  },
  {
    label: __('Cell View'),
    value: VIEW_TYPES.CELL_VIEW,
  },
  {
    label: __('Grid View'),
    value: VIEW_TYPES.GRID_VIEW,
  },
  {
    label: __('Record Selection View'),
    value: VIEW_TYPES.RECORD_TYPE_SELECTION_VIEW,
  },
];

export { FIELD_IDS, CONTEXT_ID, ACTION_TYPES, VIEW_TYPE_OPTIONS };
