import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';
import Spinner from '@tekion/tekion-components/molecules/SpinnerComponent';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Label from '@tekion/tekion-components/atoms/Label';
import withSize from '../../../../../connectors/withSize';

import ConfigTable from './components/configTable';

// Constants
import { FORM_MODES } from '../../../../../constants/general.constants';
import ACTION_TYPES from './constants/tenantUserForm.actionTypes';
import { HEADER_PROPS, DEFAULT_USER_FORM_PROPS, USER_FORM_CONTEXT_ID } from './constants/tenantUserForm.general.constants';

// Helpers
import { getUserFormFields } from './formHelpers/tenantUserForm.fields';
import { getUserManagementSections } from './formHelpers/tenantUserForm.sections';
import ACTION_HANDLERS from './formHelpers/tenantUserForm.actionHandlers';

import styles from './tenantUserForm.module.scss';

const TenantUserForm = ({
  isDataLoading,
  isSaveLoading,
  isConfirmModalVisible,
  contentHeight,
  homeWorkspaceIndex,
  formMode,
  values,
  errors,
  selectedWorkspaces,
  workspaceList,
  userWorkspaceList,
  onAction,
}) => {
  const sections = useMemo(() => getUserManagementSections(formMode), [formMode]);
  const fields = useMemo(() => getUserFormFields(formMode), [formMode]);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_REDIRECTION });
  }, [onAction]);

  const goBackHandler = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_REDIRECTION,
    });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(USER_FORM_CONTEXT_ID);
  }, []);

  const formHeader = useMemo(() => {
    if (formMode === FORM_MODES.CREATE) return __('Create New User');
    return __('Edit User');
  }, [formMode]);

  const renderUserDetails = useCallback(
    () => (
      <div className={styles.userDetailModalContainer}>
        <Label highlight>
          {__(
            // eslint-disable-next-line max-len
            'Credentials has been sent to user email. Please check email and login with the credentials.',
          )}
        </Label>
      </div>
    ),
    [],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INITIALIZE_USER_FORM_VALUES });
  }, [onAction]);

  return (
    <Page>
      <Page.Header {...HEADER_PROPS} goBackHandler={goBackHandler}>
        <Heading className="full-width">{formHeader}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight, overflowY: 'scroll' }}>
        {isDataLoading && (
          <div className={styles.spinnerWrap}>
            <Spinner size="medium" />
          </div>
        )}
        {!isDataLoading && (
          <>
            <FormWithSubmission
              contextId={USER_FORM_CONTEXT_ID}
              fields={fields}
              sections={sections}
              values={values}
              errors={errors}
              onAction={onAction}
            />
            <ConfigTable
              workspaceList={workspaceList}
              userWorkspaceList={userWorkspaceList}
              selectedWorkspaces={selectedWorkspaces}
              homeWorkspaceIndex={homeWorkspaceIndex}
              onAction={onAction}
            />
            <Modal
              visible={isConfirmModalVisible}
              destroyOnClose
              hideSubmit
              width={Modal.SIZES.SM}
              title={__('User Credentials')}
              secondaryBtnText={__('Close')}
              onCancel={goBackHandler}
            >
              {renderUserDetails()}
            </Modal>
          </>
        )}
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          primaryButtonLabel={__('Save')}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancel}
          primaryActionLoading={isSaveLoading}
        />
      </Page.Footer>
    </Page>
  );
};

TenantUserForm.propTypes = {
  isDataLoading: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  isConfirmModalVisible: PropTypes.bool,
  contentHeight: PropTypes.number,
  homeWorkspaceIndex: PropTypes.number,
  formMode: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  workspaceList: PropTypes.array,
  userWorkspaceList: PropTypes.array,
  selectedWorkspaces: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};
TenantUserForm.defaultProps = {
  isDataLoading: false,
  isSaveLoading: false,
  isConfirmModalVisible: false,
  contentHeight: 0,
  formMode: EMPTY_STRING,
  homeWorkspaceIndex: 0,
  values: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  workspaceList: EMPTY_ARRAY,
  userWorkspaceList: EMPTY_ARRAY,
  selectedWorkspaces: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageFooter: 1, hasPageHeader: 1 }), withActions(DEFAULT_USER_FORM_PROPS, ACTION_HANDLERS))(TenantUserForm);
