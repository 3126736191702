import _find from 'lodash/find';

import { uuid } from '@tekion/tekion-components/utils';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { getApplicationConfigWithUpdatedComponent } from '../../../helpers/applicationBuilder.helpers';
import { COMPONENTS_TYPES } from '../../../../../../../../../constants/applicationRenderer.constants';
import { COMPONENT_KEYS } from '../../../constants/applicationBuilder.constants';

const addNewTabToApplicationConfig = (applicationConfig) => {
  const tabId = uuid();
  let component = _find(
    tget(applicationConfig, 'components', EMPTY_ARRAY),
    (componentItem) => tget(componentItem, COMPONENT_KEYS.TYPE) === COMPONENTS_TYPES.NAVIGATION,
  );
  let children = tget(component, COMPONENT_KEYS.CHILDREN, EMPTY_ARRAY);
  children = [
    ...children,
    {
      componentId: tabId,
      type: COMPONENTS_TYPES.TAB,
      children: null,
      properties: [],
    },
  ];
  component = { ...component, children };

  return getApplicationConfigWithUpdatedComponent(applicationConfig, COMPONENTS_TYPES.NAVIGATION, component);
};

const getUpdatedChildrenOnDrag = (children, result) => {
  const newItems = [...children];
  const [removed] = newItems.splice(result.source.index - 1, 1);
  newItems.splice(result.destination.index - 1, 0, removed);
  return newItems;
};

export { addNewTabToApplicationConfig, getUpdatedChildrenOnDrag };
