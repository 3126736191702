import React, { useCallback, useEffect } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Dropdown from '@tekion/tekion-components/molecules/DropDown';
import Menu from '@tekion/tekion-components/molecules/Menu';
import Button from '@tekion/tekion-components/atoms/Button';
import LoadingSpinner from '@tekion/tekion-components/molecules/loadingSpinner';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import { SIZES } from '@tekion/tekion-components/atoms/FontIcon/Icon.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Tooltip from '@tekion/tekion-components/atoms/tooltip';
import Loader from '@tekion/tekion-components/molecules/loader';

import withSize from '../../../../connectors/withSize';
import withActionExecutionModal from '../../../../connectors/withActionExecutionModal';

import ViewViewer from '../../../../organisms/viewBuilder/organisms/viewViewer/ViewViewer';
import ACTION_HANDLERS from './helpers/entityRecordDetailPage.actionHandlers';
import ACTION_TYPES from './constants/entityRecordDetailPage.constants';

import styles from './entityRecordDetailPage.module.scss';

const EntityRecordDetailPage = ({ isActionLoading, isLoading, contentHeight, entity, entityRecord, detailViewConfig, actionOptions, onAction }) => {
  const handleMenuClick = useCallback(
    (payload) => {
      onAction({ type: ACTION_TYPES.ACTION_CLICK, payload });
    },
    [onAction],
  );

  const renderActionMenu = useCallback(
    () =>
      isActionLoading ? (
        <LoadingSpinner size={20} />
      ) : (
        <Menu onClick={handleMenuClick}>
          {!_isEmpty(actionOptions) ? (
            _map(actionOptions, ({ id, name, disabled = false, errorMessage }) => (
              <Menu.Item key={id} disabled={disabled} className={styles.actionMenuItem}>
                <div>{name}</div>
                {disabled ? (
                  <Tooltip className={styles.actionMenuTooltip} placement="topRight" title={errorMessage}>
                    <FontIcon>icon-information-filled</FontIcon>
                  </Tooltip>
                ) : null}
              </Menu.Item>
            ))
          ) : (
            <div className={styles.noDataContainer}>{__('No Actions Added')}</div>
          )}
        </Menu>
      ),
    [actionOptions, handleMenuClick, isActionLoading],
  );

  const handleGoBack = useCallback(() => {
    onAction({ type: ACTION_TYPES.GO_BACK });
  }, [onAction]);

  const handleFetchActions = useCallback(() => {
    onAction({ type: ACTION_TYPES.FETCH_ACTIONS });
  }, [onAction]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT_FORM });
  }, [onAction]);

  if (isLoading) return <Loader />;

  return (
    <Page>
      <Page.Header hasBack goBackHandler={handleGoBack}>
        <div className={styles.header}>
          <Heading>{__('Details View')}</Heading>
          <Dropdown overlay={renderActionMenu} trigger={['click']}>
            <Button view={Button.VIEW.SECONDARY} onClick={handleFetchActions}>
              {__('Actions')}
              <FontIcon className="p-l-8 inline" size={SIZES.S}>
                icon-chevron-down
              </FontIcon>
            </Button>
          </Dropdown>
        </div>
      </Page.Header>
      <Page.Body className={styles.body} style={{ height: contentHeight }}>
        <div className={styles.viewViewer}>
          <ViewViewer isPreviewMode viewConfiguration={detailViewConfig} entity={entity} entityRecord={entityRecord} />
        </div>
      </Page.Body>
    </Page>
  );
};

EntityRecordDetailPage.propTypes = {
  isActionLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  entity: PropTypes.object,
  entityRecord: PropTypes.object,
  detailViewConfig: PropTypes.object,
  actionOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

EntityRecordDetailPage.defaultProps = {
  isActionLoading: true,
  isLoading: true,
  entity: undefined,
  entityRecord: undefined,
  detailViewConfig: undefined,
  actionOptions: EMPTY_ARRAY,
};

export default compose(
  withActionExecutionModal,
  withSize({ hasPageHeader: 1, hasPageFooter: 1 }),
  withActions(EMPTY_OBJECT, ACTION_HANDLERS),
)(EntityRecordDetailPage);
