import { DEFAULTS_TD } from '@udecode/plate-table';

const { getNodeProps } = DEFAULTS_TD;

const makeGetTdCellNodeProps =
  (readOnly) =>
  ({ element }) => ({
    ...getNodeProps?.({ element }),
    enableTableResizing: !readOnly,
  });

const createTdCellNodeOptions = (readOnly) => ({
  ...DEFAULTS_TD,
  getNodeProps: makeGetTdCellNodeProps(readOnly),
});

export default createTdCellNodeOptions;
