exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".configTable_userWorkspaceContainer__fFBGcSuRzu{width:100%}[dir] .configTable_userWorkspaceContainer__fFBGcSuRzu{padding:0 2.4rem;margin-bottom:2.4rem}.configTable_userWorkspaceContainer__fFBGcSuRzu .configTable_addWorkspaceContainer__fskKhSkhio{display:flex;justify-content:space-between;align-items:center}[dir] .configTable_userWorkspaceContainer__fFBGcSuRzu .configTable_addWorkspaceContainer__fskKhSkhio .configTable_heading__b4KY1Kb9RG{margin-bottom:1.6rem}[dir] .configTable_userWorkspaceContainer__fFBGcSuRzu .configTable_addWorkspaceContainer__fskKhSkhio .configTable_button__nigj8mcezJ{margin-bottom:1.6rem}", ""]);

// Exports
exports.locals = {
	"userWorkspaceContainer": "configTable_userWorkspaceContainer__fFBGcSuRzu",
	"addWorkspaceContainer": "configTable_addWorkspaceContainer__fskKhSkhio",
	"heading": "configTable_heading__b4KY1Kb9RG",
	"button": "configTable_button__nigj8mcezJ"
};