exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".sendEmailModalContentRenderer_flexRow__iTGaroPaN7{display:flex;flex-direction:row}.sendEmailModalContentRenderer_emailPreviewContainer__uch2xckwxp{width:50%;overflow-y:auto;max-height:60rem}[dir] .sendEmailModalContentRenderer_emailPreviewContainer__uch2xckwxp{background-color:#f4f5f6;padding:2.4rem 1rem 2rem}.sendEmailModalContentRenderer_addTagStyle__nqhysrubto{width:4rem}.sendEmailModalContentRenderer_emailTemplateContainer__cm6r37UpRU{overflow-y:auto}[dir] .sendEmailModalContentRenderer_emailTemplateContainer__cm6r37UpRU{margin-top:4rem}.sendEmailModalContentRenderer_templateVariableContainer__4ypTZQfadC{max-height:40rem;overflow-y:auto}[dir=ltr] .sendEmailModalContentRenderer_templateVariableContainer__4ypTZQfadC{margin-right:1rem}[dir=rtl] .sendEmailModalContentRenderer_templateVariableContainer__4ypTZQfadC{margin-left:1rem}.sendEmailModalContentRenderer_customSelect__oAeWeJvWQ3{width:100%}[dir] .sendEmailModalContentRenderer_customSelect__oAeWeJvWQ3 .ant-select-selection__choice{padding-top:2px}.sendEmailModalContentRenderer_customSelect__oAeWeJvWQ3 .ant-select-selection__choice__remove{top:-1px}[dir=ltr] .sendEmailModalContentRenderer_customSelect__oAeWeJvWQ3 .ant-select-selection__choice__remove{right:-1px}[dir=rtl] .sendEmailModalContentRenderer_customSelect__oAeWeJvWQ3 .ant-select-selection__choice__remove{left:-1px}.sendEmailModalContentRenderer_richTextEditorWrapper__57igi8dqh1{min-height:30rem;max-height:50rem;width:98%;overflow:auto}", ""]);

// Exports
exports.locals = {
	"flexRow": "sendEmailModalContentRenderer_flexRow__iTGaroPaN7",
	"emailPreviewContainer": "sendEmailModalContentRenderer_emailPreviewContainer__uch2xckwxp",
	"addTagStyle": "sendEmailModalContentRenderer_addTagStyle__nqhysrubto",
	"emailTemplateContainer": "sendEmailModalContentRenderer_emailTemplateContainer__cm6r37UpRU",
	"templateVariableContainer": "sendEmailModalContentRenderer_templateVariableContainer__4ypTZQfadC",
	"customSelect": "sendEmailModalContentRenderer_customSelect__oAeWeJvWQ3",
	"richTextEditorWrapper": "sendEmailModalContentRenderer_richTextEditorWrapper__57igi8dqh1"
};