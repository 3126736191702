import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

// Tekion Components
import withActions from '@tekion/tekion-components/connectors/withActions';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import Loader from '@tekion/tekion-components/molecules/loader';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

// Components
import WithSize from '../../../../../../../connectors/withSize';

// Helpers
import getFields from '../../constants/workflowBuilderForm.fields';
import ACTION_HANDLERS from './workflowBuilderForm.actionHandlers';

// Constants
import { WORKFLOW_SECTIONS, RULE_SECTIONS } from '../../constants/workflowBuilderForm.sections';
import { WORKFLOW_BUILDER_MODE } from '../../../../../../../constants/general.constants';
import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import PAGE_IDS from '../../../../../constants/PageIds.constants';

const CONTEXT_ID = 'WORKFLOW_BUILDER_FORM';
const INITIAL_STATE = {
  values: {},
  errors: {},
};

const WorkflowBuilderForm = ({ isEventTypesLoading, contentHeight, workflowMode, history, eventTypes, values, errors, onAction }) => {
  const formHeading = workflowMode === WORKFLOW_BUILDER_MODE.PROCESS ? __('Process details') : __('Rule details');
  const sections = useMemo(() => (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS ? WORKFLOW_SECTIONS : RULE_SECTIONS), [workflowMode]);
  const fields = useMemo(() => getFields(workflowMode, eventTypes), [workflowMode, eventTypes]);

  const handleSubmit = useCallback(() => triggerSubmit(CONTEXT_ID), []);

  const handleCancel = useCallback(() => history.push(`${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${workflowMode}`), [history, workflowMode]);

  if (isEventTypesLoading) {
    return <Loader id="ENTRY_PAGE" />;
  }

  return (
    <Page>
      <Page.Header hasBack goBackTo={`${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${workflowMode}`}>
        <Heading>{formHeading}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight, paddingTop: '2.4rem' }}>
        <FormWithSubmission contextId={CONTEXT_ID} sections={sections} fields={fields} values={values} errors={errors} onAction={onAction} />
      </Page.Body>
      <SaveComponent primaryButtonLabel={__('Next')} onPrimaryAction={handleSubmit} onSecondaryAction={handleCancel} />
    </Page>
  );
};

WorkflowBuilderForm.propTypes = {
  isEventTypesLoading: PropTypes.bool.isRequired,
  contentHeight: PropTypes.number.isRequired,
  workflowMode: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  eventTypes: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withActions(INITIAL_STATE, ACTION_HANDLERS),
  WithSize({ hasPageFooter: true, hasPageHeader: true }),
)(WorkflowBuilderForm);
