const ACTION_TYPES = {
  LOAD_CONDITION_FIELDS: 'LOAD_CONDITION_FIELDS',
  LOAD_TARGET_ENTITY_FIELDS: 'LOAD_TARGET_ENTITY_FIELDS',
  LOAD_TARGET_ENTITY_VALIDATION_RULES: 'LOAD_TARGET_ENTITY_VALIDATION_RULES',
  LOAD_TARGET_ENTITY_VIEWS: 'LOAD_TARGET_ENTITY_VIEWS',
  LOAD_ACTION: 'LOAD_ACTION',
  ON_CANCEL: 'ON_CANCEL',
  ON_INIT: 'ON_INIT',
  ON_CANCEL_MODAL_OPEN: 'ON_CANCEL_MODAL_OPEN',
  ON_CANCEL_MODAL_CLOSE: 'ON_CANCEL_MODAL_CLOSE',
  ON_ASYNC_TEMPLATE_LOADED_OPTIONS: 'ON_ASYNC_LOADED_OPTIONS',
};

export default ACTION_TYPES;
