import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import _isNil from 'lodash/isNil';
import _noop from 'lodash/noop';
import _get from 'lodash/get';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

// Utils
import { toMoment } from '@tekion/tekion-base/utils/dateUtils';

const DatePickerWrapper = (DatePickerField) => {
  const WrappedComponent = React.memo(({ value, onAction, ...restProps }) => {
    const momentValue = useMemo(() => {
      if (_isNil(value)) return undefined;
      return toMoment(value);
    }, [value]);

    const handleAction = useCallback(
      (action = EMPTY_OBJECT) => {
        const type = _get(action, 'type');
        const payload = _get(action, 'payload', EMPTY_OBJECT);
        const _value = _get(action, 'payload.value', EMPTY_ARRAY);
        if (type === FORM_ACTION_TYPES.ON_FIELD_CHANGE) {
          onAction({
            type,
            payload: {
              ...payload,
              value: _value.valueOf(),
            },
          });
        } else {
          onAction(action);
        }
      },
      [onAction],
    );

    return <DatePickerField value={momentValue} onAction={handleAction} {...restProps} />;
  });

  WrappedComponent.propTypes = {
    value: PropTypes.string,
    onAction: PropTypes.func,
  };

  WrappedComponent.defaultProps = {
    value: undefined,
    onAction: _noop,
  };

  return WrappedComponent;
};

export default DatePickerWrapper;
