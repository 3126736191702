import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import Button from '@tekion/tekion-components/atoms/Button';

import envReader from '../../../../../readers/env.reader';

const HCaptchaRenderer = ({ actionType, onAction, ...rest }) => {
  const captchaRef = useRef(null);
  const { hCaptchaSiteKey } = envReader;

  const handleClick = async () => {
    const { response: captchaToken } = await captchaRef.current.execute({ async: true });
    onAction({ type: actionType, payload: { captchaToken } });
  };

  return (
    <>
      <Button className="full-width" onClick={handleClick} {...rest} />
      <HCaptcha sitekey={hCaptchaSiteKey} size="invisible" ref={captchaRef} />
    </>
  );
};

HCaptchaRenderer.propTypes = {
  actionType: PropTypes.string,
  onAction: PropTypes.func.isRequired,
};

HCaptchaRenderer.defaultProps = {
  actionType: '',
};

export default HCaptchaRenderer;
