import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _castArray from 'lodash/castArray';
import _head from 'lodash/head';
import _size from 'lodash/size';
import _filter from 'lodash/filter';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { EMPTY_STRING, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import PAGE_IDS from '../../../../../constants/PageIds.constants';
import { STUDIO_ROUTE } from '../../../../../../../constants/routes';

import { MODAL_ACTION_TYPES, TREE_ACTION_TYPES } from '../constants/reporteeList.actionTypes';

import { getParentKey, getModifiedTreeData, getAllRoleOptions } from './reporteeList.helpers';
import { getAllRoles } from '../../../../../../../actions/rolesHierarchyManagement.actions';

const handleInitializeRoles = async ({ setState }) => {
  const rolesTreeData = await getAllRoles();
  const rolesDataByName = {};
  const roleOptions = [];
  if (!_isEmpty(rolesTreeData)) {
    const treeData = _castArray(getModifiedTreeData(rolesTreeData, rolesDataByName));
    getAllRoleOptions(_head(treeData), roleOptions);

    setState({ treeData, roleOptions, totalRoles: _size(roleOptions), rolesDataByName, topMostRoleKey: _get(_head(treeData), 'key') });
  } else {
    setState({ treeData: [], totalRoles: 0, roleOptions, rolesDataByName: {}, topMostRoleKey: '' });
  }
};

const handleExpandRole = ({ setState, params }) => {
  const { expandedKeys } = params;
  setState({ expandedKeys });
};

const handleClickAddReportee = ({ getState, params }) => {
  const { history, roleOptions } = getState();
  const path = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTEE}/${PAGE_IDS.REPORTEE_CREATE}`;

  if (!_isEmpty(params)) {
    history.push({ pathname: path, state: { selectedRoleKey: _get(params, 'selectedRoleKey'), roleOptions } });
  }
  history.push({ pathname: path, state: { roleOptions } });
};

const handleDeleteRole = ({ setState, params }) => {
  const { selectedRoleName } = params;
  setState({ isDeleteModalVisible: true, selectedRoleName });
};

const handleOnCloseDeleteModal = ({ setState }) => {
  setState({ isDeleteModalVisible: false });
};

const handleConfirmDelete = ({ setState }) => {
  setState({ isDeleteModalVisible: false });
};

const handleClickModalAddNewReportee = ({ setState }) => {
  setState({ isModalRoleFormVisible: true });
};

const handleEditRole = ({ getState, params }) => {
  const { history, roleOptions } = getState();
  const { selectedRoleKey } = params;

  const path = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTEE}/${PAGE_IDS.REPORTEE_EDIT}/${selectedRoleKey}`;

  history.push({ pathname: path, state: { roleOptions } });
};

const handleCollapseAll = ({ setState }) => {
  setState({ expandedKeys: [] });
};

const handleExpandAll = ({ getState, setState }) => {
  const { roleOptions } = getState();
  const expandedKeys = _map(roleOptions, 'value');
  setState({ expandedKeys });
};

const handleClearRoleFormFields = ({ setState }) => {
  setState({ newReporteeData: {} });
};

const handleSelectRole = ({ getState, params }) => {
  const { history, rolesDataByName } = getState();
  const { selectedKeys } = params;

  const selectedKey = _head(selectedKeys);
  const path = `${STUDIO_ROUTE}/${PAGE_IDS.REPORTEE}/${PAGE_IDS.ROLE_CONFIGURE}/${selectedKey}`;

  history.push({ pathname: path, state: { rolesDataByName, selectedRoleData: _get(rolesDataByName, selectedKey) } });
};

const handleSearchRole = ({ getState, setState, params }) => {
  const { treeData, roleOptions } = getState();
  const { searchText } = params;

  let expandedKeys = _map(roleOptions, (item) => {
    const title = _get(item, 'label');
    if (title.indexOf(searchText) > -1) {
      return getParentKey(item.value, treeData);
    }
    return null;
  });

  expandedKeys = _filter(expandedKeys, (item, index, self) => item && self.indexOf(item) === index);

  if (searchText) {
    setState({
      expandedKeys,
      searchText,
      autoExpandParent: true,
    });
  } else {
    setState({
      searchText: EMPTY_STRING,
      autoExpandParent: false,
    });
  }
};

const handleOnChangeRole = ({ setState, params = EMPTY_OBJECT }) => {
  const { selectedvalue } = params;
  setState({ selectedRoleValue: selectedvalue });
};

const handleFieldChange = ({ setState, params = EMPTY_OBJECT }) => {
  const { id, value } = params;
  setState((state) => ({
    newReporteeData: {
      ...state?.newReporteeData,
      [id]: value,
    },
  }));
};

const handleErrors = ({ setState, params = EMPTY_OBJECT }) => {
  const { errors } = params;
  setState({ errors });
};

const ACTION_HANDLERS = {
  [TREE_ACTION_TYPES.ON_INITIALIZE_ROLES]: handleInitializeRoles,
  [TREE_ACTION_TYPES.ON_EXPAND_ROLE]: handleExpandRole,
  [TREE_ACTION_TYPES.ON_CLICK_ADD_REPORTEE]: handleClickAddReportee,
  [TREE_ACTION_TYPES.ON_DELETE_ROLE]: handleDeleteRole,
  [TREE_ACTION_TYPES.ON_EDIT_ROLE]: handleEditRole,
  [TREE_ACTION_TYPES.ON_CLICK_COLLAPSE_ALL]: handleCollapseAll,
  [TREE_ACTION_TYPES.ON_CLICK_EXPAND_ALL]: handleExpandAll,
  [TREE_ACTION_TYPES.ON_SELECT_ROLE]: handleSelectRole,
  [TREE_ACTION_TYPES.ON_SEARCH_ROLE]: handleSearchRole,
  [MODAL_ACTION_TYPES.ON_CHANGE_ROLE]: handleOnChangeRole,
  [MODAL_ACTION_TYPES.ON_CLOSE_DELETE_MODAL]: handleOnCloseDeleteModal,
  [MODAL_ACTION_TYPES.ON_CLICK_DONE]: handleConfirmDelete,
  [MODAL_ACTION_TYPES.ON_CLICK_MODAL_ADD_NEW_REPORTEE]: handleClickModalAddNewReportee,
  [MODAL_ACTION_TYPES.ON_CLICK_CLEAR_FIELDS]: handleClearRoleFormFields,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
};

export default ACTION_HANDLERS;
