import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import Heading from '@tekion/tekion-components/atoms/Heading';
import Button from '@tekion/tekion-components/atoms/Button';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import { EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';

import NavigationItem from '../../../../../../../../molecules/NavigationFlow/components/navigationItem';

import { APPLICATION_TOP_NAVIGATION_DEFAULT_DATA } from '../../constants/applicationBuilder.constants';
import ACTION_TYPES from '../../constants/applicationBuilder.actionTypes';

import styles from './applicationHeading.module.scss';
import { FORM_MODES } from '../../../../../../../../constants/general.constants';

const ApplicationHeading = (props) => {
  const { formMode, history, applicationConfig, onAction } = props;

  const handleEntityMappingsClick = () => onAction({ type: ACTION_TYPES.ON_ENTITY_MAPPINGS_CLICK });

  const handleEditClick = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_EDIT_CLICK });
  }, [onAction]);

  const headerNavigationData = useMemo(
    () => [...APPLICATION_TOP_NAVIGATION_DEFAULT_DATA, { label: __(_get(applicationConfig, 'displayName', NO_DATA)), key: '2' }],
    [applicationConfig],
  );

  const handleNavigationItemClick = useCallback(
    (goTo) => {
      if (!_isEmpty(goTo)) {
        history.push(goTo);
      }
    },
    [history],
  );

  const headerNavigation = useMemo(
    () =>
      _map(headerNavigationData, (data, index) => (
        <NavigationItem
          key={_get(data, 'key', EMPTY_STRING)}
          itemNumber={index}
          totalItems={_size(headerNavigationData)}
          data={data}
          onNavigationItemClick={handleNavigationItemClick}
        />
      )),
    [headerNavigationData, handleNavigationItemClick],
  );

  return (
    <>
      <div className="flex">
        <Heading className={styles.headerNavigationContainer} size={2}>
          {headerNavigation}
        </Heading>
        <FontIcon size={SIZES.MD_S} className="cursor-pointer p-t-4" onClick={handleEditClick}>
          icon-edit
        </FontIcon>
      </div>
      {formMode === FORM_MODES.EDIT && (
        <Button view={Button.VIEW.TERTIARY} onClick={handleEntityMappingsClick}>
          {__('Entity mappings')}
        </Button>
      )}
    </>
  );
};

ApplicationHeading.propTypes = {
  formMode: PropTypes.string.isRequired,
  applicationConfig: PropTypes.object,
  history: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ApplicationHeading.defaultProps = {
  applicationConfig: EMPTY_OBJECT,
  history: EMPTY_OBJECT,
};

export default ApplicationHeading;
