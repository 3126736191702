import { compose } from 'recompose';

import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import withCellPopover from '@tekion/tekion-components/molecules/withCellPopover/withCellPopover';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import EntityAsyncSelect from '../../../molecules/EntityAsyncSelect/EntityAsyncSelect';
import AsyncPaginatedSelect from '../../../../../organisms/asyncPaginatedSelect/AsyncPaginatedSelect';

import { getLabelForNameField } from './metadataComponentForm.helpers';
import { getOptionsForAsyncSelect, getServiceHandler, getPayload } from './metadataComponentForm.AsyncSelectHelpers';

import { COMPONENT_TYPE_OPTIONS } from '../constants/metadataComponentForm.general.constants';

const EntityAsyncSelectRenderer = compose(makeCellRenderer, withCellPopover)(EntityAsyncSelect);

const FIELD_IDS = {
  COMPONENT_TYPE: 'componentType',
  ENTITY_NAME: 'entityName',
  NAME: 'name',
};

const COMPONENT_TYPE_FIELD = {
  id: FIELD_IDS.COMPONENT_TYPE,
  renderer: SelectInput,
  renderOptions: {
    required: true,
    label: __('Component Type'),
    placeholder: __('Select Component Type'),
    options: COMPONENT_TYPE_OPTIONS,
    validators: [isRequiredRule],
  },
};

const ENTITY_NAME_FIELD = {
  id: FIELD_IDS.ENTITY_NAME,
  renderer: EntityAsyncSelectRenderer,
  renderOptions: {
    required: true,
    label: __('Entity Name'),
    placeholder: __('Select an Entity'),
    validators: [isRequiredRule],
  },
};

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    required: true,
    label: __('Name'),
    validators: [isRequiredRule],
  },
};

const COMPONENT_FORM_FIELDS = {
  [FIELD_IDS.COMPONENT_TYPE]: COMPONENT_TYPE_FIELD,
  [FIELD_IDS.ENTITY_NAME]: ENTITY_NAME_FIELD,
  [FIELD_IDS.NAME]: NAME_FIELD,
};

const getFields = (ref, componentTypeName, entityName) => {
  const [serviceHandler, filters] = getServiceHandler(componentTypeName, entityName);
  const payload = getPayload(filters, entityName, true);

  return {
    ...COMPONENT_FORM_FIELDS,
    [FIELD_IDS.NAME]: addToRenderOptions(NAME_FIELD, [
      {
        path: 'ref',
        value: ref,
      },
      {
        path: 'serviceHandler',
        value: serviceHandler,
      },
      {
        path: 'getPayload',
        value: payload,
      },
      {
        path: 'getOptions',
        value: getOptionsForAsyncSelect(componentTypeName),
      },
      {
        path: 'label',
        value: __(`${getLabelForNameField(componentTypeName)} Name`),
      },
    ]),
  };
};

export { FIELD_IDS, getFields };
