import React, { useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _size from 'lodash/size';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import WithSize from '../../../../../../../../connectors/withSize';

// Helpers
import { createTableProps } from './helpers/fieldsList.helpers';
import ACTION_HANDLERS from './helpers/fieldsList.actionHandlers';

// Constants
import { TABLE_MANAGER_PROPS, SUB_HEADER_PROPS } from './constants/fieldsList.constants';
import COLUMN_CONFIG from './constants/fieldsList.columns';

// Styles
import styles from './fieldsList.module.scss';

const FieldsList = ({ fieldsList, contentHeight, onAction }) => {
  const fieldsCount = _size(fieldsList);

  const tableProps = useMemo(() => createTableProps(fieldsCount), [fieldsCount]);

  return (
    <div className={styles.tableConainer} style={{ height: contentHeight - 100 }}>
      <TableManager
        containerClassName={styles.tableContainer}
        tableManagerProps={TABLE_MANAGER_PROPS}
        subHeaderProps={SUB_HEADER_PROPS}
        tableProps={tableProps}
        columns={COLUMN_CONFIG}
        data={fieldsList}
        onAction={onAction}
      />
    </div>
  );
};

FieldsList.propTypes = {
  fieldsList: PropTypes.array,
  contentHeight: PropTypes.number.isRequired,
  onAction: PropTypes.func.isRequired,
};

FieldsList.defaultProps = {
  fieldsList: EMPTY_ARRAY,
};

export default compose(WithSize({ hasPageHeader: true }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(FieldsList);
