/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import _map from 'lodash/map';
import _noop from 'lodash/noop';
import _curry from 'lodash/curry';
import _size from 'lodash/size';

// HOC
import withActions from '@tekion/tekion-components/connectors/withActions';

// components
import DefaultCellActionElement from './molecules/defaultCellActionElement';

// constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ACTION_HANDLERS from './tableCellActionMenu.actionHandlers';
import TABLE_CELL_ACTIONS_CONFIG from './constants/tableCellActionMenu.config';

// styles
import styles from './tableCellActionMenu.module.scss';

// hooks
import useTableCellActions from './hooks/useTableCellActions';

const renderCellAction = _curry(({ onCellActionClick, element, cellBackgroundColor }, actionConfig) => {
  const { actionType, Component = DefaultCellActionElement, label, className } = actionConfig;
  return (
    <Component
      key={actionType}
      actionType={actionType}
      label={label}
      onAction={onCellActionClick}
      className={className}
      element={element}
      cellBackgroundColor={cellBackgroundColor}
    />
  );
});

const renderCellActionsSection = _curry(({ onCellActionClick, element, totalSections, cellBackgroundColor }, sectionConfig, index) => {
  const { id, actions } = sectionConfig;
  const isLastSection = index + 1 === totalSections;
  return (
    <div key={id}>
      {_map(actions, renderCellAction({ onCellActionClick, element, cellBackgroundColor }))}
      {!isLastSection && <div className={styles.separator} />}
    </div>
  );
});

function TableCellActionMenu(props) {
  const { onAction, element, cellBackgroundColor } = props;

  const { onCellActionClick } = useTableCellActions(onAction);

  return (
    <div className={`${styles.cellActionContainer} p-y-4`}>
      {_map(
        TABLE_CELL_ACTIONS_CONFIG,
        renderCellActionsSection({
          onCellActionClick,
          element,
          totalSections: _size(TABLE_CELL_ACTIONS_CONFIG),
          cellBackgroundColor,
        }),
      )}
    </div>
  );
}

TableCellActionMenu.propTypes = {
  onAction: PropTypes.func,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  }).isRequired,
  cellBackgroundColor: PropTypes.string,
};

TableCellActionMenu.defaultProps = {
  onAction: _noop,
  cellBackgroundColor: undefined,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS), React.memo)(TableCellActionMenu);
