import _constant from 'lodash/constant';
import _set from 'lodash/set';

import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import ButtonRenderer from '@tekion/tekion-components/molecules/CellRenderers/buttonRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import Button from 'tcomponents/atoms/Button';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer';

import { COLOR_MAP } from '@tekion/tekion-base/constants/statusColors';
import { STATUS } from '@tekion/tekion-base/constants/status.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { IMPORT_BATCH_COLUMN_IDS } from '../constants/dataImportDetailPage.constants';
import ACTION_TYPES from '../constants/dataImportDetailPage.actionTypes';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const STATUS_COLOR_MAP = {
  [STATUS.COMPLETED]: COLOR_MAP.GREEN,
  [STATUS.FAILED]: COLOR_MAP.RED,
  [STATUS.IN_PROGRESS]: COLOR_MAP.YELLOW,
  [STATUS.PENDING]: COLOR_MAP.GREY,
};

const STATUS_LABEL_MAP = {
  [STATUS.COMPLETED]: __('Completed'),
  [STATUS.FAILED]: __('Failed'),
  [STATUS.IN_PROGRESS]: __('In Progress'),
  [STATUS.PENDING]: __('Pending'),
};

const getStatusProps = _constant({
  colorMap: STATUS_COLOR_MAP,
  labelMap: STATUS_LABEL_MAP,
});

const handleAction = (onAction, columnId) => (action) => {
  _set(action, 'payload.columnId', columnId);
  onAction(action);
};

const COLUMN_CONFIG = [
  {
    Header: __('Batch ID'),
    id: IMPORT_BATCH_COLUMN_IDS.ID,
    accessor: IMPORT_BATCH_COLUMN_IDS.ID,
    Cell: TextRenderer,
  },
  {
    Header: __('Start Time'),
    id: IMPORT_BATCH_COLUMN_IDS.START_TIME,
    accessor: IMPORT_BATCH_COLUMN_IDS.START_TIME,
    Cell: TextRenderer,
  },
  {
    Header: __('End Time'),
    id: IMPORT_BATCH_COLUMN_IDS.END_TIME,
    accessor: IMPORT_BATCH_COLUMN_IDS.END_TIME,
    Cell: TextRenderer,
  },

  {
    Header: __('Records Processed'),
    id: IMPORT_BATCH_COLUMN_IDS.RECORDS_PROCESSED,
    accessor: IMPORT_BATCH_COLUMN_IDS.RECORDS_PROCESSED,
    Cell: TextRenderer,
  },
  {
    Header: __('Records Failed'),
    id: IMPORT_BATCH_COLUMN_IDS.RECORDS_FAILED,
    accessor: IMPORT_BATCH_COLUMN_IDS.RECORDS_FAILED,
    Cell: TextRenderer,
  },
  {
    Header: __('View Request'),
    id: IMPORT_BATCH_COLUMN_IDS.VIEW_REQUEST,
    accessor: IMPORT_BATCH_COLUMN_IDS.VIEW_REQUEST,
    Cell: ButtonRenderer,
    getProps: (props) => ({
      label: __('Download'),
      view: Button.VIEW.LINK,
      actionType: ACTION_TYPES.DOWNLOAD_DATA,
      onAction: handleAction(tget(props, 'onAction'), IMPORT_BATCH_COLUMN_IDS.VIEW_REQUEST),
    }),
  },
  {
    Header: __('View Result'),
    id: IMPORT_BATCH_COLUMN_IDS.VIEW_RESULT,
    accessor: IMPORT_BATCH_COLUMN_IDS.VIEW_RESULT,
    Cell: ButtonRenderer,
    getProps: (props) => ({
      label: __('Download'),
      view: Button.VIEW.LINK,
      actionType: ACTION_TYPES.DOWNLOAD_DATA,
      onAction: handleAction(tget(props, 'onAction'), IMPORT_BATCH_COLUMN_IDS.VIEW_RESULT),
    }),
  },
  {
    Header: __('Status'),
    id: IMPORT_BATCH_COLUMN_IDS.TASK_STATUS,
    accessor: IMPORT_BATCH_COLUMN_IDS.TASK_STATUS,
    Cell: StatusCellRenderer,
    getProps: getStatusProps,
  },
];

export default COLUMN_CONFIG;
