import { createAlignPlugin } from '@udecode/plate-alignment';
import { getLeafDeserializer } from '@udecode/plate-common';
import { getPlatePluginOptions } from '@udecode/plate-core';
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_H4, ELEMENT_H5, ELEMENT_H6 } from '@udecode/plate-heading';
import { ELEMENT_IMAGE } from '@udecode/plate-image';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';

const getAlignmentDeserialize = () => (editor) => {
  const options = getPlatePluginOptions(editor, 'align');

  return {
    leaf: getLeafDeserializer({
      type: options.type,

      getNode: (element) => ({
        [options.type]: element.style.textAlign,
      }),

      rules: [
        {
          nodeNames: ['P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'IMG'],

          style: {
            textAlign: ['center', 'left', 'right', 'justify'],
          },
        },
      ],

      ...options.deserialize,
    }),
  };
};

const createAlignmentPlugin = () => ({
  ...createAlignPlugin({
    types: [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_H4, ELEMENT_H5, ELEMENT_H6, ELEMENT_IMAGE],
  }),
  pluginKeys: 'align',
  deserialize: getAlignmentDeserialize(),
});

export default createAlignmentPlugin;
