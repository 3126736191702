import produce from 'immer';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _unset from 'lodash/unset';
import _pick from 'lodash/pick';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

// Actions
import { fetchAction, createAction, updateAction } from '../../../../../../actions/actionBuilder.actions';
import { fetchValidationRules } from '../../../../../../actions/validationRuleBuilder.actions';
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../../actions/conditionBuilder.actions';
import { searchEntityViewConfigurations } from '../../../../../../actions/entityViewDefinitions.actions';

// Helpers
import { generatePayloadFromFormValues, getPayloadForViewDefinitionsSearch } from './actionBuilder.payloadHelpers';
import { convertApiDataToFormValues, validateFormValues } from './actionBuilder.helpers';
import getSections from '../formHelpers/actionBuilerForm.sections';

// Constants
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../constants/actionBuilder.constants';
import { VIEW_TYPES as VIEW_BUILDER_VIEW_TYPES } from '../../../../../../constants/viewBuilder.constants';
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import { FORM_MODES, STANDARD_ENTITY_NAME } from '../../../../../../constants/general.constants';
import PAGE_IDS from '../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/actionBuilderForm.actionTypes';
import {
  UPDATE_RECORD_VALUES,
  USER_INPUT_TYPES,
  ACTION_DEFINITION_CONDITION_VARIABLE_NAME,
  FILTER_CONDITION_VARIABLE_NAME,
  ACTION_BUILDER_ACTION_TYPES,
} from '../constants/actionBuilderForm.general';
import { FIELD_IDS as TEMPLATE_BUILDER_FIELD_IDS } from '../../../../../../constants/templateBuilder.constants';
import { CURRENT_ENTITY_NAMESPACE } from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';

const handleLoadConditionFields = async ({ getState, setState }) => {
  const { match = EMPTY_OBJECT } = getState();
  const { entityName } = _get(match, 'params', EMPTY_OBJECT);

  setState({ isConditionFieldsLoading: true });

  const mapOfVariableToEntityNameForEntryCondition = { [CURRENT_ENTITY_NAMESPACE]: entityName };
  const conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityNameForEntryCondition);

  setState({
    isConditionFieldsLoading: false,
    mapOfVariableToEntityNameForEntryCondition,
    conditionBuilderFieldDefinitionObject,
  });
};

const handleLoadTargetEntityFields = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { targetEntity } = params;
  const { conditionBuilderFieldDefinitionObject = EMPTY_OBJECT } = getState();

  if (!_isEmpty(getArraySafeValue(targetEntity))) {
    setState({ isTargetEntityFieldsLoading: true });

    const mapOfVariableToEntityNameForActionDefinition = { [ACTION_DEFINITION_CONDITION_VARIABLE_NAME]: getArraySafeValue(targetEntity) };
    const mapOfVariableToEntityNameForFilterCondition = { [FILTER_CONDITION_VARIABLE_NAME]: getArraySafeValue(targetEntity) };
    const updatedConditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(
      mapOfVariableToEntityNameForActionDefinition,
      conditionBuilderFieldDefinitionObject,
    );

    setState({
      isTargetEntityFieldsLoading: false,
      mapOfVariableToEntityNameForActionDefinition,
      conditionBuilderFieldDefinitionObject: updatedConditionBuilderFieldDefinitionObject,
      mapOfVariableToEntityNameForFilterCondition,
    });
  }
};

const handleLoadAction = async ({ setState, getState }) => {
  const { match = EMPTY_OBJECT } = getState();
  const entityName = _get(match, 'params.entityName');
  const actionName = _get(match, 'params.actionName', '');

  if (_isEmpty(actionName)) {
    const initialFormValues = {
      [ACTION_DEFINITION_FIELD_IDS.ACTION_NAME]: `${entityName}`,
    };

    setState({ formMode: FORM_MODES.CREATE, formValues: initialFormValues });
  } else {
    setState({ formMode: FORM_MODES.EDIT, isActionDataLoading: true });

    const data = await fetchAction(actionName);
    const modifiedFormData = convertApiDataToFormValues(data);
    const targetEntityName = getArraySafeValue(
      _get(modifiedFormData, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME, STANDARD_ENTITY_NAME.NOTIFICATION),
    );

    setState({
      formValues: modifiedFormData,
      isActionDataLoading: false,
      mapOfVariableToEntityNameForActionDefinition: { [ACTION_DEFINITION_CONDITION_VARIABLE_NAME]: targetEntityName },
      mapOfVariableToEntityNameForFilterCondition: { [FILTER_CONDITION_VARIABLE_NAME]: targetEntityName },
    });
  }
};

const handleFieldChange = async ({ params, getState, setState }) => {
  const { id, value } = params;
  const { formValues, match, formMode, isUserOverriddenName = false } = getState();
  const updatedValues = { ...formValues, [id]: value };

  const entityName = match?.params?.entityName;

  // Updating Action Name on basis of action display name while creating an new action
  if (id === ACTION_DEFINITION_FIELD_IDS.ACTION_NAME && !isUserOverriddenName) {
    setState({ isUserOverriddenName: true });
  } else if (formMode === FORM_MODES.CREATE && id === ACTION_DEFINITION_FIELD_IDS.ACTION_DISPLAY_NAME && !isUserOverriddenName) {
    _set(updatedValues, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME, `${entityName}${_snakeCase(value)}`);
  }

  if (
    id === ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE &&
    (getArraySafeValue(value) === ACTION_BUILDER_ACTION_TYPES.DELETE_RECORD ||
      getArraySafeValue(value) === ACTION_BUILDER_ACTION_TYPES.SEND_EMAILS ||
      getArraySafeValue(value) === ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL ||
      getArraySafeValue(value) === ACTION_BUILDER_ACTION_TYPES.WORKFLOW_TRIGGER)
  ) {
    _unset(updatedValues, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME);
  }

  if (id === ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE && getArraySafeValue(value) === ACTION_BUILDER_ACTION_TYPES.SEND_EMAILS) {
    setState({
      mapOfVariableToEntityNameForActionDefinition: { [ACTION_DEFINITION_CONDITION_VARIABLE_NAME]: STANDARD_ENTITY_NAME.NOTIFICATION },
    });
  }

  if (id === ACTION_DEFINITION_FIELD_IDS.USER_INPUT && value && _isEmpty(formValues?.[ACTION_DEFINITION_FIELD_IDS.USER_INPUT_TYPE])) {
    _set(updatedValues, ACTION_DEFINITION_FIELD_IDS.USER_INPUT_TYPE, USER_INPUT_TYPES.FORM);
  } else if (id === ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS && value && _isEmpty(formValues?.[ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS])) {
    _set(updatedValues, ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS, [EMPTY_OBJECT]);
  }

  const targetEntity = formValues?.[ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME];
  if (targetEntity === entityName) {
    _set(formValues, ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD, UPDATE_RECORD_VALUES.OTHER);
  } else {
    _set(formValues, ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD, null);
  }

  if (id === ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME) {
    setState({
      mapOfVariableToEntityNameForActionDefinition: { [ACTION_DEFINITION_CONDITION_VARIABLE_NAME]: getArraySafeValue(value) },
      mapOfVariableToEntityNameForFilterCondition: { [FILTER_CONDITION_VARIABLE_NAME]: getArraySafeValue(value) },
    });
  }

  setState({ formValues: updatedValues });
};

const handleLoadTargetEntityValidationRules = async ({ setState, params = EMPTY_OBJECT }) => {
  const { targetEntity } = params;

  if (!_isEmpty(getArraySafeValue(targetEntity))) {
    setState({ isTargetEntityRulesLoading: true });
    const response = await fetchValidationRules(targetEntity, { rows: 5000 });
    const targetEntityValidationRules = tget(response, 'hits', EMPTY_ARRAY);

    setState(
      produce((draft) => ({
        isTargetEntityRulesLoading: false,
        targetEntityValidationRulesStore: {
          ...draft.targetEntityValidationRulesStore,
          [targetEntity]: targetEntityValidationRules,
        },
      })),
    );
  }
};

const handleLoadTargetEntityViews = async ({ setState, params = EMPTY_OBJECT }) => {
  const { targetEntity } = params;

  if (!_isEmpty(getArraySafeValue(targetEntity))) {
    setState({ isTargetEntityViewsLoading: true });
    const payload = getPayloadForViewDefinitionsSearch(targetEntity, [VIEW_BUILDER_VIEW_TYPES.FORM_VIEW], []);
    const response = await searchEntityViewConfigurations(payload);
    const targetEntityFormViews = tget(response, 'hits', EMPTY_ARRAY);

    setState(
      produce((draft) => ({
        isTargetEntityViewsLoading: false,
        targetEntityViewsStore: {
          ...draft.targetEntityViewsStore,
          [targetEntity]: targetEntityFormViews,
        },
      })),
    );
  }
};

const handleErrors = ({ params, setState, getState }) => {
  const { errors } = params;
  const { match, formValues } = getState();
  const entityName = tget(match, 'params.entityName', '');
  const sections = getSections(entityName, formValues);

  const fields = [];

  _forEach(sections, (section) => {
    _forEach(_get(section, 'rows', EMPTY_ARRAY), (row) => {
      _forEach(_get(row, 'columns', EMPTY_ARRAY), (column) => {
        fields.push(column);
      });
    });
  });

  const filteredErrors = _pick(errors, fields);
  setState({
    errors: filteredErrors,
  });
};

const handleSubmit = async ({ getState, setState }) => {
  const { errors = EMPTY_OBJECT, formMode, match = EMPTY_OBJECT, formValues, history } = getState();
  const { entityName, actionName } = _get(match, 'params', EMPTY_OBJECT);

  const hasErrors = _reduce(
    errors,
    (hasError, curr, fieldId) => {
      if (fieldId !== ACTION_DEFINITION_FIELD_IDS.ENTRY_CONDITION && fieldId !== ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS)
        return hasError || !_isEmpty(curr);
      return _reduce(curr, (conditionHasError, error) => hasError || conditionHasError || !_isEmpty(error), false);
    },
    false,
  );
  const { isValid, errorMessage } = validateFormValues(formValues);
  if (!isValid || hasErrors) {
    if (errorMessage) {
      toaster(TOASTER_TYPE.ERROR, errorMessage);
    } else {
      toaster(TOASTER_TYPE.ERROR, __('Please correct form errors.'));
    }
  } else {
    setState({ isSubmitting: true });

    const payload = generatePayloadFromFormValues(formValues, entityName);
    if (formMode === FORM_MODES.CREATE) {
      const response = await createAction(payload);
      if (!_isEmpty(response)) {
        setTimeout(() => {
          history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.ACTION_BUILDER}`);
        }, ES_REFETCH_DELAY);
      } else {
        setState({ isSubmitting: false });
      }
    } else {
      const response = await updateAction(actionName, payload);
      if (!_isEmpty(response)) {
        setTimeout(() => {
          history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.ACTION_BUILDER}`);
        }, ES_REFETCH_DELAY);
      } else {
        setState({ isSubmitting: false });
      }
    }
  }
};

const handleCancel = ({ getState }) => {
  const { history, match = EMPTY_OBJECT } = getState();
  const { entityName } = _get(match, 'params', EMPTY_OBJECT);
  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.ACTION_BUILDER}`);
};

const handleOnFieldBlur = ({ getState, setState, params }) => {
  const { errors = EMPTY_OBJECT } = getState();
  const { id, value, newConditionExpression } = params;
  if (id === ACTION_DEFINITION_FIELD_IDS.ENTRY_CONDITION || id === ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS) {
    if (!_isEmpty(newConditionExpression)) {
      setState({ errors: { ...errors, [id]: value } });
    } else {
      setState({ errors: { ...errors, [id]: undefined } });
    }
  }
};

const handleCancelModalClose = ({ setState }) => {
  setState({ isCancelModalOpen: false });
};

const handleCancelModalOpen = ({ setState }) => {
  setState({ isCancelModalOpen: true });
};

const handleSaveEmailTemplateApiValues = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { value = EMPTY_ARRAY } = params;
  const newValues = _keyBy(value, TEMPLATE_BUILDER_FIELD_IDS.NAME);
  const { emailTemplateDefStore = EMPTY_OBJECT } = getState();

  setState({ emailTemplateDefStore: { ...emailTemplateDefStore, ...newValues } });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.LOAD_CONDITION_FIELDS]: handleLoadConditionFields,
  [ACTION_TYPES.LOAD_TARGET_ENTITY_FIELDS]: handleLoadTargetEntityFields,
  [ACTION_TYPES.LOAD_TARGET_ENTITY_VALIDATION_RULES]: handleLoadTargetEntityValidationRules,
  [ACTION_TYPES.LOAD_TARGET_ENTITY_VIEWS]: handleLoadTargetEntityViews,
  [ACTION_TYPES.ON_INIT]: handleLoadAction,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL]: handleCancel,
  [FORM_ACTION_TYPES.ON_FIELD_BLUR]: handleOnFieldBlur,
  [ACTION_TYPES.ON_CANCEL_MODAL_CLOSE]: handleCancelModalClose,
  [ACTION_TYPES.ON_CANCEL_MODAL_OPEN]: handleCancelModalOpen,
  [ACTION_TYPES.ON_ASYNC_TEMPLATE_LOADED_OPTIONS]: handleSaveEmailTemplateApiValues,
};

export default ACTION_HANDLERS;
