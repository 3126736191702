import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';

import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _keyBy from 'lodash/keyBy';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Modal from '@tekion/tekion-components/molecules/Modal';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import { FormWithSubmission } from '@tekion/tekion-components/pages/formPage';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

import getFields from './helpers/pushNotificationModal.getFields';
import { getApiFormattedFormValues, getFormFormattedFormValues, getUsedVariables } from './helpers/pushNotificationModal.helpers';
import FORM_SECTIONS from './helpers/pushNotificationModal.sections';

import { FIELD_IDS as TEMPLATE_FIELD_IDS } from '../../../../../../../../constants/templateBuilder.constants';
import { ACTION_TYPES, NOTIFICATION_INITIAL_FORM_VALUES } from './pushNotificationModal.constants';
import { MODAL_MODES } from '../../../constants/workflowBuilder.constants';

import styles from './pushNotificationModal.module.scss';

const NOTIFICATION_MODAL_CONTEXT_ID = 'NOTIFICATION_MODAL_CONTEXT_ID';

const PushNotificationModal = ({
  isLoading,
  mode,
  stepDetails,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  onCancel,
  onSubmit,
}) => {
  const [formValues, setFormValues] = useState(NOTIFICATION_INITIAL_FORM_VALUES);
  const [errors, setErrors] = useState({});
  const [templateDefStore, setTemplateDefStore] = useState({});

  const fields = useMemo(
    () => getFields(conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, templateDefStore),
    [conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, templateDefStore],
  );

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case ACTION_TYPES.ON_ASYNC_LOADED_OPTIONS: {
          const { value = EMPTY_ARRAY } = payload;
          const newValues = _keyBy(value, TEMPLATE_FIELD_IDS.NAME);
          setTemplateDefStore((prev) => ({ ...prev, ...newValues }));
          break;
        }
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { value, id } = payload;
          setFormValues(
            produce((draftState) => {
              _set(draftState, id, value);
            }),
          );

          break;
        }
        case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
          const { errors: newErrors } = payload;
          setErrors(newErrors);
          break;
        }
        case FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT: {
          const apiFormValues = getApiFormattedFormValues(formValues);
          const usedVariables = getUsedVariables(apiFormValues);
          onSubmit(apiFormValues, [], [], usedVariables);
          setFormValues({});
          setErrors({});

          break;
        }
        default:
          break;
      }
    },
    [formValues, onSubmit],
  );

  const handleCancel = useCallback(() => {
    setFormValues({});
    setErrors({});
    onCancel();
  }, [onCancel]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(NOTIFICATION_MODAL_CONTEXT_ID);
  }, []);

  useEffect(() => {
    if (mode === MODAL_MODES.EDIT) {
      const userData = tget(stepDetails, 'userData.data');
      if (!_isEmpty(userData)) {
        setFormValues((value) => getFormFormattedFormValues({ ...value, ...userData }));
      }
    }
  }, [stepDetails, mode]);

  return (
    <Modal
      visible
      destroyOnClose
      width={Modal.SIZES.L}
      title={__('Send push notification')}
      submitBtnText={__('Done')}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    >
      <FormWithSubmission
        isFetching={isLoading}
        className={styles.pushNotificationContentContainer}
        contextId={NOTIFICATION_MODAL_CONTEXT_ID}
        sections={FORM_SECTIONS}
        fields={fields}
        values={formValues}
        errors={errors}
        onAction={handleAction}
      />
    </Modal>
  );
};

PushNotificationModal.propTypes = {
  isLoading: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  stepDetails: PropTypes.object.isRequired,
  conditionBuilderFieldDefinitionObject: PropTypes.object.isRequired,
  mapOfVariableToEntityName: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

PushNotificationModal.defaultProps = {
  isLoading: false,
};

export default PushNotificationModal;
