import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import _valuesIn from 'lodash/valuesIn';
import _omit from 'lodash/omit';
import _noop from 'lodash/noop';
import _includes from 'lodash/includes';
import _filter from 'lodash/filter';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { getRecordTypeViewDefFromCache } from '../../../../actions/applicationRenderer.actions';

import { getRecordsFromEntityRecord } from './entityViewViewer.helpers';
import { getRecordTypeOverriddenEntityDef } from '../../../../helpers/entityManagement.helpers';
import { getUpdatedViewConfig } from './viewOverrideHelpers/viewOverride.helpers';
import { extractAllCellViewNamesFromViewConfiguration } from '../../../../helpers/viewBuilder.helpers';

import { ACTION_TYPES, ENTITY_VIEW_CONFIG_KEYS } from './entityViewViewer.constants';
import { VIEW_TYPES } from '../../constants/viewBuilder.constants';
import { APPLICATION_CONTEXT_KEYS } from '../../../../constants/applicationRenderer.constants';
import SYSTEM_DEFINED_FIELDS from '../../../../constants/systemDefinedFields';

import entityReader from '../../../../readers/entity.reader';

const fetchRelatedDefinitions = async ({ getState, setState }) => {
  const { currentRecordType, recordTypeVsViewMapper, viewConfigsByName, applicationContext, entityDef } = getState();
  const currentViewDef = tget(viewConfigsByName, tget(recordTypeVsViewMapper, currentRecordType));

  const cellViewNames = [];
  extractAllCellViewNamesFromViewConfiguration(tget(currentViewDef, 'section', {}), cellViewNames);

  const cachedRecordTypeViewConfigByName = tget(
    applicationContext,
    APPLICATION_CONTEXT_KEYS.RECORD_TYPE_VIEW_CONFIG_BY_NAME_FOR_ENTITIES,
    EMPTY_OBJECT,
  );
  const handleSetRecordTypeViewConfig = tget(applicationContext, APPLICATION_CONTEXT_KEYS.SET_RECORD_TYPE_VIEW_CONFIG, _noop);
  const entityName = entityReader.name(entityDef);

  const newViewConfigsByName = await getRecordTypeViewDefFromCache({
    cacheValue: cachedRecordTypeViewConfigByName,
    setCacheHandler: handleSetRecordTypeViewConfig,
    viewNames: cellViewNames,
    entityName,
  });

  setState({
    viewConfigsByName: { ...viewConfigsByName, ...newViewConfigsByName },
  });
};

const handleInit = async ({ setState, getState }) => {
  const { entityViewConfiguration, entityRecord, viewOverrides = {}, entityDef, initialViewConfigByName = {}, applicationContext } = getState();

  const viewRecordTypeMetadataList = tget(entityViewConfiguration, ENTITY_VIEW_CONFIG_KEYS.VIEW_RECORD_TYPE_METADATA_LIST, EMPTY_ARRAY);
  const viewType = tget(entityViewConfiguration, ENTITY_VIEW_CONFIG_KEYS.VIEW_TYPE);

  let recordTypeVsViewMapper = _reduce(
    viewRecordTypeMetadataList,
    (result, metadata) => ({
      ...result,
      [tget(metadata, 'recordTypeName')]: tget(metadata, 'viewName'),
    }),
    {},
  );

  const recordTypeSelectionViewName = tget(entityViewConfiguration, ENTITY_VIEW_CONFIG_KEYS.RECORD_TYPE_SELECTION_VIEW_NAME);
  if (recordTypeSelectionViewName) {
    recordTypeVsViewMapper = {
      ...recordTypeVsViewMapper,
      recordTypeSelectionViewName,
    };
  }

  const cachedRecordTypeViewConfigByName = tget(
    applicationContext,
    APPLICATION_CONTEXT_KEYS.RECORD_TYPE_VIEW_CONFIG_BY_NAME_FOR_ENTITIES,
    EMPTY_OBJECT,
  );
  const handleSetRecordTypeViewConfig = tget(applicationContext, APPLICATION_CONTEXT_KEYS.SET_RECORD_TYPE_VIEW_CONFIG, _noop);

  // Below we are excluding viewNames which are present in initialViewConfigByName
  const viewNames = _filter(_valuesIn(recordTypeVsViewMapper), (viewName) => !_includes(_valuesIn(initialViewConfigByName), viewName));

  const viewConfigsByName = await getRecordTypeViewDefFromCache({
    cacheValue: cachedRecordTypeViewConfigByName,
    setCacheHandler: handleSetRecordTypeViewConfig,
    viewNames,
    entityName: entityReader.name(entityDef),
  });

  let currentRecordType = tget(entityViewConfiguration, ENTITY_VIEW_CONFIG_KEYS.ENTITY_NAME);
  if (!_isEmpty(entityRecord)) {
    // The below fallback helps when Entity View viewer is getting mount from action hoc
    // in such case it might not contain recordTypeId in entityRecord
    currentRecordType = tget(entityRecord, SYSTEM_DEFINED_FIELDS.RECORD_TYPE_ID, currentRecordType);
  } else if (viewType === VIEW_TYPES.LIST_VIEW) {
    if (!_isEmpty(viewRecordTypeMetadataList)) {
      currentRecordType = tget(getArraySafeValue(viewRecordTypeMetadataList), 'recordTypeName', currentRecordType);
    }
  }

  const currentViewConfig = tget(viewConfigsByName, tget(recordTypeVsViewMapper, currentRecordType));
  const updatedViewConfig = getUpdatedViewConfig(viewOverrides, currentViewConfig);
  const updatedEntityDef = getRecordTypeOverriddenEntityDef(currentRecordType, entityDef);
  const preFilledRecordSelectionFormValues = { recordTypeId: currentRecordType };
  let updatedRecords = {};

  if (viewType === VIEW_TYPES.FORM_VIEW && !_isEmpty(entityRecord)) {
    updatedRecords = getRecordsFromEntityRecord(entityRecord, entityDef, currentRecordType);
  }
  setState(
    {
      recordTypeEntityDef: updatedEntityDef,
      recordTypeVsViewMapper,
      viewConfigsByName,
      currentRecordType,
      currentViewConfig: updatedViewConfig,
      recordTypeSelectionViewName,
      preFilledRecordSelectionFormValues,
      recordTypeSelectionRecord: tget(updatedRecords, 'recordTypeSelectionRecord', EMPTY_OBJECT),
      formData: tget(updatedRecords, 'formData', entityRecord),
    },
    async () => {
      await fetchRelatedDefinitions({ setState, getState });
    },
  );
};

const handleViewVariableChange = ({ getState, setState, params }) => {
  const { viewVariables } = getState();
  const { variableName, value } = params;
  setState({ viewVariables: { ...viewVariables, [variableName]: value } });
};

const handleRecordSelectionViewChange = async ({ getState, setState, params }) => {
  const { recordTypeVsViewMapper, viewConfigsByName, viewOverrides, entityDef, formValues } = getState();
  const recordTypeId = tget(params, 'recordTypeId');

  if (recordTypeId) {
    const currentViewConfig = tget(viewConfigsByName, tget(recordTypeVsViewMapper, recordTypeId));
    const updatedViewConfig = getUpdatedViewConfig(viewOverrides, currentViewConfig);
    const updatedEntityDef = getRecordTypeOverriddenEntityDef(recordTypeId, entityDef);

    setState({
      currentRecordType: recordTypeId,
      recordSelectionFormValues: params,
      currentViewConfig: updatedViewConfig,
      recordTypeEntityDef: updatedEntityDef,
      formValues: { ...formValues, recordTypeId },
    });
  } else {
    setState({ recordSelectionFormValues: params });
  }
};

const handleFormValueChange = ({ setState, params }) => {
  setState({ formValues: params });
};

const handleFormSubmit = ({ getState, params }) => {
  const { onFormSubmit = _noop } = getState();
  const { recordTypeFormValues, additional } = params;

  const payload = {
    ..._omit(additional, 'entity'),
    ..._omit(recordTypeFormValues, 'entity'),
    entity: {
      ...tget(additional, 'entity'),
      ...tget(recordTypeFormValues, 'entity'),
    },
  };

  onFormSubmit(payload);
};

const handleOnBlur = ({ getState }) => {
  const { formValues = {}, recordSelectionFormValues = {}, onFormFieldBlur = _noop } = getState();

  onFormFieldBlur({ ...formValues, ...recordSelectionFormValues });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: handleInit,
  [ACTION_TYPES.ON_VIEW_VARIABLE_CHANGE]: handleViewVariableChange,
  [ACTION_TYPES.ON_RECORD_SELECTION_FIELD_CHANGE]: handleRecordSelectionViewChange,
  [ACTION_TYPES.ON_FORM_VALUE_CHANGE]: handleFormValueChange,
  [ACTION_TYPES.ON_FORM_SUBMIT]: handleFormSubmit,
  [ACTION_TYPES.ON_BLUR]: handleOnBlur,
};

export default ACTION_HANDLERS;
