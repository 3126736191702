const FETCH_CHART_DATA = 'FETCH_CHART_DATA';
const APPLY_FILTERS = 'APPLY_FILTERS';
const INIT = 'INIT';
const ON_SELECT_CHART_AREA = 'SELECT_CHART_AREA';

export default {
  FETCH_CHART_DATA,
  APPLY_FILTERS,
  INIT,
  ON_SELECT_CHART_AREA,
};
