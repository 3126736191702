import _property from 'lodash/property';

const createShareAndManageRecordModalContextValue = (wwdData, onShareAndManageRecordModalOpen) => ({
  onShareAndManageRecordModalOpen,
  wwdData,
});

const getOnShareAndManageRecordModalOpen = _property('onShareAndManageRecordModalOpen');

const getWwdData = _property('wwdData');

const shareAndManageRecordContextGetters = {
  getOnShareAndManageRecordModalOpen,
  getWwdData,
};

export { createShareAndManageRecordModalContextValue, shareAndManageRecordContextGetters };
