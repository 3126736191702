import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { TOASTER_TYPE, toaster } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { getSettingByName } from '../../../../../../../actions/approvalManagement.actions';

import { ACTION_TYPES } from '../constants/approvalSettingDetails.constants';

const handleInit = async ({ getState, setState }) => {
  const { history, match } = getState();
  const approvalSettingFromState = _get(history, 'location.state.approvalSetting');
  const settingName = _get(match, 'params.settingName');

  if (!_isEmpty(approvalSettingFromState)) {
    setState({ isLoading: false, approvalSetting: approvalSettingFromState });
  } else if (settingName) {
    setState({ isLoading: true });
    const approvalSetting = await getSettingByName(settingName);

    if (!_isEmpty(approvalSetting)) {
      setState({ isLoading: false, approvalSetting });
    }
  } else {
    toaster(TOASTER_TYPE.ERROR, __('Unable to fetch Approval Setting, please try again later.'));
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
};

export default ACTION_HANDLERS;
