import _get from 'lodash/get';

import SwitchCellRenderer from '../renderers/SwitchCellRenderer';
import { ACCESS_TYPE_MAPPER, COLUMN_IDS } from '../constants/wwdPermissionList.constants';

const getAccesTypeAccessor = (rowInfo) => {
  const accessType = _get(rowInfo, 'accessType');
  return ACCESS_TYPE_MAPPER[accessType];
};

const COLUMNS = [
  {
    id: COLUMN_IDS.ENTITY_NAME,
    Header: __('Entity Name'),
    accessor: COLUMN_IDS.ENTITY_NAME,
  },
  {
    id: COLUMN_IDS.ACCESS_TYPE,
    Header: __('Access Type'),
    accessor: getAccesTypeAccessor,
  },
  {
    Header: __('Role Hierarchy'),
    accessor: COLUMN_IDS.ROLE_HIERARCHY,
    resizable: false,
    fixed: 'right',
    Cell: SwitchCellRenderer,
  },
];

export default COLUMNS;
