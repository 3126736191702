import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

// Components
import InputTable from '@tekion/tekion-components/organisms/inputTable/containers/withRowActions/Table';
import Heading from '@tekion/tekion-components/atoms/Heading';

// Constants
import { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { getColumns } from './helpers/entityFieldPermissionTable.columns';

import { PERMISSION_TYPE } from './constants/entityFieldPermissionTable.constants';
import styles from './entityFieldPermissionTable.module.scss';

const EntityFieldPermissionTable = ({ data, permissionTableType, columnHeaderCheckbox, disabledFieldPermissionIds, onAction }) => {
  const columns = useMemo(
    () => getColumns(permissionTableType, onAction, data, columnHeaderCheckbox, disabledFieldPermissionIds),
    [permissionTableType, data, columnHeaderCheckbox, disabledFieldPermissionIds, onAction],
  );
  const handleFormHeader = useMemo(
    () => (permissionTableType === PERMISSION_TYPE.ENTITY ? __('Entity Permissions') : __('Field Permissions')),
    [permissionTableType],
  );
  return (
    <div className={styles.entityFieldPermissionContainer}>
      <Heading className={styles.headingContainer}>{handleFormHeader}</Heading>
      <InputTable columns={columns} onAction={onAction} value={data} />
    </div>
  );
};

EntityFieldPermissionTable.propTypes = {
  permissionTableType: PropTypes.string,
  columnHeaderCheckbox: PropTypes.object,
  data: PropTypes.array,
  disabledFieldPermissionIds: PropTypes.array,
  onAction: PropTypes.func,
};

EntityFieldPermissionTable.defaultProps = {
  permissionTableType: EMPTY_STRING,
  columnHeaderCheckbox: EMPTY_OBJECT,
  data: EMPTY_ARRAY,
  disabledFieldPermissionIds: EMPTY_ARRAY,
  onAction: _noop,
};

export default EntityFieldPermissionTable;
