exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".recordTypeDetailForm_spinnerWrap__oi9DrLyaPS{display:flex;justify-content:center;align-items:center;width:100%;height:100%}.recordTypeDetailForm_headerNavigationContainer__hk7jYqSgnX{display:flex;flex-flow:row;font-family:\"Proxima-Nova-Regular\"}.recordTypeDetailForm_customSelect__d9SjD5UtQn{width:70%}[dir] .recordTypeDetailForm_customSelect__d9SjD5UtQn .ant-select-selection__choice{padding-top:2px}.recordTypeDetailForm_customSelect__d9SjD5UtQn .ant-select-selection__choice__remove{top:-1px}[dir=ltr] .recordTypeDetailForm_customSelect__d9SjD5UtQn .ant-select-selection__choice__remove{right:-1px}[dir=rtl] .recordTypeDetailForm_customSelect__d9SjD5UtQn .ant-select-selection__choice__remove{left:-1px}[dir] .recordTypeDetailForm_formContainer__bHqajDKNAJ{margin-top:2rem}", ""]);

// Exports
exports.locals = {
	"spinnerWrap": "recordTypeDetailForm_spinnerWrap__oi9DrLyaPS",
	"headerNavigationContainer": "recordTypeDetailForm_headerNavigationContainer__hk7jYqSgnX",
	"customSelect": "recordTypeDetailForm_customSelect__d9SjD5UtQn",
	"formContainer": "recordTypeDetailForm_formContainer__bHqajDKNAJ"
};