import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _uniq from 'lodash/uniq';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _has from 'lodash/has';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../actions/conditionBuilder.actions';
import { fetchEntityRecords } from '../../../../../actions/recordManagement.actions';
import { fetchReport } from '../../../../../actions/reporting.actions';
import { getTransformedData, getTransformedColumns, createFiltersObject, getFieldDef } from './tableWidget.helpers';

import ACTION_TYPES from '../constants/tableWidget.actionTypes';
import { STANDARD_ENTITY_NAME } from '../../../../../constants/general.constants';
import FIELD_TYPES from '../../../../../constants/fieldDefinition.fieldTypes';
import { COLUMN_TYPES, RELATIONSHIP_FIELD_COLUMN_DATA } from '../../../constants/reporting.general.constants';
import userReader from '../../../../../readers/loginResponse.reader';
import fieldDefinitionReader from '../../../../../readers/fieldDefinition.reader';

const resolveData = async (data, column, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject) => {
  const entityRecordPromises = [];
  const columnData = [];

  _forEach(column, (item) => {
    if (_get(item, 'type') === COLUMN_TYPES.DIMENSION) {
      const fieldValue = _get(item, 'reportingDimension.field');
      const fieldDef = getFieldDef(fieldValue, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject);

      if (fieldDefinitionReader.fieldType(fieldDef) === FIELD_TYPES.RELATIONSHIP) {
        const ids = [];
        const columnName = _get(item, 'name');
        _forEach(data, (dataItem) => {
          if (_get(dataItem, columnName)) {
            ids.push(_get(dataItem, columnName));
          }
        });
        if (_isEmpty(ids)) {
          return {};
        } else {
          const field = fieldDefinitionReader.lookupField(fieldDef) || 'id';
          const payload = {
            filters: [
              {
                field,
                values: _uniq(ids),
                filterType: OPERATORS.IN,
              },
            ],
          };
          const entityName = fieldDefinitionReader.lookupFieldEntityType(fieldDef);
          if (entityName) {
            columnData.push({
              [RELATIONSHIP_FIELD_COLUMN_DATA.DISPLAY_FIELD]: fieldDefinitionReader.lookupFieldDisplayField(fieldDef),
              columnName,
              field,
            });
            entityRecordPromises.push(fetchEntityRecords(entityName, payload));
          }
        }
      }
    }
    return {};
  });

  const allPromises = await Promise.all(entityRecordPromises);
  let updatedData = [...data];

  _forEach(columnData, (item, index) => {
    const dataByIds = _keyBy(tget(allPromises[index], 'hits', EMPTY_ARRAY), _get(item, RELATIONSHIP_FIELD_COLUMN_DATA.FIELD));
    updatedData = _map(updatedData, (dataItem) => {
      const value = _get(dataByIds, _get(dataItem, _get(item, RELATIONSHIP_FIELD_COLUMN_DATA.COLUMN_NAME)));
      const newData = { ...dataItem };
      if (_get(value, 'entityName') === STANDARD_ENTITY_NAME.USER) {
        _set(newData, _get(item, RELATIONSHIP_FIELD_COLUMN_DATA.COLUMN_NAME), value ? userReader.name(value) : __('User deleted'));
      } else if (_has(value, _get(item, RELATIONSHIP_FIELD_COLUMN_DATA.DISPLAY_FIELD))) {
        _set(newData, _get(item, RELATIONSHIP_FIELD_COLUMN_DATA.COLUMN_NAME), _get(value, _get(item, RELATIONSHIP_FIELD_COLUMN_DATA.DISPLAY_FIELD)));
      } else {
        _set(
          newData,
          _get(item, RELATIONSHIP_FIELD_COLUMN_DATA.COLUMN_NAME),
          _get(value, `entity.${_get(item, RELATIONSHIP_FIELD_COLUMN_DATA.DISPLAY_FIELD)}`),
        );
      }

      return newData;
    });
  });

  return updatedData;
};

const handleTableDataFetch = async ({ setState, getState }) => {
  const {
    requestPayload,
    widget,
    entityName,
    currentPage = 0,
    selectedFilters = EMPTY_ARRAY,
    timeRange = EMPTY_OBJECT,
    aggregateAfterKey: aggregateAfterOriginal,
    bucketSize = 50,
  } = getState();

  setState({ loading: true });

  const filters = createFiltersObject(selectedFilters);
  if (!_isEmpty(timeRange)) {
    filters.push({ field: 'createdTime', operator: OPERATORS.BTW, values: [_get(timeRange, 'startTime'), _get(timeRange, 'endTime')] });
  }

  const mapOfVariableToEntityName = { $record: entityName };
  let conditionBuilderFieldDefinitionObject = _get(widget, 'conditionBuilderFieldDefinitionObject', EMPTY_OBJECT);
  if (_isEmpty(conditionBuilderFieldDefinitionObject)) {
    conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityName, {}, false, false);
  }

  const { rows, columns, aggregateAfterKey } = await fetchReport({
    columns: requestPayload,
    entityName,
    bucketSize,
    aggregateAfterKey: currentPage === 0 ? null : aggregateAfterOriginal,
    filters,
  });

  const data = await resolveData(
    getTransformedData(rows, columns, mapOfVariableToEntityName, conditionBuilderFieldDefinitionObject),
    requestPayload,
    mapOfVariableToEntityName,
    conditionBuilderFieldDefinitionObject,
  );
  const transformedColumns = getTransformedColumns(columns);

  setState({
    data,
    columns: transformedColumns,
    loading: false,
    aggregateAfterKey,
  });
};

const handlePageUpdate = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { page, resultsPerPage } = _get(params, 'value');
  const { previousPageTokens, pageToken, currentPage = 0, bucketSize = 50, aggregateAfterKey } = getState();

  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = aggregateAfterKey;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }

  if (bucketSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1 > 0 ? pageNo - 1 : 0,
      bucketSize: resultsPerPage,
      pageToken: currentPageToken,
      aggregateAfterKey: currentPageToken,
      previousPageTokens: prevPageTokens,
    },
    () => {
      handleTableDataFetch({ setState, getState });
    },
  );
};

const handleTableSetFilter = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const selectedFilterGroup = _get(params, 'selectedFilterGroup');
  const filters = _get(params, 'value');
  setState({ selectedFilters: filters, currentPage: 0, selectedFilterGroup }, () => handleTableDataFetch({ setState, getState }));
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: handleTableDataFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleTableDataFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleTableSetFilter,
};

export default ACTION_HANDLERS;
