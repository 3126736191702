import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _unionBy from 'lodash/unionBy';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { COMPONENT_TYPES } from '../../../constants/viewBuilder.constants';
import { COMPONENT_CONFIG_KEYS, VIEW_OVERRIDE_FIELD_IDS } from '../../../../visualBuilder/constants/visualBuilder.general.constants';
import { ALL_VIEW_PROPERTY_KEYS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../../constants/viewBuilder.constants';

const getMergedFilterData = (gridViewConfig, viewOverrides) => {
  const filterMetaDataOfViews = _get(
    gridViewConfig,
    `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.FILTER_META_DATA}`,
    EMPTY_ARRAY,
  );
  const filterMetaDataOfWidget = _get(viewOverrides, 'filterMetadata', EMPTY_ARRAY);
  const mergedFilterData = _unionBy(filterMetaDataOfViews, filterMetaDataOfWidget, 'fieldName');
  // if we have filterable true in both view and widget, we will consider upfront value of view
  return mergedFilterData;
};

const getMergedPreAppliedFilters = (gridViewConfig, viewOverrides) => {
  const preAppliedFilterOfViews = _get(
    gridViewConfig,
    `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.PRE_APPLIED_FILTERS}`,
    EMPTY_ARRAY,
  );
  const preAppliedFilterOfWidget = _get(viewOverrides, 'preAppliedFilters', EMPTY_ARRAY);
  const mergedFilterData = _unionBy(preAppliedFilterOfWidget, preAppliedFilterOfViews, 'field');
  // if we have pre applied filter in both view and widget, we will consider value of widget pre applied filter
  return mergedFilterData;
};

const updateGridViewConfigurationToAddPreAppliedFilters = (gridViewConfiguration, viewOverrides) => {
  if (_isEmpty(gridViewConfiguration)) {
    return;
  }

  const type = _get(gridViewConfiguration, 'type');
  if (type === COMPONENT_TYPES.GRID_VIEW || type === COMPONENT_TYPES.LIST_VIEW_RENDERER) {
    _set(
      gridViewConfiguration,
      `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.PRE_APPLIED_FILTERS}`,
      getMergedPreAppliedFilters(gridViewConfiguration, viewOverrides),
    );
    _set(
      gridViewConfiguration,
      `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${ALL_VIEW_PROPERTY_KEYS.FILTER_META_DATA}`,
      getMergedFilterData(gridViewConfiguration, viewOverrides),
    );
    return;
  }

  _forEach(_get(gridViewConfiguration, 'children', []), (config) => {
    updateGridViewConfigurationToAddPreAppliedFilters(config, viewOverrides);
  });
};
const updateGridViewConfigurationToAddSimilarViewDetails = (gridViewConfiguration, similarViewDetails) => {
  if (_isEmpty(gridViewConfiguration)) {
    return;
  }

  const type = _get(gridViewConfiguration, 'type');
  if (type === COMPONENT_TYPES.GRID_VIEW || type === COMPONENT_TYPES.LIST_VIEW_RENDERER) {
    _set(gridViewConfiguration, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${VIEW_OVERRIDE_FIELD_IDS.SIMILAR_VIEW_DETAILS}`, similarViewDetails);
    return;
  }

  _forEach(_get(gridViewConfiguration, COMPONENT_CONFIG_KEYS.CHILDREN, []), (config) => {
    updateGridViewConfigurationToAddSimilarViewDetails(config, similarViewDetails);
  });
};
export { updateGridViewConfigurationToAddPreAppliedFilters, updateGridViewConfigurationToAddSimilarViewDetails };
