const KEY_BOARD_KEYS = {
  CONTROL_KEY: 'Control',
  ENTER_KEY: 'Enter',
  LESS_THAN_KEY: '<',
  GREATER_THAN_KEY: '>',
  ESCAPE_KEY: 'Escape',
  SPACE: ' ',
  BACKSPACE_KEY: 'Backspace',
  DELETE_KEY: 'Delete',
  TAB_KEY: 'Tab',
  ARROW_LEFT_KEY: 'ArrowLeft',
  ARROW_RIGHT_KEY: 'ArrowRight',
  ARROW_UP_KEY: 'ArrowUp',
  ARROW_DOWN_KEY: 'ArrowDown',
  HASH: '#',
};

const KEY_BOARD_EVENT_KEYS = {
  KEY_DOWN: 'keydown',
  KEY_UP: 'keyup',
};

const KEY_BOARD_INPUT_TYPE_KEYS = {
  INSERT_TEXT: 'insertText',
  DELETE_CONTENT_BACKWARD: 'deleteContentBackward',
  DELETE_CONTENT_FORWARD: 'deleteContentForward',
  INSERT_PARAGRAPH: 'insertParagraph',
};

const KEY_BOARD_KEY_CODES = {
  ENTER_KEY_CODE: 13,
  BACKSPACE_KEY_CODE: 8,
  Y_KEY_CODE: 89,
  Z_KEY_CODE: 90,
  FORMATTING_KEY_CODES: [66, 73, 85], // B - Bold, I - Italic, U - Underline
};
export { KEY_BOARD_KEYS, KEY_BOARD_EVENT_KEYS, KEY_BOARD_INPUT_TYPE_KEYS, KEY_BOARD_KEY_CODES };
