import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _noop from 'lodash/noop';

import { tget } from '@tekion/tekion-base/utils/general';
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import AsyncPaginatedSelect from '../../../asyncPaginatedSelect/AsyncPaginatedSelect';

import { searchViewConfigurations } from '../../../../actions/viewBuilderPage.actions';
import {
  getValuesFromProperties,
  getRelatedEntityAndFieldOptions,
  getUpdatedExpandableRowConfigValue,
  getOptions,
  getPayload,
} from './expandableRowConfigForm.helper';

import { FIELD_IDS, FIELD_LABELS, LIST_VIEW_CONFIG_PROPERTY_KEYS } from './expandableRowConfigForm.constants';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './expandableRowConfigForm.module.scss';

const ExpandableRowConfigForm = ({ entity, selectedViewComponentProperties, errors, onAction }) => {
  const ref = useRef(null);

  const { relatedEntityOptions, relatedEntityFieldOptions } = useMemo(() => getRelatedEntityAndFieldOptions(entity), [entity]);
  const { value, expandableRowsEnabled } = useMemo(() => getValuesFromProperties(selectedViewComponentProperties), [selectedViewComponentProperties]);
  const relatedEntity = tget(value, [FIELD_IDS.RELATED_ENTITY]);

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { id, value: fieldValue } = payload;
          const checked = id === LIST_VIEW_CONFIG_PROPERTY_KEYS.EXPANDABLE_ROWS_ENABLED ? fieldValue : expandableRowsEnabled;
          const newPayload = id === LIST_VIEW_CONFIG_PROPERTY_KEYS.EXPANDABLE_ROWS_ENABLED ? {} : { [id]: fieldValue };

          const expandableRowConfigValues = getUpdatedExpandableRowConfigValue(selectedViewComponentProperties, checked, newPayload);

          onAction({
            type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
            payload: { id: ALL_VIEW_PROPERTY_KEYS.EXPANDABLE_ROW_CONFIG_FORM, value: expandableRowConfigValues },
          });

          break;
        }
        default:
          onAction(action);
      }
    },
    [expandableRowsEnabled, selectedViewComponentProperties, onAction],
  );

  const getPayloadForRelatedEntity = useMemo(() => getPayload(relatedEntity), [relatedEntity]);

  useEffect(() => {
    const resetLoadedOptions = tget(ref, 'current.resetLoadedOptions', _noop);
    resetLoadedOptions();
  }, [relatedEntity]);

  return (
    <div className={styles.checkBoxComponent}>
      <CheckboxRenderer
        checked={expandableRowsEnabled}
        id={LIST_VIEW_CONFIG_PROPERTY_KEYS.EXPANDABLE_ROWS_ENABLED}
        checkboxLabel={__('Show Related Entity in List View')}
        onAction={handleAction}
      />
      <PropertyControlledComponent controllerProperty={expandableRowsEnabled}>
        <div>
          <div className={styles.selectSection}>
            <Select
              required
              id={FIELD_IDS.RELATED_ENTITY}
              className={styles.inputSelect}
              label={FIELD_LABELS[FIELD_IDS.RELATED_ENTITY]}
              value={value?.[FIELD_IDS.RELATED_ENTITY]}
              error={errors?.[FIELD_IDS.RELATED_ENTITY]}
              options={relatedEntityOptions}
              onAction={handleAction}
            />
            <Select
              required
              disabled={_isEmpty(_get(value, FIELD_IDS.RELATED_ENTITY))}
              id={FIELD_IDS.RELATED_FIELD}
              className={styles.inputSelect}
              label={FIELD_LABELS[FIELD_IDS.RELATED_FIELD]}
              value={value?.[FIELD_IDS.RELATED_FIELD]}
              error={errors?.[FIELD_IDS.RELATED_FIELD]}
              options={relatedEntityFieldOptions[value?.[FIELD_IDS.RELATED_ENTITY]] || []}
              onAction={handleAction}
            />
          </div>

          <AsyncPaginatedSelect
            required
            isDisabled={_isEmpty(_get(value, FIELD_IDS.RELATED_ENTITY))}
            id={FIELD_IDS.VIEW_NAME}
            label={__('View')}
            ref={ref}
            fieldClassName={styles.asyncSelect}
            value={value?.[FIELD_IDS.VIEW_NAME]}
            error={errors?.[FIELD_IDS.VIEW_NAME]}
            serviceHandler={searchViewConfigurations}
            getOptions={getOptions}
            getPayload={getPayloadForRelatedEntity}
            onAction={handleAction}
          />
        </div>
      </PropertyControlledComponent>
    </div>
  );
};

ExpandableRowConfigForm.propTypes = {
  entity: PropTypes.object.isRequired,
  selectedViewComponentProperties: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ExpandableRowConfigForm.defaultProps = {
  selectedViewComponentProperties: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default ExpandableRowConfigForm;
