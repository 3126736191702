import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { fetchEntityAccessForUsers } from '../../../../../../../../../../../actions/shareAndManageRecord.actions';

import { getPayloadForUsersEntityPermission, getUserDetailForSharingRecord } from './useShareRecordForm.general';

const handleFieldChange = ({ payload = EMPTY_OBJECT, setFormValues }) => {
  const { id, value } = payload;
  setFormValues((prev) => ({ ...prev, [id]: value }));
};

const handleErrors = ({ payload = EMPTY_OBJECT, setFormErrors }) => {
  const { errors } = payload;
  setFormErrors(errors);
};

const handleSubmit = async ({
  setIsUserAdditionInProgress,
  formValues,
  setFormValues,
  setFormErrors,
  onUserAddition,
  entityRecord,
  entityDef,
  allUsersById,
}) => {
  const { entityName, recordType, payload } = getPayloadForUsersEntityPermission(entityDef, entityRecord, formValues);

  setIsUserAdditionInProgress(true);

  const entityPermissionsForUsers = await fetchEntityAccessForUsers(entityName, recordType, payload);
  const usersDetailForSharingRecord = getUserDetailForSharingRecord(allUsersById, entityPermissionsForUsers, formValues);

  onUserAddition(usersDetailForSharingRecord);
  setIsUserAdditionInProgress(false);
  setFormValues({});
  setFormErrors({});
};

const actionHandlers = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
};

export default actionHandlers;
