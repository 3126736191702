/* eslint-disable max-len */
const CUSTOM_STYLE_IDS = {
  CONTAINER: 'container',
  TITLE: 'title',
  CONTENT: 'content',
  ROW: 'row',
  CARD_STYLE: 'cardStyle',
};

const CUSTOM_STYLE_LABEL_MAP = {
  [CUSTOM_STYLE_IDS.CONTAINER]: __('Container'),
  [CUSTOM_STYLE_IDS.TITLE]: __('Title'),
  [CUSTOM_STYLE_IDS.CONTENT]: __('Content'),
  [CUSTOM_STYLE_IDS.ROW]: __('Row'),
  [CUSTOM_STYLE_IDS.CARD_STYLE]: __('Card Style'),
};

const CUSTOM_STYLE_TYPES = {
  SCRIPT: 'SCRIPT',
  STYLES_OBJECT: 'STYLES_OBJECT',
};

const CUSTOM_STYLE_TYPE_LABEL_MAP = {
  [CUSTOM_STYLE_TYPES.SCRIPT]: __('Script'),
  [CUSTOM_STYLE_TYPES.STYLES_OBJECT]: __('Styles Object'),
};

const CUSTOM_STYLE_SCRIPT_FALLBACK = 'return {}';

const CUSTOM_STYLES_FIELD_IDS = {
  TYPE: 'type',
  VALUE: 'value',
  METADATA: 'metadata',
};

const HELPER_CUSTOM_STYLES_INITIAL_VALUE_FOR_MONACO_EDITOR =
  '/* $record:- This variable provides us with the populated record value. In case of detail view it will a single record value object and in case of list/grid view will contain array of records. This can be used to access different field of the populated record */\n/* Variables:- This is an object that contains two key get,set each holds a function reference. This can be used in the following way:- */\n/* Variables.get(variableName) this will return us the value of the variableName passed */\n/* Variables.set(variableName,variableValue) this will set the given value for the mentioned variableName */\n';

const HELPER_RICH_TEXT_EDITOR_INITIAL_VALUE_FOR_CUSTOM_STYLES_OBJECT = '{ "color": "red", "fontSize": "1.2rem", "marginLeft": "1rem" }';

export {
  CUSTOM_STYLE_IDS,
  CUSTOM_STYLE_LABEL_MAP,
  CUSTOM_STYLE_TYPES,
  CUSTOM_STYLE_TYPE_LABEL_MAP,
  CUSTOM_STYLE_SCRIPT_FALLBACK,
  CUSTOM_STYLES_FIELD_IDS,
  HELPER_CUSTOM_STYLES_INITIAL_VALUE_FOR_MONACO_EDITOR,
  HELPER_RICH_TEXT_EDITOR_INITIAL_VALUE_FOR_CUSTOM_STYLES_OBJECT,
};
