import { defaultMemoize } from 'reselect';

import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../../../../../../../../../../../constants/viewBuilder.constants';
import entityReader from '../../../../../../../../../../../../../../../readers/entity.reader';

const getPayloadForViewDefinitionsSearch = defaultMemoize((entityNames, viewTypes) => ({ nextPageToken, searchText }) => {
  const filters = [
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      values: entityNames,
      filterType: OPERATORS.IN,
    },
  ];

  if (!_isEmpty(viewTypes)) {
    filters.push({
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
      values: viewTypes,
      filterType: OPERATORS.IN,
    });
  }

  if (!_isEmpty(searchText)) {
    filters.push({
      field: 'name',
      values: [searchText],
      filterType: OPERATORS.TEXT_STARTS_WITH,
    });
  }
  return { filters, nextPageToken };
});

const getPayloadForEntitySearch = defaultMemoize((selectedEntities) => ({ nextPageToken, searchText }) => {
  const filters = [];
  if (!_isEmpty(selectedEntities)) {
    filters.push({
      field: 'name',
      filterType: OPERATORS.NIN,
      values: selectedEntities,
    });
  }

  if (!_isEmpty(searchText)) {
    filters.push({
      field: 'name',
      values: [searchText],
      filterType: OPERATORS.TEXT_STARTS_WITH,
    });
  }
  return { filters, nextPageToken };
});

const getValueOption = defaultMemoize((viewConfig) => ({
  label: tget(viewConfig, 'displayName', EMPTY_STRING) || tget(viewConfig, 'name', EMPTY_STRING),
  value: tget(viewConfig, 'id', EMPTY_STRING),
}));

const getViewsOptions = defaultMemoize((views) => _map(views, (viewConfig) => getValueOption(viewConfig)));

const getEntitiesOptions = defaultMemoize((entities) =>
  _map(entities, (entity) => ({ label: entityReader.displayName(entity), value: entityReader.name(entity) })),
);

export { getViewsOptions, getPayloadForViewDefinitionsSearch, getEntitiesOptions, getPayloadForEntitySearch };
