import { compose, withProps } from 'recompose';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Button from '@tekion/tekion-components/atoms/Button';
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';

import LeftSubHeaderSection from '../components/leftSubheaderSection/LeftSubHeaderSection';
import { CUSTOM_ACTIONS, COLUMN_ACCESSORS, STATUS_TO_LABEL, STATUS_TO_COLOR } from './recordTypeList.constants';
import { RECORD_TYPES } from '../../../../../constants/general.constants';

const StatusCellRenderer = compose(
  makeCellRenderer,
  withProps({
    labelMap: STATUS_TO_LABEL,
    colorMap: STATUS_TO_COLOR,
  }),
)(StatusRenderer);

const COLUMNS = [
  {
    Header: __('Name'),
    accessor: COLUMN_ACCESSORS.NAME,
    id: COLUMN_ACCESSORS.NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Display Name'),
    accessor: COLUMN_ACCESSORS.DISPLAY_NAME,
    id: COLUMN_ACCESSORS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    Header: __('Description'),
    accessor: COLUMN_ACCESSORS.DESCRIPTION,
    id: COLUMN_ACCESSORS.DESCRIPTION,
    Cell: TextRenderer,
  },
  {
    Header: __('Status'),
    accessor: COLUMN_ACCESSORS.STATUS,
    id: COLUMN_ACCESSORS.STATUS,
    Cell: StatusCellRenderer,
  },
];

const getSubHeaderProps = (onAction, recordType, derivationFields) => {
  let disable = false;
  if (recordType === RECORD_TYPES.DERIVED && _isEmpty(derivationFields)) {
    disable = true;
  }
  return {
    subHeaderRightActions: [
      {
        renderer: Button,
        renderOptions: {
          view: Button.VIEW.PRIMARY,
          label: __('Create New'),
          disabled: disable,
        },
        action: CUSTOM_ACTIONS.CREATE_NEW,
      },
    ],
    subHeaderLeftActions: [
      {
        renderer: LeftSubHeaderSection,
        renderOptions: {
          recordType,
          derivationFields,
          onAction,
        },
      },
    ],
  };
};

const TABLE_MANAGER_PROPS = {
  showSearch: false,
  showFilter: false,
  showHeader: false,
  showSubHeader: true,
  showRefreshIcon: true,
};

const EDIT_ACTION = {
  id: 'EDIT',
  name: __('Edit'),
};

const ACTIVATE_ACTION = {
  id: 'ACTIVATE',
  name: __('Activate RecordType'),
};

const DEACTIVATE_ACTION = {
  id: 'DEACTIVATE',
  name: __('Deactivate RecordType'),
};

const getRow = (rowData) => {
  const active = _get(rowData, 'active', '');
  const ACTIONS = active ? [EDIT_ACTION, DEACTIVATE_ACTION] : [EDIT_ACTION, ACTIVATE_ACTION];
  return ACTIONS;
};

const getTableProps = (handleRowAction, loading, totalNumberOfEntries) => ({
  loading,
  minRows: 0,
  showPagination: false,
  rows: totalNumberOfEntries,
  totalNumberOfEntries,
  showActions: true,
  onRowActionClick: handleRowAction,
  getRowActions: getRow,
});

export { COLUMNS, getSubHeaderProps, TABLE_MANAGER_PROPS, getTableProps };
