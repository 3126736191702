import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _concat from 'lodash/concat';
import _reject from 'lodash/reject';
import _find from 'lodash/find';
import _noop from 'lodash/noop';
import _map from 'lodash/map';

import InfiniteScroll from 'react-infinite-scroller';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

// Components
import WithSize from '../../../../../../../../connectors/withSize';
import NotificationSubHeader from '../../atoms/notificationSubHeader';
import NotificationGroup from '../../molecules/notificationGroup';
import NotificationCardSkeleton from '../../atoms/notificationCardSkeleton';
import EmptyNotification from '../../atoms/emptyNotification';

// Actions
import communicationsActions from '../../../../../../../../actions/communications.actions';

// Constants
import { NOTIFICATION_FIELD_IDS, NOTIFICATION_GROUP_FIELD_IDS, NOTIFICATION_TYPES } from '../../../../../../../../constants/notification.constants';

import styles from './notificationGroupList.module.scss';

const DEFAULT_LIMIT = 10;
const DEFAULT_OFFSET = 0;

const NotificationGroupList = ({ showUnreadOnly, contentHeight, filterType, onFilterChange }) => {
  const [expandedGroupKey, setExpandedGroup] = useState();
  const [groupedNotifications, setGroupedNotifications] = useState([]);
  const [{ isInitialLoading, isInitialErrored, isInitialFetched }, setInitialFetchingState] = useState({});
  const [isScrollLoading, setIsScrollLoading] = useState(false);

  const [{ limit, offset, hasMore }, setPaginationState] = useState({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET, hasMore: false });

  const loadGroupedNotifications = async () => {
    setInitialFetchingState({ isInitialLoading: true, isInitialErrored: false, isInitialFetched: false });
    const groupedNotificationsPayload = { limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET, unread: showUnreadOnly };
    const { hits, isErrored } = await communicationsActions.fetchGroupedNotifications(groupedNotificationsPayload);

    setGroupedNotifications(hits);
    setPaginationState({ limit: DEFAULT_LIMIT, offset: DEFAULT_LIMIT + DEFAULT_OFFSET, hasMore: !_isEmpty(hits) });
    setInitialFetchingState({ isInitialErrored: isErrored, isInitialFetched: true, isInitialLoading: false });
  };

  const loadMoreGroupedNotifications = async () => {
    if (isInitialFetched && !isScrollLoading) {
      setIsScrollLoading(true);
      const payload = { limit, offset, unread: showUnreadOnly };
      const { hits, isErrored } = await communicationsActions.fetchGroupedNotifications(payload);

      if (!isErrored) {
        setGroupedNotifications((_cur) => _concat(_cur, hits));
        setPaginationState({ limit, offset: limit + offset, hasMore: !_isEmpty(hits) });
      }
      setIsScrollLoading(false);
    }
  };

  const removeGroup = useCallback(
    (groupKey) =>
      setGroupedNotifications((_groupedNotifications) =>
        _reject(_groupedNotifications, (groupNotification) => {
          const _groupKey = tget(groupNotification, [NOTIFICATION_GROUP_FIELD_IDS.COMMUNICATION, NOTIFICATION_FIELD_IDS.GROUP_KEY]);

          if (!_isEmpty(_groupKey) && _groupKey === groupKey) {
            return true;
          }

          return false;
        }),
      ),
    [],
  );

  const setUnreadCountZero = useCallback((groupKey) => {
    setGroupedNotifications((_groupedNotifications) =>
      _map(_groupedNotifications, (groupNotification) => {
        const _groupKey = tget(groupNotification, [NOTIFICATION_GROUP_FIELD_IDS.COMMUNICATION, NOTIFICATION_FIELD_IDS.GROUP_KEY]);

        if (!_isEmpty(_groupKey) && _groupKey === groupKey) {
          return {
            ...groupNotification,
            [NOTIFICATION_GROUP_FIELD_IDS.UNREAD_COUNT]: 0,
          };
        }

        return groupNotification;
      }),
    );
  }, []);

  const handleMarkAllAsRead = async () => {
    const response = await communicationsActions.markAllNotificationsAsRead(NOTIFICATION_TYPES.NUDGE);

    if (!_isEmpty(response)) {
      loadGroupedNotifications();
    }
  };

  const handleClearAll = async () => {
    const response = await communicationsActions.clearAllNotifications(NOTIFICATION_TYPES.NUDGE);

    if (!_isEmpty(response)) {
      loadGroupedNotifications();
    }
  };

  const handleGroupExpand = useCallback((groupKey) => {
    setExpandedGroup(groupKey);
  }, []);

  useEffect(() => {
    loadGroupedNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUnreadOnly]);

  const renderGroupListContent = () => {
    if (isInitialLoading || (!isInitialFetched && !isInitialErrored)) {
      return <NotificationCardSkeleton />;
    }

    if (isInitialErrored || (isInitialFetched && _isEmpty(groupedNotifications))) {
      return <EmptyNotification />;
    }

    if (expandedGroupKey) {
      const expandedNotificationGroup = _find(groupedNotifications, (notificationGroup) => {
        const groupKey = tget(notificationGroup, [NOTIFICATION_GROUP_FIELD_IDS.COMMUNICATION, NOTIFICATION_FIELD_IDS.GROUP_KEY]);
        return groupKey === expandedGroupKey;
      });

      if (!_isEmpty(expandedNotificationGroup)) {
        return (
          <NotificationGroup
            key={expandedGroupKey}
            isGroupExpanded
            showUnreadOnly={showUnreadOnly}
            filterType={filterType}
            notificationGroup={expandedNotificationGroup}
            removeGroup={removeGroup}
            setUnreadCountZero={setUnreadCountZero}
            onGroupExpand={handleGroupExpand}
          />
        );
      }
    }

    return (
      <div style={{ height: contentHeight }} className={styles.listContainer}>
        <InfiniteScroll hasMore={hasMore} useWindow={false} pageStart={0} loadMore={loadMoreGroupedNotifications} loader={NotificationCardSkeleton}>
          {_map(groupedNotifications, (notificationGroup, index) => {
            const topNotification = tget(notificationGroup, NOTIFICATION_GROUP_FIELD_IDS.COMMUNICATION);
            const groupKey = tget(topNotification, NOTIFICATION_FIELD_IDS.GROUP_KEY, index);
            return (
              <NotificationGroup
                key={groupKey}
                showUnreadOnly={showUnreadOnly}
                filterType={filterType}
                notificationGroup={notificationGroup}
                removeGroup={removeGroup}
                setUnreadCountZero={setUnreadCountZero}
                onGroupExpand={handleGroupExpand}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    );
  };

  const isActionDisabled = isInitialErrored || (isInitialFetched && _isEmpty(groupedNotifications));

  return (
    <>
      <NotificationSubHeader
        isActionDisabled={isActionDisabled}
        filterType={filterType}
        onFilterChange={onFilterChange}
        onClearAll={handleClearAll}
        onMarkAllAsRead={handleMarkAllAsRead}
      />
      {renderGroupListContent()}
    </>
  );
};

NotificationGroupList.propTypes = {
  showUnreadOnly: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  filterType: PropTypes.string,
  onFilterChange: PropTypes.func,
};

NotificationGroupList.defaultProps = {
  showUnreadOnly: false,
  filterType: EMPTY_STRING,
  onFilterChange: _noop,
};

export default WithSize({ hasPageHeader: true, headerHeight: 200 })(NotificationGroupList);
