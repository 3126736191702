import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import Heading from '@tekion/tekion-components/atoms/Heading';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Loader from '@tekion/tekion-components/molecules/loader';
import Tabs from '@tekion/tekion-components/molecules/Tabs';

import ApprovalRequestsList from '../../../../../../../../organisms/approvalManagementPages/approvalRequestsList/ApprovalRequestsList';
import ApprovalTasksList from '../../../../../../../../organisms/approvalManagementPages/approvalTasksList/ApprovalTasksList';

// Actions
import { fetchGroups, fetchCategories } from '../../../../../../../../actions/approvalManagement.actions';

// Constants
import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../../../../../constants/approvalCentre.constants';
import { TAB_IDS } from './constants/approvalPreviewComponent.constants';
import { DEFAULT_OPTIONS } from '../../../../../../../../connectors/withSize';

// Styles
import styles from './approvalPreviewComponent.module.scss';

const optionGenerator = (response, getter) => {
  if (_isEmpty(response) || _isEmpty(getter)) return [];

  const hits = tget(response, 'hits', EMPTY_ARRAY);

  return _map(hits, (item) => ({
    label: tget(item, getter),
    value: tget(item, getter),
  }));
};

const ApprovalPreviewComponent = ({ isMountedInsideApplication, contentHeight, contentWidth, applicationProperties, currentLoggedInUserData }) => {
  const [isMetadataLoading, setIsMetadataLoading] = useState(false);
  const [metadata, setMetadata] = useState({ GROUPS: [], CATEGORIES: [] });
  const [selectedTabId, setSelectedTabId] = useState();

  const tabStyles = useMemo(() => {
    if (contentWidth > 0) {
      return { width: contentWidth };
    }
    return {};
  }, [contentWidth]);

  const handleTabChange = useCallback((tabKey) => setSelectedTabId(tabKey), []);

  const fetchMetadata = useCallback(async () => {
    setIsMetadataLoading(true);

    const groupsResponse = await fetchGroups({ rows: 5000 });
    const categoriesResponse = await fetchCategories({ rows: 5000 });

    setMetadata({
      GROUPS: optionGenerator(groupsResponse, APPROVAL_CENTRE_FIELD_IDS.GROUP),
      CATEGORIES: optionGenerator(categoriesResponse, APPROVAL_CENTRE_FIELD_IDS.CATEGORY),
    });
    setIsMetadataLoading(false);
  }, []);

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  useEffect(() => {
    setSelectedTabId(TAB_IDS.APPROVALS);
  }, []);

  if (isMetadataLoading) {
    return <Loader id="APPROVAL_CENTRE" />;
  }

  return (
    <Page>
      <Page.Header contentClassName={styles.headerContainer}>
        <Heading>{__('Approval Centre')}</Heading>
      </Page.Header>
      <Tabs style={tabStyles} activeKey={selectedTabId} onChange={handleTabChange}>
        <Tabs.TabPane key={TAB_IDS.APPROVALS} tab={__('Approvals')}>
          <ApprovalTasksList
            isMountedInsideApplication={isMountedInsideApplication}
            contentHeight={contentHeight - DEFAULT_OPTIONS.headerHeight}
            currentLoggedInUserData={currentLoggedInUserData}
            filterMetadata={metadata}
            applicationProperties={applicationProperties}
            onRowClick={_noop}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key={TAB_IDS.REQUESTS} tab={__('Requests')}>
          <ApprovalRequestsList
            isMountedInsideApplication={isMountedInsideApplication}
            contentHeight={contentHeight - DEFAULT_OPTIONS.headerHeight}
            currentLoggedInUserData={currentLoggedInUserData}
            filterMetadata={metadata}
            applicationProperties={applicationProperties}
            onRowClick={_noop}
          />
        </Tabs.TabPane>
      </Tabs>
    </Page>
  );
};

ApprovalPreviewComponent.propTypes = {
  isMountedInsideApplication: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  contentWidth: PropTypes.number.isRequired,
  currentLoggedInUserData: PropTypes.object,
  applicationProperties: PropTypes.object,
};

ApprovalPreviewComponent.defaultProps = {
  isMountedInsideApplication: false,
  currentLoggedInUserData: EMPTY_OBJECT,
  applicationProperties: EMPTY_OBJECT,
};

export default ApprovalPreviewComponent;
