exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".loginConfigurationsDashboard_buttonContainer__hobZf8M7dV{display:flex;width:100%;height:100%}.loginConfigurationsDashboard_providerTile__dZjQnHmRXH{height:15rem;width:15rem;display:flex;justify-content:center;align-items:center}[dir] .loginConfigurationsDashboard_providerTile__dZjQnHmRXH{margin:2rem;box-shadow:0 .2rem .6rem #969aa3;border-radius:.8rem;border:0;cursor:pointer}[dir] .loginConfigurationsDashboard_providerTile__dZjQnHmRXH:hover{border-color:#000;box-shadow:0 .4rem .8rem #969aa3}.loginConfigurationsDashboard_providerTile__dZjQnHmRXH .loginConfigurationsDashboard_header__dw3dPJDV7Q{display:flex;flex-direction:column;align-items:center}.loginConfigurationsDashboard_loader__6SaYZ5z1ds{height:100%;display:flex;align-items:center;justify-content:center}", ""]);

// Exports
exports.locals = {
	"buttonContainer": "loginConfigurationsDashboard_buttonContainer__hobZf8M7dV",
	"providerTile": "loginConfigurationsDashboard_providerTile__dZjQnHmRXH",
	"header": "loginConfigurationsDashboard_header__dw3dPJDV7Q",
	"loader": "loginConfigurationsDashboard_loader__6SaYZ5z1ds"
};