import { defaultMemoize } from 'reselect';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _map from 'lodash/map';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';

import { EDIT_ACTION } from '../constants/wwdPermissionList.rowActions';
import { COLUMN_IDS } from '../constants/wwdPermissionList.constants';
import { TEXT_STARTS_WITH } from '../../../../../constants/filter.constants';

const getTableProps = defaultMemoize((handleRowAction, currentPage, loading, rows, totalNumberOfEntries) => ({
  currentPage: currentPage + 1,
  loading,
  showPagination: true,
  pageSize: rows,
  resultsPerPage: rows,
  totalNumberOfEntries,
  minRows: 0,
  showActions: true,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  rows,
  onRowActionClick: handleRowAction,
  getRowActions: () => [EDIT_ACTION],
}));

const getPayload = defaultMemoize((searchText, pageSize, nextPageToken) => {
  let payload = { rows: pageSize };
  if (!_isEmpty(searchText)) {
    payload = {
      ...payload,
      filters: [
        {
          field: COLUMN_IDS.ENTITY_NAME,
          values: [searchText],
          filterType: TEXT_STARTS_WITH,
        },
      ],
    };
  }
  if (!_isEmpty(nextPageToken)) {
    payload = {
      ...payload,
      nextPageToken,
    };
  }
  return payload;
});

const getEntityPayload = (wwdData, pageSize) => {
  const values = _map(wwdData, (data) => _get(data, 'entityName'));
  return {
    rows: pageSize,
    filters: [
      {
        field: 'name',
        values,
        filterType: OPERATORS.IN,
      },
    ],
  };
};

export { getTableProps, getPayload, getEntityPayload };
