import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import withActions from '@tekion/tekion-components/connectors/withActions';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';

import AssignModal from '../assignModal';
import withSize from '../../../../../../../connectors/withSize';

import { fetchEntities } from '../../../../../../../actions/entityManagement.actions';
import { getColumnConfig } from './helpers/entitySettings.columns';
import { getTableProps } from './helpers/entitySettings.helpers';
import getSubHeaderProps from './helpers/entitySettings.subHeaderProps';
import ACTION_HANDLERS from './helpers/entitySettings.actionHandler';

import ACTION_TYPES from './constants/entitySettings.actionTypes';
import { TABLE_MANAGER_PROPS } from './constants/entitySettings.constants';
import { TABS } from '../../constants/permissionSetConfigure.constants';

const EntitySettings = ({
  loading,
  isAddEntityModalVisible,
  isAddEntityModalDataSubmitting,
  pageSize,
  currentPage,
  totalCount,
  contentHeight,
  resolvedEntityNamesToDisplayNameMap,
  filteredPermissionData,
  entitiesDropdownOptions,
  permissionData,
  selectedEntityModalData,
  onAction,
}) => {
  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({
        type: actionType,
        payload,
      });
    },
    [onAction],
  );

  const columnConfig = useMemo(() => getColumnConfig(resolvedEntityNamesToDisplayNameMap), [resolvedEntityNamesToDisplayNameMap]);

  const tableProps = useMemo(
    () => getTableProps(currentPage, totalCount, loading, pageSize, handleRowAction),
    [handleRowAction, currentPage, pageSize, loading, totalCount],
  );

  const subHeaderProps = useMemo(() => getSubHeaderProps(totalCount), [totalCount]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.INIT_DATA,
    });
  }, [onAction, permissionData]);

  return (
    <Page>
      <Page.Body style={{ height: contentHeight - 188 }}>
        <TableManager
          columns={columnConfig}
          tableManagerProps={TABLE_MANAGER_PROPS}
          tableProps={tableProps}
          subHeaderProps={subHeaderProps}
          data={filteredPermissionData}
          onAction={onAction}
        />
        <AssignModal
          isAssignModalVisible={isAddEntityModalVisible}
          isAssignModalSubmitting={isAddEntityModalDataSubmitting}
          modalTitle={__('Add Entity')}
          selectDropdownOptions={entitiesDropdownOptions}
          selectedDropdownData={selectedEntityModalData}
          getOptionsApi={fetchEntities}
          tabType={TABS.ENTITY_SETTINGS}
          existingData={filteredPermissionData}
          onAction={onAction}
        />
      </Page.Body>
    </Page>
  );
};
EntitySettings.propTypes = {
  loading: PropTypes.bool,
  isAddEntityModalVisible: PropTypes.bool,
  isAddEntityModalDataSubmitting: PropTypes.bool,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  contentHeight: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  permissionData: PropTypes.object,
  resolvedEntityNamesToDisplayNameMap: PropTypes.object,
  filteredPermissionData: PropTypes.array,
  entitiesDropdownOptions: PropTypes.array,
  selectedEntityModalData: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

EntitySettings.defaultProps = {
  loading: false,
  isAddEntityModalVisible: false,
  isAddEntityModalDataSubmitting: false,
  pageSize: DEFAULT_PAGE_INFO.rows,
  currentPage: DEFAULT_PAGE_INFO.start,
  totalCount: 0,
  permissionData: EMPTY_OBJECT,
  resolvedEntityNamesToDisplayNameMap: EMPTY_OBJECT,
  filteredPermissionData: EMPTY_ARRAY,
  entitiesDropdownOptions: EMPTY_ARRAY,
  selectedEntityModalData: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 0 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(EntitySettings);
