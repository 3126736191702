import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _get from 'lodash/get';

import { Draggable } from '@tekion/tekion-components/organisms/dragAndDrop';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import Ellipsis from '@tekion/tekion-components/atoms/Ellipsis';
import Button from '@tekion/tekion-components/atoms/Button';
import Content from '@tekion/tekion-components/atoms/Content';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { DELAY_TASK_TAB_IDS } from '../constants/delayTaskModal.tabs';

import styles from '../delayTaskModal.module.scss';

const TabHeader = ({ index, selectedTabId, tab, onAdd, onDelete }) => {
  const tabId = _get(tab, 'id', '');
  const tabLabel = _get(tab, 'displayName', '');

  const handleDeleteTab = useCallback(() => onDelete(tab), [onDelete, tab]);
  const handleAddTab = useCallback(() => onAdd(tab), [onAdd, tab]);

  const renderTabsHeader = useCallback(
    (provided) => {
      if (selectedTabId === tabId) {
        return (
          <div className={styles.draggableContainer} ref={provided.innerRef} {...provided.draggableProps}>
            <div>
              <FontIcon size={SIZES.S} className={styles.dragIcon} {...provided.dragHandleProps}>
                icon-drag-and-drop
              </FontIcon>
            </div>
            <div className={styles.textContent}>
              <Content className={styles.contentStyle}>
                <Ellipsis length={15} tooltip>
                  {tabLabel}
                </Ellipsis>
              </Content>
            </div>
            <div className="flex">
              <FontIcon size={SIZES.S} onClick={handleDeleteTab}>
                icon-trash
              </FontIcon>
            </div>
          </div>
        );
      } else {
        return (
          <div className={styles.draggableContainer} ref={provided.innerRef} {...provided.draggableProps}>
            <Content className={styles.contentStyle}>
              <Ellipsis length={15} tooltip>
                {tabLabel}
              </Ellipsis>
            </Content>

            <FontIcon size={SIZES.S} onClick={handleDeleteTab}>
              icon-trash
            </FontIcon>
          </div>
        );
      }
    },
    [handleDeleteTab, selectedTabId, tabId, tabLabel],
  );

  if (
    tabId === DELAY_TASK_TAB_IDS.TIME_DELAY ||
    tabId === DELAY_TASK_TAB_IDS.ADD_DELAY_EXIT_EVENT ||
    tabId === DELAY_TASK_TAB_IDS.ADD_WORKFLOW_EXIT_EVENT
  ) {
    return (
      <Button className={styles.button} onClick={handleAddTab} view={Button.VIEW.TERTIARY}>
        {tabLabel}
      </Button>
    );
  }

  return (
    <Draggable key={tabId} draggableId={tabId} index={index}>
      {(provided) => renderTabsHeader(provided)}
    </Draggable>
  );
};

TabHeader.propTypes = {
  index: PropTypes.number.isRequired,
  editTabId: PropTypes.string,
  selectedTabId: PropTypes.string,
  tab: PropTypes.object,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
};

TabHeader.defaultProps = {
  editTabId: EMPTY_STRING,
  selectedTabId: EMPTY_STRING,
  tab: EMPTY_OBJECT,
  onDelete: _noop,
  onAdd: _noop,
};

export default TabHeader;
