import { setValueInLocalStorage } from './localStorage';
import LS_CACHE_KEYS from '../constants/localStorage.cacheKeys';
import LOG_IN_DATA from '../constants/logInData.constants';
import logInResponseReader from '../readers/loginResponse.reader';
import localStorageReader from '../readers/localStorage.reader';

const updateUserDataFromLoginData = (loginData) => {
  const currentUserInfoFromLS = localStorageReader.userInfo();
  const updatedUserData = { ...loginData, [LOG_IN_DATA.WORKSPACE_ID]: logInResponseReader.workspaceId(currentUserInfoFromLS) };
  setValueInLocalStorage(LS_CACHE_KEYS.USER, JSON.stringify(updatedUserData));
};

export { updateUserDataFromLoginData };
