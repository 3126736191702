import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Tree } from 'antd';
import cx from 'classnames';

import _castArray from 'lodash/castArray';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import withActions from '@tekion/tekion-components/connectors/withActions';
import { Col, Row } from '@tekion/tekion-components/molecules/Grid';
import LoadingSpinner from '@tekion/tekion-components/molecules/loadingSpinner';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Button from '@tekion/tekion-components/atoms/Button';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import Loader from '@tekion/tekion-components/molecules/loader';
import Tooltip from '@tekion/tekion-components/atoms/tooltip';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import Modal from '@tekion/tekion-components/molecules/Modal';

import HistoryControlManager, { registerChangeToHistoryManager } from '../../atoms/historyControlManager/HistoryControlManager';
import ViewViewer from './organisms/viewViewer/ViewViewer';
import Configurator from './organisms/configurator/Configurator';
import CompoundConfigModal from './organisms/compoundConfigModal';
import ViewBuilderContext from './ViewBuilder.context';

import ACTION_HANDLERS from './helpers/viewBuilder.actionHandlers';
import { findComponentById, hasErrorInConfiguratorForm, convertViewConfigIntoTreeStructure } from './helpers/viewBuilder.helper';
import { getEmptyViewConfiguration } from './helpers/viewTypeConfiguration.helper';
import { pascalCase } from '../../helpers/general.helpers';

import { VIEW_TYPES } from './constants/viewBuilder.constants';
import { VIEW_CONFIGURATION_FIELD_IDS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../constants/viewBuilder.constants';
import ACTION_TYPES from './constants/viewBuilder.actionTypes';

import styles from './viewBuilder.module.scss';

const ViewBuilder = ({
  isEditMode,
  showCompoundConfigModal,
  showPreviewModal,
  isPreviewLoading,
  showTreeStructure,
  selectedViewComponentId,
  viewType,
  initialViewConfiguration,
  viewConfiguration,
  entity,
  entityRecord,
  viewConfigsByName,
  errors,
  usedEntityFieldNames,
  onViewConfigurationSave,
  onViewConfigurationCancel,
  onAction,
}) => {
  // It calls nesting/recursive function over view config so wrapped with useMemo
  const selectedViewComponent = useMemo(
    () => findComponentById(viewConfiguration, selectedViewComponentId),
    [viewConfiguration, selectedViewComponentId],
  );

  const selectedViewComponentDisplayName = tget(selectedViewComponent, VIEW_CONFIGURATION_FIELD_IDS.DISPLAY_NAME, NO_DATA);
  const selectedViewComponentType = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.TYPE);

  // It calls nesting/recursive function over view config so wrapped with useMemo
  const treeData = useMemo(() => convertViewConfigIntoTreeStructure(viewConfiguration), [viewConfiguration]);

  const handleComponentDrop = useCallback(
    (dropZoneComponentId, dropZonePositionIndex, droppedComponent) => {
      onAction({ type: ACTION_TYPES.ON_COMPONENT_DROP, payload: { dropZoneComponentId, dropZonePositionIndex, droppedComponent } });
    },
    [onAction],
  );

  const handleViewComponentSelect = useCallback(
    (componentId) => {
      onAction({ type: ACTION_TYPES.ON_COMPONENT_SELECT, payload: { componentId } });
    },
    [onAction],
  );

  const handleViewComponentDelete = useCallback(
    (componentId) => {
      onAction({ type: ACTION_TYPES.ON_COMPONENT_DELETE, payload: { componentId } });
    },
    [onAction],
  );

  const handleViewComponentEdit = useCallback(
    (viewComponentIdToEdit) => {
      onAction({ type: ACTION_TYPES.ON_COMPONENT_EDIT_CLICK, payload: { viewComponentIdToEdit } });
    },
    [onAction],
  );

  const handleViewConfigurationSaveOrUpdate = useCallback(() => {
    if (!hasErrorInConfiguratorForm(errors)) {
      onViewConfigurationSave(viewConfiguration);
    }
  }, [onViewConfigurationSave, errors, viewConfiguration]);

  const handelViewConfigurationCancel = useCallback(() => {
    onViewConfigurationCancel();
  }, [onViewConfigurationCancel]);

  const handleViewConfigurationPreview = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_PREVIEW_CLICK });
  }, [onAction]);

  const handlePreviewModalClose = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_PREVIEW_MODAL_CLOSE });
  }, [onAction]);

  const handleComponentConfiguratorAction = useCallback(
    (action = EMPTY_OBJECT) => {
      onAction({ type: ACTION_TYPES.ON_CONFIGURATOR_ACTION, payload: { action } });
    },
    [onAction],
  );

  const handelComponentConfigModalSave = useCallback(
    (updatedViewConfig) => {
      onAction({ type: ACTION_TYPES.ON_COMPOUND_CONFIG_MODAL_SAVE, payload: { updatedViewConfig } });
    },
    [onAction],
  );

  const handelComponentConfigModalCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_COMPOUND_CONFIG_MODAL_CANCEL });
  }, [onAction]);

  const handleTreeNodeSelect = useCallback(
    (selectedTreeNodeKeys, data) => {
      onAction({ type: ACTION_TYPES.ON_TREE_NODE_CLICK, payload: { data, selectedTreeNodeKeys } });
    },
    [onAction],
  );

  const handleToggleTreeStructureSideBar = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_VIEW_TREE_STRUCTURE_CLICK });
  }, [onAction]);

  const handleUndoRedo = useCallback(
    (configuration) => {
      onAction({ type: ACTION_TYPES.ON_UNDO_REDO, payload: { configuration } });
    },
    [onAction],
  );

  const viewBuilderContextProviderValue = useMemo(
    () => ({
      selectedViewComponentId,
      onSelect: handleViewComponentSelect,
      onDelete: handleViewComponentDelete,
      onDrop: handleComponentDrop,
      onEdit: handleViewComponentEdit,
    }),
    [selectedViewComponentId, handleViewComponentSelect, handleViewComponentDelete, handleComponentDrop, handleViewComponentEdit],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction]);

  useEffect(() => {
    if (!_isEmpty(initialViewConfiguration)) {
      registerChangeToHistoryManager(initialViewConfiguration);
    }

    onAction({ type: ACTION_TYPES.ON_INITIAL_VIEW_CONFIGURATION_CHANGE, payload: { initialViewConfiguration } });
  }, [onAction, initialViewConfiguration]);

  return (
    <ViewBuilderContext.Provider value={viewBuilderContextProviderValue}>
      <DndProvider backend={HTML5Backend}>
        <Row className={styles.viewBuilder} type="flex" gutter={10}>
          <PropertyControlledComponent controllerProperty={showTreeStructure}>
            <Col span={4} className={styles.treeView}>
              <Heading size={4} className={cx(styles.heading, styles.headingBorder)}>
                View Hierarchy
              </Heading>
              <PropertyControlledComponent
                controllerProperty={tget(viewConfiguration, 'viewType') !== VIEW_TYPES.CUSTOM_VIEW}
                fallback={<LoadingSpinner className={styles.loadingContainer} size={30} />}
              >
                <Tree
                  showLine
                  defaultExpandAll
                  showIcon={false}
                  selectedKeys={_castArray(selectedViewComponentId)}
                  treeData={treeData}
                  onSelect={handleTreeNodeSelect}
                />
              </PropertyControlledComponent>
            </Col>
          </PropertyControlledComponent>
          <Col span={showTreeStructure ? 15 : 19} className={styles.centerPanel}>
            <div>
              <HistoryControlManager className={styles.historyControlManager} state={viewConfiguration} onUndoRedo={handleUndoRedo} />
              <div className={styles.headingContainer}>
                <Tooltip placement="topRight" title={showTreeStructure ? __('Hide Hierarchy Panel') : __('Show Hierarchy Panel')}>
                  <FontIcon isHoverAndFocusOutlineEnabled className={styles.treeStructureIcon} onClick={handleToggleTreeStructureSideBar}>
                    {showTreeStructure ? 'icon-arrow-left-circled' : 'icon-arrow-right-circled'}
                  </FontIcon>
                </Tooltip>
                <Heading size={4} className={styles.heading}>
                  View Viewer
                </Heading>
              </div>
              <div style={{ overflowY: 'auto', height: '95%' }}>
                <ViewViewer
                  isPreviewMode={false}
                  selectedViewComponentId={selectedViewComponentId}
                  entity={entity}
                  entityRecord={entityRecord}
                  viewConfiguration={viewConfiguration}
                  viewConfigurationsByName={viewConfigsByName}
                />
              </div>
            </div>
          </Col>
          <Col span={5} className={styles.rightPanel}>
            <div>
              <Heading size={4} className={cx(styles.heading, styles.headingBorder)}>
                Configurator {selectedViewComponentType ? `for ${pascalCase(selectedViewComponentType)}` : null}
              </Heading>

              <Configurator
                viewType={viewType}
                componentDisplayName={selectedViewComponentDisplayName}
                selectedViewComponent={selectedViewComponent}
                entity={entity}
                errors={errors}
                viewConfiguration={viewConfiguration}
                usedEntityFieldNames={usedEntityFieldNames}
                handleComponentConfiguratorAction={handleComponentConfiguratorAction}
              />

              <div className={styles.toolbar}>
                <Button view={Button.VIEW.SECONDARY} className={styles.btnPreview} onClick={handleViewConfigurationPreview}>
                  {__('Preview')}
                </Button>
                <div>
                  <Button view={Button.VIEW.PRIMARY} className={styles.cancel} onClick={handelViewConfigurationCancel}>
                    {__('Exit')}
                  </Button>
                  <Button view={Button.VIEW.PRIMARY} className={styles.btnSave} onClick={handleViewConfigurationSaveOrUpdate}>
                    {isEditMode ? __('Update') : __('Save')}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </DndProvider>

      <Modal visible={showPreviewModal} showFooter={false} title={__('Preview')} width="90%" onCancel={handlePreviewModalClose}>
        {isPreviewLoading ? (
          <Loader />
        ) : (
          <ViewViewer
            entity={entity}
            entityRecord={entityRecord}
            viewConfiguration={viewConfiguration}
            viewConfigurationsByName={viewConfigsByName}
          />
        )}
      </Modal>

      <PropertyControlledComponent controllerProperty={showCompoundConfigModal}>
        <CompoundConfigModal
          showCompoundConfigModal={showCompoundConfigModal}
          entity={entity}
          onCompoundConfigModalSave={handelComponentConfigModalSave}
          onCompoundConfigModalCancel={handelComponentConfigModalCancel}
          viewConfiguration={viewConfiguration}
          selectedViewComponent={selectedViewComponent}
        />
      </PropertyControlledComponent>
    </ViewBuilderContext.Provider>
  );
};

ViewBuilder.propTypes = {
  isEditMode: PropTypes.bool,
  showCompoundConfigModal: PropTypes.bool,
  showPreviewModal: PropTypes.bool,
  isPreviewLoading: PropTypes.bool,
  showTreeStructure: PropTypes.bool,
  selectedViewComponentId: PropTypes.string,
  viewType: PropTypes.string,
  viewConfiguration: PropTypes.object,
  entity: PropTypes.object,
  entityRecord: PropTypes.object,
  initialViewConfiguration: PropTypes.object,
  onViewConfigurationCancel: PropTypes.func.isRequired,
  viewConfigsByName: PropTypes.object,
  errors: PropTypes.object,
  usedEntityFieldNames: PropTypes.array,
  onViewConfigurationSave: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

ViewBuilder.defaultProps = {
  isEditMode: false,
  showCompoundConfigModal: false,
  showPreviewModal: false,
  isPreviewLoading: false,
  showTreeStructure: false,
  selectedViewComponentId: EMPTY_STRING,
  viewType: VIEW_TYPES.CUSTOM_VIEW,
  viewConfiguration: EMPTY_OBJECT,
  entity: undefined,
  entityRecord: undefined,
  viewConfigsByName: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  usedEntityFieldNames: EMPTY_ARRAY,
  initialViewConfiguration: getEmptyViewConfiguration(),
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(ViewBuilder);
