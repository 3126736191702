const TAB_POSITION_OPTIONS_VALUES = {
  TOP: 'TOP',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  BOTTOM: 'BOTTOM',
};

const TAB_SIZE_OPTIONS_VALUES = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
};

const TAB_POSITION_OPTIONS = [
  { label: __('Top'), value: TAB_POSITION_OPTIONS_VALUES.TOP },
  { label: __('Left'), value: TAB_POSITION_OPTIONS_VALUES.LEFT },
  { label: __('Right'), value: TAB_POSITION_OPTIONS_VALUES.RIGHT },
  { label: __('Bottom'), value: TAB_POSITION_OPTIONS_VALUES.BOTTOM },
];

const TAB_SIZE_OPTIONS = [
  { label: __('Small'), value: TAB_SIZE_OPTIONS_VALUES.SMALL },
  { label: __('Medium'), value: TAB_SIZE_OPTIONS_VALUES.MEDIUM },
  { label: __('Large'), value: TAB_SIZE_OPTIONS_VALUES.LARGE },
];

export { TAB_POSITION_OPTIONS, TAB_SIZE_OPTIONS };
