import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';

import styles from './formViewSectionPropertiesForm.module.scss';

const FIELD_IDS = {
  WIDTH: ALL_VIEW_PROPERTY_KEYS.WIDTH,
  CUSTOM_STYLES: ALL_VIEW_PROPERTY_KEYS.CUSTOM_STYLES,
};

const SECTIONS = [
  {
    header: {
      size: 5,
      className: styles.formSectionHeader,
    },
    className: styles.formBuilderSectionContainer,
    rows: [
      {
        columns: [FIELD_IDS.CUSTOM_STYLES],
      },
    ],
  },
];

export { SECTIONS, FIELD_IDS };
