import { Transforms } from 'slate';
import { useState, useEffect, useCallback } from 'react';

const updateNodeWidth = ({ updatedWidth, nodeWidth, editor, path }) => {
  if (updatedWidth === nodeWidth) {
    Transforms.select(editor, path);
  } else {
    Transforms.setNodes(editor, { width: updatedWidth }, { at: path });
  }
};

function useMediaWidth({ nodeWidth, editor, path }) {
  const [width, setWidth] = useState(nodeWidth);

  useEffect(() => {
    setWidth(nodeWidth);
  }, [nodeWidth]);

  const handleResize = useCallback((e, direction, ref) => {
    setWidth(ref.offsetWidth);
  }, []);

  const handleResizeStop = useCallback(
    (e, direction, ref) =>
      updateNodeWidth({
        updatedWidth: ref.offsetWidth,
        nodeWidth,
        editor,
        path,
      }),
    [editor, path, nodeWidth],
  );

  return {
    width,
    handleResizeStop,
    handleResize,
  };
}

export default useMediaWidth;
