/* eslint-disable prefer-destructuring */
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _head from 'lodash/head';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { fetchActions } from '../../../../../actions/actionBuilder.actions';
import { searchViewConfigurations } from '../../../../../actions/viewBuilderPage.actions';
import { searchEntityViewConfigurations } from '../../../../../actions/entityViewDefinitions.actions';
import { fetchValidationRules } from '../../../../../actions/validationRuleBuilder.actions';
import { fetchEntities } from '../../../../../actions/entityManagement.actions';

import { VIEW_CONFIGURATION_FIELD_IDS } from '../../../../../constants/viewBuilder.constants';
import { COMPONENT_TYPE_OPTIONS_VALUES } from '../constants/metadataComponentForm.general.constants';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../constants/actionBuilder.constants';

const fetchEntitiesForAsyncSelect = (payload = EMPTY_OBJECT) => {
  const includeFieldDefinitions = _get(payload, 'includeFieldDefinitions', false);
  return fetchEntities(payload, includeFieldDefinitions);
};

const fetchValidationRulesForAsyncSelect = (payload = EMPTY_OBJECT) => {
  const { entityName } = payload;
  return fetchValidationRules(entityName, payload);
};

const getServiceHandler = (componentTypeName, entityName) => {
  let filters = [];
  if (!_isEmpty(entityName)) {
    filters.push({
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      values: [entityName],
      filterType: OPERATORS.IN,
    });
  }

  switch (componentTypeName) {
    case COMPONENT_TYPE_OPTIONS_VALUES.ACTION_DEFINITION:
      filters.push({
        field: ACTION_DEFINITION_FIELD_IDS.ACTION_LEVEL,
        values: ['ENTITY'],
        filterType: OPERATORS.IN,
      });
      return [fetchActions, filters];

    case COMPONENT_TYPE_OPTIONS_VALUES.VIEW_DEFINITION:
      filters.push({
        field: 'deleted',
        values: [false],
        filterType: OPERATORS.IN,
      });
      return [searchViewConfigurations, filters];

    case COMPONENT_TYPE_OPTIONS_VALUES.ENTITY_VIEW_DEFINITION:
      filters.push({
        field: 'deleted',
        values: [false],
        filterType: OPERATORS.IN,
      });
      return [searchEntityViewConfigurations, filters];

    case COMPONENT_TYPE_OPTIONS_VALUES.VALIDATION_RULE:
      filters = [];
      return [fetchValidationRulesForAsyncSelect, filters];

    case COMPONENT_TYPE_OPTIONS_VALUES.FIELD_DEFINITION:
      filters = [
        {
          field: VIEW_CONFIGURATION_FIELD_IDS.NAME,
          values: [entityName],
          filterType: OPERATORS.IN,
        },
      ];
      return [fetchEntitiesForAsyncSelect, filters];

    default:
      return [_noop, filters];
  }
};

const getActionIdOptionsGeneral = (data) =>
  _map(data, (actionData) => ({
    label: `${tget(actionData, 'name', NO_DATA)}`,
    value: tget(actionData, 'id'),
  }));

const getActionIdOptionsForFieldDefinition = (data) => {
  let fieldData;

  if (!_isEmpty(data)) {
    fieldData = _head(data);
  }

  const fieldDefinitions = _get(fieldData, 'fieldDefinitions', EMPTY_ARRAY);

  if (!_isEmpty(fieldDefinitions)) {
    return _map(fieldDefinitions, (actionData) => ({
      label: `${tget(actionData, 'displayName', NO_DATA)}`,
      value: tget(actionData, 'name'),
    }));
  }
  return [];
};

const getActionIdOptionForEntityViewDefinition = (data) =>
  _map(data, (actionData) => ({
    label: `${tget(actionData, 'displayName', NO_DATA)}`,
    value: tget(actionData, 'name'),
  }));

const getActionIdOptionForViewDefinition = (data) =>
  _map(data, (actionData) => ({
    label: `${tget(actionData, 'properties.title', NO_DATA)}`,
    value: tget(actionData, 'name', EMPTY_STRING),
  }));

const getActionNameOptionForActionDefinition = (data) =>
  _map(data, (actionData) => ({
    label: tget(actionData, ACTION_DEFINITION_FIELD_IDS.ACTION_DISPLAY_NAME, NO_DATA),
    value: tget(actionData, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME),
  }));

const getOptionsForAsyncSelect = (componentType) => {
  switch (componentType) {
    case COMPONENT_TYPE_OPTIONS_VALUES.FIELD_DEFINITION:
      return getActionIdOptionsForFieldDefinition;

    case COMPONENT_TYPE_OPTIONS_VALUES.ENTITY_VIEW_DEFINITION:
      return getActionIdOptionForEntityViewDefinition;

    case COMPONENT_TYPE_OPTIONS_VALUES.VIEW_DEFINITION:
      return getActionIdOptionForViewDefinition;

    case COMPONENT_TYPE_OPTIONS_VALUES.ACTION_DEFINITION:
      return getActionNameOptionForActionDefinition;

    default:
      return getActionIdOptionsGeneral;
  }
};

const getPayload =
  (filters, entityName, includeFieldDefinitions) =>
  ({ nextPageToken = EMPTY_STRING, searchText = EMPTY_STRING }) => ({
    rows: 30,
    nextPageToken,
    searchText,
    filters,
    entityName,
    includeFieldDefinitions,
  });

export { getPayload, getServiceHandler, getOptionsForAsyncSelect };
