import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _set from 'lodash/set';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

// Helpers
import { getFormViewConfiguration } from '../../../../../../../organisms/viewBuilder/helpers/viewTypeConfiguration.helper';

// Constants
import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../../../../constants/approvalCentre.constants';
import {
  CELL_TYPES,
  VIEW_TYPES,
  VIEW_CONFIGURATION_GENERAL_KEYS,
  ALL_VIEW_PROPERTY_KEYS,
} from '../../../../../../../constants/viewBuilder.constants';
import { CUSTOM_STYLE_IDS, CUSTOM_STYLE_TYPES } from '../../../../../../../constants/customStyles.constants';

// Readers
import fieldDefinitionReader from '../../../../../../../readers/fieldDefinition.reader';

// Check format here - https://tekion.atlassian.net/browse/DPF-1090
const CUSTOM_PREFERENCES_FOR_FORM_VIEW = {
  container: {
    title: __('Request details'),
    [VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES]: {
      [ALL_VIEW_PROPERTY_KEYS.CUSTOM_STYLES]: {
        [CUSTOM_STYLE_IDS.TITLE]: {
          type: CUSTOM_STYLE_TYPES.STYLES_OBJECT,
          value: {
            padding: '0 2.4rem',
          },
        },
      },
    },
  },
  containerSectionColumn: {
    [VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES]: {
      [ALL_VIEW_PROPERTY_KEYS.CUSTOM_STYLES]: {
        [CUSTOM_STYLE_IDS.CONTAINER]: {
          type: CUSTOM_STYLE_TYPES.STYLES_OBJECT,
          value: {
            background: 'none',
            padding: 0,
          },
        },
      },
    },
  },
};

const getFormViewConfigurationFromFields = (approvalSetting) => {
  const fields = tget(approvalSetting, APPROVAL_CENTRE_FIELD_IDS.FIELDS, EMPTY_ARRAY);
  const fieldsByName = _keyBy(fields, 'name');

  const viewSummary = {
    viewType: [VIEW_TYPES.FORM_VIEW],
  };

  const columns = _map(fieldsByName, (fieldDef) => ({
    cellType: CELL_TYPES.SIMPLE,
    title: fieldDefinitionReader.displayName(fieldDef),
    fieldNames: [fieldDefinitionReader.name(fieldDef)],
    template: `\${${fieldDefinitionReader.name(fieldDef)}}`,
    mandatory: fieldDefinitionReader.mandatory(fieldDef),
    creatable: true,
    editable: true,
    disabled: false,
  }));

  _set(viewSummary, 'columns', columns);

  return getFormViewConfiguration(viewSummary, approvalSetting, CUSTOM_PREFERENCES_FOR_FORM_VIEW);
};

export { getFormViewConfigurationFromFields };
