import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import RolesHierarchyManagementServices from '../services/rolesHierarchy.services';

const getRoleByName = async (roleName) => {
  try {
    const receivedData = await RolesHierarchyManagementServices.getRoleByName(roleName);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch Role data, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const getAllRoles = async () => {
  try {
    const receivedData = await RolesHierarchyManagementServices.getAllRoles();
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch Roles data, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const editRoleByName = async (roleName, payload = EMPTY_OBJECT) => {
  try {
    const receivedData = await RolesHierarchyManagementServices.editRoleByName(roleName, payload);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Edit Role data, please try again later.')));
    return null;
  }
};

const createRole = async (payload = EMPTY_OBJECT) => {
  try {
    const receivedData = await RolesHierarchyManagementServices.createRole(payload);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create Role, please try again later.')));
    return null;
  }
};

const assignUsersByRoleName = async (payload = EMPTY_OBJECT) => {
  try {
    const response = await RolesHierarchyManagementServices.assignUsersByRoleName(payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to Assign Users, please try again later.')));
    return EMPTY_OBJECT;
  }
};

const getAllRolesByWorkspaceId = async (workspaceId) => {
  try {
    const receivedData = await RolesHierarchyManagementServices.getAllRolesByWorkspaceId(workspaceId);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch Roles, please try again later.')));
    return EMPTY_ARRAY;
  }
};

const fetchRoles = async (payload) => {
  try {
    const receivedData = await RolesHierarchyManagementServices.searchRoles(payload);
    return getDataFromResponse(receivedData);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch Roles, please try again later.')));
    return EMPTY_ARRAY;
  }
};

export { createRole, getRoleByName, editRoleByName, getAllRoles, assignUsersByRoleName, getAllRolesByWorkspaceId, fetchRoles };
