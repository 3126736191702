import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { getPreSignedUrls } from '../media.actions';

const Media = {
  fetchPresignedUrls: async (mediaIds = []) => {
    if (_isEmpty(mediaIds)) {
      return EMPTY_ARRAY;
    }
    const response = await getPreSignedUrls(_castArray(mediaIds));

    return response;
  },
};

export default Media;
