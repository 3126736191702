import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import communicationsActions from '../../../../../../../../actions/communications.actions';

import NotificationCardList from '../notificationCardList';
import NotificationGroupHeader from './NotificationGroupHeader';

import { NOTIFICATION_FIELD_IDS, NOTIFICATION_GROUP_FIELD_IDS } from '../../../../../../../../constants/notification.constants';

import styles from './notificationGroup.module.scss';

const NotificationGroup = ({ isGroupExpanded, showUnreadOnly, filterType, notificationGroup, removeGroup, setUnreadCountZero, onGroupExpand }) => {
  const groupKey = tget(notificationGroup, [NOTIFICATION_GROUP_FIELD_IDS.COMMUNICATION, NOTIFICATION_FIELD_IDS.GROUP_KEY]);

  const handleGroupExpand = () => {
    if (isGroupExpanded) {
      onGroupExpand(undefined);
    } else {
      onGroupExpand(groupKey);
    }
  };

  const handleClearGroupClick = async () => {
    const response = await communicationsActions.clearGroupNotifications(groupKey);

    if (!_isEmpty(response)) {
      removeGroup(groupKey);
    }
  };

  const handleMarkGroupAsRead = async () => {
    const response = await communicationsActions.markGroupAsRead(groupKey);

    if (!_isEmpty(response)) {
      setUnreadCountZero(groupKey);
    }
  };

  return (
    <div className={styles.groupContainer}>
      <NotificationGroupHeader
        isGroupExpanded={isGroupExpanded}
        notificationGroup={notificationGroup}
        onClearGroupClick={handleClearGroupClick}
        onMarkGroupAsReadClick={handleMarkGroupAsRead}
        onGroupExpandClick={handleGroupExpand}
      />
      <NotificationCardList
        isGroupExpanded={isGroupExpanded}
        showUnreadOnly={showUnreadOnly}
        cardContainerClassName={styles.cardContainer}
        filterType={filterType}
        notificationGroup={notificationGroup}
      />
    </div>
  );
};

NotificationGroup.propTypes = {
  isGroupExpanded: PropTypes.bool,
  showUnreadOnly: PropTypes.bool,
  filterType: PropTypes.string,
  notificationGroup: PropTypes.object.isRequired,
  removeGroup: PropTypes.func,
  setUnreadCountZero: PropTypes.func,
  onGroupExpand: PropTypes.func,
};

NotificationGroup.defaultProps = {
  isGroupExpanded: false,
  showUnreadOnly: false,
  filterType: EMPTY_STRING,
  removeGroup: _noop,
  setUnreadCountZero: _noop,
  onGroupExpand: _noop,
};

export default React.memo(NotificationGroup);
