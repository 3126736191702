const COMPONENT_FORM_CONTEXT_ID = 'ADD_COMPONENT_FORM';

const COMPONENT_TYPE_OPTIONS_VALUES = {
  ACTION_DEFINITION: 'ACTION_DEFINITION',
  VIEW_DEFINITION: 'VIEW_DEFINITION',
  ENTITY_DEFINITION: 'ENTITY_DEFINITION',
  FIELD_DEFINITION: 'FIELD_DEFINITION',
  ENTITY_VIEW_DEFINITION: 'ENTITY_VIEW_DEFINITION',
  VALIDATION_RULE: 'VALIDATION_RULE',
};

const COMPONENT_TYPE_OPTIONS = [
  {
    label: __('Action Definition'),
    value: COMPONENT_TYPE_OPTIONS_VALUES.ACTION_DEFINITION,
  },
  {
    label: __('View Definition'),
    value: COMPONENT_TYPE_OPTIONS_VALUES.VIEW_DEFINITION,
  },
  {
    label: __('Field Definition'),
    value: COMPONENT_TYPE_OPTIONS_VALUES.FIELD_DEFINITION,
  },
  {
    label: __('Entity Definition'),
    value: COMPONENT_TYPE_OPTIONS_VALUES.ENTITY_DEFINITION,
  },
  {
    label: __('Entity View Definition'),
    value: COMPONENT_TYPE_OPTIONS_VALUES.ENTITY_VIEW_DEFINITION,
  },
  {
    label: __('Validation Rule'),
    value: COMPONENT_TYPE_OPTIONS_VALUES.VALIDATION_RULE,
  },
];

export { COMPONENT_FORM_CONTEXT_ID, COMPONENT_TYPE_OPTIONS, COMPONENT_TYPE_OPTIONS_VALUES };
