import api from "!../../../../../../../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[4].use[1]!../../../../../../../../../../../../node_modules/postcss-loader/src/index.js??ruleSet[1].rules[0].oneOf[4].use[2]!../../../../../../../../../../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[4].use[3]!./backgroundColorSelector.module.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};