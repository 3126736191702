import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import {
  getConfigFormattedSelectedWidgetProperties,
  getFormFormattedSelectedWidgetProperties,
  getFilteredEntityForSelectedWidgetType,
  getFilteredRelativeFieldForSelectedEntity,
} from './helpers/entityAndViewConfigure.helpers';
import { getEntityAndViewConfigureFields } from './helpers/entityAndViewConfigure.fields';
import { getEntityAndViewConfigureSections } from './helpers/entityAndViewConfigure.sections';

import { ACTION_TYPES as VISUAL_BUILDER_ACTION_TYPES, COMPONENT_CONFIG_KEYS } from '../../../../constants/visualBuilder.general.constants';
import { ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS } from './constants/entityAndViewConfigure.fieldIds';
import { VIEW_TYPES } from '../../../../../../constants/viewBuilder.constants';
import { PAGE_TYPES } from '../../../../../../constants/visualBuilder';

const EntityAndViewConfigure = ({ pageType, selectedWidgetConfig, pageEntity, onChangeWidgetConfigurationAction }) => {
  const ref = useRef(null);

  const widgetName = useMemo(() => tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, ''), [selectedWidgetConfig]);

  const widgetType = useMemo(() => tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_TYPE, ''), [selectedWidgetConfig]);

  const entityAsyncSelectFilter = useMemo(() => {
    const filteredEntityForSelectedWidgetType = getFilteredEntityForSelectedWidgetType(widgetType, pageEntity);
    let updatedEntityAsyncSelectFilter = [];
    if (!_isEmpty(filteredEntityForSelectedWidgetType)) {
      updatedEntityAsyncSelectFilter = [{ field: 'name', filterType: OPERATORS.IN, values: filteredEntityForSelectedWidgetType }];
    }

    return updatedEntityAsyncSelectFilter;
  }, [widgetType, pageEntity]);

  const selectedWidgetProperties = useMemo(() => tget(selectedWidgetConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, {}), [selectedWidgetConfig]);

  const entityName = useMemo(() => tget(selectedWidgetProperties, ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.ENTITY_NAME, ''), [selectedWidgetProperties]);

  const filteredRelativeFieldsNameOptions = useMemo(
    () => getFilteredRelativeFieldForSelectedEntity(entityName, widgetType, pageEntity),
    [widgetType, pageEntity, entityName],
  );

  const viewType = useMemo(
    () => tget(selectedWidgetProperties, ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW_TYPE, VIEW_TYPES.LIST_VIEW),
    [selectedWidgetProperties],
  );

  const formFormattedSelectedWidgetProperties = getFormFormattedSelectedWidgetProperties(selectedWidgetProperties, widgetType);

  const fields = useMemo(
    () =>
      getEntityAndViewConfigureFields(ref, entityName, widgetType, viewType, entityAsyncSelectFilter, filteredRelativeFieldsNameOptions, pageType),
    [ref, entityName, widgetType, entityAsyncSelectFilter, viewType, filteredRelativeFieldsNameOptions, pageType],
  );

  const sections = useMemo(() => getEntityAndViewConfigureSections(widgetType), [widgetType]);

  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload = EMPTY_OBJECT } = action;
    switch (type) {
      case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
        const { id, value } = payload;
        const configFormattedSelectedWidgetProperties = getConfigFormattedSelectedWidgetProperties(value, id, selectedWidgetProperties, widgetType);

        onChangeWidgetConfigurationAction({
          type: VISUAL_BUILDER_ACTION_TYPES.UPDATE_WIDGET_CONFIGURATION,
          payload: {
            value: { widgetConfiguration: { ...selectedWidgetConfig, properties: { ...configFormattedSelectedWidgetProperties } }, widgetName },
          },
        });
        break;
      }
      default:
    }
  };

  useEffect(() => {
    const resetLoadedOptions = tget(ref, 'current.resetLoadedOptions', _noop);
    resetLoadedOptions();
  }, [formFormattedSelectedWidgetProperties]);

  return <FormBuilder sections={sections} fields={fields} values={formFormattedSelectedWidgetProperties} onAction={handleAction} />;
};

EntityAndViewConfigure.propTypes = {
  pageType: PropTypes.object,
  selectedWidgetConfig: PropTypes.object,
  pageEntity: PropTypes.object,
  onChangeWidgetConfigurationAction: PropTypes.func,
};

EntityAndViewConfigure.defaultProps = {
  pageType: PAGE_TYPES.HOME_PAGE,
  selectedWidgetConfig: undefined,
  pageEntity: undefined,
  onChangeWidgetConfigurationAction: _noop,
};

export default EntityAndViewConfigure;
