import { useCallback, useState } from 'react';

function useImagePreview() {
  const [showImagePreview, setShowImagePreview] = useState(false);

  const openImagePreview = useCallback(() => setShowImagePreview(true), []);

  const closeImagePreview = useCallback(() => setShowImagePreview(false), []);

  return {
    showImagePreview,
    closeImagePreview,
    openImagePreview,
  };
}

export default useImagePreview;
