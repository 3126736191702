import _reduce from 'lodash/reduce';

import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';
import COLUMN_IDS from './approvalRequestsList.columnIds';

import { APPROVAL_STATUS_LABELS, APPROVAL_STATUS_TYPES } from '../../../../constants/approvalCentre.constants';

const STATUS_FILTER_OPTIONS = _reduce(
  APPROVAL_STATUS_TYPES,
  (result, value) => {
    if (value !== APPROVAL_STATUS_TYPES.INACTIVE) {
      result.push({ label: APPROVAL_STATUS_LABELS[value], value });
    }

    return result;
  },
  [],
);

const STATUS_FILTER = {
  id: COLUMN_IDS.STATUS,
  type: FILTER_TYPES.SINGLE_SELECT,
  name: __('Status'),
  additional: {
    options: STATUS_FILTER_OPTIONS,
  },
};

const GROUP_FILTER = {
  id: COLUMN_IDS.GROUP,
  type: FILTER_TYPES.MULTI_SELECT,
  name: __('Group'),
  additional: {
    options: [],
  },
};

const CATEGORY_FILTER = {
  id: COLUMN_IDS.CATEGORY,
  type: FILTER_TYPES.MULTI_SELECT,
  name: __('Category'),
  additional: {
    options: [],
  },
};

const DEFAULT_FILTERS_TYPES = [STATUS_FILTER.id, GROUP_FILTER.id, CATEGORY_FILTER.id];

export { STATUS_FILTER, GROUP_FILTER, CATEGORY_FILTER, DEFAULT_FILTERS_TYPES };
