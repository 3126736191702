import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { getSections } from './imagePropertiesForm.sections';
import { getFields } from './imagePropertiesForm.fields';
import { getImagePropertiesFromProperties, getUpdatedSelectedViewComponentFromOnChangeValue } from './imagePropertiesForm.helpers';

import { VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

const ImagePropertiesForm = ({ selectedViewComponent, entity, onAction }) => {
  const properties = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);
  const componentType = tget(selectedViewComponent, VIEW_CONFIGURATION_GENERAL_KEYS.TYPE);

  const sections = useMemo(() => getSections(properties), [properties]);
  const fields = useMemo(() => getFields(componentType, properties, entity), [componentType, entity, properties]);
  const imageProperties = useMemo(() => getImagePropertiesFromProperties(properties), [properties]);

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const updatedSelectedViewComponent = getUpdatedSelectedViewComponentFromOnChangeValue(selectedViewComponent, payload);
          onAction({ type, payload: updatedSelectedViewComponent });
          break;
        }

        default: {
          onAction(action);
        }
      }
    },
    [selectedViewComponent, onAction],
  );

  return <FormBuilder className="full-width" sections={sections} fields={fields} values={imageProperties} onAction={handleAction} />;
};

ImagePropertiesForm.propTypes = {
  selectedViewComponent: PropTypes.object,
  entity: PropTypes.object,
  onAction: PropTypes.func,
};

ImagePropertiesForm.defaultProps = {
  selectedViewComponent: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  onAction: _noop,
};

export default ImagePropertiesForm;
