import { useCallback } from 'react';

// hooks
import useEditor from '../../../../hooks/useEditor';

// helpers
import { showLinkDialog } from '../../../../organisms/linkDialog';
import { showLinkSelectionPopup } from '../../../../components/link';

const handleLinkButtonClick = (editor, selectedLinkNode) => {
  if (selectedLinkNode) {
    showLinkSelectionPopup(editor);
    return;
  }
  showLinkDialog(editor);
};

function useLinkButtonClick(selectedLinkNode) {
  const editor = useEditor();
  const handleClick = useCallback(() => handleLinkButtonClick(editor, selectedLinkNode), [editor, selectedLinkNode]);

  return { handleClick };
}

export default useLinkButtonClick;
