import { deleteColumn, deleteRow, ELEMENT_TABLE, ELEMENT_TD, ELEMENT_TH } from '@udecode/plate-table';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { getPlatePluginType } from '@udecode/plate-core';
import { findNode, getAbove } from '@udecode/plate-common';

// constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ACTION_TYPES from './constants/tableCellActionMenu.actionTypes';

// helpers
import insertColumn from '../../../../../../../helpers/table/richTextEditor.insertColumn';
import insertRow from '../../../../../../../helpers/table/richTextEditor.insertRow';

const handleAddRow = ({ params, getState }) => {
  const { setShowPopoverContent } = getState();
  const { editor } = params;
  const { selection } = editor;
  insertRow(editor);
  Transforms.select(editor, selection);
  ReactEditor.focus(editor);
  setShowPopoverContent(false);
};

const handleAddColumn = ({ params, getState }) => {
  const { setShowPopoverContent } = getState();
  const { editor } = params;
  const { selection } = editor;
  insertColumn(editor);
  Transforms.select(editor, selection);
  ReactEditor.focus(editor);
  setShowPopoverContent(false);
};

const handleDeleteRow = ({ params }) => {
  const { editor } = params;
  deleteRow(editor, EMPTY_OBJECT);
  ReactEditor.focus(editor);
};

const handleDeleteColumn = ({ params }) => {
  const { editor } = params;
  deleteColumn(editor, EMPTY_OBJECT);
  ReactEditor.focus(editor);
};

const handleBackgroundColorChange = ({ params, getState }) => {
  const { color, editor } = params;
  const { setShowPopoverContent } = getState();
  const cellNode = findNode(editor, {
    match: { type: [getPlatePluginType(editor, ELEMENT_TD), getPlatePluginType(editor, ELEMENT_TH)] },
  });
  if (cellNode) {
    const [, cellNodeElementPath] = cellNode;
    Transforms.setNodes(
      editor,
      { backgroundColor: color },
      {
        at: cellNodeElementPath,
      },
    );
  }
  ReactEditor.focus(editor);
  setShowPopoverContent(false);
};

const handleTableDeletion = ({ params }) => {
  const { editor } = params;
  const currentTableItem = getAbove(editor, {
    match: { type: getPlatePluginType(editor, ELEMENT_TABLE) },
  });
  if (currentTableItem) {
    const [, currentTablePath] = currentTableItem;
    Transforms.removeNodes(editor, {
      at: currentTablePath,
    });
  }
  ReactEditor.focus(editor);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ADD_ROW]: handleAddRow,
  [ACTION_TYPES.ADD_COLUMN]: handleAddColumn,
  [ACTION_TYPES.DELETE_ROW]: handleDeleteRow,
  [ACTION_TYPES.DELETE_COLUMN]: handleDeleteColumn,
  [ACTION_TYPES.CHANGE_CELL_BACKGROUND_COLOR]: handleBackgroundColorChange,
  [ACTION_TYPES.DELETE_TABLE]: handleTableDeletion,
};

export default ACTION_HANDLERS;
