import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _noop from 'lodash/noop';

import { NativeSelect as Select } from '@tekion/tekion-components/molecules/advancedSelect';
import withAsyncSelect from '@tekion/tekion-components/molecules/advancedSelect/containers/withAsyncSelect';
import { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { getOptions, getPayload } from './asyncProjectSelect.helper';
import { getWorkspaceProjectList } from '../../../../../../actions/workspaceProjectManagement.actions';

import styles from './asyncProjectSelect.module.scss';

const AsyncSelect = withAsyncSelect(Select);

const AsyncProjectSelect = ({ isClearable, value, dropDownClassName, additional, onChange, ...restProps }) => {
  const [nextPageToken, setNextPageToken] = useState(EMPTY_STRING);
  const [searchText, setSearchText] = useState(EMPTY_STRING);

  const handleLoadOptions = useCallback(
    async (inputString = EMPTY_STRING) => {
      let payload = EMPTY_OBJECT;

      if (inputString !== searchText) {
        payload = getPayload(inputString);

        setNextPageToken(EMPTY_STRING);
        setSearchText(inputString);
      } else {
        payload = getPayload(inputString, nextPageToken);
      }

      const data = await getWorkspaceProjectList(payload); // To make it generalized send action name
      const hits = tget(data, 'hits', EMPTY_ARRAY);
      const currentNextPageToken = _get(data, 'nextPageToken', null);
      const newOptions = getOptions(hits); // To make it generalized send getOptions
      const hasMore = currentNextPageToken !== null;
      setNextPageToken(currentNextPageToken);

      return { options: newOptions, hasMore };
    },
    [nextPageToken, searchText],
  );

  return (
    <div className={styles.asyncProjectSelectContainer}>
      <AsyncSelect
        {...restProps}
        refreshOptions
        openMenuOnFocus
        loadOptions={handleLoadOptions}
        value={value}
        loadingMessage={() => __('Searching...')}
        onChange={onChange}
      />
    </div>
  );
};
AsyncProjectSelect.propTypes = {
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  dropDownClassName: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  additional: PropTypes.object,
  onChange: PropTypes.func,
};

AsyncProjectSelect.defaultProps = {
  isClearable: false,
  placeholder: __('Type Here'),
  dropDownClassName: EMPTY_STRING,
  value: EMPTY_ARRAY,
  additional: EMPTY_OBJECT,
  onChange: _noop,
};

export default AsyncProjectSelect;
