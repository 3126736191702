import _get from 'lodash/get';
import _head from 'lodash/head';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { ACTION_TYPES } from '../constants/entityRecordRightPane.constants';

import { fetchEntityDefByName } from '../../../../../actions/entityManagement.actions';
import { searchViewConfigurations } from '../../../../../actions/viewBuilderPage.actions';
import { fetchEntityRecords } from '../../../../../actions/recordManagement.actions';

import { getPayloadForViewDefinitionSearch, getPayloadForEntityRecords } from './entityRecordRightPane.helpers';
import { VIEW_CONFIG_TYPES, VIEW_TYPES } from '../../../../../constants/viewBuilder.constants';
import PAGE_IDS from '../../../constants/pageIds.constants';

const handleInitData = async ({ getState, setState }) => {
  const { match, entityRecordId, history } = getState();
  const entityName = _get(match, 'params.entityName');

  const viewDefinitionPayload = getPayloadForViewDefinitionSearch([entityName], [VIEW_TYPES.RIGHT_PANE_VIEW], [VIEW_CONFIG_TYPES.STANDARD]);

  const [entity, rightPaneViewConfigResponse] = await Promise.all([
    fetchEntityDefByName(entityName),
    searchViewConfigurations(viewDefinitionPayload),
  ]);
  const rightPaneViewConfig = _head(tget(rightPaneViewConfigResponse, 'hits', EMPTY_ARRAY));
  let entityRecord = _get(history, 'location.state.entityRecord', EMPTY_OBJECT);
  if (_isEmpty(entityRecord)) {
    const entityRecordPayload = getPayloadForEntityRecords([entityRecordId], rightPaneViewConfig);
    const entityRecords = await fetchEntityRecords(entityName, entityRecordPayload);
    entityRecord = _head(tget(entityRecords, 'hits', [{}]));
  }

  setState({
    entity,
    entityRecord,
    rightPaneViewConfig,
  });
};

const handleCloseSidePane = ({ getState }) => {
  const { onDrawerClose } = getState();
  onDrawerClose();
};

const handleEditClick = ({ getState }) => {
  const { history, match, entity, entityRecordId } = getState();
  const { entityName } = _get(match, 'params', {});
  const path = `/entities/${entityName}/${PAGE_IDS.EDIT_ENTITY_RECORD}/${entityRecordId}`;

  history.push({ pathname: path, state: { entity } });
};
const handleViewClick = ({ getState }) => {
  const { history, match, entity, entityRecordId, entityRecord } = getState();
  const { entityName } = _get(match, 'params', {});
  const path = `/entities/${entityName}/${PAGE_IDS.VIEW_ENTITY_RECORD}/${entityRecordId}`;

  history.push({ pathname: path, state: { entity, entityRecord } });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT_FORM]: handleInitData,
  [ACTION_TYPES.ON_CLICK_EDIT]: handleEditClick,
  [ACTION_TYPES.ON_CLOSE_SIDE_PANE]: handleCloseSidePane,
  [ACTION_TYPES.ON_CLICK_VIEW]: handleViewClick,
};

export default ACTION_HANDLERS;
