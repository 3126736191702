import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import ConfirmationDialog from '@tekion/tekion-components/molecules/confirmationDialog';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import withActions from '@tekion/tekion-components/connectors/withActions';
import withSize from '../../../../connectors/withSize';

import ACTION_HANDLERS from './helpers/exportList.actionHandlers';
import { getFilterProps, getTableProps } from './helpers/exportList.helpers';

import { HEADER_PROPS, INITIAL_STATE_EXPORT_LIST_PROPS, TABLE_CONSTANTS, TABLE_MANAGER_PROPS } from './constants/exportList.general.constants';
import { EXPORT_LIST_COLUMNS, SUB_HEADER_PROPS } from './constants/exportList.table.constants';
import ACTION_TYPES from './constants/exportList.actionTypes';

const ExportList = ({
  isLoaded,
  showCancelModal,
  loading,
  contentHeight,
  pageSize,
  currentPage,
  totalNumberOfEntries,
  searchText,
  selectedFilterGroup,
  nextPageToken,
  exports,
  selectedFilters,
  onAction,
}) => {
  const handleRowAction = useCallback(
    (actionType, payload) => {
      onAction({ type: actionType, payload });
    },
    [onAction],
  );

  const handleConfirmationDialogSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CONFIRM_DIALOG_SUBMIT });
  }, [onAction]);

  const handleConfirmationDialogCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CONFIRM_DIALOG_CANCEL });
  }, [onAction]);

  return (
    <div style={{ height: contentHeight }}>
      <TableManager
        tokenPagination
        isLoaded={isLoaded}
        pageSize={pageSize}
        searchText={searchText}
        nextPageToken={nextPageToken}
        tableManagerProps={TABLE_MANAGER_PROPS}
        subHeaderProps={SUB_HEADER_PROPS}
        headerProps={HEADER_PROPS}
        columns={EXPORT_LIST_COLUMNS}
        data={exports}
        tableProps={getTableProps(handleRowAction, currentPage, loading, pageSize, totalNumberOfEntries)}
        filterProps={getFilterProps(selectedFilters, selectedFilterGroup)}
        onAction={onAction}
      />
      <PropertyControlledComponent controllerProperty={showCancelModal}>
        <ConfirmationDialog
          isVisible={showCancelModal}
          title={__('Delete Bundle')}
          content={__('Are you sure you want to delete the bundle? All the components inside the bundle will be deleted.')}
          onSubmit={handleConfirmationDialogSubmit}
          onCancel={handleConfirmationDialogCancel}
        />
      </PropertyControlledComponent>
    </div>
  );
};

ExportList.propTypes = {
  loading: PropTypes.bool,
  showCancelModal: PropTypes.bool,
  isLoaded: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalNumberOfEntries: PropTypes.number,
  searchText: PropTypes.string,
  selectedFilterGroup: PropTypes.string,
  nextPageToken: PropTypes.string,
  exports: PropTypes.array,
  selectedFilters: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ExportList.defaultProps = {
  loading: false,
  showCancelModal: false,
  isLoaded: true,
  pageSize: DEFAULT_PAGE_SIZE,
  currentPage: DEFAULT_PAGE_INFO.start,
  totalNumberOfEntries: TABLE_CONSTANTS.TOTAL_NUMBER_OF_ENTRIES,
  searchText: EMPTY_STRING,
  selectedFilterGroup: EMPTY_STRING,
  nextPageToken: undefined,
  selectedFilters: EMPTY_ARRAY,
  exports: EMPTY_ARRAY,
};

export default compose(
  withSize({ hasPageFooter: 0, hasPageHeader: 0 }),
  withActions(INITIAL_STATE_EXPORT_LIST_PROPS, ACTION_HANDLERS),
  React.memo,
)(ExportList);
