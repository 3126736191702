import _property from 'lodash/property';

// TODO: Make Dev-platform's pages use this reader
const entityReader = {
  id: _property('id'),
  name: _property('name'),
  displayName: _property('displayName'),
  complexField: _property('complexField'),
  creatable: _property('creatable'),
  editable: _property('editable'),
  listViewEnabled: _property('listViewEnabled'),
  detailViewEnabled: _property('detailViewEnabled'),
  createViewEnabled: _property('createViewEnabled'),
  editEnabled: _property('editEnabled'),
  searchEnabled: _property('searchEnabled'),
  fieldDefinitions: _property('fieldDefinitions'),
  createdBy: _property('createdBy'),
  createdTime: _property('createdTime'),
  description: _property('description'),
  recordTypeDefinitionType: _property('recordTypeDefinition.type'),
  recordTypeDefinitionRecordTypes: _property('recordTypeDefinition.recordTypes'),
  defaultRecordType: _property('recordTypeDefinition.defaultRecordType'),
  derivedRecordTypeFields: _property('recordTypeDefinition.recordTypeDerivationConfig.derivationFields'),
  recordTypeOverrides: _property('recordTypeOverrides'),
  recordTypeEnabled: _property('recordTypeEnabled'),
  entityRelationMetadataList: _property('entityRelationMetadataList'),
  auditEnabled: _property('auditEnabled'),
};

export default entityReader;
