import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import { getMetadataExportList, deleteBundleById, publishBundleById } from '../../../../../actions/metadataManagement.actions';

import { getPayload, getExportData } from './exportList.helpers';

import { STUDIO_ROUTE } from '../../../../../constants/routes';
import { DEFAULT_FILTER_GROUP } from '../constants/exportList.filters';
import { TABLE_CONSTANTS } from '../constants/exportList.general.constants';
import PAGE_IDS from '../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/exportList.actionTypes';

const handleInit = async ({ getState, setState }) => {
  const { pageSize, searchText, pageToken, selectedFilters } = getState();
  const payload = getPayload(pageSize, pageToken, searchText, selectedFilters);

  setState({ loading: true });

  const exportResponse = await getMetadataExportList(payload);
  const exportList = getExportData(exportResponse);

  setState({ exports: exportList, loading: false });
};

const handlePageSizeUpdate = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');

  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }
  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleInit({ getState, setState });
    },
  );
};

const handleSetFilter = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const filters = tget(params, 'value', EMPTY_ARRAY);
  const selectedFilterGroup = tget(params, 'selectedFilterGroup', DEFAULT_FILTER_GROUP);
  setState(
    {
      selectedFilters: filters,
      currentPage: TABLE_CONSTANTS.CURRENT_PAGE,
      selectedFilterGroup,
    },
    () => {
      handleInit({ getState, setState });
    },
  );
};

const handleSearchApply = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const searchText = tget(params, 'value', EMPTY_STRING);
  setState({ searchText }, () => {
    handleInit({ getState, setState });
  });
};

const handleCreateExport = ({ getState }) => {
  const { history } = getState();
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.EXPORT_CREATE}`;

  history.push({ pathname });
};

const rowClickHandler = ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const bundleData = tget(params, 'value.original', EMPTY_OBJECT);
  const bundleId = tget(bundleData, 'id', EMPTY_STRING);
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.EXPORTS}/${PAGE_IDS.ADD_METADATA_COMPONENT}/${bundleId}`;

  history.push({ pathname, state: { bundleInfo: bundleData } });
};

const handleBundlePublish = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const bundleId = tget(params, 'id', EMPTY_STRING);

  await publishBundleById(bundleId);

  setState({}, () => {
    handleInit({ getState, setState });
  });
};

const handleCancelationModalOpen = ({ setState, params = EMPTY_OBJECT }) => {
  setState({ showCancelModal: true, bundleToBeDeleted: params });
};

const handleConfirmationDialogCancel = ({ setState }) => {
  setState({ showCancelModal: false, bundleToBeDeleted: {} });
};

const handleConfirmationDialogSubmit = async ({ getState, setState }) => {
  setState({ showCancelModal: false });

  const { bundleToBeDeleted } = getState();
  const bundleId = tget(bundleToBeDeleted, 'id', EMPTY_STRING);

  setState({ loading: true });

  await deleteBundleById(bundleId);

  setState({ loading: false, bundleToBeDeleted: {} }, () => {
    handleInit({ getState, setState });
  });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageSizeUpdate,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleSetFilter,
  [TABLE_ACTION_TYPES.TABLE_SEARCH_TERM_UPDATE]: handleSearchApply,
  [ACTION_TYPES.CREATE_EXPORT]: handleCreateExport,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: rowClickHandler,
  [ACTION_TYPES.PUBLISH_BUNDLE]: handleBundlePublish,
  [ACTION_TYPES.OPEN_CANCELATION_MODAL]: handleCancelationModalOpen,
  [ACTION_TYPES.ON_CONFIRM_DIALOG_CANCEL]: handleConfirmationDialogCancel,
  [ACTION_TYPES.ON_CONFIRM_DIALOG_SUBMIT]: handleConfirmationDialogSubmit,
};

export default ACTION_HANDLERS;
