import _isEmpty from 'lodash/isEmpty';

// Constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Readers
import entityReader from '../../../../../../readers/entity.reader';

const setEntityDisplayName = (workflow, entityDefinition = EMPTY_OBJECT) => {
  const entityDisplayName = entityReader.displayName(entityDefinition);

  if (_isEmpty(entityDisplayName)) {
    return workflow;
  }

  return {
    ...workflow,
    uiMetadata: {
      ...workflow?.uiMetadata,
      entityDisplayName,
    },
  };
};

export default setEntityDisplayName;
