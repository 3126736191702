import { defaultMemoize } from 'reselect';
import _map from 'lodash/map';
import _split from 'lodash/split';
import _join from 'lodash/join';

import Heading from '@tekion/tekion-components/atoms/Heading';
import Button from '@tekion/tekion-components/atoms/Button';

import ACTION_TYPES from '../constants/usersSetting.actionTypes';
import { REMOVE_USER_ACTION } from '../constants/userGroupSetting.rowActions';

const createTableProps = defaultMemoize((isLoading, handleRowAction) => ({
  loading: isLoading,
  rowHeight: 35,
  minRows: 0,
  showPagination: false,
  showActions: true,
  onRowActionClick: handleRowAction,
  getRowActions: () => [REMOVE_USER_ACTION],
}));

const createSearchPayload = defaultMemoize((permissionName) => ({
  filters: [
    {
      field: 'permissionSetNames',
      filterType: 'IN',
      values: [permissionName],
    },
    {
      field: 'principalType',
      filterType: 'IN',
      values: ['USER'],
    },
  ],
}));

const createAssignmentPayload = defaultMemoize((userIds, permissionName) =>
  _map(userIds, (userId) => ({
    principalIdentifier: userId,
    principalType: 'USER',
    permissionSetNames: [permissionName],
    name: `u_${_join(_split(userId, '-'), '')}`,
    displayName: `u_${userId}`,
  })),
);

const getSubHeaderProps = defaultMemoize((count) => ({
  subHeaderLeftActions: [
    {
      renderer: Heading,
      renderOptions: {
        children: __(`Assigned Users (${count})`),
        size: 2,
      },
    },
  ],
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Assign User'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.ASSIGN_USER_CLICK,
    },
  ],
}));

export { createTableProps, createSearchPayload, getSubHeaderProps, createAssignmentPayload };
