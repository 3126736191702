import React, { useCallback } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

// Tekion-components
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';

// Tekion-connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Connectors
import WithSize from '../../../../../../connectors/withSize';

// Helpers
import SECTIONS from './helpers/approvalSettingForm.sections';
import FIELDS from './helpers/approvalSettingForm.fields';
import ACTION_HANDLERS from './helpers/approvalSettingForm.actionHandlers';

// Constants
import { CONTEXT_ID } from './constants/approvalSettingForm.constants';
import ROUTES from '../../../../constants/routes';

const ApprovalSettingForm = ({ isSubmitting, contentHeight, formValues, errors, history, onAction }) => {
  const handleCancel = useCallback(() => {
    history.push(ROUTES.APPROVAL_SETTINGS_ROUTE);
  }, [history]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, []);

  return (
    <Page>
      <Page.Header hasBack goBackTo={ROUTES.APPROVAL_SETTINGS_ROUTE}>
        <Heading>{__('Create approval setting')}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight }}>
        <FormWithSubmission
          className="p-t-12"
          contextId={CONTEXT_ID}
          sections={SECTIONS}
          fields={FIELDS}
          values={formValues}
          errors={errors}
          onAction={onAction}
        />
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id={CONTEXT_ID}
          primaryActionLoading={isSubmitting}
          primaryButtonLabel={__('Create')}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

ApprovalSettingForm.propTypes = {
  isSubmitting: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  history: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
};

ApprovalSettingForm.defaultProps = {
  isSubmitting: false,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default compose(WithSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(ApprovalSettingForm);
