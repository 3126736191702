import _get from 'lodash/get';

import { filterSections } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS from '../constants/childAggregateSummaryForm.fieldIds';
import { FILTER_CONDITION_OPTIONS } from '../constants/childAggregateSummaryForm.general.constants';

import styles from '../../../fieldsForm.module.scss';

const getSections = (values) => {
  const childFilterCriteria = _get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.FILTER_CRITERIA_FOR_CHILD);
  const parentFilterCriteria = _get(values, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.FILTER_CRITERIA_FOR_PARENT);

  return filterSections([
    {
      header: {
        label: __('Summary Details'),
        className: styles.formSectionHeader,
      },
      rows: [
        {
          columns: [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_ENTITY_NAME, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.ROLL_UP_FUNCTION],
        },
        {
          columns: [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_RELATIONSHIP, CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.AGGREGATE_FIELD],
        },
      ],
    },
    {
      header: {
        label: __('Filter Criteria For Child Entity'),
      },
      rows: [
        {
          columns: [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.FILTER_CRITERIA_FOR_CHILD],
        },
      ],
    },
    {
      rows: [
        {
          columns: [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.CHILD_FILTER_CONDITION],
        },
      ],
      visible: childFilterCriteria === FILTER_CONDITION_OPTIONS.ONLY_CERTAIN_RECORDS,
    },
    {
      header: {
        label: __('Filter Criteria For Parent Entity'),
      },
      rows: [
        {
          columns: [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.FILTER_CRITERIA_FOR_PARENT],
        },
      ],
    },
    {
      rows: [
        {
          columns: [CHILD_AGGREGATE_SUMMARY_FORM_FIELD_IDS.UPDATE_CONDITION],
        },
      ],
      visible: parentFilterCriteria === FILTER_CONDITION_OPTIONS.ONLY_CERTAIN_RECORDS,
    },
  ]);
};
export default getSections;
