import { BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS } from '../constants/buttonPropertiesConfigure.fieldIds';

const BUTTON_PROPERTIES_CONFIGURE_SECTIONS = [
  {
    rows: [
      {
        columns: [BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS.BUTTON_ICON, BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS.BUTTON_LABEL],
      },
      {
        columns: [BUTTON_PROPERTIES_CONFIGURE_FIELD_IDS.BUTTON_VIEW_TYPE],
      },
    ],
  },
];

export { BUTTON_PROPERTIES_CONFIGURE_SECTIONS };
