import _valuesIn from 'lodash/valuesIn';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { CUSTOM_ENTITY_ACTION_TYPES } from '../../../../../../constants/approvalCentre.constants';
import FIELD_IDS from '../constants/approvalRequestForm.fieldIds';
import SYSTEM_DEFINED_FIELDS from '../../../../../../constants/systemDefinedFields';

const getPayloadForApprovalMatchingProcess = (recordFormValues, formValues) => {
  const group = getArraySafeValue(tget(formValues, FIELD_IDS.GROUP, EMPTY_ARRAY));
  const category = getArraySafeValue(tget(formValues, FIELD_IDS.CATEGORY, EMPTY_ARRAY));
  const type = getArraySafeValue(tget(formValues, FIELD_IDS.TYPE, EMPTY_ARRAY));
  const recordId = tget(formValues, [FIELD_IDS.DATA, FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.CUSTOM_ENTITY_REQUEST, 'id']);

  const actionType = _isEmpty(recordId) ? CUSTOM_ENTITY_ACTION_TYPES.CREATE_CUSTOM_ENTITY : CUSTOM_ENTITY_ACTION_TYPES.UPDATE_CUSTOM_ENTITY;
  const actionTextForName = _isEmpty(recordId) ? __('Creation') : __('Updation');

  return {
    [FIELD_IDS.GROUP]: group,
    [FIELD_IDS.CATEGORY]: category,
    [FIELD_IDS.TYPE]: type,
    [FIELD_IDS.NAME]: __("{{actionTextForName}} request for {{type}}'s record", { actionTextForName, type }),
    [FIELD_IDS.DATA]: {
      [FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.ACTION_TYPE]: actionType,
      [FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.CUSTOM_ENTITY_REQUEST]: {
        entity: {
          ..._omit(recordFormValues, _valuesIn(SYSTEM_DEFINED_FIELDS)),
        },
        ..._pick(recordFormValues, _valuesIn(SYSTEM_DEFINED_FIELDS)),
      },
      [FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.ID]: recordId,
    },
  };
};

export { getPayloadForApprovalMatchingProcess };
