import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

import _values from 'lodash/values';
import _noop from 'lodash/noop';

import { tget } from '@tekion/tekion-base/utils/general';
import { getNodeUpdates } from '@tekion/tekion-rule-engine/src/utils/core/helpers/tasks.helpers';
import { ValidationProvider, validationManager } from '@tekion/tekion-rule-engine/src/contexts/ValidationContext';
import { workflowHistoryManager } from '@tekion/tekion-rule-engine';

// Components
import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components/molecules/loader';
import RuleRenderer from '@tekion/tekion-rule-engine/src/components/RuleRenderer';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import NullComponent from '@tekion/tekion-components/atoms/nullComponent/NullComponent';

import withSize from '../../../../../../../connectors/withSize';
import RightDrawer from '../../components/rightDrawer/RightDrawer';
import ConditionModal from '../../components/modals/conditionModal/ConditionModal';
import ConfirmationModal from '../../components/modals/confirmationModal/ConfirmationModal';
import RecordActionModal from '../../components/modals/recordActionModal/RecordActionModal';
import DelayTaskModal from '../../components/modals/delayTaskModal/DelayTaskModal';
import ConditionChangeToModal from '../../components/modals/conditionChangeToModal/ConditionChangeToModal';
import RepeatNodeModal from '../../components/modals/repeatNodeModal/RepeatNodeModal';
import SwitchWorkflowModal from '../../components/modals/switchWorkflow';
import TimeOfTheDayModal from '../../components/modals/timeOfTheDayModal/TimeOfTheDayModal';
import VariableNodeModal from '../../components/modals/variableNodeModal';
import CallOutModal from '../../components/modals/calloutModal/CallOutModal';
import LoopNodeModal from '../../components/modals/loopNodeModal/LoopNodeModal';
import NotificationPanel from '../../components/NotificationPanel';
import PushNotificationModal from '../../components/modals/pushNotificationModal/PushNotificationModal';
import DeleteNodeModal from '../../components/modals/deleteNodeModal/DeleteNodeModal';
import Box from '../../components/nodeBox';

// Helpers
import { generateValidationAction, getDropdownOptions } from '../../helpers/workflowBuilder.helpers';
import ACTION_HANDLERS from './workflowBuilder.actionHandlers';

// Constants
import WORKFLOW_BUILDER_ACTION_TYPES from '../../constants/workflowBuilder.actionTypes';
import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import PAGE_IDS from '../../../../../constants/PageIds.constants';
import WORKFLOW_FIELD_IDS from '../../constants/workflow.fieldIds';
import WORKFLOW_BUILDER_MODAL_TYPES from '../../constants/workflowBuilder.modalTypes';
import { INITIAL_STATE, MAX_FUTURE, MAX_HISTORY, MODAL_MODES } from '../../constants/workflowBuilder.constants';
import { LOOP_NODE_STYLES, NODE_STRUCTURE } from '../../constants/workflow.constants';
import { WORKFLOW_BUILDER_MODE } from '../../../../../../../constants/general.constants';

// Utils
import getNodeContent from '../../utils/getNodeContent';
import { validateByNode } from '../../utils/validators';

// Styles
import styles from './workflowBuilder.module.scss';

const MODAL_TYPE_VS_COMPONENT = {
  [WORKFLOW_BUILDER_MODAL_TYPES.CONFIRMATION]: ConfirmationModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.CONDITION]: ConditionModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.ACTION]: RecordActionModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.DELAY_TASK]: DelayTaskModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.CHECK_FIELD]: ConditionModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.CHANGE_TO_IF]: ConditionChangeToModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.CHANGE_TO_IF_ELSE]: ConditionChangeToModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.CHANGE_TO_SWITCH]: ConditionChangeToModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.REPEAT_NODE]: RepeatNodeModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.GOTO]: RepeatNodeModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.SWITCH_WORKFLOW]: SwitchWorkflowModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.TIME_OF_THE_DAY]: TimeOfTheDayModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.SET_VARIABLE]: VariableNodeModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.PUSH_NOTIFICATION]: PushNotificationModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.CALLOUT]: CallOutModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.FETCH_AND_ITERATE]: LoopNodeModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.FOR_LOOP]: LoopNodeModal,
  [WORKFLOW_BUILDER_MODAL_TYPES.DELETE_CONFIRMATION_MODAL]: DeleteNodeModal,
};

const WorkflowBuilder = ({
  isEventTypesLoading,
  isStepsLoading,
  isWorkflowLoading,
  isWorkflowSubmitting,
  isStepDrawerVisible,
  isTriggerDrawerVisible,
  isModalVisible,
  contentHeight,
  contentWidth,
  workflowMode,
  modalType,
  modalProps,
  expandedNodes,
  workflow,
  stepDrawerData,
  triggerDrawerData,
  onAction,
}) => {
  const [isAllNodesExpanded, setIsAllNodesExpanded] = useState(false);

  const rootNodeId = tget(workflow, 'rootNodeId');
  const nodeList = tget(workflow, 'nodeList');

  const ModalComponent = modalType ? MODAL_TYPE_VS_COMPONENT[modalType] : NullComponent;

  const toggleNodeExpansion = useCallback(() => setIsAllNodesExpanded((prev) => !prev), []);

  const onEntityChange = useCallback(
    (variableMap, loadingPropName) =>
      onAction({
        type: WORKFLOW_BUILDER_ACTION_TYPES.ON_ENTITY_CHANGE,
        payload: { variableMap, loadingPropName },
      }),
    [onAction],
  );

  const handleAddNode = useCallback(
    (parentNode, label, nodePlacement, isAddStep) =>
      onAction({
        type: WORKFLOW_BUILDER_ACTION_TYPES.ON_ADD_NODE,
        payload: { parentNode, label, nodePlacement, isAddStep, workflow },
      }),
    [onAction, workflow],
  );

  const getNode = useCallback((nodeId) => tget(nodeList, nodeId), [nodeList]);

  const handleNodeDataUpdate = useCallback(
    (nodeId, dataToUpdate, type) => {
      const node = getNode(nodeId);

      const updates = getNodeUpdates(node, dataToUpdate, type === MODAL_MODES.EDIT);
      onAction({
        type: WORKFLOW_BUILDER_ACTION_TYPES.NODE_UPDATE,
        payload: { node: { ...node, ...updates }, config: tget(dataToUpdate, 'nodeConfig', {}) },
      });
    },
    [onAction, getNode],
  );

  const handleWorkflowHistoryUpdate = useCallback(
    (newWorkflow) => {
      onAction({
        type: WORKFLOW_BUILDER_ACTION_TYPES.ON_UNDO_REDO_WORKFLOW_UPDATES,
        payload: { changes: { ...workflow, ...newWorkflow }, config: { updateWithoutModifications: true } },
      });
    },
    [onAction, workflow],
  );

  const handleNodeDnd = useCallback((dndNodes) => onAction({ type: WORKFLOW_BUILDER_ACTION_TYPES.ON_NODE_DND, payload: { dndNodes } }), [onAction]);

  const handleNodeMenuClick = (nodeData, actionType) => {
    const nodeId = tget(nodeData, 'nodeId');
    onAction({ type: WORKFLOW_BUILDER_ACTION_TYPES.ON_NODE_MENU_CLICK, payload: { nodeId, actionType, onEntityChange } });
  };

  const handleTriggerItemClick = useCallback(
    (triggerId) => onAction({ type: WORKFLOW_BUILDER_ACTION_TYPES.ON_TRIGGER_ITEM_CLICK, payload: { triggerId } }),
    [onAction],
  );

  const handleStepItemClick = useCallback(
    (stepId) => onAction({ type: WORKFLOW_BUILDER_ACTION_TYPES.ON_STEP_ITEM_CLICK, payload: { stepId, onEntityChange } }),
    [onAction, onEntityChange],
  );

  const handleNodeOnDrop = useCallback(
    (payload) => {
      onAction({
        type: WORKFLOW_BUILDER_ACTION_TYPES.ON_NODE_DROP_REPOSITION,
        payload,
      });
    },
    [onAction],
  );

  const handleDuplicateNode = useCallback(
    (parentNode, label, nodePlacement) => {
      const parentNodeId = tget(parentNode, 'nodeId');
      onAction({
        type: WORKFLOW_BUILDER_ACTION_TYPES.ON_NODE_DUPLICATE,
        payload: {
          parentNode: parentNodeId,
          content: {
            label,
            isDuplicate: true,
            nodePlacement,
          },
        },
      });
    },
    [onAction],
  );

  const handleNodeExpand = useCallback(
    (nodeId) =>
      onAction({
        type: WORKFLOW_BUILDER_ACTION_TYPES.ON_NODE_EXPAND,
        payload: { nodeId },
      }),
    [onAction],
  );

  const handleShowCancelConfirmationModal = useCallback(() => onAction({ type: WORKFLOW_BUILDER_ACTION_TYPES.ON_CANCEL_CLICK }), [onAction]);

  const handleModalSubmit = (formValues, childLabels = [], assignedVariableInfo = {}, usedVariableInfo = []) =>
    onAction({ type: WORKFLOW_BUILDER_ACTION_TYPES.ON_MODAL_SUBMIT, payload: { formValues, childLabels, assignedVariableInfo, usedVariableInfo } });

  const handleModalCancel = useCallback(() => onAction({ type: WORKFLOW_BUILDER_ACTION_TYPES.ON_MODAL_CANCEL }), [onAction]);

  const handleWorkflowSubmit = useCallback(() => onAction({ type: WORKFLOW_BUILDER_ACTION_TYPES.ON_WORKFLOW_SUBMIT }), [onAction]);

  const notificationPanel = useMemo(() => NotificationPanel, []);

  const validationAction = useMemo(
    () => (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS ? generateValidationAction(workflow) : _noop),
    [workflow, workflowMode],
  );

  useEffect(() => {
    if (!isEventTypesLoading && !isStepsLoading) {
      onAction({ type: WORKFLOW_BUILDER_ACTION_TYPES.ON_BUILDER_INIT });
    }
  }, [isEventTypesLoading, isStepsLoading, onAction]);

  useEffect(() => {
    const variablesByNodeId = {};
    if (!isWorkflowLoading) {
      if (!isStepsLoading) {
        validationManager.validators = {
          defaultValidator: (dataObject, additional) => validateByNode(dataObject, additional, variablesByNodeId),
        };
        validationManager.validateRemote(validationAction);
        validationManager.validateMultiple(_values(nodeList), { workflow });
        workflowHistoryManager.maxHistoryValue = MAX_HISTORY;
        workflowHistoryManager.maxFutureValue = MAX_FUTURE;

        onAction({ type: WORKFLOW_BUILDER_ACTION_TYPES.ON_UPDATE_MAP_OF_SCOPED_VARIABLES_BY_NODE, payload: { variablesByNodeId } });
      }
    }
  }, [isStepsLoading, isWorkflowLoading, workflow, validationAction, nodeList, onAction]);

  useMemo(() => {
    if (!isWorkflowLoading && !isStepsLoading) {
      workflowHistoryManager.recordChange({ workflow });
    }
  }, [workflow, isWorkflowLoading, isStepsLoading]);

  if (isWorkflowLoading || isStepsLoading) {
    return <Loader id="WORKFLOW_BUILDER" />;
  }

  return (
    <Page>
      <ValidationProvider>
        <Page.Header contentClassName={styles.headerContainer} hasBack goBackTo={`${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${workflowMode}`}>
          <Heading>{workflow?.[WORKFLOW_FIELD_IDS.DISPLAY_NAME]}</Heading>
          <div className={styles.entityNameContainer}>
            <span className={styles.entityLabel}>{__('Entity: ')}</span>
            {tget(workflow, 'uiMetadata.entityDisplayName', '')}
          </div>
        </Page.Header>

        <div style={{ height: contentHeight, width: contentWidth }} className={styles.container}>
          <RuleRenderer
            allNodesExpanded={isAllNodesExpanded}
            nodeStruture={NODE_STRUCTURE}
            loopNodeStyles={LOOP_NODE_STYLES}
            rootNodeId={rootNodeId}
            nodes={workflow?.nodeList}
            expandedNodes={expandedNodes}
            notificationPanel={notificationPanel}
            nodeComponent={Box}
            toggleNodeExpansion={toggleNodeExpansion}
            conterntParser={getNodeContent}
            operations={{ getDropdownOptions, onMenuItemClick: handleNodeMenuClick }}
            handleNodeOnDND={handleNodeDnd}
            handleOnDropNode={handleNodeOnDrop}
            handleNodeDataUpdate={handleNodeDataUpdate}
            handleNodeDuplication={handleDuplicateNode}
            handleAddNodeClick={handleAddNode}
            handleUndoRedo={handleWorkflowHistoryUpdate}
            handleNodeExpand={handleNodeExpand}
          />
          <RightDrawer
            visible={isTriggerDrawerVisible}
            header={__('Choose a trigger')}
            drawerItemsData={triggerDrawerData}
            onItemClick={handleTriggerItemClick}
            onClose={() => onAction({ type: WORKFLOW_BUILDER_ACTION_TYPES.CLOSE_TRIGGER_DRAWER })}
          />
          <RightDrawer
            visible={isStepDrawerVisible}
            header={__('Choose a step')}
            drawerItemsData={stepDrawerData}
            onItemClick={handleStepItemClick}
            onClose={() => onAction({ type: WORKFLOW_BUILDER_ACTION_TYPES.CLOSE_STEP_DRAWER })}
          />
          {isModalVisible && (
            <ModalComponent
              visible={isModalVisible}
              modalType={modalType}
              {...modalProps}
              onCancel={handleModalCancel}
              onSubmit={handleModalSubmit}
            />
          )}
        </div>
        <Page.Footer>
          <SaveComponent
            id="WORKFLOW_BUILDER"
            primaryActionLoading={isWorkflowSubmitting}
            onPrimaryAction={handleWorkflowSubmit}
            onSecondaryAction={handleShowCancelConfirmationModal}
          />
        </Page.Footer>
      </ValidationProvider>
    </Page>
  );
};

WorkflowBuilder.propTypes = {
  isWorkflowLoading: PropTypes.bool,
  isWorkflowFetched: PropTypes.bool,
  isWorkflowModified: PropTypes.bool,
  isWorkflowSubmitting: PropTypes.bool,
  isEventTypesLoading: PropTypes.bool,
  isStepsLoading: PropTypes.bool,
  isStepDrawerVisible: PropTypes.bool,
  isTriggerDrawerVisible: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  contentWidth: PropTypes.number.isRequired,
  contentHeight: PropTypes.number.isRequired,
  workflowMode: PropTypes.string,
  modalType: PropTypes.string,
  modalProps: PropTypes.object,
  workflow: PropTypes.object,
  stepDrawerData: PropTypes.object,
  triggerDrawerData: PropTypes.object,
  expandedNodes: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

WorkflowBuilder.defaultProps = {
  isWorkflowLoading: false,
  isWorkflowFetched: false,
  isWorkflowModified: false,
  isWorkflowSubmitting: false,
  isEventTypesLoading: false,
  isStepsLoading: false,
  isStepDrawerVisible: false,
  isTriggerDrawerVisible: false,
  isModalVisible: false,
  workflowMode: WORKFLOW_BUILDER_MODE.PROCESS,
  modalType: '',
  modalProps: EMPTY_OBJECT,
  workflow: EMPTY_OBJECT,
  stepDrawerData: EMPTY_OBJECT,
  triggerDrawerData: EMPTY_OBJECT,
  expandedNodes: EMPTY_OBJECT,
};

export default compose(withRouter, withSize({ hasPageFooter: 1, hasPageHeader: 1 }), withActions(INITIAL_STATE, ACTION_HANDLERS))(WorkflowBuilder);
