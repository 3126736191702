// Plate
import {
  DEFAULTS_BOLD,
  DEFAULTS_CODE,
  DEFAULTS_ITALIC,
  DEFAULTS_STRIKETHROUGH,
  DEFAULTS_SUBSCRIPT,
  DEFAULTS_SUPERSCRIPT,
  DEFAULTS_UNDERLINE,
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  MARK_UNDERLINE,
} from '@udecode/plate-basic-marks';
import { DEFAULTS_BLOCKQUOTE, ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote';
import { DEFAULTS_CODE_BLOCK, ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE } from '@udecode/plate-code-block';
import { MARK_SEARCH_HIGHLIGHT } from '@udecode/plate-find-replace';
import {
  DEFAULTS_H1,
  DEFAULTS_H2,
  DEFAULTS_H3,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
} from '@udecode/plate-heading';
import { DEFAULTS_HIGHLIGHT, MARK_HIGHLIGHT } from '@udecode/plate-highlight';
import { ELEMENT_IMAGE } from '@udecode/plate-image';
import { MARK_KBD } from '@udecode/plate-kbd';
import { ELEMENT_LINK } from '@udecode/plate-link';
import { DEFAULTS_TODO_LIST, ELEMENT_LI, ELEMENT_OL, ELEMENT_TODO_LI, ELEMENT_UL } from '@udecode/plate-list';
import { ELEMENT_MEDIA_EMBED } from '@udecode/plate-media-embed';
import { ELEMENT_MENTION } from '@udecode/plate-mention';
import { DEFAULTS_PARAGRAPH, ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { ELEMENT_TABLE, ELEMENT_TD, ELEMENT_TH, ELEMENT_TR } from '@udecode/plate-table';

// constants
// import { ELEMENT_VIDEO } from '../../../plugins/video';
// import { ELEMENT_TEMPLATE_VARIABLE } from '../../../plugins/templateVariable';

// helpers
import createImageNodeOptions from './useEditorOptions.image';
// import createVideoNodeOptions from './useEditorOptions.video';
// import createTemplateVariableNodeOptions from './useEditorOptions.templateVariable';
import createTdCellNodeOptions from './useEditorOptions.tdCell';
import createThCellNodeOptions from './useEditorOptions.thCell';
import createLinkCellNodeOptions from './useEditorOptions.link';

const createEditorOptions = (readOnly) => {
  const options = {
    [ELEMENT_BLOCKQUOTE]: DEFAULTS_BLOCKQUOTE,
    [ELEMENT_CODE_BLOCK]: DEFAULTS_CODE_BLOCK,
    [ELEMENT_CODE_LINE]: {},
    [ELEMENT_H1]: DEFAULTS_H1,
    [ELEMENT_H2]: DEFAULTS_H2,
    [ELEMENT_H3]: DEFAULTS_H3,
    [ELEMENT_H4]: {},
    [ELEMENT_H5]: {},
    [ELEMENT_H6]: {},
    [ELEMENT_IMAGE]: {},
    [ELEMENT_LI]: {},
    [ELEMENT_LINK]: createLinkCellNodeOptions(readOnly),
    [ELEMENT_MEDIA_EMBED]: {},
    [ELEMENT_MENTION]: {},
    [ELEMENT_OL]: {},
    [ELEMENT_PARAGRAPH]: DEFAULTS_PARAGRAPH,
    [ELEMENT_TABLE]: {},
    [ELEMENT_TD]: createTdCellNodeOptions(readOnly),
    [ELEMENT_TH]: createThCellNodeOptions(readOnly),
    [ELEMENT_TODO_LI]: DEFAULTS_TODO_LIST,
    [ELEMENT_TR]: {},
    [ELEMENT_UL]: {},
    [MARK_BOLD]: DEFAULTS_BOLD,
    [MARK_CODE]: DEFAULTS_CODE,
    [MARK_HIGHLIGHT]: DEFAULTS_HIGHLIGHT,
    [MARK_ITALIC]: DEFAULTS_ITALIC,
    [MARK_KBD]: {},
    [MARK_SEARCH_HIGHLIGHT]: {},
    [MARK_STRIKETHROUGH]: DEFAULTS_STRIKETHROUGH,
    [MARK_SUBSCRIPT]: DEFAULTS_SUBSCRIPT,
    [MARK_SUPERSCRIPT]: DEFAULTS_SUPERSCRIPT,
    [MARK_UNDERLINE]: DEFAULTS_UNDERLINE,
    [ELEMENT_IMAGE]: createImageNodeOptions(readOnly),
    // [ELEMENT_VIDEO]: createVideoNodeOptions(readOnly),
    // [ELEMENT_TEMPLATE_VARIABLE]: createTemplateVariableNodeOptions(readOnly),
  };
  return options;
};

export default createEditorOptions;
