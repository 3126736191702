const COLUMN_IDS = {
  ENTITY_NAME: 'entityName',
  PUBLIC_READ: 'PUBLIC_READONLY',
  PUBLIC_READ_WRITE: 'PUBLIC_READ_WRITE',
  PRIVATE_READ_WRITE: 'PRIVATE',
  ACCESS_TYPE: 'accessType',
  ROLE_HIERARCHY: 'grantAccessUsingHierarchies',
};

const TABLE_CONSTANTS = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 50,
  TOTAL_NUMBER_OF_ENTRIES: 0,
};

const ACCESS_TYPE_MAPPER = {
  [COLUMN_IDS.PRIVATE_READ_WRITE]: 'Private',
  [COLUMN_IDS.PUBLIC_READ]: 'Public Read',
  [COLUMN_IDS.PUBLIC_READ_WRITE]: 'Public Read-Write',
};

export { COLUMN_IDS, TABLE_CONSTANTS, ACCESS_TYPE_MAPPER };
