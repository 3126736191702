import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { EMPTY_ARRAY } from '@tekion/tekion-base/utils/constant';

import TextArea from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';
import OptionsTable from '../components/optionsTable';

import { nameValidator } from '../../../../../../utils/formValidators';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import { FIELD_IDS } from '../constants/globalSelectListForm.general.constants';

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Display name'),
    validators: [isRequiredRule],
    placeholder: __('Enter a display name'),
  },
};

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: TextInput,
  renderOptions: {
    label: __('Name'),
    placeholder: __('This will be autofilled'),
  },
};

const DESCRIPTION_FIELD = {
  id: FIELD_IDS.DESCRIPTION,
  renderer: TextArea,
  renderOptions: {
    label: __('Description'),
    placeholder: __('Enter a description'),
    rows: 3,
  },
};

const OPTION_FIELD = {
  id: FIELD_IDS.OPTIONS,
  renderer: OptionsTable,
  renderOptions: {},
};

const FORM_FIELDS = {
  [FIELD_IDS.DISPLAY_NAME]: DISPLAY_NAME_FIELD,
  [FIELD_IDS.NAME]: NAME_FIELD,
  [FIELD_IDS.DESCRIPTION]: DESCRIPTION_FIELD,
  [FIELD_IDS.OPTIONS]: OPTION_FIELD,
};

const getFields = (mode) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.NAME]: addToRenderOptions(NAME_FIELD, [
    { path: 'disabled', value: mode !== FORM_MODES.CREATE },
    { path: 'required', value: mode === FORM_MODES.CREATE },
    { path: 'validators', value: mode === FORM_MODES.CREATE ? [nameValidator] : EMPTY_ARRAY },
  ]),
  [FIELD_IDS.OPTIONS]: addToRenderOptions(OPTION_FIELD, [{ path: 'formMode', value: mode }]),
});

export default getFields;

export { FIELD_IDS };
