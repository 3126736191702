import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { COLOR_MAP } from '@tekion/tekion-base/constants/statusColors';

const COLUMN_ACCESSORS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  DESCRIPTION: 'description',
  STATUS: 'active',
};

const CUSTOM_ACTIONS = {
  CREATE_NEW: 'CREATE_NEW',
  EDIT: 'EDIT',
  ACTIVATE: 'ACTIVATE',
  DEACTIVATE: 'DEACTIVATE',
  CLOSE_MODAL_REQUEST_DIALOG: 'CLOSE_MODAL_REQUEST_DIALOG',
  CONFIRM_MODAL_REQUEST: 'CONFIRM_MODAL_REQUEST',
  ON_EDIT_CLICK: 'ON_EDIT_CLICK',
  ON_INIT: 'ON_INIT',
};

const INITIAL_STATE = {
  showModal: false,
  modalContent: EMPTY_STRING,
  recordTypes: EMPTY_ARRAY,
};

const STATUS_TO_LABEL = {
  [true]: __('Active'),
  [false]: __('Inactive'),
};

const STATUS_TO_COLOR = {
  [true]: COLOR_MAP.GREEN,
  [false]: COLOR_MAP.RED,
};

const STATUSES = {
  ACTIVE: 'true',
  INACTIVE: 'false',
};

const ACTIVATE_MODAL_CONTENT = 'Activate Record Type';
const DEACTIVATE_MODAL_CONTENT = 'Deactivate Record Type';
const ENTITY = 'ENTITY';
const ENTITY_NAME = 'entityName';

export {
  COLUMN_ACCESSORS,
  CUSTOM_ACTIONS,
  INITIAL_STATE,
  STATUS_TO_LABEL,
  STATUS_TO_COLOR,
  STATUSES,
  ACTIVATE_MODAL_CONTENT,
  DEACTIVATE_MODAL_CONTENT,
  ENTITY,
  ENTITY_NAME,
};
