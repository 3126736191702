import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _size from 'lodash/size';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Components
import withDragNDropTable from '@tekion/tekion-components/organisms/withDragNDropTable';
import BaseTable from '@tekion/tekion-components/molecules/table/BaseTable';
import Button from '@tekion/tekion-components/atoms/Button';
import Heading from '@tekion/tekion-components/atoms/Heading';

import { getDraggableColumns } from './columnConfig.columns';
import { ACTION_TYPES } from '../../constants/addWidget.general.constant';

import styles from './columnConfig.module.scss';

const DraggableTable = withDragNDropTable(BaseTable);

const ColumnConfig = ({
  showFilterColumn,
  headingTitle,
  fieldTitle,
  options,
  value = [],
  selectedFieldValues,
  fieldId,
  metrics,
  fieldClassName,
  onAction,
  ...restProps
}) => {
  const pageSize = _size(value);

  const handleAddRow = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CLICK_ADD_NEW, payload: { id: fieldId } });
  }, [fieldId, onAction]);

  const getTdProps = useCallback(() => ({ onAction, fieldId }), [onAction, fieldId]);
  const columns = useMemo(
    () => getDraggableColumns(showFilterColumn, options, fieldTitle, selectedFieldValues, metrics, fieldId),
    [fieldId, fieldTitle, metrics, options, selectedFieldValues, showFilterColumn],
  );

  return (
    <div className={styles.columnTableContainer}>
      <div className={styles.addColumnContainer}>
        <Heading size={2} className={styles.heading}>
          {__(`${headingTitle}`)}
        </Heading>
        <Button view={Button.VIEW.PRIMARY} className={styles.button} onClick={handleAddRow}>
          {__('Add New')}
        </Button>
      </div>
      <DraggableTable
        columns={columns}
        pageSize={pageSize}
        showPagination={false}
        data={value}
        value={value}
        rowHeight={48}
        onAction={onAction}
        getTdProps={getTdProps}
        {...restProps}
      />
    </div>
  );
};

ColumnConfig.propTypes = {
  showFilterColumn: PropTypes.bool,
  fieldClassName: PropTypes.string,
  fieldId: PropTypes.string,
  headingTitle: PropTypes.string,
  fieldTitle: PropTypes.string,
  metrics: PropTypes.object,
  options: PropTypes.array,
  selectedColumns: PropTypes.array,
  value: PropTypes.array,
  selectedFieldValues: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

ColumnConfig.defaultProps = {
  showFilterColumn: false,
  fieldClassName: EMPTY_STRING,
  fieldId: EMPTY_STRING,
  headingTitle: EMPTY_STRING,
  fieldTitle: EMPTY_STRING,
  metrics: EMPTY_OBJECT,
  options: EMPTY_ARRAY,
  value: EMPTY_ARRAY,
  selectedColumns: EMPTY_ARRAY,
  selectedFieldValues: EMPTY_ARRAY,
};

export default React.memo(ColumnConfig);
