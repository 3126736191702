import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _trim from 'lodash/trim';
import _noop from 'lodash/noop';

// Components
import Button from '@tekion/tekion-components/atoms/Button';
import Heading from '@tekion/tekion-components/atoms/Heading';
import { onlyInitials } from '@tekion/tekion-components/utils';

// Styles
import styles from './tileButton.module.scss';

const TileButton = ({ isHeaderTile, displayName, application, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(application);
  }, [onClick, application]);
  const tileClassName = isHeaderTile ? styles.tileHeaderName : styles.tileName;
  const tileClass = `module-tile ${styles.tile}`;
  const content = onlyInitials(_trim(displayName));
  /* To Do : Need to fetch icon when apis are provided 
  
  const url = useMemo(async () => {
    if (_isEmpty(logoUrl)) return undefined;
    const { isValid } = isValidURLRule(logoUrl);
    if (isValid) return logoUrl;
    if (typeof logoUrl === 'string') {
      try {
        const response = await getSignedURLsForV2Service(getMediaConfig(), [logoUrl])();
        const signedUrl = _get(response, `data[${logoUrl}]`);
        if (!_isEmpty(signedUrl)) return signedUrl;
      } catch {
        return undefined;
      }
    }
    return undefined;
  }, [logoUrl]); */

  return (
    <Button view={Button.VIEW.TERTIARY} className={styles.button} onClick={handleClick}>
      {/* !_isEmpty(url) ? <img src={url} alt="Logo" /> :  */}
      {/* <Tile isHeaderTile={isHeaderTile} title={_trim(displayName)} /> */}
      <span className={tileClass}>
        <div className={isHeaderTile ? styles.tileWrapperHeader : styles.tileWrapper}>
          <span className={`${styles.text}`}>{content}</span>
        </div>
      </span>
      <Heading regular size={5} className={tileClassName}>
        {displayName}
      </Heading>
    </Button>
  );
};

TileButton.propTypes = {
  isHeaderTile: PropTypes.bool,
  displayName: PropTypes.string.isRequired,
  application: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

TileButton.defaultProps = {
  isHeaderTile: false,
  onClick: _noop,
};

export default React.memo(TileButton);
