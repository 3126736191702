const ACTION_TYPES = {
  CREATE_VIEW_BUILDER: 'CREATE_VIEW_BUILDER',
  EDIT_VIEW_BUILDER: 'EDIT_VIEW_BUILDER',
  CLONE_VIEW_MODAL_OPEN: 'CLONE_VIEW_MODAL_OPEN',
  CLONE_VIEW_MODAL_CLOSE: 'CLONE_VIEW_MODAL_CLOSE',
  CLONE_VIEW_MODAL_SUBMIT: 'ON_FORM_SUBMIT',
  ON_VIEWS_TAB_CHANGE: 'ON_VIEWS_TAB_CHANGE',
  DELETE_ENTITY_VIEW: 'DELETE_ENTITY_VIEW',
};

export default ACTION_TYPES;
