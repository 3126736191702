exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".fieldValuePair_stageFieldContainer__W23HffncTg{display:flex;align-items:center}.fieldValuePair_stageFieldContainer__W23HffncTg .fieldValuePair_icon__7tCKhJwXHW{color:#969aa3}[dir] .fieldValuePair_stageFieldContainer__W23HffncTg .fieldValuePair_icon__7tCKhJwXHW{padding:.4rem}[dir] .fieldValuePair_stageFieldContainer__W23HffncTg .fieldValuePair_clickableIcon__6uBRBNP7SN:hover{cursor:pointer}.fieldValuePair_stageFieldContainer__W23HffncTg .fieldValuePair_label__eQRQX7aVzS{color:#969aa3}[dir] .fieldValuePair_stageFieldContainer__W23HffncTg .fieldValuePair_label__eQRQX7aVzS{padding:.4rem}[dir] .fieldValuePair_stageFieldContainer__W23HffncTg .fieldValuePair_value__k9TxjwzMpp{padding:.4rem}", ""]);

// Exports
exports.locals = {
	"stageFieldContainer": "fieldValuePair_stageFieldContainer__W23HffncTg",
	"icon": "fieldValuePair_icon__7tCKhJwXHW",
	"clickableIcon": "fieldValuePair_clickableIcon__6uBRBNP7SN",
	"label": "fieldValuePair_label__eQRQX7aVzS",
	"value": "fieldValuePair_value__k9TxjwzMpp"
};