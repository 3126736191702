import _property from 'lodash/property';

const id = _property('id');
const name = _property('name');
const displayName = _property('displayName');
const widgetType = _property('widgetType');
const dimensions = _property('dimensions');
const measurements = _property('measurements');
const createdBy = _property('createdBy');
const updatedBy = _property('updatedBy');
const createdTime = _property('createdTime');
const updatedTime = _property('updatedTime');
const filtersToRender = _property('filtersToRender');
const entityName = _property('entityName');

export default {
  id,
  name,
  widgetType,
  createdBy,
  updatedBy,
  createdTime,
  updatedTime,
  filtersToRender,
  dimensions,
  measurements,
  entityName,
  displayName,
};
