import { useCallback } from 'react';
import { unwrapNodes } from '@udecode/plate-common';
import { getPlatePluginType } from '@udecode/plate-core';
import { ELEMENT_LINK } from '@udecode/plate-link';
import { ReactEditor } from 'slate-react';

// helpers
import openWindowInNewTab from '@tekion/tekion-base/utils/openWindowInNewTab';
import { showLinkDialog } from '../../../../../../../organisms/linkDialog';

// hooks
import useEditor from '../../../../../../../hooks/useEditor';

const removeSelectedLink = (editor) => {
  unwrapNodes(editor, { match: { type: getPlatePluginType(editor, ELEMENT_LINK) } });
  ReactEditor.focus(editor);
};

const handleLinkEdition = (closePopup, editor) => {
  closePopup();
  showLinkDialog(editor);
};

function useLinkPopupActions(linkNodeElement, closePopup, url) {
  const editor = useEditor();

  const handleEditLink = useCallback(() => {
    handleLinkEdition(closePopup, editor);
  }, [closePopup, editor]);

  const handleLinkRemoval = useCallback(() => {
    removeSelectedLink(editor);
  }, [editor]);

  const handleUrlClick = useCallback(() => {
    openWindowInNewTab(url);
  }, [url]);

  return {
    handleLinkRemoval,
    handleEditLink,
    handleUrlClick,
  };
}

export default useLinkPopupActions;
