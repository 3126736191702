import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import _head from 'lodash/head';
import _get from 'lodash/get';
import { compose } from 'recompose';

import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import TextInputCellRenderer from '@tekion/tekion-components/organisms/inputTable/cellRenderers/TextInputCellRenderer';
import { ACTION_TYPES, FIELD_IDS } from '../../../constants/createView.constants';

const InputCellRenderer = (props) => {
  const { column, nestingPath, original, data, onAction } = props;
  const columnId = _get(column, 'id', '');
  const rowIndex = _head(nestingPath);

  const onChange = useCallback(
    (e) => {
      onAction({
        type: ACTION_TYPES.ON_TABLE_CHANGE,
        payload: {
          id: FIELD_IDS.COLUMN_TABLE,
          columnId,
          value: e?.target?.value || '',
          rowIndex,
          original,
        },
      });
    },
    [onAction, columnId, rowIndex, original],
  );

  return <TextInputCellRenderer id={`${columnId}_${rowIndex}`} value={data} onChange={onChange} className="full-width" />;
};

InputCellRenderer.propTypes = {
  column: PropTypes.object.isRequired,
  data: PropTypes.string.isRequired,
  nestingPath: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
  original: PropTypes.object.isRequired,
};

export default compose(makeCellRenderer, React.memo)(InputCellRenderer);
