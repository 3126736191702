const ACTION_TYPES = {
  CREATE_COMPONENT: 'CREATE_COMPONENT',
  ON_FETCH_PROJECT_DATA: 'ON_FETCH_PROJECT_DATA',
  COMPONENT_CONFIG_MODAL: 'COMPONENT_CONFIG_MODAL',
  PUBLISH_BUNDLE: 'PUBLISH_BUNDLE',
  SAVE_BUNDLE_AS_DRAFT: 'SAVE_BUNDLE_AS_DRAFT',
  ON_FORM_SUBMIT: 'ON_FORM_SUBMIT',
  FETCH_BUNDLE_DATA: 'FETCH_BUNDLE_DATA',
  CLONE: 'CLONE',
  DELETE: 'DELETE',
};

export default ACTION_TYPES;
