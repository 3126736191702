import { FIELD_IDS } from './importForm.fields';

const importFormSections = () => [
  {
    header: {
      className: 'p-t-12',
      label: __('Import details'),
    },
    rows: [
      {
        columns: [FIELD_IDS.MEDIA_ID],
      },
    ],
  },
];

export default importFormSections;
