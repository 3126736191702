import _omit from 'lodash/omit';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';

import { createEntityRecord, fetchEntityRecords } from '../recordManagement.actions';

const Records = {
  search: async (entityName, payload) => {
    const response = await fetchEntityRecords(entityName, payload);
    const hits = tget(response, 'hits', []);
    return _map(hits, (record) => ({
      ..._omit(record, 'entity'),
      ...tget(record, 'entity', {}),
    }));
  },
  create: async (entityName, record) => {
    const createdRecord = await createEntityRecord(entityName, record);
    return createdRecord;
  },
};

export default Records;
