const FIELD_IDS = {
  ID: 'id',
  PROVIDER_TYPE: 'providerType',
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  URL_SUFFIX: 'urlSuffix',
  KEY: 'key',
  SECRET: 'secret',
  AUTHORIZE_END_POINT_URL: 'authorizeEndpointUrl',
  TOKEN_END_POINT_URL: 'tokenEndpointUrl',
  USER_INFO_END_POINT_URL: 'userInfoEndpointUrl',
  DEFAULT_SCOPE: 'defaultScope',
  INCLUDE_CONSUMER_SECRET_IN_RESPONSE: 'includeConsumerSecretInResponse',
  CUSTOM_ERROR_URL: 'customErrorUrl',
  CUSTOM_LOGOUT_URL: 'customLogoutUrl',
  REGISTRATION_HANDLER: 'registrationHandler',
  EXECUTE_REGISTRATION_AS: 'executeRegistrationAs',
  PORTAL: 'portal',
  ICON_URL: 'iconUrl',
  TEAM_ID: 'teamId',
  SERVICE_ID: 'serviceId',
  KEY_ID: 'keyId',
  KEY_MEDIA_ID: 'keyMediaId',
  DEFAULT_SCOPES: 'defaultScopes',
  REGISTER_AS: 'registerAs',
  TOKEN_ISSUER: 'tokenIssuer',
  SEND_ACCESS_TOKEN_IN_HEADER: 'sendAccessTokenInHeader',
  SEND_CLIENT_CREDS_IN_HEADER: 'sendClientCredsInHeader',
};

const PROVIDER_TYPES = {
  FACEBOOK: 'FACEBOOK',
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE',
  GITHUB: 'GITHUB',
  LINKEDIN: 'LINKEDIN',
  MICROSOFT: 'MICROSOFT',
  MICROSOFT_ACCESS_CONTROL_SERVICE: 'MICROSOFT_ACCESS_CONTROL_SERVICE',
  OPEN_ID: 'OPEN_ID',
  SLACK: 'SLACK',
  TEKION: 'TEKION',
  TWITTER: 'TWITTER',
  CUSTOM: 'CUSTOM',
};

const PROVIDER_TYPE_OPTIONS = [
  { label: __('Facebook'), value: PROVIDER_TYPES.FACEBOOK },
  { label: __('Apple'), value: PROVIDER_TYPES.APPLE },
  { label: __('Google'), value: PROVIDER_TYPES.GOOGLE },
  { label: __('Github'), value: PROVIDER_TYPES.GITHUB },
  { label: __('LinkedIn'), value: PROVIDER_TYPES.LINKEDIN },
  { label: __('Microsoft'), value: PROVIDER_TYPES.MICROSOFT },
  { label: __('Microsoft Access Control Service'), value: PROVIDER_TYPES.MICROSOFT_ACCESS_CONTROL_SERVICE },
  { label: __('Open Id'), value: PROVIDER_TYPES.OPEN_ID },
  { label: __('Slack'), value: PROVIDER_TYPES.SLACK },
  { label: __('Tekion'), value: PROVIDER_TYPES.TEKION },
  { label: __('Twitter'), value: PROVIDER_TYPES.TWITTER },
  { label: __('Custom'), value: PROVIDER_TYPES.CUSTOM },
];

const AUTH_PROVIDER_FORM_CONTEXT_ID = 'AUTH_PROVIDER_FORM_CONTEXT_ID';

export { FIELD_IDS, PROVIDER_TYPES, PROVIDER_TYPE_OPTIONS, AUTH_PROVIDER_FORM_CONTEXT_ID };
