const ACTION_TYPES = {
  CREATE_APPROVAL_PROCESS: 'CREATE_APPROVAL_PROCESS',
  EDIT_APPROVAL_PROCESS: 'EDIT_APPROVAL_PROCESS',
  ACTIVATE_APPROVAL_PROCESS: 'ACTIVATE_APPROVAL_PROCESS',
  DEACTIVATE_APPROVAL_PROCESS: 'DEACTIVATE_APPROVAL_PROCESS',
  ON_CANCEL_CONFIRMATION_MODAL: 'ON_CANCEL_CONFIRMATION_MODAL',
  ON_SUBMIT_CONFIRMATION_MODAL: 'ON_SUBMIT_CONFIRMATION_MODAL',
};

export default ACTION_TYPES;
