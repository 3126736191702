import _map from 'lodash/map';
import _unset from 'lodash/unset';
import _set from 'lodash/set';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { fetchProcesses } from '../../../../../../../../../../actions/processBuilder.actions';

import { generateTabs } from '../delayTaskModal.helpers';
import { generateUserData, getChildLabels } from '../delayTaskModal.payloadHelpers';
import DELAY_TASK_ACTION_TYPES from '../../constants/delayTaskModal.actionTypes';

const handleInit = async ({ setState, params = EMPTY_OBJECT }) => {
  const { stepDetails } = params;
  const userData = tget(stepDetails, 'userData.data');
  const uiMetadata = tget(stepDetails, 'uiMetadata');
  const tabsList = generateTabs(userData, uiMetadata);
  const workflowList = await fetchProcesses();
  const workflowOptions = _map(workflowList, (workflow) => ({ label: tget(workflow, 'name', ''), value: tget(workflow, 'id', '') }));

  setState({ tabsList, workflowOptions });
};

const handleSubmit = ({ setState, getState }) => {
  const { tabsList, onSubmit, stepDetails } = getState();

  const userData = generateUserData(tabsList);
  const childLabels = getChildLabels(tabsList);
  const delayTime = tget(userData, 'delayTime', {});
  _unset(userData, 'delayTime');
  _set(stepDetails, 'uiMetadata.delayTime', delayTime);

  onSubmit(userData, childLabels);

  setState({ tabsList: [] });
};

const handleCancel = ({ setState, getState }) => {
  const { onCancel } = getState();
  onCancel();
  setState({ tabsList: [] });
};

const GENERAL_ACTION_HANDLERS = {
  [DELAY_TASK_ACTION_TYPES.ON_INIT]: handleInit,
  [DELAY_TASK_ACTION_TYPES.ON_SUBMIT]: handleSubmit,
  [DELAY_TASK_ACTION_TYPES.ON_CANCEL]: handleCancel,
};

export default GENERAL_ACTION_HANDLERS;
