import React from 'react';
import _noop from 'lodash/noop';

const UploadingMediaContext = React.createContext(_noop);

const { Provider: UploadingMediaContextProvider, Consumer: UploadingMediaContextConsumer } = UploadingMediaContext;

export { UploadingMediaContextProvider, UploadingMediaContextConsumer };

export default UploadingMediaContext;
