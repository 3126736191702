import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import TextAreaInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import SwitchRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch/Switch';
import SelectInputFieldWrapper from '../../../../../../../connectors/selectInputFieldWrapper/SelectInputFieldWrapper';
import { MFA_AUTH_TYPE_OPTIONS } from '../constants/authConfigModal.general.constants';

const FIELD_IDS = {
  PROVIDER_TYPE: 'providerType',
  CLIENT_ID: 'clientId',
  CLIENT_SECRET: 'clientSecret',
  REDIRECT_URL: 'redirectUrl',
  IP_SSO_URL: 'ipSsoUrl',
  OKTA_CERTIFICATE: 'certificate',
  MFA_ENABLED: 'mfaEnabled',
  MFA_AUTH_TYPES: 'mfaAuthTypes',
};

const PROVIDER_TYPE_FIELD = {
  id: FIELD_IDS.PROVIDER_TYPE,
  renderer: SelectInputFieldWrapper(SelectInput),
  renderOptions: {
    required: true,
    label: __('Provider Type'),
    validators: [isRequiredRule],
    placeholder: __('Select Provider Type'),
  },
};

const CLIENT_ID_FIELD = {
  id: FIELD_IDS.CLIENT_ID,
  renderer: TextInput,
  renderOptions: {
    label: __('Client Id'),
    placeholder: __('Enter Client Id'),
    required: true,
    validators: [isRequiredRule],
  },
};

const IP_SSO_URL_FIELD = {
  id: FIELD_IDS.IP_SSO_URL,
  renderer: TextInput,
  renderOptions: {
    label: __('SSO URL'),
    placeholder: __('Enter SSO URL'),
    required: true,
    validators: [isRequiredRule],
  },
};

const OKTA_CERTIFICATE_FIELD = {
  id: FIELD_IDS.OKTA_CERTIFICATE,
  renderer: TextAreaInput,
  renderOptions: {
    label: __('Certificate'),
    placeholder: __('Enter Certificate'),
    required: true,
    validators: [isRequiredRule],
    rows: 6,
  },
};

const REDIRECT_URL_FIELD = {
  id: FIELD_IDS.REDIRECT_URL,
  renderer: TextInput,
  renderOptions: {
    label: __('Redirect URL'),
    placeholder: __('Enter Redirect URL'),
    required: true,
    validators: [isRequiredRule],
  },
};

const CLIENT_SECRET_FIELD = {
  id: FIELD_IDS.CLIENT_SECRET,
  renderer: TextAreaInput,
  renderOptions: {
    label: __('Client Secret'),
    placeholder: __('Enter Client Secret'),
    required: true,
    validators: [isRequiredRule],
  },
};
const MFA_ENABLED_FIELD = {
  id: FIELD_IDS.MFA_ENABLED,
  renderer: SwitchRenderer,
  renderOptions: {
    label: __('MFA Enabled'),
  },
};

const MFA_AUTH_TYPES_FIELD = {
  id: FIELD_IDS.MFA_AUTH_TYPES,
  renderer: SelectInput,
  renderOptions: {
    isMulti: true,
    label: __('MFA Auth Types'),
    placeholder: __('Select MFA auth types'),
    options: MFA_AUTH_TYPE_OPTIONS,
  },
};

const getAuthConfigFormFields = (providerTypeOptions) => ({
  [FIELD_IDS.CLIENT_ID]: CLIENT_ID_FIELD,
  [FIELD_IDS.CLIENT_SECRET]: CLIENT_SECRET_FIELD,
  [FIELD_IDS.PROVIDER_TYPE]: addToRenderOptions(PROVIDER_TYPE_FIELD, [{ path: 'options', value: providerTypeOptions }]),
  [FIELD_IDS.IP_SSO_URL]: IP_SSO_URL_FIELD,
  [FIELD_IDS.OKTA_CERTIFICATE]: OKTA_CERTIFICATE_FIELD,
  [FIELD_IDS.REDIRECT_URL]: REDIRECT_URL_FIELD,
  [FIELD_IDS.MFA_ENABLED]: MFA_ENABLED_FIELD,
  [FIELD_IDS.MFA_AUTH_TYPES]: MFA_AUTH_TYPES_FIELD,
});

export default getAuthConfigFormFields;

export { FIELD_IDS };
