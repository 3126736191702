const GENERAL_DETAILS_FORM_FIELD_IDS = {
  DISPLAY_NAME: 'displayName',
  NAME: 'name',
  FIELD_DATA_TYPE: 'dataType',
  FIELD_TYPE: 'fieldType',
  MULTI_VALUED: 'multiValued',
  PLACEHOLDER: 'placeholder',
  HELP_TEXT: 'helpText',
};

export default GENERAL_DETAILS_FORM_FIELD_IDS;
