import _pick from 'lodash/pick';
import _valuesIn from 'lodash/valuesIn';
import _includes from 'lodash/includes';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';

import { updateSelectedViewComponent } from '../../organisms/propertiesForm/propertiesForm.helper';

import { FIELD_IDS, CUSTOM_ON_CHANGE_FIELD_IDS } from './formViewColumnPropertiesForm.constants';
import {
  PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS,
  VIEW_COMPONENT_UPDATE_ACTION_ID,
  VIEW_COMPONENT_UPDATE_ACTION_TYPE,
} from '../../organisms/propertiesForm/propertiesForm.constants';

const ARRAY_OF_FIELD_IDS = _valuesIn(FIELD_IDS);

const getFormViewColumnPropertiesFromProperties = (properties) => _pick(properties, ARRAY_OF_FIELD_IDS);

const componentRendererOnChangeHandler = (selectedViewComponent, payload) => {
  const id = tget(payload, 'id');
  let value = tget(payload, 'value');

  value = getArraySafeValue(value);

  const propertiesToUpdate = [
    { [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_NAME]: id, [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_VALUE]: value },
  ];

  const action = {
    [VIEW_COMPONENT_UPDATE_ACTION_ID.ACTION_TYPE]: VIEW_COMPONENT_UPDATE_ACTION_TYPE.PROPERTIES_UPDATE,
    [VIEW_COMPONENT_UPDATE_ACTION_ID.PAYLOAD]: propertiesToUpdate,
  };

  const newSelectedViewComponent = updateSelectedViewComponent(selectedViewComponent, action);

  return newSelectedViewComponent;
};

const customOnChangeHandlerByFieldId = {
  [FIELD_IDS.COMPONENT_RENDERER]: componentRendererOnChangeHandler,
};

const getUpdatedSelectedViewComponentFromOnChangeValue = (selectedViewComponent, payload = EMPTY_OBJECT) => {
  const { id, value } = payload;

  if (!_includes(CUSTOM_ON_CHANGE_FIELD_IDS, id)) {
    const propertiesToUpdate = [
      { [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_NAME]: id, [PROPERTIES_VALUE_TO_UPDATE_OBJECT_KEYS.PROPERTY_VALUE]: value },
    ];

    const action = {
      [VIEW_COMPONENT_UPDATE_ACTION_ID.ACTION_TYPE]: VIEW_COMPONENT_UPDATE_ACTION_TYPE.PROPERTIES_UPDATE,
      [VIEW_COMPONENT_UPDATE_ACTION_ID.PAYLOAD]: propertiesToUpdate,
    };

    const newSelectedViewComponent = updateSelectedViewComponent(selectedViewComponent, action);

    return newSelectedViewComponent;
  }

  const resultFromCustomOnChange = customOnChangeHandlerByFieldId[id](selectedViewComponent, payload);

  return resultFromCustomOnChange;
};

export { getFormViewColumnPropertiesFromProperties, getUpdatedSelectedViewComponentFromOnChangeValue };
