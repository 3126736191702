import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _valuesIn from 'lodash/valuesIn';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT, EMPTY_STRING, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Loader from '@tekion/tekion-components/molecules/loader';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

import WithSize from '../../../../../../connectors/withSize';
import GridLayout from '../../../../../../organisms/reporting/molecules/gridLayout';
import ErrorMessage from './components/errorMessage/errorMessage';
import WidgetContainer from '../../../../../../organisms/reporting/molecules/widgetContainer/WidgetContainer';

import styles from './leftPanel.module.scss';

const LeftPanel = (props) => {
  const {
    contentHeight,
    contentWidth,
    layoutsByName,
    leftPanelLoading,
    dashboardName,
    history,
    widgetItems,
    handleWidgetEdit,
    handleLayoutUpdate,
    addWidgetDropdownMenu,
    handleWidgetRemove,
    updatedWidget,
  } = props;

  const renderWidget = useCallback(
    (widget) => (
      <div key={widget?.widgetName}>
        <WidgetContainer
          key={widget?.widgetName}
          history={history}
          dashboardName={dashboardName}
          widgetLayout={layoutsByName[widget?.widgetName]}
          isEditable
          handleWidgetEdit={handleWidgetEdit}
          handleWidgetRemove={handleWidgetRemove}
          isWidgetUpdated={updatedWidget === widget?.widgetName}
          {...widget}
        />
      </div>
    ),
    [dashboardName, layoutsByName, handleWidgetEdit, handleWidgetRemove, history, updatedWidget],
  );

  const layouts = useMemo(() => _valuesIn(layoutsByName), [layoutsByName]);

  const handleLayoutChange = useCallback((params) => handleLayoutUpdate(params), [handleLayoutUpdate]);

  return (
    <PropertyControlledComponent controllerProperty={!leftPanelLoading} fallback={<Loader className="full-width full-height align-center" />}>
      <Page className={styles.container}>
        <Page.Body style={{ height: contentHeight }}>
          <PropertyControlledComponent controllerProperty={_isEmpty(widgetItems)}>
            <ErrorMessage className="full-width full-height align-center" addWidgetDropdownMenu={addWidgetDropdownMenu} />
          </PropertyControlledComponent>
          <GridLayout width={0.86 * contentWidth} layouts={layouts} onLayoutChange={handleLayoutChange}>
            {_map(widgetItems, renderWidget)}
          </GridLayout>
        </Page.Body>
      </Page>
    </PropertyControlledComponent>
  );
};

LeftPanel.propTypes = {
  leftPanelLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  contentWidth: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  dashboardName: PropTypes.string,
  layoutsByName: PropTypes.object,
  widgetItems: PropTypes.array,
  updatedWidget: PropTypes.string,
  addWidgetDropdownMenu: PropTypes.node,
  handleWidgetEdit: PropTypes.func,
  handleWidgetRemove: PropTypes.func,
  handleLayoutUpdate: PropTypes.func,
};

LeftPanel.defaultProps = {
  leftPanelLoading: true,
  dashboardName: EMPTY_STRING,
  layoutsByName: EMPTY_OBJECT,
  widgetItems: EMPTY_ARRAY,
  updatedWidget: EMPTY_STRING,
  addWidgetDropdownMenu: null,
  handleWidgetRemove: _noop,
  handleWidgetEdit: _noop,
  handleLayoutUpdate: _noop,
};

export default WithSize({ hasPageFooter: 1, hasPageHeader: 1 })(LeftPanel);
