import _get from 'lodash/get';
import { NO_DATA, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { VIEW_TYPE_MAPPER } from '../constants/exportListPage.constants';
import { convertTimeStamp } from '../utils/exportListPage.utils';

const createdTimeReader = (params) => convertTimeStamp(tget(params, 'createdTime', EMPTY_STRING));
const exportTaskIdReader = (params) => tget(params, 'id', EMPTY_STRING);
const exportTemplateEntityNameReader = (params) => tget(params, 'exportTemplate.entityName') || NO_DATA;
const downloadTypeReader = (params) => tget(params, 'downloadType') || NO_DATA;
const viewNameReader = (params) => tget(params, 'manualInput.displayName') || NO_DATA;
const viewTypeReader = (params) => _get(VIEW_TYPE_MAPPER, tget(params, 'manualInput.viewType')) || NO_DATA;

export default {
  createdTimeReader,
  exportTaskIdReader,
  exportTemplateEntityNameReader,
  downloadTypeReader,
  viewNameReader,
  viewTypeReader,
};
