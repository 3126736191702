exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".leftPanel_container__7qCXdQ3H5m{height:100%;width:100%;flex:1 1 75%;display:flex;flex-flow:column nowrap;overflow:auto}[dir] .leftPanel_container__7qCXdQ3H5m{padding:1.2rem 0}[dir] .leftPanel_message__e4WmA3UiqW{padding:1rem;text-align:center}.leftPanel_createWidgetContainer__bpnXhHJ2fq{display:flex;justify-content:center;align-items:center;flex-flow:row nowrap}.leftPanel_createWidgetButton__rKRvHhneUR{display:flex;justify-content:space-between;align-items:center;flex-flow:row nowrap}[dir] .leftPanel_createWidgetButton__rKRvHhneUR>*{margin:auto .3rem}", ""]);

// Exports
exports.locals = {
	"container": "leftPanel_container__7qCXdQ3H5m",
	"message": "leftPanel_message__e4WmA3UiqW",
	"createWidgetContainer": "leftPanel_createWidgetContainer__bpnXhHJ2fq",
	"createWidgetButton": "leftPanel_createWidgetButton__rKRvHhneUR"
};