import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { tget } from '@tekion/tekion-base/utils/general';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

// Actions
import { fetchCategories, createCategory } from '../../../../../../../actions/approvalManagement.actions';

// Helpers
import { getPayload } from './approvalCategoryList.helpers';

// Constants
import ACTION_TYPES from '../constants/approvalCategoryList.actionTypes';
import COLUMN_IDS from '../constants/approvalCategoryList.columnIds';

const fetchData = async ({ getState, setState }) => {
  setState({ isLoading: true });
  const {
    pageSize = DEFAULT_PAGE_SIZE,
    currentPage = DEFAULT_PAGE_INFO.start,
    currentPageToken = EMPTY_STRING,
    searchText = EMPTY_STRING,
  } = getState();

  const payload = getPayload({ currentPage, currentPageToken, pageSize, searchText });

  const response = await fetchCategories(payload);
  const data = tget(response, 'hits', EMPTY_ARRAY);
  const nextPageToken = _get(response, 'nextPageToken', EMPTY_STRING);
  setState({ data, isLoading: false, nextPageToken });
};

const handleSearch = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ searchText: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => fetchData({ getState, setState }));
};

const handlePageUpdate = ({ params, getState, setState }) => {
  const { page, resultsPerPage } = _get(params, 'value', EMPTY_OBJECT);
  const { currentPage, pageSize, nextPageToken, previousPageTokens = [], currentPageToken } = getState();
  let newPageNumber = page;
  let newTokenNumber = null;
  let newPrevPageTokens = [...previousPageTokens];

  if (resultsPerPage !== pageSize) {
    newPageNumber = 1;
    newPrevPageTokens = [];
  } else if (page > currentPage + 1) {
    newTokenNumber = nextPageToken;
    newPrevPageTokens.push(currentPageToken);
  } else if (page !== 1) {
    newTokenNumber = newPrevPageTokens.pop();
  } else {
    newPrevPageTokens = [];
  }

  setState(
    { currentPage: newPageNumber - 1, pageSize: resultsPerPage, currentPageToken: newTokenNumber, previousPageTokens: newPrevPageTokens },
    () => fetchData({ getState, setState }),
  );
};

const handleFilterChange = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ selectedFilters: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => fetchData({ getState, setState }));
};

const handleCreateApprovalCategoryClick = ({ setState }) => {
  setState({ isCategoryAddModalVisible: true });
};

const handleFieldChange = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { values, errors } = getState();
  const { id, value } = params;
  setState({ values: { ...values, [id]: value } });

  if (!_isEmpty(value)) {
    setState({ errors: { ...errors, [id]: undefined } });
  }
};

const handleCancelCategoryCreation = ({ setState }) => {
  setState({ isCategoryAddModalVisible: false, values: {}, errors: {} });
};

const handleSubmitCategoryCreation = async ({ getState, setState }) => {
  const { values } = getState();
  if (_isEmpty(values) || _isEmpty(values?.[COLUMN_IDS.CATEGORY])) {
    setState({ errors: { [COLUMN_IDS.CATEGORY]: __('Please enter a name for category.') } });
  } else {
    setState({ isCategoryCreationInProgress: true, errors: {} });

    const response = await createCategory(values);

    if (!_isEmpty(response)) {
      setTimeout(() => {
        setState({ isCategoryCreationInProgress: false, isCategoryAddModalVisible: false, values: {} });
        fetchData({ getState, setState });
      }, ES_REFETCH_DELAY);
    } else {
      setState({ isCategoryCreationInProgress: false });
    }
  }
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleFilterChange,
  [ACTION_TYPES.CREATE_CATEGORY_CLICK]: handleCreateApprovalCategoryClick,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [ACTION_TYPES.CANCEL_CATEGORY_CREATION]: handleCancelCategoryCreation,
  [ACTION_TYPES.SUBMIT_CATEGORY_CREATION]: handleSubmitCategoryCreation,
};

export default ACTION_HANDLERS;
