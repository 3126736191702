import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

// Components
import TextArea from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import NumberInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';
import EntityAsyncSelect from '../../../../molecules/EntityAsyncSelect/EntityAsyncSelect';

// Utils
import { nameValidator } from '../../../../../../utils/formValidators';

// Constants
import { RULE_FIELD_IDS, PROCESS_FIELD_IDS } from './workflow.fieldIds';
import { WORKFLOW_BUILDER_MODE } from '../../../../../../constants/general.constants';

const getFieldsForProcess = (eventTypes) => ({
  [PROCESS_FIELD_IDS.DISPLAY_NAME]: {
    id: PROCESS_FIELD_IDS.DISPLAY_NAME,
    renderer: TextInput,
    renderOptions: {
      required: true,
      label: __('Display Name'),
      validators: [isRequiredRule],
      placeholder: __('Enter Display Name'),
    },
  },
  [PROCESS_FIELD_IDS.NAME]: {
    id: PROCESS_FIELD_IDS.NAME,
    renderer: TextInput,
    renderOptions: {
      required: true,
      label: __('Name'),
      validators: [isRequiredRule, nameValidator],
      placeholder: __('Name will be autofilled'),
    },
  },
  [PROCESS_FIELD_IDS.DESCRIPTION]: {
    id: PROCESS_FIELD_IDS.DESCRIPTION,
    renderer: TextArea,
    renderOptions: {
      required: true,
      rows: 3,
      label: __('Description'),
      placeholder: __('Add a description'),
      validators: [isRequiredRule],
    },
  },
  [PROCESS_FIELD_IDS.ENTITY]: {
    // Async Entity Select to be used here.
    id: PROCESS_FIELD_IDS.ENTITY,
    renderer: EntityAsyncSelect,
    renderOptions: {
      label: __('Entity'),
      placeholder: __('Select entity'),
      required: true,
      validators: [isRequiredRule],
    },
  },

  [PROCESS_FIELD_IDS.EVENT_TYPE]: {
    id: PROCESS_FIELD_IDS.EVENT_TYPE,
    renderer: SelectInput,
    renderOptions: {
      size: 2,
      placeholder: __('Choose trigger'),
      label: __('Process trigger'),
      options: eventTypes,
    },
  },
});

const getFieldsForRule = (eventTypes) => ({
  [RULE_FIELD_IDS.DISPLAY_NAME]: {
    id: RULE_FIELD_IDS.DISPLAY_NAME,
    renderer: TextInput,
    renderOptions: {
      required: true,
      label: __('Display Name'),
      validators: [isRequiredRule],
      placeholder: __('Enter Display Name'),
    },
  },
  [RULE_FIELD_IDS.NAME]: {
    id: RULE_FIELD_IDS.NAME,
    renderer: TextInput,
    renderOptions: {
      required: true,
      label: __('Name'),
      validators: [isRequiredRule, nameValidator],
      placeholder: __('Name will be autofilled'),
    },
  },
  [RULE_FIELD_IDS.DESCRIPTION]: {
    id: RULE_FIELD_IDS.DESCRIPTION,
    renderer: TextArea,
    renderOptions: {
      rows: 3,
      label: __('Description'),
      placeholder: __('Add a description'),
    },
  },
  [RULE_FIELD_IDS.ENTITY]: {
    // Async Entity Select to be used here.
    id: RULE_FIELD_IDS.ENTITY,
    renderer: EntityAsyncSelect,
    renderOptions: {
      label: __('Entity'),
      placeholder: __('Select entity'),
      required: true,
      validators: [isRequiredRule],
    },
  },
  [RULE_FIELD_IDS.EVENT_TYPE]: {
    id: RULE_FIELD_IDS.EVENT_TYPE,
    renderer: SelectInput,
    renderOptions: {
      size: 2,
      placeholder: __('Choose trigger'),
      label: __('Rule trigger'),
      options: eventTypes,
    },
  },
  [RULE_FIELD_IDS.PRIORITY]: {
    id: RULE_FIELD_IDS.PRIORITY,
    renderer: NumberInput,
    renderOptions: {
      label: __('Priority'),
      required: true,
      validators: [isRequiredRule],
      placeholder: __('Priority of rule'),
    },
  },
});

const getFields = (workflowMode, eventTypes) =>
  workflowMode === WORKFLOW_BUILDER_MODE.PROCESS ? getFieldsForProcess(eventTypes) : getFieldsForRule(eventTypes);

export default getFields;
