import _get from 'lodash/get';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import { COMPONENT_TYPES, VIEW_TYPES } from '../../constants/viewBuilder.constants';
import { CONFIGURATOR_COMPONENT_PANELS } from './configuratorForm.constant';
import { CELL_TYPES } from '../../../../constants/viewBuilder.constants';

import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

const getComponentPanels = (viewType) => tget(CONFIGURATOR_COMPONENT_PANELS, viewType, EMPTY_ARRAY);

const getFieldNames = (fieldDefinition) => {
  const name = fieldDefinitionReader.name(fieldDefinition);
  const fieldType = fieldDefinitionReader.fieldType(fieldDefinition);

  if (fieldType === FIELD_TYPES.RELATIONSHIP) {
    const lookupField = fieldDefinitionReader.lookupFieldDisplayField(fieldDefinition);
    return `${name}.${lookupField}`;
  }

  return name;
};

const getCompoundFieldPanel = (viewType) => {
  let componentType = '';

  switch (viewType) {
    case VIEW_TYPES.DETAIL_VIEW:
      componentType = COMPONENT_TYPES.DETAIL_VIEW_ATTRIBUTE;
      break;
    case VIEW_TYPES.LIST_VIEW:
      componentType = COMPONENT_TYPES.LIST_VIEW_COLUMN;
      break;
    case VIEW_TYPES.CELL_VIEW:
      componentType = COMPONENT_TYPES.CELL_VIEW_ATTRIBUTE;
      break;
    case VIEW_TYPES.GRID_VIEW:
      componentType = COMPONENT_TYPES.GRID_VIEW_ATTRIBUTE;
      break;
    default:
      componentType = EMPTY_STRING;
  }

  if (viewType === VIEW_TYPES.FORM_VIEW) {
    return EMPTY_ARRAY;
  }

  return [
    {
      panelId: 'compound',
      panelName: 'Compound Field',
      components: [
        {
          type: componentType,
          index: 0,
          viewType,
          properties: {
            title: __('Compound Field'),
            cellType: CELL_TYPES.COMPOUND,
            fieldNames: [],
            template: '',
            viewType,
          },
        },
      ],
    },
  ];
};

const getEntityPanels = (viewType, entity) => {
  const entityName = _get(entity, 'displayName', EMPTY_STRING);
  const entityFieldDefinitions = _get(entity, 'fieldDefinitions', EMPTY_ARRAY);
  let components;

  switch (viewType) {
    case VIEW_TYPES.DETAIL_VIEW:
      components = entityFieldDefinitions.map((entityFieldDefinition, index) => {
        const sectionId = _get(entityFieldDefinition, 'id', EMPTY_STRING);
        const name = getFieldNames(entityFieldDefinition);
        const title = _get(entityFieldDefinition, 'displayName', EMPTY_STRING);

        return {
          sectionId,
          index,
          viewType,
          type: COMPONENT_TYPES.DETAIL_VIEW_ATTRIBUTE,
          properties: {
            title,
            viewType,
            cellType: CELL_TYPES.SIMPLE,
            fieldNames: [name],
            template: `$\{${name}}`,
          },
        };
      });
      break;
    case VIEW_TYPES.CELL_VIEW:
      components = entityFieldDefinitions.map((entityFieldDefinition, index) => {
        const sectionId = _get(entityFieldDefinition, 'id', EMPTY_STRING);
        const title = _get(entityFieldDefinition, 'displayName', EMPTY_STRING);
        const name = getFieldNames(entityFieldDefinition);

        return {
          sectionId,
          index,
          viewType,
          type: COMPONENT_TYPES.CELL_VIEW_ATTRIBUTE,
          properties: {
            title,
            viewType,
            cellType: CELL_TYPES.SIMPLE,
            fieldNames: [name],
            template: `$\{${name}}`,
          },
        };
      });
      break;

    case VIEW_TYPES.LIST_VIEW:
      components = entityFieldDefinitions.map((entityFieldDefinition, index) => {
        const sectionId = _get(entityFieldDefinition, 'id', EMPTY_STRING);
        const name = getFieldNames(entityFieldDefinition);
        const title = _get(entityFieldDefinition, 'displayName', EMPTY_STRING);

        return {
          sectionId,
          index,
          viewType,
          type: COMPONENT_TYPES.LIST_VIEW_COLUMN,
          properties: {
            title,
            viewType,
            cellType: CELL_TYPES.SIMPLE,
            fieldNames: [name],
            template: `$\{${name}}`,
          },
        };
      });
      break;

    case VIEW_TYPES.GRID_VIEW:
      components = entityFieldDefinitions.map((entityFieldDefinition, index) => {
        const sectionId = _get(entityFieldDefinition, 'id', EMPTY_STRING);
        const name = getFieldNames(entityFieldDefinition);
        const title = _get(entityFieldDefinition, 'displayName', EMPTY_STRING);

        return {
          sectionId,
          index,
          viewType,
          type: COMPONENT_TYPES.GRID_VIEW_ATTRIBUTE,
          properties: {
            title,
            viewType,
            cellType: CELL_TYPES.SIMPLE,
            fieldNames: [name],
            template: `$\{${name}}`,
          },
        };
      });
      break;

    case VIEW_TYPES.FORM_VIEW:
    default:
      components = entityFieldDefinitions.map((entityFieldDefinition, index) => {
        const id = _get(entityFieldDefinition, 'id', EMPTY_STRING);
        const name = _get(entityFieldDefinition, 'name');
        const title = _get(entityFieldDefinition, 'displayName', EMPTY_STRING);

        return {
          sectionId: id,
          index,
          viewType,
          type: COMPONENT_TYPES.FORM_VIEW_COLUMN,
          properties: {
            title,
            viewType,
            cellType: CELL_TYPES.SIMPLE,
            fieldNames: [name],
            template: `$\{${name}}`,
          },
        };
      });
  }

  return [
    {
      panelId: 'attributes',
      panelName: `Attributes - ${entityName}`,
      components,
    },
    ...getCompoundFieldPanel(viewType),
  ];
};

const getUpdatedSelectedComponentProperties = (selectedViewComponentProperties, payload) => {
  const newViewComponentProperties = _cloneDeep(selectedViewComponentProperties);

  _forEach(payload, (config) => {
    const id = _get(config, 'id');
    const value = _get(config, 'value');

    if (newViewComponentProperties) {
      _set(newViewComponentProperties, id, value);
    }
  });

  return newViewComponentProperties;
};

export { getEntityPanels, getComponentPanels, getUpdatedSelectedComponentProperties };
