import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import _isFunction from 'lodash/isFunction';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';
import _set from 'lodash/set';
import _keyBy from 'lodash/keyBy';

import { tget } from '@tekion/tekion-base/utils/general';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import Modal from '@tekion/tekion-components/molecules/Modal';
import Loader from '@tekion/tekion-components/molecules/loader';
import Checkbox from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import CreatableSelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/CreatableSelectInput';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';

import getFields from './helpers/recordActionModal.fields';
import getSections from './helpers/recordActionModal.sections';
import { generateUserData, getAssignedVariableInfo } from './helpers/recordActionModal.payloadHelpers';
import { getFormValues } from './helpers/recordActionModal.formValuesHelpers';
import { getVariableOptions, getUsedVariables, isUpsertOrUpdateActionType } from './helpers/recordActionModal.helpers';

import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../../../constants/actionBuilder.constants';
import {
  ACTION_TYPE_TO_MODAL_TITLE_MAP,
  CONTEXT_ID,
  ACTION_DEFINITION_LOADER,
  FILTER_CONDITION_LOADER,
  ACTION_TYPES,
} from './constants/recordActionModal.constants';
import {
  ACTION_BUILDER_ACTION_TYPES,
  FILTER_CONDITION_VARIABLE_NAME,
  ACTION_DEFINITION_CONDITION_VARIABLE_NAME,
} from '../../../../../actionBuilder/actionBuilderForm/constants/actionBuilderForm.general';
import { FIELD_IDS as TEMPLATE_BUILDER_FIELD_IDS } from '../../../../../../../../constants/templateBuilder.constants';
import { FIELD_EVENT_ACTION_NAMES } from '../../../constants/workflow.constants';

import styles from './recordActionModal.module.scss';

const RecordActionModal = ({
  isLoading,
  visible,
  mode,
  parentNodeId,
  entityName,
  stepDetails,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  onEntityChange,
  variablesInfo,
  variablesByNodeId,
  onSubmit,
  onCancel,
  ...rest
}) => {
  const actionType = tget(stepDetails, 'taskDefId');
  const nodeId = tget(stepDetails, 'nodeId') || parentNodeId;
  const [formValues, setFormValues] = useState({});
  const [variableOptions, setVariableOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [filterConditionVariableMap, setFilterConditionVariableMap] = useState({});
  const [actionDefinitionVariableMap, setActionDefinitionVariableMap] = useState({});
  const [emailTemplateDefStore, setEmailTemplateDefStore] = useState({});

  const isTargetFieldsLoading = tget(rest, FILTER_CONDITION_LOADER) || tget(rest, ACTION_DEFINITION_LOADER);

  const sections = getSections(entityName, formValues, isTargetFieldsLoading);
  const fields = useMemo(
    () =>
      getFields(
        mode,
        formValues,
        conditionBuilderFieldDefinitionObject,
        mapOfVariableToEntityName,
        filterConditionVariableMap,
        actionDefinitionVariableMap,
        emailTemplateDefStore,
      ),
    [
      mode,
      formValues,
      conditionBuilderFieldDefinitionObject,
      mapOfVariableToEntityName,
      filterConditionVariableMap,
      actionDefinitionVariableMap,
      emailTemplateDefStore,
    ],
  );

  const setVariableMaps = (targetEntity) => {
    const _filterConditionVariableMap = {
      [FILTER_CONDITION_VARIABLE_NAME]: targetEntity,
    };
    const _actionDefinitionVariableMap = {
      [ACTION_DEFINITION_CONDITION_VARIABLE_NAME]: targetEntity,
    };

    setFilterConditionVariableMap(_filterConditionVariableMap);
    setActionDefinitionVariableMap(_actionDefinitionVariableMap);

    return {
      _filterConditionVariableMap,
      _actionDefinitionVariableMap,
    };
  };

  const handleAction = (action) => {
    const type = tget(action, 'type');
    const payload = tget(action, 'payload');

    switch (type) {
      case ACTION_TYPES.ON_ASYNC_LOADED_OPTIONS: {
        const { value = EMPTY_ARRAY } = payload;
        const newValues = _keyBy(value, TEMPLATE_BUILDER_FIELD_IDS.NAME);
        setEmailTemplateDefStore({ ...emailTemplateDefStore, ...newValues });
        break;
      }
      case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
        const { id, value, eventDetails } = payload;

        setFormValues((_values) => {
          if (id === ACTION_DEFINITION_FIELD_IDS.ASSIGNED_VARIABLE && tget(eventDetails, 'action') === FIELD_EVENT_ACTION_NAMES.CREATE_OPTION) {
            const targetEntityName = getArraySafeValue(tget(formValues, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME, ['']));
            setVariableOptions([
              ...getVariableOptions(variablesInfo, targetEntityName, tget(variablesByNodeId, nodeId)),
              { label: getArraySafeValue(value), value: getArraySafeValue(value) },
            ]);
            return { ..._values, [id]: value };
          }
          return { ..._values, [id]: value };
        });

        if (id === ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME && _isFunction(onEntityChange)) {
          const targetEntity = getArraySafeValue(value);
          setVariableOptions(getVariableOptions(variablesInfo, targetEntity, tget(variablesByNodeId, nodeId)));
          const { _filterConditionVariableMap, _actionDefinitionVariableMap } = setVariableMaps(targetEntity);

          onEntityChange(_filterConditionVariableMap, FILTER_CONDITION_LOADER);
          onEntityChange(_actionDefinitionVariableMap, ACTION_DEFINITION_LOADER);
        }
        break;
      }
      case FORM_ACTION_TYPES.ON_FIELD_BLUR: {
        const { id, value, newConditionExpression } = payload;
        if (!_isEmpty(newConditionExpression)) {
          setErrors({ ...errors, [id]: value });
        } else {
          setErrors({ ...errors, [id]: undefined });
        }
        break;
      }
      case FORM_ACTION_TYPES.VALIDATION_SUCCESS: {
        const { errors: newErrors } = payload;
        setErrors(newErrors);
        break;
      }
      case FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT: {
        const userData = generateUserData(formValues, entityName);
        const assignedVariableInfo = getAssignedVariableInfo(formValues);
        const usedVariables = getUsedVariables(userData);
        onSubmit(userData, [], assignedVariableInfo, usedVariables);
        break;
      }

      default:
    }
  };

  const handleCancel = () => {
    setFormValues({});
    setErrors({});
    onCancel();
  };

  const handleSubmit = () => {
    triggerSubmit(CONTEXT_ID);
  };

  const renderFooterLeftSection = () => {
    const assignVariable = tget(formValues, ACTION_DEFINITION_FIELD_IDS.SHOULD_ASSIGN_VARIABLE, false);
    const assignedVariable = tget(formValues, ACTION_DEFINITION_FIELD_IDS.ASSIGNED_VARIABLE);
    if (actionType !== ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL) {
      return (
        <div className={styles.footerLeftSection}>
          <Checkbox
            id={ACTION_DEFINITION_FIELD_IDS.SHOULD_ASSIGN_VARIABLE}
            value={assignVariable}
            className={styles.assignVarCheckbox}
            checkboxLabel={__('Assign Variable')}
            onAction={handleAction}
          />
          {assignVariable && (
            <CreatableSelectInput
              id={ACTION_DEFINITION_FIELD_IDS.ASSIGNED_VARIABLE}
              options={variableOptions}
              value={assignedVariable}
              onAction={handleAction}
            />
          )}
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    const _formValues = { [ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE]: actionType };

    if (isUpsertOrUpdateActionType(actionType)) {
      _set(_formValues, ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD, false);
    }

    const userData = tget(stepDetails, 'userData.data');
    if (!_isEmpty(userData)) {
      const targetEntityName = tget(userData, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME);

      if (!_isEmpty(targetEntityName) && actionType !== ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL) {
        const { _filterConditionVariableMap, _actionDefinitionVariableMap } = setVariableMaps(targetEntityName);
        onEntityChange(_filterConditionVariableMap, FILTER_CONDITION_LOADER);
        onEntityChange(_actionDefinitionVariableMap, ACTION_DEFINITION_LOADER);
        setVariableOptions(getVariableOptions(variablesInfo, targetEntityName, tget(variablesByNodeId, nodeId)));
      }
    }

    const updatedUserData = getFormValues(userData, _formValues, variablesInfo, stepDetails);

    setFormValues(updatedUserData);

    // Following dependencies are not supposed to change definition during runtime, when a RecordActionModal is open.
    // This useEffect is supposed to run only once.
  }, [actionType, stepDetails, onEntityChange, variablesInfo, nodeId, variablesByNodeId]);

  return (
    <Modal
      className={styles.modalBodyContainer}
      destroyOnClose
      visible={visible}
      title={ACTION_TYPE_TO_MODAL_TITLE_MAP[actionType]}
      width={SIZES.XL}
      submitBtnText={__('Done')}
      renderFooterLeftSection={renderFooterLeftSection}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    >
      <FormWithSubmission
        isFetching={isLoading}
        className={styles.recordActionModalContentContainer}
        contextId={CONTEXT_ID}
        sections={sections}
        fields={fields}
        values={formValues}
        errors={errors}
        onAction={handleAction}
      />
      {isTargetFieldsLoading && <Loader id="TARGET_FIELDS_LOADING" />}
    </Modal>
  );
};

RecordActionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isTargetFieldsLoading: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  parentNodeId: PropTypes.string.isRequired,
  stepDetails: PropTypes.object.isRequired,
  variablesInfo: PropTypes.object,
  variablesByNodeId: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object.isRequired,
  targetEntityFields: PropTypes.object,
  targetEntityComplexFieldDefinition: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.array.isRequired,
  onEntityChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

RecordActionModal.defaultProps = {
  isLoading: false,
  isTargetFieldsLoading: false,
  variablesInfo: EMPTY_OBJECT,
  variablesByNodeId: EMPTY_OBJECT,
  targetEntityFields: EMPTY_OBJECT,
  targetEntityComplexFieldDefinition: EMPTY_OBJECT,
  onEntityChange: _noop,
};

export default RecordActionModal;
