import { CHECKBOX_FIELD_IDS } from '../constants/formFieldsCheckbox.constants';

const getDisabledFieldBasedOnDefaultValue = (id, defaultValue = false) => {
  switch (id) {
    case CHECKBOX_FIELD_IDS.DISABLED: {
      return defaultValue;
    }
    case CHECKBOX_FIELD_IDS.MANDATORY: {
      return defaultValue;
    }
    case CHECKBOX_FIELD_IDS.EDITABLE: {
      return !defaultValue;
    }
    default: {
      return false;
    }
  }
};

export { getDisabledFieldBasedOnDefaultValue };
