exports = module.exports = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".accessTypeFilter_container__i5699a23wn{display:flex;flex-direction:row}[dir=ltr] .accessTypeFilter_filterClassName__95PCDnsNgE{margin-left:1.6rem}[dir=rtl] .accessTypeFilter_filterClassName__95PCDnsNgE{margin-right:1.6rem}.accessTypeFilter_readAndWriteIconClassName__hR48ExKXdm{color:#4ba343}.accessTypeFilter_accessDeniedIconClassName__5MGkFArahn{color:#c42106}[dir] .accessTypeFilter_selectedFilterClassName__w3QtvwAS97{background-color:#ebf4ff;border:.1rem solid #4285f4}", ""]);

// Exports
exports.locals = {
	"container": "accessTypeFilter_container__i5699a23wn",
	"filterClassName": "accessTypeFilter_filterClassName__95PCDnsNgE",
	"readAndWriteIconClassName": "accessTypeFilter_readAndWriteIconClassName__hR48ExKXdm",
	"accessDeniedIconClassName": "accessTypeFilter_accessDeniedIconClassName__5MGkFArahn",
	"selectedFilterClassName": "accessTypeFilter_selectedFilterClassName__w3QtvwAS97"
};