import { ACTION_BUILDER_ACTION_TYPES } from '../../../../../../actionBuilder/actionBuilderForm/constants/actionBuilderForm.general';
import { TASK_DEF_IDS } from '../../../../constants/workflow.constants';

const CONTEXT_ID = 'RECORD_ACTION_MODAL_FORM';

const FILTER_CONDITION_LOADER = 'isFilterConditionFieldsLoading';
const ACTION_DEFINITION_LOADER = 'isActionDefinitionFieldsLoading';

const ACTION_TYPE_TO_MODAL_TITLE_MAP = {
  [ACTION_BUILDER_ACTION_TYPES.CREATE_RECORD]: __('Action - Create record'),
  [ACTION_BUILDER_ACTION_TYPES.UPDATE_RECORD]: __('Action - Update record'),
  [ACTION_BUILDER_ACTION_TYPES.GET_RECORDS]: __('Action - Get records'),
  [ACTION_BUILDER_ACTION_TYPES.UPSERT_RECORD]: __('Action - Upsert record'),
  [ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL]: __('Send Email'),
};

const TARGET_ENTITY_LABELS = {
  [TASK_DEF_IDS.CREATE_RECORD]: __('Entity To Create Records'),
  [TASK_DEF_IDS.GET_RECORDS]: __('Entity To Get Records '),
  [TASK_DEF_IDS.UPDATE_RECORD]: __('Entity To Update Records'),
  [TASK_DEF_IDS.UPSERT_RECORD]: __('Entity To Upsert Records'),
};

const ACTION_TYPES = {
  ON_ASYNC_LOADED_OPTIONS: 'ON_ASYNC_LOADED_OPTIONS',
};

const ACTION_DEFINITION_HELP_TEXT_LABEL = {
  LABEL: __('Please ensure to set all the mandatory fields of target entity.'),
};

export {
  CONTEXT_ID,
  FILTER_CONDITION_LOADER,
  ACTION_DEFINITION_LOADER,
  ACTION_TYPE_TO_MODAL_TITLE_MAP,
  ACTION_DEFINITION_HELP_TEXT_LABEL,
  TARGET_ENTITY_LABELS,
  ACTION_TYPES,
};
