import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _get from 'lodash/get';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_STRING, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import CommentRenderer from '../../../commentRenderer';

import ViewBuilderContext from '../../ViewBuilder.context';
import ViewRendererContext from '../../../../context/viewRenderer.context';
import VisualBuilderEventEmitter from '../../../../eventEmitters/visualBuilderEventEmitter';

import { EVENT_ACTIONS } from '../../../../constants/eventActions.constants';
import { VIEW_CONTEXT_KEYS } from '../../constants/viewBuilder.constants';
import { APPLICATION_CONTEXT_KEYS } from '../../../../constants/applicationRenderer.constants';

import styles from './commentSection.module.scss';

const CommentSection = ({ isPreviewMode, className, entity, entityRecord, componentConfig }) => {
  const [showCommentSection, setShowCommentSection] = useState(!isPreviewMode);

  const { onSelect } = useContext(ViewBuilderContext);
  const viewContextValue = useContext(ViewRendererContext);

  const widgetName = tget(viewContextValue, `${VIEW_CONTEXT_KEYS.WIDGET_CONTEXT}.widgetName`, EMPTY_STRING);

  const currentUser = useMemo(
    () => tget(viewContextValue, [VIEW_CONTEXT_KEYS.APPLICATION_CONTEXT, APPLICATION_CONTEXT_KEYS.CURRENT_USER], EMPTY_OBJECT),
    [viewContextValue],
  );

  const entityName = _get(entity, 'name');
  const recordId = _get(entityRecord, 'id');

  const handleSelect = useCallback(
    (event) => {
      event.stopPropagation();

      onSelect(componentConfig.sectionId);
    },
    [componentConfig, onSelect],
  );

  useEffect(() => {
    const handleCommentIconClick = (payload) => {
      const eventRecordId = _get(payload, ['entityRecord', 'id']);
      if (eventRecordId === recordId) {
        setShowCommentSection((prevVal) => !prevVal);
      }
    };

    VisualBuilderEventEmitter.on(`${widgetName}:${EVENT_ACTIONS.TOGGLE_COMMENT_SECTION}`, handleCommentIconClick);

    return () => {
      VisualBuilderEventEmitter.removeAllListeners(`${widgetName}:${EVENT_ACTIONS.TOGGLE_COMMENT_SECTION}`);
    };
  }, [recordId, widgetName]);

  return (
    <div key={componentConfig.sectionId} className={cx(className, { [styles.commentRendererBuilderMode]: !isPreviewMode })} onClick={handleSelect}>
      {showCommentSection ? (
        <CommentRenderer isPreviewMode={isPreviewMode} assetType={`ENTITY#${entityName}`} assetId={recordId} currentUser={currentUser} />
      ) : null}
    </div>
  );
};

CommentSection.propTypes = {
  isPreviewMode: PropTypes.bool,
  className: PropTypes.string,
  componentConfig: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  entityRecord: PropTypes.object.isRequired,
};

CommentSection.defaultProps = {
  isPreviewMode: false,
  className: '',
};

export default CommentSection;
