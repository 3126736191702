const RECORD_TYPE_LIST_COLUMN_IDS = {
  VIEW_NAME: 'name',
  VIEW_TYPE: 'viewType',
  VIEW_CONFIG_TYPE: 'viewConfigType',
  DESCRIPTION: 'description',
  MODIFIED_BY: 'lastModifiedBy',
};

const ENTITY_VIEW_LIST_COLUMN_IDS = {
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  VIEW_TYPE: 'viewType',
  MODIFIED_BY: 'lastModifiedBy',
};

export { RECORD_TYPE_LIST_COLUMN_IDS, ENTITY_VIEW_LIST_COLUMN_IDS };
