import ApiService from './apiService/apiService';

const BASE_URL = 'core/u';

const fetchAllExportsData = (payload) => ApiService.post(`${BASE_URL}/export-service/export/search`, payload);
const sendDataForExport = (payload) => ApiService.post(`${BASE_URL}/cas/export/request`, payload);
const getFileDetailsForDownload = (id) => ApiService.get(`${BASE_URL}/export-service/downloads/${id}`);
const cancelTaskWithSelectedId = (id) => ApiService.post(`${BASE_URL}/export-service/export/${id}/cancel`);

export default { sendDataForExport, fetchAllExportsData, getFileDetailsForDownload, cancelTaskWithSelectedId };
