import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import ROUTES from '../../../constants/routes';

import ACTION_TYPES from '../constants/studioHomePage.actionTypes';
import { CLICKABLE_DIV_IDS } from '../constants/studioHomePage.general.constants';

const clickContainerHandler = ({ params, getState }) => {
  const { history } = getState();
  let path = EMPTY_STRING;
  if (params === CLICKABLE_DIV_IDS.CREATE_ENTITY) {
    path = ROUTES.ENTITY_CREATE_ROUTE;
  } else {
    path = ROUTES.APPLICATION_CREATE_ROUTE;
  }
  history.push({ pathname: path });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_CLICK_CONTAINER]: clickContainerHandler,
};

export default ACTION_HANDLERS;
