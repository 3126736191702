const ON_CANCEL = 'ON_CANCEL';
const INIT_FORM = 'INIT_FORM';
const CHANGE_FORM_TAB = 'CHANGE_FORM_TAB';
const ON_PREVIOUS_BUTTON_CLICK = 'ON_PREVIOUS_BUTTON_CLICK';
const OPTION_TABLE_ON_CHANGE = 'OPTION_TABLE_ON_CHANGE';
const OPTION_TABLE_REMOVE_ROW = 'OPTION_TABLE_REMOVE_ROW';
const OPTION_TABLE_SAVE_ROW = 'OPTION_TABLE_SAVE_ROW';
const OPTION_TABLE_ADD_ROW = 'OPTION_TABLE_ADD_ROW';
const OPTION_TABLE_MODAL_CLOSE = 'OPTION_TABLE_MODAL_CLOSE';
const ON_CLEAR_ALL = 'ON_CLEAR_ALL';
const CONFIG_TABLE_ON_CHANGE = 'CONFIG_TABLE_ON_CHANGE';
const CONFIG_TABLE_REMOVE_ROW = 'CONFIG_TABLE_REMOVE_ROW';
const CONFIG_TABLE_ADD_ROW = 'CONFIG_TABLE_ADD_ROW';

export default {
  ON_CANCEL,
  INIT_FORM,
  CHANGE_FORM_TAB,
  ON_PREVIOUS_BUTTON_CLICK,
  OPTION_TABLE_ON_CHANGE,
  OPTION_TABLE_REMOVE_ROW,
  OPTION_TABLE_SAVE_ROW,
  OPTION_TABLE_ADD_ROW,
  OPTION_TABLE_MODAL_CLOSE,
  ON_CLEAR_ALL,
  CONFIG_TABLE_ON_CHANGE,
  CONFIG_TABLE_REMOVE_ROW,
  CONFIG_TABLE_ADD_ROW,
};
