import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

const ROWS = 20;

const getHasMore = (nextPageToken) => !_isEmpty(nextPageToken);

const getLoadingMessage = () => __('Searching...');

const getWorkSpaceOptions = (data) =>
  _map(data, (user) => ({
    ...user,
    label: `${_get(user, 'entity.firstname', EMPTY_STRING)}  ${_get(user, 'entity.lastname')}`,
    value: _get(user, 'id', EMPTY_STRING),
  }));

const getTenantUniverseOptions = (data) =>
  _map(data, (user) => ({
    ...user,
    label: `${_get(user, 'firstName', EMPTY_STRING)}  ${_get(user, 'lastName')}`,
    value: _get(user, 'id', EMPTY_STRING),
  }));

const getPayload = (nextPageToken, searchText) => ({
  rows: ROWS,
  nextPageToken,
  searchText,
});

export { getPayload, getTenantUniverseOptions, getWorkSpaceOptions, getHasMore, getLoadingMessage };
