import _forEach from 'lodash/forEach';
import _castArray from 'lodash/castArray';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { FIELD_IDS } from '../constants/visualBuilderMetadataPage.constants';
import { COMPONENT_CONFIG_KEYS, PAGE_TYPES } from '../../../../../../constants/visualBuilder';

const getPageMetadata = (formValues, pageConfiguration) => {
  const entityName = getArraySafeValue(tget(formValues, FIELD_IDS.PAGE_ENTITY_NAME));
  const pageType = getArraySafeValue(tget(formValues, FIELD_IDS.PAGE_TYPE));
  let configProperties = [...tget(pageConfiguration, COMPONENT_CONFIG_KEYS.PROPERTIES, [])];

  if (pageType === PAGE_TYPES.RECORD_DETAIL_PAGE) {
    if (!_isEmpty(configProperties)) {
      configProperties = _map(configProperties, (property) => {
        const type = tget(property, 'type');
        if (type === FIELD_IDS.PAGE_ENTITY_NAME) {
          return { type, value: entityName };
        }
        return property;
      });
    } else {
      configProperties = [{ type: FIELD_IDS.PAGE_ENTITY_NAME, value: entityName }];
    }
  }

  return {
    ...pageConfiguration,
    [FIELD_IDS.PAGE_ENTITY_NAME]: entityName,
    [COMPONENT_CONFIG_KEYS.PROPERTIES]: configProperties,
    [FIELD_IDS.PAGE_NAME]: tget(formValues, FIELD_IDS.PAGE_NAME, EMPTY_STRING),
    [FIELD_IDS.PAGE_TITLE]: tget(formValues, FIELD_IDS.PAGE_TITLE, EMPTY_STRING),
    [FIELD_IDS.PAGE_TYPE]: pageType,
    [FIELD_IDS.PAGE_DESCRIPTION]: tget(formValues, FIELD_IDS.PAGE_DESCRIPTION, EMPTY_STRING),
  };
};

const getFormValuesFromPageConfiguration = (pageConfiguration) => {
  let formValues = {};
  const pageType = tget(pageConfiguration, FIELD_IDS.PAGE_TYPE);
  const pageConfigProperties = tget(pageConfiguration, COMPONENT_CONFIG_KEYS.PROPERTIES);
  let pageEntityName = '';
  formValues = {
    ...formValues,
    [FIELD_IDS.PAGE_TYPE]: _castArray(pageType),
    [FIELD_IDS.PAGE_NAME]: tget(pageConfiguration, FIELD_IDS.PAGE_NAME),
    [FIELD_IDS.PAGE_TITLE]: tget(pageConfiguration, FIELD_IDS.PAGE_TITLE),
    [FIELD_IDS.PAGE_DESCRIPTION]: tget(pageConfiguration, FIELD_IDS.PAGE_DESCRIPTION),
  };

  if (pageType === PAGE_TYPES.RECORD_DETAIL_PAGE) {
    _forEach(pageConfigProperties, (property) => {
      if (tget(property, 'type') === FIELD_IDS.PAGE_ENTITY_NAME) {
        pageEntityName = tget(property, 'value');
      }
    });
    formValues = { ...formValues, [FIELD_IDS.PAGE_ENTITY_NAME]: _castArray(pageEntityName) };
  }
  return formValues;
};

export { getPageMetadata, getFormValuesFromPageConfiguration };
