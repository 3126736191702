import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';

const FIELD_IDS = {
  COLUMN_COUNT: ALL_VIEW_PROPERTY_KEYS.COLUMN_COUNT,
  TITLE: ALL_VIEW_PROPERTY_KEYS.TITLE,
  SECTION_TITLE_SIZE: ALL_VIEW_PROPERTY_KEYS.SECTION_TITLE_SIZE,
  EVENT_HANDLERS: ALL_VIEW_PROPERTY_KEYS.EVENT_HANDLERS,
  CUSTOM_STYLES: ALL_VIEW_PROPERTY_KEYS.CUSTOM_STYLES,
};

const CUSTOM_ON_CHANGE_FIELD_IDS = [FIELD_IDS.COLUMN_COUNT];

export { FIELD_IDS, CUSTOM_ON_CHANGE_FIELD_IDS };
