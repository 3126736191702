exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".manageRecordAccessTable_manageRecordHeading__rm63Aipdmh{width:100%}[dir] .manageRecordAccessTable_manageRecordHeading__rm63Aipdmh{border-top:.1rem solid #d4d5d6;padding:1.6rem 0}[dir] .manageRecordAccessTable_container__4iDPPvzLsE{padding-top:1.6rem}[dir] .manageRecordAccessTable_accessDenied__7YwjRPm2Dh{background-color:#feeae6}.manageRecordAccessTable_accessDeniedContainer__v1giJTBYqB{display:flex;flex-direction:row;align-items:center}[dir] .manageRecordAccessTable_accessDeniedContainer__v1giJTBYqB{background-color:#feeae6;border-radius:.4rem;padding:.4rem .8rem;margin-top:2.4rem}.manageRecordAccessTable_accessDeniedContainer__v1giJTBYqB .manageRecordAccessTable_iconClass__tBkHQ4Kj4S{color:#c42106}.manageRecordAccessTable_accessDeniedContainer__v1giJTBYqB .manageRecordAccessTable_textClass__8Th6YqC54i{color:#444f5c}[dir=ltr] .manageRecordAccessTable_accessDeniedContainer__v1giJTBYqB .manageRecordAccessTable_textClass__8Th6YqC54i{margin-left:.8rem}[dir=rtl] .manageRecordAccessTable_accessDeniedContainer__v1giJTBYqB .manageRecordAccessTable_textClass__8Th6YqC54i{margin-right:.8rem}", ""]);

// Exports
exports.locals = {
	"manageRecordHeading": "manageRecordAccessTable_manageRecordHeading__rm63Aipdmh",
	"container": "manageRecordAccessTable_container__4iDPPvzLsE",
	"accessDenied": "manageRecordAccessTable_accessDenied__7YwjRPm2Dh",
	"accessDeniedContainer": "manageRecordAccessTable_accessDeniedContainer__v1giJTBYqB",
	"iconClass": "manageRecordAccessTable_iconClass__tBkHQ4Kj4S",
	"textClass": "manageRecordAccessTable_textClass__8Th6YqC54i"
};