import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Components
import InputTable from '@tekion/tekion-components/organisms/inputTable/containers/withRowActions/Table';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent/PropertyControlledComponent';
import Button from '@tekion/tekion-components/atoms/Button';
import { tget } from '@tekion/tekion-base/utils/general';
// Helpers

import { getActionsForRow } from './helpers/listInputTable.general';

// Constants
import { FORM_MODES } from '../../../../../../../../constants/general.constants';
import ACTION_TYPES from '../../constants/approvalSettingFieldForm.actionTypes';
import { ROW_ACTION_PROPS, ROW_OPERATION, TABLE_ACTION_TYPES } from './constants/listInputTable.constants';
import { COLUMNS } from './helpers/listInputTable.columns';

// Styles
import styles from '../../approvalSettingFieldForm.module.scss';

const ListInputTable = (props) => {
  const { data, formMode, onAction } = props;

  const getActions = useCallback(
    (row) => {
      const isDeletable = tget(row, 'rowData.isEditable', false);
      const showDelete = formMode === FORM_MODES.CREATE || isDeletable;
      return getActionsForRow(showDelete);
    },
    [formMode],
  );

  const handleAction = useCallback(
    (action) => {
      const actionType = _get(action, 'type', EMPTY_STRING);

      if (actionType === TABLE_ACTION_TYPES.ON_CHANGE) {
        onAction({
          type: ACTION_TYPES.OPTION_TABLE_ON_CHANGE,
          payload: _get(action, 'payload', EMPTY_OBJECT),
        });
      }
      if (actionType === TABLE_ACTION_TYPES.TABLE_ACTION_CLICK) {
        const tableActionType = _get(action, 'payload.actionType', EMPTY_STRING);
        const row = _get(action, 'payload.nestingPath.[0]', EMPTY_STRING);
        if (tableActionType === TABLE_ACTION_TYPES.REMOVE_ROW) {
          onAction({
            type: ACTION_TYPES.OPTION_TABLE_REMOVE_ROW,
            payload: { row },
          });
        }
      }
    },
    [onAction],
  );

  const handleAddRow = useCallback(() => {
    onAction({
      type: ACTION_TYPES.OPTION_TABLE_ADD_ROW,
      payload: { operation: ROW_OPERATION.ADD },
    });
  }, [onAction]);

  const isAddRowEnabled = formMode === FORM_MODES.EDIT;

  return (
    <div>
      <InputTable
        columns={COLUMNS}
        value={data}
        className={styles.inputTable}
        rowActionProps={ROW_ACTION_PROPS}
        additional={{ formMode }}
        onClick={handleAction}
        getActionsForRow={getActions}
        onAction={handleAction}
      />
      <PropertyControlledComponent controllerProperty={isAddRowEnabled}>
        <Button onClick={handleAddRow} view={Button.VIEW.TERTIARY} className={styles.addRowInputTableButton}>
          {__('Add Option')}
        </Button>
      </PropertyControlledComponent>
    </div>
  );
};

ListInputTable.propTypes = {
  formMode: PropTypes.string,
  data: PropTypes.array,

  onAction: PropTypes.func.isRequired,
};

ListInputTable.defaultProps = {
  formMode: FORM_MODES.CREATE,
  data: EMPTY_ARRAY,
};

export default React.memo(ListInputTable);
