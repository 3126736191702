import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _valuesIn from 'lodash/valuesIn';

import Page from '@tekion/tekion-components/molecules/pageComponent';
import Loader from '@tekion/tekion-components/molecules/loader';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Icon from '@tekion/tekion-components/atoms/FontIcon';
import withDateAsFilter from '@tekion/tekion-components/organisms/filterSection/containers/withDateAsFilter';
import DateTimeRangeWithoutPreset from '@tekion/tekion-components/organisms/filterSection/molecules/DateTimeRange/DateTimeRange';
import Label from '@tekion/tekion-components/atoms/Label/Label';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select/Select';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { EMPTY_OBJECT, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import WidgetContainer from '../../../../organisms/reporting/molecules/widgetContainer/WidgetContainer';
import GridLayout from '../../../../organisms/reporting/molecules/gridLayout';
import WithSize from '../../../../connectors/withSize';
import { getDefaultTimeRange } from './helpers/dashboard.helpers';
import { DASHBOARDS_SELECT_FIELD, DASHBOARD_FILTERS } from './constants/dashboard.general.constants';
import ACTION_TYPES from './constants/dashboard.actionTypes';
import ACTION_HANDLERS from './helpers/dashboard.actionHandlers';

import styles from './dashboard.module.scss';

const DateTimeFilter = withDateAsFilter(DateTimeRangeWithoutPreset);

const Dashboard = (props) => {
  const {
    loading,
    match,
    contentHeight,
    contentWidth,
    layoutsByName,
    history,
    hasBack,
    timeRange,
    widgetItems,
    dashboardList,
    selectedDashboard,
    onAction,
  } = props;

  const dashboardName = _get(match, 'params.dashboardName');

  const handleDateTimeFilterChange = useCallback(
    (value) => {
      onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id: DASHBOARD_FILTERS.TIME_RANGE, value } });
    },
    [onAction],
  );

  const renderWidget = useCallback(
    (widget) => (
      <div key={widget?.widgetName}>
        <WidgetContainer
          key={widget?.widgetName}
          history={history}
          dashboardName={dashboardName}
          widgetLayout={_get(layoutsByName, _get(widget, 'widgetName'))}
          timeRange={timeRange}
          triggerRangeChange={handleDateTimeFilterChange}
          {...widget}
        />
      </div>
    ),
    [history, dashboardName, layoutsByName, timeRange, handleDateTimeFilterChange],
  );

  const handleLayoutUpdate = useCallback(
    (value) => {
      onAction({
        type: ACTION_TYPES.UPDATE_GRID_LAYOUT,
        payload: {
          value,
        },
      });
    },
    [onAction],
  );

  const handleDashboardChange = useCallback(
    (value) => {
      onAction({
        type: ACTION_TYPES.CHANGE_DASHBOARD,
        payload: {
          value,
        },
      });
    },
    [onAction],
  );

  const handleGoBack = useCallback(() => {
    onAction({
      type: ACTION_TYPES.GO_BACK,
    });
  }, [onAction]);

  const rangeValues = useMemo(() => _valuesIn(timeRange), [timeRange]);
  const layouts = useMemo(() => _valuesIn(layoutsByName), [layoutsByName]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.INIT,
    });
  }, [dashboardName, onAction]);

  return (
    <PropertyControlledComponent controllerProperty={!loading} fallback={<Loader className="full-width full-height align-center" />}>
      <Page className={styles.dashboardContainer}>
        <Page.Header hasBack={hasBack} className={styles.dashboardHeaderContainer} contentClassName={styles.content}>
          <div onClick={handleGoBack} className={styles.label}>
            {__('Reports')}
          </div>
          <Icon className={styles.icon}>icon-right-arrow-thick</Icon>
          <Select
            id={DASHBOARDS_SELECT_FIELD}
            required={false}
            onChange={handleDashboardChange}
            className={styles.dashboardSelect}
            value={selectedDashboard}
            options={dashboardList}
          />
        </Page.Header>
        <Page.Body className={`full-width full-height ${styles.body}`} style={{ height: contentHeight }}>
          <div className={styles.customFilterContainer}>
            <Label className="m-t-4">{__('Time Trigger')}</Label>
            <DateTimeFilter
              id={DASHBOARD_FILTERS.TIME_RANGE}
              showTime
              required={false}
              onChange={handleDateTimeFilterChange}
              values={rangeValues}
              className={styles.dateFilter}
            />
          </div>
          <GridLayout width={contentWidth * 1.16} layouts={layouts} onLayoutChange={handleLayoutUpdate}>
            {_map(widgetItems, renderWidget)}
          </GridLayout>
        </Page.Body>
      </Page>
    </PropertyControlledComponent>
  );
};

Dashboard.propTypes = {
  loading: PropTypes.bool,
  hasBack: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  contentWidth: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  layoutsByName: PropTypes.object,
  timeRange: PropTypes.object,
  match: PropTypes.object.isRequired,
  dashboardList: PropTypes.array,
  selectedDashboard: PropTypes.array,
  widgetItems: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  loading: true,
  hasBack: false,
  layoutsByName: EMPTY_OBJECT,
  timeRange: getDefaultTimeRange(),
  dashboardList: EMPTY_ARRAY,
  selectedDashboard: EMPTY_ARRAY,
  widgetItems: EMPTY_ARRAY,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS), WithSize({ hasPageFooter: 0, hasPageHeader: 1 }))(Dashboard);
