import React, { useCallback, useEffect, useMemo } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import _map from 'lodash/map';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import Heading from '@tekion/tekion-components/atoms/Heading';
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent/SaveComponent';
import Spinner, { SIZES } from '@tekion/tekion-components/molecules/SpinnerComponent';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

import withSize from '../../../../../../connectors/withSize';
import NavigationItem from '../../../../../../molecules/NavigationFlow/components/navigationItem';

// Constants
import { FORM_MODES } from '../../../../../../constants/general.constants';
import ACTION_TYPES from './constants/reporteeForm.actionTypes';
import {
  INITIAL_STATES_REPORTEE_FORM_PROPS,
  REPORTEE_FORM_CONTEXT_ID,
  ROLES_FORM_NAVIGATION_DEFAULT_DATA,
} from './constants/reporteeForm.general.constants';

// Helpers
import ACTION_HANDLERS from './helpers/reporteeForm.actionHandlers';
import RoleForm from '../../molecules/roleForm/RoleForm';

import styles from './reporteeForm.module.scss';

const ReporteeForm = ({ isDataLoading, isSaveLoading, contentHeight, match, history, errors, roleData, roleOptions, onAction }) => {
  const roleId = _get(match, 'params.roleId', '');
  const formMode = _isEmpty(roleId) ? FORM_MODES.CREATE : FORM_MODES.EDIT;

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INITIALIZE_REPORTEE_FORM_VALUES });
  }, [onAction]);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_REDIRECTION });
  }, [onAction]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(REPORTEE_FORM_CONTEXT_ID);
  }, []);

  const headerNavigationData = useMemo(
    () => [
      ...ROLES_FORM_NAVIGATION_DEFAULT_DATA,
      { label: formMode === FORM_MODES.CREATE ? __('Add Roles') : __('Edit Roles'), key: 'addEditReportee' },
    ],
    [formMode],
  );

  const handleNavigationItemClick = useCallback(
    (goTo) => {
      if (!_isEmpty(goTo)) {
        history.push(goTo);
      }
    },
    [history],
  );

  const headerNavigation = useMemo(
    () =>
      _map(headerNavigationData, (data, index) => (
        <NavigationItem
          key={_get(data, 'key', EMPTY_STRING)}
          itemNumber={index}
          totalItems={_size(headerNavigationData)}
          data={data}
          onNavigationItemClick={handleNavigationItemClick}
        />
      )),
    [headerNavigationData, handleNavigationItemClick],
  );

  return (
    <Page className="full-height full-width">
      <Page.Header>
        <Heading className={styles.headerNavigationContainer} size={2}>
          {headerNavigation}
        </Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight }}>
        <PropertyControlledComponent controllerProperty={isDataLoading}>
          <div className={styles.spinnerWrap}>
            <Spinner size={SIZES.MEDIUM} />
          </div>
        </PropertyControlledComponent>

        <Heading size={2} className={styles.formHeading}>
          {__('Role Details')}
        </Heading>

        <PropertyControlledComponent controllerProperty={!isDataLoading}>
          <RoleForm contextId={REPORTEE_FORM_CONTEXT_ID} onAction={onAction} roleData={roleData} roleOptions={roleOptions} errors={errors} />
        </PropertyControlledComponent>
      </Page.Body>

      <Page.Footer>
        <SaveComponent
          primaryButtonLabel={formMode === FORM_MODES.CREATE ? __('Create') : __('Update')}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancel}
          primaryActionLoading={isSaveLoading}
        />
      </Page.Footer>
    </Page>
  );
};

ReporteeForm.propTypes = {
  isDataLoading: PropTypes.bool,
  isSaveLoading: PropTypes.bool,
  contentHeight: PropTypes.number,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errors: PropTypes.object,
  roleData: PropTypes.object,
  roleOptions: PropTypes.array,
  onAction: PropTypes.func,
};

ReporteeForm.defaultProps = {
  isDataLoading: false,
  isSaveLoading: false,
  contentHeight: 0,
  errors: EMPTY_OBJECT,
  roleData: EMPTY_OBJECT,
  roleOptions: EMPTY_ARRAY,
  onAction: _noop,
};

export default compose(
  withSize({ hasPageFooter: 1, hasPageHeader: 1 }),
  withActions(INITIAL_STATES_REPORTEE_FORM_PROPS, ACTION_HANDLERS),
)(ReporteeForm);
