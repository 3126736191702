import _pick from 'lodash/pick';
import _values from 'lodash/values';
import _reduce from 'lodash/reduce';
import { defaultMemoize } from 'reselect';

const getShortcutsInfo = defaultMemoize((shortcutsInfos, toolbarGroups) =>
  _reduce(_values(_pick(shortcutsInfos, toolbarGroups)), (acc, shortcutsInfo) => [...acc, ...shortcutsInfo], []),
);

export { getShortcutsInfo };
