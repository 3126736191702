import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _head from 'lodash/head';
import _split from 'lodash/split';
import _upperFirst from 'lodash/upperFirst';
import _join from 'lodash/join';
import _toLower from 'lodash/toLower';
import _castArray from 'lodash/castArray';
import _find from 'lodash/find';
import _camelCase from 'lodash/camelCase';
import _startCase from 'lodash/startCase';
import _capitalize from 'lodash/capitalize';
import _slice from 'lodash/slice';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { ADD_WIDGET_FORM_FIELDS, COLUMN_IDS, METRIC_TYPES } from '../constants/addWidget.general.constant';
import { WIDGET_TYPES } from '../../../../../../../organisms/reporting/molecules/widgetContainer/constants/widgetContainer.general.constants';

const createPayloadForTableWidget = ({ formValues, widgetType, widgetName, possibleYAxis }) => {
  let dimensions = _get(formValues, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS, EMPTY_ARRAY);
  let measurements = _get(formValues, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS, EMPTY_ARRAY);
  dimensions = _map(dimensions, (item) => ({
    ..._pick(item, [COLUMN_IDS.COLUMN_DISPLAY_NAME, COLUMN_IDS.SELECT_FIELD, COLUMN_IDS.FILTERABLE]),
  }));

  measurements = _map(measurements, (item) => ({
    ..._find(possibleYAxis, (option) => {
      const selectedMeasurement = _head(_get(item, COLUMN_IDS.SELECT_FIELD));
      return selectedMeasurement === `${_get(option, 'function')}.${_get(option, 'fieldName')}`;
    }),
    ..._pick(item, [COLUMN_IDS.COLUMN_DISPLAY_NAME]),
  }));

  const payload = {
    [ADD_WIDGET_FORM_FIELDS.WIDGET_NAME]: _get(formValues, ADD_WIDGET_FORM_FIELDS.WIDGET_NAME),
    [ADD_WIDGET_FORM_FIELDS.DISPLAY_NAME]: _get(formValues, ADD_WIDGET_FORM_FIELDS.DISPLAY_NAME),
    [ADD_WIDGET_FORM_FIELDS.ENTITY_NAME]: _get(formValues, `${ADD_WIDGET_FORM_FIELDS.ENTITY_NAME}.0`),
    widgetType,
    dimensions: _map(dimensions, (item) => ({
      name: getArraySafeValue(_get(item, COLUMN_IDS.SELECT_FIELD)),
      displayName: _get(item, COLUMN_IDS.COLUMN_DISPLAY_NAME),
      filter: _get(item, 'filter', false),
      field: getArraySafeValue(_get(item, COLUMN_IDS.SELECT_FIELD)),
    })),

    measurements: _map(measurements, (item) => ({
      name: item.name,
      displayName: item.displayName,
      function: item.function,
      field: item.fieldName,
      additional: item.additional,
    })),
  };

  if (!_isEmpty(widgetName)) {
    payload.name = widgetName;
  }
  return payload;
};

const createPayloadForChartWidget = ({ formValues, widgetName, possibleXAxis, possibleYAxis }) => {
  const xAxis = getArraySafeValue(_get(formValues, ADD_WIDGET_FORM_FIELDS.X_AXIS, ['']));
  const yAxis = _get(formValues, ADD_WIDGET_FORM_FIELDS.Y_AXIS, '');
  const dimensions = _castArray(_find(possibleXAxis, (item) => item.name === xAxis));
  const measurements = _map(yAxis, (item) => ({
    ..._find(possibleYAxis, (option) => item.includes(_get(option, 'function')) && item.includes(_get(option, 'name'))),
  }));

  const payload = {
    widgetType: _get(formValues, `${ADD_WIDGET_FORM_FIELDS.WIDGET_TYPE}.0`, ''),
    [ADD_WIDGET_FORM_FIELDS.WIDGET_NAME]: _get(formValues, ADD_WIDGET_FORM_FIELDS.WIDGET_NAME),
    [ADD_WIDGET_FORM_FIELDS.DISPLAY_NAME]: _get(formValues, ADD_WIDGET_FORM_FIELDS.DISPLAY_NAME),
    [ADD_WIDGET_FORM_FIELDS.ENTITY_NAME]: _get(formValues, `${ADD_WIDGET_FORM_FIELDS.ENTITY_NAME}.0`),
    dimensions: _map(dimensions, (item) => ({ name: item.displayName, displayName: item.displayName, field: item.name })),
    measurements: _map(measurements, (item) => ({
      name: item.name,
      displayName: item.displayName,
      function: item.function,
      field: item.field,
      additional: item.additional,
    })),
  };

  if (!_isEmpty(widgetName)) {
    payload.name = widgetName;
  }
  return payload;
};

const getValuesFromWidget = (widget = EMPTY_OBJECT, resource = EMPTY_OBJECT) => {
  const { dimensions, measurements, name = '', widgetType = '', displayName, entityName } = widget;
  const possibleXAxis = _map(_get(resource, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS, EMPTY_ARRAY), (obj) => ({
    ...obj,
    metricType: METRIC_TYPES.DIMENSION,
    id: _get(obj, 'name'),
    label: __(_get(obj, 'displayName')),
    value: _get(obj, 'name'),
  }));
  const possibleYAxis = _map(_get(resource, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS, EMPTY_ARRAY), (obj) => ({
    ...obj,
    metricType: METRIC_TYPES.MEASUREMENTS,
    id: _get(obj, 'name'),
    label: __(_get(obj, 'displayName')),
    value: _get(obj, 'name'),
  }));

  const values = {
    [ADD_WIDGET_FORM_FIELDS.WIDGET_NAME]: name,
    [ADD_WIDGET_FORM_FIELDS.ENTITY_NAME]: _castArray(entityName),
    [ADD_WIDGET_FORM_FIELDS.DISPLAY_NAME]: displayName,
    [ADD_WIDGET_FORM_FIELDS.WIDGET_TYPE]: _castArray(widgetType),
  };

  let selectedDimensions = EMPTY_ARRAY;
  let selectedMeasurements = EMPTY_ARRAY;

  if (widgetType === WIDGET_TYPES.DATA_TABLE) {
    const tableDimensions = _map(dimensions, (item) => ({ displayName: item.displayName, field: _castArray(item.field), filter: item.filter }));
    const tableMeasurements = _map(measurements, (item) => ({ displayName: item.displayName, field: _castArray(item.name) }));
    values[ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_DIMENSIONS] = tableDimensions;
    values[ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS] = tableMeasurements;
    selectedDimensions = _map(dimensions, (item) => item.field);
    selectedMeasurements = _map(measurements, (item) => item.name);
  } else {
    let xAxis = _head(dimensions);
    xAxis = _castArray(_get(xAxis, 'field'));
    const yAxis = _map(measurements, (item) => item.name);
    values[ADD_WIDGET_FORM_FIELDS.X_AXIS] = xAxis;
    values[ADD_WIDGET_FORM_FIELDS.Y_AXIS] = yAxis;
  }

  return { values, possibleXAxis, possibleYAxis, selectedDimensions, selectedMeasurements };
};

const convertEnumValue = (option) => {
  const displayValue = _split(option, '_');
  const newdisplayValue = _map(displayValue, (item) => _upperFirst(_toLower(item)));
  return _join(newdisplayValue, ' ');
};
const pascalCase = (str) => _startCase(_camelCase(str)).replace(/ /g, '');

const getOptionFieldName = (fieldName) => {
  let fieldArray = _split(fieldName, '.');
  fieldArray = _map(fieldArray, (field) => pascalCase(field));
  fieldArray = _map(fieldArray, (item) => {
    if (getArraySafeValue(_slice(item, -1)) === 'U') {
      const newItem = item.slice(0, -1);
      return newItem;
    }
    return item;
  });
  return _join(fieldArray, ' - ');
};

const getMeasurements = (measurements) =>
  _map(measurements, (item) => ({
    ...item,
    name: `${_get(item, 'function')}.${_get(item, 'name')}`,
    displayName: `${_get(item, 'function')} of ${_get(item, 'displayName')}`,
  }));

const getMeasurementsTable = (measurements) =>
  _map(measurements, (item) => {
    const fieldName = _isEmpty(_get(item, 'relatedFieldName')) ? _get(item, 'field') : `${_get(item, 'relatedFieldName')}.${_get(item, 'field')}`;
    return {
      ...item,
      name: `${_get(item, 'function')}.${fieldName}`,
      displayName: `${_capitalize(_get(item, 'function'))} of ${getOptionFieldName(fieldName)}`,
      fieldName,
    };
  });

const getMetrics = (data, fieldName) => {
  let measurements = [];
  _forEach(data, (item) => {
    if (_get(item, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS)) {
      let metrics = _get(item, ADD_WIDGET_FORM_FIELDS.COLUMN_CONFIG_MEASUREMENTS);
      metrics = _map(metrics, (metric) => ({ ...metric, entity: _get(item, 'entityName'), relatedFieldName: fieldName }));
      measurements.push(...metrics);
    }
  });

  measurements = getMeasurementsTable(measurements);
  return measurements;
};

const getGenericOptions = (options, selectedFieldValues = EMPTY_ARRAY) =>
  _map(options, (item) => ({ ...item, isDisabled: _includes(selectedFieldValues, item.name) }));

export {
  getGenericOptions,
  getMetrics,
  getMeasurements,
  convertEnumValue,
  getValuesFromWidget,
  createPayloadForChartWidget,
  createPayloadForTableWidget,
};
