import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import NullComponent from '@tekion/tekion-components/atoms/nullComponent/NullComponent';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import { COMPONENT_CONFIG_KEYS } from '../../constants/visualBuilder.general.constants';
import { WIDGET_DISPLAY_NAMES } from '../../constants/visualBuilder.widgetMapper';

import styles from './blankWidgetContainer.module.scss';

const BlankWidgetContainer = ({ isPreviewMode, componentConfig }) => {
  const widgetDisplayName = useMemo(
    () => WIDGET_DISPLAY_NAMES[tget(componentConfig, COMPONENT_CONFIG_KEYS.WIDGET_TYPE)] || NO_DATA,
    [componentConfig],
  );

  if (!isPreviewMode) {
    return <div className={styles.blankWidget}>{__(` Configure ${widgetDisplayName}`)}</div>;
  }
  return <NullComponent />;
};

BlankWidgetContainer.propTypes = {
  isPreviewMode: PropTypes.bool.isRequired,
  componentConfig: PropTypes.object,
};

BlankWidgetContainer.defaultProps = {
  componentConfig: EMPTY_OBJECT,
};

export default BlankWidgetContainer;
