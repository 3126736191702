import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _valuesIn from 'lodash/valuesIn';
import { defaultMemoize } from 'reselect';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import { tget } from '@tekion/tekion-base/utils/general';
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox/Checkbox';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import { EMPTY_ARRAY, NO_DATA } from '@tekion/tekion-base/app.constants';

import { mergeRenderOptions } from '../../../helpers/fieldsForm.general.helpers';

import { SORT_OPTIONS } from '../constants/propertiesForm.general.constants';
import PROPERTIES_FORM_FIELD_IDS from '../constants/propertiesForm.fieldIds';
import PROPERTIES_FORM_LABELS from '../constants/propertiesForm.labels';

const CREATABLE_FIELD = {
  id: PROPERTIES_FORM_FIELD_IDS.CREATABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: tget(PROPERTIES_FORM_LABELS, PROPERTIES_FORM_FIELD_IDS.CREATABLE, NO_DATA),
    defaultValue: false,
  },
};

const EDITABLE_FIELD = {
  id: PROPERTIES_FORM_FIELD_IDS.EDITABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: tget(PROPERTIES_FORM_LABELS, PROPERTIES_FORM_FIELD_IDS.EDITABLE, NO_DATA),
    defaultValue: false,
  },
};

const SEARCHABLE_FIELD = {
  id: PROPERTIES_FORM_FIELD_IDS.SEARCHABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: tget(PROPERTIES_FORM_LABELS, PROPERTIES_FORM_FIELD_IDS.SEARCHABLE, NO_DATA),
    defaultValue: false,
  },
};

const FILTERABLE_FIELD = {
  id: PROPERTIES_FORM_FIELD_IDS.FILTERABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: tget(PROPERTIES_FORM_LABELS, PROPERTIES_FORM_FIELD_IDS.FILTERABLE, NO_DATA),
    defaultValue: false,
  },
};

const SORTABLE_FIELD = {
  id: PROPERTIES_FORM_FIELD_IDS.SORTABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: tget(PROPERTIES_FORM_LABELS, PROPERTIES_FORM_FIELD_IDS.SORTABLE, NO_DATA),
    defaultValue: false,
  },
};

const SORT_DIRECTION_FIELD = {
  id: PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Sort Direction'),
    checkboxLabel: tget(PROPERTIES_FORM_LABELS, PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION, NO_DATA),
    size: 6,
    options: SORT_OPTIONS,
    validators: [isRequiredRule],
    required: true,
  },
};

const IMPORTABLE_FIELD = {
  id: PROPERTIES_FORM_FIELD_IDS.IMPORTABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: tget(PROPERTIES_FORM_LABELS, PROPERTIES_FORM_FIELD_IDS.IMPORTABLE, NO_DATA),
    defaultValue: false,
  },
};

const EXPORTABLE_FIELD = {
  id: PROPERTIES_FORM_FIELD_IDS.EXPORTABLE,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: tget(PROPERTIES_FORM_LABELS, PROPERTIES_FORM_FIELD_IDS.EXPORTABLE, NO_DATA),
    defaultValue: false,
  },
};

const MANDATORY_FIELD = {
  id: PROPERTIES_FORM_FIELD_IDS.MANDATORY,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: tget(PROPERTIES_FORM_LABELS, PROPERTIES_FORM_FIELD_IDS.MANDATORY, NO_DATA),
    defaultValue: false,
  },
};

const DISABLED_FIELD = {
  id: PROPERTIES_FORM_FIELD_IDS.DISABLED,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: tget(PROPERTIES_FORM_LABELS, PROPERTIES_FORM_FIELD_IDS.DISABLED, NO_DATA),
    defaultValue: false,
  },
};

const UNIQUE_CONSTRAINT_FIELD = {
  id: PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: tget(PROPERTIES_FORM_LABELS, PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT, NO_DATA),
    defaultValue: false,
  },
};

const AUDIT_ENABLED_FIELD = {
  id: PROPERTIES_FORM_FIELD_IDS.AUDIT_ENABLED,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: tget(PROPERTIES_FORM_LABELS, PROPERTIES_FORM_FIELD_IDS.AUDIT_ENABLED, NO_DATA),
    defaultValue: false,
  },
};

const PROPERTIES_FORM_FIELDS = {
  [PROPERTIES_FORM_FIELD_IDS.CREATABLE]: CREATABLE_FIELD,
  [PROPERTIES_FORM_FIELD_IDS.EDITABLE]: EDITABLE_FIELD,
  [PROPERTIES_FORM_FIELD_IDS.SEARCHABLE]: SEARCHABLE_FIELD,
  [PROPERTIES_FORM_FIELD_IDS.FILTERABLE]: FILTERABLE_FIELD,
  [PROPERTIES_FORM_FIELD_IDS.SORTABLE]: SORTABLE_FIELD,
  [PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION]: SORT_DIRECTION_FIELD,
  [PROPERTIES_FORM_FIELD_IDS.IMPORTABLE]: IMPORTABLE_FIELD,
  [PROPERTIES_FORM_FIELD_IDS.EXPORTABLE]: EXPORTABLE_FIELD,
  [PROPERTIES_FORM_FIELD_IDS.MANDATORY]: MANDATORY_FIELD,
  [PROPERTIES_FORM_FIELD_IDS.DISABLED]: DISABLED_FIELD,
  [PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT]: UNIQUE_CONSTRAINT_FIELD,
  [PROPERTIES_FORM_FIELD_IDS.AUDIT_ENABLED]: AUDIT_ENABLED_FIELD,
};

const getFieldLabel = defaultMemoize((fieldId) => tget(PROPERTIES_FORM_LABELS, fieldId, NO_DATA));

const getFields = (renderOptions) => {
  const mergedRenderOptions = mergeRenderOptions({}, _valuesIn(PROPERTIES_FORM_FIELD_IDS), renderOptions, getFieldLabel);

  return _reduce(
    _valuesIn(PROPERTIES_FORM_FIELD_IDS),
    (fields, fieldId) =>
      _set(fields, fieldId, addToRenderOptions(_get(PROPERTIES_FORM_FIELDS, fieldId), _get(mergedRenderOptions, fieldId, EMPTY_ARRAY))),
    {},
  );
};

export default getFields;
