import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _constant from 'lodash/constant';
import _includes from 'lodash/includes';
import _map from 'lodash/map';
// Components
import InputTable from '@tekion/tekion-components/organisms/inputTable/containers/withRowActions/Table';
import Button from '@tekion/tekion-components/atoms/Button';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Heading from '@tekion/tekion-components/atoms/Heading';

// Constants
import { tget } from '@tekion/tekion-base/utils/general';
import AddUserModal from '../addUserModal/AddUserModal';
import { ROW_ACTION_PROPS, TABLE_ACTION_TYPES, REMOVE_ACTION, COLUMN_IDS } from './constants/userListTable.general';
import { NAME_COLUMN_CONFIG, EMAIL_COLUMN_CONFIG, ROLE_COLUMN_CONFIG } from './helper/userListTable.columns';
import { TENANT_UNIVERSE_PAGE_ID } from '../../../../../../../constants/routes';
import { ACTION_TYPES } from '../../constants/createUserGroup.constants';

// Styles
import styles from '../../createUserGroup.module.scss';

const getActions = _constant([REMOVE_ACTION]);

const UserListTable = (props) => {
  const { onAction, data, modalData, isModalVisible, history } = props;
  const [users, setUsersState] = useState([]);

  const isTenantUniverse = _includes(_get(history, 'location.pathname', ''), TENANT_UNIVERSE_PAGE_ID);
  let newData = data;

  const columnConfig = useMemo(() => {
    const columns = [NAME_COLUMN_CONFIG, EMAIL_COLUMN_CONFIG];
    if (!isTenantUniverse) columns.push(ROLE_COLUMN_CONFIG);
    return columns;
  }, [isTenantUniverse]);

  if (!isTenantUniverse) {
    newData = _map(data, (item) => ({
      ...item,
      ..._get(item, 'entity', EMPTY_OBJECT),
      name: `${_get(item, 'entity.firstname', EMPTY_STRING)} ${_get(item, 'entity.lastname', EMPTY_STRING)}`,
    }));
  } else {
    newData = _map(data, (item) => ({ ...item, name: `${_get(item, 'firstName')} ${_get(item, 'lastName')}` }));
  }

  const handleClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.MODAL_CLICK,
    });
  }, [onAction]);

  const handleAction = useCallback(
    (action) => {
      const tableActionType = _get(action, 'payload.actionType', EMPTY_STRING);
      const row = _get(action, 'payload.nestingPath.[0]', EMPTY_STRING);
      const value = _get(action, `payload.rowData.${COLUMN_IDS.NAME}`, '');
      if (tableActionType === TABLE_ACTION_TYPES.REMOVE_ROW) {
        onAction({
          type: ACTION_TYPES.TABLE_REMOVE_ROW,
          payload: { row, value },
        });
      }
    },
    [onAction],
  );

  const handleClose = useCallback(() => {
    onAction({
      type: ACTION_TYPES.MODAL_CLOSE,
    });
  }, [onAction]);

  const handleChange = useCallback((selectedUsers) => {
    setUsersState(selectedUsers);
  }, []);

  const handleUserDelete = useCallback((updatedUsers) => {
    setUsersState(updatedUsers);
  }, []);

  const handleModalSubmit = useCallback(() => {
    onAction({
      type: ACTION_TYPES.MODAL_SAVE,
      payload: { users },
    });
  }, [onAction, users]);

  useEffect(() => {
    const newUsers = tget(modalData, 'selectedUsers', []);
    setUsersState(newUsers);
  }, [modalData]);

  return (
    <div>
      <PropertyControlledComponent controllerProperty={isModalVisible}>
        <AddUserModal
          visible={isModalVisible}
          users={users}
          handleChange={handleChange}
          handleClose={handleClose}
          handleUserDelete={handleUserDelete}
          handleModalSubmit={handleModalSubmit}
        />
      </PropertyControlledComponent>
      <Heading size={2} className={styles.addUserHeader}>
        {__('Added user')}
        <Button onClick={handleClick} view={Button.VIEW.PRIMARY}>
          {__('Add User')}
        </Button>
      </Heading>
      <InputTable columns={columnConfig} value={newData} rowActionProps={ROW_ACTION_PROPS} getActionsForRow={getActions} onAction={handleAction} />
    </div>
  );
};

UserListTable.propTypes = {
  isModalVisible: PropTypes.bool,
  data: PropTypes.array,
  modalData: PropTypes.object,
  history: PropTypes.object,
  onAction: PropTypes.func,
};

UserListTable.defaultProps = {
  isModalVisible: false,
  data: EMPTY_ARRAY,
  modalData: EMPTY_OBJECT,
  history: EMPTY_OBJECT,
  onAction: PropTypes.func,
};

export default React.memo(UserListTable);
