import React from 'react';

import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import Modal from '@tekion/tekion-components/molecules/Modal';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import AsyncTag from '../asyncTag/AsyncTag';
import UserAsyncSelect from '../../../../../molecules/userAsyncSelect/UserAsyncSelect';

import styles from './addUserModal.module.scss';

const AddUserModal = (props) => {
  const { visible, users, handleChange, handleUserDelete, handleModalSubmit, handleClose } = props;

  return (
    <Modal title={__('Add User')} visible={visible} onCancel={handleClose} width={Modal.SIZES.MD} destroyOnClose onSubmit={handleModalSubmit}>
      <div className={styles.userSelect}>
        <UserAsyncSelect isMulti placeholder={__('Select Users')} key="select-bar" value={users} onChange={handleChange} />
      </div>
      <AsyncTag value={users} onChange={handleUserDelete} />
    </Modal>
  );
};

AddUserModal.propTypes = {
  visible: PropTypes.bool,
  users: PropTypes.array,
  handleChange: PropTypes.func,
  handleUserDelete: PropTypes.func,
  handleModalSubmit: PropTypes.func,
  handleClose: PropTypes.func,
};

AddUserModal.defaultProps = {
  visible: false,
  users: EMPTY_ARRAY,
  handleChange: _noop,
  handleUserDelete: _noop,
  handleModalSubmit: _noop,
  handleClose: _noop,
};
export default React.memo(AddUserModal);
