import { defaultMemoize } from 'reselect';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import addOptionsToAdditional from '@tekion/tekion-base/utils/addOptionsToAdditional';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { TEXT_STARTS_WITH } from '../../../../../constants/filter.constants';
import { ASSET_TYPES } from '../../../../../constants/general.constants';
import { INCLUDED_BY_FILTER, COMPONENT_TYPE_FILTERS, DEFAULT_FILTERS, DEFAULT_FILTER_GROUP } from '../constants/metadataComponentList.filters';
import { INCLUDED_BY_FILTER_OPTIONS, COMPONENT_TYPE_FILTER_OPTIONS, BUNDLE_STATE } from '../constants/metadataComponentList.general.constants';
import { DELETE_ACTION } from '../constants/metadataComponentList.rowActions';
import { COLUMN_IDS } from '../constants/metadataComponentList.table.constants';

const getRowActions = (props) => {
  const includedBy = tget(props, 'includedBy', EMPTY_ARRAY);

  if (_size(includedBy) === 1) {
    const includedByValue = getArraySafeValue(includedBy);
    if (includedByValue === ASSET_TYPES.USER) {
      return [DELETE_ACTION];
    } else {
      return [];
    }
  } else {
    return [];
  }
};

const getTableProps = defaultMemoize((handleRowAction, currentPage, loading, rows, totalNumberOfEntries, state) => ({
  currentPage: currentPage + 1,
  loading,
  showPagination: true,
  pageSize: rows,
  showActions: state === BUNDLE_STATE.DRAFT,
  resultsPerPage: rows,
  totalNumberOfEntries,
  minRows: 0,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
  rows,
  onRowActionClick: handleRowAction,
  getRowActions,
}));

const getOptions = defaultMemoize((options) =>
  _map(options, (option) => ({
    value: option.value,
    label: option.label,
  })),
);

const getFilterProps = defaultMemoize((selectedFilters, selectedFilterGroup) => {
  const filterTypes = [
    addOptionsToAdditional(INCLUDED_BY_FILTER, getOptions(INCLUDED_BY_FILTER_OPTIONS)),
    addOptionsToAdditional(COMPONENT_TYPE_FILTERS, getOptions(COMPONENT_TYPE_FILTER_OPTIONS)),
  ];

  return {
    filterTypes,
    selectedFilters,
    appliedFilterGroup: selectedFilterGroup || DEFAULT_FILTER_GROUP,
    defaultFilterTypes: DEFAULT_FILTERS,
  };
});

const getModifiedFilters = defaultMemoize((selectedFilters) => {
  const modifiedFilters = _reduce(
    selectedFilters,
    (result, filter) => {
      if (!_isEmpty(_get(filter, 'values'))) {
        result.push({
          values: tget(filter, 'values'),
          field: tget(filter, 'type'),
          filterType: tget(filter, 'operator'),
        });
      }

      return result;
    },
    [],
  );

  return modifiedFilters;
});

const getPayload = defaultMemoize((pageSize, selectedFilters, nextPageToken, searchText) => {
  let payload = { rows: pageSize };
  let filters = EMPTY_ARRAY;

  if (!_isEmpty(selectedFilters)) {
    filters = [...getModifiedFilters(selectedFilters)];
  }

  if (!_isEmpty(searchText)) {
    filters = [
      {
        field: COLUMN_IDS.NAME,
        values: [searchText],
        filterType: TEXT_STARTS_WITH,
      },
      ...filters,
    ];
  }

  if (!_isEmpty(filters)) {
    payload = {
      filters,
      ...payload,
    };
  }

  if (!_isEmpty(nextPageToken)) {
    payload = {
      nextPageToken,
      ...payload,
    };
  }
  return payload;
});

const getComponentData = (componentResponse) => {
  const components = tget(componentResponse, 'hits', EMPTY_ARRAY);
  return components;
};

export { getTableProps, getComponentData, getPayload, getFilterProps };
