import _size from 'lodash/size';
import _map from 'lodash/map';

import FilterBuilder from '../../../../../builders/filterBuilder';
import SearchRequest from '../../../../../builders/searchRequest';

import EntityReader from '../../../../../readers/entity.reader';
import EntityRecordReader from '../../../../../readers/entityRecord.reader';
import WorkspaceUserReader from '../../../../../readers/workSpaceUser.reader';
import { ShareAndManageRecordUserDetailGetters } from '../shareAndManageRecordUserData';

const manageAccessDataPayloadCreation = (entityDef, entityRecord) => {
  const entityName = EntityReader.name(entityDef);
  const recordId = EntityRecordReader.id(entityRecord);

  return { entityName, recordId };
};

const userDataFromIdsPayloadCreation = (userIds) => {
  const FilterObject = new FilterBuilder();
  FilterObject.addIdFilter({ values: userIds });
  const filters = FilterObject.getFilterPayload();

  const searchRequest = new SearchRequest();
  searchRequest.setFilters(filters).setRows(_size(userIds));

  return searchRequest;
};

const entityAccessForUsersPayloadCreation = (entityDef, entityRecord) => {
  const entityName = EntityReader.name(entityDef);
  const recordTypeId = EntityRecordReader.recordTypeId(entityRecord);

  return { entityName, recordTypeId };
};

const getSharePayload = (entityName, recordId) => (userData) => {
  const userId = WorkspaceUserReader.id(userData);

  const expiryInLong = ShareAndManageRecordUserDetailGetters.expiryTime(userData);
  const recordAccessType = ShareAndManageRecordUserDetailGetters.recordAccessType(userData);

  return {
    entityName,
    recordId,
    userId,
    expiryInLong,
    recordAccessType,
  };
};

const shareRecordUsersPayloadCreation = (shareUsersData, entityDef, entityRecord) => {
  const entityName = EntityReader.name(entityDef);
  const recordId = EntityRecordReader.id(entityRecord);

  const payload = _map(shareUsersData, getSharePayload(entityName, recordId));

  return payload;
};

const wwdPayloadCreation = (entityDef) => {
  const entityName = EntityReader.name(entityDef);

  const FilterObject = new FilterBuilder();
  FilterObject.addEntityNameFilter({ values: [entityName] });

  const searchRequest = new SearchRequest();
  searchRequest.setFilters(FilterObject.getFilterPayload());

  return searchRequest;
};

const deleteSharedRecordPayloadCreation = (sharedRecordUserData, entityDef, entityRecord) => {
  const recordId = EntityRecordReader.id(entityRecord);
  const userId = ShareAndManageRecordUserDetailGetters.id(sharedRecordUserData);
  const entityName = EntityReader.name(entityDef);

  return { recordId, userId, entityName };
};

export {
  manageAccessDataPayloadCreation,
  userDataFromIdsPayloadCreation,
  entityAccessForUsersPayloadCreation,
  shareRecordUsersPayloadCreation,
  wwdPayloadCreation,
  deleteSharedRecordPayloadCreation,
};
