import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../../../constants/approvalCentre.constants';

const APPROVAL_SETTING_FIELD_IDS = {
  ID: APPROVAL_CENTRE_FIELD_IDS.ID,
  DISPLAY_NAME: APPROVAL_CENTRE_FIELD_IDS.DISPLAY_NAME,
  NAME: APPROVAL_CENTRE_FIELD_IDS.NAME,
  GROUP: APPROVAL_CENTRE_FIELD_IDS.GROUP,
  CATEGORY: APPROVAL_CENTRE_FIELD_IDS.CATEGORY,
  FIELDS: APPROVAL_CENTRE_FIELD_IDS.FIELDS,
};

export default APPROVAL_SETTING_FIELD_IDS;
