import produce from 'immer';
import { handleActions } from 'redux-actions';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { APPLICATION_VARIABLES_REDUCER_ACTION_TYPES } from '../constants/reducers.actionTypes';

const INITIAL_STATE = {};

const handleSetVariable = produce((state, { payload = EMPTY_OBJECT }) => {
  const { variableName, value } = payload;
  return {
    ...state,
    [variableName]: value,
  };
});

const handleClearVariables = () => EMPTY_OBJECT;

const applicationVariablesReducer = handleActions(
  {
    [APPLICATION_VARIABLES_REDUCER_ACTION_TYPES.SET_VARIABLE]: handleSetVariable,
    [APPLICATION_VARIABLES_REDUCER_ACTION_TYPES.CLEAR_VARIABLES]: handleClearVariables,
  },
  INITIAL_STATE,
);

export default applicationVariablesReducer;
