import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import Tooltip, { TOOLTIP_PLACEMENT } from '@tekion/tekion-components/atoms/tooltip';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';

import { hasUserAccessDenied } from '../../helpers/manageRecord.general';

import styles from './userNameWithWarningRenderer.module.scss';

const ACCESS_DENIED_WARNING_MESSAGE = __(
  // eslint-disable-next-line max-len
  'Access Denied: This user might not have access to this record. He/She will need to be given access to the entity to be able to access the shared record',
);

const overlayStyle = {
  minWidth: '50rem',
};

const UserNameWithWarningRenderer = ({ data, isSelectedAccessTypeFilterNotOfAccessDenied, original }) => {
  const isAccessDenied = useMemo(() => hasUserAccessDenied(original), [original]);

  return (
    <div className={styles.container}>
      <div>{data}</div>
      {isAccessDenied && isSelectedAccessTypeFilterNotOfAccessDenied && (
        <Tooltip className={styles.icon} overlayStyle={overlayStyle} title={ACCESS_DENIED_WARNING_MESSAGE} placement={TOOLTIP_PLACEMENT.BOTTOM_RIGHT}>
          <FontIcon>icon-alert-outline</FontIcon>
        </Tooltip>
      )}
    </div>
  );
};

UserNameWithWarningRenderer.propTypes = {
  isSelectedAccessTypeFilterNotOfAccessDenied: PropTypes.bool,
  data: PropTypes.string,
  original: PropTypes.object,
};

UserNameWithWarningRenderer.defaultProps = {
  isSelectedAccessTypeFilterNotOfAccessDenied: true,
  data: '',
  original: EMPTY_OBJECT,
};

export default makeCellRenderer(UserNameWithWarningRenderer);
