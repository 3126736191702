import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Button from '@tekion/tekion-components/atoms/Button';
import Module from '@tekion/tekion-components/molecules/leftPanelItem';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import { tget } from '@tekion/tekion-base/utils/general';

import getFields from '../helpers/searchOptionsConfigurator.fields';
import getSearchOptionsFormSection from '../helpers/searchOptionsConfigurator.sections';

import ACTION_TYPES from '../constants/searchOptionsConfigurator.actionTypes';
import { FIELD_IDS, SEARCH_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID } from '../constants/searchOptionsConfigurator.constants';

import styles from '../searchOptionsConfigurator.module.scss';

const SearchOptionsModal = ({
  selectedModuleIndex,
  relationshipField,
  allFormValues,
  errors,
  searchableFieldOptions,
  searchableFieldOptionsByRelationshipField,
  onAction,
}) => {
  const searchOptionFormValue = tget(allFormValues, [selectedModuleIndex], {});
  const searchFields = tget(searchOptionFormValue, [FIELD_IDS.SEARCH_METADATA], []);
  const relationshipFieldOptions = tget(searchableFieldOptionsByRelationshipField, [relationshipField], undefined);

  const handleTabChange = useCallback((moduleIndex) => {
    triggerSubmit(SEARCH_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID, { actionType: ACTION_TYPES.ON_CHANGE_MODULE, payload: { moduleIndex } });
  }, []);

  const handleAddSearchOptionClick = useCallback(() => {
    if (!_isEmpty(allFormValues)) {
      triggerSubmit(SEARCH_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID, { actionType: ACTION_TYPES.ON_CLICK_ADD_SEARCH_OPTION });
    } else {
      onAction({ type: ACTION_TYPES.ON_CLICK_ADD_SEARCH_OPTION });
      onAction({ type: ACTION_TYPES.ON_CHANGE_MODULE, payload: { moduleIndex: 0 } });
    }
  }, [allFormValues, onAction]);

  const handleDeleteClick = useCallback(
    (moduleIndex) => (event) => {
      onAction({
        type: ACTION_TYPES.DELETE_ROW,
        payload: { moduleIndex },
      });
      event.stopPropagation();
    },
    [onAction],
  );

  const fields = useMemo(
    () => getFields(searchFields, searchableFieldOptions, relationshipFieldOptions),
    [searchFields, searchableFieldOptions, relationshipFieldOptions],
  );

  const sections = useMemo(() => getSearchOptionsFormSection(relationshipField), [relationshipField]);

  const renderModuleItem = useCallback(
    (searchOptionDisplayName, moduleIndex) => (
      <div
        className={cx(styles.moduleItem, {
          [styles.selectedBackgroundColor]: selectedModuleIndex === moduleIndex,
        })}
      >
        <div className={styles.moduleSection}>
          <div className={styles.moduleItemTitle}>{__(!_isEmpty(searchOptionDisplayName) ? searchOptionDisplayName : 'Untitled')}</div>
          <div>
            <FontIcon className={styles.moduleItemDeleteIcon} onClick={handleDeleteClick(moduleIndex)}>
              icon-trashcan
            </FontIcon>
          </div>
        </div>
      </div>
    ),
    [handleDeleteClick, selectedModuleIndex],
  );

  const renderActionModule = useCallback(
    () =>
      _map(allFormValues, (searchOption, moduleIndex) => {
        const displayName = tget(searchOption, [FIELD_IDS.DISPLAY_NAME]);

        return (
          <Module
            key={moduleIndex}
            moduleId={moduleIndex}
            title={renderModuleItem(displayName, moduleIndex)}
            selected={selectedModuleIndex === moduleIndex}
            onModuleSelect={handleTabChange}
          />
        );
      }),
    [allFormValues, handleTabChange, renderModuleItem, selectedModuleIndex],
  );

  return (
    <div>
      <div className={styles.searchOptionsHandlerModal}>
        <div className={styles.searchOptionSideBar}>
          <Button view={Button.VIEW.TERTIARY} className={styles.addSearchOptionButton} onClick={handleAddSearchOptionClick}>
            <FontIcon className={styles.addIcon}>icon-add-circle</FontIcon>
            {__('Add Search Option')}
          </Button>
          {renderActionModule()}
        </div>
        <div className={styles.rightPanel}>
          <PropertyControlledComponent controllerProperty={!_isEmpty(allFormValues)}>
            <FormWithSubmission
              className="full-width p-t-12"
              contextId={SEARCH_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID}
              fields={fields}
              sections={sections}
              values={searchOptionFormValue}
              errors={errors}
              onAction={onAction}
            />
          </PropertyControlledComponent>
        </div>
      </div>
    </div>
  );
};

SearchOptionsModal.propTypes = {
  selectedModuleIndex: PropTypes.number,
  relationshipField: PropTypes.string,
  searchableFieldOptionsByRelationshipField: PropTypes.object,
  allFormValues: PropTypes.array,
  errors: PropTypes.array,
  searchableFieldOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

SearchOptionsModal.defaultProps = {
  selectedModuleIndex: undefined,
  relationshipField: undefined,
  searchableFieldOptionsByRelationshipField: EMPTY_OBJECT,
  errors: EMPTY_ARRAY,
  searchableFieldOptions: EMPTY_ARRAY,
  allFormValues: EMPTY_ARRAY,
};

export default SearchOptionsModal;
