const ACTION_TYPES = {
  ON_MOUNT: 'ON_MOUNT',
  ON_CANCEL: 'ON_CANCEL',
  ON_REDIRECTION: 'ON_REDIRECTION',
  MODAL_SAVE: 'MODAL_SAVE',
  MODAL_CLOSE: 'MODAL_CLOSE',
  DELETE: 'DELETE',
  OVERRIDE: 'OVERRIDE',
  MODAL_OPEN: 'MODAL_OPEN',
};

const CONTEXT_ID = 'RECORD_TYPE_FORM';

const FIELD_IDS = {
  DISPLAY_NAME: 'displayName',
  DESCRIPTION: 'description',
  NAME: 'name',
  STATUS: 'active',
  AVAILABLE_FIELDS: 'availableFields',
  DERIVATION_CONDITION: 'derivationCondition',
};

const STATUS_OPTIONS = [
  {
    label: __('Active'),
    value: true,
  },
  {
    label: __('Inactive'),
    value: false,
  },
];

const RECORD_TYPE_FIELD_NAME = 'recordTypeName';

export { ACTION_TYPES, CONTEXT_ID, FIELD_IDS, STATUS_OPTIONS, RECORD_TYPE_FIELD_NAME };
