import React from 'react';
import compose from 'recompose/compose';
import renameProps from 'recompose/renameProps';
import PropTypes from 'prop-types';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';

import MEDIA_PROPERTIES_FORM_ACTION_HANDLERS from './helpers/mediaPropertiesForm.actionHandlers';
import MEDIA_PROPERTIES_FORM_FIELDS from './helpers/mediaPropertiesForm.fields';

import MEDIA_PROPERTIES_FORM_SECTIONS from './constants/mediaPropertiesForm.sections';
import FIELDS_FORM_FIELD_IDS from '../../constants/fieldsForm.fieldIds';

const MediaPropertiesForm = ({ error, values, onAction }) => (
  <FormWithSubmission
    className="full-width"
    contextId={FIELDS_FORM_FIELD_IDS.MEDIA_PROPERTIES_FORM}
    errors={error}
    values={values}
    fields={MEDIA_PROPERTIES_FORM_FIELDS}
    sections={MEDIA_PROPERTIES_FORM_SECTIONS}
    onAction={onAction}
  />
);

MediaPropertiesForm.propTypes = {
  error: PropTypes.object,
  values: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

MediaPropertiesForm.defaultProps = {
  error: undefined,
  values: EMPTY_OBJECT,
};

export default compose(
  renameProps({ onAction: 'fieldsFormOnAction', value: 'fieldsFormMediaPropertiesValue' }),
  withActions(EMPTY_OBJECT, MEDIA_PROPERTIES_FORM_ACTION_HANDLERS),
)(MediaPropertiesForm);
