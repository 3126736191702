import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cx from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Components
import Page from '@tekion/tekion-components/molecules/pageComponent';
import Loader from '@tekion/tekion-components/molecules/loader';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import FormBuilder from '@tekion/tekion-components/organisms/FormBuilder';
import withEventSubmission from '@tekion/tekion-components/pages/formPage/withEventSubmission';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import withSize from '../../../../../connectors/withSize';
// Constants
import { ACTION_TYPES, CONTEXT_ID, FIELD_IDS } from './constants/createView.constants';
import { FORM_MODES } from '../../../../../constants/general.constants';
// Helpers
import { getFormFields, getFormSections } from './helpers/createView.general.helpers';
import ACTION_HANDLERS from './helpers/createView.actionHandlers';

// Styles
import styles from './createView.module.scss';
import entityReader from '../../../../../readers/entity.reader';

const Form = withEventSubmission(FormBuilder);

const CreateView = ({
  isLoading,
  contentHeight,
  formMode,
  initialViewConfiguration,
  cachedCreateViewFormState,
  entity,
  recordTypeOptions,
  selectedColumnIds,
  formValues,
  formErrors,
  history,
  match,
  onAction,
}) => {
  const viewName = _get(match, 'params.viewName') || _get(history, 'location.state.viewConfiguration.name');
  const formTitle = _isEmpty(viewName) ? __('Create View') : __('Edit View');
  const viewType = useMemo(() => getArraySafeValue(_get(formValues, FIELD_IDS.VIEW_TYPE, EMPTY_ARRAY)), [formValues]);
  const isCurrentEntityRecordTypeEnabled = useMemo(() => entityReader.recordTypeEnabled(entity), [entity]);

  const formFields = useMemo(
    () => getFormFields(selectedColumnIds, viewType, recordTypeOptions, formMode),
    [selectedColumnIds, viewType, recordTypeOptions, formMode],
  );
  const formSections = useMemo(
    () => getFormSections(formValues, formMode, isCurrentEntityRecordTypeEnabled),
    [formValues, formMode, isCurrentEntityRecordTypeEnabled],
  );

  const isValidViewSummary = useMemo(() => !_isEmpty(formValues[FIELD_IDS.VIEW_NAME]) && !_isEmpty(formValues[FIELD_IDS.VIEW_TYPE]), [formValues]);

  const handleSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, []);

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_REDIRECTION });
  }, [onAction]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.INITIALIZE,
      payload: { initialViewConfiguration, entity, cachedCreateViewFormState },
    });
  }, [onAction, initialViewConfiguration, entity, cachedCreateViewFormState]);

  const renderPageContent = () => {
    if (isLoading) {
      return <Loader />;
    } else {
      return (
        <Form
          className={`full-width p-3 ${styles.form}`}
          contextId={CONTEXT_ID}
          sections={formSections}
          fields={formFields}
          values={formValues}
          errors={formErrors}
          onAction={onAction}
        />
      );
    }
  };

  return (
    <Page className="full-height">
      <Page.Header hasBack className={styles.header}>
        <div className="full-width flex justify-content-between">
          <Heading>{formTitle}</Heading>
        </div>
      </Page.Header>
      <Page.Body className={cx('full-width flex', styles.body)} style={{ height: contentHeight }}>
        {renderPageContent()}
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id={CONTEXT_ID}
          isPrimaryDisabled={!isValidViewSummary}
          primaryButtonLabel={__('Next')}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

CreateView.propTypes = {
  isLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  formValues: PropTypes.object,
  initialViewConfiguration: PropTypes.object,
  entity: PropTypes.object.isRequired,
  recordTypeOptions: PropTypes.array,
  selectedColumnIds: PropTypes.arrayOf(PropTypes.string),
  formErrors: PropTypes.arrayOf(PropTypes.object),
  cachedCreateViewFormState: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
};

CreateView.defaultProps = {
  isLoading: true,
  formMode: FORM_MODES.CREATE,
  formValues: EMPTY_OBJECT,
  initialViewConfiguration: EMPTY_OBJECT,
  cachedCreateViewFormState: EMPTY_OBJECT,
  selectedColumnIds: EMPTY_ARRAY,
  formErrors: EMPTY_ARRAY,
  recordTypeOptions: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(CreateView);
