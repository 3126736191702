import { FIELD_IDS } from './projectForm.fields';

const PROJECT_FORM_FIELDS = [
  {
    header: {
      className: 'p-t-12',
      label: __('Project details'),
    },
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
      },
    ],
  },
];

export default PROJECT_FORM_FIELDS;
