import { defaultMemoize } from 'reselect';

// Components
import ConditionBuilder from '../../../../../../../../../organisms/conditionBuilder/ConditionBuilder';

// Helpers
import { isConditionRequiredRule } from '../../../../../../../../../organisms/conditionBuilder/helpers/conditionBuilder.general.helpers';
import { getFilteredFieldDefinition } from './conditionModal.helpers';

// Constants
import CONDITION_BUILDER_MODES from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.modes';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import { CONDITION_BUILDER_TYPES } from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import { CHECK_FIELD_OPERATOR_OPTIONS, FIELD_IDS } from '../conditionModal.constants';
import { CONDITION_TYPES } from '../../../../constants/workflow.constants';

const getFields = defaultMemoize(
  (targetType, enableRecordChangeComparatorOperators, conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName) => {
    const updatedConditionBuilderFieldDefinitionObject = getFilteredFieldDefinition(conditionBuilderFieldDefinitionObject);

    return {
      [FIELD_IDS.CONDITION]: {
        id: FIELD_IDS.CONDITION,
        renderer: ConditionBuilder,
        renderOptions: {
          mode: targetType === CONDITION_TYPES.SWITCH ? CONDITION_BUILDER_MODES.SINGLE_CONDITION_MODE : CONDITION_BUILDER_MODES.CONDITION_MODE,
          conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
          label: '',
          validators:
            targetType === CONDITION_TYPES.SWITCH
              ? [isConditionRequiredRule(CONDITION_BUILDER_MODES.SINGLE_CONDITION_MODE)]
              : [isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)],
          required: true,
          childProps: {
            [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
              label: '',
              childProps: {
                [CONDITION_FIELD_IDS.FIELD]: {
                  label: __('Field'),
                  conditionBuilderFieldDefinitionObject:
                    targetType === CONDITION_TYPES.SWITCH ? updatedConditionBuilderFieldDefinitionObject : conditionBuilderFieldDefinitionObject,
                  mapOfVariableToEntityName,
                },
                [CONDITION_FIELD_IDS.OPERATOR]: {
                  enableRecordChangeComparatorOperators,
                  label: __('Operator'),
                  conditionBuilderFieldDefinitionObject:
                    targetType === CONDITION_TYPES.SWITCH ? updatedConditionBuilderFieldDefinitionObject : conditionBuilderFieldDefinitionObject,
                  mapOfVariableToEntityName,
                  isCustomOperatorOptions: targetType === CONDITION_TYPES.SWITCH,
                  customOperatorOptions: CHECK_FIELD_OPERATOR_OPTIONS,
                },
                [CONDITION_FIELD_IDS.VALUES]: {
                  showFieldSwitcher: true,
                  label: __('Value'),
                  conditionBuilderFieldDefinitionObject:
                    targetType === CONDITION_TYPES.SWITCH ? updatedConditionBuilderFieldDefinitionObject : conditionBuilderFieldDefinitionObject,
                  mapOfVariableToEntityName,
                },
              },
            },
          },
        },
      },
    };
  },
);

export default getFields;
