import React from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'recompose';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import createHistory from 'history/createBrowserHistory';

import NotificationWrapper from '@tekion/tekion-components/organisms/NotificationWrapper';
import TekionI18nProvider from '@tekion/tekion-i18n/TekionI18nProvider';
import updateDirection from '@tekion/tekion-i18n/utils/updateDirection';
import translate from '@tekion/tekion-i18n/translate';
import makeStore from '@tekion/tekion-base/redux/store';

import WithAuth from './connectors/WithAuth';
import ErrorBoundary from './atoms/errorBoundary';
import { createAxiosInstance } from './services/apiService/apiService';
import reducers from './reducers';
import Routes from './Routes';

const AppContainer = compose(translate(), WithAuth)(Routes);
const history = createHistory({ basename: '/' });
const App = (i18n) => {
  updateDirection(i18n);

  createAxiosInstance();

  ReactDOM.render(
    <TekionI18nProvider i18n={i18n}>
      <Provider store={makeStore(reducers, history)}>
        <ErrorBoundary>
          <BrowserRouter>
            <AppContainer />
            <NotificationWrapper />
          </BrowserRouter>
        </ErrorBoundary>
      </Provider>
    </TekionI18nProvider>,
    document.getElementById('tekion-dev-platform-container'),
  );
};

export default App;
