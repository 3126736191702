import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import Button from '@tekion/tekion-components/atoms/Button';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import variableApiFactory from '../../../../factories/variableApi.Factory';
import ApplicationRendererContext from '../../../../context/applicationRenderer.context';
import Records from '../../../../actions/customCodeApis/customCode.RecordApi';
import Actions from '../../../../actions/customCodeApis/customCode.ActionApi';

import { getButtonProps } from './buttonWidgetRenderer.helpers';
import { convertEventHandlersFromArrayToObjectByEventName, executeEventFromEventViewConfigData } from '../../../../utils/eventHandlers';

import { MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT } from '../../../../constants/eventHandlers.constants';
import { EVENT_NAMES } from '../../../../constants/eventActions.constants';
import { COMPONENT_CONFIG_KEYS, COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP, WIDGET_TYPES } from '../../constants/visualBuilder.general.constants';
import { GENERAL_PROPERTIES } from '../../constants/visualBuilder.properties.constants';
import { APPLICATION_CONTEXT_KEYS } from '../../../../constants/applicationRenderer.constants';
import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';

const SUPPORTED_CUSTOM_STYLE_BUTTON = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[WIDGET_TYPES.BUTTON_WIDGET];

const ButtonWidgetRenderer = ({ isPreviewMode, componentConfig, pageEntityRecord }) => {
  const properties = useMemo(() => tget(componentConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, EMPTY_OBJECT), [componentConfig]);

  const buttonProps = useMemo(() => getButtonProps(properties, Button.VIEW), [properties]);

  const customStyles = tget(properties, 'customStyles', {});

  const resolvedCustomStyles = useMemo(
    () => getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_BUTTON, customStyles, pageEntityRecord),
    [pageEntityRecord, customStyles],
  );

  const applicationContext = useContext(ApplicationRendererContext);

  const currentUserData = useMemo(() => tget(applicationContext, APPLICATION_CONTEXT_KEYS.CURRENT_USER, EMPTY_OBJECT), [applicationContext]);
  const applicationVariables = useMemo(() => tget(applicationContext, APPLICATION_CONTEXT_KEYS.APPLICATION_VARIABLES, {}), [applicationContext]);
  const handleSetApplicationContext = useMemo(
    () => tget(applicationContext, APPLICATION_CONTEXT_KEYS.SET_APPLICATION_VARIABLES, _noop),
    [applicationContext],
  );

  const handleSetApplicationVariables = useCallback(
    ({ variableName, value }) => {
      handleSetApplicationContext(variableName, value);
    },
    [handleSetApplicationContext],
  );

  const variablesApi = useMemo(
    () => variableApiFactory(applicationVariables, handleSetApplicationVariables),
    [applicationVariables, handleSetApplicationVariables],
  );

  const handleClick = useCallback(() => {
    const eventHandlers = tget(properties, GENERAL_PROPERTIES.EVENT_HANDLERS, EMPTY_ARRAY);
    const modifiedEventHandlers = convertEventHandlersFromArrayToObjectByEventName(eventHandlers);

    const onClickEventData = tget(modifiedEventHandlers, EVENT_NAMES.CLICK, EMPTY_OBJECT);

    const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$RECORD]: pageEntityRecord,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$CURRENT_USER]: currentUserData,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RECORDS]: Records,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.VARIABLES]: variablesApi,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.ACTIONS]: Actions,
    };

    executeEventFromEventViewConfigData(onClickEventData, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE, pageEntityRecord);
  }, [properties, pageEntityRecord, currentUserData, variablesApi]);

  const renderWidget = () => (
    <Button {...buttonProps} style={tget(resolvedCustomStyles, 'container')} onClick={isPreviewMode ? handleClick : _noop} />
  );

  return renderWidget();
};

ButtonWidgetRenderer.propTypes = {
  isPreviewMode: PropTypes.bool,
  componentConfig: PropTypes.object,
  pageEntityRecord: PropTypes.object,
};

ButtonWidgetRenderer.defaultProps = {
  isPreviewMode: false,
  componentConfig: EMPTY_OBJECT,
  pageEntityRecord: EMPTY_OBJECT,
};

export default ButtonWidgetRenderer;
