/* eslint-disable import/order */
import React, { useCallback } from 'react';
import _noop from 'lodash/noop';
import PropTypes from 'prop-types';

// components
import Popover from '@tekion/tekion-components/molecules/popover';
import LinkSelectionPopupContent from './molecules/linkSelectionPopupContent';

// constants
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

// helpers
import { getSelectedLinkNode } from '../../../../helpers/richTextEditor.link';

// containers
import withEditor from '../../../../containers/withEditor';

const renderPopoverContent = (linkNode, closePopup) => {
  const [linkNodeElement] = linkNode || EMPTY_ARRAY;
  return <LinkSelectionPopupContent linkNodeElement={linkNodeElement} closePopup={closePopup} />;
};

function LinkSelectionPopup(props) {
  const { editor, visible, onVisibleChange } = props;
  const linkNode = getSelectedLinkNode(editor);
  const closePopup = useCallback(() => onVisibleChange(false), [onVisibleChange]);

  return (
    <Popover
      content={renderPopoverContent(linkNode, closePopup)}
      visible={Boolean(linkNode && visible)}
      onVisibleChange={onVisibleChange}
      destroyTooltipOnHide
    />
  );
}

LinkSelectionPopup.propTypes = {
  editor: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  onVisibleChange: PropTypes.func,
};

LinkSelectionPopup.defaultProps = {
  visible: false,
  onVisibleChange: _noop,
};

export default withEditor(LinkSelectionPopup);
