import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';
import COLUMN_IDS from './subscriptionConfigList.columnIds';

const SUBSCRIPTION_CONFIG_STATUS_FILTER_OPTIONS = [
  { label: __('Active'), value: false },
  { label: __('Inactive'), value: true },
];

const SUBSCRIPTION_CONFIG_STATUS_FILTER = {
  id: COLUMN_IDS.STATUS,
  type: FILTER_TYPES.SINGLE_SELECT,
  name: __('Status'),
  additional: {
    options: SUBSCRIPTION_CONFIG_STATUS_FILTER_OPTIONS,
  },
};

const DEFAULT_FILTERS_TYPES = [COLUMN_IDS.STATUS];

export { SUBSCRIPTION_CONFIG_STATUS_FILTER, DEFAULT_FILTERS_TYPES };
