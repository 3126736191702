import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _split from 'lodash/split';
import _size from 'lodash/size';
import _join from 'lodash/join';
import _last from 'lodash/last';
import _get from 'lodash/get';

import { NAMESPACES } from '../../../../constants/general.constants';
import SYSTEM_DEFINED_FIELDS from '../../../../constants/systemDefinedFields';

const getDerivedTitle = (valueToPopulate, record) => {
  if (_includes(valueToPopulate, NAMESPACES.CURRENT_RECORD)) {
    let sourceFieldName;
    const parsedResourceValue = _split(valueToPopulate, '.');

    if (_size(parsedResourceValue) === 2) {
      sourceFieldName = _last(parsedResourceValue);
    } else if (_size(parsedResourceValue) === 3) {
      // Assuming here that such field is a complex one.
      sourceFieldName = _join([parsedResourceValue?.[1], parsedResourceValue?.[2]], '.');
    }

    if (!_isEmpty(sourceFieldName)) {
      return _get(record, sourceFieldName, _get(record, [SYSTEM_DEFINED_FIELDS.ENTITY, sourceFieldName]));
    }
    return undefined;
  }

  return valueToPopulate;
};

export { getDerivedTitle };
