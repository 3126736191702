import { tget } from '@tekion/tekion-base/utils/general';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import { FORM_MODES } from '../../../../../../../constants/general.constants';
import { LOGIN_PROVIDERS } from '../../../constants/loginConfigurationsDashboard.general.constants';

import { FIELD_IDS } from './authConfigModal.fields';

const rowCheck = {
  [FIELD_IDS.CLIENT_ID]: ({ selectedProviderType }) => selectedProviderType === LOGIN_PROVIDERS.OKTA_OPENID,
  [FIELD_IDS.CLIENT_SECRET]: ({ selectedProviderType }) => selectedProviderType === LOGIN_PROVIDERS.OKTA_OPENID,
  [FIELD_IDS.IP_SSO_URL]: ({ selectedProviderType }) => selectedProviderType && selectedProviderType !== LOGIN_PROVIDERS.TEKION,
  [FIELD_IDS.REDIRECT_URL]: ({ selectedProviderType }) => selectedProviderType === LOGIN_PROVIDERS.OKTA_OPENID,
  [FIELD_IDS.PROVIDER_TYPE]: ({ formMode }) => formMode === FORM_MODES.CREATE,
  [FIELD_IDS.OKTA_CERTIFICATE]: ({ selectedProviderType }) => selectedProviderType === LOGIN_PROVIDERS.OKTA_SAML,
  [FIELD_IDS.MFA_ENABLED]: ({ selectedProviderType }) => selectedProviderType === LOGIN_PROVIDERS.TEKION,
  [FIELD_IDS.MFA_AUTH_TYPES]: ({ selectedProviderType, isMFAEnabled }) => selectedProviderType === LOGIN_PROVIDERS.TEKION && isMFAEnabled,
};

const getAuthConfigFormSections = (formMode, authConfigFormValues) => {
  const selectedProviderType = tget(authConfigFormValues, FIELD_IDS.PROVIDER_TYPE);
  const isMFAEnabled = tget(authConfigFormValues, FIELD_IDS.MFA_ENABLED);
  return [
    {
      rows: [
        {
          columns: filterRows([FIELD_IDS.PROVIDER_TYPE], { formMode }, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.CLIENT_ID], { selectedProviderType }, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.CLIENT_SECRET], { selectedProviderType }, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.IP_SSO_URL], { selectedProviderType }, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.REDIRECT_URL], { selectedProviderType }, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.OKTA_CERTIFICATE], { selectedProviderType }, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.MFA_ENABLED], { selectedProviderType }, rowCheck),
        },
        {
          columns: filterRows([FIELD_IDS.MFA_AUTH_TYPES], { selectedProviderType, isMFAEnabled }, rowCheck),
        },
      ],
    },
  ];
};

export default getAuthConfigFormSections;
