const PERMISSIONS_CONTROL_FLAGS = {
  DEV_PLATFORM_STUDIO: 'dev_platform_studio',
  DEV_PLATFORM_EXPERIENCE: 'dev_platform_experience',
};

const PERMISSIONS_CONTEXT_KEYS = {
  CONTROL_FLAGS: 'controlFlags',
};

export { PERMISSIONS_CONTROL_FLAGS, PERMISSIONS_CONTEXT_KEYS };
