import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';

import _get from 'lodash/get';
import _set from 'lodash/set';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import InputTableWithRowActions from '@tekion/tekion-components/organisms/inputTable/containers/withRowActions/Table';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import Button from '@tekion/tekion-components/atoms/Button';
import Heading from '@tekion/tekion-components/atoms/Heading';

import getColumns from './calloutRequestDetailsRenderer.columns';
import { checkLastRowFilled } from './calloutRequestDetailsRenderer.helpers';

import { TABLE_ACTION_TYPES, ROW_ACTIONS, ROW_ACTION_PROPS, SECTION_TABLE_ROW_STYLE } from './calloutRequestDetailsRenderer.constants';

import styles from './calloutRequestDetailsRenderer.module.scss';

const CalloutRequestDetailsRenderer = ({ id, buttonLabel, value, conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, onAction }) => {
  const isAddButtonDisabled = useMemo(() => checkLastRowFilled(value), [value]);

  const columns = useMemo(
    () => getColumns(conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName),
    [conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName],
  );

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { id: fieldId, value: updatedValue } = payload;
          const rowIndex = tget(payload, 'nestingPath.[0]');
          const updatedRequestDetails = produce(value, (draft) => {
            _set(draft, `${rowIndex}.${fieldId}`, updatedValue);
          });

          onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id, value: updatedRequestDetails } });

          break;
        }

        case TABLE_ACTION_TYPES.TABLE_ACTION_CLICK: {
          const { actionType } = payload;
          const rowIndex = _get(payload, 'nestingPath.[0]');

          if (actionType === TABLE_ACTION_TYPES.ON_REMOVE) {
            const updatedValue = produce(value, (draft) => {
              draft.splice(rowIndex, 1);
            });

            onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id, value: updatedValue } });
          }
          break;
        }

        default:
      }
    },
    [id, onAction, value],
  );

  const handleClickAdd = useCallback(() => {
    onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id, value: [...value, {}] } });
  }, [id, onAction, value]);

  const getRowActions = useCallback(() => ROW_ACTIONS, []);

  return (
    <div className={styles.requestContainer}>
      <div className={styles.headerContainer}>
        <div>
          <Heading size={4}>{buttonLabel}</Heading>
        </div>
        <div>
          <Button disabled={isAddButtonDisabled} onClick={handleClickAdd}>
            {__(`Add ${buttonLabel}`)}
          </Button>
        </div>
      </div>
      <InputTableWithRowActions
        rowActionProps={ROW_ACTION_PROPS}
        rowStyle={SECTION_TABLE_ROW_STYLE}
        value={value}
        columns={columns}
        getActionsForRow={getRowActions}
        onAction={handleAction}
      />
    </div>
  );
};

CalloutRequestDetailsRenderer.propTypes = {
  id: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  conditionBuilderFieldDefinitionObject: PropTypes.object.isRequired,
  mapOfVariableToEntityName: PropTypes.object.isRequired,
  value: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

CalloutRequestDetailsRenderer.defaultProps = {
  buttonLabel: EMPTY_STRING,
  value: EMPTY_ARRAY,
};

export default CalloutRequestDetailsRenderer;
