import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';

import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { DEFAULT_RESULTS_PER_PAGE } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { searchExternalCredentials } from '../../../../../../../actions/externalCredential.actions';

import { getPayload } from './externalCredentialList.helpers';

import PAGE_IDS from '../../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/externalCredentialList.actionTypes';
import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import COLUMN_IDS from '../constants/externalCredentialList.columnIds';
import { TABLE_CONSTANTS } from '../constants/externalCredentialList.constants';

const handleFetchData = async ({ getState, setState, params }) => {
  const { pageSize = DEFAULT_RESULTS_PER_PAGE, currentPage = 0 } = getState();

  const currentPageToken = _get(params, 'nextPageToken', null);
  const payload = getPayload({ currentPage, currentPageToken, pageSize });

  setState({ isLoading: true });

  const response = await searchExternalCredentials(payload);

  const nextPageToken = tget(response, 'nextPageToken', EMPTY_STRING);
  const tableData = tget(response, 'hits', EMPTY_ARRAY);

  setState({ tableData, nextPageToken, isLoading: false });
};

const handleRefreshListView = ({ getState, setState }) => {
  setState(
    {
      currentPage: TABLE_CONSTANTS.CURRENT_PAGE,
    },
    () => {
      handleFetchData({ getState, setState });
    },
  );
};

const handleCreateAuthProtocol = ({ getState }) => {
  const { history } = getState();
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.EXTERNAL_CREDENTIAL_CREATE}`;

  history.push({ pathname });
};

const handleEditAuthProtocol = ({ getState, params }) => {
  const { history } = getState();
  const externalCredentialName = _get(params, [COLUMN_IDS.NAME]);

  if (!_isEmpty(externalCredentialName)) {
    const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.EXTERNAL_CREDENTIAL_EDIT}/${externalCredentialName}`;
    history.push({ pathname, state: { externalCredentialData: params } });
  }
};

const handleTableItemClick = ({ getState, params }) => {
  const { history } = getState();
  const rowData = _get(params, ['value', 'original']);
  const externalCredentialName = _get(rowData, [COLUMN_IDS.NAME]);

  if (!_isEmpty(externalCredentialName)) {
    const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.EXTERNAL_CREDENTIAL_EDIT}/${externalCredentialName}`;
    history.push({ pathname, state: { externalCredentialData: rowData } });
  }
};

const handlePageSizeUpdate = ({ getState, setState, params }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens = EMPTY_ARRAY, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');

  let prevPageTokens = [...previousPageTokens];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }
  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }
  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      handleFetchData({ getState, setState, params: { nextPageToken: currentPageToken } });
    },
  );
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleFetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleRefreshListView,
  [ACTION_TYPES.CREATE_EXTERNAL_CREDENTIAL]: handleCreateAuthProtocol,
  [ACTION_TYPES.EDIT_EXTERNAL_CREDENTIAL]: handleEditAuthProtocol,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleTableItemClick,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageSizeUpdate,
};

export default ACTION_HANDLERS;
