import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Button from '@tekion/tekion-components/atoms/Button';

import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import Comment from './Comment';

import ACTION_TYPES from '../constants/commentRenderer.actionTypes';
import commentReader from '../../../readers/comment.reader';

import styles from '../commentRenderer.module.scss';

const CommentList = ({
  isPreviewMode,
  nextPageToken,
  commentLevel,
  parentCommentId,
  parentCommentIndex,
  commentInputRef,
  commentsTree,
  userDetailsById,
  currentUser,
  scrollToCommentInput,
  onAction,
}) => {
  const [loadingMoreComments, setLoadingMoreComments] = useState(false);
  const commentRef = useRef(parentCommentId);
  const scrollToTopComment = useCallback(() => {
    if (commentRef && commentRef.current && commentRef.current.scrollIntoView) {
      commentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [commentRef]);

  const handleLoadMoreCommentsClick = useCallback(() => {
    setLoadingMoreComments(true);
    onAction({ type: ACTION_TYPES.LOAD_MORE_COMMENTS, payload: { commentId: parentCommentId, setLoadingMoreComments } });
  }, [parentCommentId, onAction]);

  const renderComments = useCallback(() => {
    if (isPreviewMode) {
      return _map(commentsTree, (comment, index) => (
        <Comment
          key={commentReader.id(comment)}
          commentRef={index === 0 && commentLevel === 2 ? commentRef : null}
          parentCommentIndex={parentCommentIndex}
          commentIndex={index}
          commentLevel={commentLevel}
          comment={comment}
          userDetailsById={userDetailsById}
          scrollToCommentInputHandler={scrollToCommentInput}
          currentUser={currentUser}
          onAction={onAction}
        />
      ));
    }
    return null;
  }, [isPreviewMode, commentsTree, commentLevel, parentCommentIndex, userDetailsById, scrollToCommentInput, currentUser, onAction]);

  return (
    <div ref={commentLevel === 1 ? commentRef : null}>
      {renderComments()}
      <div className={styles.loadMoreCommentsSection}>
        <PropertyControlledComponent controllerProperty={nextPageToken && isPreviewMode}>
          <Button view={Button.VIEW.TERTIARY} label={__('Load more Comments')} onClick={handleLoadMoreCommentsClick} loading={loadingMoreComments} />
        </PropertyControlledComponent>
      </div>
      <div ref={commentInputRef}>
        <Comment
          isPreviewMode={isPreviewMode}
          commentLevel={commentLevel}
          parentCommentId={parentCommentId}
          currentUser={currentUser}
          scrollToTopComment={scrollToTopComment}
          onAction={onAction}
        />
      </div>
    </div>
  );
};

CommentList.propTypes = {
  commentLevel: PropTypes.number,
  parentCommentIndex: PropTypes.number,
  isPreviewMode: PropTypes.bool,
  nextPageToken: PropTypes.string,
  parentCommentId: PropTypes.string,
  commentsTree: PropTypes.array,
  commentInputRef: PropTypes.object,
  userDetailsById: PropTypes.object,
  currentUser: PropTypes.object,
  scrollToCommentInput: PropTypes.func,
  onAction: PropTypes.func.isRequired,
};

CommentList.defaultProps = {
  commentLevel: 1,
  parentCommentIndex: undefined,
  isPreviewMode: true,
  nextPageToken: null,
  parentCommentId: undefined,
  commentsTree: EMPTY_ARRAY,
  commentInputRef: undefined,
  userDetailsById: EMPTY_OBJECT,
  currentUser: EMPTY_OBJECT,
  scrollToCommentInput: _noop,
};

export default CommentList;
