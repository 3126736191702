import _isFunction from 'lodash/isFunction';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import ACTION_TYPES from '../constants/fieldsList.actionTypes';

const handleCreateClick = ({ getState }) => {
  const { onCreateClick } = getState();
  onCreateClick();
};

const handleRowClick = ({ getState, params = EMPTY_OBJECT }) => {
  const { onFieldClick } = getState();
  const field = params?.value.original;
  const { name } = field;
  if (_isFunction(onFieldClick)) {
    onFieldClick(name);
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE_FIELD_CLICK]: handleCreateClick,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleRowClick,
};

export default ACTION_HANDLERS;
