import { APPROVAL_VALIDITY_TYPES } from '../../../../../../../constants/approvalCentre.constants';

const RULE_STATUS = {
  ACTIVE: false,
  INACTIVE: true,
};

const RULE_STATUS_OPTIONS = [
  { label: __('Active'), value: RULE_STATUS.ACTIVE },
  { label: __('Inactive'), value: RULE_STATUS.INACTIVE },
];

const VALIDITY_TYPE_OPTIONS = [
  { label: __('Relative'), value: APPROVAL_VALIDITY_TYPES.RELATIVE },
  { label: __('Exact'), value: APPROVAL_VALIDITY_TYPES.EXACT },
];

export { RULE_STATUS, RULE_STATUS_OPTIONS, VALIDITY_TYPE_OPTIONS };
