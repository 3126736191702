import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
// import _nth from 'lodash/nth';
import _castArray from 'lodash/castArray';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _compact from 'lodash/compact';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { getCompactedFiltersPayload } from '../../../../../helpers/general.helpers';
import { NAVIGATION_TYPES, APPLICATION_COMPONENT_TYPES, PROPERTY_IDS, APPROVAL_TAB_ID } from '../constants/applicationRenderer.constants';
import { APPLICATION_SEARCH_PARAM_IDS } from '../../../../../constants/applicationRenderer.constants';
import { APPROVAL_METADATA_PROPERTY_IDS } from '../../../../../constants/approvalCentre.constants';

const getTabData = (tabComponent) => {
  const properties = _get(tabComponent, 'properties');
  const tabName = _get(properties, 'tabName');
  const tabIcon = _get(properties, 'tabIcon');
  const displayName = _get(properties, 'tabDisplayName', '-');
  const pageName = _get(tabComponent, 'pageName');

  const searchParams = new URLSearchParams(EMPTY_STRING);
  searchParams.append('tab', tabName);
  searchParams.append('pageName', pageName);

  const data = {
    pageName,
    id: tabName,
    displayName,
    tabIcon,
    lastUrl: `?${searchParams.toString()}`,
  };

  return { data, pageName, tabName };
};

const getAllTabsData = (navigationComponent, approvalMetadata) => {
  const tabsData = [];
  const tabIdToOrderMapper = {};
  const pageNames = [];
  const navigationTabs = _get(navigationComponent, 'children');

  _forEach(navigationTabs, (tabComponent, index) => {
    const { data, pageName, tabName } = getTabData(tabComponent);
    tabsData[index] = data;
    tabIdToOrderMapper[tabName] = index;
    pageNames.push(pageName);
  });

  const isApprovalTabEnabled = tget(approvalMetadata, APPROVAL_METADATA_PROPERTY_IDS.IS_ENABLED, false);
  if (isApprovalTabEnabled) {
    tabsData.push({
      id: APPROVAL_TAB_ID,
      ...approvalMetadata,
    });
    tabIdToOrderMapper[APPROVAL_TAB_ID] = tabsData.length - 1;
  }

  return { tabsData, tabIdToOrderMapper, pageNames };
};

const getNoNavigationData = (noNavigationComponent) => {
  // const properties = _get(noNavigationComponent, 'properties');
  const pageName = tget(noNavigationComponent, 'pageName', EMPTY_STRING);
  const searchParams = new URLSearchParams(EMPTY_STRING);
  searchParams.append('pageName', pageName);

  const data = {
    pageName,
    id: 'home',
    lastUrl: `?${searchParams.toString()}`,
  };
  const pageNameArray = [pageName];
  return { data, pageNameArray };
};

const getApplicationData = (applicationData) => {
  const navigationComponent = _find(tget(applicationData, 'components'), { type: APPLICATION_COMPONENT_TYPES.NAVIGATION });
  const noNavigationComponent = _find(tget(applicationData, 'components'), { type: APPLICATION_COMPONENT_TYPES.NO_NAVIGATION });
  const approvalMetadata = tget(applicationData, ['properties', PROPERTY_IDS.APPROVAL_METADATA], EMPTY_OBJECT);

  const isNavigationRequire = !_isEmpty(navigationComponent);

  let noNavigationData = EMPTY_OBJECT;
  let pageNamesArray;

  const { tabsData, tabIdToOrderMapper, pageNames } = getAllTabsData(navigationComponent, approvalMetadata);
  pageNamesArray = pageNames;

  if (!isNavigationRequire) {
    const { data, pageNameArray } = getNoNavigationData(noNavigationComponent);
    noNavigationData = data;
    pageNamesArray = pageNameArray;
  }

  const navigationType = tget(navigationComponent, 'properties.navigationType', NAVIGATION_TYPES.TOP);

  return {
    tabsData,
    noNavigationData,
    tabIdToOrderMapper,
    isNavigationRequired: isNavigationRequire,
    pageNames: pageNamesArray,
    navigationType,
  };
};

const getUrl = ({ tabId, pageName, url = '', recordId }) => {
  const searchParams = new URLSearchParams(url);
  if (tabId) {
    searchParams.set(APPLICATION_SEARCH_PARAM_IDS.TAB_ID, tabId);
  }
  if (pageName) {
    searchParams.set(APPLICATION_SEARCH_PARAM_IDS.PAGE_NAME, pageName);
  }
  if (recordId) {
    searchParams.set(APPLICATION_SEARCH_PARAM_IDS.PAGE_RECORD_ID, recordId);
  } else {
    searchParams.delete(APPLICATION_SEARCH_PARAM_IDS.PAGE_RECORD_ID);
  }

  return `?${searchParams.toString()}`;
};

// const getUpdatedTabsData = (tabsData, tabIdToOrderMapper, tabId, newTabData) => {
//   const currentTabIndex = tabIdToOrderMapper[tabId];
//   const currentTabData = _nth(tabsData, currentTabIndex);
//   const updatedCurrentTabData = { ...currentTabData, ...newTabData };
//   _set(tabsData, currentTabIndex, updatedCurrentTabData);

//   return [...tabsData];
// };

const getUpdatedContentHeightWidth = ({ hasTabs = false, contentHeight, contentWidth, tabPosition }) => {
  let updatedContentHeight = contentHeight;
  let updatedContentWidth = contentWidth;

  if (hasTabs && tabPosition === NAVIGATION_TYPES.TOP) {
    updatedContentHeight -= 55;
  }

  if (hasTabs && tabPosition === NAVIGATION_TYPES.LEFT) {
    // 28px padding left is added by antd to tab content. Hence removing it from contentWidth as well
    updatedContentWidth -= 160 + 28;
  }

  return { updatedContentHeight, updatedContentWidth };
};

const getFlattenProperties = (properties) =>
  _reduce(
    properties,
    (result, property) => {
      const type = _get(property, 'type');
      const value = _get(property, 'value');
      if (!_isEmpty(type)) {
        return { ...result, [type]: value };
      }
      return result;
    },
    {},
  );

const getComponentWithFlattenProperties = (component) => {
  const flattenProperties = getFlattenProperties(tget(component, 'properties', EMPTY_ARRAY));
  const children = tget(component, 'children', EMPTY_ARRAY);
  let updatedChildren = [];
  if (!_isEmpty(children)) {
    updatedChildren = _map(children, (child) => getComponentWithFlattenProperties(child));
  }

  return { ...component, properties: flattenProperties, children: updatedChildren };
};

const getAppConfigWithFlattenProperties = (applicationConfig) => {
  const components = _get(applicationConfig, 'components');
  const updatedComponents = _map(components, (component) => getComponentWithFlattenProperties(component));

  return { ...applicationConfig, properties: getFlattenProperties(tget(applicationConfig, 'properties')), components: updatedComponents };
};

// const getPageConfigsWithFlattenProperties = (pageConfigs) =>
//   _map(pageConfigs, (config) => {
//     const containers = _get(config, 'containers');
//     const updatedContainers = _map(containers, (container) => getComponentWithFlattenProperties(container));

//     return { ...config, properties: getFlattenProperties(tget(config, 'properties')), containers: updatedContainers };
//   });

const getPayloadForPageConfig = (pageNames) => {
  let payload = {};
  if (!_isEmpty(pageNames)) {
    const filters = [
      {
        field: 'name',
        values: pageNames,
        filterType: OPERATORS.IN,
      },
    ];
    payload = { ...payload, filters: getCompactedFiltersPayload(filters) };
  }

  return payload;
};

const getPageNamesFromApplicationConfig = (components, pageNamesInApplicationResponse) => {
  _forEach(components, (component) => {
    const children = tget(component, 'children', EMPTY_ARRAY);
    const pageName = tget(component, 'pageName');
    pageNamesInApplicationResponse.push(pageName);
    getPageNamesFromApplicationConfig(children, pageNamesInApplicationResponse);
  });
};

const getPayloadForPageConfigFetch = (pageNames) => {
  const filter = [{ filterType: OPERATORS.IN, field: 'name', values: _castArray(_compact(pageNames)) }];
  return { filters: getCompactedFiltersPayload(filter) };
};

export {
  getApplicationData,
  getUrl,
  getAppConfigWithFlattenProperties,
  getPayloadForPageConfig,
  getUpdatedContentHeightWidth,
  getPageNamesFromApplicationConfig,
  getPayloadForPageConfigFetch,
};
