import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import Modal from '@tekion/tekion-components/molecules/Modal';
import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import Heading from '@tekion/tekion-components/atoms/Heading';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';

import ACTION_HANDLERS from './deleteNodeModal.actionHandler';
import { FORM_SECTIONS, getFieldConfig } from './deleteNodeModal.fields';
import { CONTEXT_ID } from './deleteNodeModal.constants';

const DeleteNodeModal = ({ shouldShowRadio, title, values, errors, headerContent, options, submitBtnText, onCancel, onSubmit, onAction }) => {
  const fields = useMemo(() => getFieldConfig(options), [options]);
  const handleFormSubmit = useCallback(() => (shouldShowRadio ? triggerSubmit(CONTEXT_ID) : onSubmit()), [shouldShowRadio, onSubmit]);

  useEffect(() => {
    onAction({ type: FORM_ACTION_TYPES.ON_FORM_INIT });
  }, [onAction]);

  return (
    <Modal visible destroyOnClose title={title} width={Modal.SIZES.MD} submitBtnText={submitBtnText} onCancel={onCancel} onSubmit={handleFormSubmit}>
      <Heading className="p-b-12" size={5}>
        {headerContent}
      </Heading>
      <PropertyControlledComponent controllerProperty={shouldShowRadio}>
        <FormWithSubmission contextId={CONTEXT_ID} sections={FORM_SECTIONS} fields={fields} values={values} errors={errors} onAction={onAction} />
      </PropertyControlledComponent>
    </Modal>
  );
};

DeleteNodeModal.propTypes = {
  shouldShowRadio: PropTypes.bool,
  title: PropTypes.string,
  submitBtnText: PropTypes.string,
  headerContent: PropTypes.string,
  errors: PropTypes.object,
  values: PropTypes.object,
  options: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onAction: PropTypes.func.isRequired,
};

DeleteNodeModal.defaultProps = {
  shouldShowRadio: false,
  title: __('Delete this step'),
  submitBtnText: __('Delete'),
  headerContent: __('Are you sure you want to delete?'),
  errors: EMPTY_OBJECT,
  values: EMPTY_OBJECT,
  options: EMPTY_ARRAY,
  onCancel: _noop,
  onSubmit: _noop,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS))(DeleteNodeModal);
