import _castArray from 'lodash/castArray';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { TEXT_CONTAINS, TEXT_STARTS_WITH } from '../../../../../../../../../constants/filter.constants';
import { PAGE_FIELD_IDS, PAGE_TYPES } from '../../../../../../../../../constants/visualBuilder';

import entityReader from '../../../../../../../../../readers/entity.reader';
import { ENTITY_APPLICATION_MAPPING_FIELD_IDS } from '../../../../../../../../../constants/entityApplicationMapping.constants';

const ROWS = 20;

const getEntityOptions = (entities) =>
  _map(entities, (entity) => ({
    label: entityReader.displayName(entity),
    value: entityReader.name(entity),
  }));

const getEntityPayload =
  (excludeEntities = []) =>
  ({ nextPageToken, searchText }) => {
    const filters = [];

    if (!_isEmpty(excludeEntities)) {
      filters.push({
        field: 'name',
        filterType: OPERATORS.NIN,
        values: _castArray(excludeEntities),
      });
    }

    if (!_isEmpty(searchText)) {
      filters.push({
        field: 'displayName',
        filterType: TEXT_STARTS_WITH,
        values: [searchText],
      });
    }

    return {
      rows: ROWS,
      nextPageToken,
      filters,
    };
  };

const getPageOptions = (pages) =>
  _map(pages, (page) => ({
    label: tget(page, PAGE_FIELD_IDS.PAGE_TITLE),
    value: tget(page, PAGE_FIELD_IDS.PAGE_NAME),
  }));

const getPagePayload =
  (entityFieldValue) =>
  ({ nextPageToken, searchText }) => {
    const filters = [
      {
        field: PAGE_FIELD_IDS.PAGE_TYPE,
        filterType: OPERATORS.IN,
        values: _castArray(PAGE_TYPES.RECORD_DETAIL_PAGE),
      },
    ];

    if (!_isEmpty(entityFieldValue)) {
      filters.push({
        field: PAGE_FIELD_IDS.PAGE_ENTITY_NAME,
        filterType: OPERATORS.IN,
        values: _castArray(entityFieldValue),
      });
    }

    if (!_isEmpty(searchText)) {
      filters.push({
        field: PAGE_FIELD_IDS.PAGE_TITLE,
        filterType: TEXT_CONTAINS,
        values: [searchText],
      });
    }

    return {
      rows: ROWS,
      nextPageToken,
      filters,
    };
  };

const getSelectedEntities = (rows, index) => {
  const entitiesList = _map(rows, ENTITY_APPLICATION_MAPPING_FIELD_IDS.ENTITY_NAME);
  entitiesList.splice(index, 1);

  return entitiesList;
};

export { getEntityOptions, getEntityPayload, getPageOptions, getPagePayload, getSelectedEntities };
