import React from 'react';
import { ToolbarAlign } from '@udecode/plate-alignment-ui';
import PropTypes from 'prop-types';

// components
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

function AlignmentToolbarButton(props) {
  const { id: align, icon } = props;
  return <ToolbarAlign align={align} icon={<FontIcon size={SIZES.MD_S}>{icon}</FontIcon>} />;
}

AlignmentToolbarButton.propTypes = {
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default AlignmentToolbarButton;
