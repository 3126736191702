const makeGetImageNodeProps = (readOnly) => () => ({
  enableMediaDeletion: !readOnly,
  enableMediaResizing: !readOnly,
  enableImagePreview: true,
  enableMediaHighlighting: !readOnly,
});

const createImageNodeOptions = (readOnly) => ({
  getNodeProps: makeGetImageNodeProps(readOnly),
});

export default createImageNodeOptions;
