import produce from 'immer';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _snakeCase from 'lodash/snakeCase';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import {
  createServerCredential,
  fetchServerCredentialByName,
  updateServerCredentialByName,
} from '../../../../../../../actions/serverCredential.actions';

import { STUDIO_ROUTE } from '../../../../../../../constants/routes';
import { FORM_MODES } from '../../../../../../../constants/general.constants';
import PAGE_IDS from '../../../../../constants/PageIds.constants';
import ACTION_TYPES from '../constants/serverCredentialForm.actionTypes';
import { FIELD_IDS } from '../constants/serverCredentialForm.constants';
// eslint-disable-next-line max-len
import { CREATABLE_ASYNC_SELECT_ACTION_TYPES } from '../../../../../../../molecules/asyncPaginatedCreatableSelect/asyncPaginatedCreatableSelect.constants';

const handleInit = async ({ getState, setState }) => {
  const { history, match } = getState();
  const serverCredentialName = _get(match, 'params.serverCredentialName');
  const isRedirectedBackExternalCredential = tget(history, 'location.state.isRedirectedBackExternalCredential', false);

  if (isRedirectedBackExternalCredential) {
    const serverCredentialFormValue = tget(history, 'location.state.serverCredentialFormValue', EMPTY_OBJECT);
    const externalCredentialDetails = tget(history, 'location.state.externalCredentialDetails', EMPTY_OBJECT);

    const newServerCredentialFormValue = {
      ...serverCredentialFormValue,
      [FIELD_IDS.EXTERNAL_CREDENTIAL_NAME]: tget(externalCredentialDetails, ['value']),
    };

    setState({ formMode: FORM_MODES.CREATE, serverCredentialFormValue: newServerCredentialFormValue });
  } else if (_isEmpty(serverCredentialName)) {
    setState({ formMode: FORM_MODES.CREATE });
  } else {
    setState({ isDataLoading: true, formMode: FORM_MODES.EDIT });

    const serverCredentialFormValue = await fetchServerCredentialByName(serverCredentialName);

    setState({ serverCredentialFormValue, isDataLoading: false });
  }
};

const handleFieldChange = ({ getState, setState, params }) => {
  const { formMode, disableAutoFillForName = false } = getState();
  const { id, value } = params;

  setState(
    produce((draft) => {
      _set(draft, `serverCredentialFormValue.${id}`, value);

      if (formMode === FORM_MODES.CREATE && id === FIELD_IDS.DISPLAY_NAME && !disableAutoFillForName) {
        _set(draft, `serverCredentialFormValue.${FIELD_IDS.NAME}`, _snakeCase(value));
      } else if (id === FIELD_IDS.NAME) {
        setState({ disableAutoFillForName: true });
      }
    }),
  );
};

const handleErrors = ({ setState, params }) => {
  const { errors } = params;

  setState({ errors });
};

const handleRedirection = ({ getState }) => {
  const { history } = getState();

  const path = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.SERVER_CREDENTIALS_LIST}`;
  history.push({ pathname: path });
};

const handleSubmit = async ({ getState, setState }) => {
  const { serverCredentialFormValue, formMode } = getState();
  const serverCredentialName = _get(serverCredentialFormValue, [FIELD_IDS.NAME]);
  let response = {};

  setState({ isSaveLoading: true });

  if (formMode === FORM_MODES.CREATE) {
    response = await createServerCredential(serverCredentialFormValue);
  } else if (formMode === FORM_MODES.EDIT) {
    response = await updateServerCredentialByName(serverCredentialName, serverCredentialFormValue);
  }

  if (serverCredentialName === _get(response, [FIELD_IDS.NAME])) {
    handleRedirection({ getState });
  }

  setState({ isSaveLoading: false });
};

const handleCreateExternalCredential = ({ getState }) => {
  const { history, serverCredentialFormValue } = getState();
  const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.SERVER_CREDENTIAL_CONFIGURE}/${PAGE_IDS.EXTERNAL_CREDENTIAL_CREATE}`;

  history.push({ pathname, state: { serverCredentialFormValue, isRedirectedFromServerCredential: true } });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_REDIRECTION]: handleRedirection,
  [CREATABLE_ASYNC_SELECT_ACTION_TYPES.ON_CREATE_NEW_OPTION]: handleCreateExternalCredential,
};

export default ACTION_HANDLERS;
