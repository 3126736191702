import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/governance/sharing-rules';

const fetchRecordSharingRuleList = (entityName = EMPTY_STRING) => ApiService.get(`${BASE_URL}/${entityName}`);

const createRecordSharingRule = (entityName, payload = EMPTY_ARRAY) => ApiService.post(`${BASE_URL}/${entityName}`, payload);

const editRecordSharingRule = (recordSharingName, entityName, payload = EMPTY_OBJECT) =>
  ApiService.put(`${BASE_URL}/${entityName}/name/${recordSharingName}`, payload);

const deleteRecordSharingRule = (recordSharingName, entityName) => ApiService.delete(`${BASE_URL}/${entityName}/name/${recordSharingName}`);

export default {
  fetchRecordSharingRuleList,
  createRecordSharingRule,
  editRecordSharingRule,
  deleteRecordSharingRule,
};
