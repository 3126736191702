const PROD_PUBLIC_S3_PATH = 'https://com-tekioncloud-cdms-global.s3-us-west-1.amazonaws.com/DMS/common';

const COLUMN = `${PROD_PUBLIC_S3_PATH}/ColumnChart.svg`;

const BAR = `${PROD_PUBLIC_S3_PATH}/BarChart.svg`;

const MULTI_LINE = `${PROD_PUBLIC_S3_PATH}/MultiLineChart.svg`;
const AREA = `${PROD_PUBLIC_S3_PATH}/AreaChart.svg`;

const DONUT = `${PROD_PUBLIC_S3_PATH}/DonutChart.svg`;
const TABLE = `${PROD_PUBLIC_S3_PATH}/TableData.svg`;
const PIE = `${PROD_PUBLIC_S3_PATH}/PieChart.svg`;

export default { COLUMN, BAR, MULTI_LINE, AREA, DONUT, TABLE, PIE };
