import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import AsyncPaginatedSelect from '../../../asyncPaginatedSelect/AsyncPaginatedSelect';

import { searchViewConfigurations } from '../../../../actions/viewBuilderPage.actions';
import { getValuesFromProperties, getUpdatedValue, getOptions, getPayload } from './selectViewField.helper';

import { FIELD_IDS, FIELD_LABELS } from './selectViewField.constants';
import { ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';

import entityReader from '../../../../readers/entity.reader';

import styles from './selectViewField.module.scss';

const SelectViewField = ({ entity, selectedViewComponentProperties, errors, onAction }) => {
  const ref = useRef(null);

  const entityName = entityReader.name(entity);

  const { selectedViewName, isCardViewEnabled } = useMemo(
    () => getValuesFromProperties(selectedViewComponentProperties),
    [selectedViewComponentProperties],
  );

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const newViewComponentProperties = getUpdatedValue(payload);
          onAction({
            type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
            payload: { id: ALL_VIEW_PROPERTY_KEYS.CARD_VIEW_NAME, value: newViewComponentProperties },
          });
          break;
        }
        default:
          onAction(action);
      }
    },
    [onAction],
  );

  useEffect(() => {
    const resetLoadedOptions = tget(ref, 'current.resetLoadedOptions', _noop);
    resetLoadedOptions();
  }, [entityName]);

  return (
    <div>
      <div className={styles.checkBoxComponent}>
        <CheckboxRenderer
          checked={isCardViewEnabled}
          id={FIELD_IDS.IS_CARD_VIEW_ENABLED}
          checkboxLabel={FIELD_LABELS[FIELD_IDS.IS_CARD_VIEW_ENABLED]}
          onAction={handleAction}
        />
      </div>
      <PropertyControlledComponent controllerProperty={isCardViewEnabled}>
        <AsyncPaginatedSelect
          required
          id={FIELD_IDS.VIEW_NAME}
          ref={ref}
          label={__('View')}
          fieldClassName={styles.asyncSelect}
          value={selectedViewName}
          error={errors?.[FIELD_IDS.VIEW_NAME]}
          serviceHandler={searchViewConfigurations}
          getOptions={getOptions}
          getPayload={getPayload(entityName)}
          onAction={handleAction}
        />
      </PropertyControlledComponent>
    </div>
  );
};

SelectViewField.propTypes = {
  entity: PropTypes.object.isRequired,
  selectedViewComponentProperties: PropTypes.object,
  errors: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

SelectViewField.defaultProps = {
  selectedViewComponentProperties: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
};

export default SelectViewField;
