import ACTION_TYPES from './permissionSetList.actionTypes';

const EDIT_ACTION = {
  id: ACTION_TYPES.EDIT,
  name: __('Edit'),
};

const DELETE_ACTION = {
  id: ACTION_TYPES.DELETE,
  name: __('Delete'),
};

export { EDIT_ACTION, DELETE_ACTION };
