import _get from 'lodash/get';
import _split from 'lodash/split';

// Constants
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

export const getQueryManifestQueryString = (context) => {
  const manifestFileUrl = _get(context, 'frag.baseurl');
  const [baseUrl, queryString] = _split(manifestFileUrl, '?'); // eslint-disable-line no-unused-vars
  return queryString;
};

// eslint-disable-next-line default-param-last
export const appendQueryStringToRequestUrl = (context = EMPTY_OBJECT, queryString) => {
  const fragmentUrl = context.url;
  const fragmentUrlWithQueryString = `${fragmentUrl}?${queryString}`;
  context.url = fragmentUrlWithQueryString; // eslint-disable-line no-param-reassign
};
