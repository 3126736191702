import { Transforms, Element } from 'slate';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';

const getNormalizeNode = (editor, defaultNormalizeNode) => (entry) => {
  const [node, path] = entry;

  if (Element.isElement(node) && (node.type === 'td' || node.type === 'th')) {
    if (_isEmpty(node.children)) {
      Transforms.insertNodes(
        editor,
        {
          type: 'p',
          children: [{ text: '' }],
        },
        { at: path.concat(0) },
      );
      return;
    }
  }
  defaultNormalizeNode(entry);
};

const withNormalizedTableCellNode = () => (editor) => {
  const { normalizeNode } = editor;

  _set(editor, 'normalizeNode', getNormalizeNode(editor, normalizeNode));

  return editor;
};

export default withNormalizedTableCellNode;
