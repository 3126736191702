import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import TableManager from '@tekion/tekion-components/organisms/TableManager';
import Heading from '@tekion/tekion-components/atoms/Heading';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_RESULTS_PER_PAGE } from '@tekion/tekion-components/molecules/table/constants/table.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';

import withSize from '../../../../connectors/withSize';
import { COLUMN_CONFIG, TABLE_MANAGER_PROPS } from './workspaceList.constants';
import { getTableProps } from './workspaceList.helpers';
import ACTION_HANDLERS from './workspaceList.actionHandlers';

const WorkspaceList = ({ loading, searchText, currentPage, totalCount, pageSize, contentHeight, filteredWorkspaceList, onAction }) => (
  <Page className="full-height full-width">
    <Page.Header>
      <Heading>{__('Workspaces')}</Heading>
    </Page.Header>
    <Page.Body style={{ height: contentHeight }}>
      <TableManager
        loading={loading}
        columns={COLUMN_CONFIG}
        tableManagerProps={TABLE_MANAGER_PROPS}
        pageSize={pageSize}
        currentPage={currentPage}
        searchText={searchText}
        tableProps={getTableProps(pageSize, loading, currentPage, totalCount)}
        data={filteredWorkspaceList}
        onAction={onAction}
      />
    </Page.Body>
  </Page>
);

WorkspaceList.propTypes = {
  loading: PropTypes.bool,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  contentHeight: PropTypes.number.isRequired,
  searchText: PropTypes.string,
  filteredWorkspaceList: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

WorkspaceList.defaultProps = {
  loading: false,
  searchText: EMPTY_STRING,
  pageSize: DEFAULT_RESULTS_PER_PAGE,
  totalCount: 0,
  currentPage: 0,
  filteredWorkspaceList: EMPTY_ARRAY,
};

export default compose(withSize({ hasPageFooter: 0, hasPageHeader: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(WorkspaceList);
