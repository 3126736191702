const NOTIFICATION_GROUP_FIELD_IDS = {
  COMMUNICATION: 'communication',
  UNREAD_COUNT: 'unreadCount',
  COUNT: 'count',
};

const NOTIFICATION_FIELD_IDS = {
  CREATED_TIME: 'createdTime',
  TENANT_ID: 'tenantId',
  WORKSPACE_ID: 'workspaceId',
  USER_ID: 'userId',
  NOTIFICATION_ID: 'notificationId',
  SENDER_ID: 'senderId',
  RECEIVER_ID: 'receiverId',
  EVENT_TYPE: 'eventType',
  TYPE: 'type', // ALERT or NUDGE
  GROUP_KEY: 'groupKey',
  LEVEL: 'level',
  BODY: 'body',
  SUBJECT: 'subject',
  STATUS: 'status', // NEW, SEEN, READ, DELETED

  // CUSTOM_ENTITY event type fields:
  ENTITY_NAME: 'entityName',
  RECORD_ID: 'recordId',
  APP_NAMES: 'appNames',

  // Following are what we will receive from Pusher Channel, they will change later.
  EVENT_TYPE_ID: 'eventTypeId', // vs eventTypeName
  NOTIFICATION_TYPE: 'notificationType', // ALERT or NUDGE, vs type
};

const NOTIFICATION_TYPES = {
  ALERT: 'ALERT',
  NUDGE: 'NUDGE',
};

const NOTIFICATION_DELIVERY_TYPES = {
  PUSHER: 'PUSHER',
};

const INTERNAL_NOTIFICATION_GROUP = 'INTERNAL';

const PUSH_NOTIFICATION_EVENT_TYPES = {
  CUSTOM_ENTITY: 'CUSTOM_ENTITY',
};

// Not sure if will be used by us
const NOTIFICATION_LEVELS = {
  GLOBAL: 'GLOBAL',
  TENANT: 'TENANT',
  WORKSPACE: 'WORKSPACE',
  USER: 'USER',
  USER_GROUP: 'USER_GROUP',
};

const NOTIFICATION_STATUS_TYPES = {
  NEW: 'NEW',
  SEEN: 'SEEN',
  READ: 'READ',
  DELETED: 'DELETED',
};

const STATUS_UPDATE_REQUEST_TYPES = {
  GROUP: 'GROUP',
  NOTIFICATION_ID: 'NOTIFICATION_ID',
  NOTIFICATION_TYPE: 'NOTIFICATION_TYPE',
};

export {
  NOTIFICATION_GROUP_FIELD_IDS,
  NOTIFICATION_FIELD_IDS,
  NOTIFICATION_TYPES,
  NOTIFICATION_DELIVERY_TYPES,
  PUSH_NOTIFICATION_EVENT_TYPES,
  NOTIFICATION_LEVELS,
  NOTIFICATION_STATUS_TYPES,
  STATUS_UPDATE_REQUEST_TYPES,
  INTERNAL_NOTIFICATION_GROUP,
};
