exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".tileButton_button__k6vZakF6up{display:flex;align-items:center;width:30rem;height:9rem;font-weight:100;justify-content:flex-start}[dir=ltr] .tileButton_button__k6vZakF6up{margin-left:2rem;margin-right:2rem}[dir=rtl] .tileButton_button__k6vZakF6up{margin-right:2rem;margin-left:2rem}.tileButton_tileHeaderName__dngt8tanD6{font-weight:bold;font-size:2rem}.tileButton_tileName__6nfwkqd4xE{font-weight:600;width:8rem;font-size:1.5rem}[dir=ltr] .tileButton_tileName__6nfwkqd4xE{padding-left:8px}[dir=rtl] .tileButton_tileName__6nfwkqd4xE{padding-right:8px}.tileButton_tile__vqQotBGkrA{position:relative;width:4rem !important;height:4rem !important;box-sizing:border-box !important;display:inline-block !important;white-space:nowrap;color:#161616}[dir] .tileButton_tile__vqQotBGkrA{border-radius:.1rem;background-color:#5c6470;box-shadow:0 .1rem 0 #00bfa5}.tileButton_tileWrapper__vXDdYqHX1M{height:100%;display:flex;flex-flow:row nowrap;justify-content:center;align-items:center;box-sizing:border-box}[dir] .tileButton_tileWrapper__vXDdYqHX1M{background-color:#b3b3b3}.tileButton_tileWrapperHeader__jpFxTxkNDp{height:100%;display:flex;flex-flow:row nowrap;justify-content:center;align-items:center;box-sizing:border-box}[dir] .tileButton_tileWrapperHeader__jpFxTxkNDp{background-color:#686868}.tileButton_text__38K5fxXssD{font-size:1.4rem;font-family:\"Proxima-Nova-Semibold\";letter-spacing:.03rem;line-height:1.7rem;color:#161616;color:#fff}", ""]);

// Exports
exports.locals = {
	"button": "tileButton_button__k6vZakF6up",
	"tileHeaderName": "tileButton_tileHeaderName__dngt8tanD6",
	"tileName": "tileButton_tileName__6nfwkqd4xE",
	"tile": "tileButton_tile__vqQotBGkrA",
	"tileWrapper": "tileButton_tileWrapper__vXDdYqHX1M",
	"tileWrapperHeader": "tileButton_tileWrapperHeader__jpFxTxkNDp",
	"text": "tileButton_text__38K5fxXssD"
};