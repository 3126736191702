import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import { tget } from '@tekion/tekion-base/utils/general';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import recordManagementServices from '../services/record.services';

const fetchEntityRecordById = async (entityName, entityRecordId) => {
  try {
    const response = await recordManagementServices.fetchEntityRecordById(entityName, entityRecordId);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch record data, please try again later.')));
    return false;
  }
};

const fetchEntityRecords = async (entityName, payload = EMPTY_OBJECT) => {
  try {
    const response = await recordManagementServices.fetchEntityRecords(entityName, payload);
    const data = getDataFromResponse(response);
    const hits = tget(data, 'hits', EMPTY_ARRAY);
    const nextPageToken = _get(data, 'nextPageToken');
    const count = _get(data, 'count');

    return { hits, nextPageToken, count };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch records, please try again later.')));
    return false;
  }
};

const fetchSimilarEntityRecords = async (entityName, recordId, payload = EMPTY_OBJECT) => {
  try {
    const response = await recordManagementServices.fetchSimilarEntityRecords(entityName, recordId, payload);
    const data = getDataFromResponse(response);
    const hits = tget(data, 'hits', EMPTY_ARRAY);
    const nextPageToken = _get(data, 'nextPageToken');

    return { hits, nextPageToken };
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch related records, please try again later.')));
    return false;
  }
};

const createEntityRecord = async (entityName, payload) => {
  try {
    const receivedData = await recordManagementServices.createEntityRecord(entityName, payload);
    const data = getDataFromResponse(receivedData);

    if (!_isEmpty(_get(data, 'referenceId'))) {
      toaster(TOASTER_TYPE.SUCCESS, __('Request successfully sent for approval.'));
    } else {
      toaster(TOASTER_TYPE.SUCCESS, __('Record created successfully'));
    }

    return data;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create record, please try again later.')));
    return false;
  }
};

const updateEntityRecord = async (entityName, entityRecordId, payload) => {
  try {
    const receivedData = await recordManagementServices.updateEntityRecord(entityName, entityRecordId, payload);
    const data = getDataFromResponse(receivedData);

    if (!_isEmpty(_get(data, 'referenceId'))) {
      toaster(TOASTER_TYPE.SUCCESS, __('Request successfully sent for approval.'));
    } else {
      toaster(TOASTER_TYPE.SUCCESS, __('Record updated successfully'));
    }

    return data;
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update record, please try again later.')));
    return false;
  }
};

const getDerivedRecordType = async (entityName, payload) => {
  try {
    const response = await recordManagementServices.getDerivedRecordType(entityName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch derived record type')));
    return EMPTY_STRING;
  }
};

export { fetchEntityRecordById, fetchEntityRecords, createEntityRecord, updateEntityRecord, getDerivedRecordType, fetchSimilarEntityRecords };
