import FILTER_TYPES from '@tekion/tekion-components/organisms/filterSection/constants/filterSection.filterTypes';
// Tekion-components
import Button from '@tekion/tekion-components/atoms/Button';
import { DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';

import COLUMNS_IDS from './visualBuilderList.columnIds';
import ACTION_TYPES from './visualBuilderList.actionTypes';
import { PAGE_TYPES } from '../../../../../constants/visualBuilder';

const INITIAL_STATE_VISUAL_BUILDER_LIST_PROPS = {
  currentPage: DEFAULT_PAGE_INFO.start,
  pageSize: DEFAULT_PAGE_INFO.rows,
};

const SUB_HEADER_PROPS = {
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Create New'),
        view: Button.VIEW.PRIMARY,
      },
      action: ACTION_TYPES.CREATE_VISUAL_BUILDER,
    },
  ],
};

const TABLE_MANAGER_PROPS = {
  showFilter: true,
  showHeader: false,
  showSearch: true,
  showSubHeader: true,
  showRefreshIcon: true,
};

const FILTER_ID = {
  PAGE_TYPE_ID: 'pageType',
};

const PAGE_TYPE_FILTER = {
  id: FILTER_ID.PAGE_TYPE_ID,
  key: COLUMNS_IDS.PAGE_TYPE,
  type: FILTER_TYPES.SINGLE_SELECT,
  name: __('Page Type'),
};

const PAGE_TYPES_OPTIONS = {
  HOME_PAGE: {
    label: __('Home Page'),
    value: PAGE_TYPES.HOME_PAGE,
  },
  RECORD_PAGE: {
    label: __('Record Page'),
    value: PAGE_TYPES.RECORD_DETAIL_PAGE,
  },
};

const DEFAULT_FILTERS = [PAGE_TYPE_FILTER.id];
const DEFAULT_FILTER_GROUP = 'DEFAULT_FILTER_GROUP';

const CLONE_PAGE_MODAL_FORM_CONTEXT_ID = 'CLONE_PAGE_MODAL_FORM';

export {
  INITIAL_STATE_VISUAL_BUILDER_LIST_PROPS,
  SUB_HEADER_PROPS,
  TABLE_MANAGER_PROPS,
  PAGE_TYPE_FILTER,
  DEFAULT_FILTERS,
  DEFAULT_FILTER_GROUP,
  PAGE_TYPES_OPTIONS,
  CLONE_PAGE_MODAL_FORM_CONTEXT_ID,
};
