import _find from 'lodash/find';
import _head from 'lodash/head';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';

import { DEFAULT_TIME_VALUE, TIME_MAPPER, TIME_UNIT_OPTIONS } from '../constants/workflow.constants';

const getDefaultUnitByValue = (type) => {
  const unit = _find(TIME_UNIT_OPTIONS, (time) => time.value === type);
  return _isEmpty(unit) ? DEFAULT_TIME_VALUE : { ...DEFAULT_TIME_VALUE, unit: unit.value };
};

const convertMsToTimeInputFormat = (inputValue, convertTo) =>
  _reduce(
    TIME_MAPPER,
    (acc, val, key) => {
      if (!_isEmpty(convertTo) && !_find(convertTo, (i) => i === key)) {
        return acc;
      }
      if (!_isNumber(inputValue)) return getDefaultUnitByValue(_head(convertTo));
      if (inputValue < val || (inputValue / val) % 1 !== 0) {
        return acc;
      }
      return {
        value: inputValue / val,
        unit: key,
      };
    },

    getDefaultUnitByValue(_head(convertTo)),
  );

const convertTimeInputToMs = (input, unit) => {
  if (!_isNumber(input)) return 0;
  return input * TIME_MAPPER[unit];
};

export { convertMsToTimeInputFormat, convertTimeInputToMs };
