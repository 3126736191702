import _isEqual from 'lodash/isEqual';
import _slice from 'lodash/slice';
import _size from 'lodash/size';
import { Range } from 'slate';

const getStartingPathToCompare = (editor) => {
  const startingPath = Range.start(editor.selection).path;
  return _slice(startingPath, 0, _size(startingPath) - 1);
};

const getEndingPathToCompare = (editor) => {
  const endingPath = Range.end(editor.selection).path;
  return _slice(endingPath, 0, _size(endingPath) - 1);
};

export const shouldEnableLink = (editor) => {
  if (editor?.selection && Range.isRange(editor?.selection)) {
    const startingPathToCompare = getStartingPathToCompare(editor);
    const endingPathToCompare = getEndingPathToCompare(editor);
    if (_isEqual(startingPathToCompare, endingPathToCompare)) {
      return true;
    }
  }
  return false;
};
