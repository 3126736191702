import _set from 'lodash/set';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import getNodeDisplayName from './getNodeDisplayName';

const setNodeLabel = (node) => {
  const label = getNodeDisplayName(node);
  _set(node, 'label', label);
  _set(node, 'displayName', label);
};

const generateNode = (nodeData, formValues) => ({
  ...nodeData,
  userData: {
    data: formValues,
  },
  uiMetadata: {
    ...tget(nodeData, 'uiMetadata', EMPTY_OBJECT),
    fields: {},
    taskType: tget(nodeData, 'taskType', tget(nodeData, 'uiMetadata.taskType')),
    optionsByConditionTypes: {},
    fieldOptions: {},
    selectedOptionsCache: {},
    skeleton: {},
  },
});

export { setNodeLabel, generateNode };
