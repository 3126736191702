import produce from 'immer';

import _set from 'lodash/set';
import _get from 'lodash/get';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { SHARE_AND_MANAGE_RECORD_USER_DATA_KEYS } from './shareAndManageRecordUserData.constants';
import { PERMISSION_ACCESS_IDS } from '../../../../../constants/permissionSet.constants';
import ShareAndManageRecordReader from '../../../../../readers/shareAndManageRecord.reader';

const { BASE_PATH } = SHARE_AND_MANAGE_RECORD_USER_DATA_KEYS;

const setRecordAccessType = (shareAndManagerRecordUserDetail, newValue) => {
  const updatedData = produce(shareAndManagerRecordUserDetail, (draft) => {
    _set(draft, [BASE_PATH, SHARE_AND_MANAGE_RECORD_USER_DATA_KEYS.RECORD_ACCESS_TYPE], newValue);
  });

  return updatedData;
};

const setExpiryTime = (shareAndManageRecordUserDetail, newValue) => {
  const updatedData = produce(shareAndManageRecordUserDetail, (draft) => {
    _set(draft, [BASE_PATH, SHARE_AND_MANAGE_RECORD_USER_DATA_KEYS.EXPIRY_TIME], newValue);
  });

  return updatedData;
};

const getNecessaryManageAccessDataForUser = (manageAccessObject) => ({
  [SHARE_AND_MANAGE_RECORD_USER_DATA_KEYS.RECORD_ACCESS_TYPE]: ShareAndManageRecordReader.recordAccessType(manageAccessObject),
  [SHARE_AND_MANAGE_RECORD_USER_DATA_KEYS.EXPIRY_TIME]: ShareAndManageRecordReader.expiryTime(manageAccessObject),
});

const getNecessaryEntityAccessDataForUser = (entityAccessObject) => ({
  [SHARE_AND_MANAGE_RECORD_USER_DATA_KEYS.ENTITY_ACCESS_TYPES]: _get(entityAccessObject, PERMISSION_ACCESS_IDS.ACTION_TYPES),
});

const createShareAndManageAccessRecordUserData = ({ userObject = EMPTY_OBJECT, manageAccessObject, entityAccessObject }) => {
  const necessaryManageAccessData = getNecessaryManageAccessDataForUser(manageAccessObject);
  const necessaryEntityAccessData = getNecessaryEntityAccessDataForUser(entityAccessObject);

  const combinedObject = {
    ...userObject,
    [SHARE_AND_MANAGE_RECORD_USER_DATA_KEYS.BASE_PATH]: {
      ...necessaryManageAccessData,
      ...necessaryEntityAccessData,
    },
  };

  return combinedObject;
};

const SHARE_AND_MANAGE_RECORD_USER_SETTERS = {
  setRecordAccessType,
  setExpiryTime,
  createShareAndManageAccessRecordUserData,
};

export default SHARE_AND_MANAGE_RECORD_USER_SETTERS;
