import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import { ACTION_TYPES } from './constants/fieldList.actionTypes';
import { STUDIO_ROUTE } from '../../../../constants/routes';
import PAGE_IDS from '../../constants/PageIds.constants';

import { fetchEntityDefByName } from '../../../../actions/entityManagement.actions';

const handleInit = async ({ setState, getState }) => {
  const { match = EMPTY_OBJECT } = getState();
  const { entityName } = _get(match, 'params', EMPTY_OBJECT);

  setState({ fieldDataLoading: true });

  const entity = await fetchEntityDefByName(entityName);

  if (!_isEmpty(entityName) && !_isEmpty(entity)) {
    const fieldsData = _get(entity, 'fieldDefinitions', EMPTY_ARRAY);
    const pageSize = _size(fieldsData);

    setState({ entity, fieldsData, fieldDataLoading: false, pageSize });
  }
};

const handleRedirectToCreate = ({ getState }) => {
  const { entity, history, match } = getState();
  const entityName = _get(match, 'params.entityName');

  if (!_isEmpty(entityName)) {
    const path = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELD_CREATE}`;
    history.push({ pathname: path, state: { entity } });
  }
};

const handleEditField = ({ getState, params }) => {
  const { history, match, entity } = getState();
  let newParams = params;
  if (!_has(newParams, 'name')) {
    newParams = _get(newParams, 'value.original', EMPTY_OBJECT);
  }
  const fieldName = _get(newParams, 'name', EMPTY_STRING);
  const entityName = _get(match, 'params.entityName');

  if (!_isEmpty(entityName) && !_isEmpty(fieldName)) {
    const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELD_EDIT}/${fieldName}/v2`;
    history.push({ pathname, state: { fieldData: newParams, entity } });
  }
};

const handleRedirectToCreateNewForm = ({ getState }) => {
  const { entity, history, match } = getState();
  const entityName = _get(match, 'params.entityName');

  if (!_isEmpty(entityName)) {
    const path = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELD_CREATE}/v2`;
    history.push({ pathname: path, state: { entity } });
  }
};

const handleEditNewField = ({ getState, params }) => {
  const { history, match, entity } = getState();
  let newParams = params;
  if (!_has(newParams, 'name')) {
    newParams = _get(newParams, 'value.original', EMPTY_OBJECT);
  }
  const fieldName = _get(newParams, 'name', EMPTY_STRING);
  const entityName = _get(match, 'params.entityName');

  if (!_isEmpty(entityName) && !_isEmpty(fieldName)) {
    const pathname = `${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.FIELD_EDIT}/${fieldName}/v2`;
    history.push({ pathname, state: { fieldData: newParams, entity } });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT_DATA]: handleInit,
  [ACTION_TYPES.REDIRECT_TO_FIELD_CREATION]: handleRedirectToCreate,
  [ACTION_TYPES.EDIT_FIELD_DETAIL]: handleEditField,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleEditField,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleInit,
  [ACTION_TYPES.REDIRECT_TO_NEW_FIELD_CREATION]: handleRedirectToCreateNewForm,
  [ACTION_TYPES.EDIT_NEW_FIELD_DETAIL]: handleEditNewField,
};

export default ACTION_HANDLERS;
