import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { downloadURI } from '@tekion/tekion-components/utils/downloadFile';
import Carousel from '@tekion/tekion-components/molecules/Gallery/Carousel';
import GalleryReader from '@tekion/tekion-components/molecules/Gallery/Gallery.reader';

import s from './mediaPreviewGallery.module.scss';

class MediaPreviewGallery extends PureComponent {
  constructor(props) {
    super(props);
    const { index } = this.props;
    this.state = {
      index,
      zoomScale: 0,
    };
  }

  getCurrentMedia = () => {
    const { index } = this.state;
    const { mediaList } = this.props;
    return GalleryReader.getMediaAtIndex(mediaList, index);
  };

  showNextMedia = () => {
    const { index } = this.state;
    const { mediaList } = this.props;
    this.showMediaAtIndex(GalleryReader.getNextMediaIndex(mediaList, index));
  };

  showPreviousMedia = () => {
    const { index } = this.state;
    const { mediaList } = this.props;
    this.showMediaAtIndex(GalleryReader.getPreviousMediaIndex(mediaList, index));
  };

  onClose = () => {
    const { closeMediaPreview } = this.props;
    closeMediaPreview();
  };

  onZoomIn = () => {
    const { zoomScale } = this.state;
    this.setState({ zoomScale: GalleryReader.getZoomScale(zoomScale) });
  };

  onZoomOut = () => {
    const { zoomScale } = this.state;
    const zoomOut = GalleryReader.getZoomScale(zoomScale, false);
    if (zoomOut < 0) {
      this.setState({ zoomScale: 0 });
      return;
    }
    this.setState({ zoomScale: GalleryReader.getZoomScale(zoomScale, false) });
  };

  showMediaAtIndex = (index) => {
    this.setState({
      index,
      zoomScale: 0,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  handleDownLoadMedia = (media) => () => {
    const url = _get(media, 'url', EMPTY_STRING);
    const fileName = _get(media, 'fileName', EMPTY_STRING);
    downloadURI(url, fileName);
  };

  render() {
    const { index, zoomScale } = this.state;
    const { mediaList, showCarouselArrows, showCountDisplay, isSecureMedia, isDownLoadIcon } = this.props;
    const mediaCount = GalleryReader.getTotalMediaCount(mediaList);
    const currentMedia = this.getCurrentMedia();

    return (
      <div className={s.container}>
        <Carousel
          label={__('Images')}
          index={index}
          totalCount={mediaCount}
          media={currentMedia}
          onNextClick={this.showNextMedia}
          onPrevClick={this.showPreviousMedia}
          onZoomOut={this.onZoomOut}
          onZoomIn={this.onZoomIn}
          zoomScale={zoomScale}
          showCloseCarouselButton
          showCarouselArrows={showCarouselArrows}
          showCountDisplay={showCountDisplay}
          onCloseCarousel={this.onClose}
          isSecureMedia={isSecureMedia}
          isDownLoadIcon={isDownLoadIcon}
          downloadMedia={this.handleDownLoadMedia(currentMedia)}
        />
      </div>
    );
  }
}

MediaPreviewGallery.propTypes = {
  mediaList: PropTypes.array,
  index: PropTypes.number,
  closeMediaPreview: PropTypes.func,
  showCarouselArrows: PropTypes.bool,
  showCountDisplay: PropTypes.bool,
  isSecureMedia: PropTypes.bool,
  isDownLoadIcon: PropTypes.bool,
};
MediaPreviewGallery.defaultProps = {
  mediaList: EMPTY_ARRAY,
  index: 0,
  closeMediaPreview: _noop,
  showCarouselArrows: true,
  showCountDisplay: true,
  isSecureMedia: false,
  isDownLoadIcon: false,
};

export default MediaPreviewGallery;
