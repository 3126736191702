import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { defaultMemoize } from 'reselect';

import _get from 'lodash/get';
import _map from 'lodash/map';

import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Button from '@tekion/tekion-components/atoms/Button';
import Heading from '@tekion/tekion-components/atoms/Heading';
import Menu from '@tekion/tekion-components/molecules/Menu';
import withActions from '@tekion/tekion-components/connectors/withActions';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import WithSize from '../../../../connectors/withSize';

import LeftPanel from './components/leftPanel';
import RightPanel from './components/rightPanel';
import EditDashboardModal from './components/editDashboardModal/EditDashboardModal';
import AddWidget from './components/addWidget/AddWidget';
import ACTION_HANDLERS from './helpers/createDashboard.actionHandlers';
import ACTION_TYPES from './constants/createDashboard.actionTypes';
import { FORM_MODES } from '../../../../constants/general.constants';
import { WIDGET_TYPES } from '../../../../organisms/reporting/molecules/widgetContainer/constants/widgetContainer.general.constants';

// styles
import styles from './createDashboard.module.scss';

const CreateDashboard = (props) => {
  const {
    onAction,
    data,
    loading,
    pageSize,
    currentPage,
    leftPanelLoading,
    isPrimaryDisabled,
    contentHeight,
    widgetItems,
    layoutsByName,
    isAddWidgetModalVisible,
    isEditDashboardModalVisible,
    widgetType,
    widgetToEdit,
    formValues,
    mode,
    updatedWidget,
  } = props;

  const handleSaveClick = useCallback(
    () =>
      onAction({
        type: ACTION_TYPES.CREATE_DASHBOARD,
      }),
    [onAction],
  );

  const handleCancelClick = useCallback(
    () =>
      onAction({
        type: ACTION_TYPES.CANCEL_DASHBOARD_CREATE,
      }),
    [onAction],
  );

  const handleModalClose = useCallback(
    (_, params = EMPTY_OBJECT) => {
      onAction({ type: ACTION_TYPES.CLOSE_MODAL, payload: params });
    },
    [onAction],
  );

  const handleWidgetEdit = useCallback(
    (params) => {
      onAction({ type: ACTION_TYPES.EDIT_WIDGET, payload: params });
    },
    [onAction],
  );

  const handleWidgetRemove = useCallback(
    (params) => {
      onAction({ type: ACTION_TYPES.REMOVE_WIDGET, payload: params });
    },
    [onAction],
  );

  const handleLayoutUpdate = useCallback(
    (value) => {
      onAction({
        type: ACTION_TYPES.UPDATE_GRID_LAYOUT,
        payload: {
          value,
        },
      });
    },
    [onAction],
  );

  const handleEditDashboardCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.CLOSE_EDIT_DASHBOARD_MODAL });
  }, [onAction]);

  const handleEditDashboardSave = useCallback(() => {
    onAction({ type: ACTION_TYPES.SAVE_EDIT_DASHBOARD_MODAL });
  }, [onAction]);

  const handleDashboardEdit = useCallback(() => {
    onAction({ type: ACTION_TYPES.EDIT_DASHBOARD });
  }, [onAction]);

  const getSaveComponent = useCallback(
    () => (
      <SaveComponent
        primaryButtonLabel={__('Save')}
        onPrimaryAction={handleSaveClick}
        isPrimaryDisabled={isPrimaryDisabled}
        onSecondaryAction={handleCancelClick}
      />
    ),
    [handleSaveClick, isPrimaryDisabled, handleCancelClick],
  );

  const getAddWidgetDropdownMenu = defaultMemoize((handleClick) => (
    <Menu onClick={handleClick}>
      {_map(WIDGET_TYPES, (value) => (
        <Menu.Item key={value}>{value}</Menu.Item>
      ))}
    </Menu>
  ));

  const handleCreateWidget = useCallback(
    (type) => {
      const { key } = type;
      onAction({ type: ACTION_TYPES.CREATE_WIDGET, payload: key });
    },
    [onAction],
  );

  useEffect(() => {
    onAction({ type: ACTION_TYPES.INIT });
    if (mode === FORM_MODES.EDIT) {
      onAction({ type: ACTION_TYPES.INIT_DASHBOARD });
    }
  }, [mode, onAction]);

  return (
    <Page className="full-height full-width">
      <Page.Header hasBack contentClassName={styles.headerContainer} className={styles.headerContainer}>
        <div className={styles.editContainer}>
          <Heading className="flex align-items-center">
            <span> {mode === FORM_MODES.EDIT ? __('Edit Dashboard') : __('Create Dashboard')}</span>
          </Heading>
          <div>
            <span className={styles.dashboardName}> {_get(formValues, 'name', '')} </span>
            <Button onClick={handleDashboardEdit} className={styles.editButton} view="icon" icon="icon-edit" />
          </div>
        </div>
      </Page.Header>
      <Page.Body style={{ height: contentHeight }}>
        <div className={styles.bodyContainer}>
          <LeftPanel
            onAction={onAction}
            widgetItems={widgetItems}
            leftPanelLoading={leftPanelLoading}
            layoutsByName={layoutsByName}
            handleWidgetEdit={handleWidgetEdit}
            handleWidgetRemove={handleWidgetRemove}
            handleLayoutUpdate={handleLayoutUpdate}
            addWidgetDropdownMenu={getAddWidgetDropdownMenu(handleCreateWidget)}
            updatedWidget={updatedWidget}
          />
          <RightPanel
            onAction={onAction}
            data={data}
            loading={loading}
            pageSize={pageSize}
            currentPage={currentPage}
            addWidgetDropdownMenu={getAddWidgetDropdownMenu(handleCreateWidget)}
          />
          <PropertyControlledComponent controllerProperty={isAddWidgetModalVisible}>
            <AddWidget modalVisible={isAddWidgetModalVisible} widgetType={widgetType} widgetName={widgetToEdit} handleCancel={handleModalClose} />
          </PropertyControlledComponent>

          <PropertyControlledComponent controllerProperty={isEditDashboardModalVisible}>
            <EditDashboardModal
              modalVisible={isEditDashboardModalVisible}
              onAction={onAction}
              handleCancel={handleEditDashboardCancel}
              handleSubmit={handleEditDashboardSave}
              formValues={formValues}
            />
          </PropertyControlledComponent>
        </div>
      </Page.Body>
      <Page.Footer>{getSaveComponent()}</Page.Footer>
    </Page>
  );
};

CreateDashboard.propTypes = {
  isAddWidgetModalVisible: PropTypes.bool,
  isEditDashboardModalVisible: PropTypes.bool,
  loading: PropTypes.bool,
  leftPanelLoading: PropTypes.bool,
  isPrimaryDisabled: PropTypes.bool,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  contentHeight: PropTypes.number.isRequired,
  widgetItems: PropTypes.array,
  layoutsByName: PropTypes.object,
  data: PropTypes.array,
  widgetType: PropTypes.string,
  widgetToEdit: PropTypes.string,
  formValues: PropTypes.object,
  mode: PropTypes.string,
  updatedWidget: PropTypes.string,
  onAction: PropTypes.func.isRequired,
};

CreateDashboard.defaultProps = {
  leftPanelLoading: true,
  isPrimaryDisabled: false,
  isAddWidgetModalVisible: false,
  isEditDashboardModalVisible: false,
  loading: false,
  pageSize: 50,
  data: EMPTY_ARRAY,
  currentPage: 0,
  widgetItems: EMPTY_ARRAY,
  layoutsByName: EMPTY_OBJECT,
  widgetType: WIDGET_TYPES.DATA_TABLE,
  widgetToEdit: EMPTY_STRING,
  formValues: EMPTY_OBJECT,
  mode: FORM_MODES.CREATE,
  updatedWidget: EMPTY_STRING,
};

export default compose(WithSize({ hasPageFooter: 1, hasPageHeader: 1 }), withActions(EMPTY_OBJECT, ACTION_HANDLERS))(CreateDashboard);
