import _map from 'lodash/map';
import _capitalize from 'lodash/capitalize';

const FILTER_CONDITION_OPTIONS = {
  ALL_RECORDS: 'ALL_RECORDS',
  ONLY_CERTAIN_RECORDS: 'ONLY_CERTAIN_RECORDS',
};

const FILTER_CRITERIA_FOR_CHILD_OPTIONS = [
  {
    label: __('Include all records'),
    value: FILTER_CONDITION_OPTIONS.ALL_RECORDS,
  },
  {
    label: __('Include only certain records '),
    value: FILTER_CONDITION_OPTIONS.ONLY_CERTAIN_RECORDS,
  },
];

const FILTER_CRITERIA_FOR_PARENT_OPTIONS = [
  {
    label: __('Apply to all records'),
    value: FILTER_CONDITION_OPTIONS.ALL_RECORDS,
  },
  {
    label: __('Apply to only certain records '),
    value: FILTER_CONDITION_OPTIONS.ONLY_CERTAIN_RECORDS,
  },
];

const ROLL_UP_FUNCTIONS = {
  SUM: 'SUM',
  AVG: 'AVG',
  MIN: 'MIN',
  MAX: 'MAX',
  COUNT: 'COUNT',
};

const createRadioOption = (option) => ({
  label: _capitalize(option),
  value: option,
});

const ROLL_UP_OPTIONS = _map(ROLL_UP_FUNCTIONS, createRadioOption);

export { FILTER_CRITERIA_FOR_PARENT_OPTIONS, ROLL_UP_OPTIONS, ROLL_UP_FUNCTIONS, FILTER_CRITERIA_FOR_CHILD_OPTIONS, FILTER_CONDITION_OPTIONS };
