import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _set from 'lodash/set';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import Heading from '@tekion/tekion-components/atoms/Heading';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import StatusRenderer from '@tekion/tekion-components/molecules/CellRenderers/statusRenderer';
import Collapse from '@tekion/tekion-components/molecules/Collapse';

import FieldValuePair from './components/fieldValuePair/FieldValuePair';
import CustomEntityRequest from './components/customEntityRequest/CustomEntityRequest';
import SettingRequest from './components/settingRequest/SettingRequest';
import RequestedEntityRecordChanges from './components/requestedEntityRecordChanges/RequestedEntityRecordChanges';

import { getMasterEntityDef, getMasterEntityRecord } from '../../../../../../../actions/entityManagement.actions';

import { compareNewRequestToPrevEntityRecordValue, getEntityRecordId, resolvedRequestedEntityRecord } from './helpers/approvalRequestDetails.helpers';

import {
  APPROVAL_CENTRE_FIELD_IDS,
  CUSTOM_ENTITY_CATEGORY,
  APPROVAL_STATUS_LABELS,
  APPROVAL_STATUS_COLOR_MAPS,
  APPROVAL_STATUS_TYPES,
  CUSTOM_ENTITY_REQUEST_FIELD_IDS,
  CUSTOM_ENTITY_ACTION_TYPES,
  APPROVAL_EXECUTION_DETAIL_FIELD_IDS,
} from '../../../../../../../constants/approvalCentre.constants';
import { REQUEST_DETAILS_PAGE_PANEL_KEYS } from '../../constants/approvalRequestDetailsPage.general.constants';
import { TAB_IDS } from '../../../approvalManagement/constants/approvalManagement.constants';
import RECORD_FIELDS from '../../../../../../../constants/recordFields';
import ENTITY_DEFINITION_FIELDS from '../../../../../../../constants/entityDefinitionFields';

import entityDefinitionReader from '../../../../../../../readers/entity.reader';

import styles from './approvalRequestDetails.module.scss';

const ApprovalRequestDetails = ({
  isMountedInsideApplication,
  className,
  approvalTabId,
  approvalRequest,
  approvalSetting,
  entityDef,
  applicationProperties,
}) => {
  const [entityRecord, setEntityRecord] = useState({});
  const [entityFieldDefByName, setEntityFieldDefByName] = useState({});
  const [isEntityRecordResolving, setIsEntityRecordResolving] = useState(false);
  const [executionFailureReason, setExecutionFailureReason] = useState('');

  const name = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.NAME, NO_DATA);
  const group = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.GROUP, NO_DATA);
  const category = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.CATEGORY, NO_DATA);
  const status = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.STATUS, NO_DATA);
  const validity = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.VALIDITY, NO_DATA);
  const recordRequestData = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.DATA, {});
  const actionType = tget(recordRequestData, CUSTOM_ENTITY_REQUEST_FIELD_IDS.ACTION_TYPE);

  const isRequestedEntityRecordChangesSectionShowing = useMemo(
    () =>
      status !== APPROVAL_STATUS_TYPES.COMPLETED &&
      category === CUSTOM_ENTITY_CATEGORY &&
      actionType === CUSTOM_ENTITY_ACTION_TYPES.UPDATE_CUSTOM_ENTITY,
    [actionType, category, status],
  );

  const resolvedRequestedDetails = useCallback(async () => {
    const entityName = entityDefinitionReader.name(entityDef);
    const masterEntityDef = await getMasterEntityDef(entityName);
    const fieldDefByName = _keyBy(entityDefinitionReader.fieldDefinitions(masterEntityDef), ENTITY_DEFINITION_FIELDS.NAME);
    setEntityFieldDefByName(fieldDefByName);

    const entityRecordId = getEntityRecordId(approvalRequest);
    const requestEntityRecord = tget(recordRequestData, CUSTOM_ENTITY_REQUEST_FIELD_IDS.CUSTOM_ENTITY_REQUEST, {});
    const resolvedRequestedData = {};

    if (!_isEmpty(entityRecordId)) {
      const _entityRecord = await getMasterEntityRecord(entityRecordId, RECORD_FIELDS.ID, masterEntityDef);
      setEntityRecord(_entityRecord);

      if (isRequestedEntityRecordChangesSectionShowing) {
        compareNewRequestToPrevEntityRecordValue(_entityRecord, requestEntityRecord, fieldDefByName, resolvedRequestedData);
      }
    } else {
      const approvalExecutionDetail = tget(approvalRequest, APPROVAL_CENTRE_FIELD_IDS.APPROVAL_EXECUTION_DETAIL, EMPTY_OBJECT);

      if (!_isEmpty(approvalExecutionDetail)) {
        const _executionFailureReason = tget(approvalExecutionDetail, APPROVAL_EXECUTION_DETAIL_FIELD_IDS.EXECUTION_FAILURE_REASON, EMPTY_STRING);
        setExecutionFailureReason(_executionFailureReason);
      }
    }

    await resolvedRequestedEntityRecord(requestEntityRecord, fieldDefByName, resolvedRequestedData);

    _set(approvalRequest, [APPROVAL_CENTRE_FIELD_IDS.DATA, CUSTOM_ENTITY_REQUEST_FIELD_IDS.CUSTOM_ENTITY_REQUEST], resolvedRequestedData);
  }, [approvalRequest, entityDef, isRequestedEntityRecordChangesSectionShowing, recordRequestData]);

  useEffect(() => {
    if (category === CUSTOM_ENTITY_CATEGORY) {
      setIsEntityRecordResolving(true);
      resolvedRequestedDetails();
      setIsEntityRecordResolving(false);
    }
  }, [category, resolvedRequestedDetails]);

  return (
    <div className={className}>
      <Collapse
        collapseHeaderClassName={styles.panelHeader}
        bodyClassName={styles.collapseBody}
        defaultActiveKey={approvalTabId === TAB_IDS.APPROVALS ? [REQUEST_DETAILS_PAGE_PANEL_KEYS.REQUEST_DETAILS] : []}
      >
        <Collapse.Panel
          panelKey={REQUEST_DETAILS_PAGE_PANEL_KEYS.REQUEST_DETAILS}
          header={
            <Heading className="p-l-4" size={3}>
              {__('Request Details')}
            </Heading>
          }
        >
          <div className={styles.container}>
            <div className={styles.row}>
              <FieldValuePair label={__('Request Name')} value={name} />
              <FieldValuePair label={__('Group')} value={group} />
              <FieldValuePair label={__('Category')} value={category} />
            </div>
            {isRequestedEntityRecordChangesSectionShowing && (
              <RequestedEntityRecordChanges
                isEntityRecordResolving={isEntityRecordResolving}
                approvalRequest={approvalRequest}
                entityRecord={entityRecord}
                entityFieldDefByName={entityFieldDefByName}
              />
            )}
          </div>

          <PropertyControlledComponent controllerProperty={category === CUSTOM_ENTITY_CATEGORY}>
            <div className={styles.container}>
              <CustomEntityRequest
                isMountedInsideApplication={isMountedInsideApplication}
                isEntityRecordResolving={isEntityRecordResolving}
                rowClassName={styles.row}
                approvalRequest={approvalRequest}
                entityRecord={entityRecord}
                entityDef={entityDef}
                applicationProperties={applicationProperties}
              />
            </div>
          </PropertyControlledComponent>
          <PropertyControlledComponent controllerProperty={category !== CUSTOM_ENTITY_CATEGORY}>
            <div className={styles.container}>
              <SettingRequest rowClassName={styles.row} approvalRequest={approvalRequest} approvalSetting={approvalSetting} />
            </div>
          </PropertyControlledComponent>
          <div className={cx(styles.row, styles.container)}>
            <FieldValuePair
              label={__('Status')}
              value={<StatusRenderer data={status} labelMap={APPROVAL_STATUS_LABELS} colorMap={APPROVAL_STATUS_COLOR_MAPS} />}
            />
            <FieldValuePair label={__('Valid till')} value={validity} />
          </div>
          <PropertyControlledComponent controllerProperty={!_isEmpty(executionFailureReason)}>
            <div className={styles.container}>
              <FieldValuePair label={__('Execution Failed')} value={executionFailureReason} />
            </div>
          </PropertyControlledComponent>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

ApprovalRequestDetails.propTypes = {
  isMountedInsideApplication: PropTypes.bool,
  className: PropTypes.string,
  approvalTabId: PropTypes.string,
  approvalRequest: PropTypes.object,
  approvalSetting: PropTypes.object,
  entityDef: PropTypes.object,
  applicationProperties: PropTypes.object,
};

ApprovalRequestDetails.defaultProps = {
  isMountedInsideApplication: false,
  className: EMPTY_STRING,
  approvalTabId: TAB_IDS.APPROVALS,
  approvalRequest: EMPTY_OBJECT,
  approvalSetting: EMPTY_OBJECT,
  entityDef: EMPTY_OBJECT,
  applicationProperties: EMPTY_OBJECT,
};

export default ApprovalRequestDetails;
