import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/reporting';

const getDashboard = (dashboardName) => ApiService.get(`${BASE_URL}/dashboards/name/${dashboardName}`);
const getAllDashboards = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/dashboards/search`, payload);

const getWidget = (widgetName) => ApiService.get(`${BASE_URL}/widgets/name/${widgetName}`);
const getAllWidgets = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/widgets/search`, payload);

const createNewWidget = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/widgets`, payload);

const updateWidget = (widgetName, payload = EMPTY_OBJECT) => ApiService.put(`${BASE_URL}/widgets/name/${widgetName}`, payload);

const getReport = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/report-data`, payload);

const createDashboard = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/dashboards`, payload);
const updateDashboard = (name, payload = EMPTY_OBJECT) => ApiService.put(`${BASE_URL}/dashboards/name/${name}`, payload);

const updateDashboardLayout = (name, payload = EMPTY_OBJECT) => ApiService.patch(`${BASE_URL}/dashboard/${name}`, payload);

const getMetaDataByEntityName = (entityName) => ApiService.get(`${BASE_URL}/metadata/${entityName}`);
const getMetaDataByFieldName = (entityName, fieldName) => ApiService.get(`${BASE_URL}/metadata/${entityName}/${fieldName}`);

export default {
  getDashboard,
  getAllWidgets,
  getWidget,
  getReport,
  createDashboard,
  updateDashboard,
  updateDashboardLayout,
  createNewWidget,
  updateWidget,
  getAllDashboards,
  getMetaDataByFieldName,
  getMetaDataByEntityName,
};
