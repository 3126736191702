import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isObject from 'lodash/isObject';
import _get from 'lodash/get';
import _set from 'lodash/set';

import { NO_DATA, EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchEntityRecords } from '../../actions/recordManagement.actions';

import { STANDARD_ENTITY_NAME } from '../../constants/general.constants';
import { MENTION_TYPES } from './asyncMentions.constants';

import workspaceUserReader from '../../readers/workSpaceUser.reader';

// defaultGetPayload, defaultServiceHandler and defaultGetOptions are written in this way that in future if we want to show user-groups, etc
// into our suggestions list then we just have to add the payload, service into these handlers and then put the options into the
// options list through defaultGetOptions function only, nothing more is required

const defaultGetPayload = (searchText, nextPageToken = EMPTY_OBJECT) => {
  const payload = {};

  let payloadForUsers = {
    rows: 7,
    nextPageToken: tget(nextPageToken, MENTION_TYPES.USER, EMPTY_STRING),
  };

  if (!_isEmpty(searchText)) {
    payloadForUsers = {
      ...payloadForUsers,
      searchText,
      searchTextMetadata: [{ field: 'name' }],
    };
  }

  _set(payload, MENTION_TYPES.USER, payloadForUsers);

  // defaultGetPayload is returning an object containing payload for different mention types ( eg. user, user-groups,etc)
  return payload;
};

const defaultServiceHandler = async (payload = EMPTY_OBJECT) => {
  const hits = {};
  const nextPageToken = {};

  if (!_isNil(_get(payload, [MENTION_TYPES.USER, 'nextPageToken']))) {
    const responseForUsers = await fetchEntityRecords(STANDARD_ENTITY_NAME.USER, tget(payload, MENTION_TYPES.USER, EMPTY_OBJECT));
    const hitsForUsers = tget(responseForUsers, 'hits', EMPTY_ARRAY);
    const newNextPageTokenForUsers = tget(responseForUsers, 'nextPageToken');
    _set(hits, MENTION_TYPES.USER, hitsForUsers);
    _set(nextPageToken, MENTION_TYPES.USER, newNextPageTokenForUsers);
  }

  // defaultServiceHandler is returning an object containing hits and pageToken for different mention types ( eg. user, user-groups,etc)
  return {
    hits,
    nextPageToken,
  };
};

const defaultGetOptions = (mentionOptions = EMPTY_OBJECT) => {
  const optionsForUsers = _map(tget(mentionOptions, MENTION_TYPES.USER, EMPTY_ARRAY), (option) => ({
    display: workspaceUserReader.fullName(option) || workspaceUserReader.NAME(option) || tget(option, 'displayName', NO_DATA),
    id: tget(option, 'id'),
    profilePicture: workspaceUserReader.profilePicture(option),
  }));

  // defaultServiceHandler is returning an array containing combined hits of all the mention types ( eg. user, user-groups,etc)
  return [...optionsForUsers];
};

const checkIfIsNilNextPageToken = (nextPageToken) => {
  if (_isNil(nextPageToken)) {
    return false;
  } else if (_isObject(nextPageToken)) {
    // checking here if at least one of the mention types has nextPageToken as not nil, only then we will be fetching the data
    return !_isNil(_get(nextPageToken, MENTION_TYPES.USER));
  }

  return true;
};

export { defaultGetOptions, defaultServiceHandler, defaultGetPayload, checkIfIsNilNextPageToken };
