const SUBSCRIPTION_CONFIG_STATUS_RADIO_OPTIONS = {
  ACTIVE: false,
  INACTIVE: true,
};

const SUBSCRIPTION_CONFIG_STATUS_RADIO_LABEL_MAP = {
  [SUBSCRIPTION_CONFIG_STATUS_RADIO_OPTIONS.ACTIVE]: __('Active'),
  [SUBSCRIPTION_CONFIG_STATUS_RADIO_OPTIONS.INACTIVE]: __('Inactive'),
};

const SUBSCRIPTION_CONFIG_FORM_CONTEXT_ID = 'SUBSCRIPTION_CONFIG_FORM';

export { SUBSCRIPTION_CONFIG_STATUS_RADIO_OPTIONS, SUBSCRIPTION_CONFIG_STATUS_RADIO_LABEL_MAP, SUBSCRIPTION_CONFIG_FORM_CONTEXT_ID };
