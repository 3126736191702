import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INFO } from '@tekion/tekion-base/constants/tableConstants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';

// Actions
import { disableProcess, enableProcess, fetchProcesses } from '../../../../../../../actions/approvalManagement.actions';
import { STUDIO_ROUTE } from '../../../../../../../constants/routes';

// Helpers
import { getPayload } from './approvalProcessList.helpers';

// Constants
import ROUTES from '../../../../../constants/routes';
import ACTION_TYPES from '../constants/approvalProcessList.actionTypes';
import COLUMN_IDS from '../constants/approvalProcessList.columnIds';
import PAGE_IDS from '../../../../../constants/PageIds.constants';

const fetchData = async ({ getState, setState }) => {
  setState({ isLoading: true });
  const {
    pageSize = DEFAULT_PAGE_SIZE,
    currentPage = DEFAULT_PAGE_INFO.start,
    currentPageToken = EMPTY_STRING,
    searchText = EMPTY_STRING,
  } = getState();

  const payload = getPayload({ currentPage, currentPageToken, pageSize, searchText });

  const response = await fetchProcesses(payload);
  const data = tget(response, 'hits', EMPTY_ARRAY);
  const nextPageToken = _get(response, 'nextPageToken', EMPTY_STRING);
  setState({ data, isLoading: false, nextPageToken });
};

const handleSearch = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ searchText: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => fetchData({ getState, setState }));
};

const handlePageUpdate = ({ params, getState, setState }) => {
  const { page, resultsPerPage } = _get(params, 'value', EMPTY_OBJECT);
  const { currentPage, pageSize, nextPageToken, previousPageTokens = [], currentPageToken } = getState();
  let newPageNumber = page;
  let newTokenNumber = null;
  let newPrevPageTokens = [...previousPageTokens];

  if (resultsPerPage !== pageSize) {
    newPageNumber = 1;
    newPrevPageTokens = [];
  } else if (page > currentPage + 1) {
    newTokenNumber = nextPageToken;
    newPrevPageTokens.push(currentPageToken);
  } else if (page !== 1) {
    newTokenNumber = newPrevPageTokens.pop();
  } else {
    newPrevPageTokens = [];
  }

  setState(
    { currentPage: newPageNumber - 1, pageSize: resultsPerPage, currentPageToken: newTokenNumber, previousPageTokens: newPrevPageTokens },
    () => fetchData({ getState, setState }),
  );
};

const handleRowClick = ({ getState, params = EMPTY_OBJECT }) => {
  const { history } = getState();
  const name = _get(params, 'value.original.name', '');

  if (!_isEmpty(name)) {
    history.push(`${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_PROCESS_EDIT}/${name}`);
  }
};

const handleFilterChange = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ selectedFilters: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => fetchData({ getState, setState }));
};

const handleCreateApprovalProcessClick = ({ getState }) => {
  const { history } = getState();
  history.push(ROUTES.APPROVAL_PROCESS_CREATE_ROUTE);
};

const handleEditApprovalProcessClick = ({ params, getState }) => {
  const { history } = getState();
  const { name } = params;
  if (!_isEmpty(name)) {
    history.push(`${STUDIO_ROUTE}/${PAGE_IDS.APPROVAL_STUDIO}/${PAGE_IDS.APPROVAL_PROCESS_EDIT}/${name}`);
  }
};

const handleActivateClick = ({ setState, params = EMPTY_OBJECT }) => {
  const processName = tget(params, COLUMN_IDS.NAME);

  setState({
    isRequestDialogVisible: true,
    actionInProgress: ACTION_TYPES.ACTIVATE_APPROVAL_PROCESS,
    modalTitle: __('Activate process - {{processName}}', { processName }),
    modalContent: __('Are you sure you want to activate this process.'),
    processUnderAction: params,
  });
};

const handleDeactivateClick = ({ setState, params = EMPTY_OBJECT }) => {
  const processName = tget(params, COLUMN_IDS.NAME);

  setState({
    isRequestDialogVisible: true,
    actionInProgress: ACTION_TYPES.DEACTIVATE_APPROVAL_PROCESS,
    modalTitle: __('Deactivate process - {{processName}}', { processName }),
    modalContent: __('Are you sure you want to deactivate this process.'),
    processUnderAction: params,
  });
};

const handleCancelConfirmationModal = ({ setState }) => {
  setState({ isRequestDialogVisible: false, actionInProgress: '', processUnderAction: {}, modalContent: '', modalTitle: '' });
};

const handleSubmitConfirmationModal = async ({ getState, setState }) => {
  const { processUnderAction, actionInProgress } = getState();
  const name = _get(processUnderAction, COLUMN_IDS.NAME, '');

  setState({
    isRequestDialogVisible: false,
    isLoading: true,
    actionInProgress: '',
    modalContent: '',
    modalTitle: '',
    currentPage: DEFAULT_PAGE_INFO.start,
    processUnderAction: {},
  });

  switch (actionInProgress) {
    case ACTION_TYPES.ACTIVATE_APPROVAL_PROCESS: {
      const updatedProcess = { ...processUnderAction, [COLUMN_IDS.INACTIVE]: false };
      const data = await enableProcess(name, updatedProcess);

      if (!_isEmpty(data)) {
        setTimeout(() => {
          fetchData({ params: { nextPageToken: EMPTY_STRING }, getState, setState });
        }, ES_REFETCH_DELAY);
      }
      break;
    }
    case ACTION_TYPES.DEACTIVATE_APPROVAL_PROCESS: {
      const updatedProcess = { ...processUnderAction, [COLUMN_IDS.INACTIVE]: true };
      const data = await disableProcess(name, updatedProcess);

      if (!_isEmpty(data)) {
        setTimeout(() => {
          fetchData({ params: { nextPageToken: EMPTY_STRING }, getState, setState });
        }, ES_REFETCH_DELAY);
      }
      break;
    }
    default:
  }
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleRowClick,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleFilterChange,
  [ACTION_TYPES.CREATE_APPROVAL_PROCESS]: handleCreateApprovalProcessClick,
  [ACTION_TYPES.EDIT_APPROVAL_PROCESS]: handleEditApprovalProcessClick,
  [ACTION_TYPES.ACTIVATE_APPROVAL_PROCESS]: handleActivateClick,
  [ACTION_TYPES.DEACTIVATE_APPROVAL_PROCESS]: handleDeactivateClick,
  [ACTION_TYPES.ON_CANCEL_CONFIRMATION_MODAL]: handleCancelConfirmationModal,
  [ACTION_TYPES.ON_SUBMIT_CONFIRMATION_MODAL]: handleSubmitConfirmationModal,
};

export default ACTION_HANDLERS;
