import React from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from '@tekion/tekion-components/molecules/Modal';

const ConfirmationModal = ({ visible, title, confirmationContent, submitBtnText, onSubmit, onCancel }) => (
  <Modal destroyOnClose visible={visible} title={title} width={Modal.SIZES.S} submitBtnText={submitBtnText} onCancel={onCancel} onSubmit={onSubmit}>
    {confirmationContent}
  </Modal>
);

ConfirmationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  submitBtnText: PropTypes.string,
  confirmationContent: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ConfirmationModal.defaultProps = {
  submitBtnText: __('Confirm'),
};

export default ConfirmationModal;
