import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _find from 'lodash/find';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchPageConfigurationByName } from '../../../../../../../../../actions/visualBuilder.actions';
import { getApplicationDetails } from './applicationPreviewPage.helpers';

import ACTION_TYPES from '../constants/applicationPreviewPage.actionTypes';
import { FIELD_IDS, PROPERTIES_FIELD_IDS } from '../../../constants/applicationBuilder.constants';

const getDataForTab = async ({ setState, getState }) => {
  const { allTabs = [], selectedTabId, applicationConfig, isNavigationRequired, landingPageData = {} } = getState();

  let tabData;
  let pageName;
  let updatedAllTabsData = [...allTabs];
  let pageConfiguration;

  if (!isNavigationRequired) {
    tabData = landingPageData;
    pageName = _get(getArraySafeValue(tget(applicationConfig, FIELD_IDS.COMPONENTS, [])), PROPERTIES_FIELD_IDS.PAGE_NAME);
    tabData = { ...tabData, pageName };
  } else {
    tabData = _find(allTabs, (tab) => _get(tab, 'id') === selectedTabId);
    pageName = _get(tabData, PROPERTIES_FIELD_IDS.PAGE_NAME);
  }

  if (!_isEmpty(pageName)) {
    pageConfiguration = _get(tabData, 'pageConfiguration');

    if (_isEmpty(pageConfiguration)) {
      pageConfiguration = await fetchPageConfigurationByName(pageName);
    }
  }
  const newTabData = {
    ...tabData,
    pageConfiguration,
  };

  if (isNavigationRequired) {
    const tabId = _get(tabData, 'id');
    updatedAllTabsData = _map(allTabs, (tab) => (tab.id === tabId ? newTabData : tab));
  }

  setState({
    loading: false,
    allTabs: updatedAllTabsData,
    landingPageData: newTabData,
  });
};

const handleOnMount = async ({ getState, setState }) => {
  const { applicationConfig } = getState();
  const { allTabs, navigationType, isNavigationRequired } = getApplicationDetails(applicationConfig);

  setState(
    {
      allTabs,
      selectedTabId: _get(getArraySafeValue(allTabs), 'id'),
      navigationType,
      isNavigationRequired,
      loading: true,
    },
    async () => {
      await getDataForTab({ getState, setState });
    },
  );
};

const handleTabClick = ({ params = EMPTY_OBJECT, setState, getState }) => {
  const newSelectedTabId = _get(params, 'tab');

  setState({ selectedTabId: newSelectedTabId }, async () => {
    await getDataForTab({ setState, getState });
  });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT_DATA]: handleOnMount,
  [ACTION_TYPES.ON_TAB_CLICK]: handleTabClick,
};

export default ACTION_HANDLERS;
