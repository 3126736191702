import _get from 'lodash/get';
import _map from 'lodash/map';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { CONTROLLING_OPTION_TABLE_COLUMN_IDS, REMOVE_ACTION } from '../constants/controllingOptionConfigTable.general';

const getActionsForRow = () => [REMOVE_ACTION];

const getSelectedOptions = (value) => _map(value, (option) => getArraySafeValue(_get(option, CONTROLLING_OPTION_TABLE_COLUMN_IDS.OPTION)));

export { getActionsForRow, getSelectedOptions };
