import { WIDGET_NAME_CONFIGURE_FIELD_IDS } from '../constants/widgetNameConfigure.fieldIds';

const WIDGET_NAME_CONFIGURE_SECTIONS = [
  {
    rows: [
      {
        columns: [WIDGET_NAME_CONFIGURE_FIELD_IDS.WIDGET_TYPE],
      },
      {
        columns: [WIDGET_NAME_CONFIGURE_FIELD_IDS.WIDGET_NAME],
      },
    ],
  },
];

export { WIDGET_NAME_CONFIGURE_SECTIONS };
