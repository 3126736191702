import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components/molecules/loader';

import PageViewer from '../../../../../../../../organisms/visualBuilder/organisms/pageViewer/PageViewer';
import ApprovalPreviewComponent from '../approvalPreviewComponent';

import ACTION_HANDLERS from './helpers/applicationPageContent.actionHandlers';
import { ACTION_TYPES } from './constants/applicationPageContent.constants';
import { TAB_IDS } from '../../constants/applicationBuilder.constants';
import { APPLICATION_CONTEXT_KEYS } from '../../../../../../../../constants/applicationRenderer.constants';

import styles from './applicationPageContent.module.scss';

const ApplicationPageContent = ({ history, tabContent, pageConfiguration, applicationContext, onAction }) => {
  const ref = useRef();

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const pageName = tget(tabContent, 'pageName', EMPTY_STRING);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_INIT });
  }, [onAction, pageName]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
    }
  });

  if (_isEmpty(pageConfiguration)) return <Loader />;

  if (_get(tabContent, 'id') === TAB_IDS.APPROVAL) {
    return (
      <div className={styles.pageViewer} ref={ref}>
        <ApprovalPreviewComponent
          isMountedInsideApplication
          contentHeight={height}
          contentWidth={width}
          currentLoggedInUserData={_get(applicationContext, APPLICATION_CONTEXT_KEYS.CURRENT_USER)}
          applicationProperties={tabContent}
        />
      </div>
    );
  }

  return (
    <div className={styles.pageViewer} ref={ref}>
      <PageViewer
        isPreviewMode
        contentWidth={width}
        contentHeight={height}
        history={history}
        pageConfiguration={pageConfiguration}
        applicationContext={applicationContext}
      />
    </div>
  );
};

ApplicationPageContent.propTypes = {
  tabContent: PropTypes.object,
  pageConfiguration: PropTypes.object,
  history: PropTypes.object,
  applicationContext: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ApplicationPageContent.defaultProps = {
  tabContent: EMPTY_OBJECT,
  pageConfiguration: EMPTY_OBJECT,
  history: EMPTY_OBJECT,
  applicationContext: EMPTY_OBJECT,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS), withRouter)(ApplicationPageContent);
