// Lodash
import _reduce from 'lodash/reduce';
import _uniq from 'lodash/uniq';
import _isArray from 'lodash/isArray';
import _isNil from 'lodash/isNil';

import { appendEntityResourcesToResolve } from '@tekion/tekion-base/bulkResolvers/helpers/bulkResolvers.general';

// utils
import castArrayIfPresent from '@tekion/tekion-base/utils/castArrayIfPresent';

import { getParsedValue } from '../helpers/auditLogsDrawer.general';
import activityReader from '../readers/Activity';
import changeReader from '../readers/Change';

const addValueToValuesToBeResolved = (valuesToBeResolved, values) => {
  const parsedValue = getParsedValue(values);

  if (_isArray(parsedValue)) {
    return [...valuesToBeResolved, ...parsedValue];
  }
  if (!_isNil(parsedValue)) {
    return [...valuesToBeResolved, parsedValue];
  }
  return valuesToBeResolved;
};

const addResourcesToBeResolvedInMap = (resourcesToBeResolved, change) => {
  const resourcesToBeResolvedMap = resourcesToBeResolved;
  const lookupType = changeReader.lookupType(change);

  if (lookupType) {
    const oldValues = changeReader.oldValues(change);
    const newValues = changeReader.newValues(change);
    let valuesToBeResolved = [];

    valuesToBeResolved = addValueToValuesToBeResolved(valuesToBeResolved, oldValues);
    valuesToBeResolved = addValueToValuesToBeResolved(valuesToBeResolved, newValues);

    if (resourcesToBeResolvedMap[lookupType]) {
      const allValuesToBeResolved = resourcesToBeResolvedMap[lookupType].keys;
      const values = [...allValuesToBeResolved, ...valuesToBeResolved];
      const uniqValuesToBeResolved = _uniq(values);
      resourcesToBeResolvedMap[lookupType] = { keys: uniqValuesToBeResolved };
    } else {
      resourcesToBeResolvedMap[lookupType] = { keys: valuesToBeResolved };
    }
  }

  return resourcesToBeResolvedMap;
};

const addResourcesToBeResolved = (resourcesToBeResolved, change) => {
  const nestedAuditChanges = changeReader.nestedAuditChanges(change);

  if (nestedAuditChanges) {
    return _reduce(nestedAuditChanges, addResourcesToBeResolved, resourcesToBeResolved);
  }

  return addResourcesToBeResolvedInMap(resourcesToBeResolved, change);
};

const addResolvedResources = (resourcesToBeResolved, dataItem) => {
  const changes = activityReader.changes(dataItem);

  return _reduce(changes, addResourcesToBeResolved, resourcesToBeResolved);
};

export const getResourcesToBeResolved = (additionalPathsAndResourcesToBeResolved) => (dataToBeResolved) => {
  const dataToBeResolvedArray = castArrayIfPresent(dataToBeResolved);
  const toBeResolvedResources = _reduce(dataToBeResolvedArray, addResolvedResources, {});
  if (!additionalPathsAndResourcesToBeResolved) {
    return { toBeResolvedResources };
  }
  return {
    toBeResolvedResources: _reduce(
      dataToBeResolvedArray,
      (resourcesToBeResolved, dataToBeResolvedListItem) =>
        appendEntityResourcesToResolve(resourcesToBeResolved, additionalPathsAndResourcesToBeResolved, dataToBeResolvedListItem),
      toBeResolvedResources,
    ),
  };
};
