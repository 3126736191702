import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { executeEventFromEventViewConfigData } from '../../utils/eventHandlers';

import { EVENT_NAMES } from '../../constants/eventActions.constants';
import { MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT } from '../../constants/eventHandlers.constants';

const EVENT_REGISTER_ACTION_TYPES = {
  SET_ERROR: 'SET_ERROR',
};

const withEventRegisterFormRenderer = (WrappedComponent) => {
  const WrappedComponentWithEventRegistered = (props) => {
    const { id, mapOfFormFieldNameToEditable, eventHandlers, getFieldValue, onAction } = props;

    const handleAction = useCallback(
      (action) => {
        const { type, payload } = action;

        switch (type) {
          case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
            const onChangeEventData = tget(eventHandlers, EVENT_NAMES.CHANGE, EMPTY_OBJECT);

            const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
              [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.FIELD_VALUE]: tget(payload, 'value'),
              [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.FIELD]: {
                get: (getterFieldName) => getFieldValue(getterFieldName),
                set: (setterFieldName, setterValue) => {
                  // Checking the value dispatched for field can be done or not
                  const isSetterFieldEditable = tget(mapOfFormFieldNameToEditable, [setterFieldName], false);

                  if (isSetterFieldEditable) {
                    onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id: setterFieldName, value: setterValue } });
                  }
                },
              },
              [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.ERROR]: {
                set: (fieldName, errorMessage) => {
                  // Keeping setTimeOut as ON_FIELD change invokes validators of that field and will call validation success
                  // and here we want that once that is completed after that we want to invoke this one as during concurrent
                  // run it might be possible that first this one got executed and after that validation success of form
                  // executed and if that validator throws no error, the set error will be lost
                  setTimeout(() => {
                    onAction({ type: EVENT_REGISTER_ACTION_TYPES.SET_ERROR, payload: { fieldName, errorMessage } });
                  }, 300);
                },
              },
            };

            executeEventFromEventViewConfigData(onChangeEventData, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE);
            break;
          }

          case FORM_ACTION_TYPES.ON_FIELD_BLUR: {
            const onFieldBlurEventData = tget(eventHandlers, EVENT_NAMES.BLUR, EMPTY_OBJECT);

            const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
              [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.FIELD_VALUE]: getFieldValue(id),
              [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.FIELD]: {
                get: (getterFieldName) => getFieldValue(getterFieldName),
                set: (setterFieldName, setterValue) => {
                  // Checking the value dispatched for field can be done or not
                  const isSetterFieldEditable = tget(mapOfFormFieldNameToEditable, [setterFieldName], false);

                  if (isSetterFieldEditable) {
                    onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id: setterFieldName, value: setterValue } });
                  }
                },
              },
              [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.ERROR]: {
                set: (fieldName, errorMessage) => {
                  onAction({ type: EVENT_REGISTER_ACTION_TYPES.SET_ERROR, payload: { fieldName, errorMessage } });
                },
              },
            };

            executeEventFromEventViewConfigData(onFieldBlurEventData, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE);
            break;
          }

          default: {
            break;
          }
        }

        onAction(action);
      },
      [onAction, eventHandlers, getFieldValue, mapOfFormFieldNameToEditable, id],
    );

    return <WrappedComponent {...props} onAction={handleAction} />;
  };

  WrappedComponentWithEventRegistered.propTypes = {
    id: PropTypes.string.isRequired,
    mapOfFormFieldNameToEditable: PropTypes.object,
    eventHandlers: PropTypes.object,
    getFieldValue: PropTypes.func,
    onAction: PropTypes.func.isRequired,
  };

  WrappedComponentWithEventRegistered.defaultProps = {
    mapOfFormFieldNameToEditable: EMPTY_OBJECT,
    eventHandlers: EMPTY_OBJECT,
    getFieldValue: _noop,
  };

  return WrappedComponentWithEventRegistered;
};

export { EVENT_REGISTER_ACTION_TYPES };

export default withEventRegisterFormRenderer;
