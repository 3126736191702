exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".workspaceUserForm_userFormSections__rMdAXNYk4t{max-width:100rem}.workspaceUserForm_spinnerWrap__65z88WJXPW{display:flex;justify-content:center;align-items:center;width:100%;height:100%}.workspaceUserForm_userDetailModalContainer__99xVdQhxUx{display:flex;flex-direction:column;align-items:flex-start;width:100%}[dir] .workspaceUserForm_userDetailModalContainer__99xVdQhxUx{margin-bottom:1rem}.workspaceUserForm_pageBody__6DKpq7SdQg{overflow-y:scroll}", ""]);

// Exports
exports.locals = {
	"userFormSections": "workspaceUserForm_userFormSections__rMdAXNYk4t",
	"spinnerWrap": "workspaceUserForm_spinnerWrap__65z88WJXPW",
	"userDetailModalContainer": "workspaceUserForm_userDetailModalContainer__99xVdQhxUx",
	"pageBody": "workspaceUserForm_pageBody__6DKpq7SdQg"
};