const OPTION_KEYS = {
  ID: 'ID',
  LABEL: 'LABEL',
  DISABLED: 'DISABLED',
  DISABLE_MESSAGE: 'DISABLE_MESSAGE',
  ON_CLICK: 'ON_CLICK',
  ADDITIONAL: 'ADDITIONAL',
};

export { OPTION_KEYS };
