import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/cms/global-selectlists';

const createGlobalSelect = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}`, payload);
const updateGlobalSelect = (name, payload = EMPTY_OBJECT) => ApiService.put(`${BASE_URL}/${name}`, payload);
const getGlobalSelectByName = (name) => ApiService.get(`${BASE_URL}/${name}`);
const updateGlobalSelectOptions = (name, payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/${name}/options`, payload);
const fetchAllGlobalSelects = (payload = EMPTY_OBJECT) => ApiService.post(`${BASE_URL}/search`, payload);

export default {
  createGlobalSelect,
  updateGlobalSelect,
  getGlobalSelectByName,
  updateGlobalSelectOptions,
  fetchAllGlobalSelects,
};
