import _get from 'lodash/get';
import _valuesIn from 'lodash/valuesIn';
import { NO_DATA } from '@tekion/tekion-base/app.constants';
import { TOASTER_TYPE, toaster } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { fetchEntityRecords } from '../../../../actions/recordManagement.actions';
import {
  fetchViewConfigurationByName,
  saveViewConfiguration,
  updateViewConfiguration,
  getCellViewConfigsByNameFromViewConfig,
} from '../../../../actions/viewBuilderPage.actions';
import { getMasterEntityDef, getMasterEntityRecord } from '../../../../actions/entityManagement.actions';

import { getFilteredFieldDefinitionsBasedOnViewTypes } from '../../../../helpers/entityManagement.helpers';

import { ACTION_TYPES } from './viewBuilderPage.constants';
import RECORD_FIELDS from '../../../../constants/recordFields';
import { VIEW_CONFIGURATION_FIELD_IDS, VIEW_CONFIGURATION_GENERAL_KEYS } from '../../../../constants/viewBuilder.constants';

import entityReader from '../../../../readers/entity.reader';

const handleInit = async ({ getState, setState }) => {
  const { match } = getState();
  const entityName = _get(match, 'params.entityName');
  const viewName = _get(match, 'params.viewName');

  setState({
    isLoading: true,
    isEditMode: !!viewName,
  });

  let masterEntityDef = await getMasterEntityDef(entityName);
  const masterEntityRecord = await getMasterEntityRecord(undefined, RECORD_FIELDS.ID, masterEntityDef, true);

  let viewConfiguration;
  if (viewName) {
    viewConfiguration = await fetchViewConfigurationByName(entityName, viewName);
    const viewType = tget(viewConfiguration, 'viewType');

    // Updating fieldDefinitions when view builder is in edit_mode, as in that case we already have a view_type and based on that
    // view_type only we should have fieldDefs, such as in case of list,grid,detail view we filter nothing, for form_view
    // we filter out derivations and record_type_view fields and for record_type_view only pick derivations and record_type fields.
    const updatedFieldDefinitions = getFilteredFieldDefinitionsBasedOnViewTypes(masterEntityDef, viewType);
    masterEntityDef = { ...masterEntityDef, fieldDefinitions: updatedFieldDefinitions };
  }

  setState({
    entity: masterEntityDef,
    currentRecordTypeEntity: masterEntityDef,
    entityRecord: masterEntityRecord,
    initialViewConfiguration: viewConfiguration,
    isLoading: false,
  });
};

const handleFetchEntityRecords = async ({ params, getState, setState }) => {
  const state = getState();
  const entityName = _get(state, 'entity.name');

  if (entityName !== params.entityName) {
    setState({
      isLoading: true,
    });

    const entityRecords = await fetchEntityRecords(params.entityName);

    setState({
      entityRecords,
      isLoading: false,
    });
  }
};

const handleViewSummarySave = ({ params, setState, getState }) => {
  const { initialViewConfiguration } = getState();
  const { viewSummary, updatedEntityDefAccordingToSelectedRecordTypes, cachedCreateViewFormState } = params;

  let updatedViewConfig;
  if (initialViewConfiguration) {
    updatedViewConfig = {
      ...initialViewConfiguration,
      viewType: getArraySafeValue(_get(viewSummary, VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE)),
      name: _get(viewSummary, VIEW_CONFIGURATION_FIELD_IDS.NAME),
      properties: {
        ..._get(initialViewConfiguration, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, {}),
        title: _get(viewSummary, VIEW_CONFIGURATION_FIELD_IDS.DISPLAY_NAME),
        description: _get(viewSummary, VIEW_CONFIGURATION_FIELD_IDS.DESCRIPTION),
        viewType: getArraySafeValue(_get(viewSummary, VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE)),
      },
      applicableRecordTypes: _get(viewSummary, VIEW_CONFIGURATION_FIELD_IDS.APPLICABLE_RECORD_TYPES),
    };
  }

  setState({
    viewSummary,
    initialViewConfiguration: updatedViewConfig,
    currentRecordTypeEntity: updatedEntityDefAccordingToSelectedRecordTypes,
    cachedCreateViewFormState,
  });
};

const handleViewConfigurationSave = async ({ params, getState, setState }) => {
  const { viewConfiguration } = params;
  const { entity, isEditMode } = getState();

  const entityName = entityReader.name(entity);
  const viewName = _get(viewConfiguration, VIEW_CONFIGURATION_FIELD_IDS.NAME);
  const viewDisplayName = tget(
    viewConfiguration,
    VIEW_CONFIGURATION_FIELD_IDS.DISPLAY_NAME,
    tget(viewConfiguration, VIEW_CONFIGURATION_FIELD_IDS.NAME, NO_DATA),
  );

  let savedViewConfiguration;
  if (isEditMode && viewName) {
    savedViewConfiguration = await updateViewConfiguration(entityName, viewName, viewConfiguration);
  } else {
    savedViewConfiguration = await saveViewConfiguration(entityName, viewConfiguration);
  }

  if (savedViewConfiguration) {
    toaster(TOASTER_TYPE.SUCCESS, `View configuration saved successfully for ${viewDisplayName}.`);

    setState({
      isEditMode: true,
      initialViewConfiguration: savedViewConfiguration,
    });
  }
};

const handleViewConfigurationSaveModalClose = ({ setState }) => {
  setState({
    isCancelModalVisible: false,
  });
};

const handleCancelViewConfiguration = ({ setState }) => {
  setState({
    isCancelModalVisible: true,
  });
};

const handlePreviewConfig = async ({ setState, params: viewConfig, getState }) => {
  const { viewConfigsByName = {} } = getState();
  const cellViewConfigsByName = await getCellViewConfigsByNameFromViewConfig({ viewConfig, existingViewNames: _valuesIn(viewConfigsByName) });
  setState({ viewConfigsByName: { ...viewConfigsByName, ...cellViewConfigsByName } });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: handleInit,
  [ACTION_TYPES.FETCH_ENTITY_RECORDS]: handleFetchEntityRecords,
  [ACTION_TYPES.SAVE_VIEW_SUMMARY]: handleViewSummarySave,
  [ACTION_TYPES.SAVE_VIEW_CONFIGURATION]: handleViewConfigurationSave,
  [ACTION_TYPES.SAVE_VIEW_CONFIGURATION_MODAL_CLOSE]: handleViewConfigurationSaveModalClose,
  [ACTION_TYPES.CANCEL_VIEW_CONFIGURATION]: handleCancelViewConfiguration,
  [ACTION_TYPES.PREVIEW_VIEW_CONFIG]: handlePreviewConfig,
};

export default ACTION_HANDLERS;
