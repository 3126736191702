import _get from 'lodash/get';
import _forEach from 'lodash/forEach';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

import RecordSharingRuleUsersDetailColumnRenderer from '../components/recordSharingRuleUsersDetailColumnRenderer';
import RecordSharingRulePermissionIconRenderer from '../components/recordSharingRulePermissionIconRenderer';
import { getRecordGroupName, isRecordGroupName } from './recordSharingRuleList.helpers';
import COLUMN_IDS from '../constants/recordSharingRuleList.columnIds';
import { ASSIGN_TYPES, PRINCIPAL_TYPES } from '../constants/recordSharingRuleList.general.constants';
import { FIELD_IDS } from '../../../../../constants/entityRecordGroup.constants';

const getAssignedUsersAndUserGroupsIds = (assignType, rowInfo) => {
  let userGroupNames = [];
  let userIds = [];
  const sharingSettings = _get(rowInfo, 'sharingSettings', EMPTY_ARRAY);
  let allAssignedIds = [];

  _forEach(sharingSettings, (shareSetting) => {
    const principalType = _get(shareSetting, 'principalType', '');

    if (principalType === PRINCIPAL_TYPES.USER) {
      allAssignedIds = _get(shareSetting, 'principalIdentifiers', []);
    }
  });

  _forEach(allAssignedIds, (id) => {
    if (isRecordGroupName(id)) {
      userGroupNames = [...userGroupNames, getRecordGroupName(id)];
    } else {
      userIds = [...userIds, id];
    }
  });

  if (assignType === ASSIGN_TYPES.USER_GROUP) {
    return userGroupNames;
  }
  return userIds;
};

const getUserAccessor = (rowInfo) => getAssignedUsersAndUserGroupsIds(ASSIGN_TYPES.USER, rowInfo);

const getUserGroupAccessor = (rowInfo) => getAssignedUsersAndUserGroupsIds(ASSIGN_TYPES.USER_GROUP, rowInfo);

const getPermissionReadOnlyAccessor = (rowInfo) => {
  const accessType = _get(rowInfo, COLUMN_IDS.PERMISSION);
  return accessType === COLUMN_IDS.READ_ONLY;
};

const getPermissionReadWriteAccessor = (rowInfo) => {
  const accessType = _get(rowInfo, COLUMN_IDS.PERMISSION);
  return accessType === COLUMN_IDS.READ_WRITE;
};

const getRecordGroupNameAccessor = (getAllEntityRecordGroupDetailsByName) => (rowInfo) => {
  const recordGroupName = _get(rowInfo, COLUMN_IDS.RECORD_GROUP_NAME);
  const recordGroupData = _get(getAllEntityRecordGroupDetailsByName, recordGroupName);
  return _get(recordGroupData, FIELD_IDS.DISPLAY_NAME);
};

const getRuleListColumns = (getAllAssignedUsersDataByKeyId, getAllAssignedUserGroupsDataByKeyName, getAllEntityRecordGroupDetailsByName) => [
  {
    id: COLUMN_IDS.RULE_NAME,
    Header: __('Rule Name'),
    accessor: COLUMN_IDS.RULE_NAME,
    minWidth: 80,
  },
  {
    id: COLUMN_IDS.RECORD_GROUP_NAME,
    Header: __('Record Group'),
    accessor: getRecordGroupNameAccessor(getAllEntityRecordGroupDetailsByName),
    Cell: TextRenderer,
  },
  {
    id: COLUMN_IDS.USERS,
    Header: __('Users'),
    accessor: getUserAccessor,
    minWidth: 140,
    Cell: RecordSharingRuleUsersDetailColumnRenderer,
    getProps: () => ({
      getAllDataById: getAllAssignedUsersDataByKeyId,
    }),
  },
  {
    id: COLUMN_IDS.USER_GROUPS,
    Header: __('User Groups'),
    accessor: getUserGroupAccessor,
    minWidth: 140,
    Cell: RecordSharingRuleUsersDetailColumnRenderer,
    getProps: () => ({
      getAllDataById: getAllAssignedUserGroupsDataByKeyName,
    }),
  },
  {
    id: COLUMN_IDS.READ_ONLY,
    Header: __('Read'),
    accessor: getPermissionReadOnlyAccessor,
    minWidth: 50,
    Cell: RecordSharingRulePermissionIconRenderer,
  },
  {
    id: COLUMN_IDS.READ_WRITE,
    Header: __('Read-Write'),
    accessor: getPermissionReadWriteAccessor,
    minWidth: 50,
    Cell: RecordSharingRulePermissionIconRenderer,
  },
];

export default getRuleListColumns;
