import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@tekion/tekion-components/atoms/Button';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import Heading from '@tekion/tekion-components/atoms/Heading';
import TableManager from '@tekion/tekion-components/organisms/TableManager';

import AddFieldModal from '../addFieldModal/AddFieldModal';
import { getFieldOptions } from '../../helpers/recordType.helpers';
import { COLUMNS, getTableProps, TABLE_MANAGER_PROPS } from './constants/feildListTable.data';
import { ACTION_TYPES } from '../../constants/recordType.constants';

import styles from '../../recordType.module.scss';
import { FORM_MODES } from '../../../../../../constants/general.constants';

const FieldListTable = (props) => {
  const { isModalVisible, formMode, fieldTableData, derivationFields, fieldModalData, entity, onAction } = props;

  const [fields, setFieldsState] = useState([]);

  const handleRowAction = useCallback(
    (actionType, value) => {
      onAction({
        type: actionType,
        payload: {
          value,
        },
      });
    },
    [onAction],
  );

  const tableProps = useMemo(() => getTableProps(handleRowAction, formMode, derivationFields), [handleRowAction, formMode, derivationFields]);

  const handleClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.MODAL_OPEN,
    });
  }, [onAction]);

  const handleClose = useCallback(() => {
    onAction({
      type: ACTION_TYPES.MODAL_CLOSE,
    });
  }, [onAction]);

  const handleModalSubmit = useCallback(() => {
    onAction({
      type: ACTION_TYPES.MODAL_SAVE,
      payload: { fields },
    });
  }, [onAction, fields]);

  const handleChange = useCallback((selectedFields) => {
    setFieldsState(selectedFields.value);
  }, []);

  useEffect(() => {
    setFieldsState(fieldModalData);
  }, [fieldModalData]);

  return (
    <>
      <PropertyControlledComponent controllerProperty={isModalVisible}>
        <AddFieldModal
          visible={isModalVisible}
          fields={fields}
          fieldOptions={getFieldOptions(entity, derivationFields)}
          handleChange={handleChange}
          handleClose={handleClose}
          handleModalSubmit={handleModalSubmit}
        />
      </PropertyControlledComponent>
      <Heading size={3} className={styles.addFieldHeader}>
        {__('Added Fields')}
        <Button onClick={handleClick} view={Button.VIEW.PRIMARY}>
          {__('Add Field')}
        </Button>
      </Heading>
      <TableManager
        isLoaded
        loading
        disableHeight
        columns={COLUMNS}
        data={fieldTableData}
        tableProps={tableProps}
        tableManagerProps={TABLE_MANAGER_PROPS}
        onAction={onAction}
      />
    </>
  );
};

FieldListTable.propTypes = {
  isModalVisible: PropTypes.bool,
  formMode: PropTypes.string,
  entity: PropTypes.object,
  fieldTableData: PropTypes.array,
  fieldModalData: PropTypes.array,
  derivationFields: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

FieldListTable.defaultProps = {
  isModalVisible: false,
  formMode: FORM_MODES.CREATE,
  entity: EMPTY_OBJECT,
  fieldTableData: EMPTY_ARRAY,
  derivationFields: EMPTY_ARRAY,
  fieldModalData: EMPTY_ARRAY,
};

export default React.memo(FieldListTable);
