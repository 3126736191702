import axios from 'axios';
import _noop from 'lodash/noop';
import ApiService from './apiService/apiService';

const BASE_URL = 'core/u/media';

const INITIATE_UPLOAD_URL = 'core/u/media/initiate-upload';
const PRESIGNED_URL = 'core/u/media/presignedurls';
const { CancelToken } = axios;

const initiateUpload = (payload) => ApiService.post(INITIATE_UPLOAD_URL, payload);
const getPreSignedUrls = (payload) => ApiService.post(PRESIGNED_URL, payload);

const getPreSignedUrlForCustomEntity = (payload) => ApiService.post(`${BASE_URL}/custom-entity/presignedurl`, payload);

const downloadMediaForCustomEntity = (payload) => ApiService.getCustomEntityMedia(`${BASE_URL}/custom-entity/download`, payload);

const uploadFileAtS3Url = (objectURL, file, fileType, onProgressUpdateCb = _noop) =>
  axios.put(objectURL, file, {
    headers: { accept: 'application/json', 'Content-Type': fileType, 'x-ms-blob-type': 'BlockBlob', 'Cache-Control': 'max-age=0' },
    onUploadProgress: onProgressUpdateCb,
  });

const getSource = () => CancelToken.source();

export default { initiateUpload, uploadFileAtS3Url, getSource, getPreSignedUrls, getPreSignedUrlForCustomEntity, downloadMediaForCustomEntity };
