import { FIELD_IDS } from '../constants/serverCredentialForm.constants';

import styles from '../serverCredentialForm.module.scss';

const SERVER_CREDENTIAL_FORM_SECTION = [
  {
    className: styles.formSection,
    rows: [
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
      },
      {
        columns: [FIELD_IDS.URL],
      },
      {
        columns: [FIELD_IDS.EXTERNAL_CREDENTIAL_NAME, FIELD_IDS.CLIENT_CERTIFICATE],
      },
      {
        columns: [FIELD_IDS.CREATE_EXTERNAL_CREDENTIAL],
      },
      {
        columns: [FIELD_IDS.OPEN_API_SPECIFICATION_ID],
      },
      {
        columns: [FIELD_IDS.ALLOW_FORMULA_IN_HEADERS, FIELD_IDS.ALLOW_FORMULA_IN_BODY],
      },
      {
        columns: [FIELD_IDS.GENERATE_AUTH_HEADERS],
      },
    ],
  },
];

export default SERVER_CREDENTIAL_FORM_SECTION;
