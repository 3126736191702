import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import Heading from '@tekion/tekion-components/atoms/Heading';
import withActions from '@tekion/tekion-components/connectors/withActions';
import Modal from '@tekion/tekion-components/molecules/Modal';
import Button from '@tekion/tekion-components/atoms/Button';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';

import TagsRenderer from '../../atoms/tagsRenderer/TagsRenderer';
import SearchOptionsModal from './components/SearchOptionsModal';

import ACTION_HANDLERS from './helpers/searchOptionsConfigurator.actionHandlers';
import { getAddedSearchOptions } from './helpers/searchOptionsConfigurator.helper';

import ACTION_TYPES from './constants/searchOptionsConfigurator.actionTypes';
import { SEARCH_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID } from './constants/searchOptionsConfigurator.constants';

import styles from './searchOptionsConfigurator.module.scss';

const SearchOptionsConfigurator = ({
  isModalVisible,
  selectedModuleIndex,
  relationshipField,
  allFormValues,
  selectedViewComponentProperties,
  errors,
  searchableFieldOptions,
  searchableFieldOptionsByRelationshipField,
  onAction,
}) => {
  const handleSubmit = useCallback(() => {
    if (!_isEmpty(allFormValues)) {
      triggerSubmit(SEARCH_OPTIONS_CONFIGURATOR_FORM_CONTEXT_ID, { actionType: ACTION_TYPES.ON_SAVE_MODAL });
    } else {
      onAction({
        type: ACTION_TYPES.ON_SAVE_MODAL,
      });
    }
  }, [allFormValues, onAction]);

  const handleCancelModal = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CANCEL_MODAL,
    });
  }, [onAction]);

  const handleConfigureClick = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_CLICK_CONFIGURE_SEARCH_OPTIONS,
    });
  }, [onAction]);

  const addedSearchOptionNames = useMemo(() => getAddedSearchOptions(allFormValues), [allFormValues]);

  useEffect(() => {
    onAction({
      type: ACTION_TYPES.ON_SELECTED_VIEW_COMPONENT_PROPERTIES_CHANGE,
      payload: { selectedViewComponentProperties },
    });
  }, [onAction, selectedViewComponentProperties]);

  return (
    <div className={styles.searchOptionsHandlerComponent}>
      <Heading size={5} className={styles.searchOptionsComponentHeading}>
        {__('Search Options Configurator')}
      </Heading>
      <div className={styles.searchOptionComponent}>
        <div className={styles.addedSearchOptionsContainer}>
          <div className={styles.addedSearchOptionSection}>
            {__('Added Options ')}({_size(addedSearchOptionNames)})
            <PropertyControlledComponent controllerProperty={_size(addedSearchOptionNames) > 0}>
              <TagsRenderer fieldValue={addedSearchOptionNames} maxLimit={2} tagClassName={styles.tag} tagContentClassName={styles.tagContent} />
            </PropertyControlledComponent>
          </div>
        </div>
        <Button disable view={Button.VIEW.TERTIARY} className={styles.configureButton} onClick={handleConfigureClick}>
          {__('Configure')}
        </Button>
      </div>
      <Modal
        visible={isModalVisible}
        width={SIZES.L}
        bodyStyle={{ padding: 0 }}
        title={__('Search Options for Table')}
        submitBtnText={__('Submit')}
        onSubmit={handleSubmit}
        onCancel={handleCancelModal}
      >
        <SearchOptionsModal
          selectedModuleIndex={selectedModuleIndex}
          relationshipField={relationshipField}
          allFormValues={allFormValues}
          errors={errors}
          searchableFieldOptions={searchableFieldOptions}
          searchableFieldOptionsByRelationshipField={searchableFieldOptionsByRelationshipField}
          onAction={onAction}
        />
      </Modal>
    </div>
  );
};

SearchOptionsConfigurator.propTypes = {
  isModalVisible: PropTypes.bool,
  relationshipField: PropTypes.string,
  selectedModuleIndex: PropTypes.number,
  selectedViewComponentProperties: PropTypes.object,
  searchableFieldOptionsByRelationshipField: PropTypes.object,
  allFormValues: PropTypes.array,
  errors: PropTypes.array,
  searchableFieldOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

SearchOptionsConfigurator.defaultProps = {
  isModalVisible: false,
  selectedModuleIndex: undefined,
  relationshipField: undefined,
  searchableFieldOptionsByRelationshipField: EMPTY_OBJECT,
  selectedViewComponentProperties: EMPTY_OBJECT,
  allFormValues: EMPTY_ARRAY,
  searchableFieldOptions: EMPTY_ARRAY,
  errors: EMPTY_ARRAY,
};

export default withActions(EMPTY_OBJECT, ACTION_HANDLERS)(SearchOptionsConfigurator);
