import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import AttachmentList from '@tekion/tekion-components/molecules/AttachmentList';
import Icon from '@tekion/tekion-components/atoms/FontIcon';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import Button from '@tekion/tekion-components/atoms/Button';

import ImageThumbnails from './components/ImageThumbnails';
import PicSelector from './components/PicSelector/PicSelector';
import mediaUploaderHOC from './mediaUploaderHOC';
import { FILE_VIEW_TYPE } from './mediaUploader.constants';

import styles from './mediaUploader.module.scss';

const MediaUploader = (props) => {
  const {
    isUploading,
    isFetchingMedia,
    isMediaPreviewEnabled,
    multiple,
    viewOnly,
    disabled,
    isThumbnailGalleryControlsEnabled,
    isAvatarDisplay,
    showRefreshIcon,
    nextPrevButton,
    displayCount,
    avatarDisplayName,
    mode,
    className,
    thumbnailImageClassName,
    view,
    addMedia,
    validFormats,
    removeMedia,
    mediaList,
    onSelection,
    onRefresh,
    onDownloadButtonClick,
  } = props;

  // Download button will be visible only when media preview is not enabled
  const isDownloadButtonVisible = !isMediaPreviewEnabled && viewOnly && !_isEmpty(mediaList);

  const renderThumbnailView = () => (
    <div className={`${styles.dockAttachment} ${className}`}>
      {!viewOnly && <PicSelector getInputFiles={addMedia} multiple={multiple} disabled={disabled} validFormats={validFormats} />}
      {isMediaPreviewEnabled && (
        <ImageThumbnails
          viewOnly={viewOnly}
          isFetchingMedia={isFetchingMedia}
          isAvatarDisplay={isAvatarDisplay}
          avatarDisplayName={avatarDisplayName}
          mediaList={mediaList}
          displayCount={displayCount}
          removeMedia={removeMedia}
          mode={mode}
          thumbnailImageClassName={thumbnailImageClassName}
          onSelection={onSelection}
          nextPrevButton={nextPrevButton}
          isGalleryControlsEnabled={isThumbnailGalleryControlsEnabled}
        />
      )}

      <PropertyControlledComponent controllerProperty={!_isEmpty(mediaList) && showRefreshIcon && isMediaPreviewEnabled}>
        <Icon onClick={onRefresh} disabled={isUploading}>
          {isFetchingMedia ? 'icon-loading1' : 'icon-refresh'}
        </Icon>
      </PropertyControlledComponent>
    </div>
  );

  const renderListView = () => (
    <div className={`attachmentList ${className}`}>
      {isMediaPreviewEnabled && <AttachmentList mediaList={mediaList} displayCount={displayCount} removeMedia={removeMedia} disabled={disabled} />}
      <PicSelector
        getInputFiles={addMedia}
        multiple={multiple}
        disabled={disabled}
        onSelection={onSelection}
        validFormats={validFormats}
        nextPrevButton={nextPrevButton}
      />
    </div>
  );

  // Currently this will be visible when media preview is not enabled
  const renderDownloadButton = useCallback(
    () => (
      <Button onClick={onDownloadButtonClick} view={Button.VIEW.TERTIARY}>
        <Icon>icon-download1</Icon>
      </Button>
    ),
    [onDownloadButtonClick],
  );

  return (
    <>
      {view === FILE_VIEW_TYPE.LIST ? renderListView() : renderThumbnailView()}
      {isDownloadButtonVisible && renderDownloadButton()}
    </>
  );
};

MediaUploader.propTypes = {
  isUploading: PropTypes.bool,
  isFetchingMedia: PropTypes.bool,
  multiple: PropTypes.bool,
  isAvatarDisplay: PropTypes.bool,
  viewOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  showRefreshIcon: PropTypes.bool,
  nextPrevButton: PropTypes.bool,
  isThumbnailGalleryControlsEnabled: PropTypes.bool,
  isMediaPreviewEnabled: PropTypes.bool.isRequired,
  displayCount: PropTypes.number,
  avatarDisplayName: PropTypes.string,
  mode: PropTypes.string,
  view: PropTypes.string,
  validFormats: PropTypes.string,
  thumbnailImageClassName: PropTypes.string,
  className: PropTypes.string,
  mediaList: PropTypes.array,
  addMedia: PropTypes.func,
  removeMedia: PropTypes.func,
  onSelection: PropTypes.func,
  onRefresh: PropTypes.func,
  onDownloadButtonClick: PropTypes.func,
};

MediaUploader.defaultProps = {
  isUploading: false,
  isFetchingMedia: true,
  isThumbnailGalleryControlsEnabled: true,
  isAvatarDisplay: false,
  nextPrevButton: false,
  multiple: false,
  viewOnly: false,
  disabled: false,
  showRefreshIcon: true,
  displayCount: 1,
  mode: EMPTY_STRING,
  avatarDisplayName: EMPTY_STRING,
  validFormats: undefined,
  view: FILE_VIEW_TYPE.THUMBNAIL,
  thumbnailImageClassName: EMPTY_STRING,
  className: '',
  mediaList: EMPTY_ARRAY,
  addMedia: _noop,
  removeMedia: _noop,
  onSelection: _noop,
  onRefresh: _noop,
  onDownloadButtonClick: _noop,
};

export { MediaUploader };

export default mediaUploaderHOC(MediaUploader);
