import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

import List from '@tekion/tekion-components/molecules/List';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';
import { NO_DATA } from '@tekion/tekion-base/app.constants';

import { getEntityCreatedTime } from './metadataCard.helpers';

import { BUNDLE_STATE } from '../exportList/constants/exportList.general.constants';
import { STUDIO_ROUTE } from '../../../../constants/routes';
import PAGE_IDS from '../../constants/PageIds.constants';

import metaDataReader from '../../../../readers/metadataManagement.reader';

import styles from './metadataCardView.module.scss';

const MetadataCardView = ({ bundle, history }) => {
  const onEditClick = useCallback(() => {
    const bundleId = metaDataReader.id(bundle);
    if (!_isEmpty(bundleId)) {
      const path = `${STUDIO_ROUTE}/${PAGE_IDS.EXPORT_EDIT}/${bundleId}`;
      history.push({ pathname: path, state: { bundle } });
    }
  }, [bundle, history]);

  const state = metaDataReader.state(bundle);
  const bundleCreatedTime = useMemo(() => getEntityCreatedTime(metaDataReader.createdTime(bundle)), [bundle]);

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Display Name:')}</div>
        <List.Item.Meta description={metaDataReader.displayName(bundle) || NO_DATA} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Name:')}</div>
        <List.Item.Meta description={metaDataReader.name(bundle) || NO_DATA} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Description:')}</div>
        <List.Item.Meta description={metaDataReader.description(bundle) || NO_DATA} />
      </div>
      {state === BUNDLE_STATE.DRAFT && (
        <div className={styles.iconItem}>
          <FontIcon size={SIZES.MD_S} onClick={onEditClick} className="cursor-pointer m-r-32">
            icon-edit
          </FontIcon>
        </div>
      )}
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Created Time:')}</div>
        <List.Item.Meta description={bundleCreatedTime || NO_DATA} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Bundle State:')}</div>
        <List.Item.Meta description={metaDataReader.state(bundle) || NO_DATA} />
      </div>
      <div className={styles.item}>
        <div className={styles.subItem}>{__('Media ID:')}</div>
        <List.Item.Meta description={metaDataReader.mediaId(bundle) || NO_DATA} />
      </div>
    </div>
  );
};

MetadataCardView.propTypes = {
  bundle: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default MetadataCardView;
