const LOGIN_PROVIDERS = {
  OKTA_SAML: 'OKTA_SAML',
  TEKION: 'TEKION',
  OKTA_OPENID: 'OKTA_OPENID',
};

const CDN_PUBLIC_URL = `${process.env.TEKION_WEB_CDN_ASSET_DOMAIN}/DMS/common`;

const PROVIDER_IMAGES = {
  [LOGIN_PROVIDERS.OKTA_SAML]: `${CDN_PUBLIC_URL}/okta-logo.svg`,
};

const PROVIDERS_DISPLAY_NAME = {
  [LOGIN_PROVIDERS.OKTA_OPENID]: __('OpenId Connect'),
  [LOGIN_PROVIDERS.OKTA_SAML]: __('SAML'),
  [LOGIN_PROVIDERS.TEKION]: __('Tekion'),
};

export { PROVIDER_IMAGES, LOGIN_PROVIDERS, PROVIDERS_DISPLAY_NAME };
