import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';

import { updateGridViewConfigurationToAddPreAppliedFilters, updateGridViewConfigurationToAddSimilarViewDetails } from './gridViewOverrides';

import { VIEW_TYPES } from '../../../../../constants/viewBuilder.constants';

const getUpdatedViewConfig = (viewOverrides, viewConfig) => {
  const viewType = tget(viewConfig, 'viewType');
  const updatedViewConfig = viewConfig;

  switch (viewType) {
    case VIEW_TYPES.GRID_VIEW:
    case VIEW_TYPES.LIST_VIEW: {
      const { filterMetadataAndPreAppliedFilters = {}, similarViewDetails = {} } = viewOverrides;
      if (!_isEmpty(filterMetadataAndPreAppliedFilters)) {
        updateGridViewConfigurationToAddPreAppliedFilters(tget(updatedViewConfig, 'section', {}), filterMetadataAndPreAppliedFilters);
      }
      if (!_isEmpty(similarViewDetails)) {
        updateGridViewConfigurationToAddSimilarViewDetails(tget(updatedViewConfig, 'section', {}), similarViewDetails);
      }
      break;
    }
    default:
  }

  return updatedViewConfig;
};

export { getUpdatedViewConfig };
