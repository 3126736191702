import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import renameProps from 'recompose/renameProps';

import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import VIEW_DETAILS_FORM_ACTION_HANDLERS from './helpers/viewDetailsForm.actionHandlers';
import getFields from './helpers/viewDetailsForm.fields';

import VIEW_DETAILS_FORM_SECTIONS from './constants/viewDetailsForm.sections';
import FIELDS_FORM_FIELD_IDS from '../../constants/fieldsForm.fieldIds';

const ViewDetailsForm = (props) => {
  const { renderOptions, values, onAction } = props;
  const fields = useMemo(() => getFields(renderOptions), [renderOptions]);

  return (
    <FormWithSubmission
      className="full-width"
      contextId={FIELDS_FORM_FIELD_IDS.VIEW_DETAILS_FORM}
      values={values}
      fields={fields}
      sections={VIEW_DETAILS_FORM_SECTIONS}
      onAction={onAction}
    />
  );
};

ViewDetailsForm.propTypes = {
  renderOptions: PropTypes.object,
  values: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ViewDetailsForm.defaultProps = {
  renderOptions: EMPTY_OBJECT,
  values: EMPTY_OBJECT,
};

export default compose(
  renameProps({ onAction: 'fieldsFormOnAction', value: 'fieldsFormViewDetailsValue' }),
  withActions(EMPTY_OBJECT, VIEW_DETAILS_FORM_ACTION_HANDLERS),
)(ViewDetailsForm);
