import { defaultMemoize } from 'reselect';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _castArray from 'lodash/castArray';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { FIELD_IDS, RECORD_TYPE_FIELD_NAME } from '../constants/recordType.constants';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../organisms/conditionBuilder/constants/condition.fieldIds';

const getFieldOptions = defaultMemoize((entity, derivationFields) => {
  const fieldDefinitions = _get(entity, 'fieldDefinitions', EMPTY_ARRAY);
  return _map(fieldDefinitions, (item) => ({
    label: _get(item, 'displayName'),
    value: _get(item, 'name'),
    isDisabled: derivationFields.includes(_get(item, 'name')) || _get(item, 'name') === RECORD_TYPE_FIELD_NAME,
  }));
});

const getDerivationConditionValues = (recordTypeValues) => {
  const derivationCondition = _get(recordTypeValues, FIELD_IDS.DERIVATION_CONDITION, EMPTY_OBJECT);
  return {
    ...derivationCondition,
    [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: _map(_get(derivationCondition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST), (criteria) => ({
      ...criteria,
      [CONDITION_FIELD_IDS.FIELD]: _castArray(_get(criteria, CONDITION_FIELD_IDS.FIELD, [''])),
    })),
  };
};

const getPayloadCondition = (formValues) => {
  const derivationCondition = _get(formValues, FIELD_IDS.DERIVATION_CONDITION, EMPTY_OBJECT);

  return {
    ...derivationCondition,
    type: 'CRITERIA',
    [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: _map(_get(derivationCondition, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST), (criteria) => ({
      ...criteria,
      [CONDITION_FIELD_IDS.FIELD]: getArraySafeValue(_get(criteria, CONDITION_FIELD_IDS.FIELD)),
    })),
  };
};

export { getFieldOptions, getDerivationConditionValues, getPayloadCondition };
