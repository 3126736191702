import _castArray from 'lodash/castArray';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _pick from 'lodash/pick';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { isActionDefinitionRequired, isUpsertOrUpdateActionType } from './recordActionModal.helpers';

import CONDITION_BUILDER_FIELD_IDS from '../../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import {
  ACTION_BUILDER_ACTION_TYPES,
  ACTION_DEFINITION_OPERATOR_OPTIONS,
} from '../../../../../../actionBuilder/actionBuilderForm/constants/actionBuilderForm.general';
import { TEMPLATE_RENDERER_FIELD_IDS } from '../../../../../../../../../organisms/templateSection/templateRenderer/templateRenderer.constant';
import { ACTION_DEFINITION_FIELD_IDS, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS } from '../../../../../../../../../constants/actionBuilder.constants';
import { GET_RECORD_FIELD_IDS } from '../constants/recordActionModal.fieldIds';
import { ASSIGNED_VARIABLE_DATA_TYPES } from '../../../../constants/workflow.nodeVariableConstants';

const setActionDefinitionValues = (formValues, prefilledData) => {
  if (!_isEmpty(prefilledData)) {
    const actionDefinition = _map(prefilledData, (value, fieldName) => ({
      [CONDITION_FIELD_IDS.FIELD]: fieldName,
      [CONDITION_FIELD_IDS.OPERATOR]: getArraySafeValue(ACTION_DEFINITION_OPERATOR_OPTIONS)?.value,
      [CONDITION_FIELD_IDS.VALUES]: value,
    }));

    _set(formValues, ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION, {
      [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: actionDefinition,
    });
  }
};

const setFilterAndSortConditionValues = (formValues, targetDetails) => {
  const filterConditions = tget(targetDetails, ACTION_DEFINITION_FIELD_IDS.CONDITION);
  const sortConditions = tget(targetDetails, ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS);
  const recordsLimit = tget(targetDetails, ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT);

  _set(formValues, ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS, filterConditions);
  _set(formValues, ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS, !_isEmpty(sortConditions));
  _set(formValues, ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS, _isEmpty(sortConditions) ? [{}] : sortConditions);
  _set(formValues, ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT, recordsLimit);
};

const setVariableAssignmentInfo = (formValues, assignedVariable) => {
  if (!_isEmpty(assignedVariable)) {
    _set(formValues, ACTION_DEFINITION_FIELD_IDS.SHOULD_ASSIGN_VARIABLE, true);
    _set(formValues, ACTION_DEFINITION_FIELD_IDS.ASSIGNED_VARIABLE, _castArray(assignedVariable));
  } else {
    _set(formValues, ACTION_DEFINITION_FIELD_IDS.SHOULD_ASSIGN_VARIABLE, false);
  }
};

const convertApiSendEmailToFormValues = (userData) => {
  const sendEmailsFormData = _pick(userData, [
    SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.FROM,
    SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.TO,
    SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.BCC,
    SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.CC,
    SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.SENDER_DISPLAY_NAME,
  ]);

  const apiTemplateValue = tget(userData, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES, EMPTY_OBJECT);
  const templateFormValue = _map(apiTemplateValue, (templateValue, key) => ({
    [TEMPLATE_RENDERER_FIELD_IDS.SELECTED_TEMPLATE]: key,
    [TEMPLATE_RENDERER_FIELD_IDS.MAP_OF_VARIABLES]: templateValue,
  }));

  _set(sendEmailsFormData, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES, templateFormValue);
  return sendEmailsFormData;
};

const getFormValues = (userData, initialValues) => {
  const formValues = initialValues;

  if (_isEmpty(userData)) {
    return formValues;
  }

  const actionType = tget(userData, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE);
  const actionDisplayName = tget(userData, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME);
  const targetEntityName = tget(userData, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME);
  const prefilledData = tget(userData, ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION);
  const updateDetails = tget(userData, ACTION_DEFINITION_FIELD_IDS.RECORD_TO_UPDATE);
  const searchRequest = tget(userData, ACTION_DEFINITION_FIELD_IDS.SEARCH_REQUEST);
  const updateSameRecord = tget(userData, ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD);
  const assignedVariable = tget(userData, ACTION_DEFINITION_FIELD_IDS.ASSIGNED_VARIABLE);
  const recordCount = tget(userData, GET_RECORD_FIELD_IDS.RECORD_COUNT_FIELD_ID, ASSIGNED_VARIABLE_DATA_TYPES.MULTIPLE);

  if (!_isEmpty(actionDisplayName)) {
    _set(formValues, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME, actionDisplayName);
  }

  if (!_isEmpty(targetEntityName)) {
    _set(formValues, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME, _castArray(targetEntityName));
  }

  if (actionType === ACTION_BUILDER_ACTION_TYPES.SEND_EMAIL) {
    _set(formValues, ACTION_DEFINITION_FIELD_IDS.SEND_EMAILS_ACTION_DEFINITION, convertApiSendEmailToFormValues(userData));
  }

  if (isActionDefinitionRequired(actionType)) {
    setActionDefinitionValues(formValues, prefilledData);
  }

  if (isUpsertOrUpdateActionType(actionType)) {
    setFilterAndSortConditionValues(formValues, updateDetails);
  } else if (actionType === ACTION_BUILDER_ACTION_TYPES.GET_RECORDS) {
    _set(formValues, GET_RECORD_FIELD_IDS.RECORD_COUNT_FIELD_ID, recordCount);
    setFilterAndSortConditionValues(formValues, searchRequest);
  }
  _set(formValues, ACTION_DEFINITION_FIELD_IDS.UPDATE_SAME_RECORD, updateSameRecord);

  setVariableAssignmentInfo(formValues, assignedVariable);

  return formValues;
};

export { getFormValues };
