import _split from 'lodash/split';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _forEach from 'lodash/forEach';
import _toLower from 'lodash/toLower';
import _toUpper from 'lodash/toUpper';
import _cloneDeep from 'lodash/cloneDeep';

import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import { FIELD_IDS } from './metadataComponentForm.field';

import { COMPONENT_TYPE_OPTIONS_VALUES } from '../constants/metadataComponentForm.general.constants';

const getLabelForNameField = (componentType) => {
  let componentTypeLabel = '';

  if (componentType) componentTypeLabel = componentType;
  componentTypeLabel = _toLower(getArraySafeValue(_split(componentTypeLabel, '_', 2)));
  const newComponentTypeLabel = _toUpper(componentTypeLabel.charAt(0)) + componentTypeLabel.slice(1);

  return newComponentTypeLabel;
};

const getPayloadForApi = (componentData) => {
  const componentType = getArraySafeValue(_get(componentData, 'componentType', EMPTY_ARRAY));

  const newComponentData = _cloneDeep(componentData);

  if (componentType === COMPONENT_TYPE_OPTIONS_VALUES.ENTITY_DEFINITION) {
    _forEach(newComponentData, (value, key) => {
      if (key === FIELD_IDS.ENTITY_NAME) {
        _set(newComponentData, FIELD_IDS.NAME, getArraySafeValue(value));
      }

      _set(newComponentData, key, getArraySafeValue(value));
    });
    _unset(newComponentData, FIELD_IDS.ENTITY_NAME);

    return newComponentData;
  }

  _forEach(newComponentData, (value, key) => {
    if (key !== FIELD_IDS.NAME) {
      _set(newComponentData, key, getArraySafeValue(value));
    }
  });
  return newComponentData;
};

export { getLabelForNameField, getPayloadForApi };
