import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import FIELD_IDS from '../constants/workspaceUserForm.fieldIds';
import { FIELD_IDS as ROLES_FIELD_IDS } from '../../../../../../constants/roles.constants';
import workspaceUserReader from '../../../../../../readers/workSpaceUser.reader';
import profileReader from '../../../../../../readers/profile.reader';

const mapPhoneDataToApiFormat = (userFormValues) => {
  const phoneNo = _get(userFormValues, FIELD_IDS.PHONE);
  const phone = _get(phoneNo, 'phoneNumber', EMPTY_STRING);
  const countrycode = _get(phoneNo, 'phoneCode', EMPTY_STRING);

  return { phone, countrycode };
};

const mapUserDataToApiFormat = (userFormValues) => {
  const name = _get(userFormValues, FIELD_IDS.NAME);
  const firstname = _get(name, FIELD_IDS.FIRST_NAME);
  const lastname = _get(name, FIELD_IDS.LAST_NAME);
  const profilepicture = _get(userFormValues, FIELD_IDS.PROFILE_PICTURE);
  const email = _get(userFormValues, FIELD_IDS.EMAIL);
  const { phone, countrycode } = mapPhoneDataToApiFormat(userFormValues);
  const profilename = getArraySafeValue(_get(userFormValues, `${FIELD_IDS.PROFILE_NAME}`));
  const rolename = getArraySafeValue(_get(userFormValues, `${FIELD_IDS.ROLE}`, [null]));
  const displayname = _get(userFormValues, FIELD_IDS.DISPLAY_NAME);

  const finalObject = {
    name: 'user',
    recordTypeName: 'user',
    entity: {
      firstname,
      lastname,
      profilepicture,
      email,
      phone,
      countrycode,
      rolename,
      profilename,
      displayname,
    },
  };

  return finalObject;
};

const mapApiDataToForm = (params) => {
  let userFormValues = {};
  userFormValues = {
    ...userFormValues,
    [FIELD_IDS.NAME]: { firstName: workspaceUserReader.firstName(params), lastName: workspaceUserReader.lastName(params) },
    [FIELD_IDS.ROLE]: _castArray(workspaceUserReader.roleName(params)),
    [FIELD_IDS.PHONE]: { phoneNumber: workspaceUserReader.phone(params), phoneCode: workspaceUserReader.countryCode(params) },
    [FIELD_IDS.COUNTRY_CODE]: workspaceUserReader.countryCode(params),
    [FIELD_IDS.PROFILE_NAME]: workspaceUserReader.profileName(params),
    [FIELD_IDS.EMAIL]: workspaceUserReader.email(params),
    [FIELD_IDS.ACTIVE]: workspaceUserReader.active(params),
    [FIELD_IDS.DISPLAY_NAME]: workspaceUserReader.displayName(params),
    [FIELD_IDS.PROFILE_PICTURE]: workspaceUserReader.profilePicture(params),
  };

  return {
    userFormValues,
  };
};

const getRoleOptions = (data, roleOptions) => {
  if (_isEmpty(data)) return;
  const roleData = _get(data, 'role');
  const label = _get(roleData, ROLES_FIELD_IDS.DISPLAY_NAME, EMPTY_STRING);
  const value = _get(roleData, ROLES_FIELD_IDS.ROLE_NAME);
  roleOptions.push({ label, value });

  const childRoles = _get(data, 'childRoles');
  _forEach(childRoles, (child) => {
    getRoleOptions(child, roleOptions);
  });
};
const getOptionsForProfile = (profileList) =>
  _map(profileList, (profile) => ({
    label: profileReader.displayName(profile),
    value: profileReader.name(profile),
  }));

export { mapPhoneDataToApiFormat, mapUserDataToApiFormat, mapApiDataToForm, getRoleOptions, getOptionsForProfile };
