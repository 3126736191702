import _castArray from 'lodash/castArray';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import TextInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput/TextInput';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import ValueField from '../../../../../../../../organisms/conditionBuilder/atoms/valueField/ValueField';
import AsyncPaginatedSelect from '../../../../../../../../organisms/asyncPaginatedSelect/AsyncPaginatedSelect';
import ConditionBuilder from '../../../../../../../../organisms/conditionBuilder/ConditionBuilder';

import { fetchEntities } from '../../../../../../../../actions/entityManagement.actions';

import { getEntityOptions, getEntityPayload } from './loopNodeModal.helpers';

import { isLastFieldMultiValuedValidatorRule } from '../../../utils/validators';

import {
  CONDITION_BUILDER_FIELD_IDS,
  CONDITION_BUILDER_MODES,
  CONDITION_BUILDER_TYPES,
  CONDITION_FIELD_IDS,
  isConditionRequiredRule,
} from '../../../../../../../../organisms/conditionBuilder';
import { VALUE_FIELD_MODES } from '../../../../../../../../organisms/conditionBuilder/atoms/valueField/valueField.constants';
import { FILTER_CONDITION_VARIABLE_NAME } from '../../../../../actionBuilder/actionBuilderForm/constants/actionBuilderForm.general';

import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../../../constants/actionBuilder.constants';
import LOOP_NODE_FIELD_IDS from '../../../constants/nodeDataFieldIds/loopNodeFieldIds';

import styles from './loopNodeModal.module.scss';

const LOOP_VARIABLE_NAME_FIELD = {
  id: LOOP_NODE_FIELD_IDS.LOOP_VARIABLE_NAME,
  renderer: TextInputField,
  renderOptions: {
    label: __('Name for loop variable'),
    required: true,
    validators: [isRequiredRule],
    placeholder: __('Enter here'),
    helpText: __('Define an iteration variable to use within the loop'),
    helpTextClassName: styles.helpText,
  },
};

const LOOP_NAME_FIELD = {
  id: LOOP_NODE_FIELD_IDS.LOOP_NAME,
  renderer: TextInputField,
  renderOptions: {
    size: 6,
    label: __('Loop Name'),
    required: true,
    validators: [isRequiredRule],
    placeholder: __('Enter Here'),
  },
};

const ENTITY_FIELD = {
  id: LOOP_NODE_FIELD_IDS.ENTITY_NAME,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    required: true,
    validators: [isRequiredRule],
    label: __('Entity'),
    size: 6,
    serviceHandler: fetchEntities,
    getOptions: getEntityOptions,
    getPayload: getEntityPayload,
  },
};

const FORM_FIELDS = {
  [LOOP_NODE_FIELD_IDS.LOOP_VARIABLE_NAME]: LOOP_VARIABLE_NAME_FIELD,
  [LOOP_NODE_FIELD_IDS.ENTITY_NAME]: ENTITY_FIELD,
  [LOOP_NODE_FIELD_IDS.LOOP_NAME]: LOOP_NAME_FIELD,
};

const getFieldConfig = (conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, mapOfVariableToEntityNameForFilterCondition) => ({
  ...FORM_FIELDS,
  [LOOP_NODE_FIELD_IDS.LIST_TO_LOOP_OVER]: {
    id: LOOP_NODE_FIELD_IDS.LIST_TO_LOOP_OVER,
    renderer: ValueField,
    renderOptions: {
      required: true,
      label: __('List to loop over'),
      validators: [isRequiredRule, isLastFieldMultiValuedValidatorRule(conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName)],
      placeholder: __('Select List'),
      helpText: __('Select the list to iterate over'),
      showFieldSwitcher: false,
      initialFieldSwitcherMode: VALUE_FIELD_MODES.FIELD,
      conditionBuilderFieldDefinitionObject,
      mapOfVariableToEntityName,
    },
  },
  [ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS]: {
    id: ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS,
    renderer: ConditionBuilder,
    renderOptions: {
      containerClassname: fieldLayoutStyles.fieldC,
      header: __('Filter conditions'),
      mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
      conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
      validators: [isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)],
      required: true,
      childProps: {
        [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
          label: '',
          childProps: {
            [CONDITION_FIELD_IDS.FIELD]: {
              label: __('Field'),
              isVariablePrefixNeeded: false,
              variableNameForPrefixNotNeeded: FILTER_CONDITION_VARIABLE_NAME,
              mapOfVariableToEntityName: mapOfVariableToEntityNameForFilterCondition,
              conditionBuilderFieldDefinitionObject,
            },
            [CONDITION_FIELD_IDS.OPERATOR]: {
              label: __('Operator'),
              mapOfVariableToEntityName: mapOfVariableToEntityNameForFilterCondition,
              conditionBuilderFieldDefinitionObject,
              isVariablePrefixNeeded: false,
              variableNameForPrefixNotNeeded: FILTER_CONDITION_VARIABLE_NAME,
            },
            [CONDITION_FIELD_IDS.VALUES]: {
              showFieldSwitcher: true,
              label: __('Value'),
              mapOfVariableToEntityName: { ...mapOfVariableToEntityName, ...mapOfVariableToEntityNameForFilterCondition },
              conditionBuilderFieldDefinitionObject,
              isResourceFieldExcluded: false,
              isVariablePrefixNeeded: false,
              keepAttributeAsArray: true,
              variableNameForPrefixNotNeeded: FILTER_CONDITION_VARIABLE_NAME,
              attributeExcludeVariableNames: _castArray(FILTER_CONDITION_VARIABLE_NAME),
            },
          },
        },
      },
    },
  },
});

export default getFieldConfig;
