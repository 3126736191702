import React, { useEffect, useMemo } from 'react';
import compose from 'recompose/compose';
import renameProps from 'recompose/renameProps';
import PropTypes from 'prop-types';

import withActions from '@tekion/tekion-components/connectors/withActions';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import getFields from './helpers/generalDetailsForm.fields';
import getSections from './helpers/generalDetailsForm.sections';
import GENERAL_DETAILS_FORM_ACTION_HANDLERS from './helpers/generalDetailsForm.actionHandlers';

import ACTION_TYPES from '../../constants/fieldsForm.actionTypes';
import FIELDS_FORM_FIELD_IDS from '../../constants/fieldsForm.fieldIds';

const GeneralDetailsForm = ({ fieldsFormGeneralDetailsValues, renderOptions, values, error, onAction }) => {
  const fields = useMemo(() => getFields(fieldsFormGeneralDetailsValues, renderOptions), [fieldsFormGeneralDetailsValues, renderOptions]);
  const sections = useMemo(() => getSections(values), [values]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.ON_RENDER_OPTIONS_CHANGE, payload: { renderOptions } });
  }, [renderOptions, onAction]);

  return (
    <FormWithSubmission
      className="full-width"
      contextId={FIELDS_FORM_FIELD_IDS.GENERAL_DETAILS_FORM}
      values={values}
      errors={error}
      fields={fields}
      sections={sections}
      onAction={onAction}
    />
  );
};

GeneralDetailsForm.propTypes = {
  fieldsFormGeneralDetailsValues: PropTypes.object,
  renderOptions: PropTypes.object,
  values: PropTypes.object,
  error: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

GeneralDetailsForm.defaultProps = {
  fieldsFormGeneralDetailsValues: EMPTY_OBJECT,
  renderOptions: EMPTY_OBJECT,
  values: EMPTY_OBJECT,
  error: EMPTY_OBJECT,
};

export default compose(
  renameProps({ onAction: 'fieldsFormOnAction', value: 'fieldsFormGeneralDetailsValues' }),
  withActions(EMPTY_OBJECT, GENERAL_DETAILS_FORM_ACTION_HANDLERS),
)(GeneralDetailsForm);
