import ROUTES from '../../../constants/routes';

const COLUMN_IDS = {
  APPLICATION_NAME: 'name',
  DISPLAY_NAME: 'displayName',
  DESCRIPTION: 'description',
};

const TABLE_MANAGER_PROPS = {
  showFilter: false,
  showHeader: false,
  showSearch: false,
  showSubHeader: true,
  showRefreshIcon: false,
};

const ROWS = 40;

const MODAL_DIRECTION = {
  LEFT: 'left',
  RIGHT: 'right',
};

const APPLICATION_CHECKBOX_LENGTH = 33;
const APPLICATION_CONTAINER_LENGTH = 321;

const PROJECT_NAVIGATION_DEFAULT_DATA = [{ label: __('Projects'), goTo: ROUTES.PROJECT_LIST_ROUTE, key: '1' }];

export {
  COLUMN_IDS,
  TABLE_MANAGER_PROPS,
  ROWS,
  PROJECT_NAVIGATION_DEFAULT_DATA,
  APPLICATION_CHECKBOX_LENGTH,
  APPLICATION_CONTAINER_LENGTH,
  MODAL_DIRECTION,
};
