import _castArray from 'lodash/castArray';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _pickBy from 'lodash/pickBy';
import _concat from 'lodash/concat';
import _isNil from 'lodash/isNil';
import _omit from 'lodash/omit';
import _head from 'lodash/head';
import _pick from 'lodash/pick';
import _find from 'lodash/find';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _map from 'lodash/map';

// Utils
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

// Constants
import { EMPTY_OBJECT, EMPTY_STRING, EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import FIELD_TYPES from '../../../../../../../constants/fieldDefinition.fieldTypes';
import DATA_TYPES from '../../../../../../../constants/fieldDefinition.dataTypes';
import FIELD_IDS from '../constants/approvalSettingFieldForm.fieldIds';
import { CONSTRAINTS_TYPES, FIELD_DETAILS_FIELD_IDS, DETAILS_FIELD_IDS, COLUMNS } from '../constants/approvalSettingFieldForm.constants';

//  -----------------  Get Payload form Value ---------------------- //

const getTextConstraintPayload = (propertyValues) => {
  const payload = {
    constraints: [
      {
        type: CONSTRAINTS_TYPES.STRING_LENGTH,
        minLength: _get(propertyValues, FIELD_IDS.TEXT_MIN_LENGTH, -1),
        maxLength: _get(propertyValues, FIELD_IDS.TEXT_MAX_LENGTH, -1),
      },
    ],
  };

  const regexPattern = _get(propertyValues, FIELD_IDS.TEXT_REGEX, EMPTY_STRING);

  if (!_isEmpty(regexPattern)) {
    _set(
      payload,
      'constraints',
      _concat(payload.constraints, [
        {
          type: CONSTRAINTS_TYPES.REGEX,
          pattern: regexPattern,
        },
      ]),
    );
  }
  return payload;
};

const getDropDownPayload = (propertyValues) => {
  const listOptions = _get(propertyValues, FIELD_IDS.LIST_INPUT_TABLE, EMPTY_ARRAY);
  let optionConfig = {
    options: _map(listOptions, (option) => ({
      displayName: _get(option, 'label', ''),
      value: _get(option, 'value', ''),
    })),
  };

  if (_get(propertyValues, FIELD_IDS.CONTROLLING_FUNCTION, false)) {
    let controllingOptionsConfigs = _map(_get(propertyValues, FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS, EMPTY_ARRAY), (item) => ({
      controlledOption: getArraySafeValue(_get(item, COLUMNS.OPTION)),
      controllingOptions: _get(item, COLUMNS.VALUES),
    }));
    controllingOptionsConfigs = _filter(controllingOptionsConfigs, (item) => !_isEmpty(_get(item, 'controllingOptions')));
    optionConfig = {
      ...optionConfig,
      controllingFieldName: getArraySafeValue(_get(propertyValues, FIELD_IDS.CONTROLLING_FIELD_NAME)),
      controllingOptionsConfigs,
    };
  }
  return { optionConfig };
};

const getDataTypeValue = (formValues, options) => {
  const fieldType = _head(_get(formValues, FIELD_IDS.FIELD_TYPE, EMPTY_ARRAY));
  let dataTypeValue = formValues[FIELD_IDS.DATA_TYPE];
  if (fieldType === FIELD_TYPES.RELATIONSHIP && !_isNil(options)) {
    const selectedEntity = _head(_get(formValues, FIELD_IDS.LOOKUP_ENTITY, EMPTY_ARRAY));
    const selectedField = _head(_get(formValues, FIELD_IDS.LOOKUP_FIELD, EMPTY_ARRAY));
    const lookupFieldOptions = _get(options, `lookupFieldOptions.${selectedEntity}`, EMPTY_ARRAY);
    dataTypeValue = _get(_find(lookupFieldOptions, ['value', selectedField]), 'dataType', '');
  }
  return { [FIELD_IDS.DATA_TYPE]: getArraySafeValue(dataTypeValue) };
};

const getFormDetailsValue = (formValues) =>
  _pickBy(formValues, (value, key) => {
    if (_includes(FIELD_DETAILS_FIELD_IDS, key) && value) return true;
    return false;
  });

const getPropertyPayload = (formValues) => {
  const fieldType = getArraySafeValue(_get(formValues, FIELD_IDS.FIELD_TYPE, EMPTY_ARRAY));
  const dataType = getArraySafeValue(_get(formValues, FIELD_IDS.DATA_TYPE, EMPTY_ARRAY));

  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      if (dataType === DATA_TYPES.TEXT) return getTextConstraintPayload(formValues);
      if (dataType === DATA_TYPES.NUMBER) return formValues;
      if (dataType === DATA_TYPES.DATE) return formValues;
      return EMPTY_OBJECT;
    case FIELD_TYPES.LIST:
      if (dataType === DATA_TYPES.TEXT) return getTextConstraintPayload(formValues);
      if (dataType === DATA_TYPES.NUMBER) return formValues;
      if (dataType === DATA_TYPES.DATE) return formValues;
      return EMPTY_OBJECT;
    case FIELD_TYPES.RANGE:
      if (dataType === DATA_TYPES.NUMBER) return formValues;
      return EMPTY_OBJECT;
    case FIELD_TYPES.SELECT:
      return getDropDownPayload(formValues);
    default:
      return EMPTY_OBJECT;
  }
};

const getPayloadFromValues = (formValues, options) => {
  const propertyPayload = getPropertyPayload(formValues);
  const fieldType = getArraySafeValue(_get(formValues, FIELD_IDS.FIELD_TYPE, EMPTY_ARRAY));

  const formDetailsValues = getFormDetailsValue(formValues);
  const dataType = getDataTypeValue(formValues, options);

  let payload = {
    ...formValues,
    ...propertyPayload,
    ...formDetailsValues,
    ...dataType,
    [FIELD_IDS.FIELD_TYPE]: getArraySafeValue(formValues[FIELD_IDS.FIELD_TYPE]),
    [FIELD_IDS.DEPENDENCY_CONFIG]: _map(_get(formValues, FIELD_IDS.DEPENDENCY_CONFIG, EMPTY_ARRAY), (item) => ({
      fieldName: getArraySafeValue(_get(item, COLUMNS.FIELD_NAME, [''])),
      dependencyType: getArraySafeValue(_get(item, COLUMNS.DEPENDENCY_TYPE, [''])),
    })),
  };

  payload = _omit(payload, [FIELD_IDS.GROUPS_ALLOWED]);

  if (fieldType === FIELD_TYPES.SELECT) {
    payload = _omit(payload, [
      FIELD_IDS.LIST_INPUT_TABLE,
      FIELD_IDS.CONTROLLING_FIELD_NAME,
      FIELD_IDS.CONTROLLING_FUNCTION,
      FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS,
    ]);
  }

  return payload;
};

// --------------  Get Values from Payload ------------------- //

const getTextValuesFromPayload = (fieldData) => {
  const constraints = _get(fieldData, 'constraints', EMPTY_ARRAY);
  const lengthContraints = _find(constraints, (obj) => _get(obj, 'type', '') === CONSTRAINTS_TYPES.STRING_LENGTH);
  const regexConstraints = _find(constraints, (obj) => _get(obj, 'type', '') === CONSTRAINTS_TYPES.REGEX);
  const textValues = {};
  const minLength = _get(lengthContraints, 'minLength', '');
  const maxLength = _get(lengthContraints, 'maxLength', '');
  const regex = _get(regexConstraints, 'pattern', '');
  if (!_isNil(minLength)) _set(textValues, FIELD_IDS.TEXT_MIN_LENGTH, minLength);
  if (!_isNil(maxLength)) _set(textValues, FIELD_IDS.TEXT_MAX_LENGTH, maxLength);
  if (!_isEmpty(regex)) _set(textValues, FIELD_IDS.TEXT_REGEX, regex);
  return textValues;
};

const getDropDownValuesFromPayload = (propertyValues) => {
  const optionConfigValues = _get(propertyValues, 'optionConfig', EMPTY_ARRAY);
  const listOptions = _get(optionConfigValues, 'options', EMPTY_ARRAY);
  let values = {
    [FIELD_IDS.LIST_INPUT_TABLE]: _map(listOptions, (option) => ({
      label: _get(option, 'displayName', ''),
      value: _get(option, 'value', ''),
    })),
  };
  if (_get(optionConfigValues, FIELD_IDS.CONTROLLING_FIELD_NAME)) {
    values = {
      ...values,
      [FIELD_IDS.CONTROLLING_FUNCTION]: true,
      [FIELD_IDS.CONTROLLING_FIELD_NAME]: _castArray(_get(optionConfigValues, FIELD_IDS.CONTROLLING_FIELD_NAME)),
      [FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS]: _map(_get(optionConfigValues, FIELD_IDS.CONTROLLING_OPTIONS_CONFIGS), (item) => ({
        [COLUMNS.OPTION]: _castArray(_get(item, 'controlledOption')),
        [COLUMNS.VALUES]: _get(item, 'controllingOptions'),
      })),
    };
  }
  return values;
};

const getPropertyValues = (fieldData) => {
  const fieldType = _get(fieldData, FIELD_IDS.FIELD_TYPE, '');
  const dataType = _get(fieldData, FIELD_IDS.DATA_TYPE, '');
  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      if (dataType === DATA_TYPES.TEXT) return getTextValuesFromPayload(fieldData);
      return EMPTY_OBJECT;
    case FIELD_TYPES.LIST:
      if (dataType === DATA_TYPES.TEXT) return getTextValuesFromPayload(fieldData);
      return EMPTY_OBJECT;
    case FIELD_TYPES.SELECT:
      return getDropDownValuesFromPayload(fieldData);
    default:
      return EMPTY_OBJECT;
  }
};

const getPropertyValuesFromPayload = (fieldData) => {
  const values = getPropertyValues(fieldData);
  const fieldDetailsValues = _pick(fieldData, FIELD_DETAILS_FIELD_IDS);
  const propertyValues = {
    ...values,
    ...fieldDetailsValues,
  };
  return propertyValues;
};

const getValuesFromPayload = (fieldData) => _pick(fieldData, DETAILS_FIELD_IDS);

export { getPayloadFromValues, getPropertyValuesFromPayload, getValuesFromPayload };
