import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';

import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import {
  getSubscriptionConfigLogsStatus,
  fetchSubscriptionConfigLogs,
  disableSubscriptionConfigLog,
  enableSubscriptionConfigLog,
  searchSubscriptionConfigLogsByTime,
  getSubscriptionConfigByName,
} from '../../../../../../actions/subscriptionConfig.actions';

import { getPayloadForConfigLog } from './subscriptionConfigLogs.helpers';

import { ACTION_TYPES } from '../constants/subscriptionConfigLogs.general';

const handleInit = async ({ getState, setState, params }) => {
  const { match, isFilteringLogsByTime = false, selectedFilters = [] } = getState();
  const isInitialFetchLoading = tget(params, 'isInitialFetchLoading', false);
  const subscriptionConfigName = tget(match, 'params.subscriptionConfigName');

  setState({ isLoading: true, isInitialFetchLoading });

  if (isInitialFetchLoading) {
    const subscriptionConfigData = await getSubscriptionConfigByName(subscriptionConfigName);
    setState({ subscriptionConfigDisplayName: tget(subscriptionConfigData, 'displayName') });
  }

  const subscriptionConfigLogStatusData = await getSubscriptionConfigLogsStatus(subscriptionConfigName);
  const isLogEnabled = tget(subscriptionConfigLogStatusData, 'loggingEnabled', false);
  let subscriptionConfigLogs = [];

  if (isLogEnabled) {
    if (isFilteringLogsByTime) {
      const subscriptionConfigLogPayload = getPayloadForConfigLog(selectedFilters);
      subscriptionConfigLogs = await searchSubscriptionConfigLogsByTime(subscriptionConfigName, subscriptionConfigLogPayload);
    } else {
      subscriptionConfigLogs = await fetchSubscriptionConfigLogs(subscriptionConfigName);
    }
  }

  setState({
    isLoading: false,
    isLogEnabled,
    isInitialFetchLoading: false,
    subscriptionConfigLogs,
  });
};

const handleChangeConfigLogStatus = async ({ getState, setState }) => {
  const { isLogEnabled, match } = getState();

  const subscriptionConfigName = tget(match, 'params.subscriptionConfigName');
  setState({ isLogStatusChanging: true });
  if (isLogEnabled) {
    const logStatusResponse = await disableSubscriptionConfigLog(subscriptionConfigName);
    setState({ isLogEnabled: tget(logStatusResponse, 'loggingEnabled', false) });
  } else {
    const logStatusResponse = await enableSubscriptionConfigLog(subscriptionConfigName);
    setState({ isLogEnabled: tget(logStatusResponse, 'loggingEnabled', false) });
  }
  setState({ isLogStatusChanging: false });
};

const handleFilterChange = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ selectedFilters: value, isFilteringLogsByTime: !_isEmpty(value) }, () => handleInit({ getState, setState }));
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_FETCH_LOGS]: handleInit,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleInit,
  [ACTION_TYPES.ON_CHANGE_CONFIG_LOG_STATUS]: handleChangeConfigLogStatus,
  [ACTION_TYPES.ON_SET_FILTERS]: handleFilterChange,
};

export default ACTION_HANDLERS;
