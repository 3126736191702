const TOTAL_COUNT = 'totalCount';

const CONTEXT_ID = 'REPEAT_MODAL_FORM';

const FIELD_IDS = {
  TOTAL_COUNT,
};

const MAX_INPUT_RANGE = 50;
const MIN_INPUT_RANGE = 0;

export { CONTEXT_ID, FIELD_IDS, MAX_INPUT_RANGE, MIN_INPUT_RANGE };
