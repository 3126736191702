import _get from 'lodash/get';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import PageComponent from '../components/pageComponent/PageComponent';
import NavigationComponent from '../components/navigationComponent/NavigationComponent';
import { FIELD_IDS } from '../constants/navigationProperties.constants';

const PAGE_NAME_FIELD = {
  id: FIELD_IDS.PAGE_NAME,
  renderer: PageComponent,
  renderOptions: {
    required: true,
  },
};

const NAVIGATION_COMPONENT_FIELD = {
  id: FIELD_IDS.NAVIGATION_PROPERTIES,
  renderer: NavigationComponent,
};

const FORM_FIELDS = {
  [FIELD_IDS.NAVIGATION_PROPERTIES]: NAVIGATION_COMPONENT_FIELD,
  [FIELD_IDS.PAGE_NAME]: PAGE_NAME_FIELD,
};

const getFields = (formValues) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.PAGE_NAME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.PAGE_NAME], [
    { path: 'isDisabled', value: _get(formValues, `${FIELD_IDS.NAVIGATION}`, false) },
    { path: 'required', value: !_get(formValues, `${FIELD_IDS.NAVIGATION}`, false) },
  ]),
  [FIELD_IDS.NAVIGATION_PROPERTIES]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.NAVIGATION_PROPERTIES], [
    { path: 'navigationPropertiesValues', value: formValues },
  ]),
});

export { getFields };
