import TextRenderer from '@tekion/tekion-components/molecules/CellRenderers/TextRenderer';

const COLUMN_IDS = {
  DISPLAY_NAME: 'displayName',
  DESCRIPTION: 'description',
};

const APPROVER_TYPES_FOR_TAGS = {
  USER: 'USER',
  USER_GROUP: 'USER_GROUP',
  ROLE: 'ROLE',
};

const COLUMN_CONFIG = [
  {
    Header: __('Display Name'),
    accessor: COLUMN_IDS.DISPLAY_NAME,
    id: COLUMN_IDS.DISPLAY_NAME,
    Cell: TextRenderer,
  },
  {
    accessor: COLUMN_IDS.DESCRIPTION,
    id: COLUMN_IDS.DESCRIPTION,
    Cell: TextRenderer,
  },
];

export { COLUMN_CONFIG, APPROVER_TYPES_FOR_TAGS };
