/* eslint-disable import/order */
import { useEffect } from 'react';
import { ReactEditor } from 'slate-react';
import _isEqual from 'lodash/isEqual';

// events
import linkSelectionPopupEvent from '../helpers/link.popupEvent';

// constants
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

const makeHandleUpdateCurrentLink =
  ({ linkNodeElement, selectedLinkNode, editor, setShowPopover }) =>
  () => {
    const currentNodePath = ReactEditor.findPath(editor, linkNodeElement);
    const [, selectedLinkNodePath] = selectedLinkNode || EMPTY_ARRAY;
    if (_isEqual(selectedLinkNodePath, currentNodePath)) {
      setShowPopover(true);
    }
  };

function useLinkSelectionPopupEvent({ setShowPopover, linkNodeElement, editor, selectedLinkNode }) {
  useEffect(() => {
    const handleUpdateCurrentLink = makeHandleUpdateCurrentLink({
      linkNodeElement,
      selectedLinkNode,
      editor,
      setShowPopover,
    });
    linkSelectionPopupEvent.on(`SHOW_LINK_SELECTION_POPUP_${editor.id}`, handleUpdateCurrentLink);
    return () => {
      linkSelectionPopupEvent.off(`SHOW_LINK_SELECTION_POPUP_${editor.id}`, handleUpdateCurrentLink);
    };
  }, [linkNodeElement, selectedLinkNode, editor, setShowPopover]);
}

export default useLinkSelectionPopupEvent;
