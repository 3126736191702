import { APPROVAL_CENTRE_FIELD_IDS } from '../../../../../../constants/approvalCentre.constants';

const FIELD_IDS = {
  NAME: APPROVAL_CENTRE_FIELD_IDS.NAME,
  GROUP: APPROVAL_CENTRE_FIELD_IDS.GROUP,
  CATEGORY: APPROVAL_CENTRE_FIELD_IDS.CATEGORY,
  TYPE: APPROVAL_CENTRE_FIELD_IDS.TYPE,
  DATA: APPROVAL_CENTRE_FIELD_IDS.DATA,
  COMMENT: APPROVAL_CENTRE_FIELD_IDS.COMMENT,
  PROCESS_ID: APPROVAL_CENTRE_FIELD_IDS.PROCESS_ID,
  CUSTOM_ENTITY_REQUEST_FIELD_IDS: {
    ID: 'id',
    ACTION_TYPE: APPROVAL_CENTRE_FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.ACTION_TYPE,
    CUSTOM_ENTITY_REQUEST: APPROVAL_CENTRE_FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.CUSTOM_ENTITY_REQUEST,
  },
};

export default FIELD_IDS;
