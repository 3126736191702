import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactGridLayout from 'react-grid-layout';

import _valuesIn from 'lodash/valuesIn';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _noop from 'lodash/noop';

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import WidgetComponent from '../../atoms/widgetComponent/WidgetComponent';
import { getLayouts, getWidgetReference } from '../../helpers/visualBuilder.helper';
import { getUpdatedPageContainers } from './visualBuilderGridLayout.helpers';
import { TOTAL_GRID_COLUMNS, DRAGGABLE_CANCEL_CLASS_NAME, BREAKPOINTS, ROW_HEIGHT } from './visualBuilderGridLayout.constants';
import {
  COMPONENT_CONFIG_KEYS,
  PAGE_CONFIGURATION_FIELD_IDS,
  ACTION_TYPES as VISUAL_BUILDER_ACTION_TYPES,
} from '../../constants/visualBuilder.general.constants';

// Styles
import 'react-grid-layout/css/styles.css';

const VisualBuilderGridLayout = (props) => {
  const {
    isPreviewMode,
    width,
    pageConfiguration,
    previewLayoutsById,
    pageEntity,
    pageEntityRecord,
    visualBuilderOnAction,
    pageViewerOnAction,
    ...restProps
  } = props;

  const layouts = getLayouts(pageConfiguration);
  const previewLayouts = useMemo(() => _valuesIn(previewLayoutsById), [previewLayoutsById]);
  const containersById = _keyBy(tget(pageConfiguration, PAGE_CONFIGURATION_FIELD_IDS.CONTAINERS, EMPTY_ARRAY), COMPONENT_CONFIG_KEYS.WIDGET_NAME);

  const handleLayoutUpdate = useCallback(
    (val) => {
      visualBuilderOnAction({
        type: VISUAL_BUILDER_ACTION_TYPES.UPDATE_GRID_LAYOUT,
        payload: {
          value: getUpdatedPageContainers(pageConfiguration, val),
        },
      });
    },
    [pageConfiguration, visualBuilderOnAction],
  );

  const renderContainer = useCallback(
    (ComponentRenderer, layoutId, container) => (
      <ComponentRenderer
        isPreviewMode={isPreviewMode}
        key={layoutId}
        widgetName={layoutId}
        pageEntity={pageEntity}
        pageEntityRecord={pageEntityRecord}
        componentConfig={container}
        pageViewerOnAction={pageViewerOnAction}
        {...restProps}
      />
    ),
    [isPreviewMode, pageEntity, pageEntityRecord, pageViewerOnAction, restProps],
  );

  return (
    <ReactGridLayout
      isDraggable={!isPreviewMode}
      isResizable={!isPreviewMode}
      width={width}
      layout={isPreviewMode ? previewLayouts : layouts}
      rowHeight={ROW_HEIGHT}
      className="layout"
      margin={[0, 0]}
      useCSSTransforms={false}
      breakpoints={BREAKPOINTS}
      cols={TOTAL_GRID_COLUMNS}
      draggableCancel={`.${DRAGGABLE_CANCEL_CLASS_NAME}`}
      onLayoutChange={isPreviewMode ? _noop : handleLayoutUpdate}
    >
      {_map(layouts, (item, index) => {
        const layoutId = _get(item, 'i');
        const container = tget(containersById, layoutId, EMPTY_OBJECT);
        const { widgetType, componentRef, viewType } = container;
        const ComponentRenderer = componentRef || getWidgetReference({ componentType: widgetType, isPreviewMode, viewType });
        return (
          <div key={layoutId}>
            {isPreviewMode ? (
              renderContainer(ComponentRenderer, layoutId, container)
            ) : (
              <WidgetComponent key={layoutId} index={index} widgetComponentConfig={container}>
                {renderContainer(ComponentRenderer, layoutId, container)}
              </WidgetComponent>
            )}
          </div>
        );
      })}
    </ReactGridLayout>
  );
};

VisualBuilderGridLayout.propTypes = {
  isPreviewMode: PropTypes.bool,
  isWidgetDragging: PropTypes.bool,
  width: PropTypes.number,
  pageConfiguration: PropTypes.object,
  previewLayoutsById: PropTypes.object,
  pageEntity: PropTypes.object,
  pageEntityRecord: PropTypes.object,
  visualBuilderOnAction: PropTypes.func.isRequired,
  pageViewerOnAction: PropTypes.func.isRequired,
};

VisualBuilderGridLayout.defaultProps = {
  isPreviewMode: true,
  isWidgetDragging: false,
  width: 0,
  pageConfiguration: EMPTY_OBJECT,
  previewLayoutsById: EMPTY_OBJECT,
  pageEntity: EMPTY_OBJECT,
  pageEntityRecord: EMPTY_OBJECT,
};

export default VisualBuilderGridLayout;
