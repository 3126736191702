import React, { useCallback, useState, useMemo } from 'react';

// Lodash
import _map from 'lodash/map';
import _curry from 'lodash/curry';

// styles
import styles from './styles/useColorPalette.module.scss';

// Components
import ColorButton from '../../molecules/colorButton';

// Helpers
import { selectColor } from './useColorPalette.helpers';

const renderColorButton = _curry(({ cellBackgroundColor, onColorSelection }, color, index) => {
  const isColorSelected = color === cellBackgroundColor;

  return <ColorButton key={index} isColorSelected={isColorSelected} onColorSelection={onColorSelection} color={color} />;
});

const renderColorPalette = (colors, cellBackgroundColor, onColorSelection) => (
  <div className={styles.colorPalette}>{_map(colors, renderColorButton({ cellBackgroundColor, onColorSelection }))}</div>
);

function useColorPalette({ colors, cellBackgroundColor, onAction, actionType }) {
  const [isColorPaletteVisible, setIsColorPaletteVisible] = useState(false);

  const onColorSelection = useCallback((color) => selectColor({ setIsColorPaletteVisible, onAction, actionType, color }), [onAction, actionType]);

  const colorPalette = useMemo(
    () => renderColorPalette(colors, cellBackgroundColor, onColorSelection),
    [colors, cellBackgroundColor, onColorSelection],
  );

  return {
    colorPalette,
    isColorPaletteVisible,
    setIsColorPaletteVisible,
  };
}

export default useColorPalette;
