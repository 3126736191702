import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import CALLOUT_FIELD_IDS from '../../../constants/nodeDataFieldIds/calloutNodeFieldIds';
import { TASK_DEF_IDS } from '../../../constants/workflow.constants';

const CONTEXT_ID = 'CALLOUT_MODAL_FORM';

const HTTP_METHOD_OPTIONS = [
  { label: __('POST'), value: 'POST' },
  { label: __('PUT'), value: 'PUT' },
  { label: __('PATCH'), value: 'PATCH' },
  { label: __('GET'), value: 'GET' },
  { label: __('DELETE'), value: 'DELETE' },
];

const REQUEST_DETAILS_FIELD_IDS = {
  REQUEST_KEY: 'requestKey',
  REQUEST_VALUE: 'requestValue',
};

const CALLOUT_INITIAL_FORM_VALUES = {
  [CALLOUT_FIELD_IDS.ACTION_TYPE]: TASK_DEF_IDS.CALLOUT,
  [CALLOUT_FIELD_IDS.FIELD_MAPPING_ID]: EMPTY_STRING,
};

export { CONTEXT_ID, HTTP_METHOD_OPTIONS, REQUEST_DETAILS_FIELD_IDS, CALLOUT_INITIAL_FORM_VALUES };
