import PROPERTIES_FORM_FIELD_IDS from './propertiesForm.fieldIds';

const PROPERTIES_FORM_LABELS = {
  [PROPERTIES_FORM_FIELD_IDS.CREATABLE]: __('Is Field Creatable?'),
  [PROPERTIES_FORM_FIELD_IDS.EDITABLE]: __('Is Field Editable?'),
  [PROPERTIES_FORM_FIELD_IDS.SEARCHABLE]: __('Is Field Searchable?'),
  [PROPERTIES_FORM_FIELD_IDS.FILTERABLE]: __('Is Field Filterable?'),
  [PROPERTIES_FORM_FIELD_IDS.SORTABLE]: __('Is Field Sortable?'),
  [PROPERTIES_FORM_FIELD_IDS.SORT_DIRECTION]: __('Select Sort Direction'),
  [PROPERTIES_FORM_FIELD_IDS.IMPORTABLE]: __('Is Field Importable?'),
  [PROPERTIES_FORM_FIELD_IDS.EXPORTABLE]: __('Is Field Exportable?'),
  [PROPERTIES_FORM_FIELD_IDS.MANDATORY]: __('Is Field Mandatory?'),
  [PROPERTIES_FORM_FIELD_IDS.DISABLED]: __('Is Field Disabled?'),
  [PROPERTIES_FORM_FIELD_IDS.UNIQUE_CONSTRAINT]: __('Is Unique field'),
  [PROPERTIES_FORM_FIELD_IDS.AUDIT_ENABLED]: __('Enable audit'),
};

export default PROPERTIES_FORM_LABELS;
