import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import getDataFromResponse from '@tekion/tekion-base/utils/getDataFromResponse';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import recordTypeServices from '../services/recordType.services';

const createRecordType = async (entityName, payload) => {
  try {
    const response = await recordTypeServices.createRecordType(entityName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create record Type. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateRecordType = async (entityName, payload) => {
  try {
    const response = await recordTypeServices.updateRecordType(entityName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update record Type. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchRecordType = async (entityName, payload) => {
  try {
    const response = await recordTypeServices.getRecordType(entityName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch the record Type. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const createRecordTypeOverride = async (entityName, payload) => {
  try {
    const response = await recordTypeServices.createRecordTypeOverride(entityName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to create record Type Override. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateRecordTypeOverride = async (entityName, recordTypeOverrideName, payload) => {
  try {
    const response = await recordTypeServices.updateRecordTypeOverride(entityName, recordTypeOverrideName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update record Type. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const fetchRecordTypeOverride = async (entityName, recordTypeName) => {
  try {
    const response = await recordTypeServices.getRecordTypeOverride(entityName, recordTypeName);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to fetch the record Type. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

const updateRecordTypeDefinition = async (entityName, payload) => {
  try {
    const response = await recordTypeServices.updateRecordTypeDefinition(entityName, payload);
    return getDataFromResponse(response);
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(error, __('Failed to update the Record Type Definition. Please try again later.')));
    return EMPTY_OBJECT;
  }
};

export {
  createRecordType,
  updateRecordType,
  fetchRecordType,
  createRecordTypeOverride,
  updateRecordTypeOverride,
  fetchRecordTypeOverride,
  updateRecordTypeDefinition,
};
