const PLUGINS_KEYS = {
  REACT: 'react',
  HISTORY: 'history',
  BASIC: 'basic',
  VOID_SELECTION: 'voidSelection',
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  STRIKE: 'strike',
  ALIGN: 'align',
  IMAGE: 'image',
  VIDEO: 'video',
  AUTO_FORMAT: 'autoformat',
  SOFT_BREAK: 'softBreak',
  EXIT_BREAK: 'exitBreak',
  FONT_COLOR: 'fontColor',
  FONT_SIZE: 'fontSize',
  LIST: 'list',
  LINK: 'link',
  TABLE: 'table',
  TABLE_CELL: 'tableCell',
  TRAILING_BLOCK: 'trailingBlock',
  DEFAULT_ELEMENT: 'defaultElement',
  BACKGROUND_COLOR: 'backgroundColor',
  // TEMPLATE_VARIABLE: 'variable',
};

const PLUGINS_ORDER = [
  PLUGINS_KEYS.REACT,
  PLUGINS_KEYS.HISTORY,
  PLUGINS_KEYS.BASIC,
  PLUGINS_KEYS.VOID_SELECTION,
  PLUGINS_KEYS.BOLD,
  PLUGINS_KEYS.ITALIC,
  PLUGINS_KEYS.UNDERLINE,
  PLUGINS_KEYS.STRIKE,
  PLUGINS_KEYS.ALIGN,
  PLUGINS_KEYS.IMAGE,
  PLUGINS_KEYS.VIDEO,
  PLUGINS_KEYS.SOFT_BREAK,
  PLUGINS_KEYS.EXIT_BREAK,
  PLUGINS_KEYS.FONT_COLOR,
  PLUGINS_KEYS.FONT_SIZE,
  PLUGINS_KEYS.LIST,
  PLUGINS_KEYS.LINK,
  PLUGINS_KEYS.TABLE,
  PLUGINS_KEYS.TABLE_CELL,
  PLUGINS_KEYS.TRAILING_BLOCK,
  PLUGINS_KEYS.DEFAULT_ELEMENT,
  PLUGINS_KEYS.BACKGROUND_COLOR,
  PLUGINS_KEYS.AUTO_FORMAT,
  // PLUGINS_KEYS.TEMPLATE_VARIABLE,
];

const TOOLBAR_GROUPS = {
  FONT_SIZE: 'FONT_SIZE',
  LIST: 'LIST',
  MARKS: 'MARKS',
  FONT_COLOR: 'FONT_COLOR',
  ALIGNMENT: 'ALIGNMENT',
  INSERTION: 'INSERTION',
  CODE: 'CODE',
  RESET: 'RESET',
  SHORTCUTS: 'SHORTCUTS',
};

const PLUGINS_FOR_TOOLBAR_GROUPS = {
  [TOOLBAR_GROUPS.FONT_SIZE]: [PLUGINS_KEYS.FONT_SIZE],
  [TOOLBAR_GROUPS.LIST]: [PLUGINS_KEYS.LIST],
  [TOOLBAR_GROUPS.MARKS]: [PLUGINS_KEYS.BOLD, PLUGINS_KEYS.ITALIC, PLUGINS_KEYS.UNDERLINE, PLUGINS_KEYS.STRIKE],
  [TOOLBAR_GROUPS.FONT_COLOR]: [PLUGINS_KEYS.FONT_COLOR],
  [TOOLBAR_GROUPS.ALIGNMENT]: [PLUGINS_KEYS.ALIGN],
  [TOOLBAR_GROUPS.INSERTION]: [
    PLUGINS_KEYS.LINK,
    PLUGINS_KEYS.IMAGE,
    PLUGINS_KEYS.VIDEO,
    PLUGINS_KEYS.TABLE,
    PLUGINS_KEYS.TABLE_CELL,
    // PLUGINS_KEYS.TEMPLATE_VARIABLE,
  ],
};

export { PLUGINS_KEYS, PLUGINS_ORDER, TOOLBAR_GROUPS, PLUGINS_FOR_TOOLBAR_GROUPS };
