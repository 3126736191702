exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .detailViewWidgetRenderer_widgetContainer__oAftQgaFqL{border:dashed .1rem #ccc;padding:1rem;margin:1rem}.detailViewWidgetRenderer_widgetContainer__oAftQgaFqL .detailViewWidgetRenderer_editIconContainer__6jRkqYvPfS{display:flex;justify-content:flex-end;align-items:center}[dir] .detailViewWidgetRenderer_widgetContainer__oAftQgaFqL .detailViewWidgetRenderer_editIconContainer__6jRkqYvPfS .detailViewWidgetRenderer_icon__nsR1F8tGAp{cursor:pointer}.detailViewWidgetRenderer_actionMenuItem__cZgeQTT3o6{min-width:160px;height:100%;display:flex;align-items:center}[dir] .detailViewWidgetRenderer_actionMenuItem__cZgeQTT3o6{padding:1rem 1.5rem}[dir=ltr] .detailViewWidgetRenderer_actionMenuTooltip__cuHYWtSWPN{margin-left:1rem}[dir=rtl] .detailViewWidgetRenderer_actionMenuTooltip__cuHYWtSWPN{margin-right:1rem}.detailViewWidgetRenderer_actionContainer__o34Edsp7ku{width:100%;display:flex;justify-content:flex-end}.detailViewWidgetRenderer_actionContainer__o34Edsp7ku .detailViewWidgetRenderer_actionDropDown__vKjBDRi9fP{width:3.6rem}[dir] .detailViewWidgetRenderer_actionContainer__o34Edsp7ku .detailViewWidgetRenderer_actionDropDown__vKjBDRi9fP{cursor:pointer;margin:1rem 0 0}.detailViewWidgetRenderer_noDataContainer__sLk9YumE9r{font-weight:bold}[dir] .detailViewWidgetRenderer_noDataContainer__sLk9YumE9r{padding:1rem 2rem}.detailViewWidgetRenderer_blankWidget__eani9FufMU{display:flex;justify-content:center;align-items:center;height:20rem;width:98%}[dir] .detailViewWidgetRenderer_blankWidget__eani9FufMU{border:dashed .1rem #ccc;background-color:#f4f5f6;margin:1rem}", ""]);

// Exports
exports.locals = {
	"widgetContainer": "detailViewWidgetRenderer_widgetContainer__oAftQgaFqL",
	"editIconContainer": "detailViewWidgetRenderer_editIconContainer__6jRkqYvPfS",
	"icon": "detailViewWidgetRenderer_icon__nsR1F8tGAp",
	"actionMenuItem": "detailViewWidgetRenderer_actionMenuItem__cZgeQTT3o6",
	"actionMenuTooltip": "detailViewWidgetRenderer_actionMenuTooltip__cuHYWtSWPN",
	"actionContainer": "detailViewWidgetRenderer_actionContainer__o34Edsp7ku",
	"actionDropDown": "detailViewWidgetRenderer_actionDropDown__vKjBDRi9fP",
	"noDataContainer": "detailViewWidgetRenderer_noDataContainer__sLk9YumE9r",
	"blankWidget": "detailViewWidgetRenderer_blankWidget__eani9FufMU"
};