import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _uniq from 'lodash/uniq';
import _map from 'lodash/map';
import _get from 'lodash/get';

// Tekion-base
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import { DEFAULT_PAGE_INFO, DEFAULT_PAGE_SIZE } from '@tekion/tekion-base/constants/tableConstants';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

// Actions
import { approveTask, fetchAllApprovalRequests, fetchAllApprovalTasks, rejectTask } from '../../../../actions/approvalCentre.actions';
import { getWorkspaceUserList } from '../../../../actions/workspaceUserManagement.actions';
import { createComment } from '../../../../actions/comments.actions';
import { fetchEntities } from '../../../../actions/entityManagement.actions';

// Helpers
import { getPayload, getPayloadForCreateComment, getPayloadForUserIds } from './approvalTasksList.helpers';
import { getEntitiesApprovalRequestsPayload, getEntitiesDisplayNamesByName } from '../../helper/approvalManagement.helpers';

// Constants
import COLUMN_IDS from '../constants/approvalTasksList.columnIds';
import { APPROVAL_CENTRE_FIELD_IDS, APPROVAL_REJECT_REQUEST_COMMENT_MODAL_ACTION_TYPES } from '../../../../constants/approvalCentre.constants';
import { ACTION_TYPES } from '../constants/approvalTasksList.general';

// Readers
import workspaceUserReader from '../../../../readers/workSpaceUser.reader';

const fetchEntitiesAction = (entitiesPayload) => {
  if (entitiesPayload) {
    return fetchEntities(entitiesPayload);
  }

  return EMPTY_OBJECT;
};

const fetchAllApprovalRequestsAction = (approvalRequestsPayload) => {
  if (approvalRequestsPayload) {
    return fetchAllApprovalRequests(approvalRequestsPayload);
  }

  return EMPTY_OBJECT;
};

const handleLoadEntityAndActionInfo = async ({ isMountedInsideApplication, data, setState }) => {
  const { entitiesPayload, approvalRequestsPayload } = getEntitiesApprovalRequestsPayload(data);

  const promises = [fetchEntitiesAction(entitiesPayload)];

  if (isMountedInsideApplication) {
    // Because for application only we want to show action type column.
    promises.push(fetchAllApprovalRequestsAction(approvalRequestsPayload));
  }

  const [entitiesResponse, approvalRequestsResponse] = await Promise.all(promises);

  const entitiesDisplayNamesByName = getEntitiesDisplayNamesByName(entitiesResponse);

  const approvalRequests = tget(approvalRequestsResponse, 'data', EMPTY_ARRAY);
  const approvalRequestsById = _keyBy(approvalRequests, 'id');

  const dataWithPopulatedInfo = _map(data, (approvalTask) => {
    const entityName = tget(approvalTask, COLUMN_IDS.ENTITY);
    const approvalId = tget(approvalTask, APPROVAL_CENTRE_FIELD_IDS.APPROVAL_ID);
    const approvalRequest = tget(approvalRequestsById, approvalId, EMPTY_OBJECT);
    const actionType = tget(approvalRequest, ['data', APPROVAL_CENTRE_FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.ACTION_TYPE], NO_DATA);

    return {
      ...approvalTask,
      [COLUMN_IDS.ENTITY]: tget(entitiesDisplayNamesByName, entityName, NO_DATA),
      [APPROVAL_CENTRE_FIELD_IDS.CUSTOM_ENTITY_REQUEST_FIELD_IDS.ACTION_TYPE]: actionType,
    };
  });

  setState({ data: dataWithPopulatedInfo });
};

const fetchUsers = async (ids) => {
  if (!_isEmpty(ids)) {
    const payload = getPayloadForUserIds(ids);
    const response = await getWorkspaceUserList(payload);
    const users = tget(response, 'hits', EMPTY_ARRAY);
    const userData = _keyBy(users, 'id');
    return userData;
  }

  return EMPTY_OBJECT;
};

const refineData = async (data) => {
  const ids = _uniq(_map(data, (value) => _get(value, COLUMN_IDS.CREATED_BY)));
  const userData = await fetchUsers(ids);
  const updatedData = _map(data, (value) => {
    const userId = tget(value, COLUMN_IDS.CREATED_BY);
    const user = tget(userData, userId, EMPTY_OBJECT);

    return {
      ...value,
      [COLUMN_IDS.CREATED_BY]: workspaceUserReader.fullName(user),
    };
  });

  return updatedData;
};

const fetchData = async ({ getState, setState }) => {
  const {
    currentPage = DEFAULT_PAGE_INFO.start,
    selectedFilters = EMPTY_ARRAY,
    pageSize = DEFAULT_PAGE_SIZE,
    currentPageToken = EMPTY_STRING,
    applicationProperties = EMPTY_OBJECT,
    currentLoggedInUserData = EMPTY_OBJECT,
    isMountedInsideApplication,
  } = getState();

  const payload = getPayload({
    applicationProperties,
    currentPage,
    currentPageToken,
    pageSize,
    selectedFilters,
    currentLoggedInUserData,
  });

  setState({ isLoading: true });

  const response = await fetchAllApprovalTasks(payload);
  const data = tget(response, 'data', EMPTY_ARRAY);
  const nextPageToken = _get(response, 'nextPageToken', EMPTY_STRING);

  const refinedData = await refineData(data);

  handleLoadEntityAndActionInfo({ isMountedInsideApplication, data: refinedData, setState });

  setState({ isLoading: false, data: refinedData, nextPageToken, pageSize, currentPage });
};

const handlePageUpdate = ({ params, getState, setState }) => {
  const { page, resultsPerPage } = _get(params, 'value', EMPTY_OBJECT);
  const { currentPage, pageSize, nextPageToken, previousPageTokens = [], currentPageToken } = getState();

  let newPageNumber = page;
  let newTokenNumber = null;
  let newPrevPageTokens = [...previousPageTokens];

  if (resultsPerPage !== pageSize) {
    newPageNumber = 1;
    newPrevPageTokens = [];
  } else if (page > currentPage + 1) {
    newTokenNumber = nextPageToken;
    newPrevPageTokens.push(currentPageToken);
  } else if (page !== 1) {
    newTokenNumber = newPrevPageTokens.pop();
  } else {
    newPrevPageTokens = [];
  }

  setState(
    { currentPage: newPageNumber - 1, pageSize: resultsPerPage, currentPageToken: newTokenNumber, previousPageTokens: newPrevPageTokens },
    () => fetchData({ getState, setState }),
  );
};

const handleFilterChange = ({ params, getState, setState }) => {
  const { value } = params;
  setState({ selectedFilters: value, currentPage: 0, currentPageToken: null, previousPageTokens: [] }, () => fetchData({ getState, setState }));
};

const handleApprovalTaskClick = async ({ getState, params = EMPTY_OBJECT }) => {
  const { history, match } = getState();
  const approvalTask = _get(params, 'value.original');
  const approvalId = tget(approvalTask, APPROVAL_CENTRE_FIELD_IDS.APPROVAL_ID);

  history.push({ pathname: `${match.url}/${approvalId}`, state: { approvalTask } });
};

const handleApproveRequest = async ({ getState, setState, params }) => {
  const approvalTaskInAction = params;

  const taskId = tget(approvalTaskInAction, APPROVAL_CENTRE_FIELD_IDS.ID);

  if (!_isEmpty(taskId)) {
    const response = await approveTask(taskId);
    if (!_isEmpty(response)) {
      setTimeout(() => {
        toaster(TOASTER_TYPE.SUCCESS, __('Request approved successfully!'));
        fetchData({ getState, setState });
      }, ES_REFETCH_DELAY);
    }
  } else {
    toaster(TOASTER_TYPE.ERROR, __('Failed to approve this request, please refresh the page and try again.'));
  }
};

const handleAddCommentInRejectRequest = async ({ params }) => {
  const { selectedRejectRequestId, newCommentContent } = params;
  const payloadForCreateComment = getPayloadForCreateComment(selectedRejectRequestId, newCommentContent);
  await createComment(payloadForCreateComment);
};

const handleRejectRequest = async ({ getState, setState, params }) => {
  const { newCommentContent } = params;
  const { selectedRejectTaskId: taskId, selectedRejectRequestId } = getState();
  if (!_isEmpty(taskId)) {
    const response = await rejectTask(taskId);
    if (!_isEmpty(response)) {
      setTimeout(() => {
        toaster(TOASTER_TYPE.SUCCESS, __('Request rejected successfully!'));
        handleAddCommentInRejectRequest({ params: { selectedRejectRequestId, newCommentContent } });
        fetchData({ getState, setState });
      }, ES_REFETCH_DELAY);
    }
  } else {
    toaster(TOASTER_TYPE.ERROR, __('Failed to reject this request, please refresh the page and try again.'));
  }
  setState({ isAddCommentModalVisible: false });
};

const handleClickRejectRequest = ({ setState, params }) => {
  const approvalTaskInAction = params;
  const selectedRejectRequestId = tget(approvalTaskInAction, APPROVAL_CENTRE_FIELD_IDS.APPROVAL_ID);
  const selectedRejectTaskId = tget(approvalTaskInAction, APPROVAL_CENTRE_FIELD_IDS.ID);
  setState({ isAddCommentModalVisible: true, selectedRejectRequestId, selectedRejectTaskId });
};

const handleCloseAddCommentModal = ({ setState }) => {
  setState({ isAddCommentModalVisible: false });
};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: fetchData,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePageUpdate,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleFilterChange,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleApprovalTaskClick,
  [ACTION_TYPES.APPROVE_REQUEST]: handleApproveRequest,
  [APPROVAL_REJECT_REQUEST_COMMENT_MODAL_ACTION_TYPES.ON_CONFIRM_REJECT_REQUEST]: handleRejectRequest,
  [ACTION_TYPES.REJECT_REQUEST]: handleClickRejectRequest,
  [APPROVAL_REJECT_REQUEST_COMMENT_MODAL_ACTION_TYPES.ON_CANCEL_REJECT_REQUEST]: handleCloseAddCommentModal,
};

export default ACTION_HANDLERS;
