exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir] .fieldsForm_titleTextPadding__5FtoaJnWEq{padding-bottom:1.2rem}.fieldsForm_addFormStyle__2qN6VG1PpQ{max-width:none !important}.fieldsForm_form__gMyvx4WEox{height:calc(100% - 5rem);overflow-y:auto}.fieldsForm_formSectionHeader__aWV3QtLJoH{font-size:1.8rem}.fieldsForm_footerContainer__vzYc88oiaR{height:6.4rem}.fieldsForm_inputTable__o4LANWZZ2A{min-height:0 !important}[dir] .fieldsForm_inputTable__o4LANWZZ2A{margin-bottom:1.5rem}.fieldsForm_addRowButton__q2AnnARYhW{width:15%}.fieldsForm_configTableHeading__5iM9wVc1XB{font-size:1.6rem;font-weight:bold}[dir] .fieldsForm_configTableHeading__5iM9wVc1XB{margin-bottom:.8rem}.fieldsForm_controllingSettingsInfoIcon__rw18NXGKkP{bottom:-0.2rem}[dir] .fieldsForm_addButton__w2jFL1c9yW{margin-bottom:2rem}.fieldsForm_halfColumn__idpgUH21rE{width:calc((100% - 2.4rem)/2)}", ""]);

// Exports
exports.locals = {
	"titleTextPadding": "fieldsForm_titleTextPadding__5FtoaJnWEq",
	"addFormStyle": "fieldsForm_addFormStyle__2qN6VG1PpQ",
	"form": "fieldsForm_form__gMyvx4WEox",
	"formSectionHeader": "fieldsForm_formSectionHeader__aWV3QtLJoH",
	"footerContainer": "fieldsForm_footerContainer__vzYc88oiaR",
	"inputTable": "fieldsForm_inputTable__o4LANWZZ2A",
	"addRowButton": "fieldsForm_addRowButton__q2AnnARYhW",
	"configTableHeading": "fieldsForm_configTableHeading__5iM9wVc1XB",
	"controllingSettingsInfoIcon": "fieldsForm_controllingSettingsInfoIcon__rw18NXGKkP",
	"addButton": "fieldsForm_addButton__w2jFL1c9yW",
	"halfColumn": "fieldsForm_halfColumn__idpgUH21rE"
};