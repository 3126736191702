const ROW_ACTION_PROPS = {
  visibleActionCount: 1,
};

const CONTROLLING_OPTION_TABLE_COLUMN_IDS = {
  OPTION: 'controlledOption',
  VALUES: 'controllingOptions',
};

const CONTROLLING_OPTION_TABLE_ACTION_TYPES = {
  REMOVE_ROW: 'REMOVE_CONTROLLED_OPTION',
  ADD_ROW: 'ADD_CONTROLLED_OPTION',
};

const REMOVE_ACTION = {
  id: CONTROLLING_OPTION_TABLE_ACTION_TYPES.REMOVE_ROW,
  name: __('Remove Row'),
  icon: 'icon-trash',
};

export { CONTROLLING_OPTION_TABLE_COLUMN_IDS, ROW_ACTION_PROPS, REMOVE_ACTION, CONTROLLING_OPTION_TABLE_ACTION_TYPES };
