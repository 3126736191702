import _isEmpty from 'lodash/isEmpty';

import { tget } from '@tekion/tekion-base/utils/general';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

// Actions
import { createProcess, getProcessById, updateProcess } from '../../../../../../../../actions/processBuilder.actions';
import { createRule, getRuleById, updateRule } from '../../../../../../../../actions/ruleBuilder.actions';

// Helpers
import { loadFieldDefinitions, loadAndSetEntityDisplayName } from './helperHandlers';

// Utils
import getInitialWorkflowData from '../../../utils/getInitialWorkflowData';
import getWorkflowPayload from '../../../utils/getWorkflowPayload';
import mapData from '../../../utils/mapNodeData';

// Constants
import WORKFLOW_BUILDER_ACTION_TYPES from '../../../constants/workflowBuilder.actionTypes';
import ROUTES from '../../../../../../constants/routes';
import WORKFLOW_FIELD_IDS from '../../../constants/workflow.fieldIds';
import WORKFLOW_BUILDER_MODAL_TYPES from '../../../constants/workflowBuilder.modalTypes';
import { CURRENT_ENTITY_NAMESPACE } from '../../../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import { WORKFLOW_BUILDER_MODE } from '../../../../../../../../constants/general.constants';
import { STUDIO_ROUTE } from '../../../../../../../../constants/routes';
import PAGE_IDS from '../../../../../../constants/PageIds.constants';

const handleBuilderInit = async ({ getState, setState }) => {
  const { history, match, eventTypes, steps, workflowMode } = getState();
  const workflowId = match?.params.workflowId;

  if (workflowId) {
    // fetch workflow
    let workflowData = {};
    if (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS) {
      workflowData = await getProcessById(workflowId);
    } else if (workflowMode === WORKFLOW_BUILDER_MODE.RULE) {
      workflowData = await getRuleById(workflowId);
    }
    const workflow = mapData(workflowData);

    const entityName = tget(workflow, WORKFLOW_FIELD_IDS.ENTITY);

    const mapOfVariableToEntityNameForCondition = { [CURRENT_ENTITY_NAMESPACE]: entityName };

    setState({
      workflow,
      isWorkflowLoading: false,
      isWorkflowFetched: true,
      isWorkflowModified: false,
      isWorkflowSubmitting: false,
      stepDrawerData: {
        isLoading: false,
        isError: false,
        error: '',
        items: steps,
      },
      triggerDrawerData: {
        isLoading: false,
        isError: false,
        items: eventTypes,
      },
      mapOfVariableToEntityNameForCondition,
    });

    loadFieldDefinitions({ setState, getState, params: { variableMap: mapOfVariableToEntityNameForCondition } });
  } else {
    const initialWorkflowDetails = tget(history, 'location.state.initialWorkflowDetails');

    if (_isEmpty(initialWorkflowDetails)) {
      // Route back to Form
      let pathname = '';

      if (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS) {
        pathname = ROUTES.PROCESS_CREATE_FORM_ROUTE;
      } else {
        pathname = ROUTES.RULE_CREATE_FORM_ROUTE;
      }
      history.push(pathname);
    } else {
      const initialWorkflowData = getInitialWorkflowData(initialWorkflowDetails, eventTypes);

      const entityName = tget(initialWorkflowData, WORKFLOW_FIELD_IDS.ENTITY);
      const mapOfVariableToEntityNameForCondition = { [CURRENT_ENTITY_NAMESPACE]: entityName };

      setState({
        workflow: initialWorkflowData,
        isWorkflowLoading: false,
        isWorkflowFetched: true,
        isWorkflowModified: false,
        isWorkflowSubmitting: false,
        stepDrawerData: {
          isLoading: false,
          isError: false,
          error: '',
          items: steps,
        },
        triggerDrawerData: {
          isLoading: false,
          isError: false,
          items: eventTypes,
        },
        mapOfVariableToEntityNameForCondition,
      });

      loadAndSetEntityDisplayName({ setState, getState, params: { entityName } });
      loadFieldDefinitions({ setState, getState, params: { variableMap: mapOfVariableToEntityNameForCondition } });
    }
  }
};

const handleEntityChange = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { variableMap, loadingPropName } = params;
  const { modalProps } = getState();
  const { scopedVariables } = modalProps;

  setState((state) => ({
    ...state,
    modalProps: {
      ...state.modalProps,
      [loadingPropName]: true,
    },
  }));

  const { conditionBuilderFieldDefinitionObject } = await loadFieldDefinitions({
    setState,
    getState,
    params: { variableMap, scopedVariables },
  });

  setState((state) => ({
    ...state,
    modalProps: {
      ...state.modalProps,
      [loadingPropName]: false,
      conditionBuilderFieldDefinitionObject,
    },
  }));
};

const handleShowCancelConfirmationModal = ({ getState, setState }) => {
  const { match } = getState();
  const workflowId = match?.params.workflowId;

  setState({
    isModalVisible: true,
    modalType: WORKFLOW_BUILDER_MODAL_TYPES.CONFIRMATION,
    modalProps: {
      stepDetails: {},
      mode: 'CANCEL',
      title: __('Cancel confirmation'),
      confirmationContent: _isEmpty(workflowId)
        ? __('Are you sure you want to cancel this workflow?')
        : __('Are you sure you want to cancel your changes?'),
      submitBtnText: __('Yes'),
      secondaryBtnText: __('No'),
    },
  });
};

const handleWorkflowSubmit = async ({ getState, setState }) => {
  const { match, history, workflow, workflowMode } = getState();
  const workflowId = match?.params.workflowId;
  const workflowPayload = getWorkflowPayload(workflow);

  let submitActionResponse;

  setState({ isWorkflowSubmitting: true });

  if (_isEmpty(workflowId)) {
    if (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS) {
      submitActionResponse = await createProcess(workflowPayload);
    } else if (workflowMode === WORKFLOW_BUILDER_MODE.RULE) {
      submitActionResponse = await createRule(workflowPayload);
    }
  } else if (workflowMode === WORKFLOW_BUILDER_MODE.PROCESS) {
    submitActionResponse = await updateProcess(workflowId, workflowPayload);
  } else if (workflowMode === WORKFLOW_BUILDER_MODE.RULE) {
    submitActionResponse = await updateRule(workflowId, workflowPayload);
  }

  if (!_isEmpty(submitActionResponse)) {
    setTimeout(() => {
      history.push(`${STUDIO_ROUTE}/${PAGE_IDS.WORKFLOW_BUILDER}/${workflowMode}`);
    }, ES_REFETCH_DELAY);
  } else {
    setState({ isWorkflowSubmitting: false });
  }
};

const handleUpdateMapOfScopedVariablesByNode = ({ setState, params }) => {
  const { variablesByNodeId } = params;
  setState({ variablesByNodeId });
};

const handleUndoRedoWorkFlowUpdate = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { workflow } = getState();
  const { changes, config = EMPTY_OBJECT } = params;
  const { updateWithoutModifications = false } = config;

  let updatedWorkflow = tget(changes, 'workflow', {});

  if (!updateWithoutModifications) {
    updatedWorkflow = { ...workflow, ...changes };
  }
  setState({
    workflow: updatedWorkflow,
  });
};

const WORKFLOW_BUILDER_ACTION_HANDLERS = {
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_BUILDER_INIT]: handleBuilderInit,
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_ENTITY_CHANGE]: handleEntityChange,
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_CANCEL_CLICK]: handleShowCancelConfirmationModal,
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_WORKFLOW_SUBMIT]: handleWorkflowSubmit,
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_UPDATE_MAP_OF_SCOPED_VARIABLES_BY_NODE]: handleUpdateMapOfScopedVariablesByNode,
  [WORKFLOW_BUILDER_ACTION_TYPES.ON_UNDO_REDO_WORKFLOW_UPDATES]: handleUndoRedoWorkFlowUpdate,
};

export default WORKFLOW_BUILDER_ACTION_HANDLERS;
