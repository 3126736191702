exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".authConfigTile_providerTile__pTc1y94PAE{height:15rem;width:15rem}[dir] .authConfigTile_providerTile__pTc1y94PAE{margin:2rem;box-shadow:0 .2rem .6rem #969aa3;border-radius:.8rem;border:0;cursor:pointer}[dir] .authConfigTile_providerTile__pTc1y94PAE:hover{border-color:#000;box-shadow:0 .4rem .8rem #969aa3}.authConfigTile_providerTile__pTc1y94PAE .authConfigTile_removeIcon__wdpqxTzAx2{position:relative;top:1rem}[dir=ltr] .authConfigTile_providerTile__pTc1y94PAE .authConfigTile_removeIcon__wdpqxTzAx2{left:13rem}[dir=rtl] .authConfigTile_providerTile__pTc1y94PAE .authConfigTile_removeIcon__wdpqxTzAx2{right:13rem}.authConfigTile_providerTile__pTc1y94PAE .authConfigTile_contentContainer__usuiPXBCqN{display:flex;flex-direction:column;justify-content:center;align-items:center;height:12rem}.authConfigTile_providerTile__pTc1y94PAE .authConfigTile_contentContainer__usuiPXBCqN .authConfigTile_providerLogo__gZdWxvj29d{color:#007bfe}.authConfigTile_providerTile__pTc1y94PAE .authConfigTile_contentContainer__usuiPXBCqN .authConfigTile_logoPlaceHolder__9ZDzZWsp9T{font-family:\"Proxima-Nova-Semibold\";font-size:3.2rem;height:4rem}.authConfigTile_providerTile__pTc1y94PAE .authConfigTile_contentContainer__usuiPXBCqN .authConfigTile_imgSize__aRfs8vQ1yr{height:4rem;width:7rem}", ""]);

// Exports
exports.locals = {
	"providerTile": "authConfigTile_providerTile__pTc1y94PAE",
	"removeIcon": "authConfigTile_removeIcon__wdpqxTzAx2",
	"contentContainer": "authConfigTile_contentContainer__usuiPXBCqN",
	"providerLogo": "authConfigTile_providerLogo__gZdWxvj29d",
	"logoPlaceHolder": "authConfigTile_logoPlaceHolder__9ZDzZWsp9T",
	"imgSize": "authConfigTile_imgSize__aRfs8vQ1yr"
};