import { tget } from '@tekion/tekion-base/utils/general';

import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../../../../constants/actionBuilder.constants';
import { TASK_DEF_IDS } from '../../../constants/workflow.constants';
import { LOOP_NODE_FIELD_IDS } from '../../../constants/nodeDataFieldIds';

import styles from './loopNodeModal.module.scss';

const rowCheck = {
  [ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS]: ({ isTargetFieldsLoading }) => !isTargetFieldsLoading,
  [ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT]: ({ isTargetFieldsLoading }) => !isTargetFieldsLoading,
  [ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS]: ({ isSortRequired, isTargetFieldsLoading }) => isSortRequired && !isTargetFieldsLoading,
  [ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS]: ({ isTargetFieldsLoading }) => !isTargetFieldsLoading,
};

const getFetchAndIterateSections = (isTargetFieldsLoading, values) => {
  const isSortRequired = tget(values, ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS, false);
  return [
    {
      className: styles.section,
      rows: [
        {
          columns: [LOOP_NODE_FIELD_IDS.LOOP_NAME],
        },
        {
          columns: [LOOP_NODE_FIELD_IDS.LOOP_VARIABLE_NAME, LOOP_NODE_FIELD_IDS.ENTITY_NAME],
        },
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.FILTER_CONDITIONS], { isTargetFieldsLoading }, rowCheck),
        },
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.SHOULD_SORT_RECORDS], { isTargetFieldsLoading }, rowCheck),
        },
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.SORT_CONDITIONS], { isSortRequired, isTargetFieldsLoading }, rowCheck),
        },
        {
          columns: filterRows([ACTION_DEFINITION_FIELD_IDS.RECORDS_LIMIT], { isTargetFieldsLoading }, rowCheck),
        },
      ],
    },
  ];
};

const ITERATE_SECTIONS = [
  {
    className: styles.section,
    rows: [
      {
        columns: [LOOP_NODE_FIELD_IDS.LOOP_NAME],
      },
      {
        columns: [LOOP_NODE_FIELD_IDS.LOOP_VARIABLE_NAME, LOOP_NODE_FIELD_IDS.LIST_TO_LOOP_OVER],
      },
    ],
  },
];

const getSections = (taskDefId, isTargetFieldsLoading, values) => {
  if (taskDefId === TASK_DEF_IDS.FETCH_AND_ITERATE) {
    return getFetchAndIterateSections(isTargetFieldsLoading, values);
  }
  return ITERATE_SECTIONS;
};

export default getSections;
